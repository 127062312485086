import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getBestContrastColor, manipulateColor } from '../common/color.helper';
import { cn } from '../common/utils';
import {
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';
import { namedColorsByKey } from '../store/namedColors.state';

type AssignmentPolicyRuleTagProps = {
  ruleId: string;
  isPrimary?: boolean;
};

const AssignmentPolicyRuleTag: React.FC<
  AssignmentPolicyRuleTagProps
> = props => {
  const rule = useRecoilValue(assignmentPolicyRule(props.ruleId));
  const setSelectedRule = useSetRecoilState(assignmentPolicySelectedIdentity);
  const [background, color] = useRecoilValue(namedColorsByKey(rule.title));

  const selectedRule = useRecoilValue(assignmentPolicySelectedIdentity);
  const isSelected = selectedRule?.ruleId === props.ruleId;

  if (_.isNil(rule)) {
    console.warn(
      `Assignment policy rule with id: ${props.ruleId} was not found`,
    );
    return null;
  }

  return (
    <span
      data-component="AssignmentPolicyRuleTag"
      aria-label={`Assignment Compliance Rule`}
      className={cn(
        'text-xxs',
        'm-0.5',
        'flex',
        'items-center',
        'cursor-pointer',
        'whitespace-nowrap',
        'rounded',
        'px-2',
        'py-1',
        'text-center',
        'hover:shadow-xl',
        'transition-colors',
        { 'hover:bg-opacity-80': !isSelected },
      )}
      style={{
        background: props.isPrimary
          ? isSelected
            ? manipulateColor(background, {
                opacity: 0.1,
              })
            : background
          : isSelected
            ? manipulateColor(background, {
                opacity: 0.5,
              })
            : manipulateColor(background, {
                opacity: 0.2,
                desaturate: true,
              }),
        color: props.isPrimary
          ? isSelected
            ? 'white'
            : getBestContrastColor(background)
          : isSelected
            ? getBestContrastColor(background)
            : background,
        border: props.isPrimary
          ? isSelected
            ? `1px solid ${background}`
            : null
          : isSelected
            ? null
            : `1px solid ${manipulateColor(background, {
                desaturate: true,
                opacity: 0.5,
              })}`,
      }}
      onClick={() =>
        setSelectedRule({
          ruleId: rule.id,
          filterType: 'locations',
        })
      }
    >
      {rule.title}
    </span>
  );
};

export default AssignmentPolicyRuleTag;
