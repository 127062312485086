import { useApolloClient } from '@apollo/client';
import {
  ImportJobImportType,
  LoadDatasetObjectDashboardDocument,
  LoadDatasetObjectDashboardQuery,
  LoadDatasetObjectDashboardQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { DASHBOARD_ITEMS_COUNT } from '../store/dashboard.default';
import { geSortByParams } from '../store/dashboard.helper';
import {
  dashboardDatasetObjectData,
  dashboardDatasetObjectLoadState,
  dashboardDatasetObjectState,
} from '../store/dashboard.state';
import { LoadDashboardParams } from '../store/dashboard.types';
export type LoadDatasetObjectDashboardParams = LoadDashboardParams & {
  type: ImportJobImportType;
};

function useLoadDatasetObjectDashboard() {
  const client = useApolloClient();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(dashboardDatasetObjectLoadState, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadDatasetObjectDashboardParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(dashboardDatasetObjectData);
        const currentState = await snapshot.getPromise(
          dashboardDatasetObjectState,
        );

        const response = await client.query<
          LoadDatasetObjectDashboardQuery,
          LoadDatasetObjectDashboardQueryVariables
        >({
          query: LoadDatasetObjectDashboardDocument,
          variables: {
            warehouseId,
            sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DASHBOARD_ITEMS_COUNT,
            },
            filter: {
              objectType: [params.type],
            },
          },
        });

        let items = response.data.warehouse.datasetObjects.content;

        if (params.isAppend) {
          items = [...current, ...items];
        }

        set(dashboardDatasetObjectData, items);
        set(dashboardDatasetObjectLoadState, AsyncLoadStatus.Ok);
        set(dashboardDatasetObjectState, {
          ...currentState,
          loadedCount: items.length,
          totalCount: response.data.warehouse.datasetObjects.totalCount,
          order: params.sort,
        });
      },
  );

  return async (params: LoadDatasetObjectDashboardParams) => {
    await initLoading();
    await loadDashDataCallback(params);
  };
}

export default useLoadDatasetObjectDashboard;
