import * as React from 'react';
const BoxPlusIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BoxPlusIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4L12.04,34v60l51.96,30,51.96-30v-60S64,4,64,4ZM89.86,68.06h-21.72v22.76c0,2.29-1.85,4.14-4.14,4.14s-4.14-1.85-4.14-4.14v-22.76h-21.72c-2.29,0-4.14-1.85-4.14-4.14s1.85-4.14,4.14-4.14h21.72v-20.69c0-2.29,1.85-4.14,4.14-4.14s4.14,1.85,4.14,4.14v20.69h21.72c2.29,0,4.14,1.85,4.14,4.14s-1.85,4.14-4.14,4.14Z"
    />
  </svg>
);
export default BoxPlusIcon;
