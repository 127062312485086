import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Icon from '../../components/icons';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';

const PickingPolicyContainerEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [rule, setRule] = useRecoilState(
    pickingPolicyRule(selectedIdentity?.ruleId),
  );
  if (!selectedIdentity || !rule) return null;

  const hasContainer = rule?.usePickingContainer;

  function setContainer(v: boolean) {
    setRule({
      ...rule,
      usePickingContainer: v,
    });
  }

  return (
    <div data-component="PickingPolicyContainerEditor">
      <TitleSection
        id={`policy-editor-containers`}
        title={t`Containers`}
        inSidebarView
        hasScreenTitle
        classNameInner={classNames('p-2 space-y-2')}
      >
        <PolicyCardItem
          key={`PolicyCardItem-containers-disabled`}
          isActive={!hasContainer}
          onClick={() => setContainer(false)}
          icon={Icon.PickByContainer}
          iconClassName={classNames(
            'w-8 h-8',
            'ltr:mr-2 rtl:mx-2 ltr:ml-1 rtl:mr-1',
          )}
          title={t`No Container`}
        />
        <PolicyCardItem
          key={`PolicyCardItem-containers-enabled`}
          isActive={hasContainer}
          onClick={() => setContainer(true)}
          icon={Icon.PickByContainer}
          iconClassName={classNames(
            'w-8 h-8',
            'ltr:mr-2 rtl:mx-2 ltr:ml-1 rtl:mr-1',
          )}
          title={t`Pick by Container`}
        />
      </TitleSection>
    </div>
  );
};

export default PickingPolicyContainerEditor;
