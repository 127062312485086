import _ from 'lodash';
import React from 'react';
import { ColumnConfig } from './DatasetTable';
import { PanelHeader } from './designer/panels/PanelHeader';
import { createFilterTagData, TableFilter } from './TableFilter';

export type DatasetTablePanelHeaderProps = {
  title: string;
  searchValues?: Record<string, string>;
  columnsConfig: ColumnConfig<Object>[];
  onCloseClick?: () => void;
  onClearAllFilters?: () => void;
};
const DatasetTablePanelHeader: React.FC<
  DatasetTablePanelHeaderProps
> = props => {
  return (
    <PanelHeader
      title={props.title}
      closable={_.isFunction(props.onCloseClick)}
      onCloseClick={props.onCloseClick}
      filterPlaceholder={
        <TableFilter
          filterData={createFilterTagData(
            props.searchValues,
            props.columnsConfig,
          )}
          onRemoveClick={props.onClearAllFilters}
        />
      }
    />
  );
};

export default DatasetTablePanelHeader;
