import {
  AssignmentIssueInvalidLocationReason,
  ItemSetIssueInvalidItemReason,
  ItemSetIssueInvalidUomReason,
  LayoutIssueInvalidLocationReason,
  OrderSetIssueUnpickableLineReason,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../Tw';
import {
  IssueDescriptorsMap,
  IssueTypeDescriptor,
} from './simulation.issues.types';

const issueColors = TwTheme.extend.colors.issues;

export const getIssuesDescriptors = _.memoize(
  (t: TFunction<'simulation'>): Partial<IssueDescriptorsMap> => {
    return {
      layout: getLayoutIssueDescriptors(t),
      assignment: getAssignmentIssueDescriptors(t),
      itemSet: {
        ...getUOMIssueDescriptors(t),
        ...getItemIssueDescriptors(t),
      },
      orderSet: getOrderIssueDescriptors(t),
      orderedItems: getOrderItemIssueDescriptors(t),
    };
  },
);

export const getLayoutIssueDescriptors = _.memoize(
  (
    t: TFunction<'simulation'>,
  ): Record<
    LayoutIssueInvalidLocationReason,
    IssueTypeDescriptor<LayoutIssueInvalidLocationReason>
  > => {
    const data: IssueTypeDescriptor<LayoutIssueInvalidLocationReason>[] = [
      {
        id: LayoutIssueInvalidLocationReason.DISABLED,
        title: t(`Inactive Locations (Disabled)`, { ns: 'simulation' }),
        tag: t(`Disabled Location`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
    ];
    return _.keyBy(data, i => i.id) as Record<
      LayoutIssueInvalidLocationReason,
      IssueTypeDescriptor<LayoutIssueInvalidLocationReason>
    >;
  },
);

export const getAssignmentIssueDescriptors = _.memoize(
  (
    t: TFunction<'simulation'>,
  ): Record<
    AssignmentIssueInvalidLocationReason,
    IssueTypeDescriptor<AssignmentIssueInvalidLocationReason>
  > => {
    const data: IssueTypeDescriptor<AssignmentIssueInvalidLocationReason>[] = [
      {
        id: AssignmentIssueInvalidLocationReason.UNKNOWN_LOCATION,
        title: t(`Locations not found (Assignment)`, { ns: 'simulation' }),
        tag: t(`Unknown location`, { ns: 'simulation' }),
        color: issueColors.unknown,
        textColor: issueColors.unknownText,
      },
      {
        id: AssignmentIssueInvalidLocationReason.EMPTY_LOCATION,
        title: t(`Empty locations`, { ns: 'simulation' }),
        tag: t(`Empty location`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
      {
        id: AssignmentIssueInvalidLocationReason.LOCATION_WITH_MIXED_ITEMS,
        title: t(`Location with multiple Items`, { ns: 'simulation' }),
        tag: t(`Multi-Item`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
      {
        id: AssignmentIssueInvalidLocationReason.DISABLED_LOCATION_WITH_ITEMS,
        title: t(`Disabled locations with Items`, { ns: 'simulation' }),
        tag: t(`Disabled Location`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
    ];
    return _.keyBy(data, i => i.id) as Record<
      AssignmentIssueInvalidLocationReason,
      IssueTypeDescriptor<AssignmentIssueInvalidLocationReason>
    >;
  },
);

export const getOrderIssueDescriptors = _.memoize(
  (
    t: TFunction<'simulation'>,
  ): Record<
    OrderSetIssueUnpickableLineReason,
    IssueTypeDescriptor<OrderSetIssueUnpickableLineReason>
  > => {
    const data: IssueTypeDescriptor<OrderSetIssueUnpickableLineReason>[] = [
      {
        id: OrderSetIssueUnpickableLineReason.UNASSIGNED_ITEM,
        title: t(`Orders: Unassigned Items`, { ns: 'simulation' }),
        tag: t(`Unassigned Item`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
        required: true,
      },
      {
        id: OrderSetIssueUnpickableLineReason.MISSING_UOMS,
        title: t(`Orders: Items without UOM's`, { ns: 'simulation' }),
        tag: t(`No UOM`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
      {
        id: OrderSetIssueUnpickableLineReason.UNKNOWN_ITEM,
        title: t(`Orders: Items missing in Item Set`, {
          ns: 'simulation',
        }),
        tag: t(`Unknown Item`, { ns: 'simulation' }),
        color: issueColors.unknown,
        textColor: issueColors.unknownText,
        required: true,
      },
      {
        id: OrderSetIssueUnpickableLineReason.UNKNOWN_LOCATIONS,
        title: t(`Orders: Items in Unknown locations`, {
          ns: 'simulation',
        }),
        tag: t(`Unknown Location`, { ns: 'simulation' }),
        color: issueColors.unknown,
        textColor: issueColors.unknownText,
        required: true,
      },
      {
        id: OrderSetIssueUnpickableLineReason.DISABLED_LOCATIONS,
        title: t(`Orders: Items in Disabled locations`, {
          ns: 'simulation',
        }),
        tag: t(`Disabled Location`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
    ];
    return _.keyBy(data, i => i.id) as Record<
      OrderSetIssueUnpickableLineReason,
      IssueTypeDescriptor<OrderSetIssueUnpickableLineReason>
    >;
  },
);

export const getOrderItemIssueDescriptors = _.memoize(
  (
    t: TFunction<'simulation'>,
  ): Record<
    OrderSetIssueUnpickableLineReason,
    IssueTypeDescriptor<OrderSetIssueUnpickableLineReason>
  > => {
    const data: IssueTypeDescriptor<OrderSetIssueUnpickableLineReason>[] = [
      {
        id: OrderSetIssueUnpickableLineReason.UNASSIGNED_ITEM,
        title: t(`Ordered Items: Not in Stock`, {
          ns: 'simulation',
        }),
        tag: t(`Unassigned Item`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
      {
        id: OrderSetIssueUnpickableLineReason.MISSING_UOMS,
        title: t(`Ordered Items: Missing UOM`, {
          ns: 'simulation',
        }),
        tag: t(`No UOM`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
      {
        id: OrderSetIssueUnpickableLineReason.UNKNOWN_ITEM,
        title: t(`Ordered Items: Missing in Item Set`, {
          ns: 'simulation',
        }),
        tag: t(`Unknown Item`, { ns: 'simulation' }),
        color: issueColors.unknown,
        textColor: issueColors.unknownText,
        required: true,
      },
      {
        id: OrderSetIssueUnpickableLineReason.UNKNOWN_LOCATIONS,
        title: t(`Ordered items: Assigned to unknown locations`, {
          ns: 'simulation',
        }),
        tag: t(`Unknown Location`, { ns: 'simulation' }),
        color: issueColors.unknown,
        textColor: issueColors.unknownText,
      },
      {
        id: OrderSetIssueUnpickableLineReason.DISABLED_LOCATIONS,
        title: t(`Ordered items: In disabled locations`, {
          ns: 'simulation',
        }),
        tag: t(`Disabled Location`, { ns: 'simulation' }),
        color: issueColors.alert,
        textColor: issueColors.alertText,
      },
    ];
    return _.keyBy(data, i => i.id) as Record<
      OrderSetIssueUnpickableLineReason,
      IssueTypeDescriptor<OrderSetIssueUnpickableLineReason>
    >;
  },
);

export const getUOMIssueDescriptors = _.memoize(
  (t: TFunction<'simulation'>) => {
    return _.keyBy(
      [
        {
          id: ItemSetIssueInvalidUomReason.INVALID_VOLUME,
          title: t(`Items: Missing Volume`, { ns: 'simulation' }),
          tag: t(`No Volume`, { ns: 'simulation' }),
          color: issueColors.alert,
          textColor: issueColors.alertText,
        },
        {
          id: ItemSetIssueInvalidUomReason.INVALID_WEIGHT,
          title: t(`Items: Missing Weight`, { ns: 'simulation' }),
          tag: t(`No Weight`, { ns: 'simulation' }),
          color: issueColors.alert,
          textColor: issueColors.alertText,
        },
      ],
      'id',
    );
  },
);

export const getItemIssueDescriptors = _.memoize(
  (t: TFunction<'simulation'>) => {
    return _.keyBy(
      [
        {
          id: ItemSetIssueInvalidItemReason.NO_UOMS,
          title: t(`Items: Missing UOM`, { ns: 'simulation' }),
          tag: t(`Missing UOM`, { ns: 'simulation' }),
          color: issueColors.alert,
          textColor: issueColors.alertText,
        },
      ],
      'id',
    );
  },
);
