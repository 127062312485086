import Flatten from '@flatten-js/core';
import Konva from 'konva';
import _ from 'lodash';
import React, { useState } from 'react';
import { Circle, Group, KonvaNodeEvents, Path, Text } from 'react-konva';
import { useRecoilState } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  getRestrictionIconSvg,
  iconSizeBase,
  restrictionIconVector,
} from '../../simulation/store/routingPolicy/routing.default';
import { routingPolicyFeatureRestrictions } from '../../simulation/store/routingPolicy/routingPolicy.state';
import { getBoxCenter } from '../viewer/store/viewer.helper';
import { FeatureBox } from './konva.types';

const routingColors = TwTheme.extend.colors.routing;

export type RoutingDirectionFeatureProps = {
  featureId: string;
  title?: string;
  box: FeatureBox;
  initialDirection: [number, number];
  isStaticMode?: boolean;
  rotateRad?: number; // default = Math.PI
};

const RoutingDirectionFeature: React.FC<
  RoutingDirectionFeatureProps
> = props => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [feature, setFeature] = useRecoilState(
    routingPolicyFeatureRestrictions(props.featureId),
  );

  const { rotateRad = Math.PI } = props;
  const initialVector = Flatten.vector(props.initialDirection);
  const currentVector = feature?.direction
    ? Flatten.vector(feature.direction.x, feature.direction.y)
    : null;

  const canRotate =
    feature && !(currentVector.x === 0 && currentVector.y === 0);
  const rotation = canRotate
    ? restrictionIconVector.angleTo(currentVector)
    : null;

  //do not show 'no restriction' icons  in static (read-only) mode
  if (props.isStaticMode && _.isNil(currentVector)) {
    return null;
  }
  const width = props.box.xmax - props.box.xmin;
  const height = props.box.ymax - props.box.ymin;
  const maxDimension = Math.min(width, height);
  const scaleFactorInternal = (0.6 * maxDimension) / iconSizeBase;
  const fillBg = isHovered
    ? routingColors.directionBackgroundHover
    : routingColors.directionBackgroundActive;

  const circleConfig: Konva.CircleConfig = {
    radius: iconSizeBase / 1.75,
    fill: props.isStaticMode
      ? routingColors.directionBackgroundDisabled
      : fillBg,
  };

  const fill = isHovered
    ? routingColors.directionHover
    : routingColors.directionActive;

  const pathConfig: Konva.PathConfig = {
    offsetX: iconSizeBase / 2,
    offsetY: iconSizeBase / 2,
    data: getRestrictionIconSvg(currentVector),
    fill: props.isStaticMode ? routingColors.directionDisabled : fill,
  };

  const [cx, cy] = getBoxCenter(props.box);
  const groupConfig: Konva.NodeConfig = {
    x: cx,
    y: cy,
    scaleX: scaleFactorInternal,
    scaleY: scaleFactorInternal,
    rotation: (180 / Math.PI) * rotation,
  };

  const eventConfig: KonvaNodeEvents = props.isStaticMode
    ? {}
    : {
        onTap: toggleRouteDirection,
        onClick: toggleRouteDirection,
        onMouseEnter: e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
          setIsHovered(true);
        },

        onMouseLeave: e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
          setIsHovered(false);
        },
      };

  function toggleRouteDirection() {
    const resultVector = canRotate
      ? currentVector.rotate(rotateRad).normalize() // Math.PI === 180 deg
      : initialVector;

    const isAllAllowed =
      currentVector &&
      Math.abs(
        Math.round((resultVector.angleTo(initialVector) * 180) / Math.PI) % 360,
      ) === 0;

    setFeature(
      isAllAllowed
        ? null
        : {
            featureId: props.featureId,
            direction: resultVector,
          },
    );
  }
  const captionLength = _.size(props.title) + 1;
  const fontSize = Math.min(
    Math.floor(0.35 * height),
    Math.floor((0.65 * width) / captionLength),
  );
  const dx =
    props.initialDirection[0] *
    (iconSizeBase * scaleFactorInternal + (fontSize * captionLength * 0.5) / 2);

  const dy = props.initialDirection[1] * iconSizeBase * scaleFactorInternal;
  const textConfig: Konva.TextConfig = {
    x: props.box.xmin + dx,
    y: props.box.ymin - dy,
    width,
    height: -height,
    fontSize: fontSize,
    align: 'center',
    verticalAlign: 'middle',
    fill: 'rgba(255,255,255,0.75)',
    scaleY: -1,
    text: props.title,
    listening: false,
  };

  return (
    <>
      <Group {...groupConfig} {...eventConfig}>
        <Circle {...circleConfig} />
        <Path {...pathConfig} />
      </Group>
      <Text {...textConfig} />
    </>
  );
};

export default React.memo(RoutingDirectionFeature);
