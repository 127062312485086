import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../Tw';

type IndicatorSignalStrengthProps = {
  dBm: number;
  viewType: 'bars' | 'wave';
  className?: string;
};

const IndicatorSignalStrength: React.FC<IndicatorSignalStrengthProps> = ({
  dBm,
  viewType,
  className = '',
  ...props
}) => {
  const { t } = useTranslation('feed');

  const signalColors = TwTheme.extend.colors.signal;

  const getSignalStrengthColor = (): string => {
    if (dBm >= -30) return signalColors[100];
    if (dBm >= -50) return signalColors[80];
    if (dBm >= -60) return signalColors[60];
    if (dBm >= -67) return signalColors[40];
    if (dBm >= -75) return signalColors[20];
    if (dBm >= -90) return signalColors[0];
    return signalColors.bar;
  };

  const getSignalBars = (): number => {
    if (dBm >= -50) return 5;
    if (dBm >= -60) return 4;
    if (dBm >= -67) return 3;
    if (dBm >= -75) return 2;
    return dBm > -90 ? 1 : 0;
  };

  const getSignalDescription = (): string => {
    if (dBm >= -30) return t`Perfect`;
    if (dBm >= -50) return t`Excellent`;
    if (dBm >= -60) return t`Good`;
    if (dBm >= -67) return t`Fair`;
    if (dBm >= -75) return t`Weak`;
    if (dBm > -90) return t`Poor`;
    return t`No Signal`;
  };

  const signalBars = getSignalBars();
  const signalColor = getSignalStrengthColor();
  const signalDescription = getSignalDescription();

  // const renderBarsView = () => (
  //   <>
  //     {[...Array(5)].map((_, i) => (
  //       <rect
  //         key={`bar-${i}`}
  //         x={5 * i + 3}
  //         y={24 - 4 * (i + 1)}
  //         width="3"
  //         height={3 * (i + 1)}
  //         fill={i < signalBars ? signalColor : 'lightgrey'}
  //       />
  //     ))}
  //   </>
  // );

  const renderBarsView = (
    svgHeight: number,
    barWidth: number,
    spacing: number,
  ) => {
    // Calculate the maximum height for the bars to ensure they fit within the SVG
    const maxBarHeight = svgHeight - 2; // Subtract 2 to account for a small margin

    // Calculate the height of each individual bar as a fraction of the maximum height
    const barHeights = [...Array(5)].map(
      (_, i) => ((i + 1) / 5) * maxBarHeight,
    );

    return (
      <>
        {barHeights.map((height, i) => (
          <rect
            key={`bar-${i}`}
            x={i * (barWidth + spacing) + spacing} // Calculate x position based on index, width, and spacing
            y={svgHeight - height} // Align to the bottom of the SVG
            width={barWidth}
            height={height}
            fill={
              i < getSignalBars() ? getSignalStrengthColor() : signalColors.bar
            }
          />
        ))}
      </>
    );
  };

  const arcPaths = [
    'M12,20 c0,-8 0,-8 8,0', // Smallest arc
    'M8,20 c4,-12 8,-12 16,0', // Small arc
    'M4,20 c8,-16 16,-16 24,0', // Medium arc
    'M0,20 c12,-24 24,-24 32,0', // Largest arc
  ];

  const renderWaveView = () => (
    <>
      <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames('w-full h-full', className)}
        {...props}
      >
        {arcPaths.map((d, i) => (
          <path
            key={i}
            // d={`M${1 + i * 6},21 c2,-${4 + i * 4} ${10 + i * 4},-${4 + i * 4} ${14},0`}
            d={d}
            stroke={i < signalBars ? signalColor : signalColors.bar}
            strokeWidth="2"
            fill="none"
          />
        ))}
      </svg>
    </>
  );

  return (
    <div
      data-component="IndicatorSignalStrength"
      className={classNames('relative', className)}
      title={`${signalDescription} (${dBm} dBm)`}
    >
      <svg
        viewBox="0 0 32 14"
        preserveAspectRatio="xMidYMid meet"
        aria-hidden="true"
        data-name="IndicatorSignalStrength"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames('w-full h-full')}
        {...props}
      >
        {viewType === 'bars' ? renderBarsView(14, 4, 2) : renderWaveView()}
      </svg>
    </div>
  );
};

export default IndicatorSignalStrength;
