import * as React from 'react';
const LinkBlocksIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkBlocksIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M46.7,4.28c-3.84,0-6.78,2.94-6.78,6.78v105.82c0,3.84,2.94,6.78,6.78,6.78h34.6c3.84,0,6.78-2.94,6.78-6.78V11.07c0-3.84-2.94-6.78-6.78-6.78h-34.6ZM82.65,8.58v110.8h-37.54V8.81l37.54-.23Z"
    />
    <path strokeWidth={0} d="M47.38,97.44h33.24v19.22h-33.24v-19.22Z" />
    <path strokeWidth={0} d="M47.38,75.96h33.24v19.22h-33.24v-19.22Z" />
    <path strokeWidth={0} d="M47.38,11.97h33.24v19.22h-33.24V11.97Z" />
    <path strokeWidth={0} d="M47.38,33.45h33.24v19.22h-33.24v-19.22Z" />
    <path strokeWidth={0} d="M47.38,54.93h33.24v19.22h-33.24v-19.22Z" />
  </svg>
);
export default LinkBlocksIcon;
