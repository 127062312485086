import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import { atom } from 'recoil';

const getKey = (postfix: string) => `warebee-converter-bay-viewer-${postfix}`;

export const converterBayViewerHoveredLocation =
  atom<LayoutBayLocationFragment>({
    key: getKey('hovered-location'),
    default: null,
  });
