import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import {
  formatInteger,
  formatShortenedTimespan,
} from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import InboxZero from '../../components/InboxZero';
import { ButtonSwitchMultiOption } from '../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import * as Icon from '../../components/icons';
import { InputSearch } from '../../components/inputs/InputSearch';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ActionBar } from '../../components/nav/ActionBar';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import FeedKPIStats from '../../feed/components/FeedKPIStats';
import {
  actualityAnalyzedAgentSummary,
  actualityResourcePolicy,
} from '../../feed/store/actuality.state';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import FeedAnalyzedKPIStats from '../components/FeedAnalyzedKPIStats';
import {
  feedAgentsStats,
  feedAgentsStatsSortBy,
  feedAgentsStatsSortDirection,
  feedDisabledAgentStatsFields,
  feedDistinctAgents,
  feedSelectedAgentIds,
  feedStatsAggregateBy,
  feedTotalStats,
} from '../store/feed.state';
import { getFeedAgentsStatsDesc } from '../store/feedStats.default';

const FeedDevicePerformancePanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const agentStatsAll = useRecoilValue(feedAgentsStats);
  const totalStats = useRecoilValue(feedTotalStats);
  const agentAnalyzedStats = useRecoilValue(actualityAnalyzedAgentSummary);
  const [selectedAgents, setSelectedAgents] =
    useRecoilState(feedSelectedAgentIds);
  const disabledStats = useRecoilValue(feedDisabledAgentStatsFields);

  const allAgents = useRecoilValue(feedDistinctAgents);
  const resourcePolicy = useRecoilValue(actualityResourcePolicy);
  const agentMapByResourcePolicy = _.keyBy(resourcePolicy.agents, 'id');
  const agentMapByData = _.keyBy(allAgents, 'agentId');

  const [agentSortBy, setAgentSortBy] = useRecoilState(feedAgentsStatsSortBy);
  const [aggregateBy, setAggregateBy] = useRecoilState(feedStatsAggregateBy);
  const [agentSortDirection, setAgentSortDirection] = useRecoilState(
    feedAgentsStatsSortDirection,
  );

  const disabledStatsSet = new Set(disabledStats);

  const agentsStatsDesc = _.filter(
    getFeedAgentsStatsDesc(t, formatter),
    item => item.category === 'kpi' && !disabledStatsSet.has(item.id),
  );

  const agentsStatsDescMap = _.keyBy(agentsStatsDesc, 'id');

  function toggleSelectAgent(agentId: string) {
    const isReset = selectedAgentsSet.has(agentId);

    setSelectedAgents(
      isReset
        ? _.filter(selectedAgents, a => a !== agentId)
        : [...selectedAgents, agentId],
    );
  }

  function selectAll() {
    setSelectedAgents([]);
  }

  const sortDirectionOptions: ButtonSwitchMultiOption[] = [
    { label: t`Asc` },
    { label: t`Desc` },
  ];

  const sortDirectionDropdownOptions = [
    { label: t('Lowest'), value: 'asc', icon: Icon.SortAscending },
    { label: t('Highest'), value: 'desc', icon: Icon.SortDescending },
  ];

  const sortDirectionSelectedIndex = agentSortDirection === 'asc' ? 0 : 1;

  const selectedAgentsSet = new Set(selectedAgents);
  const showAll = _.isEmpty(selectedAgents);
  const agentsTotalCount = _.size(agentStatsAll);
  const lastUpdated = new Date().getTime();

  const hasData = _.size(agentStatsAll) > 0;
  if (!hasData) {
  }

  const [searchTerm, setSearchTerm] = useState('');
  const filteredResources = agentStatsAll.filter(agent =>
    agent.agentId.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const filteredAgentsTotalCount = filteredResources.length;

  const aggregateOptions = [
    { label: t('Resource'), value: 'agent' },
    { label: t('Type'), value: 'agentType' },
  ];

  return (
    <PanelBody data-component="FeedDevicePerformanceKPIPanel">
      <ScreenTitle
        isSticky
        fixedHeight
        title={t`Performance`}
        subtitle={t`Warehouse`}
        helpNavTo={'live/feed'}
      />
      {!hasData && <InboxZero selfCenter message={t`No events`} />}
      {hasData && (
        <div className="relative h-full">
          <StatGroup>
            <ActionBar
              transparent
              className={classNames(
                'p-2',
                'bg-app-panel-light/40',
                'saturate-120 backdrop-blur',
                'top-header_title sticky',
                'z-header_screen',
              )}
            >
              <DropdownSelector
                className="text-xs"
                value={aggregateBy}
                values={aggregateOptions.map(option => option.value)}
                renderValue={v =>
                  t`Group By: ` +
                  aggregateOptions.find(option => option.value === v)?.label
                }
                valuePrimary
                light
                widthFull
                onChange={(id: 'agent' | 'agentType') => setAggregateBy(id)}
              />
            </ActionBar>
            <div
              className={classNames(
                'flex flex-1 flex-col space-y-0.5 lg:flex-row lg:space-x-1',
              )}
            >
              <Stat
                key={`scorecard-resource-all`}
                inPanelMode
                isPreview
                isSelectable={true}
                isSelected={showAll}
                titleElement={
                  <div className={classNames('flex w-full items-center')}>
                    <span className={classNames('text-xxs opacity-75')}>
                      {formatShortenedTimespan(lastUpdated)}
                    </span>
                  </div>
                }
                classChildren="mt-4"
                value={formatInteger(totalStats.totalLines)}
                unitOfMeasure={t`Total picks`}
                isActionable
                onClick={selectAll}
                subStats={
                  <FeedKPIStats
                    value={totalStats}
                    category={'kpi'}
                    isSelected={showAll}
                  />
                }
              />
            </div>
          </StatGroup>

          {/* <div className={classNames('')}> */}
          <StatGroup
            className="!top-18 sticky"
            title={
              <InputSearch
                placeholder={t('By {{resource}} ({{count}})', {
                  count: filteredAgentsTotalCount,
                  resource:
                    aggregateOptions.find(
                      option => option.value === aggregateBy,
                    )?.label || t('Resource'),
                })}
                value={searchTerm}
                onChange={(value: string) => setSearchTerm(value)}
                className="mb-2 rounded"
                titleMode
                classNameInput="!bg-app-panel-dark/60"
              />
            }
            // title={t('By Resource ({{count}})', { count: agentsTotalCount })}
            helpNavTo={'live/feed'}
          >
            <ActionBar
              transparent
              className={classNames(
                'p-2',
                'bg-app-panel-light/40',
                'saturate-110 backdrop-blur',
                'top-18 sticky',
                'z-header_screen',
              )}
            >
              <DropdownSelector
                className="text-xs"
                value={agentSortBy}
                values={_.map(agentsStatsDesc, d => d.id)}
                renderValue={v =>
                  t`Sort By: ` + (agentsStatsDescMap[v]?.title ?? v)
                }
                valuePrimary
                light
                widthFull
                onChange={id => setAgentSortBy(id)}
              />
              <DropdownSelector
                className={classNames('text-xs', 'ltr:ml-1 rtl:mr-1')}
                DropAlignRight
                value={agentSortDirection}
                values={sortDirectionDropdownOptions.map(
                  option => option.value,
                )}
                renderValue={value =>
                  sortDirectionDropdownOptions.find(
                    option => option.value === value,
                  )?.label || value
                }
                onChange={value =>
                  setAgentSortDirection(value === 'asc' ? 'asc' : 'desc')
                }
                // widthFull
                light
                iconValue={
                  agentSortDirection === 'asc'
                    ? Icon.SortAscending
                    : Icon.SortDescending
                }
                iconClass={classNames('w-4 h-4 xl:w-5 xl:h-5')}
              />
            </ActionBar>
            {_.map(filteredResources, agent => {
              const isSelected = selectedAgentsSet.has(agent.agentId);
              const interval = formatShortenedTimespan(agent.netTime);
              const deviceColors = getQualitativeColor(
                agent.agentId,
                'dimension',
              );
              const analyzedStats = _.filter(
                agentAnalyzedStats,
                stat => stat.agentId === agent.agentId,
              );

              return (
                <Stat
                  key={`scorecard-resource-${agent.agentId}`}
                  inPanelMode
                  isPreview
                  isSelected={isSelected}
                  legendColor={isSelected ? null : deviceColors}
                  color={isSelected ? deviceColors : null}
                  titleElement={
                    <div className={classNames('flex w-full items-center')}>
                      <span className={classNames('flex-1')}>
                        {agent.agentId}
                      </span>
                      <span
                        title={t(`Net Time: {{netTime}}`, {
                          netTime: agent.netTime,
                        })}
                        className={classNames('text-xxs opacity-75')}
                      >
                        {interval}
                      </span>
                    </div>
                  }
                  icon={getAgentIcon(
                    agentMapByResourcePolicy[
                      agentMapByData[agent.agentId]?.agentType ?? ''
                    ]?.resourceTypeId as AgentCategoryId,
                  )}
                  classIcon={classNames('w-9 h-9 xl:w-12 xl:h-12')}
                  value={formatInteger(agent.totalLines)}
                  unitOfMeasure={t`tasks`}
                  isActionable
                  onClick={() => toggleSelectAgent(agent.agentId)}
                  subStats={
                    <>
                      <FeedKPIStats
                        value={agent}
                        category={'kpi'}
                        isSelected={isSelected}
                      />
                      <FeedAnalyzedKPIStats
                        value={analyzedStats}
                        isSelected={isSelected}
                      />
                    </>
                  }
                ></Stat>
              );
            })}
          </StatGroup>
          {/* </div> */}
        </div>
      )}
    </PanelBody>
  );
};

export default FeedDevicePerformancePanel;
