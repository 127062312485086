const MenuBars4LongIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuBars4LongIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M115.43,97.04H12.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h102.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57ZM115.43,77.39H12.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h102.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57ZM115.43,57.75H12.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h102.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57Z"
    />
    <path
      strokeWidth={0}
      d="M115.43,38.11H12.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h102.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57Z"
    />
  </svg>
);
export default MenuBars4LongIcon;
