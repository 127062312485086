import _ from 'lodash';
import { useRecoilCallback } from 'recoil';
import { analyticsAgent } from '../../AnalyticTracker';
import { WizardStepConfig } from '../../common/types';
import {
  viewerSelectedAisleIdAtom,
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdAtom,
  viewerShowLocations,
} from '../../layout/viewer/store/viewer.state';
import { loadBalancingPolicySelectedIdentity } from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import {
  sidebarStateAll,
  sidebarStateAllPatch,
} from '../../store/sidebar.state';
import { swapPolicySelectedIdentity } from '../../swapPolicy/swapPolicy.state';
import { assignmentComplianceSelectedIdentity } from '../store/assignmentCompliance.state';
import { assignmentPolicySelectedIdentity } from '../store/assignmentPolicy.state';
import {
  optimisationAssignmentImplement,
  optimisationSelectedAssignmentDiff,
} from '../store/optimisation.state';
import { resourcePolicySelectedAgentId } from '../store/resourcePolicy.state';
import { routingPolicySelectedIdentity } from '../store/routingPolicy/routingPolicy.state';
import { simulationIssueSelected } from '../store/simulation.issues.state';
import {
  simulationLayoutSelectedOrderId,
  simulationLayoutSelectedWaypointInner,
} from '../store/simulation.layout.state';
import { simulationShowDatasetAsTable } from '../store/simulation.state';
import { SimulationMenuWizardStepId } from '../store/simulation.wizard';
import {
  simulationWizardSelectedStepId,
  simulationWizardStepsNoTitles,
} from '../store/simulation.wizard.state';

export type SelectSimulationWizardStepParams = {
  current?: boolean;
  stepId?: SimulationMenuWizardStepId;
  selectNext?: boolean;
};

function useSelectSimulationWizardStep() {
  const selectStepCallback = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: SelectSimulationWizardStepParams) => {
        const currentStepId = await snapshot.getPromise(
          simulationWizardSelectedStepId,
        );

        const steps = await snapshot.getPromise(simulationWizardStepsNoTitles);
        const sidebarStates = await snapshot.getPromise(sidebarStateAll);

        //set(simulationStepSwitchDelay, true);

        // cleanup selection
        // ## common
        set(viewerSelectedBayIdAtom, null);
        set(viewerSelectedAisleIdAtom, null);
        set(viewerSelectedLocationIdAtom, null);
        set(simulationLayoutSelectedWaypointInner, null);

        set(simulationIssueSelected, null);

        // ## analyze/optimize
        set(simulationLayoutSelectedOrderId, null);

        // ## optimise results
        set(optimisationSelectedAssignmentDiff, null);
        set(optimisationAssignmentImplement, null);

        // ## policies
        set(resourcePolicySelectedAgentId, null);
        reset(assignmentPolicySelectedIdentity);
        set(routingPolicySelectedIdentity, null);
        reset(swapPolicySelectedIdentity);
        reset(loadBalancingPolicySelectedIdentity);

        //compliance
        reset(assignmentComplianceSelectedIdentity);

        //
        set(simulationShowDatasetAsTable, null);

        let stepConfig: WizardStepConfig<string>;

        if (params.current) {
          stepConfig = steps.find(s => s.id === currentStepId);
        }
        if (params.stepId) {
          stepConfig = steps.find(s => s.id === params.stepId);
        }
        if (params.selectNext) {
          const currentStepIndex = _.findIndex(
            steps,
            step => step.id === currentStepId,
          );
          if (currentStepIndex > -1 && currentStepIndex < steps.length) {
            stepConfig = steps[currentStepIndex + 1];
          }
        }
        if (!stepConfig) {
          throw new Error('Cannot change simulation wizard step');
        }
        analyticsAgent?.track('Simulation: ' + stepConfig.title);

        // if (stepConfig.disabled) {
        //   return false;
        // }

        if (stepConfig.id === 'compliance-weight') {
          set(viewerShowLocations, false);
        }
        if (stepConfig.id === 'compliance-size') {
          set(viewerShowLocations, true);
        }
        // if (
        //   stepConfig.id === 'analyse-allocate' ||
        //   stepConfig.id === 'optimise-reassign'
        // ) {
        //   set(analyzeProductMetricSelected, 'reassign-task-type');
        // }
        if (stepConfig.id === 'analyse' || stepConfig.id === 'optimise') {
          set(analyzeProductMetricSelected, 'abc');
        }

        const allPatches = _.reduce(
          stepConfig.sidebar,
          (acc, statePatch, id) => ({
            ...acc,
            [id]: { ...(sidebarStates[id] || {}), ...statePatch },
          }),
          {},
        );
        set(sidebarStateAllPatch, allPatches);
        set(simulationWizardSelectedStepId, stepConfig.id);
      },
  );

  return async (params: SelectSimulationWizardStepParams) => {
    await selectStepCallback(params);
  };
}

export default useSelectSimulationWizardStep;
