import Konva from 'konva';
import React from 'react';
import { Line } from 'react-konva';
import { TwTheme } from '../../../Tw';

const locationColors = TwTheme.extend.colors.location;

export type StaticBorderFeatureProps = {
  points: number[];
  strokeWidth?: number; //default  is 2
};

const strokeWidthDefault = 2;
const dashSpace = 4;
const fps = 16;

const StaticBorderFeature: React.FC<StaticBorderFeatureProps> = props => {
  const strokeWidth = props.strokeWidth ?? strokeWidthDefault;

  const baseConfig: Konva.LineConfig = {
    points: props.points,
    draggable: false,
    strokeScaleEnabled: false,
    perfectDrawEnabled: false,
    closed: true,
    strokeWidth: strokeWidth,
    //fillEnabled: false,
    listening: false,
    fill: 'rgba(0,0,0,0.4)',
  };

  const staticBorderConfig: Konva.LineConfig = {
    ...baseConfig,
    stroke: locationColors.selectedBorderDash,
  };

  return <Line key={`active-feature-border-bg`} {...staticBorderConfig} />;
};

export default StaticBorderFeature;
