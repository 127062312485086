import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import * as Icon from '../../components/icons';
import { ActionBar } from '../../components/nav/ActionBar';
import { IconChevronDirection } from '../../components/nav/IconChevronDirectional';
import HelpNavigate from '../../helpContext/HelpNavigate';
import { HelpIndex } from '../../helpContext/help.default';
import { helpSelectedId } from '../../helpContext/help.state';

const SidebarHelpBreadcrumbs = () => {
  const { t } = useTranslation('app');
  const currentHelpId = useRecoilValue(helpSelectedId);
  const parts = _.split(currentHelpId, '/');

  const sectionTitles = {
    simulation: t`Simulation`,
    designer: t`Designer`,
    importer: t`Importer`,
    dataset: t`Dataset`,
    app: t`App`,
    compliance: t`Compliance`,
    optimise: t`Optimise`,
    analyse: t`Analyse`,
    policies: t`Policies`,
    'policy-storage': t`Storage Policy`,
    'policy-picking': t`Picking Policy`,
    'policy-resource': t`Resource Policy`,
    'policy-routing': t`Routing Policy`,
    'policy-optimisation': t`Optimisation Policy`,
    'policy-stacking': t`Stacking Policy`,
    warehouse: t`Warehouse`,
    workforce: t`Workforce`,
    demo: t`Demo`,
  };

  let prev = '';

  return (
    <ActionBar
      transparent
      className={classNames(
        'bg-sidebar-header/80 saturate-110 backdrop-blur',
        'shadow-xl',
        'z-[100]',
        'sticky top-0',
      )}
    >
      <nav className="flex w-full items-center">
        {/* Hide on Index page */}
        {currentHelpId !== 'index' && (
          <HelpNavigate
            href={'index'}
            className={classNames('flex items-center', 'py-3')}
          >
            <Icon.CircleArrowLeft
              className={classNames('h-4 w-4', 'fill-current', 'mx-2')}
            />
            <span>{t`Help Center`}</span>
          </HelpNavigate>
        )}

        {_.map(parts, (p, i) => {
          const isLast = i + 1 === _.size(parts);

          if (isLast) return;
          const href = (_.isEmpty(prev) ? p : `${prev}/${p}`) as HelpIndex;
          prev = href;
          return (
            <HelpNavigate
              key={href}
              className={classNames('flex items-center', 'py-3')}
              href={href}
            >
              <IconChevronDirection arrowSize="xs" />
              {sectionTitles[p] ?? p}
            </HelpNavigate>
          );
        })}
      </nav>
      {/* <Spacer flexspace /> */}
    </ActionBar>
  );
};

export default SidebarHelpBreadcrumbs;
