import * as React from 'react';
const AxisXyzIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AxisXyzIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M101.69,97.52l-36.69-25.11v-36.42c0-1.66-1.34-3-3-3s-3,1.34-3,3v36.47l-34.76,25.1c-1.34.97-1.65,2.84-.68,4.19.97,1.34,2.84,1.64,4.19.67l34.29-24.76,36.26,24.81c.52.35,1.11.52,1.69.52.96,0,1.9-.46,2.48-1.31.94-1.37.58-3.23-.78-4.17Z"
    />
    <path
      strokeWidth={0}
      d="M59,23.24v4.76c0,1.66,1.34,3,3,3s3-1.34,3-3v-4.76l5.12-5.12c1.17-1.17,1.17-3.07,0-4.24-1.17-1.17-3.07-1.17-4.24,0l-3.88,3.88-3.88-3.88c-1.17-1.17-3.07-1.17-4.24,0-1.17,1.17-1.17,3.07,0,4.24l5.12,5.12Z"
    />
    <path
      strokeWidth={0}
      d="M118.24,106l3.88-3.88c1.17-1.17,1.17-3.07,0-4.24-1.17-1.17-3.07-1.17-4.24,0l-3.88,3.88-3.88-3.88c-1.17-1.17-3.07-1.17-4.24,0-1.17,1.17-1.17,3.07,0,4.24l3.88,3.88-3.88,3.88c-1.17,1.17-1.17,3.07,0,4.24.59.59,1.35.88,2.12.88s1.54-.29,2.12-.88l3.88-3.88,3.88,3.88c.59.59,1.35.88,2.12.88s1.54-.29,2.12-.88c1.17-1.17,1.17-3.07,0-4.24l-3.88-3.88Z"
    />
    <path
      strokeWidth={0}
      d="M20.77,101.14s0-.03.01-.05c.13-.34.21-.71.21-1.09s-.08-.75-.21-1.09c0-.02,0-.03-.01-.05-.3-.74-.89-1.33-1.63-1.63-.01,0-.03,0-.05-.01-.34-.13-.71-.21-1.09-.21H6c-1.66,0-3,1.34-3,3s1.34,3,3,3h4.76l-6.88,6.88c-.28.28-.5.61-.65.98,0,.02,0,.03-.01.05-.13.34-.21.71-.21,1.09s.08.75.21,1.09c0,.02,0,.03.01.05.3.74.89,1.33,1.63,1.63.01,0,.03,0,.05.01.34.13.71.21,1.09.21h12c1.66,0,3-1.34,3-3s-1.34-3-3-3h-4.76l6.88-6.88c.28-.28.5-.61.65-.98Z"
    />
  </svg>
);
export default AxisXyzIcon;
