import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { formatPercent } from '../common/formatHelper';
import Loader from '../components/loaders/Loader';
import {
  DashboardItemStatus,
  DashboardItemStatusType,
} from './store/dashboard.types';

export type DashboardItemStatusProps = {
  className?: string;
  status?: DashboardItemStatus;
  type?: string;
  children?: React.ReactNode;
};

const DashboardItemStatusTag: React.FC<DashboardItemStatusProps> = props => {
  return (
    <div
      data-component="DashboardItemStatusTag"
      aria-label={`status-${props.status.type} ${DashboardItemStatusType.error}`}
      className={classNames(
        'flex items-center rounded px-2 py-0.5 text-xs font-bold ltr:mr-1 rtl:ml-1',
        {
          'border-state-draft text-menu-text border':
            props.status.type === DashboardItemStatusType.draft,
        },
        {
          'text-menu bg-state-analyzed':
            props.status.type === DashboardItemStatusType.progress,
        },
        {
          'text-menu bg-state-optimized':
            props.status.type === DashboardItemStatusType.complete,
        },
        {
          'text-menu bg-state-analyzed':
            props.status.type === DashboardItemStatusType.inuse,
        },
        {
          'text-issues-alertText bg-alerts-error':
            props.status.type === DashboardItemStatusType.error,
        },
        {
          'bg-app-panel text-menu-text uppercase':
            props.status.type === DashboardItemStatusType.data,
        },
        props.className,
      )}
    >
      {props.children}
      {!_.isNil(props.status.progress) && props.status.progress < 100 && (
        <Loader type={'loading-original'} size={20} stroke={10} />
      )}
      <div className={`ltr:ml-1 rtl:mr-1`}>
        <span>{props.status.title}</span>
        <span className={`ltr:ml-2 rtl:mr-2`}>
          {props.status.progress
            ? props.status.progress < 1
              ? `${props.status.progress.toFixed(1)}%`
              : formatPercent(props.status.progress / 100)
            : ''}
        </span>
      </div>
    </div>
  );
};

export default DashboardItemStatusTag;
