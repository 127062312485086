import * as React from 'react';
const GridTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="GridTopIcon"
    {...props}
  >
    <rect width={38} height={38} x={4} y={4} strokeWidth={0} opacity={0.8} />
    <rect width={38} height={38} x={4} y={45} strokeWidth={0} opacity={0.6} />
    <rect width={38} height={38} x={4} y={86} strokeWidth={0} opacity={0.5} />
    <path
      strokeWidth={0}
      d="M44.75,4v120h79V4s-79,0-79,0ZM64.44,23.44c0-.54.33-1.04.83-1.25.5-.21,1.08-.09,1.47.29l19.26,19.26,19.26-19.26c.39-.39.96-.5,1.47-.29.5.21.83.7.83,1.25v11.13c0,.37-.16.73-.43.99l-20.22,18.87c-.52.48-1.32.48-1.84,0l-20.21-18.87c-.27-.26-.43-.61-.43-.99v-11.13ZM64.44,48.78c0-.54.33-1.04.83-1.24.5-.21,1.08-.09,1.47.29l19.26,19.26,19.26-19.26c.39-.39.96-.5,1.47-.29.5.21.83.7.83,1.25v11.13c0,.37-.16.73-.43.99l-20.22,18.87c-.52.48-1.32.48-1.84,0l-20.21-18.87c-.27-.26-.43-.61-.43-.99v-11.13ZM107.56,84.51c0,.37-.16.73-.43.99l-20.22,18.87c-.52.48-1.32.48-1.84,0l-20.21-18.87c-.27-.26-.43-.61-.43-.99v-11.13c0-.54.33-1.04.83-1.25.5-.21,1.08-.09,1.47.29l19.26,19.26,19.26-19.26c.39-.39.96-.5,1.47-.29.5.21.83.7.83,1.25v11.13Z"
    />
  </svg>
);
export default GridTopIcon;
