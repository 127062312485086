import { signOut } from 'aws-amplify/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import { MailTo } from '../../components/actions/MailTo';
import InputText from '../../components/inputs/InputText';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import PanelContainer from '../../containers/PanelContainer';
import Callout from '../../helpContext/Callout';
import { loggedInUser } from '../../store/auth.state';
import SettingsMFASetup from './SettingsMFASetup';

const SettingsAccount: React.FC = () => {
  const { t } = useTranslation('app');
  const user = useRecoilValue(loggedInUser);
  return (
    <PanelContainer
      id="panel-system-username"
      title={t`User Settings`}
      collapsible
      // hasPadding
    >
      <ScreenTitle isSticky subtitle={t`System`} title={t`Account Details`} />
      <Callout panelMode type="suggestion">
        {t`To change the settings, please contact support`}
        <MailTo
          email="support+gdpr@warebee.com"
          subject="[WAREBEE] Account Settings Change Request"
          body={`Hello, Please update my account:`}
        >
          <div>{t`support@warebee.com`}</div>
        </MailTo>
      </Callout>
      <section className="mb-4 w-full space-y-8 p-4 pb-0">
        <fieldset>
          <InputText
            value={user.firstName}
            label={t`First Name`}
            name="firstName"
            isDisabled
          />
        </fieldset>

        <fieldset>
          <InputText
            value={user.lastName}
            label={t`Last Name`}
            name="lastName"
            isDisabled
          />
        </fieldset>

        <fieldset>
          <InputText
            value={user.email}
            label={t`Email`}
            name="email"
            isDisabled
          />
        </fieldset>
      </section>
      <ScreenTitle isSticky subtitle={t`System`} title={t`Account Security`} />
      <section className="mb-4 w-full space-y-8 p-4 pb-0">
        <fieldset>
          <label className="text-menu-200 mb-2 mt-3">{t`Logout`}</label>
          <Button
            className="backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
            buttonSize={`lg`}
            label={t`Logout`}
            full
            buttonType={`primary`}
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            onPress={() => signOut()}
          />
        </fieldset>
        <fieldset>
          <label className="text-menu-200 mb-2 mt-3">{t`Password`}</label>
          <Button
            className="backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
            buttonSize={`lg`}
            label={t`Change Password`}
            full
            buttonType={`primary`}
            hasIconAfter
            buttonIcon={<IconArrowDir />}
          />
        </fieldset>

        <SettingsMFASetup />
      </section>
      <ScreenTitle isSticky subtitle={t`System`} title={t`Business Details`} />
      {/* <HelperMessage hasPadding className={`mt-8 mb-4`}>
      <InboxZero message={t`Please contact support`} />
    </HelperMessage> */}

      <Callout
        panelMode
        type="suggestion"
        // iconAfter
        // icon={Icon.ArrowRightBottom}
      >
        <InboxZero message={t`Please contact support`} />
      </Callout>
    </PanelContainer>
  );
};

export default SettingsAccount;
