import {
  BayFeatureFragment,
  BayTypeModel,
} from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { KonvaNodeEvents, Line, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';
import { getBox, getPoints } from '../viewer/store/viewer.helper';

const bayColors = TwTheme.extend.colors.bay;

export type BayProps = {
  bay: BayFeatureFragment;
  bayType: BayTypeModel;
  isStaticMode?: boolean;
  isUnreachable?: boolean;
  hideTitle?: boolean;
  showAsBackground?: boolean;
  color?: string;
  textColor?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

function getFillColor(props: BayProps): string {
  if (props.color) return props.color;
  if (props.isUnreachable) return bayColors.unreachable;
  if (props.bay.navigable) return bayColors.navigable;

  return bayColors.DEFAULT;
}

const BayFeature: React.FC<BayProps> = props => {
  const { bay } = props;
  function onBayClick(evt: Konva.KonvaEventObject<MouseEvent>) {
    props.onClick && props.onClick();
  }

  const showDetailed = !_.isNil(bay.innerShape);
  const borderPoints = showDetailed ? getPoints(bay.shape) : [];
  const mainShapePoints = showDetailed
    ? getPoints(bay.innerShape)
    : getPoints(bay.shape);
  const bayBorderConfig: Konva.LineConfig = {
    id: `inner-shape-${bay.id}`,
    points: _.flatten(borderPoints),
    fill: bayColors.border,
    strokeScaleEnabled: true,
    perfectDrawEnabled: false,
    listening: false,
    closed: true,
    //opacity: props.showAsBackground ? 0.2 : 1,
  };

  const box = getBox(bay.shape);

  const bayShapeConfig: Konva.LineConfig = {
    points: _.flatten(mainShapePoints),
    id: bay.id,
    draggable: false,
    fill: getFillColor(props),
    strokeWidth: 0,
    perfectDrawEnabled: false,
    closed: true,
    opacity: props.showAsBackground ? 0.8 : 1,
    listening: !props.isStaticMode,
  };

  let eventConfig: KonvaNodeEvents = {};

  if (!props.isStaticMode) {
    eventConfig = {
      ...eventConfig,
      onClick: onBayClick,
      onTap: onBayClick,
      onMouseEnter: e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        props.onMouseOver && props.onMouseOver();
      },

      onMouseLeave: e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        props.onMouseOut && props.onMouseOut();
      },
    };
  }

  const boxWidth = box.xmax - box.xmin;
  const boxHeight = box.ymax - box.ymin;
  const captionLength = (bay.title || bay.id).length || 1;
  const fontSize = Math.min(
    Math.floor(0.85 * boxHeight),
    Math.floor(boxWidth / captionLength),
  );
  return (
    <>
      {showDetailed && <Line {...bayBorderConfig} />}
      <Line {...bayShapeConfig} {...eventConfig} />

      {props.hideTitle ? null : (
        <Text
          x={box.xmin}
          y={box.ymin}
          width={boxWidth}
          height={-boxHeight}
          fontSize={fontSize}
          align="center"
          verticalAlign="middle"
          fill={props.textColor ?? bayColors.text}
          scaleY={-1}
          text={bay.title || bay.id}
          listening={false}
          strokeEnabled={false}
        />
      )}
    </>
  );
};

export default BayFeature;
