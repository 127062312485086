import * as React from 'react';
const WarehouseTiresIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseTiresIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M42.38,57.69c-.11.11-.22.16-.38.16s-.27-.05-.38-.16c-.27-.22-.27-.54-.05-.76l3.19-3.46c.22-.27.54-.27.76-.05s.22.54.05.81l-3.19,3.46Z"
    />
    <path
      strokeWidth={0}
      d="M40.17,71.84c-.11.05-.22.11-.32.11-.16,0-.32-.05-.43-.22l-2.59-3.94c-.16-.27-.11-.59.16-.76s.59-.11.76.16l2.59,3.94c.16.22.05.59-.16.7Z"
    />
    <path
      strokeWidth={0}
      d="M40.33,62.44l-3.35-3.35c-.22-.22-.22-.54,0-.76s.54-.22.76,0l3.35,3.35c.22.22.22.54,0,.76-.11.11-.27.16-.38.16s-.27-.05-.38-.16Z"
    />
    <path
      strokeWidth={0}
      d="M41.03,52.77c-.11.16-.27.22-.43.22-.11,0-.22-.05-.32-.11-.22-.16-.27-.54-.11-.76l2.75-3.84c.16-.22.54-.27.76-.11s.27.54.11.76l-2.76,3.84Z"
    />
    <path
      strokeWidth={0}
      d="M40.65,57.74c-.11.11-.22.16-.38.16s-.27-.05-.38-.16l-3.46-3.19c-.27-.22-.27-.54-.05-.76.22-.27.54-.27.76-.05l3.46,3.24c.27.22.27.54.05.76Z"
    />
    <path
      strokeWidth={0}
      d="M35.14,44.72c-.05,0-.16,0-.22-.05l-4.27-1.94c-.27-.11-.38-.43-.27-.7.11-.27.43-.38.7-.27l4.27,1.94c.27.11.43.43.27.7-.11.22-.27.32-.49.32Z"
    />
    <path
      strokeWidth={0}
      d="M37.36,48.99c-.11,0-.16,0-.27-.05l-4.05-2.38c-.27-.16-.38-.49-.22-.76.16-.27.49-.38.76-.22l4.05,2.38c.27.11.32.43.22.76-.11.16-.32.27-.49.27Z"
    />
    <path
      strokeWidth={0}
      d="M38.92,53.26c-.11,0-.22-.05-.32-.11l-3.84-2.76c-.22-.16-.27-.54-.11-.76.16-.22.54-.27.76-.11l3.84,2.76c.22.22.27.54.11.76-.11.16-.27.22-.43.22Z"
    />
    <path
      strokeWidth={0}
      d="M42.76,62.49c-.11.11-.27.16-.38.16s-.27-.05-.38-.16c-.22-.22-.22-.54,0-.76l3.35-3.35c.22-.22.54-.22.76,0,.22.22.22.54,0,.76l-3.35,3.35Z"
    />
    <path
      strokeWidth={0}
      d="M40.6,67.36c-.16,0-.32-.11-.43-.22l-3.03-3.62c-.22-.22-.16-.54.05-.76.22-.22.54-.16.76.05l3.03,3.62c.16.22.16.54-.05.81-.11.05-.22.11-.32.11Z"
    />
    <path
      strokeWidth={0}
      d="M36.06,71.57c.27-.11.59-.05.76.22l2.38,4.05c.11.27.05.59-.22.76-.11.05-.16.05-.27.05-.22,0-.38-.11-.49-.27l-2.38-4.05c-.11-.27-.05-.59.22-.76Z"
    />
    <path
      strokeWidth={0}
      d="M24.5,77.13c.27,0,.43.16.43.43,0,.27-.22.43-.43.43h0c-2.54,0-4.81-3.62-5.73-9.35-.05-.22.11-.43.32-.49.27-.05.43.11.49.32.86,5.19,2.81,8.64,4.92,8.64Z"
    />
    <path
      strokeWidth={0}
      d="M34.66,75.57c.27-.11.59,0,.7.27l2.05,4.21c.11.27,0,.59-.27.7-.05.05-.16.05-.22.05-.22,0-.38-.11-.49-.32l-2.05-4.21c-.16-.27-.05-.59.27-.7Z"
    />
    <path
      strokeWidth={0}
      d="M20.55,63.25c0-6.65,1.78-12.05,3.94-12.05s3.94,5.4,3.94,12.05-1.78,12.05-3.94,12.05-3.94-5.4-3.94-12.05Z"
    />
    <path
      strokeWidth={0}
      d="M42.33,67.52c-.16,0-.32-.11-.43-.22-.22-.22-.16-.54.05-.76l3.62-3.03c.22-.22.54-.16.76.05.16.22.16.59-.05.81l-3.62,3.03c-.11.05-.22.11-.32.11Z"
    />
    <path
      strokeWidth={0}
      d="M40.33,77.03c-.22,0-.38-.11-.49-.27-.11-.27-.05-.59.22-.76l4.05-2.38c.27-.11.59-.05.76.22s.05.59-.22.76l-4.05,2.38c-.11.05-.16.05-.27.05Z"
    />
    <path
      strokeWidth={0}
      d="M64.5,13.87L.84,30.3v62.58h126.32V30.3l-62.66-16.43ZM25.63,84.7c5.13-1.62,9.02-10.64,9.02-21.39,0-1.03-.05-2.11-.11-3.13-.16-2.54-.59-4.92-1.19-7.19-.54-2-1.19-3.78-2-5.35-1.67-3.24-3.84-5.29-6.1-5.83,1.89-.22,5.89-.54,10.32-.11.49.05.97.11,1.46.16,0,.05-.05.05-.05.11l-.65,1.46c-.11.27,0,.59.27.7.05.05.16.05.22.05.22,0,.38-.11.49-.32l.65-1.46c.05-.16.05-.32,0-.43.16,0,.32.05.43.05h-.11c1.03.16,2.05.7,2.97,1.46-.11.05-.22.11-.27.22l-2.38,4.05c-.16.27-.05.59.22.76.11.05.16.05.27.05.16,0,.38-.11.49-.27l2.38-4.05c.05-.05.05-.11.05-.16,3.46,3.46,5.89,10.81,5.89,19.23,0,9.08-2.75,16.8-6.59,19.94.05-.11,0-.27-.05-.43-.16-.27-.49-.32-.76-.16l-2.32,1.46c-.22.11-.27.38-.22.59h-.49s-2.32,0-2.32,0c.05-.16.05-.32,0-.49l-2.05-3.3c-.16-.27-.49-.32-.76-.16s-.32.49-.16.76l1.84,3.19h-8.37ZM14.07,63.2c0-10.32,3.57-18.96,8.38-20.91h0c.38-.11.76-.16.97-.16,2.59,0,5.02,2.05,6.92,5.73.81,1.51,1.46,3.3,2,5.24.59,2.22.97,4.59,1.13,7.08.05,1.03.11,2.05.11,3.08,0,11.67-4.54,21.12-10.16,21.12h-.54c-4.97-1.62-8.81-10.48-8.81-21.18Z"
    />
    <path
      strokeWidth={0}
      d="M41.46,72.27c-.16,0-.32-.05-.43-.22-.16-.27-.11-.59.16-.76l3.94-2.59c.27-.16.59-.11.76.16.16.22.11.54-.16.7l-3.94,2.59c-.11.05-.22.11-.32.11Z"
    />
    <path
      strokeWidth={0}
      d="M38.33,80.32l4.21-2.05c.27-.11.59,0,.7.27s0,.59-.27.7l-4.21,2.05c-.05.05-.16.05-.22.05-.22,0-.38-.11-.49-.32-.11-.27,0-.59.27-.7Z"
    />
  </svg>
);
export default WarehouseTiresIcon;
