import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type PanelBodyProps = {
  children?: React.ReactNode;
  className?: string;
  hasPadding?: boolean;
  hidden?: boolean;
};

export const PanelBody = ({
  children,
  className,
  hasPadding,
  hidden,
}: PanelBodyProps) => {
  const [id] = useState<string>(_.uniqueId());
  const [showPanelBody, setShowPanelBody] = useState(true);

  return (
    showPanelBody && (
      <section
        data-component="PanelBody"
        id={id}
        className={classNames(
          'relative',
          { 'p-2 pb-10': hasPadding },
          { hidden: hidden },
          className,
        )}
      >
        {children}
      </section>
    )
  );
};
