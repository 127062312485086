import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyzeComponentBaseProps } from '../../analyze/analyze.types';
import WorkforceForecastPanel from './workforce/forecast/WorkforceForecastPanel';

const SimulationAnalyzePanelWorkforce: React.FC<
  AnalyzeComponentBaseProps
> = props => {
  const { t } = useTranslation('simulation');

  return (
    <>
      <WorkforceForecastPanel {...props} />
      {/* <WorkforceSummaryPanel analyzeResult={analyzeResult} /> */}
    </>
  );
};

export default SimulationAnalyzePanelWorkforce;
