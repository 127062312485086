import * as React from 'react';
const ObjectDepthIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectDepthIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="81.91 72.17 43.21 90.43 43.21 49.35 81.75 33.75 81.91 72.17"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="43.26 53.43 5.35 70.48 5.1 31.41 43.16 16.98 43.26 53.43"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="43.21 90.43 5.35 70.48 5.1 31.41 43.21 49.35 43.21 90.43"
      opacity={0.7}
    />
    <polygon
      strokeWidth={0}
      points="43.21 49.35 5.1 31.41 43.16 16.98 81.75 33.75 43.21 49.35"
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="125.2 92.54 84.18 111.9 84.18 68.36 125.02 51.82 125.2 92.54"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="84.18 111.9 43.26 90.22 43.26 49.32 84.18 68.36 84.18 111.9"
      opacity={0.7}
    />
    <polygon
      strokeWidth={0}
      points="84.18 68.36 43.27 49.23 81.75 33.75 125.02 51.82 84.18 68.36"
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="40.56 119.57 54.4 116.56 16.9 98.75 20.58 110.09 12.01 106.02 7.01 90.63 26.17 86.45 34.28 90.3 20.44 93.31 57.95 111.11 54.26 99.78 62.84 103.85 67.84 119.24 48.67 123.42 40.56 119.57"
    />
  </svg>
);
export default ObjectDepthIcon;
