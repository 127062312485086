import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { Button } from '../../../../components/actions/Button';
import { Container } from '../../../../components/layout/ContainerFlex';
import { Spacer } from '../../../../components/layout/Spacer';
import { ActionBar } from '../../../../components/nav/ActionBar';
import { IconArrowDir } from '../../../../components/nav/IconArrowDirectional';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../../containers/SidebarContainer';
import {
  converterLayoutBuilder,
  converterWizardStepId,
} from '../store/converter.state';
import { wizardSteps } from '../store/converter.wizard';
import StepAisleSetup from '../wizard/StepAisleSetup';
import StepAislesAccessAisles from '../wizard/StepAislesAccessAisles';
import StepAislesMerge from '../wizard/StepAislesMerge';
import StepAislesOrder from '../wizard/StepAislesOrder';
import StepAislesCustom from '../wizard/StepAislesSpacers';
import StepAislesTunnel from '../wizard/StepAislesTunnel';
import StepAreaSetup from '../wizard/StepAreaSetup';
import StepAreasConnect from '../wizard/StepAreasConnect';
import StepAreasDelete from '../wizard/StepAreasDelete';
import StepAreasOrganize from '../wizard/StepAreasOrganize';
import StepAreasPosition from '../wizard/StepAreasPosition';
import StepBaySetup from '../wizard/StepBaySetup';
import StepDesigner from '../wizard/StepDesigner';
import StepLocationsEditor from '../wizard/StepEditLocations';
import StepFeaturesSetup from '../wizard/StepFeaturesSetup';
import StepGettingStarted from '../wizard/StepGettingStarted';
import StepDefaultSizes from '../wizard/StepLocationDefaultSizes';
import StepLocationSetup from '../wizard/StepLocationSetup';
import StepMeasurementSystem from '../wizard/StepMeasurementSystem';
import StepFinalize from '../wizard/StepNavigation';
import StepRename from '../wizard/StepRename';
import StepSetupFloors from '../wizard/StepSetupFloors';
import StepDataSource from '../wizard/StepSource';
import StepSpacers from '../wizard/StepSpacers';
import StepStartEndPoints from '../wizard/StepStartEndPoints';
import ConverterSavePanel from './ConverterSavePanel';

export const ConverterWizardStepSidebarCmp: React.FC = () => {
  const { t } = useTranslation('designer');
  const [wizardStepId, setWizardStepId] = useRecoilState(converterWizardStepId);
  const layoutBuilder = useRecoilValue(converterLayoutBuilder);

  const steps = wizardSteps(t);
  const totalSteps = steps.length;
  const current = _.findIndex(steps, s => s.id === wizardStepId);
  const isLastStep = totalSteps === current + 1;
  const next = () => {
    if (current < totalSteps - 1) {
      setWizardStepId(steps[current + 1].id);
    }
  };
  if (!layoutBuilder) {
    return <LoadingIndicator selfCenter message={t`Loading layout settings`} />;
  }

  const getContent = () => {
    switch (wizardStepId) {
      case 'data-source':
        return <StepDataSource />;
      case 'getting-started':
        return <StepGettingStarted />;
      case 'change-name':
        return <StepRename />;
      case 'measurement-system':
        return <StepMeasurementSystem />;
      case 'setup-floors':
        return <StepSetupFloors />;
      case 'areas-setup':
        return <StepAreaSetup />;
      case 'organize-areas':
        return <StepAreasOrganize />;
      case 'delete-areas':
        return <StepAreasDelete />;
      case 'position-areas':
        return <StepAreasPosition />;
      case 'aisles-bays-setup':
        return <StepFeaturesSetup />;
      case 'aisles-setup':
        return <StepAisleSetup />;
      case 'merge-aisles':
        return <StepAislesMerge />;
      case 'order-aisles':
        return <StepAislesOrder />;
      case 'access-aisles':
        return <StepAislesAccessAisles />;
      case 'tunnel-aisles':
        return <StepAislesTunnel />;
      case 'custom-aisles':
        return <StepAislesCustom />;
      case 'default-sizes':
        return <StepDefaultSizes />;
      case 'location-setup':
        return <StepLocationSetup />;
      case 'bay-setup':
        return <StepBaySetup />;
      case 'resize-align':
        return <StepLocationsEditor />;
      case 'spacers':
        return <StepSpacers />;
      case 'finalize':
        return <StepFinalize />;
      case 'connect-areas':
        return <StepAreasConnect />;
      case 'define-starting-points':
        return <StepStartEndPoints />;
      case 'designer':
        return <StepDesigner />;
    }
  };
  return (
    <>
      <Container col hasOverflowY>{getContent()}</Container>
      <ActionBar>
        <Spacer flexspace />
        {isLastStep ? (
          <ConverterSavePanel />
        ) : (
          <Button
            buttonType="primary"
            label={t`Next`}
            onPress={next}
            hasIconAfter
            buttonIcon={<IconArrowDir />}
          />
        )}
      </ActionBar>
    </>
  );
};

export default () => {
  const { t } = useTranslation('designer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-converter-wizard-step',
    title: t`Layout Setup Helper`,
    size: 'md',
  };

  return (
    <SidebarContainer {...containerProps}>
      <ConverterWizardStepSidebarCmp />
    </SidebarContainer>
  );
};
