import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import RoutingPolicyAgentEditor from '../../routingPolicy/RoutingPolicyAgentEditor';
import RoutingPolicyNavigationEditor from '../../routingPolicy/RoutingPolicyNavigationEditor';
import WaypointPolicyEditorPanel from '../../waypointPolicy/WaypointPolicyEditorPanel';
import { routingPolicySelectedIdentity } from '../store/routingPolicy/routingPolicy.state';
import { simulationRoutingTabKey } from '../store/simulation.wizard.state';
import { waypointPolicySelectedIdentity } from '../store/waypointPolicy/waypointPolicy.state';

const RoutingPolicyEditorSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const tab = useRecoilValue(simulationRoutingTabKey);
  const selectedIdentityRoutingPolicy = useRecoilValue(
    routingPolicySelectedIdentity,
  );
  const selectedIdentityWaypointPolicy = useRecoilValue(
    waypointPolicySelectedIdentity,
  );

  const showRoutingAgents =
    tab === 'tab-routing-policy' &&
    selectedIdentityRoutingPolicy?.isRoutingAgentSelected;
  const showRoutingNavigation =
    tab === 'tab-routing-policy' &&
    selectedIdentityRoutingPolicy?.isRoutingConfigurationSelected;

  const showWaypointFilters =
    tab === 'tab-waypoint-policy' &&
    selectedIdentityWaypointPolicy?.locationFilterId;

  const showAny =
    showRoutingAgents || showRoutingNavigation || showWaypointFilters;

  if (!showAny) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Select policy rule to apply restrictions`}
      />
    );
  }

  return (
    <ContainerScroll hasOverflowY className="flex flex-col">
      {showRoutingAgents && <RoutingPolicyAgentEditor />}
      {showRoutingNavigation && <RoutingPolicyNavigationEditor />}
      {showWaypointFilters && <WaypointPolicyEditorPanel />}
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-routing-policy-editor',
    title: t`Routing Policy Params`,
    loadingMessage: t`Loading...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <RoutingPolicyEditorSidebar />
    </SidebarContainer>
  );
};
