import * as React from 'react';
const AisleAccessBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AisleAccessBottomIcon"
    {...props}
  >
    <rect
      width={21.27}
      height={87.82}
      x={56.03}
      y={3.21}
      strokeWidth={0}
      opacity={0.3}
    />
    <rect
      width={30.22}
      height={120.45}
      x={48.96}
      y={49.66}
      strokeWidth={0}
      transform="translate(173.96 45.82) rotate(90)"
    />
    <rect width={21.27} height={27.91} x={4.04} y={63.12} strokeWidth={0} />
    <rect width={21.27} height={27.91} x={4.04} y={33.16} strokeWidth={0} />
    <rect width={21.27} height={27.91} x={4.04} y={3.21} strokeWidth={0} />
    <rect width={21.27} height={27.91} x={30.04} y={63.12} strokeWidth={0} />
    <rect width={21.27} height={27.91} x={30.04} y={33.16} strokeWidth={0} />
    <rect width={21.27} height={27.91} x={30.04} y={3.21} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M102.14,87.37c-.09.09-.18.17-.29.24-.05.03-.1.05-.15.08-.06.03-.11.07-.18.09-.06.03-.13.04-.19.06-.05.02-.1.04-.16.05-.24.05-.49.05-.74,0-.05-.01-.11-.03-.16-.05-.06-.02-.13-.03-.19-.06-.06-.03-.12-.06-.18-.09-.05-.03-.1-.05-.15-.08-.1-.07-.2-.15-.29-.24l-15.07-15.07c-.74-.74-.74-1.93,0-2.66.37-.37.85-.55,1.33-.55s.96.18,1.33.55l11.85,11.85v-28.41c0-1.04.84-1.88,1.88-1.88s1.88.84,1.88,1.88v28.41s11.85-11.85,11.85-11.85c.37-.37.85-.55,1.33-.55s.96.18,1.33.55c.74.74.74,1.93,0,2.66l-15.07,15.07Z"
    />
  </svg>
);
export default AisleAccessBottomIcon;
