import { MappingMeasuresValuesType } from '@warebee/shared/import-converter';
import classNames from 'classnames';
import { t } from 'i18next';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import { cn } from '../../../common/utils';
import { ColumnConfig } from '../../../components/DatasetTable';
import { Button } from '../../../components/actions/Button';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import * as Icon from '../../../components/icons';
import { IconArrowDirectional } from '../../../components/nav/IconArrowDirectional';
import HelpNavigate from '../../../helpContext/HelpNavigate';
import Tag from '../../../helpContext/Tag';
import { TagDataType } from '../../../helpContext/TagDataType';
import { TagRequired } from '../../../helpContext/TagRequired';
import {
  changeMappingColumn,
  changeMappingMeasure,
} from '../../store/import.helper';
import {
  importMappingSettingsByType,
  importParsedFile,
  importPreviewRowIndex,
  importTypeCurrent,
} from '../../store/import.state';
import { ImportType, MappingFieldSettings } from '../../store/import.types';
import useApplyMappingSettings from '../../useApplyMappingSettings';
import ImporterTableMeasureEditor from './ImporterTableMeasureEditor';
import { HelpIndex } from '../../../helpContext/help.default';

export type MappingTableConfigParams = {
  type: ImportType;
  withErrors: boolean;
};

type MapFieldProps = {
  field: string;
  data: string;
};

export function getHelpFieldIndex(
  importType: ImportType,
  fieldName: string,
): string {
  // Normalize the field name (convert to lowercase, replace spaces with hyphens)
  const normalizedFieldName = fieldName.toLowerCase().replace(/\s+/g, '-');

  // Construct the help index path
  return `dataset/field-guide/${normalizedFieldName}`;
}

const MapField: React.FC<MapFieldProps> = props => {
  return (
    <div data-component="MapField" className={cn('flex w-full flex-col')}>
      <div className={cn('flex-1', 'text-base', 'ltr:mr-2 rtl:ml-2')}>
        {props.field}
      </div>
      <div
        className={cn(
          'mt-2',
          'flex-1',
          'opacity-75',
          'text-base',
          'px-2 py-1',
          'rounded',
          'bg-app-panel-dark/75',
          'text-menu-text',
        )}
      >
        {props.data}
      </div>
    </div>
  );
};

type MapFieldSelectProps = {
  columnName: string;
  mappingField: MappingFieldSettings<Object>;
  headerRow: string[];
  previewObject: Record<string, any>;
  onMappingChange: (columnName: string) => void;
};

const MapFieldSelect: React.FC<MapFieldSelectProps> = props => {
  const [filter, setFilter] = useState<string>('');
  const isMapped = !_.isNil(props.columnName);
  const isError = !isMapped && !props.mappingField.optional;
  const isWarning = !isMapped && props.mappingField.optional;
  const isUnmappedRequired = !isMapped && !props.mappingField.optional;
  const isUnmappedOptional = !isMapped && props.mappingField.optional;

  const filterLower = filter.toLocaleLowerCase();
  const values: string[] = _.filter(
    props.headerRow,
    (c, index) =>
      c.toLowerCase().includes(filterLower) ||
      !!props.previewObject[c]
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterLower),
  );

  return (
    <div className={cn('flex w-full flex-row')}>
      <div className={cn('flex w-full')}>
        <DropdownSelector
          className={classNames('w-full rounded', {
            'mapped bg-alerts-okay': isMapped,
            'isUnmappedRequired bg-alerts-error': isUnmappedRequired,
            'isUnmappedOptional bg-alerts-unmapped': isUnmappedOptional,
          })}
          classNameValue={classNames('ltr:pl-2 rtl:pr-2')}
          widthFull
          values={values}
          value={props.columnName}
          hasAsyncSearch={true}
          onAsyncSearch={v => setFilter(v)}
          renderValueSelected={f =>
            isUnmappedRequired
              ? t`--- UNMAPPED (Required) ---`
              : isUnmappedOptional
                ? t`--- UNMAPPED (Optional) ---`
                : f
          }
          renderValue={field => (
            <MapField field={field} data={props.previewObject?.[field]} />
          )}
          onChange={field => props.onMappingChange(field)}
        />
      </div>
      {!isUnmappedOptional && (
        <Button
          className={cn(
            'group',
            'rounded',
            'flex flex-1 flex-col items-center justify-center',
            'h-8 w-8',
            'bg-app-panel/50',
            'hover:bg-menu-hover hover:text-menu-active-text',
            'p-2',
          )}
          buttonType="secondary"
          onPress={() => props.onMappingChange(null)}
        >
          <Icon.Close className={cn('h-3 w-3 xl:h-4 xl:w-4', 'fill-current')} />
        </Button>
      )}
    </div>
  );
};

function useMappingTableConfig(
  params: MappingTableConfigParams,
): ColumnConfig<MappingFieldSettings<Object>>[] {
  const { t } = useTranslation('importer');
  const formatter = useFormatter();
  const previewRowIndex = useRecoilValue(importPreviewRowIndex);
  const importType = useRecoilValue(importTypeCurrent);
  const mappingSettings = useRecoilValue(
    importMappingSettingsByType(params.type),
  );
  const applyMappingChange = useApplyMappingSettings();
  const parsedFile = useRecoilValue(importParsedFile);
  const { data, fields } = parsedFile;

  const previewRow =
    data && previewRowIndex < data.length ? data[previewRowIndex] : null;

  function onMappingChange(
    targetColumnName: string,
    field: MappingFieldSettings<Object>,
  ) {
    const newMapping = changeMappingColumn({
      mapping: mappingSettings,
      schemaField: field.name,
      columnName: targetColumnName,
    });
    applyMappingChange({ mappingSettings: newMapping, skipValidation: true });
  }

  function onMeasureChange(
    measureValue: MappingMeasuresValuesType,
    field: MappingFieldSettings<Object>,
  ) {
    const newMapping = changeMappingMeasure({
      mapping: mappingSettings,
      schemaField: field.name,
      measureValueType: measureValue,
    });
    applyMappingChange({ mappingSettings: newMapping });
  }

  // function getHelpIndex(type: string, field: string) {
  //   switch (importType) {
  //     case 'items':
  //       return 'dataset/items/items-field-guide';
  //     case 'orders':
  //       return 'dataset/items/items-field-guide';
  //     default:
  //       return 'index';
  //   }
  // }
  const columnsConfig: ColumnConfig<MappingFieldSettings<Object>>[] = [
    {
      field: 'title',
      title: t`WareBee field`,
      isHeader: true,
      render: (title: string, rowData) => {
        const type = typeof rowData.type === 'string' ? rowData.type : 'string'; // TODO: Handle Enum type properly
        return (
          <div className={cn('my-1 flex items-center')}>
            <span className={classNames('text-menu-text/50 text-xxs w-6')}>
              {rowData.rowNumber}
            </span>

            <HelpNavigate href={getHelpFieldIndex(importType, rowData.name) as HelpIndex} />
            <TagRequired optional={rowData.optional} />
            <div className={cn('flex-1 text-end text-lg')}>{title}</div>
            <TagDataType>{type}</TagDataType>
          </div>
        );
      },
    },

    {
      field: 'columnName',
      title: ``,
      render: (title: string, rowData) => {
        return (
          <div className={cn('flex w-full items-center')}>
            <IconArrowDirectional type="MappingHelper" direction="Back" />
          </div>
        );
      },
    },
    {
      field: 'columnName',
      title: `Source Field`,
      render: (columnName: string, mappingField) => {
        return (
          <MapFieldSelect
            key={`mapping-select-${mappingField.type}`}
            columnName={columnName}
            headerRow={fields}
            mappingField={mappingField}
            previewObject={previewRow}
            onMappingChange={columnName =>
              onMappingChange(columnName, mappingField)
            }
          />
        );
      },
    },
    {
      field: 'measureValue',
      title: 'Unit',
      render: (measureValue: MappingMeasuresValuesType, mappingField) => (
        <ImporterTableMeasureEditor
          measureValue={measureValue}
          schemaField={mappingField}
          onChange={measure => onMeasureChange(measure, mappingField)}
        />
      ),
    },
    {
      field: 'uniqErrorCount',
      title: t`Errors`,
      hiddenInBrowser: !params.withErrors,
      render: (uniqErrorCount, rawData) => {
        if (uniqErrorCount === 0) return null;
        return (
          <div className="flex">
            {/* <Tag classType="bg-alerts-error">{rawData['uniqErrorCount']}</Tag> */}

            <Tag classType="bg-alerts-error">
              <span className={cn('ltr:mr-1 rtl:mr-1')}>{`Errors:`}</span>
              {rawData['totalErrorCount']}
            </Tag>
          </div>
        );
      },
    },
  ];

  return columnsConfig;
}
export default useMappingTableConfig;
