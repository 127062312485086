import { AllocateJobEngine } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../components/actions/DropdownSelector';
import { allocateJobEngineState } from '../simulation/store/allocation/allocation.state';

export const AllocateRunOptionsSelector: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [engineAllocate, setEngineAllocate] = useRecoilState(
    allocateJobEngineState,
  );

  console.debug('engineAllocate:', engineAllocate);

  const allocateRunOptions: Record<
    AllocateJobEngine,
    { title: string; desc: string }
  > = {
    [AllocateJobEngine.LAMBDA]: {
      title: t`Auto`,
      desc: t`Lambda`,
    },
    [AllocateJobEngine.FARGATE]: {
      title: t`Advanced`,
      desc: t`Fargate`,
    },
  };

  return (
    <DropdownSelector
      className="my-4 text-base"
      classNameLabel="flex-1"
      classNameValue="relative"
      headerMode
      label={t`Allocate Mode`}
      values={[AllocateJobEngine.LAMBDA, AllocateJobEngine.FARGATE]}
      value={engineAllocate}
      DropUp={true}
      onChange={(value: AllocateJobEngine) => {
        console.log('AllocateJobEngine (Before):', value);
        setEngineAllocate(value);
        console.log('AllocateJobEngine (After):', value);
      }}
      renderValue={i => allocateRunOptions[i]?.title}
    />
  );
};
