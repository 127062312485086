export const ButtonType = {
  primary: 'bg-blue-500 hover:bg-blue-700 text-white font-bold',
  secondary: 'bg-blue-500 hover:bg-blue-700 text-white font-bold',
  basic: 'bg-white hover:bg-gray-700 text-gray-700 font-bold',
  delete: 'bg-red-300 hover:bg-red-500 text-white font-bold',
  register: 'bg-yellow-500 hover:bg-yellow-600 text-white font-bold',
  login: 'bg-red-300 hover:bg-red-500 text-white font-bold',
  helper: '',
  header: '',
  loadmore: '',
};

export const ButtonSize = {
  base: 'px-2 py-2 xl:px-3 xl:py-3',
  xs: 'px-2 py-1',
  sm: 'px-2 py-1',
  md: 'px-4 py-2',
  xl: 'px-2 py-2',
  lg: 'py-3 px-4',
  footer: 'px-4 py-4',
  header: 'px-1 py-3 m-0',
  helper: 'pl-1 text-xs uppercase',
};

export const StatusType = {
  LOADING: 'bg-menu text-menu-inverse',
  ALERT: 'bg-alerts-alert text-menu-inverse',
  ERROR: 'bg-alerts-error text-menu-inverse',
  WARNING: 'bg-alerts-warning text-menu-inverse',
  INFO: 'bg-alerts-info text-menu-inverse',
  OKAY: 'bg-alerts-okay text-menu-inverse',
  DISABLED: 'bg-alerts-disabled text-menu-inverse',
  HELPER: 'bg-alerts-helper text-menu-inverse',
  OVERLAP: 'bg-alerts-overlap text-menu-inverse',
  CONSENT: '',
};

export const CalloutType = {
  tip: '',
  suggestion: '',
  caution: '',
  check: '',
  note: '',
  warning: '',
  helper: '',
  loadmore: '',
};
