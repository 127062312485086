import * as React from 'react';
const PickingWaveRouteTruckHistoryGateIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PickingWaveRouteTruckHistoryGateIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="m4.27,50.92c0,12.58,10.24,22.82,22.82,22.82v-8.99c-7.62,0-13.82-6.2-13.82-13.82s6.2-13.82,13.82-13.82c7.62,0,13.82,6.2,13.82,13.82h-4.32s8.35,9.85,8.35,9.85l8.35-9.85h-3.39c0-12.58-10.24-22.82-22.82-22.82-12.58,0-22.82,10.24-22.82,22.82Zm16.32,18.85l.57-2.42c.19-.81.99-1.31,1.8-1.12.81.19,1.31,1,1.12,1.8l-.56,2.43c-.19.81-1,1.31-1.8,1.12-.81-.19-1.31-.99-1.12-1.8Zm23.24-22.64l2.47-.28c.82-.09,1.57.5,1.66,1.32.09.82-.49,1.57-1.32,1.66l-2.47.28c-.82.09-1.57-.5-1.66-1.32-.1-.82.49-1.57,1.32-1.66Zm-3.77-7.42l2.05-1.41c.69-.47,1.62-.29,2.08.39.47.68.29,1.62-.39,2.08l-2.05,1.41c-.68.47-1.62.29-2.08-.39-.47-.68-.29-1.62.39-2.08Zm-6.8-4.8l1.16-2.2c.39-.73,1.29-1.01,2.02-.63.73.39,1.01,1.29.63,2.02l-1.16,2.2c-.39.73-1.29,1.01-2.03.63-.73-.39-1.01-1.29-.63-2.02Zm-7.33-3.57c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5v2.49c0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5v-2.49Zm-8.44.74c.73-.39,1.64-.11,2.02.63l1.16,2.2c.39.73.11,1.64-.63,2.02s-1.64.11-2.03-.63l-1.16-2.2c-.39-.73-.11-1.64.63-2.02Zm-5.3,10.1l-2.06-1.41c-.68-.47-.86-1.4-.39-2.08.47-.68,1.4-.86,2.08-.39l2.05,1.41c.68.47.86,1.4.39,2.08-.47.68-1.4.86-2.08.39Zm.4,22.44l1.63-1.88c.54-.63,1.49-.69,2.12-.15.63.54.69,1.49.15,2.12l-1.63,1.88c-.54.62-1.49.69-2.12.15-.62-.54-.69-1.49-.15-2.12Zm-4.68-8.28l2.32-.9c.77-.3,1.64.08,1.94.85.3.77-.08,1.64-.85,1.94l-2.32.9c-.77.3-1.64-.08-1.94-.85-.3-.77.08-1.64.86-1.94Zm-1.94-8.19c.09-.82.84-1.41,1.66-1.32l2.47.28c.82.09,1.41.84,1.32,1.66-.09.82-.84,1.41-1.66,1.32l-2.47-.28c-.82-.1-1.41-.84-1.32-1.66Z" />
      <path d="m16.44,52.17c0,.83.67,1.5,1.5,1.5h7.39c.47.65,1.23,1.08,2.1,1.08,1.43,0,2.58-1.16,2.58-2.58,0-.87-.43-1.63-1.08-2.1v-7.82c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5v7.81c-.23.17-.43.37-.6.6h-7.39c-.83,0-1.5.67-1.5,1.5Z" />
    </g>
    <g>
      <path d="m94.11,45.24s.06.02.15.04c-.05-.02-.1-.04-.15-.04Z" />
      <path d="m107.59,87.6c-3.83,0-6.96,3.12-6.95,6.96,0,.5.05.99.16,1.47.68,3.13,3.47,5.49,6.8,5.49s6.12-2.36,6.8-5.49c.1-.47.16-.96.16-1.47,0-3.84-3.12-6.96-6.96-6.96Zm1.16,8.42c-.32.25-.72.41-1.16.41s-.84-.15-1.16-.41c-.43-.34-.71-.87-.71-1.47,0-1.03.84-1.87,1.87-1.87s1.87.84,1.87,1.87c0,.59-.28,1.13-.71,1.47Z" />
      <path d="m121.04,91.54v-20.41l-3.02-13.36c-3.12-7.23-13.61-8.89-13.61-8.89-1.14-1.33-8.96-3.31-10.15-3.6.81.42.86,4.17.86,4.17v36.05s-4.84,0-4.84,0v-40.26s0-4.23-4.03-4.23h-30.13c.74,2.58,1.14,5.3,1.14,8.11,0,16.25-13.18,29.43-29.43,29.43-6.06,0-11.7-1.84-16.38-4.98v11.93h8.74v1.85h-5.88v7.21h36.43v-7.21h-3.27v-1.85h5.27l.75,3.13v2.78s-.65.38-.65,1.2h2.78c0-.82-.65-1.2-.65-1.2v-2.78l.75-3.13h6.82c-.35,0-.64.28-.64.64v9.26c0,.35.28.63.64.63h2.03c-.09-.47-.14-.97-.14-1.47,0-1.99.78-3.87,2.19-5.28,1.41-1.41,3.28-2.19,5.27-2.19s3.87.78,5.28,2.19c1.41,1.41,2.19,3.28,2.19,5.28,0,.5-.05.99-.14,1.47h21.07c-.1-.47-.15-.97-.15-1.47,0-1.99.78-3.87,2.19-5.28,1.41-1.41,3.28-2.19,5.28-2.19s3.86.78,5.27,2.19c1.41,1.41,2.19,3.28,2.19,5.28,0,.5-.05.99-.14,1.47h4.39c2.11,0,1.74-4.48,1.74-4.48Zm-75.4-4.2h-23.98v-1.85h23.98v1.85Zm62.19-18.12c0,.35-.28.64-.63.64h-3.94c-.35,0-.64-.28-.64-.64v-8.78c0-.35.29-.64.64-.64h3.94c.35,0,.63.29.63.64v8.78Zm2.9,8.45h-1.48c-.34,0-.62-.28-.62-.63s.28-.62.62-.62h1.48c.35,0,.62.28.62.62s-.28.63-.62.63Zm8.62-3.16s.05,1.69-1.21,1.69h-1.85l-6.31-5.17s-.87-.51-.87-1.92v-8.01s-.18-1.51,1.42-1.51h6.41l2.43,10.2v4.72Z" />
      <path d="m71.89,87.6c-3.83,0-6.96,3.12-6.95,6.96,0,.5.06.99.16,1.47.67,3.13,3.47,5.49,6.8,5.49s6.12-2.36,6.8-5.49c.1-.47.16-.96.16-1.47,0-3.84-3.12-6.96-6.96-6.96Zm1.16,8.42c-.32.25-.72.41-1.16.41s-.84-.15-1.16-.41c-.44-.34-.71-.87-.71-1.47,0-1.03.84-1.87,1.87-1.87s1.87.84,1.87,1.87c0,.59-.28,1.13-.71,1.47Z" />
    </g>
  </svg>
);
export default PickingWaveRouteTruckHistoryGateIcon;
