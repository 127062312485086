export const consigneeAliases = [
  'consignee',
  'consigneeid',
  'idconsignee',
  'client',
  'clientid',
  'clt',
  'cltcde',
  'owner',
  'ownerId',
  'clinet',
  'clientcode',
  'idclient',
  'idvendor',
  'vendor',
  'depositor',
  'car_name',
  'carname',
  'name',
  'Prod.OwnerId',
  'ProdOwnerId',
  'ProdOwner',
  'orderclientcode',
  'company',
  'warehouse',
  'wh_id',
];

export const skuAliases = [
  'sku',
  'skuid',
  'sku/item',
  'item',
  'itemid',
  'itemnumber',
  'itemserialnumber',
  'itemsn',
  'itemcode',
  'itmnum',
  'itm',
  'itmcde',
  'number',
  'code',
  'inventory',
  'stock',
  'lot',
  'product',
  'productid',
  'productnumber',
  'productcode',
  'partnumber',
  'prodversion',
  'prodversionid',
  'prodid',
  'prodcode',
  'prod',
  'material',
  'tuotenumero',
  'article',
  'articleid',
  'warehouse_sku_id',
  'warehouseskuid',
  'warehouse_sku',
  'warehousesku',
  'prtnum',
];

export const uomAliases = [
  'uom',
  'unitofmeasure',
  'unitsofmeasure',
  'unit',
  'fixedunitsymbolassignment',
  'unitsymbol',
  'untuom',
  'layuom',
  'articletype',
  'article_type',
  'stockUom',
  'stock_uom',
  'um',
  'item_uom',
  'sku_uom',
  'product_uom',
  'part_uom',
  'partnm',
  'itm_uom',
  'itmuom',
  'inventory_uom',
  'inventoryuom',
  'lot_uom',
  'lotuom',
  'quantity_uom',
  'quantity_um',
  'qty_um',
  'quantity_unit',
];

export const itemQtyAliases = [
  'qty',
  'quantity',
  'item_qty',
  'itemqty',
  'sku_qty',
  'skuqty',
  'product_qty',
  'productqty',
  'itm_qty',
  'itmqty',
  'inventory_qty',
  'inventoryqty',
  'stock_qty',
  'stockqty',
  'lot_qty',
  'lotqty',
  'picked',
  'eachpicked',
  'eachespicked',
  'casespicked',
  'palletspicked',
  'layerspicked',
  'amount',
  'on_hand_qty',
  'qty_um',
  'lpn_qty',
  'stockUomQuantity',
  'uomquantity',
  'ActualQuantity',
];

export const locationIdAliases = [
  'locationId',
  'locationname',
  'location',
  'locid',
  'loc',
  'srcloc',
  'src',
  'dstloc',
  'source',
  'containerid',
  'container',
  'binid',
  'bin',
  'pickface',
  'pickfaceid',
  'pickfacelocationid',
  'rakusr',
  'stoloc',
  'locationId',
  'rakusr',
  'blbinl',
  'WHSE_SLT_LOC_ID',
  'SourceStorageBin',
  'StorageBin',
  'Storage',
  'ToLocationName',
  'ToLocationId',
  'ToLocation',
];
