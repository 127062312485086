import * as React from 'react';
const SimulationAddPolicyIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationAddPolicyIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M24.89,73.2l-18.35,10.59v21.19s18.35,10.59,18.35,10.59l18.35-10.59v-21.19s-18.35-10.59-18.35-10.59Z"
    />
    <path
      strokeWidth={0}
      d="M64.86,73.2l-18.35,10.59v21.19s18.35,10.59,18.35,10.59l18.35-10.59v-21.19s-18.35-10.59-18.35-10.59Z"
    />
    <path
      strokeWidth={0}
      d="M44.76,37.18l-18.35,10.59v21.19s18.35,10.59,18.35,10.59l18.35-10.59v-21.19s-18.35-10.59-18.35-10.59Z"
    />
    <path
      strokeWidth={0}
      d="M105.25,73.2l-18.35,10.59v21.19s18.35,10.59,18.35,10.59l18.35-10.59v-21.19s-18.35-10.59-18.35-10.59Z"
    />
    <path
      strokeWidth={0}
      d="M86.24,37.18l-18.35,10.59v21.19s18.35,10.59,18.35,10.59l18.35-10.59v-21.19s-18.35-10.59-18.35-10.59Z"
    />
  </svg>
);
export default SimulationAddPolicyIcon;
