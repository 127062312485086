import * as React from 'react';
const CalendarTime2Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CalendarTime2Icon"
    {...props}
  >
    <path strokeWidth={0} d="M32.66,7.15h6.53v7.68h-6.53v-7.68Z" />
    <path strokeWidth={0} d="M61.67,7.15h6.53v7.68h-6.53v-7.68Z" />
    <path strokeWidth={0} d="M90.69,7.15h6.53v7.68h-6.53v-7.68Z" />
    <path
      strokeWidth={0}
      d="M32.9,26.73v-11.82H13.92v20.21h103.1V14.91h-20.03v11.82h-6.48v-11.82h-22.32v11.82h-6.48v-11.82h-22.32v11.82h-6.48Z"
    />
    <path
      strokeWidth={0}
      d="M13.57,41.5v64.64c0,2.52,2.05,4.56,4.56,4.56h50.9c.31,0,.61-.1.84-.3l.12-.1c-2.47-4.42-3.86-9.48-3.86-14.88,0-16.93,13.78-30.71,30.71-30.71,7.29,0,13.99,2.54,19.25,6.8l.12-.1c.3-.26.47-.63.47-1.01v-28.9s-103.1,0-103.1,0ZM98.07,46.56c0-.73.59-1.3,1.3-1.3h10.23c.71,0,1.3.57,1.3,1.3v10.21c0,.73-.59,1.3-1.3,1.3h-10.23c-.71,0-1.3-.57-1.3-1.3v-10.21ZM78.39,46.56c0-.73.57-1.3,1.3-1.3h10.21c.73,0,1.3.57,1.3,1.3v10.21c0,.73-.57,1.3-1.3,1.3h-10.21c-.73,0-1.3-.57-1.3-1.3v-10.21ZM58.71,64.38c0-.71.57-1.3,1.3-1.3h10.21c.73,0,1.3.59,1.3,1.3v10.23c0,.71-.58,1.3-1.31,1.3h-10.21c-.73,0-1.3-.59-1.3-1.3v-10.23ZM58.71,46.56c0-.73.57-1.3,1.3-1.3h10.21c.73,0,1.3.57,1.3,1.3v10.21c0,.73-.57,1.3-1.3,1.3h-10.21c-.73,0-1.3-.57-1.3-1.3v-10.21ZM39.03,82.22c0-.73.57-1.3,1.3-1.3h10.21c.73,0,1.3.57,1.3,1.3v10.21c0,.73-.58,1.3-1.31,1.3h-10.21c-.73,0-1.3-.57-1.3-1.3v-10.21ZM39.03,64.38c0-.71.57-1.3,1.3-1.3h10.21c.73,0,1.3.59,1.3,1.3v10.23c0,.71-.58,1.3-1.31,1.3h-10.21c-.73,0-1.3-.59-1.3-1.3v-10.23ZM39.03,46.56c0-.73.57-1.3,1.3-1.3h10.21c.73,0,1.3.57,1.3,1.3v10.21c0,.73-.57,1.3-1.3,1.3h-10.21c-.73,0-1.3-.57-1.3-1.3v-10.21ZM19.33,82.22c0-.73.59-1.3,1.3-1.3h10.23c.71,0,1.3.57,1.3,1.3v10.21c0,.73-.59,1.3-1.3,1.3h-10.23c-.71,0-1.3-.57-1.3-1.3v-10.21ZM19.33,64.38c0-.71.59-1.3,1.3-1.3h10.23c.71,0,1.3.59,1.3,1.3v10.23c0,.71-.59,1.3-1.3,1.3h-10.23c-.71,0-1.3-.59-1.3-1.3v-10.23ZM19.33,46.56c0-.73.59-1.3,1.3-1.3h10.23c.71,0,1.3.57,1.3,1.3v10.21c0,.73-.59,1.3-1.3,1.3h-10.23c-.71,0-1.3-.57-1.3-1.3v-10.21Z"
    />
    <path
      strokeWidth={0}
      d="M69.6,95.42c0,15.02,12.22,27.23,27.23,27.23,15.02,0,27.23-12.22,27.23-27.23,0-15.02-12.22-27.23-27.23-27.23-15.02,0-27.23,12.22-27.23,27.23ZM114.97,94.12h4.45v2.61h-4.45v-2.61ZM108.74,109.15l1.84-1.84,3.15,3.17-1.84,1.84-3.15-3.17ZM108.74,81.69l3.15-3.17,1.84,1.84-3.15,3.17-1.84-1.84ZM95.53,113.55h2.61v4.45h-2.61v-4.45ZM95.53,81.03h2.61v13.85s10.37,10.37,10.37,10.37l-1.84,1.84-10.75-10.75c-.24-.24-.38-.57-.38-.92v-14.39ZM95.53,72.83h2.61v4.47h-2.61v-4.47ZM79.95,110.47l3.15-3.17,1.84,1.84-3.15,3.17-1.84-1.84ZM79.95,80.37l1.84-1.84,3.15,3.17-1.84,1.84-3.15-3.17ZM74.26,96.72v-2.61h4.45v2.61h-4.45Z"
    />
  </svg>
);
export default CalendarTime2Icon;
