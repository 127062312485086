import React from 'react';

export type useImageProps = {
  path: string;
  pathSize: number;
  targetSize: number;
  color: string;
};

function useCanvasImage(props: useImageProps) {
  const shape = React.useMemo(() => {
    const m = document
      .createElementNS('http://www.w3.org/2000/svg', 'svg')
      .createSVGMatrix();
    const t = m.scale(props.targetSize / props.pathSize);

    const canvas = document.createElement('canvas');
    canvas.width = props.pathSize;
    canvas.height = props.pathSize;
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = props.color;
    ctx.lineWidth = 1;
    ctx.fillStyle = props.color;

    const iconPath = new Path2D(props.path);
    const p = new Path2D();
    p.addPath(iconPath, t);
    ctx.stroke(p);
    ctx.fill(p);
    return canvas;
  }, [props.path, props.pathSize, props.targetSize, props.color]);

  return shape;
}
export default useCanvasImage;
