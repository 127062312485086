import { FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateLayoutImportDocument,
  UpdateLayoutImportInput,
  UpdateLayoutImportMutation,
  UpdateLayoutImportMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { cloneWithoutTypename } from '../../../../common/utils';
import { errorAppender } from '../../../../store/error.state';

function useUpdateLayoutImport() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');

  // const beforeUpdate = useRecoilCallback(({ set }) => async () => {

  // });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (patch: UpdateLayoutImportInput) => {
        let response: FetchResult<UpdateLayoutImportMutation>;
        try {
          response = await client.mutate<
            UpdateLayoutImportMutation,
            UpdateLayoutImportMutationVariables
          >({
            mutation: UpdateLayoutImportDocument,
            variables: {
              layoutImportInput: {
                ...cloneWithoutTypename(patch),
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save LayoutImport`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save LayoutImport`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          return;
        }
      },
  );

  return async (patch: UpdateLayoutImportInput) => {
    //await beforeUpdate();
    await callUpdate(patch);
  };
}
export default useUpdateLayoutImport;
