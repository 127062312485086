import { RoutingPolicyFeatureRuleFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import * as Icon from '../components/icons';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import { Stat } from '../components/stats/Stat';
import {
  routingPolicy,
  routingPolicyCheckResultByRule,
  routingPolicyCheckResultByRuleLoadStatus,
  routingPolicySelectedIdentity,
} from '../simulation/store/routingPolicy/routingPolicy.state';
import { simulationLayoutSummary } from '../simulation/store/simulation.layout.state';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import { sidebarStateByType } from '../store/sidebar.state';
import useCheckRoutingPolicyRule from './useCheckRoutingPolicyRule';

export type RoutingPolicyFeaturesRuleProps = {
  ruleId: string;
  featureRules: RoutingPolicyFeatureRuleFragment[];
  threshold?: number;
};

const RoutingPolicyFeaturesRule: React.FC<
  RoutingPolicyFeaturesRuleProps
> = props => {
  const { ruleId } = props;
  const { t } = useTranslation('simulation');
  const policy = useRecoilValue(routingPolicy);
  const layout = useRecoilValue(simulationLayoutSummary);
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    routingPolicySelectedIdentity,
  );
  const [callCheck, cancelCheck] = useCheckRoutingPolicyRule();
  const checkResult = useRecoilValue(routingPolicyCheckResultByRule(ruleId));
  const checkResultStatus = useRecoilValue(
    routingPolicyCheckResultByRuleLoadStatus(ruleId),
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-routing-policy-editor'),
  );
  const canUpdate = useRecoilValue(simulationIsEditable);

  useEffect(() => {
    cancelCheck();
    callCheck({
      ruleId,
      featureRules: props.featureRules,
      threshold: policy?.threshold,
    });

    return () => {
      cancelCheck();
    };
  }, [props?.featureRules, props?.threshold]);

  const isActive =
    selectedIdentity?.ruleId === ruleId &&
    (!canUpdate || selectedIdentity?.isRoutingConfigurationSelected);

  function toggleSelect() {
    const shouldSelect = !isActive;
    const newIdentity = shouldSelect
      ? {
          ruleId: ruleId,
          isRoutingAgentSelected: false,
          isRoutingConfigurationSelected: canUpdate,
        }
      : null;

    setSelectedIdentity(newIdentity);
    if (canUpdate) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: !policyEditPanelState.isPinned
          ? !shouldSelect
          : policyEditPanelState.isCollapsed,
        isHidden: !shouldSelect,
      });
    }
  }

  const features = props.featureRules;

  const isCheckLoading =
    checkResultStatus === AsyncLoadStatus.Loading ||
    checkResultStatus === AsyncLoadStatus.None;

  const totalCount = layout.locationCount;
  const invalidCount = checkResult;
  const hasIssue = invalidCount > 0;
  const restrictedFeaturesCount = _.size(features);
  const zeroRestrictions = restrictedFeaturesCount === 0;

  return (
    <PolicyFilter
      data-component="RoutingPolicyFeaturesRule"
      className="p-1 xl:p-2"
      key={`PolicyFilter-for-features-${ruleId}`}
      label={t`Routing Restrictions`}
      isActive={isActive}
      onClick={toggleSelect}
    >
      <Stat
        className={classNames('px-1 xl:px-2 py-1')}
        hasIssue={hasIssue}
        isCheckLoading={isCheckLoading}
        title={
          isCheckLoading ? t`Validating Routing` : t`Inaccessible locations`
        }
        isSectionable
        value={hasIssue ? formatInteger(invalidCount) : t`None`}
        valueTotal={hasIssue ? formatInteger(totalCount) : null}
        icon={hasIssue ? Icon.TriangleInfo : Icon.CircleOk}
        inPanelMode
        isActionable
        // inFilterStat
        isSelected={isActive}
        onClick={toggleSelect}
        transparent
      />
      <PolicyFilterItem
        key={`PolicyFilterItem-for-features-overall`}
        isDisabled={!canUpdate}
        isActive={!canUpdate ? false : isActive}
        value={
          zeroRestrictions
            ? t('No Restrictions')
            : t('{{restrictedFeaturesCount}} aisles', {
                restrictedFeaturesCount,
              })
        }
        icon={zeroRestrictions ? Icon.CircleWaypoint4 : Icon.CircleArrowTwoWay}
        iconClassName={classNames(
          'w-4 h-4 lg:w-5 lg:h-5 xl:w-6 xl:h-6',
          'ltr:mr-2 rtl:ml-2',
        )}
      />
    </PolicyFilter>
  );
};

export default RoutingPolicyFeaturesRule;
