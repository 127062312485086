import * as React from 'react';
const GridUpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="GridUpIcon"
    {...props}
  >
    <rect width={38} height={38} x={86} y={4} strokeWidth={0} />
    <rect width={38} height={38} x={86} y={45} strokeWidth={0} />
    <rect width={38} height={38} x={4} y={86} strokeWidth={0} />
    <rect width={38} height={38} x={45} y={86} strokeWidth={0} />
    <rect width={38} height={38} x={86} y={86} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M4.01,4.02h23.25s23.25-.02,23.25-.02l8.76,8.76,8.76,8.76h-17.05s-17.05.01-17.05.01l17.05,17.05,32.01,32.01v12.41h-12.4l-32.01-32.01-17.05-17.05v17.05s0,17.05,0,17.05l-8.76-8.76-8.76-8.76v-23.25s.01-23.25.01-23.25Z"
    />
  </svg>
);
export default GridUpIcon;
