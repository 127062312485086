import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { simulationHeatmapLocationByBay } from '../../store/simulation.heatmap.state';
import { simulationAnalyzeId } from '../../store/simulation.state';
import SimulationBayLocationLayerBase from './SimulationBayLocationLayerBase';

const SimulationBayLocationLayer: React.FC = () => {
  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const heatmapByLocLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByBay(analyzeId),
  );

  const heatmapByLoc =
    heatmapByLocLoadable.state === 'hasValue'
      ? heatmapByLocLoadable.contents
      : null;
  return <SimulationBayLocationLayerBase heatmapByLoc={heatmapByLoc} />;
};

export default SimulationBayLocationLayer;
