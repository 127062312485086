import _ from 'lodash';
import {
  agentCategoriesId,
  AgentModelDefinition,
} from '../../agentData/agent.types';
import modelsDefaultsManufacturers from './manufacturersDefaultModels.json';
import defaultManual from './modelDefaultManual.json';
import defaultPowered from './modelDefaultPowered.json';
import modelsDefaultPalletJackManual from './models-04-pallet-jack-manual.json';
import modelsBT from './models-bt.json';
import modelsCrownWalkieStacker from './models-crown-walkie-stalker.json';
import modelsDefaultManualVoice from './models-default-picker-voice.json';
import modelsToyota from './models-toyota.json';
import modelsWiseRobotics from './models-wiserobotics.json';
import modelsZebra from './models-zebra-fetch-robotics.json';
// import modelsManualVoice from './models-voice.json';

export const agentModels: AgentModelDefinition[] = [
  defaultManual,
  defaultPowered,
  ...modelsDefaultsManufacturers,
  ...modelsDefaultManualVoice,
  ...modelsBT,
  ...modelsToyota,
  ...modelsZebra,
  ...modelsCrownWalkieStacker,
  ...modelsWiseRobotics,
  ...modelsDefaultPalletJackManual,
  // ...modelsManualVoice,

  ...agentCategoriesId.map(categoryId => ({
    id: `default-${categoryId}`,
    manufacturerId: 'default',
    useAsDefault: true,
    modelFamilyId: 'default',
    settingId: categoryId,
    categoryId: categoryId,
    image: [],
    title: 'Default (Industry Standard)',
    description: null,
    sensors: [],
    features: [],
    tags: [],
    isPremium: false,
    isSpotlight: false,
  })),
];

export const agentModelsMap = _.keyBy(agentModels, model => model.id);

export const agentModelsByCategory = _.groupBy(
  agentModels,
  model => model.categoryId,
);
