import {
  LocationFilterIntersectionFragment,
  LocationSharingPolicyFragment,
  LocationSharingPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { FilterIntersectionIdentity } from '../../../common/types';
import { LSP_FALLBACK_RULE_ID } from './locationSharingPolicy.default';

const getKey = (postfix: string) =>
  `warego-simulation-location-sharing-policy-${postfix}`;

export const locationSharingPolicy = atom<LocationSharingPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const locationSharingPolicySelectedRuleId = atom<string>({
  key: getKey('selected-rule-id'),
  default: null,
});

const locationSharingPolicyFallbackRule =
  selector<LocationSharingPolicyRuleFragment>({
    key: getKey('fallback-rule'),
    get: ({ get }) => {
      const policy = get(locationSharingPolicy);
      return {
        id: LSP_FALLBACK_RULE_ID,
        title: 'Default Rule',
        maxItemsPerLocation: policy.defaultMaxItemsPerLocation,
        locationsMatch: null,
      };
    },
  });

export const locationSharingPolicyRule = selectorFamily<
  LocationSharingPolicyRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(locationSharingPolicy);
      if (ruleId === LSP_FALLBACK_RULE_ID)
        return get(locationSharingPolicyFallbackRule);
      return _.find(policy.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, newRule: LocationSharingPolicyRuleFragment) => {
      const policy = get(locationSharingPolicy);

      if (ruleId === LSP_FALLBACK_RULE_ID) {
        set(locationSharingPolicy, {
          ...policy,
          defaultMaxItemsPerLocation: newRule.maxItemsPerLocation,
        });
      } else {
        set(locationSharingPolicy, {
          ...policy,
          rules: policy.rules.map(rule =>
            rule.id === ruleId ? newRule : rule,
          ),
        });
      }
    },
});

export const locationSharingPolicySelectedIdentityAtom =
  atom<FilterIntersectionIdentity>({
    key: getKey('selected-identity-atom'),
    default: null,
  });

export const locationSharingPolicySelectedIdentity =
  selector<FilterIntersectionIdentity>({
    key: getKey('selected-identity'),
    get: ({ get }) => get(locationSharingPolicySelectedIdentityAtom),
    set: ({ get, set }, value: FilterIntersectionIdentity) => {
      if (!_.isNil(value?.ruleId)) {
        set(locationSharingPolicySelectedRuleId, value.ruleId);
      }
      set(locationSharingPolicySelectedIdentityAtom, value);
    },
  });

export const locationSharingPolicySelectedFilterIntersection =
  selector<LocationFilterIntersectionFragment>({
    key: getKey('selected-location-filter-intersection-by-id'),
    get: ({ get }) => {
      const identity = get(locationSharingPolicySelectedIdentity);
      if (!identity?.locationFilterId || !identity?.ruleId) return null;

      const rule = get(locationSharingPolicyRule(identity.ruleId));
      if (!rule) return null;

      const filterIntersection = _.find(
        rule.locationsMatch?.anyOf,
        fs => fs.id === identity.locationFilterId,
      );
      return filterIntersection;
    },
    set: ({ get, set }, value: LocationFilterIntersectionFragment) => {
      const identity = get(locationSharingPolicySelectedIdentity);
      if (!identity?.locationFilterId || !identity?.ruleId) {
        throw new Error('Invalid  parameters. Filter identity is invalid');
      }

      const rule = get(locationSharingPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(locationSharingPolicyRule(identity.ruleId), {
        ...rule,
        locationsMatch: {
          ...rule.locationsMatch,
          anyOf: _.map(rule.locationsMatch.anyOf, fs =>
            fs.id === identity.locationFilterId ? value : fs,
          ),
        },
      });
    },
  });

export const locationSharingPolicyCheckResult = atom<any>({
  key: getKey('check-results'),
  default: null,
});
