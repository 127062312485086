import { InferResult } from 'kysely';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedProcessTypeTotals = InferResult<
  ReturnType<typeof getFeedProcessTypeTotalsQuery>
>;

export type FeedProcessTypeTotal = FeedProcessTypeTotals[number];

export const getFeedProcessTypeTotalsQuery = (
  params: ActivityFeedQueryBuilderParams,
) => {
  let query = datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .selectFrom('af')
    .select(({ fn }) => [
      'eventProcessType',
      'eventType',
      fn.countAll<number>().as('totalCount'),
    ])
    .groupBy(['eventProcessType', 'eventType']);

  return query;
};
export const postProcessFeedProcessTypeTotals =
  postProcessDefault<FeedProcessTypeTotal>;
