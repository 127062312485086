import { useApolloClient } from '@apollo/client';
import {
    LoadAnalyzedOrderDetailsDocument,
    LoadAnalyzedOrderDetailsQuery,
    LoadAnalyzedOrderDetailsQueryVariables
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import naturalCompare from 'string-natural-compare';

import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { getIterationCode } from '../panels/route/OrderPicklists';
import {
    optimisationAnalyzeOrderDetails,
    optimisationAnalyzeOrderDetailsLoadStatus,
    optimisationPicklistRoutes,
    optimisationSelectedPicklistId
} from '../store/optimisation.state';
import useLoadAnalyzedPickListDetails from './useLoadAnalyzedPicklistDetails';

export type LoadAnalyzedOrdersListParams = {
  analyzeId: string;
  orderId: string;
};

function useLoadOptimizedOrderDetails() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Order details`;
  const [loadEvents] = useLoadAnalyzedPickListDetails();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set, reset }) => async () => {
    set(optimisationAnalyzeOrderDetailsLoadStatus, AsyncLoadStatus.Loading);
    reset(optimisationSelectedPicklistId);
    reset(optimisationAnalyzeOrderDetails);
    reset(optimisationPicklistRoutes);
  });

  const cancelLoading = useRecoilCallback(({ set, reset }) => async () => {
    observable?.unsubscribe();
    set(optimisationAnalyzeOrderDetailsLoadStatus, AsyncLoadStatus.Ok);
    reset(optimisationSelectedPicklistId);
    reset(optimisationAnalyzeOrderDetails);
    reset(optimisationPicklistRoutes);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAnalyzedOrdersListParams) => {
        const query = client.watchQuery<
          LoadAnalyzedOrderDetailsQuery,
          LoadAnalyzedOrderDetailsQueryVariables
        >({
          query: LoadAnalyzedOrderDetailsDocument,
          variables: {
            analyzeId: params.analyzeId,
            orderId: params.orderId,
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(optimisationAnalyzeOrderDetailsLoadStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const orderDetails = data.analyzeResult.orders.content?.[0];

            const sortedList = [...orderDetails.picklists].sort((a1, a2) =>
              naturalCompare(
                getIterationCode(a1.picklist.title),
                getIterationCode(a2.picklist.title),
              ),
            );
            const picklistId = sortedList?.[0]?.picklist?.id;
            set(optimisationAnalyzeOrderDetails, orderDetails);
            set(optimisationSelectedPicklistId, picklistId);
            set(optimisationAnalyzeOrderDetailsLoadStatus, AsyncLoadStatus.Ok);

            if (!_.isNil(picklistId)) {
              loadEvents({
                analyzeId: params.analyzeId,
                orderId: params.orderId,
                picklistId,
              });
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAnalyzedOrdersListParams) {
    await initLoading();
    await loadCallback(params);
  }

  async function cancel() {
    await cancelLoading();
  }

  return [call, cancel] as const;
}

export default useLoadOptimizedOrderDetails;
