import _ from 'lodash';
import React, { useState } from 'react';

export type PanelProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Panel = ({ children, className }: PanelProps) => {
  const [id] = useState<string>(_.uniqueId());
  return (
    <section
      id={id}
      className={`Panel hover:bg-app-panel-dark hover:bg-opacity-10 ${
        className || ''
      }`}
    >
      {children}
    </section>
  );
};
