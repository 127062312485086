import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import ObjectSize from '../../../../components/designer/panels/ObjectSize';
import * as Icon from '../../../../components/icons';
import { InputValue } from '../../../../components/inputs/InputValue';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import PanelContainer from '../../../../containers/PanelContainer';
import { getBox } from '../../../../layout/viewer/store/viewer.helper';
import { warehouseSelected } from '../../../../store/warehouse.state';
import { converterEditableArea } from '../store/converter.area.state';
import { converterLayoutSelectedAisle } from '../store/converter.layout.state';
import { converterAreaBuilder } from '../store/converter.state';

const ConverterAutoSpacerInfoPanel: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const area = useRecoilValue(converterEditableArea);
  const wh = useRecoilValue(warehouseSelected);
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );
  const [aisle, setSelectedAisle] = useRecoilState(
    converterLayoutSelectedAisle,
  );
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );
  if (!area || !aisle) return null;

  const box = getBox(aisle.shape);
  const dX = box.xmax - box.xmin;
  const dY = box.ymax - box.ymin;

  const w = Math.floor(Math.min(dX, dY));
  const l = Math.floor(Math.max(dX, dY));

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <WarehouseNavigator>
        <WarehouseNavigatorItem active name={t`Area`} value={area.id} />
        <WarehouseNavigatorItem
          name={t`Aisle (Dynamic)`}
          value={aisle.title}
          active
          aria-label={aisle.title}
        />
      </WarehouseNavigator>
      <ContainerScroll>
        <PanelContainer
          id="panel-aisle-properties-dynamic"
          title={t`Aisle Properties`}
          hasPadding
        >
          <InputValue
            className="flex-1"
            inputText
            key={`aisle-title-${aisle.id}`}
            title={t`Title`}
            icon={<Icon.TagTitle className="w-8 h-8 fill-current" />}
            value={aisle.title}
            disabled
          />
          <ObjectSize
            title={t`Size and Position`}
            object={aisle.id}
            unitMeasurement={sizeMeasureValue}
            hasSize
            sizeWidth={Math.floor(w)}
            sizeLength={Math.floor(l)}
            disabled
          />
        </PanelContainer>
      </ContainerScroll>
    </div>
  );
};

export default ConverterAutoSpacerInfoPanel;
