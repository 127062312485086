import { Dialog, Transition } from '@headlessui/react';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../_ComponentsThemeStyles';
import { Spacer } from '../layout/Spacer';
import { ActionBar } from '../nav/ActionBar';
import { Button } from './Button';

export type ModalDialogProps = {
  title: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  message?: string;
  hasCancelButton?: boolean;
  okButtonTitle?: string;
  okButtonType?: keyof typeof ButtonType;

  onConfirm: () => Promise<void>;
  onCancel?: () => Promise<void>;
};

const ModalDialog: React.FC<ModalDialogProps> = props => {
  const { isOpen, setIsOpen } = props;
  const { t } = useTranslation('app');
  const [isLoading, setIsLoading] = useState(false);

  const okDefaultTitle = t`Ok`;
  const cancelTitleDefault = t`Cancel`;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-app-panel-dark bg-opacity-60 backdrop-filter backdrop-blur-lg backdrop-saturate-150" />
          </Transition.Child>

          {/* This element is to trick the browser into centring the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-start align-middle transition-all transform bg-white shadow-xl bg-opacity-80">
              <Dialog.Title
                as="h3"
                className="p-4 text-2xl font-medium leading-6 text-menu"
              >
                {props.title}
              </Dialog.Title>

              <div className="p-4 mt-2">
                {!_.isEmpty(props.message) && (
                  <p className="text-sm text-menu">{props.message}</p>
                )}
              </div>

              <ActionBar>
                {props.hasCancelButton && (
                  <Button
                    label={cancelTitleDefault}
                    buttonType="primary" // secondary  by default ?
                    onPress={async () => {
                      setIsLoading(true);
                      if (props.onCancel) {
                        await props.onCancel();
                      }
                      setIsLoading(false);
                      props.setIsOpen(false);
                    }}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  />
                )}
                <Spacer flexspace />
                <Button
                  label={props.okButtonTitle ?? okDefaultTitle}
                  buttonType={props.okButtonType ?? 'primary'}
                  onPress={async () => {
                    setIsLoading(true);
                    await props.onConfirm();
                    setIsLoading(false);
                    props.setIsOpen(false);
                  }}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                />
              </ActionBar>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalDialog;
