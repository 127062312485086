import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AppHeaderData, { AppHeaderProps } from '../components/AppHeaderData';
import { warehouseSelected } from '../store/warehouse.state';
import { layoutDocument } from './store/layout.state';

const LayoutHeader: React.FC = () => {
  const { t } = useTranslation('dataset');

  const wh = useRecoilValue(warehouseSelected);
  const document = useRecoilValue(layoutDocument);

  if (!wh) return null;

  const headerProps: AppHeaderProps = {
    warehouseId: wh.id,
    breadcrumbItems: [
      { title: wh.title, href: `/wh/i/${wh.id}` },
      { title: t`Layout dashboard`, href: `/wh/i/${wh.id}/designer` },
    ],
  };
  if (document) {
    headerProps.breadcrumbItems.push({
      title: document?.title,
      href: `/wh/i/${wh.id}/layouts/${document?.id}`,
    });
  }
  return <AppHeaderData {...headerProps} />;
};
export default LayoutHeader;
