import * as React from 'react';
const ArrowLeftBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowLeftBottomIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M21.57,106.43l-.03-68.24,25.72-25.72.02,50.04L97.32,12.47l18.21,18.21-50.04,50.04,50.03.02-25.72,25.72-68.23-.02Z"
    />
  </svg>
);
export default ArrowLeftBottomIcon;
