import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';

const StepEditLocations: React.FC = () => {
  const { t } = useTranslation('designer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Have you noticed any bays with incorrect dimensions?`}
        subtitle={t`Setup Locations`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerLocationsSize}
          question={t`Adjust your location sizes`}
        />
        <InputAnswerGroup>
          <HelpContainer
            id={'designer/03-setup-locations-dimensions'}
            hasPadding
          />
        </InputAnswerGroup>
      </InputQA>
    </PanelBody>
  );
};

export default StepEditLocations;
