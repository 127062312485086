import * as React from 'react';
const PolicyComplianceVolumeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceVolumeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2L8.94,29.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,19.17,35.74,19.17l35.74-19.17h0c11.01-6.77,19.31-20.94,19.31-32.96V29.46L64,2ZM109.37,73.87c0,8.92-6.54,20.24-14.67,25.48l-30.7,16.04-30.7-16.05c-8.12-5.24-14.67-16.55-14.67-25.47v-38.98l45.37-23.15,45.37,23.15v38.98Z"
    />
    <g isolation="isolate">
      <polyline
        strokeWidth={0}
        points="82.45 27.4 63.88 17.45 45.42 27.4 64 37.35 82.45 27.4"
      />
      <polyline
        strokeWidth={0}
        points="102.48 38.13 83.91 28.18 65.46 38.13 84.03 48.07 102.48 38.13"
        opacity={0.6}
      />
      <polyline
        strokeWidth={0}
        points="62.54 38.13 43.97 28.18 25.51 38.13 44.09 48.08 62.54 38.13"
        opacity={0.8}
      />
      <polyline
        strokeWidth={0}
        points="82.58 48.86 64 38.91 45.55 48.86 64.12 58.8 82.58 48.86"
      />
    </g>
    <g isolation="isolate">
      <polyline
        strokeWidth={0}
        points="66.39 85.06 66.39 63.32 85.38 52.95 85.38 74.68"
        opacity={0.6}
      />
      <polyline
        strokeWidth={0}
        points="66.39 108.51 66.39 86.77 85.38 76.4 85.38 98.13 66.39 108.51"
      />
      <polyline
        strokeWidth={0}
        points="86.47 73.87 86.47 52.13 105.46 41.76 105.46 63.49 86.43 73.87"
        opacity={0.7}
      />
      <path
        strokeWidth={0}
        d="M86.47,97.31v-21.73l18.99-10.37v9.5c0,7.56-4.09,14.52-10.69,18.2l-7.89,4.41"
        opacity={0.8}
      />
    </g>
    <g isolation="isolate">
      <polyline
        strokeWidth={0}
        points="61.63 85.06 61.63 63.32 42.63 52.95 42.63 74.68"
        opacity={0.85}
      />
      <polyline
        strokeWidth={0}
        points="61.63 108.51 61.63 86.77 42.63 76.4 42.63 98.13 61.63 108.51"
        opacity={0.75}
      />
      <polyline
        strokeWidth={0}
        points="41.55 73.87 41.55 52.13 22.56 41.76 22.56 63.49 41.58 73.87"
        opacity={0.8}
      />
      <path
        strokeWidth={0}
        d="M41.55,97.31v-21.73s-18.99-10.37-18.99-10.37v9.5c0,7.56,4.09,14.52,10.69,18.2l7.89,4.41"
      />
    </g>
  </svg>
);
export default PolicyComplianceVolumeIcon;
