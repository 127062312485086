import classNames from 'classnames';
import React from 'react';

interface CounterLabelProps {
  children?: React.ReactNode;
  counterSize?: 'xs' | 'sm' | 'lg' | 'xl' | 'date' | 'timedetail' | 'time';
  counterType?: 'route' | 'Warn' | 'info';
  bgColor?: string;
  textColor?: string;
  className?: string;
  classNameIcon?: string;
  IconItem?: React.ComponentType<{ className: string }>;
}

const CounterLabel: React.FC<CounterLabelProps> = ({
  children,
  counterSize = 'sm',
  counterType = 'route',
  bgColor,
  textColor,
  className,
  classNameIcon,
  IconItem,
}) => (
  <span
    aria-label="counterLabel"
    className={classNames(
      'z-50',
      'absolute',
      'flex items-center justify-center',
      'text-center',
      'p-0',
      'text-bold text-xs',
      'rounded-full',
      { 'mx-1 my-2 h-12 w-12 self-start': counterSize === 'xl' },
      { 'mx-1 my-2 h-10 w-10 self-start': counterSize === 'lg' },
      { 'mx-2 my-2 h-8 w-8 self-start': counterSize === 'sm' },
      { 'mx-2 my-2 h-7 w-7 self-start': counterSize === 'xs' },
      { 'mx-2 my-2 h-9 w-9 self-start': counterSize === 'date' },
      { 'mx-2 my-2 mt-6 h-8 w-8 self-start': counterSize === 'time' },
      { 'mx-1 mt-6 h-6 w-12 self-start': counterSize === 'timedetail' },
      {
        'bg-route-line_full text-menu-active-text':
          !bgColor && counterType === 'route',
      },
      {
        'bg-menu-active text-menu-active-text':
          !bgColor && counterType === 'Warn',
      },
      '',
      className,
    )}
    style={{ background: bgColor, color: textColor }}
  >
    {IconItem && (
      <IconItem
        className={classNames(
          { 'h-6 w-6': counterSize === 'sm' },
          { 'h-4 w-4': counterSize === 'xs' },
          'fill-current',
          classNameIcon,
        )}
      />
    )}
    {children}
  </span>
);

export default CounterLabel;
