import { ApolloQueryResult } from '@apollo/client';
import {
  LoadBillingStateDocument,
  LoadBillingStateQuery,
  LoadBillingStateQueryVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { billingClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { getWarehouseToken } from '../store/appBilling.helper';
import {
  appBillingWarehouseLoadStatus,
  appBillingWarehouseStateAll,
} from '../store/appBilling.state';

function useLoadWarehouseBillingState() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load warehouse billing status`;

  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    // set(appBillingWarehouseStatesAll, null);
    set(appBillingWarehouseLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (whIds: string[]) => {
        if (_.isEmpty(_.compact(whIds))) {
          console.warn(
            'Warehouse ids was not provided. Refreshing of billing state was canceled.',
          );
          return;
        }

        function handleError(details: string, stack: string) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(appBillingWarehouseLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const tokens = await getWarehouseToken(whIds, handleError);
        let response: ApolloQueryResult<LoadBillingStateQuery>;
        try {
          response = await billingClient.query<
            LoadBillingStateQuery,
            LoadBillingStateQueryVariables
          >({
            query: LoadBillingStateDocument,
            variables: {
              warehouseTokens: tokens,
            },
          });
        } catch (ex) {
          handleError(ex.message || ex, ex.stack || null);
        }

        if (response.errors) {
          handleError(null, response.errors.map(e => e.message).join('. '));
        } else {
          const billingState = _.keyBy(
            response.data.appBillingWarehouseStates,
            s => s.warehouseId,
          );
          set(appBillingWarehouseStateAll, billingState);
          set(appBillingWarehouseLoadStatus, AsyncLoadStatus.Ok);
        }
      },
  );

  return async (whIds: string[]) => {
    await cleanupState();
    await loadCallback(whIds);
  };
}

export default useLoadWarehouseBillingState;
