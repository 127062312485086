import * as React from 'react';
const FilterMenuIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FilterMenuIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M77.55,96.9v11.61h-27.1v-11.61h27.1ZM89.16,77.55v11.61h-50.32v-11.61h50.32ZM100.77,58.19v11.61H27.23v-11.61h73.55ZM112.39,38.84v11.61H15.61v-11.61h96.77ZM124,19.48v11.61H4v-11.61h120Z"
    />
  </svg>
);
export default FilterMenuIcon;
