import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { getActualityMetricDescriptorsMap } from '../../metrics/actuality/actualityMetric.default';
import {
  actualityHeatmapBuckets,
  actualityMetricHasStats,
  actualityMetricSelected,
} from '../store/actuality.heatmap.state';
import HeatmapLegendPanel from './HeatmapLegendPanel';

const ActualityHeatmapLegendPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const metric = useRecoilValue(actualityMetricSelected);
  const buckets = useRecoilValue(actualityHeatmapBuckets);
  const bucketsWithStats = useRecoilValue(actualityMetricHasStats);
  const metricDescriptorMap = getActualityMetricDescriptorsMap(t, formatter);
  const metricDescriptor = metricDescriptorMap[metric];

  return (
    <HeatmapLegendPanel
      metricDescriptor={metricDescriptor as any}
      buckets={buckets}
      hasStats={bucketsWithStats}
    />
  );
};

export default ActualityHeatmapLegendPanel;
