import * as React from 'react';
const DesignerAislesAutofillIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesAutofillIcon"
    {...props}
  >
    <rect width={120.05} height={5.42} x={4.19} y={119.46} strokeWidth={0} />
    <rect width={14} height={27.33} x={94.03} y={64.96} strokeWidth={0} />
    <rect width={14} height={27.33} x={94.03} y={35.63} strokeWidth={0} />
    <rect width={14} height={27.33} x={94.03} y={6.29} strokeWidth={0} />
    <rect width={14} height={27.33} x={110.25} y={35.63} strokeWidth={0} />
    <rect width={14} height={27.33} x={110.25} y={6.29} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={64.96} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={35.63} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={6.29} strokeWidth={0} />
    <rect width={14} height={27.33} x={4.07} y={35.63} strokeWidth={0} />
    <rect width={14} height={27.33} x={4.07} y={6.29} strokeWidth={0} />
    <rect width={14} height={27.33} x={65.11} y={6.29} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={35.63} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={6.29} strokeWidth={0} />
    <rect
      width={13.75}
      height={51.3}
      x={4.19}
      y={64.96}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={13.75}
      height={51.89}
      x={110.79}
      y={64.37}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={13.75}
      height={21.97}
      x={94.16}
      y={94.29}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={13.75}
      height={21.97}
      x={20.41}
      y={94.29}
      strokeWidth={0}
      opacity={0.65}
    />
    <polygon
      strokeWidth={0}
      points="85.28 93.56 64.21 114.58 43.13 93.52 43.13 81.07 59.79 97.71 59.79 66.07 68.62 66.08 68.62 97.72 85.28 81.1 85.28 93.56"
    />
  </svg>
);
export default DesignerAislesAutofillIcon;
