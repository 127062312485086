import * as React from 'react';
const CursorMoveBoxIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorMoveBoxIcon"
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 93.99 93.99">
        <path
          strokeWidth={0}
          d="M.51,4.38C-.93.81.81-.93,4.38.51l87.02,35.14c3.57,1.44,3.44,3.4-.29,4.36l-33.91,8.68c-3.73.96-7.56,4.79-8.52,8.52l-8.68,33.91c-.96,3.73-2.92,3.86-4.36.29L.51,4.38Z"
        />
      </symbol>
    </defs>
    <path
      strokeWidth={0}
      d="M70.11,64.45h4.33c2.21,0,4-1.79,4-4s-1.79-4-4-4h-13.29c-1.26-.25-2.62.12-3.6,1.09-.98.98-1.34,2.34-1.09,3.61v13.29c0,2.21,1.79,4,4,4s4-1.79,4-4v-4.33s10.07,10.07,10.07,10.07c.78.78,1.8,1.17,2.83,1.17s2.05-.39,2.83-1.17c1.56-1.56,1.56-4.09,0-5.66l-10.07-10.07Z"
    />
    <path
      strokeWidth={0}
      d="M74.51,85.83l-10.07,10.07v-4.33c0-2.21-1.79-4-4-4s-4,1.79-4,4v13.29c-.25,1.26.12,2.62,1.09,3.6.78.78,1.8,1.17,2.83,1.17.26,0,.52-.03.78-.08h13.29c2.21,0,4-1.79,4-4s-1.79-4-4-4h-4.33s10.07-10.07,10.07-10.07c1.56-1.56,1.56-4.09,0-5.66-1.56-1.56-4.09-1.56-5.66,0Z"
    />
    <path
      strokeWidth={0}
      d="M109.55,91.56c0-2.21-1.79-4-4-4s-4,1.79-4,4v4.33s-10.07-10.07-10.07-10.07c-1.56-1.56-4.09-1.56-5.66,0-1.56,1.56-1.56,4.09,0,5.66l10.07,10.07h-4.33c-2.21,0-4,1.79-4,4s1.79,4,4,4h13.29c.26.05.52.08.78.08,1.02,0,2.05-.39,2.83-1.17.98-.98,1.34-2.34,1.09-3.61v-13.29Z"
    />
    <path
      strokeWidth={0}
      d="M88.66,81.34c1.02,0,2.05-.39,2.83-1.17l10.07-10.07v4.33c0,2.21,1.79,4,4,4s4-1.79,4-4v-13.29c.25-1.26-.12-2.62-1.09-3.6-.98-.98-2.34-1.34-3.6-1.09h-13.29c-2.21,0-4,1.79-4,4s1.79,4,4,4h4.33s-10.07,10.07-10.07,10.07c-1.56,1.56-1.56,4.09,0,5.66.78.78,1.8,1.17,2.83,1.17Z"
    />
    <use
      xlinkHref="#a"
      width={93.99}
      height={93.99}
      transform="translate(10.11 12.11) scale(.75)"
    />
  </svg>
);
export default CursorMoveBoxIcon;
