import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import FeedActivityPanel from '../../feed/panels/FeedActivityPanel';
import FeedPoliciesPanel from '../../feed/panels/FeedPoliciesPanel';
import FeedSetupPanel from '../../feed/panels/FeedSetupPanel';
import FeedSimulationsPanel from '../../feed/panels/FeedSimulationsPanel';
import { layoutDocument } from '../../layout/store/layout.state';
import useUserPermissionFeatures from '../../permissions/roles/hooks/useUserPermissionFeatures';
import ActualityHeatmapPanel from '../panels/ActualityHeatmapPanel';
import {
  actualitySelectedDocument,
  actualitySelectedDocumentStatus,
} from '../store/actuality.state';
import {
  activityFeedSidebarMenuState,
  feedSelectedMenuItemId,
} from '../store/feed.state';

export const FeedMainSidebar: React.FC = () => {
  const { t } = useTranslation('feed');
  const actuality = useRecoilValue(actualitySelectedDocument);
  const showFeedSetup = !actuality?.activityFeedId || !actuality?.layoutId;

  const documentLoadStatus = useRecoilValue(actualitySelectedDocumentStatus);
  const [stepId, setStepId] = useRecoilState(
    feedSelectedMenuItemId(actuality?.id),
  );

  if (documentLoadStatus === AsyncLoadStatus.Loading) {
    return <LoadingIndicator selfCenter message={t`Loading Data`} />;
  }
  if (documentLoadStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator selfCenter message={t`Cannot load Data`} />;
  }

  const getContent = () => {
    if (showFeedSetup) {
      return <FeedSetupPanel />;
    }
    switch (stepId) {
      case 'feed-menu-activity':
        return <FeedActivityPanel />;
      case 'feed-menu-stats':
        return <ActualityHeatmapPanel />;
      case 'feed-menu-data-view':
        return <FeedSetupPanel />;
      case 'feed-menu-policies':
        return <FeedPoliciesPanel />;
      case 'feed-menu-setup':
        return <FeedSetupPanel />;
      case 'feed-menu-simulation':
        return <FeedSimulationsPanel />;
    }
  };

  return (
    <Container col hasOverflowY>
      {getContent()}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('feed');
  const document = useRecoilValue(layoutDocument);
  const [showMenuSidebarFeed, setShowMenuSidebarFeed] = useRecoilState(
    activityFeedSidebarMenuState,
  );
  const visibleFeatures = useUserPermissionFeatures();
  const isSidebarFeedMenuVisible = visibleFeatures.find(
    f => f.id === 'sidebar-feed-menu',
  )?.visible;

  const [stepId, setStepId] = useRecoilState(
    feedSelectedMenuItemId(document?.id),
  );

  const getTitle = () => {
    switch (stepId) {
      case 'feed-menu-activity':
        return t`Activity Feed`;
      case 'feed-menu-stats':
        return t`KPI`;
      case 'feed-menu-data-view':
        return t`Data`;
      case 'feed-menu-setup':
        return t`Feed Setup`;
      case 'feed-menu-policies':
        return t`Warehouse Policies`;
      case 'feed-menu-simulation':
        return t`in Simulations`;
    }
  };

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-feed-main',
    title: getTitle(),
    size: 'sm',
    titleAction: isSidebarFeedMenuVisible ? (
      <>
        <DropdownSelector
          classNameLabel={classNames('text-xxs xl:text-sm')}
          className={classNames(
            'ltr:mr-0.5 rtl:ml-0.5',
            'xl:ltr:mr-2 xl:rtl:ml-2',
          )}
          onClick={e => {
            e.stopPropagation();
          }}
          DropAlignLeft
          buttonTransparent
          vertical
          panelMode
          value={``}
          values={[showMenuSidebarFeed ? t`Hide Setup` : t`Show Setup`]}
          onChange={async (option, e) => {
            setShowMenuSidebarFeed(!showMenuSidebarFeed);
          }}
        />
        {getTitle()}
      </>
    ) : (
      getTitle()
    ),
  };

  return (
    <SidebarContainer {...containerProps}>
      <FeedMainSidebar />
    </SidebarContainer>
  );
};
