import { ItemSetSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import { Card } from '../components/cards/Card';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import { StatListItem } from '../components/stats/StatListItem';

export type DashboardCardProps = {
  summary: ItemSetSummaryFragment;
  itemLimit?: number | 20;
  isActionable?: boolean;
};

const ItemSetDashboardCard: React.FC<DashboardCardProps> = props => {
  const { t } = useTranslation('simulation');

  const { summary } = props;
  if (!summary) return null;

  const { itemLimit } = props;

  return (
    <Card transparent isActionable label={`ItemSetDashboardCard`}>
      <StatGroup
        helpNavTo={'simulation/dataset/simulation-dataset-stats-items'}
      >
        <Stat
          dashboardView
          isPreview
          title={t`Items`}
          value={formatInteger(summary.totalItemsCount).toString()}
        />
        <Stat dashboardView isPreview title={t`Items by client`}>
          {/* {_.take(summary.consignees, itemLimit).map(cons => (
            <StatListItem
              key={`si-by-sky-${cons.consignee}`}
              title={cons.consignee}
              value={formatInteger(cons.itemsCount).toString()}
            />
          ))} */}

          {_.take(
            _.sortBy(summary.consignees, itemLimit => -itemLimit.itemsCount),
            itemLimit,
          ).map(cons => (
            <StatListItem
              key={`si-by-item-${cons.consignee}`}
              title={cons.consignee}
              value={formatInteger(cons.itemsCount).toString()}
            />
          ))}
        </Stat>
      </StatGroup>
    </Card>
  );
};

export default ItemSetDashboardCard;
