import * as React from 'react';
const PolicyStackingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyStackingIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64 101.45 32.94 69.17 32.94 82.99 36.87 87.14 40.87 84.31 45.67 89.37 45.67 96.72 49.6 100.87 52.82 97.83 60.43 105.48 60.43 111.04 64 114.81 67.57 111.04 67.57 105.48 75.18 97.83 78.4 100.87 82.33 96.72 82.33 89.37 87.13 84.31 91.13 87.14 95.06 82.99 95.06 69.17 64 101.45"
    />
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <polygon
      strokeWidth={0}
      points="39.72 68.76 39.72 45.35 62.16 67.19 62.16 90.95 39.72 68.76"
    />
    <polygon
      strokeWidth={0}
      points="66.49 67.19 88.69 45.37 88.69 68.77 66.49 90.93 66.49 67.19"
      opacity={0.6}
    />
    <polyline
      strokeWidth={0}
      points="75.26 28.1 63.89 17.21 52.58 28.1 63.96 38.98 75.26 28.1"
    />
    <polyline
      strokeWidth={0}
      points="88.22 40.63 76.84 29.75 65.54 40.63 76.91 51.52 88.22 40.63"
      opacity={0.4}
    />
    <polyline
      strokeWidth={0}
      points="62.38 40.63 51.01 29.75 39.7 40.63 51.08 51.52 62.38 40.63"
      opacity={0.8}
    />
    <polyline
      strokeWidth={0}
      points="75.34 53.17 63.96 42.28 52.66 53.17 64.04 64.05 75.34 53.17"
      opacity={0.6}
    />
  </svg>
);
export default PolicyStackingIcon;
