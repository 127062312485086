import { AnalyzeResultEventTypeSummary } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AnalyzeEventsDetailsPanel from '../../simulation/panels/AnalyzeEventsDetailsPanel';
import FeedProcessPie from '../components/FeedProcessPie';
import { actualityAnalyzedEventsSummary } from '../store/actuality.state';
import { feedProcessTypeTotals } from '../store/feed.state';

const FeedEventsStats: React.FC = () => {
  const { t } = useTranslation('feed');
  const analyzeEventsSummary = useRecoilValue(actualityAnalyzedEventsSummary);
  const processTypeTotals = useRecoilValue(feedProcessTypeTotals);

  return (
    <>
      <FeedProcessPie />
      {_.chain(processTypeTotals)
        .map(original => {
          const events: (AnalyzeResultEventTypeSummary & {
            distance: number;
          })[] = _.filter(
            analyzeEventsSummary,
            analyze => analyze.processType === original.eventProcessType,
          ).map(e => ({
            count: e.eventsCount,
            eventType: e.eventType as any,
            totalCost: e.cost,
            totalDuration: e.duration,
            distance: e.distance,
          }));

          const eventsTotal = _.transform(
            events,
            (current, e) => {
              current.distance += e.distance;
              current.duration += e.totalDuration;
              current.cost += e.totalCost;
            },
            {
              distance: 0,
              duration: 0,
              cost: 0,
            },
          );

          if (events.length === 0) {
            return null;
          }

          return { original, events, eventsTotal };
        })
        .filter(Boolean)
        // Sort by duration in descending order
        .sortBy(item => -item.eventsTotal.duration)
        .map(({ original, events, eventsTotal }) => (
          <AnalyzeEventsDetailsPanel
            key={`event-group-${original.eventProcessType}-${original.eventType}`}
            title={t(`{{title}}`, { title: original.eventProcessType })}
            subTitle={t`Activity Summary`}
            totalTitle={original.eventProcessType}
            events={events}
            eventsTotal={eventsTotal}
            showKpiSwitch
            showTotalStats
            showLegendStats
          />
        ))
        .value()}
    </>
  );
};

export default FeedEventsStats;
