import {
  Field,
  ID,
  InputAndObjectType,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { AssignmentChange } from './assignment-change.model';
import {
  BayWidthConstraintSettings,
  LocationSizeConstraintSettings,
} from './constraint.model';
import { OrderSetFilter } from './dataset-filters.model';
import { LoadBalancingPolicy } from './load-balancing-policy.model';
import {
  LocationFilterUnion,
  ResourcePolicy,
  SimulationItemFilterUnion,
} from './policies';
import { ReassignJobsSettings } from './reassign.settings';

export enum SwapMode {
  ALL = 'ALL',
  OCCUPIED_ONLY = 'OCCUPIED_ONLY',
  USED_ONLY = 'USED_ONLY',
}

registerEnumType(SwapMode, {
  name: 'SwapMode',
});

export enum UnassignedLocationsMatch {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
  UNASSIGNED_ONLY = 'UNASSIGNED_ONLY',
}

registerEnumType(UnassignedLocationsMatch, {
  name: 'UnassignedLocationsMatch',
});

@InputAndObjectType()
export class AssignmentSubsetSpec {
  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  itemsMatch?: SimulationItemFilterUnion;

  @Field({
    nullable: true,
    deprecationReason:
      'in favour of unassignedLocationsMatch = EXCLUDE/INCLUDE',
  })
  allowEmptyLocations?: boolean;

  @Field(() => UnassignedLocationsMatch, {
    nullable: true,
  })
  unassignedLocationsMatch?: UnassignedLocationsMatch;
}

@InputAndObjectType()
export class OptimizationSwapRule {
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => AssignmentSubsetSpec, { nullable: true })
  src?: AssignmentSubsetSpec;

  @Field(() => AssignmentSubsetSpec, { nullable: true })
  dest?: AssignmentSubsetSpec;
}

export enum DeadStockSwapMode {
  SWAP_DEAD_STOCK_WITH_OTHERS = 'SWAP_DEAD_STOCK_WITH_OTHERS',
  EXCLUDE_DEAD_STOCK = 'EXCLUDE_DEAD_STOCK',
  INCLUDE_DEAD_STOCK = 'INCLUDE_DEAD_STOCK',
}

registerEnumType(DeadStockSwapMode, {
  name: 'DeadStockSwapMode',
});

@InputAndObjectType()
export class SwapSettings {
  @Field(() => SwapMode, {
    nullable: true,
    deprecationReason: 'in favour of detailed rules',
  })
  swapMode?: SwapMode;

  @Field(() => [OptimizationSwapRule], { nullable: true })
  rules?: OptimizationSwapRule[];

  @Field(() => DeadStockSwapMode, { nullable: true })
  deadStock?: DeadStockSwapMode;
}

@InputAndObjectType()
export class LimitSettings {
  @Field({ nullable: true })
  maxMoves?: number;
}

@InputAndObjectType()
export class MinImprovementRateSettings {
  @Field({
    description:
      'Stop optimization if improvement change for the given number of steps is below this limit',
  })
  minImprovementChange: number;

  @Field({ description: 'Window to measure improvement change, in steps' })
  steps: number;
}

@InputAndObjectType()
export class TerminationSettings {
  @Field({
    nullable: true,
    description:
      'Stop optimization when this improvement is achieved (in percent)',
  })
  targetImprovement?: number;

  @Field({
    nullable: true,
    description: 'Stop optimization after running for this time (in seconds)',
  })
  timeLimit?: number;

  @Field(() => MinImprovementRateSettings, {
    nullable: true,
    description:
      'Stop optimization when improvement rate is below the given one',
  })
  minImprovementRate?: MinImprovementRateSettings;
}

@InputAndObjectType()
export class OptimizationPolicyOverrides {
  @Field({ nullable: true })
  resourcePolicy?: ResourcePolicy;
}

export enum PickabilityConstraintMode {
  NO_MOVES_BETWEEN_PICKING_RULES = 'NO_MOVES_BETWEEN_PICKING_RULES',
  KEEP_PICKABILITY = 'KEEP_PICKABILITY',
  NO_CONSTRAINT = 'NO_CONSTRAINT',
}

registerEnumType(PickabilityConstraintMode, {
  name: 'PickabilityConstraintMode',
});

@InputAndObjectType()
export class PickabilityConstraintSettings {
  @Field({ nullable: true })
  disabled?: boolean;

  @Field(() => PickabilityConstraintMode, { nullable: true })
  mode?: PickabilityConstraintMode;
}

@InputAndObjectType()
export class AssignmentCapacitySettings {
  @Field({ nullable: true })
  avoidMerge?: boolean;
}

@InputAndObjectType()
export class OptimizationSettings {
  @Field(() => SwapSettings, { nullable: true })
  swapSettings?: SwapSettings;

  @Field(() => LoadBalancingPolicy, { nullable: true })
  loadBalancing?: LoadBalancingPolicy;

  @Field(() => LocationSizeConstraintSettings, { nullable: true })
  locationSizeConstraint?: LocationSizeConstraintSettings;

  @Field(() => BayWidthConstraintSettings, { nullable: true })
  bayWidthConstraint?: BayWidthConstraintSettings;

  @Field(() => LimitSettings, { nullable: true })
  limitSettings?: LimitSettings;

  @Field(() => TerminationSettings, { nullable: true })
  terminationSettings?: TerminationSettings;

  @Field(() => ReassignJobsSettings, { nullable: true })
  reassignJobs?: ReassignJobsSettings;

  @Field(() => OptimizationPolicyOverrides, { nullable: true })
  policyOverrides?: OptimizationPolicyOverrides;

  @Field(() => PickabilityConstraintSettings, { nullable: true })
  pickabilityConstraint?: PickabilityConstraintSettings;

  @Field(() => AssignmentCapacitySettings, { nullable: true })
  assignmentCapacitySettings?: AssignmentCapacitySettings;

  @Field(() => OrderSetFilter, { nullable: true })
  orderSetFilter?: OrderSetFilter;
}

export enum OptimizationRunSourceType {
  SIMULATION = 'SIMULATION',
  ALLOCATION_RUN = 'ALLOCATION_RUN',
}

registerEnumType(OptimizationRunSourceType, {
  name: 'OptimizationRunSourceType',
});

@InputAndObjectType()
export class OptimizationRunSourceReference {
  @Field(() => OptimizationRunSourceType)
  type: OptimizationRunSourceType;

  @Field(() => ID)
  id: string;
}

@ObjectType()
export class OptimizationRunSettings extends OptimizationSettings {
  @Field(() => OptimizationRunSourceReference, { nullable: true })
  source?: OptimizationRunSourceReference;

  @Field(() => AssignmentChange, { nullable: true })
  initialAssignmentChange?: AssignmentChange;
}
