import 'reflect-metadata';

export function InputType(): ClassDecorator {
  return () => {
    // no op
  };
}

export function Field(): PropertyDecorator {
  return () => {
    // no op
  };
}

export function ObjectType(): PropertyDecorator {
  return () => {
    // no op
  };
}

export function InterfaceType(): PropertyDecorator {
  return () => {
    // no op
  };
}

export function InputAndObjectType(): PropertyDecorator {
  return () => {
    // no op
  };
}

interface Type<T = any> extends Function {
  new (...args: any[]): T;
}

export function PickType<T, K extends keyof T>(
  classRef: Type<T>,
  keys: readonly K[],
  decorator?: any,
): Type<Pick<T, (typeof keys)[number]>> {
  return classRef;
}

export function PartialType<T>(
  classRef: Type<T>,
  decorator?: any,
): Type<Partial<T>> {
  return classRef;
}

export function OmitType<T, K extends keyof T>(
  classRef: Type<T>,
  keys: readonly K[],
  decorator?: any,
): Type<Pick<T, (typeof keys)[number]>> {
  return classRef;
}

export function IntersectionType<A, B>(
  classARef: Type<A>,
  classBRef: Type<B>,
  decorator?: any,
): Type<A & B> {
  return classARef as any;
}

export const ID = 'string';
export const Int = 'int';
export const GraphQLTimestamp = 'string';
export const GraphQLLocalDateTime = 'string';
export const GraphQLLocalTime = 'string';
export const GraphQLJSON = 'any';

export type EnumOptions<T> = any;

export function registerEnumType<T extends object = any>(
  enumRef: T,
  options: EnumOptions<T>,
): void {
  // no op
}

export type UnionOptions<T extends readonly Type<unknown>[] = Type<unknown>[]> =
  any;

export declare type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType[number];
export declare type Union<T extends readonly any[]> = InstanceType<
  ArrayElement<T>
>;

export function createUnionType<
  T extends readonly Type<unknown>[] = Type<unknown>[],
>(options: UnionOptions<T>): Union<T> {
  return null;
}
