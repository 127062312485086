import classNames from 'classnames';
import React, { useId } from 'react';

export type HelperLearnMoreProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
};

export const HelperLearnMore = ({
  children,
  title,
  className,
}: HelperLearnMoreProps) => {
  const id = useId();
  return (
    <span
      data-component="HelperLearnMore"
      id={id}
      className={classNames(
        'font-bold text-menu-active',
        'any-hover:text-menu-active-hover',
        'cursor-pointer',
        'text-xxs uppercase',
      )}
    >
      {title}
      {children}
    </span>
  );
};
