import _ from 'lodash';
import Flatten from '@flatten-js/core';
import { Aisle, Bay, Location } from '@warebee/shared/engine-model';
import { loadPolygon } from './geometry.engine';

export function getAisleShape(a: Pick<Aisle, 'points'>): Flatten.Polygon {
  return loadPolygon(a.points);
}

export function getBayShape(
  b: Pick<Bay, 'position' | 'frontEdge' | 'depth'>,
): Flatten.Polygon {
  const anchor = Flatten.point(b.position.x, b.position.y);
  const frontEdge = Flatten.vector(b.frontEdge.x, b.frontEdge.y);
  const sideEdge = frontEdge.rotate90CCW().normalize().multiply(b.depth);

  const p0 = anchor;
  const p1 = p0.translate(sideEdge);
  const p2 = p1.translate(frontEdge);
  const p3 = anchor.translate(frontEdge);

  // CW orientation
  return new Flatten.Polygon([p0, p1, p2, p3]);
}
export function getInnerBayShape(
  b: Pick<Bay, 'position' | 'frontEdge' | 'depth' | 'width'>,
  frameWidth: number,
): Flatten.Polygon {
  const offset = frameWidth;
  const anchor = Flatten.point(b.position.x, b.position.y);

  const frontEdge = Flatten.vector(b.frontEdge.x, b.frontEdge.y).normalize();
  const sideEdge = frontEdge.rotate90CCW().multiply(b.depth);

  const p0 = anchor.translate(frontEdge.multiply(offset));
  const p3 = anchor.translate(frontEdge.multiply(b.width - offset));
  const p1 = p0.translate(sideEdge);
  const p2 = p3.translate(sideEdge);

  // CW orientation
  return new Flatten.Polygon([p0, p1, p2, p3]);
}

export type BayToBindLocations = Pick<Bay, 'id' | 'locationOrder' | 'width'>;
export type LocationToBind = Pick<
  Location,
  'locationId' | 'locationBayPosition' | 'locationWidth'
>;
export type BayLocationToBind = LocationToBind &
  Pick<Location, 'locationWidth'>;

export interface BindBayLocationsInput {
  bay: BayToBindLocations;
  locations: LocationToBind[];
}

export function groupByBayId<L extends Pick<Location, 'locationBayId'>>(
  locations: L[],
) {
  return _.groupBy(locations, l => l.locationBayId);
}
