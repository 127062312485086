import { FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateImportPipelineDocument,
  UpdateImportPipelineInput,
  UpdateImportPipelineMutation,
  UpdateImportPipelineMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { errorAppender } from '../../store/error.state';
import {
  importPipelineIsActiveEditor,
  importPipelineUpdateStatus,
  importPipelinesDataTableState,
} from '../store/importPipelines.state';

export type UpdateImportPipelineParams = {
  patch: Partial<UpdateImportPipelineInput>;
};

function useUpdateImportPipeline() {
  const client = useApolloClient();
  const { t } = useTranslation('dataset');

  const beforeUpdate = useRecoilCallback(({ set }) => async () => {
    set(importPipelineUpdateStatus, AsyncLoadStatus.Loading);
  });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (patch: UpdateImportPipelineInput) => {
        let response: FetchResult<UpdateImportPipelineMutation>;
        const tableState = await snapshot.getPromise(
          importPipelinesDataTableState,
        );
        try {
          response = await client.mutate<
            UpdateImportPipelineMutation,
            UpdateImportPipelineMutationVariables
          >({
            mutation: UpdateImportPipelineDocument,
            variables: {
              input: {
                ...cloneWithoutTypename(patch),
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not update pipeline`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(importPipelineUpdateStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not update pipeline`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(importPipelineUpdateStatus, AsyncLoadStatus.Error);
          return;
        }
        set(importPipelineUpdateStatus, AsyncLoadStatus.Ok);
        set(importPipelinesDataTableState, {
          ...tableState,
          searchValues: { ...tableState?.searchValues },
        });
        set(importPipelineIsActiveEditor, false);
      },
  );

  return async (patch: UpdateImportPipelineInput) => {
    await beforeUpdate();
    await callUpdate(patch);
  };
}
export default useUpdateImportPipeline;
