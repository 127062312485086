import {
  AisleSide,
  BayLocationOrder,
} from '@warebee/frontend/data-access-api-graphql';
import { BayRowBuilderSettings } from '@warebee/shared/data-access-layout-import-converter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import * as Icon from '../../../../components/icons';
import InputNumber from '../../../../components/inputs/InputNumber';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import PanelContainer from '../../../../containers/PanelContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import { converterEditableArea } from '../store/converter.area.state';
import {
  converterLayoutSelectedAisle,
  converterLayoutSelectedAreaBayMap,
} from '../store/converter.layout.state';
import {
  converterAisleBuilder,
  converterAreaBuilder,
} from '../store/converter.state';
import ConverterAisleSidePanel from './ConverterAisleSidePanel';

const ConverterAisleInfoPanel: React.FC = () => {
  const wh = useRecoilValue(warehouseSelected);
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const area = useRecoilValue(converterEditableArea);
  const aisle = useRecoilValue(converterLayoutSelectedAisle);
  const areaBuilder = useRecoilValue(converterAreaBuilder(area?.id));
  const [aisleBuilder, setAisleBuilder] = useRecoilState(
    converterAisleBuilder([area?.id, aisle?.id]),
  );
  const bayMap = useRecoilValue(converterLayoutSelectedAreaBayMap);

  if (!area || !aisle) return null;

  function applyBayOrderChange(
    side: AisleSide,
    settings: BayRowBuilderSettings,
  ) {
    setAisleBuilder({
      ...aisleBuilder,
      bayRowSettings: {
        ...aisleBuilder.bayRowSettings,
        [side]: settings,
      },
    });
  }

  function applyLocationOrderChange(side: AisleSide, order: BayLocationOrder) {
    setAisleBuilder({
      ...aisleBuilder,
      locationOrderSettings: {
        ...(aisleBuilder.locationOrderSettings ?? {}),
        [side]: order,
      },
    });
  }
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <ContainerScroll>
        <WarehouseNavigator>
          <WarehouseNavigatorItem active name={t`Area`} value={area.id} />
          <WarehouseNavigatorItem
            name={t`Aisle`}
            value={aisle.title}
            active
            aria-label={aisle.title}
          />
        </WarehouseNavigator>
        <PanelContainer
          key={`panel-aisle-properties-${aisle.id}`}
          id="panel-aisle-properties"
          title={t`Aisle Properties`}
          collapsible
          hasPadding
        >
          <InputNumber
            title={t`Aisle width`}
            icon={<Icon.ObjectAisleWidth className="w-8 h-8 fill-current" />}
            value={aisleBuilder.width ?? areaBuilder.aisleWidth}
            range={[1, null]}
            unitOfMeasure={sizeMeasureValue}
            onChange={v =>
              setAisleBuilder({
                ...aisleBuilder,
                width: v,
              })
            }
          />
        </PanelContainer>
        {aisle.leftBays && aisle.rightBays && (
          <PanelContainer
            id="panel-bay-order"
            title={t`Aisle Bay Order`}
            collapsible
            hasPadding
          >
            <ConverterAisleSidePanel
              side={AisleSide.LEFT}
              bays={aisle.leftBays}
              builder={aisleBuilder.bayRowSettings[AisleSide.LEFT]}
              onChangeBayOrder={settings =>
                applyBayOrderChange(AisleSide.LEFT, settings)
              }
              bayMap={bayMap}
              locationOrder={
                aisleBuilder.locationOrderSettings?.[AisleSide.LEFT] ??
                BayLocationOrder?.LTR
              }
              onChangeLocationOrder={v =>
                applyLocationOrderChange(AisleSide.LEFT, v)
              }
            />

            <ConverterAisleSidePanel
              side={AisleSide.RIGHT}
              bays={aisle.rightBays}
              builder={aisleBuilder.bayRowSettings[AisleSide.RIGHT]}
              onChangeBayOrder={settings =>
                applyBayOrderChange(AisleSide.RIGHT, settings)
              }
              onChangeLocationOrder={v =>
                applyLocationOrderChange(AisleSide.RIGHT, v)
              }
              bayMap={bayMap}
              locationOrder={
                aisleBuilder.locationOrderSettings?.[AisleSide.RIGHT] ??
                BayLocationOrder?.RTL
              }
            />
          </PanelContainer>
        )}
      </ContainerScroll>
    </div>
  );
};

export default ConverterAisleInfoPanel;
