import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import * as Icon from '../components/icons';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import AnalyzePanel from '../simulation/panels/AnalyzePanel';
import { simulationAnalyzeTypeSelected } from '../simulation/store/simulation.state';
import { AnalyzeComponentBaseProps } from './analyze.types';
import AnalyzeResultIssuesMessage from './helperMessages/AnalyzeResultIssuesMessage';

/**
 *  Main panel for AnalyzeResult  (both analyze/optimize)
 *  expected successful analyze result passed as parameter
 * @param props
 * @returns
 */

const AnalyzeResultPanel: React.FC<AnalyzeComponentBaseProps> = props => {
  const { analyzeResult } = props;
  const { t } = useTranslation('simulation');
  const analyzeType = useRecoilValue(simulationAnalyzeTypeSelected);

  const hasIssues = analyzeResult.totalDistance === 0;

  const titleSidebar =
    analyzeType === 'initial' ? t`Analyse Report` : t`Allocate Forecast`;

  return (
    <>
      {/* <Container col> */}
      <ScreenTitle
        subtitle={t`Analyse`}
        title={titleSidebar}
        helpNavTo={'simulation/analyse/simulation-analyse'}
        icon={Icon.SimulationAnalyze}
        isSticky={false}
      />

      {hasIssues && <AnalyzeResultIssuesMessage />}
      {/* {!hasIssues && <AnalyzeResultSuccessMessage />} */}

      <AnalyzePanel analyzeResult={analyzeResult} />
      {/* </Container > */}
    </>
  );
};

export default AnalyzeResultPanel;
