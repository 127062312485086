import {
  AnalyzeResultEventTypeSummary,
  AnalyzeResultProcessType,
  PicklistEventType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getCurrencySymbolSettings } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { cn } from '../../common/utils';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import StatItem from '../../components/stats/StatItem';
import { warehouseSelected } from '../../store/warehouse.state';
import { LCV_EU_Multiplier } from '../store/resourcePolicy.default';
import {
  AnalyzeResultType,
  EventGroup,
  EventGroupGlobal,
  EventGroupKeys,
  EventGroupMap,
  KPI,
  getEventGroupColor,
  getEventGroupTitle,
  getKPITitles,
} from '../store/simulation.helper';
import { EventsDetailsPanelBaseProps } from './AnalyzeEventsDetailsPanel';
import StackedBar from './StackedBar';

export type OptimisationEventsCompareProps = EventsDetailsPanelBaseProps & {
  eventsBefore: AnalyzeResultEventTypeSummary[];
  eventsAfter: AnalyzeResultEventTypeSummary[];
  energyRate: number;
};

const chartMinHeight = 300;

export type EventGroupGlobalValues = Record<EventGroupGlobal, number>;
export type EventGroupValues = Record<EventGroup, number>;
export type EventGroupExtendedValues = EventGroupValues & {
  idle: number;
  overwork: number;
  total: number;
};

export type CompareRawDatum = {
  analyzeType: AnalyzeResultType;
} & EventGroupValues;

const OptimisationEventsComparePanel: React.FC<
  OptimisationEventsCompareProps
> = props => {
  const { t } = useTranslation('simulation');
  const wh = useRecoilValue(warehouseSelected);

  const [kpi, setKpi] = useState<KPI>('cost');
  const formatter = useFormatter();

  const KPIs: KPI[] = ['cost', 'duration', 'co2'];

  function getFormatter(kpi: KPI) {
    switch (kpi) {
      case 'cost':
        return formatter.formatCurrency;
      case 'duration':
        return formatter.formatTimeSpan;
      case 'co2':
        return formatter.formatCO2;
      default:
        return formatter.formatCurrency;
    }
  }

  function getEventKPIValue(event: AnalyzeResultEventTypeSummary, kpi: KPI) {
    switch (kpi) {
      case 'cost':
        return event.totalCost;
      case 'duration':
        return event.totalDuration;
      case 'co2':
        return event.eventType === PicklistEventType.TRAVELLING_HORIZONTAL ||
          event.eventType === PicklistEventType.TRAVELLING_VERTICAL
          ? event.totalDuration * props.energyRate * LCV_EU_Multiplier
          : 0;
      default:
        return 0;
    }
  }

  const eventsGroupsBefore: EventGroupValues = _.reduce(
    _.filter(
      props.eventsBefore,
      e => e.processType === AnalyzeResultProcessType.PICKING,
    ),
    (acc, event) => {
      return {
        ...acc,
        [EventGroupMap[event.eventType]]:
          (acc[EventGroupMap[event.eventType]] ?? 0) +
          getEventKPIValue(event, kpi),
      };
    },
    {} as EventGroupValues,
  );

  const eventsGroupsAfter: EventGroupValues = _.reduce(
    _.filter(
      props.eventsAfter,
      e => e.processType === AnalyzeResultProcessType.PICKING,
    ),
    (acc, event) => {
      return {
        ...acc,
        [EventGroupMap[event.eventType]]:
          (acc[EventGroupMap[event.eventType]] ?? 0) +
          getEventKPIValue(event, kpi),
      };
    },
    {} as EventGroupValues,
  );

  const combinedDatum: CompareRawDatum[] = [
    {
      analyzeType: 'before',
      ...eventsGroupsBefore,
    },
    {
      analyzeType: 'after',
      ...eventsGroupsAfter,
    },
  ];

  const formatFn = getFormatter(kpi);

  const kpiSwitch = props.showKpiSwitch ? (
    <ButtonSwitchMulti
      autoSize
      options={[
        {
          label: t`Cost`,
          icon: getCurrencySymbolSettings(wh.currency).symbol,
        },
        {
          label: t`Time`,
          icon: 'h',
        },
        {
          label: t`CO2`,
          icon: 'CO2',
        },
      ]}
      selectedIndex={KPIs.indexOf(kpi)}
      onClick={index => {
        setKpi(KPIs[index]);
      }}
    />
  ) : null;

  return (
    <StatGroup
      title={props.title}
      subTitle={t`Stats`}
      helpNavTo={'simulation/optimise/simulation-optimise-summary-events'}
    >
      <Stat
        hasHelper
        isPreview
        inPanelMode
        cardView={props.cardView}
        title={getKPITitles(kpi, t)}
        switchUnitOfMeasure={kpiSwitch}
      >
        <div
          data-component="StackedBarContainer"
          className={cn(
            'relative',
            'flex flex-1 flex-col items-center',
            'p-0.5 md:p-1 xl:p-2',
          )}
        >
          <div
            data-component="StackedBarWrapper"
            style={{ height: chartMinHeight, minWidth: '100%' }}
          >
            <StackedBar data={combinedDatum} valueFormatter={formatFn} />
          </div>
        </div>

        {props.showLegendStats && (
          <div className="text-xs">
            {_.map(EventGroupKeys, key => {
              const beforeValue = eventsGroupsBefore?.[key] ?? 0;
              const afterValue = eventsGroupsAfter?.[key] ?? 0;

              // Only show if either before or after values are greater than 0
              if (beforeValue === 0 && afterValue === 0) return null;

              return (
                <StatItem
                  key={`event-group-${key}`}
                  title={getEventGroupTitle(key, t)}
                  value={formatFn(beforeValue)}
                  valueToCompare={formatFn(afterValue)}
                  legendColor={getEventGroupColor(key)}
                />
              );
            })}
          </div>
        )}
      </Stat>
    </StatGroup>
  );
};

export default OptimisationEventsComparePanel;
