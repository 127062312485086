import React from 'react';

import useSharedRef from '../../utils/use-shared-ref';
import * as Icon from '../icons';

export type ButtonOrderProps = {
  children?: React.ReactNode;
  className?: string;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  moveUp?: (e: any) => void;
  moveDown?: (e: any) => void;
  isDisabled?: boolean;
};

const ButtonOrder = React.forwardRef<HTMLInputElement, ButtonOrderProps>(
  (
    {
      children,
      className,
      canMoveUp,
      canMoveDown,
      moveUp,
      moveDown,
      isDisabled,
      ...props
    }: ButtonOrderProps,
    ref,
  ) => {
    const sharedRef = useSharedRef(ref);

    return (
      <div
        data-component="ButtonOrder"
        className="flex flex-col space-y-1 group items-center"
      >
        <button
          type="button"
          className={`p-1 text-menu-active hover:opacity-75 ${
            (isDisabled || !canMoveUp) && 'opacity-20 cursor-not-allowed'
          }`}
          disabled={isDisabled || !canMoveUp}
          onClick={moveUp}
          aria-label={'ButtonOrder-Up'}
        >
          <Icon.ArrowTop className="w-5 fill-current" />
        </button>
        <button
          type="button"
          className={`p-1 text-menu-active hover:opacity-75 ${
            (isDisabled || !canMoveDown) && 'opacity-20 cursor-not-allowed'
          }`}
          disabled={isDisabled || !canMoveDown}
          onClick={moveDown}
          aria-label={'ButtonOrder-Down'}
        >
          <Icon.ArrowBottom className="w-5 fill-current" />
        </button>
        {children}
      </div>
    );
  },
);

export default ButtonOrder;
