import * as React from 'react';
const AisleIcon = props => (
  <svg
    aria-hidden="true"
    data-name="AisleIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon points="45.97 124.5 21.61 124.78 30.57 102.35 37.12 102.35 45.97 124.5" />
    <g>
      <polygon points="5.86 106.18 5.86 120.27 15.79 106.18 5.86 106.18" />
      <polygon points="5.86 67.05 5.86 81.15 15.79 67.05 5.86 67.05" />
      <polygon points="16.6 123.08 16.6 109.08 6.73 123.08 16.6 123.08" />
      <polygon points="5.86 86.62 5.86 100.71 15.79 86.62 5.86 86.62" />
      <polygon points="16.6 83.96 16.6 69.95 6.73 83.96 16.6 83.96" />
      <polygon points="16.6 103.52 16.6 89.51 6.73 103.52 16.6 103.52" />
      <polygon points="5.86 45.73 5.86 59.82 15.79 45.73 5.86 45.73" />
      <polygon points="5.86 6.6 5.86 20.7 15.79 6.6 5.86 6.6" />
      <polygon points="16.6 62.63 16.6 48.63 6.73 62.63 16.6 62.63" />
      <polygon points="5.86 26.17 5.86 40.26 15.79 26.17 5.86 26.17" />
      <polygon points="16.6 23.51 16.6 9.5 6.73 23.51 16.6 23.51" />
      <polygon points="16.6 43.07 16.6 29.06 6.73 43.07 16.6 43.07" />
      <path d="m18.92,4.02H3.61v120.9h15.31l9.48-22.97v-20.05L18.92,4.02ZM5.39,44.33v-.77h-1.14v-18.53h1.14v-.27h0v-.77h-1.14V5.47h1.14v-.63h11.67v.63h1.14v18.53h-1.14v.42h0v.62h1.14v18.53h-1.14v.42h0v.62h1.14v18.53h-1.14v.77H5.39v-.77h-1.14v-18.53h1.14v-.27h0Zm11.67,40.54v.62h1.14v18.53h-1.14v.42h0v.62h1.14v18.53h-1.14v.77H5.39v-.77h-1.14v-18.53h1.14v-.27h0v-.77h-1.14v-18.53h1.14v-.27h0v-.77h-1.14v-18.53h1.14v-.63h11.67v.63h1.14v18.53h-1.14v.42h0Z" />
    </g>
    <g>
      <polygon points="61.46 106.18 61.46 120.27 51.54 106.18 61.46 106.18" />
      <polygon points="61.46 67.05 61.46 81.15 51.54 67.05 61.46 67.05" />
      <polygon points="50.73 123.08 50.73 109.08 60.59 123.08 50.73 123.08" />
      <polygon points="61.46 86.62 61.46 100.71 51.54 86.62 61.46 86.62" />
      <polygon points="50.73 83.96 50.73 69.95 60.59 83.96 50.73 83.96" />
      <polygon points="50.73 103.52 50.73 89.51 60.59 103.52 50.73 103.52" />
      <polygon points="61.46 45.73 61.46 59.82 51.54 45.73 61.46 45.73" />
      <polygon points="61.46 6.6 61.46 20.7 51.54 6.6 61.46 6.6" />
      <polygon points="50.73 62.63 50.73 48.63 60.59 62.63 50.73 62.63" />
      <polygon points="61.46 26.17 61.46 40.26 51.54 26.17 61.46 26.17" />
      <polygon points="50.73 23.51 50.73 9.5 60.59 23.51 50.73 23.51" />
      <polygon points="50.73 43.07 50.73 29.06 60.59 43.07 50.73 43.07" />
      <path d="m38.93,81.9v10.03s0,10.03,0,10.03l9.48,22.97h15.31s0-60.45,0-60.45V4.02s-15.31,0-15.31,0l-9.48,77.88Zm23-37.58v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h-11.67v-.77s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0v-.42s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0v-.42s-.57,0-.57,0h-.57V5.47s1.13,0,1.13,0v-.62h11.67s0,.62,0,.62h1.14v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0Zm-11.67,40.54v-.42s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h11.67s0,.62,0,.62h1.14v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h-11.67s0-.63,0-.63v-.14s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0v-.42s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0Z" />
    </g>
    <polygon points="106.36 124.5 82 124.78 90.96 102.35 97.51 102.35 106.36 124.5" />
    <g>
      <polygon points="66.25 106.18 66.25 120.27 76.18 106.18 66.25 106.18" />
      <polygon points="66.25 67.05 66.25 81.15 76.18 67.05 66.25 67.05" />
      <polygon points="76.98 123.08 76.98 109.08 67.12 123.08 76.98 123.08" />
      <polygon points="66.25 86.62 66.25 100.71 76.18 86.62 66.25 86.62" />
      <polygon points="76.98 83.96 76.98 69.95 67.12 83.96 76.98 83.96" />
      <polygon points="76.98 103.52 76.98 89.51 67.12 103.52 76.98 103.52" />
      <polygon points="66.25 45.73 66.25 59.82 76.18 45.73 66.25 45.73" />
      <polygon points="66.25 6.6 66.25 20.7 76.18 6.6 66.25 6.6" />
      <polygon points="76.98 62.63 76.98 48.63 67.12 62.63 76.98 62.63" />
      <polygon points="66.25 26.17 66.25 40.26 76.18 26.17 66.25 26.17" />
      <polygon points="76.98 23.51 76.98 9.5 67.12 23.51 76.98 23.51" />
      <polygon points="76.98 43.07 76.98 29.06 67.12 43.07 76.98 43.07" />
      <path d="m79.31,4.02h-15.31v120.9h15.31l9.48-22.97v-20.05L79.31,4.02Zm-13.52,40.31v-.77h-1.14v-18.53h1.14v-.27h0v-.77h-1.14V5.47h1.14v-.63h11.67v.63h1.14v18.53h-1.14v.42h0v.62h1.14v18.53h-1.14v.42h0v.62h1.14v18.53h-1.14v.77h-11.67v-.77h-1.14v-18.53h1.14v-.27h0Zm11.67,40.54v.62h1.14v18.53h-1.14v.42h0v.62h1.14v18.53h-1.14v.77h-11.67v-.77h-1.14v-18.53h1.14v-.27h0v-.77h-1.14v-18.53h1.14v-.27h0v-.77h-1.14v-18.53h1.14v-.63h11.67v.63h1.14v18.53h-1.14v.42h0Z" />
    </g>
    <g>
      <polygon points="121.85 106.18 121.85 120.27 111.93 106.18 121.85 106.18" />
      <polygon points="121.85 67.05 121.85 81.15 111.93 67.05 121.85 67.05" />
      <polygon points="111.12 123.08 111.12 109.08 120.98 123.08 111.12 123.08" />
      <polygon points="121.85 86.62 121.85 100.71 111.93 86.62 121.85 86.62" />
      <polygon points="111.12 83.96 111.12 69.95 120.98 83.96 111.12 83.96" />
      <polygon points="111.12 103.52 111.12 89.51 120.98 103.52 111.12 103.52" />
      <polygon points="121.85 45.73 121.85 59.82 111.93 45.73 121.85 45.73" />
      <polygon points="121.85 6.6 121.85 20.7 111.93 6.6 121.85 6.6" />
      <polygon points="111.12 62.63 111.12 48.63 120.98 62.63 111.12 62.63" />
      <polygon points="121.85 26.17 121.85 40.26 111.93 26.17 121.85 26.17" />
      <polygon points="111.12 23.51 111.12 9.5 120.98 23.51 111.12 23.51" />
      <polygon points="111.12 43.07 111.12 29.06 120.98 43.07 111.12 43.07" />
      <path d="m99.32,81.9v10.03s0,10.03,0,10.03l9.48,22.97h15.31s0-60.45,0-60.45V4.02s-15.31,0-15.31,0l-9.48,77.88Zm23-37.58v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h-11.67v-.77s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0v-.42s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0v-.42s-.57,0-.57,0h-.57V5.47s1.13,0,1.13,0v-.62h11.67s0,.62,0,.62h1.14v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0Zm-11.67,40.54v-.42s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h11.67s0,.62,0,.62h1.14v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h0v.27s1.14,0,1.14,0v18.53s-.57,0-.57,0h-.57s0,.06,0,.06v.71h-11.67s0-.63,0-.63v-.14s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0v-.42s-.57,0-.57,0h-.57v-18.53s1.13,0,1.13,0v-.62h0Z" />
    </g>
  </svg>
);
export default AisleIcon;
