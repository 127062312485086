import {
  AssignmentIssueInvalidLocationReason,
  LoadAssignmentIssueDataDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AssignmentItemIssuesConverterConfig,
  AssignmentItemIssuesDataRow,
  assignmentItemIssuesExportJobParams,
  AssignmentItemIssuesExportJobParams,
  getAssignmentItemIssuesTableRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import ButtonLocateLocation from '../../../components/ButtonLocateLocation';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';
import { ContainerScroll } from '../../../components/layout/ContainerScroll';
import useLoadAssignmentItemIssues from '../../hooks/useLoadAssignmentLinesIssues';
import { getAssignmentIssueDescriptors } from '../../store/simulation.issues.helper';
import {
  simulationIssuesAssignmentItemsData,
  simulationIssuesAssignmentItemsDataStatus,
  simulationIssueSelected,
} from '../../store/simulation.issues.state';
import { DataIssueReason } from '../../store/simulation.issues.types';
import { simulationCurrent } from '../../store/simulation.state';
import ItemTag from '../../tags/ItemTag';
import DatasetIssueTag from './DatasetIssueTag';

const AssignmentIssuesTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const assignmentItemData = useRecoilValue(
    simulationIssuesAssignmentItemsData,
  );
  const assignmentItemDataStatus = useRecoilValue(
    simulationIssuesAssignmentItemsDataStatus,
  );
  const selectedIssue = useRecoilValue(simulationIssueSelected);
  const [loadCallback, cancelLoad] = useLoadAssignmentItemIssues();
  const [runExportCSV] = useRunExportJobMutation();
  const searchValues = {};

  const tableTitle = t`Assignments with issues`;

  function callDataLoad(page = { isAppend: false, skip: 0 }) {
    if (selectedIssue?.group !== 'assignment') return;

    cancelLoad();
    loadCallback({
      //filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad();
  }, [selectedIssue]);

  const columnsConfig: ColumnConfig<AssignmentItemIssuesDataRow>[] = [
    {
      field: 'reason',
      title: t`Issue`,
      hiddenInExport: true,
      render: (v: string[]) => {
        if (_.isNil(v)) {
          return '—';
        }
        return _.map(v, reason => (
          <DatasetIssueTag
            key={reason}
            identity={{
              group: 'assignment',
              reason: reason as DataIssueReason,
            }}
          />
        ));
      },
    },
    {
      field: 'reasonString',
      title: t`Issue`,
      hiddenInBrowser: true,
    },

    {
      field: 'locationId',
      title: t`Location`,
      hasFilter: true,
      hasSort: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      //hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
  ];

  function onLoadNext() {
    callDataLoad({
      isAppend: true,
      skip: itemsCount,
    });
  }

  const converterConfig: AssignmentItemIssuesConverterConfig = {
    columnsConfig,
    dictionaries: {
      reasons: _.reduce(
        _.values(getAssignmentIssueDescriptors(t)),
        (acc, i) => ({
          ...acc,
          [i.id]: i.tag,
        }),
        {} as Record<AssignmentIssueInvalidLocationReason, string>,
      ),
    },
  };

  async function startExportCSV() {
    const key = {
      layoutId: sim.layout?.id,
      assignmentId: sim.assignment?.id,
    };
    const variables: AssignmentItemIssuesExportJobParams = {
      ...assignmentItemIssuesExportJobParams,
      query: LoadAssignmentIssueDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        key,
        page: null,
        reason: selectedIssue?.reason as AssignmentIssueInvalidLocationReason,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = assignmentItemDataStatus === AsyncLoadStatus.Loading;
  const itemsCount = assignmentItemData?.content?.length || 0;
  const totalCount = assignmentItemData?.totalCount ?? 0;
  const subtitle = t(`{{totalCount}} Filtered Assignment Lines`, {
    totalCount,
  });

  const flattenData = getAssignmentItemIssuesTableRows(
    assignmentItemData?.content,
    converterConfig,
  );

  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        id={selectedIssue?.reason}
        title={tableTitle}
        subtitle={subtitle}
        isSticky
        columnsConfig={columnsConfig}
        keyFields={['consignee', 'sku', 'locationId']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={_.noop} // setSearchValues}
        totalCount={totalCount}
        searchValues={searchValues}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        hasCounter
      />
    </ContainerScroll>
  );
};

export default AssignmentIssuesTable;
