import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormErrorField } from '../components/FormError';
import LoadingIndicator from '../components/LoadingIndicator';
import { Button } from '../components/actions/Button';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { Spacer } from '../components/layout/Spacer';
import { ActionBar } from '../components/nav/ActionBar';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { warehouseSelectedId } from '../store/warehouse.state';
import {
  appBillingCustomer,
  appBillingPurchase,
  appBillingSubscription,
} from './store/appBilling.state';

const AppBillingPaymentForm: React.FC = () => {
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const [purchase, setPurchase] = useRecoilState(appBillingPurchase);
  const [subscription, setSubscription] = useRecoilState(
    appBillingSubscription,
  );
  const customer = useRecoilValue(appBillingCustomer);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true);
  const [stripeError, setStripeError] = useState<StripeError>(null);

  const origin = window.location.origin.toString();
  const purchaseQueryPart = purchase ? `purchaseId=${purchase?.id}` : '';
  const subscriptionQueryPart = subscription
    ? `subscriptionId=${subscription?.id}`
    : '';
  const returnUrl = `${origin}/wh/i/${whId}/payment/?&${purchaseQueryPart}&${subscriptionQueryPart}`;
  const isStripeReady = stripe && elements;

  useEffect(() => {
    return () => {
      setPurchase(null);
      setSubscription(null);
    };
  }, []);

  const handleSubmit = async () => {
    try {
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl,
          receipt_email: customer.email,
          payment_method_data: {
            billing_details: {
              phone: customer.phone,
              email: customer.email,
              name: customer.name,
              address: {
                city: customer.address.city,
                line1: customer.address.line1,
                line2: customer.address.line2,
                state: customer.address.state,
                country: customer.address.country,
                postal_code: customer.address.postalCode,
              },
            },
          },
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        setStripeError(result.error);

        // addError({
        //   id: nanoid(),
        //   title: 'Payment failed',
        //   callStack: result.error.message,
        //   details: result.error.message,
        // });
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (ex) {
      console.error(ex);
      setStripeError(ex?.message ?? 'Error');
    }

    setIsLoading(false);
  };

  return (
    <div
      data-component="AppBillingPaymentForm"
      className="flex flex-1 flex-col"
    >
      {stripeError && <FormErrorField message={stripeError.message} />}

      <ContainerScroll className="relative">
        {(isLoading || !isStripeReady) && (
          <LoadingIndicator message={t`Please Wait.`} absolute selfCenter />
        )}
        <fieldset className="flex px-10 py-4">
          <div className="flex-1">
            <PaymentElement
              onReady={() => setIsLoading(false)}
              onChange={() => setStripeError(null)}
              options={{
                fields: {
                  billingDetails: {
                    name: 'never',
                    email: 'never',
                    phone: 'never',
                    address: {
                      country: 'never',
                      postalCode: 'never',
                      state: 'never',
                      city: 'never',
                      line1: 'never',
                      line2: 'never',
                    },
                  },
                },
              }}
            />
          </div>
        </fieldset>
      </ContainerScroll>
      <ActionBar>
        <Spacer flexspace />
        <Button
          className="flex-1"
          label={t`Subscribe`}
          buttonType="purchase"
          buttonSize="xl"
          isLoading={isLoading || !isStripeReady}
          isDisabled={isLoading || !isStripeReady}
          full
          hasIconAfter
          buttonIcon={<IconArrowDir />}
          onPress={() => {
            setIsLoading(true);
            handleSubmit();
          }}
        />
      </ActionBar>
    </div>
  );
};

export default AppBillingPaymentForm;
