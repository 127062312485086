import * as React from 'react';

const WareBeeLogoFullSloganLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2245.21 584.58"
    {...props}
  >
    <path
      d="M594.81,190.6,438.57,100.39c-12.5-7.22-31.35-7.22-43.85,0l-72.56,41.9-72.57-41.9c-12.5-7.22-31.34-7.22-43.84,0L49.5,190.6c-12.49,7.21-21.92,23.53-21.92,38V409c0,14.43,9.43,30.76,21.92,38l156.25,90.21a47.11,47.11,0,0,0,43.84,0l72.57-41.9,72.56,41.9a47.13,47.13,0,0,0,43.85,0L594.81,447c12.5-7.22,21.92-23.54,21.92-38V228.57C616.73,214.13,607.31,197.81,594.81,190.6Zm-.3,176.31-167,96.45c-5.56,3.21-16.07,3.21-21.62,0l-14.22-8.2L594.51,338Zm0-109.1-356,205.55c-5.56,3.21-16.06,3.21-21.62,0L49.8,366.91V338l156,90a47.11,47.11,0,0,0,43.84,0L594.51,228.91ZM205.75,319a47.11,47.11,0,0,0,43.84,0l156.25-90.21c5.55-3.21,16.06-3.21,21.62,0l14.21,8.2L238.48,354.25c-5.56,3.21-16.06,3.21-21.62,0L49.8,257.81v-28.9Zm305.38-67.56-72.56-41.9c-12.5-7.21-31.35-7.21-43.85,0L238.48,299.7c-5.56,3.21-16.06,3.21-21.62,0l-14.21-8.2L405.84,174.19c5.55-3.21,16.06-3.21,21.62,0l108.7,62.75ZM205.75,373.5a47.11,47.11,0,0,0,43.84,0l214.3-123.73,25,14.45L238.48,408.81c-5.56,3.21-16.06,3.21-21.62,0L49.8,312.36V283.47ZM405.84,119.63c5.55-3.2,16.06-3.2,21.62,0l155.94,90-25,14.44L438.57,154.94c-12.5-7.21-31.35-7.22-43.85,0L180.43,278.67l-25-14.45ZM133.18,251.39l-25-14.45,166.75-96.27,25,14.45Zm83.68-131.76c5.56-3.2,16.06-3.2,21.62,0l14.21,8.21L85.94,224.11l-25-14.44Zm21.62,398.28c-5.56,3.21-16.06,3.21-21.62,0L60.61,427.7C55.06,424.5,49.8,415.4,49.8,409V392.57l156,90a47.11,47.11,0,0,0,43.84,0L594.51,283.47v28.89Zm345.22-90.2-156.24,90.2c-5.56,3.21-16.07,3.21-21.62,0l-61.46-35.48,25-14.45,25.32,14.63a47.13,47.13,0,0,0,43.85,0l155.94-90V409C594.51,415.4,589.26,424.5,583.7,427.71Z"
      // fill="#0f0f1b"
    />
    <text
      transform="translate(619.1 398.37)"
      fontSize={412}
      fontFamily="NeoSansPro-Regular, Neo Sans Pro"
    >
      <tspan letterSpacing="-0.04em">{'W'}</tspan>
      <tspan x={324.24} y={0} letterSpacing="-0.02em">
        {'a'}
      </tspan>
      <tspan x={528.72} y={0} letterSpacing="-0.03em">
        {'r'}
      </tspan>
      <tspan x={657.18} y={0} letterSpacing="-0.03em">
        {'e'}
      </tspan>
      <tspan x={869.23} y={0} letterSpacing="-0.01em">
        {'B'}
      </tspan>
      <tspan x={1116.88} y={0} letterSpacing="-0.02em">
        {'e'}
      </tspan>
      <tspan x={1332.4} y={0}>
        {'e'}
      </tspan>
    </text>
    <text
      transform="translate(2122.72 142.34)"
      fontSize={60.69}
      fontFamily="NeoSansPro-Regular, Neo Sans Pro"
      letterSpacing="-0.03em"
    >
      {'tm'}
    </text>
    <text
      transform="translate(657.05 509.26)"
      fontSize={88.12}
      fontFamily="NeoSansPro-Regular, Neo Sans Pro"
    >
      <tspan letterSpacing="-0.01em">{'M'}</tspan>
      <tspan x={65.83} y={0} letterSpacing="-0.01em">
        {'A'}
      </tspan>
      <tspan x={120.33} y={0} letterSpacing="-0.03em">
        {'K'}
      </tspan>
      <tspan x={171.33} y={0} letterSpacing="-0.03em">
        {'I'}
      </tspan>
      <tspan x={191.98} y={0} letterSpacing="-0.02em">
        {'N'}
      </tspan>
      <tspan x={249.92} y={0} letterSpacing="0em">
        {'G '}
      </tspan>
      <tspan x={325.61} y={0} letterSpacing="-0.05em">
        {'W'}
      </tspan>
      <tspan x={393.74} y={0} letterSpacing="-0.01em">
        {'A'}
      </tspan>
      <tspan x={448.24} y={0} letterSpacing="-0.04em">
        {'R'}
      </tspan>
      <tspan x={500.15} y={0} letterSpacing="-0.03em">
        {'E'}
      </tspan>
      <tspan x={546.12} y={0} letterSpacing="-0.02em">
        {'H'}
      </tspan>
      <tspan x={603.96} y={0} letterSpacing="-0.02em">
        {'O'}
      </tspan>
      <tspan x={660.02} y={0} letterSpacing="-0.02em">
        {'U'}
      </tspan>
      <tspan x={716.21} y={0} letterSpacing="-0.03em">
        {'S'}
      </tspan>
      <tspan x={762.32} y={0} letterSpacing="-0.02em">
        {'E'}
      </tspan>
      <tspan x={808.78} y={0}>
        {'S '}
      </tspan>
      <tspan x={879} y={0} letterSpacing="-0.02em">
        {'M'}
      </tspan>
      <tspan x={944.03} y={0} letterSpacing="-0.02em">
        {'O'}
      </tspan>
      <tspan x={1000.21} y={0} letterSpacing="-0.04em">
        {'R'}
      </tspan>
      <tspan x={1052.11} y={0} letterSpacing="0em">
        {'E '}
      </tspan>
      <tspan x={1122.43} y={0} letterSpacing="-0.03em">
        {'EF'}
      </tspan>
      <tspan x={1214.37} y={0} letterSpacing="-0.03em">
        {'F'}
      </tspan>
      <tspan x={1259.85} y={0} letterSpacing="-0.03em">
        {'I'}
      </tspan>
      <tspan x={1280.67} y={0} letterSpacing="-0.02em">
        {'C'}
      </tspan>
      <tspan x={1326.6} y={0} letterSpacing="-0.03em">
        {'I'}
      </tspan>
      <tspan x={1347.12} y={0} letterSpacing="-0.03em">
        {'E'}
      </tspan>
      <tspan x={1393.09} y={0} letterSpacing="-0.02em">
        {'N'}
      </tspan>
      <tspan x={1451.44} y={0}>
        {'T'}
      </tspan>
    </text>
  </svg>
);

export default WareBeeLogoFullSloganLogo;
