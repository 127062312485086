import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { StageType } from '../../stage/stage.helper';
import {
  viewerBayLocations,
  viewerBayModels,
  viewerLocationTypes,
  viewerSelectedBayDetails,
  viewerSelectedLevelInner,
  viewerSelectedLocationIdAtom,
} from '../../viewer/store/viewer.state';
import { bayViewerHoveredLocation } from '../store/bayViewer.state';
import BayLocationLayerBase, {
  BayLocationLayerBaseProps,
} from './BayLocationLayerBase';

export const groundLevelWidthDelta = 0.1;
export const groundLevelHeightDelta = 0.05;

export type BayLocationLayerProps = {
  stageId?: StageType;
  getColor?: (loc: LayoutBayLocationFragment) => [string, string];
  getLabel?: (loc: LayoutBayLocationFragment) => string;
  getLabelColor?: (loc: LayoutBayLocationFragment) => [string, string];
  getGradientColors?: (location: LayoutBayLocationFragment) => string[];
  isCompliance?: boolean;
};

const BayLocationLayer: React.FC<BayLocationLayerProps> = props => {
  const stageId = props.stageId ?? 'viewer-bay-view';
  const bayDetailsLoadable = useRecoilValueLoadable(viewerSelectedBayDetails);
  const locationsResponse = useRecoilValueLoadable(viewerBayLocations);
  const [selectedLocationId, setSelectedLocationId] = useRecoilState(
    viewerSelectedLocationIdAtom,
  );
  const setSelectedLevel = useSetRecoilState(viewerSelectedLevelInner);
  const setHovered = useSetRecoilState(bayViewerHoveredLocation);
  const bayTypes = useRecoilValue(viewerBayModels);
  const locationTypes = useRecoilValue(viewerLocationTypes);

  if (
    bayDetailsLoadable.state !== 'hasValue' ||
    locationsResponse.state !== 'hasValue'
  )
    return null;

  const bay = bayDetailsLoadable.getValue();
  const locations = locationsResponse.getValue();
  if (!locations) return null;

  function selectLocation(location: LayoutBayLocationFragment) {
    setSelectedLevel(location.locationLevel);
    setSelectedLocationId(
      location.locationId === selectedLocationId ? null : location.locationId,
    );
  }

  const config: BayLocationLayerBaseProps = {
    stageId,
    bay,
    locations,
    bayType: bayTypes[bay.details.bayType ?? DEFAULT_BAY_TYPE],
    locationTypes,
    selectedLocationId,
    getColor: props.getColor,
    getLabel: props.getLabel,
    getLabelColor: props.getLabelColor,
    onHover: setHovered,
    onSelect: selectLocation,
    getGradientColors: props.getGradientColors,
    isCompliance: props.isCompliance,
  };

  return <BayLocationLayerBase {...config} />;
};

export default BayLocationLayer;
