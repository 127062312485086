import { StackingPolicyRuleFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getSequentialColor } from '../common/color.helper';
import { formatInteger } from '../common/formatHelper';
import useFormatter from '../common/useFormatter';
import { getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import {
  stackingPolicyDocument,
  stackingPolicySelectedIdentity,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';
import { StakingPolicyRuleGroupType } from '../simulation/store/stackingPolicy/stackingPolicy.types';
import StackingPolicyRule from './StackingPolicyRule';

export type StackingPolicyRulesGroupProps = {
  groupType: StakingPolicyRuleGroupType;
};

const StackingPolicyRulesGroup: React.FC<
  StackingPolicyRulesGroupProps
> = props => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(stackingPolicyDocument);
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    stackingPolicySelectedIdentity,
  );
  const canUpdate = useRecoilValue(simulationIsEditable);
  const formatter = useFormatter();

  const rules =
    (props.groupType === 'top' ? policy?.topRules : policy?.bottomRules) ?? [];

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      topRules: _.filter(policy.topRules, rule => rule.id !== ruleId),
      bottomRules: _.filter(policy.bottomRules, rule => rule.id !== ruleId),
    });
  };

  const rulePrefix =
    props.groupType === 'top' ? t`Top Rule #` : t`Bottom Rule #`;

  const addRule = () => {
    const ruleId = nanoid();
    const ruleTitle = getIndexedTitle(
      new Set(_.map(rules, r => r.title)),
      rulePrefix,
    );

    const newRule: StackingPolicyRuleFragment = {
      id: ruleId,
      title: ruleTitle,
      itemsMatch: {
        anyOf: [],
      },
      subcategories: null,
    };

    setPolicy({
      ...policy,
      topRules:
        props.groupType === 'top' ? [...rules, newRule] : policy.topRules,
      bottomRules:
        props.groupType === 'bottom' ? [...rules, newRule] : policy.bottomRules,
    });

    setSelectedIdentity({
      ruleId,
    });
  };

  function moveRule(from, to) {
    const newRules = [...rules];
    newRules.splice(to, 0, newRules.splice(from, 1)[0]);

    setPolicy({
      ...policy,
      topRules: props.groupType === 'top' ? newRules : policy.topRules,
      bottomRules: props.groupType === 'bottom' ? newRules : policy.bottomRules,
    });
  }

  const groupTitle = props.groupType === 'top' ? t`On Top` : t`On Bottom`;
  const hasRules = !_.isEmpty(rules);
  const rulesCount = _.size(rules);
  const rulesCountText = formatInteger(rulesCount);
  const isLoading = false;
  const hasError = false;

  return (
    <TitleSection
      title={
        <PolicyRuleSectionTitle
          isFeasible={true}
          title={groupTitle}
          counter={rulesCount}
        />
      }
      inPanelView
      className={classNames(
        'top-12 xl:top-20 z-400',
        props.groupType === 'top'
          ? 'bg-priority-top/50 p-0 border-b-priority-top'
          : 'bg-priority-bottom/50 p-0 border-b-priority-bottom',
      )}
      classNameInner={classNames('flex-1', {
        'bg-app-panel-dark': canUpdate,
        'bg-issue-transparent bg-app-panel-dark': !canUpdate,
      })}
      hasScreenTitle
      hasAction={
        canUpdate ? (
          hasRules && (
            <Button
              label={t`Add Policy`}
              className={classNames('ltr:ml-4 rtl:mr-4 rounded')}
              buttonSize="xs"
              buttonType="primary"
              hasIconAfter={
                <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
              }
              onPress={() => addRule()}
            />
          )
        ) : (
          <StatusTag
            title={t`Policy Locked`}
            type="locked"
            modeStyle="stroke"
            icon={Icon.Lock}
            onPress={null}
          />
        )
      }
    >
      <Container col flex1>
        <PolicyWell
          data-component="PolicyWellRules"
          className={classNames(
            'flex-1',
            props.groupType === 'top'
              ? 'bg-priority-top/10 p-0'
              : 'bg-priority-bottom/10 p-0',
          )}
          classNameChildren={classNames(
            'space-y-2',
            props.groupType === 'top' ? '' : '',
          )}
          isCentred={!hasRules}
        >
          {_.map(rules, (rule, index) => {
            return (
              <StackingPolicyRule
                key={`staking-policy-rule-${props.groupType}-${rule.id}`}
                ruleId={rule.id}
                index={index + 1}
                canRename={true}
                canDelete={true}
                isDisabled={!canUpdate}
                onDeleteClick={() => removeRule(rule.id)}
                canMoveUp={index > 0}
                onMoveUp={() => moveRule(index, index - 1)}
                canMoveDown={index < _.size(rules) - 1}
                onMoveDown={() => moveRule(index, index + 1)}
                color={getSequentialColor(
                  (index * 100) / rulesCount,
                  props.groupType === 'top'
                    ? 'stacking-top'
                    : 'stacking-bottom',
                )}
              />
            );
          })}

          {!hasRules && (
            <InboxZero selfCenter hasIcon message={t`No Rules Found`}>
              {canUpdate && (
                <Button
                  full
                  buttonSize="xs"
                  buttonType="primary"
                  className="flex-1 mt-3 rounded"
                  label={
                    props.groupType === 'top'
                      ? t`Add Top Rule`
                      : t`Add Bottom Rule`
                  }
                  onPress={addRule}
                  hasIconAfter
                  buttonIcon={
                    <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                  }
                />
              )}
            </InboxZero>
          )}
        </PolicyWell>
      </Container>
    </TitleSection>
  );
};

export default StackingPolicyRulesGroup;
