import _ from 'lodash';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import useLoadAnalyzedOrderDetails from '../hooks/useLoadAnalyzedOrderDetails';
import useLoadAnalyzedOrdersList from '../hooks/useLoadAnalyzedOrdersList';
import useLoadOptimizedOrderDetails from '../hooks/useLoadOptimizedOrderDetails';
import { analyzeResultById } from '../store/analyze.state';
import { simulationLayoutSelectedOrderId } from '../store/simulation.layout.state';
import {
  simulationAnalyzeOrdersListData,
  simulationAnalyzeOrdersListState,
  simulationAnalyzeOrdersListStatus,
} from '../store/simulation.state';
import AnalyzedOrdersList from './AnalyzedOrdersList';

export type SimulationAnalyzedOrdersPanelProps = {
  analyzeId: string;
};

export type SimulationAnalyzedOrdersPanelBaseProps =
  SimulationAnalyzedOrdersPanelProps & {
    onOrderSelect: (orderId: string) => void;
  };

const SimulationAnalyzedOrdersPanelBase: React.FC<
  SimulationAnalyzedOrdersPanelBaseProps
> = props => {
  const analyzeResult = useRecoilValue(analyzeResultById(props.analyzeId));
  const [selectedOrderId, setSelectedOrderId] = useRecoilState(
    simulationLayoutSelectedOrderId,
  );
  const ordersList = useRecoilValue(simulationAnalyzeOrdersListData);
  const orderListLoadStatus = useRecoilValue(simulationAnalyzeOrdersListStatus);
  const [orderListState, setOrderListState] = useRecoilState(
    simulationAnalyzeOrdersListState,
  );
  const [loadOrders, cancelLoadOrders] = useLoadAnalyzedOrdersList();

  useEffect(() => {
    if (_.isNil(props.analyzeId)) return null;
    setSelectedOrderId(null);
    cancelLoadOrders();
    loadOrders({
      analyzeId: props.analyzeId,
      isAppend: false,
      sortBy: orderListState.sortBy,
      filter: orderListState.searchValues,
      status: orderListState.status,
    });
    return () => {
      cancelLoadOrders();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.analyzeId,
    orderListState.sortBy,
    orderListState.searchValues,
    orderListState.status,
  ]);

  const loadedCount = ordersList?.length ?? 0;

  function loadNext() {
    loadOrders({
      analyzeId: props.analyzeId,
      isAppend: true,
      skip: loadedCount,
      sortBy: orderListState.sortBy,
      filter: orderListState.searchValues,
      status: orderListState.status,
    });
  }

  function selectOrder(orderId: string) {
    setSelectedOrderId(orderId);
    if (selectedOrderId !== orderId) {
      props.onOrderSelect(orderId);
    }
  }

  return (
    <AnalyzedOrdersList
      summary={analyzeResult}
      orders={ordersList}
      state={orderListState}
      onLoadNext={loadNext}
      selectedOrderId={selectedOrderId}
      onSelect={selectOrder}
      selectedKPI={orderListState.sortBy}
      isLoading={orderListLoadStatus === AsyncLoadStatus.Loading}
      onStateChanged={setOrderListState}
    />
  );
};

export const SimulationAnalyzeOrdersPanel: React.FC<
  SimulationAnalyzedOrdersPanelProps
> = props => {
  const [loadOrderDetails, cancelLoadOrderDetails] =
    useLoadAnalyzedOrderDetails();

  function onOrderSelect(orderId: string) {
    cancelLoadOrderDetails();
    loadOrderDetails({
      analyzeId: props.analyzeId,
      orderId,
    });
  }

  return (
    <SimulationAnalyzedOrdersPanelBase
      analyzeId={props.analyzeId}
      onOrderSelect={onOrderSelect}
    />
  );
};

export const SimulationOptimiseOrdersPanel: React.FC<
  SimulationAnalyzedOrdersPanelProps
> = props => {
  const [loadOrderDetails, cancelLoadOrderDetails] =
    useLoadOptimizedOrderDetails();

  function onOrderSelect(orderId: string) {
    cancelLoadOrderDetails();
    loadOrderDetails({
      analyzeId: props.analyzeId,
      orderId,
    });
  }

  return (
    <SimulationAnalyzedOrdersPanelBase
      analyzeId={props.analyzeId}
      onOrderSelect={onOrderSelect}
    />
  );
};
