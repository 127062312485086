import * as React from 'react';
const PolicyAssignmentIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAssignmentIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M51.86,45.99v-9.16s12.16-12.15,12.16-12.15l12.16,12.15v9.16s-8.91-8.91-8.91-8.91v5.68c0,6.52-2.56,12.66-7.19,17.3-.74.74-1.51,1.41-2.32,2.04-2.16-1.41-4.48-2.5-6.93-3.25,1.7-.86,3.28-1.99,4.67-3.38,3.41-3.41,5.29-7.93,5.29-12.72v-5.68s-8.91,8.91-8.91,8.91ZM64.02,103.32l-12.16-12.15v-9.16s8.91,8.91,8.91,8.91v-5.68c0-4.79-1.88-9.3-5.29-12.72-3.41-3.41-7.93-5.29-12.72-5.29h-18.08s0-6.48,0-6.48h18.08c6.52,0,12.66,2.55,17.3,7.19,4.64,4.64,7.19,10.78,7.19,17.3v5.68s8.91-8.91,8.91-8.91v9.16s-12.15,12.15-12.15,12.15ZM91.2,76.14h-9.16s8.91-8.91,8.91-8.91h-23.59s-3.59-3.24-3.59-3.24l3.59-3.24h23.59s-8.91-8.91-8.91-8.91h9.16s12.15,12.15,12.15,12.15l-12.15,12.16Z"
    />
  </svg>
);
export default PolicyAssignmentIcon;
