import * as React from 'react';
const MoveTypeMoveStashIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeMoveStashIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points=".86 58.37 29.89 58.37 5.21 33.62 21.09 33.62 51.38 64 21.03 94.38 5.16 94.38 29.89 69.63 .86 69.63 .86 58.37"
    />
    <path
      strokeWidth={0}
      d="M53.2,21.81v84.38s72.38,0,72.38,0V21.81s-72.38,0-72.38,0ZM118.07,91.06c-2.06,2.06-5.42,2.04-7.51-.05l-19.86-19.83-20.49,20.52c-2.06,2.06-5.42,2.04-7.51-.05-2.09-2.09-2.11-5.45-.06-7.51l20.49-20.52-19.86-19.83c-2.09-2.09-2.11-5.45-.06-7.51,2.06-2.06,5.42-2.04,7.51.05l19.86,19.83,18.63-18.65c2.06-2.06,5.42-2.04,7.51.05,2.09,2.09,2.11,5.45.06,7.51l-18.63,18.65,19.86,19.83c2.09,2.09,2.11,5.45.06,7.51Z"
    />
  </svg>
);
export default MoveTypeMoveStashIcon;
