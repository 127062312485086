import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AssignmentComplianceTable from '../../assignmentCompliance/AssignmentComplianceTable';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../../layout/LayoutActiveFeatureTag';
import { viewerShowLocations } from '../../layout/viewer/store/viewer.state';
import AssignmentOccupancyTable from '../../occupancy/AssignmentOccupancyTable';
import SimulationComplianceLayoutView from '../SimulationComplianceLayoutView';
import SimulationLayoutView from '../SimulationLayoutView';
import AssignmentComplianceLocationLayer from '../layers/AssignmentComplianceLocationLayer';
import AssignmentOccupancyLocationLayer from '../layers/AssignmentOccupancyLocationLayer';
import SizeComplianceLocationLayer from '../layers/SizeComplianceLocationLayer';
import WeightComplianceLocationLayer from '../layers/WeightComplianceLocationLayer';
import SimulationAnalyzePanelItemsABCStats from '../panels/SimulationAnalyzePanelItemsABCStats';
import SizeComplianceLocationsTable from '../panels/compliance/size/SizeComplianceLocationsTable';
import WeightComplianceBaysTable from '../panels/compliance/weight/WeightComplianceBaysTable';
import WeightComplianceLocationsTable from '../panels/compliance/weight/WeightComplianceLocationsTable';
import { assignmentComplianceDataTableState } from '../store/assignmentCompliance.state';
import {
  simulationShowComplianceTable,
  viewAsSelector,
} from '../store/simulation.state';
import { simulationComplianceTabKey } from '../store/simulation.wizard.state';
import AssignmentOccupancyToolbar from '../toolbars/AssignmentOccupancyToolbar';
import SizeComplianceToolbar from '../toolbars/SizeComplianceToolbar';
import WeightComplianceToolbar from '../toolbars/WeightComplianceToolbar';

type ViewAsKey = 'table' | 'layout' | 'kpi';

export type AnalyzeProps = {
  analyzeId: string;
};

const SimulationComplianceView: React.FC<AnalyzeProps> = props => {
  const { t } = useTranslation('simulation');
  const [showTable, setShowTable] = useRecoilState(
    simulationShowComplianceTable,
  );
  const showAssignmentComplianceDataTable = useRecoilValue(
    assignmentComplianceDataTableState,
  );
  const showLocations = useRecoilValue(viewerShowLocations);
  const selectedTab = useRecoilValue(simulationComplianceTabKey);
  const [viewAsKey, setViewAsKey] = useRecoilState(viewAsSelector);
  const [showTableState, setShowTableState] = useRecoilState(
    simulationShowComplianceTable,
  );

  const content = [
    {
      id: 'tab-compliance-policy',
      title: t`Storage Policy`,
      getTable: () => <AssignmentComplianceTable />,
      getLocationLayer: () => <AssignmentComplianceLocationLayer />,
      // getToolbars: () => <AssignmentOccupancyToolbar />,
    },
    {
      id: 'tab-compliance-volume',
      title: t`Stock occupancy`,
      getTable: () => <AssignmentOccupancyTable />,
      getLocationLayer: () => <AssignmentOccupancyLocationLayer />,
      getToolbars: () => <AssignmentOccupancyToolbar />,
    },
    {
      id: 'tab-compliance-weight',
      title: t(`Weight compliance: {{featureType}}`, {
        featureType: showLocations ? t`Locations` : t`Bays`,
      }),
      getTable: () =>
        showLocations ? (
          <WeightComplianceLocationsTable />
        ) : (
          <WeightComplianceBaysTable />
        ),
      getLocationLayer: () => <WeightComplianceLocationLayer />,
      getToolbars: () => <WeightComplianceToolbar />,
    },
    {
      id: 'tab-compliance-size',
      title: t`Size compliance: Locations`,
      getTable: () => <SizeComplianceLocationsTable />,
      getLocationLayer: () => <SizeComplianceLocationLayer />,
      getToolbars: () => <SizeComplianceToolbar />,
    },
    {
      id: 'tab-analyze-heatmaps',
      title: t`Heatmaps`,
      getTable: () => null,
      getLocationLayer: () => null,
      getToolbars: () => null,
    },
  ];

  const contentItem = _.find(content, c => c.id === selectedTab);

  const viewAsOptions = [
    {
      key: 'table',
      label: t`Table`,
    },
    {
      key: 'layout',
      label: t`Layout`,
    },
    {
      key: 'kpi',
      label: t`KPIS`,
    },
  ];

  return (
    <Container
      componentName="SimulationComplianceViewContainer"
      col
      overflow
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
      )}
    >
      <Container row hasNoScroll className={classNames('relative !h-auto')}>
        <HeaderAppMenu
          title={contentItem?.title}
          className={classNames('flex-1')}
        >
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          <ButtonSwitchMulti
            options={viewAsOptions}
            autoSize
            className="mx-3"
            classNameLabel="text-xs py-1 px-2"
            buttonType="header"
            selectedIndex={viewAsOptions.findIndex(
              o => o.key === (showTableState ? 'table' : viewAsKey),
            )}
            onClick={index => {
              const selectedKey = viewAsOptions[index].key as ViewAsKey;
              setViewAsKey(selectedKey);
              setShowTableState(selectedKey === 'table');
            }}
          />
        </HeaderAppMenu>
      </Container>
      {showTableState &&
      showAssignmentComplianceDataTable &&
      selectedTab !== 'tab-analyze-heatmaps' ? (
        contentItem.getTable()
      ) : (
        <>
          {viewAsKey === 'kpi' ? (
            <header
              data-component="SimulationComplianceViewKPI"
              className="my-1 flex flex-wrap gap-1"
            >
              <SimulationAnalyzePanelItemsABCStats
                mode={'header'}
                analyzeId={props.analyzeId}
              />
            </header>
          ) : null}
          <Container
            componentName={`SimulationComplianceLayoutViewContainer`}
            overflow
            flex1
            col
            className={classNames(
              'relative',
              'border-app-background/70 border-8',
            )}
          >
            <Container
              componentName={`SimulationComplianceLayoutView`}
              col
              overflow-hidden
              className={classNames('relative', 'flex-1')}
            >
              {selectedTab === 'tab-analyze-heatmaps' ? (
                <SimulationLayoutView stepId={'analyse'} />
              ) : (
                <SimulationComplianceLayoutView
                  getLocationLayer={contentItem.getLocationLayer}
                  getAbsoluteContent={contentItem.getToolbars}
                />
              )}
            </Container>
          </Container>
        </>
      )}
    </Container>
  );
};

export default SimulationComplianceView;
