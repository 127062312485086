import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationDetails } from '../store/notification.state';
import { Button } from './actions/Button';
import * as Icon from './icons';
import { Spacer } from './layout/Spacer';
import { ActionBar } from './nav/ActionBar';

export type NotificationProps = {
  notification: NotificationDetails;
  onClose: () => void;
};

const Notification: React.FC<NotificationProps> = props => {
  const { t } = useTranslation('app');
  const { notification } = props;

  return (
    <div data-component="Notification" className={`flex flex-col p-2`}>
      <div className={`flex items-start mb-2`}>
        <div
          className={`flex-1 text-xl flex ltr:items-start rtl:items-end ltr:pl-1 rtl:pr-1 `}
        >
          <Icon.TriangleInfo className="w-6 h-6 pb-0.5 pt-1 ltr:mr-1 rtl:ml-1 fill-current ltr:ml-1 rtl:pr-1" />
          <div
            className={`flex-1 ltr:ml-1 rtl:pr-1 ltr:pl-2 rtl:pr-2 h-full ltr:border-l rtl:border-r border-opacity-50`}
          >
            {notification.title}
          </div>
        </div>
        <button
          type="button"
          className={`ml-2 rounded-full w-7 h-7 flex items-center hover:text-menu-text text-menu/60`}
          aria-label={t`Close Error`}
          onClick={props.onClose}
        >
          <Icon.Close className={`w-4 h-4 fill-current`} />
        </button>
      </div>
      <ActionBar transparent className={`mt-4`}>
        <Spacer flexspace />
        <Button
          buttonSize="xs"
          buttonType="primary"
          onPress={props.onClose}
        >{t`Ok`}</Button>
      </ActionBar>
    </div>
  );
};
export default Notification;
