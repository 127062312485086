import { FetchResult } from '@apollo/client';
import {
  CreateAppBillingPurchaseDocument,
  CreateAppBillingPurchaseMutation,
  CreateAppBillingPurchaseMutationVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { billingClient } from '../../GraphQLClient';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getWarehouseToken } from '../store/appBilling.helper';
import {
  appBillingPurchase,
  appBillingPurchaseError,
  appBillingPurchaseLoadStatus,
} from '../store/appBilling.state';

function useCreatePurchase() {
  const { t } = useTranslation('app');
  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    set(appBillingPurchase, null);
    set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (priceId: string, currency: string) => {
        let purchaseResponse: FetchResult<
          CreateAppBillingPurchaseMutation,
          Record<string, any>,
          Record<string, any>
        >;

        function handleError(message, details?) {
          console.error(message, details);
          set(appBillingPurchaseError, message);
          set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Error);
          return;
        }
        const whId = await snapshot.getPromise(warehouseSelectedId);

        const tokens = await getWarehouseToken([whId], handleError);
        const whToken = _.head(tokens);

        if (_.isNil(whToken)) {
          handleError(
            t`Cannot get authorisation (Token), please contact support`,
            '',
          );
          return;
        }

        try {
          purchaseResponse = await billingClient.mutate<
            CreateAppBillingPurchaseMutation,
            CreateAppBillingPurchaseMutationVariables
          >({
            mutation: CreateAppBillingPurchaseDocument,
            variables: {
              input: {
                currency,
                items: [
                  {
                    warehouseToken: whToken,
                    priceId,
                    quantity: 1,
                  },
                ],
                finalize: true,
              },
            },
          });

          if (purchaseResponse.errors) {
            handleError(
              t`Cannot generate invoice, please contact support`,
              purchaseResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }
        } catch (ex) {
          handleError(
            t`Cannot complete purchase, please contact support`,
            ex.message,
          );
          return;
        }

        const data = purchaseResponse.data.createAppBillingPurchase;
        if (data?.stripeError) {
          handleError(data?.stripeError.message, data?.stripeError);
        }

        set(appBillingPurchase, data.purchase);
        set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (priceId: string, currency: string) => {
    await cleanupState();
    await loadCallback(priceId, currency);
  };
}

export default useCreatePurchase;
