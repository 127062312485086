import * as React from 'react';
const WorkloadZoneBalanceIcon = props => (
  <svg
    aria-hidden="true"
    data-name="WorkloadZoneBalanceIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m65.29,121.84v-56.55s56.55,0,56.55,0c.71,0,1.29-.58,1.29-1.29s-.58-1.29-1.29-1.29h-56.55s0-56.55,0-56.55c0-.71-.58-1.29-1.29-1.29-.71,0-1.29.58-1.29,1.29v56.55s-56.55,0-56.55,0c-.71,0-1.29.58-1.29,1.29s.58,1.29,1.29,1.29h56.55s0,56.55,0,56.55c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29h0Z" />
    <path d="m57.12,119.33v-23s-23,0-23,0v23s23,0,23,0Z" />
    <path d="m57.12,93.52v-23.02s-23,0-23,0v23s23,0,23,0h0Z" opacity={0.5} />
    <path d="m31.32,119.33v-23s-23,0-23,0v23s23,0,23,0Z" opacity={0.6} />
    <path d="m31.32,93.52v-23.02s-23,0-23,0v23s23,0,23,0h0Z" />
    <path d="m92.16,58.86v-23.02s-23,0-23,0v23.02s23,0,23,0Z" />
    <path d="m92.16,33.04V10.03s-23,0-23,0v23s23,0,23,0Z" opacity={0.5} />
    <path d="m117.97,58.85v-23s-23,0-23,0v23s23,0,23,0Z" opacity={0.5} />
    <g>
      <circle
        cx={28.6}
        cy={7.61}
        r={4.55}
        opacity={0.5}
        transform="translate(6.45 27.24) rotate(-56.46)"
      />
      <circle
        cx={47.65}
        cy={8.38}
        r={4.95}
        transform="translate(2.85 26.66) rotate(-32.17)"
      />
      <circle
        cx={15.98}
        cy={7.61}
        r={4.55}
        opacity={0.85}
        transform="translate(.83 16.77) rotate(-56.63)"
      />
      <path
        d="m6.93,34.07c.98,0,1.77-.79,1.77-1.77v-10.3c0-.37.3-.68.68-.68s.68.3.68.68v10.3c0,.1,0,.19-.01.28h.01s0,25.14,0,25.14c.02,1.45,1.21,2.61,2.66,2.59,1.42-.02,2.57-1.17,2.59-2.59v-19.56c0-.37.3-.68.68-.68s.68.3.68.68v19.56c0,1.45,1.18,2.63,2.63,2.63.93,0,1.8-.49,2.27-1.3-.15-.43-.23-.88-.23-1.33v-22.86c-1.42.98-3.36.62-4.34-.79-.36-.52-.55-1.14-.55-1.77v-11.5c0-2.67,1.33-5.16,3.55-6.64h-8.18c-3.67,0-6.64,2.98-6.64,6.64v11.5c0,.98.79,1.77,1.77,1.77Z"
        opacity={0.85}
      />
      <path d="m52.19,25.97s-2.28-4.86-3.64-7.93c-1.28-2.89-2.21-3.65-4.03-3.65h-2.81c-1.47,0-2.01.54-2.92,1.05l-7.61,6.47c-.66.38-1.09.96-1.35,1.77l-3.26,6.66c-.24,1.75.69,2.49,1.73,2.7.84.18,2.12-.25,2.37-1.47.2-1.01,3.38-5.68,3.38-5.68l4.96-3.82c-.82,3.81-1.65,7.97-1.92,9.39-.47,2.42.73,4.25,1.69,5.7.87,1.31,12.1,19.01,13.58,20.94,1.49,1.93,2.84,2.88,4.9,1.73,1.73-.97,1.44-3.17.48-4.81-.96-1.63-12.29-20.07-12.29-20.07l1.9-8.22s1.32,1.88,1.99,2.94c.33.5,4.57,3.68,5.98,4.64,2.26,1.55,4.98-1.74,2.54-3.5l-5.67-4.84Z" />
      <path d="m38.52,39.23l-2.2,7.75s-4.59,6.64-5.86,8.49c-.96,1.4-1.68,3.1-.04,4.34,1.84,1.39,3.32-.42,4.27-1.5.87-.98,4.43-5.95,5.43-7.21.67-.85,1.03-1.56,1.33-2.27.18-.47.91-2.44,1.37-3.71l-4.29-5.89Z" />
      <path
        d="m28.6,37.49c.37,0,.68.3.68.68v16.51s0,0,0,0c.99-1.44,3.96-5.73,5.25-7.61v-14.47s0-.06,0-.1c0-.06,0-.13,0-.19v-4.56c-1.18,1.79-2.32,3.66-2.46,4.14-.38,1.8-2.02,2.63-3.38,2.63-.24,0-.47-.02-.69-.07-1.61-.33-3.22-1.63-2.85-4.3l.03-.23,3.32-6.78c.36-1.07.97-1.85,1.84-2.39l6.28-5.35c-1.09-.78-2.41-1.24-3.85-1.24h-8.35c-3.67,0-6.64,2.98-6.64,6.64v11.5c.02.98.82,1.75,1.8,1.74.95-.02,1.72-.78,1.74-1.74v-10.3c0-.37.3-.68.68-.68s.68.3.68.68v10.3c0,.06,0,.13,0,.19,0,.03,0,.06,0,.1v25.14c.02,1.45,1.21,2.61,2.66,2.59,1.42-.02,2.57-1.17,2.59-2.59v-19.56c0-.37.3-.68.68-.68Z"
        opacity={0.5}
      />
      <path d="m38.52,39.23l-2.2,7.75s-4.59,6.64-5.86,8.49c-.96,1.4-1.68,3.1-.04,4.34,1.84,1.39,3.32-.42,4.27-1.5.87-.98,4.43-5.95,5.43-7.21.67-.85,1.03-1.56,1.33-2.27.18-.47.91-2.44,1.37-3.71l-4.29-5.89Z" />
    </g>
    <path d="m94.87,70.56c-13.39,0-24.25,10.86-24.25,24.25s10.86,24.25,24.25,24.25,24.25-10.86,24.25-24.25-10.86-24.25-24.25-24.25Zm11.77,21.9l-9.77,7.6c-.26.13-.54.19-.81.19-.47,0-.94-.18-1.29-.53l-14.45-15.4c-.71-.71-.71-1.86,0-2.57.71-.71,1.86-.71,2.57,0l13.53,14.47,8.6-7.02c.9-.45,1.99-.08,2.44.81.45.9.08,1.99-.81,2.44Z" />
  </svg>
);
export default WorkloadZoneBalanceIcon;
