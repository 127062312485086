import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AppBillingActiveSubscription from '../appBilling/AppBillingActiveSubscription';
import useLoadPayments from '../appBilling/hooks/useLoadPayments';
import useLoadWarehouseBillingState from '../appBilling/hooks/useLoadWarehouseBillingState';
import {
  appBillingPayments,
  appBillingPaymentsLoadStatus,
  appBillingWarehouseLoadStatus,
  appBillingWarehouseState,
} from '../appBilling/store/appBilling.state';
import { AsyncLoadStatus } from '../common/types';
import LoadingIndicator from '../components/LoadingIndicator';
import { warehouseSelectedId } from '../store/warehouse.state';
import WarehouseContainer from '../warehouse/WarehouseContainer';

export type SettingsParams = {
  sectionId?: string;
};

const Settings: React.FC = () => {
  const { t } = useTranslation('app');

  const whId = useRecoilValue(warehouseSelectedId);
  const whState = useRecoilValue(appBillingWarehouseState);
  const payments = useRecoilValue(appBillingPayments);
  const { sectionId } = useParams<SettingsParams>();

  const loadBillingState = useLoadWarehouseBillingState();
  const [loadPayments, clearPayments] = useLoadPayments();

  const whStateLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const paymentsLoadStatus = useRecoilValue(appBillingPaymentsLoadStatus);

  // refresh warehouse state
  useEffect(() => {
    loadBillingState([whId]);
    loadPayments(whId);
    return () => {
      clearPayments();
    };
  }, [whId]);

  const isLoading =
    whStateLoadStatus === AsyncLoadStatus.Loading ||
    whStateLoadStatus === AsyncLoadStatus.None ||
    paymentsLoadStatus === AsyncLoadStatus.None ||
    paymentsLoadStatus === AsyncLoadStatus.Loading;

  if (isLoading) {
    return <LoadingIndicator message={t`Loading Settings`} selfCenter />;
  }

  return (
    <WarehouseContainer fullHeaders={true}>
      <AppBillingActiveSubscription whState={whState} payments={payments} />
    </WarehouseContainer>
  );
};

export default Settings;
