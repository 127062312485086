// import Flatten from '@flatten-js/core';

import { registerEnumType } from '@warebee/shared/util-backend-only-types';

export enum WaypointType {
  LOCATION = 'LOCATION',
  PORTAL = 'PORTAL',
  INTERNAL = 'INTERNAL',
  TERMINAL = 'TERMINAL',
}

registerEnumType(WaypointType, {
  name: 'WaypointType',
});

// export class Waypoint {
//   type: WaypointType;
//   id: string;
//   position: Flatten.Point;
// }

// export class Route {
//   waypoints: Waypoint[];
//   distance: number;
//   unreachableLocations: Set<string>;
// }
