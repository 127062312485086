import { useApolloClient } from '@apollo/client';
import {
  BatchJobStatus,
  LoadAllocationRunStatusDocument,
  LoadAllocationRunStatusQuery,
  LoadAllocationRunStatusQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  allocationRunSummary,
  allocationRunSummaryLoadStatus,
} from '../store/allocation/allocation.state';

export type LoadAllocationRunParams = {
  allocationRunId: string;
};

function useLoadAllocationRun() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Allocation Run`;

  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadAllocationRun = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAllocationRunParams) => {
        const query = client.watchQuery<
          LoadAllocationRunStatusQuery,
          LoadAllocationRunStatusQueryVariables
        >({
          query: LoadAllocationRunStatusDocument,
          variables: { allocationRunId: params.allocationRunId },
        });

        function handleError(details?, stack?) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const report = data?.allocationRun;
            set(allocationRunSummary, report);

            if (
              report?.status === BatchJobStatus.FAILED ||
              report?.status === BatchJobStatus.TERMINATED
            ) {
              handleError();
            }

            if (report.status === BatchJobStatus.READY) {
              set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Ok);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAllocationRunParams) {
    await initLoading();
    await loadAllocationRun(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAllocationRun;
