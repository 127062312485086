import { ComputedDatum } from '@nivo/swarmplot';
import classNames from 'classnames';
import { format } from 'date-fns-tz';
import isWeekend from 'date-fns/isWeekend';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../../common/color.helper';
import { cn } from '../../common/utils';
import { TooltipItem } from '../../components/stats/TooltipItem';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import { FeedSwarmItem } from '../layout/FeedActivitySwarm';

export type ChartTooltipProps = {
  datum: ComputedDatum<FeedSwarmItem>;
};

const FeedSwarmChartTooltip: React.FC<ChartTooltipProps> = props => {
  const { datum } = props;
  const { t } = useTranslation('feed');
  const data = props.datum.data;
  const AgentIcon = getAgentIcon(data.agentType as AgentCategoryId);
  // const showOnLeft = props.totalX > 0 && datum.index / props.totalX > 0.66;

  const date = _.isNaN(data.eventEndTime) ? null : new Date(data.date);
  const weekend = _.isNil(date) ? false : isWeekend(date);

  const dayTitleShort = _.isNil(date)
    ? t`No Date`
    : format(date, 'MMM dd, hh:mm:ss');
  const weekdayTitle = _.isNil(date) ? null : format(date, 'EE');

  const colors = getQualitativeColor(props.datum.data.agentId, 'dimension');

  return (
    <div
      key={datum.id}
      data-component="FeedSwarmChartTooltip"
      className={cn(
        'bg-app-panel-dark/90 whitespace-nowrap text-sm backdrop-blur backdrop-filter',
      )}
      // style={{
      //   left: showOnLeft ? undefined : 16,
      //   right: showOnLeft ? 16 : undefined,
      //   top: -64,
      // }}
    >
      <div className="min-w-tooltip">
        <div
          className="flex items-center p-2 text-xs ltr:pl-3 rtl:pr-3"
          style={{
            backgroundColor: colors[0],
            color: colors[1],
          }}
        >
          <AgentIcon
            data-component="AgentResourceIcon"
            className={classNames(
              'h-6 w-6',
              'fill-current',
              'ltr:mr-2 rtl:ml-2',
            )}
          />

          {props.datum.data.agentId}
        </div>
        <div
          className={classNames(
            'flex items-center',
            'px-2 py-2',
            'text-start text-sm font-bold',
            'bg-app-panel-dark',
          )}
        >
          <span className={classNames('flex-1')}>{dayTitleShort}</span>
          {weekdayTitle && (
            <span
              className={classNames(
                'ltr:ml-3 rtl:mr-3',
                'px-2 py-0.5',
                'rounded uppercase',
                'border',
                weekend ? 'border-menu/50 text-menu-active' : 'border-menu',
              )}
            >
              {format(date, 'EE')}
            </span>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'px-1',
          'divide-menu/50 divide-y divide-solid divide-opacity-50',
          'border-menu/75 border-t',
        )}
      >
        <TooltipItem title={t`Item`} value={data.sku} />
        <TooltipItem title={t`Location`} value={data.locationId} />

        <TooltipItem title={t`Resource`} value={data.agentType} />

        <TooltipItem title={t`Process`} value={data.eventProcessType} />
        {/* <TooltipItem title={t`Type`} value={data.eventType} /> */}

        {!_.isNil(data.jobId) && (
          <TooltipItem title={t`Job ID`} value={data.jobId} />
        )}
      </div>
    </div>
  );
};

export default FeedSwarmChartTooltip;
