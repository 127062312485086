import * as React from 'react';
const PolicyWaypointStartToEndIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaypointStartToEndIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <circle cx={32.18} cy={64.51} r={11.5} strokeWidth={0} />
    <path
      fill="none"
      strokeWidth={0}
      d="M98.09,64.51c0,7.44-6.05,13.5-13.5,13.5s-13.5-6.05-13.5-13.5c0-1.52.26-2.97.73-4.33l-3.68-2.39c-.85,2.08-1.33,4.35-1.33,6.73,0,9.8,7.97,17.78,17.78,17.78s17.78-7.97,17.78-17.78-7.37-17.13-16.65-17.72l.63,4.35c6.61.87,11.74,6.52,11.74,13.37Z"
    />
    <path
      fill="none"
      strokeWidth={0}
      d="M89.31,71.57l-5.56-3.62-8.42-5.49c-.15.66-.23,1.35-.23,2.05,0,5.24,4.26,9.5,9.5,9.5s9.5-4.26,9.5-9.5c0-4.42-3.04-8.13-7.13-9.19l1.4,9.68.95,6.56Z"
    />
    <path
      strokeWidth={0}
      d="M84.6,78.01c7.44,0,13.5-6.05,13.5-13.5,0-6.84-5.13-12.5-11.74-13.37l.6,4.18c4.09,1.05,7.13,4.77,7.13,9.19,0,5.24-4.26,9.5-9.5,9.5s-9.5-4.26-9.5-9.5c0-.71.08-1.39.23-2.05l-3.5-2.28c-.46,1.36-.73,2.82-.73,4.33,0,7.44,6.05,13.5,13.5,13.5Z"
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M85.14,42.75l.11.74.48,3.3c9.28.59,16.65,8.3,16.65,17.72s-7.97,17.78-17.78,17.78-17.78-7.97-17.78-17.78c0-2.38.48-4.65,1.33-6.73l-2.41-1.57-.99-.64c-1.24,2.73-1.93,5.76-1.93,8.95,0,12.01,9.77,21.78,21.78,21.78s21.78-9.77,21.78-21.78-9.48-21.47-21.23-21.76Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M86.04,66.46l-3.38-23.35-4.98,3-.55-.46c-1.27-1.06-2.62-2.01-4.03-2.85-3.55-2.1-7.44-3.45-11.56-4-1.38-.19-2.79-.28-4.17-.28-2.71,0-5.42.36-8.04,1.08-3.85,1.05-7.41,2.81-10.59,5.23-2.28,1.74-4.28,3.76-5.98,6.03,2.64.11,5.09.96,7.14,2.36.94-1.02,1.96-1.97,3.08-2.83,2.46-1.88,5.21-3.24,8.19-4.05,2.03-.55,4.11-.83,6.2-.83,1.07,0,2.16.07,3.23.22,3.19.43,6.19,1.47,8.93,3.09.21.13.43.26.65.4l1.19.77-4.86,3.74,19.53,12.72Z"
    />
  </svg>
);
export default PolicyWaypointStartToEndIcon;
