import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import * as Icon from '../../components/icons';
import Callout from '../../helpContext/Callout';

interface WarningProps {
  children?: React.ReactNode;
  messages?: string[];
}

const PolicyWarnings: React.FC<WarningProps> = ({ children, messages }) => {
  if (_.isEmpty(messages)) return null;

  return (
    <Callout
      data-component="PolicyWarnings"
      panelMode
      type="suggestion"
      className={classNames('!bg-alerts-alert text-menu-text', 'mb-2')}
    >
      {children}

      <div className={classNames('divide-y divide-menu-white/30')}>
        {_.map(messages, msg => (
          <div key={msg} className={classNames('flex', 'py-1 xl:py-2')}>
            <Icon.TriangleInfo
              className={classNames(
                'fill-current',
                'w-5 h-5',
                'ltr:ml-1 rtl:mr-1 ltr:mr-2 rtl:ml-2',
              )}
            />
            {msg}
          </div>
        ))}
      </div>
    </Callout>
  );
};

export default PolicyWarnings;
