import { OrderSetSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import { Card } from '../components/cards/Card';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import { StatListItem } from '../components/stats/StatListItem';

export type DashboardCardProps = {
  summary: OrderSetSummaryFragment;
  itemLimit?: number | 10;
  isActionable?: boolean;
};

const OrderSetDashboardCard: React.FC<DashboardCardProps> = props => {
  const { t } = useTranslation('simulation');

  const { summary } = props;
  if (!summary) return null;

  const { itemLimit } = props;
  const orderLinesCount =
    _.sumBy(summary?.consignees, c => c.orderLineCount) ?? 0;

  return (
    <Card
      isActionable={props.isActionable}
      transparent
      label={`SimulationDashboardCard`}
    >
      <StatGroup
        helpNavTo={'simulation/dataset/simulation-dataset-stats-orders'}
      >
        <Stat
          dashboardView
          isPreview
          title={t`Total Orders`}
          value={formatInteger(summary.orderCount)}
        />
        <Stat
          dashboardView
          isPreview
          title={t`Total Order Lines`}
          value={formatInteger(orderLinesCount)}
        />
        <Stat
          dashboardView
          isPreview
          title={t(`Top {{itemLimit}} client(s) by Orders count`, {
            itemLimit,
          })}
        >
          {_.take(
            _.sortBy(summary.consignees, c => -c.orderCount),
            itemLimit,
          ).map(cons => (
            <StatListItem
              key={`si-by-order-${cons.consignee}`}
              title={cons.consignee}
              value={t('{{orders}} ({{orderLines}} lines)', {
                orders: formatInteger(cons.orderCount),
                orderLines: formatInteger(cons.orderLineCount),
              }).toString()}
            />
          ))}
        </Stat>
        <Stat
          dashboardView
          isPreview
          title={t(`Top {{itemLimit}} client(s) by Items count`, {
            itemLimit,
          })}
        >
          {_.take(
            _.sortBy(summary.consignees, c => -c.productCount),
            itemLimit,
          ).map(cons => (
            <StatListItem
              key={`si-by-product-${cons.consignee}`}
              title={cons.consignee}
              value={formatInteger(cons.productCount).toString()}
            />
          ))}
        </Stat>
      </StatGroup>
    </Card>
  );
};

export default OrderSetDashboardCard;
