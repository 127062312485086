import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../../common/constants';
import { formatInteger } from '../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import ErrorIndicator from '../../../components/ErrorIndicator';
import * as Icon from '../../../components/icons';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Stat } from '../../../components/stats/Stat';
import { StatGroup } from '../../../components/stats/StatGroup';
import { viewerShowLocations } from '../../../layout/viewer/store/viewer.state';
import useLoadWeightComplianceMeta from '../../hooks/useLoadWeightComplianceMeta';
import { simulationCurrent } from '../../store/simulation.state';
import {
  weightComplianceBaysState,
  weightComplianceLocationsState,
  weightComplianceMeta,
  weightComplianceMetaLoadStatus,
} from '../../store/weightCompliance.state';

const ComplianceWeightPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrent)?.id;
  const formatter = useFormatter();

  const [showLocations, setShowLocations] = useRecoilState(viewerShowLocations);
  const [baysSearchState, setBaysSearchState] = useRecoilState(
    weightComplianceBaysState,
  );
  const [locationsSearchState, setLocationsSearchState] = useRecoilState(
    weightComplianceLocationsState,
  );
  const complianceMeta = useRecoilValue(weightComplianceMeta);
  const complianceMetaLoadStatus = useRecoilValue(
    weightComplianceMetaLoadStatus,
  );
  const [loadMeta, cancelLoadMeta] = useLoadWeightComplianceMeta();

  useEffect(() => {
    if (complianceMetaLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    // compliance not triggered
    if (_.isNil(complianceMeta?.status)) {
      loadMeta({
        simulationId: simulationId,
      });
    }
    if (
      complianceMeta?.status === BatchJobStatus.CREATED ||
      complianceMeta?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadMeta({
          simulationId: simulationId,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadMeta();
    };
  }, [complianceMeta]);

  const hasError =
    complianceMetaLoadStatus === AsyncLoadStatus.Error ||
    complianceMeta?.status === BatchJobStatus.FAILED;

  const isLoading = complianceMeta?.status !== BatchJobStatus.READY;

  const baySummary = complianceMeta?.baySummary?.items;
  const locSummary = complianceMeta?.locationSummary?.items;

  // Bay Weight - Safety Compliance
  const totalBayCount = _.sumBy(baySummary, item => item.totalCount);
  const nonCompliantBayCount = _.sumBy(
    baySummary,
    item => item.nonCompliantCount,
  );
  const compliantBayCount = _.sumBy(baySummary, item => item.compliantCount);
  const unknownBayCount = _.sumBy(baySummary, item => item.unknownCount);
  const emptyBayCount = _.sumBy(baySummary, item => item.unassignedCount);

  const bayShare = formatter.formatShare(
    totalBayCount > 0 ? nonCompliantBayCount / totalBayCount : 0,
  );

  // Locations Weight - Safety Compliance
  const totalLocCount = _.sumBy(locSummary, item => item.totalCount);
  const nonCompliantLocCount = _.sumBy(
    locSummary,
    item => item.nonCompliantCount,
  );

  const compliantLocCount = _.sumBy(locSummary, item => item.compliantCount);
  const unknownLocCount = _.sumBy(locSummary, item => item.unknownCount);
  const emptyLocCount = _.sumBy(locSummary, item => item.unassignedCount);

  const locShare = formatter.formatShare(
    totalLocCount > 0 ? nonCompliantLocCount / totalLocCount : 0,
  );

  return (
    <>
      <ScreenTitle
        subtitle={t`Analyse — Compliance`}
        title={t`Weight Compliance`}
        // isSticky
        helpNavTo={'simulation/compliance/simulation-compliance-weight'}
        icon={Icon.PolicyComplianceWeight}
      />

      {hasError && (
        <ErrorIndicator message={t`Cannot load weight compliance issues`} />
      )}
      {isLoading && !hasError && (
        <LoadingIndicator message={t`Loading compliance data`} selfCenter />
      )}
      {!isLoading && !hasError && (
        <>
          <StatGroup
            title={t`Bays`}
            subTitle={t`Weight Safety Compliance`}
            helpNavTo={'simulation/compliance/simulation-compliance-weight'}
          >
            <Stat
              isSectionable
              toggleTable
              isPreview
              title={t`Bays (Non-Compliant)`}
              value={formatInteger(nonCompliantBayCount)}
              valueTotal={formatInteger(totalBayCount)}
              valuePercent={bayShare}
              isSelectable={true}
              isSelected={!showLocations}
              isActionable
              onClick={() => {
                if (nonCompliantBayCount === 0) {
                  setBaysSearchState({
                    ...baysSearchState,
                    searchValues: {
                      ...(baysSearchState.searchValues ?? {}),
                      status: null,
                    },
                  });
                }
                setShowLocations(!showLocations);
              }}
            />
            {/* <Stat
          hasHelper
          isPreview
          title={'Non-Compliant Bays'}
          // counter={'0'}
          isFullWidth
          inPanelMode
          hasListDecimals
        >
          <StatListHeader hasSearch title={t`Bay`} value={'...'} />
        </Stat> */}
            <Stat
              hasHelper
              isPreview
              title={t`Compliant Bays`}
              // counter={
              //   formatInteger(compliantBayCount) +
              //   ` of ` +
              //   formatInteger(totalBayCount)
              // }
              isSectionable
              value={formatInteger(compliantBayCount)}
              valueTotal={formatInteger(totalBayCount)}
              isFullWidth
              inPanelMode
              hasListDecimals
            />
            <Stat
              hasHelper
              isPreview
              title={t`Unknown Bays`}
              // counter={
              //   formatInteger(unknownBayCount) +
              //   ` of ` +
              //   formatInteger(totalBayCount)
              // }
              isSectionable
              value={formatInteger(unknownBayCount)}
              valueTotal={formatInteger(totalBayCount)}
              isFullWidth
              inPanelMode
              hasListDecimals
            />
            <Stat
              hasHelper
              isPreview
              title={t`Unassigned (Empty) Bays`}
              // counter={
              //   formatInteger(emptyBayCount) + ` of ` + formatInteger(totalBayCount)
              // }
              isSectionable
              value={formatInteger(emptyBayCount)}
              valueTotal={formatInteger(totalBayCount)}
              isFullWidth
              inPanelMode
              hasListDecimals
            />
          </StatGroup>
          <StatGroup
            title={t`Locations`}
            subTitle={t`Weight Safety Compliance`}
            helpNavTo={'simulation/compliance/simulation-compliance-weight'}
          >
            <Stat
              isSectionable
              toggleTable
              isActionable
              isPreview
              title={t`Locations (Non-Compliant)`}
              value={formatInteger(nonCompliantLocCount)}
              valueTotal={formatInteger(totalLocCount)}
              valuePercent={locShare}
              isSelectable={true}
              isSelected={showLocations}
              onClick={() => {
                if (nonCompliantLocCount === 0) {
                  setLocationsSearchState({
                    ...locationsSearchState,
                    searchValues: {
                      ...(locationsSearchState.searchValues ?? {}),
                      status: null,
                    },
                  });
                }
                setShowLocations(!showLocations);
              }}
            />
            {/* <Stat
          hasHelper
          isPreview
          title={'Non-Compliant locations'}
          // counter={'0'}
          isFullWidth
          inPanelMode
          hasListDecimals
        ></Stat> */}
            <Stat
              hasHelper
              isPreview
              title={t`Compliant locations`}
              // counter={
              //   formatInteger(compliantLocCount) +
              //   ` of ` +
              //   formatInteger(totalLocCount)
              // }
              isSectionable
              value={formatInteger(compliantLocCount)}
              valueTotal={formatInteger(totalLocCount)}
              isFullWidth
              inPanelMode
              hasListDecimals
            />
            <Stat
              hasHelper
              isPreview
              title={t`Unknown Locations`}
              // counter={
              //   formatInteger(unknownLocCount) +
              //   ` of ` +
              //   formatInteger(totalLocCount)
              // }
              isSectionable
              value={formatInteger(unknownLocCount)}
              valueTotal={formatInteger(totalLocCount)}
              isFullWidth
              inPanelMode
              hasListDecimals
            />
            <Stat
              hasHelper
              isPreview
              title={t`Unassigned (Empty) Locations`}
              // counter={
              //   formatInteger(emptyLocCount) + ` of ` + formatInteger(totalLocCount)
              // }
              isSectionable
              value={formatInteger(emptyLocCount)}
              valueTotal={formatInteger(totalLocCount)}
              isFullWidth
              inPanelMode
              hasListDecimals
            />
          </StatGroup>
        </>
      )}
    </>
  );
};

export default ComplianceWeightPanel;
