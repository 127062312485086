import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { HelperMessage } from '../../../../components/HelperMessage';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputText from '../../../../components/inputs/InputText';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import { ContainerCol } from '../../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import { Spacer } from '../../../../components/layout/Spacer';
import TitleSection from '../../../../components/layout/TitleSection';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { importTriggeredBySim } from '../../../../store/global.state';
import {
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../../../store/warehouse.state';
import useUpdateLayoutImport from '../hooks/useUpdateLayoutImport';
import { converterLayoutData } from '../store/converter.state';

const StepRename: React.FC = () => {
  const { t } = useTranslation('dataset');
  const navigate = useNavigate();

  const whId = useRecoilValue(warehouseSelectedId);
  const saveConverterIdForSettings = useSetRecoilState(importTriggeredBySim);
  const [document, setDocument] = useRecoilState(converterLayoutData);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const updateLayoutImport = useUpdateLayoutImport();

  const changeTitle = (title: string) => {
    setDocument({ ...document, title: title });
    updateLayoutImport({ layoutImportId: document.id, title: title });
  };

  const changeDescription = (description: string) => {
    setDocument({ ...document, description: description });
    updateLayoutImport({
      layoutImportId: document.id,
      description: description,
    });
  };
  const proceedWithImport = () => {
    saveConverterIdForSettings(document.id);
    navigate(`/wh/i/${whId}/import/layout`);
  };

  if (!document) return null;

  return (
    <PanelBody className="h-full">
      <ContainerCol overflow>
        <ScreenTitle
          isSticky
          title={t`Name & Description`}
          subtitle={t`Layout Setup`}
        />
        <HelperMessage hasPadding className="pb-0">
          <HelperMessage iconAction={Icon.ArrowBottom}>
            {t`Enter your layout name and description`}
          </HelperMessage>
        </HelperMessage>
        <TitleSection className="mt-4" titleView title={t`Layout Properties`} />
        <InputGroupList hasPadding hasSpacing>
          <InputText
            className="bg-app-panel-dark"
            placeholder={t`Layout Name`}
            label={t`Layout Name`}
            name={t`Layout Name`}
            value={document.title}
            onChange={changeTitle}
            isDisabled={!canUpdate}
            animate={!canUpdate && document.title?.trim().length === 0}
          />
          <InputTextArea
            className="bg-app-panel-dark"
            label={t`Layout Description (Optional)`}
            placeholder={t`About the Layout...`}
            value={document.description ?? ''}
            onChange={changeDescription}
            isDisabled={!canUpdate}
            animate={!canUpdate && document.description?.trim().length === 0}
          />
        </InputGroupList>

        <Spacer flexspace />
        <HelpContainer id={'designer/01-layout-properties'} hasPadding />

        {/* <HelperMessage hasPadding>
          <HelperMessage isSuggestion>
            {t`It's a good practice to descriptively name your data for future use.`}
          </HelperMessage>
        </HelperMessage>
        <HelperMessage hasPadding>
          <HelperMessage hasBefore iconAction={Icon.ArrowRightBottom}>
            {t`Click`}
            <HelperMessageAction>{t`Next`}</HelperMessageAction>
            {t`button bellow to get started.`}
          </HelperMessage>
        </HelperMessage> */}
      </ContainerCol>
    </PanelBody>
  );
};

export default StepRename;
