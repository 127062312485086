import * as React from 'react';
const CirclePhoneIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CirclePhoneIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M64.23,2c33.9,0,61.77,27.87,61.77,62.23s-27.87,61.77-61.77,61.77S2,98.13,2,64.23,29.87,2,64.23,2ZM23.83,34.05s9.29-10.22,13.47-10.22c4.64.46,10.68,10.68,12.54,16.26,1.39,5.57-2.32,9.75-3.72,11.61-1.39,1.86-2.32,3.25-2.32,3.25,3.72,11.15,18.58,26.01,29.26,29.72,0,0,1.39-1.39,3.25-2.79,2.32-1.39,6.04-5.11,11.61-3.25,6.04,1.39,15.79,7.43,16.26,12.07.46,4.64-10.22,13.47-10.22,13.47-35.3,1.39-71.52-34.37-70.13-70.13h0Z"
    />
  </svg>
);
export default CirclePhoneIcon;
