import * as React from 'react';
const DesignerAislesSpacersIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesSpacersIcon"
    {...props}
  >
    <defs>
      <clipPath>
        <polyline
          strokeWidth={0}
          points="112.19 98.23 112.19 107.08 125.94 107.08 125.94 98.23"
        />
      </clipPath>
      <clipPath>
        <polyline
          strokeWidth={0}
          points="2.41 65.23 2.41 85.74 26.38 85.74 26.38 65.23"
        />
      </clipPath>
      <clipPath>
        <polyline
          strokeWidth={0}
          points="46.35 65.23 46.35 85.74 77.42 85.74 77.42 65.23"
        />
      </clipPath>
    </defs>
    <rect
      width={17.73}
      height={105.24}
      x={78.82}
      y={1.84}
      strokeWidth={0}
      opacity={0.6}
    />
    <rect
      width={17.73}
      height={83.7}
      x={27.67}
      y={23.37}
      strokeWidth={0}
      opacity={0.6}
    />
    <rect
      width={17.86}
      height={124}
      x={55.07}
      y={55.07}
      strokeWidth={0}
      opacity={0.6}
      transform="translate(181.07 53.08) rotate(90)"
    />
    <rect
      width={13.75}
      height={32.33}
      x={112.25}
      y={65.32}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={19.6}
      x={112.25}
      y={44.28}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={19.6}
      x={112.25}
      y={23.37}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={19.6}
      x={112.25}
      y={2}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={20.73}
      x={97.36}
      y={86.35}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={40.63}
      x={97.36}
      y={44.28}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={19.6}
      x={97.36}
      y={23.37}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={19.6}
      x={97.36}
      y={2}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={62.76}
      y={87.19}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={62.76}
      y={44.51}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={62.76}
      y={23.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={46.45}
      y={87.19}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={46.45}
      y={44.51}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={46.45}
      y={23.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.49}
      x={62.76}
      y={2}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={11.71}
      y={87.19}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={11.71}
      y={44.51}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={14.66}
      height={19.89}
      x={11.71}
      y={23.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={7.66}
      height={19.89}
      x={2.41}
      y={87.19}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={7.66}
      height={19.89}
      x={2.41}
      y={44.51}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={7.66}
      height={19.89}
      x={2.41}
      y={23.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="61.35 2.31 56.1 2.31 61.35 6.86 61.35 2.31"
    />
    <polygon
      strokeWidth={0}
      points="40.18 2.31 29.57 2.31 51.46 21.29 61.35 21.29 61.35 20.67 40.18 2.31"
    />
    <polygon
      strokeWidth={0}
      points="53.45 2.31 42.83 2.31 61.35 18.37 61.35 9.17 53.45 2.31"
    />
    <polyline
      strokeWidth={0}
      points="24.93 21.29 35.54 21.29 13.65 2.31 3.04 2.31"
    />
    <polygon
      strokeWidth={0}
      points="26.92 2.31 16.31 2.31 38.19 21.29 48.8 21.29 26.92 2.31"
    />
    <polygon
      strokeWidth={0}
      points="2.73 4.34 2.73 13.55 11.66 21.29 22.27 21.29 2.73 4.34"
    />
    <polygon
      strokeWidth={0}
      points="2.73 21.29 9.01 21.29 2.73 15.85 2.73 21.29"
    />
    <polyline points="112.19 98.23 112.19 107.08 125.94 107.08 125.94 98.23" />
    <g clipPath="url(#b)">
      <polygon
        strokeWidth={0}
        points="121.92 98.23 129.85 107.08 133.44 107.08 133.44 106.79 125.77 98.23 121.92 98.23"
      />
      <polygon
        strokeWidth={0}
        points="126.73 98.23 133.44 105.72 133.44 101.43 130.57 98.23 126.73 98.23"
        opacity={0.75}
      />
      <polygon
        strokeWidth={0}
        points="112.31 98.23 120.24 107.08 124.08 107.08 116.15 98.23 112.31 98.23"
      />
      <polygon
        strokeWidth={0}
        points="117.11 98.23 125.05 107.08 128.89 107.08 120.96 98.23 117.11 98.23"
      />
      <polygon
        strokeWidth={0}
        points="112.19 103.47 115.43 107.08 119.28 107.08 112.19 99.18 112.19 103.47"
      />
      <polygon
        strokeWidth={0}
        points="112.19 107.08 114.47 107.08 112.19 104.54 112.19 107.08"
      />
    </g>
    <polyline points="2.41 65.23 2.41 85.74 26.38 85.74 26.38 65.23" />
    <g clipPath="url(#c)">
      <polygon
        strokeWidth={0}
        points="36.13 65.23 39.45 70.15 39.45 65.23 36.13 65.23"
        opacity={0.75}
      />
      <polygon
        strokeWidth={0}
        points="19.37 65.23 33.19 85.74 39.45 85.74 39.45 85.07 26.07 65.23 19.37 65.23"
      />
      <polygon
        strokeWidth={0}
        points="27.75 65.23 39.45 82.58 39.45 72.63 34.45 65.23 27.75 65.23"
        opacity={0.75}
      />
      <polygon
        strokeWidth={0}
        points="2.6 65.23 16.43 85.74 23.14 85.74 9.31 65.23 2.6 65.23"
      />
      <polygon
        strokeWidth={0}
        points="10.98 65.23 24.81 85.74 31.52 85.74 17.69 65.23 10.98 65.23"
      />
      <polygon
        strokeWidth={0}
        points="2.41 77.37 8.05 85.74 14.76 85.74 2.41 67.42 2.41 77.37"
      />
      <polygon
        strokeWidth={0}
        points="2.41 85.74 6.37 85.74 2.41 79.85 2.41 85.74"
      />
    </g>
    <polyline points="46.35 65.23 46.35 85.74 77.42 85.74 77.42 65.23" />
    <g clipPath="url(#d)">
      <polygon
        strokeWidth={0}
        points="90.06 65.23 94.36 70.15 94.36 65.23 90.06 65.23"
        opacity={0.75}
      />
      <polygon
        strokeWidth={0}
        points="68.33 65.23 86.26 85.74 94.36 85.74 94.36 85.07 77.03 65.23 68.33 65.23"
      />
      <polygon
        strokeWidth={0}
        points="79.2 65.23 94.36 82.58 94.36 72.63 87.89 65.23 79.2 65.23"
        opacity={0.75}
      />
      <polygon
        strokeWidth={0}
        points="46.61 65.23 64.53 85.74 73.22 85.74 55.3 65.23 46.61 65.23"
      />
      <polygon
        strokeWidth={0}
        points="57.47 65.23 75.39 85.74 84.09 85.74 66.16 65.23 57.47 65.23"
      />
      <polygon
        strokeWidth={0}
        points="46.35 77.37 53.67 85.74 62.36 85.74 46.35 67.42 46.35 77.37"
      />
      <polygon
        strokeWidth={0}
        points="46.35 85.74 51.49 85.74 46.35 79.85 46.35 85.74"
      />
    </g>
  </svg>
);
export default DesignerAislesSpacersIcon;
