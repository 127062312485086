import * as React from 'react';
const LinkSourceAreaLockedIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkSourceAreaLockedIcon"
    {...props}
  >
    <path
      fill="#070101"
      strokeWidth={0}
      d="M85.08,5.15H13.47c-5.32,0-9.73,4.41-9.73,9.73v71.61c0,5.41,4.41,9.73,9.73,9.73h21.88v-56.56c0-3.16,2.66-5.82,5.91-5.82h53.57V14.88c0-5.41-4.41-9.73-9.73-9.73Z"
    />
    <path
      fill="#070101"
      strokeWidth={0}
      d="M114.61,33.85h-19.8v56.56c0,3.24-2.66,5.91-5.91,5.91h-53.57v16.8c0,5.41,4.41,9.73,9.73,9.73h69.45c5.41,0,9.73-4.41,9.73-9.73V43.58c.08-5.32-4.33-9.73-9.65-9.73Z"
    />
    <path
      fill="#070101"
      strokeWidth={0}
      d="M64.96,43.57c-7.64,0-13.91,5.35-13.91,11.85v4.12h-.3c-2.04,0-3.7,1.42-3.7,3.16v22.26c0,1.74,1.66,3.16,3.7,3.16h28.27c2.04,0,3.7-1.42,3.7-3.16v-22.33c-.15-1.74-1.74-3.09-3.78-3.09h-.08v-4.12c0-6.51-6.27-11.85-13.91-11.85ZM72.82,59.54h-15.72s0-4.12,0-4.12c0-3.67,3.48-6.7,7.86-6.7s7.86,2.96,7.86,6.7v4.12Z"
    />
  </svg>
);
export default LinkSourceAreaLockedIcon;
