import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import React from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { TwTheme } from '../../../../../Tw';
import BayFeature from '../../../../layout/features/BayFeature';
import {
  converterAreaConfiguration,
  converterEditableArea,
} from '../store/converter.area.state';
import { getLayerConfig } from '../store/converter.helper';
import {
  converterLayoutAltSelectedBayId,
  converterLayoutHoveredFeature,
  converterLayoutSelectedBay,
  converterShelvingTypeSelected,
} from '../store/converter.layout.state';
import {
  converterBayTypesBuilder,
  converterWizardStepId,
} from '../store/converter.state';
import { converterSelectedFloorValidationResult } from '../store/validation.state';

const bayColors = TwTheme.extend.colors.bay;

const ConverterBayLayer: React.FC = () => {
  const areaResponse = useRecoilValueLoadable(converterEditableArea);
  const setSelectedBay = useSetRecoilState(converterLayoutSelectedBay);
  const highlightedBayId = useRecoilValue(converterLayoutAltSelectedBayId);
  const setHovered = useSetRecoilState(converterLayoutHoveredFeature);
  const areasConfigResponse = useRecoilValueLoadable(
    converterAreaConfiguration,
  );
  const bayTypes = useRecoilValue(converterBayTypesBuilder);
  const validationResult = useRecoilValue(
    converterSelectedFloorValidationResult,
  );
  const step = useRecoilValue(converterWizardStepId);
  const selectedShelvesType = useRecoilValue(converterShelvingTypeSelected);

  if (
    areasConfigResponse.state !== 'hasValue' ||
    areaResponse.state !== 'hasValue' ||
    !areaResponse.contents
  )
    return null;
  const area = areaResponse.contents;
  const areasConfig = areasConfigResponse.contents;
  const areaConfig = areasConfig[area?.id];

  const showValidation = step === 'designer' || step === 'connect-areas';

  const unreachableBays = new Set(
    showValidation ? validationResult?.unreachableBayIds || [] : [],
  );

  return (
    <Layer {...getLayerConfig(areaConfig, area)}>
      {[...area.bays].map(bay => (
        <BayFeature
          key={`bay-${bay.id}`}
          bay={bay}
          bayType={bayTypes[bay.details.bayType ?? DEFAULT_BAY_TYPE]}
          isUnreachable={unreachableBays.has(bay.id)}
          color={
            bay.shelvingType === selectedShelvesType ? bayColors.active : null
          }
          textColor={bay.shelvingType === selectedShelvesType ? '#000' : null}
          onClick={() => {
            setSelectedBay(bay);
          }}
          onMouseOver={() => {
            setHovered(bay);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
        />
      ))}
    </Layer>
  );
};

export default ConverterBayLayer;
