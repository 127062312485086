const LinkSourceAreaIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkSourceAreaIcon"
    {...props}
  >
    <path d="M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM109,99.56c0,5.22-4.23,9.45-9.45,9.45h-5.39l-54.13-54.13.03,40.85-21.06-21V19h55.69l21.02,21h-40.83s54.13,54.1,54.13,54.1v5.46Z" />
  </svg>
);
export default LinkSourceAreaIcon;
