import { LayoutImportConverterLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Paths } from 'type-fest';
import { DATASET_VIEW_PAGE_SIZE } from '../../../../common/constants';
import { DatasetDataViewProps } from '../../../../common/types';
import DatasetTable from '../../../../components/DatasetTable';
import { ConvertedLocationFeature } from '../converter.serializable.model';
import {
  converterLocationsTableState,
  converterPatchedLocations,
} from '../store/converter.state';
import useConverterLocationTableConfig from './useConverterLocationTableConfig';

const ConverterLocationTable: React.FC<
  DatasetDataViewProps<ConvertedLocationFeature>
> = props => {
  const { t } = useTranslation('designer');

  const data = _.values(useRecoilValue(converterPatchedLocations));
  const [tableState, setTableState] = useRecoilState(
    converterLocationsTableState,
  );
  const columnsConfig = useConverterLocationTableConfig();
  const [rowsCount, setRowsCount] = useState(DATASET_VIEW_PAGE_SIZE);

  function onLoadNext() {
    setRowsCount(rowsCount + DATASET_VIEW_PAGE_SIZE);
  }

  const sv = tableState.searchValues;
  const fieldsWithFilter = _(columnsConfig)
    .filter(c => c.hasFilter)
    .map(c => c.field)
    .value();

  const contains = (
    row: LayoutImportConverterLocationFragment,
    path: Paths<LayoutImportConverterLocationFragment>,
  ) => {
    return (
      _.isEmpty(_.get(sv, path)) ||
      _.get(row, path)
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(_.get(sv, path)?.toLowerCase()) > -1
    );
  };

  const filteredDataAll = _(data).filter(row => {
    return _.every(fieldsWithFilter, p => contains(row, p));
  });

  const filteredData = filteredDataAll.take(rowsCount).value();
  const isLoading = false;
  const totalCount = filteredDataAll.size() ?? 0;

  return (
    <DatasetTable
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Locations`}
      title={props.title}
      isSticky
      id={props?.id}
      columnsConfig={columnsConfig}
      keyFields={['locationId']}
      data={filteredData}
      onLoadNext={onLoadNext}
      onSearch={v => {
        setTableState({ ...tableState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={tableState.searchValues}
      isLoading={isLoading}
    />
  );
};

export default ConverterLocationTable;
