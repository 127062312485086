import {
  LoadSizeComplianceMetaDocument,
  LoadSizeComplianceMetaQuery,
  LoadSizeComplianceMetaQueryVariables,
  RunCalculateSizeComplianceDocument,
  RunCalculateSizeComplianceMutation,
  RunCalculateSizeComplianceMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  sizeComplianceMeta,
  sizeComplianceMetaLoadStatus,
} from '../store/sizeCompliance.state';

export type LoadSizeComplianceMetaParams = {
  simulationId: string;
};

function useLoadSizeComplianceMeta() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load size compliance metadata`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(sizeComplianceMetaLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadSizeComplianceMetaParams) => {
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(sizeComplianceMetaLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadSizeComplianceMetaQuery,
          LoadSizeComplianceMetaQueryVariables
        >({
          query: LoadSizeComplianceMetaDocument,
          variables: {
            simulationId: params.simulationId,
          },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let compliance = data?.simulation?.sizeCompliance;

            if (_.isNil(compliance)) {
              try {
                const response = await secureClient.mutate<
                  RunCalculateSizeComplianceMutation,
                  RunCalculateSizeComplianceMutationVariables
                >({
                  mutation: RunCalculateSizeComplianceDocument,
                  variables: {
                    simulationId: params.simulationId,
                  },
                });
                compliance = response.data?.calculateSimulationSizeCompliance;
              } catch (ex) {
                console.error(errorTitle, ex);
                handleError(ex.message || ex, ex.stack || null);
              }
            }

            set(sizeComplianceMeta, compliance);
            set(sizeComplianceMetaLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadSizeComplianceMetaParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadSizeComplianceMeta;
