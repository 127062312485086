import _ from 'lodash';
import React, { useState } from 'react';
import { JsonObject } from 'type-fest';
import { DatasetTableState } from '../../../common/types';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';

export type ImporterJSONTablePreviewProps = {
  id: string;
  data: JsonObject[];
  columns: string[];
};

const ImporterJSONTablePreview: React.FC<
  ImporterJSONTablePreviewProps
> = props => {
  const [tableState, setTableState] = useState<DatasetTableState<any>>({
    searchValues: {},
    sortValues: {},
  });

  const preview = _.map(props.data, row => {
    return _.reduce(
      _.entries(row),
      (acc, [key, value]) => {
        const loweredKey =
          typeof key === 'number' ? key : key?.toString()?.toLowerCase();
        if (_.isNil(loweredKey)) {
          return acc;
        }
        return {
          ...acc,
          [loweredKey]: value,
        };
      },
      {} as JsonObject,
    );
  });
  const previewSize = _.size(preview);

  const columnsConfig: ColumnConfig<JsonObject>[] = _.map(
    props.columns,
    column => ({
      field: column,
      title: column,
      hasFilter: true,
    }),
  ) as any;

  const sv = tableState.searchValues;
  const fieldsWithFilter = _(columnsConfig)
    .filter(c => c.hasFilter)
    .map(c => c.field)
    .value();

  const contains = (row: Object, path: string) => {
    return (
      _.isEmpty(_.get(sv, path)) ||
      _.get(row, path)
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(_.get(sv, path)?.toLowerCase()) > -1
    );
  };

  const filteredDataAll = _(preview)
    .filter(row => {
      return _.every(fieldsWithFilter, p => contains(row, p));
    })
    .value();

  return (
    <DatasetTable
      id={`import-data-preview-${props.id}`}
      data={filteredDataAll}
      columnsConfig={columnsConfig}
      keyFields={props.columns}
      hideScreenTitle={true}
      isSticky
      totalCount={_.size(filteredDataAll)}
      searchValues={tableState.searchValues}
      isLoading={false}
      onLoadNext={_.noop}
      onSearch={v => {
        setTableState({ ...tableState, searchValues: v });
      }}
      hasRowCounter
    />
  );
};
export default ImporterJSONTablePreview;
