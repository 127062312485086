import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { getOccupancyCategory } from '../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyCategoryFilter,
  assignmentOccupancyLevelLocation,
  assignmentOccupancyShowHeatmap,
} from '../store/occupancy/assignmentOccupancy.state';

const AssignmentOccupancyLocationLayer: React.FC = () => {
  const levelLocationLoadable = useRecoilValueLoadable(
    assignmentOccupancyLevelLocation,
  );
  const showHeatmap = useRecoilValue(assignmentOccupancyShowHeatmap);
  const categoryFilter = useRecoilValue(assignmentOccupancyCategoryFilter);

  const levelLocation =
    levelLocationLoadable.state === 'hasValue'
      ? levelLocationLoadable.contents
      : null;

  const hasFilter = !_.isEmpty(categoryFilter);
  const filter = (locationFeature: LayoutLevelLocationFragment) => {
    const loc = levelLocation?.[locationFeature.locationId];
    if (_.isNil(loc)) return false;

    const cat = getOccupancyCategory(loc?.occupancy);
    return _.has(categoryFilter, cat.key);
  };

  function getLabel(locationFeature: LayoutLevelLocationFragment) {
    const loc = levelLocation?.[locationFeature.locationId];
    const itemCount = _.size(loc?.items);
    return itemCount > 1 ? formatInteger(itemCount) : null;
  }

  function getColor(
    locationFeature: LayoutLevelLocationFragment,
  ): [string, string] {
    const loc = levelLocation?.[locationFeature.locationId];
    if (loc && showHeatmap) {
      const cat = getOccupancyCategory(loc?.occupancy);
      return [cat.color, cat.textColor];
    }
    return null;
  }

  return (
    <LocationLayer
      filter={hasFilter ? filter : null}
      getLabel={getLabel}
      getColor={showHeatmap ? getColor : null}
    />
  );
};

export default AssignmentOccupancyLocationLayer;
