import * as React from 'react';
const CloseIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CloseIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="114 22.69 105.31 14 64 55.31 22.69 14 14 22.69 55.31 64 14 105.31 22.69 114 64 72.69 105.31 114 114 105.31 72.69 64 114 22.69"
    />
  </svg>
);
export default CloseIcon;
