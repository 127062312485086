import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { StatGroup } from '../components/stats/StatGroup';
import { loadBalancingPolicyDocument } from '../loadBalancingPolicy/loadBalancingPolicy.state';
import AnalyzeResultLoadBalancingRuleCompare from './AnalyzeResultLoadBalancingRuleCompare';
import { AnalyzeCompareComponentBaseProps } from './analyze.types';

const AnalyzeResultLoadBalancingCompare: React.FC<
  AnalyzeCompareComponentBaseProps
> = props => {
  const { t } = useTranslation('simulation');
  const policy = useRecoilValue(loadBalancingPolicyDocument);

  if (_.isEmpty(policy?.rules)) {
    return null;
  }

  return (
    <StatGroup
      title={t`Compare Load Balancing`}
      subTitle={t`Stats`}
      helpNavTo={'simulation/optimise/simulation-optimise-summary-workload'}
    >
      {_.map(policy?.rules, r => (
        <AnalyzeResultLoadBalancingRuleCompare
          key={r.id}
          analyzeIdBefore={props.beforeResult.id}
          analyzeIdAfter={props.afterResult.id}
          rule={r}
        />
      ))}
    </StatGroup>
  );
};

export default AnalyzeResultLoadBalancingCompare;
