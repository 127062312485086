import { ImportFormat } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import FileUpload from '../../../components/FileUpload';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import {
  importSelectedFile,
  importSourceSettings,
} from '../../store/import.state';
import useUploadPreviewFile from '../../useUploadPreviewFile';

export type ImporterFileSelectorProps = {
  actionName: string;
  filename: string;
  disabled?: boolean;
};

const ImporterFileSelector: React.FC<ImporterFileSelectorProps> = props => {
  const { t } = useTranslation('importer');
  const [file, setFile] = useRecoilState(importSelectedFile);
  const [sourceSettings, setSourceSettings] =
    useRecoilState(importSourceSettings);

  const [createPreview] = useUploadPreviewFile();

  let filterExtensions: string;
  let IconComponent;
  let compressionText = '';

  if (sourceSettings.compression === 'GZIP') {
    compressionText = '.gz';
  }

  switch (sourceSettings.format) {
    case 'CSV':
      filterExtensions = `.csv${compressionText}, .tsv${compressionText}, .txt${compressionText}`;
      IconComponent = Icon.LayoutWarehouseCsv;
      break;
    case 'JSON':
      filterExtensions = `.json${compressionText}, ${compressionText}`;
      IconComponent = Icon.LayoutWarehouseTemplate;
      break;
    default:
      filterExtensions = '.*';
      IconComponent = Icon.LayoutWarehouseTemplate;
      break;
  }

  const shouldImportFile = sourceSettings.format !== ImportFormat.DATASET;
  return (
    <Container
      className={classNames(
        'p-1',
        props.disabled
          ? 'striped-container-sm-disabled'
          : 'bg-barber-pole bg-barber-size animate-spinny bg-menu-active text-menu-active-text',
      )}
    >
      {shouldImportFile && (
        <FileUpload
          accept={filterExtensions}
          onChange={async file => {
            setFile(file);
            createPreview({ file });
          }}
        >
          <div
            className={classNames(
              'flex-1',
              'flex w-full items-center justify-center',
              'mb-4',
            )}
          >
            <IconComponent
              className={classNames(
                'h-32 w-32',
                'fill-current',
                'text-menu/50',
              )}
            />
          </div>
          <div
            className={classNames('p-10', 'flex w-full flex-col items-center')}
          >
            {/* Rest of the UI remains the same */}
            <div
              className={classNames(
                'flex items-center justify-between',
                'w-full',
                'px-4 py-2',
                'mt-4',
                'text-center',
                'rounded',
                'bg-app-panel-dark/60',
              )}
            >
              <span className={classNames('text-menu-active flex-1')}>
                <span className={classNames('opacity-50 ltr:mr-1 rtl:ml-1')}>
                  {t(`Accepted file type(s):`)}
                </span>
                {filterExtensions}
              </span>
            </div>
          </div>
        </FileUpload>
      )}
      {!shouldImportFile && (
        <div
          className={classNames(
            'flex-1',
            'flex w-full items-center justify-center',
            'bg-menu',
          )}
        >
          <Button
            className={classNames(
              'flex-1',
              'border-menu/50 border',
              'text-base',
              'min-w-64',
            )}
            label={t`Create`}
            buttonType="primary"
            hasIconAfter
            buttonIcon={
              <Icon.CloudUpload
                className={classNames('h-5 w-5 fill-current')}
              />
            }
            isDisabled={false}
            isLoading={false}
            onPress={() => createPreview({ file: null })}
          />
        </div>
      )}
    </Container>
  );
};

export default ImporterFileSelector;
