import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { sidebarStateByType } from '../../store/sidebar.state';
import { orderSetMenuItems } from '../store/orderSet.default';
import {
  orderSetDocumentId,
  orderSetSelectedMenuItemId,
} from '../store/orderSet.state';
import { OrderSetMenuItemId } from '../store/orderSet.types';

type OrderSetMenuSidebarProps = {
  isMinimised: boolean;
};

const OrderSetMenuSidebar: React.FC<OrderSetMenuSidebarProps> = props => {
  const { t } = useTranslation('app');
  const orderSetId = useRecoilValue(orderSetDocumentId);
  const [activeMenuId, setActiveMenuId] = useRecoilState(
    orderSetSelectedMenuItemId(orderSetId),
  );

  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-order-set-main'),
  );

  useEffect(() => {
    if (!sidebarState.isPinned) {
      setSidebarState({ ...sidebarState, isCollapsed: false });
    }
  }, []);

  function changeActiveMenu(menuId: OrderSetMenuItemId) {
    setActiveMenuId(menuId);
    if (!sidebarState.isPinned) {
      setSidebarState({ ...sidebarState, isCollapsed: false });
    }
  }

  return (
    <ContainerScroll className="flex flex-col">
      <ul
        data-component="OrderSetMenuSidebar"
        className={`relative flex flex-col text-start px-1`}
      >
        {orderSetMenuItems(t).map(item => {
          return (
            <SidebarMenuItem
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeMenuId}
              treeLine={item?.treeLine}
              treeLineStart={item?.treeLineStart}
              treeLineEnd={item?.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              inProgress={item?.progress}
              stepCounter={item?.stepCounter}
              disabled={item?.disabled}
              isHidden={item?.isHidden}
              //locked={status?.isLocked}
              onClick={() => changeActiveMenu(item.id)}
            />
          );
        })}
      </ul>
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('app');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-order-set-menu',
    title: t('Menu'),
    size: 'xs',
  };

  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      widthAutoMinimised
      withMenuIcons={<OrderSetMenuSidebar isMinimised={true} />}
    >
      <OrderSetMenuSidebar isMinimised={false} />
    </SidebarContainer>
  );
};
