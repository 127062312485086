import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../components/HelperMessage';
import { Alert } from '../../components/alerts/Alerts';
import * as Icon from '../../components/icons';
import { ImportDatasetButton } from '../../components/importer/DatasetActionHelpers';
import { Spacer } from '../../components/layout/Spacer';
import Callout from '../../helpContext/Callout';
import { importTriggeredBySim } from '../../store/global.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { simulationCurrent } from '../store/simulation.state';

export type HelperWidgetProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  dataType?: 'layout' | 'assignment' | 'orders' | 'items';
  iconAction?: React.FC<HTMLAttributes<Element>>;
  hasDataIssues?: boolean;
};

export const HelperWidgetImportSelector = ({
  children,
  className,
  title,
  iconAction,
}: HelperWidgetProps) => {
  const { t } = useTranslation('simulation');
  return (
    <Callout
      panelMode
      type="suggestion"
      iconAfter
      icon={iconAction || Icon.ArrowBottom}
    >
      {t`Click to`}
      <HelperMessageAction>{t`Select`}</HelperMessageAction>
      {t`or`}
      <HelperMessageAction>{t`Import`}</HelperMessageAction>
      {t(`{{title}} data, from the menu.`, {
        title,
      })}
      {children}
    </Callout>
  );
};

export const HelperWidgetNextStep = ({
  children,
  className,
  title,
  iconAction,
  hasDataIssues,
}: HelperWidgetProps) => {
  const { t } = useTranslation('simulation');

  return (
    <>
      <Spacer flexspace />
      {hasDataIssues && (
        <Callout panelMode type="suggestion" iconAfter icon={Icon.ArrowRight}>
          {t`Check`}
          <HelperMessageAction>{t`Stats & Issues`}</HelperMessageAction>
          {t`panel to review data inconsistencies and potential issues found by WareBee.`}
        </Callout>
      )}
      <Callout
        panelMode
        // type="suggestion"
        iconAfter
        icon={iconAction || Icon.ArrowBottom}
      >
        {t`Click the`}
        <HelperMessageAction>{t`Next`}</HelperMessageAction>
        {t(`button below to proceed to the {{title}} step.`, {
          title,
        })}
        {children}
      </Callout>
    </>
  );
};

export const HelperWidgetImportLocked = ({
  children,
  className,
  title,
  iconAction,
}: HelperWidgetProps) => {
  const { t } = useTranslation('simulation');
  return (
    <Alert
      hasStatusHelper
      icon={Icon.Lock}
      message={t(
        `Once the Simulation is completed, the selected {{title}} can no longer be changed!`,
        {
          title,
        },
      )}
      className={classNames('m-4 mt-2', 'z-container', 'flex flex-col')}
    >
      {children}
    </Alert>
  );
};

export const HelperWidgetImportInitialSetup = ({
  children,
  className,
  title,
  dataType,
}: HelperWidgetProps) => {
  const { t } = useTranslation('importer');
  const whId = useRecoilValue(warehouseSelectedId);
  const simulation = useRecoilValue(simulationCurrent);
  const navigate = useNavigate();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);

  const proceedWithImport = () => {
    setImportTriggerSimId(simulation.id);
    navigate(`/wh/i/${whId}/import/${dataType}`);
  };

  return (
    <HelperMessage hasBefore hasPadding>
      <Callout type="suggestion" iconAfter icon={Icon.ArrowBottom}>
        <p>
          {t(`In order to setup the Simulation, we require {{title}}.`, {
            title,
          })}
        </p>
        <p className="mt-3">{t`Please click the button below to import.`}</p>
        <p className="mt-3">{t`We will guide you with CSV requirements on the next step`}</p>
      </Callout>

      <div className="my-8 mb-4">
        <ImportDatasetButton isSimulation dataType={dataType} />
      </div>
    </HelperMessage>
  );
};
