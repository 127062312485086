import {
  SkipLimitPageSpec,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { SidebarState, SidebarType } from '../store/sidebar.state';
import { FormattedValueWithUnit } from './formatHelper';

export type ContentViewAs = 'table' | 'layout' | 'dashboard';

export type EventViewAs = 'picking' | 'replenishment' | 'reassign' | 'summary';
export type WorkloadViewAs = 'workforce' | 'workloadbalance';
export type ItemsViewAs = 'stock' | 'allocate';

export type FilterFieldDataStateKey = 'main' | 'extra';
export enum AsyncLoadStatus {
  None,
  Loading,
  Ok,
  Error,
}

export enum FilterFieldDataType {
  String,
  NumberRange,
  IntegerRange,
}
export enum FilterFieldEditorType {
  SingleSelect,
  SingleRadio,
  MultipleCheck,
  SliderRange,
  ABCRange,
  SliderRangeFixed,
  StringRange,
}
export type FilterFieldConfigBase<T extends string> = {
  type: T;
  title: string;
  dataType?: FilterFieldDataType;
  editorType: FilterFieldEditorType;
  rangeMinValue?: number;
  rangeMaxValue?: number;
  isStatic?: boolean;
  format?: (v: number) => FormattedValueWithUnit;
  nullOptionTitle?: string;
};

export type WizardStepStatus = {
  isVisited?: boolean;
  inProgress?: boolean | number;
  isDisabled?: boolean;
  isLocked?: boolean;
};

export type MenuVisibilityConfig = {
  include: string[]; // Modes where the step should be shown
  exclude: string[]; // Modes where the step should be hidden
};

export type WizardStepConfig<T extends string> = {
  id: T;
  title: string;
  titleMinimized?: string;
  menuLevel: number;
  stepCounter?: string;
  menuGroup?: string;
  hasMenuItems?: boolean;
  sidebar?: Partial<Record<SidebarType, Partial<SidebarState>>>;
  treeLine?: boolean;
  treeLineStart?: boolean;
  treeLineEnd?: boolean;
  icon?: React.FC;
  progress?: boolean;
  disabled?: boolean;
  hasWizardArrows?: boolean;
  className?: string;
  isHidden?: boolean;
  menuVisibility?: MenuVisibilityConfig;
};

export type DatasetTableState<T extends string> = {
  searchValues: Partial<Record<T, string>>;
  sortValues: Partial<Record<T, SortDirection>>;
};

export type FilterIntersectionIdentity = {
  ruleId: string;
  productFilterId?: string;
  locationFilterId?: string;
};

export type DataTableState<T extends string> = {
  searchValues: Partial<Record<T, string>>;
  sortValues: Partial<Record<T, SortDirection>>;
};

export type DatasetDataViewProps<T> = {
  id: string;
  title?: string;
  hideScreenTitle?: boolean;
  customRenders?: Partial<
    Record<keyof T, (value: T[keyof T], row: T) => React.ReactNode>
  >;
};

export type DataPageParams = SkipLimitPageSpec & {
  isAppend?: boolean;
};
