import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export type TagRequiredProps = PropsWithChildren & {
  className?: string;
  optional?: boolean;
};

export const TagRequired: React.FC<TagRequiredProps> = props => {
  const { t } = useTranslation('importer');

  return (
    <span data-component="TagRequired">
      <span
        className={classNames(
          'px-2 py-1 ltr:mr-3 rtl:ml-3 align-middle',
          'bg-opacity-50 border rounded text-minimal',
          props.optional
            ? 'border-menu-100'
            : 'border-menu-active text-menu-active',
          props.className,
        )}
      >
        {props.optional ? t`Optional` : t`Required`}
      </span>

      {props.children}
    </span>
  );
};
