import { Popover, Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import * as Icon from '../icons';
import { Button } from './Button';

export type CopyToClipboardButtonProps = {
  className?: string;
  classNameContainer?: string;
  value?: string;
  title?: string;
  truncate?: boolean;
  hasIcon?: boolean;
  hasIconSmall?: boolean;
  popoverPosition?: 'left' | 'right';
  popoverDelay?: number;
  showOnHover?: boolean;
  hoverDelay?: number;
};

const DEFAULT_DELAY = 500;

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = props => {
  const { t } = useTranslation('app');
  const [showPopover, setShowPopover] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showPopover) {
      timer = setTimeout(() => {
        setShowPopover(false);
      }, DEFAULT_DELAY);
    }
    return () => clearTimeout(timer);
  }, [showPopover]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isHovering) {
      timer = setTimeout(() => {
        setShowPopover(true);
      }, props.hoverDelay ?? DEFAULT_DELAY);
    } else {
      setShowPopover(false);
    }
    return () => clearTimeout(timer);
  }, [isHovering, props.hoverDelay]);

  function onClick() {
    navigator.clipboard.writeText(props.value);
    const delay = props.popoverDelay ?? DEFAULT_DELAY;
    setTimeout(() => {
      setShowPopover(true);
    }, delay);
  }

  const hoverStyle = props.showOnHover
    ? `opacity-0 group-hover:opacity-100 transition-opacity delay-[2000] duration-500 ease-in-out`
    : '';

  return (
    <Popover
      data-component="CopyToClipboardButton"
      className={cn(
        'group',
        props.popoverDelay || props.popoverPosition ? 'relative' : '',
        props.classNameContainer,
      )}
      aria-details={`${props.title ? props.title + ':\n' : ''}${props.value}`}
    >
      {(props.hasIconSmall || props.hasIcon || props.title) && (
        <Button
          buttonType="secondary"
          buttonSize={props.hasIconSmall ? 'xs' : 'sm'}
          hasIconBefore={
            <Icon.ActionCopyPaste
              className={cn(
                'fill-current',
                props.hasIcon ? 'h-6 w-6' : 'h-4 w-4',
              )}
            />
          }
          hasIcon
          onPress={onClick}
          label={props.title ?? ''}
          className={cn(
            'z-alert',
            'bg-opacity-70',
            'backdrop backdrop-blur backdrop-saturate-150',
            'shadow-2xl',
            'rounded',
            'text-xs',
            {
              'max-w-ch-20 truncate': props.truncate,
              '!p-2': props.hasIcon,
              '!p-1': props.hasIconSmall,
            },
            hoverStyle,
            props.className,
          )}
          titleAlt={props.title}
        />
      )}

      <Transition
        show={showPopover}
        enter="transition-all ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition-all ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className={'relative'}
      >
        <Popover.Panel
          data-component="CopyToClipboardButtonPopover"
          className={cn(
            'flex items-center',
            'absolute',
            props.popoverPosition === 'right' ? '-right-10' : '-left-10',
            '-top-6',
            'z-alert',
            'bg-menu-active/80 text-menu-active-text',
            'rounded',
            'p-1',
            'backdrop backdrop-blur backdrop-saturate-150',
            'whitespace-nowrap',
          )}
        >
          <Icon.CircleOk
            className={cn('h-4 w-4', 'fill-current', 'ltr:mr-2 rtl:ml-2')}
          />
          <span className={cn('flex-1 text-xs')}>{t`Copied`}</span>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default CopyToClipboardButton;
