import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../components/HelperMessage';
import { StepCounterCircle } from '../../components/helpers/StepCounterCircle';
import * as Icon from '../../components/icons';
import { Spacer } from '../../components/layout/Spacer';

export type HelperImporterProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  hasStepCounter?: string;
  dataType?: 'layout' | 'assignment' | 'orders' | 'items';
  iconAction?: React.FC<HTMLAttributes<Element>>;
};

export const HelperImporter = ({
  children,
  hasStepCounter,
}: HelperImporterProps) => {
  const { t } = useTranslation('importer');
  return (
    <HelperMessage
      hasBefore
      iconAction={Icon.ArrowRightBottom}
      className={classNames('mb-1 ltr:pr-2 rtl:pl-2')}
    >
      {hasStepCounter && (
        <StepCounterCircle
          isPrimary
          stepCountSize={'sm'}
          stepCountId={hasStepCounter}
          className={classNames('ltr:ml-2 rtl:mr-2')}
        />
      )}

      {t`Click the`}
      <HelperMessageAction>{t`Next`}</HelperMessageAction>
      {t`to import the data file`}
      {children}
    </HelperMessage>
  );
};

export const HelperImporterStart = ({
  children,
  title,
}: HelperImporterProps) => {
  const { t } = useTranslation('importer');
  return (
    <>
      <HelperMessage hasPadding className="mt-4">
        {t`To get started with analysing your warehouse, please import your data, using the template (on the right)`}
      </HelperMessage>
      <HelperMessage hasPadding iconAction={Icon.ArrowBottom}>
        {t`And click`}
        <span
          className={classNames('text-menu-action text-bold mx-1')}
        >{t`Next`}</span>
        {t(`below to get upload your warehouse {{title}} data.`, {
          title,
        })}
        {children}
      </HelperMessage>
    </>
  );
};

export const HelperImporterSelect = ({
  children,
  title,
  hasStepCounter,
}: HelperImporterProps) => {
  const { t } = useTranslation('importer');
  return (
    <>
      <HelperMessage hasPadding className="mt-4">
        {t`Start importing your data by clicking or dragging & dropping your file into the upload panel on the right side.`}
      </HelperMessage>
      <Spacer flexspace />

      <HelperMessage className={classNames('mb-1 ltr:pr-2 rtl:pl-2')}>
        {hasStepCounter && (
          <StepCounterCircle
            isPrimary
            stepCountSize={'sm'}
            stepCountId={hasStepCounter}
            className={classNames('ltr:ml-2 rtl:mr-2')}
          />
        )}

        <HelperMessage
          hasBefore
          iconAction={Icon.ArrowRightBottom}
          // textSize={'sm'}
        >
          {t`Use`}
          <HelperMessageAction>{t`Transform`}</HelperMessageAction>
          {t`button, to start data transformation (optional) and mapping.`}
        </HelperMessage>
        {children}
      </HelperMessage>
    </>
  );
};

export const HelperImporterDataset = ({
  children,
  title,
}: HelperImporterProps) => {
  const { t } = useTranslation('importer');
  const styleTitle = 'mt-8 mb-2 text-xl text-menu-h3';

  return (
    <>
      <h3 className={classNames(styleTitle)}>
        {t(`What data does WareBee use?`, {
          title,
        })}
      </h3>
      <div className="mb-1">
        {t`We will only ask you for a minimal set of data to build the warehouse model. Providing all the optional fields will allow WareBee to create a more precise digital twin model.`}
      </div>
      <h3 className={classNames(styleTitle)}>{t`Data Best Practices`}</h3>
      <div>
        {t(
          `To import your warehouse {{title}} data, you will need to use the following CSV template.`,
          {
            title,
          },
        )}
      </div>

      <h3 className={classNames(styleTitle)}>{t`Need Assistance?`}</h3>
      <div>
        {t`Are you having trouble exporting your data from WMS/ERP System? or you have some issue with importing your data?`}
      </div>
      {children}
    </>
  );
};
