import {
  AgentRoleSettings,
  UomHandlingSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  AgentEditorSettings,
  AgentFilterSectionConfig,
} from './AgentEditorSettings';

export function getPickingFilterConfiguration(
  config: AgentEditorSettings,
  pickingRole: AgentRoleSettings,
  rolePrefix: string,
  uoms: string[],
  t: TFunction<'simulation'>,
): AgentFilterSectionConfig[] {
  function getTimePerUom(
    group: UomHandlingSettingsFragment[],
    groupPrefix: string,
    getTitle: (uom: string) => string,
  ) {
    const getUomIndex = (uom: string) => _.findIndex(group, g => g.uom === uom);

    return _(uoms)
      .filter(u => _.some(group, g => g.uom === u))
      .map(uom => ({
        path: [rolePrefix, groupPrefix, `[${getUomIndex(uom)}]`, 'timePerUom'],
        title: getTitle(uom),
        filterConfig: config.timePerUom,
      }))
      .value();
  }

  return [
    {
      title: t`Timing — Picking`,
      counter: `B1`,
      filterGroups: [
        {
          title: t(`Order Pre-handling Time`, { ns: 'simulation' }),
          description: t(
            `Time spent before picking starts. It can include task assignments, handling unit preparation, labels, or picklist printing. Configure it per order, order line, quantity (UOM), or just fixed per picklist.`,
            { ns: 'simulation' },
          ),
          category: t(`HANDLING`, { ns: 'simulation' }),
          filters: [
            {
              path: [rolePrefix, 'preHandling', 'timeFixed'],
              title: t(`Fixed time`, { ns: 'simulation' }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'preHandling', 'timePerOrder'],
              title: t(`Time per Order`, { ns: 'simulation' }),
              filterConfig: config.timePerOrder,
            },
            {
              path: [rolePrefix, 'preHandling', 'timePerPicklistLine'],
              title: t(`Time per Picklist line`, { ns: 'simulation' }),
              filterConfig: config.timePerLine,
            },
            {
              path: [rolePrefix, 'preHandling', 'timePerUom'],
              title: t(`Time per Unit picked`, { ns: 'simulation' }),
              filterConfig: config.timePerUom,
            },
          ],
        },
        {
          title: t(`Picking Time (At Location)`, { ns: 'simulation' }),
          description: t(
            `Actual picking time. Picking time can be measured by picklist line, UOM quantity, weight, volume, or just as a constant.`,
            { ns: 'simulation' },
          ),
          category: t(`SPECIFICATION`, { ns: 'simulation' }),
          filters: [
            {
              path: [rolePrefix, 'handling', 'timeFixed'],
              title: t(`Picking Time per Picklist line`, {
                ns: 'simulation',
              }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'handling', 'timePerWeight'],
              title: t(`Picking Time - Weight`, { ns: 'simulation' }),
              filterConfig: config.timePerWeight,
            },
            {
              path: [rolePrefix, 'handling', 'timePerVolume'],
              title: t(`Picking Time - Volume`, { ns: 'simulation' }),
              filterConfig: config.timePerVolume,
            },
            ...getTimePerUom(
              pickingRole.handling.timePerUom,
              'handling.timePerUom',
              uom =>
                t(`Picking Time - UOM Type: {{uom}}`, {
                  ns: 'simulation',
                  uom,
                }),
            ),
          ],
        },
        {
          title: t(`Pallet Re-Ordering Time (Item Stacking Order)`, {
            ns: 'simulation',
          }),
          category: t(`HANDLING`, { ns: 'simulation' }),
          description: t(
            `Time spent re-ordering picked items on the pallet, placing heavy items at the bottom, and light on the top. Change heavy vs. light item grouping in the Data-Item Set.`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: [rolePrefix, 'reordering', 'timeFixed'],
              title: t(`Re-Ordering (Fixed) time`, { ns: 'simulation' }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'reordering', 'timePerWeight'],
              title: t(`Re-Ordering Time - Weight`, { ns: 'simulation' }),
              filterConfig: config.timePerWeight,
            },
            {
              path: [rolePrefix, 'reordering', 'timePerVolume'],
              title: t(`Re-Ordering Time - Volume`, { ns: 'simulation' }),
              filterConfig: config.timePerVolume,
            },

            ...getTimePerUom(
              pickingRole.reordering.timePerUom,
              'reordering.timePerUom',
              uom =>
                t(`Re-Ordering Time - UOM Type: {{uom}}`, {
                  ns: 'simulation',
                  uom,
                }),
            ),
          ],
        },
        {
          title: t(`Picking Post-Handling`, { ns: 'simulation' }),
          category: t(`HANDLING`, { ns: 'simulation' }),
          description: t(
            `Additional Time spent to organise picked quantity. Can be measured per quantity picked or can be constant.`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: [rolePrefix, 'handlingCompletion', 'timeFixed'],
              title: t(`Handling Completion - Time per line`, {
                ns: 'simulation',
              }),
              filterConfig: config.timeAbsolute,
            },

            {
              path: [rolePrefix, 'handlingCompletion', 'timePerUom'],
              title: t(`Handling Completion - Time per UOM qty`, {
                ns: 'simulation',
              }),
              filterConfig: config.timePerUom,
            },
          ],
        },
        {
          title: t(`Order Post-Handling`, { ns: 'simulation' }),
          category: t(`HANDLING`, { ns: 'simulation' }),
          description: t(
            `Time spent on order quality controls, packing, manifesting, loading and other warehouse tasks after picking has been completed. Can be measured per order, order line, UOM qty or can be constant.`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: [rolePrefix, 'postHandling', 'timeFixed'],
              title: t(`Post-Handling Fixed Time`, { ns: 'simulation' }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'postHandling', 'timePerOrder'],
              title: t(`Post-Handling - Time per order`, { ns: 'simulation' }),
              filterConfig: config.timePerOrder,
            },
            {
              path: [rolePrefix, 'postHandling', 'timePerPicklistLine'],
              title: t(`Post-Handling - Time per picklist line`, {
                ns: 'simulation',
              }),
              filterConfig: config.timePerLine,
            },
            {
              path: [rolePrefix, 'postHandling', 'timePerUom'],
              title: t(`Post-Handling Time per UOM`, { ns: 'simulation' }),
              filterConfig: config.timePerUom,
            },
          ],
        },
      ],
    },
  ];
}
