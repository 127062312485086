import * as React from 'react';
const CalendarIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CalendarIcon"
    {...props}
  >
    <path strokeWidth={0} d="M26.64,4h7.51v8.84h-7.51V4Z" />
    <path strokeWidth={0} d="M60.02,4h7.51v8.84h-7.51V4Z" />
    <path strokeWidth={0} d="M93.41,4h7.51v8.84h-7.51V4Z" />
    <path
      strokeWidth={0}
      d="M14.65,124h99.71c5.52,0,10-4.48,10-10V43.78H4.65v70.22c0,5.52,4.48,10,10,10ZM20.96,52.04h17.81v9.6h-17.81v-9.6ZM20.96,75.47h17.81v9.6h-17.81v-9.6ZM20.96,98.63h17.81v9.6h-17.81v-9.6ZM54.87,52.04h17.81v9.6h-17.81v-9.6ZM54.87,75.47h17.81v9.6h-17.81v-9.6ZM54.87,98.63h17.81v9.6h-17.81v-9.6ZM88.79,52.04h17.81v9.6h-17.81v-9.6ZM88.79,75.47h17.81v9.6h-17.81v-9.6ZM88.79,98.63h17.81v9.6h-17.81v-9.6Z"
    />
    <path
      strokeWidth={0}
      d="M26.64,26.54v-13.7H4.65v23.43h119.49V12.84h-23.22v13.7h-7.51v-13.7h-25.87v13.7h-7.51v-13.7h-25.87v13.7h-7.51Z"
    />
  </svg>
);
export default CalendarIcon;
