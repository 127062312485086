import * as React from 'react';
const Mhe2APalletJackManualIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe2APalletJackManualIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M20.59,105.64v-6.42h-4.72v6.42c-1.69.73-2.93,1.9-3.69,3.51-.77,1.61-.81,3.31-.12,5.08s1.84,3.05,3.45,3.81c1.61.77,3.31.81,5.08.12,1.78-.69,3.03-1.84,3.75-3.45s.75-3.35.06-5.21c-.69-1.86-1.96-3.15-3.81-3.87h0ZM16.6,94.98v3.27h3.51v-3.27h-3.51ZM20.83,91.11c-.08-.73-.36-1.33-.85-1.82s-1.09-.73-1.82-.73H7.28v3.03c0,.73.24,1.33.73,1.82s1.13.73,1.94.73h10.9v-3.03h0ZM7.28,88.2h3.03V21.74h-3.03v9.56l-2.91-8.84c-.65-1.94-.4-3.75.73-5.45l4-6.05c.4-.48.38-.95-.06-1.39-.44-.44-.91-.42-1.39.06l-4.24,6.42c-.73,1.05-1.17,2.2-1.33,3.45-.16,1.25-.04,2.48.36,3.69l4.84,14.41v50.61h0ZM125.19,111.93H43.47c-1.61-.08-2.78-.77-3.51-2.06l-13.32-24.94c-.73-1.21-1.78-1.86-3.15-1.94h-1.33c-1.13,0-2.1.52-2.91,1.57l-1.69,2.3h1.57c.73.08,1.37.32,1.94.73s.93.93,1.09,1.57l5.21,17.31c.16.4.24.77.24,1.09v7.51c0,.56.2,1.05.61,1.45.4.4.89.61,1.45.61h73.12c.32.73.81,1.21,1.45,1.45s1.29.2,1.94-.12,1.13-.77,1.45-1.33h1.45c.32.73.81,1.21,1.45,1.45s1.29.2,1.94-.12c.65-.32,1.13-.77,1.45-1.33h3.51c1.37,0,2.66-.36,3.87-1.09l4.36-2.66c.32-.32.4-.65.24-.97-.16-.32-.4-.48-.73-.48h0Z"
    />
  </svg>
);
export default Mhe2APalletJackManualIcon;
