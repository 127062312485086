import React from 'react';
import { ContainerMap } from '../../components/layout/ContainerMap';
import StageContainer from '../stage/StageContainer';
import LocationHtmlTooltipLayer from './layers/LocationHtmlTooltipLayer';
import ViewerBayLocationLayer from './layers/ViewerBayLocationLayer';

export type LayoutContainerProps = {};

const BayViewerLayout: React.FC<LayoutContainerProps> = () => {
  return (
    <ContainerMap className="min-h-bayMap">
      <StageContainer type="viewer-bay-view" darkBg={true}>
        <ViewerBayLocationLayer />
      </StageContainer>
      <LocationHtmlTooltipLayer />
    </ContainerMap>
  );
};

export default BayViewerLayout;
