import {
  Field,
  ID,
  InputAndObjectType,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { OrderSetFilter } from '../dataset-filters.model';
import { ItemUomQuantity } from '../item-quantity.model';
import { SimulationItemFilterUnion } from '../policies';
import {
  AllocationRequirementAggregationSettings,
  AllocationRequirementSourceSettings,
} from './allocation-settings.model';
import type { LocationMask } from './location-mask.model';

@ObjectType()
export class AllocationRequirementUomQuantity extends ItemUomQuantity {
  @Field()
  totalVolume: number;

  @Field({ nullable: true })
  aggregatedTotalVolume?: number;

  /**
   * @TJS-type integer
   */
  @Field({ nullable: true })
  aggregatedQuantity?: number;

  /**
   * @TJS-type integer
   */
  @Field({ nullable: true })
  aggregatedUomQuantity?: number;
}

@ObjectType()
export class AllocationRequirementRuleDetails {
  @Field()
  pickingRuleId: string;

  @Field()
  mask: string;

  @Field()
  requiredQuantity: number;

  @Field()
  preferredRequiredVolume: number;

  @Field({ nullable: true })
  aggregatedRequiredQuantity?: number;

  @Field({ nullable: true })
  aggregatedPreferredRequiredVolume?: number;

  @Field(() => [ID])
  allowedUoms: string[];

  @Field(() => [AllocationRequirementUomQuantity])
  preferredUoms: AllocationRequirementUomQuantity[];
}

@ObjectType()
export class AllocationRequirementDetails {
  @Field(() => [AllocationRequirementUomQuantity], { nullable: true })
  preferredUoms?: AllocationRequirementUomQuantity[];

  @Field(() => [AllocationRequirementRuleDetails], { nullable: true })
  rules?: AllocationRequirementRuleDetails[];
}

export enum AllocationRequirementRuleType {
  PICKING_RULE = 'PICKING_RULE',
  ASSIGNMENT_RULE = 'ASSIGNMENT_RULE',
}

registerEnumType(AllocationRequirementRuleType, {
  name: 'AllocationRequirementRuleType',
});

@ObjectType()
export class AllocationRequirementSetRuleDetails {
  @Field(() => AllocationRequirementRuleType)
  type: AllocationRequirementRuleType;

  @Field(() => ID)
  id: string;

  @Field(() => ID)
  mask: LocationMask;
}

@ObjectType()
export class AllocationRequirementSetSummary {
  @Field()
  maskLength: number;

  @Field(() => [AllocationRequirementSetRuleDetails])
  rules: AllocationRequirementSetRuleDetails[];
}

@InputAndObjectType()
export class AllocationRequirementSetRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => [String], { nullable: true })
  uomTypes?: string[];

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  itemsMatch?: SimulationItemFilterUnion;

  @Field(() => AllocationRequirementAggregationSettings, { nullable: true })
  requirementAggregationSettings?: AllocationRequirementAggregationSettings;
}

@InputAndObjectType()
export class AllocationRequirementSetSettings {
  @Field(() => OrderSetFilter, { nullable: true })
  orderSetFilter?: OrderSetFilter;

  @Field(() => AllocationRequirementSourceSettings, { nullable: true })
  requirementSource?: AllocationRequirementSourceSettings;

  @Field(() => [AllocationRequirementSetRule], { nullable: true })
  rules?: AllocationRequirementSetRule[];

  @Field(() => AllocationRequirementSetRule, { nullable: true })
  defaultRule?: AllocationRequirementSetRule;
}
