import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { analyzeJobDetailsSelectedRow } from '../../store/analyze.state';
import { simulationHeatmapLocationByBay } from '../../store/simulation.heatmap.state';
import SimulationBayLocationLayerBase from './SimulationBayLocationLayerBase';

export type ReassignBayLocationLayerProps = {
  analyzeId: string;
};

const ReassignBayLocationLayer: React.FC<
  ReassignBayLocationLayerProps
> = props => {
  const heatmapByLocLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByBay(props.analyzeId),
  );
  const selectedJob = useRecoilValue(analyzeJobDetailsSelectedRow);

  const heatmapByLoc =
    heatmapByLocLoadable.state === 'hasValue'
      ? heatmapByLocLoadable.contents
      : null;

  if (_.isNil(heatmapByLoc)) return null;

  function filter(location: LayoutLevelLocationFragment): boolean {
    if (selectedJob) {
      return (
        selectedJob.srcLocationId === location.locationId ||
        selectedJob.destLocationId === location.locationId
      );
    }

    const products = heatmapByLoc[location.locationId];
    return _.some(products, p => !_.isNil(p.reassignTasks));
  }
  return (
    <SimulationBayLocationLayerBase
      heatmapByLoc={heatmapByLoc}
      filter={filter}
    />
  );
};

export default ReassignBayLocationLayer;
