import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { getQualitativeColor } from '../common/color.helper';
import { persistAtom } from '../common/recoil/persistAtom';
import { normalizeColorKey } from './namedColors.helper';
import { NamedColors, WarehouseNamedColors } from './namedColors.types';
import { warehouseSelectedId } from './warehouse.state';

const getKey = (postfix: string) => `warebee-named-colors-${postfix}`;

export const namedColorsByWarehouse = atom<
  Record<string, WarehouseNamedColors>
>({
  key: getKey('by-warehouse'),
  default: {},
});

export const namedColorsGroups = selector<WarehouseNamedColors>({
  key: getKey('groups-all'),
  get: ({ get }) => {
    const whId = get(warehouseSelectedId);
    return get(namedColorsByWarehouse)[whId];
  },
  set: ({ get, set }, value: WarehouseNamedColors) => {
    const whId = get(warehouseSelectedId);
    set(namedColorsByWarehouse, current => ({ ...current, [whId]: value }));
  },
});

export const namedColorsGroupSelectedId = persistAtom<string>({
  key: getKey('selected-group-id'),
  default: null,
});

export const namedColorsGroupSelected = selector<NamedColors>({
  key: getKey('selected-group'),
  get: ({ get }) => {
    const groupKey = get(namedColorsGroupSelectedId);
    if (_.isNil(groupKey)) return null;
    const allGroups = get(namedColorsGroups);
    return allGroups?.[groupKey];
  },
  set: ({ get, set }, value: NamedColors) => {
    const groupKey = get(namedColorsGroupSelectedId);
    if (_.isNil(groupKey)) return null;
    const allGroups = get(namedColorsGroups);
    set(namedColorsGroups, { ...allGroups, [groupKey]: value });
  },
});

export const namedColorsByKey = selectorFamily<[string, string], string>({
  key: getKey('get-color-by-key'),
  get:
    (value: string) =>
    ({ get }) => {
      if (_.isNil(value)) return [null, null];
      const key = normalizeColorKey(value);
      const group = get(namedColorsGroupSelected);
      if (_.isNil(group)) {
        return getQualitativeColor(key, 'policy');
      }
      return group.colors[value.toUpperCase()] ?? [null, null];
    },
});
