import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Container } from '../../components/layout/ContainerFlex';
import LoadingIndicator from '../../components/LoadingIndicator';
import AislePropertiesPanel from '../../components/sharedPanels/AislePropertiesPanel';
import BayPropertiesPanel from '../../components/sharedPanels/BayPropertiesPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import BayViewerLayout from '../bayViewer/BayViewerLayout';
import {
  viewerSelectedAisle,
  viewerSelectedBayDetails,
} from '../viewer/store/viewer.state';

const LayoutCommonInfoSidebar: React.FC = () => {
  const { t } = useTranslation('designer');

  const bay = useRecoilValue(viewerSelectedBayDetails);
  const aisle = useRecoilValue(viewerSelectedAisle);

  const getContent = () => {
    if (bay)
      return (
        <Suspense
          fallback={
            <LoadingIndicator selfCenter message={t`Loading bay locations`} />
          }
        >
          <BayPropertiesPanel bay={bay} bayViewer={() => <BayViewerLayout />} />
        </Suspense>
      );
    if (aisle) {
      return <AislePropertiesPanel />;
    }
    return (
      <InboxZero
        selfCenter
        message={t`Click on Location, Bay, Aisle on the map to get more info`}
      />
    );
  };

  return (
    <Container col hasOverflowY>
      {getContent()}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('designer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-layout-common-info',
    title: t`Info`,
    size: 'sm',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <LayoutCommonInfoSidebar />
    </SidebarContainer>
  );
};
