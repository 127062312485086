import {
  AnalyzeActualityPipelineSettings,
  ProcessingPipelineFragment,
  ProcessingPipelineType,
  useCreateProcessingPipelineMutation,
  useUpdateProcessingPipelineMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatDateTime } from '../../common/formatHelper';
import SectionOptional from '../../components/layout/SectionOptional';
import Loader from '../../components/loaders/Loader';
import PanelContainer from '../../containers/PanelContainer';
import TimeAgo from '../../dashboard/TimeAgo';
import Tag from '../../helpContext/Tag';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  getActualityAnalyzeJobStatus,
  getActualityAnalyzeJobStatusDescriptors,
} from '../store/actuality.helper';
import { actualitySelectedDocument } from '../store/actuality.state';

const ActualityPipelineSettings: React.FC = () => {
  const { t } = useTranslation('feed');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const [actuality, setActuality] = useRecoilState(actualitySelectedDocument);
  const [createProcessingPipeline] = useCreateProcessingPipelineMutation();
  const [updateProcessingPipeline] = useUpdateProcessingPipelineMutation();

  const [creatingError, setCreatingError] = useState<string>();
  const [isUpdating, setIsUpdating] = useState(false);

  async function createPipeline() {
    try {
      setIsUpdating(true);

      const pipelineSettings: AnalyzeActualityPipelineSettings = {
        actualityId: actuality.id,
      };
      const { data } = await createProcessingPipeline({
        variables: {
          input: {
            warehouseId,
            pipelineType: ProcessingPipelineType.ANALYZE_ACTUALITY,
            title: `${actuality.title}-pipeline`,
            settings: pipelineSettings,
          },
        },
      });
      return data.createProcessingPipeline;
    } catch (ex) {
      setCreatingError(ex.message);
    } finally {
      setIsUpdating(false);
    }
  }

  async function updatePipeline(enabled: boolean) {
    try {
      setIsUpdating(true);

      const { data } = await updateProcessingPipeline({
        variables: {
          input: {
            pipelineId: actuality.analyzePipeline.id,
            enabled,
          },
        },
      });

      return data.updateProcessingPipeline;
    } catch (ex) {
      setCreatingError(ex.message);
    } finally {
      setIsUpdating(false);
    }
  }

  async function onChange(enabled: boolean) {
    let pipeline: ProcessingPipelineFragment;
    if (_.isNil(actuality.analyzePipeline) && enabled) {
      pipeline = await createPipeline();
    } else {
      pipeline = await updatePipeline(enabled);
    }

    setActuality(current => ({ ...current, analyzePipeline: pipeline }));
  }
  const hasPipeline = !_.isNil(actuality.analyzePipeline);

  const statusMap = getActualityAnalyzeJobStatusDescriptors(t);

  return (
    <PanelContainer
      id="actuality-integration-settings"
      title={t`Feed Settings`}
      collapsible
      hasPadding
    >
      <SectionOptional
        id={'actuality-integration-pipeline-settings'}
        title={t`Auto Analyse`}
        value={actuality.analyzePipeline?.enabled}
        isDisabled={isUpdating}
        onChange={onChange}
      >
        <div className="flex flex-col px-1 py-0.5">
          {actuality.analyzePipeline?.enabled && (
            <div className="flex text-xs">
              <div className="flex-1 text-start">{`${t('Updated')}`}</div>
              <div className="flex-1 text-end">{`${t('Status')}`}</div>
            </div>
          )}

          <div>
            {_.map(actuality.analyzePipeline?.jobs?.content, job => {
              const statusRaw = getActualityAnalyzeJobStatus(job);
              const status = statusMap[statusRaw];
              const lastModifiedDate = new Date(job.createdAt);
              const oneDayAgo = new Date();
              oneDayAgo.setDate(oneDayAgo.getDate() - 1);

              return (
                <div
                  key={job.id}
                  className="border-menu/50 flex items-center border-b px-1 py-0.5"
                >
                  <div className="flex-1">
                    {lastModifiedDate > oneDayAgo ? (
                      <TimeAgo
                        timeDate={lastModifiedDate}
                        updatedDate={lastModifiedDate}
                        createdDate={lastModifiedDate}
                        alignStart
                      />
                    ) : (
                      <span className="opacity-75">
                        {formatDateTime(lastModifiedDate)}
                      </span>
                    )}
                  </div>

                  <div className="flex-1 text-end">
                    <Tag
                      classType={`border border-alerts-${status.state} text-alerts-${status.state} uppercase !text-minimal !px-1 !py-0.5`}
                    >
                      {status.state === 'AnalyzeActualityJobEngine' && (
                        <Loader
                          size={23}
                          stroke={5}
                          type={'loading-original'}
                          className="mx-1"
                        />
                      )}
                      {status.label}
                    </Tag>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </SectionOptional>
    </PanelContainer>
  );
};

export default ActualityPipelineSettings;
