import { PickingWavePickByDateMode } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import * as Icon from '../components/icons';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import {
  getIsWaveSettingSelected,
  getPickingRequirementsWaypointSettingsOptions,
  getPicklistLinesOrderOptionTitle,
  getWaveByDateDescriptors,
  getWaveDescriptors,
} from '../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../simulation/store/pickingPolicy/pickingPolicy.state';
import { PickingPolicySelectionType } from '../simulation/store/pickingPolicy/pickingPolicy.types';
import { sidebarStateByType } from '../store/sidebar.state';
import { getGroupByOptions } from './editors/PickingPolicyGroupEditor';

export type PickingPolicyRuleStrategyFilterProps = {
  ruleId: string;
  isDisabled?: boolean;
};

const PickingPolicyRuleStrategyFilter: React.FC<
  PickingPolicyRuleStrategyFilterProps
> = props => {
  const { t } = useTranslation('simulation');
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    pickingPolicySelectedIdentity,
  );
  const rule = useRecoilValue(pickingPolicyRule(props.ruleId));
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-picking-editor'),
  );

  const [showPickingOptions, setShowPickingOptions] = useState<boolean>(false);

  function selectWithToggle(type: PickingPolicySelectionType) {
    if (props.isDisabled) return;

    const isDeselect =
      selectedIdentity?.ruleId === rule.id &&
      selectedIdentity?.selectionTypeId === type;

    setSelectedIdentity(
      isDeselect
        ? null
        : {
            ruleId: rule.id,
            selectionTypeId: type,
          },
    );
    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? isDeselect
        : policyEditPanelState.isCollapsed,
      isHidden: isDeselect,
    });
  }

  const activeType = selectedIdentity?.selectionTypeId;

  const isActiveRule = selectedIdentity?.ruleId === rule.id;
  const isActiveGroupByFilter = isActiveRule && activeType === 'orderLineGroup';
  const isActivePickingWavePickBy =
    isActiveRule && activeType === 'orderLineWave';
  const isActivePickingWaveReleaseDate =
    isActiveRule && activeType === 'orderLineWaveDate';
  const isActivePickingSort = isActiveRule && activeType === 'orderLineSort';
  const isActivePickingSortLines =
    isActiveRule && activeType === 'orderLinePicklistLineSortBy';
  const isActivePickingContainer =
    isActiveRule && activeType === 'orderLineContainer';
  const isActiveWaypoints = isActiveRule && activeType === 'orderLineWaypoints';

  const isActiveStrategy =
    isActiveGroupByFilter ||
    isActivePickingWavePickBy ||
    isActivePickingWaveReleaseDate ||
    isActivePickingSort;

  const groupByValue = rule?.pickingRequirementsSettings?.groupBy;
  const groupByDescriptor = _.find(
    getGroupByOptions(t),
    g => g.id === groupByValue,
  ) ?? {
    title: t`Not Selected`,
    icon: Icon.TriangleX,
  };
  // ## wave group by
  const wave = rule?.pickingRequirementsSettings?.wave;
  const wavePickByDescriptors = getWaveDescriptors(t).filter(
    desc => wave && getIsWaveSettingSelected(desc.id, wave),
  );

  const waypointsDescriptor = getPickingRequirementsWaypointSettingsOptions(t);

  if (_.isEmpty(wavePickByDescriptors)) {
    wavePickByDescriptors.push({
      id: 'none' as any,
      title: t`Not Selected`,
      icon: Icon.CircleInfo,
    });
  }

  // ## wave by time
  const byTimeMode =
    wave?.pickByDateSettings?.mode || PickingWavePickByDateMode.DATE;
  const waveByDate = _.find(
    getWaveByDateDescriptors(t),
    d => d.id === byTimeMode,
  );
  // ## sort by
  const sortBy = rule?.picklistSettings?.usePickingPriority
    ? t`Picking Priority (Order data.)`
    : t`Location Picking Order`;

  const pickOrderByIcon = rule?.picklistSettings?.usePickingPriority
    ? Icon.OrderPickingPriority
    : Icon.LocationPickingOrder;

  const sortByIcon = rule?.picklistSettings?.picklistLinesOrder
    ? Icon.SortPrioritySequence
    : Icon.SortPriorityMulti;

  // const arrow = '\u1F86A'; //TODO: Check if we can use unicode icons

  const sortByLinesValue = _.isEmpty(rule?.picklistSettings?.picklistLinesOrder)
    ? t`Default`
    : _.map(
        rule?.picklistSettings?.picklistLinesOrder,
        v => `${getPicklistLinesOrderOptionTitle(v.type, t)} (${v.direction})`,
      ).join(`, `);

  const containerValue = rule?.usePickingContainer
    ? t`Pick by Container`
    : t`No Container (Default)`;
  const IconSizeStyle =
    'w-6 h-6 xl:w-8 xl:h-8 ltr:mr-2 rtl:mx-2 ltr:ml-1 rtl:mr-1';

  return (
    <>
      <div className={classNames('flex w-full items-center', 'py-2')}>
        <ButtonSwitchMulti
          buttonType={isActiveStrategy ? null : 'secondary'}
          autoSize
          className={classNames('mx-1 flex-1 xl:mx-2')}
          // className={classNames('ltr:ml-2 rtl:mr-2')}
          classNameContainer={classNames('border border-menu-600/50')}
          selectedIndex={showPickingOptions ? 1 : 0}
          onClick={index => setShowPickingOptions(index === 1)}
          options={[
            { label: t`Picking Strategy - Auto` },
            { label: t`Custom` },
          ]}
        />
      </div>
      {showPickingOptions && (
        <>
          <PolicyFilter
            label={t`Group by`}
            isDisabled={props.isDisabled}
            isActive={isActiveGroupByFilter}
            onClick={e => selectWithToggle('orderLineGroup')}
          >
            <PolicyFilterItem
              isDisabled={props.isDisabled}
              value={groupByDescriptor.title}
              isActive={isActiveGroupByFilter}
              icon={groupByDescriptor.icon}
              iconClassName={IconSizeStyle}
            />
          </PolicyFilter>

          <PolicyFilter
            label={t`Pick by`}
            isDisabled={props.isDisabled}
            isActive={
              isActivePickingWavePickBy || isActivePickingWaveReleaseDate
            }
          >
            <PolicyFilter
              label={t`Release By Time (deadline)`}
              isDisabled={props.isDisabled}
              isActive={isActivePickingWaveReleaseDate}
              onClick={e => selectWithToggle('orderLineWaveDate')}
            >
              <PolicyFilterItem
                isDisabled={props.isDisabled}
                value={waveByDate.title}
                isActive={isActivePickingWaveReleaseDate}
                icon={Icon.PickingWaveTime}
                iconClassName={IconSizeStyle}
              />
            </PolicyFilter>
            <PolicyFilter
              label={t`Wave (Order metadata)`}
              isDisabled={props.isDisabled}
              isActive={isActivePickingWavePickBy}
              onClick={e => selectWithToggle('orderLineWave')}
            >
              {_.map(wavePickByDescriptors, wavePickBy => {
                return (
                  <PolicyFilterItem
                    key={`wave-by-${wavePickBy.id}`}
                    isDisabled={props.isDisabled}
                    value={wavePickBy.title}
                    isActive={isActivePickingWavePickBy}
                    icon={wavePickBy.icon}
                    iconClassName={IconSizeStyle}
                  />
                );
              })}
            </PolicyFilter>
          </PolicyFilter>

          <PolicyFilter
            label={t`Pick Order by`}
            isDisabled={props.isDisabled}
            isActive={isActivePickingSort}
            onClick={e => selectWithToggle('orderLineSort')}
          >
            <PolicyFilterItem
              value={sortBy}
              isDisabled={props.isDisabled}
              isActive={isActivePickingSort}
              icon={pickOrderByIcon}
              iconClassName={IconSizeStyle}
            />
          </PolicyFilter>
          <PolicyFilter
            label={t`Sort Lines by`}
            isDisabled={props.isDisabled}
            isActive={isActivePickingSortLines}
            onClick={e => selectWithToggle('orderLinePicklistLineSortBy')}
          >
            <PolicyFilterItem
              value={sortByLinesValue}
              isDisabled={props.isDisabled}
              isActive={isActivePickingSortLines}
              icon={sortByIcon}
              iconClassName={IconSizeStyle}
            />
          </PolicyFilter>
          <PolicyFilter
            label={t`Pick by Container`}
            isDisabled={props.isDisabled}
            isActive={isActivePickingContainer}
            onClick={e => selectWithToggle('orderLineContainer')}
          >
            <PolicyFilterItem
              value={containerValue}
              isDisabled={props.isDisabled}
              isActive={isActivePickingContainer}
              icon={Icon.PickByContainer}
              iconClassName={IconSizeStyle}
            />
          </PolicyFilter>

          <PolicyFilter
            label={t`Gates waypoints`}
            isDisabled={props.isDisabled}
            isActive={isActiveWaypoints}
            onClick={e => selectWithToggle('orderLineWaypoints')}
          >
            <PolicyFilterItem
              name={t`Start`}
              value={
                _.find(
                  waypointsDescriptor,
                  v =>
                    v.id ==
                    rule?.pickingRequirementsSettings?.startLocationIdSource,
                ).title
              }
              isDisabled={props.isDisabled}
              isActive={isActiveWaypoints}
              icon={Icon.PickByContainer}
              iconClassName={IconSizeStyle}
            />
            <PolicyFilterItem
              name={t`End`}
              value={
                _.find(
                  waypointsDescriptor,
                  v =>
                    v.id ==
                    rule?.pickingRequirementsSettings?.endLocationIdSource,
                ).title
              }
              isDisabled={props.isDisabled}
              isActive={isActiveWaypoints}
              icon={Icon.PickByContainer}
              iconClassName={IconSizeStyle}
            />
          </PolicyFilter>
        </>
      )}
    </>
  );
};

export default PickingPolicyRuleStrategyFilter;
