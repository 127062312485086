import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { loggedInUser } from '../store/auth.state';
import Callout from './Callout';

export type HelpHeaderProps = PropsWithChildren & {
  className?: string;
};

const HelpHeader: React.FC<HelpHeaderProps> = props => {
  // User's Details
  const user = useRecoilValue(loggedInUser);
  const firstName = user.firstName;
  const supportEmail = 'support@warebee.com';

  return (
    <header className="mb-8 mt-2">
      <Callout panelMode type="suggestion">
        <div className="whitespace-pre-line">
          {t(
            `👋 Hello {{firstName}},
            I am a WareBee Helper,

            You will see me throughout the app, with helpers, tips and data insights,
            And you can email us at {{supportEmail}} get on free Chat support (with real humans).

            WareBee Team.🤓`,
            {
              firstName,
              supportEmail,
            },
          )}
        </div>
      </Callout>
    </header>
  );
};

export default HelpHeader;
