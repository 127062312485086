import * as React from 'react';
const Mhe6BForkliftSitDownFuelDieselIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe6BForkliftSitDownFuelDieselIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M14.04,112.52c.18,4.04,1.86,6.96,5.03,8.75s6.54,1.81,10.13.07c1.56-1.01,2.8-2.27,3.72-3.79.92-1.52,1.38-3.19,1.38-5.03-.28-4.04-1.97-6.96-5.1-8.75-3.12-1.79-6.47-1.77-10.06.07-1.56.92-2.8,2.14-3.72,3.65s-1.38,3.19-1.38,5.03Z"
    />
    <path
      strokeWidth={0}
      d="M70.52,111c.18,4.59,2.09,7.94,5.72,10.06,3.63,2.11,7.51,2.11,11.64,0,1.75-1.01,3.15-2.41,4.2-4.2s1.58-3.74,1.58-5.85c-.28-4.59-2.23-7.92-5.85-9.99-3.63-2.07-7.46-2.04-11.5.07-1.84,1.01-3.26,2.39-4.27,4.13s-1.52,3.67-1.52,5.79Z"
    />
    <path
      strokeWidth={0}
      d="M38.97,81.94c2.66,0,5.05.92,7.16,2.76l8.4,7.58c2.3,2.11,5.01,3.17,8.13,3.17l20.8.14c.64,0,1.01-.37,1.1-1.1,0-.18-.05-.32-.14-.41-1.75-4.32-4.34-9.21-7.78-14.67s-7.74-10.45-12.88-14.95c-2.02-1.65-4.22-2.85-6.61-3.58-2.39-.73-4.87-1.01-7.44-.83l-9.09.69c-1.1.09-2.09.46-2.96,1.1-.87.64-1.54,1.42-2,2.34-2.2,5.33-4,10.84-5.37,16.53-.18.64.05,1.06.69,1.24h7.99Z"
    />
    <path
      strokeWidth={0}
      d="M62.39,98.47c-3.49-.09-6.61-1.33-9.37-3.72l-8.4-7.71c-1.65-1.47-3.58-2.25-5.79-2.34h-22.32c-1.75.09-2.98.96-3.72,2.62-2.3,8.08-3.54,16.35-3.72,24.8,0,1.1.34,2.02,1.03,2.76s1.58,1.1,2.69,1.1h.14c-.55-1.56-.73-3.21-.55-4.96.46-3.31,1.95-5.92,4.48-7.85,2.53-1.93,5.44-2.71,8.75-2.34,3.03.46,5.49,1.75,7.37,3.86s2.87,4.68,2.96,7.71c0,1.19-.14,2.39-.41,3.58h34.03c-.83-2.2-1.13-4.43-.9-6.68.23-2.25.94-4.34,2.14-6.27.92-1.47,2.16-2.66,3.72-3.58.64-.37,1.33-.69,2.07-.96h-14.19Z"
    />
    <path
      strokeWidth={0}
      d="M99.59,110.87c-.28-2.2-1.42-11.02-3.44-26.45l-3.44-26.45-10.61,1.38.55,4.55c.18.73.69,1.1,1.52,1.1l1.52-.14,4.41,34.72c.09.37.28.69.55.96,2.3,1.84,3.81,4.11,4.55,6.82.73,2.71.64,5.44-.28,8.2l21.9-2.76,5.65-3.44-21.22,2.76c-.83.09-1.33-.28-1.52-1.1-.18,0-.25-.02-.21-.07l.07-.07Z"
    />
    <path
      strokeWidth={0}
      d="M20.51,65.96h1.38c.46,0,.73.28.83.83v19.98h-3.03v-19.98c0-.55.28-.83.83-.83h0Z"
    />
    <path
      strokeWidth={0}
      d="M122.61,21.82l-8.58-9.86-2.5-.32c-.32-.58-.7-1.15-1.09-1.6l-4.1-4.67c-3.46-3.97-9.47-4.35-13.38-.96l-4.74,4.1c-.19.13-.38.32-.51.51l-2.3-.26-2.37,2.05-1.22-1.41.9-.77c.7-.64.83-1.79.19-2.56l-.38-.38c-.64-.7-1.79-.83-2.56-.19l-8.51,7.36c-.7.64-.83,1.79-.19,2.56l.38.38c.64.7,1.79.83,2.56.19l.9-.77,1.34,1.54c-2.88,3.52-2.94,8.7.19,12.29l16.26,18.69c3.46,3.97,9.47,4.35,13.38.96l15.42-13.44c3.9-3.52,4.29-9.54.9-13.44h0ZM95.09,6.84c2.56-2.24,6.53-1.98,8.77.64l3.2,3.65-2.69-.32-12.22-1.41,2.94-2.56ZM100.6,39.8c-4.29,0-7.74-3.39-7.74-7.49,0-1.22.26-2.3.83-3.39,0-.06.06-.06.06-.06l6.34-11.39c.06-.19.32-.32.58-.32s.45.13.58.32l6.27,11.33c.06.06.06.13.06.19.26.45.38.96.58,1.47.19.58.26,1.22.26,1.92-.06,4.1-3.52,7.42-7.81,7.42h0Z"
    />
    <path
      strokeWidth={0}
      d="M100.66,36.68c-1.29,0-2.19-.1-2.94-.23-.29-.05-.42-.16-.42-.52v-7.66c0-.36.13-.47.42-.52.74-.13,1.64-.23,2.94-.23,2.38,0,3.97,1.18,3.97,4.03v1.09c0,2.85-1.59,4.03-3.97,4.03ZM101.76,31.56c0-1.27-.26-1.61-1.1-1.61h-.53v4.31h.53c.84,0,1.1-.34,1.1-1.61v-1.09Z"
    />
  </svg>
);
export default Mhe6BForkliftSitDownFuelDieselIcon;
