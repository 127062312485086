import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  AsyncLoadStatus,
  FilterFieldConfigBase,
  FilterFieldDataType,
  FilterFieldEditorType,
} from '../../common/types';
import {
  policyFilterData,
  policyFilterSearchByFilterKey,
} from '../../simulation/store/policyFilter.state';
import { getPolicyFilterKey } from '../policyFilter.helper';
import {
  PolicyFilterEditorData,
  PolicyFilterEditorEvents,
} from '../policyFilter.types';
import PolicyABCRangeEditor from './PolicyABCRangeEditor';
import PolicyEditorContainer from './PolicyEditorContainer';
import PolicyRangeEditor, { PolicyRangeEditorProps } from './PolicyRangeEditor';

export type PolicyFilterNumberEditorProps = PolicyFilterEditorEvents &
  PolicyFilterEditorData & {
    config: FilterFieldConfigBase<string>;
  };

const PolicyFilterNumberEditor: React.FC<
  PolicyFilterNumberEditorProps
> = props => {
  const { t } = useTranslation('app');
  const filterKey = getPolicyFilterKey(props.filterType, 'main');
  const filterData = useRecoilValue(policyFilterData(filterKey));
  const search = useRecoilValue(policyFilterSearchByFilterKey(filterKey));
  const selectedRange = props.value?.range;
  const filterRange = filterData?.range || { from: null, to: null };
  const isLoading = filterData?.status === AsyncLoadStatus.Loading;
  const loadedCount = filterData?.content?.length || 0;
  const totalCount = filterData?.totalCount || 0;

  const editorRange: [number, number] =
    props.config.editorType === FilterFieldEditorType.SliderRangeFixed
      ? [props.config.rangeMinValue, props.config.rangeMaxValue]
      : [
          _.isNil(filterRange.from)
            ? filterRange.from
            : Math.min(
                filterRange.from,
                selectedRange?.from ?? Number.MAX_VALUE,
              ),
          _.isNil(filterRange.to)
            ? filterRange.to
            : Math.max(filterRange.to, selectedRange?.to ?? 0),
        ];

  const onSelectRange = (value: [number, number], isNull: boolean) => {
    const from = value[0] === filterRange.from ? null : value[0];
    const to = value[1] === filterRange.to ? null : value[1];
    props.onChange({
      type: props.filterType,
      range: {
        from,
        to,
      },
      isNull,
    });
  };

  const rangeEditorProps: PolicyRangeEditorProps = {
    title: props.config.title,
    fieldId: props.config.type,
    selected: [selectedRange?.from, selectedRange?.to],
    range: editorRange,
    isLoading: isLoading,
    onSelectValue: onSelectRange,
    format: props.config.format,
    isIntegerStepSize:
      props.config.dataType === FilterFieldDataType.IntegerRange,
    hasSelectNullOption: !_.isNil(props.config.nullOptionTitle),
    selectedNullOption: props.value?.isNull,
    nullOptionTitle: props.config.nullOptionTitle,
  };

  const showABC = props.config.editorType === FilterFieldEditorType.ABCRange;

  return (
    <div data-component="PolicyFilterEditorSelect">
      <PolicyEditorContainer
        title={props.title}
        filterType={props.filterType}
        // summary={
        //   <PolicyEditorDataStatus
        //     totalCount={totalCount}
        //     loadedCount={loadedCount}
        //   />
        // }
        isLoading={isLoading}
      >
        {showABC && <PolicyABCRangeEditor {...rangeEditorProps} />}
        {!showABC && <PolicyRangeEditor {...rangeEditorProps} />}
      </PolicyEditorContainer>
    </div>
  );
};

export default PolicyFilterNumberEditor;
