import React, { HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import useSelectLocationById from '../layout/hooks/useSelectLocationById';
import {
  viewerLayoutId,
  viewerSelectedLocationIdAtom,
} from '../layout/viewer/store/viewer.state';
import ButtonLocate from './ButtonLocate';

export type ButtonLocateLocationProps = {
  children?: React.ReactNode;
  locationId: string;
  className?: string;
  classNameIcon?: string;
  color?: [string, string];
  colorBorder?: string;
  colorText?: string;
  colorBg?: string;
  colorBgIcon?: string;
  title?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  showActiveSelection?: boolean;
};

const ButtonLocateLocation: React.FC<ButtonLocateLocationProps> = ({
  showActiveSelection = true,
  ...props
}) => {
  const { locationId } = props;
  const layoutId = useRecoilValue(viewerLayoutId);
  const [selectLocation, cancelSelectLocation] = useSelectLocationById();
  const selectedLocationId = useRecoilValue(viewerSelectedLocationIdAtom);

  const locationIsSeelcted = locationId === selectedLocationId;

  const handleToggleSelectLocation = async () => {
    if (locationIsSeelcted) {
      await cancelSelectLocation();
    } else {
      await selectLocation({
        layoutId,
        locationId,
      });
    }
  };

  return (
    <ButtonLocate
      className={props.className}
      classNameIcon={props.classNameIcon}
      selectValue={locationId}
      isActive={showActiveSelection && locationIsSeelcted}
      color={props.color}
      colorBorder={props.colorBorder}
      colorText={props.colorText}
      colorBg={props.colorBg}
      colorBgIcon={props.colorBgIcon}
      icon={props.icon}
      title={props.title}
      onClick={handleToggleSelectLocation}
    >
      {props.children}
    </ButtonLocate>
  );
};
export default ButtonLocateLocation;
