import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';
import { LocationFilterUnion } from '../location-filter.model';

@InputAndObjectType()
export class LocationSharingPolicyRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;

  @Field()
  maxItemsPerLocation: number;
}

@InputAndObjectType()
export class LocationSharingPolicy {
  @Field(() => [LocationSharingPolicyRule], { nullable: true })
  rules?: LocationSharingPolicyRule[];

  @Field({ nullable: true, defaultValue: 1 })
  defaultMaxItemsPerLocation?: number;
}
