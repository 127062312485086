import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkloadViewAs } from '../../common/types';
import ButtonSwitchMulti from './ButtonSwitchMulti';

export type SwitchProps = {
  selected: WorkloadViewAs;
  onChange: (value: WorkloadViewAs) => void;
  availableOptions: WorkloadViewAs[];
};

const SwitchWorkforce: React.FC<SwitchProps> = ({
  selected,
  onChange,
  availableOptions,
}) => {
  const { t } = useTranslation('app');

  const viewAs: { key: WorkloadViewAs; label: string }[] = [
    {
      key: 'workforce',
      label: t('Workforce'),
    },
    {
      key: 'workloadbalance',
      label: t('Workload'),
    },
  ];

  const filteredViewAsT = viewAs.filter(({ key }) =>
    availableOptions.includes(key),
  );

  return (
    <ButtonSwitchMulti
      autoSize
      className="mx-1 xl:mx-3 w-full flex-1 truncate"
      classNameLabel="text-xs xl:text-sm py-1 px-2"
      buttonType="header"
      options={filteredViewAsT}
      selectedIndex={filteredViewAsT.findIndex(o => o.key === selected)}
      onClick={index => onChange(filteredViewAsT[index].key)}
    />
  );
};
export default SwitchWorkforce;
