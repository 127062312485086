import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { POLICY_FILTER_VALUES_PAGE_SIZE } from '../common/constants';
import { FilterFieldConfigBase, FilterFieldEditorType } from '../common/types';
import { policyFiltersSearchValues } from '../simulation/store/policyFilter.state';
import PolicyFilterNumberEditor, {
  PolicyFilterNumberEditorProps,
} from './editors/PolicyFilterNumberEditor';
import PolicyFilterStringEditor, {
  PolicyFilterStringEditorProps,
} from './editors/PolicyFilterStringEditor';
import {
  LoadPolicyFilterValuesParams,
  PolicyFilterBase,
  PolicyFilterEditorData,
  PolicyFilterEditorEvents,
} from './policyFilter.types';

export type PolicyFilterValueEditorProps<T extends string> = {
  config: FilterFieldConfigBase<T>;
  value: PolicyFilterBase<T>;
  onValueChange: (value: PolicyFilterBase<T>) => void;
  startLoadData: (
    params: Omit<LoadPolicyFilterValuesParams<T>, 'filterIntersection'>,
  ) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const PolicyFilterValueEditor: React.FC<
  PolicyFilterValueEditorProps<string>
> = props => {
  const { t } = useTranslation('app');
  const { type } = props.config;
  const { startLoadData, cancelLoadData } = props;
  const [search, setSearch] = useRecoilState(policyFiltersSearchValues);

  const onSearch = (value: string, filterKey: string) => {
    cancelLoadData(filterKey);
    setSearch({ ...search, [filterKey]: value });
    startLoadData({
      field: type,
      filterKey,
    });
  };

  const onLoadNext = (filterKey: string, skip: number) => {
    startLoadData({
      field: type,
      filterKey,
      isAppend: true,
      skip,
      limit: POLICY_FILTER_VALUES_PAGE_SIZE,
    });
  };

  const editorProps: PolicyFilterEditorEvents & PolicyFilterEditorData = {
    title: props.config.title,
    filterType: type,
    value: props.value,
    onSearch,
    onLoadNext,
    onChange: props.onValueChange,
  };

  const numberEditorProps: PolicyFilterNumberEditorProps = {
    ...editorProps,
    config: props.config,
  };

  const stringEditorProps: PolicyFilterStringEditorProps = {
    ...editorProps,
    mode:
      props.config.editorType === FilterFieldEditorType.StringRange
        ? 'both'
        : 'items',
  };

  switch (props.config.editorType) {
    case FilterFieldEditorType.MultipleCheck:
    case FilterFieldEditorType.StringRange:
      return <PolicyFilterStringEditor {...stringEditorProps} />;
    case FilterFieldEditorType.ABCRange:
    case FilterFieldEditorType.SliderRange:
    case FilterFieldEditorType.SliderRangeFixed:
      return <PolicyFilterNumberEditor {...numberEditorProps} />;
    default:
      console.log('Filter editor is not supported. Config: ', props.config);
      new Error(t`Filter editor is not supported`);
  }
};

export default PolicyFilterValueEditor;
