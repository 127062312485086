import {
  StoredFileFragment,
  useRemoveWarehouseFileMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { formatDateTime } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import useFormatter from '../../common/useFormatter';
import { Button } from '../../components/actions/Button';
import { ColumnConfig } from '../../components/DatasetTable';
import * as Icon from '../../components/icons';
import TimeAgo from '../../dashboard/TimeAgo';
import { warehouseFilesSeed } from '../store/warehouseFiles.state';

const RemoveFileButton = (props: { warehouseId: string; name: string }) => {
  const { t } = useTranslation('dataset');
  const [removeFile] = useRemoveWarehouseFileMutation();
  const setSeed = useSetRecoilState(warehouseFilesSeed);
  const [status, setStatus] = useState<AsyncLoadStatus>(AsyncLoadStatus.None);

  async function remove() {
    try {
      setStatus(AsyncLoadStatus.Loading);
      const { data, errors } = await removeFile({
        variables: {
          id: {
            warehouseId: props.warehouseId,
            name: props.name,
          },
        },
      });
      if (errors) {
        setStatus(AsyncLoadStatus.Error);
        return;
      }

      //success
      setStatus(AsyncLoadStatus.Ok);
      setSeed(nanoid());
    } catch (ex) {
      console.error(ex);
      setStatus(AsyncLoadStatus.Error);
    }
  }
  const label =
    status === AsyncLoadStatus.Loading
      ? t`Processing...`
      : status === AsyncLoadStatus.Error
        ? t`Error`
        : t`Remove`;

  return (
    <Button
      className="rounded"
      buttonSize="xs"
      buttonType="delete"
      label={label}
      onPress={remove}
      hasIconAfter={
        <Icon.CircleX className={classNames('h-5 w-5 fill-current')} />
      }
    />
  );
};

const FileSizeComponent: React.FC<{ size: number }> = ({ size }) => {
  const formatFileSize = (
    bytes: number,
  ): { value: string; unitSize: string } => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    let formattedSize = bytes;

    while (formattedSize >= 1024 && i < units.length - 1) {
      formattedSize /= 1024;
      i++;
    }

    return {
      value: formattedSize.toFixed(2),
      unitSize: units[i],
    };
  };

  const { value, unitSize: unitSize } = formatFileSize(size);

  return (
    <span>
      <span>{value}</span>
      <span className="text-xxs mx-0.5 opacity-70">{unitSize}</span>
    </span>
  );
};

function useWarehouseFilesTableConfig(): ColumnConfig<StoredFileFragment>[] {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();

  function getFileExtension(filename: string): JSX.Element {
    const extension = filename.split('.').pop()?.toLowerCase();
    return <span>{extension}</span>;
  }

  function getIconByExtension(filename: string): JSX.Element {
    const extension = filename.split('.').pop()?.toLowerCase();
    const iconStyle = 'h-6 w-6 fill-current';
    switch (extension) {
      case 'xls':
        return <Icon.FiletypeXlsx className={classNames(iconStyle)} />;
      case 'csv':
        return <Icon.FiletypeCsv className={classNames(iconStyle)} />;
      case 'zip':
        return <Icon.FiletypeData className={classNames(iconStyle)} />;
      case 'json':
        return <Icon.FiletypeJson className={classNames(iconStyle)} />;
      default:
        return <Icon.FiletypeAny className={classNames(iconStyle)} />;
    }
  }

  const columnsConfig: ColumnConfig<StoredFileFragment>[] = [
    {
      field: 'name',
      title: t`Filename`,
      hasFilter: true,
      hasSort: true,
      render: (value: string, file: StoredFileFragment) => (
        <a
          rel="noopener noreferrer"
          className={classNames(
            'hover:text-menu-active',
            'flex items-center',
            'px-2 py-0.5 text-sm',
          )}
          href={file.downloadUrl}
          target="_blank"
        >
          {getIconByExtension(file.name)}
          <span className={classNames('ltr:ml-2 rtl:mr-2')}>{file.name}</span>
        </a>
      ),
    },
    {
      field: 'lastModified',
      title: t`Date Modified`,
      hasFilter: false,
      hasSort: true,
      render: (v: number, row: StoredFileFragment) => {
        const lastModifiedDate = new Date(v);
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        return lastModifiedDate > oneWeekAgo ? (
          <TimeAgo
            timeDate={lastModifiedDate}
            updatedDate={lastModifiedDate}
            createdDate={lastModifiedDate}
            alignStart
          />
        ) : (
          <span className="opacity-75">{formatDateTime(lastModifiedDate)}</span>
        );
      },
    },
    {
      field: 'size',
      title: t`Size`,
      hasFilter: false,
      render: (v: number) => <FileSizeComponent size={v} />,
    },
    {
      field: 'name',
      title: t`kind`,
      hasFilter: false,
      render: (value: string, file: StoredFileFragment) => (
        <span className={classNames('ltr:ml-2 rtl:mr-2')}>
          {getFileExtension(file.name)}
        </span>
      ),
    },
    {
      field: 'warehouseId',
      title: t`Actions`,
      hasFilter: false,
      render: (whid, row) => (
        <div className="flex justify-end">
          <RemoveFileButton {...row} />
        </div>
      ),
    },
  ];

  // Set default sort to lastModified in descending order
  const defaultSort = { field: 'lastModified', direction: 'desc' as const };

  return columnsConfig.map(column => ({
    ...column,
    defaultSort:
      column.field === defaultSort.field ? defaultSort.direction : undefined,
  }));
}

export default useWarehouseFilesTableConfig;
