import { Container } from '../../components/layout/ContainerFlex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import StackingPolicyFilterEditorPanel from '../../stackingPolicy/StackingPolicyFilterEditorPanel';
import StackingPolicySubcategoriesEditor from '../../stackingPolicy/StackingPolicySubcategoriesEditor';
import { routingPolicyRule } from '../store/routingPolicy/routingPolicy.state';
import { stackingPolicySelectedIdentity } from '../store/stackingPolicy/stackingPolicy.state';

const StackingPolicyEditorSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const selectedIdentity = useRecoilValue(stackingPolicySelectedIdentity);
  const rule = useRecoilValue(routingPolicyRule(selectedIdentity?.ruleId));

  const showProductFilters = selectedIdentity?.productFilterId;
  const showSubcategories = selectedIdentity?.isSubcategoriesFilterSelected;
  const showAny = showProductFilters || showSubcategories;

  if (!showAny) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Rule selected`}
        message_helper={t`Select stacking policy rule to apply restrictions`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      {showProductFilters && <StackingPolicyFilterEditorPanel />}
      {showSubcategories && <StackingPolicySubcategoriesEditor />}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-stacking-policy-editor',
    title: t`Staking Policy Params`,
    loadingMessage: t`Loading...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <StackingPolicyEditorSidebar />
    </SidebarContainer>
  );
};
