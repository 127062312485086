import {
  AppBillingInvoiceFragment,
  AppBillingProductFragment,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrencyBest } from '../common/formatHelper';
import { TotalAmount } from '../pricingPlans/PlanCard';
import { convertStripeAmount } from './store/appBilling.helper';

export type AppBillingInvoiceProps = {
  invoice: AppBillingInvoiceFragment;
  product: AppBillingProductFragment;
};

const AppBillingInvoice: React.FC<AppBillingInvoiceProps> = props => {
  const { t } = useTranslation('app');

  const { invoice } = props;
  if (!invoice) return null;
  const total = formatCurrencyBest(
    convertStripeAmount(invoice.total),
    invoice.currency,
  );
  const tax = formatCurrencyBest(
    convertStripeAmount(invoice.tax),
    invoice.currency,
  );
  const item = _.head(invoice.items);
  const price = item.price;
  const itemPrice = formatCurrencyBest(
    convertStripeAmount(item.price.unitAmount),
    invoice.currency,
  );

  return (
    <div
      data-component="AppBillingInvoice"
      className={classNames('flex-1', 'p-10', 'bg-brand text-menu-active-text')}
    >
      <h3 className={classNames('text-3xl flex mb-8')}>
        <span className="">{t`Invoice`}</span>
        <span
          className={classNames(
            'ltr:ml-2 rtl:mr-2',
            'px-2 py-0.5',
            'text-base font-bold',
            'rounded',
            'flex items-center border border-menu',
            'text-opacity-75',
          )}
        >
          {t`Draft`}
        </span>
      </h3>
      <div data-component="ProductSummary" className="">
        <div data-component="ProductSummary-info">
          <TotalAmount
            title={props.product.name}
            price={total.value}
            currency={total.unit}
            recurring={price?.subscriptionDetails?.interval}
            billingBasis={`Warehouse`}
            billingInterval={
              props.product?.productCategory === 'BUNDLE'
                ? item?.quantity + ' ' + t`simulation runs`
                : t`month`
            }
            displayMode={'SUMMARY'}
          >
            {/* <span
                  className="ProductSummary-description Text Text-color--gray500 Text-fontSize--14 Text-fontWeight--500"
                  id="ProductSummary-description"
                >
                  <div className="ProductSummaryDescription">
                    Analyse your warehouse operations
                  </div>
                </span> */}
            {/* <div className="ProductSummary-detailsPrompt">
                  <span className="text-menu-text">View details</span>
                </div> */}
          </TotalAmount>
          {/* <div
                data-component="ProductSummaryTotalAmount"
                className="text-menu-text tabularNumbers"
              >
                <div className="flex flex-row align-items-baseline wrap-wrap">
                  <div className="mr-2 text-6xl width-fixed">$199</div>
                  <div className="ProductSummaryTotalAmount-billingInterval text-menu-400">
                    <div className="flex">per</div>
                    <div className="flex">month</div>
                  </div>
                </div>
              </div> */}
        </div>

        <section data-component="OrderDetails" className="flex-1">
          <ul data-component="OrderDetails-items" className="mb-8">
            <div data-component="OrderDetails-LineItem">
              <div
                data-component="LineItLineItem-productName"
                className="flex text-lg"
              >
                <div className="flex-1">
                  <span className="">
                    {props.product?.productCategory === 'BUNDLE'
                      ? props.product?.operationType + ' ' + t`One time bundle`
                      : props.product?.operationType + ' ' + t`Subscription`}
                  </span>
                </div>
                <div className="ml-2">
                  <span className="text-base">{itemPrice.fullString}</span>
                </div>
              </div>
              <div className="flex w-full text-sm text-menu-600 py-1">
                <span className="flex-1">{t`Warehouses`}</span>
                <span className="ml-2">1</span>
              </div>
              <div className="flex w-full text-sm text-menu-600 py-1">
                <span className="flex-1">
                  {props.product?.productCategory === 'BUNDLE'
                    ? t`Billed One-time`
                    : t`Billed monthly`}
                </span>
              </div>
            </div>
          </ul>
          <div data-component="OrderDetails-footer">
            <div
              data-component="OrderDetailsFooter-subtotal"
              className="flex text-base py-2"
            >
              <span className="flex-1">{t`Subtotal`}</span>
              <span className="ml-2" id="OrderDetailsFooter-SubtotalAmount">
                {itemPrice.fullString}
              </span>
            </div>
            <div
              data-component="OrderDetailsFooter-subtotalItems"
              className="border-b border-t border-menu-400 py-2"
            >
              <div
                data-component="OrderDetailsSubtotalItem"
                className="flex text-base"
              >
                <div className="flex-1">
                  <span>{t`Tax`}</span>
                </div>
                <div className="ml-2">
                  <span className="">{tax.fullString}</span>
                </div>
              </div>
            </div>
            <div data-component="OrderDetails-total" className="flex py-2">
              <span className="flex-1 text-base">{t`Total due today`}</span>
              <span className="ml-2 text-lg" id="OrderDetails-TotalAmount">
                {total.fullString}
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AppBillingInvoice;
