import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getOperationLabel } from '../../appBilling/store/appBilling.helper';
import {
  WarehouseBillingInterval,
  WarehouseBillingOneTime,
  WarehouseBillingSummary,
} from '../../appBilling/store/appBilling.types';
import { AsyncLoadStatus } from '../../common/types';
import * as Icon from '../icons';
import { ContainerTag } from '../layout/ContainerFlex';
import Loader from '../loaders/Loader';

export type BillingTagIntervalProps = {
  interval: WarehouseBillingInterval;
};
export type BillingTagCounterProps = {
  counter: WarehouseBillingOneTime;
};

const styleIcon = classNames(
  'fill-current',
  'w-4 h-4',
  'ltr:mr-0.5 rtl:ml-0.5',
);

const styleContainerTag = classNames('text-menu/60_ border border-menu-400');

export const BillingTagTrial: React.FC<BillingTagIntervalProps> = () => {
  const { t } = useTranslation('app');
  return (
    <ContainerTag className="bg-menu-active text-menu-active-text">
      <Icon.ResourceTiming className={styleIcon} />
      <span className="ltr:ml-1 rtl:mr-1">{t`Trial`}</span>
      <span className="ltr:ml-1 rtl:mr-1">—</span>
      <span className="ltr:ml-1 rtl:mr-1">{t`Subscribe`}</span>
    </ContainerTag>
  );
};

export const BillingTagSubscription: React.FC<
  BillingTagIntervalProps
> = props => {
  const { t } = useTranslation('app');
  return (
    <ContainerTag className={styleContainerTag}>
      <Icon.CircleOk className={styleIcon} />
      <span className={classNames('ltr:ml-1 rtl:mr-1')}>{t`Subscribed`}</span>
    </ContainerTag>
  );
};

export const BillingTagExpiredSubscription: React.FC<
  BillingTagIntervalProps
> = () => {
  const { t } = useTranslation('app');
  return (
    <ContainerTag className="bg-alerts-error">
      <Icon.ArrowRight className={styleIcon} />
      <span className="ltr:ml-1 rtl:mr-1">{t`Subscription Expired`}</span>
      <span className="ltr:ml-1 rtl:mr-1">—</span>
      <span className="ltr:ml-1 rtl:mr-1">{t`Subscribe`}</span>
    </ContainerTag>
  );
};

export const BillingTagOneTime: React.FC<BillingTagCounterProps> = props => {
  const { t } = useTranslation('app');

  return (
    <ContainerTag className={styleContainerTag}>
      <Icon.CircleOk className={styleIcon} />
      <span className="ltr:ml-1 rtl:mr-1">{props.counter.counter}</span>
      <span className="ltr:ml-1 rtl:mr-1">—</span>
      <span className="ltr:ml-1 rtl:mr-1">
        {getOperationLabel(props.counter.operation, t)}
      </span>
    </ContainerTag>
  );
};

export const BillingTagExpiredOneTime: React.FC<
  BillingTagCounterProps
> = props => {
  const { t } = useTranslation('app');
  return (
    <ContainerTag className="bg-alerts-error">
      <Icon.ArrowRight className={styleIcon} />
      <span className="ltr:ml-1 rtl:mr-1">{t`Expired`}</span>
      <span className="ltr:ml-1 rtl:mr-1">—</span>
      <span className="ltr:ml-1 rtl:mr-1">{t`Subscribe`}</span>
    </ContainerTag>
  );
};

export const BillingTagUnpaid: React.FC = () => {
  const { t } = useTranslation('app');
  return (
    <ContainerTag className={styleContainerTag}>
      <Icon.TriangleX className={styleIcon} />
      <span className="ltr:ml-1 rtl:mr-1">{t`No Subscription`}</span>
      <span className="ltr:ml-1 rtl:mr-1">{`—`}</span>
      <span className="ltr:ml-1 rtl:mr-1">{t`Subscribe`}</span>
    </ContainerTag>
  );
};

export const BillingTagDemo: React.FC = () => {
  const { t } = useTranslation('app');

  return (
    <ContainerTag
      className={classNames(
        'bg-menu-active text-menu-active-text ltr:mr-4 rtl:ml-4 ltr:pr-1 rtl:pl-1',
      )}
    >
      <span className="ltr:ml-1 rtl:mr-1">{t`Demo`}</span>
      <span className="ltr:ml-1 rtl:mr-1">{`—`}</span>
      <span className="ml-1 ">{t`Start Trial`}</span>
      <Icon.CircleArrowRight
        className={`fill-current w-4 h-4 ltr:ml-2 rtl:mr-2`}
      />
    </ContainerTag>
  );
};

export const BillingTagLoading = () => {
  const { t } = useTranslation('app');
  return (
    <ContainerTag className={styleContainerTag}>
      <Loader size={20} type={'loading-original'} />
      <span className="ml-1 flex-1">{t`Updating...`}</span>
    </ContainerTag>
  );
};

export type BillingTagProps = {
  billingState: WarehouseBillingSummary;
  billingStateLoadStatus: AsyncLoadStatus;
};

export const BillingTag: React.FC<BillingTagProps> = ({
  billingState,
  billingStateLoadStatus,
}) => {
  const isLoading =
    billingStateLoadStatus === AsyncLoadStatus.Loading ||
    billingStateLoadStatus === AsyncLoadStatus.None;

  if (isLoading) {
    return <BillingTagLoading />;
  }

  switch (billingState.status) {
    case 'unpaid':
      return <BillingTagUnpaid />;
    case 'hasTrial':
      return <BillingTagTrial interval={_.head(billingState.intervals)} />;
    case 'hasSubscription':
      return (
        <BillingTagSubscription interval={_.head(billingState.intervals)} />
      );
    case 'hasExpiredSubscription':
      return (
        <BillingTagExpiredSubscription
          interval={_.head(billingState.intervals)}
        />
      );
    case 'hasOneTime':
      return (
        <>
          {_(billingState.oneTimes)
            .filter(ot => ot.active)
            .map((ot, index) => (
              <BillingTagOneTime
                key={`billing-tag-${ot.operation}`}
                counter={ot}
              />
            ))
            .value()}
        </>
      );
    case 'hasExpiredOneTime':
      return (
        <BillingTagExpiredOneTime counter={_.head(billingState.oneTimes)} />
      );
  }
};

export default BillingTag;

export const BillingWarehouseMenuLoading = () => {
  // const { t } = useTranslation('app');
  return (
    <div className="flex items-center">
      <Loader size={40} type={'loading-original'} />
    </div>
  );
};

export const BillingWarehouseMenuTag: React.FC<BillingTagProps> = ({
  billingState,
  billingStateLoadStatus,
}) => {
  const isLoading =
    billingStateLoadStatus === AsyncLoadStatus.Loading ||
    billingStateLoadStatus === AsyncLoadStatus.None;

  if (isLoading) {
    return <BillingWarehouseMenuLoading />;
  }

  switch (billingState.status) {
    case 'unpaid':
      return <BillingTagUnpaid />;
    case 'hasTrial':
      return <BillingTagTrial interval={_.head(billingState.intervals)} />;
    case 'hasSubscription':
      return (
        <BillingTagSubscription interval={_.head(billingState.intervals)} />
      );
    case 'hasExpiredSubscription':
      return (
        <BillingTagExpiredSubscription
          interval={_.head(billingState.intervals)}
        />
      );
    case 'hasOneTime':
      return (
        <>
          {_(billingState.oneTimes)
            .filter(ot => ot.active)
            .map((ot, index) => (
              <BillingTagOneTime
                key={`billing-tag-${ot.operation}`}
                counter={ot}
              />
            ))
            .value()}
        </>
      );
    case 'hasExpiredOneTime':
      return (
        <BillingTagExpiredOneTime counter={_.head(billingState.oneTimes)} />
      );
  }
};
