import {
  AgentRole,
  AgentSettings,
  MeasurementSystem,
  WarehouseMetaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { TwTheme } from '../../../Tw';
import { getIndexedTitle } from '../../common/utils';
import {
  AgentFilterSectionConfig,
  getAgentEditorSettings,
} from '../../resourcePolicy/AgentEditorSettings';
import {
  AgentCategoryId,
  AgentLabourEvent,
  AgentSettingsWithMeta,
} from '../../resourcePolicy/agentData/agent.types';
import { getModelSettings } from '../../resourcePolicy/agentData/agentDataHelper';
import { getCostFilterConfiguration } from '../../resourcePolicy/costFilterConfig';
import { agentModelsByCategory } from '../../resourcePolicy/data/models/models';
import { getPickingFilterConfiguration } from '../../resourcePolicy/pickingFilterConfig';
import { getReassignFilterConfiguration } from '../../resourcePolicy/reassignFilterConfig';
import { getReplenishmentFilterConfiguration } from '../../resourcePolicy/replenishmentFilterConfig';
import { getSpecFilterConfiguration } from '../../resourcePolicy/specFilterConfig';

const eventColors = TwTheme.extend.colors.eventGroups;
const eventTextColors = TwTheme.extend.colors.eventGroupsTxt;

export const agentAllRoles = [
  AgentRole.PICKING,
  AgentRole.REPLENISHMENT,
  AgentRole.REASSIGN,
];
const defaultAgentTypeId: AgentCategoryId = 'default';

export function getAgentRolesTitle(
  role: AgentRole,
  t: TFunction<'simulation'>,
) {
  switch (role) {
    case AgentRole.PICKING:
      return t(`Picking`, { ns: 'simulation' });
    case AgentRole.REPLENISHMENT:
      return t(`Replenishment`, { ns: 'simulation' });
    case AgentRole.REASSIGN:
      return t(`Reassign`, { ns: 'simulation' });
  }
}

export type GetFilterConfigurationParams = {
  agent: AgentSettings;
  wh: WarehouseMetaFragment;
  uoms: string[];
  t: TFunction<'simulation'>;
};

export function getFilterConfiguration(
  params: GetFilterConfigurationParams,
): AgentFilterSectionConfig[] {
  const { agent, t } = params;
  const rolesMap = _.keyBy(agent.roles, r => r.roleId);
  const disablePicking =
    _.isNil(rolesMap[AgentRole.PICKING]) ||
    rolesMap[AgentRole.PICKING]?.disabled;

  const disableReplenishment =
    _.isNil(rolesMap[AgentRole.REPLENISHMENT]) ||
    rolesMap[AgentRole.REPLENISHMENT]?.disabled;

  const disableReassign =
    _.isNil(rolesMap[AgentRole.REASSIGN]) ||
    rolesMap[AgentRole.REASSIGN]?.disabled;

  const getRoleIndex = (roleId: AgentRole) =>
    _.findIndex(agent.roles, r => r.roleId === roleId);

  const editorSettings = getAgentEditorSettings(
    t,
    params.wh.measurementSystem,
    params.wh.currency,
  );

  return [
    ...getSpecFilterConfiguration(editorSettings, t, agent.specs, params.uoms),
    ...(disablePicking
      ? []
      : getPickingFilterConfiguration(
          editorSettings,
          rolesMap[AgentRole.PICKING],
          `roles[${getRoleIndex(AgentRole.PICKING)}]`,
          params.uoms,
          t,
        )),
    ...(disableReplenishment
      ? []
      : getReplenishmentFilterConfiguration(
          editorSettings,
          rolesMap[AgentRole.REPLENISHMENT],
          `roles[${getRoleIndex(AgentRole.REPLENISHMENT)}]`,
          params.uoms,
          t,
        )),
    ...(disableReassign
      ? []
      : getReassignFilterConfiguration(
          editorSettings,
          rolesMap[AgentRole.REASSIGN],
          `roles[${getRoleIndex(AgentRole.REASSIGN)}]`,
          params.uoms,
          t,
        )),
    ...getCostFilterConfiguration(editorSettings, t),
  ];
}

export const getLabourEventColor = (event: AgentLabourEvent) =>
  eventColors[event];

export const getLabourEventTextColor = (event: AgentLabourEvent) =>
  eventTextColors[event];

export type CreateAgentParams = {
  agentId?: string;
  titles: string[];
  titlePrefix: string;
  uoms: string[];
  system: MeasurementSystem;
  currencyCode: string;
};

export function createAgent(params: CreateAgentParams): AgentSettingsWithMeta {
  const agentId = params.agentId ?? nanoid();
  const agentTitle = getIndexedTitle(
    new Set(params.titles),
    params.titlePrefix,
  );

  const models = agentModelsByCategory[defaultAgentTypeId];
  const modelId = _.head(models)?.id;

  const agentDefault = getModelSettings(
    modelId,
    params.uoms,
    params.system,
    params.currencyCode,
    'simple',
  );

  return {
    ...agentDefault,
    id: agentId,
    title: agentTitle,
    resourceTypeId: defaultAgentTypeId,
    metadata: {
      modelId,
    },
  };
}

export type CloneAgentParams = {
  agent: AgentSettingsWithMeta;
  titles: string[];
  titlePrefix: string;
};

export function cloneAgent(params: CloneAgentParams): AgentSettingsWithMeta {
  return {
    ..._.cloneDeep(params.agent),
    id: nanoid(),
    title: getIndexedTitle(new Set(params.titles), params.titlePrefix),
  };
}
