import { useApolloClient } from '@apollo/client';
import { FetchResult } from '@apollo/client/core';
import {
  ChangeDemoWarehouseSubscriptionDocument,
  ChangeDemoWarehouseSubscriptionMutation,
  ChangeDemoWarehouseSubscriptionMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { errorAppender } from '../../store/error.state';
import useLoadWarehouses from './useLoadWarehouses';

function useAssignDemoWarehouse() {
  const client = useApolloClient();
  const loadWhList = useLoadWarehouses();
  const { t } = useTranslation('app');
  const errorAssign = t`Can not assign demo warehouse`;

  const assignDemoWarehouse = useRecoilCallback(
    ({ snapshot, set }) =>
      async (whId: string, subscribe: boolean) => {
        let response: FetchResult<ChangeDemoWarehouseSubscriptionMutation>;
        try {
          response = await client.mutate<
            ChangeDemoWarehouseSubscriptionMutation,
            ChangeDemoWarehouseSubscriptionMutationVariables
          >({
            mutation: ChangeDemoWarehouseSubscriptionDocument,
            variables: {
              input: {
                warehouseId: whId,
                subscribed: subscribe,
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: errorAssign,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: errorAssign,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          return;
        }
      },
  );

  return async (warehouseId: string, subscribe: boolean) => {
    await assignDemoWarehouse(warehouseId, subscribe);
    await loadWhList();
  };
}

export default useAssignDemoWarehouse;
