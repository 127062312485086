import { Container } from '../../components/layout/ContainerFlex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ImportPipelineEditPanel from '../../importJobs/panels/ImportPipelineEditPanel';

const DatasetObjectEditorSidebar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-dataset-pipeline-editor',
    title: t`Pipeline Settings`,
    size: 'md',
  };
  return (
    <SidebarContainer {...containerProps}>
      <Container col>
        <ImportPipelineEditPanel />
      </Container>
    </SidebarContainer>
  );
};

export default DatasetObjectEditorSidebar;
