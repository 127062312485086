import * as React from 'react';
const MoveTypeSwapArrowsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeSwapArrowsIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="65.6 64.46 65.6 91.07 39.27 91.07 56.82 73.47 45.53 73.47 23.99 95.07 45.57 116.67 56.85 116.67 39.27 99.07 65.6 99.07 65.6 124.46 5.6 124.46 5.6 64.46 65.6 64.46"
    />
    <rect width={20} height={8} x={69.97} y={91.02} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="65.76 3.2 65.76 29.81 92.09 29.81 74.54 12.21 85.83 12.21 107.37 33.81 85.79 55.41 74.51 55.41 92.09 37.82 65.76 37.81 65.76 63.2 125.76 63.2 125.76 3.2 65.76 3.2"
    />
    <rect width={20} height={8} x={41.39} y={29.76} strokeWidth={0} />
  </svg>
);
export default MoveTypeSwapArrowsIcon;
