import { SegmentFragment } from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import React, { useState } from 'react';
import { Circle } from 'react-konva';
import { TwTheme } from '../../../Tw';

const portalColors = TwTheme.extend.colors.portal;

export type PortalProps = {
  portal: SegmentFragment;
  isSelected: boolean;
  isEntryPoint?: boolean;
  onClick?;
  withCancelClickBubble?: boolean;
  onMouseOver?;
  onMouseOut?;
  scaleFactor?: number;
  color?: [string, string];
};

const PortalFeature: React.FC<PortalProps> = props => {
  const { portal, isSelected } = props;
  const [hovered, setHovered] = useState(false);

  const config: Konva.CircleConfig = {
    // id: portal.id,
    x: (portal.start.x + portal.end.x) / 2,
    y: (portal.end.y + portal.start.y) / 2,
    radius: 50,
    draggable: false, //props.isSelected,
    fill:
      props.color?.[0] ??
      (props.isEntryPoint
        ? portalColors.entrypoint
        : props.isSelected
        ? portalColors.selected
        : hovered
        ? portalColors.hover
        : portalColors.DEFAULT),
    stroke: '#AAAAAA',
    strokeScaleEnabled: false,
    strokeWidth: 0,
    perfectDrawEnabled: false,
    scale: { x: props.scaleFactor ?? 1, y: props.scaleFactor ?? 1 },
  };

  function onClick(evt: Konva.KonvaEventObject<MouseEvent>) {
    evt.cancelBubble = props.withCancelClickBubble;
    props.onClick && props.onClick(!props.isSelected);
  }

  return (
    <Circle
      {...config}
      onTap={onClick}
      onClick={onClick}
      onMouseEnter={e => {
        // style stage container:
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        setHovered(true);
        props.onMouseOver && props.onMouseOver();
      }}
      onMouseLeave={e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        setHovered(false);
        props.onMouseOut && props.onMouseOut();
      }}
    />
  );
};

export default React.memo(PortalFeature);
