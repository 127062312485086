import { MeasurementSystem } from '@warebee/shared/data-access-layout-manager';
import { TFunction } from 'i18next';

export type MeasureEditor = 'input' | 'drop-down';
export type MeasureType = 'size' | 'volume' | 'weight' | 'local-date-time';

export function getMeasurementSystemTitle(
  system: MeasurementSystem,
  t: TFunction<'measures'>,
) {
  switch (system) {
    case MeasurementSystem.METRIC:
      return t('Metric', { ns: 'measures' });
    case MeasurementSystem.IMPERIAL:
      return t('Imperial', { ns: 'measures' });
  }
}

export function getDefaultMeasure(
  system: MeasurementSystem,
  type: MeasureType,
) {
  switch (type) {
    case 'size':
      switch (system) {
        case MeasurementSystem.METRIC:
          return 'cm';
        case MeasurementSystem.IMPERIAL:
          return 'in';
      }
      break;
    case 'volume':
      switch (system) {
        case MeasurementSystem.METRIC:
          return 'cm3';
        case MeasurementSystem.IMPERIAL:
          return 'in3';
      }
      break;
    case 'weight':
      switch (system) {
        case MeasurementSystem.METRIC:
          return 'kg';
        case MeasurementSystem.IMPERIAL:
          return 'lb';
      }
      break;
  }
}

export function getMeasureTitle(key: string, t: TFunction<'measures'>) {
  switch (key) {
    case 'mm':
      return t('mm', { ns: 'measures' });
    case 'cm':
      return t('cm', { ns: 'measures' });
    case 'm':
      return t('m', { ns: 'measures' });
    case 'km':
      return t('km', { ns: 'measures' });

    //....
    default:
      return key;
  }
}

export function getMeasureEditor(mappingMeasure: MeasureType): MeasureEditor {
  switch (mappingMeasure) {
    case 'local-date-time':
      return 'input';
    default:
      return 'drop-down';
  }
}
