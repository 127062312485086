import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import RuleContainer from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import useLoadItemSetFilterValues from '../itemSet/hooks/useLoadItemSetFilterValues';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from '../policyFilters/policyFilter.helper';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import { getStackingPolicyFilterConfig } from '../simulation/store/stackingPolicy/stackingPolicy.default';
import {
  stackingPolicyRule,
  stackingPolicySelectedIdentity,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';
import { sidebarStateByType } from '../store/sidebar.state';
import StackingPolicyItemsStats from './StackingPolicyItemsStats';
import StackingPolicySubcategoriesSummary from './StackingPolicySubcategoriesSummary';

export type StackingPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;
  color?: [string, string];
  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
};

const StackingPolicyRule: React.FC<StackingPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');

  const [rule, setRule] = useRecoilState(stackingPolicyRule(props.ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    stackingPolicySelectedIdentity,
  );
  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-item-set-editors'),
  );
  const [startLoadData] = useLoadItemSetFilterValues();
  const canUpdate = useRecoilValue(simulationIsEditable);

  function addFilterSet() {
    const id = nanoid();
    setRule({
      ...rule,
      itemsMatch: {
        anyOf: [...(rule.itemsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });

    setSelectedIdentity({
      ruleId: rule.id,
      productFilterId: id,
    });
  }

  const removeField = (groupId: string, fieldId: string) => {
    setRule({
      ...rule,
      itemsMatch: {
        anyOf: _.map(rule.itemsMatch.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };

  const removeFilterSet = (groupId: string) => {
    setRule({
      ...rule,
      itemsMatch: {
        anyOf: _.filter(rule.itemsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  function selectFilterSet(id: string, isSelected: boolean) {
    setSelectedIdentity(
      isSelected
        ? {
            ruleId: rule?.id,
            productFilterId: id,
          }
        : null,
    );
    setSidebarState({
      ...sidebarState,
      isHidden: !isSelected,
      isCollapsed: false,
    });
    // trigger filter to refresh
    if (isSelected) {
      getStackingPolicyFilterConfig(t).forEach(configItem => {
        const keyParts = getPolicyFilterDataKeysByEditorType(
          configItem.editorType,
        );

        keyParts.forEach(key => {
          const filterKey = getPolicyFilterKey(configItem.type, key);
          //params.cancel(filterKey);
          startLoadData({
            field: configItem.type,
            filterKey,
            filterIntersection: null,
          });
        });
      });
    }
  }

  function selectRule() {
    // setSelectedRuleId(isSelected ? null : rule.id);
    //deselect filter set when rule selection changed
    // selectFilterSet(selectedFilterSetId, false);
  }

  const isSelectedRule = rule?.id === selectedIdentity?.ruleId;

  const filterConfig = getStackingPolicyFilterConfig(t);

  function renameRule(newTitle: string) {
    setRule({
      ...rule,
      title: newTitle,
    });
  }

  return (
    <RuleContainer
      dataComponent="StackingPolicyRule"
      key={`stacking-policy-rule-${rule.id}`}
      id={`stacking-policy-rule-${rule.id}`}
      // classNameInner="bg-priority-top/20"
      title={rule.title}
      isRemovable={true}
      hasIcon={true}
      hasColorMode={false}
      isCollapsible={true}
      isActive={isSelectedRule}
      hasArrowUp={props.canMoveUp}
      hasArrowDown={props.canMoveDown}
      hasOrder
      onClick={selectRule}
      isDisabled={!canUpdate}
      onDeleteClick={props.onDeleteClick}
      onArrowUpClick={() => props.onMoveUp && props.onMoveUp()}
      onArrowDownClick={() => props.onMoveDown && props.onMoveDown()}
      canRename={canUpdate}
      onTitleChange={title => renameRule(title)}
      color={_.head(props.color)}
    >
      <RuleSectionContainer
        // className={classNames('')}
        // hasIssue={true}
        isSelected={isSelectedRule}
        transparent
        // onClick={() => setSelectedResultsId(isSelected ? null : rule.id)}
      >
        <StackingPolicyItemsStats
          ruleId={rule.id}
          color={_.head(props.color)}
        />

        <PolicyFilterGroupList
          filterSets={_.map(rule?.itemsMatch?.anyOf, filterGroup => ({
            id: filterGroup.id,
            allOf: [...filterGroup.allOf],
          }))}
          isDisabled={!canUpdate}
          config={filterConfig}
          selectedId={selectedIdentity?.productFilterId}
          onAddGroup={addFilterSet}
          onDeleteField={removeField}
          onSelectGroup={selectFilterSet}
          onDeleteGroup={removeFilterSet}
        />
        <StackingPolicySubcategoriesSummary
          ruleId={rule.id}
          subcategories={rule.subcategories}
        />
      </RuleSectionContainer>
    </RuleContainer>
  );
};

export default StackingPolicyRule;
