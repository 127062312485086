import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../components/LoadingIndicator';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../../components/actions/ButtonSwitchMulti';
import { Container } from '../../../components/layout/ContainerFlex';
import { ContainerMap } from '../../../components/layout/ContainerMap';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import StageContainer from '../../../layout/stage/StageContainer';
import SchedulerLayer from '../../../layout/viewer/layers/SchedulerLayer';
import OrderSetDataView from '../../../orders/OrderSetDataView';
import WorkforceAgentScheduleHeader from './WorkforceAgentScheduleHeader';
import WorkforceAgentScheduleTable from './WorkforceAgentScheduleTable';
import WorkforceToolbarLayer from './WorkforceToolbarLayer';
import WorkforceSchedulerTooltip from './tooltip/WorkforceSchedulerTooltip';

type TableViewMode = 'schedule' | 'orderSet';

export type WorkforceAgentScheduleProps = {
  analyzeId: string;
};

const WorkforceAgentSchedule: React.FC<WorkforceAgentScheduleProps> = props => {
  const { t } = useTranslation('simulation');
  const [tableViewMode, setTableViewMode] = useState<TableViewMode>('schedule');

  const tableViewOptionsKeys: TableViewMode[] = ['schedule', 'orderSet'];
  const tableViewOptions: ButtonSwitchMultiOption[] = [
    { label: t`Schedule` },
    { label: t`Orders` },
  ];

  return (
    <>
      <Suspense
        fallback={
          <Container
            hasNoScroll
            flex1
            className={classNames(
              'items-center justify-center h-full border-b border-app-panel-dark',
            )}
          >
            <LoadingIndicator
              selfCenter
              message={t`Loading Workforce Scheduler`}
            />
          </Container>
        }
      >
        <WorkforceAgentScheduleHeader analyzeId={props.analyzeId} />
      </Suspense>

      <ContainerMap className={classNames('min-h-bayMap h-full flex-1')}>
        <StageContainer type="workforce-agent-schedule" darkBg={true}>
          <SchedulerLayer
            stageId="workforce-agent-schedule"
            analyzeId={props.analyzeId}
          />
        </StageContainer>
        <WorkforceSchedulerTooltip stageId="workforce-agent-schedule" />
        <WorkforceToolbarLayer stageId="workforce-agent-schedule" />
      </ContainerMap>

      <Suspense
        fallback={
          <Container
            hasNoScroll
            flex1
            className={classNames('items-center justify-center h-full')}
          >
            <LoadingIndicator selfCenter message={t`Loading Workforce Data`} />
          </Container>
        }
      >
        <>
          <ActionBar className={classNames('px-2 py-3')}>
            <ButtonSwitchMulti
              // buttonType="minimal"
              buttonType="primary"
              autoSize
              options={tableViewOptions}
              selectedIndex={_.indexOf(tableViewOptionsKeys, tableViewMode)}
              onClick={index => setTableViewMode(tableViewOptionsKeys[index])}
            />
            <Spacer flexspace />
          </ActionBar>
          {tableViewMode === 'schedule' && (
            <WorkforceAgentScheduleTable analyzeId={props.analyzeId} />
          )}

          {tableViewMode === 'orderSet' && (
            <Container className={classNames('bg-app-panel-dark/60')}>
              <OrderSetDataView />
            </Container>
          )}
        </>
      </Suspense>
    </>
  );
};

export default WorkforceAgentSchedule;
