import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { StepCounterCircle } from '../components/helpers/StepCounterCircle';
import * as Icon from '../components/icons';
import { helperCalloutVisibilityState } from '../store/global.state';

export const CalloutType = {
  tip: 'bg-blue-500 hover:bg-blue-700 text-white font-bold',
  suggestion: '',
  caution: '',
  check: '',
  note: '',
  warning: '',
  action: '',
  counter: '',
};

export type CalloutProps = PropsWithChildren & {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconType?: string;
  type?: keyof typeof CalloutType | string;
  panelMode?: boolean;
  iconBefore?: boolean;
  iconAfter?: boolean;
  counter?: string;
};

const Callout: React.FC<CalloutProps> = props => {
  const { t } = useTranslation('help');

  const isHelperCalloutVisible = useRecoilValue(helperCalloutVisibilityState)[
    props.type
  ];

  if (!isHelperCalloutVisible) {
    return null;
  }

  let IconComponent = props.icon;

  if (!_.isNil(props.iconType)) {
    IconComponent = Icon[props.iconType];
  }

  const styleIcon = classNames(
    'inline',
    'text-menu-icon fill-current',
    'w-3 h-3',
    'ltr:ml-1 rtl:mr-1',
  );

  return (
    <div className={classNames({ 'px-3 pb-4 pt-1': props.panelMode })}>
      <div
        data-component="Callout"
        className={classNames(
          {
            'border-t-brand border-b-menu-100/30 mb-4 mt-4 border-b border-t pb-2 pt-5':
              props.type === 'tip',
          },
          {
            'border-menu/30 bg-app-panel/50 text-menu-active mt-4 rounded border p-1 shadow-xl md:p-2 xl:p-3':
              props.type === 'suggestion',
          },
          {
            'mt-2 flex p-2': props.type === 'warning',
          },
          { 'mt-2 flex': props.type === 'note' },
          { relative: props.panelMode },
          props.className,
        )}
      >
        {props.type && (
          <span
            data-component="CalloutType"
            className={classNames(
              {
                'absolute -top-3': props.panelMode,
              },
              { flex: props.type === 'note' },
            )}
          >
            {props.type === 'tip' && (
              <span
                className={classNames(
                  'px-2 py-1',
                  'ltr:mr-2 rtl:ml-2',
                  'text-xs uppercase',
                  'rounded',
                  'bg-menu-active text-menu-active-text',
                )}
              >
                {t`Tip`}
              </span>
            )}
            {props.type === 'note' && (
              <span
                className={classNames(
                  'px-2 py-1',
                  'ltr:mr-2 rtl:ml-2',
                  'text-xs uppercase',
                  'rounded',
                  'text-alerts-warningText',
                  'bg-alerts-info',
                )}
              >
                <Icon.CircleHelp
                  className={classNames('h-4 w-4 fill-current')}
                />
              </span>
            )}
            {props.type === 'suggestion' && (
              <Icon.HelpBot
                className={classNames(
                  'fill-current',
                  'h-6 w-6',
                  'ltr:mr-1 rtl:ml-1',
                )}
              />
            )}
            {props.type === 'warning' && (
              <Icon.TriangleInfo
                className={classNames(
                  'fill-current',
                  'h-4 w-4',
                  'ltr:mr-2 rtl:ml-2',
                  'text-menu-active',
                )}
              />
            )}
          </span>
        )}
        {/* {props.title && props.children && ( */}
        <div
          data-component="CalloutContentContainer"
          className={classNames(
            // '',
            { 'flex flex-col': props.title },
            // { '': props.type !== 'tip' },
            // { '': props.type !== 'warning' },
          )}
        >
          {props.type === 'counter' && (
            <StepCounterCircle
              isPrimary
              stepCountSize={'sm'}
              stepCountId={props.counter}
            />
          )}
          {props.title && (
            <div data-component="CalloutTitle" className={classNames('flex-1')}>
              {props.title}
            </div>
          )}
          {props.children && (
            <div
              data-component="CalloutChildren"
              className={classNames(
                'whitespace-normal',
                props.type === 'suggestion'
                  ? 'text-menu-text/90'
                  : 'text-menu-text/80',
                {
                  inline: props.type === 'tip',
                },
                {
                  inline: props.type === 'warning',
                },
              )}
            >
              {props.iconBefore && !_.isNil(IconComponent) && (
                <IconComponent className={classNames(styleIcon)} />
              )}
              {props.children}
              {(props.type === 'action' || props.iconAfter) &&
                !_.isNil(IconComponent) && (
                  <IconComponent className={classNames(styleIcon)} />
                )}
            </div>
          )}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default Callout;
