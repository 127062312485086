import * as React from 'react';
const TagTitleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TagTitleIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M122.02,65.5l-23.18-24.05c-1.23-1.27-2.96-2.43-4.75-2.83H13.19c-5.54,0-10.05,4.52-10.05,10.07v47.77c0,5.56,4.51,10.07,10.07,10.07h78.96c2.41,0,5-1.09,6.67-2.83l23.18-24.05c3.79-3.92,3.8-10.23,0-14.16ZM37.68,82.48h-8.76v-2.77h2.9l-1.73-5.04h-8.06l-1.72,5.04h2.82v2.77h-8.82v-2.77h2.68l5.99-16.76h-2.67v-2.68h11.54v2.68h-2.67l6.02,16.76h2.48v2.77ZM60.29,78.65c-.65,1.25-1.55,2.24-2.71,2.99s-2.44,1.11-3.86,1.11c-1.13,0-2.2-.23-3.22-.69-1.02-.46-1.82-1.1-2.4-1.9v2.33h-5.71v-2.71h2.74v-16.82h-2.74v-2.68h5.71v8.93c1.55-1.75,3.44-2.62,5.65-2.62,1.42,0,2.71.36,3.86,1.08,1.15.72,2.05,1.7,2.69,2.93.64,1.23.97,2.56.97,3.98s-.33,2.84-.98,4.08ZM74.3,82.94c-1.54,0-2.92-.36-4.11-1.09s-2.12-1.73-2.78-2.99c-.65-1.27-.98-2.67-.98-4.22s.33-2.89,1-4.13c.66-1.24,1.57-2.2,2.73-2.9,1.16-.69,2.45-1.04,3.88-1.04,1.69,0,3.14.51,4.35,1.53v-1.12h2.71v5.87h-2.71c-.12-1.13-.57-1.99-1.36-2.59-.79-.59-1.7-.89-2.73-.89-.88,0-1.68.24-2.38.71-.7.47-1.25,1.11-1.63,1.92-.38.81-.58,1.69-.58,2.64,0,1.62.43,2.95,1.3,3.97.86,1.03,2,1.54,3.42,1.54,2.19,0,3.74-1.02,4.64-3.05l2.54,1.21c-1.43,3.09-3.86,4.63-7.31,4.63ZM96.59,81.28c-4.8,0-8.69-3.89-8.69-8.69s3.89-8.69,8.69-8.69,8.69,3.89,8.69,8.69-3.89,8.69-8.69,8.69Z"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M55.68,70.04c-.75-.46-1.59-.69-2.54-.69s-1.79.24-2.54.72-1.33,1.14-1.75,1.96c-.42.83-.63,1.75-.63,2.76s.22,1.85.65,2.65,1.02,1.44,1.77,1.9,1.57.69,2.48.69c.96,0,1.82-.26,2.58-.78.76-.52,1.34-1.2,1.75-2.05.41-.84.62-1.74.62-2.7s-.21-1.81-.63-2.59c-.42-.79-1.01-1.41-1.75-1.87Z"
    />
    <polygon
      fillRule="evenodd"
      strokeWidth={0}
      points="26.05 62.95 22.91 72.06 29.22 72.06 26.16 62.95 26.05 62.95"
    />
  </svg>
);
export default TagTitleIcon;
