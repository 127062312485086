import React from 'react';
import { useRecoilValue } from 'recoil';
import { loggedInUser } from '../store/auth.state';

declare global {
  interface Window {
    HubSpotConversations: any;
  }
}

const useHubspotChat = portalId => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [activeConversation, setActiveConversation] = React.useState(false);
  const eventRef = React.useRef(null);
  const [chatVisible, setChatVisible] = React.useState(false);
  const [chatButtonVisible, setChatButtonVisible] = React.useState(false);
  const [chatWindowOpen, setChatWindowOpen] = React.useState(false);
  const w = window as any;
  const user = useRecoilValue(loggedInUser);
  const [shadowContainer, setShadowContainer] =
    React.useState<HTMLElement | null>(null);

  const setEmail = () => {
    if (hasLoaded && user.email && w.HubSpotConversations?.api) {
      w.HubSpotConversations?.api.setEmail(user.email);
      console.log('Email set:', user?.email);
    }
  };

  const getEmail = () => {
    if (hasLoaded && w.HubSpotConversations?.api) {
      w.HubSpotConversations?.api
        .getEmail()
        .then(email => console.log('Current email:', email))
        .catch(err => console.error('Error getting email:', err));
    }
  };

  const isWidgetLoaded = () => {
    if (w.HubSpotConversations && w.HubSpotConversations?.widget) {
      const status = w.HubSpotConversations?.widget.status();
      console.log('Widget isWidgetLoaded:', status);
      setChatWindowOpen(true);
      return status.status === 'loaded';
    }
    return false;
  };

  React.useEffect(() => {
    const findElements = () => {
      const shadowContainer = document.querySelector('.hs-shadow-container');
      const hubspotButton = document.getElementById(
        'hubspot-messages-iframe-container',
      );

      if (shadowContainer) {
        setShadowContainer(shadowContainer as HTMLElement);
      }

      if (hubspotButton) {
        setChatButtonVisible(true);
      }
    };

    findElements();
  }, []);

  React.useEffect(() => {
    if (shadowContainer) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'class'
          ) {
            const isActive = shadowContainer.classList.contains('active');
            setChatWindowOpen(isActive);
          }
        });
      });

      observer.observe(shadowContainer, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [shadowContainer]);

  React.useEffect(() => {
    w.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];
    w.hsConversationsSettings = {
      loadImmediately: false,
      // welcomeMessage: `Hi! ${user.firstName} How can I help you?`,

      //  identificationEmail: 'visitor-email@example.com',
    };

    // Create script component.
    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    script.onload = function () {
      console.info('[Chat] HubSpot loaded');
    };

    script.onerror = function () {
      console.error('[Chat] HubSpot failed');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      w.hsConversationsOnReady = [];
    };
  }, [user]);

  React.useEffect(() => {
    eventRef.current = payload => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      const status = w.HubSpotConversations
        ? w.HubSpotConversations.widget.status()
        : 'HubSpotConversations object is missing';
      console.log('[Chat] Status:', status);

      w.HubSpotConversations?.on('unreadConversationCountChanged', payload => {
        console.log(`[Chat] New unread count is ${payload.unreadCount}!`);
      });
      w.HubSpotConversations?.on('conversationStarted', eventRef.current);

      // Event listener to update the chat button visibility
      w.HubSpotConversations?.on('widgetVisibilityChanged', payload => {
        setChatButtonVisible(payload.visible);
      });

      // Event listener to update the chat window status
      w.HubSpotConversations?.on('widgetOpen', () => {
        console.log('[Chat] Status: Opened', status);
        setChatWindowOpen(true);
      });
      w.HubSpotConversations?.on('widgetClose', () => {
        // setChatWindowOpen(false);
        console.log('[Chat] Status: Closed', status);
        setHasLoaded(false);
      });
    }

    return () => {
      w.HubSpotConversations?.off('conversationStarted', eventRef.current);
    };
  }, [hasLoaded, user]);

  const { HubSpotConversations } = window;

  const findHubspotButton = () => {
    const buttonByTestId = document.querySelector(
      '[data-test-id="chat-widget-launcher"]',
    );
    // const buttonByClass = document.querySelector(
    //   '.IconLauncher__BaseLauncher-sxyez0-0.IconLauncher__CircleLauncher-sxyez0-2.diFQJd.reagan--widget-loaded',
    // );

    return buttonByTestId; //|| buttonByClass;
  };

  const openHandler = React.useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      if (user?.email) {
        setEmail(); // Set email before opening the chat.
        getEmail(); // Get email before opening the chat.
      }

      if (w.HubSpotConversations && w.HubSpotConversations?.widget) {
        const status = w.HubSpotConversations.widget.status();
        console.log('[Chat] Widget Status (openHandler):', status);
        if (status.loaded) {
          w.HubSpotConversations.widget.refresh();
          w.HubSpotConversations.widget.load({
            widgetOpen: true,
            openToNewThread: false,
          });
          // w.HubSpotConversations.widget.open();
          console.log(
            '[Chat] Status (openHandler.status.loaded setChatVisible):',
            status,
          );
          setChatVisible(true);
          // setChatButtonVisible(true); // Hide chat button when chat window is open
          // setChatWindowOpen(true); // Set chat window open state
        } else {
          w.HubSpotConversations.widget.load({
            widgetOpen: true,
            openToNewThread: false,
          });
          w.HubSpotConversations.widget.open();
          setChatVisible(true);
          // setChatButtonVisible(true); // Hide chat button when chat window is open
          // setChatWindowOpen(true); // Set chat window open state
          console.log('[Chat] Status (openHandler)', status);
        }
      }
    },
    [hasLoaded, HubSpotConversations],
  );

  const closeHandler = React.useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      const status = w.HubSpotConversations.widget.status();
      console.log('[Chat] Status (closeHandler)', status);

      if (status.loaded) {
        console.log('[Chat] HubSpotConversations:', HubSpotConversations);
        w.HubSpotConversations.widget.remove();
        setHasLoaded(false);
        setChatVisible(false);
        // setChatButtonVisible(false); // Show chat button when chat window is closed
        // setChatWindowOpen(false); // Set chat window closed state
      }
    },
    [hasLoaded, HubSpotConversations],
  );

  React.useEffect(() => {
    const hubspotButton = findHubspotButton();

    const handleClick = event => {
      event.stopPropagation();
      closeHandler(event);
      isWidgetLoaded();
    };

    if (hubspotButton) {
      hubspotButton.addEventListener('click', handleClick);
      console.log('[Chat] hubspotButton:');
    }

    return () => {
      if (hubspotButton) {
        hubspotButton.removeEventListener('click', handleClick);
      }
    };
  }, [closeHandler]);

  return {
    isWidgetLoaded,
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
    chatVisible,
    chatButtonVisible,
    chatWindowOpen,
  };
};
export default useHubspotChat;
