import { format } from 'date-fns';
import React from 'react';
import { TwTheme } from '../../../../Tw';
import { getBestContrastColor } from '../../../common/color.helper';
import { getDimensionColor } from '../../store/workforce.helper';

const waveColors = TwTheme.extend.colors.wave;

export type WorkforceDeadlineTag = {
  deadline: Date;
  isScheduled: boolean;
};

const WorkforceDeadlineTag: React.FC<WorkforceDeadlineTag> = props => {
  let [background, color] = getDimensionColor(props.deadline.toString());

  if (!props.isScheduled) {
    background = waveColors.unscheduled;
    color = getBestContrastColor(waveColors.unscheduled);
  }

  const tooltip = props.deadline.toLocaleString();

  return (
    <div
      data-component="TableLegend"
      className={`p-1 px-2 text-xs flex items-center`}
      // style={{
      //   background,
      //   color,
      // }}
      title={tooltip}
    >
      <div
        data-component="TableLegend"
        className={`ltr:mr-2 rtl:ml-2 w-5 h-5 rounded-md`}
        style={{
          borderColor: color,
          background: background,
        }}
      />

      <span
        // title={`Value Raw:`}
        data-component="tableValue"
        className="flex-1 select-text"
      >
        {format(props.deadline, 'MMM dd HH:mm')}
      </span>
    </div>
  );
};

export default WorkforceDeadlineTag;
