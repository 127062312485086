import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getHeatmapBucketByValue } from '../common/heatmap.helper';
import useFormatter from '../common/useFormatter';
import InboxZero from '../components/InboxZero';
import { StatListHeader } from '../components/stats/StatListHeader';
import { StatListItem } from '../components/stats/StatListItem';
import PanelContainer from '../containers/PanelContainer';
import { getAssignmentStatItemMetricDescriptorsMap } from './assignmentStats.default';

import {
  assignmentStatsBuckets,
  assignmentStatsItemMetric,
  assignmentStatsLocationsByBay,
  assignmentStatsRangeDescriptor,
} from './assignmentStats.state';
import { AssignmentLocationItemStat } from './assignmentStats.type';

export type AssignmentStatsLocationInfoFullProps = {
  locationId: string;
};

const AssignmentStatsLocationInfoFull: React.FC<
  AssignmentStatsLocationInfoFullProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const locationMap = useRecoilValue(assignmentStatsLocationsByBay);
  const rangeDescriptor = useRecoilValue(assignmentStatsRangeDescriptor);
  const buckets = useRecoilValue(assignmentStatsBuckets);
  const selectedMetric = useRecoilValue(assignmentStatsItemMetric);

  const metricDescriptors = getAssignmentStatItemMetricDescriptorsMap(
    t,
    formatter,
  );
  const locationData = locationMap?.[props.locationId];
  const sectionTitle = t`Item Info`;

  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty location`} message_helper={`(No Items)`} />
  );

  if (_.isNil(locationData)) {
    return <EmptyPlaceholder />;
  }

  function getMetrics(locationItem: AssignmentLocationItemStat) {
    return _.map(metricDescriptors, descriptor => {
      const valueRaw = _.get(locationItem, descriptor.path);
      if (_.isNil(valueRaw)) return null;

      const valueString =
        _.isFunction(descriptor.format) && _.isNumber(valueRaw)
          ? descriptor.format(valueRaw)
          : valueRaw?.toString();
      let color: string = null;
      let background: string = null;

      if (descriptor.type === selectedMetric) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bucket = getHeatmapBucketByValue({
          data: locationItem,
          metricDescriptor: descriptor,
          rangeDescriptor,
          buckets: _.keyBy(buckets, b => b.id),
        });
        background = bucket?.color;
        color = bucket?.textColor;
      }

      return (
        <StatListItem
          key={`item-${locationItem.item?.id}-${descriptor.type}`}
          title={descriptor.title}
          value={valueString}
          multiline
          legendColor={background}
          valueStyle={{
            color: background,
            borderColor: background,
            borderWidth: background ? 1 : 0,
          }}
        />
      );
    });
  }

  function getItems(items: AssignmentLocationItemStat[]) {
    return _(items)
      .sortBy(item => -item?.item?.sku)
      .map(row => {
        const p = row.item;
        return (
          <>
            <StatListItem
              key={`item-${p.id}`}
              title={p.sku}
              labelValueType={'itemId'}
              labelValueFilters={{
                consignee: p.consignee,
                sku: p.sku,
              }}
            />
            {getMetrics(row)}
          </>
        );
      })
      .value();
  }

  return (
    <PanelContainer
      id="panel-assignment-stats-item"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      {!_.isEmpty(locationData) && (
        <>
          <StatListHeader title={t`Item`} />
          {getItems(locationData)}
        </>
      )}
    </PanelContainer>
  );
};

export default AssignmentStatsLocationInfoFull;
