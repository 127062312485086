import * as React from 'react';
const BoxXIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BoxXIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4L12.04,34v60l51.96,30,51.96-30v-60S64,4,64,4ZM92.06,91.78c-2.13,2.13-5.58,2.13-7.7,0l-20.22-20.22-21.18,21.18c-2.13,2.13-5.58,2.13-7.7,0-2.13-2.13-2.13-5.58,0-7.7l21.18-21.18-20.22-20.22c-2.13-2.13-2.13-5.58,0-7.7,2.13-2.13,5.58-2.13,7.7,0l20.22,20.22,19.26-19.26c2.13-2.13,5.58-2.13,7.7,0s2.13,5.58,0,7.7l-19.26,19.26,20.22,20.22c2.13,2.13,2.13,5.58,0,7.7Z"
    />
  </svg>
);
export default BoxXIcon;
