const MenuBarsLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuBarsLeftIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M59.66,34.06h48.67c2.92,0,5.29,2.37,5.29,5.29s-2.37,5.29-5.29,5.29h-48.67c-2.92,0-5.29-2.37-5.29-5.29s2.37-5.29,5.29-5.29ZM59.66,58.71h48.67c2.92,0,5.29,2.37,5.29,5.29s-2.37,5.29-5.29,5.29h-48.67c-2.92,0-5.29-2.37-5.29-5.29s2.37-5.29,5.29-5.29ZM59.66,83.36h48.67c2.92,0,5.29,2.37,5.29,5.29s-2.37,5.29-5.29,5.29h-48.67c-2.92,0-5.29-2.37-5.29-5.29s2.37-5.29,5.29-5.29Z"
    />
    <path
      strokeWidth={0}
      d="M19.96,64l16.01-26.74c.32-.54.42-1.34.24-2.04-.17-.7-.58-1.15-1.03-1.15h-9.25c-.31,0-.61.22-.82.59l-15.68,28.07c-.4.72-.4,1.83,0,2.55l15.68,28.07c.21.38.51.59.82.59h9.25c.45,0,.86-.46,1.03-1.15.17-.7.08-1.5-.24-2.04l-16-26.75Z"
    />
  </svg>
);
export default MenuBarsLeftIcon;
