import {
  ImportJobSortOption,
  SortDirection,
  useLoadImportJobsQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cn } from '../../../../common/utils';
import { HelperMessage } from '../../../../components/HelperMessage';
import { ImportFileFooterStats } from '../../../../components/ImportFileFooterStats';
import { Button } from '../../../../components/actions/Button';
import { ButtonGroup } from '../../../../components/actions/ButtonGroup';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import { ContainerCol } from '../../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import { importTriggeredBySim } from '../../../../store/global.state';
import {
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../../../store/warehouse.state';
import useUpdateLayoutImport from '../hooks/useUpdateLayoutImport';
import { converterLayoutData } from '../store/converter.state';

const StepSource: React.FC = () => {
  const { t } = useTranslation('dataset');
  const navigate = useNavigate();
  const whId = useRecoilValue(warehouseSelectedId);
  const saveConverterIdForSettings = useSetRecoilState(importTriggeredBySim);
  const [document, setDocument] = useRecoilState(converterLayoutData);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const updateLayoutImport = useUpdateLayoutImport();

  const { data, loading, error } = useLoadImportJobsQuery({
    variables: {
      filter: { targetId: [document.id] },
      sortBy: {
        field: ImportJobSortOption.UPDATED_AT,
        direction: SortDirection.DESC,
      },
      page: { skip: 0, limit: 1 },
    },
  });

  const changeTitle = (title: string) => {
    setDocument({ ...document, title: title });
    updateLayoutImport({ layoutImportId: document.id, title: title });
  };

  const changeDescription = (description: string) => {
    setDocument({ ...document, description: description });
    updateLayoutImport({
      layoutImportId: document.id,
      description: description,
    });
  };
  const proceedWithImport = () => {
    saveConverterIdForSettings(document.id);
    navigate(`/wh/i/${whId}/import/layout`);
  };

  if (!document) return null;

  return (
    <PanelBody className="h-full">
      <ContainerCol overflow>
        <ScreenTitle isSticky title={t`Data Source`} subtitle={t`Import`} />
        <HelperMessage hasPadding className="mt-2 pb-0">
          <HelperMessage iconAction={Icon.ArrowBottom}>
            {t`The data source file for this layout design is`}
          </HelperMessage>
        </HelperMessage>

        <div className="my-2 flex-1 px-2">
          <ButtonGroup>
            <div
              data-component="inputFileName"
              title={document.inputFileName}
              className="bg-app-panel min-w-[8rem] flex-1 select-text truncate rounded-l px-2 py-0.5 xl:min-w-[16rem]"
            >
              {document.inputFileName}
            </div>
            <Button
              label={t`Re-Upload`}
              // label={t(`Re-Import: {{filename}}`, {
              //   filename: document.inputFileName,
              // })}
              onPress={proceedWithImport}
              buttonType="primary"
              buttonSize="xs"
              className="w-auto min-w-[5rem] rounded-l-none rounded-r"
              hasIconBefore
              buttonIcon={<Icon.Reload className={`h-5 w-5 fill-current`} />}
              full
            />
          </ButtonGroup>

          <div className="my-2">
            <ButtonGroup>
              <Button
                label={t`Re-Import`}
                onPress={() => {
                  navigate(
                    `/wh/i/${whId}/import/layout/${data?.importJobs?.content?.[0].id}`,
                  );
                }}
                buttonType="primary"
                buttonSize="xs"
                className={cn(
                  'w-auto min-w-[5rem] rounded',
                  // 'rounded-l-none rounded-r'
                )}
                hasIconBefore
                buttonIcon={<Icon.Refresh className={`h-5 w-5 fill-current`} />}
                full
                isDisabled={_.isNil(data?.importJobs?.content?.[0].id)}
              />
            </ButtonGroup>
          </div>
        </div>

        <ImportFileFooterStats
          className="mb-2 px-2"
          updatedDate={document.updatedAt}
          createdDate={document.createdAt}
          importSource={document.inputFileName}
        />
      </ContainerCol>
    </PanelBody>
  );
};

export default StepSource;
