import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ButtonSidebarToggle } from '../components/ButtonSidebarToggle';
import { DashboardActionbar } from '../components/DashboardActionbar';
import { DemoHelper } from '../components/helpers/DemoHelper';
import { ImportHelper } from '../components/helpers/ImportHelper';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { importTriggeredBySim } from '../store/global.state';
import WarehouseContainer from '../warehouse/WarehouseContainer';
import DataDashboardAssignments from './sections/DataDashboardAssignments';
import DataDashboardDatasetObject from './sections/DataDashboardDatasetObject';
import DataDashboardFiles from './sections/DataDashboardFiles';
import DataDashboardItemSets from './sections/DataDashboardItemSets';
import DataDashboardOrderSets from './sections/DataDashboardOrderSets';
import DataDashboardSidebar from './sidebars/DataDashboardSidebar';
import { DataDashboardMenuItemId } from './store/dashboard.types';

export type DataDashboardProps = {
  selectedMenuId: DataDashboardMenuItemId;
};

export const DataDashboard: React.FC<DataDashboardProps> = props => {
  const { t } = useTranslation('app');
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const { selectedMenuId } = props;
  const importTemplatePath = '/app/assets/importer/csv';
  const importTemplateVersion = 'v24.04.001';
  const importTemplateNameMetric = `WareBee-Templates-${importTemplateVersion}`;

  useEffect(() => {
    // clear import from simulation state
    setImportTriggerSimId(null);
  });

  const showDataAll = selectedMenuId === 'data-dash-data-all';

  const showAssignment =
    selectedMenuId === 'data-dash-data-all' ||
    selectedMenuId === 'data-dash-assignment';

  const showOrderSet =
    selectedMenuId === 'data-dash-data-all' ||
    selectedMenuId === 'data-dash-order';

  const showItemSet =
    selectedMenuId === 'data-dash-data-all' ||
    selectedMenuId === 'data-dash-items';

  const showDataSet = selectedMenuId === 'data-dash-dataset-object';
  const showDataDashboard = selectedMenuId === 'data-dash-data-all';
  const showFiles = selectedMenuId === 'data-dash-files';

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar>
        <ButtonSidebarToggle
          title={t`Data Menu`}
          noTitle
          icon={Icon.MenuBars3Bars}
          buttonSize={'sm'}
          sidebarID="sidebar-menu-dashboard-data"
        />
        <div className={classNames('flex-1 ltr:ml-2')}>{t`Data Dashboard`}</div>
        {showDataDashboard && (
          <Link
            className={classNames(
              'flex items-center',
              'bg-app-panel',
              'text-menu-action-text hover:text-menu-hover',
              'border-app-background rounded border',
              'px-4 py-2',
              'shadow-xl',
            )}
            to={{
              pathname: `${importTemplatePath}/${importTemplateNameMetric}.zip`,
            }}
            target="_blank"
          >
            {t`Download Data templates`}
            <Icon.CloudDownload
              className={classNames(
                'h-5 w-5',
                'ltr:ml-2 rtl:mr-2',
                'fill-current',
              )}
            />
          </Link>
        )}
      </DashboardActionbar>
      <ContainerMain data-component="data-container">
        <DataDashboardSidebar selectedId={selectedMenuId} />
        <Container hasExtraPadding col hasOverflowY>
          {showDataAll && (
            <>
              <ImportHelper
                headerMode
                dataType={['items', 'assignment', 'orders']}
              />
              <DemoHelper />
            </>
          )}
          {showAssignment && <DataDashboardAssignments />}
          {showItemSet && <DataDashboardItemSets />}
          {showOrderSet && <DataDashboardOrderSets />}
          {showDataSet && (
            <>
              <ImportHelper headerMode dataType={['activityFeed']} />
              <DataDashboardDatasetObject />
            </>
          )}
          {showFiles && <DataDashboardFiles />}
        </Container>
      </ContainerMain>
    </WarehouseContainer>
  );
};

export default DataDashboard;
