import * as React from 'react';
const DesignerAreasConnectIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAreasConnectIcon"
    {...props}
  >
    <g opacity={0.75}>
      <polygon
        strokeWidth={0}
        points="64.66 92.46 59.12 92.46 64.66 100.35 64.66 92.46"
      />
      <polygon
        strokeWidth={0}
        points="42.31 92.46 31.11 92.46 54.21 125.36 64.66 125.36 64.66 124.29 42.31 92.46"
      />
      <polygon
        strokeWidth={0}
        points="56.32 92.46 45.11 92.46 64.66 120.3 64.66 104.34 56.32 92.46"
      />
      <polyline
        strokeWidth={0}
        points="26.2 125.36 37.41 125.36 14.3 92.46 3.1 92.46"
      />
      <polygon
        strokeWidth={0}
        points="28.31 92.46 17.1 92.46 40.21 125.36 51.41 125.36 28.31 92.46"
      />
      <polygon
        strokeWidth={0}
        points="2.77 95.98 2.77 111.94 12.2 125.36 23.4 125.36 2.77 95.98"
      />
      <polygon
        strokeWidth={0}
        points="2.77 125.36 9.4 125.36 2.77 115.92 2.77 125.36"
      />
    </g>
    <path
      strokeWidth={0}
      d="M2.77,2.91v87.55h61.89V2.91H2.77ZM36.9,61.11h-8.52s11.39-11.4,11.39-11.4H15.42s0-6.04,0-6.04h24.35s-11.36-11.4-11.36-11.4h8.52s14.38,14.42,14.38,14.42l-14.4,14.42Z"
    />
    <path
      strokeWidth={0}
      d="M67.93,65.67v59.7h57.58v-59.7h-57.58ZM115.32,101.49h-24.35l11.36,11.4h-8.52s-14.38-14.42-14.38-14.42l14.4-14.42h8.52l-11.39,11.39h24.35s0,6.04,0,6.04Z"
    />
    <path
      strokeWidth={0}
      d="M67.93,2.64v59.7h57.58V2.64h-57.58ZM111.43,36.05l-14.41,14.38-14.42-14.4v-8.52l11.39,11.39V14.54s6.04,0,6.04,0v24.35l11.4-11.36v8.52Z"
    />
  </svg>
);
export default DesignerAreasConnectIcon;
