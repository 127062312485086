import {
  MappingErrorFragment,
  useValidatePreviewMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { cloneWithoutTypename } from '../common/utils';
import {
  createMappingSpecInput,
  createValidationErrorFromMessage,
  processValidationErrors,
} from './store/import.helper';
import {
  importJob,
  importValidationErrors,
  importValidationStatus,
} from './store/import.state';
import { MappingSettings } from './store/import.types';

export type ValidatePreviewParams = {
  mappingSettings: MappingSettings<Object>;
};

function useValidatePreview() {
  const { t } = useTranslation('errors');
  const [validatePreview] = useValidatePreviewMutation();
  const errorTitleValidation = t`Cannot get validation results`;

  const initValidation = useRecoilCallback(
    ({ set }) =>
      async (params: ValidatePreviewParams) => {
        set(importValidationErrors, null);
        set(importValidationStatus, AsyncLoadStatus.Loading);
      },
  );

  const handleValidationError = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (details?: string, stack?: string) => {
        console.error(errorTitleValidation, details, stack);
        const customError = createValidationErrorFromMessage(details);

        set(importValidationErrors, [customError]);
        set(importValidationStatus, AsyncLoadStatus.Error);
        // set(errorAppender, {
        //   id: nanoid(),
        //   title: errorTitleValidation,
        //   details: details,
        //   callStack: stack,
        // });
      },
  );

  const validate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ValidatePreviewParams) => {
        const job = await snapshot.getPromise(importJob);
        try {
          const { data: validationData, errors: requestErrors } =
            await validatePreview({
              variables: {
                jobId: job.id,
                mapping: createMappingSpecInput(params.mappingSettings),
                transformation: cloneWithoutTypename(
                  params.mappingSettings?.transformation,
                ),
                page: {
                  limit: 1000,
                },
              },
            });

          if (requestErrors) {
            handleValidationError(
              _.map(requestErrors, e => e.message).join('; '),
            );
            return _.map(requestErrors, e => e.message);
          }

          const errors: MappingErrorFragment[] =
            validationData.validateImportJobPreviewMapping.content;
          set(
            importValidationErrors,
            processValidationErrors({
              mappingSettings: params.mappingSettings,
              errors: errors,
            }),
          );
          set(importValidationStatus, AsyncLoadStatus.Ok);
          return errors;
        } catch (ex) {
          handleValidationError(ex?.message ?? ex);
          return [ex.message];
        }
      },
  );

  return async (params: ValidatePreviewParams) => {
    await initValidation(params);
    return await validate(params);
  };
}

export default useValidatePreview;
