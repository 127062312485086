import React from 'react';
import { useRecoilValue } from 'recoil';
import { bayViewerHoveredLocation } from '../../../layout/bayViewer/store/bayViewer.state';
import LocationDetailedHtmlTooltip from '../../../layout/tooltip/LocationDetailedHtmlTooltip';
import TooltipWrapper from '../../../layout/tooltip/TooltipWrapper';
import { simulationBayViewerHoveredLocation } from '../store/simulationBayViewer.state';

export type SimulationLocationHtmlTooltipLayerProps = {};

const SimulationLocationHtmlTooltipLayer: React.FC<
  SimulationLocationHtmlTooltipLayerProps
> = props => {
  const locationSim = useRecoilValue(simulationBayViewerHoveredLocation);
  const locationViewer = useRecoilValue(bayViewerHoveredLocation);
  const location = locationSim ?? locationViewer;

  if (!location) return null;
  return (
    <TooltipWrapper stageId="simulation-bay-view">
      <LocationDetailedHtmlTooltip location={location} />
    </TooltipWrapper>
  );
};

export default SimulationLocationHtmlTooltipLayer;
