import { ItemFilterType } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import {
  FilterFieldDataType,
  FilterFieldEditorType,
} from '../../../common/types';
import { StackingPolicyFilterConfig } from './stackingPolicy.types';

export const STACKING_POLICY_DEFAULT_RULE_ID =
  'STACKING_POLICY_DEFAULT_RULE_ID';

export const getStackingPolicyFilterConfig: (
  t: TFunction<'dataset'>,
) => StackingPolicyFilterConfig = t => [
  {
    type: ItemFilterType.CONSIGNEE,
    title: t(`Client`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SKU,
    title: t(`Item`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SKU_GROUP,
    title: t(`Item Group`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SUB_GROUP,
    title: t(`Item Sub Group`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.TRANSPORT_CLASS,
    title: t(`Transport Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.STOCK_CATEGORY,
    title: t(`Stock Category`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.STORAGE_CLASS,
    title: t(`Storage Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.POLLUTION_CLASS,
    title: t(`Pollution Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.NET_WEIGHT,
    title: t('Net Weight', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.LENGTH,
    title: t('Length', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.WIDTH,
    title: t('Width', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.HEIGHT,
    title: t('Height', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.VOLUME,
    title: t('Volume', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
];
