import * as React from 'react';
const DesignerAreasDeleteIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAreasDeleteIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M62.5,15.52c-13.5-13.5-35.46-13.5-48.96,0-13.5,13.5-13.5,35.46,0,48.96,13.5,13.5,35.46,13.5,48.96,0,13.5-13.5,13.5-35.46,0-48.96ZM47.91,53.65l-9.89-9.89-10.36,10.36c-1.04,1.04-2.73,1.04-3.77,0-1.04-1.04-1.04-2.73,0-3.77l10.36-10.36-9.89-9.89c-1.04-1.04-1.04-2.73,0-3.77,1.04-1.04,2.73-1.04,3.77,0l9.89,9.89,9.41-9.41c1.04-1.04,2.73-1.04,3.77,0,1.04,1.04,1.04,2.73,0,3.77l-9.41,9.41,9.89,9.89c1.04,1.04,1.04,2.73,0,3.77-1.04,1.04-2.73,1.04-3.77,0Z"
    />
    <path
      strokeWidth={0}
      d="M124,124v-16h-6v-52h6v-16h-16v6h-23.89c-.17,1.35-.4,2.69-.69,4h24.58v6h6v52h-6v6h-52v-6h-6v-22.91c-1.31.35-2.65.65-4,.89v22.02h-6v16h16v-6h52v6h16Z"
    />
    <path
      strokeWidth={0}
      d="M106,58h-25.09c-4.42,10.58-12.62,19.18-22.9,24.13v23.87h48s0-48,0-48Z"
    />
  </svg>
);
export default DesignerAreasDeleteIcon;
