import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimulationsListPanel from '../../components/SimulationsListPanel';
import { itemSetDocument } from '../store/itemSet.state';

const ItemSetSimulationPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(itemSetDocument);
  const simulations = document?.simulationsWith?.content;
  return (
    <SimulationsListPanel subtitle={t`Item set`} simulations={simulations} />
  );
};

export default ItemSetSimulationPanel;
