import {
  ImportJobsConnectionFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { atom } from 'recoil';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { ImportJobsDataColumn } from './importJobs.types';

const getKey = (postfix: string) => `warebee-import-jobs-${postfix}`;

export const importJobsData = atom<ImportJobsConnectionFragment>({
  key: getKey('data'),
  default: null,
});

export const importJobsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('data-status'),
  default: AsyncLoadStatus.None,
});

export const importJobsDataTableState = atom<
  DataTableState<ImportJobsDataColumn>
>({
  key: getKey('data-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      createdAt: SortDirection.ASC,
    },
  },
});
