import { BarTooltipProps } from '@nivo/bar';
import { format, isWeekend } from 'date-fns';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../../Tw';
import {
  TooltipItem,
  TooltipItemProps,
} from '../../../components/stats/TooltipItem';
import { EventGroup } from '../../store/simulation.helper';
import {
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
} from '../../store/workforce.types';
import { TimelineDatum } from './WorkforceAgentTimeline';

const eventGroupsColor = TwTheme.extend.colors.eventGroups;
const eventGroupsColorTxt = TwTheme.extend.colors.eventGroupsTxt;
const eventGroupsBackgroundColor = TwTheme.extend.colors.eventGroups;

export type WorkforceAgentTimelineTooltipProps = {
  datum: BarTooltipProps<TimelineDatum>;
  totalX: number;
  series: string[];

  formatValue: (value: number) => string;
  getDimensionTitle: (dimensionKey: string) => string;
  getDimensionColor: (dimensionKey: string) => [string, string];
};

const WorkforceAgentTimelineTooltip: React.FC<
  WorkforceAgentTimelineTooltipProps
> = props => {
  const { datum } = props;
  const { t } = useTranslation('simulation');
  const hoveredKey = datum.id as EventGroup;
  const showOnLeft = props.totalX > 0 && datum.index / props.totalX > 0.66;

  const overtimeRaw = datum.data[DRILL_KEY_OVERTIME] ?? 0;
  const idleRaw = datum.data[DRILL_KEY_IDLE] ?? 0;
  const totalRaw = datum.data[DRILL_KEY_OVERTIME] ?? 0;

  const date = new Date(datum.data.date);
  const weekend = isWeekend(date);

  const colors = props.getDimensionColor(datum.id?.toString());
  return (
    <div
      key={datum.id}
      data-component="AgentTimelineTooltip"
      className="bg-app-panel-dark/90 backdrop-filter backdrop-blur absolute text-sm whitespace-nowrap"
      style={{
        left: showOnLeft ? undefined : 16,
        right: showOnLeft ? 16 : undefined,
        top: -64,
      }}
    >
      <div className="min-w-tooltip">
        <div className="flex items-center p-2 ltr:pl-3 rtl:pr-3 text-xs bg-menu-active text-menu-active-text uppercase">
          {t`Day Analysis`}
        </div>
        <div
          className={`flex items-center py-2 px-2 text-sm font-bold text-start bg-app-panel-dark`}
        >
          <span className="ltr:pl-1 rtl:pr-1 flex-1">
            {format(date, 'dd MMMM, yyyy')}
          </span>
          <span
            className={`ml-3 py-0.5 px-2 rounded uppercase border ${
              weekend ? 'border-menu-300 text-menu-active' : 'border-menu-500'
            }`}
          >
            {format(date, 'EE')}
          </span>
        </div>
      </div>

      <div className="px-1 divide-y divide-opacity-50 divide-solid divide-menu-400 border-t border-menu-300">
        {overtimeRaw > 0 && (
          <TooltipItem
            key={`overtime-${datum.id}`}
            // isActive={true}
            title={t`Overtime`}
            value={props.formatValue(overtimeRaw)}
            color={eventGroupsColorTxt.overtime}
            backgroundColor={eventGroupsColor.overtime}
            tooltipLegend
          />
        )}
        {/* {idleRaw > 0 && (
          <TooltipItem
            key={`idle-${datum.id}`}
            // isActive={true}
            title={t`Idle`}
            value={props.formatValue(idleRaw)}
            color={eventGroupsColorTxt.idle}
            backgroundColor={eventGroupsColor.idle}
            tooltipLegend
          />
        )} */}

        {/* <TooltipItem
          key={`selected-${datum.id}`}
          isActive={true}
          title={props.getDimensionTitle(datum.id?.toString())}
          value={props.formatValue(datum.value)}
          color={colors[1] ?? 'white'}
          backgroundColor={colors[0]}
        /> */}

        {_(props.series)
          .filter(key => {
            const v = datum.data[key];
            return _.isNumber(v) && v > 0;
          })
          .map((key: EventGroup) => {
            const id = nanoid();
            const [backgroundColor, color] = props.getDimensionColor(key);

            const params: TooltipItemProps = {
              isActive: key === hoveredKey,
              title: props.getDimensionTitle(key),
              color,
              backgroundColor,
              value: props.formatValue(datum.data?.[key] as number),
            };

            return (
              <TooltipItem
                key={`${datum.id}-${datum.data.date}-${id}`}
                {...params}
                tooltipLegend
              />
            );
          })
          .reverse()
          .value()}
      </div>
    </div>
  );
};

export default WorkforceAgentTimelineTooltip;
