import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import AuthHeader from './AuthHeader';

export const UserHeader = () => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <header
      data-component="UserHeader"
      id={id}
      className={classNames('flex items-center p-2 text-sm')}
    >
      <AuthHeader />
    </header>
  );
};
