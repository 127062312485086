import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type PolicyWellProps = {
  className?: string;
  classNameChildren?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  isCentred?: boolean;
  fullHeight?: boolean;
};

export const PolicyWell = ({
  children,
  className,
  classNameChildren,
  isDisabled,
  isCentred,
  fullHeight,
}: PolicyWellProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="PolicyWell"
      id={id}
      className={classNames(
        'px-0.5 py-1 md:p-1 lg:px-1 lg:py-2 xl:p-2',
        // 'rounded',
        {
          'bg-app-panel-dark/75': !isDisabled,
          'bg-issue-transparent bg-app-policy-well': isDisabled,
        },
        { 'flex flex-col justify-center': isCentred },
        { 'flex-1': fullHeight },
        className,
      )}
    >
      <div
        data-component={`PolicyWellChildren`}
        className={classNames(
          'flex flex-wrap flex-col',
          'p-1',
          classNameChildren,
        )}
      >
        {children}
      </div>
    </div>
  );
};
