import * as React from 'react';
const MeasurementRulerIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MeasurementRulerIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M29.52,8.49H5.53c-.84,0-1.53.69-1.53,1.53v107.97c0,.84.69,1.53,1.53,1.53h23.99c.84,0,1.53-.69,1.53-1.53v-8.19h-8.35c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h8.35v-6.78h-6.78c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h6.78v-6.78h-6.78c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h6.78v-6.78h-8.35c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h8.35v-6.78h-6.78c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h6.78v-6.78h-6.78c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h6.78v-6.78h-8.35c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h8.35v-6.78h-6.78c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h6.78v-6.78h-6.78c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h6.78v-6.78h-8.35c-.85,0-1.53-.69-1.53-1.53s.68-1.53,1.53-1.53h8.35v-8.19c0-.84-.69-1.53-1.53-1.53Z"
    />
    <path
      strokeWidth={0}
      d="M123.4,116.76L40.48,35.64c-.47-.35-1.09-.41-1.61-.15-.52.26-.86.8-.86,1.38v81.12c0,.84.69,1.53,1.53,1.53h5.6v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h7.18v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h7.18v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h7.18v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h7.18v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h7.18v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h7.18v-6.06c0-.85.68-1.53,1.53-1.53s1.53.69,1.53,1.53v6.06h12.79c.66,0,1.24-.43,1.45-1.04.21-.63,0-1.32-.52-1.71ZM94.75,106.28c-.2.56-.72.95-1.32.95h-41.92c-.77,0-1.39-.63-1.39-1.39v-42.76c0-.53.31-1,.78-1.24.47-.24,1.05-.18,1.46.14l41.92,42.75c.47.36.67.99.47,1.56Z"
    />
  </svg>
);
export default MeasurementRulerIcon;
