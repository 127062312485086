import { useApolloClient } from '@apollo/client';
import {
  BatchJobStatus,
  LoadOrderedQuantitySummaryDocument,
  LoadOrderedQuantitySummaryQuery,
  LoadOrderedQuantitySummaryQueryVariables,
  useRunGenerateQuantityReportMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { GEN_REPORT_JOB_REFRESH_INTERVAL } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { simulationCurrentId } from '../store/simulation.state';
import {
  orderedQuantityReport,
  orderedQuantityReportLoadStatus,
} from '../store/simulationReport.state';

function useLoadOrderedQuantityReport() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load ordered quantity report`;
  const [runJob] = useRunGenerateQuantityReportMutation();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();
  let timeoutId = null;

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(orderedQuantityReportLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadJob = useRecoilCallback(
    ({ snapshot, set }) =>
      async (simulationId: string) => {
        const query = client.watchQuery<
          LoadOrderedQuantitySummaryQuery,
          LoadOrderedQuantitySummaryQueryVariables
        >({
          query: LoadOrderedQuantitySummaryDocument,
          variables: { simulationId },
        });

        function handleError(details?, stack?) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(orderedQuantityReportLoadStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const report = data?.simulation?.orderedQuantityReport;
            if (
              report?.status === BatchJobStatus.FAILED ||
              report?.status === BatchJobStatus.TERMINATED
            ) {
              handleError();
            }

            if (
              report.status === BatchJobStatus.CALCULATING ||
              report.status === BatchJobStatus.CREATED
            ) {
              timeoutId = setTimeout(() => {
                loadJob(simulationId);
              }, GEN_REPORT_JOB_REFRESH_INTERVAL);
            }

            if (report.status === BatchJobStatus.READY) {
              set(orderedQuantityReport, report);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  const startCheck = useRecoilCallback(({ snapshot, set }) => async () => {
    const simulationId = await snapshot.getPromise(simulationCurrentId);

    function handleError(details?, stack?) {
      set(errorAppender, {
        id: nanoid(),
        title: errorTitle,
        details: details,
        callStack: stack,
      });
      set(orderedQuantityReportLoadStatus, AsyncLoadStatus.Error);
    }

    try {
      const { data: jobData, errors: jobErrors } = await runJob({
        variables: { simulationId },
      });

      if (
        !_.isEmpty(jobErrors) ||
        _.isNil(jobData.generateSimulationOrderedQuantityReport?.id)
      ) {
        const details = _(jobErrors)
          .map(j => j.message)
          .join(';');
        handleError(details);
        return;
      }

      loadJob(simulationId);
    } catch (ex) {
      handleError(ex?.message ?? ex);
    }
  });

  async function call() {
    await initLoading();
    await startCheck();
  }

  function cancel() {
    observable?.unsubscribe();
    clearTimeout(timeoutId);
  }

  return [call, cancel] as const;
}

export default useLoadOrderedQuantityReport;
