import { FetchResult } from '@apollo/client';
import {
  CreateAppBillingSubscriptionDocument,
  CreateAppBillingSubscriptionMutation,
  CreateAppBillingSubscriptionMutationVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { billingClient } from '../../GraphQLClient';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getWarehouseToken } from '../store/appBilling.helper';
import {
  appBillingSubscription,
  appBillingSubscriptionError,
  appBillingSubscriptionLoadStatus,
} from '../store/appBilling.state';

function useCreateSubscription() {
  const { t } = useTranslation('app');
  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    set(appBillingSubscription, null);
    set(appBillingSubscriptionError, null);
    set(appBillingSubscriptionLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (priceId: string) => {
        let subscriptionResponse: FetchResult<
          CreateAppBillingSubscriptionMutation,
          Record<string, any>,
          Record<string, any>
        >;

        function handleError(message, details?) {
          console.error(message, details);
          set(appBillingSubscriptionError, message);
          set(appBillingSubscriptionLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const whId = await snapshot.getPromise(warehouseSelectedId);

        const tokens = await getWarehouseToken([whId], handleError);
        const whToken = _.head(tokens);

        if (_.isNil(whToken)) {
          handleError(t`Cannot get authorisation (Security Token)`, '');
          return;
        }

        try {
          subscriptionResponse = await billingClient.mutate<
            CreateAppBillingSubscriptionMutation,
            CreateAppBillingSubscriptionMutationVariables
          >({
            mutation: CreateAppBillingSubscriptionDocument,
            variables: {
              input: {
                priceId,
                warehouseToken: whToken,
              },
            },
          });

          if (subscriptionResponse.errors) {
            handleError(
              t`Cannot create subscription`,
              subscriptionResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }
        } catch (ex) {
          handleError(t`Error creating the subscription`, ex.message);
          return;
        }

        const data = subscriptionResponse.data.createAppBillingSubscription;
        if (data?.stripeError) {
          handleError(data?.stripeError.message, data?.stripeError);
        }

        set(appBillingSubscription, data.subscription);
        set(appBillingSubscriptionLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (priceId: string) => {
    await cleanupState();
    await loadCallback(priceId);
  };
}

export default useCreateSubscription;
