import * as React from 'react';
const PolicyComplianceWeightFillIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceWeightFillIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM63.74,97.59l-36.62-21.56,14-33.63,11.53-4.63c-.67-1.73-1.03-3.65-1.03-5.66,0-7.78,5.53-14.11,12.34-14.11s12.35,6.32,12.35,14.11c0,2.19-.44,4.26-1.22,6.11l8.98,4.04,16.8,33.74-37.14,21.59Z"
    />
    <path
      strokeWidth={0}
      d="M72.9,42.37c-1.94,3.33-16.33,3.16-18.24-.38l-7.56,3.29,16.52,7.83,14.89-7.92-5.6-2.82Z"
    />
    <path
      strokeWidth={0}
      d="M58.94,36.19h0c1.09,1.89,8.81,1.99,9.92.23.2-.31.38-.64.53-.99.42-.96.66-2.06.66-3.21,0-3.91-2.72-7.09-6.07-7.09s-6.07,3.18-6.07,7.09c0,1.06.2,2.06.55,2.96.14.35.3.68.49,1Z"
    />
    <path
      strokeWidth={0}
      d="M75.6,74.83l-1.25.72-1.54-6.86c-.03-.15-.15-.21-.27-.14l-1.45.84c-.09.05-.13.11-.21.19l-1.67,1.99c-.09.12-.12.22-.09.35l.5,2.21c.02.11.07.14.14.09l.12-.11,1.12-1.22.91,4.08-1.25.72c-.11.06-.19.24-.16.38l.47,2.08c.03.15.17.2.28.14l4.94-2.85c.12-.07.19-.24.16-.39l-.47-2.08c-.03-.14-.16-.2-.28-.13Z"
    />
    <path
      strokeWidth={0}
      d="M82.28,67.61c-.65-2.91-2-3.68-3.93-2.57-1.93,1.11-2.67,3.09-2.02,6l.32,1.44c.65,2.9,2.01,3.67,3.93,2.56,1.93-1.11,2.66-3.09,2.01-5.99l-.32-1.44ZM80,72.4c-.31.18-.56.1-.89-1.34l-.32-1.44c-.33-1.45-.16-1.77.15-1.95.31-.18.56-.1.88,1.36l.32,1.44c.32,1.44.17,1.76-.14,1.94Z"
    />
    <path
      strokeWidth={0}
      d="M83.28,67.04l.32,1.44c.65,2.9,2,3.68,3.93,2.56,1.93-1.11,2.67-3.09,2.02-5.99l-.32-1.44c-.65-2.91-2.01-3.68-3.94-2.57-1.93,1.11-2.66,3.09-2.01,6ZM85.88,63.67c.31-.18.57-.1.89,1.35l.32,1.44c.32,1.44.16,1.76-.15,1.94-.31.18-.56.1-.89-1.34l-.32-1.44c-.33-1.45-.16-1.77.15-1.95Z"
    />
  </svg>
);
export default PolicyComplianceWeightFillIcon;
