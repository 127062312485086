import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';

export type SubTitleProps = {
  className?: string;
  subtitle?: string;
};

export const SubTitle = ({ subtitle, className }: SubTitleProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <h2
      id={id}
      data-component="SubTitle"
      className={classNames(
        'pt-1 text-xs md:text-sm',
        'text-menu-h2',
        className,
      )}
      aria-label="Sub Title"
    >
      {subtitle}
    </h2>
  );
};
