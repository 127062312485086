import {
  AssignmentComplianceItemStatus,
  LayoutLevelLocationFragment,
  LayoutLocationWithMatchingFiltersFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../Tw';
import useGetNamedColor from '../../common/useGetNamedColor';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import {
  getAssignmentComplianceLocationColor,
  getAssignmentComplianceLocationStatus,
} from '../store/assignmentCompliance.helper';
import {
  assignmentComplianceLocationsByLevel,
  assignmentComplianceLocationsByLevelAll,
  assignmentComplianceSelectedIdentity,
  assignmentComplianceUseComplianceColors,
} from '../store/assignmentCompliance.state';
import { AP_FALLBACK_RULE_ID } from '../store/assignmentPolicy.default';
import { assignmentPolicyDocument } from '../store/assignmentPolicy.state';
import { simulationWizardSelectedStepId } from '../store/simulation.wizard.state';

const locationColor = TwTheme.extend.colors.location;
const heatmapColors = TwTheme.extend.colors.heatmap_compliance;
const opacity = '33';

const AssignmentComplianceLocationLayer: React.FC = () => {
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const selectedDetails = useRecoilValue(assignmentComplianceSelectedIdentity);
  const assignmentPolicy = useRecoilValue(assignmentPolicyDocument);
  const levelLocationLoadable = useRecoilValueLoadable(
    assignmentComplianceLocationsByLevel,
  );
  const levelLocationAllLoadable = useRecoilValueLoadable(
    assignmentComplianceLocationsByLevelAll,
  );

  const applyComplianceColor = useRecoilValue(
    assignmentComplianceUseComplianceColors,
  );
  const getNamedColor = useGetNamedColor();

  if (
    stepId !== 'compliance' ||
    levelLocationLoadable.state !== 'hasValue' ||
    levelLocationAllLoadable.state !== 'hasValue'
  )
    return null;

  let getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null;
  let getLabelColor: (
    location: LayoutLevelLocationFragment,
  ) => [string, string];
  let getGradientColors: (loc: LayoutLevelLocationFragment) => string[];

  const locationMap = levelLocationLoadable.getValue();
  const rules = _.keyBy(assignmentPolicy?.rules, r => r.id);

  /**
   * color  by COMPLIANT / NON_COMPLIANT status of location
   */

  if (applyComplianceColor) {
    //color
    getColor = (loc: LayoutLevelLocationFragment) =>
      getAssignmentComplianceLocationColor(
        locationMap?.[loc.locationId],
        selectedDetails,
      );
    // no-labels
    // no-gradient
  }

  /**
   * color  by Named / Dynamic  colors
   */
  if (!applyComplianceColor) {
    //color
    const locationAllMap: Record<
      string,
      LayoutLocationWithMatchingFiltersFragment
    > = levelLocationAllLoadable.contents ?? {};

    getGradientColors = (loc: LayoutLevelLocationFragment): string[] => {
      const ruleIndexes = locationAllMap?.[loc.locationId]?.allMatchingFilters;

      if (_.isEmpty(ruleIndexes)) return null;
      const ruleIds = _.map(ruleIndexes, i => assignmentPolicy?.rules[i]?.id);
      const ruleTitles = _.map(
        ruleIndexes,
        i => assignmentPolicy?.rules[i]?.title,
      );
      const ruleSet = new Set(ruleIds);

      let colors = _(ruleTitles)
        .map(title => getNamedColor(title ?? AP_FALLBACK_RULE_ID)?.[0])
        .compact()
        .value();

      const applyOpacity =
        !_.isNil(selectedDetails?.ruleId) &&
        !ruleSet.has(selectedDetails?.ruleId);

      if (applyOpacity) {
        colors = _.map(colors, c => `${c}${opacity}`);
      }
      return _.isEmpty(colors) ? null : colors;
    };

    getLabelColor = (loc: LayoutLevelLocationFragment): [string, string] => {
      const locationStatus = getAssignmentComplianceLocationStatus(
        locationMap?.[loc.locationId],
      );
      return locationStatus === AssignmentComplianceItemStatus.NONCOMPLIANT
        ? [heatmapColors.nonCompliant, heatmapColors.nonCompliantText]
        : null;
    };
  }

  return (
    <LocationLayer
      getColor={getColor}
      getLabelColor={getLabelColor}
      getGradientColors={getGradientColors}
      isCompliance
    />
  );
};

export default AssignmentComplianceLocationLayer;
