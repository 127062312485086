import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { InboxZero } from '../../components/InboxZero';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import OptimisationImplementPanel from '../panels/implement/OptimisationImplementPanel';
import { optimisationResult } from '../store/optimisation.state';

const SidebarOptimisationResultsItems: React.FC = () => {
  const { t } = useTranslation('simulation');
  const optimisation = useRecoilValue(optimisationResult);

  if (!optimisation) {
    return <InboxZero selfCenter hasIcon message={t`No Results Found`} />;
  }

  return (
    <OptimisationImplementPanel
      hasControls={true}
      optimisationId={optimisation.id}
    />
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-optimisation-results-implement',
    title: t`Implement Slotting`,
    loadingMessage: t`Loading Optimised Slotting plan`,
    size: 'xl',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps} width={140}>
      <SidebarOptimisationResultsItems />
    </SidebarContainer>
  );
};
