import React from 'react';
import { useRecoilValue } from 'recoil';
import BayLayer from '../../layout/viewer/layers/BayLayer';
import { simulationRoutingDisplayMode } from '../store/simulation.state';

const SimulationBayLayer: React.FC = () => {
  const routingMode = useRecoilValue(simulationRoutingDisplayMode);
  return <BayLayer hideTitle={routingMode !== 'none'} />;
};

export default SimulationBayLayer;
