import {
  LayoutSummaryFragment,
  OptimizationObjectiveType,
  OptimizationRunForDashboardFragment,
  OptimizationRunStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  formatDateTime,
  formatInteger,
  formatTimespan,
} from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { Stat } from '../../../components/stats/Stat';
import { StatGroup } from '../../../components/stats/StatGroup';
import { loadBalancingPolicyDocument } from '../../../loadBalancingPolicy/loadBalancingPolicy.state';

export type OptimisationStatsInfoProps = {
  opt: OptimizationRunForDashboardFragment;
  layoutSummary: LayoutSummaryFragment;
};

const OptimisationStatsInfo: React.FC<OptimisationStatsInfoProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const balancingPolicy = useRecoilValue(loadBalancingPolicyDocument);

  const balancingPolicyRules = _.keyBy(balancingPolicy?.rules, r => r.id);

  const { opt } = props;
  const optStatus = opt?.status;
  const isStopping = optStatus === OptimizationRunStatus.STOPPING;

  const statusRaw = opt?.status;
  const titleStatus =
    statusRaw === OptimizationRunStatus.COMPLETED
      ? t(`Optimised`)
      : statusRaw === OptimizationRunStatus.CREATED
        ? t(`Preparing Data`)
        : statusRaw === OptimizationRunStatus.FAILED
          ? t(`Error`)
          : statusRaw === OptimizationRunStatus.IN_PROGRESS
            ? t(`Optimising`)
            : statusRaw === OptimizationRunStatus.STOPPING
              ? t(`Finalising Optimisation`)
              : '—';

  const lastStep = opt?.latestProgressDetails;
  console.log('lastStep:opt?:', opt);

  const totalImprovementRaw = lastStep?.currentTotalImprovement ?? 0;
  const remainingTimeRaw = lastStep?.remainingComputeTime ?? 0;
  const elapsedTimeRaw = lastStep?.elapsedComputeTime ?? 0;
  const currentMoveCount = lastStep?.currentMoveCount ?? 0;
  const currentStepCount = lastStep?.step ?? 0;

  const totalImprovement = formatter.formatShare(totalImprovementRaw / 100);

  const objectivesTitles: Record<OptimizationObjectiveType, string> = {
    COST: t`Predicted Cost Improvement`,
    LOAD_BALANCING: t`Est. Balancing`,
  };

  function getTitle(
    type: OptimizationObjectiveType,
    ruleId: string,
    weight: number,
  ) {
    if (_.isNil(ruleId)) {
      return objectivesTitles[type];
    }
    return t('{{objType}} - {{ ruleTitle}}', {
      objType: objectivesTitles[type],
      ruleTitle: balancingPolicyRules[ruleId]?.title,
      ruleWeight: formatter.formatShare(weight).fullString,
    });
  }

  const costStats = _.filter(
    lastStep?.details?.improvement?.objectives,
    target => target.type === OptimizationObjectiveType.COST,
  );

  const loadBalancingStats = _.filter(
    lastStep?.details?.improvement?.objectives,
    target => target.type === OptimizationObjectiveType.LOAD_BALANCING,
  );
  const optimiseTime = formatTimespan(
    opt?.completedAt ?? 0,
    opt?.startedAt ?? 0,
  );
  const totalLocationCount = props.layoutSummary?.locationCount;
  const rateOfOptimisationSecPerTasks =
    opt?.elapsedRunningTime / opt.optimizationSummary?.effectiveSteps || 0;
  const rateOfOptimisationTasksPerSec =
    opt?.optimizationSummary?.effectiveSteps / opt?.elapsedRunningTime || 1;
  const rateOfOptimisationMoves =
    opt?.elapsedRunningTime / opt.optimizationSummary?.moveCount ||
    currentMoveCount;
  const rateOfSwaps = opt?.steps / totalLocationCount;

  const optimisationStopReasonRaw = opt?.optimizationSummary?.stopReason;

  const stopReasonMapping = {
    STEP_LIMIT: t`Step Limit`,
    USER_REQUEST: t`User Request`,
    TARGET_IMPROVEMENT: t`Target Limit`,
    MIN_IMPROVEMENT_RATE: t`Improvement Rate Limit`,
    TIME_LIMIT: t`Time Limit`,
  };

  return (
    <StatGroup
      title={`Optimisation Stats`}
      className="px-0.5 py-1"
      classNameTitle="!text-xs"
      // hasNoPadding
      classNameStatGroupItems="divide-y divide-menu-400/50 bg-app-panel-dark/60"
    >
      <Stat
        isMetaValue
        className="px-2 py-2"
        title={t`Optimisation Status:`}
        value={titleStatus}
      />
      {opt.optimizationSummary?.stopReason && (
        <Stat
          isMetaValue
          className="px-2 py-2"
          title={t`Stop Reason:`}
          value={stopReasonMapping[optimisationStopReasonRaw] ?? '—'}
        />
      )}
      <Stat
        isMetaValue
        className="px-2 py-2"
        title={t`Moves:`}
        value={formatInteger(
          opt.optimizationSummary?.moveCount ?? currentMoveCount,
        )}
      />
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Total Time:`}
        titleAlt={`${formatDateTime(
          new Date(opt.completedAt),
        )} — ${formatDateTime(new Date(opt.startedAt))}`}
        value={optimiseTime}
      />
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Run Time`} //Ended
        value={formatTimespan(opt.elapsedRunningTime * 1000)}
      />
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Started`}
        value={formatDateTime(new Date(opt?.startedAt))}
      />
      {opt?.completedAt && (
        <Stat
          className="px-2 py-2"
          isMetaValue
          title={t`Completed`} //Ended
          value={formatDateTime(new Date(opt?.completedAt))}
        />
      )}
      <Stat
        isMetaValue
        className="px-2 py-2"
        title={t`Optimisation Tasks:`}
        value={`${formatInteger(currentStepCount) ?? '—'} / ${
          opt?.steps ? formatInteger(opt?.steps) : '—'
        }`}
      />
      {opt.optimizationSummary?.effectiveSteps && (
        <Stat
          isMetaValue
          className="px-2 py-2"
          title={t`Effective Tasks:`}
          value={formatInteger(opt.optimizationSummary?.effectiveSteps)}
        />
      )}
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Optimisation Rate`}
        value={`${rateOfOptimisationTasksPerSec.toPrecision(
          3,
        )} task/sec | \n ${rateOfOptimisationSecPerTasks.toPrecision(
          3,
        )} sec/task`}
      />
      {/* <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Optimisation Rate (Tasks/sec)`}
        value={rateOfOptimisationSecSteps.toPrecision(3)}
      /> */}
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Optimisation Moves (Moves/sec)`}
        value={rateOfOptimisationMoves.toPrecision(3)}
      />
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Swap Ratio (Swaps/Location)`}
        value={rateOfSwaps.toPrecision(3)}
      />
      <Stat
        className="px-2 py-2"
        isMetaValue
        title={t`Locations (Total)`}
        value={formatInteger(totalLocationCount)}
      />
    </StatGroup>
  );
};

export default OptimisationStatsInfo;
