import * as React from 'react';
const CircleXIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleXIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M99.37,28.63c-19.5-19.5-51.24-19.5-70.74,0-19.5,19.5-19.5,51.24,0,70.74,19.5,19.5,51.24,19.5,70.74,0,19.5-19.5,19.5-51.24,0-70.74ZM78.28,83.73l-14.28-14.28-14.96,14.96c-1.5,1.5-3.94,1.5-5.44,0-1.5-1.5-1.5-3.94,0-5.44l14.96-14.96-14.28-14.28c-1.5-1.5-1.5-3.94,0-5.44,1.5-1.5,3.94-1.5,5.44,0l14.28,14.28,13.6-13.6c1.5-1.5,3.94-1.5,5.44,0,1.5,1.5,1.5,3.94,0,5.44l-13.6,13.6,14.28,14.28c1.5,1.5,1.5,3.94,0,5.44-1.5,1.5-3.94,1.5-5.44,0Z"
    />
  </svg>
);
export default CircleXIcon;
