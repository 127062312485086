import { MappingSchema } from '@warebee/shared/import-converter';

export class AnalyzedJobEvent {
  documentId: string;

  jobId: string;

  sequenceId?: number;
  jobLine?: number;
  jobGroupIds?: string;

  jobDate?: Date;
  eventDate?: Date;

  createdAt: Date;

  eventType: string;
  processType: string;

  duration: number;
  cost: number;

  agents: string;

  startPosition?: string;
  endPosition?: string;

  locationId?: string;

  details?: string;

  recurringCount?: number;

  sourceEventId?: string;
}

export const ANALYZED_JOB_EVENT_MAPPING_SCHEMA: MappingSchema<AnalyzedJobEvent> =
  {
    fields: [
      {
        name: 'documentId',
        type: 'string',
      },
      {
        name: 'jobDate',
        type: 'localDateTime',
        optional: true,
      },
      {
        name: 'eventDate',
        type: 'localDateTime',
        optional: true,
      },
      {
        name: 'createdAt',
        type: 'localDateTime',
      },
      {
        name: 'jobId',
        type: 'string',
      },
      {
        name: 'sequenceId',
        type: 'integer',
        optional: true,
      },
      {
        name: 'jobLine',
        type: 'integer',
        optional: true,
      },
      {
        name: 'jobGroupIds',
        type: 'string',
        optional: true,
      },
      {
        name: 'eventType',
        type: 'string',
      },
      {
        name: 'processType',
        type: 'string',
      },
      {
        name: 'duration',
        type: 'number',
      },
      {
        name: 'cost',
        type: 'number',
      },
      {
        name: 'agents',
        type: 'string',
      },
      {
        name: 'startPosition',
        type: 'string',
        optional: true,
      },
      {
        name: 'endPosition',
        type: 'string',
        optional: true,
      },
      {
        name: 'locationId',
        type: 'string',
        optional: true,
      },
      {
        name: 'details',
        type: 'string',
        optional: true,
      },
      {
        name: 'recurringCount',
        type: 'integer',
        optional: true,
      },
      {
        name: 'sourceEventId',
        type: 'string',
        optional: true,
      },
    ],
    partitionBy: [
      { key: 'documentId' },
      { key: 'eventDate', transform: 'day' },
    ],
  };
