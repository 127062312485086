import * as React from 'react';
const PolicyAssignmentAgentFillIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAssignmentAgentFillIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM93.18,69.32c-1.53,1.35-3.86,1.23-5.21-.3-1.33-1.51-1.25-3.83.24-5.15l3.14-2.77s-17.96,0-26.94,0c-.41,0-.73.35-.73.76s.32.73.73.73h13.74c2.04,0,3.69,1.65,3.69,3.69,0,2.04-1.68,3.69-3.72,3.69-4.57,0-9.16.06-13.71,0-.41,0-.73.32-.73.73s.32.73.73.73h9.29c2.04,0,3.72,1.65,3.72,3.69s-1.68,3.72-3.72,3.72c-4.36,0-8.76.06-13.1,0-.41,0-.73.32-.73.73s.32.73.73.73h7.86c2.04,0,3.69,1.65,3.69,3.69s-1.64,3.72-4.78,3.72h-22.61c-9.61,0-19.2-9.59-19.2-21.42s9.56-21.39,21.39-21.39h23.61c3.15,0,3.72,0,3.72,2.04,0,3.57-3.26,5.3-5.3,5.3h-10.06c-.41,0-.73.32-.73.73s.32.76.73.76c10.81,0,32.42,0,32.42,0l-3.14-2.77c-1.5-1.32-1.57-3.64-.24-5.15,1.35-1.53,3.68-1.65,5.21-.3l13.53,11.91-13.53,11.91Z"
    />
  </svg>
);
export default PolicyAssignmentAgentFillIcon;
