import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { UpdateBaseEntityInput } from '../update-base-entity.input';

@InputType()
export class UpdateLayoutImportInput extends UpdateBaseEntityInput {
  @Field(() => ID)
  layoutImportId: string;
}
