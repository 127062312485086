import {
  ImportPipelineFragment,
  ImportPipelinesConnectionFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import {
  UploadChunkStatus,
  UploadStatus,
} from '../../import/store/import.types';
import { ImportPipelinesTableColumn } from './importJobs.types';

const getKey = (postfix: string) => `warebee-import-pipelines-${postfix}`;

export const importPipelinesData = atom<ImportPipelinesConnectionFragment>({
  key: getKey('data'),
  default: null,
});

export const importPipelineSelectedId = atom<string>({
  key: getKey('selected-id'),
  default: null,
});

export const importPipelineSelected = selector<ImportPipelineFragment>({
  key: getKey('selected'),
  get: ({ get }) => {
    const selectedId = get(importPipelineSelectedId);
    const pipelines = get(importPipelinesData)?.content;
    return _.find(pipelines, p => p.id === selectedId);
  },
});

export const importPipelinesDataStatus = atom<AsyncLoadStatus>({
  key: getKey('data-status'),
  default: AsyncLoadStatus.None,
});

export const importPipelinesDataTableState = atom<
  DataTableState<ImportPipelinesTableColumn>
>({
  key: getKey('data-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      createdAt: SortDirection.ASC,
    },
  },
});

export const importPipelineSelectedConnectorId = atom<string>({
  key: getKey('selected-connector-id'),
  default: null,
});

export const importPipelineUpdateStatus = atom<AsyncLoadStatus>({
  key: getKey('update-status'),
  default: AsyncLoadStatus.None,
});

export const importPipelineIsActiveEditor = atom<boolean>({
  key: getKey('is-active-editor'),
  default: false,
});

export const importPipelineFilesUploadStatus = atom<AsyncLoadStatus>({
  key: getKey('upload-status'),
  default: AsyncLoadStatus.None,
});

export const importPipelineFilesUploadChunkStatusAtom = atom<
  Record<string, UploadChunkStatus>
>({
  key: getKey('chunk-upload-status-atom'),
  default: {},
});

export const importPipelineFilesUploadChunkStatus = selectorFamily<
  UploadChunkStatus,
  string
>({
  key: getKey('chun-upload-status'),
  get:
    key =>
    ({ get }) =>
      get(importPipelineFilesUploadChunkStatusAtom)?.[key],
  set:
    key =>
    ({ get, set }, value) => {
      const current = get(importPipelineFilesUploadChunkStatusAtom);
      set(importPipelineFilesUploadChunkStatusAtom, {
        ...current,
        [key]: value,
      });
    },
});

export const importPipelineFilesUploadProgress = selector<UploadStatus>({
  key: getKey('upload-progress-summary'),
  get: ({ get }) => {
    const allChunks = get(importPipelineFilesUploadChunkStatusAtom);
    return _.reduce(
      _.values(allChunks),
      (acc, chunk) => ({
        jobId: chunk.jobId,
        status: Math.max(acc.status, chunk.status),
        loaded: acc.loaded + chunk.loaded,
        total: acc.total + chunk.total,
        errors: [...acc.errors, ...(chunk.errors ?? [])],
      }),
      {
        jobId: null,
        status: AsyncLoadStatus.None,
        loaded: 0,
        total: 0,
        errors: [],
      },
    );
  },
});
