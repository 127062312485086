import * as React from 'react';
const SortPriorityRoundRobinIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortPriorityRoundRobinIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M114.17,34.97v-8.15h0v-1.33c0-5.65-4.56-10.45-10.21-10.45h-24.6v6.89h24.6c1.93,0,3.56,1.52,3.56,3.44v8.96s0,0,0,0v.65l-9.15-9.13v9.4l12.48,12.45,12.48-12.47v-9.4l-9.15,9.14Z"
    />
    <path
      strokeWidth={0}
      d="M48.65,3.75v30.94h30.7V3.75h-30.7ZM72.8,30.5h-15.7v-3.91h6.07v-15.12h-.29l-4.59,6.01-3.1-2.42,5.4-7.11h7.43v18.64h4.78v3.91Z"
    />
    <path
      strokeWidth={0}
      d="M126.81,79.19v-30.94h-30.7s0,30.94,0,30.94h12.67v22.44c0,1.93-1.52,3.56-3.44,3.56h-8.96s-.65,0-.65,0l9.13-9.15h-9.4s-12.45,12.48-12.45,12.48l12.47,12.48h9.4s-9.14-9.15-9.14-9.15h8.15s1.33,0,1.33,0c5.65,0,10.45-4.56,10.45-10.21v-22.44h11.15ZM113.32,57.2c-.56-.5-1.31-.74-2.26-.74-1.01,0-1.81.29-2.41.87-.59.58-1.03,1.35-1.31,2.29l-4.23-1.62c.54-1.7,1.52-3.09,2.94-4.15,1.42-1.07,3.25-1.6,5.49-1.6,1.62,0,3.01.29,4.19.87,1.17.58,2.07,1.38,2.68,2.41.61,1.02.92,2.19.92,3.51,0,1.19-.26,2.27-.77,3.26-.52.99-1.2,1.9-2.04,2.73-.84.83-1.93,1.75-3.26,2.76l-4.3,3.33h10.86v4.07h-16.22v-4.43l7.11-5.91c1.25-1.08,2.14-2,2.67-2.78.53-.77.79-1.58.79-2.42v-.32c0-.93-.28-1.64-.84-2.13Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M81.09,124.25v-30.94h-30.7v13.36h-23.79c-1.93,0-3.56-1.52-3.56-3.44v-8.96h0v-.65l9.15,9.13v-9.4s-12.48-12.45-12.48-12.45l-12.48,12.47v9.4l9.15-9.14v8.15h0v1.33c0,5.65,4.56,10.45,10.21,10.45h23.79v10.69h30.7ZM68.15,111.17c-.71-.47-1.7-.71-2.97-.71h-2.1v-4.04h2.13c1.18,0,2.08-.23,2.68-.68.6-.45.9-1.04.9-1.78v-.23c0-.82-.28-1.47-.84-1.95-.56-.48-1.34-.73-2.33-.73-.93,0-1.79.23-2.6.7-.81.46-1.49,1.13-2.05,1.99l-3.07-2.84c.95-1.23,2.04-2.16,3.28-2.81,1.24-.65,2.78-.97,4.64-.97,1.62,0,3.03.25,4.25.74,1.22.5,2.16,1.2,2.83,2.1.67.9,1,1.95,1,3.13,0,1.38-.42,2.52-1.24,3.41-.83.89-1.88,1.45-3.15,1.66v.19c1.44.26,2.6.86,3.49,1.79.88.94,1.33,2.15,1.33,3.63,0,1.34-.37,2.5-1.1,3.51-.73,1-1.76,1.78-3.09,2.33-1.33.55-2.87.82-4.64.82-2.09,0-3.79-.39-5.11-1.18-1.31-.79-2.39-1.84-3.23-3.15l3.59-2.78c.56,1.01,1.22,1.79,1.97,2.34.75.55,1.71.82,2.88.82,1.14,0,2.03-.27,2.67-.81.64-.54.95-1.28.95-2.23v-.23c0-.9-.35-1.59-1.07-2.07Z"
      opacity={0.65}
    />
    <path
      strokeWidth={0}
      d="M36.2,31.34l12.45-12.48-12.47-12.48h-9.4s9.14,9.15,9.14,9.15h-8.15s-1.33,0-1.33,0c-5.65,0-10.45,4.56-10.45,10.21v22.5H3.87v30.94h30.7v-30.94h-11.69v-22.5c0-1.93,1.52-3.56,3.44-3.56h8.95s.65,0,.65,0l-9.13,9.15h9.4ZM28.22,67.43v3.75h-2.91v4.33h-4.65v-4.33h-10.15v-4.14l8.98-14.09h5.82v14.48h2.91Z"
      opacity={0.55}
    />
    <polygon
      strokeWidth={0}
      points="14.42 67.43 20.65 67.43 20.65 57.96 20.36 57.96 14.42 67.43"
      opacity={0.55}
    />
  </svg>
);
export default SortPriorityRoundRobinIcon;
