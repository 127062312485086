import React, { PropsWithChildren, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { AppFooter } from '../components/AppFooter';
import { AppHeader } from '../components/AppHeader';
import { ContainerAppView } from '../components/layout/ContainerAppView';
import { ContainerCol, ContainerRow } from '../components/layout/ContainerFlex';
import LoadingIndicator from '../components/LoadingIndicator';
import SidebarMenuWarehouses from '../screens/SidebarMenuWarehouses';

export type WarehouseContainerProps = PropsWithChildren & {
  fullHeaders?: boolean;
};

const WarehouseContainer: React.FC<WarehouseContainerProps> = props => {
  const { t } = useTranslation('app');

  return (
    <>
      <ContainerAppView>
        <ContainerCol componentName="WarehouseDataContainer" overflow flex1>
          <AppHeader
            hasMenuApps
            hasMenuGlobal
            hasHelp
            hasNotifications
            hasSetting
            hasUser
            hasSubscription
          />
          <ContainerRow overflow>
            {props.fullHeaders && <SidebarMenuWarehouses />}
            <ContainerCol overflow flex1>
              <Suspense
                fallback={
                  <LoadingIndicator
                    selfCenter
                    message={t`Loading Warehouse Data`}
                  />
                }
              >
                {props.children}
              </Suspense>
            </ContainerCol>
          </ContainerRow>
        </ContainerCol>
      </ContainerAppView>

      <AppFooter />
    </>
  );
};
export default WarehouseContainer;
