import * as React from 'react';
const PolicyReplenishmentAgentsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyReplenishmentAgentsIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M57.71,41.7c2.91.36,5.57-1.7,5.93-4.61.36-2.91-1.7-5.57-4.61-5.93-2.91-.36-5.57,1.7-5.93,4.61-.36,2.91,1.7,5.57,4.61,5.93Z"
    />
    <path
      strokeWidth={0}
      d="M88,46.13l-2.7-4.48c-.24-.4-1.67-.64-2.14-.64h-10.41c-.47,0-1.9.24-2.14.64l-4.79,4.62s-.01.03-.02.05c-.04.07-.07.14-.1.22-.01.03-.02.06-.03.09-.02.09-.03.17-.04.27,0,.02,0,.03,0,.05,0,0,0,0,0,.01v4.23s-7.52-6.86-7.52-6.86c-1.18-1.41-2.99-2.19-4.92-1.95-3.05.38-5.22,3.16-4.84,6.22l2.13,17.56-3.74,9.56-7.43,5.42c-1.81.17-3.19,1.73-3.11,3.57.09,1.89,1.69,3.36,3.7,3.27l8.86-5.6c1.69-.08,3.19-1.12,3.84-2.68l4.1-9.75,9.31,7.13,7.57,12.35c.44,1.77,2.19,2.9,4,2.54,1.86-.37,3.07-2.18,2.68-4.15l-7.79-12.63c-.31-1.5-1.16-2.85-2.39-3.77l-8.55-6.49-1.27-10.44,5.38,4.73v4.39c0,.73.6,1.33,1.33,1.33h19.98c.73,0,1.33-.6,1.33-1.33v-16.53s.01-.08.01-.13c0-.31-.11-.6-.29-.83ZM85.62,62.28h-17.32s0-2.48,0-2.48h8.58s.33.01.33.01c1.76-.03,3.09-1.4,3.06-3.07-.03-1.62-1.32-2.91-2.92-2.96h-8.88s-.17-.17-.17-.17v-5.33s17.32,0,17.32,0v14Z"
    />
  </svg>
);
export default PolicyReplenishmentAgentsIcon;
