export const textSizeAttributes = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-xl',
};

export type TextSizeAttribute = keyof typeof textSizeAttributes;

export const roundedAttributes = {
  md: 'rounded-md',
  left: 'rounded-l',
  right: 'rounded-r',
};
export type RoundedAttribute = keyof typeof roundedAttributes;

export const panelFixedHeights = [
  'h-min',
  'h-default',
  'h-half',
  'h-full',
] as const;

export type PanelFixedHeight = (typeof panelFixedHeights)[number];

export const panelFixedHeightsCss: Record<PanelFixedHeight, string> = {
  'h-min': 'h-10',
  'h-default': 'h-1/4',
  'h-half': 'h-1/2',
  'h-full': 'h-full',
};
