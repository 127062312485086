const DeliveryTrailerIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DeliveryTrailerIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M68.98,76.34h-6.61c1.01.28,1.86.93,2.39,1.81h1.82c.53-.88,1.38-1.53,2.39-1.81h0Z"
    />
    <path strokeWidth={0} d="M84.66,43.73H1.62v27.79H.5v1.32h84.16v-29.12Z" />
    <path
      strokeWidth={0}
      d="M68.98,76.34h2.11c-.34-.09-.69-.15-1.05-.15-.37,0-.71.06-1.05.15h0Z"
    />
    <path
      strokeWidth={0}
      d="M62.37,76.34h6.61c.34-.09,1.77-.09,2.11,0h13.59v-1.78H.5v1.11c.28.61.51.67,1.19.67h1.45l1.21,4.82h.14c.42,1.78,2.01,3.11,3.92,3.11s3.5-1.33,3.92-3.11h.56c.42,1.78,2.01,3.11,3.92,3.11s3.5-1.33,3.92-3.11h.16l.81-4.82h38.57c.34-.09,1.77-.09,2.11,0h0ZM9.85,81.16c-.31.48-.83.82-1.45.82s-1.14-.34-1.45-.82c-.18-.28-.31-.58-.31-.93,0-.97.79-1.76,1.76-1.76s1.76.79,1.76,1.76c0,.35-.13.66-.31.93h0ZM18.25,81.16c-.31.48-.83.82-1.45.82s-1.14-.34-1.45-.82c-.18-.28-.31-.58-.31-.93,0-.97.79-1.76,1.76-1.76s1.76.79,1.76,1.76c0,.35-.13.66-.31.93h0Z"
    />
    <path
      strokeWidth={0}
      d="M126.35,74.46v-4.62q-.27-.87-1.23-1.5l-11.28-1.37-.77-4.06h.93s-.19-1.12-1.2-2.47l-1.04-.06c-.89-1.53-2.55-3.83-7.04-6.06,0,0-10.23-4.07-17.07-4.75-1.27.15-1.23,1.35-1.23,3.01v25.6l-12.94-.03c-.53-.89-1.38-1.54-2.4-1.82h-2.11c-1.01.28-1.86.93-2.39,1.81h-1.82c-.53-.88-1.38-1.53-2.39-1.81h-2.11c-1.01.27-1.85.92-2.38,1.79h-4.12s0,1.16,0,1.16c0,1.66.55,2.53,1.23,2.53h2.62c.62,1.43,2.04,2.44,3.71,2.44s3.09-1.01,3.71-2.44h1.3c.62,1.43,2.04,2.44,3.7,2.44s3.09-1.01,3.71-2.44h41.94c.62,1.43,2.04,2.44,3.7,2.44s3.09-1.01,3.7-2.44h1.57c.55,0,1.12-.61,1.28-1.21,0,0,1.56.17,1.56-2.91,0-3.52-1.15-3.24-1.15-3.24h0ZM62.03,81.83c-.22.1-.46.16-.71.16s-.5-.06-.71-.16c-.61-.28-1.05-.89-1.05-1.6,0-.97.79-1.76,1.76-1.76s1.76.79,1.76,1.76c0,.71-.43,1.33-1.04,1.6h0ZM70.75,81.83c-.22.1-.46.16-.71.16s-.49-.06-.71-.16c-.61-.28-1.05-.89-1.05-1.6,0-.97.79-1.76,1.76-1.76s1.76.79,1.76,1.76c0,.71-.43,1.33-1.05,1.6h0ZM107.31,66.65v-1.66c0-.92-.13-1.65.78-1.65,0,0,1.92-.02,2.48,0,.56.02,1.18-.12,1.3.59.13.71.48,2.72.48,2.72h-5.04ZM119.39,81.99c-.26,0-.5-.06-.71-.16-.61-.28-1.05-.89-1.05-1.6,0-.97.79-1.76,1.76-1.76s1.76.79,1.76,1.76c0,.71-.43,1.32-1.04,1.6-.22.1-.46.16-.72.16h0Z"
    />
    <path
      strokeWidth={0}
      d="M61.32,76.19c-.37,0-.71.06-1.05.16h2.11c-.34-.09-.69-.16-1.05-.16h0Z"
    />
  </svg>
);
export default DeliveryTrailerIcon;
