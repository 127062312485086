import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cn } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import InputTextArea from '../../../components/inputs/InputTextArea';
import TitleSection from '../../../components/layout/TitleSection';
import Tag from '../../../helpContext/Tag';
import { warehouseSelected } from '../../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../../store/warehouse.types';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import {
  importPipelineIsActiveEditor,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
} from '../../store/importPipelines.state';

export const PipelineSectionProperties: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const whExtraSettings: WarehouseExtraSettings = wh?.extraSettings;
  const pipeline = useRecoilValue(importPipelineSelected);
  const isDefaultPipeline =
    pipeline &&
    pipeline?.id === whExtraSettings.defaultPipelines?.[pipeline?.importType];

  const setConnectorId = useSetRecoilState(importPipelineSelectedConnectorId);
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  function setDefaultPipelineSettings(id: string) {
    updateWhExtraSettings({
      patch: {
        defaultPipelines: {
          ...(whExtraSettings.defaultPipelines ?? {}),
          [pipeline.importType]: id,
        },
      },
    });
  }

  return (
    <>
      <TitleSection
        id={`pipeline-properties`}
        title={t`Pipeline Properties`}
        inPanelView
        // className="top-12 xl:top-20 z-400"
        hasScreenTitle
        classNameInner="p-2 xl:p-4 space-y-4"
        //   collapsible
        summaryView
        hasAction={
          <Button
            label={t`Edit Pipeline`}
            className={classNames('rounded ltr:ml-4 rtl:mr-4')}
            buttonSize="xs"
            buttonType="primary"
            hasIconAfter={
              <Icon.EditPencil className={`h-5 w-5 fill-current`} />
            }
            onPress={() => {
              setConnectorId(null);
              setShowEditor(true);
            }}
          />
        }
      >
        {/* <PolicyWell
        fullHeight
        // isDisabled={!canUpdate}
        className={cn('flex-1')}
        classNameChildren={cn('space-y-1')}
      > */}
        <InputTextArea
          optional
          label={t`Pipeline Description`}
          placeholder={t`About the Pipeline...`}
          value={pipeline.description}
          isDisabled
        />

        <div className={cn('my-2 flex items-center py-1')}>
          <div
            className={cn('text-menu-text mb-2 flex-1 text-sm')}
          >{t`Data Format`}</div>
          <Tag classType={cn('bg-app-panel text-menu-text shadow')}>
            {pipeline.settings?.sourceSettings?.format}
          </Tag>
        </div>
        <div className={cn('my-2 flex items-center py-1')}>
          <div
            className={cn('text-menu-text flex-1 text-sm')}
          >{t`Compression Settings`}</div>
          <Tag classType={cn('bg-app-panel text-menu-text shadow')}>
            {pipeline.settings?.sourceSettings?.compression}
          </Tag>
        </div>

        <div className={cn('my-2 flex items-center py-1')}>
          <div className={cn('text-menu-text mb-2 flex-1 text-sm')}>
            {isDefaultPipeline ? t`Default Pipeline` : t`Default Pipeline`}
          </div>
          <Button
            className={cn('rounded ltr:ml-4 rtl:mr-4')}
            label={
              isDefaultPipeline ? t`Unset as: Default` : t`Set as: Default`
            }
            buttonSize="xs"
            buttonType={isDefaultPipeline ? 'primary' : 'secondary'}
            hasIconAfter={<Icon.CircleOk className={`h-5 w-5 fill-current`} />}
            // hasIconAfter={<Icon.CircleX className={`h-5 w-5 fill-current`} />}
            onPress={() =>
              setDefaultPipelineSettings(isDefaultPipeline ? null : pipeline.id)
            }
          />
        </div>
        {/* </PolicyWell> */}
      </TitleSection>
    </>
  );
};
