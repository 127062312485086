import {
  RoutingPolicyFragment,
  RoutingPolicyInput,
} from '@warebee/frontend/data-access-api-graphql';
import { cloneWithoutTypename } from '../../../common/utils';

export function getRoutingPolicyInput(
  policy: RoutingPolicyFragment,
): RoutingPolicyInput {
  return cloneWithoutTypename(policy);
}

export function shouldCallRoutingPolicyCheck(
  last: RoutingPolicyFragment,
  current: RoutingPolicyFragment,
): boolean {
  return false;
}
