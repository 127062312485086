import { LayoutFloorSettings } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import { customAlphabet } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../../../../components/actions/Button';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { InputValue } from '../../../../components/inputs/InputValue';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableRowHead } from '../../../../components/table/TableRowHead';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { converterAreaConfiguration } from '../store/converter.area.state';
import { converterLayoutBuilder } from '../store/converter.state';

const nanoid = customAlphabet('1234567890abcdef', 10);

const StepSetupFloors: React.FC = () => {
  const { t } = useTranslation('designer');

  const [layoutBuilder, setLayoutBuilder] = useRecoilState(
    converterLayoutBuilder,
  );
  const areasConfig = useRecoilValue(converterAreaConfiguration);

  if (!layoutBuilder) {
    return <LoadingIndicator selfCenter message={t`Loading layout settings`} />;
  }
  const floors = _.values(layoutBuilder.floors);

  const removeFloor = (floorId: string) => {
    const newFloors = _(layoutBuilder.floors)
      .values()
      .filter(f => f.id !== floorId)
      .keyBy(f => f.id)
      .value();
    setLayoutBuilder({ ...layoutBuilder, floors: newFloors });
  };

  const renameFloor = (floorId: string, title: string) => {
    setLayoutBuilder({
      ...layoutBuilder,
      floors: {
        ...layoutBuilder.floors,
        [floorId]: { id: floorId, title: title },
      },
    });
  };
  const addFloor = () => {
    const newId = `floor-${nanoid()}`;
    const newTitle = `Floor-${floors?.length || 0}`;
    setLayoutBuilder({
      ...layoutBuilder,
      floors: {
        ...layoutBuilder.floors,
        [newId]: { id: newId, title: newTitle },
      },
    });
  };

  const groupByFloor = _(areasConfig)
    .values()
    .filter(conf => !conf.isDeleted)
    .groupBy(conf => conf.floor)
    .value();

  const getCount = (floorId: string) => {
    return groupByFloor[floorId]?.length || 0;
  };

  function drawFloorRow(floor: LayoutFloorSettings) {
    const areasOnFloor = getCount(floor.id);
    return (
      <TableRow rowLineList rowHover key={floor.id} aria-label={floor.title}>
        <TableTd
          hasBorder
          cellSize="md"
          // className="border-b border-opacity-50 border-menu-400"
        >
          <InputValue
            value={floor.title}
            onChange={v => {
              renameFloor(floor.id, v);
            }}
          />
        </TableTd>
        <TableTd hasBorder cellSize="md">
          {areasOnFloor}
        </TableTd>
        <TableTd hasBorder cellSize="md">
          <Button
            className="rounded"
            label={t`Delete`}
            buttonSize="xs"
            buttonType="delete"
            isDisabled={areasOnFloor > 0}
            onPress={() => {
              removeFloor(floor.id);
            }}
            hasIconAfter={<Icon.CircleX className={`w-5 h-5 fill-current`} />}
          />
        </TableTd>
      </TableRow>
    );
  }

  //const
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Layout Setup`}
        title={t`Warehouse Floors`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.Floor}
          question={t`Do you have more than one floor in the warehouse?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/01-setup-floors'} hasPadding />

          {/* <HelperContainer
            collapsible
            id={'helper-layout-warehouse-floors'}
            hideMessage={t`Hide helper`}
            showMessage={t`show more`}
            message={t`From the data provided, we cannot know how many floors does your warehouse have.`}
          >
            <HelperMessage hasBefore>
              {t`In the following table, please add the necessary floors.`}
            </HelperMessage>
            <HelperMessage isSuggestion hasBefore>
              {t`You will be able to allocate areas to the floors in the "Areas" section`}
              <HelperMessage isTip hasBefore>
                {t`You can remove the areas from the layout if you don't wish to analyse them.`}
              </HelperMessage>
            </HelperMessage>
          </HelperContainer>
          <HelperMessage hasBefore hasAction iconAction={Icon.ArrowRightBottom}>
            {t`Use`}
            <HelperMessageAction>{t`Add Floor`}</HelperMessageAction>
            {t`button if you have more floors in the warehouse`}
          </HelperMessage> */}
        </InputAnswerGroup>
      </InputQA>
      <TitleSection className="mt-4" titleView title={t`Floor Setup`} />
      <Table isSticky viewAsLine isHoverable className="mt-0">
        <TableHead>
          <TableRowHead>
            <TableTh isWide panelMode cellSize={'sm'}>{t`Floor`}</TableTh>
            <TableTh isWide panelMode cellSize={'sm'}>{t`No. Areas`}</TableTh>
            <TableTh panelMode cellSize={'sm'}>
              <Button
                className="rounded"
                label={t`Floor`}
                buttonSize="xs"
                buttonType="primary"
                onPress={addFloor}
                hasIconAfter={
                  <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                }
              />
            </TableTh>
          </TableRowHead>
        </TableHead>
        <TableBody>{_.map(floors, floor => drawFloorRow(floor))}</TableBody>
      </Table>
    </PanelBody>
  );
};

export default StepSetupFloors;
