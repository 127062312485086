import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  LoadAllAssignmentsMetaDocument,
  LoadAllAssignmentsMetaQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseAllAssignmentsMeta } from '../../store/warehouse.state';

export function useLoadAllAssignmentsMeta() {
  const client = useApolloClient();

  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    const current = await snapshot.getPromise(warehouseAllAssignmentsMeta);
    set(warehouseAllAssignmentsMeta, {
      ...current,
      status: AsyncLoadStatus.Loading,
    });
  });

  const loadAllAssignmentsMetaCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (warehouseId: string) => {
        if (_.isNil(warehouseId)) return;
        const current = await snapshot.getPromise(warehouseAllAssignmentsMeta);
        let response: ApolloQueryResult<LoadAllAssignmentsMetaQuery>;
        try {
          response = await client.query<LoadAllAssignmentsMetaQuery>({
            query: LoadAllAssignmentsMetaDocument,
            variables: {
              warehouseId,
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load assignment list',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(warehouseAllAssignmentsMeta, {
            ...current,
            status: AsyncLoadStatus.Error,
          });
          return;
        }

        if (response.error) {
          console.error(response.error);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load assignment list',
            details: response.error.message,
            callStack: response.error.stack || null,
          });
          set(warehouseAllAssignmentsMeta, {
            ...current,
            status: AsyncLoadStatus.Error,
          });
          return;
        }

        // Add checks for null values
        const items = response.data?.warehouse?.assignments?.content || [];
        console.log('warehouseAllAssignmentsMeta Items', items);
        set(warehouseAllAssignmentsMeta, {
          status: AsyncLoadStatus.Ok,
          items,
        });
      },
  );

  return async (warehouseId: string) => {
    await cleanupState();
    await loadAllAssignmentsMetaCallback(warehouseId);
  };
}
