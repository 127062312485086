import * as React from 'react';
const DataStatsPieIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataStatsPieIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M94.47,97.86c-.4.49-.82.97-1.24,1.43-.42.46-.86.93-1.31,1.37l14.51,14.5c.68-.68,1.34-1.38,1.98-2.08.64-.71,1.28-1.44,1.88-2.17.61-.74,1.21-1.5,1.78-2.27l-16.43-12.28c-.38.5-.77,1.01-1.17,1.49Z"
    />
    <path
      strokeWidth={0}
      d="M101.09,86.33c-.22.6-.46,1.2-.71,1.79-.25.59-.51,1.17-.79,1.75-.28.57-.57,1.15-.87,1.7-.3.56-.62,1.11-.95,1.65l17.53,10.65c.5-.82.99-1.67,1.44-2.51.46-.85.91-1.72,1.33-2.59.42-.87.82-1.77,1.2-2.66.38-.9.74-1.81,1.08-2.73.34-.92.65-1.86.94-2.79l-19.59-6.09c-.19.61-.4,1.23-.62,1.83Z"
    />
    <path
      strokeWidth={0}
      d="M103.49,72.75c0,.68-.02,1.37-.05,2.04-.03.67-.09,1.35-.15,2.01-.07.66-.15,1.32-.25,1.98-.1.65-.22,1.3-.35,1.94l20.1,4.11c.2-.98.38-1.97.53-2.96.15-.99.28-2,.38-3,.1-1.01.18-2.03.23-3.04.05-1.02.08-2.06.08-3.08h-20.51Z"
    />
    <path
      strokeWidth={0}
      d="M93.23,46.2c.42.46.84.95,1.24,1.43.4.49.8.99,1.17,1.49.38.51.75,1.03,1.1,1.55.35.52.7,1.06,1.03,1.6.33.54.65,1.1.95,1.65.3.56.6,1.13.87,1.7.28.57.54,1.16.79,1.75.25.59.49,1.19.71,1.79.22.6.43,1.22.62,1.83.19.62.37,1.25.53,1.87.16.63.31,1.27.44,1.91.13.64.25,1.3.35,1.94.1.65.18,1.32.25,1.98l20.41-2.07c-.1-1-.23-2.01-.38-3-.15-.99-.33-1.98-.53-2.96-.2-.97-.42-1.95-.67-2.91-.25-.95-.52-1.91-.81-2.85-.29-.93-.61-1.87-.94-2.79-.33-.91-.7-1.83-1.08-2.73-.38-.89-.78-1.79-1.2-2.66-.42-.87-.86-1.74-1.33-2.59-.46-.84-.94-1.69-1.44-2.51-.5-.82-1.02-1.64-1.56-2.43-.53-.79-1.1-1.58-1.67-2.35-.57-.77-1.17-1.53-1.78-2.27-.61-.74-1.24-1.47-1.88-2.17-.64-.71-1.31-1.41-1.98-2.08-.67-.67-1.37-1.34-2.08-1.99-.71-.64-1.44-1.28-2.17-1.88-.74-.61-1.5-1.21-2.26-1.78-.77-.57-1.56-1.14-2.35-1.67-.8-.54-1.62-1.06-2.43-1.56-.82-.5-1.67-.98-2.51-1.45-.85-.46-1.72-.91-2.59-1.32-.87-.42-1.77-.83-2.66-1.2-.9-.38-1.81-.74-2.73-1.08-.92-.34-1.86-.65-2.79-.94-.94-.29-1.9-.56-2.85-.81-.96-.25-1.93-.47-2.91-.67-.98-.2-1.97-.38-2.95-.53-.99-.15-2-.28-3-.38-1.01-.1-2.03-.18-3.04-.23l-1.04,20.49c.67.03,1.34.09,2.01.15.66.07,1.32.15,1.98.25.65.1,1.3.22,1.94.35.64.13,1.28.28,1.91.44.62.16,1.25.34,1.87.53.61.19,1.23.4,1.83.62.6.22,1.2.46,1.79.71.58.25,1.17.51,1.75.79.57.27,1.14.57,1.7.87.56.3,1.11.62,1.65.95.54.33,1.08.67,1.6,1.03.52.35,1.04.72,1.55,1.1.5.38,1.01.77,1.49,1.17.49.4.97.82,1.43,1.24.47.42.93.87,1.37,1.31.44.44.88.91,1.31,1.37Z"
    />
    <path
      strokeWidth={0}
      d="M45.18,38.02c.56-.3,1.13-.6,1.7-.87.57-.28,1.16-.54,1.75-.79.59-.25,1.19-.49,1.79-.71.6-.22,1.22-.43,1.83-.62.62-.19,1.25-.37,1.87-.53.63-.16,1.27-.31,1.9-.44.64-.13,1.3-.25,1.95-.35.65-.1,1.32-.18,1.98-.25.67-.07,1.34-.12,2.01-.15l-1.04-20.49c-1.01.05-2.04.13-3.04.23-1,.1-2.01.23-3,.38-.98.15-1.98.33-2.96.53-.97.2-1.95.43-2.9.67-.95.24-1.91.52-2.85.81-.93.29-1.87.61-2.79.94-.91.33-1.83.7-2.73,1.08-.89.38-1.79.78-2.66,1.2-.87.42-1.74.86-2.59,1.33-.85.46-1.69.95-2.51,1.44-.82.5-1.64,1.02-2.43,1.56-.79.54-1.58,1.1-2.35,1.67-.76.57-1.52,1.17-2.26,1.78l13.05,15.82c.49-.4.99-.8,1.49-1.17.51-.38,1.03-.75,1.55-1.1.53-.35,1.06-.7,1.6-1.03.54-.33,1.1-.65,1.65-.95Z"
    />
    <path
      strokeWidth={0}
      d="M33.53,97.86c-.4-.49-.8-.99-1.17-1.49-.38-.5-.75-1.03-1.1-1.55-.35-.52-.7-1.06-1.03-1.6-.33-.54-.65-1.1-.95-1.65-.3-.56-.6-1.13-.87-1.7-.28-.57-.54-1.16-.79-1.75-.25-.59-.49-1.19-.71-1.79-.22-.6-.43-1.22-.62-1.83-.19-.62-.37-1.24-.53-1.87-.16-.63-.31-1.27-.44-1.91-.13-.64-.25-1.29-.35-1.94-.1-.65-.18-1.32-.25-1.98-.07-.66-.12-1.34-.15-2.01-.03-.67-.05-1.36-.05-2.04s.02-1.37.05-2.04c.03-.67.09-1.34.15-2.01.07-.66.15-1.32.25-1.98.1-.65.22-1.3.35-1.94.13-.64.28-1.28.44-1.91.16-.62.34-1.25.53-1.87.19-.61.4-1.23.62-1.83.22-.6.46-1.2.71-1.79.25-.59.51-1.17.79-1.75.27-.57.57-1.14.87-1.7.3-.56.62-1.11.95-1.65.33-.54.67-1.08,1.03-1.6.35-.52.72-1.04,1.1-1.55.38-.51.77-1.01,1.17-1.49.4-.49.82-.97,1.24-1.44.42-.47.86-.93,1.31-1.37l-14.5-14.51c-.67.67-1.34,1.38-1.98,2.08-.64.7-1.27,1.44-1.89,2.18-.61.74-1.21,1.5-1.78,2.27-.57.77-1.14,1.56-1.67,2.35-.54.8-1.06,1.61-1.56,2.43-.5.82-.99,1.67-1.45,2.51-.46.85-.91,1.72-1.33,2.59-.42.87-.83,1.77-1.2,2.66-.38.9-.74,1.81-1.08,2.73-.34.92-.65,1.86-.94,2.79-.29.94-.56,1.9-.81,2.85-.25.96-.47,1.94-.67,2.91-.2.98-.38,1.97-.53,2.96-.15.99-.28,2-.38,3-.1,1.01-.18,2.03-.23,3.04-.05,1.02-.08,2.06-.08,3.08s.03,2.06.08,3.08c.05,1.01.13,2.04.23,3.04.1,1,.23,2.01.38,3,.15.99.33,1.98.53,2.96.2.97.42,1.95.67,2.91.25.95.52,1.91.81,2.85.29.93.61,1.87.94,2.79.33.91.7,1.83,1.08,2.73.38.89.78,1.79,1.2,2.66.42.87.87,1.74,1.33,2.59.46.85.95,1.69,1.45,2.51.5.82,1.02,1.64,1.56,2.43.54.79,1.1,1.59,1.67,2.35.57.76,1.17,1.53,1.78,2.27.61.74,1.24,1.47,1.88,2.17.64.7,1.31,1.4,1.98,2.08l14.51-14.5c-.45-.45-.89-.91-1.31-1.37-.42-.47-.84-.95-1.24-1.43Z"
    />
    <path
      strokeWidth={0}
      d="M60.42,67.89v-4.4c0-6.8-3.17-10.02-8.68-10.02s-8.68,3.22-8.68,10.02v4.4c0,6.8,3.17,10.08,8.68,10.08s8.68-3.28,8.68-10.08ZM48.93,67.89v-4.4c0-3.99,1.11-4.98,2.81-4.98s2.76,1,2.76,4.98v4.4c0,3.99-1.06,5.04-2.76,5.04s-2.81-1.06-2.81-5.04Z"
    />
    <path
      strokeWidth={0}
      d="M79.18,73.11c-5.51,0-8.68,3.28-8.68,10.08v4.4c0,6.8,3.17,10.08,8.68,10.08s8.62-3.28,8.62-10.08v-4.4c0-6.8-3.11-10.08-8.62-10.08ZM81.93,87.59c0,3.99-1.06,4.98-2.76,4.98s-2.81-1-2.81-4.98v-4.4c0-3.99,1.11-4.98,2.81-4.98s2.76,1,2.76,4.98v4.4Z"
    />
    <path
      strokeWidth={0}
      d="M81.76,54.24c0-.29-.18-.47-.59-.47h-6.04c-.64,0-.94.29-1.47,1.17l-24.39,41.15c-.12.23-.18.41-.18.59,0,.41.23.64.64.64h6.04c.64,0,.88-.41,1.35-1.23l24.39-41.15c.18-.23.23-.47.23-.7Z"
    />
  </svg>
);
export default DataStatsPieIcon;
