import { AssignmentDiffItemFullFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationNavigatorItem } from '../../components/LocationNavigatorItem';
import { WarehouseNavigator } from '../../components/WarehouseNavigator';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ContainerScroll } from '../../components/layout/ContainerScroll';

export type PanelItemMoveActionProps = {
  item: AssignmentDiffItemFullFragment;
};

const PanelItemMoveAction: React.FC<PanelItemMoveActionProps> = props => {
  const { item } = props;
  const { t } = useTranslation('simulation');

  return (
    <>
      <Container
        data-component="PanelItemMoveAction"
        flex1
        col
        hasNoScroll
        // className={`flex flex-col flex-1 overflow-hidden`}
      >
        <ContainerScroll>
          <header
            className={classNames(
              'bg-location-srcLocations/80 text-white',
              'sticky top-0 backdrop-filter backdrop-blur-lg',
            )}
          >
            <h2
              className={classNames('text-2xl p-2 text-center uppercase')}
            >{t`Old Location`}</h2>
            <WarehouseNavigator>
              <LocationNavigatorItem name={t`Area`} />
              <LocationNavigatorItem name={t`Aisle`} />
              <LocationNavigatorItem name={t`Bay`} />
              <LocationNavigatorItem name={t`Level`} />
              <LocationNavigatorItem name={t`Location`} />
            </WarehouseNavigator>
          </header>
          {item.srcLocations.map(l => {
            return (
              <WarehouseNavigator key={`loc-src-${l.locationId}`}>
                <LocationNavigatorItem
                  // name={t`Area`}
                  value={l?.location?.warehouseArea}
                />
                <LocationNavigatorItem
                  // name={t`Aisle`}
                  value={l.location?.aisle?.title}
                />
                <LocationNavigatorItem
                  // name={t`Bay`}
                  value={l.location?.bay?.title}
                />
                <LocationNavigatorItem
                  // name={t`Level`}
                  value={l?.location?.locationLevel}
                />
                <LocationNavigatorItem
                  // name={t`Location`}
                  value={l.locationId}
                />
              </WarehouseNavigator>
            );
          })}
        </ContainerScroll>

        <WarehouseNavigator className="relative flex flex-col items-center flex-1 p-2 bg-app-panel-dark min-h-1/4 h-full">
          <div
            className={`text-menu-active ${
              item.product.sku.length > 12
                ? 'text-2xl xl:text-4xl'
                : 'text-4xl xl:text-6xl'
            } p-2 flex-1 items-center justify-center flex flex-col w-full overflow-hidden`}
          >
            <div
              className={`absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center`}
            >
              <Icon.ChevronTripleRight
                className={`rotate-90 w-full h-full fill-current text-menu-active text-opacity-40 animation-slide`}
              />
            </div>
            <div
              className={`w-full p-2 bg-app-panel-dark/70 backdrop-filter backdrop-blur-lg backdrop-saturate-110`}
            >
              <div className="mt-1 mb-2 text-xs">{t`Item`}</div>
              <div>{item.product.sku}</div>
            </div>
          </div>
        </WarehouseNavigator>

        <ContainerScroll>
          <header
            className={classNames(
              'bg-location-destLocations/80 text-white',
              'text-2xl p-2 text-center uppercase',
              // 'sticky top-0 backdrop-filter backdrop-blur-lg'
            )}
          >{t`New Location`}</header>
          <WarehouseNavigator>
            <LocationNavigatorItem name={t`Area`} />
            <LocationNavigatorItem name={t`Aisle`} />
            <LocationNavigatorItem name={t`Bay`} />
            <LocationNavigatorItem name={t`Level`} />
            <LocationNavigatorItem name={t`Location`} />
          </WarehouseNavigator>

          {item.destLocations.map(l => {
            return (
              <WarehouseNavigator key={`loc-dest-${l.locationId}`}>
                <LocationNavigatorItem
                  // name={t`Area`}
                  value={l?.location?.warehouseArea}
                />
                <LocationNavigatorItem
                  // name={t`Aisle`}
                  value={l.location?.aisle?.title}
                />
                <LocationNavigatorItem
                  // name={t`Bay`}
                  value={l.location?.bay?.title}
                />
                <LocationNavigatorItem
                  // name={t`Level`}
                  value={l?.location?.locationLevel}
                />
                <LocationNavigatorItem
                  // name={t`Location`}
                  value={l.locationId}
                />
              </WarehouseNavigator>
            );
          })}
        </ContainerScroll>
      </Container>
      {/* TODO: implement in DB / localstorage */}
      {/* <ActionBar>
        <Button
          className="ltr:mr-1 rtl:ml-1"
          label={t`Not Moved`}
          buttonType="primary"
          isDisabled={false}
          full
          hasIconBefore
          buttonIcon={<Icon.CircleX className={`w-6 h-6 fill-current`} />}
        />
        <Spacer flexspace />
        <Button
          className="ltr:ml-1 rtl:mr-1"
          label={t`Mark as Moved`}
          buttonType="primary"
          isDisabled={false}
          full
          hasIconAfter
          buttonIcon={<Icon.CircleOk className={`w-6 h-6 fill-current`} />}
        />
      </ActionBar> */}
    </>
  );
};

export default PanelItemMoveAction;
