import * as React from 'react';
const MoveTypeMovePutIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeMovePutIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="53.2 21.81 53.2 59.23 90.22 59.23 65.54 34.48 81.42 34.48 111.71 64.86 81.36 95.23 65.49 95.23 90.22 70.49 53.2 70.49 53.2 106.19 125.58 106.19 125.58 21.81 53.2 21.81"
    />
    <rect width={44.61} height={11.25} x={2.43} y={59.16} strokeWidth={0} />
    <rect width={11.25} height={44.61} x={19.11} y={42.48} strokeWidth={0} />
  </svg>
);
export default MoveTypeMovePutIcon;
