import TimeAgo from '../../dashboard/TimeAgo';
import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, PropsWithChildren, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { DemoHelperReadonlyWarning } from '../../components/helpers/DemoHelper';
import * as Icon from '../../components/icons';
import { Spacer } from '../../components/layout/Spacer';
import {
  warehouseCanUpdate,
  warehouseIsDemo,
} from '../../store/warehouse.state';
import DashboardItemStatusTag from '../DashboardItemStatusTag';
import { DashboardItemStatus } from '../store/dashboard.types';
import { ItemListCard } from './ItemListCard';

export type DashboardItemContainerProps = PropsWithChildren & {
  id: string;
  title: string;
  description: string;
  classNameTitle?: string;
  className?: string;
  path: string;
  createdDate: Date;
  updatedDate: Date;
  status?: DashboardItemStatus;
  isLoading?: boolean;
  icon?: React.FC<HTMLAttributes<Element>>;
  //onEditClick?: () => void;
  canDelete: boolean;
  onDeleteClick?: () => Promise<void>;
  actions?: Partial<Record<DocumentAction, () => Promise<string>>>;
};

const documentActions = ['Edit', 'Delete', 'Converter', 'Duplicate'] as const;

type DocumentActionTuple = typeof documentActions;
export type DocumentAction = DocumentActionTuple[number];

const DashboardItemContainer: React.FC<DashboardItemContainerProps> = props => {
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMessage = t`Updating...`;
  const isDemo = useRecoilValue(warehouseIsDemo);
  const IconMenu = props.icon;

  function getActionTitle(action: DocumentAction): string {
    switch (action) {
      case 'Edit':
        return t`Open`;
      case 'Delete':
        return t`Delete`;
      case 'Converter':
        return t`Edit in Designer`;
      case 'Duplicate':
        return t`Duplicate`;
    }
  }

  const options: DocumentAction[] =
    canUpdate && props.canDelete ? ['Edit', 'Delete'] : ['Edit'];
  if (props.actions) {
    options.push(...(_.keys(props.actions) as DocumentAction[]));
  }

  return (
    <ItemListCard
      titleTrace={`Card (Data): Item ${props.title}`}
      className={props.className}
      isActionable
      label={`DashboardItemContainer`}
      id={props.id}
      isLoading={props.isLoading}
      onClick={e => {
        e.stopPropagation();
        navigate(props.path);
      }}
    >
      <header
        className={classNames('mb-4 min-h-5')}
        data-component={`DashboardItemContainerHeader`}
      >
        <div className={classNames('flex items-center mb-2')}>
          {props.status ? (
            <DashboardItemStatusTag status={props.status}>
              {!props.canDelete && (
                <Icon.Lock
                  className={classNames(
                    'w-4 h-4',
                    'ltr:mr-1 rtl:ml-1',
                    'fill-current',
                  )}
                />
              )}
            </DashboardItemStatusTag>
          ) : null}
          <Spacer flexspace />
          <TimeAgo
            timeDate={props.updatedDate}
            updatedDate={props.updatedDate}
            createdDate={props.createdDate}
          />
        </div>

        <div className={classNames('flex items-center w-full')}>
          {IconMenu && (
            <div
              className={classNames(
                'ltr:mr-2 rtl:ml-2',
                props.description
                  ? 'w-12 h-12 ltr:ml-1 rtl:mr-1 ltr:mr-2 rtl:ml-2'
                  : 'w-8 h-8',
              )}
            >
              <IconMenu
                className={classNames(
                  'fill-current',
                  'text-menu-text',
                  props.description ? 'w-12 h-12' : 'w-8 h-8',
                )}
              />
            </div>
          )}
          <div
            className={classNames('flex-1 w-full flex flex-col', 'truncate')}
          >
            <div
              title={props.title}
              className={classNames(
                'flex items-center w-full',
                props.classNameTitle,
              )}
            >
              <h3
                className={classNames(
                  'flex-1 w-full text-2xl truncate',
                  'text-menu-h3',
                )}
              >
                {props.title}
              </h3>
            </div>
            {props.description && (
              <div
                title={props.description}
                className={classNames('flex', 'truncate', 'text-menu-text/75')}
              >
                <div className={classNames('truncate')}>
                  {props.description}
                </div>
              </div>
            )}
          </div>
          <DropdownSelector
            onClick={e => {
              e.stopPropagation();
            }}
            DropAlignRight
            buttonTransparent
            vertical
            value={'...'}
            values={options}
            renderValue={getActionTitle}
            onChange={async (option: DocumentAction, e) => {
              e.stopPropagation();
              switch (option) {
                case 'Edit':
                  navigate(props.path);
                  break;
                case 'Delete':
                  setIsLoading(true);
                  props.onDeleteClick();
                  break;
                default:
                  if (props.actions?.[option]) {
                    setIsLoading(true);
                    const redirectUrl = await props.actions?.[option]();
                    setIsLoading(false);
                    if (!_.isNil(redirectUrl)) {
                      navigate(redirectUrl);
                    }
                  }
                  break;
              }
            }}
          />
        </div>
      </header>
      {(props.isLoading || isLoading) && (
        <LoadingIndicator absolute selfCenter message={loadingMessage} />
      )}
      {/* <Link to={{ pathname: props.path }}> */}
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorIndicator message={t`Cannot display card content`} />
        )}
      >
        {props.children}
      </ErrorBoundary>
      {/* </Link> */}
      {isDemo && <DemoHelperReadonlyWarning />}
    </ItemListCard>
  );
};
export default DashboardItemContainer;
