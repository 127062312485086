import { LocationFilterUnionFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../layout/viewer/store/viewer.state';
import { AP_FALLBACK_RULE_ID } from '../simulation/store/assignmentPolicy.default';
import {
  assignmentPolicyDocument,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';
import {
  policyHighlightedLocations,
  simulationLayoutId,
} from '../simulation/store/simulation.state';
import useLoadLocationsByRule from './useLoadLocationsByRule';

const AssignmentPolicyRuleDetailWatcher: React.FC = () => {
  const selectedDetails = useRecoilValue(assignmentPolicySelectedIdentity);

  const level = useRecoilValue(viewerSelectedLevel);
  const planeId = useRecoilValue(viewerSelectedPlaneId);

  const layoutId = useRecoilValue(simulationLayoutId);
  const policy = useRecoilValue(assignmentPolicyDocument);
  const setHighlightedLocations = useSetRecoilState(policyHighlightedLocations);

  const [loadLocationByRule, cancelLoadLocationByRule] =
    useLoadLocationsByRule();

  // Load highlighted locations
  useEffect(() => {
    if (!selectedDetails) {
      setHighlightedLocations(null);
    }
    if (
      selectedDetails?.filterType !== 'locations' &&
      selectedDetails?.filterType !== 'locations-empty'
    )
      return;
    if (
      _.isNil(layoutId) ||
      _.isNil(planeId) ||
      _.isNil(policy) ||
      _.isNil(selectedDetails?.ruleId)
    ) {
      //console.warn('Layout and Policy should be selected');
      return;
    }
    cancelLoadLocationByRule();

    const ruleIndex = _.findIndex(
      policy.rules,
      rule => rule.id === selectedDetails.ruleId,
    );
    const rulesToExclude =
      selectedDetails.ruleId === AP_FALLBACK_RULE_ID // && policy.type === FALLBACK_TAKES_EMPTY
        ? policy.rules
        : [];

    const excludeMatching = _.flatMap(
      rulesToExclude,
      rule => rule.locationsMatch,
    );

    let includeMatching: LocationFilterUnionFragment =
      selectedDetails.ruleId === AP_FALLBACK_RULE_ID
        ? policy.fallbackLocationsMatch
        : policy.rules[ruleIndex]?.locationsMatch;

    if (selectedDetails.filterType === 'locations-empty') {
      // To find Empty locations move  all to excludeMatch
      includeMatching && excludeMatching.push(includeMatching);
      includeMatching = null;
    }

    loadLocationByRule({
      planeId,
      level,
      layoutId,
      includeMatching,
      excludeMatching,
    });
  }, [planeId, policy, selectedDetails, layoutId, level]);

  return null;
};

export default AssignmentPolicyRuleDetailWatcher;
