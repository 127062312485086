import { ReassignTaskAction } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { TwTheme } from '../../../Tw';
import { getBestContrastColor } from '../../common/color.helper';
import { getHeatmapBuckets, HeatmapBucket } from '../../common/heatmap.helper';
import { HeatmapMetricRange } from '../../common/heatmap.types';
import { productCategoriesBase } from '../../simulation/store/abc/simulation.ABC.helper';
import { NamedColors } from '../../store/namedColors.types';
import { AnalyzeProductMetricDescriptorBase } from './analyzeProductMetric.types';

const locationColors = TwTheme.extend.colors.location;

export function getSKUKey(item: { consignee: string; sku: string }) {
  return `${item.consignee}-${item.sku}`;
}

export function getABCProductHeatmapBuckets(): HeatmapBucket[] {
  return _(productCategoriesBase)
    .map(category => ({
      id: category.key,
      index: -category.sortIndex,
      color: category.color,
      textColor: category.textColor,
      title: category.tag,
      from: category.fromCmlPercentile,
      to: category.toCmlPercentile,
    }))
    .value();
}

export function getReassignTypeHeatmapBuckets(): HeatmapBucket[] {
  return [
    {
      id: ReassignTaskAction.PUT,
      index: 3,
      title: 'Put',
      color: locationColors.destLocations,
      textColor: getBestContrastColor(locationColors.destLocations),
    },
    {
      id: ReassignTaskAction.TAKE,
      index: 2,
      title: 'Take',
      color: locationColors.srcLocations,
      textColor: getBestContrastColor(locationColors.srcLocations),
    },
    {
      id: 'PUT - TAKE',
      index: 1,
      title: 'Swap',
      color: locationColors.reassignSwap,
      textColor: getBestContrastColor(locationColors.reassignSwap),
    },
  ];
}

export function getAnalyzeProductHeatmapBuckets(
  rangeDescriptor: HeatmapMetricRange,
  metricDescriptor: AnalyzeProductMetricDescriptorBase,
  namedColors?: NamedColors,
): HeatmapBucket[] {
  switch (metricDescriptor.type) {
    case 'abc':
      return getABCProductHeatmapBuckets();
    case 'reassign-task-type':
      return getReassignTypeHeatmapBuckets();
  }
  return getHeatmapBuckets(
    rangeDescriptor,
    metricDescriptor as any,
    namedColors,
  );
}
