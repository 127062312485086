import * as React from 'react';
const DualWayIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DualWayIcon"
    {...props}
  >
    <path strokeWidth={0} d="M125.34,4v12h-60V4h60Z" />
    <path
      strokeWidth={0}
      d="M102.98,119.63V51.78s22.21,22.19,22.21,22.19v-21.58s-29.84-29.83-29.84-29.83l-29.86,29.83v21.58s22.22-22.2,22.22-22.2v67.85c0,2.32,1.88,4.21,4.2,4.21h6.87c2.32,0,4.21-1.88,4.21-4.21Z"
    />
    <path strokeWidth={0} d="M4,123.84v-12h60v12H4Z" />
    <path
      strokeWidth={0}
      d="M41.64,8.21v67.85s22.21-22.19,22.21-22.19v21.58s-29.84,29.83-29.84,29.83l-29.86-29.83v-21.58s22.22,22.2,22.22,22.2V8.21c0-2.32,1.88-4.21,4.2-4.21h6.87c2.32,0,4.21,1.88,4.21,4.21Z"
    />
  </svg>
);
export default DualWayIcon;
