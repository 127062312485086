import {
  AssignmentIssueSetFragment,
  ItemSetIssueSetFragment,
  LayoutIssueSetFragment,
  OrderedQuantityReportSummaryFragment,
  OrderSetIssueSetFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { t, TFunction } from 'i18next';
import { atomFamily, selectorFamily } from 'recoil';
import { HelpIndex as HelpId } from '../../../helpContext/help.default';
import useLoadAssignmentIssues from '../../hooks/useLoadAssignmentIssues';
import useLoadItemSetIssues from '../../hooks/useLoadItemSetIssues';
import useLoadLayoutIssues from '../../hooks/useLoadLayoutIssues';
import useLoadOrderedQuantityReport from '../../hooks/useLoadOrderedQuantityReport';
import useLoadOrderSetIssues from '../../hooks/useLoadOrderSetIssues';
import {
  simulationAssignmentIssues,
  simulationAssignmentIssuesLoadStatus,
  simulationItemSetIssues,
  simulationItemSetIssuesLoadStatus,
  simulationLayoutIssues,
  simulationLayoutIssuesLoadStatus,
  simulationOrderSetIssues,
  simulationOrderSetIssuesLoadStatus,
} from '../../store/simulation.issues.state';
import { IssuePanelConfig } from '../../store/simulation.issues.types';
import {
  orderedQuantityReport,
  orderedQuantityReportLoadStatus,
} from '../../store/simulationReport.state';
import {
  getAssignmentIssuesPanelConfigs,
  getItemsIssuesPanelConfigs,
  getLayoutIssuesPanelConfigs,
  getOrderedQuantityIssuesPanelConfigs,
  getOrdersIssuesPanelConfigs,
} from './datasetIssuesConfigs';

export type DataIssueGroup =
  | 'layout'
  | 'assignment'
  | 'itemSet'
  | 'orderSet'
  | 'orderedItems'
  | 'orderedQuantity';

export const simulationIssueSelected = atomFamily({
  key: 'simulationIssueSelected',
  default: null,
});

interface DataTypeConfig {
  title?: string;
  helpNavTo?: HelpId;
  loadIssues: () => void;
  getIssues: any;
  getIssuesLoadStatus: any;
  getIssuesPanelConfigs: (
    issues: any,
    t: TFunction<'simulation'>,
  ) => IssuePanelConfig[];
}

export const DataTypeConfigMap: Record<DataIssueGroup, DataTypeConfig> = {
  layout: {
    title: t`Layout`,
    helpNavTo: 'simulation/dataset/simulation-dataset-stats-layout',
    loadIssues: useLoadLayoutIssues,
    getIssues: simulationLayoutIssues,
    getIssuesLoadStatus: simulationLayoutIssuesLoadStatus,
    getIssuesPanelConfigs: getLayoutIssuesPanelConfigs,
  },
  assignment: {
    title: t`Assignment`,
    helpNavTo: 'simulation/dataset/simulation-dataset-stats-assignment',
    loadIssues: useLoadAssignmentIssues,
    getIssues: simulationAssignmentIssues,
    getIssuesLoadStatus: simulationAssignmentIssuesLoadStatus,
    getIssuesPanelConfigs: getAssignmentIssuesPanelConfigs,
  },
  itemSet: {
    title: t`Items`,
    helpNavTo: 'simulation/dataset/simulation-dataset-stats-items',
    loadIssues: useLoadItemSetIssues,
    getIssues: simulationItemSetIssues,
    getIssuesLoadStatus: simulationItemSetIssuesLoadStatus,
    getIssuesPanelConfigs: getItemsIssuesPanelConfigs,
  },
  orderSet: {
    title: t`Order Lines`,
    helpNavTo: 'simulation/dataset/simulation-dataset-stats-orders',
    loadIssues: useLoadOrderSetIssues,
    getIssues: simulationOrderSetIssues,
    getIssuesLoadStatus: simulationOrderSetIssuesLoadStatus,
    getIssuesPanelConfigs: getOrdersIssuesPanelConfigs,
  },
  orderedItems: {
    title: t`Ordered Items (Gap Report)`,
    helpNavTo: 'simulation/dataset/simulation-dataset-stats-orders',
    loadIssues: useLoadOrderedQuantityReport,
    getIssues: orderedQuantityReport,
    getIssuesLoadStatus: orderedQuantityReportLoadStatus,
    getIssuesPanelConfigs: getOrderedQuantityIssuesPanelConfigs,
  },
  orderedQuantity: {
    title: t`Order Gap Report`,
    helpNavTo: 'simulation/dataset/simulation-dataset-stats-orders',
    loadIssues: useLoadOrderedQuantityReport,
    getIssues: orderedQuantityReport,
    getIssuesLoadStatus: orderedQuantityReportLoadStatus,
    getIssuesPanelConfigs: getOrderedQuantityIssuesPanelConfigs,
  },
};

export const getIssuesLoadStatus = selectorFamily({
  key: 'getIssuesLoadStatus',
  get:
    (dataType: string) =>
    ({ get }) => {
      switch (dataType) {
        case 'layout':
          return get(simulationLayoutIssuesLoadStatus);
        case 'assignment':
          return get(simulationAssignmentIssuesLoadStatus);
        case 'itemSet':
          return get(simulationItemSetIssuesLoadStatus);
        case 'orderSet':
          return get(simulationOrderSetIssuesLoadStatus);
        case 'orderedItems':
          return get(orderedQuantityReportLoadStatus);
        case 'orderedQuantity':
          return get(orderedQuantityReportLoadStatus);
        default:
          throw new Error(`getIssuesLoadStatus Unknown data type: ${dataType}`);
      }
    },
});

export const getIssues = selectorFamily({
  key: 'getIssues',
  get:
    (dataType: string) =>
    ({ get }) => {
      switch (dataType) {
        case 'layout':
          return get(simulationLayoutIssues);
        case 'assignment':
          return get(simulationAssignmentIssues);
        case 'itemSet':
          return get(simulationItemSetIssues);
        case 'orderSet':
          return get(simulationOrderSetIssues);
        case 'orderedItems':
          return get(orderedQuantityReport);
        case 'orderedQuantity':
          return get(orderedQuantityReport);
        default:
          throw new Error(`getIssues Unknown data type: ${dataType}`);
      }
    },
});

export const useLoadIssues = (dataType: DataIssueGroup) => {
  switch (dataType) {
    case 'layout':
      return useLoadLayoutIssues();
    case 'assignment':
      return useLoadAssignmentIssues();
    case 'itemSet':
      return useLoadItemSetIssues();
    case 'orderSet':
      return useLoadOrderSetIssues();
    case 'orderedItems':
      return useLoadOrderedQuantityReport();
    case 'orderedQuantity':
      return useLoadOrderedQuantityReport();
    default:
      throw new Error(`useLoadIssues Unknown data type: ${dataType}`);
  }
};

export function getIssuesPanelConfigs(
  dataType: DataIssueGroup,
  issues:
    | AssignmentIssueSetFragment
    | ItemSetIssueSetFragment
    | LayoutIssueSetFragment
    | OrderSetIssueSetFragment
    | OrderedQuantityReportSummaryFragment,
  t: TFunction<'simulation'>,
  displayOption: string,
): IssuePanelConfig[] {
  let panelConfigs: IssuePanelConfig[] = [];
  switch (dataType) {
    case 'layout':
      panelConfigs = getLayoutIssuesPanelConfigs(
        issues as LayoutIssueSetFragment,
        t,
      );
      break;
    case 'assignment':
      panelConfigs = getAssignmentIssuesPanelConfigs(
        issues as AssignmentIssueSetFragment,
        t,
      );
      break;
    case 'itemSet':
      panelConfigs = getItemsIssuesPanelConfigs(
        issues as ItemSetIssueSetFragment,
        t,
      );
      break;
    case 'orderSet':
      panelConfigs = getOrdersIssuesPanelConfigs(
        issues as OrderSetIssueSetFragment,
        t,
      );
      break;
    case 'orderedItems':
      panelConfigs = getOrderedQuantityIssuesPanelConfigs(
        issues as OrderedQuantityReportSummaryFragment,
        t,
      );
      break;
    case 'orderedQuantity':
      panelConfigs = getOrderedQuantityIssuesPanelConfigs(
        issues as OrderedQuantityReportSummaryFragment,
        t,
      );
      break;
    default:
      throw new Error(`getIssuesPanelConfigs Unknown data type: ${dataType}`);
  }

  if (displayOption === 'all') {
    return panelConfigs;
  }

  return panelConfigs.filter(config => {
    if (displayOption === 'issues') {
      const share = 100 * (config.value / config.total);
      return share > config.threshold;
    }
    if (displayOption === 'stats') {
      return config.value > 0;
    }
    return false;
  });
}
