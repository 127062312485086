import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import HeaderDataset from '../components/headers/HeaderDataset';
import { ContainerCol } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import AssignmentDataView from './AssignmentDataView';
import useLoadAssignment from './hooks/useLoadAssignment';
import AssignmentMainSidebar from './sidebar/AssignmentMainSidebar';
import AssignmentMenuSidebar from './sidebar/AssignmentMenuSidebar';
import {
  assignmentDocument,
  assignmentDocumentLoadStatus,
} from './store/assignment.state';
export type AssignmentViewParams = {
  assignmentId: string;
};

const AssignmentView: React.FC = () => {
  const { assignmentId } = useParams<AssignmentViewParams>();
  const { t } = useTranslation('dataset');
  const documentAssignment = useRecoilValue(assignmentDocument);

  const documentLoadStatus = useRecoilValue(assignmentDocumentLoadStatus);
  const loadDocument = useLoadAssignment();

  useEffect(() => {
    loadDocument(assignmentId);
  }, [assignmentId]);

  return (
    <>
      <HeaderDataset dataType="assignment" document={documentAssignment} />

      <ContainerMain className="flex">
        {documentLoadStatus === AsyncLoadStatus.Ok ? (
          <>
            <ContainerSidebar side="left">
              <AssignmentMenuSidebar />
              <AssignmentMainSidebar />
            </ContainerSidebar>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton={true}
                  exception={error}
                />
              )}
            >
              <Suspense
                fallback={
                  <ContainerCol overflow flex1>
                    <PanelHeader isLoading title={t`Loading Assignments...`} />
                    <LoadingIndicator
                      className={classNames(
                        'bg-map-area-striped',
                        'border-app-background border-8',
                      )}
                      selfCenter
                      message={t`Loading Assignments`}
                    />
                  </ContainerCol>
                }
              >
                <AssignmentDataView />
              </Suspense>
            </ErrorBoundary>
            <ContainerSidebar side="right">
              {/* <AssignmentInfoSidebar /> */}
            </ContainerSidebar>
          </>
        ) : (
          <LoadingIndicator
            selfCenter
            message={t`Loading Assignment Data...`}
          />
        )}
      </ContainerMain>
    </>
  );
};

export default AssignmentView;
