import * as React from 'react';
const DataAssignmentIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataAssignmentIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M92.12,14.74v-4.56c0-1.47-1.18-2.62-2.62-2.62h-10.69c0-3.67-2.96-6.63-6.63-6.63s-6.63,2.96-6.63,6.63h-10.69c-1.44,0-2.62,1.15-2.62,2.62v4.56c0,1.44,1.18,2.62,2.62,2.62h34.62c1.44,0,2.62-1.18,2.62-2.62ZM74.81,7.57c0,1.44-1.18,2.62-2.62,2.62s-2.62-1.18-2.62-2.62c0-1.47,1.18-2.62,2.62-2.62s2.62,1.15,2.62,2.62Z"
    />
    <polygon
      strokeWidth={0}
      points="36.79 117.03 12.66 102.38 12.66 71.48 36.79 85.91 36.79 117.03"
    />
    <polygon
      strokeWidth={0}
      points="38.59 117.03 62.5 102.38 62.5 71.48 38.59 85.91 38.59 117.03"
    />
    <polygon
      strokeWidth={0}
      points="61.55 111.85 61.55 111.85 64.97 109.68 64.97 102.38 37.6 119.48 37.6 127.06 41.02 124.89 41.02 121.45 47.66 117.45 50.46 119.04 53.88 116.86 53.88 113.02 58.06 110.37 61.55 111.85"
    />
    <path
      strokeWidth={0}
      d="M10.23,102.38v7.3s27.37,17.38,27.37,17.38v-7.58s-27.37-17.1-27.37-17.1ZM11.53,106.74l24.76,15.47v2.48s-24.76-15.72-24.76-15.72v-2.23Z"
    />
    <polygon
      strokeWidth={0}
      points="51.2 76.55 62.5 69.86 37.58 55.39 26.81 61.65 51.2 76.55"
    />
    <polygon
      strokeWidth={0}
      points="25.7 62.29 12.66 69.86 37.58 84.62 50.1 77.2 25.7 62.29"
    />
    <path
      strokeWidth={0}
      d="M39.75,50.34l27.11,15.99v26.41s27.43,0,27.43,0h4.01c4.52,0,5.47-2.98,5.47-5.48V15.54c0-2.5-.94-5.48-5.46-5.48h-3.17s0,4.06,0,4.06c0,3.11-2.53,5.64-5.64,5.64h-34.62c-3.11,0-5.64-2.53-5.64-5.64v-4.06s-4.01,0-4.01,0c-4.52,0-5.47,2.98-5.47,5.48v34.8ZM95.64,41.58l-14.62,14.66h-11.03s10.72-10.75,10.72-10.75h-28.47s0-7.82,0-7.82h28.47s-10.74-10.75-10.74-10.75h11.03s14.64,14.65,14.64,14.65Z"
    />
  </svg>
);
export default DataAssignmentIcon;
