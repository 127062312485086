import React from 'react';
import { useRecoilState } from 'recoil';
import NotificationPopover from './components/NotificationPopover';
import { notificationCollection } from './store/notification.state';

const NotificationWatcher: React.FC = props => {
  const [notification, setNotification] = useRecoilState(
    notificationCollection,
  );

  return (
    <NotificationPopover
      notifications={notification}
      onClose={i => {
        setNotification(notification.filter((e, index) => index !== i));
      }}
    />
  );
};
export default NotificationWatcher;
