import * as React from 'react';
const CircleArrowBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowBottomIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M3.95,63.95c0,33.08,26.94,60.03,60.05,60.05,33.12.03,60.05-26.86,60.05-59.95,0-33.08-26.94-60.03-60.05-60.05C30.88,3.97,3.95,30.86,3.95,63.95ZM72.59,23.99v47.2s23.63-23.57,23.63-23.57v24.26s-32.23,32.14-32.23,32.14l-32.23-32.2v-24.26s23.63,23.61,23.63,23.61V23.97s17.19.02,17.19.02Z"
    />
  </svg>
);
export default CircleArrowBottomIcon;
