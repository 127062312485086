import classNames from 'classnames';
import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { MailTo } from '../components/actions/MailTo';

export type ContactSupportProps = PropsWithChildren & {
  className?: string;
};

const ContactSupport: React.FC<ContactSupportProps> = props => {
  return (
    <MailTo
      data-component="ContactSupport"
      email="support@warebee.com"
      subject="[WAREBEE] Support"
      body={t`Hello, I need support with the following: \n 1.`}
      className={classNames(props.className)}
    >
      <div>{t`Need Support?`}</div>
      <div
        className={classNames('ltr:ml-1 rtl:mr-1')}
      >{t`support@warebee.com`}</div>
      {props.children}
    </MailTo>
  );
};

export default ContactSupport;
