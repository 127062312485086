import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import AnalyzeCompareHeatmapPanel from '../../../analyzeCompare/panels/AnalyzeCompareHeatmapPanel';
import Tabs, { TabItem } from '../../../components/actions/Tabs';
import * as Icon from '../../../components/icons';
import { ComplianceTabId } from '../../store/simulation.types';
import { simulationComplianceTabKey } from '../../store/simulation.wizard.state';
import ComplianceAssignmentPanel from './ComplianceAssignmentPanel';
import ComplianceSizePanel from './ComplianceSizePanel';
import ComplianceVolumePanel from './ComplianceVolumePanel';
import ComplianceWeightPanel from './ComplianceWeightPanel';

const CompliancePanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [selectedTab, setSelectedTab] = useRecoilState(
    simulationComplianceTabKey,
  );
  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const tabItems: TabItem<ComplianceTabId>[] = [
    {
      id: 'tab-compliance-policy',
      title: t`Policy`,
      icon: <Icon.PolicyComplianceAssignmentArrow className={tabIconStyle} />,
      content: <ComplianceAssignmentPanel />,
    },
    {
      id: 'tab-compliance-volume',
      title: t`Volume`,
      icon: <Icon.PolicyComplianceVolume className={tabIconStyle} />,
      content: <ComplianceVolumePanel />,
    },
    {
      id: 'tab-compliance-weight',
      title: t`Weight`,
      icon: <Icon.PolicyComplianceWeight className={tabIconStyle} />,
      content: <ComplianceWeightPanel />,
    },
    {
      id: 'tab-compliance-size',
      title: t`Size`,
      icon: <Icon.PolicyComplianceSize className={tabIconStyle} />,
      content: <ComplianceSizePanel />,
    },
    {
      id: 'tab-analyze-heatmaps',
      title: t`Heatmaps`,
      icon: <Icon.PolicyComplianceSafety className={tabIconStyle} />,
      content: <AnalyzeCompareHeatmapPanel />,
    },
  ];

  return (
    <Tabs
      componentName="CompliancePanel"
      items={tabItems}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      hasScroll
      fullHeight
    />
  );
};

export default CompliancePanel;
