import * as React from 'react';
const ForecastTimeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ForecastTimeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M65.97,4.03C31.28,2.92,2.92,31.27,4.03,65.96c1,31.42,26.59,57,58,58.01,34.69,1.11,63.04-27.24,61.93-61.93-1-31.42-26.59-57-58-58.01ZM82.39,37.7l9.08-5.79c-7.65-6.57-17.28-10.16-27.47-10.16-11.29,0-21.9,4.4-29.88,12.38-7.98,7.98-12.38,18.59-12.38,29.88s4.4,21.9,12.38,29.88c7.98,7.98,18.59,12.38,29.88,12.38,10.61,0,20.63-3.89,28.42-10.99.84-.76,2.13-.72,2.93.08h0c1.3,1.3,1.25,3.45-.13,4.65-8.66,7.54-19.63,11.65-31.22,11.65-12.73,0-24.7-4.96-33.69-13.96-9-9-13.96-20.97-13.96-33.69s4.96-24.7,13.96-33.69c9-9,20.97-13.96,33.69-13.96,11.68,0,22.71,4.18,31.41,11.83l4.67-8.15,11.07,27.54-28.74-9.86ZM91.14,61.78c1.33,16.76-12.61,30.7-29.37,29.36-13.19-1.05-23.87-11.74-24.92-24.93-1.33-16.76,12.61-30.7,29.37-29.36,13.19,1.05,23.87,11.74,24.92,24.93Z"
    />
    <path
      strokeWidth={0}
      d="M48.7,62.54h-7.93c-.81,0-1.46.66-1.46,1.46s.66,1.46,1.46,1.46h7.93c.81,0,1.46-.66,1.46-1.46s-.66-1.46-1.46-1.46Z"
    />
    <path
      strokeWidth={0}
      d="M77.84,64c0,.39.15.76.43,1.04s.65.43,1.04.43h7.93c.81,0,1.46-.66,1.46-1.46s-.66-1.46-1.46-1.46h-7.93c-.81,0-1.46.66-1.46,1.46Z"
    />
    <path
      strokeWidth={0}
      d="M64,47.16c.39,0,.76-.15,1.04-.43.27-.27.43-.65.43-1.04v-4.93c0-.81-.66-1.46-1.46-1.46s-1.46.66-1.46,1.46v4.93c0,.39.15.76.43,1.04s.65.43,1.04.43Z"
    />
    <path
      strokeWidth={0}
      d="M65.04,66.93c.56-.21.99-.67,1.21-1.23.22-.55.22-1.17-.01-1.72l-8.64-10.03c-.48-1.15-1.8-1.68-2.95-1.2-1.15.48-1.68,1.8-1.2,2.95l8.64,10.03c.85,1.1,1.98,1.57,2.95,1.2Z"
    />
    <path
      strokeWidth={0}
      d="M62.75,65.74c.22.49.59.89,1.03,1.12s.91.26,1.32.08l8.03-4.4c.84-.36,1.15-1.48.69-2.5-.46-1.02-1.51-1.56-2.35-1.2l-8.03,4.4c-.4.17-.7.53-.83,1-.13.47-.08,1.01.14,1.5Z"
    />
    <path
      strokeWidth={0}
      d="M64,80.84c-.81,0-1.46.66-1.46,1.46v4.93c0,.81.66,1.46,1.46,1.46s1.46-.66,1.46-1.46v-4.93c0-.39-.15-.76-.43-1.04-.27-.27-.65-.43-1.04-.43Z"
    />
    <path
      strokeWidth={0}
      d="M50.02,47.95c-.57-.57-1.5-.57-2.07,0-.57.57-.57,1.5,0,2.07l2.07,2.07h0c.27.27.65.42,1.03.42.59,0,1.13-.35,1.35-.9.23-.55.1-1.18-.31-1.6l-2.07-2.07Z"
    />
    <path
      strokeWidth={0}
      d="M77.98,75.9c-.57-.57-1.5-.57-2.07,0s-.57,1.5,0,2.07l2.07,2.07h0c.57.57,1.5.57,2.07,0,.57-.57.57-1.5,0-2.07l-2.07-2.07Z"
    />
    <path
      strokeWidth={0}
      d="M77.98,47.95l-2.07,2.07h0c-.42.42-.54,1.05-.31,1.59.23.55.76.9,1.35.9.39,0,.76-.15,1.03-.42l2.07-2.07h0c.57-.57.57-1.5,0-2.07-.57-.57-1.5-.57-2.07,0h0Z"
    />
    <path
      strokeWidth={0}
      d="M50.02,75.9l-2.07,2.07h0c-.57.57-.57,1.5,0,2.07.57.57,1.5.57,2.07,0l2.07-2.07h0c.57-.57.57-1.5,0-2.07-.57-.57-1.5-.57-2.07,0h0Z"
    />
  </svg>
);
export default ForecastTimeIcon;
