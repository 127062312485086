import {
  ImportJobImportType,
  ImportJobSortOption,
  ImportPipelinesSortBy,
  SortDirection,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { importPipelineIsActiveEditor } from '../importJobs/store/importPipelines.state';
import useLoadImportPipelines from './hooks/useLoadImportPipelines';
import { ImportPipelinesTableColumn } from './store/importJobs.types';
import {
  importPipelineSelected,
  importPipelineSelectedId,
  importPipelinesData,
  importPipelinesDataStatus,
  importPipelinesDataTableState,
} from './store/importPipelines.state';
import useImportPipelinesTableConfig from './useImportPipelinesTableConfig';

const sortFieldsMapping: Partial<
  Record<ImportPipelinesTableColumn, ImportJobSortOption>
> = {
  createdAt: ImportJobSortOption.CREATED_AT,
  updatedAt: ImportJobSortOption.UPDATED_AT,
};

export type ImportPipelinesTableProps = {
  importType: ImportJobImportType;
  targetId?: string;
};

const ImportPipelinesTable: React.FC<ImportPipelinesTableProps> = props => {
  const { t } = useTranslation('simulation');
  const importPipelines = useRecoilValue(importPipelinesData);
  const selected = useRecoilValue(importPipelineSelected);
  const setSelectedId = useSetRecoilState(importPipelineSelectedId);
  const loadStatus = useRecoilValue(importPipelinesDataStatus);
  const [state, setState] = useRecoilState(importPipelinesDataTableState);
  const [runExportCSV] = useRunExportJobMutation();
  const [loadCallback, cancelLoad] = useLoadImportPipelines();
  const { searchValues, sortValues } = state;
  const columnsConfig = useImportPipelinesTableConfig();
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  // function getFilter(): ImportPipelinesItemFilter {
  //   return {
  //     status: searchValues?.status
  //       ? [searchValues.status as ImportPipelinesItemStatus]
  //       : null,
  //     locationRuleId: searchValues?.locationRuleIds
  //       ? [searchValues.locationRuleIds]
  //       : null,
  //     itemRuleId: searchValues?.itemRuleIds ? [searchValues.itemRuleIds] : null,
  //     violatedRuleId: searchValues?.violatedRuleIds
  //       ? [searchValues.violatedRuleIds]
  //       : null,
  //   };
  // }

  function getSortBy(): ImportPipelinesSortBy[] {
    return _.map(sortValues, (value, key, index) => {
      const sortObjectKey = sortFieldsMapping[key];
      return sortObjectKey ? { field: sortObjectKey, direction: value } : null;
    }).filter(i => !!i);
  }

  function callDataLoad(
    searchValues: Partial<Record<ImportPipelinesTableColumn, string>>,
    sortValues: Partial<Record<ImportPipelinesTableColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    //const filter: ImportPipelinesFilter = getFilter();
    const sortBy: ImportPipelinesSortBy[] = getSortBy();
    cancelLoad();
    loadCallback({
      importType: props.importType,
      targetId: props.targetId,
      //filter,
      //sortBy,
      ...page,
    });
  }

  // const converterConfig: ImportPipelinesConverterConfig = {
  //   columnsConfig,
  //   dictionaries: {
  //     rules: rulesMap,
  //   },
  // };

  // async function startExportCSV() {
  //   const variables: ImportPipelinesExportJobParams = {
  //     ...ImportPipelinesExportJobParams,
  //     query: LoadImportPipelinesItemsDocument.loc.source.body,
  //     config: converterConfig,
  //     variables: {
  //       simulationId,
  //       //filter: getFilter(),
  //       sortBy: getSortBy(),
  //       page: null,
  //     },
  //   };
  //   const { data, errors } = await runExportCSV({
  //     variables,
  //   });

  //   return {
  //     errors: errors,
  //     job: data.createExportJob,
  //   };
  // }

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = importPipelines?.content?.length || 0;
  const totalCount = importPipelines?.totalCount ?? 0;

  // const flattenData = getImportPipelinesTableRows(
  //   importPipelines?.content,
  //   converterConfig,
  // );
  const flattenData = importPipelines?.content;
  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  return (
    <Container hasOverflowY col>
      <DatasetTable
        id={'import-pipelines-table'}
        title={t`Pipelines`}
        subtitle={t`Integration`}
        childrenHeader={
          <div className={classNames('flex flex-1')}>
            <Button
              className={classNames('text-xs', 'rounded', 'mx-2')}
              label={t`Create new pipeline`}
              buttonType="primary"
              full
              buttonSize="sm"
              hasIconAfter
              buttonIcon={
                <Icon.CirclePlus
                  className={classNames('w-6 h-6', 'fill-current')}
                />
              }
              onPress={() => {
                setSelectedId(null);
                setShowEditor(true);
              }}
            />
          </div>
        }
        childrenNoItems={
          <div className={classNames('flex flex-1 mt-4')}>
            <Button
              className={classNames('text-xs', 'rounded', 'mx-2')}
              label={t`Create new pipeline`}
              buttonType="primary"
              full
              buttonSize="sm"
              hasIconAfter
              buttonIcon={
                <Icon.CirclePlus
                  className={classNames('w-6 h-6', 'fill-current')}
                />
              }
              onPress={() => {
                setSelectedId(null);
                setShowEditor(true);
              }}
            />
          </div>
        }
        //  data
        columnsConfig={columnsConfig}
        keyFields={['id']}
        data={flattenData}
        //  actions
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        onRowSelect={v => setSelectedId(v?.id)}
        selectedRow={selected}
        //onStartExportClick={startExportCSV}

        //  status
        isLoading={isLoading}
        //  style and design
        isActionable
        isSticky
        hasCounter
      />
    </Container>
  );
};

export default ImportPipelinesTable;
