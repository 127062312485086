import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import { analyticsAgent } from '../AnalyticTracker';
import * as Icon from '../components/icons';
import { HelpIndex } from './help.default';
import { helpSelectedId } from './help.state';

export type HelpNavigateProps = PropsWithChildren & {
  href: HelpIndex;
  className?: string;
};

const HelpNavigate: React.FC<HelpNavigateProps> = props => {
  const [helpKey, setHelpKey] = useRecoilState(helpSelectedId);
  const isSelected = props.href === helpKey;
  return (
    <a
      data-component="HelpNavigate"
      href="/"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        setHelpKey(props.href);

        try {
          analyticsAgent?.track(`Help: ${props.href}`);
        } catch (ex) {
          console.debug('analyticsAgent Error:', ex);
        }
      }}
      className={classNames(
        'hover:text-menu-active-link-hover hover:underline-offset-1',
        isSelected ? 'text-menu-active-link' : '',
        props.className,
      )}
    >
      {props.children ? (
        props.children
      ) : (
        <Icon.CircleHelp
          data-component="CircleHelp"
          className={classNames(
            'h-5 w-5',
            'cursor-pointer',
            'mx-2',
            // 'any-hover-none:opacity-75 any-hover:opacity-0 any-hover:group-hover:opacity-75',
            'fill-current',
            'transition duration-100 ease-in-out',
            isSelected
              ? 'text-menu-active any-hover:text-menu-hover ring-menu-active rounded-full ring-1'
              : 'text-menu-text/70 any-hover:hover:text-menu-active ring-0',
          )}
          alt={`Help`}
        />
      )}
    </a>
  );
};

export default HelpNavigate;
