/* eslint-disable react/jsx-no-useless-fragment */
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '../inputs/InputSearch';

export type StatListHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  value?: string;
  title?: string;
  isActionable?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  valueBefore?: boolean;
  valueAfter?: boolean;
  isComparable?: boolean;
  isPrimaryResult?: boolean;
  hasSearch?: boolean;
  searchValue?: string;
  onSearch?: (searchValue: string) => void;
};

export const StatListHeader = ({
  children,
  className,
  value,
  title,
  isActionable,
  onClick,
  isSelected,
  isComparable,
  hasSearch,
  isPrimaryResult,
  searchValue,
  onSearch,
}: StatListHeaderProps) => {
  const { t } = useTranslation('simulation');

  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="StatListHeader"
      className={classNames(
        'flex-1 group text-xs uppercase',
        isPrimaryResult ? 'text-menu/600' : 'text-menu-text/60',
        className,
      )}
      onClick={() => onClick && onClick()}
    >
      <div
        className={classNames(
          'flex items-end border-menu-100 border-solid border-b border-opacity-30 mt-0.5 py-1',
        )}
      >
        <div
          className={classNames(
            'flex-1 ltr:pr-1 rtl:pl-1 font-bold',
            isActionable
              ? isSelected && 'isSelected bg-menu-active text-menu-active-text'
              : '',
          )}
        >
          {hasSearch ? (
            <InputSearch
              titleMode
              headerStatMode
              placeholder={`${title}...`}
              value={searchValue || ''}
              onChange={v => onSearch(v)}
            />
          ) : (
            <>{title}</>
          )}
        </div>
        {value && (
          <>
            <div
              className={classNames(
                'truncate text-end font-bold',
                'lg:min-w-20 xl:min-w-24',
                // 'lg:min-w-24 xl:min-w-32',
                'ltr:xl:pl-2 rtl:xl:pr-2 ltr:pr-1 rtl:pl-1',
                { 'bg-menu-active text-menu-active-text': isActionable },
              )}
            >
              {value}
              {isComparable && (
                <div className={`flex text-menu-100 text-xs text-opacity-80`}>
                  <div className={`flex-1`}>{t`Before`}</div>
                  <div className={``}>
                    <span className={`text-menu-300 mx-1`}>{` | `}</span>
                    {t`After`}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {children}
    </div>
  );
};
