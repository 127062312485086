import * as React from 'react';
const Mhe9CTurretTruckOperatorUpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe9CTurretTruckOperatorUpIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M91.71,111.09H2v2.42c.08,1.05.61,1.61,1.57,1.69h.61c0-.08,0-.04,0,.12.08,1.05.52,1.92,1.33,2.6.81.69,1.74,1.01,2.78.97s1.92-.4,2.6-1.09,1.07-1.51,1.15-2.48v-.12h79.66c1.05-.08,1.61-.65,1.69-1.69v-.73c-.08-1.13-.65-1.69-1.69-1.69h0ZM125.61,104.31h-27.24c-.65-.08-1.05-.48-1.21-1.21v-7.63c0-.56-.2-1.05-.61-1.45-.4-.4-.89-.61-1.45-.61h0v-26.63c-.08-1.37-.56-2.5-1.45-3.39-.89-.89-1.98-1.33-3.27-1.33h-22.4v-30.75c0-1.05.34-1.9,1.03-2.54s1.51-.97,2.48-.97h15.62c.89,0,1.69-.32,2.42-.97l1.57-1.33c.24-.24.28-.46.12-.67-.16-.2-.28-.3-.36-.3h-26.39v76.39h29.54c.65.08,1.01.44,1.09,1.09h0v3.87h26.39l.97-.12,3.27-.97c.24-.08.32-.2.24-.36-.08-.16-.16-.22-.24-.18l-.12.06h0ZM53.21,28.65h2.18v80.87h7.38V18.96h2.78v-10.17h-10.17v12.83h-2.18c-.65.08-1.01.44-1.09,1.09v4.84c.08.65.44,1.01,1.09,1.09h0ZM53.69,70.42h-27.36c-2.18,0-4.26.36-6.23,1.09-1.98.73-3.73,1.82-5.27,3.27l-10.41,9.2c-1.61,1.53-2.42,3.35-2.42,5.45v19.98h51.82v-38.98h-.12ZM82.99,110.25h7.02c-1.05-.89-2.24-1.33-3.57-1.33s-2.48.44-3.45,1.33h0ZM86.5,119.21c1.05,0,2.02-.3,2.91-.91s1.49-1.39,1.82-2.36h-9.56c.4.97,1.05,1.76,1.94,2.36s1.86.91,2.91.91h0Z"
    />
  </svg>
);
export default Mhe9CTurretTruckOperatorUpIcon;
