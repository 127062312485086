import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import useLoadItemSetFilterValues from '../hooks/useLoadItemSetFilterValues';
import ItemSetPolicyFilterEditor from '../policy/ItemSetPolicyFilterEditor';
import { itemSetPalletSortFilterConfig } from '../store/itemSet.default';

export const ItemSetEditorPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [startLoadData, cancelLoadData] = useLoadItemSetFilterValues();

  return (
    <ContainerScroll>
      <InputGroupList hasSpacing></InputGroupList>
      <InputGroupList>
        {_.map(itemSetPalletSortFilterConfig(t), filterConfig => {
          return (
            <ItemSetPolicyFilterEditor
              key={`item-set-filter-${filterConfig.type}`}
              filterConfig={filterConfig}
              startLoadData={startLoadData}
              cancelLoadData={cancelLoadData}
            />
          );
        })}
      </InputGroupList>
    </ContainerScroll>
  );
};

export default ItemSetEditorPanel;
