import {
  AssignmentPolicyInput,
  AssignmentPolicyRuleInput,
  LocationSharingPolicyFragment,
  LocationSharingPolicyInput,
  LocationSharingPolicyRuleFragment,
  LocationSharingPolicyRuleInput,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { cloneWithoutTypename } from '../../../common/utils';

export const LSP_FALLBACK_RULE_ID = 'location-sharing-policy-fallback-rule-id';

export function getLocationSharingPolicyRuleInput(
  rule: LocationSharingPolicyRuleFragment,
): LocationSharingPolicyRuleInput {
  if (!rule) return null;
  const locationsMatchAnyOf = _.filter(
    rule.locationsMatch?.anyOf,
    intersection => intersection?.allOf?.length > 0,
  );
  return {
    id: rule.id,
    title: rule.title,
    maxItemsPerLocation: rule.maxItemsPerLocation,
    locationsMatch: {
      anyOf: cloneWithoutTypename(locationsMatchAnyOf),
    },
  };
}

export function getLocationSharingPolicyInput(
  policy: LocationSharingPolicyFragment,
): LocationSharingPolicyInput {
  return {
    rules: policy.rules.map(getLocationSharingPolicyRuleInput),
    defaultMaxItemsPerLocation: policy.defaultMaxItemsPerLocation,
  };
}

export function getDefaultLocationSharingPolicy(): LocationSharingPolicyFragment {
  return {
    rules: [],
    defaultMaxItemsPerLocation: 1,
  };
}

function getAPRuleFromLSPRule(
  rule: LocationSharingPolicyRuleFragment,
): AssignmentPolicyRuleInput {
  if (!rule) return null;
  const locationsMatchAnyOf = rule.locationsMatch.anyOf.filter(
    intersection => intersection.allOf.length > 0,
  );
  return {
    id: rule.id,
    title: rule.title,
    productsMatch: {
      anyOf: [],
    },
    locationsMatch: {
      anyOf: cloneWithoutTypename(locationsMatchAnyOf),
    },
  };
}

export function getAssignmentPolicyInputFromLSP(
  policy: LocationSharingPolicyFragment,
): AssignmentPolicyInput {
  return {
    rules: _.map(policy.rules, getAPRuleFromLSPRule),
    fallbackLocationsMatch: null,
  };
}
