import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import PanelContainer from '../../../containers/PanelContainer';
import {
  getLayoutCachingOptions,
  getLayoutCachingSizeOptions,
  ViewerCachingOption,
  ViewerCachingSizeOption,
} from '../../../layout/viewer/store/viewer.helper';
import { viewerLayoutCachingSettings } from '../../../layout/viewer/store/viewer.state';

export type SettingsProps = {
  children?: React.ReactNode;
};

export const PanelSystemPerformance = ({ children }: SettingsProps) => {
  const { t } = useTranslation('app');

  const [cachingSettings, setCachingSettings] = useRecoilState(
    viewerLayoutCachingSettings,
  );

  const cachingTypesOptions = _.keyBy(getLayoutCachingOptions(t), i => i.id);
  const cachingSizeOptions = _.keyBy(getLayoutCachingSizeOptions(t), i => i.id);

  return (
    <PanelContainer
      id="panel-system-performance"
      title={t`Performance`}
      collapsible
    >
      <ScreenTitle
        isSticky
        subtitle={t`System`}
        title={t`Performance and caching`}
      />
      <section className="divide-menu-400/50 mb-4 w-full divide-y divide-solid p-4">
        <DropdownSelector
          className="my-4 text-base"
          classNameLabel="flex-1"
          classNameValue="relative"
          headerMode
          label={t('Layout caching')}
          value={cachingSettings.type}
          values={_.keys(cachingTypesOptions) as ViewerCachingOption[]}
          renderValue={v => cachingTypesOptions[v]?.title || ''}
          onChange={v =>
            setCachingSettings({
              ...cachingSettings,
              type: v,
            })
          }
        />
        <DropdownSelector
          className="my-4 text-base"
          classNameLabel="flex-1"
          headerMode
          label={t('Cache size')}
          disabled={cachingSettings.type === 'off'}
          value={cachingSettings.size}
          values={_.keys(cachingSizeOptions) as ViewerCachingSizeOption[]}
          renderValue={v => cachingSizeOptions[v]?.title || ''}
          onChange={v =>
            setCachingSettings({
              ...cachingSettings,
              size: v,
            })
          }
        />
      </section>
    </PanelContainer>
  );
};
