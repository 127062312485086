import * as React from 'react';
const BoxOrdersIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BoxOrdersIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M107.71,81.7l-41.21-5.18,12.7,14.07,41.26,6.18-12.75-15.07h0ZM106.55,98.82c-27.71-2.8-25.63-1.94-27.52-1.74-.51.05-13.31-14.77-13.31-14.77v43.8l40.83-13.56v-13.73Z"
    />
    <path
      strokeWidth={0}
      d="M18.26,81.7l-12.75,15.07,41.26-5.18,12.7-15.07-41.21,5.18h0ZM19.42,112.55l40.83,13.56v-43.8s-12.8,13.82-13.31,13.77c-1.88-.2.19-.05-27.52,2.74v13.73Z"
    />
    <path
      strokeWidth={0}
      d="M47.76,20.9h32.47c1.35,0,2.46-1.11,2.46-2.46v-4.27c0-1.38-1.11-2.46-2.46-2.46h-10.02c0-3.44-2.78-6.21-6.21-6.21s-6.21,2.78-6.21,6.21h-10.02c-1.35,0-2.46,1.08-2.46,2.46v4.27c0,1.35,1.11,2.46,2.46,2.46ZM64,9.25c1.35,0,2.46,1.08,2.46,2.46s-1.1,2.46-2.46,2.46-2.46-1.11-2.46-2.46,1.11-2.46,2.46-2.46Z"
    />
    <path
      strokeWidth={0}
      d="M63.66,71.59l33.69,4.93V20.72c0-2.71-2.19-4.9-4.93-4.9h-7.71v2.12c0,2.66-2.17,4.83-4.81,4.83h-32.47c-2.66,0-4.83-2.17-4.83-4.83v-2.12h-7.71c-2.71,0-4.93,2.19-4.93,4.9v55.8l33.69-4.93ZM63.15,32.4h19v3.36h-19v-3.36ZM63.15,39.47h28.29v3.36h-28.29v-3.36ZM37.04,41.59h2.09v-8.57h-2.09v8.57ZM59.25,33.02h-2.34v8.57h2.34v-8.57ZM42.87,38.73h3.67v-5.7h-3.67v5.7ZM40.11,38.73h1.17v-5.7h-1.17v5.7ZM50.04,38.73h1.17v-5.7h-1.17v5.7ZM52.52,38.73h1.17v-5.7h-1.17v5.7ZM55.17,38.73h.85v-5.7h-.85v5.7ZM47.56,38.73h1.17v-5.7h-1.17v5.7ZM35.14,42.23v-9.08c0-.72.59-1.31,1.31-1.31h22.64c.72,0,1.31.59,1.31,1.31v9.08c0,.72-.59,1.31-1.31,1.31h-22.64c-.72,0-1.31-.59-1.31-1.31ZM63.15,52.17h19v3.36h-19v-3.36ZM63.15,59.24h28.29v3.36h-28.29v-3.36ZM37.04,61.37h2.09v-8.57h-2.09v8.57ZM59.25,52.8h-2.34v8.57h2.34v-8.57ZM42.87,58.5h3.67v-5.7h-3.67v5.7ZM40.11,58.5h1.17v-5.7h-1.17v5.7ZM50.04,58.5h1.17v-5.7h-1.17v5.7ZM52.52,58.5h1.17v-5.7h-1.17v5.7ZM55.17,58.5h.85v-5.7h-.85v5.7ZM47.56,58.5h1.17v-5.7h-1.17v5.7ZM35.14,62v-9.08c0-.72.59-1.31,1.31-1.31h22.64c.72,0,1.31.59,1.31,1.31v9.08c0,.72-.59,1.31-1.31,1.31h-22.64c-.72,0-1.31-.59-1.31-1.31Z"
    />
  </svg>
);
export default BoxOrdersIcon;
