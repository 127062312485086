import {
  AgentCapacityUomSettingsFragment,
  AgentCapacityUomSettingsInput,
  AgentRole,
  AgentRoleSettingsFragment,
  AgentSettingsInput,
  MeasurementSystem,
  UomHandlingSettingsFragment,
  UomHandlingSettingsInput,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { HTMLAttributes } from 'react';
import { cloneWithoutTypename } from '../../common/utils';
import * as Icon from '../../components/icons';
import { agentAllRoles } from '../../simulation/store/resourcePolicy.helper';
import advancedMask from '../agentPropertiesSet/advanced.json';
import simplMask from '../agentPropertiesSet/simple.json';
import { agentCategoriesMap } from '../data/categories/categories';
import { agentEventSettingsMap } from '../data/eventsDefaults/agentEventsSettings';
import { agentManufacturersMap } from '../data/manufacturers/manufacturers';
import { agentModelsMap } from '../data/models/models';
import {
  AgentCategoryId,
  AgentEventSettingsDefinition,
  AgentSettingsWithMeta,
} from './agent.types';
import { AgentSettingsDefault } from './agentSettings.default';
import { currencyDefaults } from './costDefaults';
import utilisationDefault from './utilisationDefaults';

type AgentConfigurationType = 'simple' | 'advanced';

const AgentConfigurationSettings: Record<
  AgentConfigurationType,
  Partial<AgentSettingsWithMeta>
> = {
  simple: simplMask,
  advanced: advancedMask,
};

export function maskBy(source, mask) {
  if (mask === 1) {
    return source;
  }
  return Object.keys(source)
    .map(key => {
      if (mask[key]) {
        if (typeof mask[key] === 'object') {
          return { [key]: maskBy(source[key], mask[key]) };
        }
        return { [key]: source[key] };
      }
      return {};
    })
    .reduce((acc, obj) => Object.assign({}, acc, obj), {});
}

export function getAgentTypeTitle(
  agentTypeId: string,
  t: TFunction<'simulation'>,
): string {
  switch (agentTypeId) {
    case 'manual-picker':
      return t('Manual Picker', { ns: 'simulation' });
    case 'forklift':
      return t('Forklift', { ns: 'simulation' });
    case 'trolley-picker':
      return t('Trolley', { ns: 'simulation' });
    default:
      return agentCategoriesMap[agentTypeId]?.title ?? agentTypeId;
  }
}

export function getAgentIcon(
  agentTypeId: AgentCategoryId,
): React.FC<HTMLAttributes<Element>> {
  if (_.isNil(agentTypeId)) return Icon.AgentWalking;

  switch (agentTypeId) {
    // case 'manual-picker':
    //   return Icon.;
    case 'picker-manual':
      return Icon.AgentWalking;
    case 'picker-voice':
      return Icon.PickingAgentHeadset;
    case 'forklift-sit-down-electric':
      return Icon.Mhe6AForkliftSitDownElectric3Wheel;
    case 'forklift-seat-tire-cushion':
      return Icon.Mhe6BForkliftSitDownFuelDiesel;
    case 'forklift-seat-tire-pneumatic':
      return Icon.Mhe6BForkliftSitDownFuelGas;
    case 'forklift-stand':
      return Icon.Mhe2BVna;
    case 'pallet-truck':
      return Icon.Mhe4APalletTruck;
    case 'walkie-stalker-electric':
      return Icon.Mhe3BWalkieStalkerElectric;
    case 'walkie-stalker-manual':
      return Icon.Mhe3AWalkieStalkerManual;
    case 'platform-truck-walkie':
      return Icon.Mhe5BWalkiePlatformTruckElectric;
    case 'platform-truck-rider':
      return Icon.Mhe5BWalkiePlatformTruckElectric; //fix icon
    case 'pallet-jack-manual':
      return Icon.Mhe2APalletJackManual;
    case 'pallet-jack-electric':
      return Icon.Mhe2BPalletJackPoweredElectric;
    case 'straddle-truck_narrow-aisle':
      return Icon.Mhe9BTurretTruckOperatorDown; //fix icon
    case 'turret-truck-operator-down':
      return Icon.Mhe9BTurretTruckOperatorDown;
    case 'turret-truck-operator-up':
      return Icon.Mhe9BTurretTruckOperatorDown;
    case 'turret-truck':
      return Icon.Mhe9BTurretTruckOperatorDown;
    case 'forklift-reach-truck':
      return Icon.Mhe8AReachTruck;
    case 'side-loader':
      return Icon.Mhe10AOrderPicker; //fix icon
    case 'order-picker':
      return Icon.Mhe10AOrderPicker;
    case 'trolley-manual':
    case 'trolley-manual-two-wheeled':
      return Icon.MheTrolley;
    case 'trolley-four-wheeled-electric':
    case 'trolley-manual-four-wheeled':
      return Icon.Mhe1BDollyManualFourWheeled;
    case 'dolly':
      return Icon.MheTrolley;
    case 'conveyors-trolley':
    case 'conveyors':
    case 'automatic-guided-vehicle-agv':
      return Icon.Mhe14BAgvTow;
    case 'amr-autonomous-manoeuvrable-robots':
      return Icon.Mhe15AutomaticMobileRobot;
    default:
      return Icon.MheForklift1; // defaultIcon ;
  }
}

export function getMaxUOMCapacityDefaults(
  uoms: string[],
): Partial<AgentCapacityUomSettingsFragment[]> {
  const countPerUom: AgentCapacityUomSettingsFragment[] = _.map(uoms, uom => ({
    uom,
    quantity: null,
  }));

  return countPerUom;
}

export function getTimePerUomDefaults(
  uoms: string[],
): Partial<AgentRoleSettingsFragment> {
  const timesPerUom: UomHandlingSettingsFragment[] = _.map(uoms, uom => ({
    uom,
    timePerUom: null,
  }));

  return {
    handling: {
      timePerUom: _.cloneDeep(timesPerUom),
    },
    reordering: {
      timePerUom: _.cloneDeep(timesPerUom),
    },
  };
}

export function combineSettings(
  def: AgentEventSettingsDefinition,
  roles: AgentRole[],
  uoms: string[],
  system: MeasurementSystem,
): Partial<AgentSettingsWithMeta> {
  const systemProperty /*: keyof typeof settingsFromModels.eventDefaults */ =
    system === MeasurementSystem.IMPERIAL ? 'imperial' : 'metric';
  const modelSettings = def?.eventDefaults?.[systemProperty];
  const settings: Partial<AgentSettingsWithMeta> = {
    ...modelSettings,

    specs: {
      ...modelSettings?.specs,
      capacity: {
        ...modelSettings?.specs?.capacity,
        maxUomQuantity: getMaxUOMCapacityDefaults(uoms),
      },
    },
    roles: _.map(roles, role => {
      return _.defaultsDeep(
        {},
        {
          ...def?.roleDefaults?.[systemProperty],
          roleId: role,
        },
        getTimePerUomDefaults(uoms),
      );
    }),
  };
  return settings;
}

export function getModelSettings(
  modelId: string,
  uoms: string[],
  system: MeasurementSystem,
  currencyCode: string,
  configurationType?: AgentConfigurationType,
): Omit<AgentSettingsWithMeta, 'id' | 'title'> {
  const model = agentModelsMap[modelId];
  if (_.isNil(model)) {
    throw new Error('Cannot find model with id: ' + modelId);
  }

  const settingsFromModels = combineSettings(
    agentEventSettingsMap[model.settingId],
    agentAllRoles,
    uoms,
    system,
  );

  const mnfr = agentManufacturersMap[model.manufacturerId];

  const settingsFromMnfr = mnfr?.settingId
    ? combineSettings(
        agentEventSettingsMap[mnfr.settingId],
        agentAllRoles,
        uoms,
        system,
      )
    : {};

  const category = agentCategoriesMap[model.categoryId];

  const settingsFromCategory = category?.eventSettingsId
    ? combineSettings(
        agentEventSettingsMap[category?.eventSettingsId],
        agentAllRoles,
        uoms,
        system,
      )
    : {};

  const settingsDefault = combineSettings(
    AgentSettingsDefault,
    agentAllRoles,
    uoms,
    system,
  );

  const combined = _.defaultsDeep(
    {},
    settingsFromModels,
    settingsFromMnfr,
    settingsFromCategory,
    currencyDefaults.get(currencyCode),
    utilisationDefault,
    settingsDefault,
  );
  // if (!_.isNil(configurationType)) {
  //   combined = maskBy(combined, AgentConfigurationSettings[configurationType]);
  // }
  return combined;
}

function updateTimesPerUom(
  timePerUom: Array<UomHandlingSettingsInput>,
  newUoms: string[],
): Array<UomHandlingSettingsInput> {
  //uomsToAdd
  return [
    ...(timePerUom ?? []),
    ..._(newUoms)
      .filter(uom => _.every(timePerUom, tpu => tpu.uom !== uom))
      .map(uom => ({
        uom,
        timePerUom: null,
      }))
      .value(),
  ];
}

function updateQuantityPerUom(
  quantityPerUom: Array<AgentCapacityUomSettingsInput>,
  newUoms: string[],
): Array<AgentCapacityUomSettingsInput> {
  //uomsToAdd
  return [
    ...(quantityPerUom ?? []),
    ..._(newUoms)
      .filter(uom => _.every(quantityPerUom, tpu => tpu.uom !== uom))
      .map(uom => ({
        uom,
        quantity: null,
      }))
      .value(),
  ];
}

export function updateAgentUoms(agent: AgentSettingsWithMeta, uoms: string[]) {
  const updatedAgent: AgentSettingsInput = cloneWithoutTypename(agent);

  _.forEach(updatedAgent.roles, role => {
    role.handling.timePerUom = updateTimesPerUom(
      role.handling.timePerUom,
      uoms,
    );
    role.reordering.timePerUom = updateTimesPerUom(
      role.reordering.timePerUom,
      uoms,
    );
  });

  updatedAgent.specs.capacity = {
    ...updatedAgent.specs.capacity,
    maxUomQuantity: updateQuantityPerUom(
      updatedAgent.specs.capacity?.maxUomQuantity,
      uoms,
    ),
  };

  return updatedAgent;
}
