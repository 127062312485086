import {
  LoadAssignmentComplianceSummaryDocument,
  LoadAssignmentComplianceSummaryQuery,
  LoadAssignmentComplianceSummaryQueryVariables,
  RunCalculateAssignmentComplianceDocument,
  RunCalculateAssignmentComplianceMutation,
  RunCalculateAssignmentComplianceMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  assignmentComplianceSummaryAtom,
  assignmentComplianceSummaryLoadStatusAtom,
  getComplianceKey,
} from '../store/assignmentCompliance.state';

export type LoadAssignmentComplianceSummaryParams = {
  simulationId: string;
  assignmentId: string;
  forceRestart?: boolean;
};

function useLoadAssignmentComplianceSummary() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load storage compliance summary`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const getKey = (params: LoadAssignmentComplianceSummaryParams) =>
    getComplianceKey(params.simulationId, params.assignmentId);

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadAssignmentComplianceSummaryParams) => {
        set(
          assignmentComplianceSummaryLoadStatusAtom(getKey(params)),
          AsyncLoadStatus.Loading,
        );
      },
  );

  const callLoad = useRecoilCallback(
    ({ set }) =>
      async (params: LoadAssignmentComplianceSummaryParams) => {
        const complianceKey = getKey(params);
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(
            assignmentComplianceSummaryLoadStatusAtom(complianceKey),
            AsyncLoadStatus.Error,
          );
          return;
        }

        const query = secureClient.watchQuery<
          LoadAssignmentComplianceSummaryQuery,
          LoadAssignmentComplianceSummaryQueryVariables
        >({
          query: LoadAssignmentComplianceSummaryDocument,
          variables: {
            simulationId: params.simulationId,
            assignmentId: params.assignmentId,
          },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let compliance = data?.simulation?.assignmentCompliance;
            if (_.isNil(compliance) || params.forceRestart) {
              try {
                const response = await secureClient.mutate<
                  RunCalculateAssignmentComplianceMutation,
                  RunCalculateAssignmentComplianceMutationVariables
                >({
                  mutation: RunCalculateAssignmentComplianceDocument,
                  variables: {
                    simulationId: params.simulationId,
                    assignmentId: params.assignmentId,
                  },
                });

                compliance =
                  response.data?.calculateSimulationAssignmentCompliance;
              } catch (ex) {
                console.error(errorTitle, ex);
                handleError(ex.message || ex, ex.stack || null);
              }
            }

            set(assignmentComplianceSummaryAtom(complianceKey), compliance);
            set(
              assignmentComplianceSummaryLoadStatusAtom(complianceKey),
              AsyncLoadStatus.Ok,
            );
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentComplianceSummaryParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAssignmentComplianceSummary;
