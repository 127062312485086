import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListItem } from '../../components/stats/StatListItem';
import { feedDisabledAgentStatsFields } from '../store/feed.state';
import { FeedStats } from '../store/feed.types';
import {
  FeedAgentStatsCategory,
  getFeedAgentsStatsDesc,
} from '../store/feedStats.default';

export type FeedKPIStatsProps = {
  value: FeedStats;
  category: FeedAgentStatsCategory;
  isSelected: boolean;
};

const FeedKPIStats: React.FC<FeedKPIStatsProps> = props => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const statsDescriptors = getFeedAgentsStatsDesc(t, formatter);
  const disabledStats = useRecoilValue(feedDisabledAgentStatsFields);

  const disabledStatsSet = new Set(disabledStats);
  const statItems = _.filter(
    statsDescriptors,
    item => item.category === props.category && !disabledStatsSet.has(item.id),
  );

  return (
    <StatGroup
      classNameStatGroupItems={classNames(
        'divide-y divide-solid divide-menu/75',
      )}
    >
      {statItems.map(item => {
        const value = props.value[item.id];
        return (
          <StatListItem
            key={item.id}
            isSelected={props.isSelected}
            // className="opacity-75"
            title={item.title}
            value={_.isNumber(value) ? item.getFormatted(value) : value}
            unitOfMeasure={item.unitOfMeasure ?? ''}
            inStatView
          />
        );
      })}
    </StatGroup>
  );
};

export default FeedKPIStats;
