import * as React from 'react';
const ArrowRightBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowRightBottomIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M106.37,106.42l-68.24.02-25.71-25.72,50.04-.02L12.45,30.66,30.67,12.45l50.01,50.04.05-50.03,25.71,25.72-.06,68.23Z"
    />
  </svg>
);
export default ArrowRightBottomIcon;
