import * as React from 'react';
const PalletStackingResortHeatmapDirectIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletStackingResortHeatmapDirectIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M125.87,113.22v-5.77s-65.25,0-65.25,0v5.77h3.35s0,7.22,0,7.22h-3.35v5.77s65.25,0,65.25,0v-5.77h-3.35s0-7.22,0-7.22h3.35ZM69.12,113.22h21.56s0,7.22,0,7.22h-21.56s0-7.22,0-7.22ZM95.82,120.45v-7.22s21.56,0,21.56,0v7.22s-21.56,0-21.56,0Z"
    />
    <polygon
      strokeWidth={0}
      points="46.25 30.45 56.31 43.58 46.23 56.71 37.88 56.71 45.85 46.79 26.84 46.79 26.84 40.38 45.86 40.38 37.9 30.45 46.25 30.45"
    />
    <polygon
      strokeWidth={0}
      points="33.96 96.37 23.9 83.24 33.98 70.1 42.32 70.1 34.35 80.03 54.82 80.03 54.82 86.44 34.35 86.44 42.31 96.37 33.96 96.37"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M123.58,104.84v-41.28s-36.14,0-36.14,0v41.28s36.14,0,36.14,0Z"
    />
    <path
      strokeWidth={0}
      d="M83.04,104.84v-41.28s-20.27,0-20.27,0v41.28s20.27,0,20.27,0Z"
    />
    <path
      strokeWidth={0}
      d="M105.72,26.51v30.81s17.86,0,17.86,0v-30.81s-17.86,0-17.86,0Z"
    />
    <path
      strokeWidth={0}
      d="M62.95,26.51v30.81s37.76,0,37.76,0v-30.81s-37.76,0-37.76,0Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M88,2.85v18.3s35.58,0,35.58,0V2.85s-35.58,0-35.58,0Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M62.95,2.85v18.3s20.04,0,20.04,0V2.85s-20.04,0-20.04,0Z"
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M19.08,104.84v-41.28s-17.44,0-17.44,0v41.28s17.44,0,17.44,0Z"
    />
    <path
      strokeWidth={0}
      d="M1.39,26.51v30.81h17.69s0-30.81,0-30.81H1.39Z"
      opacity={0.6}
    />
    <polygon
      strokeWidth={0}
      points="23.53 113.22 23.53 107.45 .7 107.45 .7 113.23 13.03 113.23 13.03 120.45 .7 120.45 .7 126.22 23.53 126.22 23.53 120.45 20.18 120.45 20.18 113.22 23.53 113.22"
    />
  </svg>
);
export default PalletStackingResortHeatmapDirectIcon;
