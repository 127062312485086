import * as React from 'react';
const CongestionMapCirclesIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CongestionMapCirclesIcon"
    {...props}
  >
    <circle
      cx={44}
      cy={80.52}
      r={40}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-18.72 13.54) rotate(-14.46)"
    />
    <circle
      cx={84}
      cy={80.52}
      r={40}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-17.45 23.53) rotate(-14.46)"
    />
    <circle
      cx={64}
      cy={47.48}
      r={40}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-9.83 17.49) rotate(-14.46)"
    />
  </svg>
);
export default CongestionMapCirclesIcon;
