import { WaypointType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import RouteFeature from '../../layout/features/RouteFeature';
import { stageStateById } from '../../layout/stage/stage.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { actualityJobRoute } from '../store/actuality.jobs.state';
import { feedSelectedPickListId } from '../store/feed.state';

const ActivityRoutesLayer: React.FC = () => {
  const jobId = useRecoilValue(feedSelectedPickListId);
  const activeRouteLoadable = useRecoilValueLoadable(actualityJobRoute(jobId));
  const stageState = useRecoilValue(stageStateById('viewer-area-view'));
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);

  if (activeRouteLoadable.state !== 'hasValue') return null;
  const waypoints = activeRouteLoadable.contents;

  if (_.isEmpty(waypoints)) return null;

  let index = 1;
  return (
    <Layer>
      <RouteFeature
        key={`job-route-${jobId}`}
        waypoints={_.map(waypoints, (w, i) => ({
          ...w,
          label: w.type === WaypointType.LOCATION ? `${index++}` : null,
        }))}
        animate
        showLabels
        scale={0.1 / stageState.scale}
      />
    </Layer>
  );
};

export default ActivityRoutesLayer;
