import * as React from 'react';
const MenuItemShapeBottomShortIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 36.99"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuItemShapeBottomShortIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M0,0v11.09c0,2.29,1.56,4.29,3.79,4.85l60.21,18.05,60.21-18.05c2.23-.56,3.79-2.56,3.79-4.85V0H0Z"
    />
  </svg>
);
export default MenuItemShapeBottomShortIcon;
