import * as React from 'react';
const WarehouseLabIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseLabIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M42.85,61.84l-.63-.21v-16.99h-4.19v16.99l-.63.21c-3.99,1.26-6.71,4.82-6.71,9.02,0,1.05.21,2.1.63,3.15h17.62c.42-1.05.63-2.1.63-3.15,0-4.19-2.73-7.76-6.71-9.02ZM34.88,70.86h-2.1c0-3.15,1.89-5.87,5.03-6.71l.63,2.1c-2.1.63-3.57,2.52-3.57,4.61Z"
    />
    <path
      strokeWidth={0}
      d="M64.5,13.87L.84,30.3v62.58h126.32V30.3l-62.66-16.43ZM15.59,82.39c-.84,0-2.1-.63-2.52-1.47-.42-.84-.63-1.68-.42-2.73l5.45-18.04v-7.13c-.63,0-1.05-.42-1.05-1.05s.42-1.05,1.05-1.05h10.49c.63,0,1.05.42,1.05,1.05s-.42,1.05-1.05,1.05v7.13l.84,2.52c-.63.63-1.05,1.47-1.47,2.31l-1.47-4.61v-7.34h-6.29v7.55l-4.61,15.52h11.95c1.05,2.52,2.94,4.82,5.24,6.29H15.59ZM22.9,64.99l-2.47,8.24-2.01-.6,2.47-8.24,2.01.6ZM40.13,82.39c-6.29,0-11.53-5.24-11.53-11.53,0-4.82,2.94-9.02,7.34-10.7v-15.52h-1.05c-.63,0-1.05-.42-1.05-1.05s.42-1.05,1.05-1.05h10.49c.63,0,1.05.42,1.05,1.05s-.42,1.05-1.05,1.05h-1.05v15.52c4.4,1.68,7.34,5.87,7.34,10.7,0,6.29-5.24,11.53-11.54,11.53Z"
    />
  </svg>
);
export default WarehouseLabIcon;
