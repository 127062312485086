import * as React from 'react';
const DesignerBaysAlignBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerBaysAlignBottomIcon"
    {...props}
  >
    <rect width={120.05} height={5.42} x={4.19} y={119.46} strokeWidth={0} />
    <rect
      width={14}
      height={27.33}
      x={94.03}
      y={30.29}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={94.03}
      y={59.63}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={94.03}
      y={88.96}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={110.25}
      y={59.63}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={110.25}
      y={88.96}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={4.07}
      y={30.29}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={4.07}
      y={59.63}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={4.07}
      y={88.96}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={20.28}
      y={59.63}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={20.28}
      y={30.29}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={20.28}
      y={0.96}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect
      width={14}
      height={27.33}
      x={20.28}
      y={88.96}
      strokeWidth={0}
      opacity={0.65}
    />
    <rect width={14} height={27.33} x={65.11} y={1.14} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={30.47} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={1.14} strokeWidth={0} />
    <rect
      width={10}
      height={115.31}
      x={36.59}
      y={0.96}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={10}
      height={115.31}
      x={81.43}
      y={0.96}
      strokeWidth={0}
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="77.83 102.39 64.21 115.97 50.59 102.36 50.59 94.32 61.35 105.07 61.36 84.63 67.06 84.63 67.06 105.08 77.83 94.34 77.83 102.39"
    />
  </svg>
);
export default DesignerBaysAlignBottomIcon;
