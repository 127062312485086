import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import PolicyWarnings from '../components/policies/PolicyWarnings';
import { pickingPolicyIsReadyToAnalyze } from '../simulation/store/pickingPolicy/pickingPolicy.state';

interface WarningProps {
  children?: React.ReactNode;
}

const PickingPolicyWarnings: React.FC<WarningProps> = ({ children }) => {
  const { t } = useTranslation('simulation');
  const pickingPolicyStatus = useRecoilValue(pickingPolicyIsReadyToAnalyze);

  const getMessages = (): string[] => {
    const messages: string[] = [];
    if (!pickingPolicyStatus.hasOrderLineRules) {
      messages.push(t`Should have at least one policy`);
    }
    if (!pickingPolicyStatus.hasPickingRules) {
      messages.push(t`Policy must have picking rules`);
    }
    if (!pickingPolicyStatus.isOrderLineTableReady) {
      messages.push(t`Please ensure the policy is fully loaded.`);
    }
    if (!pickingPolicyStatus.isPickingAgentsSelected) {
      messages.push(t`Agent must be selected in a picking rule`);
    }
    if (!pickingPolicyStatus.isUOMsSelected) {
      messages.push(t`UOM type must be selected in a picking rule`);
    }
    return messages;
  };

  return <PolicyWarnings messages={getMessages()} />;
};

export default PickingPolicyWarnings;
