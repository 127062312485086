import {
  useBeginImportPipelineMultipartUploadMutation,
  useCompleteImportPipelineMultipartUploadMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { getChunkKey, uploadFile } from '../../import/store/import.helper';
import { errorAppender } from '../../store/error.state';
import {
  importPipelineFilesUploadChunkStatus,
  importPipelineFilesUploadStatus,
} from '../store/importPipelines.state';

const chunkSize = 1024 * 1024 * 5; //5 MB

export type UploadPipelineFileParams = {
  pipelineId: string;
  file: File;
};

function useUploadPipelineFile() {
  const [startMultipartUpload] =
    useBeginImportPipelineMultipartUploadMutation();
  const [completeUpload] = useCompleteImportPipelineMultipartUploadMutation();

  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot upload file`;

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: UploadPipelineFileParams) => {
        set(importPipelineFilesUploadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: UploadPipelineFileParams) => {
        const { file } = params;
        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importPipelineFilesUploadStatus, AsyncLoadStatus.Error);
        }

        if (_.isNil(file)) {
          handleError(t`File is not selected`);
          return;
        }

        const chunkCount = Math.ceil(file.size / chunkSize);

        try {
          const { data: startUploadData, errors: startUploadErrors } =
            await startMultipartUpload({
              variables: {
                input: {
                  importPipelineId: params.pipelineId,
                  parts: chunkCount,
                },
              },
            });

          if (!_.isEmpty(startUploadErrors)) {
            const details = _(startUploadErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          const { uploadId, uploadLinks } =
            startUploadData.beginImportPipelineMultipartUpload;
          const etags: string[] = [];
          await Promise.all(
            _.range(chunkCount).map(i => {
              return new Promise<void>((resolve, reject) => {
                const chunkId = getChunkKey(uploadId, i);

                uploadFile({
                  blob: file.slice(i * chunkSize, (i + 1) * chunkSize),
                  url: uploadLinks[i],

                  onComplete: (e, etag) => {
                    etags[i] = etag;
                    resolve();
                  },

                  onError: e => {
                    handleError(t`File part upload failed`);
                  },

                  onProgress: e => {
                    set(importPipelineFilesUploadChunkStatus(chunkId), {
                      id: chunkId,
                      jobId: uploadId,
                      status: AsyncLoadStatus.Loading,
                      loaded: e.loaded,
                      total: e.total,
                    });
                  },
                });
              });
            }),
          );

          const { data: completeJobData, errors: completeJobErrors } =
            await completeUpload({
              variables: {
                input: {
                  importPipelineId: params.pipelineId,
                  uploadId,
                  etags,
                },
              },
            });

          if (!_.isEmpty(completeJobErrors)) {
            const details = _(completeJobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          set(importPipelineFilesUploadStatus, AsyncLoadStatus.Ok);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: UploadPipelineFileParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    //
  }

  return [call, cancel] as const;
}
export default useUploadPipelineFile;
