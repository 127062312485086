import * as React from 'react';
const WarehouseBoxLeftArrowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseBoxLeftArrowIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64 27.22 5.2 41.25 5.2 98.16 31.96 98.16 31.96 61.77 64 43.27 96.04 61.77 96.04 98.16 122.8 98.16 122.8 41.25 64 27.22"
    />
    <polygon
      strokeWidth={0}
      points="90.26 91.5 59.12 91.5 74.68 75.93 58.68 75.92 37.45 97.15 58.68 118.4 74.68 118.4 59.12 102.83 90.26 102.83 90.26 91.5"
    />
  </svg>
);
export default WarehouseBoxLeftArrowIcon;
