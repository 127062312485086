import * as React from 'react';
const CompareIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CompareIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M111.53,118.62h-38.09v-10h38.09c.82,0,1.58-.32,2.16-.89s.89-1.34.89-2.16V22.62c0-.82-.32-1.58-.89-2.16-.58-.58-1.34-.9-2.16-.9h-38.09v-10h38.09c3.49,0,6.77,1.36,9.23,3.82,2.46,2.47,3.82,5.74,3.82,9.23v82.95c0,3.49-1.36,6.77-3.83,9.23s-5.74,3.82-9.23,3.82Z"
    />
    <path
      strokeWidth={0}
      d="M54.35.89v8.68H15.51c-3.49,0-6.77,1.36-9.23,3.82-2.47,2.47-3.83,5.75-3.83,9.23v82.95c0,3.49,1.36,6.77,3.82,9.23,2.47,2.47,5.74,3.83,9.23,3.83h38.84v8.21h10.17V.89h-10.17ZM32.11,97.54c-1.02,0-1.86-.78-1.86-1.73v-8.9c0-.95.84-1.64,1.86-1.64h12.39v-37.14l-12.11,4.84c-.47.17-.84.26-1.12.26-.65,0-1.03-.43-1.03-1.12v-9.85c0-.6.28-.95.93-1.21l20.86-7.34c.65-.26,1.21-.35,2.14-.35h.17v64.17h-22.24Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M74.6,45.1c2.12-.13,4.19-.21,6.11-.21,6.95,0,17.53,3.5.34,15.1l-6.45,4.56v18.28c1.23-4.62,2.09-7.93,8.33-12.11l7.29-4.89c6.35-4.29,9.87-9.18,9.87-16.13,0-10.98-2.31-16.39-18.1-16.39-2.41,0-4.93.17-7.39.47v11.33Z"
    />
    <path
      strokeWidth={0}
      d="M97.6,85.65h-23v12.19s23,0,23,0c.94,0,1.72-.77,1.72-1.72v-8.84c0-.94-.77-1.63-1.72-1.63Z"
    />
  </svg>
);
export default CompareIcon;
