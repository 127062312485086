import * as React from 'react';
const LocationSimpleIcon = props => (
  <svg
    aria-hidden="true"
    data-name="LocationSimpleIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={89.15}
      height={72.44}
      x={19.76}
      y={19.82}
      rx={4.43}
      ry={4.43}
    />
    <path d="m8,102.79v20.95h14.51v-13.64h30.72v13.64h20.44v-13.64h30.72v13.64h16.26v-20.95c0-1.94-1.58-3.52-3.52-3.52H11.52c-1.94,0-3.52,1.58-3.52,3.52Z" />
  </svg>
);
export default LocationSimpleIcon;
