import * as React from 'react';
const ObjectAislesSpacersIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAislesSpacersIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.97 66.37 6.97 87.45 21.81 66.37 6.97 66.37"
    />
    <polygon
      strokeWidth={0}
      points="6.97 7.88 6.97 28.95 21.81 7.88 6.97 7.88"
    />
    <polygon
      strokeWidth={0}
      points="23.02 91.64 23.02 70.71 8.28 91.64 23.02 91.64"
    />
    <polygon
      strokeWidth={0}
      points="6.97 37.13 6.97 58.2 21.81 37.13 6.97 37.13"
    />
    <polygon
      strokeWidth={0}
      points="23.02 33.15 23.02 12.21 8.28 33.15 23.02 33.15"
    />
    <polygon
      strokeWidth={0}
      points="23.02 62.4 23.02 41.46 8.28 62.4 23.02 62.4"
    />
    <path
      strokeWidth={0}
      d="M26.49,4.02H3.61v90.38h22.88l25.12-27.34v-14.99L26.49,4.02ZM23.72,34.51v.92h1.7v27.7h-1.7v.63h0v.92h1.7v27.7h-1.7v1.15H6.28v-1.15h-1.7v-27.7h1.7v-.4h0v-1.15h-1.7v-27.7h1.7v-.4h0v-1.15h-1.7V6.18h1.7v-.94l17.44.02v.92h1.7v27.7h-1.7v.63h0Z"
    />
    <polygon
      strokeWidth={0}
      points="122.26 66.37 122.26 87.45 107.42 66.37 122.26 66.37"
    />
    <polygon
      strokeWidth={0}
      points="122.26 7.88 122.26 28.95 107.42 7.88 122.26 7.88"
    />
    <polygon
      strokeWidth={0}
      points="106.21 91.64 106.21 70.71 120.95 91.64 106.21 91.64"
    />
    <polygon
      strokeWidth={0}
      points="122.26 37.13 122.26 58.2 107.42 37.13 122.26 37.13"
    />
    <polygon
      strokeWidth={0}
      points="106.21 33.15 106.21 12.21 120.95 33.15 106.21 33.15"
    />
    <polygon
      strokeWidth={0}
      points="106.21 62.4 106.21 41.46 120.95 62.4 106.21 62.4"
    />
    <path
      strokeWidth={0}
      d="M77.62,52.07v14.99s25.12,27.34,25.12,27.34h22.88s0-90.38,0-90.38h-22.88s-25.12,48.05-25.12,48.05ZM105.51,34.51v-.63h-.85s-.85,0-.85,0V6.18s1.7,0,1.7,0v-.92l17.44-.02v.94s1.7,0,1.7,0v27.7s-.85,0-.85,0h-.85s0,.1,0,.1v1.06h0v.4h1.7v27.7s-.85,0-.85,0h-.85s0,.1,0,.1v1.06h0v.4h1.7v27.7s-.85,0-.85,0h-.85s0,.1,0,.1v1.06h-17.44v-1.15s-.85,0-.85,0h-.85v-27.7s1.7,0,1.7,0v-.92h0s0-.42,0-.42v-.21h-.85s-.85,0-.85,0v-27.7s1.7,0,1.7,0v-.92h0Z"
    />
    <polygon
      strokeWidth={0}
      points="120.07 125.07 7.08 125.07 58.52 63.77 70.63 63.77 120.07 125.07"
    />
    <path
      strokeWidth={0}
      d="M68.55,48.03l10.7-10.7c1.18-1.18,1.18-3.1,0-4.28-1.18-1.18-3.1-1.18-4.28,0l-10.7,10.7-11.23-11.23c-1.18-1.18-3.1-1.18-4.28,0-1.18,1.18-1.18,3.1,0,4.28l11.23,11.23-11.77,11.77c-1.18,1.18-1.18,3.1,0,4.28,1.18,1.18,3.1,1.18,4.28,0l11.77-11.77,11.23,11.23c1.18,1.18,3.1,1.18,4.28,0,1.18-1.18,1.18-3.1,0-4.28l-11.23-11.23Z"
    />
    <path
      strokeWidth={0}
      d="M63.58,6.22c-22.89,0-41.44,18.55-41.44,41.44,0,22.89,18.55,41.44,41.44,41.44s41.44-18.55,41.44-41.44c0-22.89-18.55-41.44-41.44-41.44ZM64,82.87c-19.06,0-34.57-15.51-34.57-34.57,0-19.06,15.51-34.57,34.57-34.57,19.06,0,34.57,15.51,34.57,34.57,0,19.06-15.51,34.57-34.57,34.57Z"
    />
  </svg>
);
export default ObjectAislesSpacersIcon;
