import * as React from 'react';
const LocationsSelectionEmptyIcon = props => (
  <svg
    aria-hidden="true"
    data-name="LocationsSelectionEmptyIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={33.21}
      height={125.45}
      x={47.07}
      y={1}
      opacity={0.3}
      transform="translate(127.35 127.45) rotate(180)"
    />
    <g opacity={0.65}>
      <path d="m1,1v23.43s20.78,0,20.78,0V1S1,1,1,1Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m23.86,1v23.43s20.78,0,20.78,0V1s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m1,26.51v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m23.86,26.51v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m1,52.01v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m23.86,52.01v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m1,77.52v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m23.86,77.52v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m1,103.02v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m23.86,103.02v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m82.87,1v23.43s20.78,0,20.78,0V1s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m105.72,1v23.43s20.78,0,20.78,0V1s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m82.87,26.51v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m105.72,26.51v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m82.87,52.01v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m105.72,52.01v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m82.87,77.52v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m105.72,77.52v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m82.87,103.02v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
    <g opacity={0.65}>
      <path d="m105.72,103.02v23.43s20.78,0,20.78,0v-23.43s-20.78,0-20.78,0Zm16.3,17.08l-1.03,1.03-4.88-4.88-4.88,4.88-1.03-1.03,4.88-4.88-4.88-4.88,1.03-1.03,4.88,4.88,4.88-4.88,1.03,1.03-4.88,4.88,4.88,4.88Z" />
    </g>
  </svg>
);
export default LocationsSelectionEmptyIcon;
