import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AssignmentStatsLocationInfoFull from '../../../assignmentStats/AssignmentStats';
import InboxZero from '../../../components/InboxZero';
import LocationPropertiesPanel from '../../../components/sharedPanels/LocationPropertiesPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import { viewerSelectedBayLocation } from '../../../layout/viewer/store/viewer.state';
import { allocationAnalyzeId } from '../../store/allocation/allocation.state';
import { itemsContentViewAs } from '../../store/analyze.state';
import {
  optimisationAnalyzeId,
  optimisationViewMode,
} from '../../store/optimisation.state';
import { simulationAnalyzeId } from '../../store/simulation.state';
import { OptimisationViewMode } from '../../store/simulation.types';
import {
  showAnalyseData,
  simulationAnalyseTabKey,
  simulationComplianceTabKey,
  simulationWizardSelectedStep,
} from '../../store/simulation.wizard.state';
import LocationPropertiesAnalyzeProduct from './LocationPropertiesAnalyzeProduct';
import LocationPropertiesAssignmentCompliance from './LocationPropertiesAssignmentCompliance';
import LocationPropertiesAssignmentOccupancy from './LocationPropertiesAssignmentOccupancy';
import LocationPropertiesAssignmentPolicy from './LocationPropertiesAssignmentPolicy';
import LocationPropertiesDatasetIssues from './LocationPropertiesDatasetIssues';
import LocationPropertiesSizeCompliance from './LocationPropertiesSizeCompliance';
import LocationPropertiesStackingPolicy from './LocationPropertiesStackingPolicy';
import LocationPropertiesWeightCompliance from './LocationPropertiesWeightCompliance';
import ReassignLocationProperties from './ReassignLocationProperties';

const SimulationLocationSidebarCmp: React.FC = () => {
  const { t } = useTranslation('simulation');
  const location = useRecoilValue(viewerSelectedBayLocation);

  const showAnalyse = useRecoilValue(showAnalyseData);
  const step = useRecoilValue(simulationWizardSelectedStep);
  const viewMode = useRecoilValue(optimisationViewMode);
  const complianceTab = useRecoilValue(simulationComplianceTabKey);
  const isOptimisationStep = step.id === 'optimise';
  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const optAnalyzeId = useRecoilValue(optimisationAnalyzeId);
  const allocationAnlId = useRecoilValue(allocationAnalyzeId);
  const analyzeTabSelected = useRecoilValue(simulationAnalyseTabKey);
  const analyzeItemsTabSelected = useRecoilValue(itemsContentViewAs);

  const hasAnalyseLayout =
    step?.id === 'optimise-reassign' ||
    (showAnalyse &&
      (!isOptimisationStep || viewMode !== OptimisationViewMode.Optimised));

  const hasOptimiseLayout =
    step?.id === 'optimise-reassign' ||
    (showAnalyse &&
      isOptimisationStep &&
      (viewMode === OptimisationViewMode.Optimised ||
        viewMode === OptimisationViewMode.Compare));

  const itemsSectionTitle = t`Location Items`;
  const itemsSectionTitleAnalyse = hasOptimiseLayout
    ? itemsSectionTitle + ' ' + t`(Before)`
    : itemsSectionTitle;
  const itemsSectionTitleOptimise = hasAnalyseLayout
    ? itemsSectionTitle + ' ' + t`(After)`
    : itemsSectionTitle;

  if (!location) {
    return (
      <InboxZero
        message={t`No Location selected`}
        message_helper={t`Click on bay map to select`}
        hasIcon
        selfCenter
      />
    );
  }

  return (
    <LocationPropertiesPanel location={location}>
      {hasAnalyseLayout && (
        <>
          <ReassignLocationProperties
            analyzeId={analyzeId}
            locationId={location.locationId}
          />
          <LocationPropertiesAnalyzeProduct
            analyzeId={analyzeId}
            locationId={location.locationId}
            title={itemsSectionTitleAnalyse}
          />
        </>
      )}
      {hasOptimiseLayout && (
        <>
          <ReassignLocationProperties
            analyzeId={optAnalyzeId}
            locationId={location.locationId}
          />
          <LocationPropertiesAnalyzeProduct
            analyzeId={optAnalyzeId}
            locationId={location.locationId}
            title={itemsSectionTitleOptimise}
          />
        </>
      )}
      {(step?.id === 'import-layout' ||
        step?.id === 'import-assignments' ||
        step?.id === 'data-issues') && (
        <LocationPropertiesDatasetIssues locationId={location.locationId} />
      )}
      {step?.id === 'policy-storage' && (
        <LocationPropertiesAssignmentPolicy locationId={location.locationId} />
      )}
      {step?.id === 'compliance' &&
        complianceTab === 'tab-compliance-weight' && (
          <LocationPropertiesWeightCompliance
            locationId={location.locationId}
          />
        )}
      {step?.id === 'compliance' && complianceTab === 'tab-compliance-size' && (
        <LocationPropertiesSizeCompliance locationId={location.locationId} />
      )}
      {step?.id === 'compliance' &&
        complianceTab === 'tab-compliance-policy' && (
          <LocationPropertiesAssignmentCompliance
            locationId={location.locationId}
          />
        )}
      {step?.id === 'compliance' &&
        complianceTab === 'tab-compliance-volume' && (
          <LocationPropertiesAssignmentOccupancy
            locationId={location.locationId}
          />
        )}
      {step?.id === 'policy-stacking' && (
        <LocationPropertiesStackingPolicy locationId={location.locationId} />
      )}

      {step?.id === 'data-stats' && (
        <AssignmentStatsLocationInfoFull locationId={location.locationId} />
      )}
      {step?.id === 'analyse' &&
        analyzeTabSelected === 'tab-analyze-items' &&
        analyzeItemsTabSelected === 'allocate' && (
          <ReassignLocationProperties
            analyzeId={allocationAnlId}
            locationId={location.locationId}
          />
        )}
    </LocationPropertiesPanel>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-info-location',
    title: t`Location Info`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationLocationSidebarCmp />
    </SidebarContainer>
  );
};
