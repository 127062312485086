import { atom, selector } from 'recoil';

const getKey = (postfix: string) => `warebee-notification-${postfix}`;

export type NotificationTypes =
  | 'importSuccessful'
  | 'importFromSimulationSuccessful';

export type NotificationDetails = {
  id: string;
  title: string;
  type: NotificationTypes;
  details?: string;
  autoDismiss?: boolean;
  simulationId?: string;
};

export const notificationCollection = atom<NotificationDetails[]>({
  key: getKey('container'),
  default: [
    // {
    //   id: 'foo foo',
    //   type: 'importFromSimulationSuccessful',
    //   title: 'AAAA',
    //   simulationId: '0000',
    // },
  ],
});

export const notificationAppender = selector<NotificationDetails>({
  key: getKey('appender'),
  get: () => null,
  set: ({ get, set }, value: NotificationDetails) => {
    set(notificationCollection, [...get(notificationCollection), value]);
    // if (value.autoDismiss) {
    //   setTimeout(() => {
    //     const newCurrent = get(notificationCollection);
    //     set(
    //       notificationCollection,
    //       newCurrent.filter(n => n.id !== value.id),
    //     );
    //   }, 5000);
    // }
  },
});
