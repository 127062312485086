import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../../components/actions/DropdownSelector';
import {
  selectedViewModeAtom,
  viewMenuModesAtom,
} from '../store/simulation.wizard.state';

export const ModeSelectorDropdown: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [modes] = useRecoilState(viewMenuModesAtom);
  const [selectedMode, setSelectedMode] = useRecoilState(selectedViewModeAtom);

  console.log('selectedMode', selectedMode);
  const handleChange = (selectedOption: { label: string; value: string }) => {
    setSelectedMode(selectedOption.value);
  };

  // Prepare values for the DropdownSelector
  const dropdownValues = modes.map(mode => ({ label: t(mode), value: mode }));

  // Function to render each option in the dropdown list
  const renderValue = (
    option: { label: string; value: string },
    filterString?: string,
  ) => <div>{option.label}</div>;

  // Function to render the selected value in the dropdown button
  const renderValueSelected = (
    option: { label: string; value: string },
    filterString?: string,
  ) => <div>{option.label}</div>;

  return (
    <DropdownSelector
      classNameLabel={classNames('text-xxs xl:text-sm')}
      className={classNames('ltr:mr-0.5 xl:ltr:mr-2 rtl:ml-0.5 xl:rtl:ml-2')}
      onClick={e => {
        e.stopPropagation();
      }}
      DropAlignLeft
      widthFull
      panelMode
      value={
        dropdownValues.find(option => option.value === selectedMode) ||
        dropdownValues[0]
      }
      values={dropdownValues}
      onChange={option => {
        handleChange(option);
      }}
      label={t('Mode')}
      renderValue={renderValue}
      renderValueSelected={renderValueSelected}
    />
  );
};
