import * as React from 'react';
const WarehouseSupportIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseSupportIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64.5 25.03 2.84 41.46 2.84 46.48 64.5 30.05 125.16 46.48 125.16 41.46 64.5 25.03"
    />
    <path
      strokeWidth={0}
      d="M35.01,60.1h-4.96c-.62,0-1.12.5-1.12,1.12v.78c0,.64.52,1.16,1.16,1.16h5.07c.59,0,1.07-.48,1.07-1.07v-.77c0-.68-.55-1.22-1.22-1.22Z"
    />
    <path
      strokeWidth={0}
      d="M5.84,46.93v49.58h70.64v-2.97h37.48v2.97h8.21v-49.58l-57.66-15.43L5.84,46.93ZM63.78,78.87v7.97c0,.1-.1.15-.2.1l-10.26-8.02s-.05-.05-.1-.05h-24.2c-1.89,0-3.42-1.53-3.42-3.47v-19.05c0-1.84,1.48-3.32,3.32-3.32h15.42v-1.57h-1.26v-2.69h3.34v2.69h-1.26v1.57h15.22c1.89,0,3.42,1.53,3.42,3.47v22.37ZM76.48,68.67h37.48v2.65h-37.48v-2.65ZM76.48,72.8h37.48v2.65h-37.48v-2.65ZM76.48,76.93h37.48v2.65h-37.48v-2.65ZM76.48,81.15h37.48v2.65h-37.48v-2.65ZM76.48,85.28h37.48v2.65h-37.48v-2.65ZM76.48,89.41h37.48v2.65h-37.48v-2.65Z"
    />
    <path
      strokeWidth={0}
      d="M41.59,68.84c-.37,0-.67.3-.67.67,0,.74-.6,1.33-1.33,1.33s-1.33-.6-1.33-1.33.6-1.33,1.33-1.33c.37,0,.67-.3.67-.67v-1.33c0-.37-.3-.67-.67-.67s-.67.3-.67.67v.75c-1.18.3-2,1.37-2,2.58,0,1.47,1.2,2.67,2.67,2.66,1.47,0,2.67-1.2,2.66-2.67,0-.37-.3-.67-.67-.67Z"
    />
    <path
      strokeWidth={0}
      d="M50.19,60.1h-4.96c-.62,0-1.12.5-1.12,1.12v.78c0,.64.52,1.16,1.16,1.16h5.07c.59,0,1.07-.48,1.07-1.07v-.77c0-.68-.55-1.22-1.22-1.22Z"
    />
  </svg>
);
export default WarehouseSupportIcon;
