import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { IconBackDirectional } from './IconBackDirectional';

export type NavProps = {
  children?: React.ReactNode;
  message?: string;
};

export const NavBackWebsite = ({ message }: NavProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="NavBackWebsite"
      id={id}
      className="flex items-center text-menu-active group"
    >
      <div className="flex items-center justify-center any-hover:group-hover:bg-menu-500 rounded-full p-1 w-7 h-7">
        <IconBackDirectional arrowSize="xs" className="" />
      </div>
      <span
        className={classNames(
          'flex-1 hover:text-menu-active mx-2 group-hover:underline underline-offset-4',
        )}
      >
        {message}
      </span>
    </div>
  );
};

export const NavWebsite = ({ message }: NavProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="NavWebsite"
      id={id}
      className="flex items-center text-menu-active group"
    >
      <div className="flex items-center justify-center any-hover:group-hover:bg-menu-500 rounded-full p-1 w-7 h-7">
        <IconBackDirectional arrowSize="xs" className="" />
      </div>
      <span
        className={classNames(
          'flex-1 hover:text-menu-active-light mx-2 any-hover:group-hover:underline underline-offset-4',
        )}
      >
        {message}
      </span>
    </div>
  );
};
