import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue } from 'recoil';
import EntryPointFeature from '../../layout/features/EntryPointFeature';
import { simulationRoutingDisplayMode } from '../store/simulation.state';
import RoutingRulesFeaturesGroup from './RoutingRulesFeaturesGroup';

const SimulationNavigationLayer: React.FC = () => {
  const routingMode = useRecoilValue(simulationRoutingDisplayMode);
  if (routingMode === 'none') return;

  return (
    <Layer>
      <RoutingRulesFeaturesGroup isStaticMode={routingMode !== 'active'} />
      <EntryPointFeature />
    </Layer>
  );
};

export default SimulationNavigationLayer;
