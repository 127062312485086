import { OptimizationRunStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ContainerMap } from '../../components/layout/ContainerMap';
import StageContainer from '../../layout/stage/StageContainer';
import { allocationAnalyzeId } from '../store/allocation/allocation.state';
import { itemsContentViewAs } from '../store/analyze.state';
import {
  optimisationAnalyzeId,
  optimisationStatus,
  optimisationViewMode,
} from '../store/optimisation.state';
import { simulationAnalyzeId } from '../store/simulation.state';
import { OptimisationViewMode } from '../store/simulation.types';
import {
  simulationAnalyseTabKey,
  simulationComplianceTabKey,
  simulationOptimisationPolicyTabKey,
  simulationWizardSelectedStep,
} from '../store/simulation.wizard.state';
import AssignmentComplianceBayLocationLayer from './layers/AssignmentComplianceBayLocationLayer';
import AssignmentOccupancyBayLocationLayer from './layers/AssignmentOccupancyBayLocationLayer';
import AssignmentStatsBayLocationLayer from './layers/AssignmentStatsBayLocationLayer';
import IssuesBayLocationLayer from './layers/IssuesBayLocationLayer';
import OptimisationBayLocationLayer from './layers/OptimisationBayLocationLayer';
import PolicyBayLocationLayer from './layers/PolicyBayLocationLayer';
import ReassignBayLocationLayer from './layers/ReassignBayLocationLayer';
import RoutingStepBayLocationLayer from './layers/RoutingStepBayLocationLayer';
import SimulationBayLocationLayer from './layers/SimulationBayLocationLayer';
import SimulationLocationHtmlTooltipLayer from './layers/SimulationLocationHtmlTooltipLayer';
import SizeComplianceBayLocationLayer from './layers/SizeComplianceBayLocationLayer';
import WeightComplianceBayLocationLayer from './layers/WeightComplianceBayLocationLayer';

const SimulationBayViewerLayout: React.FC = () => {
  const { t } = useTranslation('simulation');
  const step = useRecoilValue(simulationWizardSelectedStep);
  const viewMode = useRecoilValue(optimisationViewMode);
  const isOptimisationStep = step.id === 'optimise';
  const optStatus = useRecoilValue(optimisationStatus);
  const complianceTab = useRecoilValue(simulationComplianceTabKey);

  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const allocationAnlId = useRecoilValue(allocationAnalyzeId);
  const optimisationAnlId = useRecoilValue(optimisationAnalyzeId);
  const optimisationTab = useRecoilValue(simulationOptimisationPolicyTabKey);

  const analyzeTabSelected = useRecoilValue(simulationAnalyseTabKey);
  const analyzeItemsTabSelected = useRecoilValue(itemsContentViewAs);

  const hasAnalyseLayout =
    !isOptimisationStep || viewMode !== OptimisationViewMode.Optimised;

  const hasOptimiseLayout =
    isOptimisationStep &&
    optStatus === OptimizationRunStatus.COMPLETED &&
    (viewMode === OptimisationViewMode.Optimised ||
      viewMode === OptimisationViewMode.Compare);

  function getCustomLocationLayer() {
    if (
      step?.id === 'analyse' &&
      analyzeTabSelected === 'tab-analyze-items' &&
      analyzeItemsTabSelected === 'allocate'
    )
      return <ReassignBayLocationLayer analyzeId={allocationAnlId} />;

    switch (step?.id) {
      case 'data-issues':
      case 'import-items':
      case 'import-assignments':
      case 'import-orders':
        return <IssuesBayLocationLayer />;
      case 'data-stats':
        return <AssignmentStatsBayLocationLayer />;
      case 'policy-storage':
      case 'policy-picking':
      case 'policy-stacking':
        return <PolicyBayLocationLayer />;
      case 'policy-routing':
        return <RoutingStepBayLocationLayer />;
      case 'compliance':
        if (complianceTab === 'tab-compliance-weight')
          return <WeightComplianceBayLocationLayer />;
        if (complianceTab === 'tab-compliance-size')
          return <SizeComplianceBayLocationLayer />;
        if (complianceTab === 'tab-compliance-volume')
          return <AssignmentOccupancyBayLocationLayer />;
        if (complianceTab === 'tab-compliance-policy')
          return <AssignmentComplianceBayLocationLayer />;
        return null;
      case 'optimise':
        if (
          optStatus !== OptimizationRunStatus.COMPLETED &&
          (optimisationTab === 'tab-optimisation-policy-swap' ||
            optimisationTab === 'tab-optimisation-policy-load-balancing')
        ) {
          return <PolicyBayLocationLayer />;
        }

        return null;
      case 'optimise-reassign':
        return <ReassignBayLocationLayer analyzeId={optimisationAnlId} />;
    }
    return null;
  }

  const customLocationLayer = getCustomLocationLayer();

  const mainLayoutTitle = isOptimisationStep
    ? t`Bay Layout (Before)`
    : t`Bay Layout`;

  return (
    <>
      {hasAnalyseLayout && (
        <ContainerMap
          data-component="SimulationBayViewerLayout"
          headerTitle={mainLayoutTitle}
          className={classNames('min-h-bayMap')}
        >
          <StageContainer type="simulation-bay-view" darkBg={true}>
            {_.isNil(customLocationLayer) ? (
              <SimulationBayLocationLayer />
            ) : (
              customLocationLayer
            )}
          </StageContainer>
          <SimulationLocationHtmlTooltipLayer />
        </ContainerMap>
      )}
      {hasOptimiseLayout && (
        <ContainerMap
          data-component="SimulationBayViewerLayout"
          headerTitle={t`Bay Layout (After)`}
          className={classNames('min-h-bayMap')}
        >
          <StageContainer type="simulation-bay-view" darkBg={true}>
            <OptimisationBayLocationLayer />
          </StageContainer>
          <SimulationLocationHtmlTooltipLayer />
        </ContainerMap>
      )}
    </>
  );
};

export default SimulationBayViewerLayout;
