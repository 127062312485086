import { atom, selector } from 'recoil';
import { persistAtom } from '../common/recoil/persistAtom';

const getKey = (postfix: string) => `warebee-cctv-${postfix}`;

export const cctvEnabled = persistAtom<boolean>({
  key: getKey('enabled'),
  default: false,
});

const cctvShowAtom = atom<boolean>({
  key: getKey('show-atom'),
  default: false,
});

export const cctvShow = selector<boolean>({
  key: getKey('show'),
  get: ({ get }) => get(cctvShowAtom) && get(cctvEnabled),
  set: ({ set }, value) => set(cctvShowAtom, value),
});
