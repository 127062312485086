import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ALLOCATION_JOB_REFRESH_INTERVAL } from '../common/constants';
import { cn } from '../common/utils';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons/';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import useLoadAllocationRequirementsMeta from '../simulation/hooks/useLoadAllocationRequirementsMeta';
import useRunAllocationRequirements from '../simulation/hooks/useRunAllocationRequirements';
import { allocationRequirement } from '../simulation/store/allocation/allocationRequirement.state';
import { pickingPolicyIsReadyToAnalyze } from '../simulation/store/pickingPolicy/pickingPolicy.state';

const AllocationRequirementGenerateButton: React.FC = () => {
  const requirement = useRecoilValue(allocationRequirement);
  const pickingPolicyStatus = useRecoilValue(pickingPolicyIsReadyToAnalyze);
  const [runAllocationRequirements] = useRunAllocationRequirements();
  const [loadAllocationRequirements, cancelLoadAllocationRequirements] =
    useLoadAllocationRequirementsMeta();

  const isLoading =
    requirement?.status === 'CALCULATING' ||
    requirement?.status === 'CREATED' ||
    requirement?.status === 'RESTARTED';

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        loadAllocationRequirements({ allocationRequirementId: requirement.id });
      }, ALLOCATION_JOB_REFRESH_INTERVAL);
      return () => {
        cancelLoadAllocationRequirements();
        clearTimeout(timeoutId);
      };
    }
  }, [requirement]);

  function updateAllocationRequirement() {
    if (pickingPolicyStatus.isAnalyzeReady) {
      runAllocationRequirements();
    }
  }

  const isReady = requirement?.status === BatchJobStatus.READY;
  const hasError =
    requirement?.status === BatchJobStatus.FAILED ||
    requirement?.status === BatchJobStatus.TERMINATED;

  const isChanged = false; // TODO: Check requirement version

  return (
    <Button
      className="mx-2 rounded"
      buttonSize="xs"
      buttonType={hasError ? 'delete' : isChanged ? 'primary' : 'primary'}
      isDisabled={isLoading}
      isLoading={isLoading}
      hasIconBefore={
        isLoading ? null : isChanged ? (
          <Icon.Reload className={cn('h-4 w-4 fill-current')} />
        ) : null
      }
      hasIconAfter={
        isLoading ? (
          <IconArrowDir className={cn('!h-4 !w-4')} />
        ) : isChanged ? null : (
          <IconArrowDir className={cn('!h-4 !w-4')} />
        )
      }
      label={
        isLoading
          ? t`Generating...`
          : isChanged
            ? t`Re-generate`
            : t`Generate`
      }
      onPress={updateAllocationRequirement}
    />
  );
};

export default AllocationRequirementGenerateButton;
