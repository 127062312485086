import classNames from 'classnames';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import SimulationCompareHeader from '../simulation/SimulationCompareHeader';
import { warehouseSelectedId } from '../store/warehouse.state';
import AnalyzeCompareMainView from './AnalyzeCompareMainView';
import {
  analyzeCompareIdByType,
  analyzeCompareViewModeSelected,
} from './analyzeCompare.state';
import AnalyzeCompareLegendSidebar from './sidebars/AnalyzeCompareLegendSidebar';
import AnalyzeCompareLocationSidebar from './sidebars/AnalyzeCompareLocationSidebar';
import AnalyzeCompareSummarySidebar from './sidebars/AnalyzeCompareSummarySidebar';

const AnalyzeCompareRoot: React.FC = () => {
  const whId = useRecoilValue(warehouseSelectedId);
  const { t } = useTranslation('simulation');

  const [selectedViewMode] = useRecoilState(analyzeCompareViewModeSelected);
  const firstAnalyzeId = useRecoilValue(analyzeCompareIdByType('first'));
  const secondAnalyzeId = useRecoilValue(analyzeCompareIdByType('second'));

  return (
    <>
      <SimulationCompareHeader />

      <ContainerMain>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorIndicator
              selfCenter
              message={error.message}
              hasReloadButton
              exception={error}
            />
          )}
        >
          <ContainerSidebar side="left">
            <AnalyzeCompareSummarySidebar />
            {selectedViewMode === 'heatmap' && <AnalyzeCompareLegendSidebar />}
          </ContainerSidebar>

          <Suspense
            fallback={
              <Container hasOverflowY flex1 col>
                <PanelHeader isLoading title={t`Loading Layout...`} />
                <LoadingIndicator
                  className={classNames(
                    'bg-map-area-striped',
                    'border-app-background border-8',
                  )}
                  selfCenter
                  message={t`Loading Layout`}
                />
              </Container>
            }
          >
            <AnalyzeCompareMainView />
          </Suspense>

          <ContainerSidebar side="right">
            {firstAnalyzeId || secondAnalyzeId ? (
              <>
                <AnalyzeCompareLocationSidebar />
                {/* <SidebarBayViewer /> */}
                {/* <LayoutCommonInfoSidebar /> */}
                {/* ToDo: Bay View in Compare mode */}
                {/* <AnalyzeCompareBaySidebar /> */}
              </>
            ) : (
              <>{/* <InboxZero message={`Select Data to compare`} /> */}</>
            )}
          </ContainerSidebar>
        </ErrorBoundary>
      </ContainerMain>
    </>
  );
};

export default AnalyzeCompareRoot;
