import {
  ItemFilterIntersectionFragment,
  LocationFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { actualityExtraSettings } from '../../feed/store/actuality.state';
import {
  FilterPreset,
  FilterPresets,
  FilterPresetSelectedIdentity,
} from './filterPreset.types';

const getKey = (postfix: string) => `warebee-filter-set-${postfix}`;

export const filterPresetsAll = selector<FilterPresets>({
  key: getKey('all'),
  get: ({ get }) => {
    return get(actualityExtraSettings)?.filterPreset ?? [];
  },
  set: ({ get, set }, value: FilterPresets) => {
    set(actualityExtraSettings, current => ({
      ...current,
      filterPreset: value,
    }));
  },
});

export const filterPresetSelectedIdentity = atom<FilterPresetSelectedIdentity>({
  key: getKey('selected-identity'),
  default: null,
});

export const filterPresetSelected = selector<FilterPreset>({
  key: getKey('selected-id'),
  get: ({ get }) => {
    const selected = get(filterPresetSelectedIdentity);
    if (_.isNil(selected?.presetId)) {
      return null;
    }
    const all = get(filterPresetsAll);
    return _.find(all, p => p.id === selected?.presetId);
  },
});

export const filterPresetById = selectorFamily<FilterPreset, string>({
  key: getKey('rule-by-id'),
  get:
    presetId =>
    ({ get }) => {
      if (_.isNil(presetId)) return null;
      const all = get(filterPresetsAll);
      return _.find(all, p => p.id === presetId);
    },
  set:
    ruleId =>
    ({ get, set }, value: FilterPreset) => {
      set(filterPresetsAll, current =>
        current.map(rule => (rule.id === ruleId ? value : rule)),
      );
    },
});

export const filterPresetProductFilterIntersection = selectorFamily<
  ItemFilterIntersectionFragment,
  FilterPresetSelectedIdentity
>({
  key: getKey('product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.productFilterId) || _.isNil(identity?.presetId))
        return null;

      const preset = get(filterPresetById(identity.presetId));
      if (!preset) return null;

      const allFilters = preset?.items?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.productFilterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: ItemFilterIntersectionFragment) => {
      if (_.isNil(identity?.productFilterId) || _.isNil(identity?.presetId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(filterPresetById(identity.presetId));
      if (!rule) return null;

      set(filterPresetById(identity.presetId), {
        ...rule,
        items: {
          ...(rule?.items ?? {}),
          anyOf: _.map(rule?.items?.anyOf, fs =>
            fs.id === identity.productFilterId ? value : fs,
          ),
        },
      });
    },
});

export const filterPresetLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  FilterPresetSelectedIdentity
>({
  key: getKey('location-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.locationFilterId) || _.isNil(identity?.presetId))
        return null;

      const rule = get(filterPresetById(identity.presetId));
      if (!rule) return null;

      const allFilters = rule?.locations?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.locationFilterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (_.isNil(identity?.locationFilterId) || _.isNil(identity?.presetId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(filterPresetById(identity.presetId));
      if (!rule) return null;

      set(filterPresetById(identity.presetId), {
        ...rule,
        locations: {
          ...(rule?.locations ?? {}),
          anyOf: _.map(rule?.locations?.anyOf, fs =>
            fs.id === identity.locationFilterId ? value : fs,
          ),
        },
      });
    },
});
