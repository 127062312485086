import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/actions/Button';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';

export type ActionBarNextStepProps = PropsWithChildren & {
  className?: string;
  title?: string;
  isDisabled?: boolean;
};

const ActionBarNextStep: React.FC<ActionBarNextStepProps> = props => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <ActionBar
      data-component="ActionBarNextStep"
      sticky
      stickyBottom
      className={classNames('', props.className)}
    >
      <Spacer flexspace />
      <Button
        label={props.title ? props.title : t`Next`}
        isDisabled={props.isDisabled}
        buttonType="primary"
        hasIconAfter
        buttonIcon={<IconArrowDir />}
        onPress={() => selectStep({ selectNext: true })}
      />
      {props.children}
    </ActionBar>
  );
};

export default ActionBarNextStep;
