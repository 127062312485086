import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';

export type ContainerMapProps = {
  children?: React.ReactNode;
  className?: string;
  headerTitle?: string;
  titleExtra?: React.ReactNode;
};

export const ContainerMap = ({
  children,
  className = '',
  headerTitle,
  titleExtra,
}: ContainerMapProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="ContainerMap"
      className={classNames('flex h-full flex-1 flex-col')}
    >
      {(headerTitle || titleExtra) && (
        <HeaderAppMenu>
          {headerTitle && <span className="">{headerTitle}</span>}
          {titleExtra}
        </HeaderAppMenu>
      )}
      <div
        id={id}
        className={classNames(
          'relative flex flex-1 flex-col',
          'bg-app-panel-dark bg-opacity-50',
          'overflow-hidden',
          'border-app-background/70 border-8',

          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
