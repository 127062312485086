import { AreaConfiguration } from '@warebee/shared/data-access-layout-import-converter';
import Konva from 'konva';
import React from 'react';
import { Group, Text } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../../../Tw';
import { converterDraggableAreaIdAtom } from '../store/converter.area.state';
import { AreaBox } from '../store/converter.types';

const areaColors = TwTheme.extend.colors.area;

const labelTextConfig: Konva.TextConfig = {
  fontFamily: TwTheme.extend.fontFamily.active[0],
  fontSize: 28,
  fill: TwTheme.extend.colors.menu[900],
};

export type ConverterAreaGroupLabelProps = {
  areaBox: AreaBox;
  areaConfig: AreaConfiguration;
  connectedAreasCount: number;
};

const ConverterAreaGroupLabel: React.FC<
  ConverterAreaGroupLabelProps
> = props => {
  const draggableId = useRecoilValue(converterDraggableAreaIdAtom);

  if (draggableId === props.areaBox.areaId) return null;
  const { width, height, low } = props.areaBox.box;
  const { x, y } = low;
  const title = `${props.areaConfig?.link ? `` : `📍 `}${props.areaBox.areaId}${props.areaConfig?.link ? ` 🔗 ` : ``}`;
  const fontSize = Math.min(height * 0.25, (width * 0.25) / title?.length);

  // console.log('props.areaConfig', props.areaConfig);

  const tagColor = props.areaConfig?.link
    ? areaColors.selected // Linked area Label color
    : areaColors.label; // Unlinked area Label color
  const areaColor = areaColors.converter_overlay;
  // applyColorOpacity(
  //   getQualitativeColor(title, 'dimension')[0],
  //   0.3,
  // );
  return (
    <Group listening={false}>
      <Text
        {...labelTextConfig}
        fontSize={fontSize}
        text={title}
        x={x}
        y={y}
        width={width}
        height={-height}
        align="center"
        verticalAlign="middle"
        scaleY={-1}
        sceneFunc={(context, shape: any, ...rest) => {
          const paddingXShare = 0.1;
          const w = shape.textWidth * (1 + 2 * paddingXShare);
          const h = shape.textHeight;
          const x = (width - w) / 2;
          const y = -(height + fontSize * 1.2) / 2;
          context.fillStyle = tagColor;
          context.fillRect(x, y, w, h);
          context.fillStyle = areaColor;
          context.fillRect(0, 0, shape.width(), shape.height());
          (shape as Konva.Text)._sceneFunc(context);
        }}
      />
    </Group>
  );
};
export default ConverterAreaGroupLabel;
