import {
  SkipLimitPageSpec,
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { InferResult } from 'kysely';
import _ from 'lodash';
import { KeysMatching } from '../../../common/utility.types';
import { AnalyzeEventsQueryBuilderParams } from './queryBuilder/analyzedEventsQueryBuilder';
import { analyzedFeedJobsQueryBuilder } from './queryBuilder/analyzedFeedJobsQueryBuilder';
import { getActivityFeedJobsBuilder } from './queryBuilder/feedJobsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type ActualityAnalyzedJobQueryBuilderParams =
  AnalyzeEventsQueryBuilderParams & {
    // layoutId: string;
    // itemSetId: string;
    filterBy?: ActualityAnalyzedJobFilterBy;
  };

export type ActualityAnalyzedJobSortBy = {
  direction?: SortDirection;
  field: ActualityAnalyzedJobColumn;
};

export type ActualityAnalyzedJobFieldWithStringFilter = KeysMatching<
  ActualityAnalyzedJob,
  string
>;

export type ActualityAnalyzedJobFilterBy = Partial<
  Record<ActualityAnalyzedJobFieldWithStringFilter, StringSearchFilter>
>;

export type ActualityAnalyzedJobs = InferResult<
  ReturnType<typeof getActualityAnalyzedJobsSelectPart>
>;
export type ActualityAnalyzedJob = ActualityAnalyzedJobs[number];
export type ActualityAnalyzedJobColumn = keyof ActualityAnalyzedJob;

export type ActualityAnalyzedJobQueryBuilderPagedClientParams = {
  sortBy?: ActualityAnalyzedJobSortBy;
  page?: SkipLimitPageSpec;
};

export type ActualityAnalyzedJobQueryBuilderPagedParams =
  ActualityAnalyzedJobQueryBuilderParams &
    ActualityAnalyzedJobQueryBuilderPagedClientParams;

export function getActualityAnalyzedJobsSelectPart(
  params: ActualityAnalyzedJobQueryBuilderParams,
) {
  let query = analyzedFeedJobsQueryBuilder(
    params,
    getActivityFeedJobsBuilder(params),
  )
    .selectFrom('afj')
    .leftJoin('aj', join => join.onRef('afj.jobId', '=', 'aj.jobId'))
    .select([
      'afj.jobKey',
      'afj.jobId',
      'afj.agentId',
      'afj.eventProcessType',
      'afj.lines',
      'afj.uomCount',
      'afj.minEventTime',
      'afj.maxEventTime',
      'afj.minPreviousEndTime',

      'aj.jobDate',
      'aj.processType',
      'aj.analyzedLines',
      'aj.cost',
      'aj.duration',
      'aj.distance',
      'aj.durationTravellingStartEnd',
      'aj.distanceStartEnd',
      'aj.durationHandling',
      'aj.durationPicking',
      'aj.durationTravellingVertical',
      'aj.durationTravelling',
      'aj.durationReordering',
    ]);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          value?.value === 'undefined'
        ) {
          return q.where(key as any, 'is', null);
        }

        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }

  return query;
}

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f as string);
  return (
    fName === 'minEventTime' ||
    fName === 'maxEventTime' ||
    fName === 'jobDate' ||
    fName === 'minPreviousEndTime'
  );
};

export function postProcessActualityAnalyzedJobs(data: Record<string, any>[]) {
  return postProcessDefault<ActualityAnalyzedJob>(data, isDateField);
}

function getActualityAnalyzedJobsTotalPart(
  params: ActualityAnalyzedJobQueryBuilderParams,
) {
  let query = analyzedFeedJobsQueryBuilder(
    params,
    getActivityFeedJobsBuilder(params),
  )
    .selectFrom('afj')
    .leftJoin('aj', join => join.onRef('afj.jobId', '=', 'aj.jobId'))
    .select(({ fn }) => [
      fn.count<number>('jobKey').distinct().as('totalCount'),
    ]);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          value?.value === 'undefined'
        ) {
          return q.where(key as any, 'is', null);
        }

        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }
  return query;
}

export function getActualityAnalyzedJobsQuery(
  params: ActualityAnalyzedJobQueryBuilderPagedParams,
) {
  let query = getActualityAnalyzedJobsSelectPart(params)
    .orderBy(
      params.sortBy?.field ?? 'jobDate',
      params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
    )
    .$if(!_.isNil(params.page), db => db.offset(params.page.skip ?? 0))
    .$if(!_.isNil(params.page), db => db.limit(params.page.limit ?? 100));

  return query;
}

export function getActualityAnalyzedJobDataTotalQuery(
  params: AnalyzeEventsQueryBuilderParams,
) {
  let query = getActualityAnalyzedJobsTotalPart(params);

  return query;
}

export function postProcessTotalCount(events: Record<string, any>[]): {
  totalCount: number;
} {
  return {
    totalCount: _.head(events)?.['total_count'] ?? 0,
  };
}
