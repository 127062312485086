import * as React from 'react';
const HideTableIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HideTableIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M123.09,60.79c-4.27-4.24-14.73-13.76-28.61-20.59l11.09-11.09h-9.59L26.19,98.89h9.6l8.67-8.67c6.14,1.99,12.7,3.26,19.54,3.26,28.26,0,52.05-21.32,59.09-28.31,1.22-1.21,1.22-3.17,0-4.37h0ZM84.45,67.4c-1.66,7.97-8.05,14.36-16.02,16.02-5.45,1.14-10.59.1-14.82-2.35l8.94-8.94c.48.08.96.13,1.46.13,5.13,0,9.29-4.16,9.29-9.29,0-.5-.05-.98-.13-1.46l8.94-8.94c2.44,4.23,3.48,9.37,2.34,14.83h0Z"
    />
    <line x1={45.73} x2={39.23} y1={74.13} y2={80.63} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M29.57,89.76H6.38v-6.5h23.19v6.5ZM29.57,80.63H6.38v-6.5h23.19v6.5ZM29.57,71.5H6.38v-6.5h23.19v6.5ZM29.57,62.38H6.38v-6.5h23.19v6.5ZM29.57,53.25H6.38v-6.5h23.19v6.5ZM29.57,44.12H6.38v-12.94h23.19v12.94ZM55.51,62.38h-23.19v-6.5h23.19v6.5ZM55.51,53.25h-23.19v-6.5h23.19v6.5ZM55.51,44.12h-23.19v-12.94h23.19v12.94Z"
    />
    <polygon
      strokeWidth={0}
      points="58.27 61.59 63.98 55.87 58.27 55.87 58.27 61.59"
    />
    <polygon
      strokeWidth={0}
      points="58.27 31.18 58.27 44.12 75.73 44.12 81.46 38.4 81.46 31.18 58.27 31.18"
    />
    <polygon
      strokeWidth={0}
      points="58.27 53.25 66.61 53.25 73.11 46.75 58.27 46.75 58.27 53.25"
    />
    <polygon
      strokeWidth={0}
      points="54.86 65 32.33 65 32.33 71.5 48.36 71.5 54.86 65"
    />
    <rect width={23.19} height={6.5} x={32.33} y={55.87} strokeWidth={0} />
    <rect width={23.19} height={12.94} x={32.33} y={31.18} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={32.33} y={46.75} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="32.33 87.53 36.6 83.26 32.33 83.26 32.33 87.53"
    />
    <polygon
      strokeWidth={0}
      points="32.33 80.63 39.23 80.63 45.73 74.13 32.33 74.13 32.33 80.63"
    />
    <rect width={23.19} height={6.5} x={6.38} y={74.13} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={6.38} y={65} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={6.38} y={83.26} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={6.38} y={55.87} strokeWidth={0} />
    <rect width={23.19} height={12.94} x={6.38} y={31.18} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={6.38} y={46.75} strokeWidth={0} />
  </svg>
);
export default HideTableIcon;
