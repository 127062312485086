import * as React from 'react';
const RoundLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoundLeftIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M63.93,4c33.16,0,60.07,26.77,60.07,59.93s-26.91,60.07-60.07,60.07S4,97.09,4,63.93,30.77,4,63.93,4ZM86.53,48.91c0,10.56.15,21.26.15,31.82h6.1c1.93,0,2.97,1.93,1.78,3.27l-13.38,15.91c-.89,1.19-2.83,1.19-3.87,0l-13.38-15.91c-1.19-1.34,0-3.27,1.93-3.27h6.1v-31.82c0-11-15.61-10.56-15.32,0v52.79h-14.72v-52.79c0-30.19,44.61-30.19,44.61,0Z"
    />
  </svg>
);
export default RoundLeftIcon;
