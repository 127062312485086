import * as React from 'react';
const MoveTypeMoveUnstashIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeMoveUnstashIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="126.55 58.37 97.52 58.37 122.2 33.62 106.33 33.62 76.03 64 106.38 94.38 122.25 94.38 97.52 69.63 126.55 69.63 126.55 58.37"
    />
    <path
      strokeWidth={0}
      d="M1.84,21.81v84.38s72.38,0,72.38,0V21.81H1.84ZM9.39,83.55l19.86-19.83-18.63-18.65c-2.06-2.06-2.03-5.42.06-7.51,2.09-2.09,5.45-2.11,7.51-.05l18.63,18.65,19.86-19.83c2.09-2.09,5.45-2.11,7.51-.05,2.06,2.06,2.03,5.42-.06,7.51l-19.86,19.83,20.49,20.52c2.06,2.06,2.03,5.42-.06,7.51-2.09,2.09-5.45,2.11-7.51.05l-20.49-20.52-19.86,19.83c-2.09,2.09-5.45,2.11-7.51.05-2.06-2.06-2.03-5.42.06-7.51Z"
    />
  </svg>
);
export default MoveTypeMoveUnstashIcon;
