import {
  LoadBalancingRuleFragment,
  LoadBalancingZoneType,
} from '@warebee/frontend/data-access-api-graphql';
import { useRecoilValue } from 'recoil';
import {
  viewerAislesMeta,
  viewerPlanesMap,
} from '../layout/viewer/store/viewer.state';

function useGetZoneTitle() {
  const planes = useRecoilValue(viewerPlanesMap);
  const aisles = useRecoilValue(viewerAislesMeta);

  function getZoneTitle(zoneId: string, rule: LoadBalancingRuleFragment) {
    switch (rule.type) {
      case LoadBalancingZoneType.AISLE:
        return aisles?.[zoneId]?.title ?? zoneId;

      case LoadBalancingZoneType.PLANE:
        return planes?.[zoneId]?.title ?? zoneId;

      case LoadBalancingZoneType.WAREHOUSE_AREA:
      case LoadBalancingZoneType.CONGESTION_ZONE:
      case LoadBalancingZoneType.BAY:
        return zoneId;
    }
  }

  return getZoneTitle;
}
export default useGetZoneTitle;
