import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import * as Icon from '../components/icons';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import { AgentCategoryId } from '../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../resourcePolicy/agentData/agentDataHelper';
import { resourcePolicyAgentsMap } from '../simulation/store/resourcePolicy.state';
import { ROUTING_POLICY_FALLBACK_ID } from '../simulation/store/routingPolicy/routingPolicy.default';
import {
  routingPolicy,
  routingPolicySelectedIdentity,
} from '../simulation/store/routingPolicy/routingPolicy.state';
import RoutingPolicyFeaturesRule from './RoutingPolicyFeaturesRule';

export type RoutingPolicyFallbackRuleProps = {
  isDisabled?: boolean;
};

const RoutingPolicyFallbackRule: React.FC<
  RoutingPolicyFallbackRuleProps
> = props => {
  const ruleId = ROUTING_POLICY_FALLBACK_ID;

  const { t } = useTranslation('simulation');
  const { isDisabled } = props;
  const policy = useRecoilValue(routingPolicy);
  const selectedIdentity = useRecoilValue(routingPolicySelectedIdentity);
  const agents = useRecoilValue(resourcePolicyAgentsMap);

  const rule = policy.defaultRule;
  const ruleTitle = t`Default routing`;
  const isActive = selectedIdentity?.ruleId === ruleId;
  const hasIssue = false;

  const usedAgentsIds = _(policy.rules)
    .map(r => r.agentIds)
    .flatten()
    .keyBy()
    .value();
  const fallbackAgents = _.values(agents).filter(
    a => !_.has(usedAgentsIds, a.id),
  );
  const containerParams: RuleContainerProps = {
    id: ruleId,
    title: ruleTitle,
    isCollapsible: true,
    isRemovable: false,
    isDisabled,
    isActive,
    hasIssue,
    hasIcon: true,
    hasColorMode: false,
  };

  return (
    <RuleContainer
      dataComponent="RoutingPolicyRule"
      {...containerParams}
      classNameInner={classNames(
        isActive ? 'bg-menu-active' : 'bg-sidebar-header/70',
        'p-1 xl:p-2',
        'space-y-2',
      )}
    >
      <RoutingPolicyFeaturesRule
        ruleId={ruleId}
        featureRules={rule.featureRules}
      />
      <PolicyFilter
        key={`PolicyFilter-for-features-${ruleId}`}
        label={t`Agent(s)`}
        isActive={isActive}
        isDisabled={isDisabled}
        hasIssue={hasIssue}
        // onClick={toggleSelect}
      >
        {_.map(fallbackAgents, agent => {
          return (
            <PolicyFilterItem
              key={`PolicyFilterItem-for-agents-${agent.id}`}
              value={agent?.title}
              isDisabled={true}
              icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
              iconClassName={classNames(
                'w-6 h-6 xl:w-8 xl:h-8',
                'text-opacity-50',
                'ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
              )}
            />
          );
        })}
        {_.isEmpty(fallbackAgents) && (
          <PolicyFilterItem
            key={`PolicyFilterItem-for-agents-no-agent`}
            value={t`No Agent Assigned`}
            isDisabled={true}
            icon={Icon.CircleInfo}
            iconClassName={classNames(
              'w-5 h-5',
              'opacity-50',
              'ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
            )}
          />
        )}
      </PolicyFilter>
    </RuleContainer>
  );
};

export default RoutingPolicyFallbackRule;
