import * as React from 'react';
const SimulationAnalyzeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationAnalyzeIcon"
    {...props}
  >
    <g isolation="isolate" opacity={0.85}>
      <polyline
        strokeWidth={0}
        points="126 64.27 113.32 56.95 63.88 85.68 14.11 56.95 1.51 64.27 63.96 100.33 126 64.27"
      />
    </g>
    <polyline
      strokeWidth={0}
      points="126 85.86 113.32 78.54 63.88 107.28 14.11 78.54 1.51 85.87 63.96 121.92"
      opacity={0.5}
    />
    <g isolation="isolate">
      <polygon
        strokeWidth={0}
        points="64.47 6.08 33.94 23.82 64.67 41.56 95.2 23.82 64.47 6.08"
      />
      <polygon
        strokeWidth={0}
        points="62.26 42.96 31.73 60.71 1 42.96 31.53 25.22 62.26 42.96"
        opacity={0.5}
      />
      <polygon
        strokeWidth={0}
        points="64.69 44.36 34.16 62.11 64.89 79.85 95.42 62.11 64.69 44.36"
      />
    </g>
    <g isolation="isolate">
      <polyline
        strokeWidth={0}
        points="111.53 33.33 97.19 24.65 82.94 33.33 97.28 42.01 111.53 33.33"
      />
      <polyline
        strokeWidth={0}
        points="127 42.69 112.66 34.02 98.41 42.69 112.75 51.37 127 42.69"
        opacity={0.6}
      />
      <polyline
        strokeWidth={0}
        points="96.16 42.69 81.82 34.02 67.57 42.69 81.91 51.37 96.16 42.69"
        opacity={0.8}
      />
      <polyline
        strokeWidth={0}
        points="111.63 52.06 97.29 43.38 83.04 52.06 97.38 60.74 111.63 52.06"
      />
    </g>
  </svg>
);
export default SimulationAnalyzeIcon;
