import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import AislePropertiesPanel from '../../components/sharedPanels/AislePropertiesPanel';
import BayPropertiesPanel from '../../components/sharedPanels/BayPropertiesPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import {
  viewerSelectedAisle,
  viewerSelectedBayDetails,
} from '../../layout/viewer/store/viewer.state';
import FeedBayViewerLayout from '../panels/FeedBayViewerLayout';

const FeedCommonInfoSidebar: React.FC = () => {
  const { t } = useTranslation('feed');

  const bay = useRecoilValue(viewerSelectedBayDetails);
  const aisle = useRecoilValue(viewerSelectedAisle);

  const getContent = () => {
    if (bay)
      return (
        <Suspense
          fallback={
            <LoadingIndicator selfCenter message={t`Loading bay locations`} />
          }
        >
          <BayPropertiesPanel
            bay={bay}
            bayViewer={() => <FeedBayViewerLayout />}
          />
        </Suspense>
      );
    if (aisle) {
      return <AislePropertiesPanel />;
    }
    return (
      <>
        <InboxZero
          selfCenter
          message={t`Click on Location, Bay, Aisle on the map to get more info`}
        />
      </>
    );
  };

  return <Container col>{getContent()}</Container>;
};

export default () => {
  const { t } = useTranslation('feed');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-layout-common-info',
    title: t`Info`,
    size: 'sm',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <FeedCommonInfoSidebar />
    </SidebarContainer>
  );
};
