import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { panelFixedHeightsCss } from '../../../common/component.types';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../../components/actions/ButtonSwitchMulti';
import { PanelHeader } from '../../../components/designer/panels/PanelHeader';
import {
  Container,
  ContainerCol,
  ContainerRow,
} from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import {
  importMappingPreviewHeight,
  importMappingPreviewViewAs,
  importParsedFile,
  importValidationErrors,
} from '../../store/import.state';
import { MappingPreviewViewAs } from '../../store/import.types';
import ImporterJSONTablePreview from './ImporterJSONTablePreview';
import ImporterValidationErrorTable from './ImporterMappingErrorTable';

const ImporterMappingPreview: React.FC = () => {
  const { t } = useTranslation('importer');
  const previewData = useRecoilValue(importParsedFile);
  const validationErrors = useRecoilValue(importValidationErrors);
  const [viewAs, setViewAs] = useRecoilState(importMappingPreviewViewAs);
  const [height, setHeight] = useRecoilState(importMappingPreviewHeight);

  const previewSize = _.size(previewData?.data);
  const validationErrorCount = _.size(validationErrors);
  const hasError = validationErrorCount > 0;

  const title = t(`Data Preview (first {{previewSize}})`, {
    previewSize: previewSize,
  });

  const titleFooter = t(`End of data preview {{previewSize}} rows`, {
    previewSize: previewSize,
  });

  const viewAsOptions: (ButtonSwitchMultiOption & {
    key: MappingPreviewViewAs;
  })[] = [
    {
      key: 'data',
      label: t`Preview Data`,
    },
    {
      key: 'errors-grouped',
      label: t`Errors (Summary)`,
      icon: hasError ? (
        <div className="">
          {t`Errors (Grouped)`}
          <span
            className={classNames(
              'h-4 w-4',
              'rounded',
              'bg-alerts-error text-alerts-error-text',
              'px-1 py-0.5',
              'ltr:ml-1 rtl:mr-1',
            )}
          >
            {validationErrorCount}
          </span>
        </div>
      ) : null,
    },
    {
      key: 'errors-all',
      label: t`Errors (Details)`,
    },
  ];

  const selectedOptionIndex = _.findIndex(viewAsOptions, o => o.key === viewAs);
  const isErrorOnSelected =
    (selectedOptionIndex == 1 || selectedOptionIndex == 2) && hasError;

  return (
    <ContainerRow
      overflow
      fullHeight={false}
      className={classNames(
        'mt-0.5 flex',
        _.isNil(height) ? null : panelFixedHeightsCss[height],
      )}
    >
      <ContainerCol
        overflow
        flex1
        className={classNames(hasError && '!bg-alerts-error/30')}
      >
        <PanelHeader
          title={title}
          className={classNames('!h-auto flex-1 py-1')}
          adjustable
          fixedHeight={height}
          onHeightChange={setHeight}
        >
          <Spacer flexspace />
          <ButtonSwitchMulti
            autoSize
            className={classNames('mx-3 ltr:mr-8 rtl:ml-8')}
            classNameLabel={classNames(
              'text-xs',
              'py-1 px-2',
              // isErrorOnSelected && 'bg-alerts-error',
            )}
            buttonType="header"
            options={viewAsOptions}
            selectedIndex={_.findIndex(viewAsOptions, o => o.key === viewAs)}
            onClick={index => setViewAs(viewAsOptions[index].key)}
            disabled={validationErrorCount === 0}
          />
        </PanelHeader>

        <Container hasOverflowY col>
          {viewAs === 'data' && (
            <ImporterJSONTablePreview
              id={`import-mapping-data-preview-table`}
              data={previewData.data}
              columns={previewData.fields}
            />
          )}
          {viewAs === 'errors-all' && (
            <ImporterValidationErrorTable showAs="all" />
          )}
          {viewAs === 'errors-grouped' && (
            <ImporterValidationErrorTable showAs="grouped" />
          )}
        </Container>
      </ContainerCol>
    </ContainerRow>
  );
};
export default ImporterMappingPreview;
