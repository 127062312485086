import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ISSUES_PROGRESS_REFRESH_INTERVAL } from '../../../common/constants';
import { AsyncLoadStatus } from '../../../common/types';
import { StatGroup } from '../../../components/stats/StatGroup';
import { simulationIssueSelected } from '../../store/simulation.issues.state';
import {
  DataIssueReason,
  IssuePanelConfig,
} from '../../store/simulation.issues.types';
import { simulationCurrent } from '../../store/simulation.state';
import {
  DataIssueGroup,
  DataTypeConfigMap,
  getIssues,
  getIssuesLoadStatus,
  getIssuesPanelConfigs,
  useLoadIssues,
} from './IssuesConfig';
import IssuesSection from './IssuesSection';
import IssuesSummaryLoadingPlaceholder from './IssuesSummaryLoadingPlaceholder';

interface IssuesSummaryProps {
  dataType: DataIssueGroup;
  displayOption?: string;
}

const IssuesSummary: React.FC<IssuesSummaryProps> = ({
  dataType,
  displayOption,
}) => {
  const { t } = useTranslation('simulation');
  const simulation = useRecoilValue(simulationCurrent);
  const issuesLoadStatus = useRecoilValue(getIssuesLoadStatus(dataType));
  const issues = useRecoilValue(getIssues(dataType));
  const [selectedIssue, setSelectedIssue] = useRecoilState(
    simulationIssueSelected,
  );
  const [loadIssues, cancelLoadIssues] = useLoadIssues(dataType);

  useEffect(() => {
    const params = {
      layoutId: simulation.layout?.id,
      assignmentId: simulation.assignment?.id,
      itemSetId: simulation.itemSet?.id,
      orderSetId: simulation.orderSet?.id,
    };

    if (issuesLoadStatus === AsyncLoadStatus.Error || _.some(params, _.isNil))
      return;

    let timeoutId;
    const delay = _.isNil(issues?.status)
      ? 0
      : ISSUES_PROGRESS_REFRESH_INTERVAL;

    if (
      _.isNil(issues?.status) ||
      issues?.status === BatchJobStatus.CREATED ||
      issues?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadIssues(params);
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadIssues();
    };
  }, [issues, simulation]);

  function select(value: DataIssueReason) {
    setSelectedIssue(
      value === selectedIssue?.reason
        ? null
        : { group: dataType, reason: value },
    );
  }

  const isCalculating =
    issues?.status === BatchJobStatus.CREATED ||
    issues?.status === BatchJobStatus.CALCULATING;

  const isReady = issues?.status === BatchJobStatus.READY;

  let panels: IssuePanelConfig[] = [];
  if (isReady && issues) {
    panels = getIssuesPanelConfigs(dataType, issues, t, displayOption);
  }
  const dataTypeConfig = DataTypeConfigMap[dataType];

  return (
    <>
      {!isReady && (
        <StatGroup
          title={dataTypeConfig.title}
          subTitle={t`Issues:`}
          helpNavTo={dataTypeConfig.helpNavTo}
        >
          <IssuesSummaryLoadingPlaceholder
            title={dataTypeConfig.title}
            state={
              _.isNil(simulation[dataType])
                ? 'no-dataset'
                : isCalculating
                  ? 'calculation'
                  : 'loading'
            }
            progress={issues?.progress}
          />
        </StatGroup>
      )}

      {isReady && panels.length > 0 && (
        <StatGroup
          title={dataTypeConfig.title}
          subTitle={t`Issues:`}
          helpNavTo={dataTypeConfig.helpNavTo}
        >
          {/* {panels.length > 0 ? ( */}
          <IssuesSection
            title={dataTypeConfig.title}
            panels={panels}
            selected={selectedIssue?.reason}
            onSelect={select}
          />
          {/* ) : (
            <InboxZero message={t`No Issues Found`} />
          )} */}
        </StatGroup>
      )}
    </>
  );
};

export default IssuesSummary;
