import * as React from 'react';
const RouteArrowsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RouteArrowsIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M50.03,38.16h5.35v17.23c3.13,1.96,6,4.31,8.61,6.79,2.61-2.61,5.48-4.83,8.61-6.79v-17.23h5.35c1.83,0,3.52-1.04,4.31-2.61.78-1.57.52-3.52-.52-4.96l-14.1-17.75c-.91-1.17-2.22-1.83-3.65-1.83s-2.87.65-3.65,1.83l-14.1,17.75c-1.17,1.44-1.31,3.39-.52,4.96.78,1.7,2.48,2.61,4.31,2.61Z"
    />
    <path
      strokeWidth={0}
      d="M124.17,59.17l-17.75-13.97c-1.44-1.17-3.39-1.31-4.96-.52-1.57.78-2.61,2.48-2.61,4.31v5.48c-9.92.26-18.93,3.79-26.24,9.4-1.44,1.04-2.74,2.35-4.05,3.52,1.44,1.83,2.74,3.92,4.05,6,1.96,3.26,3.39,6.79,4.57,10.57,4.7-7.05,12.53-11.88,21.54-12.27v5.35c0,1.83,1.04,3.52,2.61,4.31.65.26,1.31.52,2.09.52,1.04,0,2.09-.39,2.87-1.04l17.88-14.23c1.17-.91,1.83-2.22,1.83-3.65s-.65-2.87-1.83-3.79Z"
    />
    <path
      strokeWidth={0}
      d="M29.15,54.34v-5.48c0-1.83-1.04-3.52-2.61-4.31-1.7-.78-3.52-.52-4.96.52L3.83,59.17c-1.17.91-1.83,2.35-1.83,3.79s.65,2.87,1.83,3.65l17.75,13.97c.91.65,1.83,1.04,2.87,1.04.65,0,1.44-.13,2.09-.52,1.57-.78,2.61-2.48,2.61-4.31v-5.35c14.62.65,26.24,12.79,26.24,27.41v14.75c0,1.96,1.57,3.39,3.39,3.39h10.44c1.96,0,3.39-1.57,3.39-3.39v-14.62c0-24.15-19.32-43.99-43.47-44.64Z"
    />
  </svg>
);
export default RouteArrowsIcon;
