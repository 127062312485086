import {
  AllocateJobEngine,
  AllocationRunBaseFragment,
  AllocationType,
  useCreateAllocationRunMutation,
  useRestartAllocationRunMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { GraphQLFormattedError } from 'graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { getAllocationSettingsInput } from '../store/allocation/allocation.helper';
import { allocationRunSummaryLoadStatus } from '../store/allocation/allocation.state';
import { allocationSettings } from '../store/allocationPolicy/allocationPolicy.state';
import { simulationCurrent } from '../store/simulation.state';
import useLoadAllocationRun from './useLoadAllocationRun';

export type RunAllocationParams = {
  type: AllocationType;
  allocationId?: string;
  engine: AllocateJobEngine;
};

function useRunAllocation() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Allocation Run`;
  const [runJob] = useCreateAllocationRunMutation();
  const [restartJob] = useRestartAllocationRunMutation();
  const [loadAllocationRun] = useLoadAllocationRun();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();
  let timeoutId = null;

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Loading);
  });

  const startCheck = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: RunAllocationParams) => {
        const simulation = await snapshot.getPromise(simulationCurrent);
        const settings = await snapshot.getPromise(allocationSettings);
        function handleError(details?, stack?) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRunSummaryLoadStatus, AsyncLoadStatus.Error);
        }

        try {
          let data: AllocationRunBaseFragment;
          let errors: readonly GraphQLFormattedError[];
          if (!_.isNil(params.allocationId)) {
            // restart existing job
            const { data: jobData, errors: jobErrors } = await restartJob({
              variables: {
                input: {
                  allocationRunId: params.allocationId,
                  allocationSettings: getAllocationSettingsInput(settings),
                  allocationRunType: params.type,
                  engine: params.engine,
                },
              },
            });
            data = jobData.restartAllocationRun;
            errors = jobErrors;
          }

          if (_.isNil(params.allocationId)) {
            // run new allocation run
            const { data: jobData, errors: jobErrors } = await runJob({
              variables: {
                input: {
                  simulationId: simulation.id,
                  allocationSettings: getAllocationSettingsInput(settings),
                  allocationRunType: params.type,
                  engine: params.engine,
                },
              },
            });
            data = jobData.createAllocationRun;
            errors = jobErrors;
          }

          if (!_.isEmpty(errors) || _.isNil(data?.id)) {
            const details = _(errors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          loadAllocationRun({ allocationRunId: data.id });
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: RunAllocationParams) {
    await initLoading();
    await startCheck(params);
  }

  function cancel() {
    observable?.unsubscribe();
    clearTimeout(timeoutId);
  }

  return [call, cancel] as const;
}

export default useRunAllocation;
