import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  viewerSelectedAisle,
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdAtom,
} from '../../layout/viewer/store/viewer.state';
import {
  SidebarState,
  SidebarType,
  sidebarStateAllPatch,
} from '../../store/sidebar.state';

function useSimulationSidebarWatcher(
  infoSidebarId: SidebarType,
  locationSidebarId: SidebarType,
) {
  const aisle = useRecoilValue(viewerSelectedAisle);
  const bay = useRecoilValue(viewerSelectedBayIdAtom);
  const location = useRecoilValue(viewerSelectedLocationIdAtom);
  const [sidebarsState, setSidebarsState] =
    useRecoilState(sidebarStateAllPatch);

  useEffect(() => {
    const locationSidebar: SidebarState = {
      ...sidebarsState[locationSidebarId],
      isHidden: !location,
    };
    console.log('locationSidebar', locationSidebar);

    const infoSidebar: SidebarState = {
      ...sidebarsState[infoSidebarId],
      isCollapsed: !aisle && !bay && !location,
    };

    setSidebarsState({
      [infoSidebarId]: infoSidebar,
      [locationSidebarId]: locationSidebar,
    });
  }, [aisle, bay, location]);

  return null;
}

export default useSimulationSidebarWatcher;
