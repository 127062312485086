import {
  Field,
  GraphQLJSON,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { NumberFilterRange } from '../filter.model';

@InputAndObjectType()
export class PicklistProcessingEventSettings {
  @Field({ nullable: true })
  timeFixed?: number;

  @Field({ nullable: true })
  timePerOrder?: number;

  @Field({ nullable: true })
  timePerPicklistLine?: number;

  @Field({ nullable: true })
  timePerUom?: number;
}

@InputAndObjectType()
export class SpeedSettings {
  @Field({ nullable: true })
  speedUnladen?: number;
  @Field({ nullable: true })
  speedLaden?: number;
}

@InputAndObjectType()
export class HorizontalTravellingEventSettings {
  @Field(() => SpeedSettings, { nullable: true })
  speed?: SpeedSettings;
}

@InputAndObjectType()
export class VerticalTravellingEventSettings {
  @Field(() => SpeedSettings, { nullable: true })
  speedUp?: SpeedSettings;

  @Field(() => SpeedSettings, { nullable: true })
  speedDown?: SpeedSettings;
}

@InputAndObjectType()
export class PicklistLineProcessingEventSettings {
  @Field({ nullable: true })
  timeFixed?: number;

  @Field({ nullable: true })
  timePerUom?: number;
}

@InputAndObjectType()
export class UomHandlingSettings {
  @Field(() => ID)
  uom: string;

  @Field({ nullable: true })
  timePerUom?: number;
}

@InputAndObjectType()
export class BaseHandlingEventSettings {
  @Field({ nullable: true })
  timeFixed?: number;

  @Field(() => [UomHandlingSettings], { nullable: true })
  timePerUom?: UomHandlingSettings[];

  @Field({ nullable: true })
  timePerWeight?: number;

  @Field({ nullable: true })
  timePerVolume?: number;
}

@InputAndObjectType()
export class HandlingHeightPenaltySettings extends BaseHandlingEventSettings {
  @Field(() => NumberFilterRange)
  locationHeightFromFloor: NumberFilterRange;
}

@InputAndObjectType()
export class HandlingEventSettings extends BaseHandlingEventSettings {
  @Field(() => [HandlingHeightPenaltySettings], { nullable: true })
  heightPenalty?: HandlingHeightPenaltySettings[];
}

@InputAndObjectType()
export class ReorderEventSettings extends BaseHandlingEventSettings {
  @Field({ nullable: true })
  timePerReorderedPosition?: number;
}

@InputAndObjectType()
export class AgentCostSettings {
  @Field({ nullable: true })
  costPerSecond?: number;
}

@InputAndObjectType()
export class AgentUtilisationSettings {
  @Field({ nullable: true })
  agentAmount?: number;

  @Field({ nullable: true })
  secondsOperation?: number;
}

@InputAndObjectType()
export class AgentCapacityUomSettings {
  @Field(() => ID)
  uom: string;

  @Field({ nullable: true })
  quantity?: number;
}

@InputAndObjectType()
export class AgentCapacitySettings {
  @Field({ nullable: true })
  maxVolume?: number;

  @Field({ nullable: true })
  maxWeight?: number;

  @Field(() => [AgentCapacityUomSettings], { nullable: true })
  maxUomQuantity?: AgentCapacityUomSettings[];
}

@InputAndObjectType()
export class AgentPowerSourceSettings {
  @Field({ nullable: true })
  energyConsumptionPerSecond?: number;
}

@InputAndObjectType()
export class AgentSpecs {
  @Field(() => HorizontalTravellingEventSettings, { nullable: true })
  horizontalTravelling?: HorizontalTravellingEventSettings;

  @Field(() => VerticalTravellingEventSettings, { nullable: true })
  verticalTravelling?: VerticalTravellingEventSettings;

  @Field(() => AgentCapacitySettings, { nullable: true })
  capacity?: AgentCapacitySettings;

  @Field(() => AgentPowerSourceSettings, { nullable: true })
  powerSource?: AgentPowerSourceSettings;
}

export enum AgentRole {
  PICKING = 'PICKING',
  REPLENISHMENT = 'REPLENISHMENT',
  REASSIGN = 'REASSIGN',
}

registerEnumType(AgentRole, { name: 'AgentRole' });

@InputAndObjectType()
export class AgentRoleSettings {
  @Field(() => AgentRole)
  roleId: AgentRole;

  @Field({ nullable: true })
  disabled?: boolean;

  @Field(() => PicklistProcessingEventSettings, { nullable: true })
  preHandling?: PicklistProcessingEventSettings;

  @Field(() => PicklistLineProcessingEventSettings, { nullable: true })
  handlingPreparation?: PicklistLineProcessingEventSettings;

  @Field(() => HandlingEventSettings, { nullable: true })
  handling?: HandlingEventSettings;

  @Field(() => ReorderEventSettings, { nullable: true })
  reordering?: ReorderEventSettings;

  @Field(() => PicklistLineProcessingEventSettings, { nullable: true })
  handlingCompletion?: PicklistLineProcessingEventSettings;

  @Field(() => PicklistProcessingEventSettings, { nullable: true })
  postHandling?: PicklistProcessingEventSettings;
}

@InputAndObjectType()
export class AgentSettings {
  @Field(() => ID)
  id: string;

  @Field(() => ID)
  resourceTypeId: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => AgentSpecs, { nullable: true })
  specs?: AgentSpecs;

  @Field(() => [AgentRoleSettings], { nullable: true })
  roles?: AgentRoleSettings[];

  @Field(() => AgentCostSettings, { nullable: true })
  cost?: AgentCostSettings;

  @Field(() => AgentUtilisationSettings, { nullable: true })
  utilisation?: AgentUtilisationSettings;

  @Field(() => GraphQLJSON, { nullable: true })
  metadata?: any;
}

@InputAndObjectType()
export class ResourcePolicy {
  @Field(() => [AgentSettings])
  agents: AgentSettings[];
}
