import * as React from 'react';
const DataPicklistsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataPicklistsIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="67.33 87.09 80.22 101.51 123.11 76.75 110.22 62.33 67.33 87.09"
    />
    <polygon
      strokeWidth={0}
      points="121.84 61.34 99.04 47.28 99.04 51.9 110.43 58.93 121.84 61.34"
    />
    <polygon
      strokeWidth={0}
      points="60.67 87.09 17.78 62.33 4.89 76.75 47.78 101.51 60.67 87.09"
    />
    <polygon
      strokeWidth={0}
      points="28.96 51.9 28.96 47.28 6.17 61.34 17.57 58.93 28.96 51.9"
    />
    <polygon
      strokeWidth={0}
      points="108.29 60.16 99.04 54.46 99.04 65.52 108.29 60.16 108.29 60.16"
    />
    <polygon
      strokeWidth={0}
      points="80.01 104.92 65.51 88.69 65.51 126 108.37 101.31 108.37 88.55 80.01 104.92"
    />
    <polygon
      strokeWidth={0}
      points="28.96 65.52 28.96 54.46 19.71 60.16 19.71 60.16 28.96 65.52"
    />
    <polygon
      strokeWidth={0}
      points="19.63 88.55 19.69 101.31 62.49 126 62.49 88.69 47.99 104.92 19.63 88.55"
    />
    <path
      strokeWidth={0}
      d="M46.26,18.42h34.62c1.44,0,2.62-1.18,2.62-2.62v-4.56c0-1.47-1.18-2.62-2.62-2.62h-10.69c0-3.67-2.96-6.63-6.62-6.63s-6.63,2.96-6.63,6.63h-10.69c-1.44,0-2.62,1.15-2.62,2.62v4.56c0,1.44,1.18,2.62,2.62,2.62ZM63.58,6.01c1.44,0,2.62,1.15,2.62,2.62s-1.18,2.62-2.62,2.62-2.62-1.18-2.62-2.62,1.18-2.62,2.62-2.62Z"
    />
    <path
      strokeWidth={0}
      d="M96.01,16.61c0-2.5-.95-5.48-5.47-5.48h-4.01v4.06c0,3.11-2.53,5.64-5.64,5.64h-34.62c-3.11,0-5.64-2.53-5.64-5.64v-4.06h-3.17c-4.52,0-5.47,2.98-5.47,5.48v50.32l32.01,18.52,32.01-18.52V16.61ZM64.52,27.57h17.39v3.07h-17.39v-3.07ZM64.52,34.04h25.89v3.07h-25.89v-3.07ZM38.88,28.25c0-.66.54-1.2,1.2-1.2h20.72c.66,0,1.2.54,1.2,1.2v8.31c0,.66-.54,1.2-1.2,1.2h-20.72c-.66,0-1.2-.54-1.2-1.2v-8.31Z"
    />
    <rect width={1.91} height={7.84} x={40.62} y={28.14} strokeWidth={0} />
    <rect width={2.15} height={7.84} x={58.8} y={28.14} strokeWidth={0} />
    <rect width={3.36} height={5.22} x={45.96} y={28.14} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={43.43} y={28.14} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={52.52} y={28.14} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={54.79} y={28.14} strokeWidth={0} />
    <rect width={0.78} height={5.22} x={57.21} y={28.14} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={50.25} y={28.14} strokeWidth={0} />
    <rect width={17.39} height={3.07} x={64.52} y={45.93} strokeWidth={0} />
    <rect width={25.89} height={3.07} x={64.52} y={52.41} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M40.08,45.42h20.72c.66,0,1.2.54,1.2,1.2v8.31c0,.66-.54,1.2-1.2,1.2h-20.72c-.66,0-1.2-.54-1.2-1.2v-8.31c0-.66.54-1.2,1.2-1.2Z"
    />
    <rect width={1.91} height={7.84} x={40.62} y={46.51} strokeWidth={0} />
    <rect width={2.15} height={7.84} x={58.8} y={46.51} strokeWidth={0} />
    <rect width={3.36} height={5.22} x={45.96} y={46.51} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={43.43} y={46.51} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={52.52} y={46.51} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={54.79} y={46.51} strokeWidth={0} />
    <rect width={0.78} height={5.22} x={57.21} y={46.51} strokeWidth={0} />
    <rect width={1.07} height={5.22} x={50.25} y={46.51} strokeWidth={0} />
  </svg>
);
export default DataPicklistsIcon;
