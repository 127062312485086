import { AssignmentChangeSequenceAction } from '@warebee/frontend/data-access-api-graphql';
import { ReassignJobsDataRow } from '@warebee/shared/export-converter';
import classnames, { default as classNames } from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { Formatter } from '../../../common/useFormatter';
import ButtonLocateLocation from '../../../components/ButtonLocateLocation';
import { ColumnConfig } from '../../../components/DatasetTable';
import * as Icon from '../../../components/icons';
import ItemCategoryTag from '../../../components/table/ItemCategoryTag';
import { getMoveTypeDescriptors } from '../../store/simulation.helper';
import ItemTag from '../../tags/ItemTag';

export function getReassignJobTableConfigDefault(
  t: TFunction<'simulation'>,
  formatter: Formatter,
): ColumnConfig<ReassignJobsDataRow>[] {
  const moveTypes = _.keyBy(getMoveTypeDescriptors(t), t => t.id);

  return [
    {
      field: 'stepId',
      title: t`Step ID`,
      isHeader: true,
      hasSort: true,
      render: (value: string, row) => {
        return (
          <span className="flex w-full items-center justify-center rounded-full text-xs">
            <span className="bg-app-panel-dark/60 text-menu-active mx-0.5 flex h-6 w-full items-center justify-start rounded-full px-3 py-1 shadow-md">
              {value}
            </span>
          </span>
        );
      },
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      isHeader: false,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag
          title={sku}
          filters={{ consignee: row['consignee'], sku }}
          // color={getQualitativeColor(sku, 'dimension')[0]}
        />
      ),
    },
    {
      field: 'item.cumulativePercentRank',
      title: t`Category`,
      hasSort: false,
      hiddenInExport: true,
      render: (v: number) => <ItemCategoryTag cmlPercent={v} />,
    },
    {
      field: 'categorySting',
      title: t`Category`,
      hiddenInBrowser: true,
    },

    {
      field: 'srcLocationId',
      title: t`From`,
      hasFilter: true,
      render: (locationId: string, row) => {
        const borderClass =
          row.destAction === AssignmentChangeSequenceAction.UNSTASH
            ? 'border-location-reassignSwap'
            : 'border-location-srcLocations';

        const bgClass =
          row.destAction === AssignmentChangeSequenceAction.UNSTASH
            ? 'bg-location-reassignSwap'
            : 'bg-location-srcLocations';

        return (
          <ButtonLocateLocation
            locationId={locationId}
            icon={Icon.Show}
            classNameIcon={classNames(
              // 'rotate-90',
              'text-menu-active-text',
              bgClass,
            )}
            className={classNames(
              'group/location',
              'w-full',
              'text-xs',
              'border',
              'group-hover/location:text-menu-text',
              'shadow hover:shadow-lg',
              // 'ltr:mr-4 rtl:ml-4',
              '!px-1',
              'z-100',
              'bg-sidebar-header/50 hover:bg-sidebar-header/75',
              borderClass,
            )}
          />
        );
      },
    },
    {
      field: 'destAction',
      title: t``,
      render: (value: string, row, isSelected) => {
        const MoveTypeIcon = moveTypes[value]?.icon;
        if (_.isNil(MoveTypeIcon)) return value;
        return (
          <div
            title={`Move Type: ${moveTypes[value]?.title}`}
            className={classnames(
              'flex items-center justify-center',
              'h-6 w-6',
              {
                'text-menu-active': isSelected,
                'text-menu-text any-hover:group-hover:text-menu-active':
                  !isSelected,
              },
            )}
          >
            <MoveTypeIcon className={classNames('h-6 w-6 fill-current')} />
          </div>
        );
      },
    },
    {
      field: 'destLocationId',
      title: t`To`,
      hasFilter: true,
      render: (locationId: string, row) => {
        const borderClass =
          row.destAction === AssignmentChangeSequenceAction.STASH
            ? 'border-location-reassignSwap'
            : 'border-location-destLocations';

        const bgClass =
          row.destAction === AssignmentChangeSequenceAction.STASH
            ? 'bg-location-reassignSwap'
            : 'bg-location-destLocations';

        return (
          <ButtonLocateLocation
            locationId={locationId}
            icon={Icon.Show}
            classNameIcon={classNames(
              // 'rotate-90',
              'text-menu-active-text',
              bgClass,
            )}
            className={classNames(
              'group/location',
              'w-full',
              'text-xs',
              'border',
              'group-hover/location:text-menu-text',
              'shadow-lg',
              'ltr:mr-4',
              'z-100',
              'bg-sidebar-header/75',
              borderClass,
            )}
          />
        );
      },
    },
    {
      field: 'jobDuration',
      title: t`Duration`,
      hasSort: true,
      render: (v: number) => formatter.formatTimeSpan(v).fullString,
    },
    {
      field: 'jobDistance',
      title: t`Distance`,
      hasSort: true,
      render: (v: number) => formatter.formatDistance(v).fullString,
    },
    {
      field: 'jobCost',
      title: t`Cost`,
      render: (v: number) => formatter.formatCurrency(v).raw.toFixed(2),
    },

    {
      field: 'groupId',
      title: t`Group Index`,
      hiddenInBrowser: true,
    },
    {
      field: 'chainNumber',
      title: t`Chain Index`,
      hiddenInBrowser: true,
    },
    {
      field: 'sequenceNumber',
      title: t`Sequence`,
      hiddenInBrowser: true,
    },
    {
      field: 'item.name',
      title: t`Item Name`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.description',
      title: t`Item Description`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.skuGroup',
      title: t`Item Group`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.subGroup',
      title: t`Item Sub Group`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.storageClass',
      title: t`Item Storage Class`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.stockCategory',
      title: t`Item Stock Category`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.transportClass',
      title: t`Item Transport Class`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'item.pollutionClass',
      title: t`Item Pollution Class`,
      isHeader: false,
      hasFilter: true,
    },
    {
      field: 'estimatedCostImprovement',
      title: t`Est. Improvement`,
      render: (v: number) => formatter.formatCurrency(v).raw.toFixed(2),
    },
  ];
}
