import * as React from 'react';
const PhoneIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PhoneIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M4.05,19.28S17.91,4.03,24.15,4.03c6.93.69,15.94,15.94,18.72,24.26,2.08,8.32-3.47,14.56-5.55,17.33-2.08,2.77-3.47,4.85-3.47,4.85,5.55,16.64,27.73,38.82,43.67,44.37,0,0,2.08-2.08,4.85-4.16,3.47-2.08,9.01-7.63,17.33-4.85,9.01,2.08,23.57,11.09,24.26,18.02.69,6.93-15.25,20.1-15.25,20.1C56.04,126.04,1.97,72.66,4.05,19.29h0Z"
    />
  </svg>
);
export default PhoneIcon;
