import {
  LayoutFeatureFragment,
  LayoutFeatureType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import naturalCompare from 'string-natural-compare';
import InboxZero from '../components/InboxZero';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import { InputSearch } from '../components/inputs/InputSearch';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { ActionBar } from '../components/nav/ActionBar';
import { Table } from '../components/table/Table';
import { TableBody } from '../components/table/TableBody';
import { TableHead } from '../components/table/TableHead';
import { TableRow } from '../components/table/TableRow';
import { TableRowHead } from '../components/table/TableRowHead';
import { TableTd } from '../components/table/TableTd';
import { TableTh } from '../components/table/TableTh';
import {
  viewerHoveredFeatureId,
  viewerLayoutNavigableFeaturesMeta,
  viewerPlanesMap,
  viewerShowTooltip,
} from '../layout/viewer/store/viewer.state';
import {
  routingPolicySelectedFeatures,
  routingPolicySelectedIdentity,
} from '../simulation/store/routingPolicy/routingPolicy.state';
import RoutingPolicyDirectionSelect from './RoutingPolicyDirectionSelect';

const RoutingPolicyNavigationEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const features = useRecoilValue(viewerLayoutNavigableFeaturesMeta);
  const selectedIdentity = useRecoilValue(routingPolicySelectedIdentity);
  const [hoveredId, setHoveredId] = useRecoilState(viewerHoveredFeatureId);
  const setShowTooltip = useSetRecoilState(viewerShowTooltip);
  const planes = useRecoilValue(viewerPlanesMap);
  const setFeatures = useSetRecoilState(routingPolicySelectedFeatures);

  const [filterValue, setFilterValue] = useState<string>('');
  if (!selectedIdentity) return null;

  const navigableByPlane = _(features)
    .filter(
      f =>
        f.navigable &&
        (_.isEmpty(filterValue) ||
          _.isEmpty(f.title) ||
          f.title.toLowerCase().includes(filterValue.toLowerCase())),
    )
    .groupBy(f => f.planeId)
    .value();

  const hasAnyFilteredResults = _.size(navigableByPlane) > 0;

  function drawRow(feature: LayoutFeatureFragment) {
    const isActive = hoveredId === feature.id;

    return (
      <TableRow
        key={feature.id}
        rowLineList
        rowHover
        className={`cursor-pointer ${isActive ? 'bg-brand/50' : ''}`}
        onMouseLeave={() => {
          setShowTooltip(true);
          setHoveredId(null);
        }}
        onMouseEnter={() => {
          setShowTooltip(false);
          setHoveredId(feature.id);
        }}
      >
        <TableTd cellSize="sm">
          <RoutingPolicyDirectionSelect featureId={feature.id} />
        </TableTd>
        <TableTd cellSize="sm" truncate>
          {feature.title}
        </TableTd>
      </TableRow>
    );
  }

  return (
    <div data-component="RoutingPolicyNavigationEditor">
      <ScreenTitle subtitle={t`Routing Policy Rules`}>
        <DropdownSelector
          classNameDropDownMenu={`text-xs p-1`}
          DropAlignRight
          buttonTransparent
          vertical
          value={'...'}
          values={['Clear All']}
          onClick={e => e.stopPropagation()}
          onChange={(option: 'Clear All', e: React.MouseEvent) => {
            switch (option) {
              case 'Clear All':
                setFeatures([]);
                break;
            }
          }}
        />
      </ScreenTitle>

      {/* <ActionBar className="p-px">
        <Button
          label={t`Clear all`}
          buttonType={'primary'}
          buttonSize={'xs'}
          onPress={() => setFeatures([])}
        />
      </ActionBar> */}
      <ActionBar sticky stickyTop className="p-px">
        <InputSearch
          placeholder={t`Filter Aisles`}
          value={filterValue}
          onChange={setFilterValue}
          sidebarFilterMode
        />
      </ActionBar>
      {!hasAnyFilteredResults && (
        <InboxZero icon={Icon.TriangleInfo} message={t`No Results`} />
      )}

      {_.map(navigableByPlane, (items, planeId) => {
        const planeTitle = planes[planeId]?.title;
        const byType = _.groupBy(items, f => f.type);
        const aisles = (byType[LayoutFeatureType.AISLE] ?? []).sort((l1, l2) =>
          naturalCompare(l1.title ?? '', l2.title ?? ''),
        );

        const bays = (byType[LayoutFeatureType.BAY] ?? []).sort((l1, l2) =>
          naturalCompare(l1.title ?? '', l2.title ?? ''),
        );
        const hasTunnelBays = _.size(bays) > 0;

        return (
          <TitleSection
            key={`routing-editor-navigable-plane-${planeId}`}
            id={`routing-editor-navigable-plane-${planeId}`}
            // inSidebarView
            inPanelView
            title={t('Floor: {{floorTitle}}', { floorTitle: planeTitle })}
          >
            <TitleSection
              key={`routing-editor-plane-${planeId}-navigable-aisles`}
              id={`routing-editor-plane-${planeId}-navigable-aisles`}
              title={t`Aisles`}
              collapsible
              inPanelView
            >
              <Table
                isSticky
                viewAsLine
                isHoverable
                fixedWidth
                className="w-full"
              >
                <TableHead>
                  <TableRowHead>
                    <TableTh isSticky cellSize="sm" panelMode hasWidth={30}>
                      <Icon.CircleArrowTwoWay
                        className={`w-5 h-5 fill-current`}
                      />
                      <span className="ml-1">{t`Direction`}</span>
                    </TableTh>
                    <TableTh
                      cellSize="sm"
                      panelMode
                      isSticky
                    >{t`Aisle`}</TableTh>
                  </TableRowHead>
                </TableHead>
                <TableBody>{_.map(aisles, drawRow)}</TableBody>
              </Table>
            </TitleSection>

            {hasTunnelBays && (
              <TitleSection
                key={`routing-editor-plane-${planeId}-navigable-bays`}
                id={`routing-editor-plane-${planeId}-navigable-bays`}
                title={t`Tunnel Bays`}
                inPanelView
                collapsible
              >
                <Table
                  isSticky
                  viewAsLine
                  isHoverable
                  fixedWidth
                  className="w-full"
                >
                  <TableHead>
                    <TableRowHead>
                      <TableTh isSticky cellSize="sm" panelMode hasWidth={30}>
                        <Icon.CircleArrowTwoWay
                          className={`w-5 h-5 fill-current`}
                        />
                        <span className="ml-1">{t`Direction`}</span>
                      </TableTh>
                      <TableTh
                        cellSize="sm"
                        panelMode
                        isSticky
                      >{t`Tunnels`}</TableTh>
                    </TableRowHead>
                  </TableHead>
                  <TableBody>
                    {!hasTunnelBays && (
                      <TableRow>
                        <TableTd colSpan={2}>
                          <InboxZero message={t`No Tunnel Bays Detected`} />
                        </TableTd>
                      </TableRow>
                    )}
                    {_.map(bays, drawRow)}
                  </TableBody>
                </Table>
              </TitleSection>
            )}
          </TitleSection>
        );
      })}
    </div>
  );
};

export default RoutingPolicyNavigationEditor;
