import * as React from 'react';
const DesignerBaysOrderIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerBaysOrderIcon"
    {...props}
  >
    <rect width={21.7} height={38.3} x={100.71} y={86.5} strokeWidth={0} />
    <rect width={21.7} height={38.3} x={100.71} y={45.4} strokeWidth={0} />
    <rect width={21.7} height={38.3} x={100.71} y={4.29} strokeWidth={0} />
    <rect width={21.7} height={38.3} x={75.58} y={86.5} strokeWidth={0} />
    <rect width={21.7} height={38.3} x={75.58} y={45.4} strokeWidth={0} />
    <rect width={21.7} height={38.3} x={75.58} y={4.29} strokeWidth={0} />
    <polyline
      strokeWidth={0}
      points="26.42 60.21 26.42 62.93 26.42 62.93 26.42 110.72 59.9 110.72 51.48 119.14 59.67 119.14 70.98 107.82 59.66 96.5 51.48 96.5 59.9 104.92 31.85 104.92 31.85 65.64"
    />
    <path
      strokeWidth={0}
      d="M51.48,52.22l8.42,8.42h-28.05s0-39.28,0-39.28h38.05v-5.43H26.42v2.71h0v47.76h2.28c.1.02.21.03.32.03h30.88s-8.41,8.42-8.41,8.42h8.18s11.31-11.32,11.31-11.32l-11.31-11.32h-8.18Z"
    />
  </svg>
);
export default DesignerBaysOrderIcon;
