import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import ItemSetDashboardCard from '../../itemSet/ItemSetDashboardCard';
import { simulationItemSetSummary } from '../store/simulation.layout.state';

const SimulationInfoPanelItemSet: React.FC = () => {
  const { t } = useTranslation('simulation');

  const summary = useRecoilValue(simulationItemSetSummary);
  if (!summary)
    return <InboxZero selfCenter message={t`No Data Stats found`} />;

  return (
    <>
      <ScreenTitle title={t`Items`} subtitle={t`Stats`} isSticky />
      <div className={`p-2`}>
        <ItemSetDashboardCard summary={summary} itemLimit={50} />
      </div>
    </>
  );
};

export default SimulationInfoPanelItemSet;
