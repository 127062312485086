import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MappingSettings } from './store/import.types';

export type SchemaHelperProps = {
  schema: MappingSettings<any>;
};

const SchemaHelper: React.FC<SchemaHelperProps> = ({ schema }) => {
  const { t } = useTranslation('importer');

  return (
    <ul data-component="schemaHelper" className={classNames('w-full')}>
      {schema.fields.map(item => (
        <li
          data-component="schemaHelperItem"
          key={item.name as string}
          className={classNames(
            'flex flex-col',
            'p-2',
            'border-b border-menu-400',
            'select-text',
          )}
        >
          <div className={classNames('flex mt-4 mb-4')}>
            <div className={classNames('flex-1 text-xl text-menu-text')}>
              {item.title}
            </div>
            <div
              className={classNames(
                'flex items-center justify-center',
                'px-2 py-1',
                'ltr:ml-2 rtl:mr-2',
                'text-xs',
                'font-medium align-middle',
                'rounded',
                'bg-app-panel-light/50',
              )}
            >
              {
                typeof item.type === 'string' ? item.type : 'string' //TODO: //HACK: Handle Enum type properly
              }
            </div>
          </div>
          <div
            className={classNames(
              'text-base text-menu-text/60 leading-relaxed',
            )}
          >
            <span
              className={classNames(
                'px-2 py-1 ltr:mr-3 rtl:ml-3 align-middle',
                'bg-opacity-50 border rounded text-xxs',
                item.optional
                  ? 'border-menu/50'
                  : 'border-menu-active text-menu-active',
              )}
            >
              {item.optional ? t`Optional` : t`Required`}
            </span>
            <span>{item.description}</span>
            {/* {item.contextHelpId && (
              <HelpNavigate href={item.contextHelpId}> Details </HelpNavigate>
            )} */}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default SchemaHelper;
