import {
  ProcessingPipelineJobFragment,
  ProcessingPipelineJobStatus,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { ActualityAutoAnalyzeStatus } from './actuality.types';

export function getActualityAnalyzeJobStatus(
  pipelineJob: ProcessingPipelineJobFragment,
): ActualityAutoAnalyzeStatus {
  switch (pipelineJob.status) {
    case ProcessingPipelineJobStatus.CREATED:
    case ProcessingPipelineJobStatus.QUEUED:
    case ProcessingPipelineJobStatus.STARTING:
    case ProcessingPipelineJobStatus.IN_PROGRESS:
      return 'progress';
    case ProcessingPipelineJobStatus.COMPLETED:
      return 'done';
    case ProcessingPipelineJobStatus.FAILED:
    case ProcessingPipelineJobStatus.TERMINATED:
    case ProcessingPipelineJobStatus.SKIPPED:
      return 'error';
  }
}

export function getActualityAnalyzeJobStatusDescriptors(t: TFunction<'feed'>) {
  const statusMap: Record<
    ActualityAutoAnalyzeStatus,
    {
      label: string;
      state?: string;
      icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    }
  > = {
    none: {
      label: t('Manual', { ns: 'feed' }),
      state: 'draft',
      icon: Icon.Hand,
    },
    progress: {
      label: t('Analysing...', { ns: 'feed' }),
      state: 'analyzing',
      icon: Icon.CircleOk,
    },
    done: {
      label: t('Analysed', { ns: 'feed' }),
      state: 'analyzed',
      icon: Icon.CircleOk,
    },
    error: {
      label: t('Error', { ns: 'feed' }),
      state: 'error',
      icon: Icon.CircleX,
    },
  };

  return statusMap;
}
