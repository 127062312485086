import {
  Field,
  ID,
  InputType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  IsBoolean,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  Min,
} from '@warego/class-validator';

export enum BaySide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
export const BAY_SIDE_ALL: BaySide[] = [BaySide.LEFT, BaySide.RIGHT];

registerEnumType(BaySide, {
  name: 'BaySide',
});

@InputType()
export class CreateLayoutImportLocationInput {
  @Field()
  @IsNotEmpty()
  locationId: string;

  @Field()
  // @IsInt()
  // @IsPositive()
  locationOrder: string; // may exceed js int

  @Field()
  @IsBoolean()
  locationStatus: boolean;

  @Field()
  @IsInt()
  @Min(0)
  locationLevel: number;

  @Field()
  @IsNotEmpty()
  warehouseArea: string;

  @Field()
  @IsNumber()
  @IsPositive()
  locationLength: number;

  @Field()
  @IsNumber()
  @IsPositive()
  locationWidth: number;

  @Field()
  @IsNumber()
  //@IsPositive()
  locationHeight: number;

  @Field()
  @IsNumber()
  //@IsPositive()
  locationWeight: number;

  @Field()
  @IsNotEmpty()
  locationAisle: string;

  @Field()
  @IsNumber()
  locationBay: number;

  @Field()
  @IsInt()
  @Min(0)
  locationBayPosition: number;

  @Field({ nullable: true })
  @IsOptional()
  @IsInt()
  @Min(0)
  locationDepthPosition?: number;

  @Field()
  @IsEnum(BAY_SIDE_ALL)
  baySide: BaySide;

  @Field()
  @IsNotEmpty()
  locationUsageType: string;

  @Field({ nullable: true })
  locmhtype: string;

  @Field()
  @IsNotEmpty()
  locationRackingType: string;

  @Field({ nullable: true })
  bayType?: string;

  @Field(() => ID, { nullable: true })
  primaryAccessAisle?: string;

  @Field(() => ID, { nullable: true })
  secondaryAccessAisle?: string;

  @Field(() => ID, { nullable: true })
  congestionZone?: string;
}
