import { DialectAdapterBase, Kysely } from 'kysely';

export class AthenaAdapter extends DialectAdapterBase {
  get supportsReturning(): boolean {
    return false;
  }

  get supportsTransactionalDdl(): boolean {
    return false;
  }

  async acquireMigrationLock(_: Kysely<any>): Promise<void> {
    this.#throwLocksError();
  }

  async releaseMigrationLock(_: Kysely<any>): Promise<void> {
    this.#throwLocksError();
  }

  #throwLocksError(): never {
    throw new Error('AthenaLocksUnsupportedError');
  }
}
