import {
  BooleanSearchFilter,
  NumberSearchFilter,
  NumberSearchFilterType,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';

export function containsFilter(value: string) {
  return value
    ? {
        type: StringSearchFilterType.CONTAINS,
        value,
      }
    : null;
}

export function numberFilter(
  value: string,
  type: NumberSearchFilterType = NumberSearchFilterType.EQ,
): NumberSearchFilter {
  return value
    ? {
        type,
        value: parseFloat(value),
      }
    : null;
}

export function booleanFilter(value: string): BooleanSearchFilter {
  if (_.isNil(value)) return null;
  return {
    value: value === 'true',
  };
}
