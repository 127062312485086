import * as React from 'react';
const ProductBoxOpenIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ProductBoxOpenIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M80.16,100.89H16c-.66,0-1.19-.52-1.19-1.19v-44.78h65.35v45.97ZM82.55,49.14l10.84-19.38c.2-.38.61-.62,1.04-.62h29.42c.42,0,.81.23,1.02.6.21.36.21.81.01,1.18l-12.08,21.62h-30.25v-3.39ZM82.55,54.92h29.41v44.78c0,.67-.52,1.19-1.19,1.19h-28.21v-45.97ZM5.23,29.68c.23-.33.6-.54.99-.54h65.35c.48,0,.91.3,1.1.74l7.5,17.91v4.74H14.22L5.12,30.8c-.15-.37-.12-.79.11-1.12Z"
    />
  </svg>
);
export default ProductBoxOpenIcon;
