const LinkSourceAreaCancelIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkSourceAreaCancelIcon"
    {...props}
  >
    <path d="M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM25.44,54.14l-11.15-11.12V13.52h29.48l11.13,11.12h-21.61s12.74,12.89,12.74,12.89c-3.09,2.1-5.79,4.73-7.98,7.76l-12.62-12.78.02,21.63ZM46.31,81.69c-9.75-9.75-9.75-25.62,0-35.37,9.75-9.75,25.62-9.75,35.37,0,9.75,9.75,9.75,25.62,0,35.37s-25.62,9.75-35.37,0ZM116.59,115.84h-29.48l-11.12-11.12h21.61s-14.72-14.89-14.72-14.89c3.01-2.21,5.63-4.92,7.71-8.03l14.86,15.05-.02-21.63,11.15,11.12v29.5Z" />
    <path d="M55.16,76.77c-1.05,0-2.03-.41-2.77-1.15-1.53-1.53-1.53-4.02,0-5.55l6.07-6.07-5.73-5.73c-1.53-1.53-1.53-4.02,0-5.55.74-.74,1.73-1.15,2.78-1.15s2.03.41,2.77,1.15l5.73,5.73,5.39-5.39c.74-.74,1.73-1.15,2.77-1.15s2.03.41,2.78,1.15c1.53,1.53,1.53,4.02,0,5.55l-5.39,5.39,5.73,5.73c.74.74,1.15,1.73,1.15,2.77s-.41,2.03-1.15,2.78c-.74.74-1.73,1.15-2.77,1.15s-2.03-.41-2.78-1.15l-5.73-5.73-6.07,6.07c-.74.74-1.73,1.15-2.77,1.15Z" />
  </svg>
);
export default LinkSourceAreaCancelIcon;
