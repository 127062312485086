import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Tooltip from '../../tooltip/ToolTip';
import TooltipWrapper from '../../tooltip/TooltipWrapper';
import {
  viewerHoveredAisle,
  viewerHoveredBay,
  viewerHoveredLocation,
  viewerShowTooltip,
} from '../store/viewer.state';

export type HtmlTooltipLayerProps = PropsWithChildren & {};

const HtmlTooltipLayer: React.FC<HtmlTooltipLayerProps> = props => {
  const { t } = useTranslation('app');
  const showTooltip = useRecoilValue(viewerShowTooltip);
  const aisle = useRecoilValue(viewerHoveredAisle);
  const bay = useRecoilValue(viewerHoveredBay);
  const location = useRecoilValue(viewerHoveredLocation);

  if (!showTooltip || (!aisle && !bay && !location)) return null;

  const styleLabel = classNames(
    'flex items-center',
    'p-1 px-2',
    'text-xs',
    'bg-menu-active',
    'text-menu-active-text',
    'uppercase',
  );

  const styleValue = classNames(
    'flex',
    'p-1 px-2',
    'text-sm font-bold',
    'text-center',
    'text-menu-text bg-app-panel-dark',
    'items-center',
  );

  return (
    <TooltipWrapper stageId={'viewer-area-view'}>
      {location && (
        <Tooltip label={t`Location`} value={location.locationId ?? ''} />
      )}
      {bay && <Tooltip label={t`Bay`} value={bay?.title ?? ''} />}
      {aisle && (
        <Tooltip
          label={aisle.navigable ? t`Aisle` : t`Block`}
          value={aisle?.title ?? ''}
        />
      )}
      {props.children}
    </TooltipWrapper>
  );
};

export default HtmlTooltipLayer;
