import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../../common/types';
import DatasetTable from '../../components/DatasetTable';
import useLoadActualityHqTableData from '../hooks/useLoadActualityHqTableData';
import {
  actualityHqBuilderParams,
  actualityHqTableData,
  actualityHqTableDataLoadStatus,
  actualityHqTableDataState,
  actualityHqTableTotalCount,
} from '../store/actuality.hq.state';
import { ActualityHqDataRow } from '../store/datasetQueries/actualityHqDataRows';
import {
  ActualityHqFilterBy,
  ActualityHqSortBy,
} from '../store/datasetQueries/actualityQueryBuilder';
import useActualityHqDataTableConfig from './useActualityHqDataTableConfig';

const ActualityHqDataTable: React.FC<
  DatasetDataViewProps<ActualityHqDataRow>
> = props => {
  const { t } = useTranslation('dataset');
  const data = useRecoilValue(actualityHqTableData);
  const totalCount = useRecoilValue(actualityHqTableTotalCount);
  const status = useRecoilValue(actualityHqTableDataLoadStatus);
  const queryBuilderParams = useRecoilValue(actualityHqBuilderParams);
  const [tableState, setTableState] = useRecoilState(actualityHqTableDataState);
  // const [runExportCSV] = useRunExportJobMutation();

  const [loadCallback, reset] = useLoadActualityHqTableData();
  const columnsConfig = useActualityHqDataTableConfig();

  const { searchValues, sortValues } = tableState;

  useEffect(() => {
    callDataLoad(tableState.searchValues, tableState.sortValues);
  }, [props.id, tableState, queryBuilderParams]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  function callDataLoad(
    searchValues: Record<string, string>,
    sortValues: Partial<Record<string, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filterBy: ActualityHqFilterBy = _.reduce(
      _.entries(searchValues),
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: containsFilter(value),
        };
      },
      {} as ActualityHqFilterBy,
    );

    const sortBy: ActualityHqSortBy[] = _.map(
      sortValues,
      (value, key, index) => ({
        field: key as any,
        direction: value,
      }),
    ).filter(i => !!i);

    loadCallback({
      ...queryBuilderParams,
      isAppend: page.isAppend,
      page: {
        skip: page.skip,
      },
      filterBy,
      sortBy,
    });
  }

  function onLoadNext() {
    callDataLoad(tableState.searchValues, tableState.sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  // const converterConfig: ActivityFeedConverterConfig = {
  //   columnsConfig,
  // };

  // async function startExportCSV() {
  //   const query = getFeedTotalRowsQuery({
  //     datasetId,
  //   }).compile();
  //   const variables: ActivityFeedExportJobParams = {
  //     ...activityFeedExportJobParams,
  //     query: QueryDatasetDocument.loc.source.body,
  //     config: converterConfig,
  //     variables: {
  //       warehouseId: whId,
  //       query: query.sql,
  //       params: query.parameters,
  //     },
  //   };
  //   const { data, errors } = await runExportCSV({
  //     variables,
  //   });

  //   return {
  //     errors: errors,
  //     job: data.createExportJob,
  //   };
  // }

  // const flattenData = getActivityFeedTableRows(
  //   data?.content as ActivityFeedEventDataset[],
  //   converterConfig,
  // );

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;

  if (props.customRenders) {
    _.forEach(props.customRenders, (renderer, key) => {
      const cfg = _.find(columnsConfig, c => c.field === key);
      if (!_.isNil(cfg)) {
        cfg.render = renderer;
      }
    });
  }

  return (
    <DatasetTable
      id={props.id}
      data={data}
      columnsConfig={columnsConfig}
      keyFields={['eventId']}
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Activities`}
      title={props.title}
      isSticky
      onLoadNext={onLoadNext}
      onSearch={searchValues => {
        setTableState({ ...tableState, searchValues });
      }}
      onSort={sortValues => {
        setTableState({ ...tableState, sortValues });
      }}
      totalCount={totalCount}
      searchValues={searchValues}
      sortBy={sortValues}
      isLoading={isLoading}
      // onStartExportClick={startExportCSV}
    />
  );
};

export default ActualityHqDataTable;
