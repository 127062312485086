import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import LayoutFilter from '../components/LayoutFilter';
import {
  allocationPolicySelectedIdentity,
  allocationPolicySelectedRule,
} from '../simulation/store/allocationPolicy/allocationPolicy.state';

const AllocationRuleFilterTag: React.FC = () => {
  const { t } = useTranslation('simulation');
  const allocationPolicyRule = useRecoilValue(allocationPolicySelectedRule);
  const resetAllocationPolicySelection = useResetRecoilState(
    allocationPolicySelectedIdentity,
  );

  return (
    <>
      {allocationPolicyRule && (
        <LayoutFilter
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`Rule`}
          onRemoveClick={() => resetAllocationPolicySelection()}
          type="selection"
        >
          {allocationPolicyRule?.title}
        </LayoutFilter>
      )}
    </>
  );
};

export default () => (
  <Suspense>
    <AllocationRuleFilterTag />
  </Suspense>
);
