import * as React from 'react';
const CursorMove1Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorMove1Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M45.76,35.02l13.57-2.52v9.09c0,2.58,2.09,4.67,4.67,4.67s4.67-2.09,4.67-4.67v-9.09l13.57,2.52c1.96.36,2.71-.73,1.67-2.43L65.88,3.01c-1.04-1.7-2.73-1.7-3.77,0l-18.03,29.58c-1.04,1.7-.28,2.79,1.67,2.43Z"
    />
    <path
      strokeWidth={0}
      d="M124.99,62.11l-29.58-18.03c-1.7-1.04-2.79-.28-2.43,1.67l2.52,13.57h-9.09c-2.58,0-4.67,2.09-4.67,4.67s2.09,4.67,4.67,4.67h9.09l-2.52,13.57c-.36,1.96.73,2.71,2.43,1.67l29.58-18.03c1.7-1.04,1.7-2.73,0-3.77Z"
    />
    <path
      strokeWidth={0}
      d="M82.24,92.98l-13.57,2.52v-9.09c0-2.58-2.09-4.67-4.67-4.67s-4.67,2.09-4.67,4.67v9.09l-13.57-2.52c-1.96-.36-2.71.73-1.67,2.43l18.03,29.58c1.04,1.7,2.73,1.7,3.77,0l18.03-29.58c1.04-1.7.28-2.79-1.67-2.43Z"
    />
    <path
      strokeWidth={0}
      d="M46.26,64c0-2.58-2.09-4.67-4.67-4.67h-9.09l2.52-13.57c.36-1.96-.73-2.71-2.43-1.67L3.01,62.12c-1.7,1.04-1.7,2.73,0,3.77l29.58,18.03c1.7,1.04,2.79.28,2.43-1.67l-2.52-13.57h9.09c2.58,0,4.67-2.09,4.67-4.67Z"
    />
  </svg>
);
export default CursorMove1Icon;
