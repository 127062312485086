import { LayoutLocationsSummaryGroupBy } from '@warebee/frontend/data-access-api-graphql';
import {
  getLocationsStatsTableRows,
  LocationsStatsConverterConfig,
} from '@warebee/shared/export-converter';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useLoadLocationsStatsByRule from '../../assignmentPolicy/useLoadLocationsStatsByRule';
import { formatInteger } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import DropdownSelector from '../../components/actions/DropdownSelector';
import InboxZero from '../../components/InboxZero';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListItem } from '../../components/stats/StatListItem';
import { getAislesSpec } from '../../layout/viewer/store/aisleSpec.config';
import { getAisleSpec } from '../../layout/viewer/store/viewer.helper';
import { viewerAislesMeta } from '../../layout/viewer/store/viewer.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import {
  policyFilteredLocationsStatsLoadStatus,
  policyFilteredLocationsStatsSearchValues,
  policyFilteredLocationStats,
  policyLocationsStatsDimension,
} from '../store/policyFilter.state';
import { simulationLayoutSummary } from '../store/simulation.layout.state';
import { simulationAssignmentId } from '../store/simulation.state';
import {
  getLocationsStatsDimensions,
  locationStatsDrillDownDimensions,
} from '../tableViews/locationsStatsTable.config';

export type SummaryViewProps = {
  warehouseId?: string;
  layoutId?: string;
};

const SimulationInfoPanelLayout: React.FC<SummaryViewProps> = props => {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('simulation');
  const summary = useRecoilValue(simulationLayoutSummary);
  const assignmentId = useRecoilValue(simulationAssignmentId);
  const [dimensionId, setDimensionId] = useRecoilState(
    policyLocationsStatsDimension,
  );
  const aislesMap = useRecoilValue(viewerAislesMeta);
  const locationsStatsData = useRecoilValue(policyFilteredLocationStats);
  const setFilters = useSetRecoilState(
    policyFilteredLocationsStatsSearchValues,
  );
  const locationStatsStatus = useRecoilValue(
    policyFilteredLocationsStatsLoadStatus,
  );
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const [loadCallback, cancelLoad] = useLoadLocationsStatsByRule();

  function callDataLoad() {
    cancelLoad();
    loadCallback({
      includeMatching: null,
      skipDefaultDimensions: true,
    });
  }

  // update data when policy changed
  useEffect(() => {
    if (summary) {
      callDataLoad();
    }
  }, [summary, dimensionId]);

  // cancel requests when unmount
  useEffect(() => {
    setFilters({});
    return () => cancelLoad();
  }, []);

  if (!summary) {
    return <InboxZero selfCenter message={t`Layout Dataset is not selected`} />;
  }

  const dimensions = getLocationsStatsDimensions(t);
  const dimensionConfigCurrent = dimensions[dimensionId];

  const aislesSpec = getAislesSpec(tApp);
  const aislesMapFiltered = _.filter(
    aislesMap,
    (a: any) =>
      _.isNil(a.details?.aisleType) || a.details?.aisleType === 'AISLE',
  );
  const groupedAisles = _(aislesMapFiltered)
    .groupBy(a => getAisleSpec(a, ms, tApp)?.id)
    .value();

  const sortBy = dimensions[dimensionId]?.field;

  const converterConfig: LocationsStatsConverterConfig = {
    columnsConfig: null,
    dictionaries: {
      aisleTitleMap: aislesMap,
    },
    searchValues: {},
    sortColumnField: sortBy,
  };

  const data = getLocationsStatsTableRows(locationsStatsData, converterConfig);

  const totalAisleCount = _.size(aislesMapFiltered);
  const isLoading = _.isNil(summary) || _.isNil(aislesMap);
  return (
    <>
      <ScreenTitle title={t`Layout`} subtitle={t`Stats`} isSticky />
      <div className={classNames('p-2')}>
        <StatGroup>
          <Stat
            dashboardView
            isPreview
            title={t`Aisles`}
            value={formatInteger(totalAisleCount)}
            isLoading={isLoading}
          >
            {_.map(groupedAisles, (items, key) => {
              const spec = aislesSpec[key];
              const count = _.size(items);

              console.log('aisle spec', spec);
              console.log('aisle count', count);

              return (
                <StatListItem
                  key={spec?.id}
                  title={spec?.title ?? 'Unknown'}
                  value={formatInteger(count)}
                />
              );
            })}
          </Stat>
          <Stat
            dashboardView
            isPreview
            isLoading={isLoading}
            titleElement={
              <div className={classNames('flex flex-1')}>
                <div
                  className={classNames('flex-1 ltr:mr-1 rtl:ml-1')}
                >{t`Locations`}</div>
                <DropdownSelector
                  className={classNames('text-sm mx-1')}
                  DropAlignRight
                  panelMode
                  value={dimensionId}
                  values={[
                    ...locationStatsDrillDownDimensions,
                    LayoutLocationsSummaryGroupBy.LOCATION_STATUS,
                  ]}
                  onChange={dim => setDimensionId(dim)}
                  renderValue={key => dimensions[key]?.title}
                />
              </div>
            }
            value={formatInteger(summary.locationCount)}
          >
            {locationStatsStatus === AsyncLoadStatus.Loading && (
              <LoadingIndicator selfCenter message={t`Loading...`} />
            )}
            {locationStatsStatus === AsyncLoadStatus.Ok &&
              _.map(data, (item, index) => {
                const titleRaw = item[dimensionConfigCurrent.field] as string;
                const title = dimensionConfigCurrent.render
                  ? dimensionConfigCurrent.render(titleRaw, item)
                  : titleRaw;

                const valueRaw = item.locationCount;
                const value = formatInteger(valueRaw);
                return (
                  <StatListItem
                    key={`item-${title}-#${index}`}
                    title={title}
                    value={value}
                  />
                );
              })}
          </Stat>
          <Stat
            dashboardView
            isPreview
            title={t`Bays`}
            value={formatInteger(summary.bayCount)}
          />
        </StatGroup>
      </div>
    </>
  );
};

export default SimulationInfoPanelLayout;
