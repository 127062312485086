import { BillableOperationType } from '@warebee/shared/app-billing-utils';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../../components/icons';

export type FeatureListPreset = 'short' | 'full';

const featureItems = (t: TFunction<'app'>) => [
  {
    title: t`Visualise`,
    description: t`Warehouse visibility to get actionable insights. See your warehouse from multiple perspectives.
`,
    services: ['ANALYZE', 'OPTIMIZE'],
    presets: ['full', 'short'],
    sub_features: [
      {
        title: t`Warehouse Layout Designer`,
        description: t`Easily create your warehouse layout in minutes`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['full'],
      },
      {
        title: t`Stock positions`,
        description: t`Instantly see your stock on the warehouse layout`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['full'],
      },
      {
        title: t`Aisle and Bay View`,
        description: t`View your aisles and bays in 2.5D`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Picking route visualisation`,
        description: t`See your pickers path through the aisles`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Location data issues`,
        description: t`Quickly identify data issues `,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Locations occupancy map`,
        description: t`Find empty or disabled locations`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
    ],
  },
  {
    title: t`Analyse`,
    description: t`Warehouse analytics to measure performance \nCost to serve, CO2 footprint, space and resource utilisation compared to industry standards.`,
    services: ['ANALYZE', 'OPTIMIZE'],
    presets: ['full', 'short'],
    sub_features: [
      {
        title: t`Order picking cost`,
        description: t`Understand your “cost to serve” on the order level`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['full'],
      },
      {
        title: t`CO2 Footprint`,
        description: t`Report accurate CO2 footprint of every warehouse activity`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['full'],
      },
      {
        title: t`ABC Analysis`,
        description: t`See where your fast movers placed`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Congestion Analysis`,
        description: t`Identify traffic bottlenecks on location and aisle levels`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Replenishment Analysis`,
        description: t`Realise replenishment overhead on your operations`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Workforce and equipment utilisation`,
        description: t`Compare resources utilisation`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Idle time breakdown`,
        description: t`Count unproductive time per resource`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Stackability`,
        description: t`Find where your heavy items crashing the fragile ones`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
    ],
  },
  {
    title: t`Compliance`,
    description: t`Audit warehouse compliance and map the risks to stock and operations.`,
    services: ['ANALYZE', 'OPTIMIZE'],
    presets: ['full', 'short'],
    sub_features: [
      {
        title: t`Pallet racking weight limits`,
        description: t`See if your storage bays are overloaded`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Individual location weight limits`,
        description: t`Make every location compliant`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Dangerous Goods`,
        description: t`Store dangerous goods according to the regulations`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Fire Safety`,
        description: t`Minimise fire risk by storing stock correctly`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Fair Trade storage policies`,
        description: t`Report Fair Trade storage compliance saving inspector’s visit`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Your storage policies`,
        description: t`Configure your own policies and validate that items are stored accordingly`,
        services: ['ANALYZE', 'OPTIMIZE'],
        presets: ['short', 'full'],
      },
    ],
  },
  {
    title: t`Optimise`,
    description: t`Improve warehouse throughput and resource utilisation by re-slotting warehouse locations`,
    services: ['OPTIMIZE'],
    presets: ['full', 'short'],
    sub_features: [
      {
        title: t`Optimal picking route`,
        description: t`Shorten travelling time to increase picking productivity`,
        services: ['OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Minimal congestion`,
        description: t`Eliminate bottlenecks in aisles`,
        services: ['OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Optimal compliance`,
        description: t`Maintain compliance while optimising performance`,
        services: ['OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`Lowest CO2 footprint`,
        description: t`Optimise for smaller Carbon footprint`,
        services: ['OPTIMIZE'],
        presets: ['short', 'full'],
      },
      {
        title: t`“What If” simulations`,
        description: t`Estimate the impact of upgrading MHE, changing layout or demand volatility`,
        services: ['OPTIMIZE'],
        presets: ['short', 'full'],
      },
    ],
  },
];

export type AppBillingFeaturesListProps = {
  operationType: BillableOperationType;
  featurePreset: FeatureListPreset;
  classNameTitle?: string;
  classNameDescription?: string;
  classNameIcon?: string;
};

const AppBillingFeaturesList: React.FC<AppBillingFeaturesListProps> = props => {
  const { t } = useTranslation('app');

  const features = featureItems(t).filter(
    fi =>
      fi.services.includes(props.operationType) &&
      fi.presets.includes(props.featurePreset),
  );
  const [id] = useState<string>(_.uniqueId());

  return (
    <ul
      id={'AppBillingFeaturesList-' + props.operationType}
      key={'AppBillingFeaturesList-' + props.operationType}
      className={classNames(
        'my-4 mb-4',
        'divide-y divide-solid divide-menu-400/50',
      )}
    >
      {features.map(item => (
        <li
          data-component={`AppBillingFeatures-${item.title}`}
          id={props.operationType + '-' + item.title}
          key={props.operationType + '-' + item.title}
          className="py-2 ltr:pl-2 rtl:pr-2"
        >
          <div className="flex">
            <Icon.CircleOk
              className={classNames(
                'w-10 h-10 fill-current',
                props.classNameIcon,
              )}
            />
            <div className="flex-1 ltr:ml-2 rtl:mr-2">
              <div
                className={classNames(
                  'text-xl font-bold',
                  props.classNameTitle,
                )}
              >
                {item.title}
              </div>

              {item.description && (
                <div
                  className={classNames(
                    'text-base',
                    props.classNameDescription,
                  )}
                >
                  {item.description}
                </div>
              )}

              {props.featurePreset === 'full' && (
                <>
                  {item.sub_features.map(sub_item => (
                    <div
                      data-component={`AppBillingFeaturesSub-${item.title}`}
                      key={sub_item.title}
                      id={sub_item.title}
                      className="flex mt-1 mb-2"
                    >
                      <Icon.CircleOk
                        className={classNames(
                          'w-5 h-5 fill-current',
                          props.classNameIcon,
                        )}
                      />
                      <div className="flex-1 ltr:ml-2 rtl:mr-2">
                        <div
                          className={classNames(
                            'text-base font-bold',
                            props.classNameTitle,
                          )}
                        >
                          {sub_item.title}
                        </div>
                        {_.includes(sub_item.presets, 'full') &&
                          sub_item.description && (
                            <div
                              className={classNames(
                                'text-base',
                                props.classNameDescription,
                              )}
                            >
                              {sub_item.description}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AppBillingFeaturesList;
