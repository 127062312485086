import { AnalyzePicklistFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import { cn } from '../../../common/utils';
import LoadingIndicator from '../../../components/LoadingIndicator';
import TitleSection from '../../../components/layout/TitleSection';
import { Stat } from '../../../components/stats/Stat';
import { PicklistRoute } from '../../../layout/features/features.types';
import { viewerSelectedPlaneId } from '../../../layout/viewer/store/viewer.state';
import { AgentCategoryId } from '../../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../../resourcePolicy/agentData/agentDataHelper';
import useLoadAnalyzedPickListDetails from '../../hooks/useLoadAnalyzedPicklistDetails';
import { resourcePolicyAgentById } from '../../store/resourcePolicy.state';
import { simulationAnalyzePicklistEventsLoadStatus } from '../../store/simulation.state';
import PicklistEvents from './PicklistEvents';
import { StatMiniInline } from './StatInline';

export type PicklistDetailsProps = {
  picklistSummary: AnalyzePicklistFragment;
  selectedPicklist: RecoilState<string>;
  setRoutePart: RecoilState<PicklistRoute>;
  setRoutePartWithZoom: RecoilState<PicklistRoute>;
  wpType?: 'before' | 'after';
  analyzeId: string;
  orderId: string;
};

export const PicklistDetails: React.FC<PicklistDetailsProps> = props => {
  const picklist = props.picklistSummary.picklist;
  const [selectedPicklist, setSelectedPicklist] = useRecoilState(
    props.selectedPicklist,
  );
  const eventsLoadStatus = useRecoilValue(
    simulationAnalyzePicklistEventsLoadStatus(picklist.id),
  );
  const setAreaId = useSetRecoilState(viewerSelectedPlaneId);
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  const agent = useRecoilValue(resourcePolicyAgentById(picklist.agents?.[0]));
  const [loadEvents, cancelLoadEvents] = useLoadAnalyzedPickListDetails();

  useEffect(() => {
    if (
      selectedPicklist === picklist.id &&
      eventsLoadStatus === AsyncLoadStatus.None
    ) {
      loadEvents({
        analyzeId: props.analyzeId,
        orderId: props.orderId,
        picklistId: picklist.id,
      });
    }
  }, [selectedPicklist, eventsLoadStatus]);

  function toggleSelection() {
    if (!isSelected) {
      setAreaId(picklist.planeId);
    }

    setSelectedPicklist(isSelected ? null : picklist.id);
  }

  // Picklist Stats
  const picklistCost = formatter.formatCurrency(
    props.picklistSummary.totalCost,
  );

  const picklistDistanceRaw = props.picklistSummary.totalDistance;
  const picklistDistance = formatter.formatDistance(picklistDistanceRaw);

  const picklistDurationRaw = props.picklistSummary.totalDuration;
  const picklistDuration = formatter.formatTimeSpan(picklistDurationRaw);

  const picklistWeightRaw = picklist?.totalNetWeight;
  const picklistWeight = formatter.formatWeight(picklistWeightRaw);

  const picklistVolumeRaw = picklist?.totalVolume;
  const picklistVolume = formatter.formatVolume(picklistVolumeRaw);

  const picklistLinesRaw = props.picklistSummary.totalLines;
  const picklistLines = formatInteger(picklistLinesRaw);
  const picklistLinesSpeed = formatter.formatShare(
    picklistLinesRaw / picklistDurationRaw,
  );

  // Agent — Weight - Capacity
  const agentCapacityMaxWeightRaw = agent?.specs?.capacity?.maxWeight;
  const agentCapacityMaxWeight = formatter.formatWeight(
    agentCapacityMaxWeightRaw,
  );

  // Agent — Weight - Utilisation
  const agentUtilizationWeightRaw =
    picklistWeightRaw / agentCapacityMaxWeightRaw;
  const agentUtilizationWeight = formatter.formatShare(
    agentUtilizationWeightRaw,
  );

  // Agent — Volume - Capacity
  const agentCapacityMaxVolumeRaw = agent?.specs?.capacity?.maxVolume;
  const agentCapacityMaxVolume = formatter.formatVolume(
    agentCapacityMaxVolumeRaw,
  );

  // Agent — Volume - Utilisation
  const agentUtilizationVolumeRaw =
    picklistVolumeRaw / agentCapacityMaxVolumeRaw;
  const agentUtilizationVolume = formatter.formatShare(
    agentUtilizationVolumeRaw,
  );

  const isSelected = _.includes(selectedPicklist, picklist.id);

  const statStyle = classNames(
    'm-0.5 border border-px',
    isSelected
      ? 'border-app-panel-dark/50 !text-menu-active-text !bg-menu-active'
      : 'shadow border-menu/50',
  );

  const statStyleThreshold = classNames(
    'm-0.5 border border-px',
    isSelected
      ? 'border-app-panel-dark/50 !text-menu-active-text !bg-menu-active'
      : 'shadow border-menu/50',
  );
  const statStyleThresholdNull = classNames(
    'm-0.5 border border-px',
    isSelected
      ? 'border-app-panel-dark/50 !text-menu-active-text !bg-menu-active'
      : 'shadow border-menu/50',
  );

  const highlightThresholdNullWeight = isNaN(agentUtilizationWeightRaw);
  const highlightThresholdWeight = agentUtilizationWeightRaw > 0.7;

  const highlightThresholdNullVolume = isNaN(agentUtilizationVolumeRaw);
  const highlightThresholdVolume = agentUtilizationVolumeRaw > 0.7;
  const highlight = `bg-brand`;

  return (
    <div data-component="PicklistDetails">
      <Stat
        inPanelMode
        hasHelper
        isActionable
        isPreview
        titleAlt={picklist.title ?? ''}
        // title={picklist.title || ''}
        title={
          ((picklist.pickByDate ?? '') || 'P') +
          (picklist.orderIds.length + '—' || '') +
          (_.takeRight(_.split(picklist.title, '/'), 2).join('—') || '')
          // (agent.title || '')
        }
        // className="max-width: 25ch;"
        toggleIconOpen={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
        toggleIconClosed={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
        value={picklistCost.raw}
        unitOfMeasure={picklistCost.unit}
        isUnitPrefix={picklistCost.prefixUnit}
        isFullWidth
        isSelected={isSelected}
        onClick={toggleSelection}
      >
        <div className="flex w-full flex-wrap">
          <StatMiniInline
            transparent
            className={`${statStyle}`}
            titleAlt={t`Distance`}
            value={picklistDistance.value}
            unitOfMeasure={picklistDistance.unit}
          />
          <StatMiniInline
            transparent
            titleAlt={t`Duration`}
            className={`${statStyle}`}
            value={picklistDuration.value}
            unitOfMeasure={picklistDuration.unit}
          />
          {/* <StatMiniInline
            transparent
            className={`${statStyle}`}
            title={t`W.`}
            value={picklistWeight.value}
            unitOfMeasure={picklistWeight.unit}
          />
          <StatMiniInline
            transparent
            className={`${statStyle}`}
            title={t`Cap.`}
            value={agentCapacityMaxWeight.value}
            unitOfMeasure={agentCapacityMaxWeight.unit}
          /> */}
          {agentUtilizationWeightRaw > 0 && (
            <StatMiniInline
              transparent
              className={`${
                highlightThresholdWeight
                  ? statStyleThreshold
                  : highlightThresholdNullWeight
                    ? statStyleThresholdNull
                    : statStyle
              }`}
              title={t`Wgt.`}
              titleAlt={t(
                `Agent Weight Cap. ${agentCapacityMaxWeight.fullString} / Picklist Weight. ${picklistWeight.fullString} (${agentUtilizationWeight.fullString})`,
              )}
              value={agentUtilizationWeight.value}
              unitOfMeasure={agentUtilizationWeight.unit}
            />
          )}

          {agentUtilizationVolume.value !== '0' && (
            <StatMiniInline
              transparent
              className={`${
                highlightThresholdVolume
                  ? statStyleThreshold
                  : highlightThresholdNullVolume
                    ? statStyleThresholdNull
                    : statStyle
              }`}
              title={t`Vol.`}
              titleAlt={t(
                `VOLUME: Agent ${agentCapacityMaxVolume.fullString} / Picklist ${picklistVolume.fullString} (${agentUtilizationVolume.fullString})`,
              )}
              value={agentUtilizationVolume.value}
              unitOfMeasure={agentUtilizationVolume.unit}
            />
          )}
          <StatMiniInline
            transparent
            titleAlt={t('Line Speed {{value}} l/hour', {
              value: picklistLinesSpeed.value,
            })}
            className={`${statStyle}`}
            value={picklistLines}
            unitOfMeasure={t`lines`}
          />
        </div>
      </Stat>
      {eventsLoadStatus === AsyncLoadStatus.Loading && (
        <div className="p-4">
          <LoadingIndicator
            oneLiner
            selfCenter
            circleSize={30}
            message={t`Loading Picklist details`}
          />
        </div>
      )}
      {eventsLoadStatus === AsyncLoadStatus.Ok && (
        <TitleSection
          id={`picklist-events-${picklist.id}`}
          dataComponent="Picklist"
          className={cn(
            'ltr:pl-4 rtl:pr-4',
            isSelected
              ? 'bg-menu-active !text-menu-active-text border-menu-400/50 border-t'
              : 'bg-menu',
          )}
          title={t`Events`}
          // inListView
          inPanelView
          collapsible
        >
          <PicklistEvents {...props} />
        </TitleSection>
      )}
    </div>
  );
};
export default PicklistDetails;
