const PickingAgentRunningIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PickingAgentRunningIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M121.68,34.12l-5.42-8.98c-.48-.8-1.35-1.29-2.28-1.29h-32.91c-.93,0-1.8.49-2.28,1.29l-5.59,9.27s-.03.07-.04.1c-.08.14-.14.29-.19.45-.02.06-.04.11-.05.17-.04.17-.07.35-.08.53,0,.03,0,.07,0,.1,0,0,0,.01,0,.02v8.49l-15.09-13.77h0c-2.37-2.83-6-4.38-9.86-3.9-6.13.76-10.47,6.35-9.71,12.47l4.27,35.22-7.5,19.18-22.93.84c-3.64.34-6.41,3.47-6.24,7.16.17,3.8,3.39,6.74,7.42,6.56l31.35-1.45,10.38-24.69,18.67,14.31,4.39,22.56c.88,3.55,4.4,5.81,8.02,5.09,3.73-.74,6.16-4.36,5.37-8.32l-5.81-27.8-20.97-15.9-2.54-20.96,10.79,9.5v8.81c0,1.47,1.19,2.67,2.67,2.67h44.1c1.47,0,2.67-1.19,2.67-2.67v-33.16c0-.09.03-.17.03-.26,0-.63-.22-1.21-.58-1.66ZM100.19,29.18h12.28l2.37,3.94h-14.66v-3.94ZM82.57,29.18h12.29v3.94h-14.66l2.38-3.94ZM116.9,66.53h-38.77v-4.97l17.21.03h.66c3.54-.07,6.2-2.82,6.15-6.17-.06-3.25-2.66-5.84-5.87-5.94l-17.82-.03-.34-.31v-10.69h38.77v28.08ZM56.9,25.24c5.84.73,11.17-3.41,11.9-9.26.73-5.84-3.42-11.17-9.26-11.9-5.84-.73-11.17,3.41-11.9,9.26-.73,5.84,3.42,11.17,9.26,11.9Z"
    />
  </svg>
);
export default PickingAgentRunningIcon;
