import * as React from 'react';
const PolicyComplianceWeightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceWeightIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M86.77,65.02c-.33-1.45-.58-1.53-.89-1.35-.31.18-.48.5-.15,1.95l.32,1.44c.32,1.45.58,1.52.89,1.34.31-.18.48-.5.15-1.94l-.32-1.44Z"
    />
    <path
      strokeWidth={0}
      d="M79.82,69.03c-.33-1.45-.57-1.53-.88-1.36-.31.18-.48.5-.15,1.95l.32,1.44c.32,1.45.58,1.52.89,1.34.31-.18.47-.49.15-1.94l-.32-1.44Z"
    />
    <path
      strokeWidth={0}
      d="M84.08,42.26l-8.98-4.04c.79-1.85,1.22-3.92,1.22-6.11,0-7.78-5.54-14.11-12.35-14.11s-12.34,6.32-12.34,14.11c0,2.01.36,3.92,1.03,5.66l-11.53,4.63-14,33.63,36.62,21.56,37.14-21.59-16.8-33.74ZM63.97,25.14c3.35,0,6.08,3.18,6.07,7.09,0,1.15-.24,2.24-.66,3.21-.15.35-.32.68-.53.99-1.1,1.75-8.83,1.66-9.92-.23h0c-.19-.32-.35-.66-.49-1.01-.35-.9-.55-1.9-.55-2.96,0-3.91,2.72-7.09,6.07-7.09ZM63.61,53.11l-16.52-7.83,7.56-3.29c1.91,3.54,16.3,3.7,18.24.38l5.6,2.82-14.89,7.92ZM76.19,77.44l-4.94,2.85c-.11.06-.24,0-.28-.14l-.47-2.08c-.03-.14.05-.32.16-.38l1.25-.72-.91-4.08-1.12,1.22-.12.11c-.08.05-.12.01-.14-.09l-.5-2.21c-.03-.13,0-.23.09-.35l1.67-1.99c.08-.08.12-.14.21-.19l1.45-.84c.12-.07.24,0,.27.14l1.54,6.86,1.25-.72c.12-.07.24,0,.27.13l.47,2.08c.03.15-.04.32-.16.39ZM80.59,75.04c-1.93,1.11-3.28.34-3.93-2.56l-.32-1.44c-.65-2.91.09-4.89,2.02-6,1.93-1.11,3.28-.34,3.93,2.57l.32,1.44c.65,2.9-.08,4.88-2.01,5.99ZM87.53,71.03c-1.93,1.11-3.28.34-3.93-2.56l-.32-1.44c-.65-2.91.08-4.89,2.01-6,1.93-1.11,3.28-.34,3.94,2.57l.32,1.44c.65,2.9-.09,4.88-2.02,5.99Z"
    />
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM109.37,70.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z"
    />
  </svg>
);
export default PolicyComplianceWeightIcon;
