import { useRunCalculateAllocationRequirementMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  allocationRequirement,
  allocationRequirementLoadStatus,
} from '../store/allocation/allocationRequirement.state';
import { simulationCurrentId } from '../store/simulation.state';

function useRunAllocationRequirements() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load ordered quantity report`;
  const [runJob] = useRunCalculateAllocationRequirementMutation();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(allocationRequirement, null);
    set(allocationRequirementLoadStatus, AsyncLoadStatus.Loading);
  });

  const runAllocationRequirement = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const simulationId = await snapshot.getPromise(simulationCurrentId);

        function handleError(details?, stack?) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRequirementLoadStatus, AsyncLoadStatus.Error);
        }

        try {
          const { data: jobData, errors: jobErrors } = await runJob({
            variables: { simId: simulationId },
          });

          if (
            !_.isEmpty(jobErrors) ||
            _.isNil(jobData.calculateSimulationAllocationRequirements?.id)
          ) {
            const details = _(jobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          set(
            allocationRequirement,
            jobData.calculateSimulationAllocationRequirements,
          );
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call() {
    await initLoading();
    await runAllocationRequirement();
  }

  return [call] as const;
}

export default useRunAllocationRequirements;
