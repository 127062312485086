import {
  CheckOptimizationSwapPolicyNoValidSwapsReason,
  CheckedOptimizationSwapRuleDetailsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';
import { Alert } from '../components/alerts/Alerts';

function getReasonMessage(
  reason: CheckOptimizationSwapPolicyNoValidSwapsReason,
  constrains: string[],
  t: TFunction<'simulation'>,
): string {
  const reasonsMessageMap = {
    ASSIGNMENT_POLICY: t(`Storage policy`, { ns: 'simulation' }),
    BAY_WIDTH: t(`Bay width constrains`, { ns: 'simulation' }),
    LOCATION_SIZE: t(`Location size constrains`, { ns: 'simulation' }),
  };

  switch (reason) {
    case CheckOptimizationSwapPolicyNoValidSwapsReason.EMPTY_SOURCE_SET:
      return t(`No matched assignment items in source`, { ns: 'simulation' });
    case CheckOptimizationSwapPolicyNoValidSwapsReason.EMPTY_DESTINATION_SET:
      return t(`No matched assignment items in destination`, {
        ns: 'simulation',
      });
    case CheckOptimizationSwapPolicyNoValidSwapsReason.NO_MATCH_BY_PICKING_POLICY:
      return t(`No matched by picking policy`, { ns: 'simulation' });
    case CheckOptimizationSwapPolicyNoValidSwapsReason.NO_MATCH_BY_SWAP_RULES:
      return t(`No matched by swap policy`, { ns: 'simulation' });
    case CheckOptimizationSwapPolicyNoValidSwapsReason.CONSTRAINT_VIOLATION:
      return t(`Constraint violation: {{violatedConstraints}} `, {
        ns: 'simulation',
        violatedConstraints: _(constrains)
          .map(c => reasonsMessageMap[c])
          .join(' ,'),
      });
  }
}

export type SwapPolicyIssueProps = {
  details: CheckedOptimizationSwapRuleDetailsFragment;
};

const SwapPolicyIssue: React.FC<SwapPolicyIssueProps> = props => {
  const { t } = useTranslation('simulation');

  let messages = _.map(props.details.noValidSwapsReasons, reason =>
    getReasonMessage(reason, props.details.violatedConstrains, t),
  );

  return (
    <Alert
      messageHelper={t`WARNING`}
      message={t`Rule doesn't define any valid swaps `}
      hasStatusAlert
      className="m-1"
    >
      {_.map(messages, (m, i) => (
        <div key={`${m}-${i}`}> {m} </div>
      ))}
    </Alert>
  );
};
export default SwapPolicyIssue;
