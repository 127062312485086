import {
  LayoutDashboardItemFragment,
  LayoutImportDashboardItemFragment,
  useRemoveLayoutImportMutation,
  useRemoveLayoutMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import * as Icon from '../../components/icons';
import LayoutDashboardCard from '../../layout/LayoutDashboardCard';
import { importTriggeredBySim } from '../../store/global.state';
import {
  warehouseIsDemo,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import DashboardItemsGroupContainer from '../DashboardItemsGroupContainer';
import DashboardItemContainer, {
  DashboardItemContainerProps,
} from '../containers/DashboardItemContainer';
import DashboardListItemContainer from '../containers/DashboardListItemContainer';
import useLoadLayoutDashboard from '../hooks/useLoadLayoutDashboard';
import DashboardLayoutInfoCard from '../infoCards/DashboardLayoutInfoCard';
import {
  dashboardLayoutData,
  dashboardLayoutLoadState,
  dashboardLayoutState,
} from '../store/dashboard.state';
import {
  DashboardItemStatusType,
  DashboardOrder,
  DashboardViewMode,
} from '../store/dashboard.types';

const DataDashboardLayout: React.FC = () => {
  const { t } = useTranslation('app');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const isDemo = useRecoilValue(warehouseIsDemo);
  const [removeLayout] = useRemoveLayoutMutation();
  const [removeLayoutImport] = useRemoveLayoutImportMutation();
  const dashData = useRecoilValue(dashboardLayoutData);
  const [dashState, setDashState] = useRecoilState(dashboardLayoutState);
  const loadingState = useRecoilValue(dashboardLayoutLoadState);
  const loadDashboardData = useLoadLayoutDashboard();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);

  useEffect(() => {
    // clear import from simulation state
    setImportTriggerSimId(null);
  });

  useEffect(() => {
    loadDashboardData({ sort: dashState.order });
  }, [warehouseId]);

  function changeViewMode(viewMode: DashboardViewMode) {
    setDashState({
      ...dashState,
      viewMode,
    });
  }

  function changeSort(sortMode: DashboardOrder) {
    setDashState({
      ...dashState,
      order: sortMode,
    });
    loadDashboardData({ sort: sortMode, limit: dashState.loadedCount });
  }

  function loadMore() {
    loadDashboardData({
      sort: dashState.order,
      skip: dashState.loadedCount,
      isAppend: true,
    });
  }

  const getCards = () => {
    if (_.isEmpty(dashData)) return null;
    return _.sortBy(dashData).map(item => {
      return item.__typename === 'Layout'
        ? getLayoutCard(item)
        : getLayoutImportCard(item);
    });
  };

  const getLayoutImportCard = (
    layoutImport: LayoutImportDashboardItemFragment,
  ) => {
    const { id, title, description } = layoutImport;
    const path = `/wh/i/${warehouseId}/import/layout/${id}/convert`;
    const status = {
      type: DashboardItemStatusType.draft,
      title: t`Draft`,
    };

    const containerProps: DashboardItemContainerProps = {
      id,
      title,
      description,
      createdDate: new Date(layoutImport.createdAt),
      updatedDate: new Date(layoutImport.updatedAt),
      path,
      status,
      canDelete: true,
      onDeleteClick: async () => {
        await removeLayoutImport({
          variables: { layoutImportId: id },
        });
        loadDashboardData({ sort: dashState.order });
      },
    };

    return dashState.viewMode === DashboardViewMode.card ? (
      <DashboardItemContainer
        icon={Icon.LayoutWarehouseTemplate}
        key={`card-layout-import-${id}`}
        {...containerProps}
        className={'bg-card-layout-draft'}
      >
        <LayoutDashboardCard
          summary={layoutImport.converterSummary}
          isActionable
        />
      </DashboardItemContainer>
    ) : (
      <DashboardListItemContainer
        key={`item-layout-import-${id}`}
        {...containerProps}
      />
    );
  };

  const getLayoutCard = (layout: LayoutDashboardItemFragment) => {
    const { id, title, description } = layout;
    const path = `/wh/i/${warehouseId}/layouts/${id}`;
    const status = {
      type: DashboardItemStatusType.complete,
      title: t`Ready`,
    };
    const statusInUsed = {
      type: DashboardItemStatusType.inuse,
      title: t`In Use`,
    };
    const hasSimulations = (layout.simulationsWith?.totalCount ?? 0) === 0;

    const containerProps: DashboardItemContainerProps = {
      id,
      title,
      description,
      createdDate: new Date(layout.createdAt),
      updatedDate: new Date(layout.updatedAt),
      path,
      status: hasSimulations ? status : statusInUsed,
      canDelete: hasSimulations,
      onDeleteClick: async () => {
        await removeLayout({
          variables: { layoutId: id },
        });
        loadDashboardData({ sort: dashState.order });
      },
      actions:
        _.isNil(layout.importedFrom?.id) || isDemo
          ? null
          : {
              Converter: async () => {
                return `/wh/i/${warehouseId}/import/layout/${layout.importedFrom?.id}/convert`;
              },
            },
    };

    return dashState.viewMode === DashboardViewMode.card ? (
      <DashboardItemContainer
        icon={Icon.WarehouseAreas}
        key={`card-layout-${id}`}
        {...containerProps}
        className={
          DashboardItemStatusType.complete
            ? 'bg-card-layout-draft'
            : DashboardItemStatusType.draft
              ? 'bg-card-layout-draft'
              : 'bg-card-layout-draft'
        }
      >
        <LayoutDashboardCard summary={layout?.summary} />
      </DashboardItemContainer>
    ) : (
      <DashboardListItemContainer
        key={`item-layout-${id}`}
        {...containerProps}
      />
    );
  };

  return (
    <DashboardItemsGroupContainer
      classNameContainerCards="space-x-1 space-y-1"
      id={'dashboard-data-layout'}
      title={t`Layouts`}
      state={dashState}
      isLoading={loadingState === AsyncLoadStatus.Loading}
      // collapsible
      onSortChanged={changeSort}
      onViewModeChanged={changeViewMode}
      onLoadMoreClick={loadMore}
      datasetPath="locations"
    >
      <DashboardLayoutInfoCard
        showButtonInline={!_.isEmpty(dashData)}
        listMode={dashState.viewMode === DashboardViewMode.list}
        isLoading={loadingState === AsyncLoadStatus.Loading}
      />
      {getCards()}
    </DashboardItemsGroupContainer>
  );
};

export default DataDashboardLayout;
