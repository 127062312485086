import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../../../components/HelperMessage';
import { Alert } from '../../../../components/alerts/Alerts';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { converterEditableAreaId } from '../store/converter.area.state';
import { converterLayoutBuilder } from '../store/converter.state';

const StepStartEndPoints: React.FC = () => {
  const { t } = useTranslation('designer');

  const layoutBuilder = useRecoilValue(converterLayoutBuilder);
  const setEditableAreaId = useSetRecoilState(converterEditableAreaId);

  useEffect(() => {
    setEditableAreaId(null);
  }, [setEditableAreaId]);

  const pointByFloorStatus = _.values(layoutBuilder.floors).map(floor => {
    const startPoint = layoutBuilder.entryPoints?.[floor.id];

    return (
      <div key={`start-point-floor-${floor.id}`} className="p-4">
        <TitleSection
          title={t(`Floor: {{floorTitle}}`, {
            floorTitle: floor.title,
          })}
          inPanelView
        />
        {startPoint ? (
          <div className="flex items-center p-2 mt-2 rounded-full bg-route-start text-menu-text">
            <Icon.CircleWaypoint4 className="w-8 h-8 ltr:mr-2 rtl:ml-2 fill-current" />
            <div className="flex flex-1 whitespace-pre truncate">
              <div className="truncate">{startPoint.Id1}</div>
              {'—'}
              <div className="truncate">{startPoint.Id2}</div>
            </div>
          </div>
        ) : (
          <Alert
            className="mt-4"
            hasStatusAlert
            message={`No Starting Point configured`}
          >{t`Click once on a waypoint dot on the map to select the Starting point`}</Alert>
        )}
      </div>
    );
  });

  return (
    <PanelBody>
      <ScreenTitle title={t`Starting Point(s)`} subtitle={t`Navigation`} />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerStartingPointGrid}
          question={t`Please select the starting point`}
        />
        <InputAnswerGroup>
          <HelpContainer
            id={'designer/04-navigation-starting-point'}
            hasPadding
          />

          <HelperMessage hasBefore iconAction={Icon.ArrowRight}>
            {t`Click on any of the `}
            <HelperMessageAction>{t`Dots`}</HelperMessageAction>
            {`on the map to define the starting point.`}
          </HelperMessage>
        </InputAnswerGroup>
      </InputQA>

      <TitleSection titleView title={t`Area Starting point`} />
      {/* <Callout panelMode type="suggestion">
        {t`Click on a waypoint on the map to deselect, or select another`}
      </Callout> */}
      {pointByFloorStatus}
    </PanelBody>
  );
};

export default StepStartEndPoints;
