import { TFunction } from 'i18next';

export const supportedLanguages = [
  'en-GB',
  'en-US',
  'he-IL',
  'fr-FR',
  'es-ES',
  'bg-BG',
  'de-DE',
  'ru-RU',
  'it-IT',
  'fi-FI',
  'nl-NL',
  'uk-UA',
  // 'da-DK',
  'tr-TR',
  // 'pt-PT',
  'zh-CN',
] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];

export function getLanguageTitle(
  lang: SupportedLanguage,
  t: TFunction<'app'>,
): string {
  switch (lang) {
    case 'en-GB':
      return t`English (British)`;
    case 'en-US':
      return t`English (American)`;
    case 'fr-FR':
      return t`French (Français)`;
    case 'de-DE':
      return t`German (Deutsch)`;
    case 'es-ES':
      return t`Spanish (Español)`;
    case 'it-IT':
      return t`Italian (Italiano)`;
    case 'bg-BG':
      return t`Bulgarian (Български)`;
    case 'fi-FI':
      return t`Finnish (Suomalainen)`;
    case 'nl-NL':
      return t`Dutch (Nederlandse)`;
    case 'uk-UA':
      return t`Ukrainian (Український)`;
    case 'he-IL':
      return t`Hebrew (עברית)`;
    case 'tr-TR':
      return t`Turkish (Türkçe)`;
    case 'zh-CN':
      return t`Chinese (中国)`;
    case 'ru-RU':
      return t`Russian (Русский)`;
  }

  return lang;
}
