import classNames from 'classnames';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import LayoutFilter from '../../components/LayoutFilter';
import {
  feedSelectedAgentId,
  feedSelectedPickListId,
} from '../store/feed.state';

const FeedActiveFiltersTags: React.FC = () => {
  const { t } = useTranslation('feed');
  const [selectedAgentId, setSelectedAgentId] =
    useRecoilState(feedSelectedAgentId);
  const [selectedPickListId, setSelectedPickListId] = useRecoilState(
    feedSelectedPickListId,
  );

  return (
    <>
      {selectedAgentId && (
        <LayoutFilter
          type="selection"
          onRemoveClick={() => setSelectedAgentId(null)}
          className={classNames('ltr:ml-1 rtl:mr-1')}
        >
          {selectedAgentId}
        </LayoutFilter>
      )}
      {selectedPickListId && (
        <LayoutFilter
          type="selection"
          filterName={t`Task:`}
          onRemoveClick={() => setSelectedPickListId(null)}
          className={classNames('ltr:ml-1 rtl:mr-1')}
        >
          {selectedPickListId}
        </LayoutFilter>
      )}
    </>
  );
};

export default () => (
  <Suspense>
    <FeedActiveFiltersTags />
  </Suspense>
);
