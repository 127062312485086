import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../../components/InboxZero';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import { stackingPolicyBayLocations } from '../../store/stackingPolicy/stackingPolicy.state';
import { StackingPolicyItem } from '../../store/stackingPolicy/stackingPolicy.types';

export type LocationPropertiesStackingPolicyProps = {
  locationId: string;
};

const LocationPropertiesStackingPolicy: React.FC<
  LocationPropertiesStackingPolicyProps
> = props => {
  const { t } = useTranslation('simulation');
  const locationMap = useRecoilValue(stackingPolicyBayLocations);

  const locationData = locationMap?.[props.locationId];
  const sectionTitle = t`Stacking Policy`;

  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty location`} message_helper={`(No Items)`} />
  );

  if (_.isNil(locationData)) {
    return <EmptyPlaceholder />;
  }

  function getItems(items: StackingPolicyItem[]) {
    return _(items)
      .sortBy(item => -item.sku)
      .map(p => {
        return (
          <>
            <StatListItem
              key={`item-${p.itemId}`}
              title={p.sku}
              labelValueType={'itemId'}
              labelValueFilters={{
                consignee: p.consignee,
                sku: p.sku,
              }}
            />
            <span
              data-component="StackingPolicyCategoryTag"
              aria-label={`Stacking Policy Category`}
              className={classNames(
                'flex',
                'text-center px-2 py-0.5',
                'rounded text-xxs whitespace-nowrap m-0.5',
                'cursor-pointer',
                'w-full',
                'bg-app-panel-dark/60',
              )}
              style={{
                color: p.color,
                border: '1px solid',
              }}
            >
              {p.policyRule?.title ?? t`Default (Middle)`}
            </span>
          </>
        );
      })
      .value();
  }

  return (
    <PanelContainer
      id="panel-stacking-policy-item"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      {!_.isEmpty(locationData) && (
        <>
          <StatListHeader title={t`Item`} />
          {getItems(locationData)}
        </>
      )}
    </PanelContainer>
  );
};

export default LocationPropertiesStackingPolicy;
