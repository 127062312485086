import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { getOccupancyCategory } from '../../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyBayLocation,
  assignmentOccupancyShowHeatmap,
} from '../../store/occupancy/assignmentOccupancy.state';

const AssignmentOccupancyBayLocationLayer: React.FC = () => {
  const locationLoadable = useRecoilValueLoadable(
    assignmentOccupancyBayLocation,
  );
  const showHeatmap = useRecoilValue(assignmentOccupancyShowHeatmap);

  if (locationLoadable.state !== 'hasValue') return null;

  const sizeByLocationMap = locationLoadable.contents;

  return (
    <BayLocationLayer
      stageId="simulation-bay-view"
      getColor={loc => {
        const locSize = sizeByLocationMap?.[loc.locationId];
        return !_.isNil(locSize) && showHeatmap
          ? [
              getOccupancyCategory(locSize?.occupancy)?.color,
              getOccupancyCategory(locSize?.occupancy)?.textColor,
            ]
          : null;
      }}
    />
  );
};

export default AssignmentOccupancyBayLocationLayer;
