import { QueryCreator, sql } from 'kysely';
import {
  AnalyzeEventsQueryBuilderParams,
  getAnalyzeEventsFilteredQueryBuilder,
} from './analyzedEventsQueryBuilder';
import { DatasetDatabase } from './datasetQueryBuilder';

export function analyzedFeedJobLinesQueryBuilder<T extends DatasetDatabase>(
  params: AnalyzeEventsQueryBuilderParams,
  db: QueryCreator<T>,
) {
  const builder = db
    .with(
      cte => cte('aje'),
      db => {
        return getAnalyzeEventsFilteredQueryBuilder(
          params,
          db as unknown as QueryCreator<DatasetDatabase>,
        ).selectAll();
      },
    )
    .with(
      cte => cte('ajl'),
      db => {
        return db
          .selectFrom('aje')
          .select(({ fn }) => [
            'sourceEventId',
            'jobId',
            'jobLine',
            'processType',
            'jobDate',
            fn.sum<number>('cost').as('cost'),
            fn.sum<number>('duration').as('duration'),

            //TRAVELLING START/END
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('durationTravellingStartEnd'),

            //DISTANCE
            fn
              .sum<number>(
                sql`cast(json_query(${sql.ref('details')}, 'strict $.distance') as double)`,
              )
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('distanceStartEnd'),

            //VOLUME
            fn
              .sum<number>(
                sql`cast(json_query(${sql.ref('details')}, 'strict $.volume') as double)`,
              )
              .as('volume'),

            //WEIGHT
            fn
              .sum<number>(
                sql`cast(json_query(${sql.ref('details')}, 'strict $.weight') as double)`,
              )
              .as('weight'),
            //HANDLING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', [
                'PRE_HANDLING',
                'HANDLING_PREPARATION',
                'HANDLING_ON_COMPLETION',
                'POST_HANDLING',
              ] as any)
              .as('durationHandling'),

            //PICKING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', ['HANDLING_EXECUTION'] as any)
              .as('durationPicking'),

            //PICKING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', ['TRAVELLING_VERTICAL'] as any)
              .as('durationTravellingVertical'),

            //TRAVELLING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', ['TRAVELLING_HORIZONTAL'] as any)
              .as('durationTravelling'),

            //REORDERING
            fn
              .sum<number>('duration')
              .filterWhere('eventType', 'in', [
                'HANDLING_PALLET_REORDERING',
              ] as any)
              .as('durationReordering'),

            fn
              .sum<number>(
                sql`cast(json_query(${sql.ref('details')}, 'strict $.distance') as double)`,
              )
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('distance'),

            fn
              .agg<string>('array_agg', [
                sql<string>`json_query(${sql.ref('details')}, 'strict $.route')`,
              ])
              .filterWhere('eventType', 'in', [
                'TRAVELLING_HORIZONTAL_START',
                'TRAVELLING_HORIZONTAL',
                'TRAVELLING_HORIZONTAL_END',
              ] as any)
              .as('route'),
            fn
              .agg<string>('array_agg', [
                sql<string>`json_query(${sql.ref('details')}, 'strict $.reason')`,
              ])
              .filterWhere('eventType', '=', 'SOURCE_EVENT_IGNORED' as any)
              .as('reasons'),
            fn
              .countAll<number>()
              .filterWhere('eventType', '=', 'SOURCE_EVENT_IGNORED' as any)
              .as('ignoredEventsCount'),
          ])
          .groupBy([
            'sourceEventId',
            'jobId',
            'processType',
            'jobDate',
            'jobLine',
          ]);
      },
    );
  return builder;
}
