import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import Prism, { highlight } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../../components/actions/Button';
import { ActionBar } from '../../components/nav/ActionBar';
import PanelContainer from '../../containers/PanelContainer';
import {
  actualityExtraSettings,
  actualitySelectedId,
} from '../store/actuality.state';
import { ActualityExtraSettings } from '../store/actuality.types';

const ActualityExtraSettingsEditor: React.FC = () => {
  const { t } = useTranslation('feed');
  const actualityId = useRecoilValue(actualitySelectedId);
  const [extraSettings, setExtraSettings] = useRecoilState(
    actualityExtraSettings,
  );
  const [stringSettings, setStringSettings] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [callUpdateActuality] = useUpdateActualityMutation();

  useEffect(() => {
    setStringSettings(JSON.stringify(extraSettings, null, '\t'));
  }, [extraSettings]);

  async function updateExtraSettings() {
    try {
      setIsLoading(true);
      setError(null);
      const newSettings = JSON.parse(stringSettings) as ActualityExtraSettings;
      setExtraSettings(newSettings);
      callUpdateActuality({
        variables: {
          input: {
            actualityId,
            extraSettings: newSettings,
          },
        },
      });
    } catch (ex) {
      console.log(ex);
      setError(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <PanelContainer
      id={`actuality-extra-settings-raw`}
      title={t`Extra settings`}
      collapsible
      hasPadding
    >
      <Editor
        value={stringSettings}
        onValueChange={v => setStringSettings(v)}
        highlight={code => {
          if (Prism.languages.json) {
            return highlight(code, Prism.languages.json, 'json');
          }
          return code;
        }}
        padding={10}
        disabled={false}
        style={{
          position: 'relative',
          overflowY: 'auto',
          fontFamily: 'monospace',
          fontSize: 13,
          paddingBottom: '3rem',
        }}
        className={classNames('bg-app-panel-dark')}
      />
      <ActionBar>
        <Button
          className={classNames('flex-1 text-sm')}
          label={t`Update`}
          buttonSize="sm"
          buttonType={error ? 'delete' : 'primary'}
          onPress={updateExtraSettings}
          isDisabled={false}
          isLoading={isLoading}
        />
      </ActionBar>
    </PanelContainer>
  );
};

export default ActualityExtraSettingsEditor;
