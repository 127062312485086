import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { InputGroupList } from '../components/inputs/InputGroupList';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { StatGroup } from '../components/stats/StatGroup';
import useLoadItemSetFilterValues from '../itemSet/hooks/useLoadItemSetFilterValues';
import { updatePolicyFilterValues } from '../policyFilters/policyFilter.helper';
import { getAssignmentStatsItemFilterConfig } from './assignmentStats.helper';
import { assignmentStatsEditorViewMode } from './assignmentStats.state';
import AssignmentStatsItemFilterEditor from './AssignmentStatsFilterEditor';

const AssignmentStatsFilterPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [startLoadData, cancelLoadData] = useLoadItemSetFilterValues();
  const setViewMode = useSetRecoilState(assignmentStatsEditorViewMode);

  const itemsFilterConfig = getAssignmentStatsItemFilterConfig(t);
  useEffect(() => {
    updatePolicyFilterValues({
      filterConfig: itemsFilterConfig,
      selectedFilterId: null,
      policyFilterUnion: null,
      load: startLoadData,
      cancel: cancelLoadData,
    });
  }, []);

  return (
    <ContainerScroll className="h-full">
      <StatGroup
        className="!my-0"
        classNameStatGroupItems="!mt-0 !pt-0"
        classNameTitle="!text-xs"
        title={t`Items filter`}
        // titleAction={
        //   <DropdownSelector
        //     classNameLabel="text-sm"
        //     onClick={e => {
        //       e.stopPropagation();
        //     }}
        //     DropAlignRight
        //     buttonTransparent
        //     vertical
        //     panelMode
        //     value={'...'}
        //     values={[t`Show legend`]}
        //     onChange={async (option, e) => {
        //       setViewMode('legend');
        //     }}
        //   />
        // }
      />
      <InputGroupList>
        {_.map(itemsFilterConfig, filterConfig => {
          return (
            <AssignmentStatsItemFilterEditor
              key={`item-set-filter-${filterConfig.type}`}
              filterConfig={filterConfig}
              startLoadData={startLoadData}
              cancelLoadData={cancelLoadData}
            />
          );
        })}
      </InputGroupList>
    </ContainerScroll>
  );
};

export default AssignmentStatsFilterPanel;
