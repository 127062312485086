import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AppHeaderData, { AppHeaderProps } from '../components/AppHeaderData';
import {
  actualityAnalyzeStatus,
  actualitySelectedDocument,
  actualityStatusUpdateTime,
} from '../feed/store/actuality.state';
import { layoutDocument } from '../layout/store/layout.state';
import {
  warehouseDefaultRoute,
  warehouseSelected,
} from '../store/warehouse.state';
import { getActualityAnalyzeJobStatusDescriptors } from './store/actuality.helper';

const FeedHeader: React.FC = () => {
  const { t } = useTranslation('feed');

  const wh = useRecoilValue(warehouseSelected);
  const document = useRecoilValue(layoutDocument);
  const actuality = useRecoilValue(actualitySelectedDocument);
  const warehouseUserRoute = useRecoilValue(warehouseDefaultRoute);
  const analyzeStatus = useRecoilValue(actualityAnalyzeStatus);
  const analyzeStatusTime = useRecoilValue(actualityStatusUpdateTime);

  if (!wh) return null;

  const statusMap = getActualityAnalyzeJobStatusDescriptors(t);
  const isLoading = analyzeStatus === 'progress';

  const headerProps: AppHeaderProps = {
    backPath:
      warehouseUserRoute === 'simulations'
        ? `/wh/i/${wh.id}`
        : `/wh/i/${wh.id}/simulations`,
    warehouseId: wh.id,
    breadcrumbItems: [
      {
        title: wh.title,
        href:
          warehouseUserRoute === 'simulations'
            ? `/wh/i/${wh.id}`
            : `/wh/i/${wh.id}/simulations`,
      },
    ],
  };

  if (document) {
    headerProps.breadcrumbItems.push({
      title: actuality?.title || t`Activity`,
      href: `/wh/i/${wh.id}/feed/${actuality?.id}`,
    });
  }

  return (
    <AppHeaderData
      {...headerProps}
      isLoading={isLoading}
      isLoadingLabel={isLoading ? t`Analysing...` : null}
    >
      {/* <Tag classType="border border-state-analyzed uppercase !text-xxs !px-2 !py-0.5">
        {statusMap[analyzeStatus]?.label}
      </Tag> */}
    </AppHeaderData>
  );
};

export default FeedHeader;
