import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ImportMapPanel } from '../panels/ImportMapPanel';
import SchemaHelper from '../SchemaHelper';
import { getLayoutSchema } from './schema/layoutSchema';

const ImportLayoutMapPanel: React.FC = () => {
  const { t } = useTranslation('importer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Map Fields`}
        subtitle={t`Layout import`}
        hasStepCounter={'4.'}
      />
      <ImportMapPanel>
        <SchemaHelper schema={getLayoutSchema(t)} />
      </ImportMapPanel>
    </PanelBody>
  );
};

export default ImportLayoutMapPanel;
