import { Field, ObjectType } from '@warebee/shared/util-backend-only-types';

@ObjectType()
export class ValueStats {
  @Field({ nullable: true })
  max?: number;

  @Field({ nullable: true })
  avg?: number;

  @Field({ nullable: true })
  cv?: number;
}

@ObjectType()
export class OrderSetItemDailyStats {
  @Field()
  totalDaysWithOrders: number;

  @Field(() => ValueStats)
  orderLineCount: ValueStats;
}

@ObjectType()
export class OrderSetItemWeeklyStats {
  @Field()
  totalWeeksWithOrders: number;

  @Field(() => ValueStats)
  orderLineCount: ValueStats;

  @Field(() => ValueStats)
  daysWithOrders: ValueStats;
}

@ObjectType()
export class OrderSetItemDetails {
  @Field(() => OrderSetItemDailyStats, { nullable: true })
  dailyStats?: OrderSetItemDailyStats;

  @Field(() => OrderSetItemWeeklyStats, { nullable: true })
  weeklyStats?: OrderSetItemWeeklyStats;
}
