import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { Stat } from '../../../components/stats/Stat';
import { resourcePolicyAgentById } from '../../store/resourcePolicy.state';
import { getWorkforceAggregationPeriodTitle } from '../../store/workforce.helper';
import {
  workforceAgentEventAggregation,
  workforceAgentForecastEnabled,
  workforceSelectedAgentId,
  workforceShowAdvancedDataTable,
  workforceShowAdvancedLabourEvents,
} from '../../store/workforce.state';
import { workforceAggregationTypes } from '../../store/workforce.types';
import ContainerChart from '../ContainerChart';
import WorkforceAgentTimeline from './WorkforceAgentTimeline';

export type WorkforceAgentStatsProps = {
  analyzeId: string;
};

const WorkforceAgentCharts: React.FC<WorkforceAgentStatsProps> = props => {
  const { t } = useTranslation('simulation');
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const agent = useRecoilValue(resourcePolicyAgentById(agentId));
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );

  const [showAdvancedCostStat, setShowAdvancedCostStat] =
    useState<boolean>(true);
  const [showAdvancedLabourEvents, setShowAdvancedLabourEvents] =
    useRecoilState(workforceShowAdvancedLabourEvents);
  const [aggregationType, setAggregationType] = useRecoilState(
    workforceAgentEventAggregation(agentId),
  );
  const [showAdvancedDataTable, setShowAdvancedDataTable] = useRecoilState(
    workforceShowAdvancedDataTable,
  );

  return (
    <div
      data-component="WorkforceAgentCharts"
      className={classNames(
        'mt-0.5',
        'flex flex-col w-full content-evenly',
        'flex-1',
        // 'grid grid-cols-1 gap-1',
        // isForecastSimulation ? 'lg:grid-cols-4' : 'lg:grid-cols-5',
        // isForecastSimulation ? 'lg:grid-cols-4' : 'lg:grid-cols-4',
      )}
    >
      {/* {showAdvancedCostStat && (
        <div className="overflow-hidden w-full">
          <WorkforceAgentTotals analyzeId={props.analyzeId} />
        </div>
      )} */}

      <div
        className={classNames(
          'flex flex-1 w-full flex-col',
          'bg-app-panel-dark/60',
          'col-span-4',
          // showAdvancedCostStat ? 'lg:col-span-4' : 'lg:col-span-4',
        )}
      >
        <Stat
          hasHelper
          isPreview
          title={t`Time Analysis`}
          switchUnitOfMeasure={
            <header
              className={classNames(
                'w-full flex flex-col md:flex-row',
                'items-center',
                // 'p-2 xl:p-4',
                'flex-wrap',
              )}
            >
              <div className="uppercase text-xs">{t`Time Analysis`}</div>
              <div
                className={classNames(
                  'flex-1 flex items-center pt-2 py-1 xl:p-0',
                  'lg:justify-end',
                )}
              >
                <ButtonSwitchMulti
                  disabled={!showAdvancedDataTable}
                  buttonType="minimal"
                  autoSize
                  className="mx-2"
                  selectedIndex={showAdvancedLabourEvents ? 0 : 1}
                  onClick={index => setShowAdvancedLabourEvents(index === 0)}
                  options={[{ label: t`Details` }, { label: t`Off` }]}
                />
                {/* <ButtonSwitchMulti
              buttonType="minimal"
              autoSize
              className="mx-2"
              selectedIndex={showAdvancedCostStat ? 0 : 1}
              onClick={index => setShowAdvancedCostStat(index === 0)}
              options={[{ label: t`Costs` }, { label: t`Off` }]}
            /> */}
                <ButtonSwitchMulti
                  buttonType="minimal"
                  autoSize
                  className="mx-2"
                  selectedIndex={showAdvancedDataTable ? 0 : 1}
                  onClick={index => setShowAdvancedDataTable(index === 0)}
                  options={[{ label: t`Table` }, { label: t`Off` }]}
                />
                <DropdownSelector
                  buttonTransparent
                  panelMode
                  className="rounded-full px-2"
                  classNameValue="text-xs"
                  value={aggregationType}
                  values={[...workforceAggregationTypes]}
                  renderValue={v => getWorkforceAggregationPeriodTitle(v, t)}
                  onChange={ts => setAggregationType(ts)}
                />
              </div>
            </header>
          }
        >
          <ContainerChart containerHeight={showAdvancedDataTable ? 440 : 640}>
            <WorkforceAgentTimeline
              analyzeId={props.analyzeId}
              showAdvancedLabourEvents={showAdvancedLabourEvents}
            />
          </ContainerChart>
        </Stat>
      </div>
    </div>
  );
};

export default WorkforceAgentCharts;
