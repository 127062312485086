import {
  Field,
  ID,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { OptimizationSwapRule } from './optimization-settings.model';

@ObjectType()
export class AssignmentSubsetSummary {
  @Field()
  locationCount: number;
  @Field()
  itemCount: number;
  @Field()
  assignmentItemCount: number;
  @Field()
  emptyLocationCount: number;
}

export enum CheckOptimizationSwapPolicyNoValidSwapsReason {
  EMPTY_SOURCE_SET = 'EMPTY_SOURCE_SET',
  EMPTY_DESTINATION_SET = 'EMPTY_DESTINATION_SET',
  NO_MATCH_BY_SWAP_RULES = 'NO_MATCH_BY_SWAP_RULES',
  NO_MATCH_BY_PICKING_POLICY = 'NO_MATCH_BY_PICKING_POLICY',
  CONSTRAINT_VIOLATION = 'CONSTRAINT_VIOLATION',
}

registerEnumType(CheckOptimizationSwapPolicyNoValidSwapsReason, {
  name: 'CheckOptimizationSwapPolicyNoValidSwapsReason',
});

@ObjectType()
export class CheckedOptimizationSwapRuleDetails {
  @Field(() => [CheckOptimizationSwapPolicyNoValidSwapsReason], {
    nullable: true,
  })
  noValidSwapsReasons?: CheckOptimizationSwapPolicyNoValidSwapsReason[];

  @Field(() => [ID], { nullable: true })
  violatedConstrains?: string[];
}

@ObjectType()
export class CheckedOptimizationSwapRule extends OptimizationSwapRule {
  @Field(() => AssignmentSubsetSummary)
  srcMatch: AssignmentSubsetSummary;

  @Field(() => AssignmentSubsetSummary)
  destMatch: AssignmentSubsetSummary;

  @Field()
  hasValidSwaps: boolean;

  @Field(() => CheckedOptimizationSwapRuleDetails, { nullable: true })
  details?: CheckedOptimizationSwapRuleDetails;
}

@ObjectType()
export class CheckedOptimizationSwapPolicy {
  @Field(() => [CheckedOptimizationSwapRule])
  rules: CheckedOptimizationSwapRule[];

  @Field()
  hasValidSwaps: boolean;
}
