import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import '../../assets/theme/css/Table.css';

export type TableProps = {
  children?: React.ReactNode;
  dataComponent?: string;
  className?: string;
  caption?: string;
  isSticky?: boolean;
  isStickyCol1?: boolean;
  isStickyCol2?: boolean;
  isStickyCol3?: boolean;
  isStickyCol4?: boolean;
  isStickyCol5?: boolean;
  isSortable?: boolean;
  isHoverable?: boolean;
  isScrollable?: boolean;
  isScrollableX?: boolean;
  hasBottomMargin?: boolean;
  hasFixedHeight?: boolean;
  classNameFooter?: string;
  footer?: React.ReactNode;
  viewAsLine?: boolean;
  viewAsTable?: boolean;
  flexHeight?: boolean;
  fixedWidth?: boolean;
  dragRef?: any;
};

export const Table = ({
  children,
  className,
  dataComponent,
  caption,
  isSticky,
  isStickyCol1,
  isStickyCol2,
  isStickyCol3,
  isStickyCol4,
  isStickyCol5,
  isSortable,
  isHoverable,
  isScrollable,
  isScrollableX,
  hasBottomMargin,
  hasFixedHeight,
  flexHeight,
  viewAsLine,
  viewAsTable,
  fixedWidth,
  dragRef,
  footer,
  classNameFooter,
}: TableProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="TableContainer"
      className={classNames(
        'relative flex flex-col',
        'w-full',
        { 'table-scrollable overflow-scroll': isScrollable },
        { 'table-scrollable overflow-x-scroll': isScrollableX },
        { 'h-fill': flexHeight },
      )}
      role={'table'}
      aria-labelledby="caption"
      tabIndex={0}
    >
      <table
        id={id}
        data-component={`Table${dataComponent ? '-' + dataComponent : ''}`}
        className={classNames(
          { 'table-fixed': fixedWidth },
          'isolate flex-1 text-start',
          'w-full',
          {
            'table-sortable': isSortable,
          },
          {
            'table-hoverable': isHoverable,
          },
          {
            'table-scrollable': isScrollable,
          },
          {
            'table-sticky': isSticky,
          },
          {
            col1: isStickyCol1,
          },
          {
            col2: isStickyCol2,
          },
          {
            col3: isStickyCol3,
          },
          {
            col4: isStickyCol4,
          },
          {
            col5: isStickyCol5,
          },
          {
            'table-view-line': viewAsLine,
          },
          {
            'table-view-table': viewAsTable,
          },
          {
            'mb-24': hasBottomMargin,
          },

          className,
        )}
        ref={dragRef}
      >
        {caption && <caption id="caption">{caption}</caption>}
        {children}
      </table>
      {footer && (
        <footer className={classNames(classNameFooter)}>{footer}</footer>
      )}
    </div>
  );
};
