import * as React from 'react';
const AisleOrderLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AisleOrderLeftIcon"
    {...props}
  >
    <rect
      width={13.79}
      height={72.7}
      x={31.28}
      y={50.09}
      strokeWidth={0}
      transform="translate(76.36 172.89) rotate(-180)"
    />
    <rect
      width={13.79}
      height={72.7}
      x={12.27}
      y={50.09}
      strokeWidth={0}
      transform="translate(38.33 172.89) rotate(-180)"
    />
    <rect
      width={13.79}
      height={72.7}
      x={107.34}
      y={50.09}
      strokeWidth={0}
      transform="translate(228.46 172.89) rotate(-180)"
    />
    <rect
      width={13.79}
      height={64.25}
      x={50.3}
      y={58.54}
      strokeWidth={0}
      transform="translate(114.38 181.34) rotate(-180)"
    />
    <rect
      width={13.79}
      height={55.8}
      x={88.32}
      y={67}
      strokeWidth={0}
      transform="translate(190.44 189.79) rotate(-180)"
    />
    <rect
      width={13.79}
      height={72.7}
      x={69.31}
      y={50.09}
      strokeWidth={0}
      transform="translate(152.41 172.89) rotate(-180)"
    />
    <rect width={4.44} height={120} x={4.55} y={4} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M17.87,26.42c-.09-.09-.18-.2-.25-.31-.03-.05-.06-.11-.09-.16-.03-.06-.07-.12-.1-.19-.03-.07-.04-.14-.06-.21-.02-.06-.04-.11-.05-.17-.05-.26-.05-.53,0-.78.01-.06.03-.11.05-.17.02-.07.04-.14.06-.2.03-.07.06-.13.1-.19.03-.05.05-.11.09-.16.07-.11.16-.21.25-.31l16-16c.78-.78,2.05-.78,2.83,0,.39.39.59.9.59,1.41,0,.51-.2,1.02-.59,1.41l-12.59,12.59h30.17c1.1,0,2,.9,2,2s-.9,2-2,2h-30.17s12.59,12.59,12.59,12.59c.39.39.59.9.59,1.41s-.2,1.02-.59,1.41c-.78.78-2.05.78-2.83,0l-16-16Z"
    />
  </svg>
);
export default AisleOrderLeftIcon;
