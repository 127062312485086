import {
  Field,
  GraphQLLocalDateTime,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';

@InputAndObjectType()
export class LocalDateRangeFilter {
  @Field(() => GraphQLLocalDateTime, { nullable: true })
  from?: string;

  @Field(() => GraphQLLocalDateTime, { nullable: true })
  to?: string;

  @Field({ nullable: true })
  excludeFrom?: boolean;

  @Field({ nullable: true })
  excludeTo?: boolean;

  @Field({ nullable: true })
  includeNull?: boolean;
}

@InputAndObjectType()
export class OrderSetFilter {
  @Field(() => LocalDateRangeFilter, { nullable: true })
  orderDate?: LocalDateRangeFilter;
}
