import { confirmSignIn } from 'aws-amplify/auth';
import classNames from 'classnames';
import _ from 'lodash';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormErrorField } from '../components/FormError';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import InputText from '../components/inputs/InputText';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import Callout from '../helpContext/Callout';
import CodeSnippet from '../helpContext/CodeSnippet';
import { useErrorTranslator } from './ErrorTranslator';
import TOTPAppLinks from './TOTPAppLinks';

export type LoginTOTPSetupProps = {
  email: string;
  mfaSetupCode: string;
};

const LoginTOTPSetup: React.FC<LoginTOTPSetupProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [totpToken, setTotpToken] = useState('');
  const [error, setError] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { translateError } = useErrorTranslator();
  const [showManualCode, setShowManualCode] = useState<boolean>(false);

  const issuer = 'WareBee';
  const str = `otpauth://totp/${props.email}?secret=${props.mfaSetupCode}&issuer=${issuer}`;

  // useEffect(() => {
  //   async function requestTOTPCode() {
  //     setIsLoading(true);
  //     try {
  //       const code = await getTOTPCode();
  //       setMfaSetupCode(code);
  //     } catch (ex) {
  //       const errorMessage = translateError(ex);
  //       setError(errorMessage);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  //   requestTOTPCode();
  // }, [props.email]);

  async function confirmTOTPCode() {
    setIsLoading(true);
    setError(null);
    try {
      await confirmSignIn({
        challengeResponse: totpToken,
      });
      navigate('/');
    } catch (ex) {
      const errorMessage = translateError(ex);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ScreenTitle
        isSticky
        subtitle={t`Security`}
        title={t`Setup multi-factor authentication`}
      />

      <Callout type="suggestion" className="border-none">
        {t`Please setup Two-Factor Authentication, as required by your organisation.`}
      </Callout>

      <section className="mb-4 w-full p-6">
        <div className="flex w-full flex-col items-center justify-center">
          <Callout panelMode type="counter" counter="1">
            <div className="mb-2 flex flex-col">
              {t`To setup your multi-factor authentication, Scan this QR code below, with your authentication app:`}
              <p className="text-menu-100">
                <span className="bold italic">{t`Use Apps like:`}</span>
                <TOTPAppLinks />
              </p>
            </div>

            <div className="mb-4 mt-4 flex flex-col items-center justify-center">
              <div className="shadow-app-200xl flex h-full flex-col items-center justify-center rounded bg-white p-3">
                <QRCodeCanvas
                  className="h-full w-full flex-1"
                  value={str}
                  size={160}
                />
              </div>
              <div
                className="text-menu-text/60 mt-4 cursor-pointer text-xs hover:underline"
                onClick={() => {
                  setShowManualCode(!showManualCode);
                }}
              >
                {showManualCode
                  ? t`Hide manual code`
                  : t`Having trouble scanning the code?`}
              </div>
            </div>
            {showManualCode && (
              <div className="mb-4 w-full p-4">
                <p className="text-menu mb-2 mt-3">{t`If you are unable to scan the QR code image, enter the following code in your authentication app.`}</p>
                <CodeSnippet>{props.mfaSetupCode}</CodeSnippet>
              </div>
            )}
          </Callout>
        </div>
        <fieldset>
          <Callout panelMode type="counter" counter="2" className="p-4">
            <div className="mb-4">
              {t`Enter the verification code from your security app to confirm setup.`}
            </div>

            <div className="mt-2 p-4 xl:px-10">
              <InputText
                value={totpToken}
                label={t`Verify Token`}
                placeholder="123456"
                name="token"
                onChange={v => setTotpToken(v)}
              />
              {!_.isEmpty(error) && <FormErrorField message={error} />}

              <Button
                className="backdrop-saturate-110 mt-2 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
                buttonSize={`lg`}
                label={t`Activate MFA`}
                full
                buttonType={`primary`}
                hasIconAfter
                isLoading={isLoading}
                buttonIcon={
                  <Icon.CircleOk
                    className={classNames('mx-1 h-8 w-8 fill-current')}
                  />
                }
                onPress={() => confirmTOTPCode()}
              />
            </div>

            {/* <span className='text-xs'>{`We're asking for this code based on your two-factor authentication.`}</span> */}
          </Callout>
        </fieldset>
      </section>
    </>
  );
};
export default LoginTOTPSetup;
