import classNames from 'classnames';
import React, { PropsWithChildren, useId } from 'react';

export type PolicyFilterTagProps = PropsWithChildren & {
  className?: string;
};

export const PolicyFilterTag: React.FC<PolicyFilterTagProps> = ({
  children,
  className,
}) => {
  const id = useId();
  return (
    <div
      key={id}
      id={id}
      data-component="PolicyFilterTag"
      className={classNames(
        'group/PolicyFilterTag',
        'relative',
        'flex flex-col flex-wrap items-center',
        'w-full',
        // 'p-1',
        // 'text-menu-text',
        'text-xs font-medium lg:text-sm',
        'transition duration-500',
        // 'space-y-3',
        className,
      )}
    >
      {children}
    </div>
  );
};
