import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { PicklistEventDetails } from '../../simulation/panels/route/PicklistEventDetails';
import {
  actualityAgentsAnalyzedJobEvents,
  actualityAgentsSimulatedJobEvents,
} from '../store/actuality.jobs.state';
import FeedActivityPicklistLineDetails from './FeedActivityPicklistLineDetails';

export type ActualityAnalyzedJobEventsProps = {
  jobId: string;
};

const ActualityAnalyzedJobEvents: React.FC<
  ActualityAnalyzedJobEventsProps
> = props => {
  const events = useRecoilValue(actualityAgentsAnalyzedJobEvents(props.jobId));
  const simulationEvents = useRecoilValue(
    actualityAgentsSimulatedJobEvents(props.jobId),
  );
  return (
    <>
      {_.map(events, (event, index) => {
        return (
          <FeedActivityPicklistLineDetails
            key={`feed-picklist-line-${event.eventId}`}
            index={index}
            line={event}
            prevLine={index > 0 ? events[index - 1] : null}
          >
            {_(simulationEvents)
              .filter(e => e['sourceEventId'] === event.eventId)
              .map(simulatedEvent => {
                return (
                  <PicklistEventDetails
                    key={`simulated-events-${event.eventId}-${simulatedEvent.sequenceId}`}
                    index={0}
                    event={simulatedEvent}
                    onClick={_.noop} //event => zoomToRoutePart(event)
                    onHover={
                      _.noop
                      // event =>
                      // setRoutePart(
                      //   event ? createRouteForEvent(props.picklistSummary, event) : null,
                      // )
                    }
                  />
                );
              })
              .value()}
          </FeedActivityPicklistLineDetails>
        );
      })}
    </>
  );
};

export default ActualityAnalyzedJobEvents;
