import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../../i18n';
import * as Icon from '../icons';
import LinkTraced from './LinkTraced';

export type NavBackProps = {
  className?: string;
  children?: React.ReactNode;
  message?: string;
  label?: string;
  action_path?: string;
  isHeaderBack?: boolean;
  isHeaderHome?: boolean;
  isHeaderWarehouse?: boolean;
  isTitle?: boolean;
};

export const NavBack = ({
  className,
  message,
  label,
  action_path,
  isHeaderBack,
  isHeaderHome,
  isHeaderWarehouse,
  isTitle,
}: NavBackProps) => {
  const [id] = useState<string>(_.uniqueId());
  // const message = 'Back';

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {isHeaderWarehouse && (
        <LinkTraced
          id={id}
          to={isHeaderHome ? '/' : action_path}
          className={classNames(
            'group flex h-full items-center justify-center',
            {
              'hover:bg-menu-hover hover:text-menu-active-text text-menu-active group-hover:text-menu-text mx-1':
                isHeaderHome,
            },
            className,
          )}
        >
          <div>
            <Icon.WarehouseBoxLeftArrow
              className={classNames('h-10 w-10 fill-current lg:h-12 lg:w-12')}
            />
            {label && (
              <span className={classNames('flex-1 px-4 text-xs uppercase')}>
                {label}
              </span>
            )}
          </div>

          {message && (
            <span className={classNames('flex-1 px-4 text-xl')}>{message}</span>
          )}
        </LinkTraced>
      )}

      {isHeaderHome && (
        <LinkTraced
          id={id}
          to={isHeaderHome ? '/' : action_path}
          className={classNames(
            'flex items-center justify-center',
            'h-full',
            'group cursor-pointer',
            'w-full',
            'text-menu-active hover:bg-menu-hover hover:text-menu-active-text group-hover:text-menu-active-text',
            { 'border-app-header-text/20 ltr:border-r rtl:border-l': isTitle },
            className,
          )}
        >
          <label
            data-component="isHeaderBackLabel"
            className={classNames('cursor-pointer', 'flex items-center', {
              'flex flex-col items-center': label,
            })}
          >
            {i18n.dir(i18n.language) === 'ltr' ? (
              <Icon.ArrowLeftTop
                className={classNames(
                  'fill-current',
                  label ? 'h-10 w-10' : 'h-10 w-8 lg:h-12 lg:w-12',
                )}
              />
            ) : (
              <Icon.ArrowRightTop
                className={classNames(
                  'fill-current',
                  label ? 'h-10 w-10' : 'h-10 w-8 lg:h-12 lg:w-12',
                )}
              />
            )}

            {label && (
              <span className={classNames('flex-1 px-4 text-xs uppercase')}>
                {label}
              </span>
            )}
          </label>

          {message && (
            <span className={classNames('flex-1 px-4 text-xl')}>{message}</span>
          )}
        </LinkTraced>
      )}

      {isHeaderBack && (
        <button
          data-component="isHeaderBack"
          id={id}
          className={classNames(
            'flex items-center justify-center',
            'h-full',
            'group cursor-pointer',
            'w-sidebar-minimised-xs sm:w-sidebar-minimised-sm md:w-sidebar-minimised-md lg:w-sidebar-minimised',
            'text-menu-active hover:bg-menu-hover hover:text-menu-active-text group-hover:text-menu-active-text',
            { 'border-app-header-text/20 ltr:border-r rtl:border-l': isTitle },
            className,
          )}
          onClick={goBack}
        >
          {isHeaderBack && (
            <label
              data-component="isHeaderBackLabel"
              className={classNames('cursor-pointer', 'flex items-center', {
                'flex flex-col items-center': label,
              })}
            >
              {i18n.dir(i18n.language) === 'ltr' ? (
                <Icon.ArrowLeftTop
                  className={classNames(
                    'fill-current',
                    label ? 'h-10 w-10' : 'h-10 w-8 lg:h-12 lg:w-12',
                  )}
                />
              ) : (
                <Icon.ArrowRightTop
                  className={classNames(
                    'fill-current',
                    label ? 'h-10 w-10' : 'h-10 w-8 lg:h-12 lg:w-12',
                  )}
                />
              )}

              {label && (
                <span className={classNames('flex-1 px-4 text-xs uppercase')}>
                  {label}
                </span>
              )}
            </label>
          )}

          {message && (
            <span className={classNames('flex-1 px-4 text-xl')}>{message}</span>
          )}
        </button>
      )}
    </>
  );
};
