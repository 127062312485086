import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import SimulationIssuesPanel from '../panels/preAnalyze/SimulationIssuesPanel';

export default () => {
  const { t } = useTranslation('simulation');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-issues',
    title: t('Data Issues'),
    loadingMessage: t('Analysing Dataset for Issues'),
    size: 'sm',
    hasIssue: true,
  };

  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      // hasIssue={true}
    >
      <SimulationIssuesPanel />
    </SidebarContainer>
  );
};
