import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../../common/constants';
import { formatInteger } from '../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import ErrorIndicator from '../../../components/ErrorIndicator';
import * as Icon from '../../../components/icons';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Stat } from '../../../components/stats/Stat';
import { StatGroup } from '../../../components/stats/StatGroup';
import useLoadSizeComplianceMeta from '../../hooks/useLoadSizeComplianceMeta';
import { simulationCurrent } from '../../store/simulation.state';
import {
  sizeComplianceMeta,
  sizeComplianceMetaLoadStatus,
} from '../../store/sizeCompliance.state';

const SizeCompliancePanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const simulationId = useRecoilValue(simulationCurrent)?.id;
  const complianceMeta = useRecoilValue(sizeComplianceMeta);
  const complianceMetaLoadStatus = useRecoilValue(sizeComplianceMetaLoadStatus);
  const [loadMeta, cancelLoadMeta] = useLoadSizeComplianceMeta();

  useEffect(() => {
    if (complianceMetaLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    // compliance not triggered
    if (_.isNil(complianceMeta?.status)) {
      loadMeta({
        simulationId: simulationId,
      });
    }
    if (
      complianceMeta?.status === BatchJobStatus.CREATED ||
      complianceMeta?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadMeta({
          simulationId: simulationId,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadMeta();
    };
  }, [complianceMeta]);

  const hasError =
    complianceMetaLoadStatus === AsyncLoadStatus.Error ||
    complianceMeta?.status === BatchJobStatus.FAILED;

  const isLoading = complianceMeta?.status !== BatchJobStatus.READY;

  const summaryItems = complianceMeta?.summary?.items;
  const nonCompliantLocCount = _.sumBy(
    summaryItems,
    item => item.nonCompliantCount,
  );

  const compliantLocCount = _.sumBy(summaryItems, item => item.compliantCount);

  const unknownLocCount = _.sumBy(summaryItems, item => item.unknownCount);
  const emptyLocCount = _.sumBy(summaryItems, item => item.unassignedCount);

  const totalLocCount = _.sumBy(summaryItems, item => item.totalCount);

  const locShare = formatter.formatShare(
    totalLocCount > 0 ? nonCompliantLocCount / totalLocCount : 0,
  );

  return (
    <>
      <ScreenTitle
        subtitle={t`Analyse — Compliance`}
        title={t`Size Compliance`}
        // isSticky // Disabled so it's won't interfere with the Stat sticky-ness.
        helpNavTo={'simulation/compliance/simulation-compliance-size'}
        icon={Icon.PolicyComplianceSize}
      />
      {/* <HelperContainer
        hasPadding
        collapsible
        id={'helper-simulation-compliance-size'}
        hideMessage={t`Hide helper`}
        showMessage={t`Show more`}
        showIcon={Icon.CircleHelp}
        hideIcon={Icon.ArrowRight}
        message={t`Location Size compliance audit`}
      >
        {t`What can you do?`}
        <ul className="mt-2 divide-y divide-menu-500">
          <li className="py-1 ltr:pl-4 rtl:pr-4">{t`Identify locations that have potential volume or dimensions issues`}</li>
          <li className="py-1 ltr:pl-4 rtl:pr-4">{t`Locate items that are oversize and occupy additional locations`}</li>
        </ul>
        <Callout panelMode type="suggestion">
          {t`Click on the Stat card item to see detailed table view`}
        </Callout>
      </HelperContainer> */}

      {hasError && (
        <ErrorIndicator message={t`Cannot show size compliance issues`} />
      )}

      {isLoading && !hasError && (
        <LoadingIndicator
          message={t`Loading size compliance data`}
          selfCenter
        />
      )}

      {!isLoading && !hasError && (
        <StatGroup
          title={t`Locations`}
          subTitle={t`Size Compliance`}
          helpNavTo={'simulation/compliance/simulation-compliance-size'}
        >
          <Stat
            isSectionable
            toggleTable
            //isActionable
            isPreview
            title={t`Locations (Non-Compliant)`}
            value={formatInteger(nonCompliantLocCount)}
            valueTotal={formatInteger(totalLocCount)}
            valuePercent={locShare}
            //isSelectable={true}
            // isSelected={showLocationsTable}
            // onClick={() => {
            //   setShowLocationsTable(!showLocationsTable);
            // }}
          />
          <Stat
            isSectionable
            hasHelper
            isPreview
            title={t`Compliant locations`}
            value={formatInteger(compliantLocCount)}
            valueTotal={formatInteger(totalLocCount)}
            isFullWidth
            inPanelMode
            // isLoading={props.isLoading}
            // hasLoadMore
            // value={'Sorted By Distance'}
            // hasListDecimals
          >
            {/* <StatListHeader
            hasSearch
            title={t`Location`}
            value={'Level'}
            // searchValue={localState.searchValues?.['orderId']}
            // onSearch={v => onSearch('orderId', v)}
          />

          <InboxZero
            selfCenter
            message={t`No Non-Compliant Locations found`}
            // className={'min-h-1/2'}
          /> */}
          </Stat>
          <Stat
            isSectionable
            hasHelper
            isPreview
            title={t`Unknown locations`}
            value={formatInteger(unknownLocCount)}
            valueTotal={formatInteger(totalLocCount)}
            // counter={
            //   formatInteger(unknownLocCount) +
            //   ` of ` +
            //   formatInteger(totalLocCount)
            // }
            isFullWidth
            inPanelMode
            hasListDecimals
          />
          <Stat
            isSectionable
            hasHelper
            isPreview
            title={t`Unassigned (Empty) locations`}
            value={formatInteger(emptyLocCount)}
            valueTotal={formatInteger(totalLocCount)}
            // counter={
            //   formatInteger(emptyLocCount) +
            //   ` of ` +
            //   formatInteger(totalLocCount)
            // }
            isFullWidth
            inPanelMode
            hasListDecimals
          />
        </StatGroup>
      )}
    </>
  );
};

export default SizeCompliancePanel;
