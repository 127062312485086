import { FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateOrderSetDocument,
  UpdateOrderSetInput,
  UpdateOrderSetMutation,
  UpdateOrderSetMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { errorAppender } from '../../store/error.state';
import { orderSetDocumentSaveStatus } from '../store/orderSet.state';

function useUpdateOrderSet() {
  const client = useApolloClient();
  const { t } = useTranslation('dataset');

  const beforeUpdate = useRecoilCallback(({ set }) => async () => {
    set(orderSetDocumentSaveStatus, AsyncLoadStatus.Loading);
  });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (patch: UpdateOrderSetInput) => {
        let response: FetchResult<UpdateOrderSetMutation>;
        try {
          response = await client.mutate<
            UpdateOrderSetMutation,
            UpdateOrderSetMutationVariables
          >({
            mutation: UpdateOrderSetDocument,
            variables: {
              orderSetInput: {
                ...cloneWithoutTypename(patch),
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Cannot save Order set`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(orderSetDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Cannot save Order set`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(orderSetDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }
        set(orderSetDocumentSaveStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (patch: UpdateOrderSetInput) => {
    await beforeUpdate();
    await callUpdate(patch);
  };
}
export default useUpdateOrderSet;
