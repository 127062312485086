import * as React from 'react';
const SimulationsArrowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationsArrowIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M113.51,71.04l12.37-7.07-12.37-7.02-5.12,2.91-3.83-2.15,5.2-2.97-12.37-7.02-5.28,3-3.78-2.12,5.31-3.04-12.37-7.02-5.4,3.06-4.1-2.3,5.21-2.98-12.37-7.02-5.29,3-3.78-2.12,5.32-3.04-12.37-7.02-5.4,3.07-3.83-2.15,5.48-3.13-12.37-7.02-5.57,3.16-3.78-2.12,5.59-3.2L16.25,1.69,3.88,8.71l12.37,7.07,2.62-1.5,1.87,3.19-.74.42,1.48.84,23.56,40.17-7.93,4.51h-9.46l-11.39-6.47-12.37,7.02,12.37,7.07,11.42-6.52h9.4l7.95,4.54-23.5,40.08-1.51.86.76.43-1.91,3.26-2.6-1.47-12.37,7.02,12.37,7.07,12.37-7.07-5.62-3.19,3.83-2.15,5.55,3.17,12.37-7.07-5.46-3.1,3.77-2.12,5.44,3.11,12.37-7.07-5.35-3.04,3.82-2.15,5.28,3.01,12.37-7.07-5.19-2.95,4.05-2.27,5.43,3.1,12.37-7.07-5.34-3.03,3.82-2.15,5.27,3.01,12.37-7.07-5.18-2.94,3.77-2.12,5.16,2.95ZM107.27,60.5l-5.15,2.92h-42.24l-1.3-.74,30.44-5.66,8.37,4.78,6.06-3.46,3.83,2.15ZM48.06,57.2l-1.02.58-10.76-30.55,11.69,6.68.09,23.29ZM48.08,70.81l-.04,23.25-11.63,6.6,10.61-30.46,1.06.61ZM90.99,51.35l-5.97,3.39,2.52,1.44-30.45,5.66-1.84-1.04,20.08-11.56,5.94,3.39,5.94-3.4,3.78,2.12ZM74.76,42.23l-5.86,3.33,5.33,3.04-20.09,11.56-2.05-1.16,9.78-17.18,2.74,1.56,6.05-3.46,4.1,2.3ZM58.2,32.93l-5.96,3.39,8.68,4.96-9.78,17.18-1.98-1.12-.09-23.46,5.36-3.06,3.78,2.12ZM41.97,23.81l-5.75,3.26-1.27-3.59,3.19-1.82,3.83,2.15ZM19.82,13.74l2.09-1.19,3.78,2.12-3.99,2.27-1.87-3.19ZM23.4,19.84l8.97,5.13,1.62-.92,12.08,34.29-.07.04L23.4,19.84ZM45.97,69.61l.14.08-11.95,34.31-1.79-1.01-8.95,5.08,22.55-38.45ZM21.88,115.41l-2.08-1.18,1.91-3.25,4,2.28-3.83,2.15ZM38.16,106.27l-3.1-1.76,1.25-3.58,5.63,3.22-3.78,2.12ZM54.4,97.15l-5.27-2.99.05-23.51,2.06-1.18,9.87,17.07-8.86,5.03,5.98,3.42-3.83,2.15ZM70.68,88l-6.07-3.45-2.62,1.49-9.87-17.07,2.05-1.17,20.08,11.56-5.34,3.04,5.82,3.33-4.05,2.28ZM87.18,78.73l-5.91-3.36-5.92,3.36-20.09-11.56,1.84-1.05,30.37,5.64-2.46,1.4,5.99,3.42-3.83,2.15ZM103.47,69.58l-6.07-3.45-8.43,4.79-30.38-5.65,1.32-.75h42.18l5.15,2.94-3.78,2.12Z"
    />
  </svg>
);
export default SimulationsArrowIcon;
