import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import { converterSelectedFloor } from '../store/converter.area.state';
import { converterSelectedPortal } from '../store/converter.layout.state';
import { converterLayoutBuilder } from '../store/converter.state';

const ConverterPortalPanel: React.FC = () => {
  const { t } = useTranslation('designer');

  const floor = useRecoilValue(converterSelectedFloor);
  const [layoutBuilder, setLayoutBuilder] = useRecoilState(
    converterLayoutBuilder,
  );
  const portal = useRecoilValue(converterSelectedPortal);

  const isEntryPoint = _.isEqual(layoutBuilder.entryPoints?.[floor], portal);
  return (
    <div
      data-component="ConverterPortalPanel"
      className="flex flex-col flex-1 overflow-hidden"
    >
      <ContainerScroll>
        <WarehouseNavigator>
          <WarehouseNavigatorItem
            active
            name={t`Portal`}
            value={portal.Id1 + `—` + portal.Id2}
          />
        </WarehouseNavigator>

        <InputCheckbox
          className="ltr:pl-2 rtl:pr-2"
          isSelected={isEntryPoint}
          onChange={v =>
            setLayoutBuilder({
              ...layoutBuilder,
              entryPoints: {
                ...layoutBuilder.entryPoints,
                [floor]: v ? portal : null,
              },
            })
          }
        >
          {t`Is Starting point`}
        </InputCheckbox>
      </ContainerScroll>
    </div>
  );
};

export default ConverterPortalPanel;
