import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ContainerAppProps = {
  children?: React.ReactNode;
  className?: string;
};

const rtlLanguages = ['he', 'he-IL'];
// const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'ps', 'sd', 'dv', 'ku'];

const isRTL = languageCode => {
  return rtlLanguages.includes(languageCode);
};

export const ContainerApp = ({ children, className }: ContainerAppProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <main
      dir={'ltr'}
      id={id}
      data-component="ContainerApp"
      className={classNames('flex flex-col', className)}
    >
      {children}
    </main>
  );
};
