import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../components/actions/ButtonSwitchMulti';
import { ContainerMap } from '../../components/layout/ContainerMap';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import StageContainer from '../../layout/stage/StageContainer';
import JobsTimelineLayer from '../../layout/viewer/layers/JobsTimelineLayer';
import TimelineTooltip from '../components/TimelineTooltip';
import {
  actualityTimelineFilterPerformance,
  actualityTimelineFilterType,
} from '../store/actuality.state';
import {
  timelineFilterByPerformance,
  timelineFilterByType,
} from '../store/actuality.types';

const ViewerJobsTimeline: React.FC = () => {
  const { t } = useTranslation('feed');

  const [filterByPerformance, setFilterByPerformance] = useRecoilState(
    actualityTimelineFilterPerformance,
  );

  const [filteByType, setFilterByType] = useRecoilState(
    actualityTimelineFilterType,
  );

  const filterOptionsPerformance: ButtonSwitchMultiOption[] = [
    {
      label: t`All`,
    },
    {
      label: t`Underperform - All`,
    },
    {
      label: t`Underperform > 20%`,
    },
  ];

  const filterOptionsByType: ButtonSwitchMultiOption[] = [
    {
      label: t`By User`,
    },
    {
      label: t`By Proccess`,
    },
    {
      label: t`By Performance`,
    },
  ];

  return (
    <>
      <ActionBar className={classNames('p-2')} sticky>
        <ButtonSwitchMulti
          className="mx-2"
          autoSize
          selectedIndex={_.indexOf(timelineFilterByType, filteByType)}
          onClick={index => {
            setFilterByType(timelineFilterByType[index]);
          }}
          options={filterOptionsByType}
          buttonType="secondary"
        />
        <Spacer flexspace />
        <ButtonSwitchMulti
          className="mx-2"
          autoSize
          selectedIndex={_.indexOf(
            timelineFilterByPerformance,
            filterByPerformance,
          )}
          onClick={index => {
            setFilterByPerformance(timelineFilterByPerformance[index]);
          }}
          options={filterOptionsPerformance}
          buttonType="secondary"
        />
      </ActionBar>
      <ContainerMap>
        <StageContainer type="workforce-agent-schedule" darkBg={true}>
          <JobsTimelineLayer stageId="workforce-agent-schedule" />
        </StageContainer>
        <TimelineTooltip stageId="workforce-agent-schedule" />
      </ContainerMap>
    </>
  );
};

export default ViewerJobsTimeline;
