import { AssignmentPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { DataPageParams } from '../common/types';
import { getItemFilterMatchings } from '../simulation/store/assignmentPolicy.helper';
import { AssignmentPolicySelectedIdentity } from '../simulation/store/assignmentPolicy.types';
import useLoadSimulationProductsByRule from './useLoadSimulationProductsByRule';

export type ProductsByRuleParams = {
  selectedDetails: AssignmentPolicySelectedIdentity;
  policy: AssignmentPolicyFragment;
  page?: DataPageParams;
};

function useLoadAssignmentPolicyProductsByRule() {
  const [loadBase, cancel] = useLoadSimulationProductsByRule();

  const load = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ProductsByRuleParams) => {
        const { policy, selectedDetails } = params;

        // const ruleIndex = _.findIndex(
        //   policy.rules,
        //   rule => rule.id === selectedDetails.ruleId,
        // );
        // const rulesToExclude = _.map(
        //   selectedDetails.ruleId === AP_FALLBACK_RULE_ID ? policy.rules : [],
        //   getAssignmentPolicyRuleInput,
        // );

        // const excludeMatching = _.flatMap(
        //   rulesToExclude,
        //   rule => rule.productsMatch,
        // );

        // const includeMatching =
        //   selectedDetails.ruleId === AP_FALLBACK_RULE_ID
        //     ? null
        //     : getAssignmentPolicyRuleInput(policy.rules[ruleIndex])
        //         ?.productsMatch;

        await loadBase({
          ...getItemFilterMatchings(policy, selectedDetails),
          page: params.page,
        });
      },
  );

  return [load, cancel] as const;
}
export default useLoadAssignmentPolicyProductsByRule;
