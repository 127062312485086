import {
  MeasurementSystem,
  useDemoWarehousesQuery,
  WarehouseMetaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsAgent } from '../AnalyticTracker';
import {
  PRIVACY_POLICY_VERSION,
  TERMS_POLICY_VERSION,
} from '../common/constants';
import ErrorIndicator from '../components/ErrorIndicator';
import { HelperMessage } from '../components/HelperMessage';
import * as Icon from '../components/icons';
import LoadingIndicator from '../components/LoadingIndicator';
import { ActionBar } from '../components/nav/ActionBar';
import { ItemListCard } from '../dashboard/containers/ItemListCard';
import Callout from '../helpContext/Callout';
import useAssignDemoWarehouse from '../warehouse/hooks/useAssignDemoWarehouse';
import useUpdateUserSettings from '../warehouse/hooks/useUpdateUserSettings';

export type WarehouseDemoCardProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  truncate?: boolean;
};

export const WarehouseDemoCard = ({
  children,
  title,
  className,
  truncate,
}: WarehouseDemoCardProps) => {
  const { t } = useTranslation('app');

  const [id] = useState<string>(_.uniqueId());
  const { loading, error, data } = useDemoWarehousesQuery();
  const assignDemo = useAssignDemoWarehouse();
  const configure = useUpdateUserSettings();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingIndicator message={t`Loading`} />;
  }

  if (error) {
    return (
      <ErrorIndicator
        selfCenter
        message={t`Apologies, we couldn't load your request, please re-try.`}
      />
    );
  }

  async function completeConfiguration(whId: string) {
    await assignDemo(whId, true);
    await configure({
      configured: true,
      consents: {
        privacyPolicy: {
          version: PRIVACY_POLICY_VERSION,
        },
        termsPolicy: {
          version: TERMS_POLICY_VERSION,
        },
      },
    });

    navigate(whId ? `/wh/i/${whId}` : '/wh/new');
  }

  const demoList = data?.demoWarehouses;
  const hasDemoWh = !_.isEmpty(demoList);

  function drawDemoButton(
    wh: Pick<WarehouseMetaFragment, 'id' | 'title' | 'measurementSystem'>,
  ) {
    const systemPrefix =
      wh.measurementSystem === MeasurementSystem.IMPERIAL
        ? t`Imperial System`
        : t`Metric System`;

    const label = t(`{{system}} {{title}}`, {
      system: systemPrefix,
      title: wh.title,
    });

    const systemID =
      wh.measurementSystem === MeasurementSystem.IMPERIAL ? t`A` : t`B`;

    return (
      <ActionBar
        data-component={`DemoButton-${label}`}
        transparent
        className={classNames('relative flex-1 cursor-pointer')}
      >
        <div
          onClick={() => {
            setIsLoading(true);
            completeConfiguration(wh.id);
            try {
              analyticsAgent?.track(`Demo (Helper): Start Trial`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
          className={classNames(
            'items-end',
            'flex flex-1 flex-col',
            'w-full',
            'h-trialcard',
            'p-4 pt-2',
            'rounded',
            'bg-menu-active hover:bg-menu-active/75 text-menu-active-text',
            'shadow hover:shadow-xl',
            'overflow-hidden',
          )}
        >
          <div className={classNames('w-full', 'flex flex-1', 'justify-end')}>
            <Icon.WarehouseDemo
              className={classNames('w-auto h-16 xl:h-20 fill-current')}
            />
          </div>

          <div className={classNames('flex items-center flex-1 w-full mt-4')}>
            <Icon.CircleArrowLeft
              className={classNames('w-12 h-12 fill-current')}
            />
            <div className={classNames('flex flex-col flex-1 text-right')}>
              <div
                className={classNames('mb-1 text-base xl:text-lg uppercase')}
              >
                {t`Demo Data`}
              </div>
              <div
                data-component="title"
                className={classNames(
                  'text-3xl md:text-4xl lg:text-5xl xl:text-5xl',
                )}
              >{t`Start Demo`}</div>
            </div>
          </div>
        </div>
        {/* <div
          onClick={() => {
            setIsLoading(true);
            completeConfiguration(wh.id);
            try {
              analyticsAgent?.track(`Demo (Helper): Demo ${wh.title}`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
          className={classNames(
            'items-start',
            'flex flex-col flex-1',
            'h-40',
            'p-3 pt-0',
            'rounded',
            'bg-menu-active hover:bg-menu-active/80 text-menu-active-text',
          )}
        >
          <Icon.WarehouseDemo
            className={classNames('w-20 h-20 fill-current')}
          />
          <div className={classNames('flex-col flex-1')}>
            <div className={classNames('mb-0.5 text-base uppercase')}>
              {systemPrefix}
            </div>
            <div data-component="title" className={classNames('text-4xl')}>
              {wh.title}
            </div>
          </div>
          <Icon.CircleArrowRight
            className={classNames('w-12 h-12 fill-current')}
          />
        </div> */}
      </ActionBar>
    );
  }

  return (
    <ItemListCard fullWidth isDemo label={`WarehouseDemoCard`}>
      <HelperMessage hasPadding>
        <div className="text-4xl">{t`Getting started`}</div>
        {/* 
        <div className="text-xl">
          {t`Demo warehouse, where you can test our app and see its features.`}
        </div> */}
      </HelperMessage>

      <div className={classNames('flex flex-col md:flex-row items-end')}>
        {hasDemoWh && (
          <div
            data-component="rightSide"
            className={classNames('flex-1 w-full')}
          >
            <HelperMessage hasPadding className={classNames('!py-0')}>
              <div
                className={classNames('text-lg xl:text-xl')}
              >{t`Please select demo warehouse`}</div>
            </HelperMessage>
            <ActionBar className={classNames('p-4 mb-4')} transparent>
              <div className={classNames('flex flex-1 space-x-4')}>
                {demoList.map(wh => (
                  <React.Fragment key={wh.warehouse.id}>
                    {drawDemoButton(wh.warehouse)}
                  </React.Fragment>
                ))}
              </div>
            </ActionBar>
          </div>
        )}
        <div
          className={classNames(
            'flex items-center justify-center self-center',
            'rounded-full bg-menu-active/50 text-menu-active-text',
            'w-10 h-10',
            'text-lg',
            'my-8 xl:my-4',
            'uppercase',
          )}
        >{t`or`}</div>
        <div data-component="rightSide" className={classNames('flex-1 w-full')}>
          {children}
        </div>
      </div>
      <Callout type="suggestion">
        {t`Have you been invited to a warehouse on WareBee? Please click on the
        link to`}
        {` `}
        <a
          className={classNames(
            'group',
            'underline',
            'decoration-menu-active/10 hover:decoration-menu-active',
            'hover:underline underline-offset-4',
            'inline-flex',
            'group-hover:text-menu-active',
          )}
          href="/"
        >
          <Icon.CircleArrowRight
            className={classNames(
              'w-4 h-4',
              'fill-current',
              'mx-1',
              'group-hover:text-menu-active',
            )}
          />
        </a>
      </Callout>
    </ItemListCard>
  );
};
