import { RoutingPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import { getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import InputSlider from '../components/inputs/InputSlider';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import RuleContainer from '../components/policies/RuleContainer';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import {
  getRoutingPolicyInput,
  shouldCallRoutingPolicyCheck,
} from '../simulation/store/routingPolicy/routingPolicy.helper';
import {
  routingPolicy,
  routingPolicySelectedIdentity,
} from '../simulation/store/routingPolicy/routingPolicy.state';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import { collapsibleStateAtom } from '../store/collapsible.state';
import { sidebarStateByType } from '../store/sidebar.state';
import RoutingPolicyFallbackRule from './RoutingPolicyFallbackRule';
import RoutingPolicyRule from './RoutingPolicyRule';
import RoutingPolicyWarnings from './RoutingPolicyWarnings';

const RoutingPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(routingPolicy);
  const updateSim = useUpdateSimulation();
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    routingPolicySelectedIdentity,
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-routing-policy'),
  );
  const [lastSavedPolicy, setLastSavedPolicy] =
    useState<RoutingPolicyFragment>();
  const formatter = useFormatter();

  useEffect(() => {
    // skip if policy not changed
    if (policy === lastSavedPolicy) return;

    // skip re-save on first render
    if (!_.isNil(lastSavedPolicy)) {
      updateSim({ routingPolicy: getRoutingPolicyInput(policy) });
    }

    // call check only whet it required
    if (shouldCallRoutingPolicyCheck(lastSavedPolicy, policy)) {
      //cancelCheck();
      //callCheck();
    }

    // update local state
    setLastSavedPolicy(policy);
  }, [policy]);

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();
    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy?.rules, r => r.title)),
      t`Routing Policy #`,
    );

    setPolicy({
      ...policy,
      rules: [
        ...(policy?.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          agentIds: [],
          featureRules: [],
        },
      ],
    });

    setSelectedIdentity({
      ruleId,
      isRoutingAgentSelected: true,
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });

    setPolicyEditPanelState({
      isHidden: false,
      isCollapsed: !policyEditPanelState.isPinned
        ? false
        : policyEditPanelState.isCollapsed,
    });
  };

  const isLoading = false;
  const hasError = false;

  const hasRules = !_.isEmpty(policy?.rules);
  const policyRuleCounter = policy?.rules?.length;

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={t`Routing policy`}
        subtitle={t`Agents — Locations`}
        isSticky
        helpNavTo={'simulation/policies/policy-routing/policy-routing'}
        icon={Icon.PolicyAssignmentRoute}
      />

      {!isLoading && !hasError && (
        <>
          {canUpdate && <RoutingPolicyWarnings />}
          <TitleSection
            title={
              <PolicyRuleSectionTitle
                isFeasible={!hasError}
                counter={policyRuleCounter}
              />
            }
            inPanelView
            className="top-12 xl:top-20 z-400"
            classNameInner={classNames('flex-1', {
              'bg-app-panel-dark': canUpdate,
              'bg-issue-transparent bg-app-panel-dark': !canUpdate,
            })}
            hasScreenTitle
            hasAction={
              canUpdate ? (
                hasRules && (
                  <Button
                    label={t`Add Policy`}
                    className={classNames('ltr:ml-4 rtl:mr-4 rounded')}
                    buttonSize="xs"
                    buttonType="primary"
                    hasIconAfter={
                      <Icon.CirclePlus
                        className={classNames('w-5 h-5 fill-current')}
                      />
                    }
                    onPress={() => addRule()}
                  />
                )
              ) : (
                <StatusTag
                  title={t`Policy Locked`}
                  type="locked"
                  modeStyle="stroke"
                  icon={Icon.Lock}
                  onPress={null}
                />
              )
            }
          >
            <Container col flex1>
              <PolicyWell
                data-component="PolicyWellRuleRouting"
                fullHeight
                isDisabled={!canUpdate}
                className={classNames('flex-1 mb-2')}
                classNameChildren={classNames('space-y-2 pb-10')}
              >
                {_.map(policy?.rules, (rule, index) => {
                  return (
                    <RoutingPolicyRule
                      key={`RoutingPolicyRule-${rule.id}-${index}`}
                      ruleId={rule.id}
                      index={index + 1}
                      canRename={true}
                      canDelete={true}
                      isDisabled={!canUpdate}
                      onDeleteClick={() => removeRule(rule.id)}
                    />
                  );
                })}

                {!hasRules && (
                  <InboxZero selfCenter hasIcon message={t`No Rules found`}>
                    {canUpdate && (
                      <Button
                        full
                        buttonSize="xs"
                        buttonType="primary"
                        className={classNames('flex-1 mt-3 rounded')}
                        label={t`Add New Policy`}
                        onPress={addRule}
                        hasIconAfter
                        buttonIcon={
                          <Icon.CirclePlus
                            className={classNames('w-5 h-5 fill-current')}
                          />
                        }
                      />
                    )}
                  </InboxZero>
                )}
              </PolicyWell>
            </Container>
          </TitleSection>
          <TitleSection
            id={'policy-routing-default'}
            title={t`Default Policy`}
            // collapsible
            inPanelView
          >
            <PolicyWell fullHeight isDisabled={!canUpdate}>
              <RoutingPolicyFallbackRule />
            </PolicyWell>
          </TitleSection>
          <TitleSection
            id={'policy-routing-setup'}
            title={t`Global Routing Setup`}
            collapsible
            inPanelView
          >
            <PolicyWell fullHeight isDisabled={!canUpdate}>
              <RuleContainer
                dataComponent="RoutingPolicyThreshold"
                key={`policy-routing-rule-threshold`}
                id={`policy-routing-rule-threshold`}
                title={t`Routing Thresholds`}
                isCollapsible={true}
                isDisabled={!canUpdate}
                hasIcon
                classNameInner={classNames(
                  'p-1 xl:p-2',
                  'bg-sidebar-header/70',
                  'space-y-2',
                )}
              >
                <PolicyFilter
                  key={`PolicyFilter-threshold-angle`}
                  label={t`Angle`}
                >
                  <InputSlider
                    min={-90}
                    max={90}
                    values={[policy.threshold?.angle ?? 0]}
                    stepSize={5}
                    onChange={v =>
                      setPolicy({
                        ...policy,
                        threshold: {
                          ...policy.threshold,
                          angle: _.head(v) ?? 0,
                        },
                      })
                    }
                    unitOfMeasure="°"
                    isDisabled={!canUpdate}
                  />
                </PolicyFilter>
                <PolicyFilter
                  key={`PolicyFilter-threshold-distance`}
                  label={t`Distance`}
                >
                  <InputSlider
                    min={0}
                    max={1000}
                    values={[policy.threshold?.distance ?? 0]}
                    stepSize={10}
                    onChange={v =>
                      setPolicy({
                        ...policy,
                        threshold: {
                          ...policy.threshold,
                          distance: _.head(v) ?? 0,
                        },
                      })
                    }
                    unitOfMeasure={formatter.sizeUnit}
                    isDisabled={!canUpdate}
                  />
                </PolicyFilter>
              </RuleContainer>
            </PolicyWell>
          </TitleSection>
        </>
      )}
    </Container>
  );
};

export default RoutingPolicy;
