import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from './alerts/Alerts';

const lsKey = 'warebee-cookie-consent';
const CookieConsent: React.FC = () => {
  const [refresh, setRefresh] = useState(nanoid());
  const value = localStorage?.getItem(lsKey);
  const { t } = useTranslation('app-public');

  if (!_.isNil(value)) return null;
  return (
    <footer
      data-component="CookieConsent"
      className={classNames(
        'absolute bottom-0 left-0 right-0 p-8 pb-6 z-privacy',
      )}
    >
      <Alert
        className={classNames(
          'shadow-xl text-xl py-2 ltr:pl-4 rtl:pr-4 rounded-xl text-menu-active-text',
        )}
        fullWidth
        // isAbsoluteBottom
        hasStatusConsent
        // message={t`Cookie accept`}
        actionable
        action_title={t`Confirm`}
        onActionButtonClick={() => {
          localStorage?.setItem(lsKey, 'true');
          setRefresh(nanoid());
        }}
        message={
          <Trans t={t}>
            <div
              className={classNames(
                'whitespace-normal text-sm md:text-base xl:text-xl',
              )}
            >
              We use cookies to enhance your experience. <br />
              By using WareBee (App and/or Services), you agree to our
              <a
                href="/legal/privacy"
                target="_blank"
                className={classNames(
                  'font-bold underline mx-1 whitespace-nowrap',
                )}
              >
                Privacy Policy
              </a>
              and our
              <a
                href="/legal/cookies"
                target="_blank"
                className={classNames(
                  'font-bold underline mx-1 whitespace-nowrap',
                )}
              >
                Cookie Policy
              </a>
              .
            </div>
          </Trans>
        }
      />
    </footer>
  );
};

export default CookieConsent;
