import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import InboxZero from '../components/InboxZero';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../containers/SidebarContainer';
import useLoadItemSetFilterValues from '../itemSet/hooks/useLoadItemSetFilterValues';
import {
  updateAllPolicyFilterValues,
  updatePolicyFilterValues,
} from '../policyFilters/policyFilter.helper';
import useLoadLocationFilterValues from '../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import { getStackingPolicyFilterConfig } from '../simulation/store/stackingPolicy/stackingPolicy.default';
import FilterPresetLocationFilterEditor from './FilterPresetLocationFilterEditor';
import FilterPresetProductFilterEditor from './FilterPresetProductFilterEditor';
import {
  filterPresetSelected,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

const FilterPresetEditorSidebar: React.FC = () => {
  const { t } = useTranslation('app');
  const formatter = useFormatter();

  const filterIdentity = useRecoilValue(filterPresetSelectedIdentity);
  const preset = useRecoilValue(filterPresetSelected);

  const [loadItemValues, cancelLoadItemValues] = useLoadItemSetFilterValues();
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  const showProductFilters =
    !_.isNil(preset) && !_.isNil(filterIdentity?.productFilterId);
  const showLocationFilters =
    !_.isNil(preset) && !_.isNil(filterIdentity?.locationFilterId);

  const productFiltersConfig = getStackingPolicyFilterConfig(t);
  const locationFiltersConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (showProductFilters) {
      updatePolicyFilterValues({
        filterConfig: productFiltersConfig,
        selectedFilterId: filterIdentity.productFilterId,
        policyFilterUnion: preset.items,
        load: loadItemValues,
        cancel: cancelLoadItemValues,
      });
    }
    if (showLocationFilters) {
      updateAllPolicyFilterValues({
        filterConfig: locationFiltersConfig,
        selectedFilterId: filterIdentity.locationFilterId,
        policyFilterUnion: preset.locations,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }
  }, [filterIdentity]);

  if (!showProductFilters && !showLocationFilters) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY className="pb-20">
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${preset?.title}`}
        isSticky
      />
      {showProductFilters &&
        _.map(productFiltersConfig, configItem => (
          <FilterPresetProductFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadItemValues}
            cancelLoadData={cancelLoadItemValues}
          />
        ))}

      {locationFiltersConfig &&
        _.map(locationFiltersConfig, configItem => (
          <FilterPresetLocationFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('feed');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-filter-preset-editor',
    title: t`Filter Preset`,
    loadingMessage: t`Loading Filters`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <FilterPresetEditorSidebar />
    </SidebarContainer>
  );
};
