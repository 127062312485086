import { AgentSettingsWithMeta } from '../agent.types';
import eur from './eur.json';
import gbp from './gbp.json';
import rub from './rub.json';
import usd from './usd.json';

export const currencyDefaults = new Map<string, Partial<AgentSettingsWithMeta>>(
  [
    ['USD', usd],
    ['GBP', gbp],
    ['EUR', eur],
    ['RUB', rub],
  ],
);
