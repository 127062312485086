import * as React from 'react';
const WarehouseECommerceIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseECommerceIcon"
    {...props}
  >
    <g>
      <path
        strokeWidth={0}
        d="M32.31,46.24h-.14c-.74,0-1.46.11-2.14.3,2.1,1.81,3.44,4.46,3.44,7.41v8.14h6.7v-8.14c0-4.25-3.52-7.71-7.85-7.71h-.01Z"
      />
      <path
        strokeWidth={0}
        d="M24.31,62.08h6.96v-8.13c0-2.66-1.38-5.02-3.48-6.4-2.1,1.39-3.48,3.74-3.48,6.4v8.13Z"
      />
      <path
        strokeWidth={0}
        d="M22.12,62.08v-8.13c0-2.95,1.34-5.6,3.44-7.41-.68-.19-1.4-.3-2.14-.3h-.14c-4.33,0-7.85,3.46-7.85,7.71v8.13h6.7-.01Z"
      />
      <path
        strokeWidth={0}
        d="M64.5,24.49L.84,40.92v62.58h126.32v-62.58l-62.66-16.43ZM48.66,92.91c0,1.65-1.42,3-3.16,3s-3.16-1.34-3.16-3v-30.8h-2.19v30.8c0,1.11.37,2.14.99,2.98H7.06v-33.81h6.18v-8.13c0-5.44,4.5-9.86,10.04-9.86h.14c1.57,0,3.05.36,4.37,1,1.32-.63,2.8-1,4.37-1h.14c5.54,0,10.04,4.42,10.04,9.86v8.14h6.31v30.82h.01Z"
      />
      <path
        strokeWidth={0}
        d="M25.42,69.99c-6.94,0-11.51,5.44-11.51,11.67s4.41,9.7,9.54,9.7c2.13,0,3.78-.24,5.6-1.1l-.55-1.81c-1.18.63-2.84.95-4.41.95-4.57,0-7.88-2.92-7.88-8.04,0-5.83,4.1-9.54,8.83-9.54s7.33,3.07,7.33,7.09c0,3.55-1.66,5.2-3,5.2-.87-.08-1.1-.87-.79-2.68l.95-5.68c-.71-.47-2.29-.79-3.63-.79-4.41,0-7.17,3.47-7.17,7.17,0,2.52,1.5,4.02,3.47,4.02,1.58,0,3-.79,3.94-2.36h.08c.08,1.66,1.18,2.36,2.6,2.36h0c3.31,0,5.83-2.84,5.91-7.33,0-5.12-3.86-8.83-9.3-8.83h-.01ZM25.81,80.4c-.24,1.81-1.5,3.23-2.6,3.23-.95,0-1.5-.71-1.5-1.89h0c0-2.29,1.66-4.34,3.55-4.41.39,0,.71.08.95.16l-.39,2.92h-.01Z"
      />
    </g>
  </svg>
);
export default WarehouseECommerceIcon;
