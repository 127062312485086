import { DefaultRawDatum, ResponsivePie } from '@nivo/pie';
import React from 'react';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import ChartTooltip from './ChartTooltip';

export type PieData = DefaultRawDatum & {
  label: string;
  color: [string, string];
  formatted: FormattedValueWithUnit;
};

export type SimulationPieChartProps = {
  data: PieData[];
  innerRadius?: number;
  showLabels?: boolean;
};

const SimulationPieChart: React.FC<SimulationPieChartProps> = props => {
  return (
    <ResponsivePie
      margin={{ top: 10, right: 5, bottom: 10, left: 5 }}
      data={props.data}
      enableArcLabels={props.showLabels}
      arcLabel={datum => datum.data.formatted.fullString}
      innerRadius={props.innerRadius || 0.65}
      // padAngle={1}
      cornerRadius={0}
      colors={row => row.data.color[0]}
      enableArcLinkLabels={false}
      arcLinkLabelsTextColor={`black`}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabelsSkipAngle={10}
      arcLabelsSkipAngle={10}
      activeOuterRadiusOffset={8}
      borderWidth={0}
      startAngle={-90}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 10]] }}
      tooltip={({ datum: { data } }) => (
        <ChartTooltip
          color={[data.color[0], data.color[0]]}
          title={data.label}
          value={data.formatted.fullString}
        />
      )}
    />
  );
};
export default SimulationPieChart;
