import { IsNotEmpty } from '@warego/class-validator';
import { signIn, signInWithRedirect } from 'aws-amplify/auth';
import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { analyticsAgent } from '../AnalyticTracker';
import FormError from '../components/FormError';
import { Button } from '../components/actions/Button';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { Alert } from '../components/alerts/Alerts';
import * as Icon from '../components/icons';
import InputText, { InputPassword } from '../components/inputs/InputText';
import {
  Container,
  ContainerHalfPromo,
} from '../components/layout/ContainerFlex';
import { ContainerPublic } from '../components/layout/ContainerPublic';
import { LogoHeader } from '../components/layout/LogoHeader';
import { Spacer } from '../components/layout/Spacer';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { NavBackWebsite } from '../components/nav/NavBackWebsite';
import { NavGo } from '../components/nav/NavGo';
import { authState, authUserPoolType } from '../store/auth.state';
import { CognitoUserPool } from '../store/auth.types';
import useValidatedForm from '../utils/use-validated-form';
import { useErrorTranslator } from './ErrorTranslator';
import { FooterPublic } from './FooterPublic';
import { Intro, RegisterHelperPanel } from './Intro';
import LoginTOTPForm from './LoginTOTPForm';
import LoginTOTPSetup from './LoginTOTPSetup';
import { FormConfirmation } from './Register';

export type LoginProps = {
  onLogin: (username: string, password: string) => Promise<void>;
  message?: string;
};

class LoginFormState {
  @IsNotEmpty({ message: ({ t }) => t('Username required') })
  username: string;

  @IsNotEmpty({ message: ({ t }) => t('Password required') })
  password: string;
}

export const Login = () => {
  const { t } = useTranslation('app-public');
  const auth = useRecoilValue(authState);
  const [userPoolType, setUserPoolType] = useRecoilState(authUserPoolType);
  const navigate = useNavigate();
  const location = useLocation();
  // SSO
  const [searchParams] = useSearchParams();
  const ssoProvider =
    searchParams.get('provider') ||
    searchParams.get('sso') ||
    searchParams.get('proglove') ||
    searchParams.get('with');

  const [loggingIn, setLoggingIn] = useState(false);
  const [loginMessage, setLoginMessage] = useState(
    location.state?.message || (auth.status === 'unauthorized' && auth.message),
  );
  const [usernameToConfirm, setUsernameToConfirm] = useState<string>(null);
  const [showTotp, setShowTotp] = useState(false);
  const [mfaSetupSecret, setMFASetupSecret] = useState<string>();
  const { translateError } = useErrorTranslator();

  const { register, handleSubmit, formState, getValues } =
    useValidatedForm<LoginFormState>(LoginFormState, {
      defaultValues: {
        username: location.state?.username,
      },
      validatorOptions: {
        extraArguments: {
          t,
        },
      },
    });

  if (auth.status !== 'unauthorized') {
    return null;
  }

  const viewAs: { key: CognitoUserPool; label: string }[] = [
    {
      key: 'default',
      label: t('Regular Login'),
    },
    {
      key: 'mfa',
      label: t('Enterprise Login'),
    },
  ];

  const showLoginForm =
    _.isEmpty(usernameToConfirm) && !showTotp && !mfaSetupSecret;

  async function handleLogin({ username, password }: LoginFormState) {
    if (loggingIn) {
      return;
    }

    setLoggingIn(true);
    setLoginMessage(undefined);
    analyticsAgent?.track('Sign In: Button Clicked', { login: username });

    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      switch (nextStep?.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
          setShowTotp(true);
          break;
        case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
          // const totpSetupDetails = nextStep.totpSetupDetails;
          // const appName = 'my_app_name';
          // const setupUri = totpSetupDetails.getSetupUri(appName);
          // console.log('MFA: setupUri', setupUri);
          setMFASetupSecret(nextStep.totpSetupDetails.sharedSecret);
          break;
        default:
          navigate('/');
      }
    } catch (err) {
      // error instanceof AuthError  && console.log(error.name, error.message, error.recoverySuggestion)
      const translatedMessage = translateError(err); // Translate the error here
      analyticsAgent?.track('Sign In: Login Error', { error: err.message });
      console.error('login error %o', err);
      if (err?.code === 'UserNotConfirmedException') {
        setLoginMessage(translatedMessage || t('Error occurred during login'));
        setUsernameToConfirm(username);
      } else {
        setLoginMessage(translatedMessage || t('Error occurred during login'));
      }
    } finally {
      setLoggingIn(false);
    }
  }

  function handleConfirm() {
    setUsernameToConfirm(null);
  }

  const linkWebsite = 'https://warebee.com/?source=app?feature=login';

  const handleSSO = async () => {
    try {
      await signInWithRedirect({
        provider: {
          custom: ssoProvider,
        },
      });
      // The user will be redirected to the SSO login page
    } catch (error) {
      console.error('SSO login error:', error);
      setLoginMessage(t('Error occurred during SSO login'));
    }
  };

  const usernameFieldProps = register('username');
  const passwordFieldProps = register('password');

  return (
    <ContainerPublic consent dataComponent="Login">
      {mfaSetupSecret && (
        <>
          {/* ——————————————— MFA Setup Side ——————————————— */}
          <ContainerHalfPromo>
            <Container flex1>
              <RegisterHelperPanel title={`Multi-factor authentication setup`}>
                {t`Please setup your two-factor authentication, as required by your organisation.`}
              </RegisterHelperPanel>
            </Container>
          </ContainerHalfPromo>
        </>
      )}
      <div
        className={classNames(
          'bg-menu-app flex w-full flex-1 flex-col md:w-1/2',
        )}
      >
        {mfaSetupSecret ? (
          <header className={classNames('hidden h-12 p-4 md:flex')}>
            <a href={`${linkWebsite}`}>
              <NavBackWebsite message={t('Back to Login')} />
            </a>
            <Spacer flexspace />
          </header>
        ) : (
          <>
            <header className={classNames('hidden h-12 p-4 md:flex')}>
              <a href={`${linkWebsite}`}>
                <NavBackWebsite message={t('Back to WareBee.com')} />
              </a>
              <Spacer flexspace />
            </header>
            <header className="bg-menu-active text-menu-active-text flex items-center p-4 md:hidden">
              <Link to="/register" className={`flex flex-1 items-center`}>
                <span className="flex-1">{t`Don't have an Account? Sign Up`}</span>
                <Icon.ArrowRight className="h-6 w-6 rounded-full fill-current p-1 ltr:ml-6 rtl:mr-6" />
              </Link>
            </header>
          </>
        )}

        <main className="flex flex-1 flex-col items-center p-2">
          <LogoHeader logoFull logoSize={mfaSetupSecret ? 'sm' : 'md'} />

          {!mfaSetupSecret ? (
            <section className="text-menu-active">
              <p>{t('Hello, Let`s start optimising')}</p>
            </section>
          ) : null}

          {usernameToConfirm && (
            <>
              <div>{t('Account is not verified')}</div>
              <FormConfirmation
                email={usernameToConfirm}
                onConfirm={handleConfirm}
                sendCode={true}
              />
            </>
          )}

          {mfaSetupSecret && (
            <LoginTOTPSetup
              email={getValues('username')}
              mfaSetupCode={mfaSetupSecret}
            />
          )}

          {showTotp && <LoginTOTPForm />}
          {showLoginForm && (
            <form
              data-component="LoginForm"
              onSubmit={handleSubmit(handleLogin)}
              className="form-login w-full flex-1 p-4"
            >
              <section className="w-full space-y-8 p-4 pb-0">
                {loginMessage && (
                  <Alert hasStatusAlert message={loginMessage} />
                )}
                <fieldset>
                  <InputText
                    id="username"
                    autoComplete="username"
                    placeholder={
                      userPoolType === 'mfa'
                        ? t('Enterprise Login')
                        : t('Login')
                    }
                    label={
                      <>
                        {/* <span>{t`Login`}</span>
                        <Spacer flexspace /> */}
                        <ButtonSwitchMulti
                          autoSize
                          buttonType="switchon"
                          className="text-xxs ltr:pr-1 rtl:pl-1"
                          options={viewAs}
                          selectedIndex={viewAs.findIndex(
                            o => o.key === userPoolType,
                          )}
                          onClick={index => setUserPoolType(viewAs[index].key)}
                        />
                      </>
                    }
                    name="username"
                    {..._.omit(usernameFieldProps, 'onChange')}
                    onChangeValidate={usernameFieldProps.onChange}
                  />
                  <FormError errors={formState?.errors} name="username" />
                </fieldset>
                <fieldset>
                  <InputPassword
                    id="current-password"
                    autoComplete="current-password"
                    placeholder={t('Password')}
                    label={t('Password')}
                    name="password"
                    {..._.omit(passwordFieldProps, 'onChange')}
                    onChangeValidate={e => {
                      passwordFieldProps.onChange(e);
                    }}
                    showPasswordPreview
                  />
                  <FormError errors={formState?.errors} name="password" />
                </fieldset>
              </section>

              <footer className="flex w-full items-center p-4">
                <Spacer flexspace />
                {ssoProvider && (
                  <button
                    type="button"
                    onClick={handleSSO}
                    className="text-menu-text hover:text-brand p-2 ltr:mr-4 rtl:ml-4"
                  >
                    {t`Login with SSO`}
                  </button>
                )}
                <Button
                  label={t('Login')}
                  type="submit"
                  isLoading={loggingIn}
                  isDisabled={loggingIn || !_.isEmpty(formState?.errors)}
                  buttonType="primary"
                  hasIconAfter
                  buttonIcon={<IconArrowDir />}
                />
              </footer>
              <section className="mt-4 flex w-full space-y-2 p-4 pt-0">
                <div className="text-menu-active flex-1 underline-offset-4 hover:underline">
                  <Link to="/forgot">{t('Forgot Password?')} </Link>
                </div>
              </section>
            </form>
          )}
        </main>
        {!mfaSetupSecret && <FooterPublic />}
      </div>
      {!mfaSetupSecret && (
        <>
          {/* ——————————————— Right Side ——————————————— */}
          <ContainerHalfPromo>
            <header className="bg-brand fixed top-0 z-0 flex w-1/2 items-center bg-opacity-80 p-4 backdrop-blur backdrop-filter">
              <Link to="/registration" className={`flex-1`}>
                <NavGo message={t`Don't have an Account? Sign Up`} />
              </Link>
            </header>
            <Container flex1>
              <Intro />
            </Container>
          </ContainerHalfPromo>
        </>
      )}
    </ContainerPublic>
  );
};
