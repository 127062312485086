import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '../components/actions/Button';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { ItemListCard } from '../dashboard/containers/ItemListCard';
import { warehouseIsDemo } from '../store/warehouse.state';
import { HeroDemo } from './HeroDemo';
import { HeroPricing } from './HeroPricing';

export type PlanCardProps = {
  children?: React.ReactNode;
  className?: string;
  warehouseId?: string;
};

const pricingPlanItems = [
  {
    title: `Analyse (Subscription)`,
    title_action: `Subscribe to Analyse Subscription`,
    description: `Description...`,
    description_features_list: ['Description...'],
    warehouse_size: 'LARGE',
    action: `Subscribe`,
    type: 'SUBSCRIPTION',
    recurring: `Monthly`,
    billingBasis: `Warehouse`,
    billingInterval: `Month`,
    price: `199`,
    currency: `$`,
    paymentLink: ``,
    services: [`analyse`],
    plan: ['PAID'],
  },
  {
    title: `Optimise (Subscription)`,
    description: `Description...`,
    description_features_list: ['Description...'],
    warehouse_size: 'LARGE',
    action: `Subscribe`,
    type: 'SUBSCRIPTION',
    recurring: `Monthly`,
    billingBasis: `Warehouse`,
    billingInterval: `Month`,

    price: `1499`,
    currency: `$`,
    paymentLink: `https://buy.stripe.com/4gwbKi3wMaX3crmaEE`,
    services: [`optimise`],
    plan: ['PAID'],
  },
  {
    title: `Analyse (Simulation Runs Bundle)`,
    description: `Description...`,
    description_features_list: ['Description...'],
    warehouse_size: 'LARGE',
    action: `Buy 10 - Analyse Runs`,
    type: `BUNDLE`,
    recurring: ``,
    billingBasis: `Analysis Bundle - 10 Runs`,
    price: `799`,
    currency: `$`,
    paymentLink: `https://buy.stripe.com/4gwbKi3wMaX3crmaEE`,
    services: [`analyse`],
    plan: ['PAID'],
  },
  {
    title: `Optimise (Simulation Runs Bundle)`,
    description: `Description...`,
    description_features_list: ['Description...'],
    warehouse_size: 'LARGE',
    action: `Buy 10 - Optimisations`,
    type: `BUNDLE`,
    recurring: ``,
    billingBasis: `Optimise Bundle - 10 Runs`,
    price: `2599`,
    currency: `$`,
    paymentLink: `https: //buy.stripe.com/4gwbKi3wMaX3crmaEE`,
    services: [`analyse`],
    plan: ['PAID'],
  },
];

const featureItems = [
  {
    title: `Warehouse Analytics heatmap`,
    services: ['analyse', 'optimise'],
  },
  {
    title: `Issues list (missing locations, items)`,
    services: ['analyse'],
  },
  {
    title: `Picking cost`,
    services: ['analyse'],
  },
  {
    title: `Picking time`,
    services: ['analyse'],
  },
  {
    title: `CO2 Footprint`,
    services: ['analyse'],
  },
];

export type TotalAmountProps = {
  children?: React.ReactNode;
  price?: string;
  currency?: string;
  recurring?: string;
  billingBasis?: string;
  billingInterval?: string;
  title?: string;
  type?: string;
  displayMode?: 'CARD' | 'SUMMARY';
};

export const TotalAmount = ({
  children,
  price,
  currency,
  recurring,
  billingBasis,
  billingInterval,
  title,
  type,
  displayMode,
}: TotalAmountProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <div
      data-component="TotalAmount"
      id={id}
      className={classNames(
        'my-4 mb-8',
        'text-center',
        displayMode === 'CARD'
          ? 'text-menu-active mx-8'
          : 'text-menu-active-text',
      )}
    >
      <div
        className={classNames(
          'mb-4 pb-4',
          'text-2xl',
          'opacity-75',
          'border-b',
          displayMode === 'CARD' ? 'border-menu-active' : 'border-menu',
        )}
      >
        <span className="">{title}</span>
        {
          recurring ? (
            <>
              {/* <span className="">{title}</span> */}
              {/* <span className="">{billingBasis}</span>
            <span className="ltr:ml-1 rtl:mr-1">{t`Subscription`}</span> */}
            </>
          ) : null
          // <span className="">{billingBasis}</span>
        }
      </div>

      <div className={classNames('flex items-center justify-center', 'mt-8')}>
        <div className={classNames('flex items-baseline')}>
          <span className={classNames('text-4xl opacity-75')}>{currency}</span>
          <span className={classNames('text-7xl')}>{price}</span>
        </div>

        <div
          className={classNames(
            'flex flex-col items-start self-center',
            'ltr:ml-2 rtl:mr-2',
          )}
        >
          <div className={classNames('items-start text-base')}>{t`per`}</div>
          <div className={classNames('text-2xl opacity-75')}>
            {billingInterval}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export type PriceCardProps = {
  children?: React.ReactNode;
  planType?: string;
};

export const PriceCard = ({ children, planType }: PriceCardProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const featureList = (
    <ul
      data-component="PriceCardFeatureList"
      className={classNames(
        'my-4 mb-8',
        'divide-menu/50 divide-y divide-solid',
      )}
    >
      {featureItems.map(item => (
        <li
          data-component="PriceCardFeatureItem"
          key={item.title}
          className={classNames('py-2 ltr:pl-2 rtl:pr-2')}
        >
          {item.title}
        </li>
      ))}
    </ul>
  );
  const cardHelper = (
    <div
      data-component="PriceCardHelper"
      className={classNames('flex', 'px-4', 'text-sm', 'text-menu-text/60')}
    >
      <div className="flex-1">{t`Not Sure, what option to pick? Let's Chat`}</div>
    </div>
  );

  function planBuyLink(paymentLink) {
    // const url = paymentLink;
    window.open(paymentLink, '_blank');
  }

  return (
    <>
      {_(pricingPlanItems)
        .filter(plan => plan.type === planType)
        .map(item => (
          <ItemListCard
            className="flex-none"
            titleTrace={`Billing: Plan Card ${item.title}`}
            fullWidth
            isDemo
            key={item.title}
          >
            <h4
              className={classNames(
                'p-3 px-6',
                'mb-8',
                'text-center text-base uppercase',
                'rounded-full',
                'text-menu-active bg-app-panel-dark/60',
              )}
            >
              {item.title}
            </h4>
            <TotalAmount
              title={item.title_action}
              price={item.price}
              currency={item.currency}
              recurring={item.recurring}
              billingBasis={item.billingBasis}
              billingInterval={item.billingInterval}
              type={item.type}
              displayMode={'CARD'}
            />
            {featureList}
            <div>
              <Button
                className={classNames('mb-8 mt-4 rounded-full')}
                titleTrace={`${item.action} ${item.title} Price:${item.price}`}
                label={item.action}
                buttonType="primary"
                buttonSize="xl"
                isDisabled={false}
                full
                hasIconAfter
                onPress={() => {
                  window.open(item.paymentLink, '_blank');
                }}
                buttonIcon={<IconArrowDir />}
              />
            </div>
            {cardHelper}
          </ItemListCard>
        ))
        .value()}
      {children}
    </>
  );
};

export const HeroBannerCard = ({ children, className }: PlanCardProps) => {
  const { t } = useTranslation('app');

  const isDemo = useRecoilValue(warehouseIsDemo);

  return (
    <>
      {isDemo ? <HeroDemo /> : <HeroPricing />}
      {children}
    </>
  );
};
