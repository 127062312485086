import { LocationPortalType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Circle, Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  viewerLevelLocations,
  viewerSelectedPlaneId,
} from '../../layout/viewer/store/viewer.state';
import { feedHeatmapCongestions } from '../store/feed.state';

const minSize = 0;
const maxSize = 80;

const ActivityCongestionLayer: React.FC = () => {
  const levelLocationsLoadable = useRecoilValueLoadable(viewerLevelLocations);
  const congestionsLoadable = useRecoilValueLoadable(feedHeatmapCongestions);
  const planeId = useRecoilValue(viewerSelectedPlaneId);
  // const showCongestion = useRecoilValue(simulationLayoutShowCongestion);
  const congestionColors = TwTheme.extend.colors.congestion;

  const getFillColor = (rate: number) => {
    // Assuming rate is between 0 and 100
    if (rate > 99) return congestionColors.DEFAULT;
    const hueStart = 170;
    const hueEnd = 345;

    const saturation = 100; // keeping saturation constant at 100%
    const lightness = 50; // keeping lightness constant at 50%

    const hue = hueStart + ((hueEnd - hueStart) * rate) / 100;
    const opacity = 0.45 + 0.35 * (rate / 100); // Adjust opacity from 45% to 90%

    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
  };

  const getBlurRadius = (rate: number) => {
    const minBlur = 1;
    const maxBlur = 10;
    // Interpolate blur based on rate, linearly from minBlur to maxBlur
    return minBlur + ((maxBlur - minBlur) * rate) / 100;
  };

  // if (!showCongestion || !planeId || _.isEmpty(props.waypoints)) return null;
  const isSetted =
    levelLocationsLoadable.state === 'hasValue' &&
    congestionsLoadable.state === 'hasValue';

  if (!isSetted) return null;
  const congestions = congestionsLoadable.getValue();
  const locationMap = _.keyBy(
    levelLocationsLoadable.getValue(),
    l => l.locationId,
  );

  const max = _.maxBy(congestions, d => d.congestion)?.congestion ?? 1;

  return (
    <Layer>
      {_(congestions)
        .filter(data => data.congestion > 0)
        .map((data, index) => {
          const loc = locationMap[data.locationId];
          const portal = _.find(
            loc?.portals,
            p =>
              p.type === LocationPortalType.DEFAULT ||
              p.type === LocationPortalType.PRIMARY,
          );
          if (_.isNil(portal)) return;

          const radius =
            Math.floor((1.25 * data.congestion * (maxSize - minSize)) / max) +
            minSize;
          const { x, y } = portal.position;
          // const congestionRate = Math.max(45, (data.total * 100) / max);
          // const fill = `#ff0073${congestionRate.toFixed(0)}`;

          const congestionRate = Math.max(
            0,
            Math.min(100, (data.congestion * 100) / max),
          );
          const fill = getFillColor(congestionRate);
          const blurRadius = getBlurRadius(congestionRate);

          const textLabel = data.congestion.toFixed(0);
          const textRadiusOffset = radius; // Assuming the text fits within the circle's radius
          const fontSize = 12;

          // Calculate the vertical offset based on the circle's position or other criteria
          const verticalOffset = locationMap ? fontSize / 4 : 0; // Adjust this logic based on your needs
          const points = _.takeRight(loc.shape.coordinates[0], 2);
          const dx = points[1][0] - points[0][0];
          const dy = points[1][1] - points[0][1];
          // console.log('dx-dy', dx, dy);
          const labelOffsetX = dy > 0 ? 20 : -20;
          const labelOffsetY = dx > 0 ? -20 : 20;
          return (
            <Fragment key={`congestion-${data.locationId}`}>
              <Circle
                key={data.locationId}
                radius={radius}
                // blurRadius={blurRadius}
                blurRadius={100}
                // fill={fill}
                fillRadialGradientStartPoint={{ x: 0, y: 0 }}
                fillRadialGradientStartRadius={0}
                fillRadialGradientEndPoint={{ x: 0, y: 0 }}
                fillRadialGradientEndRadius={radius * 1.2} // extending the gradient outside the circle
                fillRadialGradientColorStops={[
                  0,
                  fill, // Start with the solid color
                  0.4,
                  fill, // Maintain the solid color up to 80% of the radius
                  1,
                  'hsla(204, 17%, 65%, 0)', // Transition to transparent
                ]}
                x={x}
                y={y}
              />
              {/* {data.congestion > 200 && (
                <Text
                  text={textLabel}
                  x={x + labelOffsetX}
                  y={y + labelOffsetY} // Apply the vertical offset by adjusting the y position
                  fontSize={fontSize}
                  fill="white"
                  align="center"
                  verticalAlign="middle"
                  listening={false}
                  // Adjust offsetX to center the text based on its width dynamically. Assuming textRadiusOffset is calculated for centering.
                  offsetX={textRadiusOffset}
                  // For a slight vertical offset, subtract (for upward) or add (for downward) from the y position instead of using offsetY
                  scaleY={-1} // Inverts the scale on the y-axis to flip the text, assuming this is necessary for your specific use case
                />
              )} */}
            </Fragment>
          );
        })
        .compact()
        .value()}
    </Layer>
  );
};

export default ActivityCongestionLayer;
