import { Transition } from '@headlessui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { cn } from '../../common/utils';

type DropdownContextType = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type DropdownVisualProps = {
  className?: string;
  classNameDropdown?: string;
  classNameDropDownMenu?: string;
  classNameItem?: string;
  menuTop?: boolean;
  menuBottom?: boolean;
  DropUp?: boolean;
  DropDown?: boolean;
  DropAlignLeft?: boolean;
  DropAlignRight?: boolean;
  w_xs?: boolean;
  w_sm?: boolean;
  w_lg?: boolean;
  w_xl?: boolean;
  widthFull?: boolean;
  hasIcon?: boolean;
  buttonTransparent?: boolean;
  light?: boolean;
  dark?: boolean;
  vertical?: boolean;
  hasStatusAlert?: boolean;
  hasStatusError?: boolean;
  hasStatusWarning?: boolean;
  hasStatusInfo?: boolean;
  hasStatusOkay?: boolean;
  hasStatusIcon?: boolean;
  hasSearch?: boolean;
  hasSubAction?: boolean;
  headerMode?: boolean;
  valuePrimary?: boolean;
  multiline?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export type DropdownProps = {
  toggle: (ctx: DropdownContextType) => React.ReactNode;
  children: React.ReactNode;
} & DropdownVisualProps;

const DropdownContext = React.createContext<DropdownContextType | null>(null);

function Dropdown({
  toggle,
  children,
  buttonTransparent,
  light,
  dark,
  vertical,
  w_xs,
  w_sm,
  w_lg,
  w_xl,
  widthFull,
  hasIcon,
  hasStatusAlert,
  hasStatusError,
  hasStatusWarning,
  hasStatusInfo,
  hasStatusOkay,
  hasStatusIcon,
  hasSearch,
  hasSubAction,
  className,
  classNameDropdown,
  classNameItem,
  classNameDropDownMenu,
  menuTop,
  menuBottom,
  DropUp,
  DropDown,
  DropAlignLeft,
  DropAlignRight,
  headerMode,
  disabled,
  ...elementProps
}: DropdownProps) {
  const dropdownRef = useRef<HTMLUListElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const pageClickEvent = (e: Event) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener('click', pageClickEvent);
      return () => window.removeEventListener('click', pageClickEvent);
    }
  }, [open]);

  // DropDown = true;
  // DropDown === true ? (DropUp = false) : (DropUp = true);
  // DropUp === true ? (DropDown = false) : (DropDown = true);

  const classNameMenu =
    (headerMode ? 'headerMode ' : '') +
      ' ' +
      (!headerMode ? 'NoHeaderMode ' : '') +
      (DropUp
        ? ' DropUp bottom-10 ltr:origin-top-left rtl:origin-top-right ltr:left-0 rtl:right-0 mb-1'
        : '') +
      (DropDown && headerMode
        ? ' DropDownHeader top-10 ltr:origin-bottom-right rtl:origin-bottom-left ltr:left-0 rtl:right-0'
        : '') +
      (DropDown && !headerMode
        ? ' DropDown top-12 ltr:origin-bottom-right rtl:origin-bottom-left ltr:left-0 rtl:right-0'
        : '') +
      ' ' +
      classNameItem || '';

  // Define width classes
  let widthClasses = '';
  if (w_xs) {
    widthClasses = 'w-20';
  } else if (w_sm) {
    widthClasses = 'w-32';
  } else if (w_lg) {
    widthClasses = 'w-48';
  } else if (w_xl) {
    widthClasses = 'w-64';
  } else {
    widthClasses = widthFull ? 'w-full' : 'w-auto';
  }

  // Define the alignment classes based on DropAlignLeft and DropAlignRight
  const alignmentClasses = cn({
    'ltr:origin-top-left rtl:origin-top-right right-auto ltr:left-0 rtl:right-0':
      DropAlignLeft,
    'ltr:origin-top-right rtl:origin-top-left ltr:left-auto rtl:right-auto ltr:right-0 rtl:left-0':
      DropAlignRight,
  });

  // Define drop-up or not
  const dropUpClasses = DropUp
    ? 'bottom-10 ltr:origin-top-right rtl:origin-top-left ltr:left-auto rtl:right-auto ltr:right-0 rtl:left-0'
    : '';

  return (
    <div
      data-component={`Dropdown`}
      className={cn(
        'relative',
        'min-w-0',
        'flex text-start',
        { 'flex-1': widthFull },
        { 'w-xs': w_xs },
        { 'w-sm': w_sm },
        { 'w-lg': w_lg },
        { 'w-xl': w_xl },
        { 'drop-down': DropDown },
        { 'drop-up': DropUp },
        classNameDropdown,
        className,
      )}
      {...elementProps}
    >
      <DropdownContext.Provider value={{ open, setOpen }}>
        <div
          data-component={`DropdownContext`}
          className={cn(
            'relative',
            'flex flex-1',
            'w-full',
            'focus:outline-none focus:ring-0',
            { 'drop-down': DropDown },
            { 'drop-up': DropUp },
          )}
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          onClick={e => {
            e.stopPropagation();
            !disabled && setOpen(!open);
          }}
          aria-haspopup="true"
          aria-expanded={open}
          role="button"
          area-role="button"
          tabIndex={0}
        >
          {toggle({ open, setOpen })}
        </div>
        <Transition
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transition transform opacity-0 scale-95"
          enterTo="transition transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transition transform opacity-100 scale-100"
          leaveTo="transition transform opacity-0 scale-95"
          className={cn(
            `z-dropdown_menu absolute`,
            alignmentClasses,
            dropUpClasses,
            widthClasses,
            'z-dropdown_menu rounded-md',
            'max-h-48 overflow-y-auto overflow-x-hidden',
            'backdrop-blur-lg',
            'bg-app-header/80',
            'shadow-lg',
          )}
        >
          <ul
            data-component={`DropdownMenu`}
            data-label={`dropdown-menu`}
            aria-label={`dropdown-menu`}
            className={cn(classNameDropDownMenu)}
            ref={dropdownRef}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {children}
          </ul>
        </Transition>
      </DropdownContext.Provider>
    </div>
  );
}

export type DropdownItemProps = {
  active?: boolean;
  icons?: string;
  classNameItem?: string;
} & React.HTMLProps<HTMLLIElement>;

function Item({
  onClick,
  classNameItem,
  active,
  icons,
  ...props
}: DropdownItemProps) {
  const { setOpen } = useContext(DropdownContext)!;

  const onClickHandler = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();

    setOpen(false);

    if (onClick) {
      onClick(e);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <li
      data-component={`dropdownItem`}
      data-label={`dropdown-item`}
      aria-label={`dropdown-item`}
      className={cn(
        'hover:bg-menu-active/80 hover:text-menu-active-text group flex items-start',
        'px-3 py-3',
        'relative',
        {
          'bg-menu-active text-menu-active-text hover:bg-opacity-100': active,
          'text-menu-active/75': !active,
        },
        classNameItem,
      )}
      onClick={onClickHandler}
      role="menuitem"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

Dropdown.Item = Item;

export default Dropdown;
