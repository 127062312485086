import classNames from 'classnames';
import { ChangeEvent, PropsWithChildren, useId, useState } from 'react';

export type FileUploadProps = PropsWithChildren & {
  className?: string;
  accept?: string;
  onChange?: (file?: File) => void;
};

const FileUpload: React.FC<FileUploadProps> = props => {
  const id = useId();
  const [draggingOver, setDraggingOver] = useState(false);
  const { accept, onChange, children } = props;

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files.length > 0) {
      onChange(files[0]);
    } else {
      onChange(null);
    }
  }

  return (
    <label
      data-component="FileUpload"
      htmlFor={id}
      className={classNames('flex flex-1', 'm-0.5', 'cursor-pointer')}
    >
      <div
        className={classNames(
          'flex flex-1 flex-col items-center justify-center',
          // 'py-4',
          'relative rounded-lg',
          'bg-sidebar-menu',
          'cursor-pointer',
          { 'border-menu-active border-2': draggingOver },
          props.className,
        )}
        onDragEnter={() => setDraggingOver(true)}
        onDragLeave={() => setDraggingOver(false)}
        onDragEnd={() => setDraggingOver(false)}
      >
        {children}
        <input
          id={id}
          type="file"
          className={classNames(
            'absolute',
            'h-full w-full',
            'opacity-0',
            'cursor-pointer',
          )}
          accept={accept}
          onChange={handleOnChange}
        />
      </div>
    </label>
  );
};

export default FileUpload;
