import * as React from 'react';
const SearchIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SearchIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M85.61,98.33l24.27,24.27c5.81,5.81,17.6-7.81,13.14-12.27l-24.7-24.7c-1.04-1.04-2.87.59-2.87.59l-4.64-4.64c14.47-18.96,13.02-46.13-4.29-63.44-18.88-18.88-49.47-18.88-68.35,0-18.88,18.88-18.88,49.51,0,68.39,17.3,17.3,44.44,18.76,63.4,4.29l4.64,4.64s-1.69,1.77-.59,2.87ZM28.56,76.11c-13.09-13.09-13.09-34.42,0-47.52,13.13-13.09,34.43-13.09,47.56,0,13.09,13.09,13.09,34.43,0,47.52-13.13,13.09-34.43,13.09-47.56,0Z"
    />
  </svg>
);
export default SearchIcon;
