import {
  DatasetObjectRawDataFieldSummaryFragment,
  UpdateActualityInput,
} from '@warebee/frontend/data-access-api-graphql';
import { HeatmapMetricDescriptor } from '../../common/heatmap.types';
import { FilterPresets } from '../../filterPreset/store/filterPreset.types';
import { ActualityMetricDataRow } from '../../metrics/actuality/actualityMetric.types';
import { AssignmentMetricDataRow } from '../../metrics/assignment/assignmentMetric.types';

export const actualityAutoAnalyzeStatus = [
  'none',
  'progress',
  'done',
  'error',
] as const;
export type ActualityAutoAnalyzeStatus =
  (typeof actualityAutoAnalyzeStatus)[number];

export const actualityMainViewTypes = ['layout', 'timeline'] as const;
export type ActualityMainViewType = (typeof actualityMainViewTypes)[number];

export const actualityHeatmapTypes = ['events', 'assignment'] as const;
export type ActualityHeatmapType = (typeof actualityHeatmapTypes)[number];

export type ActualityDatasetPatch = Pick<
  UpdateActualityInput,
  'activityFeedId' | 'assignmentId' | 'layoutId' | 'itemSetId' | 'orderSetId'
>;

export const timelineFilterByPerformance = [
  'all',
  'underperform',
  'underperform20',
] as const;

export type TimelineFilterByPerformance =
  (typeof timelineFilterByPerformance)[number];

export const timelineFilterByType = [
  'user',
  'proccess',
  'performance',
] as const;

export type TimelineFilterByType = (typeof timelineFilterByType)[number];

export type DatasetExtraFields = Pick<
  DatasetObjectRawDataFieldSummaryFragment,
  'name' | 'type' | 'distinctValueCount'
>;

export type ActualityExtraMetricDescriptors = {
  assignmentMetrics: {
    descriptor: HeatmapMetricDescriptor<string, AssignmentMetricDataRow>[];
  };
};

export type ActualityExtraSettings = {
  autoRefresh: boolean;
  refreshInterval: number;
  assignmentExtraFields: DatasetExtraFields[];
  activityExtraFields: DatasetExtraFields[];
  assignmentMetricDescriptors: HeatmapMetricDescriptor<
    string,
    AssignmentMetricDataRow
  >[];
  actualityMetricDescriptors: HeatmapMetricDescriptor<
    string,
    ActualityMetricDataRow
  >[];
  filterPreset?: FilterPresets;
};

export type ActualityEntityType = 'event' | 'location' | 'item' | 'job';
export type ActualityDataSetTableType =
  | 'layout'
  | 'assignment'
  | 'item-set'
  | 'activity-feed'
  | 'jobs'
  | 'activity-metric';
