import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/actions/Button';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import { ItemListCard } from '../../dashboard/containers/ItemListCard';
import {
  AppBillingPricingCardsCategoryMode,
  AppBillingPricingCardsLinkMode,
  AppBillingPricingCardsPriceMode,
  AppBillingPricingCardsViewMode,
} from '../AppBillingPricingCards';

export type AppBillingPriceCardContainerProps = PropsWithChildren & {
  title: string;
  subtitle?: string;
  isSelected?: boolean;
  showHelper?: boolean;
  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  onCardClick: VoidFunction;
  onButtonClick: VoidFunction;
};

const AppBillingPriceCardContainer: React.FC<
  AppBillingPriceCardContainerProps
> = props => {
  const { t } = useTranslation('app');

  const cardHelper = (
    <div className={classNames('flex px-4 text-sm', 'text-menu-text/60')}>
      <div className="flex-1">{t`Not Sure, what option to pick? Let's Chat`}</div>
    </div>
  );

  return (
    <ItemListCard
      titleTrace={`Billing: Price Card ${props.title}`}
      data-component="AppBillingPriceCardContainer"
      isDemo
      fullWidth={props.modePreview === 'FULL'}
      className={classNames(
        'flex flex-col',
        'xs:w-full xs:max-w-full',
        { 'text-menu-active !bg-menu-500': !props.isSelected },
        {
          '!bg-menu-active !text-menu-active-text rounded-t-xl':
            props.isSelected,
        },
      )}
      onClick={props.modePrice === 'PRICING_SHOW' && props.onCardClick}
    >
      <>
        <header
          className={classNames(
            'sticky top-0',
            'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
            'bg-opacity-50',
            'mt-4',
            'p-4',
            'rounded-t-xl',
            props.isSelected ? 'bg-menu-active' : 'bg-menu',
          )}
        >
          {props.subtitle && (
            <div data-component="SubTitle" className="">
              {props.subtitle}
            </div>
          )}
          <h4
            className={classNames(
              'pb-2 mb-4',
              'text-3xl',
              'opacity-75',
              'border-b',
              { 'border-menu-active': !props.isSelected },
              { 'border-menu': props.isSelected },
            )}
          >
            {props.title}
          </h4>
          {props.modePrice === 'PRICING_SHOW' &&
            props.modePreview === 'PREVIEW' && (
              <Button
                className={classNames('mt-4 mb-4', 'rounded-full')}
                label={t`Continue`}
                titleTrace={`Purchase ${props.title}`}
                buttonType="purchase"
                buttonSize="xl"
                isDisabled={false}
                full
                hasIconAfter
                onPress={props.onButtonClick}
                buttonIcon={<IconArrowDir />}
              />
            )}
          {props.header}
          {props.modePrice === 'PRICING_SHOW' &&
            props.modePreview === 'FULL' && (
              <Button
                className={classNames('mt-4 mb-4', 'rounded-full')}
                label={props.modeLink === 'APP' ? t`Purchase` : t`Contact Us`}
                titleTrace={`Purchase ${props.title}`}
                buttonType="purchase"
                buttonSize="xl"
                isDisabled={false}
                full
                hasIconAfter
                onPress={props.onButtonClick}
                buttonIcon={<IconArrowDir />}
              />
            )}
        </header>
        {props.content}
        {props.children}

        {props.showHelper && { cardHelper }}
        {props.footer}
      </>
    </ItemListCard>
  );
};
export default AppBillingPriceCardContainer;
