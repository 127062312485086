import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import TitleSection from '../../components/layout/TitleSection';
import {
  pickingPolicyPickingRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { simulationUoms } from '../../simulation/store/simulation.state';

const PickingPolicyUomEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const uoms = useRecoilValue(simulationUoms);
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [pickingRule, updatePickingRule] = useRecoilState(
    pickingPolicyPickingRule({
      ruleId: selectedIdentity?.ruleId,
      pickingRuleId: selectedIdentity?.pickingRuleId,
    }),
  );

  if (!selectedIdentity || !pickingRule) return null;

  const selected = pickingRule?.uomTypes;
  const selectedSet = new Set(pickingRule?.uomTypes);

  const onSelectValue = (value: string) => {
    updatePickingRule({
      ...pickingRule,
      uomTypes: selectedSet.has(value)
        ? _.filter(selected, v => v !== value)
        : [...selected, value],
    });
  };

  return (
    <div data-component="PickingPolicyUomEditor">
      <TitleSection
        id={`policy-editor-uoms`}
        // collapsible
        title={t`UOM`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {_.map(uoms, (uom, index) => {
                return (
                  <InputCheckbox
                    isHoverable
                    className="ltr:pl-4 rtl:pr-4"
                    key={`chk-${uom}`}
                    isSelected={selectedSet.has(uom)}
                    value={uom}
                    onChange={() => onSelectValue(uom)}
                  >
                    {uom}
                  </InputCheckbox>
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default PickingPolicyUomEditor;
