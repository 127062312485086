import {
  Field,
  ID,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { ConstraintSummary } from './constraint.model';

export enum OptimizationStopReason {
  STEP_LIMIT = 'STEP_LIMIT',
  USER_REQUEST = 'USER_REQUEST',
  TARGET_IMPROVEMENT = 'TARGET_IMPROVEMENT',
  MIN_IMPROVEMENT_RATE = 'MIN_IMPROVEMENT_RATE',
  TIME_LIMIT = 'TIME_LIMIT',
}

registerEnumType(OptimizationStopReason, {
  name: 'OptimizationStopReason',
});

export enum OptimizationObjectiveType {
  COST = 'COST',
  LOAD_BALANCING = 'LOAD_BALANCING',
}

registerEnumType(OptimizationObjectiveType, {
  name: 'OptimizationObjectiveType',
});

@ObjectType()
export class OptimizationObjectiveImprovementSummary {
  @Field(() => OptimizationObjectiveType)
  type: OptimizationObjectiveType;

  @Field(() => ID, { nullable: true })
  id?: string;

  @Field()
  objectiveWeight: number;

  @Field()
  initialCost: number;

  @Field()
  optimizedCost: number;

  @Field()
  improvement: number;
}

@ObjectType()
export class OptimizationImprovementSummary {
  @Field(() => [OptimizationObjectiveImprovementSummary])
  objectives: OptimizationObjectiveImprovementSummary[];

  @Field()
  initialEffectiveCost: number;

  @Field()
  optimizedEffectiveCost: number;

  @Field()
  effectiveImprovement: number;
}

@ObjectType()
export class OptimizationSummary {
  @Field({ nullable: true })
  effectiveSteps?: number;

  @Field(() => OptimizationStopReason, { nullable: true })
  stopReason?: OptimizationStopReason;

  @Field({ nullable: true })
  totalComputeTime?: number;

  @Field({ nullable: true })
  moveCount?: number;

  @Field(() => OptimizationImprovementSummary, { nullable: true })
  improvement?: OptimizationImprovementSummary;

  @Field(() => [ConstraintSummary])
  constraints?: ConstraintSummary[];
}

@ObjectType()
export class OptimizationRunProgressDetails {
  @Field(() => OptimizationImprovementSummary, { nullable: true })
  improvement?: OptimizationImprovementSummary;
}
