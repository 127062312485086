import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import * as Icon from '../../components/icons';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import { getWeightHeatmapBuckets } from '../store/weightCompliance.helper';
import { weightComplianceShowHeatmap } from '../store/weightCompliance.state';
import { LegendItem, LegendItemProps } from './LegendItem';

const WeightComplianceToolbar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [showHeatmap, setShowHeatmap] = useRecoilState(
    weightComplianceShowHeatmap,
  );
  const getItems = () => {
    return getWeightHeatmapBuckets(t).map(config => {
      const legendProps: LegendItemProps = {
        color: config.color,
        text: config.title,
        //isDisabled: filter.hiddenBuckets?.has(bucket),
        //onClick: () => toggleBucketFilter(bucket),
      };
      return (
        <ButtonToolbar key={`legend-item-${config.id}`} disabled>
          <LegendItem {...legendProps} disabled />
        </ButtonToolbar>
      );
    });
  };
  return (
    <ButtonToolbarGroup
      className={`absolute ltr:left-1 rtl:right-1 top-1 flex flex-col`}
    >
      <TitleToolbarContainer
        id="panel-toolbar-analytics-heatmap-weight"
        title={t`Analytics`}
        collapsible
      >
        <div className="flex items-start">
          <ButtonToolbar isSelected={showHeatmap} onChange={setShowHeatmap}>
            <Icon.HeatMap9
              className="w-8 h-8 text-white"
              alt={t`Show Weight heatmap`}
              title={t`Show Weight heatmap`}
            />
          </ButtonToolbar>
        </div>
      </TitleToolbarContainer>
      {showHeatmap && (
        <TitleToolbarContainer
          id="panel-toolbar-heatmap-weight"
          title={t`Weight`}
          collapsible
          className="mt-2"
        >
          <div>{getItems()}</div>
        </TitleToolbarContainer>
      )}
    </ButtonToolbarGroup>
  );
};

export default WeightComplianceToolbar;
