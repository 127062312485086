import * as React from 'react';
const CloudSyncIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CloudSyncIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M105.08,28.07h0c-1.88-14.15-14.02-25.1-28.68-25.1-10.97,0-18.68,6.81-22.49,11.17-3.17-1.89-6.81-2.91-10.58-2.91-10.08,0-18.5,7.26-20.31,16.82-11.89,1.82-21.03,12.12-21.03,24.51,0,13.67,11.13,24.8,24.8,24.8h37.68v-21.65s-13.09,13.08-13.09,13.08v-13.45s17.85-17.83,17.85-17.83l17.85,17.84v13.45s-13.09-13.08-13.09-13.08v21.65h27.2c13.67,0,24.8-11.13,24.8-24.8,0-12.35-9.08-22.63-20.92-24.5Z"
    />
    <polygon
      strokeWidth={0}
      points="73.99 77.37 73.99 104.02 87.07 90.94 87.07 104.39 69.22 122.23 51.37 104.39 51.37 90.94 64.46 104.02 64.46 77.37 73.99 77.37"
    />
  </svg>
);
export default CloudSyncIcon;
