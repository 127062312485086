import classNames from 'classnames';
import { endOfDay, startOfDay } from 'date-fns';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorIndicator from '../../../components/ErrorIndicator';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { ButtonSwitchMultiOption } from '../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { Container } from '../../../components/layout/ContainerFlex';
import { ActionBar } from '../../../components/nav/ActionBar';
import { agentCategoriesMap } from '../../../resourcePolicy/data/categories/categories';
import { agentModelsMap } from '../../../resourcePolicy/data/models/models';
import { resourcePolicyAgentById } from '../../store/resourcePolicy.state';
import {
  simulationAnalyzeDistinctDays,
  simulationAnalyzeInterval,
} from '../../store/simulation.state';
import {
  getWorkforceDrillDownTitle,
  getWorkforceKpiTitle,
} from '../../store/workforce.helper';
import {
  workforceAgentForecastEnabled,
  workforceAgentForecastMode,
  workforceSelectedAgentId,
  workforceSelectedDrill,
  workforceSelectedKPI,
  workforceShowAdvancedDataTable,
  workforceShowAdvancedLabourEvents,
  workforceSimulateInterval,
} from '../../store/workforce.state';
import {
  WorkforceForecastMode,
  workforceDrillTypes,
  workforceKpiTypes,
} from '../../store/workforce.types';
import WorkforceAgentCharts from './WorkforceAgentCharts';
import WorkforceAgentSchedule from './WorkforceAgentSchedule';
import WorkforceAgentStats from './WorkforceAgentStats';
import WorkforceAgentTimelineDataTable from './WorkforceAgentTimelineDataTable';

type WorkforceAgentContainerProps = {
  analyzeId: string;
};

const WorkforceAgentContainer: React.FC<
  WorkforceAgentContainerProps
> = props => {
  const { t } = useTranslation('simulation');
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const showAdvancedDataTable = useRecoilValue(workforceShowAdvancedDataTable);
  const showAdvancedLabourEvents = useRecoilValue(
    workforceShowAdvancedLabourEvents,
  );
  const days = useRecoilValue(simulationAnalyzeDistinctDays);

  const [mode, setMode] = useRecoilState(workforceAgentForecastMode(agentId));
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );
  const [selectedDrill, setSelectedDrill] = useRecoilState(
    workforceSelectedDrill(agentId),
  );
  const [selectedKPI, setSelectedKPI] = useRecoilState(
    workforceSelectedKPI(agentId),
  );

  const agent = useRecoilValue(resourcePolicyAgentById(agentId));

  const analyzeInterval = useRecoilValue(simulationAnalyzeInterval);
  const [simulateInterval, setSimulateInterval] = useRecoilState(
    workforceSimulateInterval,
  );

  function setInterval([from, to]: [Date, Date]) {
    setSimulateInterval([startOfDay(from), endOfDay(to)]);
  }

  const LoadingContainer = (
    <Container hasNoScroll className={'items-center justify-center h-full'}>
      <LoadingIndicator selfCenter message={t('Loading Agent Data...')} />
    </Container>
  );

  const ErrorContainer = (
    <Container hasNoScroll className={'items-center justify-center'}>
      <ErrorIndicator
        selfCenter
        message={t`Apologies, we couldn't load the data, please re-load.`}
      />
    </Container>
  );

  const modes: WorkforceForecastMode[] = ['byDate', 'schedule'];
  const modeOptions: ButtonSwitchMultiOption[] = [
    {
      label: t`Date Forecast`,
    },
    {
      label: t`Wave Forecast`,
    },
  ];

  const agentCategory = agentCategoriesMap[agent.resourceTypeId];
  const agentModel = _.isNil(agent.metadata?.modelId)
    ? null
    : agentModelsMap[agent.metadata?.modelId];

  return (
    <Container
      col
      flex1
      fullHeight
      fullWidth
      componentName="WorkforceAgentContainer"
      hasNoScroll
    >
      {/* <StatGroup
        icon={getAgentIcon(agent?.resourceTypeId as AgentCategoryId)}
        classNameIcon="w-14 h-14"
        title={agent?.title}
        subTitle={`${agentCategory?.title} (${agentModel?.title})`}
        className="flex flex-col"
        classNameStatGroupItems={`h-full flex-1`}
      > */}

      <ActionBar
        data-component="WorkforceAgentHeader"
        className={classNames(
          'px-2 xl:px-4 py-2 flex flex-col lg:flex-row items-center',
          'z-menu',
          // 'sticky top-[3.75rem]',
          'sticky top-0',
          'bg-sidebar-header/80 backdrop-filter backdrop-blur',
          'space-y-2 lg:space-y-0',
        )}
      >
        {mode === 'byDate' && (
          <div className="flex flex-1 flex-wrap w-full space-x-1 space-y-1 md:space-y-0 md:space-x-2 lg:space-x-1 mb-4 lg:mb-0">
            <DropdownSelector
              className="bg-app-panel-dark/70 rounded-full px-2 lg:ml-2"
              classNameDropdown="flex-1"
              label={t`Labour as`}
              classNameLabel="text-xs opacity-80"
              classNameValue="text-sm"
              value={selectedKPI}
              values={[...workforceKpiTypes]}
              renderValue={item => getWorkforceKpiTitle(item, t)}
              onChange={kpi => setSelectedKPI(kpi)}
            />

            <DropdownSelector
              className="bg-app-panel-dark/70 rounded-full px-2 lg:ml-2"
              classNameDropdown="flex-1"
              label={t`Group by`}
              classNameLabel="text-xs opacity-80"
              // classNameValue="text-xs"
              value={selectedDrill}
              values={workforceDrillTypes}
              renderValue={item => getWorkforceDrillDownTitle(item, t)}
              onChange={drill => setSelectedDrill(drill)}
            />
          </div>
        )}
      </ActionBar>
      <Container
        data-component="WorkforceAgentSimulations"
        col
        flex1
        fullHeight
        fullWidth
        className={classNames('relative', 'pb-1', {
          'p-px bg-brand': isForecastSimulation,
        })}
      >
        <ErrorBoundary fallback={ErrorContainer}>
          <Suspense fallback={LoadingContainer}>
            {mode === 'byDate' && (
              <Container
                hasNoScroll
                col
                flex1
                fullHeight
                fullWidth
                className={classNames('relative pb-2', {
                  'p-px bg-brand': isForecastSimulation,
                })}
              >
                <WorkforceAgentStats analyzeId={props.analyzeId} />
                <WorkforceAgentCharts analyzeId={props.analyzeId} />
                {showAdvancedDataTable && (
                  <WorkforceAgentTimelineDataTable
                    analyzeId={props.analyzeId}
                    showAdvancedLabourEvents={showAdvancedLabourEvents}
                  />
                )}
              </Container>
            )}
          </Suspense>

          {mode === 'schedule' && (
            <Container col hasNoScroll flex1 className="w-full h-full">
              <WorkforceAgentSchedule analyzeId={props.analyzeId} />
            </Container>
          )}
        </ErrorBoundary>
      </Container>
      {/* </StatGroup> */}
    </Container>
  );
};

export default WorkforceAgentContainer;
