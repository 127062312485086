import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from './inputs/InputSearch';
// import * as Icon from '../components/icons';

export type DashboardActionbarProps = {
  children?: React.ReactNode;
  search?: boolean;
};

export const DashboardActionbar = ({
  children,
  search,
}: DashboardActionbarProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <div
      data-component="DashboardActionbar"
      id={'DashboardActionbar-' + id}
      className={classNames(
        'flex items-center w-full',
        'bg-app-panel-dark/60',
        children && 'p-2',
      )}
    >
      {children}
      {/* <Spacer flexspace /> */}
      {search && <InputSearch placeholder={t`Search`} />}
    </div>
  );
};
