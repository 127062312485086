import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDateTime } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { DatasetNavigation } from '../../components/importer/DatasetActionHelpers';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { importTriggeredBySim } from '../../store/global.state';
import {
  warehouseAllOrderSetsMeta,
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { useLoadAllOrderSetsMeta } from '../../warehouse/hooks/useLoadAllOrderSetsMeta';
import {
  HelperWidgetImportInitialSetup,
  HelperWidgetImportLocked,
  HelperWidgetImportSelector,
  HelperWidgetNextStep,
} from '../helper/HelperWidgets';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationShowDatasetAsTable,
} from '../store/simulation.state';

const SimulationDatasetPanelOrders: React.FC = () => {
  const { t } = useTranslation('simulation');
  const navigate = useNavigate();
  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const updateSimulation = useUpdateSimulation();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const whId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const canChange = useRecoilValue(simulationIsEditable);
  const [showAsTable, setShowAsTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );
  const orderSetsMeta = useRecoilValue(warehouseAllOrderSetsMeta);
  const loadOrderSetsList = useLoadAllOrderSetsMeta();
  useEffect(() => {
    loadOrderSetsList(whId);
  }, []);

  const proceedWithImport = () => {
    setImportTriggerSimId(simulation.id);
    navigate(`/wh/i/${whId}/import/orders`);
  };

  const changeOrderSet = (orderSetId: string) => {
    setSimulation({
      ...simulation,
      orderSet: { id: orderSetId },
    });
    updateSimulation({ orderSetId });
  };
  const orderSets = orderSetsMeta.items;

  const orderSetMap = _.keyBy(orderSets, a => a.id);
  const isLoading =
    orderSetsMeta.status === AsyncLoadStatus.None ||
    orderSetsMeta.status === AsyncLoadStatus.Loading;

  const datasetSelectorMenu = () => {
    return (
      <div className="flex flex-col">
        <div
          className={classNames(
            'group',
            'flex items-center',
            'py-1 lg:py-2',
            canChange ? 'px-4' : 'px-1',
          )}
        >
          <DropdownSelector
            value={
              simulation?.orderSet?.id || t`No Orders Selected, Click to Select`
            }
            values={_.keys(orderSetMap)}
            renderValue={v => orderSetMap[v]?.title || v}
            valueHelper={v =>
              formatDateTime(new Date(orderSetMap[v]?.updatedAt))
            }
            hasSubAction
            subAction={t`Import Order Set`}
            DropAlignRight
            widthFull
            valuePrimary
            multiline
            light
            onChange={changeOrderSet}
            onSubActionClick={proceedWithImport}
            disabled={!canChange || !canUpdate}
            border={canChange || canUpdate}
            hasSearch
            hasSearchLabel={t`Order Sets`}
          />
          {simulation?.orderSet?.id && (
            <ButtonToolbar
              titleTrace={`Order Datatable`}
              className={classNames(
                'ltr:ml-2 rtl:mr-2',
                showAsTable === 'order-set' ? '' : 'hidden group-hover:block',
              )}
              label={t`Show Data`}
              isBoxed
              isSelected={showAsTable === 'order-set'}
              onChange={() =>
                setShowAsTable(showAsTable === 'order-set' ? null : 'order-set')
              }
            >
              {showAsTable === 'order-set' ? (
                <Icon.ShowTable
                  className={classNames('h-9 w-9 lg:h-10 lg:w-10', 'p-0.5')}
                />
              ) : (
                <Icon.HideTable
                  className={classNames('h-9 w-9 lg:h-10 lg:w-10', 'p-0.5')}
                />
              )}
            </ButtonToolbar>
          )}
        </div>
        <DatasetNavigation
          dataType={'orders'}
          datasetId={simulation?.orderSet?.id}
        />
      </div>
    );
  };

  return (
    <>
      <ScreenTitle
        title={t`Orders`}
        subtitle={t`Simulation Dataset`}
        isSticky
        helpNavTo={'simulation/dataset/simulation-dataset-orders'}
        icon={Icon.DataOrders}
      />

      {isLoading ? (
        <LoadingIndicator message={t`Loading Orders`} selfCenter />
      ) : orderSetsMeta.status === AsyncLoadStatus.Error ? (
        <ErrorIndicator selfCenter message={t`Cannot load Order Set`} />
      ) : !_.isEmpty(orderSets) ? (
        !canChange ? (
          <HelperWidgetImportLocked title={t`Orders`}>
            {datasetSelectorMenu()}
          </HelperWidgetImportLocked>
        ) : (
          <>
            <HelperWidgetImportSelector title={t`Orders`} />
            {datasetSelectorMenu()}
          </>
        )
      ) : (
        <HelperWidgetImportInitialSetup
          dataType="orders"
          title={t`Import Order Set (csv)`}
        />
      )}

      {simulation?.orderSet?.id && (
        <HelperWidgetNextStep title={t`Item Set import`} hasDataIssues />
      )}
    </>
  );
};

export default SimulationDatasetPanelOrders;
