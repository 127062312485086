const ImportMapIcon = props => (
  <svg
    aria-hidden="true"
    data-name="ImportMapIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m102.74,4.15v8.01s-95.87,0-95.87,0c-.42,0-.82.17-1.11.46-.29.29-.46.7-.46,1.11v11.42c0,.87.7,1.57,1.57,1.57h95.87s0,8.02,0,8.02c0,.68.4,1.3,1.02,1.59.62.28,1.35.18,1.87-.27l17.74-15.3h0c.39-.33.61-.82.61-1.32s-.22-.99-.61-1.33l-17.73-15.3c-.52-.45-1.26-.56-1.88-.27-.63.29-1.03.91-1.02,1.6h0Z" />
    <path d="m59.66,66.51c.27-.37.38-.83.29-1.28-.09-.45-.36-.84-.75-1.09-6.7-4.2-14.43-6.43-22.34-6.45H6.87c-.42,0-.82.17-1.11.46-.29.29-.46.69-.46,1.11v11.41c0,.87.7,1.57,1.57,1.57h30c4.87,0,9.65,1.3,13.86,3.76.71.41,1.61.22,2.1-.43,1.64-2.15,5.09-6.74,6.83-9.06h0Z" />
    <path d="m82.12,89.75c-.24-.27-.59-.42-.95-.4-.36.01-.7.19-.91.48l-3.32,4.4c-1.32,1.75-2.75,3.42-4.29,4.98-.47.46-.48,1.21-.02,1.69,7.94,8.09,18.78,12.66,30.12,12.69v8.05c0,.69.4,1.31,1.03,1.6.62.28,1.36.18,1.88-.27l17.74-15.3c.39-.33.61-.82.61-1.33s-.22-.99-.61-1.33l-17.74-15.31c-.52-.45-1.25-.55-1.87-.26-.62.29-1.02.91-1.02,1.59v8.02c-7.89-.02-15.39-3.4-20.63-9.3h0Z" />
    <path d="m102.74,42.39v8.01s-.41,0-.41,0c-13.11.15-25.4,6.37-33.29,16.84l-2.22,2.96-7.9,10.53c-.33.45-.66.86-1.01,1.27h0c-5.25,6.17-12.94,9.74-21.04,9.76H6.87c-.42,0-.82.17-1.11.46-.29.3-.46.7-.46,1.11v11.44c0,.87.7,1.57,1.57,1.57h30c11.25-.03,22.02-4.54,29.95-12.53,1.35-1.35,2.61-2.8,3.76-4.32l5.62-7.49,4.53-6.02c5.22-6.92,13.38-11,22.05-11.02v8.03c.01.67.41,1.28,1.03,1.56.61.28,1.33.18,1.85-.25l17.74-15.3c.39-.33.61-.82.61-1.33s-.22-.99-.61-1.33l-17.74-15.28c-.52-.45-1.25-.56-1.88-.27-.62.29-1.03.91-1.02,1.6h0Z" />
  </svg>
);
export default ImportMapIcon;
