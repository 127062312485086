import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import _ from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import BayLocationLayerBase, {
  BayLocationLayerBaseProps,
} from '../../../../../layout/bayViewer/layers/BayLocationLayerBase';
import { converterEditableArea } from '../../store/converter.area.state';
import {
  converterLayoutSelectedBay,
  converterLayoutSelectedLocationId,
} from '../../store/converter.layout.state';
import {
  converterBayTypesBuilder,
  converterLocationTypesBuilder,
} from '../../store/converter.state';
import { converterBayViewerHoveredLocation } from '../store/converterBayViewer.state';

const ConverterBayLocationLayer: React.FC = () => {
  const area = useRecoilValue(converterEditableArea);
  const bay = useRecoilValue(converterLayoutSelectedBay);
  const locationTypesBuilder = useRecoilValue(converterLocationTypesBuilder);
  const bayTypes = useRecoilValue(converterBayTypesBuilder);
  const [selectedId, setSelectedId] = useRecoilState(
    converterLayoutSelectedLocationId,
  );
  const setHovered = useSetRecoilState(converterBayViewerHoveredLocation);
  if (!bay || !area) return null;

  const locations = _.keyBy(bay.locations, l => l.locationId);
  if (!locations) return null;

  const config: BayLocationLayerBaseProps = {
    stageId: 'converter-bay-view',
    bay,
    locations,
    bayType: bayTypes[bay.details.bayType ?? DEFAULT_BAY_TYPE],
    locationTypes: locationTypesBuilder,
    selectedLocationId: selectedId,

    onHover: setHovered,
    onSelect: l => setSelectedId(l?.locationId),
  };

  return <BayLocationLayerBase {...config} />;
};

export default ConverterBayLocationLayer;
