import * as React from 'react';
const CircleEyeSideIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleEyeSideIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M126.43,64c0-33.22-26.35-60.28-59.29-61.44-.21,0-.43,0-.64-.02-.52-.01-1.04-.04-1.57-.04-.06,0-.12,0-.18,0-.07,0-.15,0-.22,0C30.57,2.5,3.03,30.03,3.03,64s27.54,61.5,61.5,61.5c.08,0,.15,0,.23,0,.06,0,.11,0,.17,0,.34,0,.68-.02,1.02-.03.36,0,.72-.01,1.07-.03,32.99-1.11,59.41-28.18,59.41-61.45ZM102.43,44.34c-12.33,0-22.39,10.05-22.39,22.39s10.05,22.39,22.39,22.39c5.31,0,10.19-1.86,14.03-4.97-1.44,3.67-3.26,7.16-5.41,10.4-7.66,2.92-16.15,4.89-25.13,4.89-34.18,0-61.26-28.42-62.47-29.63-1.61-1.74-1.61-4.42,0-6.17,1.21-1.21,28.29-29.63,62.47-29.63,10.57,0,20.46,2.72,29.12,6.51,1.64,3.48,2.92,7.16,3.8,11-4.09-4.41-9.93-7.18-16.41-7.18Z"
    />
  </svg>
);
export default CircleEyeSideIcon;
