import * as React from 'react';
const PickingAgentStandingIsoIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PickingAgentStandingIsoIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M80.83,78.13c2.24-1.98,2.79-5.62,2.79-6.33V29.06h0c0-2.98-2.39-5.39-5.36-5.45h-8.99l1.2-8.69-.06-.03c6.76-.43.29-2.42.29-3.22,0-1.84-2.34-7.71-7.52-7.71s-7.52,5.87-7.52,7.71c0,.81-6.39,2.79.29,3.22l-.06.03,1.2,8.69h-7.74c-2.97.06-5.36,2.47-5.36,5.45h0v42.74c0,.98,0,7.61,5.43,7.61l-2.72,40.79h-1.67c-3.12,0-3.12,3.75-3.12,3.75h17.1l4.17-36.09,3.76,36.09h16.69s0-3.13-2.09-3.13h-2.09l-2.03-41.12M49.56,77.48h-.02.02s0,0,0,0ZM49.53,77.48c-.28,0-3.78-.18-3.78-4.62v-3.57h4.25l-.47,8.19ZM66.99,21.55c-.23,1.8-2.19,2.06-2.19,2.06h-3.27s-1.96-.26-2.19-2.06c-.18-1.39-.69-3.6-.91-6.53,1.39.01,3.01-.03,4.73-.03s3.34.04,4.73.03c-.22,2.93-.73,5.15-.91,6.53ZM77.3,77.48l-.35-7.35h-.01v-.19s0-.01,0-.01h0l-.02-.63h4.31v3.57c0,4.62-3.92,4.62-3.92,4.62Z"
    />
  </svg>
);
export default PickingAgentStandingIsoIcon;
