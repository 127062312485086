import * as React from 'react';
const ChevronDualCloseHorizontalIcon = props => (
  <svg
    aria-hidden="true"
    data-name="ChevronDualCloseHorizontalIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m36.44,64l-25.85,25.84c-.52.52-.67,1.29-.39,1.97.28.68.94,1.12,1.67,1.12h14.93c.5,0,.98-.21,1.32-.57l25.32-27.12c.65-.69.65-1.77,0-2.47l-25.32-27.12c-.34-.37-.82-.57-1.32-.57h-14.93c-.73,0-1.39.44-1.67,1.12-.28.68-.13,1.45.39,1.97l25.85,25.85Z" />
    <path d="m91.56,64l25.85-25.85c.52-.52.67-1.29.39-1.97-.28-.68-.94-1.12-1.67-1.12h-14.93c-.5,0-.98.21-1.32.57l-25.32,27.12c-.65.69-.65,1.77,0,2.47l25.32,27.12c.34.37.82.57,1.32.57h14.93c.73,0,1.39-.44,1.67-1.12.28-.68.13-1.45-.39-1.97l-25.85-25.85Z" />
  </svg>
);
export default ChevronDualCloseHorizontalIcon;
