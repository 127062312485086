import {
  LocationFilterFragment,
  LocationFilterIntersectionFragment,
  LocationFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterFieldConfigBase } from '../common/types';
import { PolicyFilterBase } from '../policyFilters/policyFilter.types';
import PolicyFilterValueEditor, {
  PolicyFilterValueEditorProps,
} from '../policyFilters/PolicyFilterValueEditor';
import { LoadLocationFilterValuesParams } from '../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import {
  filterPresetLocationFilterIntersection,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

export type FilterPresetLocationFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<LocationFilterType>;
  startLoadData: (
    params: Partial<LoadLocationFilterValuesParams>,
  ) => Promise<void>;
  cancelLoadData: (filterKey: string) => void;
};

const FilterPresetLocationFilterEditor: React.FC<
  FilterPresetLocationFilterEditorProps
> = props => {
  const { t } = useTranslation('simulation');
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;

  const editableFilterSetIdentity = useRecoilValue(
    filterPresetSelectedIdentity,
  );

  const [filterIntersection, setFilterIntersection] = useRecoilState(
    filterPresetLocationFilterIntersection(editableFilterSetIdentity),
  );

  if (!editableFilterSetIdentity || !filterIntersection) return null;

  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const filterConfigs = getLocationFilterConfigCommon(t);
  const onSelectValue = (value: PolicyFilterBase<string>) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);

    // If all values were deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn || value.stringRange || value.range) ||
      value.isNot
    ) {
      others.push(value as LocationFilterFragment);
    }

    const newIntersection: LocationFilterIntersectionFragment = {
      id: filterIntersection.id,
      allOf: others,
    };

    setFilterIntersection(newIntersection);

    startLoadData({
      triggeredField: type,
      filterConfigs,
      affect: 'others',
      filterIntersection: newIntersection,
    });
  };

  const editorProps: PolicyFilterValueEditorProps<LocationFilterType> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params =>
      startLoadData({
        ...params,
        triggeredField: type,
        filterConfigs,
        affect: 'self',
        filterIntersection,
      }),
    cancelLoadData,
  };

  return <PolicyFilterValueEditor {...editorProps} />;
};

export default FilterPresetLocationFilterEditor;
