import { Container } from '../../components/layout/ContainerFlex';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import SimulationDatasetPanelAssignment from '../panels/SimulationDatasetPanelAssignment';
import { simulationCurrent } from '../store/simulation.state';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDatasetAssignment: React.FC = () => {
  const simulation = useRecoilValue(simulationCurrent);
  const { t } = useTranslation('simulation');

  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <Container col hasOverflowY hasExtraPadding>
        <Suspense
          fallback={
            <LoadingIndicator
              selfCenter
              message={t`Loading Assignment Dataset...`}
            />
          }
        >
          <SimulationDatasetPanelAssignment />
        </Suspense>
      </Container>
      <ActionBarNextStep isDisabled={_.isNil(simulation?.assignment?.id)} />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-dataset-assignment',
    title: t`Dataset > Assignment`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDatasetAssignment />
    </SidebarContainer>
  );
};
