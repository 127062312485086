import {
  AllocationRequirementSortBy,
  AllocationRequirementsFilter,
  LoadAllocationRequirementItemsDocument,
  LoadAllocationRequirementItemsQuery,
  LoadAllocationRequirementItemsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { secureClient } from '../GraphQLClient';
import { DATASET_VIEW_PAGE_SIZE } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import {
  allocationRequirementItems,
  allocationRequirementItemsLoadStatus,
} from '../simulation/store/allocation/allocationRequirement.state';
import { errorAppender } from '../store/error.state';

export type LoadAllocationRequirementItemsParams = {
  simulationId: string;
  limit?: number;
  skip?: number;
  isAppend?: boolean;
  filter?: AllocationRequirementsFilter;
  sortBy?: AllocationRequirementSortBy[];
};

function useLoadAllocationRequirementItems() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load allocation requirement results`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(allocationRequirementItemsLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAllocationRequirementItemsParams) => {
        const current = await snapshot.getPromise(allocationRequirementItems);
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRequirementItemsLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadAllocationRequirementItemsQuery,
          LoadAllocationRequirementItemsQueryVariables
        >({
          query: LoadAllocationRequirementItemsDocument,
          variables: {
            simulationId: params.simulationId,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip || 0,
            },
            filter: params.filter,
            sortBy: params.sortBy,
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const locationData =
              data?.simulation?.allocationRequirementSet?.requirements;
            let content = locationData?.content ?? [];

            if (params.isAppend) {
              content = [...current.content, ...content];
            }

            set(allocationRequirementItems, {
              totalCount: locationData?.totalCount ?? 0,
              content,
            });
            set(allocationRequirementItemsLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAllocationRequirementItemsParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAllocationRequirementItems;
