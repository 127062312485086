import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../../../common/useFormatter';
import { StatListItem } from '../../../../components/stats/StatListItem';
import { AgentForecastWorkWindow } from '../../../store/workforce.types';

export type WorkforceShiftTooltipProps = {
  workWindow: AgentForecastWorkWindow;
};

const WorkforceShiftTooltip: React.FC<WorkforceShiftTooltipProps> = props => {
  const { t } = useTranslation('simulation');
  const { workWindow } = props;
  const formatter = useFormatter();

  const from = format(workWindow.from, 'hh:mm');
  const to = format(workWindow.to, 'hh:mm');
  return (
    <div className="min-w-tooltip">
      <div
        className={classNames(
          'flex items-center p-1 px-2 text-xs uppercase bg-menu-active text-menu-active-text',
        )}
      >
        {t`Shift`}
      </div>
      <div
        className={classNames(
          'flex flex-col p-1 px-2 text-sm font-bold text-start bg-app-panel-dark text-menu-text',
        )}
      >
        <StatListItem
          titleClass={classNames('uppercase text-xxs opacity-75')}
          title={t`From`}
          value={from}
        />
        <StatListItem
          titleClass={classNames('uppercase text-xxs opacity-75')}
          title={t`To`}
          value={to}
        />
      </div>
    </div>
  );
};

export default WorkforceShiftTooltip;
