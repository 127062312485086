import * as React from 'react';
const CalendarResetIcon = props => (
  <svg
    aria-hidden="true"
    data-name="CalendarResetIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m26.14,4h7.51v8.84h-7.51V4Z" />
    <path d="m59.52,4h7.51v8.84h-7.51V4Z" />
    <path d="m92.91,4h7.51v8.84h-7.51V4Z" />
    <path d="m26.14,26.54v-13.7H4.15v23.43h119.49V12.84h-23.22v13.7h-7.51v-13.7h-25.87v13.7h-7.51v-13.7h-25.87v13.7h-7.51Z" />
    <path d="m87.03,43.78c11.47,7.52,19.06,20.48,19.06,35.22,0,23.24-18.84,42.09-42.09,42.09s-42.08-18.84-42.08-42.09c0-14.74,7.58-27.7,19.06-35.22H4.15v70.22c0,5.52,4.48,10,10,10h99.71c5.52,0,10-4.48,10-10V43.78h-36.83Z" />
    <path d="m64.95,108.73c-5.76,0-11.26-1.71-15.9-4.9l6.09-8.61c2.88,1.93,6.26,2.97,9.81,2.97,4.72,0,9.15-1.83,12.47-5.16,6.88-6.88,6.88-18.07,0-24.94-3.33-3.33-7.75-5.16-12.47-5.16s-9.14,1.83-12.47,5.16c-2.63,2.63-4.32,5.94-4.92,9.54l11.4-4.09-17.23,24.48-15.8-24.51,10.98,4.19c.64-6.46,3.46-12.44,8.1-17.08,5.31-5.31,12.39-8.24,19.93-8.24s14.61,2.93,19.93,8.24c10.99,10.99,10.99,28.87,0,39.85-5.31,5.31-12.39,8.24-19.93,8.24Z" />
  </svg>
);
export default CalendarResetIcon;
