import * as React from 'react';
const SimulationOptimizeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationOptimizeIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="73.29 94.99 64.52 89.14 55.75 94.99 64.52 100.89 73.29 94.99"
    />
    <polygon
      strokeWidth={0}
      points="93.66 82.76 84.89 76.9 76.12 82.76 84.89 88.65 93.66 82.76"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="119.64 70.52 110.87 64.66 102.1 70.52 110.87 76.41 119.64 70.52"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="119.64 52.4 110.87 46.54 102.1 52.4 110.87 58.3 119.64 52.4"
    />
    <path
      strokeWidth={0}
      d="M63.6,95.94l11.11-18.11-10.9-5.26,20.71-11.25-20.68-11.23,10.69-5.58-10.9-17.76,21.38,12.41,25.88,12.41.98-.36-7.98,9.44,7.72,8.8.73.23-27.12,13.05-.09.11-.05-.03-21.46,13.11ZM75.24,78.09l-9.81,15.99,18.95-11.58-9.14-4.41ZM75.58,77.54l9.4,4.54,13.62-16.11-13.3-4.27-9.72,15.84ZM99.22,66.17l-13,15.38,24.37-11.73-11.37-3.65ZM65.22,72.54l9.83,4.75,9.28-15.13-19.11,10.38ZM99.68,65.64l10.81,3.47-7-7.98-3.81,4.51ZM86.18,61.31l12.87,4.13,4.03-4.77-3.68-4.2-13.22,4.84ZM75.39,44.79l9.91,16.15,13.65-4.99-14.11-16.09-9.45,4.93ZM65.2,50.1l19.14,10.4-9.47-15.44-9.66,5.04ZM100.01,56.25l3.47,3.96,6.39-7.56-9.86,3.61ZM86.12,40.42l13.43,15.31,10.49-3.84-23.91-11.47ZM65.4,28.52l9.65,15.72,9.18-4.79-18.83-10.93Z"
    />
    <polygon
      strokeWidth={0}
      points="7.56 70.52 16.33 64.66 25.1 70.52 16.33 76.41 7.56 70.52"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="7.56 52.4 16.33 46.54 25.1 52.4 16.33 58.3 7.56 52.4"
    />
    <polygon
      strokeWidth={0}
      points="51.08 61.31 42.31 55.45 33.54 61.31 42.31 67.2 51.08 61.31"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="33.54 82.76 42.31 76.9 51.08 82.76 42.31 88.65 33.54 82.76"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M42.14,82.83l-.05.03-.09-.11-27.12-13.05.73-.23,7.72-8.8-7.98-9.44.98.36,25.88-12.41,21.38-12.41-10.9,17.76,10.69,5.58-20.68,11.23,20.71,11.25-10.9,5.26,11.11,18.11-21.46-13.11ZM42.81,82.5l18.95,11.58-9.81-15.99-9.14,4.41ZM41.9,61.7l-13.3,4.27,13.62,16.11,9.4-4.54-9.72-15.84ZM16.61,69.82l24.37,11.73-13-15.38-11.37,3.65ZM42.87,62.15l9.28,15.13,9.83-4.75-19.11-10.38ZM23.71,61.13l-7,7.98,10.81-3.47-3.81-4.51ZM27.8,56.47l-3.68,4.2,4.03,4.77,12.87-4.13-13.22-4.84ZM42.37,39.86l-14.11,16.09,13.65,4.99,9.91-16.15-9.45-4.93ZM52.34,45.06l-9.47,15.44,19.14-10.4-9.66-5.04ZM17.33,52.64l6.39,7.56,3.47-3.96-9.86-3.61ZM17.16,51.89l10.49,3.84,13.43-15.31-23.91,11.47ZM42.97,39.45l9.18,4.79,9.65-15.72-18.83,10.93Z"
    />
    <polygon
      strokeWidth={0}
      points="72.77 73.06 64 67.2 55.23 73.06 64 78.96 72.77 73.06"
    />
    <polygon
      strokeWidth={0}
      points="94.3 64.35 85.53 58.49 76.76 64.35 85.53 70.24 94.3 64.35"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="73.66 51.76 64.89 45.9 56.12 51.76 64.89 57.66 73.66 51.76"
    />
    <polygon
      strokeWidth={0}
      points="120.28 33.99 111.51 28.13 102.74 33.99 111.51 39.89 120.28 33.99"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="76.76 21.45 85.53 15.59 94.3 21.45 85.53 27.34 76.76 21.45"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="56.39 9.21 65.16 3.35 73.93 9.21 65.16 15.11 56.39 9.21"
    />
    <polygon
      strokeWidth={0}
      points="76.76 42.9 85.53 37.04 94.3 42.9 85.53 48.79 76.76 42.9"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="56.45 30.49 65.22 24.63 73.99 30.49 65.22 36.38 56.45 30.49"
    />
    <path
      strokeWidth={0}
      d="M64.24,77.53l11.11-18.11-10.9-5.26,20.71-11.25-20.68-11.23,10.69-5.58-10.9-17.76,21.38,12.41,25.88,12.41.98-.36-7.98,9.44,7.72,8.8.73.23-27.12,13.05-.09.11-.05-.03-21.46,13.11ZM75.88,59.68l-9.81,15.99,18.95-11.58-9.14-4.41ZM76.22,59.13l9.4,4.54,13.62-16.11-13.3-4.27-9.72,15.84ZM99.86,47.76l-13,15.38,24.37-11.73-11.37-3.65ZM65.86,54.13l9.83,4.75,9.28-15.13-19.11,10.38ZM100.32,47.23l10.81,3.47-7-7.98-3.81,4.51ZM86.82,42.9l12.87,4.13,4.03-4.77-3.68-4.2-13.22,4.84ZM76.03,26.38l9.91,16.15,13.65-4.99-14.11-16.09-9.45,4.93ZM65.84,31.69l19.14,10.4-9.47-15.44-9.66,5.04ZM100.65,37.84l3.47,3.96,6.39-7.56-9.86,3.61ZM86.76,22.01l13.43,15.31,10.49-3.84-23.91-11.47ZM66.04,10.11l9.65,15.72,9.18-4.79-18.83-10.93Z"
    />
    <polygon
      strokeWidth={0}
      points="8.63 33.99 17.4 28.13 26.17 33.99 17.4 39.89 8.63 33.99"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="52.15 21.45 43.38 15.59 34.61 21.45 43.38 27.34 52.15 21.45"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="52.15 42.9 43.38 37.04 34.61 42.9 43.38 48.79 52.15 42.9"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M43.21,64.42l-.05.03-.09-.11-27.12-13.05.73-.23,7.72-8.8-7.98-9.44.98.36,25.88-12.41,21.38-12.41-10.9,17.76,10.69,5.58-20.68,11.23,20.71,11.25-10.9,5.26,11.11,18.11-21.46-13.11ZM43.89,64.09l18.95,11.58-9.81-15.99-9.14,4.41ZM42.97,43.29l-13.3,4.27,13.62,16.11,9.4-4.54-9.72-15.84ZM17.68,51.41l24.37,11.73-13-15.38-11.37,3.65ZM43.94,43.74l9.28,15.13,9.83-4.75-19.11-10.38ZM24.79,42.72l-7,7.98,10.81-3.47-3.81-4.51ZM28.87,38.06l-3.68,4.2,4.03,4.77,12.87-4.13-13.22-4.84ZM43.44,21.45l-14.11,16.09,13.65,4.99,9.91-16.15-9.45-4.93ZM53.41,26.65l-9.47,15.44,19.14-10.4-9.66-5.04ZM18.4,34.23l6.39,7.56,3.47-3.96-9.86-3.61ZM18.23,33.48l10.49,3.84,13.43-15.31-23.91,11.47ZM44.04,21.04l9.18,4.79,9.65-15.72-18.83,10.93Z"
    />
    <path
      strokeWidth={0}
      d="M.29,83.1l63.93,36.91,63.5-36.9-9.55-5.51-52.95,30.77h-2.16s-53.3-30.77-53.3-30.77L.29,83.1Z"
      opacity={0.5}
    />
  </svg>
);
export default SimulationOptimizeIcon;
