import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonToolbar from '../../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../../components/actions/ButtonToolbarGroup';
import * as Icon from '../../../components/icons';
import { StageType } from '../../../layout/stage/stage.helper';
import { stageStateById } from '../../../layout/stage/stage.state';

export type WorkforceToolbarLayerProps = {
  stageId: StageType;
};

const defaultHeight = 24 * 60;
const WorkforceToolbarLayer: React.FC<WorkforceToolbarLayerProps> = props => {
  const { t } = useTranslation('simulation');
  const [stageState, setStageState] = useRecoilState(
    stageStateById(props.stageId),
  );

  const resetHeight = () => {
    const scale = stageState.size[1] / defaultHeight;
    setStageState({
      position: [
        (stageState.position[0] * scale) / stageState.scale,
        0.5 * defaultHeight * scale,
      ],
      scale,
    });
  };
  return (
    <ButtonToolbarGroup
      className={`absolute ltr:right-1 rtl:left-1 bottom-1 z-[5001] isolate`}
      //  z-[5001] to be above the Alert of z-5000
    >
      <ButtonToolbar titleTrace={`Reset Zoom`} onChange={resetHeight}>
        <Icon.FitToBox
          className="w-8 h-8 text-white"
          alt={t`Reset zoom and position`}
          title={t`Reset zoom and position`}
        />
      </ButtonToolbar>
    </ButtonToolbarGroup>
  );
};

export default WorkforceToolbarLayer;
