import { format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';

import { TimelineDatum } from './WorkforceAgentTimeline';

export type WorkforceAgentTimelineTableProps = {
  data: TimelineDatum[];
  series: string[];

  formatValue: (value: number) => string;
  getDimensionTitle: (dimensionKey: string) => string;
  getDimensionColor: (dimensionKey: string) => [string, string];
};

const WorkforceAgentTimelineTable: React.FC<
  WorkforceAgentTimelineTableProps
> = props => {
  const { t } = useTranslation('simulation');
  const searchValues = {};

  const columnsConfig: ColumnConfig<TimelineDatum>[] = [
    {
      field: 'date',
      title: t`Date`,
      isHeader: true,
      render: (n: number) => format(new Date(n), 'dd MMMM, yyyy'),
    },
    ..._.map(props.series, dim => ({
      field: dim,
      title: props.getDimensionTitle(dim),
      render: val => (
        <div
          data-component="columnsConfig"
          className="flex"
          // style={{
          //   // color: props.getDimensionColor(dim)[0],
          //   backgroundColor: props.getDimensionColor(dim)[1],
          // }}
        >
          {!val ? (
            <span className="opacity-50">{`—`}</span>
          ) : (
            <>
              <div
                data-component="TableLegend"
                className={`ltr:mr-2 rtl:ml-2 w-2 h-4 rounded-sm border-2`}
                style={{
                  borderColor: props.getDimensionColor(dim)[0],
                  background: props.getDimensionColor(dim)[0],
                }}
              />
              <span
                title={`Value Raw: ${val}`}
                data-component="tableValue"
                className="flex-1 select-text"
              >
                {props.formatValue(val)}
              </span>
            </>
          )}
        </div>
      ),
    })),
  ];

  async function startExportCSV() {
    // const { data, errors } = await runExportCSV({
    //   variables: {
    //     id: agent.id,
    //   },
    // });
    //
    return {
      errors: [],
      job: [],
    };
  }

  const totalCount = props.data.length;
  return (
    <>
      <DatasetTable
        id={'agent-timeline-table'}
        // title={t`Title`}
        // subtitle={t`Subtitle`}
        isSticky
        columnsConfig={columnsConfig}
        keyFields={['date']}
        data={props.data}
        onLoadNext={_.noop}
        onSearch={_.noop} // setSearchValues}
        totalCount={totalCount}
        searchValues={searchValues}
        hideScreenTitle={true}
        // onStartExportClick={startExportCSV}
      />
    </>
  );
};

export default WorkforceAgentTimelineTable;
