import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';

const StepMeasurementSystem: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const wh = useRecoilValue(warehouseSelected);

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );
  const volumeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'volume'),
    tMeasures,
  );

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Layout Setup`}
        title={t`System of Measurement`}
      />

      <InputQA>
        <InputQuestion
          icon={Icon.Settings4}
          question={t`Warehouse system of measurement`}
        />

        <InputAnswerGroup>
          <HelpContainer id={'designer/01-system-of-measurement'}>
            <div className="mt-4">
              <div className="flex items-center py-2 border-b border-opacity-50 border-menu-400">
                <div className="text-xs uppercase text-menu-text">
                  {t`System:`}
                </div>
                <div className="ml-2 px-2 py-1 rounded text-xxs bg-menu-500/50 text-menu-active">
                  {wh.measurementSystem}
                </div>
              </div>
              <div className="flex items-center py-2 border-b border-opacity-50 border-menu-400">
                <div className="text-xs uppercase text-menu-text">
                  {t`Dimensions measured in:`}
                </div>
                <div className="ml-2 px-2 py-1 rounded text-xxs bg-menu-500/50 text-menu-active">
                  {sizeMeasureValue}
                </div>
              </div>
              <div className="flex items-center py-2 border-b border-opacity-50 border-menu-400">
                <div className="text-xs uppercase text-menu-text">
                  {t`Volume measured in:`}
                </div>
                <div className="ml-2 px-2 py-1 rounded text-xxs bg-menu-500/50 text-menu-active">
                  {volumeMeasureValue}
                </div>
              </div>
            </div>
          </HelpContainer>
        </InputAnswerGroup>
      </InputQA>
    </PanelBody>
  );
};

export default StepMeasurementSystem;
