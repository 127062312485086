import {
  AllocationItemPriority,
  AllocationLocationPriority,
  AllocationRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import OrderedList, {
  OrderedListItem,
  OrderedListProps,
} from '../../../../components/actions/OrderedList';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  getItemsPriorityOptions,
  getLocationPriorityOptions,
  getPriorityDirectionOptions,
  getPriorityValue,
} from '../../../store/allocation/allocation.helper';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

const AllocationPolicyPrioritiesEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  function updateAllocationSettings(patch: Partial<AllocationRuleFragment>) {
    updateRule(current => ({
      ...current,
      ...patch,
    }));
  }

  const prioritySettings = rule?.prioritySettings;

  const locationPriorityOptions = getLocationPriorityOptions(t);
  const itemsPriorityOptions = getItemsPriorityOptions(t);
  const directionOptions = getPriorityDirectionOptions(t);

  function updateLocationPriority(
    items: OrderedListItem<AllocationLocationPriority>[],
  ) {
    updateAllocationSettings({
      prioritySettings: {
        ...rule?.prioritySettings,
        locationPriority: _.map(items, i => ({
          priority: i.type,
          direction: i.direction as any,
        })),
      },
    });
  }

  const locationSortOrderProps: OrderedListProps<AllocationLocationPriority> = {
    current: _.map(prioritySettings?.locationPriority, p => ({
      type: p.priority,
      direction: p.direction,
    })),
    options: locationPriorityOptions,
    onChange: updateLocationPriority,
  };

  function updateItemPriority(
    items: OrderedListItem<AllocationItemPriority>[],
  ) {
    updateAllocationSettings({
      prioritySettings: {
        ...rule?.prioritySettings,
        itemPriority: _.map(items, i => ({
          priority: i.type,
          direction: i.direction as any,
        })),
      },
    });
  }

  const itemsSortOrderProps: OrderedListProps<AllocationItemPriority> = {
    current: _.map(prioritySettings?.itemPriority, p => ({
      type: p.priority,
      direction: p.direction,
    })),
    options: itemsPriorityOptions,
    onChange: updateItemPriority,
  };

  const itemPriorityValue = getPriorityValue(
    t,
    rule.prioritySettings?.itemPriority,
    itemsPriorityOptions,
    directionOptions,
  );
  const locationPriorityValue = getPriorityValue(
    t,
    rule.prioritySettings?.locationPriority,
    locationPriorityOptions,
    directionOptions,
  );

  return (
    <TitleSection
      id={`policy-editor-priorities`}
      title={t('Allocation Priorities')}
      inSidebarView
      hasScreenTitle
    >
      <InputGroupList className="space-y-2">
        <p className="text-menu-active p-2 text-base">
          {t('Items Priorities')}
        </p>
        <OrderedList {...itemsSortOrderProps} />
        <p className="text-menu-active p-2 text-base">
          {t('Location Priorities')}
        </p>
        <OrderedList {...locationSortOrderProps} />
      </InputGroupList>
    </TitleSection>
  );
};

export default AllocationPolicyPrioritiesEditor;
