import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import PanelContainer from '../../containers/PanelContainer';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import HelpContainer from '../../helpContext/HelpContainer';
import HelpHeader from '../../helpContext/HelpHeader';
import { helpSelectedId } from '../../helpContext/help.state';
import SidebarHelpBreadcrumbs from './SidebarHelpBreadcrumbs';

const SidebarHelp: React.FC = props => {
  const { t } = useTranslation('help');
  const currentHelpId = useRecoilValue(helpSelectedId);

  return (
    <Container
      col
      hasOverflowY
      className={classNames('flex flex-col', 'shadow-xl')}
    >
      <PanelContainer
        className="h-full"
        classNameInner="h-full"
        id="panel-help-documentation"
        title={t`Documentation`}
      >
        {currentHelpId === 'index' && <HelpHeader />}
        <SidebarHelpBreadcrumbs />

        <HelpContainer
          sidebarMode
          id={currentHelpId}
          hasPadding
          className={classNames('pb-40 mt-4 pl-5 pr-10')}
        />
      </PanelContainer>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('help');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-global-help',
    title: t`Help`,
    size: 'lg',
    isGlobal: true,
    sideRight: true,
  };

  return (
    <SidebarContainer {...containerProps}>
      <SidebarHelp />
    </SidebarContainer>
  );
};
