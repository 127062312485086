const WareBeeSymbolShapeThinLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 599.87 477.77"
    {...props}
  >
    <path d="M568,118.6,411.79,28.39c-9.58-5.52-25.16-5.52-34.73,0L299.94,72.92,222.81,28.39c-9.57-5.52-25.15-5.52-34.73,0L31.84,118.6c-9.58,5.53-17.37,19-17.37,30.08V329.09c0,11.06,7.79,24.55,17.37,30.08l156.24,90.21c4.79,2.77,11.08,4.15,17.37,4.15s12.58-1.38,17.36-4.15l77.13-44.52,77.12,44.52c4.79,2.77,11.08,4.15,17.36,4.15s12.58-1.38,17.37-4.15L568,359.17c9.58-5.53,17.37-19,17.37-30.08V148.68C585.4,137.62,577.61,124.13,568,118.6ZM581.4,292.28,409.79,391.36c-8.33,4.81-22.4,4.81-30.73,0l-27.88-16.09L581.4,242.35Zm0-109.1L220.81,391.36c-8.32,4.81-22.4,4.81-30.73,0L18.47,292.28V242.35l169.61,97.93c4.79,2.76,11.08,4.14,17.37,4.14S218,343,222.81,340.28l355-204.95a30.34,30.34,0,0,1,3.61,13.35ZM488.91,182,411.79,137.5c-9.57-5.53-25.16-5.53-34.73,0L220.81,227.71c-8.33,4.81-22.4,4.8-30.73,0l-27.88-16.1L379.06,86.41c8.33-4.81,22.4-4.81,30.73,0l122.37,70.65Zm-4,2.31L220.81,336.81c-8.32,4.81-22.4,4.81-30.73,0L18.47,237.73V187.8l169.61,97.92c4.79,2.77,11.08,4.15,17.37,4.15s12.58-1.38,17.36-4.15L441.67,159.37ZM379.06,31.86c8.33-4.81,22.4-4.81,30.73,0L566,122.06a30.33,30.33,0,0,1,9.76,9.8l-39.63,22.89L411.79,82.94c-9.58-5.52-25.16-5.52-34.73,0L158.2,209.3l-43.24-25ZM111,182l-43.24-25,185-106.8,43.25,25Zm-77.12-60L190.08,31.86c8.33-4.81,22.4-4.81,30.73,0L248.69,48l-185,106.8L24.08,131.86A30.2,30.2,0,0,1,33.84,122.07ZM22.08,135.33l166,95.84c4.79,2.76,11.08,4.15,17.37,4.15s12.58-1.39,17.36-4.15L379.06,141c8.33-4.81,22.4-4.81,30.73,0l27.88,16.1L220.81,282.26c-8.33,4.81-22.4,4.81-30.73,0L18.47,183.18v-34.5A30.34,30.34,0,0,1,22.08,135.33ZM220.81,445.92c-8.32,4.81-22.4,4.81-30.73,0L33.84,355.71c-8.33-4.81-15.37-17-15.37-26.62V296.9l169.61,97.93c4.79,2.76,11.08,4.14,17.37,4.14s12.58-1.38,17.36-4.14l358.59-207v49.93ZM566,355.71,409.79,445.92c-8.33,4.81-22.4,4.81-30.73,0l-75.12-43.37,43.24-25,29.88,17.25c4.79,2.76,11.08,4.14,17.36,4.14s12.58-1.38,17.37-4.14L581.4,296.9v32.19C581.4,338.71,574.36,350.9,566,355.71Z" />
  </svg>
);

export default WareBeeSymbolShapeThinLogo;
