import _ from 'lodash';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../../../components/HelperMessage';
import InboxZero from '../../../../components/InboxZero';
import { Button } from '../../../../components/actions/Button';
import * as Icon from '../../../../components/icons';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import {
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import { Spacer } from '../../../../components/layout/Spacer';
import { ActionBar } from '../../../../components/nav/ActionBar';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import Callout from '../../../../helpContext/Callout';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { ConvertedAisleFeature } from '../converter.serializable.model';
import { converterSelectedArea } from '../store/converter.area.state';
import { converterAreaBuilder } from '../store/converter.state';

const StepMergeAislesContainer: React.FC<PropsWithChildren> = props => {
  const { t } = useTranslation('converter');
  return (
    <>
      <ScreenTitle title={t`Merge Aisles`} subtitle={t`Setup Aisles`} />
      <InputQA className="flex flex-col">
        <InputQuestion
          icon={Icon.DesignerAislesMerge}
          question={t`How to Combine/Merge two Aisles?`}
        />
        <HelpContainer id={'designer/03-setup-aisles-merge'} hasPadding />

        {props.children}
      </InputQA>
    </>
  );
};

const StepMergeAisles: React.FC = () => {
  const { t } = useTranslation('designer');

  const area = useRecoilValue(converterSelectedArea);
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [area]);

  if (_.isNil(area) || _.isNil(areaBuilder))
    return (
      <StepMergeAislesContainer>
        {/* {mergeCandidates.length < 2 ? (
          <HelperMessage hasPadding isSuggestion>
            {t`We haven't found any aisles to merge`}
          </HelperMessage>
        ) : ( */}
        <InboxZero
          className={`mt-2`}
          message={t`No Area Selected`}
          message_helper={t`Click once on an Area to select`}
        />
        {/* )} */}
      </StepMergeAislesContainer>
    );

  let canMerge = false;
  if (selected.length === 2) {
    const first = area.aisles.find(a => a.id === selected[0]);
    const second = area.aisles.find(a => a.id === selected[1]);

    canMerge =
      first &&
      second &&
      (first.leftBays?.length ?? 0) * (second.leftBays?.length ?? 0) +
        (first.rightBays?.length ?? 0) * (second.rightBays?.length ?? 0) ===
        0;
  }

  const applyMerge = () => {
    setAreaBuilder({
      ...areaBuilder,
      mergeAisles: [
        ...(areaBuilder.mergeAisles || []),
        [selected[0], selected[1]],
      ],
    });
  };
  const unmerge = (id: string) => {
    setAreaBuilder({
      ...areaBuilder,
      mergeAisles: areaBuilder.mergeAisles.filter(
        pair => pair[0] !== id && pair[1] !== id,
      ),
    });
  };

  function getSideValue(aisle: ConvertedAisleFeature) {
    const hasLeft = (aisle.leftBays?.length ?? 0) > 0;
    const hasRight = (aisle.rightBays?.length ?? 0) > 0;
    return `${hasLeft ? '' : t('L')}${hasRight ? '' : t('R')}`;
  }

  const mergeCandidates = area.aisles.filter(a => a.mergeId || a.unmergeId);

  if (mergeCandidates.length < 2) {
    return (
      <StepMergeAislesContainer>
        <Spacer flexspace />
        <Callout panelMode type="suggestion">
          {t`We haven't found any aisles to merge, click next to skip this step.`}
        </Callout>
      </StepMergeAislesContainer>
    );
  }

  return (
    <StepMergeAislesContainer>
      <HelperMessage hasBefore hasAction>
        {t`Select two sides of the physical aisle and `}
        <HelperMessageAction>{t`Merge`}</HelperMessageAction>
        {t`button`}
      </HelperMessage>

      <ActionBar>
        <div className="px-3">
          <span className="opacity-90">{t`Aisles in Area`}:</span> {area.id}
        </div>
        <Spacer flexspace />
        <Button
          label={t`Merge`}
          buttonType="primary"
          isDisabled={!canMerge}
          onPress={applyMerge}
        />
      </ActionBar>
      <Table
        isSticky
        isSortable
        viewAsLine
        isHoverable
        // className="sticky top-0"
      >
        <TableHead>
          <TableRow rowLine>
            <TableTh panelMode hasWidth={4}></TableTh>
            <TableTh panelMode value={t`Aisle`} selectable={true} />
            <TableTh panelMode value={t`Side`} />
            <TableTh panelMode hasWidth={4}></TableTh>
          </TableRow>
        </TableHead>
        <TableBody>
          {mergeCandidates.map((aisle, i) => (
            <TableRow rowLineList rowHover key={`row-${i}-${aisle}`}>
              <TableTd>
                <InputCheckbox
                  isSelected={selected.includes(aisle.id)}
                  onChange={v => {
                    if (v) {
                      setSelected([...selected, aisle.id]);
                    } else setSelected(selected.filter(s => s !== aisle.id));
                  }}
                />
              </TableTd>
              <TableTd>{aisle.title}</TableTd>
              <TableTd>{getSideValue(aisle)}</TableTd>
              <TableTd>
                {aisle.unmergeId ? (
                  <Button
                    label={t`Unmerge`}
                    buttonSize={'xs'}
                    onPress={() => unmerge(aisle.unmergeId)}
                  />
                ) : null}
              </TableTd>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StepMergeAislesContainer>
  );
};

export default StepMergeAisles;
