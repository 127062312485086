import { Field, ObjectType } from '@warebee/shared/util-backend-only-types';

@ObjectType()
export class ItemUomQuantity {
  /**
   * @TJS-type integer
   */
  @Field()
  quantity: number;

  @Field({ nullable: true })
  uom: string;

  /**
   * @TJS-type integer
   */
  @Field()
  uomQuantity: number;
}

@ObjectType()
export class ItemQuantityDetails {
  @Field(() => [ItemUomQuantity])
  breakdownByUom: ItemUomQuantity[];

  @Field(() => [ItemUomQuantity])
  quantityPerUom: ItemUomQuantity[];
}
