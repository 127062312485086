import _ from 'lodash';
import {
  agentCategoriesId,
  AgentCategoryDefinition,
  AgentCategoryId,
} from '../../agentData/agent.types';
import categoriesData from './categories.json';

export const agentCategories: AgentCategoryDefinition[] = _(
  categoriesData.categories,
)
  .filter(category => _.includes(agentCategoriesId, category.id))
  .map(cat => ({
    ...cat,
    id: cat.id as AgentCategoryId,
  }))
  .value();

export const agentCategoriesIdsSorted: AgentCategoryId[] = _(agentCategories)
  .sortBy(cat => cat.sortOrder ?? 1)
  .map(cat => cat.id)
  .value();
export const agentCategoriesMap = _.keyBy(agentCategories, 'id');
