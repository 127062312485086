import { LayoutDataRow } from '@warebee/shared/export-converter';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import { ColumnConfig } from '../components/DatasetTable';
import FormattedValue from '../components/FormattedValue';

function useLayoutDataTableConfig(): ColumnConfig<LayoutDataRow>[] {
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();

  const columnsConfig: ColumnConfig<LayoutDataRow>[] = [
    { field: 'locationId', title: t`Location Id`, hasFilter: true },
    { field: 'bay', title: t`Bay`, hasFilter: true },
    { field: 'aisle', title: t`Aisle`, hasFilter: true },
    { field: 'warehouseArea', title: t`Area`, hasFilter: true },
    // { field: 'locationBayId', title: t`Bay Id`, hasFilter: true },
    { field: 'locationUsageType', title: t`Usage Type`, hasFilter: true },
    { field: 'locationBayPosition', title: t`Bay Position`, hasFilter: true },
    { field: 'locmhtype', title: t`MHE Type`, hasFilter: true },
    { field: 'locationRackingType', title: t`Location Type`, hasFilter: true },
    { field: 'bayType', title: t`Bay Type`, hasFilter: true },
    {
      field: 'locationHeightFromFloor',
      title: t`Height From Floor`,
      hasFilter: true,
    },
    { field: 'locationOrder', title: t`Order`, hasFilter: true },
    { field: 'locationStatus', title: t`Status`, hasFilter: true },
    { field: 'locationLevel', title: t`Level`, hasFilter: true },
    {
      field: 'locationLength',
      title: t`Length`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 10)} />
      ),
    },
    {
      field: 'locationWidth',
      title: t`Width`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 10)} />
      ),
    },
    {
      field: 'locationHeight',
      title: t`Height`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 10)} />
      ),
    },
    { field: 'locationWeight', title: t`Weight`, hasFilter: true },
    { field: 'portal', title: t`Access Aisles`, hasFilter: false },
    { field: 'congestionZone', title: t`Work Zone`, hasFilter: true },
  ];

  return columnsConfig;
}
export default useLayoutDataTableConfig;
