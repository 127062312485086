import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import { BayFeatureFragment } from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import BayFeature from '../../features/BayFeature';
import {
  viewerAppliedCachingSettings,
  viewerAreaBays,
  viewerBayModels,
  viewerHoveredBay,
  viewerSelectedBayIdWithToggle,
  viewerShowLocations,
} from '../store/viewer.state';

export type BayLayerProps = {
  getColor?: (bay: BayFeatureFragment) => [string, string];
  bayFilter?: (bay: any) => boolean;
  hideTitle?: boolean;
};

const BayLayer: React.FC<BayLayerProps> = props => {
  const areaBaysMapLoadable = useRecoilValueLoadable(viewerAreaBays);
  const bayTypes = useRecoilValue(viewerBayModels);
  const showLocations = useRecoilValue(viewerShowLocations);
  const setSelectedBayId = useSetRecoilState(viewerSelectedBayIdWithToggle);
  const setHovered = useSetRecoilState(viewerHoveredBay);
  const cachingSettings = useRecoilValue(viewerAppliedCachingSettings);
  const ref = useRef<Konva.Layer>(null);

  useEffect(() => {
    if (ref?.current?.isCached) {
      console.debug('Remove Bay Layer cache');
      ref?.current?.clearCache();
    }
    if (
      cachingSettings.type === 'on' ||
      (cachingSettings.type === 'auto' && cachingSettings.ratio < 1)
    ) {
      console.debug('Create Bay Layer cache');
      ref?.current?.cache({
        pixelRatio: cachingSettings.ratio,
        hitCanvasPixelRatio: cachingSettings.ratio,
      });
    }
  });

  const hideLabelWithCache =
    cachingSettings.type !== 'off' && cachingSettings.size !== 'quality';

  if (areaBaysMapLoadable.state !== 'hasValue') return null;

  const areaBaysMap = areaBaysMapLoadable.contents;

  if (_.isEmpty(areaBaysMap)) return null;
  const areaBays = _.values(areaBaysMap);
  const baysToDraw = props.bayFilter
    ? areaBays.filter(props.bayFilter)
    : areaBays;

  return (
    <Layer listening={!showLocations} ref={ref}>
      {baysToDraw.map(bay => (
        <BayFeature
          key={`bay-${bay.id}`}
          bay={bay}
          bayType={bayTypes[bay.details?.bayType ?? DEFAULT_BAY_TYPE]}
          hideTitle={props.hideTitle || showLocations || hideLabelWithCache}
          showAsBackground={showLocations}
          onClick={() => {
            setSelectedBayId(bay.id);
          }}
          color={props.getColor ? props.getColor(bay)?.[0] : null}
          textColor={props.getColor ? props.getColor(bay)?.[0] : null}
          onMouseOver={() => {
            setHovered(bay);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
        />
      ))}
    </Layer>
  );
};

export default React.memo(BayLayer);
