import { PickingRequirementsSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import { pickingRequirementDefault } from '../../simulation/store/pickingPolicy/pickingPolicy.default';
import { getPickingRequirementsWaypointSettingsOptions } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';

const PickingPolicyWaypointEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    pickingPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  const waveDescriptors = getPickingRequirementsWaypointSettingsOptions(t);

  const onSelectValue = (
    patch: Partial<PickingRequirementsSettingsFragment>,
  ) => {
    updateRule({
      ...rule,
      pickingRequirementsSettings: {
        ...(rule.pickingRequirementsSettings ?? pickingRequirementDefault),
        ...patch,
      },
    });
  };

  return (
    <>
      <TitleSection
        id={`policy-editor-waypoint-start`}
        title={t`Start waypoint`}
        inSidebarView
        hasScreenTitle
        classNameInner="p-2 space-y-2"
      >
        {_.map(waveDescriptors, (descriptor, index) => {
          const isSelected =
            descriptor.id ===
            rule.pickingRequirementsSettings?.startLocationIdSource;
          return (
            <PolicyCardItem
              key={`PolicyCardItem-start-location-${descriptor.id}`}
              title={descriptor.title}
              isActive={isSelected}
              // icon={descriptor.icon}
              iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
              onClick={() =>
                onSelectValue({
                  startLocationIdSource: descriptor.id,
                })
              }
            />
          );
        })}
      </TitleSection>
      <TitleSection
        id={`policy-editor-waypoint-end`}
        title={t`End waypoint`}
        inSidebarView
        hasScreenTitle
        classNameInner="p-2 space-y-2"
      >
        {_.map(waveDescriptors, (descriptor, index) => {
          const isSelected =
            descriptor.id ===
            rule.pickingRequirementsSettings?.endLocationIdSource;
          return (
            <PolicyCardItem
              key={`PolicyCardItem-end-location-${descriptor.id}`}
              title={descriptor.title}
              isActive={isSelected}
              // icon={descriptor.icon}
              iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
              onClick={() =>
                onSelectValue({
                  endLocationIdSource: descriptor.id,
                })
              }
            />
          );
        })}
      </TitleSection>
    </>
  );
};

export default PickingPolicyWaypointEditor;
