import * as React from 'react';
const CircleWaypoint4Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleWaypoint4Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4C30.92,4,4,30.91,4,64s26.92,60,60,60,60-26.91,60-60S97.08,4,64,4ZM48.25,28.13l15.75-15.74,15.75,15.74v11.87s-11.55-11.54-11.55-11.54v21.09c0,1.1-.9,2-2,2h-4.4c-1.1,0-2-.9-2-2v-21.09s-11.55,11.54-11.55,11.54v-11.87ZM28.78,79.1l-15.74-15.75,15.74-15.75h11.87s-11.54,11.55-11.54,11.55h21.09c1.1,0,2,.9,2,2v4.4c0,1.1-.9,2-2,2h-21.09s11.54,11.55,11.54,11.55h-11.87ZM79.75,98.56l-15.75,15.74-15.75-15.74v-11.87l11.55,11.54v-21.09c0-1.1.9-2,2-2h4.4c1.1,0,2,.9,2,2v21.09s11.55-11.54,11.55-11.54v11.87ZM99.22,79.1h-11.87s11.54-11.55,11.54-11.55h-21.09c-1.1,0-2-.9-2-2v-4.4c0-1.1.9-2,2-2h21.09s-11.54-11.55-11.54-11.55h11.87s15.74,15.75,15.74,15.75l-15.74,15.75Z"
    />
  </svg>
);
export default CircleWaypoint4Icon;
