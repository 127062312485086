import {
  BatchJobStatus,
  useExportSimulationAssignmentDetailsMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useLoadSimulationItemSet from '../../assignmentPolicy/useLoadSimulationItemSet';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { formatInteger, formatPercent } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import InboxZero from '../../components/InboxZero';
import { Stat } from '../../components/stats/Stat';
import { viewerShowLocations } from '../../layout/viewer/store/viewer.state';
import { getABCProductHeatmapBuckets } from '../../metrics/analyzeProduct/analyzeProductMetric.helper';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { getProductCategories } from '../store/abc/simulation.ABC.helper';
import { analyzeABC, simulationABC } from '../store/abc/simulation.ABC.state';
import {
  simulationLayoutHeatmapFilters,
  simulationLayoutHeatmapFiltersHasAny,
} from '../store/simulation.layout.state';
import {
  simulationCurrent,
  simulationEffectiveItemSet,
  simulationEffectiveItemSetLoadStatus,
} from '../store/simulation.state';

export type SimulationAnalyzePanelProductProps = {
  analyzeId: string;
  mode?: 'panel' | 'header';
};

// We get multiple values for each product category to draw smooth areas on chart
// subgroupCount count of points in each category
const subgroupCount = 5;
// subgroupLengths - delta  of cumulative %  for each group
const subgroupLengths = [0.1, 0.06, 0.03, 0.01];

const SimulationAnalyzePanelItemsABCStats: React.FC<
  SimulationAnalyzePanelProductProps
> = props => {
  const { t } = useTranslation('simulation');
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [showProductCount, setShowProductCount] = useState<boolean>(false);

  const sim = useRecoilValue(simulationCurrent);
  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );
  const heatmapType = useRecoilValue(analyzeProductMetricSelected);
  const hasAnyFiltered = useRecoilValue(simulationLayoutHeatmapFiltersHasAny);
  const setShowLocation = useSetRecoilState(viewerShowLocations);
  const effectiveItemSet = useRecoilValue(simulationEffectiveItemSet);
  const effectiveItemSetLoadStatus = useRecoilValue(
    simulationEffectiveItemSetLoadStatus,
  );
  const [runExportCSV] = useExportSimulationAssignmentDetailsMutation();
  const [loadItemSetStatus, cancelLoadItemSetStatus] =
    useLoadSimulationItemSet();
  const abc = useRecoilValue(simulationABC);
  const hitABC = useRecoilValue(analyzeABC(props.analyzeId));

  console.log('### hitABC', hitABC);
  const buckets = getABCProductHeatmapBuckets();
  // Check and Re-calculate SimulationEffectiveItemSet
  useEffect(() => {
    if (effectiveItemSetLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;

    if (_.isNil(effectiveItemSet)) {
      loadItemSetStatus({ simulationId: sim.id });
    }
    if (
      effectiveItemSet?.status === BatchJobStatus.CREATED ||
      effectiveItemSet?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadItemSetStatus({ simulationId: sim.id });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }
    return () => {
      clearTimeout(timeoutId);
      cancelLoadItemSetStatus();
    };
  }, [effectiveItemSet]);

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [heatmapFilter, buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: !value,
      },
    });
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  if (_.isNil(abc)) {
    return null;
  }

  const categoryDescriptors = getProductCategories(t);

  const orderSetCategoryDataRaw = abc.orderSet?.itemsByCategory.categories;
  const assignedCategoryDataRaw =
    abc.effectiveItemSet?.itemsByCategory.categories;
  const analyzedCategoryDataRaw = hitABC?.categories;

  const orderLineCount = abc.orderSet?.itemsByCategory.totalOrderLineCount;

  const orderSetDataGrouped = _.chunk(orderSetCategoryDataRaw, subgroupCount);
  const analyzedDataGrouped = _.chunk(analyzedCategoryDataRaw, subgroupCount);

  const categorizedData = orderSetDataGrouped.map((orderSetChunks, index) => {
    // const simChunks = assignmentDataGrouped?.[index];
    const simChunks = analyzedDataGrouped[index];
    const minPercentRank = _.minBy(
      orderSetChunks,
      c => c.minPercentRank,
    )?.minPercentRank;

    const maxPercentRank = _.maxBy(
      orderSetChunks,
      c => c.maxPercentRank,
    )?.maxPercentRank;

    return {
      skip: _.minBy(orderSetChunks, c => c.skip)?.skip,
      count: _.sumBy(orderSetChunks, ch => ch.count),
      pickedCount: _.sumBy(simChunks, ch => ch.count),
      orderLineCount: _.sumBy(orderSetChunks, ch => ch.totalOrderLineCount),
      maxPercentRank,
      minPercentRank,
    };
  });

  return (
    <>
      {orderLineCount === 0 ? (
        <InboxZero message={t`No data for Items Analytics (ABC)`} />
      ) : (
        <div className="mx-1 flex w-full flex-1 flex-wrap gap-1">
          {_.values(categoryDescriptors).map((meta, index) => {
            const data = categorizedData[index];
            // last item in descriptors is for disabled location;
            const productCount = data?.count;
            const pickedProductCount = data?.pickedCount;

            const cumulativeShareDiff = formatPercent(
              data?.maxPercentRank - data?.minPercentRank,
            );
            const orderLinesShare = formatPercent(
              data.orderLineCount / orderLineCount,
            );
            const orderLines = formatInteger(data?.orderLineCount);

            const orderLineStat = t(
              `{{cumulativeShareDiff}} of Items, {{orderLinesShare}} of Sales ({{orderLines}} OL)`,
              {
                cumulativeShareDiff,
                orderLinesShare,
                orderLines,
              },
            );

            const complianceStat = t(`{{cumulativeShareDiff}} of Items`, {
              cumulativeShareDiff,
              orderLinesShare,
              orderLines,
            });

            const isDeselected =
              heatmapFilter?.hiddenBuckets[meta.key] === true;

            return productCount > 0 || pickedProductCount > 0 ? (
              <Stat
                key={`product-category-stat-${meta.key}`}
                legendColor={[meta.color, meta.textColor]}
                inPanelMode
                // hasHelper
                isActionable={heatmapType === 'abc'}
                isPreview
                title={`[${meta.tag}] ${meta.title}`}
                value={
                  showProductCount
                    ? formatInteger(productCount)
                    : formatInteger(pickedProductCount)
                }
                // isSectionable={heatmapType === 'abc'}
                // valueTotal={formatInteger(productCount)}
                // isFullWidth
                isSelected={!isDeselected && heatmapType === 'abc'}
                onClick={
                  evt => debouncedClick(meta.key, isDeselected, evt)
                  // setHeatmapFilter({
                  //   ...heatmapFilter,
                  //   hiddenBuckets: {
                  //     ...(heatmapFilter.hiddenBuckets ?? {}),
                  //     [meta.key]: !isDeselected,
                  //   },
                  // })
                }
              >
                {data.count > 0 && (
                  <div className="opacity-75">
                    <div>{complianceStat}</div>
                  </div>
                )}
              </Stat>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

export default SimulationAnalyzePanelItemsABCStats;
