import { ConfigType } from '@markdoc/markdoc';
import { Component } from 'react';
import { Button } from '../components/actions/Button';
import ActionLink, { ActionLinkProps } from './ActionLink';
import Callout from './Callout';
import ClassWrapper from './ClassWrapper';
import HelpContainer from './HelpContainer';
import HelpNavigate from './HelpNavigate';
// import SqlHighlight from './SqlHighlight';
import CodeSnippet from './CodeSnippet';
import Tag from './Tag';
import { HelpIndex } from './help.default';
import { action } from './markdoc/action.markdoc';
import { button } from './markdoc/button.markdoc';
import { callout } from './markdoc/callout.markdoc';
import { classwrapper } from './markdoc/classwrapper.markdoc';
import { codesnippet } from './markdoc/codesnippet.markdoc';
import { helpinclude } from './markdoc/helpinclude.markdoc';
import { navigate } from './markdoc/navigate.markdoc';
import { sql } from './markdoc/sql.markdoc';
import { tag } from './markdoc/tag.markdoc';
import { tip } from './markdoc/tip.markdoc';

export const markdocTagConfig: ConfigType = {
  nodes: {
    link: navigate,
  },
  tags: {
    button,
    tip,
    callout,
    action,
    tag,
    sql,
    classwrapper,
    helpinclude,
    codesnippet,
  },
};

export const markdocComponentsConfig: Record<string, Component | React.FC> = {
  HelpNavigate,
  Button,
  Tag,
  CodeSnippet,
  Callout,
  ClassWrapper,
  ActionLink: (props: ActionLinkProps) => (
    <ActionLink icon={props.icon}>{props.children}</ActionLink>
  ),
  HelpInclude: ({ id }: { id: HelpIndex }) => {
    return <HelpContainer id={id} />;
  },
};
