import { MeasurementSystem } from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { Arrow } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';

const routeColors = TwTheme.extend.colors.route;

export type AnimatedArrowProps = {
  points: number[];
  strokeWidth?: number; //default  is 2
  // speed?: number; //default  is 64
  // size?: number; //default  is 1
  scale?: number;
};

const AnimatedArrow: React.FC<AnimatedArrowProps> = props => {
  const ref = useRef(null);
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);
  const scale =
    props.scale ?? (measureSystem === MeasurementSystem.IMPERIAL ? 0.4 : 1);
  const dashSize = 4 * 3.5 * scale;
  const dashSpace = dashSize * 4;
  const strokeWidthDefault = dashSize * 2;
  const fps = 120 * scale;

  const strokeWidth = props.strokeWidth ?? strokeWidthDefault;

  useEffect(() => {
    const anim = new Konva.Animation(
      frame => {
        ref.current?.dashOffset(
          -((frame.time / 1000) * fps) % (dashSpace * (dashSize + dashSpace)),
        );
      },
      ref.current?.getLayer(),
    );

    anim.start();
    return () => {
      anim.stop();
    };
  }, [ref]);

  const baseConfig: Konva.ArrowConfig = {
    points: props.points,
    draggable: false,
    strokeScaleEnabled: true,
    perfectDrawEnabled: false,
    // closed: true,
    fillEnabled: false,
    listening: false,
    strokeWidth: strokeWidth,
    lineCap: 'round',
    pointerWidth: 18 * scale,
    pointerLength: 36 * scale,
  };
  const staticBorderConfig: Konva.ArrowConfig = {
    ...baseConfig,
    stroke: routeColors.selectedBorder, //locationColors.border,
  };

  const animatedBorderConfig: Konva.ArrowConfig = {
    ...baseConfig,
    stroke: routeColors.selectedBorderDash,

    dashEnabled: true,
    dash: [dashSize, dashSpace],
  };

  return (
    <>
      <Arrow key={`active-feature-border-bg`} {...staticBorderConfig} />
      <Arrow
        ref={ref}
        key={`active-feature-border-anim`}
        {...animatedBorderConfig}
      />
    </>
  );
};

export default AnimatedArrow;
