import * as React from 'react';
const LayersFullIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayersFullIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="124 35.73 64 1.66 4 35.73 64 70.01 124 35.73"
    />
    <polygon
      strokeWidth={0}
      points="64 75.91 23.54 52.8 4 63.9 64 98.18 124 63.9 104.46 52.8 64 75.91"
    />
    <polygon
      strokeWidth={0}
      points="64 104.08 23.54 80.96 4 92.06 64 126.34 124 92.06 104.46 80.96 64 104.08"
    />
  </svg>
);
export default LayersFullIcon;
