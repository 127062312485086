import * as React from 'react';
const CursorMoveSelectIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorMoveSelectIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M60.14,43.86c0-2.93,1.72-3.66,3.83-1.63l51.41,49.47c2.11,2.03,1.46,3.38-1.44,2.99l-26.38-3.49c-2.9-.38-6.69,1.23-8.42,3.59l-15.74,21.45c-1.73,2.36-3.15,1.9-3.16-1.03l-.1-71.35Z"
    />
    <rect width={44} height={44} x={8.78} y={74.97} strokeWidth={0} />
    <polyline
      strokeWidth={0}
      points="59.28 29.47 59.28 18.47 59.28 7.47 70.28 7.47 81.28 7.47 92.28 7.47 103.28 7.47 103.28 18.47 103.28 29.47 103.28 40.47 103.28 51.47 92.28 51.47 81.28 51.47"
    />
    <line
      x1={59.28}
      x2={59.28}
      y1={29.47}
      y2={28.47}
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeDasharray="0 0 1.82 5.45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="59.28 23.02 59.28 18.47 59.28 11.2"
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="59.28 8.47 59.28 7.47 60.28 7.47"
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeDasharray="0 0 1.83 5.48"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="65.76 7.47 70.28 7.47 81.28 7.47 92.28 7.47 99.54 7.47"
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="102.28 7.47 103.28 7.47 103.28 8.47"
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeDasharray="0 0 1.83 5.48"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="103.28 13.95 103.28 18.47 103.28 29.47 103.28 40.47 103.28 47.73"
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="103.28 50.47 103.28 51.47 102.28 51.47"
    />
    <polyline
      fill="none"
      stroke="#000"
      strokeDasharray="0 0 1.82 5.45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      points="96.83 51.47 92.28 51.47 85.01 51.47"
    />
    <line
      x1={82.28}
      x2={81.28}
      y1={51.47}
      y2={51.47}
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
  </svg>
);
export default CursorMoveSelectIcon;
