import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import OrderSetSimulationPanel from '../panels/OrderSetSimulation';
import OrderSetStartPanel from '../panels/OrderSetStartPanel';
import OrderSetStatsPanel from '../panels/OrderSetStats';
import { orderSetMenuItems } from '../store/orderSet.default';
import {
  orderSetDocument,
  orderSetDocumentLoadStatus,
  orderSetSelectedMenuItemId,
} from '../store/orderSet.state';

export const OrderSetMainSidebar: React.FC = () => {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(orderSetDocument);
  const documentLoadStatus = useRecoilValue(orderSetDocumentLoadStatus);
  const [stepId, setStepId] = useRecoilState(
    orderSetSelectedMenuItemId(document?.id),
  );
  const menuItems = orderSetMenuItems(tApp);
  const totalSteps = menuItems.length;
  const current = _.findIndex(menuItems, s => s.id === stepId);
  const isLastStep = totalSteps === current + 1;
  const next = () => {
    if (current < totalSteps - 1) {
      setStepId(menuItems[current + 1].id);
    }
  };

  if (documentLoadStatus === AsyncLoadStatus.Loading) {
    return <LoadingIndicator selfCenter message={t`Loading Order set`} />;
  }
  if (documentLoadStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator selfCenter message={t`Cannot load Order set`} />;
  }

  const getContent = () => {
    switch (stepId) {
      case 'order-set-menu-start':
        return <OrderSetStartPanel />;
      case 'order-set-menu-stats':
        return <OrderSetStatsPanel />;
      case 'order-set-menu-simulation':
        return <OrderSetSimulationPanel />;
    }
  };

  return (
    <Container col hasOverflowY>
      {getContent()}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(orderSetDocument);
  const [stepId, setStepId] = useRecoilState(
    orderSetSelectedMenuItemId(document?.id),
  );

  const getTitle = (): string => {
    switch (stepId) {
      case 'order-set-menu-start':
        return t`Dataset Properties`;
      case 'order-set-menu-stats':
        return t`Dataset Stats`;
      case 'order-set-menu-simulation':
        return t`Dataset in Simulations`;
    }
  };

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-order-set-main',
    title: t`Order Set details`,
    size: 'sm',
  };

  return (
    <SidebarContainer {...containerProps}>
      <OrderSetMainSidebar />
    </SidebarContainer>
  );
};
