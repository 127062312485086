import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../../components/actions/DropdownSelector';
import {
  appThemeSelectedId,
  ThemeId,
  themeIds,
} from '../../store/global.state';

export type ThemeSelectorProps = PropsWithChildren & {
  className?: string;
};

const ThemeSelector: React.FC<ThemeSelectorProps> = props => {
  const { t } = useTranslation('app');

  const [themeId, setThemeId] = useRecoilState(appThemeSelectedId);
  const themeOptions: ThemeId[] = [null, ...themeIds];

  return (
    <DropdownSelector
      className={classNames('text-base', props.className)}
      classNameLabel="flex-1"
      headerMode
      DropAlignRight
      label={t('Theme')}
      value={themeId}
      values={themeOptions}
      renderValue={v => v ?? 'default'}
      onChange={v => setThemeId(v as any)}
    />
  );
};

export default ThemeSelector;
