import * as React from 'react';
const FilterIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FilterIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M8.25,13.93c-3.22,0-5.36,3.65-3.65,6.43l46.53,62.18v36.17c0,3.22,3.43,5.36,6.22,3.86l17.15-8.58c1.5-.64,2.36-2.14,2.36-3.86v-27.59l46.53-62.18c1.72-2.79-.43-6.43-3.65-6.43H8.25Z"
    />
  </svg>
);
export default FilterIcon;
