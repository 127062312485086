import * as React from 'react';
const PolicySwapIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PolicySwapIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65Zm-6.16,12.5l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z" />
    <g opacity={0.7}>
      <rect
        width={18.15}
        height={18.14}
        x={55.64}
        y={90.3}
        transform="translate(-51.31 74.86) rotate(-45)"
      />
    </g>
    <rect
      width={18.15}
      height={18.14}
      x={72.75}
      y={73.2}
      opacity={0.75}
      transform="translate(-34.21 81.94) rotate(-45)"
    />
    <g opacity={0.6}>
      <rect
        width={18.15}
        height={18.14}
        x={55.64}
        y={56.09}
        transform="translate(-27.12 64.84) rotate(-45)"
      />
      <rect
        width={18.15}
        height={18.14}
        x={55.64}
        y={56.09}
        transform="translate(-27.12 64.84) rotate(-45)"
      />
    </g>
    <rect
      width={18.15}
      height={18.14}
      x={89.85}
      y={56.09}
      transform="translate(-17.1 89.03) rotate(-45)"
    />
    <g opacity={0.85}>
      <rect
        width={18.15}
        height={18.14}
        x={72.75}
        y={38.98}
        transform="translate(-10.02 71.92) rotate(-45)"
      />
    </g>
    <g opacity={0.6}>
      <rect
        width={18.15}
        height={18.14}
        x={55.64}
        y={21.88}
        transform="translate(-2.93 54.82) rotate(-45)"
      />
      <rect
        width={18.15}
        height={18.14}
        x={55.64}
        y={21.88}
        transform="translate(-2.93 54.82) rotate(-45)"
      />
    </g>
  </svg>
);
export default PolicySwapIcon;
