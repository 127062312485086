import {
  AllocateJobEngine,
  AllocationType,
} from '@warebee/frontend/data-access-api-graphql';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ALLOCATION_JOB_REFRESH_INTERVAL } from '../common/constants';
import { cn } from '../common/utils';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons/';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import useLoadAllocationRun from '../simulation/hooks/useLoadAllocationRun';
import useRunAllocation from '../simulation/hooks/useRunAllocation';
import { getAllocationSettingsInput } from '../simulation/store/allocation/allocation.helper';
import { allocationRunSummary } from '../simulation/store/allocation/allocation.state';
import { allocationSettings } from '../simulation/store/allocationPolicy/allocationPolicy.state';

const AllocationRunGenerateButton: React.FC = () => {
  const allocationRun = useRecoilValue(allocationRunSummary);
  const currentSettings = useRecoilValue(allocationSettings);
  const [runAllocation] = useRunAllocation();
  const [loadAllocationRun] = useLoadAllocationRun();

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        loadAllocationRun({ allocationRunId: allocationRun.id });
      }, ALLOCATION_JOB_REFRESH_INTERVAL);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [allocationRun]);

  async function updateAllocationDraft() {
    runAllocation({
      allocationId: allocationRun?.id,
      type: AllocationType.FULL,
      engine: AllocateJobEngine.FARGATE,
    });
  }

  const isLoading =
    allocationRun?.status === 'CALCULATING' ||
    allocationRun?.status === 'CREATED' ||
    allocationRun?.status === 'RESTARTED';

  const hasError =
    allocationRun?.status === 'FAILED' ||
    allocationRun?.status === 'TERMINATED';

  const isDraft = allocationRun?.status === 'CREATED';
  const isReady = allocationRun?.status === 'READY';

  const isChanged =
    isReady &&
    !_.isEqual(
      getAllocationSettingsInput(currentSettings),
      getAllocationSettingsInput(allocationRun?.allocationSettings),
    );

  return (
    <Button
      className="mx-2 rounded"
      buttonSize="xs"
      buttonType={hasError ? 'delete' : isChanged ? 'primary' : 'primary'}
      isDisabled={isLoading}
      isLoading={isLoading}
      hasIconBefore={
        isLoading ? null : isChanged ? (
          <Icon.Reload className={cn('h-4 w-4 fill-current')} />
        ) : null
      }
      hasIconAfter={
        isLoading ? (
          <IconArrowDir className={cn('!h-4 !w-4')} />
        ) : isChanged ? null : (
          <IconArrowDir className={cn('!h-4 !w-4')} />
        )
      }
      label={
        isLoading
          ? t`Allocating...`
          : isChanged
            ? t`Re-generate`
            : t`Allocate preview`
      }
      onPress={updateAllocationDraft}
    />
  );
};

export default AllocationRunGenerateButton;
