import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import LoadBalancingEditorPanel from '../../../loadBalancingPolicy/LoadBalancingEditorPanel';
import SwapPolicyEditorPanel from '../../../swapPolicy/SwapPolicyEditorPanel';
import { optimisationIsEditable } from '../../store/optimisation.state';
import { simulationOptimisationPolicyTabKey } from '../../store/simulation.wizard.state';

const OptimisationPolicyEditorSidebar: React.FC = () => {
  const selectedTab = useRecoilValue(simulationOptimisationPolicyTabKey);
  const isEditable = useRecoilValue(optimisationIsEditable);

  const { t } = useTranslation('simulation');

  if (selectedTab === 'tab-optimisation-settings' || !isEditable) {
    return null;
  }

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-swap-editor',
    title: t`Filter Policy Params`,
    loadingMessage: t`Loading Optimisation Policy...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      {selectedTab === 'tab-optimisation-policy-swap' && (
        <SwapPolicyEditorPanel />
      )}
      {selectedTab === 'tab-optimisation-policy-load-balancing' && (
        <LoadBalancingEditorPanel />
      )}
    </SidebarContainer>
  );
};

export default OptimisationPolicyEditorSidebar;
