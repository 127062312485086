import _ from 'lodash';

import { NamedColors } from './namedColors.types';
// Local Storage Version
export const NAMED_COLORS_VERSION = '2023.03.2';

export const commonColors: NamedColors = {
  id: 'policy',
  title: 'Policy Colours',
  colors: {
    'BABY CARE': ['#e77396', null],
    AEROSOLS: ['#df72fd', null],
    FLAMMABLES: ['#ff7300', null],
    'HEALTHCARE GENERAL': ['#BA3E3E', null],
    HYPOCHLORITES: ['#00ff00', null],
    'HOUSEHOLD GENERAL': ['#9aebb2', null],
    ACIDS: ['#d0ff00', null],
    PAPERWORK: ['#0075c8', null],
    CONSUMABLES: ['#3B3FE1', null],
    CHARITY: ['#E4604E', null],
    'OVERSIZED CONS': ['#39A388', null],
    APIS: ['#97c6ff', null],
    'API PACKAGING': ['#2EFF9D', null],
  },
};

export const hazardColors: NamedColors = {
  id: 'hazard',
  title: 'COMAH Colours',
  colors: {
    AEROSOLS: ['#ff4dd8', '#000000'],
    FLAMMABLES: ['#ff4d00', '#000000'],
    HYPOCHLORITES: ['#00ff00', '#000000'],
    ACIDS: ['#eeff00', '#000000'],
  },
};

export const namedColors = _.keyBy([commonColors, hazardColors], nc => nc.id);
