import * as React from 'react';
const CursorClickIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorClickIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M28.39,31.84c-1.29-3.2.27-4.75,3.46-3.46l77.89,31.45c3.2,1.29,3.08,3.05-.26,3.9l-30.35,7.77c-3.34.85-6.77,4.29-7.62,7.62l-7.77,30.35c-.85,3.34-2.61,3.46-3.9.26L28.39,31.84Z"
    />
    <path
      strokeWidth={0}
      d="M31.32,18.8V4.8c0-1.1.9-2,2-2s2,.9,2,2v14c0,1.1-.9,2-2,2s-2-.9-2-2ZM21.32,32.8c0-1.1-.9-2-2-2H5.32c-1.1,0-2,.9-2,2s.9,2,2,2h14c1.1,0,2-.9,2-2ZM43.22,24.9c.51,0,1.02-.2,1.41-.59l9.9-9.9c.78-.78.78-2.05,0-2.83s-2.05-.78-2.83,0l-9.9,9.9c-.78.78-.78,2.05,0,2.83.4.4.91.59,1.42.59ZM22.01,41.29l-9.9,9.9c-.78.78-.78,2.05,0,2.83.39.39.9.59,1.41.59s1.02-.2,1.41-.59l9.9-9.9c.78-.78.78-2.05,0-2.83-.78-.79-2.04-.79-2.82,0ZM14.94,11.59c-.78-.78-2.05-.78-2.83,0-.78.78-.78,2.05,0,2.83l9.9,9.9c.39.39.9.59,1.41.59s1.02-.2,1.41-.59c.78-.78.78-2.05,0-2.83l-9.89-9.9Z"
    />
  </svg>
);
export default CursorClickIcon;
