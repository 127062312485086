import { WarehouseType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  appBillingWarehouseLoadStatus,
  appBillingWarehouseStateAll,
} from '../appBilling/store/appBilling.state';
import { WAREHOUSE_NEW_ID } from '../common/constants';
import LoadingIndicator from '../components/LoadingIndicator';
import * as Icon from '../components/icons';
import NavLink from '../components/nav/NavLink';
import { MenuItemWarehouse } from '../components/site/warehouse/MenuItemWarehouse';
import SidebarContainer, {
  SidebarContainerProps,
} from '../containers/SidebarContainer';
import { warehouseList, warehouseSelectedId } from '../store/warehouse.state';

const SidebarMenuWarehouses: React.FC = () => {
  const { t } = useTranslation('app');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const warehouses = useRecoilValue(warehouseList);
  const whBilling = useRecoilValue(appBillingWarehouseStateAll);
  const whBillingStatus = useRecoilValue(appBillingWarehouseLoadStatus);

  if (!warehouses)
    return (
      <LoadingIndicator
        absolute
        className={classNames('text-center')}
        message={t`Loading Warehouses`}
      />
    );

  return (
    <nav
      data-component="sidebarMenuWarehouses"
      role="menu"
      className={classNames(
        'bg-app-background',
        'flex flex-col items-center flex-1',
        'text-xs lg:text-sm',
        'p-0.5',
      )}
    >
      {_(warehouses)
        .orderBy(['type', wh => wh.title?.toLocaleLowerCase()], ['desc', 'asc'])
        .map(wh => (
          <MenuItemWarehouse
            key={wh.id}
            id={wh.id}
            title={wh.title}
            billing={whBilling?.[wh.id]}
            billingLoadStatus={whBillingStatus}
            isDemo={wh.type === WarehouseType.DEMO}
          />
        ))
        .value()}

      <NavLink
        className={classNames('relative overflow-hidden w-full')}
        to="/wh/new"
        active={warehouseId === WAREHOUSE_NEW_ID}
        data-component={`MenuItemWarehouseAdd`}
        data-label={`menu-warehouse-item-add`}
        aria-label={`menu-warehouse-item-add`}
        titleTrace={`Warehouse: Add New`}
      >
        <Icon.WarehouseAdd
          className={classNames(
            'fill-current',
            'w-12 h-12',
            'opacity-50',
            'hover:text-menu-active',
          )}
        />
        <span>{t`Add`}</span>
      </NavLink>
    </nav>
  );
};

export default () => {
  const { t } = useTranslation('app');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-menu-warehouses',
    title: t('Warehouses'),
    loadingMessage: t('Loading...'),
    size: 'warehouses',
    hasControls: false,
  };

  return (
    <SidebarContainer
      {...containerProps}
      widthAuto
      widthAutoOpen
      isHiddenMinimised
      noHeaderTitle
      noHeaderTitleMinimised
      hasControls={false}
      hasScroll
    >
      <SidebarMenuWarehouses />
    </SidebarContainer>
  );
};
