import classNames from 'classnames';
import i18n from '../../i18n';
import * as Icon from '../icons';

export type IconProps = {
  className?: string;
  direction?: 'Back' | 'Forward';
  type?: 'Default' | 'MappingHelper';
};

export const IconArrowDirectional = ({
  className,
  direction,
  type = 'Default',
}: IconProps) => {
  const dir =
    direction || (i18n.dir(i18n.language) === 'ltr' ? 'Forward' : 'Back');

  const classes = classNames(
    'fill-current',
    'rounded-full',
    {
      'p-1 ltr:mr-2 rtl:ml-2 h-6 w-6 bg-menu-active text-menu-active-text':
        type === 'Default',
      'p-1 flex-1 h-5 w-5 bg-table-header/30 text-mapping-helper-text':
        type === 'MappingHelper',
    },
    className,
  );

  return dir === 'Forward' ? (
    <Icon.ArrowRight className={classes} />
  ) : (
    <Icon.ArrowLeft className={classes} />
  );
};

export const IconArrowDir = ({
  className,
  direction,
  type = 'Default',
}: IconProps) => {
  const dir =
    direction || (i18n.dir(i18n.language) === 'ltr' ? 'Forward' : 'Back');

  const classes = classNames(
    'fill-current',
    {
      'h-6 w-6 bg-default-class': type === 'Default',
      'h-5 w-5 bg-table-header/50': type === 'MappingHelper',
    },
    className,
  );

  return dir === 'Forward' ? (
    <Icon.ArrowRight className={classes} />
  ) : (
    <Icon.ArrowLeft className={classes} />
  );
};
