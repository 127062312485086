import { AtomOptions, RecoilState, atom } from 'recoil';
import { recoilPersist } from './recoilPersist';

export function persistAtom<T>(options: AtomOptions<T>): RecoilState<T> {
  const persist = recoilPersist({
    key: options.key,
  });

  return atom<T>({
    ...options,
    default: persist.getCurrent() ?? options['default'],
    effects: [persist.persistAtom, ...(options.effects ?? [])],
  });
}
