import * as React from 'react';
const ResourceTimingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ResourceTimingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M97.62,43.45c0,3.35-.53,6.58-1.52,9.6l-.22-.07s.15-.41.38-1.2c.23-.78.54-1.94.8-3.45.25-1.51.45-3.39.42-5.54-.03-2.16-.31-4.6-.96-7.19-.14-.53-.3-1.08-.47-1.63.02-.03.04-.07.07-.1.99,3.02,1.52,6.24,1.52,9.58Z"
    />
    <circle cx={94.18} cy={68.91} r={5.89} strokeWidth={0} opacity={0.8} />
    <circle cx={77.92} cy={79.31} r={4.97} strokeWidth={0} opacity={0.6} />
    <circle cx={58.62} cy={80.06} r={4.4} strokeWidth={0} opacity={0.5} />
    <path
      strokeWidth={0}
      d="M109.34,44.81c0,4.05-.6,7.96-1.71,11.65l-11.53-3.41c.99-3.02,1.52-6.25,1.52-9.6s-.53-6.56-1.51-9.58c-1.95-5.98-5.66-11.16-10.53-14.91-.14-.11-.28-.22-.43-.33-2.16-1.64-4.59-3.04-7.21-4.11-1.69-.69-3.45-1.22-5.26-1.61l-.69-.15-.69-.11c-.45-.08-.93-.16-1.35-.21l-.65-.07c-.22-.03-.42-.06-.71-.07l-.79-.05-.39-.02h-.2s-.09-.02-.09-.02h-.05s-.04,0-.04,0c-3.72-.12-7.49.38-11.01,1.51-3.56,1.15-6.89,2.89-9.77,5.07-2.88,2.18-5.33,4.75-7.23,7.5-.5.68-.93,1.38-1.36,2.07-.21.35-.4.7-.6,1.05-.2.35-.38.71-.55,1.06-.73,1.41-1.29,2.84-1.78,4.23-.23.7-.47,1.39-.63,2.07-.09.34-.19.68-.26,1.01-.08.34-.15.67-.22,1.01-.03.16-.07.33-.1.48-.03.17-.06.33-.09.5-.05.32-.1.65-.16.96-.09.63-.15,1.22-.22,1.8-.05.61-.08,1.26-.12,1.85v.12s0,.02-.01.02v.24c-.01.14-.01.28-.02.41,0,.28,0,.54,0,.81,0,1.05.05,2.02.14,2.88.16,1.74.42,3.1.61,4.01.09.46.17.78.23,1.04.06.25.1.38.1.38l-3.63,1.04s-.04-.12-.12-.37c-.08-.23-.21-.65-.35-1.15-.16-.56-.35-1.26-.53-2.11-.39-2.25-.59-4.55-.59-6.91,0-22.25,18.04-40.28,40.29-40.28,10.02,0,19.19,3.66,26.24,9.72,3.1,2.66,5.79,5.79,7.96,9.27,3.86,6.18,6.09,13.48,6.09,21.3Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M87.6,33.44c-.75-1.14-1.94-1.47-3.15-.88-1.33.65-2.62,1.41-3.88,2.15l-.31.18c-2.04,1.2-4.07,2.41-6.11,3.62l-3.46,2.05c-2.26-.95-4.96-.69-6.99.69-.36.25-.71.53-1.03.85-2.51,2.49-2.89,6.42-.89,9.34,1.36,2,3.63,3.19,6.05,3.19,1.02,0,2.01-.21,2.95-.62.4-.17.8-.4,1.18-.66,2.06-1.4,3.27-3.8,3.19-6.28,1.96-1.69,4.98-4.25,7.41-6.3l.21-.18c2.28-1.93,4.08-3.45,4.46-3.8,1.03-.93,1.16-2.16.37-3.35Z"
    />
    <path
      strokeWidth={0}
      d="M121.12,92.69c-1.4-.18-2.98.12-4.33.61-8.13,2.95-15.87,6.39-23.97,9.44-.4.15-.86.32-1.12.63-2.11,2.48-4.89,3.49-8,3.52-5.04.06-10.07.23-15.11.35-3.42.08-6.83.17-10.25.23-.86.02-1.95-.95-2.01-1.96-.06-1.01.63-1.35,1.51-1.44.35-.03.7-.06,1.05-.07,4.25-.11,8.5-.21,12.75-.32,4.47-.11,8.94-.14,13.4-.36,3.12-.15,5.24-2.47,5.29-5.46.06-3.21-2.43-5.64-5.82-5.57-5.01.1-11.35-.59-16.5-1.39-6.12-.95-9.09-4.08-15.03-5.98-9.14-2.94-18.5-1.87-27.94-1.68-.02-.66-.03-1.31-.05-1.97-.05-2.08-1.06-2.98-3.16-2.93-5.52.13-11.03.26-16.55.4-.34,0-.68.05-1.29.1.31,2.94.52,5.8.92,8.64,1.51,10.58,5.19,20.36,10.97,29.34.27.41.93.78,1.42.8,1.88.05,3.76-.04,5.64-.1,1.86-.07,2.89-1.12,2.85-3.02-.01-.54-.03-1.08-.04-1.62,0,0,.02,0,.02,0,4.24,1.21,8.47,2.48,12.71,3.71,7.89,2.28,15.74,4.74,23.7,6.74,6.6,1.66,13.26,1.86,19.54-1.6,13.3-7.32,26.61-14.62,39.89-21.96.66-.37,1.26-.97,1.71-1.59,1.5-2.13.37-5.15-2.21-5.48Z"
    />
  </svg>
);
export default ResourceTimingIcon;
