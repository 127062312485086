import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import { ItemListCard } from '../../../dashboard/containers/ItemListCard';
// import * as Icon from '../components/icons';

export type BetaCardProps = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  action?: string;
};

export const BetaCard = ({
  children,
  title,
  subtitle,
  action,
}: BetaCardProps) => {
  const [id] = useState<string>(_.uniqueId());

  const { t } = useTranslation('app-beta');

  return (
    <ItemListCard
      titleTrace={`${subtitle || `Beta`} ${title}`}
      isDemo
      className="flex flex-col"
    >
      {title && <ScreenTitle subtitle={subtitle || t`Beta`} title={title} />}
      {/* {action && (
        <Button
          className="p-1 mt-8 mb-4 rounded-full"
          label={
            action?.featureRequests?.billing
              ? 'Unsubscribe'
              : 'Join the Waitlist'
          }
          buttonType={action?.featureRequests?.billing ? '' : 'primary'}
          isDisabled={settingsStatus === AsyncLoadStatus.Loading}
          isLoading={settingsStatus === AsyncLoadStatus.Loading}
          full
          hasIconAfter={action?.featureRequests?.billing}
          hasIconBefore={!action?.featureRequests?.billing}
          buttonIcon={
            action?.featureRequests?.billing ? (
              <Icon.CircleOk className={`w-7 h-7 fill-current`} />
            ) : (
              <Icon.CircleArrowRight className={`w-8 h-8 fill-current`} />
            )
          }
          onPress={() => {
            updateUserSettings({
              featureRequests: {
                billing: !action.featureRequests?.billing,
              },
            });
          }}
        />
      )} */}
      {children}
    </ItemListCard>
  );
};
