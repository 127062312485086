import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  HeatmapBucket,
  getHeatmapBucketTitle,
} from '../../common/heatmap.helper';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import { heatmapLegendSortOrder } from '../store/simulation.state';
import { HeatmapFilter } from '../store/simulation.types';
import { LegendItem } from './LegendItem';
const locationColor = TwTheme.extend.colors.location;

export type HeatmapLegendBucketsProps = {
  title: string;
  buckets: HeatmapBucket[];
  metric: any; //HeatmapMetricDescriptor<string, Record<string, any>>;
  hasDisabledOption?: boolean;
  hasEmptyOption?: boolean;
  heatmapFilter: HeatmapFilter;
  setHeatmapFilter: SetterOrUpdater<HeatmapFilter>;
};

const HeatmapLegendBuckets: React.FC<HeatmapLegendBucketsProps> = props => {
  const { t } = useTranslation('simulation');
  const { heatmapFilter, setHeatmapFilter } = props;
  const [sortOrder, setSortOrder] = useRecoilState(heatmapLegendSortOrder);
  const [allSelected, setAllSelected] = useState(true);

  const iconStyle = 'h-4 w-4 mx-1';

  const sortOptions = [
    {
      id: 'asc',
      title: t('Sort Asc.'),
      icon: 'SortAscending',
    },
    {
      id: 'desc',
      title: t('Sort Desc.'),
      icon: 'SortDescending',
    },
  ];

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [props.heatmapFilter, props.buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    props.setHeatmapFilter(heatmapFilter => ({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: value,
      },
    }));
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      props.buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );
    props.setHeatmapFilter(heatmapFilter => ({
      ...heatmapFilter,
      hiddenBuckets,
    }));
  };

  return (
    <TitleToolbarContainer
      id="panel-toolbar-heatmap-hit-count"
      title={props.title}
      collapsible
      hasScroll
      classNameContainerBodyChildren="max-h-[88vh]"
    >
      {/* <TitleToolbarContainer id="sort-order" title={t`Sort Order`} collapsible> */}
      {props.buckets.length > 10 && (
        <>
          {/* <TitleToolbarContainer
            id="sort-order"
            title={t`Sort Order`}
            collapsible
          > */}
          <div className="flex w-full items-center px-px">
            <DropdownSelector
              // w_sm
              DropAlignRight
              className="text-xxs w-full"
              classNameValue="text-menu-text/70 hover:text-menu-text"
              iconSelection={
                sortOrder === 'asc' ? Icon.SortDescending : Icon.SortAscending
              }
              iconSelectionClass={iconStyle}
              value={sortOrder}
              values={sortOptions.map(option => option.id)}
              renderValue={(v: string) =>
                sortOptions.find(option => option.id === v)?.title
              }
              onChange={(v: string) => setSortOrder(v as 'asc' | 'desc')}
            />
          </div>
          {/* </TitleToolbarContainer> */}

          {/* <TitleToolbarContainer
            id="search-filter"
            title={t`Search`}
            collapsible
          >
            <input
              type="text"
              className="text-menuw-full bg-menu/20 p-1s rounded text-xs"
              placeholder={t`Filter buckets...`}
              onChange={e => {
                const searchTerm = e.target.value.toLowerCase();
                const filteredBuckets = props.buckets.filter(bucket =>
                  getHeatmapBucketTitle(bucket, props.metric, t`Unknown`)
                    .toLowerCase()
                    .includes(searchTerm),
                );
              }}
            />
          </TitleToolbarContainer> */}
        </>
      )}
      {/* <DropdownSelector
        w_xs
        className="text-menu-text text-xxs w-full"
        buttonTransparent
        value={sortOrder}
        values={['asc', 'desc']}
        renderValue={v => t(v === 'asc' ? 'Sort Asc.' : 'Sort Dec.')}
        onChange={v => setSortOrder(v as 'asc' | 'desc')}
      /> */}
      {/* </TitleToolbarContainer> */}

      <Container col>
        {_(props.buckets)
          .orderBy([b => b.index], [sortOrder === 'asc' ? 'asc' : 'desc'])
          .map(bucket => {
            const metricDescriptor = props.metric;

            const isHidden =
              !allSelected && heatmapFilter.hiddenBuckets[bucket.id] === true;

            const hasIssue = bucket.title === 'undefined';
            const hasNoActivity =
              metricDescriptor?.titleCategory === 'Time' &&
              bucket.title === '0';

            let title;
            if (hasIssue) {
              title = t`Unknown`;
            } else if (bucket.title === '0') {
              if (
                // metricDescriptor?.type === 'lastActivity' ||
                metricDescriptor?.titleCategory === 'Time'
              ) {
                title = t`No Activity`;
              } else if (metricDescriptor?.type === 'visits') {
                title = t`0 Visits`;
              } else {
                title = t`0`;
              }
            } else {
              title = getHeatmapBucketTitle(
                bucket,
                metricDescriptor,
                t`Unknown`,
              );
            }

            return (
              <ButtonToolbar
                key={`legend-item-${bucket.id}`}
                titleTrace={`Legend: Item: ${title}`}
              >
                <LegendItem
                  classNameText={classNames(
                    'max-w-ch-20 truncate text-ellipsis',
                  )}
                  color={bucket.color}
                  text={title}
                  labelRaw={title}
                  isHidden={isHidden}
                  onClick={evt => {
                    debouncedClick(bucket.id, !isHidden, evt);
                  }}
                />
              </ButtonToolbar>
            );
          })
          .value()}

        {(props.hasEmptyOption || props.hasDisabledOption) && (
          <hr className={classNames('border-menu/50 mb-1 mt-1')} />
        )}

        {props.hasEmptyOption && (
          <ButtonToolbar titleTrace={`Toggle: Empty visits`}>
            <LegendItem
              key={`legend-item-empty`}
              text={t`Empty`}
              color={locationColor.DEFAULT}
              isHidden={heatmapFilter?.hideEmpty}
              onClick={() =>
                setHeatmapFilter({
                  ...heatmapFilter,
                  hideEmpty: !heatmapFilter.hideEmpty,
                })
              }
            />
          </ButtonToolbar>
        )}

        {props.hasDisabledOption && (
          <ButtonToolbar titleTrace={`Toggle: Disabled`}>
            <LegendItem
              key={`legend-item-disabled`}
              text={t`Disabled`}
              color={locationColor.disabled}
              isHidden={heatmapFilter?.hideDisabled}
              onClick={() =>
                setHeatmapFilter({
                  ...heatmapFilter,
                  hideDisabled: !heatmapFilter.hideDisabled,
                })
              }
            />
          </ButtonToolbar>
        )}
      </Container>
    </TitleToolbarContainer>
  );
};

export default HeatmapLegendBuckets;
