import { SizeComplianceStatus } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSizeColorByStatus,
  getSizeComplianceStatusMap,
} from '../../../store/sizeCompliance.helper';

type SizeComplianceStatusTagProps = {
  status: SizeComplianceStatus;
};

const SizeComplianceStatusTag: React.FC<
  SizeComplianceStatusTagProps
> = props => {
  const { t } = useTranslation('simulation');
  const statusMap = getSizeComplianceStatusMap(t);
  const [background, color] = getSizeColorByStatus(
    props.status ?? SizeComplianceStatus.UNKNOWN,
  );
  return (
    <span
      aria-label={`Size Compliance Status`}
      className={`text-center px-2 py-1 rounded text-xxs flex-1 whitespace-nowrap`}
      style={{
        background,
        color,
      }}
    >
      {statusMap[props.status]}
    </span>
  );
};

export default SizeComplianceStatusTag;
