import {
  validate,
  ValidationError,
  ValidatorOptions,
} from '@warego/class-validator';
import { plainToClass, ClassConstructor } from 'class-transformer';
import {
  ErrorOption,
  FieldError,
  FieldErrors,
  FieldName,
  Resolver,
} from 'react-hook-form';
import _ from 'lodash';

export function toFieldError(validationError: ValidationError): FieldError {
  const firstConstraint = _.toPairs(validationError.constraints)[0];
  return {
    type: firstConstraint[0],
    message: firstConstraint[1],
  };
}

export function toFieldErrors<T extends object>(
  validationErrors: ValidationError[],
): FieldErrors<T> {
  return validationErrors.reduce(
    (prev, error) => ({
      ...prev,
      [error.property]: toFieldError(error),
    }),
    {},
  );
}

export function useFieldErrors<T extends object>(
  setError: (name: FieldName<T>, error: ErrorOption) => void,
): (ves: ValidationError[]) => void {
  return validationErrors => {
    validationErrors.forEach(ve => {
      setError(ve.property as FieldName<T>, toFieldError(ve));
    });
  };
}

/**
 * Resolver for react-hook-forms based on class-validator.
 * @param Type form type
 * @param validatorOptions validator options
 */
export default function classValidatorResolver<T extends object>(
  Type: ClassConstructor<T>,
  validatorOptions?: ValidatorOptions,
): Resolver<T, any> {
  return async values => {
    const typedValues = plainToClass(Type, values);
    return validate(typedValues, validatorOptions).then(errors => {
      if (errors.length === 0) {
        return { values, errors: {} };
      }

      return {
        values: {},
        errors: toFieldErrors(errors),
      };
    });
  };
}
