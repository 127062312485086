import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatTimespan } from '../../common/formatHelper';
import { Stat } from '../../components/stats/Stat';
import AnalyzeEventsDetailsPanel from '../panels/AnalyzeEventsDetailsPanel';
import AnalyzeStatsPanel from '../panels/AnalyzeStatsPanel';
import { SimulationDashboardCardProps } from './SimulationDashboardCard';

const SimulationAnalyzedCardContent: React.FC<
  SimulationDashboardCardProps
> = props => {
  const { t } = useTranslation('simulation');
  const { sim } = props;

  const analyzeTime = formatTimespan(
    sim.analyzeResult?.completedAt ?? 0,
    sim.analyzeResult?.startedAt ?? 0,
  );

  const hasEvents = !_.isEmpty(sim.analyzeResult?.summary?.eventTypes);

  return (
    <>
      <AnalyzeStatsPanel
        dashboardSimulationView
        analyzeResult={sim.analyzeResult}
      />
      {hasEvents && (
        <AnalyzeEventsDetailsPanel
          dashboardSimulationView
          cardView
          events={sim.analyzeResult.summary?.eventTypes}
          eventsTotal={{
            distance: sim.analyzeResult.totalDistance,
            cost: sim.analyzeResult.totalCost,
            duration: sim.analyzeResult.totalDuration,
          }}
        />
      )}
      <Stat
        isMetaValue
        transparent
        title={t`Analyse Stats:`}
        value={analyzeTime}
      />
    </>
  );
};

export default SimulationAnalyzedCardContent;
