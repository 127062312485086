import * as React from 'react';
const SelectWithMenuIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SelectWithMenuIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M9.84,11.43c0-4.32,2.54-5.41,5.65-2.41l75.93,73.06c3.12,3,2.16,4.99-2.13,4.42l-38.96-5.16c-4.29-.57-9.88,1.82-12.44,5.3l-23.25,31.68c-2.56,3.49-4.65,2.8-4.66-1.52l-.15-105.37Z"
    />
    <polygon strokeWidth={0} points="116 116 96 116 116 96 116 116" />
  </svg>
);
export default SelectWithMenuIcon;
