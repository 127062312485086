import * as React from 'react';
const DesignerBaysLocationsProfileIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerBaysLocationsProfileIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124.29,110.26H19.74s0-106.55,0-106.55c0-.94-.77-1.71-1.71-1.71H3.71c-.94,0-1.71.77-1.71,1.71v9.15s11.32,0,11.32,0c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2s0,7.57,0,7.57h11.33c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v7.57h11.33c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H2v31.11h0c0,.94.77,1.71,1.71,1.71h26.8s0,0,0,0h4.32v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57v-11.33c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v11.33h7.57s0-6.57,0-6.57c0-.94.77-1.71,1.71-1.71.94,0,1.71.76,1.71,1.71v6.57h7.57v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57s0-11.33,0-11.33c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v11.33h7.57v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57s0-11.33,0-11.33c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v11.33h9.14c.94,0,1.71-.77,1.71-1.71v-12.32c0-.94-.77-1.71-1.71-1.71Z"
      opacity={0.7}
    />
    <rect width={91.03} height={4.37} x={27.1} y={97.81} strokeWidth={0} />
    <rect
      width={19.71}
      height={17.27}
      x={39.27}
      y={14.36}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={62.02}
      y={14.36}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={84.78}
      y={14.36}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={39.27}
      y={44.45}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={62.02}
      y={44.45}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={84.78}
      y={44.45}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={39.27}
      y={74.53}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={62.02}
      y={74.53}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <rect
      width={19.71}
      height={17.27}
      x={84.78}
      y={74.53}
      strokeWidth={0}
      rx={1.18}
      ry={1.18}
    />
    <path
      strokeWidth={0}
      d="M34.98,98.34h1.48l-.64-2.7h-.85v-21.39l1.49-.41v-.91h70.45v.92l1.49.41v21.39h-.85l-.64,2.7h6.75l-.64-2.7h-.85V8.77h-3.78v28.67l-1.49.41v.92H36.47v-.92l-1.49-.41V8.77h-3.78v86.88h-.85l-.64,2.7h5.27ZM106.92,42.84v.92l1.49.41v23.36l-1.49.41v.92H36.47v-.92l-1.49-.41v-23.36l1.49-.41v-.91h70.45Z"
    />
    <path
      strokeWidth={0}
      d="M58.97,35.74h-1.01v-1.49h1.01v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92ZM48.34,35.74h-6.51v-1.49h6.51v1.49ZM56.41,35.74h-6.51v-1.49h6.51v1.49Z"
    />
    <path
      strokeWidth={0}
      d="M81.55,35.74h-1.01v-1.49h1.01v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92ZM70.92,35.74h-6.51v-1.49h6.51v1.49ZM78.98,35.74h-6.51v-1.49h6.51v1.49Z"
    />
    <path
      strokeWidth={0}
      d="M104.49,35.74h-1.01v-1.49h1.01v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92ZM93.86,35.74h-6.51v-1.49h6.51v1.49ZM101.92,35.74h-6.51v-1.49h6.51v1.49Z"
    />
    <path
      strokeWidth={0}
      d="M58.97,65.97h-1.01v-1.49h1.01v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92ZM48.34,65.97h-6.51v-1.49h6.51v1.49ZM56.41,65.97h-6.51v-1.48h6.51v1.48Z"
    />
    <path
      strokeWidth={0}
      d="M81.55,65.97h-1.01v-1.49h1.01v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92ZM70.92,65.97h-6.51v-1.49h6.51v1.49ZM78.98,65.97h-6.51v-1.48h6.51v1.48Z"
    />
    <path
      strokeWidth={0}
      d="M104.49,65.97h-1.01v-1.49h1.01v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92ZM93.86,65.97h-6.51v-1.49h6.51v1.49ZM101.92,65.97h-6.51v-1.48h6.51v1.48Z"
    />
    <path
      strokeWidth={0}
      d="M58.97,94.41v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92h-1.01v-1.49h1.01ZM48.34,95.89h-6.51v-1.49h6.51v1.49ZM56.41,95.89h-6.51v-1.49h6.51v1.49Z"
    />
    <path
      strokeWidth={0}
      d="M81.55,94.41v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92h-1.01v-1.49h1.01ZM70.92,95.89h-6.51v-1.49h6.51v1.49ZM78.98,95.89h-6.51v-1.49h6.51v1.49Z"
    />
    <path
      strokeWidth={0}
      d="M104.49,94.41v-1.92h-19.71v1.92h1.01v1.49h-1.01v1.92h19.71v-1.92h-1.01v-1.49h1.01ZM93.86,95.89h-6.51v-1.49h6.51v1.49ZM101.92,95.89h-6.51v-1.49h6.51v1.49Z"
    />
  </svg>
);
export default DesignerBaysLocationsProfileIcon;
