import * as React from 'react';
const WarehouseLibraryIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseLibraryIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M31.28,58.35l11.24-10.36h-19.07c-.51,0-1.01.13-1.39.38l-12.63,7.2c-.38.25-.63.63-.63,1.14v6.82h21.22c-.76-1.89-.25-3.92,1.26-5.18Z"
    />
    <path
      strokeWidth={0}
      d="M97.98,63.53h21.22v-6.82c0-.51-.25-.88-.63-1.14l-12.63-7.2c-.38-.25-.88-.38-1.39-.38h-19.07l11.24,10.36c1.52,1.26,1.89,3.28,1.26,5.18Z"
    />
    <path
      strokeWidth={0}
      d="M122.99,90.18h-3.79v-23.62h-28.67v23.62h-3.16v-26.65h6.19c1.52,0,2.27-1.89,1.14-2.91l-29.31-26.78c-.76-.76-2.02-.76-2.78,0l-29.31,26.78c-1.14,1.01-.38,2.91,1.14,2.91h6.19v26.65h-3.16v-23.62H8.8v23.62h-3.79c-.51,0-1.01.51-1.01,1.01v2.53c0,.51.51,1.01,1.01,1.01h117.98c.51,0,1.01-.51,1.01-1.01v-2.53c0-.51-.51-1.01-1.01-1.01ZM20.67,87.15c0,.51-.38.88-.88.88h-4.17c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04ZM20.67,76.92c0,.51-.38.88-.88.88h-4.17c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04ZM31.41,87.15c0,.51-.38.88-.88.88h-4.17c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04ZM31.41,76.92c0,.51-.38.88-.88.88h-4.17c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04ZM50.86,84.62c0,1.14-1.01,2.15-2.15,2.15s-2.15-1.01-2.15-2.15v-18.95c0-1.14,1.01-2.15,2.15-2.15s2.15,1.01,2.15,2.15v18.95ZM60.97,84.62c0,1.14-1.01,2.15-2.15,2.15s-2.15-1.01-2.15-2.15v-18.95c0-1.14,1.01-2.15,2.15-2.15s2.15,1.01,2.15,2.15v18.95ZM64,54.43c-1.77,0-3.28-1.52-3.28-3.28s1.52-3.28,3.28-3.28,3.28,1.52,3.28,3.28c0,1.89-1.52,3.28-3.28,3.28ZM71.2,84.62c0,1.14-1.01,2.15-2.15,2.15s-2.15-1.01-2.15-2.15v-18.95c0-1.14,1.01-2.15,2.15-2.15s2.15,1.01,2.15,2.15v18.95ZM81.43,84.62c0,1.14-1.01,2.15-2.15,2.15s-2.15-1.01-2.15-2.15v-18.95c0-1.14,1.01-2.15,2.15-2.15s2.15,1.01,2.15,2.15v18.95ZM102.27,87.15c0,.51-.38.88-.88.88h-4.17c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04ZM102.27,76.92c0,.51-.38.88-.88.88h-4.17c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04ZM113.14,87.15c0,.51-.38.88-.88.88h-4.04c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04h-.13ZM113.14,76.92c0,.51-.38.88-.88.88h-4.04c-.51,0-.88-.38-.88-.88v-4.04c0-.51.38-.88.88-.88h4.17c.51,0,.88.38.88.88v4.04h-.13Z"
    />
    <path
      strokeWidth={0}
      d="M65.11,36.45v-8.07c.43.09.84.13,1.24.13,1.44,0,2.82-.51,4.02-.95,2.11-.79,3.48-1.21,4.87-.17.41.29.95.33,1.4.11.44-.23.73-.68.73-1.19v-10.06c0-.43-.2-.83-.55-1.08-2.62-1.94-5.26-.96-7.38-.17-2.12.79-3.48,1.21-4.88.17-.77-.59-2.12-.11-2.12,1.08v20.19"
    />
  </svg>
);
export default WarehouseLibraryIcon;
