import * as React from 'react';
const HeatMap4Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HeatMap4Icon"
    {...props}
  >
    <rect width={57.72} height={57.72} x={3.46} y={3.57} strokeWidth={0} />
    <rect
      width={57.72}
      height={57.72}
      x={65.74}
      y={3.57}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={57.72}
      height={57.72}
      x={3.46}
      y={65.85}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={57.72}
      height={57.72}
      x={65.74}
      y={65.85}
      strokeWidth={0}
      opacity={0.6}
    />
  </svg>
);
export default HeatMap4Icon;
