import * as React from 'react';
const HandIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HandIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M45.24,66.14c0-.45-.12-54.25-.03-54.67,0-4.12,3.35-7.47,7.47-7.47s7.47,3.35,7.47,7.47v47.82c-.02.42.29.75.69.76.41,0,.72-.3.73-.69v-20.52c0-4.12,3.35-7.47,7.47-7.47s7.47,3.35,7.47,7.47v20.44c-.02.42.29.75.69.76.41,0,.72-.3.73-.69v-12.34c.26-9.84,14.68-9.86,14.94,0,0,0,0,16.41,0,16.41.02.86,1.43.84,1.42-.04,0,0,0-8.2,0-8.2,0-4.12,3.35-7.47,7.47-7.47s7.47,3.35,7.47,7.47l-.02,18.67c0,30.93-14.06,50.14-36.7,50.12-20.64.2-33.97-16.54-38.53-33.44-2.32-6.8-4.71-13.83-10.61-21.22-15.38-18.99,12.12-22.54,20.62,6.75,1.65,5.67,1.23-7.22,1.25-9.96Z"
    />
  </svg>
);
export default HandIcon;
