import {
  ImportJobTargetType,
  useUpdateImportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { cloneWithoutTypename } from '../common/utils';
import { errorAppender } from '../store/error.state';
import { createMappingSpecInput } from './store/import.helper';
import {
  importJob,
  importMappingSettingsByType,
  importTargetDatasetId,
  importTypeCurrent,
} from './store/import.state';

function useUpdateImportJob() {
  const { t } = useTranslation('errors');
  const [updateJob] = useUpdateImportJobMutation();
  const errorTitle = t`Cannot update import job`;

  const callProcess = useRecoilCallback(({ snapshot, set }) => async () => {
    const job = await snapshot.getPromise(importJob);
    const type = await snapshot.getPromise(importTypeCurrent);
    const targetId = await snapshot.getPromise(importTargetDatasetId);
    const mappingSettings = await snapshot.getPromise(
      importMappingSettingsByType(type),
    );

    function handleError(details?: string, stack?: string) {
      console.error(errorTitle, details, stack);
      set(errorAppender, {
        id: nanoid(),
        title: errorTitle,
        details: details,
        callStack: stack,
      });
    }

    try {
      if (_.isNil(job)) {
        handleError(`Incorrect process job call. Job is not yet created `);
        return;
      }

      const { data: updateJobData, errors: updateJobErrors } = await updateJob({
        variables: {
          input: {
            id: job.id,

            settings: {
              ..._.omit(cloneWithoutTypename(mappingSettings), ['fields']),
              mapping: createMappingSpecInput(mappingSettings),
            },
            targetSettings: _.isEmpty(targetId)
              ? undefined
              : {
                  target: ImportJobTargetType.APPEND,
                  appendSettings: {
                    targetId,
                  },
                },
          },
        },
      });

      if (!_.isEmpty(updateJobErrors)) {
        const details = _(updateJobErrors)
          .map(j => j.message)
          .join(';');
        handleError(details);
        return;
      }
    } catch (ex) {
      handleError(ex?.message ?? ex);
    }
  });

  async function call() {
    await callProcess();
  }

  return [call] as const;
}
export default useUpdateImportJob;
