import { ItemsFilter } from '@warebee/frontend/data-access-api-graphql';
import { default as classnames } from 'classnames';
import _ from 'lodash';
import { ReactNode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { Button } from '../components/actions/Button';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import * as Icon from '../components/icons';
import { InputSearch } from '../components/inputs/InputSearch';
import { ContainerCol } from '../components/layout/ContainerFlex';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import { ActionBarFooter } from '../components/nav/ActionBar';
import { Table } from '../components/table/Table';
import { TableBody } from '../components/table/TableBody';
import { TableHead } from '../components/table/TableHead';
import { TableRowBody } from '../components/table/TableRowBody';
import { TableRowHead } from '../components/table/TableRowHead';
import { TableTd } from '../components/table/TableTd';
import { TableTh } from '../components/table/TableTh';
import useLoadItemsByRule from './hooks/useLoadItemsByRule';
import useLoadPalletPolicyItemsData from './hooks/useLoadPalletPolicyItemsData';
import {
  PALLET_ORDER_BOTTOM_RULE_ID,
  PALLET_ORDER_TOP_RULE_ID,
} from './store/itemSet.default';
import {
  itemSetDocument,
  itemSetPalletSortPolicy,
  itemSetPalletSortPolicyData,
  itemSetPalletSortPolicyDataState,
  itemSetPalletSortPolicyDataStatus,
  itemSetSelectedRuleResultsId,
  itemSetViewSearchValues,
} from './store/itemSet.state';
import { PalletOrderRuleItemData } from './store/itemSet.types';

const sortOptions = ['Ascending', 'Descending'] as const;
type SortOptionsTuple = typeof sortOptions;
export type sortOption = SortOptionsTuple[number];

const ItemSetWeightPolicyView = () => {
  const { t } = useTranslation('dataset');
  const itemSet = useRecoilValue(itemSetDocument);
  const data = useRecoilValue(itemSetPalletSortPolicyData);
  const dataLoadStatus = useRecoilValue(itemSetPalletSortPolicyDataStatus);
  const dataState = useRecoilValue(itemSetPalletSortPolicyDataState);
  const selectedRuleResults = useRecoilValue(itemSetSelectedRuleResultsId);
  const policy = useRecoilValue(itemSetPalletSortPolicy);
  const formatter = useFormatter();

  const [loadAll, cancelLoadAll] = useLoadPalletPolicyItemsData();
  const loadByRule = useLoadItemsByRule();

  const [searchValues, setSearchValues] = useRecoilState(
    itemSetViewSearchValues,
  );

  type pageParam = {
    isAppend: boolean;
    skip: number;
  };
  function callDataLoad(
    searchValues: Record<string, string>,
    page: pageParam = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(itemSet?.id)) return;

    const filter: ItemsFilter = {
      consigneeContains: searchValues['consignee'],
      skuContains: searchValues['sku'],
      skuGroupContains: searchValues['skuGroup'],
    };

    if (_.isNil(selectedRuleResults)) {
      cancelLoadAll();
      loadAll({
        itemSetId: itemSet?.id,
        policy,
        filter,
        ...page,
      });
    } else {
      loadByRule({
        itemSetId: itemSet.id,
        policy,
        ruleId: selectedRuleResults,
        itemFilter: filter,
        ...page,
      });
    }
  }

  const debouncedDataLoad = useCallback(
    _.debounce(searchValues => callDataLoad(searchValues), 500),
    [itemSet?.id, selectedRuleResults, policy],
  );

  useEffect(() => {
    callDataLoad(searchValues);
  }, [itemSet?.id, selectedRuleResults, policy]);

  function onSearch(field: string, value: string) {
    const newSearchValues = { ...searchValues, [field]: value };
    setSearchValues({ ...searchValues, [field]: value });
    debouncedDataLoad(newSearchValues);
  }

  const itemsCount = data?.length || 0;
  const totalCount = dataState.totalCount;
  const itemsCountFormatted = formatInteger(itemsCount);
  const totalCountFormatted = formatInteger(totalCount);

  const isLoading = dataLoadStatus === AsyncLoadStatus.Loading;

  const weightRange = policy.totalNetWeightRange;

  function onLoadNext() {
    callDataLoad(searchValues, { isAppend: true, skip: itemsCount });
  }

  type Bucket = 'top' | 'middle' | 'bottom';

  function getItemsCount(
    item: PalletOrderRuleItemData,
    bucket: Bucket,
  ): ReactNode {
    // First check if item strictly specified to bucket
    if (item.palletOrderPolicyRuleId === PALLET_ORDER_BOTTOM_RULE_ID) {
      return bucket === 'bottom' ? (
        <div className="border-priority-bottom text-priority-bottom flex items-center justify-center rounded border p-0.5 text-xs">
          <span className={`px-1 text-xs`}>{t`Bottom Stack Priority`}</span>
        </div>
      ) : null;
    }
    if (item.palletOrderPolicyRuleId === PALLET_ORDER_TOP_RULE_ID) {
      return bucket === 'top' ? (
        <div className="border-priority-top text-priority-top flex items-center justify-center rounded border p-0.5 text-xs">
          <span className={`px-1 text-xs`}>{t`Top Stack Priority`}</span>
        </div>
      ) : null;
    }
    // If no item's weight provided or weight  === 0 , qualify item as always in 'Middle' bucket
    if (
      !item?.baseUom?.netWeight ||
      (_.isNil(weightRange.to) && _.isNil(weightRange.from))
    ) {
      return bucket === 'middle' ? (
        <div className="border-priority-none text-priority-none flex items-center justify-center rounded border p-0.5 text-xs opacity-50">
          <span className={`px-1 text-xs`}>{t`No Policy Assigned`}</span>
        </div>
      ) : null;
    }

    //Calculate item's count range for every bucket
    switch (bucket) {
      case 'top':
        if (
          _.isNil(weightRange.from) ||
          item.baseUom.netWeight > weightRange.from
        )
          return null;

        return (
          <div className="bg-priority-top text-menu-text flex items-center rounded p-1 text-xs">
            <Icon.ChevronLeft className={`h-6 w-6 fill-current`} />
            <div>{`${Math.ceil(
              weightRange.from / item.baseUom.netWeight,
            )}`}</div>
          </div>
        );

      case 'bottom':
        if (_.isNil(weightRange.to)) return null;

        return (
          <div className="bg-priority-bottom text-menu-text flex items-center rounded p-1 text-xs">
            <Icon.ChevronRight className={`h-6 w-6 fill-current`} />
            <div>{`${Math.ceil(weightRange.to / item.baseUom.netWeight)}`}</div>
          </div>
        );
      // return ` >   ${Math.ceil(weightRange.to / item.baseUom.netWeight)}`;

      case 'middle':
        if (_.isNil(weightRange.to))
          // return ` >   ${Math.ceil(weightRange.from / item.baseUom.netWeight)}`;
          return (
            <div className="bg-priority-none text-menu-text flex items-center rounded p-1 text-xs">
              <Icon.ChevronRight className={`h-6 w-6 fill-current`} />
              <div>{`${Math.ceil(
                weightRange.from / item.baseUom.netWeight,
              )}`}</div>
            </div>
          );
        if (_.isNil(weightRange.from))
          return (
            <div className="bg-priority-none text-menu-text flex items-center rounded p-1 text-xs">
              <Icon.ChevronLeft className={`h-6 w-6 fill-current`} />
              <div>{`${Math.ceil(
                weightRange.to / item.baseUom.netWeight,
              )}`}</div>
            </div>
          );
        // return ` <   ${Math.ceil(weightRange.to / item.baseUom.netWeight)}`;

        return (
          <div className="bg-priority-none text-menu-text flex items-center justify-center rounded p-1 text-xs">
            <div className={``}>{`${Math.ceil(
              weightRange.from / item.baseUom.netWeight,
            )}`}</div>
            <div className={`flex flex-1 items-center`}>
              <Icon.Minus className={`h-6 w-6 flex-1 fill-current`} />
            </div>
            <div className={``}>{`${Math.ceil(
              weightRange.to / item.baseUom.netWeight,
            )}`}</div>
          </div>
        );

      // return ` ${Math.ceil(
      //   weightRange.from / item.baseUom.netWeight,
      // )} ... ${Math.ceil(weightRange.to / item.baseUom.netWeight)}`;
    }
  }

  function getItemsCountAll(item: PalletOrderRuleItemData): ReactNode {
    // First check if item strictly specified to bucket
    if (item.palletOrderPolicyRuleId === PALLET_ORDER_BOTTOM_RULE_ID) {
      return (
        <div className="bg-priority-bottom text-menu-text flex items-center justify-center rounded  p-0.5 text-xs">
          <span className={`px-1 text-xs uppercase`}>{t`Bottom`}</span>
        </div>
      );
    }
    if (item.palletOrderPolicyRuleId === PALLET_ORDER_TOP_RULE_ID) {
      return (
        <div className="bg-priority-top text-menu-text flex items-center justify-center rounded  p-0.5 text-xs">
          <span className={`px-1 text-xs uppercase`}>{t`Top`}</span>
        </div>
      );
    }
    // If no item's weight provided or weight  === 0 , qualify item as always in 'Middle' bucket
    if (
      (item?.baseUom?.netWeight ?? 0) === 0 ||
      (_.isNil(weightRange.to) && _.isNil(weightRange.from))
    ) {
      return (
        <div className="bg-priority-none text-menu-text flex items-center justify-center rounded  p-0.5 text-xs opacity-50">
          <span className={`px-1 text-xs`}>{t`No Policy Assigned`}</span>
        </div>
      );
    }

    const hasTopRange =
      !_.isNil(weightRange.from) && item.baseUom.netWeight < weightRange.from;
    const topRangeValue = hasTopRange
      ? `${Math.ceil(weightRange.from / item.baseUom.netWeight)}`
      : 'never';

    const hasBottomRange = !_.isNil(weightRange.to);
    const bottomRangeValue = hasBottomRange
      ? `${Math.ceil(weightRange.to / item.baseUom.netWeight)}`
      : 'never';

    const middleToBorder = _.isNil(weightRange.to)
      ? ' any '
      : `${Math.ceil(weightRange.to / item.baseUom.netWeight)}`;
    const middleFromBorder = _.isNil(weightRange.from)
      ? ' any '
      : `${Math.ceil(weightRange.from / item.baseUom.netWeight)}`;
    return (
      <div className={`group flex space-x-1`}>
        <div className="border-priority-top text-priority-top bg-app-panel-dark/60 flex flex-1 items-center rounded border p-1 text-xs">
          {hasTopRange && (
            <Icon.ChevronLeft className={`h-4 w-4 fill-current`} />
          )}
          <div className={`flex-1`}>{topRangeValue}</div>
        </div>
        <div className="group-hover:block_ border-priority-none text-priority-none flex hidden flex-1 items-center justify-center rounded p-1 text-xs">
          <div className={``}>{middleFromBorder}</div>
          <div className={`flex flex-1 items-center`}>
            <Icon.Minus className={`h-4 w-4 flex-1 fill-current`} />
          </div>
          <div className={``}>{middleToBorder}</div>
        </div>
        <div className="border-priority-bottom text-priority-bottom bg-app-panel-dark/60 flex flex-1 items-center rounded border p-1 text-xs">
          {hasTopRange && (
            <Icon.ChevronRight className={`h-4 w-4 fill-current`} />
          )}
          <div className={`flex-1`}>{bottomRangeValue}</div>
        </div>
      </div>
    );
  }

  const reduceItemSet = (itemSet: PalletOrderRuleItemData) => {
    const base = _.omit(itemSet, 'baseUom');
    const uom = itemSet.baseUom;
    return {
      ...base,
      ...uom,
      policy: getItemsCountAll(itemSet),
      light: getItemsCount(itemSet, 'top'),
      medium: getItemsCount(itemSet, 'middle'),
      heavy: getItemsCount(itemSet, 'bottom'),
    };
  };

  const flattenData = _.flatMap(data, reduceItemSet);
  type ColumnConfig = {
    field: string;
    title: string;
    hasFilter?: boolean;
    hasSort?: boolean;
    isHeader?: boolean;
  };

  // TODO: columnsConfig    const columnsConfig: ColumnConfig<OrderLineByRuleRow>[] = [
  const tableBaseColumns: ColumnConfig[] = [
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item`,
      hasFilter: true,
      hasSort: false,
      // render: (sku: string, row) => (
      //   <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      // ),
    },
    {
      field: 'skuGroup',
      title: t`Item Group`,
      hasFilter: true,
      hasSort: false,
    },
    // { field: 'uom', title: t`UOM` },
    { field: 'netWeight', title: t`Weight`, hasSort: false },
    { field: 'length', title: t`Length`, hasSort: false },
    { field: 'width', title: t`Width`, hasSort: false },
    { field: 'height', title: t`Height`, hasSort: false },
    { field: 'volume', title: t`Volume`, hasSort: false },
  ];

  const tableColumnsTotalWeight: ColumnConfig[] = [
    { field: 'policy', title: t`Qty Range` },
    // { field: 'light', title: t`Top` },
    // { field: 'medium', title: t`None` },
    // { field: 'heavy', title: t`Bottom` },
  ];

  const showTotalWeight =
    selectedRuleResults !== PALLET_ORDER_BOTTOM_RULE_ID &&
    selectedRuleResults !== PALLET_ORDER_TOP_RULE_ID;

  const tableColumns: ColumnConfig[] = [
    ...(showTotalWeight ? tableColumnsTotalWeight : []),
    ...tableBaseColumns,
  ];

  return (
    <ContainerCol
      overflow
      componentName="ItemSetMainView"
      className={`border-app-panel-dark relative flex-1 ltr:border-l rtl:border-r`}
    >
      <PanelHeader title={t`Items Data Table - Stacking Policy`}></PanelHeader>
      <ContainerScroll className="flex flex-col">
        <ScreenTitle subtitle={t`Items`} title={`${itemSet?.title}`} isSticky />
        <Table
          dataComponent="ItemSetMainViewDataTable"
          isHoverable
          isSticky
          isStickyCol1
          flexHeight={isLoading || totalCount === 0 || totalCount >= 20}
        >
          <TableHead>
            <TableRowHead className={`hidden`}>
              <TableTh
                panelMode
                hasStickyTitleBefore
                isSticky
                colSpan={3}
                // className={`min-w-40`}
              >
                <div className={`flex w-full min-w-80 flex-col`}>
                  <h4>{t`Weight Policy Threshold per UOM`}</h4>
                </div>
              </TableTh>
              <TableTh
                // panelMode
                // hasStickyTitleBefore
                // isSticky
                colSpan={8}
                rowSpan={1}
              >
                <h4>{t`Data`}</h4>
              </TableTh>
            </TableRowHead>

            <TableRowHead>
              {tableColumns.map(column => {
                return (
                  <TableTh
                    key={`th-${column.field}`}
                    hasStickyTitleBefore
                    panelMode
                    isSticky
                    cellSize={`xs`}
                    // isNarrow
                    value={column.hasFilter ? null : column.title}
                    sortable
                  >
                    {column.hasFilter && (
                      // <div className={`flex-1 min-w-20`}>
                      <InputSearch
                        headerTableMode
                        titleMode
                        placeholder={column.title}
                        value={searchValues[column.field] || ''}
                        onChange={v => onSearch(column.field, v)}
                      />
                      // </div>
                    )}

                    {/* {column.hasSort && (
                      <DropdownSelector
                        DropAlignRight
                        buttonTransparent
                        vertical
                        value={'...'}
                        values={[...sortOptions]}
                        onChange={(option: sortOption) => {
                          switch (option) {
                            case 'Ascending':
                              //
                              break;
                            case 'Descending':
                              //
                              break;
                          }
                        }}
                      />
                    )} */}
                  </TableTh>
                );
              })}
            </TableRowHead>
          </TableHead>
          {totalCount === 0 ? (
            <TableBody className={`relative`}>
              <TableRowBody>
                <TableTd colSpan={12}>
                  <InboxZero selfCenter hasIcon message={t`No Items`} />
                </TableTd>
              </TableRowBody>
            </TableBody>
          ) : (
            <TableBody className={`relative`}>
              {isLoading ? (
                <TableRowBody>
                  <TableTd colSpan={100}>
                    <LoadingIndicator
                      selfCenter
                      absolute
                      message={t`Loading filtered results`}
                    />
                  </TableTd>
                </TableRowBody>
              ) : (
                <>
                  {_.map(flattenData, row => (
                    <TableRowBody key={`product-row-${row.sku}`} isHoverable>
                      {tableColumns.map(column => {
                        return (
                          <TableTd
                            className={classnames('select-text')}
                            key={`td-${column.field}-${row.id}`}
                            cellSize={`sm`}
                            isHeader={column.isHeader}
                            alignChildren={row[column.field] ?? 'center'}
                          >
                            {row[column.field] ?? '—'}
                          </TableTd>
                        );
                      })}
                    </TableRowBody>
                  ))}
                </>
              )}
            </TableBody>
          )}
        </Table>
      </ContainerScroll>
      {itemsCount < totalCount ? (
        <ActionBarFooter
          className={`bg-app-panel sticky bottom-0 bg-opacity-80`}
        >
          <Button
            full
            buttonSize="sm"
            className="bg-app-panel bg-opacity-40"
            isDisabled={isLoading}
            isLoading={isLoading}
            hasIconAfter
            buttonIcon={
              <Icon.MenuDotsHorizontal className={`h-5 w-5 fill-current`} />
            }
            label={t(
              `{{itemsCountFormatted}} of {{totalCountFormatted}} shown. Click to load more`,
              {
                itemsCountFormatted,
                totalCountFormatted,
              },
            )}
            onPress={onLoadNext}
          />
        </ActionBarFooter>
      ) : null}
    </ContainerCol>
  );
};

export default ItemSetWeightPolicyView;
