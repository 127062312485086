import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { Button } from '../../components/actions/Button';
import TitleSection from '../../components/layout/TitleSection';
import LoadingIndicator from '../../components/LoadingIndicator';
import useAnalyzeActuality from '../hooks/useAnalyzeActuality';
import { actualityAnalyzeLoadStatus } from '../store/actuality.state';

const AnalyzeActuality: React.FC = () => {
  const { t } = useTranslation('feed');
  const [startAnalyze] = useAnalyzeActuality();
  const status = useRecoilValue(actualityAnalyzeLoadStatus);

  const hasError = status === AsyncLoadStatus.Error;
  const isLoading = status === AsyncLoadStatus.Loading;

  return (
    <>
      {isLoading ? (
        <LoadingIndicator message={t`Analysing...`} selfCenter oneLiner />
      ) : (
        <>
          <TitleSection
            title={''}
            inPanelView
            className="z-400 top-12 xl:top-20"
            hasScreenTitle
            hasAction={
              <>
                <Button
                  className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                  buttonSize="xs"
                  label={t`New Only`}
                  buttonType={hasError ? 'delete' : 'primary'}
                  isLoading={isLoading}
                  hasIconBefore={isLoading}
                  isDisabled={isLoading}
                  onPress={() =>
                    startAnalyze({
                      newEventsOnly: true,
                    })
                  }
                />
                {/* <Spacer flexspace /> */}
                <Button
                  className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                  buttonSize="xs"
                  label={t`Re-Analyse All`}
                  buttonType={hasError ? 'delete' : 'primary'}
                  isLoading={isLoading}
                  hasIconBefore={isLoading}
                  isDisabled={isLoading}
                  onPress={() =>
                    startAnalyze({
                      newEventsOnly: false,
                    })
                  }
                />
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default AnalyzeActuality;
