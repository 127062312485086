import { PickingPolicyLocationSelectionMode } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Icon from '../../components/icons';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import { getLocationSelectionModeOptions } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyPickingRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';

const PickingPolicyModeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [pickingRule, updatePickingRule] = useRecoilState(
    pickingPolicyPickingRule({
      ruleId: selectedIdentity?.ruleId,
      pickingRuleId: selectedIdentity?.pickingRuleId,
    }),
  );
  if (!selectedIdentity || !pickingRule) return null;

  const selected =
    pickingRule?.locationSelectionMode ??
    PickingPolicyLocationSelectionMode.ROUND_ROBIN;

  const onSelectValue = (mode: PickingPolicyLocationSelectionMode) => {
    updatePickingRule({
      ...pickingRule,
      locationSelectionMode: mode,
    });
  };

  const options = getLocationSelectionModeOptions(t);

  return (
    <div data-component="PickingPolicyModeEditor">
      <TitleSection
        id={`policy-editor-picking-mode-section`}
        title={t`Location Pick Order`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {_.map(options, (mode, index) => {
                const groupByType = mode.type;
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-Mode-${mode.id}`}
                    isActive={mode.id === selected}
                    onClick={() => onSelectValue(mode.id)}
                    icon={
                      groupByType === 'ROUND_ROBIN'
                        ? Icon.SortPriorityRoundRobin
                        : groupByType === 'LOCATION_PRIORITY'
                        ? Icon.LocationPickingOrder
                        : Icon.SortPriorityAscending
                    }
                    iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
                    title={mode.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default PickingPolicyModeEditor;
