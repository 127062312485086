import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getOperationTitle } from '../../appBilling/store/appBilling.helper';
import {
  WarehouseBillingInterval,
  WarehouseBillingOneTime,
  WarehouseBillingSummary,
} from '../../appBilling/store/appBilling.types';
import {
  PRIVACY_POLICY_VERSION,
  TERMS_POLICY_VERSION,
} from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import useUpdateUserSettings from '../../warehouse/hooks/useUpdateUserSettings';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';

export type BillingTagIntervalProps = {
  interval: WarehouseBillingInterval;
};
export type BillingTagCounterProps = {
  counter: WarehouseBillingOneTime;
};

export const BillingTagTrial: React.FC<BillingTagIntervalProps> = () => {
  const { t } = useTranslation('app');

  return (
    <div
      data-component="BillingTagTrial"
      className={classNames(
        'flex items-center',
        'rounded-md',
        'py-0.5 px-2',
        'bg-menu-active/80 text-menu-active-text',
        'ltr:mr-[25%] rtl:ml-[25%]',
        'border border-app-panel-dark',
        'shadow',
        'text-minimal',
      )}
    >
      <Icon.ResourceTiming
        className={classNames('fill-current w-4 h-4 ltr:mr-0.5 rtl:ml-0.5')}
      />
      <span
        className={classNames('ltr:ml-0.5 rtl:mr-0.5 uppercase')}
      >{t`Trial`}</span>
    </div>
  );
};

export const BillingTagSubscription: React.FC<BillingTagIntervalProps> = () => {
  const { t } = useTranslation('app');
  return (
    <div
      data-component="BillingTagSubscription"
      className={classNames(
        'flex items-center',
        'rounded-md py-0.5 px-2',
        'text-menu-text/60',
        'border border-menu',
        'text-minimal',
      )}
    >
      <Icon.CircleOk
        className={classNames('fill-current w-3 h-3 ltr:mr-1 rtl:ml-1')}
      />
      <span className={classNames('ltr:ml-1 rtl:mr-1')}>{t`Subscribed`}</span>
    </div>
  );
};

export const BillingTagExpiredSubscription: React.FC<
  BillingTagIntervalProps
> = () => {
  const { t } = useTranslation('app');
  return (
    <div
      data-component="BillingTagExpiredSubscription"
      className={classNames(
        'flex items-center',
        'rounded-md',
        'py-0.5 px-2',
        'bg-alerts-error text-alerts-alertText',
        'ltr:mr-[25%] rtl:ml-[25%]',
      )}
    >
      <Icon.TriangleInfo
        className={classNames(
          'fill-current',
          'w-3 h-3',
          'ltr:mr-0.5 rtl:ml-0.5',
        )}
      />
      <span
        className={classNames('ltr:ml-0.5 rtl:mr-0.5', 'uppercase')}
      >{t`Expired`}</span>
    </div>
  );
};

export const BillingTagOneTime: React.FC<BillingTagCounterProps> = props => {
  const { t } = useTranslation('app');

  const configure = useUpdateUserSettings();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  async function completeConfiguration(whId?: string) {
    await configure({
      configured: true,
      consents: {
        privacyPolicy: {
          version: PRIVACY_POLICY_VERSION,
        },
        termsPolicy: {
          version: TERMS_POLICY_VERSION,
        },
      },
    });
    navigate(whId ? `/wh/i/${whId}` : '/wh/new');
  }

  return (
    <div
      data-component="BillingTagOneTime"
      className={classNames(
        'flex items-center',
        'rounded-md',
        'py-0.5 px-2',
        'text-menu-text/60',
        'border border-menu',
      )}
      onClick={() => {
        setIsLoading(true);
        completeConfiguration();
      }}
    >
      <Icon.CircleOk
        className={classNames('fill-current w-4 h-4 ltr:mr-1 rtl:ml-1')}
      />
      <span className={classNames('ltr:ml-1 rtl:mr-1')}>
        {props.counter.counter}
      </span>
      <span className={classNames('ltr:ml-1 rtl:mr-1')}>—</span>
      <span className={classNames('ltr:ml-1 rtl:mr-1')}>
        {getOperationTitle(props.counter.operation, t)}
      </span>
    </div>
  );
};

export const BillingTagExpiredOneTime: React.FC<
  BillingTagCounterProps
> = props => {
  const { t } = useTranslation('app');
  return (
    <div
      data-component="BillingTagCounterProps"
      title={t`Pay-as-you-go Expired`}
      className={classNames(
        'flex items-center',
        'rounded-md',
        'py-0.5 px-2',
        'bg-alerts-error text-alerts-alertText',
        'ltr:mr-[25%] rtl:ml-[25%]',
      )}
    >
      <Icon.TriangleInfo
        className={classNames('fill-current w-3 h-3 ltr:mr-1 rtl:ml-1')}
      />
      <span
        className={classNames('mx-0.5 uppercase flex-1')}
      >{t`Expired`}</span>
    </div>
  );
};

export const BillingTagUnpaid: React.FC = () => {
  const { t } = useTranslation('app');

  return (
    <div
      data-component="BillingTagUnpaid"
      title={t`Unpaid Service, Please Subscribe`}
      className="flex items-center rounded-md py-0.5 px-2 bg-alerts-error text-white ltr:mr-4 rtl:ml-4 text-minimal"
    >
      <Icon.ArrowRight
        className={`fill-current w-3 h-3 ltr:mr-0.5 rtl:ml-0.5`}
      />
      <span className="ltr:ml-0.5 rtl:mr-0.5 uppercase">{t`Subscribe`}</span>
    </div>
  );
};

export const BillingTagLoading = () => {
  const { t } = useTranslation('app');
  return (
    <div className="flex items-center justify-center">
      <Loader size={50} type={'loading-original'} />
    </div>
  );
};

export type BillingTagProps = {
  billingState: WarehouseBillingSummary;
  billingStateLoadStatus: AsyncLoadStatus;
  active?: boolean;
};

export const BillingMenuTag: React.FC<BillingTagProps> = ({
  billingState,
  billingStateLoadStatus,
}) => {
  const isLoading =
    billingStateLoadStatus === AsyncLoadStatus.Loading ||
    billingStateLoadStatus === AsyncLoadStatus.None;

  if (isLoading) {
    return <BillingTagLoading />;
  }

  switch (billingState.status) {
    case 'unpaid':
      return <BillingTagUnpaid />;
    case 'hasTrial':
      return <BillingTagTrial interval={_.head(billingState.intervals)} />;
    case 'hasSubscription':
      return (
        <BillingTagSubscription interval={_.head(billingState.intervals)} />
      );
    case 'hasExpiredSubscription':
      return (
        <BillingTagExpiredSubscription
          interval={_.head(billingState.intervals)}
        />
      );
    case 'hasOneTime':
      return (
        <>
          {_(billingState.oneTimes)
            .filter(ot => ot.active)
            .map((ot, index) => (
              <BillingTagOneTime
                key={`billing-tag-${ot.operation}`}
                counter={ot}
              />
            ))
            .value()}
        </>
      );
    case 'hasExpiredOneTime':
      return (
        <BillingTagExpiredOneTime counter={_.head(billingState.oneTimes)} />
      );
  }
};

export default BillingMenuTag;
