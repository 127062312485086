import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValueLoadable } from 'recoil';
import AnimatedBorderFeature from '../../features/AnimatedBorderFeature';
import { getPoints } from '../store/viewer.helper';
import {
  viewerSelectedAisle,
  viewerSelectedBay,
  viewerSelectedLocation,
} from '../store/viewer.state';

const ActiveFeatureLayer: React.FC = () => {
  const selectedAisleLoadable = useRecoilValueLoadable(viewerSelectedAisle);
  const selectedBayLoadable = useRecoilValueLoadable(viewerSelectedBay);
  const selectedLocationLoadable = useRecoilValueLoadable(
    viewerSelectedLocation,
  );

  const selectedAisle =
    selectedAisleLoadable.state === 'hasValue'
      ? selectedAisleLoadable.contents
      : null;
  const selectedBay =
    selectedBayLoadable.state === 'hasValue'
      ? selectedBayLoadable.contents
      : null;
  const location =
    selectedLocationLoadable.state === 'hasValue'
      ? selectedLocationLoadable.contents
      : null;

  const shape = location?.shape ?? selectedBay?.shape ?? selectedAisle?.shape;

  if (_.isEmpty(shape)) return null;

  return (
    <Layer>
      <AnimatedBorderFeature points={_.flatten(getPoints(shape))} />
    </Layer>
  );
};

export default ActiveFeatureLayer;
