import { useApolloClient } from '@apollo/client';
import {
  LayoutLocationsSearchFilter,
  LoadLayoutDataDocument,
  LoadLayoutDataQuery,
  LoadLayoutDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  layoutItemsData,
  layoutItemsDataState,
  layoutItemsDataStatus,
} from '../store/layout.state';


export type LoadLayoutDataParams = {
  layoutId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: LayoutLocationsSearchFilter;
};

function useLoadLayoutData() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Layout data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(layoutItemsDataStatus, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLayoutDataParams) => {
        const current = await snapshot.getPromise(layoutItemsData);
        const currentState = await snapshot.getPromise(layoutItemsDataState);

        const query = client.watchQuery<
          LoadLayoutDataQuery,
          LoadLayoutDataQueryVariables
        >({
          query: LoadLayoutDataDocument,
          variables: {
            layoutId: params.layoutId,
            filter: params.filter,
            //sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DATASET_VIEW_PAGE_SIZE,
            },
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(layoutItemsDataStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let items = data.layout.locations.content;

            if (params.isAppend) {
              items = [...current, ...items];
            }

            set(layoutItemsData, items);
            set(layoutItemsDataStatus, AsyncLoadStatus.Ok);
            set(layoutItemsDataState, {
              ...currentState,
              totalCount: data.layout.locations.totalCount,
            });
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadLayoutDataParams) {
    await initLoading();
    await loadDashDataCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadLayoutData;
