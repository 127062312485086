import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import { toHumanReadableTime } from '../../common/dateTimeHelper';
import {
  FormattedValueWithUnit,
  formatDateTime,
  formatTimespan,
} from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import ButtonLocateLocation from '../../components/ButtonLocateLocation';
import LegendItem from '../../components/events/LegendItem';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListItem } from '../../components/stats/StatListItem';
import EventSection from '../../containers/EventSection';
import IndicatorEnergy from '../../feed/components/IndicatorEnergy';
import IndicatorSignalStrength from '../../feed/components/IndicatorSignalStrength';
import { actualityResourcePolicy } from '../../feed/store/actuality.state';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import EventListItemHeader from '../../simulation/panels/route/EventListItemHeader';
import {
  ContainerStatsMini,
  StatMiniInline,
} from '../../simulation/panels/route/StatInline';
import { FeedLatestEvent } from '../store/datasetQueries/feedLatestEvents';
import { delayDuration } from '../store/feed.helper';
import {
  feedDisabledAgentStatsFields,
  feedSelectedAgentId,
} from '../store/feed.state';

export type FeedActivityEventDetailsProps = {
  index: number;
  event: FeedLatestEvent;
  onHover?: (event: FeedLatestEvent) => void;
  onClick?: (event: FeedLatestEvent) => void;
};

const styleEventPath = 'w-2 rounded-sm';
const styleLegendPosition = 'absolute z-0 ltr:left-5 rtl:right-5';

const FeedActivityEventDetails: React.FC<
  FeedActivityEventDetailsProps
> = props => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const device = useRecoilValue(feedSelectedAgentId);
  const disabledStats = useRecoilValue(feedDisabledAgentStatsFields);

  const resourcePolicy = useRecoilValue(actualityResourcePolicy);
  const agentMap = _.keyBy(resourcePolicy.agents, 'id');

  const { event } = props;
  const disabledStatsSet = new Set(disabledStats);

  let eventDurationCalcRaw: number | null = null;
  if (event.eventEndTime && event.eventStartTime) {
    eventDurationCalcRaw = Math.abs(
      event.eventEndTime.getTime() - event.eventStartTime.getTime(),
    );
  }
  const eventDurationCalc = formatter.formatTimeSpan(eventDurationCalcRaw);

  // const cost = formatter.formatCurrency(event);

  let distance: FormattedValueWithUnit;
  let weight: FormattedValueWithUnit;
  let volume: FormattedValueWithUnit;

  // if (event.details?.__typename === 'TravellingHorizontalDetails') {
  //   distance = formatter.formatDistance(event.details.distance);
  //   weight = formatter.formatWeight(event.details.weight);
  //   volume = formatter.formatVolume(event.details.volume);
  // }
  // if (event.details?.__typename === 'HandlingExecutionDetails') {
  //   weight = formatter.formatWeight(event.details.weight);
  //   volume = formatter.formatVolume(event.details.volume);
  // }
  // if (event.details?.__typename === 'TravellingVerticalDetails') {
  //   weight = formatter.formatWeight(event.details.weight);
  //   volume = formatter.formatVolume(event.details.volume);
  // }
  // if (event.details?.__typename === 'HandlingPalletReorderingDetails') {
  //   const reorderedWeight = _.sumBy(
  //     event.details.reordered,
  //     item => item.weight,
  //   );
  //   weight = formatter.formatWeight(reorderedWeight);
  // }

  const stats = _.compact([
    // device
    //   ? null
    //   : {
    //       title: t`From:`,
    //       value: props.event.locationId,
    //     },

    {
      title: t`From:`,
      value: props.event.locationId,
    },
    {
      title: t`To:`,
      value: props.event.locationIdSource,
    },
    {
      title: t`Item:`,
      value: props.event.sku,
    },

    // {
    //   title: t`Process`,
    //   value: props.event.eventProcessType,
    // },
    // {
    //   title: t`Event`,
    //   value: props.event.eventType,
    // },

    // {
    //   title: t`Time`,
    //   value: toHumanReadableTime(new Date(props.event.createTime)),
    // },
    // {
    //   title: t`code`,
    //   value: props.event.code,
    // },
  ]);

  const offset = !_.isNil(event.eventStartTime)
    ? Math.abs(event.eventEndTime.getTime() - event.eventStartTime.getTime())
    : 0;

  const issues = [];

  if (offset > delayDuration) {
    issues.push({
      title: t`Sync`,
      value: formatTimespan(offset),
    });
  }

  return (
    <EventSection
      id={`feed-event-section-${event.eventId}`}
      fullWidth
      inPanelView
      collapsible
      isSelected={false}
      titleContainer={
        <EventListItemHeader
          // indicatorEnergy={
          //   <>
          //     {!disabledStatsSet.has('minEnergy') &&
          //       props.event.agentEnergy && (
          //         <IndicatorEnergy
          //           percentage={props.event.agentEnergy}
          //           isCharging={false}
          //           className={classNames(
          //             'w-9 h-auto',
          //             'text-menu/50',
          //             'ltr:ml-1 ltr:mr-2',
          //             'rtl:mr-1 rtl:ml-2',
          //           )}
          //         />
          //       )}
          //   </>
          // }
          // indicatorConnectivity={
          //   <>
          //     {!disabledStatsSet.has('minSignal') &&
          //       props.event.agentSignalStrength && (
          //         <IndicatorSignalStrength
          //           viewType="bars"
          //           dBm={props.event.agentSignalStrength}
          //           className={classNames(
          //             'w-9 h-auto',
          //             'text-menu/50',
          //             'ltr:ml-1 ltr:mr-2',
          //             'rtl:mr-1 rtl:ml-2',
          //           )}
          //         />
          //       )}
          //   </>
          // }
          titleTag={t`Event`}
          title={
            <>
              <ButtonLocateLocation
                locationId={props.event.locationId}
                title={props.event.agentId}
                icon={getAgentIcon(
                  agentMap[props.event.agentType]
                    ?.resourceTypeId as AgentCategoryId,
                )}
              >
                <div className="flex gap-x-2">
                  {!disabledStatsSet.has('minEnergy') &&
                    props.event.agentEnergy && (
                      <IndicatorEnergy
                        percentage={props.event.agentEnergy}
                        isCharging={false}
                        className={classNames(
                          'h-auto w-9',
                          'text-menu/50',
                          // 'ltr:ml-1 ltr:mr-2',
                          // 'rtl:mr-1 rtl:ml-2',
                        )}
                      />
                    )}
                  {!disabledStatsSet.has('minSignal') &&
                    props.event.agentSignalStrength && (
                      <IndicatorSignalStrength
                        viewType="bars"
                        dBm={props.event.agentSignalStrength}
                        className={classNames(
                          'h-auto w-9',
                          'text-menu/50',
                          // 'ltr:ml-1 ltr:mr-2',
                          // 'rtl:mr-1 rtl:ml-2',
                        )}
                      />
                    )}
                </div>
              </ButtonLocateLocation>
            </>
          }
          // title={device ? props.event.locationId : props.event.agentId}
          // title={props.event.article + ' / ' + props.event.shelf}
          // labelValueType={'itemId'}
          // labelValueFilters={{}}
          titleAriaLabel={t`Item`}
          counter={
            <span
              title={t(`timestamp:\n {{deviceMaxTime}}`, {
                deviceMaxTime: props.event.eventEndTime,
              })}
              className="text-minimal"
            >
              {toHumanReadableTime(props.event.eventEndTime)}
            </span>
          }
          stats={stats}
          issues={issues}
          color={getQualitativeColor(event.agentId, 'dimension')}
        ></EventListItemHeader>
      }
    >
      <div
        className={classNames(
          'relative',
          'w-full',
          'my-0.5',
          'py-2',
          'ltr:pl-12 ltr:pr-4 rtl:pl-4 rtl:pr-12',
          'bg-app-panel-dark',
        )}
      >
        <LegendItem legendSize="Full" legendType="Info" />

        <ContainerStatsMini>
          <StatMiniInline title={t`UOM`} value={props.event.uom} />
          <StatMiniInline title={t`Qty`} value={props.event.quantity} />
        </ContainerStatsMini>
        <StatGroup>
          <StatListItem title={t`Agent Type`} value={props.event.agentType} />

          <StatListItem
            title={t`Process Type`}
            value={props.event.eventProcessType}
          />
        </StatGroup>
        <StatGroup>
          {props.event.congestion > 0 && (
            <StatListItem
              title={t`Congestion Score`}
              value={props.event.congestion}
            />
          )}
        </StatGroup>
        <StatGroup className="mt-4">
          {eventDurationCalcRaw && (
            <StatListItem
              title={t`Duration`}
              value={eventDurationCalc.value}
              unitOfMeasure={eventDurationCalc.unitCode}
            />
          )}
          {props.event.eventStartTime && (
            <StatListItem
              title={t`Start Time`}
              value={formatDateTime(props.event.eventStartTime)}
            />
          )}
          {props.event.eventEndTime && (
            <StatListItem
              title={t`End Time`}
              value={formatDateTime(props.event.eventEndTime)}
            />
          )}
        </StatGroup>
        <StatGroup className="mt-4">
          {props.event.jobGroupId && (
            <StatListItem
              title={t`Job Group ID`}
              value={props.event.jobGroupId}
              truncate
              canCopy
            />
          )}
          {props.event.jobId && (
            <StatListItem
              title={t`Job ID`}
              value={props.event.jobId}
              truncate
              canCopy
            />
          )}
        </StatGroup>
        <StatGroup>
          <StatListItem
            title={t`ID`}
            value={props.event.eventId}
            canCopy
            truncate
          />
        </StatGroup>
      </div>
    </EventSection>
  );
};
export default FeedActivityEventDetails;
