import {
  OptimizationRunSourceType,
  OptimizeJobEngine,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import * as Icon from '../../../components/icons';
import { InputGroupList } from '../../../components/inputs/InputGroupList';
import InputNumber from '../../../components/inputs/InputNumber';
import { ActionBar } from '../../../components/nav/ActionBar';
import PanelContainer from '../../../containers/PanelContainer';
import useRunOptimization, {
  RunOptimizationParams,
} from '../../../warehouse/hooks/useRunOptimization';
import { allocationRunSummary } from '../../store/allocation/allocation.state';
import { optimisationUseAllocationRun } from '../../store/optimisation.state';
import {
  optimisationSetupEngineType,
  optimisationSetupNodesCount,
  optimisationSetupSeed,
  optimizationSetupStepMultiplier,
  optimizationSetupSupportEnabled,
} from '../../store/optimisationSetup.state';
import { simulationLayoutSummary } from '../../store/simulation.layout.state';
import { simulationCurrent } from '../../store/simulation.state';

export type OptimizationRunActionAdvancedProps = {
  className?: string;
  showAdvanced?: boolean;
};

const OptimizationRunActionAdvanced: React.FC<
  OptimizationRunActionAdvancedProps
> = props => {
  const { t } = useTranslation('simulation');
  const layoutSummary = useRecoilValue(simulationLayoutSummary);
  const [isLoading, setIsLoading] = useState(false);
  const runOptimization = useRunOptimization();
  const [sim, updateSim] = useRecoilState(simulationCurrent);
  const totalLocationCount = layoutSummary.locationCount;
  const simId = sim.id;
  const [supportEnabled, setSupportEnabled] = useRecoilState(
    optimizationSetupSupportEnabled,
  );
  const stepsMultiplier = useRecoilValue(optimizationSetupStepMultiplier);
  const [seed, setSeed] = useRecoilState(optimisationSetupSeed);
  const [nodesCount, setNodesCount] = useRecoilState(
    optimisationSetupNodesCount,
  );
  const [engineType, setEngineType] = useRecoilState(
    optimisationSetupEngineType,
  );
  const useAllocationRun = useRecoilValue(optimisationUseAllocationRun);
  const allocationRun = useRecoilValue(allocationRunSummary);

  const [stepsCount, setStepsCount] = useState<number>(0);

  useEffect(() => {
    setStepsCount(Math.floor(stepsMultiplier * totalLocationCount));
  }, [stepsMultiplier]);

  const optimisationEngineOptions: Record<
    OptimizeJobEngine,
    { title: string }
  > = {
    [OptimizeJobEngine.FARGATE]: {
      title: `F - Engine`,
    },
    [OptimizeJobEngine.MPI]: {
      title: `M - Engine`,
    },
    [OptimizeJobEngine.LAMBDA]: {
      title: `L - Engine`,
    },
  };

  const runOnAllocate = useAllocationRun && !_.isNil(allocationRun);
  const optRun: RunOptimizationParams = {
    id: runOnAllocate ? allocationRun?.id : simId,
    type: runOnAllocate
      ? OptimizationRunSourceType.ALLOCATION_RUN
      : OptimizationRunSourceType.SIMULATION,
    seed,
    steps: stepsCount,
    engine: engineType,
    nodes: nodesCount,
  };
  return (
    <>
      {supportEnabled && (
        <PanelContainer
          title={t`Debug Options`}
          id={'optimisation-debug-params'}
          collapsible={true}
          data-component="OptimizationRunActionAdvanced"
          className={classNames('', props.className)}
        >
          <div className="bg-app-panel-dark/75" title={`Steps:(${stepsCount})`}>
            <InputGroupList
              hasSpacing
              hasPadding
              className="bg-app-panel-dark/60"
            >
              <InputNumber
                className="mb-4"
                title={`Opt. Steps`}
                value={stepsCount}
                onChange={v => setStepsCount(v)}
              />
              <DropdownSelector
                label={`Opt. Engine`}
                DropAlignRight
                panelMode
                classNameLabel="text-sm"
                onClick={e => {
                  e.stopPropagation();
                }}
                value={engineType}
                values={
                  Object.keys(optimisationEngineOptions) as OptimizeJobEngine[]
                }
                renderValue={value => optimisationEngineOptions[value].title}
                onChange={async selectedEngine => {
                  setEngineType(selectedEngine as OptimizeJobEngine);
                }}
              />

              {OptimizeJobEngine.MPI && (
                <InputNumber
                  className="mb-4"
                  title={`Opt. Nodes`}
                  value={nodesCount}
                  onChange={setNodesCount}
                />
              )}

              {/* <InputNumber
              className="mt-4"
              title={`Opt. Seed`}
              value={optimizationSeed}
              onChange={setOptimizationSeed}
            /> */}
            </InputGroupList>
          </div>
        </PanelContainer>
      )}
      <ActionBar>
        {supportEnabled && (
          <Button
            className={cn('h-10 xl:h-12')}
            buttonType="secondary"
            hasIconAfter
            buttonIcon={
              <Icon.ArrowLeft className={cn('h-6 w-6 fill-current')} />
            }
            isLoading={isLoading}
            isDisabled={isLoading}
            onPress={() => {
              setSupportEnabled(false);
            }}
          />
        )}

        <Button
          full
          className={cn('h-10 xl:h-12', 'truncate')}
          label={t`Run Optimisation`}
          buttonType="primary"
          isLoading={isLoading}
          isDisabled={isLoading}
          onPress={async () => {
            setIsLoading(true);
            await runOptimization(optRun);
            setIsLoading(false);
          }}
        />
        {!supportEnabled && (
          <Button
            className={cn('h-10 xl:h-12', 'truncate')}
            buttonType="primary"
            hasIconAfter
            buttonIcon={
              <Icon.SimulationOptimize
                className={cn('h-6 w-auto fill-current')}
              />
            }
            isLoading={isLoading}
            isDisabled={isLoading}
            onPress={() => {
              setSupportEnabled(true);
            }}
          />
        )}
      </ActionBar>
    </>
  );
};

export default OptimizationRunActionAdvanced;
