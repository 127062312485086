import * as React from 'react';
const AisleWidthIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AisleWidthIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M45.74,109.41c-.05.05-.1.11-.14.18-.02.03-.03.06-.05.09-.02.04-.04.07-.06.11-.02.04-.03.08-.04.12,0,.03-.02.06-.03.1-.03.15-.03.3,0,.45,0,.03.02.06.03.1.01.04.02.08.04.12.02.04.04.07.06.11.02.03.03.06.05.09.04.06.09.12.14.18l9.19,9.19c.45.45,1.18.45,1.62,0,.22-.22.34-.52.34-.81s-.11-.59-.34-.81l-7.23-7.23h17.33c.63,0,1.15-.51,1.15-1.15s-.51-1.15-1.15-1.15h-17.33s7.23-7.23,7.23-7.23c.22-.22.34-.52.34-.81s-.11-.59-.34-.81c-.45-.45-1.18-.45-1.62,0l-9.19,9.19Z"
    />
    <path
      strokeWidth={0}
      d="M81.89,111.04c.05-.05.1-.11.14-.18.02-.03.03-.06.05-.09.02-.04.04-.07.06-.11.02-.04.03-.08.04-.12,0-.03.02-.06.03-.1.03-.15.03-.3,0-.45,0-.03-.02-.06-.03-.1-.01-.04-.02-.08-.04-.12-.02-.04-.04-.07-.06-.11-.02-.03-.03-.06-.05-.09-.04-.06-.09-.12-.14-.18l-9.19-9.19c-.45-.45-1.18-.45-1.62,0-.22.22-.34.52-.34.81s.11.59.34.81l7.23,7.23h-17.33c-.63,0-1.15.51-1.15,1.15s.51,1.15,1.15,1.15h17.33s-7.23,7.23-7.23,7.23c-.22.22-.34.52-.34.81s.11.59.34.81c.45.45,1.18.45,1.62,0l9.19-9.19Z"
    />
    <rect width={3.12} height={25.3} x={85.27} y={97.81} strokeWidth={0} />
    <rect width={3.12} height={25.3} x={39.6} y={97.81} strokeWidth={0} />
    <rect
      width={47.76}
      height={84.38}
      x={40.12}
      y={6.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect width={15.2} height={26.82} x={90.28} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={90.28} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={90.28} y={6.29} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={107.88} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={107.88} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={107.88} y={6.29} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={22.52} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={22.52} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={22.52} y={6.29} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={4.93} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={4.93} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={4.93} y={6.29} strokeWidth={0} />
  </svg>
);
export default AisleWidthIcon;
