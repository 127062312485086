import { ConverterWizardStepId } from './converter.wizard';

export const stepsWithValidation: Set<ConverterWizardStepId> =
  new Set<ConverterWizardStepId>([
    'setup-floors',
    'areas-setup',
    'organize-areas',
    'delete-areas',
    'position-areas',
    'designer',
    'connect-areas',
    'define-starting-points',
  ]);
