import * as React from 'react';
const ProjectAddCopyIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ProjectAddCopyIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.16 106.84 33.36 122.54 33.36 91.14 6.16 75.44 6.16 106.84"
    />
    <polygon
      strokeWidth={0}
      points="34 58.54 6.88 74.2 34 89.86 61.12 74.2 34 58.54"
    />
    <polygon
      strokeWidth={0}
      points="34.71 91.16 34.71 122.48 61.84 106.82 61.84 75.5 34.71 91.16"
    />
    <polygon
      strokeWidth={0}
      points="36.16 56.22 63.36 71.91 63.36 40.52 36.16 24.81 36.16 56.22"
    />
    <polygon
      strokeWidth={0}
      points="64 7.91 36.88 23.58 64 39.23 91.12 23.57 64 7.91"
    />
    <polygon
      strokeWidth={0}
      points="64.71 40.54 64.71 71.86 91.84 56.2 91.84 24.87 64.71 40.54"
    />
    <path
      strokeWidth={0}
      d="M65.64,75.84v32l27.71,16,27.71-16v-32l-27.71-16-27.71,16ZM95.56,78.56v11.03h11.59c1.22,0,2.21.99,2.21,2.21s-.99,2.21-2.21,2.21h-11.59v12.14c0,1.22-.99,2.21-2.21,2.21s-2.21-.99-2.21-2.21v-12.14h-11.59c-1.22,0-2.21-.99-2.21-2.21s.99-2.21,2.21-2.21h11.59v-11.03c0-1.22.99-2.21,2.21-2.21s2.21.99,2.21,2.21Z"
    />
  </svg>
);
export default ProjectAddCopyIcon;
