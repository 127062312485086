import * as React from 'react';
const PolicyRoutingAgentIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyRoutingAgentIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M82.55,78.95l10.65-10.66h-21.32c.58-1.37.88-2.85.88-4.34s-.3-2.96-.88-4.33h21.33s-11.63-11.67-11.63-11.67h12.23l15.95,16-14.98,15h-12.23Z"
    />
    <path
      strokeWidth={0}
      d="M44.73,47.95l-10.65,10.66h21.32c-.58,1.37-.88,2.85-.88,4.34,0,1.49.3,2.96.88,4.33h-21.33s11.63,11.67,11.63,11.67h-12.23s-15.95-16-15.95-16l14.98-15h12.23Z"
      opacity={0.3}
    />
    <path
      strokeWidth={0}
      d="M79.14,43.45l-10.66-10.65v21.32c-1.37-.58-2.85-.88-4.34-.88-1.49,0-2.96.3-4.33.88v-21.33s-11.67,11.63-11.67,11.63v-12.23s16-15.95,16-15.95l15,14.98v12.23Z"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M80.44,87.49c-1.06-.48-8.05-3.95-8.05-3.95,0,0-2.48-5.29-3.76-8.18-1.21-2.72-2.08-3.43-3.79-3.43h-2.65c-1.39,0-1.9.51-2.74.99l-7.16,6.09c-.63.36-1.03.9-1.27,1.66l-1.54,10.31c-.22,1.64.65,2.34,1.63,2.54.8.17,1.99-.23,2.24-1.39.19-.95,1.64-9.39,1.64-9.39l4.67-3.59c-.77,3.58-1.56,7.5-1.81,8.84-.45,2.28.69,4.01,1.59,5.36.82,1.23,12.39,16.19,13.78,18,1.4,1.81,2.67,2.71,4.61,1.63,1.63-.91,1.36-2.99.46-4.53-.9-1.54-12.57-17.18-12.57-17.18l1.79-7.74s1.24,1.77,1.88,2.77c.31.47.63.66,1.46,1.11.99.54,5.76,2.71,7.22,3.37,1.29.57,2.89.79,3.53-.35.65-1.13-.17-2.5-1.13-2.95Z"
    />
    <path
      strokeWidth={0}
      d="M55.11,102.75s-5.14,3.5-7.21,4.57c-1.56.81-3.05,2.02-2.16,3.95,1.01,2.17,2.07,1.65,3.46,1.13,1.28-.47,8.8-4.06,9.84-5.37.69-.88,1.07-1.62,1.38-2.36.19-.49.94-2.52,1.42-3.84l-4.45-6.11-2.28,8.03Z"
    />
    <circle cx={64.36} cy={64} r={6.03} strokeWidth={0} />
  </svg>
);
export default PolicyRoutingAgentIcon;
