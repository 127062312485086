import classNames from 'classnames';
import React, { useState } from 'react';
import { formatDateTime, formatTimeAgo } from '../common/formatHelper';
import Loader from '../components/loaders/Loader';

export type TimeDateProps = {
  children?: React.ReactNode;
  className?: string;
  updatedDate?: Date;
  createdDate?: Date;
  timeDate?: Date;
  isLoading?: boolean;
  alignEnd?: boolean;
  alignStart?: boolean;
};

const TimeAgo: React.FC<TimeDateProps> = props => {
  const [showTimeAgo, setShowTimeAgo] = useState(true);

  const toggleFormat = () => setShowTimeAgo(!showTimeAgo);

  const displayDate = showTimeAgo
    ? formatTimeAgo(props.timeDate, {
        includeSeconds: false,
        includeMinutes: true,
      })
    : props.timeDate.toLocaleString();

  return (
    <div
      data-component="TimeAgo"
      title={`Updated: ${formatDateTime(
        props.updatedDate,
      )} \nCreated: ${formatDateTime(props.createdDate)}`}
      aria-label={`Updated: ${props.updatedDate.toLocaleString()} ${'\n'} Created: ${props.createdDate.toLocaleString()}`}
      className={classNames(
        'flex-1 text-xs',
        'opacity-75',
        props.alignStart ? 'text-start' : 'text-end',
        props.className,
      )}
      onClick={toggleFormat}
      style={{ cursor: 'pointer' }}
    >
      {displayDate}
      {props.children}
      {props.isLoading && (
        <Loader type={'loading-original'} size={20} stroke={10} />
      )}
    </div>
  );
};

export default TimeAgo;
