import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import InboxZero from '../../../../components/InboxZero';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import {
  InputAnswer,
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { converterSelectedArea } from '../store/converter.area.state';
import { converterAreaBuilder } from '../store/converter.state';

const StepAislesAccessAisles = () => {
  const { t } = useTranslation('designer');
  const area = useRecoilValue(converterSelectedArea);
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );

  return (
    <PanelBody>
      <ScreenTitle title={t`Access Aisles`} subtitle={t`Setup Aisles`} />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerAislesAccessBottom}
          question={t`Where are the area's access aisles?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/03-setup-aisles-access'} hasPadding />

          {/* <HelperContainer
            collapsible
            id={'helper-layout-access-aisles'}
            hideMessage={t`Hide helper`}
            showMessage={t`Show more`}
            showIcon={Icon.CircleHelp}
            hideIcon={Icon.ArrowRight}
            message={t`Access aisles connect the racking aisles to allow navigation. Choose between top, bottom or both.`}
          >
            <HelperMessage isSuggestion hasBefore>
              {t`WareBee will automatically extend the racking aisles connecting all to the access aisle.`}
            </HelperMessage>
          </HelperContainer> */}
        </InputAnswerGroup>

        <TitleSection className="mt-4" titleView title={t`Access Aisles`} />

        {area && areaBuilder ? (
          <InputGroupList hasPadding hasSpacing>
            <InputAnswerGroup>
              <InputAnswer
                answer_id={t`A.`}
                answer={t`None`}
                defaultChecked
                selectedAnswer={
                  !areaBuilder.hasBottomAccessAisle &&
                  !areaBuilder.hasTopAccessAisle
                }
                onClick={() => {
                  setAreaBuilder({
                    ...areaBuilder,
                    hasBottomAccessAisle: false,
                    hasTopAccessAisle: false,
                  });
                }}
              />
              <InputAnswer
                answer_id={t`B.`}
                answer={t`Only Bottom Aisle`}
                selectedAnswer={
                  areaBuilder.hasBottomAccessAisle &&
                  !areaBuilder.hasTopAccessAisle
                }
                onClick={() => {
                  setAreaBuilder({
                    ...areaBuilder,
                    hasBottomAccessAisle: true,
                    hasTopAccessAisle: false,
                  });
                }}
              />
              <InputAnswer
                answer_id={t`C.`}
                answer={t`Only Top Aisle`}
                selectedAnswer={
                  !areaBuilder.hasBottomAccessAisle &&
                  areaBuilder.hasTopAccessAisle
                }
                onClick={() => {
                  setAreaBuilder({
                    ...areaBuilder,
                    hasBottomAccessAisle: false,
                    hasTopAccessAisle: true,
                  });
                }}
              />
              <InputAnswer
                answer_id={t`D.`}
                answer={t`Both Top and Bottom Aisles`}
                selectedAnswer={
                  areaBuilder.hasBottomAccessAisle &&
                  areaBuilder.hasTopAccessAisle
                }
                onClick={() => {
                  setAreaBuilder({
                    ...areaBuilder,
                    hasBottomAccessAisle: true,
                    hasTopAccessAisle: true,
                  });
                }}
              />
            </InputAnswerGroup>
          </InputGroupList>
        ) : (
          <InboxZero
            className={`mt-2`}
            message={t`No Area Selected`}
            message_helper={t`Click once on an Area to select`}
          />
        )}
      </InputQA>
    </PanelBody>
  );
};

export default StepAislesAccessAisles;
