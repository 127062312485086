import * as React from 'react';
const CursorSelectAreaAddIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectAreaAddIcon"
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 93.99 93.99">
        <path
          strokeWidth={0}
          d="M.51,4.38C-.93.81.81-.93,4.38.51l87.02,35.14c3.57,1.44,3.44,3.4-.29,4.36l-33.91,8.68c-3.73.96-7.56,4.79-8.52,8.52l-8.68,33.91c-.96,3.73-2.92,3.86-4.36.29L.51,4.38Z"
        />
      </symbol>
    </defs>
    <path
      strokeWidth={0}
      d="M82.61,54.25h-.61c-2.69,0-4.87-2.18-4.87-4.87s2.18-4.87,4.87-4.87h.61c2.69,0,4.87,2.18,4.87,4.87s-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M98.85,54.25h-1.08c-2.69,0-4.87-2.18-4.87-4.87s2.18-4.87,4.87-4.87h1.08c2.69,0,4.87,2.18,4.87,4.87s-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M114.63,54.86c-1.5,0-2.84-.68-3.74-1.75-1.07-.89-1.74-2.24-1.74-3.74,0-2.69,2.18-4.87,4.87-4.87h.61c2.69,0,4.87,2.18,4.87,4.87v.61c0,2.69-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M114.63,103.7c-2.69,0-4.87-2.18-4.87-4.87v-1.09c0-2.69,2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87v1.09c0,2.69-2.18,4.87-4.87,4.87ZM114.63,87.42c-2.69,0-4.87-2.18-4.87-4.87v-1.09c0-2.69,2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87v1.09c0,2.69-2.18,4.87-4.87,4.87ZM114.63,71.14c-2.69,0-4.87-2.18-4.87-4.87v-1.09c0-2.69,2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87v1.09c0,2.69-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M114.63,119.5h-.61c-2.69,0-4.87-2.18-4.87-4.87,0-1.5.68-2.85,1.75-3.74.89-1.07,2.24-1.74,3.74-1.74,2.69,0,4.87,2.18,4.87,4.87v.61c0,2.69-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M98.82,119.5h-1.09c-2.69,0-4.87-2.18-4.87-4.87s2.18-4.87,4.87-4.87h1.09c2.69,0,4.87,2.18,4.87,4.87s-2.18,4.87-4.87,4.87ZM82.54,119.5h-1.09c-2.69,0-4.87-2.18-4.87-4.87s2.18-4.87,4.87-4.87h1.09c2.69,0,4.87,2.18,4.87,4.87s-2.18,4.87-4.87,4.87ZM66.26,119.5h-1.09c-2.69,0-4.87-2.18-4.87-4.87s2.18-4.87,4.87-4.87h1.09c2.69,0,4.87,2.18,4.87,4.87s-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M49.98,119.5h-.61c-2.69,0-4.87-2.18-4.87-4.87v-.61c0-2.69,2.18-4.87,4.87-4.87,1.5,0,2.84.68,3.74,1.74,1.07.89,1.75,2.24,1.75,3.74,0,2.69-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M49.37,103.73c-2.69,0-4.87-2.18-4.87-4.87v-1.08c0-2.69,2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87v1.08c0,2.69-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M49.37,87.48c-2.69,0-4.87-2.18-4.87-4.87v-.61c0-2.69,2.18-4.87,4.87-4.87s4.87,2.18,4.87,4.87v.61c0,2.69-2.18,4.87-4.87,4.87Z"
    />
    <path
      strokeWidth={0}
      d="M97.23,77.04h-10.27v-10.27c0-2.74-2.22-4.96-4.96-4.96s-4.96,2.22-4.96,4.96v10.27h-10.27c-2.74,0-4.96,2.22-4.96,4.96s2.22,4.96,4.96,4.96h10.27v10.27c0,2.74,2.22,4.96,4.96,4.96s4.96-2.22,4.96-4.96v-10.27h10.27c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96Z"
    />
    <use
      xlinkHref="#a"
      width={93.99}
      height={93.99}
      transform="translate(4 4) scale(.85)"
    />
  </svg>
);
export default CursorSelectAreaAddIcon;
