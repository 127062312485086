import classNames from 'classnames';
import _ from 'lodash';
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useId } from 'react-aria';

export type InputValueProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  classNameLabel?: string;
  classNameValue?: string;
  title?: string;
  id_name?: string;
  value?: any;
  disabled?: boolean;
  lg?: boolean;
  xs?: boolean;
  xxs?: boolean;
  labelVertical?: boolean;
  roundedLeft?: boolean;
  roundedRight?: boolean;
  rounded?: boolean;
  unitOfMeasure?: string;
  isNumeric?: boolean;
  inputText?: boolean;
  hasError?: boolean;
  placeholder?: string;
  onChange?: (value) => void;
  onEndEdit?: (value) => void;
  onCancelEdit?: () => void;
  isDebouncedChange?: boolean;
};

export const InputValue = ({
  children,
  className,
  classNameLabel,
  classNameValue,
  icon,
  title,
  value: valueFromProps,
  id_name,
  disabled,
  lg,
  xs,
  xxs,
  labelVertical,
  roundedLeft,
  roundedRight,
  rounded,
  unitOfMeasure,
  isNumeric,
  inputText,
  hasError,
  onChange: onChangeFromProps,
  onEndEdit,
  onCancelEdit,
  placeholder,
  isDebouncedChange,
}: InputValueProps) => {
  const [value, setValue] = useState(valueFromProps);
  const [isValid, setValid] = useState(true);
  const id = useId();
  const areaId = useId();
  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  id_name ??= areaId;

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = event => {
    //console.log(event.key);
    if (event.key === 'Enter') {
      onEndEdit((event.target as HTMLInputElement).value);
    }
    if (event.key === 'Escape') {
      onCancelEdit();
    }
  };

  const debouncedChange = useCallback(
    _.debounce(async (value: String) => {
      try {
        await onChangeFromProps(value);
      } catch (ex) {
        //setValid(false);
      }
    }, 800),
    [id, onChangeFromProps],
  );

  function validate(value: string): boolean {
    return true;
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValid(true);

    // validate
    const value = e.target.value;
    const validationRes = validate(value);

    setValue(value);

    //call debounce nonChange only for valid values
    const callback = isDebouncedChange
      ? debouncedChange
      : onChangeFromProps ?? _.noop;

    if (validationRes) {
      callback(value);
    } else {
      debouncedChange.cancel();
    }
  }

  const isValueApplied = valueFromProps === value;

  return (
    <div
      data-component="InputValue"
      id={id}
      className={classNames(
        'flex items-center',
        { 'flex-col': labelVertical },
        className,
      )}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <label
        data-component="InputValueLabel"
        data-label={`input-value-${title || ''}`}
        aria-label={`input-value-${title || ''}`}
        htmlFor={`${id_name}`}
        className={classNames(
          'flex items-center w-full',
          'mb-0 text-xs font-bold text-menu-text',
          { 'px-0.5 flex-1': title },
          { 'flex-col': labelVertical },
          classNameLabel,
        )}
      >
        {icon && (
          <div
            className={classNames(
              'flex items-center',
              'w-10 h-10',
              'p-1 m-1 ltr:ml-0 rtl:mr-0 ltr:mr-2 rtl:ml-2',
              'shadow-md',
              'hover:bg-menu-active hover:text-menu-active-text',
              'bg-app-panel/50 text-menu-text',
              'rounded',
            )}
          >
            {icon}
          </div>
        )}

        {title && (
          <div
            data-component="title"
            className={classNames(
              { 'w-2/3': !inputText },
              'text-sm flex',
              //mb-2
            )}
          >
            <span
              className={classNames(
                'text-xs uppercase text-menu-text',
                'ltr:mr-1 rtl:ml-1',
                'flex-1',
                { 'w-full': labelVertical },
              )}
            >
              {title}
            </span>
            {unitOfMeasure && (
              <span
                className={classNames(
                  'ltr:ml-1 rtl:mr-1',
                  'text-xs text-menu-text/70',
                )}
              >
                <span className="opacity-50">{`(`}</span>
                {unitOfMeasure}
                <span className="opacity-50">{`)`}</span>
              </span>
            )}
          </div>
        )}

        <input
          id={`${id_name}`}
          className={classNames(
            'flex-1 w-full min-w-10 p-2 border-transparent',
            title ? (labelVertical ? '' : 'ltr:ml-1 rtl:mr-1') : '',
            lg ? 'text-lg' : xs ? 'text-xs' : xxs ? 'text-xxs' : 'text-sm',

            disabled
              ? 'text-menu-text/50 bg-app-input/60'
              : 'bg-app-input/90 active:bg-app-input focus:bg-app-input/75 text-menu-text',
            isNumeric ? 'text-end' : 'text-start',
            {
              'text-menu-text/75': isValid && !isValueApplied,
              'w-2/3': inputText,
              'rounded-md': rounded,
              'rounded-l': !rounded && roundedLeft,
              'rounded-r': !rounded && roundedRight,
              'text-alerts-error border-alerts-error': hasError,
            },
            classNameValue,
          )}
          type={isNumeric ? 'number' : 'text'}
          inputMode={isNumeric ? 'numeric' : null}
          pattern={isNumeric ? '[0-9]*[.,]?[0-9]+' : null}
          disabled={disabled}
          value={isNumeric ? value : `${value}`}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={e => {
            onEndEdit && onEndEdit(e.target.value);
          }}
          onKeyUp={handleKeyPress}
        />
      </label>
      {children}
    </div>
  );
};
