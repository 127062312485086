import * as React from 'react';
const CongestionMapVariableIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CongestionMapVariableIcon"
    {...props}
  >
    <g opacity={0.4}>
      <circle cx={23} cy={23} r={12} strokeWidth={0} />
    </g>
    <g opacity={0.6}>
      <circle cx={64} cy={23} r={21} strokeWidth={0} />
    </g>
    <g opacity={0.8}>
      <circle cx={105} cy={23} r={7.5} strokeWidth={0} />
    </g>
    <circle cx={23} cy={64} r={14} strokeWidth={0} />
    <g opacity={0.7}>
      <circle cx={64} cy={64} r={7.5} strokeWidth={0} />
    </g>
    <g opacity={0.9}>
      <circle cx={105} cy={64} r={20} strokeWidth={0} />
    </g>
    <g opacity={0.9}>
      <circle cx={23} cy={105} r={12} strokeWidth={0} />
    </g>
    <g opacity={0.8}>
      <circle cx={64} cy={105} r={19} strokeWidth={0} />
    </g>
    <g opacity={0.5}>
      <circle cx={105} cy={105} r={15} strokeWidth={0} />
    </g>
  </svg>
);
export default CongestionMapVariableIcon;
