import {
  AnalyzeResultStatus,
  OptimizationRunStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { assignmentStatsLocationsByLevel } from '../../assignmentStats/assignmentStats.state';
import { AsyncLoadStatus } from '../../common/types';
import LayoutAlertsBase from '../../layout/alerts/LayoutAlertsBase';
import { InformationAlertMessageType } from '../../layout/alerts/LayoutInformationAlert';
import { LoadingAlertMessageType } from '../../layout/alerts/LayoutLoadingAlert';
import { LoadingErrorAlertMessageType } from '../../layout/alerts/LayoutLoadingErrorAlert';
import { assignmentComplianceLocationsByLevel } from '../store/assignmentCompliance.state';
import { assignmentPolicyLocationByLevel } from '../store/assignmentPolicy.state';
import { optimisationStatus } from '../store/optimisation.state';
import {
  routingPolicyInaccessibleLevelLocation,
  routingPolicyRoutes,
} from '../store/routingPolicy/routingPolicy.state';
import { simulationHeatmapLocationByLevel } from '../store/simulation.heatmap.state';
import { simulationIssuesSelectedLevelLocations } from '../store/simulation.issues.state';
import {
  simulationAnalyzePicklistEventsSelectedLoadStatus,
  simulationAnalyzeResult,
  simulationAnalyzeStatus,
  simulationWaypointHeatmap,
} from '../store/simulation.state';
import { showAnalyseData } from '../store/simulation.wizard.state';
import { sizeComplianceLevelLocation } from '../store/sizeCompliance.state';
import { stackingPolicyLocationByLevel } from '../store/stackingPolicy/stackingPolicy.state';
import {
  weightComplianceBay,
  weightComplianceLevelLocation,
} from '../store/weightCompliance.state';

const SimulationLayoutAlerts: React.FC = () => {
  const analyzeStatus = useRecoilValue(simulationAnalyzeStatus);
  const optimiseStatus = useRecoilValue(optimisationStatus);
  const analyzeResult = useRecoilValue(simulationAnalyzeResult);
  const applyAnalyse = useRecoilValue(showAnalyseData);

  const heatmapLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByLevel(analyzeResult?.id),
  );
  const congestionLoadable = useRecoilValueLoadable(simulationWaypointHeatmap);
  const picklistLoadStatus = useRecoilValue(
    simulationAnalyzePicklistEventsSelectedLoadStatus,
  );
  const weightComplianceBayLoadable =
    useRecoilValueLoadable(weightComplianceBay);
  const weightComplianceLocLoadable = useRecoilValueLoadable(
    weightComplianceLevelLocation,
  );
  const sizeComplianceLocLoadable = useRecoilValueLoadable(
    sizeComplianceLevelLocation,
  );
  const assignmentComplianceLocLoadable = useRecoilValueLoadable(
    assignmentComplianceLocationsByLevel,
  );
  const routingInaccessibleLoadable = useRecoilValueLoadable(
    routingPolicyInaccessibleLevelLocation,
  );
  const routingRoutesLoadable = useRecoilValueLoadable(routingPolicyRoutes);
  const issuesLoadable = useRecoilValueLoadable(
    simulationIssuesSelectedLevelLocations,
  );
  const assignmentPolicyLocationLoadable = useRecoilValueLoadable(
    assignmentPolicyLocationByLevel,
  );
  const stackingPolicyLoadable = useRecoilValueLoadable(
    stackingPolicyLocationByLevel,
  );
  const assignmentHeatmapLoadable = useRecoilValueLoadable(
    assignmentStatsLocationsByLevel,
  );

  const messages: LoadingAlertMessageType[] = [];
  const errors: LoadingErrorAlertMessageType[] = [];
  const infos: InformationAlertMessageType[] = [];

  // #3 - analyze result
  const isAnalyzeLoading =
    analyzeStatus === AnalyzeResultStatus.COMPLETED &&
    !analyzeResult &&
    applyAnalyse;
  if (isAnalyzeLoading) {
    messages.push(LoadingAlertMessageType.analyze);
  }

  // #4 - location heatmap
  if (heatmapLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.heatmap);
  }
  if (heatmapLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.heatmap);
  }

  // #5 - waypoint heatmap
  if (congestionLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.congestion);
  }
  if (congestionLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.congestion);
  }
  // #6 - order route
  if (
    picklistLoadStatus === AsyncLoadStatus.Loading ||
    routingRoutesLoadable.state === 'loading'
  ) {
    messages.push(LoadingAlertMessageType.route);
  }
  if (
    picklistLoadStatus === AsyncLoadStatus.Error ||
    routingRoutesLoadable.state === 'hasError'
  ) {
    errors.push(LoadingErrorAlertMessageType.route);
  }

  // assignment stats heatmap
  if (assignmentHeatmapLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.assignmentHeatmap);
  }
  if (assignmentHeatmapLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.assignmentHeatmap);
  }

  //stacking policy
  if (stackingPolicyLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.stackingPolicy);
  }
  if (stackingPolicyLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.stackingPolicy);
  }
  // storage policy locations
  if (assignmentPolicyLocationLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.assignmentPolicy);
  }
  if (assignmentPolicyLocationLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.assignmentPolicy);
  }

  // compliance
  if (
    weightComplianceBayLoadable.state === 'loading' ||
    weightComplianceLocLoadable.state === 'loading'
  ) {
    messages.push(LoadingAlertMessageType.weightCompliance);
  }
  if (
    weightComplianceBayLoadable.state === 'hasError' ||
    weightComplianceLocLoadable.state === 'hasError'
  ) {
    errors.push(LoadingErrorAlertMessageType.weightCompliance);
  }

  if (sizeComplianceLocLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.sizeCompliance);
  }
  if (sizeComplianceLocLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.sizeCompliance);
  }

  if (assignmentComplianceLocLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.assignmentCompliance);
  }
  if (assignmentComplianceLocLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.assignmentCompliance);
  }

  if (routingInaccessibleLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.routingInaccessible);
  }
  if (routingInaccessibleLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.routingInaccessible);
  }

  // INFO STATUSES
  if (analyzeStatus === AnalyzeResultStatus.ANALYZING) {
    infos.push(InformationAlertMessageType.analyze);
  }
  if (optimiseStatus === OptimizationRunStatus.IN_PROGRESS) {
    infos.push(InformationAlertMessageType.optimise);
  }

  if (issuesLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.datasetIssues);
  }
  if (issuesLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.datasetIssues);
  }

  return (
    <LayoutAlertsBase
      messages={_.take(messages, 1)}
      errors={errors}
      infos={infos}
    />
  );
};
export default SimulationLayoutAlerts;
