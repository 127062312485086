import {
  AnalyzeResultProcessType,
  PicklistEventType,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger, formatToPrecision } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { Stat } from '../../../components/stats/Stat';
import { StatListItem } from '../../../components/stats/StatListItem';
import { agentCategoriesMap } from '../../../resourcePolicy/data/categories/categories';
import { agentModelsMap } from '../../../resourcePolicy/data/models/models';
import { analyzeResultById } from '../../store/analyze.state';
import { LCV_EU_Multiplier } from '../../store/resourcePolicy.default';
import { resourcePolicyAgentById } from '../../store/resourcePolicy.state';
import {
  workforceAgentEventsTotal,
  workforceAgentForecastEnabled,
  workforceEffectiveAgentSchedules,
  workforceSelectedAgentId,
} from '../../store/workforce.state';
import {
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_TOTAL,
  DRILL_KEY_WORK_TOTAL,
} from '../../store/workforce.types';

export type WorkforceAgentStatsProps = {
  analyzeId: string;
};

const WorkforceAgentStats: React.FC<WorkforceAgentStatsProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const eventsTotals = useRecoilValue(
    workforceAgentEventsTotal(props.analyzeId),
  );
  const agent = useRecoilValue(resourcePolicyAgentById(agentId));
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );
  const forecastSettings = useRecoilValue(
    workforceEffectiveAgentSchedules(agentId),
  );
  const analyzeResult = useRecoilValue(analyzeResultById(props.analyzeId));

  const durationAnalyzeRaw = eventsTotals.duration[DRILL_KEY_TOTAL];
  const durationWorkTotal = eventsTotals.duration[DRILL_KEY_WORK_TOTAL];
  const costWorkWithLabourRaw = eventsTotals.cost[DRILL_KEY_TOTAL];

  const assembledOrdersCount =
    analyzeResult.totalAssembledOrders +
    analyzeResult.totalAssembledPartiallyOrders;

  const durationTotalRaw =
    eventsTotals.duration[DRILL_KEY_TOTAL] +
    eventsTotals.duration[DRILL_KEY_IDLE];
  //- eventsTotals.duration[DRILL_KEY_OVERTIME];

  const costTotalRaw =
    eventsTotals.cost[DRILL_KEY_TOTAL] + eventsTotals.cost[DRILL_KEY_IDLE]; //- eventsTotals.cost[DRILL_KEY_OVERTIME];

  const utilizationRaw = durationAnalyzeRaw / durationTotalRaw;
  const utilization = formatter.formatShare(utilizationRaw);
  const overTimeRaw = eventsTotals.duration[DRILL_KEY_OVERTIME];
  const idleTimeRaw = eventsTotals.duration[DRILL_KEY_IDLE];

  const fteDailyAverageRaw =
    eventsTotals.duration[DRILL_KEY_WORK_TOTAL] /
    eventsTotals.effectiveShiftDurations;
  const fteTotalRaw = fteDailyAverageRaw * eventsTotals.effectiveShiftCount;

  const duration = formatter.formatTimeSpan(durationAnalyzeRaw, 1);
  const durationTotal = formatter.formatTimeSpan(durationTotalRaw, 1);
  const overtime = formatter.formatTimeSpan(overTimeRaw);
  const idle = formatter.formatTimeSpan(idleTimeRaw);
  const overtimeCost = formatter.formatCurrency(
    eventsTotals.cost[DRILL_KEY_OVERTIME] ?? 0,
  );
  const idleCost = formatter.formatCurrency(
    eventsTotals.cost[DRILL_KEY_IDLE] ?? 0,
  );
  const cost = formatter.formatCurrency(costWorkWithLabourRaw);
  const costTotal = formatter.formatCurrency(costTotalRaw);

  const fteTotal = formatToPrecision(fteTotalRaw, 2);
  const fteDailyAverage = formatToPrecision(fteDailyAverageRaw, 2);

  const agentEventsSummary = _.find(
    analyzeResult.summary.agents,
    a => a.agent === agentId,
  );
  const travelDurations =
    _.find(
      agentEventsSummary?.eventTypes,
      e =>
        e.eventType === PicklistEventType.TRAVELLING_HORIZONTAL &&
        e.processType === AnalyzeResultProcessType.PICKING,
    )?.totalDuration ?? 0;

  const liftingDurations =
    _.find(
      agentEventsSummary?.eventTypes,
      e =>
        e.eventType === PicklistEventType.TRAVELLING_VERTICAL &&
        e.processType === AnalyzeResultProcessType.PICKING,
    )?.totalDuration ?? 0;

  const agentEnergyTime = travelDurations + liftingDurations;

  const energyRate = agent.specs?.powerSource?.energyConsumptionPerSecond ?? 0;
  const carbonValue = agentEnergyTime * energyRate * LCV_EU_Multiplier;
  const carbon = formatter.formatCO2(carbonValue);

  const distanceRaw =
    travelDurations * agent.specs?.horizontalTravelling.speed.speedLaden;
  const distance = formatter.formatDistance(distanceRaw);

  const agentCategory = agentCategoriesMap[agent.resourceTypeId];
  const agentModel = _.isNil(agent.metadata?.modelId)
    ? null
    : agentModelsMap[agent.metadata?.modelId];

  // const itemWidthStyle =
  //   'min-w-half sm:min-w-third md:min-w-quarter lg:min-w-1/5 xl:min-w-1/6 2xl:min-w-1/8 flex-1';

  const itemWidthStyle = 'flex-1';

  const minAgentCount =
    _.min(
      forecastSettings.map(s =>
        _.min(_.map(s.shiftSettings, ss => ss.agentAmount)),
      ),
    ) ?? agent.utilisation.agentAmount;

  const maxAgentCount =
    _.max(
      forecastSettings.map(s =>
        _.max(_.map(s.shiftSettings, ss => ss.agentAmount)),
      ),
    ) ?? agent.utilisation.agentAmount;

  return (
    <div
      data-component="WorkforceAgentStats"
      className={classNames(
        'flex flex-wrap w-full content-evenly',
        'grid grid-cols-2',
        isForecastSimulation ? 'xl:grid-cols-4' : 'lg:grid-cols-5',
        'gap-0.5', //gap-1
      )}
    >
      {/* <AgentStatItem
        className={`p-1 relative bg-app-panel-dark/60 flex-1 overflow-hidden min-w-full md:min-w-quarter mt-1`}
        cardClassName="h-full"
        // isActive={props.agent.id === selected}
        // onClick={() => onSelectValue(props.agent.id)}
        icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
        iconClassName={`w-24 h-24 p-1`}
        // title={category?.title}
        // subtitle={`Cost: ${cost.value}${cost.unit} Utilisation: ${utilization.value}${utilization.unit}`}
        // counter={agent.utilisation?.agentAmount}
      >
        <div className="p-2">
          <div className="truncate text-xs">{agentCategory?.title}</div>
          <div className="truncate opacity-70 text-xxs">{agentModel?.title}</div>
        </div>
      </AgentStatItem> */}
      <Stat
        className={`${itemWidthStyle}`}
        hasHelper
        isPreview
        inPanelMode
        title={t`Agent(s)`}
        tag={isForecastSimulation ? t`Forecast` : ``}
        classSelected={isForecastSimulation ? 'bg-menu-active' : ''}
        value={
          minAgentCount === maxAgentCount
            ? formatInteger(minAgentCount)
            : `${formatInteger(minAgentCount)} — ${formatInteger(
                maxAgentCount,
              )}`
        }
      >
        <div className="flex-1">
          <StatListItem
            title={t`Distance`}
            value={distance.value}
            unitOfMeasure={distance.unit}
          />
          <StatListItem
            title={t`CO2`}
            value={carbon.value}
            unitOfMeasure={carbon.unit}
          />
        </div>
      </Stat>
      <Stat
        className={`${itemWidthStyle}`}
        hasHelper
        isPreview
        inPanelMode
        title={t`Costs`}
        tag={isForecastSimulation ? t`Forecast` : ``}
        value={costTotal.value}
        unitOfMeasure={costTotal.unit}
        isUnitPrefix={costTotal.prefixUnit}
      >
        <div className="flex-1 divide-y divide-solid divide-menu-400/50">
          <StatListItem
            title={t`Picking Cost`}
            value={cost.value}
            unitOfMeasure={cost.unit}
            isUnitPrefix={cost.prefixUnit}
          />
          {eventsTotals.cost[DRILL_KEY_OVERTIME] > 0 && (
            <StatListItem
              title={t`Overtime`}
              unitOfMeasure={overtimeCost.unit}
              value={overtimeCost.value}
              isUnitPrefix={overtimeCost.prefixUnit}
            />
          )}
          {eventsTotals.cost[DRILL_KEY_IDLE] > 0 && (
            <StatListItem
              title={t`Idle`}
              unitOfMeasure={idleCost.unit}
              value={idleCost.value}
              isUnitPrefix={idleCost.prefixUnit}
            />
          )}
        </div>
      </Stat>
      <Stat
        className={`${itemWidthStyle}`}
        hasHelper
        isPreview
        inPanelMode
        title={t`Time`}
        tag={isForecastSimulation ? t`Forecast` : ``}
        unitOfMeasure={durationTotal.unit}
        value={durationTotal.value}
      >
        <StatListItem
          title={t`Work (h)`}
          unitOfMeasure={duration.unit}
          value={duration.value}
          // statClass={isForecastSimulation ? t`flex-col` : ``}
        ></StatListItem>
        {/* <StatListItem
          title={t`Total Time`}
          unitOfMeasure={durationTotal.unit}
          value={durationTotal.value}
        /> */}
        {overTimeRaw > 0 && (
          <StatListItem
            title={t`Overtime`}
            unitOfMeasure={overtime.unit}
            value={overtime.value}
          />
        )}
        {idleTimeRaw > 0 && (
          <StatListItem
            title={t`Idle`}
            unitOfMeasure={idle.unit}
            value={idle.value}
          />
        )}
      </Stat>
      <Stat
        className={`${itemWidthStyle}`}
        hasHelper
        isPreview
        inPanelMode
        title={t`FTE`}
        tag={isForecastSimulation ? t`Forecast` : ``}
        value={fteTotal}
      >
        <StatListItem title={t`FTE (Daily Average)`} value={fteDailyAverage} />

        <StatListItem
          title={t`Utilisation`}
          unitOfMeasure={utilization.unit}
          value={utilization.value}
        />
        {/* <StatListItem
          title={t`Efficiency`}
          unitOfMeasure={efficiency.unit}
          value={efficiency.value}
        /> */}
        {/* <StatListItem
          title={t`Total Shifts`}
          unitOfMeasure={shift.unit}
          value={shift.value}
        /> */}
      </Stat>
      {!isForecastSimulation && (
        <Stat
          className={`${itemWidthStyle} col-span-2 lg:col-span-1`}
          hasHelper
          isPreview
          inPanelMode
          title={t`Velocity`}
          tag={isForecastSimulation ? t`Forecast` : ``}
          unitOfMeasure={t`O/D`}
          value={
            eventsTotals.effectiveShiftCount > 0
              ? formatInteger(
                  assembledOrdersCount / eventsTotals.effectiveShiftCount,
                )
              : 0
          }
        >
          <StatListItem
            title={t`Orders`}
            // unitOfMeasure={t`Orders`}
            value={formatInteger(assembledOrdersCount)}
          />
          {/* <StatListItem
            title={t`Lines/Hour`}
            unitOfMeasure={t`l/h`}
            value={utilization.value}
          /> */}
        </Stat>
      )}
    </div>
  );
};

export default WorkforceAgentStats;
