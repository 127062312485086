import { useApolloClient } from '@apollo/client';
import {
  FindSimulationItemsByFilterDocument,
  FindSimulationItemsByFilterQuery,
  FindSimulationItemsByFilterQueryVariables,
  ItemsFilter,
  SimulationItemFilterUnionInput,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { DATASET_VIEW_PAGE_SIZE } from '../common/constants';
import { AsyncLoadStatus, DataPageParams } from '../common/types';
import { assignmentPolicyShowUnassigned } from '../simulation/store/assignmentPolicy.state';
import {
  policyFilteredProducts,
  policyFilteredProductsSearchValues,
} from '../simulation/store/policyFilter.state';
import { simulationCurrentId } from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';

export type LoadSimulationProductsByRuleParams = {
  includeMatching?: SimulationItemFilterUnionInput;
  excludeMatching?: SimulationItemFilterUnionInput[];
  page?: DataPageParams;
};

function useLoadSimulationProductsByRule() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Items by rule.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadSimulationProductsByRuleParams) => {
        const current = await snapshot.getPromise(policyFilteredProducts);
        set(policyFilteredProducts, {
          ...current,
          status: AsyncLoadStatus.Loading,
        });
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadSimulationProductsByRuleParams) => {
        const simulationId = await snapshot.getPromise(simulationCurrentId);
        const current = await snapshot.getPromise(policyFilteredProducts);
        const searchValues = await snapshot.getPromise(
          policyFilteredProductsSearchValues,
        );
        const showUnassigned = await snapshot.getPromise(
          assignmentPolicyShowUnassigned,
        );
        const { includeMatching, excludeMatching } = params;

        const filter: ItemsFilter = {
          consigneeContains: searchValues['consignee'],
          skuContains: searchValues['sku'],
          skuGroupContains: searchValues['skuGroup'],
        };

        function handleError(details, stack) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(policyFilteredProducts, {
            status: AsyncLoadStatus.Error,
            result: null,
            totalCount: 0,
          });
        }

        const page = {
          limit: params.page?.limit ?? DATASET_VIEW_PAGE_SIZE,
          skip: params.page?.skip ?? 0,
        };

        const query = client.watchQuery<
          FindSimulationItemsByFilterQuery,
          FindSimulationItemsByFilterQueryVariables
        >({
          query: FindSimulationItemsByFilterDocument,
          variables: {
            input: {
              simulationId,
              includeMatching,
              excludeMatching,
              includeUnassigned: showUnassigned,
            },
            page,
            filter,
          },
        });

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let content = data?.findSimulationItemsByFilter?.content || [];
            if (params.page?.isAppend) {
              content = [...current.result, ...content];
            }

            set(policyFilteredProducts, {
              status: AsyncLoadStatus.Ok,
              result: content,
              totalCount: data?.findSimulationItemsByFilter.totalCount,
            });
          },
          error => {
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadSimulationProductsByRuleParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadSimulationProductsByRule;
