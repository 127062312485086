import classNames from 'classnames';
import React from 'react';
import { Container } from '../components/layout/ContainerFlex';
import LayoutWatcher from '../layout/watcher/LayoutWatcher';
import SimulationExtraDatasetView from './SimulationExtraDatasetView';
import SimulationMainContentView from './SimulationMainContentView';

const SimulationMainView: React.FC = () => {
  return (
    <Container
      col
      overflow
      componentName={`SimulationMainViewContainer`}
      className={classNames(
        'flex-1',
        'ltr:border-l rtl:border-r border-app-panel-dark',
      )}
    >
      <SimulationMainContentView />

      <SimulationExtraDatasetView />
      <LayoutWatcher
        stageId="simulation-area-view"
        sidebarIds={[
          'sidebar-simulation-info-common',
          'sidebar-simulation-info-location',
        ]}
      />
    </Container>
  );
};

export default SimulationMainView;
