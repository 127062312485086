import { LoadBalancingZoneType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../components/layout/TitleSection';
import { PolicyCardItem } from '../components/policies/PolicyCardItem';
import { getZoneTypeOptions } from './loadBalancingPolicy.helper';

import classNames from 'classnames';
import {
  loadBalancingPolicyRule,
  loadBalancingPolicySelectedIdentity,
} from './loadBalancingPolicy.state';

const LoadBalancingZoneTypeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(loadBalancingPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    loadBalancingPolicyRule(selectedIdentity?.ruleId),
  );
  if (selectedIdentity?.type !== 'zone-type' || !rule) return null;

  const selected = rule.type;
  function onChangeMode(type: LoadBalancingZoneType) {
    updateRule({
      ...rule,
      type,
    });
  }

  const options = getZoneTypeOptions(t);
  return (
    <div
      data-component="LoadBalancingZoneTypeEditor"
      className={classNames('flex flex-1 flex-col')}
    >
      <TitleSection
        id={`policy-swap-mode-section`}
        title={t`Load Balance by`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div
            className={classNames(
              'bg-app-panel-dark/60',
              'flex flex-col',
              'p-1',
            )}
          >
            <div
              className={classNames(
                'relative',
                'flex flex-1 flex-col',
                'h-full min-h-[3rem]',
              )}
            >
              {_.map(options, (mode, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-group-${mode.id}`}
                    isActive={mode.id === selected}
                    onClick={() => onChangeMode(mode.id)}
                    icon={mode.icon}
                    iconClassName={classNames('w-12 h-12 ltr:mr-2 rtl:ml-2')}
                    title={mode.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default LoadBalancingZoneTypeEditor;
