export type CCTVConfig = {
  aisleTitle: string;
};

export const cctvConfig: CCTVConfig[] = [
  { aisleTitle: 'AX' },
  { aisleTitle: 'AO' },
  { aisleTitle: 'AM' },
  { aisleTitle: 'AV' },
  { aisleTitle: 'AR' },
  { aisleTitle: 'Access aisle (Bottom)' },
];
