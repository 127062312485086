import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, useState } from 'react';
import { analyticsAgent } from '../../AnalyticTracker';
import { cn } from '../../common/utils';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';

export const ButtonSize = {
  xs: 'w-full sm:h-12 lg:h-16 my-0.5',
  sm: 'w-full h-12 sm:h-14 md:h-14 lg:h-20 my-0.5',
  md: 'w-full h-12 sm:h-14 md:h-14 lg:h-20 my-0.5',
  lg: 'w-full h-16 sm:h-20 lg:h-24 my-0.5',
  auto: 'w-full my-0.5',
};

export type SidebarMenuItemProps = {
  className?: string;
  children?: React.ReactNode;
  message?: string;
  title: string;
  titleMinimised?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  active?: boolean;
  visited?: boolean;
  disabled?: boolean;
  locked?: boolean;
  isOpen?: boolean;
  isMinimised?: boolean;
  inProgress?: number | boolean;
  hasMenuItems?: boolean;
  treeLine?: boolean;
  treeLineStart?: boolean;
  treeLineEnd?: boolean;
  stepCounter?: string;
  counterItems?: number;
  counterIssues?: number;
  menuLevel?: string;
  project_state?: string;
  url_path?: string;
  isSticky?: boolean;
  hasWizardArrows?: boolean;
  buttonSize?: typeof ButtonSize | string;
  isHidden?: boolean;
  onClick?: () => void;
};

export const SidebarMenuItem = ({
  className,
  hasMenuItems,
  menuLevel,
  isOpen,
  isMinimised,
  inProgress,
  visited,
  disabled,
  locked,
  active,
  treeLine,
  hasWizardArrows,
  treeLineStart,
  treeLineEnd,
  stepCounter,
  counterItems,
  counterIssues,
  title,
  titleMinimised,
  message,
  icon,
  url_path,
  isSticky,
  buttonSize,
  isHidden,
  onClick,
}: SidebarMenuItemProps) => {
  const [id] = useState<string>(_.uniqueId());

  const IconMenu = icon;

  // const arrow =
  //   'mb-1 relative after:absolute after:left-0 after:right-0 after:m-auto after:border-t-8 after:border-menu-100 before:absolute before:left-0 before:right-0 before:m-auto before:border-t-[0.65rem] before:ltr:border-l rtl:border-r-[2rem] before:border-r-[2rem] before:border-menu-700 before:hover:border-menu-active';

  const StepLock = () => {
    return (
      <div
        data-component="StepLock"
        className={`z-1000 relative flex items-center justify-center rounded-full font-bold ltr:mr-1 rtl:ml-1`}
      >
        <Icon.Lock className={`text-menu-disabled h-5 w-5 fill-current`} />
      </div>
    );
  };

  // StepCounter
  const menuStyleSizeCounterLg = ' w-8 h-8 ltr:mr-2 rtl:ml-2 items-center ';
  const menuStyleSizeCounterSm =
    ' w-6 h-6 text-xxs left-[0.25rem] items-center ';

  const StepCounter = () => {
    return (
      <div
        data-component="StepCounterMenuItem"
        className={classNames(
          'z-1000 relative flex justify-center rounded-full font-bold',
          isMinimised
            ? 'absolute left-2 top-1 hidden items-end'
            : 'items-center',
          menuLevel === '0' || menuLevel === '1'
            ? active
              ? menuStyleSizeCounterLg +
                ` ` +
                'text-menu-active bg-app-panel-dark border-app-panel-dark border '
              : disabled
                ? menuStyleSizeCounterLg +
                  ` ` +
                  `${
                    isMinimised
                      ? 'bg-app-panel-light/50 group-hover:bg-app-panel-light/50 text-menu-text/60'
                      : 'bg-app-panel/90 group-hover:bg-app-panel text-menu-text'
                  }`
                : menuStyleSizeCounterLg +
                  ` ` +
                  'text-menu-active-text bg-menu-active border-menu-active group-hover:border-menu border group-hover:border'
            : active // menuLevel all other  menuLevel === '2' || menuLevel === '3'
              ? menuStyleSizeCounterSm +
                ` ` +
                'text-menu-active bg-app-panel-dark border-app-panel-dark border'
              : disabled
                ? menuStyleSizeCounterSm +
                  ` ` +
                  `${
                    isMinimised
                      ? 'bg-app-panel-light/50 group-hover:bg-app-panel-light/50 text-menu/60'
                      : 'bg-app-panel/90 group-hover:bg-app-panel text-menu-text'
                  }`
                : menuStyleSizeCounterSm +
                  ` ` +
                  'text-menu-active bg-app-panel items-end' +
                  ` ` +
                  (isMinimised
                    ? 'border-app-background/80' // minimised
                    : 'border-menu-active group-hover:border-app-background/70 border'),
        )}
      >
        {inProgress && (
          <Loader
            size={12}
            stroke={8}
            type={'loading-original'}
            className={classNames('absolute h-12 w-12 fill-current')}
          />
        )}
        {stepCounter}
      </div>
    );
  };

  const StepTreeline = () => {
    return (
      <div
        data-component="StepTreeline"
        className={classNames(
          'ltr:left-menu rtl:right-menu absolute z-0 ltr:border-l rtl:border-r',
          inProgress || active
            ? 'border-app-panel-dark group-hover:border-menu-active'
            : disabled
              ? 'border-app-background/20'
              : 'border-menu-active group-hover:border-app-background/60',
          { 'h-full': treeLineStart || treeLineEnd },
          treeLineStart ? 'top-1/2 h-1/2' : 'bottom-0',
          treeLineEnd ? 'bottom-1/2 h-1/2' : 'top-0',
          { 'border-opacity-20': disabled },
          { 'hidden ltr:left-1/2 rtl:left-1/2': isMinimised },
        )}
      />
    );
  };

  const StepWizardMinimised = () => {
    return (
      <>
        {!treeLineEnd && (
          <svg
            data-component="ArrowTop"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 37"
            fill="currentColor"
            className={classNames(
              'absolute left-0 right-0 z-50 -mt-1 h-full w-full',
              'text-sidebar-menu',
              menuLevel === '0'
                ? 'level-0 bottom-[-30px] lg:bottom-[-46px]'
                : menuLevel === '1'
                  ? 'level-1 bottom-[-29px] lg:bottom-[-45px]'
                  : menuLevel === '2'
                    ? 'level-2 bottom-[-28px] lg:bottom-[-43px]'
                    : menuLevel === '3'
                      ? 'level-3 bottom-[-27px] lg:bottom-[-42px]'
                      : menuLevel === '4'
                        ? 'level-4 bottom-[-27px] lg:bottom-[-41px]'
                        : menuLevel === '5'
                          ? 'level-5 bottom-[-27px] lg:bottom-[-41px]'
                          : '',
            )}
          >
            <path d="M0 0v11.1a5 5 0 003.79 4.85L64 34l60.21-18.05a5 5 0 003.79-4.86V0z" />
          </svg>
        )}
        {!treeLineEnd && (
          <svg
            data-component="ArrowBottom"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 37"
            fill="currentColor"
            className={classNames(
              'h-full w-full',
              'absolute left-0 right-0',
              'z-100',
              '-mt-1',
              'bottom-[-25px] lg:bottom-[-42px]',
              disabled
                ? 'text-menu-disabled group-hover:text-menu-disabled'
                : active
                  ? 'text-menu-active group-hover:text-menu-hover'
                  : menuLevel === '0'
                    ? 'text-menu-level-0 group-hover:text-menu-hover'
                    : menuLevel === '1'
                      ? 'text-menu-level-1 group-hover:text-menu-hover'
                      : menuLevel === '2'
                        ? 'text-menu-level-2 group-hover:text-menu-hover'
                        : menuLevel === '3'
                          ? 'text-menu-level-3 group-hover:text-menu-hover'
                          : '',
            )}
          >
            <path d="M0 0v11.1a5 5 0 003.79 4.85L64 34l60.21-18.05a5 5 0 003.79-4.86V0z" />
          </svg>
        )}
      </>
    );
  };

  return (
    <li
      role="menuitem"
      data-component={`SidebarMenuItem`}
      data-label={`menu ${isMinimised ? titleMinimised : title}`}
      aria-label={`menu ${isMinimised ? titleMinimised : title}`}
      className={classNames(
        'group',
        'relative',
        'text-xs md:text-sm',
        menuLevel ? 'menuLevel-' + menuLevel : 'menuLevel-0',
        isMinimised && !buttonSize && ButtonSize.sm,
        {
          'bg-menu-active backdrop-saturate-110 sticky top-0 backdrop-blur-lg backdrop-filter':
            isSticky,
        },
        { 'hasWizardArrows mb-0': isMinimised && hasWizardArrows },
        {
          'border-app-background/75 border-b': !treeLine,
        },
        disabled ? 'cursor-default' : 'cursor-pointer',
        { hidden: isHidden },
      )}
      onClick={() => {
        !disabled && onClick && onClick();
        try {
          analyticsAgent?.track(`Menu (Sidebar): ${title}`);
        } catch (ex) {
          console.debug('analyticsAgent Error:', ex);
        }
      }}
    >
      {counterItems > 0 && (
        <span
          data-component="counterItems"
          className={cn(
            'bg-menu/50 text-minimal absolute right-0.5 m-px rounded p-0.5',
            'border-menu-active border',
          )}
        >
          {counterItems}
        </span>
      )}
      <div
        data-component="menuItemIsMinimised"
        id={id}
        className={classNames(
          isMinimised
            ? 'mt-0 flex h-full w-full flex-1 items-center overflow-hidden text-ellipsis rounded'
            : 'flex items-center p-2',
          { 'bg-menu-active text-menu-active-text': inProgress },
          // 'transition-all ease-in-out duration-200',
          disabled
            ? isMinimised
              ? 'bg-menu-disabled group-hover:bg-menu-disabled'
              : 'bg-menu-level-0/50 group-hover:bg-menu-level-0'
            : active
              ? 'bg-menu-active group-hover:bg-menu-hover group-hover:text-menu-active-text hover:text-menu-active-text'
              : menuLevel === '0'
                ? 'bg-menu-level-0 group-hover:bg-menu-hover group-hover:text-menu-active-text hover:text-menu-active-text'
                : menuLevel === '1'
                  ? 'bg-menu-level-1 text-menu-text group-hover:bg-menu-hover group-hover:text-menu-active-text'
                  : menuLevel === '2'
                    ? 'bg-menu-level-2 group-hover:bg-menu-hover group-hover:text-menu-active-text'
                    : menuLevel === '3'
                      ? 'bg-menu-level-3 group-hover:bg-menu-hover group-hover:text-menu-active-text'
                      : '',
          className,
        )}
      >
        {treeLine && <StepTreeline />}

        {stepCounter && <StepCounter />}

        {isMinimised && hasWizardArrows && <StepWizardMinimised />}

        {hasMenuItems && (
          <div
            data-component="iconContainer"
            className={classNames('flex items-center', {
              'ltr:pl-0 rtl:pr-0': menuLevel === '0',
              'ltr:pl-1 rtl:pr-1': menuLevel === '1',
              'ltr:pl-2 rtl:pr-2': menuLevel === '2',
              'ltr:pl-4 rtl:pr-4': menuLevel === '3',
            })}
          >
            <Icon.ChevronRight
              className={classNames(
                'fill-current',
                'h-9 w-9',
                { 'rotate-90 transform': !isOpen },
                disabled
                  ? 'text-menu-disabled group-hover:text-menu-disabled'
                  : 'text-menu-active group-hover:text-menu-text',
              )}
            />
          </div>
        )}

        <div
          data-component="hasMenuItems"
          className={classNames(
            hasMenuItems ? 'flex-1' : 'w-full flex-1 items-center',
            { 'z-100 pt-2 lg:pt-3': isMinimised && hasWizardArrows },
          )}
        >
          <div
            data-component={`menuLevelContainer-${menuLevel ?? '0'}`}
            className={classNames(
              'flex flex-1 items-center justify-center',
              !isMinimised && menuLevel === '0' && 'ltr:pl-0 rtl:pr-0',
              !isMinimised && menuLevel === '1' && 'ltr:pl-1 rtl:pr-1',
              !isMinimised &&
                menuLevel === '2' &&
                'ltr:ml-5 ltr:pl-2 rtl:mr-5 rtl:pr-2',
              !isMinimised &&
                menuLevel === '3' &&
                'ltr:ml-6 ltr:pl-4 rtl:mr-6 rtl:pr-4',
              disabled
                ? 'text-menu-text/40'
                : inProgress && !active
                  ? 'text-menu-active-text group-hover:text-menu-text'
                  : inProgress && active
                    ? 'text-menu-active-text'
                    : !inProgress && active
                      ? 'text-menu-active-text'
                      : 'hover:text-menu-active-text',
            )}
          >
            {isMinimised && (
              <div
                data-component="isMinimised-wrapper"
                className={classNames(
                  'flex flex-1 flex-col items-center justify-center',
                  'text-center',
                  'lg:w-full',
                )}
              >
                {IconMenu && (
                  <IconMenu
                    className={classNames(
                      'h-8 w-8 md:h-9 md:w-9 lg:h-10 lg:w-10',
                      'fill-current',
                      'text-opacity-50',
                    )}
                  />
                )}
                <div
                  data-component="title-wrapper"
                  className={classNames('flex flex-1', 'mt-1', 'w-full')}
                >
                  {locked && <StepLock />}

                  <span
                    className={classNames(
                      'text-minimal uppercase',
                      'checked:z-menu',
                      'hidden lg:flex lg:flex-col',
                      'w-full',
                    )}
                  >
                    {titleMinimised}
                  </span>
                </div>
              </div>
            )}

            {!isMinimised && IconMenu && (
              <IconMenu
                className={classNames(
                  'h-6 w-6 fill-current',
                  'ltr:mr-4 rtl:ml-4',
                )}
              />
            )}

            {isMinimised && inProgress && (
              <Loader
                size={10}
                stroke={0.5}
                type={'loading-circle'}
                className={classNames(
                  'h-full w-8 xl:w-12',
                  'fill-current',
                  'absolute top-0',
                  active ? 'text-menu-active-text' : 'text-menu-active',
                )}
              />
            )}

            {!isMinimised && title && (
              <div className={classNames('flex flex-1')}>
                {locked && <StepLock />}
                <span>{title}</span>
              </div>
            )}
          </div>
        </div>
        {message && <span className="flex-1">{message}</span>}
      </div>
    </li>
  );
};
