import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../../components/designer/panels/PanelHeader';
import {
  ContainerCol,
  ContainerRow,
} from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../../layout/LayoutActiveFeatureTag';
import AssignmentOccupancyTable from '../../occupancy/AssignmentOccupancyTable';
import SwapPolicyProductByRuleTableView from '../../swapPolicy/SwapPolicyProductByRuleTableView';
import { swapPolicySelectedIdentity } from '../../swapPolicy/swapPolicy.state';
import SimulationComplianceLayoutView from '../SimulationComplianceLayoutView';
import SimulationHelperOptimisation from '../helper/SimulationHelperOptimisation';
import SimulationPolicyLocationLayer from '../layers/SimulationPolicyLocationLayer';
import {
  OptimisationPolicyTabId,
  StepTabItemConfig,
} from '../store/simulation.types';
import { simulationOptimisationPolicyTabKey } from '../store/simulation.wizard.state';
import LoadBalancingPolicyToolbar from '../toolbars/LoadBalancingPolicyToolbar';
import OptimisationSetupAgentViewContainer from './OptimisationSetupAgentViewContainer';

const SimulationOptimisationSetupView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedTab = useRecoilValue(simulationOptimisationPolicyTabKey);
  const swapPolicyIdentity = useRecoilValue(swapPolicySelectedIdentity);

  const showSwapPolicyItemsTable =
    swapPolicyIdentity?.type === 'items' &&
    selectedTab === 'tab-optimisation-policy-swap';

  const content: StepTabItemConfig<OptimisationPolicyTabId>[] = [
    {
      id: 'tab-optimisation-settings',
      title: t`Optimisation`,
      getContent: () => <SimulationHelperOptimisation />,
    },
    {
      id: 'tab-optimisation-policy-swap',
      title: t`Swap policy`,
      getTable: () => <AssignmentOccupancyTable />,
      getLocationLayer: () => <SimulationPolicyLocationLayer />,
    },
    {
      id: 'tab-optimisation-policy-load-balancing',
      title: t`Load balancing`,
      getLocationLayer: () => <SimulationPolicyLocationLayer />,
      getToolbars: () => <LoadBalancingPolicyToolbar />,
    },
    {
      id: 'tab-optimisation-resource-policy',
      title: t`Resource`,
      getContent: () => <OptimisationSetupAgentViewContainer />,
    },
  ];

  const contentItem = _.find(content, c => c.id === selectedTab);

  const viewAs = [
    {
      key: 'table',
      label: t`Table`,
    },
    {
      key: 'layout',
      label: t`Layout`,
    },
  ];

  if (showSwapPolicyItemsTable) {
    return <SwapPolicyProductByRuleTableView />;
  }

  return (
    <ContainerCol
      overflow
      componentName={`SimulationOptimisationViewContainer`}
      className={classNames(
        'flex-1 ltr:border-l rtl:border-r border-app-panel-dark',
      )}
    >
      <ContainerRow overflow className={classNames('relative !h-auto')}>
        <PanelHeader title={contentItem?.title} className={'flex-1'}>
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
        </PanelHeader>
      </ContainerRow>
      {contentItem &&
        _.isFunction(contentItem.getContent) &&
        contentItem.getContent()}
      {contentItem && !_.isFunction(contentItem.getContent) && (
        <ContainerRow
          overflow
          flex1
          className={classNames(
            'relative',
            'border-8 border-app-background/70',
          )}
        >
          <ContainerCol
            overflow
            componentName={`SimulationLayoutViewContainer`}
            className={classNames('relative flex-1')}
          >
            <SimulationComplianceLayoutView
              getLocationLayer={contentItem.getLocationLayer}
              getAbsoluteContent={contentItem.getToolbars}
            />
          </ContainerCol>
        </ContainerRow>
      )}
    </ContainerCol>
  );
};

export default SimulationOptimisationSetupView;
