import Flatten from '@flatten-js/core';
import {
  BayLocationOrder,
  BayTypeModelFragment,
  LayoutBayLocationFragment,
  LocationTypeModelFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Group, Layer } from 'react-konva';
import { useSetRecoilState } from 'recoil';
import { BayFeatureDetailedFragment } from '../../../import/layout/converter/converter.serializable.model';
import BayLocationFeature, {
  BayLocationFeatureProps,
} from '../../features/BayLocationFeature';
import BayStructureFeature, {
  BayStructureFeatureProps,
} from '../../features/BayStructureFeature';
import { StageType } from '../../stage/stage.helper';
import { stageStateById } from '../../stage/stage.state';

export const groundLevelWidthDelta = 0.1;
export const groundLevelHeightDelta = 0.05;

export type BayLocationLayerBaseProps = {
  stageId: StageType;
  bay: BayFeatureDetailedFragment;
  bayType: BayTypeModelFragment;
  locations: Record<string, LayoutBayLocationFragment>;
  locationTypes: Record<string, LocationTypeModelFragment>;
  selectedLocationId: string;

  getColor?: (loc: LayoutBayLocationFragment) => [string, string];
  getLabel?: (loc: LayoutBayLocationFragment) => string;
  getLabelColor?: (loc: LayoutBayLocationFragment) => [string, string];
  getGradientColors?: (location: LayoutBayLocationFragment) => string[];
  onHover?: (loc?: LayoutBayLocationFragment) => void;
  onSelect?: (loc: LayoutBayLocationFragment) => void;

  isCompliance?: boolean;
};

const BayLocationLayerBase: React.FC<BayLocationLayerBaseProps> = props => {
  const stageId = props.stageId ?? 'viewer-bay-view';
  const setStageState = useSetRecoilState(stageStateById(stageId));
  const { bay } = props;
  const locations = _.filter(
    props.locations,
    l => l.locationDepthFromFront === 0,
  );
  // console.log('locations::', locations);
  useEffect(() => {
    if (!_.isNil(bay)) {
      const bayTagSize =
        _(bay.details.levels)
          .map(b => b.levelHeight)
          .min() / 2;

      setStageState({
        autoSizeId: `auto-size-bay-${bay.id}`,
        contentBounds: [
          [
            Math.round(
              Math.min(-groundLevelWidthDelta * bay.details.width, -bayTagSize),
            ),
            Math.round(-bay.details.height * groundLevelHeightDelta),
          ],
          [
            Math.round(bay.details.width * (1 + groundLevelWidthDelta)),
            Math.round(bay.details.height),
          ],
        ],
      });
    }
  }, [setStageState, bay]);

  if (_.isNil(props.bay) || _.isEmpty(props.locations)) return null;

  const { verticalFrameProfile, shelfHeight } = props.bayType;
  const locationsByLevel = _.mapValues(
    _.groupBy(locations, l => l.locationLevel),
    locs => _.sortBy(locs, l => l.locationBayPosition),
  );
  const start = Flatten.point(bay.details?.position.x, bay.details?.position.y);
  // const bayFrontLine: Flatten.Line = Flatten.line(
  //   start,
  //   start.translate(
  //     Flatten.vector(bay.details.frontEdge.x, bay.details.frontEdge.y),
  //   ),
  // );
  const maxShelfHeight = _.max(bay.details.levels.map(l => l.heightFromFloor));

  const shelfPositions = _(bay.details.levels)
    .filter(l => !_.isEmpty(locationsByLevel[l.level]))
    .map(l => ({
      level: l.level,
      y: l.heightFromFloor,
    }))
    .value();

  const bayStructureProps: BayStructureFeatureProps = {
    width: bay.details.width,
    height: bay.details.height,
    frameWidth: verticalFrameProfile,
    shelfPositions,
    minLevelHeight: _(bay.details.levels)
      .map(b => b.levelHeight)
      .min(),
    shelfHeight,
    groundLevelWidthDelta,
    groundLevelHeightDelta,
    showFrames: maxShelfHeight > 0,
  };

  function drawLevelLocationLTR(
    level: number,
    bayWidth: number,
    locationOrder: BayLocationOrder,
  ) {
    const isLTR = locationOrder === BayLocationOrder.LTR;
    const offsetFrom = isLTR ? 0 : bayWidth;
    const offsetMultiplier = isLTR ? 1 : -1;
    let offsetX = offsetFrom + offsetMultiplier * verticalFrameProfile;
    const locationFeatures = _.map(locationsByLevel[level], (loc, index) => {
      const prevLoc = locationsByLevel[level][index - 1];
      const nextLoc = locationsByLevel[level][index + 1];

      const gapCurrent = props.locationTypes[loc.locationRackingType].gapWidth;
      const gapPrev = prevLoc
        ? props.locationTypes[prevLoc.locationRackingType].gapWidth
        : 0;
      const gapNext = nextLoc
        ? props.locationTypes[nextLoc.locationRackingType].gapWidth
        : 0;
      offsetX += offsetMultiplier * Math.max(gapCurrent, gapPrev);

      if (!isLTR) {
        offsetX -= locationsByLevel[level][index].locationWidth;
      }
      const bayAisleId = _.first(bay.bayAisles)?.aisle?.id;
      const hasAccessFromFront = _.some(
        loc.portals,
        p => p.aisleId === bayAisleId,
      );
      const featureProps: BayLocationFeatureProps = {
        location: loc,
        positionX: offsetX,
        positionY: loc.locationHeightFromFloor,
        isSelected: props.selectedLocationId === loc.locationId,
        setSelected: () => props.onSelect && props.onSelect(loc),
        onMouseOver: () => props.onHover(loc),
        onMouseOut: () => props.onHover(null),
        color: props.getColor ? props.getColor(loc) : null,
        inaccessibleFront: !hasAccessFromFront,
        label: props.getLabel ? props.getLabel(loc) : null,
        labelColor: props.getLabelColor ? props.getLabelColor(loc) : null,
        gradientColors: props.getGradientColors
          ? props.getGradientColors(loc)
          : null,
        isCompliance: props.isCompliance,
      };
      if (isLTR) {
        offsetX += locationsByLevel[level][index].locationWidth;
      }

      offsetX += nextLoc ? 0 : offsetMultiplier * Math.max(gapCurrent, gapNext);
      return (
        <BayLocationFeature
          key={`location-${loc.locationId}`}
          {...featureProps}
        />
      );
    });
    return (
      <Group key={`bays-location-level-${level}`}>{locationFeatures}</Group>
    );
  }
  return (
    <Layer>
      <BayStructureFeature {...bayStructureProps} />
      {_.map(bay.details.levels, l =>
        drawLevelLocationLTR(
          l.level,
          bay.details.width,
          bay.details?.locationOrder,
        ),
      )}
      {/* <Text

        fontSize={15}
        align="left"
        padding={1}
        margin={1}
        fill={'white'}
        scaleY={-1}
        text={'Aisle'}
        fontFamily={'NeoSans-Regular'}
        listening={false}
        strokeEnabled={false}
      /> */}
    </Layer>
  );
};

export default BayLocationLayerBase;
