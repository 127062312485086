import { ImportSource } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { Button } from '../components/actions/Button';
import { ButtonWrapper } from '../components/actions/ButtonWrapper';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import { Spacer } from '../components/layout/Spacer';
import { ActionBar } from '../components/nav/ActionBar';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { sidebarStateByType } from '../store/sidebar.state';
import ImportHeader from './ImportHeader';
import ImportInfoSidebar from './sidebar/ImportInfoSidebar';
import SidebarImportMenuWizard from './sidebar/SidebarImportMenuWizard';
import ImportMappingStep from './steps/ImportMappingStep';
import ImportSelectFileStep from './steps/ImportSelectFileStep';
import ImportStartStep from './steps/ImportStartStep';
import ImportTransformStep from './steps/ImportTransformStep';
import ImportUploadStep from './steps/ImportUploadStep';
import {
  importCanUpload,
  importJob,
  importMappingSettingsByType,
  importRawPreview,
  importSelectedStep,
  importTransformedPreview,
  importTypeCurrent,
  importValidationErrors,
  importValidationStatus,
} from './store/import.state';
import { ImportMenuItemId, ImportType } from './store/import.types';
import useLoadImportJob from './useLoadImportJob';
import useProcessImportJob from './useProcessImportJob';
import useResetImportState from './useResetImportState';
import useUploadFile from './useUploadFile';
import useValidatePreview from './useValidatePreview';

export type ImportRootProps = {
  type: ImportType;
};

function UploadButton() {
  const { t } = useTranslation('importer');
  const canUpload = useRecoilValue(importCanUpload);
  const setStepId = useSetRecoilState(importSelectedStep);
  const [uploadFile] = useUploadFile();
  const [processJob] = useProcessImportJob();
  const validatePreview = useValidatePreview();
  const validationStatus = useRecoilValue(importValidationStatus);
  const type = useRecoilValue(importTypeCurrent);
  const mappingSettings = useRecoilValue(importMappingSettingsByType(type));
  const job = useRecoilValue(importJob);

  const validationErrors = useRecoilValue(importValidationErrors);

  const validationErrorCount = _.size(validationErrors);
  const hasAlerts = validationErrorCount > 0;

  async function tryUpload() {
    const previewErrors = await validatePreview({ mappingSettings });
    if (!_.isEmpty(previewErrors)) return;
    setStepId('import-upload-data');
    if (job?.uploaded || job.sourceSettings?.source === ImportSource.DATASET) {
      processJob({});
    } else {
      uploadFile({});
    }
  }

  const isValidating = validationStatus === AsyncLoadStatus.Loading;
  const hasValidationErrors = validationStatus === AsyncLoadStatus.Error;

  return (
    <ButtonWrapper disabled={!canUpload || isValidating}>
      <Button
        className={classNames(
          'flex-1',
          'border-menu/50 border',
          'text-base',
          'min-w-64',
        )}
        label={hasAlerts ? t`Re-Validate` : t`Upload`}
        buttonType="primary"
        hasIconAfter
        buttonIcon={
          <Icon.CloudUpload className={classNames('h-5 w-5 fill-current')} />
        }
        isDisabled={!canUpload || isValidating}
        isLoading={isValidating}
        onPress={() => tryUpload()}
      />
    </ButtonWrapper>
  );
}

function TransformButton() {
  const { t } = useTranslation('importer');
  const rawPreview = useRecoilValue(importRawPreview);
  const setStepId = useSetRecoilState(importSelectedStep);
  // const shouldUpload = useRecoilValue(importSourceShouldUploadFile);
  const isDisabled =
    // shouldUpload &&
    _.isNil(rawPreview);
  return (
    <ButtonWrapper disabled={isDisabled}>
      <Button
        className={classNames(
          'flex-1',
          'border-menu/50 border',
          'text-base',
          'min-w-64',
        )}
        label={t`Transform`}
        buttonType="primary"
        hasIconAfter
        buttonIcon={<IconArrowDir />}
        isDisabled={isDisabled}
        onPress={() => setStepId('import-transform')}
      />
    </ButtonWrapper>
  );
}

function MapButton() {
  const { t } = useTranslation('importer');
  const rawPreview = useRecoilValue(importRawPreview);
  const transformedPreview = useRecoilValue(importTransformedPreview);
  const importType = useRecoilValue(importTypeCurrent);
  const mappingSettings = useRecoilValue(
    importMappingSettingsByType(importType),
  );

  const hasTransformation = mappingSettings?.transformation?.enabled ?? false;
  const setStepId = useSetRecoilState(importSelectedStep);
  const isDisabled = hasTransformation
    ? _.isNil(transformedPreview)
    : _.isNil(rawPreview);

  return (
    <ButtonWrapper disabled={isDisabled}>
      <Button
        className={classNames(
          'flex-1',
          'border-menu/50 border',
          'text-base',
          'min-w-64',
        )}
        label={t`Map fields`}
        buttonType="primary"
        hasIconAfter
        buttonIcon={<IconArrowDir />}
        isDisabled={isDisabled}
        onPress={() => setStepId('import-map-fields')}
      />
    </ButtonWrapper>
  );
}

function NextButton(props: { nextStepId: ImportMenuItemId }) {
  const { t } = useTranslation('importer');
  const setStepId = useSetRecoilState(importSelectedStep);
  return (
    <ButtonWrapper>
      <Button
        className={classNames(
          'flex-1',
          'border-menu/50 border',
          'text-base',
          'min-w-64',
        )}
        label={t`Next`}
        buttonType="primary"
        hasIconAfter
        buttonIcon={<IconArrowDir />}
        onPress={() => setStepId(props.nextStepId)}
      />
    </ButtonWrapper>
  );
}

const ImportRoot: React.FC<ImportRootProps> = props => {
  const { t } = useTranslation('importer');
  const { jobId } = useParams<{ jobId: string }>();
  const stepId = useRecoilValue(importSelectedStep);
  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-importer-info'),
  );
  const [loadJob] = useLoadImportJob();
  const resetState = useResetImportState();

  useEffect(() => {
    if (!_.isNil(jobId)) {
      resetState({
        step: 'import-select-csv',
        importType: props.type,
      });

      loadJob({
        id: jobId,
      });
    } else {
      resetState({
        step: 'import-getting-started',
        importType: props.type,
      });
      setSidebarState({
        ...sidebarState,
        isCollapsed: false,
      });
    }
  }, [jobId]);

  function getMainContent() {
    switch (stepId) {
      case 'import-getting-started':
        return <ImportStartStep {...props} />;
      case 'import-select-csv':
        return <ImportSelectFileStep {...props} />;
      case 'import-transform':
        return <ImportTransformStep {...props} />;
      case 'import-map-fields':
        return <ImportMappingStep {...props} />;
      case 'import-upload-data':
        return <ImportUploadStep {...props} />;
    }
  }

  function importActions() {
    switch (stepId) {
      case 'import-getting-started':
        return (
          <>
            <Spacer flexspace />
            <NextButton nextStepId="import-select-csv" />
          </>
        );
      case 'import-select-csv':
        return (
          <>
            <Spacer flexspace />
            <TransformButton />
          </>
        );
      case 'import-transform':
        return (
          <>
            <Spacer flexspace />
            <MapButton />
          </>
        );
      case 'import-map-fields':
        return (
          <>
            {/* <SelectTargetDocument /> */}
            <Spacer flexspace />

            {/* <CreatePipelineButton /> */}
            <UploadButton />
          </>
        );
    }
  }

  return (
    <>
      <ImportHeader type={props.type} currentStepId={stepId} />
      <ContainerMain data-component="ImportRoot">
        <ContainerSidebar data-component="ImportMenuSidebar" side="left">
          <SidebarImportMenuWizard />
        </ContainerSidebar>
        <Container col>
          <Container hasOverflowY>
            <ContainerSidebar data-component="ImportSidebar" side="left">
              <ImportInfoSidebar type={props.type} />
            </ContainerSidebar>
            {getMainContent()}
          </Container>
          <ActionBar>{importActions()}</ActionBar>
        </Container>
      </ContainerMain>
    </>
  );
};

export default ImportRoot;
