import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../components/actions/ButtonSwitchMulti';

export const loadBalancingKPI = ['absolute', 'relative'] as const;
export type LoadBalancingKPI = (typeof loadBalancingKPI)[number];

export type AnalyzeResultLoadBalancingKPISwitchProps = {
  selected: LoadBalancingKPI;
  setSelected: (value: LoadBalancingKPI) => void;
};

const AnalyzeResultLoadBalancingKPISwitch: React.FC<
  AnalyzeResultLoadBalancingKPISwitchProps
> = props => {
  const { t } = useTranslation('simulation');

  const formatter = useFormatter();

  const loadBalancingKPITitles: Record<LoadBalancingKPI, string> = {
    absolute: t`Absolute`,
    relative: t`Relative`,
  };

  const options: ButtonSwitchMultiOption[] = _.map(
    [...loadBalancingKPI],
    kpi => ({
      label: loadBalancingKPITitles[kpi],
    }),
  );

  return (
    <ButtonSwitchMulti
      buttonType="header"
      autoSize
      options={options}
      selectedIndex={loadBalancingKPI.indexOf(props.selected)}
      onClick={index => {
        props.setSelected(loadBalancingKPI[index]);
      }}
    />
  );
};

export default AnalyzeResultLoadBalancingKPISwitch;
