import { TFunction } from 'i18next';

export const getFeatureDefinitions = (t: TFunction<'features'>) =>
  [
    {
      id: '*',
      title: t('All Features', { ns: 'features' }),
      description: t('All features', { ns: 'features' }),
    },
    // Switches
    {
      id: 'layout',
      title: t('Layout View', { ns: 'features' }),
      description: t('Permission to view the layout', { ns: 'features' }),
    },
    {
      id: 'table',
      title: t('Table View', { ns: 'features' }),
      description: t('Permission to view the table', { ns: 'features' }),
    },
    {
      id: 'timeline',
      title: t('Timeline View', { ns: 'features' }),
      description: t('Permission to view the timeline', { ns: 'features' }),
    },
    // Sidebars
    {
      id: 'sidebar-feed-menu',
      title: t('Activity Feed Menu bar', { ns: 'features' }),
      description: t('Permission to view the Activity Feed Menu bar', {
        ns: 'features',
      }),
    },
    {
      id: 'sidebar-feed-main',
      title: t('Activity Feed Main Menu', { ns: 'features' }),
      description: t('Permission to view the Activity Feed Main Menu', {
        ns: 'features',
      }),
    },
    // Tabs
    {
      id: 'tab-feed-activity',
      title: t('Activity Feed: Events View', { ns: 'features' }),
      description: t('Activity Feed: Events View description', {
        ns: 'features',
      }),
    },
    {
      id: 'tab-feed-summary',
      title: t('Feed Summary', { ns: 'features' }),
      description: t('Summary of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-process',
      title: t('Feed Process', { ns: 'features' }),
      description: t('Process view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-resources',
      title: t('Feed Resources', { ns: 'features' }),
      description: t('Resources view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-workforce',
      title: t('Feed Workforce', { ns: 'features' }),
      description: t('Workforce view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-items',
      title: t('Feed Items', { ns: 'features' }),
      description: t('Items view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-orders',
      title: t('Feed Orders', { ns: 'features' }),
      description: t('Orders view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-heatmaps',
      title: t('Feed Heatmaps', { ns: 'features' }),
      description: t('Heatmaps view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-stats',
      title: t('Feed Stats', { ns: 'features' }),
      description: t('Stats view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-allocate',
      title: t('Feed Allocate', { ns: 'features' }),
      description: t('Allocation view of the feed', { ns: 'features' }),
    },
    {
      id: 'tab-feed-kpi',
      title: t('Feed KPI', { ns: 'features' }),
      description: t('KPI view of the feed', { ns: 'features' }),
    },
  ] as const;

export type FeatureId = ReturnType<typeof getFeatureDefinitions>[number]['id'];
