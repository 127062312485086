import * as React from 'react';
const PolicyReplenishmentAgentsLineIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyReplenishmentAgentsLineIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M67.36,51.86l.17.15h8.88c1.6.06,2.9,1.36,2.92,2.98.03,1.67-1.3,3.04-3.06,3.07h-.33s-8.58-.01-8.58-.01v2.48h19.32v-14h-19.32v5.33Z"
    />
    <path
      strokeWidth={0}
      d="M113.99,62.66L65.34,14.01c-.43-.43-.88-.64-1.34-.64h0c-.46,0-.91.21-1.34.64L14.01,62.66c-.86.86-.86,1.82,0,2.68l48.65,48.65c.86.86,1.82.85,2.68,0l48.65-48.65c.85-.85.85-1.82,0-2.68ZM52.16,34.01c.36-2.91,3.02-4.98,5.93-4.61,2.91.36,4.98,3.02,4.61,5.93-.36,2.91-3.02,4.98-5.93,4.61s-4.98-3.02-4.61-5.93ZM89.34,45.32v16.53c0,.73-.6,1.33-1.33,1.33h-21.98c-.73,0-1.33-.6-1.33-1.33v-4.39l-5.38-4.73,1.27,10.44,8.55,6.49c1.22.93,2.07,2.27,2.39,3.77l5.41,10.52c.39,1.97-.82,3.78-2.68,4.15-1.81.36-3.56-.77-4-2.54l-5.19-10.25-9.31-7.13-4.1,9.75c-.66,1.56-2.15,2.6-3.84,2.68l-9.86.6c-2.01.09-3.61-1.38-3.7-3.27-.08-1.84,1.3-3.4,3.11-3.57l8.43-.42,3.74-9.56-2.13-17.56c-.38-3.05,1.79-5.84,4.84-6.22,1.92-.24,3.73.53,4.92,1.95l7.52,6.86v-4.23s0,0,0-.01c0-.02,0-.03,0-.05,0-.09.02-.18.04-.27,0-.03.02-.06.03-.09.03-.08.06-.15.1-.22,0-.02.01-.04.02-.05l2.79-4.62c.24-.4.67-.64,1.14-.64h16.41c.47,0,.9.24,1.14.64l2.7,4.48c.18.23.29.52.29.83,0,.04,0,.09-.01.13Z"
    />
  </svg>
);
export default PolicyReplenishmentAgentsLineIcon;
