import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';

@InputType()
export class MarkItemSetImportedInput {
  @Field(() => ID)
  itemSetId: string;
}
