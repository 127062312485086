import * as React from 'react';
const WarehouseSmallIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseSmallIcon"
    {...props}
  >
    <rect width={46.71} height={2.98} x={40.65} y={74.99} strokeWidth={0} />
    <rect width={46.71} height={2.98} x={40.65} y={79.62} strokeWidth={0} />
    <rect width={46.71} height={2.98} x={40.65} y={70.35} strokeWidth={0} />
    <rect width={46.71} height={2.98} x={40.65} y={84.38} strokeWidth={0} />
    <rect width={46.71} height={1.8} x={40.65} y={98.29} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="40.65 89.01 40.65 91.99 87.36 91.99 87.36 89.01 74.09 89.01 40.65 89.01"
    />
    <polygon
      strokeWidth={0}
      points="4.84 50.51 4.84 100.09 38.09 100.09 38.09 68 89.91 68 89.91 100.09 123.16 100.09 123.16 50.51 64.5 35.09 4.84 50.51"
    />
    <rect width={46.71} height={2.98} x={40.65} y={93.65} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="64.5 27.91 2.84 44.34 2.84 49.36 64.5 32.93 125.16 49.36 125.16 44.34 64.5 27.91"
    />
  </svg>
);
export default WarehouseSmallIcon;
