import {
  LoadWorkforceDataDocument,
  LoadWorkforceDataQuery,
  LoadWorkforceDataQueryVariables,
  ResourceUsageSummaryFragment,
  ResourceUsageSummaryGroupBy,
} from '@warebee/frontend/data-access-api-graphql';
import { differenceInDays, eachDayOfInterval } from 'date-fns';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { WORKFORCE_FORECAST_VERSION } from '../../common/constants';
import {
  toDateFromLocaleStringDate,
  toLocaleDateTimeString,
} from '../../common/dateTimeHelper';
import { formatDateTimeShort } from '../../common/formatHelper';
import { recoilPersist } from '../../common/recoil/recoilPersist';
import { orderSetItemsDataState } from '../../orders/store/orderSet.state';
import { resourcePolicyAgentById } from './resourcePolicy.state';
import {
  simulationAnalyzeInterval,
  simulationCurrentId,
} from './simulation.state';
import {
  getAgentSimulationDefaults,
  getAggregatedEvents,
  getForecastShifts,
  reduceAgentEvents,
} from './workforce.helper';
import {
  AgentForecastWorkWindow,
  AgentWorkforceEventsAggregatedMap,
  AgentWorkforceEventsByPeriod,
  AgentWorkforceEventsTotal,
  AgentWorkforcePeriodType,
  AgentWorkforceSchedule,
  AgentWorkforceSimulationSettingsItem,
  AgentWorkforceWave,
  AgentWorkforceWaveBase,
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_TOTAL,
  WorkforceDailyWorkWindows,
  WorkforceDrillType,
  WorkforceForecastMode,
  WorkforceKpiType,
  WorkforceScheduleMap,
  sumEvents,
} from './workforce.types';
import { scheduleWaves } from './workforce.waves.helper';

type AnalyzeId = string;
const getKey = (postfix: string) => `warebee-simulation-workforce-${postfix}`;

const { persistAtom: persistWorkforceAgentForecastEnabled } = recoilPersist({
  key: getKey(`agent-forecast-enabled-v-${WORKFORCE_FORECAST_VERSION}`),
  storage: localStorage,
});

export const workforceAgentForecastEnabled = atomFamily<boolean, string>({
  key: getKey(`agent-forecast-enabled-v-${WORKFORCE_FORECAST_VERSION}`),
  default: () => false,
  effects: [persistWorkforceAgentForecastEnabled],
});

const { persistAtom: persistWorkforceAgentForecastMode } = recoilPersist({
  key: getKey(`agent-forecast-mode-v-${WORKFORCE_FORECAST_VERSION}`),
  storage: localStorage,
});

export const workforceAgentForecastMode = atomFamily<
  WorkforceForecastMode,
  string
>({
  key: getKey(`agent-forecast-mode-v-${WORKFORCE_FORECAST_VERSION}`),
  default: () => 'byDate',
  effects: [persistWorkforceAgentForecastMode],
});

export const workforceAgentEventAggregation = atomFamily<
  AgentWorkforcePeriodType,
  string
>({
  key: getKey('use-simulation'),
  default: () => 'daily',
});

const { persistAtom: persistWorkforceSettingsBySimulation } = recoilPersist({
  key: getKey(`all-sim-settings-v-${WORKFORCE_FORECAST_VERSION}`),
  storage: localStorage,
});

// For LOCAL STORAGE, store settings as Dictionary by simulation id
// export const workforceSettingsBySimulation = atom<
//   Record<string, WorkforceScheduleMap>
// >({
//   key: getKey('all-sim-settings'),
//   default: {},
//   effects: [persistWorkforceSettingsBySimulation],
// });

// Dictionary by ScheduleId within selected simulation
export const workforceAllAgentSettingsMap = atom<WorkforceScheduleMap>({
  key: getKey('all-agents-settings'),
  default: null,
  // get: ({ get }) => {
  //   const simulationId = get(simulationCurrentId);
  //   return get(workforceSettingsBySimulation)[simulationId] ?? {};
  // },
  // set: ({ get, set }, value) => {
  //   const simulationId = get(simulationCurrentId);
  //   const current = get(workforceSettingsBySimulation);
  //   set(workforceSettingsBySimulation, {
  //     ...current,
  //     [simulationId]: value,
  //   });
  // },
});

export const workforceScheduleSetting = selectorFamily<
  AgentWorkforceSimulationSettingsItem,
  string
>({
  key: getKey('agent-settings-overrides'),
  get:
    (scheduleId: string) =>
    ({ get }) => {
      return get(workforceAllAgentSettingsMap)?.[scheduleId];
    },

  set:
    (scheduleId: string) =>
    ({ get, set }, value: AgentWorkforceSimulationSettingsItem) => {
      set(workforceAllAgentSettingsMap, {
        ...get(workforceAllAgentSettingsMap),
        [scheduleId]: value,
      });
    },
});

export const workforceEffectiveAgentSchedules = selectorFamily<
  AgentWorkforceSimulationSettingsItem[],
  string
>({
  key: getKey('agent-settings-overrides'),
  get:
    (agentId: string) =>
    ({ get }) => {
      const isForecastSimulation = get(workforceAgentForecastEnabled(agentId));
      return _.values(get(workforceAllAgentSettingsMap)).filter(
        s => isForecastSimulation && s.agentId === agentId,
      );
    },
});

export const workforceSimulateIntervalAtom = atomFamily<[Date, Date], string>({
  key: getKey('simulate-interval-atom'),
  default: null,
});

export const workforceSimulateInterval = selector<[Date, Date]>({
  key: getKey('simulate-interval'),
  get: ({ get }) => {
    const simId = get(simulationCurrentId);
    const current = get(workforceSimulateIntervalAtom(simId));
    return current ?? get(simulationAnalyzeInterval);
  },
  set: ({ get, set }, value) => {
    const simId = get(simulationCurrentId);
    set(workforceSimulateIntervalAtom(simId), value);
  },
});

export const workforceSimulateDaysCount = selector<number>({
  key: getKey('simulate-days-count'),
  get: ({ get }) => {
    const analyzeInterval = get(simulationAnalyzeInterval);
    const selectedInterval = get(workforceSimulateInterval);
    const from = selectedInterval?.[0] ?? analyzeInterval[0];
    const to = selectedInterval?.[1] ?? analyzeInterval[1];

    const diff = differenceInDays(to, from);
    return diff >= 0 ? diff + 1 : 0;
  },
});

export const workforceEventsRawData = selectorFamily<
  ResourceUsageSummaryFragment[],
  AnalyzeId
>({
  key: getKey('workforce-data'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      try {
        const agentId = get(workforceSelectedAgentId);
        const [from, to] = get(workforceSimulateInterval);
        if (_.isNil(analyzeId)) return;
        const drill = get(workforceSelectedDrill(agentId));
        const response = await secureClient.query<
          LoadWorkforceDataQuery,
          LoadWorkforceDataQueryVariables
        >({
          query: LoadWorkforceDataDocument,
          variables: {
            analyzeId,
            agentsId: [agentId],
            drill: [ResourceUsageSummaryGroupBy.PICK_BY_DATE, drill],
            from: toLocaleDateTimeString(from),
            to: toLocaleDateTimeString(to),
          },
        });

        return response.data.analyzeResult?.resourceUsageSummary;
      } catch (ex) {
        console.log(ex);
        throw ex;
      }
    },
});

const workforceAgentAllEventsByPeriod = selectorFamily<
  AgentWorkforceEventsByPeriod[],
  AnalyzeId
>({
  key: getKey('agent-daily-event-groups'),
  get:
    (analyzeId: AnalyzeId) =>
    ({ get }) => {
      const agentId = get(workforceSelectedAgentId);
      const agentEvents = get(workforceEventsRawData(analyzeId));
      if (_.isNil(agentId) || _.isEmpty(agentEvents)) {
        return null;
      }

      const agent = get(resourcePolicyAgentById(agentId));
      const drill = get(workforceSelectedDrill(agentId));
      const drillDownData = reduceAgentEvents(agentEvents, drill);

      return _.map(drillDownData, (events, key) => {
        return {
          ...events,
          periodStart: toDateFromLocaleStringDate(key),
          periodType: 'daily',
          agentAmount: agent.utilisation.agentAmount,
          secondsOperation: agent.utilisation.secondsOperation,
          costPerSecond: agent.cost?.costPerSecond ?? 0,
        } as AgentWorkforceEventsByPeriod;
      });
    },
});

export const workforceAgentEventsTotal = selectorFamily<
  AgentWorkforceEventsTotal,
  AnalyzeId
>({
  key: getKey('agent-event-selected'),
  get:
    (analyzeId: AnalyzeId) =>
    ({ get }) => {
      const events = get(workforceAgentAggregatedEventsCurrent(analyzeId));
      return _.reduce(
        events,
        (acc, e) => ({
          ...acc,
          dimensions: {
            ...(acc.dimensions ?? {}),
            ...e.dimensions,
          },
          duration: sumEvents(acc.duration ?? {}, e.duration),
          cost: sumEvents(acc.cost ?? {}, e.cost),
          shiftDurations: acc.shiftDurations + e.aggregatedShift,
          effectiveShiftDurations:
            acc.effectiveShiftDurations + e.aggregatedShiftWithoutLabourEvents,
          effectiveShiftCount: acc.effectiveShiftCount + 1,
        }),
        {
          duration: {
            [DRILL_KEY_TOTAL]: 0,
            [DRILL_KEY_IDLE]: 0,
            [DRILL_KEY_OVERTIME]: 0,
          },
          cost: {
            [DRILL_KEY_TOTAL]: 0,
            [DRILL_KEY_IDLE]: 0,
            [DRILL_KEY_OVERTIME]: 0,
          },
          dimensions: {},
          shiftDurations: 0,
          effectiveShiftDurations: 0,
          effectiveShiftCount: 0,
        } as AgentWorkforceEventsTotal,
      );
    },
});

export const workforceAgentAggregatedEventsCurrent = selectorFamily<
  AgentWorkforceEventsAggregatedMap,
  AnalyzeId
>({
  key: getKey('agent-event-selected'),
  get:
    (analyzeId: AnalyzeId) =>
    ({ get }) => {
      const agentId = get(workforceSelectedAgentId);

      const events = get(workforceAgentAllEventsByPeriod(analyzeId));
      if (_.isNil(agentId) || _.isEmpty(events)) {
        return null;
      }
      const settingsAll = get(workforceAllAgentSettingsMap);

      const agent = get(resourcePolicyAgentById(agentId));
      const interval = get(workforceSimulateInterval);

      const isForecastSimulation = get(workforceAgentForecastEnabled(agentId));
      const aggregationType = get(workforceAgentEventAggregation(agentId));
      const dailyWorkWindows = get(workforceForecastDailyWorkWindows(agent.id));
      const defaultAgentSettings = getAgentSimulationDefaults({
        agent,
        analyzePeriod: interval,
        isDefault: true,
        title: `Default`,
        sortIndex: Number.MAX_SAFE_INTEGER,
      });

      const settings = _(settingsAll)
        .filter(s => isForecastSimulation && s.agentId === agentId)
        .value();
      return getAggregatedEvents({
        events,
        simulationSettings: settings,
        agent,
        defaultAgentSettings: defaultAgentSettings,
        periodType: aggregationType,
        dailyWorkWindows,
      });
    },
});

export const workforceSelectedAgentId = atom<string>({
  key: getKey('selected-agent-id'),
  default: null,
});

export const workforceSelectedKPI = atomFamily<WorkforceKpiType, string>({
  key: getKey('selected-kpi-by-agent'),
  default: 'avg',
});

export const workforceSelectedDrill = atomFamily<WorkforceDrillType, string>({
  key: getKey('selected-drill-by-agent'),
  default: ResourceUsageSummaryGroupBy.EVENT_TYPE,
});

export const workforceEventsByDate = selectorFamily<
  ResourceUsageSummaryFragment[],
  AnalyzeId
>({
  key: getKey('wave-data-by-agent'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      try {
        const agentId = get(workforceSelectedAgentId);
        const [from, to] = get(workforceSimulateInterval);
        if (_.isNil(agentId) || _.isNil(analyzeId)) return;
        const response = await secureClient.query<
          LoadWorkforceDataQuery,
          LoadWorkforceDataQueryVariables
        >({
          query: LoadWorkforceDataDocument,
          variables: {
            analyzeId,
            agentsId: [agentId],
            drill: [ResourceUsageSummaryGroupBy.PICK_BY_DATE],
            from: toLocaleDateTimeString(from),
            to: toLocaleDateTimeString(to),
          },
        });

        return response.data.analyzeResult?.resourceUsageSummary;
      } catch (ex) {
        console.log(ex);
        throw ex;
      }
    },
});

export const workforceWavesSource = selectorFamily<
  AgentWorkforceWaveBase[],
  AnalyzeId
>({
  key: getKey('waves-by-agent'),
  get:
    (analyzeId: AnalyzeId) =>
    ({ get }) => {
      const agentId = get(workforceSelectedAgentId);
      if (_.isNil(agentId)) {
        return null;
      }
      const data = get(workforceEventsByDate(analyzeId));
      return _(data)
        .map(d => {
          return {
            id: nanoid(),
            agentId,
            remainingDuration: d.totalDuration,
            scheduledIndex: 0,
            source: {
              to: toDateFromLocaleStringDate(d.key.pickByDate),
              duration: d.totalDuration,
              cost: d.totalCost,
            },
          } as AgentWorkforceWaveBase;
        })
        .sortBy(w => -w.source.to)
        .value();
    },
});

export const workforceForecastDailyWorkWindows = selectorFamily<
  WorkforceDailyWorkWindows[],
  string
>({
  key: getKey('daily-work-windows'),
  get:
    (agentId: string) =>
    ({ get }) => {
      const [from, to] = get(workforceSimulateInterval);
      const forecastAgentSettings = get(
        workforceEffectiveAgentSchedules(agentId),
      );
      const agent = get(resourcePolicyAgentById(agentId));
      const analyzePeriod = get(simulationAnalyzeInterval);

      const defaultAgentSettings = getAgentSimulationDefaults({
        agent,
        analyzePeriod,
        title: `Default`,
        sortIndex: Number.MAX_SAFE_INTEGER,
      });

      const settingsToApply = _.isEmpty(forecastAgentSettings)
        ? [defaultAgentSettings]
        : forecastAgentSettings;

      const forecastShifts = _(eachDayOfInterval({ start: from, end: to }))
        .map(d => getForecastShifts(d, settingsToApply))
        .compact()
        .value();
      return forecastShifts;
    },
});

export const workforceForecastWorkWindows = selectorFamily<
  AgentForecastWorkWindow[],
  string
>({
  key: getKey('work-window-by-agent'),
  get:
    (agentId: string) =>
    ({ get }) =>
      _.flatMap(
        get(workforceForecastDailyWorkWindows(agentId)),
        dw => dw.workWindows,
      ),
});

export const workforceAgentSchedule = selectorFamily<
  AgentWorkforceSchedule,
  AnalyzeId
>({
  key: getKey('schedule-by-agent'),
  get:
    (analyzeId: AnalyzeId) =>
    ({ get }) => {
      const agentId = get(workforceSelectedAgentId);
      if (_.isNil(agentId)) {
        return null;
      }
      const interval = get(workforceSimulateInterval);
      const waves = get(workforceWavesSource(analyzeId));
      const workWindows = get(workforceForecastWorkWindows(agentId));

      return scheduleWaves(interval, waves, workWindows);
    },
});

export const workforceHoveredWave = atom<AgentWorkforceWave>({
  key: getKey('hovered-wave'),
  default: null,
});

const workforceSelectedDeadlineAtom = atom<Date>({
  key: getKey('selected-deadline-atom'),
  default: null,
});

export const workforceSelectedDeadline = selector<Date>({
  key: getKey('selected-deadline-id'),
  get: ({ get }) => get(workforceSelectedDeadlineAtom),
  set: ({ get, set }, value: Date | null) => {
    set(workforceSelectedDeadlineAtom, value);
    set(workforceSelectedUnscheduledAtom, false);
    const currentOrderSetState = get(orderSetItemsDataState);

    set(orderSetItemsDataState, {
      ...currentOrderSetState,
      searchValues: {
        ...currentOrderSetState.searchValues,
        orderDate: _.isNil(value) ? null : formatDateTimeShort(value),
      },
    });
  },
});

const workforceSelectedUnscheduledAtom = atom<boolean>({
  key: getKey('selected-unscheduled-atom'),
  default: false,
});

export const workforceSelectedUnscheduled = selector<boolean>({
  key: getKey('selected-unscheduled'),
  get: ({ get }) => get(workforceSelectedUnscheduledAtom),
  set: ({ set }, value) => {
    set(workforceSelectedUnscheduledAtom, value);
    if (value) {
      set(workforceSelectedDeadlineAtom, null);
    }
  },
});

export const workforceHoveredWorkWindow = atom<AgentForecastWorkWindow>({
  key: getKey('hovered-work-window'),
  default: null,
});

export const workforceShowAdvancedDataTable = atom<boolean>({
  key: getKey('show-table'),
  default: true,
});
export const workforceShowAdvancedLabourEvents = atom<boolean>({
  key: getKey('show-labour-events'),
  default: false,
});
