import {
  AthenaAdapter,
  AthenaIntrospector,
  AthenaQueryCompiler,
} from '@warebee/shared/athena-utils';
import {
  ActivityFeedEventDataset,
  AnalyzedEventsDataset,
  AssignmentDataset,
  ItemsDataset,
  LayoutLocationDataset,
} from '@warebee/shared/import-converter';
import { CamelCasePlugin, DummyDriver, Kysely } from 'kysely';

export interface DatasetDatabase {
  ___activity_feed___: ActivityFeedEventDataset;
  ___activity_feed_iceberg___: ActivityFeedEventDataset;
  ___item_set_iceberg___: ItemsDataset;
  ___analyzed_job_event___: AnalyzedEventsDataset;
  ___layout_location___: LayoutLocationDataset;
  ___assignment___: AssignmentDataset;
}

/**
 * Base query builder for sandboxed Athena queries from client
 */
export const datasetQueryBuilder = new Kysely<DatasetDatabase>({
  dialect: {
    createAdapter: () => new AthenaAdapter(),
    createDriver: () => new DummyDriver(),
    createIntrospector: db => new AthenaIntrospector(db),
    createQueryCompiler: () => new AthenaQueryCompiler(),
  },
  plugins: [new CamelCasePlugin()],
});
