import * as React from 'react';
const WarehouseSmallIssueIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseSmallIssueIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64.5 25.03 2.84 41.46 2.84 46.48 64.5 30.05 125.16 46.48 125.16 41.46 64.5 25.03"
    />
    <path
      strokeWidth={0}
      d="M5.84,46.93v49.58h70.64v-2.97h37.48v2.97h8.21v-49.58l-57.66-15.43L5.84,46.93ZM76.48,68.67h37.48v2.65h-37.48v-2.65ZM76.48,72.8h37.48v2.65h-37.48v-2.65ZM76.48,76.93h37.48v2.65h-37.48v-2.65ZM76.48,81.15h37.48v2.65h-37.48v-2.65ZM76.48,85.28h37.48v2.65h-37.48v-2.65ZM76.48,89.41h37.48v2.65h-37.48v-2.65Z"
    />
    <path
      strokeWidth={0}
      d="M38.66,51.59l-18.8,32.55h37.59s-18.8-32.55-18.8-32.55ZM38.66,61.2c1.34,0,2.4,1.14,2.3,2.47l-.96,9.96c-.06.71-.65,1.24-1.34,1.24s-1.28-.53-1.34-1.24l-.96-9.96c-.1-1.33.96-2.47,2.3-2.47ZM38.66,81.08c-1.37,0-2.49-1.12-2.49-2.49s1.12-2.49,2.49-2.49,2.49,1.12,2.49,2.49-1.12,2.49-2.49,2.49Z"
    />
  </svg>
);
export default WarehouseSmallIssueIcon;
