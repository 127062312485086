import * as React from 'react';
const TagPencilIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TagPencilIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M102.83,8.89l-1.38,1.38h0s16.28,16.28,16.28,16.28h0s1.38-1.38,1.38-1.38c3.62-3.62,3.62-9.5,0-13.12l-3.16-3.16c-3.62-3.63-9.5-3.63-13.13,0Z"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M121.67,84.28l-23.18-24.05c-1.23-1.27-2.96-2.2-4.75-2.6l-20.39,20.39-23.33,4.63c-2.77.55-5.21-1.89-4.66-4.66l4.09-20.58H12.84c-5.54,0-10.05,4.52-10.05,10.07v47.77c0,5.56,4.51,10.07,10.07,10.07h78.96c2.41,0,5-1.09,6.67-2.83l23.18-24.05c3.79-3.92,3.8-10.23,0-14.16ZM18.83,90.73h27.26c2.21,0,4,1.79,4,4s-1.79,4-3.99,4h-27.26c-2.21,0-4-1.79-4-4s1.79-4,4-4ZM65.09,113.46H18.83c-2.21,0-4-1.79-4-4s1.79-4,4-4h46.26c2.21,0,4,1.79,4,4s-1.79,4-3.99,4ZM101.24,98.28c-3.82,0-6.92-3.09-6.92-6.92s3.09-6.92,6.92-6.92,6.92,3.1,6.92,6.92-3.09,6.92-6.92,6.92Z"
    />
    <path
      strokeWidth={0}
      d="M54.36,58.29l-2.66,13.38c-.55,2.75,1.87,5.17,4.62,4.62l13.38-2.66c.76-.15,1.46-.53,2.01-1.08l39.17-39.17c1.54-1.54,1.54-4.02,0-5.56l-10.72-10.73c-1.54-1.54-4.02-1.54-5.56,0l-39.17,39.17c-.55.55-.92,1.25-1.08,2.01Z"
    />
  </svg>
);
export default TagPencilIcon;
