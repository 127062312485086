import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import { viewerSelectedBay } from '../../../layout/viewer/store/viewer.state';
import {
  getBayWeightColor,
  hasWeightCompliantActualNumbers,
  hasWeightCompliantMaxNumbers,
} from '../../store/weightCompliance.helper';
import {
  weightComplianceBay,
  weightComplianceBayLocation,
} from '../../store/weightCompliance.state';

export type WeightComplianceBayPropertiesProps = {};

const WeightComplianceBayProperties: React.FC<
  WeightComplianceBayPropertiesProps
> = props => {
  const { t } = useTranslation('simulation');
  const selectedBay = useRecoilValue(viewerSelectedBay);
  const bayMap = useRecoilValue(weightComplianceBay);
  const locationMap = useRecoilValue(weightComplianceBayLocation);
  const formatter = useFormatter();

  if (_.isNil(selectedBay)) {
    return null;
  }

  const bayWeight = bayMap?.[selectedBay.id];
  const sectionTitle = t`Weight Compliance`;

  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty Bay`} message_helper={`(No Items)`} />
  );

  if (_.isNil(bayWeight)) {
    return <EmptyPlaceholder />;
  }

  const byLevelMap = _.values(locationMap).reduce(
    (acc, loc) => ({
      ...acc,
      [loc.location.locationLevel]: {
        level: loc.location.locationLevel,
        weight:
          (acc?.[loc.location.locationLevel]?.weight ?? 0) + (loc.weight ?? 0),
      },
    }),
    {} as Record<string, { level: Number; weight: number }>,
  );

  function drawLevelValues() {
    return (
      _(byLevelMap)
        // .filter(levelSummary => levelSummary.weight > 0)
        .sortBy(item => item.level)
        .map(levelSummary => {
          const weight = formatter.formatWeight(levelSummary.weight);

          return (
            <React.Fragment key={`level-${levelSummary.level}`}>
              <StatListItem
                title={levelSummary.level.toFixed(0).padStart(2, '0')}
                value={weight.value === '0' ? '—' : weight.value}
                unitOfMeasure={weight.value === '0' ? '' : weight.unit}
                valueClass={'rounded text-xxs'}
              ></StatListItem>
            </React.Fragment>
          );
        })
        .value()
    );
  }

  const weight = formatter.formatWeight(bayWeight.weight);
  const maxWeight = formatter.formatWeight(bayWeight.maxWeight);

  const [background, color] = getBayWeightColor(bayWeight);

  return (
    <PanelContainer
      id="panel-compliance-weight-bay"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      <StatListItem
        title={t`Status`}
        value={`${bayWeight.status}`}
        titleClass={'text-xs'}
        valueClass={'rounded text-xxs'}
        valueStyle={{
          background,
          color,
        }}
      ></StatListItem>

      {!_.isNil(maxWeight) && hasWeightCompliantMaxNumbers(bayWeight.status) && (
        <StatListItem
          title={t`Max Capacity limit`}
          value={maxWeight.value === '0' ? '—' : maxWeight.value}
          unitOfMeasure={maxWeight.value === '0' ? '' : maxWeight.unit}
          titleClass={'text-xs'}
          valueClass={'rounded text-xxs'}
          valueStyle={{
            background,
            color,
          }}
        />
      )}

      {hasWeightCompliantActualNumbers(bayWeight.status) && (
        <StatListItem
          title={t`Item Weight`}
          value={weight.value === '0' ? '—' : weight.value}
          unitOfMeasure={weight.value === '0' ? '' : weight.unit}
          titleClass={'text-xs'}
          valueClass={'rounded text-xxs'}
          valueStyle={{
            background,
            color,
          }}
        ></StatListItem>
      )}

      {!_.isEmpty(byLevelMap) && (
        <div className={`mt-4`}>
          <StatListHeader title={t`Level`} value={t`Weight`} />
          {drawLevelValues()}
        </div>
      )}
    </PanelContainer>
  );
};

export default WeightComplianceBayProperties;
