import { Time } from '@internationalized/date';
import { useTimeField } from '@react-aria/datepicker';
import { useLocale } from '@react-aria/i18n';
import { useTimeFieldState } from '@react-stately/datepicker';
import { TimeValue } from '@react-types/datepicker';
import classNames from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { TimeFieldStateOptions } from 'react-stately';
import { toDateFromLocaleStringTime } from '../../../common/dateTimeHelper';
import DateSegmentField from './DateSegment';

const defaultDebounceInterval = 800;
export type TimeFieldProps = Partial<
  Omit<TimeFieldStateOptions, 'value' | 'onChange' | 'locale'>
> & {
  value: string;
  onChange: (v: string) => void;
  debounceInterval?: number;
};

const InputTime: React.FC<TimeFieldProps> = props => {
  const [value, setValue] = useState<TimeValue>();
  const [isValid, setValid] = useState(true);
  const id = useId();
  const { locale } = useLocale();
  const ref = useRef();

  useEffect(() => {
    const time = toDateFromLocaleStringTime(props.value);
    setValue(new Time(time.getHours(), time.getMinutes()));
  }, [props.value]);

  const delay = props.debounceInterval ?? defaultDebounceInterval;
  const debouncedChange = useCallback(
    _.debounce(async (time: TimeValue) => {
      try {
        await props.onChange(time.toString());
      } catch (ex) {
        setValid(false);
      }
    }, delay),
    [id, props.onChange],
  );
  const isValueApplied = value && props.value === value.toString();
  const onChange = (time: TimeValue) => {
    setValue(time);
    debouncedChange(time);
  };
  const stateProps: TimeFieldStateOptions = {
    ...props,
    value,

    onChange,
    locale,
  };
  const state = useTimeFieldState(stateProps);

  const { labelProps, fieldProps } = useTimeField(stateProps, state, ref);

  return (
    <div
      data-component="InputTime"
      className={classNames('flex flex-col items-start flex-1 w-full')}
    >
      <span {...labelProps} className={classNames('text-sm')}>
        {props.label}
      </span>
      <div
        {...fieldProps}
        ref={ref}
        className={classNames(
          'flex flex-1 flex-row w-full min-w-10 h-10 p-1 border-transparent transition-colors rounded-md',
          {
            'bg-app-panel-dark': isValueApplied,
            'bg-app-panel-dark/60': !isValueApplied,
          },
        )}
      >
        {state.segments.map((segment, i) => (
          <DateSegmentField key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  );
};

export default InputTime;
