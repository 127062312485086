import * as React from 'react';
const DataGrid2Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataGrid2Icon"
    {...props}
  >
    <rect
      width={33.07}
      height={10.39}
      x={63.2}
      y={72.07}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={33.07}
      height={10.39}
      x={63.2}
      y={57.48}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect width={33.07} height={20.69} x={63.2} y={18.01} strokeWidth={0} />
    <rect
      width={33.07}
      height={10.39}
      x={63.2}
      y={42.89}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={33.07}
      height={10.39}
      x={63.2}
      y={101.25}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={33.07}
      height={10.39}
      x={63.2}
      y={86.66}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={22.07}
      height={10.39}
      x={102.25}
      y={72.07}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={22.07}
      height={10.39}
      x={102.25}
      y={57.48}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect width={22.07} height={20.69} x={102.25} y={18.01} strokeWidth={0} />
    <rect
      width={22.07}
      height={10.39}
      x={102.25}
      y={42.89}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={22.07}
      height={10.39}
      x={102.25}
      y={101.25}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={22.07}
      height={10.39}
      x={102.25}
      y={86.66}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={37.07}
      height={10.39}
      x={20.73}
      y={72.07}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={37.07}
      height={10.39}
      x={20.73}
      y={57.48}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect width={37.07} height={20.69} x={20.73} y={18.01} strokeWidth={0} />
    <rect
      width={37.07}
      height={10.39}
      x={20.73}
      y={42.89}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={37.07}
      height={10.39}
      x={20.73}
      y={101.25}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={37.07}
      height={10.39}
      x={20.73}
      y={86.66}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect width={12.07} height={10.39} x={4.06} y={86.66} strokeWidth={0} />
    <rect width={12.07} height={10.39} x={4.06} y={72.07} strokeWidth={0} />
    <rect width={12.07} height={10.39} x={4.06} y={101.25} strokeWidth={0} />
    <rect width={12.07} height={10.39} x={4.06} y={57.48} strokeWidth={0} />
    <rect width={12.07} height={20.69} x={4.06} y={18.01} strokeWidth={0} />
    <rect width={12.07} height={10.39} x={4.06} y={42.89} strokeWidth={0} />
  </svg>
);
export default DataGrid2Icon;
