import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
    FindItemsByFilterDocument,
    FindItemsByFilterQuery,
    FindItemsByFilterQueryVariables,
    ItemsFilter
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
    getAffectedPalletOrderRuleIds,
    getPalletOrderPolicyRuleFragment,
    PALLET_ORDER_MIDDLE_RULE_ID
} from '../store/itemSet.default';
import {
    itemSetPalletSortPolicyData, itemSetPalletSortPolicyDataState, itemSetPalletSortPolicyDataStatus
} from '../store/itemSet.state';
import { PalletOrderPolicy, PalletOrderRuleId } from '../store/itemSet.types';

const ItemsToLoadDefault = 100;

export type LoadItemsByRuleParams = {
  itemSetId: string;
  ruleId: PalletOrderRuleId;

  policy: PalletOrderPolicy;
  limit?: number;
  skip?: number;
  isAppend?: boolean;

  itemFilter?: ItemsFilter;
};

function useLoadItemsByRule() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Items by Rule`;
  const initLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadItemsByRuleParams) => {
        set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadItemsByRuleParams) => {
        const { policy } = params;
        const current = await snapshot.getPromise(itemSetPalletSortPolicyData);

        const referencedRules = getAffectedPalletOrderRuleIds(params.ruleId);
        const include = _(referencedRules.include)
          .map(id => policy.rules.find(r => r.id === id))
          .map(getPalletOrderPolicyRuleFragment)
          .filter(r => !!r)
          .map(r => r.itemsMatch)
          .first();

        const exclude = _(referencedRules.exclude)
          .map(id => policy.rules.find(r => r.id === id))
          .map(getPalletOrderPolicyRuleFragment)
          .filter(r => !!r)
          .map(r => r.itemsMatch)
          .value();

        if (_.isNil(include) && params.ruleId !== PALLET_ORDER_MIDDLE_RULE_ID) {
          set(itemSetPalletSortPolicyDataState, { totalCount: 0 });
          set(itemSetPalletSortPolicyData, []);
          set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Ok);
          return;
        }

        let response: ApolloQueryResult<FindItemsByFilterQuery>;

        try {
          response = await client.query<
            FindItemsByFilterQuery,
            FindItemsByFilterQueryVariables
          >({
            query: FindItemsByFilterDocument,
            variables: {
              page: {
                limit: params.limit || ItemsToLoadDefault,
                skip: params.skip,
              },
              filter: params.itemFilter,
              input: {
                itemSetId: params.itemSetId,
                includeMatching: include,
                excludeMatching: exclude,
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response?.data) {
          const foundItems = response.data?.findItemsByFilter;

          set(itemSetPalletSortPolicyDataState, { totalCount: foundItems.totalCount });
          set(
            itemSetPalletSortPolicyData,
            params.isAppend
              ? [...current, ...foundItems.content]
              : foundItems.content,
          );
          set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Ok);
        }
      },
  );

  return async (params: LoadItemsByRuleParams) => {
    await initLoad(params);
    await callLoad(params);
  };
}
export default useLoadItemsByRule;
