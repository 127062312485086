import * as React from 'react';
const DesignerAislesWidthIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesWidthIcon"
    {...props}
  >
    <rect width={3.84} height={31.12} x={90.28} y={93.61} strokeWidth={0} />
    <rect width={3.84} height={31.12} x={33.88} y={93.61} strokeWidth={0} />
    <rect
      width={47.76}
      height={84.38}
      x={40.12}
      y={6.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect width={15.2} height={26.82} x={90.28} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={90.28} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={90.28} y={6.29} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={107.88} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={107.88} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={107.88} y={6.29} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={22.52} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={22.52} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={22.52} y={6.29} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={4.93} y={63.85} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={4.93} y={35.07} strokeWidth={0} />
    <rect width={15.2} height={26.82} x={4.93} y={6.29} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="79.89 98.52 90.51 109.17 79.87 119.82 73.58 119.82 81.98 111.4 66 111.4 66 106.94 81.99 106.94 73.59 98.52 79.89 98.52"
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="48.34 119.81 37.72 109.17 48.36 98.52 54.65 98.52 46.24 106.94 62.23 106.94 62.22 111.4 46.24 111.4 54.63 119.82 48.34 119.81"
      opacity={0.4}
    />
  </svg>
);
export default DesignerAislesWidthIcon;
