import * as React from 'react';
const DistributeHorizontalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DistributeHorizontalIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M39.61,103.68c-.08.08-.14.16-.2.25-.03.04-.05.09-.07.13-.03.05-.06.1-.08.15-.02.05-.04.11-.05.17-.01.05-.03.09-.04.14-.04.21-.04.43,0,.64,0,.05.03.09.04.14.02.06.03.11.05.17.02.05.05.1.08.15.02.04.04.09.07.13.06.09.13.17.2.25l13.08,13.08c.64.64,1.67.64,2.31,0,.32-.32.48-.74.48-1.16,0-.42-.16-.84-.48-1.16l-10.29-10.29h24.67c.9,0,1.64-.73,1.64-1.63s-.73-1.64-1.64-1.64h-24.67s10.29-10.29,10.29-10.29c.32-.32.48-.74.48-1.16,0-.42-.16-.84-.48-1.16-.64-.64-1.67-.64-2.31,0l-13.08,13.08Z"
    />
    <path
      strokeWidth={0}
      d="M91.06,105.99c.08-.08.14-.16.2-.25.03-.04.05-.09.07-.13.03-.05.06-.1.08-.15.02-.05.04-.11.05-.17.01-.05.03-.09.04-.14.04-.21.04-.43,0-.64,0-.05-.03-.09-.04-.14-.02-.06-.03-.11-.05-.17-.02-.05-.05-.1-.08-.15-.02-.04-.04-.09-.07-.13-.06-.09-.13-.17-.2-.25l-13.08-13.08c-.64-.64-1.67-.64-2.31,0-.32.32-.48.74-.48,1.16s.16.84.48,1.16l10.29,10.29h-24.67c-.9,0-1.64.73-1.64,1.64s.73,1.64,1.64,1.64h24.67s-10.29,10.29-10.29,10.29c-.32.32-.48.74-.48,1.16s.16.84.48,1.16c.64.64,1.67.64,2.31,0l13.08-13.08Z"
    />
    <rect width={4.44} height={36} x={95.87} y={87.17} strokeWidth={0} />
    <rect width={4.44} height={36} x={30.87} y={87.17} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="95.48 12.54 102.2 5.81 95.48 5.81 95.48 12.54"
    />
    <polygon
      strokeWidth={0}
      points="95.48 46.48 123.48 18.48 123.48 5.81 122.56 5.81 95.48 32.9 95.48 46.48"
    />
    <polygon
      strokeWidth={0}
      points="95.48 29.51 119.17 5.81 105.59 5.81 95.48 15.93 95.48 29.51"
    />
    <polygon
      strokeWidth={0}
      points="95.48 66.84 95.48 80.42 123.48 52.42 123.48 38.84 95.48 66.84"
    />
    <polygon
      strokeWidth={0}
      points="95.48 49.87 95.48 63.45 123.48 35.45 123.48 21.87 95.48 49.87"
    />
    <polygon
      strokeWidth={0}
      points="98.47 80.81 112.05 80.81 123.48 69.39 123.48 55.81 98.47 80.81"
    />
    <polygon
      strokeWidth={0}
      points="123.48 80.81 123.48 72.78 115.44 80.81 123.48 80.81"
    />
    <polygon
      strokeWidth={0}
      points="6.48 46.48 17.14 35.81 6.47 35.81 6.48 46.48"
    />
    <polygon
      strokeWidth={0}
      points="6.48 66.84 6.48 80.42 34.48 52.42 34.48 38.84 6.48 66.84"
    />
    <polygon
      strokeWidth={0}
      points="6.48 49.87 6.48 63.45 34.11 35.81 20.53 35.81 6.48 49.87"
    />
    <polygon
      strokeWidth={0}
      points="9.47 80.81 23.05 80.81 34.48 69.39 34.48 55.81 9.47 80.81"
    />
    <polygon
      strokeWidth={0}
      points="34.48 80.81 34.48 72.78 26.44 80.81 34.48 80.81"
    />
  </svg>
);
export default DistributeHorizontalIcon;
