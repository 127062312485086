import {
  SortDirection,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { ActivityFeedEventDataset } from '@warebee/shared/import-converter';
import { SelectQueryBuilder } from 'kysely';
import _ from 'lodash';
import { toDateFromLocaleStringDate } from '../../../common/dateTimeHelper';
import {
  DatasetDatabase,
  datasetQueryBuilder,
} from './queryBuilder/datasetQueryBuilder';
import { FeedQueryBuilderPagedParams } from './queryBuilder/feedQueryBuilder';

// export type FeedLatestEvents = InferResult<typeof latestEventsSelect>;
// export type FeedLatestEvent = FeedLatestEvents[number];

const dataQuery = datasetQueryBuilder
  .selectFrom('___activity_feed_iceberg___')
  .select([
    'eventId',
    'eventProcessType',
    'eventType',
    'consignee',
    'sku',
    'uom',
    'quantity',
    'scanCode',
    'warehouseAreaSource',
    'locationIdSource',
    'warehouseArea',
    'locationId',
    'agentType',
    'agentId',
    'agentUser',
    'agentEnergy',
    'agentSignalStrength',
    'eventStartTime',
    'eventEndTime',
    'jobId',
    'jobLine',
    'jobGroupId',
    // 'partition',
    'datasetObjectId',
    'importJobId',
  ]);

const totalCountQuery = datasetQueryBuilder
  .selectFrom('___activity_feed_iceberg___')
  .select(({ fn }) => [fn.countAll<number>().as('totalCount')]);

type ActivityFeedQueryBase<T> = SelectQueryBuilder<
  DatasetDatabase,
  '___activity_feed_iceberg___',
  T
>;

function applyFilters<T>(
  baseQuery: ActivityFeedQueryBase<T>,
  params: FeedQueryBuilderPagedParams,
  applyOrder: boolean,
  applyPage: boolean,
): ActivityFeedQueryBase<T> {
  let query = baseQuery.where('datasetObjectId', '=', params.datasetId);
  if (applyOrder) {
    query = query.orderBy(
      params.sortBy?.field ?? 'eventEndTime',
      params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
    );
  }
  if (applyPage) {
    query = query.offset(params.page.skip ?? 0).limit(params.page.limit ?? 100);
  }
  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }
  return query;
}

export const getFeedDataRowsQuery = (params: FeedQueryBuilderPagedParams) => {
  return applyFilters(dataQuery, params, true, true);
};

export const getFeedTotalRowsQuery = (params: FeedQueryBuilderPagedParams) => {
  return applyFilters(totalCountQuery, params, false, false);
};

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return fName === 'eventStartTime' || fName === 'eventEndTime';
};

export function postProcessFeedDataRows(
  events: Record<string, any>[],
): ActivityFeedEventDataset[] {
  return _.map(events, e => {
    return _.reduce(
      e,
      (acc, value, key) => {
        return {
          ...acc,
          [_.camelCase(key)]: isDateField(key)
            ? toDateFromLocaleStringDate(value as string)
            : value,
        };
      },
      {} as ActivityFeedEventDataset,
    );
  });
}

export function postProcessTotalCount(events: Record<string, any>[]): {
  totalCount: number;
} {
  return {
    totalCount: _.head(events)?.['total_count'] ?? 0,
  };
}
