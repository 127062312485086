import * as React from 'react';
const WarehouseParams1Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseParams1Icon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="63.04 82.89 17.71 97.87 17.71 124 110.29 124 110.29 97.87 63.04 82.89"
    />
    <path
      strokeWidth={0}
      d="M47.78,19.39h32.45c1.35,0,2.46-1.1,2.46-2.45v-4.27c0-1.37-1.11-2.45-2.46-2.45h-10.02c0-3.44-2.77-6.21-6.21-6.21s-6.21,2.77-6.21,6.21h-10.02c-1.35,0-2.45,1.08-2.45,2.45v4.27c0,1.35,1.1,2.45,2.45,2.45ZM64,7.76c1.35,0,2.46,1.08,2.46,2.45s-1.1,2.45-2.46,2.45-2.45-1.1-2.45-2.45,1.1-2.45,2.45-2.45Z"
    />
    <path
      strokeWidth={0}
      d="M75,44.33c2.71,0,4.91-2.2,4.91-4.91s-2.2-4.91-4.91-4.91-4.91,2.2-4.91,4.91,2.2,4.91,4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M98.42,90.44V19.71c0-2.71-2.19-4.9-4.92-4.9h-7.71v2.12c0,2.66-2.17,4.83-4.81,4.83h-32.45c-2.66,0-4.83-2.17-4.83-4.83v-2.12h-7.71c-2.71,0-4.92,2.19-4.92,4.9v70.78l32.97-10.82,34.37,10.76ZM54.58,32.05h20.57c4.07,0,7.37,3.3,7.37,7.37s-3.3,7.37-7.37,7.37h-20.57c-4.07,0-7.37-3.3-7.37-7.37s3.3-7.37,7.37-7.37ZM47.21,61.1c0-4.07,3.3-7.37,7.37-7.37h20.57c4.07,0,7.37,3.3,7.37,7.37s-3.3,7.37-7.37,7.37h-20.57c-4.07,0-7.37-3.3-7.37-7.37Z"
    />
    <path
      strokeWidth={0}
      d="M54.56,56.19c-2.71,0-4.91,2.2-4.91,4.91s2.2,4.91,4.91,4.91,4.91-2.2,4.91-4.91-2.2-4.91-4.91-4.91Z"
    />
  </svg>
);
export default WarehouseParams1Icon;
