import _ from 'lodash';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../components/actions/DropdownSelector';
import useActualityDatasetTableTypes from './hooks/useActualityDatasetTableTypes';
import { actualityDataSetTableTypeSelected } from './store/actuality.state';
import { ActualityDataSetTableType } from './store/actuality.types';

const ActualityDatasetSelector: React.FC = () => {
  const [selected, setSelected] = useRecoilState(
    actualityDataSetTableTypeSelected,
  );
  const tableTypesOptions = useActualityDatasetTableTypes();
  return (
    <DropdownSelector
      value={selected}
      values={_.keys(tableTypesOptions) as ActualityDataSetTableType[]}
      renderValue={v => tableTypesOptions[v].shortTitle}
      onChange={v => setSelected(v)}
      buttonTransparent
      panelMode
      className="rounded-full px-2"
      classNameValue="text-xs"
    />
  );
};

export default ActualityDatasetSelector;
