import * as React from 'react';
const CircleInfoIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleInfoIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,14c-27.57,0-50,22.43-50,50s22.43,50,50,50,50-22.43,50-50S91.57,14,64,14ZM64,26.39c5.08,0,9.07,4.33,8.7,9.35l-3.62,37.65c-.23,2.68-2.44,4.7-5.08,4.7-2.63,0-4.84-2.02-5.08-4.7l-3.62-37.65c-.38-5.03,3.62-9.35,8.7-9.35ZM64,101.61c-5.17,0-9.4-4.23-9.4-9.4s4.23-9.4,9.4-9.4,9.4,4.23,9.4,9.4-4.23,9.4-9.4,9.4Z"
    />
  </svg>
);
export default CircleInfoIcon;
