import {
  AisleFeatureMetaFragment,
  MeasurementSystem,
} from '@warebee/frontend/data-access-api-graphql';
import {
  LayoutPlaneNavigationEngine,
  Route,
} from '@warebee/shared/data-access-layout-manager';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { FeatureBox } from '../../features/konva.types';
import { getAislesSpec } from './aisleSpec.config';
import { AisleSpec } from './viewer.types';

export const createRoute = _.memoize(
  (selectedLocationsList, area, areaConnectivityGraph): Route => {
    const ng = new LayoutPlaneNavigationEngine(
      area,
      areaConnectivityGraph,
      selectedLocationsList,
    );
    //console.log(ng);
    try {
      const route = ng.findRoute(selectedLocationsList.map(l => l.locationId));
      //console.log(route);
      return route;
    } catch (error) {
      console.log('could not find route: %o', error);
      return null;
    }
  },
);

export function getBoxFromPoints(points: number[][]) {
  return points.reduce(
    (acc, v) => ({
      xmin: Math.min(v[0], acc.xmin),
      xmax: Math.max(v[0], acc.xmax),
      ymin: Math.min(v[1], acc.ymin),
      ymax: Math.max(v[1], acc.ymax),
    }),
    {
      xmin: Number.MAX_SAFE_INTEGER,
      xmax: Number.MIN_SAFE_INTEGER,
      ymin: Number.MAX_SAFE_INTEGER,
      ymax: Number.MIN_SAFE_INTEGER,
    },
  );
}

export function getBox(shape: GeoJSON.Polygon) {
  const points = getPoints(shape);
  return points.reduce(
    (acc, v) => ({
      xmin: Math.min(v[0], acc.xmin),
      xmax: Math.max(v[0], acc.xmax),
      ymin: Math.min(v[1], acc.ymin),
      ymax: Math.max(v[1], acc.ymax),
    }),
    {
      xmin: Number.MAX_SAFE_INTEGER,
      xmax: Number.MIN_SAFE_INTEGER,
      ymin: Number.MAX_SAFE_INTEGER,
      ymax: Number.MIN_SAFE_INTEGER,
    },
  );
}
export function getBoxWH(box: FeatureBox) {
  return [box.xmax - box.xmin, box.ymax - box.ymin];
}
export function getBoxCenter(box: FeatureBox) {
  const [w, h] = getBoxWH(box);
  return [box.xmin + w / 2, box.ymin + h / 2];
}

export function getPoints(shape: GeoJSON.Geometry): number[][] {
  switch (shape.type) {
    case 'Polygon':
      return _.head(shape.coordinates);
  }
  return [];
}

export type ViewerCachingOption = 'auto' | 'on' | 'off';
export type ViewerCachingSizeOption = 'speed' | 'balance' | 'quality';

export const ViewerCacheSize: Record<ViewerCachingSizeOption, number> = {
  speed: 5000 * 5000,
  balance: 10000 * 10000,
  quality: 16000 * 16000,
};

export type CachingSettings = {
  type: ViewerCachingOption;
  size?: ViewerCachingSizeOption;
  ratio?: number;
};

export const cachingSettingsDefault: CachingSettings = {
  type: 'auto',
  size: 'balance',
};

export function getLayoutCachingOptions(
  t: TFunction<'app'>,
): { id: ViewerCachingOption; title: string }[] {
  return [
    {
      id: 'auto',
      title: t('Auto', { ns: 'app' }),
    },
    {
      id: 'on',
      title: t('On', { ns: 'app' }),
    },
    {
      id: 'off',
      title: t('Off', { ns: 'app' }),
    },
  ];
}

export function getLayoutCachingSizeOptions(
  t: TFunction<'app'>,
): { id: ViewerCachingSizeOption; title: string }[] {
  return [
    {
      id: 'speed',
      title: t('Best performance', { ns: 'app' }),
    },
    {
      id: 'quality',
      title: t('Best quality', { ns: 'app' }),
    },
    {
      id: 'balance',
      title: t('Balanced', { ns: 'app' }),
    },
  ];
}

export function getAisleSpec(
  aisle: AisleFeatureMetaFragment,
  ms: MeasurementSystem,
  t?: TFunction<'app'>,
): AisleSpec {
  const points = aisle.shape.coordinates[0];
  let minEdge = Number.MAX_SAFE_INTEGER;
  for (let i = 1; i < points.length; i++) {
    const [x1, y1] = points[i];
    const [x2, y2] = points[i - 1];
    minEdge = Math.min(minEdge, Math.hypot(x2 - x1, y2 - y1));
  }

  const spec = _.find(getAislesSpec(t), spec => {
    const [from, to] = spec.size[ms].width;
    return minEdge >= (from ?? 0) && minEdge < (to ?? Number.MAX_SAFE_INTEGER);
  });
  return spec;
}
