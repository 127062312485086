import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ContainerMainProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ContainerMain = ({ children, className }: ContainerMainProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <main
      id={id}
      data-component="ContainerMain"
      className={classNames(
        'flex flex-1 w-full h-full overflow-hidden',
        'isolate',
        className,
      )}
    >
      {children}
    </main>
  );
};
