import Flatten from '@flatten-js/core';
import {
  AislePortal,
  BayPortal,
  LayoutImportConverterLocationFragment,
} from '@warebee/frontend/data-access-api-graphql';
import {
  LayoutTransformation,
  VisualAisle,
  VisualBay,
  VisualLocation,
} from '@warebee/shared/data-access-layout-manager';
import _ from 'lodash';

export class ConvertedAisle extends VisualAisle {
  stickyLeft?: boolean;
  stickyRight?: boolean;
  leftBays?: string[];
  rightBays?: string[];
  mergeId?: string;
  unmergeId?: string;
  isSpacer?: boolean;
  isAutoSpacer?: boolean;

  transform(t: LayoutTransformation): ConvertedAisle {
    const { stickyLeft, stickyRight, leftBays, rightBays, mergeId, unmergeId } =
      this;

    // TODO use composition to avoid this
    const r = super.transform(t);
    const ca = new ConvertedAisle(r.id, r.shape, r.title, r.nonNavigable);

    return Object.assign(ca, {
      stickyLeft,
      stickyRight,
      leftBays,
      rightBays,
      mergeId,
      unmergeId,
    });
  }
}

export class ConvertedBay extends VisualBay {
  public locations?: ConvertedLocation[];
  aisleId: string | null;

  getShelvingType() {
    return _(this.locations)
      .map(l => l.locationLevel)
      .uniq()
      .sort()
      .join('-');
  }

  transform(t: LayoutTransformation): ConvertedBay {
    const { locations, aisleId } = this;

    // TODO use compoisition to avoid this
    const r = super.transform(t);
    const cb = new ConvertedBay(
      r.id,
      r.bayType,
      r.position,
      r.frontEdge,
      r.width,
      r.depth,
      r.locationOrder,
      r.hasPass,
      r.title,
      r.height,
      r.levels,
    );
    return Object.assign(cb, {
      locations,
      aisleId,
    });
  }

  // save(): Bay {
  //   const bayHeight = _.max(
  //     Object.values(this.levelsHeight || {}).map(l => l.self + l.fromFloor),
  //   );
  //   return Object.assign(super.save(), {
  //     height: bayHeight,
  //     levels: Object.entries(this.levelsHeight || {}).map(([level, props]) => ({
  //       level: parseInt(level),
  //       levelHeight: props.self,
  //       heightFromFloor: props.fromFloor,
  //     })),
  //   }) as Bay;
  // }
}

export type ConvertedLocation = VisualLocation<
  LayoutImportConverterLocationFragment & {
    locationHeightFromFloor: number;
    locationDepthFromFront: number;
    locationIndexFromFront: number;
  }
>;

export interface ConvertedArea {
  id: string;
  aisles: ConvertedAisle[];
  bays: ConvertedBay[];
  bounds: Flatten.Box;
  aislePortals: AislePortal[];
  bayPortals: BayPortal[];
  startAisleId?: string;
  endAisleId?: string;
  size?: [number, number];
  shape?: Flatten.Polygon;
  levels: number[];
}

export interface ConvertedLayout {
  areas: ConvertedArea[];
}
