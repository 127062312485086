import {
  IdentifierNode,
  KyselyPlugin,
  OperationNodeTransformer,
  PluginTransformQueryArgs,
  PluginTransformResultArgs,
  QueryResult,
  RootOperationNode,
  UnknownRow,
} from 'kysely';

class TableMapperTransformer extends OperationNodeTransformer {
  constructor(private readonly mapper: (name: string) => string | null) {
    super();
  }

  protected transformIdentifier(node: IdentifierNode): IdentifierNode {
    node = super.transformIdentifier(node);

    const mappedName = this.mapper(node.name);

    if (mappedName) {
      return {
        ...node,
        name: mappedName,
      };
    } else {
      return node;
    }
  }
}

export class TableMapperPlugin implements KyselyPlugin {
  constructor(private readonly mapper: (name: string) => string | null) {}

  transformQuery(args: PluginTransformQueryArgs): RootOperationNode {
    return new TableMapperTransformer(this.mapper).transformNode(args.node);
  }

  async transformResult(
    args: PluginTransformResultArgs,
  ): Promise<QueryResult<UnknownRow>> {
    return args.result;
  }
}
