const MenuDotsHorizontalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuDotsHorizontalIcon"
    {...props}
  >
    <circle
      cx={64}
      cy={64}
      r={17}
      strokeWidth={0}
      transform="translate(-26.51 63.4) rotate(-44.62)"
    />
    <circle
      cx={108}
      cy={64}
      r={17}
      strokeWidth={0}
      transform="translate(-13.83 94.3) rotate(-44.62)"
    />
    <circle
      cx={20}
      cy={64}
      r={17}
      strokeWidth={0}
      transform="translate(-39.19 32.49) rotate(-44.62)"
    />
  </svg>
);
export default MenuDotsHorizontalIcon;
