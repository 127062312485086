import * as React from 'react';
const WarehouseBoxRightArrowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseBoxRightArrowIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64 27.22 5.2 41.25 5.2 98.16 31.96 98.16 31.96 61.77 64 43.27 96.04 61.77 96.04 98.16 122.8 98.16 122.8 41.25 64 27.22"
    />
    <polygon
      strokeWidth={0}
      points="37.45 102.82 68.59 102.83 53.03 118.4 69.04 118.4 90.26 97.17 69.03 75.93 53.03 75.92 68.59 91.5 37.45 91.49 37.45 102.82"
    />
  </svg>
);
export default WarehouseBoxRightArrowIcon;
