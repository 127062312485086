import * as React from 'react';
const Settings3Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Settings3Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M85.05,34.14c.46-.46.58-1.14.32-1.73-3.39-7.59-1.72-16.64,4.16-22.51,5.76-5.76,14.53-7.44,21.96-4.39l-15.38,15.38c-.61.61-.61,1.59,0,2.2l8.79,8.79c.61.61,1.59.61,2.2,0l15.38-15.38c3.05,7.43,1.37,16.2-4.39,21.96-5.88,5.88-14.93,7.55-22.52,4.16-.59-.26-1.28-.14-1.73.32l-10.9,10.9-8.79-8.79,10.9-10.9ZM42.46,92.01c-.47.45-.61,1.14-.36,1.73,3.19,7.58,1.29,16.61-4.74,22.48-5.91,5.76-14.72,7.43-22.08,4.39l15.77-15.35c.62-.61.65-1.59.06-2.19l-8.56-8.77c-.59-.61-1.58-.61-2.2,0l-15.77,15.35c-2.86-7.42-.95-16.17,4.96-21.93,6.03-5.87,15.12-7.54,22.62-4.15.58.26,1.27.13,1.74-.32l11.18-10.88,8.56,8.77-11.18,10.88ZM109.35,124l-7.98-7.98,5.49-5.49c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-5.49,5.49-5.32-5.32,3.3-3.3c.61-.61.5-1.69-.23-2.43-.74-.73-1.82-.84-2.43-.23l-3.3,3.3-5.32-5.32,5.49-5.49c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-5.49,5.49-5.32-5.32,3.29-3.3c.61-.61.5-1.69-.23-2.43-.74-.73-1.82-.84-2.43-.23l-3.3,3.3-5.32-5.32,5.49-5.49c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-5.49,5.49-5.32-5.32,3.3-3.3c.61-.61.5-1.69-.23-2.43-.74-.73-1.82-.84-2.43-.23l-3.3,3.3-5.32-5.32,5.49-5.49c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-5.49,5.49-5.32-5.32,3.29-3.3c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-3.3,3.3-5.32-5.32,5.49-5.49c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-5.49,5.49-5.32-5.32,3.3-3.3c.61-.61.5-1.69-.23-2.43-.74-.73-1.82-.84-2.43-.23l-3.3,3.3-5.32-5.32,5.49-5.49c.61-.61.5-1.69-.23-2.43-.73-.73-1.82-.84-2.43-.23l-5.49,5.49-7.98-7.98,15.38-15.38,98.37,98.38-15.38,15.38Z"
    />
  </svg>
);
export default Settings3Icon;
