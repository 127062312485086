import * as React from 'react';
const Mhe6AForkliftSitDownElectric3WheelIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe6AForkliftSitDownElectric3WheelIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M17.88,118.82c-1.74,0-3.23-.62-4.46-1.85s-1.85-2.77-1.85-4.62v-.62l.46-4.77.46-4c-2.77,1.44-4.62,3.57-5.54,6.39s-.69,5.67.69,8.54,3.49,4.77,6.31,5.7c2.82.92,5.67.72,8.54-.62,1.95-.92,3.49-2.26,4.62-4l-9.24-.15h0ZM68.53,111.28c.21,5.13,2.33,8.85,6.39,11.16s8.39,2.33,13.01.08c2.05-1.23,3.64-2.82,4.77-4.77,1.13-1.95,1.69-4.1,1.69-6.46-.31-5.13-2.46-8.88-6.46-11.24-4-2.36-8.31-2.36-12.93,0-2.05,1.13-3.64,2.69-4.77,4.69-1.13,2-1.69,4.18-1.69,6.54ZM33.43,78.8c2.98,0,5.59,1.03,7.85,3.08l9.54,8.47c2.57,2.26,5.54,3.44,8.93,3.54l23.24.15c.72,0,1.13-.46,1.23-1.39,0-.1-.05-.21-.15-.31-2.05-4.82-4.98-10.26-8.77-16.32-3.8-6.05-8.52-11.6-14.16-16.62-2.15-1.85-4.59-3.21-7.31-4.08-2.72-.87-5.52-1.21-8.39-1l-15.08,1.23c-1.13.1-2.18.51-3.16,1.23s-1.72,1.64-2.23,2.77c-2.46,5.75-4.41,11.7-5.85,17.86-.1.62.15,1.03.77,1.23l13.55.15h0ZM59.44,97.27c-3.9-.1-7.34-1.44-10.31-4l-9.39-8.62c-1.95-1.64-4.16-2.46-6.62-2.46h-14.78c-1.23,5.13-2.41,11.34-3.54,18.62l-.46,3.54c-.31,2.36-.62,4.93-.92,7.7-.1,1.23.23,2.31,1,3.23.77.92,1.82,1.49,3.16,1.69h49.72c-.92-2.46-1.26-4.95-1-7.47s1.1-4.85,2.54-7c.92-1.64,2.26-2.98,4-4,.82-.51,1.64-.92,2.46-1.23h-15.85ZM100.85,111.13l-3.69-29.55-3.85-29.4-11.85,1.54.62,5.08c.21.82.77,1.23,1.69,1.23l1.69-.15,5.08,38.64c0,.41.15.77.46,1.08,2.46,2.15,4.13,4.72,5,7.7s.74,6-.38,9.08l24.47-3.08,6.31-3.69-23.7,3.08c-1.03-.21-1.64-.72-1.85-1.54h0Z"
    />
    <path
      strokeWidth={0}
      d="M99.84,47.66l5.1-19.44h-10.55l12.22-22.77h11.11l-7.78,14.44h12.22l-22.31,27.77Z"
    />
  </svg>
);
export default Mhe6AForkliftSitDownElectric3WheelIcon;
