import * as React from 'react';
const ObjectAisleWidthIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisleWidthIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.28 85.07 6.27 112.62 21.12 85.07 6.28 85.07"
    />
    <polygon
      strokeWidth={0}
      points="6.28 8.59 6.27 36.14 21.12 8.59 6.28 8.59"
    />
    <polygon
      strokeWidth={0}
      points="22.32 118.11 22.33 90.73 7.58 118.11 22.32 118.11"
    />
    <polygon
      strokeWidth={0}
      points="6.28 46.83 6.27 74.38 21.12 46.83 6.28 46.83"
    />
    <polygon
      strokeWidth={0}
      points="22.32 41.63 22.33 14.25 7.58 41.63 22.32 41.63"
    />
    <polygon
      strokeWidth={0}
      points="22.32 79.87 22.33 52.49 7.58 79.87 22.32 79.87"
    />
    <path
      strokeWidth={0}
      d="M25.79,3.54H2.91v118.16h22.88l10.12-31.82v-23.52L25.79,3.54ZM23.02,43.4v1.2h1.7v36.22h-1.7v.82h0v1.2h1.7v36.22h-1.7v1.5H5.58v-1.5h-1.7v-36.22h1.7v-.52h0v-1.5h-1.7v-36.22h1.7v-.52h0v-1.5h-1.7V6.37h1.7v-1.23l17.44.03v1.2h1.7v36.22h-1.7v.82h0Z"
    />
    <polygon
      strokeWidth={0}
      points="122.56 85.07 122.56 112.62 107.72 85.07 122.56 85.07"
    />
    <polygon
      strokeWidth={0}
      points="122.56 8.59 122.56 36.14 107.72 8.59 122.56 8.59"
    />
    <polygon
      strokeWidth={0}
      points="106.51 118.11 106.51 90.73 121.25 118.11 106.51 118.11"
    />
    <polygon
      strokeWidth={0}
      points="122.56 46.83 122.56 74.38 107.72 46.83 122.56 46.83"
    />
    <polygon
      strokeWidth={0}
      points="106.51 41.63 106.51 14.25 121.25 41.63 106.51 41.63"
    />
    <polygon
      strokeWidth={0}
      points="106.51 79.87 106.51 52.49 121.25 79.87 106.51 79.87"
    />
    <path
      strokeWidth={0}
      d="M92.92,66.36v23.52s10.12,31.82,10.12,31.82h22.88s0-118.16,0-118.16h-22.88s-10.12,62.82-10.12,62.82ZM105.81,43.4v-.82s-.85,0-.85,0h-.85V6.37s1.7,0,1.7,0v-1.2l17.44-.03v1.23s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.12s0,.15,0,.15v1.1h0v.52s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.28s0,1.1,0,1.1h0v.52s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.28s0,1.1,0,1.1h-17.44v-1.23s0-.28,0-.28h-.85s-.85,0-.85,0v-36.22s1.7,0,1.7,0v-1.2h0v-.82s-.85,0-.85,0h-.85v-36.22s1.7,0,1.7,0v-1.2h0Z"
    />
    <path
      strokeWidth={0}
      d="M40.44,73.83c-.07.07-.13.15-.19.23-.03.04-.04.08-.06.12-.03.05-.05.09-.07.14-.02.05-.03.1-.05.15-.01.04-.03.08-.04.13-.04.19-.04.39,0,.59,0,.04.03.08.04.13.02.05.03.1.05.15.02.05.05.09.07.14.02.04.04.08.06.12.06.08.12.16.19.23l11.98,11.98c.58.58,1.53.58,2.12,0,.29-.29.44-.68.44-1.06s-.15-.77-.44-1.06l-9.42-9.42h22.59c.83,0,1.5-.67,1.5-1.5s-.67-1.5-1.5-1.5h-22.59s9.42-9.42,9.42-9.42c.29-.29.44-.68.44-1.06s-.15-.77-.44-1.06c-.58-.58-1.53-.58-2.12,0l-11.98,11.98Z"
    />
    <path
      strokeWidth={0}
      d="M87.56,75.95c.07-.07.13-.15.19-.23.03-.04.04-.08.06-.12.03-.05.05-.09.07-.14.02-.05.03-.1.05-.15.01-.04.03-.08.04-.13.04-.19.04-.39,0-.59,0-.04-.03-.08-.04-.13-.02-.05-.03-.1-.05-.15-.02-.05-.05-.09-.07-.14-.02-.04-.04-.08-.06-.12-.06-.08-.12-.16-.19-.23l-11.98-11.98c-.58-.58-1.53-.58-2.12,0-.29.29-.44.68-.44,1.06s.15.77.44,1.06l9.42,9.42h-22.59c-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5h22.59s-9.42,9.42-9.42,9.42c-.29.29-.44.68-.44,1.06s.15.77.44,1.06c.58.58,1.53.58,2.12,0l11.98-11.98Z"
    />
  </svg>
);
export default ObjectAisleWidthIcon;
