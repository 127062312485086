import { Vector } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getRestrictionIcon,
  getRotationAngle,
} from '../simulation/store/routingPolicy/routing.default';
import { getDirectionDescriptorsMapById } from '../simulation/store/routingPolicy/routingPolicy.default';
import { DirectionDescriptorId } from '../simulation/store/routingPolicy/routingPolicy.types';

export type RoutingPolicyDirectionOptionProps = {
  directionId?: DirectionDescriptorId;
  sourceVector: Vector;
};

const RoutingPolicyDirectionOption: React.FC<
  RoutingPolicyDirectionOptionProps
> = ({ directionId, sourceVector }) => {
  const { t } = useTranslation('simulation');
  const direction = getDirectionDescriptorsMapById(t)?.[directionId];
  const Arrow = getRestrictionIcon(directionId);

  const rotation = getRotationAngle(direction?.value ?? sourceVector);
  const text = direction?.title ?? t`Custom`;

  return (
    <div
      data-component={`RoutingPolicyDirectionOption`}
      data-label={`RoutingPolicyDirectionOption-${directionId}`}
      aria-label={`RoutingPolicyDirectionOption-${directionId}`}
      className={classNames('flex flex-1 my-0.5', 'min-w-14')}
    >
      <Arrow
        className={`fill-current w-4 h-4`}
        style={{ transform: `rotate(${rotation}deg)` }}
      />
      <span className="flex items-center px-1 text-xs uppercase opacity-75">
        {text}
      </span>
    </div>
  );
};

export default RoutingPolicyDirectionOption;
