import * as React from 'react';
const DesignerStartingPointIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerStartingPointIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M63.01,96.13c.06-.06.14-.12.21-.17.03-.02.07-.04.11-.06.04-.02.08-.05.13-.07.05-.02.09-.03.14-.04.04-.01.08-.03.12-.03.18-.04.36-.04.54,0,.04,0,.08.02.12.03.05.01.1.02.14.04.05.02.09.04.13.07.04.02.07.04.11.06.08.05.15.11.21.17l11.06,11.06c.54.54.54,1.42,0,1.96-.27.27-.62.41-.98.41s-.71-.14-.98-.41l-8.7-8.7v20.86c0,.76-.62,1.38-1.38,1.38s-1.38-.62-1.38-1.38v-20.86s-8.7,8.7-8.7,8.7c-.27.27-.62.41-.98.41s-.71-.14-.98-.41c-.54-.54-.54-1.42,0-1.96l11.06-11.06Z"
    />
    <circle cx={113.61} cy={64.09} r={12.44} strokeWidth={0} />
    <circle cx={14.39} cy={64.09} r={12.44} strokeWidth={0} />
    <g opacity={0.7}>
      <polygon
        strokeWidth={0}
        points="84.09 66.66 84.07 61.66 111.09 61.54 111.11 13.83 116.11 13.84 116.08 66.52 84.09 66.66"
      />
    </g>
    <g opacity={0.7}>
      <polygon
        strokeWidth={0}
        points="11.89 66.66 11.89 13.9 116.1 13.77 116.11 18.77 16.89 18.89 16.89 61.64 43.89 61.52 43.91 66.52 11.89 66.66"
      />
    </g>
    <circle cx={113.61} cy={16.33} r={12.44} strokeWidth={0} />
    <circle cx={64} cy={16.33} r={12.44} strokeWidth={0} />
    <circle cx={14.39} cy={16.33} r={12.44} strokeWidth={0} />
    <g opacity={0.7}>
      <rect width={5} height={30} x={61.5} y={13.83} strokeWidth={0} />
    </g>
    <path
      strokeWidth={0}
      d="M64,38.06c-14.38,0-26.03,11.66-26.03,26.03s11.66,26.03,26.03,26.03,26.03-11.66,26.03-26.03-11.66-26.03-26.03-26.03ZM76.77,70.51l-9.36-9.36v18.7s-6.82,0-6.82,0v-18.7s-9.36,9.34-9.36,9.34v-9.61s12.78-12.74,12.78-12.74l12.77,12.76v9.61Z"
    />
  </svg>
);
export default DesignerStartingPointIcon;
