import { useTranslation } from 'react-i18next';
import AssignmentStatsLegend from '../../assignmentStats/AssignmentStatsLegend';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-dataset-stats-legend',
    title: t`Legend`,
    loadingMessage: t`Loading Items...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <AssignmentStatsLegend />
    </SidebarContainer>
  );
};
