import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import * as Icon from '../../components/icons';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import { getOccupancyCategories } from '../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyCategoryFilter,
  assignmentOccupancyShowHeatmap,
  assignmentOccupancyShowHeatmapLegend,
} from '../store/occupancy/assignmentOccupancy.state';
import { LegendItem, LegendItemProps } from './LegendItem';

const AssignmentOccupancyToolbar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [showHeatmap, setShowHeatmap] = useRecoilState(
    assignmentOccupancyShowHeatmap,
  );
  const [showHeatmapLegend, setShowHeatmapLegend] = useRecoilState(
    assignmentOccupancyShowHeatmapLegend,
  );
  const [filters, setFilters] = useRecoilState(
    assignmentOccupancyCategoryFilter,
  );

  const hasFiltered = !_.isEmpty(filters);
  const toggleBucketFilter = (bucketKey: string) => {
    if (_.has(filters, bucketKey)) {
      setFilters(_.omit(filters, bucketKey));
    } else {
      setFilters({
        ...filters,
        [bucketKey]: true,
      });
    }
  };

  const getItems = () => {
    return _.map(getOccupancyCategories(t), config => {
      const legendProps: LegendItemProps = {
        color: config.color,
        text: config.title,
        isHidden: hasFiltered && !_.has(filters, config.key),
        onClick: () => toggleBucketFilter(config.key),
      };
      return (
        <ButtonToolbar key={`legend-item-${config.key}`}>
          <LegendItem {...legendProps} />
        </ButtonToolbar>
      );
    });
  };
  return (
    <ButtonToolbarGroup
      className={`absolute ltr:left-1 rtl:right-1 top-1 flex flex-col`}
    >
      <TitleToolbarContainer
        id="panel-toolbar-analytics-heatmap-occupancy"
        title={t`Analytics`}
        collapsible
      >
        <div className="flex items-start">
          <ButtonToolbar isSelected={showHeatmap} onChange={setShowHeatmap}>
            <Icon.HeatMap9
              className="w-8 h-8 text-white"
              alt={t`Show Occupancy heatmap`}
              title={t`Show Occupancy heatmap`}
            />
          </ButtonToolbar>
        </div>
      </TitleToolbarContainer>
      {showHeatmap && showHeatmapLegend && (
        <TitleToolbarContainer
          id="panel-toolbar-heatmap-occupancy"
          title={t`Occupancy %`}
          collapsible
          className="mt-2"
        >
          <div>{getItems()}</div>
        </TitleToolbarContainer>
      )}
    </ButtonToolbarGroup>
  );
};

export default AssignmentOccupancyToolbar;
