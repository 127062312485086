import {
  AnalyzeResultEventTypeSummary,
  AnalyzeResultFragment,
  AnalyzeResultProcessType,
  PicklistEventType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../../../../common/useFormatter';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { StatListItem } from '../../../../components/stats/StatListItem';
import { LCV_EU_Multiplier } from '../../../store/resourcePolicy.default';
import { resourcePolicyPickingAgents } from '../../../store/resourcePolicy.state';
import { EventGroup, EventGroupMap } from '../../../store/simulation.helper';
import {
  simulationAnalyzeDistinctDays,
  simulationAnalyzeInterval,
} from '../../../store/simulation.state';
import { workforceSimulateInterval } from '../../../store/workforce.state';

import { endOfDay, startOfDay } from 'date-fns';
import { Stat } from '../../../../components/stats/Stat';
import {
  DRILL_KEY_WORK_TOTAL,
  zipEvents,
} from '../../../store/workforce.types';
import WorkforceAgentsStats from '../agentSummary/WorkforceAgentsStats';
import WorkforceSummaryAgentsChart, {
  WorkforceSummaryAgentsChartDataRow,
} from './WorkforceSummaryAgentsChart';

export type WorkforceSummaryPanelProps = {
  analyzeResult: AnalyzeResultFragment;
  showSummary?: boolean;
};

const groupEvents = (events: AnalyzeResultEventTypeSummary[]) =>
  _.reduce(
    events,
    (acc, event) => {
      return {
        ...acc,
        [EventGroupMap[event.eventType]]:
          (acc[EventGroupMap[event.eventType]] ?? 0) + event.totalCost,
      };
    },
    {} as Record<EventGroup, number>,
  );
/**
 *
 *
 * @param {*} props
 * @return {*}
 */
const WorkforceSummaryPanel: React.FC<WorkforceSummaryPanelProps> = props => {
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  const days = useRecoilValue(simulationAnalyzeDistinctDays);
  const agents = useRecoilValue(resourcePolicyPickingAgents);
  const analyzeInterval = useRecoilValue(simulationAnalyzeInterval);
  const [simulateInterval, setSimulateInterval] = useRecoilState(
    workforceSimulateInterval,
  );
  const { analyzeResult } = props;
  if (!analyzeResult) return null;

  function setInterval([from, to]: [Date, Date]) {
    setSimulateInterval([startOfDay(from), endOfDay(to)]);
  }

  const pickingEvents = _.filter(
    analyzeResult.summary?.eventTypes,
    e => e.processType === AnalyzeResultProcessType.PICKING,
  );

  const eventsTotal = zipEvents(
    pickingEvents.map(e => _.pick(e, 'totalCost', 'totalDuration')),
  );

  const dynamoByAgent = _.reduce(
    analyzeResult?.summary?.agents,
    (acc, agent) => ({
      ...acc,
      [agent.agent]: _.reduce(
        agent.eventTypes.filter(
          e => e.processType === AnalyzeResultProcessType.PICKING,
        ),
        (acc, e) => {
          if (
            e.eventType !== PicklistEventType.TRAVELLING_HORIZONTAL &&
            e.eventType !== PicklistEventType.TRAVELLING_VERTICAL
          )
            return acc;

          const a = agents[agent.agent];
          const energyRate =
            a.specs?.powerSource?.energyConsumptionPerSecond ?? 0;

          const speed =
            e.eventType !== PicklistEventType.TRAVELLING_VERTICAL
              ? a.specs.horizontalTravelling?.speed?.speedLaden ?? 0
              : 0;

          return {
            distance: acc.distance + e.totalDuration * speed,
            co2: acc.co2 + e.totalDuration * energyRate * LCV_EU_Multiplier,
          };
        },
        {
          distance: 0,
          co2: 0,
        },
      ),
    }),
    {} as Record<string, { distance: number; co2: number }>,
  );
  const dynamoTotals = zipEvents(_.values(dynamoByAgent));

  const agentSummaryMap = _.keyBy(
    props.analyzeResult.summary.agents,
    a => a.agent,
  );

  const distance = formatter.formatDistance(dynamoTotals.distance ?? 0);
  const duration = formatter.formatTimeSpan(eventsTotal.totalDuration ?? 0);
  const cost = formatter.formatCurrency(eventsTotal.totalCost ?? 0);

  const assembledOrdersCount =
    analyzeResult.totalAssembledOrders +
    analyzeResult.totalAssembledPartiallyOrders;

  const eventsGroups = groupEvents(pickingEvents);

  const carbonValue = dynamoTotals.co2; // agentEnergyTime * energyRate * LCV_EU_Multiplier;
  const carbon = formatter.formatCO2(carbonValue);

  const chartData: WorkforceSummaryAgentsChartDataRow[] = _(
    props.analyzeResult.summary.agents,
  )
    .map(agentSummary => {
      const pickingEvents = _.filter(
        agentSummary.eventTypes,
        e => e.processType === AnalyzeResultProcessType.PICKING,
      );
      const eventGroups = groupEvents(pickingEvents);
      const totalCost = _.sumBy(pickingEvents, e => e.totalCost);
      const row: WorkforceSummaryAgentsChartDataRow = {
        agentId: agentSummary.agent,
        agentTitle: agents[agentSummary.agent]?.title ?? agentSummary.agent,
        [DRILL_KEY_WORK_TOTAL]: totalCost,
        ...eventGroups,
      };

      return row;
    })
    .filter(agentSummary => agentSummary[DRILL_KEY_WORK_TOTAL] > 0)
    .orderBy(agentSummary => agentSummary[DRILL_KEY_WORK_TOTAL], 'asc')
    .value();

  const max =
    _.maxBy(chartData, row => row[DRILL_KEY_WORK_TOTAL])?.[
      DRILL_KEY_WORK_TOTAL
    ] ?? 0;

  return (
    <>
      <StatGroup
        title={t`Workforce Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/workforce/simulation-workforce'}
      >
        <Stat title={t`Workforce Totals`}>
          <StatListItem
            title={t`Total Days`}
            value={days}
            // unitOfMeasure={days}
            // isUnitPrefix={cost.prefixUnit}
          />
          {props.showSummary ? (
            <>
              {cost.raw > 0 && (
                <StatListItem
                  title={t`Total Cost`}
                  value={cost.value}
                  unitOfMeasure={cost.unit}
                  isUnitPrefix={cost.prefixUnit}
                />
              )}
              {duration.raw > 0 && (
                <StatListItem
                  title={t`Total Time`}
                  unitOfMeasure={duration.unit}
                  value={duration.value}
                />
              )}
              {distance.raw > 0 && (
                <StatListItem
                  title={t`Total Distance`}
                  value={distance.value}
                  unitOfMeasure={distance.unit}
                />
              )}
              {carbon.raw > 0 && (
                <StatListItem
                  title={t`Total CO2`}
                  value={carbon.value}
                  unitOfMeasure={carbon.unit}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Stat>
        <Stat title={t`Labour by Type`}>
          <WorkforceSummaryAgentsChart
            data={chartData}
            kpi={'cost'}
            max={max}
          />
        </Stat>

        <div className="space-y-1">
          <WorkforceAgentsStats analyzeResult={analyzeResult} />
        </div>
      </StatGroup>
    </>
  );
};

export default WorkforceSummaryPanel;
