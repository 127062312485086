import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { LocationFilterUnion } from './policies';

export enum LoadBalancingZoneType {
  AISLE = 'AISLE',
  BAY = 'BAY',
  CONGESTION_ZONE = 'CONGESTION_ZONE',
  PLANE = 'PLANE',
  WAREHOUSE_AREA = 'WAREHOUSE_AREA',
}

registerEnumType(LoadBalancingZoneType, {
  name: 'LoadBalancingZoneType',
});

@InputAndObjectType()
export class LoadBalancingConstraint {
  @Field()
  maxRelativeDeviation: number;
}

@InputAndObjectType()
export class LoadBalancingRule {
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field({ nullable: true })
  title?: string;

  @Field({ nullable: true })
  objectiveWeight?: number;

  @Field(() => LoadBalancingZoneType)
  type: LoadBalancingZoneType;

  @Field(() => LocationFilterUnion, { nullable: true })
  include?: LocationFilterUnion;

  @Field(() => LoadBalancingConstraint, { nullable: true })
  constraint?: LoadBalancingConstraint;
}

@InputAndObjectType()
export class LoadBalancingPolicy {
  @Field(() => [LoadBalancingRule])
  rules: LoadBalancingRule[];
}
