import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { getQualitativeColor } from '../common/color.helper';
import { formatInteger } from '../common/formatHelper';
import { Stat } from '../components/stats/Stat';
import {
  waypointPolicyLocationCount,
  waypointPolicySelectedIdentity,
} from '../simulation/store/waypointPolicy/waypointPolicy.state';
import { WaypointPolicyGroupType } from '../simulation/store/waypointPolicy/waypointPolicy.types.';

export type WaypointPolicyLocationsStatsProps = {
  ruleId: string;
  groupType: WaypointPolicyGroupType;
};

const WaypointPolicyLocationsStats: React.FC<
  WaypointPolicyLocationsStatsProps
> = props => {
  const { t } = useTranslation('dataset');
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    waypointPolicySelectedIdentity,
  );
  const ruleStatsLoadable = useRecoilValueLoadable(
    waypointPolicyLocationCount(props),
  );

  const ruleStats =
    ruleStatsLoadable.state === 'hasValue' ? ruleStatsLoadable.contents : null;

  const locationCount = ruleStats?.filterMatches?.[0]?.totalCount;

  const isLoading = ruleStatsLoadable.state === 'loading';

  const isSelected = selectedIdentity?.ruleId === props.ruleId;

  const colors = getQualitativeColor(
    props.ruleId,
    props.groupType === 'start' ? 'waypointStart' : 'waypointEnd',
  );

  return (
    <Stat
      title={t`Location(s)`}
      value={formatInteger(locationCount)}
      inPanelMode
      isActionable
      inFilterStat
      className={`bg-opacity-0 px-1 xl:px-2 py-1`}
      isCheckLoading={isLoading}
      isSelected={isSelected}
      color={colors}
      onClick={() =>
        setSelectedIdentity(
          isSelected
            ? null
            : {
                ruleId: props.ruleId,
                groupType: props.groupType,
              },
        )
      }
    />
  );
};

export default WaypointPolicyLocationsStats;
