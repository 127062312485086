import * as React from 'react';
const Mhe12ATowTractorElectricIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe12ATowTractorElectricIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M125.48,86.4h-62.46v-40.43c0-.56-.18-1.01-.54-1.33s-.79-.48-1.27-.48h-1.45c-.97,0-1.8.3-2.48.91s-1.07,1.39-1.15,2.36l-3.51,36.07c-.16.81-.65,1.25-1.45,1.33h-16.95c-.73-.08-1.13-.48-1.21-1.21h0v-36.19c-.08-1.21-.61-2.18-1.57-2.91l2.54-2.42,1.94,3.27c.48.4.93.4,1.33,0,.24-.24.32-.56.24-.97l-5.08-8.96c-.24-.56-.67-.75-1.27-.54s-.83.59-.67,1.15c.08.16.16.28.24.36l1.82,3.03-14.16,6.66h0c-3.23,1.53-5.89,3.67-7.99,6.42-2.1,2.74-3.47,5.85-4.12,9.32l-4.24,24.81c-.08.89.12,1.63.61,2.24s1.13.95,1.94,1.03h115.23c.97,0,1.86-.2,2.66-.61l3.27-1.69c.32-.32.36-.65.12-.97-.08-.16-.16-.24-.24-.24h-.12ZM6.74,90.52v.12c0,.73.24,1.33.73,1.82s1.09.73,1.82.73,1.33-.24,1.82-.73.73-1.09.73-1.82v-.12h-5.08ZM55.28,90.52v.12c0,.73.24,1.33.73,1.82s1.09.73,1.82.73,1.33-.24,1.82-.73.73-1.09.73-1.82v-.12h-5.08ZM90.86,90.52v.12c0,.73.24,1.33.73,1.82s1.09.73,1.82.73,1.33-.24,1.82-.73.73-1.09.73-1.82v-.12h-5.08ZM96.67,90.52v.12c0,.73.24,1.33.73,1.82s1.07.73,1.76.73,1.27-.24,1.76-.73.77-1.09.85-1.82v-.12h-5.08Z"
    />
  </svg>
);
export default Mhe12ATowTractorElectricIcon;
