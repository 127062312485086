import { Schema } from '@markdoc/markdoc';

export const helpinclude: Schema<any, 'HelpInclude'> = {
  render: 'HelpInclude',
  attributes: {
    id: {
      type: String,
    },
  },
};
