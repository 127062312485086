import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import TooltipWrapper from '../../layout/tooltip/TooltipWrapper';
import {
  viewerHoveredAisle,
  viewerHoveredBay,
  viewerHoveredLocation,
  viewerShowTooltip,
} from '../../layout/viewer/store/viewer.state';
import SimulationLocationTooltip from '../tooltips/SimulationLocationTooltip';

export type HtmlTooltipLayerProps = { analyzeId: string };

const HtmlTooltipLayer: React.FC<HtmlTooltipLayerProps> = props => {
  const { t } = useTranslation('app');
  const showTooltip = useRecoilValue(viewerShowTooltip);
  const aisle = useRecoilValue(viewerHoveredAisle);
  const bay = useRecoilValue(viewerHoveredBay);
  const location = useRecoilValue(viewerHoveredLocation);

  if (!showTooltip || (!aisle && !bay && !location)) return null;

  const styleLabel = classNames(
    'flex items-center',
    'p-1 px-',
    'text-xs ',
    'uppercase',
    'bg-menu-active text-menu-active-text',
  );

  const styleValue = classNames(
    'flex p-1 px-2 text-sm font-bold text-start',
    'bg-app-panel-dark text-menu-text',
  );

  return (
    <TooltipWrapper stageId={'simulation-area-view'}>
      {location && <SimulationLocationTooltip analyzeId={props.analyzeId} />}
      {bay && (
        <div className="min-w-tooltip">
          <label className={classNames(styleLabel)}>{t`Bay`}</label>
          <div className={classNames(styleValue)}>{bay?.title ?? ''}</div>
        </div>
      )}
      {aisle && (
        <div className="min-w-tooltip">
          <label className={styleLabel}>
            {aisle.navigable ? t`Aisle` : t`Block`}
          </label>
          <div className={classNames(styleValue)}>{aisle?.title ?? ''}</div>
        </div>
      )}
    </TooltipWrapper>
  );
};

export default HtmlTooltipLayer;
