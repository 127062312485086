import _ from 'lodash';
import { atom, selector } from 'recoil';
import { userSettings, warehouseSelected } from '../../store/warehouse.state';
import { DEFAULT_ROLES } from '../roles';
import { AdminWarehouse } from '../roles/adminWarehouse';
import { FeatureKey, Role } from '../types';

const getKey = (postfix: string) => `warebee-permissions-${postfix}`;

export const currentUserRoleIdOverride = atom<string>({
  key: getKey('current-role-override'),
  default: null,
});

export const currentUserRole = selector<Role>({
  key: getKey('current-role'),
  get: ({ get }) => {
    const userData = get(userSettings);
    const roles: Record<string, Role> =
      get(warehouseSelected)?.extraSettings?.roles ?? DEFAULT_ROLES;

    const activeRoleId =
      get(currentUserRoleIdOverride) ?? userData?.featureFlags?.role;

    if (_.isNil(activeRoleId)) return AdminWarehouse;
    return roles?.[activeRoleId] ?? AdminWarehouse;
  },
});

export const visibleFeaturesKeys = selector<FeatureKey[]>({
  key: getKey('visible-features'),
  get: ({ get }) => {
    return get(currentUserRole).permissions[0].features;
  },
});
