import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { CollapsibleId } from '../store/collapsible.default';

export type SwitchContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  title: string;
  classNameHeader?: string;
  classNameInner?: string;
  classNameChildren?: string;
  className?: string;
  hasAction?: React.ReactNode;
  disabled?: boolean;
  isOpen?: boolean;
  hasInnerPadding?: boolean;
};

const SwitchContainer: React.FC<SwitchContainerProps> = props => {
  const { isOpen } = props;
  return (
    <div
      data-component="SwitchContainer"
      className={classNames(
        'text-menu-text',
        {
          'rounded shadow-xl': isOpen && !props.disabled,
        },
        {
          'rounded shadow-xl bg-app-panel-light': isOpen && props.disabled,
        },
        props.className,
      )}
    >
      <div
        className={classNames(
          'z-container group',
          'sticky top-0',
          'px-1 xl:px-2 py-2 xl:py-3',
          'flex items-center',
          'transition duration-100 ease-in-out delay-75',
          {
            'bg-app-panel-dark/60 rounded-t': isOpen,
          },
          props.classNameHeader,
        )}
      >
        <h6 className="flex items-start lg:items-center flex-1 h-full mr-0 w-full flex-col lg:flex-row">
          <span
            data-component="Title"
            className={classNames(
              'flex-1 text-sm text-start truncate px-1 mb-2 xl:mb-0 w-full',
            )}
          >
            {props.title}
          </span>
          {props.hasAction}
        </h6>
      </div>
      {props.children && (
        <Transition
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <section
            data-component="SwitchContainerBody"
            className={classNames(
              'z-0',
              'bg-app-panel-dark/40',
              props.hasInnerPadding && 'p-0.5 lg:px-1 2xl:px-2 pt-2 pb-4',
              'rounded-b',
              props.classNameChildren,
            )}
          >
            {props.children}
          </section>
        </Transition>
      )}
    </div>
  );
};

export default SwitchContainer;
