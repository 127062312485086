import {
  AnalyzeResultJobFragment,
  LoadAnalyzeJobsDetailsQuery,
  LoadAnalyzeJobsDetailsQueryVariables,
  ReassignJobDetailsFragment,
  ReassignJobMoveFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  ProductCategoryDescriptorExport,
  getCategoryString,
} from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type ReassignJobsExportJobParams = ExportJobParams<
  LoadAnalyzeJobsDetailsQueryVariables,
  ReassignJobsConverterConfig
>;

export const reassignJobsExportJobParams: ReassignJobsExportJobParams = {
  converterId: 'REASSIGN_JOBS',
  query: null,
  config: null,
  variables: null,
  filename: 're-assign.csv',
};

export type ReassignJobsDataRow = AnalyzeResultJobFragment &
  ReassignJobMoveFragment & {
    stepId: string;
    categorySting: string;
    uomString: string;
  };

export type ReassignJobsDataColumn = keyof ReassignJobsDataRow;

export type ReassignJobsConverterConfig = {
  columnsConfig: ColumnConfigBase<ReassignJobsDataRow>[];
  dictionaries: {
    categories: ProductCategoryDescriptorExport[];
  };
};

export const getReassignJobsTableRows = (
  data: AnalyzeResultJobFragment[],
  config: ReassignJobsConverterConfig,
): ReassignJobsDataRow[] => {
  const getReassignJobsDataRow = (job: AnalyzeResultJobFragment) => {
    const details = job.details as ReassignJobDetailsFragment;
    return _.map(details?.moves, move => ({
      ...job,
      ...move,
      categorySting: getCategoryString(
        move.item?.cumulativePercentRank,
        config.dictionaries.categories,
      )?.tag,
      stepId: `${move.groupId}.${move.chainNumber + 1}.${
        move.sequenceNumber + 1
      }`,
      uomString:
        move?.movedQuantity?.breakdownByUom
          ?.map(item => `${item.uom}, ${item.uomQuantity}`)
          ?.join(',') ?? '',
    })) as ReassignJobsDataRow[];
  };

  return _.flatMap(data, getReassignJobsDataRow);
};

export const REASSIGN_JOBS_EXPORT_CONVERTER: ExportConverter<
  LoadAnalyzeJobsDetailsQuery,
  ReassignJobsConverterConfig
> = (input, config) => {
  const data = getReassignJobsTableRows(
    input.analyzeResult?.jobs?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
