import { AnalyzeResultFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AnalyzeResultSummary from '../../analyze/AnalyzeResultSummary';
import AnalyzeCompareHeatmapPanel from '../../analyzeCompare/panels/AnalyzeCompareHeatmapPanel';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import { analyzeContentViewAs } from '../store/analyze.state';
import { AnalyseTabId } from '../store/simulation.types';
import { simulationAnalyseTabKey } from '../store/simulation.wizard.state';
import SimulationAnalyzePanelProduct from './SimulationAnalyzePanelProduct';
import SimulationAnalyzePanelWork from './SimulationAnalyzePanelWork';
import { SimulationAnalyzeOrdersPanel } from './SimulationAnalyzedOrdersPanel';

interface AnalyzePanelProps {
  analyzeResult: AnalyzeResultFragment;
  isOptimise?: boolean;
}

const AnalyzePanel: React.FC<AnalyzePanelProps> = ({
  analyzeResult,
  isOptimise,
}) => {
  const { t } = useTranslation('simulation');
  const [selectedTab, setSelectedTab] = useRecoilState(simulationAnalyseTabKey);
  const setAnalyzeViewAs = useSetRecoilState(analyzeContentViewAs);

  useEffect(() => {
    switch (selectedTab) {
      case 'tab-analyze-resources':
        setAnalyzeViewAs('dashboard');
        break;
      default:
        setAnalyzeViewAs('layout');
    }
  }, [selectedTab]);
  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const tabItems: TabItem<AnalyseTabId>[] = [
    {
      id: 'tab-analyze-summary',
      title: t`Summary`,
      icon: <Icon.SimulationAnalyze className={tabIconStyle} />,
      content: <AnalyzeResultSummary analyzeResult={analyzeResult} />,
    },
    // {
    //   id: 'tab-analyze-process',
    //   title: t`Process`,
    //   content: <AnalyzeResultSummary analyzeResult={analyzeResult} />,
    // },
    {
      id: 'tab-analyze-resources',
      title: t`Resources`,
      icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
      content: (
        <SimulationAnalyzePanelWork
          analyzeResult={analyzeResult}
          isOptimise={isOptimise}
        />
      ),
    },
    // {
    //   id: 'tab-analyze-workload',
    //   title: t`Workload`,
    //   icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
    //   content: <AnalyzeResultLoadBalancing analyzeResult={analyzeResult} />,
    // },
    {
      id: 'tab-analyze-items',
      title: t`Items`,
      icon: <Icon.DataItems className={tabIconStyle} />,
      content: <SimulationAnalyzePanelProduct analyzeId={analyzeResult.id} />,
    },
    {
      id: 'tab-analyze-orders',
      title: t`Orders`,
      icon: <Icon.DataOrders className={tabIconStyle} />,
      content: <SimulationAnalyzeOrdersPanel analyzeId={analyzeResult.id} />,
    },
    {
      id: 'tab-analyze-heatmaps',
      title: t`Heatmaps`,
      icon: <Icon.HeatMap9 className={tabIconStyle} />,
      content: <AnalyzeCompareHeatmapPanel />,
    },
    // {
    //   id: 'tab-analyze-allocate',
    //   title: t`Allocate`,
    //   icon: <Icon.AllocationReport className={tabIconStyle} />,
    //   content: <SimulationAnalyzeOrdersPanel analyzeId={analyzeResult.id} />,
    // },
  ];

  return (
    <Tabs
      // className="flex-1 h-full"
      componentName="AnalysePanel"
      items={tabItems}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      classNameTitle="uppercase"
      hasScroll
      fullHeight
    />
  );
};

export default AnalyzePanel;
