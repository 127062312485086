import { CreateAssignmentItemInput } from '@warebee/shared/data-access-api-dto';
import { TFunction } from 'i18next';
import { MappingSchema } from '../mapping';
import {
  BusinessLogicValidationError,
  BusinessLogicValidator,
} from '../validator';

class AssignmentBusinessLogicValidator
  implements BusinessLogicValidator<CreateAssignmentItemInput>
{
  private readonly importedItemKeys = new Set();

  validateRow(
    row: CreateAssignmentItemInput,
    t: TFunction<'importer', undefined>,
  ): BusinessLogicValidationError[] {
    const { consignee, sku, locationId } = row;
    const key = JSON.stringify([consignee, sku, locationId]);
    if (this.importedItemKeys.has(key)) {
      const message = t(
        t`Duplicates item(s) found: \n
          Consignee: {{consignee}}, \n
          SKU: {{sku}},\n
          location: {{locationId}}\n`,
        {
          consignee,
          sku,
          locationId,
        },
      );
      return [
        {
          type: t`Item duplication error`,
          message,
        },
      ];
    } else {
      this.importedItemKeys.add(key);
    }
    return [];
  }
}

export const ASSIGNMENT_MAPPING_SCHEMA: MappingSchema<CreateAssignmentItemInput> =
  {
    fields: [
      {
        name: 'consignee',
        type: 'string',
      },
      {
        name: 'sku',
        type: 'string',
      },
      {
        name: 'locationId',
        type: 'string',
      },
      {
        name: 'stockUom',
        type: 'string',
        optional: true,
      },
      {
        name: 'stockUomQuantity',
        type: 'number',
        optional: true,
      },
    ],
    includeRawData: true,
    createValidator: () => new AssignmentBusinessLogicValidator(),
  };
