import { PicklistEventType } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { FeedMenuItem } from './feed.types';

export const SWARM_PAGE_SIZE = 20;

export const feedMenuItems: (
  t: TFunction<'app'>,
  isEmbedded?: boolean,
  isHidden?: boolean,
  showFeedSetup?: boolean,
) => FeedMenuItem[] = (t, isEmbedded, isHidden, showFeedSetup) => [
  {
    id: 'feed-menu-activity',
    title: t('Activity Feed', { ns: 'app' }),
    titleMinimized: t('Feed', { ns: 'app' }),
    icon: Icon.Routing,
    menuLevel: 1,
    sidebar: {
      'sidebar-feed-info-event': {
        isCollapsed: true,
        openByDefault: true,
        isHidden: true,
      },
    },
    isHidden: showFeedSetup,
  },

  // {
  //   id: 'feed-menu-data-view',
  //   title: t('Data', { ns: 'app' }),
  //   titleMinimized: t('Data', { ns: 'app' }),
  //   disabled: false,
  //   icon: Icon.TableView,
  //   menuLevel: 1,
  //   sidebar: {
  //     'sidebar-feed-info-event': {
  //       isHidden: true,
  //     },
  //   },
  // },
  // {
  //   id: 'feed-menu-policies',
  //   title: t('Policies', { ns: 'app' }),
  //   titleMinimized: t('Policies', { ns: 'app' }),
  //   disabled: false,
  //   icon: Icon.Policy,
  //   menuLevel: 1,
  //   sidebar: {
  //     'sidebar-feed-info-event': {
  //       isHidden: true,
  //     },
  //   },
  // },
  // {
  //   id: 'feed-menu-stats',
  //   title: t('Stats', { ns: 'app' }),
  //   titleMinimized: t('Stats', { ns: 'app' }),
  //   disabled: false,
  //   icon: Icon.HeatMap9,
  //   menuLevel: 1,
  //   sidebar: {
  //     'sidebar-feed-info-event': {
  //       isHidden: true,
  //     },
  //   },
  //   isHidden: isEmbedded,
  // },

  {
    id: 'feed-menu-setup',
    title: t('Setup', { ns: 'app' }),
    titleMinimized: t('Setup', { ns: 'app' }),
    icon: Icon.Settings,
    menuLevel: 1,
    sidebar: {
      'sidebar-feed-info-event': {
        isHidden: true,
      },
    },
    disabled: false,
    isHidden: false,
  },
];

export const feedEffectiveEventsTypeSet = new Set([
  PicklistEventType.HANDLING_EXECUTION,
  PicklistEventType.HANDLING_ON_COMPLETION,
  PicklistEventType.HANDLING_PALLET_REORDERING,
  PicklistEventType.HANDLING_PREPARATION,
  PicklistEventType.TRAVELLING_HORIZONTAL,
  PicklistEventType.TRAVELLING_VERTICAL,
]);

export const actualityJobTimingModes = [
  'absolute',
  'relative',
  'detailed',
  'steps',
] as const;

export type ActualityJobTimingMode = (typeof actualityJobTimingModes)[number];

export const actualityStatAggregates = ['agent', 'agentType'] as const;
export type ActualityStatAggregate = (typeof actualityStatAggregates)[number];
