const Mhe15AutostoreIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe15AutostoreIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M62.06,10.74c-.59-.34-1.33-.35-1.92,0L14.9,36.63s-.03.02-.04.03c-.13.08-.26.17-.36.28,0,0,0,0,0,0-.11.11-.2.23-.28.36-.02.04-.04.07-.06.11-.05.1-.1.21-.13.32-.01.04-.03.08-.04.12-.04.15-.06.3-.06.46v57.17c0,.69.37,1.32.96,1.67l45.24,26.18c.3.17.63.26.97.26s.67-.09.96-.26c.6-.35.96-.98.96-1.67V12.41c0-.69-.37-1.32-.96-1.67ZM59.17,15.74v45.14l-39.43-22.57L59.17,15.74ZM17.78,94.37v-52.73l41.39,23.69v53l-41.39-23.95Z"
    />
    <path
      strokeWidth={0}
      d="M68.39,97.8c1.06,0,1.93-.86,1.93-1.93v-24.61l5.49-3.13v7c0,1.06.86,1.93,1.93,1.93s1.93-.86,1.93-1.93v-10.32c0-.69-.37-1.32-.96-1.67-.59-.35-1.33-.35-1.92,0l-9.34,5.34c-.6.34-.97.98-.97,1.67v25.72c0,1.06.86,1.93,1.93,1.93h0Z"
    />
    <path
      strokeWidth={0}
      d="M67.44,63.94c.3.17.63.25.96.25s.68-.09.98-.27l24.59-14.52c.58-.35.94-.97.95-1.65l.09-18.64,14.16,8.07-6.54,3.61v-4.46c0-1.07-.86-1.93-1.93-1.93s-1.93.86-1.93,1.93v7.73c0,.68.36,1.31.95,1.66.59.35,1.32.36,1.91.03l12.41-6.85c.61-.34.99-.98,1-1.68,0-.7-.37-1.34-.97-1.69l-20.02-11.4c-.6-.34-1.33-.34-1.92,0-.59.34-.96.97-.96,1.66l-.1,20.84-20.74,12.24v-17.18l10.09-5.3c.94-.5,1.3-1.66.81-2.6-.49-.94-1.66-1.31-2.6-.81l-11.12,5.85c-.63.33-1.03.99-1.03,1.71v21.72c0,.69.37,1.33.97,1.67h0Z"
    />
    <path
      strokeWidth={0}
      d="M106.07,93.34c.93-.52,1.27-1.69.75-2.62-.52-.93-1.69-1.27-2.62-.75l-25.48,14.1v-8.51l15.46-8.05c.64-.33,1.04-1,1.04-1.72l-.2-30.09c0-.69-.38-1.32-.98-1.67-.6-.34-1.34-.33-1.93.02l-8.86,5.23c-.59.35-.95.98-.95,1.66v17.39l-6.35,3.02c-.96.46-1.37,1.61-.91,2.57.46.96,1.61,1.37,2.57.91l7.45-3.54c.67-.32,1.1-1,1.1-1.74v-17.5l5.02-2.96.17,25.56-15.46,8.05c-.64.33-1.04.99-1.04,1.71v12.95c0,.68.36,1.31.95,1.66.3.18.64.27.98.27.32,0,.64-.08.93-.24l8.78-4.85c0,2.35,0,4.82,0,6.13l-16.19,9.47v-14.81c0-1.06-.86-1.93-1.93-1.93s-1.93.86-1.93,1.93v18.17c0,.69.37,1.33.97,1.67.3.17.63.25.96.25s.67-.09.97-.26l20.03-11.73c.54-.32.89-.87.95-1.49,0-.11.02-.28,0-9.55l15.71-8.69h0Z"
    />
    <path
      strokeWidth={0}
      d="M114.09,43.84c-.58-.35-1.3-.37-1.9-.05l-12.29,6.58c-.61.33-1,.96-1.02,1.66l-.11,5.27c-.02,1.06.82,1.95,1.89,1.97.01,0,.03,0,.04,0,1.05,0,1.9-.84,1.93-1.89l.09-4.14,8.46-4.53v13.1l-11.38,6c-.63.33-1.03.99-1.03,1.71v13.76c0,.68.36,1.31.94,1.66.58.35,1.31.36,1.91.04l9.56-5.19v16.69c-8.92,4.88-13.57,7.44-13.83,7.61-.87.58-1.11,1.74-.55,2.63.36.58.99.89,1.63.89.34,0,.69-.09,1-.27.53-.32,9.02-4.97,14.6-8.02.62-.34,1-.99,1-1.69v-21.08c0-.68-.36-1.31-.94-1.66-.58-.35-1.31-.36-1.91-.04l-9.56,5.19v-9.35l11.38-6c.63-.33,1.03-.99,1.03-1.71v-17.48c0-.68-.36-1.31-.94-1.65h0Z"
    />
    <path
      strokeWidth={0}
      d="M67.55,33.22c.58.35,1.3.37,1.9.05l10.02-5.35c.94-.5,1.3-1.67.79-2.61-.5-.94-1.67-1.3-2.61-.79l-7.19,3.83v-13.02l13.74,7.9v18.97l-10.01,5.49c-.93.51-1.28,1.68-.76,2.62.35.64,1.01,1,1.69,1,.31,0,.63-.08.93-.24l11.01-6.04c.62-.34,1-.99,1-1.69v-21.22c0-.69-.37-1.33-.97-1.67l-17.6-10.12c-.6-.34-1.33-.34-1.93,0-.6.34-.96.98-.96,1.67v19.56c0,.68.36,1.3.94,1.65h0Z"
    />
  </svg>
);
export default Mhe15AutostoreIcon;
