import { MeasurementSystem } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import LocationFeature from '../../../../layout/features/LocationFeature';
import { stageStateById } from '../../../../layout/stage/stage.state';
import { warehouseMeasurementSystem } from '../../../../store/warehouse.state';
import { ConvertedLocationFeature } from '../converter.serializable.model';
import {
  converterAreaConfiguration,
  converterEditableArea,
} from '../store/converter.area.state';
import { getLayerConfig } from '../store/converter.helper';
import {
  converterHighlightedRackTypes,
  converterLayoutHoveredLocation,
  converterLayoutLocations,
  converterLayoutSelectedLocation,
  converterLayoutSelectedLocationId,
  converterLayoutSelectedLocationIdAtom,
  converterLayoutShowLocations,
} from '../store/converter.layout.state';

const ConverterLocationLayer: React.FC = () => {
  const showLocations = useRecoilValue(converterLayoutShowLocations);
  const areaResponse = useRecoilValueLoadable(converterEditableArea);
  const areasConfigResponse = useRecoilValueLoadable(
    converterAreaConfiguration,
  );
  const [selectedId, setSelectedId] = useRecoilState(
    converterLayoutSelectedLocationId,
  );
  const setHovered = useSetRecoilState(converterLayoutHoveredLocation);

  const locationsResponse = useRecoilValueLoadable(converterLayoutLocations);
  const highlightedRackTypes = useRecoilValue(converterHighlightedRackTypes);
  const stage = useRecoilValue(stageStateById('converter-area-view'));
  const ms = useRecoilValue(warehouseMeasurementSystem);

  if (
    areasConfigResponse.state !== 'hasValue' ||
    locationsResponse.state !== 'hasValue' ||
    areaResponse.state !== 'hasValue' ||
    !areaResponse.contents ||
    !showLocations
  )
    return null;
  const area = areaResponse.contents;
  const locations = locationsResponse.contents;
  const areasConfig = areasConfigResponse.contents;
  if (_.isEmpty(locations)) {
    return null;
  }

  const areaConfig = areasConfig[area?.id];
  function getTitle(l: ConvertedLocationFeature) {
    const minSize = ms === MeasurementSystem.METRIC ? 50 : 20;
    return stage.scale * l.locationLength > minSize ? l.locationId : null;
  }
  return (
    <Layer {...getLayerConfig(areaConfig, area)}>
      {locations.map(l => (
        <LocationFeature
          key={l.locationId}
          loc={l}
          title={getTitle(l)}
          onClick={() =>
            setSelectedId(l.locationId === selectedId ? null : l.locationId)
          }
          color={
            _.includes(highlightedRackTypes, l.locationRackingType)
              ? ['magenta', null]
              : null
          }
          onMouseOver={() => {
            setHovered(l);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
        />
      ))}
    </Layer>
  );
};

export default ConverterLocationLayer;
