import * as React from 'react';
const WarehouseIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.09,25.75L2.29,44.78v57.47h122.6v-57.47l-60.8-19.03ZM83.86,55.46h5.12v3.28h-5.12v-3.28ZM76.55,55.47h5.52v4.66h8.69v-4.66h5.52v12.12h-19.73v-12.12ZM88.98,71.1v3.28h-5.12v-3.28h5.12ZM76.55,70.96h5.52v4.79s8.69,0,8.69,0v-4.79h5.52v14.77h-19.73s0-14.77,0-14.77ZM61.04,55.46h5.12v3.28h-5.12v-3.28ZM53.73,55.47h5.52v4.66h8.71v-4.66h5.5v12.12s-19.73,0-19.73,0v-12.12ZM66.16,71.1v3.28h-5.12v-3.28h5.12ZM53.73,70.96h5.52v4.79h8.71v-4.79h5.5v14.77h-19.73v-14.77ZM38.23,55.46h5.12v3.28h-5.12v-3.28ZM30.93,55.47h5.5v4.66h8.71v-4.66h5.5v12.12h-19.71v-12.12ZM43.34,71.1v3.28h-5.12v-3.28h5.12ZM30.93,70.96h5.5v4.79h8.71v-4.79h5.5v14.77h-19.71v-14.77ZM100.29,95.47c0,.76-.8,1.38-1.79,1.38H28.69c-.99,0-1.79-.62-1.79-1.38v-5.99c0-.76.8-1.38,1.79-1.38h69.81c.99,0,1.79.62,1.79,1.38v5.99Z"
    />
    <rect width={19.51} height={5.3} x={39.22} y={89.82} strokeWidth={0} />
    <rect width={19.53} height={5.3} x={68.45} y={89.82} strokeWidth={0} />
  </svg>
);
export default WarehouseIcon;
