import { AssignmentComplianceItemStatus } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAssignmentComplianceStatusColor,
  getAssignmentComplianceStatusMap,
} from '../simulation/store/assignmentCompliance.helper';

type AssignmentComplianceStatusTagProps = {
  status: AssignmentComplianceItemStatus;
};

const AssignmentComplianceStatusTag: React.FC<
  AssignmentComplianceStatusTagProps
> = props => {
  const { t } = useTranslation('simulation');
  const statusMap = getAssignmentComplianceStatusMap(t);
  const [background, color] = getAssignmentComplianceStatusColor(props.status);

  return (
    <span
      aria-label={`Assignment Compliance Status`}
      className={`text-center px-2 py-1 rounded text-xxs flex-1 whitespace-nowrap`}
      style={{
        background,
        color,
      }}
    >
      {statusMap[props.status]}
    </span>
  );
};

export default AssignmentComplianceStatusTag;
