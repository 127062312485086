import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  getFeedDataRowsQuery,
  getFeedTotalRowsQuery,
} from '../../feed/store/datasetQueries/feedDataRows';
import { FeedQueryBuilderPagedParams } from '../../feed/store/datasetQueries/queryBuilder/feedQueryBuilder';
import { executeDatasetQuery } from '../../feed/store/feed.helper';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  datasetObjectData,
  datasetObjectDataLoadStatus,
  datasetObjectDataState,
} from '../store/datasetObject.state';
import { DatasetObjectDataRows } from '../store/datasetObject.types';

export type LoadActivityFeedDataParams = FeedQueryBuilderPagedParams & {
  isAppend?: boolean;
};

function useLoadActivityFeedData() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Activity Feed Data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(datasetObjectDataLoadStatus);
    reset(datasetObjectData);
    reset(datasetObjectDataState);
  });

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(datasetObjectDataLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadActivityFeedDataParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(datasetObjectData);

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(datasetObjectDataLoadStatus, AsyncLoadStatus.Error);
        }

        let totalCount = current?.totalCount ?? 0;
        if (!params.isAppend) {
          const compiledQueryTotal = getFeedTotalRowsQuery(params).compile();

          const totalResult = await executeDatasetQuery({
            warehouseId,
            compiledQuery: compiledQueryTotal,
            comment: '[dataset] Activity Feed Data',
          });

          // console.log('totalResult::', totalResult);
          totalCount =
            totalResult && totalResult.length > 0
              ? totalResult[0].totalCount
              : 0;
        }

        let rows = [];
        if (totalCount > 0) {
          const compiledQuery = getFeedDataRowsQuery(params).compile();

          rows = await executeDatasetQuery({
            warehouseId,
            compiledQuery,

            comment: '[dataset] Activity Feed Data',
          });
        }

        let data: DatasetObjectDataRows = {
          totalCount: totalCount,
          content: params.isAppend
            ? [...(current?.content ?? []), ...rows]
            : rows,
        };

        set(datasetObjectData, data);
        set(datasetObjectDataLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function call(params: LoadActivityFeedDataParams) {
    await initLoading();
    await loadDataCallback(params);
  }

  async function reset() {
    await resetCallback();
  }

  return [call, reset] as const;
}

export default useLoadActivityFeedData;
