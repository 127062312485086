import {
  ItemFilterIntersectionFragment,
  LocationFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import useFormatter from '../common/useFormatter';
import { cn } from '../common/utils';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import { PolicyFilterTag } from '../components/policies/PolicyFilterTag';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import { sidebarStateByType } from '../store/sidebar.state';
import {
  filterPresetById,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

export type FilterPresetProps = {
  presetId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;

  canRename?: boolean;
  hasOrder?: boolean;
  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;
};

const FilterPreset: React.FC<FilterPresetProps> = props => {
  const { t } = useTranslation('app');
  const formatter = useFormatter();
  const [preset, updatePreset] = useRecoilState(
    filterPresetById(props.presetId),
  );
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    filterPresetSelectedIdentity,
  );
  const [editorPanelState, setEditorPanelState] = useRecoilState(
    sidebarStateByType('sidebar-filter-preset-editor'),
  );

  function selectProductFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        presetId: preset.id,
        productFilterId: filterIntersectionId,
      });
    }

    if (!editorPanelState.isPinned) {
      setEditorPanelState({
        ...editorPanelState,
        isHidden: !shouldSelect,
        isCollapsed: !shouldSelect,
      });
    }
  }

  function selectLocationFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        presetId: preset.id,
        locationFilterId: filterIntersectionId,
      });
    }

    if (!editorPanelState.isPinned) {
      setEditorPanelState({
        ...editorPanelState,
        isHidden: !shouldSelect,
        isCollapsed: !shouldSelect,
      });
    }
  }

  function addLocationIntersection(
    filter?: LocationFilterIntersectionFragment,
  ) {
    const id = nanoid();
    updatePreset({
      ...preset,
      locations: {
        anyOf: [
          ...(preset.locations?.anyOf ?? []),
          { id, allOf: filter?.allOf ?? [] },
        ],
      },
    });
    selectLocationFilterIntersection(id, true);
  }

  function addProductIntersection(filter?: ItemFilterIntersectionFragment) {
    const id = nanoid();
    updatePreset({
      ...preset,
      items: {
        anyOf: [
          ...(preset.items?.anyOf ?? []),
          { id, allOf: filter?.allOf ?? [] },
        ],
      },
    });
    selectProductFilterIntersection(id, true);
  }

  function renameRule(newTitle: string) {
    updatePreset({
      ...preset,
      title: newTitle,
    });
  }

  const removeField = (groupId: string, fieldId: string) => {
    updatePreset({
      ...preset,
      locations: {
        anyOf: _.map(preset.locations?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
      items: {
        anyOf: _.map(preset.items?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };

  const removeIntersection = (groupId: string) => {
    updatePreset({
      ...preset,
      locations: {
        anyOf: _.filter(preset.locations?.anyOf, fg => fg.id !== groupId),
      },
      items: {
        anyOf: _.filter(preset.items?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  const isActive = selectedIdentity?.presetId === preset.id;
  const isSelectedLocations =
    isActive && !_.isNil(selectedIdentity?.locationFilterId);
  const isSelectedProducts =
    isActive && !_.isNil(selectedIdentity?.productFilterId);

  const containerParams: RuleContainerProps = {
    id: preset.id,
    title: preset.title,
    isCollapsible: true,
    isDisabled: props.isDisabled,
    isRemovable: props.canDelete,
    isActive: isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    hasIcon: true,
    namedColorKey: preset.title,
    hasColorMode: true,

    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    hasOrder: props.hasOrder,
    onTitleChange: title => renameRule(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
  };

  // todo:
  const matchedItemsCount = '...';
  const matchedLocationsCount = '...';

  return (
    <RuleContainer
      dataComponent="FilterPreset"
      {...containerParams}
      classNameInner={cn(
        isActive ? '' : 'bg-sidebar-header/70',
        'p-1 xl:p-2',
        'space-y-2',
      )}
    >
      <PolicyFilterTag className={'space-y-3'}>
        <RuleSectionContainer
          isSelected={isSelectedProducts}
          namedColorKey={isSelectedProducts ? preset.title : null}
          // className={classNames('p-1')}
        >
          <PolicyStatContainer>
            <Stat
              hasNoPadding
              className={cn('px-1 py-1 xl:px-2')}
              transparent
              title={t`Item(s) Filtered`}
              value={matchedItemsCount}
              inPanelMode
              inFilterStat
              toggleTable
              isCheckLoading={false}
              hasTooltip={t('Global Items: {{itemsCount}}', {
                itemsCount: matchedItemsCount,
              })}
              isSelected={isSelectedProducts}

              //isActionable
              //toggleTable
              // onClick={() => {
              //   setSelectedIdentity(
              //     isSelectedProducts
              //       ? null
              //       : {
              //           presetId: preset.id,
              //           filterType: 'items',
              //         },
              //   );
              // }}
            />
          </PolicyStatContainer>
          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={_.map(preset.items?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getProductFilterConfigCommon(t, formatter)}
            selectedId={selectedIdentity?.productFilterId}
            onAddGroup={filterData => addProductIntersection(filterData as any)}
            onDeleteField={removeField}
            onSelectGroup={selectProductFilterIntersection}
            onDeleteGroup={removeIntersection}
            filterGroupType="item"
          />
        </RuleSectionContainer>

        <RuleSectionContainer
          isSelected={isSelectedLocations}
          namedColorKey={isSelectedLocations ? preset.title : null}
        >
          <PolicyStatContainer>
            <Stat
              title={t`Location(s) Filtered`}
              className={cn('px-1 py-1 xl:px-2')}
              value={matchedLocationsCount}
              hasNoPadding
              transparent
              inPanelMode
              inFilterStat
              isCheckLoading={false}
              hasTooltip={t('Global Locations: {{locationsCount}}', {
                locationsCount: matchedLocationsCount,
              })}
              isSelected={isSelectedLocations}

              // isActionable
              // onClick={() => {
              //   setSelectedIdentity(
              //     isSelectedLocations
              //       ? null
              //       : {
              //           presetId: preset.id,
              //           filterType: 'locations',
              //         },
              //   );
              // }}
            />
          </PolicyStatContainer>
          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={_.map(preset.locations?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getLocationFilterConfigCommon(t)}
            selectedId={selectedIdentity?.locationFilterId}
            onAddGroup={f =>
              addLocationIntersection(f as LocationFilterIntersectionFragment)
            }
            onDeleteField={removeField}
            onSelectGroup={selectLocationFilterIntersection}
            onDeleteGroup={removeIntersection}
            filterGroupType="location"
          />
        </RuleSectionContainer>
      </PolicyFilterTag>
    </RuleContainer>
  );
};
export default FilterPreset;
