import {
  ItemFragment,
  ItemUomFragment,
  LoadItemSetDataQuery,
  LoadItemSetDataQueryVariables,
  UomFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type ItemsExportJobParams = ExportJobParams<
  LoadItemSetDataQueryVariables,
  ItemsSetConverterConfig
>;

export const itemsExportJobParams: ItemsExportJobParams = {
  converterId: 'ITEM_SET',
  query: null,
  config: null,
  variables: null,
  filename: 'WareBee-Items.csv',
};

export type ItemSetDataTableRow = _.Omit<ItemFragment, '__typename'> &
  _.Omit<UomFragment, '__typename'>;
export type ItemSetDataTableColumn = keyof ItemSetDataTableRow;

export type ItemsSetConverterConfig = {
  columnsConfig: ColumnConfigBase<ItemSetDataTableRow>[];
};

export const getItemsTableRows = (
  data: ItemUomFragment[],
  config: ItemsSetConverterConfig,
): ItemSetDataTableRow[] => {
  return _.map(data, row => ({
    ...row.item,
    ...row.uom,
  }));
};

export const ITEMS_SET_EXPORT_CONVERTER: ExportConverter<
  LoadItemSetDataQuery,
  ItemsSetConverterConfig
> = (input, config) => {
  const data = getItemsTableRows(input?.itemSet?.itemUoms?.content, config);
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
