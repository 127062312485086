import { AssignmentOccupancyLocationStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../../../../common/formatHelper';
import useFormatter from '../../../../common/useFormatter';
import { Stat } from '../../../../components/stats/Stat';
import { getOccupancyCategories } from '../../../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyCategories,
  assignmentOccupancyCategoryFilter,
} from '../../../store/occupancy/assignmentOccupancy.state';
import { simulationShowDatasetAsTable } from '../../../store/simulation.state';

const OccupancyByCategory: React.FC = () => {
  const { t } = useTranslation('simulation');
  const assignmentOccupancy = useRecoilValue(assignmentOccupancyCategories);
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);

  const [filters, setFilters] = useRecoilState(
    assignmentOccupancyCategoryFilter,
  );
  const formatter = useFormatter();

  const categoryEmpty = _.find(
    assignmentOccupancy?.locationsByOccupancyCategory?.categoriesByStatus,
    c => c.status === AssignmentOccupancyLocationStatus.EMPTY,
  );
  const categoryUnknown = _.find(
    assignmentOccupancy?.locationsByOccupancyCategory?.categoriesByStatus,
    c =>
      c.status ===
        AssignmentOccupancyLocationStatus.PARTIALLY_UNKNOWN_ITEMS_VOLUME ||
      c.status === AssignmentOccupancyLocationStatus.UNKNOWN_ITEMS_VOLUME,
  );
  const categories =
    assignmentOccupancy?.locationsByOccupancyCategory.categories ?? [];

  const categorizedData = [
    ..._.reverse([...categories]),
    categoryEmpty,
    categoryUnknown,
  ];

  function filterByCategory(category: string) {
    if (_.has(filters, category)) {
      setFilters(_.omit(filters, category));
    } else {
      setFilters({
        ...filters,
        [category]: true,
      });
    }
  }

  return (
    <div data-component="OccupancyByCategory" className="w-full space-y-1">
      {_.values(getOccupancyCategories(t)).map((meta, index) => {
        const data = categorizedData[index];

        const volume = formatter.formatVolume(data?.totalStorageVolume);
        const locationCount = formatInteger(data?.count);

        const orderLineStat = t(`{{locationCount}} Locations`, {
          locationCount,
        });

        return (
          <Stat
            key={`product-category-stat-${meta.key}`}
            inPanelMode
            hasHelper
            isActionable
            isPreview
            title={`${meta.title}`}
            value={volume.value}
            unitOfMeasure={volume.unit}
            isFullWidth
            legendColor={[meta.color, meta.textColor]}
            isSelected={_.has(filters, meta.key)}
            onClick={() => {
              filterByCategory(meta.key);
              setShowDatasetTable('locations-stats-all');
            }}
          >
            <div className="opacity-75">
              <div>{orderLineStat}</div>
            </div>
          </Stat>
        );
      })}
    </div>
  );
};

export default OccupancyByCategory;
