import {
  LoadActualityDocument,
  LoadActualityQuery,
  LoadActualityQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { actualityExtraSettingsDefault } from '../store/actuality.default';
import {
  actualityExtraSettings,
  actualitySelectedDocument,
  actualitySelectedDocumentStatus,
  actualitySelectedId,
} from '../store/actuality.state';

export function useLoadActuality() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Could not load Actuality`;

  const cleanup = useRecoilCallback(({ set, reset }) => async () => {
    reset(actualitySelectedId);
    reset(actualitySelectedDocument);
    reset(actualitySelectedDocumentStatus);
    reset(actualityExtraSettings);
  });

  const init = useRecoilCallback(({ set, reset }) => async () => {
    set(actualitySelectedId, null);
    set(actualitySelectedDocument, null);
    set(actualitySelectedDocumentStatus, AsyncLoadStatus.Loading);
  });

  const handleError = useRecoilCallback(
    ({ snapshot, set }) =>
      async (details: string, stack: string) => {
        set(errorAppender, {
          id: nanoid(),
          title: errorTitle,
          details: details,
          callStack: stack,
        });
        set(actualitySelectedDocumentStatus, AsyncLoadStatus.Error);
        return;
      },
  );

  const loadActualityCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (actualityId: string) => {
        try {
          const actualityResponse = await secureClient.query<
            LoadActualityQuery,
            LoadActualityQueryVariables
          >({
            fetchPolicy: 'no-cache',
            query: LoadActualityDocument,
            variables: {
              actualityId: actualityId,
            },
          });

          if (actualityResponse?.errors) {
            handleError(
              null,
              actualityResponse?.errors.map(e => e.message).join('. '),
            );
            return;
          }

          const actuality = actualityResponse.data.actuality;
          set(actualitySelectedId, actuality?.id);
          set(actualitySelectedDocument, actuality);
          set(
            actualityExtraSettings,
            actuality.extraSettings ?? actualityExtraSettingsDefault,
          );
          set(actualitySelectedDocumentStatus, AsyncLoadStatus.Ok);
          return actuality;
        } catch (ex) {
          handleError(ex.message || ex, ex.stack || null);
          return;
        }
      },
  );

  async function load(actualityId: string) {
    await init();
    await loadActualityCallback(actualityId);
  }

  return [load, /*cancel,*/ cleanup] as const;
}
