import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { HeaderAppMenu } from '../components/headers/HeaderAppMenu';
import { Container } from '../components/layout/ContainerFlex';
import ImportJobsView from '../importJobs/ImportJobsView';
import ImportPipelinesView from '../importJobs/ImportPipelinesView';
import ActivityFeedDataTable from './ActivityFeedDataTable';
import {
  datasetObjectDocumentId,
  datasetObjectSelectedMenuItemId,
} from './store/datasetObject.state';

const DatasetObjectDataView: React.FC = () => {
  const { t } = useTranslation('dataset');
  const id = useRecoilValue(datasetObjectDocumentId);
  const stepId = useRecoilValue(datasetObjectSelectedMenuItemId(id));

  const getContent = () => {
    switch (stepId) {
      case 'dataset-object-menu-start':
        return (
          <>
            <HeaderAppMenu title={t`Dataset Data Table`} />
            <Container col overflow flex1>
              <ActivityFeedDataTable id={id} title={t`Feed activities`} />
            </Container>
          </>
        );
      case 'dataset-object-menu-stats':
        return (
          <>
            <HeaderAppMenu title={t`Dataset Data Table`} />
            <Container col overflow flex1>
              <ActivityFeedDataTable id={id} title={t`Feed activities`} />
            </Container>
          </>
        );

      case 'dataset-object-menu-import-jobs':
        return (
          <ImportJobsView
            documentId={id}
            documentImportType={ImportJobImportType.ACTIVITY_FEED}
          />
        );

      case 'dataset-object-menu-import-pipelines':
        return (
          <ImportPipelinesView
            documentId={id}
            documentImportType={ImportJobImportType.ACTIVITY_FEED}
          />
        );
    }
  };

  return (
    <Container col overflow flex1 componentName="DatasetObjectDataView">
      {getContent()}
    </Container>
  );
};

export default DatasetObjectDataView;
