import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { SimulationDashboardCardProps } from './SimulationDashboardCard';

const SimulationDraftCardContent: React.FC<
  SimulationDashboardCardProps
> = props => {
  const { t } = useTranslation('simulation');

  const { sim } = props;
  const notSelectedText = t('Not selected');

  const titleLabelStyle = 'text-app-title/60 !mb-1';
  const statItemListStyle = 'border-b border-app-title/10 pb-2';

  return (
    <StatGroup classNameStatGroupItems="space-y-4">
      <Stat
        cardView
        isPreview
        title={t`Layout`}
        value={sim.layout?.title ?? notSelectedText}
        className={statItemListStyle}
        classTitle={titleLabelStyle}
      />
      <Stat
        cardView
        isPreview
        title={t`Assignment`}
        value={sim.assignment?.title ?? notSelectedText}
        className={statItemListStyle}
        classTitle={titleLabelStyle}
      />
      <Stat
        cardView
        isPreview
        title={t`Orders`}
        value={sim.orderSet?.title ?? notSelectedText}
        className={statItemListStyle}
        classTitle={titleLabelStyle}
      />
      <Stat
        cardView
        isPreview
        title={t`Items`}
        value={sim.itemSet?.title ?? notSelectedText}
        classTitle={titleLabelStyle}
      />
    </StatGroup>
  );
};

export default SimulationDraftCardContent;
