import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import ErrorIndicator from '../../../components/ErrorIndicator';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  simulationLayoutSelectedOrderId,
  simulationLayoutSelectedPicklistId,
  simulationLayoutSelectedRoutePart,
  simulationLayoutSelectedRoutePartZoom,
} from '../../store/simulation.layout.state';
import {
  simulationAnalyzeId,
  simulationAnalyzeOrderDetails,
  simulationAnalyzeOrderDetailsLoadStatus,
} from '../../store/simulation.state';
import OrderPicklists from './OrderPicklists';

const AnalyzeOrderPicklists: React.FC = () => {
  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const orderId = useRecoilValue(simulationLayoutSelectedOrderId);
  const orderDetails = useRecoilValue(simulationAnalyzeOrderDetails);
  const orderDetailsStatus = useRecoilValue(
    simulationAnalyzeOrderDetailsLoadStatus,
  );
  const { t } = useTranslation('simulation');

  if (
    orderDetailsStatus === AsyncLoadStatus.None ||
    orderDetailsStatus === AsyncLoadStatus.Loading
  ) {
    return (
      <LoadingIndicator
        className="p-4"
        selfCenter
        message={t`Loading Picklists`}
      />
    );
  }

  if (orderDetailsStatus === AsyncLoadStatus.Error) {
    return (
      <ErrorIndicator
        className="p-4"
        selfCenter
        message={t`Cannot load Picklists, please contact support`}
      />
    );
  }

  return (
    <OrderPicklists
      orderId={orderId}
      analyzeId={analyzeId}
      picklists={orderDetails?.picklists ?? []}
      selectedPicklist={simulationLayoutSelectedPicklistId}
      setRoutePart={simulationLayoutSelectedRoutePart}
      setRoutePartWithZoom={simulationLayoutSelectedRoutePartZoom}
    />
  );
};

export default AnalyzeOrderPicklists;
