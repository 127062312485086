import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../AnalyticTracker';
import { cn } from '../common/utils';
import DropdownSelector from './actions/DropdownSelector';
import * as Icon from './icons';

export type FilterTagWithSearchProps<T> = {
  selected?: T;
  values: T[];
  isLoading?: boolean;
  onSearch: (value: string) => Promise<void>;
  onChange: (value: T) => Promise<void>;
};

const FilterTagWithSearch: React.FC<FilterTagWithSearchProps<any>> = props => {
  const { t } = useTranslation('app');
  const type = 'filter';
  const TypeIcon = type === 'filter' ? Icon.Search : Icon.CursorClick;

  // border-dashed
  const typeClass =
    type === 'filter'
      ? 'text-menu-active border border-menu-active'
      : 'border border-menu-active bg-app-panel-dark/60 flex items-center px-2 py-0.5 rounded text-menu-active';

  const typeIconClass =
    type === 'filter' ? 'h-3 w-3 fill-current' : 'h-4 w-4 fill-current';

  function remove() {
    if (!_.isFunction(props.onChange)) return;
    props.onChange(null);
    try {
      analyticsAgent?.track(`LayoutFilter: Deselect ${type}`);
    } catch (ex) {
      console.debug('analyticsAgent Error:', ex);
    }
  }

  return (
    <div
      data-component={`FilterTagWithSearch`}
      className={classNames(
        typeClass,
        'cursor-pointer rounded',
        'group flex items-center',
        'py-1px ltr:pl-2 ltr:pr-0.5 rtl:pl-0.5 rtl:pr-2',
        'bg-app-panel-dark/60',
        'rounded',
        'text-xxs md:text-xs',
      )}
    >
      <div
        data-component="FilterControl"
        className={classNames('ltr:mr-1 rtl:ml-1')}
      >
        <div
          className="block hover:hidden group-hover:hidden"
          title="hide filter"
        >
          <TypeIcon className={classNames(typeIconClass)} />
        </div>
        <div
          className="hidden group-hover:block"
          title="show filter"
          onClick={remove}
        >
          <Icon.CircleX className={classNames('h-4 w-4', typeIconClass)} />
        </div>
      </div>
      {/* className={classNames('flex flex-1 ltr:mr-1 rtl:ml-1')} */}
      <DropdownSelector
        classNameDropdown={cn(
          'border-menu-active',
          'z-[5002]',
          'backdrop-filter backdrop-blur',
        )}
        className={classNames(
          'backdrop-blur backdrop-filter',
          'bg-app-panel-dark',
          'min-w-32 max-w-44',
          'flex-1',
          'z-[5002]',
        )}
        // buttonTransparent
        values={props.values}
        placeholder={t`Find location`}
        value={props.selected}
        onChange={v => props.onChange(v)}
        hasAsyncSearch
        // asyncSearchValue={search}
        onAsyncSearch={props.onSearch}
        isLoading={props.isLoading}
      />
    </div>
  );
};

export default FilterTagWithSearch;
