import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import StaticBorderFeature from '../../features/StaticBorderFeature';
import { getPoints } from '../store/viewer.helper';
import {
  viewerHoveredAisle,
  viewerHoveredBay,
  viewerHoveredLocation,
  viewerLevelLocations,
} from '../store/viewer.state';

const HoveredFeatureLayer: React.FC = () => {
  const selectedAisleLoadable = useRecoilValueLoadable(viewerHoveredAisle);
  const selectedBayLoadable = useRecoilValueLoadable(viewerHoveredBay);
  const selectedLocation = useRecoilValue(viewerHoveredLocation);
  const levelLocationLoadable = useRecoilValueLoadable(viewerLevelLocations);
  const isReady =
    selectedAisleLoadable.state === 'hasValue' &&
    selectedBayLoadable.state === 'hasValue' &&
    levelLocationLoadable.state === 'hasValue';

  if (!isReady) return null;
  const selectedAisle = selectedAisleLoadable.getValue();
  const selectedBay = selectedBayLoadable.getValue();
  const shape =
    selectedLocation?.shape ?? selectedBay?.shape ?? selectedAisle?.shape;

  if (_.isEmpty(shape)) return null;

  return (
    <Layer>
      <StaticBorderFeature points={_.flatten(getPoints(shape))} />
    </Layer>
  );
};

export default HoveredFeatureLayer;
