import * as React from 'react';
const LinkArrowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkArrowIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="105.35 44.63 125.84 65.23 107.54 83.4 94.38 83.35 108.36 69.48 2.35 69.39 2.37 60.79 108.38 60.88 92.19 44.59 105.35 44.63"
    />
    <circle cx={27.76} cy={63.97} r={18.51} strokeWidth={0} />
    <circle cx={73.44} cy={63.97} r={18.51} strokeWidth={0} />
  </svg>
);
export default LinkArrowIcon;
