import { useRecoilValue } from 'recoil';
import ViewModeToolbar from '../../layout/toolbar/ViewModeToolbar';
import { simulationIssuesCountByLevel } from '../store/simulation.issues.state';

const SimulationViewModeToolbar: React.FC = () => {
  const issuesMap = useRecoilValue(simulationIssuesCountByLevel);

  return <ViewModeToolbar levelIssues={issuesMap} />;
};

export default SimulationViewModeToolbar;
