import * as React from 'react';
const BoxMinusIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BoxMinusIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4L12.04,34v60l51.96,30,51.96-30v-60S64,4,64,4ZM88.04,67.85h-48.08c-2.12,0-3.85-1.72-3.85-3.85s1.72-3.85,3.85-3.85h48.08c2.12,0,3.85,1.72,3.85,3.85s-1.72,3.85-3.85,3.85Z"
    />
  </svg>
);
export default BoxMinusIcon;
