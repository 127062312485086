import classNames from 'classnames';
import React from 'react';

export type TooltipItemProps = {
  children?: React.ReactNode;
  title: string;
  value: string | number;
  color?: string;
  backgroundColor?: string;
  isActive?: boolean;
  tooltipLegend?: boolean;
};

export const TooltipItem: React.FC<TooltipItemProps> = ({
  children,
  title,
  value,
  color,
  backgroundColor,
  isActive,
  tooltipLegend,
}) => {
  return (
    <div
      key={`TooltipItem-${title}-${value}`}
      id={`TooltipItem-${title}-${value}`}
      className={classNames(
        'flex items-center py-2 text-sm ltr:pl-1 ltr:pr-3 rtl:pr-1 rtl:ltr:pl-3',
        tooltipLegend ? '' : 'text-menu-text',
      )}
    >
      {tooltipLegend && (
        <div
          data-component="tooltipLegend"
          className={classNames(
            'h-3 w-3 rounded-full lg:h-4 lg:w-4 ltr:mr-2 rtl:ml-2',
            isActive ? '' : 'border-2',
            backgroundColor ? '' : 'border-menu border-4',
          )}
          style={{
            borderColor: isActive ? color : '#1f2326',
            background: backgroundColor,
          }}
        />
      )}
      <span
        style={{
          color: isActive ? color : null,
        }}
        className="flex-1"
      >
        {title}
      </span>
      <span className={classNames('ltr:ml-3 rtl:mr-3')}>{value}</span>
      {children}
    </div>
  );
};
