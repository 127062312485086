import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import {
  HeatmapBucket,
  HeatmapBucketSortDirection,
  HeatmapBucketStatField,
  getHeatmapBucketTitle,
} from '../../common/heatmap.helper';
import { HeatmapMetricDescriptor } from '../../common/heatmap.types';
import { cn } from '../../common/utils';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ActionBar } from '../../components/nav/ActionBar';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import useShowFilteredActualityHqTable from '../hqTable/useShowFilteredActualityHqTable';
import {
  actualityHeatmapBucketsSortBy,
  actualityHeatmapBucketsSortDirection,
  actualityHeatmapFilters,
  actualityMetricHasStats,
} from '../store/actuality.heatmap.state';
import { actualityDataSetTableTypeSelected } from '../store/actuality.state';
import FeedStockPanel from './FeedStockPanel';

export type HeatmapLegendPanelProps = {
  metricDescriptor: HeatmapMetricDescriptor<string, Record<string, any>>;
  buckets: HeatmapBucket[];
  hasStats: boolean;
};

const HeatmapLegendPanel: React.FC<HeatmapLegendPanelProps> = props => {
  const { t } = useTranslation('feed');
  const { metricDescriptor, buckets } = props;

  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    actualityHeatmapFilters,
  );
  const [sortBy, setSortBy] = useRecoilState(actualityHeatmapBucketsSortBy);
  const [sortDirection, setSortDirection] = useRecoilState(
    actualityHeatmapBucketsSortDirection,
  );
  const showDataTable = useShowFilteredActualityHqTable();
  const bucketsWithStats = useRecoilValue(actualityMetricHasStats);
  const setExtraDatasetTable = useSetRecoilState(
    actualityDataSetTableTypeSelected,
  );
  const [allSelected, setAllSelected] = useState(true);

  const sortDirectionDropdownOptions: {
    label: string;
    value: HeatmapBucketSortDirection;
    icon: React.FC;
  }[] = [
    { label: t('Lowest'), value: 'asc', icon: Icon.SortAscending },
    { label: t('Highest'), value: 'desc', icon: Icon.SortDescending },
  ];

  const sortFieldOptions: {
    key: HeatmapBucketStatField;
    label: string;
    unitOfMeasure?: string;
  }[] = [{ key: null, label: t`Title`, unitOfMeasure: t`events` }];

  if (bucketsWithStats) {
    sortFieldOptions.push(
      { key: 'eventsCount', label: t`Events`, unitOfMeasure: t`events` },
      { key: 'assignmentsCount', label: t`Items`, unitOfMeasure: t`items` },
      {
        key: 'totalExistedLocations',
        label: t`Locations`,
        unitOfMeasure: t`locations`,
      },
    );
  }

  const sortFieldOptionsMap = _.keyBy(sortFieldOptions, f => f.key);

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [heatmapFilter, props.buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: value,
      },
    });
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      props.buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  // Add a useEffect hook to track if even one bucket is deselected.
  useEffect(() => {
    // Check if any bucket's hidden status is true (i.e., it's deselected).
    const anyDeselected = Object.values(heatmapFilter.hiddenBuckets).some(
      hidden => hidden,
    );

    if (anyDeselected) {
      // If even one bucket is deselected, set allSelected to false.
      setAllSelected(false);
    } else {
      // If all buckets are selected, set allSelected to true.
      setAllSelected(true);
    }
  }, [heatmapFilter.hiddenBuckets]); // Add heatmapFilter.hiddenBuckets as a dependency.

  const toggleSelectAllBuckets = () => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: allSelected, // If allSelected is true, deselect all. If allSelected is false, select all.
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        isSticky
        title={`${metricDescriptor?.title ?? '—'} ${
          buckets?.length > 1 ? `(${buckets?.length})` : ''
        }`}
        subtitle={t`Heatmaps`}
        helpNavTo={'actuality/actuality-kpi'}
      />
      <ActionBar
        transparent
        className={cn(
          'p-2',
          'bg-app-panel-light/40',
          'saturate-110 backdrop-blur',
          'xl:top-18 sticky top-16',
          'z-header_screen',
          'gap-2',
        )}
      >
        <fieldset className={cn('flex flex-1 items-center')}>
          <DropdownSelector
            className={cn(
              //'flex-1',
              'text-xs',
            )}
            value={sortBy}
            values={_.map(sortFieldOptions, o => o.key)}
            renderValue={sortBy => sortFieldOptionsMap[sortBy]?.label}
            onChange={sortBy => setSortBy(sortBy)}
            light
            widthFull
          />
          <DropdownSelector
            className={cn('text-xs')}
            classNameValue={cn('!rounded-r-md')}
            DropAlignRight
            value={sortDirection}
            values={_.map(sortDirectionDropdownOptions, option => option.value)}
            renderValue={value =>
              sortDirectionDropdownOptions.find(
                option => option.value === value,
              )?.label || value
            }
            onChange={v => setSortDirection(v)}
            light
            iconValue={
              sortDirection === 'asc' ? Icon.SortDescending : Icon.SortAscending
            }
            iconClass="w-4 h-4"
          />
        </fieldset>

        <DropdownSelector
          classNameLabel="text-sm"
          onClick={e => {
            e.stopPropagation();
          }}
          DropAlignRight
          buttonTransparent
          vertical
          panelMode
          value={'...'}
          values={[allSelected ? t`Hide All` : t`Show All`]}
          onChange={async (option, e) => {
            toggleSelectAllBuckets();
          }}
        />
      </ActionBar>
      <StatGroup
        classNameStatGroupItems={classNames('!space-y-0.5 !mt-0 !pt-0')}
      >
        {metricDescriptor?.type === 'abc' ? (
          <FeedStockPanel />
        ) : _.isEmpty(buckets) ? (
          <InboxZero selfCenter hasIcon>{t`No Items`}</InboxZero>
        ) : (
          _.map(buckets, bucket => {
            const isHidden =
              !allSelected && heatmapFilter.hiddenBuckets[bucket.id] === true;

            const hasIssue = bucket.title === 'undefined';
            const hasNoActivity =
              metricDescriptor?.titleCategory === 'Time' &&
              bucket.title === '0';

            let title;
            if (hasIssue) {
              title = t`Unknown`;
            } else if (bucket.title === '0') {
              if (
                // metricDescriptor?.type === 'lastActivity' ||
                metricDescriptor?.titleCategory === 'Time'
              ) {
                title = t`No Activity`;
              } else if (metricDescriptor?.type === 'visits') {
                title = t`0 Visits`;
              } else {
                title = t`0`;
              }
            } else {
              title = getHeatmapBucketTitle(
                bucket,
                metricDescriptor,
                t`Unknown`,
              );
            }

            const dataField = sortBy ?? 'eventsCount';
            const statValueRaw = bucket.stats?.[dataField];
            const statValue = _.isNil(statValueRaw)
              ? null
              : statValueRaw === 0
                ? t`Blank`
                : formatInteger(statValueRaw);

            const hasDataAction =
              metricDescriptor?.rangeType === 'distinct' &&
              metricDescriptor?.type !== 'abc';

            const unitOfMeasureLabel =
              sortFieldOptionsMap[sortBy]?.unitOfMeasure ?? null;

            return (
              <Stat
                key={`legend-stat-bucket-${bucket.id}`}
                title={title}
                legendColor={[bucket.color, bucket.textColor]}
                isActionable
                isSelectable
                inPanelMode
                isPreview
                toggleTable
                isSelected={!isHidden}
                toggleIconOpen={() => null}
                toggleIconClosed={() => null}
                onClick={evt => {
                  debouncedClick(bucket.id, !isHidden, evt);
                }}
                unitOfMeasure={unitOfMeasureLabel}
                hasIssue={hasIssue}
                switchUnitOfMeasure={
                  hasDataAction && (
                    <Button
                      className={classNames(
                        'border-menu/50 rounded border !p-1',
                      )}
                      buttonIcon={
                        <Icon.ShowTable
                          className={classNames(
                            'fill-current',
                            'group-hover:opacity-50',
                            'h-5 w-5 md:h-6 md:w-6',
                            // 'xl:w-8 xl:h-8',
                          )}
                        />
                      }
                      // buttonType="secondary"
                      buttonSize="xs"
                      isTransparent
                      hasIconAfter
                      onPress={() => {
                        showDataTable({
                          [metricDescriptor.path]: bucket.id,
                        });
                        setExtraDatasetTable('activity-metric');
                      }}
                    />
                  )
                }
                value={statValue}
              ></Stat>
            );
          })
        )}
      </StatGroup>
    </Container>
  );
};

export default HeatmapLegendPanel;
