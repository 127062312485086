// import jsPDF from 'jspdf';
// import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { exportStageSVG } from 'react-konva-to-svg';
import { useRecoilState } from 'recoil';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { ContainerMain } from '../../components/layout/ContainerMain';
import { ContainerMap } from '../../components/layout/ContainerMap';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../LayoutActiveFeatureTag';
import LayoutAlertsBase from '../alerts/LayoutAlertsBase';
import StageContainer from '../stage/StageContainer';
import { layoutLocationColorMode } from '../store/layout.state';
import { LocationColorMode } from '../store/layout.types';
import LocationOrderToolbar from '../toolbar/LocationOrderToolbar';
import ViewModeToolbar from '../toolbar/ViewModeToolbar';
import LayoutWatcher from '../watcher/LayoutWatcher';
import ActiveFeatureLayer from './layers/ActiveFeatureLayer';
import AisleLayer from './layers/AisleLayer';
import BayLayer from './layers/BayLayer';
import HoveredFeatureLayer from './layers/HoveredFeatureLayer';
import HtmlTooltipLayer from './layers/HtmlTooltipLayer';
import PortalLayer from './layers/PortalLayer';
import RoutesLayer from './layers/RoutesLayer';
import ViewerLocationLayer from './layers/ViewerLocationLayer';

export type LayoutContainerProps = {};

const ViewerLayout: React.FC<LayoutContainerProps> = props => {
  const { t } = useTranslation('app');
  const [selectedMode, setSelectedMode] = useRecoilState(
    layoutLocationColorMode,
  );

  const viewOptions: { key: LocationColorMode; label: string }[] = [
    { key: 'default', label: t`Default` },
    { key: 'sortOrder', label: t`Pick Order heatmap` },
  ];
  // const stageRef = useRef<Konva.Stage>(null);

  // const exportMapAsPDF = async () => {
  //   const stage = stageRef.current;
  //   if (stage) {
  //     // Export the stage to SVG using react-konva-to-svg
  //     const svgString = await exportStageSVG(stage, false);
  //     const svgElement = new DOMParser().parseFromString(
  //       svgString,
  //       'image/svg+xml',
  //     ).documentElement;

  //     // Create a new jsPDF instance
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'pt',
  //       format: [stage.width(), stage.height()],
  //     });

  //     // Use svg2pdf to convert the SVG element to PDF
  //     await svg2pdf(svgElement, pdf, {
  //       x: 0,
  //       y: 0,
  //       width: stage.width(),
  //       height: stage.height(),
  //     });

  //     // Save the PDF
  //     pdf.save('layout-map.pdf');
  //   }
  // };

  // const exportAsPDFImage = () => {
  //   const stage = stageRef.current;
  //   if (stage) {
  //     const scale = 7; // Adjust the scale for higher resolution
  //     const dataURL = stage.toDataURL({ pixelRatio: scale });
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'px',
  //       format: [stage.width(), stage.height()],
  //     });
  //     pdf.addImage(dataURL, 'PNG', 0, 0, stage.width(), stage.height());
  //     pdf.save('layout-map.pdf');
  //   }
  // };

  return (
    <ContainerMain className="feed-container feed-mode-select">
      <ContainerMap
        headerTitle={t`Layout Map`}
        className="bg-app-panel-dark/60"
        titleExtra={
          <>
            <Spacer flexspace />
            <LayoutActiveFeatureTag />
            <ButtonSwitchMulti
              autoSize
              className="mx-3"
              classNameLabel="text-xs py-1 px-2"
              buttonType="header"
              options={viewOptions}
              selectedIndex={_.findIndex(
                viewOptions,
                o => o.key === selectedMode,
              )}
              onClick={index => setSelectedMode(viewOptions[index].key)}
            />
            {/* <Button onPress={exportMapAsPDF}>Export PDF</Button> */}
            {/* <Button onPress={exportAsPDFImage}>Export</Button> */}
          </>
        }
      >
        {/* <StageContainer type="viewer-area-view" stageRef={stageRef}> */}
        <StageContainer type="viewer-area-view">
          <AisleLayer />
          <BayLayer />
          <ViewerLocationLayer />
          <PortalLayer />
          <RoutesLayer />
          <ActiveFeatureLayer />
          <HoveredFeatureLayer />
        </StageContainer>
        <LayoutAlertsBase />
        <HtmlTooltipLayer />
        <ViewModeToolbar />
        <LocationOrderToolbar />
      </ContainerMap>
      <LayoutWatcher
        stageId="viewer-area-view"
        sidebarIds={[
          'sidebar-layout-common-info',
          'sidebar-layout-info-location',
        ]}
      />
    </ContainerMain>
  );
};

export default ViewerLayout;
