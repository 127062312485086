import _ from 'lodash';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useResizeObserver from 'use-resize-observer';
import InboxZero from '../../components/InboxZero';
import { StageType } from '../stage/stage.helper';
import { stagePointerPosition, stageStateById } from '../stage/stage.state';

export type TooltipWrapperProps = PropsWithChildren & {
  stageId: StageType;
};

const TooltipWrapper: React.FC<TooltipWrapperProps> = props => {
  const { t } = useTranslation('app');
  const position = useRecoilValue(stagePointerPosition(props.stageId));
  const stageState = useRecoilValue(stageStateById(props.stageId));
  const [tooltipSize, setTooltipSize] = useState<[w: number, h: number]>([
    0, 0,
  ]);
  const onResize = useMemo(
    () =>
      _.debounce(({ width, height }) => {
        if (!width || !height) return;
        setTooltipSize([width, height]);
      }, 200),
    [setTooltipSize],
  );
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize,
  });
  useEffect(() => {
    return () => {
      onResize.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [w, h] = stageState.size;

  const defaultXOffset = 20;
  const tooltipOffset = [
    defaultXOffset,
    Math.min(h - position[1] - tooltipSize[1], 0),
  ];

  const tooltipSizeWithOffset = [
    tooltipSize[0] + tooltipOffset[0],
    tooltipSize[1] + tooltipOffset[1],
  ];

  const tooltipX =
    w - position[0] > tooltipSizeWithOffset[0] ||
    position[0] < tooltipSizeWithOffset[0]
      ? position[0] + tooltipOffset[0]
      : position[0] - tooltipSize?.[0] - tooltipOffset[0];

  const tooltipY = position[1] + tooltipOffset[1];
  // h - position[1] > tooltipSizeWithOffset[1] ||
  // position[1] < tooltipSizeWithOffset[1]
  //   ? position[1] + tooltipOffset[1]
  //   : position[1] - tooltipSize?.[1] - tooltipOffset[1];

  // do not show tooltip before tooltip size is set
  const visible = tooltipSize[0] !== 0 && tooltipSize[1] !== 0;
  // console.log('tooltipSize', tooltipSize, visible, tooltipX, tooltipY);
  return (
    <div
      ref={ref}
      data-component={'tooltip-helper'}
      style={{
        position: 'absolute',
        left: tooltipX,
        top: tooltipY,
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      <ErrorBoundary
        fallbackRender={() => (
          <InboxZero message={t`Apologies, something went wrong`} />
        )}
      >
        {props.children}
      </ErrorBoundary>
    </div>
  );
};

export default TooltipWrapper;
