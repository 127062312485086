import { OptimizationRunLogRecordDetails } from '@warebee/shared/engine-model';
import { MappingSchema } from '@warebee/shared/import-converter';

export class OptimizationRunLogRecord {
  optimizationRunId: string;
  executionId: string;
  step: number;

  prevStateId?: string;
  proposedStateId: string;

  proposedCost: number;
  costChange: number;

  proposedPrimaryCost: number;
  primaryCostChange: number;

  accepted: boolean;
  improves: boolean;

  swapSrcItemId?: string;
  swapSrcConsignee?: string;
  swapSrcSku?: string;
  swapSrcLocationId?: string;

  swapDestItemId?: string;
  swapDestConsignee?: string;
  swapDestSku?: string;
  swapDestLocationId?: string;

  timeGenerated: number;
  timeApplied: number;

  affectedItems: number;
  affectedUows: number;

  details?: OptimizationRunLogRecordDetails;
}

export const OPTIMIZATION_RUN_LOG_SCHEMA: MappingSchema<OptimizationRunLogRecord> =
  {
    partitionBy: [{ key: 'optimizationRunId' }, { key: 'executionId' }],
    fields: [
      {
        name: 'optimizationRunId',
        type: 'string',
      },
      {
        name: 'executionId',
        type: 'string',
      },
      {
        name: 'step',
        type: 'integer',
      },
      {
        name: 'prevStateId',
        type: 'string',
        optional: true,
      },
      {
        name: 'proposedStateId',
        type: 'string',
      },
      {
        name: 'proposedCost',
        type: 'number',
      },
      {
        name: 'costChange',
        type: 'number',
      },
      {
        name: 'proposedPrimaryCost',
        type: 'number',
      },
      {
        name: 'primaryCostChange',
        type: 'number',
      },
      {
        name: 'swapSrcItemId',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapSrcConsignee',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapSrcSku',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapSrcLocationId',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapDestItemId',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapDestConsignee',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapDestSku',
        type: 'string',
        optional: true,
      },
      {
        name: 'swapDestLocationId',
        type: 'string',
        optional: true,
      },
      {
        name: 'accepted',
        type: 'boolean',
      },
      {
        name: 'improves',
        type: 'boolean',
      },
      {
        name: 'timeGenerated',
        type: 'number',
      },
      {
        name: 'timeApplied',
        type: 'number',
      },
      {
        name: 'affectedItems',
        type: 'number',
      },
      {
        name: 'affectedUows',
        type: 'number',
      },
      {
        name: 'details',
        type: 'string',
        optional: true,
      },
    ],
  };
