import classNames from 'classnames';
import * as React from 'react';

export type LoaderProps = {
  children?: React.ReactNode;
  className?: string;
  size?: number;
  stroke?: number;
  type?:
    | 'loading-circle'
    | 'loading-dots'
    | 'loading-dashed'
    | 'loading-logo'
    | 'loading-original'
    | 'loading-path';
};

export const Loader = ({
  children,
  className,
  size,
  stroke,
  type,
  ...props
}: LoaderProps) => {
  const viewBoxSize = size / 2;
  const viewBox = `-${viewBoxSize} -${viewBoxSize} ${size} ${size}`;
  const halfStroke = stroke / 2;
  const centerX = 0;
  const centerY = 0;
  const radius = size / 2 - halfStroke;
  const circumference = 2 * Math.PI * radius;

  return (
    <>
      {/* <span style={{ width: 'inherit', height: 'inherit' }}> */}
      {type === 'loading-circle' && (
        <svg
          {...props}
          className={classNames(
            'overflow-visible fill-current stroke-current',
            className,
          )}
          viewBox={viewBox}
          width={size}
          height={size}
          id="loading-circle"
        >
          <circle
            className="base-circle"
            fill="none"
            cx={centerX}
            cy={centerY}
            r={radius}
            strokeWidth={stroke}
            opacity={0.3}
          />
          <circle
            className="value-circle"
            stroke="url(#spinner-gradient)"
            fill="none"
            cx={centerX}
            cy={centerY}
            r={radius}
            strokeWidth={stroke}
            strokeDashoffset={(7 / 10) * circumference}
            strokeDasharray={circumference}
          >
            <animateTransform
              attributeName="transform"
              dur="0.7s"
              type="rotate"
              from="0 0 0"
              to="360 0 0"
              repeatCount="indefinite"
            />
          </circle>
          <defs>
            <linearGradient id="spinner-gradient">
              <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
              <stop offset="30%" stopOpacity="1" stopColor="currentColor" />
              <stop offset="50%" stopOpacity="1" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="1" stopColor="currentColor" />
            </linearGradient>
          </defs>
        </svg>
        // <svg
        //   {...props}
        //   data-component="Loader"
        //   id="loader-indicator"
        //   xmlns="http://www.w3.org/2000/svg"
        //   x="0px"
        //   y="0px"
        //   width={size}
        //   height={size}
        //   // style={{ width: 'inherit', height: 'inherit' }}
        //   viewBox={`0 0 ${size * 2} ${size * 2}`}
        //   className={classNames('fill-current stroke-current', className)}
        //   preserveAspectRatio="xMinYMin meet"
        // >
        //   {/* <path
        //   xmlns="http://www.w3.org/2000/svg"
        //   id="MyPath"
        //   d="m 100,75 a 25,25 0 0 1 0,50  25,25 0 0 1 0 -50 z"
        // /> */}

        //   <defs>
        //     <linearGradient id="spinner-gradient">
        //       <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
        //       <stop offset="30%" stopOpacity="1" stopColor="currentColor" />
        //       <stop offset="50%" stopOpacity="1" stopColor="currentColor" />
        //       <stop offset="100%" stopOpacity="1" stopColor="currentColor" />
        //     </linearGradient>
        //   </defs>
        //   <circle
        //     fill="none"
        //     strokeWidth={width}
        //     cx="50%"
        //     cy="50%"
        //     r={size}
        //     opacity={0.3}
        //   />
        //   <circle
        //     fill="none"
        //     stroke="url(#spinner-gradient)"
        //     strokeWidth={width}
        //     // stroke-miterlimit="15"
        //     strokeDasharray="50,200"
        //     strokeDashoffset="100"
        //     strokeLinecap="round"
        //     cx="50%"
        //     cy="50%"
        //     r={size}
        //   >
        //     <animateTransform
        //       attributeName="transform"
        //       dur="0.7s"
        //       type="rotate"
        //       from="0 50 48"
        //       to="360 50 52"
        //       repeatCount="indefinite"
        //     />
        //   </circle>
        // </svg>
      )}

      {type === 'loading-path' && (
        <svg
          {...props}
          data-component="Loader"
          id="loader-indicator"
          xmlns="http://www.w3.org/2000/svg"
          // x="0px"
          // y="0px"
          // width={size}
          // height={size}
          viewBox={`0 0 100 100`}
          className={classNames(
            'overflow-visible fill-current stroke-current',
            className,
          )}
        >
          <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      )}

      {type === 'loading-dashed' && (
        <svg
          {...props}
          data-component="Loader"
          id="loader-indicator"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={size}
          height={size}
          viewBox={`0 0 100 100`}
          className={classNames('fill-current stroke-current', className)}
        >
          <circle
            fill="none"
            strokeWidth="6"
            strokeMiterlimit="15"
            strokeDasharray="14.2472,14.2472"
            cx="50"
            cy="50"
            r={size}
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="5s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      )}

      {type === 'loading-original' && (
        <svg
          {...props}
          viewBox={`0 0 40 40`}
          id="loading-original"
          width={size}
          height={size}
          className={classNames(
            'overflow-visible fill-current stroke-current',
            className,
          )}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      )}
      {type === 'loading-dots' && (
        <svg
          // version="1.1"
          // id="L4"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMinYMin meet"
          // enable-background="new 0 0 0 0"
          className={classNames(
            'overflow-visible fill-current stroke-current',
            className,
          )}
        >
          <circle cx="6" cy="50" r={size}>
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle cx="26" cy="50" r={size}>
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle cx="46" cy="50" r={size}>
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      )}
      {/* </span> */}
    </>
  );
};

export default Loader;
