import { AssignmentPolicyImportLineInput } from '@warebee/shared/data-access-api-dto';
import { ASSIGNMENT_POLICY_MAPPING_SCHEMA } from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { MappingSettings } from '../../store/import.types';
import { consigneeAliases, skuAliases } from '../../store/sharedAliases';

export function getAssignmentPolicySchema(
  t: TFunction<'importer'>,
): MappingSettings<AssignmentPolicyImportLineInput> {
  const baseFields = keyBy(
    ASSIGNMENT_POLICY_MAPPING_SCHEMA.fields,
    f => f.name,
  );

  return {
    fields: [
      {
        ...baseFields['consignee'],
        title: t(`Client (Consignee)`, { ns: 'importer' }),
        description: t(
          `Client (consignee) — Name of your company or your client (if you are a Logistics Services Provider)`,
          { ns: 'importer' },
        ),
        aliases: consigneeAliases,
      },
      {
        ...baseFields['sku'],
        title: t(`Item`, { ns: 'importer' }),
        description: t(`Item code (SKU)`, { ns: 'importer' }),
        aliases: skuAliases,
      },
      {
        ...baseFields['skuGroup'],
        title: t(`Item Group`, { ns: 'importer' }),
        description: t(`Item Group name or group id`, { ns: 'importer' }),
        aliases: [
          'skugroup',
          'itemgroup',
          'itmgroup',
          'productgroup',
          'group',
          'series',
          'groupid',
          'productgroupid',
          'itmtype',
          'itm_type',
          'type',
        ],
      },
      {
        ...baseFields['subGroup'],
        title: t('Item Sub-Group', { ns: 'importer' }),
        description: t(
          'Item Sub Group, or any additional product category, or classification',
          { ns: 'importer' },
        ),
        example: 'Sub Group A',
        aliases: [
          'subGroup',
          'sub_group',
          'item_sub_group',
          'inventory_sub_group',
          'label_group',
          'additionalarticlegroups',
        ],
      },
      {
        ...baseFields['transportClass'],
        title: t('Item Transport Class', { ns: 'importer' }),
        description: t('Item Transport Class', { ns: 'importer' }),
        example: '01-A',
        aliases: [
          'transportClass',
          'transport_class',
          'transport',
          'transport_type',
          'transporttype',
          'transport_id',
          'transportid',
          'trans',
          'trans_id',
          'transid',
          'pick_sequence_sku',
          'picksequencesku',
          'picksequence',
        ],
      },
      {
        ...baseFields['stockCategory'],
        title: t('Item Stock category', { ns: 'importer' }),
        description: t('Special stock indicator and number', {
          ns: 'importer',
        }),
        example: '1-LIGHT',
        aliases: [
          'stockCategory',
          'cat',
          'stock_cat',
          'stock_category',
          'stock_id',
          'packingCategory',
        ],
      },
      {
        ...baseFields['storageClass'],
        title: t('Storage class', { ns: 'importer' }),
        description: t('Storage Class/type, Packing Group, DOT Code, etc.', {
          ns: 'importer',
        }),
        example: '1-CLASS',
        aliases: [
          'storageClass',
          'class',
          'storage_class',
          'warehouse_sku_storage_type',
          'sku_storage_type',
          'storage_type',
          'handlingclass',
          'storage',
          'storage_id',
          'type',
        ],
      },
      {
        ...baseFields['pollutionClass'],
        title: t('Pollution class', { ns: 'importer' }),
        description: t(
          'Hazardous Material Classifications or Water pollution class (Hazmat SKU Label)',
          {
            ns: 'importer',
          },
        ),
        example: '3 Class 3: Flammable Liquid',
        aliases: [
          'pollutionClass',
          'pollution_class',
          'pollution',
          'hazard_class',
          'hazardclass',
          'hazmat',
          'material_class',
          'dot',
          'dot_code',
          'dot_class',
        ],
      },
      // Locations
      {
        ...baseFields['locationFrom'],
        title: t('Location Range - Start', { ns: 'importer' }),
        description: t('Location Name, typically in the format AA010101', {
          ns: 'importer',
        }),
        aliases: [
          'locationfrom',
          'locfrom',
          'from',
          'fromlocation',
          'fromloc',
          'locationstart',
          'locstart',
          'start',
          'startloc',
          'startlocation',
        ],
      },
      {
        ...baseFields['locationTo'],
        title: t('Location Range - To', { ns: 'importer' }),
        description: t('Location Name, typically in the format AA010101', {
          ns: 'importer',
        }),
        aliases: [
          'locationto',
          'locto',
          'to',
          'tolocation',
          'toloc',
          'locationend',
          'locend',
          'end',
          'endlocation',
          'endloc',
        ],
      },
      {
        ...baseFields['aisleFrom'],
        title: t('Aisle Range - From', { ns: 'importer' }),
        description: t('Aisle (From)', {
          ns: 'importer',
        }),
        aliases: ['aislefrom', 'aislestart'],
      },
      {
        ...baseFields['aisleTo'],
        title: t('Aisle Range - To', { ns: 'importer' }),
        description: t('Aisle (End)', {
          ns: 'importer',
        }),
        aliases: ['aisleto', 'aisleend'],
      },
      {
        ...baseFields['bayFrom'],
        title: t('Bay Range - From', { ns: 'importer' }),
        description: t('Bay (Start)', {
          ns: 'importer',
        }),
        aliases: ['bayfrom', 'baystart'],
      },
      {
        ...baseFields['bayTo'],
        title: t('Bay Range - To', { ns: 'importer' }),
        description: t('Bay (End)', {
          ns: 'importer',
        }),
        aliases: ['bayto', 'bayend'],
      },
      {
        ...baseFields['baySide'],
        title: t('Bay Side', { ns: 'importer' }),
        description: t(
          'Side of the bay in the Aisle. Can be "Left" or "Right". ',
          { ns: 'importer' },
        ),
        aliases: [
          'bayside',
          'side',
          'aisleside',
          'sideaisle',
          'sidebay',
          'sidecolumn',
          'columnside',
        ],
        valueResolver: {
          ...baseFields.baySide.defaultValueResolver,
          both: undefined,
        },
      },
      {
        ...baseFields['levelFrom'],
        title: t('Level Range - From', { ns: 'importer' }),
        description: t('Level Range start (bottom)', {
          ns: 'importer',
        }),
        aliases: ['levelfrom', 'levelstart', 'lvlfrom', 'fromlevel'],
      },
      {
        ...baseFields['levelTo'],
        title: t('Level Range - To', { ns: 'importer' }),
        description: t('Level Range end (top)', {
          ns: 'importer',
        }),
        aliases: ['levelto', 'levelend', 'lvlto', 'tolevel'],
      },
      {
        ...baseFields['congestionZone'],
        title: t(`Work Zone`, { ns: 'importer' }),
        description: t(`Work Zone / Area`, { ns: 'importer' }),
        aliases: [
          'Zone',
          'congestionZone',
          'workZone',
          'Area',
          'congestionArea',
          'workArea',
          'section',
        ],
      },
      {
        ...baseFields['status'],
        title: t('Status', { ns: 'importer' }),
        description: t('Skip row if disabled', {
          ns: 'importer',
        }),
        aliases: ['status'],
        valueResolver: baseFields.status.defaultValueResolver,
      },
      {
        ...baseFields.ruleType,
        title: t('Rule Type', { ns: 'importer' }),
        description: t('Rule Type', {
          ns: 'importer',
        }),
        aliases: ['ruleType'],
        valueResolver: baseFields.ruleType.defaultValueResolver,
      },
    ],
  };
}
