import {
  ProductsByRuleDataColumn,
  ProductsByRuleDataRow,
} from '@warebee/shared/export-converter';
import { TFunction } from 'i18next';
import { Formatter } from '../../common/useFormatter';
import { ColumnConfig } from '../../components/DatasetTable';
import FormattedValue from '../../components/FormattedValue';
import ItemCategoryTag from '../../components/table/ItemCategoryTag';
import ItemTag from '../../simulation/tags/ItemTag';

export const simulationProductTableConfigDefaultKeys: ProductsByRuleDataColumn[] =
  ['consignee', 'sku'];

export function getSimulationProductTableConfigDefault(
  t: TFunction<'simulation'>,
  formatter: Formatter,
): ColumnConfig<ProductsByRuleDataRow>[] {
  return [
    {
      field: 'consignee',
      title: t(`Client (Consignee)`, { ns: 'simulation' }),
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'cumulativePercentRank',
      title: t(`Category`, { ns: 'simulation' }),
      hasSort: false,
      render: (v: number) => <ItemCategoryTag cmlPercent={v} />,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t(`Item`, { ns: 'simulation' }),
      hasFilter: true,
      hasSort: false,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag
          title={sku}
          filters={{ consignee: row['consignee'], sku }}
          // color={getQualitativeColor(sku, 'dimension')[0]}
        />
      ),
    },
    {
      field: 'skuGroup',
      title: t(`Item Group`, { ns: 'simulation' }),
      hasFilter: true,
      hasSort: false,
    },
    {
      field: 'subGroup',
      title: t(`Sub Group`, { ns: 'simulation' }),
      hasFilter: true,
      hasSort: false,
    },
    {
      field: 'transportClass',
      title: t(`Transport Class`, { ns: 'simulation' }),
      hasFilter: true,
      hasSort: false,
    },
    {
      field: 'stockCategory',
      title: t(`Stock Category`, { ns: 'simulation' }),
      hasFilter: true,
      hasSort: false,
    },

    {
      field: 'netWeight',
      title: t(`Weight`, { ns: 'simulation' }),
      render: (v: number) => (
        <FormattedValue value={formatter.formatWeight(v)} />
      ),
    },
    {
      field: 'length',
      title: t(`Length`, { ns: 'simulation' }),
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v)} />
      ),
    },
    {
      field: 'width',
      title: t(`Width`, { ns: 'simulation' }),
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v)} />
      ),
    },
    {
      field: 'height',
      title: t(`Height`, { ns: 'simulation' }),
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v)} />
      ),
    },
    {
      field: 'volume',
      title: t(`Volume`, { ns: 'simulation' }),
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
  ];
}
