import * as React from 'react';
const RoutePathCheckIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoutePathCheckIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M58.5,113.52H22.74c-2.19,0-4.29-.87-5.83-2.42-1.55-1.55-2.42-3.64-2.42-5.83v-26.37s-9.06,9.06-9.06,9.06l-3.89-3.89,13.75-13.75c.52-.52,1.22-.81,1.95-.81s1.43.29,1.95.81l13.75,13.75-3.89,3.89-9.06-9.06v26.37c0,1.52,1.23,2.75,2.75,2.75h35.76s0,5.5,0,5.5ZM57.69,19.18l-13.75,13.75-3.89-3.89,9.06-9.06h-26.37c-1.52,0-2.75,1.23-2.75,2.75v35.76s-5.5,0-5.5,0V22.74c0-2.19.87-4.29,2.42-5.83,1.55-1.55,3.64-2.42,5.83-2.42h26.37s-9.06-9.06-9.06-9.06l3.89-3.89,13.75,13.75c.52.52.81,1.22.81,1.95s-.29,1.43-.81,1.95h0ZM113.52,105.26c0,2.19-.87,4.29-2.42,5.83-1.55,1.55-3.64,2.42-5.83,2.42h-26.37s9.06,9.06,9.06,9.06l-3.89,3.89-13.75-13.75c-.52-.52-.81-1.22-.81-1.95s.29-1.43.81-1.95l13.75-13.75,3.89,3.89-9.06,9.06h26.37c1.52,0,2.75-1.23,2.75-2.75v-35.76s5.5,0,5.5,0v35.76ZM112.71,57.69c-.52.52-1.22.81-1.95.81s-1.43-.29-1.95-.81l-13.75-13.75,3.89-3.89,9.06,9.06v-26.37c0-1.52-1.23-2.75-2.75-2.75h-35.76s0-5.5,0-5.5h35.76c2.19,0,4.29.87,5.83,2.42,1.55,1.55,2.42,3.64,2.42,5.83v26.37s9.06-9.06,9.06-9.06l3.89,3.89-13.75,13.75Z"
    />
    <path
      strokeWidth={0}
      d="M64.95,34.21c-15.8,0-28.6,12.81-28.6,28.6,0,15.8,12.81,28.6,28.6,28.6,15.8,0,28.6-12.81,28.6-28.6,0-15.8-12.81-28.6-28.6-28.6ZM69.53,65.1l-4.59,17.94-15.26-37.8,37.8,15.26-17.94,4.59Z"
    />
    <circle
      cx={17.55}
      cy={58.5}
      r={7.66}
      strokeWidth={0}
      transform="translate(-41.21 75.14) rotate(-89.11)"
    />
    <circle
      cx={54.62}
      cy={110.9}
      r={7.66}
      strokeWidth={0}
      transform="translate(-57.11 163.78) rotate(-89.11)"
    />
    <circle
      cx={111.13}
      cy={69.5}
      r={7.66}
      strokeWidth={0}
      transform="translate(39.9 179.53) rotate(-89.11)"
    />
    <circle
      cx={69.94}
      cy={17.43}
      r={7.66}
      strokeWidth={0}
      transform="translate(51.42 87.09) rotate(-89.11)"
    />
  </svg>
);
export default RoutePathCheckIcon;
