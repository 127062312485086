import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  HeatmapBucket,
  getHeatmapBucketByValue,
} from '../../common/heatmap.helper';
import useFormatter from '../../common/useFormatter';
import { ActualityMetricDescriptor } from '../../metrics/actuality/actualityMetric.types';
import { AssignmentMetricDescriptor } from '../../metrics/assignment/assignmentMetric.types';
import { useActualityEffectiveMetricDescriptors } from '../../metrics/useActualityEffectiveMetricDescriptors';
import {
  actualityEffectiveHeatmapBuckets,
  actualityEffectiveHeatmapLevelData,
  actualityEffectiveHeatmapRangeDescriptor,
  actualityEffectiveMetric,
} from '../store/actuality.heatmap.state';
import { ActualityHeatmapDataRow } from '../store/datasetQueries/actualityHeatmapData';
import { feedItemsWithRankMap } from '../store/feed.state';
import { FeedItemWithABCRank } from '../store/feed.types';

export type ActualityHeatmapItem = ActualityHeatmapDataRow & {
  itemRanked: FeedItemWithABCRank;
  heatmapValue: string;
  bucket: HeatmapBucket;
  metricDescriptor: ActualityMetricDescriptor | AssignmentMetricDescriptor;
};

/**
 *  Helpers for get actuality items with resolved heatmap value and bucket
 */

function useActualityHeatmapItems() {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();
  const itemsWithRank = useRecoilValue(feedItemsWithRankMap);
  const metric = useRecoilValue(actualityEffectiveMetric);
  const buckets = useRecoilValue(actualityEffectiveHeatmapBuckets);
  const heatmapData = useRecoilValue(actualityEffectiveHeatmapLevelData);
  const rangeDescriptor = useRecoilValue(
    actualityEffectiveHeatmapRangeDescriptor,
  );
  const metricDescriptorMap = useActualityEffectiveMetricDescriptors();

  const bucketsMap = _.keyBy(buckets, b => b.id);
  const metricDescriptor = metricDescriptorMap[metric];

  function getHeatmapItem(
    dataRow: ActualityHeatmapDataRow,
  ): ActualityHeatmapItem {
    const bucket = getHeatmapBucketByValue({
      data: {
        [metricDescriptor.path]: dataRow.dimensionValue,
      },
      buckets: bucketsMap,
      metricDescriptor: metricDescriptor as any,
      rangeDescriptor,
    });

    const value = dataRow.dimensionValue?.toString();
    const heatmapValue = metricDescriptor.format
      ? metricDescriptor.format(parseFloat(value))
      : value;

    return {
      ...dataRow,
      heatmapValue,
      bucket,
      metricDescriptor,
      itemRanked: itemsWithRank[dataRow.skuKey],
    };
  }

  function getLocationItems(locationId: string) {
    if (_.isNil(locationId)) return [];

    const locationData = _.filter(
      heatmapData,
      row => row.locationId === locationId,
    );
    return _.map(locationData, getHeatmapItem);
  }

  return { getLocationItems };
}

export default useActualityHeatmapItems;
