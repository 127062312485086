import classNames from 'classnames';
import i18n from '../../i18n';
import * as Icon from '../icons';

export const ArrowSize = {
  default: 'h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6',
  xs: 'h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6',
  sm: 'h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6',
  md: 'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8',
  lg: 'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8',
  xl: 'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8',
};

export type IconProps = {
  className?: string;
  arrowSize?: keyof typeof ArrowSize | string;
};

export const IconChevronDirection = ({
  className,
  arrowSize = 'default',
}: IconProps) => {
  const sizeClass = ArrowSize[arrowSize];

  return i18n.dir(i18n.language) === 'ltr' ? (
    <Icon.ChevronRight
      className={classNames(sizeClass, 'mx-0.5', 'fill-current', className)}
    />
  ) : (
    <Icon.ChevronLeft
      className={classNames(sizeClass, 'mx-0.5', 'fill-current', className)}
    />
  );
};
