const LinkSourceAreaLockedCancelIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkSourceAreaLockedCancelIcon"
    {...props}
  >
    <path
      fill="#070101"
      strokeWidth={0}
      d="M85.08,5.15H13.47c-5.32,0-9.73,4.41-9.73,9.73v71.61c0,5.41,4.41,9.73,9.73,9.73h21.88v-56.56c0-3.16,2.66-5.82,5.91-5.82h53.57V14.88c0-5.41-4.41-9.73-9.73-9.73Z"
    />
    <path
      fill="#070101"
      strokeWidth={0}
      d="M114.61,33.85h-19.8v56.56c0,3.24-2.66,5.91-5.91,5.91h-53.57v16.8c0,5.41,4.41,9.73,9.73,9.73h69.45c5.41,0,9.73-4.41,9.73-9.73V43.58c.08-5.32-4.33-9.73-9.65-9.73Z"
    />
    <path d="M47.84,87.39c-1.93,0-3.75-.75-5.11-2.12-2.82-2.82-2.82-7.41,0-10.23l11.18-11.18-10.56-10.56c-2.82-2.82-2.82-7.41,0-10.23,1.37-1.37,3.18-2.12,5.12-2.12s3.75.75,5.11,2.12l10.56,10.56,9.93-9.93c1.37-1.37,3.18-2.12,5.11-2.12s3.75.75,5.12,2.12c2.82,2.82,2.82,7.4,0,10.22l-9.93,9.93,10.56,10.56c1.37,1.36,2.12,3.18,2.12,5.11s-.75,3.75-2.12,5.12c-1.36,1.36-3.18,2.12-5.11,2.12s-3.75-.75-5.12-2.12l-10.56-10.55-11.18,11.18c-1.37,1.37-3.18,2.12-5.11,2.12Z" />
  </svg>
);
export default LinkSourceAreaLockedCancelIcon;
