import * as React from 'react';
const CursorMoveIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorMoveIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M31.48,69.85h20.82c3.23,0,5.85-2.62,5.85-5.85s-2.62-5.85-5.85-5.85h-20.82l4.48-4.48c2.28-2.29,2.28-5.99,0-8.27-2.29-2.28-5.99-2.28-8.27,0l-13.74,13.74c-1.57,1.05-2.6,2.83-2.6,4.86s1.03,3.81,2.6,4.86l13.74,13.74c1.14,1.14,2.64,1.71,4.14,1.71s2.99-.57,4.14-1.71c2.29-2.28,2.29-5.99,0-8.27l-4.48-4.48Z"
    />
    <path
      strokeWidth={0}
      d="M74.33,92.04l-4.48,4.48v-20.82c0-3.23-2.62-5.85-5.85-5.85s-5.85,2.62-5.85,5.85v20.82l-4.48-4.48c-2.29-2.28-5.99-2.28-8.27,0-2.28,2.29-2.28,5.99,0,8.27l13.74,13.74c1.05,1.57,2.83,2.6,4.86,2.6s3.81-1.03,4.86-2.6l13.74-13.74c2.29-2.28,2.29-5.99,0-8.27-2.29-2.29-5.99-2.29-8.27,0Z"
    />
    <path
      strokeWidth={0}
      d="M116.66,64c0-2.03-1.03-3.81-2.6-4.86l-13.74-13.74c-2.29-2.29-5.99-2.29-8.27,0-2.29,2.28-2.29,5.99,0,8.27l4.48,4.48h-20.82c-3.23,0-5.85,2.62-5.85,5.85s2.62,5.85,5.85,5.85h20.82l-4.48,4.48c-2.28,2.29-2.28,5.99,0,8.27,1.14,1.14,2.64,1.71,4.14,1.71s2.99-.57,4.14-1.71l13.74-13.74c1.57-1.05,2.6-2.83,2.6-4.86Z"
    />
    <path
      strokeWidth={0}
      d="M49.53,37.67c1.5,0,2.99-.57,4.14-1.71l4.48-4.48v20.82c0,3.23,2.62,5.85,5.85,5.85s5.85-2.62,5.85-5.85v-20.82l4.48,4.48c2.29,2.28,5.99,2.28,8.27,0,2.28-2.29,2.28-5.99,0-8.27l-13.74-13.74c-1.05-1.57-2.83-2.6-4.86-2.6s-3.81,1.03-4.86,2.6l-13.74,13.74c-2.29,2.28-2.29,5.99,0,8.27,1.14,1.14,2.64,1.71,4.14,1.71Z"
    />
  </svg>
);
export default CursorMoveIcon;
