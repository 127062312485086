import Flatten from '@flatten-js/core';
import Konva from 'konva';
import { nanoid } from 'nanoid';
import React from 'react';
import { Path } from 'react-konva';
import { TwTheme } from '../../../Tw';

const areaColors = TwTheme.extend.colors.area;

export type IntersectionFeatureProps = {
  shape: Flatten.Polygon;
  fillColor?: string;
  strokeColor?: string;
  stroke?: number;
  listening?: boolean;
};

const IntersectionFeature: React.FC<IntersectionFeatureProps> = props => {
  const { shape } = props;

  // path correctly displays multipolygons with holes and multiple islands
  const path = [...shape.faces].map(f => f.svg()).join(' ');
  //console.log(path);

  const pathConfig: Konva.PathConfig = {
    id: nanoid(),
    data: path,
    draggable: false,
    // fill: props.color || '#FF000055',
    fill: props.fillColor,
    globalCompositeOperation: 'multiply',
    strokeScaleEnabled: false,
    stroke: props.strokeColor,
    strokeWidth: props.stroke || 1,
    perfectDrawEnabled: false,
    closed: true,
    listening: !!props.listening,
  };

  return <Path key={`intersection-${nanoid()}`} {...pathConfig} />;
};

export default React.memo(IntersectionFeature);
