import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../../common/types';
import BoxZoneFeature from '../../../../layout/features/BoxZoneFeature';
import { stageStateById } from '../../../../layout/stage/stage.state';
import { converterCalculationStatus } from '../store/converter.state';

const ConverterStatusLayer: React.FC = () => {
  const calcStatus = useRecoilValue(converterCalculationStatus);
  const stageState = useRecoilValue(stageStateById('converter-area-view'));
  const vp = stageState.viewPort;
  return (
    <Layer>
      {calcStatus === AsyncLoadStatus.Loading && (
        <BoxZoneFeature
          box={{ xmin: vp[0], ymin: vp[1], xmax: vp[2], ymax: vp[3] }}
          fillColor={'rgba(54,61,66,0.3)'}
          // label={`Generating Layout`}
        />
      )}
    </Layer>
  );
};

export default ConverterStatusLayer;
