import { useDemoWarehousesQuery } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIndicator from '../components/ErrorIndicator';
import { HelperMessage } from '../components/HelperMessage';
import LoadingIndicator from '../components/LoadingIndicator';
import { ContainerIsolated } from '../components/layout/ContainerIsolated';
import { WarehouseDemoCard } from '../demo/WarehouseDemoCard';
import { TrialHelper } from './TrialHelper';

export type WelcomeHomeProps = {
  children?: React.ReactNode;
};

export const WelcomeHome = ({ children }: WelcomeHomeProps) => {
  const { t } = useTranslation('app');
  const { loading, error, data } = useDemoWarehousesQuery();

  if (loading) {
    return <LoadingIndicator absolute selfCenter message={t`Loading`} />;
  }

  if (error) {
    return (
      <ErrorIndicator
        selfCenter
        message={t`Apologies, we cannot load WareBee.`}
        hasReloadButton={true}
      />
    );
  }

  const demoList = data?.demoWarehouses;
  const hasDemoWh = !_.isEmpty(demoList);

  return (
    <ContainerIsolated containerName={`WelcomeHome`}>
      <HelperMessage hasPadding>
        <h1
          className={classNames(
            'text-menu-h1 mb-4 text-2xl sm:text-4xl md:text-5xl xl:text-6xl',
          )}
        >{t`Welcome to WareBee`}</h1>
        <p className={classNames('text-menu-h1 text-lg xl:text-3xl')}>
          {t`You can start with our Demo Warehouse`}
          {/* <br /> */}
          {t`or create your own, importing your data.`}
        </p>
      </HelperMessage>
      <div
        className={classNames(
          'space-x-0 sm:space-x-0 md:space-x-2 lg:space-x-4',
          'p-4 sm:flex-col md:flex md:flex-row lg:flex',
        )}
      >
        <WarehouseDemoCard>
          <TrialHelper />
        </WarehouseDemoCard>
      </div>

      {children}
    </ContainerIsolated>
  );
};
