import {
  Field,
  InputType,
  ObjectType,
} from '@warebee/shared/util-backend-only-types';

@ObjectType()
@InputType('PointInput')
export class Point {
  @Field()
  x: number;
  @Field()
  y: number;
}

@ObjectType()
@InputType('VectorInput')
export class Vector {
  @Field()
  x: number;
  @Field()
  y: number;
}

@ObjectType()
@InputType('Segment1DInput')
export class Segment1D {
  @Field()
  start: number;
  @Field()
  end: number;
}

@ObjectType()
@InputType('SegmentInput')
export class Segment {
  @Field(() => Point)
  start: Point;

  @Field(() => Point)
  end: Point;
}
