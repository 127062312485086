import {
  AssignmentItemDataFragment,
  LoadAssignmentDataDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AssignmentConverterConfig,
  assignmentExportJobParams,
  AssignmentExportJobParams,
  getAssignmentTableRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import useLoadAssignmentData from './hooks/useLoadAssignmentData';
import {
  assignmentItemsData,
  assignmentItemsDataState,
  assignmentItemsDataStatus,
} from './store/assignment.state';
import useAssignmentDataTableConfig from './useAssignmentDataTableConfig';

const AssignmentDataTable: React.FC<
  DatasetDataViewProps<AssignmentItemDataFragment>
> = props => {
  const { t } = useTranslation('dataset');
  const data = useRecoilValue(assignmentItemsData);
  const status = useRecoilValue(assignmentItemsDataStatus);
  const [dataState, setDataState] = useRecoilState(assignmentItemsDataState);
  const [runExportCSV] = useRunExportJobMutation();
  const [loadCallback, cancelLoad] = useLoadAssignmentData();
  const columnsConfig = useAssignmentDataTableConfig();

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filter = {
      locationId: containsFilter(searchValues['locationId']),
      sku: containsFilter(searchValues['sku']),
      consignee: containsFilter(searchValues['consignee']),
    };

    cancelLoad();
    loadCallback({
      assignmentId: props.id,
      filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props.id, dataState.searchValues]);

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  async function startExportCSV() {
    const variables: AssignmentExportJobParams = {
      ...assignmentExportJobParams,
      query: LoadAssignmentDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        assignmentId: props.id,
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    //
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;
  const totalCount = dataState?.totalCount ?? 0;

  const converterConfig: AssignmentConverterConfig = {
    columnsConfig,
  };

  const flattenData = getAssignmentTableRows(data, converterConfig);

  if (props.customRenders) {
    _.forEach(props.customRenders, (renderer, key) => {
      const cfg = _.find(columnsConfig, c => c.field === key);
      if (!_.isNil(cfg)) {
        cfg.render = renderer;
      }
    });
  }

  return (
    <div className="flex-1">
      <DatasetTable
        hasCounter
        hideScreenTitle={props.hideScreenTitle}
        subtitle={t`Assignments`}
        title={props.title}
        isSticky
        id={props.id}
        columnsConfig={columnsConfig}
        keyFields={['consignee', 'sku', 'locationId']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={v => {
          setDataState({ ...dataState, searchValues: v });
        }}
        totalCount={totalCount}
        searchValues={dataState.searchValues}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
      />
    </div>
  );
};

export default AssignmentDataTable;
