const MenuCloseAlt3Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuCloseAlt3Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M32.28,64l20.12-39.17c.4-.78.52-1.96.31-2.99-.22-1.02-.73-1.69-1.3-1.69h-22.53c-.39,0-.76.32-1.03.87L8.14,62.13c-.5,1.05-.5,2.69,0,3.74l19.71,41.1c.27.56.64.87,1.03.87h22.53c.57,0,1.08-.67,1.3-1.69.22-1.02.1-2.2-.31-2.99l-20.12-39.17Z"
    />
    <rect width={47.33} height={89.83} x={72.62} y={18.08} strokeWidth={0} />
  </svg>
);
export default MenuCloseAlt3Icon;
