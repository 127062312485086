import * as React from 'react';
const HeatMap4IsometricLayersIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HeatMap4IsometricLayersIcon"
    {...props}
  >
    <defs>
      <clipPath>
        <polygon
          strokeWidth={0}
          points="4 41.69 63.8 6.94 124 41.69 64.2 76.45 4 41.69"
        />
      </clipPath>
    </defs>
    <g isolation="isolate">
      <polygon points="4 41.69 63.8 6.94 124 41.69 64.2 76.45 4 41.69" />
      <g clipPath="url(#b)">
        <polyline
          strokeWidth={0}
          points="92.76 23.66 63.8 6.94 35.04 23.66 63.99 40.37 92.76 23.66"
        />
        <polyline
          strokeWidth={0}
          points="124 41.69 95.04 24.98 66.28 41.69 95.23 58.41 124 41.69"
          opacity={0.6}
        />
        <polyline
          strokeWidth={0}
          points="61.72 41.69 32.77 24.98 4 41.69 32.96 58.41 61.72 41.69"
          opacity={0.8}
        />
        <polyline
          strokeWidth={0}
          points="92.96 59.73 64.01 43.01 35.24 59.73 64.2 76.45 92.96 59.73"
        />
      </g>
    </g>
    <g isolation="isolate">
      <polyline
        strokeWidth={0}
        points="122.65 61.69 110.7 54.79 64.11 81.86 17.22 54.79 5.35 61.69 64.19 95.66 122.65 61.69"
      />
    </g>
    <g isolation="isolate">
      <polyline
        strokeWidth={0}
        points="122.65 81.67 110.7 74.77 64.11 101.84 17.22 74.77 5.35 81.67 64.19 115.64 122.65 81.67"
      />
    </g>
  </svg>
);
export default HeatMap4IsometricLayersIcon;
