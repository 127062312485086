import Konva from 'konva';
import _ from 'lodash';
import React, { useState } from 'react';
import { KonvaNodeEvents, Rect } from 'react-konva';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  getBestContrastColor,
  getQualitativeColor,
  getSequentialColor,
} from '../../common/color.helper';
import {
  actualityTimelineFilterPerformance,
  actualityTimelineFilterType,
} from '../../feed/store/actuality.state';
import { ActualityAnalyzedJobSummary } from '../../feed/store/datasetQueries/actualityAnalyzedJobSummaries';
import { FeedPicklist } from '../../feed/store/datasetQueries/feedPicklists';
import {
  feedHoveredPickListId,
  feedSelectedAgentId,
  feedSelectedPickListId,
} from '../../feed/store/feed.state';
import { ticksInPixel } from '../viewer/layers/SchedulerLayer';

const menuColors = TwTheme.extend.colors.menu;
const brandColors = TwTheme.extend.colors.brand;
const colors = TwTheme.extend.colors;

export const jobFeatureHeight = 34;
export const jobPadding = 8;
export const actualJobHeight = 20;
export const analyzedJobHeight = actualJobHeight / 10;

export type FeedJobFeatureProps = {
  job: FeedPicklist;
  analyzedJob: ActualityAnalyzedJobSummary;
  offsetY: number;
  isFirst?: boolean;
  isLast?: boolean;
  isActive?: boolean;
  viewJobMode?: string;
};

function createWarningPattern(callback) {
  const size = 20; // The size of the pattern tile
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  // Background color
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, size, size);

  // Diagonal yellow lines
  ctx.strokeStyle = 'yellow';
  ctx.lineWidth = 4;
  ctx.beginPath();
  ctx.moveTo(-size / 2, size / 2);
  ctx.lineTo(size / 2, size * 1.5);
  ctx.moveTo(0, 0);
  ctx.lineTo(size, size);
  ctx.moveTo(size / 2, -size / 2);
  ctx.lineTo(size * 1.5, size / 2);
  ctx.stroke();

  // Convert the canvas to an image and pass it to the callback
  const image = new Image();
  image.onload = () => callback(image);
  image.src = canvas.toDataURL();
}

const svgPattern = `
<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <pattern id="diagonalLines" patternUnits="userSpaceOnUse" width="4" height="4">
      <path d="M-1,1 l2,-2
               M0,4 l4,-4
               M3,5 l2,-2" style="stroke:black; strokeWidth:1" />
    </pattern>
  </defs>
  <rect width="10" height="10" fill="url(#diagonalLines)" />
</svg>
`;

const FeedJobFeature: React.FC<FeedJobFeatureProps> = props => {
  const { job, analyzedJob, offsetY = 0 } = props;
  const filterByPerformance = useRecoilValue(
    actualityTimelineFilterPerformance,
  );

  const filterByType = useRecoilValue(actualityTimelineFilterType);

  const setHoveredGlobal = useSetRecoilState(feedHoveredPickListId);
  const setSelectedJobId = useSetRecoilState(feedSelectedPickListId);
  const setSelectedAgentId = useSetRecoilState(feedSelectedAgentId);
  const [hovered, setHovered] = useState(false);

  const id = job.jobId;
  const actualJobStart = job.minEventTime.getTime() / ticksInPixel;
  const actualJobEnd = job.maxEventTime.getTime() / ticksInPixel;
  const actualJobLength = Math.max(actualJobEnd - actualJobStart, 1);

  let actualJobFill: string;

  if (filterByType === 'proccess') {
    actualJobFill = getQualitativeColor(job.eventProcessType, 'dimension')?.[0];
  } else if (filterByType === 'performance') {
    actualJobFill = getSequentialColor(job.uomCount, 'velocity-analytics')?.[0];
  } else if (filterByType === 'user') {
    actualJobFill = getQualitativeColor(job.agentId, 'dimension')?.[0];
  }
  actualJobFill = actualJobFill ?? '';

  const analyzedJobWidthRaw =
    ((analyzedJob?.duration ?? 0) * 1000) / ticksInPixel;
  const analyzedJobDuration = Math.abs(actualJobLength - analyzedJobWidthRaw);
  const analyzedJobStart = Math.min(
    actualJobEnd,
    actualJobStart + analyzedJobWidthRaw,
  );

  const isUnderperform = analyzedJobWidthRaw < actualJobLength;

  const svgBlob = new Blob([svgPattern], {
    type: 'image/svg+xml;charset=utf-8',
  });
  const url = URL.createObjectURL(svgBlob);

  const [patternImage, setPatternImage] = React.useState(null);

  // useEffect(() => {
  //   createWarningPattern(img => {
  //     setPatternImage(img);
  //   });
  // }, []);

  // useEffect(() => {
  //   const image = new Image();
  //   image.onload = () => {
  //     setPatternImage(image);
  //     URL.revokeObjectURL(url); // Clean up the URL object
  //   };
  //   image.src = url;
  // }, []);

  const analyzedJobFill = _.isNil(analyzedJob)
    ? colors.timeline.unknown
    : isUnderperform
      ? colors.timeline.underperform
      : colors.timeline.overperform;

  if (
    (actualJobLength < 1 || _.isNil(analyzedJob)) &&
    filterByPerformance !== 'all'
  )
    return null;
  if (actualJobLength > 0 && analyzedJobWidthRaw > 0) {
    if (
      filterByPerformance === 'underperform20' &&
      analyzedJobWidthRaw / actualJobLength > 0.8
    ) {
      return null;
    }
    if (
      filterByPerformance === 'underperform' &&
      analyzedJobWidthRaw > actualJobLength
    ) {
      return null;
    }
  }

  function onClick() {
    setSelectedAgentId(job.agentId);
    setSelectedJobId(current => (current === job.jobId ? null : job.jobId));
  }

  // Set corner radius based on the job's position in the list
  let jobCornerStyle: any = 2; // Use 'any' to bypass type checking
  if (props.isFirst) {
    jobCornerStyle = [2, 0, 0, 2] as any; // Cast as 'any'
  } else if (props.isLast) {
    jobCornerStyle = [0, 2, 2, 0] as any; // Cast as 'any'
  }

  const actualJobConfig: Konva.RectConfig = {
    id,
    x: actualJobStart,
    y: offsetY + jobPadding,
    width: actualJobLength,
    height: actualJobHeight,
    fill: actualJobFill,
    fillEnabled: true,
    opacity: hovered ? 0.5 : 0.8,
    cornerRadius: jobCornerStyle,
    strokeWidth: 0,
  };

  const analyzedJobConfig: Konva.RectConfig = {
    id: `analyzed-${id}`,
    x: analyzedJobStart,
    y: 1 + offsetY + jobPadding + actualJobHeight,
    width: analyzedJobDuration,
    height: analyzedJobHeight,
    fill: analyzedJobFill,
    fillEnabled: true,
    opacity: hovered ? 0.4 : 0.8,
    strokeWidth: 0,
    cornerRadius: 0.5,
  };

  function getDynamicFontSize(actualJobWidth) {
    // Minimum and maximum font sizes
    const minFontSize = 2;
    const maxFontSize = 5;

    // Define the width range for the smallest to the largest expected job widths
    const minWidthFont = 1;
    const maxWidthFont = 30;

    // Calculate the font size as a linear function of job width
    if (actualJobWidth <= minWidthFont) {
      return minFontSize;
    } else if (actualJobWidth >= maxWidthFont) {
      return maxFontSize;
    } else {
      // Linear interpolation between min and max font sizes
      return (
        minFontSize +
        (maxFontSize - minFontSize) *
          ((actualJobWidth - minWidthFont) / (maxWidthFont - minWidthFont))
      );
    }
  }

  const maxTextWidth = actualJobLength * 0.5;
  const maximumFontSize = 14;
  const initialFontSize = 10;
  const textLength = job.jobId.length;
  const estimatedCharWidth = initialFontSize * 0.6;
  const estimatedTextWidth = textLength * estimatedCharWidth;
  const scaleFactor = maxTextWidth / estimatedTextWidth;
  let fontSize = initialFontSize * scaleFactor;
  fontSize = Math.min(fontSize, maximumFontSize); // Apply the hard max font size
  // const fontSize = getDynamicFontSize(actualJobWidth);

  const jobTitleConfig: Konva.TextConfig = {
    // Left padding of 2px
    x: actualJobStart + 2,
    // Aligned to the bottom with 2px padding
    y: offsetY + jobPadding + actualJobHeight - 2,
    text: job.jobId,
    fontSize: fontSize,
    fontFamily: 'NeoSans-Regular',
    fill: getBestContrastColor(analyzedJobFill),
    width: actualJobLength,
    align: 'left',
    verticalAlign: 'bottom',
    scaleY: -1, // Mirroring the text verticallyp
    listening: false,
  };

  function selectDeadline() {}

  const eventConfig: KonvaNodeEvents = {
    onClick: onClick,
    onTap: onClick,
    onMouseEnter: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
      setHoveredGlobal(job.jobId);
      setHovered(true);
    },

    onMouseLeave: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';
      setHoveredGlobal(null);
      setHovered(false);
    },
  };

  return (
    <React.Fragment key={id}>
      <Rect {...actualJobConfig} {...eventConfig} />
      <Rect {...analyzedJobConfig} {...eventConfig} />
      {/* <Text {...jobTitleConfig} /> */}
    </React.Fragment>
  );
};

export default FeedJobFeature;
