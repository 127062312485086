import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ContainerCol } from '../components/layout/ContainerFlex';
import ImportJobsView from '../importJobs/ImportJobsView';
import ImportPipelinesView from '../importJobs/ImportPipelinesView';
import { datasetAutomationSelectedMenuItemId } from './store/datasetAutomation.state';

export type DatasetAutomationDataViewProps = {
  importType: ImportJobImportType;
};
const DatasetAutomationDataView: React.FC<
  DatasetAutomationDataViewProps
> = props => {
  const { t } = useTranslation('dataset');
  const stepId = useRecoilValue(
    datasetAutomationSelectedMenuItemId(props.importType),
  );

  const getContent = () => {
    switch (stepId) {
      case 'dataset-automation-menu-import-jobs':
        return <ImportJobsView documentImportType={props.importType} />;

      case 'dataset-automation-menu-import-pipelines':
        return <ImportPipelinesView documentImportType={props.importType} />;
    }
  };

  return (
    <ContainerCol
      overflow
      flex1
      componentName="DatasetAutomationDataView"
      className={`ltr:border-l rtl:border-r border-app-panel-dark relative`}
    >
      {getContent()}
    </ContainerCol>
  );
};

export default DatasetAutomationDataView;
