import { AllocationConstraintSettings } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyFilter } from '../../../components/policies/PolicyFilter';
import PolicyFilterItem from '../../../components/policies/PolicyFilterItem';

export type PolicyRuleConstraintProps = {
  constraintSettings: AllocationConstraintSettings;
  isActive: boolean;
  isDisabled: boolean;
  onClick: () => void;
};

const PolicyRuleConstraint: React.FC<PolicyRuleConstraintProps> = props => {
  const { t } = useTranslation('simulation');

  const ignoreAssignmentPolicyConstraint =
    _.isNil(props.constraintSettings?.assignmentPolicyConstraint?.disabled) ||
    props.constraintSettings?.assignmentPolicyConstraint?.disabled === true;

  const ignoreLocationSizeConstraint =
    _.isNil(props.constraintSettings?.locationSizeConstraint?.disabled) ||
    props.constraintSettings?.locationSizeConstraint?.disabled === true;

  const ignoreBayWidthConstraint =
    _.isNil(props.constraintSettings?.bayWidthConstraint?.disabled) ||
    props.constraintSettings?.bayWidthConstraint?.disabled === true;

  return (
    <PolicyFilter
      label={t`Constraints`}
      isActive={props.isActive}
      isDisabled={props.isDisabled}
      onClick={props.onClick}
    >
      <PolicyFilterItem
        name={t`Storage policy`}
        isActive={props.isActive}
        value={ignoreAssignmentPolicyConstraint ? t`Ignore` : t`Enforce`}
        isDisabled={props.isDisabled}
        isRemovable={false}
      />

      <PolicyFilterItem
        name={t`Location size`}
        value={ignoreLocationSizeConstraint ? t`Ignore` : t`Enforce`}
        isActive={props.isActive}
        isDisabled={props.isDisabled}
        isRemovable={false}
      />

      <PolicyFilterItem
        name={t`Bay width`}
        value={ignoreBayWidthConstraint ? t`Ignore` : t`Enforce`}
        isActive={props.isActive}
        isDisabled={props.isDisabled}
        isRemovable={false}
      />
    </PolicyFilter>
  );
};
export default PolicyRuleConstraint;
