import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useState } from 'react';
import { Group, Path, Rect } from 'react-konva';
import { TwTheme } from '../../../Tw';
import { IconDef } from './featuresIcons';

const iconSize = 128;
const colors = TwTheme.extend.colors;

export type ActionIconOffset = {
  dx: number;
  dy: number;
};

export type ActionIconFeatureProp = {
  x: number;
  y: number;
  scale: number;
  bgFill?: string;
  iconFill?: string;
  iconFillHovered?: string;
  iconDef: IconDef;
  iconHoverDef?: IconDef;
  offset?: ActionIconOffset;
  onClick: () => void;
  rotation?: number;
};

const ActionIconFeature: React.FC<ActionIconFeatureProp> = props => {
  const [isHovered, setIsHovered] = useState<boolean>(null);

  const iconOffset = iconSize / 2;

  const onMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'pointer';
    setIsHovered(true);
  };

  const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
    setIsHovered(false);
  };

  const buttonConfigBase: Konva.RectConfig = {
    offsetX: iconOffset,
    offsetY: iconOffset,
    width: iconSize,
    height: iconSize,
    cornerRadius: iconSize / 8,
  };
  const fillColor =
    (isHovered ? props.iconFillHovered : props.iconFill) ?? colors.area.link;

  const iconDef = isHovered
    ? props.iconHoverDef ?? props.iconDef
    : props.iconDef;

  const pathConfig: Konva.PathConfig = {
    offsetX: iconOffset,
    offsetY: iconOffset,
    fill: fillColor,
    fillEnabled: true,
    data: iconDef.path,
    rotation: iconDef.rotation ?? 0,
    scaleX: iconDef.flipX ? -1 : 1,
    scaleY: iconDef.flipY ? -1 : 1,
  };

  const groupConfig: Konva.NodeConfig = {
    x: props.x,
    y: props.y,
    offsetX: iconOffset * (props.offset.dx ?? 0),
    offsetY: iconOffset * (props.offset.dy ?? 0),
    scaleX: props.scale ?? 1,
    scaleY: props.scale ?? 1,
    onMouseEnter,
    onMouseLeave,
    onTap: props.onClick,
    onClick: props.onClick,
  };

  return (
    <Group {...groupConfig}>
      <Rect
        {...buttonConfigBase}
        // fill={'rgba(0,0,0,0.2)'}
        // globalCompositeOperation="difference"
      />

      <Path {...pathConfig} />
    </Group>
  );
};

export default ActionIconFeature;
