import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../AnalyticTracker';
import { HelperMessage } from './HelperMessage';
import { Button } from './actions/Button';
import { MailTo } from './actions/MailTo';
import * as Icon from './icons';
import { Spacer } from './layout/Spacer';
import { ActionBar } from './nav/ActionBar';

export type ErrorProps = {
  message: string | string[];
  title?: string;
  selfCenter?: boolean;
  className?: string;
  hasReloadButton?: boolean;
  exception?: any;
};
const ErrorIndicator: React.FC<ErrorProps> = props => {
  const { t } = useTranslation('errors');
  const { title = t`Error`, className = '' } = props;

  console.error(props.title, props.message, props.exception);
  useEffect(() => {
    if (props.exception) {
      const errorStringified = JSON.stringify(
        props.exception,
        Object.getOwnPropertyNames(props.exception),
      );
      analyticsAgent?.track('Error', { exception: errorStringified });
    }
  }, []);

  const getMessage = () => {
    const messages = _.isArray(props.message) ? props.message : [props.message];
    return _.map(messages, m => (
      <span key={nanoid()} className={classNames('w-full flex-1')}>
        {m}
      </span>
    ));
  };

  return (
    <div
      data-component="ErrorIndicator"
      className={classNames(
        'z-1000 relative flex items-center justify-center',
        props.selfCenter ? 'flex-1' : '',
        className,
      )}
    >
      <div
        className={classNames(
          'mx-2 mb-4 px-0 py-1',
          'text-menu-text bg-alerts-error/80',
          'backdrop-saturate-110 backdrop-blur-lg backdrop-filter ',
          // 'max-w-80',
          'w-full',
          'rounded',
        )}
      >
        <div className={classNames('flex flex-col')}>
          <div className={classNames('flex flex-1 items-start p-1 text-xl')}>
            <Icon.TriangleInfo
              className={classNames(
                'h-8 w-8',
                'pb-0.5',
                'fill-current px-1 pt-1 ltr:ml-1 rtl:mr-1',
              )}
            />
            <div
              className={classNames(
                'h-full flex-1 ltr:ml-1 ltr:pl-2 rtl:mr-1 rtl:pr-2',
                'border-app-panel-dark/30 ltr:border-l rtl:border-r',
                'select-text',
              )}
            >
              <div className="text-sm uppercase">{t`ERROR`}</div>
              <div className="">{getMessage()}</div>
            </div>
          </div>

          <HelperMessage hasPadding className={classNames('py-2 pt-4')}>
            <HelperMessage
              isError
              className={classNames('border-app-panel-dark/30 text-xs')}
            >
              <MailTo
                email="support@warebee.com"
                subject="[WAREBEE] ERROR"
                body={`Hello, I need help with the following error: ${getMessage()}`}
              >
                {t`If you continue to see this message, please contact us at support@warebee.com`}
              </MailTo>
            </HelperMessage>
          </HelperMessage>

          <ActionBar transparent>
            <Spacer flexspace />
            {props.hasReloadButton && (
              <Button
                hasIconAfter
                buttonIcon={
                  <Icon.Reload className={classNames('h-5 w-5 fill-current')} />
                }
                buttonType="transparent"
                buttonSize="sm"
                label={t`Reload`}
                onPress={() => window.location.reload()}
              />
            )}
          </ActionBar>
        </div>
      </div>
    </div>
  );
};
export default ErrorIndicator;
