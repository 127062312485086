import { AllocationRequirementDataRow } from '@warebee/shared/export-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { formatInteger } from '../common/formatHelper';
import { ColumnConfig } from '../components/DatasetTable';
import Tag from '../helpContext/Tag';
import EmptyValueTag from '../simulation/tags/EmptyValueTag';
import ItemTag from '../simulation/tags/ItemTag';

export function getAllocationRequirementTableConfig(
  uoms: string[],
  t: TFunction<'simulation'>,
): ColumnConfig<AllocationRequirementDataRow>[] {
  function renderQtyValue(value: number) {
    if (value === undefined) {
      return <EmptyValueTag />;
    }
    return <span className="w-full text-right">{formatInteger(value)}</span>;
  }

  function renderRules(rulesIds: string[] | string) {
    if (typeof rulesIds === 'string') {
      rulesIds = [rulesIds];
    }
    return _.map(rulesIds, (ruleId, index) => {
      return <Tag key={`tag-${ruleId}-${index}`}>{ruleId}</Tag>;
    });
  }
  const uomColumns = _.flatMap(uoms, uom => {
    const uomDescription = `UOM: {{uomUnit}}`;
    const translationOptions = {
      uomUnit: uom,
      ns: 'simulation',
    };

    return [
      {
        field: uom,
        title: t(uomDescription, translationOptions),
        render: renderQtyValue,
        // hasSort: true,
      },
      {
        field: `${uom}-agg`,
        title: `${uom} (Req. avg.)`,
        render: renderQtyValue,
        // hasSort: true,
      },
    ];
  });

  return [
    {
      field: 'orderLineRule',
      title: t(`Picking Rule`, { ns: 'simulation' }),
      hasSort: true,
      render: (values: string[]) => renderRules(values),
    },
    {
      field: 'consignee',
      title: t(`Consignee`, { ns: 'simulation' }),
      isHeader: true,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t(`Item`, { ns: 'simulation' }),
      isHeader: true,
      hasSort: true,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'requiredQuantity',
      title: t(`Ordered (qty)`, { ns: 'simulation' }),
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'unpickableRequiredQuantity',
      title: t(`Unpickable (qty)`, { ns: 'simulation' }),
      // hasSort: true,
      render: renderQtyValue,
    },
    ...(uomColumns as any),

    { field: 'name', title: t`Item Name`, hasFilter: false },
    { field: 'description', title: t`Item Description`, hasFilter: false },
    {
      field: 'skuGroup',
      title: t(`Item Group`, { ns: 'simulation' }),
      // hasFilter: true,
    },
    {
      field: 'subGroup',
      title: t(`Sub Group`, { ns: 'simulation' }),
      // hasFilter: true,
    },
    {
      field: 'transportClass',
      title: t(`Transport Class`, { ns: 'simulation' }),
      // hasFilter: true,
    },
    {
      field: 'stockCategory',
      title: t(`Stock Category`, { ns: 'simulation' }),
      // hasFilter: true,
    },
    {
      field: 'storageClass',
      title: t(`Storage Class`, { ns: 'simulation' }),
      // hasFilter: true,
    },
    {
      field: 'pollutionClass',
      title: t(`Pollution Class`, { ns: 'simulation' }),
      // hasFilter: true,
    },
  ];
}
