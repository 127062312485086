import * as React from 'react';
const ProjectAddIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ProjectAddIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.16 106.84 33.36 122.54 33.36 91.14 6.16 75.44 6.16 106.84"
    />
    <polygon
      strokeWidth={0}
      points="34 58.54 6.88 74.2 34 89.86 61.12 74.2 34 58.54"
    />
    <polygon
      strokeWidth={0}
      points="34.71 91.16 34.71 122.48 61.84 106.82 61.84 75.5 34.71 91.16"
    />
    <polygon
      strokeWidth={0}
      points="66.16 106.84 93.36 122.54 93.36 91.14 66.16 75.44 66.16 106.84"
    />
    <polygon
      strokeWidth={0}
      points="94 58.54 66.88 74.2 94 89.86 121.12 74.2 94 58.54"
    />
    <polygon
      strokeWidth={0}
      points="94.71 91.16 94.71 122.48 121.84 106.82 121.84 75.5 94.71 91.16"
    />
    <path
      strokeWidth={0}
      d="M36.29,23.13v32l27.71,16,27.71-16V23.13l-27.71-16-27.71,16ZM66.21,25.85v11.03h11.59c1.22,0,2.21.99,2.21,2.21s-.99,2.21-2.21,2.21h-11.59v12.14c0,1.22-.99,2.21-2.21,2.21s-2.21-.99-2.21-2.21v-12.14h-11.59c-1.22,0-2.21-.99-2.21-2.21s.99-2.21,2.21-2.21h11.59v-11.03c0-1.22.99-2.21,2.21-2.21s2.21.99,2.21,2.21Z"
    />
  </svg>
);
export default ProjectAddIcon;
