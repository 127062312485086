import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { optimisationViewMode } from '../simulation/store/optimisation.state';
import { simulationAnalyzeCompareTypeSelected } from '../simulation/store/simulation.state';
import {
  AnalyzeResultCompareType,
  OptimisationViewMode,
} from '../simulation/store/simulation.types';

const SwitchAnalyzeCompareType: React.FC = () => {
  const { t } = useTranslation('app');
  const [typeSelected, setTypeSelected] = useRecoilState(
    simulationAnalyzeCompareTypeSelected,
  );
  const [, setViewMode] = useRecoilState(optimisationViewMode);

  const viewAs: { key: AnalyzeResultCompareType; label: string }[] = [
    {
      key: 'before',
      label: t`Before`,
    },
    {
      key: 'after',
      label: t`After`,
    },
    {
      key: 'compare',
      label: t`Compare`,
    },
  ];

  return (
    <ButtonSwitchMulti
      autoSize
      className={classNames('mx-0.5 xl:mx-3 w-full')}
      classNameLabel={classNames('text-xs py-0.5 px-2')}
      buttonType="header"
      options={viewAs}
      // disabled={allocationRun?.status !== BatchJobStatus.READY}
      selectedIndex={_.findIndex(viewAs, o => o.key === typeSelected)}
      // onClick={index => setTypeSelected(viewAs[index].key)}
      onClick={index => {
        setTypeSelected(viewAs[index].key);
        // Additional logic to set viewMode
        switch (viewAs[index].key) {
          case 'before':
            setViewMode(OptimisationViewMode.Original);
            break;
          case 'after':
            setViewMode(OptimisationViewMode.Optimised);
            break;
          case 'compare':
            setViewMode(OptimisationViewMode.Compare);
            break;
          default:
            break;
        }
      }}
    />
  );
};

export default SwitchAnalyzeCompareType;
