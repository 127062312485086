import {
  AssignmentIssueInvalidLocationFragment,
  AssignmentIssueInvalidLocationsDataFragment,
  AssignmentIssueSetFragment,
  BatchJobStatus,
  ItemSetIssueInvalidItemsDataFragment,
  ItemSetIssueInvalidUomsDataFragment,
  ItemSetIssueSetFragment,
  LayoutIssueInvalidLocationFragment,
  LayoutIssueSetFragment,
  LoadAssignmentIssueDataDocument,
  LoadAssignmentIssueDataQuery,
  LoadAssignmentIssueDataQueryVariables,
  LoadLayoutIssueDataDocument,
  LoadLayoutIssueDataQuery,
  LoadLayoutIssueDataQueryVariables,
  OrderSetIssueSetFragment,
  OrderSetIssueUnpickableLinesDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedLocationIdAtom,
  viewerSelectedPlaneId,
} from '../../layout/viewer/store/viewer.state';
import {
  DataIssueIdentity,
  LocationIssueData,
} from './simulation.issues.types';
import { simulationCurrent } from './simulation.state';
const getKey = (postfix: string) => `warebee-simulation-data-issues-${postfix}`;

export const simulationLayoutIssuesLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('layout-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationLayoutIssues = atom<LayoutIssueSetFragment>({
  key: getKey('layout'),
  default: null,
});

export const simulationAssignmentIssuesLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('assignment-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationAssignmentIssues = atom<AssignmentIssueSetFragment>({
  key: getKey('assignment'),
  default: null,
});

export const simulationItemSetIssuesLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('item-set-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationItemSetIssues = atom<ItemSetIssueSetFragment>({
  key: getKey('item-set'),
  default: null,
});

export const simulationOrderSetIssuesLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('order-set-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationOrderSetIssues = atom<OrderSetIssueSetFragment>({
  key: getKey('order-set'),
  default: null,
});

export const simulationIssueSelected = atom<DataIssueIdentity>({
  key: getKey('selected-issue-type'),
  default: null,
});

export const simulationShowTable = atom<boolean>({
  key: getKey('show-table'),
  default: false,
});

export const simulationIssuesSelectedLevelLocations = selector<
  Record<string, LocationIssueData>
>({
  key: getKey('selected-level-locations'),
  get: async ({ get }) => {
    const simulation = get(simulationCurrent);
    const selectedIssue = get(simulationIssueSelected);
    const layoutIssues = get(simulationLayoutIssues);
    const assignmentIssues = get(simulationAssignmentIssues);
    if (_.isNil(selectedIssue)) return new Set();

    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);

    let locationsResult: (
      | LayoutIssueInvalidLocationFragment
      | AssignmentIssueInvalidLocationFragment
    )[];
    if (
      selectedIssue?.group === 'assignment' &&
      assignmentIssues?.status === BatchJobStatus.READY
    ) {
      try {
        const response = await secureClient.query<
          LoadAssignmentIssueDataQuery,
          LoadAssignmentIssueDataQueryVariables
        >({
          query: LoadAssignmentIssueDataDocument,
          variables: {
            key: {
              layoutId: simulation.layout?.id,
              assignmentId: simulation.assignment?.id,
            },
            planeId: [planeId],
            level: [level],
            reason: [selectedIssue.reason as any],
            page: null,
          },
        });
        if (!_.isEmpty(response.errors)) {
          console.error('Cannot load aisles by plane', response.errors);
          throw new Error('Cannot load aisles by plane');
        }
        locationsResult =
          response.data?.assignmentIssueSet?.invalidLocations?.content;
      } catch (ex) {
        console.error('Cannot load aisles by plane', ex);
        throw new Error('Cannot load aisles by plane');
      }
    }

    if (
      selectedIssue?.group === 'layout' &&
      layoutIssues?.status === BatchJobStatus.READY
    ) {
      try {
        const response = await secureClient.query<
          LoadLayoutIssueDataQuery,
          LoadLayoutIssueDataQueryVariables
        >({
          query: LoadLayoutIssueDataDocument,
          variables: {
            key: {
              layoutId: simulation.layout?.id,
            },
            planeId: [planeId],
            level: [level],
          },
        });
        if (!_.isEmpty(response.errors)) {
          console.error('Cannot load aisles by plane', response.errors);
          throw new Error('Cannot load aisles by plane');
        }
        locationsResult =
          response.data?.layoutIssueSet?.invalidLocations?.content;
      } catch (ex) {
        console.error('Cannot load aisles by plane', ex);
        throw new Error('Cannot load aisles by plane');
      }
    }

    return _.reduce(
      locationsResult,
      (acc, l) => ({
        ...acc,
        [l.locationId]: {
          locationId: l.locationId,
          reason: l.reason,
          items:
            l.__typename === 'AssignmentIssueInvalidLocation'
              ? l.productIds
              : [],
        },
      }),
      {},
    );
  },
});

export const simulationIssuesSelectedBayLocations = selector<Set<string>>({
  key: getKey('selected--bay-locations'),
  get: async ({ get }) => {
    const simulation = get(simulationCurrent);
    const selectedIssue = get(simulationIssueSelected);
    const layoutIssues = get(simulationLayoutIssues);
    const assignmentIssues = get(simulationAssignmentIssues);
    if (_.isNil(selectedIssue)) return new Set();

    const bayId = get(viewerSelectedBayIdAtom);
    if (
      selectedIssue?.group === 'assignment' &&
      assignmentIssues?.status === BatchJobStatus.READY
    ) {
      try {
        const response = await secureClient.query<
          LoadAssignmentIssueDataQuery,
          LoadAssignmentIssueDataQueryVariables
        >({
          query: LoadAssignmentIssueDataDocument,
          variables: {
            key: {
              layoutId: simulation.layout?.id,
              assignmentId: simulation.assignment?.id,
            },
            bayId: [bayId],
            reason: [selectedIssue.reason as any],
            page: null,
          },
        });
        if (!_.isEmpty(response.errors)) {
          console.error('Cannot load aisles by plane', response.errors);
          throw new Error('Cannot load aisles by plane');
        }
        const lcs = _.map(
          response.data?.assignmentIssueSet?.invalidLocations?.content,
          l => l.locationId,
        );
        return new Set(lcs);
      } catch (ex) {
        console.error('Cannot load aisles by plane', ex);
        throw new Error('Cannot load aisles by plane');
      }
    }

    if (
      selectedIssue?.group === 'layout' &&
      layoutIssues?.status === BatchJobStatus.READY
    ) {
      try {
        const response = await secureClient.query<
          LoadLayoutIssueDataQuery,
          LoadLayoutIssueDataQueryVariables
        >({
          query: LoadLayoutIssueDataDocument,
          variables: {
            key: {
              layoutId: simulation.layout?.id,
            },
            bayId: [bayId],
          },
        });
        if (!_.isEmpty(response.errors)) {
          console.error('Cannot load aisles by plane', response.errors);
          throw new Error('Cannot load aisles by plane');
        }
        const lcs = _.map(
          response.data?.layoutIssueSet?.invalidLocations?.content,
          l => l.locationId,
        );
        return new Set(lcs);
      } catch (ex) {
        console.error('Cannot load aisles by plane', ex);
        throw new Error('Cannot load aisles by plane');
      }
    }
    return new Set();
  },
});

export const simulationIssuesCountByLevel = selector<Record<number, number>>({
  key: getKey('issues-count-by-level'),
  get: ({ get }) => {
    const selectedIssue = get(simulationIssueSelected);
    const assignmentIssues = get(simulationAssignmentIssues);
    const layoutIssues = get(simulationLayoutIssues);
    const planeId = get(viewerSelectedPlaneId);
    let invalidLocation = [];

    if (
      selectedIssue?.group === 'assignment' &&
      assignmentIssues?.status === BatchJobStatus.READY
    ) {
      invalidLocation = assignmentIssues.summary.invalidLocations;
    }

    if (
      selectedIssue?.group === 'layout' &&
      layoutIssues?.status === BatchJobStatus.READY
    ) {
      invalidLocation = layoutIssues.summary.invalidLocations;
    }

    return _(invalidLocation)
      .filter(v => v.planeId === planeId && v.reason === selectedIssue?.reason)
      .reduce(
        (acc, v) => ({
          ...acc,
          [v.locationLevel]: v.count,
        }),
        {},
      );
  },
});

export const simulationIssuesSelectedLocation = selector<LocationIssueData>({
  key: getKey('selected-location-full-info'),
  get: async ({ get }) => {
    const levelLocations = get(simulationIssuesSelectedLevelLocations);
    const locationId = get(viewerSelectedLocationIdAtom);
    return locationId ? levelLocations?.[locationId] : null;
  },
});

export const simulationIssuesOrderLinesData =
  atom<OrderSetIssueUnpickableLinesDataFragment>({
    key: getKey('order-line-data'),
    default: null,
  });

export const simulationIssuesOrderLinesStatus = atom<AsyncLoadStatus>({
  key: getKey('order-line-data-status'),
  default: AsyncLoadStatus.None,
});

export const simulationIssuesItemsData =
  atom<ItemSetIssueInvalidItemsDataFragment>({
    key: getKey('items-data'),
    default: null,
  });

export const simulationIssuesItemsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('items-data-status'),
  default: AsyncLoadStatus.None,
});

export const simulationIssuesAssignmentItemsData =
  atom<AssignmentIssueInvalidLocationsDataFragment>({
    key: getKey('assignment-items-data'),
    default: null,
  });

export const simulationIssuesAssignmentItemsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('assignment-items-data-status'),
  default: AsyncLoadStatus.None,
});

export const simulationIssuesUOMsData =
  atom<ItemSetIssueInvalidUomsDataFragment>({
    key: getKey('uom-data'),
    default: null,
  });

export const simulationIssuesUOMsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('uom-data-status'),
  default: AsyncLoadStatus.None,
});
