import Konva from 'konva';
import _ from 'lodash';
import React, { useState } from 'react';
import { KonvaNodeEvents, Line, Rect, Text } from 'react-konva';
import { useSetRecoilState } from 'recoil';
import { TwTheme } from '../../../Tw';
import { workforceHoveredWorkWindow } from '../../simulation/store/workforce.state';
import { AgentForecastWorkWindow } from '../../simulation/store/workforce.types';
import { ticksInPixel } from '../viewer/layers/SchedulerLayer';

const bayColors = TwTheme.extend.colors.bay;

const widthDay = 24 * 60; //24h*60min
const heightDay = widthDay / 3;
const height = 0.2 * heightDay;
const offset = 0.1 * heightDay;

export type ShiftFeatureProps = {
  workWindow: AgentForecastWorkWindow;
  shiftRowIndex: number;
};

const ShiftFeature: React.FC<ShiftFeatureProps> = props => {
  const { from, to } = props.workWindow;
  const setHoveredGlobal = useSetRecoilState(workforceHoveredWorkWindow);

  const [hovered, setHovered] = useState(false);
  const id = from.toString();
  const shiftRowOffset = (props.shiftRowIndex - 1) * height;
  const x = from.getTime() / ticksInPixel;
  const y = -0.7 * heightDay - shiftRowOffset;

  // const fill = getShiftColor(props.workWindow.shift)[0];
  const fill = 'rgba(88,152,170,0.33)';
  const width = (to.getTime() - from.getTime()) / ticksInPixel;

  const xMax = x + width;
  const strokeWidth = 5;

  const shiftHeight = 0.2;

  const widthDay = 24 * 60;
  const configShiftDayBackground: Konva.RectConfig = {
    id: `day`,
    x,
    y: y + height,
    widthDay,
    height: shiftHeight * height,
    fill: 'rgba(240,240,250,0.5)',
    opacity: 1,
    fillEnabled: true,
  };

  const configShift: Konva.RectConfig = {
    id: `shift-${id}`,
    x,
    y,
    width,
    height,
    fill,
    perfectDrawEnabled: false,
    fillEnabled: true,
    opacity: hovered ? 0.7 : 0.8,
  };

  const configShiftTitle: Konva.TextConfig = {
    x,
    y: y + height,
    width,
    height,
    // fontSize: Math.abs(
    //   Math.min(height, width / props.workWindow.shift.title.length),
    // ),
    fontSize: 0.4 * Math.abs(height),
    //text: from.toLocaleDateString(),
    text: _.head(props.workWindow.shifts).title,
    fontFamily: 'NeoSans-Regular',
    fill: 'white',
    scaleY: -1,
    align: 'center',
    verticalAlign: 'middle',
  };

  const shiftBorderStartConfig: Konva.LineConfig = {
    id: `tick-start-${id}`,
    points: [x, 0 + y, x, y + height],
    // points: [xMax, height * 0.15, xMax, heightDay * 1.25],
    strokeWidth: 4,
    stroke: '#141618',
    opacity: 0.3,
    lineCap: 'round',
    strokeScaleEnabled: true,
    perfectDrawEnabled: true,
    // dash: [4, 16],
  };

  const shiftBorderEndConfig: Konva.LineConfig = {
    id: `tick-end-${id}`,
    points: [xMax, 0 + y, xMax, y + height],
    // points: [xMax, height * 0.15, xMax, heightDay * 1.25],
    strokeWidth: 4,
    stroke: '#141618',
    opacity: 0.5,
    lineCap: 'round',
    strokeScaleEnabled: true,
    perfectDrawEnabled: true,
  };

  const eventConfig: KonvaNodeEvents = {
    // ...eventConfig,
    // onClick: onBayClick,
    // onTap: onBayClick,
    onMouseEnter: e => {
      //const container = e.target.getStage().container();
      //container.style.cursor = 'pointer';
      setHoveredGlobal(props.workWindow);
      setHovered(true);
    },

    onMouseLeave: e => {
      //const container = e.target.getStage().container();
      //container.style.cursor = 'default';
      setHoveredGlobal(null);
      setHovered(false);
    },
  };

  return (
    <React.Fragment key={id}>
      <Rect {...configShiftDayBackground} />

      <Rect {...configShift} />
      <Line {...shiftBorderStartConfig} />
      <Line {...shiftBorderEndConfig} />

      <Text {...configShiftTitle} {...eventConfig} />
    </React.Fragment>
  );
};

export default ShiftFeature;
