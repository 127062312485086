import Flatten from '@flatten-js/core';
import { LayoutImportLocation } from '@warebee/shared/data-access-layout-import-converter';
import {
  Aisle,
  Bay,
  BayLevel,
  BayLocationOrder,
  BayTypeModel,
  DEFAULT_BAY_TYPE,
  LayoutMap,
  Location,
  LocationTypeModel,
  Plane,
  PlaneAccessSpec,
  PlaneMap,
  Point,
} from '@warebee/shared/engine-model';
import { groupBy, keyBy } from 'lodash';
import {
  fromGeojsonPolygon,
  loadPoint,
  mergeBoxes,
  savePoint,
  toGeojsonPolygon,
} from './geometry.engine';
import {
  getAisleShape,
  getBayShape,
  getInnerBayShape,
} from './layout-map.engine';
import { LayoutTransformation, transformVector } from './layout-transformation';
import { bindBayLocations } from './location-binding.engine';
import { NavigablePolygon } from './navigation.common';
const { unify } = Flatten.BooleanOperations;

export interface VisualObject {
  readonly id: string;
  readonly shape: Flatten.Polygon;
}

export class VisualAisle implements VisualObject {
  constructor(
    readonly id: string,
    readonly shape: Flatten.Polygon,
    readonly title?: string,
    readonly nonNavigable?: boolean,
    readonly aisleType?: string,
  ) {}

  static load(a: Aisle) {
    return new VisualAisle(
      a.id,
      getAisleShape(a),
      a.title,
      a.nonNavigable,
      a.aisleType,
    );
  }

  save(): Aisle {
    return {
      id: this.id,
      title: this.title,
      points: this.points,
      nonNavigable: this.nonNavigable,
      aisleType: this.aisleType,
    };
  }

  transform({ m }: LayoutTransformation) {
    return new VisualAisle(
      this.id,
      this.shape.transform(m),
      this.title,
      this.nonNavigable,
      this.aisleType,
    );
  }

  get points(): Point[] {
    return this.shape.vertices.map(pt => ({ x: pt.x, y: pt.y }));
  }
}

export interface VisualLocationPortal {
  aisleId: string;
  position: Flatten.Point;
}

export interface VisualLocationBinding {
  readonly locationBayProjection: number;
  readonly locationHeightFromFloor: number;
  readonly shape: Flatten.Polygon;
}

export interface VisualLocationPortals {
  readonly portals: VisualLocationPortal[];
}

export type VisualLocation<L> = VisualLocationBinding &
  L & { locationId: string };

export type BoundLocation = Pick<
  Location,
  | 'locationId'
  | 'locationBayProjection'
  | 'portals'
  | 'shape'
  | 'locationHeightFromFloor'
>;

export function loadLocation<L extends BoundLocation>(
  loc: L,
): VisualLocation<L> {
  return {
    ...loc,
    portals: loc.portals?.map(p => ({ ...p, position: loadPoint(p.position) })),
    shape: fromGeojsonPolygon(loc.shape),
  };
}

export function loadLocations<L extends BoundLocation>(
  locs: L[],
): VisualLocation<L>[] {
  return locs.map(loc => loadLocation(loc));
}

export function saveLocation<L>(
  loc: VisualLocation<L> & VisualLocationPortals,
): L {
  return {
    ...loc,
    __typename: undefined,
    portals: loc.portals?.map(p => ({ ...p, position: savePoint(p.position) })),
    shape: toGeojsonPolygon(loc.shape),
  };
}

export type LocationDepthBinding = Pick<
  Location,
  'locationDepthFromFront' | 'locationIndexFromFront'
>;

export type BindLocationTypes = Record<
  string,
  Pick<LocationTypeModel, 'gapWidth' | 'isWeakDepth' | 'gapDepth' | 'minDepth'>
>;
export type BindBayTypes = Record<
  string,
  Pick<BayTypeModel, 'verticalFrameProfile'>
>;

export class VisualBay implements VisualObject {
  readonly frontEdgeSegment: Flatten.Segment;
  readonly shape: Flatten.Polygon;
  readonly innerShape: Flatten.Polygon;
  readonly bayLevelMap: Record<number, BayLevel>;

  constructor(
    readonly id: string,
    readonly bayType: string,
    readonly position: Flatten.Point,
    readonly frontEdge: Flatten.Vector,
    readonly width: number,
    readonly depth: number,
    readonly locationOrder: BayLocationOrder,
    readonly hasPass: boolean,
    readonly title?: string,
    readonly height?: number,
    readonly levels?: BayLevel[],
    model?: BayTypeModel,
  ) {
    this.frontEdgeSegment = Flatten.segment(
      this.position,
      this.position.translate(this.frontEdge),
    );
    this.shape = getBayShape(this);
    if (model?.verticalFrameProfile) {
      this.innerShape = getInnerBayShape(this, model?.verticalFrameProfile);
    }
    this.bayLevelMap = keyBy(levels, l => l.level);
  }

  transform({ m, flipAndRotateOnly, flipDirections }: LayoutTransformation) {
    let locationOrder = this.locationOrder;
    let frontEdge = transformVector(this.frontEdge, flipAndRotateOnly);
    let position = this.position.transform(m);

    if (flipDirections) {
      locationOrder =
        locationOrder === BayLocationOrder.LTR
          ? BayLocationOrder.RTL
          : BayLocationOrder.LTR;
      position = position.translate(frontEdge);
      frontEdge = frontEdge.invert();
    }

    return new VisualBay(
      this.id,
      this.bayType,
      position,
      frontEdge,
      this.width,
      this.depth,
      locationOrder,
      this.hasPass,
      this.title,
      this.height,
      this.levels,
    );
  }

  static load(b: Bay, model?: BayTypeModel) {
    return new VisualBay(
      b.id,
      b.bayType,
      Flatten.point(b.position.x, b.position.y),
      Flatten.vector(b.frontEdge.x, b.frontEdge.y),
      b.width,
      b.depth,
      b.locationOrder,
      b.hasPass,
      b.title,
      b.height,
      b.levels,
      model,
    );
  }

  save(): Bay {
    return {
      id: this.id,
      title: this.title,
      position: { x: this.position.x, y: this.position.y },
      frontEdge: { x: this.frontEdge.x, y: this.frontEdge.y },
      width: this.width,
      depth: this.depth,
      locationOrder: this.locationOrder,
      hasPass: this.hasPass,
      height: this.height,
      levels: this.levels,
      bayType: this.bayType,
    };
  }
}

export function getNavigablePolygons({
  aisles,
  bays,
}: Pick<VisualPlaneMap, 'aisles' | 'bays'>) {
  return (aisles.filter(a => !a.nonNavigable) as NavigablePolygon[]).concat(
    bays.filter(b => b.hasPass),
  );
}

export class VisualPlaneMap {
  readonly aisles: VisualAisle[];
  readonly bays: VisualBay[];

  readonly box: Flatten.Box;
  //readonly shape: Flatten.Polygon;

  readonly aislesById: Record<string, VisualAisle>;
  readonly baysById: Record<string, VisualBay>;

  constructor(aisles?: VisualAisle[], bays?: VisualBay[]) {
    this.aisles = aisles || [];
    this.bays = bays || [];

    const allObjects = (this.aisles as VisualObject[]).concat(this.bays);
    this.box = mergeBoxes(allObjects.map(o => o.shape.box));
    //this.shape = getAreaShape(aisles, bays);

    this.aislesById = keyBy(this.aisles, a => a.id);
    this.baysById = keyBy(this.bays, b => b.id);
  }

  static load(plane: PlaneMap) {
    return new VisualPlaneMap(
      plane.aisles.map(VisualAisle.load),
      plane.bays.map(b => VisualBay.load(b)),
    );
  }

  save(): PlaneMap {
    return {
      aisles: this.aisles.map(a => a.save()),
      bays: this.bays.map(b => b.save()),
    };
  }

  transform(m: LayoutTransformation): this {
    return this.updated(
      this.aisles.map(a => a.transform(m)),
      this.bays.map(b => b.transform(m)),
    );
  }

  translate(dx: number, dy: number): this {
    return this.transform({
      m: new Flatten.Matrix().translate(dx, dy),
      flipAndRotateOnly: new Flatten.Matrix(),
      flipDirections: false,
    });
  }

  normalize(): this {
    return this.translate(-this.box.xmin, -this.box.ymin);
  }

  protected updated(aisles?: VisualAisle[], bays?: VisualBay[]): this {
    return new VisualPlaneMap(aisles, bays) as this;
  }

  get navigablePolygons(): NavigablePolygon[] {
    return getNavigablePolygons(this);
  }
}

export class VisualPlane extends VisualPlaneMap {
  constructor(
    readonly id: string,
    readonly title: string,
    aisles?: VisualAisle[],
    bays?: VisualBay[],
    readonly start?: PlaneAccessSpec,
    readonly end?: PlaneAccessSpec,
  ) {
    super(aisles, bays);
  }
  static load(plane: Plane, bayModels?: Record<string, BayTypeModel>) {
    return new VisualPlane(
      plane.id,
      plane.title,
      plane.aisles.map(VisualAisle.load),
      plane.bays.map(b =>
        VisualBay.load(b, bayModels?.[b.bayType ?? DEFAULT_BAY_TYPE]),
      ),
      plane.start,
      plane.end,
    );
  }

  save(): Plane {
    return Object.assign(super.save(), {
      id: this.id,
      title: this.title,
    });
  }

  protected updated(aisles, bays): this {
    return new VisualPlane(
      this.id,
      this.title,
      aisles,
      bays,
      this.start,
      this.end,
    ) as this;
  }

  bindLocations(
    locationsByBayId: Record<string, LayoutImportLocation[]>,
    bayTypes: BindBayTypes,
    locationTypes: BindLocationTypes,
  ): VisualLocation<LayoutImportLocation>[] {
    return this.bays.flatMap(b =>
      locationsByBayId[b.id]
        ? bindBayLocations(b, locationsByBayId[b.id], bayTypes, locationTypes)
        : [],
    );
  }
}

export class VisualLayoutMap {
  readonly planesById: Record<string, VisualPlane>;
  readonly planeByBayId: Record<string, VisualPlane>;
  readonly planeByAisleId: Record<string, VisualPlane>;
  readonly bayModelsByType: Record<string, BayTypeModel>;
  readonly locationModelsByType: Record<string, LocationTypeModel>;

  constructor(
    readonly planes: VisualPlane[],
    readonly bayTypes?: BayTypeModel[],
    readonly locationTypes?: LocationTypeModel[],
  ) {
    this.planesById = keyBy(planes, a => a.id);
    this.planeByBayId = Object.fromEntries(
      planes.flatMap(a => a.bays.map(b => [b.id, a])),
    );
    this.planeByAisleId = Object.fromEntries(
      planes.flatMap(a => a.aisles.map(aa => [aa.id, a])),
    );

    this.bayModelsByType = keyBy(this.bayTypes || [], bt => bt.bayType);
    this.locationModelsByType = keyBy(
      this.locationTypes || [],
      lt => lt.locationRackingType,
    );
  }

  static load(map: LayoutMap) {
    return new VisualLayoutMap(map.planes.map(p => VisualPlane.load(p)));
  }

  save(): LayoutMap {
    return {
      planes: this.planes.map(a => a.save()),
      bayTypes: this.bayTypes,
      locationTypes: this.locationTypes,
    };
  }

  bindLocations(
    locations: LayoutImportLocation[],
  ): VisualLocation<LayoutImportLocation>[] {
    const locationsByBayId = groupBy(locations, loc => loc.locationBayId);
    return this.planes.flatMap(a =>
      a.bindLocations(
        locationsByBayId,
        this.bayModelsByType,
        this.locationModelsByType,
      ),
    );
  }
}

function getAreaShape(
  aisles: VisualAisle[],
  bays: VisualBay[],
): Flatten.Polygon {
  return [...aisles, ...bays]
    .map(item => item.shape)
    .map(shape => {
      // normally, feature polygons should be generated with CW orientation
      // check orientation just in case it's not so
      if ([...shape.faces][0].orientation() !== Flatten.ORIENTATION.CW) {
        return shape.reverse();
      } else {
        return shape;
      }
    })
    .reduce(unify);
}
