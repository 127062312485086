import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../components/HelperMessage';
import InboxZero from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ItemListCard } from '../../dashboard/containers/ItemListCard';
import {
  warehouseCanUpdate,
  warehouseIsDemo,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { DashboardInfoCardProps } from '../store/dashboard.types';

const DashboardOrderSetInfoCard: React.FC<DashboardInfoCardProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const isDemo = useRecoilValue(warehouseIsDemo);

  function StartButton() {
    if (!canUpdate) return null;
    return (
      <Button
        className="mt-4"
        label={t`Import Orders`}
        buttonType="primary"
        isDisabled={props.isLoading}
        full
        hasIconAfter
        buttonIcon={<Icon.CirclePlus className={`h-6 w-6 fill-current`} />}
        onPress={() => {
          navigate(`/wh/i/${warehouseId}/import/orders`);
        }}
        isLoading={props.isLoading}
      />
    );
  }

  if (props.showButtonOnly) {
    return (
      <ItemListCard>
        <StartButton />
      </ItemListCard>
    );
  }

  if (props.listMode) {
    return null;
  }

  return (
    <>
      <ItemListCard isHelper>
        <ScreenTitle subtitle={t`Data Dashboard`} title={t`Order Set`} />

        <HelperMessage hasPadding>
          <Icon.DataOrders
            className={classNames('h-24 w-24', 'p-2', 'fill-current')}
          />
          <HelperMessage isLead textSize={'hero'}>
            {t`'Order set' — is the collection of customer orders you want WareBee to optimise your warehouse picking location assignment.`}
          </HelperMessage>
          <HelperMessage
            hasBefore
            isTip
          >{t`You can import historical orders (for different periods), future orders or sales forecasts.`}</HelperMessage>
          {isDemo ? (
            <HelperMessage
              hasBefore
              iconAction={Icon.ArrowRight}
              textSize={'xl'}
            >
              {t`Click on`}
              <HelperMessageAction>{t`Order Set Card`}</HelperMessageAction>
              {`(on the right) to see orders data and stats`}
            </HelperMessage>
          ) : (
            <HelperMessage
              hasBefore
              iconAction={Icon.ArrowRightBottom}
              textSize={'xl'}
            >
              {t`Use`}
              <HelperMessageAction>{t`Import Order Set`}</HelperMessageAction>
              {t`to start the interactive import wizard.`}
            </HelperMessage>
          )}
        </HelperMessage>

        {props.showButtonInline ? <StartButton /> : null}
      </ItemListCard>
      {props.showButtonInline ? null : (
        <ItemListCard>
          {props.isLoading && <LoadingIndicator absolute selfCenter />}
          <InboxZero
            className={classNames('w-full flex-1')}
            selfCenter
            message_helper={t`No Order set data found`}
          >
            <StartButton />
          </InboxZero>
        </ItemListCard>
      )}
    </>
  );
};

export default DashboardOrderSetInfoCard;
