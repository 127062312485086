import * as React from 'react';
const ActionCopyPasteIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ActionCopyPasteIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M49.73,8.09v62.74c0,3.54,2.87,6.4,6.4,6.4h62.13c3.54,0,6.4-2.87,6.4-6.4V8.09c0-3.54-2.87-6.4-6.4-6.4h-62.13c-3.54,0-6.4,2.87-6.4,6.4Z"
    />
    <path
      strokeWidth={0}
      d="M8.53,49.29h28.01v33.7c0,4.16,3.37,7.53,7.53,7.53h32.99v27.91c0,3.54-2.87,6.4-6.4,6.4H8.53c-3.54,0-6.4-2.87-6.4-6.4v-62.74c0-3.54,2.87-6.4,6.4-6.4Z"
      opacity={0.3}
    />
  </svg>
);
export default ActionCopyPasteIcon;
