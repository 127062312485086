import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { StageType } from '../../../../layout/stage/stage.helper';
import TooltipWrapper from '../../../../layout/tooltip/TooltipWrapper';
import { viewerShowTooltip } from '../../../../layout/viewer/store/viewer.state';
import {
  workforceHoveredWave,
  workforceHoveredWorkWindow,
} from '../../../store/workforce.state';
import WorkforceShiftTooltip from './WorkforceShiftTooltip';
import WorkforceWaveTooltip from './WorkforceWaveTooltip';

export type WorkforceSchedulerTooltipProps = {
  stageId: StageType;
};

const WorkforceSchedulerTooltip: React.FC<
  WorkforceSchedulerTooltipProps
> = props => {
  const showTooltip = useRecoilValue(viewerShowTooltip);
  const wave = useRecoilValue(workforceHoveredWave);
  const workWindow = useRecoilValue(workforceHoveredWorkWindow);

  if (!showTooltip || (_.isNil(wave) && _.isNil(workWindow))) return null;
  return (
    <TooltipWrapper stageId={props.stageId}>
      {wave && <WorkforceWaveTooltip wave={wave} />}
      {workWindow && <WorkforceShiftTooltip workWindow={workWindow} />}
    </TooltipWrapper>
  );
};

export default WorkforceSchedulerTooltip;
