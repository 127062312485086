import Konva from 'konva';
import React, { useState } from 'react';
import { Circle, Group, KonvaNodeEvents, Path } from 'react-konva';
import { TwTheme } from '../../Tw';

const iconSize = 190;
const iconSvg =
  'm64,2C29.81,2,2,29.81,2,64s27.81,62,62,62,62-27.81,62-62S98.19,2,64,2ZM28.25,84.33l-17.32-17.88,9.87-4.47,14.16,13.04-6.71,9.31Zm73.62,20.22c0,.87-.71,1.58-1.58,1.58h-10.8c-.87,0-1.58-.71-1.58-1.58v-16.06c-1.33.26-2.98.44-4.85.44-3.82,0-11.22-.78-17.91-6.01-4.76-3.68-8.31-9.37-10.38-16.48l-12.23,8.92-1.6-1.51s-.04-.08-.07-.11l-19.34-18.18s-.08-.05-.12-.08l-1.33-1.25,49.63-37.55,18.87,25.1-29.76,21.71c.03.09.1.16.12.25,1.72,6.8,4.88,12.13,9.14,15.43,8.23,6.43,17.87,5.08,19.84,4.49v-27.17c0-.87.71-1.58,1.58-1.58h10.8c.87,0,1.58.71,1.58,1.58v48.05Z';

const iconColors = TwTheme.extend.colors.bay;

export type BaySideActionFeatureProps = {
  x: number;
  y: number;
  maxIconWidth: number;
  onClick?: () => void;
};

const CCTVFeature: React.FC<BaySideActionFeatureProps> = props => {
  const [hovered, setHovered] = useState(false);
  const { x, y } = props;

  const scaleFactorInternal = props.maxIconWidth / iconSize;

  const circleConfig: Konva.CircleConfig = {
    radius: iconSize / 3,
    fill: '#00b8d3',
    // fill: 'rgba(255,255,0, 0.6)',
  };

  const pathConfig: Konva.PathConfig = {
    offsetX: iconSize / 3,
    offsetY: iconSize / 3,
    data: iconSvg,
    fill: hovered ? 'rgb(0,0,0)' : 'rgb(0,0,0)',
    // stroke: iconColors.actionBg,
    rotation: 180,
  };

  const groupConfig: Konva.NodeConfig = {
    x,
    y,
    scaleX: hovered ? scaleFactorInternal + 2 : scaleFactorInternal,
    scaleY: hovered ? scaleFactorInternal + 2 : scaleFactorInternal,
  };

  const eventConfig: KonvaNodeEvents = {
    onTap: props.onClick,
    onClick: props.onClick,
    onMouseEnter: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
      setHovered(true);
    },

    onMouseLeave: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';
      setHovered(false);
    },
  };

  return (
    <Group {...groupConfig} {...eventConfig}>
      <Circle {...circleConfig} />
      <Path {...pathConfig} />
    </Group>
  );
};

export default React.memo(CCTVFeature);
