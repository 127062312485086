import { useApolloClient } from '@apollo/client';
import {
  CheckAssignmentPolicyDocument,
  CheckAssignmentPolicyQuery,
  CheckAssignmentPolicyQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../common/types';
import { getAssignmentPolicyInput } from '../simulation/store/assignmentPolicy.helper';
import {
  assignmentPolicyCheckLoadStatus,
  assignmentPolicyCheckResult,
  assignmentPolicyDocument,
  assignmentPolicyShowUnassigned,
} from '../simulation/store/assignmentPolicy.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';

function useCheckAssignmentPolicy() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Item stats for Storage policy`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(assignmentPolicyCheckLoadStatus, AsyncLoadStatus.Loading);
  });

  const callCheck = useRecoilCallback(({ snapshot, set }) => async () => {
    const simulation = await snapshot.getPromise(simulationCurrent);
    const policy = await snapshot.getPromise(assignmentPolicyDocument);

    const layoutId = simulation?.layout?.id;
    const assignmentId = simulation?.assignment?.id;
    const itemSetId = simulation?.itemSet?.id;
    const showUnassigned = await snapshot.getPromise(
      assignmentPolicyShowUnassigned,
    );

    if (_.isNil(layoutId) || _.isNil(assignmentId) || _.isNil(policy)) {
      console.error('Layout and Assignment should be selected');
      return;
    }

    function handleError(details, stack) {
      set(errorAppender, {
        id: nanoid(),
        title: errorTitle,
        details: details,
        callStack: stack,
      });
      set(assignmentPolicyCheckLoadStatus, AsyncLoadStatus.Error);
      set(assignmentPolicyCheckResult, null);
    }

    const query = client.watchQuery<
      CheckAssignmentPolicyQuery,
      CheckAssignmentPolicyQueryVariables
    >({
      query: CheckAssignmentPolicyDocument,
      variables: {
        input: {
          simulationId: simulation.id,
          policy: getAssignmentPolicyInput(policy),
          includeUnassigned: showUnassigned,
        },
      },
    });

    const queryObservable = query.subscribe(
      ({ data, errors }) => {
        if (errors) {
          console.error(errors);
          handleError(null, errors.map(e => e.message).join('. '));
          return;
        }
        const checkResult = data?.checkSimulationAssignmentPolicy?.policy;

        set(assignmentPolicyCheckLoadStatus, AsyncLoadStatus.Ok);
        set(assignmentPolicyCheckResult, checkResult);
      },
      error => {
        console.error(error);
        handleError(error.message || error, error.stack || null);
      },
    );
    setObservable(queryObservable);
  });

  async function call() {
    await initLoading();
    await callCheck();
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useCheckAssignmentPolicy;
