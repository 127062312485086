export const defaultSequentialColors = [
  '#00bbff', // Blue
  '#00FFFF', // Cyan
  '#09ff00', // Chartreuse
  '#ADFF2F', // Green Yellow
  '#FFFF00', // Yellow
  '#FFD700', // Gold
  '#FFA500', // Orange
  '#ff6831', // Orange Red
  '#fd3434', // Red
  '#d30584', // Maroon
];

export const heatmapAnalytics = [
  '#FFE67A',
  '#f9ffd5',
  '#ffffbf',
  '#e6f598',
  '#eafc52',
  '#fac727',
  '#f99230',
  '#ee7918',
  '#ea6002',
  '#E30303',
  '#99037c',
];

export const elevationSortColors = [
  '#00ffe5',
  '#1af495',
  '#09ff00',
  '#ADFF2F',
  '#FFFF00',
  '#FFD700',
  '#FFA500',
  '#ff6831',
  '#fd3434',
  '#d30584',
];

export const assignmentStateColor = [
  '#00bbff',
  '#00FFFF',
  '#09ff00',
  '#ADFF2F',
  '#FFFF00',
  '#FFD700',
  '#FFA500',
  '#ff6831',
  '#fd3434',
  '#d30584',
];

export const categoryHotToColdColor = [
  // 'hsla(331, 100%, 41%, 1)',
  // 'hsla(331, 100%, 34%, 1)',
  // 'hsla(331, 71%, 47%, 1)',
  // 'hsla(165, 45%, 80%, 1)',
  // 'hsla(165, 45%, 80%, 1)',
  // 'hsla(100, 82%, 80%, 1)',
  'hsla(30, 100%, 50%, 1)',
  'hsla(70, 100%, 50%, 1)',
  'hsla(120, 100%, 60%, 1)',
  // 'hsla(313, 100%, 50%, 1)',
];

export const categoryABCD = [
  // 'hsla(331, 100%, 41%, 1)',
  // 'hsla(331, 100%, 34%, 1)',
  // 'hsla(331, 71%, 47%, 1)',
  'hsla(163, 45%, 80%, 1)',
  'hsla(100, 82%, 80%, 1)',
  'hsla(49, 100%, 50%, 1)',
  'hsla(35, 100%, 60%, 1)',
  // 'hsla(313, 100%, 50%, 1)',
];

export const moveSequenceColors = [
  '#00ffb3',
  '#0aacf7',
  '#000ac1',
  '#6300b3',
  '#7700a5',
];

export const stackingTopColors = ['#00FFFF', '#09ff00'];

export const stackingBottomColors = ['#ff6831', '#fd3434', '#d30584'];
