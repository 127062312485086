import * as React from 'react';
const AgentCarryingCartIcon = props => (
  <svg
    aria-hidden="true"
    data-name="AgentCarryingCartIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="m65.32,59.37c-2.26.87,4.14,3.52,4.69,4.97,2.58,6.91,8.7,23.66,12.43,33.98.95,2.66,1.37,5.56-.35,7.94-.01.01-.01.03-.01.03-1.61,2.34-2.39,5.3-1.83,8.45.88,4.9,5.01,8.71,9.96,9.21,6.76.68,12.47-4.62,12.47-11.25,0-.06-.01-.13-.01-.2.52-3.23,14.84-7.91,14.08-10.61-.4-1.36-.62-1.78-1.5-1.92h-.64l-13.8,6.52c-1.92-2.9-5.15-4.87-8.84-5.05-.2-.01-.4-.03-.59-.03-.23,0-.45.01-.68.03-1.67-2.08-2.56-4.66-2.83-5.42-3.85-11.43-7.83-22.83-11.75-34.23-.74-2.11-8.54-3.28-10.79-2.41Zm25.79,58.16c-2.42,0-4.37-1.95-4.37-4.37s1.95-4.37,4.37-4.37,4.37,1.97,4.37,4.37-1.95,4.37-4.37,4.37Z" />
      <path d="m99.87,102.05c.67.74,1.72.99,2.63.58,0,0,23.05-10.08,23-10.19-.05-.11-7.72-17.31-7.72-17.31-.51-1.15-1.86-1.67-3.01-1.15l-26.04,11.31c-1.15.51-1.67,1.86-1.15,3.01l3.92,8.79c.31.7.96,1.21,1.72,1.33,2.63.41,4.95,1.73,6.65,3.62Z" />
      <path d="m103.37,75.52l-11.79,5.24c-1.58.7-3.43,0-4.14-1.59l-3.93-12.44c-.7-1.58,0-3.43,1.59-4.14l10.48-4.59c1.58-.7,3.43,0,4.14,1.59l5.24,11.79c.7,1.58,0,3.43-1.59,4.14Z" />
    </g>
    <g>
      <path d="m72.91,23.13c0,6.39-5.17,11.56-11.56,11.56s-11.56-5.17-11.56-11.56,5.17-11.55,11.56-11.55,11.56,5.17,11.56,11.55h0Z" />
      <path d="m60.23,62.05s-.2-12.99-1.7-19.56c-1.41-6.19-3.52-6.64-6.53-8.73-1.96-1.37-5.39-5.11-8.22-3.23-2.7,2.11-22.97,12.74-24.26,15.85,0,0-7.43,20.92-7.43,20.92-2.17,5.63,5.03,8.74,7.61,4,.79-1.92,7.27-18.95,7.27-18.95l11.24-5.6c-4.63,12.81-11.35,19.42-6.28,29.59.66,1.6,13.29,10.58,18.63,22,4.87,10.4,2.56,22.89,7.51,24.08,1.75.42,6.14.09,6.42-1.64,1.58-9.66-.87-18.14-3.01-25.98-3.1-11.36-15.59-20.23-15.59-20.23l6.91-15.44s-.95,5.13-.03,7.47c2.37,4,3.75,3.3,8.1,6.83,5.83,4.91,11.75-1.06,6.36-5.91l-7.01-5.47Z" />
      <path d="m29.89,80.38l-4.12,15.3s-8.97,10.19-10.81,14.52c-1.39,3.27-2.16,7.07,1.72,8.93,4.36,2.09,17.06-13.56,18.59-16.56,1.02-2.03,4.1-9.96,4.52-12.76l-9.9-9.43Z" />
    </g>
  </svg>
);
export default AgentCarryingCartIcon;
