import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { getAnalyzeProductMetricDescriptorsMap } from '../../metrics/analyzeProduct/analyzeProductMetric.default';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { simulationHeatmapBuckets } from '../store/simulation.heatmap.state';
import { simulationLayoutHeatmapFilters } from '../store/simulation.layout.state';
import HeatmapLegendBuckets, {
  HeatmapLegendBucketsProps,
} from './HeatmapLegendBuckets';

const HeatmapLegend: React.FC = () => {
  const { t } = useTranslation('simulation');
  const buckets = useRecoilValue(simulationHeatmapBuckets);
  const metric = useRecoilValue(analyzeProductMetricSelected);
  const formatter = useFormatter();
  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );

  const descriptorsAll = getAnalyzeProductMetricDescriptorsMap(t, formatter);
  const metricDescriptor = descriptorsAll[metric];

  const bucketProps: HeatmapLegendBucketsProps = {
    title: metricDescriptor?.title ?? t`Default Title`,
    buckets: buckets ?? [],
    metric: metricDescriptor,
    heatmapFilter,
    setHeatmapFilter,
  };

  if (!bucketProps.title || !bucketProps.buckets.length) {
    console.warn('Invalid bucketProps', bucketProps);
    return null; // or return a fallback component
  }

  return <HeatmapLegendBuckets {...bucketProps} />;
};
export default HeatmapLegend;
