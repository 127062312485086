import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PanelFixedHeight,
  panelFixedHeightsCss,
} from '../../../../common/component.types';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { PanelHeader } from '../../../../components/designer/panels/PanelHeader';
import { ContainerCol } from '../../../../components/layout/ContainerFlex';
import ConverterLocationTable from './ConverterLocationTable';

const ConverterExtraDatasetView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [showDatasetTable, setShowDatasetTable] = useState('locations');
  const [height, setHeight] = useState<PanelFixedHeight>('h-min');
  if (_.isNil(showDatasetTable)) return null;

  return (
    <ContainerCol
      overflow
      componentName={`DatasetTableContainer`}
      fullHeight={false}
      className={classNames(
        height !== 'h-min' ? 'min-h-20' : '',
        'ltr:border-l rtl:border-r border-app-panel-dark',
        'relative',
        _.isNil(height) ? null : panelFixedHeightsCss[height],
      )}
    >
      <Suspense
        fallback={
          <LoadingIndicator
            className="left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-10 shadow-2xl"
            absolute
            selfCenter
            backdrop
            message={t`Loading Simulation Data...`}
          />
        }
      >
        <PanelHeader
          title={t`Locations`}
          closable={false}
          adjustable={!_.isNil(height)}
          fixedHeight={height}
          onHeightChange={setHeight}
          onCloseClick={() => setShowDatasetTable(null)}
        />
        {height !== 'h-min' && (
          <ConverterLocationTable
            id={'converter-locations'}
            hideScreenTitle={true}
          />
        )}
      </Suspense>
    </ContainerCol>
  );
};

export default ConverterExtraDatasetView;
