import {
  AssignmentComplianceItemStatus,
  BatchJobStatus,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AssignmentComplianceFallbackRule from '../../../assignmentCompliance/AssignmentComplianceFallbackRule';
import AssignmentComplianceRule, {
  AssignmentComplianceRuleBaseProps,
} from '../../../assignmentCompliance/AssignmentComplianceRule';
import AssignmentPolicyRuleDetailWatcher from '../../../assignmentPolicy/AssignmentPolicyRuleDetailWatcher';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../../common/constants';
import { formatInteger } from '../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import ErrorIndicator from '../../../components/ErrorIndicator';
import InboxZero from '../../../components/InboxZero';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Button } from '../../../components/actions/Button';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import * as Icon from '../../../components/icons';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import { Spacer } from '../../../components/layout/Spacer';
import TitleSection from '../../../components/layout/TitleSection';
import { ActionBar } from '../../../components/nav/ActionBar';
import PolicyRuleSectionTitle from '../../../components/policies/PolicyRuleSectionTitle';
import { PolicyStatContainer } from '../../../components/policies/PolicyStatContainer';
import { PolicyWell } from '../../../components/policies/PolicyWell';
import { Stat } from '../../../components/stats/Stat';
import {
  namedColorsGroupSelectedId,
  namedColorsGroups,
} from '../../../store/namedColors.state';
import SimulationItemSetWrap from '../../hoc/SimulationItemSetWrap';
import useLoadAssignmentComplianceSummary from '../../hooks/useLoadAssignmentComplianceSummary';
import {
  assignmentComplianceDataTableState,
  assignmentComplianceSummary,
  assignmentComplianceSummaryLoadStatus,
  assignmentComplianceUseComplianceColors,
} from '../../store/assignmentCompliance.state';
import { AP_FALLBACK_RULE_ID } from '../../store/assignmentPolicy.default';
import { assignmentPolicyDocument } from '../../store/assignmentPolicy.state';
import {
  simulationCurrent,
  simulationEffectiveAssignmentId,
  simulationShowComplianceTable,
} from '../../store/simulation.state';

const menuAdvancedOptions = [
  // 'Export',
  //'Clear Rules'
  // 'Re-Order',
  'Colour Modes',
] as const;
type MenuOptionsTuple = typeof menuAdvancedOptions;
export type menuAdvancedOptions = MenuOptionsTuple[number];

const ComplianceAssignmentPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const sim = useRecoilValue(simulationCurrent);
  const policy = useRecoilValue(assignmentPolicyDocument);
  const effectiveAssignmentId = useRecoilValue(simulationEffectiveAssignmentId);
  const complianceSummary = useRecoilValue(assignmentComplianceSummary);
  const complianceSummaryLoadStatus = useRecoilValue(
    assignmentComplianceSummaryLoadStatus,
  );
  const [loadComplianceSummary, cancelLoadComplianceSummary] =
    useLoadAssignmentComplianceSummary();

  const [showComplianceTable, setShowComplianceTable] = useRecoilState(
    simulationShowComplianceTable,
  );

  const [useComplianceColor, setUseComplianceColors] = useRecoilState(
    assignmentComplianceUseComplianceColors,
  );
  const [selectedNamedColorGroup, setSelectedNamedColorGroup] = useRecoilState(
    namedColorsGroupSelectedId,
  );
  const namedColorGroups = useRecoilValue(namedColorsGroups);
  const [showColorsSetup, setShowColorsSetup] = useState<boolean>(false);
  const setTableFilter = useSetRecoilState(assignmentComplianceDataTableState);

  useEffect(() => {
    if (complianceSummaryLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    // compliance not triggered
    if (_.isNil(complianceSummary?.status)) {
      loadComplianceSummary({
        simulationId: sim.id,
        assignmentId: effectiveAssignmentId,
      });
    }
    if (
      complianceSummary?.status === BatchJobStatus.CREATED ||
      complianceSummary?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadComplianceSummary({
          simulationId: sim.id,
          assignmentId: effectiveAssignmentId,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadComplianceSummary();
    };
  }, [complianceSummary, effectiveAssignmentId]);

  function showAllNonCompliantTable() {
    setShowComplianceTable(prevState => !prevState);
    setTableFilter({
      searchValues: {
        status: AssignmentComplianceItemStatus.NONCOMPLIANT,
      },
      sortValues: {
        consignee: SortDirection.ASC,
      },
    });
  }

  const hasError =
    complianceSummaryLoadStatus === AsyncLoadStatus.Error ||
    complianceSummary?.status === BatchJobStatus.FAILED ||
    complianceSummary?.status === BatchJobStatus.TERMINATED;

  const isLoading =
    complianceSummary?.status !== BatchJobStatus.READY ||
    complianceSummaryLoadStatus !== AsyncLoadStatus.Ok;

  const totalNonCompliantCount =
    complianceSummary?.summary?.totalSummary?.nonCompliantCount ?? 0;
  const totalNonCompliantCountFormatted = formatInteger(
    complianceSummary?.summary?.totalSummary?.nonCompliantCount ?? 0,
  );

  const hasIssue =
    complianceSummary?.summary?.totalSummary?.nonCompliantCount > 0;

  const totalCount = complianceSummary?.summary?.totalSummary?.totalCount ?? 0;
  const totalCountFormatted = formatInteger(totalCount);

  const compliancePercentage = hasIssue
    ? formatter.formatShare(totalNonCompliantCount / totalCount)
    : null;

  const complianceRulesMap = _.keyBy(
    complianceSummary?.summary?.rulesSummary?.rules,
    r => r.ruleId,
  );

  const fallbackRuleCheckResult = complianceRulesMap[AP_FALLBACK_RULE_ID];
  const unmatchedByLocation =
    complianceSummary?.summary?.rulesSummary?.unmatchedByLocation;

  const policyRuleCounter = policy?.rules?.length;

  return (
    <>
      <AssignmentPolicyRuleDetailWatcher />
      <ScreenTitle
        subtitle={t`Analyse — Compliance`}
        title={t`Storage Compliance`}
        // isSticky // Disabled so it's won't interfere with the Stat sticky-ness.
        helpNavTo={'simulation/compliance/simulation-compliance-policy-storage'}
        icon={Icon.PolicyComplianceAssignment}
      />
      {hasError && (
        <>
          <ErrorIndicator message={t`Issue Loading Compliance Data`} />
          <Button
            label={t`Re-run complince check`}
            onPress={() =>
              loadComplianceSummary({
                simulationId: sim.id,
                assignmentId: effectiveAssignmentId,
                forceRestart: true,
              })
            }
          />
        </>
      )}
      {isLoading && !hasError && (
        <LoadingIndicator selfCenter message={t`Loading Data`} />
      )}
      {!isLoading && !hasError && (
        <>
          <TitleSection
            className="z-400"
            title={
              <PolicyRuleSectionTitle
                isFeasible={!hasError}
                counter={policyRuleCounter}
              />
            }
            hasAction={
              <DropdownSelector
                className="ltr:ml-3 rtl:mr-3"
                DropAlignRight
                buttonTransparent
                vertical
                value={'...'}
                values={[...menuAdvancedOptions]}
                onChange={(option: menuAdvancedOptions) => {
                  switch (option) {
                    case t`Colour Modes`:
                      showColorsSetup
                        ? setShowColorsSetup(false)
                        : setShowColorsSetup(true);
                      break;
                  }
                }}
              />
            }
            inSidebarView
            hasScreenTitle
            titleSummaryClosed={`(${policy?.rules.length})`}
          />

          {showColorsSetup && (
            <ActionBar className="p-1">
              <ButtonSwitchMulti
                buttonType="minimal"
                autoSize
                className="mx-1"
                classNameContainer="border border-menu-400"
                selectedIndex={useComplianceColor ? 0 : 1}
                onClick={index => setUseComplianceColors(index === 0)}
                options={[{ label: t`Status` }, { label: t`Heatmap` }]}
              />

              {!useComplianceColor && (
                <>
                  <Spacer flexspace />
                  <DropdownSelector
                    className="ltr:ml-3 rtl:mr-3"
                    DropAlignRight
                    // buttonTransparent
                    value={selectedNamedColorGroup}
                    values={[null, ..._.keys(namedColorGroups)]}
                    renderValue={id =>
                      namedColorGroups[id]?.title ?? t`Auto Colour`
                    }
                    onChange={groupId => setSelectedNamedColorGroup(groupId)}
                  />
                </>
              )}
            </ActionBar>
          )}

          <PolicyStatContainer noPadding>
            <Stat
              title={t`Non-compliant / Total items`}
              value={
                hasIssue
                  ? `${totalNonCompliantCountFormatted}`
                  : t('All Compliant ({{totalCountFormatted}})', {
                      totalCountFormatted,
                    })
              }
              valuePercent={hasIssue ? compliancePercentage : null}
              valueTotal={hasIssue ? totalCountFormatted : null}
              icon={hasIssue ? Icon.TriangleInfo : Icon.CircleOk}
              isSectionable
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={isLoading}
              transparent={!showAllNonCompliantTable}
              isSelected={showComplianceTable}
              onClick={showAllNonCompliantTable}
              toggleTable
            />
          </PolicyStatContainer>

          <PolicyWell fullHeight>
            {_.isEmpty(policy?.rules) && (
              <InboxZero selfCenter message={t`No Rules found`} />
            )}

            {_.map(policy?.rules, (rule, index) => {
              const ruleCheckResult = complianceRulesMap[rule.id];

              const commonRuleProps: AssignmentComplianceRuleBaseProps = {
                index: index + 1,
                isDisabled: true,
                isCheckLoading: isLoading,
                locationMatch: ruleCheckResult?.matchedByLocation,
                productMatch: ruleCheckResult?.matchedByItem,
              };

              return (
                <AssignmentComplianceRule
                  key={`assignment-compliance-rule-${rule.id}`}
                  ruleId={rule.id}
                  {...commonRuleProps}
                />
              );
            })}
          </PolicyWell>

          <TitleSection title={t`Default Policy`} inPanelView />
          <PolicyWell
            data-component="PolicyWellRuleCompliance"
            fullHeight
            // isDisabled={!canUpdate}
            className={classNames('mb-2 flex-1')}
            classNameChildren={classNames('space-y-2 pb-10')}
          >
            <AssignmentComplianceFallbackRule
              key={`assignment-compliance-rule-fallback-rule`}
              locationMatch={fallbackRuleCheckResult?.matchedByLocation}
              productMatch={fallbackRuleCheckResult?.matchedByItem}
              unmatchedByLocation={unmatchedByLocation}
              isCheckLoading={isLoading}
              isDisabled={true}
            />
          </PolicyWell>
        </>
      )}
    </>
  );
};

export default () => (
  <SimulationItemSetWrap>
    <ComplianceAssignmentPanel />
  </SimulationItemSetWrap>
);
