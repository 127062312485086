import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../components/alerts/Alerts';

export enum LoadingAlertMessageType {
  layout,
  locations,
  analyze,
  optimise,
  heatmap,
  congestion,
  route,
  assignmentHeatmap,
  stackingPolicy,
  assignmentPolicy,
  weightCompliance,
  sizeCompliance,
  assignmentCompliance,
  datasetIssues,
  routingInaccessible,
}

type LayoutLoadingAlertProps = {
  messageType: LoadingAlertMessageType;
};

const LayoutLoadingAlert: React.FC<LayoutLoadingAlertProps> = props => {
  const { t } = useTranslation('app');

  const loadingMessages: Record<LoadingAlertMessageType, string> = {
    [LoadingAlertMessageType.layout]: t`Loading layout`,
    [LoadingAlertMessageType.locations]: t`Loading locations`,
    [LoadingAlertMessageType.analyze]: t`Loading analyse results`,
    [LoadingAlertMessageType.optimise]: t`Loading optimise result`,
    [LoadingAlertMessageType.heatmap]: t`Loading heatmap`,
    [LoadingAlertMessageType.congestion]: t`Loading waypoints heatmap`,
    [LoadingAlertMessageType.assignmentPolicy]: t`Loading Policy locations`,
    [LoadingAlertMessageType.route]: t`Loading Route`,
    [LoadingAlertMessageType.weightCompliance]: t`Loading weight compliance`,
    [LoadingAlertMessageType.sizeCompliance]: t`Loading size compliance`,
    [LoadingAlertMessageType.assignmentCompliance]: t`Loading assignment compliance`,
    [LoadingAlertMessageType.datasetIssues]: t`Loading locations with issues`,
    [LoadingAlertMessageType.routingInaccessible]: t`Loading location by Routing Policy Rule`,
    [LoadingAlertMessageType.assignmentHeatmap]: t`Loading heatmap data`,
    [LoadingAlertMessageType.stackingPolicy]: t`Loading location by Stacking Policy Rule`,
  };
  return (
    <Alert
      hasStatusLoading={true}
      message={loadingMessages[props.messageType]}
    />
  );
};

export default LayoutLoadingAlert;
