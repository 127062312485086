import { MeasurementSystem } from '@warebee/shared/data-access-layout-manager';
import { TFunction } from 'i18next';
import { getCurrencySymbolSettings } from '../common/formatHelper';

export type AgentFilterConfig = {
  path: string[];
  title: string;
  description?: string;
  filterConfig: AgentSettingsEditorConfig;
};

export type AgentFilterGroupConfig = {
  title: string;
  description?: string;
  category?: string;
  filters: AgentFilterConfig[];
};
export type AgentFilterSectionConfig = {
  title: string;
  description?: string;
  counter?: string;
  filterGroups: AgentFilterGroupConfig[];
};

function savePerHour(value: number): number {
  return value / 3600;
}
function loadPerHour(value: number): number {
  return value * 3600;
}

function saveHours(value: number) {
  return value * 3600;
}
function loadHours(value: number) {
  return value / 3600;
}

function saveMins(value: number) {
  return value * 60;
}
function loadMins(value: number) {
  return value / 60;
}

function loadSpeedMetric(value: number): number {
  return value / 100;
}
function saveSpeedMetric(value: number): number {
  return value * 100;
}

function loadSpeedImperial(value: number): number {
  return value / 12;
}
function saveSpeedImperial(value: number): number {
  return value * 12;
}

const savePerVolumeMetric = (value: number) => value / 1000000;
const loadPerVolumeMetric = (value: number) => value * 1000000;

const saveVolumeMetric = (value: number) => value * 1000000;
const loadVolumeMetric = (value: number) => value / 1000000;

// in3
const saveTimePerVolumeImperial = (value: number) => value;
const loadTimePerVolumeImperial = (value: number) => value;

// ft3
const saveTimePerVolumeImperialFt3 = (value: number) => value / 1728;
const loadTimePerVolumeImperialFt3 = (value: number) => value * 1728;

// in3
const saveVolumeImperial = (value: number) => value;
const loadVolumeImperial = (value: number) => value;

// ft3
// To save cubic feet as cubic inches
const saveVolumeImperialFt3 = (value: number) => value * 1728;
const loadVolumeImperialFt3 = (value: number) => value / 1728;

// sec/UOM=> UOM/HOUR
const saveTimePerUOM = (value: number) => (value > 0 ? 3600 / value : 0);
const loadTimePerUOM = (value: number) => (value > 0 ? 3600 / value : 0);

export type AgentEditorSettingsCurrency = {
  hourCost: AgentSettingsEditorConfig;
};

export type AgentEditorSettingsCommon = {
  timeAbsolute: AgentSettingsEditorConfig;
  timePerOrder: AgentSettingsEditorConfig;
  timePerUom: AgentSettingsEditorConfig;
  timePerLine: AgentSettingsEditorConfig;
  timePerWorkShift: AgentSettingsEditorConfig;

  secondsOperation: AgentSettingsEditorConfig;
  energyConsumption: AgentSettingsEditorConfig;
  capacityMaxUomCount: AgentSettingsEditorConfig;
};

export type AgentEditorSettings = AgentEditorSettingsCommon &
  AgentEditorSettingsCurrency & {
    timePerWeight: AgentSettingsEditorConfig;
    timePerVolume: AgentSettingsEditorConfig;

    horizontalSpeed: AgentSettingsEditorConfig;
    verticalSpeed: AgentSettingsEditorConfig;

    capacityWeight: AgentSettingsEditorConfig;
    capacityVolume: AgentSettingsEditorConfig;
  };

export type AgentSettingsEditorConfig = {
  min: number;
  max: number;
  step: number;
  unitOfMeasure: string;
  load?: (value: number) => number;
  save?: (value: number) => number;
  isRequired?: boolean;
};

type fnGetAgentEditor = (
  t: TFunction<'simulation'>,
  currencyCode?: string,
) => AgentSettingsEditorConfig;

// Global
const timeAbsoluteEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 300,
  step: 0.1,
  unitOfMeasure: 'sec',
});

const timePerOrderEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 300,
  step: 0.1,
  unitOfMeasure: t('sec/order', { ns: 'simulation' }),
});

const timePerLineEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 300,
  step: 0.1,
  unitOfMeasure: t('sec/line', { ns: 'simulation' }),
});

const maxUomCapacityEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 1,
  max: 100,
  step: 1,
  unitOfMeasure: t('uom(s)', { ns: 'simulation' }),
});

const timePerUOMEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 1,
  max: 500,
  step: 1,
  load: loadTimePerUOM,
  save: saveTimePerUOM,
  unitOfMeasure: t('UOM/hour', { ns: 'simulation' }),
});

const timePerWorkShiftEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 360,
  step: 1,
  load: loadMins,
  save: saveMins,
  unitOfMeasure: t('min/shift', { ns: 'simulation' }),
});

const energyConsumptionEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.5,
  max: 30,
  step: 0.1,
  load: loadPerHour,
  save: savePerHour,
  unitOfMeasure: t('kWh/h', { ns: 'simulation' }),
});

const secondsOperationEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 24,
  step: 0.1,
  load: loadHours,
  save: saveHours,
  unitOfMeasure: t('h', { ns: 'simulation' }),
});

const hoursCostEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
  currencyCode: string,
) => {
  const [min, max, step] =
    currencyCode === 'RUB' || currencyCode === 'SAR'
      ? [0, 1000, 10]
      : [0, 100, 0.5]; // USD

  return {
    min,
    max,
    step,
    load: loadPerHour,
    save: savePerHour,
    unitOfMeasure: getCurrencySymbolSettings(currencyCode).symbol,
  };
};

//*************** METRIC ************************ */
export const horizontalSpeedMetricEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.1,
  max: 34,
  step: 0.1,
  load: loadSpeedMetric,
  save: saveSpeedMetric,
  unitOfMeasure: t('m/sec', { ns: 'simulation' }),
});

export const verticalSpeedMetricEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.1,
  max: 5,
  step: 0.1,
  load: loadSpeedMetric,
  save: saveSpeedMetric,
  unitOfMeasure: t('m/sec', { ns: 'simulation' }),
});

const timePerWeightMetricEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.5,
  max: 60,
  step: 0.5,
  unitOfMeasure: t('sec/kg', { ns: 'simulation' }),
});

const timePerVolumeMetricEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.5,
  max: 60,
  step: 0.5,
  save: savePerVolumeMetric,
  load: loadPerVolumeMetric,
  unitOfMeasure: t('sec/m3', { ns: 'simulation' }),
});

function getAgentEditorCommonSettings(
  t: TFunction<'simulation'>,
): AgentEditorSettingsCommon {
  const settings: AgentEditorSettingsCommon = {
    timeAbsolute: timeAbsoluteEditorConfig(t),
    timePerOrder: timePerOrderEditorConfig(t),
    timePerUom: timePerUOMEditorConfig(t),
    timePerLine: timePerLineEditorConfig(t),
    timePerWorkShift: timePerWorkShiftEditorConfig(t),
    energyConsumption: energyConsumptionEditorConfig(t),
    secondsOperation: secondsOperationEditorConfig(t),
    capacityMaxUomCount: maxUomCapacityEditorConfig(t),
  };
  return settings;
}

const capacityWeightMetricEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 10000,
  step: 5,
  unitOfMeasure: t('kg', { ns: 'simulation' }),
});

const capacityVolumeMetricEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 20,
  step: 0.1,
  save: saveVolumeMetric,
  load: loadVolumeMetric,
  unitOfMeasure: t('m3', { ns: 'simulation' }),
});

//*************** IMPERIAL ************************ */
// https://www.conger.com/forklift-speed/#:~:text=How%20Fast%20Can%20a%20Forklift,between%205%20and%2015%20mph.
// Walkie stacker: 3.6 mph / 5.28ft/s
// Order picker: 6.5 mph / 9.5ft/s
// 3-wheel forklift: 9.9 mph 14.52ft/s to 10.2 mph 14.52ft/s
// 5,000 lb. warehouse forklift: 10.6 mph 15.5ft/s
// 12,000 lb. warehouse forklift: 13 mph
// 15, 500 lbs.outdoor diesel forklift: 13 mph
// Reach Trucks: 6.5 mph / 9.5ft/s when unloaded and up to 7.5 mph  11ft/s
// Pallet Jack: 3.6 mph / 5.28ft/s

export const horizontalSpeedImperialEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 20,
  step: 0.1,
  load: loadSpeedImperial,
  save: saveSpeedImperial,
  unitOfMeasure: t('ft/sec', { ns: 'simulation' }),
});

export const verticalSpeedImperialEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.1,
  max: 25,
  step: 0.1,
  load: loadSpeedImperial,
  save: saveSpeedImperial,
  unitOfMeasure: t('ft/sec', { ns: 'simulation' }),
});

const timePerWeightImperialEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.1,
  max: 60,
  step: 0.1,
  unitOfMeasure: t('sec/lb', { ns: 'simulation' }),
});

const timePerVolumeImperialEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.1,
  max: 10,
  step: 0.1,
  save: saveTimePerVolumeImperial,
  load: loadTimePerVolumeImperial,
  unitOfMeasure: t('sec/in3', { ns: 'simulation' }),
});

const timePerVolumeImperialEditorConfigFt3: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0.1,
  max: 60,
  step: 0.1,
  save: saveTimePerVolumeImperialFt3,
  load: loadTimePerVolumeImperialFt3,
  unitOfMeasure: t('sec/ft3', { ns: 'simulation' }),
});

const capacityWeightImperialEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 50000,
  step: 1,
  unitOfMeasure: t('lb', { ns: 'simulation' }),
});

const capacityVolumeImperialEditorConfigIn3: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 486,
  step: 1,
  save: saveVolumeImperial,
  load: loadVolumeImperial,
  unitOfMeasure: t('in3', { ns: 'simulation' }),
});

const capacityVolumeImperialEditorConfig: fnGetAgentEditor = (
  t: TFunction<'simulation'>,
) => ({
  min: 0,
  max: 486,
  step: 1,
  save: saveVolumeImperialFt3,
  load: loadVolumeImperialFt3,
  unitOfMeasure: t('ft3', { ns: 'simulation' }),
});

export function getAgentEditorSettings(
  t: TFunction<'simulation'>,
  system: MeasurementSystem,
  currencyCode: string,
): AgentEditorSettings {
  const isImperial = system === MeasurementSystem.IMPERIAL;
  return {
    ...getAgentEditorCommonSettings(t),
    horizontalSpeed: isImperial
      ? horizontalSpeedImperialEditorConfig(t)
      : horizontalSpeedMetricEditorConfig(t),
    verticalSpeed: isImperial
      ? verticalSpeedImperialEditorConfig(t)
      : verticalSpeedMetricEditorConfig(t),
    timePerWeight: isImperial
      ? timePerWeightImperialEditorConfig(t)
      : timePerWeightMetricEditorConfig(t),
    timePerVolume: isImperial
      ? timePerVolumeImperialEditorConfig(t)
      : timePerVolumeMetricEditorConfig(t),
    capacityWeight: isImperial
      ? capacityWeightImperialEditorConfig(t)
      : capacityWeightMetricEditorConfig(t),
    capacityVolume: isImperial
      ? capacityVolumeImperialEditorConfig(t)
      : capacityVolumeMetricEditorConfig(t),
    hourCost: hoursCostEditorConfig(t, currencyCode),
  };
}
