import _ from 'lodash';
import { atom, selector } from 'recoil';
import {
  converterAreaConfiguration,
  converterFloorAreas,
  converterSelectedFloor,
} from './converter.area.state';
import { ConfiguredArea } from './converter.helper';
import {
  converterAreaLayout,
  converterLayoutBuilder,
  converterWizardStepId,
} from './converter.state';
import { stepsWithValidation } from './validation.defaults';
import { validateFloor } from './validation.helper';
import {
  FloorValidationResult,
  LayoutValidationResult,
} from './validation.types';

export const converterSelectedFloorValidationResult =
  selector<FloorValidationResult>({
    key: 'warebee-layout-converter-selected-floor-validation-result',
    get: ({ get }) => {
      const stepId = get(converterWizardStepId);
      const floor = get(converterSelectedFloor);
      const layoutBuilder = get(converterLayoutBuilder);
      const entryPoint = layoutBuilder?.entryPoints?.[floor];
      const areas = get(converterFloorAreas);
      const areaConfigurations = get(converterAreaConfiguration);

      if (!stepsWithValidation.has(stepId) || _.isEmpty(areas)) return null;

      const convertedAreas: ConfiguredArea[] = _.map(areas, a => {
        const config = areaConfigurations?.[a.id];
        if (!config) {
          return null;
        }
        return {
          area: get(converterAreaLayout(config.id)),
          config,
        };
      }).filter(a => a !== null);

      console.time('validation');
      console.log('Validation convertedAreas', convertedAreas);
      const validationResult = validateFloor(floor, convertedAreas, entryPoint);

      console.timeEnd('validation');

      return validationResult;
    },
  });

export const converterValidationResult = atom<LayoutValidationResult>({
  key: 'warebee-layout-converter-validation-result',
  default: {
    floors: [],
  },
});
