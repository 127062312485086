import * as React from 'react';
const RoundSnakeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoundSnakeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M63.93,4C30.77,4,4,30.77,4,63.93s26.77,60.07,59.93,60.07,60.07-26.91,60.07-60.07S97.09,4,63.93,4ZM61.63,39.78c-2.46,0-4.67,1-6.27,2.61-1.59,1.59-2.59,3.81-2.59,6.26,0,3.09,1.56,5.81,3.96,7.39,1.4.95,3.09,1.5,4.91,1.5h7.86c4.94,0,9.78,1.79,13.29,5.27.02.02.05.05.07.07,1.96,1.96,3.59,4.3,4.75,6.87,1.26,2.76,1.99,5.83,2.07,9.04.32,13.14-10.83,23.89-23.98,23.89h-7.89l1.43,6.95c0,2.41-3.09,3.62-4.92,1.93l-5.16-4.84-9.97-9.35c-.56-.53-.85-1.22-.85-1.91s.29-1.38.85-1.91l9.97-9.33,5.16-4.86c1.83-1.69,4.92-.48,4.92,1.93l-1.43,6.95h8.56c2.46,0,4.67-1,6.27-2.59,1.59-1.61,2.59-3.83,2.59-6.27,0-3.07-1.56-5.79-3.94-7.39-1.4-.95-3.09-1.5-4.92-1.5h-8.29c-4.85,0-9.5-1.92-12.93-5.34-1.98-1.98-3.59-4.3-4.75-6.87-1.26-2.76-1.99-5.83-2.07-9.04-.32-13.14,10.83-23.89,23.98-23.89h7.89l-1.43-6.97c0-2.4,3.09-3.62,4.92-1.91l5.16,4.84,9.97,9.35c.56.53.85,1.22.85,1.9,0,.71-.29,1.38-.85,1.93l-9.97,9.33-5.16,4.84c-1.83,1.71-4.92.48-4.92-1.91l1.43-6.95h-8.56Z"
    />
  </svg>
);
export default RoundSnakeIcon;
