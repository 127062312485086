import {
  KyselyPlugin,
  PluginTransformQueryArgs,
  PluginTransformResultArgs,
  QueryResult,
  RootOperationNode,
  UnknownRow,
} from 'kysely';

export type CommentedRootOperationNode = RootOperationNode & {
  comment: string;
};

export function isCommentedNode(
  node: RootOperationNode,
): node is CommentedRootOperationNode {
  return !!(node as any).comment;
}

export class QueryCommentPlugin implements KyselyPlugin {
  constructor(private readonly comment?: string) {}

  transformQuery(args: PluginTransformQueryArgs): RootOperationNode {
    if (this.comment) {
      // we cannot prepend comment immediately, because we have to return node of the same kind as received
      // therefore we attach comment as extra field for further processing by AthenaQueryCompiler
      return {
        ...args.node,
        comment: this.comment,
      } as CommentedRootOperationNode;
    } else {
      return args.node;
    }
  }

  async transformResult(
    args: PluginTransformResultArgs,
  ): Promise<QueryResult<UnknownRow>> {
    return args.result;
  }
}
