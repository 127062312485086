import * as React from 'react';
const CalendarFullIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CalendarFullIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124.49,21.42v11.9H4.49v-11.9c0-3.95,3.23-7.18,7.18-7.18h6.1v6.8c0,2.42,1.97,4.4,4.4,4.4h4.57c2.42,0,4.4-1.97,4.4-4.4v-6.8h66.72s0,6.8,0,6.8c0,2.42,1.97,4.4,4.38,4.4h4.57c2.42,0,4.4-1.97,4.4-4.4v-6.8h6.1c3.98,0,7.18,3.22,7.18,7.18h0Z"
    />
    <path
      strokeWidth={0}
      d="M28.64,6.58v14.47c0,1.05-.85,1.9-1.9,1.9h-4.57c-1.05,0-1.9-.85-1.9-1.9V6.58c0-1.05.85-1.9,1.9-1.9h4.57c1.05,0,1.9.85,1.9,1.9Z"
    />
    <path
      strokeWidth={0}
      d="M108.72,6.58v14.47c0,1.05-.85,1.9-1.9,1.9h-4.57c-1.05,0-1.87-.85-1.87-1.9V6.58c0-1.05.82-1.9,1.87-1.9h4.57c1.05,0,1.9.85,1.9,1.9Z"
    />
    <path
      strokeWidth={0}
      d="M4.49,37.89v78.12c0,3.95,3.23,7.18,7.18,7.18h105.65c3.98,0,7.18-3.23,7.18-7.18V37.89H4.49ZM21.22,108.96h-10.48v-10.48h10.48v10.48ZM21.22,92.5h-10.48v-10.48h10.48v10.48ZM37.39,108.96h-10.48v-10.48h10.48v10.48ZM37.39,92.5h-10.48v-10.48h10.48v10.48ZM53.56,108.96h-10.48v-10.48h10.48v10.48ZM53.56,92.5h-10.48v-10.48h10.48v10.48ZM69.74,108.96h-10.48v-10.48h10.48v10.48ZM69.74,92.51h-10.48v-10.48h10.48v10.48ZM69.74,76.05h-10.48v-10.48h10.48v10.48ZM85.91,108.96h-10.48v-10.48h10.48v10.48ZM85.91,92.5h-10.48v-10.48h10.48v10.48ZM85.91,76.04h-10.48v-10.48h10.48v10.48ZM102.08,92.51h-10.48v-10.48h10.48v10.48ZM102.08,76.05h-10.48v-10.48h10.48v10.48ZM118.25,92.51h-10.48v-10.48h10.48v10.48ZM18.6,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM34.77,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM50.94,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM67.12,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM83.29,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM99.46,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM115.63,50.81h-5.24c-1.45,0-2.62-1.17-2.62-2.62h0c0-1.45,1.17-2.62,2.62-2.62h5.24c1.45,0,2.62,1.17,2.62,2.62h0c0,1.45-1.17,2.62-2.62,2.62ZM118.25,76.05h-10.48v-10.48h10.48v10.48Z"
    />
  </svg>
);
export default CalendarFullIcon;
