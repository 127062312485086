import {
  AssignmentOccupancyLocationStatus,
  AssignmentOccupancyLocationStatusFragment,
  LoadAssignmentOccupancyForLocationsStatusOnlyDocument,
  LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
  LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../../Tw';
import { secureClient } from '../../../GraphQLClient';
import i18n from '../../../i18n';
import {
  OccupancyCategoryDescriptor,
  OccupancyCategoryDescriptorMap,
} from './assignmentOccupancy.types';

const categoryColor = TwTheme.extend.colors.categoryOccupancy;
const categoryTextColor = TwTheme.extend.colors.categoryOccupancyText;

export const getOccupancyCategories = _.memoize(
  (t: TFunction<'simulation'> = i18n.t): OccupancyCategoryDescriptorMap =>
    _.keyBy(
      [
        // {
        //   key: '100+',
        //   tag: '100%+',
        //   title: t('100%+', { ns: 'simulation' }),
        //   color: categoryColor[150],
        //   textColor: categoryTextColor[100],
        //   fromVolumePercentile: 1.1,
        //   toVolumePercentile: null,
        // },
        {
          key: '100',
          tag: '100+',
          title: t('> 100%', { ns: 'simulation' }),
          color: categoryColor[150],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 1,
          toVolumePercentile: null,
        },
        {
          key: '80-100',
          tag: '80-100',
          title: t('80-100%', { ns: 'simulation' }),
          color: categoryColor[100],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.8,
          toVolumePercentile: 1,
        },
        // {
        //   key: '80-90',
        //   tag: '80-90',
        //   title: t('80-90%', { ns: 'simulation' }),
        //   color: categoryColor[80],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.8,
        //   toVolumePercentile: 0.9,
        // },
        // {
        //   key: '70-80',
        //   tag: '70-80',
        //   title: t('70-80%', { ns: 'simulation' }),
        //   color: categoryColor[70],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.7,
        //   toVolumePercentile: 0.8,
        // },
        {
          key: '60-80',
          tag: '60-80',
          title: t('60-80%', { ns: 'simulation' }),
          color: categoryColor[70],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.6,
          toVolumePercentile: 0.8,
        },
        {
          key: '40-60',
          tag: '40-60',
          title: t('40-60%', { ns: 'simulation' }),
          color: categoryColor[50],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.4,
          toVolumePercentile: 0.6,
        },
        // {
        //   key: '50-60',
        //   tag: '50-60',
        //   title: t('50-60', { ns: 'simulation' }),
        //   color: categoryColor[50],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.6,
        //   toVolumePercentile: 0.5,
        // },
        // {
        //   key: '40-50',
        //   tag: '40-50',
        //   title: t('40-50', { ns: 'simulation' }),
        //   color: categoryColor[40],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.5,
        //   toVolumePercentile: 0.4,
        // },
        {
          key: '20-40',
          tag: '20-40',
          title: t('20-40%', { ns: 'simulation' }),
          color: categoryColor[30],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0.2,
          toVolumePercentile: 0.4,
        },
        // {
        //   key: '30-40',
        //   tag: '30-40',
        //   title: t('30-40', { ns: 'simulation' }),
        //   color: categoryColor[30],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.4,
        //   toVolumePercentile: 0.3,
        // },
        // {
        //   key: '20-30',
        //   tag: '20-30',
        //   title: t('20-30', { ns: 'simulation' }),
        //   color: categoryColor[20],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.3,
        //   toVolumePercentile: 0.2,
        // },
        {
          key: '0-20',
          tag: '0-20',
          title: t('0-20%', { ns: 'simulation' }),
          color: categoryColor[20],
          textColor: categoryTextColor[10],
          fromVolumePercentile: 0,
          toVolumePercentile: 0.2,
        },

        // {
        //   key: '10-20',
        //   tag: '10-20',
        //   title: t('10-20', { ns: 'simulation' }),
        //   color: categoryColor[10],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.2,
        //   toVolumePercentile: 0.1,
        // },
        // {
        //   key: '0-10',
        //   tag: '0-10',
        //   title: t('0-10', { ns: 'simulation' }),
        //   color: categoryColor[10],
        //   textColor: categoryTextColor[10],
        //   fromVolumePercentile: 0.1,
        //   toVolumePercentile: 0,
        // },
        {
          key: 'empty',
          tag: 'EMPTY',
          title: t('Empty', { ns: 'simulation' }),
          color: categoryColor['empty'],
          textColor: categoryTextColor[0],
          fromVolumePercentile: null,
          toVolumePercentile: 0,
        },
        {
          key: 'unknown',
          tag: 'UNKNOWN',
          title: t('Unknown', { ns: 'simulation' }),
          color: categoryColor['unknown'],
          textColor: categoryTextColor['Unknown'],
          fromVolumePercentile: null,
          toVolumePercentile: null,
        },
      ],

      'key',
    ) as unknown as OccupancyCategoryDescriptorMap,
);

const categories = _.values(getOccupancyCategories());
export function getOccupancyCategory(
  volumeShare: number,
): OccupancyCategoryDescriptor {
  const targetCategory = _.find(categories, c => {
    if (_.isNil(volumeShare))
      return c.fromVolumePercentile === null && c.toVolumePercentile === null;
    return (
      volumeShare > (c.fromVolumePercentile ?? Number.MIN_SAFE_INTEGER) &&
      volumeShare <= (c.toVolumePercentile ?? Number.MAX_SAFE_INTEGER)
    );
  });
  return targetCategory;
}

export function getOccupancyCategoryFull(
  volumeShare: number,
  categories: OccupancyCategoryDescriptorMap,
): OccupancyCategoryDescriptor {
  return categories[getOccupancyCategory(volumeShare)?.key ?? ''];
}

export type LoadOccupancyHeatmapParams = {
  simulationId;
  areaId: string;
  level?: number;
  bayId?: string;
  locationId?: string;
};

export async function loadOccupancyHeatmap(
  params: LoadOccupancyHeatmapParams,
): Promise<Record<string, AssignmentOccupancyLocationStatusFragment>> {
  const { simulationId } = params;

  const response = await secureClient.query<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  >({
    query: LoadAssignmentOccupancyForLocationsStatusOnlyDocument,
    variables: {
      simulationId,
      page: {
        limit: null,
        skip: 0,
      },
      filter: {
        planeId: params.areaId ? [params.areaId] : null,
        bayId: params.bayId ? [params.bayId] : null,
        level: params.level ? [params.level] : null,
      },
    },
  });
  const locationMap = _.keyBy(
    response.data?.simulation.assignmentOccupancy?.locations.content,
    locCompliance => locCompliance.locationId,
  );
  return locationMap;
}

let prev = 0;
export const categoryOccupancyWidths = _(getOccupancyCategories())
  .map(c => [c.fromVolumePercentile, c.toVolumePercentile])
  .flatten()
  .uniq()
  .compact()
  .filter(v => v > 0)
  .sort()
  .map(v => {
    const diff = v - prev;
    prev = v;
    return Math.round(diff * 100);
  })
  .value();

export function getAssignmentOccupancyStatusMap(
  t: TFunction<'simulation'>,
): Record<AssignmentOccupancyLocationStatus, string> {
  return {
    [AssignmentOccupancyLocationStatus.EMPTY]: t('Empty', { ns: 'simulation' }),
    [AssignmentOccupancyLocationStatus.OCCUPIED]: t('Occupied', {
      ns: 'simulation',
    }),
    [AssignmentOccupancyLocationStatus.PARTIALLY_UNKNOWN_ITEMS_VOLUME]: t(
      'Partial Vol.',
      { ns: 'simulation' },
    ),
    [AssignmentOccupancyLocationStatus.UNKNOWN_ITEMS_VOLUME]: t(
      'Unknown Vol.',
      { ns: 'simulation' },
    ),
    [AssignmentOccupancyLocationStatus.UNKNOWN_LOCATION_VOLUME]: t(
      'Unknown Loc. Vol.',
      { ns: 'simulation' },
    ),
  };
}
