import * as React from 'react';
const PolicyWaveHistoryIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaveHistoryIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M86.95,22.33h-18.5c-1.24,0-2.24,1-2.24,2.24,0,1.24,1,2.24,2.24,2.24h18.5c1.24,0,2.24-1,2.24-2.24s-1-2.24-2.24-2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M69.74,14.43h-18.5c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24h18.5c1.24,0,2.24-1,2.24-2.24s-1-2.24-2.24-2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M49.01,5.25h-18.5c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24h18.5c1.24,0,2.24-1,2.24-2.24s-1-2.24-2.24-2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M82.73,37.46c0,1.24,1,2.24,2.24,2.24h13.75c1.24,0,2.24-1,2.24-2.24,0-1.24-1-2.24-2.24-2.24h-13.75c-1.24,0-2.24,1-2.24,2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M110.49,48.11h-18.79c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24h18.79c1.24,0,2.24-1,2.24-2.24,0-1.24-1-2.24-2.24-2.24Z"
    />
    <path
      strokeWidth={0}
      d="M124.5,63.24c0-1.24-1-2.24-2.24-2.24h-26.07c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24h26.07c1.24,0,2.24-1,2.24-2.24Z"
    />
    <path
      strokeWidth={0}
      d="M77.62,112.56c0-1.24-1-2.24-2.24-2.24h-18.97c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24h18.97c1.24,0,2.24-1,2.24-2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M58.08,121.64c0-1.24-1-2.24-2.24-2.24h-18.97c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24h18.97c1.24,0,2.24-1,2.24-2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M75.45,104.15h11.5c1.24,0,2.24-1,2.24-2.24s-1-2.24-2.24-2.24h-11.5c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24Z"
    />
    <path
      strokeWidth={0}
      d="M84.97,91.26h13.75c1.24,0,2.24-1,2.24-2.24,0-1.24-1-2.24-2.24-2.24h-13.75c-1.24,0-2.24,1-2.24,2.24,0,1.24,1,2.24,2.24,2.24h0Z"
    />
    <path
      strokeWidth={0}
      d="M91.7,78.37h18.79c1.24,0,2.24-1,2.24-2.24s-1-2.24-2.24-2.24h-18.79c-1.24,0-2.24,1-2.24,2.24s1,2.24,2.24,2.24Z"
    />
    <path
      strokeWidth={0}
      d="M46.9,26.82c-20.53,0-37.24,16.7-37.24,37.24h-5.54l13.63,16.07,13.63-16.07h-7.05c0-12.44,10.12-22.56,22.56-22.56s22.56,10.12,22.56,22.56-10.12,22.56-22.56,22.56v14.68c20.53,0,37.24-16.7,37.24-37.24s-16.7-37.24-37.24-37.24ZM55.69,97.75c-1.31.31-2.63-.51-2.94-1.83l-.92-3.96c-.31-1.31.51-2.63,1.83-2.94,1.31-.31,2.63.51,2.94,1.83l.92,3.96c.31,1.32-.51,2.63-1.83,2.94ZM21.73,60.58c-.15,1.34-1.37,2.3-2.71,2.15l-4.04-.46c-1.34-.16-2.31-1.37-2.15-2.71.15-1.34,1.37-2.3,2.71-2.15l4.04.46c1.34.15,2.31,1.37,2.15,2.71ZM26.38,49.16c-.76,1.11-2.29,1.4-3.4.64l-3.35-2.29c-1.11-.76-1.4-2.29-.64-3.4.76-1.11,2.28-1.4,3.4-.64l3.35,2.3c1.11.76,1.4,2.28.64,3.4ZM35.81,41.23c-1.19.63-2.68.17-3.31-1.02l-1.89-3.59c-.63-1.2-.17-2.68,1.02-3.3,1.19-.63,2.67-.18,3.3,1.02l1.9,3.59c.63,1.19.17,2.67-1.02,3.3ZM48.8,36.18c0,1.35-1.1,2.45-2.45,2.45s-2.45-1.1-2.45-2.45v-4.06c0-1.35,1.1-2.45,2.45-2.45s2.45,1.09,2.45,2.45v4.06ZM63.6,36.62l-1.9,3.59c-.63,1.19-2.11,1.65-3.31,1.02-1.19-.63-1.65-2.11-1.02-3.3l1.9-3.59c.63-1.2,2.11-1.65,3.3-1.02,1.19.63,1.65,2.11,1.02,3.3ZM67.83,49.16c-.76-1.11-.48-2.64.64-3.4l3.35-2.3c1.12-.76,2.64-.48,3.4.64.76,1.11.48,2.64-.64,3.4l-3.35,2.29c-1.11.76-2.64.48-3.4-.64ZM70.33,89.88c-1.02.88-2.57.78-3.45-.24l-2.66-3.07c-.89-1.02-.78-2.57.24-3.45,1.02-.89,2.56-.78,3.45.25l2.66,3.07c.89,1.02.78,2.57-.24,3.45ZM79.6,76.08c-.49,1.26-1.91,1.88-3.17,1.39l-3.79-1.47c-1.26-.49-1.89-1.91-1.39-3.17.49-1.26,1.91-1.88,3.17-1.39l3.79,1.47c1.26.49,1.88,1.91,1.4,3.17ZM79.22,62.26l-4.04.46c-1.34.15-2.56-.81-2.71-2.15-.16-1.34.81-2.56,2.15-2.71l4.04-.46c1.35-.15,2.56.81,2.71,2.15.15,1.34-.81,2.56-2.15,2.71Z"
    />
    <path
      strokeWidth={0}
      d="M61.84,63.65h-12.06c-.27-.38-.6-.71-.98-.98v-12.75c0-1.35-1.1-2.45-2.45-2.45s-2.45,1.09-2.45,2.45v12.75c-1.07.77-1.77,2.01-1.77,3.42,0,2.33,1.89,4.22,4.22,4.22,1.41,0,2.66-.7,3.42-1.77h12.06c1.35,0,2.45-1.1,2.45-2.45,0-1.35-1.09-2.45-2.45-2.45Z"
    />
  </svg>
);
export default PolicyWaveHistoryIcon;
