import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorContainer from '../containers/ErrorContainer';
import { ErrorDetails } from '../store/error.state';
import { Button } from './actions/Button';
import * as Icon from './icons';
import { Spacer } from './layout/Spacer';
import { ActionBar } from './nav/ActionBar';

export type ErrorProps = {
  errors: ErrorDetails[];
  onClose: (index: number[]) => void;
  closable?: boolean;
};

type ErrorGroup = {
  indexes: number[];
  errors: ErrorDetails[];
};

const ErrorPopover: React.FC<ErrorProps> = props => {
  const { t } = useTranslation('errors');

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const errorGroups: Record<string, ErrorGroup> = _.reduce(
    props.errors,
    (acc, error, index) => {
      const groupKey = error.title;
      return {
        ...acc,
        [groupKey]: {
          indexes: [...(acc[groupKey]?.indexes ?? []), index],
          errors: [...(acc[groupKey]?.errors ?? []), error],
        },
      };
    },
    {},
  );

  return (
    <Popover
      data-component="ErrorPopover"
      className={`absolute top-1 ltr:right-1 rtl:left-1 z-alert select-text`}
    >
      {_.map(errorGroups, (errorGroup, groupTitle) => {
        const count = errorGroup.indexes.length;
        const suffix =
          count > 1
            ? t(`And {{errorCount}} more errors.`, { errorCount: count - 1 })
            : '';

        const er = _.head(errorGroup.errors);
        return (
          <Popover.Panel
            key={`error-popover-${groupTitle}`}
            static
            className={`px-0 py-1 mb-4 text-white w-80 rounded`}
          >
            <div
              className={`flex flex-col p-2 bg-alerts-error/70 backdrop-blur-lg backdrop-saturate-110 rounded-t`}
            >
              <div className={`flex items-start mb-2`}>
                <div
                  className={`flex-1 flex text-xl items-start ltr:pl-1 rtl:pr-1`}
                >
                  <Icon.TriangleInfo className="w-6 h-6 pb-0.5 pt-1 ltr:mr-1 rtl:ml-1 fill-current ltr:ml-1 rtl:mr-1" />
                  <div
                    className={`flex-1 ltr:ml-1 rtl:mr-1 ltr:pl-2 rtl:pr-2 h-full ltr:border-l rtl:border-r border-app-panel-dark/30 select-text`}
                  >
                    <div>
                      <span>{groupTitle ? groupTitle : er.details}</span>
                      {suffix && (
                        <span>
                          {` `}
                          {suffix}
                        </span>
                      )}
                    </div>

                    <button
                      className={classNames(
                        'opacity-75 uppercase text-sm border border-menu rounded px-2 flex items-center',
                      )}
                      type="button"
                      onClick={() => setShowDetails(!showDetails)}
                    >
                      {showDetails
                        ? t`Hide Error Details`
                        : t`Show Error Details`}

                      <Icon.MenuDotsHorizontal
                        className={classNames(
                          'w-6 h-6',
                          'pb-0.5 pt-1',
                          'ltr:ml-1 rtl:mr-1',
                          'fill-current',
                        )}
                      />
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  className={classNames(
                    'ltr:ml-4 rtl:pr-4',
                    'hover:bg-app-panel-dark/50 rounded-full',
                    'w-7 h-7',
                    'flex items-center justify-center ',
                    'text-menu-text/60 hover:text-menu-text',
                  )}
                  aria-label={`Close Error`}
                  onClick={() => props.onClose(errorGroup.indexes)}
                >
                  <Icon.Close className={classNames('w-4 h-4 fill-current')} />
                </button>
              </div>

              {showDetails && er.callStack && (
                <>
                  <h4
                    className={classNames('uppercase text-xs mt-4 mb-1')}
                  >{t`Error Details`}</h4>
                  <ErrorContainer
                    icon={Icon.TriangleInfo}
                    id={`error-stack-${er.id}`}
                    title={
                      er.details
                        ? er.details
                        : er.callStack.split(' ').slice(0, 6).join(' ')
                    }
                    collapsible
                  >
                    <div
                      className={classNames(
                        'p-3',
                        'overflow-auto',
                        'min-h-5 max-h-80',
                        'rounded',
                        'border border-menu/50',
                        'text-xs',
                        'select-text',
                      )}
                    >
                      {er.callStack}
                    </div>
                  </ErrorContainer>
                </>
              )}
            </div>
            <ActionBar
              transparent
              className={classNames(
                'bg-alerts-error/80 backdrop-blur-lg backdrop-saturate-110 p-2 rounded-b',
              )}
            >
              <Spacer flexspace />
              <Button
                buttonSize="xs"
                buttonType="basic"
                className={classNames('border border-text-light')}
                onPress={() => props.onClose(errorGroup.indexes)}
              >{t`Dismiss`}</Button>
            </ActionBar>
          </Popover.Panel>
        );
      })}
    </Popover>
  );
};
export default ErrorPopover;
