import { ProductCategoryDescriptorExport } from '@warebee/shared/export-converter';

export const productCategories = ['S', 'A', 'B', 'C', 'D'] as const;
export type ProductCategory = (typeof productCategories)[number];

export type ProductCategoryDescriptorBase = ProductCategoryDescriptorExport & {
  key: ProductCategory;
  sortIndex: number;
  color?: string;
  textColor?: string;
  productShareTarget?: number;
};

export type ProductCategoryDescriptor = ProductCategoryDescriptorBase & {
  title?: string;
  description?: string;
};

export type ProductCategoryWithResults = ProductCategoryDescriptor & {
  productShare: number;
};

export type ProductCategoryDescriptorMap = Record<
  ProductCategory,
  ProductCategoryDescriptor
>;

export type ProductCategoryFilter = Record<ProductCategory, boolean>;
