import { useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  actualityHqTableData,
  actualityHqTableDataState,
} from '../store/actuality.hq.state';
import { actualityMainViewType } from '../store/actuality.state';
import { ActualityHqDataColumn } from '../store/datasetQueries/actualityHqDataRows';

function useShowFilteredActualityHqTable() {
  const setViewAs = useSetRecoilState(actualityMainViewType);
  const setTableState = useSetRecoilState(actualityHqTableDataState);
  const resetTableData = useResetRecoilState(actualityHqTableData);

  return (searchValues: Partial<Record<ActualityHqDataColumn, string>>) => {
    resetTableData();
    setTableState({
      sortValues: {},
      searchValues,
    });
  };
}

export default useShowFilteredActualityHqTable;
