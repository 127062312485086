import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';

const StepLocationSetup: React.FC = () => {
  const { t } = useTranslation('designer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Aisles, Locations and Bays`}
        title={t`Setup Locations`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.LocationPallet}
          question={t`How to change location dimensions?`}
        />
        <HelpContainer
          id={'designer/03-setup-locations-dimensions'}
          hasPadding
        />
        {/* <HelperMessage hasPadding>
          {t`Location occupies the physical space that consists of location dimensions from your data and gaps between the location to the rack beam and neighbouring location or rack column.`}
          <br />
          {t`You can change the gaps and/or individual location dimensions in this step.`}
          <br />
          <br />
          <StepListItemHelper stepCountId="1" title={t`Location Gaps`}>
            {t`Change gap to the beam above, neighbouring location or back wall.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="2" title={t`Location Dimensions`}>
            {t`Modify dimensions of the individual location.`}
          </StepListItemHelper>
        </HelperMessage> */}
      </InputQA>
    </PanelBody>
  );
};
export default StepLocationSetup;
