import * as React from 'react';
const PolicyAssignmentRouteIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAssignmentRouteIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M86.76,37.92c-.46-.46-1.01-.7-1.63-.7h-17.8s0-7.05,0-11.17-7.65-3.74-7.65-.27c0,5.42,0,11.42,0,11.42h-15.49c-1.28-.01-2.32,1.03-2.32,2.31v16.12c-.02,1.28,1.02,2.32,2.31,2.32h15.49s0,6.66,0,6.66h-21.8c-.62-.01-1.16.22-1.63.68l-8.07,7.13c-.85.93-.85,2.32,0,3.25l8.06,7.99c.46.46,1.01.7,1.63.7h21.8s0,34.92,0,34.92c0,1.28,3.04,2.32,4.32,2.32h0c1.28,0,3.32-1.04,3.32-2.32v-34.9s13.49.01,13.49.01c1.28,0,2.32-1.04,2.32-2.32v-15.12c.02-1.28-1.02-2.32-2.31-2.32h-13.49s0-6.66,0-6.66h17.8c.62.01,1.16-.22,1.63-.68l8.07-8.13c.85-.93.85-2.32,0-3.25l-8.06-7.99Z"
    />
  </svg>
);
export default PolicyAssignmentRouteIcon;
