import { AssignmentPolicyRuleMatchType } from '@warebee/shared/engine-model';
import {
  Field,
  ID,
  InputType,
  Int,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { BaySide } from '../layout-import/create-layout-import-location.input';

export enum AssignmentPolicyRuleStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

registerEnumType(AssignmentPolicyRuleStatus, {
  name: 'AssignmentPolicyRuleStatus',
});

export const ASSIGNMENT_POLICY_RULE_STATUS_ALL: AssignmentPolicyRuleStatus[] = [
  AssignmentPolicyRuleStatus.ENABLED,
  AssignmentPolicyRuleStatus.DISABLED,
];

export const ASSIGNMENT_POLICY_RULE_TYPE: AssignmentPolicyRuleMatchType[] = [
  AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION,
  AssignmentPolicyRuleMatchType.LOCATION_MUST_CONTAIN_ITEM,
  AssignmentPolicyRuleMatchType.STRICT_MATCH,
];

@InputType()
export class AssignmentPolicyImportLineInput {
  @Field(() => ID, { nullable: true })
  consignee?: string;

  @Field(() => ID, { nullable: true })
  sku?: string;

  @Field(() => ID, { nullable: true })
  skuGroup?: string;

  @Field(() => ID, { nullable: true })
  subGroup?: string;

  @Field(() => ID, { nullable: true })
  transportClass?: string;

  @Field(() => ID, { nullable: true })
  stockCategory?: string;

  @Field(() => ID, { nullable: true })
  storageClass?: string;

  @Field(() => ID, { nullable: true })
  pollutionClass?: string;

  @Field(() => ID, { nullable: true })
  locationId?: string;

  @Field(() => BaySide, { nullable: true })
  baySide?: BaySide;

  @Field(() => ID, { nullable: true })
  locationFrom?: string;

  @Field(() => ID, { nullable: true })
  locationTo?: string;

  @Field(() => Int, { nullable: true })
  levelFrom?: number;

  @Field(() => Int, { nullable: true })
  levelTo?: number;

  @Field(() => Int, { nullable: true })
  bayFrom?: string;

  @Field(() => Int, { nullable: true })
  bayTo?: string;

  @Field(() => Int, { nullable: true })
  aisleFrom?: string;

  @Field(() => Int, { nullable: true })
  aisleTo?: string;

  @Field(() => ID, { nullable: true })
  congestionZone?: string;

  @Field(() => AssignmentPolicyRuleStatus, { nullable: true })
  status?: AssignmentPolicyRuleStatus;

  @Field(() => AssignmentPolicyRuleMatchType, { nullable: true })
  ruleType?: AssignmentPolicyRuleMatchType;
}
