import * as React from 'react';
const DesignerAreaLockConnectionIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAreaLockConnectionIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M3,3.58v12.19s4.57,0,4.57,0v39.62s-4.57,0-4.57,0v12.19s12.19,0,12.19,0v-4.57s18.2,0,18.2,0c.13-1.03.31-2.05.52-3.05H15.19s0-4.57,0-4.57h-4.57s0-39.62,0-39.62h4.57s0-4.57,0-4.57h39.62s0,4.57,0,4.57h4.57s0,17.45,0,17.45c1-.27,2.02-.49,3.05-.68V15.77s4.57,0,4.57,0V3.58s-12.19,0-12.19,0v4.57s-39.62,0-39.62,0V3.58s-12.19,0-12.19,0Z"
    />
    <path
      strokeWidth={0}
      d="M16.71,53.87h19.12c3.37-8.06,9.61-14.61,17.45-18.38v-18.19s-36.57,0-36.57,0v36.57Z"
    />
    <g opacity={0.7}>
      <path
        strokeWidth={0}
        d="M125,125.58v-12.19s-4.57,0-4.57,0v-39.62s4.57,0,4.57,0v-12.19s-12.19,0-12.19,0v4.57s-18.2,0-18.2,0c-.13,1.03-.31,2.05-.52,3.05h18.73s0,4.57,0,4.57h4.57s0,39.62,0,39.62h-4.57s0,4.57,0,4.57h-39.62s0-4.57,0-4.57h-4.57s0-17.45,0-17.45c-1,.27-2.02.49-3.05.68v16.78s-4.57,0-4.57,0v12.19s12.19,0,12.19,0v-4.57s39.62,0,39.62,0v4.57s12.19,0,12.19,0Z"
      />
      <path
        strokeWidth={0}
        d="M111.29,75.3h-19.12c-3.37,8.06-9.61,14.61-17.45,18.38v18.19s36.57,0,36.57,0v-36.57Z"
        opacity={0.5}
      />
    </g>
    <path
      strokeWidth={0}
      d="M63.89,54.7c-2.58,0-4.62,2.09-4.62,4.62v2.84h9.2s.04,0,.04,0v-2.84c0-2.58-2.09-4.62-4.62-4.62Z"
    />
    <path
      strokeWidth={0}
      d="M80.55,48.23c-9.13-9.13-23.98-9.13-33.1,0-9.13,9.13-9.13,23.98,0,33.1,9.13,9.13,23.98,9.13,33.1,0,9.13-9.13,9.13-23.98,0-33.1ZM74.33,76.25c0,1.2-.98,2.18-2.18,2.18h-16.62c-1.2,0-2.18-.98-2.18-2.18v-11.91c0-1.2.98-2.18,2.18-2.18h.18v-2.84c0-4.49,3.69-8.18,8.18-8.18,4.49,0,8.18,3.69,8.18,8.18v2.84h.04c1.2,0,2.13.93,2.22,2.13v11.95Z"
    />
  </svg>
);
export default DesignerAreaLockConnectionIcon;
