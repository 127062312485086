import {
  ItemFilterIntersectionFragment,
  StackingPolicyFallbackRuleFragment,
  StackingPolicyFragment,
  StackingPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../../layout/viewer/store/viewer.state';
import { collapsibleStateAtom } from '../../../store/collapsible.state';
import { sidebarStateByType } from '../../../store/sidebar.state';
import { simulationCurrent } from '../simulation.state';
import { simulationWizardSelectedStepId } from '../simulation.wizard.state';
import {
  loadStackingPolicyItems,
  loadStackingPolicyStats,
} from './stackingPolicy.helper';
import {
  StackingPolicyItem,
  StackingPolicySelectionIdentity,
} from './stackingPolicy.types';

const getKey = (postfix: string) => `warebee-stacking-policy-${postfix}`;

export const stackingPolicyDocument = atom<StackingPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const stackingPolicyIsDefined = selector<boolean>({
  key: getKey('is-defined'),
  get: ({ get }) => {
    const policy = get(stackingPolicyDocument);
    return (
      !_.isEmpty(policy?.topRules) ||
      !_.isEmpty(policy.bottomRules) ||
      !_.isNil(policy.fallbackRule?.subcategories)
    );
  },
});

export const stackingPolicyRule = selectorFamily<
  StackingPolicyRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(stackingPolicyDocument);
      const allRules = [
        ...(policy?.topRules ?? []),
        ...(policy?.bottomRules ?? []),
      ];
      return _.find(allRules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, newRule: StackingPolicyRuleFragment) => {
      const policy = get(stackingPolicyDocument);
      set(stackingPolicyDocument, {
        ...policy,
        topRules: _.map(policy.topRules, rule =>
          rule.id === ruleId ? newRule : rule,
        ),
        bottomRules: _.map(policy.bottomRules, rule =>
          rule.id === ruleId ? newRule : rule,
        ),
      });
    },
});

export const stackingPolicyFallbackRule =
  selector<StackingPolicyFallbackRuleFragment>({
    key: getKey('rule-by-id'),
    get: ({ get }) => get(stackingPolicyDocument)?.fallbackRule,
    set: ({ get, set }, newRule: StackingPolicyFallbackRuleFragment) => {
      const policy = get(stackingPolicyDocument);
      set(stackingPolicyDocument, {
        ...policy,
        fallbackRule: newRule,
      });
    },
  });

export const stackingPolicySelectedIdentityAtom =
  atom<StackingPolicySelectionIdentity>({
    key: getKey('selected-identity-atom'),
    default: null,
  });

export const stackingPolicySelectedIdentity =
  selector<StackingPolicySelectionIdentity>({
    key: getKey('selected-identity'),
    get: ({ get }) => get(stackingPolicySelectedIdentityAtom),
    set: ({ get, set }, value: StackingPolicySelectionIdentity) => {
      set(stackingPolicySelectedIdentityAtom, value);

      if (!_.isNil(value?.ruleId)) {
        set(collapsibleStateAtom, {
          ...get(collapsibleStateAtom),
          [value.ruleId]: { isCollapsed: false },
        });
      }
      const shouldOpenEditor =
        !_.isNil(value?.productFilterId) ||
        value?.isSubcategoriesFilterSelected;

      const policyEditPanelState = get(
        sidebarStateByType('sidebar-stacking-policy-editor'),
      );
      set(sidebarStateByType('sidebar-stacking-policy-editor'), {
        isHidden: false,
        isCollapsed: !policyEditPanelState.isPinned
          ? !shouldOpenEditor
          : policyEditPanelState.isCollapsed,
      });
    },
  });

export const stackingPolicySelectedFilterSet =
  selector<ItemFilterIntersectionFragment>({
    key: getKey('selected-filter-set'),
    get: ({ get }) => {
      const identity = get(stackingPolicySelectedIdentity);
      if (!identity.productFilterId || _.isNil(identity.ruleId)) return null;
      const rule = get(stackingPolicyRule(identity.ruleId));

      return _(rule.itemsMatch?.anyOf).find(
        filterSet => filterSet.id === identity.productFilterId,
      );
    },

    set: ({ get, set }, value: ItemFilterIntersectionFragment) => {
      const identity = get(stackingPolicySelectedIdentity);
      if (!identity.productFilterId || _.isNil(identity.ruleId)) {
        console.error('Error while updating item filter in stacking policy');
        return null;
      }
      const rule = get(stackingPolicyRule(identity.ruleId));
      set(stackingPolicyRule(identity.ruleId), {
        ...rule,
        itemsMatch: {
          anyOf: rule.itemsMatch.anyOf.map(filterSet =>
            filterSet.id === value.id ? value : filterSet,
          ),
        },
      });
    },
  });

export const stackingPolicyLocationByLevel = selector<
  Record<string, StackingPolicyItem[]>
>({
  key: getKey('locations-per-level'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(stackingPolicyDocument);

    const layoutId = sim?.layout?.id;
    const assignmentId = sim?.assignment?.id;
    const itemSetId = sim?.itemSet?.id;
    if (_.isNil(layoutId) || _.isNil(level) || stepId !== 'policy-stacking')
      return null;
    return await loadStackingPolicyItems({
      layoutId,
      assignmentId,
      itemSetId,
      policy,
      planeId,
      level,
    });
  },
});

export const stackingPolicyBayLocations = selector<
  Record<string, StackingPolicyItem[]>
>({
  key: getKey('locations-per-bay-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const bayId = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(stackingPolicyDocument);

    const layoutId = sim?.layout?.id;
    const assignmentId = sim?.assignment?.id;
    const itemSetId = sim?.itemSet?.id;
    if (_.isNil(layoutId) || _.isNil(bayId) || stepId !== 'policy-stacking')
      return null;

    return await loadStackingPolicyItems({
      layoutId,
      assignmentId,
      itemSetId,
      policy,
      planeId,
      bayId,
    });
  },
});

export const stackingPolicyRulesStats = selector<Record<string, number>>({
  key: getKey('rules-stats'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const policy = get(stackingPolicyDocument);
    const stepId = get(simulationWizardSelectedStepId);

    const layoutId = sim?.layout?.id;
    const assignmentId = sim?.assignment?.id;
    const itemSetId = sim?.itemSet?.id;
    if (_.isNil(layoutId) || stepId !== 'policy-stacking') return null;

    return await loadStackingPolicyStats({
      layoutId,
      assignmentId,
      itemSetId,
      policy,
    });
  },
});
