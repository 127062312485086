import * as React from 'react';
const ViewGraphIcon = props => (
  <svg
    aria-hidden="true"
    data-name="ViewGraphIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        d="m117.81,33.46c-.92-.92-21.47-22.49-47.42-22.49s-46.51,21.57-47.42,22.49c-1.22,1.32-1.22,3.36,0,4.68.92.92,21.47,22.49,47.42,22.49s46.51-21.57,47.42-22.49c1.22-1.32,1.22-3.36,0-4.68Zm-47.42,19.34c-9.36,0-17-7.63-17-17s7.63-17,17-17,17,7.63,17,17-7.63,17-17,17Z"
      />
      <circle cx={70.38} cy={35.8} r={9.87} />
    </g>
    <g>
      <path
        fillRule="evenodd"
        d="m122.72,121.45h0s-108.51,0-108.51,0V5.28c0-.7-.57-1.28-1.28-1.28s-1.28.57-1.28,1.28v6.38h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v10.21h-6.38c-.71,0-1.28.57-1.28,1.28s.57,1.28,1.28,1.28h6.38v6.38c0,.7.57,1.28,1.28,1.28h109.79c.71,0,1.28-.57,1.28-1.28s-.57-1.28-1.28-1.28Z"
      />
      <path
        fillRule="evenodd"
        d="m32.09,81.83v37.07h10.21v-37.07s-9.89.22-10.21,0Z"
      />
      <rect width={10.21} height={23.88} x={19.41} y={95.02} />
      <rect width={10.21} height={51.95} x={70.38} y={66.94} />
      <rect width={10.21} height={31.14} x={44.85} y={87.76} />
      <rect width={10.21} height={54.76} x={83.15} y={64.14} />
      <rect width={10.21} height={48.05} x={57.62} y={70.85} />
      <path
        fillRule="evenodd"
        d="m95.91,118.89h10.21v-63.33c-3.09,1.93-6.51,3.81-10.21,5.48v57.85Z"
      />
      <path
        fillRule="evenodd"
        d="m108.68,118.89h10.21V45.09c-2.53,2.21-6,5-10.21,7.82v65.99Z"
      />
    </g>
  </svg>
);

export default ViewGraphIcon;
