import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatTimespan } from '../../common/formatHelper';
import { Stat } from '../../components/stats/Stat';
import AnalyzeEventsDetailsPanel from '../panels/AnalyzeEventsDetailsPanel';
import AnalyzeStatsPanel from '../panels/AnalyzeStatsPanel';
import { SimulationDashboardCardProps } from './SimulationDashboardCard';
import SimulationDraftCardContent from './SimulationDraftCardContent';

const SimulationAllocatedCardContent: React.FC<
  SimulationDashboardCardProps
> = props => {
  const { t } = useTranslation('simulation');
  const { sim } = props;

  const analyzeTime = formatTimespan(
    sim.latestAllocationRun?.completedAt ?? 0,
    sim.latestAllocationRun?.startedAt ?? 0,
  );

  const hasEvents = !_.isEmpty(
    sim.latestAllocationRun.analyzeResult?.summary?.eventTypes,
  );

  const allocationAnalyzeResult = sim.latestAllocationRun.analyzeResult;


  return (
    <>
      <AnalyzeStatsPanel
        dashboardSimulationView
        analyzeResult={sim.latestAllocationRun.analyzeResult}
      />

      <div className="flex-1">
        {!allocationAnalyzeResult && <SimulationDraftCardContent {...props} />}

        {hasEvents && (
          <AnalyzeEventsDetailsPanel
            dashboardSimulationView
            cardView
            events={sim.latestAllocationRun.analyzeResult.summary?.eventTypes}
            eventsTotal={{
              distance: sim.latestAllocationRun.analyzeResult.totalDistance,
              cost: sim.latestAllocationRun.analyzeResult.totalCost,
              duration: sim.latestAllocationRun.analyzeResult.totalDuration,
            }}
          />
        )}
      </div>

      <Stat
        isMetaValue
        transparent
        title={t`Allocate Stats:`}
        value={analyzeTime}
        className="!flex-none"
      />
    </>
  );
};

export default SimulationAllocatedCardContent;
