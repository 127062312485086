import {
  LoadWeightComplianceForBaysDocument,
  LoadWeightComplianceForBaysQuery,
  LoadWeightComplianceForBaysQueryVariables,
  LoadWeightComplianceForLocationsDocument,
  LoadWeightComplianceForLocationsQuery,
  LoadWeightComplianceForLocationsQueryVariables,
  WeightComplianceBayDetailsFragment,
  WeightComplianceLocationDetailsFragment,
  WeightComplianceStatus,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../Tw';
import { secureClient } from '../../GraphQLClient';
import { WeightComplianceBucketConfig } from './simulation.types';

const heatmapColors = TwTheme.extend.colors.heatmap_compliance;
const heatmapTextColors = TwTheme.extend.colors.heatmap_text;

export const getWeightHeatmapBuckets = (
  t?: TFunction<'simulation'>,
): WeightComplianceBucketConfig[] => [
  {
    id: 'overweight-heavy',
    from: 1.25,
    to: Number.MAX_SAFE_INTEGER,
    title: t && t(`> 125%`, { ns: 'simulation' }),
    color: heatmapColors[100],
    textColor: heatmapTextColors['light'],
    status: WeightComplianceStatus.NONCOMPLIANT,
  },
  {
    id: 'overweight-light',
    from: 1,
    to: 1.25,
    title: t && t(`> 100%`, { ns: 'simulation' }),
    color: heatmapColors[90],
    textColor: heatmapTextColors['light'],
    status: WeightComplianceStatus.NONCOMPLIANT,
  },
  {
    id: 'normal',
    from: 0.9,
    to: 1,
    title: t && t(`> 90%`, { ns: 'simulation' }),
    color: heatmapColors[50],
    textColor: heatmapTextColors['dark'],
    status: WeightComplianceStatus.COMPLIANT,
  },
  {
    id: 'underweight-light',
    from: 0.5,
    to: 0.9,
    title: t && t(`< 89%`, { ns: 'simulation' }),
    color: heatmapColors[50],
    textColor: heatmapTextColors['dark'],
    status: WeightComplianceStatus.COMPLIANT,
  },
  {
    id: 'underweight-heavy',
    from: -1,
    to: 0.5,
    title: t && t(`< 50%`, { ns: 'simulation' }),
    color: heatmapColors[30],
    textColor: heatmapTextColors['dark'],
    status: WeightComplianceStatus.COMPLIANT,
  },
  {
    id: 'floor-level',
    from: null,
    to: null,
    title: t && t(`Ignored`, { ns: 'simulation' }),
    color: heatmapColors.floor,
    textColor: heatmapTextColors['dark'],
    status: WeightComplianceStatus.FLOOR_LEVEL,
  },
  {
    id: 'unknown',
    from: null,
    to: null,
    title: t && t(`Unknown`, { ns: 'simulation' }),
    color: heatmapColors.unknown,
    textColor: heatmapColors.unknownText,
    status: WeightComplianceStatus.UNKNOWN,
  },
  {
    id: 'empty',
    from: null,
    to: null,
    title: t && t(`Empty`, { ns: 'simulation' }),
    color: heatmapColors.empty,
    textColor: heatmapColors.emptyText,
    status: WeightComplianceStatus.UNASSIGNED,
  },
];

export function getWeightColorByValue(
  current: number,
  max: number,
): [bg: string, text: string] {
  // eslint-disable-next-line eqeqeq
  if (max == 0) return [heatmapColors['unknown'], heatmapTextColors['dark']];
  // eslint-disable-next-line eqeqeq
  if (current == 0) return [heatmapColors['empty'], heatmapTextColors['dark']];

  const ratio = max > 0 ? current / max : 0;
  const bucketConfig = getWeightHeatmapBuckets().find(
    config => config.from < ratio && config.to >= ratio,
  );

  return [
    bucketConfig?.color ?? heatmapColors['unknown'],
    bucketConfig?.textColor ?? heatmapTextColors['dark'],
  ];
}

export function getWeightColorByStatus(
  status: WeightComplianceStatus,
): [string, string] {
  if (
    status === WeightComplianceStatus.UNKNOWN ||
    status === WeightComplianceStatus.UNASSIGNED ||
    status === WeightComplianceStatus.FLOOR_LEVEL
  ) {
    const bucketConfig = getWeightHeatmapBuckets().find(
      bucket => bucket.status === status,
    );
    return bucketConfig ? [bucketConfig.color, bucketConfig.textColor] : null;
  }

  return null;
}

export function getLocationWeightColor(
  loc: WeightComplianceLocationDetailsFragment,
): [string, string] {
  return (
    getWeightColorByStatus(loc?.status ?? WeightComplianceStatus.UNKNOWN) ??
    getWeightColorByValue(loc?.weight, loc.maxWeight)
  );
}

export function getBayWeightColor(bay: WeightComplianceBayDetailsFragment) {
  return (
    getWeightColorByStatus(bay?.status ?? WeightComplianceStatus.UNKNOWN) ??
    getWeightColorByValue(bay?.weight, bay.maxWeight)
  );
}

export function hasWeightCompliantActualNumbers(
  status: WeightComplianceStatus,
) {
  return (
    status === WeightComplianceStatus.COMPLIANT ||
    status === WeightComplianceStatus.NONCOMPLIANT ||
    status === WeightComplianceStatus.FLOOR_LEVEL
  );
}

export function hasWeightCompliantMaxNumbers(status: WeightComplianceStatus) {
  return status !== WeightComplianceStatus.FLOOR_LEVEL;
}

export type LoadLocationWeightHeatmapParams = {
  simulationId: string;
  areaId: string;
  level?: number;
  bayId?: string;
};

export async function loadLocationWeightHeatmap(
  params: LoadLocationWeightHeatmapParams,
): Promise<Record<string, WeightComplianceLocationDetailsFragment>> {
  const { simulationId } = params;
  if (!simulationId) return null;

  const response = await secureClient.query<
    LoadWeightComplianceForLocationsQuery,
    LoadWeightComplianceForLocationsQueryVariables
  >({
    query: LoadWeightComplianceForLocationsDocument,
    variables: {
      simulationId,
      page: {
        limit: null,
        skip: 0,
      },
      filter: {
        planeId: params.areaId ? [params.areaId] : null,
        bayId: params.bayId ? [params.bayId] : null,
        level: params.level ? [params.level] : null,
      },
    },
  });
  const locationMap = _.keyBy(
    response.data?.simulation?.weightCompliance?.locations?.content,
    locCompliance => locCompliance.location.locationId,
  );
  return locationMap;
}

export type LoadBayWeightHeatmapParams = {
  simulationId;
  areaId: string;
};

export async function loadBayWeightHeatmap(
  params: LoadBayWeightHeatmapParams,
): Promise<Record<string, WeightComplianceBayDetailsFragment>> {
  const { simulationId } = params;
  if (!simulationId) return null;

  const response = await secureClient.query<
    LoadWeightComplianceForBaysQuery,
    LoadWeightComplianceForBaysQueryVariables
  >({
    query: LoadWeightComplianceForBaysDocument,
    variables: {
      simulationId,
      page: {
        limit: null,
        skip: 0,
      },
      filter: {
        planeId: params.areaId ? [params.areaId] : null,
      },
    },
  });
  const bayMap = _.keyBy(
    response.data?.simulation?.weightCompliance?.bays.content,
    locCompliance => locCompliance.bay.id,
  );
  return bayMap;
}

export function getWeightComplianceStatusMap(
  t: TFunction<'simulation'>,
): Record<WeightComplianceStatus, string> {
  return {
    [WeightComplianceStatus.NONCOMPLIANT]: t(`Non-compliant`, {
      ns: 'simulation',
    }),
    [WeightComplianceStatus.COMPLIANT]: t(`Compliant`, { ns: 'simulation' }),
    [WeightComplianceStatus.FLOOR_LEVEL]: t(`Ignored (Floor)`, {
      ns: 'simulation',
    }),
    [WeightComplianceStatus.UNASSIGNED]: t(`Unassigned`, { ns: 'simulation' }),
    [WeightComplianceStatus.UNKNOWN]: t(`Unknown`, { ns: 'simulation' }),
  };
}
