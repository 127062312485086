import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { DataTableState } from '../../common/types';
import { actualityHeatmapTableDataState } from '../store/actuality.heatmap.state';
import { actualityHqTableDataState } from '../store/actuality.hq.state';
import { actualityJobsTableDataState } from '../store/actuality.jobs.state';
import {
  ActualityDataSetTableType,
  ActualityEntityType,
} from '../store/actuality.types';

export type ActualityDatasetTableDescriptor = {
  id: ActualityDataSetTableType;
  title: string;
  shortTitle: string;
  setFilter: (tableState: DataTableState<string>) => void;
  entities: Set<ActualityEntityType>;
};

const useActualityDatasetTableTypes = (): Record<
  ActualityDataSetTableType,
  ActualityDatasetTableDescriptor
> => {
  const { t } = useTranslation('feed');

  const setActivityMetricTableState = useSetRecoilState(
    actualityHeatmapTableDataState,
  );
  const setActivityFeedTableState = useSetRecoilState(
    actualityHqTableDataState,
  );
  const setJobsTableState = useSetRecoilState(actualityJobsTableDataState);

  return {
    'activity-feed': {
      id: 'activity-feed',
      title: t`Feed Data`,
      shortTitle: t`Feed Data`,
      setFilter: v => setActivityFeedTableState(v),
      entities: new Set<ActualityEntityType>(['event', 'item', 'location']),
    },

    'item-set': {
      id: 'item-set',
      title: t`Item Set`,
      shortTitle: t`Item Set`,
      setFilter: _.noop,
      entities: new Set<ActualityEntityType>(['item']),
    },

    assignment: {
      id: 'assignment',
      title: t`Assignment`,
      shortTitle: t`Assignment`,
      setFilter: _.noop,
      entities: new Set<ActualityEntityType>(['item', 'location']),
    },

    layout: {
      id: 'layout',
      title: t`Layout`,
      shortTitle: t`Layout`,
      setFilter: _.noop,
      entities: new Set<ActualityEntityType>(['location']),
    },

    'activity-metric': {
      id: 'activity-metric',
      title: t`Feed Metric`,
      shortTitle: t`Feed Metric`,
      setFilter: v => setActivityMetricTableState(v),
      entities: new Set<ActualityEntityType>(['item', 'location']),
    },

    jobs: {
      id: 'jobs',
      title: t`Feed Jobs`,
      shortTitle: t`Feed Jobs`,
      setFilter: v => setJobsTableState(v),
      entities: new Set<ActualityEntityType>(['job']),
    },
  };
};

export default useActualityDatasetTableTypes;
