import classNames from 'classnames';
import React from 'react';
import { useId } from 'react-aria';

export type ItemListCardsContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ItemListCardsContainer = ({
  children,
  className = '',
}: ItemListCardsContainerProps) => {
  const id = useId();

  return (
    <div
      data-component="ItemListCardsContainer"
      id={`list-card-container-${id}`}
      className={classNames('flex flex-wrap max-w-screen px-4', className)}
    >
      {children}
    </div>
  );
};
