import { useApolloClient } from '@apollo/client';
import {
  FindItemSetFilterValuesDocument,
  FindItemSetFilterValuesQuery,
  FindItemSetFilterValuesQueryVariables,
  FindOrderLineFilterValuesDocument,
  FindOrderLineFilterValuesQuery,
  FindOrderLineFilterValuesQueryVariables,
  ItemFilterType,
  OrderLineFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { POLICY_FILTER_VALUES_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { LoadPolicyFilterValuesParams } from '../../policyFilters/policyFilter.types';
import {
  policyFilterData,
  policyFiltersSearchValues,
} from '../../simulation/store/policyFilter.state';
import { simulationCurrent } from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';

export type LoadOrderLineFilterValuesParams =
  LoadPolicyFilterValuesParams<OrderLineFilterType>;

function useLoadOrderLineFilterValues() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load OrderLines filter values.`;

  const [observables] = useState<Record<string, ZenObservable.Subscription>>(
    {},
  );

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (field: string) => {
        const current = await snapshot.getPromise(policyFilterData(field));

        set(policyFilterData(field), {
          ...current,
          status: AsyncLoadStatus.Loading,
        });
      },
  );

  const loadFilterValues = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadOrderLineFilterValuesParams) => {
        const simulation = await snapshot.getPromise(simulationCurrent);

        const searchValues = await snapshot.getPromise(
          policyFiltersSearchValues,
        );
        const current = await snapshot.getPromise(
          policyFilterData(params.filterKey),
        );
        const searchValue = searchValues[params.filterKey];

        const includeMatchValues =
          params.filterIntersection?.allOf?.filter(
            item => item.type !== params.field,
          ) ?? [];

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(policyFilterData(params.filterKey), {
            content: [],
            totalCount: 0,
            status: AsyncLoadStatus.Error,
          });
          return;
        }
        const itemSetFields = new Set(_.keys(ItemFilterType));

        const queryItemSetSet = itemSetFields.has(params.field);
        const includeMatching = _.filter(includeMatchValues, match => {
          return queryItemSetSet === itemSetFields.has(match.type);
        });
        const query = queryItemSetSet
          ? client.watchQuery<
              FindItemSetFilterValuesQuery,
              FindItemSetFilterValuesQueryVariables
            >({
              query: FindItemSetFilterValuesDocument,
              variables: {
                page: {
                  limit: params.limit || POLICY_FILTER_VALUES_PAGE_SIZE,
                  skip: params.skip || 0,
                },
                input: {
                  itemSetId: simulation.itemSet.id,
                  filterType: params.field as any,
                  includeMatching: _.isEmpty(includeMatching)
                    ? null
                    : {
                        allOf: cloneWithoutTypename(includeMatching),
                      },
                  query: searchValue,
                },
              },
            })
          : client.watchQuery<
              FindOrderLineFilterValuesQuery,
              FindOrderLineFilterValuesQueryVariables
            >({
              query: FindOrderLineFilterValuesDocument,
              variables: {
                page: {
                  limit: params.limit || POLICY_FILTER_VALUES_PAGE_SIZE,
                  skip: params.skip || 0,
                },
                input: {
                  simulationId: simulation.id,
                  filterType: params.field,
                  includeMatching: _.isEmpty(includeMatching)
                    ? null
                    : {
                        allOf: cloneWithoutTypename(includeMatching),
                      },
                  query: searchValue,
                },
              },
            });

        const queryObservable = (query as any).subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }
            const filterValues = queryItemSetSet
              ? data?.findItemFilterValues
              : data?.findSimulationOrderLineFilterValues;
            let content = filterValues?.content ?? [];
            if (params.isAppend) {
              content = [...current.content, ...content];
            }

            set(policyFilterData(params.filterKey), {
              ...(filterValues ?? {}),
              status: AsyncLoadStatus.Ok,
              content: content,
            });
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        observables[params.filterKey] = queryObservable;
      },
  );

  async function call(params: LoadOrderLineFilterValuesParams) {
    await initLoading(params.filterKey);
    await loadFilterValues(params);
  }

  function cancel(field: string) {
    observables[field]?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadOrderLineFilterValues;
