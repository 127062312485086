import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimulationsListPanel from '../../components/SimulationsListPanel';
import { assignmentDocument } from '../store/assignment.state';

const AssignmentSimulationPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(assignmentDocument);
  const simulations = document?.simulationsWith?.content;
  return (
    <SimulationsListPanel subtitle={t`Assignment`} simulations={simulations} />
  );
};

export default AssignmentSimulationPanel;
