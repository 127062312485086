import { useApolloClient } from '@apollo/client';
import {
  LoadSimulationAnalyzeListDocument,
  LoadSimulationAnalyzeListQuery,
  LoadSimulationAnalyzeListQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { createAnalyzeList } from '../analyzeCompare.helper';
import {
  analyzeCompareAnalyzeList,
  analyzeCompareAnalyzeListLoadStatus,
} from '../analyzeCompare.state';

export type LoadAnalyzeResultListParams = {
  warehouseId: string;
};

/**
 * Load simulation from of provided warehouse, create AnalyzeListItems and save it to recoil state
 * @returns
 */
function useLoadAnalyzeResultList() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Analyze list.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: LoadAnalyzeResultListParams) => {
        reset(analyzeCompareAnalyzeList);
        set(analyzeCompareAnalyzeListLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAnalyzeResultListParams) => {
        function handleError(details, stack) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(analyzeCompareAnalyzeListLoadStatus, AsyncLoadStatus.Error);
        }

        const query = client.watchQuery<
          LoadSimulationAnalyzeListQuery,
          LoadSimulationAnalyzeListQueryVariables
        >({
          query: LoadSimulationAnalyzeListDocument,
          variables: {
            warehouseId: params.warehouseId,
          },
        });

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }
            const content = data?.warehouse?.simulations?.content || [];
            const items = createAnalyzeList(content);

            set(analyzeCompareAnalyzeList, items);
            set(analyzeCompareAnalyzeListLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAnalyzeResultListParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAnalyzeResultList;
