import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import naturalCompare from 'string-natural-compare';
import { getSequentialColor } from '../../../common/color.helper';
import { layoutLocationColorMode } from '../../store/layout.state';
import { viewerLevelLocations } from '../store/viewer.state';
import LocationLayer from './LocationLayer';

const ViewerLocationLayer: React.FC = () => {
  const selectedMode = useRecoilValue(layoutLocationColorMode);
  const levelLocationsLoadable = useRecoilValueLoadable(viewerLevelLocations);

  const locations =
    levelLocationsLoadable.state === 'hasValue'
      ? levelLocationsLoadable.contents
      : null;

  if (_.isNil(locations)) return null;

  const sorted: [string, number][] = _(locations)
    .values()
    .uniqBy(l => l.locationOrder)
    .value()
    .sort((l1, l2) => naturalCompare(l1.locationOrder, l2.locationOrder))
    .map((l, index) => [l.locationOrder, index] as [string, number]);

  const locationOrderMap = new Map(sorted);
  const total = locationOrderMap.size;

  function getColor(loc: LayoutLevelLocationFragment): [string, string] {
    if (selectedMode === 'default' || total < 1) return null;
    const weight =
      (locationOrderMap.get(loc.locationOrder) * 100) / (total - 1);
    return getSequentialColor(weight, 'elevation-sort');
  }

  return (
    <LocationLayer
      getColor={getColor}
      getOpacity={() => (selectedMode === 'default' ? null : 1)}
    />
  );
};

export default ViewerLocationLayer;
