import * as React from 'react';
const LocationPickingOrderIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LocationPickingOrderIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M76.32,35.5l-17.63.02-6.64-6.64,12.93-.02-14.1-14.09,4.7-4.71,14.1,14.09v-12.94s6.65,6.64,6.65,6.64v17.64Z"
    />
    <path
      strokeWidth={0}
      d="M73.87,118.71l-17.63.02-6.64-6.64,12.93-.02-14.1-14.09,4.7-4.71,14.1,14.09v-12.94s6.65,6.64,6.65,6.64v17.64Z"
    />
    <path
      strokeWidth={0}
      d="M51.08,75.24l-.19-17.62,6.58-6.64.14,12.92,13.96-14.09,4.76,4.7-13.96,14.09h12.93s-6.58,6.65-6.58,6.65h-17.64Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M41.39,1.03H1.31s0,40.39,0,40.39h40.08V1.03ZM31.44,34.52H13.45v-4.48h6.96V12.73h-.33l-5.25,6.88-3.55-2.77,6.18-8.14h8.51v21.35h5.48v4.48Z"
    />
    <path
      strokeWidth={0}
      d="M41.39,43.42H1.31s0,40.39,0,40.39h40.08v-40.39ZM29.92,73.36c-.84,1.15-2.02,2.03-3.53,2.66-1.52.63-3.29.94-5.31.94-2.39,0-4.34-.45-5.85-1.35-1.5-.9-2.74-2.1-3.7-3.61l4.11-3.18c.64,1.16,1.39,2.05,2.26,2.68.86.63,1.96.94,3.29.94s2.32-.31,3.05-.92c.73-.62,1.09-1.47,1.09-2.55v-.26c0-1.04-.41-1.83-1.22-2.37-.81-.54-1.95-.81-3.4-.81h-2.4v-4.62h2.44c1.36,0,2.38-.26,3.07-.78.69-.52,1.04-1.2,1.04-2.04v-.26c0-.94-.32-1.68-.96-2.24-.64-.55-1.53-.83-2.66-.83-1.06,0-2.05.27-2.98.79-.92.53-1.71,1.29-2.35,2.28l-3.52-3.26c1.09-1.41,2.34-2.48,3.76-3.22,1.42-.74,3.19-1.11,5.31-1.11,1.85,0,3.47.28,4.87.85s2.47,1.37,3.24,2.41c.76,1.04,1.15,2.23,1.15,3.59,0,1.58-.48,2.88-1.42,3.9-.95,1.02-2.15,1.66-3.61,1.91v.22c1.65.3,2.98.98,4,2.05,1.01,1.07,1.52,2.46,1.52,4.16,0,1.53-.42,2.87-1.26,4.02Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M42,85.85H2v40.31h40v-40.31ZM29.88,114.56c-.79,1.38-1.94,2.46-3.46,3.24-1.52.78-3.32,1.17-5.42,1.17-2.34,0-4.26-.46-5.75-1.37-1.49-.91-2.7-2.11-3.61-3.59l4.03-3.18c.67,1.13,1.41,2.02,2.24,2.66.83.64,1.87.96,3.13.96,1.36,0,2.39-.37,3.11-1.11.71-.74,1.07-1.75,1.07-3.03v-.3c0-1.21-.36-2.16-1.09-2.85-.73-.69-1.71-1.04-2.94-1.04-.94,0-1.75.18-2.42.54-.68.36-1.24.78-1.68,1.28l-4.55-.63.96-14.62h16.02v4.85h-11.95l-.59,8.18h.33c.59-1.31,1.34-2.31,2.26-3.02.91-.7,2.12-1.05,3.63-1.05s2.74.33,3.94.98c1.2.65,2.15,1.6,2.87,2.85.71,1.25,1.07,2.71,1.07,4.38s-.4,3.32-1.18,4.7Z"
    />
    <path
      strokeWidth={0}
      d="M125.34.99h-40.08s0,40.39,0,40.39h40.08s0-40.39,0-40.39ZM114.86,34.52h-18.57v-5.07l8.14-6.77c1.43-1.23,2.45-2.29,3.05-3.18.6-.89.91-1.81.91-2.77v-.37c0-1.06-.32-1.87-.96-2.44-.64-.57-1.5-.85-2.59-.85-1.16,0-2.08.33-2.76,1-.68.67-1.18,1.54-1.5,2.63l-4.85-1.85c.62-1.95,1.74-3.53,3.37-4.75s3.72-1.83,6.29-1.83c1.85,0,3.45.33,4.79,1,1.34.67,2.37,1.59,3.07,2.76.7,1.17,1.05,2.51,1.05,4.01,0,1.36-.3,2.6-.89,3.74-.59,1.13-1.37,2.18-2.33,3.13-.96.95-2.21,2-3.74,3.16l-4.92,3.81h12.43v4.66Z"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="106.78 56.43 106.44 56.43 99.64 67.27 106.78 67.27 106.78 56.43"
    />
    <path
      strokeWidth={0}
      d="M85.26,83.81h40.08v-40.39s-40.08,0-40.08,0v40.39ZM95.16,66.83l10.29-16.13h6.66v16.58h3.33v4.29h-3.33v4.96h-5.33v-4.96h-11.62v-4.74Z"
    />
    <path
      strokeWidth={0}
      d="M105.28,106.13c-1.26,0-2.24.35-2.96,1.04-.72.69-1.07,1.67-1.07,2.92v.52c0,1.28.34,2.28,1.04,2.98.69.7,1.69,1.05,3,1.05s2.34-.35,3.03-1.05c.69-.7,1.04-1.7,1.04-2.98v-.52c0-1.28-.35-2.26-1.05-2.94-.7-.68-1.71-1.02-3.02-1.02Z"
    />
    <path
      strokeWidth={0}
      d="M85.3,85.85v40.31h40v-40.31h-40ZM113.9,114.62c-.81,1.37-1.96,2.44-3.44,3.2-1.48.76-3.19,1.15-5.14,1.15-2.05,0-3.81-.42-5.29-1.26-1.48-.84-2.61-2.03-3.39-3.57-.78-1.54-1.17-3.35-1.17-5.42,0-2.27.46-4.43,1.37-6.49.91-2.06,2.08-3.89,3.5-5.49,1.42-1.6,2.94-2.95,4.57-4.03h7.66c-3.48,2.44-6.1,4.62-7.88,6.55-1.78,1.92-2.91,4.06-3.4,6.4l.33.11c.54-1.16,1.27-2.1,2.18-2.81.91-.71,2.13-1.07,3.66-1.07,1.43,0,2.73.33,3.9,1,1.17.67,2.09,1.61,2.76,2.83s1,2.63,1,4.24c0,1.75-.41,3.31-1.22,4.68Z"
      opacity={0.8}
    />
  </svg>
);
export default LocationPickingOrderIcon;
