import {
  SpacerBuilderSettings,
  SpacerPositionOption,
  SpacerTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getIndexedTitle } from '../../../../common/utils';
import { InboxZero } from '../../../../components/InboxZero';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import { Button } from '../../../../components/actions/Button';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import InputNumber from '../../../../components/inputs/InputNumber';
import { InputValue } from '../../../../components/inputs/InputValue';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableRowHead } from '../../../../components/table/TableRowHead';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import PanelContainer from '../../../../containers/PanelContainer';
import ConverterBayViewerLayout from '../bayViewer/ConverterBayViewerLayout';
import { converterEditableArea } from '../store/converter.area.state';
import { converterLayoutSelectedBay } from '../store/converter.layout.state';
import {
  converterAreaBuilder,
  converterNewSpacerType,
} from '../store/converter.state';

const ConverterBayInfoPanel: React.FC = () => {
  const { t } = useTranslation('designer');
  const area = useRecoilValue(converterEditableArea);
  const bay = useRecoilValue(converterLayoutSelectedBay);
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );

  const [spacerType, setSpacerType] = useRecoilState(converterNewSpacerType);
  const [spacerPosition, setSpacerPosition] = useState<SpacerPositionOption>(
    SpacerPositionOption.Left,
  );
  const [spacerWidth, setSpacerWidth] = useState<number>(
    bay?.details?.width || 300,
  );

  if (!area) return null;

  const spacersNames = new Set(
    _(areaBuilder.spacers?.[bay.id])
      .map(s => s.title)
      .value(),
  );

  function addSpacer() {
    const title = getIndexedTitle(spacersNames, `${bay.title}-A-`);

    const id = `${area.id}-${bay.id}-${title}`;
    const spacer: SpacerBuilderSettings = {
      id,
      title,
      bayId: bay.id,
      type: spacerType,
      position: spacerPosition,
      width: spacerWidth,
    };
    setAreaBuilder({
      ...areaBuilder,
      spacers: {
        ...areaBuilder.spacers,
        [bay.id]: [...(areaBuilder.spacers?.[bay.id] || []), spacer],
      },
    });
  }

  function removeSpacer(id: string) {
    setAreaBuilder({
      ...areaBuilder,
      spacers: {
        ...areaBuilder.spacers,
        [bay.id]: _.filter(
          areaBuilder.spacers[bay.id],
          spacer => spacer.id !== id,
        ),
      },
    });
  }

  function drawSpacerRow(spacer: SpacerBuilderSettings) {
    return (
      <TableRow rowLine key={spacer.id} aria-label={spacer.id}>
        <TableTd cellSize="xs">
          <InputValue xxs value={spacer.title} disabled={true} />
        </TableTd>
        <TableTd cellSize="xs">
          <InputValue xxs value={SpacerTypeOption[spacer.type]} disabled />
        </TableTd>
        <TableTd cellSize="xs">
          <InputValue xxs value={spacer.width} disabled />
        </TableTd>
        <TableTd cellSize="xs">
          <Button
            label={t`Delete`}
            full
            buttonType="primary"
            buttonSize={'xs'}
            onPress={() => {
              removeSpacer(spacer.id);
            }}
          />
        </TableTd>
      </TableRow>
    );
  }

  const drawSpacerAddRow = () => {
    return (
      <TableRowHead key={'spacer-add'}>
        <TableTh cellSize="xs" panelMode isSticky>
          <DropdownSelector
            className="text-xs"
            labelVertical
            panelMode
            DropAlignRight
            value={spacerPosition}
            values={Object.values(SpacerPositionOption).filter(
              value => typeof value === 'number',
            )}
            renderValue={i => SpacerPositionOption[i]}
            label={t`Position`}
            onChange={(v: SpacerPositionOption) => {
              setSpacerPosition(v);
            }}
          />
        </TableTh>
        <TableTh cellSize="xs" panelMode>
          <DropdownSelector
            className="text-xs"
            labelVertical
            panelMode
            DropAlignRight
            value={spacerType}
            values={Object.values(SpacerTypeOption).filter(
              value => typeof value === 'number',
            )}
            renderValue={i => SpacerTypeOption[i]}
            label={t`Type`}
            onChange={(v: SpacerTypeOption) => {
              setSpacerType(v);
            }}
          />
        </TableTh>
        <TableTh cellSize="xs" panelMode>
          {/* <InputValue
            title={t`Size`}
            xs
            labelVertical
            value={spacerWidth}
            onChange={v => setSpacerWidth(Number.parseInt(v) || 300)}
          /> */}

          <InputNumber
            title={t`Size`}
            inputClassName="text-xs"
            labelVertical
            value={spacerWidth}
            onChange={v => setSpacerWidth(v)}
            range={[0, null]}
            debounceInterval={100}
          />
        </TableTh>
        <TableTh cellSize="xs" panelMode>
          <Button
            className="mx-2"
            label={t`Add`}
            full
            buttonType="primary"
            buttonSize={'xs'}
            onPress={() => {
              addSpacer();
            }}
          />
        </TableTh>
      </TableRowHead>
    );
  };

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <WarehouseNavigator>
        <WarehouseNavigatorItem active name={t`Area`} value={area.id} />
        <WarehouseNavigatorItem
          name={bay.navigable ? t`Tunnel Bay` : t`Bay`}
          value={bay.title}
          active
          aria-label={bay.title}
        />
      </WarehouseNavigator>
      {/* <div className="flex flex-row flex-1"> */}
      <ConverterBayViewerLayout />
      <ContainerScroll className="flex flex-col flex-1">
        <PanelContainer
          id="panel-bay-properties"
          title={t`Bay Properties`}
          collapsible
          hasPadding
        >
          <InputCheckbox
            isSelected={bay.navigable}
            label={t`Bay Type: Tunnel bay`}
            onChange={v => {
              setAreaBuilder({
                ...areaBuilder,
                tunnelBays: {
                  ...(areaBuilder.tunnelBays || {}),
                  [bay.id]: v,
                },
              });
            }}
          >
            {t`Bay Type: Tunnel bay`}
          </InputCheckbox>
        </PanelContainer>

        <PanelContainer
          id="panel-bay-spacers"
          title={t`Bay Custom Aisles`}
          collapsible
          // hasPadding
        >
          <Table isSticky viewAsLine isHoverable className="mb-16">
            <TableHead>{drawSpacerAddRow()}</TableHead>
            {areaBuilder.spacers ? (
              <TableBody>
                {_.map(areaBuilder.spacers?.[bay.id], s => drawSpacerRow(s))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableTd colSpan={4}>
                    <InboxZero message={t`No adjacent custom aisles found`} />
                  </TableTd>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </PanelContainer>
      </ContainerScroll>
    </div>
  );
};

export default ConverterBayInfoPanel;
