import * as React from 'react';
const ObjectAisle2MiddleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisle2MiddleIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="121.85 85.07 121.85 112.62 107.01 85.07 121.85 85.07"
    />
    <polygon
      strokeWidth={0}
      points="121.85 8.59 121.85 36.14 107.01 8.59 121.85 8.59"
    />
    <polygon
      strokeWidth={0}
      points="105.8 118.11 105.8 90.73 120.54 118.11 105.8 118.11"
    />
    <polygon
      strokeWidth={0}
      points="121.85 46.83 121.85 74.38 107.01 46.83 121.85 46.83"
    />
    <polygon
      strokeWidth={0}
      points="105.8 41.63 105.8 14.25 120.54 41.63 105.8 41.63"
    />
    <polygon
      strokeWidth={0}
      points="105.8 79.87 105.8 52.49 120.54 79.87 105.8 79.87"
    />
    <path
      strokeWidth={0}
      d="M92.21,45.36v44.52s10.12,31.82,10.12,31.82h22.88s0-118.16,0-118.16h-22.88s-10.12,41.82-10.12,41.82ZM105.1,43.4v-.82s-.85,0-.85,0h-.85V6.37s1.7,0,1.7,0v-1.2l17.44-.03v1.23s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.28s0,1.1,0,1.1h0v.52s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.28s0,1.1,0,1.1h0v.52s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.12s0,.15,0,.15v1.1h-17.44v-1.23s0-.28,0-.28h-.85s-.85,0-.85,0v-36.22s1.7,0,1.7,0v-1.2h0s0-.54,0-.54v-.28s-.85,0-.85,0h-.85v-36.22s1.7,0,1.7,0v-1.2h0Z"
    />
    <polygon
      strokeWidth={0}
      points="7.13 117.37 21.88 117.37 7.13 90 7.13 117.37"
    />
    <polygon
      strokeWidth={0}
      points="8.34 84.33 23.18 111.88 23.18 84.33 8.34 84.33"
    />
    <polygon
      strokeWidth={0}
      points="21.88 79.13 7.13 51.76 7.13 79.13 21.88 79.13"
    />
    <polygon
      strokeWidth={0}
      points="7.13 13.52 7.13 40.89 21.88 40.89 7.13 13.52"
    />
    <path
      strokeWidth={0}
      d="M26.64,3.54H3.76v118.16h22.88l55.12-31.82v-43.52L26.64,3.54ZM25.58,43.87v36.22s-.85,0-.85,0h-.85v1.5h0v.52h1.7v36.22s-.85,0-.85,0h-.85v1.5H6.44v-1.5h-1.7v-36.22s1.7,0,1.7,0v-1.2h0v-.82h-1.7v-36.22s1.7,0,1.7,0v-1.2h0v-.82h-1.7V5.63s1.7,0,1.7,0v-1.2l17.44-.03v1.23h1.7v36.22s-.85,0-.85,0h-.85v1.5h0v.52h1.7Z"
    />
    <polygon
      strokeWidth={0}
      points="8.34 46.1 23.18 73.65 23.18 46.1 8.34 46.1"
    />
    <polygon
      strokeWidth={0}
      points="8.34 7.86 23.18 35.41 23.18 7.86 8.34 7.86"
    />
  </svg>
);
export default ObjectAisle2MiddleIcon;
