import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, useState } from 'react';
import { Link } from 'react-router-dom';
import { Spacer } from '../components/layout/Spacer';

export type HeaderPublicProps = {
  children?: React.ReactNode;
  className?: string;
  classNameIcon?: string;
  message?: string;
  url?: string;
  iconBefore?: React.FC<HTMLAttributes<Element>>;
  iconAfter?: React.FC<HTMLAttributes<Element>>;
  hasIconBefore?: React.ReactNode;
  hasIconAfter?: React.ReactNode;
};

export const HeaderPublic = ({
  children,
  className,
  message,
  url,
  iconBefore,
  iconAfter,
  hasIconBefore,
  hasIconAfter,
}: HeaderPublicProps) => {
  // const { t } = useTranslation('app');
  const [id] = useState<string>(_.uniqueId());
  const IconBefore = iconBefore;
  const IconAfter = iconAfter;
  const iconSize = 'w-8 h-8';

  return (
    <header
      id={id}
      data-component="HeaderPublic"
      className={classNames(
        'sticky top-0',
        'flex items-center',
        'bg-brand/60 text-menu-text lg:bg-app-panel-dark/90 lg:text-menu-active',
        'backdrop-filter backdrop-blur',
        'px-10 py-2',
        className,
      )}
    >
      {iconBefore && <Spacer />}
      <Link to={url} className="flex items-center flex-1">
        {hasIconBefore}

        {iconBefore && (
          <IconBefore className={classNames('fill-current', 'p-1', iconSize)} />
        )}
        <span
          className={classNames(
            'flex-1',
            (hasIconBefore || iconBefore) && 'ltr:ml-3 rtl:mr-3',
          )}
        >
          {message}
        </span>
        {iconAfter && (
          <IconAfter
            className={classNames(
              'fill-current',
              'p-1 ltr:ml-6 rtl:mr-6',
              iconSize,
            )}
          />
        )}
        {hasIconAfter}
      </Link>
      {children}
    </header>
  );
};
