import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import StaticBorderFeature from '../../../../layout/features/StaticBorderFeature';
import { getPoints } from '../../../../layout/viewer/store/viewer.helper';
import {
  converterAreaConfiguration,
  converterEditableArea,
} from '../store/converter.area.state';
import { getLayerConfig } from '../store/converter.helper';
import {
  converterLayoutHoveredFeature,
  converterLayoutHoveredLocation,
} from '../store/converter.layout.state';

const ConverterHoveredFeatureLayer: React.FC = () => {
  const selectedShape = useRecoilValue(converterLayoutHoveredFeature);
  const selectedLocation = useRecoilValue(converterLayoutHoveredLocation);

  const areaResponse = useRecoilValueLoadable(converterEditableArea);
  const areasConfigResponse = useRecoilValueLoadable(
    converterAreaConfiguration,
  );

  const shape = selectedLocation?.shape ?? selectedShape?.shape;

  if (
    _.isNil(shape) ||
    areasConfigResponse.state !== 'hasValue' ||
    areaResponse.state !== 'hasValue' ||
    !areaResponse.contents
  )
    return null;

  const area = areaResponse.contents;
  const areasConfig = areasConfigResponse.contents;
  const areaConfig = areasConfig[area?.id];

  return (
    <Layer {...getLayerConfig(areaConfig, area)}>
      <StaticBorderFeature points={_.flatten(getPoints(shape))} />
    </Layer>
  );
};

export default ConverterHoveredFeatureLayer;
