import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { SidebarMenuItem } from '../../../../components/nav/SidebarMenuItem';
import SidebarContainer from '../../../../containers/SidebarContainer';
import useSelectConverterWizardStep from '../hooks/useSelectConverterWizardStep';
import {
  converterLayoutIdSaving,
  converterWizardStepId,
} from '../store/converter.state';
import { wizardSteps } from '../store/converter.wizard';

const ConverterWizardSidebarCmp: React.FC = () => {
  const activeStepId = useRecoilValue(converterWizardStepId);
  const isLayoutSaving = !_.isNil(useRecoilValue(converterLayoutIdSaving));
  const selectStep = useSelectConverterWizardStep();
  const { t: tDesigner } = useTranslation('designer');
  return (
    <ContainerScroll className="flex flex-col">
      <nav className="flex flex-col flex-1 bg-app-panel-dark/60">
        <ul
          data-component="ConverterWizardSidebar"
          className="relative text-start"
        >
          {wizardSteps(tDesigner).map(step => {
            return (
              <SidebarMenuItem
                className={step.className}
                key={step.id}
                icon={step.icon}
                menuLevel={step.menuLevel.toString()}
                title={step.title}
                active={step.id === activeStepId}
                treeLine={step.treeLine}
                treeLineEnd={step.treeLineEnd}
                treeLineStart={step.treeLineStart}
                stepCounter={step.stepCounter}
                disabled={isLayoutSaving}
                isHidden={step?.isHidden}
                onClick={() => !isLayoutSaving && selectStep(step.id)}
                inProgress={step.id === activeStepId && isLayoutSaving}
              />
            );
          })}
        </ul>
      </nav>
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('app');
  return (
    <SidebarContainer
      type={'sidebar-converter-wizard'}
      title={t`Layout Design Wizard`}
      size={'xs'}
    >
      <ConverterWizardSidebarCmp />
    </SidebarContainer>
  );
};
