import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  LoadLayoutImportConverterDataDocument,
  LoadLayoutImportConverterDataQuery,
  LoadLayoutImportConverterDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { errorAppender } from '../../store/error.state';

function useLoadLayoutImportMeta() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load "Layout Import" metadata`;

  const load = useRecoilCallback(
    ({ set }) =>
      async (importLayoutId: string) => {
        function handleError(details: string, stack: string) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
        }

        let layoutImportDataResponse: ApolloQueryResult<LoadLayoutImportConverterDataQuery>;

        try {
          layoutImportDataResponse = await client.query<
            LoadLayoutImportConverterDataQuery,
            LoadLayoutImportConverterDataQueryVariables
          >({
            query: LoadLayoutImportConverterDataDocument,
            variables: {
              id: importLayoutId,
            },
          });
        } catch (ex) {
          handleError(ex.message || ex, ex.stack || null);
        }

        if (layoutImportDataResponse.errors) {
          handleError(
            null,
            layoutImportDataResponse.errors.map(e => e.message).join('. '),
          );
          return;
        }

        return layoutImportDataResponse.data?.layoutImport;
      },
  );

  return load;
}

export default useLoadLayoutImportMeta;
