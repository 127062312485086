import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilState, useRecoilState } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { PolicyFilterTag } from '../components/policies/PolicyFilterTag';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import { resourcePolicySelectedAgentId } from '../simulation/store/resourcePolicy.state';
import AgentSummary from './AgentSummary';
import {
  AgentCategoryId,
  AgentSettingsWithMeta,
} from './agentData/agent.types';
import { getAgentIcon, getAgentTypeTitle } from './agentData/agentDataHelper';

export type ResourcePolicyAgentProps = {
  agentId: string;
  index?: number;
  className?: string;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;
  isCheckLoading: boolean;

  agentSelector: (agentId: string) => RecoilState<AgentSettingsWithMeta>;
  disableProductSelection?: boolean;
  canDelete?: boolean;
  onDeleteClick?: () => void;
  onCloneClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
};

const ResourcePolicyAgent: React.FC<ResourcePolicyAgentProps> = props => {
  const [agent, updateAgent] = useRecoilState(
    props.agentSelector(props.agentId),
  );
  const [selectedAgentId, setSelectedAgentId] = useRecoilState(
    resourcePolicySelectedAgentId,
  );
  const { t } = useTranslation('simulation');

  const isSelected = agent?.id === selectedAgentId;

  function selectAgent() {
    setSelectedAgentId(isSelected ? null : agent.id);
  }

  function renameAgent(newTitle: string) {
    updateAgent({
      ...agent,
      title: newTitle,
    });
  }

  const containerParams: RuleContainerProps = {
    id: 'resource-policy-agent-id-' + agent.id,
    title: agent.title,
    isRemovable: props.canDelete,
    isActive: isSelected,
    isNew: props.isNew,
    orderCounter: props.index,
    isCollapsible: true,
    hasIcon: true,
    isDisabled: props.isDisabled,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameAgent(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
    icon: getAgentIcon(agent.resourceTypeId as AgentCategoryId),
    onClick: () => !props.isDisabled && selectAgent(),
    actions: {
      clone: () => props.onCloneClick(),
    },
    hasColorMode: false,
  };

  const agentTypeTitle = getAgentTypeTitle(agent.resourceTypeId, t);

  return (
    <RuleContainer {...containerParams}>
      <PolicyFilterTag className={'space-y-3'}>
        <RuleSectionContainer
          className="my-0"
          hasIssue={false}
          isSelected={isSelected}
        >
          <Stat
            className={classNames('whitespace-pre-line')}
            title={agentTypeTitle}
            value={formatInteger(agent?.utilisation?.agentAmount) ?? 0}
            inFilterStat
            hasHelper
            transparent={!isSelected}
            isSelected={isSelected}
            isActionable={!props.isDisabled}
            icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
          />

          {props.isDisabled ? (
            <AgentSummary key={agent.id} agentSettings={agent} />
          ) : (
            <AgentSummary
              key={agent.id}
              isSelected={isSelected}
              agentSettings={agent}
            />
          )}
        </RuleSectionContainer>
      </PolicyFilterTag>
    </RuleContainer>
  );
};
export default ResourcePolicyAgent;
