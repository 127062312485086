import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { LocationFilterUnion } from '../location-filter.model';
import { SimulationItemFilterUnion } from '../simulation-item-filter.model';

export enum AssignmentPolicyRuleMatchType {
  ITEM_MUST_BE_IN_LOCATION = 'ITEM_MUST_BE_IN_LOCATION',
  LOCATION_MUST_CONTAIN_ITEM = 'LOCATION_MUST_CONTAIN_ITEM',
  STRICT_MATCH = 'STRICT_MATCH',
}

registerEnumType(AssignmentPolicyRuleMatchType, {
  name: 'AssignmentPolicyRuleMatchType',
});

@InputAndObjectType()
export class AssignmentPolicyRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => AssignmentPolicyRuleMatchType, { nullable: true })
  type?: AssignmentPolicyRuleMatchType;

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  productsMatch?: SimulationItemFilterUnion;

  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;
}

@InputAndObjectType()
export class AssignmentPolicy {
  @Field(() => [AssignmentPolicyRule])
  rules: AssignmentPolicyRule[];

  @Field(() => LocationFilterUnion, { nullable: true })
  fallbackLocationsMatch?: LocationFilterUnion;

  @Field({ nullable: true })
  fallbackLocationsAllowAnyItems?: boolean;

  @Field({ nullable: true })
  fallbackItemsAllowAnyLocations?: boolean;
}
