import { PickingPolicyLocationSelectionMode } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../common/color.helper';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { Alert } from '../components/alerts/Alerts';
import * as Icon from '../components/icons';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import { AgentCategoryId } from '../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../resourcePolicy/agentData/agentDataHelper';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import { getPickingHowFilterConfig } from '../simulation/store/pickingPolicy/pickingPolicy.default';
import {
  getLocationSelectionModeOptions,
  getPickingConfigurationValue,
  getPolicyGroupOptions,
} from '../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyCheckResult,
  pickingPolicyCheckResultLoadStatus,
  pickingPolicyPickingRule,
  pickingPolicyRule,
  pickingPolicySelectedDetailsIdentityAtom,
  pickingPolicySelectedIdentity,
} from '../simulation/store/pickingPolicy/pickingPolicy.state';
import { PickingPolicySelectionType } from '../simulation/store/pickingPolicy/pickingPolicy.types';
import {
  resourcePolicy,
  resourcePolicyPickingAgents,
} from '../simulation/store/resourcePolicy.state';
import { waypointPolicy } from '../simulation/store/waypointPolicy/waypointPolicy.state';
import { sidebarStateByType } from '../store/sidebar.state';

export type PickingPolicyPickingRuleProps = {
  ruleId: string;
  pickingRuleId: string;
  index?: number;
  className?: string;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;

  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
};

const PickingPolicyPickingRule: React.FC<
  PickingPolicyPickingRuleProps
> = props => {
  const { t } = useTranslation('simulation');
  const [showAdvancedOptions, setShowAdvancedOptions] =
    useState<boolean>(false);

  const [
    showAdvancedOptionLocationSelector,
    setShowAdvancedOptionLocationSelector,
  ] = useState<boolean>(false);

  const { ruleId, pickingRuleId } = props;

  const rule = useRecoilValue(pickingPolicyRule(ruleId));
  const resources = useRecoilValue(resourcePolicy);
  const wpPolicy = useRecoilValue(waypointPolicy);
  const [pickingRule, updatePickingRule] = useRecoilState(
    pickingPolicyPickingRule({
      ruleId,
      pickingRuleId,
    }),
  );
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    pickingPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-picking-editor'),
  );
  const [selectedDetails, setSelectedDetails] = useRecoilState(
    pickingPolicySelectedDetailsIdentityAtom,
  );
  const checkResult = useRecoilValue(pickingPolicyCheckResult);
  const checkResultStatus = useRecoilValue(pickingPolicyCheckResultLoadStatus);
  const pickingAgents = useRecoilValue(resourcePolicyPickingAgents);

  const selectedAgent = _.find(
    resources.agents,
    a =>
      a.id === pickingRule.agentId && _.has(pickingAgents, pickingRule.agentId),
  );

  const selectedWaypointsRule = _.find(
    wpPolicy?.rules,
    rule => rule.id === pickingRule.waypointSettings?.ruleId,
  );
  const ruleCheckResult = _.find(
    checkResult?.policy?.orderLineRules,
    r => r.id === props.ruleId,
  );
  const pickingRuleCheckResult = _.find(
    ruleCheckResult?.pickingRules,
    r => r.id === pickingRule.id,
  );

  const isCheckLoading = checkResultStatus !== AsyncLoadStatus.Ok;

  function selectLocationFilterIntersection(id: string) {
    const shouldSelect = id !== selectedIdentity?.locationFilterId;
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: ruleId,
        pickingRuleId: pickingRule.id,
        locationFilterId: id,
      });

      setSelectedDetails({
        ruleId: ruleId,
        pickingRuleId: pickingRule.id,
        showLocations: true,
      });
    }

    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? !shouldSelect
        : policyEditPanelState.isCollapsed,
      isHidden: !shouldSelect,
    });
  }

  function selectWithToggle(type: PickingPolicySelectionType) {
    if (props.isDisabled) return;

    const isDeselect =
      selectedIdentity?.pickingRuleId === pickingRule.id &&
      selectedIdentity?.selectionTypeId === type;

    setSelectedIdentity(
      isDeselect
        ? null
        : {
            ruleId: rule.id,
            pickingRuleId: pickingRule.id,
            selectionTypeId: type,
          },
    );
    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? isDeselect
        : policyEditPanelState.isCollapsed,
      isHidden: isDeselect,
    });
  }

  function addLocationIntersection() {
    const id = nanoid();
    updatePickingRule({
      ...pickingRule,
      locationsMatch: {
        anyOf: [
          ...(pickingRule.locationsMatch?.anyOf ?? []),
          { id, allOf: [] },
        ],
      },
    });
    selectLocationFilterIntersection(id);
  }

  function renameRule(newTitle: string) {
    updatePickingRule({
      ...pickingRule,
      title: newTitle,
    });
  }

  const removeField = (intersectionId: string, fieldId: string) => {
    updatePickingRule({
      ...pickingRule,
      locationsMatch: {
        anyOf: _.map(pickingRule.locationsMatch.anyOf, match => {
          if (match.id !== intersectionId) return match;
          return {
            id: intersectionId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };

  const removeIntersection = (intersectionId: string) => {
    updatePickingRule({
      ...pickingRule,
      locationsMatch: {
        anyOf: _.filter(
          pickingRule.locationsMatch?.anyOf,
          fg => fg.id !== intersectionId,
        ),
      },
    });
  };
  const isActivePickingRule =
    selectedIdentity?.pickingRuleId === pickingRule.id;
  const containerParams: RuleContainerProps = {
    id: pickingRule.id,
    title: pickingRule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive: isActivePickingRule,
    isNew: props.isNew,
    isDisabled: props.isDisabled,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
  };
  const hasIssues = false;

  // A. Where to Pick from?
  const isActiveLocationsFilters =
    selectedDetails?.pickingRuleId === pickingRule.id &&
    selectedDetails?.showLocations;

  // A.1. Location selection mode
  const isActiveModeFilters =
    isActivePickingRule &&
    selectedIdentity?.selectionTypeId === 'picklistLineMode';

  // B. Which UOMs?
  const isActiveUOMFilters =
    isActivePickingRule &&
    selectedIdentity?.selectionTypeId === 'picklistLineUOM';

  // C. Who is Picking?
  const isActiveAgentFilters =
    isActivePickingRule &&
    selectedIdentity?.selectionTypeId === 'picklistLineAgent';

  // D. Picklist line group
  const isActiveGroupFilters =
    isActivePickingRule &&
    selectedIdentity?.selectionTypeId === 'picklistLineGroup';

  // E. Waypoints
  const isActiveWaypointsFilters =
    isActivePickingRule &&
    selectedIdentity?.selectionTypeId === 'picklistLineWaypoints';

  const isActivePickingFilters =
    isActiveLocationsFilters ||
    isActiveUOMFilters ||
    isActiveAgentFilters ||
    isActiveModeFilters ||
    isActiveGroupFilters ||
    isActiveWaypointsFilters;

  const selectedMode = _.find(
    getLocationSelectionModeOptions(t),
    mode =>
      mode.id ===
      (pickingRule.locationSelectionMode ??
        PickingPolicyLocationSelectionMode.ROUND_ROBIN),
  );

  const selectedGroup = _.find(
    getPolicyGroupOptions(t),
    group => group.id === (pickingRule.picklistGroupKey ?? null),
  );

  const keyString = `PickingPolicyPickingRule-${props.ruleId}-${props.pickingRuleId}-${props.index}`;
  const hasUOMWarning = _.isEmpty(pickingRule.uomTypes);
  const hasAgentWarning = _.isNil(selectedAgent);

  return (
    <RuleContainer
      dataComponent="PickingPolicyPickingRule"
      key={keyString}
      id={keyString}
      {...containerParams}
      hasIssue={hasIssues}
      actions={{ advanced: v => setShowAdvancedOptions(!showAdvancedOptions) }}
      hasIcon
      hasColorMode={false}
      hasOrder
      stickyTop={6.5}
      isActive={isActivePickingFilters}
      transparent
      // transparent={isActivePickingFilters}
      color={
        // isActiveLocationsFilters &&
        getQualitativeColor(props.ruleId, 'policy')[0]
      }
      // className={classNames(
      //   isActivePickingFilters ? 'bg-brand/50' : 'bg-menu-500',
      // )}
      classNameInner={classNames('p-1 space-y-1 mt-px', {
        // 'p-1 space-y-1 mt-px': isActivePickingFilters,
      })}
    >
      {/* {isActiveLocationsFilters && 'isActiveLocationsFilters'}
      {isActivePickingFilters && 'isActivePickingFilters'} */}
      <RuleSectionContainer
        className={classNames(
          'pt-1 2xl:pt-2',
          'mt-0',
          // isActiveLocationsFilters
          //   ? ' bg-brand__ text-menu-text shadow-2xl'
          //   : 'bg-app-panel-dark',
        )}
        isSelected={isActiveLocationsFilters}
        hasIssue={hasIssues}
        color={
          // isActiveLocationsFilters &&
          getQualitativeColor(props.ruleId, 'policy')[0]
        }
      >
        {/* ——————————————————————————————————————————————————————————————— */}
        {/* A. Where to pick from? (Locations) */}
        {/* ——————————————————————————————————————————————————————————————— */}
        <div className="flex w-full flex-1 truncate">
          {/* <StepListItemHelper
            isPrimary
            stepCountId="A"
            stepCountSize="policy"
            isActive={isActiveLocationsFilters}
            className={classNames(
              'text-xl truncate',
              'ltr:pl-1 ltr:xl:pl-2 rtl:pr-1 rtl:xl:pr-2 py-2 xl:pt-4 !items-center',

            )}
            title={
              <div className="flex items-center w-full">
                <span className="flex-1">{t`Where-From`}</span>
                <ButtonSwitchMulti
                  buttonType={isActiveLocationsFilters ? null : 'minimal'}
                  autoSize
                  className="mx-1"
                  classNameContainer="border border-menu-400"
                  selectedIndex={showAdvancedOptionLocationSelector ? 1 : 0}
                  onClick={index =>
                    setShowAdvancedOptionLocationSelector(index === 1)
                  }
                  options={[{ label: t`Auto` }, { label: t`Custom` }]}
                />
              </div>
            }
          /> */}

          <ButtonSwitchMulti
            buttonType={isActiveLocationsFilters ? null : 'minimal'}
            autoSize
            className="mx-1"
            classNameContainer="border border-menu-400"
            selectedIndex={showAdvancedOptionLocationSelector ? 1 : 0}
            onClick={index =>
              setShowAdvancedOptionLocationSelector(index === 1)
            }
            options={[{ label: t`Auto` }, { label: t`Custom` }]}
          />
        </div>

        <PolicyStatContainer color>
          <Stat
            title={t`Location(s) Filtered`}
            hasNoPadding
            transparent
            className={classNames('px-1 py-1 xl:px-2')}
            hasIssue={hasIssues}
            value={formatInteger(
              pickingRuleCheckResult?.locationFilterMatch?.count ?? 0,
            )}
            inPanelMode
            inFilterStat
            isCheckLoading={isCheckLoading}
            isSelected={isActiveLocationsFilters}
          />
        </PolicyStatContainer>
        <PolicyFilterGroupList
          isDisabled={props.isDisabled}
          filterSets={_.map(pickingRule.locationsMatch?.anyOf, filterGroup => ({
            id: filterGroup.id,
            allOf: [...filterGroup.allOf],
          }))}
          config={getLocationFilterConfigCommon(t)}
          selectedId={selectedIdentity?.locationFilterId}
          onAddGroup={addLocationIntersection}
          onDeleteField={removeField}
          onSelectGroup={selectLocationFilterIntersection}
          onDeleteGroup={removeIntersection}
          showAddFilter={false}
        />
        {(showAdvancedOptionLocationSelector || showAdvancedOptions) && (
          <>
            {/* <RuleSectionContainer
              key={`RuleSectionContainer-mode-${props.pickingRuleId}`}
              className={classNames('mt-2 p-2', {
                'bg-menu-active text-menu-active-text shadow-2xl': isActiveAgentFilters,
              })}
              isSelected={isActiveModeFilters}
            > */}
            {/* ——————————————————————————————————————————————————————————————— */}
            {/* A.1. Location selection mode */}
            {/* ——————————————————————————————————————————————————————————————— */}
            {/* <StepListItemHelper
              isPrimary
              isActive={isActiveModeFilters}
              className={classNames(
                'text-xl ltr:pl-2 rtl:pr-2 py-2 xl:pt-4 items-center',
                // isActiveModeFilters ? 'text-menu-text' : 'text-menu-active',
              )}
              // stepCountSize="policy"
              // stepCountId={`Advanced`}
              title={t`Location Order`}
            /> */}

            <PolicyFilter
              key={`PolicyFilter-PickedWith-${props.pickingRuleId}-${selectedAgent?.id}`}
              label={t`Location Pick Order`}
              isActive={isActiveModeFilters}
              isDisabled={props.isDisabled}
              onClick={() => selectWithToggle('picklistLineMode')}
            >
              <PolicyFilterItem
                isDisabled={props.isDisabled}
                isActive={isActiveModeFilters}
                icon={
                  selectedMode?.type === 'ROUND_ROBIN'
                    ? Icon.SortPriorityRoundRobin
                    : selectedMode?.type === 'LOCATION_PRIORITY'
                      ? Icon.LocationPickingOrder
                      : Icon.SortPriorityAscending
                }
                value={selectedMode?.title}
              />
            </PolicyFilter>
            {/* </RuleSectionContainer> */}
          </>
        )}
      </RuleSectionContainer>

      {/* ———————————————————————————————————————————————————————————————
       /* B. UOM
       /* ——————————————————————————————————————————————————————————————— */}

      <RuleSectionContainer
        key={`RuleSectionContainer-UOM-${props.pickingRuleId}`}
        isSelected={isActiveUOMFilters}
        className={classNames('p-12xl:p-2', 'mt-0', {
          'bg-menu-active text-menu-active-text shadow-2xl': isActiveUOMFilters,
          'bg-app-panel-dark': !isActiveUOMFilters && !hasUOMWarning,
          'bg-alerts-alert': hasUOMWarning,
          // 'bg-alerts-warning': hasOUMWarning,
        })}
        // hasIssue={hasOUMWarning}
      >
        {/*
        <StepListItemHelper
          stepCountId={t`B`}
          title={
            <div className="flex flex-col xl:flex-row items-baseline">
              <div>{t`Which UOM(s)?`}</div>
            </div>
          }
          // title={t`Which UOM(s)?`}
          isPrimary
          isActive={isActiveUOMFilters}
          hasIssue={hasUOMWarning}
          stepCountSize="policy"
          className={classNames(
            'text-xl ltr:pl-2 rtl:pr-2 py-2 xl:pt-4 items-center',

          )}
        /> */}

        {getPickingHowFilterConfig(t).map(filterConfig => {
          const filterValue = getPickingConfigurationValue({
            field: filterConfig.type,
            selectedIdentity: {
              ruleId: ruleId,
              pickingRuleId: pickingRuleId,
            },
            rule,
          });

          const UOMValue = _.map(filterValue?.valueIn, vi => vi.id).join(', ');
          const key = `PolicyFilter-${props.pickingRuleId}-${filterConfig.type}`;
          return (
            <PolicyFilter
              key={key}
              label={t`Which UOM(s)?`}
              isActive={isActiveUOMFilters}
              isDisabled={props.isDisabled}
              onClick={() => selectWithToggle('picklistLineUOM')}
              hasIssue={hasUOMWarning}
            >
              <PolicyFilterItem
                key={`PolicyFilterItem-${filterConfig.type}-${props.pickingRuleId}`}
                name={filterConfig.title}
                icon={hasUOMWarning ? Icon.TriangleInfo : Icon.Pallet}
                value={hasUOMWarning ? t`No UOM selected` : UOMValue}
                hasIssue={hasUOMWarning}
                isActive={isActiveUOMFilters}
                isDisabled={props.isDisabled}
                isRemovable={false}
              />
            </PolicyFilter>
          );
        })}
      </RuleSectionContainer>

      {/* C. Who is Picking? (Agent) */}
      {/* ——————————————————————————————————————————————————————————————— */}
      <RuleSectionContainer
        key={`RuleSectionContainer-Agent`}
        className={classNames(
          isActiveAgentFilters
            ? 'bg-menu-active text-menu-active-text shadow-2xl'
            : 'bg-app-panel-dark',
          // 'p-1 2xl:p-2',
          'mt-0',
        )}
        isSelected={isActiveAgentFilters}
      >
        {/* {hasAgentWarning && (
          <div className="p-1 pt-2">
            <HelperMessage
              className={`border border-alerts-alert border-opacity-50 bg-alerts-alert text-menu-text p-1 rounded`}
              // isSuggestion
              isError
            >
              <div className="flex">
                <Icon.TriangleInfo className="w-5 h-5 fill-current ltr:ml-1 rtl:mr-1 ltr:mr-2 rtl:ml-2" />
                {t`No Agent selected`}
              </div>
            </HelperMessage>
          </div>
        )} */}
        {/*
        <StepListItemHelper
          stepCountId={t`C`}
          stepCountSize="policy"
          // title={t`Who is Picking? (Agent)`}
          title={
            <div className="flex flex-col xl:flex-row items-baseline">
              <div>{t`Who is Picking?`}</div>
              <div className="opacity-70 xl:mx-2 text-xxs xl:text-xs">{t`(Agent)`}</div>
            </div>
          }
          isPrimary
          isActive={isActiveAgentFilters}
          hasIssue={hasAgentWarning}
          className={classNames(
            'text-xl ltr:pl-2 rtl:pr-2 py-2 xl:pt-4 items-center',
          )}
        /> */}

        <PolicyFilter
          key={`PolicyFilter-PickedWith-${props.pickingRuleId}-${selectedAgent?.id}`}
          label={t`Who is Picking? (Agent)`}
          isActive={isActiveAgentFilters}
          isDisabled={props.isDisabled}
          hasIssue={hasAgentWarning}
          onClick={() => selectWithToggle('picklistLineAgent')}
        >
          <PolicyFilterItem
            key={`PolicyFilterItem-${selectedAgent?.id}`}
            // name={filterConfig.title}
            icon={
              hasAgentWarning
                ? Icon.TriangleInfo
                : getAgentIcon(selectedAgent?.resourceTypeId as AgentCategoryId)
            }
            value={selectedAgent?.title ?? t`No Agent selected`}
            hasIssue={hasAgentWarning}
            isActive={isActiveAgentFilters}
            isDisabled={props.isDisabled}
            isRemovable={false}
          />
        </PolicyFilter>
      </RuleSectionContainer>

      {/* D. Waypoints                                                    */}
      {/* ——————————————————————————————————————————————————————————————— */}
      <RuleSectionContainer
        key={`RuleSectionContainer-Waypoints`}
        className={classNames(
          isActiveWaypointsFilters
            ? 'bg-menu-active text-menu-active-text shadow-2xl'
            : 'bg-app-panel-dark',
          // 'p-1 2xl:p-2',
          // 'mt-0',
        )}
        isSelected={isActiveWaypointsFilters}
      >
        {/* <StepListItemHelper
          stepCountId={t`E`}
          stepCountSize="policy"
          title={
            <div className="flex flex-col xl:flex-row items-baseline">
              <div>{t`Waypoints`}</div>
            </div>
          }
          isPrimary
          isActive={isActiveWaypointsFilters}
          className={classNames(
            'text-xl ltr:pl-2 rtl:pr-2 py-2 xl:pt-4 items-center',
          )}
        /> */}

        <PolicyFilter
          key={`PolicyFilter-Waypoint`}
          label={t`Waypoint Policy`}
          isActive={isActiveWaypointsFilters}
          isDisabled={props.isDisabled}
          onClick={() => selectWithToggle('picklistLineWaypoints')}
        >
          <PolicyFilterItem
            key={`PolicyFilterItem-Waypoint`}
            isDisabled={props.isDisabled}
            isActive={isActiveWaypointsFilters}
            value={selectedWaypointsRule?.title ?? t`No Rule selected`}
            icon={Icon.CircleWaypoint3Right}
          />
        </PolicyFilter>
      </RuleSectionContainer>
      {(showAdvancedOptionLocationSelector || showAdvancedOptions) && (
        <>
          <RuleSectionContainer
            key={`RuleSectionContainer-rule-group-${props.pickingRuleId}`}
            className={classNames(
              isActiveGroupFilters
                ? 'bg-menu-active text-menu-active-text shadow-2xl'
                : 'bg-app-panel-dark',
              'mt-0',
            )}
            isSelected={isActiveGroupFilters}
          >
            {/* D. Picklist line group */}
            {/* ——————————————————————————————————————————————————————————————— */}
            {/* <StepListItemHelper
              isPrimary
              isActive={isActiveGroupFilters}
              className={`text-xl ltr:pl-2 rtl:pr-2 py-2 pt-6 items-center ${
                isActiveGroupFilters
                  ? 'isActiveAgentFilters text-menu-text'
                  : 'text-menu-active'
              } `}
              stepCountSize="policy"
              stepCountId={`D`}
              title={t`Picklist line group`}
            /> */}

            <PolicyFilter
              label={t`Group`}
              isActive={isActiveGroupFilters}
              isDisabled={props.isDisabled}
              onClick={() => selectWithToggle('picklistLineGroup')}
            >
              <PolicyFilterItem
                isDisabled={props.isDisabled}
                isActive={isActiveGroupFilters}
                iconClassName={classNames(
                  'w-6 h-6 xl:w-8 xl:h-8',
                  'opacity-50',
                  'ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
                )}
                value={selectedGroup?.title}
              />
            </PolicyFilter>
          </RuleSectionContainer>
        </>
      )}
      {hasIssues && (
        <div className="m-1">
          <Alert messageHelper={t`WARNING`} message={''} hasStatusAlert />
        </div>
      )}
    </RuleContainer>
  );
};
export default PickingPolicyPickingRule;
