import * as React from 'react';
const AlignVerticalTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlignVerticalTopIcon"
    {...props}
  >
    <rect width={120} height={4.44} x={4} y={8.89} strokeWidth={0} />
    <rect width={28} height={100} x={53} y={19.11} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M26.42,22.2c-.09-.09-.2-.18-.31-.25-.05-.03-.11-.06-.16-.09-.06-.03-.12-.07-.19-.1-.07-.03-.14-.04-.21-.06-.06-.02-.11-.04-.17-.05-.26-.05-.53-.05-.78,0-.06.01-.11.03-.17.05-.07.02-.14.04-.2.06-.07.03-.13.06-.19.1-.05.03-.11.05-.16.09-.11.07-.21.16-.31.25l-16,16c-.78.78-.78,2.05,0,2.83.39.39.9.59,1.41.59s1.02-.2,1.41-.59l12.59-12.59v30.17c0,1.1.9,2,2,2s2-.9,2-2v-30.17s12.59,12.59,12.59,12.59c.39.39.9.59,1.41.59s1.02-.2,1.41-.59c.78-.78.78-2.05,0-2.83l-16-16Z"
    />
    <polygon
      strokeWidth={0}
      points="117.01 19.18 116.89 19.07 103.31 19.07 117.01 32.76 117.01 19.18"
    />
    <polygon
      strokeWidth={0}
      points="89.01 84.07 97.04 84.07 89.01 76.04 89.01 84.07"
    />
    <polyline
      strokeWidth={0}
      points="114.15 46.88 117.01 49.73 117.01 36.15 99.92 19.07 89.01 19.07 89.01 21.73 114.15 46.88 114.15 46.88"
    />
    <polygon
      strokeWidth={0}
      points="117.01 83.67 117.01 70.1 89.01 42.1 89.01 55.67 117.01 83.67"
    />
    <polygon
      strokeWidth={0}
      points="89.04 25.16 89.04 38.74 117.01 66.7 117.01 53.13 89.04 25.16"
    />
    <polygon
      strokeWidth={0}
      points="89.01 72.64 100.43 84.07 114.01 84.07 89.01 59.07 89.01 72.64"
    />
  </svg>
);
export default AlignVerticalTopIcon;
