import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  assignmentStatsBuckets,
  assignmentStatsItemMetricDescriptor,
  assignmentStatsLocationsByBay,
  assignmentStatsRangeDescriptor,
} from '../../../assignmentStats/assignmentStats.state';
import { getHeatmapBucketByValue } from '../../../common/heatmap.helper';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { simulationLayoutHeatmapFilters } from '../../store/simulation.layout.state';

const AssignmentStatsBayLocationLayer: React.FC = () => {
  const levelLocationsLoadable = useRecoilValueLoadable(
    assignmentStatsLocationsByBay,
  );
  const rangeDescriptorLoadable = useRecoilValueLoadable(
    assignmentStatsRangeDescriptor,
  );
  const heatmapFilter = useRecoilValue(simulationLayoutHeatmapFilters);
  const bucketsLoadable = useRecoilValueLoadable(assignmentStatsBuckets);
  const metricDescriptor = useRecoilValue(assignmentStatsItemMetricDescriptor);
  const locations =
    levelLocationsLoadable.state === 'hasValue'
      ? levelLocationsLoadable.contents
      : null;

  const buckets =
    bucketsLoadable.state === 'hasValue' ? bucketsLoadable.contents : null;

  const rangeDescriptor =
    rangeDescriptorLoadable.state === 'hasValue'
      ? rangeDescriptorLoadable.contents
      : null;

  if (_.isNil(locations) || _.isNil(_.isNil(locations))) return null;

  const bucketsMap = _.keyBy(buckets, b => b.id);
  function getGradientColors(loc: LayoutBayLocationFragment): string[] {
    if (_.isNil(rangeDescriptor)) return null;
    const colors = _(locations[loc.locationId])
      .map(locationItem =>
        getHeatmapBucketByValue({
          data: locationItem,
          buckets: bucketsMap,
          metricDescriptor,
          rangeDescriptor,
        }),
      )
      .compact()
      .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
      .sortBy(b => -b.index)
      .map(p => p.color)
      .uniq()
      .value();
    return _.isEmpty(colors) ? null : colors;
  }

  return (
    <BayLocationLayer
      stageId="simulation-bay-view"
      getGradientColors={getGradientColors}
    />
  );
};

export default AssignmentStatsBayLocationLayer;
