import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import {
  HeatmapBucket,
  HeatmapBucketSortDirection,
  HeatmapBucketStatField,
} from '../../common/heatmap.helper';
import {
  HeatmapMetricDescriptor,
  HeatmapMetricRange,
} from '../../common/heatmap.types';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { filterPresetSelected } from '../../filterPreset/store/filterPreset.state';
import {
  viewerSelectedBay,
  viewerSelectedLevel,
} from '../../layout/viewer/store/viewer.state';
import { actualityMetricDescriptorsMap } from '../../metrics/actuality/actualityMetric.default';
import { getActualityHeatmapBuckets } from '../../metrics/actuality/actualityMetric.helper';
import {
  ActualityMetric,
  ActualityMetricDataRow,
  ActualityMetricDescriptorBase,
} from '../../metrics/actuality/actualityMetric.types';
import { getProductCategory } from '../../simulation/store/abc/simulation.ABC.helper';
import { HeatmapFilter } from '../../simulation/store/simulation.types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getActualityHeatmapMetricRange } from './actuality.heatmap.helper';
import {
  actualityExtraSettings,
  actualityHeatmapTypeSelected,
  actualitySelectedDocument,
} from './actuality.state';
import {
  assignmentHeatmapBuckets,
  assignmentHeatmapLevelData,
  assignmentHeatmapRangeDescriptor,
  assignmentMetricSelected,
} from './assignment.heatmap.state';
import {
  ActualityHeatmapDataColumn,
  ActualityHeatmapDataQueryParams,
  ActualityHeatmapDataRow,
  getActualityHeatmapDataQuery,
} from './datasetQueries/actualityHeatmapData';
import {
  ActualityMetricSummaryRow,
  getActualityMetricSummary,
} from './datasetQueries/actualityHq';
import { executeDatasetQuery } from './feed.helper';
import { feedItemsWithRankMap, feedQueryBuilderParams } from './feed.state';

const getKey = (postfix: string) => `warebee-actuality-heatmap-${postfix}`;

export const actualityHeatmapBuilderParams =
  selector<ActualityHeatmapDataQueryParams>({
    key: getKey('heatmap-query-builder-params'),
    get: ({ get }) => {
      const actuality = get(actualitySelectedDocument);
      const queryParamsBase = get(feedQueryBuilderParams);
      const metric = get(actualityMetricDescriptor);
      return {
        ...queryParamsBase,
        itemSetId: actuality.itemSetId,
        layoutId: actuality.layoutId,
        assignmentId: actuality.assignmentId ?? 'dummy',
        groupBy: metric.path as any, // todo : correct type
        aggregationFn: metric.aggregationFn ?? 'min',
        filterBy: metric.filterBy,
        filterPreset: get(filterPresetSelected),
      };
    },
  });

const actualityHeatmapRequestReadyInner = selector<boolean>({
  key: getKey('heatmap-request-ready-inner'),
  get: ({ get }) => {
    const actuality = get(actualitySelectedDocument);
    const itemsWithRank = get(feedItemsWithRankMap);

    return (
      !_.isNil(actuality.itemSetId) &&
      !_.isNil(actuality.layoutId) &&
      !_.isEmpty(itemsWithRank)
    );
  },
});

export const actualityMetricSelected = atom<ActualityMetric>({
  key: getKey('metric-selected'),
  default: 'lastActivity',
});
export const actualityExtraMetricDescriptors = selector<
  HeatmapMetricDescriptor<string, ActualityMetricDataRow>[]
>({
  key: getKey('extra-metric-descriptors'),
  get: ({ get }) =>
    get(actualityExtraSettings).actualityMetricDescriptors ?? [],
});

export const actualityMetricDescriptor =
  selector<ActualityMetricDescriptorBase>({
    key: getKey('metric-descriptor'),
    get: ({ get }) => {
      const metric = get(actualityMetricSelected);
      const extraDescriptors = _.keyBy(
        get(actualityExtraMetricDescriptors) ?? [],
        'type',
      );
      return (
        actualityMetricDescriptorsMap[metric] ??
        (extraDescriptors[metric] as ActualityMetricDescriptorBase)
      );
    },
  });

export const actualityMetricHasStats = selector<boolean>({
  key: getKey('metric-has-stats'),
  get: ({ get }) => {
    const metricDescriptor = get(actualityMetricDescriptor);
    return (
      metricDescriptor.rangeType === 'distinct' &&
      metricDescriptor.type !== 'abc'
    );
  },
});

export const actualityHeatmapFilters = atom<HeatmapFilter>({
  key: getKey('heatmap-filters'),
  default: {
    hiddenBuckets: {},
  },
});

export const actualityHeatmapSummary = selector<ActualityMetricSummaryRow[]>({
  key: getKey('summary'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const metric = get(actualityMetricDescriptor);

    if (!get(actualityHeatmapRequestReadyInner)) return null;

    const queryParamsBase = get(actualityHeatmapBuilderParams);

    const compiledQuery = getActualityMetricSummary({
      ...queryParamsBase,
      aggregateBy: (metric.aggregateBy ?? metric.path) as any,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap Summary',
    });

    return result as ActualityMetricSummaryRow[];
  },
});

export const actualityHeatmapRangeDescriptor = selector<HeatmapMetricRange>({
  key: getKey('heatmap-range-descriptor'),
  get: ({ get }) => {
    const metricDescriptor = get(actualityMetricDescriptor);
    const heatmapSummary = get(actualityHeatmapSummary);
    return getActualityHeatmapMetricRange(metricDescriptor, heatmapSummary);
  },
});

const actualityHeatmapBucketsSortByAtom = persistAtom<HeatmapBucketStatField>({
  key: getKey('heatmap-buckets-sort-by-atom'),
  default: null,
});

export const actualityHeatmapBucketsSortBy = selector<HeatmapBucketStatField>({
  key: getKey('heatmap-buckets-sort-by'),
  get: ({ get }) => {
    const hasStats = get(actualityMetricHasStats);
    if (!hasStats) {
      return null;
    }
    return get(actualityHeatmapBucketsSortByAtom);
  },

  set: ({ set }, value) => set(actualityHeatmapBucketsSortByAtom, value),
});

export const actualityHeatmapBucketsSortDirection =
  persistAtom<HeatmapBucketSortDirection>({
    key: getKey('heatmap-buckets-sort-direction'),
    default: 'desc',
  });

const actualityHeatmapBucketsInner = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets-inner'),
  get: ({ get }) => {
    // const namedColors = get(namedColorsGroupSelected);
    const metricDescriptor = get(actualityMetricDescriptor);
    const rangeDescriptor = get(actualityHeatmapRangeDescriptor);
    if (_.isNil(rangeDescriptor)) return null;

    return getActualityHeatmapBuckets(rangeDescriptor, metricDescriptor);
  },
});

export const actualityHeatmapBuckets = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets'),
  get: ({ get }) => {
    const buckets = get(actualityHeatmapBucketsInner);
    const sortBy = get(actualityHeatmapBucketsSortBy);
    const sortDirection = get(actualityHeatmapBucketsSortDirection);

    return _.orderBy(
      buckets,
      b => {
        if (sortBy && b.stats) {
          return b.stats?.[sortBy];
        }
        return b.index;
      },
      sortDirection,
    );
  },
});

export const actualityHeatmapLevelData = selector<ActualityHeatmapDataRow[]>({
  key: getKey('actuality-heatmap-by-level'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const metric = get(actualityMetricDescriptor);
    const level = get(viewerSelectedLevel);

    if (!get(actualityHeatmapRequestReadyInner)) return null;

    const queryParamsBase = get(actualityHeatmapBuilderParams);

    const compiledQuery = getActualityHeatmapDataQuery({
      ...queryParamsBase,
      locationLevel: level,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap By Level',
    });

    if (metric.type === 'abc') {
      const itemsWithRank = get(feedItemsWithRankMap);
      _.forEach(result, row => {
        row.dimensionValue = itemsWithRank[row.skuKey]
          ? getProductCategory(itemsWithRank[row.skuKey].cmlPercentRank)?.key
          : 'D';
      });
    }

    return result as ActualityHeatmapDataRow[];
  },
});

export const actualityHeatmapBayData = selector<ActualityHeatmapDataRow[]>({
  key: getKey('actuality-heatmap-by-bay'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(actualityMetricDescriptor);
    const bay = get(viewerSelectedBay);

    if (!get(actualityHeatmapRequestReadyInner) || _.isNil(bay)) {
      return null;
    }

    const queryParamsBase = get(actualityHeatmapBuilderParams);

    const compiledQuery = getActualityHeatmapDataQuery({
      ...queryParamsBase,
      bayId: bay.id,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap By Bay',
    });

    if (metric.type === 'abc') {
      const itemsWithRank = get(feedItemsWithRankMap);
      _.forEach(result, row => {
        row.dimensionValue = itemsWithRank[row.skuKey]
          ? getProductCategory(itemsWithRank[row.skuKey].cmlPercentRank)?.key
          : null;
      });
    }

    return result as ActualityHeatmapDataRow[];
  },
});

export const actualityHeatmapLocationByLevel = selector<Record<number, number>>(
  {
    key: getKey('location-by-level'),
    get: ({ get }) => {
      const buckets = get(actualityHeatmapBuckets);
      const bucketsFilter = get(actualityHeatmapFilters);
      const affectedBuckets = _.filter(
        buckets,
        b => !bucketsFilter.hiddenBuckets[b.id],
      );

      return _(affectedBuckets)
        .map(b => _.entries(b.levelStats))
        .flatten()
        .reduce((acc, [level, levelStat]) => {
          return {
            ...acc,
            [level]: levelStat.totalExistedLocations + (acc[level] ?? 0),
          };
        }, {});
    },
  },
);

export const actualityHeatmapTableData = atom<ActualityHeatmapDataRow[]>({
  key: getKey('heatmap-table-data'),
  default: [],
});

export const actualityHeatmapTableTotalCount = atom<number>({
  key: getKey('heatmap-table-total-count'),
  default: 0,
});

export const actualityHeatmapTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('heatmap-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const actualityHeatmapTableDataState = atom<
  DataTableState<ActualityHeatmapDataColumn>
>({
  key: getKey('heatmap-table-data-state'),
  default: {
    sortValues: {
      dimensionValue: SortDirection.DESC,
    },
    searchValues: {},
  },
});

export const actualityEffectiveMetric = selector<string>({
  key: getKey('effective-metric'),
  get: ({ get }) => {
    switch (get(actualityHeatmapTypeSelected)) {
      case 'events':
        return get(actualityMetricSelected);
      case 'assignment':
        return get(assignmentMetricSelected);
    }
  },
});

export const actualityEffectiveHeatmapRangeDescriptor =
  selector<HeatmapMetricRange>({
    key: getKey('effective-heatmap-range-descriptor'),
    get: ({ get }) => {
      switch (get(actualityHeatmapTypeSelected)) {
        case 'events':
          return get(actualityHeatmapRangeDescriptor);
        case 'assignment':
          return get(assignmentHeatmapRangeDescriptor);
      }
    },
  });

export const actualityEffectiveHeatmapBuckets = selector<HeatmapBucket[]>({
  key: getKey('effective-heatmap-buckets'),
  get: ({ get }) => {
    switch (get(actualityHeatmapTypeSelected)) {
      case 'events':
        return get(actualityHeatmapBuckets);
      case 'assignment':
        return get(assignmentHeatmapBuckets);
    }
  },
});

export const actualityEffectiveHeatmapLevelData = selector<
  ActualityHeatmapDataRow[]
>({
  key: getKey('effective-heatmap-by-level'),
  get: ({ get }) => {
    switch (get(actualityHeatmapTypeSelected)) {
      case 'events':
        return get(actualityHeatmapLevelData);
      case 'assignment':
        return get(assignmentHeatmapLevelData);
    }
  },
});
