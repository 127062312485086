import * as React from 'react';
const WarehousePharmaIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehousePharmaIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,13.87L.84,30.3v62.58h126.32V30.3l-62.66-16.43ZM55.01,73.47c-.99,3.31-3.5,5.95-6.74,7.08-3.24,1.13-6.83.64-9.66-1.34l14.68-15.42c2.07,2.77,2.71,6.37,1.72,9.68ZM36.22,76.9c-2.11-2.77-2.77-6.39-1.78-9.72.99-3.34,3.51-5.99,6.78-7.12,3.26-1.13,6.87-.61,9.7,1.4l-14.7,15.44ZM17.01,64.67l17.64-18.64c2.13-2.23,3.66-2.98,6.45-2.27,1.39.36,2.66,1.07,3.7,2.07,2.54,2.46,3.16,4.61,1.88,7.69-.29.69-.67,1.34-1.13,1.93-1.62-.19-2.44.67-3.97,1.02l1.84-1.96c.92-.95,1.43-2.24,1.41-3.58-.02-1.34-.57-2.61-1.53-3.53-.96-.93-2.24-1.43-3.56-1.4-1.32.03-2.58.6-3.48,1.57l-5.49,5.88,5.48,5.31c-1.35,1.61-4.62,5.93-5.15,8.12h0c-.46,1.87-.53,3.82-.21,5.73h0s-2.71,2.86-2.71,2.86h0c-1.52,1.59-3.6,2.51-5.79,2.55-2.19.04-4.31-.8-5.9-2.33-3.3-3.19-2.62-7.72.53-11.03Z"
    />
  </svg>
);
export default WarehousePharmaIcon;
