import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../components/alerts/Alerts';

export enum LoadingErrorAlertMessageType {
  locations,
  analyze,
  optimise,
  heatmap,
  congestion,
  route,
  assignmentHeatmap,
  stackingPolicy,
  assignmentPolicy,
  weightCompliance,
  sizeCompliance,
  assignmentCompliance,
  routingInaccessible,
  datasetIssues,
}

export type LayoutLoadingErrorAlertProps = {
  messageType: LoadingErrorAlertMessageType;
};

const LayoutLoadingErrorAlert: React.FC<
  LayoutLoadingErrorAlertProps
> = props => {
  const { t } = useTranslation('app');

  const loadingErrorMessages: Record<LoadingErrorAlertMessageType, string> = {
    [LoadingErrorAlertMessageType.locations]: t`Can't load locations`,
    [LoadingErrorAlertMessageType.analyze]: t`Can't load analyze result`,
    [LoadingErrorAlertMessageType.optimise]: t`Can't load optimise result`,
    [LoadingErrorAlertMessageType.heatmap]: t`Can't load heatmap `,
    [LoadingErrorAlertMessageType.congestion]: t`Can't load waypoints heatmap`,
    [LoadingErrorAlertMessageType.route]: t`Can't load Order route`,
    [LoadingErrorAlertMessageType.assignmentPolicy]: t`Can't load Storage Policy Locations`,
    [LoadingErrorAlertMessageType.weightCompliance]: t`Can't load weight compliance`,
    [LoadingErrorAlertMessageType.sizeCompliance]: t`Can't load size Compliance`,
    [LoadingErrorAlertMessageType.assignmentCompliance]: t`Can't load Assignment Compliance`,
    [LoadingErrorAlertMessageType.datasetIssues]: t`Can't load Locations with issues`,
    [LoadingErrorAlertMessageType.routingInaccessible]: t`Can't load location by Routing Policy Rule`,
    [LoadingErrorAlertMessageType.assignmentHeatmap]: t`Can't load heatmap data`,
    [LoadingErrorAlertMessageType.stackingPolicy]: t`Can't load locations by Stacking Policy Rule`,
  };
  return (
    <Alert
      hasStatusError={true}
      message={loadingErrorMessages[props.messageType]}
    />
  );
};

export default LayoutLoadingErrorAlert;
