import _ from 'lodash';
import { AgentManufacturerDefinition } from '../../agentData/agent.types';
import manufacturers from './manufacturers.json';
import defaults from './defaults.json';

export const agentManufacturers: AgentManufacturerDefinition[] = [
  ...defaults,
  ...manufacturers,
];

export const agentManufacturersMap = _.keyBy(agentManufacturers, m => m.id);
