import {
  Field,
  GraphQLJSON,
  ID,
  InputAndObjectType,
  Int,
  ObjectType,
} from '@warebee/shared/util-backend-only-types';
import { ClassConstructor, plainToClass } from 'class-transformer';
import { format, parse } from 'date-fns';
import { has, isNil, keyBy, mapValues } from 'lodash';
import type { MappingMeasuresValuesType } from './mappingMeasures';
import { MeasureType, getMappingMeasureMultiplier } from './mappingMeasures';
import { BusinessLogicValidator } from './validator';
import { booleanValueResolverBase } from './valueResolver/booleanValueResolver';
import { MappingValueResolver } from './valueResolver/valueResolverBase';

export enum AthenaTableType {
  HIVE = 'HIVE',
  ICEBERG = 'ICEBERG',
}

export type MappingEnum = {
  enumValues: string[];
};
export type MappingFieldType =
  | 'string'
  | 'integer'
  | 'number'
  | 'boolean'
  | 'bigint' // integer, but saved as string
  | 'localDateTime' // datetime without timezone
  | MappingEnum;

export type MappingSchemaField<T = object> = {
  name: keyof T;
  type: MappingFieldType;
  optional?: boolean;
  unique?: boolean;
  measureType?: MeasureType;
  measureValue?: MappingMeasuresValuesType;
  defaultValueResolver?: MappingValueResolver<T[keyof T]>;
};

export type DatePartition<TColumn> = {
  column: TColumn;
  range: [string, string];
  format: string;
  interval: string;
  unit: string;
};

export type PartitionKeyTransform = 'year' | 'month' | 'day' | 'hour'; // TODO | 'bucket' | 'truncate'

export type PartitionKeySpec<T extends object = object> = {
  engine?: AthenaTableType;
  key: keyof T;
  transform?: PartitionKeyTransform;
};

export type MappingSchema<T extends object = object> = {
  fields: MappingSchemaField<T>[];
  createValidator?: () => BusinessLogicValidator<T>;
  partitionBy?: PartitionKeySpec<T>[];
  dateField?: keyof T;
  includeRawData?: boolean;
};

@InputAndObjectType()
export class MappingValueResolverSpecItem {
  @Field()
  title: string;

  @Field(() => ID)
  value: string;
}

@InputAndObjectType()
export class MappingValueResolverSpec {
  @Field(() => [MappingValueResolverSpecItem])
  values: MappingValueResolverSpecItem[];
}

@InputAndObjectType()
export class MappingFieldPreprocessingSpec {
  @Field({ nullable: true, description: 'do not trim value before mapping' })
  doNotTrim?: boolean;

  @Field({ nullable: true, description: 'do not treat empty strings as NULL' })
  allowEmptyStrings?: boolean;

  @Field({ nullable: true, description: "do not treat 'NULL' strings as NULL" })
  allowNullStrings?: boolean;
}
@InputAndObjectType()
export class MappingFieldSpec<T = object> {
  @Field(() => ID)
  name: keyof T;

  @Field(() => ID, { nullable: true })
  columnName?: string;

  @Field(() => Int, { nullable: true })
  index?: number;

  @Field(() => ID, { nullable: true })
  measureValue?: MappingMeasuresValuesType;

  @Field(() => MappingValueResolverSpec, { nullable: true })
  valueResolver?: MappingValueResolverSpec;

  @Field(() => MappingFieldPreprocessingSpec, { nullable: true })
  preprocessing?: MappingFieldPreprocessingSpec;
}

@InputAndObjectType()
export class MappingSpec<T = object> {
  @Field(() => [MappingFieldSpec])
  fields: MappingFieldSpec<T>[];
}

export function countUnmappedFields<T extends object = object>(
  schema: MappingSchema<T>,
  mapping: MappingSpec<T>,
) {
  const mappedFields = keyBy(mapping.fields, s => s.name);
  return schema.fields.filter(f => !mappedFields[f.name as string]).length;
}

export function countUnmappedRequiredFields<T extends object = object>(
  schema: MappingSchema<T>,
  mapping: MappingSpec<T>,
) {
  const mappedFields = keyBy(mapping.fields, s => s.name);
  return schema.fields.filter(
    f => !f.optional && !mappedFields[f.name as string],
  ).length;
}

export function countUnmappedOptionalFields<T extends object = object>(
  schema: MappingSchema<T>,
  mapping: MappingSpec<T>,
) {
  const mappedFields = keyBy(mapping.fields, s => s.name);
  return schema.fields.filter(
    f => f.optional && !mappedFields[f.name as string],
  ).length;
}

export function getUnmappedRequiredFields<T extends object = object>(
  schema: MappingSchema<T>,
  mapping: MappingSpec<T>,
) {
  const mappedFields = keyBy(mapping.fields, s => s.name);
  return schema.fields.filter(
    f => !f.optional && !mappedFields[f.name as string],
  );
}

export function getAllUnmappedFields<T extends object = object>(
  schema: MappingSchema<T>,
  mapping: MappingSpec<T>,
) {
  const mappedFields = keyBy(mapping.fields, s => s.name);
  return schema.fields.filter(f => !mappedFields[f.name as string]);
}

export type MappingErrorType =
  | 'RequiredFieldNotMapped'
  | 'RequiredFieldEmpty'
  | 'UnexpectedType'
  | 'InvalidMapping'
  | 'InvalidIntegerFormat'
  | 'InvalidNumberFormat'
  | 'InvalidBooleanValue'
  | 'InvalidEnumValue'
  | 'InvalidDateTimeValue'
  | 'ValidationError'
  | 'DuplicateValue'
  | 'BusinessLogicValidationError'
  | 'EmptyInput';

@ObjectType()
export class MappingError {
  @Field(() => ID)
  code: MappingErrorType;

  @Field(() => ID, { nullable: true })
  type?: string;

  @Field(() => ID, { nullable: true })
  property?: string;

  @Field()
  message: string;

  @Field({ nullable: true })
  value?: string;

  @Field(() => GraphQLJSON, { nullable: true })
  mappedValue?: any;

  @Field(() => MappingValueResolverSpec, { nullable: true })
  valueResolver?: MappingValueResolverSpec;

  @Field({ nullable: true })
  line?: number;

  @Field(() => [Int], { nullable: true })
  lines?: number[];

  @Field({ nullable: true })
  count?: number;
}

export interface MappingResult<T = object> {
  result: T;
  errors: MappingError[];
}

function doMapField(
  schemaField: MappingSchemaField,
  fieldSpec: MappingFieldSpec,
  value: string,
  result: any,
  errors: MappingError[],
) {
  const { name, measureValue, valueResolver } = fieldSpec;
  const { type, optional, defaultValueResolver } = schemaField;

  const effectiveValueResolver = valueResolver
    ? mapValues(
        keyBy(valueResolver.values, v => v.title),
        v => (schemaField.type == 'boolean' ? v.value == 'true' : v.value),
      )
    : defaultValueResolver;

  if (value == undefined || value.trim() === '') {
    if (optional) {
      return;
    } else {
      errors.push({
        code: 'RequiredFieldEmpty',
        property: name,
        message: 'Required field is empty',
      });
    }
  } else if (typeof value === 'string') {
    if (type === 'string') {
      result[name] = value?.toUpperCase()?.trim();
    } else if (type === 'integer') {
      const trimmed = value.trim();
      if (/^-?\d+$/.test(trimmed)) {
        const parsed = parseInt(trimmed);
        if (!isNaN(parsed)) {
          result[name] = parsed;
          return;
        }
      }
      errors.push({
        code: 'InvalidIntegerFormat',
        property: name,
        message: 'Invalid integer',
        value: value,
      });
    } else if (type === 'number') {
      const trimmed = value.trim();
      if (/^-?\d*.\d*$/.test(trimmed)) {
        const parsed = parseFloat(trimmed);
        if (!isNaN(parsed)) {
          const multiplier = isNil(measureValue)
            ? 1
            : getMappingMeasureMultiplier(measureValue);
          result[name] = parseFloat((parsed * multiplier).toPrecision(7));
          return;
        }
      }
      errors.push({
        code: 'InvalidNumberFormat',
        property: name,
        message: 'Invalid number format',
        value: value,
      });
    } else if (type === 'boolean') {
      const trimmed = value.trim().toLowerCase();
      const resolver = effectiveValueResolver ?? booleanValueResolverBase;

      if (has(resolver, trimmed)) {
        result[name] = resolver[trimmed];
      } else {
        errors.push({
          code: 'InvalidBooleanValue',
          property: name,
          message: 'Invalid boolean value',
          value: value,
          valueResolver: resolver
            ? {
                values: Object.entries(resolver).map(([title, value]) => ({
                  title,
                  value: `${value}`,
                })),
              }
            : null,
        });
      }
    } else if (type === 'bigint') {
      const trimmed = value.trim();
      if (/^-?\d*.\d*$/.test(trimmed)) {
        try {
          const parsed = BigInt(trimmed);
          result[name] = parsed.toString();
          return;
        } catch (e) {
          console.log('cannot parse BigInt "%s": %o', trimmed, e);
        }
      }
      errors.push({
        code: 'InvalidNumberFormat',
        property: name,
        message: 'Not a number',
        value: value,
      });
    } else if (type === 'localDateTime') {
      const trimmed = value.trim().replace(/  +/g, ' ');
      try {
        const date = parse(trimmed, measureValue, new Date());
        result[name] = format(date, `yyyy-MM-dd'T'HH:mm:ss.SSS`);
        return;
      } catch (e) {
        errors.push({
          code: 'InvalidDateTimeValue',
          property: name,
          message: `Could't parse to DateTime`,
          value: value,
        });
        console.log('cannot parse Date "%s": %o', trimmed, measureValue, e);
      }
    } else if (type.enumValues) {
      const trimmed = value.trim();
      const resolver = effectiveValueResolver ?? booleanValueResolverBase;

      if (has(resolver, trimmed.toLowerCase())) {
        result[name] = resolver[trimmed.toLowerCase()];
      } else if (type.enumValues.includes(trimmed)) {
        result[name] = trimmed;
      } else {
        errors.push({
          code: 'InvalidEnumValue',
          property: name,
          message: 'Invalid value type',
          value: value,
        });
      }
    } else {
      errors.push({
        code: 'InvalidMapping',
        property: name,
        message: 'Invalid field mapping',
        value,
      });
      return;
    }
  } else {
    errors.push({
      code: 'UnexpectedType',
      property: name,
      message: 'Unexpected input type',
      value,
    });
  }
}

export type PreparedMappingSpec = [
  MappingSchemaField,
  MappingFieldSpec,
  number,
][];

export function formatToAlias(s: string) {
  return s?.toLowerCase()?.replace(/(_| |-)/g, '');
}

export function prepareMappingSpec<T extends object>(
  mappingSchema: MappingSchema<T>,
  mappingSpec: MappingSpec<T>,
  columnCount: number,
  errors: MappingError[],
  headers?: string[],
): PreparedMappingSpec {
  const preparedFieldSpecs = [];

  const fieldSpecsByName = keyBy(mappingSpec.fields, spec => spec.name);

  mappingSchema.fields.forEach(fieldSchema => {
    const mapping = fieldSpecsByName[fieldSchema.name as string];
    if (mapping) {
      let index = mapping.index;
      if (index == null) {
        if (!mapping.columnName) {
          errors.push({
            code: 'InvalidMapping',
            property: fieldSchema.name as string,
            message: 'Neither column name nor column index provided',
          });
        }
        index = headers?.indexOf(mapping.columnName);
      }

      if (index != null && index >= 0 && index < columnCount) {
        preparedFieldSpecs.push([fieldSchema, mapping, index]);
      } else {
        errors.push({
          code: 'InvalidMapping',
          property: fieldSchema.name as string,
          message: 'Field mapped to non-existing column',
        });
      }
    } else if (!fieldSchema.optional) {
      errors.push({
        code: 'RequiredFieldNotMapped',
        property: fieldSchema.name as string,
        message: 'Required field is not mapped',
      });
    }
  });

  return preparedFieldSpecs;
}

function doMapRecord(
  fieldSpecs: PreparedMappingSpec,
  record: string[],
): MappingResult {
  const result = {};
  const errors: MappingError[] = [];

  fieldSpecs.forEach(([schemaField, mapping, index]) => {
    doMapField(schemaField, mapping, record[index], result, errors);
  });

  return { result, errors };
}

export function mapRecord<T = object>(
  fieldSpecs: PreparedMappingSpec,
  record: string[],
  cls: ClassConstructor<T>,
): MappingResult<T>;

export function mapRecord<T = object>(
  fieldSpecs: PreparedMappingSpec,
  record: string[],
): MappingResult;

export function mapRecord<T = object>(
  fieldSpecs: PreparedMappingSpec,
  record: string[],
  cls?: ClassConstructor<T>,
): MappingResult {
  const mapped = doMapRecord(fieldSpecs, record);
  if (cls) {
    return {
      ...mapped,
      result: plainToClass(cls, mapped.result) as unknown as MappingResult<T>,
    };
  } else {
    return mapped;
  }
}
