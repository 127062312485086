import { LocationPortalSpec } from '@warebee/shared/engine-model';
import {
  Field,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';

export enum AisleSide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

registerEnumType(AisleSide, {
  name: 'AisleSide',
});

@ObjectType()
export class LayoutImportLocation {
  @Field()
  locationKey: string;

  @Field()
  locationId: string;

  @Field()
  locationOrder: string; // may exceed js int

  @Field()
  locationStatus: boolean;

  @Field()
  locationLevel: number;

  @Field()
  warehouseArea: string;

  @Field()
  locationLength: number;

  @Field()
  locationWidth: number;

  @Field()
  locationHeight: number;

  @Field()
  locationWeight: number;

  @Field()
  locationAisle: string;

  @Field()
  locationBay: number;

  @Field()
  locationBayPosition: number;

  @Field()
  locationDepthPosition: number;

  @Field()
  baySide: string;

  @Field()
  locationBayId: string;

  @Field()
  locationUsageType: string;

  @Field()
  locmhtype: string;

  @Field()
  locationRackingType: string;

  @Field({ nullable: true })
  bayType?: string;

  @Field(() => [LocationPortalSpec], { nullable: true })
  portalSpecs?: LocationPortalSpec[];
}
