import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { analyticsAgent } from '../AnalyticTracker';
import * as Icon from '../components/icons';
import { warehouseSelectedId } from '../store/warehouse.state';
import {
  appBillingPurchase,
  appBillingSubscription,
} from './store/appBilling.state';

const AppBillingStepsValues = [
  'pricing',
  'purchase-customer',
  'purchase-form',
  'payment-status',
] as const;

export type AppBillingSteps = (typeof AppBillingStepsValues)[number];

export type AppBillingStepperProps = {
  active: AppBillingSteps;
  priceId?: string;
};

const AppBillingStepper: React.FC<AppBillingStepperProps> = props => {
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const setPurchase = useSetRecoilState(appBillingPurchase);
  const setSubscription = useSetRecoilState(appBillingSubscription);
  const navigate = useNavigate();

  const activeIndex = AppBillingStepsValues.indexOf(props.active);

  function getStepName(step: AppBillingSteps): string {
    switch (step) {
      case 'pricing':
        return t`Warehouse Plan`;
      case 'purchase-customer':
        return t`Billing Details`;
      case 'purchase-form':
        return t`Payment Details`;
      case 'payment-status':
        return t`Confirmation`;
    }
  }

  function isEnabled(target: AppBillingSteps, active: AppBillingSteps) {
    //disallow go back from status step
    if (active === 'payment-status') {
      return false;
    }
    return (
      AppBillingStepsValues.indexOf(target) <
      AppBillingStepsValues.indexOf(active)
    );
  }

  function backToStep(step: AppBillingSteps) {
    switch (step) {
      case 'pricing':
        navigate(`/wh/i/${whId}/pricing/${props.priceId ?? ''}`);
        return;
      case 'purchase-customer':
        setPurchase(null);
        setSubscription(null);
        navigate(`/wh/i/${whId}/purchase/${props.priceId ?? ''}`);
        return;
      default:

      //do nothing for other steps
    }
  }

  return (
    <nav
      data-component="AppBillingStepper"
      aria-label="Progress"
      className={classNames('flex p-4 px-8 w-full sticky top-0 z-10')}
    >
      <ol
        className={classNames(
          'flex-1',
          'bg-menu',
          'rounded-md',
          'divide-y divide-menu md:flex md:divide-y-0',
        )}
      >
        {AppBillingStepsValues.map((step: AppBillingSteps, stepIdx) => {
          const title = getStepName(step);
          const counter = (stepIdx + 1).toString().padStart(2, '0');
          const isEnabledStep = isEnabled(step, props.active);
          const isLastStep = stepIdx !== AppBillingStepsValues.length - 1;
          const isActive = step === props.active;

          const styleClassTitleSize = classNames(
            'text-base md:text-lg xl:text-xl',
            'font-bold',
          );

          const styleClassStep = classNames(
            'ltr:pl-4 rtl:pr-4 lg:ltr:pl-8 lg:rtl:pr-8 xl:ltr:pl-10 rtl:pr-10 ltr:pr-2 rtl:pl-2 py-3',
            'flex items-center',
            'flex-1',
            styleClassTitleSize,
          );

          const styleClassCounter = classNames(
            'w-8 h-8 xl:w-10 xl:h-10',
            'rounded-full',
            'flex flex-shrink-0 items-center justify-center',
          );

          const styleClassTitle = classNames(
            'ltr:ml-2 ltr:xl:ml-4 rtl:mr-2 rtl:xl:mr-4',
          );

          return (
            <li
              key={step}
              className={classNames('relative', 'flex-1 flex', 'group')}
            >
              {isActive ? (
                <div
                  aria-current="step"
                  data-component="step-active"
                  className={classNames(
                    'flex-1',
                    'bg-menu-active text-menu-active-text',
                    'rounded',
                    styleClassStep,
                  )}
                >
                  <span
                    className={classNames(
                      'bg-app-panel-dark/60 text-menu-active',
                      styleClassCounter,
                    )}
                  >
                    <span className="">{counter}</span>
                  </span>
                  <span
                    className={classNames(
                      'text-menu',
                      styleClassTitle,
                      styleClassTitleSize,
                    )}
                  >
                    {title}
                  </span>
                </div>
              ) : isEnabledStep ? (
                <div
                  data-component="step-before"
                  className={classNames(
                    'rounded-l-md',
                    'flex items-center',
                    'w-full',
                    'bg-menu-active text-menu-active-text',
                    'group-hover:bg-menu group-hover:text-menu-active',
                    'hover:cursor-pointer',
                  )}
                  onClick={e => {
                    backToStep(step);
                    try {
                      analyticsAgent?.track(`Progress Step (Before): ${title}`);
                    } catch (ex) {
                      console.debug('analyticsAgent Error:', ex);
                    }
                  }}
                >
                  <span className={classNames('', styleClassStep)}>
                    <span
                      className={classNames(
                        'rounded-full',
                        'border-2 border-app-background/50 group-hover:border-menu-active',
                        styleClassCounter,
                      )}
                    >
                      {counter}
                    </span>

                    <span
                      className={classNames(
                        'text-opacity-75 group-hover:text-menu-active',
                        styleClassTitle,
                      )}
                    >
                      {title}
                    </span>
                  </span>
                </div>
              ) : (
                <div
                  data-component="step-future"
                  className={classNames(
                    'isDisabledStep rounded-md group flex items-center flex-1',
                    stepIdx > activeIndex ? '' : 'bg-menu-active',
                  )}
                >
                  <span className={classNames('', styleClassStep)}>
                    <span
                      className={classNames(
                        'rounded-full border-2',
                        styleClassCounter,
                        stepIdx > activeIndex
                          ? 'border-menu/50'
                          : 'border-menu',
                      )}
                    >
                      <span
                        className={classNames(
                          'activeIndex',
                          stepIdx > activeIndex
                            ? 'text-menu/30'
                            : 'text-menu/60',
                        )}
                      >
                        {stepIdx > activeIndex ? (
                          counter
                        ) : (
                          <Icon.CircleOk
                            className={classNames('w-10 h-10 fill-current')}
                          />
                        )}
                      </span>
                    </span>
                    <span
                      className={classNames(
                        'ltr:ml-2 xl:ltr:ml-4',
                        'rtl:mr-2 xl:rtl:mr-4',
                        styleClassTitleSize,
                        stepIdx > activeIndex
                          ? 'text-menu-text/50'
                          : 'text-menu-active-text',
                      )}
                    >
                      {title}
                    </span>
                  </span>
                </div>
              )}

              {isLastStep && (
                <svg
                  data-component="ArrowForward"
                  height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 60 128"
                  className={classNames(
                    'absolute -right-6 top-0 bottom-0 z-100 max-h-5',
                    stepIdx > activeIndex
                      ? 'text-menu group-hover:text-menu'
                      : isActive
                        ? 'text-menu-active group-hover:text-menu-active'
                        : 'text-menu-active group-hover:text-menu',
                  )}
                >
                  <polyline
                    points="0 128 12.09 128 49 64 12.09 0 0 0"
                    fill="currentColor"
                  />
                  <polyline
                    points="12.09 128 49 64 12.09 0"
                    fill="none"
                    stroke="#2B3034"
                    strokeMiterlimit="10"
                    strokeWidth="3px"
                  />
                </svg>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default AppBillingStepper;
