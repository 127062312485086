import {
  AnalyzeResultFragment,
  AnalyzeResultProcessType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import {
  getAnalyzeTotals,
  getCarbonValue,
} from '../../simulation/store/analyze.helper';
import InboxZero from '../InboxZero';
import TitleSection from '../layout/TitleSection';
import { Stat } from './Stat';
import { StatGroup } from './StatGroup';

interface StatsComparePanelProps {
  analyzeBefore?: AnalyzeResultFragment;
  analyzeAfter?: AnalyzeResultFragment;
}

const StatsCompareSummaryPanel: React.FC<StatsComparePanelProps> = ({
  analyzeBefore,
  analyzeAfter,
}) => {
  const { t } = useTranslation('app');
  const formatter = useFormatter();

  if (!analyzeBefore && !analyzeAfter) {
    return (
      <StatGroup
        title={t`Optimisation Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/optimise/simulation-optimise-summary-diff'}
      >
        <InboxZero selfCenter message={t`No Analysed Data`} />
      </StatGroup>
    );
  } else if (!analyzeBefore) {
    return (
      <StatGroup
        title={t`Optimisation Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/optimise/simulation-optimise-summary-diff'}
      >
        <InboxZero selfCenter message={t`No Analyse Data (First)`} />
      </StatGroup>
    );
  } else if (!analyzeAfter) {
    return (
      <StatGroup
        title={t`Optimisation Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/optimise/simulation-optimise-summary-diff'}
      >
        <InboxZero selfCenter message={t`No Analyse Data (Second)`} />
      </StatGroup>
    );
  }

  const totalsBefore = getAnalyzeTotals(analyzeBefore);
  const totalsAfter = getAnalyzeTotals(analyzeAfter);

  // Cost Data
  const costBefore = formatter.formatCurrency(totalsBefore.cost);
  const costAfter = formatter.formatCurrency(totalsAfter.cost);
  const costDelta = formatter.formatCurrency(costBefore.raw - costAfter.raw, 1);
  const costShareNumeric = 1 - totalsAfter.cost / totalsBefore.cost;
  const costShare = formatter.formatShare(costShareNumeric);

  // Distance Data
  const distanceBefore = formatter.formatDistance(totalsBefore.distance);
  const distanceAfter = formatter.formatDistance(totalsAfter.distance);
  const distanceDelta = formatter.formatDistance(
    distanceBefore.raw - distanceAfter.raw,
    1,
  );
  const distanceShareNumeric = 1 - distanceAfter.raw / distanceBefore.raw;
  const distanceShare = formatter.formatShare(distanceShareNumeric);

  // Duration Data
  const durationBefore = formatter.formatTimeSpan(totalsBefore.duration, 1);
  const durationAfter = formatter.formatTimeSpan(totalsAfter.duration, 1);
  const durationDelta = formatter.formatTimeSpan(
    durationBefore.raw - durationAfter.raw,
  );
  const durationShareNumeric = 1 - totalsAfter.duration / totalsBefore.duration;
  const durationShare = formatter.formatShare(durationShareNumeric);

  // CO2 Data
  const carbonBeforeValue = getCarbonValue(analyzeBefore);
  const carbonAfterValue = getCarbonValue(analyzeAfter);
  const carbonBefore = formatter.formatCO2(carbonBeforeValue);
  const carbonAfter = formatter.formatCO2(carbonAfterValue);
  const carbonDelta = formatter.formatCO2(carbonBefore.raw - carbonAfter.raw);
  const carbonShareNumeric = 1 - carbonAfter.raw / carbonBefore.raw;
  const carbonShare = formatter.formatShare(carbonShareNumeric);

  // Order Pickability (Count of Assembled Orders)
  const orderCountAssembledBefore = formatter.formatInteger(
    analyzeBefore.totalAssembledOrders +
      analyzeBefore.totalAssembledPartiallyOrders,
  );
  const orderountAssembledAfter = formatter.formatInteger(
    analyzeAfter.totalAssembledOrders +
      analyzeAfter.totalAssembledPartiallyOrders,
  );

  // Order Lines
  const totalOrderLinesBeforeRaw =
    analyzeBefore?.assembledLines +
    analyzeBefore?.assembledPartiallyLines +
    analyzeBefore?.ignoredLines;

  const totalOrderLinesBeforeCount = formatter.formatInteger(
    totalOrderLinesBeforeRaw,
  );

  const totalOrderLinesAfterRaw =
    analyzeBefore?.assembledLines +
    analyzeBefore?.assembledPartiallyLines +
    analyzeBefore?.ignoredLines;

  const totalOrderLinesAfterCount = formatter.formatInteger(
    totalOrderLinesAfterRaw,
  );

  const assembledLinesBeforeRaw = analyzeBefore?.assembledLines ?? 0;
  const assembledLinesAfterRaw = analyzeAfter?.assembledLines ?? 0;
  const assembledLinesBeforeCount = formatter.formatInteger(
    assembledLinesBeforeRaw,
  );
  const assembledLinesAfterCount = formatter.formatInteger(
    assembledLinesAfterRaw,
  );

  const assembledPartiallyLinesBeforeRaw =
    analyzeBefore?.assembledPartiallyLines ?? 0;
  const assembledPartiallyLinesAfterRaw =
    analyzeAfter?.assembledPartiallyLines ?? 0;
  const assembledPartiallyLinesBeforeCount = formatter.formatInteger(
    assembledPartiallyLinesBeforeRaw,
  );
  const assembledPartiallyLinesAfterCount = formatter.formatInteger(
    assembledPartiallyLinesAfterRaw,
  );

  const ignoredLinesBeforeRaw = analyzeBefore?.ignoredLines ?? 0;
  const ignoredLinesAfterRaw = analyzeAfter?.ignoredLines ?? 0;
  const ignoredLinesBeforeCount = formatter.formatInteger(
    ignoredLinesBeforeRaw,
  );
  const ignoredLinesAfterCount = formatter.formatInteger(ignoredLinesAfterRaw);

  // Performance
  const totalAssembledOrderLinesCountBeforeRaw =
    analyzeBefore.assembledLines + analyzeBefore.assembledPartiallyLines;

  const totalAssembledOrderLinesBeforeCount = formatter.formatInteger(
    totalAssembledOrderLinesCountBeforeRaw,
  );

  const totalAssembledOrderLinesCountAfterRaw =
    analyzeAfter.assembledLines + analyzeAfter.assembledPartiallyLines;

  const totalAssembledOrderLinesAfterCount = formatter.formatInteger(
    totalAssembledOrderLinesCountAfterRaw,
  );

  const pickingDurationBeforeRaw = _.find(
    analyzeBefore?.costDetails?.processes,
    p => p.processType === AnalyzeResultProcessType.PICKING,
  )?.duration;

  const pickingDurationAfterRaw = _.find(
    analyzeAfter?.costDetails?.processes,
    p => p.processType === AnalyzeResultProcessType.PICKING,
  )?.duration;

  const linesPerHourBeforeRaw =
    pickingDurationBeforeRaw > 0
      ? (totalAssembledOrderLinesCountBeforeRaw * 3600) /
        pickingDurationBeforeRaw
      : 0;

  const linesPerHourBefore = formatter.formatToPrecision(
    linesPerHourBeforeRaw,
    2,
    t`lines/h`,
  );

  const linesPerHourAfterRaw =
    pickingDurationAfterRaw > 0
      ? (totalAssembledOrderLinesCountAfterRaw * 3600) / pickingDurationAfterRaw
      : 0;
  const linesPerHourAfter = formatter.formatToPrecision(
    linesPerHourAfterRaw,
    2,
    t`lines/h`,
  );

  const linesPerHourShare = formatter.formatShare(
    linesPerHourBeforeRaw > 0
      ? linesPerHourAfterRaw / linesPerHourBeforeRaw - 1
      : 0,
  );

  const linesPerHourDelta = formatter.formatToPrecision(
    linesPerHourAfterRaw - linesPerHourBeforeRaw,
    2,
    t`lines/hour`,
  );

  return (
    <>
      <StatGroup
        title={t`Optimisation Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/optimise/simulation-optimise-summary-diff'}
      >
        <Stat
          inPanelMode
          hasHelper
          isPreview
          title={t`Total Cost`}
          valueDiffPercent={costShare.value}
          valueDiff={costDelta.value}
          valueBefore={costBefore}
          valueAfter={costAfter}
          isComparable
          unitOfMeasure={costAfter.unit}
          isUnitPrefix={costAfter.prefixUnit}
          valueIsBig
          isFullWidth
        />
        <Stat
          isFullWidth
          inPanelMode
          hasHelper
          isPreview
          title={t`Picking Distance`}
          valueDiffPercent={distanceShare.value}
          valueDiff={distanceDelta.value}
          valueBefore={distanceBefore}
          valueAfter={distanceAfter}
          isComparable
          valueIsBig
          unitOfMeasure={distanceBefore.unit}
        />
        <Stat
          inPanelMode
          hasHelper
          isPreview
          title={t`Picking Time`}
          valueBefore={durationBefore}
          valueAfter={durationAfter}
          valueDiffPercent={durationShare.value}
          valueDiff={durationDelta.value}
          isComparable
          unitOfMeasure={durationBefore.unit}
          valueIsBig
        />
        {(carbonBefore.raw > 0 || carbonAfter.raw > 0) && (
          <Stat
            inPanelMode
            hasHelper
            isPreview
            title={t`CO2`}
            valueBefore={carbonBefore}
            valueAfter={carbonAfter}
            valueDiffPercent={carbonShare.value}
            valueDiff={carbonDelta.value}
            isComparable
            unitOfMeasure={carbonBefore.unit}
            valueIsBig
          />
        )}

        {orderCountAssembledBefore.raw !== orderountAssembledAfter.raw && (
          <Stat
            inPanelMode
            hasHelper
            isPreview
            title={t`Order Pickability`}
            valueBefore={orderCountAssembledBefore}
            valueAfter={orderountAssembledAfter}
            // valueDiffPercent={durationShare.value}
            // valueDiff={durationDelta}
            isComparable
            unitOfMeasure={orderCountAssembledBefore.unit}
            valueIsBig
          />
        )}
        {(linesPerHourBeforeRaw > 0 || linesPerHourAfterRaw > 0) && (
          <Stat
            inPanelMode
            hasHelper
            isPreview
            title={t`Performance`}
            valueBefore={linesPerHourBefore}
            valueAfter={linesPerHourAfter}
            valueDiffPercent={linesPerHourShare.value}
            valueDiff={linesPerHourDelta.value}
            isComparable
            unitOfMeasure={linesPerHourBefore.unit}
            valueIsBig
          />
        )}

        <TitleSection
          key={`dataset-metric-group-Pickability`}
          id={`dataset-metric-group-Pickability`}
          title={t`Pickability (Order Lines)`}
          inPanelView
          collapsible
          classNameInner="space-y-1"
        >
          {(totalOrderLinesBeforeRaw > 0 || totalOrderLinesAfterRaw > 0) && (
            <Stat
              title={t`Total Order Lines`}
              valueBefore={totalOrderLinesBeforeCount}
              valueAfter={totalOrderLinesAfterCount}
              isComparable
              valueDiff={formatInteger(
                totalOrderLinesAfterCount.raw - totalOrderLinesAfterCount.raw,
              )}
              inPanelMode
            />
          )}

          {(totalAssembledOrderLinesCountBeforeRaw > 0 ||
            totalAssembledOrderLinesCountAfterRaw > 0) && (
            <Stat
              inPanelMode
              title={t`Total Picked Lines (Picked and Partial)`}
              valueBefore={totalAssembledOrderLinesBeforeCount}
              valueAfter={totalAssembledOrderLinesAfterCount}
              valueDiffPercent={
                formatter.formatShare(
                  totalAssembledOrderLinesCountAfterRaw /
                    totalAssembledOrderLinesCountBeforeRaw -
                    1,
                ).value
              }
              valueDiff={formatInteger(
                totalAssembledOrderLinesCountAfterRaw -
                  totalAssembledOrderLinesCountBeforeRaw,
              )}
              isComparable
              unitOfMeasure={totalAssembledOrderLinesBeforeCount.unit}
            />
          )}

          {(assembledLinesBeforeRaw > 0 || assembledLinesAfterRaw > 0) && (
            <Stat
              title={t`Picked Lines`}
              valueBefore={assembledLinesBeforeCount}
              valueAfter={assembledLinesAfterCount}
              isComparable
              valueDiff={formatInteger(
                assembledLinesAfterCount.raw - assembledLinesBeforeCount.raw,
              )}
              inPanelMode
            />
          )}

          {(assembledPartiallyLinesBeforeRaw > 0 ||
            assembledPartiallyLinesAfterRaw > 0) && (
            <Stat
              title={t`Partially Picked Lines`}
              valueBefore={assembledPartiallyLinesBeforeCount}
              valueAfter={assembledPartiallyLinesAfterCount}
              isComparable
              valueDiff={formatInteger(
                assembledPartiallyLinesAfterCount.raw -
                  assembledPartiallyLinesBeforeCount.raw,
              )}
              inPanelMode
            />
          )}

          {(ignoredLinesBeforeRaw > 0 || ignoredLinesAfterRaw > 0) && (
            <Stat
              title={t`Ignored Lines`}
              valueBefore={ignoredLinesBeforeCount}
              valueAfter={ignoredLinesAfterCount}
              isComparable
              valueDiff={formatInteger(
                ignoredLinesAfterCount.raw - ignoredLinesBeforeCount.raw,
              )}
              inPanelMode
            />
          )}
        </TitleSection>
      </StatGroup>
    </>
  );
};

export default StatsCompareSummaryPanel;
