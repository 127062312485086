import * as React from 'react';
const WarehouseLargeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseLargeIcon"
    {...props}
  >
    <rect width={17.6} height={1.57} x={42} y={81.75} strokeWidth={0} />
    <rect width={17.6} height={0.15} x={13.5} y={91.58} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={42} y={76.87} strokeWidth={0} />
    <rect width={17.6} height={0.15} x={42} y={91.58} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={42} y={89.14} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={42} y={84.26} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="42 86.7 42 88.27 59.6 88.27 59.6 86.7 54.6 86.7 42 86.7"
    />
    <rect width={17.6} height={1.57} x={42} y={79.31} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={13.5} y={79.31} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={13.5} y={81.75} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={13.5} y={76.87} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="13.5 86.7 13.5 88.27 31.11 88.27 31.11 86.7 26.11 86.7 13.5 86.7"
    />
    <rect width={17.6} height={1.57} x={13.5} y={84.26} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={13.5} y={89.14} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="98.98 86.7 98.98 88.27 116.58 88.27 116.58 86.7 111.58 86.7 98.98 86.7"
    />
    <rect width={17.6} height={1.57} x={98.98} y={81.75} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={98.98} y={79.31} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={98.98} y={84.26} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="4.84 53.16 4.84 91.73 11.79 91.73 11.79 74.05 32.82 74.05 32.82 91.73 40.28 91.73 40.28 74.05 61.31 74.05 61.31 91.73 68.77 91.73 68.77 74.05 89.81 74.05 89.81 91.73 97.26 91.73 97.26 74.05 118.3 74.05 118.3 91.73 123.16 91.73 123.16 53.16 64.5 42.73 4.84 53.16"
    />
    <rect width={17.6} height={1.57} x={98.98} y={76.87} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={70.49} y={76.87} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={98.98} y={89.14} strokeWidth={0} />
    <rect width={17.6} height={0.15} x={98.98} y={91.58} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={70.49} y={84.26} strokeWidth={0} />
    <rect width={17.6} height={0.15} x={70.49} y={91.58} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={70.49} y={79.31} strokeWidth={0} />
    <rect width={17.6} height={1.57} x={70.49} y={81.75} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="70.49 86.7 70.49 88.27 88.09 88.27 88.09 86.7 83.09 86.7 70.49 86.7"
    />
    <rect width={17.6} height={1.57} x={70.49} y={89.14} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="64.5 36.27 2.84 46.69 2.84 51.71 64.5 41.29 125.16 51.71 125.16 46.69 64.5 36.27"
    />
  </svg>
);
export default WarehouseLargeIcon;
