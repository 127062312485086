import { OrderSetRow } from '@warebee/shared/export-converter';
import { parseISO } from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../common/formatHelper';
import useFormatter from '../common/useFormatter';
import { ColumnConfig } from '../components/DatasetTable';
import ItemCategoryTag from '../components/table/ItemCategoryTag';
import { getOrderSetSchema } from '../import/orders/schema/orderSetSchema';
import ItemTag from '../simulation/tags/ItemTag';

function useOrderSetDataTableConfig(): ColumnConfig<OrderSetRow>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();
  const schema = _.keyBy(getOrderSetSchema(tImporter).fields, f => f.name);

  const columnsConfigRaw: ColumnConfig<OrderSetRow>[] = [
    {
      field: 'orderId',
      title: t`Order ID`,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
    },
    {
      field: 'cumulativePercentRank',
      title: t`Category`,
      hasSort: false,
      hiddenInExport: true,
      render: (v: number) => <ItemCategoryTag cmlPercent={v} />,
    },
    {
      field: 'categorySting',
      title: t`Category`,
      hiddenInBrowser: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'customer',
      title: t`Customer`,
      hasFilter: true,
    },
    {
      field: 'orderLine',
      title: t`Order Line`,
      hasFilter: true,
    },
    {
      field: 'quantity',
      title: t`Qty`,
      hasFilter: true,
    },
    {
      field: 'orderDate',
      title: t`Order Date`,
      hasFilter: false, // ToDo: Filter
      render: (v: string) => (_.isNil(v) ? '—' : formatDateTime(parseISO(v))),
    },
    {
      field: 'pickingPriority',
      title: t`Picking Priority`,
      // hasFilter: true, // ToDo: Filter
    },
    {
      field: 'deliveryRoute',
      title: t`Delivery Route`,
      hasFilter: true,
    },
    {
      field: 'dock',
      title: t`Dock`,
      hasFilter: true,
    },
    {
      field: 'stagingArea',
      title: t`Staging Area`,
      hasFilter: true,
    },
    {
      field: 'waveId',
      title: t`Wave ID`,
      hasFilter: true,
    },
    {
      field: 'pickingContainer',
      title: t`Container`,
      hasFilter: true,
    },
    {
      field: 'details.dailyStats.orderLineCount.max',
      title: t`Visits Count (Daily) (max)`,
      hasFilter: false,
    },
    {
      field: 'details.dailyStats.orderLineCount.avg',
      title: t`Visits Count (Daily) (avg)`,
      hasFilter: false,
    },
    {
      field: 'details.dailyStats.orderLineCount.cv',
      title: t`Visits Count (Daily) (cv)`,
      hasFilter: false,
    },
    {
      field: 'details.weeklyStats.orderLineCount.max',
      title: t`Visits Count (Weekly) (max)`,
      hasFilter: false,
    },
    {
      field: 'details.weeklyStats.orderLineCount.avg',
      title: t`Visits Count (Weekly) (avg)`,
      hasFilter: false,
    },

    {
      field: 'details.dailyStats.totalDaysWithOrders',
      title: t`Day Count (Ordered Daily) (max)`,
      hasFilter: false,
    },
    {
      field: 'details.weeklyStats.daysWithOrders.max',
      title: t`Day Count (Weekly Orders) (max)`,
      hasFilter: false,
    },
    {
      field: 'details.weeklyStats.totalWeeksWithOrders',
      title: t`Week Count (Weekly Orders)`,
      hasFilter: false,
    },
  ];

  const columnsConfig = columnsConfigRaw.map(f => ({
    ...f,
    title: f.title ?? schema[f.field]?.title,
  }));

  return columnsConfig;
}
export default useOrderSetDataTableConfig;
