import { StringFilterRangeFragment } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPolicyFilterKey } from '../policyFilter.helper';
import {
  PolicyFilterBase,
  PolicyFilterEditorEvents,
} from '../policyFilter.types';
import PolicyEditorDropDown from './PolicyEditorDropDown';

export type PolicyStringRangeEditorProps = PolicyFilterEditorEvents & {
  filterType: string;
  value: PolicyFilterBase<string>;
};

const PolicyStringRangeEditor: React.FC<
  PolicyStringRangeEditorProps
> = props => {
  const { t } = useTranslation('app');
  const fromFilterKey = getPolicyFilterKey(props.filterType, 'main');
  const toFilterKey = getPolicyFilterKey(props.filterType, 'extra');

  function onChange(changes: Partial<StringFilterRangeFragment>) {
    props.onChange({
      ...props.value,
      stringRange: {
        ...(props.value?.stringRange ?? {}),
        ...changes,
      },
    });
  }

  return (
    <div data-component="PolicyStringRangeEditor" className="p-2 space-y-4">
      <PolicyEditorDropDown
        title={t`From:`}
        type={props.filterType}
        filterKey={fromFilterKey}
        selected={props.value?.stringRange?.from}
        onSearch={v => props.onSearch(v, fromFilterKey)}
        onSelectValue={v => onChange({ from: v })}
      />
      <PolicyEditorDropDown
        title={t`To:`}
        type={props.filterType}
        filterKey={toFilterKey}
        selected={props.value?.stringRange?.to}
        onSearch={v => props.onSearch(v, toFilterKey)}
        onSelectValue={v => onChange({ to: v })}
      />
    </div>
  );
};

export default PolicyStringRangeEditor;
