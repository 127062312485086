import {
  AgentCapacityUomSettingsFragment,
  AgentSpecsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  AgentEditorSettings,
  AgentFilterSectionConfig,
} from './AgentEditorSettings';

export function getSpecFilterConfiguration(
  config: AgentEditorSettings,
  t: TFunction<'simulation'>,
  agentSpecs: AgentSpecsFragment,
  uoms: string[],
): AgentFilterSectionConfig[] {
  function getMaxQuantityPerUom(
    group: AgentCapacityUomSettingsFragment[],
    getTitle: (uom: string) => string,
  ) {
    const getUomIndex = (uom: string) => _.findIndex(group, g => g.uom === uom);

    return _(uoms)
      .filter(u => _.some(group, g => g.uom === u))
      .map(uom => ({
        path: [
          'specs',
          'capacity',
          'maxUomQuantity',
          `[${getUomIndex(uom)}]`,
          'quantity',
        ],
        title: getTitle(uom),
        filterConfig: config.capacityMaxUomCount,
      }))
      .value();
  }

  return [
    {
      title: t(`Specs`, { ns: 'simulation' }),
      counter: t`C`,
      filterGroups: [
        {
          title: t(`Speeds`, { ns: 'simulation' }),
          description: t(
            `Travelling speeds calculation parameters. Time calculation is based on the travelling speed of horizontal and vertical movement (Up/Down). Different speed values can be configured for the loaded and unloaded state of the MHE.`,
            { ns: 'simulation' },
          ),
          category: t(`SPECIFICATION`, { ns: 'simulation' }),
          filters: [
            {
              path: ['specs', 'horizontalTravelling', 'speed', 'speedLaden'],
              title: t(`Horizontal Speed - Loaded`, { ns: 'simulation' }),
              filterConfig: config.horizontalSpeed,
            },
            {
              path: ['specs', 'horizontalTravelling', 'speed', 'speedUnladen'],
              title: t(`Horizontal Speed - Unloaded`, { ns: 'simulation' }),
              filterConfig: config.horizontalSpeed,
            },
            // vertical up
            {
              path: ['specs', 'verticalTravelling', 'speedUp', 'speedLaden'],
              title: t(`Vertical Speed - Up - Loaded`, { ns: 'simulation' }),
              filterConfig: config.verticalSpeed,
            },
            {
              path: ['specs', 'verticalTravelling', 'speedUp', 'speedUnladen'],
              title: t(`Vertical Speed - Up - Unloaded`, { ns: 'simulation' }),
              filterConfig: config.verticalSpeed,
            },
            // vertical down
            {
              path: ['specs', 'verticalTravelling', 'speedDown', 'speedLaden'],
              title: t(`Vertical Speed - Down - Loaded`, { ns: 'simulation' }),
              filterConfig: config.verticalSpeed,
            },
            {
              path: [
                'specs',
                'verticalTravelling',
                'speedDown',
                'speedUnladen',
              ],
              title: t(`Vertical Speed - Down - Unloaded`, {
                ns: 'simulation',
              }),
              filterConfig: config.verticalSpeed,
            },
          ],
        },
        {
          title: t(`Capacity`, { ns: 'simulation' }),
          description: t(
            `We use manufacturer's equipment specs to define the capacity specs.`,
            { ns: 'simulation' },
          ),
          category: t(`SPECIFICATION`, { ns: 'simulation' }),
          filters: [
            {
              path: ['specs', 'capacity', 'maxVolume'],
              title: t(`Capacity by volume`, { ns: 'simulation' }),
              filterConfig: config.capacityVolume,
            },
            {
              path: ['specs', 'capacity', 'maxWeight'],
              title: t(`Capacity by weight`, {
                ns: 'simulation',
              }),
              filterConfig: config.capacityWeight,
            },

            ...getMaxQuantityPerUom(agentSpecs.capacity?.maxUomQuantity, uom =>
              t(`Max Capacity: {{uom}}`, {
                ns: 'simulation',
                uom,
              }),
            ),
          ],
        },
        {
          title: t(`Energy Consumption`, { ns: 'simulation' }),
          description: t(
            `We use manufacturer's equipment specs to define the default energy consumption parameters. Adjust if needed.`,
            { ns: 'simulation' },
          ),
          category: t(`SPECIFICATION`, { ns: 'simulation' }),
          filters: [
            {
              path: ['specs', 'powerSource', 'energyConsumptionPerSecond'],
              title: t(`Energy Consumption Per Hour`, { ns: 'simulation' }),
              description: t(
                'Energy consumed per unit of time (KW/h). CO2 calculations use this coefficient.',
                { ns: 'simulation' },
              ),
              filterConfig: config.energyConsumption,
            },
          ],
        },
      ],
    },
  ];
}
