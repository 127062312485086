import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LayoutFilter from '../components/LayoutFilter';
import {
  simulationLayoutHeatmapFilters,
  simulationLayoutSelectedOrderId,
} from './store/simulation.layout.state';
import { showAnalyseData } from './store/simulation.wizard.state';

const SimulationFilters: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [rangeFilter, setRangeFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );
  const [selectedOrder, setSelectedOrder] = useRecoilState(
    simulationLayoutSelectedOrderId,
  );
  const showAnalyse = useRecoilValue(showAnalyseData);

  if (!showAnalyse) return null;

  return (
    <>
      {rangeFilter?.hiddenBuckets?.size ||
      rangeFilter.hideDisabled ||
      rangeFilter.hideEmpty ||
      rangeFilter.hideZeros ? (
        <LayoutFilter
          key={`heatmap-filter`}
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`Filter`}
          onRemoveClick={() => {
            setRangeFilter({});
            //   }}
          }}
        />
      ) : null}
      {/* //    <DropdownSelector
      //   className="text-xs m-0.5"
      //   // label={'Area'}
      //   values={[getCategoryName(key)]}
      //   value={
      //     <Icon.Filter className="h-4 ltr:mr-2 rtl:ml-2 text-menu-text fill-current" /> +
      //     ' ' +
      //     'Show'
      //   }
      //   onChange={() => {
      //     setCategoryFilter(_.omit(categoryFilter, key));
      //   }}
      //   // renderValue={[getHeatmapName(f)]}
      //   headerMode
      // />

      // <DropdownSelector
      //   className="text-xs m-0.5"
      //   // label={'Area'}
      //   values={_.keys(rangeFilter) as any}
      //   value={
      //     <div>
      //       <Icon.Filter className="h-4 ltr:mr-2 rtl:ml-2 text-menu-text fill-current " />
      //       {t`Filtered:`}
      //     </div>
      //   }
      //   onChange={v => {
      //     setRangeFilter(_.omit(rangeFilter, v as any));
      //   }}
      //   //renderValue={v => getHeatmapName(v)}
      //   headerMode
      // /> */}
      {/*
      {_.isEmpty(categoryFilter) ? null : (
        <LayoutFilter
          key={`category-filter`}
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`ABC filter`}
          onRemoveClick={() => {
            setCategoryFilter({});
          }}
        />
      )} */}

      {_.isNil(selectedOrder) ? null : (
        <LayoutFilter
          key={`order-filter`}
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`Order filter`}
          onRemoveClick={() => {
            setSelectedOrder(null);
          }}
        />
      )}
      {/* //    <DropdownSelector
        //   className="text-xs m-0.5"
        //   // label={'Area'}
        //   values={[getCategoryName(key)]}
        //   value={
        //     <Icon.Filter className="h-4 ltr:mr-2 rtl:ml-2 text-menu-text fill-current" /> +
        //     ' ' +
        //     'Show'
        //   }
        //   onChange={() => {
        //     setCategoryFilter(_.omit(categoryFilter, key));
        //   }}
        //   // renderValue={[getHeatmapName(f)]}
        //   headerMode
        // /> */}
    </>
  );
};

export default SimulationFilters;
