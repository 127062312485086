import {
  getDefaultMeasureOption,
  getMeasureOptions,
} from '@warebee/shared/import-converter';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getMeasureEditor } from '../../../common/measureHelper';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { InputValue } from '../../../components/inputs/InputValue';
import { warehouseMeasurementSystem } from '../../../store/warehouse.state';
import { MappingFieldSettings } from '../../store/import.types';

export type ImporterTableMeasureEditorProps = {
  measureValue: string;
  schemaField: MappingFieldSettings<Object>;
  onChange: (v: string) => void;
};

const ImporterTableMeasureEditor: React.FC<
  ImporterTableMeasureEditorProps
> = props => {
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const fieldMeasure = props.schemaField?.measureType;

  if (_.isNil(fieldMeasure)) return null;

  const fieldMeasureEditor = getMeasureEditor(fieldMeasure);
  const options = getMeasureOptions(fieldMeasure, ms);
  const defaultOption = getDefaultMeasureOption(fieldMeasure, ms);

  const selectedValue = props.measureValue ?? defaultOption;

  //
  if (fieldMeasureEditor === 'input') {
    return (
      <InputValue
        className="min-w-[10rem]"
        classNameValue={classNames('rounded ltr:pl-4 rtl:pr-4')}
        value={selectedValue}
        onChange={props.onChange}
        isDebouncedChange={true}
      />
    );
  }

  // return default editor - drop down
  return (
    <DropdownSelector
      className={classNames({
        'unmapped bg-alerts-error rounded': _.isNil(selectedValue),
        'mapped bg-app-panel/75 rounded': !_.isNil(selectedValue),
      })}
      widthFull
      classNameValue={classNames('ltr:pl-2 rtl:pr-2')}
      values={options}
      value={selectedValue}
      onChange={props.onChange}
      //renderValue={v => MappingLocalDateTimeFormats?.[v]?.example ?? v}
    />
  );
};

export default ImporterTableMeasureEditor;
