import _ from 'lodash';
import React, { PropsWithChildren, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import StageContainer from '../layout/stage/StageContainer';
import { layoutDocumentLoadStatus } from '../layout/store/layout.state';
import SimulationLayoutPlaceholder from './SimulationLayoutPlaceholder';
import HtmlTooltipLayer from './layers/HtmlTooltipLayer';
import {
  simulationAnalyzeId,
  simulationCurrentId,
  simulationLayoutId,
} from './store/simulation.state';
import SimulationViewModeToolbar from './toolbars/SimulationViewModeToolbar';

export type SimulationLayoutContainerProps = PropsWithChildren & {
  getAbsoluteContent: () => React.ReactNode | undefined;
  hideViewModeToolbar?: boolean;
};
const SimulationLayoutContainer: React.FC<
  SimulationLayoutContainerProps
> = props => {
  const { t } = useTranslation('simulation');
  const simId = useRecoilValue(simulationCurrentId);
  const layoutId = useRecoilValue(simulationLayoutId);
  const loadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const analyzeId = useRecoilValue(simulationAnalyzeId);

  if (_.isNil(simId)) return null;
  if (!_.isNil(simId) && !layoutId) {
    return <SimulationLayoutPlaceholder />;
  }

  if (loadStatus === AsyncLoadStatus.Loading) {
    return (
      <LoadingIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Loading Layout...`}
      />
    );
  }
  if (loadStatus === AsyncLoadStatus.Error) {
    return (
      <ErrorIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Cannot load layout`}
      />
    );
  }

  return (
    <>
      <StageContainer type="simulation-area-view">
        {props.children}
      </StageContainer>

      <Suspense
        fallback={
          <LoadingIndicator
            className="left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-10 shadow-2xl"
            absolute
            selfCenter
            backdrop
            message={t`Loading Layout...`}
          />
        }
      >
        {!props.hideViewModeToolbar && <SimulationViewModeToolbar />}
        <HtmlTooltipLayer analyzeId={analyzeId} />
        {props.getAbsoluteContent()}
      </Suspense>
    </>
  );
};

export default SimulationLayoutContainer;
