import { endOfDay, startOfDay } from 'date-fns';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import DateRangePicker from '../../components/inputs/calendar/DateRangePicker';
import { simulationAnalyzeInterval } from '../store/simulation.state';
import { workforceSimulateInterval } from '../store/workforce.state';

type SimulationDatePickerProps = {
  analyzeId?: string;
};

const SimulationDatePicker: React.FC<SimulationDatePickerProps> = props => {
  // const days = useRecoilValue(simulationAnalyzeDistinctDays);

  const analyzeInterval = useRecoilValue(simulationAnalyzeInterval);
  const [simulateInterval, setSimulateInterval] = useRecoilState(
    workforceSimulateInterval,
  );

  function setInterval([from, to]: [Date, Date]) {
    setSimulateInterval([startOfDay(from), endOfDay(to)]);
  }

  return (
    <DateRangePicker
      className="mx-1"
      value={simulateInterval}
      minValue={analyzeInterval?.[0]}
      maxValue={analyzeInterval?.[1]}
      onChange={setInterval}
      onResetClick={() => setSimulateInterval(null)}
    />
  );
};

export default SimulationDatePicker;
