import { addSeconds, max, min } from 'date-fns';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import {
  AgentForecastWorkWindow,
  AgentWorkforceSchedule,
  AgentWorkforceWave,
  AgentWorkforceWaveBase,
} from './workforce.types';

export function scheduleWaves(
  forecastInterval: [Date, Date],
  sourceWaves: AgentWorkforceWaveBase[],
  workWindows: AgentForecastWorkWindow[],
): AgentWorkforceSchedule {
  const waves: AgentWorkforceWave[] = [];
  const unscheduledWaves = _.sortBy(sourceWaves, w => w.source.to);
  const availableWorkWindows = _.sortBy(workWindows, w => w.to);

  while (!(_.isEmpty(unscheduledWaves) || _.isEmpty(availableWorkWindows))) {
    const wave = unscheduledWaves.pop();
    let workWindow: AgentForecastWorkWindow;
    do {
      workWindow = availableWorkWindows.pop();
    } while (!_.isNil(workWindow) && workWindow?.from > wave.source.to);

    // If work window is null, that means there is now any available work windows
    // and remaining work become unscheduled
    if (_.isNil(workWindow)) continue;

    const scheduledTo = min([workWindow.to, wave.source.to]);
    const targetWaveFrom = addSeconds(
      scheduledTo,
      -Math.ceil(wave.remainingDuration / workWindow.agentAmount),
    );

    const scheduledFrom = max([workWindow.from, targetWaveFrom]);

    const scheduledDuration = Math.ceil(
      (scheduledTo.getTime() - scheduledFrom.getTime()) / 1000,
    );

    const scheduledDurationTotal = scheduledDuration * workWindow.agentAmount;

    // If scheduled duration less than initial wave duration,
    // create and add new wave  with remaining duration
    // and  push it to unscheduled waves array

    const remainingDuration = wave.remainingDuration - scheduledDurationTotal;
    if (remainingDuration > 0) {
      const subWave: AgentWorkforceWaveBase = {
        ...wave,
        scheduledIndex: wave.scheduledIndex + 1,
        remainingDuration,
      };
      unscheduledWaves.push(subWave);
    }

    // Restore remaining working time
    if (workWindow.from < scheduledFrom) {
      const remainingWorkWindow: AgentForecastWorkWindow = {
        ...workWindow,
        to: scheduledFrom,
      };
      availableWorkWindows.push(remainingWorkWindow);
    }

    // create scheduled wave
    const scheduledWave: AgentWorkforceWave = {
      ...wave,
      id: nanoid(),
      remainingDuration,
      forecast: {
        from: scheduledFrom,
        to: scheduledTo,
        durationTotal: scheduledDurationTotal,
      },
      forecastWindow: workWindow,
    };
    waves.push(scheduledWave);
  }

  const unscheduledDuration = _.sumBy(
    unscheduledWaves,
    w => w.remainingDuration,
  );

  return {
    forecastInterval,
    waves,
    workWindows,
    unscheduledWaves,
  };
}
