import { InferResult, sql } from 'kysely';
import _ from 'lodash';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedAggregatedEvents = InferResult<
  ReturnType<typeof getFeedAggregatedEventsQuery>
>;
export type FeedAggregatedEvent = FeedAggregatedEvents[number];

export function getFeedAggregatedEventsQuery(
  params: ActivityFeedQueryBuilderParams,
) {
  return datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .selectFrom('af')
    .select(({ fn }) => [
      'agentId',
      sql<string>`date_trunc('hour' , ${sql.ref('eventEndTime')})`.as(
        'eventHour',
      ),
      fn.count<number>('eventId').as('eventCount'),
      fn.sum<number>('quantity').as('uomCount'),
      fn
        .agg<string>('array_agg', [
          sql<string>`concat('"', ${sql.ref('af.jobId')},'"')`,
        ])
        .distinct()
        .as('jobIds'),
    ])
    .where('locationId', 'is not', null)
    .groupBy('agentId')
    .groupBy(sql<string>`date_trunc('hour' , ${sql.ref('eventEndTime')})`);
}

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return fName === 'start' || fName === 'end';
};

export function postProcessFeedAggregatedEvents(
  events: Record<string, any>[],
): FeedAggregatedEvents {
  return postProcessDefault<FeedAggregatedEvent>(events, isDateField);
}
