import {
  Field,
  ID,
  InputType,
  PartialType,
  PickType,
} from '@warebee/shared/util-backend-only-types';
import { CreateWarehouseInput } from './create-warehouse.input';

@InputType()
export class UpdateWarehouseInput extends PartialType(
  PickType(CreateWarehouseInput, [
    'title',
    'description',
    'companyName',
    'address',
    'extraSettings',
  ]),
) {
  @Field(() => ID)
  warehouseId: string;
}
