import * as React from 'react';
const HideIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HideIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M82.94,34.38c-5.98-1.82-12.34-2.95-18.94-2.95-29.2,0-53.79,22.04-61.06,29.25-1.26,1.25-1.26,3.28,0,4.52,4.3,4.27,14.66,13.72,28.44,20.73l51.56-51.56"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M125.06,60.68c-4.42-4.38-15.22-14.22-29.56-21.28l11.46-11.46h-9.91L24.93,100.05h9.92l8.96-8.96c6.34,2.06,13.13,3.36,20.19,3.36,29.2,0,53.78-22.03,61.06-29.25,1.26-1.25,1.26-3.27,0-4.52h0ZM85.13,67.51c-1.72,8.24-8.32,14.84-16.56,16.56-5.63,1.17-10.94.1-15.32-2.42l9.24-9.24c.49.08.99.14,1.51.14,5.3,0,9.6-4.3,9.6-9.6,0-.52-.05-1.02-.13-1.51l9.24-9.24c2.52,4.37,3.6,9.69,2.42,15.32h0Z"
    />
  </svg>
);
export default HideIcon;
