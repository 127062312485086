import * as React from 'react';
const MapPathIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MapPathIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="4.93 81.68 17.96 74.28 64 100.59 110.04 74.28 123.07 81.68 64 115.44 4.93 81.68"
    />
    <path
      strokeWidth={0}
      d="M40.23,38.95c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M87.77,38.95c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M64,52.49c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M64,25.41c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M112.05,52.49c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.86-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.47.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M15.95,52.49c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M40.23,66.11c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M87.77,66.11c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M64,79.65c-3.13,0-6.1-.67-8.16-1.85-1.48-.84-1.85-1.59-1.87-1.73.01-.13.39-.87,1.86-1.7,2.06-1.17,5.04-1.84,8.17-1.84s6.11.67,8.17,1.84c1.46.83,1.84,1.57,1.86,1.71-.01.13-.39.88-1.87,1.72-2.06,1.17-5.03,1.85-8.16,1.85Z"
    />
    <path
      strokeWidth={0}
      d="M79.83,72.82l-35.69-17.99c-2.25-1.13-3.65-3.4-3.66-5.91-.01-2.51,1.37-4.79,3.6-5.95l11.9-6.16c.94-.49,1.49-1.41,1.45-2.48-.04-1.06-.64-1.95-1.61-2.37l-10.15-4.97c-1.27-.55,4.45-2.96,5.71-2.41l6.43,2.8c2.75,1.2,4.51,3.8,4.61,6.79.1,2.99-1.49,5.71-4.15,7.08l-11.9,6.16c-.82.42-.9,1.18-.9,1.48,0,.31.09,1.06.91,1.47l39.26,20.05s-5.45,2.59-5.81,2.4Z"
    />
  </svg>
);
export default MapPathIcon;
