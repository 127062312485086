import {
  Field,
  ID,
  InputAndObjectType,
  Int,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { NumberFilterRange } from '../filter.model';
import { ItemFilterUnion } from '../item-filter.model';

export const STACKING_POLICY_FALLBACK_CATEGORY = 'MIDDLE';

export enum StackingPolicyRuleSubcategoryType {
  PICKLIST_LINE_WEIGHT = 'PICKLIST_LINE_WEIGHT',
  BASE_UOM_WEIGHT = 'BASE_UOM_WEIGHT',
  PICKED_UOM_WEIGHT = 'PICKED_UOM_WEIGHT',
}

registerEnumType(StackingPolicyRuleSubcategoryType, {
  name: 'StackingPolicyRuleSubcategoryType',
});

@InputAndObjectType()
export class StackingPolicyRuleSubcategories {
  @Field(() => StackingPolicyRuleSubcategoryType, {
    nullable: true,
    defaultValue: StackingPolicyRuleSubcategoryType.PICKLIST_LINE_WEIGHT,
  })
  type?: StackingPolicyRuleSubcategoryType;

  @Field({ nullable: true })
  step?: number;

  @Field(() => [Number], { nullable: true })
  boundaries?: number[];
}

@InputAndObjectType()
export class StackingPolicyRuleBase {
  @Field(() => StackingPolicyRuleSubcategories, { nullable: true })
  subcategories?: StackingPolicyRuleSubcategories;
}

@InputAndObjectType()
export class StackingPolicyRule extends StackingPolicyRuleBase {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => ItemFilterUnion, { nullable: true })
  itemsMatch?: ItemFilterUnion;
}

@InputAndObjectType()
export class StackingPolicyFallbackRule extends StackingPolicyRuleBase {}

@InputAndObjectType()
export class StackingPolicy {
  @Field(() => [StackingPolicyRule], { nullable: true })
  topRules?: StackingPolicyRule[];

  @Field(() => [StackingPolicyRule], { nullable: true })
  bottomRules?: StackingPolicyRule[];

  @Field(() => StackingPolicyFallbackRule, { nullable: true })
  fallbackRule?: StackingPolicyFallbackRule;

  @Field(() => StackingPolicyRule, {
    nullable: true,
    deprecationReason: 'in favour of topRules',
  })
  top?: StackingPolicyRule;

  @Field(() => StackingPolicyRule, {
    nullable: true,
    deprecationReason: 'in favour of bottomRules',
  })
  bottom?: StackingPolicyRule;

  @Field(() => NumberFilterRange, {
    nullable: true,
    deprecationReason: 'in favour of fallbackRule.subcategories.boundaries',
  })
  totalNetWeightRange?: NumberFilterRange;
}

/**
 * Stacking priority.
 *
 * @TJS-type array
 * @items.type integer
 * @minItems 2
 * @maxItems 2
 */
export type StackingPriority = [number, number];
export const GRAPHQL_STACKING_PRIORITY = [Int];
