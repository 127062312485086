import {
  LocationFilterIntersectionFragment,
  SimulationItemFilterIntersectionFragment,
  SwapPolicyFragment,
  SwapPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../layout/viewer/store/viewer.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import {
  simulationOptimisationPolicyTabKey,
  simulationWizardSelectedStepId,
} from '../simulation/store/simulation.wizard.state';
import { loadLocationsByRule } from './swapPolicy.helper';
import {
  SwapPolicyCheckResult,
  SwapPolicySelectedIdentity,
} from './swapPolicy.type';

const getKey = (postfix: string) => `warebee-simulation-swap-policy-${postfix}`;

export const swapPolicyDocument = atom<SwapPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const swapPolicySelectedIdentity = atom<SwapPolicySelectedIdentity>({
  key: getKey('selected-identity'),
  default: null,
});

export const swapPolicyRule = selectorFamily<SwapPolicyRuleFragment, string>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(swapPolicyDocument);
      return _.find(policy?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, value: SwapPolicyRuleFragment) => {
      const policy = get(swapPolicyDocument);
      set(swapPolicyDocument, {
        ...policy,
        rules: policy.rules.map(rule => (rule.id === ruleId ? value : rule)),
      });
    },
});

export const swapPolicyLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  SwapPolicySelectedIdentity
>({
  key: getKey('location-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(swapPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters =
        identity.group === 'source'
          ? rule.src?.locationsMatch?.anyOf
          : rule.dest?.locationsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(swapPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(swapPolicyRule(identity.ruleId), {
        ...rule,
        src: {
          ...(rule.src ?? {}),
          locationsMatch: {
            ...(rule.src?.locationsMatch ?? {}),
            anyOf: _.map(rule.src?.locationsMatch?.anyOf, fs =>
              fs.id === identity.filterId ? value : fs,
            ),
          },
        },
        dest: {
          ...(rule.dest ?? {}),
          locationsMatch: {
            ...(rule.dest?.locationsMatch ?? {}),
            anyOf: _.map(rule.dest?.locationsMatch?.anyOf, fs =>
              fs.id === identity.filterId ? value : fs,
            ),
          },
        },
      });
    },
});

export const swapPolicyProductFilterIntersection = selectorFamily<
  SimulationItemFilterIntersectionFragment,
  SwapPolicySelectedIdentity
>({
  key: getKey('product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(swapPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters =
        identity.group === 'source'
          ? rule.src?.itemsMatch?.anyOf
          : rule.dest?.itemsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: SimulationItemFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(swapPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(swapPolicyRule(identity.ruleId), {
        ...rule,
        src: {
          ...(rule.src ?? {}),
          itemsMatch: {
            ...(rule.src?.itemsMatch ?? {}),
            anyOf: _.map(rule.src?.itemsMatch?.anyOf, fs =>
              fs.id === identity.filterId ? value : fs,
            ),
          },
        },
        dest: {
          ...(rule.dest ?? {}),
          itemsMatch: {
            ...(rule.dest?.itemsMatch ?? {}),
            anyOf: _.map(rule.dest?.itemsMatch?.anyOf, fs =>
              fs.id === identity.filterId ? value : fs,
            ),
          },
        },
      });
    },
});

export const swapPolicyCheckResult = atom<SwapPolicyCheckResult>({
  key: getKey('check-result'),
  default: null,
});

export const swapPolicyLevelLocationByRule = selector<Set<string>>({
  key: getKey('locations-by-level-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const identity = get(swapPolicySelectedIdentity);
    const tabKey = get(simulationOptimisationPolicyTabKey);
    const layoutId = sim?.layout?.id;
    if (
      _.isNil(layoutId) ||
      _.isNil(identity?.ruleId) ||
      identity?.type !== 'locations' ||
      _.isNil(level) ||
      stepId !== 'optimise' ||
      tabKey !== 'tab-optimisation-policy-swap'
    )
      return null;

    const rule = get(swapPolicyRule(identity.ruleId));

    return loadLocationsByRule({
      layoutId,
      rule,
      group: identity.group,
      filter: {
        planeId: [planeId],
        level: [level],
      },
    });
  },
});

export const swapPolicyBayLocationByRule = selector<Set<string>>({
  key: getKey('locations-per-bay-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const bay = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const identity = get(swapPolicySelectedIdentity);
    const tabKey = get(simulationOptimisationPolicyTabKey);

    const layoutId = sim?.layout?.id;
    if (
      _.isNil(layoutId) ||
      _.isNil(identity?.ruleId) ||
      _.isNil(bay) ||
      identity?.type !== 'locations' ||
      stepId !== 'optimise' ||
      tabKey !== 'tab-optimisation-policy-swap'
    )
      return null;

    const rule = get(swapPolicyRule(identity.ruleId));
    return loadLocationsByRule({
      layoutId,
      rule,
      group: identity.group,
      filter: {
        planeId: [planeId],
        bayId: [bay],
      },
    });
  },
});
