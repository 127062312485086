import * as React from 'react';
const FlipAreaIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FlipAreaIcon"
    {...props}
  >
    <g opacity={0.7}>
      <path
        strokeWidth={0}
        d="M17.49,103.56h108.51l-11.12,11.21,11.12,11.24H49.27c-4.61,0-7.87-.4-9.79-1.19,0,0-8.97-5.57-22-21.25Z"
      />
    </g>
    <path
      strokeWidth={0}
      d="M40.01,124.81c1.35,1.59-4.51-2.83-7.76-6.08l-11.96-12.07c-2.32-2.35-3.87-4.3-4.64-5.85-.76-1.56-1.15-3.53-1.15-5.94v-62.59H2L25.63,2l23.63,30.28h-12.51l.18,84.96s.02,3.97,3.06,7.57Z"
    />
    <g opacity={0.75}>
      <polygon
        strokeWidth={0}
        points="80.78 83.55 77.21 83.55 80.78 87.63 80.78 83.55"
      />
      <polygon
        strokeWidth={0}
        points="66.39 83.55 59.17 83.55 74.05 100.58 80.78 100.58 80.78 100.02 66.39 83.55"
      />
      <polygon
        strokeWidth={0}
        points="75.41 83.55 68.19 83.55 80.78 97.96 80.78 89.7 75.41 83.55"
      />
      <polyline
        strokeWidth={0}
        points="56.01 100.58 63.23 100.58 48.34 83.55 41.12 83.55"
      />
      <polygon
        strokeWidth={0}
        points="57.36 83.55 50.15 83.55 65.03 100.58 72.25 100.58 57.36 83.55"
      />
      <polygon
        strokeWidth={0}
        points="40.91 85.37 40.91 93.63 46.99 100.58 54.21 100.58 40.91 85.37"
      />
      <polygon
        strokeWidth={0}
        points="40.91 100.58 45.18 100.58 40.91 95.69 40.91 100.58"
      />
    </g>
    <path strokeWidth={0} d="M40.91,37.2v45.32h39.87v-45.32h-39.87Z" />
    <path strokeWidth={0} d="M82.88,69.68v30.9h37.09v-30.9h-37.09Z" />
    <path
      strokeWidth={0}
      d="M82.88,37.06v30.9h37.09v-30.9h-37.09Z"
      opacity={0.75}
    />
  </svg>
);
export default FlipAreaIcon;
