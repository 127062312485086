import { FetchResult } from '@apollo/client';
import {
  LoadAppBillingPurchaseDocument,
  LoadAppBillingPurchaseQuery,
  LoadAppBillingPurchaseQueryVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { billingClient } from '../../GraphQLClient';
import {
  appBillingPurchase,
  appBillingPurchaseError,
  appBillingPurchaseLoadStatus,
} from '../store/appBilling.state';

function useLoadPurchase() {
  const { t } = useTranslation('app');

  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    set(appBillingPurchase, null);
    set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (purchaseId: string) => {
        let purchaseResponse: FetchResult<
          LoadAppBillingPurchaseQuery,
          Record<string, any>,
          Record<string, any>
        >;

        function handleError(message, details?) {
          console.error(message, details);
          set(appBillingPurchaseError, message);
          set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        try {
          purchaseResponse = await billingClient.mutate<
            LoadAppBillingPurchaseQuery,
            LoadAppBillingPurchaseQueryVariables
          >({
            mutation: LoadAppBillingPurchaseDocument,
            variables: {
              id: purchaseId,
            },
          });

          if (purchaseResponse.errors) {
            handleError(
              t`Can't load purchases`,
              purchaseResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }
        } catch (ex) {
          handleError(t`Can't load purchases`, ex.message);
          return;
        }

        set(appBillingPurchase, purchaseResponse.data.appBillingPurchase);
        set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (purchaseId: string) => {
    await cleanupState();
    await loadCallback(purchaseId);
  };
}

export default useLoadPurchase;
