import * as React from 'react';
const CursorTransformRoundIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorTransformRoundIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M125,64c0-5.39-3.88-9.87-9-10.81V12h-41.19c-.94-5.12-5.42-9-10.81-9s-9.87,3.88-10.81,9H12v41.19c-5.12.94-9,5.42-9,10.81s3.88,9.87,9,10.81v41.19h41.19c.94,5.12,5.42,9,10.81,9s9.87-3.88,10.81-9h41.19v-41.19c5.12-.94,9-5.42,9-10.81ZM74.81,112c-.94-5.12-5.42-9-10.81-9s-9.87,3.88-10.81,9H16v-37.19c5.12-.94,9-5.42,9-10.81s-3.88-9.87-9-10.81V16h37.19c.94,5.12,5.42,9,10.81,9s9.87-3.88,10.81-9h37.19v37.19c-5.12.94-9,5.42-9,10.81s3.88,9.87,9,10.81v37.19h-37.19Z"
    />
  </svg>
);
export default CursorTransformRoundIcon;
