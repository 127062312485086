import * as React from 'react';
const Mhe3AWalkieStalkerManualIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe3AWalkieStalkerManualIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M119.78,114.93h-63.82v-46.87c0-.97-.32-1.8-.97-2.48-.65-.69-1.49-1.03-2.54-1.03h-19.5l-9.45,1.94-5.81-13.2-9.81-17.8c-.48-.81-1.17-1.09-2.06-.85-.97.4-1.37,1.09-1.21,2.06l.12.36-.36.24c-.81.57-1.43,1.27-1.88,2.12-.44.85-.59,1.76-.42,2.72l.36,3.15c.16,1.61,1.01,2.75,2.54,3.39l5.81,2.06,2.79,4.12,3.27,12.84-3.88.85c-.89.24-1.59.69-2.12,1.33s-.83,1.41-.91,2.3v44.45c.08,1.37.57,2.5,1.45,3.39s2.02,1.37,3.39,1.45h.24c.08.89.4,1.63.97,2.24s1.31.91,2.24.91,1.7-.3,2.3-.91.91-1.35.91-2.24h86.11c.08.89.4,1.63.97,2.24s1.31.91,2.24.91,1.7-.3,2.3-.91.91-1.35.91-2.24h5.93c.65,0,1.21-.24,1.7-.73.48-.48.73-1.09.73-1.82h0v-1.45c0-.73-.24-1.31-.73-1.76-.48-.44-1.09-.71-1.82-.79h0ZM5.7,47.23c-.97-.4-1.49-1.09-1.57-2.06l-.36-3.15c-.08-1.29.4-2.34,1.45-3.15l1.82,6.06c.08.48.32.93.73,1.33l1.57,2.3-3.63-1.33h0ZM125.35,69.99H60.8v-23.37c0-.81-.28-1.49-.85-2.06s-1.29-.89-2.18-.97v30.28h61.64c.48,0,1.01-.08,1.57-.24l4.6-2.54c.4-.16.5-.4.3-.73-.2-.32-.38-.44-.54-.36h0ZM46.63,62.97h5.81c1.37,0,2.54.44,3.51,1.33V3.38h-15.26v6.06h5.81v53.53h.12Z"
    />
  </svg>
);
export default Mhe3AWalkieStalkerManualIcon;
