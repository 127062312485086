import { getWeeksInMonth } from '@internationalized/date';
import { useCalendarGrid } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { AriaCalendarGridProps } from 'react-aria';
import { CalendarCell } from './CalendarCell';

export type CalendarGridProps = AriaCalendarGridProps & {
  state: CalendarState | RangeCalendarState;
};

export const CalendarGrid: React.FC<CalendarGridProps> = ({
  state,
  ...props
}) => {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className={classNames('text-menu-text/50')}>
        <tr>
          {weekDays.map((day, index) => (
            <th className="text-center" key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={classNames('text-menu-text')}>
        {_.range(weeksInMonth).map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
