import {
  SpacerBuilderSettings,
  SpacerPositionOption,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { getIndexedTitle } from '../../../../common/utils';
import BaySideActionFeature, {
  BaySideActionFeatureProps,
} from '../../../../layout/features/BaySideActionFeature';
import {
  converterAreaConfiguration,
  converterEditableArea,
} from '../store/converter.area.state';
import { getLayerConfig } from '../store/converter.helper';
import {
  converterLayoutSelectedBay,
  converterLayoutShowLocations,
} from '../store/converter.layout.state';
import {
  converterAreaBuilder,
  converterNewSpacerType,
} from '../store/converter.state';

const ConverterBayActionLayer: React.FC = () => {
  const area = useRecoilValue(converterEditableArea);
  const bay = useRecoilValue(converterLayoutSelectedBay);
  const areasConfigResponse = useRecoilValueLoadable(
    converterAreaConfiguration,
  );
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );
  const showLocation = useRecoilValue(converterLayoutShowLocations);
  const spacerType = useRecoilValue(converterNewSpacerType);

  // showLocation ||
  if (!area || areasConfigResponse.state !== 'hasValue' || !bay) return null;

  const areasConfig = areasConfigResponse.contents;
  const areaConfig = areasConfig[area?.id];

  const spacersNames = new Set(
    _.map(areaBuilder.spacers?.[bay.id], s => s.title),
  );

  function addSpacer(pos: SpacerPositionOption) {
    const title = getIndexedTitle(spacersNames, `${bay.title}-A-`);
    const id = `${area.id}-${bay.id}-${title}`;
    const spacer: SpacerBuilderSettings = {
      id,
      title,
      bayId: bay.id,
      type: spacerType,
      position: pos,
      width: bay.details.width,
    };
    setAreaBuilder({
      ...areaBuilder,
      spacers: {
        ...areaBuilder.spacers,
        [bay.id]: [...(areaBuilder.spacers?.[bay.id] || []), spacer],
      },
    });
  }

  const points = bay.shape.coordinates[0];
  const leftSidePointX = points[0][0] + (points[1][0] - points[0][0]) / 2;
  const leftSidePointY = points[0][1] + (points[1][1] - points[0][1]) / 2;

  const rightSidePointX = points[2][0] + (points[3][0] - points[2][0]) / 2;
  const rightSidePointY = points[2][1] + (points[3][1] - points[2][1]) / 2;

  const leftActionConfig: BaySideActionFeatureProps = {
    x: leftSidePointX,
    y: leftSidePointY,
    maxIconWidth: bay.details?.depth ?? Number.MAX_SAFE_INTEGER,
    onClick: () => addSpacer(SpacerPositionOption.Left),
  };

  const rightActionConfig: BaySideActionFeatureProps = {
    x: rightSidePointX,
    y: rightSidePointY,
    maxIconWidth: bay.details?.depth ?? Number.MAX_SAFE_INTEGER,
    onClick: () => addSpacer(SpacerPositionOption.Right),
  };
  return (
    <Layer {...getLayerConfig(areaConfig, area)}>
      <BaySideActionFeature {...leftActionConfig} />
      <BaySideActionFeature {...rightActionConfig} />
    </Layer>
  );
};

export default ConverterBayActionLayer;
