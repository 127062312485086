import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';
const StepFeaturesSetup = () => {
  const { t } = useTranslation('designer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Aisles, Locations and Bays`}
        subtitle={t`Importing Layout`}
        hasStepCounter={'3.'}
      />

      <HelpContainer id={'designer/03-aisles-locations-bays'} hasPadding />

      {/* <HelperMessage hasMargin hasPadding>
        <div>
          {t`This step configures aisles, bays, and locations in the warehouse areas you wish to analyse.`}
          <br />

          {t`Use individual steps to align the digital model of the warehouse to your physical one as close as possible.`}
        </div>
        <h4 className={`text-xl text-menu-active mt-6 mb-2`}>
          {t`This section consists of 4 steps:`}
        </h4>
        <div className="flex flex-col space-y-1">
          <StepListItemHelper stepCountId="1" title={t`Setup Aisles`}>
            {t`WareBee AI will find aisles automatically from your data. You can merge and re-order your aisles. Create new ones or define access and tunnel aisles to describe the navigation around the warehouse.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="2" title={t`Setup Obstacles`}>
            {t`You can add physical objects like columns, internal walls or other obstacles that are present in the warehouse and affect navigation.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="3" title={t`Setup Racks`}>
            {t`You can define default sizes of racking profiles, like rack column width and beam height.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="4" title={t`Setup Locations`}>
            {t`You can define gaps between the pallets, sides, levels. You can change the dimensions of the locations in your warehouse if the imported data is not accurate.`}
          </StepListItemHelper>
        </div>
      </HelperMessage> */}
    </PanelBody>
  );
};

export default StepFeaturesSetup;
