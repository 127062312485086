import { TFunction } from 'i18next';
import _ from 'lodash';

const getCurrencyList: (
  t: TFunction<'currency'>,
) => CurrencyDefinition[] = t => [
  {
    name: t('US Dollar', { ns: 'currency' }),
    code: 'USD',
  },
  {
    name: t('British Pound Sterling', { ns: 'currency' }),
    code: 'GBP',
  },
  {
    name: t('Euro', { ns: 'currency' }),
    code: 'EUR',
  },
  {
    name: t('United Arab Emirates Dirham', { ns: 'currency' }),
    code: 'AED',
  },
  {
    name: t('Afghan Afghani', { ns: 'currency' }),
    code: 'AFN',
  },
  {
    name: t('Albanian Lek', { ns: 'currency' }),
    code: 'ALL',
  },
  {
    name: t('Armenian Dram', { ns: 'currency' }),
    code: 'AMD',
  },
  {
    name: t('Argentine Peso', { ns: 'currency' }),
    code: 'ARS',
  },
  {
    name: t('Australian Dollar', { ns: 'currency' }),
    code: 'AUD',
  },
  {
    name: t('Azerbaijani Manat', { ns: 'currency' }),
    code: 'AZN',
  },
  {
    name: t('Bosnia-Herzegovina Convertible Mark', { ns: 'currency' }),
    code: 'BAM',
  },
  {
    name: t('Bangladeshi Taka', { ns: 'currency' }),
    code: 'BDT',
  },
  {
    name: t('Bulgarian Lev', { ns: 'currency' }),
    code: 'BGN',
  },
  {
    name: t('Bahraini Dinar', { ns: 'currency' }),
    code: 'BHD',
  },
  {
    name: t('Burundian Franc', { ns: 'currency' }),
    code: 'BIF',
  },
  {
    name: t('Brunei Dollar', { ns: 'currency' }),
    code: 'BND',
  },
  {
    name: t('Bolivian Boliviano', { ns: 'currency' }),
    code: 'BOB',
  },
  {
    name: t('Brazilian Real', { ns: 'currency' }),
    code: 'BRL',
  },
  {
    name: t('Botswanan Pula', { ns: 'currency' }),
    code: 'BWP',
  },
  {
    name: t('Belarusian Ruble', { ns: 'currency' }),
    code: 'BYN',
  },
  {
    name: t('Belize Dollar', { ns: 'currency' }),
    code: 'BZD',
  },
  {
    name: t('Canadian Dollar', { ns: 'currency' }),
    code: 'CAD',
  },
  {
    name: t('Congolese Franc', { ns: 'currency' }),
    code: 'CDF',
  },
  {
    name: t('Swiss Franc', { ns: 'currency' }),
    code: 'CHF',
  },
  {
    name: t('Chilean Peso', { ns: 'currency' }),
    code: 'CLP',
  },
  {
    name: t('Chinese Yuan', { ns: 'currency' }),
    code: 'CNY',
  },
  {
    name: t('Colombian Peso', { ns: 'currency' }),
    code: 'COP',
  },
  {
    name: t('Costa Rican Colón', { ns: 'currency' }),
    code: 'CRC',
  },
  {
    name: t('Cape Verdean Escudo', { ns: 'currency' }),
    code: 'CVE',
  },
  {
    name: t('Czech Republic Koruna', { ns: 'currency' }),
    code: 'CZK',
  },
  {
    name: t('Djiboutian Franc', { ns: 'currency' }),
    code: 'DJF',
  },
  {
    name: t('Danish Krone', { ns: 'currency' }),
    code: 'DKK',
  },
  {
    name: t('Dominican Peso', { ns: 'currency' }),
    code: 'DOP',
  },
  {
    name: t('Algerian Dinar', { ns: 'currency' }),
    code: 'DZD',
  },
  {
    name: t('Estonian Kroon', { ns: 'currency' }),
    code: 'EEK',
  },
  {
    name: t('Egyptian Pound', { ns: 'currency' }),
    code: 'EGP',
  },
  {
    name: t('Eritrean Nakfa', { ns: 'currency' }),
    code: 'ERN',
  },
  {
    name: t('Ethiopian Birr', { ns: 'currency' }),
    code: 'ETB',
  },
  {
    name: t('Georgian Lari', { ns: 'currency' }),
    code: 'GEL',
  },
  {
    name: t('Ghanaian Cedi', { ns: 'currency' }),
    code: 'GHS',
  },
  {
    name: t('Guinean Franc', { ns: 'currency' }),
    code: 'GNF',
  },
  {
    name: t('Guatemalan Quetzal', { ns: 'currency' }),
    code: 'GTQ',
  },
  {
    name: t('Hong Kong Dollar', { ns: 'currency' }),
    code: 'HKD',
  },
  {
    name: t('Honduran Lempira', { ns: 'currency' }),
    code: 'HNL',
  },
  {
    name: t('Croatian Kuna', { ns: 'currency' }),
    code: 'HRK',
  },
  {
    name: t('Hungarian Forint', { ns: 'currency' }),
    code: 'HUF',
  },
  {
    name: t('Indonesian Rupiah', { ns: 'currency' }),
    code: 'IDR',
  },
  {
    name: t('Israeli New Sheqel', { ns: 'currency' }),
    code: 'ILS',
  },
  {
    name: t('Indian Rupee', { ns: 'currency' }),
    code: 'INR',
  },
  {
    name: t('Iraqi Dinar', { ns: 'currency' }),
    code: 'IQD',
  },
  {
    name: t('Iranian Rial', { ns: 'currency' }),
    code: 'IRR',
  },
  {
    name: t('Icelandic Króna', { ns: 'currency' }),
    code: 'ISK',
  },
  {
    name: t('Jamaican Dollar', { ns: 'currency' }),
    code: 'JMD',
  },
  {
    name: t('Jordanian Dinar', { ns: 'currency' }),
    code: 'JOD',
  },
  {
    name: t('Japanese Yen', { ns: 'currency' }),
    code: 'JPY',
  },
  {
    name: t('Kenyan Shilling', { ns: 'currency' }),
    code: 'KES',
  },
  {
    name: t('Cambodian Riel', { ns: 'currency' }),
    code: 'KHR',
  },
  {
    name: t('Comorian Franc', { ns: 'currency' }),
    code: 'KMF',
  },
  {
    name: t('South Korean Won', { ns: 'currency' }),
    code: 'KRW',
  },
  {
    name: t('Kuwaiti Dinar', { ns: 'currency' }),
    code: 'KWD',
  },
  {
    name: t('Kazakhstani Tenge', { ns: 'currency' }),
    code: 'KZT',
  },
  {
    name: t('Lebanese Pound', { ns: 'currency' }),
    code: 'LBP',
  },
  {
    name: t('Sri Lankan Rupee', { ns: 'currency' }),
    code: 'LKR',
  },
  {
    name: t('Lithuanian Litas', { ns: 'currency' }),
    code: 'LTL',
  },
  {
    name: t('Latvian Lats', { ns: 'currency' }),
    code: 'LVL',
  },
  {
    name: t('Libyan Dinar', { ns: 'currency' }),
    code: 'LYD',
  },
  {
    name: t('Moroccan Dirham', { ns: 'currency' }),
    code: 'MAD',
  },
  {
    name: t('Moldovan Leu', { ns: 'currency' }),
    code: 'MDL',
  },
  {
    name: t('Malagasy Ariary', { ns: 'currency' }),
    code: 'MGA',
  },
  {
    name: t('Macedonian Denar', { ns: 'currency' }),
    code: 'MKD',
  },
  {
    name: t('Myanma Kyat', { ns: 'currency' }),
    code: 'MMK',
  },
  {
    name: t('Macanese Pataca', { ns: 'currency' }),
    code: 'MOP',
  },
  {
    name: t('Mauritian Rupee', { ns: 'currency' }),
    code: 'MUR',
  },
  {
    name: t('Mexican Peso', { ns: 'currency' }),
    code: 'MXN',
  },
  {
    name: t('Malaysian Ringgit', { ns: 'currency' }),
    code: 'MYR',
  },
  {
    name: t('Mozambican Metical', { ns: 'currency' }),
    code: 'MZN',
  },
  {
    name: t('Namibian Dollar', { ns: 'currency' }),
    code: 'NAD',
  },
  {
    name: t('Nigerian Naira', { ns: 'currency' }),
    code: 'NGN',
  },
  {
    name: t('Nicaraguan Córdoba', { ns: 'currency' }),
    code: 'NIO',
  },
  {
    name: t('Norwegian Krone', { ns: 'currency' }),
    code: 'NOK',
  },
  {
    name: t('Nepalese Rupee', { ns: 'currency' }),
    code: 'NPR',
  },
  {
    name: t('New Zealand Dollar', { ns: 'currency' }),
    code: 'NZD',
  },
  {
    name: t('Omani Rial', { ns: 'currency' }),
    code: 'OMR',
  },
  {
    name: t('Panamanian Balboa', { ns: 'currency' }),
    code: 'PAB',
  },
  {
    name: t('Peruvian Nuevo Sol', { ns: 'currency' }),
    code: 'PEN',
  },
  {
    name: t('Philippine Peso', { ns: 'currency' }),
    code: 'PHP',
  },
  {
    name: t('Pakistani Rupee', { ns: 'currency' }),
    code: 'PKR',
  },
  {
    name: t('Polish Zloty', { ns: 'currency' }),
    code: 'PLN',
  },
  {
    name: t('Paraguayan Guarani', { ns: 'currency' }),
    code: 'PYG',
  },
  {
    name: t('Qatari Rial', { ns: 'currency' }),
    code: 'QAR',
  },
  {
    name: t('Romanian Leu', { ns: 'currency' }),
    code: 'RON',
  },
  {
    name: t('Serbian Dinar', { ns: 'currency' }),
    code: 'RSD',
  },
  {
    name: t('Russian Ruble', { ns: 'currency' }),
    code: 'RUB',
  },
  {
    name: t('Rwandan Franc', { ns: 'currency' }),
    code: 'RWF',
  },
  {
    name: t('Saudi Riyal', { ns: 'currency' }),
    code: 'SAR',
  },
  {
    name: t('Sudanese Pound', { ns: 'currency' }),
    code: 'SDG',
  },
  {
    name: t('Swedish Krona', { ns: 'currency' }),
    code: 'SEK',
  },
  {
    name: t('Singapore Dollar', { ns: 'currency' }),
    code: 'SGD',
  },
  {
    name: t('Somali Shilling', { ns: 'currency' }),
    code: 'SOS',
  },
  {
    name: t('Syrian Pound', { ns: 'currency' }),
    code: 'SYP',
  },
  {
    name: t('Thai Baht', { ns: 'currency' }),
    code: 'THB',
  },
  {
    name: t('Tunisian Dinar', { ns: 'currency' }),
    code: 'TND',
  },
  {
    name: t('Tongan Paʻanga', { ns: 'currency' }),
    code: 'TOP',
  },
  {
    name: t('Turkish Lira', { ns: 'currency' }),
    code: 'TRY',
  },
  {
    name: t('Trinidad and Tobago Dollar', { ns: 'currency' }),
    code: 'TTD',
  },
  {
    name: t('New Taiwan Dollar', { ns: 'currency' }),
    code: 'TWD',
  },
  {
    name: t('Tanzanian Shilling', { ns: 'currency' }),
    code: 'TZS',
  },
  {
    name: t('Ukrainian Hryvnia', { ns: 'currency' }),
    code: 'UAH',
  },
  {
    name: t('Ugandan Shilling', { ns: 'currency' }),
    code: 'UGX',
  },
  {
    name: t('Uruguayan Peso', { ns: 'currency' }),
    code: 'UYU',
  },
  {
    name: t('Uzbekistan Som', { ns: 'currency' }),
    code: 'UZS',
  },
  {
    name: t('Venezuelan Bolívar', { ns: 'currency' }),
    code: 'VEF',
  },
  {
    name: t('Vietnamese Dong', { ns: 'currency' }),
    code: 'VND',
  },
  {
    name: t('CFA Franc BEAC', { ns: 'currency' }),
    code: 'XAF',
  },
  {
    name: t('CFA Franc BCEAO', { ns: 'currency' }),
    code: 'XOF',
  },
  {
    name: t('Yemeni Rial', { ns: 'currency' }),
    code: 'YER',
  },
  {
    name: t('South African Rand', { ns: 'currency' }),
    code: 'ZAR',
  },
  {
    name: t('Zambian Kwacha', { ns: 'currency' }),
    code: 'ZMK',
  },
  {
    name: t('Zimbabwean Dollar', { ns: 'currency' }),
    code: 'ZWL',
  },
];

export type CurrencyDefinition = {
  name: string;
  code: string;
};

const getCurrencyByCode = (t: TFunction<'currency'>) =>
  _.keyBy(getCurrencyList(t), currency => currency.code);

export const currencyByCode = _.memoize(getCurrencyByCode);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: DisplayNames
export const currencyNamesIntl = new Intl.DisplayNames(
  [Intl.NumberFormat().resolvedOptions().locale],
  { type: 'currency' },
);
