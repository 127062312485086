import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { analyticsAgent } from '../../AnalyticTracker';
import { WizardStepConfig } from '../../common/types';
import { sidebarStateAll } from '../../store/sidebar.state';
import { itemSetMenuItems } from '../store/itemSet.default';
import {
  itemSetSelectedFilterSetId,
  itemSetSelectedMenuItemId,
  itemSetSelectedRuleId,
  itemSetSelectedRuleResultsId,
} from '../store/itemSet.state';
import { ItemSetMenuItemId } from '../store/itemSet.types';

export type SelectItemSetMenuStepParams = {
  current?: boolean;
  stepId?: ItemSetMenuItemId;
  selectNext?: boolean;
};

function useSelectItemSetMenuStep() {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('dataset');

  const selectStepCallback = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: SelectItemSetMenuStepParams) => {
        const currentMenuItemId = await snapshot.getPromise(
          itemSetSelectedMenuItemId,
        );

        const steps = itemSetMenuItems(tApp);
        const sidebarStates = await snapshot.getPromise(sidebarStateAll);
        let stepConfig: WizardStepConfig<string>;

        if (params.current) {
          stepConfig = steps.find(s => s.id === currentMenuItemId);
        }
        if (params.stepId) {
          stepConfig = steps.find(s => s.id === params.stepId);
        }
        if (params.selectNext) {
          const currentStepIndex = _.findIndex(
            steps,
            step => step.id === currentMenuItemId,
          );
          if (currentStepIndex > -1 && currentStepIndex < steps.length) {
            stepConfig = steps[currentStepIndex + 1];
          }
        }
        if (!stepConfig) {
          throw new Error(t`Cannot change item set menu item`);
        }
        analyticsAgent?.track('Item Set Menu: ' + stepConfig.title);

        const allPatches = _.reduce(
          stepConfig.sidebar,
          (acc, statePatch, id) => ({
            ...acc,
            [id]: { ...(sidebarStates[id] || {}), ...statePatch },
          }),
          {},
        );

        set(sidebarStateAll, {
          ...sidebarStates,
          ...allPatches,
        });
        set(itemSetSelectedMenuItemId, stepConfig.id);

        // cleanup selection
        set(itemSetSelectedRuleId, null);
        set(itemSetSelectedFilterSetId, null);
        set(itemSetSelectedRuleResultsId, null);
      },
  );

  return async (params: SelectItemSetMenuStepParams) => {
    await selectStepCallback(params);
  };
}

export default useSelectItemSetMenuStep;
