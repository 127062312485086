import {
  LayoutLevelLocationFragment,
  ReassignTaskAction,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { allocationSummarySelectedMetric } from '../store/allocation/allocation.state';
import {
  analyzeJobDetailsSelectedRow,
  analyzeJobsTypeFilter,
} from '../store/analyze.state';
import { simulationHeatmapLocationByLevel } from '../store/simulation.heatmap.state';
import {
  simulationAllocateTabKey,
  simulationWizardSelectedStepId,
} from '../store/simulation.wizard.state';
import SimulationLocationHeatmapLayerBase from './SimulationLocationHeatmapLayerBase';

export type ReassignLocationLayerProps = {
  analyzeId: string;
};

const ReassignLocationLayer: React.FC<ReassignLocationLayerProps> = props => {
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const heatmapByLocLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByLevel(props.analyzeId),
  );
  const selectedJob = useRecoilValue(analyzeJobDetailsSelectedRow);
  const allocationSummaryMetric = useRecoilValue(
    allocationSummarySelectedMetric,
  );
  const jobTypeFilter = useRecoilValue(analyzeJobsTypeFilter);
  const allocationTab = useRecoilValue(simulationAllocateTabKey);

  const isAllocate = stepId === 'analyse';
  const isOptimise = stepId === 'optimise-reassign';

  let heatmapByLoc =
    heatmapByLocLoadable.state === 'hasValue'
      ? heatmapByLocLoadable.contents
      : null;

  if (_.isNil(heatmapByLoc)) return null;

  let filter: (location: LayoutLevelLocationFragment) => boolean = null;

  if (selectedJob) {
    // Reassign/allocate job is selected
    filter = location => {
      return (
        selectedJob.srcLocationId === location.locationId ||
        selectedJob.destLocationId === location.locationId
      );
    };
  }

  // // Reassign view specific filters
  // if (!selectedJob && isOptimise && !_.isNil(jobTypeFilter)) {
  //   filter = location => {
  //     const products = heatmapByLoc[location.locationId];
  //     return _.some(products, p =>
  //       _.some(p.reassignTasks, t => t.action === (jobTypeFilter as any)),
  //     );
  //   };
  // }

  // Allocate specific filters
  if (!selectedJob && isAllocate) {
    //
    if (allocationTab === 'tab-allocate-jobs') {
      filter = location => {
        const products = heatmapByLoc[location.locationId];
        return _.some(products, p => !_.isNil(p.reassignTasks));
      };
    }

    if (allocationTab === 'tab-allocate-summary') {
      filter = location => {
        switch (allocationSummaryMetric) {
          case 'all':
          case 'required':
            return true;
          case 'assigned':
            return _.some(heatmapByLoc[location.locationId], p =>
              _.isNil(p.reassignTasks),
            );
          case 'allocated':
            return _.some(heatmapByLoc[location.locationId], p =>
              _.some(p.reassignTasks, t => t.action === ReassignTaskAction.PUT),
            );

          case 'deallocated':
            return _.some(heatmapByLoc[location.locationId], p =>
              _.some(
                p.reassignTasks,
                t => t.action === ReassignTaskAction.TAKE,
              ),
            );
          case 'unallocated':
          case 'unpickable':
            return false;
          default:
            return true;
        }
      };
    }
  }

  return (
    <SimulationLocationHeatmapLayerBase
      heatmapByLoc={heatmapByLoc}
      filter={filter}
    />
  );
};

export default ReassignLocationLayer;
