import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import HeaderDataset from '../components/headers/HeaderDataset';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import OrderSetDataView from './OrderSetDataView';
import useLoadOrderSet from './hooks/useLoadOrderSet';
import OrderSetMainSidebar from './sidebar/OrderSetMainSidebar';
import OrderSetMenuSidebar from './sidebar/OrderSetMenuSidebar';
import {
  orderSetDocument,
  orderSetDocumentLoadStatus,
} from './store/orderSet.state';

export type OrderSetViewParams = {
  orderSetId: string;
};

const OrderSetView: React.FC = () => {
  const { t } = useTranslation('dataset');
  const { orderSetId } = useParams<OrderSetViewParams>();
  const documentLoadStatus = useRecoilValue(orderSetDocumentLoadStatus);
  const documentOrderSet = useRecoilValue(orderSetDocument);

  const loadDocument = useLoadOrderSet();

  useEffect(() => {
    loadDocument(orderSetId);
  }, [orderSetId]);

  return (
    <>
      <HeaderDataset dataType="orders" document={documentOrderSet} />
      <ContainerMain>
        {documentLoadStatus === AsyncLoadStatus.Ok ? (
          <>
            <ContainerSidebar side="left">
              <OrderSetMenuSidebar />
              <OrderSetMainSidebar />
            </ContainerSidebar>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton
                  exception={error}
                />
              )}
            >
              <Suspense
                fallback={
                  <Container hasOverflowY flex1 col>
                    <PanelHeader isLoading title={t`Loading Order set...`} />
                    <LoadingIndicator
                      className={classNames(
                        'bg-map-area-striped',
                        'border-app-background border-8',
                      )}
                      selfCenter
                      message={t`Loading Order set`}
                    />
                  </Container>
                }
              >
                <OrderSetDataView />
              </Suspense>
            </ErrorBoundary>
          </>
        ) : (
          <LoadingIndicator selfCenter message={t`Loading Order set...`} />
        )}
      </ContainerMain>
    </>
  );
};

export default OrderSetView;
