import { useApolloClient } from '@apollo/client';
import { FetchResult } from '@apollo/client/core';
import {
  AnalyzeSimulationDocument,
  AnalyzeSimulationMutation,
  AnalyzeSimulationMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { simulationAnalyzeResultAtom } from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';

function useRunAnalyze() {
  const client = useApolloClient();

  const runAnalyze = useRecoilCallback(
    ({ snapshot, set }) =>
      async (simulationId: string) => {
        let response: FetchResult<AnalyzeSimulationMutation>;
        try {
          response = await client.mutate<
            AnalyzeSimulationMutation,
            AnalyzeSimulationMutationVariables
          >({
            mutation: AnalyzeSimulationDocument,
            variables: {
              input: {
                simulationId,
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Can not run analyse',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: 'Can not run analyse',
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          return;
        }

        const analyzeResult = response.data.analyzeSimulation.analyzeResult;
        set(simulationAnalyzeResultAtom, analyzeResult);
      },
  );

  return async (simulationId: string) => {
    await runAnalyze(simulationId);
  };
}

export default useRunAnalyze;
