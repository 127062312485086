import { BillableOperationType } from '@warebee/shared/app-billing-utils';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DisplayPreset = 'short' | 'full';

const descriptionItems = (t: TFunction<'app'>) => [
  {
    description: t`Analyse Subscription — Analyse your warehouse, ABC Item Analytics, Industry Standards KPIs, Resources costs and Utilisation including CO2.`,
    category: ['SUBSCRIPTION'],
    services: ['ANALYZE'],
    presets: ['full'],
  },
  {
    description: t`Analyse Subscription — Analyse your warehouse, ABC Item Analytics, Industry Standards KPIs, Resources costs and Utilisation including CO2.`,
    category: ['SUBSCRIPTION'],
    services: ['ANALYZE'],
    presets: ['short'],
  },
  {
    description: t`Optimise Subscription — Continuously optimize your warehouse with AI, make better decisions with planning while driving your costs down.`,
    category: ['SUBSCRIPTION'],
    services: ['OPTIMIZE'],
    presets: ['full'],
  },
  {
    description: t`Optimise Subscription — Continuously optimize your warehouse with AI, make better decisions with planning while driving your costs down.`,
    category: ['SUBSCRIPTION'],
    services: ['OPTIMIZE'],
    presets: ['short'],
  },
  {
    description: t`Analyse Bundle — Analyse your warehouse, ABC Item Analytics, Industry Standards KPIs, Resources costs and Utilisation including CO2.`,
    category: ['BUNDLE'],
    services: ['ANALYZE'],
    presets: ['full'],
  },
  {
    description: t`Analyse your warehouse bundle with 10 Simulations`,
    category: ['BUNDLE'],
    services: ['ANALYZE'],
    presets: ['short'],
  },
  {
    description: t`Optimise Bundle — You have 10 simulations to Optimize your warehouse with AI`,
    category: ['BUNDLE'],
    services: ['OPTIMIZE'],
    presets: ['full'],
  },
  {
    description: t`Optimise your warehouse, 10 simulations to reduce Resources costs and Utilisation including CO2.`,
    category: ['BUNDLE'],
    services: ['OPTIMIZE'],
    presets: ['short'],
  },
];

export type AppBillingPriceCardDescriptionProps = {
  description?: string;
  category?: string;
  operationType: BillableOperationType;
  displayPreset: DisplayPreset;
};

const AppBillingPriceCardDescription: React.FC<
  AppBillingPriceCardDescriptionProps
> = props => {
  const { t } = useTranslation('app');

  const descriptions = descriptionItems(t).filter(
    di =>
      di.services.includes(props.operationType) &&
      di.presets.includes(props.displayPreset) &&
      di.category.includes(props.category),
  );
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="AppBillingPriceCardDescription"
      className={`mt-2 flex flex-col items-start pt-4`}
    >
      {descriptions.map(item => (
        <div
          data-component={`AppBillingPriceCardDescription-${id}`}
          key={id}
          className="py-2 ltr:pl-2 rtl:pr-2"
        >
          {item.description && (
            <div className="text-xl xl:text-3xl">{item.description}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AppBillingPriceCardDescription;
