import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import InboxZero from '../../../../components/InboxZero';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import HelpContainer from '../../../../helpContext/HelpContainer';
import ConverterAisleOrderPanel from '../panels/ConverterAisleOrderPanel';
import { converterEditableArea } from '../store/converter.area.state';
import { converterAreaBuilder } from '../store/converter.state';

const StepOrderAisles: React.FC = () => {
  const { t } = useTranslation('designer');

  const area = useRecoilValue(converterEditableArea);
  const areaBuilder = useRecoilValue(converterAreaBuilder(area?.id));

  return (
    <PanelBody>
      <ScreenTitle isSticky subtitle={t`Setup Aisle`} title={t`Aisle Order`} />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerAislesOrder}
          question={t`Are the aisles shown in the correct order on the map?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/03-setup-aisles-order'} hasPadding />

          {/* <HelperContainer
            collapsible
            id={'helper-layout-aisle-order'}
            hideMessage={t`Hide helper`}
            showMessage={t`show more`}
            showIcon={Icon.ArrowRight}
            hideIcon={Icon.MenuDotsHorizontal}
            message={t`You can change the aisle order by choosing one of the four options below.`}
          >
            <HelperMessage isTip hasBefore>
              {t`It's an interactive warehouse map! You can try different settings and immediately see the result.`}
            </HelperMessage>
            <StepListItemHelper stepCountId="1" title={t`Default Order`}>
              {t`Aisles are ordered by ABC ascending.`}
            </StepListItemHelper>
            <StepListItemHelper stepCountId="2" title={t`Reverse Order`}>
              {t`Aisles are ordered by ABC descending.`}
            </StepListItemHelper>
            <StepListItemHelper stepCountId="3" title={t`Shifted Order`}>
              {t`Shift number of aisles to the other side. Number of aisles is entered in "SHIFT AISLE BY".`}
            </StepListItemHelper>
            <StepListItemHelper stepCountId="4" title={t`Custom Order`}>
              {t`Change the order of the aisles as you like. Drag and drop the aisle to its position or use arrows to reorder.`}
            </StepListItemHelper>
          </HelperContainer> */}
        </InputAnswerGroup>

        <TitleSection className="mt-4" titleView title={t`Aisle Order Setup`} />
        {area && areaBuilder ? (
          <ConverterAisleOrderPanel area={area} />
        ) : (
          <InboxZero
            message={t`No Area Selected`}
            message_helper={t`Double-Click Area to edit Aisles, Bays or Locations`}
          />
        )}
      </InputQA>
    </PanelBody>
  );
};

export default StepOrderAisles;
