import {
  AssignmentPolicyFragment,
  AssignmentPolicyInput,
  AssignmentPolicyRuleFragment,
  AssignmentPolicyRuleInput,
  AssignmentPolicyRuleMatchType,
  LayoutLocationFilter,
  LayoutLocationWithMatchingFiltersFragment,
  LoadLocationsByFilterDocument,
  LoadLocationsByFilterQuery,
  LoadLocationsByFilterQueryVariables,
  LocationFilterUnionInput,
  SimulationItemFilterUnionInput,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { HTMLAttributes } from 'react';
import { secureClient } from '../../GraphQLClient';
import { cloneWithoutTypename } from '../../common/utils';
import * as Icon from '../../components/icons';
import { getPolicyMatchInput } from '../../policyFilters/policyFilter.helper';
import { AP_FALLBACK_RULE_ID } from './assignmentPolicy.default';
import { AssignmentPolicySelectedIdentity } from './assignmentPolicy.types';

const EmptyLocationFilterUnionInput: LocationFilterUnionInput[] = [
  {
    anyOf: [
      {
        allOf: [],
      },
    ],
  },
];

const EmptyItemFilterUnionInput: SimulationItemFilterUnionInput[] = [
  {
    anyOf: [
      {
        allOf: [],
      },
    ],
  },
];

export function getAssignmentPolicyRuleInput(
  rule: AssignmentPolicyRuleFragment,
): AssignmentPolicyRuleInput {
  if (!rule) return null;

  const productsMatch = getPolicyMatchInput(rule.productsMatch);
  const locationsMatch = getPolicyMatchInput(rule.locationsMatch);
  return {
    id: rule.id,
    title: rule.title,
    type: rule.type,
    productsMatch,
    locationsMatch,
  };
}

export function getAssignmentPolicyInput(
  policy: AssignmentPolicyFragment,
): AssignmentPolicyInput {
  const fallback = _.filter(
    policy.fallbackLocationsMatch?.anyOf,
    intersection => intersection.allOf.length > 0,
  );

  return {
    rules: policy.rules.map(getAssignmentPolicyRuleInput),

    fallbackLocationsMatch: _.isEmpty(fallback)
      ? null
      : cloneWithoutTypename({ anyOf: fallback }),
    fallbackItemsAllowAnyLocations: policy.fallbackItemsAllowAnyLocations,
    fallbackLocationsAllowAnyItems: policy.fallbackLocationsAllowAnyItems,
  };
}

export async function loadLocationsByPolicy(params: {
  layoutId: string;
  policy: AssignmentPolicyFragment;
  filter: LayoutLocationFilter;
}): Promise<Record<string, LayoutLocationWithMatchingFiltersFragment>> {
  const { policy } = params;

  // const ruleIndex = _.findIndex(policy.rules, rule => rule.id === ruleId);

  // const rules = _.map(policy.rules, getAssignmentPolicyRuleInput);

  const filters = [
    ..._.map(policy.rules, r => getPolicyMatchInput(r.locationsMatch)),
    //getPolicyMatchInput(policy.fallbackLocationsMatch),
  ];
  // const rulesToExclude =
  //   ruleId === AP_FALLBACK_RULE_ID ? rules : _.take(rules, ruleIndex);

  // const excludeMatching = _.flatMap(
  //   rulesToExclude,
  //   rule => rule.locationsMatch,
  // );

  // const includeMatching: LocationFilterUnionFragment =
  //   ruleId === AP_FALLBACK_RULE_ID
  //     ? getPolicyMatchInput(policy.fallbackLocationsMatch)
  //     : rules[ruleIndex]?.locationsMatch;

  // if (params.detailsType === RuleDetailsType.Empty) {
  //   // To find Empty locations move  all to excludeMatch
  //   includeMatching && excludeMatching.push(includeMatching);
  //   includeMatching = null;
  // }

  const response = await secureClient.query<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >({
    query: LoadLocationsByFilterDocument,
    variables: {
      filter: params.filter,
      layoutId: params.layoutId,
      input: {
        filters,
      },
    },
  });
  return (
    _.keyBy(
      response.data?.layout?.locationsWithMatchingFilters.content,
      c => c.locationId,
    ) ?? {}
  );
  // const result = response.data?.applyLocationFilters;

  // const rulesIds = _.map(policy.rules, r => r.id);
  // return _.reduce(
  //   result.content,
  //   (acc, row) => {
  //     const locationId = row.locationId;
  //     const rules = _.map(
  //       row.allMatchingFilters,
  //       i => rulesIds[i] ?? AP_FALLBACK_RULE_ID,
  //     );
  //     return {
  //       ...acc,
  //       [locationId]: rules,
  //     };
  //   },
  //   {} as Record<string, string[]>,
  // );
}

export function getAssignmentPolicyRuleTypes(t: TFunction<'simulation'>): {
  id: AssignmentPolicyRuleMatchType;
  title: string;
  icon?: React.FC<HTMLAttributes<Element>>;
}[] {
  return [
    {
      id: AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION,
      title: t(`Items in Locations`, { ns: 'simulation' }),
      icon: Icon.LocationsOccupied,
    },
    {
      id: AssignmentPolicyRuleMatchType.LOCATION_MUST_CONTAIN_ITEM,
      title: t(`Locations with Items`, { ns: 'simulation' }),
      icon: Icon.LocationsEmpty,
    },
    {
      id: AssignmentPolicyRuleMatchType.STRICT_MATCH,
      title: t(`Strict Assignment`, {
        ns: 'simulation',
      }),
      icon: Icon.LocationsOccupiedAll,
    },
  ];
}

export function getItemFilterMatchings(
  policy: AssignmentPolicyFragment,
  selectedDetails: AssignmentPolicySelectedIdentity,
) {
  const ruleIndex = _.findIndex(
    policy.rules,
    rule => rule.id === selectedDetails.ruleId,
  );
  const rulesToExclude = _.map(
    selectedDetails.ruleId === AP_FALLBACK_RULE_ID ? policy.rules : [],
    getAssignmentPolicyRuleInput,
  );

  let excludeMatching = _(rulesToExclude)
    .flatMap(rule => rule.productsMatch)
    .value();

  const includeMatching =
    selectedDetails.ruleId === AP_FALLBACK_RULE_ID
      ? null
      : getAssignmentPolicyRuleInput(policy.rules[ruleIndex])?.productsMatch;

  const hasExcludingEmpty = _.some(
    excludeMatching,
    match => _.isNil(match) || _.isEmpty(match.anyOf),
  );
  if (hasExcludingEmpty) {
    excludeMatching = EmptyItemFilterUnionInput;
  }

  return {
    includeMatching: _.isEmpty(includeMatching) ? null : includeMatching,
    excludeMatching: _.isEmpty(excludeMatching) ? null : excludeMatching,
  };
}

export function getLocationFilterMatchings(
  policy: AssignmentPolicyFragment,
  selectedDetails: AssignmentPolicySelectedIdentity,
) {
  const ruleIndex = _.findIndex(
    policy.rules,
    rule => rule.id === selectedDetails.ruleId,
  );
  const rulesToExclude = _.map(
    selectedDetails.ruleId === AP_FALLBACK_RULE_ID ? policy.rules : [],
    getAssignmentPolicyRuleInput,
  );

  let excludeMatching = _(rulesToExclude)
    .flatMap(rule => rule.locationsMatch)
    .value();

  const includeMatching =
    selectedDetails.ruleId === AP_FALLBACK_RULE_ID
      ? null
      : getAssignmentPolicyRuleInput(policy.rules[ruleIndex])?.locationsMatch;

  const hasExcludingEmpty = _.some(
    excludeMatching,
    match => _.isNil(match) || _.isEmpty(match.anyOf),
  );
  if (hasExcludingEmpty) {
    excludeMatching = EmptyLocationFilterUnionInput;
  }

  return {
    includeMatching: _.isEmpty(includeMatching) ? null : includeMatching,
    excludeMatching: _.isEmpty(rulesToExclude) ? null : excludeMatching,
  };
}

export function getAssignmentComplianceModeOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: 'allow-selected',
      title: t(`Enforce (default)`, { ns: 'simulation' }),
      value: false,
      icon: Icon.PolicyAllocationRequirement,
    },
    {
      id: 'allow-any',
      title: t(`Ignore Complinace`, { ns: 'simulation' }),
      value: true,
      icon: Icon.PolicyAssignmentAgentFill,
    },
  ];
}
