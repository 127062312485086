import {
  PicklistEventWaypointFragment,
  WaypointType,
} from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React, { useState } from 'react';
import { Arrow, Circle, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';
import AnimatedArrow from './AnimatedArrow';
import { WaypointLabeled } from './features.types';

const routeColors = TwTheme.extend.colors.route;

const fallbackColors = {
  lineStart: routeColors.line_start, // Example fallback start color
  lineEnd: routeColors.line_end, // Example fallback end color
};

function getColorWithFallback(
  colorKey: keyof typeof routeColors,
  fallback: string,
): string {
  return routeColors[colorKey] ?? fallback;
}

export type RouteFeatureProps = {
  waypoints: WaypointLabeled[];
  selected?: string;
  showLabels?: boolean;
  setSelected?: (wp: PicklistEventWaypointFragment) => void;
  onMouseOver?: (wp: PicklistEventWaypointFragment) => void;
  onMouseOut?: (wp: PicklistEventWaypointFragment) => void;
  scale?: number;
  color?: string;
  animate?: boolean;
};

const tagConfig: Konva.TagConfig = {
  fill: routeColors.hover,
  offsetY: 4,
  //pointerDirection: 'down',
  //pointerWidth: 10,
  //pointerHeight: 10,
  //lineJoin: 'round',
  cornerRadius: 20,
};

const RouteFeature: React.FC<RouteFeatureProps> = props => {
  const { waypoints } = props;

  const [hoveredId, setHoveredId] = useState<string>(null);

  const visitedPoints: Record<string, number> = {};
  const scale = props.scale ?? 1;

  const waypointRadius = 36 * scale;
  const portalWaypointRadius = 10 * scale;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const routeConfig: Konva.ArrowConfig = {
    points: _.flatten(waypoints.map(wp => [wp.position.x, wp.position.y])),
    //stroke: props.color ?? routeColors.arrow,
    //fill: props.color ?? routeColors.arrow,
    strokeScaleEnabled: false,
    strokeWidth: 4,
    //perfectDrawEnabled: false,
    pointerWidth: 10 * scale,
    pointerLength: 50 * scale,
  };

  function getPointConfig(
    wp: PicklistEventWaypointFragment,
  ): Konva.CircleConfig {
    return {
      perfectDrawEnabled: false,
      radius:
        wp.type === WaypointType.PORTAL ? portalWaypointRadius : waypointRadius,
      fill:
        props.color ??
        (wp.id === hoveredId ? routeColors.hover : routeColors.waypoint),
      // (wp.id === props.selected
      //   ? routeColors.selected
      //   :
      //   ),
      x: wp.position.x,
      y: wp.position.y,
    };
  }
  const labelOffsetXBase = -waypointRadius;
  const labelOffsetYBase = waypointRadius * 0.9;
  const labelOffsetX = 65;
  const labelOffsetY = 0;

  const getPointLabelPos = (
    x: number,
    y: number,
    visitedPoints: Record<string, number>,
  ) => {
    const key = `${x}-${y}`;
    visitedPoints[key] = (visitedPoints[key] ?? -1) + 1;
    return {
      x: x + labelOffsetXBase + visitedPoints[key] * labelOffsetX,
      y: y + labelOffsetYBase,
    };
  };

  const textConfig: Konva.TextConfig = {
    fontFamily: TwTheme.extend.fontFamily.active[0],

    fill: routeColors.label,
  };

  return (
    <>
      {waypoints.map((wp, i) => {
        const prevPos = i > 0 ? waypoints[i - 1].position : { x: 0, y: 0 };
        const dx = prevPos.x - wp.position.x;
        const dy = prevPos.y - wp.position.y;
        const len = Math.sqrt(dx * dx + dy * dy);

        const points: [number, number, number, number] = [
          prevPos.x - (len ? (waypointRadius * dx) / len : 0),
          prevPos.y - (len ? (waypointRadius * dy) / len : 0),
          wp.position.x + (len ? (waypointRadius * dx) / len : 0),
          wp.position.y + (len ? (waypointRadius * dy) / len : 0),
        ];

        // Safely fetch theme colors or use fallbacks
        const gradientStartColor = getColorWithFallback(
          'line_start',
          fallbackColors.lineStart,
        );
        const gradientEndColor = getColorWithFallback(
          'line_end',
          fallbackColors.lineEnd,
        );

        const startColor = routeColors.line_start || '#95628a';
        const endColor = routeColors.line_end || '#ffffff';

        const gradient = ctx.createLinearGradient(...points);
        gradient.addColorStop(0.0, startColor);
        gradient.addColorStop(1.0, endColor);

        // gradient.addColorStop(0.0, routeColors.line_start);
        // gradient.addColorStop(1.0, routeColors.line_end);

        const labelPosition = getPointLabelPos(
          wp.position.x,
          wp.position.y,
          visitedPoints,
        );

        let fontSize: number;

        switch (wp.label?.toString()?.length) {
          case 1:
            fontSize = 60;
            break;
          case 2:
            fontSize = 50;
            break;
          case 3:
            fontSize = 40;
            break;
          default:
            fontSize = 35;
        }

        return (
          <React.Fragment key={`wp-${i}-${wp.id}`}>
            {i > 0 &&
              (props.animate ? (
                <AnimatedArrow points={points} scale={scale} />
              ) : (
                <Arrow
                  stroke={props.color ? props.color : (gradient as any)}
                  strokeWidth={26 * scale}
                  points={points}
                  lineCap="round"
                  pointerWidth={18 * scale}
                  pointerLength={36 * scale}
                />
              ))}
            <Circle
              {...getPointConfig(wp)}
              onTap={e =>
                props.setSelected &&
                props.setSelected(wp.id !== props.selected ? wp : null)
              }
              onClick={e =>
                props.setSelected &&
                props.setSelected(wp.id !== props.selected ? wp : null)
              }
              onMouseEnter={e => {
                e.cancelBubble = true;
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer';
                setHoveredId(wp.id);
                props.onMouseOver && props.onMouseOver(wp);
              }}
              onMouseLeave={e => {
                e.cancelBubble = true;
                const container = e.target.getStage().container();
                container.style.cursor = 'default';
                setHoveredId(null);
                props.onMouseOut && props.onMouseOut(wp);
              }}
            />
            {props.showLabels && !_.isEmpty(wp.label) && (
              <Text
                {...labelPosition}
                width={2 * waypointRadius}
                height={2 * waypointRadius}
                {...textConfig}
                align="center"
                verticalAlign="middle"
                scaleY={-1}
                text={wp.label}
                fontSize={
                  fontSize *
                  scale *
                  (2 / Math.max(2, wp.label?.toString()?.length ?? 0))
                }
                listening={false}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default React.memo(RouteFeature);
