const LinkSourceAreaLockIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkSourceAreaLockIcon"
    {...props}
  >
    <path d="M90.44,63.04c-5.73,0-10.28,4.65-10.28,10.28v6.33h20.46s.1,0,.1,0v-6.33c0-5.73-4.65-10.28-10.28-10.28Z" />
    <path d="M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM61.94,61.16h-7.86l-28.65-28.65.02,21.63-11.15-11.12V13.52h29.48l11.13,11.12h-21.61s28.65,28.64,28.65,28.64v7.89ZM113.68,110.98c0,2.67-2.18,4.84-4.84,4.84h-36.97c-2.67,0-4.84-2.17-4.84-4.84v-26.49c0-2.67,2.18-4.84,4.84-4.84h.4v-6.33c0-9.98,8.21-18.19,18.19-18.19,9.98,0,18.19,8.2,18.19,18.19v6.33h.1c2.67,0,4.75,2.08,4.94,4.74v26.59Z" />
  </svg>
);
export default LinkSourceAreaLockIcon;
