import { ImportPipelineConnectorType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { Container } from '../../components/layout/ContainerFlex';
import PipelineEditor from '../../importJobs/panels/editors/PipelineEditor';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import {
  connectorNewDatasetKey,
  connectorNewSFTPKey,
  createDefaultDatasetConnector,
  createDefaultSftpConnector,
} from '../store/importPipeline.helper';
import {
  importPipelineSelected,
  importPipelineSelectedConnectorId,
} from '../store/importPipelines.state';
import DatasetConnectorEditor from './editors/DatasetConnectorEditor';
import SFTPConnectorEditor from './editors/SFTPConnectorEditor';

const ImportPipelineEditPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const pipeline = useRecoilValue(importPipelineSelected);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const connectorId = useRecoilValue(importPipelineSelectedConnectorId);

  const connector = _.find(
    pipeline?.settings?.integrationSettings?.connectors,
    c => c.id === connectorId,
  );

  const isSftpConnector =
    connector?.type === ImportPipelineConnectorType.SFTP_PULL ||
    connectorId === connectorNewSFTPKey;

  const isDatasetConnector =
    connector?.type === ImportPipelineConnectorType.DATASET_CHANGED ||
    connectorId === connectorNewDatasetKey;

  return (
    <PanelBody className="h-full">
      <Container col hasOverflowY hasExtraPadding>
        {_.isNil(connectorId) && (
          <PipelineEditor
            pipeline={pipeline}
            isNew={_.isNil(pipeline)}
            onChange={() => null}
          />
        )}
        {isSftpConnector && (
          <SFTPConnectorEditor
            connector={connector ?? createDefaultSftpConnector()}
            isNew={connectorId === connectorNewSFTPKey}
            onChange={() => null}
          />
        )}
        {isDatasetConnector && (
          <DatasetConnectorEditor
            connector={connector ?? createDefaultDatasetConnector()}
            isNew={connectorId === connectorNewDatasetKey}
            onChange={() => null}
          />
        )}
      </Container>
    </PanelBody>
  );
};

export default ImportPipelineEditPanel;
