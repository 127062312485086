import {
  ArrayNotEmpty,
  IsNotEmpty,
  ValidateNested,
} from '@warego/class-validator';
import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { ImportItemSetLineInput } from './import-item-set-line.input';

@InputType()
export class ImportItemSetLinesInput {
  @Field(() => ID)
  @IsNotEmpty()
  itemSetId: string;

  @Field(() => [ImportItemSetLineInput])
  @ValidateNested()
  @ArrayNotEmpty()
  lines: ImportItemSetLineInput[];
}
