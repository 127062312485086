import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import * as Icon from '../icons';

export type AgentStatItemProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  counter?: string | number;
  subtitle?: string | number;
  isDisabled?: boolean;
  isActive?: boolean;
  isActionable?: boolean;
  onClick?: () => void;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconClassName?: string;
  cardClassName?: string;
};

export const AgentStatItem: React.FC<AgentStatItemProps> = props => {
  const IconItem = props.icon;

  return (
    <div
      data-component="AgentStatItem"
      data-label={`AgentStatItem-${props.title}`}
      aria-label={`AgentStatItem-${props.title}`}
      className={classNames([
        'relative',
        'group',
        'flex flex-1',
        'isolate',
        {
          'cursor-pointer': props.isActionable,
          'bg-app-stat text-menu-text': !props.isActive && props.isActionable,
          'bg-menu-active hover:bg-menu-active/70 text-menu-active-text':
            props.isActive && props.isActionable,
          'hover:bg-menu-active/70 hover:text-menu-active-text':
            !props.isDisabled && props.isActionable,
          'bg-opacity-50': props.isDisabled,
          'bg-app-stat/80': !props.isActionable,
        },
        props.className,
      ])}
      onClick={e => {
        // e.stopPropagation();
        props.onClick && props.onClick();
      }}
    >
      <div
        className={classNames([
          'flex flex-col w-full',
          {
            'items-start': props.icon,
            'items-center': !props.icon,
            'p-2': props.title,
          },
          props.cardClassName,
        ])}
      >
        {props.icon && (
          <IconItem
            className={classNames('fill-current flex-1', props.iconClassName)}
          />
        )}

        {props.title && (
          <div
            className={classNames('flex items-center', 'px-0.5', 'font-bold')}
          >
            {props.title}
          </div>
        )}
        {props.subtitle && (
          <div
            className={classNames('flex items-center', 'px-0.5', 'opacity-75')}
          >
            {props.subtitle}
          </div>
        )}
        {props.children}
      </div>
      {props.counter && (
        <div
          className={classNames(
            'text-7xl',
            'opacity-25',
            'flex',
            'absolute bottom-2',
            'ltr:items-end ltr:content-end ltr:right-2',
            'rtl:items-start rtl:content-start rtl:left-2',
          )}
        >
          <span>
            <Icon.Close
              className={classNames('fill-current', 'w-10 h-10', 'pb-4')}
            />
          </span>
          <span>{props.counter}</span>
        </div>
      )}
    </div>
  );
};
