import { useApolloClient } from '@apollo/client';
import {
    LoadAnalyzedPickListDetailsDocument,
    LoadAnalyzedPickListDetailsQuery,
    LoadAnalyzedPickListDetailsQueryVariables
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
    simulationAnalyzePicklistEvents,
    simulationAnalyzePicklistEventsLoadStatus
} from '../store/simulation.state';

export type LoadAnalyzedPickListDetailsParams = {
  analyzeId: string;
  orderId: string;
  picklistId: string;
};

function useLoadAnalyzedPickListDetails() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load picklist events`;
  const [observables] = useState<Record<string, ZenObservable.Subscription>>(
    {},
  );

  const initLoading = useRecoilCallback(
    ({ set, reset }) =>
      async (picklistId: string) => {
        set(simulationAnalyzePicklistEvents(picklistId), null);
        set(
          simulationAnalyzePicklistEventsLoadStatus(picklistId),
          AsyncLoadStatus.Loading,
        );
      },
  );

  const cancelLoading = useRecoilCallback(
    ({ set, reset }) =>
      async (picklistId: string) => {
        observables[picklistId]?.unsubscribe();
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAnalyzedPickListDetailsParams) => {
        const query = client.watchQuery<
          LoadAnalyzedPickListDetailsQuery,
          LoadAnalyzedPickListDetailsQueryVariables
        >({
          query: LoadAnalyzedPickListDetailsDocument,
          variables: params,
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationAnalyzePicklistEvents(params.picklistId), null);
          set(
            simulationAnalyzePicklistEventsLoadStatus(params.picklistId),
            AsyncLoadStatus.Error,
          );
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const picklistDetails =
              data.analyzeResult.orders.content?.[0]?.picklists?.[0];

            set(
              simulationAnalyzePicklistEvents(params.picklistId),
              picklistDetails,
            );
            set(
              simulationAnalyzePicklistEventsLoadStatus(params.picklistId),
              AsyncLoadStatus.Ok,
            );
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        observables[params.picklistId] = queryObservable;
      },
  );

  async function call(params: LoadAnalyzedPickListDetailsParams) {
    await initLoading(params.picklistId);
    await loadCallback(params);
  }

  async function cancel(params: LoadAnalyzedPickListDetailsParams) {
    await cancelLoading(params.picklistId);
  }

  return [call, cancel] as const;
}

export default useLoadAnalyzedPickListDetails;
