import {
  AssignmentDiffItemFragment,
  LoadOptimizationDiffQuery,
  LoadOptimizationDiffQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  getCategoryString,
  ProductCategoryDescriptorExport,
} from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ExportConverter, ColumnConfigBase, ExportJobParams } from './types';

export type AssignmentDiffExportJobParams = ExportJobParams<
  LoadOptimizationDiffQueryVariables,
  AssignmentDiffConverterConfig
>;

export const assignmentDiffExportJobParams: AssignmentDiffExportJobParams = {
  converterId: 'ASSIGNMENT_DIFF',
  query: null,
  config: null,
  variables: null,
  filename: 're-assign.csv',
};

export type AssignmentDiffDataRow = {
  data: AssignmentDiffItemFragment;
  consignee: string;
  category: number;
  categorySting: string;
  sku: string;
  srcLocations: string;
  spacer?: string;
  destLocations: string;
  hasSortByMultipleColumns?: boolean;
};

export type AssignmentDiffConverterConfig = {
  columnsConfig: ColumnConfigBase<AssignmentDiffDataRow>[];
  dictionaries: {
    categories: ProductCategoryDescriptorExport[];
  };
};

export function getAssignmentDiffFiltered<T extends AssignmentDiffItemFragment>(
  data: T[],
) {
  return _.map(data, diffItem => {
    const srcSet = _.keyBy(diffItem?.srcLocations, l => l.locationId);
    const destSet = _.keyBy(diffItem?.destLocations, l => l.locationId);

    return {
      ...diffItem,
      srcLocations: _.filter(
        diffItem.srcLocations,
        l => !_.has(destSet, l.locationId),
      ),
      destLocations: _.filter(
        diffItem.destLocations,
        l => !_.has(srcSet, l.locationId),
      ),
    };
  });
}

export const getAssignmentDiffTableRows = (
  data: AssignmentDiffItemFragment[],
  config: AssignmentDiffConverterConfig,
): AssignmentDiffDataRow[] => {
  const getAssignmentDiffDataRow = (row: AssignmentDiffItemFragment) => {
    const rank = row.productDetails?.cumulativePercentRank;
    return {
      data: row,
      ...row.product,
      srcLocations: _.join(
        row.srcLocations.map(l => l.locationId),
        ', ',
      ),
      destLocations: _.join(
        row.destLocations.map(l => l.locationId),
        ', ',
      ),
      category: rank,
      categorySting: getCategoryString(rank, config.dictionaries.categories)
        ?.tag,
    } as AssignmentDiffDataRow;
  };

  return _.map(getAssignmentDiffFiltered(data), getAssignmentDiffDataRow);
};

export const ASSIGNMENT_DIFF_EXPORT_CONVERTER: ExportConverter<
  LoadOptimizationDiffQuery,
  AssignmentDiffConverterConfig
> = (input, config) => {
  const data = getAssignmentDiffTableRows(
    input.optimizationRun?.assignmentDiff?.items?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
