import {
  AllocationRunResultRequirementGroupBy,
  AllocationRunResultRequirementsFilter,
  LoadAllocationSummaryDocument,
  LoadAllocationSummaryQuery,
  LoadAllocationSummaryQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { secureClient } from '../../../GraphQLClient';
import { AsyncLoadStatus } from '../../../common/types';
import { errorAppender } from '../../../store/error.state';
import {
  allocationSummaryData,
  allocationSummaryDataLoadStatus,
} from '../../store/allocation/allocation.state';

export type LoadAllocationSummaryParams = {
  simulationId: string;
  groupBy: AllocationRunResultRequirementGroupBy[];
  filter: AllocationRunResultRequirementsFilter;
};

function useLoadAllocationSummary() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load allocation summary`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(allocationSummaryDataLoadStatus, AsyncLoadStatus.Loading);
    set(allocationSummaryData, null);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAllocationSummaryParams) => {
        //const current = await snapshot.getPromise(allocationSummaryData);
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationSummaryDataLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadAllocationSummaryQuery,
          LoadAllocationSummaryQueryVariables
        >({
          query: LoadAllocationSummaryDocument,
          variables: {
            simulationId: params.simulationId,
            groupBy: params.groupBy,
            filter: params.filter,
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const summaryData =
              data?.simulation?.latestAllocationRun?.resultRequirementsSummary;

            set(allocationSummaryData, summaryData);
            set(allocationSummaryDataLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAllocationSummaryParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAllocationSummary;
