import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { PickingConfigurationFilterType } from '../../simulation/store/pickingPolicy/pickingPolicy.default';
import { getPickingConfigurationFilterValues } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  policyFilterData,
  policyFiltersSearchValues,
} from '../../simulation/store/policyFilter.state';
import { simulationCurrent } from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';

export type LoadPickingConfigurationFilterValuesParams = {
  field: PickingConfigurationFilterType;
};

function useLoadPickingConfigurationFilterValues() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Picking Configuration filter values.`;

  const loadFilterValues = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadPickingConfigurationFilterValuesParams) => {
        const simulation = await snapshot.getPromise(simulationCurrent);

        const searchValues = await snapshot.getPromise(
          policyFiltersSearchValues,
        );

        const current = await snapshot.getPromise(
          policyFilterData(params.field),
        );
        const searchFilter = searchValues[params.field];

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(policyFilterData(params.field), {
            content: [],
            totalCount: 0,
            status: AsyncLoadStatus.Error,
          });
          return;
        }

        try {
          const filterData = getPickingConfigurationFilterValues({
            field: params.field,
            simulation,
            searchFilter,
          });
          set(policyFilterData(params.field), filterData);
        } catch (error) {
          console.error(error);
          handleError(error.message || error, error.stack || null);
        }
      },
  );

  async function call(params: LoadPickingConfigurationFilterValuesParams) {
    await loadFilterValues(params);
  }

  function cancel(field: string) {
    //
  }

  return [call, cancel] as const;
}
export default useLoadPickingConfigurationFilterValues;
