import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import LoadingIndicator from '../components/LoadingIndicator';
import StageContainer from '../layout/stage/StageContainer';
import { layoutDocumentLoadStatus } from '../layout/store/layout.state';
import ActiveFeatureLayer from '../layout/viewer/layers/ActiveFeatureLayer';
import AisleLayer from '../layout/viewer/layers/AisleLayer';
import BayLayer from '../layout/viewer/layers/BayLayer';
import HoveredFeatureLayer from '../layout/viewer/layers/HoveredFeatureLayer';
import OptimisationCongestionLayer from './layers/congestion/OptimisationCongestionLayer';
import HtmlTooltipLayer from './layers/HtmlTooltipLayer';
import { OptimisationLocationLayer } from './layers/OptimisationLocationLayer';
import OptimisationRouteLayer, {
  OptimisationRoutePartLayer,
} from './layers/OptimisationRouteLayer';
import SimulationLayoutPlaceholder from './SimulationLayoutPlaceholder';
import { optimisationAnalyzeId } from './store/optimisation.state';
import { simulationLayoutId } from './store/simulation.state';
import { OptimisationLayoutLoadingAlerts } from './toolbars/OptimisationLayoutLoadingAlerts';

const OptimisationLayoutView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const loadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const layoutId = useRecoilValue(simulationLayoutId);
  const analyzeId = useRecoilValue(optimisationAnalyzeId);

  if (!layoutId) return <SimulationLayoutPlaceholder />;

  if (loadStatus === AsyncLoadStatus.Loading) {
    return (
      <LoadingIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Loading Layout data...`}
      />
    );
  }

  return (
    <>
      <StageContainer type="simulation-area-view">
        <AisleLayer />
        <OptimisationCongestionLayer />
        <BayLayer />
        <OptimisationLocationLayer />
        <OptimisationRouteLayer />
        <OptimisationRoutePartLayer />
        <ActiveFeatureLayer />
        <HoveredFeatureLayer />
      </StageContainer>
      <Suspense
        fallback={
          <LoadingIndicator
            className="left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-[12rem] shadow-2xl"
            absolute
            selfCenter
            backdrop
            message={t`Loading Optimised Layout...`}
          />
        }
      >
        <HtmlTooltipLayer analyzeId={analyzeId} />
        <OptimisationLayoutLoadingAlerts />
      </Suspense>
    </>
  );
};

export default OptimisationLayoutView;
