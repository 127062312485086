import { atom, selector } from 'recoil';
import { viewerShowLocations } from '../../layout/viewer/store/viewer.state';
import { simulationLayoutHeatmapFilters } from '../../simulation/store/simulation.layout.state';
import { analyzeProductMetricDescriptorsMap } from './analyzeProductMetric.default';
import {
  AnalyzeProductMetric,
  AnalyzeProductMetricDescriptorBase,
} from './analyzeProductMetric.types';

const getKey = (postfix: string) => `warebee-analyze-product-metric-${postfix}`;

export const analyzeProductMetricSelectedAtom = atom<AnalyzeProductMetric>({
  key: getKey('metric-selected-atom'),
  default: 'abc',
});

export const analyzeProductMetricSelected = selector<AnalyzeProductMetric>({
  key: getKey('metric-selected'),
  get: ({ get }) => get(analyzeProductMetricSelectedAtom),
  set: ({ get, set, reset }, value) => {
    value && set(viewerShowLocations, true);
    reset(simulationLayoutHeatmapFilters);
    set(analyzeProductMetricSelectedAtom, value);
  },
});

export const analyzeProductMetricDescriptor =
  selector<AnalyzeProductMetricDescriptorBase>({
    key: getKey('metric-descriptor'),
    get: ({ get }) => {
      const metric = get(analyzeProductMetricSelected);
      return analyzeProductMetricDescriptorsMap[metric];
    },
  });
