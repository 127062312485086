import * as React from 'react';
const WarehouseCommerceIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseCommerceIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64.5 25.03 2.84 41.46 2.84 46.48 64.5 30.05 125.16 46.48 125.16 41.46 64.5 25.03"
    />
    <rect width={9.48} height={3.4} x={41.87} y={55.84} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M64.5,31.5L5.84,46.93v49.58h70.64v-2.97h37.48v2.97h8.21v-49.58l-57.66-15.43ZM24.92,59.48c.16-2.24,2.04-4,4.28-4,.89,0,1.72.26,2.43.75.71-.73,1.66-1.13,2.68-1.13.2,0,.36.16.36.36s-.16.35-.36.35c-.8,0-1.55.31-2.12.87.05.05.11.11.16.16.81.87,1.23,2.06,1.15,3.25l-.14,2.03c-.01.19-.17.33-.35.33,0,0-.02,0-.03,0-.2-.01-.34-.18-.33-.38l.14-2.03c.07-1.01-.27-1.98-.96-2.72-.03-.03-.06-.06-.08-.09-.32.51-.48,1.1-.46,1.72l.11,3.13c0,.2-.15.36-.34.37,0,0,0,0-.01,0-.19,0-.35-.15-.35-.34l-.11-3.13c-.03-.79.19-1.55.61-2.2-.58-.39-1.26-.6-1.98-.6-1.87,0-3.44,1.47-3.58,3.34l-.19,2.52c-.02.2-.19.34-.38.33h0c-.2-.02-.34-.19-.33-.38l.19-2.51ZM17.29,88.72l3.29-25.35h4.06l-.21,3.02c-.01.2.13.37.33.38.2.01.37-.13.38-.33l.22-3.07h7.19l-.21,3.02c-.01.2.13.37.33.38,0,0,.02,0,.03,0,.18,0,.34-.14.35-.33l.22-3.07h6.17l-3.29,25.35h-18.85ZM35.3,62.66v-6.68c1.16.4,1.99,1.47,2.04,2.77l.14,3.91h-2.18ZM36.87,88.62l3.26-25.09h0c.34.12.76.22,1.25.23l1.09,23.61-5.6,1.25ZM43.17,87.21l-1.08-23.51h0s0,0,0,0c.5-.09.91-.26,1.23-.45l2.9,23.28-3.05.68ZM57.92,84.94h-11.18l-2.78-22.35c-.02-.13-.1-.24-.22-.29-.12-.05-.26-.03-.36.05-.28.22-.87.61-1.69.68-.74.06-1.32-.15-1.69-.34h0s-.11-.04-.16-.04h-1.65l-.14-3.94c-.06-1.69-1.2-3.07-2.75-3.49v-1.31h22.62v31.02ZM59.55,82.76c-.35.3-.65.66-.92,1.04v-22.48l1.9,20.75c-.35.2-.69.43-.99.69ZM113.95,92.06h-37.48v-2.65h37.48v2.65ZM113.95,87.93h-37.48v-2.65h37.48v2.65ZM113.95,83.8h-37.48v-2.65h37.48v2.65ZM113.95,79.57h-37.48v-2.65h37.48v2.65ZM113.95,75.45h-37.48v-2.65h37.48v2.65ZM113.95,71.32h-37.48v-2.65h37.48v2.65Z"
    />
  </svg>
);
export default WarehouseCommerceIcon;
