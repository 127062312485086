import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type LabelProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  type?: string;
  active?: boolean;
};

export const LabelModes = ({
  children,
  className,
  title,
  active,
}: LabelProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="LabelModes"
      className={classNames(
        'inline-block px-2 text-xs font-bold rounded-md',
        active
          ? 'bg-app-panel-dark text-menu-active'
          : 'bg-menu-active text-menu-active-text',
        className,
      )}
    >
      {title}
      {children}
    </div>
  );
};

export const LabelModesBeta = ({
  children,
  title,
  active,
  className,
}: LabelProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="LabelModesBeta"
      className={classNames(
        'inline-block',
        'px-1 xl:px-2 py-0.5',
        'ltr:ml-1 xl:ltr:ml-2 rtl:mr-2',
        'text-xs font-bold',
        'rounded-md',
        active
          ? 'border border-app-panel-dark/70'
          : 'bg-menu-active text-menu-active-text',
        className,
      )}
    >
      {title}
      {children}
    </div>
  );
};

export const LabelModesDemo = ({
  children,
  title,
  className,
  active,
}: LabelProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="LabelModesDemo"
      className={classNames(
        'inline-block',
        'px-1 xl:px-2 py-0.5',
        'ltr:ml-1 xl:ltr:ml-2 rtl:mr-2',
        'text-xs font-bold',
        'rounded-md',
        active
          ? 'border border-app-panel-dark/70'
          : 'bg-menu-active text-menu-active-text',
        className,
      )}
    >
      {title}
      {children}
    </div>
  );
};
