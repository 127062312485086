import {
  AnalyzeResultLocationFragment,
  AnalyzeResultProductFragment,
  AnalyzeResultProductReplenishmentFragment,
  LoadAnalyzeLocationsQuery,
  LoadAnalyzeLocationsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  ProductCategoryDescriptorExport,
  getCategoryString,
} from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type AnalyzedProductsExportJobParams = ExportJobParams<
  LoadAnalyzeLocationsQueryVariables,
  AnalyzedProductsConverterConfig
>;

export const analyzedProductsExportJobParams: AnalyzedProductsExportJobParams =
  {
    converterId: 'ANALYZED_PRODUCTS',
    query: null,
    config: null,
    variables: null,
    filename: 'analyzed-products.csv',
  };

export type AnalyzedProductsDataRow = {
  locationId: string;
  categorySting: string;
  stackingPolicyRule: string;

  triggeredReorderCount: number;
  triggeredReorderTotalDuration: number;
  triggeredReorderTotalCost: number;
  triggeredReorderTotalReorderedWeight: number;

  appliedReorderCount: number;
  appliedReorderTotalDuration: number;
  appliedReorderTotalCost: number;
  appliedReorderTotalReorderedWeight: number;
} & Omit<AnalyzeResultProductFragment, '__typename'> &
  Omit<AnalyzeResultProductReplenishmentFragment, '__typename'> &
  Pick<AnalyzeResultLocationFragment, 'congestionZone'>;

export type AnalyzedProductsDataColumn = keyof AnalyzedProductsDataRow;

export type AnalyzedProductsConverterConfig = {
  columnsConfig: ColumnConfigBase<AnalyzedProductsDataRow>[];
  dictionaries: {
    categories: ProductCategoryDescriptorExport[];
    stackingPolicyIndexedRuleTitles: Record<number, string>;
  };
};

export const getAnalyzedProductsTableRows = (
  data: AnalyzeResultLocationFragment[],
  config: AnalyzedProductsConverterConfig,
): AnalyzedProductsDataRow[] => {
  const getAnalyzedProductsDataRow = (
    row: AnalyzeResultLocationFragment,
  ): AnalyzedProductsDataRow[] => {
    return _.map(row.products, p => ({
      ...p,
      ...(p.replenishment ?? ({} as AnalyzeResultProductReplenishmentFragment)),

      locationId: row.locationId,
      congestionZone: row.congestionZone,
      triggeredReorderCount: p.triggeredReorder?.count,
      triggeredReorderTotalDuration: p.triggeredReorder?.totalDuration,
      triggeredReorderTotalCost: p.triggeredReorder?.totalCost,
      triggeredReorderTotalReorderedWeight:
        p.triggeredReorder?.totalReorderedWeight,

      appliedReorderCount: p.appliedReorder?.count,
      appliedReorderTotalDuration: p.appliedReorder?.totalDuration,
      appliedReorderTotalCost: p.appliedReorder?.totalCost,
      appliedReorderTotalReorderedWeight:
        p.appliedReorder?.totalReorderedWeight,

      categorySting: getCategoryString(
        p.cumulativePercentRank,
        config.dictionaries.categories,
      )?.tag,
      stackingPolicyRule: _.isNil(p.stackingCategoryIndex)
        ? null
        : config.dictionaries.stackingPolicyIndexedRuleTitles[
            p.stackingCategoryIndex
          ],
    }));
  };

  return _.flatMap(data, getAnalyzedProductsDataRow);
};

export const ANALYZED_PRODUCTS_EXPORT_CONVERTER: ExportConverter<
  LoadAnalyzeLocationsQuery,
  AnalyzedProductsConverterConfig
> = (input, config) => {
  const data = getAnalyzedProductsTableRows(
    input.analyzeResult?.visitedLocations?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
