// Environment variable params
declare const BACKEND_URL_PARAM: string;
declare const BACKEND_PATH_PARAM: string;
declare const APP_BILLING_URL_PARAM: string;
declare const APP_BILLING_PATH_PARAM: string;
declare const APP_BILLING_PUBLIC_PATH_PARAM: string;

declare const COGNITO_APP_DOMAIN_URL_PARAM: string;

declare const COGNITO_REGION_PARAM: string;
declare const COGNITO_USER_POOL_ID_PARAM: string;
declare const COGNITO_USER_POOL_CLIENT_ID_PARAM: string;

declare const COGNITO_USER_POOL_MFA_ID_PARAM: string;
declare const COGNITO_USER_POOL_MFA_CLIENT_ID_PARAM: string;

declare const BUILD_INFO_ENV: string;
declare const BUILD_INFO_APP_NAME: string;
declare const BUILD_INFO_APP_VER: string;
declare const BUILD_INFO_APP_VER_FRONTEND: string;
declare const BUILD_INFO_APP_VER_BACKEND: string;
declare const BUILD_INFO_APP_VER_BILLING: string;
declare const BUILD_INFO_APP_VER_ENGINE: string;
declare const BUILD_INFO_APP_VER_DB: string;

declare const BUILD_INFO_TIMESTAMP: string;
declare const BUILD_INFO_BUILD_ID: string;
declare const BUILD_INFO_BUILD_HASH: string;

/* Analytics */
declare const ANALYTICS_VARS_ANALYTICS_STATUS: string;
declare const ANALYTICS_VARS_HUBSPOT_KEY: string;
declare const SEGMENT_WRITE_KEY_PARAM: string;

declare const ANALYTICS_VARS_GTM_ID: string;
declare const ANALYTICS_VARS_GTM_AUTH: string;
declare const ANALYTICS_VARS_GTM_PREVIEW: string;

/**
 * Base URL of backend.
 * Will be injected during build, if necessary, otherwise
 * empty (e.g. same domain).
 */
export const BACKEND_URL = BACKEND_URL_PARAM || '';
export const BACKEND_PATH = BACKEND_PATH_PARAM || '/graphql';
export const TOKEN_BACKEND_PATH = '/oauth' + BACKEND_PATH;
export const APP_BACKEND_URL = BACKEND_URL_PARAM || '';
export const APP_BILLING_URL = APP_BILLING_URL_PARAM || '';
export const APP_BILLING_PATH = APP_BILLING_PATH_PARAM || '/graphql';
export const TOKEN_APP_BILLING_PATH = '/oauth' + APP_BILLING_PATH;
export const APP_BILLING_PUBLIC_PATH =
  APP_BILLING_PUBLIC_PATH_PARAM || '/public';

/*COGNITO */
export const APP_DOMAIN_URL = COGNITO_APP_DOMAIN_URL_PARAM;
export const COGNITO_REGION = COGNITO_REGION_PARAM || 'eu-west-1';
export const COGNITO_USER_POOL_ID = COGNITO_USER_POOL_ID_PARAM;
export const COGNITO_USER_POOL_CLIENT_ID = COGNITO_USER_POOL_CLIENT_ID_PARAM;

export const COGNITO_USER_POOL_MFA_ID = COGNITO_USER_POOL_MFA_ID_PARAM;
export const COGNITO_USER_POOL_MFA_CLIENT_ID =
  COGNITO_USER_POOL_MFA_CLIENT_ID_PARAM;

/* Build Info  `BUILD_INFO_${key}`, */
export const APP_NAME = BUILD_INFO_APP_NAME;
export const APP_VER = BUILD_INFO_APP_VER;
export const APP_VER_FRONTEND = BUILD_INFO_APP_VER_FRONTEND;
export const APP_VER_BACKEND = BUILD_INFO_APP_VER_BACKEND;
export const APP_VER_ENGINE = BUILD_INFO_APP_VER_ENGINE;
export const APP_VER_BILLING = BUILD_INFO_APP_VER_BILLING;
export const APP_VER_DB = BUILD_INFO_APP_VER_DB;

export const BUILD_ENV = BUILD_INFO_ENV;
export const BUILD_TIMESTAMP = BUILD_INFO_TIMESTAMP;
export const BUILD_ID = BUILD_INFO_BUILD_ID;
export const BUILD_HASH = BUILD_INFO_BUILD_HASH;

/* Analytics  `ANALYTICS_VARS_${key}`, */
export const ANALYTICS_STATUS = ANALYTICS_VARS_ANALYTICS_STATUS;
export const SEGMENT_WRITE_KEY = SEGMENT_WRITE_KEY_PARAM;
export const HUBSPOT_KEY = ANALYTICS_VARS_HUBSPOT_KEY;

export const GTM_ID = ANALYTICS_VARS_GTM_ID;
export const GTM_AUTH = ANALYTICS_VARS_GTM_AUTH;
export const GTM_PREVIEW = ANALYTICS_VARS_GTM_PREVIEW;
