const CloudDownloadIcon = props => (
  <svg
    aria-hidden="true"
    data-name="CloudDownloadIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon points="74.04 81.27 74.04 102.92 87.12 89.84 87.13 103.29 69.28 121.13 51.43 103.29 51.43 89.84 64.52 102.92 64.52 81.27 74.04 81.27" />
    <path d="m105.08,31.97h0c-1.88-14.15-14.02-25.1-28.68-25.1-10.97,0-18.68,6.81-22.49,11.17-3.17-1.89-6.81-2.91-10.58-2.91-10.08,0-18.5,7.26-20.31,16.82-11.89,1.82-21.03,12.12-21.03,24.51,0,13.67,11.13,24.8,24.8,24.8h37.68v-21.65s9.52,0,9.52,0v21.65h27.2c13.67,0,24.8-11.13,24.8-24.8,0-12.35-9.08-22.63-20.92-24.5Z" />
  </svg>
);
export default CloudDownloadIcon;
