import { registerEnumType } from '@warebee/shared/util-backend-only-types';

export enum BatchJobStatus {
  CREATED = 'CREATED',
  CALCULATING = 'CALCULATING',
  READY = 'READY',
  FAILED = 'FAILED',
  TERMINATED = 'TERMINATED',
  RESTARTED = 'RESTARTED',
}

registerEnumType(BatchJobStatus, {
  name: 'BatchJobStatus',
});
