import { AssignmentOccupancySummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../../../Tw';
import { getBestContrastColor } from '../../../../common/color.helper';
import useFormatter from '../../../../common/useFormatter';
import ButtonSwitchMulti from '../../../../components/actions/ButtonSwitchMulti';
import { Stat } from '../../../../components/stats/Stat';
import { StatCenterPie } from '../../../../components/stats/StatCenterPie';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { StatListItem } from '../../../../components/stats/StatListItem';
import LayoutStats from '../../../../layout/LayoutStats';
import ContainerChart from '../../ContainerChart';
import SimulationPieChart, { PieData } from '../../SimulationPieChart';

const colors = TwTheme.extend.colors.dimensionsColors;
const colorsOccupancy = TwTheme.extend.colors.categoryOccupancy;

export type OccupancySummaryPanelProps = {
  summary: AssignmentOccupancySummaryFragment;
  cardView?: boolean;
};

const OccupancySummaryPanel: React.FC<OccupancySummaryPanelProps> = props => {
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  const { summary } = props;
  const maxOccupancy = summary.maxOccupancy;
  const totalVolume = formatter.formatVolume(summary.totalStorageVolume);
  const occupiedVolume = formatter.formatVolume(summary.totalOccupiedVolume);
  const unknownVolume = formatter.formatVolume(summary.totalUnknownVolume);
  const occupiedShare = formatter.formatShare(summary.totalOccupancy);
  const freeShare = formatter.formatShare(1 - summary.totalOccupancy);
  const freeVolume = formatter.formatVolume(
    summary.totalStorageVolume -
      (summary.totalOccupiedVolume ?? 0) -
      (summary.totalUnknownVolume ?? 0),
  );

  const [selectedSwitchOption, setSelectedSwitchOption] = useState<number>(0);

  const isPercentageView = selectedSwitchOption === 1;

  const chartData: PieData[] = [
    {
      id: 'occupied',
      label: t`Occupied Volume`,
      color: [colorsOccupancy[90], getBestContrastColor(colorsOccupancy[90])],
      value: isPercentageView
        ? summary.totalOccupancy * 100
        : occupiedVolume.raw,
      formatted: isPercentageView ? occupiedShare : occupiedVolume,
    },
    {
      id: 'unknownVolume',
      label: t`Unknown Volume`,
      color: [
        colorsOccupancy['unknown'],
        getBestContrastColor(colorsOccupancy['unknown']),
      ],
      value: isPercentageView
        ? (unknownVolume.raw / summary.totalStorageVolume) * 100
        : unknownVolume.raw,
      formatted: isPercentageView
        ? formatter.formatShare(unknownVolume.raw / summary.totalStorageVolume)
        : unknownVolume,
    },
    {
      id: 'free',
      label: t`Free Volume`,
      color: [colorsOccupancy[20], getBestContrastColor(colorsOccupancy[20])],
      value: isPercentageView
        ? (1 - summary.totalOccupancy) * 100
        : freeVolume.raw,
      formatted: isPercentageView ? freeShare : freeVolume,
    },
  ];

  const getKpiValue = (dim: typeof summary) => {
    return isPercentageView ? occupiedShare.fullString : occupiedVolume.unit;
  };

  const legendOptions = [{ label: t`Vol. (m³)` }, { label: t`Vol. (%)` }];

  return (
    <>
      <StatGroup
        data-component="OccupancySummaryPanel"
        title={t`Occupancy`}
        subTitle={t`Stats`}
        cardView={props.cardView}
        helpNavTo={'simulation/compliance/simulation-compliance-volume'}
      >
        <Stat
          cardView={props.cardView}
          hasHelper
          isPreview
          title={t`Occupancy`}
          inPanelMode
          value={isPercentageView ? occupiedShare.value : totalVolume.value}
          unitOfMeasure={isPercentageView ? '%' : totalVolume.unit}
          switchUnitOfMeasure={
            <ButtonSwitchMulti
              buttonType="minimal"
              autoSize
              className="mx-2"
              selectedIndex={selectedSwitchOption}
              onClick={setSelectedSwitchOption}
              options={legendOptions}
            />
          }
        >
          <ContainerChart>
            <StatCenterPie
              statLabelTop={t`Occupied`}
              statValueTop={
                isPercentageView ? occupiedVolume.value : occupiedShare.value
              }
              statLabelBottom={t`Free`}
              statValueBottom={
                isPercentageView ? freeVolume.value : freeShare.value
              }
              
            />
            <SimulationPieChart data={chartData} />
          </ContainerChart>

          <StatListItem
            title={t`Occupied Volume`}
            unitOfMeasure={isPercentageView ? '%' : occupiedVolume.unit}
            value={
              isPercentageView ? occupiedShare.value : occupiedVolume.value
            }
            legendColor={colorsOccupancy[90]}
            legendType={'circle'}
          />
          <StatListItem
            title={t`Free Volume`}
            unitOfMeasure={isPercentageView ? '%' : occupiedVolume.unit}
            value={isPercentageView ? freeShare.value : freeVolume.value}
            legendColor={colorsOccupancy[20]}
            legendType={'circle'}
          />
          <StatListItem
            title={t`Unknown Location Volume`}
            value={
              isPercentageView
                ? formatter.formatShare(
                    unknownVolume.raw / summary.totalStorageVolume,
                  ).value
                : unknownVolume.value
            }
            unitOfMeasure={isPercentageView ? '%' : unknownVolume.unit}
            legendColor={colorsOccupancy['unknown']}
            legendType={'circle'}
          />
        </Stat>
      </StatGroup>
      <LayoutStats />
    </>
  );
};

export default OccupancySummaryPanel;
