import _ from 'lodash';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import LoadingIndicator from '../LoadingIndicator';

export type ContainerAppViewProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ContainerAppView = ({
  children,
  className,
}: ContainerAppViewProps) => {
  const { t } = useTranslation('app');
  const [id] = useState<string>(_.uniqueId());

  return (
    <main
      data-component="ContainerAppView"
      id={id}
      className={cn('flex h-full w-full flex-1 overflow-hidden', className)}
    >
      <Suspense fallback={<LoadingIndicator message={t`Loading`} selfCenter />}>
        {children}
      </Suspense>
    </main>
  );
};
