import React from 'react';
import { ContainerMap } from '../../components/layout/ContainerMap';
import LocationHtmlTooltipLayer from '../../layout/bayViewer/layers/LocationHtmlTooltipLayer';
import StageContainer from '../../layout/stage/StageContainer';
import ActualityBayLayoutLoadingAlerts from '../components/ActualityBayLayoutLoadingAlerts';
import FeedBayLocationLayer from '../layers/FeedBayLocationLayer';

export type LayoutContainerProps = {};

const FeedBayViewerLayout: React.FC<LayoutContainerProps> = () => {
  return (
    <ContainerMap className="min-h-bayMap">
      <StageContainer type="viewer-bay-view" darkBg={true}>
        <FeedBayLocationLayer />
      </StageContainer>
      <ActualityBayLayoutLoadingAlerts />
      <LocationHtmlTooltipLayer />
    </ContainerMap>
  );
};

export default FeedBayViewerLayout;
