import {
  ASSIGNMENT_POLICY_RULE_STATUS_ALL,
  ASSIGNMENT_POLICY_RULE_TYPE,
  AssignmentPolicyImportLineInput,
  AssignmentPolicyRuleStatus,
  BAY_SIDE_ALL,
} from '@warebee/shared/data-access-api-dto';
import {
  AssignmentPolicy,
  AssignmentPolicyRule,
  AssignmentPolicyRuleMatchType,
  FilterValue,
  LocationFilter,
  LocationFilterIntersection,
  LocationFilterType,
  NumberFilterRange,
  SimulationItemFilter,
  SimulationItemFilterType,
  StringFilterRange,
} from '@warebee/shared/engine-model';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { MappingSchema } from '../mapping';
import { baySideValueResolver } from '../valueResolver/enumValueResolver';
import { MappingValueResolver } from '../valueResolver/valueResolverBase';

export type AssignmentPolicyImportLineField =
  keyof AssignmentPolicyImportLineInput;

export const policyStatusValueResolver: MappingValueResolver<AssignmentPolicyRuleStatus> =
  {
    enabled: AssignmentPolicyRuleStatus.ENABLED,
    disabled: AssignmentPolicyRuleStatus.DISABLED,
  };

export const policyRuleTypeValueResolver: MappingValueResolver<AssignmentPolicyRuleMatchType> =
  {
    itemMustBeInLocation:
      AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION,
    locationMustContainItem:
      AssignmentPolicyRuleMatchType.LOCATION_MUST_CONTAIN_ITEM,
    strictMatch: AssignmentPolicyRuleMatchType.STRICT_MATCH,
  };

export const ASSIGNMENT_POLICY_MAPPING_SCHEMA: MappingSchema<AssignmentPolicyImportLineInput> =
  {
    fields: [
      {
        name: 'consignee',
        type: 'string',
        optional: true,
      },
      {
        name: 'sku',
        type: 'string',
        optional: true,
      },
      {
        name: 'skuGroup',
        type: 'string',
        optional: true,
      },
      {
        name: 'subGroup',
        type: 'string',
        optional: true,
      },
      {
        name: 'transportClass',
        type: 'string',
        optional: true,
      },
      {
        name: 'stockCategory',
        type: 'string',
        optional: true,
      },
      {
        name: 'storageClass',
        type: 'string',
        optional: true,
      },
      {
        name: 'pollutionClass',
        type: 'string',
        optional: true,
      },
      // Locations
      {
        name: 'locationFrom',
        type: 'string',
        optional: true,
      },
      {
        name: 'locationTo',
        type: 'string',
        optional: true,
      },
      {
        name: 'aisleFrom',
        type: 'string',
        optional: true,
      },
      {
        name: 'aisleTo',
        type: 'string',
        optional: true,
      },
      {
        name: 'bayFrom',
        type: 'string',
        optional: true,
      },
      {
        name: 'bayTo',
        type: 'string',
        optional: true,
      },
      {
        name: 'baySide',
        type: { enumValues: BAY_SIDE_ALL },
        defaultValueResolver: baySideValueResolver,
        optional: true,
      },
      {
        name: 'levelFrom',
        type: 'number',
        optional: true,
      },
      {
        name: 'levelTo',
        type: 'number',
        optional: true,
      },
      {
        name: 'congestionZone',
        type: 'string',
        optional: true,
      },
      {
        name: 'status',
        type: { enumValues: ASSIGNMENT_POLICY_RULE_STATUS_ALL },
        optional: true,
        defaultValueResolver: policyStatusValueResolver,
      },
      {
        name: 'ruleType',
        type: { enumValues: ASSIGNMENT_POLICY_RULE_TYPE },
        optional: true,
        defaultValueResolver: policyRuleTypeValueResolver,
      },
    ],
  };

type FilterBase<T extends string> = {
  isNull?: boolean;
  range?: NumberFilterRange;
  stringRange?: StringFilterRange;
  type: T;
  valueIn?: FilterValue[];
};

type FilterValueType =
  | 'equal'
  | 'rangeFrom'
  | 'rangeTo'
  | 'stringRangeFrom'
  | 'stringRangeTo'
  | 'integerToStringRangeFrom'
  | 'integerToStringRangeTo';

const itemFilterFields = [
  'consignee',
  'sku',
  'skuGroup',
  'subGroup',
  'transportClass',
  'stockCategory',
  'storageClass',
  'pollutionClass',
] as const;

type ItemFilterField = (typeof itemFilterFields)[number] &
  AssignmentPolicyImportLineInput;

const locationRelatedFields = [
  'locationId',
  'locationFrom',
  'locationTo',
  'levelFrom',
  'levelTo',
  'aisleFrom',
  'aisleTo',
  'bayFrom',
  'bayTo',
  'baySide',
  'congestionZone',
] as const;
type LocationFilterField = (typeof locationRelatedFields)[number] &
  AssignmentPolicyImportLineInput;

function getItemRuleKey(data: AssignmentPolicyImportLineInput): string {
  return _(itemFilterFields)
    .map(f => data[f])
    .compact()
    .join(' - ');
}

function getItemFilterType(
  field: ItemFilterField,
): [SimulationItemFilterType, FilterValueType] {
  switch (field) {
    case 'consignee':
      return [SimulationItemFilterType.CONSIGNEE, 'equal'];
    case 'sku':
      return [SimulationItemFilterType.SKU, 'equal'];
    case 'skuGroup':
      return [SimulationItemFilterType.SKU_GROUP, 'equal'];
    case 'subGroup':
      return [SimulationItemFilterType.SUB_GROUP, 'equal'];
    case 'transportClass':
      return [SimulationItemFilterType.TRANSPORT_CLASS, 'equal'];
    case 'stockCategory':
      return [SimulationItemFilterType.STOCK_CATEGORY, 'equal'];
    case 'storageClass':
      return [SimulationItemFilterType.STORAGE_CLASS, 'equal'];
    case 'pollutionClass':
      return [SimulationItemFilterType.POLLUTION_CLASS, 'equal'];
    default:
      new Error(`$Field '{field}' is not valid Item field`);
  }
}

function getLocationsFilterType(
  field: LocationFilterField,
): [LocationFilterType, FilterValueType] {
  switch (field) {
    case 'baySide':
      return [LocationFilterType.SIDE, 'equal'];
    case 'locationId':
      return [LocationFilterType.LOCATION, 'equal'];
    case 'locationFrom':
      return [LocationFilterType.LOCATION, 'stringRangeFrom'];
    case 'locationTo':
      return [LocationFilterType.LOCATION, 'stringRangeTo'];
    case 'levelFrom':
      return [LocationFilterType.LEVEL, 'integerToStringRangeFrom'];
    case 'levelTo':
      return [LocationFilterType.LEVEL, 'integerToStringRangeTo'];
    case 'bayFrom':
      return [LocationFilterType.BAY, 'stringRangeFrom'];
    case 'bayTo':
      return [LocationFilterType.BAY, 'stringRangeTo'];
    case 'aisleFrom':
      return [LocationFilterType.AISLE, 'stringRangeFrom'];
    case 'aisleTo':
      return [LocationFilterType.AISLE, 'stringRangeTo'];
    case 'congestionZone':
      return [LocationFilterType.CONGESTION_ZONE, 'equal'];
    default:
      new Error(`$Field '{field}' is not valid Location field`);
  }
}

function getFilter(
  value: any,
  type: LocationFilterType | SimulationItemFilterType,
  valueType: FilterValueType,
  source: LocationFilter | SimulationItemFilter,
): FilterBase<string> {
  let intRange;
  switch (valueType) {
    case 'equal':
      return {
        type,
        valueIn: [
          {
            title: value,
          },
        ],
      };
    case 'rangeFrom':
      return {
        type,
        range: {
          ...(source?.range ?? {}),
          from: value,
        },
      };
    case 'rangeTo':
      return {
        type,
        range: {
          ...(source?.range ?? {}),
          to: value,
        },
      };
    case 'stringRangeFrom':
      return {
        ...(source ?? {}),
        type,
        stringRange: {
          ...(source?.stringRange ?? {}),
          from: value,
        },
      };
    case 'stringRangeTo':
      return {
        ...(source ?? {}),
        type,
        stringRange: {
          ...(source?.stringRange ?? {}),
          to: value,
        },
      };
    case 'integerToStringRangeFrom':
      intRange = [parseInt(value), parseInt(source?.stringRange?.to)];
      if (_.every(intRange, v => !Number.isNaN(v))) {
        // replace from-to filter to valueIn filter
        return {
          type,
          valueIn: _.range(
            Math.min(...intRange),
            Math.max(...intRange) + 1,
            1,
          ).map(i => ({
            title: `${i}`,
          })),
        };
      }

      return {
        ...(source ?? {}),
        type,
        stringRange: {
          ...(source?.stringRange ?? {}),
          from: value,
        },
      };

    case 'integerToStringRangeTo':
      intRange = [parseInt(value), parseInt(source?.stringRange?.from)];
      if (_.every(intRange, v => !Number.isNaN(v))) {
        // replace from-to filter to valueIn filter
        return {
          type,
          valueIn: _.range(
            Math.min(...intRange),
            Math.max(...intRange) + 1,
            1,
          ).map(i => ({
            title: `${i}`,
          })),
        };
      }

      return {
        ...(source ?? {}),
        type,
        stringRange: {
          ...(source?.stringRange ?? {}),
          to: value,
        },
      };
    default:
      new Error(`$Field '{field}' is not valid Location field`);
  }
}

function getLocationFilter(
  field: LocationFilterField,
  value: any,
  source?: LocationFilter,
): LocationFilter {
  const [type, valueType] = getLocationsFilterType(field);
  return getFilter(value, type, valueType, source) as LocationFilter;
}

function getItemFilter(
  field: ItemFilterField,
  value: any,
  source?: LocationFilter,
): SimulationItemFilter {
  const [type, valueType] = getItemFilterType(field);
  return getFilter(value, type, valueType, source) as SimulationItemFilter;
}

export function createAssignmentPolicy(
  rows: AssignmentPolicyImportLineInput[],
): AssignmentPolicy {
  const rules = _(rows)
    .filter(r => r.status !== 'DISABLED')
    .groupBy(getItemRuleKey)
    .map((data, key) => {
      const first = _.head(data);

      const allOfItemsFilters = _(itemFilterFields)
        .filter(f => !_.isNil(first[f]))
        .map(f => getItemFilter(f, first[f]))
        .value();

      const locationsFilters: LocationFilterIntersection[] = _.map(
        data,
        row => {
          const filters = _(locationRelatedFields)
            .filter(f => !_.isNil(row[f]))
            .reduce((acc, f) => {
              const [type, valueType] = getLocationsFilterType(f);
              const current = getLocationFilter(f, row[f], acc[type] ?? {});
              return {
                ...acc,
                [type]: current,
              };
            }, {});
          return {
            id: nanoid(),
            allOf: _.values(filters),
          };
        },
      );

      const rule: AssignmentPolicyRule = {
        id: key,
        title: key,
        type: first.ruleType,
        productsMatch: {
          anyOf: [
            {
              id: nanoid(),
              allOf: allOfItemsFilters,
            },
          ],
        },
        locationsMatch: {
          anyOf: locationsFilters,
        },
      };
      return rule;
    })
    .value();
  return {
    rules,
  };
}
