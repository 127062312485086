import * as React from 'react';
const WeightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WeightIcon"
    {...props}
  >
    <polyline
      strokeWidth={0}
      points="122.65 86.94 110.7 80.04 64.11 107.11 17.22 80.04 5.35 86.94 64.19 120.91 122.65 86.94"
    />
    <path
      strokeWidth={0}
      d="M86.67,40.27l-9.64-4.34c.84-1.98,1.31-4.21,1.31-6.56,0-8.35-5.95-15.14-13.26-15.14-7.31,0-13.24,6.79-13.24,15.14,0,2.16.39,4.21,1.1,6.07l-12.37,4.97-15.03,36.09,39.3,23.14,39.86-23.17-18.03-36.21ZM58.18,27.89c0-4.46,3.1-8.08,6.91-8.08s6.92,3.62,6.92,8.08c0,1.31-.27,2.55-.75,3.65-.17.39-.37.78-.6,1.13-1.26,2-10.06,1.89-11.29-.26h0c-.22-.37-.4-.75-.56-1.15-.4-1.03-.63-2.16-.63-3.37h0ZM64.68,51.66l-18.81-8.91,8.61-3.75c2.17,4.03,18.57,4.22,20.77.43l6.38,3.21-16.96,9.02Z"
    />
  </svg>
);
export default WeightIcon;
