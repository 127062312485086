import {
  GenerateLayoutIssueSetDocument,
  GenerateLayoutIssueSetMutation,
  GenerateLayoutIssueSetMutationVariables,
  LoadLayoutIssueSetDocument,
  LoadLayoutIssueSetQuery,
  LoadLayoutIssueSetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  simulationLayoutIssues,
  simulationLayoutIssuesLoadStatus,
} from '../store/simulation.issues.state';

export type LoadLayoutIssuesParams = {
  layoutId: string;
};

function useLoadLayoutIssues() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Layout Issues`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadLayoutIssuesParams) => {
        if (_.isNil(params.layoutId)) {
          return;
        }
        set(simulationLayoutIssuesLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLayoutIssuesParams) => {
        if (_.isNil(params.layoutId)) {
          return;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationLayoutIssuesLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const key = {
          layoutId: params.layoutId,
        };

        const query = secureClient.watchQuery<
          LoadLayoutIssueSetQuery,
          LoadLayoutIssueSetQueryVariables
        >({
          query: LoadLayoutIssueSetDocument,
          variables: { key },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const issuesSet = data?.layoutIssueSet;

            if (_.isNil(issuesSet)) {
              const runResult = await secureClient.mutate<
                GenerateLayoutIssueSetMutation,
                GenerateLayoutIssueSetMutationVariables
              >({
                mutation: GenerateLayoutIssueSetDocument,
                variables: {
                  key,
                },
              });
              set(
                simulationLayoutIssues,
                runResult?.data?.generateLayoutIssueSet,
              );
              set(simulationLayoutIssuesLoadStatus, AsyncLoadStatus.Ok);
            } else {
              set(simulationLayoutIssues, issuesSet);
              set(simulationLayoutIssuesLoadStatus, AsyncLoadStatus.Ok);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadLayoutIssuesParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadLayoutIssues;
