import { AnalyzeResultFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import naturalCompare from 'string-natural-compare';
import { TwTheme } from '../../Tw';
import {
  AnalyzeProductDataRow,
  AnalyzeProductMetricDescriptorBase,
} from '../metrics/analyzeProduct/analyzeProductMetric.types';
import { productCategories } from '../simulation/store/abc/simulation.ABC.types';
import { NamedColors } from '../store/namedColors.types';
import { getQualitativeColor, getSequentialColor } from './color.helper';
import { formatInteger, formatToPrecision } from './formatHelper';
import {
  HeatmapMetricDescriptor,
  HeatmapMetricDescriptorBase,
  HeatmapMetricRange,
} from './heatmap.types';

const locationColors = TwTheme.extend.colors.location;

export const HEATMAP_BUCKETS_COUNT = 10;
export const HEATMAP_ZERO_BUCKET_ID = 'zero';

export type HeatmapBucketStats = {
  totalCount?: number;
  eventsCount?: number;
  itemsCount?: number;
  assignmentsCount?: number;
  locationCount?: number;
  totalExistedLocations?: number;
};

export type HeatmapBucketStatField = keyof HeatmapBucketStats;
export type HeatmapBucketSortDirection = 'asc' | 'desc';

export type HeatmapBucket = {
  id: string;
  index: number;
  title: string;
  from?: number;
  to?: number;
  color: string;
  textColor: string;
  stats?: HeatmapBucketStats;
  levelStats?: Record<number, HeatmapBucketStats>;
};

//  Named buckets
export type GetHeatmapNamedBucketsParams = {
  keys: string[];
  getColor: (key: string) => [string, string];
  stats?: Record<string, HeatmapBucketStats>;
  levelStats?: Record<string, Record<number, HeatmapBucketStats>>;
};

export function getHeatmapNamedBuckets(
  params: GetHeatmapNamedBucketsParams,
): HeatmapBucket[] {
  const buckets = _([...params.keys])
    .sort((l1, l2) => naturalCompare(l1, l2))
    .map((id, index) => {
      const [color, textColor] = params.getColor(id);
      const bucket: HeatmapBucket = {
        id,
        index,
        title: id ?? 'Unknown',
        color,
        textColor,
        stats: params.stats?.[id],
        levelStats: params.levelStats?.[id],
      };
      return bucket;
    })
    .value();
  return buckets;
}

// Linear range buckets
export type LinearRangeBucketsParams = {
  minValue: number;
  maxValue: number;
  bucketCount: number;
  formatter?: (v: number) => string;
};

export type GetHeatmapLinearRangeBuckets = LinearRangeBucketsParams & {
  getColor: (bucketNum: number) => [string, string];
};

export function getHeatmapLinearRangeBuckets(
  params: GetHeatmapLinearRangeBuckets,
): HeatmapBucket[] {
  const valuesRange = params.maxValue - params.minValue;
  const bucketSize = valuesRange / params.bucketCount;
  const valueFormatter = params.formatter ?? formatToPrecision;
  const valueBuckets: HeatmapBucket[] = _.rangeRight(
    1,
    params.bucketCount + 1,
  ).map((bucketNum, index) => {
    const from = params.minValue + (bucketNum - 1) * bucketSize;
    const to = from + bucketSize;
    const title = `${valueFormatter(from)} - ${valueFormatter(to)}`;
    const [color, textColor] = params.getColor(bucketNum);
    return {
      id: bucketNum.toString(),
      index: bucketSize - index,
      title,
      from,
      to,
      color,
      textColor,
    };
  });
  valueBuckets.push({
    id: HEATMAP_ZERO_BUCKET_ID,
    index: 0,
    title: '0',
    from: 0,
    to: 0,
    color: locationColors.unused,
    textColor: locationColors.textUnused,
  });
  return valueBuckets;
}

/**
 * Get key of Linear Bucket by value
 * @param params
 * @returns string , represents bucket key in range 1 ... bucketCount
 */
export function getHeatmapLinearRangeBucketKey(
  params: LinearRangeBucketsParams & { value: number },
) {
  const valuesRange = params.maxValue - params.minValue;
  const bucketSize = valuesRange / params.bucketCount;
  const valueOffset = params.value - params.minValue;
  const bucketIndexCalc = Math.floor(valueOffset / bucketSize) + 1;
  const bucketIndex = Math.min(bucketIndexCalc, params.bucketCount);
  return bucketIndex.toString();
}

export function getHeatmapLinearRangeBucketByValue(
  params: LinearRangeBucketsParams & {
    value: number;
    buckets: Record<string, HeatmapBucket>;
  },
) {
  if (_.isNil(params.value)) return params.buckets[HEATMAP_ZERO_BUCKET_ID];
  if (params.value === 0 && _.has(params.buckets, HEATMAP_ZERO_BUCKET_ID)) {
    return params.buckets[HEATMAP_ZERO_BUCKET_ID];
  }
  const bucketKey = getHeatmapLinearRangeBucketKey(params);
  return params.buckets[bucketKey];
}

// Pow range buckets
export type GetHeatmapPowRangeBucketsParams = {
  maxValue: number;
  bucketCount: number;
  getColor: (bucketNum: number) => [string, string];
};

export function getHeatmapPowRangeBuckets(
  params: GetHeatmapPowRangeBucketsParams,
): HeatmapBucket[] {
  const { maxValue, bucketCount } = params;
  const valueBuckets: HeatmapBucket[] = _.range(1, bucketCount + 1)
    .reverse()
    .map(index => {
      const bucket = index - 1;
      const from = Math.ceil(Math.pow(maxValue, bucket / bucketCount));
      const to = Math.floor(Math.pow(maxValue, index / bucketCount));

      const title = `${formatInteger(from)} - ${formatInteger(to)}`;

      const [color, textColor] = params.getColor(index);
      return {
        id: bucket.toString(),
        index,
        title,
        from,
        to,
        color,
        textColor,
      };
    });

  const zeroValueBucket: HeatmapBucket = {
    id: HEATMAP_ZERO_BUCKET_ID,
    index: 0,
    title: '0',
    from: 0,
    to: 0,
    color: locationColors.unused,
    textColor: locationColors.textUnused,
  };
  return [...valueBuckets, zeroValueBucket];
}

export function getHeatmapPowRangeBucketKey(hit: number, maxHit: number) {
  const base = Math.pow(maxHit, 1 / 10);
  return Math.min(Math.floor(Math.log(hit) / Math.log(base)), 9);
}

export function getHeatmapPowRangeBucketByValue(
  hit: number,
  maxHit: number,
  buckets: Record<string, HeatmapBucket>,
) {
  if (_.isNil(hit)) return null;
  if (hit === 0) {
    return buckets[HEATMAP_ZERO_BUCKET_ID];
  }
  const bucketKey = getHeatmapPowRangeBucketKey(hit, maxHit);
  return buckets[bucketKey];
}

export function getHeatmapBuckets<T>(
  rangeDescriptor: HeatmapMetricRange,
  metricDescriptor: HeatmapMetricDescriptorBase<string, T>,
  namedColors?: NamedColors,
) {
  const buckets: HeatmapBucket[] = [];
  switch (rangeDescriptor.rangeType) {
    case 'distinct':
      buckets.push(
        ...getHeatmapNamedBuckets({
          keys: rangeDescriptor.keys,
          getColor: v =>
            namedColors?.colors?.[v] ??
            getQualitativeColor(v, metricDescriptor.qualitativePaletteId),
          stats: rangeDescriptor.stats,
          levelStats: rangeDescriptor.levelStats,
        }),
      );
      break;
    case 'range-linear':
    case 'distinct-range':
      buckets.push(
        ...getHeatmapLinearRangeBuckets({
          minValue: rangeDescriptor.min,
          maxValue: rangeDescriptor.max,
          bucketCount: HEATMAP_BUCKETS_COUNT,
          getColor: bucketNum =>
            getSequentialColor(
              (100 * bucketNum) / HEATMAP_BUCKETS_COUNT,
              metricDescriptor.sequentialPaletteId,
            ),
        }),
      );
      break;

    case 'range-pow':
      buckets.push(
        ...getHeatmapPowRangeBuckets({
          bucketCount: HEATMAP_BUCKETS_COUNT,
          maxValue: rangeDescriptor.max,
          getColor: bucketNum =>
            getSequentialColor(
              (100 * bucketNum) / HEATMAP_BUCKETS_COUNT,
              metricDescriptor.sequentialPaletteId,
            ),
        }),
      );
      break;
  }
  return buckets;
}

export type GetHeatmapBucketByValueParams<T> = {
  data: T;
  metricDescriptor: HeatmapMetricDescriptorBase<string, T>;
  rangeDescriptor: HeatmapMetricRange;
  buckets: Record<string, HeatmapBucket>;
};

export function getHeatmapBucketByValue<T>(
  params: GetHeatmapBucketByValueParams<T>,
): HeatmapBucket {
  const value = _.get(params.data, params.metricDescriptor.path);
  switch (params.metricDescriptor.rangeType) {
    case 'distinct':
      return params.buckets[value];
    case 'range-linear':
    case 'distinct-range':
      return getHeatmapLinearRangeBucketByValue({
        value,
        minValue: params.rangeDescriptor.min,
        maxValue: params.rangeDescriptor.max,
        bucketCount: HEATMAP_BUCKETS_COUNT,
        buckets: params.buckets,
      });
    case 'range-pow':
      return getHeatmapPowRangeBucketByValue(
        value,
        params.rangeDescriptor.max,
        params.buckets,
      );
  }
}

export function getHeatmapMetricRangeFromData<T>(
  data: T[],
  metricDescriptor: HeatmapMetricDescriptorBase<string, T>,
): HeatmapMetricRange {
  let keys: string[] = null;
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  switch (metricDescriptor.rangeType) {
    case 'distinct':
      keys = _(data)
        .map(item => _.get(item, metricDescriptor.path)?.toString())
        .compact()
        .uniq()
        .value();
      break;

    case 'range-pow':
    case 'range-linear':
      _(data).forEach(item => {
        const value = _.get(item, metricDescriptor.path);
        if (!isNaN(value)) {
          max = Math.max(max, value);
          min = Math.min(min, value);
        }
      });

      break;
  }

  return {
    rangeType: metricDescriptor.rangeType,
    keys,
    min,
    max,
  };
}

export function getAnalyzeProductHeatmapMetricRange(
  metricDescriptor: AnalyzeProductMetricDescriptorBase,
  summaries: AnalyzeResultFragment[],
  data: AnalyzeProductDataRow[],
): HeatmapMetricRange {
  let keys: string[] = null;
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  switch (metricDescriptor.type) {
    case 'visits':
      min = 0;
      max =
        _.maxBy(summaries, ar => ar?.maxLocationHitCount)
          ?.maxLocationHitCount ?? 0;
      break;
    case 'abc':
      keys = [...productCategories];
      break;

    case 'applied-reorder':
      min = 0;
      max =
        _.maxBy(summaries, ar => ar?.maxReorderAppliedSummary?.count)
          ?.maxReorderAppliedSummary?.count ?? 0;
      break;
    case 'triggered-reorder':
      min = 0;
      max =
        _.maxBy(summaries, ar => ar?.maxReorderTriggeredSummary?.count)
          ?.maxReorderTriggeredSummary?.count ?? 0;
      break;
    default:
      return getHeatmapMetricRangeFromData(data, metricDescriptor);
  }

  return {
    rangeType: metricDescriptor.rangeType,
    keys,
    min,
    max,
  };
}

export function getHeatmapBucketTitle<T extends string>(
  bucket: HeatmapBucket,
  metric: HeatmapMetricDescriptor<T, Record<T, any>>,
  defaultTitle: string,
) {
  const hasRangeTitle =
    !_.isNil(bucket.from) &&
    !_.isNil(bucket.to) &&
    metric.rangeType !== 'distinct';

  if (hasRangeTitle) {
    const formatter = metric.format ?? formatToPrecision;
    return `${formatter(bucket.from)} ... ${formatter(bucket.to)}`;
  }
  return bucket.title ?? defaultTitle;
}
