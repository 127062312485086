import { format, isWeekend, startOfDay } from 'date-fns';
import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { Line, Rect, Text } from 'react-konva';
import { formatHours } from '../../common/formatHelper';
import { AgentWorkforceSimulationSettingsItem } from '../../simulation/store/workforce.types';
import { ticksInPixel } from '../viewer/layers/JobsTimelineLayer';
import { jobFeatureHeight } from './FeedJobFeature';

// const width = 24 * 60; //24h*60min
// const height = width / 3;
// const inlineTickHeight = 1.1 * height;
// const inlineMajorTickHeight = 1.2 * height;
// const margin = 0.1 * height;
const colorEven = 'rgba(60,69,75,0.3)';
const colorOdd = 'rgba(60,69,75,0.2)';
// const colorOdd = 'rgba(43,48,52,0.75)';

export type TimelineFeatureProps = {
  day: Date;
  index: number;
  scheduleSettings?: AgentWorkforceSimulationSettingsItem;
  hourCount?: number;
  maxShiftCount: number;
  rowCount: number;
  scale?: number;
};

const TimelineFeature: React.FC<TimelineFeatureProps> = props => {
  const ticksCount = 24;
  const rowsCount = props.rowCount ?? 0;
  const width = 24 * 60; //24h*60min
  const height = width / 3;
  const inlineTickHeight = 1.1 * height;
  const inlineMajorTickHeight = 1.2 * height;
  const margin = 0.1 * height;

  const d = startOfDay(props.day);
  const weekend = isWeekend(d);

  const id = d.toString();
  const x = d.getTime() / ticksInPixel;
  const y = 0;
  const fill = props.index % 2 === 1 ? colorOdd : colorEven;
  const tickOffset = width / ticksCount;

  const xMax = x + width;
  const strokeWidth = 5;

  const isDefaultSchedule =
    _.isNil(props.scheduleSettings) || props.scheduleSettings.isDefault;

  const configDay: Konva.RectConfig = {
    id: `day-${d}`,
    x,
    y,
    width,
    height: rowsCount * jobFeatureHeight,
    fill,
    fillEnabled: true,
  };

  const shiftPlaceholderHeight = 0.2 * height;

  const borderTickHeight =
    rowsCount * jobFeatureHeight +
    0.5 * height +
    shiftPlaceholderHeight * props.maxShiftCount;

  const configShiftPlaceholders = _.range(0, props.maxShiftCount).map(i => {
    const shiftIndexOffset = shiftPlaceholderHeight * i;
    const configShiftPlaceholder: Konva.RectConfig = {
      id: `day-${d}`,
      x,
      y: -0.7 * height - shiftIndexOffset,
      width,
      height: shiftPlaceholderHeight,
      fill: i % 2 ? 'rgba(60,69,75,0.4)' : 'rgba(60,69,75,0.8)',
      // opacity: 0.3,
      fillEnabled: true,
    };
    return configShiftPlaceholder;
  });

  const dayEdgeTicks = props.index === 0 ? [x, xMax] : [xMax];

  const dayEdgeTicksConfig: Konva.LineConfig[] = _.map(dayEdgeTicks, x => ({
    id: `axis-tick-${x}`,
    points: [
      x,
      rowsCount * jobFeatureHeight,
      x,
      rowsCount * jobFeatureHeight - borderTickHeight,
    ],
    strokeWidth: strokeWidth,
    stroke: '#B3BBC1',
    strokeScaleEnabled: true,
    perfectDrawEnabled: true,
  }));

  const inlineTicks = _.range(x, xMax, tickOffset);
  const majorFactor = Math.round(ticksCount / 6);
  const inlineTicksConfig: Konva.LineConfig[] = _(inlineTicks)
    .map((x, i) => {
      if (i === 0) return null;
      const isMajorTick = i % majorFactor === 0;
      const tickHeight = isMajorTick ? inlineMajorTickHeight : inlineTickHeight;
      return {
        id: `inline-tick-${x}`,
        points: [x, rowsCount * jobFeatureHeight, x, height - tickHeight],
        strokeWidth: strokeWidth,
        stroke: '#B3BBC1',
        opacity: isMajorTick ? 1 : 0.2,
        strokeScaleEnabled: true,
      };
    })
    .compact()
    .value();

  const ticksLabelConfig: Konva.TextConfig[] = _(inlineTicks)
    .map((x, i) => {
      const isMajorTick = i > 0 && i % majorFactor === 0;
      if (!isMajorTick) return null;
      return {
        id: `inline-tick-label-${x}`,
        x: x - 2 * tickOffset,
        y: -0.25 * height,
        width: 4 * tickOffset,
        height: 0.04 * height,
        fontSize: 0.075 * height,
        fontFamily: 'NeoSans-Regular',
        text: formatHours(new Date(x * ticksInPixel)),
        fill: 'rgba(255,255,255,0.7)',
        scaleY: -1,
        align: 'center',
      };
    })
    .compact()
    .value();

  const ticks = [...dayEdgeTicksConfig, ...inlineTicksConfig];

  const dayTitleY =
    -0.6 * height - shiftPlaceholderHeight * props.maxShiftCount;

  const dayOfWeekBoxHeight = 0.125 * height;
  const dayOfWeekTitleY = dayTitleY + 0.15 * dayOfWeekBoxHeight;
  const configDayTitle: Konva.TextConfig = {
    x,
    y: dayTitleY,
    width,
    height,
    fontSize: 0.135 * height,
    fontFamily: 'NeoSans-Regular',
    text: format(d, 'dd MMM'), //{format(wave.forecast.to, 'MMM dd hh:mm')}
    fill: 'rgba(255,255,255,0.8)',
    scaleY: -1,
    align: 'center',
    //verticalAlign: 'top',
  };

  const configDayOfWeekShape: Konva.RectConfig = {
    x: x + 500,
    y: dayOfWeekTitleY,
    width: 100,
    height: dayOfWeekBoxHeight,
    // fill: getDimensionColor(idETA)?.[0],
    strokeScaleEnabled: true,
    stroke: 'rgba(255,255,255,0.5)',
    strokeWidth: 2,
    perfectDrawEnabled: false,
    cornerRadius: [12, 12, 12, 12],
    // fillEnabled: true,
    opacity: weekend ? 0.5 : 0.7,
    align: 'center',
    scaleY: -1,
  };

  const configDayOfWeekTitle: Konva.TextConfig = {
    x: x + 500,
    y: dayOfWeekTitleY,
    width: 100,
    height: dayOfWeekBoxHeight,
    fontSize: 0.075 * Math.abs(height),
    fontFamily: 'NeoSans-Regular',
    text: format(d, 'EE'),
    fill: weekend ? 'rgb(255 204 0 / 60%)' : 'rgba(255,255,255,0.7)',
    scaleY: -1,
    align: 'center',
    //padding: 10,
    strokeWidth: 1,
    verticalAlign: 'middle',
  };

  return (
    <React.Fragment key={id}>
      {configShiftPlaceholders.map(c => (
        <Rect key={`placeholder-${c.id}`} {...c} />
      ))}
      {_.map(ticks, c => (
        <Line key={`tick-${c.id}`} {...c} />
      ))}

      <Rect {...configDay} />

      {_.map(ticksLabelConfig, c => (
        <Text {...c} />
      ))}

      <Text {...configDayTitle} />
      <Rect {...configDayOfWeekShape} />
      <Text {...configDayOfWeekTitle} />
    </React.Fragment>
  );
};

export default TimelineFeature;
