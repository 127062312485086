import { SimulationDashboardItemFragment } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { Card } from '../../components/cards/Card';
import { DashboardSimulationItemStatus } from '../../dashboard/store/dashboard.types';
import SimulationAllocatedCardContent from './SimulationAllocatedCardContent';
import SimulationAnalyzedCardContent from './SimulationAnalyzedCardContent';
import SimulationDraftCardContent from './SimulationDraftCardContent';
import SimulationOptimisedCardContent from './SimulationOptimisedCardContent';

export type SimulationDashboardCardProps = {
  sim: SimulationDashboardItemFragment;
  status: DashboardSimulationItemStatus;
  showAdvancedStats?: boolean;
};

const SimulationDashboardCard: React.FC<
  SimulationDashboardCardProps
> = props => {
  function CardContent() {
    switch (props.status.simulationStage) {
      case 'draft':
      case 'analysing':
      case 'allocating':
      case 'analyseError':
        return <SimulationDraftCardContent {...props} />;
      case 'allocated':
        return <SimulationAllocatedCardContent {...props} />;
      case 'allocationError':
        return <SimulationAllocatedCardContent {...props} />;
      case 'analyzed':
      case 'optimising':
      case 'optimiseError':
        return <SimulationAnalyzedCardContent {...props} />;
      case 'optimised':
        return <SimulationOptimisedCardContent {...props} />;
    }
  }
  return (
    <Card
      transparent
      isActionable
      label={`SimulationDashboardCard-${props.status.simulationStage}`}
    >
      <CardContent />
    </Card>
  );
};

export default SimulationDashboardCard;
