import * as React from 'react';
const CircleHelpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleHelpIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,14c-27.57,0-50,22.43-50,50s22.43,50,50,50,50-22.43,50-50S91.57,14,64,14ZM67.85,92.85c0,2.12-1.72,3.85-3.85,3.85s-3.85-1.72-3.85-3.85v-3.85c0-2.12,1.72-3.85,3.85-3.85s3.85,1.72,3.85,3.85v3.85ZM73.37,61.24c-3.41,2.2-5.53,6.32-5.53,10.75v5.46c0,2.12-1.72,3.85-3.85,3.85s-3.85-1.72-3.85-3.85v-5.46c0-7.13,3.38-13.56,9.05-17.22,2.81-1.81,4.46-4.89,4.41-8.23-.07-5.07-4.41-9.41-9.48-9.48-2.63-.13-5.04.95-6.89,2.77-1.85,1.82-2.86,4.25-2.86,6.85,0,2.12-1.72,3.85-3.85,3.85s-3.85-1.72-3.85-3.85c0-4.67,1.83-9.04,5.16-12.32,3.26-3.22,7.57-4.98,12.15-4.98.08,0,.17,0,.25,0,9.28.13,16.93,7.78,17.06,17.06.08,6.01-2.88,11.54-7.93,14.8Z"
    />
  </svg>
);
export default CircleHelpIcon;
