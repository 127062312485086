import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';

@InputType()
export class MarkOrderSetImportedInput {
  @Field(() => ID)
  orderSetId: string;
}
