import Flatten from '@flatten-js/core';
import Konva from 'konva';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useRef, useState } from 'react';
import { Group, KonvaNodeEvents, Layer, Line, Transformer } from 'react-konva';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TwTheme } from '../../../../../Tw';
import { analyticsAgent } from '../../../../AnalyticTracker';
import AreaActionsFeature from '../../../../layout/features/AreaActionsFeature';
import { stageStateById } from '../../../../layout/stage/stage.state';
import { ConvertedAreaFeature } from '../converter.serializable.model';
import useCanvasImage from '../hooks/useLockImage';
import {
  converterAreaConfiguration,
  converterDraggableAreaIdAtom,
  converterEditableArea,
  converterEditableAreaId,
  converterFloorAreaBoxesMap,
  converterFloorAreas,
  converterSelectedArea,
  converterSelectedFloor,
} from '../store/converter.area.state';
import {
  converterBayTypesBuilder,
  converterGuidelineMode,
  converterWizardStepId,
} from '../store/converter.state';
import {
  TransformParams,
  getTransformationMatrix,
} from '../store/transformation.helper';
import ConverterAreaContent, {
  ConverterAreaContentProps,
} from './ConverterAreaGroup';
import ConverterPortalByArea from './ConverterPortalByArea';

const GUIDELINE_OFFSET = 10;
const areaColors = TwTheme.extend.colors.area;

const IconRefresh =
  'm19,64c0,24.75,20.25,45,45,45v15s30-22.5,30-22.5l-30-22.5v15c-16.65,0-30-13.35-30-30h-15Zm15-37.5l30,22.5v-15c16.65,0,30,13.35,30,30h15c0-24.75-20.25-45-45-45V4s-30,22.5-30,22.5Z';
const IconLocked =
  'm89.26,52.42v-24c0-13.47-11.81-24.42-26.32-24.42s-26.32,10.96-26.32,24.42v24h-18.95v71.58h92.63V52.42h-21.05Zm-48.42-24c0-11.15,9.92-20.21,22.11-20.21s22.11,9.07,22.11,20.21v24h-44.21v-24Zm-18.95,70.31h21.05l-21.05,21.05v-21.05Zm21.05,21.05l21.05-21.05h27.37l-21.05,21.05h-27.37Zm63.16,0h-14.74l14.74-14.74v14.74Z';

// Area Selector / Area Transform border
const trConfig: Konva.TransformerConfig = {
  centeredScaling: true,
  rotationSnaps: [0, 90, 180, 270],
  rotationSnapTolerance: 45,
  resizeEnabled: false,
  anchorStroke: areaColors.control_border,
  anchorFill: areaColors.control_border,
  borderStroke: areaColors.control_border,
  borderStrokeWidth: 5,
  anchorSize: 20,
  rotateAnchorCursor: 'pointer',
};

type GuideConfig = {
  vertical: number[];
  horizontal: number[];
};

const ConverterAreaLayer: React.FC = () => {
  const floor = useRecoilValue(converterSelectedFloor);
  const areas = useRecoilValue(converterFloorAreas);
  const [areasConfiguration, setAreaConfiguration] = useRecoilState(
    converterAreaConfiguration,
  );
  const [selectedArea, setSelectedArea] = useRecoilState(converterSelectedArea);
  const editableArea = useRecoilValue(converterEditableArea);
  const setEditableAreaId = useSetRecoilState(converterEditableAreaId);
  const areaBoxes = useRecoilValue(converterFloorAreaBoxesMap);
  // const validationResult = useRecoilValue(
  //   converterSelectedFloorValidationResult,
  // );
  const bayTypes = useRecoilValue(converterBayTypesBuilder);
  const guidelineMode = useRecoilValue(converterGuidelineMode);

  const [lineGuideStops, setLineGuideStops] = useState<GuideConfig>(null);

  const [dragStartX, setDragStartX] = useState<number>();
  const [dragStartY, setDragStartY] = useState<number>();
  const [dragAreaPoints, setDragAreaPoints] = useState<Flatten.Point[]>();
  const [guides, setGuides] = useState([]);
  const [stageState, setStageState] = useRecoilState(
    stageStateById('converter-area-view'),
  );
  const setDraggableAreaId = useSetRecoilState(converterDraggableAreaIdAtom);
  const step = useRecoilValue(converterWizardStepId);
  const snapOffset = GUIDELINE_OFFSET / stageState.scale;
  const areaRef = useRef<Konva.Group>(null);
  const transformerRef = useRef(null);

  const [adjustedFloor, setAdjustedFloor] = useState(null);
  const lockImage = useCanvasImage({
    path: IconLocked,
    pathSize: 128,
    targetSize: 20,
    color: areaColors.control_border,
  });

  const refreshImage = useCanvasImage({
    path: IconRefresh,
    pathSize: 128,
    targetSize: 20,
    color: areaColors.control_border,
  });

  function onAreaDblClick(area) {
    analyticsAgent?.track('Area: Selected (DoubleClick)', {
      id: area?.id,
      title: area?.title,
      viewer: 'main',
    });
    setSelectedArea(area);
    setEditableAreaId(area.id);
  }

  React.useEffect(() => {
    if (selectedArea && transformerRef.current) {
      // we need to attach transformer manually
      transformerRef.current.nodes([areaRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedArea, floor]);

  React.useEffect(() => {
    if (adjustedFloor !== floor) {
      if (_.isEmpty(areas)) return;

      const xMin = _.min(areas.map(a => areasConfiguration[a.id].x));
      const xMax = _.max(
        areas.map(a => areasConfiguration[a.id].x + a.size[0]),
      );
      const yMin = _.min(areas.map(a => areasConfiguration[a.id].y));
      const yMax = _.max(
        areas.map(a => areasConfiguration[a.id].y + a.size[1]),
      );

      setAdjustedFloor(floor);
      setStageState({
        autoSizeId: nanoid(),
        contentBounds: [
          [xMin, yMin],
          [xMax, yMax],
        ],
      });
    }
  }, [areas, areasConfiguration, setStageState, adjustedFloor, floor]);

  if (_.isEmpty(areas) || _.isNil(areasConfiguration) || _.isNil(bayTypes))
    return null;

  const showValidation =
    step === 'designer' ||
    step === 'connect-areas' ||
    step !== 'define-starting-points';

  const unreachableBays = new Set(
    [],
    // showValidation ? validationResult?.unreachableBayIds || [] : [],
  );

  const prepareDrag = (draggableArea: ConvertedAreaFeature) => {
    const vertical: number[] = [];
    const horizontal: number[] = [];

    areas.forEach(area => {
      const config = areasConfiguration[area.id];

      const transformParams: TransformParams = {
        center: Flatten.point(area.size[0] / 2, area.size[1] / 2),
        offset: Flatten.point(config.x, config.y),
        flipX: config.flipX,
        flipY: config.flipY,
        rotation: config.rotation,
      };

      let points: Flatten.Point[] = [];

      const transformMatrix = getTransformationMatrix(transformParams);
      if (guidelineMode === 'full') {
        points = _((area.outlineShape as GeoJSON.Polygon).coordinates)
          .flatten()
          .map(p => new Flatten.Point(p[0], p[1]).transform(transformMatrix.m))
          .value();
      }
      if (guidelineMode === 'box') {
        points = [
          new Flatten.Point(area.boundingBox[0], area.boundingBox[1]).transform(
            transformMatrix.m,
          ),
          new Flatten.Point(area.boundingBox[4], area.boundingBox[5]).transform(
            transformMatrix.m,
          ),
        ];
      }
      if (area.id === draggableArea.id) {
        setDragAreaPoints(points);
      } else {
        vertical.push(...points.map(p => p.x));
        horizontal.push(...points.map(p => p.y));
      }
    });

    setLineGuideStops({
      vertical: _.uniq(vertical),
      horizontal: _.uniq(horizontal),
    });
    setDraggableAreaId(draggableArea.id);
  };

  const getObjectSnappingEdges = (node: Konva.Node): GuideConfig => {
    const { x, y } = node.position();
    const dx = x - dragStartX;
    const dy = y - dragStartY; // invert axis

    return {
      vertical: _.uniq(dragAreaPoints.map(p => p.x + dx)),
      horizontal: _.uniq(dragAreaPoints.map(p => p.y + dy)),
    };
  };

  const getGuides = (lineGuideStops: GuideConfig, itemBounds: GuideConfig) => {
    const resultV = [];
    const resultH = [];

    lineGuideStops.vertical.forEach(guideX => {
      itemBounds.vertical.forEach(boundX => {
        const diff = guideX - boundX;
        if (Math.abs(diff) < snapOffset) {
          resultV.push({
            lineGuide: guideX,
            diff: diff,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach(guideY => {
      itemBounds.horizontal.forEach(boundY => {
        const diff = guideY - boundY;
        if (Math.abs(diff) < snapOffset) {
          resultH.push({
            lineGuide: guideY,
            diff: diff,
          });
        }
      });
    });
    const guides = [];

    // find closest snap
    const minV = _.minBy(resultV, guide => Math.abs(guide.diff));
    const minH = _.minBy(resultH, guide => Math.abs(guide.diff));

    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.diff,
        orientation: 'V',
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.diff,
        orientation: 'H',
      });
    }
    return guides;
  };

  const drawGuides = () => {
    const configs: Konva.LineConfig[] = _.map(guides, lg => {
      const guideLength = 1000000;
      const x = lg.orientation === 'H' ? null : lg.lineGuide;
      const y = lg.orientation === 'V' ? null : lg.lineGuide;
      const config: Konva.LineConfig = {
        key: `guideline-${x}-${y}`,
        points: [
          x ?? -guideLength,
          y ?? -guideLength,
          x ?? guideLength,
          y ?? guideLength,
        ],
        stroke: TwTheme.extend.colors.guides.area,
        strokeWidth: 4,
        strokeScaleEnabled: false,
        listening: false,
        dash: [12, 3],
      };
      return config;
    });

    return _.map(configs, guideConfig => {
      return <Line key={guideConfig.key} {...guideConfig} />;
    });
  };

  const drawArea = (area: ConvertedAreaFeature) => {
    const [areaWidth, areaHeight] = area.size;

    const areaConfig = areasConfiguration[area.id];

    const eventConfig: KonvaNodeEvents = {
      onClick: e => {
        if (!editableArea) {
          setTimeout(
            () => setSelectedArea(area.id === selectedArea?.id ? null : area),
            200,
          );
        }
      },
      onTap: e => {
        if (!editableArea) {
          setSelectedArea(area.id === selectedArea?.id ? null : area);
        }
      },
      onDblClick: e => {
        onAreaDblClick(area);
      },
      onDblTap: e => onAreaDblClick(area),
      onMouseEnter: e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
      },
      onMouseLeave: e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
      },
      onDragStart: e => {
        const rect = e.target.position();
        setDragStartX(rect.x);
        setDragStartY(rect.y);
        prepareDrag(selectedArea);
      },
      onDragMove: e => {
        const node = e.target;

        const itemBounds = getObjectSnappingEdges(node);
        const guides = getGuides(lineGuideStops, itemBounds);
        setGuides(guides);
        const xOffset = guides.find(g => g.orientation === 'V')?.offset ?? 0;
        const yOffset = guides.find(g => g.orientation === 'H')?.offset ?? 0;
        let { x, y } = node.position();
        x = xOffset === 0 ? Math.round(x) : x + xOffset;
        y = yOffset === 0 ? Math.round(y) : y + yOffset;
        node.position({ x, y });
      },
      onDragEnd: e => {
        setGuides([]);
        setDraggableAreaId(null);
        setAreaConfiguration({
          ...areasConfiguration,
          [area.id]: {
            ...areaConfig,
            x: e.target.x() - areaWidth / 2,
            y: e.target.y() - areaHeight / 2,
          },
        });
      },
      onTransform: e => {
        const group = areaRef.current;
        const rotation = Math.round(group.rotation());
        if (areaConfig.rotation !== rotation) {
          setAreaConfiguration({
            ...areasConfiguration,
            [area.id]: {
              ...areaConfig,
              rotation,
            },
          });
        }
      },
    };
    const flipXModifier = areaConfig.flipX ? -1 : 1;
    const flipYModifier = areaConfig.flipY ? -1 : 1;

    const contentConfig: ConverterAreaContentProps = {
      area,
      bayTypes,
    };

    return (
      <Group
        key={`area-${area.id}`}
        {...eventConfig}
        draggable={
          !editableArea &&
          area.id === selectedArea?.id &&
          _.isNil(areasConfiguration[selectedArea.id].link)
        }
        ref={area.id === selectedArea?.id ? areaRef : null}
        x={areaConfig.x + areaWidth / 2}
        y={areaConfig.y + areaHeight / 2}
        rotation={areaConfig.rotation}
        opacity={editableArea && editableArea?.id !== area.id ? 0.5 : 1} // Inactive Area Opacity when edit mode
        offsetX={areaWidth / 2}
        offsetY={areaHeight / 2}
        scaleX={flipXModifier}
        scaleY={flipYModifier}
      >
        <ConverterAreaContent {...contentConfig} />
        <ConverterPortalByArea area={area} />
      </Group>
    );
  };

  const isSelectedAreaLocked =
    !_.isNil(selectedArea?.id) &&
    !_.isNil(areasConfiguration[selectedArea?.id]?.link?.masterAreaId);

  const drawDefaultAnchor = (anchor: Konva.Rect) => {
    anchor.fillPriority('color');
    anchor.strokeEnabled(true);
    anchor.offsetY(20);
  };

  const drawLockedAnchor = (anchor: Konva.Rect) => {
    anchor.fillPriority('pattern');
    anchor.fillPatternImage(lockImage);
    anchor.strokeEnabled(false);
    anchor.offsetY(22);
  };
  const drawRefreshAnchor = (anchor: Konva.Rect) => {
    anchor.fillPriority('pattern');
    anchor.fillPatternImage(refreshImage);
    anchor.strokeEnabled(false);
    anchor.offsetY(22);
  };

  return (
    <Layer key={`floor-layer-${floor}-${selectedArea?.id}`}>
      {_(areas)
        .filter(a => a?.id !== editableArea?.id)
        .orderBy(a => areasConfiguration[a.id]?.zIndex ?? 0)
        .map(drawArea)
        .value()}
      {selectedArea && !editableArea && (
        <Transformer
          ref={transformerRef}
          key={`transformer-${selectedArea?.id}`}
          {...trConfig}
          borderDash={isSelectedAreaLocked ? [3, 2] : null}
          borderStrokeWidth={isSelectedAreaLocked ? 3 : 4}
          anchorStyleFunc={
            isSelectedAreaLocked ? drawLockedAnchor : drawRefreshAnchor //  drawDefaultAnchor
          }
        />
      )}
      {_(areaBoxes)
        .orderBy(a => areasConfiguration[a.areaId]?.zIndex ?? 0)
        .map(box => (
          <AreaActionsFeature
            key={`area-box-${box.areaId}`}
            areaBox={box}
            areaConfig={areasConfiguration[box.areaId]}
          />
        ))
        .value()}
      {drawGuides()}
    </Layer>
  );
};

export default ConverterAreaLayer;
