import {
  QueryDatasetDocument,
  QueryDatasetQuery,
  QueryDatasetQueryVariables,
  WarehouseMetaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { postProcessDatasetQuery } from '@warebee/shared/export-converter';
import { startOfDay } from 'date-fns';
import { CompiledQuery } from 'kysely';
import _ from 'lodash';
import { secureClient } from '../../GraphQLClient';
import { parseJSON } from '../../common/utils';
import { AgentSettingsWithMeta } from '../../resourcePolicy/agentData/agent.types';
import { createAgent } from '../../simulation/store/resourcePolicy.helper';
import { FeedAggregatedEvents } from './datasetQueries/feedAggregatedEvents';
import { FeedLatestEvent } from './datasetQueries/feedLatestEvents';
import { FeedPicklists } from './datasetQueries/feedPicklists';
import {
  FeedActivityAggregateBy,
  FeedAgentIntervalStat,
  FeedAgentIntervalStats,
  FeedAgentStats,
  FeedStats,
} from './feed.types';

export type FeedPicklistLineScan = FeedLatestEvent & {
  picklistId: string;
  lineIndex: number;
  duration: number;
};

export type FeedPicklistLine = {
  picklistId: string;
  lineIndex: number;
  consignee: string;
  sku: string;
  quantity: number;
  uom: string;
  locationId: string;
  startTime: number;
  endTime: number;
  receiveTime: number;
  duration: number;
  durationPrevLine: number;
  scans: FeedLatestEvent[];
};

// export type FeedPicklist = {
//   id: string;
//   agent: string;
//   lines: FeedPicklistLine[];
//   startTime: number;
//   receiveStartTime: number;
//   endTime: number;
//   duration: number;
//   unknownDuration: number;
//   offsetTime?: number;
//   origin: 'data' | 'evaluated';
//   analyzedDistance?: number;
//   analyzedDuration?: number;
//   analyzedCost?: number;
//   analyzedLinesCount?: number;
// };

export const delayDuration = 20 * 60 * 1000; //20min
export const cutOffDuration = 5 * 60 * 1000; //5 min
export const errorDuration = 3 * 3600 * 1000; //3 h

export function getItemKey(item: { consignee: string; sku: string }): string {
  return `${item.consignee}-${item.sku}`;
}

// function createFeedPicklist(
//   agent: string,
//   startTime: number,
//   receiveStartTime: number,
//   unknownDuration: number,
// ): FeedPicklist {
//   return {
//     id: nanoid(8),
//     agent,
//     lines: [],
//     startTime,
//     receiveStartTime,
//     endTime: null,
//     duration: 0,
//     unknownDuration,
//     origin: 'evaluated',
//   };
// }

// function createOriginalPicklists(events: FeedLatestEvent[]): FeedPicklist[] {
//   // create picklist for events where jobId and jobLine present in data

//   const picklists = _(events)
//     .groupBy(e => `${e.jobId}-${e.agentId}`)
//     .map((plEvents, picklistId) => {
//       const sorted = _.orderBy(
//         plEvents,
//         ['eventEndTime', 'eventStartTime'],
//         ['asc', 'asc'],
//       );
//       const first = _.head(plEvents);
//       const last = _.last(plEvents);
//       const picklist: FeedPicklist = {
//         id: first.jobId,
//         agent: first.agentId,
//         origin: 'data',
//         startTime: first.eventEndTime.getTime(),
//         receiveStartTime: first.eventEndTime.getTime(),
//         endTime: last.eventEndTime.getTime(),
//         duration: last.eventEndTime.getTime() - first.eventEndTime.getTime(),
//         unknownDuration: 0,
//         offsetTime: 0,
//         lines: _.map(
//           sorted,
//           (e, index) =>
//             ({
//               picklistId: e.jobId,
//               lineIndex: index,
//               consignee: e.consignee,
//               sku: e.sku,
//               quantity: e.quantity,
//               uom: e.uom,
//               locationId: e.locationId,
//               startTime:
//                 e.eventStartTime?.getTime() ?? e.eventEndTime.getTime(),
//               endTime: e.eventEndTime.getTime(),
//               receiveTime: e.eventEndTime.getTime(),
//               duration: 0,
//               durationPrevLine: e.previousStartTime
//                 ? e.eventEndTime.getTime() - e.previousStartTime.getTime()
//                 : null,
//               scans: [e],
//             }) as FeedPicklistLine,
//         ),
//       };
//       return picklist;
//     })
//     .value();

//   return picklists;
// }

// export function createPicklist(events: FeedLatestEvent[]): FeedPicklist[] {
//   const eventsWithPicklist = _.filter(events, e => !_.isNil(e.jobId));
//   const eventsToGenerate = _(events)
//     .filter(e => _.isNil(e.jobId))
//     .orderBy(['eventEndTime', 'eventStartTime'], ['asc', 'asc'])
//     .value();

//   const picklists: FeedPicklist[] = createOriginalPicklists(eventsWithPicklist);
//   let currentPl: FeedPicklist = null;
//   let plLines: FeedPicklistLine[] = [];
//   let lineIndex = 0;

//   _.forEach(eventsToGenerate, (e, index) => {
//     const isLast = index === eventsToGenerate.length - 1;

//     const duration = e.previousStartTime
//       ? e.eventEndTime.getTime() - e.previousStartTime.getTime()
//       : null;

//     if (_.isNil(currentPl) || duration > cutOffDuration) {
//       //  finalize previous pl
//       if (!_.isNil(currentPl)) {
//         const offset = _(plLines)
//           .map(l => l.receiveTime - l.startTime)
//           .min();
//         const lastLine = _.last(plLines);
//         const durationPl = lastLine.endTime - currentPl.startTime;
//         const durationPlReceived =
//           lastLine.receiveTime - currentPl.receiveStartTime;
//         currentPl.lines = plLines;
//         currentPl.endTime = lastLine.endTime;
//         currentPl.duration =
//           durationPl < 0 || durationPl > errorDuration
//             ? durationPlReceived
//             : durationPl;
//         currentPl.offsetTime = offset;
//         picklists.push(currentPl);
//       }

//       // create new pl, reset state
//       currentPl = createFeedPicklist(
//         e.agentId,
//         (e.eventStartTime ?? e.eventEndTime).getTime(),
//         e.eventEndTime.getTime(),
//         duration,
//       );
//       plLines = [];
//       lineIndex = 0;
//     }
//     const prevLine = _.last(plLines);
//     if (e.sku === prevLine?.sku && e.locationId === prevLine?.locationId) {
//       //pick same item from same location
//       prevLine.scans.push(e);
//       prevLine.quantity += 1;
//       prevLine.endTime = (e.eventStartTime ?? e.eventEndTime).getTime();
//       //prevLine.durationPrevLine += duration;
//     } else {
//       // create new picklistLine

//       plLines.push({
//         picklistId: currentPl.id,
//         lineIndex: lineIndex++,
//         consignee: e.consignee,
//         sku: e.sku,
//         quantity: 1,
//         uom: e.uom,
//         locationId: e.locationId,
//         startTime: (e.eventStartTime ?? e.eventEndTime).getTime(),
//         endTime: (e.eventStartTime ?? e.eventEndTime).getTime(),
//         receiveTime: e.eventEndTime.getTime(),
//         durationPrevLine: duration,
//         duration: 0,
//         scans: [e],
//       });
//     }

//     if (isLast) {
//       const offset = _(plLines)
//         .map(l => l.receiveTime - l.startTime)
//         .min();
//       const lastLine = _.last(plLines);
//       const durationPl = lastLine.endTime - currentPl.startTime;
//       const durationPlReceived =
//         lastLine.receiveTime - currentPl.receiveStartTime;
//       currentPl.lines = plLines;
//       currentPl.endTime = lastLine.endTime;
//       currentPl.duration =
//         durationPl < 0 || durationPl > errorDuration
//           ? durationPlReceived
//           : durationPl;
//       currentPl.offsetTime = offset;
//       picklists.push(currentPl);
//     }
//   });
//   console.debug('picklists::', picklists);
//   return picklists;
// }

export function getAggregatedStats(
  picklistByAggregate: Record<string, FeedPicklists>,
  [from, to]: [Date, Date],
  shiftCutOf: number,
): FeedAgentStats[] {
  return _(picklistByAggregate)
    .map((agentLists, agentId) => {
      const picklistCount = _.size(agentLists);
      const totalLines = _.sumBy(agentLists, p =>
        _.isNumber(p.lines) ? p.timeFramedEventsCount : 0,
      );
      const totalUoms = _.sumBy(agentLists, l =>
        _.isNumber(l.timeFramedUomCount) ? l.timeFramedUomCount : 0,
      );

      const netTime = _.sumBy(agentLists, p => {
        const max = Math.max(
          from.getTime(),
          Math.min(to.getTime(), p.maxEventTime.getTime()),
        );
        const min = Math.min(
          to.getTime(),
          Math.max(from.getTime(), p.minEventTime.getTime()),
        );
        return max - min;
      });
      const netHours = netTime / (3600 * 1000);

      const idleTime = _.sumBy(agentLists, p => {
        if (_.isNil(p.minPreviousEndTime)) return 0;
        const idleRaw =
          Math.max(from.getTime(), p.minEventTime.getTime()) -
          Math.max(from.getTime(), p.minPreviousEndTime.getTime());
        return idleRaw > shiftCutOf * (3600 * 1000) ? 0 : idleRaw;
      });

      const grossTime = idleTime + netTime;
      const grossHours = grossTime / (3600 * 1000);

      const netLinesPerformance = netHours > 0 ? totalLines / netHours : 0;
      const netUomPerformance = netHours > 0 ? totalUoms / netHours : 0;
      const grossLinePerformance = grossHours > 0 ? totalLines / grossHours : 0;
      const grossUomPerformance = grossHours > 0 ? totalUoms / grossHours : 0;

      const stats: FeedAgentStats = {
        agentId,
        picklistCount,
        totalLines,
        totalUoms,
        netLinesPerformance,
        netUomPerformance,
        grossLinePerformance,
        grossUomPerformance,
        netTime,
        grossTime,
        idleTime: idleTime,
      };

      return stats;
    })
    .value();
}

export function getTotalStats(
  agentStats: FeedAgentStats[],
  [from, to]: [Date, Date],
): FeedStats {
  const duration = to.getTime() - from.getTime();
  const grossHours = duration / (3600 * 1000);

  // calc aggregative
  const totalStats = _.reduce(
    agentStats,
    (acc, agentStats) => {
      return {
        picklistCount: (acc.picklistCount ?? 0) + agentStats.picklistCount,
        totalLines: (acc.totalLines ?? 0) + agentStats.totalLines,
        totalUoms: (acc.totalUoms ?? 0) + agentStats.totalUoms,
        netLinesPerformance: 0,
        netUomPerformance: 0,
        grossLinePerformance: 0,
        grossUomPerformance: 0,
        netTime: (acc.netTime ?? 0) + agentStats.netTime,
        grossTime: (acc.grossTime ?? 0) + agentStats.grossTime,
        idleTime: (acc.idleTime ?? 0) + agentStats.idleTime,
      };
    },
    {} as FeedStats,
  );

  //calc averages
  const netHours = totalStats.netTime / (3600 * 1000);
  totalStats.netLinesPerformance =
    netHours > 0 ? totalStats.totalLines / netHours : 0;
  totalStats.netUomPerformance =
    netHours > 0 ? totalStats.totalUoms / netHours : 0;
  totalStats.grossLinePerformance =
    grossHours > 0 ? totalStats.totalLines / grossHours : 0;
  totalStats.grossUomPerformance =
    grossHours > 0 ? totalStats.totalUoms / grossHours : 0;

  return totalStats;
}

export type ExecuteDatasetQueryParams<TOut> = {
  warehouseId: string;
  compiledQuery: CompiledQuery<TOut>;
  comment: string;
};

export async function executeDatasetQuery<TOut>(
  params: ExecuteDatasetQueryParams<TOut>,
): Promise<TOut[]> {
  try {
    const formattedComment = _.padEnd(
      params.comment,
      120,
      String.fromCharCode(160),
    );
    const queryDescription = `
    -- ${formattedComment}
    -- warehouseId: '${params.warehouseId}' \n
    ----------------------------------- \n\n 
    ${_(params.compiledQuery.parameters)
      .map((p, i) => `-- ${i}: '${p}'`)
      .join(' \n')} 
    \n`;

    const response = await secureClient.query<
      QueryDatasetQuery,
      QueryDatasetQueryVariables
    >({
      query: QueryDatasetDocument,
      variables: {
        warehouseId: params.warehouseId,
        query: queryDescription + params.compiledQuery.sql,
        params: params.compiledQuery.parameters,
      },
    });
    const processed = postProcessDatasetQuery<TOut>(
      response.data?.warehouse?.datasetObjectQuery,
    ); //params.postProcess(raw);
    return processed;
  } catch (ex) {
    console.error(ex);
  }
}

// export function preparePicklists(
//   source: FeedPicklist[],
//   agentsTypesMap: Record<string, string>,
//   planeId: string,
// ): PicklistInput[] {
//   return _.map(source, pl => {
//     const plInput: PicklistInput = {
//       id: pl.id,
//       jobDate: toLocaleDateTimeString(new Date(pl.startTime)),
//       agents: [agentsTypesMap[pl.agent]],
//       orderIds: [pl.id],
//       planeId,
//       lines: _.map(pl.lines, l => {
//         const line: PicklistLineInput = {
//           itemId: btoa(JSON.stringify([l.consignee, l.sku])),
//           locationId: l.locationId,
//           picklistLine: l.lineIndex,
//           quantity: l.quantity,
//           uom: l.uom,
//           uomQuantity: l.quantity,
//           orderLines: [[pl.id, l.lineIndex]],
//         };
//         return line;
//       }),
//     };
//     return plInput;
//   });
// }

export function createFeedResources(
  agentTypes: string,
  uoms: string[],
  wh: WarehouseMetaFragment,
): AgentSettingsWithMeta[] {
  return _.map(agentTypes, agentType => {
    return createAgent({
      titles: [],
      titlePrefix: `${agentType}`,
      uoms: uoms,
      system: wh.measurementSystem,
      currencyCode: wh.currency,
    });
  });
}

const getIntervalDate = (d: Date, aggBy: FeedActivityAggregateBy) =>
  aggBy === 'day' ? startOfDay(d) : d;

export const getIntervalStatKey = (agentId: string, time: Date) =>
  `${agentId} - ${time.getTime()}`;

export function getAgentIntervalStats(
  events: FeedAggregatedEvents,
  aggBy: FeedActivityAggregateBy,
): FeedAgentIntervalStats {
  const byAgent = _.groupBy(events, e => e.agentId);
  const stats = _(byAgent)
    .map((agentEvents, agentId) => {
      const eventsByIntervals = _.reduce(
        agentEvents,
        (acc, e) => {
          const dataTime = new Date(e.eventHour);
          const dataDay = getIntervalDate(dataTime, aggBy);
          const key = getIntervalStatKey(e.agentId, dataDay);

          const current: FeedAgentIntervalStat = acc[key] ?? {
            intervalKey: key,
            intervalDate: dataDay,
            intervalsCount: 0,
            aggregateBy: aggBy,
            agentId: e.agentId,
            eventCount: 0,
            uomCount: 0,
            jobs: [],
          };

          const intervalTotals: FeedAgentIntervalStat = {
            ...current,
            eventCount: current.eventCount + e.eventCount,
            uomCount: current.uomCount + e.uomCount,
            intervalsCount: ++current.intervalsCount,
            jobIds: null,
            jobs: _.uniq([
              ...current.jobs,
              ...((parseJSON(e.jobIds) ?? []) as string[]),
            ]),
          };
          return {
            ...acc,
            [key]: intervalTotals,
          };
        },
        {} as Record<number, FeedAgentIntervalStat>,
      );
      return _.values(eventsByIntervals);
    })
    .flatten()
    .value();
  return stats;
}
