import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { HeatmapBucket } from '../../common/heatmap.helper';
import { HeatmapMetricDescriptor } from '../../common/heatmap.types';
import { HeatmapFilter } from '../../simulation/store/simulation.types';
import InboxZero from '../InboxZero';
import LoadingIndicator from '../LoadingIndicator';
import HeatmapBucketsListItem from './HeatmapBucketListItem';

export type HeatmapBucketsListProps = {
  buckets: HeatmapBucket[];
  heatmapFilter: HeatmapFilter;
  metricDescriptor: HeatmapMetricDescriptor<string, Object>;
  isLoading: boolean;
  onClick: (
    id: string,
    value: boolean,
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
};

const HeatmapBucketsList: React.FC<HeatmapBucketsListProps> = props => {
  const { t } = useTranslation('app');
  const anyDeselected = Object.values(props.heatmapFilter.hiddenBuckets).some(
    hidden => hidden,
  );

  if (_.isEmpty(props.buckets) && !props.isLoading) {
    return <InboxZero selfCenter hasIcon>{t`No Items`}</InboxZero>;
  }

  if (props.isLoading) {
    return <LoadingIndicator selfCenter>{t`Loading`}</LoadingIndicator>;
  }

  return (
    <Virtuoso
      style={{ height: '100%' }}
      data={props.buckets}
      itemContent={(index, bucket) => {
        const isHidden = anyDeselected
          ? props.heatmapFilter.hiddenBuckets[bucket.id] === true
          : false;

        return (
          <HeatmapBucketsListItem
            bucket={bucket}
            metricDescriptor={props.metricDescriptor}
            isSelected={!isHidden}
            onClick={(value, evt) => props.onClick(bucket.id, value, evt)}
          />
        );
      }}
    />
  );
};

export default HeatmapBucketsList;
