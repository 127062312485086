import * as React from 'react';
const CircleArrowRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowRightIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M63.95,124.05c33.08,0,60.03-26.94,60.05-60.05.03-33.12-26.86-60.05-59.95-60.05-33.08,0-60.03,26.94-60.05,60.05-.03,33.12,26.86,60.05,59.95,60.05ZM23.99,55.41h47.2s-23.57-23.63-23.57-23.63h24.26s32.14,32.23,32.14,32.23l-32.2,32.23h-24.26s23.61-23.63,23.61-23.63H23.97s.02-17.19.02-17.19Z"
    />
  </svg>
);
export default CircleArrowRightIcon;
