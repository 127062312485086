import * as React from 'react';
const ArrowLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowLeftIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4,64L52.23,15.73h36.37s-35.37,35.4-35.37,35.4h70.76s0,25.75,0,25.75H53.24s35.36,35.39,35.36,35.39h-36.37S4,64,4,64Z"
    />
  </svg>
);
export default ArrowLeftIcon;
