import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  formatPercent,
  getCurrencySymbolSettings,
} from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import InboxZero from '../../../components/InboxZero';
import { Stat } from '../../../components/stats/Stat';
import { StatCenterPie } from '../../../components/stats/StatCenterPie';
import StatItem from '../../../components/stats/StatItem';
import { viewerPlanesTitles } from '../../../layout/viewer/store/viewer.state';
import { warehouseSelected } from '../../../store/warehouse.state';
import { getPolicyGroupMap } from '../../store/pickingPolicy/pickingPolicy.helper';
import { pickingPolicyOrderLineRulesTitles } from '../../store/pickingPolicy/pickingPolicy.state';
import { getKPITitles, KPI } from '../../store/simulation.helper';
import {
  getDimensionColor,
  getDimensionTitle,
} from '../../store/workforce.helper';
import {
  workforceAgentEventsTotal,
  workforceAgentForecastEnabled,
  workforceSelectedAgentId,
  workforceSelectedDrill,
} from '../../store/workforce.state';
import {
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_TOTAL,
  DRILL_KEY_WORK_CUSTOM_TOTAL,
} from '../../store/workforce.types';
import ContainerChart from '../ContainerChart';
import SimulationPieChart, { PieData } from '../SimulationPieChart';

export type WorkforceAgentTotalsProps = {
  analyzeId: string;
};

const chartDrillOption = ['cost', 'duration', 'share'] as const;
type ChartDrillOption = (typeof chartDrillOption)[number];

const WorkforceAgentTotals: React.FC<WorkforceAgentTotalsProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const events = useRecoilValue(workforceAgentEventsTotal(props.analyzeId));
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );
  const pickingRuleMap = useRecoilValue(pickingPolicyOrderLineRulesTitles);
  const planesMap = useRecoilValue(viewerPlanesTitles);
  const selectedDrillDown = useRecoilValue(workforceSelectedDrill(agentId));

  const wh = useRecoilValue(warehouseSelected);

  const [kpi, setKpi] = useState<ChartDrillOption>('cost');
  const KPIs = chartDrillOption;

  const kpiOptions = [
    {
      label: t`Cost`,
      icon: getCurrencySymbolSettings(wh.currency).symbol,
    },
    {
      label: t`Time`,
      icon: 'h',
    },
    {
      label: t`Share`,
      icon: '%',
    },
  ];

  const chartCostEvents = events.cost;
  const chartDurationEvents = events.duration;
  const chartData = kpi === 'cost' ? chartCostEvents : chartDurationEvents;

  const totalWork = chartData[DRILL_KEY_TOTAL];
  const totalIdle = chartData[DRILL_KEY_IDLE];
  const totalOvertime = chartData[DRILL_KEY_OVERTIME];
  const totalScheduled =
    chartData[DRILL_KEY_TOTAL] +
    chartData[DRILL_KEY_IDLE] -
    chartData[DRILL_KEY_OVERTIME];

  const workingShareRaw = totalWork / totalScheduled;
  const workingSharePercent = formatPercent(workingShareRaw);

  const idleShareRaw = totalIdle / totalScheduled;
  const idleSharePrecent = formatPercent(idleShareRaw);

  const overtimeShareRaw = totalOvertime / totalScheduled;
  const overtimeSharePercent = formatPercent(overtimeShareRaw);

  function getFormatter(kpi: ChartDrillOption) {
    switch (kpi) {
      case 'cost':
        return formatter.formatCurrency;
      case 'duration':
        return formatter.formatTimeSpan;
      case 'share':
        return formatter.formatShare;
      default:
        return formatter.formatCurrency;
    }
  }
  const formatFn = getFormatter(kpi);
  const showLegendStats = true;
  const kpiTitle = getKPITitles(kpi as KPI, t) ?? t`Share`;
  const kpiSwitch = (
    <ButtonSwitchMulti
      autoSize
      className="mx-1 items-center text-xs"
      options={kpiOptions}
      selectedIndex={KPIs.indexOf(kpi)}
      onClick={index => {
        setKpi(KPIs[index]);
      }}
    />
  );

  const series = [..._.keys(events.dimensions), DRILL_KEY_WORK_CUSTOM_TOTAL];

  const getValue = (key: string) => {
    if (kpi === 'share') {
      return totalWork > 0 ? chartData[key] / totalWork : 0;
    }
    return chartData[key];
  };

  if (_.isEmpty(series)) {
    return (
      <div
        className={classNames(
          'flex flex-col',
          'flex-1 items-center',
          'relative',
          'h-full',
          // 'bg-app-panel-dark/60',
        )}
      >
        <header
          className={classNames('min-w-half flex w-full p-1 lg:p-2 xl:p-4')}
        >
          <div className="flex-1 text-xs uppercase">{t`Cost`}</div>
        </header>
        <div className="flex-1">
          <InboxZero selfCenter message={t`No data for selected time range`} />
        </div>
      </div>
    );
  }
  return (
    <Stat
      className={'h-full'}
      isPreview
      // tag={isForecastSimulation ? '*' : ''}
      title={kpiTitle}
      inPanelMode
      switchUnitOfMeasure={kpiSwitch}
      // classChildren={'flex sm:grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-1'}
    >
      <ContainerChart>
        <StatCenterPie
          statLabelTop={t`Work`}
          statValueTop={workingSharePercent}
          statLabelMiddle={idleShareRaw > 0.01 && t`Idle`}
          statValueMiddle={idleShareRaw > 0.01 && idleSharePrecent}
          statLabelBottom={overtimeShareRaw > 0.01 && t`Overtime`}
          statValueBottom={overtimeShareRaw > 0.01 && overtimeSharePercent}
        />
        <SimulationPieChart
          showLabels={false}
          innerRadius={0.9}
          data={_(series)
            .filter(key => chartData[key] > 0)
            .map((key: string) => {
              const value = getValue(key);
              const dataItem: PieData = {
                id: key,
                label: getDimensionTitle(
                  key,
                  selectedDrillDown,
                  pickingRuleMap,
                  planesMap,
                  getPolicyGroupMap(t),
                  t,
                ),
                color: getDimensionColor(key),
                value,
                formatted: _.isNil(value) ? null : formatFn(value),
              };
              return dataItem;
            })
            .value()}
        />
      </ContainerChart>
      <div data-component="LegendStats" className="flex-1">
        {showLegendStats &&
          _(series)
            .filter(key => getValue(key) > 0)
            .map((key: string) => (
              <StatItem
                key={`event-group-${key}`}
                title={getDimensionTitle(
                  key,
                  selectedDrillDown,
                  pickingRuleMap,
                  planesMap,
                  getPolicyGroupMap(t),
                  t,
                )}
                value={formatFn(getValue(key))}
                legendColor={getDimensionColor(key)?.[0]}
              />
            ))
            .value()}
      </div>
    </Stat>
  );
};

export default WorkforceAgentTotals;
