import * as React from 'react';
const DashboardHomeEmptyIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DashboardHomeEmptyIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points=".84 94.42 .84 31.85 64.5 15.42 127.16 31.85 127.16 94.42 .84 94.42"
    />
  </svg>
);
export default DashboardHomeEmptyIcon;
