import * as React from 'react';
const PolicyComplianceInfoLineIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceInfoLineIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM109.37,70.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z"
    />
    <path
      strokeWidth={0}
      d="M64,14.49l-42.37,18.82v37.9c0,7.8,6.05,18.29,13.14,22.87l29.23,18.13,29.15-18.08c7.17-4.62,13.22-15.11,13.22-22.92v-37.9l-42.37-18.82ZM64,93.6c-3.54,0-6.43-2.9-6.43-6.43s2.9-6.43,6.43-6.43,6.43,2.89,6.43,6.43-2.89,6.43-6.43,6.43ZM74.29,38.88c0,.09-.01.17-.03.26l-5.11,33.98c-.06,2.78-2.35,5.04-5.14,5.04s-5.09-2.26-5.14-5.04l-5.14-34.14c-.02-.12-.02-.23,0-.35.09-5.6,4.67-10.13,10.29-10.13h0c5.62,0,10.21,4.55,10.29,10.15h0c0,.08,0,.15,0,.23Z"
    />
  </svg>
);
export default PolicyComplianceInfoLineIcon;
