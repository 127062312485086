import {
  BAY_SIDE_ALL,
  CreateLayoutImportLocationInput,
} from '@warebee/shared/data-access-api-dto';
import { MappingSchema } from '../mapping';
import { locationStatusResolver } from '../valueResolver/booleanValueResolver';
import { baySideValueResolver } from '../valueResolver/enumValueResolver';

// class LayoutBusinessLogicValidator
//   implements BusinessLogicValidator<CreateLayoutImportLocationInput>
// {
//   private bayMap = new Map();

//   validateRow(
//     row: CreateLayoutImportLocationInput,
//     t: TFunction<'importer'>,
//   ): BusinessLogicValidationError[] {
//     const { warehouseArea, locationAisle, locationBay, baySide, bayType } = row;
//     const bayKey = `${warehouseArea}-${locationAisle}-${baySide}-${locationBay}`;
//     if (this.bayMap.has(bayKey)) {
//       if (this.bayMap.get(bayKey) !== bayType) {
//         const message = t(
//           t`Multiple 'Bay Types' assigned to the same Bay ID:
//           \n {{locationBay}}\n at
//           Aisle: {{locationAisle}}\n
//           Side: {{baySide}}\n
//           Area: {{warehouseArea}}\n
//           Bay Type(s): {{bayTypes}}`,
//           {
//             locationBay: locationBay,
//             locationAisle: locationAisle,
//             baySide: baySide,
//             warehouseArea: warehouseArea,
//             bayTypes: `${bayType}, ${this.bayMap.get(bayKey)}`,
//           },
//         );
//         return [
//           {
//             type: t`Bay Type validation error`,
//             message,
//           },
//         ];
//       }
//     } else {
//       this.bayMap.set(bayKey, bayType);
//     }
//     return [];
//   }
// }

export const LAYOUT_IMPORT_MAPPING_SCHEMA: MappingSchema<CreateLayoutImportLocationInput> =
  {
    fields: [
      {
        name: 'locationId',
        type: 'string',
        unique: true,
      },
      {
        name: 'locationOrder',
        type: 'bigint',
      },
      {
        name: 'locationStatus',
        type: 'boolean',
        defaultValueResolver: locationStatusResolver,
      },
      {
        name: 'locationLevel',
        type: 'integer',
      },
      {
        name: 'warehouseArea',
        type: 'string',
      },
      {
        name: 'locationLength',
        type: 'number',
        measureType: 'size',
      },
      {
        name: 'locationWidth',
        type: 'number',
        measureType: 'size',
      },
      {
        name: 'locationHeight',
        type: 'number',
        measureType: 'size',
      },
      {
        name: 'locationWeight',
        type: 'number',
        measureType: 'weight',
      },
      {
        name: 'locationAisle',
        type: 'string',
      },
      {
        name: 'locationBay',
        type: 'integer',
      },
      {
        name: 'locationBayPosition',
        type: 'integer',
      },
      {
        name: 'baySide',
        type: { enumValues: BAY_SIDE_ALL },
        defaultValueResolver: baySideValueResolver,
      },
      {
        name: 'locationUsageType',
        type: 'string',
      },
      {
        name: 'locationRackingType',
        type: 'string',
      },
      {
        name: 'locationDepthPosition',
        type: 'integer',
        optional: true,
      },
      {
        name: 'locmhtype',
        type: 'string',
        optional: true,
      },
      {
        name: 'bayType',
        type: 'string',
        optional: true,
      },
      {
        name: 'primaryAccessAisle',
        type: 'string',
        optional: true,
      },
      {
        name: 'secondaryAccessAisle',
        type: 'string',
        optional: true,
      },
      {
        name: 'congestionZone',
        type: 'string',
        optional: true,
      },
    ],
    // createValidator: () => new LayoutBusinessLogicValidator(),
  };
