import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../../../Tw';
import IntersectionFeature from '../../../../layout/features/IntersectionFeature';
import PortalFeature from '../../../../layout/features/PortalFeature';
import { converterSelectedFloorValidationResult } from '../store/validation.state';

const areaColors = TwTheme.extend.colors.area;
const portalColors = TwTheme.extend.colors.portal;

const ConverterIntersectionsLayer: React.FC = () => {
  const validationResultResponse = useRecoilValueLoadable(
    converterSelectedFloorValidationResult,
  );

  if (
    validationResultResponse.state !== 'hasValue' ||
    !validationResultResponse.contents
  )
    return null;

  const intersections = validationResultResponse.contents.areaIntersections;
  const interAreaPortals = validationResultResponse.contents.interAreaPortals;
  return (
    <Layer>
      {_.map(intersections, (settings, i) => {
        return (
          <IntersectionFeature
            key={`intersection-${settings.area1Id}-${settings.area2Id}-${i}`}
            shape={settings.shape}
            fillColor={areaColors.intersection_preview}
          />
        );
      })}
      {_.map(interAreaPortals, (portal, i) => {
        return (
          <PortalFeature
            key={`inter-area-portal-${portal.area1Id}-${portal.area2Id}-${i}`}
            portal={portal.coords}
            isSelected={false}
            color={[portalColors.waypoint, portalColors.label]}
          />
        );
      })}
    </Layer>
  );
};

export default ConverterIntersectionsLayer;
