import { useApolloClient } from '@apollo/client';
import {
  FindItemSetFilterValuesDocument,
  FindItemSetFilterValuesQuery,
  FindItemSetFilterValuesQueryVariables,
  ItemFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { POLICY_FILTER_VALUES_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { LoadPolicyFilterValuesParams } from '../../policyFilters/policyFilter.types';
import {
  policyFilterData,
  policyFiltersSearchValues,
} from '../../simulation/store/policyFilter.state';
import { simulationItemSetId } from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';

export type LoadItemSetFilterValuesParams =
  LoadPolicyFilterValuesParams<ItemFilterType>;

function useLoadItemSetFilterValues() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Item's filter values.`;

  const [observables, setObservables] = useState<
    Record<string, ZenObservable.Subscription>
  >({});

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (filterKey: string) => {
        const current = await snapshot.getPromise(policyFilterData(filterKey));

        set(policyFilterData(filterKey), {
          ...current,
          status: AsyncLoadStatus.Loading,
        });
      },
  );

  const loadItemSetFilterValues = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadItemSetFilterValuesParams) => {
        const itemSetId = await snapshot.getPromise(simulationItemSetId);
        const searchValues = await snapshot.getPromise(
          policyFiltersSearchValues,
        );
        const current = await snapshot.getPromise(
          policyFilterData(params.filterKey),
        );

        const searchValue = searchValues[params.filterKey];

        const includeMatchValues = _.filter(
          params.filterIntersection?.allOf,
          item => item.type !== params.field,
        );

        const query = client.watchQuery<
          FindItemSetFilterValuesQuery,
          FindItemSetFilterValuesQueryVariables
        >({
          query: FindItemSetFilterValuesDocument,
          variables: {
            page: {
              limit: params.limit || POLICY_FILTER_VALUES_PAGE_SIZE,
              skip: params.skip || 0,
            },
            input: {
              itemSetId,
              filterType: params.field as any,
              includeMatching: {
                allOf: cloneWithoutTypename(includeMatchValues),
              },
              query: searchValue,
            },
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(policyFilterData(params.filterKey), {
              content: [],
              totalCount: 0,
              status: AsyncLoadStatus.Error,
            });
            return;
          }

          let content = data.findItemFilterValues.content;
          if (params.isAppend) {
            content = [...current.content, ...content];
          }
          set(policyFilterData(params.filterKey), {
            ...data.findItemFilterValues,
            content: content,
            totalCount: data.findItemFilterValues.totalCount,
            status: AsyncLoadStatus.Ok,
          });
        });
        observables[params.field] = queryObservable;
      },
  );

  async function call(params: LoadItemSetFilterValuesParams) {
    await initLoading(params.filterKey);
    await loadItemSetFilterValues(params);
  }

  function cancel(field: string) {
    observables[field]?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadItemSetFilterValues;
