import {
  AreaConfiguration,
  AreaLink,
  AreaLinkPointStatus,
  AreaLinkPointType,
  areaActionPointPositions,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React, { memo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import useAreaActions from '../../import/layout/converter/hooks/useAreaActions';
import ConverterAreaGroupLabel from '../../import/layout/converter/layers/ConverterAreaGroupLabel';
import {
  converterAreaActionsMode,
  converterAreaConfiguration,
  converterAreaLinkSelected,
  converterDraggableAreaIdAtom,
  converterSelectedAreaId,
  converterShowAreaLabels,
} from '../../import/layout/converter/store/converter.area.state';
import {
  AreaBox,
  AreaLinkPoint,
} from '../../import/layout/converter/store/converter.types';
import { stageStateById } from '../stage/stage.state';
import ActionIconFeature, {
  ActionIconFeatureProp,
  ActionIconOffset,
} from './ActionIconFeature';
import {
  IconDef,
  getLinkBothIconDef,
  getLinkSourceIconDef,
  getLinkTargetIconDef,
  iconLinkAdd,
  iconLinkRemove,
} from './featuresIcons';

const iconSize = 128;

const colors = TwTheme.extend.colors;

function getLinkColor(type: AreaLinkPointStatus) {
  switch (type) {
    case 'linkSource':
      return colors.area.link_source;
    case 'linkTarget':
      return colors.area.link_source; // colors.area.link_target;
    case 'linkBoth':
      return colors.area.link_source; // colors.area.link_both;
  }
}

export type AreaActionsFeatureProps = {
  areaBox: AreaBox;
  areaConfig: AreaConfiguration;
};

/**
 * Area action feature
 * - draw actionable icons for link area
 * - lock/unlock area
 * - action icons to add spacer areas on sides
 * @param props: AreaActionsFeatureProps
 * @returns
 */

const AreaActionsFeature: React.FC<AreaActionsFeatureProps> = props => {
  const { box, points } = props.areaBox;
  const selectedAreaId = useRecoilValue(converterSelectedAreaId);
  const [selectedLink, setSelectedLink] = useRecoilState(
    converterAreaLinkSelected,
  );
  const [areasConfiguration, setAreasConfigurations] = useRecoilState(
    converterAreaConfiguration,
  );
  const draggableId = useRecoilValue(converterDraggableAreaIdAtom);
  const actionsMode = useRecoilValue(converterAreaActionsMode);

  const showLabels = useRecoilValue(converterShowAreaLabels);
  const stageState = useRecoilValue(stageStateById('converter-area-view'));
  const [addArea, addAreaWithLocation] = useAreaActions();

  const areaId = props.areaBox.areaId;
  if (draggableId === props.areaBox.areaId) return null;

  const showLinkActions = actionsMode === 'links';
  const showCreateAreaActions = actionsMode === 'add-area';
  const showCreateLocationsActions = actionsMode === 'add-location';

  const connectedAreasIds = new Set<string>([
    areaId,
    ...getMasterAreasIds(areaId),
  ]);

  function getMasterAreasIds(areaId: string) {
    const visitedAreasIds = new Set<string>();
    return getMasterAreasIdsInternal(areaId, visitedAreasIds);
  }

  function getMasterAreasIdsInternal(
    areaId: string,
    visitedAreasIds: Set<string>,
  ): string[] {
    if (_.isNil(areaId) || visitedAreasIds.has(areaId)) return [];
    const masterAreaId = areasConfiguration[areaId].link?.masterAreaId;
    visitedAreasIds.add(areaId);
    return _.compact([
      masterAreaId,
      ...getMasterAreasIdsInternal(masterAreaId, visitedAreasIds),
    ]);
  }

  const minAreaSize = Math.min(box.width, box.height);
  // icon size  10% of lower box dimension
  // const scaleFactorInternal = (minWH * 0.15) / iconSize;

  const scaleFactorInternalMax = minAreaSize * 0.25;
  const scaleFactorInternalOptimal =
    (stageState.size[0] / stageState.scale) * 0.0285;
  const scaleFactorInternal =
    Math.min(scaleFactorInternalOptimal, scaleFactorInternalMax) / iconSize;

  const middlePoints = _.map(points, (p, i) => {
    const next = points[(i + 1) % 4];
    const position = areaActionPointPositions[i];
    return {
      id: `${areaId}-${position}`,
      position,
      x: (p.x + next.x) / 2,
      y: (p.y + next.y) / 2,
    };
  });

  function getMiddlePointsOffsets(
    index: number,
    total: number,
  ): ActionIconOffset[] {
    const positionOffsets = _.range(total).map(
      (p, i) => (i - (total - 1) / 2) * 2.2,
    );
    const dPosition = positionOffsets[index];

    return [
      { dx: -1, dy: dPosition },
      { dx: dPosition, dy: 1 },
      { dx: 1, dy: dPosition },
      { dx: dPosition, dy: -1 },
    ];
  }
  const anglePointsOffsets: ActionIconOffset[] = [
    { dx: -1, dy: -1 },
    { dx: -1, dy: 1 },
    { dx: 1, dy: 1 },
    { dx: 1, dy: -1 },
  ];

  const getFillColor = (point: AreaLinkPoint): [string, string] => {
    if (selectedLink?.id === point.id) {
      return [colors.area.link_active, colors.area.link_hover];
    }
    if (selectedLink) {
      return [null, null];
    }
    const base =
      selectedLink?.id === point.id
        ? colors.area.link_active
        : getLinkColor(point.status);

    return [base, colors.area.link_hover];
  };

  const onClick = (point: AreaLinkPoint) => {
    // delete link
    if (
      _.isNil(selectedLink) &&
      (point.status === 'linkSource' ||
        point.status === 'linkBoth' ||
        point.status === 'linkTarget')
    ) {
      setAreasConfigurations(current => {
        return _.reduce(
          current,
          (acc, ac) => ({
            ...acc,
            [ac.id]: {
              ...ac,
              link:
                ac.link?.areaId === point.areaId ||
                ac.link?.masterAreaId === point.areaId
                  ? null
                  : ac.link,
            },
          }),
          {},
        );
      });
      return;
    }

    // select active point
    if (_.isNil(selectedLink)) {
      setSelectedLink(point);
      return;
    }
    //deselect active point
    if (selectedLink?.id === point.id) {
      setSelectedLink(null);
      return;
    }

    // create link

    const sourceAreaId = selectedLink.areaId;
    const link: AreaLink = {
      areaId: sourceAreaId,
      masterAreaId: point.areaId,
      masterLinkPointType: point.type,
      linkPointType: selectedLink.type,
      linkOffsetX: 0,
      linkOffsetY: 0,
    };
    setAreasConfigurations(current => ({
      ...current,
      [sourceAreaId]: {
        ...current[sourceAreaId],
        link: link,
      },
    }));
    setSelectedLink(null);
    return;
  };

  const isVisible = (point: AreaLinkPoint): boolean => {
    if (selectedLink) {
      // when source link selected show selected link and potential target points in other areas
      return (
        point.id === selectedLink?.id ||
        !connectedAreasIds.has(selectedLink.areaId)
      );
    }
    if (selectedAreaId) {
      // when area is selected and linked, show only source points
      if (selectedAreaId === props.areaBox.areaId) {
        if (props.areaConfig.link) {
          return point.status === 'linkSource' || point.status === 'linkBoth';
        }
        // show all points in selected area if area is not linked
        return true;
      }
      // for not selected areas show only target points related to selected area
      return _.includes(point.connectedAreasIds, selectedAreaId);
    }
    return point.status !== 'default';
  };

  function getLinkIconPath(
    status: AreaLinkPointStatus,
    type: AreaLinkPointType,
  ): [IconDef, IconDef] {
    if (selectedLink) {
      return [{ path: iconLinkAdd }, null];
    }
    switch (status) {
      case 'linkSource':
        return [getLinkSourceIconDef(type), { path: iconLinkRemove }];
      case 'linkTarget':
        return [getLinkTargetIconDef(type), { path: iconLinkRemove }];
      case 'linkBoth':
        return [getLinkBothIconDef(type), { path: iconLinkRemove }];
      default:
        return [{ path: iconLinkAdd }, null];
    }
  }

  return (
    <>
      {showLinkActions &&
        _(points)
          .map((p, index) => {
            // filter visibility inside to not lose proper index
            if (!isVisible(p)) return null;

            const [iconPath, iconHoverPath] = getLinkIconPath(p.status, p.type);
            const [iconFill, iconFillHovered] = getFillColor(p);
            const iconConfig: ActionIconFeatureProp = {
              x: p.x,
              y: p.y,
              scale: scaleFactorInternal,
              offset: anglePointsOffsets[index],
              iconFill,
              iconFillHovered,
              iconDef: iconPath,
              iconHoverDef: iconHoverPath,
              onClick: () => onClick(p),
            };
            return (
              <ActionIconFeature
                key={`link-area-${areaId}-${p.type}`}
                {...iconConfig}
              />
            );
          })
          .value()}

      {showCreateLocationsActions &&
        _(middlePoints)
          .map((p, index) => {
            const iconConfigAddLocation: ActionIconFeatureProp = {
              x: p.x,
              y: p.y,
              scale: scaleFactorInternal,
              iconDef: { path: iconLinkAdd },
              iconFill: colors.menu.active,
              offset: getMiddlePointsOffsets(0, 1)[index],
              onClick: () =>
                addAreaWithLocation({
                  sourceAreaBox: props.areaBox,
                  position: p.position,
                }),
            };

            return (
              <ActionIconFeature
                key={`add-location-${areaId}-${index}`}
                {...iconConfigAddLocation}
              />
            );
          })
          .value()}

      {showCreateAreaActions &&
        _(middlePoints)
          .map((p, index) => {
            const iconConfigAddArea: ActionIconFeatureProp = {
              x: p.x,
              y: p.y,
              scale: scaleFactorInternal,
              iconDef: { path: iconLinkAdd },
              offset: getMiddlePointsOffsets(0, 1)[index],
              iconFill: colors.menu.active,
              onClick: () =>
                addArea({
                  sourceAreaBox: props.areaBox,
                  position: p.position,
                }),
            };

            return (
              <ActionIconFeature
                key={`add-area-${areaId}-${index}`}
                {...iconConfigAddArea}
              />
            );
          })
          .value()}
      {/* 
      {!props.areaConfig.link && (
        <Line
          points={_.flatten(box.toPoints().map(p => [p.x, p.y]))}
          closed
          stroke={colors.area.link_active}
          strokeWidth={minWH * 0.01}
          dash={[minWH * 0.03, minWH * 0.02]}
          dashEnabled={true}
          fillEnabled={false}
          listening={false}
        />
      )} */}
      {showLabels && (
        <ConverterAreaGroupLabel
          areaBox={props.areaBox}
          areaConfig={props.areaConfig}
          connectedAreasCount={connectedAreasIds.size}
        />
      )}
    </>
  );
};

export default memo(AreaActionsFeature);
