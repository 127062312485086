import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { layoutDocumentLoadStatus } from '../store/layout.state';
import { viewerBayLocations } from '../viewer/store/viewer.state';
import LayoutInformationAlert, {
  InformationAlertMessageType,
} from './LayoutInformationAlert';
import LayoutLoadingAlert, {
  LoadingAlertMessageType,
} from './LayoutLoadingAlert';
import LayoutLoadingErrorAlert, {
  LoadingErrorAlertMessageType,
} from './LayoutLoadingErrorAlert';

export type BayLayoutAlertsBaseProps = {
  messages?: LoadingAlertMessageType[];
  errors?: LoadingErrorAlertMessageType[];
  infos?: InformationAlertMessageType[];
};

const BayLayoutAlertsBase: React.FC<BayLayoutAlertsBaseProps> = props => {
  const layoutLoadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const locationLoadable = useRecoilValueLoadable(viewerBayLocations);
  const { messages = [], errors = [], infos = [] } = props;

  const messagesLayout: LoadingAlertMessageType[] = [];
  const errorsLayout: LoadingErrorAlertMessageType[] = [];
  const infosLayout: InformationAlertMessageType[] = [];

  // #1 - layout
  if (layoutLoadStatus === AsyncLoadStatus.Loading) {
    messagesLayout.push(LoadingAlertMessageType.layout);
  }

  // #2 - locations by level
  if (locationLoadable.state === 'loading') {
    messagesLayout.push(LoadingAlertMessageType.locations);
  }

  if (locationLoadable.state === 'hasError') {
    errorsLayout.push(LoadingErrorAlertMessageType.locations);
  }

  return (
    <>
      {_.take([...messagesLayout, ...messages], 1).map(mt => (
        <LayoutLoadingAlert key={`alert-loading-${mt}`} messageType={mt} />
      ))}
      {[...errorsLayout, ...errors].map(mt => (
        <LayoutLoadingErrorAlert
          key={`alert-loading-error-${mt}`}
          messageType={mt}
        />
      ))}
      {[...infosLayout, ...infos].map(mt => (
        <LayoutInformationAlert
          key={`alert-information-${mt}`}
          messageType={mt}
        />
      ))}
    </>
  );
};

export default BayLayoutAlertsBase;
