import Konva from 'konva';
import React, { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KonvaNodeEvents, Rect, Text } from 'react-konva';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  workforceHoveredWave,
  workforceSelectedUnscheduled,
} from '../../simulation/store/workforce.state';
import { ticksInPixel } from '../viewer/layers/SchedulerLayer';

const menuColors = TwTheme.extend.colors.menu;
const brandColors = TwTheme.extend.colors.brand;
const waveColors = TwTheme.extend.colors.wave;

const widthDay = 24 * 60; //24h*60min
const heightDay = widthDay / 3;
const height = 0.8 * heightDay;

export type UnscheduledFeatureProps = {
  schedulingStart: Date;
};

const UnscheduledFeature: React.FC<UnscheduledFeatureProps> = props => {
  const { t } = useTranslation('simulation');
  const setHoveredGlobal = useSetRecoilState(workforceHoveredWave);
  const [selectedUnscheduled, setSelectedUnscheduled] = useRecoilState(
    workforceSelectedUnscheduled,
  );
  const [hovered, setHovered] = useState(false);
  const id = useId();

  const xMax = props.schedulingStart.getTime() / ticksInPixel;
  const x = xMax - widthDay;

  const width = (xMax - x) / 2;

  const cornerRadiusMax = Math.min(0.085 * heightDay, width / 2);
  const waveConfig: Konva.RectConfig = {
    id,
    x: x - widthDay / 4 + 40,
    y: 0.115 * heightDay,
    width: widthDay,
    height,
    fill: waveColors.unscheduled,
    cornerRadius: cornerRadiusMax,
    fillEnabled: true,
    opacity: hovered ? 0.85 : selectedUnscheduled ? 1 : 0.45,
    stroke: menuColors[800],
    strokeWidth: selectedUnscheduled ? 5 : 0,
    shadowEnabled: selectedUnscheduled ? true : false,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowBlur: 12,
    shadowOffset: {
      x: 10,
      y: -10,
    },
  };

  const waveSelectionStroke: Konva.RectConfig = {
    id,
    x: x - widthDay / 4 + 40,
    y: 0.115 * heightDay,
    width: widthDay,
    height,
    cornerRadius: cornerRadiusMax,
    fillEnabled: false,
    opacity: hovered ? 0.85 : selectedUnscheduled ? 1 : 0.45,
    perfectDrawEnabled: true,
    strokeScaleEnabled: true,
    stroke: brandColors[500],
    strokeWidth: selectedUnscheduled ? 5 : 0,
    dash: [15, 20],
  };

  const waveTitle: Konva.TextConfig = {
    // x: x + 0.08 * heightDay,
    x: xMax - widthDay / 4,
    y: heightDay * 0.85,
    rotation: -90,
    fontSize: 0.125 * Math.abs(height),
    fontFamily: 'NeoSans-Regular',
    text: t`UNSCHEDULED`,
    fill: 'rgba(255,255,255,0.9)',
    scaleY: -1,
    align: 'center',
  };

  function onClick() {
    setSelectedUnscheduled(!selectedUnscheduled);
  }

  const eventConfig: KonvaNodeEvents = {
    onClick: onClick,
    onTap: onClick,
    onMouseEnter: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
      setHovered(true);
    },

    onMouseLeave: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';
      setHovered(false);
    },
  };

  return (
    <React.Fragment key={id}>
      <Rect {...waveConfig} {...eventConfig} />
      <Rect {...waveSelectionStroke} {...eventConfig} />
      <Text {...waveTitle} />
    </React.Fragment>
  );
};

export default UnscheduledFeature;
