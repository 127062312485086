import {
  LayoutLocationFilter,
  LoadBalancingPolicyFragment,
  LoadBalancingPolicyInput,
  LoadBalancingRuleFragment,
  LoadBalancingRuleInput,
  LoadBalancingRuleLocationsDocument,
  LoadBalancingRuleLocationsQuery,
  LoadBalancingRuleLocationsQueryVariables,
  LoadBalancingRuleSummaryWithLocationsFragment,
  LoadBalancingZoneType,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { HTMLAttributes } from 'react';
import { secureClient } from '../GraphQLClient';
import { cloneWithoutTypename } from '../common/utils';
import * as Icon from '../components/icons';
import { getPolicyMatchInput } from '../policyFilters/policyFilter.helper';

export function getLoadBalancingPolicyRuleInput(
  rule: LoadBalancingRuleFragment,
): LoadBalancingRuleInput {
  const clonedRule: LoadBalancingRuleFragment = cloneWithoutTypename(rule);
  const include = getPolicyMatchInput(rule?.include);

  const r: LoadBalancingRuleInput = {
    ...clonedRule,
    include,
  };
  return r;
}

export function getLoadBalancingPolicyInput(
  policy: LoadBalancingPolicyFragment,
): LoadBalancingPolicyInput {
  return {
    rules: _.map(policy?.rules, getLoadBalancingPolicyRuleInput),
  };
}

export function getZoneTypeOptions(t: TFunction<'simulation'>): {
  id: LoadBalancingZoneType;
  title: string;
  icon?: React.FC<HTMLAttributes<Element>>;
}[] {
  return [
    {
      id: LoadBalancingZoneType.AISLE,
      title: t(`Aisle`, { ns: 'simulation' }),
      icon: Icon.ObjectAisle,
    },
    {
      id: LoadBalancingZoneType.CONGESTION_ZONE,
      title: t(`Work Zone`, { ns: 'simulation' }),
      icon: Icon.WorkloadZoneBalance,
    },
    {
      id: LoadBalancingZoneType.PLANE,
      title: t(`Floor`, { ns: 'simulation' }),
      icon: Icon.Floor,
    },
    {
      id: LoadBalancingZoneType.WAREHOUSE_AREA,
      title: t(`Area`, { ns: 'simulation' }),
      icon: Icon.AreasStaging,
    },
  ];
}

export async function loadBalancingRuleLocations(params: {
  analyzeId: string;
  rule: LoadBalancingRuleFragment;
  queryFilter: LayoutLocationFilter;
}): Promise<LoadBalancingRuleSummaryWithLocationsFragment> {
  const response = await secureClient.query<
    LoadBalancingRuleLocationsQuery,
    LoadBalancingRuleLocationsQueryVariables
  >({
    query: LoadBalancingRuleLocationsDocument,
    variables: {
      analyzeId: params.analyzeId,
      rule: getLoadBalancingPolicyRuleInput(params.rule),
      layoutLocationFilter: params.queryFilter,
    },
  });

  return response.data?.analyzeResult?.loadBalancingRuleSummary;
}
