import { Popover } from '@headlessui/react';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationDetails } from '../store/notification.state';
import Notification from './Notification';
import NotificationWithSimulation from './NotificationWithSimulation';

export type NotificationPopoverProps = {
  notifications: NotificationDetails[];
  onClose: (index: number) => void;
  closable?: boolean;
};

const NotificationPopover: React.FC<NotificationPopoverProps> = props => {
  const { t } = useTranslation('app');

  return (
    <>
      {_.map(props.notifications, (notification, index) => (
        <Popover
          key={`error-popover-${notification.id}`}
          className={`NotificationPopover absolute ${
            notification.type === 'importSuccessful' ||
            notification.type !== 'importFromSimulationSuccessful'
              ? 'top-1 ltr:right-1 rtl:left-1'
              : 'top-0 right-0 left-0'
          } z-dropdown_menu select-text`}
        >
          <Popover.Panel
            key={`error-popover-panel-${notification.id}`}
            static
            className={`px-0 ${
              notification.type === 'importSuccessful' ||
              notification.type !== 'importFromSimulationSuccessful'
                ? 'bg-green-600 w-80 rounded bg-opacity-70 text-white backdrop-filter backdrop-blur-lg backdrop-saturate-110 py-1 mb-4'
                : 'bg-menu-active text-menu-active-text w-full'
            } `}
          >
            {notification.type === 'importSuccessful' && (
              <Notification
                notification={notification}
                onClose={() => props.onClose(index)}
              />
            )}
            {notification.type === 'importFromSimulationSuccessful' && (
              <NotificationWithSimulation
                notification={notification}
                onClose={() => props.onClose(index)}
              />
            )}
          </Popover.Panel>
        </Popover>
      ))}
    </>
  );
};
export default NotificationPopover;
