import * as React from 'react';
const PolicyPickingAgentsLineIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyPickingAgentsLineIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M43.9,73.52c.24,0,.43.19.43.43s-.19.43-.43.43-.43-.19-.43-.43.19-.43.43-.43Z"
    />
    <path
      strokeWidth={0}
      d="M71.39,74.1c.12.07.16.22.09.35-.07.12-.22.16-.35.09s-.16-.22-.09-.35c.07-.12.22-.16.35-.09Z"
    />
    <path
      strokeWidth={0}
      d="M71.04,70.97c-.07-.12-.03-.28.09-.34.12-.07.28-.03.35.09.07.12.03.28-.09.35-.12.07-.28.03-.35-.09Z"
    />
    <path
      strokeWidth={0}
      d="M40.86,73.95c0,1.68,1.36,3.04,3.04,3.04s3.04-1.36,3.04-3.04c0-1.68-1.36-3.04-3.04-3.04s-3.04,1.36-3.04,3.04ZM41.09,73.95c0-1.55,1.26-2.81,2.81-2.81s2.81,1.26,2.81,2.81-1.26,2.81-2.81,2.81-2.81-1.26-2.81-2.81Z"
    />
    <path
      strokeWidth={0}
      d="M70.26,72.84c-.14,0-.25-.11-.25-.25,0-.14.11-.25.25-.25s.25.11.25.25-.11.25-.25.25Z"
    />
    <path
      strokeWidth={0}
      d="M43.28,72.49c.05.09.02.22-.07.27-.09.05-.22.02-.27-.07-.05-.09-.02-.22.07-.27.09-.06.22-.02.27.07Z"
    />
    <path
      strokeWidth={0}
      d="M43.21,75.14c.09.05.13.18.07.27-.05.09-.18.13-.27.07-.09-.05-.13-.18-.07-.27.06-.09.18-.13.27-.07Z"
    />
    <path
      strokeWidth={0}
      d="M14.01,65.34l48.65,48.65c.85.85,1.82.86,2.68,0l48.65-48.65c.86-.86.86-1.82,0-2.68L65.34,14.01c-.43-.43-.88-.64-1.34-.64h0c-.46,0-.91.21-1.34.64L14.01,62.66c-.85.86-.85,1.82,0,2.68ZM38.95,73.95c0-2.73,2.22-4.95,4.95-4.95s4.95,2.22,4.95,4.95c0,2.73-2.22,4.95-4.95,4.95s-4.95-2.22-4.95-4.95ZM34.22,68.1l1.24-5.3,2.28-2.72h9.71s-.5-1.59-.5-2.33.59-8.37.79-10.5c.2-2.13.89-2.97.89-2.97,0,0,11.99-1.24,14.91.74,2.92,1.98,6.83,15.55,6.83,15.55,1.29.25,1.14.5,1.78,4.16,1.19.26,2.28.57,2.97.79v-29.71h3.52s-.3,21.15-.3,21.15h1.14s.84-.84.84-.84h.5v19.32s15.41,0,15.41,0v.45s-15.95.74-15.95.74c0,0-1.93.35-1.93-1.63-.08,0-.15-.02-.23-.06-.93,2.33-3.2,3.97-5.86,3.97-3.49,0-6.31-2.83-6.31-6.31,0-3.49,2.83-6.31,6.31-6.31,1.04,0,2.01.25,2.87.7v-.91c-.91-.18-2.37-.43-3.12-.43-7.51,0-6.79,9.01-6.79,9.01h-15.45s.45-6.44-5.94-6.44c-6.39,0-5.48,7.08-5.48,7.08l-3.19-1.44-.94-5.75Z"
    />
    <path
      strokeWidth={0}
      d="M42.32,73.75c.11,0,.2.09.2.2s-.09.2-.2.2-.2-.09-.2-.2c0-.11.09-.2.2-.2Z"
    />
    <path
      strokeWidth={0}
      d="M44.85,75.21c.06.09.02.22-.07.27-.09.05-.22.02-.27-.07-.05-.1-.02-.22.07-.27.1-.05.22-.02.27.07Z"
    />
    <circle cx={72.26} cy={72.58} r={0.55} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M76.14,72.58c0-2.14-1.73-3.88-3.87-3.88-2.14,0-3.87,1.74-3.87,3.88,0,2.14,1.73,3.87,3.87,3.87s3.87-1.74,3.87-3.87ZM75.71,72.58c0,1.91-1.55,3.45-3.45,3.45s-3.45-1.55-3.45-3.45c0-1.91,1.55-3.45,3.45-3.45,1.91,0,3.45,1.55,3.45,3.45Z"
    />
    <polygon
      strokeWidth={0}
      points="65 51.64 65.43 51.11 66.55 53.89 65.74 53.66 65 51.64"
    />
    <path
      strokeWidth={0}
      d="M45.47,73.75c.11,0,.2.09.2.2s-.09.2-.2.2-.2-.09-.2-.2c0-.11.09-.2.2-.2Z"
    />
    <path
      strokeWidth={0}
      d="M61.51,46.46c.99,0,1.93-.3,3.76,4.31-.5.64-.64.78-.64.78l.89,2.43,1.17.35.91,2.46-1.25-.08s-.81-1.44-1.65-1.52c-.36-.15.17-.43.58-.71.41-.28.2-.73-.59-.15-.79.58-1.95,1.34-2.56,1.77-.61.43-.35.8.54.28.89-.53,1.07,0,1.07,0,0,0,.5.94.78,1.88.28.94.71,2.03,1.27,2.11-.13.31.33.76,2.46,2.59l-2.97,3.02-3.62.3s1.39-4.01-2.87-4.85c-.05-.3,1.59-.3,1.59-.3,0,0,.5-1.58-1.19-1.58s-2.77.44-2.77.44c0,0-.5-2.53-3.42-3.07.64-1.68.35-3.67-2.53-3.67-.32.32-.15,1.04,0,1.83.15.79.59,5.05,1.88,5.99-.42.42-2.06.1-2.06.1l-.76-2.33.94-12.38h11.05Z"
    />
    <path
      strokeWidth={0}
      d="M73.14,71.07c-.12-.07-.16-.22-.09-.35.07-.12.22-.16.35-.09.12.07.16.22.09.34-.07.12-.22.16-.35.09Z"
    />
    <path
      strokeWidth={0}
      d="M44.58,72.76c-.09-.06-.13-.18-.07-.27.05-.09.18-.13.27-.07.09.05.13.18.07.27-.05.09-.18.13-.27.07Z"
    />
    <path
      strokeWidth={0}
      d="M73.48,74.19c.07.12.03.27-.09.35-.12.07-.28.03-.35-.09-.07-.12-.03-.28.09-.35.12-.07.28-.03.35.09Z"
    />
    <path
      strokeWidth={0}
      d="M74.26,72.84c-.14,0-.25-.11-.25-.25,0-.14.11-.25.25-.25s.25.11.25.25-.11.25-.25.25Z"
    />
  </svg>
);
export default PolicyPickingAgentsLineIcon;
