import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export type PolicyCardItemProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconClassName?: string;
};

export const PolicyCardItem: React.FC<PolicyCardItemProps> = props => {
  const IconItem = props.icon;

  return (
    <div
      data-component={`PolicyCardItem`}
      data-label={`PolicyCardItem-${props.title}`}
      aria-label={`PolicyCardItem-${props.title}`}
      className={classNames(
        'flex flex-col flex-1 isolate mb-1 cursor-pointer',
        props.className,
      )}
      // className={`flex flex-col flex-1 isolate mb-1 cursor-pointer ${
      //   props.className || ''
      //   }`}

      onClick={e => {
        // e.stopPropagation();
        props.onClick && props.onClick();
      }}
    >
      <div
        className={classNames([
          'flex flex-col flex-1',
          'rounded',
          {
            'items-start': props.icon,
            'items-center': !props.icon,
            'p-2': props.title,
            'bg-app-panel/80 text-menu-text': !props.isActive,
            'bg-menu-active text-menu-active-text hover:bg-menu-active hover:text-menu-active-text/90 hover:bg-opacity-50':
              props.isActive,
            'hover:bg-menu-active/75 hover:text-menu-active-text':
              !props.isDisabled,
            'bg-opacity-50 group-hover:rounded hover:rounded': props.isDisabled,
          },
        ])}
      >
        {props.icon && (
          <IconItem
            className={classNames('fill-current', props.iconClassName)}
          />
        )}

        {props.title && (
          <div
            className={classNames(
              'flex flex-1 items-center',
              'px-0.5',
              'font-bold',
            )}
          >
            {props.title}
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};
