import {
  SkipLimitPageSpec,
  SortDirection,
  StringSearchFilter,
} from '@warebee/frontend/data-access-api-graphql';
import { ActivityFeedEventDataset } from '@warebee/shared/import-converter';
import _ from 'lodash';
import { toDateFromLocaleStringDate } from '../../../../common/dateTimeHelper';
import { KeysMatching } from '../../../../common/utility.types';

export type FeedSortBy = {
  direction?: SortDirection;
  field: keyof ActivityFeedEventDataset;
};

export type FeedFieldWithStringFilter = KeysMatching<
  ActivityFeedEventDataset,
  string
>;

export type FeedFilterBy = Partial<
  Record<FeedFieldWithStringFilter, StringSearchFilter>
>;

export type FeedQueryBuilderPagedParams = {
  datasetId?: string;
  sortBy?: FeedSortBy;
  filterBy?: FeedFilterBy;
  page?: SkipLimitPageSpec;
};

export function postProcessDefault<T>(
  data: Record<string, any>[],
  isDateField?: (v) => boolean,
): T[] {
  return _.map(data, row => {
    return _.reduce(
      _.entries(row),
      (acc, [key, value]) => {
        return {
          ...acc,
          [_.camelCase(key)]:
            _.isFunction(isDateField) && isDateField(key)
              ? toDateFromLocaleStringDate(value as string)
              : value,
        };
      },
      {} as T,
    );
  });
}

export function postProcessTotalCount(events: Record<string, any>[]): {
  totalCount: number;
} {
  return {
    totalCount: _.head(events)?.['total_count'] ?? 0,
  };
}
