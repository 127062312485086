import * as React from 'react';
const LayoutIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayoutIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="63.04 82.89 17.71 97.87 17.71 124 110.29 124 110.29 97.87 63.04 82.89"
    />
    <path
      strokeWidth={0}
      d="M47.78,19.39h32.45c1.35,0,2.46-1.1,2.46-2.45v-4.27c0-1.37-1.11-2.45-2.46-2.45h-10.01c0-3.44-2.78-6.21-6.21-6.21s-6.21,2.77-6.21,6.21h-10.02c-1.35,0-2.46,1.08-2.46,2.45v4.27c0,1.35,1.11,2.45,2.46,2.45ZM64,7.76c1.35,0,2.46,1.08,2.46,2.45s-1.1,2.45-2.46,2.45-2.45-1.1-2.45-2.45,1.11-2.45,2.45-2.45Z"
    />
    <path
      strokeWidth={0}
      d="M92.75,14.81h-7.71v2.12c0,2.66-2.17,4.83-4.81,4.83h-32.45c-2.66,0-4.83-2.17-4.83-4.83v-2.12h-7.71c-2.71,0-4.92,2.19-4.92,4.9v70.78l32.97-10.82,34.37,10.76V19.71c0-2.71-2.19-4.9-4.92-4.9ZM86.87,30.05l-2,8.44-6.2-6.2,8.21-2.24ZM83.45,39.91l-10.51,10.51-2.31-2.31,10.51-10.51,2.31,2.31ZM77.47,33.93l2.31,2.31-10.51,10.51-2.31-2.31,10.51-10.51ZM43.71,79.65c-1.11,1.11-2.95,1.01-4.18-.22l-2-1.99c-1.24-1.24-1.33-3.07-.22-4.19l2.01-2.01,6.37,6.44-1.98,1.98ZM41.1,69.7l14.48-14.48,2.31,2.31-14.48,14.48-2.31-2.31ZM47.38,75.98l-2.31-2.31,14.48-14.48,2.31,2.31-14.48,14.48ZM84.13,80.07l-3.81-3.81,2.62-2.62c.29-.29.24-.81-.11-1.16s-.87-.4-1.16-.11l-2.62,2.62-2.54-2.54,1.57-1.57c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-1.57,1.57-2.54-2.54,2.62-2.62c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-2.62,2.62-2.54-2.54,1.57-1.57c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-1.57,1.57-2.54-2.54,2.62-2.62c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-2.62,2.62-2.54-2.54,1.58-1.57c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-1.57,1.57-2.54-2.54,2.62-2.62c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-2.62,2.62-2.54-2.54,1.57-1.57c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-1.58,1.57-2.54-2.54,2.62-2.62c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-2.62,2.62-2.54-2.54,1.57-1.57c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-1.58,1.57-2.54-2.54,2.62-2.62c.29-.29.24-.81-.11-1.16-.35-.35-.87-.4-1.16-.11l-2.62,2.62-3.81-3.81,7.35-7.35,47,47-7.35,7.35Z"
    />
  </svg>
);
export default LayoutIcon;
