import { OrderedQuantityReportItemStatus } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';

export function getOrderedItemsTitlesMap(
  t: TFunction<'simulation'>,
): Record<OrderedQuantityReportItemStatus, string> {
  return {
    ENOUGH: t('Ordered - Ready', { ns: 'simulation' }),
    NOT_ENOUGH: t('Ordered (Gap)', { ns: 'simulation' }),
    NOT_ORDERED: t('Not Ordered (Dead Stock)', { ns: 'simulation' }),
    UNKNOWN_ASSIGNED: t('Unknown (Assigned)', { ns: 'simulation' }),
    UNKNOWN_ORDERED: t('Unknown (Ordered)', { ns: 'simulation' }),
    UNKNOWN_BOTH: t('Unknown', { ns: 'simulation' }),
  };
}
