import { useApolloClient } from '@apollo/client';
import {
  FindItemsByPolicyDocument,
  FindItemsByPolicyQuery,
  FindItemsByPolicyQueryVariables,
  ItemsFilter,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { getPalletOrderPolicyAPIInput } from '../store/itemSet.default';
import {
  itemSetPalletSortPolicyData,
  itemSetPalletSortPolicyDataState,
  itemSetPalletSortPolicyDataStatus,
} from '../store/itemSet.state';
import {
  PalletOrderPolicy,
  PalletOrderRuleItemData,
} from '../store/itemSet.types';

export type LoadPalletPolicyItemsDataParams = {
  itemSetId: string;
  policy: PalletOrderPolicy;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: ItemsFilter;
};
function useLoadPalletPolicyItemsData() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Items by Policy rules`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadPalletPolicyItemsDataParams) => {
        const current = await snapshot.getPromise(itemSetPalletSortPolicyData);
        const currentState = await snapshot.getPromise(
          itemSetPalletSortPolicyDataState,
        );

        const query = client.watchQuery<
          FindItemsByPolicyQuery,
          FindItemsByPolicyQueryVariables
        >({
          query: FindItemsByPolicyDocument,
          variables: {
            input: {
              itemSetId: params.itemSetId,
              policy: getPalletOrderPolicyAPIInput(params.policy),
            },
            filter: params.filter,
            //sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? 100,
            },
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Error);
            return;
          }

          let items: PalletOrderRuleItemData[] =
            data.applyStackingPolicyRules.content.map(i => ({
              ...i.item,
              palletOrderPolicyRuleId: i.stackingPolicyRuleId,
            }));

          if (params.isAppend) {
            items = [...current, ...items];
          }

          set(itemSetPalletSortPolicyData, items);
          set(itemSetPalletSortPolicyDataStatus, AsyncLoadStatus.Ok);
          set(itemSetPalletSortPolicyDataState, {
            ...currentState,
            totalCount: data.applyStackingPolicyRules.totalCount,
          });
        });
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadPalletPolicyItemsDataParams) {
    await initLoading();
    await loadDashDataCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadPalletPolicyItemsData;
