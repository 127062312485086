import { TerminationSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroupList } from '../../../components/inputs/InputGroupList';
import InputNumber from '../../../components/inputs/InputNumber';
import SectionOptional from '../../../components/layout/SectionOptional';
import Callout from '../../../helpContext/Callout';
import { optimisationTerminationSettingsDefault } from '../../store/simulation.default';

export type OptimisationTerminationSettingsProps = {
  children?: React.ReactNode;
  setting: TerminationSettingsFragment;
  onChange: (settings: TerminationSettingsFragment) => void;
};

const OptimisationTerminationSettings: React.FC<
  OptimisationTerminationSettingsProps
> = props => {
  const { t } = useTranslation('simulation');
  const { setting } = props;

  function applyChange(patch: Partial<TerminationSettingsFragment>) {
    props.onChange({
      ...props.setting,
      ...patch,
    });
  }

  const defaults = optimisationTerminationSettingsDefault;
  const hasStepLimit = !_.isNil(setting?.minImprovementRate?.steps);
  const hasDeltaImprovementsLimit = !_.isNil(
    setting?.minImprovementRate?.minImprovementChange,
  );

  return (
    <section data-component="OptimisationTerminationSettings" className="mb-4">
      <Callout type="suggestion">
        {t`Optimisation should stop when first of the following conditions is met:`}
      </Callout>

      <InputGroupList className="space-y-2 mt-4">
        {props.children}

        <SectionOptional
          id={'optimisation-termination-by-rate'}
          title={t`Max Improvement`}
          value={!_.isNil(setting?.targetImprovement)}
          onChange={enabled => {
            console.log(
              'optimisation-termination-by-rate',
              enabled,
              defaults.targetImprovement,
            );
            applyChange({
              targetImprovement: enabled ? defaults.targetImprovement : null,
            });
          }}
        >
          <InputNumber
            title={t`Max Improvement Percentage`}
            value={setting?.targetImprovement ?? 0}
            disabled={_.isNil(setting?.targetImprovement)}
            onChange={v => applyChange({ targetImprovement: v })}
            unitOfMeasure="%"
          />

          <SectionOptional
            inset
            id={'optimisation-termination-by-improvement'}
            title={t`Advanced Settings`}
            value={!_.isNil(setting?.minImprovementRate)}
            onChange={enabled =>
              applyChange({
                minImprovementRate: enabled
                  ? defaults.minImprovementRate
                  : null,
              })
            }
          >
            <InputGroupList hasSpacing>
              <InputNumber
                title={t`Rate of Change`}
                unitOfMeasure="%"
                value={setting?.minImprovementRate?.minImprovementChange}
                disabled={_.isNil(setting?.minImprovementRate)}
                onChange={v =>
                  applyChange({
                    minImprovementRate: {
                      ...setting?.minImprovementRate,
                      minImprovementChange: v,
                    },
                  })
                }
              />
              <InputNumber
                title={t`In total Steps`}
                value={setting?.minImprovementRate?.steps ?? 0}
                disabled={_.isNil(setting?.minImprovementRate)}
                onChange={v =>
                  applyChange({
                    minImprovementRate: {
                      ...setting?.minImprovementRate,
                      steps: v,
                    },
                  })
                }
              />
            </InputGroupList>
          </SectionOptional>
        </SectionOptional>

        <SectionOptional
          id={'optimisation-termination-by-time'}
          title={t`Max Duration`}
          value={!_.isNil(setting?.timeLimit)}
          onChange={enabled =>
            applyChange({
              timeLimit: enabled ? defaults.timeLimit : null,
            })
          }
        >
          <InputNumber
            title={t`Duration (min)`}
            value={(setting?.timeLimit ?? 0) / 60}
            onChange={v => applyChange({ timeLimit: v * 60 })}
            disabled={_.isNil(setting?.timeLimit)}
          />
        </SectionOptional>
      </InputGroupList>
    </section>
  );
};

export default OptimisationTerminationSettings;
