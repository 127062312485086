import {
  SkipLimitPageSpec,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { HeatmapAggregationFn } from '../../../common/heatmap.types';
import { ActualityHqField } from './actualityHq';
import {
  ActualityHqQueryBuilderParams,
  getHqQueryBuilder,
} from './actualityQueryBuilder';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type ActualityHeatmapDataRow = {
  locationId: string;
  skuKey: string;
  consignee: string;
  sku: string;
  dimensionValue: number | string | bigint;
};
export type ActualityHeatmapDataColumn = keyof ActualityHeatmapDataRow;

export type ActualityHeatmapDataSortBy = {
  direction?: SortDirection;
  field: ActualityHeatmapDataColumn;
};

export type ActualityHeatmapDataQueryParams = ActualityHqQueryBuilderParams & {
  groupBy: ActualityHqField;
  locationLevel?: number;
  bayId?: string;
  aggregationFn: HeatmapAggregationFn;
  page?: SkipLimitPageSpec;
  sortBy?: ActualityHeatmapDataSortBy;
};

export function getActualityHeatmapDataQuery(
  params: ActualityHeatmapDataQueryParams,
) {
  let query = getHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) =>
      _.compact([
        'locationId',
        'skuKey',
        'consignee',
        'sku',
        params.aggregationFn === 'max'
          ? fn.max(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'min'
          ? fn.min(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'avg'
          ? fn.avg(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'sum'
          ? fn.sum(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'count'
          ? fn.count('eventId').as('dimensionValue')
          : null,
        params.aggregationFn === 'count-locations'
          ? fn.count<number>(params.groupBy).distinct().as('dimensionValue')
          : null,
      ]),
    )
    .where('eventType', 'not in', params.informationalEventTypes)
    .groupBy(['locationId', 'skuKey', 'consignee', 'sku'])
    .orderBy(
      params.sortBy?.field ?? 'dimensionValue',
      params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
    )
    .$if(!_.isNil(params.page), db => db.offset(params.page.skip ?? 0))
    .$if(!_.isNil(params.page), db => db.limit(params.page.limit ?? 100));

  if (!_.isNil(params.locationLevel)) {
    query = query.where('locationLevel', '=', params.locationLevel);
  }
  if (!_.isNil(params.bayId)) {
    query = query.where('locationBayId', '=', params.bayId);
  }
  return query;
}

export function getActualityHeatmapTotalQuery(
  params: ActualityHeatmapDataQueryParams,
) {
  let query = getHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) => [fn.countAll<number>().as('totalCount')])
    .groupBy(['locationId', 'skuKey']);

  if (!_.isNil(params.locationLevel)) {
    query = query.where('locationLevel', '=', params.locationLevel);
  }
  if (!_.isNil(params.bayId)) {
    query = query.where('locationBayId', '=', params.bayId);
  }
  return query;
}

export function postProcessActualityHeatmapData(data: Record<string, any>[]) {
  return postProcessDefault<ActualityHeatmapDataRow>(data);
}
