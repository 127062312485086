import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TwTheme } from '../../Tw';
import TitleSection from '../components/layout/TitleSection';
import { PolicyWell } from '../components/policies/PolicyWell';
import RuleContainer from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import { STACKING_POLICY_DEFAULT_RULE_ID } from '../simulation/store/stackingPolicy/stackingPolicy.default';
import {
  stackingPolicyDocument,
  stackingPolicySelectedIdentity,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';
import StackingPolicyItemsStats from './StackingPolicyItemsStats';
import StackingPolicySubcategoriesSummary from './StackingPolicySubcategoriesSummary';

const stackingPriorityColors = TwTheme.extend.colors.priority;

const StackingPolicyFallbackRule: React.FC = () => {
  const { t } = useTranslation('simulation');
  const policy = useRecoilValue(stackingPolicyDocument);
  const ruleId = STACKING_POLICY_DEFAULT_RULE_ID;
  const rule = policy.fallbackRule;
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    stackingPolicySelectedIdentity,
  );
  const canUpdate = useRecoilValue(simulationIsEditable);

  function selectRule() {
    setSelectedIdentity(
      isSelectedRule
        ? null
        : {
            ruleId,
          },
    );
  }
  const isSelectedRule = ruleId === selectedIdentity?.ruleId;

  return (
    <TitleSection inPanelView title={t`Default (Middle)`}>
      <PolicyWell
        data-component="PolicyWellRules"
        className={classNames('bg-issue-transparent flex-1')}
        classNameChildren={classNames('space-y-2 pb-10')}
      >
        <RuleContainer
          dataComponent="StackingPolicyRule"
          key={`stacking-policy-rule-${ruleId}`}
          id={`stacking-policy-rule-${ruleId}`}
          title={t`Default rule`}
          isRemovable={false}
          hasIcon={true}
          hasColorMode={false}
          isCollapsible={false}
          isActive={isSelectedRule}
          hasArrowUp={false}
          hasArrowDown={false}
          onClick={selectRule}
          isDisabled={!canUpdate}
          color={stackingPriorityColors.middle}
        >
          <RuleSectionContainer
            isSelected={isSelectedRule}
            onClick={selectRule}
          >
            <StackingPolicyItemsStats
              ruleId={ruleId}
              color={stackingPriorityColors.middle}
            />

            <StackingPolicySubcategoriesSummary
              ruleId={ruleId}
              subcategories={rule?.subcategories}
            />
          </RuleSectionContainer>
        </RuleContainer>
      </PolicyWell>
    </TitleSection>
  );
};

export default StackingPolicyFallbackRule;
