import * as React from 'react';
const CursorTransformDuplicateIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorTransformDuplicateIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,56v-16h-16v6h-26v-26h6V4h-16v6H20v-6H4v16h6v52h-6v16h16v-6h26v26h-6v16h16v-6h52v6h16v-16h-6v-52h6ZM20,78v-6h-6V20h6v-6h52v6h6v52h-6v6H20ZM114,108h-6v6h-52v-6h-6v-26h22v6h16v-16h-6v-22h26v6h6v52Z"
    />
    <rect width={48} height={48} x={22} y={22} strokeWidth={0} />
  </svg>
);
export default CursorTransformDuplicateIcon;
