import _ from 'lodash';
import React from 'react';
import { useRecoilState } from 'recoil';
import ErrorPopover from './components/ErrorPopover';
import { errorContainer } from './store/error.state';

const ErrorWatcher: React.FC = props => {
  const [errors, setErrors] = useRecoilState(errorContainer);

  return (
    <ErrorPopover
      errors={errors}
      onClose={indexes => {
        setErrors(errors.filter((e, i) => !_.includes(indexes, i)));
      }}
    />
  );
};
export default ErrorWatcher;
