import { createCalendar } from '@internationalized/date';
import { useRef } from 'react';
import { useDateField, useLocale } from 'react-aria';
import { DateFieldStateOptions, useDateFieldState } from 'react-stately';
import DateSegmentField from './DateSegment';
import classNames from 'classnames';

export type InputDateFieldProps = Partial<DateFieldStateOptions> & {};

const InputDateField: React.FC<InputDateFieldProps> = props => {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div
      data-component="InputDateField"
      {...fieldProps}
      ref={ref}
      className={classNames('flex items-center')}
    >
      {state.segments.map((segment, i) => (
        <DateSegmentField key={i} segment={segment} state={state} />
      ))}
    </div>
  );
};

export default InputDateField;
