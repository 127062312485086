import {
  LayoutLocationDataFragment,
  LoadLayoutDataQuery,
  LoadLayoutDataQueryVariables,
  LocationPortalDataFragment,
  LocationPortalType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type LayoutLocationsExportJobParams = ExportJobParams<
  LoadLayoutDataQueryVariables,
  LayoutDataConverterConfig
>;

export const layoutLocationsExportJobParams: LayoutLocationsExportJobParams = {
  converterId: 'LAYOUT_LOCATIONS',
  query: null,
  config: null,
  variables: null,
  filename: 'layout-locations.csv',
};

export type LayoutDataRow = Omit<
  LayoutLocationDataFragment,
  'aisle' | 'bay'
> & {
  bay: string;
  aisle: string;
  portal: string;
};

export type LayoutDataConverterConfig = {
  columnsConfig: ColumnConfigBase<LayoutDataRow>[];
  dictionaries: {
    portalTypes: Record<LocationPortalType, string>;
  };
};

const getAccessAisles = (
  portals: LocationPortalDataFragment[],
  portalTypes: Record<LocationPortalType, string>,
) => {
  return _(portals)
    .map(p => `${portalTypes[p.type]}: ${p.aisle.title}`)
    .join(', ');
};

export const getLayoutLocationTableRows = (
  data: LayoutLocationDataFragment[],
  config: LayoutDataConverterConfig,
) => {
  const reduceItemSet = (locationData: LayoutLocationDataFragment) => {
    return {
      ...locationData,
      bay: locationData.bay?.title,
      aisle: locationData.aisle?.title,
      portal: getAccessAisles(
        locationData?.portals,
        config.dictionaries.portalTypes,
      ),
    };
  };

  return _.flatMap(data, reduceItemSet);
};

export const LAYOUT_LOCATIONS_EXPORT_CONVERTER: ExportConverter<
  LoadLayoutDataQuery,
  LayoutDataConverterConfig
> = (input, config) => {
  const data = getLayoutLocationTableRows(
    input.layout.locations.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
