import { AgentEventSettingsDefinition } from './agent.types';

export const AgentSettingsDefault: AgentEventSettingsDefinition = {
  id: undefined, // all agents
  roleDefaults: {
    metric: {
      preHandling: {
        timeFixed: 50,
        timePerOrder: null,
        timePerPicklistLine: null,
        timePerUom: null,
      },
      handling: {
        timeFixed: 10,
        timePerVolume: null,
        timePerWeight: null,
      },
      handlingPreparation: {
        timeFixed: null,
        timePerUom: null,
      },
      handlingCompletion: {
        timeFixed: null,
        timePerUom: null,
      },
      reordering: {
        timeFixed: null,
        timePerVolume: null,
        timePerWeight: null,
      },
      postHandling: {
        timeFixed: null,
        timePerOrder: null,
        timePerPicklistLine: null,
        timePerUom: null,
      },
    },
    imperial: {
      preHandling: {
        timeFixed: 50,
        timePerOrder: null,
        timePerPicklistLine: null,
        timePerUom: null,
      },
      handling: {
        timeFixed: 10,
        timePerVolume: null,
        timePerWeight: null,
      },
      handlingPreparation: {
        timeFixed: null,
        timePerUom: null,
      },
      handlingCompletion: {
        timeFixed: null,
        timePerUom: null,
      },
      reordering: {
        timeFixed: null,
        timePerVolume: null,
        timePerWeight: null,
      },
      postHandling: {
        timeFixed: null,
        timePerOrder: null,
        timePerPicklistLine: null,
        timePerUom: null,
      },
    },
  },
  eventDefaults: {
    metric: {
      specs: {
        horizontalTravelling: {
          speed: {
            speedLaden: 100,
            speedUnladen: 100,
          },
        },
        verticalTravelling: {
          speedUp: {
            speedLaden: 100,
            speedUnladen: 100,
          },
          speedDown: {
            speedLaden: 100,
            speedUnladen: 100,
          },
        },
        capacity: {
          maxVolume: 200000,
          maxWeight: 2000,
        },
        powerSource: {
          energyConsumptionPerSecond: 0.0025,
        },
      },
    },
    imperial: {
      specs: {
        horizontalTravelling: {
          speed: {
            speedLaden: 100,
            speedUnladen: 100,
          },
        },
        verticalTravelling: {
          speedUp: {
            speedLaden: 100,
            speedUnladen: 100,
          },
          speedDown: {
            speedLaden: 100,
            speedUnladen: 100,
          },
        },
        capacity: {
          maxVolume: 168000,
          maxWeight: 4500,
        },
        powerSource: {
          energyConsumptionPerSecond: 0.0025,
        },
      },
    },
  },
};
