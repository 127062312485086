import _ from 'lodash';
import { selectorFamily } from 'recoil';
import { persistAtom } from '../common/recoil/persistAtom';
import {
  COLLAPSIBLE_OPEN_PREFIX,
  CollapsibleId,
  CollapsibleState,
  CollapsibleStateMap,
  getDefaultCollapsibleState,
} from './collapsible.default';

export const collapsibleStateAtom = persistAtom<CollapsibleStateMap>({
  key: 'warebee-collapsible-state-inner',
  default: getDefaultCollapsibleState(),
});

export const collapsibleState = selectorFamily<CollapsibleState, CollapsibleId>(
  {
    key: 'warebee-collapsible-state',
    get:
      (id: CollapsibleId) =>
      ({ get }) => {
        return (
          get(collapsibleStateAtom)[id] || {
            isCollapsed: !_.some(COLLAPSIBLE_OPEN_PREFIX, prefix =>
              id?.startsWith(prefix),
            ),
          }
        );
      },

    set:
      (id: CollapsibleId) =>
      ({ get, set }, value: CollapsibleState) => {
        const current = get(collapsibleStateAtom);
        set(collapsibleStateAtom, { ...current, [id]: value });
      },
  },
);

// export const collapsibleFilterState = atom<CollapsibleFilterStateMap>({
//   key: 'warebee-collapsible-filter-state',
//   default: {},
// });
