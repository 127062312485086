import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer from '../../containers/SidebarContainer';
import { sidebarStateByType } from '../../store/sidebar.state';
import { assignmentMenuItems } from '../store/assignment.default';
import {
  assignmentDocumentId,
  assignmentSelectedMenuItemId,
} from '../store/assignment.state';
import { AssignmentMenuItemId } from '../store/assignment.types';

type AssignmentMenuSidebarProps = {
  isMinimised: boolean;
};

const AssignmentMenuSidebar: React.FC<AssignmentMenuSidebarProps> = props => {
  const { t } = useTranslation('app');
  const assignmentId = useRecoilValue(assignmentDocumentId);
  const [activeMenuId, setActiveMenuId] = useRecoilState(
    assignmentSelectedMenuItemId(assignmentId),
  );
  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-assignment-main'),
  );

  useEffect(() => {
    if (!sidebarState.isPinned) {
      setSidebarState({ ...sidebarState, isCollapsed: false });
    }
  }, []);

  function changeActiveMenu(menuId: AssignmentMenuItemId) {
    setActiveMenuId(menuId);
    if (!sidebarState.isPinned) {
      setSidebarState({ ...sidebarState, isCollapsed: false });
    }
  }

  return (
    <ContainerScroll className="flex flex-col">
      <ul
        data-component="AssignmentMenuSidebar"
        className={`relative flex flex-col text-start px-1`}
      >
        {assignmentMenuItems(t).map(item => {
          return (
            <SidebarMenuItem
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeMenuId}
              treeLine={item?.treeLine}
              treeLineStart={item?.treeLineStart}
              treeLineEnd={item?.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              //inProgress={status?.inProgress}
              stepCounter={item?.stepCounter}
              disabled={item?.disabled}
              isHidden={item?.isHidden}
              //locked={status?.isLocked}
              onClick={() => changeActiveMenu(item.id)}
            />
          );
        })}
      </ul>
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('app');
  return (
    <SidebarContainer
      key={'sidebar-assignment-menu'}
      type={'sidebar-assignment-menu'}
      title={t`Menu`}
      size={'xs'}
      widthAutoMinimised
      withMenuIcons={<AssignmentMenuSidebar isMinimised={true} />}
    >
      <AssignmentMenuSidebar isMinimised={false} />
    </SidebarContainer>
  );
};
