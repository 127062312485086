import Flatten from '@flatten-js/core';
import {
  RoutingPolicy,
  RoutingPolicyDirectionThreshold,
  RoutingPolicyFeatureRule,
} from '@warebee/shared/engine-model';
import { keyBy } from 'lodash';
import { loadVector } from './geometry.engine';
import { NavigablePolygon, RouteInfo } from './navigation.common';

export interface LocalNavigationSettings {
  ignoreAllowedDirection?: boolean;
}

export interface LocalNavigationAlgorithm {
  findRoute(
    polygon: NavigablePolygon,
    from: Flatten.Point,
    to: Flatten.Point,
    settings?: LocalNavigationSettings,
  ): RouteInfo | undefined;
}

export interface RoutingSettings {
  featureRules?: RoutingPolicyFeatureRule[];
  threshold?: RoutingPolicyDirectionThreshold;
}

export function routingSettingsFromPolicy(
  routingPolicy: RoutingPolicy | null,
  agentId: string | null,
): RoutingSettings | null {
  if (!routingPolicy) {
    return null;
  }

  const effectiveRule =
    (agentId && routingPolicy.rules?.find(r => r.agentIds.includes(agentId))) ||
    routingPolicy.defaultRule;
  if (!effectiveRule) {
    return null;
  }

  return {
    featureRules: effectiveRule.featureRules,
    threshold: routingPolicy.threshold,
  };
}

function degToRad(angle: number) {
  return (angle * Math.PI) / 180;
}

const BASE_THRESHOLD_ANGLE = Math.PI / 2;

class ParsedFeatureRule {
  private allowed: boolean;
  private direction?: Flatten.Vector;
  private angleThresholdRads: number;
  private distanceThreshold?: number;

  constructor(
    rule: RoutingPolicyFeatureRule,
    defaultThreshold?: RoutingPolicyDirectionThreshold,
  ) {
    this.direction = rule.direction ? loadVector(rule.direction) : null;

    this.allowed =
      this.direction && !(this.direction.x == 0 && this.direction.y == 0);

    const effectiveAngleThreshold =
      rule.threshold?.angle ?? defaultThreshold?.angle;
    this.angleThresholdRads = effectiveAngleThreshold
      ? degToRad(effectiveAngleThreshold)
      : 0;

    this.distanceThreshold =
      rule.threshold?.distance ?? defaultThreshold?.distance;
  }

  isAllowed(from: Flatten.Point, to: Flatten.Point): boolean {
    if (!this.direction) {
      return true;
    }

    if (!this.allowed) {
      return false;
    }

    const navDirection = new Flatten.Vector(from, to);

    if (Flatten.Utils.EQ_0(navDirection.length)) {
      return true;
    }

    if (this.distanceThreshold) {
      const scalarProjection = navDirection.dot(this.direction.normalize());
      return scalarProjection >= -this.distanceThreshold;
    }

    let angle = navDirection.angleTo(this.direction);
    if (angle > Math.PI) {
      angle = 2 * Math.PI - angle;
    }
    return angle - this.angleThresholdRads <= BASE_THRESHOLD_ANGLE;
  }
}

/**
 * Local navigation algorithm that always returns direct route with Euclidean distance.
 * Suitable for convex polygons without obstacles.
 */
export class DirectLocalNavigationAlgorithm
  implements LocalNavigationAlgorithm
{
  private readonly featureRuleById: Record<string, RoutingPolicyFeatureRule>;
  private readonly parsedFeatureRuleById: Record<
    string,
    ParsedFeatureRule | null
  > = {};

  constructor(private readonly settings?: RoutingSettings) {
    this.featureRuleById = keyBy(
      settings?.featureRules || [],
      r => r.featureId,
    );
  }

  private isAllowed(featureId: string, from: Flatten.Point, to: Flatten.Point) {
    let rule = this.parsedFeatureRuleById[featureId];

    if (rule === undefined) {
      const rawRule = this.featureRuleById[featureId];
      if (rawRule) {
        rule = new ParsedFeatureRule(rawRule, this.settings?.threshold);
        this.parsedFeatureRuleById[featureId] = rule;
      } else {
        this.parsedFeatureRuleById[featureId] = null;
      }
    }

    return rule ? rule.isAllowed(from, to) : true;
  }

  findRoute(
    polygon: NavigablePolygon,
    from: Flatten.Point,
    to: Flatten.Point,
    localNavSettings?: LocalNavigationSettings,
  ): RouteInfo {
    const distance = from.distanceTo(to)[0];

    if (!localNavSettings?.ignoreAllowedDirection) {
      if (!this.isAllowed(polygon.id, from, to)) {
        return null;
      }
    }

    return { waypoints: [], distance };
  }
}
