import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AssignmentPolicyRuleTag from '../../../assignmentPolicy/AssignmentPolicyRuleTag';
import InboxZero from '../../../components/InboxZero';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import PanelContainer from '../../../containers/PanelContainer';
import {
  assignmentPolicyBayLocations,
  assignmentPolicyDocument,
} from '../../store/assignmentPolicy.state';

export type LocationPropertiesAssignmentPolicyProps = {
  locationId: string;
};

const LocationPropertiesAssignmentPolicy: React.FC<
  LocationPropertiesAssignmentPolicyProps
> = props => {
  const { t } = useTranslation('simulation');
  const locationMap = useRecoilValue(assignmentPolicyBayLocations);
  const policy = useRecoilValue(assignmentPolicyDocument);
  const rulesIndexes = locationMap[props.locationId]?.allMatchingFilters;

  return (
    <PanelContainer
      id="panel-location-assignment-policy"
      title={t`Assignment Policy`}
      collapsible
      hasPadding
    >
      <StatListHeader title={t`Rules`} />
      {_.isEmpty(rulesIndexes) && (
        <InboxZero message={t`No Matched Rules`} selfCenter />
      )}

      <div className="mt-2 space-y-2">
        {_.map(rulesIndexes, ruleIndex => {
          return (
            <AssignmentPolicyRuleTag
              key={`rule-tag-${ruleIndex}`}
              ruleId={policy.rules[ruleIndex]?.id}
              isPrimary
            />
          );
        })}
      </div>
    </PanelContainer>
  );
};

export default LocationPropertiesAssignmentPolicy;
