import * as React from 'react';
const BayIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BayIcon"
    {...props}
  >
    <rect width={128} height={3.09} y={122.98} strokeWidth={0} />
    <rect width={106.09} height={5.31} x={9.66} y={45.2} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="115.76 43.11 115.76 52.6 112.24 51.71 112.24 44 115.76 43.11"
    />
    <polygon
      strokeWidth={0}
      points="9.66 52.6 9.66 43.1 13.18 44 13.18 51.7 9.66 52.6"
    />
    <rect
      width={27.71}
      height={22.51}
      x={17.11}
      y={13.38}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M17.11,37.69v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={49.11}
      y={13.38}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M49.11,37.69v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={81.11}
      y={13.38}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M81.11,37.69v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect width={106.09} height={5.31} x={9.66} y={84.42} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="115.76 82.33 115.76 91.82 112.24 90.92 112.24 83.22 115.76 82.33"
    />
    <polygon
      strokeWidth={0}
      points="9.66 91.82 9.66 82.32 13.18 83.22 13.18 90.92 9.66 91.82"
    />
    <rect
      width={27.71}
      height={22.51}
      x={17.11}
      y={52.6}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M17.11,76.91v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={49.11}
      y={52.6}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M49.11,76.91v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={81.11}
      y={52.6}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M81.11,76.91v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={17.11}
      y={91.82}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M17.11,116.13v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={49.11}
      y={91.82}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M49.11,116.13v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={27.71}
      height={22.51}
      x={81.11}
      y={91.82}
      strokeWidth={0}
      rx={1.29}
      ry={1.29}
    />
    <path
      strokeWidth={0}
      d="M81.11,116.13v5.15h3.57v-3.36h7.56v3.36h5.03v-3.36h7.56v3.36h4v-5.15c0-.48-.39-.87-.87-.87h-25.98c-.48,0-.87.39-.87.87Z"
    />
    <rect
      width={116.76}
      height={5.31}
      x={-49.95}
      y={61.81}
      strokeWidth={0}
      transform="translate(-56.04 72.9) rotate(-90)"
    />
    <polygon
      strokeWidth={0}
      points="13.17 122.85 3.68 122.85 4.57 119.34 12.28 119.34 13.17 122.85"
    />
    <rect
      width={116.76}
      height={5.31}
      x={58.62}
      y={61.81}
      strokeWidth={0}
      transform="translate(52.52 181.47) rotate(-90)"
    />
    <polygon
      strokeWidth={0}
      points="121.74 122.85 112.24 122.85 113.14 119.34 120.84 119.34 121.74 122.85"
    />
  </svg>
);
export default BayIcon;
