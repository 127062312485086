import * as React from 'react';
const PolicyAllocateIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAllocateIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.06-.16.16-.31.28-.43L60.82,11.99c.88-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M54.36,34.08l6.78-6.78v36.93c0,1.59,1.16,2.74,2.75,2.75h12.65c1.51,0,2.74-1.23,2.74-2.74s-1.23-2.75-2.74-2.75h-9.91V27.49s6.71,6.71,6.71,6.71c1.07,1.07,4.62-3.05,4.26-3.5-.09-.12-.2-.22-.32-.32l-11.46-11.58c-.48-.48-1.19-.84-1.91-.84-.72,0-1.55,0-2.03.6h0s-11.46,11.58-11.46,11.58c-1.07,1.07,2.86,5.01,3.94,3.94Z"
    />
    <path
      strokeWidth={0}
      d="M110.44,62.73l-11.61-11.49c-1.08-1.08-2.87-1.08-3.95,0-1.08,1.08-1.08,2.87,0,3.95l6.82,6.82h-7.74c.22,1.4.45,2.71.32,5.38h7.31l-6.82,6.82c-1.08,1.08-1.08,2.87,0,3.95.6.6,1.2.84,1.92.84s1.44-.24,1.91-.84l11.61-11.49c.48-.48.84-1.2.84-1.91,0-.72,0-1.56-.6-2.03h0Z"
    />
    <path
      strokeWidth={0}
      d="M70.4,39.39v7.19c8.54,2.66,14.76,10.65,14.76,20.06,0,11.58-9.42,21.01-21.01,21.01-11.58,0-21.01-9.42-21.01-21.01,0-9.41,6.22-17.39,14.76-20.06v-7.25c-12.6,2.75-22.02,13.99-22.02,27.31,0,15.6,12.66,28.27,28.26,28.27s28.11-12.67,28.11-28.11c0-13.43-9.4-24.57-21.87-27.41Z"
    />
  </svg>
);
export default PolicyAllocateIcon;
