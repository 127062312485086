import * as React from 'react';
const AisleOrderIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AisleOrderIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M19,118.14c-.05.05-.11.1-.17.14-.03.02-.06.03-.09.05-.03.02-.07.04-.1.05-.04.02-.08.02-.11.04-.03,0-.06.02-.09.03-.14.03-.29.03-.44,0-.03,0-.06-.02-.09-.03-.04-.01-.08-.02-.11-.04-.04-.02-.07-.04-.1-.06-.03-.02-.06-.03-.09-.05-.06-.04-.12-.09-.17-.14l-8.94-8.94c-.44-.44-.44-1.14,0-1.58.22-.22.5-.33.79-.33s.57.11.79.33l7.03,7.03v-16.86c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v16.86s7.03-7.03,7.03-7.03c.22-.22.5-.33.79-.33s.57.11.79.33c.44.44.44,1.14,0,1.58l-8.94,8.94Z"
    />
    <path
      strokeWidth={0}
      d="M17.42,21.6c.05-.05.11-.1.17-.14.03-.02.06-.03.09-.05.03-.02.07-.04.1-.05.04-.02.08-.02.11-.04.03,0,.06-.02.09-.03.14-.03.29-.03.44,0,.03,0,.06.02.09.03.04.01.08.02.11.04.04.02.07.04.1.06.03.02.06.03.09.05.06.04.12.09.17.14l8.94,8.94c.44.44.44,1.14,0,1.58-.22.22-.5.33-.79.33s-.57-.11-.79-.33l-7.03-7.03v16.86c0,.62-.5,1.12-1.12,1.12s-1.12-.5-1.12-1.12v-16.86s-7.03,7.03-7.03,7.03c-.22.22-.5.33-.79.33s-.57-.11-.79-.33c-.44-.44-.44-1.14,0-1.58l8.94-8.94Z"
    />
    <rect
      width={14.82}
      height={86}
      x={70.72}
      y={-13.43}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(107.7 -48.56) rotate(90)"
    />
    <rect
      width={14.82}
      height={86}
      x={70.72}
      y={54.72}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(175.84 19.59) rotate(90)"
    />
    <rect
      width={14.82}
      height={86}
      x={70.72}
      y={71.57}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(192.7 36.45) rotate(90)"
    />
    <rect
      width={14.82}
      height={86}
      x={70.72}
      y={-30.59}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(90.54 -65.72) rotate(90)"
    />
    <rect
      width={14.82}
      height={76}
      x={75.72}
      y={42.86}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(163.98 -2.27) rotate(90)"
    />
    <rect
      width={14.82}
      height={66}
      x={80.72}
      y={13.63}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(134.76 -41.49) rotate(90)"
    />
    <rect
      width={14.82}
      height={86}
      x={70.72}
      y={21}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(142.13 -14.13) rotate(90)"
    />
  </svg>
);
export default AisleOrderIcon;
