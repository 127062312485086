import * as React from 'react';
const DistributeVerticalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DistributeVerticalIcon"
    {...props}
  >
    <polygon strokeWidth={0} points="115.28 95 122 101.72 122 95 115.28 95" />
    <polygon
      strokeWidth={0}
      points="81.34 95 109.34 123 122 123 122 122.08 94.92 95 81.34 95"
    />
    <polygon
      strokeWidth={0}
      points="98.31 95 122 118.69 122 105.11 111.89 95 98.31 95"
    />
    <polygon
      strokeWidth={0}
      points="60.97 95 47.4 95 75.4 123 88.97 123 60.97 95"
    />
    <polygon
      strokeWidth={0}
      points="77.94 95 64.37 95 92.37 123 105.94 123 77.94 95"
    />
    <polygon strokeWidth={0} points="47 98 47 111.57 58.43 123 72 123 47 98" />
    <polygon strokeWidth={0} points="47 123 55.03 123 47 114.97 47 123" />
    <polygon strokeWidth={0} points="81.34 6 92 16.66 92 6 81.34 6" />
    <polygon strokeWidth={0} points="60.97 6 47.4 6 75.4 34 88.97 34 60.97 6" />
    <polygon
      strokeWidth={0}
      points="77.94 6 64.37 6 92 33.63 92 20.06 77.94 6"
    />
    <polygon strokeWidth={0} points="47 9 47 22.57 58.43 34 72 34 47 9" />
    <polygon strokeWidth={0} points="47 34 55.03 34 47 25.97 47 34" />
    <path
      strokeWidth={0}
      d="M24.16,90.23c-.08.08-.16.14-.25.2-.04.03-.09.05-.13.07-.05.03-.1.06-.15.08-.05.02-.11.04-.17.05-.05.01-.09.03-.14.04-.21.04-.43.04-.64,0-.05,0-.09-.03-.14-.04-.06-.02-.11-.03-.17-.05-.05-.02-.1-.05-.15-.08-.04-.02-.09-.04-.13-.07-.09-.06-.17-.13-.25-.2l-13.08-13.08c-.64-.64-.64-1.67,0-2.31.32-.32.74-.48,1.16-.48s.84.16,1.16.48l10.29,10.29v-24.67c0-.9.73-1.64,1.63-1.64s1.64.73,1.64,1.64v24.67s10.29-10.29,10.29-10.29c.32-.32.74-.48,1.16-.48s.84.16,1.16.48c.64.64.64,1.67,0,2.31l-13.08,13.08Z"
    />
    <path
      strokeWidth={0}
      d="M21.84,38.77c.08-.08.16-.14.25-.2.04-.03.09-.05.13-.07.05-.03.1-.06.15-.08.05-.02.11-.04.17-.05.05-.01.09-.03.14-.04.21-.04.43-.04.64,0,.05,0,.09.03.14.04.06.02.11.03.17.05.05.02.1.05.15.08.04.02.09.04.13.07.09.06.17.13.25.2l13.08,13.08c.64.64.64,1.67,0,2.31-.32.32-.74.48-1.16.48s-.84-.16-1.16-.48l-10.29-10.29v24.67c0,.9-.73,1.64-1.64,1.64s-1.64-.73-1.64-1.64v-24.67s-10.29,10.29-10.29,10.29c-.32.32-.74.48-1.16.48s-.84-.16-1.16-.48c-.64-.64-.64-1.67,0-2.31l13.08-13.08Z"
    />
    <rect width={36} height={4.44} x={4.67} y={29.52} strokeWidth={0} />
    <rect width={36} height={4.44} x={4.67} y={94.52} strokeWidth={0} />
  </svg>
);
export default DistributeVerticalIcon;
