import {
  ItemSetIssueInvalidUomReason,
  LoadItemSetUomIssueDataDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  getUOMIssuesTableRows,
  UOMIssuesConverterConfig,
  UOMIssuesDataRow,
  UOMIssuesExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';
import { ContainerScroll } from '../../../components/layout/ContainerScroll';
import useLoadUOMsIssues from '../../hooks/useLoadUOMsIssues';
import { getUOMIssueDescriptors } from '../../store/simulation.issues.helper';
import {
  simulationIssueSelected,
  simulationIssuesUOMsData,
  simulationIssuesUOMsDataStatus,
} from '../../store/simulation.issues.state';
import { simulationCurrent } from '../../store/simulation.state';
import DatasetIssueTag from './DatasetIssueTag';
import ItemTag from '../../tags/ItemTag';

const UOMIssuesTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const issuesData = useRecoilValue(simulationIssuesUOMsData);
  const issuesDataLoadStatus = useRecoilValue(simulationIssuesUOMsDataStatus);
  const selectedIssue = useRecoilValue(simulationIssueSelected);
  const [loadCallback, cancelLoad] = useLoadUOMsIssues();
  const [runExportCSV] = useRunExportJobMutation();
  const searchValues = {};

  const tableTitle = t`UOMs with issues`;

  function callDataLoad(page = { isAppend: false, skip: 0 }) {
    if (selectedIssue?.group !== 'itemSet') return;

    const filter /*: OrderLinesFilter*/ = {
      consigneeContains: searchValues['consignee'],
      skuContains: searchValues['sku'],
      skuGroupContains: searchValues['skuGroup'],
    };

    cancelLoad();
    loadCallback({
      //filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad();
  }, [selectedIssue]);

  const columnsConfig: ColumnConfig<UOMIssuesDataRow>[] = [
    {
      field: 'reason',
      title: t`Issue`,
      hiddenInExport: true,
      render: v => {
        if (_.isNil(v)) {
          return '-';
        }
        return _.map(v, reason => (
          <DatasetIssueTag
            key={reason}
            identity={{
              group: 'itemSet',
              reason: reason as any,
            }}
          />
        ));
      },
    },
    {
      field: 'reasonString',
      title: t`Issue`,
      hiddenInBrowser: true,
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'uom',
      title: t`UOM`,
    },
  ];

  function onLoadNext() {
    callDataLoad({
      isAppend: true,
      skip: itemsCount,
    });
  }

  const converterConfig: UOMIssuesConverterConfig = {
    columnsConfig,
    dictionaries: {
      reasons: _.reduce(
        _.values(getUOMIssueDescriptors(t)),
        (acc, i) => ({
          ...acc,
          [i.id]: i.tag,
        }),
        {} as Record<ItemSetIssueInvalidUomReason, string>,
      ),
    },
  };

  async function startExportCSV() {
    const variables: UOMIssuesExportJobParams = {
      ...UOMIssuesExportJobParams,
      query: LoadItemSetUomIssueDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        key: {
          assignmentId: sim.assignment.id,
          itemSetId: sim.itemSet.id,
        },
        page: null,
        reason: selectedIssue?.reason as any,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = issuesDataLoadStatus === AsyncLoadStatus.Loading;
  const itemsCount = issuesData?.content?.length || 0;
  const totalCount = issuesData?.totalCount ?? 0;

  const flattenData = getUOMIssuesTableRows(
    issuesData?.content,
    converterConfig,
  );

  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        title={tableTitle}
        id={selectedIssue?.reason}
        columnsConfig={columnsConfig}
        keyFields={['itemId', 'uom']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={_.noop}
        totalCount={totalCount}
        searchValues={searchValues}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        isSticky
        hasCounter
      />
    </ContainerScroll>
  );
};

export default UOMIssuesTable;
