import {
  LoadBalancingPolicyFragment,
  LoadBalancingRuleFragment,
  LoadBalancingRuleSummaryWithLocationsFragment,
  LocationFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../layout/viewer/store/viewer.state';
import {
  simulationAnalyzeResult,
  simulationCurrent,
} from '../simulation/store/simulation.state';
import {
  simulationOptimisationPolicyTabKey,
  simulationWizardSelectedStepId,
} from '../simulation/store/simulation.wizard.state';
import { loadBalancingRuleLocations } from './loadBalancingPolicy.helper';
import { LoadBalancingPolicySelectedIdentity } from './loadBalancingPolicy.type';

const getKey = (postfix: string) =>
  `warebee-simulation-load-balancing-policy-${postfix}`;

export const loadBalancingPolicyDocument = atom<LoadBalancingPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const loadBalancingPolicySelectedIdentity =
  atom<LoadBalancingPolicySelectedIdentity>({
    key: getKey('selected-identity'),
    default: null,
  });

export const loadBalancingPolicyRule = selectorFamily<
  LoadBalancingRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(loadBalancingPolicyDocument);
      return _.find(policy?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, value: LoadBalancingRuleFragment) => {
      const policy = get(loadBalancingPolicyDocument);
      set(loadBalancingPolicyDocument, {
        ...policy,
        rules: policy.rules.map(rule => (rule.id === ruleId ? value : rule)),
      });
    },
});

export const loadBalancingPolicyRuleSelected =
  selector<LoadBalancingRuleFragment>({
    key: getKey('rule-selected'),
    get: ({ get }) => {
      const identity = get(loadBalancingPolicySelectedIdentity);
      const policy = get(loadBalancingPolicyDocument);
      return _.find(policy?.rules, rule => rule.id === identity?.ruleId);
    },
  });

export const loadBalancingPolicyLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  LoadBalancingPolicySelectedIdentity
>({
  key: getKey('location-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.ruleId)) return null;

      const rule = get(loadBalancingPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters = rule.include?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.locationFilterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (_.isNil(identity?.locationFilterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(loadBalancingPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(loadBalancingPolicyRule(identity.ruleId), {
        ...rule,
        include: {
          ...(rule.include ?? {}),
          anyOf: _.map(rule.include?.anyOf, fs =>
            fs.id === identity.locationFilterId ? value : fs,
          ),
        },
      });
    },
});

export const loadBalancingShowAdvancedOptions = atom<boolean>({
  key: getKey('show-advanced-options'),
  default: false,
});

const loadBalancingPolicyActiveRule = selector<LoadBalancingRuleFragment>({
  key: getKey('active-locations-filter'),
  get: ({ get }) => {
    const identity = get(loadBalancingPolicySelectedIdentity);
    if (_.isNil(identity?.ruleId)) return null;
    const rule = get(loadBalancingPolicyRule(identity.ruleId));

    //check visibility conditions
    const sim = get(simulationCurrent);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationOptimisationPolicyTabKey);
    const layoutId = sim?.layout?.id;
    if (
      _.isNil(layoutId) ||
      stepId !== 'optimise' ||
      tab !== 'tab-optimisation-policy-load-balancing'
    )
      return null;

    return rule;
  },
});

export const loadBalancingPolicyLocationByLevel =
  selector<LoadBalancingRuleSummaryWithLocationsFragment>({
    key: getKey('locations-per-level'),
    get: async ({ get }) => {
      const analyze = get(simulationAnalyzeResult);
      const planeId = get(viewerSelectedPlaneId);
      const level = get(viewerSelectedLevel);
      const rule = get(loadBalancingPolicyActiveRule);
      if (_.isNil(rule) || _.isNil(analyze)) return null;

      return await loadBalancingRuleLocations({
        analyzeId: analyze.id,
        rule,
        queryFilter: {
          planeId: [planeId],
          level: [level],
        },
      });
    },
  });

export const loadBalancingPolicyBayLocationByRule =
  selector<LoadBalancingRuleSummaryWithLocationsFragment>({
    key: getKey('locations-per-bay-by-rule'),
    get: async ({ get }) => {
      const analyze = get(simulationAnalyzeResult);
      const bay = get(viewerSelectedBayIdAtom);
      const planeId = get(viewerSelectedPlaneId);
      const rule = get(loadBalancingPolicyActiveRule);

      if (_.isNil(bay) || _.isNil(rule) || _.isNil(analyze)) return null;

      return await loadBalancingRuleLocations({
        analyzeId: analyze.id,
        rule,
        queryFilter: {
          planeId: [planeId],
          bayId: [bay],
        },
      });
    },
  });

export const loadBalancingPolicyShowHeatmap = atom<boolean>({
  key: getKey('show-heatmap-atom'),
  default: true,
});
