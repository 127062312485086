import {
  LoadBalancingRuleFragment,
  useLoadBalancingRuleSummaryQuery,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import ErrorIndicator from '../components/ErrorIndicator';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { Stat } from '../components/stats/Stat';
import { StatListItem } from '../components/stats/StatListItem';
import { getLoadBalancingPolicyRuleInput } from '../loadBalancingPolicy/loadBalancingPolicy.helper';
import useGetZoneTitle from '../loadBalancingPolicy/useGetZoneTitle';
import AnalyzeResultLoadBalancingKPISwitch, {
  LoadBalancingKPI,
} from './AnalyzeResultLoadBalancingKPISwitch';
import LoadBalancingChart, {
  LoadBalancingChartDataRow,
  LoadBalancingChartSeries,
} from './LoadBalancingChart';

export type AnalyzeResultLoadBalancingRuleProps = {
  analyzeId: string;
  series: LoadBalancingChartSeries;
  rule: LoadBalancingRuleFragment;
};

const AnalyzeResultLoadBalancingRule: React.FC<
  AnalyzeResultLoadBalancingRuleProps
> = props => {
  const { t } = useTranslation('simulation');
  const [kpi, setKpi] = useState<LoadBalancingKPI>('absolute');
  const getZoneTitle = useGetZoneTitle();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  const { data, loading, error } = useLoadBalancingRuleSummaryQuery({
    variables: {
      analyzeId: props.analyzeId,
      rule: getLoadBalancingPolicyRuleInput(props.rule),
    },
  });

  const ruleSummary = data?.analyzeResult?.loadBalancingRuleSummary;

  const meanLoad = Math.floor(ruleSummary?.meanLoad ?? 0);
  const maxLoadZone = _.maxBy(ruleSummary?.zones, z => z.zoneLoad);
  const minLoadZone = _.minBy(ruleSummary?.zones, z => z.zoneLoad);

  const maxLoadValue =
    maxLoadZone?.zoneLoad - (kpi === 'absolute' ? 0 : meanLoad);
  const minLoadValue =
    minLoadZone?.zoneLoad - (kpi === 'absolute' ? 0 : meanLoad);
  const chartData: LoadBalancingChartDataRow[] = _(ruleSummary?.zones)
    .map((zone, groupIndex) => {
      return {
        zoneId: zone.zoneId,
        zoneTitle: getZoneTitle(zone.zoneId, props.rule),
        [`${props.series}Absolute`]: zone.zoneLoad,
        [`${props.series}Relative`]: zone.zoneLoad - meanLoad,
      };
    })
    .sortBy(datum => datum[`${props.series}Absolute`])
    .value() as any as LoadBalancingChartDataRow[];

  const zoneCount = chartData.length ?? 1;
  const calculatedHeight = 40 * zoneCount;

  const hasChartData = !_.isEmpty(chartData);
  const showData = !loading && !error && hasChartData;
  const showPlaceholder = !loading && !error && !hasChartData;
  const title = _.isNil(props.rule.title)
    ? ' '
    : t(`Rule: {{title}}`, { title: props.rule.title });

  return (
    <div
      className={classNames(
        'flex flex-col flex-1 items-center relative w-full',
      )}
    >
      <Stat
        title={title}
        hasHelper
        isPreview
        inPanelMode
        switchUnitOfMeasure={
          <AnalyzeResultLoadBalancingKPISwitch
            selected={kpi}
            setSelected={setKpi}
          />
        }
      >
        {showData && (
          <div className="mt-4">
            {!_.isNil(maxLoadZone?.zoneId) && (
              <StatListItem
                title={t(`Max: {{zone}}`, {
                  zone: getZoneTitle(maxLoadZone?.zoneId, props.rule),
                })}
                value={formatInteger(maxLoadZone?.zoneLoad)}
                unitOfMeasure={t`hit`}
              />
            )}
            {!_.isNil(minLoadZone?.zoneId) && (
              <StatListItem
                title={t(`Min: {{zone}}`, {
                  zone: getZoneTitle(minLoadZone?.zoneId, props.rule),
                })}
                value={formatInteger(minLoadZone?.zoneLoad)}
                unitOfMeasure={t`hit`}
              />
            )}
          </div>
        )}

        <div
          className={classNames(
            'p-1 md:p-2 lg:p-3 xl:p-4 ltr:px-0.5 rtl:px-0.5 ltr:xl:px-1 rtl:xl:px-1',
            'm-0.5',
            // 'bg-app-panel-dark/60',
            'min-h-10',
          )}
          style={{ height: calculatedHeight, width: '100%', minWidth: '100%' }}
        >
          <div className="h-full">
            {loading && (
              <LoadingIndicator selfCenter message={t`Loading Data`} />
            )}
            {error && (
              <ErrorIndicator
                selfCenter
                message={t`Apologies, we couldn't load the data`}
              />
            )}
            {showPlaceholder && <InboxZero selfCenter message={t`No data`} />}
            {showData && (
              <LoadBalancingChart
                data={chartData}
                series={[props.series]}
                kpi={kpi}
                min={minLoadValue}
                max={maxLoadValue}
                // avg2={ruleSummary?.stdDev}
                avg={meanLoad}
              />
            )}
          </div>
        </div>
        {showData && (
          <span
            className={classNames(
              'text-menu-text/60 hover:underline text-sm cursor-pointer',
            )}
            onClick={() => {
              setShowAdvanced(!showAdvanced);
            }}
          >
            {showAdvanced ? t`Hide advanced stats` : t`Show advanced stats...`}
          </span>
        )}

        {showData && showAdvanced && (
          <>
            <StatListItem
              title={t(`Mean Load`)}
              value={formatInteger(meanLoad)}
              unitOfMeasure={t`hit`}
            />
            <StatListItem
              title={t`Variance`}
              value={formatInteger(ruleSummary?.variance)}
            />
            <StatListItem
              title={t`Std deviation`}
              value={formatInteger(ruleSummary?.stdDev)}
            />
          </>
        )}
      </Stat>
    </div>
  );
};

export default AnalyzeResultLoadBalancingRule;
