import { AriaButtonProps } from '@react-types/button';
import classNames from 'classnames';
import React from 'react';
import { useButton } from 'react-aria';
import { analyticsAgent } from '../../AnalyticTracker';
import useSharedRef from '../../utils/use-shared-ref';
import { ButtonSize, ButtonType } from '../_ComponentsThemeStyles';
import Loader from '../loaders/Loader';

export type ButtonProps = {
  className?: string;
  titleTrace?: string;
  titleAlt?: string;
  label?: string;
  buttonType?: keyof typeof ButtonType | string;
  buttonSize?: keyof typeof ButtonSize | string;
  buttonIcon?: React.ReactNode;
  hasIcon?: boolean;
  hasIconBefore?: React.ReactNode;
  hasIconAfter?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  isTransparent?: boolean;
  full?: boolean;
  style?: React.CSSProperties;
} & AriaButtonProps<'button'>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      label,
      buttonType,
      buttonSize = 'base',
      buttonIcon,
      children,
      hasIcon,
      hasIconBefore,
      hasIconAfter,
      isLoading,
      isTransparent,
      full,
      titleTrace,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const { isDisabled } = props;
    const sharedRef = useSharedRef(ref);
    const { buttonProps } = useButton(
      {
        ...props,
        onPress: e => {
          props.onPress && props.onPress(e);
          try {
            analyticsAgent?.track(`Button: [${label}] ${titleTrace ?? ''}`, {
              label,
            });
          } catch (ex) {
            //we do not want any analytic problems ruin main workflow
            //TODO: Handle analytics exception
          }
        },
      },
      sharedRef,
    );

    const buttonSizeClass = ButtonSize[buttonSize];

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        data-component={`Button`}
        data-label={`button-${label || ''}`}
        aria-label={`button-${label || ''}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        style={props.style}
        title={props.titleAlt}
        className={classNames(
          'flex items-center',
          'focus:outline-none focus:ring-0',
          { 'w-full': full },
          {
            'bg-app-panel-dark/60 text-menu-active hover:bg-menu-hover hover:text-menu-active-text':
              buttonType === '',
          },
          buttonType === 'primary'
            ? isDisabled
              ? 'isDisabled bg-menu text-menu-text/75 hover:bg-menu hover:text-menu/75'
              : 'buttonPrimary bg-menu-active text-menu-active-text hover:bg-brand-hover hover:text-menu-active-text'
            : '',
          {
            'buttonSecondary bg-app-panel text-menu-text hover:bg-menu-hover hover:text-menu-active-text':
              buttonType === 'secondary',
          },
          {
            'buttonHelper text-menu-text hover:text-menu-active hover:text-opacity-100':
              buttonType === 'helper',
          },
          {
            'buttonError text-menu-text bg-alerts-error hover:bg-alerts-error hover:text-menu-text':
              buttonType === 'error',
          },
          {
            'bg-brand-purchase text-menu-text hover:text-menu-text hover:bg-blue-600':
              buttonType === 'purchase',
          },
          buttonType === 'delete'
            ? isDisabled
              ? 'isDisabled bg-menu text-menu-text hover:bg-menu hover:text-menu-text'
              : 'bg-menu-delete text-menu-text hover:bg-menu-delete hover:text-menu-text'
            : '',
          isDisabled ? 'cursor-default' : 'cursor-pointer',
          buttonSizeClass,
          className,
        )}
      >
        <div className={classNames('flex w-full flex-1 items-center truncate')}>
          {hasIconBefore && (
            <div className="flex items-center">
              {isLoading ? (
                <Loader
                  size={buttonSize === 'xs' ? 20 : 25}
                  stroke={7}
                  type={'loading-original'}
                />
              ) : (
                buttonIcon || hasIconBefore
              )}
            </div>
          )}
          {label && (
            <div
              className={classNames(
                'whitespace-pre',
                'flex-1',
                'truncate',
                buttonType === 'helper' ? 'helper' : 'flex-1 px-2 uppercase',
                { 'text-xxs md:text-xs lg:text-sm': !buttonSize },
                { 'text-menu-text/50': isDisabled },
                buttonSize === 'xs' && 'text-xxs md:text-xs',
                buttonSize === 'xl' && 'text-xs md:text-sm lg:text-base',
                buttonSize === 'xxl' && 'text-sm md:text-base lg:text-xl',
              )}
            >
              {label}
            </div>
          )}
          {children}
          {hasIconAfter && (
            <div className="flex items-center">
              {isLoading ? (
                <Loader
                  size={buttonSize === 'xs' ? 20 : 30}
                  stroke={7}
                  type={'loading-original'}
                />
              ) : (
                buttonIcon || hasIconAfter
              )}
            </div>
          )}
        </div>
      </button>
    );
  },
);
