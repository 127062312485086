import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../../AnalyticTracker';
import ButtonToggle from '../actions/ButtonToggle';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';

export type HeaderAppMenuProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string;
  collapsible?: boolean;
  isCollapsed?: boolean;
  isPinned?: boolean;
  hasAlert?: boolean;
  hasIssue?: boolean;
  hasMenu?: boolean;
  hasControls?: boolean;
  hasControlsPin?: boolean;
  hasControlsCollapse?: boolean;
  hasPadding?: boolean;
  collapsible_by_button?: boolean;
  collapsible_left?: boolean;
  collapsible_right?: boolean;
  collapsible_horizontal?: boolean;
  collapsible_vertical?: boolean;
  collapsible_global?: boolean;
  closable?: boolean;
  isLoading?: boolean;
  filterPlaceholder?: React.ReactNode;

  toggleCollapsed?: () => void;
  togglePinned?: () => void;
  onHeaderClick?: () => void;
  onCloseClick?: () => void;
  onClick?: () => void;
};

export const HeaderAppMenu: React.FC<HeaderAppMenuProps> = props => {
  const {
    className,
    title,
    subtitle,
    closable,
    collapsible,
    collapsible_by_button,
    collapsible_horizontal,
    collapsible_vertical,
    collapsible_global,
    isCollapsed,
    isPinned,
    hasAlert,
    hasIssue,
    hasPadding,
    // hasControls,
    hasControlsPin,
    hasControlsCollapse = true,
    hasMenu,
    filterPlaceholder,
    children,
    isLoading,
    onCloseClick,
    // onClick,
  } = props;
  const { t } = useTranslation('app');

  const [id] = useState<string>(_.uniqueId());

  const hasControls = hasControlsPin || hasControlsCollapse;

  const styleIconButton = classNames(
    'h-6 w-6',
    'fill-current',
    'bg-menu/10 lg:bg-menu/5',
    'hover:bg-menu-hover hover:text-menu-active-text',
    'p-1',
    'mx-0.5',
    'rounded-full',
  );

  return (
    <header
      id={id}
      data-component="HeaderAppPanel"
      className={classNames(
        className,
        'h-header',
        'uppercase',
        'text-xs',
        'border-menu/10 border-b',
        'group-hover:transition group-hover:delay-500 group-hover:duration-500 group-hover:ease-in-out',
        hasPadding ? 'p-1 ltr:pl-2 ltr:pr-2 rtl:pl-2 rtl:pr-2' : 'p-1',
        hasIssue
          ? 'hasIssue bg-app-menu-header text-menu-active flex items-center'
          : 'bg-sidebar-header/90 text-menu-text hover:bg-sidebar-header flex items-center',
        isCollapsed && !hasMenu && !collapsible_by_button
          ? 'text-menu-text w-8 flex-1 cursor-pointer pt-1' // Vertical Collapsed
          : '',
        hasMenu && isCollapsed ? 'w-30' : 'group w-full',
        !collapsible_by_button && isCollapsed && collapsible_vertical,
        // 'ltr:[direction:ltr] rtl:[direction:ltr] ltr:[writing-mode:vertical-lr] rtl:[writing-mode:vertical-rl]',
      )}
      aria-label={typeof title === 'string' ? title : null}
      onClick={() => {
        props.onHeaderClick && props.onHeaderClick();
        try {
          analyticsAgent?.track(`Header: ${props.title} (Click)`);
        } catch (ex) {
          console.debug('analyticsAgent Error:', ex);
        }
      }}
    >
      {hasControlsCollapse &&
        !collapsible_by_button &&
        collapsible_vertical &&
        isCollapsed && (
          <ButtonToggle
            title={t`Open`}
            className={classNames(
              'flex flex-1 flex-col items-end',
              'mb-2 mt-1',
            )}
            onChange={props.toggleCollapsed}
          >
            {isLoading && <Loader size={20} type={'loading-original'} />}

            {!collapsible_by_button && hasMenu && (
              <div
                className={classNames(
                  'writing-mode-horizontal',
                  'ltr:mr-1 rtl:ml-1',
                )}
              >
                {title}
              </div>
            )}
            {collapsible_vertical && (
              <Icon.ChevronDualOpenHorizontal
                className={classNames(styleIconButton)}
              />
            )}
          </ButtonToggle>
        )}

      {hasControlsCollapse && collapsible_by_button && isCollapsed && (
        <ButtonToggle
          title={t`Toggle Full Menu`}
          classNameLabel={classNames('flex-1')}
          className={classNames(
            'flex flex-col items-center justify-center',
            'flex-1',
            'bg-menu/5 lg:bg-menu/5',
            'hover:bg-menu-hover hover:text-menu-active-text',
            'text-menu-text',
            'rounded-full',
            'p-0.5',
            'group',
          )}
          onChange={props.toggleCollapsed}
        >
          <Icon.MenuBarsLeft
            className={classNames(
              'fill-current',
              'h-6 w-6',
              'rotate-180 transform',
            )}
          />
        </ButtonToggle>
      )}

      {hasControlsCollapse && collapsible_by_button && !isCollapsed && (
        <ButtonToggle
          title={t`Toggle Minimised Menu`}
          className={classNames(
            'flex flex-1 flex-col items-center justify-center',
            'bg-menu/5 lg:bg-menu/5',
            'hover:bg-menu-hover hover:text-menu-active-text',
            'text-menu-text',
            'rounded-full',
            'p-0.5',
            'group',
          )}
          onChange={props.toggleCollapsed}
        >
          <Icon.MenuBarsRight
            className={classNames(
              'fill-current',
              'h-6 w-6',
              'rotate-180 transform',
            )}
          />
        </ButtonToggle>
      )}

      {hasControlsCollapse && collapsible_horizontal && isCollapsed && (
        <ButtonToggle
          title={t`Open me`}
          className={classNames('collapsible_horizontal isCollapsed')}
          onChange={props.toggleCollapsed}
        >
          <Icon.ChevronDualOpenVertical
            className={classNames(styleIconButton)}
          />
        </ButtonToggle>
      )}

      {hasControlsCollapse &&
        collapsible_horizontal &&
        // !collapsible_global &&
        !isCollapsed && (
          <ButtonToggle
            title={t`Close me`}
            className="notCollapsed"
            onChange={props.toggleCollapsed}
          >
            <Icon.ChevronDualCloseHorizontal
              className={classNames(styleIconButton)}
            />
          </ButtonToggle>
        )}

      {hasControlsCollapse &&
        !collapsible_horizontal &&
        collapsible_global &&
        !isCollapsed && (
          <ButtonToggle
            title={t`Close me`}
            className="notCollapsed"
            onChange={props.toggleCollapsed}
          >
            <Icon.Close className={classNames(styleIconButton)} />
          </ButtonToggle>
        )}

      {hasAlert && (
        <Icon.CircleInfo
          className={classNames(
            'h-5',
            'mb-1',
            'fill-current',
            'text-menu-active',
          )}
        />
      )}

      {hasIssue && (
        <Icon.TriangleInfo
          className={classNames(
            'h-5',
            'mb-1 mt-4',
            'fill-current',
            'text-menu-active',
          )}
        />
      )}

      {hasControlsPin &&
        hasControlsCollapse &&
        collapsible &&
        !isCollapsed &&
        !collapsible_global && (
          <ButtonToggle
            title={isPinned ? t`Unpin - to Auto-close` : t`Pin Open`}
            className={classNames(
              isPinned
                ? 'inline-block ltr:pr-2 rtl:pl-2'
                : 'hidden group-hover:block ltr:pr-2 rtl:pl-2',
            )}
            onChange={props.togglePinned}
          >
            {isPinned ? (
              <Icon.Pin className={classNames(styleIconButton, '-rotate-45')} />
            ) : (
              <Icon.Pin className={classNames(styleIconButton)} />
            )}
          </ButtonToggle>
        )}

      {title && !hasMenu && !collapsible_by_button && (
        <div
          data-component={
            `title` +
            (isCollapsed
              ? `-isCollapsed`
              : collapsible_horizontal
                ? `-isCollapsibleHorizontal`
                : '')
          }
          className={classNames(
            'flex items-center',
            subtitle ? '' : 'flex-1',
            isCollapsed && !collapsible_horizontal
              ? 'pt-1'
              : 'ltr:pl-1 rtl:pr-1',
            isCollapsed
              ? hasIssue
                ? 'text-menu-title'
                : 'text-menu-text '
              : 'font-bold',
            collapsible_horizontal ? '' : React.isValidElement(title) ? '' : '',
          )}
        >
          {title}
          {filterPlaceholder && (
            <span className={classNames('ltr:ml-4 rtl:mr-4')}>
              {filterPlaceholder}
            </span>
          )}
        </div>
      )}

      {subtitle && (
        <div
          className={classNames(
            'text-menu-text/60',
            'flex items-center',
            'px-1',
          )}
        >
          <span className={classNames('flex-1', 'text-xxs')}>{subtitle}</span>
        </div>
      )}

      {hasControlsCollapse &&
        collapsible &&
        !isCollapsed &&
        !collapsible_global && (
          <ButtonToggle
            title={t`Close me`}
            className={classNames()}
            onChange={props.toggleCollapsed}
          >
            <Icon.ChevronDualCloseHorizontal
              className={classNames(styleIconButton)}
            />
          </ButtonToggle>
        )}

      {hasControlsCollapse && closable && (
        <button
          type="button"
          className={classNames('ltr:ml-4 rtl:mr-4', 'flex items-center')}
          aria-label={`Close`}
          onClick={onCloseClick}
        >
          <Icon.Close className={classNames(styleIconButton)} />
        </button>
      )}

      {hasMenu ? (
        <div className={classNames('flex flex-1 items-center', 'mt-4')}>
          {children}
        </div>
      ) : (
        children && (
          <div className={classNames('flex flex-1 items-center')}>
            {children}
          </div>
        )
      )}
    </header>
  );
};
