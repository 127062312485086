import classNames from 'classnames';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as Icon from '../icons';

export type InputSearchProps = {
  children?: React.ReactNode;
  className?: string;
  classNameInput?: string;
  title?: string;
  id_name?: string;
  value?: string;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  titleMode?: boolean;
  sidebarFilterMode?: boolean;
  headerTableMode?: boolean;
  headerStatMode?: boolean;
  onChange?: (value: string) => void;
  debounceInterval?: number;
};

export const InputSearch: React.FC<InputSearchProps> = props => {
  const { placeholder } = props;
  const id = useId();
  const placeholderRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [isValid, setValid] = useState(true);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useLayoutEffect(() => {
    if (placeholderRef.current) {
      const placeholderWidth = placeholder
        ? placeholder.length < 5
          ? placeholder.length * 18 + 20
          : placeholder.length > 15
            ? placeholder.length * 9
            : placeholder.length * 12 + 15
        : 0;
      const valueWidth = value ? value.length * 10 + 10 : 0;
      const maxPlaceholderOrValueWidth = Math.max(placeholderWidth, valueWidth);
      setInputWidth(maxPlaceholderOrValueWidth);
    }
  }, [placeholder, value]);

  const debouncedChange = useCallback(
    _.debounce(async (value: string) => {
      try {
        await props.onChange(value);
      } catch (ex) {
        setValid(false);
      }
    }, props.debounceInterval ?? 800),
    [id, props.onChange],
  );

  function onChangeInner(e: React.ChangeEvent<HTMLInputElement>) {
    //reset validation state
    setValid(true);
    const value = e.target.value;
    setValue(value);

    //call debounce nonChange only for valid values
    debouncedChange(value);
  }

  const inputFieldWidth = inputWidth ? `${inputWidth}px` : '2rem';

  return (
    <label
      data-component="InputSearch"
      htmlFor={id}
      className={classNames(
        'relative',
        'group flex items-center',
        'cursor-pointer',
        'mt-4',
        {
          'flex flex-1': props.headerTableMode,
          'w-full': props.sidebarFilterMode,
        },
        props.className,
      )}
    >
      <input
        id={id}
        className={classNames(
          'block w-full flex-1',
          'min-w-[2.5rem]',
          'focus:border-menu-active focus:outline-none',
          'focus:ring-menu-active focus:ring-1',
          'focus:bg-app-panel-dark focus:text-menu-active',
          'focus:border-menu border-transparent',
          'valid:text-menu-active',
          'truncate',
          'peer',
          'appearance-none',
          'group-focus:bg-app-input/60 group-active:text-menu-text group-focus:border-menu-active group-focus:text-menu-text',
          props.titleMode
            ? 'text-menu-text/90 placeholder-menu-text focus:placeholder-menu-text/30 rounded-md bg-transparent px-2 py-1 text-sm group-focus:placeholder:text-xs group-active:placeholder:text-xs'
            : props.sidebarFilterMode
              ? 'text-menu-text bg-app-input/70 focus:bg-app-input/80 rounded px-2 py-2 text-xs'
              : 'text-menu-active-text bg-app-input/80 focus:bg-app-input/60 focus:ring-menu-active rounded-sm px-2 py-1',
          props.classNameInput,
        )}
        placeholder=" "
        type="search"
        value={value}
        onChange={e => onChangeInner(e)}
        style={props.sidebarFilterMode ? null : { minWidth: inputFieldWidth }}
      />
      {value === '' && (
        <Icon.Search
          className={classNames(
            'fill-current',
            'absolute',
            'ltr:pl-0.5 rtl:pr-0.5',
            'opacity-30',
            'group-hover:opacity-100',
            'transition duration-100 ease-in-out',
            'h-3 w-3 ltr:-ml-1 rtl:-mr-1',
            'text-menu-text',
            'group-active:text-menu-active focus:text-menu-text',
            'group-focus-visible:text-menu-300 group-focus:text-menu-text focus-within:text-menu-text',
            'right-2',
          )}
        />
      )}

      {/* Placeholder as floating label */}
      <span
        aria-label="label"
        className={classNames(
          'text-menu-text absolute text-sm',
          'transform duration-300',
          'peer-focus:text-xxs -peer-focus:-left-1 peer-focus:-translate-y-6', // (input) is focused: scale down, move up, align left
          'peer-active:text-xxs -peer-active:-left-1 peer-active:-translate-y-6', // (input) is active (i.e., clicked but not necessarily filled): scale down, move up, align left
          value !== ''
            ? 'text-xxs hasValue text-menu-text/60 left-1 -translate-y-6' // If value, move up, align left,
            : 'text-menu-active left-4 top-2', // No Value, move up, align left
          'peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100', // If the placeholder is shown: revert scale to 100% and don't move vertically
          'peer-placeholder-shown:truncate', // Truncate the placeholder text if it overflows
        )}
      >
        {placeholder}
      </span>

      {/* Hidden for calculating placeholder text width */}
      <span
        data-size={inputFieldWidth}
        data-length={placeholder.length}
        data-width={inputFieldWidth}
        className={classNames('invisible absolute whitespace-nowrap')}
        ref={placeholderRef}
      >
        {placeholder}
      </span>
    </label>
  );
};
