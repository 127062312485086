import {
  Field,
  ID,
  ObjectType,
  registerEnumType,
  createUnionType,
} from '@warebee/shared/util-backend-only-types';
import { Point, Vector } from './geometry.model';
import { BayLevel, BayLocationOrder } from './layout-map.model';
import GeoJSON from 'geojson';

export enum LayoutFeatureType {
  AISLE = 'AISLE',
  BAY = 'BAY',
}

registerEnumType(LayoutFeatureType, {
  name: 'LayoutFeatureType',
});

@ObjectType()
export class LayoutBayDetails {
  @Field(() => Point)
  position: Point;

  @Field(() => Vector)
  frontEdge: Vector;

  @Field()
  width: number;

  @Field()
  depth: number;

  @Field(() => BayLocationOrder)
  locationOrder: BayLocationOrder;

  @Field({ nullable: true })
  height?: number;

  @Field(() => [BayLevel], { nullable: true })
  levels?: BayLevel[];

  @Field(() => ID, { nullable: true })
  bayType?: string;
}

@ObjectType()
export class LayoutAisleDetails {
  @Field(() => ID, { nullable: true })
  aisleType?: string;
}

export const LayoutFeatureDetailsUnion = createUnionType({
  name: 'LayoutFeatureDetails',
  types: () => [LayoutBayDetails, LayoutAisleDetails],
});

export type LayoutFeatureDetails = typeof LayoutFeatureDetailsUnion;

export interface LayoutFeatureBase {
  id: string;

  type: LayoutFeatureType;
  title?: string;

  shape?: GeoJSON.Polygon;
  innerShape?: GeoJSON.Polygon;
  navigable: boolean;
  details?: LayoutFeatureDetails;
}
