import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import HeaderDataset from '../components/headers/HeaderDataset';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import ItemSetDataView from './ItemSetDataView';
import ItemSetWeightPolicyView from './ItemSetWeightPolicyView';
import { useLoadItemSet } from './hooks/useLoadItemSet';
import ItemSetEditorSidebar from './sidebar/ItemSetEditorSidebar';
import ItemSetMainSidebar from './sidebar/ItemSetMainSidebar';
import ItemSetMenuSidebar from './sidebar/ItemSetMenuSidebar';
import {
  itemSetDocument,
  itemSetDocumentLoadStatus,
  itemSetSelectedMenuItemId,
} from './store/itemSet.state';

export type ItemSetRootParams = {
  itemSetId: string;
};

const ItemSetRoot: React.FC = () => {
  const { t } = useTranslation('dataset');
  const { itemSetId } = useParams<ItemSetRootParams>();
  const itemSetLoadStatus = useRecoilValue(itemSetDocumentLoadStatus);
  const documentItemSet = useRecoilValue(itemSetDocument);

  const loadDocument = useLoadItemSet();
  const selectedMenuItem = useRecoilValue(itemSetSelectedMenuItemId);

  function getContent() {
    switch (selectedMenuItem) {
      case 'item-set-menu-pallet-policy':
        return <ItemSetWeightPolicyView />;
      default:
        return <ItemSetDataView />;
    }
  }

  useEffect(() => {
    loadDocument(itemSetId);
  }, [itemSetId]);

  return (
    <>
      <HeaderDataset dataType="items" document={documentItemSet} />

      <ContainerMain className="flex">
        {itemSetLoadStatus === AsyncLoadStatus.Ok ? (
          <>
            <ContainerSidebar side="left">
              <ItemSetMenuSidebar />
              <ItemSetMainSidebar />
              <ItemSetEditorSidebar />
            </ContainerSidebar>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton
                  exception={error}
                />
              )}
            >
              <Suspense
                fallback={
                  <Container hasOverflowY flex1 col>
                    <PanelHeader isLoading title={t`Loading Items...`} />
                    <LoadingIndicator
                      className={classNames(
                        'bg-map-area-striped',
                        'border-app-background border-8',
                      )}
                      selfCenter
                      message={t`Loading Items`}
                    />
                  </Container>
                }
              >
                {getContent()}
              </Suspense>
            </ErrorBoundary>
          </>
        ) : (
          <LoadingIndicator selfCenter message={t`Loading Item set data...`} />
        )}
      </ContainerMain>
    </>
  );
};

export default ItemSetRoot;
