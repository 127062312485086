import { InferResult, sql } from 'kysely';
import _ from 'lodash';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedAgentShifts = InferResult<
  ReturnType<typeof getFeedAgentShiftsQuery>
>;
export type FeedAgentShift = FeedAgentShifts[number];

export const getFeedAgentShiftsQuery = (
  params: ActivityFeedQueryBuilderParams,
) => {
  let query = datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .with(
      cte => cte('af_with_gap'),
      db => {
        return db.selectFrom('af').select([
          'agentUser',
          'eventEndTime',
          //previousTime
          sql<Date>`
          lag(${sql.ref('eventEndTime')}, 1) 
          OVER(
            PARTITION BY ${sql.ref('agentUser')}
            ORDER BY ${sql.ref('eventEndTime')} ASC
          )`.as('previousTime'),
          //nextTime
          sql<Date>`
          lag(${sql.ref('eventEndTime')}, 1) 
          OVER(
            PARTITION BY ${sql.ref('agentUser')}
            ORDER BY ${sql.ref('eventEndTime')} DESC
          )`.as('nextTime'),
        ]);
      },
    )
    .with(
      cte => cte('shift_events'),
      db => {
        return db
          .selectFrom('af_with_gap')
          .select([
            //  [shiftIndex]
            sql<number>`
            ceil((
              row_number() over (
                partition by ${sql.ref('agentUser')}
                order by ${sql.ref('eventEndTime')} asc
            ) -1) / 2)`.as('shiftId'),

            'agentUser',
            'eventEndTime',
          ])
          .where(({ eb, or }) =>
            or([
              eb('previousTime', 'is', null),
              eb('nextTime', 'is', null),
              eb(
                sql<number>`date_diff('hour', ${sql.ref('previousTime')}, ${sql.ref('eventEndTime')} )`,
                '>',
                8,
              ),
              eb(
                sql<number>`date_diff('hour', ${sql.ref('eventEndTime')}, ${sql.ref('nextTime')})`,
                '>',
                8,
              ),
            ]),
          )
          .orderBy('eventEndTime', 'asc');
      },
    )
    .selectFrom('shift_events')
    .select(({ fn }) => [
      'shiftId',
      'agentUser',
      fn.min('eventEndTime').as('shiftStart'),
      fn.max('eventEndTime').as('shiftEnd'),
    ])
    .groupBy(['agentUser', 'shiftId']);

  return query;
};

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return fName === 'shiftStart' || fName === 'shiftEnd';
};

export function postFeedAgentShifts(
  events: Record<string, any>[],
): FeedAgentShifts {
  return postProcessDefault<FeedAgentShift>(events, isDateField);
}
