import { useLoadLayoutMetaLazyQuery } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IMPORT_POST_PROCESS_REFRESH_INTERVAL } from '../../../common/constants';
import { AsyncLoadStatus } from '../../../common/types';
import AppHeaderInner from '../../../components/AppHeaderInner';
import { AppHeaderProps } from '../../../components/AppHeaderSimulation';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  Container,
  ContainerCol,
} from '../../../components/layout/ContainerFlex';
import { ContainerMain } from '../../../components/layout/ContainerMain';
import { ContainerSidebar } from '../../../components/layout/ContainerSidebar';
import useUpdateSimulation from '../../../simulation/hooks/useUpdateSimulation';
import { importTriggeredBySim } from '../../../store/global.state';
import { warehouseSelected } from '../../../store/warehouse.state';
import { useLoadLayoutImport } from '../../../warehouse/hooks/useLoadLayoutImport';
import ConverterLayoutView from './ConverterLayoutView';
import ConverterWatcher from './ConverterWatcher';
import ConverterLocationSidebar from './sidebar/ConverterLocationSidebar';
import ConverterPropertyPanelSidebar from './sidebar/ConverterPropertyPanelSidebar';
import ConverterSaveBuilderPanel from './sidebar/ConverterSaveBuilderPanel';
import ConverterWizardSidebar from './sidebar/ConverterWizardSidebar';
import ConverterWizardStepSidebar from './sidebar/ConverterWizardStepSidebar';
import { CONVERTER_SAVE_PREPARE_ID } from './store/converter.defaults';
import {
  converterCalculationStatus,
  converterLayoutData,
  converterLayoutIdSaving,
} from './store/converter.state';
import ConverterExtraDatasetView from './tables/ConverterExtraDatasetView';

export type ConverterRootParams = {
  layoutImportId: string;
};

const ConverterRoot: React.FC = () => {
  const { t } = useTranslation('designer');
  const { layoutImportId } = useParams<ConverterRootParams>();
  const wh = useRecoilValue(warehouseSelected);
  const layoutData = useRecoilValue(converterLayoutData);
  const calcStatus = useRecoilValue(converterCalculationStatus);
  const triggerSimId = useRecoilValue(importTriggeredBySim);
  const [savingLayoutId, setSavingLayoutId] = useRecoilState(
    converterLayoutIdSaving,
  );
  const updateSim = useUpdateSimulation();
  const [loadLayoutImport, unloadLayoutImport] = useLoadLayoutImport();

  const navigate = useNavigate();

  const [loadLayout, { loading: layoutLoading, data: importedLayout }] =
    useLoadLayoutMetaLazyQuery();
  const isLayoutSaving = !_.isNil(savingLayoutId);

  useEffect(() => {
    if (!wh) return null;
    loadLayoutImport(layoutImportId);
    return () => {
      unloadLayoutImport(layoutImportId);
    };
  }, [wh?.id, layoutImportId]);

  useEffect(() => {
    if (_.isNil(savingLayoutId) || savingLayoutId === CONVERTER_SAVE_PREPARE_ID)
      return;
    let timeoutId;
    const status = importedLayout?.layout?.status;
    if (_.isNil(importedLayout) || status === 'CONVERTING') {
      timeoutId = setTimeout(() => {
        loadLayout({ variables: { layoutId: savingLayoutId } });
      }, IMPORT_POST_PROCESS_REFRESH_INTERVAL);
    }
    if (status === 'READY') {
      setSavingLayoutId(null);
      if (triggerSimId) {
        updateSim({ layoutId: savingLayoutId }, triggerSimId);
      }
      navigate(`/wh/i/${wh.id}/layouts/${savingLayoutId}`);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [savingLayoutId, importedLayout]);

  if (!wh) return null;
  const headerProps: AppHeaderProps = {
    warehouseId: wh.id,
    breadcrumbItems: [
      { title: wh.title, href: `/wh/i/${wh.id}` },
      { title: t`Layout dashboard`, href: `/wh/i/${wh.id}/designer` },
    ],
  };
  if (layoutData) {
    headerProps.breadcrumbItems.push({
      title: layoutData?.title,
      href: `/wh/i/${wh.id}/import/layout/${layoutData?.id}/convert`,
    });
  }

  return (
    <>
      <AppHeaderInner {...headerProps} subtitle={t`Layout - Import Wizard`}>
        <ConverterSaveBuilderPanel />
        {/* <ConverterWatcher /> */}
      </AppHeaderInner>
      <ContainerMain>
        {isLayoutSaving ? (
          <Container overflow>
            <ConverterWizardSidebar />
            <Container overflow flex1 className="relative">
              <ConverterLayoutView />

              <LoadingIndicator
                className="left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-10 shadow-2xl"
                absolute
                selfCenter
                backdrop
                message={t`Converting Layout...`}
              />
            </Container>
          </Container>
        ) : (
          <>
            <ContainerSidebar side="left">
              <ConverterWizardSidebar />
              <ConverterWizardStepSidebar />
            </ContainerSidebar>
            <ContainerCol overflow className="relative">
              {calcStatus === AsyncLoadStatus.Loading && (
                <LoadingIndicator
                  className="left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-10 shadow-2xl"
                  absolute
                  selfCenter
                  backdrop
                  message={t`Updating Layout...`}
                />
              )}
              <ConverterLayoutView />
              <ConverterExtraDatasetView />
              <ConverterWatcher />
            </ContainerCol>
            <ContainerSidebar side="right">
              <ConverterLocationSidebar />
              <ConverterPropertyPanelSidebar />
            </ContainerSidebar>
          </>
        )}
      </ContainerMain>
    </>
  );
};

export default ConverterRoot;
