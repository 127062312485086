import * as React from 'react';
const FiletypeJosnIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FiletypeJosnIcon"
    {...props}
  >
    <rect
      width={97.49}
      height={45}
      x={15.25}
      y={64}
      strokeWidth={0}
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M16.19,4c-.52,0-.94.42-.94.94v59.06h97.49v-30h-29.06c-.52,0-.94-.42-.94-.94V4H16.19ZM86.5,4v26.25h26.25l-26.25-26.25ZM15.25,109v11c0,2.21,1.79,4,4,4h89.49c2.21,0,4-1.79,4-4v-11H15.25Z"
    />
    <path
      strokeWidth={0}
      d="M25.82,105.22c-1.26,0-2.42-.14-3.86-.4-.43-.07-.76-.36-.76-.79v-3.9c0-.43.33-.76.76-.76h1.52c.69,0,.76-.61.76-1.34v-20.95c0-.43.4-.76.83-.76h6.46c.43,0,.79.33.79.76v20.95c0,5.56-2.75,7.19-6.5,7.19ZM31.67,74.7h-6.83c-.43,0-.83-.33-.83-.76v-3.68c0-.43.4-.76.83-.76h6.83c.43,0,.83.33.83.76v3.68c0,.43-.4.76-.83.76Z"
    />
    <path
      strokeWidth={0}
      d="M43.19,98c-3.11,0-5.31-.47-6.75-.94-.4-.14-.69-.36-.69-.79v-4.51c0-.61.22-.94.94-.83,2.17.33,5.02.69,6.28.69.98,0,1.26-.18,1.26-.72,0-.25-.11-.47-.83-.83l-4.12-2.1c-2.53-1.26-3.65-3.14-3.65-6.03,0-3.72,2.71-5.96,8.49-5.96,2.67,0,5.31.54,6.9.98.4.11.76.33.76.76v4.7c0,.47-.14.87-.76.76-1.08-.18-5.02-.65-6.43-.65-.54,0-.76.11-.76.4,0,.22.11.36.47.54l3.97,1.95c2.96,1.44,3.97,3.61,3.97,5.74,0,3.86-2.67,6.86-9.07,6.86Z"
    />
    <path
      strokeWidth={0}
      d="M64.9,98c-6.18,0-9.93-3.21-9.93-10.19v-1.66c0-6.97,3.76-10.19,9.93-10.19s9.93,3.21,9.93,10.19v1.66c0,6.97-3.76,10.19-9.93,10.19ZM66.82,86.15c0-2.82-.69-3.54-1.91-3.54s-1.91.72-1.91,3.54v1.66c0,2.82.69,3.54,1.91,3.54s1.91-.72,1.91-3.54v-1.66Z"
    />
    <path
      strokeWidth={0}
      d="M97.09,97.64h-6.5c-.43,0-.79-.36-.79-.79v-12.5c0-1.26-.83-1.77-1.84-1.77-.65,0-1.34.18-1.99.54v13.72c0,.43-.29.79-.72.79h-6.5c-.43,0-.83-.36-.83-.79v-19.79c0-.4.33-.72.72-.72h5.96c.4,0,.72.33.72.72v1.01c1.7-1.37,2.85-2.09,5.92-2.09,5.31,0,6.61,3.86,6.61,8.38v12.5c0,.43-.33.79-.76.79Z"
    />
  </svg>
);
export default FiletypeJosnIcon;
