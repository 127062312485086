import {
  AssignmentItemDataFragment,
  LoadAssignmentDataQuery,
  LoadAssignmentDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type AssignmentExportJobParams = ExportJobParams<
  LoadAssignmentDataQueryVariables,
  AssignmentConverterConfig
>;

export const assignmentExportJobParams: AssignmentExportJobParams = {
  converterId: 'ASSIGNMENT',
  query: null,
  config: null,
  variables: null,
  filename: 'assignment.csv',
};

type AssignmentDataRow = AssignmentItemDataFragment;

export type AssignmentConverterConfig = {
  columnsConfig: ColumnConfigBase<AssignmentDataRow>[];
};

export const getAssignmentTableRows = (
  data: AssignmentItemDataFragment[],
  config: AssignmentConverterConfig,
): AssignmentDataRow[] => {
  return data;
};

export const ASSIGNMENT_EXPORT_CONVERTER: ExportConverter<
  LoadAssignmentDataQuery,
  AssignmentConverterConfig
> = (input, config) => {
  const data = getAssignmentTableRows(
    input?.assignment?.items?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
