import * as React from 'react';
const WarehouseBoxMenuIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseBoxMenuIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M36.29,66.27v32l27.71,16,27.71-16v-32l-27.71-16-27.71,16ZM77.71,97.7h-27.43c-1.26,0-2.29-1.02-2.29-2.29s1.02-2.29,2.29-2.29h27.43c1.26,0,2.29,1.02,2.29,2.29s-1.02,2.29-2.29,2.29ZM77.71,85.12h-27.43c-1.26,0-2.29-1.02-2.29-2.29s1.02-2.29,2.29-2.29h27.43c1.26,0,2.29,1.02,2.29,2.29s-1.02,2.29-2.29,2.29ZM80,70.27c0,1.26-1.02,2.29-2.29,2.29h-27.43c-1.26,0-2.29-1.02-2.29-2.29s1.02-2.29,2.29-2.29h27.43c1.26,0,2.29,1.02,2.29,2.29Z"
    />
    <polygon
      strokeWidth={0}
      points="64 27.22 5.2 41.26 5.2 98.16 20.96 98.16 20.96 51.77 64 43.27 107.04 51.77 107.04 98.16 122.8 98.16 122.8 41.26 64 27.22"
    />
  </svg>
);
export default WarehouseBoxMenuIcon;
