import * as React from 'react';
const PickingAgentHeadsetIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PickingAgentHeadsetIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M41.39,91.02c-4.66,0-8.46-3.79-8.46-8.46v-29.26c0-4.66,3.79-8.46,8.46-8.46h45.22c4.66,0,8.46,3.79,8.46,8.46v29.26c0,4.66-3.79,8.46-8.46,8.46h-5.31s.03,8.76.03,8.76c0,.97-.56,1.85-1.44,2.25-.32.15-.68.23-1.03.23-.59,0-1.16-.21-1.61-.6l-12.36-10.64h-23.5ZM4,67.22c0-4.03,3.03-7.45,6.98-7.99v-.08h4.72c.59-12.19,5.42-23.62,13.66-32.27-.73-1.2-1.05-2.56-.93-3.96.16-1.88,1.1-3.57,2.65-4.76,4.18-3.24,8.85-5.82,13.87-7.68,5.73-2.12,11.75-3.2,17.89-3.2,6.14,0,12.16,1.08,17.89,3.2,5.02,1.85,9.68,4.44,13.87,7.68,1.54,1.19,2.48,2.88,2.65,4.76.12,1.4-.2,2.76-.93,3.96,8.23,8.66,13.07,20.08,13.66,32.27h5.95c4.45,0,8.07,3.62,8.07,8.07v24.03c0,4.03-3.03,7.45-6.98,7.99v.08h-11.76c-3.93,0-7.13-3.2-7.13-7.13v-25.9c0-3.86,3.08-7.01,6.9-7.13-.58-10.87-4.9-21.05-12.21-28.78-.95.54-2.02.85-3.17.95-1.97.16-3.8-.38-5.31-1.55-2.89-2.24-6.11-4.02-9.55-5.29-7.71-2.85-16.19-2.85-23.9,0-3.45,1.28-6.66,3.06-9.55,5.29-1.52,1.17-3.35,1.71-5.31,1.55-1.16-.09-2.22-.41-3.17-.95-7.31,7.73-11.63,17.91-12.21,28.78h2.1c3.93,0,7.13,3.2,7.13,7.13v25.9c0,3.93-3.2,7.13-7.13,7.13h-1.8c1.38,5.65,6.62,9.84,12.44,9.84h7.54c1.1-3.91,4.64-6.61,8.73-6.61h11.33c5.01,0,9.08,4.07,9.08,9.08,0,5.01-4.07,9.08-9.08,9.08h-11.33c-4.09,0-7.62-2.7-8.73-6.61h-7.54c-8.6,0-16.04-6.33-17.49-14.79h-3.81c-4.45,0-8.07-3.62-8.07-8.07v-24.03h0ZM10.98,64.3c-1.19.45-2.03,1.62-2.03,2.93v24.03c0,1.31.84,2.48,2.03,2.93v-29.88s0,0,0,0ZM119.05,67.22c0-1.31-.84-2.48-2.03-2.93v29.88c1.19-.45,2.03-1.61,2.03-2.92v-24.03s0,0,0,0Z"
    />
  </svg>
);
export default PickingAgentHeadsetIcon;
