import { validateSync } from '@warego/class-validator';
import { TFunction } from 'i18next';
import { identity } from 'lodash';
import { MappingError, MappingSchema } from './mapping';

export type BusinessLogicValidationError = Omit<MappingError, 'code' | 'line'>;

export interface BusinessLogicValidator<T extends object> {
  validateRow(row: T, t: TFunction): BusinessLogicValidationError[];
}

export interface ValidatorOptions {
  t: TFunction;
}

export class MappedRowsValidator<T extends object> {
  private uniqueFieldNames: (keyof T)[];
  private uniqueValuesByFieldName: Partial<Record<keyof T, Set<any>>> = {};

  private businessLogicValidator?: BusinessLogicValidator<T>;

  constructor(private readonly schema: MappingSchema<T>) {
    this.uniqueFieldNames = schema.fields
      .filter(s => s.unique)
      .map(s => s.name);
    for (const name of this.uniqueFieldNames) {
      this.uniqueValuesByFieldName[name] = new Set();
    }
    this.businessLogicValidator = schema.createValidator
      ? schema.createValidator()
      : null;
  }

  validateRows(mappedRows: T[], firstLine = 0, options?: ValidatorOptions) {
    return mappedRows.flatMap((row, i) =>
      this.validateRow(row, firstLine + i, options),
    );
  }

  validateRow(
    mappedRow: T,
    line: number,
    options?: ValidatorOptions,
  ): MappingError[] {
    const errors: MappingError[] = [];

    const validationErrors = validateSync(mappedRow, {});
    if (validationErrors.length > 0) {
      errors.push(
        ...validationErrors.flatMap(validationError =>
          Object.entries(validationError.constraints).map(
            ([constraintName, constraintMessage]) =>
              ({
                code: 'ValidationError',
                type: constraintName,
                line,
                property: validationError.property,
                message: constraintMessage,
                mappedValue: validationError.value,
              }) as MappingError,
          ),
        ),
      );
    } else {
      for (const fieldName of this.uniqueFieldNames) {
        const value = mappedRow[fieldName];
        if (value != null) {
          if (this.uniqueValuesByFieldName[fieldName].has(value)) {
            errors.push({
              code: 'DuplicateValue',
              message: 'Duplicate value',
              property: fieldName as string,
              line,
              mappedValue: value,
            });
          } else {
            this.uniqueValuesByFieldName[fieldName].add(value);
          }
        }
      }

      if (this.businessLogicValidator) {
        errors.push(
          ...this.businessLogicValidator
            .validateRow(mappedRow, options?.t || (identity as TFunction))
            .map(
              error =>
                ({
                  ...error,
                  code: 'BusinessLogicValidationError',
                  line,
                }) as MappingError,
            ),
        );
      }
    }

    return errors;
  }
}
