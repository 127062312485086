import { useEffect, useState } from 'react';

// export const useDetectAdBlock = () => {
//   const [adBlockDetected, setAdBlockDetected] = useState(false);

//   useEffect(() => {
//     const baitUrl = 'ads.js'; // Update this path to the location of your bait file

//     fetch(baitUrl, {
//       method: 'HEAD',
//       mode: 'no-cors',
//       cache: 'no-store',
//     })
//       .then(() => setAdBlockDetected(false))
//       .catch(() => setAdBlockDetected(true));
//   }, []);

//   return adBlockDetected;
// };

export const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    const checkAdBlocker = () => {
      // Create a bait element
      const bait = document.createElement('div');
      bait.className = 'adsbox';
      bait.style.display = 'none';
      document.body.appendChild(bait);

      // Check if the bait has been hidden
      if (
        window.getComputedStyle(bait).display === 'none' ||
        bait.offsetHeight === 0
      ) {
        setAdBlockDetected(true);
      } else {
        setAdBlockDetected(false);
      }

      // Clean up
      document.body.removeChild(bait);
    };

    checkAdBlocker();
    window.addEventListener('load', checkAdBlocker);
    return () => window.removeEventListener('load', checkAdBlocker);
  }, []);

  return adBlockDetected;
};
