import * as React from 'react';
const DesignerAislesAccessBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesAccessBottomIcon"
    {...props}
  >
    <rect
      width={21.27}
      height={87.82}
      x={54.5}
      y={5.19}
      strokeWidth={0}
      opacity={0.4}
    />
    <g opacity={0.7}>
      <rect width={21.27} height={27.91} x={2.52} y={65.1} strokeWidth={0} />
      <rect width={21.27} height={27.91} x={2.52} y={35.15} strokeWidth={0} />
      <rect width={21.27} height={27.91} x={2.52} y={5.19} strokeWidth={0} />
    </g>
    <g opacity={0.7}>
      <rect width={21.27} height={27.91} x={28.51} y={65.1} strokeWidth={0} />
      <rect width={21.27} height={27.91} x={28.51} y={35.15} strokeWidth={0} />
      <rect width={21.27} height={27.91} x={28.51} y={5.19} strokeWidth={0} />
    </g>
    <polygon
      strokeWidth={0}
      points="13.41 125 2.52 118.28 2.52 125 13.41 125"
    />
    <polygon
      strokeWidth={0}
      points="68.43 125 23.04 97 2.52 97 2.52 97.92 46.42 125 68.43 125"
    />
    <polygon
      strokeWidth={0}
      points="40.92 125 2.52 101.31 2.52 114.89 18.91 125 40.92 125"
    />
    <polygon
      strokeWidth={0}
      points="101.44 125 123.45 125 78.06 97 56.05 97 101.44 125"
    />
    <polygon
      strokeWidth={0}
      points="73.93 125 95.94 125 50.55 97 28.54 97 73.93 125"
    />
    <polygon
      strokeWidth={0}
      points="124.09 122 124.09 108.43 105.57 97 83.56 97 124.09 122"
    />
    <polygon
      strokeWidth={0}
      points="124.09 97 111.07 97 124.09 105.03 124.09 97"
    />
    <path
      strokeWidth={0}
      d="M101.74,50.77c.72,0,1.3.58,1.3,1.3v27.89s12.13-12.12,12.13-12.12v8.96s-15.31,15.3-15.31,15.3l-15.3-15.29v-8.96s12.13,12.12,12.13,12.12v-27.89c0-.72.59-1.3,1.3-1.3h3.75Z"
    />
  </svg>
);
export default DesignerAislesAccessBottomIcon;
