import {
  useBeginImportJobMultipartUploadMutation,
  useCompleteImportJobMultipartUploadMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { format } from 'date-fns';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { errorAppender } from '../store/error.state';
import { getChunkKey, uploadFile } from './store/import.helper';
import {
  importJob,
  importSelectedFile,
  importTypeCurrent,
  importUploadChunkStatus,
  importUploadStatus,
} from './store/import.state';
import useProcessImportJob from './useProcessImportJob';

const chunkSize = 1024 * 1024 * 50;

export type LoadLocationsStatsByRuleParams = {};

function useUploadFile() {
  const [startUpload] = useBeginImportJobMultipartUploadMutation();
  const [completeUpload] = useCompleteImportJobMultipartUploadMutation();
  const [processJob] = useProcessImportJob();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Location stats by rule.`;

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLocationsStatsByRuleParams) => {
        set(importUploadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLocationsStatsByRuleParams) => {
        const importType = await snapshot.getPromise(importTypeCurrent);
        const file = await snapshot.getPromise(importSelectedFile);
        const job = await snapshot.getPromise(importJob);

        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importUploadStatus, AsyncLoadStatus.Error);
        }

        if (_.isNil(job)) {
          handleError(t`Job should exists`);
          return;
        }

        if (_.isNil(file)) {
          handleError(t`File is not selected`);
          return;
        }
        const jobId = job.id;
        const chunkCount = Math.ceil(file.size / chunkSize);
        const filename = file.name;

        const targetTitle = `${file.name}-${format(new Date(), 'yyyy-MM-dd')}`;

        try {
          const { data: startUploadData, errors: startUploadErrors } =
            await startUpload({
              variables: {
                id: jobId,
                parts: chunkCount,
              },
            });

          if (!_.isEmpty(startUploadErrors)) {
            const details = _(startUploadErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          const uploadUrls = startUploadData.beginImportJobMultipartUpload;
          const etags: string[] = [];
          await Promise.all(
            _.range(chunkCount).map(i => {
              return new Promise<void>((resolve, reject) => {
                const chunkId = getChunkKey(jobId, i);
                //resolve();
                uploadFile({
                  blob: file.slice(i * chunkSize, (i + 1) * chunkSize),
                  url: uploadUrls[i],

                  onComplete: (e, etag) => {
                    etags[i] = etag;
                    resolve();
                  },

                  onError: e => {
                    console.error(e);
                  },

                  onProgress: e => {
                    set(importUploadChunkStatus(chunkId), {
                      id: chunkId,
                      jobId,
                      status: AsyncLoadStatus.Loading,
                      loaded: e.loaded,
                      total: e.total,
                    });
                  },
                });
              });
            }),
          );

          const { data: completeJobData, errors: completeJobErrors } =
            await completeUpload({
              variables: {
                id: jobId,
                etags,
              },
            });

          if (!_.isEmpty(completeJobErrors)) {
            const details = _(completeJobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          set(importJob, completeJobData.completeImportJobMultipartUpload);
          set(importUploadStatus, AsyncLoadStatus.Ok);
          processJob({ type: importType });
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: LoadLocationsStatsByRuleParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    //
  }

  return [call, cancel] as const;
}
export default useUploadFile;
