export const tag = {
  render: 'Tag',
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    type: {
      type: String,
      default: 'tag',
      matches: ['tip', 'suggestion', 'caution', 'check', 'note', 'warning'],
    },
    // title: {
    //   type: String,
    // },
    icon: {
      type: String,
    },
  },
};
