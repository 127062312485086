import { TFunction } from 'i18next';
import { WizardStepConfig } from '../../../../common/types';
import * as Icon from '../../../../components/icons';

export type ConverterWizardStepId =
  | 'data-source'
  | 'getting-started'
  | 'change-name'
  | 'measurement-system'
  | 'setup-floors'
  | 'areas-setup'
  | 'organize-areas'
  | 'delete-areas'
  | 'position-areas'
  | 'aisles-bays-setup'
  | 'aisles-setup'
  | 'merge-aisles'
  | 'order-aisles'
  | 'access-aisles'
  | 'tunnel-aisles'
  | 'custom-aisles'
  | 'default-sizes'
  | 'bay-setup'
  | 'location-setup'
  | 'resize-align'
  | 'spacers'
  | 'finalize'
  | 'connect-areas'
  | 'define-starting-points'
  | 'designer';

export const wizardSteps: (
  t: TFunction<'designer'>,
) => WizardStepConfig<ConverterWizardStepId>[] = t => [
  {
    id: 'data-source',
    menuLevel: 1,
    title: t('Data Source', { ns: 'designer' }),
    icon: Icon.CloudSync,
    className: 'bg-app-panel-dark',
    // treeLine: true,
  },
  {
    id: 'getting-started',
    title: t('Getting Started', { ns: 'designer' }),
    stepCounter: '1.',
    menuLevel: 1,
    treeLine: true,
    treeLineStart: true,
  },
  {
    id: 'change-name',
    menuLevel: 2,
    title: t('Layout Properties', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'measurement-system',
    menuLevel: 2,
    title: t('System of Measurement', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'setup-floors',
    menuLevel: 2,
    title: t('Warehouse Floors', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'areas-setup',
    stepCounter: '2.',
    title: t('Areas', { ns: 'designer' }),
    //icon="Icon.CircleArrowRight"
    menuLevel: 1,
    treeLine: true,
  },
  {
    id: 'organize-areas',
    menuLevel: 2,
    title: t('Floor Assignment', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'delete-areas',
    menuLevel: 2,
    title: t('Delete Areas', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'position-areas',
    menuLevel: 2,
    title: t('Areas Orientation', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'aisles-bays-setup',
    stepCounter: '3.',
    menuLevel: 1,
    title: t('Aisles, Locations and Bays', { ns: 'designer' }),
    treeLine: true,
    //icon="Icon.CircleArrowRight"
  },
  {
    id: 'aisles-setup',
    menuLevel: 2,
    title: t('Aisles', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'merge-aisles',
    menuLevel: 3,
    title: t('Merge Aisles', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'order-aisles',
    menuLevel: 3,
    title: t('Aisles Order', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'access-aisles',
    menuLevel: 3,
    title: t('Access Aisles', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'tunnel-aisles',
    menuLevel: 3,
    title: t('Tunnel Aisles', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'custom-aisles',
    treeLine: true,
    menuLevel: 3,
    title: t('Custom Aisles', { ns: 'designer' }),
  },
  {
    id: 'spacers',
    menuLevel: 2,
    title: t('Spacers and Blocks', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'bay-setup',
    menuLevel: 2,
    title: t('Racks and Shelving', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'location-setup',
    menuLevel: 2,
    title: t('Locations Setup', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'default-sizes',
    menuLevel: 3,
    title: t('Location Profiles', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'resize-align',
    menuLevel: 3,
    title: t('Location Dimensions', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'finalize',
    stepCounter: '4.',
    title: t('Navigation', { ns: 'designer' }),
    //icon="Icon.CircleArrowRight"
    menuLevel: 1,
    treeLine: true,
  },

  {
    id: 'connect-areas',
    menuLevel: 2,
    title: t('Connect & Link Areas', { ns: 'designer' }),
    treeLine: true,
  },
  {
    id: 'define-starting-points',
    menuLevel: 2,
    title: t('Starting Point', { ns: 'designer' }),
    treeLine: true,
  },

  {
    id: 'designer',
    stepCounter: '5.',
    menuLevel: 1,
    title: t('Finish', { ns: 'designer' }),
    treeLine: true,
    treeLineEnd: true,
  },
];
