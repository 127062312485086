import * as React from 'react';
const WarehouseCo2Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseCo2Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,24.5L.84,40.93v62.58h126.32v-62.58l-62.66-16.43ZM33.59,66.4c0,.47-.24.78-.74.78h-.12c-1.49-.12-3.64-.31-5.8-.31-2.7,0-4.86,1.41-4.86,6.62v4.94c0,5.21,2.15,6.62,4.86,6.62,2.15,0,4.31-.2,5.8-.31h.12c.51,0,.74.31.74.78v3.8c0,.55-.31.74-.86.9-.82.27-3.09.67-5.84.67-5.95,0-11.59-3.13-11.59-12.46v-4.94c0-9.32,5.64-12.42,11.59-12.42,2.74,0,5.01.39,5.84.67.55.16.86.31.86.86v3.8ZM59.64,78.31c0,9.09-5.13,12.57-11.59,12.57s-11.59-3.49-11.59-12.57v-4.66c0-9.09,5.13-12.57,11.59-12.57s11.59,3.49,11.59,12.57v4.66ZM72.76,96.29h-10.58v-1.45l2.47-2.31c3.25-2.94,4.94-4.78,4.94-6.78,0-1.49-.94-2.74-2.98-2.74-1.37,0-2.55.71-3.29,1.21l-.82-1.76c1.06-.9,2.82-1.65,4.78-1.65,3.68,0,5.21,2.31,5.21,4.43,0,2.94-2.23,5.09-5.21,7.72l-1.21,1.1v.08h6.7v2.15Z"
    />
    <path
      strokeWidth={0}
      d="M48.04,66.95c-3.29,0-4.82,2-4.82,6.7v4.66c0,4.7,1.53,6.7,4.82,6.7s4.86-2,4.86-6.7v-4.66c0-4.7-1.57-6.7-4.86-6.7Z"
    />
  </svg>
);
export default WarehouseCo2Icon;
