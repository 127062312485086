import * as React from 'react';
const DeliveryVanIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DeliveryVanIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M127.5,79.61c0-.23-.77-.7-.77-.94,0-4.1,0-10.13,0-10.94,0-3.71-1.95-4.87-3.81-6.72-6.42-6.4-19.02-20.66-22.36-24.53-1.13-1.31-2.77-2.05-4.5-2.05-2.83,0-62.32,0-90.95,0-1.32,0-1.98.6-2.06,1.95-.23,3.78-1.24,35.28-1.23,38.31,0,1.09-.2,2.02-.99,2.81-.16.16-.31.46-.31.68-.02,2.23-.03,4.46,0,6.69.01.88,1.06,2.1,1.85,2.11.85.01,6.28.02,14.36.02-.12-.65-.2-1.32-.2-2.01,0-5.83,4.73-10.55,10.55-10.55s10.55,4.73,10.55,10.55c0,.69-.07,1.36-.2,2.01,16.27,0,35.39,0,51.06,0-.12-.65-.2-1.32-.2-2.01,0-5.83,4.73-10.55,10.55-10.55s10.55,4.73,10.55,10.55c0,.68-.07,1.35-.2,2,6.14,0,9.9,0,9.94,0,0,0,3.95.06,6.6-.59,1.06-.26,1.73-1.32,1.73-2.41v-1.45c0-.23,0-1.29,0-2.93h0ZM112.29,58.99h-19.87c-3.11,0-5.63-2.52-5.63-5.63v-9.57c0-.13.1-.23.23-.23h15.26c.74,0,1.44.33,1.92.89l10.72,11.77s.07.11.06.17c-.05,1.44-1.24,2.6-2.7,2.6h0ZM124.74,71.45c-2.12,0-3.84-1.72-3.84-3.84v-2.57h1.79c1.13,0,2.05.92,2.05,2.05v4.36h0Z"
    />
    <path
      strokeWidth={0}
      d="M27.1,76.38c-4.75,0-8.6,3.85-8.6,8.6,0,.69.09,1.36.25,2.01.91,3.78,4.3,6.6,8.36,6.6s7.45-2.81,8.36-6.59c.15-.65.25-1.32.25-2.01,0-4.75-3.85-8.6-8.6-8.6h0ZM32.75,87c-.83,2.33-3.04,3.99-5.65,3.99s-4.83-1.67-5.65-3.99c-.22-.63-.35-1.3-.35-2.01,0-3.32,2.69-6,6-6s6,2.69,6,6c0,.71-.13,1.38-.35,2.01h0Z"
    />
    <path
      strokeWidth={0}
      d="M98.87,76.38c-4.75,0-8.6,3.85-8.6,8.6,0,.69.09,1.36.25,2.01.9,3.78,4.3,6.6,8.36,6.6s7.46-2.82,8.36-6.6c.15-.64.24-1.31.24-2,0-4.75-3.85-8.61-8.6-8.61h0ZM104.52,86.99c-.83,2.33-3.04,4-5.65,4s-4.83-1.67-5.65-4c-.22-.63-.35-1.3-.35-2.01,0-3.32,2.69-6,6-6s6,2.69,6,6c0,.7-.13,1.37-.35,2h0Z"
    />
  </svg>
);
export default DeliveryVanIcon;
