import * as React from 'react';
const AgentStandingGroupX3Icon = props => (
  <svg
    aria-hidden="true"
    data-name="AgentStandingGroupX3Icon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={64}
      cy={11.86}
      r={11.38}
      transform="translate(26.12 64.79) rotate(-64.91)"
    />
    <circle cx={36.69} cy={14.01} r={8.36} opacity={0.5} />
    <path d="m83.58,69.11c2.11,0,3.82-1.71,3.82-3.82v-24.89c0-7.93-6.44-14.36-14.37-14.37h-18.07c-7.93,0-14.36,6.44-14.37,14.37v24.89c.03,2.11,1.77,3.8,3.89,3.76,2.06-.03,3.73-1.7,3.76-3.76v-22.29c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46v77.31c.04,3.14,2.62,5.65,5.76,5.61,3.08-.04,5.57-2.53,5.61-5.61v-42.34c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46v42.34c.04,3.14,2.62,5.65,5.76,5.61,3.08-.04,5.57-2.53,5.61-5.61V43c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46v22.29c0,2.11,1.71,3.82,3.82,3.82Z" />
    <path
      d="m82.65,26.02c4.8,3.21,7.68,8.6,7.69,14.37v24.89c0,3.72-3.01,6.75-6.73,6.75-1.37,0-2.71-.41-3.84-1.19v49.47c0,.97-.17,1.94-.5,2.86,1.58,2.71,5.05,3.63,7.76,2.06,1.75-1.02,2.83-2.89,2.82-4.92v-42.34c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46v42.34c.04,3.14,2.62,5.65,5.76,5.61,3.08-.04,5.57-2.53,5.61-5.61V43c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46l2,22.29c.03,2.11,1.77,3.8,3.88,3.76,2.06-.03,3.73-1.7,3.76-3.76l-2-24.89c0-7.93-6.44-14.36-14.37-14.37h-17.7Z"
      opacity={0.8}
    />
    <path
      d="m15.1,69.11c2.11,0,3.82-1.71,3.82-3.82l2-22.29c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46v77.31c.04,3.14,2.62,5.65,5.76,5.61,3.08-.04,5.57-2.53,5.61-5.61v-42.34c0-.81.66-1.46,1.46-1.46s1.46.66,1.46,1.46v42.34c0,3.14,2.54,5.68,5.68,5.69,2.02,0,3.89-1.07,4.91-2.81-.33-.92-.5-1.89-.5-2.87v-49.47c-3.06,2.12-7.26,1.35-9.38-1.72-.78-1.13-1.2-2.47-1.19-3.84v-24.89c0-5.77,2.89-11.17,7.69-14.37h-17.7c-7.93,0-14.36,6.44-14.37,14.37l-2,24.89c0,2.11,1.71,3.82,3.82,3.82Z"
      opacity={0.5}
    />
    <circle cx={91.31} cy={14.01} r={7.71} opacity={0.8} />
  </svg>
);
export default AgentStandingGroupX3Icon;
