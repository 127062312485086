import * as React from 'react';
const FlipVerticalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FlipVerticalIcon"
    {...props}
  >
    <path strokeWidth={0} d="M3,116.03l55.3-42.03L3,31.97v84.06Z" />
    <path strokeWidth={0} d="M67.7,74l55.3,42.03V31.97l-55.3,42.03Z" />
    <path
      strokeWidth={0}
      d="M63,86c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,106c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,116c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,46c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,56c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,26c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,36c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,66c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M63,76c-1.11,0-2,.89-2,2v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-1.11-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M64.42,96.04c-.74-.74-2.08-.74-2.84,0-.36.38-.58.9-.58,1.42,0,.09.04.18.05.27-.01.09-.05.17-.05.27v2c0,1.11.89,2,2,2s2-.89,2-2v-2c0-.1-.04-.18-.05-.27.01-.09.05-.18.05-.27,0-.52-.22-1.04-.58-1.42Z"
    />
    <path
      strokeWidth={0}
      d="M26.95,33.86c1.02.41,2.19-.09,2.6-1.12,5.43-13.6,18.88-22.74,33.46-22.74s26.94,8.45,32.73,21.09l-11.8-3.16c-1.06-.28-2.16.35-2.45,1.41s.35,2.16,1.41,2.45l15.46,4.14c.17.05.35.07.52.07.02,0,.04-.01.07-.01.02,0,.05.01.07.01.21,0,.44-.04.65-.11.87-.3,1.36-1.14,1.31-2.01l3.97-14.82c.29-1.07-.35-2.16-1.41-2.45-1.06-.28-2.16.35-2.45,1.41l-2.58,9.63c-6.84-13.09-20.62-21.66-35.49-21.66-16.2,0-31.13,10.15-37.17,25.26-.41,1.03.09,2.19,1.12,2.6Z"
    />
  </svg>
);
export default FlipVerticalIcon;
