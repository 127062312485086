import {
  AreaConfiguration,
  SpacerAreaSettings,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import { Button } from '../../../../components/actions/Button';
import ButtonToolbar from '../../../../components/actions/ButtonToolbar';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import { InputValue } from '../../../../components/inputs/InputValue';
import { Container } from '../../../../components/layout/ContainerFlex';
import { Spacer } from '../../../../components/layout/Spacer';
import TitleSection from '../../../../components/layout/TitleSection';
import PanelContainer from '../../../../containers/PanelContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import {
  converterAreaConfiguration,
  converterEditableAreaIdAtom,
  converterSelectedAreaId,
} from '../store/converter.area.state';
import { converterLayoutSelectedAisle } from '../store/converter.layout.state';
import {
  converterLayoutBuilder,
  converterNewSpacerType,
  converterSpacerAreaSettings,
} from '../store/converter.state';
import {
  getBaysAlignOptionMap,
  getBaysOrderInAisleOptionMap,
  getSpacerTypeOptionMap,
} from '../store/converter.types';

export type ConverterSpacerAreaInfoPanelProps = {
  hideHeader?: boolean;
};

const ConverterSpacerAreaInfoPanel: React.FC<
  ConverterSpacerAreaInfoPanelProps
> = props => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const wh = useRecoilValue(warehouseSelected);

  const [selectedId, setSelectedId] = useRecoilState(converterSelectedAreaId);
  const [editableId, setEditableId] = useRecoilState(
    converterEditableAreaIdAtom,
  );
  const layoutBuilder = useRecoilValue(converterLayoutBuilder);
  const [areaSpacers, setAreaSpacers] = useRecoilState(
    converterSpacerAreaSettings,
  );
  const [areasConfigurations, setAreaConfiguration] = useRecoilState(
    converterAreaConfiguration,
  );
  const setNewSpacerType = useSetRecoilState(converterNewSpacerType);
  const setSelectedAisle = useSetRecoilState(converterLayoutSelectedAisle);
  const setSelectedAreaId = useSetRecoilState(converterSelectedAreaId);
  const areaId = selectedId ?? editableId;
  if (_.isNil(areaId)) return null;
  const config = areasConfigurations[areaId];

  const spacerConfig = areaSpacers?.[areaId];
  if (_.isNil(spacerConfig)) return;

  function updateConfig(areaConfig: AreaConfiguration) {
    setAreaConfiguration({
      ...areasConfigurations,
      [areaId]: areaConfig,
    });
  }

  const floorMap = _.keyBy(_.values(layoutBuilder.floors), f => f.id);
  const baysAlignOption = getBaysAlignOptionMap(t);
  const baysOrderInAisle = getBaysOrderInAisleOptionMap(t);

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  const spacerTypeOptions = getSpacerTypeOptionMap(t);

  function remove() {
    setSelectedAisle(null);
    setSelectedAreaId(null);
    setAreaSpacers(_.omit(areaSpacers, areaId));
    setAreaConfiguration(_.omit(areasConfigurations, areaId));
  }

  function applyChange(patch: Partial<SpacerAreaSettings>) {
    setAreaSpacers({
      ...areaSpacers,
      [areaId]: {
        ...areaSpacers[areaId],
        ...patch,
      },
    });
  }
  const minZIndex = _(areasConfigurations)
    .map(a => a.zIndex ?? 0)
    .min();

  return (
    <>
      {!props.hideHeader && (
        <WarehouseNavigator>
          <WarehouseNavigatorItem name={t`Area`} value={areaId} active />
        </WarehouseNavigator>
      )}
      <Container col hasOverflowY hasExtraPadding>
        <PanelContainer
          id="panel-area-floor-assignment"
          title={t`Area Floor Assignment`}
          collapsible
          hasPadding
        >
          <InputGroupList hasPadding panelMode>
            <DropdownSelector
              className="text-xs"
              panelMode
              widthFull
              value={config.floor}
              values={_.values(layoutBuilder.floors).map(f => f.id)}
              renderValue={v => floorMap[v]?.title || ''}
              label={t`Area Floor`}
              onChange={(value: string) => {
                setSelectedId(null);
                setEditableId(null);
                updateConfig({ ...config, floor: value });
              }}
              icon={<Icon.Floor className="h-8 w-8 fill-current" />}
            />
          </InputGroupList>
          <ButtonToolbar
            title={t`Send to back`}
            onChange={value => {
              updateConfig({ ...config, zIndex: minZIndex - 1 });
            }}
          >
            <Icon.ArrowBottom className="h-8 w-8" />
          </ButtonToolbar>
        </PanelContainer>

        <PanelContainer
          id="panel-aisle-properties"
          title={
            spacerConfig.type === 0 ? t`Aisle Properties` : t`Block Properties`
          }
          // collapsible
          hasPadding
        >
          <InputValue
            className="flex-1"
            inputText
            key={`aisle-title-${areaId}`}
            title={t`Title`}
            icon={<Icon.TagTitle className="h-8 w-8 fill-current" />}
            value={spacerConfig.title}
            onChange={v =>
              applyChange({
                title: v,
              })
            }
          />

          <TitleSection inPanelView title={t`Size`} className="mb-1" />
          <InputNumber
            key={`aisle-width-${areaId}`}
            title={t`Width`}
            unitOfMeasure={sizeMeasureValue}
            icon={<Icon.ObjectWidth className="h-8 w-8 fill-current" />}
            value={spacerConfig.width}
            onChange={v => applyChange({ width: v })}
            range={[1, null]}
          />

          <InputNumber
            key={`aisle-height-${areaId}`}
            title={t`Height`}
            unitOfMeasure={sizeMeasureValue}
            icon={<Icon.ObjectHeight className="h-8 w-8 fill-current" />}
            value={spacerConfig.height}
            onChange={v => applyChange({ height: v })}
            range={[1, null]}
          />

          <TitleSection inPanelView title={t`Type`} className="mb-1" />

          <DropdownSelector
            className="text-sm text-white"
            panelMode
            widthFull
            DropAlignRight
            label={t`Type`}
            value={spacerConfig.type}
            values={[...spacerTypeOptions.keys()]}
            renderValue={key => spacerTypeOptions.get(key)}
            onChange={v => {
              applyChange({ type: v });
              setNewSpacerType(v);
            }}
            icon={<Icon.ObjectSettings className="h-8 w-8 fill-current" />}
          />

          <div className="flex items-center">
            <Spacer flexspace />
            <Button
              className="mt-4"
              buttonSize="xs"
              label={t`Remove`}
              hasIconAfter
              buttonIcon={<Icon.CircleX className={`h-5 w-5 fill-current`} />}
              buttonType="delete"
              onPress={remove}
            />
          </div>
        </PanelContainer>
      </Container>
    </>
  );
};

export default ConverterSpacerAreaInfoPanel;
