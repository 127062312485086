import * as React from 'react';
const ObjectBayUnknownIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectBayUnknownIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="8.04 86.14 8.04 113.88 27.58 86.14 8.04 86.14"
    />
    <polygon
      strokeWidth={0}
      points="8.04 9.11 8.04 36.85 27.58 9.11 8.04 9.11"
    />
    <polygon
      strokeWidth={0}
      points="29.18 119.41 29.18 91.84 9.76 119.41 29.18 119.41"
    />
    <polygon
      strokeWidth={0}
      points="8.04 47.62 8.04 75.37 27.58 47.62 8.04 47.62"
    />
    <polygon
      strokeWidth={0}
      points="29.18 42.38 29.18 14.81 9.76 42.38 29.18 42.38"
    />
    <polygon
      strokeWidth={0}
      points="29.18 80.9 29.18 53.32 9.76 80.9 29.18 80.9"
    />
    <path
      strokeWidth={0}
      d="M33.74,4.02H3.61v119.02h30.13l38.59-42.33v-19.74L33.74,4.02ZM30.1,44.17v1.21h2.24v36.48h-2.24v.82h0v1.21h2.24v36.48h-2.24v1.52H7.12v-1.52h-2.24v-36.48h2.24v-.52h0v-1.52h-2.24v-36.48h2.24v-.52h0v-1.52h-2.24V6.87h2.24v-1.24l22.97.03v1.21h2.24v36.48h-2.24v.82h0Z"
    />
    <polygon
      strokeWidth={0}
      points="88.62 123.07 39.91 123.07 75.93 82.71 88.62 82.71 88.62 123.07"
    />
    <rect width={26.13} height={115.02} x={95.35} y={6.02} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M119.48,8.02v111.02h-22.13V8.02h22.13M123.48,4.02h-30.13v119.02h30.13V4.02h0Z"
    />
    <circle
      cx={84.06}
      cy={43.76}
      r={41.44}
      strokeWidth={0}
      transform="translate(5.9 97.36) rotate(-61.94)"
    />
    <path
      strokeWidth={0}
      d="M84.06,9.19c-19.06,0-34.57,15.51-34.57,34.57s15.51,34.57,34.57,34.57c19.06,0,34.57-15.51,34.57-34.57,0-19.06-15.51-34.57-34.57-34.57ZM86.72,63.71c0,1.47-1.19,2.66-2.66,2.66s-2.66-1.19-2.66-2.66v-2.66c0-1.47,1.19-2.66,2.66-2.66s2.66,1.19,2.66,2.66v2.66ZM90.54,41.86c-2.36,1.52-3.82,4.37-3.82,7.43v3.78c0,1.47-1.19,2.66-2.66,2.66s-2.66-1.19-2.66-2.66v-3.78c0-4.93,2.34-9.38,6.26-11.9,1.94-1.25,3.08-3.38,3.05-5.69-.05-3.5-3.05-6.5-6.55-6.55-1.82-.09-3.49.65-4.76,1.91-1.28,1.26-1.98,2.94-1.98,4.73,0,1.47-1.19,2.66-2.66,2.66s-2.66-1.19-2.66-2.66c0-3.23,1.27-6.25,3.56-8.52,2.26-2.23,5.23-3.45,8.4-3.45.06,0,.11,0,.17,0,6.41.09,11.71,5.38,11.79,11.8.06,4.15-1.99,7.98-5.49,10.23Z"
    />
  </svg>
);
export default ObjectBayUnknownIcon;
