import { HeatmapBucket, getHeatmapBuckets } from '../../common/heatmap.helper';
import { HeatmapMetricRange } from '../../common/heatmap.types';
import { NamedColors } from '../../store/namedColors.types';
import { getABCProductHeatmapBuckets } from '../analyzeProduct/analyzeProductMetric.helper';
import { ActualityMetricDescriptorBase } from './actualityMetric.types';

export function getActualityHeatmapBuckets(
  rangeDescriptor: HeatmapMetricRange,
  metricDescriptor: ActualityMetricDescriptorBase,
  namedColors?: NamedColors,
): HeatmapBucket[] {
  switch (metricDescriptor.type) {
    case 'abc':
      return getABCProductHeatmapBuckets();
  }
  return getHeatmapBuckets(
    rangeDescriptor,
    metricDescriptor as any,
    namedColors,
  );
}
