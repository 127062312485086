import * as React from 'react';
const FiletypeDataIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FiletypeDataIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M23.56,3.25c-.47,0-.84.38-.84.85l.11,37.96,87.99-.25-.03-10.83-26.23.08c-.47,0-1.75-1.28-1.75-1.74l-.08-26.23-59.17.17ZM87.02,3.06l.07,23.69,23.69-.07L87.02,3.06Z"
    />
    <rect
      width={18.27}
      height={20.12}
      x={93.22}
      y={103.1}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(-.33 .3) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={20.12}
      x={69.82}
      y={103.16}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-.33 .23) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={20.12}
      x={46.42}
      y={103.23}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(-.33 .16) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={20.12}
      x={23.02}
      y={103.3}
      strokeWidth={0}
      opacity={0.9}
      transform="translate(-.33 .09) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={15.66}
      x={93.1}
      y={61.31}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(-.2 .3) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={15.66}
      x={69.7}
      y={61.38}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-.2 .23) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={15.66}
      x={46.3}
      y={61.44}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(-.2 .16) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={15.66}
      x={22.9}
      y={61.51}
      strokeWidth={0}
      opacity={0.9}
      transform="translate(-.2 .09) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={8.97}
      x={22.84}
      y={46.75}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(-.15 .09) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={8.97}
      x={46.24}
      y={46.68}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-.15 .16) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={8.97}
      x={69.64}
      y={46.62}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(-.15 .23) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={8.97}
      x={93.04}
      y={46.55}
      strokeWidth={0}
      opacity={0.9}
      transform="translate(-.15 .29) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={16.77}
      x={22.96}
      y={82.22}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(-.26 .09) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={16.77}
      x={46.36}
      y={82.15}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(-.26 .16) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={16.77}
      x={69.76}
      y={82.08}
      strokeWidth={0}
      opacity={0.7}
      transform="translate(-.26 .23) rotate(-.17)"
    />
    <rect
      width={18.27}
      height={16.77}
      x={93.16}
      y={82.01}
      strokeWidth={0}
      opacity={0.9}
      transform="translate(-.26 .3) rotate(-.17)"
    />
  </svg>
);
export default FiletypeDataIcon;
