import {
  GroupLocationsByFilterResultFragment,
  LayoutLocationWithMatchingFiltersFragment,
  LocationFilterIntersectionFragment,
  LocationFilterUnionFragment,
  WaypointPolicyFragment,
  WaypointPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../../layout/viewer/store/viewer.state';
import { simulationLayoutId } from '../simulation.state';
import { simulationWizardSelectedStepId } from '../simulation.wizard.state';
import {
  getWaypointPolicyOrderedLocationsMatch,
  loadLocationsByWaypointPolicy,
  loadLocationsCountWaypointPolicy,
} from './waypointPolicy.helper';
import {
  WaypointPolicyLocationMatchDesc,
  WaypointPolicyLocationMatchIdentity,
  WaypointPolicySelectionIdentity,
} from './waypointPolicy.types.';

const getKey = (postfix: string) => `warebee-waypoint-policy-${postfix}`;

export const waypointPolicy = atom<WaypointPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const waypointPolicyRule = selectorFamily<
  WaypointPolicyRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(waypointPolicy);
      return _.find(policy?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, newRule: WaypointPolicyRuleFragment) => {
      const policy = get(waypointPolicy);
      set(waypointPolicy, {
        ...policy,
        rules: policy.rules.map(rule => (rule.id === ruleId ? newRule : rule)),
      });
    },
});

const waypointPolicySelectedIdentityAtom =
  atom<WaypointPolicySelectionIdentity>({
    key: getKey('selected-identity-atom'),
    default: null,
  });

export const waypointPolicySelectedIdentity =
  selector<WaypointPolicySelectionIdentity>({
    key: getKey('selected-identity'),
    get: ({ get }) => get(waypointPolicySelectedIdentityAtom),
    set: ({ get, set }, value: WaypointPolicySelectionIdentity) => {
      set(waypointPolicySelectedIdentityAtom, value);
    },
  });

export const waypointPolicyRuleSelected = selector<WaypointPolicyRuleFragment>({
  key: getKey('rule-selected'),
  get: ({ get }) => {
    const identity = get(waypointPolicySelectedIdentity);
    const policy = get(waypointPolicy);
    return _.find(policy?.rules, rule => rule.id === identity?.ruleId);
  },
});

export const waypointPolicyLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  WaypointPolicySelectionIdentity
>({
  key: getKey('location-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (
        _.isNil(
          identity?.ruleId ||
            _.isNil(identity?.groupType) ||
            _.isNil(identity?.locationFilterId),
        )
      )
        return null;

      const rule = get(waypointPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters =
        identity.groupType === 'start'
          ? rule.startPoints?.locationsMatch?.anyOf
          : rule.endPoints?.locationsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.locationFilterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (
        _.isNil(identity?.locationFilterId) ||
        _.isNil(identity?.ruleId) ||
        _.isNil(identity?.groupType)
      ) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(waypointPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters =
        identity.groupType === 'start'
          ? rule.startPoints?.locationsMatch?.anyOf
          : rule.endPoints?.locationsMatch?.anyOf;

      const newAllFilters: LocationFilterUnionFragment = {
        anyOf: _.map(allFilters, fs =>
          fs.id === identity.locationFilterId ? value : fs,
        ),
      };

      set(waypointPolicyRule(identity.ruleId), {
        ...rule,
        startPoints:
          identity.groupType === 'start'
            ? {
                ...rule.startPoints,
                locationsMatch: newAllFilters,
              }
            : rule.startPoints,
        endPoints:
          identity.groupType === 'end'
            ? {
                ...rule.endPoints,
                locationsMatch: newAllFilters,
              }
            : rule.endPoints,
      });
    },
});

export const waypointPolicyOrderedLocationsMatch = selector<
  WaypointPolicyLocationMatchDesc[]
>({
  key: getKey('ordered-location-match'),
  get: async ({ get }) =>
    getWaypointPolicyOrderedLocationsMatch(get(waypointPolicy)),
});

export const waypointPolicyLocationByLevel = selector<
  Record<string, LayoutLocationWithMatchingFiltersFragment>
>({
  key: getKey('locations-per-level'),
  get: async ({ get }) => {
    const layoutId = get(simulationLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(waypointPolicy);

    if (_.isNil(layoutId) || _.isNil(level) || stepId !== 'policy-routing')
      return null;

    return await loadLocationsByWaypointPolicy({
      layoutId,
      policy,
      filter: {
        planeId: [planeId],
        level: [level],
      },
    });
  },
});

export const waypointPolicyBayLocations = selector<
  Record<string, LayoutLocationWithMatchingFiltersFragment>
>({
  key: getKey('locations-per-bay-by-rule'),
  get: async ({ get }) => {
    const layoutId = get(simulationLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    const bay = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(waypointPolicy);

    if (_.isNil(layoutId) || _.isNil(bay) || stepId !== 'policy-routing')
      return null;

    return await loadLocationsByWaypointPolicy({
      layoutId,
      policy,
      filter: {
        planeId: [planeId],
        bayId: [bay],
      },
    });
  },
});

export const waypointPolicyLocationCount = selectorFamily<
  GroupLocationsByFilterResultFragment,
  WaypointPolicyLocationMatchIdentity
>({
  key: getKey('locations-count-family'),
  get:
    ({ ruleId, groupType }) =>
    async ({ get }) => {
      const layoutId = get(simulationLayoutId);
      const stepId = get(simulationWizardSelectedStepId);
      const policy = get(waypointPolicy);
      if (_.isNil(layoutId) || stepId !== 'policy-routing') return null;

      const rule = get(waypointPolicyRule(ruleId));
      const filterSettings =
        groupType === 'start' ? rule?.startPoints : rule?.endPoints;

      return await loadLocationsCountWaypointPolicy({
        layoutId,
        locationsMatch: filterSettings?.locationsMatch,
      });
    },
});
