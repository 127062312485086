import classNames from 'classnames';
import React from 'react';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../../layout/LayoutActiveFeatureTag';
import ActiveFeatureLayer from '../../layout/viewer/layers/ActiveFeatureLayer';
import HoveredFeatureLayer from '../../layout/viewer/layers/HoveredFeatureLayer';
import SimulationLayoutContainer from '../SimulationLayoutContainer';
import MoveRoutesLayer from '../layers/MoveRoutesLayer';
import ReassignLocationLayer from '../layers/ReassignLocationLayer';
import SimulationAisleLayer from '../layers/SimulationAisleLayer';
import SimulationBayLayer from '../layers/SimulationBayLayer';
import SimulationNavigationLayer from '../layers/SimulationNavigationLayer';
import ReassignHeatmapToolbar from '../toolbars/ReassignHeatmapToolbar';
import SimulationLayoutAlerts from '../toolbars/SimulationLayoutLoadingAlerts';
import ReassignMoveSelectedTag from './ReassignMoveSelectedTag';

export type ReassignJobsViewProps = {
  analyzeId: string;
  title: string;
  hasJobFilterOptions?: boolean;
};
/**
 *  Simulation main content view for Reassign Jobs ( allocation / reassign steps)
 */
const ReassignJobsView: React.FC<ReassignJobsViewProps> = props => {
  return (
    <Container
      col
      overflow
      componentName={`ReassignJobsView`}
      className={classNames(
        'flex-1',
        'ltr:border-l rtl:border-r border-app-panel-dark',
      )}
    >
      <Container overflow className={classNames('relative !h-auto')}>
        <HeaderAppMenu title={props.title} className={classNames('flex-1')}>
          <Spacer flexspace />
          <ReassignMoveSelectedTag />
          <LayoutActiveFeatureTag />
        </HeaderAppMenu>
      </Container>
      <Container overflow flex1 className={classNames('relative')}>
        <Container
          col
          overflow-hidden
          componentName={`SimulationLayoutViewContainer`}
          className={classNames('relative flex-1')}
        >
          <SimulationLayoutContainer
            getAbsoluteContent={() => <SimulationLayoutAlerts />}
          >
            <SimulationAisleLayer />
            <SimulationBayLayer />
            <ReassignLocationLayer analyzeId={props.analyzeId} />
            <SimulationNavigationLayer />
            <MoveRoutesLayer />
            <ActiveFeatureLayer />
            <HoveredFeatureLayer />
          </SimulationLayoutContainer>
          <ReassignHeatmapToolbar />
        </Container>
      </Container>
    </Container>
  );
};

export default ReassignJobsView;
