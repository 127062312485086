import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../components/HelperMessage';
import InboxZero from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ItemListCard } from '../../dashboard/containers/ItemListCard';
import {
  warehouseCanUpdate,
  warehouseIsDemo,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { DashboardInfoCardProps } from '../store/dashboard.types';

const DashboardLayoutInfoCard: React.FC<DashboardInfoCardProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const isDemo = useRecoilValue(warehouseIsDemo);

  function StartButton() {
    if (!canUpdate) return null;
    return (
      <Button
        className="mt-4"
        label={t`Import Layout`}
        buttonType="primary"
        isDisabled={props.isLoading}
        full
        hasIconAfter
        buttonIcon={<Icon.CirclePlus className={`h-6 w-6 fill-current`} />}
        onPress={() => {
          navigate(`/wh/i/${warehouseId}/import/layout`);
        }}
        isLoading={props.isLoading}
      />
    );
  }

  if (props.showButtonOnly) {
    return (
      <ItemListCard>
        <StartButton />
      </ItemListCard>
    );
  }

  if (props.listMode) {
    return null;
  }

  return (
    <>
      <ItemListCard isHelper>
        <ScreenTitle subtitle={t`Data Dashboard`} title={t`Warehouse Layout`} />
        <HelperMessage hasPadding>
          <Icon.LayoutWarehouseDesign
            className={classNames('h-24 w-24', 'p-2', 'fill-current')}
          />
          <HelperMessage isLead textSize={'hero'}>
            {t`WareBee will create a digital model of your warehouse - "Digital Twin". It will accurately represent the physical space with aisles, racking system, navigation rules, access limitations and much more.`}
          </HelperMessage>
          <HelperMessage
            hasBefore
            isTip
          >{t`You can experiment with different warehouse layouts to analyse their impact on your picking process.`}</HelperMessage>
          {isDemo ? (
            <HelperMessage
              hasBefore
              iconAction={Icon.ArrowRight}
              textSize={'xl'}
            >
              {t`Click on`}
              <HelperMessageAction>{t`Layout Card`}</HelperMessageAction>
              {t`(on the right) to see your layout and locations data`}
            </HelperMessage>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {props.showButtonInline ? (
                <>
                  <HelperMessage
                    hasBefore
                    iconAction={Icon.ArrowRightBottom}
                    textSize={'xl'}
                  >
                    {t`Start the interactive import wizard by clicking on the`}
                    <HelperMessageAction>{t`Import Layout`}</HelperMessageAction>
                  </HelperMessage>
                  <StartButton />
                </>
              ) : null}
            </>
          )}
        </HelperMessage>
      </ItemListCard>
      {props.showButtonInline ? null : (
        <ItemListCard>
          {props.isLoading && <LoadingIndicator absolute selfCenter />}
          <InboxZero
            className="w-full flex-1"
            selfCenter
            message_helper={t`No Layouts found`}
          >
            <StartButton />
          </InboxZero>
        </ItemListCard>
      )}
    </>
  );
};

export default DashboardLayoutInfoCard;
