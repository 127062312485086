import { useApolloClient } from '@apollo/client';
import {
    ItemUomOrItemsSearchFilter,
    LoadItemSetDataDocument,
    LoadItemSetDataQuery,
    LoadItemSetDataQueryVariables
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
    itemSetItemsData,
    itemSetItemsDataStatus
} from '../store/itemSet.state';

export type LoadItemSetDataParams = {
  itemSetId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: ItemUomOrItemsSearchFilter;
};

function useLoadItemSetData() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Items set data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(itemSetItemsDataStatus, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadItemSetDataParams) => {
        const current = await snapshot.getPromise(itemSetItemsData);

        const query = client.watchQuery<
          LoadItemSetDataQuery,
          LoadItemSetDataQueryVariables
        >({
          query: LoadItemSetDataDocument,
          variables: {
            itemSetId: params.itemSetId,
            filter: params.filter,
            //sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? 100,
            },
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(itemSetItemsDataStatus, AsyncLoadStatus.Error);
            return;
          }

          let items = data.itemSet.itemUoms.content;

          if (params.isAppend) {
            items = [...(current?.content ?? []), ...items];
          }

          set(itemSetItemsData, {
            content: items,
            totalCount: data.itemSet.itemUoms.totalCount,
          });
          set(itemSetItemsDataStatus, AsyncLoadStatus.Ok);
        });
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadItemSetDataParams) {
    await initLoading();
    await loadDashDataCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadItemSetData;
