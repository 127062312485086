import { CreateAssignmentItemInput } from '@warebee/shared/data-access-api-dto';
import { ASSIGNMENT_MAPPING_SCHEMA } from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { MappingSettings } from '../../store/import.types';
import {
  consigneeAliases,
  locationIdAliases,
  skuAliases,
} from '../../store/sharedAliases';

export function getAssignmentSchema(
  t: TFunction<'importer'>,
): MappingSettings<CreateAssignmentItemInput> {
  const baseFields = keyBy(ASSIGNMENT_MAPPING_SCHEMA.fields, f => f.name);

  return {
    fields: [
      {
        ...baseFields['consignee'],
        title: t(`Client (Consignee)`, { ns: 'importer' }),
        description: t(
          `Client (consignee) — Name of your company or your client (if you are a Logistics Services Provider)`,
          { ns: 'importer' },
        ),
        optional: false,
        example: 'Client A',
        queryHelper: [`'$CLIENT_NAME' AS "consignee"`],
        aliases: consigneeAliases,
      },
      {
        ...baseFields['sku'],
        title: t(`Item`, { ns: 'importer' }),
        description: t(`Item code (SKU)`, { ns: 'importer' }),
        example: 'SKU1234567890',
        aliases: skuAliases,
      },
      {
        ...baseFields['locationId'],
        title: t(`Location`, { ns: 'importer' }),
        description: t(`Location Name, typically in the format AA010101`, {
          ns: 'importer',
        }),
        aliases: locationIdAliases,
      },
      {
        ...baseFields['stockUom'],
        title: t(`Item UOM`, { ns: 'importer' }),
        description: t('Item UOM in a given location', { ns: 'importer' }),
        example: 'EACH',
        aliases: [
          'stockUom',
          'uom',
          'um',
          'item_uom',
          'sku_uom',
          'product_uom',
          'part_uom',
          'partnm',
          'itm_uom',
          'itmuom',
          'inventory_uom',
          'inventoryuom',
          'stock_uom',
          'stockuom',
          'lot_uom',
          'lotuom',
          'quantity_uom',
          'quantityum',
          'quantity_um',
        ],
      },
      {
        ...baseFields['stockUomQuantity'],
        title: t(`Item UOM qty`, { ns: 'importer' }),
        description: t(`Item UOM quantity in a given location`, {
          ns: 'importer',
        }),
        example: '97',
        aliases: [
          'stockUomQuantity',
          'qty',
          'quantity',
          'item_qty',
          'itemqty',
          'sku_qty',
          'skuqty',
          'product_qty',
          'productqty',
          'itm_qty',
          'itmqty',
          'inventory_qty',
          'inventoryqty',
          'stock_qty',
          'stockqty',
          'lot_qty',
          'lotqty',
          'picked',
          'eachpicked',
          'eachespicked',
          'casespicked',
          'palletspicked',
          'layerspicked',
          'amount',
          'on_hand_qty',
          'qty_um',
          'lpn_qty',
        ],
      },
    ],
  };
}
