import {
  AnalyzeProductsByCategoryFragment,
  BatchJobStatus,
  LoadAnalyzedProductByCategoriesDocument,
  LoadAnalyzedProductByCategoriesQuery,
  LoadAnalyzedProductByCategoriesQueryVariables,
  LoadProductByCategoriesDocument,
  LoadProductByCategoriesQuery,
  LoadProductByCategoriesQueryVariables,
  LoadSimulationProductMetaDocument,
  LoadSimulationProductMetaQuery,
  LoadSimulationProductMetaQueryVariables,
  SimulationAbcFragment,
  SimulationItemMetaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { selector, selectorFamily } from 'recoil';
import { secureClient } from '../../../GraphQLClient';
import {
  simulationCurrentId,
  simulationEffectiveItemSet,
} from '../simulation.state';
import { categoryABCWidths } from './simulation.ABC.helper';

const getKey = (postfix: string) => `warebee-simulation-ABC-${postfix}`;

export const simulationABC = selector<SimulationAbcFragment>({
  key: getKey('document'),
  get: async ({ get }) => {
    const errorMsg = 'Cannot load product categories';
    const simulationId = get(simulationCurrentId);
    const effectiveItemSet = get(simulationEffectiveItemSet);

    if (
      _.isNil(simulationId) ||
      effectiveItemSet?.status !== BatchJobStatus.READY
    ) {
      return null;
    }

    try {
      const response = await secureClient.query<
        LoadProductByCategoriesQuery,
        LoadProductByCategoriesQueryVariables
      >({
        query: LoadProductByCategoriesDocument,
        variables: {
          simulationId,
          categoryWidths: categoryABCWidths,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }

      return response.data.simulation;
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});

export const analyzeABC = selectorFamily<
  AnalyzeProductsByCategoryFragment,
  string
>({
  key: getKey('analyze-document'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const errorMsg = 'Cannot load product categories';
      try {
        const response = await secureClient.query<
          LoadAnalyzedProductByCategoriesQuery,
          LoadAnalyzedProductByCategoriesQueryVariables
        >({
          query: LoadAnalyzedProductByCategoriesDocument,
          variables: {
            analyzeId,
            categoryWidths: categoryABCWidths,
          },
        });
        if (!_.isEmpty(response.errors)) {
          console.error(errorMsg, response.errors);
          throw new Error(errorMsg);
        }

        return response.data.analyzeResult?.pickedProductsByCategory;
      } catch (ex) {
        console.error(errorMsg, ex);
        throw new Error(errorMsg);
      }
    },
});

export const simulationProductsMeta = selector<
  Record<string, SimulationItemMetaFragment>
>({
  key: getKey('products-meta'),
  get: async ({ get }) => {
    const errorMsg = 'Cannot load product categories';
    const simulationId = get(simulationCurrentId);
    const effectiveItemSet = get(simulationEffectiveItemSet);

    if (
      _.isNil(simulationId) ||
      effectiveItemSet?.status !== BatchJobStatus.READY
    ) {
      return null;
    }

    try {
      const response = await secureClient.query<
        LoadSimulationProductMetaQuery,
        LoadSimulationProductMetaQueryVariables
      >({
        query: LoadSimulationProductMetaDocument,
        variables: {
          simulationId,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }
      const items = response.data.findSimulationItemsByFilter?.content;

      return _.keyBy(items, i => i.id);
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});
