import { TFunction } from 'i18next';
import _ from 'lodash';
import { formatInteger, formatToPrecision } from '../common/formatHelper';
import { Formatter } from '../common/useFormatter';
import {
  AssignmentStatItemMetric,
  AssignmentStatItemMetricDescriptor,
  AssignmentStatItemMetricDescriptorBase,
} from './assignmentStats.type';

export const assignmentStatItemMetricDescriptors: AssignmentStatItemMetricDescriptorBase[] =
  [
    {
      type: 'consignee',
      path: 'item.consignee',
      rangeType: 'distinct',
    },
    {
      type: 'skuGroup',
      path: 'item.skuGroup',
      rangeType: 'distinct',
      qualitativePaletteId: 'dimension',
    },
    {
      type: 'subGroup',
      path: 'item.subGroup',
      rangeType: 'distinct',
    },
    {
      type: 'baseUOMWeight',
      path: 'item.baseUom.netWeight',
      rangeType: 'range-linear',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'baseUOMWeight-pow',
      path: 'item.baseUom.netWeight',
      rangeType: 'range-pow',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'baseUOMVolume',
      path: 'item.baseUom.volume',
      rangeType: 'range-linear',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'baseUOMVolume-pow',
      path: 'item.baseUom.volume',
      rangeType: 'range-pow',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'baseUOMCapacity',
      path: 'baseUOMCapacity',
      rangeType: 'range-pow',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'baseUOMDensity',
      path: 'baseUOMDensity',
      rangeType: 'range-linear',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'baseUOMProportionality',
      path: 'baseUOMProportionality',
      rangeType: 'range-pow',
      sequentialPaletteId: 'elevation-sort',
    },
    {
      type: 'pollutionClass',
      path: 'item.pollutionClass',
      rangeType: 'distinct',
    },
    {
      type: 'transportClass',
      path: 'item.transportClass',
      rangeType: 'distinct',
    },
    {
      type: 'stockCategory',
      path: 'item.stockCategory',
      rangeType: 'distinct',
    },
    {
      type: 'storageClass',
      path: 'item.storageClass',
      rangeType: 'distinct',
    },
    {
      type: 'baseUOM',
      path: 'item.baseUom.uom',
      rangeType: 'distinct',
      qualitativePaletteId: 'dimension',
    },
    {
      type: 'stockUom',
      path: 'stockUom',
      rangeType: 'distinct',
    },
    {
      type: 'locationRackingType',
      path: 'location.locationRackingType',
      rangeType: 'distinct',
    },
    {
      type: 'locationUsageType',
      path: 'location.locationUsageType',
      rangeType: 'distinct',
    },
    {
      type: 'locationOrder',
      path: 'location.locationOrder',
      rangeType: 'range-linear',
    },
    {
      type: 'congestionZone',
      path: 'location.congestionZone',
      rangeType: 'distinct',
    },
  ];

export const assignmentStatItemMetricDescriptorsMap = _.keyBy(
  assignmentStatItemMetricDescriptors,
  d => d.type,
) as Record<AssignmentStatItemMetric, AssignmentStatItemMetricDescriptorBase>;

export function getAssignmentStatItemMetricDescriptorsMap(
  t: TFunction<'simulation'>,
  formatter: Formatter,
) {
  const metrics: AssignmentStatItemMetricDescriptor[] = [
    {
      ...assignmentStatItemMetricDescriptorsMap['consignee'],
      title: t('Consignee', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['skuGroup'],
      title: t('Item Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['subGroup'],
      title: t('Sub Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOM'],
      title: t('Base Uom', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMWeight'],
      title: t('Base UOM Weight', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v, 10).fullString,
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMWeight-pow'],
      title: t('Base UOM Weight (Relative)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatWeight(v).fullString,
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMVolume'],
      title: t('Base UOM Volume', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatVolume(v).fullString,
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMVolume-pow'],
      title: t('Base UOM Volume (Relative)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatter.formatVolume(v).fullString,
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMCapacity'],
      title: t('Base UOM Capacity', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatInteger(v),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMDensity'],
      title: t('Base UOM Density', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatToPrecision(v),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['baseUOMProportionality'],
      title: t('Base UOM Proportionality', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      format: v => formatToPrecision(v),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['stockCategory'],
      title: t('Stock Category', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['stockUom'],
      title: t('Stock Uom', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['transportClass'],
      title: t('Transport Class', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['storageClass'],
      title: t('Storage Class', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['pollutionClass'],
      title: t('Pollution Class', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['locationRackingType'],
      title: t('Location Type', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['locationUsageType'],
      title: t('Usage Type', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['locmhtype'],
      title: t('Location MHE', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['locationOrder'],
      title: t('Picking Order', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
    {
      ...assignmentStatItemMetricDescriptorsMap['congestionZone'],
      title: t('Work Zone', { ns: 'simulation' }),
      subTitle: t('Location', { ns: 'simulation' }),
    },
  ];

  return _.keyBy(metrics, d => d.type);
}
