import {
  AllocationEnforcePickability,
  LocationFilterIntersectionFragment,
  SimulationItemFilterIntersectionFragment,
  useFindLocationsStatsFilterQuery,
  useFindSimulationItemsTotalByFilterQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { cn } from '../../../common/utils';
import * as Icon from '../../../components/icons';
import { PolicyFilter } from '../../../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../../../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../../../components/policies/PolicyFilterItem';
import { PolicyStatContainer } from '../../../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../../../components/policies/RuleContainer';
import RuleSectionContainer from '../../../components/policies/RuleSectionContainer';
import { Stat } from '../../../components/stats/Stat';
import { getPolicyMatchInput } from '../../../policyFilters/policyFilter.helper';
import { sidebarStateByType } from '../../../store/sidebar.state';
import {
  getItemsPriorityOptions,
  getLocationPriorityOptions,
  getPickabilityOptions,
  getPriorityDirectionOptions,
  getPriorityValue,
} from '../../store/allocation/allocation.helper';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import { AllocationPolicySelectionType } from '../../store/allocationPolicy/allocationPolicy.types';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../../store/assignmentPolicy.default';
import {
  simulationCurrent,
  simulationShowDatasetAsTable,
} from '../../store/simulation.state';
import {
  getLocationsStatsDimensions,
  useLocationCapacitySpec,
} from '../../tableViews/locationsStatsTable.config';
import PolicyRuleConstraint from '../deallocate/PolicyRuleConstraint';

export type AllocationPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canDelete?: boolean;
  onDeleteClick?: () => void;
  canRename?: boolean;
};

const AllocationPolicyRule: React.FC<AllocationPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const { ruleId } = props;
  const simulation = useRecoilValue(simulationCurrent);
  const [rule, updateRule] = useRecoilState(allocationPolicyRule(ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    allocationPolicySelectedIdentity,
  );
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);

  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-allocation-editor'),
  );

  const locationCapacitySpec = useLocationCapacitySpec();

  const simulationId = simulation.id;
  const {
    data: itemsTotalByFilter,
    loading: isLoading,
    error: itemsTotalByFilterError,
  } = useFindSimulationItemsTotalByFilterQuery({
    variables: {
      input: {
        simulationId,
        includeMatching: getPolicyMatchInput(rule.itemsMatch),
      },
    },
  });

  const dimensions = getLocationsStatsDimensions();

  const {
    data: locationStats,
    loading: locationStatsLoading,
    error: locationStatsError,
  } = useFindLocationsStatsFilterQuery({
    variables: {
      layoutId: simulation.layout.id,
      summary: {
        assignmentId: simulation.assignment.id,
        groupBy: [], //LayoutLocationsSummaryGroupBy.LOCATION_STATUS
        uoms: _.map(locationCapacitySpec, spec => _.omit(spec, 'title')),
      },
      input: {
        includeMatching: getPolicyMatchInput(rule.locationsMatch),
      },
    },
  });

  const enabledLocationSummary = _.head(
    locationStats?.layout?.locationsByFilter?.summary,
  );
  const matchedLocationCount = enabledLocationSummary?.locationCount;
  const matchedLocationCountFormatted = formatInteger(matchedLocationCount);
  const matchedLocationCapacity = _(enabledLocationSummary?.uoms)
    .map(uom => `${uom.id}: ${formatInteger(uom.uomCount)}`)
    .join(t` — `);

  const matchedItemsCount =
    itemsTotalByFilter?.findSimulationItemsByFilter?.totalCount;

  const matchedLocationCountString = `${matchedLocationCapacity}`;

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  function selectFilterIntersection(
    filterIntersectionId: string,
    selectionType: AllocationPolicySelectionType,
  ) {
    const shouldSelect =
      selectedIdentity?.selectionType !== selectionType ||
      selectedIdentity?.filterId !== filterIntersectionId;

    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        filterId: filterIntersectionId,
        selectionType: selectionType,
      });
    }

    setShowDatasetTable(
      shouldSelect && selectionType === 'locationFilter'
        ? 'allocation-locations-stats-by-rule'
        : null,
    );
    const isFilterPanelVisible =
      shouldSelect &&
      (!_.isNil(filterIntersectionId) ||
        (selectionType !== 'locationFilter' &&
          selectionType !== 'itemsFilter'));

    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !isFilterPanelVisible,
      isHidden: !isFilterPanelVisible,
    });
  }

  function addLocationIntersection(
    filter?: LocationFilterIntersectionFragment,
  ) {
    const id = nanoid();
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: [
          ...(rule.locationsMatch?.anyOf ?? []),
          { id, allOf: filter?.allOf ?? [] },
        ],
      },
    });
    selectFilterIntersection(id, 'locationFilter');
  }

  function addProductIntersection(
    filter?: SimulationItemFilterIntersectionFragment,
  ) {
    const id = nanoid();
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: [
          ...(rule.itemsMatch?.anyOf ?? []),
          { id, allOf: filter?.allOf ?? [] },
        ],
      },
    });
    selectFilterIntersection(id, 'itemsFilter');
  }

  const removeField = (fieldId: string, fieldType: string) => {
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: _.map(rule.itemsMatch?.anyOf, match => {
          if (match.id !== fieldId) return match;
          return {
            id: match.id,
            allOf: match.allOf.filter(filter => filter.type !== fieldType),
          };
        }),
      },
      locationsMatch: {
        anyOf: _.map(rule.locationsMatch?.anyOf, match => {
          if (match.id !== fieldId) return match;
          return {
            id: match.id,
            allOf: match.allOf.filter(filter => filter.type !== fieldType),
          };
        }),
      },
    });
  };

  const removeIntersection = (groupId: string) => {
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: _.filter(rule.itemsMatch?.anyOf, fg => fg.id !== groupId),
      },
      locationsMatch: {
        anyOf: _.filter(rule.locationsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  const hasIssue = false;
  const isActive = selectedIdentity?.ruleId === ruleId;
  const isActiveUOMFilters =
    isActive && selectedIdentity?.selectionType === 'uoms';

  const isActiveLimitsQuantitiesFilters =
    isActive && selectedIdentity?.selectionType === 'limitsQuantities';
  const isActiveLimitsVolumeFilters =
    isActive && selectedIdentity?.selectionType === 'limitsVolume';

  const isActivePriorityFilters =
    isActive && selectedIdentity?.selectionType === 'priorities';
  const isActiveConstraintsFilters =
    isActive && selectedIdentity?.selectionType === 'constraints';
  const isActiveItemsFilters =
    isActive && selectedIdentity?.selectionType === 'itemsFilter';
  const isActiveItemsStats =
    isActive &&
    selectedIdentity?.selectionType === 'itemsFilter' &&
    _.isNil(selectedIdentity?.filterId);

  const isActiveLocationsFilters =
    isActive && selectedIdentity?.selectionType === 'locationFilter';
  const isActiveLocationsStats =
    isActive &&
    selectedIdentity?.selectionType === 'locationFilter' &&
    _.isNil(selectedIdentity?.filterId);

  const isActiveOverflowSettingsFilters =
    isActive && selectedIdentity?.selectionType === 'overflowSettings';

  const isActivePickabilityFilters =
    isActive && selectedIdentity?.selectionType === 'pickability';

  const UOMValue = _.join(rule.uomTypes, ', ');

  const hasUOMWarning = _.isEmpty(rule.uomTypes);

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),

    namedColorKey: rule.title,
  };

  const itemsPriorityOptions = getItemsPriorityOptions(t);
  const locationPriorityOptions = getLocationPriorityOptions(t);
  const directionOptions = getPriorityDirectionOptions(t);
  const pickabilityOptions = getPickabilityOptions(t);
  const itemPriorityValue = getPriorityValue(
    t,
    rule.prioritySettings?.itemPriority,
    itemsPriorityOptions,
    directionOptions,
  );
  const locationPriorityValue = getPriorityValue(
    t,
    rule.prioritySettings?.locationPriority,
    locationPriorityOptions,
    directionOptions,
  );
  const pickabilityValue =
    pickabilityOptions[
      rule.pickabilitySettings?.enforcePickability ??
        AllocationEnforcePickability.NEVER
    ];

  const getMaxAllocatedAssignmentValue = () => {
    const maxAllocatedAssignments =
      rule?.limitSettings?.maxAllocatedAssignments;

    if (maxAllocatedAssignments === 0) return t`Do not allocate`;
    if (_.isNil(maxAllocatedAssignments)) return t`Unlimited`;

    return t('{{maxAllocatedAssignments}} assignment(s)', rule?.limitSettings);
  };

  return (
    <RuleContainer
      {...containerParams}
      isDisabled={props.isDisabled}
      hasIssue={hasIssue}
      hasIcon
      hasColorMode={true}
      hasOrder
      classNameInner="space-y-2 xl:space-y-3 p-0.5 xl:p-1"
    >
      <PolicyFilter
        label={t`Item Limits`}
        isActive={isActive}
        isDisabled={props.isDisabled}
        className="!p-0"
        transparent
      >
        <RuleSectionContainer
          isSelected={isActiveItemsFilters}
          namedColorKey={isActive ? rule.title : null}
          hasIssue={hasIssue}
          // className={cn('p-1')}
        >
          <PolicyStatContainer className="!p-0">
            <Stat
              title={t`Item(s) Filtered`}
              value={matchedItemsCount ? formatInteger(matchedItemsCount) : 0}
              className={cn('px-1 py-1 xl:px-2')}
              hasNoPadding
              transparent
              inPanelMode
              isActionable
              inFilterStat
              hasIssue={!_.isNil(itemsTotalByFilterError)}
              toggleTable
              isCheckLoading={isLoading}
              isSelected={isActive}
              onClick={() => {
                setSelectedIdentity(
                  isActiveItemsStats
                    ? null
                    : {
                        selectionType: 'itemsFilter',
                        ruleId: rule.id,
                      },
                );
              }}
            />
          </PolicyStatContainer>

          <PolicyFilterGroupList
            filterSets={rule.itemsMatch?.anyOf as any[]}
            config={getProductFilterConfigCommon(t, formatter)}
            selectedId={selectedIdentity?.filterId}
            isDisabled={props.isDisabled}
            filterGroupType="item"
            onAddGroup={f =>
              addProductIntersection(
                f as SimulationItemFilterIntersectionFragment,
              )
            }
            onDeleteField={removeField}
            onSelectGroup={groupId =>
              selectFilterIntersection(groupId, 'itemsFilter')
            }
            onDeleteGroup={removeIntersection}
          />
        </RuleSectionContainer>
      </PolicyFilter>
      <PolicyFilter
        label={t`Location Limits`}
        isActive={isActive}
        isDisabled={props.isDisabled}
        className="!p-0"
        transparent
      >
        <RuleSectionContainer
          // className={cn('pt-1 2xl:pt-2', 'mt-0')}
          isSelected={isActiveLocationsFilters}
          hasIssue={hasIssue}
          namedColorKey={isActive ? rule.title : null}
        >
          <PolicyStatContainer>
            <Stat
              title={t`Location(s) Filtered`}
              value={matchedLocationCountFormatted}
              subStats={matchedLocationCountString}
              className={cn('px-1 py-1 xl:px-2')}
              // isSelected={isActiveLocationsStats}
              isSelected={isActive}
              hasNoPadding
              transparent
              inPanelMode
              isActionable
              inFilterStat
              hasIssue={hasIssue}
              isLoading={locationStatsLoading}
              onClick={() => {
                selectFilterIntersection(null, 'locationFilter');
              }}
            />
          </PolicyStatContainer>
          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={rule.locationsMatch?.anyOf as any[]}
            config={getLocationFilterConfigCommon(t)}
            selectedId={selectedIdentity?.filterId}
            filterGroupType="location"
            onAddGroup={f =>
              addLocationIntersection(f as LocationFilterIntersectionFragment)
            }
            onDeleteField={removeField}
            onSelectGroup={groupId =>
              selectFilterIntersection(groupId, 'locationFilter')
            }
            onDeleteGroup={removeIntersection}
            showAddFilter={false}
          />
        </RuleSectionContainer>
      </PolicyFilter>

      {/*
       * UOM
       */}

      <PolicyFilter
        label={t`Which UOM(s)?`}
        isActive={isActiveUOMFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'uoms')}
        hasIssue={hasUOMWarning}
      >
        <PolicyFilterItem
          name={t`UOM`}
          isActive={isActiveUOMFilters}
          icon={hasUOMWarning ? Icon.TriangleInfo : Icon.Pallet}
          value={hasUOMWarning ? t`No UOM selected` : UOMValue}
          hasIssue={hasUOMWarning}
          isDisabled={props.isDisabled}
          isRemovable={false}
        />
      </PolicyFilter>

      <PolicyFilter
        label={t`Allocation Limits`}
        isActive={isActiveLimitsQuantitiesFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'limitsQuantities')}
      >
        <PolicyFilterItem
          name={t`Allocate Strategy`}
          isActive={isActiveLimitsQuantitiesFilters}
          value={
            rule?.requirementAggregationSettings?.aggregation
              ? t('{{period}} day(s) average', {
                  period: rule?.requirementAggregationSettings?.numberOfPeriods,
                })
              : t`Actual Demand`
          }
        />

        <PolicyFilterItem
          name={t`Limit Allocated assignment`}
          isActive={isActiveLimitsQuantitiesFilters}
          value={getMaxAllocatedAssignmentValue()}
        />

        <PolicyFilterItem
          name={t`Limit Total assignment`}
          isActive={isActiveLimitsQuantitiesFilters}
          value={
            rule?.limitSettings?.maxPickableAssignments
              ? t(
                  '{{maxPickableAssignments}} assignment(s)',
                  rule?.limitSettings,
                )
              : t`Unlimited`
          }
        />

        <PolicyFilterItem
          name={t`Assignments per round`}
          isActive={isActiveLimitsQuantitiesFilters}
          value={
            rule?.roundSettings?.maxAssignmentsPerRound
              ? t(
                  '{{maxAssignmentsPerRound}} assignment(s)',
                  rule?.roundSettings,
                )
              : t`Unlimited`
          }
        />
      </PolicyFilter>

      <PolicyFilter
        label={t`Volume Limits`}
        isActive={isActiveLimitsVolumeFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'limitsVolume')}
      >
        <PolicyFilterItem
          name={t`Limit allocated volume`}
          isActive={isActiveLimitsVolumeFilters}
          value={
            rule?.limitSettings?.maxAllocatedVolume
              ? formatter.formatVolume(rule?.limitSettings?.maxAllocatedVolume)
                  .fullString
              : t`Unlimited`
          }
        />

        <PolicyFilterItem
          isActive={isActiveLimitsVolumeFilters}
          name={t`Limit pickable volume`}
          value={
            rule?.limitSettings?.maxPickableVolume
              ? formatter.formatVolume(rule?.limitSettings?.maxPickableVolume)
                  .fullString
              : t`Unlimited`
          }
        />
      </PolicyFilter>

      <PolicyFilter
        label={t`Priorities`}
        isActive={isActivePriorityFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'priorities')}
      >
        <PolicyFilterItem
          isActive={isActivePriorityFilters}
          name={t`Items priority`}
          value={itemPriorityValue}
        />

        <PolicyFilterItem
          isActive={isActivePriorityFilters}
          name={t`Location priority`}
          value={locationPriorityValue}
        />
      </PolicyFilter>

      <PolicyRuleConstraint
        constraintSettings={rule.constraintSettings}
        isActive={isActiveConstraintsFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'constraints')}
      />

      <PolicyFilter
        label={t`Overflow Strategy`}
        isActive={isActiveOverflowSettingsFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'overflowSettings')}
      >
        <PolicyFilterItem
          name={t`Multiplexing`}
          isActive={isActiveOverflowSettingsFilters}
          value={rule?.multiplexingSettings?.allow ? t`Allowed` : t`Disabled`}
        />

        <PolicyFilterItem
          name={t`Dead Stock Removal (Auto)`}
          isActive={isActiveOverflowSettingsFilters}
          value={rule?.replacementSettings?.allow ? t`Allowed` : t`Disabled`}
        />
      </PolicyFilter>

      <PolicyFilter
        label={t`Pickability`}
        isActive={isActivePickabilityFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'pickability')}
      >
        <PolicyFilterItem
          name={t`Force pickability`}
          isActive={isActivePickabilityFilters}
          value={pickabilityValue}
        />
      </PolicyFilter>
    </RuleContainer>
  );
};
export default AllocationPolicyRule;
