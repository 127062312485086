import { useApolloClient } from '@apollo/client';
import { FetchResult } from '@apollo/client/core';
import {
  AnalyzeAllocationRunDocument,
  AnalyzeAllocationRunMutation,
  AnalyzeAllocationRunMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { errorAppender } from '../../store/error.state';
import { useLoadAnalyzeResult } from './useLoadAnalyzeResult';

function useRunAllocationAnalyze() {
  const loadAnalyzeResult = useLoadAnalyzeResult();
  const client = useApolloClient();

  const runAnalyze = useRecoilCallback(
    ({ snapshot, set }) =>
      async (allocationRunId: string) => {
        let response: FetchResult<AnalyzeAllocationRunMutation>;
        try {
          response = await client.mutate<
            AnalyzeAllocationRunMutation,
            AnalyzeAllocationRunMutationVariables
          >({
            mutation: AnalyzeAllocationRunDocument,
            variables: {
              input: {
                allocationRunId,
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Can not run analyse',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: 'Can not run analyse',
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          return;
        }

        await loadAnalyzeResult({
          analyzeId: response.data.analyzeAllocationRun.id,
          analyzeType: 'allocate',
        });
      },
  );

  return async (allocationRunId: string) => {
    await runAnalyze(allocationRunId);
  };
}

export default useRunAllocationAnalyze;
