import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import ItemSetDashboardCard from '../ItemSetDashboardCard';
import { itemSetDocument } from '../store/itemSet.state';

const ItemSetStatsPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const itemSet = useRecoilValue(itemSetDocument);

  return (
    <PanelBody className="h-full">
      <ScreenTitle isSticky title={t`Stats`} subtitle={t`Items Set`} />
      <div className={`p-2`}>
        <ItemSetDashboardCard summary={itemSet?.summary} itemLimit={20} />
      </div>
    </PanelBody>
  );
};

export default ItemSetStatsPanel;
