import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { HelpItemListCard } from '../../dashboard/containers/HelpItemListCard';
import { HelpItemListCardsContainer } from '../../dashboard/containers/HelpItemListCardsContainer';
import Callout from '../../helpContext/Callout';
import SchemaHelper from '../SchemaHelper';
import { ImportSelectPanel } from '../panels/ImportSelectPanel';
import { getItemSetSchema } from './schema/itemSetSchema';

const ImportItemSetStartView: React.FC = () => {
  const { t } = useTranslation('importer');
  const importTemplatePath = '/app/assets/importer/csv';
  const importTemplateVersion = 'v24.04.001';
  const importTemplateDataType = 'itemset';
  const importTemplateNameMetric = `WareBee-WAREHOUSE_NAME-${importTemplateDataType}-${importTemplateVersion}-TEMPLATE`;

  return (
    <Container col>
      <HeaderAppMenu title={t`Items - Getting Started`} />
      <Container
        componentName="ImportSelectFileStep"
        col
        overflow
        className={classNames(
          'flex-1',
          'border-app-background ltr:border-l rtl:border-r',
          'relative',
          'bg-app-panel-dark',
        )}
      >
        <ScreenTitle
          title={t`Item set Import`}
          subtitle={t`Map fields`}
          isSticky
          truncate
        />
        <HelperMessage hasMargin hasPadding className="sticky top-0">
          <HelpItemListCardsContainer>
            <HelpItemListCard
              className={classNames('flex-1', 'sticky top-0')}
              transparent
            >
              <HelperMessage isLead textSize={'lead'}>
                {t`We need the following data`}
              </HelperMessage>
              <HelperMessage hasBefore textSize={'hero'}>
                {t`We will only ask you for a minimal set of data to build the warehouse model. Providing all the optional fields will allow WareBee to create a more precise digital twin model.`}
              </HelperMessage>

              <Callout type="suggestion">
                {t`We recommend including Units of Measure (packaging information), volumetric's (dimensions and volume).`}
              </Callout>
              <HelperMessage
                isTip
                hasBefore
              >{t`Item Groups data will help you to configure rules and policies in the warehouse.`}</HelperMessage>
              <ImportSelectPanel
                className="mt-8"
                titleDataset={t`Items`}
                linkPath={`${importTemplatePath}/${importTemplateNameMetric}.zip`}
              />
            </HelpItemListCard>
            <HelperMessage className="flex-1" hasPadding>
              <HelperMessage isLead className="mt-2 pt-4" textSize={'xl'}>
                {t`Field Guide (Schema)`}
              </HelperMessage>
              <SchemaHelper schema={getItemSetSchema(t)} />
            </HelperMessage>
          </HelpItemListCardsContainer>
        </HelperMessage>
      </Container>
    </Container>
  );
};

export default ImportItemSetStartView;
