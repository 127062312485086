import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import AppHeaderImporter, {
  AppHeaderProps,
} from '../components/AppHeaderImporter';
import { warehouseSelected } from '../store/warehouse.state';
import {
  getImportConfigurations,
  importMenuItemsDefault,
} from './store/import.default';
import { ImportType } from './store/import.types';

const ImportHeader: React.FC<{ type: ImportType; currentStepId: string }> = ({
  type,
  currentStepId,
}) => {
  const { t } = useTranslation('importer');
  const location = useLocation();
  const { warehouseId } = useParams<{ warehouseId: string }>();
  const warehouse = useRecoilValue(warehouseSelected);
  const importConfigurations = getImportConfigurations(t);
  const menuItems = importMenuItemsDefault(t);
  const currentMenuItem = menuItems.find(item => item.id === currentStepId);

  if (!warehouse) return null;

  const datasetConfig = importConfigurations.find(
    config => config.dataType === type,
  );
  if (!datasetConfig) return null;

  const basePath = `/wh/i/${warehouse.id}`;

  const headerProps: AppHeaderProps = {
    warehouseId: warehouse.id,
    breadcrumbItems: [
      { title: warehouse.title, href: basePath },
      { title: t('Data'), href: `${basePath}/data` },
      {
        title: datasetConfig.title,
        href: datasetConfig.getDashboardPath(warehouse.id),
      },
      {
        title: t('Import {{dataType}}', { dataType: datasetConfig.title }),
        href: datasetConfig.getImportPath(warehouse.id),
      },
      {
        title: t(currentMenuItem?.title || t`Select Data`, { ns: 'app' }),
        href: location.pathname,
      },
    ],
  };

  return <AppHeaderImporter {...headerProps} />;
};

export default ImportHeader;
