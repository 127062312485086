import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import naturalCompare from 'string-natural-compare';
import { getSequentialColor } from '../../../common/color.helper';
import { layoutLocationColorMode } from '../../store/layout.state';
import { viewerBayLocations } from '../../viewer/store/viewer.state';
import BayLocationLayer from './BayLocationLayer';

const ViewerBayLocationLayer: React.FC = () => {
  const selectedMode = useRecoilValue(layoutLocationColorMode);
  const levelLocationsLoadable = useRecoilValueLoadable(viewerBayLocations);

  const locations =
    levelLocationsLoadable.state === 'hasValue'
      ? levelLocationsLoadable.contents
      : null;

  if (_.isNil(locations)) return null;

  const sorted: [string, number][] = _.values(locations)
    .sort((l1, l2) => naturalCompare(l1.locationOrder, l2.locationOrder))
    .map((l, index) => [l.locationId, index] as [string, number]);

  const locationMap = new Map(sorted);
  const total = locationMap.size;

  function getColor(loc: LayoutLevelLocationFragment): [string, string] {
    if (selectedMode === 'default' || total < 1) return null;
    const weight = (locationMap.get(loc.locationId) * 100) / (total - 1);
    return getSequentialColor(weight, 'elevation-sort');
  }
  return (
    <BayLocationLayer
      getColor={getColor}
      //getOpacity={() => (selectedMode === 'default' ? null : 1)}
    />
  );
};

export default ViewerBayLocationLayer;
