import * as React from 'react';
const SortAscendingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortAscendingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M66.08,98.67h-14.58c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h14.58c1.24,0,2.48-1.24,2.48-2.48v-7.43c0-1.24-1.24-2.48-2.48-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M66.08,73.91h-24.58c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h24.58c1.24,0,2.48-1.24,2.48-2.48v-7.43c0-1.24-1.24-2.48-2.48-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M66.08,49.14H31.49c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h34.58c1.24,0,2.48-1.24,2.48-2.48v-7.43c0-1.24-1.24-2.48-2.48-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M66.08,24.37H21.49c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h44.58c1.24,0,2.48-1.24,2.48-2.48v-7.43c0-1.24-1.24-2.48-2.48-2.48Z"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M85.1,47l-4.69.1c-2.07.04-3.05-1.44-2.16-3.31l16.59-24.05c.88-1.85,2.39-1.9,3.35-.07l18.09,23.31c.96,1.81.06,3.35-2.02,3.4l-4.69.1c-2.07.04-3.73,1.76-3.69,3.83l1,57.35c.04,2.07-1.6,3.79-3.68,3.83l-9.42.2c-2.07.04-4.79-1.6-4.83-3.67v-57.35c-.04-2.07-1.77-3.72-3.85-3.67h0Z"
    />
  </svg>
);
export default SortAscendingIcon;
