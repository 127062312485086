import {
  AssignmentComplianceItemFragment,
  AssignmentComplianceItemStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AssignmentComplianceRuleTag from '../../../assignmentCompliance/AssignmentComplianceRuleTag';
import InboxZero from '../../../components/InboxZero';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import {
  getAssignmentComplianceLocationColor,
  getAssignmentComplianceStatusColor,
  getAssignmentComplianceStatusMap,
} from '../../store/assignmentCompliance.helper';
import { assignmentComplianceLocationsByBay } from '../../store/assignmentCompliance.state';

export type LocationPropertiesAssignmentComplianceProps = {
  locationId: string;
};

const LocationPropertiesAssignmentCompliance: React.FC<
  LocationPropertiesAssignmentComplianceProps
> = props => {
  const { t } = useTranslation('simulation');
  const locationMap = useRecoilValue(assignmentComplianceLocationsByBay);

  const locComplianceData = locationMap?.[props.locationId];
  const sectionTitle = t`Assignment Compliance`;

  const statusMap = getAssignmentComplianceStatusMap(t);
  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty location`} message_helper={`(No Items)`} />
  );

  if (_.isNil(locComplianceData)) {
    return <EmptyPlaceholder />;
  }
  function getRules(
    sectionTitle: string,
    rulesIds: string[],
    violatedRules: string[],
    withPlaceholder?: boolean,
  ) {
    if (_.isEmpty(rulesIds) && !withPlaceholder) return;

    const violatedRulesMap = new Set(violatedRules ?? []);
    return (
      <>
        <StatListHeader className="mb-2 mt-4" title={sectionTitle} />
        {withPlaceholder && _.isEmpty(rulesIds) && (
          <InboxZero message={t`No Matched Rules `} selfCenter />
        )}
        {_.map(rulesIds, ruleId => {
          return (
            <AssignmentComplianceRuleTag
              key={`rule-tag-${ruleId}`}
              ruleId={ruleId}
              hasIssue={violatedRulesMap.has(ruleId)}
            />
          );
        })}
      </>
    );
  }

  function getItems(items: AssignmentComplianceItemFragment[]) {
    return _(items)
      .sortBy(item => -item.sku)
      .map(p => {
        const [background, color] = getAssignmentComplianceStatusColor(
          p.status,
        );

        return (
          <>
            <StatListItem
              key={`item-${p.itemId}`}
              title={p.sku}
              value={statusMap[p.status]}
              valueClass={'px-2 rounded text-xxs'}
              valueStyle={{
                background,
                color,
              }}
              labelValueType={'itemId'}
              labelValueFilters={{
                consignee: p.consignee,
                sku: p.sku,
              }}
            />
            {getRules(t`Items rules`, p.itemRuleIds, p.violatedRuleIds, true)}
            {getRules(
              t`Locations rules`,
              p.locationRuleIds,
              p.violatedRuleIds,
              true,
            )}
          </>
        );
      })
      .value();
  }

  const locationStatus = _.some(
    locComplianceData,
    p => p.status === AssignmentComplianceItemStatus.NONCOMPLIANT,
  )
    ? AssignmentComplianceItemStatus.NONCOMPLIANT
    : AssignmentComplianceItemStatus.COMPLIANT;

  const [background, color] = getAssignmentComplianceLocationColor(
    locComplianceData,
    null,
  );
  return (
    <PanelContainer
      id="panel-compliance-weight-items"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      <StatListItem
        title={t`Status`}
        value={statusMap[locationStatus]}
        valueClass={'px-2 rounded text-xxs'}
        valueStyle={{
          background,
          color,
        }}
      ></StatListItem>

      {!_.isEmpty(locComplianceData) && (
        <>
          <StatListHeader title={t`Item`} value={t`Status`} />
          {getItems(locComplianceData)}
        </>
      )}
    </PanelContainer>
  );
};

export default LocationPropertiesAssignmentCompliance;
