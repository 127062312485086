import {
  OrderLineDataFragment,
  OrderSetFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { atom, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import { OrderSetDataState, OrderSetMenuItemId } from './orderSet.types';

const getKey = (postfix: string) => `warebee-orderSet-${postfix}`;

export const orderSetDocumentId = atom<string>({
  key: getKey('document-id'),
  default: null,
});

export const orderSetDocument = atom<OrderSetFragment>({
  key: getKey('document'),
  default: null,
});

export const orderSetDocumentLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('document-load-state'),
  default: AsyncLoadStatus.None,
});

export const orderSetDocumentSaveStatus = atom<AsyncLoadStatus>({
  key: getKey('document-save-state'),
  default: AsyncLoadStatus.None,
});

export const orderSetItemsData = atom<OrderLineDataFragment[]>({
  key: getKey('items-data'),
  default: null,
});

export const orderSetItemsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('items-data-status'),
  default: AsyncLoadStatus.None,
});

export const orderSetItemsDataState = atom<OrderSetDataState>({
  key: getKey('items-data-state'),
  default: { totalCount: 0, searchValues: {} },
});

const orderSetSelectedMenuItemIdAll = persistAtom<
  Record<string, OrderSetMenuItemId>
>({
  key: getKey('selected-menu-item-id-all'),
  default: {},
});

export const orderSetSelectedMenuItemId = selectorFamily<
  OrderSetMenuItemId,
  string
>({
  key: getKey('selected-menu-item-id'),
  get:
    (orderSetId: string) =>
    ({ get }) => {
      return (
        get(orderSetSelectedMenuItemIdAll)?.[orderSetId] ||
        'order-set-menu-start'
      );
    },

  set:
    (orderSetId: string) =>
    ({ get, set }, value: OrderSetMenuItemId) => {
      set(orderSetSelectedMenuItemIdAll, {
        ...get(orderSetSelectedMenuItemIdAll),
        [orderSetId]: value,
      });
    },
});
