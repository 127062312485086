import { SwapSettingsInput } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import { optimisationIsEditable } from '../simulation/store/optimisation.state';
import { collapsibleStateAtom } from '../store/collapsible.state';
import SwapPolicyRule from '../swapPolicy/SwapPolicyRule';
import { getSwapPolicyInput } from '../swapPolicy/swapPolicy.helper';
import {
  swapPolicyCheckResult,
  swapPolicyDocument,
  swapPolicySelectedIdentity,
} from '../swapPolicy/swapPolicy.state';
import useCheckSwapPolicy from '../swapPolicy/useCheckSwapPolicy';

const SwapPolicyPanel: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(swapPolicyDocument);
  const checkResult = useRecoilValue(swapPolicyCheckResult);
  const [callCheck, cancelCheck] = useCheckSwapPolicy();
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    swapPolicySelectedIdentity,
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(optimisationIsEditable);
  const updateSim = useUpdateSimulation();

  const [savedPolicy, setSavedPolicy] = useState<SwapSettingsInput>(null);

  useEffect(() => {
    return () => {
      setSelectedIdentity(null);
    };
  }, []);
  // trigger policy saving and policy check request when AP changed
  useEffect(() => {
    const policyToSave = getSwapPolicyInput(policy);

    const isPolicyChanged = !_.isEqual(policyToSave, savedPolicy);
    const isInitial = savedPolicy === null;
    setSavedPolicy(policyToSave);
    if (!isPolicyChanged) return;

    if (!isInitial) {
      updateSim({
        optimizationSettings: {
          swapSettings: policyToSave,
        },
      });
    }

    //reset assignment compliance state
    cancelCheck();
    callCheck();
    return () => {
      cancelCheck();
    };
  }, [policy]);

  const removeRule = (ruleId: string) => {
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy?.rules, r => r.title)),
      t`Rule #`,
    );
    setPolicy({
      ...(policy ?? {}),
      rules: [
        ...(policy?.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  const isCheckLoading = checkResult?.status === AsyncLoadStatus.Loading;

  const hasErrorPolicy = checkResult?.result?.hasValidSwaps === false;
  const hasRules = !_.isEmpty(policy?.rules);
  const policyRuleCounter = policy?.rules?.length;

  return (
    <>
      <ScreenTitle
        subtitle={t`Optimisation`}
        title={t`Swap Policy`}
        isSticky
        helpNavTo={'simulation/optimise/simulation-optimise-policy'}
        icon={Icon.PolicySwap}
      />

      <TitleSection
        title={
          <PolicyRuleSectionTitle
            isFeasible={!hasErrorPolicy}
            counter={policyRuleCounter}
          />
        }
        inSidebarView
        hasScreenTitle
        hasAction={
          canUpdate ? (
            hasRules && (
              <Button
                label={t`Add Policy`}
                className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                }
                onPress={() => addRule()}
              />
            )
          ) : (
            <StatusTag
              title={t`Policy Locked`}
              type="locked"
              modeStyle="stroke"
              icon={Icon.Lock}
              onPress={null}
            />
          )
        }
      />
      <PolicyWell
        data-component="PolicyWellRuleSwap"
        fullHeight
        isDisabled={!canUpdate}
        className={classNames('flex-1')}
        classNameChildren={classNames('space-y-2 pb-10')}
      >
        {!hasRules && (
          <InboxZero selfCenter hasIcon message={t`No Rules found`}>
            {canUpdate && (
              <Button
                full
                buttonSize="sm"
                buttonType="primary"
                label={t`Add Policy`}
                className="mt-4 flex-1 rounded"
                onPress={addRule}
                hasIconAfter
                buttonIcon={
                  <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                }
              />
            )}
          </InboxZero>
        )}

        {_.map(policy?.rules, (rule, index) => {
          const ruleCheckResult = checkResult?.result?.rules?.find(
            r => r.id === rule.id,
          );

          return (
            <SwapPolicyRule
              isDisabled={!canUpdate}
              key={`policy-rule-swap-${rule.id}`}
              ruleId={rule.id}
              index={index + 1}
              canDelete={true}
              onDeleteClick={() => removeRule(rule.id)}
              canRename={true}
              isCheckLoading={isCheckLoading}
            />
          );
        })}
      </PolicyWell>
    </>
  );
};

export default SwapPolicyPanel;
