import * as React from 'react';
const Mhe15AutomaticMobileRobotIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe15AutomaticMobileRobotIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M127.04,109.08H3c-.4.11-.6.35-.6.69s.05.52.15.52l8,4.66h108.95l8-4.66c.1,0,.15-.17.15-.52s-.2-.58-.6-.69Z"
    />
    <path
      strokeWidth={0}
      d="M106.74,121c1.36.69,2.72.72,4.08.09,1.36-.63,2.39-1.7,3.09-3.19h-9.96c.5,1.38,1.43,2.42,2.79,3.11Z"
    />
    <path
      strokeWidth={0}
      d="M19.23,121.08c1.36.63,2.72.6,4.08-.09,1.36-.69,2.29-1.73,2.79-3.11h-9.96c.7,1.5,1.74,2.56,3.09,3.19Z"
    />
    <path
      strokeWidth={0}
      d="M103.57,81.06c.25-.07.38-.22.38-.44s-.03-.33-.09-.33l-2.89-4.15H29.07s-2.89,4.15-2.89,4.15c-.06,0-.09.11-.09.33s.12.36.38.44h77.1Z"
    />
    <path
      strokeWidth={0}
      d="M3,106.23h124.04c.4-.11.6-.34.6-.69s-.05-.52-.15-.52l-2-5.62c-2.87-8.05-9.72-15.8-17.31-15.8H21.87c-7.6,0-14.44,7.75-17.31,15.8l-2,5.62c-.1,0-.15.17-.15.52s.2.58.6.69ZM108.93,91.05c2.46,0,4.46,2,4.46,4.46s-2,4.46-4.46,4.46-4.46-2-4.46-4.46,2-4.46,4.46-4.46ZM45.36,92.99h39.33c1.4,0,2.53,1.13,2.53,2.53s-1.13,2.53-2.53,2.53h-39.33c-1.39,0-2.53-1.13-2.53-2.53s1.13-2.53,2.53-2.53ZM21.64,91.05c2.46,0,4.46,2,4.46,4.46s-2,4.46-4.46,4.46-4.46-2-4.46-4.46,2-4.46,4.46-4.46Z"
    />
    <path
      strokeWidth={0}
      d="M124.48,62.32v-2.47H4.48v2.47h6.17v8.12h-6.17v2.47h120v-2.47h-6.17v-8.12h6.17ZM20.11,62.32h39.64v8.12H20.11v-8.12ZM69.21,70.45v-8.12h39.64v8.12h-39.64Z"
    />
    <path
      strokeWidth={0}
      d="M4.44,4.89v53.11h120.03V4.89H4.44ZM109.32,34.76v15.05h-7.75v-15.05h-5.76l9.63-13.74,9.63,13.74h-5.76Z"
    />
  </svg>
);
export default Mhe15AutomaticMobileRobotIcon;
