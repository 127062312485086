import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  assignmentStatsBuckets,
  assignmentStatsItemMetricDescriptor,
  assignmentStatsLocationsByLevel,
  assignmentStatsRangeDescriptor,
} from '../../assignmentStats/assignmentStats.state';
import { getHeatmapBucketByValue } from '../../common/heatmap.helper';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { simulationLayoutHeatmapFilters } from '../store/simulation.layout.state';

const AssignmentStatsLocationLayer: React.FC = () => {
  const levelLocationsLoadable = useRecoilValueLoadable(
    assignmentStatsLocationsByLevel,
  );
  const rangeDescriptorLoadable = useRecoilValueLoadable(
    assignmentStatsRangeDescriptor,
  );
  const heatmapFilter = useRecoilValue(simulationLayoutHeatmapFilters);
  const bucketsLoadable = useRecoilValueLoadable(assignmentStatsBuckets);
  const metricDescriptor = useRecoilValue(assignmentStatsItemMetricDescriptor);
  const locations =
    levelLocationsLoadable.state === 'hasValue'
      ? levelLocationsLoadable.contents
      : null;

  const buckets =
    bucketsLoadable.state === 'hasValue' ? bucketsLoadable.contents : null;

  const rangeDescriptor =
    rangeDescriptorLoadable.state === 'hasValue'
      ? rangeDescriptorLoadable.contents
      : null;

  if (
    _.isNil(locations) ||
    _.isNil(rangeDescriptor) ||
    _.isNil(_.isNil(locations))
  )
    return null;

  const bucketsMap = _.keyBy(buckets, b => b.id);
  function getGradientColors(loc: LayoutLevelLocationFragment): string[] {
    const colors = _(locations[loc.locationId])
      .map(locationItem =>
        getHeatmapBucketByValue({
          data: locationItem,
          buckets: bucketsMap,
          metricDescriptor,
          rangeDescriptor,
        }),
      )
      .compact()
      .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
      .sortBy(b => -b.index)
      .map(p => p.color)
      .uniq()
      .value();
    return _.isEmpty(colors) ? null : colors;
  }

  return (
    <LocationLayer getGradientColors={getGradientColors} getOpacity={() => 1} />
  );
};

export default AssignmentStatsLocationLayer;
