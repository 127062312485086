import {
  AnalyzeResultLocationsFilter,
  AnalyzeResultLocationsSortBy,
  LoadAnalyzeLocationsDocument,
  LoadAnalyzeLocationsQuery,
  LoadAnalyzeLocationsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  analyzeLocationProductsData,
  analyzeLocationProductsLoadStatus,
} from '../store/analyze.state';

export type LoadAnalyzeLocationsParams = {
  analyzeId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: AnalyzeResultLocationsFilter;
  sortBy?: AnalyzeResultLocationsSortBy[];
};

function useLoadAnalyzedProducts() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load analyzed products`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(analyzeLocationProductsLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAnalyzeLocationsParams) => {
        const current = await snapshot.getPromise(analyzeLocationProductsData);

        if (_.isNil(params.analyzeId)) {
          console.log('Invalid parameter value: analyzeId:', params.analyzeId);
          return null;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(analyzeLocationProductsLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadAnalyzeLocationsQuery,
          LoadAnalyzeLocationsQueryVariables
        >({
          query: LoadAnalyzeLocationsDocument,
          variables: {
            analyzeId: params.analyzeId,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip || 0,
            },
            filter: params.filter,
            sortBy: params.sortBy,
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const locationsData = data?.analyzeResult?.visitedLocations;
            let content = locationsData?.content ?? [];

            if (params.isAppend) {
              content = [...current.content, ...content];
            }

            set(analyzeLocationProductsData, {
              totalCount: locationsData?.totalCount ?? 0,
              content,
            });
            set(analyzeLocationProductsLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAnalyzeLocationsParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAnalyzedProducts;
