import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ButtonWrapperProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  invisible?: boolean;
};

export const ButtonWrapper = ({
  children,
  className,
  disabled,
  invisible,
}: ButtonWrapperProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      className={classNames(
        'flex',
        invisible ? '' : 'p-1',
        disabled
          ? 'striped-container-sm-disabled disabled'
          : invisible
            ? ''
            : 'striped-container-sm-bg animate-ants-left',
        className,
      )}
    >
      {children}
    </div>
  );
};
