import { AssignmentPolicyInput } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { cn, getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { ActionBar } from '../components/nav/ActionBar';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import { getAssignmentPolicyInput } from '../simulation/store/assignmentPolicy.helper';
import {
  assignmentPolicyCheckLoadStatus,
  assignmentPolicyCheckResult,
  assignmentPolicyDocument,
  assignmentPolicySelectedIdentity,
  assignmentPolicyShowUnassigned,
} from '../simulation/store/assignmentPolicy.state';
import {
  simulationCurrent,
  simulationIsEditable,
} from '../simulation/store/simulation.state';
import { collapsibleStateAtom } from '../store/collapsible.state';
import { importTriggeredBySim } from '../store/global.state';
import {
  namedColorsGroupSelectedId,
  namedColorsGroups,
} from '../store/namedColors.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import AssignmentPolicyFallbackRule from './AssignmentPolicyFallbackRule';
import AssignmentPolicyRule from './AssignmentPolicyRule';
import AssignmentPolicyRuleDetailWatcher from './AssignmentPolicyRuleDetailWatcher';
import useCheckAssignmentPolicy from './useCheckAssignmentPolicy';

type MenuOptionsTuple = typeof menuAdvancedOptions;
const menuAdvancedOptions = [
  'Import',
  //'Clear Rules'
  'Re-Order',
  'Colour Modes',
] as const;
const menuAdvancedOptionsLocked = ['Colour Modes'] as const;

export type menuAdvancedOptions = MenuOptionsTuple[number];

const AssignmentPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const whId = useRecoilValue(warehouseSelectedId);
  const [policy, setPolicy] = useRecoilState(assignmentPolicyDocument);
  const checkResult = useRecoilValue(assignmentPolicyCheckResult);
  const checkResultStatus = useRecoilValue(assignmentPolicyCheckLoadStatus);
  const [checkAssignmentPolicy, cancelCheckAssignmentPolicy] =
    useCheckAssignmentPolicy();
  const updateSim = useUpdateSimulation();

  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    assignmentPolicySelectedIdentity,
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const navigate = useNavigate();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const [savedPolicy, setSavedPolicy] = useState<AssignmentPolicyInput>(null);
  const [selectedNamedColorGroup, setSelectedNamedColorGroup] = useRecoilState(
    namedColorsGroupSelectedId,
  );
  const [withUnassigned, setWithUnassigned] = useRecoilState(
    assignmentPolicyShowUnassigned,
  );
  const namedColorGroups = useRecoilValue(namedColorsGroups);
  const [showCustomOrder, setShowCustomOrder] = useState<boolean>(false);
  const [showColorsSetup, setShowColorsSetup] = useState<boolean>(false);

  // trigger policy saving and policy check request when AP changed
  useEffect(() => {
    const policyToSave = getAssignmentPolicyInput(policy);

    const isPolicyChanged = !_.isEqual(policyToSave, savedPolicy);
    const isInitial = savedPolicy === null;
    setSavedPolicy(policyToSave);
    if (!isPolicyChanged) return;

    if (!isInitial) {
      updateSim({ assignmentPolicy: getAssignmentPolicyInput(policy) });
    }
    //reset assignment compliance state
    cancelCheckAssignmentPolicy();
    checkAssignmentPolicy();
  }, [policy]);

  useEffect(() => {
    //reset assignment compliance state
    cancelCheckAssignmentPolicy();
    checkAssignmentPolicy();
  }, [withUnassigned]);

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(policy.rules.map(r => r.title)),
      t`Rule #`,
    );
    setPolicy({
      ...policy,
      rules: [
        ...policy.rules,
        {
          id: ruleId,
          title: ruleTitle,
          locationsMatch: { anyOf: [] },
          productsMatch: { anyOf: [] },
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  function moveRule(from, to) {
    const newRules = [...policy.rules];
    newRules.splice(to, 0, newRules.splice(from, 1)[0]);
    setPolicy({
      ...policy,
      rules: newRules,
    });
  }

  const proceedWithImport = () => {
    setImportTriggerSimId(sim.id);
    navigate(`/wh/i/${whId}/import/ap`);
  };

  const hasRules = !_.isEmpty(policy?.rules);
  const policyRuleCounter = policy?.rules?.length;

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={t`Storage policy`}
        subtitle={t`Items — Locations`}
        isSticky
        helpNavTo={'simulation/policies/policy-storage/policy-storage'}
        icon={Icon.PolicyAssignment}
      />

      <TitleSection
        title={
          <PolicyRuleSectionTitle
            isFeasible={true}
            counter={policyRuleCounter}
          />
        }
        inPanelView
        className={cn('z-400 top-12 xl:top-20')}
        hasScreenTitle
        hasAction={
          canUpdate ? (
            <>
              {hasRules && (
                <Button
                  label={t`Add Policy`}
                  className={cn('rounded ltr:ml-4 rtl:mr-4')}
                  buttonSize="xs"
                  buttonType="primary"
                  hasIconAfter={
                    <Icon.CirclePlus className={cn('h-5 w-5 fill-current')} />
                  }
                  onPress={() => addRule()}
                />
              )}

              <DropdownSelector
                className={cn('text-xs ltr:ml-3 rtl:mr-3')}
                DropAlignRight
                buttonTransparent
                vertical
                w_lg
                value={
                  withUnassigned
                    ? t`Stats: All Items`
                    : t`Stats: Assigned Items`
                }
                values={[
                  t`Stats: Assigned Items`,
                  t`Stats: All Items`,
                  ...menuAdvancedOptions,
                ]}
                onChange={(option: menuAdvancedOptions) => {
                  switch (option) {
                    case t`Stats: Assigned Items`:
                      setWithUnassigned(false);
                      break;
                    case t`Stats: All Items`:
                      setWithUnassigned(true);
                      break;
                    case t`Re-Order`:
                      showCustomOrder
                        ? setShowCustomOrder(false)
                        : setShowCustomOrder(true);
                      break;
                    case t`Colour Modes`:
                      showColorsSetup
                        ? setShowColorsSetup(false)
                        : setShowColorsSetup(true);
                      break;
                    case t`Import`:
                      proceedWithImport();
                      break;
                    // case t`Clear Rules`:
                    //   // TODO: Clear All rules
                    //   break;
                  }
                }}
              />
            </>
          ) : (
            <>
              <StatusTag
                title={t`Policy Locked`}
                type="locked"
                modeStyle="stroke"
                icon={Icon.Lock}
                onPress={null}
              />
              <DropdownSelector
                className={cn('ltr:ml-3 rtl:mr-3')}
                DropAlignRight
                buttonTransparent
                vertical
                value={'...'}
                values={[...menuAdvancedOptionsLocked]}
                onChange={(option: menuAdvancedOptions) => {
                  switch (option) {
                    case t`Stats: Assigned Items`:
                      setWithUnassigned(false);
                      break;
                    case t`Stats: All Items`:
                      setWithUnassigned(true);
                      break;
                    case t`Colour Modes`:
                      showColorsSetup
                        ? setShowColorsSetup(false)
                        : setShowColorsSetup(true);
                      break;
                  }
                }}
              />
            </>
          )
        }
      />
      {showColorsSetup && (
        <ActionBar className="p-1">
          <DropdownSelector
            widthFull
            className={cn('px-1 py-1 ltr:ml-3 rtl:mr-3')}
            DropAlignLeft
            value={selectedNamedColorGroup}
            values={[null, ..._.keys(namedColorGroups)]}
            renderValue={id =>
              namedColorGroups[id]?.title ?? t`Dynamic colours`
            }
            onChange={groupId => setSelectedNamedColorGroup(groupId)}
          />
        </ActionBar>
      )}

      <AssignmentPolicyRuleDetailWatcher />
      <Container col flex1 hasOverflowY>
        <PolicyWell fullHeight isDisabled={!canUpdate} isCentred={!hasRules}>
          {!hasRules && (
            <InboxZero selfCenter hasIcon message={t`No Rules found`}>
              {canUpdate && (
                <div
                  className={cn(
                    'mt-4',
                    'flex items-center',
                    'flex-col xl:flex-row',
                    'space-y-2 xl:space-y-0',
                    'w-full',
                  )}
                >
                  <Button
                    full
                    buttonSize="xs"
                    buttonType="primary"
                    label={t`Add Policy`}
                    className={cn('w-full flex-1 rounded')}
                    onPress={addRule}
                    hasIconAfter={
                      <Icon.CirclePlus
                        className={cn('h-5 w-5', 'fill-current')}
                      />
                    }
                  />
                  <div
                    className={cn('text-xxs text-menu-active mx-4')}
                  >{t`OR`}</div>
                  <Button
                    full
                    buttonSize="xs"
                    buttonType="primary"
                    label={t`Import Policy`}
                    className={cn('w-full flex-1 rounded')}
                    onPress={() => {
                      proceedWithImport();
                    }}
                    hasIconAfter={
                      <Icon.CloudUploadCsv
                        className={cn('h-5 w-5', 'fill-current')}
                      />
                    }
                  />
                </div>
              )}
            </InboxZero>
          )}

          {_.map(policy?.rules, (rule, index) => {
            const ruleCheckResult = checkResult?.rules?.find(
              r => r.id === rule.id,
            );
            const locationCount: [number, number] = [
              ruleCheckResult?.locationFilterMatch?.total,
              ruleCheckResult?.locationFilterMatch?.exclusive,
            ];

            const productCount: [number, number] = [
              ruleCheckResult?.productFilterMatch?.total,
              ruleCheckResult?.productFilterMatch?.exclusive,
            ];

            return (
              <AssignmentPolicyRule
                isDisabled={!canUpdate}
                key={`ap-filter-tag-rule-${rule.id}`}
                ruleId={rule.id}
                index={index + 1}
                isRemovable={true}
                canDelete={true}
                canRename={true}
                onDeleteClick={() => removeRule(rule.id)}
                canMoveUp={index > 0}
                onMoveUp={() => moveRule(index, index - 1)}
                canMoveDown={index < policy.rules.length - 1}
                onMoveDown={() => moveRule(index, index + 1)}
                isActive={selectedIdentity?.ruleId === rule.id}
                isCheckLoading={checkResultStatus !== AsyncLoadStatus.Ok}
                locationCount={locationCount}
                productCount={productCount}
                hasOrder={showCustomOrder}
              />
            );
          })}
        </PolicyWell>
        <TitleSection title={t`Default Policy`} inPanelView />
        <PolicyWell
          data-component="PolicyWellRuleStorageAssignmentFallback"
          fullHeight
          isDisabled={!canUpdate}
          className={cn('mb-2 flex-1')}
          classNameChildren={cn('space-y-2 pb-10')}
        >
          <AssignmentPolicyFallbackRule key={`ap-filter-tag-fallback-rule`} />
        </PolicyWell>
      </Container>
    </Container>
  );
};

export default AssignmentPolicy;
