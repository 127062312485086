import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useDetectAdBlock } from './AdBlockDetector';
import { SEGMENT_WRITE_KEY } from './common/environmentVariables';
import { loggedInUser } from './store/auth.state';
import { analyticsUserState } from './store/global.state';

export let analyticsAgent: Analytics | null = null;

const useAnalyticsSetup = () => {
  const analyticsEnabled = useRecoilValue(analyticsUserState);
  const writeKey = SEGMENT_WRITE_KEY;
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    console.warn(
      `Analytics state changed: Enabled=${analyticsEnabled}, AdBlock Detected=${adBlockDetected}, Key=${!!writeKey}`,
    );

    if (!analyticsEnabled) {
      console.warn(
        'Analytics Disabled (User): Analytics have been disabled by the user.',
      );
    }

    if (!writeKey) {
      console.warn(
        'Analytics Disabled (No Key): No Analytics write key provided.',
      );
    }

    if (adBlockDetected) {
      console.warn(
        'Analytics Disabled (AdBlock): Analytics are disabled due to an ad blocker.',
      );
    }

    if (analyticsEnabled && writeKey) {
      AnalyticsBrowser.load({ writeKey })
        .then(([anl]) => {
          analyticsAgent = anl;
          console.log('Analytics initialized.');
        })
        .catch(() => {
          console.warn('Failed to initialize analytics.');
          analyticsAgent = null;
        });
    } else {
      console.warn(
        'Analytics setup skipped or disabled based on the conditions above.',
      );
      analyticsAgent = null;
    }

    // Cleanup when component unmounts or dependencies change
    return () => {
      console.warn('Disabling analytics...');
      analyticsAgent = null;
    };
  }, [analyticsEnabled, writeKey]); //adBlockDetected

  return { analyticsAgent, analyticsEnabled }; //adBlockDetected
};

function AnalyticTracker() {
  const location = useLocation();
  const user = useRecoilValue(loggedInUser);
  const { analyticsAgent, analyticsEnabled } = useAnalyticsSetup();

  useEffect(() => {
    // !adBlockDetected &&
    if (analyticsEnabled && analyticsAgent) {
      console.log('Tracking page:', location.pathname);
      analyticsAgent.page(location.pathname);
    }
  }, [location, analyticsAgent, analyticsEnabled]);

  useEffect(() => {
    if (analyticsEnabled && analyticsAgent) {
      if (user && user.id !== analyticsAgent.user()?.id()) {
        analyticsAgent.identify(user.id, {
          name: user.fullName,
          email: user.email,
        });
        analyticsAgent.track('User: Sign In');
      } else if (!user && analyticsAgent.user()?.id()) {
        analyticsAgent.track('User: Sign Out');
        analyticsAgent.reset();
      }
    }
  }, [user, analyticsAgent, analyticsEnabled]);

  return null;
}

export default AnalyticTracker;
