import { useApolloClient } from '@apollo/client';
import {
  CheckAssignmentPolicyDocument,
  CheckAssignmentPolicyQuery,
  CheckAssignmentPolicyQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../common/types';
import { getAssignmentPolicyInputFromLSP } from '../simulation/store/locationSharingPolicy/locationSharingPolicy.default';
import {
  locationSharingPolicy,
  locationSharingPolicyCheckResult,
} from '../simulation/store/locationSharingPolicy/locationSharingPolicy.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';

function useCheckLocationSharingPolicy() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Items count for Assignment policy`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(locationSharingPolicyCheckResult, {
      status: AsyncLoadStatus.Loading,
      result: null,
    });
  });

  const callCheck = useRecoilCallback(({ snapshot, set }) => async () => {
    const simulation = await snapshot.getPromise(simulationCurrent);
    const policy = await snapshot.getPromise(locationSharingPolicy);

    const layoutId = simulation?.layout?.id;
    const assignmentId = simulation?.assignment?.id;
    const itemSetId = simulation?.itemSet?.id;

    if (_.isNil(layoutId) || _.isNil(assignmentId) || _.isNil(policy)) {
      console.error('Layout and Assignment should be selected');
      set(locationSharingPolicyCheckResult, {
        status: AsyncLoadStatus.Error,
        result: null,
      });
      return;
    }

    function handleError(details, stack) {
      set(errorAppender, {
        id: nanoid(),
        title: errorTitle,
        details: details,
        callStack: stack,
      });
      set(locationSharingPolicyCheckResult, {
        status: AsyncLoadStatus.Error,
        result: null,
      });
    }

    const query = client.watchQuery<
      CheckAssignmentPolicyQuery,
      CheckAssignmentPolicyQueryVariables
    >({
      query: CheckAssignmentPolicyDocument,
      variables: {
        input: {
          simulationId: simulation.id,
          policy: getAssignmentPolicyInputFromLSP(policy),
        },
      },
    });

    const queryObservable = query.subscribe(
      ({ data, errors }) => {
        if (errors) {
          console.error(errors);
          handleError(null, errors.map(e => e.message).join('. '));
          return;
        }
        const checkResult = data?.checkSimulationAssignmentPolicy?.policy;
        set(locationSharingPolicyCheckResult, {
          status: AsyncLoadStatus.Ok,
          result: checkResult,
        });
      },
      error => {
        console.error(error);
        handleError(error.message || error, error.stack || null);
      },
    );
    setObservable(queryObservable);
  });

  async function call() {
    await initLoading();
    await callCheck();
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useCheckLocationSharingPolicy;
