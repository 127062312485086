import * as React from 'react';
const CircleMinusIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleMinusIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,14c-27.57,0-50,22.43-50,50s22.43,50,50,50,50-22.43,50-50S91.57,14,64,14ZM88.04,67.85h-48.08c-2.12,0-3.85-1.72-3.85-3.85s1.72-3.85,3.85-3.85h48.08c2.12,0,3.85,1.72,3.85,3.85s-1.72,3.85-3.85,3.85Z"
    />
  </svg>
);
export default CircleMinusIcon;
