import {
  PicklistLinesOrderOptions,
  PicklistLinesSortByFragment,
  PicklistLinesSortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import TitleSection from '../../components/layout/TitleSection';
import { ActionBar } from '../../components/nav/ActionBar';
import OrderedItemsTable from '../../import/layout/converter/panels/OrderedItemsTable';
import { picklistLinesOrderOptions } from '../../simulation/store/pickingPolicy/pickingPolicy.default';
import { getPicklistLinesOrderOptionTitle } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';

const menuOptions = [
  //'Sort (ASC)',
  //'Sort (DESC)',
  'Delete',
] as const;
type PopupOptionsTuple = typeof menuOptions;
export type MenuOption = PopupOptionsTuple[number];
export type MenuAction = 'SORT' | 'DELETE';

function getActionTitle(action: MenuAction, t: TFunction<'components'>) {
  switch (action) {
    case 'SORT':
      return t`Sort (ASC)`;
    case 'DELETE':
      return t`Delete`;
  }
  return action;
}

const PickingPolicySortByEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [rule, setRule] = useRecoilState(
    pickingPolicyRule(selectedIdentity?.ruleId),
  );
  const [selectedType, setSelectedType] = useState<PicklistLinesOrderOptions>();
  const current = rule?.picklistSettings?.picklistLinesOrder ?? [];
  const currentTypesSorted = _.map(current, sortBy => sortBy.type);
  const usedForSort = _.keyBy(current, s => s.type);
  const availableOptions = _.filter(
    picklistLinesOrderOptions,
    type => !_.has(usedForSort, type),
  );

  useEffect(() => {
    if (!_.some(availableOptions, type => type === selectedType)) {
      setSelectedType(_.head(availableOptions));
    }
  }, [rule?.picklistSettings?.picklistLinesOrder]);
  if (!selectedIdentity || !rule) return null;

  function saveSorting(v: PicklistLinesSortByFragment[]) {
    setRule({
      ...rule,
      picklistSettings: {
        ...rule.picklistSettings,
        picklistLinesOrder: v,
      },
    });
  }

  function addSortOption(type: PicklistLinesOrderOptions) {
    const newSorting: PicklistLinesSortByFragment[] = [
      ...current,
      {
        type,
        direction: PicklistLinesSortDirection.ASC,
      },
    ];
    saveSorting(newSorting);
  }

  function removeSortOption(type: PicklistLinesOrderOptions) {
    const newSorting: PicklistLinesSortByFragment[] = _.filter(
      current,
      so => so.type !== type,
    );
    saveSorting(newSorting);
  }

  function updateSortOption(
    type: PicklistLinesOrderOptions,
    direction,
    nullsFirst,
  ) {
    const newSorting: PicklistLinesSortByFragment[] = _.map(current, so =>
      so.type !== type
        ? so
        : {
            ...so,
            direction: _.isNil(direction) ? so.direction : direction,
            nullsFirst: _.isNil(nullsFirst) ? so.nullsFirst : nullsFirst,
          },
    );
    saveSorting(newSorting);
  }

  function onSortChange(newSortedTypes: PicklistLinesOrderOptions[]) {
    const newSorting: PicklistLinesSortByFragment[] = _.map(
      newSortedTypes,
      type => usedForSort[type],
    );
    saveSorting(newSorting);
  }

  function SortIcon({ type }: { type: PicklistLinesOrderOptions }) {
    const value = usedForSort[type]?.direction;

    function onClick() {
      const newValue =
        value === PicklistLinesSortDirection.ASC
          ? PicklistLinesSortDirection.DESC
          : PicklistLinesSortDirection.ASC;
      updateSortOption(type, newValue, null);
    }

    const IconCpm = props => {
      switch (value) {
        case PicklistLinesSortDirection.ASC:
          return <Icon.SortAscending className="text-menu-text" {...props} />;

        case PicklistLinesSortDirection.DESC:
          return <Icon.SortDescending className="text-menu-text" {...props} />;

        default:
          return <Icon.SortNone className="text-menu-400" {...props} />;
      }
    };

    return (
      <div
        data-component="SortIcon"
        className={classNames(
          `flex hover:text-white cursor-pointer ltr:ml-2 rtl:mr-2 p-1`,
        )}
        onClick={onClick}
      >
        <IconCpm className={`fill-current w-5 h-5`} />
      </div>
    );
  }

  const drawItem = (type: PicklistLinesOrderOptions) => {
    return (
      <div className="flex items-center flex-1">
        <div className="flex-1 text-xs xl:text-sm truncate">
          {getPicklistLinesOrderOptionTitle(type, t)}
        </div>
        {/* <SortIcon type={type} /> */}
        <DropdownSelector
          DropAlignRight
          buttonTransparent
          vertical
          value={'...'}
          values={[...menuOptions]}
          onChange={(option: MenuOption) => {
            switch (option) {
              // case 'Sort':
              //   // updateSortOption(type);
              //   break;
              case 'Delete':
                removeSortOption(type);
                break;
            }
          }}
        />
        {/* <Button
          onPress={() => removeSortOption(type)}
          hasIconAfter
          buttonIcon={<Icon.CircleX className={`w-5 h-5 fill-current`} />}
          buttonType="transparent"
          buttonSize="sm"
          // buttonType="delete"
        /> */}
      </div>
    );
  };

  return (
    <div data-component="PickingPolicyModeEditor">
      <TitleSection
        id={`policy-editor-picking-mode-section`}
        title={t`Sort Order Lines by`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            {!_.isEmpty(availableOptions) && (
              <ActionBar className="px-1 py-2">
                <DropdownSelector
                  classNameDropdown="flex-1 text-sm mr-2"
                  classNameLabel="text-xs"
                  classNameItem="text-sm"
                  dark
                  DropAlignRight
                  // panelMode
                  widthFull
                  // buttonTransparent
                  value={selectedType}
                  renderValue={v => getPicklistLinesOrderOptionTitle(v, t)}
                  values={availableOptions}
                  onChange={v => setSelectedType(v)}
                />
                <Button
                  className="rounded"
                  buttonType="primary"
                  buttonSize="xs"
                  hasIconAfter
                  buttonIcon={<Icon.Plus className={`w-4 h-4 fill-current`} />}
                  // label={t`Add`}
                  onPress={() => addSortOption(selectedType)}
                />
              </ActionBar>
            )}

            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {!_.isEmpty(currentTypesSorted) && (
                <OrderedItemsTable
                  // itemColumnTitle={t`Sort by`}
                  items={currentTypesSorted}
                  onChange={onSortChange}
                  render={v => drawItem(v as PicklistLinesOrderOptions)}
                />
              )}

              {_.isEmpty(currentTypesSorted) && (
                <InboxZero
                  message={t`No custom order is set. Items will be picked by Location Order Metadata.`}
                />
              )}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default PickingPolicySortByEditor;
