import {
  AnalyzeResultEventTypeSummary,
  AnalyzeResultFragment,
  AnalyzeResultProcessType,
  PicklistEventType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../../common/useFormatter';
import { LCV_EU_Multiplier } from '../../../store/resourcePolicy.default';
import { resourcePolicyPickingAgents } from '../../../store/resourcePolicy.state';
import { EventGroup, EventGroupMap } from '../../../store/simulation.helper';
import WorkforceAgentStatItem from './WorkforceAgentStatItem';

export type WorkforceSummaryPanelProps = {
  analyzeResult: AnalyzeResultFragment;
  cardView?: boolean;
};

const groupEvents = (events: AnalyzeResultEventTypeSummary[]) =>
  _.reduce(
    events,
    (acc, event) => {
      return {
        ...acc,
        [EventGroupMap[event.eventType]]: {
          totalCost:
            (acc[EventGroupMap[event.eventType]]?.totalCost ?? 0) +
            event.totalCost,
          totalDuration:
            (acc[EventGroupMap[event.eventType]]?.totalDuration ?? 0) +
            event.totalDuration,
        },
      };
    },
    {} as Record<EventGroup, { totalCost: number; totalDuration: number }>,
  );

const WorkforceAgentsStats: React.FC<WorkforceSummaryPanelProps> = props => {
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  const agents = useRecoilValue(resourcePolicyPickingAgents);

  const { analyzeResult } = props;
  if (!analyzeResult) return null;

  const pickingEvents = _.filter(
    analyzeResult.summary?.eventTypes,
    e => e.processType === AnalyzeResultProcessType.PICKING,
  );

  const dynamoByAgent = _.reduce(
    analyzeResult?.summary?.agents,
    (acc, agent) => ({
      ...acc,
      [agent.agent]: _.reduce(
        agent.eventTypes.filter(
          e => e.processType === AnalyzeResultProcessType.PICKING,
        ),
        (acc, e) => {
          if (
            e.eventType !== PicklistEventType.TRAVELLING_HORIZONTAL &&
            e.eventType !== PicklistEventType.TRAVELLING_VERTICAL
          )
            return acc;

          const a = agents[agent.agent];
          const energyRate =
            a.specs?.powerSource?.energyConsumptionPerSecond ?? 0;

          const speed =
            e.eventType !== PicklistEventType.TRAVELLING_VERTICAL
              ? a.specs.horizontalTravelling?.speed?.speedLaden ?? 0
              : 0;

          return {
            distance: acc.distance + e.totalDuration * speed,
            co2: acc.co2 + e.totalDuration * energyRate * LCV_EU_Multiplier,
          };
        },
        {
          distance: 0,
          co2: 0,
        },
      ),
    }),
    {} as Record<string, { distance: number; co2: number }>,
  );

  const agentSummaryMap = _.keyBy(
    props.analyzeResult.summary.agents,
    a => a.agent,
  );

  return (
    // <StatGroup
    //   title={t`Workforce Agents`}
    //   subTitle={t`Stats`}
    //   cardView={props.cardView}
    //   helpNavTo={'simulation/workforce/simulation-workforce'}
    // >
    <>
      {_.map(agents, agent => {
        return (
          <WorkforceAgentStatItem
            key={`picking-agent-summary-${agent.id}`}
            agent={agent}
            agentSummary={agentSummaryMap[agent.id]}
            distance={formatter.formatDistance(
              dynamoByAgent[agent.id]?.distance,
            )}
            co2={formatter.formatCO2(dynamoByAgent[agent.id]?.co2)}
          />
        );
      })}
    </>

    // </StatGroup>
  );
};

export default WorkforceAgentsStats;
