import classNames from 'classnames';
import React from 'react';
import { Container } from '../components/layout/ContainerFlex';
import AnalyzeCompareLayoutStages from './AnalyzeCompareLayoutStages';

const AnalyzeCompareMainView: React.FC = () => {
  return (
    <Container
      overflow
      componentName={`AnalyzeCompareMainView`}
      className={classNames(
        'flex-1 ltr:border-l rtl:border-r border-app-panel-dark',
      )}
    >
      <AnalyzeCompareLayoutStages />

      {/* <SimulationExtraDatasetView /> */}
    </Container>
  );
};

export default AnalyzeCompareMainView;
