export const countyTaxIds = [
  {
    country: 'Australia',
    taxType: 'au_abn',
    taxTitle: 'Australian Business Number (AU ABN)',
    pattern: '12345678912',
    countryCode: 'AU',
  },
  {
    country: 'Australia',
    taxType: 'au_arn',
    taxTitle: 'Australian Taxation Office Reference Number',
    pattern: '123456789123',
    countryCode: 'AU',
  },
  {
    country: 'Austria',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'ATU12345678',
    countryCode: 'AT',
  },
  {
    country: 'Belgium',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'BE0123456789',
    countryCode: 'BE',
  },
  {
    country: 'Brazil',
    taxType: 'br_cnpj',
    taxTitle: 'Brazilian CNPJ number',
    pattern: '01.234.456/5432-10',
    countryCode: 'BR',
  },
  {
    country: 'Brazil',
    taxType: 'br_cpf',
    taxTitle: 'Brazilian CPF number',
    pattern: '123.456.789-87',
    countryCode: 'BR',
  },
  {
    country: 'Bulgaria',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'BG0123456789',
    countryCode: 'BG',
  },
  {
    country: 'Canada',
    taxType: 'ca_bn',
    taxTitle: 'Canadian BN',
    pattern: '123456789',
    countryCode: 'CA',
  },
  {
    country: 'Canada',
    taxType: 'ca_gst_hst',
    taxTitle: 'Canadian GST/HST number',
    pattern: '123456789RT0002',
    countryCode: 'CA',
  },
  {
    country: 'Canada',
    taxType: 'ca_pst_bc',
    taxTitle: 'Canadian PST number (British Columbia)',
    pattern: 'PST-1234-5678',
    countryCode: 'CA',
  },
  {
    country: 'Canada',
    taxType: 'ca_pst_mb',
    taxTitle: 'Canadian PST number (Manitoba)',
    pattern: '123456-7',
    countryCode: 'CA',
  },
  {
    country: 'Canada',
    taxType: 'ca_pst_sk',
    taxTitle: 'Canadian PST number (Saskatchewan)',
    pattern: '1234567',
    countryCode: 'CA',
  },
  {
    country: 'Canada',
    taxType: 'ca_qst',
    taxTitle: 'Canadian QST number (Québec)',
    pattern: '1234567890TQ1234',
    countryCode: 'CA',
  },
  {
    country: 'Chile',
    taxType: 'cl_tin',
    taxTitle: 'Chilean TIN',
    pattern: '12.345.678-K',
    countryCode: 'CL',
  },
  {
    country: 'Croatia',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'HR12345678912',
    countryCode: 'HR',
  },
  {
    country: 'Cyprus',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'CY12345678Z',
    countryCode: 'CY',
  },
  {
    country: 'Czech Republic',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'CZ1234567890',
    countryCode: 'CZ',
  },
  {
    country: 'Denmark',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'DK12345678',
    countryCode: 'DK',
  },
  {
    country: 'Estonia',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'EE123456789',
    countryCode: 'EE',
  },
  {
    country: 'Finland',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'FI12345678',
    countryCode: 'FI',
  },
  {
    country: 'France',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'FRAB123456789',
    countryCode: 'FR',
  },
  {
    country: 'Georgia',
    taxType: 'ge_vat',
    taxTitle: 'Georgian VAT',
    pattern: '123456789',
    countryCode: 'GE',
  },
  {
    country: 'Germany',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'DE123456789',
    countryCode: 'DE',
  },
  {
    country: 'Greece',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'EL123456789',
    countryCode: 'GR',
  },
  {
    country: 'Hong Kong',
    taxType: 'hk_br',
    taxTitle: 'Hong Kong BR number',
    pattern: '12345678',
    countryCode: 'HK',
  },
  {
    country: 'Hungary',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'HU12345678912',
    countryCode: 'HU',
  },
  {
    country: 'Iceland',
    taxType: 'is_vat',
    taxTitle: 'Icelandic VAT',
    pattern: '123456',
    countryCode: 'IS',
  },
  {
    country: 'India',
    taxType: 'in_gst',
    taxTitle: 'Indian GST number',
    pattern: '12ABCDE3456FGZH',
    countryCode: 'IN',
  },
  {
    country: 'Indonesia',
    taxType: 'id_npwp',
    taxTitle: 'Indonesian NPWP number',
    pattern: '12.345.678.9-012.345',
    countryCode: 'ID',
  },
  {
    country: 'Ireland',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'IE1234567AB',
    countryCode: 'IE',
  },
  {
    country: 'Israel',
    taxType: 'il_vat',
    taxTitle: 'Israel VAT',
    pattern: '000012345',
    countryCode: 'IL',
  },
  {
    country: 'Italy',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'IT12345678912',
    countryCode: 'IT',
  },
  {
    country: 'Japan',
    taxType: 'jp_cn',
    taxTitle: 'Japanese Corporate Number (*Hōjin Bangō*)',
    pattern: '1234567891234',
    countryCode: 'JP',
  },
  {
    country: 'Japan',
    taxType: 'jp_rn',
    taxTitle:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    pattern: '12345',
    countryCode: 'JP',
  },
  {
    country: 'Latvia',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'LV12345678912',
    countryCode: 'LV',
  },
  {
    country: 'Liechtenstein',
    taxType: 'li_uid',
    taxTitle: 'Liechtensteinian UID number',
    pattern: 'CHE123456789',
    countryCode: 'LI',
  },
  {
    country: 'Lithuania',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'LT123456789123',
    countryCode: 'LT',
  },
  {
    country: 'Luxembourg',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'LU12345678',
    countryCode: 'LU',
  },
  {
    country: 'Malaysia',
    taxType: 'my_frp',
    taxTitle: 'Malaysian FRP number',
    pattern: '12345678',
    countryCode: 'MY',
  },
  {
    country: 'Malaysia',
    taxType: 'my_itn',
    taxTitle: 'Malaysian ITN',
    pattern: 'C 1234567890',
    countryCode: 'MY',
  },
  {
    country: 'Malaysia',
    taxType: 'my_sst',
    taxTitle: 'Malaysian SST number',
    pattern: 'A12-3456-78912345',
    countryCode: 'MY',
  },
  {
    country: 'Malta',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'MT12345678',
    countryCode: 'MT',
  },
  {
    country: 'Mexico',
    taxType: 'mx_rfc',
    taxTitle: 'Mexican RFC number',
    pattern: 'ABC010203AB9',
    countryCode: 'MX',
  },
  {
    country: 'Netherlands',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'NL123456789B12',
    countryCode: 'NL',
  },
  {
    country: 'New Zealand',
    taxType: 'nz_gst',
    taxTitle: 'New Zealand GST number',
    pattern: '123456789',
    countryCode: 'NZ',
  },
  {
    country: 'Norway',
    taxType: 'no_vat',
    taxTitle: 'Norwegian VAT number',
    pattern: '123456789MVA',
    countryCode: 'NO',
  },
  {
    country: 'Poland',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'PL1234567890',
    countryCode: 'PL',
  },
  {
    country: 'Portugal',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'PT123456789',
    countryCode: 'PT',
  },
  {
    country: 'Romania',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'RO1234567891',
    countryCode: 'RO',
  },
  {
    country: 'Russia',
    taxType: 'ru_inn',
    taxTitle: 'Russian INN',
    pattern: '1234567891',
    countryCode: 'RU',
  },
  {
    country: 'Russia',
    taxType: 'ru_kpp',
    taxTitle: 'Russian KPP',
    pattern: '123456789',
    countryCode: 'RU',
  },
  {
    country: 'Saudi Arabia',
    taxType: 'sa_vat',
    taxTitle: 'Saudi Arabia VAT',
    pattern: '123456789012345',
    countryCode: 'SA',
  },
  {
    country: 'Singapore',
    taxType: 'sg_gst',
    taxTitle: 'Singaporean GST',
    pattern: 'M12345678X',
    countryCode: 'SG',
  },
  {
    country: 'Singapore',
    taxType: 'sg_uen',
    taxTitle: 'Singaporean UEN',
    pattern: '123456789F',
    countryCode: 'SG',
  },
  {
    country: 'Slovakia',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'SK1234567891',
    countryCode: 'SK',
  },
  {
    country: 'Slovenia',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'SI12345678',
    countryCode: 'SI',
  },
  {
    country: 'South Africa',
    taxType: 'za_vat',
    taxTitle: 'South African VAT number',
    pattern: '4123456789',
    countryCode: 'ZA',
  },
  {
    country: 'South Korea',
    taxType: 'kr_brn',
    taxTitle: 'Korean BRN',
    pattern: '123-45-67890',
    countryCode: 'KR',
  },
  {
    country: 'Spain',
    taxType: 'es_cif',
    taxTitle: 'Spanish CIF number',
    pattern: 'A12345678',
    countryCode: 'ES',
  },
  {
    country: 'Spain',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'ESA1234567Z',
    countryCode: 'ES',
  },
  {
    country: 'Sweden',
    taxType: 'eu_vat',
    taxTitle: 'European VAT number',
    pattern: 'SE123456789123',
    countryCode: 'SE',
  },
  {
    country: 'Switzerland',
    taxType: 'ch_vat',
    taxTitle: 'Switzerland VAT number',
    pattern: 'CHE-123.456.789 MWST',
    countryCode: 'CH',
  },
  {
    country: 'Taiwan',
    taxType: 'tw_vat',
    taxTitle: 'Taiwanese VAT',
    pattern: '12345678',
    countryCode: 'TW',
  },
  {
    country: 'Thailand',
    taxType: 'th_vat',
    taxTitle: 'Thai VAT',
    pattern: '1234567891234',
    countryCode: 'TH',
  },
  {
    country: 'Ukraine',
    taxType: 'ua_vat',
    taxTitle: 'Ukrainian VAT',
    pattern: '123456789',
    countryCode: 'UA',
  },
  {
    country: 'United Arab Emirates',
    taxType: 'ae_trn',
    taxTitle: 'United Arab Emirates TRN',
    pattern: '123456789012345',
    countryCode: 'AE',
  },
  {
    country: 'United Kingdom',
    taxType: 'gb_vat',
    taxTitle: 'United Kingdom VAT number',
    pattern: 'GB123456789',
    countryCode: 'GB',
  },
  {
    country: 'United Kingdom',
    taxType: 'eu_vat',
    taxTitle: 'Northern Ireland VAT number',
    pattern: 'XI123456789',
    countryCode: 'GB',
  },
  {
    country: 'United States',
    taxType: 'us_ein',
    taxTitle: 'United States EIN',
    pattern: '12-3456789',
    countryCode: 'US',
  },
];
