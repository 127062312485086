import * as React from 'react';
const PalletStackingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletStackingIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="94.51 99.28 94.51 99.28 98.87 95.39 98.87 77.76 64 108.35 64 126.48 68.36 122.6 68.36 116.45 76.81 109.29 80.38 112.13 84.74 108.25 84.74 101.36 90.07 96.63 94.51 99.28"
    />
    <path
      strokeWidth={0}
      d="M29.13,77.76v17.63s34.87,31.09,34.87,31.09v-18.14s-34.87-30.58-34.87-30.58ZM30.79,90.13l31.54,27.67v4.44s-31.54-28.13-31.54-28.13v-3.98Z"
    />
    <polygon
      strokeWidth={0}
      points="33.75 76.55 33.75 51.62 61.45 74.88 61.45 100.17 33.75 76.55"
    />
    <polygon
      strokeWidth={0}
      points="66.79 74.88 94.2 51.64 94.2 76.56 66.79 100.16 66.79 74.88"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="33.75 45.81 33.75 30.03 61.45 53.29 61.45 69.43 33.75 45.81"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="66.79 53.28 94.2 30.04 94.2 45.82 66.79 69.41 66.79 53.28"
      opacity={0.6}
    />
    <polyline
      strokeWidth={0}
      points="77.62 12.11 63.58 .52 49.63 12.11 63.67 23.7 77.62 12.11"
    />
    <polyline
      strokeWidth={0}
      points="93.78 25.62 79.73 14.02 65.78 25.62 79.82 37.21 93.78 25.62"
      opacity={0.4}
    />
    <polyline
      strokeWidth={0}
      points="61.57 25.62 47.53 14.02 33.57 25.62 47.62 37.21 61.57 25.62"
      opacity={0.8}
    />
    <polyline
      strokeWidth={0}
      points="77.72 39.12 63.68 27.53 49.72 39.12 63.77 50.72 77.72 39.12"
      opacity={0.6}
    />
  </svg>
);
export default PalletStackingIcon;
