import * as React from 'react';
const TimeFullIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TimeFullIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4,64c0,33.09,26.91,60,60,60,33.09,0,60-26.91,60-60,0-33.09-26.91-60-60-60-33.09,0-60,26.91-60,60ZM103.95,61.12h9.81v5.75h-9.81v-5.75ZM90.22,94.25l4.06-4.06,6.94,6.98-4.06,4.06-6.94-6.98ZM90.22,33.75l6.94-6.98,4.06,4.06-6.94,6.98-4.06-4.06ZM61.12,103.95h5.75v9.81h-5.75v-9.81ZM61.12,32.29h5.75v30.52s22.85,22.85,22.85,22.85l-4.06,4.06-23.69-23.69c-.54-.54-.84-1.27-.84-2.03v-31.71ZM61.12,14.23h5.75v9.85h-5.75v-9.85ZM26.81,97.16l6.94-6.98,4.06,4.06-6.94,6.98-4.06-4.06ZM26.81,30.83l4.06-4.06,6.94,6.98-4.06,4.06-6.94-6.98ZM14.27,66.87v-5.75h9.81v5.75h-9.81Z"
    />
  </svg>
);
export default TimeFullIcon;
