import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimulationsListPanel from '../../components/SimulationsListPanel';
import { layoutDocument } from '../store/layout.state';

const LayoutSimulationPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(layoutDocument);

  const simulations = document?.simulationsWith?.content;
  return (
    <SimulationsListPanel subtitle={t`Layout`} simulations={simulations} />
  );
};

export default LayoutSimulationPanel;
