import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getIssuesDescriptors } from '../../store/simulation.issues.helper';
import { DataIssueIdentity } from '../../store/simulation.issues.types';

type DatasetStatusTagProps = {
  identity: DataIssueIdentity;
  className?: string;
};

const DatasetIssueTag: React.FC<DatasetStatusTagProps> = props => {
  const { t } = useTranslation('simulation');
  const descriptors = getIssuesDescriptors(t);

  const dscr = descriptors[props.identity.group]?.[props.identity?.reason];
  return (
    <span
      data-component="DatasetIssueTag"
      aria-label={`Dataset Issue Reason`}
      className={classNames(
        'ltr:ml-0.5 rtl:mr-0.5 mb-1 md:mb:0 ltr:md:ml-1 ltr:lg:ml-2 rtl:md:mr-1 rtl:lg:mr-2',
        'px-2 py-1 rounded flex-1 text-center whitespace-nowrap',
        'text-xs md:text-sm lg:text-base',
        props.className,
      )}
      style={{
        background: dscr?.color,
        color: dscr?.textColor,
      }}
    >
      {dscr?.tag}
    </span>
  );
};

export default DatasetIssueTag;
