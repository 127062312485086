import {
  LoadBalancingRuleFragment,
  useLoadBalancingRuleSummaryQuery,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { Stat } from '../components/stats/Stat';
import { StatListItem } from '../components/stats/StatListItem';
import { getLoadBalancingPolicyRuleInput } from '../loadBalancingPolicy/loadBalancingPolicy.helper';
import useGetZoneTitle from '../loadBalancingPolicy/useGetZoneTitle';
import AnalyzeResultLoadBalancingKPISwitch, {
  LoadBalancingKPI,
} from './AnalyzeResultLoadBalancingKPISwitch';
import LoadBalancingChart, {
  LoadBalancingChartDataRow,
} from './LoadBalancingChart';

export type AnalyzeResultLoadBalancingRuleProps = {
  analyzeIdBefore: string;
  analyzeIdAfter: string;
  rule: LoadBalancingRuleFragment;
};

const AnalyzeResultLoadBalancingRule: React.FC<
  AnalyzeResultLoadBalancingRuleProps
> = props => {
  const { t } = useTranslation('simulation');
  const [kpi, setKpi] = useState<LoadBalancingKPI>('absolute');
  const getZoneTitle = useGetZoneTitle();

  const {
    data: beforeData,
    loading: beforeLoading,
    error: beforeError,
  } = useLoadBalancingRuleSummaryQuery({
    variables: {
      analyzeId: props.analyzeIdBefore,
      rule: getLoadBalancingPolicyRuleInput(props.rule),
    },
  });

  const {
    data: afterData,
    loading: afterLoading,
    error: afterError,
  } = useLoadBalancingRuleSummaryQuery({
    variables: {
      analyzeId: props.analyzeIdAfter,
      rule: getLoadBalancingPolicyRuleInput(props.rule),
    },
  });

  const meanLoadBefore = Math.floor(
    beforeData?.analyzeResult?.loadBalancingRuleSummary?.meanLoad ?? 0,
  );
  const meanLoadAfter = Math.floor(
    afterData?.analyzeResult?.loadBalancingRuleSummary?.meanLoad ?? 0,
  );

  const stdDeviationBefore = Math.floor(
    beforeData?.analyzeResult?.loadBalancingRuleSummary?.stdDev ?? 0,
  );
  const stdDeviationAfter = Math.floor(
    afterData?.analyzeResult?.loadBalancingRuleSummary?.stdDev ?? 0,
  );

  const beforeZones =
    beforeData?.analyzeResult?.loadBalancingRuleSummary?.zones;
  const afterZones = afterData?.analyzeResult?.loadBalancingRuleSummary?.zones;
  let chartData: LoadBalancingChartDataRow[] = [];
  if (beforeZones && afterZones) {
    const afterMap = _.keyBy(afterZones, z => z.zoneId);
    chartData = _(beforeZones)
      .map((zone, groupIndex) => {
        return {
          zoneId: zone.zoneId,
          zoneTitle: getZoneTitle(zone.zoneId, props.rule),
          beforeAbsolute: zone.zoneLoad,
          beforeRelative: zone.zoneLoad - meanLoadBefore,
          afterAbsolute: afterMap[zone.zoneId].zoneLoad,
          afterRelative: afterMap[zone.zoneId].zoneLoad - meanLoadAfter,
        };
      })
      .sortBy(datum => datum.beforeAbsolute)
      .value();
  }
  const max = _.maxBy(chartData, r =>
    Math.max(
      kpi === 'absolute' ? r.afterAbsolute : r.afterRelative,
      kpi === 'absolute' ? r.beforeAbsolute : r.beforeRelative,
    ),
  );
  const min = _.minBy(chartData, r =>
    Math.min(
      kpi === 'absolute' ? r.afterAbsolute : r.afterRelative,
      kpi === 'absolute' ? r.beforeAbsolute : r.beforeRelative,
    ),
  );

  const isLoading = beforeLoading || afterLoading;
  const hasError = beforeError || afterError;

  const zoneCount = chartData.length ?? 1;
  const calculatedHeight = 40 * zoneCount;

  return (
    <div
      data-component="AnalyzeResultLoadBalancingRule"
      className={classNames(
        'flex flex-col flex-1 items-center relative w-full',
      )}
    >
      <Stat
        title={t(`{{title}}`, { title: props.rule.title })}
        hasHelper
        isPreview
        inPanelMode
        switchUnitOfMeasure={
          <AnalyzeResultLoadBalancingKPISwitch
            selected={kpi}
            setSelected={setKpi}
          />
        }
      >
        {/* <StatListItem
          isComparable
          title={t(`Std. Deviation (Before)`)}
          value={formatInteger(stdDeviationBefore)}
          unitOfMeasure={'visits'}
          legendType="line"
          legendColor={'rgba(162,188,204, 0.7)'}
        />
        <StatListItem
          isComparable
          title={t(`Std. Deviation (After)`)}
          value={formatInteger(stdDeviationAfter)}
          unitOfMeasure={'visits'}
          legendType="line"
          legendColor={'rgba(255,206,0,0.8)'}
        /> */}

        <StatListItem
          isComparable
          title={t(`Load Average (Before)`)}
          value={formatInteger(meanLoadBefore)}
          unitOfMeasure={'visits'}
          legendType="circle"
          legendColor={'rgba(162,188,204, 0.7)'}
        />
        <StatListItem
          isComparable
          title={t(`Load Average (After)`)}
          value={formatInteger(meanLoadAfter)}
          unitOfMeasure={'visits'}
          legendType="circle"
          legendColor={'rgba(255,206,0,0.8)'}
        />

        <div
          className={classNames(
            'p-1 md:p-2 lg:p-3 xl:p-4 ltr:px-0.5 rtl:px-0.5 ltr:xl:px-1 rtl:xl:px-1',
            'm-0.5',
            // 'bg-app-panel-dark/60',
            'min-h-10',
          )}
          style={{ height: calculatedHeight, width: '100%', minWidth: '100%' }}
        >
          <div className="h-full pb-2 pt-4 px-0.5 lg:px-2">
            {isLoading && (
              <LoadingIndicator selfCenter message={t`Loading Data`} />
            )}
            {hasError && (
              <ErrorIndicator
                selfCenter
                message={t`Apologies, we couldn't load the data`}
              />
            )}

            {!isLoading && !hasError && (
              <LoadBalancingChart
                data={chartData}
                series={['before', 'after']}
                kpi={kpi}
                max={0}
                min={0}
                avg2={stdDeviationBefore}
                avg={stdDeviationAfter}
              />
            )}
          </div>
        </div>
      </Stat>
    </div>
  );
};

export default AnalyzeResultLoadBalancingRule;
