import * as React from 'react';
const SortNoneIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortNoneIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M79.4,88.97h5.91c2.6,0,3.8,1.89,2.63,4.22l-21.83,31.08c-1.15,2.3-3.05,2.33-4.22,0l-21.83-31.08c-1.15-2.3.01-4.22,2.63-4.22h5.91c2.6,0,4.74-2.11,4.74-4.72v-8c0-2.61,2.12-4.73,4.73-4.73h11.85c2.61,0,4.73,2.12,4.73,4.73v8c0,2.61,2.12,4.72,4.74,4.72h0ZM48.6,39.03h-5.91c-2.6,0-3.8-1.89-2.63-4.22L61.89,3.74c1.15-2.3,3.05-2.33,4.22,0l21.83,31.08c1.15,2.3-.01,4.22-2.63,4.22h-5.91c-2.6,0-4.74,2.11-4.74,4.72v8c0,2.61-2.12,4.73-4.73,4.73h-11.85c-2.61,0-4.73-2.12-4.73-4.73v-8c0-2.61-2.12-4.72-4.74-4.72h0Z"
    />
  </svg>
);
export default SortNoneIcon;
