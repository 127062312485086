import * as React from 'react';
const SimulationComplianceLinesIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationComplianceLinesIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M127,72.04c0-.64-.34-1.23-.89-1.55l-4.59-2.65,4.59-2.65c.55-.32.89-.91.89-1.55s-.34-1.23-.89-1.55l-10.32-5.96-1.79,3.09,7.64,4.41-57.18,33.01L7.29,63.65l3.91-2.26-1.79-3.09-6.59,3.8c-.55.32-.89.91-.89,1.55s.34,1.23.89,1.55l4.6,2.65-4.6,2.65c-.55.32-.89.91-.89,1.55s.34,1.23.89,1.55l4.64,2.68-4.64,2.68c-.55.32-.89.91-.89,1.55s.34,1.23.89,1.55l60.75,35.07c.28.16.58.24.89.24s.62-.08.89-.24l60.75-35.07c.55-.32.89-.91.89-1.55s-.34-1.23-.89-1.55l-4.64-2.68,4.64-2.68c.55-.32.89-.91.89-1.55ZM121.64,80.49l-57.18,33.01L7.29,80.5l3.75-2.16,52.54,30.33c.28.16.58.24.89.24s.62-.08.89-.24l52.54-30.33,3.75,2.16ZM64.47,105.05L7.29,72.05l3.7-2.14,52.58,30.36c.28.16.58.24.89.24s.62-.08.89-.24l52.58-30.36,3.7,2.14-57.18,33.01Z"
    />
    <path
      strokeWidth={0}
      d="M63.53,90.81c-.31,0-.62-.08-.89-.24L1.89,55.5c-.55-.32-.89-.91-.89-1.55s.34-1.23.89-1.55L62.64,17.33c.67-.39,1.52-.3,2.09.22,25.56,23.17,54.68,16.17,54.97,16.1.91-.22,1.84.29,2.13,1.17,6.6,20.11-25.4,37.54-26.77,38.27l-30.65,17.48c-.27.16-.58.23-.88.23ZM6.36,53.95l57.18,33.01,29.81-16.99c.34-.18,28.99-15.78,25.51-32.49-6.8,1.25-32.13,4.11-55.54-16.41L6.36,53.95Z"
    />
    <path
      strokeWidth={0}
      d="M100.75,17.61s-19.41,12.63-37.07-6.96L2.79,45.81l60.89,35.15s12.08-5.64,25.41-14.58c14.58-9.76,17.57-26.07,11.66-48.78ZM52.35,59.19l-9.53-26.77,46.04,4.67-36.51,22.1Z"
    />
    <polygon
      strokeWidth={0}
      points="54.52 54.06 47.94 35.56 79.76 38.78 54.52 54.06"
      opacity={0.8}
    />
  </svg>
);
export default SimulationComplianceLinesIcon;
