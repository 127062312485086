import {
  DeallocateType,
  ReallocateQuantitySource,
  ReallocateType,
  ReallocateUomSource,
  useFindSimulationItemsTotalByFilterQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { cn } from '../../../common/utils';
import { PolicyFilter } from '../../../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../../../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../../../components/policies/PolicyFilterItem';
import { PolicyStatContainer } from '../../../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../../../components/policies/RuleContainer';
import RuleSectionContainer from '../../../components/policies/RuleSectionContainer';
import { Stat } from '../../../components/stats/Stat';
import { getPolicyMatchInput } from '../../../policyFilters/policyFilter.helper';
import { sidebarStateByType } from '../../../store/sidebar.state';
import {
  getDeallocationTypeOptions,
  getReallocationTypeOptions,
  getReallocationUOMQuantityOptions,
  getReallocationUOMSourceOptions,
} from '../../store/allocation/allocation.helper';
import {
  deallocationPolicyRule,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import { AllocationPolicySelectionType } from '../../store/allocationPolicy/allocationPolicy.types';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../../store/assignmentPolicy.default';
import { simulationCurrentId } from '../../store/simulation.state';
import PolicyRuleConstraint from './PolicyRuleConstraint';

export type DeallocatePolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;

  canDelete?: boolean;
  onDeleteClick?: () => void;
  canRename?: boolean;
};

const DeallocatePolicyRule: React.FC<DeallocatePolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const { ruleId } = props;
  const simulationId = useRecoilValue(simulationCurrentId);
  const [rule, updateRule] = useRecoilState(deallocationPolicyRule(ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    deallocationPolicySelectedIdentity,
  );

  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-allocation-editor'),
  );

  const {
    data: itemsTotalByFilter,
    loading: isLoading,
    error: itemsTotalByFilterError,
  } = useFindSimulationItemsTotalByFilterQuery({
    variables: {
      input: {
        simulationId,
        includeMatching: getPolicyMatchInput(rule.itemsMatch),
      },
    },
  });

  const matchedItemsCount =
    itemsTotalByFilter?.findSimulationItemsByFilter?.totalCount;

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  function selectFilterIntersection(
    filterIntersectionId: string,
    selectionType: AllocationPolicySelectionType,
  ) {
    const shouldSelect =
      selectedIdentity?.selectionType !== selectionType ||
      selectedIdentity?.filterId !== filterIntersectionId;

    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        filterId: filterIntersectionId,
        selectionType: selectionType,
      });
    }
    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !shouldSelect,
      isHidden: !shouldSelect,
    });
  }

  function addProductIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: [...(rule.itemsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectFilterIntersection(id, 'itemsFilter');
  }

  function addLocationIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: [...(rule.locationsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectFilterIntersection(id, 'locationFilter');
  }

  const removeField = (fieldId: string, fieldType: string) => {
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: _.map(rule.itemsMatch?.anyOf, match => {
          if (match.id !== fieldId) return match;
          return {
            id: match.id,
            allOf: match.allOf.filter(filter => filter.type !== fieldType),
          };
        }),
      },
      locationsMatch: {
        anyOf: _.map(rule.locationsMatch?.anyOf, match => {
          if (match.id !== fieldId) return match;
          return {
            id: match.id,
            allOf: match.allOf.filter(filter => filter.type !== fieldType),
          };
        }),
      },
    });
  };

  const removeIntersection = (groupId: string) => {
    updateRule({
      ...rule,
      itemsMatch: {
        anyOf: _.filter(rule.itemsMatch?.anyOf, fg => fg.id !== groupId),
      },
      locationsMatch: {
        anyOf: _.filter(rule.locationsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  const hasIssue = false;
  const isActive = selectedIdentity?.ruleId === ruleId;
  const isActiveSettingsFilters =
    isActive && selectedIdentity?.selectionType === 'settings';
  const isActiveConstraintsFilters =
    isActive && selectedIdentity?.selectionType === 'constraints';
  const isActiveItemsFilters =
    isActive && selectedIdentity?.selectionType === 'itemsFilter';
  const isActiveLocationsFilters =
    isActive && selectedIdentity?.selectionType === 'locationFilter';

  const deallocateOptions = getDeallocationTypeOptions(t);
  const reallocateOptions = getReallocationTypeOptions(t);
  const reallocateUOMOptions = getReallocationUOMSourceOptions(t);
  const reallocateQtyOptions = getReallocationUOMQuantityOptions(t);

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),
  };

  const reallocateUOMValue =
    rule.reallocateQuantitySettings?.reallocateUom ??
    ReallocateUomSource.CAPACITY_UOM;
  const reallocateQtyValue =
    rule.reallocateQuantitySettings?.reallocateQuantity ??
    ReallocateQuantitySource.BY_CAPACITY;

  const reallocateUOMFilterTitle =
    reallocateUOMValue === ReallocateUomSource.FIXED_UOM
      ? t('Fixed: {{uom}}', { uom: rule.reallocateQuantitySettings?.fixedUom })
      : _.find(reallocateUOMOptions, o => o.id === reallocateUOMValue).title;

  const reallocateQtyFilterTitle =
    reallocateQtyValue === ReallocateQuantitySource.FIXED_QUANTITY
      ? t('Fixed: {{qty}}', {
          qty: rule.reallocateQuantitySettings?.fixedUomQuantity,
        })
      : _.find(reallocateQtyOptions, o => o.id === reallocateQtyValue).title;

  return (
    <RuleContainer
      {...containerParams}
      isDisabled={props.isDisabled}
      hasIssue={hasIssue}
      hasIcon
      hasColorMode={false}
      hasOrder
      classNameInner="space-y-2 xl:space-y-3 p-0.5 xl:p-1"
    >
      <PolicyFilter
        label={t`Item Limits`}
        isActive={isActiveItemsFilters}
        isDisabled={props.isDisabled}
      >
        <RuleSectionContainer
          isSelected={isActiveItemsFilters}
          // namedColorKey={isActive ? rule.title : null}
          className={cn('p-1')}
        >
          <PolicyStatContainer>
            <Stat
              title={t`Item(s) Filtered`}
              value={matchedItemsCount ? formatInteger(matchedItemsCount) : 0}
              hasNoPadding
              className={cn('px-1 py-1 xl:px-2')}
              transparent
              inPanelMode
              // isActionable
              inFilterStat
              hasIssue={!_.isNil(itemsTotalByFilterError)}
              // toggleTable
              isCheckLoading={isLoading}
              isSelected={isActiveItemsFilters}
              // onClick={() => {
              //   setSelectedIdentity(
              //     isActive
              //       ? null
              //       : {
              //           ruleId: rule.id,
              //         },
              //   );
              // }}
            />
          </PolicyStatContainer>

          <PolicyFilterGroupList
            filterSets={rule.itemsMatch?.anyOf as any[]}
            config={getProductFilterConfigCommon(t, formatter)}
            selectedId={selectedIdentity?.filterId}
            isDisabled={props.isDisabled}
            onAddGroup={addProductIntersection}
            onDeleteField={removeField}
            onSelectGroup={groupId =>
              selectFilterIntersection(groupId, 'itemsFilter')
            }
            onDeleteGroup={removeIntersection}
          />
        </RuleSectionContainer>
      </PolicyFilter>
      <PolicyFilter
        label={t`Location Limits`}
        isActive={isActiveLocationsFilters}
        isDisabled={props.isDisabled}
      >
        <RuleSectionContainer
          className={cn('pt-1 2xl:pt-2', 'mt-0')}
          isSelected={isActiveLocationsFilters}
          hasIssue={hasIssue}
        >
          {/* <PolicyStatContainer color>
            <Stat
              title={t`Location(s) Filtered`}
              hasNoPadding
              transparent
              className={cn('px-1 py-1 xl:px-2')}
              hasIssue={hasIssue}
              value={0}
              inPanelMode
              inFilterStat
              isSelected={isActiveLocationsFilters}
            />
          </PolicyStatContainer> */}
          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={rule.locationsMatch?.anyOf as any[]}
            config={getLocationFilterConfigCommon(t)}
            selectedId={selectedIdentity?.filterId}
            onAddGroup={addLocationIntersection}
            onDeleteField={removeField}
            onSelectGroup={groupId =>
              selectFilterIntersection(groupId, 'locationFilter')
            }
            onDeleteGroup={removeIntersection}
            showAddFilter={false}
          />
        </RuleSectionContainer>
      </PolicyFilter>
      <PolicyFilter
        label={t`Mode`}
        isActive={isActiveSettingsFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'settings')}
      >
        <PolicyFilterItem
          isActive={isActiveSettingsFilters}
          name={t`De-Allocate Mode`}
          value={
            _.find(
              deallocateOptions,
              o => o.id === (rule.deallocateType ?? DeallocateType.NONE),
            ).title
          }
        />
        <PolicyFilterItem
          isActive={isActiveSettingsFilters}
          name={t`Re-Allocate Mode`}
          value={
            _.find(
              reallocateOptions,
              o => o.id === (rule.reallocateType ?? ReallocateType.NONE),
            ).title
          }
        />

        {rule.reallocateType === ReallocateType.REALLOCATE && (
          <>
            <PolicyFilterItem
              isActive={isActiveSettingsFilters}
              name={t`Re-allocate UOM Type`}
              value={reallocateUOMFilterTitle}
            />
            <PolicyFilterItem
              isActive={isActiveSettingsFilters}
              name={t`Re-allocate UOM quantity`}
              value={reallocateQtyFilterTitle}
            />
          </>
        )}
      </PolicyFilter>

      <PolicyRuleConstraint
        constraintSettings={rule.constraintSettings}
        isActive={isActiveConstraintsFilters}
        isDisabled={props.isDisabled}
        onClick={() => selectFilterIntersection(null, 'constraints')}
      />
    </RuleContainer>
  );
};
export default DeallocatePolicyRule;
