import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useParams, useRoutes } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NoMatch404 } from '../NoMatch404';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { namedColorsGroups } from '../store/namedColors.state';
import {
  warehouseList,
  warehouseListLoadStatus,
  warehouseSelectedId,
} from '../store/warehouse.state';
import useUpdateWarehouseExtraSettings from './hooks/useUpdateWarehouseExtraSettings';

export type WarehouseRootParams = {
  warehouseId: string;
};

export type WarehouseRootProps = {
  routes: RouteObject[];
};

const WarehouseRoot: React.FC<WarehouseRootProps> = props => {
  const { t } = useTranslation('app');
  const { warehouseId } = useParams<WarehouseRootParams>();
  const [whId, setWhId] = useRecoilState(warehouseSelectedId);
  const whList = useRecoilValue(warehouseList);
  const loadStatus = useRecoilValue(warehouseListLoadStatus);
  const routes = useRoutes(props.routes);
  const namedColors = useRecoilValue(namedColorsGroups);
  const [updateFeedSettings] = useUpdateWarehouseExtraSettings();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (_.isNil(whId)) return;
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      updateFeedSettings({
        patch: {
          namedColors: namedColors,
        },
      });
    }
  }, [namedColors]);

  useEffect(() => {
    if (
      loadStatus === AsyncLoadStatus.Ok &&
      !_.isNil(warehouseId) &&
      warehouseId !== whId
    ) {
      setWhId(warehouseId);
    }
  }, [warehouseId, loadStatus]);

  const isWarehouseListLoading =
    loadStatus === AsyncLoadStatus.None ||
    loadStatus === AsyncLoadStatus.Loading;

  const hasError = loadStatus === AsyncLoadStatus.Error;
  const isWarehouseIdValid = _.some(whList, wh => wh.id === warehouseId);

  // If there is no Warehouses  available, redirect warehouse creation page
  if (loadStatus === AsyncLoadStatus.Ok && _.isEmpty(whList)) {
    return <Navigate to={'../new'} replace={true} />;
  }

  if (_.isNil(warehouseId) && !_.isEmpty(whList)) {
    const targetWH = _.find(whList, wh => wh.id === whId) ?? _.head(whList);
    return <Navigate to={targetWH.id} replace={true} />;
  }

  return (
    <>
      {isWarehouseListLoading && (
        <LoadingIndicator selfCenter message={t`Loading Warehouse`} />
      )}

      {hasError && (
        <ErrorIndicator selfCenter message={t`Cannot load warehouse list`} />
      )}

      {!isWarehouseListLoading && !isWarehouseIdValid && (
        <NoMatch404
          title={t`Warehouse Not found`}
          subtitle={t`We couldn't find what you were looking for`}
        />
      )}

      {isWarehouseIdValid && routes}
    </>
  );
};
export default WarehouseRoot;
