import * as React from 'react';
const CursorSelectMoveIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectMoveIcon"
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 93.99 93.99">
        <path
          strokeWidth={0}
          d="M.51,4.38C-.93.81.81-.93,4.38.51l87.02,35.14c3.57,1.44,3.44,3.4-.29,4.36l-33.91,8.68c-3.73.96-7.56,4.79-8.52,8.52l-8.68,33.91c-.96,3.73-2.92,3.86-4.36.29L.51,4.38Z"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#a"
      width={93.99}
      height={93.99}
      transform="translate(10.11 12.11) scale(.75)"
    />
    <path
      strokeWidth={0}
      d="M60.77,87h14.23c2.21,0,4-1.79,4-4s-1.79-4-4-4h-14.23l3.06-3.06c1.56-1.56,1.56-4.09,0-5.66-1.56-1.56-4.09-1.56-5.66,0l-9.4,9.4c-1.07.72-1.77,1.94-1.77,3.32s.71,2.61,1.78,3.32l9.4,9.4c.78.78,1.8,1.17,2.83,1.17s2.05-.39,2.83-1.17c1.56-1.56,1.56-4.09,0-5.66l-3.06-3.06Z"
    />
    <path
      strokeWidth={0}
      d="M90.06,102.17l-3.06,3.06v-14.23c0-2.21-1.79-4-4-4s-4,1.79-4,4v14.23l-3.06-3.06c-1.56-1.56-4.09-1.56-5.66,0-1.56,1.56-1.56,4.09,0,5.66l9.4,9.4c.72,1.07,1.94,1.77,3.32,1.77s2.61-.71,3.32-1.78l9.4-9.4c1.56-1.56,1.56-4.09,0-5.66s-4.09-1.56-5.66,0Z"
    />
    <path
      strokeWidth={0}
      d="M119,83c0-1.39-.71-2.61-1.78-3.32l-9.4-9.4c-1.56-1.56-4.09-1.56-5.66,0-1.56,1.56-1.56,4.09,0,5.66l3.06,3.06h-14.23c-2.21,0-4,1.79-4,4s1.79,4,4,4h14.23l-3.06,3.06c-1.56,1.56-1.56,4.09,0,5.66.78.78,1.8,1.17,2.83,1.17s2.05-.39,2.83-1.17l9.4-9.4c1.07-.72,1.77-1.94,1.77-3.32Z"
    />
    <path
      strokeWidth={0}
      d="M75.94,63.83l3.06-3.06v14.23c0,2.21,1.79,4,4,4s4-1.79,4-4v-14.23l3.06,3.06c.78.78,1.8,1.17,2.83,1.17s2.05-.39,2.83-1.17c1.56-1.56,1.56-4.09,0-5.66l-9.4-9.4c-.72-1.07-1.94-1.77-3.32-1.77s-2.61.71-3.32,1.78l-9.4,9.4c-1.56,1.56-1.56,4.09,0,5.66s4.1,1.56,5.66,0Z"
    />
  </svg>
);
export default CursorSelectMoveIcon;
