import * as React from 'react';
const CursorSelectAreaClickIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectAreaClickIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M54.83,55.22c.41-.43,1.05-.55,1.59-.3l69.75,32.01c.56.26.89.85.81,1.46s-.55,1.1-1.16,1.2l-31.28,5.24-3.58,29.56c-.08.62-.55,1.11-1.16,1.22-.08.01-.16.02-.24.02-.53,0-1.02-.3-1.26-.78L54.58,56.82c-.26-.53-.16-1.18.25-1.6Z"
    />
    <path
      strokeWidth={0}
      d="M61.69,110.42h-.61c-2.71,0-4.91-2.2-4.91-4.91s2.2-4.91,4.91-4.91h.61c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M40.52,110.42h-1.47c-2.71,0-4.91-2.2-4.91-4.91s2.2-4.91,4.91-4.91h1.47c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M18.49,110.42h-.61c-2.71,0-4.91-2.2-4.91-4.91v-.61c0-2.71,2.2-4.91,4.91-4.91,1.51,0,2.86.68,3.77,1.76,1.08.9,1.76,2.25,1.76,3.77,0,2.71-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M17.88,93.46c-2.71,0-4.91-2.2-4.91-4.91v-1.17c0-2.71,2.2-4.91,4.91-4.91s4.91,2.2,4.91,4.91v1.17c0,2.71-2.2,4.91-4.91,4.91ZM17.88,75.95c-2.71,0-4.91-2.2-4.91-4.91v-1.17c0-2.71,2.2-4.91,4.91-4.91s4.91,2.2,4.91,4.91v1.17c0,2.71-2.2,4.91-4.91,4.91ZM17.88,58.43c-2.71,0-4.91-2.2-4.91-4.91v-1.17c0-2.71,2.2-4.91,4.91-4.91s4.91,2.2,4.91,4.91v1.17c0,2.71-2.2,4.91-4.91,4.91ZM17.88,40.92c-2.71,0-4.91-2.2-4.91-4.91v-1.17c0-2.71,2.2-4.91,4.91-4.91s4.91,2.2,4.91,4.91v1.17c0,2.71-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M17.88,23.41c-2.71,0-4.91-2.2-4.91-4.91v-.61c0-2.71,2.2-4.91,4.91-4.91h.61c2.71,0,4.91,2.2,4.91,4.91,0,1.51-.68,2.86-1.76,3.77-.9,1.08-2.25,1.76-3.77,1.76Z"
    />
    <path
      strokeWidth={0}
      d="M88.55,22.79h-1.17c-2.71,0-4.91-2.2-4.91-4.91s2.2-4.91,4.91-4.91h1.17c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91ZM71.04,22.79h-1.17c-2.71,0-4.91-2.2-4.91-4.91s2.2-4.91,4.91-4.91h1.17c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91ZM53.52,22.79h-1.17c-2.71,0-4.91-2.2-4.91-4.91s2.2-4.91,4.91-4.91h1.17c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91ZM36.01,22.79h-1.17c-2.71,0-4.91-2.2-4.91-4.91s2.2-4.91,4.91-4.91h1.17c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M105.51,23.41c-1.51,0-2.87-.68-3.77-1.76-1.07-.9-1.76-2.25-1.76-3.77,0-2.71,2.2-4.91,4.91-4.91h.61c2.71,0,4.91,2.2,4.91,4.91v.61c0,2.71-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M105.51,45.43c-2.71,0-4.91-2.2-4.91-4.91v-1.47c0-2.71,2.2-4.91,4.91-4.91s4.91,2.2,4.91,4.91v1.47c0,2.71-2.2,4.91-4.91,4.91Z"
    />
    <path
      strokeWidth={0}
      d="M105.51,66.61c-2.71,0-4.91-2.2-4.91-4.91v-.61c0-2.71,2.2-4.91,4.91-4.91s4.91,2.2,4.91,4.91v.61c0,2.71-2.2,4.91-4.91,4.91Z"
    />
  </svg>
);
export default CursorSelectAreaClickIcon;
