import * as React from 'react';
const PolicyCompliancePreviewIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyCompliancePreviewIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M98.4,59.33c-.66-.66-15.58-16.32-34.4-16.32s-33.74,15.65-34.4,16.32c-.89.96-.89,2.44,0,3.4.66.66,15.58,16.32,34.4,16.32s33.74-15.65,34.4-16.32c.89-.96.89-2.44,0-3.4ZM64,72.79c-6.5,0-11.79-5.3-11.79-11.79s5.3-11.79,11.79-11.79,11.79,5.3,11.79,11.79-5.3,11.79-11.79,11.79Z"
    />
    <circle cx={64} cy={60.99} r={6.85} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM109.37,70.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z"
    />
  </svg>
);
export default PolicyCompliancePreviewIcon;
