import * as React from 'react';
const PinIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PinIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M111.74,33.82l2.33-2.99c.56-.72,1.43-1.15,2.35-1.15h3.33l-1.8-1.8-8.89-8.89-10.69-10.69v3.33c0,.92-.42,1.78-1.15,2.35l-17.87,13.9-7.64,5.94-21.78,16.94c-.63.49-1.43.71-2.25.6l-27.96-3.99,30.48,30.48,30.48,30.48-3.99-27.96c-.11-.8.1-1.61.6-2.25l34.45-44.3Z"
    />
    <path
      strokeWidth={0}
      d="M48.1,84.15L13.42,118.83c-.58.58-1.34.87-2.1.87s-1.52-.29-2.1-.87c-1.16-1.16-1.16-3.04,0-4.2l34.68-34.68"
    />
  </svg>
);
export default PinIcon;
