import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type SpecItemProps = {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  value?: string | number;
  unitOfMeasure?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const SpecItem = ({
  children,
  className,
  label,
  value,
  unitOfMeasure,
  isActive,
  isDisabled,
  onClick,
}: SpecItemProps) => {
  const [id] = useState<string>(_.uniqueId());
  // const { t } = useTranslation('simulation');""
  if (_.isNil(value) || value === '') return null;
  return (
    <div
      id={id}
      data-component="SpecItem"
      key={`SpecItem-${label}`}
      className={classNames(
        'py-2 text-sm',
        'border-b border-menu/50',
        'flex items-center flex-1',
        'w-full',
        className,
      )}
      onClick={onClick}
    >
      <label className={classNames('text-xs uppercase flex-1')}>{label}</label>
      <div
        className={classNames(
          'ltr:ml-4 rtl:mr-4',
          'px-2 py-1',
          'bg-menu-active text-menu-active-text',
          'rounded',
          'text-xs',
        )}
      >
        <span className="">{value}</span>
        {unitOfMeasure && (
          <span
            className={classNames(
              'ltr:ml-1 rtl:mr-1',
              'text-xxs',
              'opacity-70',
            )}
          >
            {unitOfMeasure}
          </span>
        )}
      </div>
      {children}
    </div>
  );
};
