import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import { sidebarStateByType } from '../../store/sidebar.state';
import {
  importPipelineIsActiveEditor,
  importPipelineSelectedId,
} from '../store/importPipelines.state';
import ImportPipelinePlaceholder from './ImportPipelinePlaceholder';
import ImportPipelineViewPanel from './ImportPipelineViewPanel';

const ImportPipelineMainPanel: React.FC = () => {
  const { t } = useTranslation('dataset');

  const selectedPipelineId = useRecoilValue(importPipelineSelectedId);
  const showEditorSidebar = useRecoilValue(importPipelineIsActiveEditor);
  const setSidebarState = useSetRecoilState(
    sidebarStateByType('sidebar-dataset-pipeline-editor'),
  );

  // const setPipelineEditorSidebarState = useSetRecoilState(
  //   sidebarStateByType('sidebar-dataset-pipeline-editor'),
  // );
  // const setPipelineSidebarMainState = useSetRecoilState(
  //   sidebarStateByType('sidebar-dataset-pipeline'),
  // );

  //   useEffect(() => {
  //   console.log(`Selected Pipeline ID: ${selectedPipelineId}`);
  //   console.log(`Show Editor Sidebar: ${showEditorSidebar}`);

  //   // Adjust visibility based on whether a new pipeline is being created or an existing one is selected
  //   if (_.isNil(selectedPipelineId)) {
  //     console.log('Configuring sidebar for new pipeline creation.');
  //     // For new pipeline creation
  //     setPipelineEditorSidebarState({
  //       isHidden: !showEditorSidebar,
  //       isCollapsed: false,
  //     });
  //     setPipelineSidebarMainState({
  //       isHidden: true, // Hide the main sidebar when creating a new pipeline
  //       isCollapsed: false,
  //     });
  //   } else {
  //     console.log('Configuring sidebar for existing pipeline.');
  //     // For existing pipeline selection
  //     setPipelineEditorSidebarState({
  //       isHidden: !showEditorSidebar,
  //       isCollapsed: false,
  //     });
  //     setPipelineSidebarMainState({
  //       isHidden: false, // Show the main sidebar when an existing pipeline is selected
  //       isCollapsed: false,
  //     });
  //   }
  // }, [
  //   selectedPipelineId,
  //   showEditorSidebar,
  //   setPipelineEditorSidebarState,
  //   setPipelineSidebarMainState,
  //   ]);

  useEffect(() => {
    setSidebarState({
      isHidden: !showEditorSidebar,
      isCollapsed: false,
    });
  }, [showEditorSidebar]);

  return (
    <Container col hasOverflowY>
      {_.isNil(selectedPipelineId) && <ImportPipelinePlaceholder />}
      {!_.isNil(selectedPipelineId) && <ImportPipelineViewPanel />}
    </Container>
  );
};

export default ImportPipelineMainPanel;
