import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import * as Icon from '../../../components/icons';
import { Stat } from '../../../components/stats/Stat';
import {
  DataIssueReason,
  IssuePanelConfig,
} from '../../store/simulation.issues.types';

export type IssuesSectionProps = {
  title: string;
  subTitle?: string;
  panels: IssuePanelConfig[];
  selected: DataIssueReason;
  onSelect: (value: DataIssueReason) => void;
};

const IssuesSection: React.FC<IssuesSectionProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const sortedPanels = [...props.panels].sort((a, b) => {
    // Calculate the share percentage for panel 'a' and 'b'
    const shareA = a.total > 0 ? a.value / a.total : null;
    const shareB = b.total > 0 ? b.value / b.total : null;

    // If both shares are valid, sort by share percentage in descending order
    if (shareA !== null && shareB !== null) {
      return shareB - shareA;
    }

    // If only 'a' has a valid share, it should come first
    if (shareA !== null) {
      return -1;
    }

    // If only 'b' has a valid share, it should come first
    if (shareB !== null) {
      return 1;
    }

    // If neither have valid shares, sort by value in descending order
    return b.value - a.value;
  });

  return (
    <>
      {sortedPanels.map(config => {
        const share = 100 * (config.value / config.total);
        const sharePercent =
          config.total > 0
            ? formatter.formatShare(config.value / config.total)
            : null;

        return (
          <Stat
            key={config.id}
            isSectionable={config.value !== 0}
            toggleTable={config.tableView}
            isActionable={config.selectable && config.value !== 0}
            isPreview
            title={config.title}
            className={classNames(
              config.value === 0
                ? 'saturate-110 opacity-75 backdrop-blur backdrop-filter'
                : 'opacity-90',
            )}
            icon={!config.value && Icon.CircleOk}
            classTitle={classNames('ltr:pr-6 rtl:pl-6')}
            value={!config.value ? t`No Issues` : formatInteger(config.value)}
            valueTotal={
              config.value > 0 && config.total > 0
                ? formatInteger(config.total)
                : null
            }
            valuePercent={sharePercent}
            isSelectable={config.selectable}
            isSelected={config.id === props.selected}
            hasIssue={share > config.threshold}
            threshold={config.threshold}
            onClick={() => {
              props.onSelect(config.id);
            }}
          />
        );
      })}
    </>
  );
};

export default IssuesSection;
