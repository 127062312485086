import * as React from 'react';
const DashboardHomeFoodIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DashboardHomeFoodIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M19.66,61.02c-1.83.97-3.25,2.63-4.17,4.45-.97,1.83-1.43,3.83-1.48,5.82-.06,1.94.17,3.94.86,5.77.23-1.94.4-3.83.8-5.6.4-1.83.91-3.54,1.71-5.08.8-1.6,1.83-2.97,3.2-4.05,1.37-1.09,3.08-1.88,4.91-2.4-1.94-.29-4,.11-5.83,1.09Z"
    />
    <path
      strokeWidth={0}
      d="M64.5,24.5L.84,40.93v62.58h126.32v-62.58l-62.66-16.43ZM43.53,93c-3.14,3.37-6.91,2.57-10.17,1.37-2.11-.74-3.37-.74-5.54.06-1.6.57-3.26,1.14-4.91,1.14-1.48,0-2.97-.46-4.28-1.77-7.54-7.54-11.08-17.82-9.25-26.84,1.26-6.17,7.6-11.82,13.88-12.28,1.37-.11,2.74.06,4.17.23l1.37.17c.11-2.4-.63-4.57-1.48-6.57-.06-.17-.17-.34-.29-.51-.23-.34-.46-.69-.51-1.14-.17-.86.97-1.43,1.31-1.6.91-.4,1.37-.23,1.6.06.4.46.8,1.48,1.26,2.57.11.23.23.51.34.8,1.94-2.51,4.51-4.8,8.62-4.57,2,.11,3.2.29,3.65,1.14.46.86.11,1.94-.8,3.6-.51.97-2.74,4.05-10.22,4.11.06.4.11.74.11,1.14,0,.34.06.63.06.91.34,0,.57-.06.8-.11,3.37-1.14,7.42-.46,11.08,1.83,4.28,2.63,7.2,6.85,7.82,11.25,1.14,8.17-2.23,17.99-8.62,25.01Z"
    />
  </svg>
);
export default DashboardHomeFoodIcon;
