import classNames from 'classnames';
import React from 'react';

export type WarehouseNavigatorProps = {
  children?: React.ReactNode;
  className?: string;
  sticky?: boolean;
};

export const WarehouseNavigator = ({
  children,
  className,
  sticky,
}: WarehouseNavigatorProps) => (
  <section
    data-component="WarehouseNavigator"
    className={classNames(
      'flex w-full',
      'divide-menu-700 divide-x',
      // 'space-x-px space-y-px',
      { 'sticky top-0': sticky },
      className,
    )}
  >
    {children}
  </section>
);
