import {
  AgentRole,
  FindLocationsByFilterDocument,
  FindLocationsByFilterQuery,
  FindLocationsByFilterQueryVariables,
  LayoutLocationFilter,
  PickingPolicyFragment,
  PickingPolicyInput,
  PickingPolicyLocationSelectionMode,
  PickingPolicyOrderLineRuleFragment,
  PickingPolicyOrderLineRuleInput,
  PickingPolicyPickingRuleFragment,
  PickingPolicyPickingRuleInput,
  PickingRequirementTerminalLocationSource,
  PickingRequirementsBatchSettings,
  PickingRequirementsGroupBy,
  PickingWavePickByDateMode,
  PickingWaveSettingsFragment,
  PicklistLinesOrderOptions,
  SimulationMetaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { secureClient } from '../../../GraphQLClient';
import { FilterValueBase } from '../../../common/filters';
import { AsyncLoadStatus } from '../../../common/types';
import { cloneWithoutTypename } from '../../../common/utils';
import * as Icon from '../../../components/icons';
import { AgentLabourEvent } from '../../../resourcePolicy/agentData/agent.types';
import { PolicyFilterData } from '../policyFilter.types';
import { PickingConfigurationFilterType } from './pickingPolicy.default';
import { PickingSelectionIdentity } from './pickingPolicy.types';

export const wareSettingIds = [
  'route',
  'dock',
  'areaStaging',
  'waveIdentity',
] as const;

export type WaveSettingId = (typeof wareSettingIds)[number];

export type PickingConfigurationFilterValuesParams = {
  field: PickingConfigurationFilterType;
  searchFilter: string;
  simulation: SimulationMetaFragment;
};

export function getPickingConfigurationFilterValues(
  params: PickingConfigurationFilterValuesParams,
): PolicyFilterData {
  let content: any[] = [];
  switch (params.field) {
    case PickingConfigurationFilterType.UOM:
      content = _.map(params.simulation.itemSet.uomTypes, ut => ({
        id: ut.uom,
        title: ut.uom,
      }));
      break;
    case PickingConfigurationFilterType.AGENT:
      content = _(params.simulation.resourcePolicy?.agents)
        .filter(a => _.some(a.roles, r => r.roleId === AgentRole.PICKING))
        .map(a => ({
          id: a.id,
          title: a.title,
        }))
        .value();
      break;
    case PickingConfigurationFilterType.GROUP_BY_ORDERS:
      content = [
        {
          id: PickingRequirementsGroupBy.ORDER,
          title: 'Order',
        },
        {
          id: PickingRequirementsGroupBy.ITEM,
          title: 'Item',
        },
      ];
  }

  return {
    content: _.filter(
      content,
      c =>
        _.isEmpty(params.searchFilter) ||
        _.indexOf(c.title, params.searchFilter) > -1,
    ),
    totalCount: content.length,
    status: AsyncLoadStatus.Ok,
  };
}

export function getPickingConfigurationValue({
  field,
  rule,
  selectedIdentity,
}: {
  field: PickingConfigurationFilterType;
  rule: PickingPolicyOrderLineRuleFragment;
  selectedIdentity: PickingSelectionIdentity;
}): FilterValueBase<PickingConfigurationFilterType, any> {
  const pickingRule = _.find(
    rule.pickingRules,
    pickRule => pickRule.id === selectedIdentity?.pickingRuleId,
  );
  switch (field) {
    case PickingConfigurationFilterType.GROUP_BY_ORDERS:
      return {
        type: field,
        valueIn: [
          {
            id: rule.pickingRequirementsSettings.groupBy,
          },
        ],
      };
    case PickingConfigurationFilterType.UOM:
      return {
        type: field,
        valueIn: _.map(pickingRule?.uomTypes, uom => ({
          id: uom,
        })),
      };
    case PickingConfigurationFilterType.AGENT:
      return {
        type: field,
        valueIn: [
          {
            id: pickingRule?.agentId,
          },
        ],
      };
  }
  return null;
}

export function applyPickingConfigurationValue({
  field,
  rule,
  selectedIdentity,
  value,
}: {
  field: PickingConfigurationFilterType;
  rule: PickingPolicyOrderLineRuleFragment;
  selectedIdentity: PickingSelectionIdentity;
  value: FilterValueBase<PickingConfigurationFilterType, any>;
}): PickingPolicyOrderLineRuleFragment {
  const firstValueId = _.head(value?.valueIn)?.id as any;
  return {
    ...rule,
    pickingRequirementsSettings:
      field === PickingConfigurationFilterType.GROUP_BY_ORDERS
        ? {
            ...rule.pickingRequirementsSettings,
            groupBy: firstValueId,
          }
        : rule.pickingRequirementsSettings,
    pickingRules: _.map(rule?.pickingRules, pr => {
      if (pr.id !== selectedIdentity?.pickingRuleId) return pr;
      return {
        ...pr,
        agentId:
          field === PickingConfigurationFilterType.AGENT
            ? firstValueId
            : pr.agentId,
        uomTypes:
          field === PickingConfigurationFilterType.UOM
            ? _.map(value.valueIn, v => v.id)
            : pr.uomTypes,
      };
    }),
  };
}

export function getPickingPolicyPickingRuleInput(
  rule: PickingPolicyPickingRuleFragment,
): PickingPolicyPickingRuleInput {
  if (!rule) return null;
  const locationsMatch = _.filter(
    rule.locationsMatch?.anyOf,
    intersection => intersection?.allOf?.length > 0,
  );
  return {
    ...cloneWithoutTypename(rule),
    locationsMatch: {
      anyOf: cloneWithoutTypename(locationsMatch),
    },
  };
}

export function getPickingPolicyOrderLineRuleInput(
  rule: PickingPolicyOrderLineRuleFragment,
): PickingPolicyOrderLineRuleInput {
  if (!rule) return null;
  const orderLineMatchAnyOf = rule.orderLinesMatch.anyOf.filter(
    intersection => intersection.allOf.length > 0,
  );
  return {
    id: rule.id,
    title: rule.title,
    pickingRequirementsSettings: cloneWithoutTypename(
      rule.pickingRequirementsSettings,
    ),
    picklistSettings: cloneWithoutTypename(rule.picklistSettings),
    orderLinesMatch: {
      anyOf: cloneWithoutTypename(orderLineMatchAnyOf),
    },
    pickingRules: _.map(rule.pickingRules, getPickingPolicyPickingRuleInput),
    usePickingContainer: rule.usePickingContainer,
  };
}

export function getPickingPolicyInput(
  policy: PickingPolicyFragment,
): PickingPolicyInput {
  return {
    orderLineRules: _.map(
      policy?.orderLineRules,
      getPickingPolicyOrderLineRuleInput,
    ),
  };
}

export async function loadLocationsByRule(params: {
  layoutId: string;
  pickingRule: PickingPolicyPickingRuleFragment;
  filter: LayoutLocationFilter;
}): Promise<Set<string>> {
  const locationsMatch = _.filter(
    params.pickingRule?.locationsMatch?.anyOf,
    intersection => intersection.allOf.length > 0,
  );

  const response = await secureClient.query<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  >({
    query: FindLocationsByFilterDocument,
    variables: {
      page: {
        limit: null,
      },
      filter: params.filter,
      layoutId: params.layoutId,
      input: {
        includeMatching: { anyOf: cloneWithoutTypename(locationsMatch) },
      },
    },
  });

  const ids = _.map(
    response.data?.layout?.locationsByFilter.content,
    l => l.locationId,
  );
  return new Set(ids);
}

export function getLocationSelectionModeOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: PickingPolicyLocationSelectionMode.ROUND_ROBIN,
      title: t(`Random Picking (Round-Robin)`, { ns: 'simulation' }),
      type: 'ROUND_ROBIN',
    },
    {
      id: PickingPolicyLocationSelectionMode.LOCATION_PRIORITY,
      title: t(`Location Priority (Sort Order)`, { ns: 'simulation' }),
      type: 'LOCATION_PRIORITY',
    },
  ];
}

export function getPolicyGroupOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: null,
      title: t(`Any with any (No Group)`, { ns: 'simulation' }),
    },
    {
      id: 'group1',
      title: t(`Default Group 1`, { ns: 'simulation' }),
    },
    {
      id: 'group2',
      title: t(`Default Group 2`, { ns: 'simulation' }),
    },
    {
      id: 'group3',
      title: t(`Default Group 3`, { ns: 'simulation' }),
    },
  ];
}

export function getLabourEventTitle(
  eventType: AgentLabourEvent,
  t: TFunction<'simulation'>,
) {
  switch (eventType) {
    case 'breaks':
      return t(`Breaks`, { ns: 'simulation' });
    case 'meetings':
      return t(`Meetings`, { ns: 'simulation' });
    case 'training':
      return t(`Training`, { ns: 'simulation' });
    case 'recharge':
      return t(`Re-charge Battery / Refuel`, { ns: 'simulation' });
    case 'maintenance':
      return t(`Maintenance, Cleanup`, { ns: 'simulation' });
    case 'misc':
      return t(`Miscellaneous`, { ns: 'simulation' });
  }
}

export function getPolicyGroupMap(
  t: TFunction<'simulation'>,
): Record<string, string> {
  return _.reduce(
    getPolicyGroupOptions(t),
    (acc, v) => ({ ...acc, [v.id]: v.title }),
    {},
  );
}

export type WaveDescriptor = {
  id: WaveSettingId;
  title: string;
  description?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export function getWaveDescriptors(
  t: TFunction<'simulation'>,
): WaveDescriptor[] {
  return [
    {
      id: 'route',
      title: t('Route', { ns: 'simulation' }),
      description: t('Transport/Delivery Route', { ns: 'simulation' }),
      icon: Icon.PickingWaveRoute,
    },
    {
      id: 'dock',
      title: t('Dock', { ns: 'simulation' }),
      description: t('Dock ID', { ns: 'simulation' }),
      icon: Icon.PickingWaveDockTime,
    },
    {
      id: 'areaStaging',
      title: t('Area', { ns: 'simulation' }),
      description: t('Staging Area', { ns: 'simulation' }),
      icon: Icon.PickingWaveAreaHistory,
    },
    {
      id: 'waveIdentity',
      title: t('Wave ID', { ns: 'simulation' }),
      description: t('', { ns: 'simulation' }),
      icon: Icon.PickingWaveHistory,
    },
  ];
}

export function getIsWaveSettingSelected(
  settingId: WaveSettingId,
  wave: PickingWaveSettingsFragment,
): boolean {
  return (
    (settingId === 'areaStaging' && wave.useStagingArea) ||
    (settingId === 'dock' && wave.useDock) ||
    (settingId === 'route' && wave.useDeliveryRoute) ||
    (settingId === 'waveIdentity' && wave.useWaveId)
  );
}

export type WaveByDateDescriptor = {
  id: PickingWavePickByDateMode;
  title: string;
};

export function getWaveByDateDescriptors(
  t: TFunction<'simulation'>,
): WaveByDateDescriptor[] {
  return [
    {
      id: PickingWavePickByDateMode.DATE,
      title: t('Date only', { ns: 'simulation' }),
    },
    {
      id: PickingWavePickByDateMode.DATETIME,
      title: t('Date & time', { ns: 'simulation' }),
    },
    {
      id: PickingWavePickByDateMode.FIXED_FRAMES,
      title: t('Scheduler', { ns: 'simulation' }),
    },
  ];
}

export function getPicklistLinesOrderOptionTitle(
  sortType: PicklistLinesOrderOptions,
  t: TFunction<'simulation'>,
): string {
  switch (sortType) {
    case PicklistLinesOrderOptions.CONSIGNEE:
      return t(`Consignee`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.LOCATION_ID:
      return t(`Location`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.LOCATION_LEVEL:
      return t(`Level`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.LOCATION_ORDER:
      return t(`Location Order`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.POLLUTION_CLASS:
      return t(`Pollution Class`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.SKU:
      return t(`Item (SKU)`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.SKU_GROUP:
      return t(`Item Group`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.STOCK_CATEGORY:
      return t(`Stock Category`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.STORAGE_CLASS:
      return t(`Storage Class`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.SUB_GROUP:
      return t(`Item Sub-Group`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.TRANSPORT_CLASS:
      return t(`Transport Class`, { ns: 'simulation' });
    case PicklistLinesOrderOptions.STACKING_PRIORITY:
      return t(`Stacking Priority`, { ns: 'simulation' });
  }
}

export function getPickingRequirementsGroupByOptions(
  t: TFunction<'simulation'>,
): { id: PickingRequirementsGroupBy; title: string }[] {
  return [
    {
      id: PickingRequirementsGroupBy.ORDER,
      title: t('Order', { ns: 'simulation' }),
    },
    {
      id: PickingRequirementsGroupBy.ITEM,
      title: t('Item', { ns: 'simulation' }),
    },
    {
      id: PickingRequirementsGroupBy.CUSTOMER,
      title: t('Customer', { ns: 'simulation' }),
    },
    {
      id: PickingRequirementsGroupBy.BATCH,
      title: t('Batch', { ns: 'simulation' }),
    },
  ];
}

export function getPickingRequirementsBatchSettingsOptions(
  t: TFunction<'simulation'>,
): { id: keyof PickingRequirementsBatchSettings; title: string }[] {
  return [
    {
      id: 'maxOrderCount',
      title: t('Max Order Count', { ns: 'simulation' }),
    },
    {
      id: 'maxOrderLineCount',
      title: t('Max Order Line Count', { ns: 'simulation' }),
    },
  ];
}

export function getPickingRequirementsWaypointSettingsOptions(
  t: TFunction<'simulation'>,
): { id: PickingRequirementTerminalLocationSource; title: string }[] {
  return [
    {
      id: null,
      title: t('Default', { ns: 'simulation' }),
    },
    {
      id: PickingRequirementTerminalLocationSource.DELIVERY_ROUTE,
      title: t('Delivery route', { ns: 'simulation' }),
    },
    {
      id: PickingRequirementTerminalLocationSource.DOCK,
      title: t('Dock', { ns: 'simulation' }),
    },
    {
      id: PickingRequirementTerminalLocationSource.STAGING_AREA,
      title: t('Staging area', { ns: 'simulation' }),
    },

    {
      id: PickingRequirementTerminalLocationSource.WAVE_ID,
      title: t('Wave', { ns: 'simulation' }),
    },
  ];
}
