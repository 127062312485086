import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import {
  updateAllPolicyFilterValues,
  updatePolicyFilterValues,
} from '../../../policyFilters/policyFilter.helper';
import useLoadItemFilterValues from '../../../policyFilters/useLoadItemFilterValues';
import useLoadLocationFilterValues from '../../../policyFilters/useLoadLocationFilterValues';
import {
  deallocationPolicyRule,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../../store/assignmentPolicy.default';
import DeallocatePolicySettingsEditor from './deallocationPolicyEditors/DeallocatePolicySettingsEditor';
import DeallocationPolicyConstraintsEditor from './deallocationPolicyEditors/DeallocationPolicyConstraintsEditor';
import DeallocationPolicyLocationFilterEditor from './deallocationPolicyEditors/DeallocationPolicyLocationFilterEditor';
import DeallocationPolicyProductFilterEditor from './deallocationPolicyEditors/DeallocationPolicyProductFilterEditor';

const DeallocatePolicyEditorPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const selectedIdentity = useRecoilValue(deallocationPolicySelectedIdentity);
  const rule = useRecoilValue(deallocationPolicyRule(selectedIdentity?.ruleId));
  const [loadItemValues, cancelLoadItemValues] = useLoadItemFilterValues();
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();
  const showProductFilters = !_.isNil(selectedIdentity?.ruleId);
  const productFiltersConfig = getProductFilterConfigCommon(t, formatter);
  const locationsFilterConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (
      selectedIdentity?.filterId &&
      selectedIdentity?.selectionType === 'itemsFilter'
    ) {
      updatePolicyFilterValues({
        filterConfig: productFiltersConfig,
        selectedFilterId: selectedIdentity.filterId,
        policyFilterUnion: rule.itemsMatch,
        load: loadItemValues,
        cancel: cancelLoadItemValues,
      });
    }

    if (
      selectedIdentity?.filterId &&
      selectedIdentity?.selectionType === 'locationFilter'
    ) {
      updateAllPolicyFilterValues({
        filterConfig: locationsFilterConfig,
        selectedFilterId: selectedIdentity.filterId,
        policyFilterUnion: rule.locationsMatch,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }
  }, [selectedIdentity]);

  const selectedType = selectedIdentity?.selectionType;

  if (!showProductFilters) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }
  const subtitle = t`Re-Allocation Policy`;
  return (
    <Container col hasOverflowY>
      <ScreenTitle subtitle={subtitle} title={rule?.title ?? ''} isSticky />
      {selectedType === 'settings' && <DeallocatePolicySettingsEditor />}
      {selectedType === 'constraints' && (
        <DeallocationPolicyConstraintsEditor />
      )}

      {selectedType === 'itemsFilter' &&
        productFiltersConfig.map(configItem => (
          <DeallocationPolicyProductFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadItemValues}
            cancelLoadData={cancelLoadItemValues}
          />
        ))}

      {selectedType === 'locationFilter' &&
        locationsFilterConfig.map(configItem => (
          <DeallocationPolicyLocationFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
    </Container>
  );
};

export default DeallocatePolicyEditorPanel;
