import { AreaConfiguration } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonToolbar from '../../../../components/actions/ButtonToolbar';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  converterAreaConfiguration,
  converterSelectedArea,
} from '../store/converter.area.state';

const ToolbarAreaOrientation: React.FC = () => {
  const { t } = useTranslation('designer');
  const area = useRecoilValue(converterSelectedArea);
  const [areasConfigurations, setAreaConfiguration] = useRecoilState(
    converterAreaConfiguration,
  );

  function updateConfig(areaConfig: AreaConfiguration) {
    setAreaConfiguration({
      ...areasConfigurations,
      [area.id]: areaConfig,
    });
  }
  if (_.isNil(area)) return null;

  const config = areasConfigurations[area.id];
  const minZIndex = _(areasConfigurations)
    .map(a => a.zIndex ?? 0)
    .min();
  return (
    <>
      <TitleSection title={t`Area Axis (Reflect/Flip/Mirroring)`} inPanelView />
      <InputGroupList hasPadding panelMode className="space-y-2">
        <ButtonToolbar
          title={t`Reflect Area - Horizontal`}
          isSelected={config.flipY}
          onChange={value => {
            updateConfig({ ...config, flipY: value });
          }}
        >
          <Icon.FlipArea className="h-8 w-8" />
        </ButtonToolbar>
        <ButtonToolbar
          title={t`Reflect Area - Vertical`}
          isSelected={config.flipX}
          onChange={value => {
            updateConfig({ ...config, flipX: value });
          }}
        >
          <Icon.FlipArea className="h-8 w-8 rotate-90 transform" />
        </ButtonToolbar>
        <ButtonToolbar
          title={t`Send to back`}
          onChange={value => {
            updateConfig({ ...config, zIndex: minZIndex - 1 });
          }}
        >
          <Icon.ArrowBottom className="h-8 w-8" />
        </ButtonToolbar>
      </InputGroupList>
    </>
  );
};

export default ToolbarAreaOrientation;
