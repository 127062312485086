import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../components/alerts/Alerts';

export enum InformationAlertMessageType {
  analyze,
  optimise,
}

type LayoutInformationAlertProps = {
  messageType: InformationAlertMessageType;
};

const LayoutInformationAlert: React.FC<LayoutInformationAlertProps> = props => {
  const { t } = useTranslation('app');

  const loadingMessages: Record<InformationAlertMessageType, string> = {
    [InformationAlertMessageType.analyze]: t`Analysing...`,
    [InformationAlertMessageType.optimise]: t`Optimising...`,
  };
  return (
    <Alert
      hasStatusLoading
      hasStatusInfo={true}
      message={loadingMessages[props.messageType]}
    />
  );
};

export default LayoutInformationAlert;
