import {
  AssignmentSubsetSpec,
  UnassignedLocationsMatch,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../components/layout/TitleSection';
import { PolicyCardItem } from '../components/policies/PolicyCardItem';
import { getSwapModeOptions } from './swapPolicy.helper';

import { swapPolicyRule, swapPolicySelectedIdentity } from './swapPolicy.state';

const SwapPolicyModeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(swapPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    swapPolicyRule(selectedIdentity?.ruleId),
  );
  if (selectedIdentity?.type !== 'mode' || !rule) return null;

  const group = selectedIdentity.group;
  const assignmentsMatch = group === 'source' ? rule.src : rule.dest;
  const selected =
    assignmentsMatch?.unassignedLocationsMatch ??
    UnassignedLocationsMatch.EXCLUDE;

  function onChangeMode(mode: UnassignedLocationsMatch) {
    const newAssignmentsMatch: AssignmentSubsetSpec = {
      ...(assignmentsMatch ?? {}),
      unassignedLocationsMatch: mode,
    };
    updateRule({
      ...rule,
      src: group === 'source' ? newAssignmentsMatch : rule.src,
      dest: group === 'destination' ? newAssignmentsMatch : rule.dest,
    });
  }

  const options = getSwapModeOptions(t);
  return (
    <div data-component="SwapPolicyModeEditor">
      <TitleSection
        id={`policy-swap-mode-section`}
        title={t`Location usage mode`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {_.map(options, (mode, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-group-${mode.id}`}
                    isActive={mode.id === selected}
                    onClick={() => onChangeMode(mode.id)}
                    icon={mode.icon}
                    iconClassName={`w-12 h-12 ltr:mr-2 rtl:ml-2`}
                    title={mode.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default SwapPolicyModeEditor;
