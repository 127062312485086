import _ from 'lodash';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type FooterLegalProps = {
  children?: React.ReactNode;
  className?: string;
};

export const FooterLegal = ({ children, className }: FooterLegalProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app-public');

  return (
    <footer id={id} className={`text-sm text-menu-active ${className || ''}`}>
      <Trans t={t}>
        This site is protected by reCAPTCHA and the
        <a className="mx-1 font-bold underline" href="/legal/privacy">
          Privacy Policy
        </a>
        and
        <a className="mx-1 font-bold underline" href="/legal/terms">
          Terms of Service
        </a>
        apply.
      </Trans>
      {children}
    </footer>
  );
};
