import * as React from 'react';
const ObjectHeightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectHeightIcon"
    {...props}
  >
    <rect width={53.21} height={6.57} x={37.39} y={120.43} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="45.79 102.66 64 120.82 82.21 102.62 82.21 91.86 67.82 106.25 67.81 71.07 60.19 71.08 60.19 106.25 45.79 91.89 45.79 102.66"
    />
    <rect width={53.21} height={6.57} x={37.39} y={1} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="82.21 25.34 64 7.18 45.79 25.38 45.79 36.14 60.18 21.75 60.19 56.93 67.81 56.92 67.81 21.75 82.21 36.11 82.21 25.34"
    />
  </svg>
);
export default ObjectHeightIcon;
