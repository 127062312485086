import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Callout from '../../helpContext/Callout';
import { warehouseIsDemo } from '../../store/warehouse.state';
import { MailTo } from '../actions/MailTo';
import * as Icon from '../icons';

export type DemoHelperProps = {
  children?: React.ReactNode;
  className?: string;
};

export const DemoHelper = ({ children, className }: DemoHelperProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);

  return (
    <div id={id} className={classNames('flex-1 p-4', !isDemo && 'hidden')}>
      <Callout type="suggestion">
        <div>{t`You are currently using the demo warehouse.`}</div>

        <div>
          <span>{t`Have a question or want to use your data?`}</span>
          {` `}
          <MailTo
            email="demo@warebee.com"
            subject="[WAREBEE] Demo Request"
            body={`Hello,\n `}
          >
            <span>{t`Use chat support or drop us a message at`}</span>
            <span
              className={classNames('ltr:ml-1 rtl:mr-1')}
            >{t`demo@warebee.com`}</span>
          </MailTo>
        </div>

        {children}
      </Callout>
    </div>
  );
};

export const DemoHelperReadonlyWarning = ({
  children,
  className,
}: DemoHelperProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <div
      id={id}
      className={classNames(
        'flex items-center',
        'p-2',
        'mt-4 mb-4',
        'rounded',
        'bg-menu-active text-menu-active-text',
      )}
    >
      <Icon.CircleHelp
        className={classNames('fill-current', 'w-6 h-6', 'ltr:mr-2 rtl:ml-2')}
      />
      <div className={classNames('flex-1')}>
        <span
          className={classNames('ltr:mr-2 rtl:ml-2', 'text-sm')}
        >{t`NOTE:`}</span>
        {t`All configurations and policies are read-only, in a Demo warehouse`}
      </div>
    </div>
  );
};
