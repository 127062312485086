import * as React from 'react';
const OptimisationMlIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="OptimisationMlIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="40.91 117.62 24.63 108.38 8.35 117.62 24.63 126.92 40.91 117.62"
    />
    <polygon
      strokeWidth={0}
      points="40.91 82.19 24.63 72.94 8.35 82.19 24.63 91.49 40.91 82.19"
      opacity={0.6}
    />
    <polygon
      strokeWidth={0}
      points="8.35 11.31 24.63 2.07 40.91 11.31 24.63 20.61 8.35 11.31"
      opacity={0.6}
    />
    <polygon
      strokeWidth={0}
      points="8.35 46.75 24.63 37.5 40.91 46.75 24.63 56.05 8.35 46.75"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="69.18 99.9 52.9 90.66 36.62 99.9 52.9 109.21 69.18 99.9"
    />
    <polygon
      strokeWidth={0}
      points="36.62 29.03 52.9 19.79 69.18 29.03 52.9 38.33 36.62 29.03"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="36.62 64.47 52.9 55.22 69.18 64.47 52.9 73.77 36.62 64.47"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="64.89 46.75 81.17 37.5 97.45 46.75 81.17 56.05 64.89 46.75"
      opacity={0.6}
    />
    <polygon
      strokeWidth={0}
      points="64.89 82.19 81.17 72.94 97.45 82.19 81.17 91.49 64.89 82.19"
      opacity={0.6}
    />
    <polygon
      strokeWidth={0}
      points="93.16 64.47 109.44 55.22 125.72 64.47 109.44 73.77 93.16 64.47"
      opacity={0.9}
    />
    <path
      strokeWidth={0}
      d="M23.43,118.9c-.32,0-.64-.12-.88-.36-.41-.4-.49-1.04-.19-1.53l31.47-52.52L23.55,11.97c-.29-.5-.2-1.13.22-1.52.41-.4,1.04-.46,1.53-.16l84.8,53.15c.37.23.59.63.59,1.06s-.23.83-.59,1.06L24.08,118.71c-.2.13-.43.19-.66.19ZM28.22,15.07l28.13,48.8c.23.39.22.88-.01,1.27l-29.21,48.75,79.93-49.4L28.22,15.07Z"
    />
    <path
      strokeWidth={0}
      d="M81.17,80.85c-.22,0-.43-.06-.63-.17l-25.89-15.1-30.64-17.72c-.38-.22-.62-.63-.62-1.07,0-.44.22-.85.6-1.08l28.87-17.72c.29-.18.64-.23.98-.14.33.09.61.31.78.61l27.67,50.54c.27.49.18,1.1-.22,1.5-.24.23-.55.35-.87.35ZM27.07,46.75l28.83,16.67,22.01,12.84-24.88-45.44-25.96,15.93Z"
    />
    <path
      strokeWidth={0}
      d="M55.27,101.18c-.22,0-.43-.06-.63-.17l-30.64-17.72c-.38-.22-.62-.62-.62-1.06,0-.44.21-.85.59-1.08l56.54-35.44c.47-.3,1.08-.24,1.5.13.41.37.53.98.29,1.48l-25.89,53.15c-.15.31-.43.55-.76.65-.12.04-.24.05-.37.05ZM27.05,82.17l27.68,16.01,23.39-48.02-51.08,32.02Z"
    />
  </svg>
);
export default OptimisationMlIcon;
