import React from 'react';
import { useRecoilValue } from 'recoil';
import AisleLayer from '../../layout/viewer/layers/AisleLayer';
import { simulationRoutingDisplayMode } from '../store/simulation.state';

const SimulationAisleLayer: React.FC = () => {
  const routingMode = useRecoilValue(simulationRoutingDisplayMode);

  return <AisleLayer hideLabels={routingMode !== 'none'} />;
};

export default SimulationAisleLayer;
