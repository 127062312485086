import {
  Bay,
  BayTypeModel,
  DEFAULT_BAY_TYPE,
  DEFAULT_LOCATION_RACKING_TYPE,
  Location,
  LocationTypeModel,
} from '@warebee/shared/engine-model';
import { chain, max, min, range, uniq } from 'lodash';
import { LocationPatch, LocationPatchHolder } from './converter-settings.model';
import { LayoutImportLocation } from './layout-import-location.model';

export function getBayId(
  l: Pick<
    LayoutImportLocation,
    'warehouseArea' | 'locationAisle' | 'baySide' | 'locationBay' | 'locationId'
  >,
) {
  return `${l.warehouseArea}-${l.locationAisle}-${l.baySide}-${l.locationBay}`;
}

export function getLocationPatchKey(patchHolder: LocationPatchHolder) {
  return (
    patchHolder.patch.locationKey ??
    `${patchHolder.bayId}-${patchHolder.patch.locationId}`
  );
}

export function getLocationKey(
  l: Pick<
    LayoutImportLocation,
    'warehouseArea' | 'locationAisle' | 'baySide' | 'locationBay' | 'locationId'
  >,
) {
  return `${getBayId(l)}-${l.locationId}`;
}

export function populateLocationKeys<
  L extends Pick<
    LayoutImportLocation,
    'warehouseArea' | 'locationAisle' | 'baySide' | 'locationBay' | 'locationId'
  >,
>(locs: L[]): (L & { locationKey: string })[] {
  return locs.map(loc => ({ ...loc, locationKey: getLocationKey(loc) }));
}

/**
 * Apply patch to location, if any.
 * @param location
 * @param patch
 * @returns
 */
export function applyLocationPatch<L extends LayoutImportLocation>(
  location: L,
  patch: LocationPatch | null,
): L {
  if (patch) {
    const l = {
      ...location,
      ...patch,
    };
    l.locationBayId = getBayId(l);
    return l;
  } else {
    return location;
  }
}

export type LocationHeightSource = Pick<
  LayoutImportLocation,
  'locationLevel' | 'locationHeight' | 'locationRackingType'
>;
export type HasHeightFromFloor = Pick<Location, 'locationHeightFromFloor'>;

/**
 *
 * @param bay Calculate level heights and assign `locationHeightFromFloor` to locations.
 * @param locations
 * @param bayModels
 * @param locationModels
 * @returns
 */
export function calculateBayLevelHeights<L extends LocationHeightSource>(
  bay: Bay,
  locations: L[],
  bayModels: Record<string, BayTypeModel>,
  locationModels: Record<string, LocationTypeModel>,
): (L & HasHeightFromFloor)[] {
  if (!locations) {
    return [];
  }

  const { hasPass } = bay;

  const bayModel =
    bayModels?.[bay.bayType] || bayModels?.[DEFAULT_BAY_TYPE] || {};

  const shelfHeight = bayModel?.shelfHeight || 0;

  const levelHeights: Record<number, number> = chain(locations)
    .groupBy(l => l.locationLevel)
    .reduce((acc, levelLocs, level) => {
      return {
        ...acc,
        [level]: Math.max(
          ...levelLocs.map(l => {
            const gapHeight =
              (
                locationModels?.[l.locationRackingType] ||
                locationModels?.[DEFAULT_LOCATION_RACKING_TYPE] ||
                {}
              )?.gapHeight || 0;
            return l.locationHeight + gapHeight;
          }),
        ),
      };
    }, {})
    .value();
  const allLevels = uniq(locations.map(loc => loc.locationLevel));

  const minLevelHeight = hasPass
    ? min(Object.values(levelHeights)) + shelfHeight
    : 0;
  const minLevel = Math.min(1, min(allLevels));
  const maxLevel = max(allLevels);

  let currentHeight = 0;

  const heightsFromFloorByLevel = range(minLevel, maxLevel + 1).map(l => {
    const hff = currentHeight;
    currentHeight += levelHeights[l]
      ? levelHeights[l] + shelfHeight
      : minLevelHeight;
    return hff;
  });

  return locations.map(loc => ({
    ...loc,
    locationHeightFromFloor:
      heightsFromFloorByLevel[loc.locationLevel - minLevel],
  }));
}
