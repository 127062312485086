import {
  AssignmentOccupancySummaryGroupBy,
  AssignmentOccupancySummaryGroupFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  FormattedValueWithUnit,
  formatInteger,
} from '../../../../common/formatHelper';
import useFormatter from '../../../../common/useFormatter';
import ErrorIndicator from '../../../../components/ErrorIndicator';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import ButtonSwitchMulti from '../../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import { Spacer } from '../../../../components/layout/Spacer';
import { ActionBar } from '../../../../components/nav/ActionBar';
import { Stat } from '../../../../components/stats/Stat';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { StatListItem } from '../../../../components/stats/StatListItem';
import { viewerPlanesMap } from '../../../../layout/viewer/store/viewer.state';
import { getAssignmentOccupancyStatusMap } from '../../../store/occupancy/assignmentOccupancy.helper';
import {
  assignmentOccupancyDrillDownSelected,
  assignmentOccupancyGroups,
} from '../../../store/occupancy/assignmentOccupancy.state';
import { getDimensionColor } from '../../../store/workforce.helper';
import ContainerChart from '../../ContainerChart';
import SimulationPieChart, { PieData } from '../../SimulationPieChart';

type DimensionData = PieData & {
  locationVolume: FormattedValueWithUnit;
  share: FormattedValueWithUnit;
};

const OccupancyByGroupChart: React.FC = () => {
  const { t } = useTranslation('simulation');
  const occupancyByGroups = useRecoilValue(assignmentOccupancyGroups);
  const formatter = useFormatter();
  const planes = useRecoilValue(viewerPlanesMap);
  const statusMap = getAssignmentOccupancyStatusMap(t);

  const [selectedSwitchOption, setSelectedSwitchOption] = useState<number>(0);
  const getKpiValue = (dim: AssignmentOccupancySummaryGroupFragment) => {
    switch (selectedSwitchOption) {
      case 0:
        return dim.locationCount;
      case 1:
        return dim.totalOccupancy;
      case 2:
        return dim.totalOccupiedVolume;
    }
  };

  const getFormatted = (v: number): FormattedValueWithUnit => {
    switch (selectedSwitchOption) {
      case 0:
        return {
          raw: v,
          unit: '',
          unitCode: '',
          fullString: formatInteger(v),
          value: formatInteger(v),
        };
      case 1:
        return formatter.formatShare(v);
      case 2:
        return formatter.formatVolume(v);
    }
  };

  const chartData: DimensionData[] = _(occupancyByGroups)
    .map(dim => {
      const value = getKpiValue(dim);
      const dimension = {
        value,
        formatted: getFormatted(value),
        color: getDimensionColor(JSON.stringify(dim.key)),
        locationVolume: formatter.formatVolume(dim.totalStorageVolume),
        share: formatter.formatShare(dim.totalOccupancy),
        locationCount: formatInteger(dim.locationCount),
      };

      const keyAsValue =
        dim.key.locationLevel ??
        dim.key.locationRackingType ??
        dim.key.warehouseArea;

      if (!_.isNil(keyAsValue)) {
        return {
          ...dimension,
          id: keyAsValue.toString(),
          label: keyAsValue.toString(),
        };
      }

      if (!_.isNil(dim.key.status)) {
        const id = dim.key.status;
        return {
          ...dimension,
          id,
          label: statusMap[id],
        };
      }

      if (!_.isNil(dim.key.planeId)) {
        return {
          ...dimension,
          id: dim.key.planeId,
          label: planes[dim.key.planeId]?.title,
        };
      }

      return null;
    })
    .compact()
    .value();
  const [drill, setDrill] = useRecoilState(
    assignmentOccupancyDrillDownSelected,
  );

  const drillOptions: Record<
    AssignmentOccupancySummaryGroupBy,
    {
      id: AssignmentOccupancySummaryGroupBy;
      title: string;
    }
  > = _.keyBy(
    [
      {
        id: AssignmentOccupancySummaryGroupBy.STATUS,
        title: t`Status`,
      },
      {
        id: AssignmentOccupancySummaryGroupBy.LEVEL,
        title: t`Level`,
      },

      {
        id: AssignmentOccupancySummaryGroupBy.WAREHOUSE_AREA,
        title: t`Area`,
      },
      {
        id: AssignmentOccupancySummaryGroupBy.LOCATION_RACKING_TYPE,
        title: t`Location Type`,
      },
      {
        id: AssignmentOccupancySummaryGroupBy.LOCATION_USAGE_TYPE,
        title: t`Usage Type`,
      },
    ],
    'id',
  ) as any;

  const legendOptions = [
    { label: t`Loc.` },
    { label: t`Vol. (%)` },
    { label: t`Vol. (m²)` },
  ];

  return (
    <Stat
      className={'h-full'}
      isPreview
      title={''}
      inPanelMode
      // switchUnitOfMeasure={kpiSwitch}
    >
      <DropdownSelector
        className="mb-4"
        label={t`Group by`}
        values={_.keys(drillOptions) as AssignmentOccupancySummaryGroupBy[]}
        value={drill}
        onChange={setDrill}
        panelMode
        DropAlignRight
        renderValue={(v: AssignmentOccupancySummaryGroupBy) =>
          drillOptions[v]?.title
        }
      />
      <ContainerChart>
        <SimulationPieChart data={chartData} />
      </ContainerChart>

      <ActionBar className="mb-2">
        <Spacer flexspace />
        <ButtonSwitchMulti
          buttonType="minimal"
          autoSize
          className="mx-2"
          selectedIndex={selectedSwitchOption}
          onClick={setSelectedSwitchOption}
          options={legendOptions}
        />
      </ActionBar>

      {_.map(chartData, dim => {
        return (
          <StatListItem
            key={dim.id}
            title={dim.label}
            value={dim.formatted?.value ?? dim.value}
            unitOfMeasure={dim.formatted?.unit}
            legendColor={dim.color[0]}
            legendType={'torus'}
          />
        );
      })}
    </Stat>
  );
};

const OccupancyByGroup: React.FC = () => {
  const { t } = useTranslation('simulation');

  return (
    <StatGroup
      subTitle={t`Stats`}
      title={t`Occupancy by group`}
      helpNavTo={'simulation/compliance/simulation-compliance-volume'}
    >
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorIndicator
            selfCenter
            message={error.message}
            hasReloadButton
            exception={error}
          />
        )}
      >
        <Suspense
          fallback={
            <LoadingIndicator
              className="min-h-20"
              selfCenter
              message={t`Loading...`}
            />
          }
        >
          <OccupancyByGroupChart />
        </Suspense>
      </ErrorBoundary>
    </StatGroup>
  );
};

export default OccupancyByGroup;
