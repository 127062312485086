const MenuItemArrowBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 16.43"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuItemArrowBottomIcon"
    {...props}
  >
    <polygon strokeWidth={0} points="128 .02 64 14.43 0 -.01 128 .02" />
  </svg>
);
export default MenuItemArrowBottomIcon;
