import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentViewAs } from '../../common/types';
import ButtonSwitchMulti from './ButtonSwitchMulti';

export type SwitchLayoutTableProps = {
  selected: ContentViewAs;
  onChange: (value: ContentViewAs) => void;
};

const SwitchLayoutTable: React.FC<SwitchLayoutTableProps> = props => {
  const { t } = useTranslation('app');

  const viewAs: { key: ContentViewAs; label: string }[] = [
    {
      key: 'dashboard',
      label: t`KPIs`,
    },
    {
      key: 'table',
      label: t`Data`,
    },
    {
      key: 'layout',
      label: t`Map`,
    },
  ];

  return (
    <ButtonSwitchMulti
      autoSize
      className="mx-3"
      classNameLabel="text-xs py-1 px-2"
      buttonType="header"
      options={viewAs}
      selectedIndex={_.findIndex(viewAs, o => o.key === props.selected)}
      onClick={index => props.onChange(viewAs[index].key)}
    />
  );
};

export default SwitchLayoutTable;
