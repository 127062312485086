import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../../../common/useFormatter';
import {
  EventGroup,
  EventGroupKeys,
  getEventGroupColor,
  getEventGroupTitle,
} from '../../../store/simulation.helper';
import { DRILL_KEY_WORK_TOTAL } from '../../../store/workforce.types';
import ChartTooltip from '../../ChartTooltip';
import ContainerChart from '../../ContainerChart';
import { WorkforceSummaryAgentsKPI } from './WorkforceSummaryAgentsChartKPISwitch';

const serieHeight = 80;
export type WorkforceSummaryAgentsChartDataRow = Record<EventGroup, number> & {
  agentId: string;
  agentTitle: string;
  [DRILL_KEY_WORK_TOTAL]: number;
};

export type WorkforceSummaryAgentsChartProps = {
  data: WorkforceSummaryAgentsChartDataRow[];
  kpi: WorkforceSummaryAgentsKPI;
  max?: number;
};

const WorkforceSummaryAgentsChart: React.FC<
  WorkforceSummaryAgentsChartProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  // Calculate the left margin based on title length
  let leftMargin = 10;
  for (const row of props.data) {
    const titleLength = row.agentTitle.length;

    // Increment leftMargin based on title length, but set a maximum of 80
    leftMargin = Math.min(titleLength * 9, 75);
  }

  let rightMargin = 10;
  if (props.max >= 1000000) {
    rightMargin = 35;
  } else if (props.max >= 100000) {
    rightMargin = 32;
  } else if (props.max >= 10000) {
    rightMargin = 30;
  } else if (props.max < 10000) {
    rightMargin = 25;
  }

  return (
    <ContainerChart containerHeight={_.size(props.data) * serieHeight}>
      <ResponsiveBar
        margin={{
          right: rightMargin,
          left: leftMargin,
          top: 5,
          bottom: 20,
        }}
        data={props.data}
        indexBy={'agentId'}
        keys={[...EventGroupKeys]}
        groupMode="stacked"
        layout="horizontal"
        enableGridY={true}
        enableGridX={false}
        colors={datum => getEventGroupColor(datum.id as any)}
        enableLabel={false}
        padding={0.2}
        markers={[
          {
            axis: 'x',
            value: props.max,
            lineStyle: {
              stroke: 'rgba(162,188,204, 0.3)',
              // strokeWidth: 1,
              // strokeDasharray: '2,2',
            },
          },
          {
            axis: 'x',
            value: 0,
            lineStyle: {
              stroke: 'rgba(162,188,204, 0.3)',
              // strokeWidth: 1,
              // strokeDasharray: '2,2',
            },
          },
        ]}
        theme={{
          tooltip: {
            container: {
              zIndex: 1000, // this looks like not work as expected
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 11,
                fill: 'rgba(255, 255, 255,  0.9)',
              },
            },
          },

          grid: {
            line: {
              stroke: 'rgba(162,188,204, 0.5)',
              strokeWidth: 0.5,
            },
          },
        }}
        axisBottom={{
          tickValues: _.uniq([props.max, 0]),
          format: v => formatter.formatCurrency(v).fullString,
        }}
        axisLeft={{
          format: agentId => {
            const title: string =
              _.find(props.data, r => r.agentId === agentId)?.agentTitle ??
              agentId;
            return title.length > 9 ? `${title.substring(0, 9)}...` : title;
          },
        }}
        isInteractive={true}
        layers={['grid', 'markers', 'bars', 'axes', 'legends']}
        tooltip={datum => {
          const agent = props.data.find(r => r.agentId === datum.data.agentId);
          const agentTitle = agent ? agent.agentTitle : '';
          const color = getEventGroupColor(datum.id as any);
          return (
            <ChartTooltip
              color={[datum.color, datum.color]}
              subTitle={agentTitle}
              title={getEventGroupTitle(datum.id as any, t)}
              value={formatter.formatCurrency(datum.value).fullString}
            />
          );
        }}
      />
    </ContainerChart>
  );
};

export default WorkforceSummaryAgentsChart;
