import * as React from 'react';
const CursorMoveLightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorMoveLightIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M65.75,4.53c-.08-.05-.16-.09-.25-.13-.1-.05-.19-.11-.29-.16-.11-.04-.22-.07-.33-.1-.09-.03-.17-.06-.26-.08-.41-.08-.83-.08-1.24,0-.09.02-.18.05-.27.08-.11.03-.21.06-.32.1-.11.04-.2.1-.3.16-.08.04-.17.08-.24.13-.17.12-.33.25-.48.39l-13.38,13.38c-1.23,1.23-1.23,3.23,0,4.46s3.23,1.23,4.46,0l7.99-7.99v32.52c0,1.74,1.41,3.15,3.15,3.15.87,0,1.66-.35,2.23-.92s.92-1.36.92-2.23V14.76s7.99,7.99,7.99,7.99c1.23,1.23,3.23,1.23,4.46,0s1.23-3.23,0-4.46l-13.38-13.38c-.15-.15-.31-.28-.48-.39Z"
    />
    <path
      strokeWidth={0}
      d="M60.85,80.72v32.52s-7.99-7.99-7.99-7.99c-1.23-1.23-3.23-1.23-4.46,0s-1.23,3.23,0,4.46l13.38,13.38c.15.15.31.28.48.39.08.05.16.09.25.13.1.05.19.11.29.16.11.04.22.07.33.1.09.03.17.06.26.08.41.08.83.08,1.24,0,.09-.02.18-.05.27-.08.11-.03.22-.06.32-.1.1-.04.2-.1.3-.16.08-.04.17-.08.25-.13.17-.12.33-.25.48-.39l13.38-13.38c1.23-1.23,1.23-3.23,0-4.46-1.23-1.23-3.23-1.23-4.46,0l-7.99,7.99v-32.52c0-1.74-1.41-3.15-3.15-3.15-1.74,0-3.15,1.41-3.15,3.15Z"
    />
    <path
      strokeWidth={0}
      d="M22.76,79.61c1.23-1.23,1.23-3.23,0-4.46l-7.99-7.99h32.51c.87,0,1.66-.35,2.23-.92s.92-1.36.92-2.23c0-1.74-1.41-3.15-3.15-3.15H14.76s7.99-7.99,7.99-7.99c1.23-1.23,1.23-3.23,0-4.46-1.23-1.23-3.23-1.23-4.46,0l-13.38,13.38c-.15.15-.28.31-.39.48-.05.08-.09.16-.13.24-.05.1-.11.19-.16.3-.04.1-.07.21-.1.32-.03.09-.06.18-.08.27-.08.41-.08.83,0,1.24.02.09.05.17.08.26.03.11.06.22.1.33.04.1.1.2.16.29.04.08.08.17.13.25.12.17.25.33.39.48l13.38,13.38c1.23,1.23,3.23,1.23,4.46,0Z"
    />
    <path
      strokeWidth={0}
      d="M105.24,48.39c-1.23,1.23-1.23,3.23,0,4.46l7.99,7.99h-32.52c-1.74,0-3.15,1.41-3.15,3.15,0,1.74,1.41,3.15,3.15,3.15h32.52s-7.99,7.99-7.99,7.99c-1.23,1.23-1.23,3.23,0,4.46,1.23,1.23,3.23,1.23,4.46,0l13.38-13.38c.15-.15.28-.31.39-.48.05-.08.09-.16.13-.25.05-.1.11-.19.16-.3.04-.1.07-.21.1-.32.03-.09.06-.17.08-.27.08-.41.08-.83,0-1.24-.02-.09-.05-.17-.08-.26-.03-.11-.06-.22-.1-.33-.04-.1-.1-.2-.16-.29-.04-.08-.08-.17-.13-.25-.12-.17-.25-.33-.39-.48l-13.38-13.38c-1.23-1.23-3.23-1.23-4.46,0Z"
    />
  </svg>
);
export default CursorMoveLightIcon;
