import _ from 'lodash';
import { selector, selectorFamily } from 'recoil';
import { persistAtom } from '../common/recoil/persistAtom';

export const SIDEBAR_TYPES_MANAGEABLE = [
  'sidebar-simulation-getting-started',
  'sidebar-simulation-setup',
  'sidebar-simulation-dataset',
  'sidebar-simulation-dataset-layout',
  'sidebar-simulation-dataset-assignment',
  'sidebar-simulation-dataset-orders',
  'sidebar-simulation-dataset-items',
  'sidebar-dataset-import',
  'sidebar-dataset-stats',
  'sidebar-dataset-stats-legend',
  'sidebar-stacking-policy',
  'sidebar-stacking-policy-editor',

  // Resource policy
  'sidebar-policy-resource',
  'sidebar-policy-routing',

  //Routing policy
  'sidebar-routing-policy',
  'sidebar-routing-policy-editor',

  //Waypoint Policy
  'waypoint-policy',

  // Swap policy
  'sidebar-policy-swap',
  'sidebar-policy-swap-editor',

  // Analyze
  'sidebar-simulation-analyze',
  'sidebar-simulation-issues',
  'sidebar-simulation-issues-wide',
  'sidebar-simulation-info-common',
  'sidebar-simulation-info-location',
  'sidebar-simulation-routes',
  'sidebar-simulation-analyze-time-summary',

  // Compliance
  'sidebar-compliance',
  'sidebar-compliance-assignment-editor',

  // Storage Policy
  'sidebar-policy-storage',
  'sidebar-policy-storage-editor',
  'sidebar-policy-storage-stats',

  'sidebar-policy-location-sharing',
  'sidebar-policy-location-sharing-editor',

  // Swap Policy
  'sidebar-policy-swap',
  'sidebar-policy-swap-editor',

  // Picking Policy
  'sidebar-policy-picking',
  'sidebar-policy-picking-editor',

  // Optimisation
  'sidebar-optimisation-setup',
  'sidebar-optimisation-running',
  'sidebar-optimisation-results',
  'sidebar-optimisation-results-items',
  'sidebar-optimisation-results-implement',

  //allocation
  'sidebar-allocation-requirement',
  'sidebar-analyze-allocate',
  'sidebar-policy-allocation-editor',

  //dataset-object
  'sidebar-dataset-object-editor',

  // dataset object
  'sidebar-dataset-object-main',
  'sidebar-dataset-object-stats',
  'sidebar-dataset-pipeline',
  'sidebar-dataset-pipeline-editor',

  // Feed
  'sidebar-feed-tasks',
  'sidebar-feed-info-location',
] as const;

export const SIDEBAR_TYPES_ANALYZE_COMPARE = [
  'analyze-compare-summary',
  'analyze-compare-bay',
  'analyze-compare-location',
  'analyze-compare-legend',
];

export const SIDEBAR_TYPES_STATIC = [
  'sidebar-global-help',
  'sidebar-global-notifications',
  'sidebar-global-settings',
  'sidebar-global-menu',

  'sidebar-menu-pricing-plans',
  'sidebar-menu-warehouses',
  'sidebar-menu-dashboard-data',

  // Importer
  'sidebar-importer-menu',
  'sidebar-importer-help',
  'sidebar-importer-info',

  // Layout Converter
  'sidebar-converter-info-common',
  'sidebar-converter-info-location',
  'sidebar-converter-issues',
  'sidebar-converter-wizard',
  'sidebar-converter-wizard-step',

  // layout
  'sidebar-layout-menu',
  'sidebar-layout-main',
  'sidebar-layout-common-info',
  'sidebar-layout-info-location',

  // item-set
  'sidebar-item-set-menu',
  'sidebar-item-set-main',
  'sidebar-item-set-editors',

  // Assignment
  'sidebar-assignment-menu',
  'sidebar-assignment-main',
  'sidebar-assignment-info',
  'sidebar-assignment-stats',
  'sidebar-assignment-simulations',

  // Order sets
  'sidebar-order-set-menu',
  'sidebar-order-set-main',
  'sidebar-order-set-info',

  // Dataset
  'sidebar-dataset-object-menu',

  //Automation
  'sidebar-dataset-automation-menu',

  // Feed
  'sidebar-feed-menu',
  'sidebar-feed-main',
  'sidebar-feed-info-event',
  'sidebar-feed-kpi',
  'sidebar-feed-heatmap',

  // Simulation
  'sidebar-simulation-menu',
  ...SIDEBAR_TYPES_ANALYZE_COMPARE,
] as const;

const SIDEBAR_TYPES = [
  ...SIDEBAR_TYPES_MANAGEABLE,
  ...SIDEBAR_TYPES_STATIC,
] as const;

export type SidebarState = {
  isCollapsed: boolean;
  isPinned: boolean;
  isHidden: boolean;
  openByDefault?: boolean;
};

type SidebarTypeManageable = (typeof SIDEBAR_TYPES_MANAGEABLE)[number];
type SidebarTypeStatic = (typeof SIDEBAR_TYPES_STATIC)[number];

export type SidebarType = SidebarTypeManageable | SidebarTypeStatic;
type SidebarStateMap = Record<SidebarType, SidebarState>;

const openByDefaultSidebars: Set<SidebarType> = new Set([]);

const sidebarStateDefault: SidebarStateMap = SIDEBAR_TYPES.reduce(
  (acc, v) => ({
    ...acc,
    [v]: {
      isCollapsed: !openByDefaultSidebars.has(v),
      isHidden: false,
      isPinned: false,
    },
  }),
  {} as SidebarStateMap,
);

export const sidebarStateAll = persistAtom<SidebarStateMap>({
  key: 'warebee-sidebar-state-all',
  default: sidebarStateDefault,
});

export const sidebarStateAllPatch = selector<
  Partial<Record<SidebarType, SidebarState>>
>({
  key: 'warebee-sidebar-state-all-patch',
  get: ({ get }) => get(sidebarStateAll),
  set: ({ get, set }, value: Record<SidebarType, SidebarState>) => {
    const current = get(sidebarStateAll);
    const patch = _.keys(value)
      .filter(
        (key: SidebarType) =>
          current[key]?.isHidden !== value[key]?.isHidden || //hidden prop changed
          !current[key]?.isPinned,
      )
      .reduce((acc, key: SidebarType) => ({ ...acc, [key]: value[key] }), {});

    const newFullState = _.defaultsDeep(patch, current);
    if (!_.isEqual(newFullState, current)) {
      set(sidebarStateAll, _.defaultsDeep(patch, get(sidebarStateAll)));
    }
  },
});

export const sidebarStateByType = selectorFamily<
  Partial<SidebarState>,
  SidebarType
>({
  key: 'warebee-sidebar-state-by-type',

  get:
    (id: SidebarType) =>
    ({ get }) => {
      return (
        get(sidebarStateAll)[id] ?? {
          isPinned: false,
          isCollapsed: true,
          isHidden: false,
        }
      );
    },

  set:
    id =>
    ({ get, set }, value: SidebarState) => {
      const current = get(sidebarStateAll)[id];
      set(sidebarStateAll, {
        ...get(sidebarStateAll),
        [id]: {
          ...current,
          ...value,
        },
      });
    },
});
