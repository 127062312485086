import {
  AreaConfiguration,
  BaysAlignOption,
  BaysOrderInAisleOption,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import ButtonToolbar from '../../../../components/actions/ButtonToolbar';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import { Container } from '../../../../components/layout/ContainerFlex';
import TitleSection from '../../../../components/layout/TitleSection';
import PanelContainer from '../../../../containers/PanelContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import {
  converterAreaConfiguration,
  converterSelectedArea,
} from '../store/converter.area.state';
import {
  converterAreaBuilder,
  converterLayoutBuilder,
} from '../store/converter.state';
import {
  getBaysAlignOptionMap,
  getBaysOrderInAisleOptionMap,
} from '../store/converter.types';
import ToolbarAreaOrientation from '../toolbars/ToolbarAreaOrientation';
import ConverterAisleOrderPanel from './ConverterAisleOrderPanel';

const ConverterAreaInfoPanel: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const wh = useRecoilValue(warehouseSelected);

  const [area, setArea] = useRecoilState(converterSelectedArea);
  const layoutBuilder = useRecoilValue(converterLayoutBuilder);
  const [areasConfigurations, setAreaConfiguration] = useRecoilState(
    converterAreaConfiguration,
  );
  const [areasBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );

  const config = areasConfigurations[area.id];
  if (!area || !areasBuilder) return;

  function updateConfig(areaConfig: AreaConfiguration) {
    setAreaConfiguration({
      ...areasConfigurations,
      [area.id]: areaConfig,
    });
  }

  const floorMap = _.keyBy(_.values(layoutBuilder.floors), f => f.id);
  const baysAlignOption = getBaysAlignOptionMap(t);
  const baysOrderInAisle = getBaysOrderInAisleOptionMap(t);

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  return (
    <>
      <WarehouseNavigator>
        <WarehouseNavigatorItem name={t`Area`} value={area.id} active />
      </WarehouseNavigator>
      <Container col hasOverflowY hasExtraPadding>
        <PanelContainer
          id="panel-area-floor-assignment"
          title={t`Area Floor Assignment`}
          collapsible
          hasPadding
        >
          <InputGroupList hasPadding panelMode>
            <DropdownSelector
              className="text-xs"
              panelMode
              widthFull
              value={config.floor}
              values={_.values(layoutBuilder.floors).map(f => f.id)}
              renderValue={v => floorMap[v]?.title || ''}
              label={t`Area Floor`}
              onChange={(value: string) => {
                setArea(null);
                updateConfig({ ...config, floor: value });
              }}
              icon={<Icon.Floor className="h-8 w-8 fill-current" />}
            />
          </InputGroupList>
        </PanelContainer>

        <PanelContainer
          id="panel-area-properties"
          title={t`Area Alignment`}
          collapsible
          hasPadding
        >
          <ToolbarAreaOrientation />
        </PanelContainer>

        <PanelContainer
          id="panel-area-aisle-properties"
          title={t`Area Aisle Global Settings`}
          collapsible
          hasPadding
        >
          <TitleSection title={t`Aisle Sizing`} inPanelView />

          <InputGroupList panelMode>
            <InputNumber
              title={t`Area Aisle Width`}
              ariaLabel={`AisleWidth`}
              value={areasBuilder.aisleWidth}
              range={[1, null]}
              unitOfMeasure={sizeMeasureValue}
              icon={
                <Icon.DesignerAislesWidth className="h-8 w-8 fill-current" />
              }
              onChange={v =>
                setAreaBuilder({
                  ...areasBuilder,
                  aisleWidth: v,
                })
              }
            />
            <InputNumber
              title={t`Area Aisle Min Length`}
              ariaLabel={`AisleMinLength`}
              value={areasBuilder.minAreaLength ?? 0}
              range={[0, null]}
              unitOfMeasure={sizeMeasureValue}
              icon={
                <Icon.DesignerAislesWidth className="h-8 w-8 fill-current" />
              }
              onChange={v =>
                setAreaBuilder({
                  ...areasBuilder,
                  minAreaLength: v,
                })
              }
            />
          </InputGroupList>

          <InputGroupList hasPadding panelMode>
            <ButtonToolbar
              title={t`Auto-fill Aisles`}
              isSelected={areasBuilder.useAutoSpacers}
              onChange={isSelected => {
                setAreaBuilder({
                  ...areasBuilder,
                  useAutoSpacers: isSelected,
                });
              }}
            >
              <Icon.DesignerAislesAutofill className="h-8 w-8 fill-current" />
            </ButtonToolbar>
          </InputGroupList>
          <TitleSection title={t`Area Aisle Order`} inPanelView />
          <InputGroupList hasPadding panelMode>
            <ConverterAisleOrderPanel area={area} />
          </InputGroupList>
          <TitleSection title={t`Area Access Aisles`} inPanelView />
          <InputGroupList hasPadding panelMode>
            <ButtonToolbar
              title={t`Access Aisle - On Start`}
              isSelected={areasBuilder.hasBottomAccessAisle}
              onChange={isSelected => {
                setAreaBuilder({
                  ...areasBuilder,
                  hasBottomAccessAisle: isSelected,
                });
              }}
            >
              <Icon.DesignerAislesAccessBottom className="h-8 w-8 fill-current" />
            </ButtonToolbar>
            <ButtonToolbar
              title={t`Access Aisle - On End`}
              isSelected={areasBuilder.hasTopAccessAisle}
              onChange={isSelected => {
                setAreaBuilder({
                  ...areasBuilder,
                  hasTopAccessAisle: isSelected,
                });
              }}
            >
              <Icon.DesignerAislesAccessTop className="h-8 w-8 fill-current" />
            </ButtonToolbar>
          </InputGroupList>
        </PanelContainer>

        <PanelContainer
          id="panel-area-bay-properties"
          title={t`Area Bay Alignment Settings`}
          collapsible
          hasPadding
        >
          <TitleSection title={t`Bay Sequence logic`} inPanelView />
          <InputGroupList hasPadding panelMode>
            <DropdownSelector
              panelMode
              widthFull
              value={areasBuilder.baysOrder}
              values={[...baysOrderInAisle.keys()]}
              renderValue={i => baysOrderInAisle.get(i)}
              label={t`Bay Order`}
              onChange={(v: BaysOrderInAisleOption) => {
                setAreaBuilder({ ...areasBuilder, baysOrder: v });
              }}
              icon={<Icon.DesignerBaysOrder className="h-8 w-8 fill-current" />}
            />
          </InputGroupList>

          <TitleSection title={t`Bay layout alignment logic`} inPanelView />
          <InputGroupList hasPadding panelMode>
            <DropdownSelector
              panelMode
              widthFull
              value={areasBuilder.baysAlign}
              values={[...baysAlignOption.keys()]}
              renderValue={i => baysAlignOption.get(i)}
              label={t`Align bays`}
              icon={
                <Icon.DesignerBaysAlignBottom className="h-8 w-8 fill-current" />
              }
              onChange={(v: BaysAlignOption) => {
                setAreaBuilder({ ...areasBuilder, baysAlign: v });
              }}
            />
          </InputGroupList>
        </PanelContainer>
      </Container>
    </>
  );
};

export default ConverterAreaInfoPanel;
