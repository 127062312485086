import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { fetchAuthSession } from 'aws-amplify/auth';
import _ from 'lodash';
import {
  APP_BILLING_PATH,
  APP_BILLING_PUBLIC_PATH,
  APP_BILLING_URL,
  BACKEND_PATH,
  BACKEND_URL,
  TOKEN_APP_BILLING_PATH,
  TOKEN_BACKEND_PATH,
} from './common/environmentVariables';

export function createClient() {
  return new ApolloClient({
    uri: `${BACKEND_URL}/graphql`,
    cache: new InMemoryCache(),
    credentials: 'include',
  });
}

export function createPublicClient(url: string) {
  return new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    credentials: 'include',
  });
}

export function createSecureClient(
  onUnauthorized: () => void,
  url: string,
): ApolloClient<any> {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      nextFetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  const logLink = new ApolloLink((operation, forward) => {
    const startTime = Date.now();

    return forward(operation).map(data => {
      const endTime = Date.now();
      const elapsed = (endTime - startTime) / 1000;

      // Retrieve the counter from the context
      const { queryCounter } = operation.getContext();

      // console.info(
      //   `[GQL][${elapsed.toFixed(2)}s][${queryCounter}]\n ${
      //     operation.operationName
      //   }`,
      // );

      return data;
    });
  });

  const queryCounter: { [key: string]: number } = {};
  const logDedupeLink = new ApolloLink((operation, forward) => {
    const operationName = operation.operationName || 'Unnamed';
    const variableHash = JSON.stringify(operation.variables);
    const queryKey = `${operationName}-${variableHash}`;

    // Increment or initialize the query counter
    queryCounter[queryKey] = (queryCounter[queryKey] || 0) + 1;

    operation.setContext({
      queryCounter: queryCounter[queryKey],
    });

    return forward(operation);
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GQL][ERROR]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError) {
      console.error(
        `[GQL][ERROR]: [Network error in Operation ${operation.operationName}]: ${networkError}`,
      );

      if (networkError.message.includes('CONNECTION_RESET')) {
        // Handle the connection reset error.
        console.error(`[GQL][ERROR]: [Connection Reset]`);
        // You can choose to retry the operation here or inform the user.
      }

      if (!window.navigator.onLine) {
        // We're offline. Handle accordingly.
        console.error(`[GQL][ERROR]: [Offline]`);
        // You could set a flag here to show an offline banner on the UI.
      }
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  });

  const logRetryLink = new ApolloLink((operation, forward) => {
    if (operation.getContext().retryCount) {
      console.log(
        `[GQL][RETRY]: Retry count for operation ${
          operation.operationName
        } is ${operation.getContext().retryCount}`,
      );
    }
    return forward(operation);
  });

  const cache = new InMemoryCache({});

  const authMiddleware = setContext(async () => {
    try {
      const { tokens } = await fetchAuthSession();
      const jwtToken = tokens.idToken.toString();
      return {
        headers: {
          authorization: jwtToken,
        },
      };
    } catch (error) {
      console.error('Auth failed:', error);
      onUnauthorized();
      throw error;
    }
  });

  // TODO detect and report auth failures
  return new ApolloClient({
    link: ApolloLink.from([
      logLink,
      logDedupeLink,
      errorLink,
      logRetryLink,
      // retryLink,
      authMiddleware,
      new HttpLink({
        uri: url,
        credentials: 'include',
      }),
    ]),
    cache,
    defaultOptions,
    queryDeduplication: false,
  });
}

export function createTokenClient(
  onUnauthorized: () => void,
  url: string,
  token: string,
): ApolloClient<any> {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      nextFetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  const logLink = new ApolloLink((operation, forward) => {
    const startTime = Date.now();

    return forward(operation).map(data => {
      const endTime = Date.now();
      const elapsed = (endTime - startTime) / 1000;

      // Retrieve the counter from the context
      const { queryCounter } = operation.getContext();

      return data;
    });
  });

  const queryCounter: { [key: string]: number } = {};
  const logDedupeLink = new ApolloLink((operation, forward) => {
    const operationName = operation.operationName || 'Unnamed';
    const variableHash = JSON.stringify(operation.variables);
    const queryKey = `${operationName}-${variableHash}`;

    // Increment or initialize the query counter
    queryCounter[queryKey] = (queryCounter[queryKey] || 0) + 1;

    operation.setContext({
      queryCounter: queryCounter[queryKey],
    });

    return forward(operation);
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GQL][ERROR]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError) {
      console.error(
        `[GQL][ERROR]: [Network error in Operation ${operation.operationName}]: ${networkError}`,
      );

      if (networkError.message.includes('CONNECTION_RESET')) {
        // Handle the connection reset error.
        console.error(`[GQL][ERROR]: [Connection Reset]`);
        // You can choose to retry the operation here or inform the user.
      }

      if (!window.navigator.onLine) {
        // We're offline. Handle accordingly.
        console.error(`[GQL][ERROR]: [Offline]`);
        // You could set a flag here to show an offline banner on the UI.
      }
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  });

  const logRetryLink = new ApolloLink((operation, forward) => {
    if (operation.getContext().retryCount) {
      console.log(
        `[GQL][RETRY]: Retry count for operation ${
          operation.operationName
        } is ${operation.getContext().retryCount}`,
      );
    }
    return forward(operation);
  });

  const cache = new InMemoryCache({});

  const authMiddleware = setContext(async () => {
    try {
      return {
        headers: {
          authorization: token,
        },
      };
    } catch (error) {
      console.error('Auth failed:', error);
      onUnauthorized();
      throw error;
    }
  });

  // TODO detect and report auth failures
  return new ApolloClient({
    link: ApolloLink.from([
      logLink,
      logDedupeLink,
      errorLink,
      logRetryLink,
      // retryLink,
      authMiddleware,
      new HttpLink({
        uri: url,
        credentials: 'include',
      }),
    ]),
    cache,
    defaultOptions,
    queryDeduplication: false,
  });
}

export let secureClient = createSecureClient(
  _.noop,
  BACKEND_URL + BACKEND_PATH,
);
export let billingClient = createSecureClient(
  _.noop,
  APP_BILLING_URL + APP_BILLING_PATH,
);
export const appBillingPublicClient = createPublicClient(
  APP_BILLING_URL + APP_BILLING_PUBLIC_PATH,
);

export function updateClient(token: string) {
  secureClient = createTokenClient(
    _.noop,
    BACKEND_URL + TOKEN_BACKEND_PATH,
    token,
  );
  billingClient = createTokenClient(
    _.noop,
    APP_BILLING_URL + TOKEN_APP_BILLING_PATH,
    token,
  );
}
