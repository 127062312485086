import clsx, { type ClassValue } from 'clsx';
import _ from 'lodash';
import { twMerge } from 'tailwind-merge';

export function getIndexedTitle(existed: Set<string>, prefix: string) {
  let index = 0;
  let title = prefix;
  while (existed.has(title)) {
    index++;
    title = `${prefix}${index}`;
  }
  return title;
}

export function cloneWithoutTypename<T>(obj: T) {
  if (!_.isObject(obj)) {
    return obj;
  }
  if (_.isArray(obj)) {
    return obj.map(cloneWithoutTypename);
  }
  const result: any = {};
  for (const key in obj) {
    if (key === '__typename') {
      continue;
    }
    const value = obj[key];
    if (_.isObject(value)) {
      result[key] = cloneWithoutTypename(value);
    } else if (_.isArray(value)) {
      result[key] = value.map(cloneWithoutTypename);
    } else {
      result[key] = value;
    }
  }
  return result;
}

// Function to correctly merge tailwind classes and classnames without duplicates and cascading issues
export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export const parseJSON = inputString => {
  if (inputString) {
    try {
      return JSON.parse(inputString);
    } catch (e) {
      return null;
    }
  }
};
