const MenuBarsRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuBarsRightIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M66.04,89.89H11.16c-2.53,0-4.57-2.05-4.57-4.57s2.05-4.57,4.57-4.57h54.89c2.53,0,4.57,2.05,4.57,4.57s-2.05,4.57-4.57,4.57ZM66.04,68.57H11.16c-2.53,0-4.57-2.05-4.57-4.57s2.05-4.57,4.57-4.57h54.89c2.53,0,4.57,2.05,4.57,4.57s-2.05,4.57-4.57,4.57ZM66.04,47.26H11.16c-2.53,0-4.57-2.05-4.57-4.57s2.05-4.57,4.57-4.57h54.89c2.53,0,4.57,2.05,4.57,4.57s-2.05,4.57-4.57,4.57Z"
    />
    <path
      strokeWidth={0}
      d="M107.54,64.45l-20.5,20.5c-.41.41-.53,1.03-.31,1.56.22.54.75.89,1.32.89h11.84c.4,0,.78-.17,1.05-.46l20.08-21.51c.51-.55.51-1.41,0-1.96l-20.08-21.51c-.27-.29-.65-.46-1.05-.46h-11.84c-.58,0-1.1.35-1.32.89-.22.54-.1,1.15.31,1.56l20.5,20.5Z"
    />
  </svg>
);
export default MenuBarsRightIcon;
