import * as React from 'react';
const LocationsEmptyIcon = props => (
  <svg
    aria-hidden="true"
    data-name="LocationsEmptyIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={25.27}
      height={122.66}
      x={50.79}
      y={2.73}
      opacity={0.3}
      transform="translate(126.85 128.11) rotate(180)"
    />
    <g>
      <path d="m125.83,2.71h-47.8v28.92s47.8,0,47.8,0V2.71h0Zm-45.8,26.92V4.71s43.8,0,43.8,0v24.92h-43.8Z" />
      <polygon points="108.06 8.95 101.93 15.74 95.81 8.95 94.52 10.38 100.64 17.17 94.52 23.96 95.81 25.39 101.93 18.6 108.06 25.39 109.35 23.96 103.22 17.17 109.35 10.38 108.06 8.95 108.06 8.95" />
    </g>
    <g>
      <path d="m125.83,33.97h-47.8v28.92s47.8,0,47.8,0v-28.92h0Zm-45.8,26.92v-24.92s43.8,0,43.8,0v24.92h-43.8Z" />
      <polygon points="108.06 40.21 101.93 47 95.81 40.21 94.52 41.64 100.64 48.43 94.52 55.22 95.81 56.65 101.93 49.86 108.06 56.65 109.35 55.22 103.22 48.43 109.35 41.64 108.06 40.21 108.06 40.21" />
    </g>
    <g>
      <path d="m125.83,65.22h-47.8v28.92s47.8,0,47.8,0v-28.92h0Zm-45.8,26.92v-24.92s43.8,0,43.8,0v24.92h-43.8Z" />
      <polygon points="108.06 71.46 101.93 78.25 95.81 71.46 94.52 72.89 100.64 79.68 94.52 86.47 95.81 87.9 101.93 81.11 108.06 87.9 109.35 86.47 103.22 79.68 109.35 72.89 108.06 71.46 108.06 71.46" />
    </g>
    <g>
      <path d="m125.83,96.47h-47.8v28.92s47.8,0,47.8,0v-28.92h0Zm-45.8,26.92v-24.92s43.8,0,43.8,0v24.92h-43.8Z" />
      <polygon points="108.06 102.7 101.93 109.5 95.81 102.7 94.52 104.13 100.64 110.93 94.52 117.72 95.81 119.15 101.93 112.36 108.06 119.15 109.35 117.72 103.22 110.93 109.35 104.13 108.06 102.7 108.06 102.7" />
    </g>
    <g>
      <path d="m48.99,2.71H1.19v28.92s47.8,0,47.8,0V2.71h0ZM3.19,29.63V4.71s43.8,0,43.8,0v24.92H3.19Z" />
      <polygon points="31.21 8.95 25.09 15.74 18.96 8.95 17.67 10.38 23.8 17.17 17.67 23.96 18.96 25.39 25.09 18.6 31.21 25.39 32.5 23.96 26.38 17.17 32.5 10.38 31.21 8.95 31.21 8.95" />
    </g>
    <g>
      <path d="m48.99,33.97H1.19v28.92s47.8,0,47.8,0v-28.92h0ZM3.19,60.89v-24.92s43.8,0,43.8,0v24.92H3.19Z" />
      <polygon points="31.21 40.21 25.09 47 18.96 40.21 17.67 41.64 23.8 48.43 17.67 55.22 18.96 56.65 25.09 49.86 31.21 56.65 32.5 55.22 26.38 48.43 32.5 41.64 31.21 40.21 31.21 40.21" />
    </g>
    <g>
      <path d="m48.99,65.22H1.19v28.92s47.8,0,47.8,0v-28.92h0ZM3.19,92.14v-24.92s43.8,0,43.8,0v24.92H3.19Z" />
      <polygon points="31.21 71.46 25.09 78.25 18.96 71.46 17.67 72.89 23.8 79.68 17.67 86.47 18.96 87.9 25.09 81.11 31.21 87.9 32.5 86.47 26.38 79.68 32.5 72.89 31.21 71.46 31.21 71.46" />
    </g>
    <g>
      <path d="m48.99,96.47H1.19v28.92s47.8,0,47.8,0v-28.92h0ZM3.19,123.39v-24.92s43.8,0,43.8,0v24.92H3.19Z" />
      <polygon points="31.21 102.7 25.09 109.5 18.96 102.7 17.67 104.13 23.8 110.93 17.67 117.72 18.96 119.15 25.09 112.36 31.21 119.15 32.5 117.72 26.38 110.93 32.5 104.13 31.21 102.7 31.21 102.7" />
    </g>
  </svg>
);
export default LocationsEmptyIcon;
