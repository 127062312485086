import { AxisTickProps } from '@nivo/axes';
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../Tw';
import { getBestContrastColor } from '../../common/color.helper';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import {
  EventGroup,
  EventGroupKeys,
  getEventGroupColor,
  getEventGroupTitle,
} from '../store/simulation.helper';
import ChartTooltip from './ChartTooltip';
import { CompareRawDatum } from './OptimisationEventsComparePanel';

const colors = TwTheme.extend.colors;

export type StackedBarProps = {
  data: CompareRawDatum[];
  valueFormatter: (v: number) => FormattedValueWithUnit;
};

const CustomTick = (tick: AxisTickProps<string>) => {
  return (
    <g transform={`translate(${tick.x},${tick.y + 25})`}>
      <rect
        x={-30}
        y={-12}
        rx={3}
        ry={3}
        width={60}
        height={24}
        fill={colors.menu.disabled}
      />
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          //...theme.axis.ticks.text,
          fill: getBestContrastColor(colors.menu.disabled),
          fontSize: 13,
        }}
      >
        {tick.value}
      </text>
    </g>
  );
};

const StackedBar: React.FC<StackedBarProps> = props => {
  const { t } = useTranslation('simulation');
  return (
    <ResponsiveBar
      motionConfig={'gentle'}
      data={props.data}
      indexBy={'analyzeType'}
      keys={[...EventGroupKeys]}
      valueFormat={v => props.valueFormatter(v).fullString}
      colors={row => getEventGroupColor(row.id as EventGroup)}
      label={datum => getEventGroupTitle(datum.id as EventGroup, t)}
      margin={{ top: 10, right: 0, bottom: 40, left: 0 }}
      axisTop={null}
      axisBottom={{
        renderTick: CustomTick,
      }}
      axisRight={null}
      axisLeft={null}
      enableGridY={true}
      padding={0.1}
      innerPadding={0}
      labelTextColor={{ from: 'color', modifiers: [['darker', 50]] }}
      theme={{
        grid: {
          line: {
            stroke: colors.menu.DEFAULT,
            strokeWidth: 1,
          },
        },
      }}
      tooltip={datum => {
        const event = getEventGroupTitle(datum.id as EventGroup, t);
        return (
          <ChartTooltip
            color={[datum.color, datum.color]}
            title={event}
            value={datum.formattedValue}
            label={datum.indexValue}
          />
        );
      }}
    />
  );
};
export default StackedBar;
