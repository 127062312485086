import classNames from 'classnames';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from './icons';

export const TextSize = {
  xs: 'xs',
  sm: 'sm',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
  '3xl': '2xl',
  '4xl': '4xl',
  '6xl': '6xl',
  lead: '4xl',
  hero: '4xl',
};

export type HelperMessageProps = PropsWithChildren & {
  className?: string;
  classNameMessage?: string;
  classNameAction?: string;
  message?: string;
  hasIcon?: boolean;
  isLead?: boolean;
  isHero?: boolean;
  isTip?: boolean;
  isError?: boolean;
  isSuggestion?: boolean;
  hasMargin?: boolean;
  hasPadding?: boolean;
  hasBackground?: boolean;
  hideMessage?: boolean;
  hasBefore?: boolean;
  hasAfter?: boolean;
  hasAction?: boolean;
  type?: string;
  textSize?: typeof TextSize | string;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconAction?: React.FC<HTMLAttributes<Element>>;
};

export const HelperMessage = ({
  children,
  className,
  classNameMessage,
  message,
  hasIcon,
  isLead,
  isHero,
  isTip,
  isError,
  isSuggestion,
  hasMargin,
  hasPadding,
  hasBefore,
  hasAfter,
  hasAction,
  hideMessage,
  hasBackground,
  textSize,
  icon,
  iconAction,
  type,
}: HelperMessageProps) => {
  const { t } = useTranslation('help');

  const IconAction = iconAction;

  const styleTransition = 'transition duration-500 ease-in-out delay-1000';

  return (
    <div
      data-component="HelperMessage"
      className={classNames(
        'w-full',
        'relative group',
        isHero ? 'text-menu-text' : 'text-menu-active',
        {
          'bg-menu/10': hasBackground,
        },
        { 'px-4 py-6 pt-0': hasPadding },
        { 'mt-0': hasMargin },
        { 'mb-4': hasAfter },
        { 'mt-4': hasBefore },
        { 'border-t border-menu-100/30 border-b py-2': isTip },
        {
          'border border-menu p-1 md:p-2 xl:p-3 rounded bg-app-panel-dark/50 shadow-xl':
            isSuggestion && !isError && hasBackground,
        },
        {
          'border border-menu/50 p-1 md:p-2 xl:p-3 rounded text-menu-text':
            isError,
        },
        className,
      )}
    >
      <div data-component="HelperMessage-container">
        {hasIcon && <Icon.CircleHelp className="w-8 h-8 fill-current" />}
        {message ||
          (children && (
            <div
              data-component="HelperMessage-message"
              className={classNames(
                'flex-1 whitespace-pre-line',
                {
                  'text-base': !textSize,
                },
                {
                  'text-2xl xl:text-3xl': textSize === 'lead',
                },
                {
                  'text-xl': textSize === 'xl',
                },
                {
                  'text-2xl': textSize === 'hero' || textSize === '2xl',
                },
                {
                  'text-menu-text font-bold': (isLead || isError) && !isHero,
                },
                isHero
                  ? 'text-menu-active-text'
                  : isSuggestion
                    ? 'text-menu-text'
                    : 'text-menu-text/80',
                classNameMessage,
              )}
            >
              {isTip && (
                <span
                  className={classNames(
                    'p-1',
                    'ltr:mr-2 rtl:ml-2',
                    'text-xs',
                    'uppercase',
                    'rounded',
                    'bg-menu-active text-menu-active-text',
                  )}
                >
                  {t`Tip:`}
                </span>
              )}
              {isSuggestion && (
                <Icon.HelpBot
                  className={classNames(
                    'w-8 h-8',
                    'ltr:mr-1 rtl:ml-1',
                    'fill-current',
                  )}
                />
              )}
              {isSuggestion && hideMessage && (
                <div
                  className={classNames(
                    'absolute top-0 ltr:right-0 rtl:left-0',
                    'flex items-center',
                    'p-1',
                    styleTransition,
                    'any-hover-none:opacity-0',
                    'any-hover:group-hover:opacity-100',
                    'cursor-pointer',
                  )}
                >
                  {t`hide`}
                  <Icon.CircleX
                    className={classNames(
                      'w-6 h-6',
                      'ltr:ml-1 rtl:mr-1',
                      'fill-current',
                    )}
                  />
                </div>
              )}
              {message}
              {children}
              {IconAction && (
                <IconAction
                  className={classNames(
                    'ltr:ml-1 rtl:mr-1',
                    'inline fill-current',
                    isHero ? 'text-menu-active-text' : 'text-menu-active',
                    {
                      'w-3 h-3': !textSize || textSize === 'sm',
                    },
                    {
                      'w-5 h-5':
                        textSize === 'hero' ||
                        textSize === 'lead' ||
                        textSize === 'xl',
                    },
                  )}
                />
              )}
            </div>
          ))}
      </div>
      {!isSuggestion && hideMessage && (
        <div
          className={classNames(
            'absolute bottom-0 left-0',
            'w-full',
            'p-2',
            'ltr:pl-4 rtl:pr-4 mt-2',
            'text-xs',
            'transition duration-500 ease-in-out delay-1000',
            'any-hover-none:opacity-0',
            'any-hover:group-hover:opacity-100',
            'cursor-pointer',
            'text-menu-text',
            'bg-app-panel-light/70',
            'backdrop-blur-lg backdrop-saturate-110',
          )}
        >
          {t`Don't show again`}
        </div>
      )}
    </div>
  );
};

export const HelperMessageAction = ({
  children,
  className,
  classNameAction,
}: HelperMessageProps) => {
  return (
    <span
      data-component={`HelperMessage-Action`}
      className={classNames(
        'text-sm uppercase text-menu-action whitespace-nowrap',
        classNameAction,
      )}
    >
      <span className={classNames('ltr:ml-1 rtl:mr-1')}>{`[`}</span>
      {children}
      <span className={classNames('ltr:mr-1 rtl:ml-1')}>{`]`}</span>
    </span>
  );
};
