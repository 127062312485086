import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import SwitchItems from '../../components/actions/SwitchItems';
import { ActionBar } from '../../components/nav/ActionBar';
import { allocationRunSummary } from '../store/allocation/allocation.state';
import { itemsContentViewAs } from '../store/analyze.state';
import SimulationAnalyzePanelItemsABC from './SimulationAnalyzePanelItemsABC';
import AllocationResultPanel from './allocate/AllocationResultPanel';

export type SimulationAnalyzePanelProductProps = {
  analyzeId: string;
};

const SimulationAnalyzePanelProduct: React.FC<
  SimulationAnalyzePanelProductProps
> = props => {
  const { t } = useTranslation('simulation');
  const [itemsViewAs, setItemsViewAs] = useRecoilState(itemsContentViewAs);

  const allocationRun = useRecoilValue(allocationRunSummary);
  const hasAllocationRun =
    allocationRun &&
    (allocationRun.status === 'READY' ||
      allocationRun.status === 'CALCULATING');

  return (
    <>
      <ActionBar
        stickyTop
        className={cn('bg-app-panel', 'flex items-center', 'p-1 xl:p-4')}
      >
        <SwitchItems
          selected={itemsViewAs}
          onChange={v => setItemsViewAs(v)}
          availableOptions={
            hasAllocationRun ? ['stock', 'allocate'] : ['stock']
          }
        />
      </ActionBar>

      {itemsViewAs === 'stock' && (
        <SimulationAnalyzePanelItemsABC analyzeId={props.analyzeId} />
      )}
      {itemsViewAs === 'allocate' && <AllocationResultPanel />}
    </>
  );
};

export default SimulationAnalyzePanelProduct;
