import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import InputSlider from '../../components/inputs/InputSlider';
import { InputValue } from '../../components/inputs/InputValue';
import LoadingIndicator from '../../components/LoadingIndicator';
import { BarPercentage } from '../../components/stats/BarPercentage';
import { PolicyRangeEditorProps } from './PolicyRangeEditor';

const PolicyABCRangeEditor: React.FC<PolicyRangeEditorProps> = props => {
  const { t } = useTranslation('app');

  const [isFromValid, setFromValid] = useState(true);
  const [isToValid, setToValid] = useState(true);
  const [from, setFrom] = useState<number>(null);
  const [to, setTo] = useState<number>(null);

  useEffect(() => {
    setFrom(Math.round((props.selected[0] ?? 0) * 100));
    setFromValid(true);
    setTo(Math.round((props.selected[1] ?? 1) * 100));
    setToValid(true);
  }, [props.selected]);

  const isRangeSelectionDisabled = props.selectedNullOption;

  function validateFrom(value: string) {
    if (value === '') {
      !_.isNil(props.selected[0]) &&
        props.onSelectValue([null, props.selected[1] ?? null]);
      return;
    }
    const num = parseFloat(value) / 100;
    const isValid =
      !Number.isNaN(num) &&
      num >= props.range[0] &&
      num <= (_.isNil(props.selected[1]) ? props.range[1] : props.selected[1]);
    if (isValid) {
      from !== num && props.onSelectValue([num, props.selected[1] ?? null]);
    } else {
      setFromValid(false);
    }
  }

  function validateTo(value: string) {
    if (value === '') {
      !_.isNil(props.selected[1]) &&
        props.onSelectValue([props.selected[0] ?? null, null]);
      return;
    }
    const num = parseFloat(value) / 100;
    const isValid =
      !Number.isNaN(num) &&
      num <= props.range[1] &&
      num >= (_.isNil(props.selected[0]) ? props.range[0] : props.selected[0]);
    if (isValid) {
      from !== num && props.onSelectValue([props.selected[0] ?? null, num]);
    } else {
      setToValid(false);
    }
  }

  return (
    <div
      data-component="PolicyABCRangeEditor"
      className="relative px-4 min-h-5"
    >
      {props.isLoading && (
        <LoadingIndicator
          inline
          selfCenter
          absolute
          message={t`Filtering values`}
        />
      )}

      <div className={`flex mb-4 mt-2`}>
        <div data-component="min" className={`flex-1 flex flex-col`}>
          <label
            className={`uppercase text-xxs mb-0.5 text-opacity-50`}
          >{t`From`}</label>
          <InputValue
            isNumeric={true}
            hasError={!isFromValid}
            placeholder={'0'}
            value={from ?? ''}
            onChange={v => {
              setFromValid(true);
              setFrom(v);
            }}
            onEndEdit={validateFrom}
            onCancelEdit={() => {
              setFromValid(true);
              setFrom(props.selected[0]);
            }}
            disabled={isRangeSelectionDisabled}
          />
        </div>
        <div className={`flex-1 max-w-2`}></div>
        <div data-component="max" className={`flex-1 flex flex-col`}>
          <label
            className={`uppercase text-xxs mb-0.5 text-opacity-50`}
          >{t`To`}</label>
          <InputValue
            placeholder={'100'}
            hasError={!isToValid}
            isNumeric={true}
            value={to ?? ''}
            onChange={v => {
              setToValid(true);
              setTo(v);
            }}
            onEndEdit={validateTo}
            onCancelEdit={() => {
              setToValid(true);
              setTo(props.selected[1]);
            }}
            disabled={isRangeSelectionDisabled}
          />
        </div>
      </div>

      <InputSlider
        values={[from, to]}
        min={0}
        max={100}
        stepSize={1}
        onChange={([from, to]) => props.onSelectValue([from / 100, to / 100])}
        handleSize={'xs'}
        segmentsClassNames={[
          isRangeSelectionDisabled ? 'bg-menu-500' : 'bg-menu-100',
          isRangeSelectionDisabled ? 'bg-menu-200' : 'bg-brand',
          isRangeSelectionDisabled ? 'bg-menu-500' : 'bg-menu-100',
        ]}
        hideHandlesValue
        isDisabled={isRangeSelectionDisabled}
        className={props.hasSelectNullOption ? '!mb-2 lg:!mb-2' : ''}
        hideTicks
      >
        <BarPercentage
          hasTickValue
          isDisabled={isRangeSelectionDisabled}
          className={`mt-1`}
        />
      </InputSlider>

      {props.nullOptionTitle && (
        <InputCheckbox
          className="border-t border-menu-400/50"
          isSelected={props.selectedNullOption}
          onChange={v => props.onSelectValue(props.selected, v)}
        >
          {props.nullOptionTitle}
        </InputCheckbox>
      )}
    </div>
  );
};

export default PolicyABCRangeEditor;
