import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export type ClassWrapperProps = PropsWithChildren & {
  className?: string;
};

const ClassWrapper: React.FC<ClassWrapperProps> = props => {
  return (
    <div
      data-component="ClassWrapper"
      className={classNames('', props.className)}
    >
      {props.children}
    </div>
  );
};

export default ClassWrapper;
