import * as React from 'react';
const BoxMarginedIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BoxMarginedIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="13.61 36.29 62.84 64.71 62.84 121.56 13.61 93.14 13.61 36.29"
    />
    <polygon
      strokeWidth={0}
      points="14.89 34.04 64 5.69 113.11 34.04 64 62.39 14.89 34.04"
    />
    <polygon
      strokeWidth={0}
      points="114.4 36.39 114.4 93.1 65.29 121.45 65.29 64.75 114.4 36.39"
    />
  </svg>
);
export default BoxMarginedIcon;
