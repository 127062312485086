import { useApolloClient } from '@apollo/client';
import {
  CheckPickingPolicyDocument,
  CheckPickingPolicyQuery,
  CheckPickingPolicyQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { getPickingPolicyInput } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicy,
  pickingPolicyCheckResult,
  pickingPolicyCheckResultLoadStatus,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { simulationCurrent } from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';


function useCheckPickingPolicy() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Items count for Picking policy`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(pickingPolicyCheckResultLoadStatus, AsyncLoadStatus.Loading);
  });

  const callCheck = useRecoilCallback(({ snapshot, set }) => async () => {
    const simulation = await snapshot.getPromise(simulationCurrent);
    const policy = await snapshot.getPromise(pickingPolicy);

    if (_.isNil(policy) || _.isNil(simulation)) {
      console.warn('Simulation and Picking policy should be provided ');
      return;
    }

    function handleError(details, stack) {
      set(errorAppender, {
        id: nanoid(),
        title: errorTitle,
        details: details,
        callStack: stack,
      });
      set(pickingPolicyCheckResultLoadStatus, AsyncLoadStatus.Error);
    }

    const query = client.watchQuery<
      CheckPickingPolicyQuery,
      CheckPickingPolicyQueryVariables
    >({
      query: CheckPickingPolicyDocument,
      variables: {
        input: {
          simulationId: simulation.id,
          pickingPolicy: getPickingPolicyInput(policy),
        },
      },
    });

    const queryObservable = query.subscribe(
      ({ data, errors }) => {
        if (errors) {
          console.error(errors);
          handleError(null, errors.map(e => e.message).join('. '));
          return;
        }
        const checkResult = data?.checkSimulationPickingPolicy;
        set(pickingPolicyCheckResult, checkResult);
        set(pickingPolicyCheckResultLoadStatus, AsyncLoadStatus.Ok);
      },
      error => {
        console.error(error);
        handleError(error.message || error, error.stack || null);
      },
    );
    setObservable(queryObservable);
  });

  async function call() {
    await initLoading();
    await callCheck();
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useCheckPickingPolicy;
