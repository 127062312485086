import {
  AppBillingWarehouseStateFragment,
  BillableOperationType,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Pricing from '../Pricing';
import {
  FormattedValueWithUnit,
  formatCurrencyBest,
  formatRemaining,
} from '../common/formatHelper';
import { HelperMessage } from '../components/HelperMessage';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { ContainerCol } from '../components/layout/ContainerFlex';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { ItemListCard } from '../dashboard/containers/ItemListCard';
import i18n from '../i18n';
import { TrialHelperMessage } from '../pricingPlans/TrialHelperMessage';
import { warehouseIsDemo, warehouseSelectedId } from '../store/warehouse.state';
import { AppBillingInvoiceLink } from './AppBillingInvoiceLink';
import AppBillingWarehouseStatus from './AppBillingWarehouseStatus';
import {
  convertStripeAmount,
  getWarehouseBillingSummary,
} from './store/appBilling.helper';
import { Payments, WarehouseBillingSummary } from './store/appBilling.types';

export type AppBillingActiveSubscriptionProps = {
  whState: AppBillingWarehouseStateFragment;
  payments?: Payments;
};

function getTitle(
  billingSummary: WarehouseBillingSummary,
  t: TFunction<'app'>,
): string {
  const remainingTime = formatRemaining(
    billingSummary.intervals?.[0]?.to?.getTime(),
  );
  const oneTimeAnalyze = _.find(
    billingSummary.oneTimes,
    b => b.operation === BillableOperationType.ANALYZE,
  );

  const oneTimeOptimise = _.find(
    billingSummary.oneTimes,
    b => b.operation === BillableOperationType.OPTIMIZE,
  );

  console.log(`billingSummary:::`, billingSummary);
  switch (billingSummary.status) {
    /*
     SUB
    */
    case 'hasSubscription':
      return t(`Warehouse has an active subscription for {{remainingTime}}`, {
        remainingTime,
      });

    /*
     EXPIRED SUB
    */
    case 'hasExpiredSubscription':
      return t(`Warehouse has an expired subscription.`, {
        remainingTime,
      });

    /*
     ONE TIME
    */
    case 'hasOneTime':
      if (oneTimeOptimise?.active) {
        // one time optimize is billable
        return t(`Warehouse has {{countOptimise}} optimise simulations left.`, {
          countOptimise: oneTimeOptimise?.counter ?? 0,
          countAnalyse: oneTimeAnalyze?.counter ?? 0,
          countAll:
            (oneTimeOptimise?.counter ?? 0) + (oneTimeAnalyze?.counter ?? 0),
        });
      }
      return t(`Warehouse has {{countAnalyse}} analyse simulations left.`, {
        countAnalyse: oneTimeAnalyze?.counter ?? 0,
      });

    /*
     EXPIRED ONE TIME
    */
    case 'hasExpiredOneTime':
      return t(
        `All bundled simulations have been used, please get a subscription to continue making simulations`,
        {},
      );

    /*
     TRIAL
    */
    case 'hasTrial':
      return t(`Warehouse is running a trial`, {});

    /*
     UNPAID
    */
    case 'unpaid':
      return t(`Warehouse requires an active subscription`, {});
  }
}

export const AppBillingActiveSubscription: React.FC<
  AppBillingActiveSubscriptionProps
> = props => {
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const isDemo = useRecoilValue(warehouseIsDemo);

  const navigate = useNavigate();

  const billingStatus = getWarehouseBillingSummary(props.whState);

  console.log('billingStatus', props.whState, billingStatus);
  const subscription = _.head(props.whState?.currentSubscriptions);
  const isEmptyPayments =
    _.isEmpty(props.payments?.purchases) &&
    _.isEmpty(props.payments?.subscriptions);

  const showPricingButton =
    billingStatus.status !== 'hasExpiredSubscription' &&
    billingStatus.status !== 'hasSubscription';

  const showPayLastInvoiceButton =
    billingStatus.status !== 'hasExpiredSubscription' &&
    props.payments?.subscriptions?.[0]?.latestInvoice?.status === 'open';

  const PricingButton = () => (
    <Button
      full
      className="mt-4 mb-4 rounded-full"
      buttonSize={'sm'}
      label={t`Go to Plans & Pricing`}
      buttonType="purchase"
      hasIconAfter
      buttonIcon={<IconArrowDir />}
      onPress={() => {
        navigate(`/wh/i/${whId}/pricing/`);
      }}
    />
  );

  const HostedInvoiceButton = () => (
    <a
      className={classNames(
        'py-4 px-2',
        'flex items-center',
        'flex-1',
        'text-sm ',
        'text-menu-active',
        'text-start',
        'hover:bg-app-panel hover:bg-opacity-10',
      )}
      target="_blank"
      href={props.payments?.subscriptions?.[0]?.latestInvoice?.hostedInvoiceUrl}
      rel="noreferrer"
    >
      <Button
        full
        className={classNames('mt-4 mb-4 rounded-full')}
        buttonSize={'sm'}
        label={t`Pay the last invoice`}
        buttonType="purchase"
        hasIconAfter
        buttonIcon={
          i18n.dir(i18n.language) === 'ltr' ? (
            <Icon.ArrowRight className={classNames('w-6 h-6 fill-current')} />
          ) : (
            <Icon.ArrowLeft className={classNames('w-6 h-6 fill-current')} />
          )
        }
      />
    </a>
  );

  return (
    <ContainerCol overflow componentName="AppBillingActiveSubscription">
      <ContainerScroll className="pt-0">
        {isDemo && (
          <>
            <ItemListCard
              isHero
              isHelper
              fullWidth={isDemo}
              className="flex-none"
              titleTrace={`Billing [Demo]: Plans & Pricing`}
            >
              <ScreenTitle className="text-menu-text" title={t`Get Started`} />
              <div className={classNames('p-8')}>
                <TrialHelperMessage classNameMessage="text-menu-text" />
              </div>
            </ItemListCard>
            <div className={classNames('mt-6')}>
              <ScreenTitle title={t`Plans & Pricing`} />

              <Pricing />
            </div>
          </>
        )}

        {!isDemo && (
          <div
            data-component="billing-card-container"
            id={`billing-card-container`}
            className={classNames(
              'flex flex-wrap max-w-screen p-8 w-full h-full',
            )}
          >
            <ItemListCard
              isHelper
              fullWidth={isDemo}
              titleTrace={`Billing: Plans & Pricing`}
            >
              <ScreenTitle title={getTitle(billingStatus, t)} />
              <HelperMessage hasPadding textSize={'xl'}>
                <AppBillingWarehouseStatus />
              </HelperMessage>
              {showPricingButton && <PricingButton />}
              {showPayLastInvoiceButton && <HostedInvoiceButton />}
            </ItemListCard>
            <ItemListCard titleTrace={`Billing: Payment history`}>
              <ScreenTitle title={t`Payment history`} />

              <TitleSection
                titleView
                title={t`Invoices`}
                className={classNames('mb-1 mt-8 text-xl top-10')}
              />

              <ul
                className={classNames(
                  'mb-4 divide-y divide-solid divide-menu-400/50',
                )}
              >
                {isEmptyPayments && (
                  <InboxZero message={t`No payment history`} />
                )}

                {_.map(props.payments?.subscriptions, subscription => {
                  const price = formatCurrencyBest(
                    convertStripeAmount(subscription.latestInvoice?.total ?? 0),
                    subscription.latestInvoice?.currency,
                  );
                  const isOpen = subscription.latestInvoice?.status === 'open';
                  return (
                    <AppBillingInvoiceLink
                      key={`app-payment-sub-payment-link-${subscription.id}`}
                      componentName={'Subscriptions'}
                      id={subscription.id}
                      receiptUrl={
                        isOpen
                          ? subscription.latestInvoice?.hostedInvoiceUrl
                          : subscription.latestInvoice?.receiptUrl
                      }
                      status={subscription.latestInvoice?.status}
                      title={subscription.latestInvoice?.title}
                      description={subscription.latestInvoice?.description}
                      date={
                        new Date(
                          subscription.updatedAt ?? subscription.createdAt,
                        )
                      }
                      total={price}
                      isOpen={isOpen}
                    />
                  );
                })}

                {_.map(props.payments?.purchases, purchase => {
                  let price: FormattedValueWithUnit = {
                    fullString: `####`,
                    unit: '',
                    unitCode: '',
                    raw: 0,
                    value: `—`,
                  };
                  if (!_.isNil(purchase.invoice)) {
                    price = formatCurrencyBest(
                      convertStripeAmount(purchase.invoice?.total ?? 0),
                      purchase.invoice?.currency,
                    );
                  }

                  return (
                    <AppBillingInvoiceLink
                      key={`app-payment-purchase-payment-link-${purchase.id}`}
                      componentName={'Purchases'}
                      id={purchase.id}
                      pdfUrl={purchase.invoice?.invoicePdf}
                      receiptUrl={purchase.invoice?.receiptUrl}
                      status={purchase.invoice?.status ?? purchase.status}
                      title={purchase.invoice?.title ?? purchase.title}
                      description={purchase.invoice?.description}
                      date={new Date(purchase.paidAt ?? purchase.createdAt)}
                      total={price}
                    />
                  );
                })}
              </ul>
            </ItemListCard>
          </div>
        )}
      </ContainerScroll>
    </ContainerCol>
  );
};

export default AppBillingActiveSubscription;
