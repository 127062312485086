import { useApolloClient } from '@apollo/client';
import {
  FindLocationFilterValuesDocument,
  FindLocationFilterValuesFilterInput,
  FindLocationFilterValuesQuery,
  FindLocationFilterValuesQueryVariables,
  LocationFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { POLICY_FILTER_VALUES_PAGE_SIZE } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import { cloneWithoutTypename } from '../common/utils';
import {
  policyFilterDataAtom,
  policyFilterEffectiveAssignmentId,
  policyFilterEffectiveLayoutId,
  policyFiltersSearchValues,
} from '../simulation/store/policyFilter.state';
import { errorAppender } from '../store/error.state';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from './policyFilter.helper';
import { LoadAllPolicyFilterValuesParams } from './policyFilter.types';

export type LoadLocationFilterValuesParams =
  LoadAllPolicyFilterValuesParams<LocationFilterType>;

const needUpdate = (
  t: LocationFilterType,
  params: LoadLocationFilterValuesParams,
): boolean => {
  switch (params.affect) {
    case 'self':
      return t === params.triggeredField;
    case 'others':
      return t !== params.triggeredField;
  }
};

function getFilterKeys(
  params: LoadLocationFilterValuesParams,
): { key: string; type: LocationFilterType }[] {
  return _(params.filterConfigs)
    .filter(cfg => needUpdate(cfg.type, params))
    .map(cfg => {
      const keyParts = getPolicyFilterDataKeysByEditorType(cfg.editorType);
      return _.map(keyParts, key => ({
        key: getPolicyFilterKey(cfg.type, key),
        type: cfg.type,
      }));
    })
    .flatten()
    .value();
}

function useLoadLocationFilterValues() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Location filter values.`;

  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLocationFilterValuesParams) => {
        const current = await snapshot.getPromise(policyFilterDataAtom);
        const keysToUpdate = getFilterKeys(params);

        const filterData = _.reduce(
          keysToUpdate,
          (acc, pair) => {
            return {
              ...acc,
              [pair.key]: {
                ...(acc?.[pair.key] ?? {}),
                status: AsyncLoadStatus.Loading,
              },
            };
          },
          current,
        );

        set(policyFilterDataAtom, filterData);
      },
  );

  const loadFilterValues = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadLocationFilterValuesParams) => {
        // const simulation = await snapshot.getPromise(simulationCurrent);
        const layoutId = await snapshot.getPromise(
          policyFilterEffectiveLayoutId,
        );
        const assignmentId = await snapshot.getPromise(
          policyFilterEffectiveAssignmentId,
        );

        if (_.isNil(layoutId) || _.isNil(assignmentId)) {
          console.log('Layout and Assignment should be selected');
          return null;
        }

        const searchValues = await snapshot.getPromise(
          policyFiltersSearchValues,
        );

        const current = await snapshot.getPromise(policyFilterDataAtom);

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          const keysToUpdate = getFilterKeys(params);

          const filterData = _.reduce(
            keysToUpdate,
            (acc, pair) => {
              return {
                ...acc,
                [pair.key]: {
                  totalCount: 0,
                  content: [],
                  status: AsyncLoadStatus.Error,
                },
              };
            },
            current,
          );

          set(policyFilterDataAtom, filterData);
          return;
        }

        const keysToUpdate = getFilterKeys(params);
        const filterInputs: FindLocationFilterValuesFilterInput[] = _.map(
          keysToUpdate,
          pair => {
            const filterInput: FindLocationFilterValuesFilterInput = {
              filterType: pair.type,
              query: searchValues[pair.key],
              page: {
                limit: params.limit || POLICY_FILTER_VALUES_PAGE_SIZE,
                skip: params.skip || 0,
              },
            };
            return filterInput;
          },
        );

        // const includeMatchValues =
        //   params.filterIntersection?.allOf?.filter(
        //     item => item.type !== params.triggeredField,
        //   ) ?? [];

        const query = client.watchQuery<
          FindLocationFilterValuesQuery,
          FindLocationFilterValuesQueryVariables
        >({
          query: FindLocationFilterValuesDocument,
          variables: {
            layoutId,
            input: {
              filters: filterInputs,
              includeMatching: cloneWithoutTypename(params.filterIntersection),
              //{
              //   allOf: cloneWithoutTypename(includeMatchValues),
              // },
            },
          },
        });

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const filterValues = data.layout?.locationFilterValues;

            const filtersData = _.reduce(
              keysToUpdate,
              (acc, pair, index) => {
                const filterData = filterValues[index];
                const values = filterData?.content;
                return {
                  ...acc,
                  [pair.key]: {
                    totalCount: filterData.totalCount,
                    status: AsyncLoadStatus.Ok,
                    content: params.isAppend
                      ? [...(acc?.[pair.key]?.content ?? []), ...values]
                      : values,
                  },
                };
              },
              current,
            );

            set(policyFilterDataAtom, filtersData);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadLocationFilterValuesParams) {
    await initLoading(params);
    await loadFilterValues(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadLocationFilterValues;
