import * as React from 'react';
const CursorZoomOutIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorZoomOutIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M113.45,112.61l-29.13-30.3c7.43-7.45,12.03-17.73,12.03-29.06,0-22.71-18.47-41.18-41.18-41.18S14,30.54,14,53.25s18.47,41.18,41.18,41.18c9.97,0,19.11-3.56,26.24-9.48l29.21,30.37c.38.4.9.6,1.41.6s.98-.18,1.36-.55c.78-.75.81-1.99.05-2.77ZM68.9,57.17h-27.45c-2.17,0-3.92-1.76-3.92-3.92s1.76-3.92,3.92-3.92h27.45c2.17,0,3.92,1.76,3.92,3.92s-1.76,3.92-3.92,3.92Z"
    />
  </svg>
);
export default CursorZoomOutIcon;
