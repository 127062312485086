import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import LayoutFilter from '../components/LayoutFilter';
import {
  viewerSelectedAisle,
  viewerSelectedAisleIdAtom,
  viewerSelectedBay,
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdAtom,
} from './viewer/store/viewer.state';

const LayoutActiveFeatureTag: React.FC = () => {
  const { t } = useTranslation('app');
  const aisle = useRecoilValue(viewerSelectedAisle);
  const resetAisle = useResetRecoilState(viewerSelectedAisleIdAtom);
  const bay = useRecoilValue(viewerSelectedBay);
  const resetBay = useResetRecoilState(viewerSelectedBayIdAtom);
  const locationId = useRecoilValue(viewerSelectedLocationIdAtom);
  const resetLocation = useResetRecoilState(viewerSelectedLocationIdAtom);
  //Draw Items in order: [AISLE] [BAY] [Location]
  const showAisle = aisle && !bay && !locationId;
  const showBay = bay && !locationId;
  const showLocation = !!locationId;

  const resetAll = () => {
    resetLocation();
    resetBay();
    resetAisle();
  };

  return (
    <>
      {showAisle && (
        <LayoutFilter
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`Aisle`}
          onRemoveClick={resetAll}
          type="selection"
        >
          {aisle?.title}
        </LayoutFilter>
      )}
      {showBay && (
        <LayoutFilter
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`Bay`}
          onRemoveClick={resetAll}
          type="selection"
        >
          {bay.title}
        </LayoutFilter>
      )}
      {showLocation && (
        <LayoutFilter
          className={'ltr:ml-1 rtl:mr-1'}
          filterName={t`L.`}
          onRemoveClick={resetAll}
          type="selection"
        >
          {locationId}
        </LayoutFilter>
      )}
    </>
  );
};

export default () => (
  <Suspense>
    <LayoutActiveFeatureTag />
  </Suspense>
);
