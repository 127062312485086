import {
  LayoutLocationsSearchFilter,
  LoadLayoutDataDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  LayoutDataConverterConfig,
  LayoutDataRow,
  LayoutLocationsExportJobParams,
  getLayoutLocationTableRows,
  layoutLocationsExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  booleanFilter,
  containsFilter,
  numberFilter,
} from '../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import useLoadLayoutData from './hooks/useLoadLayoutData';
import { getPortalTypeDictionary } from './store/layout.helper';
import {
  layoutItemsData,
  layoutItemsDataState,
  layoutItemsDataStatus,
} from './store/layout.state';
import useLayoutDataTableConfig from './useLayoutDataTableConfig';

const LayoutDataTable: React.FC<
  DatasetDataViewProps<LayoutDataRow>
> = props => {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('dataset');
  const data = useRecoilValue(layoutItemsData);
  const status = useRecoilValue(layoutItemsDataStatus);
  const [dataState, setDataState] = useRecoilState(layoutItemsDataState);
  const [runExportCSV] = useRunExportJobMutation();
  const [loadCallback, cancelLoad] = useLoadLayoutData();
  const columnsConfig = useLayoutDataTableConfig();

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filter: LayoutLocationsSearchFilter = {
      locationId: containsFilter(searchValues['locationId']),
      warehouseArea: containsFilter(searchValues['warehouseArea']),
      locationUsageType: containsFilter(searchValues['locationUsageType']),
      locationBayPosition: numberFilter(searchValues['locationBayPosition']),
      //  locmhtype: containsFilter(searchValues['locmhtype']),
      locationRackingType: containsFilter(searchValues['locationRackingType']),
      locationOrder: numberFilter(searchValues['locationOrder']),
      locationStatus: booleanFilter(searchValues['locationStatus']),
      locationLevel: numberFilter(searchValues['locationLevel']),

      locationLength: numberFilter(searchValues['locationLength']),
      locationWidth: numberFilter(searchValues['locationWidth']),
      locationHeight: numberFilter(searchValues['locationHeight']),
      locationWeight: numberFilter(searchValues['locationWeight']),
      // congestionZone: numberFilter(searchValues['congestionZone']),
      // locationHeightFromFloor: eqFilter(
      //   searchValues['locationHeightFromFloor'],
      // ),
    };

    cancelLoad();
    loadCallback({
      layoutId: props.id,
      filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props?.id, dataState.searchValues]);

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  async function startExportCSV() {
    const variables: LayoutLocationsExportJobParams = {
      ...layoutLocationsExportJobParams,
      query: LoadLayoutDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        layoutId: props.id,
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    //
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  const converterConfig: LayoutDataConverterConfig = {
    columnsConfig,
    dictionaries: {
      portalTypes: getPortalTypeDictionary(tApp),
    },
  };

  const flattenData = getLayoutLocationTableRows(data, converterConfig);

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;
  const totalCount = dataState?.totalCount ?? 0;
  return (
    <DatasetTable
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Layout`}
      title={props.title}
      isSticky
      id={props?.id}
      columnsConfig={columnsConfig}
      keyFields={['locationId']}
      data={flattenData}
      onLoadNext={onLoadNext}
      onSearch={v => {
        setDataState({ ...dataState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={dataState.searchValues}
      isLoading={isLoading}
      onStartExportClick={startExportCSV}
    />
  );
};

export default LayoutDataTable;
