import Flatten from '@flatten-js/core';
import {
  AislePortal,
  BayPortal,
  MeasurementSystem,
  Point,
} from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import React from 'react';
import { KonvaNodeEvents, Path } from 'react-konva';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TwTheme } from '../../../../../Tw';
import PortalFeature from '../../../../layout/features/PortalFeature';
import { warehouseMeasurementSystem } from '../../../../store/warehouse.state';
import { ConvertedAreaFeature } from '../converter.serializable.model';
import { converterSelectedFloor } from '../store/converter.area.state';
import {
  converterLayoutShowPortals,
  converterSelectedPortal,
} from '../store/converter.layout.state';
import { converterLayoutBuilder } from '../store/converter.state';
export type ConverterPortalLayerProps = {
  area: ConvertedAreaFeature;
};

const iconOffset = 64;

const ConverterPortalByArea: React.FC<ConverterPortalLayerProps> = props => {
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);
  const floor = useRecoilValue(converterSelectedFloor);
  const showPortals = useRecoilValue(converterLayoutShowPortals);
  const [layoutBuilder, setLayoutBuilder] = useRecoilState(
    converterLayoutBuilder,
  );

  const [selected, setSelected] = useRecoilState(converterSelectedPortal);

  if (!showPortals) return null;

  const entryPoint = layoutBuilder.entryPoints?.[floor];

  const scaleFactor = measureSystem === MeasurementSystem.IMPERIAL ? 0.4 : 1;

  const waypointColors = TwTheme.extend.colors.route;

  function getEntryPointConfig(p: Point): Konva.PathConfig {
    return {
      x: p.x,
      y: p.y,
      offsetX: iconOffset,
      offsetY: iconOffset,
      // radius: 30,
      data: 'M64,4a60,60,0,1,0,60,60A60.07,60.07,0,0,0,64,4ZM48.25,28.13,64,12.39,79.75,28.13V40L68.2,28.46V51.55H59.8V28.46L48.25,40Zm-19.47,51L13,63.34,28.78,47.6H40.65L29.11,59.14H52.2v8.4H29.11L40.65,79.1Zm51,19.46L64,114.3,48.25,98.57V86.7L59.8,98.24V75.15h8.4V98.24L79.75,86.7ZM99.22,79.1H87.35L98.89,67.55H75.8V59.14H98.89L87.35,47.6H99.22L115,63.35Z',
      draggable: false,
      fill: waypointColors.start,
      stroke: '#AAAAAA',
      strokeScaleEnabled: false,
      strokeWidth: 0,
      perfectDrawEnabled: false,
      scale: { x: scaleFactor, y: scaleFactor },
    };
  }

  function getEventsConfig(id1: string, id2: string): KonvaNodeEvents {
    const isSelected =
      selected && id1 === selected?.Id1 && id2 === selected?.Id2;
    return {
      onClick: () => {
        const pointToSet = isSelected ? null : { Id1: id1, Id2: id2 };

        setLayoutBuilder({
          ...layoutBuilder,
          entryPoints: {
            ...layoutBuilder.entryPoints,
            [floor]: pointToSet,
          },
        });
        setSelected(pointToSet);
      },
    };
  }

  const allPortals = [...props.area.aislePortals, ...props.area.bayPortals];
  return (
    <>
      {allPortals.map((p, index) => {
        const id1 = (p as AislePortal)?.aisleId1 ?? (p as BayPortal)?.aisleId;
        const id2 = (p as AislePortal)?.aisleId2 ?? (p as BayPortal)?.bayId;

        if (!id1 || !id2) return null;
        const isSelected =
          selected && id1 === selected?.Id1 && id2 === selected?.Id2;
        const isEntryPoint =
          entryPoint && id1 === entryPoint?.Id1 && id2 === entryPoint?.Id2;

        if (isEntryPoint) {
          const center = new Flatten.Segment(
            new Flatten.Point([p.coords.start.x, p.coords.start.y]),
            new Flatten.Point([p.coords.end.x, p.coords.end.y]),
          ).middle();
          return (
            <Path
              key={`${id1}-${id2}`}
              {...getEntryPointConfig(center)}
              {...getEventsConfig(id1, id2)}
            />
          );
        }

        return (
          <PortalFeature
            key={`portal-${id1}-${id2}-${index}`}
            portal={p.coords}
            isSelected={isSelected}
            withCancelClickBubble={true}
            scaleFactor={scaleFactor}
            {...getEventsConfig(id1, id2)}
          />
        );
      })}
    </>
  );
};

export default ConverterPortalByArea;
