import {
  createUnionType,
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { IsNotEmpty, IsString } from '@warego/class-validator';
import {
  AnalyzeActualityJobInput,
  AnalyzeActualityJobOutput,
} from '../engine-api';

export enum ProcessingPipelineType {
  ANALYZE_ACTUALITY = 'ANALYZE_ACTUALITY',
}

registerEnumType(ProcessingPipelineType, {
  name: 'ProcessingPipelineType',
});

@InputAndObjectType()
export class AnalyzeActualityPipelineSettings {
  @Field(() => ID)
  @IsNotEmpty()
  @IsString()
  actualityId: string;
}

export const ProcessingPipelineSettingsUnion = createUnionType({
  name: 'ProcessingPipelineSettings',
  types: () => [AnalyzeActualityPipelineSettings],
});

export type ProcessingPipelineSettings = AnalyzeActualityPipelineSettings;

export const ProcessingPipelineJobInputUnion = createUnionType({
  name: 'ProcessingPipelineJobInput',
  types: () => [AnalyzeActualityJobInput],
});

export type ProcessingPipelineJobInput = AnalyzeActualityJobInput;

export const ProcessingPipelineJobOutputUnion = createUnionType({
  name: 'ProcessingPipelineJobOutput',
  types: () => [AnalyzeActualityJobOutput],
});

export type ProcessingPipelineJobOutput = AnalyzeActualityJobOutput;
