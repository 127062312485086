import { useDateSegment } from '@react-aria/datepicker';
import { DateFieldState, DateSegment } from '@react-stately/datepicker';
import classNames from 'classnames';
import { useRef } from 'react';

export type DateSegmentProps = {
  segment: DateSegment;
  state: DateFieldState;
};

const DateSegmentField: React.FC<DateSegmentProps> = ({ segment, state }) => {
  const ref = useRef();
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      data-component="DateSegment"
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue != null && String(segment.maxValue).length + '.75ch',
      }}
      className={classNames(
        'group',
        'box-content tabular-nums',
        'text-end',
        'outline-none',
        'rounded-sm',
        'focus:bg-menu-active focus:text-menu-active-text',
        'flex-1',
        !segment.isEditable ? 'opacity-50' : 'px-px xl:px-0.5',
      )}
      // className={` ${!segment.isEditable ? 'text-menu-200' : 'text-menu-active'}`}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className={classNames('block w-full text-center')}
        style={{
          visibility: segment.isPlaceholder ? undefined : 'hidden',
          height: segment.isPlaceholder ? '' : 0,
          pointerEvents: 'none',
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
};

export default DateSegmentField;
