import {
  Field,
  GraphQLJSON,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { IsNotEmpty } from '@warego/class-validator';

@InputType({ isAbstract: true })
export class CreateBaseEntityInput {
  @Field()
  @IsNotEmpty()
  title: string;

  @Field({ nullable: true })
  description?: string;

  @Field(() => GraphQLJSON, { nullable: true })
  extraSettings?: any;
}
