import * as React from 'react';
const CircleEmailIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleEmailIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2c-16.44,0-32.21,6.53-43.84,18.16C8.53,31.79,2,47.56,2,64s6.53,32.21,18.16,43.84c11.63,11.63,27.4,18.16,43.84,18.16s32.21-6.53,43.84-18.16c11.63-11.63,18.16-27.4,18.16-43.84s-6.53-32.21-18.16-43.84C96.21,8.53,80.44,2,64,2ZM26.51,40.04c.28-1.34.88-2.58,1.76-3.63.99-1.25,2.5-1.97,4.1-1.96h62.29c1.63-.06,3.22.47,4.48,1.5,1.26,1.02,2.12,2.47,2.4,4.07v.18c.3,2.49-.33,5-1.76,7.05-1.59,2.39-3.64,4.44-6.02,6.02-6.97,4.69-13.93,9.55-20.85,14.42-1.92,1.49-3.99,2.77-6.18,3.83-1.76.84-3.82.81-5.56-.09-1.91-1.01-3.76-2.13-5.54-3.36-5.93-4.02-11.82-8.11-17.68-12.28-2.72-1.67-5.24-3.64-7.53-5.86-1.68-1.64-2.94-3.66-3.68-5.89-.39-1.3-.47-2.67-.23-4.01h0ZM94.75,93.61h-61.56c-1.83-.01-3.57-.75-4.86-2.05-1.28-1.3-2-3.05-1.98-4.88v-33.35c3.09,3.42,6.91,5.44,10.41,7.89,5.95,4.22,11.93,8.38,17.9,12.58,1.85,1.3,3.88,2.31,6.03,3.01,2.19.68,4.54.65,6.72-.09,2.32-.77,4.51-1.91,6.48-3.36,7.74-5.42,15.55-10.79,23.29-16.22,1.51-1.07,2.89-2.32,4.4-3.54v4.76c0,9.45-.08,18.89,0,28.32h0c-.03,1.81-.75,3.54-2.02,4.83-1.27,1.29-2.98,2.04-4.79,2.1h0Z"
    />
  </svg>
);
export default CircleEmailIcon;
