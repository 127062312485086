
const WareBeeSymbolLinesLightLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 599.87 477.77"
    {...props}
  >
    <path
      d="M299.94,402.55l78.12,45.1c9,5.2,23.73,5.2,32.73,0L567,357.44c9-5.2,16.37-17.95,16.37-28.35V148.68c0-10.39-7.36-23.15-16.37-28.35L410.79,30.12c-9-5.19-23.73-5.19-32.73,0L111,184.33"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M536.16,157.06,410.79,84.68c-9-5.2-23.73-5.2-32.73,0L158.2,211.61"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <line
      x1={252.69}
      y1={47.95}
      x2={63.72}
      y2={157.06}
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M347.18,375.27l30.88,17.83c9,5.19,23.73,5.19,32.73,0L583.4,293.44"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M299.94,75.23,221.81,30.12c-9-5.19-23.73-5.19-32.73,0L32.84,120.33c-9,5.2-16.37,18-16.37,28.35V329.09c0,10.4,7.37,23.15,16.37,28.35l156.24,90.21c9,5.2,23.73,5.2,32.73,0l109-62.93,32.74-18.9L583.4,238.89"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M583.4,184.33,221.81,393.1c-9,5.19-23.73,5.19-32.73,0L16.47,293.44"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M16.47,238.89l172.61,99.65c9,5.2,23.74,5.21,32.75,0L581.4,131.78"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M441.67,157.06,221.81,284c-9,5.2-23.73,5.2-32.73,0L16.47,184.33"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
    <path
      d="M19.47,132.78,189,229.52c9,5.15,23.78,5.11,32.78-.08l156.25-90.21c9-5.2,23.73-5.2,32.73,0l78.12,45.1"
      fill="none"
      stroke="#0f0f1b"
      strokeMiterlimit={10}
      strokeWidth={10}
    />
  </svg>
);

export default WareBeeSymbolLinesLightLogo;
