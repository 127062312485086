import { InferResult } from 'kysely';
import _ from 'lodash';
import { toDateFromLocaleStringDate } from '../../../common/dateTimeHelper';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';

const feedDateRangeSelect = datasetQueryBuilder
  .selectFrom('___activity_feed_iceberg___')
  .select(({ fn }) => [
    fn.min('eventEndTime').as('minTime'),
    fn.max('eventEndTime').as('maxTime'),
  ]);

export type FeedDateRanges = InferResult<typeof feedDateRangeSelect>;
export type FeedDateRange = FeedDateRanges[number];

export const getFeedDateRangeQuery = (datasetId: string) => {
  return feedDateRangeSelect
    .where('datasetObjectId', '=', datasetId)
    .where('locationId', 'is not', null);
};

export function postProcessFeedDateRange(
  events: Record<string, any>[],
): FeedDateRange {
  return _.reduce(
    _.head(events),
    (acc, value, key) => {
      return {
        ...acc,
        [_.camelCase(key)]: toDateFromLocaleStringDate(value as string),
      };
    },
    {} as FeedDateRange,
  );
}
