import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { HelperImporterStart } from '../sidebar/HelperImporter';

const ImportAssignmentStartPanel: React.FC = () => {
  const { t } = useTranslation('importer');
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Start`}
        subtitle={t`Assignment import`}
        hasStepCounter={'1.'}
      />
      <HelperImporterStart title={t`assignment`} />
    </PanelBody>
  );
};

export default ImportAssignmentStartPanel;
