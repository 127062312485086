import * as React from 'react';
const XlsIcon = props => (
  <svg
    aria-hidden="true"
    data-name="XlsIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <polygon points="126.65 83.33 141.28 76.25 155.92 83.33 141.28 90.46 126.65 83.33" />
      <polygon points="126.65 61.33 141.28 54.25 155.92 61.33 141.28 68.46 126.65 61.33" />
      <polygon points="126.65 39.33 141.28 32.25 155.92 39.33 141.28 46.46 126.65 39.33" />
      <polygon points="126.65 17.33 141.28 10.25 155.92 17.33 141.28 24.46 126.65 17.33" />
      <polygon points="126.65 -4.67 141.28 -11.75 155.92 -4.67 141.28 2.46 126.65 -4.67" />
    </g>
    <g>
      <path d="m16.19,4c-.52,0-.94.42-.94.94v59.06h97.49v-30h-29.06c-.52,0-.94-.42-.94-.94V4H16.19Zm70.31,0v26.25h26.25l-26.25-26.25ZM15.25,109v14.07c0,.52.42.94.94.94h95.62c.52,0,.94-.42.94-.94v-14.07H15.25Z" />
      <g>
        <path d="m37.21,102.51h-6.29c-.37,0-.59-.25-.78-.56l-3.35-5.52-2.98,5.46c-.19.34-.4.62-.74.62h-5.8c-.25,0-.4-.19-.4-.37,0-.09.03-.16.06-.25l6.33-11.41-6.26-10.42c-.03-.06-.06-.16-.06-.22,0-.22.16-.37.4-.37h6.33c.34,0,.56.31.74.59l3.19,5.27,2.73-5.27c.16-.28.4-.59.74-.59h5.83c.25,0,.4.15.4.37,0,.06-.03.16-.06.22l-6.23,10.98,6.51,10.85c.06.09.09.19.09.28,0,.19-.16.34-.4.34Z" />
        <path d="m53.42,102.41c-1.12.22-3.22.37-4.87.37-3.97,0-7.97-.93-7.97-6.95v-15.75c0-.34.31-.62.65-.62h5.05c.34,0,.62.28.62.62v15.75c0,1.21.37,1.55,1.49,1.55h5.02c.34,0,.62.28.62.62v3.78c0,.37-.22.56-.62.62Z" />
        <path d="m63.49,102.79c-2.54,0-4.62-.34-6.26-.81-.31-.09-.65-.28-.65-.62v-3.81c0-.43.19-.65.65-.62,1.33.12,4.65.46,6.29.46,1.52,0,1.95-.59,1.95-1.58,0-.62-.31-.96-1.46-1.64l-4.46-2.64c-2.91-1.71-3.69-3.81-3.69-6.2,0-3.38,1.86-6.17,8.12-6.17,2.45,0,5.77.4,7.04.78.28.09.56.25.56.59v3.88c0,.34-.12.62-.62.59-1.27-.09-4.81-.4-7.04-.4-1.15,0-1.55.4-1.55,1.05,0,.56.28.87,1.61,1.58l4.19,2.23c3.01,1.61,4.09,3.66,4.09,6.23,0,3.47-2.39,7.1-8.77,7.1Z" />
      </g>
    </g>
    <rect width={97.49} height={45} x={15.25} y={64} opacity={0.4} />
  </svg>
);
export default XlsIcon;
