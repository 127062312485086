import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useUpdateSimulation from '../../simulation/hooks/useUpdateSimulation';
import {
  resourcePolicy,
  resourcePolicySelectedAgent,
} from '../../simulation/store/resourcePolicy.state';
import {
  simulationIsEditable,
  simulationUoms,
} from '../../simulation/store/simulation.state';
import ResourcePolicyAgentView from './ResourcePolicyAgentView';

const ResourcePolicyAgentViewContainer: React.FC = () => {
  const policy = useRecoilValue(resourcePolicy);
  const isEditable = useRecoilValue(simulationIsEditable);
  const [agent, setAgent] = useRecoilState(resourcePolicySelectedAgent);
  const uoms = useRecoilValue(simulationUoms);
  const updateSim = useUpdateSimulation();
  const firstMount = useRef(true);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
    } else {
      updateSim({ resourcePolicy: policy });
    }
  }, [policy]);

  return (
    <ResourcePolicyAgentView
      agent={agent}
      setAgent={setAgent}
      editMode={isEditable ? 'full' : 'disabled'}
      uoms={uoms}
    />
  );
};

export default ResourcePolicyAgentViewContainer;
