import _ from 'lodash';
import React from 'react';
import { FormattedValueWithUnit } from '../common/formatHelper';
import * as Icon from '../components/icons';

export type AppBillingInvoiceLinkProps = {
  id?: string;
  className?: string;
  hostedLink?: string;
  pdfUrl?: string;

  receiptUrl?: string;
  status?: string;
  title?: string;
  description?: string;
  total?: FormattedValueWithUnit;
  date?: Date;
  children?: React.ReactNode;
  componentName?: string;
  isOpen?: boolean;
};

export const AppBillingInvoiceLink = ({
  children,
  className,
  id,
  pdfUrl,
  receiptUrl,
  status,
  title,
  description,
  total,
  date,
  componentName,
  isOpen,
}: AppBillingInvoiceLinkProps) => {
  return (
    <li
      data-component={`InvoiceLink${componentName ? '-' + componentName : ''}`}
      className={`flex cursor-pointer`}
      key={`invoice-${id}`}
    >
      <a
        className={`py-4 px-2 flex items-center text-sm text-menu-active flex-1 text-start hover:bg-app-panel hover:bg-opacity-10 ${
          className || ''
        }`}
        target="_blank"
        href={receiptUrl}
        rel="noreferrer"
      >
        <span
          className={`px-2 py-0.5 ltr:mr-2 rtl:ml-2 text-xs font-bold rounded flex items-center uppercase ${
            status === 'paid'
              ? 'bg-alerts-okay text-menu-text'
              : 'bg-menu-active text-menu-600'
          }
          `}
        >
          {status}
        </span>

        <span className="mr-2 text-menu-100">{date.toLocaleDateString()}</span>

        <div className="text-sm text-menu-active flex-1 flex items-center">
          <span className="">{title}</span>
          <span className="ltr:ml-2 rtl:mr-2 text-menu-100">{description}</span>
        </div>

        <div className="text-sm text-menu-active flex-1 text-end ltr:ml-2 rtl:mr-2 flex">
          {total && (
            <span className="ltr:ml-2 rtl:mr-2 text-menu-100 flex-1">
              {total.fullString}
            </span>
          )}
          {!_.isNil(receiptUrl) && (
            <span
              className={`p-1 bg-app-panel rounded-xl ltr:ml-2 rtl:mr-2 flex items-center hover:bg-menu-hover hover:text-menu-active-text`}
            >
              <Icon.ArrowBottom className="w-3 h-3 mx-1 fill-current" />
            </span>
          )}
        </div>
      </a>
    </li>
  );
};
