import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import EventNotificationVideo from './EventNotificationVideo';

const CCTVPanel: React.FC = props => {
  const { t } = useTranslation('simulation');

  return (
    <ContainerScroll className="flex flex-col">
      <div className="flex py-2">
        <svg
          id="a"
          data-name="Icons"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 153.42"
          className="fill-current  w-10 h-10 xl:w-12 xl:h-12 ltr:ml-1 rtl:mr-2 xl:ltr:ml-2 xl:rtl:mr-4"
        >
          <path
            d="m60.32,15.51v130.99L5.4,114.86,60.32,15.51m4-15.51L0,116.37l64.32,37.05V0h0Z"
            fill="#ececec"
          />
          <path
            d="m107.9,127.95l-44.22,25.47V0h0l52.92,96.2-6.06,3.35L63.68,14.72v130.19l40.77-22.95m14.21-8.18"
            fill="#ececec"
          />
          <polyline
            points="118.65 113.77 112.94 103.7 119 100.35 128 116.37 111.92 125.63 108.47 119.63 118.65 113.77"
            fill="#00b8d3"
          />
        </svg>
        <div className="text-sm md:text-base lg:text-lg xl:text-2xl flex flex-col items-start text-menu-active-cctv pl-2">
          <div className="text-sm">{t`CCTV`}</div>
          <div className="text-sm md:text-base lg:text-lg xl:text-2xl flex items-center">{t`Alerts Feed`}</div>
        </div>
      </div>

      <div className="flex flex-col space-y-2">
        <EventNotificationVideo
          title="Near-Miss with Forklift"
          iframeSrc="https://drive.google.com/file/d/1P8Pmq9j5Coz2s8eF5eDT8u2wpcequnxL/preview"
          alertCategory="Unsafe Movement"
          alertClassification="medium"
          alertConfidence="92%"
          alertSource="4"
          time="22/02/2023 15:40"
        />
        <EventNotificationVideo
          title="Non truck vehicles in the loading dock"
          iframeSrc="https://drive.google.com/file/d/1I63OaOE_N9hzntWDvl6RBtSOllcCfmKX/preview"
          alertCategory="Non Compliance"
          alertClassification="Low"
          alertConfidence="96%"
          alertSource="6"
          time="21/02/2023 09:40"
        />
        <EventNotificationVideo
          title="Loading dock door blocked"
          iframeSrc="https://drive.google.com/file/d/1lIi6QfLcgz1Wa_4CTq9LhyR6ydg1q9nm/preview"
          alertCategory="Unsafe Movement"
          alertClassification="medium"
          alertConfidence="98%"
          alertSource="6"
          time=" 21/02/2023 16:25"
        />
        <EventNotificationVideo
          title="Loading dock door unblocked"
          iframeSrc="https://drive.google.com/file/d/1g0Z1cBKV2dsVyMNVlEPGHY2mmykNRZVR/preview"
          alertCategory="unblocked"
          alertClassification="medium"
          alertConfidence="98%"
          alertSource="6"
          time="21/02/2023 15:25"
        />
      </div>
    </ContainerScroll>
  );
};

export default CCTVPanel;
