import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import naturalCompare from 'string-natural-compare';
import { analyticsAgent } from '../../AnalyticTracker';
import { cn } from '../../common/utils';
import { Counter } from '../../components/Counter';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import { ButtonToolbarWrapper } from '../../components/actions/ButtonToolbarWrapper';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { LogoHeader } from '../../components/layout/LogoHeader';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import { collapsibleState } from '../../store/collapsible.state';

export type ViewModeToolbarCmpProps = {
  planeCustomTitle?: string;
  planes: { id: string; title?: string }[];
  selectedPlaneId: string;
  onPlaneChange: (id: string) => void;
  showLocations: boolean;
  toggleShowLocations: () => void;
  isLocationsLoading: boolean;
  isBaysLoading?: boolean;
  levels: number[];
  setSelectedLevel: (l: number) => void;
  selectedLevel: number;
  adjustStageCallback?: VoidFunction;
  levelIssues?: Record<number, number>;
};

const ViewModeToolbarCmp: React.FC<ViewModeToolbarCmpProps> = props => {
  const { t } = useTranslation('app');
  const areaState = useRecoilValue(collapsibleState('panel-toolbar-floor'));

  function onLevelChange(level: number) {
    props.setSelectedLevel(level);
    analyticsAgent?.track('Location: Level Change (Click)', {
      level: level,
      viewer: 'main',
    });
  }

  const levels = [...(props.levels ?? [])].sort((l1, l2) =>
    naturalCompare(l1?.toString() ?? '', l2?.toString() ?? ''),
  );

  return (
    <>
      {props.planes.length > 1 && (
        <ButtonToolbarGroup
          data-component="ViewModeToolbarCmp"
          className={classNames(
            'absolute z-[3002]',
            'ltr:right-1 rtl:left-1',
            'top-1',
            'mb-2 flex flex-col',
          )}
        >
          <TitleToolbarContainer
            id="panel-toolbar-floor"
            collapsible
            title={props.planeCustomTitle ?? t`Floor`} // {`Area`}
            classNameContainerBodyChildren="p-1"
          >
            <ButtonToolbarWrapper
              className={cn('!m-0 !p-0', 'w-full')}
              selected={props.planes.length <= 1}
            >
              <DropdownSelector
                className={cn('text-menu-text text-sm', 'h-full', 'w-full')}
                classNameValue={cn('h-full')}
                values={_.map(props.planes, p => p.id)}
                value={props.selectedPlaneId}
                onChange={planeId => props.onPlaneChange(planeId)}
                renderValue={planeId =>
                  _.find(props.planes, p => p.id === planeId)?.title ?? planeId
                }
                buttonTransparent
                headerMode
                DropAlignRight
                disabled={props.planes.length <= 1}
              />
            </ButtonToolbarWrapper>
          </TitleToolbarContainer>
        </ButtonToolbarGroup>
      )}

      <ButtonToolbarGroup
        className={classNames(
          'absolute z-[3001]',
          'top-1 ltr:right-1 rtl:left-1',
          'flex flex-col',
          areaState?.isCollapsed
            ? 'top-8'
            : props.planes.length <= 1
              ? 'top-1'
              : 'top-20',
        )}
      >
        <TitleToolbarContainer
          id="panel-toolbar-bays"
          title={t`Bay`}
          collapsible
        >
          <ButtonToolbar
            titleTrace={`Bay`}
            isSelected={!props.showLocations}
            onChange={props.toggleShowLocations}
            className={classNames('w-full flex-1')}
            isLoading={props.isBaysLoading}
          >
            <Icon.Bay className={classNames('h-7 w-7')} />
          </ButtonToolbar>
        </TitleToolbarContainer>
        <TitleToolbarContainer
          id="panel-toolbar-levels"
          title={t`Level`}
          collapsible
        >
          <div className={classNames('flex flex-col')}>
            {levels.length > 12 ? (
              <ButtonToolbarWrapper>
                <DropdownSelector
                  DropDown
                  DropAlignRight
                  widthFull={props.levelIssues?.[props.selectedLevel] == null}
                  classNameDropDownMenu="w-[5.5rem]"
                  w_xs={props.levelIssues?.[props.selectedLevel] == null}
                  className="text-sm"
                  values={levels}
                  value={props.selectedLevel}
                  renderValue={level => (
                    <>
                      {level}
                      {props.levelIssues?.[level] && (
                        <Counter
                          className={classNames(
                            'bg-alerts-alert text-menu-text ltr:ml-2 rtl:mr-2',
                          )}
                        >
                          {props.levelIssues?.[level]}
                        </Counter>
                      )}
                    </>
                  )}
                  renderValueSelected={level => (
                    <>
                      {level}
                      {props.levelIssues?.[level] && (
                        <Counter
                          className={classNames(
                            'bg-alerts-alert -right-[10px] -top-[15px] rounded-md',
                          )}
                        >
                          {props.levelIssues?.[level] > 999 ? (
                            <Icon.MenuDotsHorizontal
                              className={`h-4 w-4 fill-current`}
                            />
                          ) : (
                            props.levelIssues?.[level]
                          )}
                        </Counter>
                      )}
                    </>
                  )}
                  onChange={l => props.setSelectedLevel(l)}
                />
              </ButtonToolbarWrapper>
            ) : (
              <>
                {levels.map(level => {
                  const showLoading =
                    props.showLocations &&
                    props.selectedLevel === level &&
                    props.isLocationsLoading;

                  return (
                    <ButtonToolbar
                      titleTrace={`Level`}
                      key={`level-btn-${level}`}
                      // isBoxed
                      isFullWidth
                      className="relative w-full flex-1"
                      buttonSize={
                        levels.length > 9
                          ? `text-sm w-8 h-8`
                          : `w-9 h-9 lg:w-10 lg:h-10 xl:w-12 xl:h-12`
                      }
                      isSelected={
                        props.showLocations && props.selectedLevel === level
                      }
                      isDisabled={showLoading}
                      onChange={() => !showLoading && onLevelChange(level)}
                      isLoading={showLoading}
                    >
                      {level}
                      {props.levelIssues?.[level] && (
                        <Counter
                          right
                          className={classNames(
                            'bg-alerts-alert text-alerts-alertText',
                          )}
                        >
                          {props.levelIssues?.[level]}
                        </Counter>
                      )}
                    </ButtonToolbar>
                  );
                })}
              </>
            )}
          </div>
        </TitleToolbarContainer>
      </ButtonToolbarGroup>

      <ButtonToolbarGroup
        className={classNames(
          'z-[5001]',
          'absolute',
          'bottom-1',
          'ltr:right-1 rtl:left-1',
          'isolate',
        )}
        //  z-[5001] to be above the Alert of z-5000
      >
        {props.adjustStageCallback && (
          <ButtonToolbar
            titleTrace={`Reset Zoom`}
            onChange={props.adjustStageCallback}
          >
            <Icon.FitToBox
              className={classNames('text-menu h-8 w-8')}
              alt={t`Reset zoom and position`}
              title={t`Reset zoom and position`}
            />
          </ButtonToolbar>
        )}
      </ButtonToolbarGroup>
      <div
        className={classNames(
          'z-menu',
          'absolute',
          'bottom-1',
          'ltr:left-1 rtl:right-1',
          'isolate',
        )}
      >
        <LogoHeader embeded logoFull logoSize={'xs'} navTo={'/'} />
      </div>
    </>
  );
};

export default React.memo(ViewModeToolbarCmp);
