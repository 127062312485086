import { useSaveLayoutImportConverterStateMutation } from '@warebee/frontend/data-access-api-graphql';
import { ConverterSettings } from '@warebee/shared/data-access-layout-import-converter';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../../../common/types';
import { errorAppender } from '../../../../store/error.state';
import { converterBuilderState } from '../store/converter.state';

function useSaveConverterBuilder() {
  const { t } = useTranslation('designer');
  const errorTitle = t`Can not save converter builder`;
  const [saveConverterBuilderMutation] =
    useSaveLayoutImportConverterStateMutation();

  const initSave = useRecoilCallback(({ set }) => async () => {
    set(converterBuilderState, {
      status: AsyncLoadStatus.Loading,
      updatedAt: new Date(),
    });
  });

  const saveConverterBuilderCallback = useRecoilCallback(
    ({ set }) =>
      async (layoutImportId: string, builder: ConverterSettings) => {
        let response;
        try {
          response = await saveConverterBuilderMutation({
            variables: {
              input: {
                id: layoutImportId,
                settings: builder,
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(converterBuilderState, {
            status: AsyncLoadStatus.Error,
            updatedAt: new Date(),
          });
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(converterBuilderState, {
            status: AsyncLoadStatus.Error,
            updatedAt: new Date(),
          });
          return;
        }

        set(converterBuilderState, {
          status: AsyncLoadStatus.Ok,
          updatedAt: new Date(),
        });
      },
  );

  return async (layoutImportId: string, builder: ConverterSettings) => {
    await initSave();
    await saveConverterBuilderCallback(layoutImportId, builder);
  };
}

export default useSaveConverterBuilder;
