import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../components/actions/ButtonSwitchMulti';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import { policyFilterData } from '../../simulation/store/policyFilter.state';
import { getPolicyFilterKey } from '../policyFilter.helper';
import {
  PolicyFilterBase,
  PolicyFilterEditorData,
  PolicyFilterEditorEvents,
} from '../policyFilter.types';
import PolicyEditorContainer from './PolicyEditorContainer';
import PolicyEditorDataStatus from './PolicyEditorDataStatus';
import PolicyEditorList from './PolicyEditorList';
import PolicyStringRangeEditor from './PolicyStringRangeEditor';

export type PolicyFilterStringEditorProps = PolicyFilterEditorData &
  PolicyFilterEditorEvents & {
    mode: 'items' | 'range' | 'both';
  };

const PolicyFilterStringEditor: React.FC<
  PolicyFilterStringEditorProps
> = props => {
  const { t } = useTranslation('app');

  const filterKey = getPolicyFilterKey(props.filterType, 'main');
  const filterData = useRecoilValue(policyFilterData(filterKey));
  const isLoading = filterData?.status === AsyncLoadStatus.Loading;
  const loadedCount = filterData?.content?.length || 0;
  const totalCount = filterData?.totalCount || 0;
  const selectedRangeFilters =
    props.value && !_.isNil(props.value?.stringRange);
  const showRangeFilters =
    (props.mode === 'both' && selectedRangeFilters) || props.mode === 'range';
  const isNotFilter = props.value?.isNot;
  const modes: ButtonSwitchMultiOption[] = [
    { label: t`Item` },
    { label: t`Range` },
  ];
  const excludeModes: ButtonSwitchMultiOption[] = [
    { label: t`Include` },
    { label: t`Exclude` },
  ];

  function onModeChange(modeIndex: number) {
    const newValue: PolicyFilterBase<string> = {
      type: props.filterType,
      valueIn: modeIndex === 0 ? [] : null,
      isNot: isNotFilter,
      stringRange:
        modeIndex === 0
          ? null
          : {
              from: null,
              to: null,
            },
    };
    props.onChange(newValue);
  }
  const showSummary = !showRangeFilters;

  const drawSummary = () => (
    <>
      {/* {props.mode === 'both' && (
        <ButtonSwitchMulti
          buttonType="minimal"
          autoSize
          className="my-1"
          options={modes}
          selectedIndex={showRangeFilters ? 1 : 0}
          onClick={onModeChange}
        />
      )} */}

      {showSummary && (
        <PolicyEditorDataStatus
          totalCount={totalCount}
          loadedCount={loadedCount}
        />
      )}
    </>
  );

  return (
    <div data-component="PolicyFilterEditorSelect">
      <PolicyEditorContainer
        title={props.title}
        filterType={props.filterType}
        summary={drawSummary()}
        summaryMode={showSummary ? 'always' : 'open'}
        isLoading={isLoading}
      >
        <ActionBar className="px-1 py-2">
          <ButtonSwitchMulti
            buttonType="minimal"
            autoSize
            options={excludeModes}
            selectedIndex={isNotFilter ? 1 : 0}
            onClick={index =>
              props.onChange({
                ...props.value,
                type: props.filterType,
                isNot: index === 1,
              })
            }
          />
          {props.mode === 'both' && (
            <>
              <Spacer flexspace />
              <ButtonSwitchMulti
                buttonType="minimal"
                autoSize
                options={modes}
                selectedIndex={showRangeFilters ? 1 : 0}
                onClick={onModeChange}
              />
            </>
          )}
        </ActionBar>

        {!showRangeFilters && (
          <PolicyEditorList {...props} isNot={isNotFilter} />
        )}
        {showRangeFilters && <PolicyStringRangeEditor {...props} />}
      </PolicyEditorContainer>
    </div>
  );
};

export default PolicyFilterStringEditor;
