import { format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import DatasetTable, { ColumnConfig } from '../../components/DatasetTable';
import FormattedValue from '../../components/FormattedValue';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import AgentResourceObject from '../../resourcePolicy/AgentResourceObject';
import { resourcePolicyAgentsMap } from '../../simulation/store/resourcePolicy.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import {
  AgentPerformanceAggregate,
  AgentPerformanceKPI,
} from '../analyze.types';
import {
  getAgentPerformanceKpiValue,
  getAgentPerformanceValueFormatted,
} from './agentPerformance.helper';

export type AgentPerformanceTableProps = {
  data: AgentPerformanceAggregate[];
  isLoading: boolean;
};

const AgentPerformanceTable: React.FC<AgentPerformanceTableProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const agents = useRecoilValue(resourcePolicyAgentsMap);
  const searchValues = {};
  const ms = useRecoilValue(warehouseMeasurementSystem);

  function renderKpiColumn(
    row: AgentPerformanceAggregate,
    kpi: AgentPerformanceKPI,
  ) {
    const value = getAgentPerformanceKpiValue(row, kpi, agents[row.agent], ms);
    const formatted = getAgentPerformanceValueFormatted(
      value,
      kpi,
      formatter,
      ms,
      t,
    );
    return <FormattedValue value={formatted} />;
  }

  const columnsConfig: ColumnConfig<AgentPerformanceAggregate>[] = [
    {
      field: 'date',
      title: t`Date`,
      isHeader: true,
      render: (n: number) => format(new Date(n), 'dd MMMM, yyyy'),
    },
    {
      field: 'agent',
      title: t`Agent`,
      isHeader: true,
      render: (agentId: number) => {
        const agent = agents[agentId];

        return agent ? (
          <AgentResourceObject
            id={agentId}
            title={agent.title}
            icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
          />
        ) : null;
      },
    },
    {
      field: 'duration',
      title: t`Distance`,
      render: (v, row) => renderKpiColumn(row, 'distance'),
    },
    {
      field: 'picklistLines',
      title: t`Lines`,
      render: (v, row) => renderKpiColumn(row, 'picklistLines'),
    },
    {
      field: 'duration',
      title: t`Performance`,
      render: (v, row) => renderKpiColumn(row, 'linesPerDistance'),
    },
    {
      field: 'duration',
      title: t`Lines/Hr`,
      render: (v, row) => renderKpiColumn(row, 'linesPerHour'),
    },
    {
      field: 'duration',
      title: t`sec/line`,
      render: (v, row) => renderKpiColumn(row, 'secondsPerLine'),
    },
  ];

  const totalCount = props.data.length;
  return (
    <DatasetTable
      id={'agent-timeline-table'}
      isSticky
      columnsConfig={columnsConfig}
      keyFields={['date', 'agent']}
      data={props.data}
      onLoadNext={_.noop}
      onSearch={_.noop}
      totalCount={totalCount}
      searchValues={searchValues}
      hideScreenTitle={true}
      isLoading={props.isLoading}
    />
  );
};

export default AgentPerformanceTable;
