import _, { Dictionary } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InboxZero from '../../../../components/InboxZero';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import InputNumber from '../../../../components/inputs/InputNumber';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { InputSearch } from '../../../../components/inputs/InputSearch';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import { ActionBar } from '../../../../components/nav/ActionBar';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableRowHead } from '../../../../components/table/TableRowHead';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import HelpContainer from '../../../../helpContext/HelpContainer';
import {
  converterEditableArea,
  converterSelectedArea,
} from '../store/converter.area.state';
import {
  converterLayoutSelectedAreaBayMap,
  converterLayoutSelectedBayId,
} from '../store/converter.layout.state';
import { converterAreaBuilder } from '../store/converter.state';

const StepTunnelAisles: React.FC = () => {
  const { t } = useTranslation('designer');

  const selectedArea = useRecoilValue(converterSelectedArea);
  const editArea = useRecoilValue(converterEditableArea);
  const bayMap = useRecoilValue(converterLayoutSelectedAreaBayMap);
  const area = selectedArea ?? editArea;
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );
  const setSelectedBay = useSetRecoilState(converterLayoutSelectedBayId);
  const [filter, setFilter] = useState<string>('');

  const setTunnel = (bayId: string, isTunnel: boolean) => {
    setAreaBuilder({
      ...areaBuilder,
      tunnelBays: {
        ...(areaBuilder.tunnelBays || {}),
        [bayId]: isTunnel,
      },
    });
  };

  const setTunnelAll = (isTunnel: boolean) => {
    const v = _.reduce(
      _.keys(filteredTunnelBays),
      (acc, key) => ({ ...acc, [key]: isTunnel }),
      {},
    );
    setAreaBuilder({
      ...areaBuilder,
      tunnelBays: {
        ...(areaBuilder.tunnelBays || {}),
        ...v,
      },
    });
  };
  const tunnelBaysMap = _(area?.bays)
    .filter(b => b.navigable)
    .map(b => b.id)
    .reduce((acc, cur) => ({ ...acc, [cur]: true }), {});
  const combinedTunnelBays = {
    ...tunnelBaysMap,
    ...(areaBuilder?.tunnelBays ?? {}),
  };
  const filterExp = new RegExp(filter, 'i');

  const filteredTunnelBays = _.pickBy(
    combinedTunnelBays,
    (value, key) => _.isEmpty(filter) || !_.isEmpty(key.match(filterExp)),
  ) as Dictionary<boolean>;

  const filteredCount = _.size(filteredTunnelBays);
  const isAllChecked = _.every(_.values(filteredTunnelBays), v => v);

  function drawRow(bayId: string, isTunnel: boolean) {
    return (
      <TableRow
        rowLineList
        rowHover
        key={bayId}
        aria-label={bayId}
        className="cursor-pointer"
        onMouseLeave={() => setSelectedBay(null)}
        onMouseEnter={() => setSelectedBay(bayId)}
      >
        <TableTd cellSize="sm">
          <InputCheckbox
            isHoverable
            label={bayMap[bayId]?.title ?? bayId}
            isSelected={isTunnel}
            onChange={v => {
              setTunnel(bayId, v);
            }}
          />
        </TableTd>
        <TableTd cellSize="sm">{bayMap[bayId]?.title ?? bayId}</TableTd>
      </TableRow>
    );
  }

  return (
    <PanelBody>
      <ScreenTitle title={t`Tunnel Aisles`} subtitle={t`Setup Aisles`} />
      <InputQA>
        <InputQuestion
          icon={Icon.ObjectAisle2Middle}
          question={t`Do you have Tunnel Aisles?`}
        />

        {!area && (
          <InboxZero
            className={`mt-2`}
            message={t`No Area Selected`}
            message_helper={t`Click once on an Area to select`}
          />
        )}

        {area && _.isEmpty(combinedTunnelBays) && (
          <InboxZero
            message={t`No Tunnel Bays Detected`}
            message_helper={
              t`Click once on an Area to select` +
              '\n' +
              t`Double-Click Area to edit Aisles, Bays and Locations`
            }
          />
        )}

        {area && !_.isEmpty(combinedTunnelBays) && (
          <>
            <InputAnswerGroup>
              <HelpContainer
                id={'designer/03-setup-aisles-tunnel'}
                hasPadding
              />
            </InputAnswerGroup>
            <ActionBar className="p-1 mt-4">
              <InputNumber
                title={t`Tunnel aisle threshold`}
                ariaLabel={'Minimal tunnel size threshold'}
                value={areaBuilder.minimalTunnelWidth ?? 0}
                onChange={v =>
                  setAreaBuilder({
                    ...areaBuilder,
                    minimalTunnelWidth: v,
                  })
                }
                range={[0, null]}
              />
            </ActionBar>

            <TitleSection titleView title={t`Tunnel Aisles`} />
            <Table isSticky viewAsLine isHoverable>
              <TableHead>
                <TableRowHead>
                  <TableTh panelMode cellSize="xs">
                    <InputCheckbox
                      isSelected={isAllChecked}
                      onChange={setTunnelAll}
                    />
                    {t`Tunnel?`}
                  </TableTh>
                  <TableTh panelMode>
                    <InputSearch
                      headerStatMode
                      titleMode
                      placeholder={t`Bay`}
                      value={filter}
                      onChange={setFilter}
                    />
                  </TableTh>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {filteredCount === 0 && (
                  <InboxZero message={t`Bays not found`} message_helper={''} />
                )}
                {_(filteredTunnelBays)
                  .keys()
                  .sortBy(id => bayMap[id]?.title ?? id)
                  .map(bayId => drawRow(bayId, filteredTunnelBays[bayId]))
                  .value()}
              </TableBody>
            </Table>
          </>
        )}
      </InputQA>
    </PanelBody>
  );
};

export default StepTunnelAisles;
