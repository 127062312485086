import { TFunction } from 'i18next';
import { getLabourEventTitle } from '../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  agentLabourEvents,
  agentLabourMetadataPropertyName,
} from './agentData/agent.types';
import {
  AgentEditorSettings,
  AgentFilterSectionConfig,
} from './AgentEditorSettings';

export function getCostFilterConfiguration(
  config: AgentEditorSettings,
  t: TFunction<'simulation'>,
): AgentFilterSectionConfig[] {
  return [
    {
      title: t(`Time & Cost`, { ns: 'simulation' }),
      counter: t`D`,
      filterGroups: [
        {
          title: t(`Hourly Cost`, { ns: 'simulation' }),
          description: t(
            `Cost calculation is based on the agent's hourly rate. We recommend including salary, equipment cost, and any other expense in the hourly rate you use.`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: ['cost', 'costPerSecond'],
              title: t(`Cost per hour`, { ns: 'simulation' }),
              description: t(
                'Hourly cost of the resource. Can include salary, amortisation and more.',
                { ns: 'simulation' },
              ),
              filterConfig: config.hourCost,
            },
          ],
        },
        {
          title: t(`Work Shift`, { ns: 'simulation' }),
          category: t(`UTILISATION`, { ns: 'simulation' }),
          description: t(
            `Duration of the agent's working shift. Employees typically work 8hr-9hr. shifts, automation and robots can work 24hr.`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: ['utilisation', 'secondsOperation'],
              title: t(`Total working hours (shift)`, { ns: 'simulation' }),
              filterConfig: config.secondsOperation,
            },
          ],
        },
        {
          title: t(`Custom Events (Indirect Labour)`, { ns: 'simulation' }),
          category: t(`CUSTOM`, { ns: 'simulation' }),
          description: t(
            `Time spent on custom tasks, like Breaks, Meetings, etc.`,
            { ns: 'simulation' },
          ),
          filters: agentLabourEvents.map(eventPropertyName => ({
            path: [
              'metadata',
              agentLabourMetadataPropertyName,
              eventPropertyName,
            ],
            title: getLabourEventTitle(eventPropertyName, t),
            filterConfig: config.timePerWorkShift,
          })),
        },
      ],
    },
  ];
}
