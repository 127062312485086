import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ANALYTICS_STATUS, HUBSPOT_KEY } from '../common/environmentVariables';
import useHubspotChat from '../common/useHubspotChat';
import { chatState } from '../store/global.state';
import * as Icon from './icons';

const HubspotChat: React.FC = () => {
  const {
    isWidgetLoaded,
    hasLoaded,
    activeConversation,
    chatVisible,
    chatButtonVisible,
    chatWindowOpen,
    openHandler,
    closeHandler,
  } = useHubspotChat(HUBSPOT_KEY);
  const { t } = useTranslation('app');

  const [showChatX, setShowChatX] = useState<boolean>(false);

  const chatEnabled = useRecoilValue(chatState);

  if (!chatEnabled || ANALYTICS_STATUS === 'false') {
    // Disabled analytics
    console.debug('Analytics:: disabled');
    console.debug('Chat User Setup Status::', chatEnabled);
  } else {
    let hubspotKey;
    hubspotKey = HUBSPOT_KEY;
  }

  if (!chatEnabled) {
    return null;
  }

  // console.log('Chat hasLoaded:', hasLoaded);
  // console.log('Chat chatVisible:', chatVisible);
  // console.log('Chat chatButtonVisible:', chatButtonVisible);
  // console.log('Chat chatWindowOpen:', chatWindowOpen);

  return (
    <>
      {chatVisible && (
        <span
          data-component="ChatCloseCircle"
          onClick={closeHandler}
          className={classNames(
            'rounded-full',
            'p-4',
            'absolute bottom-[18px] right-[18px]',
            'z-menu',
            'bg-menu-active text-menu-active-text',
            'hover:scale-120 transform transition duration-200 motion-reduce:transform-none',
            'cursor-pointer',
          )}
        >
          <Icon.Close className={classNames('h-6 w-6')} />
        </span>
      )}

      <div
        dir={'ltr'}
        onClick={chatWindowOpen ? closeHandler : openHandler}
        data-component="ChatButton"
        className={classNames(
          'group',
          'ltr:[direction:ltr] ltr:[writing-mode:vertical-lr] rtl:[direction:ltr] rtl:[writing-mode:vertical-rl]',
          'absolute bottom-32 right-0',
          'z-dropdown_menu rounded-bl-md rounded-tl-md p-0.5',
          'bg-brand-chat/75 text-menu-text group-hover:bg-menu-active group-hover:text-menu-active-text',
          'backdrop-blur-lg backdrop-saturate-150 backdrop-filter',
          'flex items-center',
          'text-sm',
          'cursor-pointer',
        )}
        onMouseEnter={() => {
          setShowChatX(true);
        }}
        onMouseLeave={() => {
          setShowChatX(false);
        }}
      >
        <label
          // onClick={showChatX ? closeHandler : openHandler}
          className="flex-1 rotate-180 cursor-pointer pb-5 pl-0.5 pr-1 pt-5"
        >{t`Need Help?`}</label>
        <span
          data-component="chatAvatar"
          className={classNames(
            'absolute -bottom-6 rounded-full p-0',
            'h-9 w-9',
            'bg-app-panel-dark/60 text-menu-active',
            'border-app-panel-dark border-2',
            'border-menu-active group-hover:bg-menu-active group-hover:text-menu-active-text',
            'hover:scale-120 transform cursor-pointer transition duration-200 motion-reduce:transform-none',
          )}
        >
          <img
            alt="Chat with us"
            className={`object-contain`}
            src="/app/assets/theme/img/chat-profile-photo.png"
          ></img>
        </span>
      </div>
    </>
  );
};
export default HubspotChat;
