import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userSettings } from '../../store/warehouse.state';
import * as Logo from '../logos';

export type LogoHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  logoApp?: boolean;
  embeded?: boolean;
  headerMode?: boolean;
  logoFull?: boolean;
  logoSize?: string;
  navTo?: string;
};

export const LogoHeader = ({
  logoApp,
  logoFull,
  logoSize,
  children,
  className,
  embeded,
  headerMode,
  navTo,
}: LogoHeaderProps) => {
  const [id] = useState<string>(_.uniqueId());
  const settings = useRecoilValue(userSettings);

  return (
    <Link
      id={id}
      to={navTo ? navTo : settings?.configured ? '/' : '/welcome'}
      className={classNames('flex items-center', className)}
      data-component={`LogoHeader`}
      data-label={`logo-header`}
      aria-label={`logo-header`}
    >
      {logoApp && (
        <Logo.WareBeeSymbolShape
          className={classNames(
            'fill-current',
            !logoSize && 'w-20 h-16',
            logoSize === 'xs' && 'h-4 md:h-5 lg:h-6',
            logoSize === 'header' && 'h-3 md:h-4 lg:h-4',
            embeded ? 'text-menu-text/50' : 'text-brand-warebee',
          )}
        />
      )}
      {logoFull && (
        <Logo.WareBeeLogoFull
          className={classNames(
            'fill-current',
            headerMode ? '' : 'm-2',
            embeded ? 'text-menu-text/50' : 'text-brand-warebee',
            !logoSize && 'h-10',
            logoSize === 'xs' && 'h-4 md:h-5 lg:h-6',
            logoSize === 'header' && 'h-6 md:h-6 lg:h-7',
            logoSize === 'sm' && 'h-5 md:h-8 lg:h-10',
            logoSize === 'md' && 'h-12 md:h-18 lg:h-20 -ml-2',
            logoSize === 'lg' && 'h-18 md:h-20 lg:h-24',
          )}
        />
      )}
      {children}
    </Link>
  );
};
