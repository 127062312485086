import React from 'react';
import useFormatter from '../../common/useFormatter';
import { cn } from '../../common/utils';
import * as Icon from '../icons';
import { UnitOfMeasure } from '../stats/UnitOfMeasure';

export type Issue = {
  title: string;
  value: string | Number;
  count?: number;
  total?: number;
};

type DisplayMode = 'detailed' | 'percentage' | 'issueicon';

interface IssueCountSummaryProps {
  issues: Issue[];
  totalCount?: number;
  displayMode: DisplayMode;
}

const IssueCountSummary: React.FC<IssueCountSummaryProps> = ({
  issues,
  displayMode,
}) => {
  const formatter = useFormatter();

  const filteredIssues = issues.filter(issue => issue.count && issue.count > 0);

  const totalCount = filteredIssues.reduce(
    (sum, issue) => sum + (issue.count || 0),
    0,
  );

  const formatCount = (count: number): string => {
    if (count >= 1000) {
      return `${Math.floor(count / 1000)}k`;
    }
    return count.toString();
  };

  const issueIcon = (
    <Icon.TriangleInfo
      className={cn('h-4 w-3', 'fill-current', 'flex-shrink-0')}
    />
  );

  if (displayMode === 'issueicon') {
    return filteredIssues.length > 0 ? issueIcon : null;
  }

  if (displayMode === 'percentage') {
    const nonCompliantItemsCount = filteredIssues[0]?.count || 0;
    const totalItemsCount = filteredIssues[0]?.total || 0;

    const nonCompliantItemsNumeric =
      totalItemsCount > 0 ? nonCompliantItemsCount / totalItemsCount : null;

    const nonCompliantItemsShare = formatter.formatShare(
      nonCompliantItemsNumeric,
    );

    return (
      <div
        className={cn(
          'flex items-center',
          'border-menu-button/50 border',
          'px-1 py-0.5',
          'rounded',
          'darken',
          'shadow-xl',
          'w-14',
          'h-8',
        )}
      >
        {issueIcon}
        <span
          className={cn(
            nonCompliantItemsNumeric > 100 ? 'text-xs' : 'text-sm',
            'mx-px',
          )}
        >
          {nonCompliantItemsShare.value}
        </span>
        <UnitOfMeasure
          className="!m-0"
          unitOfMeasure={nonCompliantItemsShare.unit}
        />
      </div>
    );
  }

  return (
    <div
      data-component="IssueCountSummary"
      className={cn(
        'flex items-center',
        'border-menu-button/50 border',
        'px-1 py-0.5',
        'rounded',
        'h-8',
        'w-12',
      )}
    >
      {issueIcon}
      <ul
        className={cn(
          'text-xxs',
          'flex flex-col',
          'space-y-0.5',
          'truncate',
          'ltr:ml-1 rtl:mr-1',
          'w-full',
        )}
      >
        {filteredIssues.map((issue, index) => (
          <li
            data-component="issue-count"
            key={index}
            title={`${issue.title} (${issue.count} of ${issue.total})`}
            className={cn(
              'border-menu-button truncate',
              index > 0 && 'border-t pt-px',
            )}
          >
            {formatCount(issue.count!)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IssueCountSummary;
