import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../../components/InboxZero';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import { getIssuesDescriptors } from '../../store/simulation.issues.helper';
import { simulationIssuesSelectedLocation } from '../../store/simulation.issues.state';
import { IssueTypeDescriptor } from '../../store/simulation.issues.types';

export type LocationPropertiesDatasetIssuesProps = {
  locationId: string;
};

const LocationPropertiesDatasetIssues: React.FC<
  LocationPropertiesDatasetIssuesProps
> = props => {
  const { t } = useTranslation('simulation');
  const locationIssue = useRecoilValue(simulationIssuesSelectedLocation);
  const sectionTitle = t`Issues`;

  const EmptyPlaceholder = () => <InboxZero message={t`No issues`} />;

  if (_.isNil(locationIssue)) {
    return <EmptyPlaceholder />;
  }
  const descriptors = getIssuesDescriptors(t);
  const issueDescriptor: IssueTypeDescriptor<string> =
    descriptors['layout'][locationIssue.reason as any] ??
    descriptors['assignment'][locationIssue.reason as any];

  function getItems(items: { consignee: string; sku: string }[]) {
    return _(items)
      .map(product => {
        return (
          <React.Fragment key={`item-${product.consignee}-${product.sku}`}>
            <StatListItem
              titleSelectable
              title={product.sku}
              value={`${issueDescriptor?.tag}`}
              valueStyle={{
                background: issueDescriptor?.color,
                color: issueDescriptor?.textColor,
              }}
              valueClass="rounded"
              labelValueType="itemId"
              labelValueFilters={{
                consignee: product.consignee,
                sku: product.sku,
              }}
            ></StatListItem>
          </React.Fragment>
        );
      })
      .value();
  }

  return (
    <PanelContainer
      id="panel-compliance-size-items"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      {/* <StatListItem
        title={t`Status`}
        value={`${statusMap[locationIssue.status]}`}
        valueStyle={{
          background,
          color,
        }}
      ></StatListItem> */}

      {!_.isEmpty(locationIssue.items) ? (
        <>
          <StatListHeader title={t`Item`} value={t`Issue`} />
          {getItems(locationIssue.items)}
        </>
      ) : (
        <>
          <StatListHeader title={t`Item`} value={t`Issue`} />
          <InboxZero message={t`No Item issues`} />
        </>
      )}
    </PanelContainer>
  );
};

export default LocationPropertiesDatasetIssues;
