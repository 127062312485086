import React from 'react';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { HelperMessage } from '../../components/HelperMessage';
import { HelperLearnMore } from '../../components/layout/HelperLearnMore';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { useTranslation } from 'react-i18next';

export type HelperTemplateProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  hasLearnMore?: boolean;
  hasIcon?: boolean;
  hasMargin?: boolean;
  hasPadding?: boolean;
  hideMessage?: boolean;
};

export const HelperTemplate = ({
  children,
  title,
  subtitle,
  className,
  hasIcon,
  hasMargin,
  hasPadding,
  hideMessage,
}: HelperTemplateProps) => {
  const { t } = useTranslation('help');

  const hasLearnMore = false;

  return (
    <PanelBody>
      <ScreenTitle title={title} subtitle={subtitle} />
      <HelperMessage
        className={className}
        hasMargin={hasMargin}
        hasIcon={hasIcon}
        hasPadding={hasPadding}
        hideMessage={hideMessage}
      >
        {children}
        {hasLearnMore && <HelperLearnMore title={t`Learn More...`} />}
      </HelperMessage>
    </PanelBody>
  );
};
