import { Transition } from '@headlessui/react';
import {
  AppBillingWarehouseStateFragment,
  BillableOperationType,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AppBillingPricing from '../appBilling/AppBillingPricing';
import { getWarehouseBillingSummary } from '../appBilling/store/appBilling.helper';
import { appBillingWarehouseState } from '../appBilling/store/appBilling.state';
import { WarehouseBillingSummary } from '../appBilling/store/appBilling.types';
import { formatRemaining } from '../common/formatHelper';
import { cn } from '../common/utils';
import { HelperMessage } from '../components/HelperMessage';
import { Button } from '../components/actions/Button';
import { MailTo } from '../components/actions/MailTo';
import * as Icon from '../components/icons';
import { ItemListCard } from '../dashboard/containers/ItemListCard';
import { loggedInUser } from '../store/auth.state';
import { collapsibleState } from '../store/collapsible.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import { PriceCard } from './PlanCard';

export type HeroPricingProps = {
  children?: React.ReactNode;
  componentName?: string;
};

export const HeroPricingBundles = ({ children }: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const [stateBundle, setStateBundle] = useRecoilState(
    collapsibleState('subscription-panel-bundles'),
  );
  const isClosedBundle = stateBundle.isCollapsed;
  const toggleOpenBundle = () => {
    setStateBundle({
      isCollapsed: !isClosedBundle,
    });
  };

  return (
    <div id={id} data-component="HeroPricingBundles">
      <HelperMessage
        className={`mb-4 mt-6 flex flex-1 items-center text-end ltr:justify-end rtl:justify-start`}
      >
        <div
          className="text-menu-text flex cursor-pointer items-center text-2xl"
          onClick={toggleOpenBundle}
        >
          <span className="">{t`Looking for one-time Bundles? Or Enterprise pricing options?`}</span>
          <Icon.CircleArrowRight className="h-6 w-6 fill-current ltr:ml-2 rtl:mr-2" />
        </div>
      </HelperMessage>

      <Transition
        className={'mt-4 flex flex-wrap'}
        show={!isClosedBundle}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <HelperMessage className={`m-4 flex-1 p-3`}>
          <div className="text-menu-text text-xl">
            {t`Here are our Non-Subscription plans`}
          </div>

          <div className="text-menu-text text-3xl">
            {t`If you still have questions or looking to purchase an enterprise license.`}
            <MailTo
              email="sales+enterpise@warebee.com"
              subject="[WAREBEE] Enterprise Plans"
              body={`Hello, I need help with pricing for Enterprise Plans`}
            >
              <div>{t`sales@warebee.com`}</div>
            </MailTo>
          </div>
        </HelperMessage>
        <PriceCard planType="BUNDLE" />
      </Transition>
      {children}
    </div>
  );
};

export const HelperPricingTrial = ({ children }: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  return (
    <div id={id} data-component="HelperPricingTrial">
      <HelperMessage className={`m-4 flex-1 p-3`}>
        <div className="text-menu-text">
          <Icon.HelpBot className="h-20 w-20 fill-current" />
          <div className="mt-8 text-3xl">
            {t`You are running a trial, limited to a single warehouse.`}
            <br />
            {t`To do more, please purchase a subscription or contact us to discuss your requirements at `}
            <MailTo
              email="hello@warebee.com"
              subject="[WAREBEE] Subscription"
              body={t`Hello, I need help with subscription`}
            >
              <div>{t`hello@warebee.com`}</div>
            </MailTo>
          </div>
        </div>
      </HelperMessage>
      {children}
    </div>
  );
};

export const HeroPricingTrial = ({ children }: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const [stateBundle, setStateBundle] = useRecoilState(
    collapsibleState('subscription-panel-bundles'),
  );
  const isClosedBundle = stateBundle.isCollapsed;
  const toggleOpenBundle = () => {
    setStateBundle({
      isCollapsed: !isClosedBundle,
    });
  };

  return (
    <div id={id} data-component="HeroPricingTrial">
      <AppBillingPricing modePreview={true}>
        <HelperMessage
          className={`border-menu-500 mb-8 flex-1 rounded-lg border p-4`}
        >
          <div className="text-menu-text">
            <Icon.HelpBot className="h-10 w-10 fill-current" />
            <div className="mt-2 text-base">
              {t`This warehouse is running in Trial`}
              <br />
              {t`You can purchase a subscription or contact us to discuss your requirements at `}
              <MailTo
                email="hello@warebee.com"
                subject="[WareBee] Subscription"
                body={`Hello, I need help with`}
              >
                <div>{t`hello@warebee.com`}</div>
              </MailTo>
            </div>
          </div>
        </HelperMessage>
      </AppBillingPricing>
      {children}
    </div>
  );
};

export const HelperPricingUnpaid = ({ children }: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  return (
    <div id={id} data-component="HelperPricingUnpaid" className="w-full">
      <AppBillingPricing modePreview={true}>
        <HelperMessage
          className={`border-menu-500 mb-8 flex-1 rounded-lg border p-4`}
        >
          <div className="text-menu-text">
            <Icon.HelpBot className="h-10 w-10 fill-current" />
            <div className="mt-2 text-base">
              {t`This warehouse has no active subscription`}
              <br />
              {t`To do more, please purchase a subscription or contact us to discuss your requirements at `}
              <MailTo
                email="hello@warebee.com"
                subject="[WareBee] Subscription"
                body={`Hello, I need help with`}
              >
                <div>{t`hello@warebee.com`}</div>
              </MailTo>
            </div>
          </div>
        </HelperMessage>
      </AppBillingPricing>
      {children}
    </div>
  );
};

export const HeroPricingUnpaid = ({
  children,
  componentName,
}: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const [stateBundle, setStateBundle] = useRecoilState(
    collapsibleState('subscription-panel-bundles'),
  );
  const isClosedBundle = stateBundle.isCollapsed;
  const toggleOpenBundle = () => {
    setStateBundle({
      isCollapsed: !isClosedBundle,
    });
  };

  return (
    <div
      id={id}
      data-component={`HeroPricingUnpaid${
        componentName ? '-' + componentName : ''
      }`}
    >
      <div className="mt-8 flex flex-wrap">
        <HelperPricingUnpaid />
      </div>
      {children}
    </div>
  );
};

export const HeroPricingPastDue = ({
  whBillingState,
}: {
  whBillingState: AppBillingWarehouseStateFragment;
}) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const navigate = useNavigate();

  const [stateBundle, setStateBundle] = useRecoilState(
    collapsibleState('subscription-panel-bundles'),
  );
  const isClosedBundle = stateBundle.isCollapsed;
  const toggleOpenBundle = () => {
    setStateBundle({
      isCollapsed: !isClosedBundle,
    });
  };

  return (
    <div id={id} data-component={`HeroPricingPastDue`}>
      <div className="mt-8 flex flex-wrap">
        <div className="text-menu-text">{t`Subscription payment is past due.`}</div>

        <Button
          full
          className="mt-4"
          buttonSize={'sm'}
          label={t`Go to billing`}
          buttonType="secondary"
          hasIconAfter
          buttonIcon={<Icon.ArrowRight className={`h-4 w-4 fill-current`} />}
          onPress={() => {
            navigate(`/wh/i/${whId}/settings/`);
          }}
        />
      </div>
    </div>
  );
};

export const HeroPricingExpiredSubscription = ({
  children,
}: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const [stateBundle, setStateBundle] = useRecoilState(
    collapsibleState('subscription-panel-bundles'),
  );
  const isClosedBundle = stateBundle.isCollapsed;
  const toggleOpenBundle = () => {
    setStateBundle({
      isCollapsed: !isClosedBundle,
    });
  };

  return (
    <div id={id} data-component="HeroPricingExpiredSubscription">
      <div className={classNames('mt-8 flex flex-wrap')}>
        <HelperPricingTrial />
        <PriceCard planType="SUBSCRIPTION" />
      </div>
      {children}
    </div>
  );
};

export const HelperBillingMessage = ({ children }: HeroPricingProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  return (
    <div
      id={id}
      className={classNames('flex-1 text-xl xl:text-2xl')}
      data-component="HelperBillingMessage"
    >
      {children}
    </div>
  );
};

function getHeroPricingTitle(
  billingSummary: WarehouseBillingSummary,
  firstName: string,
  t: TFunction<'app'>,
): string {
  const remainingTime = formatRemaining(
    billingSummary.intervals?.[0]?.to?.getTime(),
  );
  const oneTimeAnalyze = _.find(
    billingSummary.oneTimes,
    b => b.operation === BillableOperationType.ANALYZE,
  );

  const oneTimeOptimise = _.find(
    billingSummary.oneTimes,
    b => b.operation === BillableOperationType.OPTIMIZE,
  );

  switch (billingSummary.status) {
    /*
     SUB
    */
    case 'hasSubscription':
      return t(
        `Hello, {{firstName}} active subscription renews in {{remainingTime}}`,
        {
          firstName,
          remainingTime,
        },
      );

    /*
     EXPIRED SUB
    */
    case 'hasExpiredSubscription':
      return t(`Hello, {{firstName}} subscription has expired.`, {
        firstName,
        remainingTime,
      });

    /*
     ONE TIME
    */
    case 'hasOneTime':
      if (oneTimeOptimise?.active) {
        // one time optimize is billable
        return t(
          `Hello, {{firstName}} you have {{countOptimise}} optimise simulations left.`,
          // `Hello, {{firstName}} you have {{countOptimise}} optimise simulations left. (+ {{countAnalyse}} analyse simulations)`,
          {
            firstName,
            countOptimise: oneTimeOptimise?.counter ?? 0,
            countAnalyse: oneTimeAnalyze?.counter ?? 0,
            countAll:
              (oneTimeOptimise?.counter ?? 0) + (oneTimeAnalyze?.counter ?? 0),
          },
        );
      }
      return t(
        `Hello, {{firstName}}, you have {{countAnalyse}} analyse simulations left.`,
        {
          firstName,
          countAnalyse: oneTimeAnalyze?.counter ?? 0,
        },
      );

    /*
     EXPIRED ONE TIME
    */
    case 'hasExpiredOneTime':
      return t(
        `Hello, {{firstName}}, all your bundled simulations have been used, subscribe to continue`,
        {
          firstName,
        },
      );

    /*
     TRIAL
    */
    case 'hasTrial':
      return t(`Hello, {{firstName}} you are currently running a trial`, {
        firstName,
      });

    /*
     UNPAID
    */
    case 'unpaid':
      return t(
        `Hello, {{firstName}}, this warehouse requires an active subscription`,
        {
          firstName,
        },
      );
  }
}

export const HeroPricing = () => {
  const { t } = useTranslation('app');
  const [id] = useState<string>(_.uniqueId());
  const whBillingState = useRecoilValue(appBillingWarehouseState);
  // User's Details
  const user = useRecoilValue(loggedInUser);
  const firstName = user.firstName;

  const [state, setState] = useRecoilState(
    collapsibleState('subscription-panel'),
  );
  const isClosed = state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const billingSummary = getWarehouseBillingSummary(whBillingState);
  const billingSummaryStatus = billingSummary.status;
  const helloBillingStatusMsg = getHeroPricingTitle(
    billingSummary,
    firstName,
    t,
  );

  const hasExpandableContent =
    billingSummaryStatus !== 'hasSubscription' &&
    billingSummaryStatus !== 'hasOneTime';

  return (
    <ItemListCard
      id={id}
      data-component="HeroPricing"
      titleTrace={`Hero Pricing`}
      isHero
      fullWidth
      className={cn(
        'flex-none',
        billingSummaryStatus === 'hasSubscription' ? 'hidden' : '',
      )}
    >
      <div className={cn('flex items-start')}>
        <HelperBillingMessage>{helloBillingStatusMsg}</HelperBillingMessage>
        {hasExpandableContent && (
          <Button
            className={cn('border-app-panel-dark rounded-full border')}
            label={isClosed ? t`More Info` : t`Later`}
            buttonSize="sm"
            buttonType={`${isClosed ? 'primary' : 'primary'}`}
            isDisabled={false}
            hasIconAfter
            buttonIcon={
              isClosed ? (
                <Icon.ArrowRightBottom className={cn('h-5 w-5 fill-current')} />
              ) : (
                <Icon.Close className={cn('h-4 w-4 fill-current')} />
              )
            }
            onPress={toggleOpen}
          />
        )}
      </div>
      
      <Transition
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {billingSummaryStatus === 'hasTrial' ? (
          <HeroPricingTrial />
        ) : billingSummaryStatus === 'hasSubscription' ? (
          ''
        ) : billingSummaryStatus === 'hasOneTime' ? (
          ''
        ) : billingSummaryStatus === 'hasExpiredSubscription' ? (
          <HeroPricingPastDue whBillingState={whBillingState} />
        ) : billingSummaryStatus === 'hasExpiredOneTime' ? (
          <HeroPricingUnpaid componentName="hasExpiredOneTime" />
        ) : billingSummaryStatus === 'unpaid' ? (
          <HeroPricingUnpaid componentName="unpaid" />
        ) : (
          <HeroPricingUnpaid />
        )}
      </Transition>
    </ItemListCard>
  );
};
