import * as React from 'react';
const PolicyLocationSharingLineIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyLocationSharingLineIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.06-.16.16-.31.28-.43L60.82,11.99c.88-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M65.34,14.01c-.43-.43-.88-.64-1.34-.64h0c-.46,0-.91.21-1.34.64L14.01,62.66c-.86.86-.86,1.82,0,2.68l48.65,48.65c.86.86,1.82.85,2.68,0l48.65-48.65c.85-.85.85-1.82,0-2.68L65.34,14.01ZM46.88,79.82l-13.35-7.71v-15.41l13.35,7.71v15.41ZM35.99,52.45l13.3-7.68,13.3,7.68-13.3,7.68-13.3-7.68ZM65.05,72.1l-13.3,7.68v-15.36l13.3-7.68v15.36ZM97.13,72.18l-14.41,8.32-14.41-8.32v-16.64l14.41-8.32,14.41,8.32v16.64Z"
    />
    <path
      strokeWidth={0}
      d="M82.72,73.45c-1.65,0-3-1.35-3-3v-3.51h-3.26c-1.65,0-3-1.35-3-3s1.35-3,3-3h3.26v-3.01c0-1.65,1.35-3,3-3s3,1.35,3,3v3.01h3.26c1.65,0,3,1.35,3,3s-1.35,3-3,3h-3.26v3.51c0,1.65-1.35,3-3,3Z"
    />
    <path
      strokeWidth={0}
      d="M81.72,57.94v5.01h-5.26c-.55,0-1,.45-1,1s.45,1,1,1h5.26v5.51c0,.55.45,1,1,1s1-.45,1-1v-5.51h5.26c.55,0,1-.45,1-1s-.45-1-1-1h-5.26v-5.01c0-.55-.45-1-1-1s-1,.45-1,1Z"
    />
  </svg>
);
export default PolicyLocationSharingLineIcon;
