import {
  AnalyzeResultLocationFragment,
  AnalyzeResultProcessType,
  AnalyzeResultProductFragment,
  AnalyzeResultSummaryFragment,
  PicklistEventType,
  ReassignTaskAction,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getSequentialColor } from '../../common/color.helper';
import {
  getHeatmapPowRangeBucketByValue,
  getHeatmapPowRangeBuckets,
  HeatmapBucket,
  HEATMAP_BUCKETS_COUNT,
} from '../../common/heatmap.helper';
import { AnalyzeProductMetric } from '../../metrics/analyzeProduct/analyzeProductMetric.types';
import { getProductCategory } from './abc/simulation.ABC.helper';
import { LCV_EU_Multiplier } from './resourcePolicy.default';
import { AnalyzeTotals } from './simulation.types';

/**
 *  Get products without 'removed' in reassign phase
 *
 * @param {AnalyzeResultLocationFragment} locationData
 * @return {*}  {AnalyzeResultProductFragment[]}
 */
export function getActiveProducts(
  locationData: AnalyzeResultLocationFragment,
): AnalyzeResultProductFragment[] {
  return _.filter(locationData?.products, p =>
    _.every(p.reassignTasks, t => t.action !== ReassignTaskAction.TAKE),
  );
}
export function getReassignedProducts(
  locationData: AnalyzeResultLocationFragment,
): AnalyzeResultProductFragment[] {
  return _.filter(locationData?.products, p => !_.isEmpty(p.reassignTasks));
}

/**
 * Visit calculated as sum of pick and replenishment visits, nullable
 *
 * @param {AnalyzeResultProductFragment[]} products
 * @return {*}  {number}
 */
export function getVisitsCount(
  products: AnalyzeResultProductFragment[],
): number {
  return _.isEmpty(products)
    ? null
    : _.sumBy(products, p => (p.pickCount ?? 0) + (p.replenishmentCount ?? 0));
}

export function getProductHeatmapMetricValue(
  product: AnalyzeResultProductFragment,
  heatmapType: AnalyzeProductMetric,
) {
  switch (heatmapType) {
    case 'applied-reorder':
      return product?.appliedReorder?.count;
    case 'triggered-reorder':
      return product?.triggeredReorder?.count;
  }
}

export function getVisitsBucket(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
): HeatmapBucket {
  const products = getActiveProducts(locationData);
  const hitCount = getVisitsCount(products);
  return getHeatmapPowRangeBucketByValue(hitCount, max, buckets);
}

export function getVisitsColor(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
): [string, string] {
  const bucket = getVisitsBucket(locationData, max, buckets);
  return bucket ? [bucket.color, bucket.textColor] : null;
}

export function getVisibleByVisits(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
  hiddenBuckets: Record<string, boolean>,
): boolean {
  const products = getActiveProducts(locationData);
  if (_.isEmpty(products)) return true;
  const bucket = getVisitsBucket(locationData, max, buckets);
  return !bucket || !hiddenBuckets[bucket.id];
}

export function getAppliedReorderingGradientColors(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
  hiddenBuckets: Record<string, boolean>,
): string[] {
  const products = getActiveProducts(locationData);

  const colors = _(products)
    .map(p =>
      getHeatmapPowRangeBucketByValue(p.appliedReorder?.count, max, buckets),
    )
    .compact()
    .filter(b => hiddenBuckets[b.id] !== true)
    .sortBy(b => -b.index)
    .map(p => p.color)
    .uniq()
    .value();

  return _.isEmpty(colors) ? null : colors;
}

export function getVisibleByAppliedReordering(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
  hiddenBuckets: Record<string, boolean>,
): boolean {
  const products = getActiveProducts(locationData);
  if (_.isEmpty(products)) return true;
  const colors = getAppliedReorderingGradientColors(
    locationData,
    max,
    buckets,
    hiddenBuckets,
  );
  return !_.isEmpty(colors);
}

export function getTriggeredReorderingGradientColors(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
  hiddenBuckets: Record<string, boolean>,
): string[] {
  const products = getActiveProducts(locationData);

  const colors = _(products)
    .map(p =>
      getHeatmapPowRangeBucketByValue(p.triggeredReorder?.count, max, buckets),
    )
    .compact()
    .filter(b => hiddenBuckets[b.id] !== true)
    .sortBy(b => -b.index)
    .map(p => p.color)
    .uniq()
    .value();

  return _.isEmpty(colors) ? null : colors;
}

export function getVisibleByTriggeredReordering(
  locationData: AnalyzeResultLocationFragment,
  max: number,
  buckets: Record<string, HeatmapBucket>,
  hiddenBuckets: Record<string, boolean>,
): boolean {
  const products = getActiveProducts(locationData);
  if (_.isEmpty(products)) return true;
  const colors = getTriggeredReorderingGradientColors(
    locationData,
    max,
    buckets,
    hiddenBuckets,
  );
  return !_.isEmpty(colors);
}

export function getABCGradientColors(
  locationData: AnalyzeResultLocationFragment,
  hiddenBuckets: Record<string, boolean>,
): string[] {
  const products = getActiveProducts(locationData);

  const colors = _(products)
    .map(p => getProductCategory(p.cumulativePercentRank))
    .compact()
    .filter(p => hiddenBuckets[p.key] !== true)
    .sortBy(p => p.sortIndex)
    .map(p => p.color)
    .uniq()
    .value();
  return _.isEmpty(colors) ? null : colors;
}

export function getVisibleByABC(
  locationData: AnalyzeResultLocationFragment,
  hiddenBuckets: Record<string, boolean>,
): boolean {
  const products = getActiveProducts(locationData);
  if (_.isEmpty(products)) return true;
  const colors = getABCGradientColors(locationData, hiddenBuckets);
  return !_.isEmpty(colors);
}

export const getVisitsHeatmapBuckets = _.memoize(maxValue => {
  return _.keyBy(
    getHeatmapPowRangeBuckets({
      maxValue: maxValue,
      bucketCount: HEATMAP_BUCKETS_COUNT,
      getColor: bucketNum =>
        getSequentialColor(
          (100 * bucketNum) / HEATMAP_BUCKETS_COUNT,
          'heatmap-analytics',
        ),
    }),
    b => b.id,
  );
});

export const getReorderingHeatmapBuckets = _.memoize(maxValue => {
  return _.keyBy(
    getHeatmapPowRangeBuckets({
      maxValue: maxValue,
      bucketCount: HEATMAP_BUCKETS_COUNT,
      getColor: bucketNum =>
        getSequentialColor(
          (100 * bucketNum) / HEATMAP_BUCKETS_COUNT,
          'heatmap-analytics',
        ),
    }),
    b => b.id,
  );
});

export function getAnalyzeTotals(
  analyzeResult: AnalyzeResultSummaryFragment,
): AnalyzeTotals {
  const totals: AnalyzeTotals = _(analyzeResult?.costDetails?.processes)
    .filter(
      p =>
        p.processType === AnalyzeResultProcessType.PICKING ||
        p.processType === AnalyzeResultProcessType.REPLENISHMENT,
    )
    .reduce(
      (acc, process) => {
        return {
          cost: acc.cost + process.cost,
          distance: acc.distance + process.distance,
          duration: acc.duration + process.duration,
        };
      },
      {
        cost: 0,
        distance: 0,
        duration: 0,
      },
    );

  return totals;
}

export function getCarbonValue(analyzeResult: AnalyzeResultSummaryFragment) {
  const agents = _.keyBy(analyzeResult?.resourcePolicy?.agents, a => a.id);
  const carbonValue = _.sumBy(analyzeResult.summary?.agents, a => {
    const agentEnergyTime = _.sumBy(a.eventTypes, et =>
      (et.processType === AnalyzeResultProcessType.PICKING ||
        et.processType === AnalyzeResultProcessType.REPLENISHMENT) &&
      (et.eventType === PicklistEventType.TRAVELLING_HORIZONTAL ||
        et.eventType === PicklistEventType.TRAVELLING_HORIZONTAL_START ||
        et.eventType === PicklistEventType.TRAVELLING_HORIZONTAL_END ||
        et.eventType === PicklistEventType.TRAVELLING_VERTICAL)
        ? et.totalDuration
        : 0,
    );
    const energyRate =
      agents[a.agent]?.specs?.powerSource?.energyConsumptionPerSecond ?? 0;

    return agentEnergyTime * energyRate * LCV_EU_Multiplier;
  });
  return carbonValue;
}
