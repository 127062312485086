import * as React from 'react';
const Mhe10AOrderPickerIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe10AOrderPickerIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M9.57,28.28h43.35c.4,0,.57.2.48.61,0,.08-.04.2-.12.36l-2.42,2.42c-.57.57-1.21.85-1.94.85h-28.7c-1.05,0-1.94.36-2.66,1.09s-1.05,1.61-.97,2.66v29.3l3.27,7.87c.57,1.21,1.41,2.18,2.54,2.91,1.13.73,2.38,1.05,3.75.97h6.54c.81.08,1.43.38,1.88.91.44.52.71,1.15.79,1.88v16.59h8.48v-16.11c0-2.18-.4-4.26-1.21-6.24-.81-1.98-1.98-3.73-3.51-5.27l-.73.85c-.48.32-.93.28-1.33-.12-.4-.4-.4-.81,0-1.21l6.3-6.3c.4-.4.85-.4,1.33,0h0c.32.4.32.85,0,1.33h0l-.85.73,3.63,3.63c.48.48,1.09.73,1.82.73h7.87v37.3h.97V7.45c.08-1.53.61-2.81,1.57-3.81.97-1.01,2.26-1.55,3.88-1.63h4c.4,0,.75.14,1.03.42.28.28.42.63.42,1.03h0v54.25h2.06v-10.54h2.06c.57,0,1.03.2,1.39.61s.54.89.54,1.45v19.86c.08.57.3,1.03.67,1.39s.79.54,1.27.54h42.26c.4.08.61.28.61.61s-.12.52-.36.61l-9.69,2.91c-.48.16-1.01.24-1.57.24h-33.42c-1.05,0-1.94-.36-2.66-1.09s-1.09-1.61-1.09-2.66h0v-5.33h-2.06v39.6h20.59c.65,0,1.17.2,1.57.61.4.4.61.89.61,1.45v10.17c0,.65-.18,1.21-.54,1.7s-.89.75-1.57.79-1.25-.14-1.7-.54c-.44-.4-.71-.93-.79-1.57v-8.23h-6.66v8.23H8.24V29.73c.08-.89.52-1.37,1.33-1.45h0ZM15.26,126c-1.78,0-3.31-.54-4.6-1.63-1.29-1.09-2.14-2.48-2.54-4.18h14.41c-.4,1.7-1.27,3.09-2.6,4.18s-2.89,1.63-4.66,1.63h0ZM86.22,112.2v6.18c0,.89.28,1.68.85,2.36.57.69,1.31,1.07,2.24,1.15s1.72-.16,2.36-.73c.65-.57,1.05-1.29,1.21-2.18v-6.78c1.86.97,3.09,2.36,3.69,4.18s.44,3.65-.48,5.51c-.93,1.86-2.32,3.09-4.18,3.69-1.86.61-3.69.44-5.51-.48-1.82-.93-3.05-2.3-3.69-4.12-.65-1.82-.52-3.65.36-5.51.65-1.53,1.7-2.62,3.15-3.27h0Z"
    />
  </svg>
);
export default Mhe10AOrderPickerIcon;
