import * as React from 'react';
const WarehouseGovernmentIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseGovernmentIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.67,108.03h-7.24v-39.27h-27.03c-.43,0-.83-.2-1.08-.53h0s0,0,0,0h0s0,0,0,0h0s0,0-.01,0c0,0-.03-.04-.05-.07.01.01.03.04.04.05-.01-.02-.02-.03-.04-.05,0-.01-.01-.02-.02-.03-.03-.03-.07-.07-.08-.11-.01-.01-.03-.03-.04-.05,0-.01-.01-.01-.01-.03-.03-.03-.04-.05-.07-.08-.01-.03-.04-.05-.04-.05-.01-.01-.01-.03-.03-.04-1.17-1.59-5.82-6.57-21.63-28.05v-8.07c.43.09.84.13,1.24.13,1.44,0,2.82-.51,4.02-.95,2.11-.79,3.48-1.21,4.87-.17.41.29.95.33,1.4.11.44-.23.73-.68.73-1.19v-10.06c0-.43-.2-.83-.55-1.08-2.62-1.94-5.26-.96-7.38-.17-2.12.79-3.48,1.21-4.88.17-.77-.59-2.12-.11-2.12,1.08v20.19c-15.8,21.47-20.46,26.46-21.63,28.06,0,0,0,.01-.01.03,0,0-.01,0-.01.01h-.01s-.04.07-.07.11c-.01.01-.03.04-.04.05-.03.04-.05.07-.08.11t-.03.03s0,.01-.01.01c0,.01-.01.03-.01.03-.01.01-.03.04-.04.07-.01.01-.03.01-.03.03h0s0,0,0,0h0s0,0,0,0h0c-.25.35-.65.55-1.08.55H12.56v39.27h-7.23c-.74,0-1.33.6-1.33,1.33s.6,1.33,1.33,1.33h117.33c.74,0,1.33-.6,1.33-1.33s-.6-1.33-1.33-1.33ZM101.28,78.75h9.23v7.46h-9.23v-7.46ZM101.28,88.88h9.23v6.02h-9.23v-6.02ZM83.98,78.75h9.25v7.46h-9.25v-7.46ZM83.98,88.88h9.25v6.02h-9.25v-6.02ZM64,55.13c6.47,0,11.7,5.24,11.7,11.72s-5.23,11.7-11.7,11.7-11.72-5.23-11.72-11.7,5.24-11.72,11.72-11.72ZM34.78,78.75h9.25v7.46h-9.25v-7.46ZM34.78,88.88h9.25v6.02h-9.25v-6.02ZM17.49,78.75h9.23v7.46h-9.23v-7.46ZM17.49,88.88h9.23v6.02h-9.23v-6.02ZM52.23,91.27c0-2.21,1.8-4,4-4h15.52c2.22,0,4,1.79,4,4v16.77h-23.52v-16.77Z"
    />
    <path
      strokeWidth={0}
      d="M59.72,68.77h4.84c.74,0,1.33-.6,1.33-1.33v-6.84c0-.74-.6-1.33-1.33-1.33s-1.33.6-1.33,1.33v5.5h-3.5c-.74,0-1.33.6-1.33,1.33s.6,1.33,1.33,1.33Z"
    />
    <path
      strokeWidth={0}
      d="M89.06,66.1h30.26l-13.51-17.53c-.75-1.05-1.97-1.67-3.26-1.67h-28.61l15.11,19.2Z"
    />
    <polygon strokeWidth={0} points="41 67.8 40.99 67.81 40.99 67.81 41 67.8" />
    <path
      strokeWidth={0}
      d="M54.05,46.9h-28.6c-1.29,0-2.51.63-3.26,1.67l-13.49,17.53h30.24l15.11-19.2Z"
    />
  </svg>
);
export default WarehouseGovernmentIcon;
