import * as React from 'react';
const RoundRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoundRightIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M124,63.93c0,33.16-26.77,60.07-59.93,60.07-33.16,0-60.07-26.91-60.07-60.07S30.91,4,64.07,4s59.93,26.77,59.93,59.93ZM86.08,48.91v52.79h-14.72v-52.79c.3-10.56-15.32-11-15.32,0v31.82s6.1,0,6.1,0c1.93,0,3.12,1.93,1.93,3.27l-13.38,15.91c-1.04,1.19-2.97,1.19-3.87,0l-13.38-15.91c-1.19-1.34-.15-3.27,1.78-3.27h6.1c0-10.56.15-21.26.15-31.82,0-30.19,44.61-30.19,44.61,0Z"
    />
  </svg>
);
export default RoundRightIcon;
