import * as React from 'react';
const DesignerAislesAccessTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesAccessTopIcon"
    {...props}
  >
    <rect
      width={27.61}
      height={87.82}
      x={50.83}
      y={37.18}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(129.27 162.18) rotate(-180)"
    />
    <rect
      width={21.27}
      height={27.91}
      x={102.81}
      y={37.18}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={21.27}
      height={27.91}
      x={102.81}
      y={67.13}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={21.27}
      height={27.91}
      x={102.81}
      y={97.09}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={21.27}
      height={27.91}
      x={80.11}
      y={37.18}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={21.27}
      height={27.91}
      x={80.11}
      y={67.13}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={21.27}
      height={27.91}
      x={80.11}
      y={97.09}
      strokeWidth={0}
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="124.09 5.19 113.19 5.19 124.09 11.92 124.09 5.19"
    />
    <polygon
      strokeWidth={0}
      points="80.18 5.19 58.18 5.19 103.56 33.19 124.09 33.19 124.09 32.28 80.18 5.19"
    />
    <polygon
      strokeWidth={0}
      points="107.69 5.19 85.68 5.19 124.09 28.89 124.09 15.31 107.69 5.19"
    />
    <polygon
      strokeWidth={0}
      points="25.17 5.2 3.16 5.2 48.55 33.2 70.55 33.2 25.17 5.2"
    />
    <polygon
      strokeWidth={0}
      points="52.68 5.19 30.67 5.2 76.05 33.2 98.06 33.19 52.68 5.19"
    />
    <polygon
      strokeWidth={0}
      points="2.52 8.19 2.52 21.77 21.04 33.2 43.05 33.2 2.52 8.19"
    />
    <polygon
      strokeWidth={0}
      points="2.52 33.2 15.54 33.2 2.52 25.16 2.52 33.2"
    />
    <path
      strokeWidth={0}
      d="M24.86,79.42c-.72,0-1.3-.58-1.3-1.3v-27.89s-12.13,12.12-12.13,12.12v-8.96l15.31-15.3,15.3,15.29v8.96l-12.13-12.12v27.89c0,.72-.59,1.3-1.3,1.3h-3.75Z"
    />
  </svg>
);
export default DesignerAislesAccessTopIcon;
