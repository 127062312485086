import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

export type LegendItemProps = {
  className?: string;
  classNameText?: string;
  color: string;
  text: string;
  labelRaw?: string;
  isHidden?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const LegendItem: React.FC<LegendItemProps> = props => {
  return (
    <div
      data-component="LegendItem"
      className={classNames(
        'flex items-center flex-row flex-1',
        'py-1 px-0.5',
        'text-start',
        props.className,
      )}
      onClick={evt => props.onClick && props.onClick(evt)}
      title={props.labelRaw}
    >
      <div
        className={classNames(
          'w-2 h-3 lg:w-3 lg:h-3 mx-1',
          'border-menu',
          props.isHidden
            ? 'border-opacity-60 border-2'
            : 'border-opacity-80 border',
        )}
        style={{ backgroundColor: props.isHidden ? '' : props.color }}
      />
      <div
        className={classNames(
          'flex-1 text-xxs',
          props.isHidden
            ? 'text-menu-text'
            : props.disabled
              ? 'text-menu-text'
              : '', //text-menu-text
          props.classNameText,
        )}
      >
        {props.text}
      </div>
    </div>
  );
};
