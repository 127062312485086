import { useRecoilValue } from 'recoil';
import ViewModeToolbar from '../../layout/toolbar/ViewModeToolbar';
import { actualityHeatmapLocationByLevel } from '../store/actuality.heatmap.state';

const ActualityViewModeToolbar: React.FC = () => {
  const heatmapLocationsByLevel = useRecoilValue(
    actualityHeatmapLocationByLevel,
  );

  return <ViewModeToolbar levelIssues={heatmapLocationsByLevel} />;
};

export default ActualityViewModeToolbar;
