import { AgentSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputSlider from '../../components/inputs/InputSlider';
import { ContainerSectionSettings } from '../../components/layout/ContainerSectionSettings';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { ResourcePolicyEditMode } from '../../simulation/store/resourcePolicy.types';
import {
  AgentFilterGroupConfig,
  AgentFilterSectionConfig,
} from '../AgentEditorSettings';

export type AgentSettingBaseProps = {
  agentSettings: AgentSettingsFragment;
  agentDefaults: Omit<AgentSettingsFragment, 'id' | 'title'>;
  editMode: ResourcePolicyEditMode;
  onChangeProperty: (value: number | object | boolean, path: string) => void;
};

export type AgentSettingsSectionProps = AgentSettingBaseProps & {
  section: AgentFilterSectionConfig;
};

type AgentSettingsGroupProps = AgentSettingBaseProps & {
  group: AgentFilterGroupConfig;
};

const AgentSettingsGroup: React.FC<AgentSettingsGroupProps> = props => {
  const { group } = props;
  return (
    <TitleSection
      key={`resource-policy-editor-section-${group.title}`}
      id={`resource-policy-editor-section-${group.title}`}
      title={group.title}
      collapsible
      inSidebarView
      inPanelView
      hasPadding
      hasScreenTitle
      className={classNames(
        'top-20',
        'text-base uppercase',
        'border-opacity-20',
      )}
    >
      {group.description && (
        <HelperMessage className="pb-4">{group.description}</HelperMessage>
      )}

      {group.filters.map(filter => {
        const path = filter.path.join('.');
        let value = _.get(props.agentSettings, path);
        const defaultValueRaw = _.get(props.agentDefaults, path);
        let defaultValue = defaultValueRaw;
        if (filter.filterConfig.load) {
          if (!_.isNil(value)) {
            value = filter.filterConfig.load(value);
          }

          defaultValue = !_.isNil(defaultValue)
            ? filter.filterConfig.load(defaultValueRaw)
            : filter.filterConfig.min;
        }

        return (
          <InputSlider
            key={filter.title}
            title={filter.title}
            unitOfMeasure={filter.filterConfig.unitOfMeasure}
            values={[value ?? defaultValue]}
            min={filter.filterConfig.min}
            max={filter.filterConfig.max}
            stepSize={filter.filterConfig.step}
            onChange={values => {
              const value = filter.filterConfig.save
                ? filter.filterConfig.save(_.head(values))
                : _.head(values);
              props.onChangeProperty(value, path);
            }}
            hasSwitch={!filter.filterConfig.isRequired}
            switchValue={value !== null}
            onSwitchChange={state => {
              const minValue = filter.filterConfig.save
                ? filter.filterConfig.save(filter.filterConfig.min)
                : filter.filterConfig.min;
              props.onChangeProperty(
                state ? defaultValueRaw ?? minValue : null,
                path,
              );
            }}
          />
        );
      })}
    </TitleSection>
  );
};

const AgentSettingsGroupAsTable: React.FC<AgentSettingsGroupProps> = props => {
  const { group } = props;
  const [id] = useState<string>(_.uniqueId());

  return (
    <TitleSection
      key={`resource-policy-editor-section-${group.title}-${id}`}
      id={`resource-policy-editor-section-${group.title}${id}`}
      title={group.title}
      collapsible
      // inSidebarView
      inTabView
      hasPadding
      hasScreenTitle
      titleView
      className={classNames(
        'top-20',
        'text-base uppercase',
        'border-opacity-20',
      )}
    >
      <ContainerSectionSettings
        title={group.title}
        description={group.description}
      >
        {group.filters.map(filter => {
          const path = filter.path.join('.');
          let value = _.get(props.agentSettings, path);
          const defaultValueRaw = _.get(props.agentDefaults, path);
          let defaultValue = defaultValueRaw;
          if (filter.filterConfig.load) {
            if (!_.isNil(value)) {
              value = filter.filterConfig.load(value);
            }

            defaultValue = !_.isNil(defaultValue)
              ? filter.filterConfig.load(defaultValueRaw)
              : filter.filterConfig.min;
          }

          return (
            <div
              data-component="SlideContainer"
              key={`${filter.title}-container-${id}`}
              className={classNames('flex flex-1', 'py-4')}
            >
              <InputSlider
                title={filter.title}
                unitOfMeasure={filter.filterConfig.unitOfMeasure}
                values={[value ?? defaultValue]}
                min={filter.filterConfig.min}
                max={filter.filterConfig.max}
                stepSize={filter.filterConfig.step}
                isDisabled={props.editMode === 'disabled'}
                onChange={values => {
                  const value = filter.filterConfig.save
                    ? filter.filterConfig.save(_.head(values))
                    : _.head(values);
                  props.onChangeProperty(value, path);
                }}
                hasSwitch={!filter.filterConfig.isRequired}
                switchValue={!_.isNil(value)}
                onSwitchChange={state => {
                  const minValue = filter.filterConfig.save
                    ? filter.filterConfig.save(filter.filterConfig.min)
                    : filter.filterConfig.min;
                  props.onChangeProperty(
                    state ? defaultValueRaw ?? minValue : null,
                    path,
                  );
                }}
              />
            </div>
          );
        })}
      </ContainerSectionSettings>
    </TitleSection>
  );
};

const AgentSettingsSection: React.FC<AgentSettingsSectionProps> = props => {
  const { t } = useTranslation('simulation');

  return (
    <>
      <ScreenTitle
        title={props.section.title}
        subtitle={t`Agent`}
        isSticky
        hasStepCounter={props.section.counter}
      />
      <InputGroupList hasPadding panelMode>
        {props.section.filterGroups.map(group => (
          <AgentSettingsGroupAsTable
            key={`agent-settings-group-${group.title}`}
            group={group}
            {...props}
          />
        ))}
      </InputGroupList>
    </>
  );
};

export default AgentSettingsSection;
