import * as React from 'react';
const ReloadIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ReloadIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M106.41,21.59c-10.86-10.86-25.86-17.59-42.41-17.59C30.92,4,4,30.92,4,64s26.92,60,60,60,60-26.92,60-60h-15c0,24.81-20.19,45-45,45s-45-20.19-45-45S39.19,19,64,19c12.41,0,23.66,5.05,31.81,13.2l-16.8,16.8h45V4l-17.59,17.59Z"
    />
  </svg>
);
export default ReloadIcon;
