import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import RuleContainer from '../components/policies/RuleContainer';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import {
  waypointPolicyRule,
  waypointPolicySelectedIdentity,
} from '../simulation/store/waypointPolicy/waypointPolicy.state';
import WaypointPolicyRuleSection from './WaypointPolicyRuleSection';

export type WaypointPolicyRuleProps = {
  ruleId: string;
  index?: number;
  hasIssue?: boolean;
  isDisabled?: boolean;
  color?: [string, string];
  canDelete?: boolean;
  onDeleteClick?: () => void;
  canRename?: boolean;
};

const WaypointPolicyRule: React.FC<WaypointPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const [rule, updateRule] = useRecoilState(waypointPolicyRule(props.ruleId));
  const selectedIdentity = useRecoilValue(waypointPolicySelectedIdentity);
  const canUpdate = useRecoilValue(simulationIsEditable);

  const { ruleId } = props;

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  const isSelectedRule = ruleId === selectedIdentity?.ruleId;

  return (
    <RuleContainer
      dataComponent="WaypointPolicyRule"
      key={`policy-waypoint-rule-${ruleId}`}
      id={`policy-waypoint-rule-${ruleId}`}
      title={rule.title}
      hasIcon={true}
      hasColorMode={false}
      isCollapsible={true}
      isActive={isSelectedRule}
      //onClick={selectRule}
      isDisabled={!canUpdate}
      color={_.head(props.color)}
      isRemovable={true}
      onDeleteClick={props.onDeleteClick}
      canRename={true}
      onTitleChange={renameRule}
      classNameInner={classNames(
        'p-1 xl:p-2 bg-sidebar-header/70',
        'space-y-2',
      )}
    >
      <WaypointPolicyRuleSection
        ruleId={ruleId}
        groupType="start"
        title={t`Start (Locations)`}
      />
      <WaypointPolicyRuleSection
        ruleId={ruleId}
        groupType="end"
        title={t`End (Locations)`}
      />
    </RuleContainer>
  );
};

export default WaypointPolicyRule;
