import { SizeComplianceLocationItemFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { FormattedValueWithUnit } from '../../../common/formatHelper';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../common/measureHelper';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import * as Icon from '../../../components/icons';
import { StatGroup } from '../../../components/stats/StatGroup';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import { warehouseMeasurementSystem } from '../../../store/warehouse.state';
import {
  getSizeColorByStatus,
  getSizeComplianceStatusMap,
} from '../../store/sizeCompliance.helper';
import { sizeComplianceSelectedLocation } from '../../store/sizeCompliance.state';

export type LocationPropertiesSizeComplianceProps = {
  locationId: string;
};

const LocationPropertiesSizeCompliance: React.FC<
  LocationPropertiesSizeComplianceProps
> = props => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('simulation');

  const locationCompliance = useRecoilValue(sizeComplianceSelectedLocation);
  const formatter = useFormatter();

  const sectionTitle = t`Size Compliance`;

  const ms = useRecoilValue(warehouseMeasurementSystem);

  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty location`} message_helper={t`(No Items)`} />
  );

  if (_.isNil(locationCompliance)) {
    return <EmptyPlaceholder />;
  }

  function getItems(items: SizeComplianceLocationItemFragment[]) {
    return _(items)
      .map(product => {
        let w: FormattedValueWithUnit,
          h: FormattedValueWithUnit,
          l: FormattedValueWithUnit;
        // console.log('::w product', w);
        // console.log('::h product', h);
        // console.log('::l product', l);
        if (product.uom) {
          w = formatter.formatDistance(product.uom.width);
          h = formatter.formatDistance(product.uom.height);
          l = formatter.formatDistance(product.uom.length);
          // console.log('::w product.uom', w);
          // console.log('::h product.uom', h);
          // console.log('::l product.uom', l);
        }

        const [background, color] = getSizeColorByStatus(product.status);

        const sizeMeasureValue = getMeasureTitle(
          getDefaultMeasure(ms, 'size'),
          tMeasures,
        );

        const statusMap = getSizeComplianceStatusMap(t);

        // Volume - Calculated
        const volumeCalculatedRaw =
          product.uom?.width * product.uom?.height * product.uom?.length;
        const volumeCalculated = formatter.formatVolume(volumeCalculatedRaw);

        return (
          <React.Fragment key={`item-${product.sku}`}>
            <StatListItem
              titleSelectable
              title={product.sku}
              value={`${statusMap[product.status]}`}
              valueStyle={{
                background,
                color,
              }}
              labelValueType="itemId"
              labelValueFilters={{
                consignee: product.consignee,
                sku: product.sku,
              }}
            ></StatListItem>

            {!_.isEmpty(product.uom) && (
              <div className={`flex items-start mb-4`}>
                <Icon.BoxMargined
                  className={`h-5 w-5 mt-3 ltr:mr-2 rtl:ml-2 mx-0.5 fill-current text-menu-100`}
                />
                <div className={`flex flex-col flex-1`}>
                  <div
                    className={`flex items-end border-menu-400/30 border-b mt-2 mb-1 py-1 text-xs uppercase text-menu/60`}
                  >
                    <div className="flex-1 uppercase">
                      {t`Dimensions`}
                      {/* {t(`Dimension: {{productUOM}}`, {
                        productUOM: product.uom.uom,
                      })} */}
                    </div>
                    <div
                      data-component="Value"
                      className="text-end text-xs p-1 px-2 rounded border border-menu-400"
                    >
                      {product.uom.uom}
                    </div>
                  </div>
                  {/* <ObjectSize
                    object={product.sku}
                    unitMeasurement={sizeMeasureValue}
                    hasSize
                    sizeWidth={product.uom.width}
                    sizeHeight={product.uom.height}
                    sizeLength={product.uom.length}
                    disabled
                  /> */}

                  <StatGroup className="">
                    <StatListItem
                      titleSelectable
                      title={t`Width`}
                      value={product.uom.width}
                      unitOfMeasure={sizeMeasureValue}
                    />
                    <StatListItem
                      titleSelectable
                      title={t`Height`}
                      value={product.uom.height}
                      unitOfMeasure={sizeMeasureValue}
                    />
                    <StatListItem
                      titleSelectable
                      title={t`Length`}
                      value={product.uom.length}
                      unitOfMeasure={sizeMeasureValue}
                    />
                    <StatListItem
                      titleSelectable
                      title={t`Volume`}
                      value={volumeCalculated.value}
                      valueRaw={volumeCalculated.raw}
                      unitOfMeasure={volumeCalculated.unit}
                    />
                  </StatGroup>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })
      .value();
  }
  const [background, color] = getSizeColorByStatus(locationCompliance.status);
  const statusMap = getSizeComplianceStatusMap(t);

  return (
    <PanelContainer
      id="panel-compliance-size-items"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      <StatListItem
        title={t`Status`}
        value={`${statusMap[locationCompliance.status]}`}
        valueStyle={{
          background,
          color,
        }}
      ></StatListItem>

      {!_.isEmpty(locationCompliance.items) && (
        <>
          <StatListHeader title={t`Item`} value={t`Size`} />
          {getItems(locationCompliance.items)}
        </>
      )}
    </PanelContainer>
  );
};

export default LocationPropertiesSizeCompliance;
