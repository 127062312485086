import * as React from 'react';
const MoveTypeMoveHorizontalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeMoveHorizontalIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M108.28,45c-10.48,0-19,8.52-19,19,0,10.48,8.52,19,19,19s19-8.52,19-19c0-10.48-8.52-19-19-19ZM118.87,57.66l-11.69,13.15c-.29.32-.69.49-1.09.49-.32,0-.64-.1-.91-.32l-7.31-5.85c-.63-.5-.73-1.42-.23-2.05.5-.63,1.42-.73,2.05-.23l6.22,4.98,10.77-12.12c.54-.6,1.46-.66,2.06-.12.6.54.66,1.46.12,2.06Z"
      opacity={0.75}
    />
    <path
      strokeWidth={0}
      d="M7.26,50.78c-7.47,7.46-7.56,19.51-.2,26.88,7.36,7.37,19.42,7.29,26.88-.16,7.47-7.46,7.56-19.51.2-26.88-7.36-7.37-19.42-7.29-26.88.16ZM28.14,58.67l-5.47,5.46,5.64,5.65c.57.57.56,1.49-.02,2.07-.58.57-1.5.58-2.07.01l-5.64-5.65-5.47,5.46c-.58.57-1.5.58-2.07.01-.57-.57-.56-1.49.02-2.07l5.47-5.46-5.13-5.14c-.57-.57-.56-1.49.02-2.07s1.5-.58,2.07-.01l5.13,5.14,5.47-5.46c.58-.57,1.5-.58,2.07-.01.57.57.56,1.49-.02,2.07Z"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M87.09,64c0-12.51-10.14-22.65-22.65-22.65-12.51,0-22.65,10.14-22.65,22.65s10.14,22.65,22.65,22.65,22.65-10.14,22.65-22.65ZM58.86,75.11l8.14-8.14h-16.27s0-5.93,0-5.93h16.27s-8.12-8.15-8.12-8.15h8.36s11.08,11.11,11.08,11.11l-11.1,11.11h-8.36Z"
    />
  </svg>
);
export default MoveTypeMoveHorizontalIcon;
