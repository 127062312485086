import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import { viewerSelectedPlaneId } from '../../layout/viewer/store/viewer.state';
import { getEventColor } from '../store/simulation.helper';
import {
  simulationLayoutPicklistRoute,
  simulationLayoutSelectedPicklistId,
  simulationLayoutSelectedRoutePart,
} from '../store/simulation.layout.state';
import SimulationRouteLayerBase from './SimulationRouteLayerBase';

const routeColor = TwTheme.extend.colors.route;

type PicklistRouterProps = {
  picklistId: string;
  color: string;
  animate: boolean;
  wpType?: 'before' | 'after';
};

export const SimulationPicklistRoute: React.FC<PicklistRouterProps> = props => {
  const route = useRecoilValue(simulationLayoutPicklistRoute(props.picklistId));
  const planeId = useRecoilValue(viewerSelectedPlaneId);
  if (_.isNil(route) || planeId !== route?.areaId) {
    return null;
  }

  return (
    <SimulationRouteLayerBase
      key={route.id}
      waypoints={route.waypoints}
      color={props.color}
      animate={props.animate}
      wpType={props.wpType}
    />
  );
};

const SimulationRouteLayer: React.FC = () => {
  const routePart = useRecoilValue(simulationLayoutSelectedRoutePart);
  const selectedPicklistId = useRecoilValue(simulationLayoutSelectedPicklistId);
  const color = routePart ? routeColor.base : null;
  if (_.isNil(selectedPicklistId)) return null;

  return (
    <SimulationPicklistRoute
      key={selectedPicklistId}
      picklistId={selectedPicklistId}
      color={color}
      animate={_.isNil(routePart)}
    />
  );
};

export default SimulationRouteLayer;

export const SimulationRoutePartLayer: React.FC = () => {
  const route = useRecoilValue(simulationLayoutSelectedRoutePart);
  const planeId = useRecoilValue(viewerSelectedPlaneId);
  if (!route || route.areaId !== planeId) return null;

  let color = routeColor.hover;

  if (route.eventType) {
    color = getEventColor(route.eventType, true);
  }
  return (
    <SimulationRouteLayerBase
      waypoints={route.waypoints}
      color={color}
      animate={true}
    />
  );
};
