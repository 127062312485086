import { QueryCreator } from 'kysely';
import { DatasetDatabase } from './datasetQueryBuilder';
import { ActivityFeedQueryBuilderParams } from './feedEventsQueryBuilders';

export type AnalyzeEventsQueryBuilderParams = ActivityFeedQueryBuilderParams & {
  actualityId: string;
};

export function getAnalyzeEventsFilteredQueryBuilder(
  params: AnalyzeEventsQueryBuilderParams,
  db: QueryCreator<DatasetDatabase>,
) {
  const at = db
    .selectFrom('___analyzed_job_event___')
    .where('documentId', '=', params.actualityId);
  return at;
}
