import {
  AisleSide,
  BayLocationOrder,
  LayoutImportConverterAreaFragment,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AisleBuilderSettings,
  AreaBuilderSettings,
  AreaConfigurationMap,
  BayRowBuilderSettings,
  BayTypeSettings,
  BaysAlignOption,
  BaysOrderInAisleOption,
  LayoutBuilderSettings,
  LayoutFloorSettings,
  LocationTypeSettings,
  OrderTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import {
  MeasurementSystem,
  mergeBoxes,
} from '@warebee/shared/data-access-layout-manager';
import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import _ from 'lodash';
import { ConvertedArea } from '../converter.model';

export const CONVERTER_SAVE_PREPARE_ID = 'PREPARING_SAVE';
const AISLE_WIDTH_CM = 350;
const AISLE_WIDTH_INCH = 144;
const TUNNEL_MINIMAL_WIDTH_CM = 50;
const TUNNEL_MINIMAL_WIDTH_INCH = 20;

export function getAisleWidthDefault(system: MeasurementSystem): number {
  switch (system) {
    case MeasurementSystem.METRIC:
      return AISLE_WIDTH_CM;
    case MeasurementSystem.IMPERIAL:
      return AISLE_WIDTH_INCH;
  }
}

export function getMinimalTunnelWidthDefault(
  system: MeasurementSystem,
): number {
  switch (system) {
    case MeasurementSystem.METRIC:
      return TUNNEL_MINIMAL_WIDTH_CM;
    case MeasurementSystem.IMPERIAL:
      return TUNNEL_MINIMAL_WIDTH_INCH;
  }
}

const DEFAULT_BAY_CONFIG_CM: BayTypeSettings = {
  bayType: DEFAULT_BAY_TYPE,
  verticalFrameProfile: 8,
  shelfHeight: 10,
  maxWeight: 5000,
  levelHeights: {},
};

const DEFAULT_BAY_CONFIG_INCH: BayTypeSettings = {
  bayType: DEFAULT_BAY_TYPE,
  verticalFrameProfile: 3,
  shelfHeight: 4,
  maxWeight: 5000,
  levelHeights: {},
};

export function getBayDefaultConfig(
  system: MeasurementSystem,
): BayTypeSettings {
  switch (system) {
    case MeasurementSystem.METRIC:
      return DEFAULT_BAY_CONFIG_CM;
    case MeasurementSystem.IMPERIAL:
      return DEFAULT_BAY_CONFIG_INCH;
  }
}

const DEFAULT_LOCATION_CONFIG_S_CM: Omit<LocationTypeSettings, 'typeId'> = {
  gapDepth: 0,
  gapWidth: 1,
  gapHeight: 1,
  isWeakDepth: false,
};

const DEFAULT_LOCATION_CONFIG_S_INCH: Omit<LocationTypeSettings, 'typeId'> = {
  gapDepth: 0,
  gapWidth: 0,
  gapHeight: 4,
  isWeakDepth: false,
};

const DEFAULT_LOCATION_CONFIG_M_CM: Omit<LocationTypeSettings, 'typeId'> = {
  gapDepth: 0,
  gapWidth: 4,
  gapHeight: 4,
  isWeakDepth: false,
};

const DEFAULT_LOCATION_CONFIG_M_INCH: Omit<LocationTypeSettings, 'typeId'> = {
  gapDepth: 0,
  gapWidth: 1,
  gapHeight: 4,
  isWeakDepth: false,
};

const DEFAULT_LOCATION_CONFIG_CM: Omit<LocationTypeSettings, 'typeId'> = {
  gapDepth: 0,
  gapWidth: 10,
  gapHeight: 10,
  isWeakDepth: false,
};

const DEFAULT_LOCATION_CONFIG_INCH: Omit<LocationTypeSettings, 'typeId'> = {
  gapDepth: 0,
  gapWidth: 4,
  gapHeight: 4,
  isWeakDepth: false,
};

export function getLocationDefaultConfig(
  system: MeasurementSystem,
  maxWidth?: number,
): Omit<LocationTypeSettings, 'typeId'> {
  switch (system) {
    case MeasurementSystem.METRIC:
      if (_.isNil(maxWidth) || maxWidth > 80) return DEFAULT_LOCATION_CONFIG_CM;
      if (maxWidth > 0 && maxWidth < 25) return DEFAULT_LOCATION_CONFIG_S_CM;
      //25 .... 80
      return DEFAULT_LOCATION_CONFIG_M_CM;
    case MeasurementSystem.IMPERIAL:
      if (_.isNil(maxWidth) || maxWidth > 30)
        return DEFAULT_LOCATION_CONFIG_INCH;
      if (maxWidth > 0 && maxWidth < 10) return DEFAULT_LOCATION_CONFIG_S_INCH;
      //25 .... 80
      return DEFAULT_LOCATION_CONFIG_M_INCH;
  }
}

export const DEFAULT_FLOOR_CONFIG: LayoutFloorSettings = {
  id: 'default-floor',
  title: '0',
};

export const BayRowBuilderSettingsDefault: BayRowBuilderSettings = {
  align: null,
  orderType: OrderTypeOption.Default,
  shift: 0,
};

export function getDefaultAreaBuilderSettings(
  system: MeasurementSystem,
): Omit<AreaBuilderSettings, 'id'> {
  return {
    aisleWidth: getAisleWidthDefault(system),
    hasBottomAccessAisle: false,
    hasTopAccessAisle: false,
    baysAlign: BaysAlignOption.Bottom,
    baysOrder: BaysOrderInAisleOption.Parallel,
    useAutoSpacers: true,
    minimalTunnelWidth: getMinimalTunnelWidthDefault(system),
    mergeAisles: [],
  };
}

export function getDefaultLayoutBuilderSettings(): Pick<
  LayoutBuilderSettings,
  'floors'
> {
  return {
    floors: { [DEFAULT_FLOOR_CONFIG.id]: DEFAULT_FLOOR_CONFIG },
  };
}

export const DefaultAisleBuilderSetting: Omit<AisleBuilderSettings, 'aisleId'> =
  {
    bayRowSettings: {
      [AisleSide.LEFT]: BayRowBuilderSettingsDefault,
      [AisleSide.RIGHT]: BayRowBuilderSettingsDefault,
    },
    locationOrderSettings: {
      [AisleSide.LEFT]: BayLocationOrder.LTR,
      [AisleSide.RIGHT]: BayLocationOrder.RTL,
    },
  };

export function getDefaultAreaConfiguration(
  areas: ConvertedArea[],
): AreaConfigurationMap {
  let padX = 0;
  let prevPad = 0;
  return _.reduce(
    areas,
    (conf, area, index) => {
      padX += prevPad;
      const allObjects = [...area.aisles, ...area.bays];
      const box = mergeBoxes(allObjects.map(obj => obj.shape.box));
      prevPad = 1.1 * box.xmax;
      return {
        ...conf,
        [area.id]: {
          id: area.id,
          x: padX,
          y: 0,
          rotation: 0,
          floor: 'default',
          flipX: false,
          flipY: false,
        },
      };
    },
    {} as AreaConfigurationMap,
  );
}

export function getDefaultAreaConfigurationRaw(
  areas: LayoutImportConverterAreaFragment[],
  aisleWidth,
): AreaConfigurationMap {
  let padX = 0;
  let prevPad = 0;
  return _.reduce(
    areas,
    (conf, area, index) => {
      padX += prevPad;
      prevPad = (area.aisles?.length || 0) * 2 * aisleWidth;
      // const allObjects = [...area.aisles, ...area.bays];
      // const box = mergeBoxes(allObjects.map(obj => obj.shape.box));
      // prevPad = 1.1 * box.xmax;
      return {
        ...conf,
        [area.area]: {
          id: area.area,
          x: padX,
          y: 0,
          rotation: 0,
          floor: DEFAULT_FLOOR_CONFIG.id,
          flipX: false,
          flipY: false,
        },
      };
    },
    {} as AreaConfigurationMap,
  );
}
