import * as React from 'react';
const BoxOpenIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="BoxOpenIcon"
    {...props}
  >
    <g isolation="isolate">
      <polygon
        strokeWidth={0}
        points="60.44 14.17 15.37 41.97 1.34 42.52 46.42 14.72 60.44 14.17"
      />
    </g>
    <polygon
      strokeWidth={0}
      points="15.59 45.56 2 60.76 47.23 86.88 60.83 71.67 15.59 45.56"
    />
    <polygon
      strokeWidth={0}
      points="47.45 90.47 45.19 89.17 17.55 73.21 17.51 97.72 62.74 123.83 62.74 73.36 49.49 88.18 47.45 90.47"
    />
    <polygon
      strokeWidth={0}
      points="17.61 73.34 17.6 86.67 29.07 79.95 17.61 73.34"
    />
    <polygon
      strokeWidth={0}
      points="54.8 64.89 62.74 68.97 62.74 15.47 17.63 43.28 17.63 43.37 54.8 64.89"
    />
    <g isolation="isolate">
      <polygon
        strokeWidth={0}
        points="67.56 14.17 112.63 41.97 127.66 42.52 82.58 14.72 67.56 14.17"
      />
    </g>
    <polygon
      strokeWidth={0}
      points="112.41 45.56 126 60.76 80.77 86.88 67.17 71.67 112.41 45.56"
    />
    <polygon
      strokeWidth={0}
      points="80.55 90.47 82.81 89.17 110.45 73.21 110.49 97.72 65.26 123.83 65.26 73.36 78.51 88.18 80.55 90.47"
    />
    <polygon
      strokeWidth={0}
      points="110.39 73.34 110.4 86.67 98.93 79.95 110.39 73.34"
    />
    <polygon
      strokeWidth={0}
      points="73.2 64.89 65.26 68.97 65.26 15.47 110.37 43.28 110.37 43.37 73.2 64.89"
    />
  </svg>
);
export default BoxOpenIcon;
