import { BayTypeSettings } from '@warebee/shared/data-access-layout-import-converter';
import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import React from 'react';
import { Group } from 'react-konva';
import { TwTheme } from '../../../../../Tw';
import AisleFeature from '../../../../layout/features/AisleFeature';
import BayFeature from '../../../../layout/features/BayFeature';
import IntersectionFeature2 from '../../../../layout/features/IntersectionFeature2';
import { getPoints } from '../../../../layout/viewer/store/viewer.helper';
import { ConvertedAreaFeature } from '../converter.serializable.model';

const bayColors = TwTheme.extend.colors.bay;

export type ConverterAreaContentProps = {
  area: ConvertedAreaFeature;
  bayTypes: Record<string, BayTypeSettings>;
};

const ConverterAreaContent: React.FC<ConverterAreaContentProps> = props => {
  const { area } = props;

  //ignore area completely if it not intersects with visible area
  //if (props.visibleArea.not_intersect(area.shape.box)) return null;
  const drawBays = true;
  // bayTargetHeight * bayPreviewThresholdLimit * visibleOverlap >
  // Math.min(
  //   Math.abs(props.visibleArea.ymin - props.visibleArea.ymax),
  //   Math.abs(props.visibleArea.xmin - props.visibleArea.xmax),
  // );

  // const visibleBays = drawBays
  //   ? area.bays.filter(bay => {
  //       return (
  //         _.inRange(
  //           bay.shape.coordinates[0][0][0],
  //           props.visibleArea.xmin,
  //           props.visibleArea.xmax,
  //         ) &&
  //         _.inRange(
  //           bay.shape.coordinates[0][0][1],
  //           props.visibleArea.ymin,
  //           props.visibleArea.ymax,
  //         )
  //       );
  //     })
  //   : [];
  const visibleBays = area.bays;

  return (
    <Group>
      {!drawBays && (
        <IntersectionFeature2
          id={`area-overlay-${area.id}`}
          points={getPoints(area.outlineShape)}
          fillColor={bayColors.DEFAULT}
          listening={false}
        />
      )}

      {area.aisles.map(aisle => (
        <AisleFeature
          key={`aisle-${aisle.id}`}
          aisle={aisle}
          isStaticMode={true}
        />
      ))}

      <Group listening={false}>
        {drawBays &&
          visibleBays.map(bay => {
            return (
              <BayFeature
                key={`bay-${bay.id}`}
                bay={bay}
                bayType={
                  props.bayTypes[bay.details.bayType ?? DEFAULT_BAY_TYPE]
                }
                isStaticMode={true}
                hideTitle={true}
              />
            );
          })}
      </Group>

      <IntersectionFeature2
        id={`area-action-overlay-${area.id}`}
        points={getPoints(area.outlineShape)}
        fillColor={'#00000000'}
        listening={true}
      />
    </Group>
  );
};
export default React.memo(ConverterAreaContent);
