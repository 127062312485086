import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../common/utils';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import LayoutActiveFeatureTag from '../layout/LayoutActiveFeatureTag';
import SimulationLayoutView from '../simulation/SimulationLayoutView';
import AllocationSummary from '../simulation/panels/allocate/AllocationSummary';
import SimulationAllocationView from '../simulation/stepContentViews/SimulationAllocationView';
import { allocationViewAs } from '../simulation/store/allocationPolicy/allocationPolicy.state';
import AllocationRequirementGenerateButton from './AllocationRequirementGenerateButton';
import AllocationRuleFilterTag from './AllocationRuleFilterTag';
import AllocationRunGenerateButton from './AllocationRunGenerateButton';
import AllocationSettingsViewSwitch from './AllocationSettingsViewSwitch';

/**
 *  Main content view for Allocation Setup
 */

const AllocationPreview: React.FC = () => {
  const { t } = useTranslation('simulation');
  const viewAs = useRecoilValue(allocationViewAs);

  return (
    <Container
      componentName={`AllocationPreview`}
      col
      overflow
      className={cn('border-app-panel-dark flex-1 ltr:border-l rtl:border-r')}
    >
      <Container overflow className={cn('relative !h-auto')}>
        <PanelHeader
          title={
            <>
              {viewAs === 'allocation-requirement' && (
                <AllocationRequirementGenerateButton />
              )}
              {viewAs === 'allocation-run' && <AllocationRunGenerateButton />}
            </>
          }
          className={cn('flex-1')}
        >
          {/* <Spacer flexspace /> */}
          <LayoutActiveFeatureTag />
          <AllocationRuleFilterTag />
          <AllocationSettingsViewSwitch />
        </PanelHeader>
      </Container>

      {viewAs === 'allocation-requirement' && <SimulationAllocationView />}
      {viewAs === 'allocation-run' && <AllocationSummary />}
      {viewAs === 'layout' && (
        <Container
          overflow
          flex1
          className={cn('relative', 'border-app-background/70 border-8')}
        >
          <Container col overflow-hidden className={cn('relative flex-1')}>
            <SimulationLayoutView stepId={'analyse'} />
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default AllocationPreview;
