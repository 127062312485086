import { OptimizationRunStatus } from '@warebee/frontend/data-access-api-graphql';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useLoadOptimizeResult } from '../../warehouse/hooks/useLoadOptimizeResult';
import useLoadOptimizeStatus from '../../warehouse/hooks/useLoadOptimizeStatus';
import {
  optimisationResult,
  optimisationResultStatus,
  optimisationStatus,
} from '../store/optimisation.state';
import { simulationCurrentId } from '../store/simulation.state';
import { simulationWizardSelectedStepId } from '../store/simulation.wizard.state';

function useSimulationTaskProgressWatcher() {
  const simId = useRecoilValue(simulationCurrentId);
  const optimisationProgress = useRecoilValue(optimisationResultStatus);
  const optResult = useRecoilValue(optimisationResult);
  const optStatus = useRecoilValue(optimisationStatus);
  const loadOptimizeStatus = useLoadOptimizeStatus();
  const loadOptimizeResult = useLoadOptimizeResult();
  const stepId = useRecoilValue(simulationWizardSelectedStepId);

  useEffect(() => {
    const loadCallback =
      stepId === 'optimise' ? loadOptimizeResult : loadOptimizeStatus;

    const status = stepId === 'optimise' ? optResult?.status : optStatus;

    const isOptimising =
      status === OptimizationRunStatus.CREATED ||
      status === OptimizationRunStatus.IN_PROGRESS ||
      status === OptimizationRunStatus.STOPPING;

    if (isOptimising && optimisationProgress?.id) {
      const timeoutId = setTimeout(() => {
        loadCallback(optimisationProgress.id);
      }, 5000);
      return () => {
        // console.log('Clear optimisation status timeout');
        clearTimeout(timeoutId);
      };
    }
  }, [optimisationProgress, simId, optStatus, stepId]);
}

export default useSimulationTaskProgressWatcher;
