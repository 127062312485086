import * as React from 'react';
const CloudUploadCsvIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CloudUploadCsvIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M10.72,123.51v-18.89h12.78c2.42,0,4.74-.97,6.45-2.68,1.71-1.71,2.67-4.03,2.68-6.45v-12.78h25.57v22.78c0,1.01.82,1.83,1.83,1.83h18.26c.48,0,.95-.19,1.29-.53s.53-.81.53-1.29h0v-22.78h27.39c1.45,0,2.85.58,3.87,1.61,1.03,1.03,1.61,2.42,1.61,3.87v35.33H10.72ZM28.98,95.49v-10.2l-15.68,15.68h10.2c1.45,0,2.85-.58,3.87-1.61,1.03-1.03,1.6-2.42,1.61-3.87h0Z"
    />
    <path
      strokeWidth={0}
      d="M69.15,50.64l14.05,14.03v10.58s-10.3-10.29-10.3-10.29v35.49s-7.49,0-7.49,0v-35.49s-10.3,10.29-10.3,10.29v-10.58s14.04-14.03,14.04-14.03Z"
    />
    <path
      strokeWidth={0}
      d="M105.08,29.59h0c-1.88-14.15-14.02-25.1-28.68-25.1-10.97,0-18.68,6.81-22.49,11.17-3.17-1.89-6.81-2.91-10.58-2.91-10.08,0-18.5,7.26-20.31,16.82-11.89,1.82-21.03,12.12-21.03,24.51,0,13.67,11.13,24.8,24.8,24.8h21.32v-17.12s21.03-21.02,21.03-21.02l21.03,21.02v17.11h11.02c13.67,0,24.8-11.13,24.8-24.8,0-12.35-9.08-22.63-20.92-24.5Z"
    />
  </svg>
);
export default CloudUploadCsvIcon;
