import {
  LoadWeightComplianceMetaDocument,
  LoadWeightComplianceMetaQuery,
  LoadWeightComplianceMetaQueryVariables,
  RunCalculateWeightComplianceDocument,
  RunCalculateWeightComplianceMutation,
  RunCalculateWeightComplianceMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  weightComplianceMeta,
  weightComplianceMetaLoadStatus,
} from '../store/weightCompliance.state';

export type LoadWeightComplianceMetaParams = {
  simulationId: string;
};

function useLoadWeightComplianceMeta() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load size compliance metadata`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(weightComplianceMetaLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadWeightComplianceMetaParams) => {
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(weightComplianceMetaLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadWeightComplianceMetaQuery,
          LoadWeightComplianceMetaQueryVariables
        >({
          query: LoadWeightComplianceMetaDocument,
          variables: {
            simulationId: params.simulationId,
          },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }
            let compliance = data?.simulation?.weightCompliance;

            if (_.isNil(compliance)) {
              try {
                const response = await secureClient.mutate<
                  RunCalculateWeightComplianceMutation,
                  RunCalculateWeightComplianceMutationVariables
                >({
                  mutation: RunCalculateWeightComplianceDocument,
                  variables: {
                    simulationId: params.simulationId,
                  },
                });

                compliance = response.data?.calculateSimulationWeightCompliance;
              } catch (ex) {
                console.error(errorTitle, ex);
                handleError(ex.message || ex, ex.stack || null);
              }
            }
            set(weightComplianceMeta, compliance);
            set(weightComplianceMetaLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadWeightComplianceMetaParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadWeightComplianceMeta;
