const LinkBothIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkBothIcon"
    {...props}
  >
    <path d="M36.71,116.57c0,1.37.56,2.72,1.53,3.69.98.98,2.29,1.53,3.68,1.53h74.64c1.39,0,2.7-.54,3.69-1.53.97-.97,1.52-2.31,1.52-3.68v-4.19s0-70.45,0-70.45c0-1.39-.54-2.7-1.53-3.68-.98-.98-2.29-1.53-3.69-1.53h-46.71s10,10.42,10,10.42h11.42s20.07,0,20.07,0v64.22s-64.22,0-64.22,0v-16.42h-10.43v21.63Z" />
    <path d="M46.4,80.86h-29.76s0-64.22,0-64.22h64.22s0,15.94,0,15.94h10.42s0-21.15,0-21.15c0-2.87-2.34-5.21-5.21-5.21H11.43c-1.37,0-2.72.55-3.69,1.52-.97.97-1.53,2.31-1.53,3.68v74.64c0,1.37.56,2.71,1.53,3.68.97.97,2.31,1.53,3.68,1.53h44.97l-10-10.42Z" />
    <polygon points="103.56 74.39 92.41 63.27 92.43 84.9 71.19 63.66 71.19 63.64 42.53 35.01 64.15 35 53.02 23.88 23.54 23.88 23.54 53.38 34.69 64.5 34.68 42.88 55.92 64.12 55.92 64.14 84.57 92.77 62.96 92.78 74.08 103.89 103.56 103.89 103.56 74.39" />
  </svg>
);
export default LinkBothIcon;
