import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { StepListItemHelper } from '../../components/helpers/StepListItemHelper';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { HelpItemListCard } from '../../dashboard/containers/HelpItemListCard';
import { HelpItemListCardsContainer } from '../../dashboard/containers/HelpItemListCardsContainer';
import Callout from '../../helpContext/Callout';
import HelpContainer from '../../helpContext/HelpContainer';

const SimulationHelperGettingStarted: React.FC = () => {
  const { t } = useTranslation('help');

  return (
    <Container col hasOverflowY className="relative">
      <ScreenTitle
        title={t`What is a Simulation?`}
        subtitle={t`Simulation - Getting started`}
        isSticky
        truncate
      />

      <HelpContainer id={'simulation/simulation-how-it-works'} hasPadding />

      <TitleSection title={t`1. Simulation Dataset`} isHelpTitle isPrimary />
      <HelperMessage hasMargin hasPadding>
        <HelperMessage hasAfter hasBefore isLead textSize={'hero'}>
          {t`The simulation dataset consists of 4 data elements:`}
        </HelperMessage>
        <HelpItemListCardsContainer>
          <HelpItemListCard>
            <StepListItemHelper stepCountId="1" title={t`Layout`}>
              <div className={classNames('float-left w-20 h-20 m-3')}>
                <Icon.SimulationVisualize
                  className={classNames('fill-current w-20 h-20')}
                />
              </div>
              {t`The digital model of the physical warehouse that the WareBee AI engine will create automatically based on the location data you provide. Use the CSV template to import the locations data or select the existing layout.`}
            </StepListItemHelper>
          </HelpItemListCard>
          <HelpItemListCard>
            <StepListItemHelper stepCountId="2" title={t`Assignment`}>
              <div className={classNames('float-left w-20 h-20 m-3')}>
                <Icon.DataAssignment
                  className={classNames('fill-current w-20 h-20')}
                />
              </div>
              {t`Item assignment to the picking locations in your warehouse. It represents the current state of the warehouse. Use the CSV template to import the assignment data or select the existing assignment.`}
            </StepListItemHelper>
          </HelpItemListCard>
          <HelpItemListCard>
            <StepListItemHelper stepCountId="3" title={t`Orders`}>
              <div className={classNames('float-left w-20 h-20 m-3')}>
                <Icon.DataOrders
                  className={classNames('fill-current w-20 h-20')}
                />
              </div>
              {t`Customer orders that WareBee AI will fulfil in the warehouse, creating step-by-step warehouse processes. You can import orders from different periods to compare your warehouse's performance. Use the CSV template to import the order data. `}
            </StepListItemHelper>
          </HelpItemListCard>
          <HelpItemListCard>
            <StepListItemHelper stepCountId="4" title={t`Items`}>
              <div className={classNames('float-left w-20 h-20 m-3')}>
                <Icon.DataItems
                  className={classNames('fill-current w-20 h-20')}
                />
              </div>
              {t`Catalogue of items (master data) stored in your warehouse. WareBee AI can use an item's dimensions, packaging structure, and other attributes like "Item Group" to accurately predict picking and travelling times. Use the CSV template provided to import the items data.`}
            </StepListItemHelper>
          </HelpItemListCard>
        </HelpItemListCardsContainer>
        <Callout type="suggestion">
          {t`WareBee will analyse your data and present data inconsistencies, missing items, missing locations and other data issues to review.`}
        </Callout>
      </HelperMessage>

      <TitleSection title={t`2. Warehouse Policies`} isHelpTitle isPrimary />
      <HelperMessage hasMargin hasPadding>
        <HelperMessage hasAfter hasBefore isLead textSize={'hero'}>
          {t`Rule-based warehouse modelling`}
        </HelperMessage>
        <HelpItemListCardsContainer>
          <HelpItemListCard>
            <HelperMessage>
              <StepListItemHelper title={t`Resource Policy`}>
                <div className={classNames('float-left w-20 h-20 m-3')}>
                  <Icon.PolicyPickingAgents
                    className={classNames('fill-current w-20 h-20')}
                  />
                </div>
                {t`A definition of the resources (MHE & personnel) you use in the warehouse.`}
                <HelperMessage>{t`The policy includes a very flexible activity duration calculation model, the resource cost model and CO2 parameters.`}</HelperMessage>
              </StepListItemHelper>
            </HelperMessage>
            <HelperMessage>
              {t`You will define:`}
              <ul className="divide-y divide-menu-500">
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Available resource quantities and shift duration`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Driving speeds and energy consumption`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Picking and Handling times`}</li>
              </ul>
            </HelperMessage>
          </HelpItemListCard>
          <HelpItemListCard>
            <HelperMessage>
              <StepListItemHelper title={t`Replenishment Policies`}>
                <div className={classNames('float-left w-20 h-20 m-3')}>
                  <Icon.PolicyReplenishmentAgents
                    className={classNames('fill-current w-20 h-20')}
                  />
                </div>
                {t`A definition of the resources (MHE & personnel) you are using in the warehouse.`}
                <HelperMessage>{t`The policy includes a very flexible activity duration calculation model, the resource cost model and CO2 parameters.`}</HelperMessage>
              </StepListItemHelper>
            </HelperMessage>
            <HelperMessage>
              {t`You will define:`}
              <ul className="divide-y divide-menu-500">
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Available resource quantities and shift duration`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Driving speeds and energy consumption`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Picking and Handling times`}</li>
              </ul>
            </HelperMessage>
          </HelpItemListCard>
        </HelpItemListCardsContainer>
        <Callout type="suggestion">
          {t`You can experiment with different resource types and configurations to learn the impact on warehouse operations.`}
        </Callout>
      </HelperMessage>

      <TitleSection
        title={t`3. Results & Recommendations`}
        isHelpTitle
        isPrimary
      />
      <HelperMessage hasMargin hasPadding>
        <HelperMessage hasAfter hasBefore isLead textSize={'hero'}>
          {t`Actionable insights into your operations`}
        </HelperMessage>
        <HelpItemListCardsContainer>
          <HelpItemListCard>
            <HelperMessage>
              <StepListItemHelper title={t`Warehouse Analytics`}>
                <div className={classNames('float-left w-20 h-20 m-3')}>
                  <Icon.SimulationAnalyze
                    className={classNames('fill-current w-20 h-20')}
                  />
                </div>
                {t`WareBee AI delivers analysis of your warehouse operations very quickly. Our digital twin technology creates a step-by-step operational flow that is the basis for the cost and time calculations.`}
              </StepListItemHelper>
            </HelperMessage>
          </HelpItemListCard>
          <HelpItemListCard>
            <HelperMessage>
              {t`Insights presented on interactive map-based display:`}
              <ul className="divide-y divide-menu-500">
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Picking cost time and distance`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Top Orders picking path and analysis`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`CO2 Footprint`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Picking & Handling cost, time and distance`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`ABC heatmap analysis`}</li>
                <li
                  className={classNames('py-1 ltr:pl-4 rtl:pr-4')}
                >{t`Resources utilisation`}</li>
              </ul>
            </HelperMessage>
          </HelpItemListCard>
        </HelpItemListCardsContainer>
        <Callout type="suggestion">
          {t`You can experiment with different layouts, assignments, order sets to make data-driven decisions`}
        </Callout>
      </HelperMessage>
    </Container>
  );
};

export default SimulationHelperGettingStarted;
