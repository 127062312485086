import classNames from 'classnames';
import Konva from 'konva';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, {
  PropsWithChildren,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { KonvaNodeEvents, Stage } from 'react-konva';
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import useResizeObserver from 'use-resize-observer';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import { errorAppender } from '../../store/error.state';
import {
  StageType,
  getCenter,
  getChangesOnPinDrag,
  getChangesOnWheel,
  getDistance,
} from './stage.helper';
import {
  IStagePropsState,
  stagePointerPosition,
  stageStateById,
} from './stage.state';

export type StageProps = PropsWithChildren & {
  type: StageType;
  darkBg?: boolean;
  stopListening?: boolean;
  stageRef?: React.RefObject<Konva.Stage>; // Add stageRef prop
};

const StageContainer: React.FC<StageProps> = props => {
  const [state, setState] = useRecoilState(stageStateById(props.type));
  const setPointerPosition = useSetRecoilState(
    stagePointerPosition(props.type),
  );
  const stageProps: StageCmpProps = {
    ...state,
    stageId: props.type,
    notifyChange: changes => setState(changes),
    onPointerMove: (pos: [number, number]) => setPointerPosition(pos),
    className: props.darkBg
      ? 'bg-app-panel-map-bay bg-map-bay-striped'
      : 'bg-app-panel-map-area bg-map-area-striped',
    stopListening: props.stopListening,
    stageRef: props.stageRef, // Pass stageRef to StageCmp
  };

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorIndicator selfCenter message={error.message} />
      )}
    >
      <StageCmp key={props.type} {...stageProps}>
        {props.children}
      </StageCmp>
    </ErrorBoundary>
  );
};

export type StageCmpProps = PropsWithChildren &
  Partial<IStagePropsState> & {
    stageId: string;
    notifyChange: (changes: Partial<IStagePropsState>) => void;
    onPointerMove: (pos: [number, number]) => void;
    className?: string;
    stopListening?: boolean;
    stageRef?: React.RefObject<Konva.Stage>; // Add stageRef prop
  };

const StageCmp: React.FC<StageCmpProps> = props => {
  const setError = useSetRecoilState(errorAppender);
  const stageRef = props.stageRef || useRef<Konva.Stage>(null); // Use passed stageRef or create a new one
  const { t } = useTranslation('errors');
  const isInit = useRef(false);
  const { notifyChange, stageId } = props;
  const onResize = useMemo(
    () =>
      _.debounce(({ width, height }) => {
        if (!width || !height) return;
        notifyChange({
          size: [width, height],
          forceAdjustContent: !isInit.current,
        });
        isInit.current = true;
      }, 100),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifyChange],
  );
  const { ref } = useResizeObserver<HTMLDivElement>({ onResize });
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
  const [pinDragDistance, setPinDragDistance] = useState(0);
  const [pinchCenter, setPinchCenter] = useState<any>();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    console.log('StageCmp first mount');
    setIsMounted(true);
    return () => {
      console.log('StageCmp unmount');
      onResize.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = stageRef.current.getStage();
    const pointers = stage.getPointersPositions();
    if (pointers?.length > 1) return;
    const { x, y } = stage.getPointerPosition();
    props.onPointerMove([x, y]);
  };

  const onTouchStart = e => {
    // console.debug('onTouchStart');
    const stage = stageRef.current.getStage();
    const pointers = stage.getPointersPositions();
    if (pointers?.length < 2) return;
    // pinch drag zoom
    stage.draggable(false);
    const p1 = pointers[0];
    const p2 = pointers[1];
    setPinchCenter(getCenter(p1, p2));
    setPinDragDistance(getDistance(p1, p2));
  };

  const onDragMove = e => {
    // console.debug('onDragMove');
    const stage = stageRef.current.getStage();
    const pointers = stage.getPointersPositions();
    if (pointers?.length === 1) {
      const { x, y } = stage.getPointerPosition();
      props.onPointerMove([x, y]);
    }
  };

  const onTouchMove = e => {
    // console.debug('onTouchMove', e);
    const stage = stageRef.current.getStage();
    const pointers = stage.getPointersPositions();
    if (pointers?.length > 1) {
      props.notifyChange(getChangesOnPinDrag(e, pinDragDistance, pinchCenter));
      setPinDragDistance(getDistance(pointers[0], pointers[1]));
    }
  };

  const onDragEnd = e => {
    const stage = stageRef.current.getStage();
    const { x, y } = stage.getPosition();
    // console.debug('onDragEnd', x, y);
    props.notifyChange({
      position: [x, y],
    });
  };

  const onTouchEnd = e => {
    // console.debug('onTouchEnd', e);
    const stage = stageRef.current.getStage();
    const pointers = stage.getPointersPositions();
    stage.draggable(true);
  };

  const stageProps: Konva.ContainerConfig = {
    width: props.size[0],
    height: props.size[1],
    scale: { x: props.scale, y: -props.scale },
    draggable: true,
    x: props.position[0],
    y: props.position[1],
    className: 'absolute top-0 left-0',
    listening: !props.stopListening,
    preventDefault: true,
  };
  const eventConfig: KonvaNodeEvents = {
    // onMouseDown: () => console.debug('onMouseDown'),
    // onMouseUp: () => console.debug('onMouseUp'),
    // onDragStart: () => console.debug('onDragStart'),
    onMouseMove: handleMouseMove,
    onDragMove: onDragMove,
    onDragEnd: onDragEnd,
    onTouchStart: onTouchStart,
    onTouchMove: onTouchMove,
    onTouchEnd: onTouchEnd,
    onWheel: e => {
      e.evt.stopImmediatePropagation();
      e.evt.preventDefault();
      const changes = getChangesOnWheel(e);
      !_.isNil(changes) && props.notifyChange(changes);
    },
  };

  return (
    <Suspense
      fallback={
        <LoadingIndicator message={t`Loading Visualisation...`} selfCenter />
      }
    >
      <div
        id="MapStage"
        data-component="MapStage"
        ref={ref}
        className={classNames('h-full w-full flex-1', props.className)}
      >
        {!isMounted && (
          <LoadingIndicator
            className={classNames(
              'absolute',
              'left-0 right-0 top-[45%]',
              'm-auto',
              'max-h-28 max-w-10',
              'shadow-2xl',
            )}
            absolute
            selfCenter
            message={t`Loading Visualisation...`}
          />
        )}
        {isMounted && (
          <Stage ref={stageRef} {...stageProps} {...eventConfig}>
            <RecoilBridge>
              <ErrorBoundary
                onError={er => {
                  console.error(er);
                  setError({
                    id: nanoid(),
                    title: t`Error occurred during layout visualisation, please refresh`,
                  });
                }}
                fallbackRender={({ error, resetErrorBoundary }) => null}
              >
                {props.children}
              </ErrorBoundary>
            </RecoilBridge>
          </Stage>
        )}
      </div>
    </Suspense>
  );
};

export default StageContainer;
