import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import StackingPolicy from '../../stackingPolicy/StackingPolicy';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import ActionBarNextStep from './ActionBarNextStep';

const StackingPolicySidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <StackingPolicy />
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-stacking-policy',
    title: t`Stacking Policy`,
    loadingMessage: t`Loading Stacking Policy`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <StackingPolicySidebar />
    </SidebarContainer>
  );
};
