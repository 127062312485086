import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { Container } from './ContainerFlex';

export type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  containerName?: string;
};

export const ContainerIsolated = ({
  children,
  className,
  containerName,
}: ContainerProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <Container
      data-component={`ContainerIsolated ${containerName || ''}`}
      col
      hasOverflowY
      className={classNames(
        'flex-1',
        'p-4 lg:p-10',
        'shadow-2xl',
        'bg-app-panel/20',
        'rounded-xl w-full',
      )}
    >
      {children}
    </Container>
  );
};
