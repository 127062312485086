import {
  LayoutImportConverterAreaFragment,
  LayoutImportConverterBayFragment,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AreaActionPointPositions,
  AreaBuilderSettings,
  AreaConfiguration,
  SpacerAreaSettings,
  SpacerTypeOption,
  getLocationKey,
  groupLocations,
} from '@warebee/shared/data-access-layout-import-converter';
import { instanceToPlain } from 'class-transformer';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { getIndexedTitle } from '../../../../common/utils';
import { warehouseMeasurementSystem } from '../../../../store/warehouse.state';
import {
  converterAreaConfiguration,
  converterAreaConfigurationAtom,
  converterSelectedAreaId,
  converterSelectedFloor,
} from '../store/converter.area.state';
import {
  getAisleWidthDefault,
  getDefaultAreaBuilderSettings,
} from '../store/converter.defaults';
import { getDefaultLocation } from '../store/converter.location.helper';
import {
  getDefaultBayTypes,
  getDefaultLocationTypes,
} from '../store/converter.settings.helper';
import {
  converterAllAreasBuilder,
  converterBayTypesBuilder,
  converterExtraLocations,
  converterLayoutData,
  converterLocationTypesBuilder,
  converterSpacerAreaSettings,
} from '../store/converter.state';
import { AreaBox } from '../store/converter.types';

export type AddAreaParams = {
  title?: string;
  sourceAreaBox: AreaBox;
  position: AreaActionPointPositions;
};

function getAreaPosition(
  sourceBox: AreaBox,
  pointPosition: AreaActionPointPositions,
  aisleWidth: number,
): { x: number; y: number; w: number; h: number } {
  const box = sourceBox.box;
  const isHorizontal = pointPosition === 'top' || pointPosition === 'bottom';
  const w = isHorizontal ? box.width : aisleWidth;
  const h = isHorizontal ? aisleWidth : box.height;

  let x: number, y: number;
  switch (pointPosition) {
    case 'left':
      x = box.xmin - w;
      y = box.ymin;
      break;
    case 'top':
      x = box.xmin;
      y = box.ymax;
      break;
    case 'right':
      x = box.xmax;
      y = box.ymin;
      break;
    case 'bottom':
      x = box.xmin;
      y = box.ymin - h;
      break;
  }

  return {
    x,
    y,
    w,
    h,
  };
}

const useAreaActions = () => {
  const { t } = useTranslation('designer');
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const floor = useRecoilValue(converterSelectedFloor);

  const aisleWidth = getAisleWidthDefault(ms);
  const defaultAreaTitle = t`Spaces Area`;

  const addEmptyArea = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: AddAreaParams) => {
        const titleTemplate = params.title ?? defaultAreaTitle;

        const spacerAreaSettings = await snapshot.getPromise(
          converterSpacerAreaSettings,
        );
        const existedIds = _.map(spacerAreaSettings, a => a.id);
        const existedTitles = _.map(spacerAreaSettings, a => a.title);

        const id = getIndexedTitle(new Set(existedIds), titleTemplate);

        const title = getIndexedTitle(new Set(existedTitles), titleTemplate);

        const targetPosition = getAreaPosition(
          params.sourceAreaBox,
          params.position,
          aisleWidth,
        );

        const spacerArea: SpacerAreaSettings = {
          id,
          title: id,
          type: SpacerTypeOption.Aisle,
          height: targetPosition.h,
          width: targetPosition.w,
        };

        const areaConfiguration: AreaConfiguration = {
          id,
          x: targetPosition.x,
          y: targetPosition.y,
          rotation: 0,
          floor: floor,
          flipX: false,
          flipY: false,
          // link: {
          //   linkOffsetX: 0,
          //   linkOffsetY: 0,
          //   linkPointType: 'bottomLeft',
          //   masterAreaId: areaToLinkConfig.id,
          //   masterLinkPointType: 'bottomRight',
          // },
        };

        set(converterSpacerAreaSettings, current => ({
          ...current,
          [id]: spacerArea,
        }));

        setTimeout(() => {
          set(converterAreaConfiguration, current => ({
            ...current,
            [id]: areaConfiguration,
          }));
          set(converterSelectedAreaId, id);
        }, 1);
      },
    [],
  );

  const addAreaWithLocation = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: AddAreaParams) => {
        const areaConfigAll = await snapshot.getPromise(
          converterAreaConfigurationAtom,
        );
        const areasIds = new Set(_.keys(areaConfigAll));
        const areaTitle = getIndexedTitle(areasIds, t`Area`);
        const loc = getDefaultLocation(areaTitle);
        const locationKey = getLocationKey(loc);
        const area = groupLocations([loc]).map(
          c => instanceToPlain(c) as LayoutImportConverterAreaFragment,
        )[0];

        const areaId = area.area;

        const areaBuilder: AreaBuilderSettings = {
          ...getDefaultAreaBuilderSettings(ms),
          id: areaId,
        };

        const targetPosition = getAreaPosition(
          params.sourceAreaBox,
          params.position,
          aisleWidth,
        );

        const areaConfiguration: AreaConfiguration = {
          id: areaId,
          x: targetPosition.x,
          y: targetPosition.y,
          rotation: 0,
          floor: floor,
          flipX: false,
          flipY: false,
        };

        set(converterAreaConfiguration, current => ({
          ...current,
          [areaId]: areaConfiguration,
        }));

        set(converterAllAreasBuilder, current => ({
          ...current,
          [area.area]: areaBuilder,
        }));

        const defaultLocationTypes = getDefaultLocationTypes([loc], ms);
        const bays: LayoutImportConverterBayFragment[] = _.flattenDeep(
          area.aisles.map(a2 => a2.sides.map(s => s.bays)),
        );
        const defaultBayTypes = getDefaultBayTypes(bays, ms);

        set(converterLocationTypesBuilder, current => ({
          ...defaultLocationTypes,
          ...current,
        }));

        set(converterBayTypesBuilder, current => ({
          ...defaultBayTypes,
          ...current,
        }));

        set(converterExtraLocations, current => ({
          ...current,
          [locationKey]: loc,
        }));

        set(converterLayoutData, current => ({
          ...current,
          areas: [...current.areas, area],
        }));
      },
    [],
  );
  return [addEmptyArea, addAreaWithLocation];
};

export default useAreaActions;
