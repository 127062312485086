import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import TitleSection from '../../components/layout/TitleSection';

export type PolicyEditorContainerProps = PropsWithChildren & {
  title: string;
  filterType: string;
  isLoading: boolean;
  summary?: ReactNode | undefined;
  summaryMode?: 'open' | 'close' | 'always';
};

const PolicyEditorContainer: React.FC<PolicyEditorContainerProps> = props => {
  const showSummaryOpen =
    !props.isLoading &&
    (props.summaryMode === 'open' || props.summaryMode === 'always');
  const showSummaryClosed =
    !props.isLoading &&
    (props.summaryMode === 'close' || props.summaryMode === 'always');
  return (
    <TitleSection
      dataComponent="PolicyEditorContainer"
      id={`policy-editor-section-field-${props.filterType}`}
      title={props.title}
      collapsible
      inSidebarView
      hasScreenTitle
      isLoading={props.isLoading}
      titleSummaryClosed={showSummaryClosed ? props.summary : null}
      titleSummaryOpen={showSummaryOpen ? props.summary : null}
      classNameInner={classNames('bg-app-panel/50', 'flex flex-col', 'py-4')}
    >
      {props.children}
    </TitleSection>
  );
};

export default PolicyEditorContainer;
