import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ViewModeToolbarCmp, {
  ViewModeToolbarCmpProps,
} from '../../../../layout/toolbar/ViewModeToolbarCmp';
import {
  converterAreaConfiguration,
  converterEditableArea,
  converterEditableAreaId,
  converterSelectedFloor,
} from '../store/converter.area.state';
import {
  converterAutoSizeId,
  converterLayoutSelectedLevel,
  converterLayoutShowLocations,
} from '../store/converter.layout.state';
import { converterLayoutBuilder } from '../store/converter.state';
import ConverterFloorsToolbarCmp, {
  ConverterFloorsToolbarCmpProps,
} from './ConverterFloorsToolbarCmp';

const ConverterViewModeToolbar: React.FC = () => {
  const { t } = useTranslation('designer');
  const areasConfiguration = useRecoilValue(converterAreaConfiguration);
  const area = useRecoilValue(converterEditableArea);
  const setAreaId = useSetRecoilState(converterEditableAreaId);
  const [showLocations, setShowLocations] = useRecoilState(
    converterLayoutShowLocations,
  );
  const [selectedLevel, setSelectedLevel] = useRecoilState(
    converterLayoutSelectedLevel,
  );
  const [selectedFloor, setSelectedFloor] = useRecoilState(
    converterSelectedFloor,
  );
  const layoutConfiguration = useRecoilValue(converterLayoutBuilder);
  const setAutoSizeId = useSetRecoilState(converterAutoSizeId);

  if (!areasConfiguration) return null;

  const singleAreaMode = areasConfiguration && area;
  let toolbarConfig: ViewModeToolbarCmpProps;
  if (singleAreaMode) {
    const areas = _(areasConfiguration)
      .filter(ac => !ac.isDeleted && ac.floor === selectedFloor)
      .value();
    const levels = area.levels;

    toolbarConfig = {
      planeCustomTitle: t`Area`,
      planes: areas,
      selectedPlaneId: area.id,
      onPlaneChange: v => {
        setAreaId(v);
      },
      showLocations,
      toggleShowLocations: () => {
        setShowLocations(!showLocations);
      },
      isLocationsLoading: false,
      selectedLevel: _.includes(levels, selectedLevel)
        ? selectedLevel
        : _.first(levels),
      setSelectedLevel,
      levels,
      adjustStageCallback: () => setAutoSizeId(nanoid()),
    };
  }

  const floorToolbarConfig: ConverterFloorsToolbarCmpProps = {
    selectedFloorId: selectedFloor,
    floors: layoutConfiguration.floors,
    changeFloor: floor => setSelectedFloor(floor),
    adjustStageCallback: () => setAutoSizeId(nanoid()),
  };
  return singleAreaMode ? (
    <ViewModeToolbarCmp {...toolbarConfig} />
  ) : (
    <ConverterFloorsToolbarCmp {...floorToolbarConfig} />
  );
};

export default ConverterViewModeToolbar;
