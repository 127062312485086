import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getBestContrastColor } from '../../common/color.helper';
import { normalizeColorKey } from '../../store/namedColors.helper';
import {
  namedColorsByKey,
  namedColorsGroupSelected,
} from '../../store/namedColors.state';
import ColorPicker from './ColorPicker';

export type NamedColorProps = {
  namedColorKey: string;
};

const NamedColor: React.FC<NamedColorProps> = props => {
  const colorKey = normalizeColorKey(props.namedColorKey);
  const colorPair = useRecoilValue(namedColorsByKey(colorKey));
  const [currentColorGroup, setCurrentColorGroup] = useRecoilState(
    namedColorsGroupSelected,
  );

  const color = _.head(colorPair);
  function onChange(color: string) {
    setCurrentColorGroup({
      ...currentColorGroup,
      colors: {
        ...currentColorGroup.colors,
        [colorKey]: [color, getBestContrastColor(color)],
      },
    });
  }
  // dynamic colors is used
  if (_.isNil(currentColorGroup)) {
    return (
      <div
        data-component="NamedColor"
        style={{ background: color }}
        className={classNames(
          'w-4 h-4',
          'rounded mx-2 border border-app-background/60',
        )}
      />
    );
  }
  //named color
  return <ColorPicker color={color} onChange={onChange} />;
};

export default NamedColor;
