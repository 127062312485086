import * as React from 'react';
const AgentRunningIcon = props => (
  <svg
    aria-hidden="true"
    data-name="AgentRunningIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m73.48,91.41c1.25.68,11.65,12.44,14.52,15.31s5.25,7.05,9.77,5.81,6.65-6.18,1.98-11.56c-2.38-2.75-8.47-11.38-11.11-15.17-.83-1.19-1.94-2.17-3.22-2.85l-20.87-11.11s0,0,0,0l-.05-18.88s8.3,7.42,8.3,7.42c0,0,0,0,0,0v8.81c0,1.47,1.19,2.67,2.67,2.67h44.1c1.47,0,2.67-1.19,2.67-2.67v-33.16c0-.09.03-.17.03-.26,0-.63-.22-1.21-.58-1.66l-5.42-8.98c-.48-.8-1.35-1.29-2.28-1.29h-32.91c-.93,0-1.8.49-2.28,1.29l-5.59,9.27s-.03.07-.04.1c-.08.14-.14.29-.19.45-.02.06-.04.11-.05.17-.04.17-.07.35-.08.53,0,.03,0,.07,0,.1,0,0,0,.01,0,.02v8.49s0,0,0,0l-8.77-12.45s-6.12-4.04-9.98-3.56c-6.13.76-14.64,39.72-14.64,39.72.14,3.17,1.93,6.03,4.72,7.54l29.35,15.89Zm26.7-62.24h12.28s0,0,0,0l2.37,3.94s0,0,0,0h-14.66s0,0,0,0v-3.94s0,0,0,0Zm-17.62,0h12.29s0,0,0,0v3.94s0,0,0,0h-14.66s0,0,0,0l2.38-3.94s0,0,0,0Zm34.33,37.35h-38.77s0,0,0,0v-4.97s0,0,0,0l17.21.03h.66c3.54-.07,6.2-2.82,6.15-6.17-.06-3.25-2.66-5.84-5.87-5.94l-17.82-.03-.34-.31v-10.69s0,0,0,0h38.77s0,0,0,0v28.08s0,0,0,0Zm-50.97-40.1c6.24.78,11.89-4,11.98-10.47.07-4.91-3.37-9.33-8.14-10.47-6.29-1.5-12.32,2.8-13.1,9.04-.73,5.84,3.42,11.17,9.26,11.9Z" />
    <path d="m40.48,78.11l-7.5,19.18-19.93.84c-3.64.34-6.41,3.47-6.24,7.16.17,3.8,3.39,6.74,7.42,6.56l23.93-1.23c2.7-.14,5.08-1.81,6.13-4.3l8.66-20.61-12.48-7.61Z" />
  </svg>
);
export default AgentRunningIcon;
