import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { errorAppender } from '../../store/error.state';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import { getSimulationExtraSettingsInput } from '../store/simulation.helper';
import { simulationCurrent } from '../store/simulation.state';
import { WorkforceScheduleMap } from '../store/workforce.types';
import useUpdateSimulation from './useUpdateSimulation';

function useUpdateWorkforceSettings() {
  const updateSim = useUpdateSimulation();
  const client = useApolloClient();
  const { t } = useTranslation('simulation');

  const callUpdate = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (workforceSchedules: WorkforceScheduleMap) => {
        const simulation = await snapshot.getPromise(simulationCurrent);
        const canUpdate = await snapshot.getPromise(warehouseCanUpdate);
        if (_.isNil(simulation) || !canUpdate) return null;
        try {
          const extraSettings = getSimulationExtraSettingsInput({
            current: simulation.extraSettings,
            workforceSchedules,
          });

          await updateSim({
            extraSettings,
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save simulation settings`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }
      },
  );

  return async (workforceSchedules: WorkforceScheduleMap) => {
    //await initCheck();
    await callUpdate(workforceSchedules);
  };
}
export default useUpdateWorkforceSettings;
