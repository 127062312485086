import * as React from 'react';
const XmlIcon = props => (
  <svg
    aria-hidden="true"
    data-name="XmlIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="m16.19,4c-.52,0-.94.42-.94.94v59.06h97.49v-30h-29.06c-.52,0-.94-.42-.94-.94V4H16.19Zm70.31,0v26.25h26.25l-26.25-26.25ZM15.25,109v14.07c0,.52.42.94.94.94h95.62c.52,0,.94-.42.94-.94v-14.07H15.25Z" />
      <g>
        <path d="m37.21,102.51h-6.29c-.37,0-.59-.25-.78-.56l-3.35-5.52-2.98,5.46c-.19.34-.4.62-.74.62h-5.8c-.25,0-.4-.19-.4-.37,0-.09.03-.16.06-.25l6.33-11.41-6.26-10.42c-.03-.06-.06-.16-.06-.22,0-.22.16-.37.4-.37h6.33c.34,0,.56.31.74.59l3.19,5.27,2.73-5.27c.16-.28.4-.59.74-.59h5.83c.25,0,.4.15.4.37,0,.06-.03.16-.06.22l-6.23,10.98,6.51,10.85c.06.09.09.19.09.28,0,.19-.16.34-.4.34Z" />
        <path d="m53.57,94.79c-.25.4-.53.59-.9.59h-2.14c-.37,0-.65-.19-.9-.59l-2.54-4.25c-.19-.28-.22-.34-.34-.34s-.12.09-.12.31v11.47c0,.25-.19.53-.43.53h-5.12c-.25,0-.5-.03-.5-.53v-21.36c0-.62.53-1.15,1.15-1.15h3.44c.78,0,1.24.34,1.58.93l4.5,7.84c.12.22.22.37.34.37s.25-.16.37-.37l4.5-7.84c.34-.59.81-.93,1.58-.93h3.44c.62,0,1.15.53,1.15,1.15v21.36c0,.34-.28.53-.53.53h-5.08c-.25,0-.46-.09-.46-.53v-11.47c0-.22,0-.31-.12-.31s-.12.06-.31.34l-2.54,4.25Z" />
        <path d="m79.49,102.41c-1.12.22-3.22.37-4.87.37-3.97,0-7.97-.93-7.97-6.95v-15.75c0-.34.31-.62.65-.62h5.05c.34,0,.62.28.62.62v15.75c0,1.21.37,1.55,1.49,1.55h5.02c.34,0,.62.28.62.62v3.78c0,.37-.22.56-.62.62Z" />
      </g>
    </g>
    <rect width={97.49} height={45} x={15.25} y={64} opacity={0.4} />
  </svg>
);
export default XmlIcon;
