import * as React from 'react';
const AgentWalkingIcon = props => (
  <svg
    aria-hidden="true"
    data-name="AgentWalkingIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m81.78,15.55c0,6.39-5.17,11.56-11.56,11.56s-11.56-5.17-11.56-11.56,5.17-11.55,11.56-11.55,11.56,5.17,11.56,11.55h0Z" />
    <path d="m97.19,64.89c-2.26-1.02-17.15-8.42-17.15-8.42,0,0-5.28-11.26-8.01-17.42-2.57-5.8-4.42-7.31-8.08-7.31h-5.64c-2.96,0-4.04,1.08-5.85,2.1l-15.26,12.98c-1.33.77-2.19,1.92-2.71,3.54l-3.27,21.96c-.47,3.5,1.38,4.99,3.48,5.42,1.69.36,4.24-.5,4.76-2.96.41-2.03,3.5-20,3.5-20l9.95-7.65c-1.65,7.63-3.32,15.98-3.86,18.82-.95,4.85,1.47,8.53,3.39,11.42,1.74,2.62,24.26,38.12,27.22,41.98,2.98,3.86,5.69,5.78,9.82,3.48,3.47-1.94,2.89-6.36.97-9.64-1.92-3.27-24.65-40.24-24.65-40.24l3.81-16.48s2.64,3.77,3.99,5.89c.65.99,1.33,1.4,3.11,2.37,2.1,1.15,12.28,5.78,15.37,7.18,2.75,1.22,6.16,1.69,7.52-.75,1.38-2.42-.36-5.33-2.41-6.27h0Z" />
    <path d="m46.91,79.83l-4.6,16.23s-9.61,13.9-12.28,17.79c-2.01,2.93-3.52,6.5-.09,9.1,3.86,2.91,6.95-.88,8.94-3.14,1.83-2.05,9.28-12.46,11.37-15.1,1.4-1.78,2.17-3.27,2.78-4.76.38-.99,1.9-5.1,2.87-7.76l-8.98-12.35Z" />
  </svg>
);
export default AgentWalkingIcon;
