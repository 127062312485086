import * as React from 'react';
const ArrowsTwoWayIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowsTwoWayIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M47.81,102.25v-58.53s15.97,15.95,15.97,15.95v-15.51l-21.45-21.44-21.46,21.45v15.51l15.97-15.95v58.53c0,1.67,1.35,3.03,3.02,3.03h4.94c1.67,0,3.03-1.35,3.03-3.02Z"
    />
    <path
      strokeWidth={0}
      d="M79.42,25.75v58.53s-15.97-15.95-15.97-15.95v15.51l21.45,21.44,21.46-21.45v-15.51l-15.97,15.95V25.75c0-1.67-1.35-3.03-3.02-3.03h-4.94c-1.67,0-3.03,1.35-3.03,3.02Z"
    />
  </svg>
);
export default ArrowsTwoWayIcon;
