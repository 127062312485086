import {
  OrderLineFilterType,
  PickingRequirementsGroupBy,
  PickingRequirementsSettingsFragment,
  PicklistLinesOrderOptions,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import {
  FilterFieldConfigBase,
  FilterFieldDataType,
  FilterFieldEditorType,
} from '../../../common/types';
import { Formatter } from '../../../common/useFormatter';
import i18n from '../../../i18n';

export const picklistLinesOrderOptions: PicklistLinesOrderOptions[] = [
  PicklistLinesOrderOptions.CONSIGNEE,
  PicklistLinesOrderOptions.LOCATION_ID,
  PicklistLinesOrderOptions.LOCATION_LEVEL,
  PicklistLinesOrderOptions.LOCATION_ORDER,
  PicklistLinesOrderOptions.POLLUTION_CLASS,
  PicklistLinesOrderOptions.SKU,
  PicklistLinesOrderOptions.SKU_GROUP,
  PicklistLinesOrderOptions.STOCK_CATEGORY,
  PicklistLinesOrderOptions.STORAGE_CLASS,
  PicklistLinesOrderOptions.SUB_GROUP,
  PicklistLinesOrderOptions.TRANSPORT_CLASS,
  PicklistLinesOrderOptions.STACKING_PRIORITY,
];

export enum PickingConfigurationFilterType {
  // BASE RULE
  GROUP_BY_ORDERS = 'GROUP_BY_ORDERS',

  // PICKING RULE
  AGENT = 'AGENT',
  UOM = 'UOM',
}

export const pickingRequirementDefault: PickingRequirementsSettingsFragment = {
  groupBy: PickingRequirementsGroupBy.ORDER,
};

export function getPickingRequirementFilterConfig(
  t: TFunction<'simulation'> = i18n.t,
): FilterFieldConfigBase<PickingConfigurationFilterType>[] {
  return [
    {
      type: PickingConfigurationFilterType.GROUP_BY_ORDERS,
      title: t('Group By', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SingleSelect,
    },
  ];
}

export function getPickingHowFilterConfig(
  t: TFunction<'simulation'> = i18n.t,
): FilterFieldConfigBase<PickingConfigurationFilterType>[] {
  return [
    {
      type: PickingConfigurationFilterType.UOM,
      title: t('UOM', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
  ];
}

export function getPickingWhoFilterConfig(
  t: TFunction<'simulation'> = i18n.t,
): FilterFieldConfigBase<PickingConfigurationFilterType>[] {
  return [
    {
      type: PickingConfigurationFilterType.AGENT,
      title: t('Agent', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SingleSelect,
    },
  ];
}

export function getOrderLineFilterConfigCommon(
  t: TFunction<'simulation'> = i18n.t,
  formatter: Formatter = null,
): FilterFieldConfigBase<OrderLineFilterType>[] {
  return [
    {
      type: OrderLineFilterType.CONSIGNEE,
      title: t('Client (Consignee)', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.SKU,
      title: t('Item', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.SKU_GROUP,
      title: t('Item Group', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.SUB_GROUP,
      title: t(`Item Sub Group`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.TRANSPORT_CLASS,
      title: t(`Transport Class`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.STOCK_CATEGORY,
      title: t(`Stock Category`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.STORAGE_CLASS,
      title: t(`Storage Class`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.POLLUTION_CLASS,
      title: t(`Pollution Class`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.CUSTOMER,
      title: t('Customer', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.DELIVERY_ROUTE,
      title: t('Delivery Route', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.DOCK,
      title: t('Dock', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.STAGING_AREA,
      title: t('Staging Area', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: OrderLineFilterType.WAVE_ID,
      title: t('Wave', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },

    {
      type: OrderLineFilterType.NET_WEIGHT,
      title: t('Net Weight', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatWeight,
    },
    {
      type: OrderLineFilterType.LENGTH,
      title: t('Length', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatDistance,
    },
    {
      type: OrderLineFilterType.WIDTH,
      title: t('Width', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatDistance,
    },
    {
      type: OrderLineFilterType.HEIGHT,
      title: t('Height', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatDistance,
    },
    {
      type: OrderLineFilterType.VOLUME,
      title: t('Volume', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatVolume,
    },
    {
      type: OrderLineFilterType.CUMULATIVE_PERCENT_RANK,
      title: t(`Order Line (ABC %)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.ABCRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatShare,
      rangeMinValue: 0,
      rangeMaxValue: 1,
      isStatic: true,
      // nullOptionTitle: t(`Dead stock`, { ns: 'simulation' }),
    },
    {
      type: OrderLineFilterType.ORDER_LINE_QUANTITY,
      title: t('Order line quantity', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
    {
      type: OrderLineFilterType.ORDER_LINE_VOLUME,
      title: t('Order line volume', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatVolume,
    },
    {
      type: OrderLineFilterType.ORDER_LINE_WEIGHT,
      title: t('Order line weight', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatWeight,
    },
    {
      type: OrderLineFilterType.ORDER_LINE_COUNT,
      title: t('Order line count', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
    {
      type: OrderLineFilterType.ORDER_TOTAL_VOLUME,
      title: t('Order volume', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatVolume,
    },
    {
      type: OrderLineFilterType.ORDER_TOTAL_WEIGHT,
      title: t('Order weight', { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatWeight,
    },
  ];
}
