import * as React from 'react';
const MoveTypeMoveIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeMoveIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="47.77 72.4 68.5 72.39 46.31 50.2 51.03 45.48 73.22 67.68 73.25 46.96 79.9 53.62 79.87 79.04 54.42 79.05 47.77 72.4"
    />
    <path
      strokeWidth={0}
      d="M101.6,74.9c-13.9,0-25.2,11.3-25.2,25.2s11.3,25.2,25.2,25.2,25.2-11.3,25.2-25.2-11.3-25.2-25.2-25.2ZM115.65,91.7l-15.51,17.45c-.38.43-.91.65-1.45.65-.43,0-.85-.14-1.21-.42l-9.69-7.75c-.84-.67-.97-1.89-.3-2.72.67-.84,1.89-.97,2.72-.3l8.26,6.6,14.29-16.07c.71-.8,1.94-.87,2.74-.16.8.71.87,1.94.16,2.74Z"
    />
    <path
      strokeWidth={0}
      d="M44.57,9.39c-9.89-9.83-25.88-9.83-35.65,0-9.77,9.83-9.67,25.82.22,35.65,9.89,9.83,25.88,9.83,35.65,0,9.77-9.83,9.67-25.82-.22-35.65ZM34.11,37.16l-7.24-7.2-7.5,7.54c-.75.76-1.98.76-2.74,0-.76-.76-.77-1.99-.02-2.74l7.5-7.54-7.24-7.2c-.76-.76-.77-1.99-.02-2.74.75-.76,1.98-.76,2.74,0l7.24,7.2,6.81-6.86c.75-.76,1.98-.76,2.74,0s.77,1.99.02,2.74l-6.81,6.86,7.24,7.2c.76.76.77,1.99.02,2.74-.75.76-1.98.76-2.74,0Z"
      opacity={0.5}
    />
  </svg>
);
export default MoveTypeMoveIcon;
