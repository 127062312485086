import * as React from 'react';
const CompareAfterIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CompareAfterIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M112.58,5.09H15.53c-5.52,0-10,4.48-10,10v98c0,5.52,4.48,10,10,10h97.05c5.52,0,10-4.48,10-10V15.09c0-5.52-4.48-10-10-10ZM84.35,84.52c1,0,1.81.73,1.81,1.72v9.34c0,1-.82,1.81-1.81,1.81h-40.35c-1,0-1.81-.82-1.81-1.81v-9.25c0-9.97,5.44-18.13,12.33-23.03l8.34-5.89c7.89-5.53,9.25-6.26,9.25-10.52,0-3.9-2.27-5.44-9.61-5.44-5.53,0-12.33.63-17.86,1.18h-.18c-1,0-1.63-.82-1.63-1.81v-7.07c0-.91.82-1.45,1.72-1.72,5.08-1.54,12.6-2.81,19.31-2.81,16.68,0,23.12,5.71,23.12,17.32,0,7.34-3.72,12.51-10.43,17.05l-7.71,5.17c-7.71,5.17-13.15,9.07-13.15,15.41v.36h28.65Z"
    />
  </svg>
);
export default CompareAfterIcon;
