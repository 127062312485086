import { TFunction } from 'i18next';
import { FeatureId, getFeatureDefinitions } from './featureDefinitions';

export type Action = 'read' | 'write' | 'delete';

export type FeatureKey =
  | FeatureId
  | `!${FeatureId}`
  | `${FeatureId}*`
  | `!${FeatureId}*`;
export interface Feature {
  id: FeatureId;
  title: string;
  description: string;
  visible: boolean;
}

export const createFeature = (
  featureDef: { id: FeatureId; title: string; description: string },
  t: TFunction,
): Feature => ({
  ...featureDef,
  title: t(`features.${featureDef.id}.title`),
  description: t(`features.${featureDef.id}.description`),
  visible: true,
});

export const featuresList = (t: TFunction): Feature[] => {
  return getFeatureDefinitions(t).map(feature => ({
    id: feature.id,
    title: feature.title,
    description: feature.description,
    visible: true,
  }));
};
// export const featuresList = (t: TFunction): Feature[] =>
//   featureDefinitions.map(fd => createFeature(fd, t));

export interface Role {
  id: string;
  version: string;
  description: string;
  permissions: {
    actions: Action[];
    features: FeatureKey[];
  }[];
  resources: string[];
}
