import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import {
  pickingPolicyPickingRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { resourcePolicyPickingAgents } from '../../simulation/store/resourcePolicy.state';

const PickingPolicyAgentEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [pickingRule, updatePickingRule] = useRecoilState(
    pickingPolicyPickingRule({
      ruleId: selectedIdentity?.ruleId,
      pickingRuleId: selectedIdentity?.pickingRuleId,
    }),
  );
  const pickingAgents = useRecoilValue(resourcePolicyPickingAgents);

  if (!selectedIdentity || !pickingRule) return null;

  const selected = pickingRule?.agentId;

  const onSelectValue = (agentId: string) => {
    updatePickingRule({
      ...pickingRule,
      agentId: agentId,
    });
  };

  return (
    <div data-component="PickingPolicyAgentEditor">
      <TitleSection
        id={`policy-editor-agent-section`}
        title={t`Agent`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div
            className={classNames(
              'flex flex-col',
              'p-1',
              'bg-app-panel-dark/60',
            )}
          >
            <div
              className={classNames(
                'flex flex-col flex-1',
                'relative h-full',
                'min-h-[3rem]',
              )}
            >
              {_.map(pickingAgents, (agent, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-Agent-${agent.id}`}
                    isActive={agent.id === selected}
                    onClick={() => onSelectValue(agent.id)}
                    icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
                    iconClassName={classNames('w-12 h-12', 'ltr:mr-2 rtl:ml-2')}
                    title={agent.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default PickingPolicyAgentEditor;
