import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import {
  ACTIVITY_FEED_MAPPING_SCHEMA,
  ASSIGNMENT_MAPPING_SCHEMA,
  ASSIGNMENT_POLICY_MAPPING_SCHEMA,
  ITEM_SET_MAPPING_SCHEMA,
  LAYOUT_IMPORT_MAPPING_SCHEMA,
  MappingSchema,
  ORDER_SET_MAPPING_SCHEMA,
} from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { IMPORTER_SCHEMA_VERSION } from '../../common/constants';
import * as Icon from '../../components/icons';
import i18n from '../../i18n';
import { getActivityFeedSchema } from '../activityFeed/schema/activityFeedSchema';
import { getAssignmentSchema } from '../assignment/schema/assignmentSchema';
import { getAssignmentPolicySchema } from '../assignmentPolicy/schema/assignmentPolicySchema';
import { getItemSetSchema } from '../itemSet/schema/itemSetSchema';
import { getLayoutSchema } from '../layout/schema/layoutSchema';
import { getOrderSetSchema } from '../orders/schema/orderSetSchema';
import {
  ImportConfiguration,
  ImportMenuItem,
  ImportType,
  MappingSettings,
} from './import.types';

export const IMPORT_PREVIEW_TABLE_NAME = '___input___';
export const IMPORT_PREVIEW_QUERY = `select * from ${IMPORT_PREVIEW_TABLE_NAME}`;
export const IMPORT_PREVIEW_DUMMY_QUERY = 'SELECT NULL';
export const IMPORT_PREVIEW_LINES_COUNT = 1001;

export const schemaVersionKey = IMPORTER_SCHEMA_VERSION.replace(/\./g, '_');

export const importMenuItemsDefault: (
  t: TFunction<'app'>,
) => ImportMenuItem[] = t => [
  {
    id: 'import-getting-started',
    title: t('Getting Started', { ns: 'app' }),
    titleMinimized: t('Start', { ns: 'app' }),
    disabled: false,
    icon: Icon.ArrowRight,
    menuLevel: 1,
    stepCounter: '1',
    treeLineStart: true,
    treeLine: true,
    hasWizardArrows: true,
    sidebar: {
      'sidebar-importer-info': {
        isHidden: false,
        isCollapsed: false,
        openByDefault: true,
      },
    },
  },
  {
    id: 'import-select-csv',
    title: t('Select Data', { ns: 'app' }),
    titleMinimized: t('Select', { ns: 'app' }),
    disabled: false,
    icon: Icon.Csv,
    menuLevel: 1,
    stepCounter: '2',
    treeLineStart: true,
    treeLine: true,
    hasWizardArrows: true,
    sidebar: {
      'sidebar-importer-info': {
        isHidden: false,
        isCollapsed: false,
        openByDefault: true,
      },
    },
  },
  {
    id: 'import-transform',
    title: t('Transform Data', { ns: 'app' }),
    titleMinimized: t('Transform', { ns: 'app' }),
    disabled: false,
    icon: Icon.ImportTransform,
    menuLevel: 1,
    stepCounter: '2',
    treeLineStart: true,
    treeLine: true,
    hasWizardArrows: true,
    sidebar: {
      'sidebar-importer-info': {
        isHidden: false,
        isCollapsed: false,
        openByDefault: true,
      },
    },
  },
  {
    id: 'import-map-fields',
    title: t('Map Fields', { ns: 'app' }),
    titleMinimized: t('Map', { ns: 'app' }),
    disabled: false,
    icon: Icon.ImportMap,
    menuLevel: 1,
    stepCounter: '3',
    treeLine: true,
    hasWizardArrows: true,
    sidebar: {
      'sidebar-importer-info': {
        isHidden: false,
        isCollapsed: false,
        openByDefault: true,
      },
    },
  },
  {
    id: 'import-upload-data',
    title: t('Upload Data', { ns: 'app' }),
    titleMinimized: t('Upload', { ns: 'app' }),
    disabled: false,
    icon: Icon.CloudUpload,
    menuLevel: 1,
    stepCounter: '4',
    hasWizardArrows: false,
    treeLine: true,
    treeLineEnd: true,
    sidebar: {
      'sidebar-importer-info': {
        isHidden: false,
        isCollapsed: false,
        openByDefault: true,
      },
    },
  },
];

export function mergeSchemas<T>(
  base: MappingSettings<T>,
  user: MappingSettings<T>,
): MappingSettings<T> {
  const userFields = _.keyBy(user?.fields, f => f.name);
  return {
    fields: base.fields.map(f => ({
      ...f,
      measureValue:
        userFields[f.name as string]?.measureValue ?? f.measureValue,
      aliases: userFields[f.name as string]?.aliases ?? f.aliases,
      valueResolver:
        userFields?.[f.name as string]?.valueResolver ?? f.valueResolver,
    })),
  };
}

export const getImportConfigurations = _.memoize(
  (t: TFunction<'importer'>): ImportConfiguration[] => {
    return [
      {
        dataType: 'assignment',
        jobType: ImportJobImportType.ASSIGNMENT,
        title: t(`Assignment`, { ns: 'importer' }),
        icon: Icon.DataAssignment,
        actionName: t(`Import assignment`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`assignment.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getAssignmentSchema(t).fields,
        } as MappingSettings<object>,
        schema: ASSIGNMENT_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/data/assignments/`,
        getImportPath: wh => `/wh/i/${wh}/import/assignment/`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/assignments/${id}`,
        getDataTablePath: (wh, id) => `/wh/i/${wh}/assignments/${id}`,
        getSimulationChanges: (assignmentId: string) => ({ assignmentId }),
      },
      {
        dataType: 'items',
        jobType: ImportJobImportType.ITEM_SET,
        title: t(`Items`, { ns: 'importer' }),
        icon: Icon.DataItems,
        actionName: t(`Import Items`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`items.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getItemSetSchema(t).fields,
        } as MappingSettings<object>,
        schema: ITEM_SET_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/data/items/`,
        getImportPath: wh => `/wh/i/${wh}/import/items/`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/items/${id}`,
        getDataTablePath: (wh, id) => `/wh/i/${wh}/items/${id}`,
        getSimulationChanges: (itemSetId: string) => ({ itemSetId }),
      },
      {
        dataType: 'layout',
        jobType: ImportJobImportType.LAYOUT,
        title: t(`Layout`, { ns: 'importer' }),
        icon: Icon.DataItems,
        actionName: t(`Import layout`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`layout.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getLayoutSchema(t).fields,
        } as MappingSettings<object>,
        schema: LAYOUT_IMPORT_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/designer`,
        getImportPath: wh => `/wh/i/${wh}/import/layout/`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/import/layout/${id}/convert`,
        getDataTablePath: (wh, id) => `/wh/i/${wh}/layouts/${id}`,
      },
      {
        dataType: 'convertedLayout',
        jobType: ImportJobImportType.LAYOUT,
        title: t(`Layout`, { ns: 'importer' }),
        icon: Icon.DataItems,
        actionName: t(`Import layout`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`layout.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getLayoutSchema(t).fields,
        } as MappingSettings<object>,
        schema: LAYOUT_IMPORT_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/designer`,
        getImportPath: wh => `/wh/i/${wh}/import/layout/`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/import/layout/${id}/convert`,
        getDataTablePath: (wh, id) => `/wh/i/${wh}/layouts/${id}`,
      },
      {
        dataType: 'orders',
        jobType: ImportJobImportType.ORDER_SET,
        title: t(`Order Set`, { ns: 'importer' }),
        icon: Icon.DataOrders,
        actionName: t(`Import Order Set`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`order-set.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getOrderSetSchema(t).fields,
        } as MappingSettings<object>,
        schema: ORDER_SET_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/data/orders/`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/orders/${id}`,
        getDataTablePath: (wh, id) => `/wh/i/${wh}/orders/${id}`,
        getImportPath: wh => `/wh/i/${wh}/import/orders/`,
        getSimulationChanges: (orderSetId: string) => ({ orderSetId }),
      },
      {
        dataType: 'assignmentPolicy',
        jobType: ImportJobImportType.ASSIGNMENT_POLICY,
        title: t(`Storage Policy`, { ns: 'importer' }),
        icon: Icon.PolicyAssignment,
        actionName: t(`Import Storage Policy`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`assignment-policy.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getAssignmentPolicySchema(t).fields,
        } as MappingSettings<object>,
        schema: ASSIGNMENT_POLICY_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/data/datasets`,
        getImportPath: wh => `/wh/i/${wh}/import/ap`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/simulations/${id}`,
      },
      {
        dataType: 'activityFeed',
        jobType: ImportJobImportType.ACTIVITY_FEED,
        title: t(`Activity Feed`, { ns: 'importer' }),
        icon: Icon.PickingWaveTime,
        actionName: t(`Import Activity Feed`, { ns: 'importer' }),
        actionNameLink: t(`Import Data`, { ns: 'importer' }),
        actionTitleShowData: t(`Show Data`, { ns: 'importer' }),
        filenameTemplate: t(`activity-feed.csv`, { ns: 'importer' }),
        importTemplateVersion: 'v24.04.001',
        mappingSettingsDefault: {
          fields: getActivityFeedSchema(t).fields,
        } as MappingSettings<object>,
        schema: ACTIVITY_FEED_MAPPING_SCHEMA as MappingSchema<object>,
        getDashboardPath: wh => `/wh/i/${wh}/data/datasets/`,
        getImportPath: wh => `/wh/i/${wh}/import/activity/`,
        getTargetPath: (wh, id) => `/wh/i/${wh}/dataset/${id}`,
        getDataTablePath: (wh, id) => `/wh/i/${wh}/dataset/${id}`,
      },
    ];
  },
);

const generateImportTemplateLinks = (dataType: string, version: string) => {
  const importTemplatePath = '/app/assets/importer/csv';
  const importTemplateNameMetric = `WareBee-WAREHOUSE_NAME-${dataType}-${version}-TEMPLATE`;
  const importTemplateAlias = 'latest';

  return {
    versionedLink: `${importTemplatePath}/${importTemplateNameMetric}.zip`,
    aliasLink: `${importTemplatePath}/${importTemplateAlias}.zip`,
  };
};

export const getImportTypeFromJobType = (
  jobType: ImportJobImportType,
): ImportType | undefined => {
  const { t } = useTranslation('importer');
  const importConfigurations = getImportConfigurations(t);
  const config = importConfigurations.find(
    config => config.jobType === jobType,
  );
  return config?.dataType;
};

export const getImportTypeFromDataType = (
  dataType: ImportType,
): ImportJobImportType | undefined => {
  const { t } = useTranslation('importer');
  const importConfigurations = getImportConfigurations(t);
  const config = importConfigurations.find(
    config => config.dataType === dataType,
  );
  return config?.jobType;
};

export const getImportConfiguration = _.memoize(
  (type: ImportType, t = i18n.t): ImportConfiguration => {
    return getImportConfigurations(t).find(config => config.dataType === type);
  },
);

export function getDataObjectTypeOptions(t: TFunction<'simulation'>): {
  id: ImportJobImportType;
  title: string;
  icon?: React.FC<HTMLAttributes<Element>>;
}[] {
  return [
    {
      id: ImportJobImportType.ACTIVITY_FEED,
      title: t(`Activity Feed`, { ns: 'simulation' }),
      icon: Icon.DataDashboard,
    },
    {
      id: ImportJobImportType.ASSIGNMENT,
      title: t(`Assignment`, { ns: 'simulation' }),
      icon: Icon.DataAssignment,
    },
    {
      id: ImportJobImportType.ASSIGNMENT_POLICY,
      title: t(`Assignment Policy`, { ns: 'simulation' }),
      icon: Icon.PolicyAssignment,
    },
    {
      id: ImportJobImportType.ITEM_SET,
      title: t(`Item Set`, { ns: 'simulation' }),
      icon: Icon.DataItems,
    },
    {
      id: ImportJobImportType.LAYOUT,
      title: t(`Layout`, { ns: 'simulation' }),
      icon: Icon.SimulationVisualize,
    },
    {
      id: ImportJobImportType.ORDER_SET,
      title: t(`Order Set`, { ns: 'simulation' }),
      icon: Icon.DataOrders,
    },
  ];
}
