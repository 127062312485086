import * as React from 'react';
const LayoutWarehouseTemplateIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayoutWarehouseTemplateIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="55.79 85.61 106.69 85.61 106.69 60.92 80.68 52.67 55.79 60.9 55.79 85.61"
    />
    <path
      strokeWidth={0}
      d="M29.18,95.63V18.45C29.18-1.89,4.1.29,4.1,18.45v77.72c4.77-10.92,20.89-10.05,25.09-.55Z"
    />
    <path
      strokeWidth={0}
      d="M35.9,22.15v84.71c0,5.94-5.39,6.03-6.72.82-1.39-5.48-6.44-9.32-12.2-9.32-7.13,0-12.89,5.76-12.89,12.75s5.76,12.89,12.89,12.89h106.92V22.15H35.9ZM111.27,90.19h-60.06v-32.6l29.45-9.73,30.62,9.71v32.63Z"
    />
  </svg>
);
export default LayoutWarehouseTemplateIcon;
