import classNames from 'classnames';
import _ from 'lodash';
import { highlight } from 'prismjs/components/prism-core';
import 'prismjs/themes/prism-okaidia.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { useRecoilState, useRecoilValue } from 'recoil';
import { format } from 'sql-formatter';
import { AsyncLoadStatus } from '../../../common/types';
import { FormErrorField } from '../../../components/FormError';
import { Button } from '../../../components/actions/Button';
import CopyToClipboardButton from '../../../components/actions/CopyToClipboardButton';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import PanelContainer from '../../../containers/PanelContainer';
import { createDefaultMapping } from '../../store/import.helper';
import {
  importJob,
  importMappingSettingsByType,
  importRawPreview,
  importTransformedPreview,
  importTransformedPreviewStatus,
  importTypeCurrent,
} from '../../store/import.state';
import useApplyImportTransformation from '../../useApplyImportTransformation';
import useApplyMappingSettings from '../../useApplyMappingSettings';
import { sql } from '../ImportExpressionSqlLanguage';
import { cn } from '../../../common/utils';

const ImporterTransformExpressionEditor: React.FC = () => {
  const { t } = useTranslation('importer');
  const importType = useRecoilValue(importTypeCurrent);
  const [mapping] = useRecoilState(importMappingSettingsByType(importType));
  const applySettings = useApplyMappingSettings();
  const status = useRecoilValue(importTransformedPreviewStatus);
  const { id: jobId } = useRecoilValue(importJob);
  const previewData = useRecoilValue(importRawPreview);
  const [transformedData, setTransformedData] = useRecoilState(
    importTransformedPreview,
  );
  const [callTransformation, cancelTransformation] =
    useApplyImportTransformation();
  const [sqlQuery, setSqlQuery] = useState(mapping.transformation?.query);
  const hasTransformation = mapping?.transformation?.enabled;

  useEffect(() => {
    if (hasTransformation && _.isEmpty(sqlQuery)) {
      setSqlQuery('SELECT * FROM ___input___');
    }
  }, [hasTransformation, sqlQuery]);

  function applyTransformation(isEnabled: boolean) {
    let newMapping = _.cloneDeep(mapping);
    if (!isEnabled) {
      setTransformedData(null);
      newMapping = createDefaultMapping(newMapping, previewData.fields);
    }

    applySettings({
      skipValidation: true,
      mappingSettings: {
        ...newMapping,
        transformation: {
          enabled: isEnabled,
          query: sqlQuery,
        },
      },
    });

    isEnabled && callTransformation({ jobId, query: sqlQuery });
  }

  const isLoading = status === AsyncLoadStatus.Loading;

  return (
    <>
      <PanelContainer
        id={`import-data-transform-code`}
        collapsible
        isAccordion
        title={
          <div className={classNames('flex flex-1 items-center')}>
            <div className="flex-1">{t`Transform Logic`}</div>
            <div onClick={e => e.stopPropagation()}>
              <InputCheckbox
                isDisabled={isLoading}
                label={t`Use transformation`}
                isSelected={mapping?.transformation?.enabled}
                onChange={v => applyTransformation(v)}
              >{t`Use transformation`}</InputCheckbox>
            </div>
          </div>
        }
      >
        <Container col className="relative">
          <div className="h-full overflow-auto">
            <Editor
              value={sqlQuery}
              onValueChange={query => setSqlQuery(query)}
              highlight={code =>
                _.isEmpty(code) ? code : highlight(code, sql)
              }
              padding={10}
              disabled={isLoading}
              style={{
                position: 'relative',
                overflowY: 'auto',
                fontFamily: 'monospace',
                fontSize: 14,
                paddingBottom: '3rem',
              }}
              placeholder="SELECT * FROM ___input___"
              className={mapping?.transformation?.enabled ? '' : 'opacity-50'}
            />
          </div>

          <ActionBar sticky stickyBottom>
            <CopyToClipboardButton
              className={cn('rounded-none')}
              // title={t`Copy Query`}
              hasIcon
              hasIconSmall
              value={sqlQuery}
            />
            <Spacer flexspace />
            <Button
              buttonSize="sm"
              label={t`Prettify`}
              buttonType="secondary"
              isLoading={isLoading}
              isDisabled={isLoading}
              onPress={() =>
                setSqlQuery(
                  format(sqlQuery, {
                    language: 'trino',
                  }),
                )
              }
            />
            <Button
              buttonSize="sm"
              label={isLoading ? t`Transforming...` : t`Transform`}
              buttonType="primary"
              isLoading={isLoading}
              hasIconBefore={isLoading}
              isDisabled={isLoading}
              onPress={() => applyTransformation(true)}
            />
          </ActionBar>
        </Container>
      </PanelContainer>
      {!_.isEmpty(transformedData?.errors) && (
        <PanelContainer
          id={`import-data-transform-code-error`}
          collapsible
          className="bg-alerts-error/70"
          title={t`Transform Errors`}
        >
          <FormErrorField message={_.head(transformedData.errors)} />
        </PanelContainer>
      )}
    </>
  );
};
export default ImporterTransformExpressionEditor;
