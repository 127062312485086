import { AnalyzeResultFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import AnalyzeCompareHeatmapPanel from '../../analyzeCompare/panels/AnalyzeCompareHeatmapPanel';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import { AnalyseTabId } from '../store/simulation.types';
import { simulationAnalyseTabKey } from '../store/simulation.wizard.state';
import SimulationAnalyzePanelProduct from './SimulationAnalyzePanelProduct';
import SimulationAnalyzePanelWorkCompare from './SimulationAnalyzePanelWorkCompare';
import SimulationOptimiseOrdersComparePanel from './SimulationOptimiseOrdersComparePanel';
import SimulationOptimiseSummaryPanel from './SimulationOptimiseSummaryPanel';

interface AnalyzePanelProps {
  resultBefore: AnalyzeResultFragment;
  resultAfter: AnalyzeResultFragment;
}

const AnalyzePanelCompare: React.FC<AnalyzePanelProps> = ({
  resultBefore,
  resultAfter,
}) => {
  const { t } = useTranslation('simulation');
  const [selectedTab, setSelectedTab] = useRecoilState(simulationAnalyseTabKey);

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const tabItems: TabItem<AnalyseTabId>[] = [
    {
      id: 'tab-analyze-summary',
      title: t`Summary`,
      icon: <Icon.SimulationAnalyze className={tabIconStyle} />,
      content: <SimulationOptimiseSummaryPanel />,
    },
    // {
    //   id: 'tab-analyze-process',
    //   title: t`Process`,
    //   content: <AnalyzeResultSummary analyzeResult={analyzeResult} />,
    // },
    {
      id: 'tab-analyze-resources',
      title: t`Resources`,
      icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
      content: (
        <SimulationAnalyzePanelWorkCompare
          resultBefore={resultBefore}
          resultAfter={resultAfter}
        />
      ),
    },
    // {
    //   id: 'tab-analyze-workload',
    //   title: t`Workload`,
    //   icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
    //   content: <AnalyzeResultLoadBalancing analyzeResult={analyzeResult} />,
    // },
    {
      id: 'tab-analyze-items',
      title: t`Items`,
      icon: <Icon.DataItems className={tabIconStyle} />,
      content: <SimulationAnalyzePanelProduct analyzeId={resultBefore?.id} />,
    },
    {
      id: 'tab-analyze-orders',
      title: t`Orders`,
      icon: <Icon.DataOrders className={tabIconStyle} />,
      content: (
        <SimulationOptimiseOrdersComparePanel
          baseAnalyzeId={resultBefore?.id}
          optimisedAnalyzeId={resultAfter?.id}
        />
      ),
    },
    {
      id: 'tab-analyze-heatmaps',
      title: t`Heatmaps`,
      icon: <Icon.HeatMap9 className={tabIconStyle} />,
      content: <AnalyzeCompareHeatmapPanel />,
    },
    // {
    //   id: 'tab-analyze-allocate',
    //   title: t`Allocate`,
    //   icon: <Icon.AllocationReport className={tabIconStyle} />,
    //   content: <SimulationAnalyzeOrdersPanel analyzeId={analyzeResult.id} />,
    // },
  ];

  return (
    <Tabs
      componentName="AnalyzePanelCompare"
      items={tabItems}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      classNameTitle="uppercase"
      hasScroll
      fullHeight
    />
  );
};

export default AnalyzePanelCompare;
