import React, { useCallback } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';
import { analyticsAgent } from '../../AnalyticTracker';

export type LinkTracedProps = LinkProps & {
  titleTrace?: string;
  to: string | To;
  children?: React.ReactNode;
  className?: string;
  ariaCurrent?:
    | boolean
    | 'time'
    | 'false'
    | 'true'
    | 'page'
    | 'step'
    | 'location'
    | 'date';
};

export default function LinkTraced({
  children,
  className,
  titleTrace,
  to,
  ariaCurrent,
  // active = false,
  ...linkProps
}: LinkTracedProps) {
  const traceClick = useCallback(() => {
    try {
      analyticsAgent?.track(`Link: ${titleTrace}`, {
        titleTrace,
      });
    } catch (ex) {
      console.debug('analyticsAgent Error:', ex);
    }
  }, [analyticsAgent, titleTrace]);

  return (
    <Link
      {...linkProps}
      to={to}
      data-component="NavLink"
      data-label={`link-to-${to}`}
      aria-label={`link-to-${to}`}
      onClick={traceClick}
      className={className}
      aria-current={ariaCurrent}
    >
      {children}
    </Link>
  );
}
