import * as React from 'react';
const Mhe3BWalkieStalkerElectricIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe3BWalkieStalkerElectricIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M67.03,112.07c.16,2.74,1.31,4.72,3.45,5.93,2.14,1.21,4.42,1.21,6.84,0,1.05-.65,1.88-1.47,2.48-2.48s.91-2.16.91-3.45c-.16-2.66-1.31-4.6-3.45-5.81-2.14-1.21-4.42-1.21-6.84,0-1.05.65-1.88,1.47-2.48,2.48s-.91,2.12-.91,3.33ZM80.71,40.74h-6.78v26.88h29.3l22.77-2.79h-45.29v-24.1ZM4.18,32.27h23.01c.48,0,.77.24.85.73,0,.16-.04.32-.12.48l-1.94,2.79c-.16.24-.4.4-.73.48h-12.35c-.89,0-1.63.3-2.24.91s-.95,1.35-1.03,2.24v28.7h28.09c1.13,0,2.1.4,2.91,1.21.81.81,1.25,1.82,1.33,3.03v35.24h17.32V18.1l-1.7-.24c-.65-.16-1.09-.57-1.33-1.21l-1.09-3.39c-.16-.57,0-.93.48-1.09h3.63v-3.39h12.35v95.06c-2.42.73-4.24,2.1-5.45,4.12s-1.49,4.28-.85,6.78c.16.73.4,1.37.73,1.94H10.84c-.73,1.21-1.74,2-3.03,2.36-1.29.36-2.58.22-3.88-.42-.73-.4-1.37-1.05-1.94-1.94V34.45c.08-.65.32-1.17.73-1.57.4-.4.89-.61,1.45-.61h0ZM31.91,67.26h-15.38v-4.24c0-.73.26-1.35.79-1.88.52-.52,1.15-.79,1.88-.79h9.93c.81,0,1.45.26,1.94.79s.73,1.15.73,1.88l.12,4.24h0Z"
    />
  </svg>
);
export default Mhe3BWalkieStalkerElectricIcon;
