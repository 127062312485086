import { AppBillingProductCategory } from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import InboxZero from '../components/InboxZero';
import { ContainerCol, ContainerRow } from '../components/layout/ContainerFlex';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import LoadingIndicator from '../components/LoadingIndicator';
import { warehouseSelectedId } from '../store/warehouse.state';
import AppBillingActiveSubscription from './AppBillingActiveSubscription';
import AppBillingCustomerDetails from './AppBillingCustomerDetails';
import AppBillingInvoice from './AppBillingInvoice';
import AppBillingPaymentForm from './AppBillingPaymentForm';
import AppBillingPreInvoice from './AppBillingPreInvoice';
import AppBillingStepper from './AppBillingStepper';
import AppBillingStripeElementsWrapper from './AppBillingStripeElementsWrapper';
import useCreatePurchase from './hooks/useCreatePurchase';
import useCreateSubscription from './hooks/useCreateSubscription';
import useLoadWarehouseBillingState from './hooks/useLoadWarehouseBillingState';
import useUpdateCustomer from './hooks/useUpdateCustomer';
import {
  appBillingPrices,
  appBillingPurchase,
  appBillingPurchaseLoadStatus,
  appBillingSubscription,
  appBillingSubscriptionLoadStatus,
  appBillingWarehouseLoadStatus,
  appBillingWarehouseState,
} from './store/appBilling.state';

export type AppBillingPurchaseParams = {
  priceId: string;
};

const AppBillingPurchase: React.FC<PropsWithChildren> = props => {
  const { priceId } = useParams<AppBillingPurchaseParams>();
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const prices = useRecoilValue(appBillingPrices);

  const purchase = useRecoilValue(appBillingPurchase);
  const purchaseStatus = useRecoilValue(appBillingPurchaseLoadStatus);

  const subscription = useRecoilValue(appBillingSubscription);
  const subscriptionStatus = useRecoilValue(appBillingSubscriptionLoadStatus);
  const whState = useRecoilValue(appBillingWarehouseState);

  const updateCustomer = useUpdateCustomer();
  const createPurchase = useCreatePurchase();
  const createSubscription = useCreateSubscription();

  const whStateLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const loadBillingState = useLoadWarehouseBillingState();

  // refresh warehouse state
  useEffect(() => {
    if (_.isNil(whState)) {
      loadBillingState([whId]);
    }
  }, []);

  if (_.isNil(priceId)) {
    return (
      <ErrorIndicator
        message={t`Plan details are not provided, please contact support`}
      />
    );
  }
  if (_.isEmpty(prices)) {
    return (
      <ErrorIndicator
        message={t`Price details couldn't be loaded, please contact support`}
      />
    );
  }

  const price = prices[priceId];
  if (_.isNil(price)) {
    return <InboxZero message={t`Provided plan doesn't exist`} />;
  }

  const isWhStatusLoading =
    whStateLoadStatus === AsyncLoadStatus.Loading ||
    whStateLoadStatus === AsyncLoadStatus.None;

  if (isWhStatusLoading) {
    return <LoadingIndicator message={t`Loading...`} />;
  }

  // do not show products list if any subscription exists
  if (!_.isEmpty(whState?.currentSubscriptions)) {
    return <AppBillingActiveSubscription whState={whState} />;
  }

  const isBundle =
    price.product.productCategory === AppBillingProductCategory.BUNDLE;
  const isSubscription =
    price.product.productCategory === AppBillingProductCategory.SUBSCRIPTION;

  async function createInvoice(customer) {
    const updateResult = await updateCustomer(customer);
    if (isBundle) {
      createPurchase(price.id, price.currency);
    }
    if (isSubscription) {
      createSubscription(priceId);
    }
  }

  const invoiceStatus = isBundle ? purchaseStatus : subscriptionStatus;
  const invoice = isBundle ? purchase?.invoice : subscription?.latestInvoice;
  const paymentFormReady = !_.isEmpty(invoice);
  const pk =
    purchase?.stripePublishableKey ?? subscription?.stripePublishableKey;
  const paymentIntentKey = invoice?.paymentIntent?.clientSecret;

  return (
    <ContainerCol overflow>
      <AppBillingStepper
        active={paymentFormReady ? 'purchase-form' : 'purchase-customer'}
        priceId={priceId}
      />
      <ContainerRow overflow>
        <ContainerScroll className="flex flex-col">
          {paymentFormReady ? (
            <AppBillingStripeElementsWrapper
              stripeKey={pk}
              paymentIntentId={paymentIntentKey}
            >
              <AppBillingPaymentForm />
            </AppBillingStripeElementsWrapper>
          ) : (
            <AppBillingCustomerDetails
              currencyCode={price.currency}
              isDisabled={paymentFormReady}
              isLoading={invoiceStatus === AsyncLoadStatus.Loading}
              onSubmit={createInvoice}
            />
          )}

          {/* {!paymentFormReady && (
            <AppBillingCustomerDetails
              currencyCode={price.currency}
              isDisabled={paymentFormReady}
              isLoading={invoiceStatus === AsyncLoadStatus.Loading}
              onSubmit={createInvoice}
            />
          )}

          {paymentFormReady && (
            <AppBillingStripeElementsWrapper
              stripeKey={pk}
              paymentIntentId={paymentIntentKey}
            >
              <AppBillingPaymentForm />
            </AppBillingStripeElementsWrapper>
          )} */}
        </ContainerScroll>
        {invoice ? (
          <AppBillingInvoice invoice={invoice} product={price.product} />
        ) : (
          <AppBillingPreInvoice product={price.product} price={price} />
        )}
      </ContainerRow>
    </ContainerCol>
  );
};

export default AppBillingPurchase;
