import * as React from 'react';
const PolicyWaveTimeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaveTimeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M81.44,61.49h-12.88c-.49-.68-1.08-1.27-1.76-1.76v-18.57c0-2.44-1.98-4.41-4.41-4.41s-4.41,1.97-4.41,4.41v18.57c-1.92,1.38-3.19,3.62-3.19,6.17,0,4.2,3.4,7.6,7.6,7.6,2.55,0,4.79-1.27,6.17-3.19h12.88c2.44,0,4.41-1.98,4.41-4.41,0-2.44-1.97-4.41-4.41-4.41Z"
    />
    <path
      strokeWidth={0}
      d="M87.26,112.88l.14-.14c.46-.46.7-1.07.66-1.68-.04-.63-.32-1.2-.79-1.6l-11.69-9.93c6.71-2.2,12.6-6.23,17.11-11.73,5.41-6.61,8.4-14.94,8.42-23.47,0-.58-.22-1.14-.63-1.57-.42-.42-.98-.66-1.57-.66h-3.09s15.62-16.03,15.62-16.03l15.43,16.16s-4.39,0-4.39,0c-.59,0-1.12.21-1.54.58-.47.47-.71,1.06-.69,1.64-.04,14.88-5.91,28.94-16.52,39.56-6.26,6.31-13.89,11.99-22.28,14.71l5.82-5.84Z"
    />
    <path
      strokeWidth={0}
      d="M66.2,122.23c-.02-.63-.25-1.17-.66-1.58-.4-.41-.95-.64-1.54-.66-7.43,0-14.65-1.45-21.48-4.29-6.82-2.83-12.92-6.94-18.14-12.2-6.05-6.06-11.62-13.32-14.36-21.22l4.83,4.61c.06.08.11.14.16.18.38.39.91.61,1.47.63h.11c.58,0,1.14-.23,1.56-.66l.12-.12,10.39-12.22c2.12,6.88,6.14,12.93,11.7,17.58,6.65,5.55,15.08,8.62,23.74,8.63,1.25,0,2.23-.98,2.23-2.23v-3.13s16.03,15.62,16.03,15.62l-16.17,15.44.02-4.35Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M1.14,65.8s4.37,0,4.37,0c.62,0,1.16-.23,1.57-.64.41-.41.64-.96.66-1.54.01-7.42,1.46-14.63,4.3-21.47,2.86-6.83,6.97-12.93,12.21-18.13l.18-.18c6.23-6.23,13.83-10.87,22.2-13.58l-5.88,4.89c-.49.42-.78,1.03-.8,1.68,0,.42.12.83.33,1.18.13.3.33.56.59.79l10.49,9.67c-6.68,2.16-11.53,6.13-16.02,11.57-5.46,6.64-8.48,15.04-8.49,23.66,0,.6.23,1.16.65,1.58s.98.65,1.58.65h3.11s-15.62,16.04-15.62,16.04L1.14,65.8Z"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M99.4,53.33c-2.09-6.88-6.08-12.93-11.63-17.58-6.67-5.6-15.14-8.68-23.85-8.68-1.23,0-2.22,1-2.22,2.22v3.22s-15.83-15.47-15.83-15.47l15.97-14.63v2.95s-.02.41-.02.41c0,.58.24,1.16.66,1.57.41.41.98.65,1.57.65,7.38,0,14.6,1.44,21.45,4.29,6.8,2.83,12.91,6.93,18.15,12.19,6.09,6.09,11.67,13.39,14.41,21.33l-4.86-4.64c-.44-.51-1.05-.8-1.68-.8-.7,0-1.33.29-1.75.78l-10.35,12.18Z"
      opacity={0.3}
    />
  </svg>
);
export default PolicyWaveTimeIcon;
