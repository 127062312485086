import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';

const StepPositionAreas: React.FC = () => {
  const { t } = useTranslation('designer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Areas Setup`}
        title={t`Areas Orientation`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.CursorTransformDuplicate}
          question={t`How can I organise the areas?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/02-areas-orientation'} hasPadding />

          {/* <HelperContainer
            collapsible
            id={'helper-layout-area-positioning'}
            hideMessage={t`Hide helper`}
            showMessage={t`show more`}
            showIcon={Icon.ArrowRight}
            hideIcon={Icon.ArrowRight}
            message={t`You can Rotate, Flip and move Areas to align with your actual warehouse layout.`}
          >
            <HelperMessage>
              {t`Navigation between areas is finalised in Step 4 - "Navigation"`}
            </HelperMessage>
            <HelperMessage isTip hasBefore>
              {t`To align two areas, drag the area close to the other one. And you will see alignment guides.`}
            </HelperMessage>
          </HelperContainer> */}
        </InputAnswerGroup>
      </InputQA>
      {/* <ToolbarAreaOrientation /> */}
    </PanelBody>
  );
};
export default StepPositionAreas;
