import * as React from 'react';
const AlignHorizontalCenterIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlignHorizontalCenterIcon"
    {...props}
  >
    <rect width={100} height={28} x={15.69} y={67.5} strokeWidth={0} />
    <rect width={4.44} height={28.5} x={63.47} y={4} strokeWidth={0} />
    <rect width={4.44} height={63.5} x={63.47} y={60.5} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="96.39 32.52 96.5 32.63 96.5 46.21 82.81 32.52 96.39 32.52"
    />
    <polygon
      strokeWidth={0}
      points="31.5 60.52 31.5 52.48 39.53 60.52 31.5 60.52"
    />
    <polyline
      strokeWidth={0}
      points="68.69 35.37 65.84 32.52 79.42 32.52 96.5 49.6 96.5 60.52 93.84 60.52 68.69 35.37 68.69 35.37"
    />
    <polygon
      strokeWidth={0}
      points="31.9 32.52 45.47 32.52 73.47 60.52 59.9 60.52 31.9 32.52"
    />
    <polygon
      strokeWidth={0}
      points="90.41 60.48 76.84 60.48 48.87 32.52 62.45 32.52 90.41 60.48"
    />
    <polygon
      strokeWidth={0}
      points="42.93 60.52 31.5 49.09 31.5 35.51 56.5 60.52 42.93 60.52"
    />
  </svg>
);
export default AlignHorizontalCenterIcon;
