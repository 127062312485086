import {
  Field,
  GraphQLLocalDateTime,
  ID,
  InputAndObjectType,
  Int,
  ObjectType,
  createUnionType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { ReassignJobDetails } from './assignment-change.model';
import { Point } from './geometry.model';
import { ItemUomQuantity } from './item-quantity.model';
import { WaypointType } from './navigation.model';
import type { StackingPriority } from './policies';
import { GRAPHQL_STACKING_PRIORITY } from './policies';

@InputAndObjectType()
export class ProductId {
  @Field(() => ID)
  consignee: string;
  @Field(() => ID)
  sku: string;
}

@ObjectType()
export class ProductLocationId {
  @Field(() => ID)
  locationId: string;
  @Field(() => ID)
  consignee: string;
  @Field(() => ID)
  sku: string;
}

@ObjectType()
export class AnalyzeResultLocationProductReplenishmentInfo {
  @Field({
    description: 'Quantity to replenish in single replenishment job',
  })
  replenishmentQuantity: number;

  @Field(() => ID, { nullable: true })
  replenishmentUom?: string;

  @Field()
  replenishmentUomQuantity: number;
}

export enum ReassignTaskAction {
  TAKE = 'TAKE',
  PUT = 'PUT',
}

registerEnumType(ReassignTaskAction, {
  name: 'ReassignTaskAction',
});

@ObjectType()
export class AnalyzeResultLocationProductReassignTask {
  @Field(() => ReassignTaskAction)
  action: ReassignTaskAction;

  @Field(() => ID)
  jobId: string;

  @Field()
  quantity: number;

  @Field(() => [ItemUomQuantity])
  breakdownByUom: ItemUomQuantity[];
}

@ObjectType()
export class AnalyzeResultReorderSummary {
  @Field()
  count: number;

  @Field()
  totalDuration: number;

  @Field()
  totalCost: number;

  @Field()
  totalReorderedWeight: number;
}

@ObjectType()
export class AnalyzeResultLocationProduct {
  @Field(() => ID)
  consignee: string;

  @Field(() => ID)
  sku: string;

  @Field({ description: 'Total number of access events for all processes' })
  hitCount: number;

  @Field({ description: 'Number of access events during picking' })
  pickCount: number;

  @Field({ description: 'Total picked quantity' })
  quantityPicked: number;

  @Field(() => AnalyzeResultLocationProductReplenishmentInfo, {
    nullable: true,
    description: 'Replenishment job details',
  })
  replenishment?: AnalyzeResultLocationProductReplenishmentInfo;

  @Field({
    nullable: true,
    description: 'Number of replenishments',
  })
  replenishmentCount?: number;

  @Field({ nullable: true, description: 'Total quantity of replenished items' })
  quantityReplenished?: number;

  @Field({ nullable: true })
  reassignCount?: number;

  @Field({ nullable: true })
  quantityReassigned?: number;

  @Field(() => [AnalyzeResultLocationProductReassignTask], { nullable: true })
  reassignTasks?: AnalyzeResultLocationProductReassignTask[];

  @Field(() => AnalyzeResultReorderSummary, {
    nullable: true,
    description:
      'Summary of reorder events triggered by picking of this item from this location',
  })
  triggeredReorder?: AnalyzeResultReorderSummary;

  @Field(() => AnalyzeResultReorderSummary, {
    nullable: true,
    description:
      'Summary of reorder events that were applied to this item picked from this location',
  })
  appliedReorder?: AnalyzeResultReorderSummary;

  /**
   * Stacking priority category index.
   *
   * @TJS-type  integer
   */
  @Field(() => Int, { nullable: true })
  stackingCategoryIndex?: number;
}

export enum PicklistEventType {
  PRE_HANDLING = 'PRE_HANDLING',
  TRAVELLING_HORIZONTAL = 'TRAVELLING_HORIZONTAL',
  TRAVELLING_VERTICAL = 'TRAVELLING_VERTICAL',
  HANDLING_PREPARATION = 'HANDLING_PREPARATION',
  HANDLING_EXECUTION = 'HANDLING_EXECUTION',
  HANDLING_PALLET_REORDERING = 'HANDLING_PALLET_REORDERING',
  HANDLING_ON_COMPLETION = 'HANDLING_ON_COMPLETION',
  POST_HANDLING = 'POST_HANDLING',
  TRAVELLING_HORIZONTAL_START = 'TRAVELLING_HORIZONTAL_START',
  TRAVELLING_HORIZONTAL_END = 'TRAVELLING_HORIZONTAL_END',
  SOURCE_EVENT_IGNORED = 'SOURCE_EVENT_IGNORED',
}

registerEnumType(PicklistEventType, {
  name: 'PicklistEventType',
});

export const PICKLIST_PROCESSING_EVENT_TYPES = [
  PicklistEventType.PRE_HANDLING,
  PicklistEventType.POST_HANDLING,
];

export const PICKLIST_LINE_PROCESSING_EVENT_TYPES = [
  PicklistEventType.HANDLING_PREPARATION,
  PicklistEventType.HANDLING_ON_COMPLETION,
];

export const TRAVELLING_HORIZONTAL_EVENT_TYPES = [
  PicklistEventType.TRAVELLING_HORIZONTAL,
  PicklistEventType.TRAVELLING_HORIZONTAL_START,
  PicklistEventType.TRAVELLING_HORIZONTAL_END,
];

@ObjectType()
export class PicklistEventWaypoint {
  @Field(() => WaypointType)
  type: WaypointType;

  @Field(() => ID)
  id: string;

  @Field(() => Point)
  position: Point;
}

@ObjectType()
export class PicklistProcessingEventDetails {
  @Field()
  orderCount: number;

  @Field()
  orderLineCount: number;

  @Field()
  totalUomQuantity: number;
}

@ObjectType()
export class PicklistLineProcessingEventDetails {
  @Field()
  uomQuantity: number;
}

export class TravellingHorizontalAlternativePointDetails {
  locationId: string;

  distance: number;

  duration: number;

  cost: number;
}

@ObjectType()
export class TravellingHorizontalDetails {
  @Field({ nullable: true })
  weight?: number;

  @Field({ nullable: true })
  volume?: number;

  @Field()
  distance: number;

  @Field(() => [PicklistEventWaypoint])
  route: PicklistEventWaypoint[];

  alternativePoints?: TravellingHorizontalAlternativePointDetails[];
}

@ObjectType()
export class TravellingVerticalDetails {
  @Field({ nullable: true })
  weight?: number;

  @Field({ nullable: true })
  volume?: number;

  @Field()
  distance: number;
}

@ObjectType()
export class HandlingExecutionDetails {
  @Field({ nullable: true })
  uom?: string;

  @Field()
  uomQuantity: number;

  @Field({ nullable: true })
  weight?: number;

  @Field({ nullable: true })
  volume?: number;
}

@ObjectType()
export class UomHandlingDetails {
  @Field({ nullable: true })
  uom?: string;

  @Field()
  uomQuantity: number;
}

@ObjectType()
export class HandlingPalletReorderCategory {
  @Field(() => GRAPHQL_STACKING_PRIORITY)
  stackingPriority: StackingPriority;

  @Field({ nullable: true })
  weight?: number;

  @Field({ nullable: true })
  volume?: number;

  @Field(() => [UomHandlingDetails])
  uoms: UomHandlingDetails[];
}

@ObjectType()
export class HandlingPalletReorderingDetails {
  @Field(() => GRAPHQL_STACKING_PRIORITY)
  stackingPriority: StackingPriority;

  @Field(() => [HandlingPalletReorderCategory])
  reordered: HandlingPalletReorderCategory[];
}

export enum SourceEventIgnoredReason {
  UNKNOWN_AGENT = 'UNKNOWN_AGENT',
  UNKNOWN_LOCATION = 'UNKNOWN_LOCATION',
  INVALID_LOCATION_FOR_JOB = 'INVALID_LOCATION_FOR_JOB',
  MULTIPLE_JOB_STARTS = 'MULTIPLE_JOB_STARTS',
  MULTIPLE_JOB_ENDS = 'MULTIPLE_JOB_ENDS',
  NO_QUANTITY = 'NO_QUANTITY',
}

registerEnumType(SourceEventIgnoredReason, {
  name: 'SourceEventIgnoredReason',
});

@ObjectType()
export class SourceEventIgnoredDetails {
  @Field(() => SourceEventIgnoredReason)
  reason: SourceEventIgnoredReason;

  @Field(() => ID, { nullable: true })
  sourceEventType?: string;
}

export enum AnalyzeResultProcessType {
  PICKING = 'PICKING',
  REPLENISHMENT = 'REPLENISHMENT',
  REASSIGN = 'REASSIGN',
}

registerEnumType(AnalyzeResultProcessType, {
  name: 'AnalyzeResultProcessType',
});

@ObjectType()
export class AnalyzeResultProcessCost {
  @Field(() => AnalyzeResultProcessType)
  processType: AnalyzeResultProcessType;

  @Field()
  distance: number;

  @Field()
  duration: number;

  @Field()
  cost: number;
}

@ObjectType()
export class AnalyzeResultCostDetails {
  @Field(() => [AnalyzeResultProcessCost])
  processes: AnalyzeResultProcessCost[];
}

@ObjectType()
export class AnalyzeResultEventTypeSummary {
  @Field(() => PicklistEventType)
  eventType: PicklistEventType;

  @Field(() => AnalyzeResultProcessType, { nullable: true })
  processType?: AnalyzeResultProcessType;

  @Field()
  count: number;

  @Field()
  totalDuration: number;

  @Field()
  totalCost: number;
}

@ObjectType()
export class AnalyzeResultAgentSummary {
  @Field(() => ID)
  agent: string;

  @Field(() => [AnalyzeResultEventTypeSummary])
  eventTypes: AnalyzeResultEventTypeSummary[];
}

@ObjectType()
export class AnalyzeResultAssignmentSummary {
  @Field({ nullable: true })
  totalStoredItems?: number;
}

@ObjectType()
export class AnalyzeResultSummary {
  @Field(() => [AnalyzeResultEventTypeSummary])
  eventTypes?: AnalyzeResultEventTypeSummary[];

  @Field(() => [AnalyzeResultAgentSummary])
  agents?: AnalyzeResultAgentSummary[];

  @Field(() => AnalyzeResultAssignmentSummary, { nullable: true })
  assignment?: AnalyzeResultAssignmentSummary;
}

@ObjectType()
export class AnalyzeResultPicklistSummary {
  @Field(() => [AnalyzeResultAgentSummary])
  agents?: AnalyzeResultAgentSummary[];
}

@ObjectType()
export class AnalyzeResultJobSummary {
  @Field(() => [AnalyzeResultAgentSummary])
  agents?: AnalyzeResultAgentSummary[];
}

@ObjectType()
export class AnalyzeResultPicklistIgnoredLine {
  @Field()
  picklistLine: number;

  @Field(() => ID)
  locationId: string;
}

export type PicklistRoute = GeoJSON.MultiLineString;

@ObjectType()
export class PickingJobDetails {
  @Field(() => [ID])
  orderIds: string[];

  @Field(() => ID, { nullable: true })
  unitOfWork?: string;

  @Field(() => ID, { nullable: true })
  pickingWaveId?: string;

  @Field(() => GraphQLLocalDateTime, { nullable: true })
  pickByDate?: string;

  @Field(() => ID, { nullable: true })
  orderLineRuleId?: string;

  @Field(() => ID, { nullable: true })
  picklistGroupKey?: string;

  @Field()
  totalLines: number;

  @Field()
  totalOrderLines: number;

  @Field(() => [AnalyzeResultPicklistIgnoredLine], { nullable: true })
  ignoredLines?: AnalyzeResultPicklistIgnoredLine[];
}

@ObjectType()
export class ReplenishmentJobLineDetails {
  @Field(() => ID)
  destLocationId: string;

  @Field(() => ID)
  itemId: string;

  @Field()
  quantity: number;

  @Field({ nullable: true })
  uom?: string;

  @Field()
  uomQuantity: number;
}

@ObjectType()
export class ReplenishmentJobDetails {
  @Field(() => [ReplenishmentJobLineDetails])
  lines: ReplenishmentJobLineDetails[];
}

export const AnalyzeResultJobDetailsUnion = createUnionType({
  name: 'AnalyzeResultJobDetails',
  types: () => [PickingJobDetails, ReplenishmentJobDetails, ReassignJobDetails],
});

export type AnalyzeResultJobDetails = typeof AnalyzeResultJobDetailsUnion;
