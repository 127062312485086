import {
  LoadOrderSetIssueDataQuery,
  LoadOrderSetIssueDataQueryVariables,
  OrderSetIssueUnpickableLineFragment,
  OrderSetIssueUnpickableLineReason,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import {
  ColumnConfigBase,
  ExportConverter,
  ExportJobParams,
  NULL_VALUE,
} from './types';

export type OrderLineIssuesExportJobParams = ExportJobParams<
  LoadOrderSetIssueDataQueryVariables,
  OrderLineIssuesConverterConfig
>;

export const orderLineIssuesExportJobParams: OrderLineIssuesExportJobParams = {
  converterId: 'ORDER_LINE_ISSUES',
  query: null,
  config: null,
  variables: null,
  filename: 'order-issues.csv',
};

export type OrderLineIssuesDataRow = OrderSetIssueUnpickableLineFragment & {
  reasonString: string;
};

export type OrderLineIssuesConverterConfig = {
  columnsConfig: ColumnConfigBase<OrderLineIssuesDataRow>[];
  dictionaries: {
    reasons: Record<OrderSetIssueUnpickableLineReason, string>;
  };
};

const getReasons = (
  reasonsData: OrderSetIssueUnpickableLineReason[],
  reasonsMap: Record<OrderSetIssueUnpickableLineReason, string>,
) => {
  if (_.isEmpty(reasonsData)) return NULL_VALUE;
  return _(reasonsData)
    .map(r => `${reasonsMap[r]}`)
    .join(', ');
};

export const getOrderLineIssuesTableRows = (
  data: OrderSetIssueUnpickableLineFragment[],
  config: OrderLineIssuesConverterConfig,
): OrderLineIssuesDataRow[] => {
  const getOrderLineIssuesDataRow = (
    row: OrderSetIssueUnpickableLineFragment,
  ) => {
    return {
      ...row,
      reasonString: getReasons(row.reason, config.dictionaries.reasons),
    } as OrderLineIssuesDataRow;
  };

  return _.map(data, getOrderLineIssuesDataRow);
};

export const ORDER_LINE_ISSUES_EXPORT_CONVERTER: ExportConverter<
  LoadOrderSetIssueDataQuery,
  OrderLineIssuesConverterConfig
> = (input, config) => {
  const data = getOrderLineIssuesTableRows(
    input.orderSetIssueSet?.unpickableLines?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
