import { Schema } from '@markdoc/markdoc';

export const navigate: Schema<any, 'HelpNavigate'> = {
  render: 'HelpNavigate',
  attributes: {
    href: {
      type: String,
    },
  },
};
