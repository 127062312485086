import * as React from 'react';
const ObjectAisleMiddleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisleMiddleIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.28 85.07 6.27 112.62 21.12 85.07 6.28 85.07"
    />
    <polygon
      strokeWidth={0}
      points="6.28 8.59 6.27 36.14 21.12 8.59 6.28 8.59"
    />
    <polygon
      strokeWidth={0}
      points="22.32 118.11 22.33 90.73 7.58 118.11 22.32 118.11"
    />
    <polygon
      strokeWidth={0}
      points="6.28 46.83 6.27 74.38 21.12 46.83 6.28 46.83"
    />
    <polygon
      strokeWidth={0}
      points="22.32 41.63 22.33 14.25 7.58 41.63 22.32 41.63"
    />
    <polygon
      strokeWidth={0}
      points="22.32 79.87 22.33 52.49 7.58 79.87 22.32 79.87"
    />
    <path
      strokeWidth={0}
      d="M25.79,3.54H2.91v118.16h22.88l35.12-31.82v-23.52L25.79,3.54ZM23.02,43.4v1.2h1.7v36.22h-1.7v.82h0v1.2h1.7v36.22h-1.7v1.5H5.58v-1.5h-1.7v-36.22h1.7v-.52h0v-1.5h-1.7v-36.22h1.7v-.52h0v-1.5h-1.7V6.37h1.7v-1.23l17.44.03v1.2h1.7v36.22h-1.7v.82h0Z"
    />
    <polygon
      strokeWidth={0}
      points="121.56 85.07 121.56 112.62 106.72 85.07 121.56 85.07"
    />
    <polygon
      strokeWidth={0}
      points="121.56 8.59 121.56 36.14 106.72 8.59 121.56 8.59"
    />
    <polygon
      strokeWidth={0}
      points="105.51 118.11 105.51 90.73 120.25 118.11 105.51 118.11"
    />
    <polygon
      strokeWidth={0}
      points="121.56 46.83 121.56 74.38 106.72 46.83 121.56 46.83"
    />
    <polygon
      strokeWidth={0}
      points="105.51 41.63 105.51 14.25 120.25 41.63 105.51 41.63"
    />
    <polygon
      strokeWidth={0}
      points="105.51 79.87 105.51 52.49 120.25 79.87 105.51 79.87"
    />
    <path
      strokeWidth={0}
      d="M66.92,66.36v23.52s35.12,31.82,35.12,31.82h22.88s0-118.16,0-118.16h-22.88s-35.12,62.82-35.12,62.82ZM104.81,43.4v-.82s-.85,0-.85,0h-.85V6.37s1.7,0,1.7,0v-1.2l17.44-.03v1.23s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.28s0,1.1,0,1.1h0v.52s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.12s0,.15,0,.15v1.1h0v.52s1.7,0,1.7,0v36.22s-.85,0-.85,0h-.85s0,.12,0,.12v.28s0,1.1,0,1.1h-17.44v-1.23s0-.28,0-.28h-.85s-.85,0-.85,0v-36.22s1.7,0,1.7,0v-1.2h0s0-.54,0-.54v-.28s-.85,0-.85,0h-.85v-36.22s1.7,0,1.7,0v-1.2h0Z"
    />
  </svg>
);
export default ObjectAisleMiddleIcon;
