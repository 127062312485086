import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardActionbar } from '../../components/DashboardActionbar';
import { HelperMessage } from '../../components/HelperMessage';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ContainerMain } from '../../components/layout/ContainerMain';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ItemListCard } from '../../dashboard/containers/ItemListCard';
import { ItemListCardsContainer } from '../../dashboard/containers/ItemListCardsContainer';
import WarehouseContainer from '../WarehouseContainer';
import { BetaBilling } from './cards/BetaBilling';
import { BetaContracts } from './cards/BetaContracts';
import { BetaMarketplace } from './cards/BetaMarketplace';
import { BetaSales } from './cards/BetaSales';

export const WarehouseDashboardBeta: React.FC = () => {
  const { t } = useTranslation('app-beta');

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar />
      <ContainerMain className="flex">
        <ContainerCol overflow>
          <ContainerScroll className="pt-0 pb-24">
            <ItemListCard fullWidth>
              <ScreenTitle
                // subtitle={t`Digital Twin`}
                title={t`Join our Beta program today!`}
              />
              <HelperMessage hasPadding>
                <p>{t`And get valuable insights before everyone else`}</p>
              </HelperMessage>
            </ItemListCard>
            {/* <ItemListCardsContainer className="p-4 space-x-4 space-y-4">
              <BetaOptimise />
            </ItemListCardsContainer> */}

            <ItemListCardsContainer className="p-4 space-x-4 space-y-4">
              <BetaSales />
              <BetaBilling />
              <BetaContracts />
              <BetaMarketplace />
            </ItemListCardsContainer>
          </ContainerScroll>
        </ContainerCol>
      </ContainerMain>
    </WarehouseContainer>
  );
};

export default WarehouseDashboardBeta;
