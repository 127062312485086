import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import {
  viewerAreaBays,
  viewerAutoSizeId,
  viewerLevelLocations,
  viewerPlanes,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
  viewerShowLocations,
} from '../viewer/store/viewer.state';
import ViewModeToolbarCmp, {
  ViewModeToolbarCmpProps,
} from './ViewModeToolbarCmp';

export type ViewModeToolbarProps = {
  levelIssues?: Record<number, number>;
};

const ViewModeToolbar: React.FC<ViewModeToolbarProps> = props => {
  const [planeId, setPlaneId] = useRecoilState(viewerSelectedPlaneId);
  const [showLocations, setShowLocations] = useRecoilState(viewerShowLocations);
  const [selectedLevel, setSelectedLevel] = useRecoilState(viewerSelectedLevel);

  const bayLoadable = useRecoilValueLoadable(viewerAreaBays);
  const locationsLoadable = useRecoilValueLoadable(viewerLevelLocations);

  const planes = useRecoilValue(viewerPlanes);
  const setAutoSizeId = useSetRecoilState(viewerAutoSizeId);

  if (_.isEmpty(planes) || _.isNil(planeId)) return null;

  const levels = _.find(planes, p => p.id === planeId)?.levels;
  if (_.isEmpty(levels)) return null;

  const toolbarConfig: ViewModeToolbarCmpProps = {
    planes: planes,
    selectedPlaneId: planeId,
    onPlaneChange: v => {
      setPlaneId(v);
    },
    showLocations,
    toggleShowLocations: () => {
      setShowLocations(!showLocations);
    },
    isLocationsLoading: locationsLoadable.state === 'loading',
    isBaysLoading: bayLoadable.state === 'loading',
    selectedLevel: _.includes(levels, selectedLevel)
      ? selectedLevel
      : _.first(levels),
    setSelectedLevel,
    levels,
    adjustStageCallback: () => {
      setAutoSizeId(nanoid());
    },
    levelIssues: props.levelIssues,
  };
  return <ViewModeToolbarCmp {...toolbarConfig} />;
};

export default ViewModeToolbar;
