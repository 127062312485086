import * as React from 'react';
const ObjectsMoveIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectsMoveIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="19.44 82.82 39.74 94.55 39.74 71.1 19.44 59.37 19.44 82.82"
    />
    <polygon
      strokeWidth={0}
      points="40.22 46.76 19.97 58.45 40.22 70.14 60.48 58.45 40.22 46.76"
    />
    <polygon
      strokeWidth={0}
      points="61.01 59.42 40.76 71.11 40.76 94.5 61.01 82.81 61.01 59.42"
    />
    <polygon
      strokeWidth={0}
      points="66.62 82.82 86.93 94.55 86.93 71.1 66.62 59.37 66.62 82.82"
    />
    <polygon
      strokeWidth={0}
      points="87.41 46.76 67.15 58.45 87.41 70.14 107.67 58.45 87.41 46.76"
    />
    <polygon
      strokeWidth={0}
      points="87.94 94.5 108.2 82.81 108.2 59.42 87.94 71.11 87.94 94.5"
    />
    <polygon
      strokeWidth={0}
      points="63.34 30.32 43.03 18.6 43.03 42.05 63.34 53.77 63.34 30.32"
    />
    <polygon
      strokeWidth={0}
      points="84.07 17.67 63.82 5.98 43.56 17.68 63.82 29.37 84.07 17.67"
    />
    <polygon
      strokeWidth={0}
      points="64.35 53.73 84.6 42.03 84.6 18.64 64.35 30.34 64.35 53.73"
    />
    <polygon
      strokeWidth={0}
      points="70.3 87.17 68.57 86.18 66.85 85.18 64.04 85.18 61.23 85.18 59.51 86.18 57.79 87.18 57.79 106.92 49.2 98.34 49.2 111.18 64.04 125.98 78.88 111.17 78.88 98.34 70.3 106.91 70.3 87.17"
    />
    <polygon
      strokeWidth={0}
      points="118.94 14.89 97.97 14.87 88.9 23.95 101.03 23.95 87.07 37.91 87.59 39.84 88.1 41.76 90.08 43.75 92.07 45.72 94 46.24 95.92 46.76 109.88 32.8 109.88 44.93 118.96 35.85 118.94 14.89"
    />
    <polygon
      strokeWidth={0}
      points="32.82 44.8 34.75 44.28 36.74 42.3 38.71 40.31 39.23 38.39 39.74 36.47 25.78 22.51 37.92 22.5 28.84 13.42 7.88 13.45 7.86 34.41 16.94 43.49 16.94 31.36 30.9 45.31 32.82 44.8"
    />
  </svg>
);
export default ObjectsMoveIcon;
