import * as React from 'react';
const LinkChain3Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkChain3Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M67.83,47.19l.08-.5c.3-1.97.38-4.01.24-6.05l-.16-2.31,1.79,1.47c.67.55,1.32,1.14,1.93,1.75l14.32,14.32c.67.67,1.3,1.37,1.89,2.11l1.43,1.78-2.28-.16c-.64-.04-1.29-.07-1.92-.07-1.38,0-2.76.1-4.09.31l-.5.08-12.73-12.73Z"
    />
    <path
      strokeWidth={0}
      d="M100.95,120.66c-6.34,0-12.68-2.71-17.41-7.44l-10.88-10.88c-.66-.66-1.3-1.37-1.88-2.1l-1.44-1.78,2.28.16c.64.04,1.29.07,1.93.07,1.38,0,2.75-.1,4.09-.31l.5-.08,10.16,10.16c3.49,3.49,8.1,5.49,12.65,5.49,3.69,0,7.06-1.33,9.49-3.76,5.62-5.63,4.84-15.55-1.74-22.13l-10.88-10.88c-3.48-3.48-8.09-5.48-12.65-5.48-3.69,0-7.05,1.33-9.48,3.75-1.33,1.33-2.33,2.94-2.98,4.79l-.28.81-.85-.16c-1.63-.3-3.26-.99-4.74-1.98l-.65-.44.27-.73c.96-2.66,2.44-5.01,4.41-7,3.72-3.71,8.8-5.76,14.31-5.76,6.33,0,12.66,2.71,17.39,7.43l10.88,10.88c4.48,4.48,7.12,10.28,7.41,16.32.29,5.94-1.73,11.38-5.68,15.33-3.69,3.69-8.75,5.71-14.24,5.71Z"
    />
    <path
      strokeWidth={0}
      d="M73.52,93.24c-6.33,0-12.68-2.71-17.4-7.44l-14.32-14.32c-9.2-9.2-9.98-23.41-1.73-31.66,1.97-1.97,4.41-3.51,7.08-4.47l.72-.26.44.62c1.02,1.46,1.74,3.08,2.08,4.7l.18.87-.84.28c-1.89.64-3.53,1.65-4.89,3.01-5.63,5.63-4.85,15.56,1.72,22.13l14.33,14.33c3.48,3.48,8.09,5.48,12.64,5.48,3.69,0,7.06-1.34,9.49-3.76,1.32-1.33,2.32-2.94,2.96-4.78l.28-.81.84.16c1.63.31,3.27.99,4.74,1.98l.65.44-.26.73c-.97,2.68-2.47,5.05-4.46,7.04-3.69,3.69-8.75,5.72-14.25,5.72Z"
    />
    <path
      strokeWidth={0}
      d="M48.85,60.92c-.99-1.47-1.68-3.11-1.99-4.74l-.16-.85.81-.28c1.84-.64,3.45-1.64,4.78-2.98,5.6-5.63,4.79-15.56-1.8-22.14l-10.92-10.89c-3.5-3.49-8.11-5.49-12.67-5.49-3.69,0-7.05,1.33-9.46,3.75-5.61,5.63-4.81,15.57,1.79,22.15l10.19,10.17-.07.5c-.29,1.96-.37,3.98-.22,6.02l.16,2.29-1.79-1.44c-.73-.59-1.44-1.22-2.1-1.89l-10.92-10.89c-9.22-9.2-10.04-23.41-1.83-31.67,3.67-3.69,8.72-5.71,14.21-5.71.37,0,.74,0,1.11.03,6.04.29,11.85,2.93,16.34,7.41l10.92,10.89c9.22,9.2,10.02,23.43,1.77,31.71-1.99,1.97-4.34,3.46-6.99,4.42l-.73.26-.44-.65Z"
    />
  </svg>
);
export default LinkChain3Icon;
