import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export type TableBodyProps = {
  children?: React.ReactNode;
  className?: string;
};

export const TableBody = ({ children, className }: TableBodyProps) => {
  const [id] = useState<string>(_.uniqueId());

  return <tbody className={classNames(className)}>{children}</tbody>;
};
