import { LayoutEntryPoint } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { stageStateById } from '../../../../layout/stage/stage.state';
import {
  SidebarState,
  sidebarStateAll,
  sidebarStateByType,
} from '../../../../store/sidebar.state';
import {
  ConvertedAisleFeature,
  ConvertedBayFeature,
  ConvertedLocationFeature,
} from '../converter.serializable.model';
import {
  converterAreaConfiguration,
  converterEditableArea,
  converterEditableAreaId,
  converterEditableAreaIdAtom,
  converterFloorAreas,
  converterSelectedArea,
} from './converter.area.state';
import { converterLocationMap, converterWizardStepId } from './converter.state';

const getKey = (postfix: string) => `warebee-converter-layout-${postfix}`;

const converterLayoutSelectedLevelInner = atom<number>({
  key: getKey('area-selected-level-inner'),
  default: 0,
});

export const converterLayoutSelectedLevel = selector<number>({
  key: getKey('area-selected-level'),
  get: ({ get }) => {
    return get(converterLayoutSelectedLevelInner);
  },
  set: ({ set }, value) => {
    set(converterLayoutSelectedLocationId, null);
    set(converterLayoutShowLocations, true);
    set(converterLayoutSelectedLevelInner, value);
  },
});

export const converterLayoutLocations = selector<ConvertedLocationFeature[]>({
  key: getKey('level-locations'),
  get: async ({ get }) => {
    const area = get(converterEditableArea);
    if (!area) return null;
    const level = get(converterLayoutSelectedLevel);

    return _(area.bays)
      .flatMap(b => b.locations)
      .filter(l => l.locationLevel === level)
      .value();
  },
});

// export const converterLayoutSelectedBayLocations = selector<
//   ConvertedLocationFeature[]
// >({
//   key: getKey('bay-locations'),
//   get: async ({ get }) => {
//     const area = get(converterEditableArea);

//     const bay = get(converterLayoutSelectedBay);
//     if (!area || !bay) return null;

//     return _.find(area.bays, b => b.id === bay?.id)?.locations;
//   },
// });

export const converterLayoutShowLocations = atom<boolean>({
  key: getKey('show-level-locations'),
  default: false,
});

export const converterLayoutShowPortals = atom<boolean>({
  key: getKey('show-portals'),
  default: false,
});

export const converterLayoutSelectedBayId = atom<string>({
  key: getKey('selected-bay'),
  default: null,
});

export const converterLayoutAltSelectedBayId = atom<string>({
  key: getKey('alt-selected-bay'),
  default: null,
});

export const converterLayoutSelectedAreaBayMap = selector<
  Record<string, ConvertedBayFeature>
>({
  key: getKey('bay-map'),
  get: ({ get }) => {
    const area = get(converterSelectedArea);
    return _.keyBy(area?.bays, b => b.id);
  },
});

export const converterLayoutSelectedBay = selector<ConvertedBayFeature>({
  key: getKey('selected-bay-selector'),
  get: ({ get }) => {
    const selectedId = get(converterLayoutSelectedBayId);
    const area = get(converterEditableArea);
    if (!area) return null;
    return area.bays.find(b => b.id === selectedId);
  },
  set: ({ get, set }, bay: ConvertedBayFeature) => {
    set(converterLayoutSelectedAisleId, null);

    const propertySidebar = get(
      sidebarStateByType('sidebar-converter-info-common'),
    );

    if (!propertySidebar.isPinned) {
      set(sidebarStateByType('sidebar-converter-info-common'), {
        ...propertySidebar,
        isCollapsed: !bay,
      });
    }
    set(converterLayoutSelectedLocationId, null);
    set(
      converterLayoutSelectedBayId,
      get(converterLayoutSelectedBayId) === bay?.id ? null : bay?.id,
    );
  },
});

const converterLayoutSelectedAisleId = atom<string>({
  key: getKey('selected-aisle-id'),
  default: null,
});

export const converterLayoutSelectedAisle = selector<ConvertedAisleFeature>({
  key: getKey('selected-aisle-selector'),
  get: ({ get }) => {
    const aisleId = get(converterLayoutSelectedAisleId);
    if (_.isNil(aisleId)) return null;
    const area = get(converterEditableArea);
    return _.find(area?.aisles, aisle => aisle.id === aisleId);
  },

  set: ({ get, set }, aisle: ConvertedAisleFeature) => {
    const aisleId = get(converterLayoutSelectedAisleId);
    const newAisle = !_.isNil(aisle) && aisleId !== aisle?.id;

    const propertySidebar = get(
      sidebarStateByType('sidebar-converter-info-common'),
    );

    if (!propertySidebar.isPinned) {
      set(sidebarStateByType('sidebar-converter-info-common'), {
        ...propertySidebar,
        isCollapsed: !newAisle,
      });
    }
    set(converterLayoutSelectedLocationId, null);
    set(converterLayoutSelectedBayId, null);
    set(converterLayoutSelectedAisleId, aisle?.id);
  },
});

export const converterLayoutSelectedLocationIdAtom = atom<string>({
  key: getKey('selected-location-id-atom'),
  default: null,
});

export const converterLayoutSelectedLocationId = selector<string>({
  key: getKey('selected-location-id'),
  get: ({ get }) => get(converterLayoutSelectedLocationIdAtom),
  set: ({ get, set }, locationId: string) => {
    //deselect concurrent
    set(converterLayoutSelectedAisleId, null);
    //
    const locationsMap = get(converterLocationMap);
    if (_.isNil(locationId)) {
      set(converterLayoutSelectedLocationIdAtom, null);
      return;
    }
    const location = locationsMap.get(locationId);
    if (!_.isNil(location)) {
      set(converterEditableAreaIdAtom, location?.areaId);
      set(converterLayoutSelectedBayId, location?.bayId);

      let locationSidebar = get(
        sidebarStateByType('sidebar-converter-info-location'),
      ) as SidebarState;
      locationSidebar = {
        ...locationSidebar,
        isCollapsed: locationSidebar.isPinned
          ? locationSidebar.isCollapsed
          : !location,
        isHidden: !location,
      };

      let infoSidebar = get(
        sidebarStateByType('sidebar-converter-info-common'),
      ) as SidebarState;
      if (!infoSidebar.isPinned && !!location) {
        infoSidebar = {
          ...infoSidebar,
          isCollapsed: false,
        };
      }
      set(sidebarStateAll, {
        ...get(sidebarStateAll),
        'sidebar-converter-info-location': locationSidebar,
        'sidebar-converter-info-common': infoSidebar,
      });

      set(converterLayoutShowLocations, true);
      set(converterLayoutSelectedLevelInner, location.level);
      set(converterLayoutSelectedLocationIdAtom, location?.locationId);
    }
  },
});

export const converterLayoutAltSelectedAisleId = atom<string>({
  key: getKey('alternative-selected-aisle'),
  default: null,
});

export const converterLayoutSelectedLocation =
  selector<ConvertedLocationFeature>({
    key: getKey('selected-location'),
    get: ({ get }) => {
      const locationId = get(converterLayoutSelectedLocationIdAtom);
      if (_.isNil(locationId)) return null;
      const levelLocations = get(converterLayoutLocations);
      return _.find(levelLocations, l => l.locationId === locationId);
    },
    // set: ({ get, set }, location: ConvertedLocationFeature) => {
    //   set(converterLayoutSelectedLocationId, location?.locationId);
    // },
  });

export const converterLayoutHoveredFeature = atom<
  ConvertedBayFeature | ConvertedAisleFeature
>({
  key: getKey('hovered-shape'),
  default: null,
});

export const converterLayoutHoveredLocation = atom<ConvertedLocationFeature>({
  key: getKey('hovered-location'),
  default: null,
});

export const converterSelectedPortal = atom<LayoutEntryPoint>({
  key: getKey('selected-portal'),
  default: null,
});

const converterHighlightedRackTypesAtom = atom<string[]>({
  key: getKey('highlighted-rack-types-atom'),
  default: [],
});

export const converterHighlightedRackTypes = selector<string[]>({
  key: getKey('highlighted-rack-types'),
  get: ({ get }) => {
    return get(converterApplyHighlightedRackTypes)
      ? get(converterHighlightedRackTypesAtom)
      : [];
  },
  set: ({ get, set }, value: string[]) =>
    set(converterHighlightedRackTypesAtom, value),
});

export const converterApplyHighlightedRackTypes = selector<boolean>({
  key: getKey('apply-highlighted-rack-types'),
  get: ({ get }) => {
    return get(converterWizardStepId) === 'default-sizes';
  },
});

export const converterShelvingTypeSelected = atom<string>({
  key: getKey('shelving-type-selected'),
  default: null,
});

export const converterAutoSizeId = selector<string>({
  key: getKey('auto-size-id'),
  get: () => null,
  set: ({ get, set }, autosizeId: string) => {
    const areasConfiguration = get(converterAreaConfiguration);
    const areaId = get(converterEditableAreaId);
    const areas = get(converterFloorAreas);

    const affectedAreas = _.filter(areas, a => !areaId || a.id === areaId);
    if (_.isEmpty(affectedAreas)) return null;
    if (_.isEmpty(affectedAreas)) return;

    const xMin = _.min(affectedAreas.map(a => areasConfiguration[a.id].x));
    const xMax = _.max(
      affectedAreas.map(a => areasConfiguration[a.id].x + a.size[0]),
    );
    const yMin = _.min(affectedAreas.map(a => areasConfiguration[a.id].y));
    const yMax = _.max(
      affectedAreas.map(a => areasConfiguration[a.id].y + a.size[1]),
    );

    set(stageStateById('converter-area-view'), {
      autoSizeId: autosizeId,
      contentBounds: [
        [xMin, yMin],
        [xMax, yMax],
      ],
    });
    return;
  },
});
