import * as React from 'react';
const CompareBeforeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CompareBeforeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M112.58,5.09H15.53c-5.52,0-10,4.48-10,10v98c0,5.52,4.48,10,10,10h97.05c5.52,0,10-4.48,10-10V15.09c0-5.52-4.48-10-10-10ZM86.37,95.58c0,1-.82,1.81-1.81,1.81h-39.53c-1,0-1.81-.82-1.81-1.81v-9.34c0-1,.82-1.72,1.81-1.72h12.06v-38.99l-11.79,5.08c-.45.18-.82.27-1.09.27-.63,0-1-.45-1-1.18v-10.34c0-.63.27-1,.91-1.27l16.41-7.71c.63-.27,1.18-.36,2.09-.36h8.16c1,0,1.72.73,1.72,1.72v52.77h12.06c1,0,1.81.73,1.81,1.72v9.34Z"
      opacity={0.8}
    />
  </svg>
);
export default CompareBeforeIcon;
