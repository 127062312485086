import classNames from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

export type AgentResourceProps = {
  id: string | number;
  title: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  className?: string;
  children?: ReactNode;
};

const AgentResourceObject: React.FC<AgentResourceProps> = props => {
  const Icon = props.icon;

  return (
    <div
      data-component="AgentResourceObject"
      key={`agent-${props.id}`}
      title={props.title}
      className={classNames(
        'flex',
        'items-center',
        'rounded',
        'px-2 py-1',
        'bg-app-panel/50 shadow',
        'border border-menu-400/50',
        props.className,
      )}
    >
      <Icon
        data-component="AgentResourceIcon"
        className={classNames('w-5 h-5', 'fill-current', 'ltr:mr-2 rtl:ml-2')}
      />
      <span
        className={classNames(
          // 'text-xxs',
          'flex-1',
          'truncate',
        )}
      >
        {props.title}
      </span>
      {props.children}
    </div>
  );
};

export default AgentResourceObject;
