import { useApolloClient } from '@apollo/client';
import {
  FindOrderLinesByFilterDocument,
  FindOrderLinesByFilterQuery,
  FindOrderLinesByFilterQueryVariables,
  PickingPolicyFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { getPickingPolicyOrderLineRuleInput } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyOrderLineByRule,
  pickingPolicyOrderLineByRuleLoadStatus,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { PickingDetailsSelectionIdentity } from '../../simulation/store/pickingPolicy/pickingPolicy.types';
import { simulationCurrent } from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';


export type OrderLinesByRuleParams = {
  selectedDetails: PickingDetailsSelectionIdentity;
  policy: PickingPolicyFragment;
  limit?: number;
  skip?: number;
  isAppend?: boolean;
  // filter?: ;
};

function useLoadOrderLinesByRule() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load simulation order lines by rule.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: OrderLinesByRuleParams) => {
        set(pickingPolicyOrderLineByRuleLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: OrderLinesByRuleParams) => {
        const { policy, selectedDetails } = params;
        const simulation = await snapshot.getPromise(simulationCurrent);
        const current = await snapshot.getPromise(pickingPolicyOrderLineByRule);

        const ruleIndex = selectedDetails.showOrderLinesUnmatched
          ? policy?.orderLineRules?.length ?? 0
          : _.findIndex(
              policy.orderLineRules,
              rule => rule.id === selectedDetails.ruleId,
            );

        const excludeMatching = _(policy.orderLineRules)
          .take(ruleIndex)
          .map(getPickingPolicyOrderLineRuleInput)
          .flatMap(rule => rule.orderLinesMatch)
          .value();

        const includeMatching = selectedDetails.showOrderLinesUnmatched
          ? null
          : getPickingPolicyOrderLineRuleInput(policy.orderLineRules[ruleIndex])
              ?.orderLinesMatch;

        const query = client.watchQuery<
          FindOrderLinesByFilterQuery,
          FindOrderLinesByFilterQueryVariables
        >({
          query: FindOrderLinesByFilterDocument,
          variables: {
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip,
            },
            input: {
              simulationId: simulation.id,
              includeMatching,
              excludeMatching,
            },
            // filter: params.filter,
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(pickingPolicyOrderLineByRuleLoadStatus, AsyncLoadStatus.Error);
            return;
          }

          let content = data?.findSimulationOrderLinesByFilter?.content || [];
          if (params.isAppend) {
            content = [...current.content, ...content];
          }

          set(pickingPolicyOrderLineByRule, {
            content,
            totalCount: data?.findSimulationOrderLinesByFilter.totalCount,
          });
          set(pickingPolicyOrderLineByRuleLoadStatus, AsyncLoadStatus.Ok);
        });
        setObservable(queryObservable);
      },
  );

  async function call(params: OrderLinesByRuleParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadOrderLinesByRule;
