import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import {
  DashboardGroupState,
  DashboardOrder,
  DashboardViewMode,
  DataDashboardMenuItem,
} from './dashboard.types';

export const DASHBOARD_ITEMS_COUNT = 50;

export function getDefaultDashboardState(): DashboardGroupState {
  return {
    viewMode: DashboardViewMode.card,
    order: DashboardOrder.updatedDesc,
    autoUpdate: true,
    page: {
      skip: 0,
      limit: DASHBOARD_ITEMS_COUNT,
    },
  };
}

export const dataDashboardMenuItems: (
  t: TFunction<'app'>,
) => DataDashboardMenuItem[] = t => [
  {
    id: 'data-dash-data-all',
    title: t(`All Data Dashboard`, { ns: 'app' }),
    titleMinimized: t(`Data Dashboard`, { ns: 'app' }),
    disabled: false,
    icon: Icon.HeatMapLayoutBack,
    menuLevel: 1,
  },
  {
    id: 'data-dash-assignment',
    title: t(`Assignments`, { ns: 'app' }),
    titleMinimized: t(`Assignments`, { ns: 'app' }),
    disabled: false,
    icon: Icon.DataAssignment,
    menuLevel: 2,
  },
  {
    id: 'data-dash-items',
    title: t(`Items`, { ns: 'app' }),
    titleMinimized: t(`Items`, { ns: 'app' }),
    disabled: false,
    icon: Icon.DataItems,
    menuLevel: 2,
  },
  {
    id: 'data-dash-order',
    title: t(`Orders`, { ns: 'app' }),
    titleMinimized: t(`Orders`, { ns: 'app' }),
    disabled: false,
    icon: Icon.DataOrders,
    menuLevel: 2,
  },
  {
    id: 'data-dash-dataset-object',
    title: t(`Datasets`, { ns: 'app' }),
    titleMinimized: t(`Datasets`, { ns: 'app' }),
    disabled: false,
    icon: Icon.Simulation,
    menuLevel: 2,
  },
  {
    id: 'data-dash-files',
    title: t(`Files`, { ns: 'app' }),
    titleMinimized: t(`Files`, { ns: 'app' }),
    disabled: false,
    icon: Icon.FiletypeAny,
    menuLevel: 2,
  },
];
