import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../components/actions/ButtonSwitchMulti';
import { allocationViewAs } from '../simulation/store/allocationPolicy/allocationPolicy.state';
import { AllocationPreviewViewAs } from '../simulation/store/allocationPolicy/allocationPolicy.types';

const AllocationSettingsViewSwitch: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [viewAs, setViewAs] = useRecoilState(allocationViewAs);

  const options: (ButtonSwitchMultiOption & { id: AllocationPreviewViewAs })[] =
    [
      {
        id: 'allocation-requirement',
        label: t`Requirements`,
      },
      {
        id: 'allocation-run',
        label: t`Allocate`,
      },
      {
        id: 'layout',
        label: t`Map`,
      },
    ];

  return (
    <ButtonSwitchMulti
      autoSize
      className="mx-3"
      classNameLabel="text-xs py-1 px-2"
      buttonType="minimal"
      selectedIndex={_.findIndex(options, o => o.id === viewAs)}
      onClick={index => setViewAs(options[index].id)}
      options={options}
    />
  );
};

export default AllocationSettingsViewSwitch;
