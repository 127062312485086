import { ActivityFeedDataRow } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatTimespan } from '../common/formatHelper';
import useFormatter from '../common/useFormatter';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import { ColumnConfig } from '../components/DatasetTable';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import ActualityEntityTag from '../feed/components/ActualityIEntityTag';
import Tag from '../helpContext/Tag';
import { getActivityFeedSchema } from '../import/activityFeed/schema/activityFeedSchema';
import EmptyValueTag from '../simulation/tags/EmptyValueTag';

function useActivityFeedDataTableConfig(): ColumnConfig<ActivityFeedDataRow>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();
  const schema = _.keyBy(getActivityFeedSchema(tImporter).fields, f => f.name);

  const columnsConfig: ColumnConfig<ActivityFeedDataRow>[] = [
    {
      field: 'eventId',
      title: schema['eventId'].title,
      hasFilter: true,
      hasSort: true,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },
    {
      field: 'eventProcessType',
      title: schema['eventProcessType'].title,
      render: (field: string) => <Tag className="text-xxs">{field}</Tag>,
    },
    {
      field: 'eventType',
      title: schema['eventType'].title,
      render: (field: string) => <Tag className="text-xxs">{field}</Tag>,
    },
    {
      field: 'eventEndTime',
      title: schema['eventEndTime'].title,
      render: (v: Date) => formatDateTime(v),
      hasSort: true,
    },
    {
      field: 'jobId',
      title: schema['jobId'].title,
      hasFilter: true,
      hasSort: true,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },
    { field: 'consignee', title: schema['consignee'].title, hasFilter: true },
    {
      field: 'sku',
      title: schema['sku'].title,
      hasFilter: true,
      hasSort: true,
      render: (sku: string, row) => (
        <ActualityEntityTag
          entity="item"
          title={sku}
          filters={{ consignee: row['consignee'], sku }}
        />
      ),
    },
    {
      field: 'uom',
      title: schema['uom'].title,
      render: (v: string) =>
        v ? <Tag className="text-xxs">{v}</Tag> : <EmptyValueTag />,
    },
    { field: 'quantity', title: schema['quantity'].title },
    {
      field: 'scanCode',
      title: schema['scanCode'].title,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },

    {
      field: 'locationId',
      title: schema['locationId'].title,
      hasFilter: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    { field: 'locationIdSource', title: schema['locationIdSource'].title },

    { field: 'warehouseArea', title: schema['warehouseArea'].title },
    {
      field: 'warehouseAreaSource',
      title: schema['warehouseAreaSource'].title,
    },
    {
      field: 'agentType',
      title: schema['agentType'].title,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'agentId',
      title: schema['agentId'].title,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'agentUser',
      title: schema['agentUser'].title,
      hasSort: true,
      hasFilter: true,
    },

    {
      field: 'eventStartTime',
      title: schema['eventStartTime'].title,
      render: (v: Date) => formatDateTime(v),
    },

    {
      field: 'jobGroupId',
      title: schema['jobGroupId'].title,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },
    { field: 'jobLine', title: schema['jobLine'].title },
    { field: 'agentEnergy', title: schema['agentEnergy'].title },
    {
      field: 'agentSignalStrength',
      title: schema['agentSignalStrength'].title,
    },
    {
      field: 'importJobId',
      title: t`Import Job ID`,
      hasFilter: true,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },
    {
      field: 'duration',
      title: t`Duration`,
      render: (v: number) => {
        if (_.isNil(v)) return <EmptyValueTag />;

        return v < 0
          ? `(${formatTimespan(v, 0, true)})`
          : formatTimespan(v, 0, true);
      },
    },
    // { field: 'partition', title: schema['partition'].title },
  ];
  return columnsConfig;
}
export default useActivityFeedDataTableConfig;
