import * as React from 'react';
const DataDashboardIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataDashboardIcon"
    {...props}
  >
    <rect
      width={120}
      height={12.68}
      x={4}
      y={4.12}
      strokeWidth={0}
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M4,20.59v58h58.5V20.59H4ZM8.36,31.56c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83H9.43c-.54,0-1.07-.42-1.07-.83v-2.5ZM58.04,72.62c0,.77-.77,1.55-1.55,1.55H9.9c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h46.59c.77,0,1.55.77,1.55,1.55v4.64Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M4,123.79h38v-43H4v43ZM7.54,84.51c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83H8.61c-.54,0-1.07-.42-1.07-.83v-2.5ZM7.54,93.48c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55H9.08c-.77,0-1.55-.77-1.55-1.55v-4.64ZM7.54,103.84c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55H9.08c-.77,0-1.55-.77-1.55-1.55v-4.64ZM7.54,114.2c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55H9.08c-.77,0-1.55-.77-1.55-1.55v-4.64Z"
    />
    <path
      strokeWidth={0}
      d="M45,123.79h38v-43h-38v43ZM48.54,84.51c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83h-19.33c-.54,0-1.07-.42-1.07-.83v-2.5ZM48.54,93.48c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64ZM48.54,103.84c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64ZM48.54,114.2c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Z"
    />
    <path
      strokeWidth={0}
      d="M86,123.79h38v-43h-38v43ZM89.54,84.51c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83h-19.33c-.54,0-1.07-.42-1.07-.83v-2.5ZM89.54,93.48c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64ZM89.54,103.84c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64ZM89.54,114.2c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Z"
    />
    <path
      strokeWidth={0}
      d="M124,20.59h-58.5v58h58.5V20.59ZM90.03,29.56v2.5c0,.42-.54.83-1.07.83h-19.33c-.54,0-1.07-.42-1.07-.83v-2.5c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83ZM121.23,73.76c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64ZM121.23,63.89c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64ZM121.23,53.53c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64ZM121.23,43.17c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64Z"
      opacity={0.6}
    />
  </svg>
);
export default DataDashboardIcon;
