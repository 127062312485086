import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';

export type PolicyEditorDataStatusProps = {
  loadedCount: number;
  totalCount: number;
};

const PolicyEditorDataStatus: React.FC<PolicyEditorDataStatusProps> = props => {
  const { t } = useTranslation('app');

  const hasLeftPart =
    !_.isNil(props.loadedCount) &&
    props.loadedCount !== 0 &&
    props.loadedCount !== props.totalCount;

  const loaded = formatInteger(props.loadedCount);
  const total =
    props.totalCount > 0 ? formatInteger(props.totalCount) : t`0 Found`;

  return (
    <div
      data-component="PolicyEditorDataStatus"
      className={classNames('flex items-center mx-1')}
    >
      <div
        className={classNames(
          'px-0.5 ltr:ml-0.5 rtl:mr-0.5 text-menu-text/60 text-xs',
        )}
      >
        <span className="opacity-80">{'('}</span>
        {hasLeftPart && (
          <>
            <span>{loaded}</span>
            <span className="mx-1 opacity-80">{t`of`}</span>
          </>
        )}
        <span>{total}</span>
        <span className="opacity-80">{')'}</span>
      </div>
    </div>
  );
};

export default PolicyEditorDataStatus;
