import {
  Field,
  InputAndObjectType,
  ObjectType,
} from '@warebee/shared/util-backend-only-types';

export type FilterOperation = 'MATCH' | 'RANGE';

export interface Filter<T> {
  type: T;
  valueIn?: FilterValue[];
  range?: NumberFilterRange;
  stringRange?: StringFilterRange;
  isNull?: boolean;
  isNot?: boolean;
}

export interface FilterIntersection<F extends Filter<any>> {
  allOf: F[];
}

export interface FilterUnion<F extends Filter<any>> {
  anyOf: FilterIntersection<F>[];
}

@InputAndObjectType()
export class FilterValue {
  @Field()
  title: string;
}

@InputAndObjectType()
export class NumberFilterRange {
  @Field({ nullable: true })
  from?: number;

  @Field({ nullable: true })
  excludeFrom?: boolean;

  @Field({ nullable: true })
  to?: number;

  @Field({ nullable: true })
  excludeTo?: boolean;
}

@InputAndObjectType()
export class StringFilterRange {
  @Field({ nullable: true })
  from?: string;

  @Field({ nullable: true })
  excludeFrom?: boolean;

  @Field({ nullable: true })
  to?: string;

  @Field({ nullable: true })
  excludeTo?: boolean;
}

@ObjectType()
export class FilterMatch {
  @Field()
  total: number;

  @Field()
  exclusive: number;
}

@InputAndObjectType({ isAbstract: true })
export class FilterBase {
  @Field(() => [FilterValue], { nullable: true })
  valueIn?: FilterValue[];

  @Field(() => NumberFilterRange, { nullable: true })
  range?: NumberFilterRange;

  @Field(() => StringFilterRange, { nullable: true })
  stringRange?: StringFilterRange;

  @Field({ nullable: true })
  isNull?: boolean;

  @Field({ nullable: true })
  isNot?: boolean;
}

// @ObjectType()
// export class FoundFilterValue extends FilterValue {
//   @Field()
//   excluded: boolean;
// }
