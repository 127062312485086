import { AgentSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatToPrecision } from '../common/formatHelper';
import { InputGroupList } from '../components/inputs/InputGroupList';
import TitleSection from '../components/layout/TitleSection';
import { StatListItem } from '../components/stats/StatListItem';
import { getFilterConfiguration } from '../simulation/store/resourcePolicy.helper';
import { simulationUoms } from '../simulation/store/simulation.state';
import { warehouseSelected } from '../store/warehouse.state';
import {
  AgentFilterGroupConfig,
  AgentFilterSectionConfig,
} from './AgentEditorSettings';

export type AgentSummaryProps = {
  agentSettings: AgentSettingsFragment;
  isSelected?: boolean;
};

const AgentSummary: React.FC<AgentSummaryProps> = props => {
  const { agentSettings } = props;
  const wh = useRecoilValue(warehouseSelected);
  const { t } = useTranslation('simulation');
  const uoms = useRecoilValue(simulationUoms);

  const filterConfiguration = getFilterConfiguration({
    agent: agentSettings,
    wh,
    uoms,
    t,
  });

  function drawSection(section: AgentFilterSectionConfig) {
    const groupsToDraw = section.filterGroups.filter(group => {
      return _.some(group.filters, filter => {
        const path = filter.path.join('.');
        return !!_.get(agentSettings, path);
      });
    });
    return (
      <React.Fragment key={`section-${section.title}`}>
        <TitleSection
          className="uppercase"
          inPanelView
          isPrimary
          summaryView
          title={section.title}
        />
        <InputGroupList panelMode>
          {groupsToDraw.map(section => drawFilterGroup(section))}
        </InputGroupList>
      </React.Fragment>
    );
  }

  function drawFilterGroup(group: AgentFilterGroupConfig) {
    return (
      <TitleSection
        key={`resource-policy-agent-summary-section-${group.title}`}
        id={`resource-policy-agent-summary-section-${group.title}`}
        title={group.title}
        collapsible
        summaryView
      >
        {group.filters
          .map(filter => {
            const path = filter.path.join('.');
            let value = _.get(agentSettings, path);

            if (!value) return null; // do not show parameters with null or zero
            if (filter.filterConfig.load) {
              if (!_.isNil(value)) {
                value = filter.filterConfig.load(value);
              }
            }

            return (
              <StatListItem
                summaryView
                key={`agent-property-${filter.title}`}
                title={filter.title}
                value={formatToPrecision(value, 2)}
                unitOfMeasure={filter.filterConfig.unitOfMeasure}
                isSelected={props.isSelected}
              />
            );
          })
          .filter(v => !!v)}
      </TitleSection>
    );
  }

  return <>{filterConfiguration.map(section => drawSection(section))}</>;
};

export default AgentSummary;
