import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import { actualityHeatmapTypeSelected } from '../feed/store/actuality.state';
import { getActualityMetricDescriptorsMap } from './actuality/actualityMetric.default';
import { useAssignmentMetricDescriptors } from './assignment/useAssignmentMetricDescriptors';

export function useActualityEffectiveMetricDescriptors() {
  const { t } = useTranslation('app');
  const formatter = useFormatter();
  const heatmapType = useRecoilValue(actualityHeatmapTypeSelected);
  const assignmentMetricDescriptors = useAssignmentMetricDescriptors();

  switch (heatmapType) {
    case 'events':
      return getActualityMetricDescriptorsMap(t, formatter);
    case 'assignment':
      return assignmentMetricDescriptors;
  }
}
