import { signOut } from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loggedInUser } from '../store/auth.state';
import DropdownSelector from './actions/DropdownSelector';

const userOptions = ['logout'] as const;
type UserOptionsTuple = typeof userOptions;
export type UserOption = UserOptionsTuple[number];

export default function AuthHeader() {
  const user = useRecoilValue(loggedInUser);
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  function getTitle(option: UserOption) {
    switch (option) {
      case 'logout':
        return t`Logout`;
    }
    return option;
  }

  async function handleLogout() {
    try {
      await signOut();
      navigate('/login');
      console.log('User logged out successfully');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  return (
    <DropdownSelector
      onClick={e => {
        e.stopPropagation();
      }}
      DropAlignRight
      buttonTransparent
      value={user.fullName}
      values={[...userOptions]}
      onChange={async (option: UserOption, e) => {
        e.stopPropagation();
        switch (option) {
          case 'logout':
            await handleLogout();
            break;
        }
      }}
      renderValue={getTitle}
    />
  );
}
