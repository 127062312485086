import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue } from 'recoil';
import PortalFeature from '../../features/PortalFeature';
import {
  viewerConnectivityGraph,
  viewerSelectedPlaneId,
  viewerShowPortals,
} from '../store/viewer.state';

const PortalLayer: React.FC = () => {
  const planeId = useRecoilValue(viewerSelectedPlaneId);
  const showPortals = useRecoilValue(viewerShowPortals);
  const cg = useRecoilValue(viewerConnectivityGraph);
  if (!showPortals || !planeId) return null;

  const connectivity = cg.planes.find(a => a.id === planeId);
  return (
    <Layer>
      {[...connectivity.aislePortals, ...connectivity.bayPortals].map(
        (p, index) => (
          <PortalFeature
            portal={p.coords}
            isSelected={false}
            key={`portal-${index}`}
          />
        ),
      )}
    </Layer>
  );
};

export default PortalLayer;
