import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import LocationPropertiesPanel from '../../components/sharedPanels/LocationPropertiesPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { viewerSelectedBayLocation } from '../viewer/store/viewer.state';

const LayoutLocationSidebarCmp: React.FC = () => {
  const { t } = useTranslation('designer');
  const location = useRecoilValue(viewerSelectedBayLocation);

  if (!location) {
    return (
      <InboxZero
        message={t`No Location selected`}
        message_helper={t`Click on bay map to select`}
        hasIcon
        selfCenter
      />
    );
  }

  return <LocationPropertiesPanel location={location} />;
};

export default () => {
  const { t } = useTranslation('designer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-layout-info-location',
    title: t`Location Info`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <LayoutLocationSidebarCmp />
    </SidebarContainer>
  );
};
