import {
  LoadAppBillingPurchasesDocument,
  LoadAppBillingPurchasesQuery,
  LoadAppBillingPurchasesQueryVariables,
  LoadAppBillingSubscriptionsDocument,
  LoadAppBillingSubscriptionsQuery,
  LoadAppBillingSubscriptionsQueryVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { billingClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  appBillingPayments,
  appBillingPaymentsLoadStatus,
} from '../store/appBilling.state';
import { Payments } from '../store/appBilling.types';

function useLoadPayments(): [
  (whId: string) => Promise<void>,
  () => Promise<void>,
] {
  const { t } = useTranslation('app');

  const cleanupPaymentsState = useRecoilCallback(
    ({ snapshot, reset }) =>
      async () => {
        reset(appBillingPayments);
        reset(appBillingPaymentsLoadStatus);
      },
  );

  const beforeLoadPayments = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        set(appBillingPayments, null);
        set(appBillingPaymentsLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (whId: string) => {
        let payments: Payments;

        function handleError(message, details?) {
          console.error(message, details);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can't load payments history`,
            details,
          });
          set(appBillingPaymentsLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        try {
          const subscriptionsResponse = await billingClient.query<
            LoadAppBillingSubscriptionsQuery,
            LoadAppBillingSubscriptionsQueryVariables
          >({
            query: LoadAppBillingSubscriptionsDocument,
            variables: {
              whId: [whId],
            },
          });

          if (subscriptionsResponse.errors) {
            handleError(
              t`Can't load Subscription`,
              subscriptionsResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }

          const purchasesResponse = await billingClient.query<
            LoadAppBillingPurchasesQuery,
            LoadAppBillingPurchasesQueryVariables
          >({
            query: LoadAppBillingPurchasesDocument,
            variables: {
              whId: [whId],
            },
          });

          if (purchasesResponse.errors) {
            handleError(
              t`Can't load purchases`,
              purchasesResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }

          payments = {
            subscriptions:
              subscriptionsResponse?.data?.appBillingSubscriptions?.content,
            purchases: purchasesResponse?.data?.appBillingPurchases?.content,
          };
        } catch (ex) {
          handleError(t`Can't load payments history`, ex.message);
          return;
        }

        set(appBillingPayments, payments);
        set(appBillingPaymentsLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function loadPayments(whId: string) {
    await beforeLoadPayments();
    await loadCallback(whId);
  }

  return [loadPayments, cleanupPaymentsState];
}

export default useLoadPayments;
