import { SpacerTypeOption } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import InboxZero from '../../../../components/InboxZero';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { ConvertedAisleFeature } from '../converter.serializable.model';
import { converterSelectedArea } from '../store/converter.area.state';
import {
  converterLayoutAltSelectedAisleId,
  converterLayoutSelectedAisle,
} from '../store/converter.layout.state';
import { converterAreaBuilder } from '../store/converter.state';

const StepCustomAisles = () => {
  const { t } = useTranslation('designer');
  const setAltSelectedAisle = useSetRecoilState(
    converterLayoutAltSelectedAisleId,
  );
  const setSelectedAisle = useSetRecoilState(converterLayoutSelectedAisle);
  const area = useRecoilValue(converterSelectedArea);
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );

  const spacersMap = _.keyBy(
    _.flatten(_.values(areaBuilder?.spacers)),
    s => s.id,
  );

  const spacerAisles = React.useMemo(() => {
    if (!area) return [];

    return _(area.aisles)
      .filter(
        a => a.isSpacer && spacersMap[a.id].type === SpacerTypeOption.Aisle,
      )
      .value();
  }, [area, spacersMap]);

  function drawRow(aisle: ConvertedAisleFeature) {
    return (
      <TableRow
        rowHover
        rowLineList
        key={aisle.id}
        aria-label={aisle.id}
        onMouseLeave={() => setAltSelectedAisle(null)}
        onMouseEnter={() => setAltSelectedAisle(aisle.id)}
        onClick={() => setSelectedAisle(aisle)}
      >
        <TableTd cellSize="md">{aisle.title}</TableTd>
      </TableRow>
    );
  }
  return (
    <PanelBody>
      <ScreenTitle title={t`Custom Aisles`} subtitle={t`Setup Aisles`} />

      <InputQA>
        <InputQuestion
          icon={Icon.DesignerAislesSpacers}
          question={t`Do you have additional Aisles not mapped?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/03-setup-aisles-custom'} hasPadding />
        </InputAnswerGroup>
      </InputQA>

      <TitleSection titleView title={t`Spacer Aisles`} />

      {area && areaBuilder ? (
        _.isEmpty(spacerAisles) ? (
          <InboxZero
            message={t`No Spacers`}
            message_helper={t`Select a bay to add a spacer aisle or block.`}
          />
        ) : (
          <Table isSticky viewAsLine isHoverable>
            <TableHead>
              <TableRow rowLine>
                <TableTh>{t`Spacer`}</TableTh>
              </TableRow>
            </TableHead>

            <TableBody>
              {_(area.aisles)
                .filter(
                  a =>
                    a.isSpacer &&
                    spacersMap[a.id].type === SpacerTypeOption.Aisle,
                )
                .map(aisle => drawRow(aisle))
                .value()}
            </TableBody>
          </Table>
        )
      ) : (
        <InboxZero
          message={t`No Area Selected`}
          message_helper={t`Click once on an Area to select`}
        />
      )}
    </PanelBody>
  );
};

export default StepCustomAisles;
