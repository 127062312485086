import {
  LayoutImportConverterAreaFragment,
  LayoutImportConverterLocationFragment,
  LayoutImportLocation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AisleBuilderSettings,
  AreaBuilderSettings,
  BayTypeSettings,
  LayoutBuilderSettings,
  LocationPatchHolder,
  LocationTypeSettings,
  ShelfSettings,
  SpacerAreaSettings,
  SpacerTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';

import { persistAtom } from '../../../../common/recoil/persistAtom';
import { AsyncLoadStatus, DatasetTableState } from '../../../../common/types';
import { warehouseSelected } from '../../../../store/warehouse.state';
import {
  ConvertedAreaFeature,
  ConvertedLayoutFeature,
} from '../converter.serializable.model';
import {
  converterAreaConfigurationAtom,
  converterSelectedArea,
} from './converter.area.state';
import {
  DefaultAisleBuilderSetting,
  getLocationDefaultConfig,
} from './converter.defaults';
import { calculateBaySize } from './converter.helper';
import {
  converterLayoutSelectedAisle,
  converterLayoutSelectedBayId,
  converterLayoutSelectedLocationId,
  converterLayoutShowPortals,
} from './converter.layout.state';
import {
  ConverterBuilderState,
  GuidelineMode,
  LayoutImportConverterData,
  LocationMeta,
} from './converter.types';
import { ConverterWizardStepId } from './converter.wizard';
import { updateLinkedAreas } from './converterConnections.helper';

const getKey = (postfix: string) => `warebee-layout-converter-${postfix}`;

export const converterLayoutImportDocumentId = atom<string>({
  key: getKey('document-id'),
  default: null,
});

export const converterPatchedLocations = atom<
  Record<string, LayoutImportConverterLocationFragment>
>({
  key: getKey('locations-input-all'),
  default: null,
});

export const converterLayoutDataInitial = atom<LayoutImportConverterData>({
  key: getKey('layout-data-initial'),
  default: null,
});

const converterLayoutDataInner = atom<LayoutImportConverterData>({
  key: getKey('layout-data-inner'),
  default: null,
});

export const converterLayoutData = selector<LayoutImportConverterData>({
  key: getKey('layout-data'),
  get: ({ get }) => get(converterLayoutDataInner),
  set: ({ set }, value: LayoutImportConverterData) =>
    set(converterLayoutDataInner, value),
});

export const converterAreaData = selectorFamily<
  LayoutImportConverterAreaFragment,
  string
>({
  key: getKey('area-data-by-id'),
  get:
    (areaId: string) =>
    ({ get }) =>
      get(converterLayoutData)?.areas?.find(a => a.area === areaId),
});

export const converterCalculationStatus = atom<AsyncLoadStatus>({
  key: getKey('layout-calculation-status'),
  default: AsyncLoadStatus.None,
});

const converterCalculatedLayoutAtom = atom<ConvertedLayoutFeature>({
  key: getKey('calculated-layout-atom'),
  default: null,
});

export const converterCalculatedLayout = selector<ConvertedLayoutFeature>({
  key: getKey('calculated-layout'),
  get: ({ get }) => get(converterCalculatedLayoutAtom),
  set: ({ get, set }, layout: ConvertedLayoutFeature) => {
    const config = get(converterAreaConfigurationAtom);
    const withLinked = updateLinkedAreas(config, layout?.areas);
    set(converterCalculatedLayoutAtom, layout);
    set(converterAreaConfigurationAtom, withLinked);
  },
});

export const converterLocationMap = selector<Map<string, LocationMeta>>({
  key: getKey('locations-map'),
  get: ({ get }) => {
    console.time('converter-locations-map');
    const layout = get(converterCalculatedLayout);
    const locationsMap: Map<string, LocationMeta> = new Map();
    _.forEach(layout?.areas, area => {
      const areaId = area.id;
      _.forEach(area.bays, bay => {
        const bayId = bay.id;
        _.forEach(bay.locations, l => {
          locationsMap.set(l.locationId, {
            areaId,
            bayId,
            locationId: l.locationId,
            level: l.locationLevel,
          });
        });
      });
    });
    console.timeEnd('converter-locations-map');
    return locationsMap;
  },
});

export const converterAreaLayout = selectorFamily<ConvertedAreaFeature, string>(
  {
    key: getKey('area-layout-by-id'),
    get:
      (areaId: string) =>
      ({ get }) =>
        _.find(get(converterCalculatedLayout)?.areas, a => a.id === areaId),
  },
);

export const converterLocationPatches = atom<
  Record<string, LocationPatchHolder>
>({
  key: getKey('location-patches'),
  default: {},
});

export const converterLocationPatchSetter = selector<LocationPatchHolder>({
  key: getKey('location-patch-setter'),
  get: () => {
    throw new Error('Invalid operation');
  },
  set: ({ get, set }, value: LocationPatchHolder) => {
    const wh = get(warehouseSelected);
    //save path to collection for storage/save settings purpose
    const allPatches = get(converterLocationPatches);
    const bayTypes = get(converterBayTypesBuilder);
    const newPatches = {
      ...allPatches,
      [value.patch.locationId]: {
        ...value,
        patch: {
          ...(allPatches[value.patch.locationId]?.patch ?? {}),
          ...(value?.patch ?? {}),
        },
      },
    };
    set(converterLocationPatches, newPatches);

    // apply changes in patch  to reload specific area
    const data = get(converterLayoutData);
    if (!data) return null;
    //value.areaId
    const area = _.find(data.areas, a => a.area === value.areaId);
    const modifiedArea = {
      ...area,
      aisles: area.aisles.map(a => ({
        ...a,
        sides: a.sides.map(s => ({
          ...s,
          bays: s.bays.map(b => {
            if (b.bayId !== value.bayId) return b;

            return calculateBaySize({
              bay: b,
              locationPatch: _.values(newPatches).filter(
                lp => lp.bayId === b.bayId,
              ),
              locationTypeMap: get(converterLocationTypesBuilder),
              bayModel: bayTypes[b.bayType ?? DEFAULT_BAY_TYPE],
              locationTypeDefault: getLocationDefaultConfig(
                wh.measurementSystem,
              ),
            });
          }),
        })),
      })),
    };

    set(converterLayoutData, {
      ...data,
      areas: [
        ..._.filter(data.areas, a => a.area !== value.areaId),
        modifiedArea,
      ],
    });
  },
});

export const converterAllAreasBuilder = atom<
  Record<string, AreaBuilderSettings>
>({
  key: getKey('all-areas-builder'),
  default: null,
});

export const converterAreaBuilder = selectorFamily<AreaBuilderSettings, string>(
  {
    key: getKey('area-builder'),
    get:
      (areaId: string) =>
      ({ get }) => {
        if (!areaId) return null;
        return get(converterAllAreasBuilder)?.[areaId];
      },
    set:
      (areaId: string) =>
      ({ get, set }, value) => {
        if (value) {
          const allAreas = get(converterAllAreasBuilder);
          set(converterAllAreasBuilder, { ...allAreas, [areaId]: value });
        }
      },
  },
);

export const converterAisleBuilder = selectorFamily<
  AisleBuilderSettings,
  [string, string]
>({
  key: getKey('aisle-builder'),
  get:
    ([areaId, aisleId]) =>
    ({ get }) => {
      if (!areaId || !aisleId) return null;
      return (
        get(converterAreaBuilder(areaId))?.aisleSettings?.[aisleId] || {
          aisleId: aisleId,
          ...DefaultAisleBuilderSetting,
        }
      );
    },
  set:
    ([areaId, aisleId]) =>
    ({ get, set }, value: AisleBuilderSettings) => {
      const areaBuilder = get(converterAreaBuilder(areaId));
      if (!areaBuilder) return;
      set(converterAreaBuilder(areaId), {
        ...areaBuilder,
        aisleSettings: {
          ...areaBuilder.aisleSettings,
          [aisleId]: value,
        },
      });
    },
});

export const converterLayoutBuilder = atom<LayoutBuilderSettings>({
  key: getKey('layout-builder'),
  default: null,
});

export const converterBayTypesBuilder = atom<Record<string, BayTypeSettings>>({
  key: getKey('bays-type-builder'),
  default: null,
});

export const converterShelvesSettingsBuilder = atom<
  Record<string, Record<number, ShelfSettings>>
>({
  key: getKey('shelves-settings-builder'),
  default: null,
});

export const converterLocationTypesBuilder = atom<
  Record<string, LocationTypeSettings>
>({
  key: getKey('locations-type-builder'),
  default: {},
});

export const converterSpacerAreaSettings = atom<
  Record<string, SpacerAreaSettings>
>({
  key: getKey('spacer-area-settings'),
  default: null,
});

export const converterExtraLocations = atom<
  Record<string, LayoutImportLocation>
>({
  key: getKey('extra-locations'),
  default: null,
});

export const converterWizardStepByLayoutId = persistAtom<
  Record<string, ConverterWizardStepId>
>({
  key: getKey('selected-step-by-layout-import'),
  default: {},
});

export const converterWizardStepId = selector<ConverterWizardStepId>({
  key: getKey('wizard-step-id'),
  get: ({ get }) => {
    const layoutImportId = get(converterLayoutImportDocumentId);
    return (
      get(converterWizardStepByLayoutId)?.[layoutImportId] ?? 'getting-started'
    );
  },
  set: ({ get, set }, value) => {
    const layoutImportId = get(converterLayoutImportDocumentId);
    if (!layoutImportId) return;
    set(converterLayoutShowPortals, value === 'define-starting-points');
    set(converterWizardStepByLayoutId, {
      ...get(converterWizardStepByLayoutId),
      [layoutImportId]: value,
    });
  },
});

export const converterBuilderState = atom<ConverterBuilderState>({
  key: getKey('builder-state'),
  default: null,
});

const converterLayoutIdSavingAtom = atom<string>({
  key: getKey('layout-id-saving-atom'),
  default: null,
});

export const converterLayoutIdSaving = selector<string>({
  key: getKey('layout-id-saving'),
  get: ({ get }) => get(converterLayoutIdSavingAtom),

  set: ({ get, set }, value: string) => {
    // clear selections
    set(converterSelectedArea, null);
    set(converterLayoutSelectedAisle, null);
    set(converterLayoutSelectedBayId, null);
    set(converterLayoutSelectedLocationId, null);

    set(converterLayoutIdSavingAtom, value);
  },
});

export const converterNewSpacerType = atom<SpacerTypeOption>({
  key: getKey('new-spacer-type'),
  default: SpacerTypeOption.Block,
});

export const converterGuidelineMode = atom<GuidelineMode>({
  key: getKey('guideline-mode'),
  default: 'box',
});

export const converterLocationsTableState = atom<
  DatasetTableState<keyof LayoutImportConverterLocationFragment>
>({
  key: getKey('locations-table-state'),
  default: {
    searchValues: {},
    sortValues: {},
  },
});
