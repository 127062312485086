import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import InboxZero from '../../../../components/InboxZero';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import ConverterSpacerAreaInfoPanel from '../panels/ConverterSpacerAreaInfoPanel';
import { converterSelectedArea } from '../store/converter.area.state';
import {
  converterAreaBuilder,
  converterSpacerAreaSettings,
} from '../store/converter.state';

const StepAisleSetup: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const area = useRecoilValue(converterSelectedArea);
  const [areasBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );
  const spacerAreaSettings = useRecoilValue(converterSpacerAreaSettings);
  const wh = useRecoilValue(warehouseSelected);

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Aisles, Locations and Bays`}
        title={t`Manage Aisles`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.AisleWidth}
          question={t`What is the Aisle Width in the Area?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/03-setup-aisles'} hasPadding />
        </InputAnswerGroup>
        {area && spacerAreaSettings[area.id] && (
          <ConverterSpacerAreaInfoPanel hideHeader={true} />
        )}
        {area && areasBuilder && (
          <>
            <TitleSection titleView title={t`Area's Aisle width`} />
            <InputGroupList hasPadding hasSpacing>
              <InputNumber
                key={`aisle-width-${area.id}`}
                title={t`Area Aisle Width`}
                range={[1, null]}
                value={areasBuilder.aisleWidth}
                unitOfMeasure={sizeMeasureValue}
                onChange={v => {
                  setAreaBuilder({
                    ...areasBuilder,
                    aisleWidth: v,
                  });
                }}
              />
            </InputGroupList>
            <TitleSection titleView title={t`Maximize Aisle's length`} />
            <InputGroupList hasPadding hasSpacing>
              <InputCheckbox
                key={`area-aisles-maximize-length-${area.id}`}
                isSelected={areasBuilder.maximizeAisleLength}
                onChange={v => {
                  setAreaBuilder({
                    ...areasBuilder,
                    maximizeAisleLength: v,
                  });
                }}
              >
                {t`Aisles have same length`}
              </InputCheckbox>
            </InputGroupList>

            <TitleSection titleView title={t`Dynamic aisles`} />
            <InputGroupList hasPadding hasSpacing>
              <InputCheckbox
                key={`area-aisles-use-auto-spacers-${area.id}`}
                isSelected={areasBuilder.useAutoSpacers}
                onChange={v => {
                  setAreaBuilder({
                    ...areasBuilder,
                    useAutoSpacers: v,
                  });
                }}
              >
                {t`Auto create aisles before/after bay`}
              </InputCheckbox>
            </InputGroupList>
          </>
        )}
        {!area && (
          <InboxZero
            className={`mt-2`}
            message={t`No Area Selected`}
            message_helper={t`Click once on an Area to select`}
          />
        )}
      </InputQA>
    </PanelBody>
  );
};

export default StepAisleSetup;
