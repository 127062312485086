import * as React from 'react';
const ScreenViewDualIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ScreenViewDualIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4,4v120h120V4H4ZM18,110V18h44v92H18ZM110,110h-44V18h44v92Z"
    />
  </svg>
);
export default ScreenViewDualIcon;
