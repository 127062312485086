import * as React from 'react';
const FlipHorizontalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FlipHorizontalIcon"
    {...props}
  >
    <path strokeWidth={0} d="M95.03,4H10.97l42.03,55.3L95.03,4Z" />
    <path strokeWidth={0} d="M10.97,124h84.06l-42.03-55.3L10.97,124Z" />
    <path
      strokeWidth={0}
      d="M67,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M57,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M87,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M47,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M77,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M37,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M97,66h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2c-1.11,0-2,.89-2,2s.89,2,2,2Z"
    />
    <path
      strokeWidth={0}
      d="M9,62h-2c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2Z"
    />
    <path
      strokeWidth={0}
      d="M17,62c-1.11,0-2,.89-2,2s.89,2,2,2h2c1.11,0,2-.89,2-2s-.89-2-2-2h-2Z"
    />
    <path
      strokeWidth={0}
      d="M108.97,102.07l-9.63-2.58c13.09-6.84,21.66-20.62,21.66-35.49,0-16.2-10.15-31.13-25.26-37.17-1.03-.41-2.19.09-2.6,1.12s.09,2.19,1.12,2.6c13.6,5.43,22.74,18.88,22.74,33.46s-8.45,26.94-21.09,32.73l3.16-11.8c.29-1.07-.35-2.16-1.41-2.45-1.07-.28-2.16.35-2.45,1.41l-4.14,15.46c-.03.12-.01.25-.02.37-.02.1-.02.21-.02.31,0,.07.01.14.03.21.02.14,0,.28.06.41.29.83,1.06,1.35,1.89,1.35.05,0,.11-.02.16-.03l14.78,3.96c.17.05.35.07.52.07.88,0,1.69-.59,1.93-1.48.29-1.07-.35-2.16-1.41-2.45Z"
    />
    <path
      strokeWidth={0}
      d="M29.32,62.07c-.11-.02-.21-.07-.32-.07h-2c-1.11,0-2,.89-2,2s.89,2,2,2h2c.1,0,.18-.04.27-.05.09.01.18.05.27.05.52,0,1.04-.22,1.42-.58.36-.38.58-.9.58-1.42s-.22-1.04-.58-1.42c-.42-.42-1.04-.58-1.64-.51Z"
    />
  </svg>
);
export default FlipHorizontalIcon;
