import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
} from 'recoil';
import { getIndexedTitle } from '../../../../common/utils';
import { Button } from '../../../../components/actions/Button';
import * as Icon from '../../../../components/icons';
import RuleContainer from '../../../../components/policies/RuleContainer';
import { resourcePolicyAgentById } from '../../../store/resourcePolicy.state';
import { simulationAnalyzeInterval } from '../../../store/simulation.state';
import { getAgentSimulationDefaults } from '../../../store/workforce.helper';
import { workforceAllAgentSettingsMap } from '../../../store/workforce.state';
import WorkforceScheduleSettings from './WorkforceScheduleSettings';

export type WorkforceAgentScheduleWellProps = {
  agentId: string;
};

const WorkforceForecastSchedule: React.FC<
  WorkforceAgentScheduleWellProps
> = props => {
  const { t } = useTranslation('simulation');
  const agent = useRecoilValue(resourcePolicyAgentById(props.agentId));
  const analyzePeriod = useRecoilValue(simulationAnalyzeInterval);
  const [scheduleMap, setScheduleMap] = useRecoilState(
    workforceAllAgentSettingsMap,
  );

  const appendDefaultSettings = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      () => {
        const defaultAgentSettings = getAgentSimulationDefaults({
          agent,
          analyzePeriod,
          title: t`Default Schedule`,
          sortIndex: Number.MAX_SAFE_INTEGER,
          isDefault: true,
        });

        set(workforceAllAgentSettingsMap, {
          ...get(workforceAllAgentSettingsMap),
          [defaultAgentSettings.id]: defaultAgentSettings,
        });
      },
  );

  const agentSchedules = _(scheduleMap)
    .filter(s => s.agentId === props.agentId)
    .sortBy(s => s.sortIndex)
    .value();

  useEffect(() => {
    if (!_.isNil(agent) && _.isEmpty(agentSchedules)) {
      appendDefaultSettings();
    }
  }, [agent]);

  function addSimulationsSettings() {
    const titles = _.values(agentSchedules).map(r => r.title);
    const maxSort =
      _.maxBy(
        _.values(agentSchedules).filter(
          r => r.sortIndex < Number.MAX_SAFE_INTEGER,
        ),
        r => r.sortIndex,
      )?.sortIndex ?? 0;

    const defaultSchedule = _.find(agentSchedules, s => s.isDefault);

    const newSettings = getAgentSimulationDefaults({
      agent,
      analyzePeriod,
      title: getIndexedTitle(new Set(titles), t`Custom Schedule #`),
      sortIndex: Math.max(maxSort, 0) + 1,
    });

    newSettings.shiftSettings = _(defaultSchedule.shiftSettings)
      .values()
      .map(s => ({
        ...s,
        id: nanoid(),
      }))
      .keyBy('id')
      .value();

    setScheduleMap({
      ...scheduleMap,
      [newSettings.id]: newSettings,
    });
  }

  function removeSchedule(id: string) {
    setScheduleMap(_.omit(scheduleMap, id));
  }

  function renameSchedule(id: string, title: string) {
    setScheduleMap({
      ...scheduleMap,
      [id]: { ...scheduleMap[id], title },
    });
  }

  type SchedulerActionsType = 'remove' | 'addShift' | 'removeAllShifts';

  type SchedulerActionOption = {
    id: SchedulerActionsType;
    title: string;
    onClick: (scheduleId: string) => void;
  };

  const schedulerOptions: SchedulerActionOption[] = [
    {
      id: 'remove',
      title: t`Remove Schedule`,
      onClick: removeSchedule,
    },
    {
      id: 'addShift',
      title: t`Add Shift`,
      onClick: _.noop,
    },
    {
      id: 'removeAllShifts',
      title: t`Clear all shifts`,
      onClick: _.noop,
    },
  ];

  const schedulerOptionsMap: Record<
    SchedulerActionsType,
    SchedulerActionOption
  > = _.keyBy(schedulerOptions, o => o.id) as Record<
    SchedulerActionsType,
    SchedulerActionOption
  >;

  return (
    <div data-component="WorkforceForecastSchedule" className="p-2">
      <footer className="flex">
        <Button
          className="mt-4 mb-4 mx-2 w-full flex-1 rounded"
          label={t`Override schedule`}
          buttonType="primary"
          buttonSize="xs"
          isDisabled={false}
          // full
          hasIconAfter
          onPress={addSimulationsSettings}
          buttonIcon={<Icon.Plus className={`w-4 h-4 fill-current`} />}
        />
      </footer>
      {_(agentSchedules)
        .filter(s => s.agentId === props.agentId)
        .sort(s => -s.sortIndex)
        .map(s => {
          // const startTime = formatDateTime(s.period.absolute.start);
          // const endTime = formatDateTime(s.period.absolute.end);
          // const timePeriod = formatTimespan(startTime, endTime);

          return (
            <RuleContainer
              key={`agent-simulation-schedule-'${s.id}`}
              id={`agent-simulation-schedule-'${s.id}`}
              title={s.title}
              canRename
              isCollapsible
              isRemovable={!s.isDefault}
              hasIcon
              hasColorMode={false}
              onDeleteClick={() => removeSchedule(s.id)}
              onTitleChange={v => renameSchedule(s.id, v)}
              classNameInner={classNames(
                'bg-app-panel-dark/75',
                // isActive ? 'bg-menu-active' : 'bg-sidebar-header/70',
                'p-1 xl:p-2',
                'space-y-2',
              )}
            >
              <WorkforceScheduleSettings
                scheduleId={s.id}
                agentId={props.agentId}
              />
            </RuleContainer>
          );
        })
        .value()}
    </div>
  );
};

export default WorkforceForecastSchedule;

// <TitleSection
//                   key={s.id}
//                   inPanelView
//                   className={`pb-1 ltr:pl-4 rtl:pr-4`}
//                   classNameInner={classNames('flex-1 bg-app-panel-dark')}
//                   title={
//                     <div className="flex items-center">
//                       {/* <Spacer flexspace /> */}
//                       <ContainerTag
//                         className={`ltr:ml-2 rtl:mr-2 items-center`}
//                         // style={{
//                         //   backgroundColor: s.color,
//                         // }}
//                       >
//                         <Icon.CalendarTime2
//                           className={`fill-current w-4 h-4 ltr:mr-0.5 rtl:ml-0.5`}
//                         />
//                         <span className="ltr:ml-1 rtl:mr-1">
//                           {format(s.period.absolute.start, 'yyyy/MM/dd')}
//                         </span>
//                         <span className="mx-0.5">—</span>
//                         <span className="ltr:ml-1 rtl:mr-1">
//                           {format(s.period.absolute.end, 'yyyy/MM/dd')}
//                         </span>
//                       </ContainerTag>
//                     </div>
//                   }
//                   id={`agent-simulation-setting-${s.id}`}
//                   collapsible
//                   hasAction={
//                     <DropdownSelector
//                       onClick={e => {
//                         e.stopPropagation();
//                       }}
//                       DropAlignRight
//                       buttonTransparent
//                       vertical
//                       value={'...'}
//                       values={_.map(schedulerOptions, o => o.id)}
//                       renderValue={v => schedulerOptionsMap[v]?.title ?? v}
//                       onChange={v => schedulerOptionsMap[v]?.onClick(s.id)}
//                     />
//                   }
//                 ></TitleSection>
