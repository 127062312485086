import {
  useBeginFileMultipartUploadMutation,
  useCompleteFileMultipartUploadMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { getChunkKey, uploadFile } from '../../import/store/import.helper';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  warehouseFilesSeed,
  warehouseFilesUploadChunkStatus,
  warehouseFilesUploadStatus,
} from '../store/warehouseFiles.state';

const chunkSize = 1024 * 1024 * 5; //5 MB

export type UploadWarehouseFileParams = {
  file: File;
};

function useUploadWarehouseFile() {
  const [startMultipartUpload] = useBeginFileMultipartUploadMutation();
  const [completeUpload] = useCompleteFileMultipartUploadMutation();

  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load upload file`;

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: UploadWarehouseFileParams) => {
        set(warehouseFilesUploadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: UploadWarehouseFileParams) => {
        const { file } = params;
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const filename = file.name;
        const id = {
          warehouseId,
          name: filename,
        };
        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(warehouseFilesUploadStatus, AsyncLoadStatus.Error);
        }

        if (_.isNil(file)) {
          handleError(t`File is not selected`);
          return;
        }

        const chunkCount = Math.ceil(file.size / chunkSize);

        try {
          const { data: startUploadData, errors: startUploadErrors } =
            await startMultipartUpload({
              variables: {
                input: {
                  id: {
                    warehouseId: warehouseId,
                    name: filename,
                  },
                  parts: chunkCount,
                },
              },
            });

          if (!_.isEmpty(startUploadErrors)) {
            const details = _(startUploadErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }

          const { uploadId, uploadLinks } =
            startUploadData.beginFileMultipartUpload;
          const etags: string[] = [];
          await Promise.all(
            _.range(chunkCount).map(i => {
              return new Promise<void>((resolve, reject) => {
                const chunkId = getChunkKey(uploadId, i);

                uploadFile({
                  blob: file.slice(i * chunkSize, (i + 1) * chunkSize),
                  url: uploadLinks[i],

                  onComplete: (e, etag) => {
                    etags[i] = etag;
                    resolve();
                  },

                  onError: e => {
                    handleError(t`File part upload failed`);
                  },

                  onProgress: e => {
                    set(warehouseFilesUploadChunkStatus(chunkId), {
                      id: chunkId,
                      jobId: uploadId,
                      status: AsyncLoadStatus.Loading,
                      loaded: e.loaded,
                      total: e.total,
                    });
                  },
                });
              });
            }),
          );

          const { data: completeJobData, errors: completeJobErrors } =
            await completeUpload({
              variables: {
                input: {
                  id,
                  uploadId,
                  etags,
                },
              },
            });

          if (!_.isEmpty(completeJobErrors)) {
            const details = _(completeJobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          set(warehouseFilesUploadStatus, AsyncLoadStatus.Ok);
          set(warehouseFilesSeed, nanoid());
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: UploadWarehouseFileParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    //
  }

  return [call, cancel] as const;
}
export default useUploadWarehouseFile;
