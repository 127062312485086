import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { simulationWaypointHeatmap } from '../../store/simulation.state';
import SimulationCongestionLayerBase from './SimulationCongestionLayerBase';

const SimulationCongestionLayer: React.FC = () => {
  const waypointsLoadable = useRecoilValueLoadable(simulationWaypointHeatmap);

  const waypoints =
    waypointsLoadable.state === 'hasValue' ? waypointsLoadable.contents : null;

  return <SimulationCongestionLayerBase waypoints={waypoints} />;
};

export default SimulationCongestionLayer;
