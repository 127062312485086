import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PolicyWarnings from '../components/policies/PolicyWarnings';

const RoutingPolicyWarnings: React.FC = () => {
  const { t } = useTranslation('simulation');

  const getMessages = () => {
    const messages = [];
    //     if (!pickingPolicyStatus.hasOrderLineRules) {
    //       messages.push(t`Should have at least one policy`);
    //     }
    //     if (!pickingPolicyStatus.hasPickingRules) {
    //       messages.push(t`Policy should have picking rules`);
    //     }
    //     if (!pickingPolicyStatus.isOrderLineTableReady) {
    //       messages.push(t`Please ensure the policy is fully loaded.`);
    //     }
    //     if (!pickingPolicyStatus.isPickingAgentsSelected) {
    //       messages.push(t`Agent must be selected in a picking rule`);
    //     }
    //     if (!pickingPolicyStatus.isUOMsSelected) {
    //       messages.push(t`UOM type must be selected in a picking rule`);
    //     }
    return messages;
  };
  const messages = getMessages();

  // return null if no warnings
  if (_.isEmpty(messages)) return null;

  return <PolicyWarnings messages={getMessages()} />;
};

export default RoutingPolicyWarnings;
