import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  appBillingWarehouseLoadStatus,
  appBillingWarehouseStateSummary,
} from '../appBilling/store/appBilling.state';
import { collapsibleState } from '../store/collapsible.state';
import { warehouseIsDemo, warehouseSelectedId } from '../store/warehouse.state';
import BillingTag, { BillingTagDemo } from './billing/BillingTag';
import LinkTraced from './nav/LinkTraced';

export type MenuSubscriptionProps = PropsWithChildren & {
  className?: string;
};

const MenuSubscription: React.FC<MenuSubscriptionProps> = ({
  className,
  children,
}) => {
  const { t } = useTranslation('app');
  const setSubscriptionPanelState = useSetRecoilState(
    collapsibleState('subscription-panel'),
  );
  const whId = useRecoilValue(warehouseSelectedId);
  const billingState = useRecoilValue(appBillingWarehouseStateSummary);
  const billingStateLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const navigate = useNavigate();
  const showSubscriptionInfo = () => {
    setSubscriptionPanelState({
      isCollapsed: false,
    });
    navigate('/');
  };
  const isDemo = useRecoilValue(warehouseIsDemo);

  const redirectToPricing =
    billingState.status === 'unpaid' || billingState.status === 'hasTrial';

  const pricingUrl = `/wh/i/${whId}/pricing/`;
  const settingsUrl = `/wh/i/${whId}/settings/billing`;
  return (
    <div
      data-component="MenuSubscription"
      aria-label={t`Menu Subscription`}
      className={classNames(
        'ltr:mr-2 rtl:ml-2 text-xs font-bold cursor-pointer',
        className,
      )}
    >
      <span className="flex flex-1">
        {isDemo ? (
          <LinkTraced
            to="/wh/new"
            // exactActive
            data-component={`MenuItemWarehouseAdd`}
            data-label={`menu-warehouse-item-add`}
            aria-label={`menu-warehouse-item-add`}
            titleTrace={`Warehouse [Demo]: Add Warehouse`}
          >
            <BillingTagDemo />
          </LinkTraced>
        ) : (
          <LinkTraced
            to={redirectToPricing ? pricingUrl : settingsUrl}
            data-label={`BillingState-setting-button-${billingState}`}
            aria-label={`BillingState-setting-button-${billingState}`}
            onClick={showSubscriptionInfo}
            titleTrace={`Billing: Show Billing State`}
            className="flex space-x-2"
          >
            <BillingTag
              billingState={billingState}
              billingStateLoadStatus={billingStateLoadStatus}
            />
          </LinkTraced>
        )}
      </span>
      {children}
    </div>
  );
};

export default MenuSubscription;
