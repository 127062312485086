import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../../Tw';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { simulationIssuesSelectedBayLocations } from '../../store/simulation.issues.state';
import { simulationWizardSelectedStepId } from '../../store/simulation.wizard.state';

const locationAlertColor = TwTheme.extend.colors.alerts;

const IssuesBayLocationLayer: React.FC = () => {
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const locationSetLoadable = useRecoilValueLoadable(
    simulationIssuesSelectedBayLocations,
  );

  if (
    stepId !== 'data-issues' &&
    stepId !== 'import-items' &&
    stepId !== 'import-assignments' &&
    stepId !== 'import-orders'
  )
    return null;

  const locationSet =
    locationSetLoadable.state === 'hasValue'
      ? locationSetLoadable.contents
      : new Set<string>();
  function getColor(loc): [string, string] {
    return locationSet.has(loc.locationId)
      ? [locationAlertColor.alert, null]
      : null;
  }

  return <BayLocationLayer stageId="simulation-bay-view" getColor={getColor} />;
};

export default IssuesBayLocationLayer;
