import * as React from 'react';
const TriangleInfoIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TriangleInfoIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,12.04L4,115.96h120L64,12.04ZM64,44.04c4.2,0,7.51,3.58,7.2,7.74l-3,31.17c-.19,2.22-2.02,3.89-4.2,3.89s-4.01-1.67-4.2-3.89l-3-31.17c-.31-4.16,3-7.74,7.2-7.74ZM64,106.3c-4.28,0-7.78-3.5-7.78-7.78s3.5-7.78,7.78-7.78c4.28,0,7.78,3.5,7.78,7.78,0,4.28-3.5,7.78-7.78,7.78Z"
    />
  </svg>
);
export default TriangleInfoIcon;
