import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../Tw';
import { normalizeColorKey } from '../store/namedColors.helper';
import { namedColorsGroupSelected } from '../store/namedColors.state';
import { getBestContrastColor, getQualitativeColor } from './color.helper';

const colors = TwTheme.extend.colors;

function useGetNamedColor(): (v: string) => [string, string] {
  const group = useRecoilValue(namedColorsGroupSelected);

  return (value: string): [string, string] => {
    const key = normalizeColorKey(value);
    if (_.isNil(group)) return getQualitativeColor(key, 'policy');
    return (
      group.colors[key] ?? [
        colors.location?.DEFAULT,
        getBestContrastColor(colors.location?.DEFAULT),
      ]
    );
  };
}

export default useGetNamedColor;
