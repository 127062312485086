import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const StepCountSize = {
  xs: 'w-6 h-6 text-minimal',
  base: 'w-6 h-6 xl:w-8 xl:h-8 text-xs m-0.5 xl:m-1',
  sm: 'w-7 h-7 text-xs ltr:mr-1 rtl:ml-1 ltr:lg:mr-3 rtl:lg:ml-3 mb-6 mt-1',
  md: 'w-6 h-6 xl:w-8 xl:h-8 text-base',
  lg: 'w-10 h-10 m-2 text-lg',
  // policy: 'w-12 h-8 text-xl',
  policy: 'w-6 xl:w-10 h-6 text-sm xl:text-base',
  title: 'w-6 h-6 xl:w-7 xl:h-7 text-sm xl:text-base',
};

export type StepCounterProps = {
  children?: React.ReactNode;
  className?: string;
  stepCountSize: keyof typeof StepCountSize | string;
  stepCountId: string;
  isPrimary?: boolean;
  isActive?: boolean;
  hasIssue?: boolean;
};

export const StepCounterCircle = ({
  children,
  className,
  stepCountId,
  stepCountSize,
  isPrimary,
  isActive,
  hasIssue,
}: StepCounterProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <div
      data-component="StepCounterCircle"
      id={id}
      key={stepCountId}
      className={classNames(
        'float-left before:relative rounded-full',
        'flex items-center justify-center m-px p-px',
        isPrimary
          ? isActive
            ? 'bg-app-panel-dark text-menu-active'
            : hasIssue
              ? 'bg-alerts-alert text-white'
              : 'bg-menu-active text-menu-active-text'
          : 'border text-menu-active bg-app-panel-dark/60 border-menu-active',
        stepCountSize === 'base' && StepCountSize.base,
        stepCountSize === 'xs' && StepCountSize.xs,
        stepCountSize === 'sm' && StepCountSize.sm,
        stepCountSize === 'md' && StepCountSize.md,
        stepCountSize === 'lg' && StepCountSize.lg,
        stepCountSize === 'policy' && StepCountSize.policy,
        stepCountSize === 'title' && StepCountSize.title,
        className,
      )}
    >
      <span className="flex-1 font-bold text-center">{stepCountId}</span>
      {children}
    </div>
  );
};
