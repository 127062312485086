import Konva from 'konva';
import React from 'react';
import { Rect } from 'react-konva';
import { ticksInPixel } from '../viewer/layers/JobsTimelineLayer';
import { jobFeatureHeight } from './FeedJobFeature';

const colorEven = 'rgba(60,69,75,0.3)';
const colorOdd = 'rgba(60,69,75,0.2)';

export type TimelineRowFeatureProps = {
  from: Date;
  to: Date;
  index: number;
  title?: string;
};

const TimelineRowFeature: React.FC<TimelineRowFeatureProps> = props => {
  const start = props.from.getTime() / ticksInPixel;
  const end = props.to.getTime() / ticksInPixel;
  const width = end - start;
  const fill = props.index % 2 === 0 ? colorOdd : colorEven;

  const timelineRowConfig: Konva.RectConfig = {
    id: 'timeline-row-${props.index}',
    x: start,
    y: props.index * jobFeatureHeight,
    width: width,
    height: jobFeatureHeight,
    fill: fill,
    fillEnabled: true,
  };

  return <Rect {...timelineRowConfig} />;
};

export default TimelineRowFeature;
