import { ReassignTaskAction } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../../Tw';
import { getBestContrastColor } from '../../../common/color.helper';
import { formatInteger } from '../../../common/formatHelper';
import { getHeatmapPowRangeBucketByValue } from '../../../common/heatmap.helper';
import InboxZero from '../../../components/InboxZero';
import JobsDetailsUOM from '../../../components/jobs/JobsDetailsUOM';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import { AnalyzeProductDataRow } from '../../../metrics/analyzeProduct/analyzeProductMetric.types';
import {
  getReorderingHeatmapBuckets,
  getVisitsHeatmapBuckets,
} from '../../store/analyze.helper';
import {
  simulationHeatmapLocationByBay,
  simulationHeatmapLocationByLevel,
} from '../../store/simulation.heatmap.state';
import {
  simulationMaxAppliedReorders,
  simulationMaxLocationVisits,
  simulationMaxTriggeredReorders,
} from '../../store/simulation.state';

const locationColors = TwTheme.extend.colors.location;

export type LocationPropertiesAnalyzeProductProps = {
  locationId: string;
  analyzeId: string;
  title: string;
};

const LocationPropertiesAnalyzeProduct: React.FC<
  LocationPropertiesAnalyzeProductProps
> = props => {
  const { t } = useTranslation('simulation');

  const levelLocations = useRecoilValue(
    simulationHeatmapLocationByLevel(props.analyzeId),
  );
  const bayLocations = useRecoilValue(
    simulationHeatmapLocationByBay(props.analyzeId),
  );
  const maxVisits = useRecoilValue(simulationMaxLocationVisits);
  const maxAppliedReorders = useRecoilValue(simulationMaxAppliedReorders);
  const maxTriggeredReorders = useRecoilValue(simulationMaxTriggeredReorders);

  const visitsBuckets = getVisitsHeatmapBuckets(maxVisits);
  const appliedReordersBuckets =
    getReorderingHeatmapBuckets(maxAppliedReorders);
  const triggeredReordersBuckets =
    getReorderingHeatmapBuckets(maxTriggeredReorders);

  const products =
    levelLocations?.[props.locationId] ?? bayLocations?.[props?.locationId];

  function getItems(products: AnalyzeProductDataRow[]) {
    const currentProducts = _.filter(products, p =>
      _.every(p.reassignTasks, t => t.action !== ReassignTaskAction.TAKE),
    );

    if (_.isEmpty(currentProducts)) {
      return (
        <InboxZero message={t`Empty location`} message_helper={t`(No Items)`} />
      );
    }

    return _(currentProducts)
      .sortBy(p => -p.hitCount)
      .map(p => {
        const visitsBucket = getHeatmapPowRangeBucketByValue(
          p.pickCount,
          maxVisits,
          visitsBuckets,
        );
        const pickHeatmapColor = visitsBucket?.color ?? locationColors.empty;
        const pickHeatmapTextColor =
          visitsBucket?.textColor ?? locationColors.textEmpty;

        const textColor = getBestContrastColor(pickHeatmapColor);

        const itemPickCounter = formatInteger(
          (p.pickCount ?? 0) + (p.replenishmentCount ?? 0),
        );
        return (
          <React.Fragment key={`item-${p.sku}`}>
            <StatListItem
              titleSelectable
              title={p.sku}
              labelValueType={'itemId'}
              value={itemPickCounter}
              valueClass={'rounded text-xxs'}
              valueStyle={{
                background: pickHeatmapColor,
                color: textColor,
              }}
              labelValueFilters={{
                locationId: props.locationId,
                consignee: p.consignee,
                sku: p.sku,
              }}
            />

            {!_.isNil(p.replenishment) && p.replenishmentCount > 0 && (
              <JobsDetailsUOM
                title={t`Item Replenishments`}
                taskCount={p.replenishmentCount}
                breakdownByUom={[
                  {
                    uom: p.replenishment.replenishmentUom,
                    uomQuantity: p.replenishment.replenishmentUomQuantity,
                  },
                ]}
              />
            )}
          </React.Fragment>
        );
      })
      .value();
  }

  return (
    <PanelContainer
      id="panel-order-stats-items-analyse"
      title={props.title}
      collapsible
      hasPadding
    >
      <StatListHeader title={t`Item`} value={t`Visits`} />
      {getItems(products)}
    </PanelContainer>
  );
};

export default LocationPropertiesAnalyzeProduct;
