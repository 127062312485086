import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import InboxZero from '../../components/InboxZero';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { Container } from '../../components/layout/ContainerFlex';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { getAnalyzeProductMetricDescriptorsMap } from '../../metrics/analyzeProduct/analyzeProductMetric.default';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { simulationLayoutHeatmapFilters } from '../../simulation/store/simulation.layout.state';
import { analyzeCompareBuckets } from '../analyzeCompare.heatmap.state';

const AnalyzeCompareLegendSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const metric = useRecoilValue(analyzeProductMetricSelected);
  const buckets = useRecoilValue(analyzeCompareBuckets);
  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );

  const [allSelected, setAllSelected] = useState(true);

  const metricDescriptorMap = getAnalyzeProductMetricDescriptorsMap(
    t,
    formatter,
  );
  const metricDescriptor = metricDescriptorMap[metric];
  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        console.log(evt);
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [heatmapFilter, buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: value,
      },
    });
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  // Add a useEffect hook to track if even one bucket is deselected.
  useEffect(() => {
    // Check if any bucket's hidden status is true (i.e., it's deselected).
    const anyDeselected = Object.values(heatmapFilter.hiddenBuckets).some(
      hidden => hidden,
    );

    if (anyDeselected) {
      // If even one bucket is deselected, set allSelected to false.
      setAllSelected(false);
    } else {
      // If all buckets are selected, set allSelected to true.
      setAllSelected(true);
    }
  }, [heatmapFilter.hiddenBuckets]); // Add heatmapFilter.hiddenBuckets as a dependency.

  const toggleSelectAllBuckets = () => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: allSelected, // If allSelected is true, deselect all. If allSelected is false, select all.
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  return (
    <Container col>
      <StatGroup
        classNameStatGroupItems="!space-y-0.5 !mt-0 !pt-0"
        title={`${metricDescriptor.title} ${
          buckets?.length > 0 ? `(${buckets?.length})` : ''
        }`}
        titleAction={
          <DropdownSelector
            // className="text-xxs"
            classNameLabel="text-sm"
            // classNameItem="text-xs"
            onClick={e => {
              e.stopPropagation();
            }}
            DropAlignRight
            buttonTransparent
            vertical
            panelMode
            value={'...'}
            values={[allSelected ? t`Hide All` : t`Show All`]}
            onChange={async (option, e) => {
              toggleSelectAllBuckets();
            }}
          />
        }
      >
        {_.isEmpty(buckets) ? (
          <InboxZero selfCenter hasIcon>{t`No Items`}</InboxZero>
        ) : (
          _.map(buckets, bucket => {
            // const isHidden = heatmapFilter.hiddenBuckets[bucket.id] === true;
            const isHidden = allSelected
              ? false
              : heatmapFilter.hiddenBuckets[bucket.id] === true;

            const title = _.isFunction(metricDescriptor.format)
              ? `${metricDescriptor.format(
                  bucket.from,
                )} ... ${metricDescriptor.format(bucket.to)}`
              : bucket.title;

            return (
              <Stat
                key={`legend-stat-bucket-${bucket.id}`}
                title={title}
                legendColor={[bucket.color, bucket.textColor]}
                isActionable
                isPreview
                isSelectable
                isSelected={!isHidden}
                onClick={evt => {
                  debouncedClick(bucket.id, !isHidden, evt);
                }}
              />
            );
          })
        )}
      </StatGroup>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'analyze-compare-legend',
    title: t`Legend`,
    loadingMessage: t`Loading Items...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <AnalyzeCompareLegendSidebar />
    </SidebarContainer>
  );
};
