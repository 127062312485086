import * as React from 'react';
const ObjectBayIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectBayIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M117.62,72.48v12.3H8.37v-12.3H3v51.52h5.37v-3.85h109.25v3.85h5.37v-51.52h-5.37ZM117.62,115.33H8.37v-25.74h109.25v25.74Z"
    />
    <path
      strokeWidth={0}
      d="M117.62,35.06v12.3H8.37v-12.3H3v51.52h5.37v-3.85h109.25v3.85h5.37v-51.52h-5.37ZM117.62,77.92H8.37v-25.74h109.25v25.74Z"
    />
    <path
      strokeWidth={0}
      d="M117.62,6.17v3.78H8.37v-3.78H3v43h5.37v-3.85h109.25v3.85h5.37V6.17h-5.37ZM117.62,40.5H8.37V14.76h109.25v25.74Z"
    />
    <rect width={24.62} height={22} x={10} y={17} strokeWidth={0} />
    <rect width={24.62} height={15.45} x={36.86} y={23.55} strokeWidth={0} />
    <rect width={24.62} height={22} x={63.72} y={17} strokeWidth={0} />
    <rect width={24.62} height={11} x={90.57} y={28} strokeWidth={0} />
    <rect width={24.62} height={10.96} x={10} y={65.04} strokeWidth={0} />
    <rect width={24.62} height={15.18} x={36.86} y={60.82} strokeWidth={0} />
    <rect width={24.62} height={12} x={63.72} y={64} strokeWidth={0} />
    <rect width={24.62} height={22} x={90.57} y={54} strokeWidth={0} />
    <rect width={24.62} height={22} x={10} y={91} strokeWidth={0} />
    <rect width={24.62} height={11} x={36.86} y={102} strokeWidth={0} />
    <rect width={24.62} height={17.73} x={63.72} y={95.27} strokeWidth={0} />
    <rect width={24.62} height={22} x={90.57} y={91} strokeWidth={0} />
  </svg>
);
export default ObjectBayIcon;
