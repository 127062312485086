import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../components/alerts/Alerts';

const UploadWarning: React.FC = props => {
  const { t } = useTranslation('app');

  return (
    <div className="mt-4 p-2">
      <Alert
        messageHelper={t`INFO`}
        message={t`Please Keep this window open, while uploading...`}
        hasStatusInfo
      />
    </div>
  );
};
export default UploadWarning;
