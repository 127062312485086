import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { LayoutMenuItem } from './layout.types';

export const layoutMenuItems: (t: TFunction<'app'>) => LayoutMenuItem[] = t => [
  // {
  //   id: 'activity-feed',
  //   title: t('Activity Feed', { ns: 'app' }),
  //   titleMinimized: t('Feed', { ns: 'app' }),
  //   disabled: false,
  //   icon: Icon.Routing,
  //   menuLevel: 1,
  // },
  {
    id: 'layout-menu-start',
    title: t('Layout Info', { ns: 'app' }),
    titleMinimized: t('Layout', { ns: 'app' }),
    disabled: false,
    icon: Icon.LayoutWarehouseTemplate,
    menuLevel: 1,
  },
  {
    id: 'layout-menu-stats',
    title: t('Stats', { ns: 'app' }),
    titleMinimized: t('Stats', { ns: 'app' }),
    disabled: false,
    icon: Icon.DataStatsPie,
    menuLevel: 1,
  },
  {
    id: 'layout-menu-simulation',
    title: t('Simulations', { ns: 'app' }),
    titleMinimized: t('Simulations', { ns: 'app' }),
    disabled: false,
    icon: Icon.SimulationsArrow,
    menuLevel: 1,
  },

  // {
  //   id: 'layout-menu-data-view',
  //   title: t('Data', {ns: 'app'}),
  //   titleMinimized: t('Data', {ns: 'app'}),
  //   disabled: false,
  //   icon: Icon.TableView,
  //   menuLevel: 1,
  // },
];
