import * as React from 'react';
const PalletStackingResortHeatmapIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletStackingResortHeatmapIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,96.27v-46.81s-78.47,0-78.47,0v46.81s78.47,0,78.47,0ZM103.03,75.33v-13.23s8.36,0,8.36,0v13.23s8.21,0,8.21,0l-12.38,14.82-12.38-14.82h8.21Z"
    />
    <path
      strokeWidth={0}
      d="M39.47,96.27v-46.81s-35.47,0-35.47,0v46.81s35.47,0,35.47,0Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M45.53,4.66v38.91s78.47,0,78.47,0V4.66s-78.47,0-78.47,0Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M4.38,4.66v38.91s35.47,0,35.47,0V4.66s-35.47,0-35.47,0Z"
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M124,105.49v-4.32H4v4.32h6.17v14.23h-6.17v4.32h120v-4.32h-6.17v-14.23h6.17ZM19.63,105.49h39.64v14.23H19.63v-14.23ZM68.73,119.71v-14.23h39.64v14.23h-39.64Z"
    />
  </svg>
);
export default PalletStackingResortHeatmapIcon;
