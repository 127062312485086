import React from 'react';
import { useRecoilState } from 'recoil';
import { createFilterTagData, TableFilter } from '../components/TableFilter';
import { orderSetItemsDataState } from './store/orderSet.state';
import useOrderSetDataTableConfig from './useOrderSetDataTableConfig';

const OrderSetFilterTag: React.FC = () => {
  const [dataState, setDataState] = useRecoilState(orderSetItemsDataState);
  const columnsConfig = useOrderSetDataTableConfig();
  return (
    <TableFilter
      filterData={createFilterTagData(dataState.searchValues, columnsConfig)}
      onRemoveClick={() => setDataState({ ...dataState, searchValues: {} })}
    />
  );
};

export default OrderSetFilterTag;
