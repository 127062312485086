import * as React from 'react';
const DesignerAislesMergeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesMergeIcon"
    {...props}
  >
    <rect
      width={15.2}
      height={26.82}
      x={90.28}
      y={63.85}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={90.28}
      y={35.07}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={90.28}
      y={6.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={107.88}
      y={63.85}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={107.88}
      y={35.07}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={107.88}
      y={6.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={22.52}
      y={63.85}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={22.52}
      y={35.07}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={22.52}
      y={6.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={4.93}
      y={63.85}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={4.93}
      y={35.07}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={15.2}
      height={26.82}
      x={4.93}
      y={6.29}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={47.06}
      height={84.38}
      x={40.81}
      y={6.29}
      strokeWidth={0}
      opacity={0.75}
    />
    <g opacity={0.75}>
      <polygon strokeWidth={0} points="64 89.41 64 82.08 58.9 89.41 64 89.41" />
      <polygon
        strokeWidth={0}
        points="64 59.89 64 45.09 42.76 75.61 42.76 89.41 42.76 89.41 64 59.89"
      />
      <polygon
        strokeWidth={0}
        points="64 78.39 64 63.59 46.03 89.41 56.33 89.41 64 78.39"
      />
      <polygon
        strokeWidth={0}
        points="42.76 38.62 42.76 53.41 64 22.9 64 7.67 42.76 38.62"
      />
      <polygon
        strokeWidth={0}
        points="64 41.39 64 26.6 42.76 57.11 42.76 71.91 64 41.39"
      />
      <polygon
        strokeWidth={0}
        points="61.73 7.67 51.43 7.67 42.76 20.12 42.76 34.92 61.73 7.67"
      />
      <polygon
        strokeWidth={0}
        points="42.76 7.67 42.76 16.42 48.85 7.67 42.76 7.67"
      />
    </g>
    <rect width={10} height={31.74} x={59} y={93.96} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="83.33 121.11 70.71 108.46 83.35 95.8 90.83 95.8 80.84 105.8 102.22 105.8 102.21 111.11 80.83 111.11 90.81 121.11 83.33 121.11"
    />
    <polygon
      strokeWidth={0}
      points="44.66 95.8 57.29 108.46 44.64 121.11 37.16 121.11 47.16 111.11 25.78 111.11 25.78 105.81 47.16 105.81 37.18 95.8 44.66 95.8"
    />
  </svg>
);
export default DesignerAislesMergeIcon;
