import {
  Field,
  ID,
  InputAndObjectType,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import GeoJSON from 'geojson';
import { Point, Vector } from './geometry.model';

export interface LayoutObject {
  id: string;
  title?: string;
}

@InputAndObjectType()
export class Aisle implements LayoutObject {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => [Point])
  points: Point[];

  @Field(() => Boolean, { nullable: true })
  nonNavigable?: boolean;

  @Field(() => ID, { nullable: true })
  aisleType?: string;
}

export enum BayLocationOrder {
  LTR = 'LTR',
  RTL = 'RTL',
}

registerEnumType(BayLocationOrder, {
  name: 'BayLocationOrder',
});

@InputAndObjectType()
export class BayLevel {
  @Field()
  level: number;

  @Field()
  levelHeight: number;

  @Field()
  heightFromFloor: number;
}

@InputAndObjectType()
export class Bay implements LayoutObject {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => Point)
  position: Point;

  @Field(() => Vector)
  frontEdge: Vector;

  @Field()
  width: number;

  @Field()
  depth: number;

  @Field(() => BayLocationOrder)
  locationOrder: BayLocationOrder;

  @Field()
  hasPass: boolean;

  @Field({ nullable: true })
  height?: number;

  @Field(() => [BayLevel], { nullable: true })
  levels?: BayLevel[];

  @Field(() => ID, { nullable: true })
  bayType?: string;
}

export interface PlaneMap {
  aisles: Aisle[];
  bays: Bay[];
}

@InputAndObjectType()
export class PlaneAccessSpec {
  @Field(() => ID)
  aisleId: string;

  @Field(() => Point)
  position: Point;
}

@InputAndObjectType()
export class Plane implements LayoutObject, PlaneMap {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => [Aisle])
  aisles: Aisle[];

  @Field(() => [Bay])
  bays: Bay[];

  @Field(() => PlaneAccessSpec, { nullable: true })
  start?: PlaneAccessSpec;

  @Field(() => PlaneAccessSpec, { nullable: true })
  end?: PlaneAccessSpec;
}

export const DEFAULT_LOCATION_RACKING_TYPE = '';

@InputAndObjectType()
export class LocationTypeModel {
  @Field({ nullable: true })
  locationRackingType?: string;

  @Field({ nullable: true })
  gapWidth?: number;

  @Field({ nullable: true })
  gapDepth?: number;

  @Field({ nullable: true })
  gapHeight?: number;

  @Field({ nullable: true })
  isWeakDepth?: boolean;

  @Field({ nullable: true })
  minDepth?: number;
}

export const DEFAULT_BAY_TYPE = '';

@InputAndObjectType()
export class BayTypeModel {
  @Field({ nullable: true })
  bayType?: string;

  @Field({ nullable: true })
  verticalFrameProfile?: number;

  @Field({ nullable: true, defaultValue: 10 })
  shelfHeight?: number;

  @Field({ nullable: true })
  maxWeight?: number;
}

@ObjectType()
export class LayoutSettings {
  @Field(() => [BayTypeModel], { nullable: true })
  bayTypes?: BayTypeModel[];

  @Field(() => [LocationTypeModel], { nullable: true })
  locationTypes?: LocationTypeModel[];
}

@InputAndObjectType()
export class LayoutMap {
  @Field(() => [BayTypeModel], { nullable: true })
  bayTypes?: BayTypeModel[];

  @Field(() => [LocationTypeModel], { nullable: true })
  locationTypes?: LocationTypeModel[];

  @Field(() => [Plane])
  planes: Plane[];
}

@InputAndObjectType()
export class SrcBay extends Bay {
  @Field(() => ID, { nullable: true })
  aisleId?: string;
}

@InputAndObjectType()
export class SrcPlane implements LayoutObject, PlaneMap {
  // OmitType doesn't work for InputAndObjectTypes

  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => [Aisle])
  aisles: Aisle[];

  @Field(() => [SrcBay])
  bays: SrcBay[];

  @Field(() => PlaneAccessSpec, { nullable: true })
  start?: PlaneAccessSpec;

  @Field(() => PlaneAccessSpec, { nullable: true })
  end?: PlaneAccessSpec;
}

@InputAndObjectType()
export class SrcLayoutMap extends LayoutMap {
  @Field(() => [SrcPlane])
  planes: SrcPlane[];
}

export interface EngineLocation {
  locationId: string;
  locationOrder: string; // may exceed js int
  locationStatus: boolean;
  locationLevel: number;
  locationLength: number;
  locationWidth: number;
  locationHeight: number;
  locationWeight: number;
  locationBayId: string;
  locationBayPosition: number;
  locationUsageType: string;
  locmhtype?: string;
  locationRackingType: string;
  warehouseArea: string;
  locationBayProjection: number;
  locationHeightFromFloor: number;
  bayType?: string;
  aisleId: string;
  planeId: string;
  portal?: GeoJSON.Point;
  shape?: GeoJSON.Polygon;
}

export enum LocationPortalType {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

registerEnumType(LocationPortalType, {
  name: 'LocationPortalType',
});

@ObjectType({ isAbstract: true })
export class LocationPortal {
  @Field()
  aisleId: string;

  @Field(() => Point)
  position: Point;

  @Field(() => LocationPortalType, { nullable: true })
  type?: LocationPortalType;
}

@InputAndObjectType()
export class LocationPortalSpec {
  @Field(() => ID)
  aisleId: string;

  @Field(() => LocationPortalType, { nullable: true })
  type?: LocationPortalType;
}

export interface Location {
  locationId: string;
  locationOrder: string; // may exceed js int
  locationStatus: boolean;
  locationLevel: number;
  locationLength: number;
  locationWidth: number;
  locationHeight: number;
  locationWeight: number;
  locationBayId: string;
  locationBayPosition: number;
  locationDepthPosition: number;
  locationUsageType: string;
  locmhtype?: string;
  locationRackingType: string;
  warehouseArea: string;
  locationSide?: string;
  congestionZone?: string;
  locationBayProjection: number;
  locationHeightFromFloor: number;
  locationDepthFromFront: number;
  locationIndexFromFront: number;
  bayType?: string;
  aisleId: string;
  planeId: string;
  shape?: GeoJSON.Polygon;
  portals?: LocationPortal[];
}

export class EngineLocation {
  locationId: string;
  locationOrder: string; // may exceed js int
  locationStatus: boolean;
  locationLevel: number;
  locationLength: number;
  locationWidth: number;
  locationHeight: number;
  locationWeight: number;
  locationBayId: string;
  locationBayPosition: number;
  locationUsageType: string;
  locmhtype?: string;
  locationRackingType: string;
  warehouseArea: string;
  locationBayProjection: number;
  locationHeightFromFloor: number;
  bayType?: string;
  aisleId: string;
  planeId: string;
  portal?: GeoJSON.Point;
  shape?: GeoJSON.Polygon;
}
