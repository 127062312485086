import _ from 'lodash';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import FilterTagWithSearch, {
  FilterTagWithSearchProps,
} from '../../../../components/FilterTagWithSearch';
import {
  converterAreaConfiguration,
  converterSelectedFloorAtom,
} from '../store/converter.area.state';
import { converterLayoutSelectedLocationId } from '../store/converter.layout.state';
import { converterLocationMap } from '../store/converter.state';

const valuesLimit = 10;

const ConverterActiveLocationTag: React.FC = () => {
  const { t } = useTranslation('designer');
  const [selectedId, setSelectedId] = useRecoilState(
    converterLayoutSelectedLocationId,
  );
  const locations = useRecoilValue(converterLocationMap);
  const areaConfiguration = useRecoilValue(converterAreaConfiguration);
  const setFloor = useSetRecoilState(converterSelectedFloorAtom);
  const [search, setSearch] = useState<string>('');

  async function onChange(locationId?: string) {
    if (_.isEmpty(locationId)) {
      setSelectedId(null);
      return;
    }
    const locationMeta = locations.get(locationId);
    const floor = areaConfiguration[locationMeta?.areaId]?.floor;
    setFloor(floor);
    setSelectedId(locationId);
  }

  console.time('Location search');
  const locs = [...(locations?.keys() ?? [])];

  const values = _(locs)
    .filter(
      l => _.isEmpty(search) || l.toLowerCase().includes(search.toLowerCase()),
    )
    .take(valuesLimit)
    .value();
  console.timeEnd('Location search');

  const filterTagProps: FilterTagWithSearchProps<string> = {
    selected: selectedId,
    values,
    isLoading: false,
    onChange: onChange,
    onSearch: async v => setSearch(v),
  };

  return <FilterTagWithSearch {...filterTagProps} />;
};

export default () => (
  <Suspense>
    <ConverterActiveLocationTag />
  </Suspense>
);
