import {
  LayoutFeatureType,
  LocationFilterType,
} from '@warebee/shared/engine-model';
import { LayoutDatabase } from '@warebee/shared/import-converter';
import { sql } from 'kysely';
import { KyselyFilterDescSet } from '../filters/kysely-filter.engine';
import { ATHENA_DIALECT_HELPER } from '../utils/kysely.utils';

export function layoutLocationFilterDescSet<
  DB extends {
    [P in TB]: LayoutDatabase['layoutLocation'];
  } & Pick<LayoutDatabase, 'layoutFeature'>,
  TB extends string = 'layoutLocation',
>(tableKey?: TB): KyselyFilterDescSet<LocationFilterType, DB, TB> {
  tableKey ??= 'layoutLocation' as any;

  return {
    [LocationFilterType.PLANE]: [
      'string',
      eb => eb.ref(`${tableKey}.planeTitle`),
    ],
    [LocationFilterType.AREA]: [
      'string',
      eb => eb.ref(`${tableKey}.warehouseArea`),
    ],
    [LocationFilterType.AISLE]: [
      'string',
      eb => eb.ref(`${tableKey}.aisleTitle`),
    ],
    [LocationFilterType.BAY]: [
      'string',
      eb => eb.ref(`${tableKey}.locationBayTitle`),
    ],
    [LocationFilterType.RACKING_TYPE]: [
      'string',
      eb => eb.ref(`${tableKey}.locationRackingType`),
    ],
    [LocationFilterType.LEVEL]: [
      'string',
      eb => eb.cast(`${tableKey}.locationLevel`, 'varchar'),
    ],
    [LocationFilterType.LOCATION]: [
      'string',
      eb => eb.ref(`${tableKey}.locationId`),
    ],
    [LocationFilterType.BAY_LOCATION_ORDER]: [
      q =>
        q.leftJoin('layoutFeature as bay', join =>
          join
            .onRef('bay.datasetObjectId', '=', `${tableKey}.datasetObjectId`)
            .onRef('bay.planeId', '=', `${tableKey}.planeId`)
            .on('bay.type' as any, '=', sql.lit(LayoutFeatureType.BAY))
            .onRef('bay.id' as any, '=', `${tableKey}.locationBayId`),
        ) as any,
      'string',
      eb =>
        ATHENA_DIALECT_HELPER.jsonPropertyValue(
          eb.ref('bay.details' as any),
          'locationOrder',
        ),
    ],
    [LocationFilterType.SIDE]: [
      'string',
      eb => eb.ref(`${tableKey}.locationSide`),
    ],
    [LocationFilterType.ACCESS_AISLE]: [
      // TODO incorrect implementation
      q =>
        q.leftJoin('layoutFeature as aisle', join =>
          join
            .onRef('aisle.datasetObjectId', '=', `${tableKey}.datasetObjectId`)
            .onRef('aisle.planeId', '=', `${tableKey}.planeId`)
            .on('aisle.type' as any, '=', sql.lit(LayoutFeatureType.AISLE))
            .onRef('aisle.id' as any, '=', `${tableKey}.aisleId`),
        ) as any,
      'string',
      eb => eb.ref('aisle.title' as any),
    ],
    [LocationFilterType.USAGE_TYPE]: [
      'string',
      eb => eb.ref(`${tableKey}.locationUsageType`),
    ],
    [LocationFilterType.CONGESTION_ZONE]: [
      'string',
      eb => eb.ref(`${tableKey}.congestionZone`),
    ],
    [LocationFilterType.HEIGHT_FROM_FLOOR]: [
      'number',
      eb => eb.ref(`${tableKey}.locationHeightFromFloor`),
    ],
    [LocationFilterType.MHTYPE]: [
      'string',
      eb => eb.ref(`${tableKey}.locmhtype`),
    ],
    [LocationFilterType.DEPTH_FROM_FRONT]: [
      'number',
      eb => eb.ref(`${tableKey}.locationDepthFromFront`),
    ],
    [LocationFilterType.DEPTH_POSITION]: [
      'number',
      eb => eb.ref(`${tableKey}.locationDepthPosition`),
    ],
    [LocationFilterType.INDEX_FROM_FRONT]: [
      'number',
      eb => eb.ref(`${tableKey}.locationIndexFromFront`),
    ],
    [LocationFilterType.BAY_TYPE]: [
      'string',
      eb => eb.ref(`${tableKey}.bayType`),
    ],
  };
}
