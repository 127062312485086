import React from 'react';
import ImportActivityFeedStartView from '../activityFeed/ImportActivityFeedStartView';
import ImportAssignmentStartView from '../assignment/ImportAssignmentStartView';
import ImportAssignmentPolicyStartView from '../assignmentPolicy/ImportAssignmentPolicyStartView';
import ImportItemSetStartView from '../itemSet/ImportItemSetStartView';
import ImportLayoutStartView from '../layout/ImportLayoutStartView';
import ImportOrderSetStartView from '../orders/ImportOrderSetStartView';
import { ImportStepContainerProps } from '../store/import.types';

const ImportStartStep: React.FC<ImportStepContainerProps> = props => {
  switch (props.type) {
    case 'assignment':
      return <ImportAssignmentStartView />;
    case 'items':
      return <ImportItemSetStartView />;
    case 'layout':
      return <ImportLayoutStartView />;
    case 'orders':
      return <ImportOrderSetStartView />;
    case 'assignmentPolicy':
      return <ImportAssignmentPolicyStartView />;
    case 'activityFeed':
      return <ImportActivityFeedStartView />;
  }
};

export default ImportStartStep;
