import * as React from 'react';
const ActionDuplicateIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ActionDuplicateIcon"
    {...props}
  >
    <path
      fill="#070101"
      strokeWidth={0}
      d="M41.25,33.85h53.57V14.88c0-5.41-4.41-9.73-9.73-9.73H13.47c-5.32,0-9.73,4.41-9.73,9.73v71.61c0,5.41,4.41,9.73,9.73,9.73h21.88v-56.56c0-3.16,2.66-5.82,5.91-5.82Z"
    />
    <path
      fill="#070101"
      strokeWidth={0}
      d="M114.61,33.85h-19.8v56.56c0,3.24-2.66,5.91-5.91,5.91h-53.57v16.8c0,5.41,4.41,9.73,9.73,9.73h69.45c5.41,0,9.73-4.41,9.73-9.73V43.58c.08-5.32-4.33-9.73-9.65-9.73Z"
    />
    <path
      fill="#070101"
      strokeWidth={0}
      d="M84.59,58.22h-14.72v-14.81c0-1.25-1-2.25-2.25-2.25h-7.07c-1.25,0-2.25,1-2.25,2.25v14.81h-14.81c-1.25,0-2.25,1-2.25,2.25v7.07c0,1.25,1,2.25,2.25,2.25h14.72v14.81c0,1.25,1,2.25,2.25,2.25h7.07c1.25,0,2.25-1,2.25-2.25v-14.81h14.81c1.25,0,2.25-1,2.25-2.25v-7.07c0-1.25-1-2.25-2.25-2.25Z"
    />
  </svg>
);
export default ActionDuplicateIcon;
