import { ACTIVITY_FEED_EXPORT_CONVERTER } from './export-converter-activity-feed';
import { ALLOCATION_REQUIREMENT_EXPORT_CONVERTER } from './export-converter-allocation-requirements';
import { ALLOCATION_SUMMARY_EXPORT_CONVERTER } from './export-converter-allocation-summary';
import { ANALYZED_PRODUCTS_EXPORT_CONVERTER } from './export-converter-analyzed-products';
import { ASSIGNMENT_EXPORT_CONVERTER } from './export-converter-assignment';
import { ASSIGNMENT_COMPLIANCE_EXPORT_CONVERTER } from './export-converter-assignment-compliance';
import { ASSIGNMENT_DIFF_EXPORT_CONVERTER } from './export-converter-assignment-diff';
import { ASSIGNMENT_ITEM_ISSUES_EXPORT_CONVERTER } from './export-converter-assignment-item-issues';
import { DATASET_QUERY_EXPORT_CONVERTER } from './export-converter-dataset-query';
import { IMPORT_JOBS_EXPORT_CONVERTER } from './export-converter-import-jobs';
import { ITEMS_SET_EXPORT_CONVERTER } from './export-converter-items';
import { ITEMS_ISSUES_EXPORT_CONVERTER } from './export-converter-items-issues';
import { LAYOUT_LOCATIONS_EXPORT_CONVERTER } from './export-converter-layout-locations';
import { LOCATIONS_STATS_EXPORT_CONVERTER } from './export-converter-locations-stats';
import { OPTIMIZATION_MOVES_EXPORT_CONVERTER } from './export-converter-optimization-moves';
import { ORDER_LINE_ISSUES_EXPORT_CONVERTER } from './export-converter-order-line-issues';
import { ORDERED_QUANTITY_REPORT_EXPORT_CONVERTER } from './export-converter-ordered-quantity';
import { ORDER_SET_EXPORT_CONVERTER } from './export-converter-orders';
// import { ORDER_SET_EXPORT_CONVERTER } from './export-converter-orders';
import { PRODUCT_BY_RULE_EXPORT_CONVERTER } from './export-converter-products-by-rule';
import { REASSIGN_JOBS_EXPORT_CONVERTER } from './export-converter-reassign-jobs';
import { UOM_ISSUES_EXPORT_CONVERTER } from './export-converter-uom-issues';
import { ExportConverter, ExportConverterId } from './types';

export const EXPORT_CONVERTERS: Record<
  ExportConverterId,
  ExportConverter<any, any>
> = {
  LOCATIONS_STATS: LOCATIONS_STATS_EXPORT_CONVERTER,
  LAYOUT_LOCATIONS: LAYOUT_LOCATIONS_EXPORT_CONVERTER,
  ASSIGNMENT: ASSIGNMENT_EXPORT_CONVERTER,
  ITEM_SET: ITEMS_SET_EXPORT_CONVERTER,
  ITEMS_ISSUES: ITEMS_ISSUES_EXPORT_CONVERTER,
  UOM_ISSUES: UOM_ISSUES_EXPORT_CONVERTER,
  ORDER_SET: ORDER_SET_EXPORT_CONVERTER,
  ORDER_LINE_ISSUES: ORDER_LINE_ISSUES_EXPORT_CONVERTER,
  ASSIGNMENT_ITEM_ISSUES: ASSIGNMENT_ITEM_ISSUES_EXPORT_CONVERTER,
  ASSIGNMENT_COMPLIANCE: ASSIGNMENT_COMPLIANCE_EXPORT_CONVERTER,
  ASSIGNMENT_DIFF: ASSIGNMENT_DIFF_EXPORT_CONVERTER,
  ORDERED_QUANTITY_REPORT: ORDERED_QUANTITY_REPORT_EXPORT_CONVERTER,
  PRODUCTS_BY_RULE: PRODUCT_BY_RULE_EXPORT_CONVERTER,
  ALLOCATION_REQUIREMENT: ALLOCATION_REQUIREMENT_EXPORT_CONVERTER,
  REASSIGN_JOBS: REASSIGN_JOBS_EXPORT_CONVERTER,
  OPTIMIZATION_MOVES: OPTIMIZATION_MOVES_EXPORT_CONVERTER,
  ANALYZED_PRODUCTS: ANALYZED_PRODUCTS_EXPORT_CONVERTER,
  ALLOCATION_SUMMARY: ALLOCATION_SUMMARY_EXPORT_CONVERTER,
  IMPORT_JOBS: IMPORT_JOBS_EXPORT_CONVERTER,
  ACTIVITY_FEED: ACTIVITY_FEED_EXPORT_CONVERTER,
  DATASET_QUERY: DATASET_QUERY_EXPORT_CONVERTER,
};
