import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { Alert } from '../../../../components/alerts/Alerts';
import * as Icon from '../../../../components/icons';
import useSelectConverterWizardStep from '../hooks/useSelectConverterWizardStep';
import {
  converterEditableArea,
  converterSelectedArea,
  converterSelectedAreaId,
} from '../store/converter.area.state';
import { converterLayoutIdSaving } from '../store/converter.state';
import { ConverterAreaIntersection } from '../store/converter.types';
import { converterSelectedFloorValidationResult } from '../store/validation.state';

const AreaTag = ({ id, title }: { id: string; title: string }) => {
  const setSelectedArea = useSetRecoilState(converterSelectedAreaId);

  return (
    <span
      className={classNames(
        'hover:text-menu-active-hover mx-1 cursor-pointer px-1 text-xs text-white',
        'rounded border border-white/50 px-1 py-1 hover:border-white',
      )}
      onClick={() => setSelectedArea(id)}
    >
      {title ?? id}
    </span>
  );
};

type AreaIntersectionTagProps = {
  baseAreaId: string;
  intersection: ConverterAreaIntersection;
};

const AreaIntersectionTag: React.FC<AreaIntersectionTagProps> = props => {
  const setSelectedArea = useSetRecoilState(converterSelectedAreaId);

  const targetAreaId =
    props.intersection.area1Id === props.baseAreaId
      ? props.intersection.area2Id
      : props.intersection.area1Id;

  const shapeBox = props.intersection.shape.box;

  const dW = parseFloat(shapeBox.width.toPrecision(7));
  const dH = parseFloat(shapeBox.height.toPrecision(7));
  return (
    <span
      className={classNames(
        'hover:text-menu-active-hover mx-1 cursor-pointer px-1 text-xs text-white',
        'rounded border border-white/50 py-1 hover:border-white',
      )}
      onClick={() => setSelectedArea(targetAreaId)}
    >
      <span className="ltr:pr-1 rtl:pl-1">{targetAreaId}</span>
      <span className="text-xxs rounded border border-white/30 px-1 py-0.5 opacity-75">
        {dW}
        <span>x</span>
        {dH}
      </span>
    </span>
  );
};

const ConverterAlerts: React.FC = () => {
  const { t } = useTranslation('designer');
  const [selectedArea, setSelectedArea] = useRecoilState(converterSelectedArea);
  const editableArea = useRecoilValue(converterEditableArea);
  const isLayoutSaving = !_.isNil(useRecoilValue(converterLayoutIdSaving));
  const validationResult = useRecoilValue(
    converterSelectedFloorValidationResult,
  );
  const selectStep = useSelectConverterWizardStep();

  if (_.isNil(validationResult)) {
    return null;
  }

  const intersections = validationResult.areaIntersections;
  const unreachableBayIds = validationResult.unreachableBayIds;
  const area = selectedArea ?? editableArea;

  const getValidationAlerts = () => {
    const alerts: React.ReactNode[] = [];

    // One area overlaps another
    if (!_.isEmpty(intersections)) {
      // Area not selected , show general message
      if (_.isNil(area)) {
        const areas = _(intersections)
          .map(i => [i.area1Id, i.area2Id])
          .flatten()
          .uniq()
          .sort()
          .value();

        alerts.push(
          <Alert
            key={`alert-area-overlaps`}
            hasStatusOverlap={true}
            icon={Icon.TriangleInfo}
            horizontal
            message={
              <>
                <span className="text-xs uppercase">{t('Area Overlaps:')}</span>
                {_.map(areas, areaId => (
                  <AreaTag key={areaId} id={areaId} title={areaId} />
                ))}
              </>
            }
          />,
        );
      }

      // When area is selected, show only intersections of selected one
      if (!_.isNil(area)) {
        const filtered = _.filter(
          intersections,
          i => i.area1Id === area?.id || i.area2Id === area?.id,
        );
        if (!_.isEmpty(filtered)) {
          alerts.push(
            <Alert
              key={`alert-area-overlaps`}
              hasStatusOverlap={true}
              icon={Icon.TriangleInfo}
              horizontal
              message={
                <>
                  <span className="text-sm">{t`Area:`}</span>
                  <span
                    className={classNames(
                      'text-white text-xs hover:text-menu-active-hover px-1 cursor-pointer mx-1',
                      'border border-white/50 px-1 py-1 rounded hover:border-white',
                    )}
                  >
                    {area.id}
                    {/* {t('Area: {{area}} overlaps:', { area: area.id })} */}
                  </span>
                  <span className="text-sm">{t`overlaps:`}</span>

                  {_.map(filtered, intersection => (
                    <AreaIntersectionTag
                      key={`${intersection.area1Id}- ${intersection.area1Id}`}
                      baseAreaId={area.id}
                      intersection={intersection}
                    />
                  ))}
                </>
              }
            />,
          );
        }
      }
    } else if (validationResult.noStartingPoint) {
      alerts.push(
        <Alert
          key={`alert-no-starting-point`}
          hasStatusError={true}
          actionable={true}
          action_title={t`Open Step`}
          message={t`No starting point defined`}
          onActionButtonClick={() => {
            selectStep('define-starting-points');
            // setValidationResult({
            //   ...validationResult,
            //   floorsWithoutStatingPoints: null,
            // });
          }}
        />,
      );
    } else if (validationResult.areasNotConnected) {
      const totalBaysCount = unreachableBayIds.length;
      const baysNamesText =
        totalBaysCount < 3
          ? unreachableBayIds.join(', ')
          : _.take(unreachableBayIds, 2).join(', ') +
            ` and ${totalBaysCount - 2} more`;

      alerts.push(
        <Alert
          key={`alert-areas-not-connected-${totalBaysCount}`}
          icon={Icon.TriangleInfo}
          hasStatusOverlap={true}
          horizontal
          message={t(
            `Oops! Areas not connected — Unreachable bays detected: {{bays}}.`,
            {
              bays: baysNamesText,
            },
          )}
        />,
      );
    } else if (!_.isEmpty(unreachableBayIds)) {
      const totalBaysCount = unreachableBayIds.length;
      const baysNamesText =
        totalBaysCount < 4
          ? unreachableBayIds.join(', ')
          : _.take(unreachableBayIds, 3).join(', ') +
            ` and ${totalBaysCount - 3} more`;
      alerts.push(
        <Alert
          key={`alert-has-unreachable-bays-${totalBaysCount}`}
          hasStatusWarning={true}
          actionable={true}
          action_title={t`Dismiss`}
          message={t('Unreachable bays detected: {{baysNamesText}}', {
            baysNamesText,
          })}
          // onActionButtonClick={() => {
          //   setValidationResult({
          //     ...validationResult,
          //     unreachableBayIds: null,
          //   });
          // }}
        />,
      );
    }

    if (isLayoutSaving) {
      alerts.push(
        <>
          <LoadingIndicator
            className="left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-10"
            absolute
            // className="bg-map-area-striped"
            selfCenter
            message={t`Converting Layout`}
          />
          <Alert
            key={`alert-layout-is-saving`}
            hasStatusLoading
            hasStatusInfo={true}
            actionable={false}
            message={t`Please Wait... Converting Layout`}
          />
        </>,
      );
    }

    return alerts;
  };
  return <>{getValidationAlerts()}</>;
};

export default ConverterAlerts;
