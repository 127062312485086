import * as React from 'react';
const Mhe1BCartManualFourWheeledIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe1BCartManualFourWheeledIcon"
    {...props}
  >
    <path strokeWidth={0} d="M37.46,105h60.74v3.81h-60.74v-3.81Z" />
    <path
      strokeWidth={0}
      d="M102.22,28.97V9.22c0-2.88-2.34-5.22-5.21-5.22h-58.36c-2.88,0-5.22,2.34-5.22,5.22v19.76h-1.03v6.74h1.03v74.22c0,.75.61,1.36,1.36,1.36s1.36-.61,1.36-1.36V9.22c0-1.38,1.12-2.49,2.49-2.49h58.36c1.38,0,2.49,1.12,2.49,2.49v100.72c0,.75.61,1.36,1.36,1.36s1.36-.61,1.36-1.36V35.71h1.03v-6.74h-1.03Z"
    />
    <path strokeWidth={0} d="M57.84,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M63.34,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M52.34,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M68.85,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M46.83,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M52.34,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M46.83,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M46.83,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M46.83,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M79.85,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M79.85,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M85.36,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M74.35,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M85.36,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M85.36,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M85.36,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M85.36,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M79.85,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M52.34,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M52.34,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M57.84,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M57.84,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M68.85,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M63.34,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M63.34,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M74.35,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M68.85,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M57.84,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M74.35,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M79.85,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M63.34,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M74.35,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M68.85,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M68.85,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M79.85,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M74.35,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M38.27,35.22h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M90.86,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M63.34,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M38.27,48.59h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M90.86,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M90.86,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M90.86,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M38.27,61.97h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M90.86,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M38.27,21.84h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M96.37,48.59h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M96.37,35.22h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M96.37,21.84h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M38.27,75.34h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M96.37,61.97h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M38.27,88.72h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M96.37,75.34h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M96.37,88.72h1.02v.34h-1.02v-.34Z" />
    <path strokeWidth={0} d="M90.86,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M41.33,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M41.33,35.22h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M41.33,75.34h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M41.33,61.97h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M41.33,48.59h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M46.83,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M57.84,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M52.34,21.84h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M57.84,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M74.35,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M68.85,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M79.85,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M41.33,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M63.34,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M46.83,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M52.34,88.72h3.46v.34h-3.46v-.34Z" />
    <path strokeWidth={0} d="M85.36,88.72h3.46v.34h-3.46v-.34Z" />
    <path
      strokeWidth={0}
      d="M57.16,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M46.16,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M51.66,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M67.83,8.43c-.19,0-.34.15-.34.34v13.42h.68v-13.42c0-.19-.15-.34-.34-.34h0Z"
    />
    <path
      strokeWidth={0}
      d="M40.65,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M73.33,8.43c-.19,0-.34.15-.34.34v13.42h.68v-13.42c0-.19-.16-.34-.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M62.32,8.43c-.19,0-.34.15-.34.34v13.42h.68v-13.42c0-.19-.15-.34-.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M67.83,102.52c.19,0,.34-.15.34-.34V35.22h-.68v66.96c0,.19.15.34.34.34h0Z"
    />
    <path
      strokeWidth={0}
      d="M84.68,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M62.32,102.52c.19,0,.34-.15.34-.34V35.22h-.68v66.96c0,.19.15.34.34.34Z"
    />
    <path
      strokeWidth={0}
      d="M95.68,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M90.18,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M73.33,102.52c.19,0,.34-.15.34-.34V35.22s-.68,0-.68,0v66.96c0,.19.15.34.34.34Z"
    />
    <path
      strokeWidth={0}
      d="M79.17,102.18V8.77c0-.19-.15-.34-.34-.34s-.34.15-.34.34v93.41c0,.19.15.34.34.34s.34-.15.34-.34Z"
    />
    <path
      strokeWidth={0}
      d="M59.48,25.08v7.55c0,.79.65,1.44,1.45,1.44h13.8c.79,0,1.45-.65,1.45-1.44v-7.55c0-.79-.65-1.45-1.45-1.45h-13.8c-.79,0-1.45.65-1.45,1.45ZM60.8,33.29c-.22,0-.41-.18-.41-.41s.18-.41.41-.41.41.18.41.41-.18.41-.41.41ZM74.85,24.42c.23,0,.41.18.41.41s-.18.41-.41.41-.41-.18-.41-.41c0-.22.18-.41.41-.41ZM74.85,32.48c.23,0,.41.18.41.41s-.18.41-.41.41-.41-.18-.41-.41c0-.22.18-.41.41-.41ZM61.21,24.82c0,.22-.18.41-.41.41s-.41-.18-.41-.41.18-.41.41-.41c.23,0,.41.18.41.41Z"
    />
    <path
      strokeWidth={0}
      d="M40.59,118.76c0,.9.73,1.62,1.62,1.62h2.3l8.72-10.36h-12.64v8.74ZM42.92,117.38c.54,0,.98.43.98.98s-.44.98-.98.98-.98-.44-.98-.98.44-.98.98-.98Z"
    />
    <path
      strokeWidth={0}
      d="M47.32,118.14c0,.07.01.14.01.21,0,2.44-1.98,4.41-4.41,4.41s-4.41-1.97-4.41-4.41c0-1.26.53-2.4,1.38-3.2v-1.92c-1.75,1.04-2.92,2.94-2.92,5.12,0,2.63,1.71,4.85,4.07,5.64h3.77c2.36-.79,4.07-3.02,4.07-5.64,0-.61-.09-1.19-.26-1.75l-1.29,1.53Z"
    />
    <path
      strokeWidth={0}
      d="M94.49,110.03h-12.64s8.72,10.36,8.72,10.36h2.3c.9,0,1.62-.73,1.62-1.62v-8.74ZM93.14,118.35c0,.54-.44.98-.98.98s-.98-.44-.98-.98c0-.54.44-.98.98-.98s.98.43.98.98Z"
    />
    <path
      strokeWidth={0}
      d="M87.76,118.14c0,.07-.01.14-.01.21,0,2.44,1.98,4.41,4.41,4.41s4.41-1.97,4.41-4.41c0-1.26-.53-2.4-1.38-3.2v-1.92c1.75,1.04,2.92,2.94,2.92,5.12,0,2.63-1.71,4.85-4.07,5.64h-3.77c-2.36-.79-4.07-3.02-4.07-5.64,0-.61.09-1.19.26-1.75l1.29,1.53Z"
    />
  </svg>
);
export default Mhe1BCartManualFourWheeledIcon;
