import Flatten from '@flatten-js/core';
import {
  AreaConfigurationMap,
  AreaLink,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import { ConvertedAreaFeature } from '../converter.serializable.model';
import {
  getReverseTransformedAreaBox,
  getTransformedAreaBox,
} from './transformation.helper';

function calculateLinkOffset(
  master: Flatten.Box,
  dependent: Flatten.Box,
  link: AreaLink,
): Flatten.Point {
  const masterX =
    link.masterLinkPointType === 'bottomLeft' ||
    link.masterLinkPointType === 'topLeft'
      ? master.xmin
      : master.xmax;
  const masterY =
    link.masterLinkPointType === 'bottomLeft' ||
    link.masterLinkPointType === 'bottomRight'
      ? master.ymin
      : master.ymax;

  const dependentX =
    link.linkPointType === 'bottomLeft' || link.linkPointType === 'topLeft'
      ? dependent.xmin
      : dependent.xmax;
  const dependentY =
    link.linkPointType === 'bottomLeft' || link.linkPointType === 'bottomRight'
      ? dependent.ymin
      : dependent.ymax;

  return new Flatten.Point(
    masterX - dependentX + link.linkOffsetX,
    masterY - dependentY + link.linkOffsetY,
  );
}

export function updateLinkedAreas(
  areaConfigs: AreaConfigurationMap,
  areas: ConvertedAreaFeature[],
): AreaConfigurationMap {
  if (_.isEmpty(areas) || _.isNil(areaConfigs)) {
    return areaConfigs;
  }
  const masterAreaIds = [];
  const linkedAreaIds = [];
  const areasMap = _.keyBy(areas, a => a.id);
  _.forEach(areaConfigs, a => {
    if (!_.isNil(a.link?.masterAreaId)) {
      linkedAreaIds.push(a.id);
      masterAreaIds.push(a.link?.masterAreaId);
    }
  });
  const startAreasIds = _.difference(masterAreaIds, linkedAreaIds);

  return _.reduce(
    startAreasIds,
    (config, id) => updateLinkedArea(id, config, areasMap),
    areaConfigs,
  );
}

function updateLinkedArea(
  masterAreaId: string,
  areaConfigs: AreaConfigurationMap,
  areasMap: Record<string, ConvertedAreaFeature>,
): AreaConfigurationMap {
  const linkedConfigs = _.filter(
    areaConfigs,
    c => c.link?.masterAreaId === masterAreaId && !c.isDeleted,
  );
  if (_.isEmpty(linkedConfigs)) {
    // no linked areas
    return areaConfigs;
  }
  const masterAreaConfig = areaConfigs[masterAreaId];
  const masterArea = areasMap[masterAreaId];
  const masterAreaBox = getTransformedAreaBox(masterArea, masterAreaConfig);

  if (_.isNil(masterArea) || _.isNil(masterAreaBox)) {
    return areaConfigs;
  }

  return _.reduce(
    linkedConfigs,
    (allConfigs, dependentConfig) => {
      const dependentArea = areasMap[dependentConfig.id];
      if (_.isNil(dependentArea)) {
        return allConfigs;
      }
      const dependentAreaBox = getTransformedAreaBox(
        dependentArea,
        dependentConfig,
      );
      const offset = calculateLinkOffset(
        masterAreaBox,
        dependentAreaBox,
        dependentConfig.link,
      );

      const targetTransformedBox = getReverseTransformedAreaBox(
        dependentAreaBox,
        dependentConfig,
        offset,
      );

      const updatedAllConfig = {
        ...allConfigs,
        [dependentConfig.id]: {
          ...dependentConfig,
          x: targetTransformedBox.xmin,
          y: targetTransformedBox.ymin,
        },
      };
      return updateLinkedArea(dependentConfig.id, updatedAllConfig, areasMap);
    },
    areaConfigs,
  );
}
