import { DateValue, parseDate } from '@internationalized/date';
import { useDateRangePicker } from '@react-aria/datepicker';
import { useDateRangePickerState } from '@react-stately/datepicker';
import { AriaDateRangePickerProps } from '@react-types/datepicker';
import classNames from 'classnames';
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  endOfDay,
  isEqual,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import _ from 'lodash';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  toDateFromLocaleStringDate,
  toLocaleDateString,
  tz,
} from '../../../common/dateTimeHelper';
import * as Icon from '../../../components/icons';
import { Button } from '../../actions/Button';
import Dialog from '../Dialog';
import Popover from '../Popover';
import InputDateField from './InputDateField';
import RangeCalendar from './RangeCalendar';

type CustomDuration = {
  title: string;
  start: Date;
  end: Date;
};

export type DateRangePickerProps = Omit<
  AriaDateRangePickerProps<DateValue>,
  'value' | 'onChange' | 'minValue' | 'maxValue'
> & {
  value: [Date, Date];
  minValue?: Date;
  maxValue?: Date;
  onChange?: (range: [Date, Date]) => void;
  onResetClick?: () => void;
  className?: string;
  showTimeMenu?: boolean;
  showDateMenu?: boolean;
  headerMode?: boolean;
};

const DateRangePicker: React.FC<DateRangePickerProps> = props => {
  const { t } = useTranslation('app');

  const pickerProps: AriaDateRangePickerProps<DateValue> = {
    ...props,
    'aria-label': props['aria-label'] ?? t`Select date range`,
    value: _.isEmpty(props.value)
      ? null
      : {
          start: parseDate(toLocaleDateString(props.value?.[0])),
          end: parseDate(toLocaleDateString(props.value?.[1])),
        },
    minValue: props.minValue
      ? parseDate(toLocaleDateString(props.minValue))
      : undefined,
    maxValue: props.maxValue
      ? parseDate(toLocaleDateString(props.maxValue))
      : undefined,
    onChange: range => {
      setTimeout(() => {
        props.onChange([
          startOfDay(range.start.toDate(tz)),
          endOfDay(range.end.toDate(tz)),
        ]);
      }, 0);
    },
  };
  const state = useDateRangePickerState(pickerProps);
  const ref = useRef();
  const {
    groupProps,
    labelProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(pickerProps, state, ref);

  function selectDuration(start: Date, end: Date) {
    state.close();

    const actualStart = isWithinInterval(start, {
      start: props.minValue,
      end: props.maxValue,
    })
      ? start
      : props.minValue;

    const actualEnd = isWithinInterval(end, {
      start: props.minValue,
      end: props.maxValue,
    })
      ? end
      : props.maxValue;

    setTimeout(() => {
      props.onChange([
        toDateFromLocaleStringDate(actualStart),
        toDateFromLocaleStringDate(actualEnd),
      ]);
    }, 0);
  }

  const customDurations: CustomDuration[] = [
    {
      title: t`Latest Activity (1h)`,
      start: addHours(props.maxValue, -1),
      end: props.maxValue,
    },
    {
      title: t`Last Shift`,
      start: addHours(props.maxValue, -8),
      end: props.maxValue,
    },
    { title: t`Today`, start: startOfDay(props.maxValue), end: props.maxValue },
    {
      title: t`Yesterday`,
      start: startOfDay(addDays(props.maxValue, -1)),
      end: endOfDay(addDays(props.maxValue, -1)),
    },
    {
      title: t`Last 7 Days`,
      start: startOfDay(addDays(props.maxValue, -7)),
      end: props.maxValue,
    },
    {
      title: t`Last 2 Weeks`,
      start: startOfDay(addWeeks(props.maxValue, -2)),
      end: props.maxValue,
    },
    {
      title: t`This Month`,
      start: startOfMonth(props.maxValue),
      end: props.maxValue,
    },
    {
      title: t`Last Month`,
      start: startOfMonth(addMonths(props.maxValue, -1)),
      end: startOfMonth(props.maxValue),
    },
  ];

  const selectedCustomDuration = _.find(
    customDurations,
    d => isEqual(d.start, props.value?.[0]) && isEqual(d.end, props.value?.[1]),
  );

  const timeMenuStyle = classNames(
    'px-3 py-1',
    'group-hover:bg-menu-active group-hover:text-menu-active-text group-hover:shadow-md',
    'group-hover:rounded-xl',
    // 'hover:bg-menu-active hover:text-menu-active-text hover:shadow-md',
    // 'hover:rounded-xl',
    'w-full',
    'flex-1',
  );

  return (
    <div
      data-component="DateRangePicker"
      className={classNames(
        'relative inline-flex flex-col',
        'text-start',
        'w-full flex-1',
        props.className,
      )}
    >
      <span {...labelProps} className={classNames('text-menu-text text-sm')}>
        {_.isEmpty(props.label) ? '' : props.label}
      </span>
      <div
        {...groupProps}
        ref={ref}
        className={classNames('group flex items-center')}
      >
        <div
          className={classNames(
            props.headerMode ? 'h-6' : 'h-6',
            'w-auto', // panelHeaderMode // h-7
            'flex items-center',
            props.headerMode ? 'bg-app-header' : 'border-menu-active border',
            'rounded-l-md transition-colors',
            'group-focus-within:border-menu group-focus-within:group-hover:border-menu',
            'p-0.5 ltr:pr-2 rtl:pl-2',
            'active:bg-app-input',
            // 'lg:p-1',
            'relative',
          )}
        >
          {_.isNil(selectedCustomDuration) && (
            <>
              {state.validationState === 'invalid' && (
                <Icon.TriangleInfo
                  className={classNames('h-5 w-5', 'mx-0.5', 'fill-current')}
                />
              )}

              <InputDateField {...startFieldProps} />
              <span
                aria-hidden="true"
                className={classNames('px-0.5 lg:px-0.5', 'flex items-center')}
              >
                {'—'}
              </span>
              <InputDateField {...endFieldProps} />
            </>
          )}
          {!_.isNil(selectedCustomDuration) && (
            <Button {...buttonProps} className={classNames('px-2 py-1')}>
              {selectedCustomDuration.title}
            </Button>
          )}
        </div>
        {/* {props.headerMode && ( */}
        <Button
          {...buttonProps}
          className={classNames(
            props.headerMode ? 'h-6 rounded-r-full' : 'h-6 rounded-r-md',
            'bg-menu-active text-menu-active-text',
            '!py-1',
            'group-focus-within:bg-app-panel-light/50 group-focus-within:text-menu-active',
          )}
          // isSelected={state.isOpen}
        >
          <Icon.Calendar
            className={classNames(
              'fill-current',
              'h-4 w-4',
              'group-focus-within:text-menu',
            )}
          />
        </Button>
        {/* )} */}
      </div>
      {state.isOpen && (
        <Popover triggerRef={ref} state={state} placement="bottom start">
          <Dialog {...dialogProps}>
            <div className={classNames('flex', 'text-menu-text', 'p-1')}>
              <ul
                data-component="TimeMenu"
                className={classNames(
                  'flex flex-col',
                  // 'divide-y divide-app-panel/50',
                  // 'space-y-4',
                  'text-xs',
                  'border-app-panel-dark/30 border-r',
                  'pl-4 pr-6',
                )}
              >
                {_.map(customDurations, d => (
                  <li
                    // className={timeMenuStyle}
                    className={classNames(
                      'group py-1',
                      'border-app-panel/70 border-b',
                      'cursor-pointer',
                      'w-full flex-1',
                    )}
                    key={d.title}
                    onClick={() => selectDuration(d.start, d.end)}
                  >
                    <div className={timeMenuStyle}>{d.title}</div>
                  </li>
                ))}
              </ul>
              <div className={classNames('pl-6 pr-4')}>
                <RangeCalendar
                  {...calendarProps}
                  onResetClick={props.onResetClick}
                />
              </div>
            </div>
          </Dialog>
        </Popover>
      )}
    </div>
  );
};

export default DateRangePicker;
