import * as React from 'react';
const CircleAddressLocationIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleAddressLocationIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M95.97,17.65h0c-8.18-8.19-19.41-13.34-31.64-13.34s-23.5,5.26-31.65,13.51c-8.26,8.36-13.39,19.83-13.39,32.04,0,4.35.68,8.6,2.01,12.65,1.28,3.92,3.19,7.65,5.68,11.12l33.1,48.4c.35.54.81,1.02,1.38,1.4,2.32,1.58,5.48.98,7.06-1.33l32.81-47.97c2.56-3.7,4.55-7.68,5.9-11.88,1.33-4.15,2.04-8.47,2.04-12.9,0-12.21-5.13-23.49-13.31-31.69ZM64.28,73.26c-13.39,0-24.25-10.86-24.25-24.25s10.86-24.25,24.25-24.25,24.25,10.86,24.25,24.25-10.86,24.25-24.25,24.25Z"
    />
  </svg>
);
export default CircleAddressLocationIcon;
