import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  LoadAnalyzeDocument,
  LoadAnalyzeQuery,
  LoadAnalyzeQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { allocationAnalyzeResult } from '../../simulation/store/allocation/allocation.state';
import { simulationAnalyzeResultAtom } from '../../simulation/store/simulation.state';
import { AnalyzeResultType } from '../../simulation/store/simulation.types';
import { errorAppender } from '../../store/error.state';

export type LoadAnalyzeResultParams = {
  analyzeId: string;
  analyzeType: AnalyzeResultType;
};

export function useLoadAnalyzeResult() {
  const client = useApolloClient();
  const loadAnalyzeResultCallback = useRecoilCallback(
    ({ set }) =>
      async (params: LoadAnalyzeResultParams) => {
        if (_.isNil(params.analyzeId)) return;

        let response: ApolloQueryResult<LoadAnalyzeQuery>;
        try {
          response = await client.query<
            LoadAnalyzeQuery,
            LoadAnalyzeQueryVariables
          >({
            query: LoadAnalyzeDocument,
            variables: {
              analyzeId: params.analyzeId,
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load analyze result',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          return;
        }

        if (response.error) {
          console.error(response.error);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load layout',
            details: response.error.message,
            callStack: response.error.stack || null,
          });
          return;
        }

        const analyzeResult = response.data.analyzeResult;
        if (params.analyzeType === 'allocate') {
          set(allocationAnalyzeResult, analyzeResult);
        } else {
          set(simulationAnalyzeResultAtom, analyzeResult);
        }
      },
  );

  return async (params: LoadAnalyzeResultParams) => {
    await loadAnalyzeResultCallback(params);
  };
}
