import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { Circle, Group, Path } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import {
  iconSizeBase,
  startPointSVG,
} from '../../simulation/store/routingPolicy/routing.default';
import { viewerSelectedPlane } from '../viewer/store/viewer.state';

const waypointColors = TwTheme.extend.colors.route;

const EntryPointFeature: React.FC = props => {
  const plane = useRecoilValue(viewerSelectedPlane);
  if (_.isNil(plane)) return null;

  const { x, y } = plane.start.position;

  const targetSize = 100;
  const scaleFactorInternal = targetSize / iconSizeBase;

  const circleConfig: Konva.CircleConfig = {
    radius: iconSizeBase / 1.75,
    fill: waypointColors.label,
  };

  const pathConfig: Konva.PathConfig = {
    offsetX: iconSizeBase / 2,
    offsetY: iconSizeBase / 2,
    data: startPointSVG,
    fill: waypointColors.start,
  };
  const groupConfig: Konva.NodeConfig = {
    x,
    y,
    scaleX: scaleFactorInternal,
    scaleY: scaleFactorInternal,
  };

  return (
    <Group {...groupConfig}>
      <Circle {...circleConfig} />
      <Path {...pathConfig} />
    </Group>
  );
};

export default EntryPointFeature;
