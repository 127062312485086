import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { HelperMessageAction } from '../../components/HelperMessage';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import Callout from '../../helpContext/Callout';
import Tag from '../../helpContext/Tag';
import { useLoadLayout } from '../../layout/hooks/useLoadLayout';
import { getAnalyzeTitle } from '../analyzeCompare.helper';
import {
  analyzeCompareAnalyzeList,
  analyzeCompareAnalyzeListLoadStatus,
  analyzeCompareIdByType,
} from '../analyzeCompare.state';
import { AnalyzeCompareAnalyzeType } from '../analyzeCompare.types';

export type AnalyzeCompareSelectorProps = {
  analyzeType: AnalyzeCompareAnalyzeType;
};

const AnalyzeCompareSelector: React.FC<AnalyzeCompareSelectorProps> = props => {
  const { t } = useTranslation('simulation');

  const [selectedAnalyzeId, setSelectedAnalyzeId] = useRecoilState(
    analyzeCompareIdByType(props.analyzeType),
  );
  const analyzeList = useRecoilValue(analyzeCompareAnalyzeList);
  const analyzeListLoadStatus = useRecoilValue(
    analyzeCompareAnalyzeListLoadStatus,
  );
  const [loadLayout, cleanupLayout] = useLoadLayout();

  function onChange(analyzeId: string) {
    const descriptor = analyzeMap[analyzeId];
    setSelectedAnalyzeId(analyzeId);
    const layoutId = descriptor?.layout?.id;
    if (!_.isNil(layoutId)) {
      loadLayout(layoutId);
    }
  }

  const analyzeMap = _.keyBy(analyzeList, anl => anl.analyzeId);

  const isLoading = analyzeListLoadStatus === AsyncLoadStatus.Loading;

  const analyzeTypeTitle =
    props.analyzeType === 'first' ? t`Scenario 1` : t`Scenario 2`;

  return (
    <>
      {!selectedAnalyzeId && (
        <Callout panelMode type="suggestion" iconAfter icon={Icon.ArrowBottom}>
          {t`Click to`}
          <HelperMessageAction>{t`Select`}</HelperMessageAction>
          {t('{{analyzeType}} to compare', {
            analyzeType: analyzeTypeTitle,
          })}
        </Callout>
      )}
      <div
        className={classNames(
          'flex items-center',
          'group py-1 lg:py-2',
          'px-2',
        )}
      >
        <DropdownSelector
          value={selectedAnalyzeId}
          values={_.keys(analyzeMap)}
          renderValue={v => {
            if (!v || !analyzeMap[v]) {
              return t`No Analyse Selected, Click to Select`;
            }

            const category = analyzeMap[v].category;
            let tag;
            switch (category) {
              case 'initial':
                tag = (
                  <Tag classType="border border-state-analyzed uppercase text-xxs">{t`Analysed`}</Tag>
                );
                break;
              case 'allocated':
                tag = (
                  <Tag classType="border border-state-allocated uppercase text-xxs">{t`Allocated`}</Tag>
                );
                break;
              case 'optimised':
                tag = (
                  <Tag classType="border border-state-optimized uppercase text-xxs">{t`Optimised`}</Tag>
                );
                break;
              default:
                tag = (
                  <Tag classType="border border-state-unknown uppercase text-xxs">{t`Unknown`}</Tag>
                );
            }

            return (
              <div className="flex items-center">
                {getAnalyzeTitle(analyzeMap[v], t)}
                {tag}
              </div>
            );
          }}
          valueHelper={v => analyzeMap[v]?.updatedAt?.toLocaleString('en-GB')}
          DropAlignRight
          widthFull
          valuePrimary
          multiline
          light
          onChange={v => onChange(v)}
          border
          hasSearch
          hasSearchLabel={t`Simulations`}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AnalyzeCompareSelector;
