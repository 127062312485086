import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { Spacer } from '../../components/layout/Spacer';
import LoadingIndicator from '../../components/LoadingIndicator';
import LinkTraced from '../../components/nav/LinkTraced';
import Tag from '../../helpContext/Tag';
import {
  warehouseCanUpdate,
  warehouseIsDemo,
} from '../../store/warehouse.state';
import DashboardItemStatusTag from '../DashboardItemStatusTag';
import { DashboardItemStatus } from '../store/dashboard.types';
import TimeAgo from '../TimeAgo';

export type DashboardListItemContainerProps = {
  id: string;
  title: string;
  description?: string;
  className?: string;
  path: string;
  createdDate: Date;
  updatedDate: Date;
  status?: DashboardItemStatus;
  isLoading?: boolean;
  icon?: React.FC<React.HTMLAttributes<Element>>;
  canDelete: boolean;
  onDeleteClick?: () => Promise<void>;
  actions?: Partial<Record<DocumentAction, () => Promise<string>>>;
};

const documentActions = ['Edit', 'Delete', 'Converter', 'Duplicate'] as const;
type DocumentAction = (typeof documentActions)[number];

const DashboardListItemContainer: React.FC<
  DashboardListItemContainerProps
> = props => {
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMessage = t`Updating...`;
  const isDemo = useRecoilValue(warehouseIsDemo);
  const IconMenu = props.icon;

  function getActionTitle(action: DocumentAction): string {
    switch (action) {
      case 'Edit':
        return t`Open`;
      case 'Delete':
        return t`Delete`;
      case 'Converter':
        return t`Edit in Designer`;
      case 'Duplicate':
        return t`Duplicate`;
    }
  }

  const options: DocumentAction[] =
    canUpdate && props.canDelete ? ['Edit', 'Delete'] : ['Edit'];
  if (props.actions) {
    options.push(...(_.keys(props.actions) as DocumentAction[]));
  }

  const handleContainerClick = (e: React.MouseEvent) => {
    navigate(props.path);
  };

  return (
    <div
      className={classNames(
        'bg-app-panel/50 hover:bg-app-panel/80',
        'group',
        'w-full',
        'flex-1',
        'cursor-pointer',
        'px-4 py-3',
        'mb-px- mb-0.5 ltr:mr-0.5 rtl:ml-0.5',
        'border-app-panel-dark/80 border',
        props.className,
      )}
    >
      <header className="flex items-center">
        {IconMenu && (
          <IconMenu className="text-menu-text h-6 w-6 flex-shrink-0 ltr:mr-2 rtl:ml-2" />
        )}
        <LinkTraced
          data-component="ItemList"
          titleTrace={`Dashboard Item: ${props.title}`}
          to={props.path}
          className="min-w-0 flex-1"
          onClick={handleContainerClick}
        >
          <div
            className="flex flex-col"
            data-component="DashboardItemContainer"
          >
            <div
              data-component="DashboardItemContainerHeader"
              className="flex items-center"
            >
              <TimeAgo
                timeDate={props.updatedDate}
                updatedDate={props.updatedDate}
                createdDate={props.createdDate}
                alignStart
              />
              <Spacer flexspace />
              {props.status && (
                <DashboardItemStatusTag
                  status={props.status}
                  className="flex-shrink-0"
                >
                  {!props.canDelete && (
                    <Icon.Lock className="h-4 w-4 fill-current ltr:mr-1 rtl:ml-1" />
                  )}
                </DashboardItemStatusTag>
              )}
            </div>

            <div
              data-component="DashboardItemContainerTitle"
              className="mt-2 flex items-center"
            >
              <h3 className="text-menu-h3 truncate text-lg font-medium">
                {props.title}
              </h3>
            </div>
            {props.description && (
              <p className="text-menu-text/75 truncate text-xs">
                {props.description}
              </p>
            )}
          </div>
        </LinkTraced>

        <DropdownSelector
          className="dropdown-selector flex-shrink-0"
          onClick={e => e.stopPropagation()}
          DropAlignRight
          buttonTransparent
          vertical
          value="..."
          values={options}
          renderValue={getActionTitle}
          onChange={async (option: DocumentAction, e) => {
            e.preventDefault();
            e.stopPropagation();
            switch (option) {
              case 'Edit':
                navigate(props.path);
                break;
              case 'Delete':
                setIsLoading(true);
                await props.onDeleteClick?.();
                setIsLoading(false);
                break;
              default:
                if (props.actions?.[option]) {
                  setIsLoading(true);
                  const redirectUrl = await props.actions[option]?.();
                  setIsLoading(false);
                  if (!_.isNil(redirectUrl)) {
                    navigate(redirectUrl);
                  }
                }
                break;
            }
          }}
        />
      </header>
      {(props.isLoading || isLoading) && (
        <LoadingIndicator absolute selfCenter message={loadingMessage} />
      )}
      {isDemo && <Tag className="!mx-0">{t`Demo mode: Read-only`}</Tag>}
    </div>
  );
};

export default DashboardListItemContainer;
