import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AnalyzePerformance from '../../analyze/dashboard/AnalyzePerformance';
import { PanelHeader } from '../../components/designer/panels/PanelHeader';
import { Spacer } from '../../components/layout/Spacer';
import WorkforceAgentContainer from '../panels/workforce/WorkforceAgentContainer';
import WorkforceForecastSwitch from '../panels/workforce/forecast/WorkforceForecastSwitch';
import { workforceSelectedAgentId } from '../store/workforce.state';

export type SimulationWorkforceViewProps = {
  analyzeId: string;
};

const SimulationWorkforceView: React.FC<
  SimulationWorkforceViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const selectedAgentId = useRecoilValue(workforceSelectedAgentId);

  if (_.isNil(selectedAgentId)) {
    return <AnalyzePerformance analyzeId={props.analyzeId} />;
  }

  return (
    <>
      <PanelHeader title={t`Analyse: Workforce`}>
        <Spacer flexspace />
        <WorkforceForecastSwitch />
      </PanelHeader>

      <WorkforceAgentContainer analyzeId={props.analyzeId} />
    </>
  );
};
export default SimulationWorkforceView;
