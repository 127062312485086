const LinkUnlockIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkUnlockIcon"
    {...props}
  >
    <path d="M36.71,116.57c0,1.37.56,2.72,1.53,3.69.98.98,2.29,1.53,3.68,1.53h74.64c1.39,0,2.7-.54,3.69-1.53.97-.97,1.52-2.31,1.52-3.68v-4.19s0-70.45,0-70.45c0-1.39-.54-2.7-1.53-3.68-.98-.98-2.29-1.53-3.69-1.53h-46.71s10,10.42,10,10.42h11.42s20.07,0,20.07,0v64.22s-64.22,0-64.22,0v-16.42h-10.43" />
    <path d="M46.4,80.86h-29.76s0-64.22,0-64.22h64.22s0,15.94,0,15.94h10.42s0-21.15,0-21.15c0-2.87-2.34-5.21-5.21-5.21H11.43c-1.37,0-2.72.55-3.69,1.52-.97.97-1.53,2.31-1.53,3.68v74.64c0,1.37.56,2.71,1.53,3.68.97.97,2.31,1.53,3.68,1.53h44.97" />
    <path d="M101.62,85.56h-.06v-3.64c0-5.74-4.72-10.46-10.46-10.46-5.74,0-10.46,4.72-10.46,10.46v3.64h-.23c-1.53,0-2.79,1.25-2.79,2.78v15.23c0,1.53,1.25,2.78,2.79,2.78h21.25c1.53,0,2.78-1.25,2.78-2.78v-15.29c-.11-1.53-1.31-2.73-2.84-2.73ZM97.01,85.56h-11.82s0-3.64,0-3.64c0-3.24,2.61-5.91,5.91-5.91,3.24,0,5.91,2.61,5.91,5.91v3.64Z" />
    <path d="M64.88,71.36l-15.94-15.94-16.7,16.7c-1.68,1.68-4.4,1.68-6.07,0-1.68-1.68-1.68-4.4,0-6.07l16.7-16.7-15.94-15.94c-1.68-1.68-1.68-4.4,0-6.07,1.68-1.68,4.4-1.68,6.07,0l15.94,15.94,15.18-15.18c1.68-1.68,4.4-1.68,6.07,0,1.68,1.68,1.68,4.4,0,6.07l-15.18,15.18,15.94,15.94c1.68,1.68,1.68,4.4,0,6.07-1.68,1.68-4.4,1.68-6.07,0Z" />
  </svg>
);
export default LinkUnlockIcon;
