import { OptimizeJobEngine } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import {
  AgentSettingsWithMeta,
  ResourcePolicy,
} from '../../resourcePolicy/agentData/agent.types';
import { resourcePolicySelectedAgentId } from './resourcePolicy.state';
import { simulationCurrent } from './simulation.state';

const getKey = (postfix: string) => `warebee-optimization-settings-${postfix}`;

export const optimizationSetupStepMultiplier = persistAtom<number>({
  key: getKey('step-multiplier'),
  default: 1,
});

export const optimizationSetupSupportEnabled = persistAtom<boolean>({
  key: getKey('support-enabled'),
  default: false,
});

export const optimisationSetupSeed = persistAtom<number>({
  key: getKey('seed'),
  default: 123,
});

export const optimisationSetupNodesCount = persistAtom<number>({
  key: getKey('nodes-count'),
  default: 1,
});

export const optimisationSetupEngineType = atom<OptimizeJobEngine>({
  key: getKey('engine-type'),
  default: OptimizeJobEngine.FARGATE,
  // effects: [
  //   recoilPersist({
  //     key: getKey('engine-type'),
  //     storage: localStorage,
  //   }).persistAtom,
  // ],
});

export const optimisationSetupResourcePolicy = selector<ResourcePolicy>({
  key: getKey('resource-policy'),
  get: ({ get }) => {
    const sim = get(simulationCurrent);
    return sim?.optimizationSettings?.policyOverrides
      ?.resourcePolicy as ResourcePolicy;
  },
  set: ({ get, set }, value: ResourcePolicy) => {
    const sim = get(simulationCurrent);
    set(simulationCurrent, {
      ...sim,
      optimizationSettings: {
        ...(sim.optimizationSettings ?? {}),
        policyOverrides: {
          ...(sim.optimizationSettings?.policyOverrides ?? {}),
          resourcePolicy: value,
        },
      },
    });
  },
});

export const optimisationSetupResourceAgentById = selectorFamily<
  AgentSettingsWithMeta,
  string
>({
  key: getKey('resource-agent-by-id'),
  get:
    (agentId: string) =>
    ({ get }) => {
      const policy = get(optimisationSetupResourcePolicy);
      return _.find(policy?.agents, agent => agent.id === agentId);
    },
  set:
    (agentId: string) =>
    ({ get, set }, value: AgentSettingsWithMeta) => {
      const policy = get(optimisationSetupResourcePolicy);
      set(optimisationSetupResourcePolicy, {
        ...policy,
        agents: policy.agents.map(rule => (rule.id === agentId ? value : rule)),
      });
    },
});

export const optimisationSetupSelectedAgent = selector<AgentSettingsWithMeta>({
  key: getKey('selected-resource-rule'),
  get: ({ get }) => {
    const selectedId = get(resourcePolicySelectedAgentId);
    if (_.isNil(selectedId)) return null;
    return get(optimisationSetupResourceAgentById(selectedId));
  },
  set: ({ get, set }, value: AgentSettingsWithMeta) => {
    const selectedId = get(resourcePolicySelectedAgentId);
    if (_.isNil(selectedId)) return null;
    set(optimisationSetupResourceAgentById(selectedId), value);
  },
});
