import * as React from 'react';
const WarehouseAddIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseAddIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M63.86,57.16c-17.64,0-32,14.35-32,32s14.36,32,32,32,32-14.35,32-32-14.36-32-32-32ZM79.24,91.62h-12.92v13.54c0,1.36-1.1,2.46-2.46,2.46s-2.46-1.1-2.46-2.46v-13.54h-12.92c-1.36,0-2.46-1.1-2.46-2.46s1.1-2.46,2.46-2.46h12.92v-12.31c0-1.36,1.1-2.46,2.46-2.46s2.46,1.1,2.46,2.46v12.31h12.92c1.36,0,2.46,1.1,2.46,2.46s-1.1,2.46-2.46,2.46Z"
    />
    <path
      strokeWidth={0}
      d="M64,27.22L5.2,41.26v56.91h22.47c-.65-2.76-1.01-5.64-1.01-8.6,0-20.54,16.65-37.19,37.19-37.19s37.19,16.65,37.19,37.19c0,2.96-.36,5.84-1.01,8.6h22.76v-56.91l-58.8-14.03Z"
    />
  </svg>
);
export default WarehouseAddIcon;
