import { useApolloClient } from '@apollo/client';
import {
  BatchJobStatus,
  LoadCheckSwapPolicyJobDocument,
  LoadCheckSwapPolicyJobQuery,
  LoadCheckSwapPolicyJobQueryVariables,
  useRunCheckSwapPolicyMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { CHECK_POLICY_JOB_REFRESH_INTERVAL } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';
import { getSwapPolicyInput } from './swapPolicy.helper';
import { swapPolicyCheckResult, swapPolicyDocument } from './swapPolicy.state';

function useCheckSwapPolicy() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load check results for Swap policy`;
  const [runJob] = useRunCheckSwapPolicyMutation();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();
  let timeoutId = null;

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(swapPolicyCheckResult, {
      status: AsyncLoadStatus.Loading,
      result: null,
    });
  });

  const loadJob = useRecoilCallback(
    ({ snapshot, set }) =>
      async (jobId: string) => {
        const query = client.watchQuery<
          LoadCheckSwapPolicyJobQuery,
          LoadCheckSwapPolicyJobQueryVariables
        >({
          query: LoadCheckSwapPolicyJobDocument,
          variables: {
            id: jobId,
          },
        });

        function handleError(details?, stack?) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(swapPolicyCheckResult, {
            status: AsyncLoadStatus.Error,
            result: null,
          });
        }
        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const job = data.checkOptimizationSwapPolicyJob;
            if (
              job.status === BatchJobStatus.FAILED ||
              job.status === BatchJobStatus.TERMINATED
            ) {
              handleError();
            }

            if (
              job.status === BatchJobStatus.CALCULATING ||
              job.status === BatchJobStatus.CREATED
            ) {
              timeoutId = setTimeout(() => {
                loadJob(jobId);
              }, CHECK_POLICY_JOB_REFRESH_INTERVAL);
            }

            if (job.status === BatchJobStatus.READY) {
              set(swapPolicyCheckResult, {
                status: AsyncLoadStatus.Ok,
                result: job.result,
              });
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  const startCheck = useRecoilCallback(({ snapshot, set }) => async () => {
    const simulation = await snapshot.getPromise(simulationCurrent);
    const policy = await snapshot.getPromise(swapPolicyDocument);

    function handleError(details?, stack?) {
      set(errorAppender, {
        id: nanoid(),
        title: errorTitle,
        details: details,
        callStack: stack,
      });
      set(swapPolicyCheckResult, {
        status: AsyncLoadStatus.Error,
        result: null,
      });
    }

    try {
      const { data: jobData, errors: jobErrors } = await runJob({
        variables: {
          input: {
            simulationId: simulation.id,
            policy: getSwapPolicyInput(policy),
          },
        },
      });

      if (
        !_.isEmpty(jobErrors) ||
        _.isNil(jobData.startCheckOptimizationSwapPolicyJob?.id)
      ) {
        const details = _(jobErrors)
          .map(j => j.message)
          .join(';');
        handleError(details);
        return;
      }

      loadJob(jobData.startCheckOptimizationSwapPolicyJob?.id);
    } catch (ex) {
      handleError(ex?.message ?? ex);
    }
  });

  async function call() {
    await initLoading();
    await startCheck();
  }

  function cancel() {
    observable?.unsubscribe();
    clearTimeout(timeoutId);
  }

  return [call, cancel] as const;
}

export default useCheckSwapPolicy;
