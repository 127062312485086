import { Container } from '../../components/layout/ContainerFlex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import DatasetObjectStartPanel from '../panels/DatasetObjectStartPanel';
import {
  datasetObjectDocument,
  datasetObjectDocumentId,
  datasetObjectSelectedMenuItemId,
} from '../store/datasetObject.state';

const DatasetObjectMainSidebar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const id = useRecoilValue(datasetObjectDocumentId);
  const stepId = useRecoilValue(datasetObjectSelectedMenuItemId(id));
  const documentLoadable = useRecoilValueLoadable(datasetObjectDocument);

  const isLoading = documentLoadable.state === 'loading';
  const hasError = documentLoadable.state === 'hasError';
  const isReady = documentLoadable.state === 'hasValue';

  if (isLoading) {
    return <LoadingIndicator selfCenter message={t`Loading Dataset`} />;
  }
  if (hasError) {
    return <ErrorIndicator selfCenter message={t`Cannot load Dataset`} />;
  }

  const getContent = () => {
    switch (stepId) {
      case 'dataset-object-menu-start':
        return <DatasetObjectStartPanel />;
      // case 'dataset-object-menu-import-jobs':
      //   return <DatasetObjectImportJobsPanel />;
      // case 'dataset-object-menu-import-pipelines':
      //   return <DatasetObjectImportPipelinesPanel />;
    }
  };

  return <Container col>{getContent()}</Container>;
};

export default () => {
  const { t } = useTranslation('dataset');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-dataset-object-main',
    title: t`Dataset Properties`,
    size: 'md',
  };
  return (
    <SidebarContainer {...containerProps}>
      <DatasetObjectMainSidebar />
    </SidebarContainer>
  );
};
