import React, { useState } from 'react';
import _ from 'lodash';
import { AppHeader } from '../../components/AppHeader';
// import * as Icon from '../components/icons';

export type HelpProps = {
  children?: React.ReactNode;
};

export const Help = ({ children }: HelpProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <>
      <AppHeader />
      <div id={id} className="flex items-center w-screen p-4 Help">
        <h2 className="flex flex-col items-center p-2 text-white bg-gray-900 rounded-full w-28 h-28">
          <span className="text-yellow-400">Help and Support</span>
        </h2>
      </div>
    </>
  );
};
