import * as React from 'react';
const DeliveryVesselContainersIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DeliveryVesselContainersIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M30.5,50.86h1.98s0-2.97,0-2.97h-4.36v-2.38s-.47,0-.47,0v2.38h-3.08v-3.92s-1.98,0-1.98,0l-1.12,3.92h-2.47s0,2.97,0,2.97l1.58,1.5v16.93s7.8,0,7.8,0v-17.86l2.12-.56ZM31.99,49.98h-1.5v-1.5h1.5v1.5ZM29.96,49.98h-1.5v-1.5h1.5v1.5ZM28,49.98h-1.5v-1.5h1.5v1.5ZM25.86,49.98h-1.5v-1.5h1.5v1.5ZM27.51,54.99h-1.65s0-1.71,0-1.71h1.65s0,1.71,0,1.71ZM27.51,57.31h-1.65s0-1.7,0-1.7h1.65s0,1.7,0,1.7ZM27.51,59.63h-1.65s0-1.71,0-1.71h1.65s0,1.71,0,1.71ZM27.51,61.95h-1.65s0-1.7,0-1.7h1.65s0,1.7,0,1.7ZM27.51,64.28h-1.65s0-1.71,0-1.71h1.65s0,1.71,0,1.71ZM27.51,66.6h-1.65s0-1.7,0-1.7h1.65s0,1.7,0,1.7Z"
    />
    <path
      strokeWidth={0}
      d="M112.18,81.17H8.9s1.76,2.86,1.76,2.86h102.55s-1.04-2.86-1.04-2.86Z"
    />
    <path strokeWidth={0} d="M96.07,59.03h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M96.07,63.91h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M96.07,68.79h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M83.22,59.03h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M83.22,63.91h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M83.22,68.79h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M70.38,59.03h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M70.38,63.91h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M70.38,68.79h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M57.54,59.03h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M57.54,63.91h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M57.54,68.79h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M44.68,59.03h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M44.68,63.91h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M44.68,68.79h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M18.17,59.03H5.97v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M18.17,63.91H5.97v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M18.17,67.03H5.97v-2.6h12.2v2.6Z" />
    <path strokeWidth={0} d="M108.83,59.03h-12.2v-4.37h12.2v4.37Z" />
    <path strokeWidth={0} d="M108.83,63.91h-12.2v-4.37h12.2v4.37Z" />
    <path
      strokeWidth={0}
      d="M108.83,65.96h-6.5s-3.1,2.83-3.1,2.83h-2.6v-4.37h12.2v1.54Z"
    />
    <path
      strokeWidth={0}
      d="M.5,67.52l7.62,12.38h104.53s14.85-13.34,14.85-13.34h-24.45s-3.81,3.18-3.81,3.18H20.18s-2.22-2.22-2.22-2.22H.5ZM106.65,72.23c0-.61.54-1.11,1.22-1.11s1.21.5,1.21,1.11c0,.61-.54,1.11-1.21,1.11s-1.22-.5-1.22-1.11Z"
    />
  </svg>
);
export default DeliveryVesselContainersIcon;
