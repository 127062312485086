import * as React from 'react';
const TriangleOkIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TriangleOkIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,12.04L4,115.96h120L64,12.04ZM63.8,103.6c-.76.85-1.81,1.29-2.88,1.29-.84,0-1.69-.28-2.4-.84l-19.23-15.38c-1.66-1.33-1.93-3.75-.6-5.4,1.33-1.66,3.75-1.93,5.41-.6l16.38,13.1,18.34-32.89c1.41-1.59,3.84-1.73,5.43-.32,1.59,1.41,1.73,3.84.32,5.43l-20.77,35.62Z"
    />
  </svg>
);
export default TriangleOkIcon;
