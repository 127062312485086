import classNames from 'classnames';
import _ from 'lodash';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import Editor from 'react-simple-code-editor';

export type CodeSnippetProps = PropsWithChildren & {
  className?: string;
  children?: ReactNode;
};

// Function to convert children to a string recursively
const convertChildrenToString = (children: ReactNode): string => {
  if (Array.isArray(children)) {
    return children.map(convertChildrenToString).join('');
  } else if (typeof children === 'string') {
    return children;
  } else if (React.isValidElement(children)) {
    return convertChildrenToString((children as ReactElement).props.children);
  }
  return '';
};

const CodeSnippet: React.FC<CodeSnippetProps> = props => {
  // Convert props.children to a string
  const codeString = convertChildrenToString(props.children);

  return (
    <div
      data-component="CodeSnippet"
      className={classNames(
        'font-mono text-xs',
        'bg-app-panel-dark/60',
        'text-menu-text/60',
        'text-center',
        'break-all',
        'select-text',
        'px-2 py-4',
        'border-menu/30 border',
        'w-full',
        props.className,
      )}
    >
      <Editor
        value={codeString}
        disabled={true}
        onValueChange={_.noop}
        highlight={code => Prism.highlight(code, Prism.languages.sql, 'sql')}
        padding={10}
        style={{
          position: 'relative',
          overflowY: 'auto',
          fontFamily: 'monospace',
          fontSize: 12,
          paddingBottom: '3rem',
          whiteSpace: 'pre-wrap', // Ensures line breaks are preserved
        }}
      />
      {/* {props.children} */}
    </div>
  );
};

export default CodeSnippet;
