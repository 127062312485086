import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import OrderSetDataTable from './OrderSetDataTable';
import OrderSetFilterTag from './OrderSetFilterTag';
import { orderSetDocument } from './store/orderSet.state';

const OrderSetDataView: React.FC = () => {
  const orderSet = useRecoilValue(orderSetDocument);
  const { t } = useTranslation('dataset');
  return (
    <Container
      componentName="OrderSetDataView"
      col
      overflow
      className={classNames(
        'flex-1 ltr:border-l rtl:border-r border-app-panel-dark relative',
      )}
    >
      <PanelHeader
        title={t`Orders Data Table`}
        filterPlaceholder={<OrderSetFilterTag />}
      />
      <OrderSetDataTable id={orderSet?.id} title={orderSet?.title ?? '—'} />
    </Container>
  );
};

export default OrderSetDataView;
