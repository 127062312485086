import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { currentUserRoleIdOverride } from './store/role.state';

const RoleParamWatcher: React.FC = () => {
  const [searchParams] = useSearchParams();
  const roleName = searchParams.get('role');
  const setRoleOverride = useSetRecoilState(currentUserRoleIdOverride);

  useEffect(() => {
    if (!_.isEmpty(roleName)) {
      setRoleOverride(roleName);
    }
  }, [roleName]);
  return null;
};

export default RoleParamWatcher;
