import { ResponsiveBar } from '@nivo/bar';
import { patternDotsDef, patternLinesDef } from '@nivo/core';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { applyColorOpacity, getQualitativeColor } from '../common/color.helper';
import { formatInteger } from '../common/formatHelper';
import useFormatter from '../common/useFormatter';
import { LoadBalancingKPI } from './AnalyzeResultLoadBalancingKPISwitch';

interface ValueBeforeAfterProps {
  title?: string;
  beforeDiff?: string;
  afterDiff?: string;
  className?: string;
  classNameBefore?: string;
  classNameAfter?: string;
}

export const ValueBeforeAfter: React.FC<ValueBeforeAfterProps> = props => {
  const valueStyle = classNames(
    'py-2 px-3',
    'flex-1',
    'text-menu-text',
    'w-20 truncate',
  );

  return (
    <div
      data-component="ValueBeforeAfter"
      className={classNames(
        'flex flex-col md:flex-row items-center',
        'w-full',
        'bg-sidebar-header backdrop-blur',
        'border-b border-b-menu',
        props.className,
      )}
    >
      <div
        data-component="title"
        className={classNames('p-2 text-xxs uppercase flex-1')}
      >
        {props.title}
      </div>
      <div
        data-component="stat"
        className={classNames('flex flex-1', 'divide-x divide-menu')}
      >
        {!(props.beforeDiff === 'NaN' || props.beforeDiff === 'N/A') &&
          props.beforeDiff && (
            <div
              data-component="value-before"
              className={classNames(valueStyle, props.classNameBefore)}
            >
              <span>{props.beforeDiff}</span>
            </div>
          )}
        {!(props.afterDiff === 'NaN' || props.afterDiff === 'N/A') &&
          props.afterDiff && (
            <div
              data-component="value-after"
              className={classNames(valueStyle, props.classNameAfter)}
            >
              <span>{props.afterDiff}</span>
            </div>
          )}
      </div>
    </div>
  );
};

export const loadBalancingChartSeries = ['before', 'after'] as const;
export type LoadBalancingChartSeries =
  (typeof loadBalancingChartSeries)[number];

export type LoadBalancingChartDataRow = {
  zoneId: string;
  zoneTitle: string;
  beforeAbsolute: number;
  afterAbsolute: number;
  beforeRelative: number;
  afterRelative: number;
};

export type LoadBalancingChartProps = {
  data: LoadBalancingChartDataRow[];
  series: LoadBalancingChartSeries[];
  kpi: LoadBalancingKPI;
  max: number;
  min: number;
  avg?: number;
  avg2?: number;
};

const LoadBalancingChart: React.FC<LoadBalancingChartProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const chartSeries = props.series.map(s => `${s}${_.upperFirst(props.kpi)}`);

  return (
    <ResponsiveBar
      margin={{ top: 10, right: 10, bottom: 40, left: 65 }}
      data={props.data}
      indexBy={'zoneId'}
      keys={chartSeries}
      groupMode="grouped"
      layout="horizontal"
      enableGridY={true}
      enableGridX={false}
      colors={datum => {
        const color = getQualitativeColor(datum.data.zoneId, 'policy')?.[0];
        return datum.id.toString().startsWith('before')
          ? applyColorOpacity(color, 0.6)
          : color;
      }}
      // labelTextColor={datum => getPolicyColor(datum.data.data.zoneId)?.[1]}
      // labelSkipHeight={14}
      enableLabel={false}
      padding={0.2}
      defs={[
        patternDotsDef('dots', {
          background: 'inherit',
          color: 'white',
          size: 4,
          padding: 4,
          stagger: true,
        }),

        patternLinesDef('lines-pattern', {
          spacing: 5,
          rotation: 45,
          lineWidth: 1,
          background: 'inherit',
          color: 'rgba(43, 48, 52, 0.8)',
        }),
      ]}
      fill={[
        // {
        //   match: node => {
        //     return node.data.id === 'before';
        //   },
        //   id: 'dots',
        // },
        {
          match: node => {
            return node.data.id.toString().includes('after');
          },
          id: 'lines-pattern',
        },
      ]}
      markers={[
        // legend: 'Std.Deviation',
        {
          axis: 'x',
          value: props.avg2,
          lineStyle: {
            stroke: 'rgba(162,188,204, 0.7)',
            strokeWidth: 1,
            strokeDasharray: '2,2',
          },
        },
        {
          axis: 'x',
          value: props.avg,
          lineStyle: {
            stroke: 'rgba(255,206,0,0.8)',
            strokeWidth: 1,
            strokeDasharray: '2,2',
          },
        },
        {
          axis: 'x',
          value: '0',
          lineStyle: { stroke: 'rgba(255, 255, 255,  0.5)', strokeWidth: 2 },
          // textStyle: { fill: '#e25c3b' },
          // legend: 'loss',
          // legendPosition: 'bottom-left',
          // legendOrientation: 'vertical',
          // legendOffsetY: 120,
        } as const,
      ]}
      theme={{
        tooltip: {
          container: {
            zIndex: 1000, // this looks like not work as expected
          },
        },
        axis: {
          ticks: {
            text: {
              fontSize: 11,
              fill: 'rgba(255, 255, 255,  0.9)',
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fill: 'rgba(255, 255, 255,  0.9)',
            },
          },
        },

        grid: {
          line: {
            stroke: 'rgba(162,188,204, 0.5)',
            strokeWidth: 0.5,
          },
        },

        // crosshair: {
        //   line: {
        //     stroke: 'rgba(0, 0, 0,  0.7)',
        //     strokeWidth: 3,
        //     // simulate line will dash stroke when index is even
        //     strokeDasharray: '3, 3',
        //   },
        // },
      }}
      axisBottom={{
        tickValues: _.uniq([props.min, props.max, 0]),
        // legend: t`Offset`,
        // legendOffset: 40,
        // legendPosition: 'middle',
        //format: '.0%',
        //renderTick: CustomTick,
      }}
      axisLeft={{
        format: zoneId =>
          _.find(props.data, r => r.zoneId === zoneId)?.zoneTitle ?? zoneId,
      }}
      isInteractive={true}
      layers={[
        'grid',
        'markers',
        'bars',
        'axes',
        'legends',
        // props => <ValueOutside {...props} />,
      ]}
      tooltip={datum => {
        const title = datum.data.zoneTitle;
        const value = datum.formattedValue;
        const d = datum.data;
        const beforeAvg = d.beforeAbsolute - d.beforeRelative;
        const afterAvg = d.afterAbsolute - d.afterRelative;

        const beforeDiffShare = formatter.formatShare(
          Math.abs((d.beforeRelative ?? 0) / (beforeAvg ?? 1)),
        );

        const afterDiffShare = formatter.formatShare(
          Math.abs((d.afterRelative ?? 0) / (afterAvg ?? 1)),
        );

        return (
          <div
            data-component="StackedBarTooltip"
            className={classNames(
              'w-full flex flex-col z-dropdown_menu',
              'animate-appear invisible',
              'bg-app-panel-dark/90 backdrop-filter backdrop-blur',
            )}
            style={{
              color: datum.color,
            }}
          >
            <div
              className="p-2 text-menu-text"
              style={{
                backgroundColor: datum.color,
              }}
            >
              <span>{title}</span>
            </div>

            <div
              className={classNames(
                'flex flex-col w-full flex-1',
                'text-menu-text',
              )}
            >
              <ValueBeforeAfter
                title={``}
                beforeDiff={
                  loadBalancingChartSeries[0] === 'before' ? t`Before` : null
                }
                afterDiff={
                  loadBalancingChartSeries[1] === 'after' ? t`After` : null
                }
                className="p-1"
                classNameBefore={classNames(
                  'text-xxs uppercase',
                  'py-0.5 px-1 m-0.5',
                  'bg-app-panel rounded',
                  'items-center',
                )}
                classNameAfter={classNames(
                  'text-xxs uppercase',
                  'py-0.5 px-1 m-0.5',
                  'bg-app-panel rounded',
                  'items-center',
                )}
              />

              <ValueBeforeAfter
                title={t`Total Visits:`}
                beforeDiff={formatInteger(datum.data.beforeAbsolute)}
                afterDiff={formatInteger(datum.data.afterAbsolute)}
              />

              <ValueBeforeAfter
                title={t`Relative Change:`}
                beforeDiff={formatInteger(datum.data.beforeRelative)}
                afterDiff={formatInteger(datum.data.afterRelative)}
              />

              <ValueBeforeAfter
                title={t`Offset (%):`}
                beforeDiff={beforeDiffShare.fullString}
                afterDiff={afterDiffShare.fullString}
              />
              {/*
              <ValueBeforeAfter
                title={t`Average (%):`}
                beforeDiff={formatInteger(beforeAvg)}
                afterDiff={formatInteger(afterAvg)}
              /> */}
            </div>
          </div>
        );
      }}
    />
  );
};

export default LoadBalancingChart;
