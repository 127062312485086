import { AllocationRuleFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import SectionOptional from '../../../../components/layout/SectionOptional';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

const AllocationPolicyLimitsVolumeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  function updateAllocationSettings(patch: Partial<AllocationRuleFragment>) {
    updateRule(current => ({
      ...current,
      ...patch,
    }));
  }

  return (
    <TitleSection
      id={`policy-editor-limits-volume`}
      title={t`Allocation Volume Limits`}
      inSidebarView
      hasScreenTitle
    >
      <InputGroupList className="space-y-2">
        {/* ALLOCATED VOLUME*/}
        <SectionOptional
          id={'allocation-limit-by-allocated-volume'}
          title={t`Limit by allocation volume`}
          value={!_.isNil(rule?.limitSettings?.maxAllocatedVolume)}
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...rule?.limitSettings,
                maxAllocatedVolume: enabled ? 1000 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max allocation volume`}
            value={rule?.limitSettings?.maxAllocatedVolume}
            range={[0, 1000000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...rule?.limitSettings,
                  maxAllocatedVolume: v,
                },
              })
            }
          />
        </SectionOptional>

        {/* PICKABLE VOLUME*/}
        <SectionOptional
          id={'allocation-limit-by-pickable-volume'}
          title={t`Limit by pickable volume`}
          value={!_.isNil(rule?.limitSettings?.maxPickableVolume)}
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...rule?.limitSettings,
                maxPickableVolume: enabled ? 1000 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max pickable volume`}
            value={rule?.limitSettings?.maxPickableVolume}
            range={[0, 1000000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...rule?.limitSettings,
                  maxPickableVolume: v,
                },
              })
            }
          />
        </SectionOptional>
      </InputGroupList>
    </TitleSection>
  );
};

export default AllocationPolicyLimitsVolumeEditor;
