import { useApolloClient } from '@apollo/client';
import {
  LoadLocationDataDocument,
  LoadLocationDataQuery,
  LoadLocationDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { simulationLayoutSelectedLocationZoom } from '../../simulation/store/simulation.layout.state';
import { errorAppender } from '../../store/error.state';
import {
  viewerLocationDataLoadStatus,
  viewerSelectedBayIdAtom,
  viewerSelectedLevelInner,
  viewerSelectedPlaneId,
} from '../viewer/store/viewer.state';

export type SelectLocationByIdParams = {
  layoutId: string;
  locationId: string;
};

function useSelectLocationById() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t('Can not Select Location {{locationId}}', {
    locationId: '{{locationId}}',
  });
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(viewerLocationDataLoadStatus, AsyncLoadStatus.Loading);
  });

  const selectLocationByIdCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: SelectLocationByIdParams) => {
        const query = client.watchQuery<
          LoadLocationDataQuery,
          LoadLocationDataQueryVariables
        >({
          query: LoadLocationDataDocument,
          variables: {
            layoutId: params.layoutId,
            locationId: params.locationId,
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(viewerLocationDataLoadStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const location = _.head(data.layout.locations.content);

            if (_.isNil(location)) return;

            set(viewerLocationDataLoadStatus, AsyncLoadStatus.Ok);
            set(viewerSelectedPlaneId, location.planeId);
            set(viewerSelectedLevelInner, location.locationLevel);
            set(viewerSelectedBayIdAtom, location.locationBayId);
            set(simulationLayoutSelectedLocationZoom, location);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: SelectLocationByIdParams) {
    await initLoading();
    await selectLocationByIdCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useSelectLocationById;
