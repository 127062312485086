import { RoutingPolicyFeatureRuleFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DropdownSelector from '../components/actions/DropdownSelector';
import {
  getDirectionDescriptorsMapById,
  getDirectionDescriptorsMapByVector,
  getVectorKey,
} from '../simulation/store/routingPolicy/routingPolicy.default';
import { routingPolicyFeatureRestrictions } from '../simulation/store/routingPolicy/routingPolicy.state';
import {
  DirectionDescriptorId,
  directionDescriptorIds,
} from '../simulation/store/routingPolicy/routingPolicy.types';
import RoutingPolicyDirectionOption from './RoutingPolicyDirectionOption';

export type RoutingPolicyDirectionSelectProps = {
  featureId: string;
};

const RoutingPolicyDirectionSelect: React.FC<
  RoutingPolicyDirectionSelectProps
> = props => {
  const { t } = useTranslation('simulation');
  const [feature, setFeature] = useRecoilState(
    routingPolicyFeatureRestrictions(props.featureId),
  );

  function onChange(directionId: DirectionDescriptorId) {
    const directions = getDirectionDescriptorsMapById(t);
    const vector = directions[directionId]?.value;
    const newFeatureRule: RoutingPolicyFeatureRuleFragment = _.isNil(vector)
      ? null
      : {
          ...(feature ?? {}),
          featureId: props.featureId,
          direction: vector,
        };

    console.log('#newFeatureRule: ', directionId, vector, newFeatureRule);
    setFeature(newFeatureRule);
  }

  const directions = getDirectionDescriptorsMapByVector(t);
  const vectorKey = getVectorKey(feature?.direction);
  const selected = directions[vectorKey]?.id;

  return (
    <DropdownSelector
      className="bg-app-panel-dark/60 group-hover:bg-menu-active group-hover:text-menu-active-text"
      classNameValue="text-xs"
      DropAlignLeft
      // buttonTransparent
      value={selected}
      values={[...directionDescriptorIds]}
      renderValue={directionId => (
        <RoutingPolicyDirectionOption
          key={`option-${directionId}`}
          directionId={directionId}
          sourceVector={feature?.direction}
        />
      )}
      onChange={onChange}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
};

export default RoutingPolicyDirectionSelect;
