import {
  AssignmentDashboardItemFragment,
  DatasetObjectFragment,
  ItemSetDashboardItemFragment,
  LayoutDashboardItemFragment,
  LayoutImportDashboardItemFragment,
  OrderSetDashboardItemFragment,
  SimulationDashboardItemFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { atom } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import { getDefaultDashboardState } from './dashboard.default';
import { DashboardGroupState } from './dashboard.types';

const getKey = (postfix: string) => `warebee-dashboard-${postfix}`;

export const dashboardSimulationState = persistAtom<DashboardGroupState>({
  key: getKey('sim-state'),
  default: getDefaultDashboardState(),
});

export const dashboardSimulationData = atom<SimulationDashboardItemFragment[]>({
  key: getKey('sim-data'),
  default: null,
});

export const dashboardSimulationLoadState = atom<AsyncLoadStatus>({
  key: getKey('sim-load-status'),
  default: AsyncLoadStatus.None,
});

export const showHelperCardState = persistAtom({
  key: 'showHelperCardState',
  default: false,
});

export const showHeaderAddButtonState = persistAtom({
  key: 'showHeaderAddButton',
  default: false,
});

export const showAutoUpdateDateTimeState = persistAtom({
  key: 'showAutoUpdateDateTime',
  default: false,
});

// Layout import

export const dashboardLayoutImportState = persistAtom<DashboardGroupState>({
  key: getKey('layout-import-state'),
  default: getDefaultDashboardState(),
});

export const dashboardLayoutImportData = atom<
  LayoutImportDashboardItemFragment[]
>({
  key: getKey('layout-import-data'),
  default: null,
});

export const dashboardLayoutImportLoadState = atom<AsyncLoadStatus>({
  key: getKey('layout-import-load-status'),
  default: AsyncLoadStatus.None,
});

export const dashboardLayoutState = persistAtom<DashboardGroupState>({
  key: getKey('layout-state'),
  default: getDefaultDashboardState(),
});

export const dashboardLayoutData = atom<
  (LayoutDashboardItemFragment | LayoutImportDashboardItemFragment)[]
>({
  key: getKey('layout-data'),
  default: null,
});

export const dashboardLayoutLoadState = atom<AsyncLoadStatus>({
  key: getKey('layout-load-status'),
  default: AsyncLoadStatus.None,
});

//Assignment
export const dashboardAssignmentState = persistAtom<DashboardGroupState>({
  key: getKey('assignment-state'),
  default: getDefaultDashboardState(),
});

export const dashboardAssignmentData = atom<AssignmentDashboardItemFragment[]>({
  key: getKey('assignment-data'),
  default: null,
});

export const dashboardAssignmentLoadState = atom<AsyncLoadStatus>({
  key: getKey('assignment-load-status'),
  default: AsyncLoadStatus.None,
});

//Order set

export const dashboardOrderSetState = persistAtom<DashboardGroupState>({
  key: getKey('order-set-state'),
  default: getDefaultDashboardState(),
});

export const dashboardOrderSetData = atom<OrderSetDashboardItemFragment[]>({
  key: getKey('order-set-data'),
  default: null,
});

export const dashboardOrderSetLoadState = atom<AsyncLoadStatus>({
  key: getKey('order-set-load-status'),
  default: AsyncLoadStatus.None,
});

//Item set

export const dashboardItemSetState = persistAtom<DashboardGroupState>({
  key: getKey('item-set-state'),
  default: getDefaultDashboardState(),
});

export const dashboardItemSetData = atom<ItemSetDashboardItemFragment[]>({
  //ItemSetDashboardItemFragment
  key: getKey('item-set-data'),
  default: null,
});

export const dashboardItemSetLoadState = atom<AsyncLoadStatus>({
  key: getKey('item-set-load-status'),
  default: AsyncLoadStatus.None,
});

//Dataset objects

export const dashboardDatasetObjectState = persistAtom<DashboardGroupState>({
  key: getKey('dataset-object-state'),
  default: getDefaultDashboardState(),
});

export const dashboardDatasetObjectData = atom<DatasetObjectFragment[]>({
  key: getKey('dataset-object-data'),
  default: null,
});

export const dashboardDatasetObjectLoadState = atom<AsyncLoadStatus>({
  key: getKey('dataset-object-load-status'),
  default: AsyncLoadStatus.None,
});
