import {
  BatchJobStatus,
  SizeComplianceLocationDetailsFragment,
  SizeComplianceLocationStatusFragment,
  SizeComplianceMetaFragment,
  SizeComplianceStatus,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  viewerSelectedBay,
  viewerSelectedLevel,
  viewerSelectedLocationIdAtom,
  viewerSelectedPlaneId,
} from '../../layout/viewer/store/viewer.state';
import { SizeComplianceLocationTableColumn } from '../panels/compliance/size/SizeComplianceLocationsTable';
import { simulationCurrent } from './simulation.state';
import {
  SizeComplianceDataState,
  SizeComplianceLocationsData,
} from './simulation.types';
import {
  simulationComplianceTabKey,
  simulationWizardSelectedStepId,
} from './simulation.wizard.state';
import {
  loadLocationSizeComplianceDetails,
  loadLocationSizeHeatmap,
} from './sizeCompliance.helper';

const getKey = (postfix: string) =>
  `warego-simulation-size-compliance-${postfix}`;

export const sizeComplianceMeta = atom<SizeComplianceMetaFragment>({
  key: getKey('meta'),
  default: null,
});

export const sizeComplianceMetaLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('summary-load-status'),
  default: AsyncLoadStatus.Ok,
});

const sizeComplianceShowHeatmapAtom = atom<boolean>({
  key: getKey('show-heatmap-atom'),
  default: true,
});

export const sizeComplianceShowHeatmap = selector<boolean>({
  key: getKey('show-heatmap'),
  get: ({ get }) => get(sizeComplianceShowHeatmapAtom),
  set: ({ set }, value) => {
    set(sizeComplianceShowHeatmapAtom, value);
  },
});

export const sizeComplianceLocationsData = atom<SizeComplianceLocationsData>({
  key: getKey('locations-data'),
  default: null,
});

export const sizeComplianceLocationsState = atom<
  SizeComplianceDataState<SizeComplianceLocationTableColumn>
>({
  key: getKey('locations-data-state'),
  default: {
    searchValues: {
      status: SizeComplianceStatus.NONCOMPLIANT,
    },
    sortValues: {
      locationId: SortDirection.ASC,
    },
  },
});

export const sizeComplianceDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('data-load-status'),
  default: AsyncLoadStatus.None,
});

export const sizeComplianceLevelLocation = selector<
  Record<string, SizeComplianceLocationStatusFragment>
>({
  key: getKey('locations-size-by-level'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const compliance = get(sizeComplianceMeta);
    const areaId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);

    if (
      compliance?.status !== BatchJobStatus.READY ||
      _.isNil(level) ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-size'
    )
      return null;

    const locationMap = await loadLocationSizeHeatmap({
      simulationId: sim.id,
      areaId,
      level,
    });
    return locationMap;
  },
});

export const sizeComplianceBayLocation = selector<
  Record<string, SizeComplianceLocationStatusFragment>
>({
  key: getKey('locations-size-by-bay'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const compliance = get(sizeComplianceMeta);
    const areaId = get(viewerSelectedPlaneId);
    const bayId = get(viewerSelectedBay)?.id;
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);
    if (
      compliance?.status !== BatchJobStatus.READY ||
      _.isNil(bayId) ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-size'
    )
      return null;

    const locationMap = await loadLocationSizeHeatmap({
      simulationId: sim.id,
      areaId,
      bayId,
    });
    return locationMap;
  },
});

export const sizeComplianceSelectedLocation =
  selector<SizeComplianceLocationDetailsFragment>({
    key: getKey('selected-location-full-info'),
    get: async ({ get }) => {
      const sim = get(simulationCurrent);
      const compliance = get(sizeComplianceMeta);
      const areaId = get(viewerSelectedPlaneId);
      const locationId = get(viewerSelectedLocationIdAtom);
      const stepId = get(simulationWizardSelectedStepId);
      const tab = get(simulationComplianceTabKey);
      if (
        compliance?.status !== BatchJobStatus.READY ||
        _.isNil(locationId) ||
        stepId !== 'compliance' ||
        tab !== 'tab-compliance-size'
      )
        return null;

      const locationData = await loadLocationSizeComplianceDetails({
        simulationId: sim.id,
        areaId,
        locationId,
      });
      return locationData;
    },
  });
