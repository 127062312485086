import Flatten from '@flatten-js/core';
import { LayoutImportLocation } from '@warebee/shared/data-access-layout-import-converter';
import {
  BayLocationOrder,
  BayTypeModel,
  DEFAULT_BAY_TYPE,
  DEFAULT_LOCATION_RACKING_TYPE,
  Location,
  LocationPortalSpec,
} from '@warebee/shared/engine-model';
import { groupBy, identity, range, sortBy } from 'lodash';
import {
  BindBayTypes,
  BindLocationTypes,
  LocationDepthBinding,
  VisualAisle,
  VisualBay,
  VisualLocation,
  VisualLocationBinding,
  VisualLocationPortal,
  VisualLocationPortals,
} from './visual-layout-map.model';
export type GroupableBayLocation = Pick<
  Location,
  'locationLevel' | 'locationBayPosition' | 'locationDepthPosition'
>;

export interface BayLocationVisitor<L extends GroupableBayLocation> {
  beforeLevel?: (level: number, locations: L[]) => void;
  afterLevel?: (level: number, locations: L[]) => void;
  beforeRow?: (level: number, row: number, locations: L[]) => void;
  afterRow?: (level: number, row: number, locations: L[]) => void;
  onTier?: (
    level: number,
    row: number,
    tier: number,
    tierIndex: number,
    locations: L[],
    isLastTier: boolean,
  ) => void;
  sortLevels?: (levels: number[]) => number[];
  sortRows?: (rows: number[]) => number[];
  sortTiers?: (tiers: number[]) => number[];
}

export function visitBayLocations<L extends GroupableBayLocation>(
  locations: L[],
  visitor: BayLocationVisitor<L>,
) {
  const locationsByLevel = groupBy(locations, loc => loc.locationLevel);

  const levelKeys = (visitor.sortLevels ?? identity)(
    Object.keys(locationsByLevel).map(level => parseInt(level)),
  );

  for (const levelKey of levelKeys) {
    const levelLocs = locationsByLevel[levelKey];

    if (visitor.beforeLevel) {
      visitor.beforeLevel(levelKey, levelLocs);
    }

    const locationsByRow = groupBy(levelLocs, loc => loc.locationBayPosition);

    const rowKeys = (visitor.sortRows ?? identity)(
      Object.keys(locationsByRow).map(row => parseInt(row)),
    );

    for (const rowKey of rowKeys) {
      const rowLocs = locationsByRow[rowKey];

      if (visitor.beforeRow) {
        visitor.beforeRow(levelKey, rowKey, rowLocs);
      }

      const locationsByTier = groupBy(
        rowLocs,
        loc => loc.locationDepthPosition,
      );

      const tierKeys = (visitor.sortTiers ?? identity)(
        Object.keys(locationsByTier).map(tier => parseInt(tier)),
      );

      const lastTierIndex = tierKeys.length - 1;
      for (const tierIndex of range(tierKeys.length)) {
        const tierKey = tierKeys[tierIndex];
        const tierLocs = locationsByTier[tierKey];

        if (visitor.onTier) {
          visitor.onTier(
            levelKey,
            rowKey,
            tierKey,
            tierIndex,
            tierLocs,
            tierIndex == lastTierIndex,
          );
        }
      }

      if (visitor.beforeRow) {
        visitor.afterRow(levelKey, rowKey, rowLocs);
      }
    }

    if (visitor.afterLevel) {
      visitor.afterLevel(levelKey, levelLocs);
    }
  }
}

export type SizeableLocation = {
  locationRackingType: string;
  locationWidth: number;
  locationLength: number;
};

export type SizeableBayLocation = GroupableBayLocation & SizeableLocation;

function getEffectiveLocationSize(
  loc: SizeableLocation,
  locationTypes: BindLocationTypes,
): [number, number, number] {
  const effectiveSettings =
    locationTypes[loc.locationRackingType] ||
    locationTypes[DEFAULT_LOCATION_RACKING_TYPE] ||
    {};

  return [
    loc.locationWidth,
    effectiveSettings.isWeakDepth
      ? Math.max(
          effectiveSettings.minDepth ?? 1,
          effectiveSettings.gapDepth ?? 1,
        )
      : loc.locationLength + (effectiveSettings.gapDepth || 0),
    effectiveSettings.gapWidth || 0,
  ];
}

export function getEffectiveBaySize(
  allBayLocations: SizeableBayLocation[],
  bayType: Pick<BayTypeModel, 'verticalFrameProfile'>,
  locationTypes: BindLocationTypes,
): [number, number] {
  let width = 0;
  let depth = 0;

  let levelWidth = 0;
  let rowWidth = 0;
  let rowDepth = 0;

  let prevRowGapWidth = 0;
  let beforeRowGapWidth = 0;
  let afterRowGapWidth = 0;

  visitBayLocations(allBayLocations, {
    beforeLevel: () => {
      levelWidth = (bayType.verticalFrameProfile || 0) * 2;
      prevRowGapWidth = 0;
    },
    beforeRow: (level, row, locs) => {
      rowDepth = 0;
      rowWidth = 0;
      beforeRowGapWidth = 0;
      afterRowGapWidth = 0;
    },
    onTier: (
      level,
      row,
      tier,
      tierIndex,
      locs: SizeableBayLocation[],
      isLastTier,
    ) => {
      let tierWidth = 0;
      let tierDepth = 0;

      let prevLocationGapWidth = 0;

      for (let i = 0; i < locs.length; i++) {
        const loc = locs[i];
        const [locWidth, locDepth, gapWidth] = getEffectiveLocationSize(
          loc,
          locationTypes,
        );
        tierWidth += locWidth;
        if (i == 0) {
          // first location in row-tier: its "before" gap affects "before row" gap
          beforeRowGapWidth = Math.max(gapWidth, beforeRowGapWidth);
        } else {
          // gap between locations in row-tier - combine current and previous
          tierWidth += Math.max(gapWidth, prevLocationGapWidth);
        }
        tierDepth = Math.max(tierDepth, locDepth);
        // after location gap
        prevLocationGapWidth = gapWidth;
      }

      // gap after last location - include into after row gap
      afterRowGapWidth = Math.max(afterRowGapWidth, prevLocationGapWidth);

      rowWidth = Math.max(rowWidth, tierWidth);
      rowDepth += tierDepth;
    },
    afterRow: (level, row, locs) => {
      // apply before row gap
      rowWidth += Math.max(prevRowGapWidth, beforeRowGapWidth);

      // keep after row gap for the next row
      prevRowGapWidth = afterRowGapWidth;
      levelWidth += rowWidth;
      depth = Math.max(depth, rowDepth);
    },
    afterLevel: () => {
      // apply after gap of the last row
      levelWidth += prevRowGapWidth;
      width = Math.max(width, levelWidth);
    },
  });

  return [width, depth];
}

export function calculateLocationPortalPosition(
  locationCenter: Flatten.Point,
  aisleShape: Flatten.Polygon,
): Flatten.Point {
  if (aisleShape.contains(locationCenter)) {
    return locationCenter;
  } else {
    const [distance, segment] = locationCenter.distanceTo(aisleShape);
    return segment.pe;
  }
}

function generateLocationPortal(
  locationShape: Flatten.Polygon,
  portalSpec: LocationPortalSpec,
  aislesById: Record<string, VisualAisle>,
): VisualLocationPortal {
  const aisle = aislesById[portalSpec.aisleId];
  if (!aisle) {
    throw new Error(
      `aisle ${portalSpec.aisleId} not found in ${Object.keys(aislesById)}`,
    );
  }

  const aisleShape = aisle.shape;
  const locationCenter = locationShape.box.center;

  const { aisleId, ...portalDetails } = portalSpec;

  return {
    aisleId,
    position: calculateLocationPortalPosition(locationCenter, aisleShape),
    ...portalDetails,
  };
}

export function generateLocationPortals(
  loc: VisualLocation<any>,
  portalSpecs: LocationPortalSpec[],
  aislesById: Record<string, VisualAisle>,
): VisualLocation<any> & VisualLocationPortals {
  const portals = portalSpecs.map(p =>
    generateLocationPortal(loc.shape, p, aislesById),
  );
  return { ...loc, portals };
}

export type BindableLocation = Pick<
  LayoutImportLocation,
  | 'locationId'
  | 'locationLevel'
  | 'locationBayPosition'
  | 'locationDepthPosition'
  | 'locationWidth'
  | 'locationLength'
  | 'locationRackingType'
>;

export function bindBayLocations<L extends BindableLocation>(
  bay: VisualBay,
  allBayLocations: L[],
  bayTypes: BindBayTypes,
  locationTypes: BindLocationTypes,
): (VisualLocation<L> & LocationDepthBinding)[] {
  const effectiveFrameProfile =
    bayTypes[bay.bayType ?? DEFAULT_BAY_TYPE]?.verticalFrameProfile || 0;

  const reverse = bay.locationOrder === BayLocationOrder.RTL;
  const direction = reverse ? -1 : 1;

  const frontEdgeUnit = bay.frontEdge.normalize();
  const sideEdgeUnit = bay.frontEdge.rotate90CCW().normalize();

  function bindLocation(
    loc: L,
    projection: number,
    depthOffset: number,
    effectiveLocationDepth: number,
  ): VisualLocationBinding {
    const portal = bay.position.translate(frontEdgeUnit.multiply(projection));
    const shapeAnchor = portal.translate(sideEdgeUnit.multiply(depthOffset));

    const p0 = shapeAnchor.translate(
      frontEdgeUnit.multiply(-loc.locationWidth / 2),
    ) as never as Flatten.Point;
    const p1 = shapeAnchor.translate(
      frontEdgeUnit.multiply(loc.locationWidth / 2),
    ) as never as Flatten.Point;
    const side = sideEdgeUnit.multiply(effectiveLocationDepth);
    const p2 = p1.translate(side) as never as Flatten.Point;
    const p3 = p0.translate(side) as never as Flatten.Point;

    const shape = new Flatten.Polygon([p0, p1, p2, p3]);

    return {
      locationBayProjection: projection,
      locationHeightFromFloor:
        bay.bayLevelMap[loc.locationLevel]?.heightFromFloor ?? 0, //TODO: AIIAK: provide updated bay level map
      shape,
    };
  }

  function bindLocationsTier(
    locations: L[],
    positionOffset: number,
    beforeRowGapWidth: number,
    depthOffset: number,
    tierIndex: number,
    lastTier: boolean,
  ): [(VisualLocation<L> & LocationDepthBinding)[], number, number, number] {
    let currentProjection = 0;
    let tierDepth = 0;
    let afterTierGapWidth = 0;

    // use before row gap as the first gap between locations
    let prevLocationGapWidth = beforeRowGapWidth;

    const boundLocs = sortBy(locations, loc => loc.locationId).map(
      (loc: L, index: number) => {
        const effectiveLocationModel =
          locationTypes[loc.locationRackingType] ||
          locationTypes[DEFAULT_LOCATION_RACKING_TYPE] ||
          {};
        const effectiveGapWidth = effectiveLocationModel.gapWidth || 0;

        if (index == locations.length - 1) {
          // last location in row-tier - affects after tier gap
          afterTierGapWidth = effectiveGapWidth;
        }

        const effectiveLocationDepth = loc.locationLength;
        // if (effectiveLocationModel.isWeakDepth && lastTier) {
        //   effectiveLocationDepth = Math.max(
        //     0,
        //     bay.depth - (effectiveLocationModel.gapDepth || 0),
        //   );
        // }

        tierDepth = Math.max(
          tierDepth,
          effectiveLocationDepth + (effectiveLocationModel.gapDepth || 0),
        );

        // gap between locations
        currentProjection +=
          direction * Math.max(prevLocationGapWidth, effectiveGapWidth);

        const projection =
          positionOffset +
          currentProjection +
          direction * (loc.locationWidth / 2);

        currentProjection += direction * loc.locationWidth;

        // after location gap
        prevLocationGapWidth = effectiveGapWidth;

        return {
          ...loc,
          ...bindLocation(loc, projection, depthOffset, effectiveLocationDepth),
          locationDepthFromFront: depthOffset,
          locationIndexFromFront: tierIndex,
        };
      },
    );

    return [
      boundLocs,
      Math.abs(currentProjection),
      tierDepth,
      afterTierGapWidth,
    ];
  }

  const boundLocations = [];

  let currentProjection = 0;
  let rowWidth = 0;
  let depthOffset = 0;

  let prevRowGapWidth = 0;
  let afterRowGapWidth = 0;

  visitBayLocations(allBayLocations, {
    beforeLevel: (level, locs) => {
      currentProjection = reverse ? bay.width : 0;
      currentProjection += direction * effectiveFrameProfile;
      prevRowGapWidth = 0;
    },
    beforeRow: (level, row, locs) => {
      rowWidth = 0;
      depthOffset = 0;
      afterRowGapWidth = 0;
    },
    afterRow: (level, row, locs) => {
      currentProjection += direction * rowWidth;
      // apply after row gap
      prevRowGapWidth = afterRowGapWidth;
    },
    onTier: (level, row, tier, tierIndex, locations, isLastTier) => {
      const [boundLocs, tierRowWidth, tierDepth, afterTierGapWidth] =
        bindLocationsTier(
          locations as any,
          currentProjection,
          prevRowGapWidth,
          depthOffset,
          tierIndex,
          isLastTier,
        );
      depthOffset += tierDepth;
      rowWidth = Math.max(rowWidth, tierRowWidth);
      boundLocations.push(...boundLocs);
      // include after tier gap into after row gap
      afterRowGapWidth = Math.max(afterRowGapWidth, afterTierGapWidth);
    },
    sortRows: rows => sortBy(rows),
    sortTiers: tiers => sortBy(tiers),
  });

  return boundLocations;
}
