import React from 'react';
import { useTranslation } from 'react-i18next';
import InboxZero from '../../components/InboxZero';
import { Container } from '../../components/layout/ContainerFlex';

/**
 * This component is used on "Resource policy" step in central pane,
 * when no agent is selected;
 */
const ResourcePolicyEmptyView: React.FC = () => {
  const { t } = useTranslation('simulation');
  return (
    <Container col>
      <InboxZero
        selfCenter
        hasIcon
        message={t`Please add or select a resource`}
      />
    </Container>
  );
};

export default ResourcePolicyEmptyView;
