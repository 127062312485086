import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import HelpContainer from '../../helpContext/HelpContainer';

const SimulationHelperOptimisation: React.FC = () => {
  const { t } = useTranslation('help');

  return (
    <Container col hasOverflowY className="relative">
      <ScreenTitle
        title={t`Warehouse Optimisation`}
        subtitle={t`Getting Started`}
        isSticky
      />
      <HelpContainer
        id={'simulation/optimise/simulation-optimise'}
        hasPadding
      />
    </Container>
  );
};

export default SimulationHelperOptimisation;
