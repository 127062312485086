import React from 'react';
import { useTranslation } from 'react-i18next';
import AccordionContainer from '../../containers/AccordionContainer';
import AppBillingFeaturesList from './AppBillingFeaturesList';
import { AppBillingContentProps } from './AppBillingOneTimePriceContent';

export type AppBillingSubscriptionFeaturesProps = AppBillingContentProps & {
  billingInterval: string;
  isSelected?: boolean;
};

const AppBillingPriceCardFeaturesList: React.FC<
  AppBillingSubscriptionFeaturesProps
> = props => {
  const { t } = useTranslation('app');

  return (
    <div
      data-component="AppBillingPriceCardFeaturesList"
      className="mt-4 border-t border-menu-400 flex flex-col items-start pt-4"
    >
      <AccordionContainer
        id="subscription-panel-features"
        classTitle="text-xl uppercase"
        title={t`Features`}
        // collapsible
      >
        {/* <div className="w-full flex-1"> */}
        <AppBillingFeaturesList
          key={props.operationType}
          operationType={props.operationType}
          featurePreset={props.featurePreset}
          classNameDescription={props.isSelected ? '' : 'text-menu-text/80'}
          classNameTitle={props.isSelected ? '' : 'text-menu-text/80'}
        />
        {/* </div> */}
      </AccordionContainer>
    </div>
  );
};

export default AppBillingPriceCardFeaturesList;
