import * as React from 'react';
const MenuItemArrowUpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 16.43"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuItemArrowUpIcon"
    {...props}
  >
    <polygon strokeWidth={0} points="0 16.43 64 2 128 16.43 0 16.43" />
  </svg>
);
export default MenuItemArrowUpIcon;
