import { useRecoilCallback } from 'recoil';
import { sidebarStateByType, SidebarType } from '../store/sidebar.state';

function useChangeSidebarState(sidebarId: SidebarType) {
  const changeState = useRecoilCallback(
    ({ snapshot, set }) =>
      async (state: { isCollapsed?: boolean; isHidden?: boolean }) => {
        const currentState = await snapshot.getPromise(
          sidebarStateByType(sidebarId),
        );

        if (!currentState.isPinned) {
          set(sidebarStateByType(sidebarId), {
            ...currentState,
            ...state,
          });
        }
      },
  );
  return changeState;
}

export default useChangeSidebarState;
