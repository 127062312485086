import {
  PickingRequirementsBatchSettingsFragment,
  PickingRequirementsGroupBy,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Icon from '../../components/icons';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { PickingRequirementsBatchSettingsEditor } from './PickingRequirementsBatchSettingsEditor';

export const getGroupByOptions = (t: TFunction<'simulation'>) => [
  {
    id: PickingRequirementsGroupBy.ORDER,
    title: t`Order`,
    icon: Icon.DataOrders,
  },
  {
    id: PickingRequirementsGroupBy.ITEM,
    title: t`Item`,
    icon: Icon.DataItems,
  },
  {
    id: PickingRequirementsGroupBy.CUSTOMER,
    title: t`Customer`,
    icon: Icon.WarehouseECommerce,
  },
  {
    id: PickingRequirementsGroupBy.BATCH,
    title: t`Batch`,
    icon: Icon.OrderPickingPriority,
  },
];

const PickingPolicyGroupByEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    pickingPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  const selectedGroup = rule?.pickingRequirementsSettings?.groupBy;
  const batchSettings = rule?.pickingRequirementsSettings?.batch ?? {
    maxOrderCount: 0,
    maxOrderLineCount: 0,
  };

  const onSelectValue = (groupBy: PickingRequirementsGroupBy) => {
    updateRule({
      ...rule,
      pickingRequirementsSettings: {
        ...(rule.pickingRequirementsSettings ?? {}),
        groupBy: groupBy,
      },
    });
  };
  const onChangeBatchSettings = (
    batchSettings: Omit<PickingRequirementsBatchSettingsFragment, '__typename'>,
  ) => {
    updateRule({
      ...rule,
      pickingRequirementsSettings: {
        ...rule.pickingRequirementsSettings,
        batch: batchSettings,
      },
    });
  };

  return (
    <TitleSection
      dataComponent="PickingPolicyOrderLineGroupEditor"
      id={`policy-editor-group-by`}
      title={t`Group by`}
      inSidebarView
      hasScreenTitle
      classNameInner="p-2 space-y-2"
    >
      {_.map(getGroupByOptions(t), (option, index) => {
        const groupByTitle = option.title;

        return (
          <PolicyCardItem
            key={'PolicyCardItem-GroupBy-' + option.id}
            isActive={option.id === selectedGroup}
            onClick={() => onSelectValue(option.id)}
            icon={option.icon}
            iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
            title={option.title}
          />
        );
      })}

      {selectedGroup === PickingRequirementsGroupBy.BATCH && (
        <>
          <PickingRequirementsBatchSettingsEditor
            value={batchSettings}
            onChange={onChangeBatchSettings}
          />
        </>
        //
      )}
    </TitleSection>
  );
};

export default PickingPolicyGroupByEditor;
