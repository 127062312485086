import * as React from 'react';
const LayoutWarehouseDesignIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayoutWarehouseDesignIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M29.18,95.63V18.45C29.18-1.89,4.1.29,4.1,18.45v77.72c4.77-10.92,20.89-10.05,25.09-.55Z"
    />
    <path
      strokeWidth={0}
      d="M35.9,22.15v84.71c0,5.94-5.39,6.03-6.72.82-1.39-5.48-6.44-9.32-12.2-9.32-7.13,0-12.89,5.76-12.89,12.75s5.76,12.89,12.89,12.89h106.92V22.15H35.9ZM107.88,46.25l-2.25,9.47-6.96-6.96,9.2-2.52ZM104.04,57.31l-11.79,11.79-2.59-2.59,11.79-11.79,2.59,2.59ZM97.34,50.61l2.59,2.59-11.79,11.79-2.59-2.59,11.79-11.79ZM59.46,101.89c-1.25,1.25-3.31,1.14-4.69-.25l-2.24-2.24c-1.39-1.39-1.5-3.45-.25-4.7l2.26-2.26,7.15,7.22-2.22,2.22ZM56.54,90.72l16.25-16.25,2.59,2.59-16.25,16.25-2.59-2.59ZM63.59,97.76l-2.59-2.59,16.25-16.25,2.59,2.59-16.25,16.25ZM104.81,102.36l-4.27-4.27,2.94-2.94c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-2.94,2.94-2.85-2.85,1.77-1.77c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-1.77,1.77-2.85-2.85,2.94-2.94c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-2.94,2.94-2.85-2.85,1.76-1.77c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-1.77,1.77-2.85-2.85,2.94-2.94c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-2.94,2.94-2.85-2.85,1.77-1.77c.32-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-1.77,1.77-2.85-2.85,2.94-2.94c.33-.33.27-.91-.12-1.3-.39-.39-.97-.45-1.3-.12l-2.94,2.94-2.85-2.85,1.76-1.77c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-1.77,1.77-2.85-2.85,2.94-2.94c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-2.94,2.94-2.85-2.85,1.77-1.77c.32-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-1.77,1.77-2.85-2.85,2.94-2.94c.33-.33.27-.91-.12-1.3-.39-.39-.98-.45-1.3-.12l-2.94,2.94-4.27-4.27,8.24-8.24,52.72,52.73-8.24,8.24Z"
    />
  </svg>
);
export default LayoutWarehouseDesignIcon;
