import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { HEATMAP_BUCKETS_COUNT } from '../../common/heatmap.helper';
import useFormatter from '../../common/useFormatter';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import { layoutLocationColorMode } from '../store/layout.state';

const LocationOrderToolbar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const selectedMode = useRecoilValue(layoutLocationColorMode);
  const formatter = useFormatter();

  if (selectedMode === 'default') return null;

  const getLegendTitle = (i: number): string => {
    const from = formatter.formatShare(i / HEATMAP_BUCKETS_COUNT).fullString;
    const to = formatter.formatShare(
      (i + 1) / HEATMAP_BUCKETS_COUNT,
    ).fullString;
    return `${from}-${to}`;
  };

  return (
    <ButtonToolbarGroup
      className={`absolute ltr:left-1 rtl:right-1 top-1 flex flex-col z-toolbar`}
    >
      <TitleToolbarContainer
        id="panel-toolbar-heatmap-hit-count"
        title={t`Sort Legend`}
        collapsible
        className="mt-2"
      >
        {/* <div>
          {_.map(elevationSortColors, (color, i) => (
            <ButtonToolbar key={`legend-color-${color}`} disabled>
              <LegendItem text={getLegendTitle(i)} color={color} />
            </ButtonToolbar>
          ))}
        </div> */}
      </TitleToolbarContainer>
    </ButtonToolbarGroup>
  );
};

export default LocationOrderToolbar;
