import * as React from 'react';
const SortPriorityMultiIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortPriorityMultiIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M70.7,104.84l-16.91-16.89v17.36l23.06,23.04,23.06-23v-17.36l-16.91,16.86v-16.18c-3.45,2.53-7.69,4.04-12.3,4.04v12.13Z"
    />
    <path
      strokeWidth={0}
      d="M25.87,71.46l16.89-16.91h-17.36S2.36,77.61,2.36,77.61l23,23.06h17.36s-16.86-16.91-16.86-16.91h21.32c-2.98-3.32-4.91-7.58-5.27-12.3h-16.04Z"
    />
    <path
      strokeWidth={0}
      d="M60.78,27.01l16.91,16.89v-17.36S54.63,3.5,54.63,3.5l-23.06,23v17.36l16.91-16.86v21.59c3.29-3.07,7.56-5.09,12.3-5.52v-16.05Z"
    />
    <path
      strokeWidth={0}
      d="M87.73,75.3h17.36s23.04-23.06,23.04-23.06l-23-23.06h-17.36s16.86,16.91,16.86,16.91h-23.98c4.45,2.75,7.78,7.12,9.18,12.3h14.79s-16.89,16.91-16.89,16.91Z"
    />
    <path
      strokeWidth={0}
      d="M66.7,50.09c-9.8,0-17.74,7.94-17.74,17.74s7.94,17.74,17.74,17.74,17.74-7.94,17.74-17.74-7.94-17.74-17.74-17.74ZM73.87,77.89h-14.1v-3.51h5.45s0-13.57,0-13.57h-.26l-4.12,5.39-2.78-2.17,4.84-6.38h6.67v16.73h4.29v3.51Z"
    />
  </svg>
);
export default SortPriorityMultiIcon;
