import {
  WaypointPolicyFragment,
  WaypointPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';

export const waypointPolicyDefault: WaypointPolicyFragment = {
  rules: [],
};

export function createWaypointPolicyRule(
  title: string,
): WaypointPolicyRuleFragment {
  const id = `waypoint-policy-rule-${nanoid()}`;
  return {
    id,
    title,
    // startPoints: {
    //   locationsMatch: {
    //     anyOf: [],
    //   },
    // },
    // endPoints: {
    //   locationsMatch: {
    //     anyOf: [],
    //   },
    // },
  };
}
