import * as React from 'react';
const DesignerBaysCustomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerBaysCustomIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M126.03,34.46h-25.18s0,29.27,0,29.27h25.18s0-29.27,0-29.27ZM121.77,59.77h-14.86s0-3.7,0-3.7h5.75v-14.31h-.27s-4.34,5.69-4.34,5.69l-2.94-2.29,5.11-6.73h7.03s0,17.64,0,17.64h4.53s0,3.7,0,3.7Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M100.85,96.34v29.27s25.18,0,25.18,0v-29.27s-25.18,0-25.18,0ZM121.35,121.82h-15.35s0-4.19,0-4.19l6.73-5.6c1.18-1.02,2.02-1.9,2.52-2.63.5-.73.75-1.5.75-2.29v-.31c0-.88-.27-1.55-.8-2.02s-1.24-.7-2.14-.7c-.96,0-1.72.27-2.28.83s-.97,1.27-1.24,2.17l-4-1.53c.51-1.61,1.44-2.92,2.78-3.93,1.35-1.01,3.08-1.51,5.2-1.51,1.53,0,2.85.27,3.96.83,1.11.55,1.96,1.31,2.54,2.28.58.97.87,2.07.87,3.32,0,1.12-.24,2.15-.73,3.09-.49.94-1.13,1.8-1.93,2.58-.8.78-1.82,1.66-3.09,2.61l-4.07,3.15h10.27s0,3.85,0,3.85Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M100.85,66.08v29.27s25.18,0,25.18,0v-29.27s-25.18,0-25.18,0ZM120.52,88.77c-.69.95-1.67,1.68-2.92,2.2-1.25.52-2.72.78-4.39.78-1.98,0-3.59-.37-4.83-1.12-1.24-.74-2.26-1.74-3.06-2.98l3.39-2.63c.53.96,1.15,1.7,1.87,2.22.71.52,1.62.78,2.72.78,1.08,0,1.92-.25,2.52-.76.6-.51.9-1.21.9-2.11v-.21c0-.86-.34-1.51-1.01-1.96-.67-.45-1.61-.67-2.81-.67h-1.99s0-3.82,0-3.82h2.02c1.12,0,1.97-.21,2.54-.64.57-.43.86-.99.86-1.68v-.21c0-.77-.27-1.39-.79-1.85-.53-.46-1.27-.69-2.2-.69-.88,0-1.7.22-2.46.66-.76.44-1.41,1.07-1.94,1.88l-2.91-2.69c.9-1.16,1.93-2.05,3.1-2.66,1.17-.61,2.63-.92,4.39-.92,1.53,0,2.87.23,4.02.7,1.15.47,2.04,1.13,2.68,1.99.63.86.95,1.85.95,2.97,0,1.3-.39,2.38-1.18,3.23-.79.85-1.78,1.37-2.98,1.57v.18c1.37.24,2.46.81,3.3,1.7.84.89,1.25,2.03,1.25,3.44,0,1.26-.35,2.37-1.04,3.32Z"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="108.9 20.99 114.8 20.99 114.8 12.03 114.52 12.03 108.9 20.99"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M100.85,2.84v29.27s25.18,0,25.18,0V2.84s-25.18,0-25.18,0ZM121.95,24.54h-2.75v4.1s-4.4,0-4.4,0v-4.1h-9.6s0-3.91,0-3.91l8.5-13.33h5.5s0,13.7,0,13.7h2.75s0,3.55,0,3.55Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M27.54,65.14H2.36s0,29.27,0,29.27h25.18s0-29.27,0-29.27ZM23.28,90.44h-14.86s0-3.7,0-3.7h5.75v-14.31h-.27s-4.34,5.69-4.34,5.69l-2.94-2.29,5.11-6.73h7.03s0,17.64,0,17.64h4.53s0,3.7,0,3.7Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M2.36,2.84v29.27s25.18,0,25.18,0V2.84s-25.18,0-25.18,0ZM22.85,28.33H7.51s0-4.19,0-4.19l6.73-5.6c1.18-1.02,2.02-1.9,2.52-2.63.5-.73.75-1.5.75-2.29v-.31c0-.88-.27-1.55-.8-2.02s-1.24-.7-2.14-.7c-.96,0-1.72.27-2.28.83s-.97,1.27-1.24,2.17l-4-1.53c.51-1.61,1.44-2.92,2.78-3.93,1.35-1.01,3.08-1.51,5.2-1.51,1.53,0,2.85.27,3.96.83,1.11.55,1.96,1.31,2.54,2.28.58.97.87,2.07.87,3.32,0,1.12-.24,2.15-.73,3.09-.49.94-1.13,1.8-1.93,2.58-.8.78-1.82,1.66-3.09,2.61l-4.07,3.15h10.27s0,3.85,0,3.85Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M2.36,33.88v29.27s25.18,0,25.18,0v-29.27s-25.18,0-25.18,0ZM22.03,56.57c-.69.95-1.67,1.68-2.92,2.2-1.25.52-2.72.78-4.39.78-1.98,0-3.59-.37-4.83-1.12-1.24-.74-2.26-1.74-3.06-2.98l3.39-2.63c.53.96,1.15,1.7,1.87,2.22.71.52,1.62.78,2.72.78,1.08,0,1.92-.25,2.52-.76.6-.51.9-1.21.9-2.11v-.21c0-.86-.34-1.51-1.01-1.96-.67-.45-1.61-.67-2.81-.67h-1.99s0-3.82,0-3.82h2.02c1.12,0,1.97-.21,2.54-.64.57-.43.86-.99.86-1.68v-.21c0-.77-.27-1.39-.79-1.85-.53-.46-1.27-.69-2.2-.69-.88,0-1.7.22-2.46.66-.76.44-1.41,1.07-1.94,1.88l-2.91-2.69c.9-1.16,1.93-2.05,3.1-2.66,1.17-.61,2.63-.92,4.39-.92,1.53,0,2.87.23,4.02.7,1.15.47,2.04,1.13,2.68,1.99.63.86.95,1.85.95,2.97,0,1.3-.39,2.38-1.18,3.23-.79.85-1.78,1.37-2.98,1.57v.18c1.37.24,2.46.81,3.3,1.7.84.89,1.25,2.03,1.25,3.44,0,1.26-.35,2.37-1.04,3.32Z"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="10.41 114.48 16.31 114.48 16.31 105.52 16.03 105.52 10.41 114.48"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M2.36,96.34v29.27s25.18,0,25.18,0v-29.27s-25.18,0-25.18,0ZM23.46,118.03h-2.75v4.1s-4.4,0-4.4,0v-4.1H6.71s0-3.91,0-3.91l8.5-13.33h5.5s0,13.7,0,13.7h2.75s0,3.55,0,3.55Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M61.1,54.85c-1.11,0-2.01-.88-2.01-1.97v-31.58s-18.73,18.38-18.73,18.38v-13.58S64,2.91,64,2.91l23.63,23.18v13.58s-18.73-18.37-18.73-18.37v31.58c0,1.08-.91,1.97-2.01,1.97h-5.79Z"
    />
    <path
      strokeWidth={0}
      d="M66.9,73.44c1.11,0,2.01.88,2.01,1.97v31.58s18.73-18.38,18.73-18.38v13.58s-23.64,23.18-23.64,23.18l-23.63-23.18v-13.58s18.73,18.37,18.73,18.37v-31.58c0-1.08.91-1.97,2.01-1.97h5.79Z"
    />
  </svg>
);
export default DesignerBaysCustomIcon;
