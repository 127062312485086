import { ExportJobFragment } from '@warebee/frontend/data-access-api-graphql';
import {
  getProductsByRuleTableRows,
  ProductsByRuleConverterConfig,
  ProductsByRuleDataColumn,
  ProductsByRuleDataRow,
} from '@warebee/shared/export-converter';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus, DataPageParams } from '../../common/types';
import useFormatter from '../../common/useFormatter';
import DatasetTable, { ColumnConfig } from '../../components/DatasetTable';
import { PanelHeaderProps } from '../../components/designer/panels/PanelHeader';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import {
  getProductCategories,
  getProductCategoryDescriptorExport,
} from '../store/abc/simulation.ABC.helper';
import {
  policyFilteredProducts,
  policyFilteredProductsSearchValues,
} from '../store/policyFilter.state';
import {
  getSimulationProductTableConfigDefault,
  simulationProductTableConfigDefaultKeys,
} from './simulationProductTable.config';

export type SimulationProductByRuleTableViewProps = {
  id: string;
  title: string;
  onChange: (page?: DataPageParams) => void;
  onExport?: () => Promise<{ job: ExportJobFragment; errors: any }>;
  columnsConfig?: ColumnConfig<ProductsByRuleDataRow>[];
  keyFields?: ProductsByRuleDataColumn[];
  panelHeader?: PanelHeaderProps;
};

const SimulationProductByRuleTableView: React.FC<
  SimulationProductByRuleTableViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const productsData = useRecoilValue(policyFilteredProducts);
  const [searchValues, setSearchValues] = useRecoilState(
    policyFilteredProductsSearchValues,
  );
  const formatter = useFormatter();

  useEffect(() => {
    props.onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValues]);

  const {
    columnsConfig = getSimulationProductTableConfigDefault(t, formatter),
    keyFields = simulationProductTableConfigDefaultKeys,
  } = props;
  const isLoading = productsData?.status === AsyncLoadStatus.Loading;
  const itemsCount = productsData?.result?.length || 0;
  const totalCount = productsData?.totalCount ?? 0;

  function onLoadNext() {
    props.onChange({
      isAppend: true,
      skip: itemsCount,
    });
  }

  const subtitle = t(`{{totalCount}} Filtered Items for`, {
    totalCount,
  });

  const productCategories = getProductCategories(t);

  const converterConfig: ProductsByRuleConverterConfig = {
    columnsConfig,
    dictionaries: {
      categories: getProductCategoryDescriptorExport(productCategories),
    },
  };

  const flattenData = getProductsByRuleTableRows(
    productsData?.result,
    converterConfig,
  );

  return (
    <Container
      componentName="SimulationProductByRuleTableView"
      col
      overflow
      className={classNames(
        'flex-1',
        'ltr:border-l rtl:border-r border-app-panel-dark',
      )}
    >
      <HeaderAppMenu {...(props.panelHeader ?? {})} />
      <Container col>
        <DatasetTable
          id={props.id}
          title={props.title}
          subtitle={subtitle}
          columnsConfig={columnsConfig}
          keyFields={keyFields}
          data={flattenData}
          onLoadNext={onLoadNext}
          onSearch={setSearchValues}
          totalCount={totalCount}
          searchValues={searchValues}
          isLoading={isLoading}
          onStartExportClick={props.onExport}
          isSticky
          hasCounter
        />
      </Container>
    </Container>
  );
};

export default SimulationProductByRuleTableView;
