export enum OptimizeJobEngine {
  LAMBDA = 'LAMBDA',
  FARGATE = 'FARGATE',
  MPI = 'MPI',
}

export class StartOptimizationInput {
  optimizationRunId: string;
  engine?: OptimizeJobEngine;
  nodes?: number;
}

export interface OptimizeEvent {
  eventType: 'optimize.success' | 'optimize.failure';
  optimizationRunId: string;
}

export interface OptimizeSuccess extends OptimizeEvent {
  eventType: 'optimize.success';
  newAssignmentId: string;
  improvement?: number;
  startedAt?: number;
  completedAt?: number;
}

export interface OptimizeFailure extends OptimizeEvent {
  eventType: 'optimize.failure';
  error?: string;
  cause?: string;
}
