import {
  BarCanvasCustomLayer,
  BarCanvasCustomLayerProps,
  BarCanvasLayer,
  BarDatum,
  BarTooltipProps,
  ComputedBarDatum,
  ResponsiveBarCanvas,
} from '@nivo/bar';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TwTheme } from '../../../../Tw';
import { drawRoundRect } from '../../../common/canvas.helper';
import {
  formatDurationHoursShort,
  formatTimespan,
} from '../../../common/formatHelper';
import InboxZero from '../../../components/InboxZero';
import { viewerPlanesTitles } from '../../../layout/viewer/store/viewer.state';
import { getPolicyGroupMap } from '../../store/pickingPolicy/pickingPolicy.helper';
import { pickingPolicyOrderLineRulesTitles } from '../../store/pickingPolicy/pickingPolicy.state';
import {
  formatMetricValue,
  getDimensionColor,
  getDimensionTitle,
} from '../../store/workforce.helper';
import {
  workforceAgentAggregatedEventsCurrent,
  workforceAgentEventAggregation,
  workforceAgentForecastEnabled,
  workforceEffectiveAgentSchedules,
  workforceSelectedAgentId,
  workforceSelectedDrill,
  workforceSelectedKPI,
  workforceSimulateInterval,
} from '../../store/workforce.state';
import {
  AgentWorkforceEventsAggregated,
  DRILL_KEYS_LABOUR_EVENTS,
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_TOTAL,
  DRILL_KEY_WORK_CUSTOM_TOTAL,
  WorkforceKpiType,
  scaleEvents,
} from '../../store/workforce.types';
import WorkforceAgentTimelineTooltip from './WorkforceAgentTimelineTooltip';

const forecastColors = TwTheme.extend.colors.workforceForecast;
const multiForecastShiftColor = 'rgba(255, 0, 102, 0.75)';

export type WorkforceAgentTimelineProps = {
  analyzeId: string;
  showAdvancedLabourEvents: boolean;
};

export type TimelineDatum = BarDatum & {
  date: number;
  forecastIds: string[];
  [DRILL_KEY_IDLE]?: number;
  [DRILL_KEY_OVERTIME]?: number;
  [DRILL_KEY_TOTAL]?: number;
};

export type ComputedTimelineDatum = ComputedBarDatum<TimelineDatum>;

const WorkforceAgentTimeline: React.FC<WorkforceAgentTimelineProps> = props => {
  const { t: tMes } = useTranslation('measures');
  const { t } = useTranslation('simulation');
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const [aggregationType, setAggregationType] = useRecoilState(
    workforceAgentEventAggregation(agentId),
  );

  const agentAggregatedEvents = useRecoilValue(
    workforceAgentAggregatedEventsCurrent(props.analyzeId),
  );
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );
  const selectedKPI = useRecoilValue(workforceSelectedKPI(agentId));
  const selectedDrillDown = useRecoilValue(workforceSelectedDrill(agentId));
  const pickingRuleMap = useRecoilValue(pickingPolicyOrderLineRulesTitles);
  const planesMap = useRecoilValue(viewerPlanesTitles);
  const interval = useRecoilValue(workforceSimulateInterval);
  const aggType = useRecoilValue(workforceAgentEventAggregation(agentId));
  const agentForecasts = useRecoilValue(
    workforceEffectiveAgentSchedules(agentId),
  );

  const series = _.keys(
    _(agentAggregatedEvents).reduce(
      (acc, e) => ({ ...acc, ...e.dimensions }),
      {},
    ),
  ).sort();

  function getMetricValues(
    aggregatedEvents: AgentWorkforceEventsAggregated,
    metric: WorkforceKpiType,
  ): Record<string, number> {
    switch (metric) {
      case 'picklists':
        return aggregatedEvents.picklists;
      case 'avg':
        return aggregatedEvents.durationAvg;
      case 'sum':
        return aggregatedEvents.duration;
      case 'fte':
        return scaleEvents(
          aggregatedEvents.duration,
          1 / aggregatedEvents.aggregatedShiftWithoutLabourEvents,
        );
    }
  }

  const intervalDays = eachDayOfInterval({
    start: interval[0],
    end: interval[1],
  });

  let chartData: TimelineDatum[];
  if (aggType === 'dailyCalendar') {
    chartData = _(intervalDays)
      .map(day => {
        const aggEvent = agentAggregatedEvents[day.toString()];

        return _.isNil(aggEvent)
          ? {
              date: day.getTime(),
              forecastIds: [],
            }
          : {
              date: day.getTime(),
              forecastIds: aggEvent.forecastIds as any,
              ...getMetricValues(aggEvent, selectedKPI),
            };
      })
      .value();
  } else {
    chartData = _(agentAggregatedEvents)
      .map(aggEvent => {
        return {
          date: aggEvent.periodStart.getTime(),
          forecastIds: aggEvent.forecastIds as any,
          ...getMetricValues(aggEvent, selectedKPI),
        };
      })
      .value();
  }

  const shiftDurations = _(agentAggregatedEvents)
    .map(aggEvent => {
      switch (selectedKPI) {
        case 'avg':
          return aggEvent.aggregatedShift;
        case 'sum':
          return aggEvent.totalShiftsDuration;
        case 'fte':
          return aggEvent.totalShiftsDuration / aggEvent.aggregatedShift;
      }
      return null;
    })
    .compact()
    .uniq()
    .value();

  const maxShiftDuration = _.max(shiftDurations);

  // Setup X axis ticks
  const maxTicks = 40;
  const maxTicksWithoutRotation = 7;
  const totalX = chartData.length;
  const rakeSize = Math.ceil(totalX / maxTicks);
  const dateTicks = chartData
    .map((v, i) => (i % rakeSize === 0 || i === totalX - 1 ? v.date : null))
    .filter(v => v !== null);

  //Setup Y-axis ticks
  const maxDaily = _.maxBy(chartData, i => i[DRILL_KEY_TOTAL])?.[
    DRILL_KEY_TOTAL
  ];

  const chartYMax = Math.max(maxDaily, maxShiftDuration);
  const chartYMaxHours = chartYMax / 3600;
  const yAxisTicksCount = 8;

  const yRakeSizeHours = Math.ceil(chartYMaxHours / yAxisTicksCount); // distance between labels in hours
  const yTicks = [
    maxDaily,
    ..._.range(24)
      .map(i => (i + 1) * 3600 * yRakeSizeHours)
      .filter(value => maxDaily - value > (yRakeSizeHours * 3600) / 2),
  ];

  function getAnnotationColor(datum: ComputedTimelineDatum) {
    const fid = _.head(datum.data.data.forecastIds);
    return (
      _.find(agentForecasts, f => f.id === fid)?.color ?? forecastColors.default
    );
  }

  function getShiftColor(v: number) {
    const matchedShifts = []; //agentForecasts.filter(f => v === f.secondsOperation);

    return matchedShifts.length === 1
      ? matchedShifts[0].color
      : multiForecastShiftColor;
  }

  const shiftTrendLineLayer: BarCanvasCustomLayer<TimelineDatum> = (
    context: CanvasRenderingContext2D,
    props: BarCanvasCustomLayerProps<TimelineDatum>,
  ) => {
    shiftDurations.forEach(shift => {
      const scaleY = props.yScale as any;
      context.beginPath();
      context.fillStyle = getShiftColor(shift);
      context.rect(0, scaleY(shift), props.innerWidth, 3);
      context.fill();
      context.closePath();
    });
  };

  const forecastCalendarLayer: BarCanvasCustomLayer<TimelineDatum> = (
    context: CanvasRenderingContext2D,
    props: BarCanvasCustomLayerProps<TimelineDatum>,
  ) => {
    props.bars
      .filter(bar => bar.key.startsWith('travellingHorizontal'))
      .forEach(bar => {
        drawRoundRect(
          context,
          bar.x,
          375,
          bar.width,
          9,
          3,
          getAnnotationColor(bar),
        );
      });
  };
  const hasDailyData = chartData.length;
  if (!hasDailyData) {
    return (
      <div
        className={classNames(
          'flex flex-col',
          'flex-1 items-center',
          'relative',
          'h-full',
          // 'bg-app-panel-dark/60',
        )}
        // style={{ maxHeight: 400 }}
      >
        <div className="flex-1">
          <InboxZero selfCenter message={t`No data for selected time range`} />
        </div>
      </div>
    );
  }

  const chartLayers: BarCanvasLayer<TimelineDatum>[] = [
    'axes',
    'bars',
    'legends',
    'annotations',
  ];
  if (aggregationType !== 'weekly') {
    chartLayers.push(shiftTrendLineLayer);
  }
  if (isForecastSimulation) {
    chartLayers.push(forecastCalendarLayer);
  }

  if (props.showAdvancedLabourEvents) {
    series.push(...DRILL_KEYS_LABOUR_EVENTS);
  } else {
    series.push(DRILL_KEY_WORK_CUSTOM_TOTAL);
  }

  //if (selectedDrillDown !== ResourceUsageSummaryGroupBy.PICK_BY_DATE) {
  series.push(DRILL_KEY_IDLE);
  //}

  return (
    <div
      className={classNames(
        'flex flex-1 items-center relative',
        'h-full w-full',
      )}
    >
      <div
        className={classNames('w-full h-full', 'flex flex-1')}
        style={{
          width: '100%',
          minWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
        }}
      >
        <ResponsiveBarCanvas
          data={chartData}
          indexBy={'date'}
          keys={[...series]}
          groupMode={'stacked'}
          colors={d => getDimensionColor(d.id.toString())[0]}
          enableLabel={false}
          // padding={20}
          margin={{ top: 20, right: 65, left: 50, bottom: 135 }}
          enableGridY={false}
          gridYValues={shiftDurations}
          theme={{
            annotations: {
              link: {
                stroke: 'rgba(255, 255, 255,  0.0)',
                opacity: 0,
                strokeWidth: 0,
                outlineWidth: 0,
              },
              outline: {
                stroke: 'rgba(255, 255, 255,  0.0)',
                outlineColor: 'rgba(255, 255, 255,  0.0)',
                opacity: 0,
                strokeWidth: 0,
                outlineWidth: 0,
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 9,
                  fill: 'rgba(255, 255, 255,  0.9)',
                },
              },
            },
          }}
          valueFormat={(v: number) => formatTimespan(v * 1000)}
          axisBottom={{
            tickValues: dateTicks,
            tickRotation: maxTicksWithoutRotation >= totalX ? 0 : -90,
            format: v => format(new Date(v), 'MMM, dd (EE)'),
          }}
          axisLeft={
            aggregationType !== 'weekly' && {
              tickValues: shiftDurations,
              format: v => {
                return t(`Shift ({{duration}})`, {
                  duration: formatDurationHoursShort(tMes, v * 1000),
                });
              },
            }
          }
          axisRight={{
            tickValues: yTicks,
            format: v => formatDurationHoursShort(tMes, v * 1000),
          }}
          layers={chartLayers}
          tooltip={(datum: BarTooltipProps<TimelineDatum>) => (
            <WorkforceAgentTimelineTooltip
              datum={datum}
              totalX={chartData.length}
              getDimensionColor={getDimensionColor}
              formatValue={v => formatMetricValue(v, selectedKPI, tMes)}
              getDimensionTitle={v =>
                getDimensionTitle(
                  v,
                  selectedDrillDown,
                  pickingRuleMap,
                  planesMap,
                  getPolicyGroupMap(t),
                  t,
                )
              }
              series={[...series]}
            />
          )}
        />
      </div>
    </div>
  );
};

export default WorkforceAgentTimeline;
