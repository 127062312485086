import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../../../Tw';

type CornerType = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';

interface ObjectLinkVisualisationProps {
  currentLinkPoint: CornerType;
  linkedLinkPoint: CornerType;
  onCurrentLinkPointChange: (point: CornerType) => void;
  onLinkedLinkPointChange: (point: CornerType) => void;
  currentTitle?: string; // Title for the current area
  linkedTitle?: string; // Title for the linked area
}

const colors = TwTheme.extend.colors;

const ObjectLinkVisualisation: React.FC<ObjectLinkVisualisationProps> = ({
  currentLinkPoint,
  linkedLinkPoint,
  onCurrentLinkPointChange,
  onLinkedLinkPointChange,
  currentTitle,
  linkedTitle,
}) => {
  const { t } = useTranslation('designer');
  const [activeArea, setActiveArea] = useState<'current' | 'linked'>('linked');

  const cellSize = 100;
  const gridCells = 3;
  const svgSize = cellSize * gridCells;
  const middleCellOffset = cellSize;
  const padding = 10;
  const svgPaddedSize = svgSize + padding * 2;

  const cornerOffsets = {
    topLeft: { x: 0, y: 0 },
    topRight: { x: cellSize, y: 0 },
    bottomLeft: { x: 0, y: cellSize },
    bottomRight: { x: cellSize, y: cellSize },
  };

  // MasterArea is always in the center of the grid
  const masterAreaPosition = {
    x: middleCellOffset,
    y: middleCellOffset,
  };

  const masterCornerOffset = cornerOffsets[currentLinkPoint];
  const linkedCornerOffset = cornerOffsets[linkedLinkPoint];

  const linkedAreaPosition = {
    x: masterAreaPosition.x + masterCornerOffset.x - linkedCornerOffset.x,
    y: masterAreaPosition.y + masterCornerOffset.y - linkedCornerOffset.y,
  };

  // Check for overlap
  const overlapThreshold = cellSize; // Change as needed for overlap detection
  const isOverlapping =
    Math.abs(masterAreaPosition.x - linkedAreaPosition.x) < overlapThreshold &&
    Math.abs(masterAreaPosition.y - linkedAreaPosition.y) < overlapThreshold;

  // Adjust linkedAreaPosition if overlapping
  if (isOverlapping) {
    linkedAreaPosition.y += 20;
    linkedAreaPosition.x += 20;
  }

  const renderAnimatedStroke = () => {
    return activeArea === 'linked' ? (
      <animate
        attributeName="stroke-dashoffset"
        values="0;8"
        dur="1s"
        repeatCount="indefinite"
      />
    ) : (
      <animate
        attributeName="stroke-dashoffset"
        values="0;8"
        dur="1s"
        repeatCount="indefinite"
      />
    );
  };

  const handleAreaClick = (area: 'current' | 'linked') => {
    setActiveArea(area);
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${svgPaddedSize} ${svgPaddedSize}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform={`translate(${padding}, ${padding})`}>
          {/* Grid lines */}
          {[...Array(gridCells + 1)].map((_, index) => (
            <React.Fragment key={index}>
              <line
                x1={0}
                y1={index * cellSize}
                x2={svgSize}
                y2={index * cellSize}
                stroke={colors.menu[400]}
              />
              <line
                x1={index * cellSize}
                y1={0}
                x2={index * cellSize}
                y2={svgSize}
                stroke={colors.menu[400]}
              />
            </React.Fragment>
          ))}

          {/* Grouping MasterArea and its Text for Clickable Interaction */}
          <g
            onClick={() => handleAreaClick('current')}
            style={{ cursor: 'pointer' }}
          >
            <rect
              x={masterAreaPosition.x}
              y={masterAreaPosition.y}
              width={cellSize}
              height={cellSize}
              fill={colors.menu.DEFAULT}
              stroke={colors.menu[800]}
              strokeWidth={activeArea === 'current' ? '2' : '1'}
              strokeDasharray={activeArea === 'current' ? '4' : 'none'}
              strokeDashoffset={activeArea === 'current' ? '0' : 'none'}
            >
              {renderAnimatedStroke()}
            </rect>
            {/* Text and Circles for the corners of MasterArea (Current) */}
            {activeArea === 'current' && (
              <>
                {Object.entries(cornerOffsets).map(([key, value]) => {
                  const isSelected = key === currentLinkPoint;
                  return (
                    <circle
                      key={`master-${key}`}
                      cx={masterAreaPosition.x + value.x}
                      cy={masterAreaPosition.y + value.y}
                      r={isSelected ? 20 : 10}
                      fill={
                        isSelected
                          ? colors.area.control_anchor
                          : colors.area.link_target
                      }
                      onClick={() =>
                        onCurrentLinkPointChange(key as CornerType)
                      }
                      style={{ cursor: 'pointer' }}
                      stroke={
                        isSelected
                          ? colors.area.control_anchor
                          : colors.area.control_anchor
                      }
                    >
                      {isSelected && renderAnimatedStroke()}
                    </circle>
                  );
                })}
              </>
            )}
            <text
              x={masterAreaPosition.x + cellSize / 2}
              y={masterAreaPosition.y + cellSize / 2}
              textAnchor="middle"
              dominantBaseline="central"
              fill={colors.menu[50]}
            >
              <tspan x={masterAreaPosition.x + cellSize / 2} dy="0">
                {currentTitle.length > 7
                  ? `${currentTitle.substring(0, 7)}...`
                  : currentTitle}
              </tspan>
              <tspan
                x={masterAreaPosition.x + cellSize / 2}
                dy="1.2em"
              >{t`(Linked to)`}</tspan>
            </text>
          </g>

          {/* Grouping LinkedArea and its Text for Clickable Interaction */}
          <g
            onClick={() => handleAreaClick('linked')}
            style={{ cursor: 'pointer' }}
          >
            <rect
              x={linkedAreaPosition.x}
              y={linkedAreaPosition.y}
              width={cellSize}
              height={cellSize}
              fill={colors.area.link_both}
              stroke={colors.area.link_source}
              strokeWidth={activeArea === 'linked' ? '2' : '1'}
              strokeDasharray={activeArea === 'linked' ? '4' : 'none'}
              strokeDashoffset={activeArea === 'linked' ? '0' : 'none'}
            >
              {renderAnimatedStroke()}
            </rect>
            {/* Circles for the corners of LinkedArea */}
            {activeArea === 'linked' && (
              <>
                {Object.entries(cornerOffsets).map(([key, value]) => {
                  const isSelected = key === linkedLinkPoint;
                  return (
                    <circle
                      key={`linked-${key}`}
                      cx={linkedAreaPosition.x + value.x}
                      cy={linkedAreaPosition.y + value.y}
                      r={isSelected ? 18 : 10}
                      fill={
                        isSelected
                          ? colors.area.overlap
                          : colors.area.control_anchor
                      }
                      onClick={() => onLinkedLinkPointChange(key as CornerType)}
                      style={{ cursor: 'pointer' }}
                      stroke={
                        isSelected
                          ? colors.area.control_anchor
                          : colors.area.control_anchor
                      }
                    >
                      {isSelected && renderAnimatedStroke()}
                    </circle>
                  );
                })}
              </>
            )}
            <text
              x={linkedAreaPosition.x + cellSize / 2}
              y={linkedAreaPosition.y + cellSize / 2}
              textAnchor="middle"
              dominantBaseline="central"
              fill={colors.area.control_anchor}
            >
              <tspan x={linkedAreaPosition.x + cellSize / 2} dy="0">
                {linkedTitle.length > 7
                  ? `${linkedTitle.substring(0, 7)}...`
                  : linkedTitle}
              </tspan>
              <tspan
                x={linkedAreaPosition.x + cellSize / 2}
                dy="1.2em"
              >{t`(Selected)`}</tspan>
            </text>
          </g>

          {/* Connecting line between the selected corners */}
          <line
            x1={masterAreaPosition.x + masterCornerOffset.x}
            y1={masterAreaPosition.y + masterCornerOffset.y}
            x2={linkedAreaPosition.x + linkedCornerOffset.x}
            y2={linkedAreaPosition.y + linkedCornerOffset.y}
            stroke={colors.area.intersection}
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
};

export default ObjectLinkVisualisation;
