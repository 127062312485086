import { Color, parseColor } from '@react-stately/color';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from 'react-stately';
import { TwTheme } from '../../../Tw';
import * as Icon from '../../components/icons';
import { Button } from '../actions/Button';
import ColorSlider from './ColorSlider';
import { InputValue } from './InputValue';
import Popover from './Popover';

export type ColorPickerProps = {
  color: string | null;
  onChange: (value: string | null) => void;
};

const colors = TwTheme.extend.colors;

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const { t } = useTranslation('app');
  const editorColor = props.color ?? colors.app.DEFAULT;
  const [color, setColor] = useState<Color>(
    parseColor(editorColor).toFormat('hsl'),
  );
  const [hexValue, setHexValue] = useState(color.toString('hex'));
  const ref = useRef(null);
  const popoverState = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    popoverState,
    ref,
  );

  const handleHexChange = (value: string) => {
    setHexValue(value);
    if (/^#[0-9A-Fa-f]{6}$/.test(value)) {
      const newColor = parseColor(value).toFormat('hsl');
      setColor(newColor);
    }
  };

  const handleColorChange = (newColor: Color) => {
    setColor(newColor);
    setHexValue(newColor.toString('hex'));
  };

  const handleReset = () => {
    props.onChange(null);
    // popoverState.close();
  };

  let bgColor = props.color;
  if (popoverState.isOpen) {
    bgColor = color.toString('css');
  }

  return (
    <div
      data-component="ColorPickerButton"
      className="relative inline-flex flex-col text-start"
      onClick={e => e.stopPropagation()}
    >
      <div className="flex items-center">
        <Button
          {...triggerProps}
          data-component="ColorPickerButton"
          className="hover:bg-app-panel-dark/60"
        >
          <div
            ref={ref}
            style={{ background: bgColor }}
            className={classNames(
              'h-4 w-4',
              'rounded',
              bgColor
                ? 'hover:border-menu-50 hover:border'
                : 'border-menu-100 border',
            )}
          />
        </Button>
      </div>

      {popoverState.isOpen && (
        <Popover
          {...overlayProps}
          state={popoverState}
          triggerRef={ref}
          placement="bottom start"
        >
          <div
            {...overlayProps}
            className="align-center flex w-64 flex-col space-y-4"
          >
            <ColorSlider
              value={color}
              channel="hue"
              onChange={handleColorChange}
            />
            <ColorSlider
              value={color}
              channel="saturation"
              onChange={handleColorChange}
            />
            <ColorSlider
              value={color}
              channel="lightness"
              onChange={handleColorChange}
            />
            <InputValue
              title={t`Hex`}
              value={hexValue}
              onChange={handleHexChange}
              className="text-xxs mt-4 rounded"
            />
            <div
              className={`mt-8 flex flex-1 flex-row items-end justify-between`}
            >
              <Button
                label={t`Reset`}
                className="rounded"
                buttonSize="xxs"
                buttonType="secondary"
                onPress={() => {
                  props.onChange(null);
                  // Reset local state
                  setColor(parseColor(colors.app.DEFAULT).toFormat('hsl'));
                  setHexValue(colors.app.DEFAULT);
                }}
              />
              <Button
                label={t`Apply`}
                className="rounded ltr:ml-2 rtl:mr-2"
                buttonSize="xxs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CircleOk className={`mx-2 h-4 w-4 fill-current`} />
                }
                onPress={() => {
                  props.onChange(color.toString('hex'));
                  popoverState.close();
                }}
              />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default ColorPicker;
