import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { importSelectedFile } from '../../store/import.state';
import useResetImportState from '../../useResetImportState';

const ImporterSelectedFileHeader: React.FC = () => {
  const { t } = useTranslation('importer');
  const file = useRecoilValue(importSelectedFile);
  const reset = useResetImportState();

  return (
    <div
      data-component="HeaderSelectedFile"
      className={classNames(
        'flex items-center',
        'w-full',
        // 'p-2 bg-app-panel-dark/60'
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-between flex-1',
          'px-4 py-1',
          'ltr:mr-4 rtl:ml-4',
          'rounded text-start file-container',
          'bg-app-panel-dark',
        )}
      >
        <span className={classNames('flex-1')}>
          <span
            className={classNames(
              'text-xxs uppercase',
              'text-menu-text/60',
              'ltr:mr-2 rtl:ml-2',
            )}
          >{t`File:`}</span>
          <span className={classNames('text-menu-active')}>{file?.name}</span>
        </span>
      </div>
      <Button
        className="rounded"
        buttonSize="xs"
        buttonType="primary"
        label={t`Cancel Upload`}
        hasIconAfter
        buttonIcon={
          <Icon.CircleX className={classNames('w-5 h-5 fill-current')} />
        }
        onPress={() => reset({ step: 'import-select-csv' })}
      />
    </div>
  );
};

export default ImporterSelectedFileHeader;
