import * as React from 'react';
const Warehouse3Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Warehouse3Icon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="13.99 89.33 13.99 91.98 39.89 91.98 39.89 89.33 32.53 89.33 13.99 89.33"
    />
    <rect width={25.91} height={2.65} x={51.05} y={80.98} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={51.05} y={76.85} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={51.05} y={72.73} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={51.05} y={97.59} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={13.99} y={72.73} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={13.99} y={97.59} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={13.99} y={85.21} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={13.99} y={76.85} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={13.99} y={80.98} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={13.99} y={93.46} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="88.11 89.33 88.11 91.98 114.01 91.98 114.01 89.33 106.66 89.33 88.11 89.33"
    />
    <rect width={25.91} height={2.65} x={88.11} y={85.21} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={88.11} y={80.98} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={88.11} y={93.46} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="3.84 51.66 3.84 100.24 11.46 100.24 11.46 69.97 42.42 69.97 42.42 100.24 48.52 100.24 48.52 69.97 79.48 69.97 79.48 100.24 85.58 100.24 85.58 69.97 116.54 69.97 116.54 100.24 124.16 100.24 124.16 51.66 64.5 35.23 3.84 51.66"
    />
    <rect width={25.91} height={2.65} x={88.11} y={76.85} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={51.05} y={85.21} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="51.05 89.33 51.05 91.98 76.95 91.98 76.95 89.33 69.6 89.33 51.05 89.33"
    />
    <rect width={25.91} height={2.65} x={51.05} y={93.46} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={88.11} y={72.73} strokeWidth={0} />
    <rect width={25.91} height={2.65} x={88.11} y={97.59} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="64.5 27.76 1.84 45.19 1.84 50.21 64.5 32.78 126.16 50.21 126.16 45.19 64.5 27.76"
    />
  </svg>
);
export default Warehouse3Icon;
