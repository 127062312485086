import { createCalendar } from '@internationalized/date';
import {
  RangeCalendarProps as RangeCalendarAriaProps,
  useRangeCalendar,
} from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { useRangeCalendarState } from '@react-stately/calendar';
import { DateValue } from '@react-types/calendar';
import classNames from 'classnames';
import _ from 'lodash';
import { useRef } from 'react';
import * as Icon from '../../../components/icons';
import { Button } from '../../actions/Button';
import { CalendarGrid } from './CalendarGrid';

export type RangeCalendarProps = RangeCalendarAriaProps<DateValue> & {
  onResetClick?: () => void;
  showDates?: boolean;
};

const RangeCalendar: React.FC<RangeCalendarProps> = props => {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref);

  const isResetVisible = _.isFunction(props.onResetClick);

  const styleButton = classNames(
    'bg-app-panel',
    '!p-1',
    'rounded-md',
    'w-7 h-7',
  );

  const styleButtonIcon = classNames(
    'fill-current',
    'w-6 h-6',
    'group-focus-within:text-menu-active',
    'hover:text-menu-active',
  );

  return (
    <div
      data-component="RangeCalendar"
      {...calendarProps}
      ref={ref}
      className={classNames('inline-block')}
    >
      <div className={classNames('flex items-center pb-4')}>
        {isResetVisible && (
          <Button className={styleButton} onPress={props.onResetClick}>
            <Icon.Refresh className={styleButtonIcon} />
          </Button>
        )}

        <h2
          className={classNames(
            'flex-1',
            'font-bold',
            'text-xl',
            'mx-2',
            'text-menu-text',
          )}
        >
          {title}
        </h2>
        <div className={classNames('flex', 'space-x-1')}>
          <Button className={styleButton} {...prevButtonProps}>
            <Icon.ChevronLeft className={styleButtonIcon} />
          </Button>
          <Button className={styleButton} {...nextButtonProps}>
            <Icon.ChevronRight className={styleButtonIcon} />
          </Button>
        </div>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
};

export default RangeCalendar;
