import * as React from 'react';
const HelpBotIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HelpBotIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4.98,25.41v58.86c0,6,4.73,10.73,10.57,10.73h74.8c.16,0,.32.16.32.16l31.72,24.78c.32.16.63,0,.63-.32v-24.62s0-69.12,0-69.12c0-6-4.73-10.73-10.57-10.73h-47.03v-4.84h3.89s0-8.31,0-8.31h-10.31s0,8.31,0,8.31h3.89v4.84H15.24c-5.68,0-10.26,4.58-10.26,10.26ZM65.7,37h15.32c2.09,0,3.78,1.69,3.78,3.78v2.37c0,1.83-1.48,3.31-3.31,3.31h-15.68c-1.98,0-3.58-1.6-3.58-3.58v-2.43c0-1.91,1.55-3.47,3.47-3.47ZM18.8,37h15.32c2.09,0,3.78,1.69,3.78,3.78v2.37c0,1.83-1.48,3.31-3.31,3.31h-15.68c-1.98,0-3.58-1.6-3.58-3.58v-2.43c0-1.91,1.55-3.47,3.47-3.47ZM46.19,58.08v-2.33c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06v4.12c0,1.14-.92,2.06-2.06,2.06-2.28,0-4.12,1.84-4.12,4.12s1.84,4.12,4.12,4.12,4.12-1.84,4.12-4.12c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06c0,4.55-3.68,8.24-8.23,8.25-4.55,0-8.24-3.68-8.25-8.24,0-3.76,2.54-7.05,6.18-7.99Z"
    />
  </svg>
);
export default HelpBotIcon;
