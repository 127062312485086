import * as React from 'react';
const TrolleyIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TrolleyIcon"
    {...props}
  >
    <rect
      width={54.36}
      height={53.04}
      x={58.45}
      y={30.63}
      strokeWidth={0}
      transform="translate(-16.35 43.1) rotate(-25.88)"
    />
    <path
      strokeWidth={0}
      d="M6.34,19.24h17.81c.1,0,.18,0,.28-.01l40.45,83.38,2.71,5.58,47.61-23.1-2.71-5.58-42.03,20.4L27.91,12.2l-21.57,4.67v2.36Z"
    />
    <circle cx={41.97} cy={99.61} r={15.35} strokeWidth={0} />
  </svg>
);
export default TrolleyIcon;
