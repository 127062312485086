import { InferResult } from 'kysely';
import _ from 'lodash';
import { toDateFromLocaleStringDate } from '../../../common/dateTimeHelper';
import { AnalyzeEventsQueryBuilderParams } from './queryBuilder/analyzedEventsQueryBuilder';
import { analyzedFeedEventsQueryBuilder } from './queryBuilder/analyzedFeedEventsQueryBuilder';

export type ActualityAnalyzedJobSummaries = InferResult<
  ReturnType<typeof getActualityAnalyzedJobSummaries>
>;
export type ActualityAnalyzedJobSummary = ActualityAnalyzedJobSummaries[number];

export function getActualityAnalyzedJobSummaries(
  params: AnalyzeEventsQueryBuilderParams,
) {
  return analyzedFeedEventsQueryBuilder(params)
    .selectFrom('ajewpl')
    .select(({ fn }) => [
      'processType',
      'ajewpl.jobId',
      'agentId',
      'jobDate',
      fn.count<number>('jobLine').distinct().as('lines'),
      fn.sum<number>('cost').as('cost'),
      fn.sum<number>('duration').as('duration'),
    ])
    .groupBy(['processType', 'ajewpl.jobId', 'agentId', 'jobDate']);
}

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return fName === 'jobDate';
};

export function postProcessActualityAnalyzedJobSummaries(
  data: Record<string, any>[],
) {
  return _.map(data, e => {
    return _.reduce(
      e,
      (acc, value, key) => {
        return {
          ...acc,
          [_.camelCase(key)]: isDateField(key)
            ? toDateFromLocaleStringDate(value as string)
            : value,
        };
      },
      {} as ActualityAnalyzedJobSummary,
    );
  });
}
