import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';

const StepFinalize: React.FC = () => {
  const { t } = useTranslation('designer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Navigation`}
        subtitle={t`Importing Layout`}
        hasStepCounter={'4.'}
      />
      <HelpContainer id={'designer/04-navigation'} hasPadding />

      {/* <HelperMessage hasMargin hasPadding>
        <div>
          {t`All the areas in our digital warehouse need to be connected to ensure that all the locations are reachable.`}
        </div>
        <div>
          {t`You need to define the starting point of the navigation as well.`}
        </div>
        <h4 className={`text-xl text-menu-active mt-6 mb-2`}>
          {t`This section consists of 2 steps:`}
        </h4>
        <div className="flex flex-col space-y-1">
          <StepListItemHelper stepCountId="1" title={t`Connect Areas`}>
            {t`Align areas between each other to represent your physical warehouse layout.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="2" title={t`Define Starting point`}>
            {t`Starting point represents the beginning of the picking path per area.`}
          </StepListItemHelper>
        </div>
      </HelperMessage> */}
    </PanelBody>
  );
};

export default StepFinalize;
