import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import PolicyRuleSectionTitle from '../../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../../components/policies/PolicyWell';
import ResourcePolicyAgent from '../../resourcePolicy/ResourcePolicyAgent';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import {
  optimisationSetupResourceAgentById,
  optimisationSetupResourcePolicy,
} from '../store/optimisationSetup.state';
import { simulationCurrent } from '../store/simulation.state';

const OptimisationResourcePolicyPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [sim, updateSim] = useRecoilState(simulationCurrent);
  const policy = useRecoilValue(optimisationSetupResourcePolicy);
  const updateSimulation = useUpdateSimulation();

  const policyRuleCounter = policy?.agents?.length;
  const hasRules = policyRuleCounter > 0;

  function resetResourcePolicy(isClone: boolean) {
    const optimizationSettings = {
      ...(sim.optimizationSettings ?? {}),
      policyOverrides: {
        ...(sim.optimizationSettings?.policyOverrides ?? {}),
        resourcePolicy: isClone ? sim.resourcePolicy : null,
      },
    };

    updateSim({
      ...sim,
      optimizationSettings,
    });
    updateSimulation({
      optimizationSettings,
    });
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={t`Resource Policy Overrides `}
        subtitle={t`Optimisation`}
        isSticky
        helpNavTo={'simulation/policies/policy-resource/policy-resource'}
        icon={Icon.PolicyPickingAgents}
      />
      <TitleSection
        title={
          <PolicyRuleSectionTitle
            title={t`Policy Overrides`}
            isFeasible={hasRules}
            counter={policyRuleCounter}
          />
        }
        inPanelView
        className="top-12 xl:top-20 z-400"
        hasScreenTitle
        hasAction={
          <ButtonSwitchMulti
            buttonType="header"
            autoSize
            options={[{ label: t`off` }, { label: t`on` }]}
            selectedIndex={policy ? 1 : 0}
            onClick={index => {
              resetResourcePolicy(index === 1);
            }}
          />
        }
      />
      <PolicyWell fullHeight>
        {_.isNil(policy) && (
          <InboxZero center selfCenter message={t`No Policy Overrides`} />
        )}
        {_.map(policy?.agents, (agent, index) => (
          <ResourcePolicyAgent
            key={`agent-${agent.id}`}
            agentId={agent.id}
            agentSelector={optimisationSetupResourceAgentById}
            index={index + 1}
            isCheckLoading={false}
            isDisabled={false}
            canDelete={false}
            canRename={false}
          />
        ))}
      </PolicyWell>
    </Container>
  );
};

export default OptimisationResourcePolicyPanel;
