import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export type TableHeadProps = {
  children?: React.ReactNode;
  className?: string;
};

export const TableHead = ({ children, className }: TableHeadProps) => {
  const [id] = useState<string>(_.uniqueId());

  return <thead className={classNames(className)}>{children}</thead>;
};
