import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import {
  pickingPolicyPickingRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { waypointPolicy } from '../../simulation/store/waypointPolicy/waypointPolicy.state';

const PickingPolicyWaypointsEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [pickingRule, updatePickingRule] = useRecoilState(
    pickingPolicyPickingRule({
      ruleId: selectedIdentity?.ruleId,
      pickingRuleId: selectedIdentity?.pickingRuleId,
    }),
  );
  const policy = useRecoilValue(waypointPolicy);

  if (!selectedIdentity || !pickingRule) return null;

  const rules = policy?.rules;
  const selected = pickingRule?.waypointSettings?.ruleId;

  const onSelectValue = (ruleId: string) => {
    updatePickingRule({
      ...pickingRule,
      waypointSettings: {
        ruleId,
      },
    });
  };

  return (
    <div data-component="PickingPolicyWaypointsEditor">
      <TitleSection
        id={`policy-editor-waypoints-section`}
        title={t`Waypoint rule`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              <PolicyCardItem
                key={`PolicyCardItem-Waypoint-No-Rule`}
                isActive={_.isNil(selected)}
                onClick={() => onSelectValue(null)}
                title={t`Use Layout Start/End points`}
              />

              {_.map(rules, (rule, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-Waypoint-${rule.id}`}
                    isActive={rule.id === selected}
                    onClick={() => onSelectValue(rule.id)}
                    title={rule.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default PickingPolicyWaypointsEditor;
