export type PickingPolicyReadyStatus = {
  isAnalyzeReady: boolean;
  hasOrderLineRules: boolean;
  hasPickingRules: boolean;
  isOrderLineTableReady: boolean;
  isPickingAgentsSelected: boolean;
  isUOMsSelected: boolean;
};

export type PickingRuleIdentity = {
  ruleId: string;
  pickingRuleId: string;
};

export const pickingOrderLineSelectionTypes = [
  'orderLineGroup',
  'orderLineWave',
  'orderLineWaveDate',
  'orderLineSort',
  'orderLinePicklistLineSortBy',
  'orderLineContainer',
  'orderLineWaypoints',
] as const;

type PickingOrderLineSelectionType =
  (typeof pickingOrderLineSelectionTypes)[number];
export const pickingPicklistLineSelectionTypes = [
  'picklistLineUOM',
  'picklistLineAgent',
  'picklistLineGroup',
  'picklistLineMode',
  'picklistLineWaypoints',
] as const;

type PickingPicklistLineSelectionType =
  (typeof pickingPicklistLineSelectionTypes)[number];

export type PickingPolicySelectionType =
  | PickingOrderLineSelectionType
  | PickingPicklistLineSelectionType;

export type PickingSelectionIdentity = {
  ruleId: string;
  orderLineFilterId?: string;
  pickingRuleId?: string;
  locationFilterId?: string;
  selectionTypeId?: PickingPolicySelectionType;
};

export type PickingDetailsSelectionIdentity = {
  ruleId?: string;
  showOrderLinesByRule?: boolean;
  showOrderLinesUnmatched?: boolean;
  pickingRuleId?: string;
  showLocations?: boolean;
};
