import * as React from 'react';
const PolicyReplenishmentAgentsFillIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyReplenishmentAgentsFillIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.55-2.56-5.94-3.83-9.33-3.83h0c-3.38,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM49.6,29.05c.47-3.77,3.91-6.45,7.69-5.98s6.45,3.91,5.98,7.69c-.47,3.77-3.91,6.45-7.69,5.98-3.77-.47-6.45-3.91-5.98-7.69ZM97.78,43.71v21.42c0,.95-.77,1.72-1.72,1.72h-28.48c-.95,0-1.72-.77-1.72-1.72v-5.69l-6.97-6.13,1.64,13.53,11.08,8.4c1.59,1.2,2.69,2.94,3.09,4.89l7.01,13.64c.51,2.55-1.06,4.89-3.47,5.37-2.34.46-4.61-1-5.18-3.29l-6.72-13.28-12.06-9.24-5.31,12.63c-.85,2.02-2.79,3.38-4.98,3.48l-12.77.77c-2.6.12-4.68-1.78-4.79-4.24-.11-2.38,1.68-4.41,4.03-4.62l10.92-.54,4.84-12.39-2.76-22.75c-.49-3.96,2.32-7.56,6.27-8.06,2.49-.31,4.84.69,6.37,2.52l9.75,8.9v-5.49s0,0,0-.01c0-.02,0-.04,0-.06,0-.12.02-.23.05-.34.01-.04.02-.07.04-.11.03-.1.07-.2.13-.29.01-.02.02-.05.03-.07l3.61-5.99c.31-.52.87-.83,1.48-.83h21.26c.6,0,1.16.32,1.47.83l3.5,5.8c.24.29.38.67.38,1.07,0,.06-.01.11-.02.17Z"
    />
    <path
      strokeWidth={0}
      d="M69.29,52.18l.22.2,11.51.02c2.07.06,3.75,1.74,3.79,3.84.04,2.16-1.68,3.94-3.97,3.98h-.43s-11.12-.02-11.12-.02v3.21h25.04v-18.14h-25.04v6.91Z"
    />
  </svg>
);
export default PolicyReplenishmentAgentsFillIcon;
