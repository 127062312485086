import {
  Field,
  ID,
  InputAndObjectType,
  InputType,
  ObjectType,
} from '@warebee/shared/util-backend-only-types';
import { Segment, Segment1D } from './geometry.model';

@ObjectType()
@InputType('AislePortalInput')
export class AislePortal {
  @Field(() => ID)
  aisleId1: string;
  @Field(() => ID)
  aisleId2: string;
  @Field(() => Segment)
  coords: Segment;
}

@ObjectType()
@InputType('BayPortalInput')
export class BayPortal {
  @Field(() => ID)
  aisleId: string;
  @Field(() => ID)
  bayId: string;
  @Field(() => Segment)
  coords: Segment;
  @Field(() => Segment1D)
  bayProjection: Segment1D;
}

export interface ConnectivityGraph {
  aislePortals: AislePortal[];
  bayPortals: BayPortal[];
}

@InputAndObjectType()
export class PlaneConnectivityGraph implements ConnectivityGraph {
  @Field(() => ID)
  id: string;

  @Field(() => [AislePortal])
  aislePortals: AislePortal[];

  @Field(() => [BayPortal])
  bayPortals: BayPortal[];
}

@InputAndObjectType()
export class LayoutConnectivityGraph {
  @Field(() => [PlaneConnectivityGraph])
  planes: PlaneConnectivityGraph[];
}
