import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import { assignmentExtraMetricDescriptors } from '../../feed/store/assignment.heatmap.state';
import { assignmentMetricDescriptorsMap } from './assignmentMetric.default';
import { AssignmentMetricDescriptor } from './assignmentMetric.types';

export function useAssignmentMetricDescriptors() {
  const { t } = useTranslation('app');
  const formatter = useFormatter();
  const extraDescriptors = useRecoilValue(assignmentExtraMetricDescriptors);
  const metrics: AssignmentMetricDescriptor[] = [
    {
      ...assignmentMetricDescriptorsMap.abc,
      titleCategory: t('Velocity', { ns: 'simulation' }),
      title: t('ABC+', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t(`show ABC heatmap`, { ns: 'simulation' }),
      icon: Icon.HeatMap4,
    },

    // ITEMS METRICS
    {
      ...assignmentMetricDescriptorsMap.consigneeSku,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('SKU', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show sku', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.consignee,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Consignee', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show consignee', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.skuGroup,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Item Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Item (SKU) Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.subGroup,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Sub Group', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      actionTitle: t('show Sub Group', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...assignmentMetricDescriptorsMap.stockCategory,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Stock Category', { ns: 'simulation' }),
      subTitle: t('Stock Category', { ns: 'simulation' }),
      actionTitle: t('show tStock Category', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.transportClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Transport Class', { ns: 'simulation' }),
      subTitle: t('Transport Class', { ns: 'simulation' }),
      actionTitle: t('show Transport Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.storageClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Storage Class', { ns: 'simulation' }),
      subTitle: t('Storage Class', { ns: 'simulation' }),
      actionTitle: t('show Storage Class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.pollutionClass,
      titleCategory: t('Item', { ns: 'simulation' }),
      title: t('Pollution Class', { ns: 'simulation' }),
      subTitle: t('Pollution Class', { ns: 'simulation' }),
      actionTitle: t('show pollution class', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },

    {
      ...assignmentMetricDescriptorsMap.stockQty,
      titleCategory: t('Inventory', { ns: 'simulation' }),
      title: t('Stock Qty', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.stockEACHQty,
      titleCategory: t('Inventory', { ns: 'simulation' }),
      title: t('Stock Qty (EACH)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.stockCASEQty,
      titleCategory: t('Inventory', { ns: 'simulation' }),
      title: t('Stock Qty (CASE)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    {
      ...assignmentMetricDescriptorsMap.stockPLQty,
      titleCategory: t('Inventory', { ns: 'simulation' }),
      title: t('Stock Qty (PALLET)', { ns: 'simulation' }),
      subTitle: t('Item', { ns: 'simulation' }),
      icon: Icon.CongestionMap4,
    },
    ...(extraDescriptors as any),
  ];

  return _.keyBy(metrics, d => d.type);
}
