import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import {
  formatInteger,
  formatTimeAgo,
  formatTimespan,
} from '../../common/formatHelper';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import InboxZero from '../../components/InboxZero';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { actualityResourcePolicy } from '../../feed/store/actuality.state';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import {
  feedAgentsSummary,
  feedDistinctAgents,
  feedSelectedAgentId,
} from '../store/feed.state';

const LiveFeedPanel: React.FC = () => {
  const { t } = useTranslation('feed');

  const deviceSummary = useRecoilValue(feedAgentsSummary);
  const [selectedDevice, setSelectedDevice] =
    useRecoilState(feedSelectedAgentId);

  const allAgents = useRecoilValue(feedDistinctAgents);
  const resourcePolicy = useRecoilValue(actualityResourcePolicy);
  const agentMapByResourcePolicy = _.keyBy(resourcePolicy.agents, 'id');
  const agentMapByData = _.keyBy(allAgents, 'agentId');

  function selectDevice(device: string) {
    const isReset = device === selectedDevice;
    // setViewAs(isReset ? 'total' : 'route');
    setSelectedDevice(isReset ? null : device);
  }

  const hasDeviceData = _.size(deviceSummary) > 0;

  return (
    <PanelBody data-component="LiveFeedResourcePanel">
      <div className={classNames('')}>
        <StatGroup
          title={t(hasDeviceData ? 'By Resource ({{count}})' : 'By Resource', {
            count: deviceSummary?.length ?? 0,
          })}
          helpNavTo={'live/feed'}
        >
          {!hasDeviceData && <InboxZero selfCenter message={t`No Data`} />}

          {_(deviceSummary)
            .sortBy(d => -d.totalEvents)
            .map(device => {
              const activityTimeAgo = formatTimeAgo(new Date(device.maxTime));
              const isSelected = selectedDevice === device.agentId;
              const deviceColors = getQualitativeColor(
                device.agentId,
                'dimension',
              );

              return (
                <Stat
                  key={`iot-device-${device.agentId}`}
                  inPanelMode
                  isPreview
                  isSelected={isSelected}
                  legendColor={isSelected ? null : deviceColors}
                  color={isSelected ? deviceColors : null}
                  titleElement={
                    <div className={classNames('flex w-full items-center')}>
                      <span className={classNames('flex-1')}>
                        {device.agentId}
                      </span>
                      <span
                        title={t(`timestamp: {{deviceMaxTime}}`, {
                          deviceMaxTime: new Date(device.maxTime),
                        })}
                        className={classNames('text-xxs opacity-75')}
                      >
                        {activityTimeAgo}
                      </span>
                    </div>
                  }
                  icon={getAgentIcon(
                    agentMapByResourcePolicy[
                      agentMapByData[device.agentId]?.agentType ?? ''
                    ]?.resourceTypeId as AgentCategoryId,
                  )}
                  classIcon={classNames('w-9 h-9 xl:w-12 xl:h-12')}
                  value={formatInteger(device.totalEvents)}
                  unitOfMeasure={t`tasks`}
                  isActionable
                  onClick={() => selectDevice(device.agentId)}
                >
                  {device.agentId}
                  <div className={classNames('mt-2 text-xs opacity-70')}>
                    {formatTimespan(
                      device.maxTime.getTime(),
                      device.minTime.getTime(),
                    )}
                  </div>
                </Stat>
              );
            })
            .value()}
        </StatGroup>
      </div>
    </PanelBody>
  );
};

export default LiveFeedPanel;
