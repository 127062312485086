const EditPencilIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="EditPencilIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M125.67,12.2l-4.83-4.83s-.07-.06-.1-.1l-4.94-4.94c-.86-.86-2-1.33-3.2-1.33h0c-1.21,0-2.35.47-3.2,1.33l-7.94,7.94,16.28,16.28,7.94-7.94c1.77-1.77,1.77-4.64,0-6.41Z"
    />
    <path
      strokeWidth={0}
      d="M74.33,78.09c-.4.4-.92.68-1.47.79l-25.39,5.04c-.19.04-.37.05-.56.05-.75,0-1.49-.3-2.03-.84-.68-.68-.97-1.65-.79-2.59l5.04-25.38c.11-.56.39-1.07.79-1.47l36.45-36.44H15.46c-7.97,0-14.45,6.48-14.45,14.45v80.86c0,7.97,6.48,14.46,14.45,14.46h80.86c7.97,0,14.45-6.48,14.45-14.45V41.65s-36.45,36.44-36.45,36.44ZM37.14,102.48H14.88c-2.21,0-4-1.79-4-4s1.79-4,4-4h22.26c2.21,0,4,1.79,4,4s-1.79,4-3.99,4Z"
    />
    <path
      strokeWidth={0}
      d="M54.59,57.12l-4.03,20.31,20.32-4.03,40.71-40.71s.02-.02.03-.03l2.04-2.04-16.28-16.29-42.79,42.79Z"
    />
  </svg>
);
export default EditPencilIcon;
