import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../Tw';
import { formatInteger } from '../../common/formatHelper';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { simulationIssuesSelectedLevelLocations } from '../store/simulation.issues.state';

const locationAlertColor = TwTheme.extend.colors.issues;

const SimulationDataIssuesLocationLayer: React.FC = () => {
  const locationSetLoadable = useRecoilValueLoadable(
    simulationIssuesSelectedLevelLocations,
  );

  const locationSet =
    locationSetLoadable.state === 'hasValue'
      ? locationSetLoadable.contents
      : {};

  function getLabel(location: LayoutLevelLocationFragment) {
    const itemCount = _.size(locationSet[location.locationId]?.items);
    return itemCount > 0 ? formatInteger(itemCount) : null;
  }

  function getColor(location: LayoutLevelLocationFragment): [string, string] {
    return _.has(locationSet, location.locationId)
      ? [locationAlertColor.alert, null]
      : null;
  }

  return <LocationLayer getColor={getColor} getLabel={getLabel} />;
};
export default SimulationDataIssuesLocationLayer;
