import {
  AreaConfiguration,
  SpacerTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getIndexedTitle } from '../../../common/utils';
import { Button } from '../../../components/actions/Button';
import InputText from '../../../components/inputs/InputText';
import {
  converterAreaConfiguration,
  converterSelectedAreaId,
  converterSelectedFloor,
} from './store/converter.area.state';
import {
  converterCalculatedLayout,
  converterSpacerAreaSettings,
} from './store/converter.state';
import { getTransformedAreaBox } from './store/transformation.helper';

const AddSpacerFreeObject: React.FC = () => {
  const { t } = useTranslation('designer');
  const floor = useRecoilValue(converterSelectedFloor);
  const [areaTitle, setAreaTitle] = useState<string>(t`Spacer (Area)`);
  const [areasConfig, setAreasConfig] = useRecoilState(
    converterAreaConfiguration,
  );
  const [spacerAreaSettings, setSpacerAreaSettings] = useRecoilState(
    converterSpacerAreaSettings,
  );
  const setSelectedAreaId = useSetRecoilState(converterSelectedAreaId);
  const layout = useRecoilValue(converterCalculatedLayout);

  const addSpacerArea = () => {
    const existedIds = _.map(spacerAreaSettings, a => a.id);
    const existedTitles = _.map(spacerAreaSettings, a => a.title);
    const id = getIndexedTitle(new Set(existedIds), areaTitle);

    const title = getIndexedTitle(
      new Set(existedTitles),
      areaTitle ?? t`Spaces Area`,
    );

    const spacerArea = {
      id,
      title: id,
      type: SpacerTypeOption.Aisle,
      height: 1000,
      width: 1000,
    };

    const areaToLinkConfig = _(areasConfig)
      .filter(a => a.floor === floor)
      .maxBy(cfg => cfg.x);

    let x = 0,
      y = 0;

    if (areaToLinkConfig) {
      const areaToLink = _.find(
        layout.areas,
        a => a.id === areaToLinkConfig.id,
      );

      if (areaToLink) {
        const masterAreaBox = getTransformedAreaBox(
          areaToLink,
          areaToLinkConfig,
        );
        x = masterAreaBox.xmax;
        y = masterAreaBox.ymin;
      }
    }
    const areaConfiguration: AreaConfiguration = {
      id,
      x,
      y,
      rotation: 0,
      floor: floor,
      flipX: false,
      flipY: false,
      // link: {
      //   linkOffsetX: 0,
      //   linkOffsetY: 0,
      //   linkPointType: 'bottomLeft',
      //   masterAreaId: areaToLinkConfig.id,
      //   masterLinkPointType: 'bottomRight',
      // },
    };

    setSpacerAreaSettings({
      ...spacerAreaSettings,
      [id]: spacerArea,
    });

    setTimeout(() => {
      setAreasConfig({
        ...areasConfig,
        [id]: areaConfiguration,
      });
      setSelectedAreaId(id);
    }, 1);
  };

  return (
    <fieldset className="flex w-full items-stretch">
      <InputText
        label={t`New Aisle Spacer (Free)`}
        value={areaTitle}
        onChange={setAreaTitle}
        className={'w-full flex-1 p-2 text-xs'}
      >
        <Button
          label={t`Add`}
          buttonType="primary"
          buttonSize={'xs'}
          onPress={addSpacerArea}
        />
      </InputText>
    </fieldset>
  );
};

export default AddSpacerFreeObject;
