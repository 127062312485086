import { atom, selector } from 'recoil';

const getKey = (postfix: string) => `warebee-error-${postfix}`;

export type ErrorDetails = {
  id: string;
  title: string;
  details?: string;
  callStack?: string;
};
export const errorContainer = atom<ErrorDetails[]>({
  key: getKey('container'),
  default: [
    // {
    //   id: nanoid(),
    //   title: 'Keep Calm, and carry on, nothing to see here, just an error',
    //   details: 'This error is manually specified in state, error.state.ts',
    //   callStack: `
    //   VM134 react_devtools_backend.js:4049 Error: connect ETIMEDOUT 13.32.123.96:443
    //   at new ApolloError (vendor.js:4254)
    //   at Object.next (vendor.js:3353)
    //   at notifySubscription (vendor.js:208420)
    //   at onNotify (vendor.js:208464)
    //   at SubscriptionObserver.next (vendor.js:208520)
    //   at vendor.js:9220
    //   at Array.forEach (<anonymous>)
    //   at iterateObserversSafely (vendor.js:9220)
    //   at Object.next (vendor.js:9004)
    //   at notifySubscription (vendor.js:208420)`,
    // },
    // {
    //   id: nanoid(),
    //   title: 'Keep Calm, and carry on, nothing to see here, just an error',
    //   details: 'This error is manually specified in state, error.state.ts',
    //   callStack: `
    //   VM134 react_devtools_backend.js:4049 Error: connect ETIMEDOUT 13.32.123.96:443
    //   at new ApolloError (vendor.js:4254)
    //   at Object.next (vendor.js:3353)
    //   at notifySubscription (vendor.js:208420)
    //   at onNotify (vendor.js:208464)
    //   at SubscriptionObserver.next (vendor.js:208520)
    //   at vendor.js:9220
    //   at Array.forEach (<anonymous>)
    //   at iterateObserversSafely (vendor.js:9220)
    //   at Object.next (vendor.js:9004)
    //   at notifySubscription (vendor.js:208420)`,
    // },
  ],
});

export const errorAppender = selector<ErrorDetails>({
  key: getKey('appender'),
  get: () => null,
  set: ({ get, set }, value: ErrorDetails) => {
    set(errorContainer, [...get(errorContainer), value]);
  },
});
