import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { analyticsAgent } from '../../AnalyticTracker';

export type ItemListCardProps = PropsWithChildren & {
  className?: string;
  titleTrace?: string;
  label?: string;
  isLoading?: boolean;
  isActionable?: boolean;
  isDemo?: boolean;
  isHelper?: boolean;
  isHero?: boolean;
  fullWidth?: boolean;
  id?: string;
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const ItemListCard = (props: ItemListCardProps) => {
  return (
    <div
      role={props.isActionable ? 'button' : 'banner'}
      data-component={`${
        props.isActionable
          ? 'ItemListCardActionable'
          : props.isActionable
            ? 'ItemListCardHelper'
            : 'ItemListCard'
      }`}
      data-label={`ItemListCard-${props.title || ''}`}
      aria-label={`ItemListCard-${
        props.label ? props.label : props.isDemo ? 'isDemo' : props.title || ''
      }`}
      key={props.id}
      id={props.id}
      className={classNames(
        'relative group isolate p-4',
        props.isActionable
          ? 'isActionable transition duration-400 transform hover:scale-103 cursor-pointer bg-app-card text-app-card-title border border-app-background/50 hover:border hover:border-menu-active/30 hover:shadow-2xl hover:z-10'
          : props.isDemo
            ? 'bg-app-card text-app-card-title shadow-xl hover:shadow-2xl rounded-xl'
            : props.isHero
              ? 'bg-menu-active text-menu-active-text shadow-xl hover:shadow-2xl'
              : 'cursor-default bg-app-card/50 text-app-card-title',
        props.isHelper
          ? 'flex-1 min-w-full md:min-w-half'
          : props.isDemo || props.isHero
            ? 'flex-1'
            : 'flex-1 min-w-full sm:min-w-full md:min-w-card-md lg:min-w-card-lg xl:min-w-card-lg 2xl:min-w-card-2xl',
        props.fullWidth
          ? 'w-full'
          : 'md:max-w-1/2 mb-px- mb-0.5 ltr:mr-0.5 rtl:ml-0.5',
        props.className,
      )}
      onClick={e => {
        props.onClick && props.onClick(e);
        try {
          analyticsAgent?.track(
            `Card: ${props.title ?? ''} ${props.label ?? ''} ${
              props.titleTrace ?? ''
            }`,
          );
        } catch (ex) {
          console.debug('analyticsAgent Error:', ex);
        }
      }}
    >
      {props.children}
    </div>
  );
};
