import {
  BatchJobEngine,
  ImportJobImportType,
  ImportJobSortOption,
  ImportJobsSortBy,
  LoadImportJobsDocument,
  SortDirection,
  useRestartImportJobsMutation,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  ImportJobsConverterConfig,
  ImportJobsDataRow,
  ImportJobsExportJobParams,
  getImportJobsTableRows,
  importJobsExportJobParams,
} from '@warebee/shared/export-converter';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import { Button } from '../components/actions/Button';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../components/actions/ButtonSwitchMulti';
import { Container } from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import { ActionBar } from '../components/nav/ActionBar';
import {
  getDataObjectTypeOptions,
  getImportTypeFromJobType,
} from '../import/store/import.default';
import ImportPipelineFileUploaderByType from './ImportPipelineFileUploaderByType';
import useLoadImportJobs from './hooks/useLoadImportJobs';
import {
  importJobsData,
  importJobsDataStatus,
  importJobsDataTableState,
} from './store/importJobs.state';
import { ImportJobsDataColumn } from './store/importJobs.types';
import useImportJobsTableConfig from './useImportJobsTableConfig';

const sortFieldsMapping: Partial<
  Record<ImportJobsDataColumn, ImportJobSortOption>
> = {
  createdAt: ImportJobSortOption.CREATED_AT,
  updatedAt: ImportJobSortOption.UPDATED_AT,
};

export type ImportJobsTableProps = {
  importType: ImportJobImportType;
  targetId?: string;
};

const ImportJobsTable: React.FC<ImportJobsTableProps> = props => {
  const { t } = useTranslation('simulation');
  const importJobs = useRecoilValue(importJobsData);
  const loadStatus = useRecoilValue(importJobsDataStatus);
  const [state, setState] = useRecoilState(importJobsDataTableState);
  const [runExportCSV] = useRunExportJobMutation();
  const [loadCallback, cancelLoad, reset] = useLoadImportJobs();
  const { searchValues, sortValues } = state;
  const columnsConfig = useImportJobsTableConfig({
    withTargetColumns: _.isNil(props.targetId),
  });
  const [selectedJobs, setSelectedJobs] = useState<ImportJobsDataRow[]>([]);
  const [engine, setEngine] = useState<BatchJobEngine>(null);
  const [restartStatus, setRestartStatus] = useState<AsyncLoadStatus>(
    AsyncLoadStatus.Ok,
  );
  const [restartImportJobs] = useRestartImportJobsMutation();

  const dataTypeOptions = getDataObjectTypeOptions(t);

  const dataType = getImportTypeFromJobType(props.importType);

  const currentImportTypeOption = dataTypeOptions.find(
    option => option.id === props.importType,
  );
  const objectType = currentImportTypeOption
    ? currentImportTypeOption.title
    : t`Unknown Import Type`;

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  // function getFilter(): ImportJobsItemFilter {
  //   return {
  //     status: searchValues?.status
  //       ? [searchValues.status as ImportJobsItemStatus]
  //       : null,
  //     locationRuleId: searchValues?.locationRuleIds
  //       ? [searchValues.locationRuleIds]
  //       : null,
  //     itemRuleId: searchValues?.itemRuleIds ? [searchValues.itemRuleIds] : null,
  //     violatedRuleId: searchValues?.violatedRuleIds
  //       ? [searchValues.violatedRuleIds]
  //       : null,
  //   };
  // }

  function getSortBy(): ImportJobsSortBy[] {
    return _.map(sortValues, (value, key, index) => {
      const sortObjectKey = sortFieldsMapping[key];
      return sortObjectKey ? { field: sortObjectKey, direction: value } : null;
    }).filter(i => !!i);
  }

  function callDataLoad(
    searchValues: Partial<Record<ImportJobsDataColumn, string>>,
    sortValues: Partial<Record<ImportJobsDataColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    //const filter: ImportJobsFilter = getFilter();
    const sortBy: ImportJobsSortBy[] = getSortBy();
    cancelLoad();
    loadCallback({
      importType: props.importType,
      targetId: props.targetId,
      //filter,
      //sortBy,
      ...page,
    });
  }

  const converterConfig: ImportJobsConverterConfig = {
    columnsConfig,
  };

  async function startExportCSV() {
    const variables: ImportJobsExportJobParams = {
      ...importJobsExportJobParams,
      query: LoadImportJobsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        filter: {
          importType: [props.importType],
          targetId: props.targetId ? [props.targetId] : null,
        },
        sortBy: getSortBy(),
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  async function restartJobs(withUpdate: boolean) {
    setRestartStatus(AsyncLoadStatus.Loading);
    try {
      const { data, errors } = await restartImportJobs({
        variables: {
          input: {
            ids: _.map(selectedJobs, v => v.id),
            options: {
              restart: true,
              updateSettingsFromPipeline: withUpdate,
              engine: engine,
            },
          },
        },
      });

      if (!_.isNil(errors)) {
        console.error(errors);
        setRestartStatus(AsyncLoadStatus.Error);
      } else {
        setRestartStatus(AsyncLoadStatus.Ok);
      }
    } catch (err) {
      setRestartStatus(AsyncLoadStatus.Error);
    }
  }

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = importJobs?.content?.length || 0;
  const totalCount = importJobs?.totalCount ?? 0;

  const flattenData = getImportJobsTableRows(
    importJobs?.content,
    converterConfig,
  );

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  const enginesOptions: (ButtonSwitchMultiOption & {
    engine: BatchJobEngine;
  })[] = [
    {
      engine: null,
      label: t`Auto (Default)`,
      description: t`Default option for most import jobs, auto-selected based on data size`,
    },
    {
      engine: BatchJobEngine.LAMBDA,
      label: t`Quick`,
      description: t`Best for quick jobs with smaller files`,
    },
    {
      engine: BatchJobEngine.FARGATE,
      label: t`Bulk`,
      description: t`Handles large-scale imports and heavy workloads`,
    },
  ];

  const actionButtons = [
    {
      label: t('Restart (Job Settings)'),
      description: t`Restarts the import job with the current settings`,
      onPress: () => restartJobs(false),
    },
    {
      label: t('Restart (Pipeline Settings)'),
      description: t`Restarts the import job with the pipeline settings`,
      onPress: () => restartJobs(true),
    },
  ];

  return (
    <Container hasOverflowY col>
      <DatasetTable
        id={'import-jobs-table'}
        subtitle={t`Filtered Items for`}
        title={t('{{objectType}} Import jobs', {
          objectType: objectType,
        })}
        //  data
        columnsConfig={columnsConfig}
        keyFields={['id']}
        data={flattenData}
        //  actions
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        onStartExportClick={startExportCSV}
        //  status
        isLoading={isLoading}
        //  style and design
        hasCounter
        isActionable
        isSticky
        checkedValues={selectedJobs}
        hasCheckboxes
        onRowCheck={(row, index, isSelected) => {
          const newChecked = isSelected
            ? [...selectedJobs, row]
            : _.filter(selectedJobs, current => current.id !== row.id);
          setSelectedJobs(newChecked);
        }}
        actionSection={
          <div className="mx-1 flex w-full flex-1 items-center space-x-2">
            <ImportPipelineFileUploaderByType
              viewMode="table"
              dataType={dataType}
            />
            <Spacer flexspace />
          </div>
        }
        actionBar={
          selectedJobs.length > 0 ? (
            <ActionBar className="space-x-2 p-1">
              {actionButtons.map((button, index) => (
                <Button
                  key={index}
                  className={classNames(
                    'rounded-full',
                    'text-menu-active text-xxs',
                  )}
                  buttonSize={'xs'}
                  label={t('{{label}} ({{count}})', {
                    label: button.label,
                    count: selectedJobs.length,
                  })}
                  titleAlt={t('{{description}}', {
                    description: button.description,
                  })}
                  buttonType={
                    restartStatus === AsyncLoadStatus.Error
                      ? 'delete'
                      : 'secondary'
                  }
                  isLoading={restartStatus === AsyncLoadStatus.Loading}
                  isDisabled={restartStatus === AsyncLoadStatus.Loading}
                  onPress={button.onPress}
                />
              ))}

              <Spacer flexspace />

              <ButtonSwitchMulti
                buttonType="minimal"
                autoSize
                options={enginesOptions}
                selectedIndex={_.findIndex(
                  enginesOptions,
                  o => o.engine == engine,
                )}
                onClick={index => setEngine(enginesOptions[index]?.engine)}
              />
            </ActionBar>
          ) : null
        }
      />
    </Container>
  );
};

export default ImportJobsTable;
