import {
  PickingRequirementsBatchSettings,
  PickingRequirementsBatchSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputNumber from '../../components/inputs/InputNumber';
import { getPickingRequirementsBatchSettingsOptions } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';

interface PickingRequirementsBatchSettingsEditorProps {
  value?: PickingRequirementsBatchSettingsFragment;
  onChange?: (value: PickingRequirementsBatchSettingsFragment) => void;
}

export const PickingRequirementsBatchSettingsEditor: React.FC<
  PickingRequirementsBatchSettingsEditorProps
> = ({ value, onChange }) => {
  const { t } = useTranslation('simulation');
  const batchSettingsOptions = getPickingRequirementsBatchSettingsOptions(t);

  const handleChange = (
    field: keyof PickingRequirementsBatchSettings,
    newValue: number | null,
  ) => {
    const updatedValue = {
      ...value,
      [field]: newValue,
    };
    onChange(updatedValue);
  };

  return (
    <div className="flex flex-col gap-2">
      {batchSettingsOptions.map(option => (
        <InputNumber
          key={option.id}
          title={option.title}
          value={value[option.id] as number}
          onChange={newValue => handleChange(option.id, newValue)}
          debounceInterval={500}
        />
      ))}
    </div>
  );
};
