import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, useState } from 'react';
import HelpNavigate from '../../helpContext/HelpNavigate';
import { HelpIndex as HelpId } from '../../helpContext/help.default';
import { StepCounter } from '../StepCounter';
import { NavBack } from '../nav/NavBack';
import { SubTitle } from './SubTitle';
import { Title } from './Title';

export type ScreenTitleProps = {
  children?: React.ReactNode;
  className?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  title?: string;
  subtitle?: string;
  navBack?: boolean;
  navHome?: boolean;
  hasPadding?: boolean;
  hasStepCounter?: string;
  isFixed?: boolean;
  isSticky?: boolean;
  isHero?: boolean;
  truncate?: boolean;
  fixedHeight?: boolean;
  helpNavTo?: HelpId;
};

export const ScreenTitle = ({
  title,
  subtitle,
  navBack,
  navHome,
  hasPadding,
  hasStepCounter,
  isFixed,
  isSticky,
  isHero,
  truncate,
  className,
  icon,
  children,
  fixedHeight,
  helpNavTo,
}: ScreenTitleProps) => {
  const [id] = useState<string>(_.uniqueId());
  const IconTitle = icon;

  return (
    <div
      data-component={`ScreenTitle`}
      data-label={`title-${title || ''}`}
      aria-label={`title-${title || ''}`}
      className={classNames(
        'z-40',
        'flex w-full items-center',
        {
          'bg-sidebar-title/75 saturate-110 sticky top-0 backdrop-blur ltr:left-0 rtl:right-0':
            isSticky,
        },
        {
          fixed: isFixed,
        },
        {
          'bg-app-panel/75': isHero,
        },
        {
          'px-screen': hasPadding,
        },
        // {
        //   'h-20': fixedHeight,
        // },
        'h-header_screen',
        className,
      )}
    >
      {navBack && <NavBack isHeaderBack isTitle />}
      {hasStepCounter && <StepCounter>{hasStepCounter}</StepCounter>}

      {icon && (
        <IconTitle
          className={classNames(
            'fill-current',
            'text-menu-icon',
            'h-10 w-10 xl:h-12 xl:w-12',
            'ltr:ml-2 xl:ltr:ml-4 rtl:mr-2 xl:rtl:mr-4',
          )}
        />
      )}

      <div
        id={id}
        className={classNames(
          'text-menu-text flex-1',
          'text-start',
          {
            'ltr:pl-2 rtl:pr-2': hasStepCounter,
          },
          icon ? 'px-1 py-2 xl:px-2 xl:py-4' : 'p-default',
        )}
      >
        <SubTitle subtitle={subtitle} />
        <Title title={title} truncate={truncate} />
      </div>
      {helpNavTo && <HelpNavigate href={helpNavTo} />}
      {children}
    </div>
  );
};
