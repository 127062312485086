import { AssignmentSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import { Card } from '../components/cards/Card';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import { StatListItem } from '../components/stats/StatListItem';

export type DashboardCardProps = {
  summary: AssignmentSummaryFragment;
  itemLimit?: number | 10;
  isActionable?: boolean;
};

const AssignmentDashboardCard: React.FC<DashboardCardProps> = props => {
  const { t } = useTranslation('simulation');

  const { summary } = props;
  const { itemLimit } = props;

  return (
    <Card
      transparent
      isActionable={props.isActionable}
      label={`AssignmentDashboardCard`}
    >
      <StatGroup
        helpNavTo={'simulation/dataset/simulation-dataset-stats-assignment'}
      >
        <Stat
          // isActionable={props.isActionable}
          dashboardView
          isPreview
          title={t`Items`}
          // value={summary.totalProductsCount}
          value={formatInteger(summary?.totalProductsCount).toString()}
        />
        <Stat
          // isActionable={props.isActionable}
          dashboardView
          isPreview
          title={t`Occupied Locations`}
          value={formatInteger(summary?.totalLocationsCount).toString()}
        />
        <Stat
          // isActionable={props.isActionable}
          dashboardView
          isPreview
          title={t`Assignment lines`}
          // value={summary.totalItemsCount}
          value={formatInteger(summary?.totalItemsCount).toString()}
        />
        <Stat dashboardView isPreview title={t`Assignments by Client`}>
          {summary?.consignees && summary.consignees.length > 0 ? (
            _.take(
              _.sortBy(summary.consignees, itemLimit => -itemLimit.itemsCount),
              itemLimit,
            ).map(cons => (
              <StatListItem
                key={`si-by-assignment-${cons.consignee}`}
                title={cons.consignee}
                value={formatInteger(cons.itemsCount).toString()}
              />
            ))
          ) : (
            <StatListItem title={t`No assignments available`} value="-" />
          )}
        </Stat>
      </StatGroup>
    </Card>
  );
};

export default AssignmentDashboardCard;
