import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getHeatmapBucketByValue } from '../common/heatmap.helper';
import useFormatter from '../common/useFormatter';
import { StatListItem } from '../components/stats/StatListItem';
import { getAssignmentStatItemMetricDescriptorsMap } from './assignmentStats.default';
import {
  assignmentStatsBuckets,
  assignmentStatsItemMetric,
  assignmentStatsLocationsByLevel,
  assignmentStatsRangeDescriptor,
} from './assignmentStats.state';
import { AssignmentLocationItemStat } from './assignmentStats.type';

export type AssignmentStatsLocationInfoActiveProps = {
  locationId: string;
};

const AssignmentStatsLocationInfoActive: React.FC<
  AssignmentStatsLocationInfoActiveProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const locationMap = useRecoilValue(assignmentStatsLocationsByLevel);
  const rangeDescriptor = useRecoilValue(assignmentStatsRangeDescriptor);
  const buckets = useRecoilValue(assignmentStatsBuckets);
  const selectedMetric = useRecoilValue(assignmentStatsItemMetric);

  const metricDescriptors = getAssignmentStatItemMetricDescriptorsMap(
    t,
    formatter,
  );
  const locationData = locationMap?.[props.locationId];
  if (_.isNil(locationData)) {
    return null;
  }
  function getMetrics(locationItem: AssignmentLocationItemStat) {
    const descriptor = metricDescriptors[selectedMetric];
    const valueRaw = _.get(locationItem, descriptor.path);
    if (_.isNil(valueRaw)) return null;

    const valueString =
      _.isFunction(descriptor.format) && _.isNumber(valueRaw)
        ? descriptor.format(valueRaw)
        : valueRaw?.toString();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const bucket = getHeatmapBucketByValue({
      data: locationItem,
      metricDescriptor: descriptor,
      rangeDescriptor,
      buckets: _.keyBy(buckets, b => b.id),
    });
    const background = bucket?.color;
    const color = bucket?.textColor;

    return (
      <StatListItem
        key={`item-${locationItem.item?.id}-${descriptor.type}`}
        title={locationItem?.item?.sku}
        value={valueString}
        className="min-w-20 bg-app-panel-dark/70 backdrop-filter backdrop-blur-lg backdrop-saturate-110 px-2 text-xs uppercase !my-0"
        valueClass="ml-2"
        valueStyle={{
          color,
          background,
        }}
      />
    );
  }

  function getItems(items: AssignmentLocationItemStat[]) {
    return _(items)
      .sortBy(item => -item?.item?.sku)
      .map(row => {
        const p = row.item;
        return getMetrics(row);
      })
      .value();
  }

  return <>{getItems(locationData)}</>;
};

export default AssignmentStatsLocationInfoActive;
