import * as React from 'react';
const SimulationComplianceIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationComplianceIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.46,35.26c-.29-.89-1.23-1.4-2.13-1.17-.12.03-4.9,1.18-12.24,1.03-.85,12.61-6.21,22.12-16.15,28.78-13.43,9-25.44,14.64-25.95,14.88l-1.89.88L10.08,48.47l-7.57,4.37c-.55.32-.89.91-.89,1.55s.34,1.23.89,1.55l60.75,35.07c.28.16.58.24.89.24s.61-.08.88-.23l30.65-17.48c1.37-.73,33.37-18.15,26.77-38.27Z"
      opacity={0.7}
    />
    <g opacity={0.6}>
      <path
        strokeWidth={0}
        d="M101.75,10.61s-19.41,12.63-37.07-6.96L3.79,38.81l60.89,35.15s12.08-5.64,25.41-14.58c14.58-9.76,17.57-26.07,11.66-48.78ZM53.35,52.19l-9.53-26.77,46.04,4.67-36.51,22.1Z"
      />
    </g>
    <polygon
      strokeWidth={0}
      points="55.52 47.06 48.94 28.56 80.76 31.78 55.52 47.06"
    />
    <path
      strokeWidth={0}
      d="M.75,70.36l63.93,36.91,63.5-36.9-9.55-5.51-52.95,30.77h-2.16s-53.3-30.77-53.3-30.77l-9.46,5.5Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M.75,87.31l63.93,36.91,63.5-36.9-9.55-5.51-52.95,30.77h-2.16s-53.3-30.77-53.3-30.77l-9.46,5.5Z"
    />
  </svg>
);
export default SimulationComplianceIcon;
