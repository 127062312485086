import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HelperMessage } from '../components/HelperMessage';
import { Alert } from '../components/alerts/Alerts';
import * as Icon from '../components/icons';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import { AgentCategoryId } from '../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../resourcePolicy/agentData/agentDataHelper';
import { resourcePolicyAgentsMap } from '../simulation/store/resourcePolicy.state';
import {
  routingPolicyRule,
  routingPolicySelectedIdentity,
} from '../simulation/store/routingPolicy/routingPolicy.state';
import { sidebarStateByType } from '../store/sidebar.state';
import RoutingPolicyFeaturesRule from './RoutingPolicyFeaturesRule';

export type RoutingPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;

  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
};

const RoutingPolicyRule: React.FC<RoutingPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const [rule, updateRule] = useRecoilState(routingPolicyRule(props.ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    routingPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-routing-policy-editor'),
  );
  const agents = useRecoilValue(resourcePolicyAgentsMap);

  function select(isAgent: boolean, isConfig: boolean) {
    const shouldSelect = isAgent || isConfig;
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        isRoutingAgentSelected: props.isDisabled ? false : isAgent,
        isRoutingConfigurationSelected: props.isDisabled ? false : isConfig,
      });
    }

    if (!props.isDisabled) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: !policyEditPanelState.isPinned
          ? !shouldSelect
          : policyEditPanelState.isCollapsed,
        isHidden: !shouldSelect,
      });
    }
  }

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }
  const currentAgents = _.filter(rule.agentIds, id => _.has(agents, id));
  const isActive = selectedIdentity?.ruleId === rule.id;
  const isActiveAgentFilters =
    isActive && selectedIdentity?.isRoutingAgentSelected;

  const hasIssue = false;
  const hasAgentIssue = _.isEmpty(currentAgents);

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isDisabled: props.isDisabled,
    isActive,
    hasIssue,

    isNew: props.isNew,
    orderCounter: props.index,

    hasIcon: true,
    hasColorMode: false,
    hasOrder: true,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
  };

  return (
    <RuleContainer
      dataComponent="RoutingPolicyRule"
      {...containerParams}
      // stickyTop={6.5}
      className={classNames({
        'bg-menu-active/40': isActive,
        'bg-menu': !isActive,
      })}
    >
      <RoutingPolicyFeaturesRule
        ruleId={props.ruleId}
        featureRules={rule.featureRules}
      />
      {hasIssue && (
        <div className="m-1">
          <Alert
            messageHelper={t`WARNING`}
            message={t`Issues detected`}
            hasStatusAlert
          />
        </div>
      )}
      {hasAgentIssue && (
        <div className={classNames('p-1 pt-2')}>
          <HelperMessage
            className={classNames(
              'border border-alerts-alert border-opacity-50 bg-alerts-alert text-menu-text p-1 rounded',
            )}
            isError
          >
            <div className="flex">
              <Icon.TriangleInfo
                className={classNames(
                  'w-5 h-5 fill-current ltr:ml-1 rtl:mr-1 ltr:mr-2 rtl:ml-2',
                )}
              />
              {t`No Agent selected`}
            </div>
          </HelperMessage>
        </div>
      )}
      {/* <StepListItemHelper
        isPrimary
        isActive={isActiveAgentFilters}
        hasIssue={hasAgentIssue}
        className={classNames(
          'text-xl ltr:pl-2 rtl:pr-2 py-2 pt-6 items-center',
          {
            'text-menu-text': isActiveAgentFilters,
            'text-menu-active': !isActiveAgentFilters && !hasAgentIssue,
            'text-alerts-alert': hasAgentIssue,
          },
        )}
        // stepCountSize="policy"
        // stepCountId={t`A`}
        // title={t`Agents`}
      /> */}
      <PolicyFilter
        key={`PolicyFilter-for-agents-${rule.id}`}
        label={t`Agents`}
        isActive={isActiveAgentFilters}
        isDisabled={props.isDisabled}
        hasIssue={hasAgentIssue}
        onClick={() => select(!isActiveAgentFilters, false)}
      >
        {_.map(currentAgents, agentId => {
          const agent = agents[agentId];
          return (
            <PolicyFilterItem
              key={`PolicyFilterItem-for-agents-${agentId}`}
              isDisabled={props.isDisabled}
              isActive={isActiveAgentFilters}
              icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
              iconClassName={classNames(
                'w-8 h-8',
                // 'opacity-50',
                'ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
              )}
              value={agent?.title}
            />
          );
        })}

        {_.isEmpty(currentAgents) && (
          <PolicyFilterItem
            key={`PolicyFilterItem-for-empty-agents`}
            isDisabled={props.isDisabled}
            isActive={isActiveAgentFilters}
            value={t`No Agent selected`}
          />
        )}
      </PolicyFilter>
    </RuleContainer>
  );
};

export default RoutingPolicyRule;
