import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import LoadingIndicator from '../../../components/LoadingIndicator';

type IssuesSummaryState = 'no-dataset' | 'loading' | 'calculation';

export type IssuesSummaryLoadingPlaceholderProps = {
  title: string;
  subTitle?: string;
  state: IssuesSummaryState;
  progress?: number;
};

const IssuesSummaryLoadingPlaceholder: React.FC<
  IssuesSummaryLoadingPlaceholderProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  let message: string;
  switch (props.state) {
    case 'loading':
      message = t`Loading Stats Data...`;
      break;

    case 'no-dataset':
      message = t`No Data...`;
      break;

    case 'calculation':
      message = t(`Analysing Data ({{progress}})`, {
        progress: formatter.formatShare((props.progress ?? 0) / 100)
          ?.fullString,
      });
      break;
  }
  return (
    <>
      {/* // <StatGroup
    //   title={props.title}
    //   subTitle={props.subTitle ?? t`Issues:`}
    //   helpNavTo={'simulation/dataset/simulation-dataset-issues'}
    // > */}
      {props.state === 'no-dataset' ? (
        <InboxZero message={message} />
      ) : (
        <InboxZero>
          <LoadingIndicator transparent message={message} />
        </InboxZero>
      )}
      {/* // </StatGroup> */}
    </>
  );
};

export default IssuesSummaryLoadingPlaceholder;
