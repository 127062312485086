import {
  ItemSetIssueInvalidItemFragment,
  ItemSetIssueInvalidItemReason,
  LoadItemSetItemIssueDataQuery,
  LoadItemSetItemIssueDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import {
  ColumnConfigBase,
  ExportConverter,
  ExportJobParams,
  NULL_VALUE,
} from './types';

export type ItemsIssuesExportJobParams = ExportJobParams<
  LoadItemSetItemIssueDataQueryVariables,
  ItemsIssuesConverterConfig
>;

export const ItemsIssuesExportJobParams: ItemsIssuesExportJobParams = {
  converterId: 'ITEMS_ISSUES',
  query: null,
  config: null,
  variables: null,
  filename: 'items-issues.csv',
};

type ItemsIssuesDataRow = ItemSetIssueInvalidItemFragment & {
  reasonString: string;
};

export type ItemsIssuesConverterConfig = {
  columnsConfig: ColumnConfigBase<ItemsIssuesDataRow>[];
  dictionaries: {
    reasons: Record<ItemSetIssueInvalidItemReason, string>;
  };
};

const getReasons = (
  reasonsData: ItemSetIssueInvalidItemReason[],
  reasonsMap: Record<ItemSetIssueInvalidItemReason, string>,
) => {
  if (_.isEmpty(reasonsData)) return NULL_VALUE;
  return _(reasonsData)
    .map(r => `${reasonsMap[r]}`)
    .join(', ');
};

export const getItemsIssuesTableRows = (
  data: ItemSetIssueInvalidItemFragment[],
  config: ItemsIssuesConverterConfig,
): ItemsIssuesDataRow[] => {
  const getItemsIssuesDataRow = (row: ItemSetIssueInvalidItemFragment) => {
    return {
      ...row,
      reasonString: getReasons(row.reason, config.dictionaries.reasons),
    } as ItemsIssuesDataRow;
  };

  return _.map(data, getItemsIssuesDataRow);
};

export const ITEMS_ISSUES_EXPORT_CONVERTER: ExportConverter<
  LoadItemSetItemIssueDataQuery,
  ItemsIssuesConverterConfig
> = (input, config) => {
  const data = getItemsIssuesTableRows(
    input.itemSetIssueSet?.invalidItems?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
