import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { getLocationWeightColor } from '../store/weightCompliance.helper';
import { weightComplianceLevelLocation } from '../store/weightCompliance.state';

const WeightComplianceLocationLayer: React.FC = () => {
  const levelLocationLoadable = useRecoilValueLoadable(
    weightComplianceLevelLocation,
  );

  const levelLocation =
    levelLocationLoadable.state === 'hasValue'
      ? levelLocationLoadable.contents
      : null;

  return (
    <LocationLayer
      getColor={loc => {
        const locWeight = levelLocation?.[loc.locationId];
        return locWeight ? getLocationWeightColor(locWeight) : null;
      }}
    />
  );
};
export default WeightComplianceLocationLayer;
