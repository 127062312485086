import * as React from 'react';
const LayoutRoutes3Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayoutRoutes3Icon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="63.04 82.89 17.71 97.87 17.71 124 110.29 124 110.29 97.87 63.04 82.89"
    />
    <path
      strokeWidth={0}
      d="M47.78,19.39h32.45c1.35,0,2.46-1.1,2.46-2.45v-4.27c0-1.37-1.1-2.45-2.46-2.45h-10.01c0-3.44-2.77-6.21-6.21-6.21s-6.21,2.77-6.21,6.21h-10.02c-1.35,0-2.45,1.08-2.45,2.45v4.27c0,1.35,1.1,2.45,2.45,2.45ZM64,7.76c1.35,0,2.46,1.08,2.46,2.45s-1.1,2.45-2.46,2.45-2.45-1.1-2.45-2.45,1.1-2.45,2.45-2.45Z"
    />
    <path
      strokeWidth={0}
      d="M97.67,90.44V19.71c0-2.71-2.19-4.9-4.92-4.9h-7.71v2.12c0,2.66-2.17,4.83-4.81,4.83h-32.45c-2.66,0-4.83-2.17-4.83-4.83v-2.12h-7.71c-2.71,0-4.92,2.19-4.92,4.9v70.78l32.97-10.82,34.37,10.76ZM87.41,72.31h-48.96v-6.14h48.96v6.14ZM87.6,42.56c.5,0,1.09,0,1.7,0,1.45,0,2.69,1.15,2.71,2.5.02,2.74.02,11.26,0,14.02-.01,1.42-1.36,2.76-2.77,2.76h-.08c-.32-.02-20.49-.02-22.17-.02h-1.59c-.32,0-.64,0-.96,0-.49,0-.81-.29-.89-.8-.07-.43.25-.86.73-.96.18-.04.36-.04.54-.04h24.24c.93,0,1.15-.21,1.15-1.12v-13.65c0-.75-.14-.89-.88-.89h-1.71s-1.82,0-1.82,0c.44.44,1.06,1.07,1.24,1.25.19.2.29.44.28.69-.01.24-.13.46-.33.63-.36.3-.89.25-1.28-.13l-2.76-2.68c-.2-.19-.31-.45-.31-.71s.12-.54.34-.75l2.92-2.82c.26-.25.55-.38.83-.38.22,0,.42.08.58.24.31.3.42.84-.17,1.44-.21.22-.89.93-1.35,1.41h1.83ZM38.45,56v-6.14h48.96v6.14h-48.96ZM87.41,31.62v6.14h-48.96v-6.14h48.96ZM33.87,27.76c.01-1.35,1.25-2.5,2.71-2.5.61,0,1.19,0,1.69,0h1.83c-.45-.48-1.14-1.19-1.35-1.41-.59-.6-.47-1.14-.17-1.44.16-.16.36-.24.58-.24.28,0,.57.13.83.38l2.92,2.82c.22.21.34.48.34.75s-.11.52-.31.71l-2.76,2.68c-.4.38-.92.43-1.28.13-.2-.16-.31-.39-.33-.63-.01-.25.09-.49.28-.69.18-.18.8-.81,1.24-1.25h-1.82s-1.71,0-1.71,0c-.74,0-.88.14-.88.89v13.65c0,.91.21,1.12,1.15,1.12h42.4c.18,0,.36,0,.54.04.48.1.8.53.73.96-.08.51-.4.79-.89.8-.32,0-.64,0-.96,0h-1.59c-1.68,0-40,0-40.32.02h-.08c-1.41,0-2.76-1.34-2.77-2.76-.03-2.75-.03-11.27,0-14.02ZM61.44,77.54c-.32,0-.64,0-.96,0h-1.59c-1.68,0-22.25.02-22.25.02-1.41,0-2.76-1.34-2.77-2.76-.02-2.75-.02-9.36,0-12.1.01-1.35,1.25-2.5,2.71-2.5.61,0,19.35,0,19.85,0h1.83c-.46-.48-1.14-1.19-1.35-1.41-.59-.6-.47-1.14-.17-1.44.16-.16.36-.24.58-.24.28,0,.57.13.83.38l2.92,2.82c.22.21.34.48.34.75s-.11.52-.31.71l-2.76,2.68c-.39.38-.92.43-1.28.13-.2-.16-.31-.39-.33-.63-.01-.25.09-.49.28-.69.18-.18.8-.81,1.24-1.25h-1.82s-19.87,0-19.87,0c-.74,0-.88.14-.88.89v11.74c0,.91.21,1.12,1.15,1.12h24.24c.18,0,.36,0,.54.04.48.1.8.53.73.96-.08.51-.4.79-.89.8Z"
    />
  </svg>
);
export default LayoutRoutes3Icon;
