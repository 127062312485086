import { useApolloClient } from '@apollo/client';
import {
  LoadOrderSetDocument,
  LoadOrderSetQuery,
  LoadOrderSetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  orderSetDocument,
  orderSetDocumentId,
  orderSetDocumentLoadStatus,
  orderSetItemsDataState,
} from '../store/orderSet.state';

function useLoadOrderSet() {
  const client = useApolloClient();

  const cleanupState = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        set(orderSetDocument, null);
        set(orderSetDocumentId, null);
        set(orderSetDocumentLoadStatus, AsyncLoadStatus.Loading);
        reset(orderSetItemsDataState);
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (orderSetId: string) => {
        const response = await client.query<
          LoadOrderSetQuery,
          LoadOrderSetQueryVariables
        >({
          query: LoadOrderSetDocument,
          variables: {
            orderSetId,
          },
        });

        const data = response.data.orderSet;
        set(orderSetDocument, data);
        set(orderSetDocumentId, orderSetId);
        set(orderSetDocumentLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (itemSetId: string) => {
    await cleanupState();
    await loadCallback(itemSetId);
  };
}

export default useLoadOrderSet;
