import * as React from 'react';
const CircleWaypoint5Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleWaypoint5Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,64c0-33.08-26.91-60-60-60-33.09,0-60,26.92-60,60,0,33.08,26.91,60,60,60,33.09,0,60-26.92,60-60ZM88.53,51.59l14.32,11.37c.95.63,1.47,1.79,1.47,2.95,0,1.16-.53,2.21-1.47,2.95l-14.32,11.37c-1.16.84-2.74,1.05-4,.42-1.26-.63-2.11-2-2.11-3.47v-4.32s-13.89,0-13.89,0c-1.58-2.53-3.37-4.84-5.47-6.95,2-2.11,3.89-4.42,5.47-6.95h13.89s0-4.32,0-4.32c0-1.47.74-2.84,2.11-3.47,1.26-.63,2.84-.53,4,.42ZM21.58,72.86c-1.47,0-2.74-1.16-2.74-2.74v-8.42c0-1.47,1.16-2.74,2.74-2.74h11.89c11.79,0,21.58-9.37,22.11-21.16h-4.32c-1.47,0-2.84-.84-3.47-2.11-.32-.53-.42-1.16-.42-1.68,0-.84.32-1.58.84-2.32l11.26-14.32c.63-.95,1.79-1.47,2.95-1.47s2.32.53,3.05,1.47l11.37,14.32c.84,1.16,1.05,2.63.42,4-.63,1.26-2,2.11-3.47,2.11h-4.42c-.53,19.47-16.53,35.05-36,35.05h-11.79ZM59.47,114.44l-11.47-14.42c-.53-.63-.84-1.47-.84-2.32,0-.63.21-1.16.42-1.68.63-1.26,2-2.11,3.47-2.11h4.32c-.32-7.26-4.21-13.58-9.89-17.37,3.05-.95,5.89-2.11,8.53-3.68,1.68-1.05,3.37-2.11,4.84-3.26.95,1.05,2,2.11,2.84,3.26,4.53,5.89,7.37,13.16,7.58,21.16h4.42c1.47,0,2.84.84,3.47,2.1.63,1.26.53,2.84-.42,4l-11.26,14.32c-.74.95-1.89,1.47-3.05,1.47s-2.21-.53-2.95-1.47Z"
    />
  </svg>
);
export default CircleWaypoint5Icon;
