import * as React from 'react';
const CursorSelectInfoIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectInfoIcon"
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 93.99 93.99">
        <path
          strokeWidth={0}
          d="M.51,4.38C-.93.81.81-.93,4.38.51l87.02,35.14c3.57,1.44,3.44,3.4-.29,4.36l-33.91,8.68c-3.73.96-7.56,4.79-8.52,8.52l-8.68,33.91c-.96,3.73-2.92,3.86-4.36.29L.51,4.38Z"
        />
      </symbol>
    </defs>
    <path
      strokeWidth={0}
      d="M90,63c-14.89,0-27,12.11-27,27s12.11,27,27,27,27-12.11,27-27-12.11-27-27-27ZM93.77,106.99c0,1.33-1.95,2.4-4.36,2.4-2.41,0-4.36-1.08-4.36-2.4v-2.4c0-1.33,1.95-2.4,4.36-2.4,2.41,0,4.36,1.08,4.36,2.4v2.4ZM93.77,94.26c0,1.33-1.95,2.4-4.36,2.4-2.41,0-4.36-1.08-4.36-2.4v-20.21c0-1.33,1.95-2.4,4.36-2.4s4.36,1.08,4.36,2.4v20.21Z"
    />
    <use
      xlinkHref="#a"
      width={93.99}
      height={93.99}
      transform="translate(4 4) scale(1.06)"
    />
  </svg>
);
export default CursorSelectInfoIcon;
