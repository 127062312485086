import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Group, Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import LocationFeature from '../../features/LocationFeature';
import {
  viewerAppliedCachingSettings,
  viewerHoveredLocation,
  viewerLevelLocations,
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdWithToggle,
  viewerShowLocationTitles,
  viewerShowLocations,
} from '../store/viewer.state';

export type LocationLayerProps = {
  filter?: (location: LayoutLevelLocationFragment) => boolean;
  getColor?: (location: LayoutLevelLocationFragment) => [string, string] | null;
  getLabelColor?: (
    location: LayoutLevelLocationFragment,
  ) => [string, string] | null;
  onClick?: (location: LayoutLevelLocationFragment) => void;
  getLabel?: (location: LayoutLevelLocationFragment) => string;
  getGradientColors?: (location: LayoutLevelLocationFragment) => string[];
  getOpacity?: (location: LayoutLevelLocationFragment) => number;
  isStaticMode?: boolean;
  isCompliance?: boolean;
  disableBayAutoSelections?: boolean;
};

const LocationLayer: React.FC<LocationLayerProps> = props => {
  const showLocations = useRecoilValue(viewerShowLocations);
  const setSelectedLocation = useSetRecoilState(
    viewerSelectedLocationIdWithToggle,
  );
  const showTitles = useRecoilValue(viewerShowLocationTitles);
  const setSelectedBay = useSetRecoilState(viewerSelectedBayIdAtom);
  const setHovered = useSetRecoilState(viewerHoveredLocation);
  const levelLocationsLoadable = useRecoilValueLoadable(viewerLevelLocations);
  const cachingSettings = useRecoilValue(viewerAppliedCachingSettings);
  const ref = useRef<Konva.Layer>(null);

  useEffect(() => {
    if (ref?.current?.isCached) {
      ref?.current?.clearCache();
    }
    if (
      cachingSettings.type === 'on' ||
      (cachingSettings.type === 'auto' && cachingSettings.ratio < 1)
    ) {
      console.debug('Cache Location layer with ratio: ', cachingSettings.ratio);
      ref?.current?.cache({
        pixelRatio: cachingSettings.ratio,
        hitCanvasPixelRatio: cachingSettings.ratio,
      });
    }
  });

  const isCachingEnabled =
    cachingSettings.type === 'on' ||
    (cachingSettings.type === 'auto' && cachingSettings.ratio < 1);

  if (levelLocationsLoadable.state !== 'hasValue' || !showLocations)
    return null;

  const levelLocations = levelLocationsLoadable.contents;

  if (_.isEmpty(levelLocations)) return null;

  function onLocationClick(loc: LayoutLevelLocationFragment) {
    props.onClick && props.onClick(loc);

    if (!props.disableBayAutoSelections) {
      setSelectedBay(loc.locationBayId);
    }
    setSelectedLocation(loc.locationId);
  }

  return (
    <Layer ref={ref}>
      {_(levelLocations)
        .filter(props.filter)
        .chunk(400)
        .map((chunk, chunkIndex) => (
          <Group key={`location-group-${chunkIndex}`}>
            {_.map(chunk, (l, index) => {
              return (
                <LocationFeature
                  key={l.locationId}
                  loc={l}
                  title={isCachingEnabled || !showTitles ? null : l.locationId}
                  onClick={() => onLocationClick(l)}
                  onMouseOver={() => setHovered(l)}
                  onMouseOut={() => setHovered(null)}
                  color={props.getColor ? props.getColor(l) : null}
                  label={props.getLabel ? props.getLabel(l) : null}
                  labelColor={
                    props.getLabelColor ? props.getLabelColor(l) : null
                  }
                  isCompliance={props.isCompliance}
                  gradientColors={
                    props.getGradientColors ? props.getGradientColors(l) : null
                  }
                  opacity={props.getOpacity ? props.getOpacity(l) : null}
                />
              );
            })}
          </Group>
        ))

        .value()}
    </Layer>
  );
};

export default React.memo(LocationLayer);
