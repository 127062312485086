const LinkLockIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkLockIcon"
    {...props}
  >
    <path d="M36.71,116.57c0,1.37.56,2.72,1.53,3.69.98.98,2.29,1.53,3.68,1.53h74.64c1.39,0,2.7-.54,3.69-1.53.97-.97,1.52-2.31,1.52-3.68v-4.19s0-70.45,0-70.45c0-1.39-.54-2.7-1.53-3.68-.98-.98-2.29-1.53-3.69-1.53h-46.71s10,10.42,10,10.42h11.42s20.07,0,20.07,0v64.22s-64.22,0-64.22,0v-16.42h-10.43" />
    <path d="M46.4,80.86h-29.76s0-64.22,0-64.22h64.22s0,15.94,0,15.94h10.42s0-21.15,0-21.15c0-2.87-2.34-5.21-5.21-5.21H11.43c-1.37,0-2.72.55-3.69,1.52-.97.97-1.53,2.31-1.53,3.68v74.64c0,1.37.56,2.71,1.53,3.68.97.97,2.31,1.53,3.68,1.53h44.97" />
    <path d="M100.1,74.46h-.09v-5.58c0-8.8-7.24-16.04-16.04-16.04-8.8,0-16.04,7.23-16.04,16.04v5.58h-.35c-2.35,0-4.27,1.92-4.27,4.27v23.36c0,2.35,1.92,4.27,4.27,4.27h32.6c2.35,0,4.27-1.92,4.27-4.27v-23.45c-.17-2.35-2-4.18-4.36-4.18ZM93.04,74.46h-18.13s0-5.58,0-5.58c0-4.97,4.01-9.06,9.07-9.06,4.97,0,9.07,4.01,9.07,9.06v5.58Z" />
    <polygon points="24.44 54.29 24.44 26.2 52.51 26.2 61.63 35.32 40.02 35.33 60.08 55.37 60.08 61.85 53.64 61.85 33.57 41.78 33.59 63.41 24.44 54.29" />
  </svg>
);
export default LinkLockIcon;
