import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ButtonToolbarProps = {
  children?: React.ReactNode;
  className?: string;
  selected?: boolean;
};

export const ButtonToolbarWrapper = ({
  children,
  className,
  selected,
}: ButtonToolbarProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      className={classNames(
        'flex items-center justify-center',
        'w-full',
        'max-h-10',
        'p-1',
        'm-1',
        'rounded',
        selected ? 'bg-menu-active shadow-md' : 'bg-menu-button/75 shadow',
        className,
      )}
    >
      {children}
    </div>
  );
};
