import { useRecoilCallback } from 'recoil';
import { analyticsAgent } from '../../../../AnalyticTracker';
import { sidebarStateByType } from '../../../../store/sidebar.state';
import { converterWizardStepId } from '../store/converter.state';
import { ConverterWizardStepId } from '../store/converter.wizard';

function useSelectConverterWizardStep() {
  const selectStepCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (stepId: ConverterWizardStepId) => {
        const wizardStepSidebar = await snapshot.getPromise(
          sidebarStateByType('sidebar-converter-wizard-step'),
        );

        if (!wizardStepSidebar.isPinned) {
          set(sidebarStateByType('sidebar-converter-wizard-step'), {
            ...wizardStepSidebar,
            isCollapsed: false,
          });
        }
        analyticsAgent?.track(`Designer: ${stepId}`);

        set(converterWizardStepId, stepId);
      },
  );
  return async (stepId: ConverterWizardStepId) => {
    await selectStepCallback(stepId);
  };
}

export default useSelectConverterWizardStep;
