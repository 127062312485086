import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ButtonOrder from '../../../../components/actions/ButtonOrder';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';

export type OrderedItemsTableProps = {
  itemColumnTitle?: string;
  items: string[];
  render?: (v: string) => React.ReactNode;
  onChange: (item: string[]) => void;
  onRowMouseEnter?: (id: string) => void;
  onRowMouseLeave?: (id: string) => void;
};

const OrderedItemsTable: React.FC<OrderedItemsTableProps> = props => {
  const [localItems, setLocalItems] = useState<string[]>([]);

  useEffect(() => {
    setLocalItems(props.items);
  }, [props.items]);

  function move(from, to) {
    const newItems = [...props.items];
    newItems.splice(to, 0, newItems.splice(from, 1)[0]);
    setLocalItems(newItems);
    props.onChange(newItems);
  }

  function onDragEnd(result: any) {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  }
  const getItemStyle = (isDragging, draggableStyle) => {
    const fixOffset = isDragging
      ? {
          top: draggableStyle.top - 80, //YA: HACK fo fix positioning
          left: 'auto !important',
        }
      : {};

    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      class: isDragging ? 'bg-menu-active/70 backdrop-blur flex' : null,
      // change background colour if dragging
      background: isDragging ? 'hsla(60, 100%, 60%, 0.5)' : null,

      // styles we need to apply on draggables
      ...draggableStyle,
      ...fixOffset,
    };
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Table
            isSticky
            isSortable
            viewAsLine
            isHoverable
            dataComponent="OrderedItemsTable"
            dragRef={provided.innerRef}
          >
            {props.itemColumnTitle && (
              <TableHead>
                <TableRow rowLine>
                  <TableTh
                    panelMode
                    hasStickyTitleBefore
                    hasWidth={2}
                  ></TableTh>
                  <TableTh
                    panelMode
                    hasStickyTitleBefore
                    value={props.itemColumnTitle}
                    selectable={true}
                  />
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {localItems.map((item, i) => (
                <Draggable key={item} draggableId={item} index={i}>
                  {(provided, snapshot) => (
                    <TableRow
                      className="hover:bg-menu-active/10 hover:rounded"
                      rowLineList
                      dragRef={provided.innerRef as any}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      dragStyle={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                      onMouseEnter={() =>
                        props.onRowMouseEnter && props.onRowMouseEnter(item)
                      }
                      onMouseLeave={() =>
                        props.onRowMouseLeave && props.onRowMouseLeave(item)
                      }
                    >
                      <TableTd cellSize="sm">
                        <div className="flex items-center">
                          <ButtonOrder
                            canMoveUp={i > 0}
                            canMoveDown={i < props.items.length - 1}
                            moveUp={() => move(i, i - 1)}
                            moveDown={() => move(i, i + 1)}
                            className="w-10"
                          />
                          <div className="mx-2 flex-1">
                            {props.render ? props.render(item) : item}
                          </div>
                        </div>

                        {/* </TableTd>
                      <TableTd cellSize="sm"> */}
                      </TableTd>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder as React.ReactNode}
            </TableBody>
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OrderedItemsTable;
