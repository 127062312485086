import * as React from 'react';
const ScreenViewSingleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ScreenViewSingleIcon"
    {...props}
  >
    <path strokeWidth={0} d="M4,4v120h120V4H4ZM18,110V18h92v92H18Z" />
  </svg>
);
export default ScreenViewSingleIcon;
