import { TFunction } from 'i18next';
import { MappingValueResolver } from './valueResolverBase';

export type BooleanValueResolverBase = MappingValueResolver<boolean>;

export function getBooleanResolverValueOptions(t: TFunction<'app'>) {
  return new Map<boolean, string>([
    [true, t(`True`, { ns: 'app' })],
    [false, t(`False`, { ns: 'app' })],
  ]);
}

export const booleanValueResolverBase: BooleanValueResolverBase = {
  true: true,
  // '1': true,
  false: false,
  // '0': false,
};

export const locationStatusResolver: BooleanValueResolverBase = {
  ...booleanValueResolverBase,
  locked: false,
  unlocked: true,
};
