import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../components/icons';
import {
  workforceAgentForecastEnabled,
  workforceSelectedAgentId,
} from '../store/workforce.state';
import classNames from 'classnames';

export type SimulationForecastSwitchProps = {
  analyzeId?: string;
};

const SimulationForecastSwitch: React.FC<
  SimulationForecastSwitchProps
> = props => {
  const { t } = useTranslation('simulation');
  const selectedAgentId = useRecoilValue(workforceSelectedAgentId);
  const [isForecastSimulation, setForecastSimulation] = useRecoilState(
    workforceAgentForecastEnabled(selectedAgentId),
  );

  const simulationOptions: ButtonSwitchMultiOption[] = [
    {
      iconLeft: true,
      icon: (
        <div className={classNames('flex items-center')}>
          <Icon.TimeFull
            className={classNames('ltr:mr-2 rtl:ml-2 w-5 h-5 fill-current')}
          />
          <span className="flex-1">{t`Current`}</span>
        </div>
      ),
      label: t`Current`,
    },
    {
      iconRight: true,
      icon: (
        <div className={classNames('flex items-center')}>
          <span className="flex-1">{t`Forecast`}</span>
          <Icon.Forecast
            className={classNames('ltr:ml-2 rtl:mr-2 w-5 h-5 fill-current')}
          />
        </div>
      ),
      label: t`Forecast simulation`,
    },
  ];

  return (
    <ButtonSwitchMulti
      className={classNames('ltr:mr-2 rtl:ml-2')}
      buttonType="header"
      autoSize
      options={simulationOptions}
      selectedIndex={isForecastSimulation ? 1 : 0}
      onClick={index => setForecastSimulation(index === 1)}
    />
  );
};
export default SimulationForecastSwitch;
