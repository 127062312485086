import {
  MeasurementSystem,
  WaypointType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import RouteFeature from '../../layout/features/RouteFeature';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { optimizationSelectedMoveRoute } from '../store/optimisation.state';

const MoveRoutesLayer: React.FC = () => {
  const routes = useRecoilValueLoadable(optimizationSelectedMoveRoute);
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);
  if (_.isEmpty(routes.contents)) return null;

  const scaleFactor = measureSystem === MeasurementSystem.IMPERIAL ? 0.4 : 1;
  let index = 1;
  return (
    <Layer>
      {_.map(routes.getValue(), r => {
        return (
          <RouteFeature
            key={`route-with-distance-${r.distance}`}
            waypoints={_.map(r.waypoints, (w, i) => ({
              ...w,
              label: w.type === WaypointType.LOCATION ? `${index++}` : null,
            }))}
            animate
            showLabels
            scale={scaleFactor}
          />
        );
      })}
    </Layer>
  );
};

export default MoveRoutesLayer;
