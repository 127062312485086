import { importPipelineIsActiveEditor } from '../../importJobs/store/importPipelines.state';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { HelperMessage } from '../../components/HelperMessage';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import Callout from '../../helpContext/Callout';

const ImportPipelinePlaceholder: React.FC = () => {
  const { t } = useTranslation('dataset');
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);

  return (
    <>
      <ScreenTitle isSticky title={t`Pipeline`} subtitle={t`Automation`} />

      <HelperMessage
        data-component="ImportPipelinePlaceholder"
        hasBefore
        hasPadding
      >
        <Callout type="suggestion">
          <p className="mt-3">{t`To view or update existing pipeline, please select one from table`}</p>

          <p className="mt-3">{t`Click the button to create new pipeline.`}</p>
          <Button
            className={classNames('text-xs', 'rounded', 'mt-4')}
            label={t`Create new pipeline`}
            buttonType="primary"
            full
            buttonSize="sm"
            hasIconAfter
            buttonIcon={
              <Icon.CirclePlus
                className={classNames('w-6 h-6', 'fill-current')}
              />
            }
            onPress={() => setShowEditor(true)}
          />
        </Callout>
      </HelperMessage>
    </>
  );
};

export default ImportPipelinePlaceholder;
