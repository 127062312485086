import {
  BuildSimulationLayoutRouteDocument,
  BuildSimulationLayoutRouteQuery,
  BuildSimulationLayoutRouteQueryVariables,
  InaccessibleLayoutLocationFragment,
  LayoutPlaneRouteFragment,
  LoadRoutingInaccessibleLocationsDocument,
  LoadRoutingInaccessibleLocationsQuery,
  LoadRoutingInaccessibleLocationsQueryVariables,
  NumberSearchFilterType,
  RoutingPolicyAgentRule,
  RoutingPolicyAgentRuleFragment,
  RoutingPolicyFeatureRule,
  RoutingPolicyFeatureRuleFragment,
  RoutingPolicyFragment,
  RoutingPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../../GraphQLClient';
import { AsyncLoadStatus } from '../../../common/types';
import { cloneWithoutTypename } from '../../../common/utils';
import {
  viewerLayoutId,
  viewerMultiselectedLocationIds,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
  viewerShowMultiselectToolbar,
} from '../../../layout/viewer/store/viewer.state';
import { simulationLayoutActiveAgentId } from '../simulation.layout.state';
import { simulationCurrentId } from '../simulation.state';
import { ROUTING_POLICY_FALLBACK_ID } from './routingPolicy.default';
import { getRoutingPolicyInput } from './routingPolicy.helper';
import { RoutingPolicySelectionIdentity } from './routingPolicy.types';

const getKey = (postfix: string) => `warebee-routing-policy-${postfix}`;

export const routingPolicy = atom<RoutingPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const routingPolicyRule = selectorFamily<
  RoutingPolicyAgentRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(routingPolicy);
      return _.find(policy?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, newRule: RoutingPolicyAgentRule) => {
      const policy = get(routingPolicy);
      set(routingPolicy, {
        ...policy,
        rules: policy.rules.map(rule => (rule.id === ruleId ? newRule : rule)),
      });
    },
});

export const routingPolicyFallbackRule = selector<RoutingPolicyRuleFragment>({
  key: getKey('rule-by-id'),
  get: ({ get }) => get(routingPolicy)?.defaultRule,
  set: ({ get, set }, newRule: RoutingPolicyRuleFragment) => {
    const policy = get(routingPolicy);
    set(routingPolicy, {
      ...policy,
      defaultRule: newRule,
    });
  },
});

const routingPolicySelectedIdentityAtom = atom<RoutingPolicySelectionIdentity>({
  key: getKey('selected-identity-atom'),
  default: null,
});

export const routingPolicySelectedIdentity =
  selector<RoutingPolicySelectionIdentity>({
    key: getKey('selected-identity'),
    get: ({ get }) => get(routingPolicySelectedIdentityAtom),
    set: ({ get, set }, value: RoutingPolicySelectionIdentity) => {
      set(viewerShowMultiselectToolbar, !_.isNil(value));
      set(routingPolicySelectedIdentityAtom, value);
    },
  });

export const routingPolicySelectedFeatures = selector<
  RoutingPolicyFeatureRule[]
>({
  key: getKey('selected-rule-features-settings'),
  get: ({ get }) => {
    const selected = get(routingPolicySelectedIdentity);
    const policy = get(routingPolicy);
    // Take from routing policy
    if (
      !_.isNil(selected?.ruleId) &&
      selected?.isRoutingConfigurationSelected
    ) {
      if (selected.ruleId === ROUTING_POLICY_FALLBACK_ID) {
        return policy.defaultRule?.featureRules ?? [];
      } else {
        const rule = get(routingPolicyRule(selected.ruleId));
        return rule?.featureRules ?? [];
      }
    }

    // from Layout selected agent
    const agentId = get(simulationLayoutActiveAgentId);
    if (_.isNil(agentId)) {
      return null;
    }
    const rule =
      _.find(policy.rules, r => _.includes(r.agentIds, agentId)) ??
      policy.defaultRule;
    return rule?.featureRules ?? [];
  },
  set: ({ get, set }, value: RoutingPolicyFeatureRule[]) => {
    const selected = get(routingPolicySelectedIdentity);
    const policy = get(routingPolicy);
    if (_.isNil(selected?.ruleId) || !selected?.isRoutingConfigurationSelected)
      return null;

    if (selected.ruleId === ROUTING_POLICY_FALLBACK_ID) {
      set(routingPolicy, {
        ...policy,
        defaultRule: {
          featureRules: value,
        },
      });
    } else {
      const rule = get(routingPolicyRule(selected.ruleId));
      set(routingPolicyRule(selected.ruleId), {
        ...rule,
        featureRules: value,
      });
    }
  },
});

export const routingPolicySelectedFeaturesMap = selector<
  Record<string, RoutingPolicyFeatureRule>
>({
  key: getKey('selected-rule-features-settings-map'),
  get: ({ get }) =>
    _.keyBy(get(routingPolicySelectedFeatures), f => f.featureId),
});

export const routingPolicyFeatureRestrictions = selectorFamily<
  RoutingPolicyFeatureRuleFragment,
  string
>({
  key: getKey('feature-rule-by-id'),
  get:
    (key: string) =>
    ({ get }) =>
      get(routingPolicySelectedFeaturesMap)?.[key],
  set:
    (key: string) =>
    ({ get, set }, value) => {
      const current = get(routingPolicySelectedFeatures);

      const others = _.filter(current, f => f.featureId && f.featureId !== key);
      set(
        routingPolicySelectedFeatures,
        _.isNil(value) ? others : [...others, value],
      );
    },
});
export const routingPolicyCheckResultByRule = atomFamily<number, string>({
  key: getKey('check-results-by-rule'),
  default: null,
});

export const routingPolicyCheckResultByRuleLoadStatus = atomFamily<
  AsyncLoadStatus,
  string
>({
  key: getKey('check-results-by-rule-load-status'),
  default: AsyncLoadStatus.None,
});

export const routingPolicyInaccessibleLevelLocation = selector<
  Record<string, InaccessibleLayoutLocationFragment>
>({
  key: getKey('level-locations'),
  //cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const errorMsg = 'Cannot load locations ids routing rule';
    const layoutId = get(viewerLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    const locationLevel = get(viewerSelectedLevel);
    const policy = get(routingPolicy);
    const selected = get(routingPolicySelectedIdentity);
    const featureRules = get(routingPolicySelectedFeatures);

    if (!layoutId || !planeId) return null;
    if (!selected?.isRoutingConfigurationSelected) return null;

    try {
      const response = await secureClient.query<
        LoadRoutingInaccessibleLocationsQuery,
        LoadRoutingInaccessibleLocationsQueryVariables
      >({
        query: LoadRoutingInaccessibleLocationsDocument,
        variables: {
          input: {
            layoutId,
            featureRules: _.map(featureRules, cloneWithoutTypename),
            threshold: cloneWithoutTypename(policy.threshold),
          },
          page: null,
          filter: {
            planeId: [planeId],
            locationLevel: {
              type: NumberSearchFilterType.EQ,
              value: locationLevel,
            },
          },
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }

      const locations = response.data.findLayoutInaccessibleLocations.content;
      if (_.isEmpty(locations)) {
        return null;
      }
      const locMap = _.keyBy(locations, l => l.locationId);

      return locMap;
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});

export const routingPolicyRoutes = selector<LayoutPlaneRouteFragment[]>({
  key: getKey('selected-locations-route'),
  get: async ({ get }) => {
    const errorMsg = 'Can not build route between location';
    const simulationId = get(simulationCurrentId);
    const planeId = get(viewerSelectedPlaneId);
    const agentId = get(simulationLayoutActiveAgentId);
    const policy = get(routingPolicy);
    const selectedLocations = get(viewerMultiselectedLocationIds);
    if (_.isNil(agentId) || _.isEmpty(_.keys(selectedLocations))) {
      return null;
    }

    try {
      const response = await secureClient.query<
        BuildSimulationLayoutRouteQuery,
        BuildSimulationLayoutRouteQueryVariables
      >({
        query: BuildSimulationLayoutRouteDocument,
        variables: {
          input: {
            simulationId,
            agentId,
            locations: _.values(selectedLocations),
            routingPolicy: getRoutingPolicyInput(policy),
          },
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }

      const routes = response.data?.buildSimulationLayoutRoute?.routes;
      return _.filter(routes, r => r.planeId === planeId);
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});
