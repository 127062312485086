import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';

const AnalyzeResultIssuesMessage = () => {
  const { t } = useTranslation('simulation');
  return (
    <HelperMessage hasPadding>
      <HelperMessage
        isSuggestion
        className={`mt-2 border border-alerts-alert border-opacity-50 bg-alerts-alert text-menu-text p-3 rounded`}
      >
        <div className="mt-4">
          {t`Hmmm,
          Looks like there are possible issues found in the data set selected, or the policies configured!`}
        </div>
        <div className="mt-4">
          {t`Please Review the Dataset and Picking Policy in previous steps.`}
        </div>
      </HelperMessage>
    </HelperMessage>
  );
};

export default AnalyzeResultIssuesMessage;
