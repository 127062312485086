import * as React from 'react';
const PalletStackingResortDirectIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletStackingResortDirectIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M126.76,107.39v-4.54H.76v4.54h6.48v14.94H.76v4.54h126v-4.54h-6.48v-14.94h6.48ZM17.17,107.39h41.62v14.94H17.17v-14.94ZM68.72,122.33v-14.94h41.62v14.94h-41.62Z"
    />
    <rect width={31.45} height={27.82} x={6.88} y={70.05} strokeWidth={0} />
    <path strokeWidth={0} d="M44.03,70.05v27.82h75.55v-27.82H44.03Z" />
    <rect
      width={53.88}
      height={27.82}
      x={32.88}
      y={36.68}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={72.83}
      height={27.82}
      x={46.17}
      y={3.13}
      strokeWidth={0}
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M39.81,3.13H6.88v27.82h32.93V3.13Z"
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M111,62.59h-9.68s9.43-9.44,9.43-9.44h-18.55s.01-6.88.01-6.88h18.55s-9.39-9.43-9.39-9.43h9.68s10.21,10.27,10.21,10.27c1.44,1.44,1.43,3.78,0,5.22l-10.25,10.26Z"
    />
    <path
      strokeWidth={0}
      d="M18.1,62.59h-9.68s9.43-9.44,9.43-9.44H5.31s.01-6.88.01-6.88h12.55s-9.39-9.43-9.39-9.43h9.68s10.21,10.27,10.21,10.27c1.44,1.44,1.43,3.78,0,5.22l-10.25,10.26Z"
    />
  </svg>
);
export default PalletStackingResortDirectIcon;
