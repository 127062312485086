
const WareBeeSymbolShapeLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 599.87 477.77"
    {...props}
  >
    <path d="M572.59,110.71,416.35,20.5c-12.5-7.21-31.35-7.22-43.85,0L299.94,62.4,227.37,20.5c-12.5-7.21-31.35-7.22-43.84,0L27.28,110.71c-12.5,7.21-21.92,23.54-21.92,38V329.09c0,14.44,9.42,30.76,21.92,38l156.25,90.21a47.11,47.11,0,0,0,43.84,0l72.57-41.9,72.56,41.9a47.13,47.13,0,0,0,43.85,0l156.24-90.21c12.5-7.21,21.92-23.54,21.92-38V148.68C594.51,134.25,585.09,117.92,572.59,110.71ZM572.29,287,405.23,383.47c-5.55,3.21-16.06,3.21-21.62,0l-14.21-8.2L572.29,258.13Zm0-109.1-356,205.55c-5.56,3.21-16.07,3.21-21.62,0L27.58,287V258.13l156,90a47.11,47.11,0,0,0,43.84,0L572.29,149ZM183.53,239.06a47.11,47.11,0,0,0,43.84,0l156.24-90.21c5.56-3.21,16.07-3.2,21.62,0l14.22,8.21L216.26,274.37c-5.56,3.21-16.07,3.21-21.62,0L27.58,177.92V149ZM488.91,171.5l-72.56-41.89c-12.5-7.22-31.35-7.22-43.85,0L216.26,219.82c-5.56,3.2-16.07,3.2-21.62,0l-14.22-8.21L383.61,94.3c5.56-3.21,16.07-3.21,21.62,0l108.7,62.76ZM183.53,293.61a47.11,47.11,0,0,0,43.84,0l214.3-123.72,25,14.44L216.26,328.92c-5.56,3.21-16.07,3.21-21.62,0L27.58,232.47V203.58ZM383.61,39.75c5.56-3.21,16.07-3.21,21.62,0l155.95,90-25,14.45L416.35,75.06c-12.5-7.22-31.35-7.22-43.85,0L158.2,198.78l-25-14.45ZM111,171.5l-25-14.44L252.69,60.78l25,14.45ZM194.64,39.75c5.56-3.21,16.06-3.21,21.62,0L230.47,48,63.72,144.23l-25-14.45ZM216.26,438c-5.56,3.2-16.07,3.2-21.62,0L38.39,347.82c-5.55-3.21-10.81-12.31-10.81-18.73V312.68l156,90a47.11,47.11,0,0,0,43.84,0L572.29,203.58v28.89Zm345.22-90.21L405.23,438c-5.55,3.2-16.06,3.2-21.62,0l-61.45-35.49,25-14.44,25.32,14.62a47.13,47.13,0,0,0,43.85,0l155.94-90v16.41C572.29,335.51,567,344.61,561.48,347.82Z" />
  </svg>
);

export default WareBeeSymbolShapeLogo;
