import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type CopyrightProps = {
  children?: React.ReactNode;
};

export const Copyright = ({ children }: CopyrightProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app-public');

  return (
    <div
      data-component="Copyright"
      id={id}
      className={classNames('flex flex-1')}
    >
      <span className={classNames('text-menu-active mr-1 text-sm opacity-70')}>
        <span
          className={classNames('ltr:mr-1 rtl:ml-1')}
        >{t`Copyright ©`}</span>
        <Link color="inherit" to={`https://warebee.com/`}>
          {t`WareBee`}.
        </Link>
        <span className={classNames('ltr:ml-1 rtl:mr-1')}>
          {new Date().getFullYear()}
        </span>
      </span>
      {children}
    </div>
  );
};
