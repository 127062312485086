import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type CounterProps = {
  children?: React.ReactNode;

  className?: string;
};

export const StepCounter = ({ children, className }: CounterProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="StepCounter"
      className={classNames(
        'flex items-center justify-center',
        'w-8 h-8 xl:w-12 xl:h-12',
        'ltr:ml-3 rtl:mr-3 ltr:mr-1 rtl:ml-1',
        'text-lg md:text-xl xl:text-2xl',
        'font-bold',
        'bg-menu-active text-menu-active-text',
        'rounded-full',
        className,
      )}
    >
      {children}
    </div>
  );
};
