import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { Line } from 'react-konva';
import { TwTheme } from '../../../Tw';

const locationColors = TwTheme.extend.colors.location;

export type AnimatedBorderFeatureProps = {
  points: number[];
  strokeWidth?: number; //default  is 2
};

const strokeWidthDefault = 2;
const dashSpace = 4;
const fps = 16;

const AnimatedBorderFeature: React.FC<AnimatedBorderFeatureProps> = props => {
  const ref = useRef(null);

  const strokeWidth = props.strokeWidth ?? strokeWidthDefault;

  useEffect(() => {
    const anim = new Konva.Animation(
      frame => {
        ref.current?.dashOffset(
          ((frame.time / 1000) * fps) % (dashSpace * fps),
        );
      },
      ref.current?.getLayer(),
    );

    anim.start();
    return () => {
      anim.stop();
    };
  }, [ref]);

  const baseConfig: Konva.LineConfig = {
    points: props.points,
    draggable: false,
    strokeScaleEnabled: false,
    perfectDrawEnabled: false,
    closed: true,
    strokeWidth: strokeWidth,
    fillEnabled: false,
    listening: false,
  };

  const staticBorderConfig: Konva.LineConfig = {
    ...baseConfig,
    stroke: locationColors.selectedBorder, //locationColors.border,
  };

  const animatedBorderConfig: Konva.LineConfig = {
    ...baseConfig,
    stroke: locationColors.selectedBorderDash,

    dashEnabled: true,
    dash: [4, dashSpace],
  };

  return (
    <>
      <Line key={`active-feature-border-bg`} {...staticBorderConfig} />
      <Line
        ref={ref}
        key={`active-feature-border-anim`}
        {...animatedBorderConfig}
      />
    </>
  );
};

export default AnimatedBorderFeature;
