import * as React from 'react';
const LinkTargetDotIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkTargetDotIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M21.08,50.69c-.13,0-.39,0-.52-.13-1.31,4.31-2.09,8.74-2.09,13.44s.65,9.13,2.09,13.44c.13-.13.39-.13.52-.13l9.78-2.09c-1.17-3.52-1.83-7.31-1.83-11.22s.65-7.7,1.83-11.22l-9.78-2.09Z"
    />
    <path
      strokeWidth={0}
      d="M52.78,30.86c3.52-1.17,7.31-1.83,11.22-1.83s7.7.65,11.22,1.83l2.09-9.78c0-.13.13-.39.13-.52-4.31-1.3-8.74-2.09-13.44-2.09s-9.13.65-13.44,2.09c.13.13.13.39.13.52l2.09,9.78Z"
    />
    <path
      strokeWidth={0}
      d="M106.92,77.31c.13,0,.39.13.52.13,1.3-4.31,2.09-8.74,2.09-13.44s-.78-9.13-2.09-13.44c-.13.13-.39.13-.52.13l-9.78,2.09c1.17,3.52,1.83,7.31,1.83,11.22s-.65,7.7-1.83,11.22l9.78,2.09Z"
    />
    <path
      strokeWidth={0}
      d="M75.22,97.14c-3.52,1.17-7.31,1.83-11.22,1.83s-7.7-.65-11.22-1.83l-2.09,9.78c0,.13-.13.39-.13.52,4.31,1.3,8.74,2.09,13.44,2.09s9.13-.78,13.44-2.09c-.13-.13-.13-.26-.13-.52l-2.09-9.78Z"
    />
    <circle cx={64} cy={64} r={15.92} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M84.48,47.95h0s21-4.31,21-4.31c3-.65,4.04-4.31,1.96-6.52l-4.83-4.83,21.92-21.92c1.96-1.96,1.96-4.96,0-6.91s-5.09-1.96-6.91,0l-22.05,21.92-4.83-4.83c-2.22-2.22-5.87-1.04-6.52,1.96l-4.31,21c-.52,2.61,1.96,5.09,4.57,4.44Z"
    />
    <path
      strokeWidth={0}
      d="M43.52,80.05h0s-21,4.3-21,4.3c-3,.65-4.04,4.31-1.96,6.52l4.83,4.83L3.47,117.62c-1.96,1.96-1.96,5.09,0,6.91,1.96,1.96,5.09,1.96,6.91,0l21.92-21.92,4.83,4.83c2.22,2.22,5.87,1.04,6.52-1.96l4.3-21c.65-2.61-1.83-5.09-4.44-4.44Z"
    />
    <path
      strokeWidth={0}
      d="M102.62,95.57l4.83-4.83c2.22-2.22,1.04-5.87-1.96-6.52l-21-4.31c-2.74-.52-5.09,1.83-4.57,4.57l4.31,21c.65,3,4.31,4.04,6.52,1.96l4.83-4.83,21.92,21.92c1.96,1.96,5.09,1.96,6.91,0,1.96-1.96,1.96-5.09,0-6.91l-21.79-22.05Z"
    />
    <path
      strokeWidth={0}
      d="M25.38,32.3l-4.83,4.83c-2.22,2.22-1.04,5.87,1.96,6.52l21,4.31c2.74.52,5.09-1.83,4.57-4.57l-4.44-20.87c-.65-3-4.31-4.04-6.52-1.96l-4.83,4.83L10.38,3.47c-1.96-1.96-4.96-1.96-6.91,0-1.96,1.96-1.96,5.09,0,6.91l21.92,21.92Z"
    />
  </svg>
);
export default LinkTargetDotIcon;
