import { useApolloClient } from '@apollo/client';
import {
  FindLocationsByFilterDocument,
  FindLocationsByFilterQuery,
  FindLocationsByFilterQueryVariables,
  LocationFilterUnionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../common/types';
import { getPolicyMatchInput } from '../policyFilters/policyFilter.helper';
import {
  policyHighlightedLocations,
  policyHighlightedLocationsLoadStatus,
} from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';

export type LocationByRuleParams = {
  level: number;
  planeId: string;
  layoutId: string;
  includeMatching?: LocationFilterUnionFragment;
  excludeMatching?: LocationFilterUnionFragment[];
};

function useLoadLocationsByRule() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Locations by rule.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoad = useRecoilCallback(({ set }) => async () => {
    set(policyHighlightedLocationsLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LocationByRuleParams) => {
        const query = client.watchQuery<
          FindLocationsByFilterQuery,
          FindLocationsByFilterQueryVariables
        >({
          query: FindLocationsByFilterDocument,
          variables: {
            page: {
              limit: null,
            },
            filter: {
              level: [params.level],
              planeId: [params.planeId],
            },
            layoutId: params.layoutId,
            input: {
              includeMatching: getPolicyMatchInput(params.includeMatching),
              excludeMatching: _.map(params.excludeMatching, match =>
                getPolicyMatchInput(match, true),
              ),
            },
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(policyHighlightedLocationsLoadStatus, AsyncLoadStatus.Error);
        }
        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const checkResult = data?.layout?.locationsByFilter;

            set(
              policyHighlightedLocations,
              new Set(checkResult.content.map(item => item.locationId)),
            );
            set(policyHighlightedLocationsLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LocationByRuleParams) {
    await initLoad();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadLocationsByRule;
