import * as React from 'react';
const Mhe9BTurretTruckOperatorDownIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe9BTurretTruckOperatorDownIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M54.09,115.45c-.73,0-1.49.12-2.3.36V5.03c0-.81.3-1.51.91-2.12s1.31-.91,2.12-.91h3.15c.48,0,.91.18,1.27.54s.59.83.67,1.39v113.33h61.87c.32.08.48.24.48.48s-.04.36-.12.36l-6.42,3.27h-55.45c-.08-1.61-.71-2.99-1.88-4.12-1.17-1.13-2.6-1.74-4.3-1.82h0ZM8.56,4.78v-2.18c.08-.4.32-.61.73-.61h34.02c.32,0,.48.1.48.3s-.04.3-.12.3l-2.06,2.06c-.48.57-1.09.81-1.82.73h-18.4c-.48.08-.89.28-1.21.61-.32.32-.48.69-.48,1.09v47.7h29.79v62.96c-.89,1.13-1.37,2.42-1.45,3.87H10.14V54.79h2.54V7.09c0-.4-.16-.77-.48-1.09-.32-.32-.73-.52-1.21-.61h-1.7c-.4,0-.65-.2-.73-.61h0ZM49.73,121.63c.08,1.7.81,2.95,2.18,3.75,1.37.81,2.78.79,4.24-.06,1.45-.85,2.18-2.1,2.18-3.75s-.69-2.89-2.06-3.69c-1.37-.81-2.78-.79-4.24.06-1.45.85-2.22,2.08-2.3,3.69ZM111.24,123.44c-.57,1.05-1.39,1.8-2.48,2.24-1.09.44-2.18.42-3.27-.06-1.09-.48-1.84-1.21-2.24-2.18h7.99ZM8.32,117.51v5.81h5.81c-.57,1.05-1.39,1.8-2.48,2.24-1.09.44-2.18.42-3.27-.06-1.09-.48-1.86-1.27-2.3-2.36-.44-1.09-.44-2.16,0-3.21.44-1.05,1.19-1.86,2.24-2.42h0Z"
    />
  </svg>
);
export default Mhe9BTurretTruckOperatorDownIcon;
