import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { HelperImporterSelect } from '../../import/sidebar/HelperImporter';

const ImportSelectPreviewPanel: React.FC = () => {
  const { t } = useTranslation('importer');
  return (
    <PanelBody className="flex flex-col h-full">
      <ScreenTitle
        isSticky
        title={t`Select & Review`}
        subtitle={t`Data import`}
        hasStepCounter={'2.'}
      />
      <HelperImporterSelect hasStepCounter={'2.2'} />
    </PanelBody>
  );
};

export default ImportSelectPreviewPanel;
