import { FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateDatasetObjectDocument,
  UpdateDatasetObjectInput,
  UpdateDatasetObjectMutation,
  UpdateDatasetObjectMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { errorAppender } from '../../store/error.state';
import { datasetObjectDocumentSaveStatus } from '../store/datasetObject.state';

function useUpdateDatasetObject() {
  const client = useApolloClient();
  const { t } = useTranslation('dataset');

  const beforeUpdate = useRecoilCallback(({ set }) => async () => {
    set(datasetObjectDocumentSaveStatus, AsyncLoadStatus.Loading);
  });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (patch: UpdateDatasetObjectInput) => {
        let response: FetchResult<UpdateDatasetObjectMutation>;
        try {
          response = await client.mutate<
            UpdateDatasetObjectMutation,
            UpdateDatasetObjectMutationVariables
          >({
            mutation: UpdateDatasetObjectDocument,
            variables: {
              input: {
                ...cloneWithoutTypename(patch),
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save Dataset Object`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(datasetObjectDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save Dataset Object`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(datasetObjectDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }
        set(datasetObjectDocumentSaveStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (patch: UpdateDatasetObjectInput) => {
    await beforeUpdate();
    await callUpdate(patch);
  };
}
export default useUpdateDatasetObject;
