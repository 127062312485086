import { WeightComplianceLocationItemFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import * as Icon from '../../../components/icons';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import {
  getLocationWeightColor,
  hasWeightCompliantActualNumbers,
  hasWeightCompliantMaxNumbers,
} from '../../store/weightCompliance.helper';
import { weightComplianceBayLocation } from '../../store/weightCompliance.state';

export type LocationPropertiesWeightCompliance = {
  locationId: string;
};

const LocationPropertiesWeightCompliance: React.FC<
  LocationPropertiesWeightCompliance
> = props => {
  const { t } = useTranslation('simulation');
  const locationMap = useRecoilValue(weightComplianceBayLocation);
  const formatter = useFormatter();

  const locWeight = locationMap?.[props.locationId];
  const sectionTitle = t`Weight Compliance`;

  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty location`} message_helper={`(No Items)`} />
  );

  if (_.isNil(locWeight)) {
    return <EmptyPlaceholder />;
  }

  function getItems(items: WeightComplianceLocationItemFragment[]) {
    return _(items)
      .sortBy(item => -item.weight)
      .map(p => {
        const weight = formatter.formatWeight(p.weight);

        return (
          <React.Fragment key={`item-${p.sku}`}>
            <StatListItem
              title={p.sku}
              value={`${weight.value || 0}`}
              unitOfMeasure={weight.unit}
              valueClass={'rounded text-xxs'}
              labelValueType={'itemId'}
              labelValueFilters={{
                consignee: p.consignee,
                sku: p.sku,
              }}
            ></StatListItem>

            {!_.isEmpty(p.uoms) && (
              <div className={`flex items-start mb-4`}>
                <Icon.ArrowRightBottom
                  className={`h-4 w-4 mt-3 ltr:mr-2 rtl:ml-2 mx-0.5 fill-current text-menu-100`}
                />
                <div className={`flex flex-col flex-1`}>
                  <header
                    data-component="flexHeader"
                    className={`flex items-end border-menu-400 border-solid border-b border-opacity-30 mt-2 mb-1 py-1 text-xxs xl:text-xs uppercase text-menu/60`}
                  >
                    <div className="flex-1">{t`UOM`}</div>
                    <div className="pr-2 ltr:mr-1 rtl:ml-1 text-end">{t`Qty`}</div>
                  </header>

                  <div className={`flex items-center`}>
                    <span className={`flex items-center`}>
                      <span
                        className={`text-end text-xs p-1 px-1 ltr:pr-2 rtl:pl-2 ltr:mr-2 rtl:ml-2`}
                      >
                        <span className="mx-1">
                          {`${p.uoms[0]?.uom.uom}`}
                          {`(s)`}
                        </span>
                      </span>
                    </span>
                    <span className={`flex-1`}></span>

                    <span
                      className={`text-end text-xs p-1 px-2 rounded border border-menu-400`}
                    >
                      {p.uoms[0]?.uomQuantity}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })
      .value();
  }

  const weight = formatter.formatWeight(locWeight.weight);
  const maxWeight = formatter.formatWeight(locWeight.maxWeight);

  const [background, color] = getLocationWeightColor(locWeight);
  return (
    <PanelContainer
      id="panel-compliance-weight-items"
      title={sectionTitle}
      collapsible
      hasPadding
    >
      <StatListItem
        title={t`Status`}
        value={`${locWeight.status}`}
        titleClass={'text-xs'}
        valueClass={'rounded text-xxs'}
        valueStyle={{
          background,
          color,
        }}
      ></StatListItem>

      {!_.isNil(maxWeight) &&
        hasWeightCompliantMaxNumbers(locWeight.status) && (
          <StatListItem
            title={t`Max Capacity limit`}
            value={maxWeight.value}
            unitOfMeasure={maxWeight.unit}
            titleClass={'text-xs'}
            valueClass={'rounded text-xxs'}
            valueStyle={{
              background,
              color,
            }}
          />
        )}

      {hasWeightCompliantActualNumbers(locWeight.status) && (
        <StatListItem
          title={t`Item Weight`}
          value={weight.value}
          unitOfMeasure={weight.unit}
          titleClass={'text-xs'}
          valueClass={'rounded text-xxs'}
          valueStyle={{
            background,
            color,
          }}
        ></StatListItem>
      )}

      {!_.isEmpty(locWeight.items) && (
        <div className="mt-4">
          <StatListHeader title={t`Item`} value={t`Weight`} />
          {getItems(locWeight.items)}
        </div>
      )}
    </PanelContainer>
  );
};

export default LocationPropertiesWeightCompliance;
