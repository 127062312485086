import {
  ArrayNotEmpty,
  IsNotEmpty,
  ValidateNested,
} from '@warego/class-validator';
import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { CreateAssignmentItemInput } from './create-assignment-item.input';

@InputType()
export class AppendAssignmentItemsInput {
  @Field(() => ID)
  @IsNotEmpty()
  assignmentId: string;

  @Field(() => [CreateAssignmentItemInput])
  @ValidateNested()
  @ArrayNotEmpty()
  items: CreateAssignmentItemInput[];
}
