import {
  ArrayNotEmpty,
  IsNotEmpty,
  ValidateNested,
} from '@warego/class-validator';
import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { ImportOrderSetLineInput } from './import-order-set-line.input';

@InputType()
export class ImportOrderSetLinesInput {
  @Field(() => ID)
  @IsNotEmpty()
  orderSetId: string;

  @Field(() => [ImportOrderSetLineInput])
  @ValidateNested()
  @ArrayNotEmpty()
  lines: ImportOrderSetLineInput[];
}
