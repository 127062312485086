import {
  BatchJobStatus,
  ImportJobProcessingStage,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import useFormatter from '../../common/useFormatter';
import ErrorIndicator from '../../components/ErrorIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import { ProgressBar } from '../../components/progressbar/ProgressBar';
import {
  importJob,
  importProcessStatus,
  importSelectedFile,
  importUploadProgress,
  importUploadStatus,
} from '../store/import.state';
import { ImportStepContainerProps } from '../store/import.types';

const ImportUploadStep: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const job = useRecoilValue(importJob);
  const uploadProgress = useRecoilValue(importUploadProgress);
  const uploadStatus = useRecoilValue(importUploadStatus);
  const processStatus = useRecoilValue(importProcessStatus);
  const file = useRecoilValue(importSelectedFile);
  const formatter = useFormatter();

  const isRequestLoading =
    uploadStatus === AsyncLoadStatus.Loading ||
    processStatus === AsyncLoadStatus.Loading;

  const isRequestError =
    processStatus === AsyncLoadStatus.Error ||
    uploadStatus === AsyncLoadStatus.Error;

  const hasError =
    isRequestError ||
    (!isRequestLoading &&
      (!_.isEmpty(uploadProgress?.errors) ||
        job?.status === BatchJobStatus.FAILED));

  const filename = file?.name;
  const steps = [
    {
      message: t`Uploading`,
      stage: null,
    },

    {
      message: t`Validating`,
      stage: ImportJobProcessingStage.VALIDATING,
    },
    {
      message: t`Saving`,
      stage: ImportJobProcessingStage.IMPORTING,
    },
    {
      message: t`Analyse`,
      stage: ImportJobProcessingStage.POSTPROCESSING,
    },
  ];

  let currentStepIndex = 0;
  let progressRaw = 0;

  if (uploadStatus === AsyncLoadStatus.Loading) {
    currentStepIndex = 0;
    progressRaw = (uploadProgress?.loaded ?? 0) / file.size;
  }

  if (processStatus === AsyncLoadStatus.Loading) {
    currentStepIndex = Math.max(
      _.findIndex(steps, s => s.stage === job?.stage),
      1,
    );
    progressRaw = (job?.progress ?? 0) / 100;
  }

  const progress = formatter.formatShare(progressRaw);

  const stepMessage = t('Step {{currentStep}} of {{totalSteps}}', {
    currentStep: currentStepIndex + 1,
    totalSteps: steps.length,
  });

  return (
    <Container
      col
      componentName="ImportSelectFileStep"
      overflow
      className={classNames(
        'flex-1',
        'ltr:border-l rtl:border-r border-app-panel-dark',
        'relative',
        'bg-app-panel-dark',
      )}
    >
      <div
        className={classNames(
          'flex flex-col items-center w-full h-full overflow-hidden',
        )}
      >
        <div
          className={classNames('flex items-center flex-1 w-full p-12 pt-28')}
        >
          {hasError && <ErrorIndicator message={t`ERROR`} />}
          {!hasError && (
            <ProgressBar
              progress={progress}
              step={stepMessage}
              action={steps[currentStepIndex]?.message}
              message={filename}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default ImportUploadStep;
