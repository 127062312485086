import { useApolloClient } from '@apollo/client';
import {
  AssignmentItemsSearchFilter,
  LoadAssignmentDataDocument,
  LoadAssignmentDataQuery,
  LoadAssignmentDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  assignmentItemsData,
  assignmentItemsDataState,
  assignmentItemsDataStatus,
} from '../store/assignment.state';

export type LoadAssignmentDataParams = {
  assignmentId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: AssignmentItemsSearchFilter;
};

function useLoadAssignmentData() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Assignment data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(assignmentItemsDataStatus, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentDataParams) => {
        const current = await snapshot.getPromise(assignmentItemsData);
        const currentState = await snapshot.getPromise(
          assignmentItemsDataState,
        );

        const query = client.watchQuery<
          LoadAssignmentDataQuery,
          LoadAssignmentDataQueryVariables
        >({
          query: LoadAssignmentDataDocument,
          variables: {
            assignmentId: params.assignmentId,
            filter: params.filter,
            //sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DATASET_VIEW_PAGE_SIZE,
            },
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(assignmentItemsDataStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let items = data.assignment.items.content;

            if (params.isAppend) {
              items = [...current, ...items];
            }

            set(assignmentItemsData, items);
            set(assignmentItemsDataStatus, AsyncLoadStatus.Ok);
            set(assignmentItemsDataState, {
              ...currentState,
              totalCount: data.assignment.items.totalCount,
            });
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentDataParams) {
    await initLoading();
    await loadDashDataCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAssignmentData;
