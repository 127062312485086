import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatCurrencyBest } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { AppBillingInvoiceLink } from './AppBillingInvoiceLink';
import useLoadPurchase from './hooks/useLoadPurchase';
import { convertStripeAmount } from './store/appBilling.helper';
import {
  appBillingPurchase,
  appBillingPurchaseError,
  appBillingPurchaseLoadStatus,
} from './store/appBilling.state';

export type AppBillingPurchaseStatusProps = {
  purchaseId: string;
};

export const AppBillingPurchaseStatus: React.FC<
  AppBillingPurchaseStatusProps
> = props => {
  const { t } = useTranslation('app');

  const purchase = useRecoilValue(appBillingPurchase);
  const purchaseError = useRecoilValue(appBillingPurchaseError);
  const loadStatus = useRecoilValue(appBillingPurchaseLoadStatus);
  const updatePurchase = useLoadPurchase();

  //purchase.invoice.invoicePdf
  useEffect(() => {
    if (!_.isEmpty(props.purchaseId)) {
      updatePurchase(props.purchaseId);
    }
  }, [props.purchaseId]);

  if (
    loadStatus === AsyncLoadStatus.Loading ||
    loadStatus === AsyncLoadStatus.None
  ) {
    return <LoadingIndicator message={t`Loading purchase information`} />;
  }

  if (_.isNil(props.purchaseId)) {
    return <ErrorIndicator message={t`Purchase ID is not provided`} />;
  }

  if (loadStatus === AsyncLoadStatus.Error) {
    return (
      <ErrorIndicator
        message={purchaseError ?? t`Error loading purchase data`}
      />
    );
  }

  if (loadStatus === AsyncLoadStatus.Ok && _.isNil(purchase)) {
    return <InboxZero message={t`Purchase cannot be found`} />;
  }

  const purchasePrice = formatCurrencyBest(
    convertStripeAmount(purchase.invoice?.total ?? 0),
    purchase.invoice?.currency,
  );

  return (
    <AppBillingInvoiceLink
      id={purchase.id}
      pdfUrl={purchase.invoice?.invoicePdf}
      receiptUrl={purchase.invoice?.receiptUrl}
      status={purchase.invoice?.status}
      title={purchase.invoice?.title}
      description={purchase.invoice?.description}
      date={new Date(purchase.paidAt ?? purchase.createdAt)}
      total={purchasePrice}
    />
  );
};

export default AppBillingPurchaseStatus;
