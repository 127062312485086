import { OptimizationSwapRule } from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import useLoadSimulationProductsByRule from '../assignmentPolicy/useLoadSimulationProductsByRule';
import { DataPageParams } from '../common/types';
import { getSwapPolicyRuleInput } from './swapPolicy.helper';
import { SwapPolicySelectedGroup } from './swapPolicy.type';

export type ProductsByRuleParams = {
  rule: OptimizationSwapRule;
  group: SwapPolicySelectedGroup;
  page?: DataPageParams;
};

function useLoadSwapPolicyProductsByRule() {
  const [loadBase, cancel] = useLoadSimulationProductsByRule();

  const load = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ProductsByRuleParams) => {
        const ruleInput = getSwapPolicyRuleInput(params.rule);

        const includeMatching =
          params.group === 'source'
            ? ruleInput.src?.itemsMatch
            : ruleInput.dest?.itemsMatch;

        await loadBase({
          includeMatching,
          excludeMatching: null,
          page: params.page,
        });
      },
  );

  return [load, cancel] as const;
}
export default useLoadSwapPolicyProductsByRule;
