import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

export type TableRowProps = {
  children?: React.ReactNode;
  className?: string;
  rowBody?: boolean;
  rowHead?: boolean;
  rowLine?: boolean;
  rowLineList?: boolean;
  rowHover?: boolean;
  id?: string;
  dragRef?: any;
  dragStyle?: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};

export const TableRow = ({
  id,
  children,
  className,
  rowBody,
  rowHead,
  rowHover,
  rowLine,
  rowLineList,
  onMouseEnter,
  onMouseLeave,
  onClick,
  dragRef,
  dragStyle,
  ...rest
}: TableRowProps) => {
  return (
    <tr
      id={id}
      ref={!_.isNil(dragRef) ? dragRef : undefined}
      data-component={`${rowBody ? 'tableRowBody' : 'tableRowHead'}`}
      className={classNames(
        'w-full',
        {
          'border-b border-app-panel-dark/50': rowHead,
        },
        {
          'border-b border-app-panel-dark': rowLine,
        },
        {
          'border-b border-app-panel-dark/50': rowLineList,
        },
        {
          'hover:bg-menu-active/50 group': rowHover,
        },
        className,
      )}
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      onClick={() => onClick && onClick()}
      {...rest}
      style={dragStyle}
    >
      {children}
    </tr>
  );
};
