import {
  QueryDatasetQuery,
  QueryDatasetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { ActivityFeedEventDataset } from '@warebee/shared/import-converter';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type ActivityFeedExportJobParams = ExportJobParams<
  QueryDatasetQueryVariables,
  ActivityFeedConverterConfig
>;

export const activityFeedExportJobParams: ActivityFeedExportJobParams = {
  converterId: 'ACTIVITY_FEED',
  query: null,
  config: null,
  variables: null,
  filename: 'activity-feed.csv',
};

export type ActivityFeedDataRow = ActivityFeedEventDataset & {
  duration: number;
};

export type ActivityFeedDataColumn = keyof ActivityFeedDataRow;

export type ActivityFeedConverterConfig = {
  columnsConfig: ColumnConfigBase<ActivityFeedDataRow>[];
};

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return fName === 'eventStartTime' || fName === 'eventEndTime';
};

export function postProcessFeedDataRows(
  events: Record<string, any>[],
): ActivityFeedEventDataset[] {
  return _.map(events, e => {
    return _.reduce(
      e,
      (acc, value, key) => {
        return {
          ...acc,
          [_.camelCase(key)]: isDateField(key) ? new Date(value) : value,
        };
      },
      {} as ActivityFeedEventDataset,
    );
  });
}

export const getActivityFeedTableRows = (
  data: ActivityFeedEventDataset[],
  config: ActivityFeedConverterConfig,
): ActivityFeedDataRow[] => {
  const reduceActivityFeed = (
    event: ActivityFeedEventDataset,
  ): ActivityFeedDataRow => {
    return {
      ...event,
      duration: _.isNil(event.eventStartTime)
        ? null
        : event.eventEndTime.getTime() - event.eventStartTime.getTime(),
    };
  };

  return _.map(data, reduceActivityFeed);
};

export const ACTIVITY_FEED_EXPORT_CONVERTER: ExportConverter<
  QueryDatasetQuery,
  ActivityFeedConverterConfig
> = (input, config) => {
  const postProcessed = postProcessFeedDataRows(
    input.warehouse?.datasetObjectQuery?.result,
  );
  const data = getActivityFeedTableRows(postProcessed, config);
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
