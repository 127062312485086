import * as React from 'react';
const HeatMapLayoutBack1Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HeatMapLayoutBack1Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M3.46,3.57v87.72h77.72V3.57H3.46ZM63.73,73.28c.52.52.67,1.29.39,1.97-.28.68-.94,1.12-1.67,1.12h-14.93c-.5,0-.98-.21-1.32-.57l-25.32-27.12c-.65-.69-.65-1.77,0-2.47l25.32-27.12c.34-.37.82-.57,1.32-.57h14.93c.73,0,1.39.44,1.67,1.12.28.68.13,1.45-.39,1.97l-25.85,25.85,25.85,25.85Z"
    />
    <rect
      width={37.72}
      height={87.72}
      x={85.74}
      y={3.57}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={77.72}
      height={27.72}
      x={3.46}
      y={95.85}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={37.72}
      height={27.72}
      x={85.74}
      y={95.85}
      strokeWidth={0}
      opacity={0.6}
    />
  </svg>
);
export default HeatMapLayoutBack1Icon;
