import {
  LoadSizeComplianceForLocationsDocument,
  LoadSizeComplianceForLocationsQuery,
  LoadSizeComplianceForLocationsQueryVariables,
  LoadSizeComplianceForLocationsStatusOnlyDocument,
  LoadSizeComplianceForLocationsStatusOnlyQuery,
  LoadSizeComplianceForLocationsStatusOnlyQueryVariables,
  SizeComplianceLocationDetailsFragment,
  SizeComplianceLocationStatusFragment,
  SizeComplianceStatus,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../Tw';
import { secureClient } from '../../GraphQLClient';
import { SizeComplianceBucketConfig } from './simulation.types';

// const complianceColors = TwTheme.extend.colors.compliance;
const heatmapColors = TwTheme.extend.colors.heatmap_compliance;
// const heatmapTextColors = TwTheme.extend.colors.heatmap_text;

export const getSizeHeatmapBuckets = (
  t?: TFunction<'simulation'>,
): SizeComplianceBucketConfig[] => [
  {
    id: 'non-compliant',
    title: t && t(`Non-compliant`, { ns: 'simulation' }),
    color: heatmapColors.nonCompliant,
    textColor: heatmapColors.nonCompliantText,
    status: SizeComplianceStatus.NONCOMPLIANT,
  },
  {
    id: 'compliant',
    title: t && t(`Compliant`, { ns: 'simulation' }),
    color: heatmapColors.compliant,
    textColor: heatmapColors.compliantText,
    status: SizeComplianceStatus.COMPLIANT,
  },
  {
    id: 'unknown',
    title: t && t(`Unknown`, { ns: 'simulation' }),
    color: heatmapColors.unknown,
    textColor: heatmapColors.unknownText,
    status: SizeComplianceStatus.UNKNOWN,
  },
  {
    id: 'empty',
    title: t && t(`Empty`, { ns: 'simulation' }),
    color: heatmapColors.empty,
    textColor: heatmapColors.emptyText,
    status: SizeComplianceStatus.UNASSIGNED,
  },
];

export function getSizeColorByStatus(
  status: SizeComplianceStatus,
): [string, string] {
  const bucketConfig = getSizeHeatmapBuckets().find(
    bucket => bucket.status === (status ?? SizeComplianceStatus.UNKNOWN),
  );
  return [bucketConfig.color, bucketConfig.textColor];
}

export type LoadLocationSizeHeatmapParams = {
  simulationId;
  areaId: string;
  level?: number;
  bayId?: string;
  locationId?: string;
};

export async function loadLocationSizeHeatmap(
  params: LoadLocationSizeHeatmapParams,
): Promise<Record<string, SizeComplianceLocationStatusFragment>> {
  const { simulationId } = params;

  const response = await secureClient.query<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  >({
    query: LoadSizeComplianceForLocationsStatusOnlyDocument,
    variables: {
      simulationId,
      page: {
        limit: null,
        skip: 0,
      },
      filter: {
        planeId: params.areaId ? [params.areaId] : null,
        bayId: params.bayId ? [params.bayId] : null,
        level: params.level ? [params.level] : null,
      },
    },
  });
  const locationMap = _.keyBy(
    response.data?.simulation.sizeCompliance?.locations.content,
    locCompliance => locCompliance.locationId,
  );
  return locationMap;
}

export async function loadLocationSizeComplianceDetails(
  params: LoadLocationSizeHeatmapParams,
): Promise<SizeComplianceLocationDetailsFragment> {
  const { simulationId } = params;

  if (_.isNil(params.locationId)) return null;

  const response = await secureClient.query<
    LoadSizeComplianceForLocationsQuery,
    LoadSizeComplianceForLocationsQueryVariables
  >({
    query: LoadSizeComplianceForLocationsDocument,
    variables: {
      simulationId,
      page: {
        limit: null,
        skip: 0,
      },
      filter: {
        planeId: params.areaId ? [params.areaId] : null,
        locationId: {
          type: StringSearchFilterType.EQ_CI,
          value: params.locationId,
        },
      },
    },
  });
  const locationData =
    response.data?.simulation.sizeCompliance?.locations?.content?.[0];
  return locationData;
}

export function getSizeComplianceStatusMap(
  t: TFunction<'simulation'>,
): Record<SizeComplianceStatus, string> {
  return {
    [SizeComplianceStatus.NONCOMPLIANT]: t(`Non-compliant`, {
      ns: 'simulation',
    }),
    [SizeComplianceStatus.COMPLIANT]: t(`Compliant`, { ns: 'simulation' }),
    [SizeComplianceStatus.UNASSIGNED]: t(`Unassigned`, { ns: 'simulation' }),
    [SizeComplianceStatus.UNKNOWN]: t(`Unknown`, { ns: 'simulation' }),
  };
}
