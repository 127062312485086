import { ImportOrderSetLineInput } from '@warebee/shared/data-access-api-dto';
import { TFunction } from 'i18next';
import { has } from 'lodash';
import { AthenaTableType, MappingSchema } from '../mapping';
import {
  BusinessLogicValidationError,
  BusinessLogicValidator,
} from '../validator';

class OrderSetBusinessLogicValidator
  implements BusinessLogicValidator<ImportOrderSetLineInput>
{
  private orderDateMap = new Map();
  private orderLineKeyMap: Record<string, number> = {};

  validateRow(
    row: ImportOrderSetLineInput,
    t: TFunction<'importer'>,
  ): BusinessLogicValidationError[] {
    const { orderId, orderDate, orderLine } = row;
    const rowKey = `${orderId}-${orderLine}`;
    if (has(this.orderLineKeyMap, rowKey)) {
      const message = t(
        t`Duplicates Order lines found: \n
            orderId: {{orderId}}, \n
            orderLine: {{orderLine}}\n`,
        {
          orderId,
          orderLine,
        },
      );
      return [
        {
          type: t`Order lines duplication error`,
          message,
        },
      ];
    } else {
      this.orderLineKeyMap[rowKey] = 1;
    }

    if (this.orderDateMap.has(orderId)) {
      if (this.orderDateMap.get(orderId) !== orderDate) {
        const message = t(
          t`Order {{orderId}} have different order date: {{orderDate}} and {{orderDate2}}`,
          {
            orderId,
            orderDate,
            orderDate2: this.orderDateMap.get(orderId),
          },
        );
        return [
          {
            type: t`Order Date validation error`,
            message,
          },
        ];
      }
    } else {
      this.orderDateMap.set(orderId, orderDate);
    }
    return [];
  }
}

export const ORDER_SET_MAPPING_SCHEMA: MappingSchema<ImportOrderSetLineInput> =
  {
    partitionBy: [
      {
        engine: AthenaTableType.ICEBERG,
        key: 'orderDate',
        transform: 'day',
      },
    ],
    fields: [
      {
        name: 'consignee',
        type: 'string',
      },
      {
        name: 'orderId',
        type: 'string',
      },
      {
        name: 'orderLine',
        type: 'integer',
      },
      {
        name: 'sku',
        type: 'string',
      },
      {
        name: 'quantity',
        type: 'integer',
      },
      {
        name: 'orderDate',
        type: 'localDateTime',
        measureType: 'local-date-time',
        optional: true,
      },
      {
        name: 'customer',
        type: 'string',
        optional: true,
      },
      {
        name: 'deliveryRoute',
        type: 'string',
        optional: true,
      },
      {
        name: 'dock',
        type: 'string',
        optional: true,
      },

      {
        name: 'stagingArea',
        type: 'string',
        optional: true,
      },
      {
        name: 'waveId',
        type: 'string',
        optional: true,
      },
      {
        name: 'pickingPriority',
        type: 'string',
        optional: true,
      },
      {
        name: 'pickingContainer',
        type: 'string',
        optional: true,
      },
    ],
    dateField: 'orderDate',
    createValidator: () => new OrderSetBusinessLogicValidator(),
  };
