import * as React from 'react';
const AlertNotificationOnIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlertNotificationOnIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M102.85,18.79c-1.22-.71-2.78-.29-3.48.93s-.29,2.78.93,3.48c5.64,3.26,9.67,8.54,11.36,14.85,1.68,6.31.81,12.9-2.45,18.54-.71,1.22-.29,2.78.93,3.48.61.35,1.3.43,1.93.26s1.2-.58,1.55-1.19c3.95-6.82,5-14.78,2.96-22.41-2.04-7.63-6.91-14.01-13.73-17.95Z"
    />
    <path
      strokeWidth={0}
      d="M90.31,27.41c-.71,1.22-.29,2.78.93,3.48,7.98,4.62,10.72,14.87,6.1,22.85-.71,1.22-.29,2.78.93,3.48.61.35,1.3.43,1.93.26s1.2-.58,1.55-1.19c6.03-10.41,2.46-23.79-7.96-29.82-1.22-.71-2.78-.29-3.48.93Z"
    />
    <path
      strokeWidth={0}
      d="M9.25,51.54c.36,1.36,1.76,2.17,3.12,1.81,1.36-.36,2.17-1.76,1.8-3.12-1.68-6.29-.81-12.88,2.46-18.53,3.27-5.65,8.54-9.69,14.83-11.37,1.36-.36,2.17-1.76,1.8-3.12-.18-.68-.62-1.22-1.19-1.55s-1.25-.44-1.93-.26c-7.61,2.03-13.98,6.91-17.93,13.75-3.95,6.84-5.01,14.79-2.98,22.4Z"
    />
    <path
      strokeWidth={0}
      d="M24.22,54.31c1.36-.36,2.17-1.76,1.8-3.12-2.38-8.91,2.93-18.09,11.84-20.47,1.36-.36,2.17-1.76,1.8-3.12-.18-.68-.62-1.22-1.19-1.55s-1.25-.44-1.93-.26c-11.62,3.1-18.56,15.08-15.45,26.71.36,1.36,1.76,2.17,3.12,1.81Z"
    />
    <path
      strokeWidth={0}
      d="M92.37,66.5v-14.99c.07-11.43-7.61-19.01-14.22-22.03-1.86-.85-3.82-1.48-5.82-1.96v-1.02c0-4.59-3.74-8.33-8.33-8.33s-8.33,3.74-8.33,8.33v1.18c-1.8.47-3.57,1.05-5.24,1.82-7.3,3.36-14.69,11.47-14.76,22v15c0,10.65-3.76,19.78-11.18,27.15-.44.44-.6,1.09-.41,1.68.19.59.69,1.04,1.31,1.14l15.82,2.73c2.98.52,5.69.93,8.24,1.26,2.91,5.7,8.62,9.36,14.86,9.36s11.96-3.66,14.86-9.37c2.54-.34,5.23-.75,8.2-1.26l15.82-2.73c.61-.11,1.12-.55,1.31-1.14.19-.59.03-1.24-.41-1.68-7.77-7.72-11.7-16.85-11.7-27.15ZM59,26.5c0-2.76,2.24-5,5-5s5,2.24,5,5v.32c-3.32-.47-6.71-.44-10,.08v-.41ZM45.67,53.17h-.01c-.92,0-1.66-.76-1.66-1.68.05-7.8,6.25-12.74,9.91-14.42,6.41-2.96,14.32-2.95,20.78,0,.84.38,1.21,1.37.82,2.21-.38.84-1.37,1.21-2.21.82-5.59-2.56-12.45-2.56-18,0-2.94,1.36-7.94,5.29-7.98,11.42,0,.92-.75,1.66-1.67,1.66Z"
    />
  </svg>
);
export default AlertNotificationOnIcon;
