import { Field, ID, InputType } from '@warebee/shared/util-backend-only-types';
import { StackingPolicy } from '@warebee/shared/engine-model';
import { UpdateBaseEntityInput } from '../update-base-entity.input';

@InputType()
export class UpdateItemSetInput extends UpdateBaseEntityInput {
  @Field(() => ID)
  itemSetId: string;

  @Field(() => StackingPolicy, { nullable: true })
  stackingPolicy?: StackingPolicy;
}
