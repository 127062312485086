import * as React from 'react';
const TimingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TimingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M107.96,62.4c0,5.16-.82,10.12-2.34,14.77l-.34-.1s.23-.63.58-1.84c.36-1.2.83-2.98,1.23-5.32.39-2.33.69-5.21.64-8.53-.05-3.32-.47-7.08-1.48-11.06-.22-.82-.46-1.66-.73-2.51.04-.05.07-.1.1-.15,1.52,4.64,2.33,9.6,2.33,14.75Z"
    />
    <circle cx={102.67} cy={101.59} r={9.06} strokeWidth={0} opacity={0.8} />
    <circle cx={77.64} cy={117.59} r={7.66} strokeWidth={0} opacity={0.6} />
    <circle cx={47.95} cy={118.75} r={6.77} strokeWidth={0} opacity={0.5} />
    <path
      strokeWidth={0}
      d="M126,64.49c0,6.23-.92,12.25-2.64,17.93l-17.74-5.25c1.52-4.65,2.34-9.61,2.34-14.77s-.81-10.1-2.33-14.75c-2.99-9.2-8.72-17.17-16.2-22.95-.22-.17-.44-.34-.66-.51-3.32-2.52-7.07-4.68-11.1-6.33-2.59-1.06-5.31-1.87-8.1-2.47l-1.06-.23-1.06-.17c-.7-.12-1.43-.25-2.08-.32l-1-.11c-.33-.04-.64-.09-1.09-.1l-1.22-.07-.6-.04-.31-.02h-.14s-.07-.01-.07-.01h-.06c-5.73-.18-11.53.59-16.95,2.33-5.48,1.77-10.6,4.45-15.04,7.8-4.43,3.35-8.2,7.32-11.13,11.55-.76,1.05-1.43,2.12-2.09,3.19-.32.54-.61,1.08-.92,1.62-.31.54-.59,1.09-.85,1.63-1.12,2.16-1.99,4.37-2.74,6.51-.35,1.08-.72,2.13-.97,3.19-.13.52-.29,1.05-.4,1.56-.12.52-.23,1.03-.33,1.55-.05.25-.11.51-.16.75-.05.26-.09.51-.13.76-.08.5-.16,1-.24,1.48-.14.97-.23,1.87-.33,2.78-.08.94-.12,1.94-.18,2.85v.18s0,.04-.02.04v.36c-.02.22-.02.43-.02.63,0,.43-.01.83,0,1.24,0,1.62.08,3.11.21,4.44.25,2.68.64,4.77.94,6.18.14.71.26,1.2.36,1.6.09.38.15.58.15.58l-5.58,1.6s-.06-.19-.18-.57c-.12-.35-.32-1-.54-1.76-.24-.86-.53-1.94-.82-3.24-.6-3.46-.91-7-.91-10.63C2,30.24,29.76,2.48,64,2.48c15.43,0,29.54,5.64,40.38,14.96,4.77,4.1,8.91,8.9,12.24,14.26,5.94,9.52,9.37,20.74,9.37,32.78Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M92.54,47c-1.16-1.75-2.99-2.26-4.85-1.35-2.04,1-4.03,2.16-5.98,3.31l-.48.28c-3.14,1.85-6.27,3.71-9.4,5.57l-5.32,3.16c-3.48-1.47-7.63-1.07-10.75,1.07-.56.38-1.09.82-1.58,1.3-3.87,3.83-4.44,9.87-1.37,14.37,2.1,3.08,5.58,4.91,9.31,4.91,1.57,0,3.1-.32,4.53-.95.62-.27,1.23-.61,1.82-1.01,3.16-2.16,5.03-5.85,4.91-9.67,3.01-2.6,7.66-6.53,11.41-9.7l.32-.27c3.51-2.97,6.28-5.31,6.87-5.85,1.58-1.44,1.78-3.32.56-5.16Z"
    />
  </svg>
);
export default TimingIcon;
