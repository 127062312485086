import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimulationsListPanel from '../../components/SimulationsListPanel';
import { layoutDocument } from '../../layout/store/layout.state';

const FeedSimulationsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const document = useRecoilValue(layoutDocument);

  const simulations = document?.simulationsWith?.content;
  return <SimulationsListPanel subtitle={t`Feed`} simulations={simulations} />;
};

export default FeedSimulationsPanel;
