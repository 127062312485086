import Konva from 'konva';
import React, { useEffect, useState } from 'react';
import { Rect, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';
import { ticksInPixel } from '../viewer/layers/JobsTimelineLayer';
import { jobFeatureHeight } from './FeedJobFeature';

const colors = TwTheme.extend.colors;

const colorEven = colors.timeline.even;
const colorOdd = colors.timeline.odd;

export type TimelineAgentShiftFeatureProps = {
  id: string;
  from: Date;
  to: Date;
  index: number;
  title?: string;
};

const TimelineAgentShiftFeature: React.FC<
  TimelineAgentShiftFeatureProps
> = props => {
  const start = props.from.getTime() / ticksInPixel;
  const end = props.to.getTime() / ticksInPixel;
  const width = end - start;
  const colorsFill = props.index % 2 === 0 ? colorOdd : colorOdd;

  // Adjusted y position and height to include 2px margin at the top and bottom
  const yPos = props.index * jobFeatureHeight + 2; // Adding 2px margin at the top
  const adjustedHeight = jobFeatureHeight - 4; // Subtracting total 4px from height for both top and bottom margins

  const [textWidth, setTextWidth] = useState(100); // Default width

  useEffect(() => {
    const titleLength = props.title?.length || 4;
    // Estimate text width; you can adjust the multiplier based on your font and size
    const estimatedWidth = titleLength * 8; // 8 is an arbitrary number; adjust based on your font metrics
    setTextWidth(estimatedWidth + 20); // Add 20px padding
  }, [props.title]);

  const timelineRowConfig: Konva.RectConfig = {
    id: `shift-${props.id}`,
    x: start,
    // y: props.index * jobFeatureHeight,
    y: yPos,

    width: width,
    // height: jobFeatureHeight,
    height: adjustedHeight,
    fill: colorsFill,
    fillEnabled: true,
    stroke: colors.timeline.shift,
    strokeWidth: 0.25,
    strokeEnabled: true,
    cornerRadius: 0.5,
  };

  const padding = 2;
  // Rectangle dimensions
  const rectWidth = Math.max(50, textWidth); // Ensure minimum width for the rectangle
  const rectHeight = 10;
  const rectX = start + 2;
  const rectY = yPos + 6;

  // Text positioning
  const textX = rectX + (rectWidth - textWidth + padding) / 2; // Center the text within the rectangle
  const textY = rectY + rectHeight / 2 - 6; // Adjust for vertical centering, '6' is half of the font size

  return (
    <>
      {/* {props.title && ( */}
      <>
        {/* <Rect
          x={rectX}
          y={rectY}
          width={rectWidth}
          height={rectHeight}
          fill={colorEven}
          cornerRadius={1}
          scaleX={-1}
        /> */}
        <Text
          x={textX}
          y={textY}
          text={props.title}
          fontSize={4}
          fontFamily={'NeoSans'}
          fill={colors.timeline.label}
          align="center"
          scaleY={-1}
          // scaleX={-1}
        />
      </>
      {/* )} */}
      <Rect {...timelineRowConfig} />
    </>
  );
};

export default TimelineAgentShiftFeature;
