import { ProductCategoryDescriptorExport } from '@warebee/shared/export-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../../Tw';
import {
  ProductCategory,
  ProductCategoryDescriptor,
  ProductCategoryDescriptorBase,
  ProductCategoryDescriptorMap,
} from './simulation.ABC.types';

const categoryABCColor = TwTheme.extend.colors.categoryABC;
const categoryABCTextColor = TwTheme.extend.colors.categoryABCText;

export const categoryABCWidths = [
  10, 10, 10, 10, 10, 6, 6, 6, 6, 6, 3, 3, 3, 3, 3, 1, 1, 1, 1, 1,
]; // [0-50]-[50-80]-[80-95]-[95-100]-[dead stock];

export const productCategoriesBase: Record<
  ProductCategory,
  ProductCategoryDescriptorBase
> = _.keyBy(
  [
    {
      key: 'S',
      tag: 'A+',
      sortIndex: 0,
      color: categoryABCColor.S,
      textColor: categoryABCTextColor.S,
      fromCmlPercentile: 0,
      toCmlPercentile: 0.5,
      productShareTarget: 0.05,
    },
    {
      key: 'A',
      tag: 'A',
      sortIndex: 1,
      color: categoryABCColor.A,
      textColor: categoryABCTextColor.A,
      fromCmlPercentile: 0.5,
      toCmlPercentile: 0.8,
      productShareTarget: 0.2,
    },
    {
      key: 'B',
      tag: 'B',
      sortIndex: 2,
      color: categoryABCColor.B,
      textColor: categoryABCTextColor.B,
      fromCmlPercentile: 0.8,
      toCmlPercentile: 0.95,
      productShareTarget: 0.5,
    },
    {
      key: 'C',
      tag: 'C',
      sortIndex: 3,
      color: categoryABCColor.C,
      textColor: categoryABCTextColor.C,
      fromCmlPercentile: 0.95,
      toCmlPercentile: 1,
      productShareTarget: 1,
    },
    {
      key: 'D',
      tag: 'D',
      sortIndex: 4,
      color: categoryABCColor.D,
      textColor: categoryABCTextColor.D,
    },
  ],
  'key',
) as any;

export const getProductCategories = _.memoize(
  (t: TFunction<'simulation'>): ProductCategoryDescriptorMap => {
    const categories: ProductCategoryDescriptor[] = [
      {
        ...productCategoriesBase['S'],
        title: t('Fastest-movers', { ns: 'simulation' }),
      },
      {
        ...productCategoriesBase['A'],
        title: t('Fast-movers', { ns: 'simulation' }),
      },
      {
        ...productCategoriesBase['B'],
        title: t('Medium-movers', { ns: 'simulation' }),
      },
      {
        ...productCategoriesBase['C'],
        title: t('Slow-movers', { ns: 'simulation' }),
      },
      {
        ...productCategoriesBase['D'],
        title: t('No Orders (Dead Stock)', { ns: 'simulation' }),
      },
    ];

    return _.keyBy(categories, c => c.key) as ProductCategoryDescriptorMap;
  },
);

export function getProductCategoryDescriptorExport(
  productCategories,
): ProductCategoryDescriptorExport[] {
  return _.map(
    productCategories,
    cd =>
      ({
        tag: cd.tag,
        fromCmlPercentile: cd.fromCmlPercentile,
        toCmlPercentile: cd.toCmlPercentile,
      }) as ProductCategoryDescriptorExport,
  );
}

/**
 * Get A+ABCD product category
 * @param cumulativePercentile - cumulative % of order lines
 * @returns  product category descriptor without translatable properties
 */
export function getProductCategory(
  cumulativePercentile: number,
): ProductCategoryDescriptorBase {
  if (_.isNil(cumulativePercentile)) {
    return productCategoriesBase['D'];
  }

  const v = Math.min(cumulativePercentile, 1);
  if (v === 1) {
    return productCategoriesBase['C'];
  }

  // value should be between  borders , upper border excluded
  const targetCategory = _.values(productCategoriesBase).find(
    c => v >= c.fromCmlPercentile && v < c.toCmlPercentile,
  );
  return targetCategory ?? productCategoriesBase['D'];
}

export function getProductCategoryFull(
  cumulativePercentile: number,
  categories: ProductCategoryDescriptorMap,
): ProductCategoryDescriptor {
  return categories[getProductCategory(cumulativePercentile)?.key];
}
