import * as React from 'react';
const TagDescriptionIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TagDescriptionIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M72.91,122.39l24.05-23.18c1.27-1.23,2.43-2.96,2.83-4.75V13.57c0-5.54-4.52-10.05-10.08-10.05h-47.78c-5.56,0-10.07,4.51-10.07,10.07v78.96c0,2.41,1.09,5,2.83,6.67l24.05,23.18c3.92,3.79,10.23,3.8,14.16,0ZM39.8,21.55c0-1.31,1.07-2.38,2.38-2.38h23.82c1.31,0,2.38,1.07,2.38,2.38v7.15c0,1.31-1.07,2.38-2.38,2.38s-2.38-1.07-2.38-2.38v-4.76s-7.15,0-7.15,0v23.82s1.59,0,1.59,0c1.31,0,2.38,1.07,2.38,2.38,0,1.31-1.07,2.38-2.38,2.38h-7.94c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38h1.59s0-23.82,0-23.82h-7.15s0,4.76,0,4.76c0,1.31-1.07,2.38-2.38,2.38s-2.38-1.07-2.38-2.38v-7.15ZM89.83,71.57h-47.64c-1.32,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38h47.64c1.32,0,2.38,1.07,2.38,2.38s-1.06,2.38-2.38,2.38ZM89.83,79.58h-47.64c-1.32,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38h47.64c1.32,0,2.38,1.07,2.38,2.38s-1.06,2.38-2.38,2.38ZM89.83,62.05h-47.64c-1.32,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38h47.64c1.32,0,2.38,1.07,2.38,2.38,0,1.31-1.06,2.38-2.38,2.38ZM89.83,52.52h-14.29c-1.32,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h14.29c1.31,0,2.38,1.07,2.38,2.38,0,1.31-1.07,2.38-2.38,2.38ZM89.83,42.99h-14.29c-1.32,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h14.29c1.31,0,2.38,1.07,2.38,2.38,0,1.31-1.07,2.38-2.38,2.38ZM89.83,33.46h-14.29c-1.32,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h14.29c1.31,0,2.38,1.07,2.38,2.38,0,1.31-1.07,2.38-2.38,2.38ZM75.53,23.93c-1.32,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38h14.29c1.31,0,2.38,1.07,2.38,2.38,0,1.31-1.07,2.38-2.38,2.38h-14.29ZM57.13,96.96c0-4.8,3.89-8.69,8.69-8.69s8.69,3.89,8.69,8.69-3.89,8.69-8.69,8.69c-4.8,0-8.69-3.89-8.69-8.69Z"
    />
  </svg>
);
export default TagDescriptionIcon;
