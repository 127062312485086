import { LayoutImportBay } from '@warebee/frontend/data-access-api-graphql';
import { ShelfSettings } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../../../../common/formatHelper';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import InboxZero from '../../../../components/InboxZero';
import { Button } from '../../../../components/actions/Button';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import TitleSection from '../../../../components/layout/TitleSection';
import { ActionBar } from '../../../../components/nav/ActionBar';
import { Stat } from '../../../../components/stats/Stat';
import { warehouseSelected } from '../../../../store/warehouse.state';
import { converterEditableAreaId } from '../store/converter.area.state';
import { converterShelvingTypeSelected } from '../store/converter.layout.state';
import {
  converterLayoutData,
  converterShelvesSettingsBuilder,
} from '../store/converter.state';

const StepBaySetupShelves: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const wh = useRecoilValue(warehouseSelected);
  const layoutData = useRecoilValue(converterLayoutData);
  const [shelfSettings, setShelfSettings] = useRecoilState(
    converterShelvesSettingsBuilder,
  );
  const [selected, setSelected] = useRecoilState(converterShelvingTypeSelected);
  const [shelfSettingsLocal, setShelfSettingsLocal] = useState<
    Record<string, Record<number, ShelfSettings>>
  >({});
  const isSelectable = !_.isNil(useRecoilValue(converterEditableAreaId));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShelfSettingsLocal(_.cloneDeep(shelfSettings));
  }, [shelfSettings]);

  const changeMinLevelHeight = useCallback(
    (value: number, level: number, typeId: string) => {
      setShelfSettingsLocal({
        ...(shelfSettingsLocal ?? {}),
        [typeId]: {
          ...(shelfSettingsLocal?.[typeId] ?? {}),
          [level]: {
            level,
            minHeight: value,
          },
        },
      });
    },
    [shelfSettingsLocal],
  );

  if (!layoutData) return null;

  const bays: LayoutImportBay[] = _.flattenDeep(
    layoutData.areas.map(area =>
      area.aisles.map(aisle => aisle.sides.map(s => s.bays)),
    ),
  );

  const allLevels = new Set<number>();

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  const bayCountByShelfType = _(bays)
    .map(b => {
      const levels = _.map(b.levels, l => l.level);
      _.forEach(levels, l => allLevels.add(l));
      return levels.join('-');
    })
    .countBy()
    .value();

  const sorted = _(bayCountByShelfType)
    .map((count, shelfType) => ({ shelfType, count }))
    .sortBy(i => -i.count)
    .value();

  const levels = _.sortBy([...allLevels.values()]);

  return (
    <TitleSection
      className="mt-4"
      titleView
      title={t`Shelving Sizes (By Level)`}
      classNameInner="p-2"
    >
      {!isSelectable && (
        <InboxZero
          className={`mt-2`}
          message={t`No Area Selected`}
          message_helper={t`To edit, double-click on an area to enter edit mode`}
        />
      )}

      {_.map(sorted, stats => {
        const { shelfType, count: countRaw } = stats;
        const count = formatInteger(countRaw);
        const isSelected = selected === shelfType;

        const maxLevel = parseInt(_.last(_.split(shelfType, '-')));
        return (
          <PanelBody key={`shelving-type-${shelfType}`}>
            <TitleSection
              id={`shelving-type-settings-${shelfType}`}
              title={
                <>
                  {/* <span>{t`Shelving:`}</span> */}
                  <span className="">{shelfType}</span>
                </>
              }
              inPanelView
              collapsible
              titleSummaryClosed={
                <span className="text-end text-sm p-1 px-2 rounded border border-menu-400">
                  {count}
                </span>
              }
              titleSummaryOpen={
                <span className="text-end text-sm p-1 px-2 rounded border border-menu-active/30 text-menu-active">
                  {count}
                </span>
              }
              classNameInner="px-2 py-2"
            >
              <Stat
                titleElement={
                  <>
                    <div className="opacity-50 ltr:mr-2 rtl:ml-2">{t`Shelving Levels`}</div>
                    <div>
                      {t(`{{ bayType }}`, {
                        bayType: shelfType,
                      })}
                    </div>
                  </>
                }
                value={count}
                isFullWidth
                hasHelper
                isActionable={isSelectable}
                isPreview
                isSelected={isSelectable && isSelected}
                onClick={() => setSelected(isSelected ? null : shelfType)}
              />
              <div className="bg-menu-500 rounded-b-md p-4 shadow-lg">
                <InputGroupList className=" space-y-2">
                  {_(levels)
                    .filter(l => l <= maxLevel)
                    .map(level => (
                      <InputNumber
                        key={`level-min-height-${level}`}
                        title={t(`Level: {{l}} (H.)`, { l: level })}
                        value={
                          shelfSettingsLocal?.[shelfType]?.[level]?.minHeight ??
                          0
                        }
                        onChange={v =>
                          changeMinLevelHeight(v, level, shelfType)
                        }
                        debounceInterval={100}
                        range={[0, null]}
                        disabled={!isSelectable}
                        unitOfMeasure={sizeMeasureValue}
                      />
                    ))
                    .value()}
                </InputGroupList>
                {isSelectable && (
                  <ActionBar className="mt-6 mb-2">
                    <Button
                      label={t`Apply`}
                      className="rounded"
                      buttonSize="sm"
                      buttonType="primary"
                      full
                      onPress={() => {
                        setLoading(true);
                        setShelfSettings(shelfSettingsLocal);
                        setLoading(false);
                      }}
                      isLoading={loading}
                      isDisabled={loading || !isSelectable}
                    />
                  </ActionBar>
                )}
              </div>
            </TitleSection>
          </PanelBody>
        );
      })}
    </TitleSection>
  );
};
export default StepBaySetupShelves;
