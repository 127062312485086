import {
  ConvertLayoutImportInput,
  SrcPlaneInput,
  useConvertLayoutImportMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useRecoilCallback } from 'recoil';
import { converterAreaConfiguration } from '../store/converter.area.state';
import { mergeAreas } from '../store/converter.helper';
import {
  converterBayTypesBuilder,
  converterCalculatedLayout,
  converterLayoutBuilder,
  converterLayoutIdSaving,
  converterLayoutImportDocumentId,
  converterLocationTypesBuilder,
} from '../store/converter.state';
import {
  getDuplicateAisleIds,
  hasCriticalValidationIssues,
  validateLayout,
} from '../store/validation.helper';
import { converterValidationResult } from '../store/validation.state';

function useSaveLayoutImport() {
  const [convertLayoutImport] = useConvertLayoutImportMutation();

  const saveLayoutCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const layoutImportId = await snapshot.getPromise(
          converterLayoutImportDocumentId,
        );
        const layoutBuilder = await snapshot.getPromise(converterLayoutBuilder);
        const areasConfigurations = await snapshot.getPromise(
          converterAreaConfiguration,
        );

        const bayTypesBuilder = await snapshot.getPromise(
          converterBayTypesBuilder,
        );

        const locationTypesBuilder = await snapshot.getPromise(
          converterLocationTypesBuilder,
        );

        const converterLayout = await snapshot.getPromise(
          converterCalculatedLayout,
        );
        const floors = _.values(layoutBuilder.floors);
        const floorConvertedAreas: SrcPlaneInput[] = [];

        const validation = validateLayout(
          converterLayout,
          layoutBuilder,
          areasConfigurations,
        );

        const hasCriticalIssues = hasCriticalValidationIssues(validation);
        if (hasCriticalIssues) {
          set(converterValidationResult, validation);
          set(converterLayoutIdSaving, null);
          return;
        }

        const areasMap = _.keyBy(converterLayout.areas, a => a.id);

        for (const floor of floors) {
          const floorAreasIds = _.values(areasConfigurations)
            .filter(
              areaConfig =>
                areaConfig.floor === floor.id &&
                !areaConfig.isDeleted &&
                _.has(areasMap, areaConfig.id),
            )
            .map(a => a.id);

          const areasToMerge = await Promise.all(
            floorAreasIds.map(async areaId => ({
              area: areasMap[areaId],
              config: areasConfigurations[areaId],
            })),
          );

          const mergedAreas = mergeAreas(
            areasToMerge,
            layoutBuilder.entryPoints?.[floor.id],
          );

          const floorLayoutArea: SrcPlaneInput = {
            id: floor.id,
            title: floor.title,
            aisles: mergedAreas.aisles.map(a => a.save()),
            bays: mergedAreas.bays.map(b => ({
              ...b.save(),
              aisleId: b.aisleId,
            })),
            start: mergedAreas.terminalSpec,
            end: mergedAreas.terminalSpec,
          };

          floorConvertedAreas.push(floorLayoutArea);
        }

        const duplicateAisleIds = getDuplicateAisleIds(floorConvertedAreas);
        if (!_.isEmpty(duplicateAisleIds)) {
          set(converterValidationResult, {
            floors: [],
            duplicateAisleIds,
          });
          set(converterLayoutIdSaving, null);
          return;
        }

        const layoutToSave: ConvertLayoutImportInput = {
          layoutImportId,
          layoutMap: {
            planes: floorConvertedAreas,
            bayTypes: Object.values(bayTypesBuilder).map(
              ({ bayType, verticalFrameProfile, shelfHeight, maxWeight }) => ({
                bayType,
                verticalFrameProfile,
                shelfHeight,
                maxWeight,
              }),
            ),
            locationTypes: Object.values(locationTypesBuilder).map(
              ({
                typeId,
                gapWidth,
                gapHeight,
                gapDepth,
                isWeakDepth,
                minDepth,
              }) => ({
                locationRackingType: typeId,
                gapWidth,
                gapHeight,
                gapDepth,
                isWeakDepth,
                //minDepth
              }),
            ),
          },
        };
        const saveResult = await convertLayoutImport({
          variables: {
            input: layoutToSave,
          },
        });

        const newLayoutId = saveResult.data.convertLayoutImport.id;
        set(converterLayoutIdSaving, newLayoutId);
      },
  );

  return saveLayoutCallback;
}

export default useSaveLayoutImport;
