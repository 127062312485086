import classNames from 'classnames';
import { LinkProps } from 'react-router-dom';
import LinkTraced from './LinkTraced';

export type NavLinkProps = LinkProps & {
  active?: boolean;
  titleTrace?: string;
};

export default function NavLink({
  to,
  children,
  className,
  titleTrace,
  active = false,
  ...linkProps
}: NavLinkProps) {
  return (
    <LinkTraced
      {...linkProps}
      to={to}
      data-component="NavLink"
      data-label={`link-to-${to}`}
      aria-label={`link-to-${to}`}
      titleTrace={`[${titleTrace || ''} ${to}]`}
      className={classNames('flex items-center', className)}
    >
      <div
        className={classNames(
          'flex flex-col items-center justify-center',
          'h-28 w-28',
          'p-2 mb-1',
          active
            ? 'active font-bold bg-menu-active text-menu-active-text'
            : 'bg-app-menu/40 text-menu-text/75 hover:bg-menu-active/70 hover:text-menu-active-text',
        )}
      >
        {children}
      </div>
    </LinkTraced>
  );
}
