import {
  GroupLocationsByFilterResultFragment,
  LayoutLocationFilter,
  LayoutLocationWithMatchingFiltersFragment,
  LoadLocationsByFilterDocument,
  LoadLocationsByFilterQuery,
  LoadLocationsByFilterQueryVariables,
  LoadLocationsCountByFilterDocument,
  LoadLocationsCountByFilterQuery,
  LoadLocationsCountByFilterQueryVariables,
  LocationFilterUnionFragment,
  WaypointPolicyFragment,
  WaypointPolicyInput,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { secureClient } from '../../../GraphQLClient';
import { cloneWithoutTypename } from '../../../common/utils';
import { getPolicyMatchInput } from '../../../policyFilters/policyFilter.helper';
import { WaypointPolicyLocationMatchDesc } from './waypointPolicy.types.';

export function getWaypointPolicyInput(
  policy: WaypointPolicyFragment,
): WaypointPolicyInput {
  return cloneWithoutTypename(policy);
}

export function getWaypointPolicyLocationMatchKey(
  descriptor: WaypointPolicyLocationMatchDesc,
) {
  return `${descriptor.ruleId}-${descriptor.groupType}`;
}
export function getWaypointPolicyOrderedLocationsMatch(
  policy: WaypointPolicyFragment,
): WaypointPolicyLocationMatchDesc[] {
  let index = 0;
  return _(policy?.rules)
    .map((r, ruleIndex) => {
      const descriptors: WaypointPolicyLocationMatchDesc[] = [];
      if (!_.isNil(r.startPoints)) {
        descriptors.push({
          ruleId: r.id,
          index: index++,
          groupType: 'start',
          locationsMatch: r.startPoints?.locationsMatch,
        });
      }
      if (!_.isNil(r.endPoints)) {
        descriptors.push({
          ruleId: r.id,
          index: index++,
          groupType: 'end',
          locationsMatch: r.endPoints?.locationsMatch,
        });
      }

      return descriptors;
    })
    .flatten()
    .value();
}

export async function loadLocationsByWaypointPolicy(params: {
  layoutId: string;
  policy: WaypointPolicyFragment;
  filter: LayoutLocationFilter;
}): Promise<Record<string, LayoutLocationWithMatchingFiltersFragment>> {
  const { policy } = params;

  const ordered = getWaypointPolicyOrderedLocationsMatch(policy);
  const filters = [
    ..._.map(ordered, r => getPolicyMatchInput(r.locationsMatch)),
  ];

  const response = await secureClient.query<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >({
    query: LoadLocationsByFilterDocument,
    variables: {
      filter: params.filter,
      layoutId: params.layoutId,
      input: {
        filters,
      },
    },
  });

  return (
    _.keyBy(response.data?.layout?.locationsWithMatchingFilters.content, c => c.locationId) ??
    {}
  );
}

export async function loadLocationsCountWaypointPolicy(params: {
  layoutId: string;
  locationsMatch: LocationFilterUnionFragment;
}): Promise<GroupLocationsByFilterResultFragment> {
  const response = await secureClient.query<
    LoadLocationsCountByFilterQuery,
    LoadLocationsCountByFilterQueryVariables
  >({
    query: LoadLocationsCountByFilterDocument,
    variables: {
      layoutId: params.layoutId,
      filter: getPolicyMatchInput(params.locationsMatch),
    },
  });

  return response.data?.layout?.locationGroupsByFilter;
}
