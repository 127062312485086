import { Role } from '../types';

export const AdminWarehouse: Role = {
  id: 'AdminWarehouse',
  version: '2024-06-01',
  description:
    'Warehouse Administrator - can read and write to all warehouses and all resources',
  permissions: [
    {
      actions: ['read', 'write', 'delete'],
      features: ['*'],
    },
  ],
  resources: ['/wh/i/{warehouse-id}/*'],
};
