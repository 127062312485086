import { OrderedQuantityReportItemStatus } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '../../helpContext/Tag';
import { getOrderedItemsTitlesMap } from '../store/simulationReport.helper';

export type OrderedItemStatusTagProps = {
  status: OrderedQuantityReportItemStatus;
};

const statusClassMap: Record<OrderedQuantityReportItemStatus, string> = {
  ENOUGH: 'bg-alerts-okay text-menu-text',
  NOT_ENOUGH: 'bg-alerts-warn text-menu-text',
  NOT_ORDERED: 'bg-menu text-menu-text',
  UNKNOWN_ORDERED: '',
  UNKNOWN_ASSIGNED: '',
  UNKNOWN_BOTH: '',
};

const OrderedItemStatusTag: React.FC<OrderedItemStatusTagProps> = props => {
  const { t } = useTranslation('simulation');
  const titles = getOrderedItemsTitlesMap(t);
  return (
    <Tag classType={statusClassMap[props.status]}>{titles[props.status]}</Tag>
  );
};

export default OrderedItemStatusTag;
