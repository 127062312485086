import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import Callout from '../../helpContext/Callout';
import {
  userHasOptimise,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import SwapPolicyIssue from '../../swapPolicy/SwapPolicyIssue';
import { swapPolicyCheckResult } from '../../swapPolicy/swapPolicy.state';
import OptimisationRunSettings from './OptimisationRunSettings';

const OptimisationSetupPanel: React.FC = () => {
  const whId = useRecoilValue(warehouseSelectedId);
  const { t } = useTranslation('simulation');
  const allowOptimise = useRecoilValue(userHasOptimise);
  const navigate = useNavigate();

  const checkResult = useRecoilValue(swapPolicyCheckResult);
  const ruleCheckResult = _.find(
    checkResult?.result?.rules,
    // r => r.id === ,
  );
  const hasIssue = ruleCheckResult?.hasValidSwaps === false;

  return (
    <>
      <Container col hasOverflowY>
        <ScreenTitle
          subtitle={t`Optimisation Configuration`}
          title={t`Optimisation`}
          // isSticky // Disabled so it's won't interfere with the Stat sticky-ness.
          helpNavTo={'simulation/optimise/simulation-optimise-setup'}
          icon={Icon.SimulationOptimize}
        />

        {hasIssue && <SwapPolicyIssue details={ruleCheckResult?.details} />}

        {!allowOptimise && (
          <Callout panelMode type="suggestion">
            <div className="text-menu-active mb-4 text-xl">
              <div>{t`Apologies, \n`}</div>
              <div>{t`Looks like the Optimisation Option is not available on your current plan.\n\n`}</div>
            </div>

            <div>{t`Please proceed to Pricing plans page:`}</div>
            <Button
              full
              className="mt-4"
              buttonSize={'sm'}
              label={t`Go to Pricing`}
              buttonType="primary"
              hasIconAfter
              buttonIcon={
                <Icon.ArrowRight className={`h-4 w-4 fill-current`} />
              }
              onPress={() => {
                navigate(`/wh/i/${whId}/pricing/`);
              }}
            />
          </Callout>
        )}
        {allowOptimise && <OptimisationRunSettings />}
      </Container>
    </>
  );
};
export default OptimisationSetupPanel;
