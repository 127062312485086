import React from 'react';
import { useTranslation } from 'react-i18next';
import AssignmentPolicy from '../../assignmentPolicy/AssignmentPolicy';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import SimulationItemSetWrap from '../hoc/SimulationItemSetWrap';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDatasetAssignmentPolicy: React.FC = () => {
  return (
    <SimulationItemSetWrap>
      <AssignmentPolicy />
      <ActionBarNextStep />
    </SimulationItemSetWrap>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-storage',
    title: t`Storage Policy + Compliance`,
    loadingMessage: t`Loading Storage Policy...`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDatasetAssignmentPolicy />
    </SidebarContainer>
  );
};
