import { AisleFeatureFragment } from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { KonvaNodeEvents, Line, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';
import { analyticsAgent } from '../../AnalyticTracker';
import { getBox, getBoxWH, getPoints } from '../viewer/store/viewer.helper';

const aisleColors = TwTheme.extend.colors.aisle;

export type AisleProps = {
  aisle: AisleFeatureFragment;
  color?: string;
  isStaticMode?: boolean;
  hideLabels?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onDblClick?: () => void;
};

const AisleFeature: React.FC<AisleProps> = props => {
  const { aisle } = props;

  function onAisleClick(evt: Konva.KonvaEventObject<MouseEvent>) {
    analyticsAgent?.track('Aisle: Selected (Click)', {
      title: props.aisle?.title,
      id: props.aisle?.id,
    });
    props.onClick && props.onClick();
  }

  const fill = aisle.navigable
    ? props.color || aisleColors.DEFAULT
    : aisleColors.block;
  const config: Konva.LineConfig = {
    id: aisle.id,
    points: _.flatten(getPoints(aisle.shape)),
    fill,
    strokeScaleEnabled: false,
    strokeWidth: 0,
    perfectDrawEnabled: false,
    closed: true,
    listening: !props.isStaticMode,
  };

  let eventConfig: KonvaNodeEvents = {};

  if (!props.isStaticMode) {
    eventConfig = {
      ...eventConfig,
      onClick: onAisleClick,
      onTap: onAisleClick,
      onDblClick: e => {
        props.onDblClick && props.onDblClick();
      },
      onMouseEnter: e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        props.onMouseOver && props.onMouseOver();
      },

      onMouseLeave: e => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        props.onMouseOut && props.onMouseOut();
      },
    };
  }
  const box = getBox(aisle.shape);
  const [boxWidth, boxHeight] = getBoxWH(box);
  const captionLength = (aisle.title || aisle.id || '').length + 1;
  const fontSize = Math.min(
    Math.floor(0.35 * boxHeight),
    Math.floor((0.65 * boxWidth) / captionLength),
  );
  return (
    <>
      <Line {...config} {...eventConfig}></Line>
      {!props.hideLabels && (
        <Text
          x={box.xmin}
          y={box.ymin}
          width={boxWidth}
          height={-boxHeight}
          fontSize={fontSize}
          align="center"
          verticalAlign="middle"
          fill={'rgba(255,255,255,0.75)'}
          scaleY={-1}
          text={aisle.title || aisle.id}
          listening={false}
        />
      )}
    </>
  );
};

export default React.memo(AisleFeature);
