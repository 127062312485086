import {
  InputAndObjectType,
  Field,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';

export enum LocationFillingMethod {
  FILL = 'FILL',
  MAX_UOM = 'MAX_UOM',
}

registerEnumType(LocationFillingMethod, {
  name: 'LocationFillingMethod',
});

@InputAndObjectType()
export class AssignmentCapacityPolicy {
  @Field(() => LocationFillingMethod, { nullable: true })
  locationFillingMethod?: LocationFillingMethod;
}
