import {
  BatchJobStatus,
  ExportJobFragment,
  useLoadExportJobLazyQuery,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EXPORT_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';
import { Button } from './Button';

export type ExportButtonProps = {
  title?: string;
  onStartExportClick?: () => Promise<{ job: ExportJobFragment; errors: any }>;
};

const ExportButton: React.FC<ExportButtonProps> = props => {
  const { t } = useTranslation('app');
  const [loadExportJob, { data: exportJobResponse }] =
    useLoadExportJobLazyQuery();
  const [exportJob, setExportJob] = useState<ExportJobFragment>();
  const [exportLoading, setExportLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [showLinkReady, setShowLinkReady] = useState(false);

  const job = exportJob
    ? exportJob.id === exportJobResponse?.exportJob?.id
      ? exportJobResponse?.exportJob
      : exportJob
    : null;

  useEffect(() => {
    let timeoutId;

    if (
      job?.status === BatchJobStatus.FAILED ||
      job?.status === BatchJobStatus.TERMINATED
    ) {
      setHasErrors(true);
      setExportLoading(false);
    }

    if (
      job?.status === BatchJobStatus.CALCULATING ||
      job?.status === BatchJobStatus.CREATED
    ) {
      timeoutId = setTimeout(() => {
        loadExportJob({ variables: { id: job.id } });
      }, EXPORT_PROGRESS_REFRESH_INTERVAL);
    }
    if (job?.status === BatchJobStatus.READY && job?.downloadUrl) {
      setExportLoading(false);
      const newWin = window.open('_blank', 'noreferrer');
      console.log(newWin);
      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        console.log(newWin, newWin?.closed);
        setShowLinkReady(true);
      } else {
        newWin.location = job?.downloadUrl;
        setExportJob(null);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [job, loadExportJob]);

  async function startExport() {
    setHasErrors(false);
    setExportLoading(true);
    try {
      const exportJobResponse = await props.onStartExportClick();
      if (
        _.isEmpty(exportJobResponse.errors) &&
        !_.isNil(exportJobResponse.job)
      ) {
        setExportJob(exportJobResponse.job);
      } else {
        console.error(exportJobResponse.errors);
        setHasErrors(true);
        setExportLoading(false);
      }
    } catch (ex) {
      console.error(ex);
      setHasErrors(true);
      setExportLoading(false);
    }
  }

  const getIcon = () => {
    if (hasErrors)
      return <Icon.Close className={classNames('w-4 h-4 fill-current')} />;
    if (exportLoading)
      return <Loader size={16} stroke={6} type={'loading-original'} />;
    return (
      <Icon.CloudDownload className={classNames('w-4 h-4 fill-current')} />
    );
  };

  const getLabel = () => {
    if (hasErrors) return t`Error`;
    if (exportLoading) return t`Loading...`;
    return props.title ?? t`Export`;
  };

  return (
    <>
      {!showLinkReady && (
        <Button
          className={classNames('hover:text-menu-active')}
          buttonSize="xs"
          buttonType={hasErrors ? 'delete' : 'buttonTransparent'}
          isDisabled={exportLoading}
          //isLoading={exportLoading}
          hasIconBefore
          buttonIcon={getIcon()}
          label={getLabel()}
          onPress={startExport}
        />
      )}

      {showLinkReady && (
        <a
          rel="noopener noreferrer"
          className={classNames(
            'text-menu-active/90 hover:text-menu-active text-sm py-0.5 flex items-center px-2',
          )}
          href={`${job.downloadUrl}`}
          target="_blank"
          onClick={() => {
            setShowLinkReady(false);
            setExportJob(null);
          }}
        >
          <Icon.CloudDownload className={classNames('w-4 h-4 fill-current')} />
          <span
            className={classNames('ltr:ml-2 rtl:mr-2')}
          >{t`Download (csv)`}</span>
        </a>
      )}
    </>
  );
};

export default ExportButton;
