import classNames from 'classnames';
import React from 'react';

type TagProps = {
  className?: string;
};

const EmptyValueTag: React.FC<TagProps> = props => {
  return (
    <span
      data-component="EmptyValueTag"
      className={classNames('flex-1 text-right opacity-30')}
    >
      {`—`}
    </span>
  );
};

export default EmptyValueTag;
