import {
  LoadBalancingZoneType,
  SwapSettingsInput,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import { optimisationIsEditable } from '../simulation/store/optimisation.state';
import { collapsibleStateAtom } from '../store/collapsible.state';
import LoadBalancingPolicyRule from './LoadBalancingPolicyRule';
import { getLoadBalancingPolicyInput } from './loadBalancingPolicy.helper';
import {
  loadBalancingPolicyDocument,
  loadBalancingPolicySelectedIdentity,
} from './loadBalancingPolicy.state';

const LoadBalancingPolicyPanel: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(loadBalancingPolicyDocument);
  // const checkResult = useRecoilValue(loadBalancingPolicyCheckResult);
  // const [callCheck, cancelCheck] = useCheckSwapPolicy();
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    loadBalancingPolicySelectedIdentity,
  );

  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(optimisationIsEditable);
  const updateSim = useUpdateSimulation();

  const [savedPolicy, setSavedPolicy] = useState<SwapSettingsInput>(null);

  useEffect(() => {
    return () => {
      setSelectedIdentity(null);
    };
  }, []);

  //trigger policy saving and policy check request when AP changed
  useEffect(() => {
    const policyToSave = getLoadBalancingPolicyInput(policy);

    const isPolicyChanged = !_.isEqual(policyToSave, savedPolicy);
    const isInitial = savedPolicy === null;
    setSavedPolicy(policyToSave);
    if (!isPolicyChanged) return;

    if (!isInitial) {
      updateSim({
        optimizationSettings: {
          loadBalancing: policyToSave,
        },
      });
    }

    //reset assignment compliance state
    // cancelCheck();
    // callCheck();
    // return () => {
    //   cancelCheck();
    // };
  }, [policy]);

  const removeRule = (ruleId: string) => {
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy?.rules, r => r.title)),
      t`Rule #`,
    );
    setPolicy({
      ...(policy ?? {}),
      rules: [
        ...(policy?.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          type: LoadBalancingZoneType.AISLE,
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  const hasErrorPolicy = false;
  const hasRules = !_.isEmpty(policy?.rules);
  const policyRuleCounter = policy?.rules?.length;

  return (
    <>
      <ScreenTitle
        subtitle={t`Optimisation`}
        title={t`Balancing Policy`}
        isSticky
        helpNavTo={'simulation/optimise/simulation-optimise-policy-workload'}
        icon={Icon.WorkloadZoneBalance}
      />

      <TitleSection
        title={
          <PolicyRuleSectionTitle
            isFeasible={!hasErrorPolicy}
            counter={policyRuleCounter}
          />
        }
        inSidebarView
        hasScreenTitle
        hasAction={
          canUpdate ? (
            hasRules && (
              <Button
                label={t`Add Policy`}
                className={classNames('ltr:ml-4 rtl:mr-4 rounded')}
                buttonSize="xs"
                buttonType="primary"
                hasIconAfter={
                  <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                }
                onPress={() => addRule()}
              />
            )
          ) : (
            <StatusTag
              title={t`Policy Locked`}
              type="locked"
              modeStyle="stroke"
              icon={Icon.Lock}
              onPress={null}
            />
          )
        }
      />
      <PolicyWell fullHeight isDisabled={!canUpdate}>
        {!hasRules && (
          <InboxZero selfCenter hasIcon message={t`No Rules found`}>
            {canUpdate && (
              <Button
                full
                buttonSize="sm"
                buttonType="primary"
                label={t`Add Policy`}
                className="flex-1 rounded mt-4"
                onPress={addRule}
                hasIconAfter
                buttonIcon={
                  <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                }
              />
            )}
          </InboxZero>
        )}
        
        {_.map(policy?.rules, (rule, index) => {
          return (
            <LoadBalancingPolicyRule
              isDisabled={!canUpdate}
              key={`load-balancing-rule-${rule.id}`}
              ruleId={rule.id}
              index={index + 1}
              canDelete={true}
              onDeleteClick={() => removeRule(rule.id)}
              canRename={true}
              isCheckLoading={false}
            />
          );
        })}
      </PolicyWell>
    </>
  );
};

export default LoadBalancingPolicyPanel;
