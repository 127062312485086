import * as React from 'react';
const LockIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LockIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M89.26,52.42v-24c0-13.47-11.81-24.42-26.32-24.42s-26.32,10.96-26.32,24.42v24h-18.95v71.58h92.63V52.42h-21.05ZM40.84,28.42c0-11.15,9.92-20.21,22.11-20.21s22.11,9.07,22.11,20.21v24h-44.21v-24ZM21.89,98.74h21.05l-21.05,21.05v-21.05ZM42.95,119.79l21.05-21.05h27.37l-21.05,21.05h-27.37ZM106.11,119.79h-14.74l14.74-14.74v14.74Z"
    />
  </svg>
);
export default LockIcon;
