import * as React from 'react';
const TagEditIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TagEditIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M101.64,11.83l-1.38,1.38h0s16.28,16.28,16.28,16.28h0s1.38-1.38,1.38-1.38c3.62-3.62,3.62-9.5,0-13.12l-3.16-3.16c-3.62-3.63-9.5-3.63-13.13,0Z"
    />
    <path
      strokeWidth={0}
      d="M53.18,61.23l-2.66,13.38c-.55,2.75,1.87,5.17,4.62,4.62l13.38-2.66c.76-.15,1.46-.53,2.01-1.08l39.16-39.17c1.54-1.54,1.54-4.02,0-5.56l-10.72-10.73c-1.54-1.54-4.02-1.54-5.56,0l-39.17,39.17c-.55.55-.92,1.25-1.08,2.01Z"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M125.44,70.18l-21.26-21.26-30.11,30.11c-1.25,1.25-2.83,2.1-4.58,2.45l-13.38,2.66c-.58.12-1.17.17-1.75.17,0,0,0,0,0,0-9.46,2.22-8.95-3.12-8.75-10.67l2.66-13.38c.35-1.74,1.19-3.33,2.45-4.58l30.11-30.11L59.63,4.38c-4.51-4.51-11.85-4.5-16.37.02L4.39,43.26C-.13,47.78-.13,55.12,4.39,59.65l64.23,64.23c1.96,1.96,4.95,3.17,7.73,3.12l38.42-.71c6.27-.11,11.41-5.23,11.52-11.52l.71-38.42c.04-2.03-.43-4.38-1.56-6.17ZM106.92,106.92c-3.91,3.91-10.23,3.91-14.14,0-3.91-3.91-3.91-10.23,0-14.14,3.91-3.91,10.23-3.91,14.14,0,3.91,3.91,3.91,10.24,0,14.14Z"
    />
    <path
      strokeWidth={0}
      d="M53.18,61.23l-2.66,13.38c-.55,2.75,1.87,5.17,4.62,4.62l13.38-2.66c.76-.15,1.46-.53,2.01-1.08l39.16-39.17c1.54-1.54,1.54-4.02,0-5.56l-10.72-10.73c-1.54-1.54-4.02-1.54-5.56,0l-39.17,39.17c-.55.55-.92,1.25-1.08,2.01Z"
    />
  </svg>
);
export default TagEditIcon;
