import {
  LayoutLocationFilter,
  LoadLocationsByFilterDocument,
  LoadLocationsByFilterQuery,
  LoadLocationsByFilterQueryVariables,
  LocationFilterUnionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { secureClient } from '../GraphQLClient';
import { getPolicyMatchInput } from '../policyFilters/policyFilter.helper';

export type RuleDef = {
  id: string;
  color: string;
};

export type RuleWithLocations = RuleDef & {
  locationsMatch?: LocationFilterUnionFragment;
};

export type LoadLocationsByPolicyParams = {
  layoutId: string;
  rules: RuleWithLocations[];
  filter: LayoutLocationFilter;
};

export async function loadLocationsByPolicy(
  params: LoadLocationsByPolicyParams,
): Promise<Record<string, RuleDef[]>> {
  const ruleDefs = _.map(params.rules, r => _.pick(r, ['id', 'color']));

  const response = await secureClient.query<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >({
    query: LoadLocationsByFilterDocument,
    variables: {
      filter: params.filter,
      layoutId: params.layoutId,
      input: {
        filters: _.map(params.rules, r =>
          getPolicyMatchInput(r.locationsMatch),
        ),
      },
    },
  });

  const result: Record<string, RuleDef[]> = {};

  _.forEach(response.data?.layout?.locationsWithMatchingFilters?.content, l => {
    result[l.locationId] = _.map(
      l.allMatchingFilters,
      index => ruleDefs[index],
    );
  });

  return result;
}
