import { ReassignTaskAction } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import AssignmentStatsLocationInfoActive from '../../assignmentStats/AssignmentStatsLocationInfoActive';
import { getBestContrastColor } from '../../common/color.helper';
import { getHeatmapPowRangeBucketByValue } from '../../common/heatmap.helper';
import ItemCategoryTag from '../../components/table/ItemCategoryTag';
import { viewerHoveredLocation } from '../../layout/viewer/store/viewer.state';
import {
  getReorderingHeatmapBuckets,
  getVisitsHeatmapBuckets,
} from '../store/analyze.helper';
import { simulationHeatmapLocationByLevel } from '../store/simulation.heatmap.state';
import {
  simulationMaxAppliedReorders,
  simulationMaxLocationVisits,
  simulationMaxTriggeredReorders,
} from '../store/simulation.state';
import {
  showAnalyseData,
  simulationWizardSelectedStepId,
} from '../store/simulation.wizard.state';

const locationColors = TwTheme.extend.colors.location;

export type SimulationLocationTooltipProps = {
  analyzeId: string;
};

const SimulationLocationTooltip: React.FC<
  SimulationLocationTooltipProps
> = props => {
  const { t } = useTranslation('simulation');
  const location = useRecoilValue(viewerHoveredLocation);
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const locationMap = useRecoilValue(
    simulationHeatmapLocationByLevel(props.analyzeId),
  );

  const maxVisits = useRecoilValue(simulationMaxLocationVisits);
  const maxAppliedReorders = useRecoilValue(simulationMaxAppliedReorders);
  const maxTriggeredReorders = useRecoilValue(simulationMaxTriggeredReorders);
  const showAnalyse = useRecoilValue(showAnalyseData);
  if (!location) return null;

  const visitsBuckets = getVisitsHeatmapBuckets(maxVisits);
  const appliedReordersBuckets =
    getReorderingHeatmapBuckets(maxAppliedReorders);
  const triggeredReordersBuckets =
    getReorderingHeatmapBuckets(maxTriggeredReorders);

  const getItems = () => {
    if (!showAnalyse || _.isNil(locationMap)) return null;
    const locationItems = locationMap?.[location.locationId];
    if (_.isEmpty(locationItems)) return null;

    const productsAssigned = _(locationItems)
      .filter(p =>
        _.every(p.reassignTasks, t => t.action !== ReassignTaskAction.TAKE),
      )
      .value();

    const products = _(productsAssigned)
      .sortBy(p => -p.hitCount)
      .take(10)
      .value();

    const diff = _.size(productsAssigned) - _.size(products);

    return (
      <>
        {products.map(item => {
          const hitCount =
            (item.pickCount ?? 0) + (item.replenishmentCount ?? 0);
          const visitsBucket = getHeatmapPowRangeBucketByValue(
            hitCount,
            maxVisits,
            visitsBuckets,
          );

          const bgColor = visitsBucket?.color ?? locationColors.empty;
          const textColor = getBestContrastColor(bgColor);

          // const bgColor = visitsBucket?.color ?? locationColors.empty;
          // const textColor = visitsBucket?.textColor ?? locationColors.textEmpty;

          return (
            <div
              data-component="SimulationLocationTooltipItems"
              key={`item-${item.sku}`}
              className={classNames(
                'min-w-20',
                'bg-app-panel-dark/70',
                'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
              )}
            >
              <div
                className={classNames(
                  'flex items-center',
                  'p-1 px-2',
                  'text-xs',
                  'uppercase',
                  'text-start',
                )}
              >
                <span className={classNames('text-xs text-menu-text')}>
                  <span className={classNames('opacity-50')}>{t`Item`}:</span>
                  <span className={classNames('ltr:ml-1 rtl:mr-1')}>
                    {item.sku}
                  </span>
                </span>

                <ItemCategoryTag cmlPercent={item.cumulativePercentRank} />

                <span
                  className={classNames(
                    'flex items-center',
                    'p-1 px-2',
                    'ltr:ml-2 rtl:mr-2',
                    'text-xs',
                    'text-menu-text',
                    'rounded-sm',
                  )}
                  style={{
                    background: bgColor,
                    color: textColor,
                  }}
                >
                  {hitCount}
                </span>
              </div>
            </div>
          );
        })}
        {diff > 0 && (
          <div
            className={classNames(
              'min-w-20',
              'p-1 px-2',
              'text-xs',
              'bg-app-panel-dark/70',
              'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
            )}
          >
            {` `}
            {t(`and {{productCount}} more`, { productCount: diff })}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div
        data-component="SimulationLocationTooltip"
        className={classNames(
          'min-w-tooltip',
          'font-bold text-center text-menu-text',
        )}
      >
        <div
          className={classNames(
            'flex items-center',
            'p-1 px-2 text-xxs',
            'uppercase',
            'bg-menu-active/90 text-menu-active-text',
            'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
          )}
        >
          {t`Location`}
        </div>
        <div
          className={classNames(
            'flex items-center',
            'p-1 px-2',
            'text-sm',
            'bg-app-panel-dark/90',
            'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
          )}
        >
          {location.locationId}
        </div>
        {location.locationStatus ? (
          ''
        ) : (
          <div
            className={classNames(
              'flex items-center',
              'p-1 px-2',
              'text-xxs',
              'uppercase',
              'bg-app-panel-dark/60',
              'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
            )}
          >
            {t`Disabled`}
          </div>
        )}
      </div>
      {getItems()}
      {stepId === 'data-stats' && (
        <AssignmentStatsLocationInfoActive locationId={location.locationId} />
      )}
    </>
  );
};

export default SimulationLocationTooltip;
