import * as React from 'react';
const ChevronDownIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ChevronDownIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,68.44l-25.85-25.85c-.52-.52-1.29-.67-1.97-.39-.68.28-1.12.94-1.12,1.67v14.93c0,.5.21.98.57,1.32l27.12,25.32c.69.65,1.77.65,2.47,0l27.12-25.32c.37-.34.57-.82.57-1.32v-14.93c0-.73-.44-1.39-1.12-1.67-.68-.28-1.45-.13-1.97.39l-25.85,25.85Z"
    />
  </svg>
);
export default ChevronDownIcon;
