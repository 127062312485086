import Flatten from '@flatten-js/core';
import { alg, Graph, Path } from 'graphlib';
import {
  ConnectivityGraph,
  Point,
  WaypointType,
} from '@warebee/shared/engine-model';
import {
  DirectLocalNavigationAlgorithm,
  LocalNavigationAlgorithm,
  LocalNavigationSettings,
  RoutingSettings,
} from './local-navigation';
import { NavigablePolygon, RouteInfo } from './navigation.common';
import { Waypoint } from './navigation.model';
import { loadSegment } from './geometry.engine';
import { keyBy } from 'lodash';

export interface InterPolygonPortal {
  id: string;
  polygonId1: string;
  polygonId2: string;
  coords: Flatten.Segment;
}

export interface RoutePointSpec {
  polygonId: string;
  waypoint: Waypoint;
  settings?: LocalNavigationSettings;
}

export class RouteResult {
  constructor(
    readonly waypoints: Waypoint[],
    readonly distance: number,
    readonly unreachablePoints: RoutePointSpec[],
  ) {}
}

export type PortalGraphEdge = RouteInfo & { polygonId: string };

const START_NODE = '_START';
const END_NODE = '_END';

class PortalGraph {
  private readonly portals: InterPolygonPortal[];

  private readonly portalsByPolygonId: Record<string, Waypoint[]> = {};
  private readonly polygonIdsByPortalId: Record<string, [string, string]> = {};

  private readonly portalGraph: Graph;

  constructor(
    private readonly polygonsById: Record<string, NavigablePolygon>,
    private readonly cg: ConnectivityGraph,
    private readonly localNav: LocalNavigationAlgorithm,
  ) {
    const portalIdSuffixes: Record<string, number> = {};
    this.portals = cg.aislePortals.map(ap => {
      let id = `${ap.aisleId1}-${ap.aisleId2}`;
      if (portalIdSuffixes[id]) {
        id = id + '-' + portalIdSuffixes[id]++;
      } else {
        portalIdSuffixes[id] = 1;
      }
      return {
        id,
        polygonId1: ap.aisleId1,
        polygonId2: ap.aisleId2,
        coords: loadSegment(ap.coords),
      };
    });

    const addWaypoint = (polygonId: string, pw: Waypoint) => {
      if (this.portalsByPolygonId[polygonId]) {
        this.portalsByPolygonId[polygonId].push(pw);
      } else {
        this.portalsByPolygonId[polygonId] = [pw];
      }
    };

    this.portalGraph = new Graph();

    this.portals.forEach(p => {
      const pw: Waypoint = {
        type: WaypointType.PORTAL,
        id: p.id,
        position: p.coords.middle(),
      };
      addWaypoint(p.polygonId1, pw);
      addWaypoint(p.polygonId2, pw);

      this.polygonIdsByPortalId[pw.id] = [p.polygonId1, p.polygonId2];

      this.portalGraph.setNode(pw.id, pw);
    });

    Object.values(polygonsById).forEach(pol => {
      const pws = this.portalsByPolygonId[pol.id];
      if (!pws) {
        return;
      }
      pws.forEach(p1 => {
        pws.forEach(p2 => {
          if (p1 === p2) {
            return;
          }

          const localRoute = localNav.findRoute(pol, p1.position, p2.position);
          if (localRoute) {
            this.portalGraph.setEdge(p1.id, p2.id, {
              ...localRoute,
              polygonId: pol.id,
            });
          }
        });
      });
    });
  }

  findRoute(
    srcPolygonId: string,
    srcWaypoint: Waypoint,
    destPolygonId: string,
    destWaypoint: Waypoint,
    settings?: LocalNavigationSettings,
  ): RouteInfo | null {
    this.portalGraph.setNode(START_NODE, srcWaypoint);
    this.portalGraph.setNode(END_NODE, destWaypoint);

    const addLocalEdges = (
      polygonId: string,
      node: string,
      position: Flatten.Point,
      portals: Waypoint[],
      backwards?: boolean,
    ) => {
      const polygon = this.polygonsById[polygonId];
      for (const portal of portals) {
        const [startNode, startPosition, endNode, endPosition] = backwards
          ? [portal.id, portal.position, node, position]
          : [node, position, portal.id, portal.position];

        const localRoute = this.localNav.findRoute(
          polygon,
          startPosition,
          endPosition,
          settings,
        );
        if (localRoute) {
          this.portalGraph.setEdge(startNode, endNode, {
            ...localRoute,
            polygonId,
          });
        }
      }
    };

    addLocalEdges(
      srcPolygonId,
      START_NODE,
      srcWaypoint.position,
      this.portalsByPolygonId[srcPolygonId] || [],
    );

    addLocalEdges(
      destPolygonId,
      END_NODE,
      destWaypoint.position,
      this.portalsByPolygonId[destPolygonId] || [],
      true,
    );

    try {
      const allPathsFromSrc = alg.dijkstra(this.portalGraph, START_NODE, e => {
        const routeInfo = this.portalGraph.edge(e) as PortalGraphEdge;
        return routeInfo.distance;
      });

      const route = this.findPortalRoute(allPathsFromSrc, START_NODE, END_NODE);
      if (route) {
        route.waypoints = route.waypoints.slice(1, -1); // remove dummy _START and _END waypoints from route
      }
      return route;
    } finally {
      this.portalGraph.removeNode(START_NODE);
      this.portalGraph.removeNode(END_NODE);
    }
  }

  private findPortalRoute(
    allPathsFromSrc: Record<string, Path>,
    srcPortalId: string,
    destPortalId: string,
  ): RouteInfo | null {
    const waypoints = [];

    let currentEnd = destPortalId;
    let lastSegment = allPathsFromSrc[destPortalId];

    if (!lastSegment || !Number.isFinite(lastSegment.distance)) {
      return null;
    }

    const distance = lastSegment.distance;

    waypoints.push(this.portalGraph.node(currentEnd));

    if (!lastSegment.predecessor) {
      return { waypoints, distance };
    }

    while (lastSegment.predecessor && lastSegment.predecessor !== srcPortalId) {
      currentEnd = lastSegment.predecessor;
      lastSegment = allPathsFromSrc[currentEnd];

      if (!lastSegment || !Number.isFinite(lastSegment.distance)) {
        // console.log(
        //   'searching for %s -> %s: leg not found for %s: %o',
        //   srcPortalId,
        //   destPortalId,
        //   '' + currentEnd,
        //   allPathsFromSrc,
        // );
        return null;
      }

      waypoints.push(this.portalGraph.node(currentEnd));
    }

    waypoints.push(this.portalGraph.node(srcPortalId));

    return { waypoints: waypoints.reverse(), distance };
  }

  findPortalsByPolygonId(featureId: string): Waypoint[] {
    return this.portalsByPolygonId[featureId] || [];
  }

  findPortalsReachableFrom(
    waypoints: Waypoint[],
    backwards?: boolean,
  ): Record<string, Waypoint[]> {
    const accessiblePortalIds = new Set(waypoints.map(wp => wp.id));
    const queue = [...waypoints];

    while (queue.length > 0) {
      const p = queue.pop();
      const successors = backwards
        ? this.portalGraph.predecessors(p.id)
        : this.portalGraph.successors(p.id);
      if (successors) {
        for (const s of successors) {
          if (!accessiblePortalIds.has(s)) {
            accessiblePortalIds.add(s);
            queue.push(this.portalGraph.node(s));
          }
        }
      }
    }

    const result: Record<string, Waypoint[]> = {};

    for (const portalId of accessiblePortalIds) {
      for (const polygonId of this.polygonIdsByPortalId[portalId]) {
        const portals = result[polygonId];
        if (portals) {
          portals.push(this.portalGraph.node(portalId));
        } else {
          result[polygonId] = [this.portalGraph.node(portalId)];
        }
      }
    }

    return result;
  }
}

/**
 * Global navigation engine.
 */
export interface NavigationEngine {
  findRoute(routeSpec: RoutePointSpec[]): RouteResult;

  findPolygonsReachableFrom(
    polygonId: string,
    position?: Flatten.Point,
    backwards?: boolean,
  ): Record<string, Waypoint[]>;

  isLocallyReachable(
    polygonId: string,
    srcs: Flatten.Point[],
    dest: Flatten.Point,
    backwards?: boolean,
  ): boolean;
}

/**
 * Creates global navigation engine for the given set of navigable polygons and portals.
 * @param polygons
 * @param portals
 * @param localNav
 * @returns
 */
export function createNavigationEngine(
  polygons: NavigablePolygon[],
  connectivityGraph: ConnectivityGraph,
  routingSettings?: RoutingSettings,
): NavigationEngine {
  const polygonsById = keyBy(polygons, a => a.id);

  const localNav = new DirectLocalNavigationAlgorithm(routingSettings);
  const portalGraph = new PortalGraph(
    polygonsById,
    connectivityGraph,
    localNav,
  );

  return new NavigationEngineImpl(polygonsById, localNav, portalGraph);
}

class NavigationEngineImpl implements NavigationEngine {
  constructor(
    private readonly polygonsById: Record<string, NavigablePolygon>,
    private readonly localNav: LocalNavigationAlgorithm,
    private readonly portalGraph: PortalGraph,
  ) {}

  findPolygonsReachableFrom(
    polygonId: string,
    position?: Flatten.Point,
    backwards?: boolean,
  ): Record<string, Waypoint[]> {
    const startPortals = this.portalGraph
      .findPortalsByPolygonId(polygonId)
      .filter(p => {
        if (position) {
          const [src, dest] = backwards
            ? [p.position, position]
            : [position, p.position];
          return (
            this.localNav.findRoute(this.polygonsById[polygonId], src, dest) !=
            null
          );
        } else {
          return true;
        }
      });

    return this.portalGraph.findPortalsReachableFrom(startPortals, backwards);
  }

  isLocallyReachable(
    polygonId: string,
    srcs: Flatten.Point[],
    dest: Flatten.Point,
    backwards?: boolean,
  ): boolean {
    const polygon = this.polygonsById[polygonId];
    return srcs.some(src => {
      const [effectiveSrc, effectiveDest] = backwards
        ? [dest, src]
        : [src, dest];
      return (
        this.localNav.findRoute(polygon, effectiveSrc, effectiveDest) != null
      );
    });
  }

  findRoute(pointSpecs: RoutePointSpec[]): RouteResult {
    if (pointSpecs.length == 0) {
      return { distance: 0, waypoints: [], unreachablePoints: [] };
    }

    let currentPolygonId = pointSpecs[0].polygonId;
    let currentWaypoint = pointSpecs[0].waypoint;

    const waypoints = [currentWaypoint];
    let distance = 0;
    const unreachablePoints = [];

    for (const nextSpec of pointSpecs.slice(1)) {
      const nextPolygonId = nextSpec.polygonId;
      const nextWaypoint = nextSpec.waypoint;

      const nextPolygon = this.polygonsById[nextPolygonId];

      if (currentPolygonId == nextPolygonId) {
        const directRoute = this.localNav.findRoute(
          nextPolygon,
          currentWaypoint.position,
          nextWaypoint.position,
          nextSpec.settings,
        );
        if (directRoute) {
          currentWaypoint = nextWaypoint;
          distance += directRoute.distance;
          waypoints.push(...directRoute.waypoints);
          waypoints.push(nextWaypoint);
          continue;
        }
      }

      const route = this.portalGraph.findRoute(
        currentPolygonId,
        currentWaypoint,
        nextPolygonId,
        nextWaypoint,
      );

      if (route) {
        currentPolygonId = nextPolygonId;
        currentWaypoint = nextWaypoint;
        distance += route.distance;
        waypoints.push(...route.waypoints);
        waypoints.push(nextWaypoint);
      } else {
        unreachablePoints.push(nextSpec);
      }
    }

    return {
      distance,
      waypoints,
      unreachablePoints,
    };
  }
}
