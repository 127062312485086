import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../components/layout/ContainerFlex';
import { IconArrowDirectional } from '../components/nav/IconArrowDirectional';
import { HeaderPublic } from './HeaderPublic';

export type CompProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Registration = ({ children, className }: CompProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app-public');

  return (
    <Container col>
      <HeaderPublic
        hasIconBefore={<IconArrowDirectional direction="Back" />}
        className="cursor-pointer"
        url={'/login'}
        message={t`Back to Login`}
      ></HeaderPublic>
      <section
        data-component="Intro"
        id={id}
        className={classNames(
          'bg-brand text-menu-active-text',
          'p-10 xl:p-20',
          'flex flex-1 flex-col items-start justify-center',
          className,
        )}
      >
        <div
          className={classNames('mb-4', 'text-xl xl:text-4xl')}
        >{t`What is WareBee?`}</div>
        <div
          className={classNames('text-2xl sm:text-4xl md:text-5xl xl:text-6xl')}
        >
          {t`WareBee™ is a digital`}{' '}
          <span className="text-opacity-60">{t`(twin)`}</span>{' '}
          {t`model of the warehouse helping you to make data-driven decisions that
        save costs.`}
        </div>

        <div
          className={classNames(
            'text-2xl xl:text-4xl',
            'mt-6',
            'flex flex-col lg:flex-row lg:items-center',
          )}
        >
          {/* <span>{t`Interested?`}</span> */}
          <a
            className={classNames(
              'bg-app-background hover:bg-app-background/80',
              'text-menu-active',
              'text-xl xl:text-4xl lg:mx-2 mt-4 lg:mt-0',
              'rounded flex-inline flex items-center',
              'p-1 px-2 xl:p-2 xl:px-4',
            )}
            href={`https://warebee.com/getting-started/?source=app-registration?feature=getting-started`}
          >
            <span
              className={classNames('ltr:mr-4 rtl:ml-4')}
            >{t`Let's Get Started`}</span>
            <IconArrowDirectional direction="Forward" />
          </a>
        </div>
      </section>
    </Container>
  );
};
