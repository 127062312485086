import { useApolloClient } from '@apollo/client';
import {
  CreateSimulationDocument,
  CreateSimulationMutation,
  CreateSimulationMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { Container } from '../../components/layout/ContainerFlex';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import {
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import PanelSimulationMetaInfo from '../panels/PanelSimulationMetaInfo';
import { simulationCurrent } from '../store/simulation.state';

const SimulationStartSidebarCmp: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [simulation, setSimulation] = useRecoilState(simulationCurrent);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const selectStep = useSelectSimulationWizardStep();
  const updateSim = useUpdateSimulation();

  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();

  const changeTitle = (title: string) => {
    setSimulation({ ...simulation, title: title });
    if (simulation.id !== 'new') {
      updateSim({
        title,
      });
    }
  };

  const changeDescription = (description: string) => {
    setSimulation({ ...simulation, description: description });
    if (simulation.id !== 'new') {
      updateSim({
        description,
      });
    }
  };

  const saveSimulation = useRecoilCallback(({ snapshot, set }) => async () => {
    try {
      const simulation = await snapshot.getPromise(simulationCurrent);

      selectStep({ selectNext: true });
      if (simulation.id === 'new') {
        const simulationResponse = await client.mutate<
          CreateSimulationMutation,
          CreateSimulationMutationVariables
        >({
          mutation: CreateSimulationDocument,
          variables: {
            input: {
              warehouseId,
              title: simulation.title,
              description: simulation.description,
            },
          },
        });
        if (_.isEmpty(simulationResponse.errors)) {
          const sim = simulationResponse.data.createSimulation;
          navigate(location.pathname.replace('/new', `/${sim.id}`));
        }
      } else {
        //sim exists, update name and title
        await updateSim({
          title: simulation.title,
          description: simulation.description,
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  });

  if (!simulation) {
    return (
      <LoadingIndicator
        transparent
        selfCenter
        message={t`Loading Simulation...`}
      />
    );
  }

  return (
    <Container col hasOverflowY hasExtraPadding>
      <PanelSimulationMetaInfo
        title={simulation.title}
        description={simulation.description}
        onTitleChange={changeTitle}
        onDescriptionChange={changeDescription}
        isDisabled={!canUpdate}
      />
      <ActionBar sticky stickyBottom>
        <Spacer flexspace />
        <Button
          label={t`Select Dataset`}
          buttonType="primary"
          hasIconAfter
          buttonIcon={<IconArrowDir />}
          onPress={saveSimulation}
          isDisabled={_.isEmpty(simulation.title?.trim())}
        />
      </ActionBar>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-getting-started',
    title: t`Getting Started`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationStartSidebarCmp />
    </SidebarContainer>
  );
};
