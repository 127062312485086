export const callout = {
  render: 'Callout',
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    type: {
      type: String,
      default: 'tip',
      matches: [
        'tip',
        'suggestion',
        'caution',
        'check',
        'note',
        'warning',
        'action',
      ],
    },
    title: {
      type: String,
    },
    iconType: {
      type: String,
    },
  },
};
