import { StackingPolicyRuleSubcategoriesFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDefaultMeasure, getMeasureTitle } from '../common/measureHelper';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import { StepListItemHelper } from '../components/helpers/StepListItemHelper';
import * as Icon from '../components/icons';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import { getStackingPolicySubcategoryTypeDescriptors } from '../simulation/store/stackingPolicy/stackingPolicy.helper';
import { stackingPolicySelectedIdentity } from '../simulation/store/stackingPolicy/stackingPolicy.state';
import { warehouseMeasurementSystem } from '../store/warehouse.state';

export type StackingPolicySubcategoriesSummaryProps = {
  ruleId: string;
  subcategories: StackingPolicyRuleSubcategoriesFragment;
};

const StackingPolicySubcategoriesSummary: React.FC<
  StackingPolicySubcategoriesSummaryProps
> = props => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('simulation');
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    stackingPolicySelectedIdentity,
  );
  const measurementSystem = useRecoilValue(warehouseMeasurementSystem);
  const canUpdate = useRecoilValue(simulationIsEditable);

  const weightUnits = getMeasureTitle(
    getDefaultMeasure(measurementSystem, 'weight'),
    tMeasures,
  );

  const isSelected =
    selectedIdentity?.ruleId === props.ruleId &&
    selectedIdentity?.isSubcategoriesFilterSelected;

  const typeDescriptors = getStackingPolicySubcategoryTypeDescriptors(t);
  const boundaryValue = `0 ... ${_.join(
    props.subcategories?.boundaries,
    ' ... ',
  )} ... max `;

  return (
    <RuleSectionContainer
      key={`RuleSectionContainer-Subcategories-${props.ruleId}`}
      isSelected={isSelected}
      className={classNames('mt-0', {
        'bg-menu-active text-menu-active-text shadow-2xl': isSelected,
        // 'bg-app-panel-dark': !isSelected,
        'p-2': !canUpdate && props.subcategories,
        '!p-0': !canUpdate,
      })}
      transparent
      onClick={() => {
        setSelectedIdentity(
          isSelected
            ? null
            : {
                ruleId: props.ruleId,
                isSubcategoriesFilterSelected: true,
              },
        );
      }}
    >
      {!canUpdate && props.subcategories && (
        <StepListItemHelper
          // stepCountId={t`B`}
          title={
            <div className="flex flex-col xl:flex-row items-baseline uppercase text-xs">
              <div>{t`Weight Categories:`}</div>
            </div>
          }
          isPrimary
          isActive={isSelected}
          stepCountSize="policy"
          className={classNames(
            'text-md ltr:pl-2 rtl:pr-2 py-1 xl:pt-2 items-center',
          )}
        />
      )}

      {canUpdate && _.isNil(props.subcategories) && (
        <InboxZero
          message={t`No Weight Categories`}
          selfCenter
          classNameInner="p-1"
        >
          <Button
            label={t`Click to setup`}
            buttonType="primary"
            buttonSize="xs"
            className="rounded flex-1 w-full mt-4 text-xs text-menu-active"
            hasIconAfter
            buttonIcon={
              <Icon.ArrowRight className={`w-4 h-4 mx-1 fill-current`} />
            }
            onPress={() => {
              setSelectedIdentity({
                ruleId: props.ruleId,
                isSubcategoriesFilterSelected: !isSelected,
              });
            }}
          />
        </InboxZero>
      )}

      {props.subcategories?.type && (
        <PolicyFilterItem
          name={t`By`}
          value={typeDescriptors[props.subcategories?.type]?.title}
          isActive={isSelected}
          isDisabled={!canUpdate}
          isRemovable={false}
        />
      )}
      {props.subcategories?.step && (
        <PolicyFilterItem
          name={t(`Weight Intervals ({{weightUnits}})`, { weightUnits })}
          value={props.subcategories?.step?.toString()}
          isActive={isSelected}
          isDisabled={!canUpdate}
          isRemovable={false}
        />
      )}
      {!_.isEmpty(props.subcategories?.boundaries) && (
        <PolicyFilterItem
          name={t(`Weight Ranges ({{weightUnits}})`, { weightUnits })}
          value={boundaryValue}
          isActive={isSelected}
          isDisabled={!canUpdate}
          isRemovable={false}
        />
      )}
    </RuleSectionContainer>
  );
};
export default StackingPolicySubcategoriesSummary;
