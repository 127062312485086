import Flatten from '@flatten-js/core';
import {
  Point,
  Segment,
  Segment1D,
  Vector,
} from '@warebee/shared/engine-model';
import { equals } from '@warego/class-validator';

export function loadPoint(p: Point): Flatten.Point {
  return Flatten.point(p.x, p.y);
}

export function loadVector(v: Vector): Flatten.Vector {
  return Flatten.vector(v.x, v.y);
}

export function loadSegment(s: Segment): Flatten.Segment {
  return Flatten.segment(loadPoint(s.start), loadPoint(s.end));
}

export function loadPolygon(pts: Point[]): Flatten.Polygon {
  return new Flatten.Polygon(pts.map(pt => Flatten.point(pt.x, pt.y)));
}

export function savePoint({ x, y }: Flatten.Point): Point {
  return { x, y };
}

export function savePolygon(p: Flatten.Polygon): Point[] {
  return p.vertices.map(({ x, y }) => ({ x, y }));
}

export function fromGeojsonPoint(pt: GeoJSON.Point): Flatten.Point {
  return Flatten.point(pt.coordinates as [number, number]);
}

export function toGeojsonPoint({ x, y }: Flatten.Point): GeoJSON.Point {
  return {
    type: 'Point',
    coordinates: [x, y],
  };
}

export function fromGeojsonPolygon(p: GeoJSON.Polygon): Flatten.Polygon {
  return new Flatten.Polygon(p.coordinates as Flatten.MultiLoopOfShapes);
}

export function toGeojsonPolygon(p: Flatten.Polygon): GeoJSON.Polygon {
  return pointsToGeojsonPolygon(p.vertices);
}

export function pointsToGeojsonPolygon(pts: Point[]): GeoJSON.Polygon {
  const coordinates = pts.map(({ x, y }) => [x, y]);
  if (!equals(coordinates[0], coordinates[coordinates.length - 1])) {
    coordinates.push(coordinates[0]);
  }
  return {
    type: 'Polygon',
    coordinates: [coordinates],
  };
}

export function toGeojsonBBox(bbox: Flatten.Box): GeoJSON.BBox {
  return [bbox.xmax, bbox.ymax, bbox.xmin, bbox.ymin];
}

export interface SharedSegmentInfo {
  segment: Flatten.Segment;
  projection1: Segment1D;
  projection2: Segment1D;
}

/**
 * Finds shared part of two segments, if any.
 * Segments should be collinear and overlapping in order to have shared part.
 * @param s1
 * @param s2
 * @returns shared subsegment and its projection to both segments, or null if not found.
 */
export function findSharedSegment(
  s1: Flatten.Segment,
  s2: Flatten.Segment,
): SharedSegmentInfo | null {
  const l1 = new Flatten.Line(s1.start, s1.end);
  if (!l1.contains(s2.start) || !l1.contains(s2.end)) {
    return null;
  }

  const coords = [s1.start, s1.end, s2.start, s2.end].map(p => l1.coord(p));

  const [c1s, c1e, c2s, c2e] = coords;
  const c1ds = Math.min(c1s, c1e);
  const c1de = Math.max(c1s, c1e);
  const c2ds = Math.min(c2s, c2e);
  const c2de = Math.max(c2s, c2e);

  const overlapStart = Math.max(c1ds, c2ds);
  const overlapEnd = Math.min(c1de, c2de);

  if (overlapStart >= overlapEnd) {
    return null;
  } else {
    const projection1: Segment1D =
      c1s < c1e
        ? { start: overlapStart - c1s, end: overlapEnd - c1s }
        : { start: c1s - overlapEnd, end: c1s - overlapStart };
    const projection2: Segment1D =
      c2s < c2e
        ? { start: overlapStart - c2s, end: overlapEnd - c2s }
        : { start: c2s - overlapEnd, end: c2s - overlapStart };
    return {
      segment: Flatten.segment(
        s1.start.translate(s1.tangentInStart().multiply(projection1.start)),
        s1.start.translate(s1.tangentInStart().multiply(projection1.end)),
      ),
      projection1,
      projection2,
    };
  }
}

export function polygon(pts: [number, number][]): Flatten.Polygon {
  return new Flatten.Polygon(pts.map(pt => Flatten.point(pt)));
}

export function rect([x, y]: [number, number], [w, h]: [number, number]) {
  // CW orientation
  return polygon([
    [x, y],
    [x, y + h],
    [x + w, y + h],
    [x + w, y],
  ]);
}

export function isSimple(p: Flatten.Polygon): boolean {
  return p.faces.size === 1 && p.isValid();
}

export function getOnlyFace(p: Flatten.Polygon): Flatten.Face {
  if (p.faces.size !== 1) {
    throw new Error('expected exactly 1 face in polygon, got ' + p.faces.size);
  }
  return p.faces.values().next().value;
}

/**
 * Find vertices with reflex internal angle in concave polygon, if any.
 * @param p
 * @returns
 */
export function findConcaveAngles(p: Flatten.Polygon): Flatten.Point[] {
  if (!isSimple(p)) {
    throw new Error('not a simple polygon');
  }

  const face = getOnlyFace(p);

  const orientation = face.orientation();
  if (orientation === Flatten.ORIENTATION.NOT_ORIENTABLE) {
    return [];
  }

  const isConcaveAngle: (v: number) => boolean =
    orientation === Flatten.ORIENTATION.CCW
      ? crossProduct => crossProduct < 0
      : crossPrduct => crossPrduct > 0;

  return face.edges
    .map((e, i) => {
      const v1 = e.shape.tangentInStart();
      const v2 = face.edges[(i + 1) % face.edges.length].shape.tangentInStart();
      return isConcaveAngle(v1.cross(v2)) ? e.shape.end : null;
    })
    .filter(p => p);
}

export function mergeBoxes(boxes: Flatten.Box[]): Flatten.Box {
  if (boxes.length === 0) {
    return new Flatten.Box(0, 0, 0, 0);
  }
  let { xmax, xmin, ymax, ymin } = boxes[0];

  boxes.forEach(b => {
    xmax = Math.max(xmax, b.xmax);
    xmin = Math.min(xmin, b.xmin);
    ymax = Math.max(ymax, b.ymax);
    ymin = Math.min(ymin, b.ymin);
  });

  return new Flatten.Box(xmin, ymin, xmax, ymax);
}

export function padBox(box: Flatten.Box, ratio: number) {
  const { xmin, xmax, ymin, ymax } = box;
  const w = xmax - xmin;
  const h = ymax - ymin;
  const dx = (w * ratio) / 2;
  const dy = (h * ratio) / 2;
  return new Flatten.Box(xmin - dx, ymin - dy, xmax + dx, ymax + dy);
}
