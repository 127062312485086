import * as React from 'react';
const ProjectAddTwinIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ProjectAddTwinIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.16 81.82 33.36 97.51 33.36 66.12 6.16 50.41 6.16 81.82"
    />
    <polygon
      strokeWidth={0}
      points="34 33.51 6.88 49.18 34 64.83 61.12 49.17 34 33.51"
    />
    <polygon
      strokeWidth={0}
      points="34.71 66.13 34.71 97.46 61.84 81.8 61.84 50.47 34.71 66.13"
    />
    <path
      strokeWidth={0}
      d="M64,50.67v32l27.71,16,27.71-16v-32l-27.71-16-27.71,16ZM93.92,53.39v11.03h11.59c1.22,0,2.21.99,2.21,2.21s-.99,2.21-2.21,2.21h-11.59v12.14c0,1.22-.99,2.21-2.21,2.21s-2.21-.99-2.21-2.21v-12.14h-11.59c-1.22,0-2.21-.99-2.21-2.21s.99-2.21,2.21-2.21h11.59v-11.03c0-1.22.99-2.21,2.21-2.21s2.21.99,2.21,2.21Z"
    />
  </svg>
);
export default ProjectAddTwinIcon;
