import classNames from 'classnames';
import React from 'react';
import {
  getBestContrastColor,
  manipulateColor,
} from '../../common/color.helper';
import { cn } from '../../common/utils';

type ChartTooltipProps = {
  color: [string, string];
  label?: string | number;
  title: string;
  subTitle?: string;
  value: string;
};

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  color,
  label,
  title,
  subTitle,
  value,
}) => {
  const backgroundColor = color[0];
  const textColor = getBestContrastColor(backgroundColor);

  const backgroundColorDarker = manipulateColor(color[0], {
    darkness: 2,
  });
  const textColorDarker = getBestContrastColor(backgroundColorDarker);

  return (
    <div data-component="ChartTooltip">
      {subTitle && (
        <div
          className={classNames(
            'p-2',
            'text-sm',
            'backdrop-filter backdrop-blur',
          )}
          style={{
            backgroundColor: backgroundColorDarker,
            color: textColorDarker,
          }}
        >
          <div className={classNames('text-sm')}>
            <span>{subTitle}</span>
          </div>
        </div>
      )}
      <div
        className={cn('p-2')}
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <span>{title}</span>
        {label && (
          <span
            className={classNames(
              'p-1',
              'text-xs',
              'rounded',
              'ltr:ml-2 rtl:mr-2',
              'bg-menu-disabled/60 text-menu-text',
            )}
          >
            {label}
          </span>
        )}
      </div>
      <div
        className={cn(
          'p-2',
          'bg-app-panel-dark/60 text-menu-text',
          'backdrop-filter backdrop-blur',
        )}
      >
        {value}
      </div>
    </div>
  );
};

export default ChartTooltip;
