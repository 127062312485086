import * as React from 'react';
const ArrowBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowBottomIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M15.73,75.77v-36.37s35.39,35.36,35.39,35.36V4s25.75,0,25.75,0v70.76s35.4-35.37,35.4-35.37v36.37s-48.27,48.23-48.27,48.23L15.73,75.77Z"
    />
  </svg>
);
export default ArrowBottomIcon;
