import * as React from 'react';
const DataOrdersIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataOrdersIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="67.33 87.09 80.22 101.51 123.11 76.75 110.22 62.33 67.33 87.09"
    />
    <polygon
      strokeWidth={0}
      points="121.84 61.34 99.04 47.28 99.04 51.9 110.43 58.93 121.84 61.34"
    />
    <polygon
      strokeWidth={0}
      points="60.67 87.09 17.78 62.33 4.89 76.75 47.78 101.51 60.67 87.09"
    />
    <polygon
      strokeWidth={0}
      points="28.96 51.9 28.96 47.28 6.17 61.34 17.57 58.93 28.96 51.9"
    />
    <polygon
      strokeWidth={0}
      points="108.29 60.16 99.04 54.46 99.04 65.52 108.29 60.16 108.29 60.16"
    />
    <polygon
      strokeWidth={0}
      points="80.01 104.92 65.51 88.69 65.51 126 108.3 101.3 108.31 101.31 108.31 101.29 108.4 101.24 108.37 88.55 80.01 104.92"
    />
    <polygon
      strokeWidth={0}
      points="28.96 65.52 28.96 54.46 19.71 60.16 19.71 60.16 28.96 65.52"
    />
    <polygon
      strokeWidth={0}
      points="45.84 103.68 19.63 88.55 19.6 101.24 19.69 101.29 19.69 101.31 19.71 101.3 62.49 126 62.49 88.69 47.99 104.92 45.84 103.68"
    />
    <path
      strokeWidth={0}
      d="M46.26,18.42h34.62c1.44,0,2.62-1.18,2.62-2.62v-4.56c0-1.47-1.18-2.62-2.62-2.62h-10.69c0-3.67-2.96-6.63-6.62-6.63s-6.63,2.96-6.63,6.63h-10.69c-1.44,0-2.62,1.15-2.62,2.62v4.56c0,1.44,1.18,2.62,2.62,2.62ZM63.58,6.01c1.44,0,2.62,1.15,2.62,2.62s-1.18,2.62-2.62,2.62-2.62-1.18-2.62-2.62,1.18-2.62,2.62-2.62Z"
    />
    <path
      strokeWidth={0}
      d="M50.19,52.26l-4.21,4.23-1.53-1.53c-.77-.77-2.01-.77-2.78,0-.77.77-.77,2.01,0,2.78l2.93,2.94c.37.4.87.58,1.38.58.5,0,1.01-.21,1.38-.58l5.58-5.61c.77-.74.79-2.04.03-2.8-.77-.77-2.01-.77-2.78,0Z"
    />
    <path
      strokeWidth={0}
      d="M90.54,11.12h-4.01v4.06c0,3.11-2.53,5.64-5.64,5.64h-34.62c-3.11,0-5.64-2.53-5.64-5.64v-4.06h-3.17c-4.52,0-5.47,2.98-5.47,5.48v50.32l32.01,18.52,32.01-18.52V16.61c0-2.5-.95-5.48-5.47-5.48ZM47.3,64.21c-4.26,0-7.72-3.47-7.72-7.73,0-4.26,3.47-7.73,7.73-7.73,4.26,0,7.72,3.47,7.72,7.73,0,4.26-3.47,7.73-7.73,7.73ZM50.19,30.19l-4.21,4.23-1.53-1.53c-.77-.77-2.01-.77-2.78,0-.77.77-.77,2.01,0,2.78l2.93,2.94c.37.4.87.58,1.38.58.5,0,1.01-.21,1.38-.58l5.58-5.61c.77-.74.79-2.04.03-2.8-.77-.77-2.01-.77-2.78,0ZM47.3,42.14c-4.26,0-7.72-3.47-7.72-7.73,0-4.26,3.47-7.73,7.73-7.73,4.26,0,7.72,3.47,7.72,7.73,0,4.26-3.47,7.73-7.73,7.73ZM67,28.39h14.99v3.36h-14.99v-3.36ZM67,50.69h14.99v3.36h-14.99v-3.36ZM91.28,61.11h-24.27v-3.36h24.27v3.36ZM91.28,38.81h-24.27v-3.36h24.27v3.36Z"
    />
  </svg>
);
export default DataOrdersIcon;
