import * as React from 'react';
const SimulationWorkforceIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationWorkforceIcon"
    {...props}
  >
    <circle
      cx={55.75}
      cy={11.89}
      r={9.86}
      strokeWidth={0}
      opacity={0.5}
      transform="translate(15.04 51.78) rotate(-56.46)"
    />
    <circle
      cx={96.99}
      cy={13.54}
      r={10.71}
      strokeWidth={0}
      transform="translate(7.68 53.71) rotate(-32.17)"
    />
    <circle
      cx={28.44}
      cy={11.89}
      r={9.86}
      strokeWidth={0}
      opacity={0.85}
      transform="translate(2.87 29.1) rotate(-56.63)"
    />
    <path
      strokeWidth={0}
      d="M8.85,69.14c2.11,0,3.82-1.71,3.82-3.82v-22.29c0-.81.65-1.46,1.46-1.46s1.46.66,1.46,1.46v22.29c0,.21-.01.41-.03.62h.03s0,54.41,0,54.41c.04,3.14,2.62,5.65,5.76,5.61,3.08-.04,5.57-2.53,5.61-5.61v-42.34c0-.81.65-1.46,1.46-1.46s1.46.66,1.46,1.46v42.34c0,3.14,2.55,5.69,5.69,5.69,2.02,0,3.89-1.07,4.91-2.81-.33-.92-.5-1.89-.5-2.87v-49.47c-3.07,2.12-7.27,1.35-9.38-1.72-.78-1.13-1.2-2.47-1.19-3.84v-24.9c0-5.77,2.89-11.17,7.69-14.37h-17.7c-7.93,0-14.36,6.44-14.37,14.37v24.9c0,2.11,1.71,3.82,3.83,3.82Z"
      opacity={0.85}
    />
    <path
      strokeWidth={0}
      d="M106.8,51.61s-4.93-10.51-7.88-17.16c-2.78-6.26-4.77-7.89-8.72-7.89h-6.09c-3.19,0-4.36,1.17-6.31,2.27l-16.47,14.01c-1.44.83-2.36,2.07-2.92,3.82l-7.06,14.41c-.51,3.78,1.49,5.39,3.75,5.85,1.83.39,4.58-.54,5.14-3.19.44-2.19,7.31-12.29,7.31-12.29l10.74-8.26c-1.78,8.24-3.58,17.25-4.17,20.32-1.02,5.24,1.58,9.21,3.65,12.33,1.88,2.83,26.19,41.15,29.38,45.31,3.21,4.17,6.14,6.24,10.6,3.75,3.75-2.1,3.12-6.87,1.05-10.4-2.07-3.53-26.6-43.43-26.6-43.43l4.12-17.78s2.85,4.07,4.31,6.36c.71,1.07,9.89,7.96,12.94,10.05,4.89,3.35,10.79-3.77,5.51-7.58l-12.27-10.48Z"
    />
    <path
      strokeWidth={0}
      d="M77.23,80.32l-4.76,16.77s-9.94,14.37-12.69,18.38c-2.07,3.03-3.64,6.72-.09,9.4,3.99,3.01,7.18-.91,9.24-3.24,1.89-2.12,9.59-12.87,11.75-15.6,1.45-1.84,2.24-3.38,2.87-4.92.4-1.03,1.96-5.27,2.96-8.02l-9.28-12.76Z"
    />
    <path
      strokeWidth={0}
      d="M55.75,76.54c.81,0,1.46.65,1.46,1.46v35.72s0-.01.01-.02c2.13-3.11,8.56-12.41,11.36-16.46v-31.32c0-.07,0-.14.02-.21,0-.14-.02-.27-.02-.41v-9.87c-2.55,3.87-5.01,7.92-5.33,8.96-.82,3.89-4.37,5.69-7.32,5.69-.51,0-1.01-.05-1.49-.15-3.49-.71-6.96-3.53-6.18-9.3l.07-.5,7.19-14.67c.78-2.31,2.09-4.01,3.99-5.18l13.6-11.57c-2.35-1.68-5.22-2.68-8.33-2.69h-18.07c-7.94,0-14.36,6.44-14.37,14.37v24.9c.04,2.11,1.77,3.8,3.89,3.76,2.06-.03,3.73-1.7,3.76-3.76v-22.29c0-.81.65-1.46,1.46-1.46s1.46.66,1.46,1.46v22.29c0,.14-.01.27-.02.41.01.07.02.14.02.21v54.41c.04,3.14,2.62,5.65,5.76,5.61,3.08-.04,5.57-2.53,5.61-5.61v-42.34c0-.81.65-1.46,1.46-1.46Z"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M77.23,80.32l-4.76,16.77s-9.94,14.37-12.69,18.38c-2.07,3.03-3.64,6.72-.09,9.4,3.99,3.01,7.18-.91,9.24-3.24,1.89-2.12,9.59-12.87,11.75-15.6,1.45-1.84,2.24-3.38,2.87-4.92.4-1.03,1.96-5.27,2.96-8.02l-9.28-12.76Z"
    />
  </svg>
);
export default SimulationWorkforceIcon;
