import React, { Ref, useEffect } from 'react';

/**
 * Uses forwarded ref passed by React.forwardRef(), if present, or uses ref generated by React.useRef() otherwise.
 *
 * Workaround for https://github.com/adobe/react-spectrum/issues/834
 * Based on https://gist.github.com/pie6k/b4717f392d773a71f67e110b42927fea
 * @param forwardedRef
 */
export default function useSharedRef<T>(forwardedRef: Ref<T>) {
  // final ref that will share value with forward ref. this is the one we will attach to components
  const innerRef = React.useRef<T>(null);

  useEffect(() => {
    // after every render - try to share current ref value with forwarded ref
    if (!forwardedRef) {
      return;
    }
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
    } else {
      // by default forwardedRef.current is readonly. Let's ignore it
      (forwardedRef as any).current = innerRef.current;
    }
  });

  return innerRef;
}
