import * as React from 'react';
const HeatMap9Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HeatMap9Icon"
    {...props}
  >
    <rect width={38} height={38} x={4} y={4} strokeWidth={0} opacity={0.4} />
    <rect width={38} height={38} x={45} y={4} strokeWidth={0} opacity={0.6} />
    <rect width={38} height={38} x={86} y={4} strokeWidth={0} opacity={0.8} />
    <rect width={38} height={38} x={4} y={45} strokeWidth={0} />
    <rect width={38} height={38} x={45} y={45} strokeWidth={0} opacity={0.7} />
    <rect width={38} height={38} x={86} y={45} strokeWidth={0} opacity={0.9} />
    <rect width={38} height={38} x={4} y={86} strokeWidth={0} opacity={0.9} />
    <rect width={38} height={38} x={45} y={86} strokeWidth={0} opacity={0.8} />
    <rect width={38} height={38} x={86} y={86} strokeWidth={0} opacity={0.5} />
  </svg>
);
export default HeatMap9Icon;
