import * as React from 'react';
const PlusIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PlusIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="114 59.24 68.76 59.24 68.76 14 59.24 14 59.24 59.24 14 59.24 14 68.76 59.24 68.76 59.24 114 68.76 114 68.76 68.76 114 68.76 114 59.24"
    />
  </svg>
);
export default PlusIcon;
