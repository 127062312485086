import { atom, selector } from 'recoil';
import { sidebarStateByType } from '../store/sidebar.state';
import { HelpIndex } from './help.default';

const getKey = (postfix: string) => `warebee-help-${postfix}`;

export const helpSelectedIdAtom = atom<HelpIndex>({
  key: getKey('current-id-atom'),
  default: 'index',
});

export const helpSelectedId = selector<HelpIndex>({
  key: getKey('current-id'),
  get: ({ get }) => get(helpSelectedIdAtom),
  set: ({ get, set }, value) => {
    if (value) {
      const current = get(helpSelectedIdAtom);
      const helpSidebar = get(sidebarStateByType('sidebar-global-help'));

      if (!helpSidebar.isPinned) {
        set(sidebarStateByType('sidebar-global-help'), {
          ...helpSidebar,
          isCollapsed:
            current === value && !helpSidebar.isCollapsed ? true : false,
        });
      }

      set(helpSelectedIdAtom, value);
    }
  },
});
