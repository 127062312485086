import React from 'react';
import { useTranslation } from 'react-i18next';
import InboxZero from '../../components/InboxZero';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';

const SidebarNotifications: React.FC = props => {
  const { t } = useTranslation('app');

  return (
    <ContainerScroll className="flex flex-col">
      <InboxZero selfCenter message={t`No Notifications found`} />
      {/* <PanelContainer
        id="panel-help-getting-started"
        title={t`Notifications`}
        collapsible
        hasPadding
      >
        {t`Notifications List...`}
      </PanelContainer> */}
    </ContainerScroll>
  );
};

export default () => {
  const { t } = useTranslation('app');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-global-notifications',
    title: t`Notifications`,
    size: 'md',
  };

  return (
    <SidebarContainer {...containerProps} sideRight isGlobal>
      <SidebarNotifications />
    </SidebarContainer>
  );
};
