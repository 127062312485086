import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  formatInteger,
  formatShortenedTimespan,
  formatToPrecision,
} from '../../common/formatHelper';
import { Formatter } from '../../common/useFormatter';
import { FeedAgentStatsField } from '../store/feed.types';

export type FeedAgentStatsCategory = 'kpi' | 'activity' | 'analyze';

type FeedAgentsStatsDesc = {
  id: FeedAgentStatsField;
  title: string;
  category: FeedAgentStatsCategory;
  getFormatted: (v: number) => string;
  unitOfMeasure?: string;
};

export function getFeedAgentsStatsDesc(
  t: TFunction<'feed'>,
  formatter: Formatter,
): FeedAgentsStatsDesc[] {
  const statsDesc: FeedAgentsStatsDesc[] = [
    {
      id: 'picklistCount',
      title: t('Picklists', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatInteger,
    },
    {
      id: 'totalLines',
      title: t('Lines Count', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatInteger,
    },
    {
      id: 'totalUoms',
      title: t('UOM Count', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatInteger,
    },
    {
      id: 'netLinesPerformance',
      title: t('Pick Rate (Net)', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatToPrecision,
      unitOfMeasure: t('picks/hr', { ns: 'feed' }),
    },
    {
      id: 'grossLinePerformance',
      title: t('Pick Rate (Gross)', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatToPrecision,
      unitOfMeasure: t('picks/hr', { ns: 'feed' }),
    },
    {
      id: 'netUomPerformance',
      title: t('UOM Rate (Net)', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatToPrecision,
      unitOfMeasure: t('units/hr', { ns: 'feed' }),
    },
    {
      id: 'grossUomPerformance',
      title: t('UOM Rate (Gross)', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: formatToPrecision,
      unitOfMeasure: t('units/hr', { ns: 'feed' }),
    },

    {
      id: 'netTime',
      title: t('Net Time', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: v => (_.isNil(v) ? '' : formatShortenedTimespan(v)),
    },
    {
      id: 'grossTime',
      title: t('Gross Time', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: v => (_.isNil(v) ? '' : formatShortenedTimespan(v)),
    },
    {
      id: 'idleTime',
      title: t('Idle Time', { ns: 'feed' }),
      category: 'kpi',
      getFormatted: v => (_.isNil(v) ? '' : formatShortenedTimespan(v)),
    },
    {
      id: 'minEnergy',
      title: t('Energy Indicator', { ns: 'feed' }),
      category: 'activity',
      getFormatted: formatToPrecision,
    },
    {
      id: 'minSignal',
      title: t('Signal Strength', { ns: 'feed' }),
      category: 'activity',
      getFormatted: formatToPrecision,
    },
    {
      id: 'cost',
      title: t('Simulated cost', { ns: 'feed' }),
      category: 'analyze',
      getFormatted: v => formatter.formatCurrency(v).fullString,
    },
    {
      id: 'duration',
      title: t('Simulated duration', { ns: 'feed' }),
      category: 'analyze',
      getFormatted: v => (_.isNil(v) ? '' : formatShortenedTimespan(v * 1000)),
    },
    {
      id: 'distance',
      title: t('Simulated distance', { ns: 'feed' }),
      category: 'analyze',
      getFormatted: v => formatter.formatDistance(v).fullString,
    },
  ];
  return statsDesc;
}
