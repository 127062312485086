import React from 'react';
import { useTranslation } from 'react-i18next';
import SimulationWorkforceView from '../../simulation/stepContentViews/SimulationWorkforceView';

export type AnalyzeDashboardProps = {
  analyzeId: string;
};

const AnalyzeDashboard: React.FC<AnalyzeDashboardProps> = props => {
  const { t } = useTranslation('simulation');

  return (
    <>
      <SimulationWorkforceView analyzeId={props.analyzeId} />
    </>
  );
};

export default AnalyzeDashboard;
