import {
  DeallocateType,
  ReallocateQuantitySource,
  ReallocateType,
  ReallocateUomSource,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import InputNumber from '../../../../components/inputs/InputNumber';
import SectionOptional from '../../../../components/layout/SectionOptional';
import TitleSection from '../../../../components/layout/TitleSection';
import { PolicyCardItem } from '../../../../components/policies/PolicyCardItem';
import {
  getDeallocationTypeOptions,
  getReallocationTypeOptions,
  getReallocationUOMQuantityOptions,
  getReallocationUOMSourceOptions,
} from '../../../store/allocation/allocation.helper';
import {
  deallocationPolicyRule,
  deallocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';
import { simulationUoms } from '../../../store/simulation.state';

const DeallocatePolicySettingsEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(deallocationPolicySelectedIdentity);
  const uoms = useRecoilValue(simulationUoms);
  const [rule, updateRule] = useRecoilState(
    deallocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  const deallocateType = rule?.deallocateType ?? DeallocateType.NONE;
  const reallocateType = rule?.reallocateType ?? ReallocateType.NONE;

  const deallocateOptions = getDeallocationTypeOptions(t);
  const reallocateOptions = getReallocationTypeOptions(t);
  const reallocateUOMOptions = getReallocationUOMSourceOptions(t);
  const reallocateUOMOptionsMap = _.keyBy(reallocateUOMOptions, v => v.type);
  const reallocateUOMValue =
    rule.reallocateQuantitySettings?.reallocateUom ??
    ReallocateUomSource.CAPACITY_UOM;
  const reallocateFixedUOMValue = rule.reallocateQuantitySettings?.fixedUom;
  const reallocateQtyOptions = getReallocationUOMQuantityOptions(t);
  const reallocateQtyOptionsMap = _.keyBy(reallocateQtyOptions, v => v.type);
  const reallocateQtyValue =
    rule.reallocateQuantitySettings?.reallocateQuantity ??
    ReallocateQuantitySource.BY_CAPACITY;

  return (
    <div data-component="DeallocatePolicySettingsEditor">
      {/* <TitleSection
        id={`deallocation-settings-editor`}
        title={t`Re-Allocate Settings`}
        inSidebarView
        hasScreenTitle
      > */}
      <div className="min-h-5">
        <div className={`flex flex-col`}>
          <TitleSection
            id={`policy-allocation-settings-deallocate`}
            title={t`De-allocateMode`}
            inSidebarView
            hasScreenTitle
            isTransparent
            classNameInner="p-2"
          >
            <div className={`relative flex h-full flex-1 flex-col`}>
              {_.map(deallocateOptions, (mode, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-Mode-${mode.id}`}
                    isActive={mode.id === deallocateType}
                    onClick={() =>
                      updateRule({
                        ...rule,
                        deallocateType: mode.id,
                      })
                    }
                    icon={mode.icon}
                    iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
                    title={mode.title}
                  />
                );
              })}
            </div>

            <SectionOptional
              id={'deallocation-reallocate-settings'}
              title={t`Re-allocate Mode`}
              value={rule.reallocateType === ReallocateType.REALLOCATE}
              onChange={enabled =>
                updateRule({
                  ...rule,
                  reallocateType: enabled
                    ? ReallocateType.REALLOCATE
                    : ReallocateType.NONE,
                })
              }
              className="bg-menu rounded"
            >
              <div className="mt-4 space-y-6 p-2">
                <DropdownSelector
                  panelMode
                  widthFull
                  value={reallocateUOMValue}
                  values={_.map(reallocateUOMOptions, v => v.id)}
                  renderValue={v => reallocateUOMOptionsMap[v].title}
                  label={t`Allocate UOM by`}
                  onChange={v =>
                    updateRule({
                      ...rule,
                      reallocateQuantitySettings: {
                        ...rule.reallocateQuantitySettings,
                        reallocateUom: v,
                      },
                    })
                  }
                  panelModeMultiline
                />
                {reallocateUOMValue === ReallocateUomSource.FIXED_UOM && (
                  <DropdownSelector
                    panelMode
                    widthFull
                    value={reallocateFixedUOMValue}
                    values={[...uoms]}
                    renderValue={v => v || t`-- Select UOM`}
                    label={t`Allocate UOM`}
                    onChange={v =>
                      updateRule({
                        ...rule,
                        reallocateQuantitySettings: {
                          ...rule.reallocateQuantitySettings,
                          fixedUom: v,
                        },
                      })
                    }
                    panelModeMultiline
                  />
                )}
                <DropdownSelector
                  value={reallocateQtyValue}
                  values={_.map(reallocateQtyOptions, v => v.id)}
                  renderValue={v => reallocateQtyOptionsMap[v].title}
                  label={t`Allocate Quantity by`}
                  onChange={v =>
                    updateRule({
                      ...rule,
                      reallocateQuantitySettings: {
                        ...rule.reallocateQuantitySettings,
                        reallocateQuantity: v,
                      },
                    })
                  }
                  panelMode
                  widthFull
                  panelModeMultiline
                />
                {reallocateQtyValue ===
                  ReallocateQuantitySource.FIXED_QUANTITY && (
                  <InputNumber
                    title={t`UOM Quantity`}
                    value={rule.reallocateQuantitySettings?.fixedUomQuantity}
                    range={[0, 1000]}
                    onChange={v =>
                      updateRule({
                        ...rule,
                        reallocateQuantitySettings: {
                          ...rule.reallocateQuantitySettings,
                          fixedUomQuantity: v,
                        },
                      })
                    }
                  />
                )}
              </div>
            </SectionOptional>
          </TitleSection>
        </div>
      </div>
      {/* </TitleSection> */}
    </div>
  );
};

export default DeallocatePolicySettingsEditor;
