import ProcessTypeFilters from './ProcessTypeFilters';
import ResourceFilters from './ResourceFilters';

const FeedGlobalFilterPanel: React.FC = () => {
  return (
    <>
      {/* <ResourceFilters />
      <ProcessTypeFilters /> */}
    </>
  );
};

export default FeedGlobalFilterPanel;
