import { IsEmail, IsNotEmpty, ValidateBy } from '@warego/class-validator';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { analyticsAgent } from '../AnalyticTracker';
import FormError from '../components/FormError';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import InputText, {
  InputEmail,
  InputPassword,
} from '../components/inputs/InputText';
import { Container, ContainerCol } from '../components/layout/ContainerFlex';
import { ContainerPublic } from '../components/layout/ContainerPublic';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { LogoHeader } from '../components/layout/LogoHeader';
import { Spacer } from '../components/layout/Spacer';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import i18n from '../i18n';
import useValidatedForm from '../utils/use-validated-form';
import { useErrorTranslator } from './ErrorTranslator';
import { FooterPublic } from './FooterPublic';
import { HeaderPublic } from './HeaderPublic';
import { IntroHelp } from './Intro';

class PasswordRecoveryStartForm {
  @IsNotEmpty({
    message: ({ t }) => t('E-mail is required', { ns: 'app-public' }),
  })
  @IsEmail(
    {},
    {
      message: ({ t }) => t('E-mail address is invalid', { ns: 'app-public' }),
    },
  )
  email: string;
}

class PasswordRecoveryVerificationForm {
  @IsNotEmpty({ message: ({ t }) => t('Verification code is required') })
  @ValidateBy({
    name: 'noSpaces',
    validator: {
      validate: (value, args) => {
        const reg = /^[\S]+$/g;
        return reg.test(value?.trim());
      },
      defaultMessage: ({ t }) =>
        t('Verification code should not contain whitespaces', {
          ns: 'app-public',
        }),
    },
  })
  code: string;

  @IsNotEmpty({
    message: ({ t }) => t('Password is required', { ns: 'app-public' }),
  })
  password: string;

  @ValidateBy({
    name: 'passwordMatch',
    validator: {
      validate: (value, args) => value === (args?.object as any).password,
      defaultMessage: ({ t }) =>
        t(
          'Mismatched passwords. Please enter the same password in both fields',
          { ns: 'app-public' },
        ),
    },
  })
  confirmPassword: string;
}

interface PasswordRecoveryStartProps {
  onSubmit: (form: PasswordRecoveryStartForm) => Promise<void>;
}

const PasswordRecoveryStart = ({ onSubmit }: PasswordRecoveryStartProps) => {
  const { t } = useTranslation('app-public');
  const { translateError } = useErrorTranslator();

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState, setError } =
    useValidatedForm<PasswordRecoveryStartForm>(PasswordRecoveryStartForm, {
      validatorOptions: {
        extraArguments: {
          t,
        },
      },
    });

  async function startRecovery(form: PasswordRecoveryStartForm) {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    try {
      await onSubmit(form);
    } catch (err) {
      console.log('password recovery error: %o', err);
      const translatedMessage = translateError(err); // Translate the error here

      // If analytics tracking is needed, you can add it here like in the login function
      analyticsAgent?.track('Password Recovery: Error', { error: err.message });

      setError('email', {
        message:
          translatedMessage || t('Error occurred during password recovery'),
      });

      setSubmitting(false);
    }
  }
  const emailFieldProps = register('email');

  return (
    <form
      onSubmit={handleSubmit(startRecovery)}
      className="flex flex-1 flex-col items-center p-2"
    >
      <section className="p-10 pb-1 pt-1 xl:p-20 xl:py-1">
        <p className="text-menu-active text-xl font-bold lg:text-2xl">
          {t`Type the email address linked to your account and we'll send you password reset instructions.`}
        </p>
        {/* <p className="text-base lg:text-xl font-bold text-menu-active">
          {t`They might end up in your spam folder, so please check there as well.`}
        </p> */}
      </section>

      <section className="w-full flex-1 space-y-8 p-10 pb-0 pt-4 xl:p-20 xl:pb-0 xl:pt-4">
        <fieldset>
          <InputEmail
            autoComplete="username"
            isRequired
            placeholder="Email"
            label="Email"
            name="email"
            {..._.omit(emailFieldProps, 'onChange')}
            onChangeValidate={emailFieldProps.onChange}
          />
          <FormError errors={formState.errors} name="email" />
        </fieldset>
      </section>
      <footer className="mb-8 mt-4 flex w-full items-center space-y-2 p-10 xl:p-20 xl:py-0">
        <Spacer flexspace />
        <Link to="/">
          <Button
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isDisabled={submitting || !_.isEmpty(formState.errors)}
            buttonType="primary"
            type="submit"
            label={t`Start Recovery`}
          />
        </Link>
      </footer>
    </form>
  );
};

interface PasswordRecoveryVerificationProps {
  username: string;
  onSubmit: (form: PasswordRecoveryVerificationForm) => Promise<void>;
}

const PasswordRecoveryVerification = ({
  username,
  onSubmit,
}: PasswordRecoveryVerificationProps) => {
  const { t } = useTranslation('app');
  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState, setError } = useValidatedForm(
    PasswordRecoveryVerificationForm,
    {
      validatorOptions: {
        extraArguments: {
          t,
        },
      },
    },
  );

  async function completeRecovery(form: PasswordRecoveryVerificationForm) {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    try {
      await onSubmit({
        ...form,
        code: form?.code?.trim(),
      });
    } catch (err) {
      console.log('password reset error: %o', err);
      setError('code', {
        message: err.message || t('password reset error'),
      });
      setSubmitting(false);
    }
  }

  const codeFieldProps = register('code');
  const pwdFieldProps = register('password');
  const confirmPwdFieldProps = register('confirmPassword');

  const passwordPolicyLength = 10;
  const passwordPolicyLengthHelper = t(
    `at least {{passwordPolicyLength}} characters`,
    { passwordPolicyLength },
  );

  return (
    <form
      className="flex flex-1 flex-col p-2"
      onSubmit={handleSubmit(completeRecovery)}
    >
      <section className="p-10 pb-1 pt-1 xl:p-20 xl:py-1">
        <p className="text-menu-active text-xl font-bold lg:text-2xl">
          {t`Please fill up the verification code you've received in your email`}
        </p>
      </section>

      <section className="w-full space-y-8 p-10 pb-0 pt-4 xl:p-20 xl:pb-0 xl:pt-4">
        <fieldset className="mb-8">
          <InputText
            autoComplete="one-time-code"
            isRequired
            placeholder={t`Enter the code you received`}
            label={t`Verification code`}
            name="code"
            {..._.omit(codeFieldProps, 'onChange')}
            onChangeValidate={codeFieldProps.onChange}
          />
          <FormError errors={formState.errors} name="code" />
        </fieldset>

        <section className="space-y-8">
          <fieldset>
            <InputPassword
              id="new-password"
              autoComplete="new-password"
              placeholder={passwordPolicyLengthHelper}
              label={t`New Password`}
              name="password"
              {..._.omit(pwdFieldProps, 'onChange')}
              onChangeValidate={pwdFieldProps.onChange}
              showPasswordPreview
            />
            <FormError errors={formState.errors} name="password" />
          </fieldset>
          <fieldset>
            <InputPassword
              autoComplete="new-password"
              placeholder={t`Confirm password`}
              label={t`Confirm password`}
              name="confirmPassword"
              {..._.omit(confirmPwdFieldProps, 'onChange')}
              onChangeValidate={confirmPwdFieldProps.onChange}
              showPasswordPreview
            />
            <FormError errors={formState.errors} name="confirmPassword" />
          </fieldset>
        </section>
      </section>
      <section className="mt-6 w-full p-4">
        <div className="flex w-full">
          <Spacer flexspace />
          <Link to="/">
            <Button
              buttonType="primary"
              type="submit"
              label={t`Reset Password`}
              hasIconAfter
              buttonIcon={
                i18n.dir(i18n.language) === 'ltr' ? (
                  <Icon.ArrowRight className={`h-6 w-6 fill-current`} />
                ) : (
                  <Icon.ArrowLeft className={`h-6 w-6 fill-current`} />
                )
              }
              isDisabled={submitting || !_.isEmpty(formState.errors)}
            />
          </Link>
        </div>
      </section>
    </form>
  );
};

export type PasswordRecoveryProps = {
  children?: React.ReactNode;
};

export const PasswordRecovery = ({ children }: PasswordRecoveryProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const [username, setUsername] = useState(null);

  const navigate = useNavigate();

  async function startRecovery(form: PasswordRecoveryStartForm): Promise<void> {
    await resetPassword({
      username: form.email,
    });
    setUsername(form.email);
  }

  async function completeRecovery(
    form: PasswordRecoveryVerificationForm,
  ): Promise<void> {
    await confirmResetPassword({
      username,
      newPassword: form.password,
      confirmationCode: form.code,
    });

    navigate('/login', {
      state: {
        username,
        message: t`Password Successfully changed, please login`,
      },
    });
  }
  return (
    <ContainerPublic consent dataComponent="PasswordRecovery">
      {/* ——————————————— Right Side ——————————————— */}
      <ContainerCol
        overflow
        fullWidth={false}
        className="bg-menu-app h-full md:w-1/2"
      >
        <ContainerScroll hasOverflowY className="flex flex-col">
          <HeaderPublic
            iconBefore={Icon.CircleArrowLeft}
            url={'/login'}
            message={t`Back to login?`}
          />
          <LogoHeader
            logoFull
            logoSize={'md'}
            className={`mb-4 mt-8 p-10 pb-0 pt-0 xl:py-0`}
          />

          <div className="flex-1">
            {username ? (
              <PasswordRecoveryVerification
                username={username}
                onSubmit={completeRecovery}
              />
            ) : (
              <PasswordRecoveryStart onSubmit={startRecovery} />
            )}
          </div>

          <FooterPublic />
        </ContainerScroll>
      </ContainerCol>

      {/* ——————————————— Left Side ——————————————— */}
      <div className="bg-brand hidden flex-col overflow-hidden md:flex md:w-1/2">
        <Container>
          <IntroHelp />
        </Container>
      </div>
    </ContainerPublic>
  );
};
