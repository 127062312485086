import * as React from 'react';
const LinkTargetFocusIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkTargetFocusIcon"
    {...props}
  >
    <path strokeWidth={0} d="M99.61,11.56l9.06-9.06H19.33l9.06,9.06h71.21Z" />
    <path
      strokeWidth={0}
      d="M28.39,116.44l-9.06,9.06h89.34l-9.06-9.06H28.39Z"
    />
    <path strokeWidth={0} d="M11.56,28.39L2.5,19.33v89.34l9.06-9.06V28.39Z" />
    <path strokeWidth={0} d="M116.44,28.39v71.21l9.06,9.06V19.33l-9.06,9.06Z" />
    <path
      strokeWidth={0}
      d="M90.28,21.27c-2.07-2.07-5.7-1.04-6.34,1.94l-4.27,20.59c-.52,2.72,1.81,5.05,4.4,4.4l20.59-4.27c2.98-.65,4.01-4.27,1.94-6.34l-4.66-4.66,21.62-21.62c1.94-1.94,1.94-4.92,0-6.86-1.94-1.94-4.92-1.94-6.86,0l-21.62,21.62-4.79-4.79Z"
    />
    <path
      strokeWidth={0}
      d="M37.72,106.73c2.07,2.07,5.7,1.04,6.34-1.94l4.27-20.59c.52-2.72-1.81-5.05-4.4-4.4l-20.59,4.27c-2.98.65-4.01,4.27-1.94,6.34l4.79,4.79-21.62,21.49c-1.94,1.94-1.94,4.92,0,6.86,1.94,1.94,4.92,1.94,6.86,0l21.49-21.62,4.79,4.79Z"
    />
    <path
      strokeWidth={0}
      d="M101.94,95.07l4.79-4.79c2.07-2.07,1.04-5.7-1.94-6.34l-20.59-4.27c-2.72-.52-5.05,1.81-4.4,4.4l4.27,20.59c.65,2.98,4.27,4.01,6.34,1.94l4.79-4.79,21.49,21.75c1.94,1.94,4.92,1.94,6.86,0,1.94-1.94,1.94-4.92,0-6.86l-21.62-21.62Z"
    />
    <path
      strokeWidth={0}
      d="M26.06,32.93l-4.79,4.79c-2.07,2.07-1.04,5.7,1.94,6.34l20.59,4.27c2.72.52,5.05-1.81,4.4-4.4l-4.27-20.59c-.65-2.98-4.27-4.01-6.34-1.94l-4.66,4.66L11.3,4.44c-1.81-1.81-4.92-1.81-6.73,0-1.81,1.81-1.94,4.92,0,6.86l21.49,21.62Z"
    />
    <rect
      width={27.81}
      height={27.81}
      x={50.1}
      y={50.1}
      strokeWidth={0}
      rx={4.08}
      ry={4.08}
    />
  </svg>
);
export default LinkTargetFocusIcon;
