import { BarDatum, ComputedBarDatum } from '@nivo/bar';
import classNames from 'classnames';
import { eachDayOfInterval } from 'date-fns';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TwTheme } from '../../../../Tw';
import InboxZero from '../../../components/InboxZero';
import { viewerPlanesTitles } from '../../../layout/viewer/store/viewer.state';
import { getPolicyGroupMap } from '../../store/pickingPolicy/pickingPolicy.helper';
import { pickingPolicyOrderLineRulesTitles } from '../../store/pickingPolicy/pickingPolicy.state';
import { resourcePolicyAgentById } from '../../store/resourcePolicy.state';
import {
  formatMetricValue,
  getDimensionColor,
  getDimensionTitle,
} from '../../store/workforce.helper';
import {
  workforceAgentAggregatedEventsCurrent,
  workforceAgentEventAggregation,
  workforceAgentForecastEnabled,
  workforceSelectedAgentId,
  workforceSelectedDrill,
  workforceSelectedKPI,
  workforceSimulateInterval,
} from '../../store/workforce.state';
import {
  AgentWorkforceEventsAggregated,
  DRILL_KEYS_LABOUR_EVENTS,
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_TOTAL,
  DRILL_KEY_WORK_CUSTOM_TOTAL,
  WorkforceKpiType,
  scaleEvents,
} from '../../store/workforce.types';
import WorkforceAgentTimelineTable from './WorkforceAgentTimelineTable';

const forecastColors = TwTheme.extend.colors.workforceForecast;
const multiForecastShiftColor = 'rgba(255, 0, 102, 0.75)';

export type WorkforceAgentTimelineProps = {
  analyzeId: string;
  showAdvancedLabourEvents: boolean;
};

export type TimelineDatum = BarDatum & {
  date: number;
  forecastIds: string[];
  [DRILL_KEY_IDLE]?: number;
  [DRILL_KEY_OVERTIME]?: number;
  [DRILL_KEY_TOTAL]?: number;
};

export type ComputedTimelineDatum = ComputedBarDatum<TimelineDatum>;

const WorkforceAgentTimelineDataTable: React.FC<
  WorkforceAgentTimelineProps
> = props => {
  const { t } = useTranslation('simulation');
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(true);

  const { t: tMes } = useTranslation('designer');
  const [aggregationType, setAggregationType] = useRecoilState(
    workforceAgentEventAggregation(props.analyzeId),
  );
  const agent = useRecoilValue(resourcePolicyAgentById(agentId));

  const agentAggregatedEvents = useRecoilValue(
    workforceAgentAggregatedEventsCurrent(props.analyzeId),
  );
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );

  const selectedKPI = useRecoilValue(workforceSelectedKPI(agentId));
  const selectedDrillDown = useRecoilValue(workforceSelectedDrill(agentId));
  const pickingRuleMap = useRecoilValue(pickingPolicyOrderLineRulesTitles);
  const planesMap = useRecoilValue(viewerPlanesTitles);
  const interval = useRecoilValue(workforceSimulateInterval);
  const aggType = useRecoilValue(
    workforceAgentEventAggregation(props.analyzeId),
  );

  const series = _.keys(
    _(agentAggregatedEvents).reduce(
      (acc, e) => ({ ...acc, ...e.dimensions }),
      {},
    ),
  );

  function getMetricValues(
    aggregatedEvents: AgentWorkforceEventsAggregated,
    metric: WorkforceKpiType,
  ): Record<string, number> {
    switch (metric) {
      case 'picklists':
        return aggregatedEvents.picklists;
      case 'avg':
        return aggregatedEvents.durationAvg;
      case 'sum':
        return aggregatedEvents.duration;
      case 'fte':
        return scaleEvents(
          aggregatedEvents.duration,
          1 / aggregatedEvents.aggregatedShift,
        );
    }
  }

  const intervalDays = eachDayOfInterval({
    start: interval[0],
    end: interval[1],
  });

  let chartData: TimelineDatum[];
  if (aggType === 'dailyCalendar') {
    chartData = _(intervalDays)
      .map(day => {
        const aggEvent = agentAggregatedEvents[day.toString()];

        return _.isNil(aggEvent)
          ? {
              date: day.getTime(),
              forecastIds: [],
            }
          : {
              date: day.getTime(),
              forecastIds: aggEvent.forecastIds as any,
              ...getMetricValues(aggEvent, selectedKPI),
            };
      })
      .value();
  } else {
    chartData = _(agentAggregatedEvents)
      .map(aggEvent => {
        return {
          date: aggEvent.periodStart.getTime(),
          forecastIds: aggEvent.forecastIds as any,
          ...getMetricValues(aggEvent, selectedKPI),
        };
      })
      .value();
  }

  const shiftDurations = _(agentAggregatedEvents)
    .map(aggEvent => {
      switch (selectedKPI) {
        case 'avg':
          return aggEvent.aggregatedShift;
        case 'sum':
          return aggEvent.totalShiftsDuration;
        case 'fte':
          return aggEvent.totalShiftsDuration / aggEvent.aggregatedShift;
      }
      return null;
    })
    .compact()
    .uniq()
    .value();

  const maxShiftDuration = _.max(shiftDurations);

  // Setup X axis ticks
  const maxTicks = 40;
  const maxTicksWithoutRotation = 7;
  const totalX = chartData.length;
  const rakeSize = Math.ceil(totalX / maxTicks);
  const dateTicks = chartData
    .map((v, i) => (i % rakeSize === 0 || i === totalX - 1 ? v.date : null))
    .filter(v => v !== null);

  //Setup Y-axis ticks
  const maxDaily = _.maxBy(chartData, i => i[DRILL_KEY_TOTAL])?.[
    DRILL_KEY_TOTAL
  ];

  const chartYMax = Math.max(maxDaily, maxShiftDuration);
  const chartYMaxHours = chartYMax / 3600;
  const yAxisTicksCount = 8;

  const yRakeSizeHours = Math.ceil(chartYMaxHours / yAxisTicksCount); // distance between labels in hours
  const yTicks = [
    maxDaily,
    ..._.range(24)
      .map(i => (i + 1) * 3600 * yRakeSizeHours)
      .filter(value => maxDaily - value > (yRakeSizeHours * 3600) / 2),
  ];

  const hasDailyData = chartData.length;
  if (!hasDailyData) {
    return (
      <div
        data-component="WorkforceAgentTimelineDataTableNoData"
        className={classNames(
          'flex flex-col',
          'flex-1 items-center',
          'relative',
          'h-full',
          // 'bg-app-panel-dark/60',
        )}
        // style={{ maxHeight: 400 }}
      >
        <div className="flex-1">
          <InboxZero selfCenter message={t`No data for selected time range`} />
        </div>
      </div>
    );
  }

  const dataTableSeries = [
    DRILL_KEY_TOTAL,
    DRILL_KEY_IDLE,
    DRILL_KEY_OVERTIME,
    DRILL_KEY_WORK_CUSTOM_TOTAL,
  ];
  if (selectedKPI !== 'picklists') {
    if (props.showAdvancedLabourEvents) {
      dataTableSeries.push(
        ...series.sort(),
        // ...(series.length === 0 ? null : series.sort()),
        ...DRILL_KEYS_LABOUR_EVENTS,
      );
    }
    // dataTableSeries.push(
    //   DRILL_KEY_LABOUR_TOTAL,
    //   DRILL_KEY_OVERTIME,
    //   DRILL_KEY_IDLE,
    // );
  }

  const pickingGroupMap = getPolicyGroupMap(t);

  return (
    <>
      {/* <header className={'w-full min-w-half flex p-1 lg:p-2 xl:p-4'}>
        <div className="flex-1 uppercase text-xs">{t`Time Analysis`}</div>
        <ButtonSwitchMulti
          buttonType="minimal"
          autoSize
          className="text-xs mx-2"
          selectedIndex={showAdvanced ? 0 : 1}
          onClick={index => setShowAdvanced(index === 0)}
          options={[{ label: t`Data Table` }, { label: t`Off` }]} //
        />
        <DropdownSelector
          buttonTransparent
          panelMode
          className="rounded-full px-2"
          classNameValue="text-xs"
          value={aggregationType}
          values={[...workforceAggregationTypes]}
          renderValue={v => getWorkforceAggregationPeriodTitle(v, t)}
          onChange={ts => setAggregationType(ts)}
        />
      </header> */}

      {/* {showAdvanced && ( */}
      <div
        data-component="WorkforceAgentTimelineDataTable"
        className={classNames(
          'flex-1 w-full h-full overflow-auto flex flex-col',
          'bg-app-panel-dark/60',
          // 'flex flex-col flex-1 items-center relative h-full bg-app-panel-dark/60',
          // 'mt-1',
          // 'h-full',
          // 'overflow-y-auto',
          { 'mt-0.5': isForecastSimulation },
        )}
        // style={{ maxHeight: 400 }}
      >
        <WorkforceAgentTimelineTable
          data={chartData}
          series={dataTableSeries}
          getDimensionColor={getDimensionColor}
          formatValue={v => formatMetricValue(v, selectedKPI, tMes)}
          getDimensionTitle={v =>
            getDimensionTitle(
              v,
              selectedDrillDown,
              pickingRuleMap,
              planesMap,
              getPolicyGroupMap(t),
              t,
            )
          }
        />
      </div>
      {/* )} */}
    </>
  );
};

export default WorkforceAgentTimelineDataTable;
