import * as React from 'react';
const DesignerAreaLockedIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAreaLockedIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,124v-16h-6v-52h6v-16h-16v6h-23.89c-.17,1.35-.4,2.69-.69,4h24.58v6h6v52h-6v6h-52v-6h-6v-22.91c-1.31.35-2.65.65-4,.89v22.02h-6v16h16v-6h52v6h16Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M106,58h-25.09c-4.42,10.58-12.62,19.18-22.9,24.13v23.87h48s0-48,0-48Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M37.86,25.09c-3.81,0-6.84,3.09-6.84,6.84v4.21h13.61s.07,0,.07,0v-4.21c0-3.81-3.09-6.84-6.84-6.83Z"
    />
    <path
      strokeWidth={0}
      d="M62.5,15.52C49.01,2.02,27.05,2.02,13.55,15.52.05,29.02.05,50.98,13.55,64.48c13.5,13.5,35.46,13.5,48.96,0,13.5-13.5,13.5-35.46,0-48.96ZM53.31,56.96c0,1.77-1.45,3.22-3.22,3.22h-24.58c-1.77,0-3.22-1.45-3.22-3.22v-17.61c0-1.77,1.45-3.22,3.22-3.22h.26v-4.21c0-6.64,5.46-12.09,12.09-12.09,6.64,0,12.09,5.45,12.09,12.09v4.21h.07c1.77,0,3.16,1.38,3.29,3.15v17.68Z"
    />
  </svg>
);
export default DesignerAreaLockedIcon;
