import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import SimulationIssuesPanel from '../panels/preAnalyze/SimulationIssuesPanel';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDatasetItems: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <Container col hasOverflowY hasExtraPadding>
        <Suspense
          fallback={
            <LoadingIndicator
              selfCenter
              message={t`Analysing Dataset for Issues`}
            />
          }
        >
          <SimulationIssuesPanel />
        </Suspense>
      </Container>
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-issues-wide',
    title: t('Data Issues'),
    loadingMessage: t('Analysing Dataset for Issues'),
    size: 'md',
    hasIssue: true,
  };
  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDatasetItems />
    </SidebarContainer>
  );
};
