import {
  AnalyzeResultLocationFragment,
  AnalyzeResultStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { selector, selectorFamily } from 'recoil';
import { getAnalyzeCompareDataRows } from '../../analyzeCompare/analyzeCompare.helper';
import {
  HeatmapBucket,
  getAnalyzeProductHeatmapMetricRange,
} from '../../common/heatmap.helper';
import { HeatmapMetricRange } from '../../common/heatmap.types';
import {
  viewerSelectedBay,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
  viewerShowLocations,
} from '../../layout/viewer/store/viewer.state';
import { getAnalyzeProductHeatmapBuckets } from '../../metrics/analyzeProduct/analyzeProductMetric.helper';
import { analyzeProductMetricDescriptor } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { AnalyzeProductDataRow } from '../../metrics/analyzeProduct/analyzeProductMetric.types';
import { namedColorsGroupSelected } from '../../store/namedColors.state';
import { optimisationAnalyzeResult } from './optimisation.state';
import { loadLocationHeatmap } from './simulation.helper';
import {
  simulationAnalyzeResult,
  simulationAnalyzeStatus,
} from './simulation.state';
import { simulationWizardSelectedStepId } from './simulation.wizard.state';

const getKey = (postfix: string) =>
  `warebee-simulation-analyze-product-heatmap-${postfix}`;

export const simulationHeatmapRangeDescriptor = selector<HeatmapMetricRange>({
  key: getKey('range-descriptor'),
  get: ({ get }) => {
    const analyzeSummary = get(simulationAnalyzeResult);
    const optimisationAnalyzeSummary = get(optimisationAnalyzeResult);
    const metricDescriptor = get(analyzeProductMetricDescriptor);
    const analyzeLocations = get(
      simulationHeatmapLocationByLevelArray(analyzeSummary?.id),
    );
    const optimisationLocations = get(
      simulationHeatmapLocationByLevelArray(optimisationAnalyzeSummary?.id),
    );

    return getAnalyzeProductHeatmapMetricRange(
      metricDescriptor,
      [analyzeSummary, optimisationAnalyzeSummary],
      _.concat(analyzeLocations, optimisationLocations),
    );
  },
});

export const simulationHeatmapBuckets = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets'),
  get: ({ get }) => {
    const namedColors = get(namedColorsGroupSelected);
    const metricDescriptor = get(analyzeProductMetricDescriptor);
    const rangeDescriptor = get(simulationHeatmapRangeDescriptor);
    if (_.isNil(rangeDescriptor)) return null;
    return getAnalyzeProductHeatmapBuckets(
      rangeDescriptor,
      metricDescriptor,
      namedColors,
    );
  },
});

// ----- Locations data by LEVEL -----
const simulationHeatmapLocationByLevelRaw = selectorFamily<
  Record<string, AnalyzeResultLocationFragment>,
  string
>({
  key: getKey('locations-level-heatmap-raw-family'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const areaId = get(viewerSelectedPlaneId);
      const level = get(viewerSelectedLevel);
      const showLocation = get(viewerShowLocations);
      const analyzeStatus = get(simulationAnalyzeStatus);
      const stepId = get(simulationWizardSelectedStepId);

      if (
        !showLocation ||
        _.isNil(level) ||
        !analyzeId ||
        !areaId ||
        analyzeStatus !== AnalyzeResultStatus.COMPLETED
        // ||
        // (stepId !== 'analyse' && stepId !== 'optimise')
      )
        return null;

      const locationMap = await loadLocationHeatmap({
        analyzeId,
        areaId,
        level,
      });
      return locationMap;
    },
});

export const simulationHeatmapLocationByLevelArray = selectorFamily<
  AnalyzeProductDataRow[],
  string
>({
  key: getKey('locations-level-heatmap-flat-family'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const locationMapRaw = get(
        simulationHeatmapLocationByLevelRaw(analyzeId),
      );
      const metricDescriptor = get(analyzeProductMetricDescriptor);
      const rows = getAnalyzeCompareDataRows(
        _.flatten(_.values(locationMapRaw)),
        metricDescriptor,
      );
      return rows;
    },
});

export const simulationHeatmapLocationByLevel = selectorFamily<
  Record<string, AnalyzeProductDataRow[]>,
  string
>({
  key: getKey('locations-level-heatmap-family'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const data = get(simulationHeatmapLocationByLevelArray(analyzeId));
      const locationMap = _.groupBy(data, loc => loc.locationId);
      return locationMap;
    },
});

// ----- Locations data by BAY -----
const simulationHeatmapLocationByBayRaw = selectorFamily<
  Record<string, AnalyzeResultLocationFragment>,
  string
>({
  key: getKey('locations-bay-heatmap-raw-family'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const areaId = get(viewerSelectedPlaneId);
      const showLocation = get(viewerShowLocations);
      const analyzeStatus = get(simulationAnalyzeStatus);
      const stepId = get(simulationWizardSelectedStepId);
      const bay = get(viewerSelectedBay);

      if (
        !showLocation ||
        _.isNil(bay) ||
        !analyzeId ||
        !areaId ||
        analyzeStatus !== AnalyzeResultStatus.COMPLETED
        // ||
        // (stepId !== 'analyse' && stepId !== 'optimise')
      )
        return null;

      const locationMap = await loadLocationHeatmap({
        analyzeId,
        areaId,
        bayId: bay.id,
      });
      return locationMap;
    },
});

export const simulationHeatmapLocationByBayArray = selectorFamily<
  AnalyzeProductDataRow[],
  string
>({
  key: getKey('locations-bay-heatmap-flat-family'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const locationMapRaw = get(simulationHeatmapLocationByBayRaw(analyzeId));
      const metricDescriptor = get(analyzeProductMetricDescriptor);
      const rows = getAnalyzeCompareDataRows(
        _.flatten(_.values(locationMapRaw)),
        metricDescriptor,
      );
      return rows;
    },
});

export const simulationHeatmapLocationByBay = selectorFamily<
  Record<string, AnalyzeProductDataRow[]>,
  string
>({
  key: getKey('locations-bay-heatmap-family'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      const data = get(simulationHeatmapLocationByBayArray(analyzeId));
      const locationMap = _.groupBy(data, loc => loc.locationId);
      return locationMap;
    },
});
