import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatShare } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import FileUpload from '../../components/FileUpload';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ProgressBar } from '../../components/progressbar/ProgressBar';
import WarehouseFilesDataTable from '../../wrehouseFiles/WarehouseFilesDataTable';
import useUploadWarehouseFile from '../../wrehouseFiles/hooks/useUploadWarehouseFile';
import {
  warehouseFilesUploadProgress,
  warehouseFilesUploadStatus,
} from '../../wrehouseFiles/store/warehouseFiles.state';

const DataDashboardFiles: React.FC = () => {
  const { t } = useTranslation();
  const [upload] = useUploadWarehouseFile();
  const status = useRecoilValue(warehouseFilesUploadStatus);
  const progress = useRecoilValue(warehouseFilesUploadProgress);
  const inProgress = status === AsyncLoadStatus.Loading;
  const [filename, setFilename] = useState<string>('');

  return (
    <div
      data-component="DataDashboardFiles"
      className={classNames('flex h-full w-full flex-col')}
    >
      <div className={classNames('max-h-48', inProgress ? 'p-4' : '')}>
        {inProgress && (
          <ProgressBar
            progress={formatShare(progress.loaded / progress.total, t)}
            action={t`Uploading...`}
            message={`${filename}`}
          />
        )}

        {!inProgress && (
          <Container
            className={classNames(
              'group',
              'p-0.5',
              'bg-barber-pole-sm bg-barber-size-sm',
              'bg-menu-active group-hover:bg-menu',
              'cursor-pointer',
            )}
          >
            <FileUpload
              accept="*"
              onChange={async file => {
                setFilename(file.name);
                upload({ file });
              }}
            >
              <div
                className={classNames(
                  'px-4',
                  'flex w-full flex-col items-center',
                )}
              >
                <div
                  className={classNames(
                    'flex items-center justify-between',
                    'w-full',
                    'px-4 py-4',
                    'text-center',
                    'rounded',
                    'bg-app-panel-dark/60',
                  )}
                >
                  <Icon.FiletypeAny
                    className={classNames(
                      'h-8 w-8',
                      'mx-4',
                      'fill-current',
                      'text-menu-active/70 group-hover:text-menu-active/30',
                    )}
                  />
                  <span
                    className={classNames(
                      'text-menu-active/70 group-hover:text-menu-active/30',
                      'flex flex-1 items-center',
                    )}
                  >
                    <span className={classNames('ltr:mr-1 rtl:ml-1')}>
                      {t(`Drag or Click to upload the file`)}
                    </span>
                  </span>
                </div>
              </div>
            </FileUpload>
          </Container>
        )}
      </div>
      <div className="flex-1">
        <WarehouseFilesDataTable id="warehouse-files-table" />
      </div>
    </div>
  );
};

export default DataDashboardFiles;
