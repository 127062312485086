import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import {
  getOccupancyCategories,
  getOccupancyCategoryFull,
} from '../simulation/store/occupancy/assignmentOccupancy.helper';

type AssignmentOccupancyTagProps = {
  occupancy: number;
};

const AssignmentOccupancyTag: React.FC<AssignmentOccupancyTagProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const category = getOccupancyCategoryFull(
    props.occupancy,
    getOccupancyCategories(t),
  );
  const value =
    props.occupancy > 0
      ? formatter.formatShare(props.occupancy).fullString
      : category.tag;
  return (
    <span
      data-component="AssignmentOccupancyTag"
      aria-label={`Assignment Occupancy Status`}
      className={`text-center px-2 py-1 rounded text-xxs flex-1 whitespace-nowrap`}
      style={{
        background: category.color,
        color: category.textColor,
      }}
    >
      {value}
    </span>
  );
};

export default AssignmentOccupancyTag;
