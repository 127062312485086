import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../../../components/actions/ButtonSwitchMulti';
import {
  workforceAgentForecastEnabled,
  workforceAgentForecastMode,
  workforceSelectedAgentId,
} from '../../../store/workforce.state';
import { WorkforceForecastMode } from '../../../store/workforce.types';

type WorkforceAgentContainerProps = {
  analyzeId?: string;
};

const WorkforceForecastSwitch: React.FC<
  WorkforceAgentContainerProps
> = props => {
  const { t } = useTranslation('simulation');
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const [mode, setMode] = useRecoilState(workforceAgentForecastMode(agentId));
  const isForecastSimulation = useRecoilValue(
    workforceAgentForecastEnabled(agentId),
  );
  const modes: WorkforceForecastMode[] = ['byDate', 'schedule'];
  const modeOptions: ButtonSwitchMultiOption[] = [
    {
      label: t`Date Forecast`,
    },
    {
      label: t`Wave Forecast`,
    },
  ];

  return (
    isForecastSimulation && (
      <ButtonSwitchMulti
        buttonType="primary"
        autoSize
        className={classNames('w-full lg:w-auto mb-4 lg:mb-0 mx-2')}
        options={modeOptions}
        selectedIndex={_.indexOf(modes, mode)}
        onClick={index => setMode(modes[index])}
      />
    )
  );
};

export default WorkforceForecastSwitch;
