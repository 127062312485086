import { AnalyzeResultFragment } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import AnalyzeResultLoadBalancingCompare from '../../analyze/AnalyzeResultLoadBalancingCompare';
import SwitchWorkforce from '../../components/actions/SwitchWorkforce';
import { ActionBar } from '../../components/nav/ActionBar';
import { workloadContentViewAs } from '../store/analyze.state';
import SimulationAnalyzePanelWorkforce from './SimulationAnalyzePanelWorkforce';

interface SimulationAnalyzePanelWorkProps {
  resultBefore: AnalyzeResultFragment;
  resultAfter: AnalyzeResultFragment;
}

const SimulationAnalyzePanelWorkCompare: React.FC<
  SimulationAnalyzePanelWorkProps
> = ({ resultBefore, resultAfter }) => {
  const { t } = useTranslation('simulation');
  const [workloadViewAs, setWorkloadViewAs] = useRecoilState(
    workloadContentViewAs,
  );

  return (
    <>
      <ActionBar
        stickyTop
        className="flex items-center !bg-app-panel p-1 xl:p-4"
      >
        <SwitchWorkforce
          selected={workloadViewAs}
          onChange={v => setWorkloadViewAs(v)}
          availableOptions={['workforce', 'workloadbalance']}
        />
      </ActionBar>

      {workloadViewAs === 'workforce' && (
        <SimulationAnalyzePanelWorkforce analyzeResult={resultAfter} />
      )}
      {workloadViewAs === 'workloadbalance' && (
        <AnalyzeResultLoadBalancingCompare
          beforeResult={resultBefore}
          afterResult={resultAfter}
        />
      )}
    </>
  );
};

export default SimulationAnalyzePanelWorkCompare;
