import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../icons';

export type PolicyFilterItemProps = {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  value: string;
  isRemovable?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  isNot?: boolean;
  hasIssue?: boolean;
  onRemoveClick?: () => void;
  onClick?: () => void;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconClassName?: string;
};

const PolicyFilterItem: React.FC<PolicyFilterItemProps> = props => {
  const { t } = useTranslation('app');
  const IconItem = props.icon;

  return (
    <div
      data-component="PolicyFilterItem"
      data-label={`PolicyFilterItem-${props.name}`}
      aria-label={`PolicyFilterItem-${props.name}`}
      className={classNames(
        'flex flex-1',
        'bg-app-panel/50',
        { 'cursor-pointer': !props.isDisabled },
        { 'cursor-default': props.isDisabled },
        props.className,
      )}
    >
      <button
        data-component="PolicyFilterItemAction"
        type="button"
        onClick={e => {
          props.onClick && props.onClick();
        }}
        disabled={props.isDisabled}
        className={classNames([
          'flex flex-1',
          'rounded',
          'text-xs xl:text-base',
          'transition-all duration-200',
          'shadow',
          { 'px-1 py-1 xl:py-2': !props.value },
          { 'bg-alerts-alert text-menu-text': props.hasIssue },
          {
            'px-1 py-1': props.value,
            'items-center': props.icon,
            'items-start xl:items-top': !props.icon,
            ' text-menu-text hover:bg-menu-active/80 hover:text-menu-active-text':
              !props.isActive && !props.isDisabled && !props.hasIssue,
            'bg-app-panel-dark/60 text-menu-text hover:bg-app-panel-dark/50 hover:text-menu-text':
              props.isActive && !props.hasIssue,
            'border border-app-background/50 group-hover:rounded hover:rounded':
              props.isDisabled,
            'group-hover:rounded-l group-hover:rounded-r-none hover:rounded-r-none':
              props.isRemovable && !props.isDisabled,
          },
        ])}
      >
        {props.icon && (
          <IconItem
            className={classNames(
              'fill-current',
              props.iconClassName
                ? props.iconClassName
                : 'w-5 h-5 xl:w-6 xl:h-6 ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
            )}
          />
        )}
        <div
          data-component="containerKeyValue"
          className={classNames(['flex flex-col flex-1'])}
        >
          {props.name && (
            <div
              className={classNames([
                'flex items-start ltr:pr-1 rtl:pl-1 uppercase opacity-75 text-minimal',
                'text-minimal',
              ])}
            >
              {`${props.name}:`}
            </div>
          )}
          {props.isNot && (
            <div
              className={classNames([
                'flex items-start ltr:pr-1 rtl:pl-1 uppercase opacity-75 text-minimal',
                'text-minimal lg:text-xxs xl:text-xs',
              ])}
            >
              {t`(NOT)`}
            </div>
          )}
          {props.value && (
            <div
              className={classNames([
                'flex flex-1 items-center text-start px-0.5 font-bold',
                'text-xs md:text-xs lg:text-sm xl:text-base',
              ])}
            >
              {props.value}
            </div>
          )}
        </div>
      </button>

      {props.isRemovable && (
        <button
          data-component="ButtonRemove"
          type="button"
          onClick={e => {
            e.stopPropagation();
            props.onRemoveClick && props.onRemoveClick();
          }}
          className={classNames([
            'relative px-1 py-1 -ml-px',
            'hover:rounded-r group-hover:rounded-r',
            'hidden group-hover/PolicyFilter:flex',
            'transition duration-200',
            'rounded-r',
            {
              'bg-app-panel/70 text-menu-text hover:bg-menu-hover hover:text-menu-text group-hover:text-menu-text':
                !props.isActive,
              'bg-app-panel-dark/70 text-menu-active hover:bg-menu/50 hover:text-menu-active':
                props.isActive,
              'hover:bg-app-panel-light hover:text-menu-text':
                !props.isDisabled,
              'bg-opacity-50': props.isDisabled,
            },
          ])}
        >
          <Icon.CircleX className="w-5 h-5 fill-current" />
        </button>
      )}
      {props.children}
    </div>
  );
};

export default PolicyFilterItem;
