import {
  GenerateOrderSetIssueSetDocument,
  GenerateOrderSetIssueSetMutation,
  GenerateOrderSetIssueSetMutationVariables,
  LoadOrderSetIssueSetDocument,
  LoadOrderSetIssueSetQuery,
  LoadOrderSetIssueSetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  simulationOrderSetIssues,
  simulationOrderSetIssuesLoadStatus,
} from '../store/simulation.issues.state';

export type LoadOrderSetIssuesParams = {
  layoutId: string;
  assignmentId: string;
  itemSetId: string;
  orderSetId: string;
};

function useLoadOrderSetIssues() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load OrderSet Issues`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadOrderSetIssuesParams) => {
        if (
          _.isNil(params.orderSetId) ||
          _.isNil(params.itemSetId) ||
          _.isNil(params.assignmentId) ||
          _.isNil(params.layoutId)
        ) {
          return;
        }
        set(simulationOrderSetIssuesLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadOrderSetIssuesParams) => {
        if (
          _.isNil(params.orderSetId) ||
          _.isNil(params.itemSetId) ||
          _.isNil(params.assignmentId) ||
          _.isNil(params.layoutId)
        ) {
          return;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationOrderSetIssuesLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const key = {
          layoutId: params.layoutId,
          assignmentId: params.assignmentId,
          itemSetId: params.itemSetId,
          orderSetId: params.orderSetId,
        };

        const query = secureClient.watchQuery<
          LoadOrderSetIssueSetQuery,
          LoadOrderSetIssueSetQueryVariables
        >({
          query: LoadOrderSetIssueSetDocument,
          variables: { key },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const issuesSet = data?.orderSetIssueSet;

            if (_.isNil(issuesSet)) {
              const runResult = await secureClient.mutate<
                GenerateOrderSetIssueSetMutation,
                GenerateOrderSetIssueSetMutationVariables
              >({
                mutation: GenerateOrderSetIssueSetDocument,
                variables: {
                  key,
                },
              });
              set(
                simulationOrderSetIssues,
                runResult?.data?.generateOrderSetIssueSet,
              );
              set(simulationOrderSetIssuesLoadStatus, AsyncLoadStatus.Ok);
            } else {
              set(simulationOrderSetIssues, issuesSet);
              set(simulationOrderSetIssuesLoadStatus, AsyncLoadStatus.Ok);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadOrderSetIssuesParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadOrderSetIssues;
