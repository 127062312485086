import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { POLICY_FILTER_VALUES_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputSearch } from '../../components/inputs/InputSearch';
import {
  policyFilterData,
  policyFilterSearchByFilterKey,
} from '../../simulation/store/policyFilter.state';
import { getPolicyFilterKey } from '../policyFilter.helper';
import {
  PolicyFilterBase,
  PolicyFilterEditorEvents,
} from '../policyFilter.types';

export type PolicyEditorListProps = PolicyFilterEditorEvents & {
  title: string;
  filterType: string;
  value: PolicyFilterBase<string>;
  isNot?: boolean;
};

const PolicyEditorList: React.FC<PolicyEditorListProps> = props => {
  const { t } = useTranslation('app');

  const filterKey = getPolicyFilterKey(props.filterType, 'main');
  const filterData = useRecoilValue(policyFilterData(filterKey));
  const search = useRecoilValue(policyFilterSearchByFilterKey(filterKey));

  const filterValues = _.map(filterData?.content, v => v.title) || [];
  const isLoading = filterData?.status === AsyncLoadStatus.Loading;
  const loadedCount = filterData?.content?.length || 0;
  const totalCount = filterData?.totalCount || 0;

  const selectedValues = _.map(props.value?.valueIn, v => v.title);

  const onSelectValue = (value: string, isSelected: boolean) => {
    const newFilterValue = isSelected
      ? [...selectedValues, value]
      : selectedValues.filter(item => item !== value);

    props.onChange({
      ...props.value,
      type: props.filterType,
      valueIn: newFilterValue.map(fv => ({ title: fv })),
    });
  };

  const selected = new Set(selectedValues);
  const listItems = filterValues;

  return (
    <div className="min-h-5 bg-app-panel-dark/50">
      <div className="p-2">
        <InputSearch
          placeholder={t(`Filter by: {{title}}`, { title: props.title })}
          value={search}
          onChange={v => props.onSearch(v, filterKey)}
          sidebarFilterMode
        />
      </div>
      <div data-component="PolicyEditorList" className={`flex flex-col p-1`}>
        <div className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}>
          {_.map(listItems, value => (
            <InputCheckbox
              key={`chk-${value}`}
              titleAlt={value}
              truncate
              isHoverable
              className="ltr:pl-3 rtl:pr-3"
              isSelected={selected.has(value)}
              onChange={selected => onSelectValue(value, selected)}
            >
              {props.isNot && (
                <span className="text-menu-text/60 text-xxs mx-1">
                  {' '}
                  {t`not`}
                </span>
              )}
              {value}
            </InputCheckbox>
          ))}

          {isLoading && (
            <LoadingIndicator
              inline
              selfCenter
              absolute
              message={t`Filtering values`}
            />
          )}

          {totalCount === 0 && (
            <div className="p-2 m-1 text-xs text-center bg-opacity-50 rounded bg-app-panel-dark text-menu-200">
              {t`No items found`}
            </div>
          )}
        </div>

        {loadedCount < totalCount && (
          <div className="mt-2">
            <Button
              full
              buttonSize="xs"
              className="py-1 bg-app-panel bg-opacity-40"
              // buttonType="secondary"
              label={t('Load next {{itemCount}}', {
                itemCount: POLICY_FILTER_VALUES_PAGE_SIZE,
              })}
              onPress={() => props.onLoadNext(filterKey, loadedCount)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyEditorList;
