import * as React from 'react';
const CongestionMap14Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CongestionMap14Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M51.93,72.98c5.14-4.17,5.14-10.98,0-15.13s-13.56-4.15-18.7,0-5.14,10.95,0,15.13,13.56,4.17,18.7,0Z"
    />
    <path
      strokeWidth={0}
      d="M93.7,72.98c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0s-5.14,10.95,0,15.13,13.56,4.17,18.7,0Z"
    />
    <path
      strokeWidth={0}
      d="M72.81,89.87c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13s13.56,4.17,18.7,0Z"
    />
    <path
      strokeWidth={0}
      d="M72.81,56.08c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13s13.56,4.17,18.7,0Z"
    />
    <path
      strokeWidth={0}
      d="M115.04,89.87c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13,5.14,4.17,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M30.59,89.87c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13s13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M51.93,106.87c5.14-4.17,5.14-10.98,0-15.13s-13.56-4.15-18.7,0c-5.14,4.15-5.14,10.95,0,15.13,5.14,4.17,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M93.7,106.87c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0s-5.14,10.95,0,15.13,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M72.81,123.77c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0s-5.14,10.95,0,15.13,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M51.93,39.13c5.14-4.17,5.14-10.98,0-15.13s-13.56-4.15-18.7,0-5.14,10.95,0,15.13,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M93.7,39.13c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0s-5.14,10.95,0,15.13,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M72.81,22.24c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13s13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M115.04,56.03c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13,5.14,4.17,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M30.59,56.03c5.14-4.17,5.14-10.98,0-15.13-5.14-4.15-13.56-4.15-18.7,0-5.14,4.15-5.14,10.95,0,15.13,5.14,4.17,13.56,4.17,18.7,0Z"
      opacity={0.7}
    />
  </svg>
);
export default CongestionMap14Icon;
