import * as React from 'react';
const Mhe6HForkliftStandUpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe6HForkliftStandUpIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M76.96,91.24h-4.96V22.58h-5.09v-4.36h10.17v4.36h-.12v68.66ZM4.18,21.49c.08-.97.46-1.76,1.15-2.36.69-.61,1.51-.91,2.48-.91h34.15c2.1,0,3.92.63,5.45,1.88,1.53,1.25,2.5,2.89,2.91,4.9l12.11,68.66h20.34c-.57,2.5-.57,4.96,0,7.39H30.46l-6.18-31.24c-.32-1.05-1.05-1.65-2.18-1.82H6.24c-1.21-.08-2.22-.52-3.03-1.33-.81-.81-1.21-1.82-1.21-3.03l2.18-42.14h0ZM85.07,97.41c.24,4.92,2.28,8.5,6.12,10.72,3.83,2.22,7.93,2.2,12.29-.06,1.94-1.13,3.45-2.62,4.54-4.48s1.63-3.92,1.63-6.18c-.24-4.84-2.28-8.38-6.12-10.6-3.83-2.22-7.93-2.24-12.29-.06-1.94,1.13-3.45,2.64-4.54,4.54-1.09,1.9-1.63,3.94-1.63,6.12ZM24.4,85.67c.08.73.69,3.75,1.82,9.08l1.7,9.2h9.93c-1.94,2.91-4.5,4.72-7.69,5.45-3.19.73-6.24.2-9.14-1.57-2.91-1.94-4.72-4.5-5.45-7.69s-.2-6.24,1.57-9.14c1.78-2.66,4.2-4.44,7.27-5.33h0ZM84.22,31.78v30.88h41.78l-8.36,3.51h-36.93V31.78h3.51Z"
    />
  </svg>
);
export default Mhe6HForkliftStandUpIcon;
