import { LocationFilterUnionFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useLoadLocationsByRule from '../assignmentPolicy/useLoadLocationsByRule';
import {
  viewerSelectedLevel,
  viewerSelectedPlaneId
} from '../layout/viewer/store/viewer.state';
import { LSP_FALLBACK_RULE_ID } from '../simulation/store/locationSharingPolicy/locationSharingPolicy.default';
import {
  locationSharingPolicy,
  locationSharingPolicySelectedRuleId
} from '../simulation/store/locationSharingPolicy/locationSharingPolicy.state';
import {
  policyHighlightedLocations,
  simulationLayoutId
} from '../simulation/store/simulation.state';

const LocationSharingPolicyRuleDetailWatcher: React.FC = () => {
  const selectedRuleId = useRecoilValue(locationSharingPolicySelectedRuleId);

  const level = useRecoilValue(viewerSelectedLevel);
  const planeId = useRecoilValue(viewerSelectedPlaneId);

  const layoutId = useRecoilValue(simulationLayoutId);
  const policy = useRecoilValue(locationSharingPolicy);
  const setHighlightedLocations = useSetRecoilState(policyHighlightedLocations);

  const [loadLocationByRule, cancelLoadLocationByRule] =
    useLoadLocationsByRule();

  // Load highlighted locations
  useEffect(() => {
    if (_.isNil(selectedRuleId)) {
      setHighlightedLocations(null);
      return;
    }
    if (_.isNil(layoutId) || _.isNil(planeId) || _.isNil(policy)) {
      //console.warn('Layout and Policy should be selected');
      return null;
    }

    const ruleIndex = _.findIndex(
      policy.rules,
      rule => rule.id === selectedRuleId,
    );
    const rulesToExclude =
      selectedRuleId === LSP_FALLBACK_RULE_ID
        ? policy.rules
        : _.take(policy.rules, ruleIndex);

    const excludeMatching = _.flatMap(
      rulesToExclude,
      rule => rule.locationsMatch,
    );

    const includeMatching: LocationFilterUnionFragment =
      selectedRuleId === LSP_FALLBACK_RULE_ID
        ? null
        : policy.rules[ruleIndex]?.locationsMatch;

    cancelLoadLocationByRule();
    loadLocationByRule({
      planeId,
      level,
      layoutId,
      includeMatching,
      excludeMatching,
    });
  }, [planeId, policy, selectedRuleId, layoutId, level]);

  return null;
};

export default LocationSharingPolicyRuleDetailWatcher;
