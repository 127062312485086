import {
  AgentRoleSettingsFragment,
  AgentSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';

// id:categories.json

export const agentLabourMetadataPropertyName = 'labour' as const;
export const agentLabourEvents = [
  'breaks',
  'meetings',
  'training',
  'recharge',
  'maintenance',
  'misc',
] as const;

export type AgentLabourEvent = (typeof agentLabourEvents)[number];
export type AgentLabourEventsSetting = Partial<
  Record<AgentLabourEvent, number>
>;
export type AgentMetadata = {
  modelId: string;
  [agentLabourMetadataPropertyName]?: AgentLabourEventsSetting;
};

export type AgentSettingsWithMeta = Omit<AgentSettingsFragment, 'metadata'> & {
  metadata: AgentMetadata;
};

export type ResourcePolicy = {
  agents: AgentSettingsWithMeta[];
};

export const agentCategoriesId = [
  'default',
  'picker-manual',
  'picker-voice',
  'hand-track-two-wheeled',
  'hand-track-floor',
  'dolly',
  'trolley-manual',
  'trolley-manual-two-wheeled',
  'trolley-manual-four-wheeled',
  'trolley-four-wheeled-electric',
  'pallet-jack-manual',
  'pallet-jack-electric',
  'walkie-stalker-manual',
  'walkie-stalker-electric',
  'pallet-truck',
  'platform-truck',
  'platform-truck-walkie',
  'platform-truck-rider',
  'forklift-seat',
  'forklift-sit-down-electric',
  'forklift-stand',
  'forklift-seat-tire-cushion',
  'forklift-seat-tire-pneumatic',
  'forklift-seat-rough-terrain',
  'straddle-truck_narrow-aisle',
  'forklift-reach-truck',
  'turret-truck',
  'turret-truck-operator-down',
  'turret-truck-operator-up',
  'order-picker',
  'side-loader',
  'amr-autonomous-manoeuvrable-robots',
  'automatic-guided-vehicle-agv',
  'automatic-guided-vehicle-agv-tow',
  'automatic-guided-vehicle-agv-unit-load',
  'automatic-guided-vehicle-agv-assembly',
  'automatic-guided-vehicle-agv-light-load',
  'automatic-guided-vehicle-agv-fork',
  'asrs-automatic-storage-retrieval-system',
  'asrs-automatic-storage-retrieval-system-unit-load',
  'asrs-automatic-storage-retrieval-system-mini-load',
  'asrs-automatic-storage-retrieval-system-man-on-board',
  'asrs-automatic-storage-retrieval-system-deep-lane',
  'conveyors',
  'conveyors-chute',
  'conveyors-wheel',
  'conveyors-roller',
  'conveyors-roller-gravity',
  'conveyors-roller-electric',
  'conveyors-chain',
  'conveyors-slat',
  'conveyors-belt-flat',
  'conveyors-belt-magnetic',
  'conveyors-belt-troughed',
  'conveyors-bucket',
  'conveyors-vibrating',
  'conveyors-screw',
  'conveyors-pneumatic',
  'conveyors-pneumatic-dilute',
  'conveyors-pneumatic-carrier',
  'conveyors-vertical',
  'conveyors-vertical-lift',
  'conveyors-vertical-reciprocating',
  'conveyors-cart-on-track',
  'conveyors-tow',
  'conveyors-trolley',
  'conveyors-power-and-free',
  'conveyors-sortation',
  'conveyors-diverters',
  'conveyors-popup',
  'conveyors-sliding-shoe',
  'conveyors-titling',
  'conveyors-cross-belt',
  'monorail',
  'tractor-trailer',
  'burden-carrier',
  'lift',
] as const;

export type AgentCategoryId = (typeof agentCategoriesId)[number];
export type AgentEventSettingsId = string;

// categories.json
export type AgentCategoryDefinition = {
  id: AgentCategoryId;
  eventSettingsId: AgentEventSettingsId;
  category_group?: string;
  category_subgroup?: string;
  class?: string;
  title_alias?: string[];
  title?: string;
  description?: string;
  sortOrder?: number;
};

// eventConfig (eventDefault-default.json)
export type AgentEventSettingsDefinition = {
  id: AgentEventSettingsId;
  roleDefaults?: {
    metric?: Partial<AgentRoleSettingsFragment>;
    imperial?: Partial<AgentRoleSettingsFragment>;
  };
  eventDefaults?: {
    metric?: Partial<AgentSettingsWithMeta>;
    imperial?: Partial<AgentSettingsWithMeta>;
  };
};

export type AgentModelDefinition = {
  id: string;
  manufacturerId: string;
  useAsDefault?: boolean;
  modeFamilyId?: string;
  settingId?: string; // AgentEventSettingsId
  categoryId: string; // AgentCategoryId;
  image?: any[];
  title: string;
  description?: string;
  sensors?: any[];
  features?: any[];
  tags?: any[];
  isPremium?: boolean;
  isSpotlight?: boolean;
  agentOperatorType?: string;
  specsMetric?: AgentModelSpec;
  specsImperial?: AgentModelSpec;
};

export type AgentManufacturerDefinition = {
  id: string;
  settingId?: string; // AgentEventSettingsId
  aliases?: string[];
  country: string[];
  description?: string;
  editorNotes?: string;
  isClaimed?: boolean;
  isPremium?: boolean;
  isValidated?: boolean;
  isDisabled?: boolean;
  logo?: string;
  title: string;
  website?: string[] | string;
};

export interface AgentModelSpec {
  productivity?: Productivity;
  performance?: Performance;
  dimensions?: SpecsMetricDimensions;
  suspension?: Suspension;
  powerSource?: PowerSource;
}

export interface SpecsMetricDimensions {
  weight?: Weight;
  heightMin?: number;
  heightMax?: number;
  heightOverheadCab?: number;
  width?: number;
  length?: number;
  turningRadiusExternal?: number;
  turningRadiusInternal?: number;
  lift?: Lift;
  aislePallet?: AislePallet;
  fork?: Fork;
}

export interface AislePallet {
  '1000x1200'?: number;
  '800x1200'?: number;
}

export interface Fork {
  dimensions?: ForkDimensions;
  carriage?: Carriage;
  forkTilt?: ForkTilt;
}

export interface Carriage {
  forkCarriageDINClass?: number;
  forkCarriageDINType?: string; //number;
  forkCarriageWidth?: number;
}

export interface ForkDimensions {
  s?: number;
  e?: number;
  l?: number;
}

export interface ForkTilt {
  a?: number;
  b?: number;
}

export interface Lift {
  lift?: number;
  liftHeight?: number;
  liftFree?: number;
}

export interface Weight {
  serviceWeight?: number;
  axleLoad?: DrawbarPull;
}

export interface DrawbarPull {
  loaded?: TrackWidth | number;
  unloaded?: TrackWidth | number;
}

export interface TrackWidth {
  front?: number;
  rear?: number;
}

export interface Performance {
  load?: Load;
  horizontalTravelling?: HorizontalTravelling;
  verticalTravelling?: VerticalTravelling;
  drawbarPull?: DrawbarPull;
  gradeability?: Gradeability;
}

export interface Gradeability {
  percentLoaded?: number;
  percentUnloaded?: number;
  maxPercentLoaded?: number;
  maxPercentUnloaded?: number;
}

export interface HorizontalTravelling {
  speed?: Speed;
  acceleration?: Acceleration;
  breaks?: Breaks;
}
export interface VerticalTravelling {
  lifting?: Speed;
  lowering?: Speed;
}

export interface Acceleration {
  speedLoaded?: number;
  speedUnloaded?: number;
}

export interface Breaks {
  type?: string;
}

export interface Speed {
  speedUOM?: 'm/s'; // 'km/h' / 'mph';
  speedLoaded?: number;
  speedUnloaded?: number;
}

export interface Load {
  maxPayloadCapacity?: number;
  maxPayloadCentre?: number;
  maxPayloadDistanceFork?: number;
  wheelbase?: number;
}

export interface PowerSource {
  drive?: string;
  driveType?: string;
  motorDriveRating?: number | string;
  motorDrive?: number | string;
  motorDriveKw?: number | string;
  motorLiftRating?: number | string;
  motorLift?: number | string;
  motorLiftKw?: number | string;
  motorPerformanceISO1585Kw?: number | string;
  motorType?: number | string;
  motorSpeed?: number | string;
  motorCylinders?: number | string;
  motorCapacity?: number | string;
  emissionLevel?: number | string;
  battery?: Battery;
  energy?: Energy;
  soundLevel?: number | string;
}

export interface Battery {
  batteryType?: string;
  batteryDIN43535?: number | string;
  batteryVoltage?: number | string;
  batteryCapacityAh?: number | string;
  batteryWeight?: number | string;
}

export interface Energy {
  energyConsumptionEN16796?: number;
  energyConsumptionEN16796Unit?: number;
}

export interface Productivity {
  'modeMaxPallet/h'?: number;
  'modeEcoPallet/h'?: number;
  'energyMax100Pallet/kWh'?: number;
  'energyEco100Pallet/kWh'?: number;
  'Turnover/h'?: number;
  'Turnover/VDI2198'?: number;
}

export interface Suspension {
  tyres?: Tyres;
}

export interface Tyres {
  type?: string[];
  tyreSize?: TyreSize;
  trackWidth?: TrackWidth;
  wheels?: TrackWidth;
}

export interface TyreSize {
  front?: string; //number;
  front_dimension?: string; //number;
  rear?: string;
  rear_dimension?: string;
}
