import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ContainerScrollProps = {
  children?: React.ReactNode;
  className?: string;
  hasExtraPadding?: boolean;
  hasOverflowX?: boolean;
  hasOverflowY?: boolean;
  hasNoScroll?: boolean;
};

export const ContainerScroll = ({
  children,
  className,
  hasExtraPadding,
  hasOverflowX,
  hasOverflowY,
  hasNoScroll,
}: ContainerScrollProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <main
      data-component="ContainerScroll"
      id={id}
      className={classNames(
        'flex-1 w-full h-full',
        { 'overflow-x-auto overflow-y-hidden': hasOverflowX },
        { 'overflow-y-auto overflow-x-hidden': hasOverflowY },
        { 'pb-[3rem]': hasExtraPadding },
        hasNoScroll ? 'overflow-hidden' : 'overflow-auto',
        className,
      )}
    >
      {children}
    </main>
  );
};
