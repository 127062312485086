import * as React from 'react';
const WarehouseFloorUpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseFloorUpIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,15.42L.84,31.85v74.65h126.32V31.85l-62.66-16.43ZM41.41,47.9v-6.03h16.29v16.01h-6.12v-5.74l-11.11,10.92-4.33-4.24,11.11-10.92h-5.84ZM13.08,86.17h36.5v-12.43h12.71v-12.43h12.62v-12.43h45.72v6.03h-39.6v12.43h-12.62v12.43h-12.71v12.43H13.08v-6.03Z"
    />
  </svg>
);
export default WarehouseFloorUpIcon;
