import { useApolloClient } from '@apollo/client';
import {
  LoadOptimizationRunStatusDocument,
  LoadOptimizationRunStatusQuery,
  LoadOptimizationRunStatusQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useRecoilCallback } from 'recoil';
import { optimisationResultStatus } from '../../simulation/store/optimisation.state';

function useLoadOptimizeStatus() {
  const client = useApolloClient();

  //   const cleanupState = useRecoilCallback(({ set }) => async () => {
  //     set(viewerLayoutId, null);
  //     set(viewerSelectedLayout, null);
  //   });

  const loadOptimizeStatusCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (optimisationId: string) => {
        if (_.isNil(optimisationId)) return;
        console.log('Call useLoadOptimizeStatus');
        const response = await client.query<
          LoadOptimizationRunStatusQuery,
          LoadOptimizationRunStatusQueryVariables
        >({
          fetchPolicy: 'no-cache',
          query: LoadOptimizationRunStatusDocument,
          variables: {
            optimisationRunId: optimisationId,
          },
        });

        const optimiseRun = response.data.optimizationRun;
        set(optimisationResultStatus, optimiseRun);
      },
  );

  return async (optimisationId: string) => {
    await loadOptimizeStatusCallback(optimisationId);
  };
}
export default useLoadOptimizeStatus;
