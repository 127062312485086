import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../../../common/utils';
import { ButtonToolbarGroup } from '../../../../components/actions/ButtonToolbarGroup';
import { ButtonToolbarWrapper } from '../../../../components/actions/ButtonToolbarWrapper';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import TitleToolbarContainer from '../../../../containers/TitleToolbarContainer';
import {
  converterAreaActionsMode,
  converterShowAreaLabels,
} from '../store/converter.area.state';
import { AreaActionsMode, areaActionsModes } from '../store/converter.types';

const ConverterAreaActionToolbar: React.FC = () => {
  const { t } = useTranslation('app');
  const [actionsMode, setActionMode] = useRecoilState(converterAreaActionsMode);
  const [showLabels, setShowLabels] = useRecoilState(converterShowAreaLabels);

  const actionsModeTitles: Record<AreaActionsMode, string> = {
    none: t`Select`,
    links: t`Link`,
    'add-area': t`Add Area`,
    'add-location': t`Add Location`,
  };

  return (
    <ButtonToolbarGroup
      className={cn(
        'flex',
        // 'flex-col',
        'z-[5001]',
        'absolute',
        'top-1',
        'ltr:left-1 rtl:right-1',
        'isolate',
      )}
    >
      <TitleToolbarContainer
        id="converter-area-view-modes"
        title={t`Edit Modes`}
        collapsible
        classNameContainerBodyChildren="flex"
      >
        {/* <ButtonToolbar
          isSelected={showLabels}
          onChange={() => setShowLabels(!showLabels)}
          className={cn('w-full flex-1')}
        >
          <Icon.TagTitle className={cn('h-7 w-7')} />
        </ButtonToolbar> */}
        {/* </TitleToolbarContainer>
      <TitleToolbarContainer
        id="converter-area-actions"
        title={t`View`}
        collapsible
      > */}
        <ButtonToolbarWrapper className={cn('!p-0', 'w-full')}>
          <DropdownSelector
            className={cn('text-menu-text text-sm', 'h-full', 'w-full')}
            classNameValue={cn('h-full')}
            values={[...areaActionsModes]}
            value={actionsMode}
            onChange={mode => setActionMode(mode)}
            renderValue={mode => actionsModeTitles[mode]}
            buttonTransparent
            headerMode
            w_sm
          />
        </ButtonToolbarWrapper>
      </TitleToolbarContainer>
      {/* <ButtonToolbar
          isSelected={showCreateAction}
          onChange={() => setShowCreateActions(!showCreateAction)}
          className={cn('w-full flex-1')}
        >
          <Icon.LinkArrow className={cn('h-7 w-7')} />
        </ButtonToolbar>

        <ButtonToolbar
          isSelected={showLinkAction}
          onChange={() => setShowLinkActions(!showLinkAction)}
          className={cn('w-full flex-1')}
        >
          <Icon.WarehouseAreas className={cn('h-7 w-7')} />
        </ButtonToolbar> */}
    </ButtonToolbarGroup>
  );
};

export default ConverterAreaActionToolbar;
