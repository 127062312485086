import { useApolloClient } from '@apollo/client';
import {
  BatchJobStatus,
  ImportJobTargetType,
  LoadImportJobDocument,
  LoadImportJobQuery,
  LoadImportJobQueryVariables,
  useStartImportJobProcessingMutation,
  useUpdateImportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { IMPORT_JOB_REFRESH_INTERVAL } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import { cloneWithoutTypename } from '../common/utils';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import { errorAppender } from '../store/error.state';
import { importTriggeredBySim } from '../store/global.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import { getImportConfiguration } from './store/import.default';
import {
  createMappingSpecInput,
  processValidationErrors,
} from './store/import.helper';
import {
  importJob,
  importMappingSettingsByType,
  importParsedFile,
  importProcessStatus,
  importSelectedStep,
  importTargetDatasetId,
  importTypeCurrent,
  importValidationErrors,
} from './store/import.state';

export type ProcessImportJobParams = {
  //type: ImportType;
};

/*
 * Run import job processing
 *  - update job with new mappings
 *  - run process job
 */

function useProcessImportJob() {
  const { t } = useTranslation('errors');
  const [updateJob] = useUpdateImportJobMutation();
  const updateSim = useUpdateSimulation();
  const [startProcessing] = useStartImportJobProcessingMutation();
  const errorTitle = t`Cannot process imported file`;
  const client = useApolloClient();
  const navigate = useNavigate();
  const [observable, setObservable] = useState<ZenObservable.Subscription>();
  let timeoutId = null;

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ProcessImportJobParams) => {
        set(importProcessStatus, AsyncLoadStatus.Loading);
      },
  );

  const loadImportJob = useRecoilCallback(
    ({ snapshot, set }) =>
      async (jobId: string) => {
        const type = await snapshot.getPromise(importTypeCurrent);
        const whId = await snapshot.getPromise(warehouseSelectedId);
        const triggerSimId = await snapshot.getPromise(importTriggeredBySim);
        const mappingSettings = await snapshot.getPromise(
          importMappingSettingsByType(type),
        );

        const query = client.watchQuery<
          LoadImportJobQuery,
          LoadImportJobQueryVariables
        >({
          query: LoadImportJobDocument,
          variables: {
            id: jobId,
          },
        });

        function handleError(details, stack) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importProcessStatus, AsyncLoadStatus.Error);
        }
        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const job = data.importJob;

            if (
              job.status === BatchJobStatus.FAILED &&
              !_.isEmpty(job.errors?.content)
            ) {
              set(importSelectedStep, 'import-map-fields');
              set(
                importValidationErrors,
                processValidationErrors({
                  mappingSettings,
                  errors: job.errors.content,
                }),
              );
              set(importProcessStatus, AsyncLoadStatus.Ok);
            } else if (
              job.status === BatchJobStatus.FAILED ||
              job.status === BatchJobStatus.TERMINATED
            ) {
              set(importProcessStatus, AsyncLoadStatus.Error);
            }

            if (
              job.status === BatchJobStatus.CALCULATING ||
              job.status === BatchJobStatus.CREATED ||
              job.status === BatchJobStatus.RESTARTED
            ) {
              timeoutId = setTimeout(() => {
                loadImportJob(jobId);
              }, IMPORT_JOB_REFRESH_INTERVAL);
            }

            if (job.status === BatchJobStatus.READY) {
              set(importProcessStatus, AsyncLoadStatus.Ok);
              const configuration = getImportConfiguration(type);

              if (
                job.targetId &&
                triggerSimId &&
                !_.isNil(configuration.getSimulationChanges)
              ) {
                await updateSim(
                  configuration.getSimulationChanges(job.targetId),
                  triggerSimId,
                );
                set(importTriggeredBySim, null);
                navigate(`/wh/i/${whId}/simulations/${triggerSimId}`);
                return;
              }
              navigate(configuration.getTargetPath(whId, job.targetId));
            }
            set(importJob, job);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  const callProcess = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ProcessImportJobParams) => {
        const job = await snapshot.getPromise(importJob);
        const type = await snapshot.getPromise(importTypeCurrent);
        const parsedFile = await snapshot.getPromise(importParsedFile);
        const targetId = await snapshot.getPromise(importTargetDatasetId);
        const mappingSettings = await snapshot.getPromise(
          importMappingSettingsByType(type),
        );

        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importProcessStatus, AsyncLoadStatus.Error);
        }

        try {
          if (_.isNil(job)) {
            console.error(
              `Incorrect process job call. Job is not yet created `,
            );
            return;
          }

          const { data: updateJobData, errors: updateJobErrors } =
            await updateJob({
              variables: {
                input: {
                  id: job.id,

                  settings: {
                    ..._.omit(cloneWithoutTypename(mappingSettings), [
                      'fields',
                    ]),
                    mapping: createMappingSpecInput(mappingSettings),
                  },
                  targetSettings: _.isEmpty(targetId)
                    ? undefined
                    : {
                        target: ImportJobTargetType.APPEND,
                        appendSettings: {
                          targetId,
                        },
                      },
                },
              },
            });

          if (!_.isEmpty(updateJobErrors)) {
            const details = _(updateJobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          const { data: processJobData, errors: processJobErrors } =
            await startProcessing({
              variables: {
                id: job.id,
                options: {
                  restart: true,
                },
              },
            });

          if (!_.isEmpty(processJobErrors)) {
            const details = _(processJobErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          //set(importJob, processJobData.startImportJobProcessing);
          loadImportJob(job.id);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: ProcessImportJobParams) {
    await initLoading(params);
    await callProcess(params);
  }

  function cancel() {
    observable?.unsubscribe();
    clearTimeout(timeoutId);
  }

  return [call, cancel] as const;
}
export default useProcessImportJob;
