import * as React from 'react';
const WarehouseAreasUpIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseAreasUpIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,24.5L.84,40.93v62.58h126.32v-62.58l-62.66-16.43ZM51.1,93.5H9.5l17.06-15.01h29.03l-4.48,15.01ZM49.94,59.04H9.63l18.56-7.5h29.03l-7.28,7.5ZM73.47,54.36l11.72-11.71,11.67,11.65v6.74s-9.3-9.28-9.3-9.28l-.04,15.41h-4.77s.04-15.41.04-15.41l-9.33,9.32v-6.73ZM72.45,78.51h29.03l6.06,5.33h-33.07l-2.01-5.33ZM78.11,93.5l-2.07-5.47h36.24l6.22,5.47h-40.4Z"
    />
  </svg>
);
export default WarehouseAreasUpIcon;
