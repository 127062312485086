import { LayoutFloorSettings } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../../../common/utils';
import ButtonToolbar from '../../../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../../../components/actions/ButtonToolbarGroup';
import { ButtonToolbarWrapper } from '../../../../components/actions/ButtonToolbarWrapper';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import TitleToolbarContainer from '../../../../containers/TitleToolbarContainer';

export type ConverterFloorsToolbarCmpProps = {
  floors: Record<string, LayoutFloorSettings>;
  selectedFloorId: string;
  changeFloor: (id: string) => void;
  adjustStageCallback?: () => void;
};

const ConverterFloorsToolbarCmp: React.FC<
  ConverterFloorsToolbarCmpProps
> = props => {
  const { t } = useTranslation('app');

  return (
    <div className={`ViewModeToolbar`}>
      <ButtonToolbarGroup
        className={cn(
          'z-toolbar absolute top-1 mb-2 flex flex-col ltr:right-1 rtl:left-1',
        )}
      >
        <TitleToolbarContainer
          id="panel-toolbar-floor"
          title={t`Floor`}
          collapsible
          classNameContainerBodyChildren='p-1'
        >
          <ButtonToolbarWrapper className={cn('!m-0 !p-0', 'w-full')}>
            <DropdownSelector
              className={cn('text-menu-text text-sm', 'h-full', 'w-full')}
              classNameValue={cn('h-full')}
              // label={'Floor'}
              value={props.selectedFloorId}
              values={_.keys(props.floors)}
              renderValue={key => props.floors[key]?.title}
              // valueHelper="configured"
              buttonTransparent
              headerMode
              DropAlignRight
              onChange={value => props.changeFloor && props.changeFloor(value)}
            />
          </ButtonToolbarWrapper>
        </TitleToolbarContainer>
      </ButtonToolbarGroup>
      <ButtonToolbarGroup
        className={`z-toolbar absolute bottom-1 ltr:right-1 rtl:left-1`}
      >
        {props.adjustStageCallback && (
          <ButtonToolbar onChange={props.adjustStageCallback}>
            <Icon.FitToBox
              className="h-8 w-8 text-white"
              alt={t`Reset zoom and position`}
              title={t`Reset zoom and position`}
            />
          </ButtonToolbar>
        )}
      </ButtonToolbarGroup>
    </div>
  );
};

export default React.memo(ConverterFloorsToolbarCmp);
