import * as React from 'react';
const PolicyCongestionIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PolicyCongestionIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65Zm-6.16,12.5l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z" />
    <g>
      <g>
        <rect
          width={11.3}
          height={11.3}
          x={58.35}
          y={97.25}
          transform="translate(-54.01 75.39) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={48.35}
          y={87.25}
          transform="translate(-49.87 65.39) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={38.35}
          y={77.25}
          transform="translate(-45.73 55.39) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={28.35}
          y={67.25}
          transform="translate(-41.59 45.39) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={18.35}
          y={57.25}
          transform="translate(-37.45 35.39) rotate(-45)"
        />
      </g>
      <g>
        <rect
          width={11.3}
          height={11.3}
          x={68.35}
          y={87.25}
          transform="translate(-44.01 79.53) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={58.35}
          y={77.25}
          transform="translate(-39.87 69.53) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={48.35}
          y={67.25}
          transform="translate(-35.73 59.53) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={38.35}
          y={57.25}
          transform="translate(-31.59 49.53) rotate(-45)"
        />
        <rect
          width={11.3}
          height={11.3}
          x={28.35}
          y={47.25}
          transform="translate(-27.45 39.53) rotate(-45)"
        />
      </g>
      <g>
        <g opacity={0.75}>
          <rect
            width={11.3}
            height={11.3}
            x={88.35}
            y={67.25}
            transform="translate(-24.01 87.82) rotate(-45)"
          />
        </g>
        <g opacity={0.75}>
          <rect
            width={11.3}
            height={11.3}
            x={78.35}
            y={57.25}
            transform="translate(-19.87 77.82) rotate(-45)"
          />
        </g>
        <g opacity={0.85}>
          <rect
            width={11.3}
            height={11.3}
            x={58.35}
            y={37.25}
            transform="translate(-11.59 57.82) rotate(-45)"
          />
        </g>
        <g opacity={0.85}>
          <rect
            width={11.3}
            height={11.3}
            x={48.35}
            y={27.25}
            transform="translate(-7.45 47.82) rotate(-45)"
          />
        </g>
      </g>
      <g>
        <g opacity={0.75}>
          <rect
            width={11.3}
            height={11.3}
            x={98.35}
            y={57.25}
            transform="translate(-14.01 91.96) rotate(-45)"
          />
        </g>
        <g opacity={0.75}>
          <rect
            width={11.3}
            height={11.3}
            x={88.35}
            y={47.25}
            transform="translate(-9.87 81.96) rotate(-45)"
          />
        </g>
        <g opacity={0.85}>
          <rect
            width={11.3}
            height={11.3}
            x={68.35}
            y={27.25}
            transform="translate(-1.59 61.96) rotate(-45)"
          />
        </g>
        <g opacity={0.85}>
          <rect
            width={11.3}
            height={11.3}
            x={58.35}
            y={17.25}
            transform="translate(2.55 51.96) rotate(-45)"
          />
        </g>
      </g>
    </g>
    <polygon
      points="92.07 42.98 84.08 34.99 64.02 54.93 44 34.91 36.01 42.9 56.01 62.9 64 70.89 84 90.89 91.99 82.9 72.01 62.92 92.07 42.98"
      opacity={0.6}
    />
  </svg>
);
export default PolicyCongestionIcon;
