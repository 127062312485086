import * as React from 'react';
const MenuBars3MediumIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuBars3MediumIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M105.43,87.21H22.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h82.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57ZM105.43,67.57H22.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h82.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57ZM105.43,47.93H22.57c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h82.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57Z"
    />
  </svg>
);
export default MenuBars3MediumIcon;
