import {
  LoadOptimizationDiffFullDocument,
  LoadOptimizationDiffFullQuery,
  LoadOptimizationDiffFullQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  optimisationAssignmentImplement,
  optimisationAssignmentImplementStatus,
} from '../store/optimisation.state';

export type LoadAssignmentImplementParams = {
  optimisationId: string;
  index: number;
};

function useLoadAssignmentImplement() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load implementation plan`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(optimisationAssignmentImplementStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentImplementParams) => {
        if (_.isNil(params.optimisationId)) {
          console.log(
            'Invalid parameter value: optimisationId:',
            params.optimisationId,
          );
          return null;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(optimisationAssignmentImplementStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadOptimizationDiffFullQuery,
          LoadOptimizationDiffFullQueryVariables
        >({
          query: LoadOptimizationDiffFullDocument,
          variables: {
            optimisationRunId: params.optimisationId,
            page: {
              limit: 1,
              skip: params.index,
            },
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const assignmentItems = data?.optimizationRun.assignmentDiff.items;
            const content = assignmentItems?.content ?? [];

            set(optimisationAssignmentImplement, {
              totalCount: assignmentItems?.totalCount ?? 0,
              content,
            });
            set(optimisationAssignmentImplementStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentImplementParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAssignmentImplement;
