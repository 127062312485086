import { ImportPipelineFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatDateTime, formatInteger } from '../common/formatHelper';
import { ColumnConfig } from '../components/DatasetTable';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import Tag from '../helpContext/Tag';
import { getImportConfigurations } from '../import/store/import.default';
import EmptyValueTag from '../simulation/tags/EmptyValueTag';
import { warehouseSelected } from '../store/warehouse.state';
import { WarehouseExtraSettings } from '../store/warehouse.types';
import { ImportPipelinesTableRow } from './store/importJobs.types';

type AppendTypeTagProps = {
  pipeline: ImportPipelineFragment;
};
function AppendTypeTag(props: AppendTypeTagProps) {
  const { t } = useTranslation('app');
  const { pipeline } = props;

  if (_.isNil(pipeline?.settings?.targetSettings?.appendSettings)) {
    return <Tag classType="bg-alerts-okay">{t`New`}</Tag>;
  }
  if (pipeline?.settings?.targetSettings?.appendSettings?.overwrite) {
    return <Tag classType="bg-alerts-warning">{t`Overwrite`}</Tag>;
  }

  return <Tag classType="bg-alerts-info">{t`Append`}</Tag>;
}

function useImportPipelinesTableConfig(): ColumnConfig<ImportPipelinesTableRow>[] {
  const { t } = useTranslation('app');
  const { t: tImporter } = useTranslation('importer');
  const wh = useRecoilValue(warehouseSelected);
  const extra: WarehouseExtraSettings = wh?.extraSettings;
  const importConfigurations = getImportConfigurations(tImporter);

  // const getImportTypeTitle = React.useCallback(
  //   (importType: string) => {
  //     const dataType = getImportTypeFromJobType(
  //       importType as ImportJobImportType,
  //     );
  //     const configuration = importConfigurations.find(
  //       config => config.dataType === dataType,
  //     );
  //     return configuration ? configuration.title : dataType || importType;
  //   },
  //   [importConfigurations],
  // );

  return [
    {
      field: 'enabled',
      title: t`Status`,
      render: (v, row) => {
        const isDefault = extra.defaultPipelines?.[row.importType] === row.id;
        return (
          <>
            <Tag classType={v ? 'bg-alerts-okay' : ''}>
              {v ? t`Enabled` : t`Disabled`}
            </Tag>
            {isDefault && <Tag classType="bg-alerts-info">{t`Default`}</Tag>}
          </>
        );
      },
    },
    {
      field: 'importType',
      title: t`Import type`,
      render: (v, row) => {
        // const title = getImportTypeTitle(v);
        return (
          <>
            <Tag>{v}</Tag> <AppendTypeTag pipeline={row} />
          </>
        );
      },
    },
    {
      field: 'createdAt',
      title: t`Date`,
      render: (v, row) => {
        const createdAt = new Date(row.createdAt);
        const updatedAt = new Date(row.updatedAt);
        const showUpdated = createdAt.getTime() !== updatedAt.getTime();

        return (
          <div className="space-y-1">
            <div className="text-xs" title={t`Created at`}>
              {formatDateTime(new Date(row.createdAt))}
            </div>
            {showUpdated && (
              <div className="text-xs" title={t`Updated at`}>
                {formatDateTime(new Date(row.updatedAt))}
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   field: 'createdAt',
    //   title: t`Created`,
    //   render: (v: number) => formatDateTime(new Date(v)),
    // },
    // {
    //   field: 'updatedAt',
    //   title: t`Updated`,
    //   render: (v: number) => formatDateTime(new Date(v)),
    // },
    {
      field: 'title',
      title: t`Title`,
    },
    {
      field: 'description',
      title: t`Description`,
    },
    {
      field: 'jobs.totalCount',
      title: t`Jobs`,
      render: value => {
        if (value === null || value === 0 || isNaN(value)) {
          return <EmptyValueTag />;
        } else {
          return <div className="">{formatInteger(value)}</div>;
        }
      },
    },
    {
      field: 'id',
      title: t`ID`,
      render: (v: string) => (
        <CopyToClipboardButton truncate title={v} value={v} />
      ),
    },
  ];
}

export default useImportPipelinesTableConfig;
