import {
  ItemSetIssueInvalidItemReason,
  ItemSetIssueInvalidUomReason,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import LayoutActiveFeatureTag from '../../layout/LayoutActiveFeatureTag';
import LayoutDataTable from '../../layout/LayoutDataTable';
import SimulationComplianceLayoutView from '../SimulationComplianceLayoutView';
import SimulationDataIssuesLocationLayer from '../layers/SimulationDataIssuesLocationLayer';
import AssignmentIssuesTable from '../panels/preAnalyze/AssignmentIssuesTable';
import { DataIssueGroup } from '../panels/preAnalyze/IssuesConfig';
import OrderLinesIssuesTable from '../panels/preAnalyze/OrderLinesIssuesTable';
import OrderedItemsReportTable from '../panels/preAnalyze/OrderedItemsReportTable';
import UOMIssuesTable from '../panels/preAnalyze/UOMIssuesTable';
import {
  simulationIssueSelected,
  simulationShowTable,
} from '../store/simulation.issues.state';
import { DataIssueReason } from '../store/simulation.issues.types';
import { simulationLayoutId } from '../store/simulation.state';

type IssueTableViewConfig = {
  group: DataIssueGroup;
  types?: DataIssueReason[];
  title: string;
  getTable?: () => React.ReactNode;
  hasLayoutView?: boolean;
};

const getTableConfigs = _.memoize(
  (t: TFunction<'simulation'>, layoutId: string) => {
    const configs: IssueTableViewConfig[] = [
      {
        group: 'layout',
        title: t`Layout issues`,
        getTable: () => <LayoutDataTable id={layoutId} hideScreenTitle />,
        hasLayoutView: true,
      },

      {
        group: 'assignment',
        title: t`Assignment issues`,
        getTable: () => <AssignmentIssuesTable />,
        hasLayoutView: true,
      },
      {
        group: 'orderSet',
        title: t`Order Set issues`,
        getTable: () => <OrderLinesIssuesTable />,
      },
      {
        group: 'orderedItems',
        title: t`Ordered quantity report`,
        getTable: () => <OrderedItemsReportTable />,
      },
      {
        group: 'itemSet',
        types: [
          ItemSetIssueInvalidUomReason.INVALID_VOLUME,
          ItemSetIssueInvalidUomReason.INVALID_WEIGHT,
        ],
        title: t`Item Set issues`,
        getTable: () => <UOMIssuesTable />,
      },
      {
        group: 'itemSet',
        types: [ItemSetIssueInvalidItemReason.NO_UOMS],
        title: t`Item Set issues`,
        getTable: () => <UOMIssuesTable />,
      },
    ];
    return configs;
  },
);

const SimulationIssuesView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const layoutId = useRecoilValue(simulationLayoutId);
  const [showTable, setShowTable] = useRecoilState(simulationShowTable);
  const [selectedDatasetIssue, setSelectedDatasetIssue] = useRecoilState(
    simulationIssueSelected,
  );
  const [noContentSelected, setNoContentSelected] = useState(false);

  const configs = getTableConfigs(t, layoutId);

  const contentItem = _.find(
    configs,
    c =>
      c.group === selectedDatasetIssue?.group &&
      (_.isNil(c.types) || _.includes(c.types, selectedDatasetIssue.reason)),
  );

  useEffect(() => {
    if (!contentItem) {
      setNoContentSelected(true);
    } else {
      setNoContentSelected(false);
    }
  }, [contentItem]);

  const viewAs = [
    {
      key: 'table',
      label: t`Table`,
    },
    {
      key: 'layout',
      label: t`Layout`,
    },
  ];
  const showTableValue = contentItem?.hasLayoutView ? showTable : 'table';

  const panelTitle = contentItem?.title ?? t`Data Stats`;

  return (
    <Container
      col
      overflow
      componentName={`SimulationIssuesViewContainer`}
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
      )}
    >
      <Container overflow className={classNames('relative !h-auto')}>
        <HeaderAppMenu title={panelTitle} className={'flex-1'}>
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          {contentItem && (
            <ButtonSwitchMulti
              autoSize
              className="mx-3"
              classNameLabel="text-xs py-1 px-2"
              buttonType="header"
              options={viewAs}
              selectedIndex={showTableValue ? 0 : 1}
              onClick={index => setShowTable(index === 0)}
              disabled={!contentItem?.hasLayoutView}
            />
          )}
        </HeaderAppMenu>
      </Container>
      {!_.isNil(contentItem) && showTableValue && contentItem.getTable()}
      {(_.isNil(contentItem) || !showTableValue) && (
        <Container
          overflow
          flex1
          className={classNames(
            'relative',
            'border-app-background/70 border-8',
          )}
        >
          <Container
            col
            overflow-hidden
            componentName={`SimulationLayoutViewContainer`}
            className={classNames('relative', 'flex-1')}
          >
            <SimulationComplianceLayoutView
              getLocationLayer={() => <SimulationDataIssuesLocationLayer />}
            />
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default SimulationIssuesView;
