import * as React from 'react';
const Mhe1BDollyManualFourWheeledIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe1BDollyManualFourWheeledIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M120.99,33.65c0-4.07-2.9-7.39-6.47-7.39H42.13c-3.57,0-6.47,3.31-6.47,7.39v57.68c0,1.06.76,1.92,1.69,1.92s1.69-.86,1.69-1.92v-57.68c0-1.95,1.39-3.53,3.09-3.53h72.39c1.71,0,3.09,1.58,3.09,3.53v57.68c0,1.06.76,1.92,1.69,1.92s1.69-.86,1.69-1.92v-57.67Z"
    />
    <path strokeWidth={0} d="M65.95,74.32h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M72.77,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M86.42,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M65.95,68.63h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M65.95,62.93h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M79.59,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M86.42,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M65.95,56.32h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M86.42,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M86.42,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M86.42,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,56.32h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M72.77,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,62.93h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M106.9,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M106.9,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M106.9,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,68.63h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M106.9,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,37.37h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,62.93h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,56.32h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,37.37h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M41.67,74.32h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,68.63h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,74.32h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M106.9,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,56.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,74.32h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,68.63h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,62.93h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M65.95,37.37h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M59.12,37.37h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M106.9,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,80.02h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,80.02h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M65.95,80.02h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M86.42,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,80.02h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M106.9,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,87.18h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,87.18h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M65.95,87.18h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M86.42,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,87.18h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M106.9,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,49.89h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,49.89h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M65.95,49.89h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M86.42,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,49.89h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M106.9,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M41.67,42.64h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M113.73,42.64h1.27v.48h-1.27v-.48Z" />
    <path strokeWidth={0} d="M65.95,42.64h4.29v.48h-4.29v-.48Z" />
    <path strokeWidth={0} d="M86.42,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M79.59,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M93.24,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M45.47,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M72.77,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M52.29,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M59.12,42.64h4.3v.48h-4.3v-.48Z" />
    <path strokeWidth={0} d="M100.07,42.64h4.3v.48h-4.3v-.48Z" />
    <path
      strokeWidth={0}
      d="M65.1,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M51.45,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M58.28,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M78.33,32.53c-.23,0-.42.22-.42.48v59.77h.84v-59.77c0-.27-.19-.48-.42-.48h0Z"
    />
    <path
      strokeWidth={0}
      d="M44.62,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M85.16,32.53c-.23,0-.42.22-.42.48v59.77h.85v-59.77c0-.27-.19-.48-.42-.48Z"
    />
    <path
      strokeWidth={0}
      d="M71.5,32.53c-.23,0-.42.22-.42.48v59.77h.85v-59.77c0-.27-.19-.48-.42-.48Z"
    />
    <path
      strokeWidth={0}
      d="M99.23,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M112.88,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M106.05,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path
      strokeWidth={0}
      d="M92.4,92.73v-59.71c0-.27-.19-.48-.42-.48s-.42.22-.42.48v59.71c0,.27.19,0,.42,0s.42.27.42,0Z"
    />
    <path strokeWidth={0} d="M34.2,95.8h89.29v5.59H34.2v-5.59Z" />
    <path
      strokeWidth={0}
      d="M38.8,118.03c0,1.32,1.07,2.38,2.39,2.38h3.38l12.82-17.23h-18.58v14.84ZM42.23,116c.79,0,1.44.64,1.44,1.43s-.64,1.44-1.44,1.44-1.44-.64-1.44-1.44.65-1.43,1.44-1.43Z"
    />
    <path
      strokeWidth={0}
      d="M48.7,117.11c0,.11.02.21.02.32,0,3.58-2.91,6.49-6.49,6.49s-6.49-2.9-6.49-6.49c0-1.86.78-3.53,2.03-4.71v-2.82c-2.57,1.53-4.3,4.32-4.3,7.52,0,3.86,2.51,7.14,5.98,8.3h5.55c3.47-1.16,5.98-4.43,5.98-8.3,0-.9-.13-1.75-.38-2.57l-1.9,2.25Z"
    />
    <path
      strokeWidth={0}
      d="M118.04,103.19h-18.58s12.82,17.23,12.82,17.23h3.38c1.32,0,2.39-1.07,2.39-2.38v-14.84ZM116.06,117.43c0,.8-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44c0-.8.64-1.43,1.44-1.43s1.44.64,1.44,1.43Z"
    />
    <path
      strokeWidth={0}
      d="M108.15,117.11c0,.11-.02.21-.02.32,0,3.58,2.91,6.49,6.49,6.49s6.49-2.9,6.49-6.49c0-1.86-.78-3.53-2.03-4.71v-2.82c2.57,1.53,4.3,4.32,4.3,7.52,0,3.86-2.51,7.14-5.98,8.3h-5.55c-3.47-1.16-5.98-4.43-5.98-8.3,0-.9.13-1.75.38-2.57l1.9,2.25Z"
    />
    <path
      strokeWidth={0}
      d="M60.03,96.64H19.93c-.31-.92-1.07-1.45-2.29-1.6v-58.89h3.67c1.22-.2,2.21-.81,2.98-1.82s1.15-2.33,1.15-3.95V14.25c0-1.62-.38-2.94-1.15-3.95s-1.76-1.52-2.98-1.52h-5.96v87.41c-.46.46-.69.92-.69,1.37.15,1.68,1.07,2.6,2.75,2.75.46,0,.92-.15,1.37-.46h43.53l-2.29-3.21ZM17.87,10.29h3.44c1.68.2,2.6,1.42,2.75,3.65v16.42c-.15,2.23-1.07,3.45-2.75,3.65h-3.44V10.29Z"
    />
  </svg>
);
export default Mhe1BDollyManualFourWheeledIcon;
