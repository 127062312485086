import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  PRIVACY_POLICY_VERSION,
  TERMS_POLICY_VERSION,
} from '../common/constants';
import { HelperMessage } from '../components/HelperMessage';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { ItemListCard } from '../dashboard/containers/ItemListCard';
import { loggedInUser } from '../store/auth.state';
import { collapsibleState } from '../store/collapsible.state';
import { warehouseIsDemo, warehouseSelected } from '../store/warehouse.state';
import useUpdateUserSettings from '../warehouse/hooks/useUpdateUserSettings';
import { TrialHelperMessage } from './TrialHelperMessage';

export type HeroDemoProps = {
  children?: React.ReactNode;
  active?: boolean;
};

export const HeroDemo = ({ children, active }: HeroDemoProps) => {
  const { t } = useTranslation('app');

  const [id] = useState<string>(_.uniqueId());
  const [stateDemo, setStateDemo] = useRecoilState(
    collapsibleState('subscription-panel-demo'),
  );

  // User's Details
  const user = useRecoilValue(loggedInUser);
  const firstName = user.firstName;

  const isDemo = useRecoilValue(warehouseIsDemo);
  const warehouse = useRecoilValue(warehouseSelected);

  const configure = useUpdateUserSettings();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isClosedDemo = stateDemo.isCollapsed;
  const toggleOpenDemo = () => {
    setStateDemo({
      isCollapsed: !isClosedDemo,
    });
  };

  async function completeConfiguration(whId?: string) {
    await configure({
      configured: true,
      consents: {
        privacyPolicy: {
          version: PRIVACY_POLICY_VERSION,
        },
        termsPolicy: {
          version: TERMS_POLICY_VERSION,
        },
      },
    });
    navigate(whId ? `/wh/i/${whId}` : '/wh/new');
  }

  return (
    <ItemListCard
      id={id}
      data-component="HeroDemo"
      titleTrace={`Hero Demo`}
      isHero
      fullWidth
      className="flex-none"
    >
      <div
        className={classNames(
          'flex flex-col-reverse md:flex-row md:items-start',
        )}
      >
        <div className={classNames('flex-1 text-2xl', 'text-menu-active-text')}>
          {t`Hello,`}
          <span className={classNames('mx-1 font-bold')}>{firstName}</span>
          {t`you are currently in a`}
          <span className={classNames('mx-1 font-bold')}>
            {warehouse?.title}
          </span>
          {t`Warehouse`}
          {/* {t(
                `Hello {{firstName}} you are currently in a {{warehouseTitle}} Warehouse`,
                {
                  firstName,
                  warehouseTitle: warehouse?.title,
                },
              )} */}
        </div>
        <Button
          className={classNames('border-app-panel-dark rounded-full border')}
          label={isClosedDemo ? t`Start Trial` : t`Later`}
          buttonSize="sm"
          buttonType={`${isClosedDemo ? 'primary' : 'primary'}`}
          isDisabled={false}
          hasIconAfter
          buttonIcon={
            isClosedDemo ? (
              <Icon.CircleArrowRight
                className={classNames('h-5 w-5 fill-current')}
              />
            ) : (
              <Icon.Close className={classNames('h-4 w-4 fill-current')} />
            )
          }
          onPress={toggleOpenDemo}
        />
      </div>
      <Transition
        show={!isClosedDemo}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <div className={classNames('mt-8 flex flex-wrap items-center')}>
          <HelperMessage className={classNames('m-4', 'p-3', 'flex-1')}>
            <div className={classNames('text-xl', 'text-menu-active-text')}>
              <Icon.HelpBot
                className={classNames('h-16 w-16', 'fill-current')}
              />
              <div className={classNames('mt-8')}>
                {t`Hello, You are in a Demo Warehouse\n Explore the Simulation below to get started.`}
                <Icon.ArrowBottom
                  className={classNames(
                    'ltr:ml-2 rtl:mr-2',
                    'h-6 w-6 fill-current',
                    'inline',
                  )}
                />
              </div>
            </div>
          </HelperMessage>
          <TrialHelperMessage
            classNameMessage={classNames('text-menu-active-text')}
          />
        </div>
      </Transition>
    </ItemListCard>
  );
};
