import _ from 'lodash';
import React, { Fragment, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { StatListItem } from '../../components/stats/StatListItem';
import Tooltip from '../../layout/tooltip/ToolTip';
import HtmlTooltipLayer from '../../layout/viewer/layers/HtmlTooltipLayer';
import { viewerHoveredLocation } from '../../layout/viewer/store/viewer.state';
import useActualityHeatmapItems from '../hooks/useActualityHeatmapItems';

const FeedLayoutTooltip: React.FC = () => {
  const { t } = useTranslation('feed');
  const location = useRecoilValue(viewerHoveredLocation);
  const { getLocationItems } = useActualityHeatmapItems();

  const items = getLocationItems(location?.locationId);
  const hasItems = !_.isEmpty(items);

  return (
    <HtmlTooltipLayer>
      <Suspense>
        {!_.isEmpty(location) && (
          <div className="text-menu-text bg-app-panel-dark">
            {hasItems && <Tooltip label={t`Item`} />}
            {_.map(items, item => (
              <Fragment key={item.skuKey}>
                <Tooltip value={item.itemRanked?.sku} />
                <StatListItem
                  title={item.metricDescriptor.title}
                  value={item.heatmapValue}
                  valueStyle={{
                    color: item.bucket?.color,
                    borderColor: item.bucket?.color,
                    borderWidth: item.bucket?.color ? 1 : 0,
                  }}
                />
              </Fragment>
            ))}
          </div>
        )}
      </Suspense>
    </HtmlTooltipLayer>
  );
};
export default FeedLayoutTooltip;
