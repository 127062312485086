import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { optimisationAnalyzeId } from '../../store/optimisation.state';
import { simulationHeatmapLocationByBay } from '../../store/simulation.heatmap.state';
import SimulationBayLocationLayerBase from './SimulationBayLocationLayerBase';

const OptimisationBayLocationLayer: React.FC = () => {
  const analyzeId = useRecoilValue(optimisationAnalyzeId);
  const heatmapByLocLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByBay(analyzeId),
  );

  const heatmapByLoc =
    heatmapByLocLoadable.state === 'hasValue'
      ? heatmapByLocLoadable.contents
      : null;
  return <SimulationBayLocationLayerBase heatmapByLoc={heatmapByLoc} />;
};
export default OptimisationBayLocationLayer;
