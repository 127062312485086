import { WaypointPolicyRuleTerminalPoints } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../common/color.helper';
import SectionOptional from '../components/layout/SectionOptional';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import {
  waypointPolicyRule,
  waypointPolicySelectedIdentity,
} from '../simulation/store/waypointPolicy/waypointPolicy.state';
import { WaypointPolicyGroupType } from '../simulation/store/waypointPolicy/waypointPolicy.types.';
import { sidebarStateByType } from '../store/sidebar.state';
import WaypointPolicyLocationsStats from './WaypointPolicyLocationsStats';

export type WaypointPolicyRuleSectionProps = {
  ruleId: string;
  groupType: WaypointPolicyGroupType;
  title: string;
};

const WaypointPolicyRuleSection: React.FC<
  WaypointPolicyRuleSectionProps
> = props => {
  const { t } = useTranslation('simulation');
  const [rule, updateRule] = useRecoilState(waypointPolicyRule(props.ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    waypointPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-routing-policy-editor'),
  );
  const canUpdate = useRecoilValue(simulationIsEditable);

  const { ruleId } = props;
  const settings =
    props.groupType === 'start' ? rule.startPoints : rule.endPoints;
  const filterConfig = getLocationFilterConfigCommon(t);

  function updateWaypointRule(newSettings: WaypointPolicyRuleTerminalPoints) {
    updateRule({
      ...rule,
      startPoints: props.groupType === 'start' ? newSettings : rule.startPoints,
      endPoints: props.groupType === 'end' ? newSettings : rule.endPoints,
    });
  }

  function addFilterSet() {
    const id = nanoid();
    const newSettings: WaypointPolicyRuleTerminalPoints = {
      locationsMatch: {
        anyOf: [...(settings?.locationsMatch?.anyOf ?? []), { id, allOf: [] }],
      },
    };
    updateWaypointRule(newSettings);
    setSelectedIdentity({
      ruleId: ruleId,
      groupType: props.groupType,
      locationFilterId: id,
    });

    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? false
        : policyEditPanelState.isCollapsed,
      isHidden: false,
    });
  }

  const removeField = (groupId: string, fieldId: string) => {
    const newSettings = {
      ...settings,
      locationsMatch: {
        anyOf: _.map(settings.locationsMatch.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    };
    updateWaypointRule(newSettings);
  };

  const removeFilterSet = (groupId: string) => {
    const newSettings: WaypointPolicyRuleTerminalPoints = {
      ...settings,
      locationsMatch: {
        anyOf: _.filter(
          settings.locationsMatch?.anyOf,
          fg => fg.id !== groupId,
        ),
      },
    };
    updateWaypointRule(newSettings);
  };

  function selectFilterSet(id: string, shouldSelect: boolean) {
    setSelectedIdentity(
      shouldSelect
        ? {
            ruleId,
            groupType: props.groupType,
            locationFilterId: id,
          }
        : null,
    );
    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? !shouldSelect
        : policyEditPanelState.isCollapsed,
      isHidden: !shouldSelect,
    });

    // trigger filter to refresh
    // if (shouldSelect) {
    //   filterConfig.forEach(configItem => {
    //     const keyParts = getPolicyFilterDataKeysByEditorType(
    //       configItem.editorType,
    //     );

    //     keyParts.forEach(key => {
    //       const filterKey = getPolicyFilterKey(configItem.type, key);
    //       cancelLoadLocationValues(filterKey);
    //       loadLocationValues({
    //         field: configItem.type,
    //         filterKey,
    //         filterIntersection: null,
    //       });
    //     });
    //   });
    // }
  }
  const isSelected =
    ruleId === selectedIdentity?.ruleId &&
    props.groupType === selectedIdentity.groupType;

  return (
    <SectionOptional
      id={'waypoint-policy-section'}
      title={props.title}
      value={!_.isNil(settings)}
      onChange={enabled =>
        updateWaypointRule(enabled ? { locationsMatch: null } : null)
      }
      isDisabled={!canUpdate}
      hasInnerPadding={false}
    >
      <RuleSectionContainer
        isSelected={isSelected}
        transparent
        color={
          getQualitativeColor(
            props.ruleId,
            props.groupType === 'start' ? 'waypointStart' : 'waypointEnd',
          )[0]
        }
      >
        <WaypointPolicyLocationsStats
          ruleId={ruleId}
          groupType={props.groupType}
        />
        <PolicyFilterGroupList
          filterSets={_.map(settings?.locationsMatch?.anyOf, filterGroup => ({
            id: filterGroup.id,
            allOf: [...filterGroup.allOf],
          }))}
          isDisabled={!canUpdate}
          config={filterConfig}
          selectedId={selectedIdentity?.locationFilterId}
          onAddGroup={addFilterSet}
          onDeleteField={removeField}
          onSelectGroup={selectFilterSet}
          onDeleteGroup={removeFilterSet}
        />
      </RuleSectionContainer>
    </SectionOptional>
  );
};

export default WaypointPolicyRuleSection;
