import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { cn } from '../../common/utils';

export type ContainerProps = React.HtmlHTMLAttributes<HTMLFormElement>;
export type ContainerFlexProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  componentName?: string;
  overflow?: boolean;
  fullWidth?: boolean;
  halfWidth?: boolean;
  fullHeight?: boolean;
  flex1?: boolean;
  row?: boolean;
  col?: boolean;
  hasExtraPadding?: boolean;
  hasOverflowX?: boolean;
  hasOverflowY?: boolean;
  hasNoScroll?: boolean;
  hidden?: boolean;
};

export const Container = ({
  children,
  className,
  overflow,
  fullWidth = true,
  fullHeight = true,
  flex1,
  componentName,
  col,
  row,
  hasExtraPadding,
  hasOverflowX,
  hasOverflowY,
  hasNoScroll,
  hidden,
}: ContainerFlexProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component={`Container${componentName ? '-' + componentName : ''}`}
      className={classNames(
        { hidden: hidden },
        { flex: !hidden },
        { 'flex-col': col },
        { 'flex-row': row },
        { 'flex-1': flex1 },
        { 'h-full': fullHeight },
        { 'w-full': fullWidth },
        { 'overflow-auto': overflow },
        { 'overflow-hidden': !overflow },
        { 'overflow-hidden': hasNoScroll },
        { 'overflow-x-auto overflow-y-hidden': hasOverflowX },
        { 'overflow-y-auto overflow-x-hidden': hasOverflowY },
        { 'pb-10 xl:pb-12': hasExtraPadding },
        // hasNoScroll ? 'overflow-hidden' : 'overflow-auto',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const ContainerForm = ({
  children,
  className,
  overflow,
  fullWidth,
  fullHeight,
  flex1,
  componentName,
  ...formProps
}: ContainerFlexProps) => {
  return (
    <form
      data-component={`ContainerForm${
        componentName ? '-' + componentName : ''
      }`}
      {...formProps}
      className={cn(
        'flex flex-col overflow-hidden',
        flex1 ? 'flex-1' : '',
        fullWidth ? 'w-full' : '',
        fullHeight ? 'h-full' : '',
        overflow ? '' : 'overflow-hidden',
        className,
      )}
    >
      {children}
    </form>
  );
};

export const ContainerCol = ({
  children,
  className,
  overflow,
  fullWidth = true,
  fullHeight = true,
  flex1,
  componentName,
}: ContainerFlexProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component={`ContainerCol${componentName ? '-' + componentName : ''}`}
      className={cn(
        'flex flex-col',
        { 'flex-1': flex1 },
        { 'h-full': fullHeight },
        { 'w-full': fullWidth },
        { 'overflow-hidden': overflow },
        className,
      )}
    >
      {children}
    </div>
  );
};

export const ContainerRow = ({
  children,
  className,
  overflow,
  fullWidth = true,
  fullHeight = true,
  flex1,
  componentName,
}: ContainerFlexProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component={`ContainerRow${componentName ? '-' + componentName : ''}`}
      className={cn(
        'flex',
        { 'flex-1': flex1 },
        { 'h-full': fullHeight },
        { 'w-full': fullWidth },
        { 'overflow-hidden': overflow },
        className,
      )}
    >
      {children}
    </div>
  );
};

export const ContainerTag = ({
  children,
  className,
  componentName,
  style,
}: ContainerFlexProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      style={style}
      data-component={`ContainerTag${componentName ? '-' + componentName : ''}`}
      className={cn('flex items-center', 'rounded-md px-1 py-0.5', className)}
    >
      {children}
    </div>
  );
};

export const ContainerHalfPromo = ({
  children,
  className,
  componentName,
  style,
}: ContainerFlexProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <Container
      componentName="HalfPromo"
      col
      className={cn(
        'overflow-hidden',
        'hidden md:flex md:w-1/2',
        'bg-brand text-menu-active-text',
      )}
    >
      {children}
    </Container>
  );
};
