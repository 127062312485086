import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Button } from '../../../../components/actions/Button';
import * as Icon from '../../../../components/icons';
import { CONVERTER_SAVE_PREPARE_ID } from '../store/converter.defaults';
import { converterLayoutIdSaving } from '../store/converter.state';
import useSaveLayoutImport from '../hooks/useSaveLayoutImport';

export const ConverterSavePanel: React.FC = () => {
  const { t } = useTranslation('designer');
  const [savingLayoutId, setSavingLayoutId] = useRecoilState(
    converterLayoutIdSaving,
  );
  const save = useSaveLayoutImport();

  return (
    <Button
      label={t`Finish Layout Design`}
      full
      hasIconAfter
      buttonIcon={<Icon.CircleArrowRight className={`w-6 h-6 fill-current`} />}
      buttonType={'primary'}
      isDisabled={!_.isNil(savingLayoutId)}
      isLoading={!_.isNil(savingLayoutId)}
      onPress={() => {
        setSavingLayoutId(CONVERTER_SAVE_PREPARE_ID);
        save();
      }}
    />
  );
};

export default ConverterSavePanel;
