import {
  OrderedQuantityItemsFragment,
  OrderedQuantityReportSummaryFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { OrderedItemsDataRow } from '@warebee/shared/export-converter';
import { atom } from 'recoil';
import { AsyncLoadStatus, DatasetTableState } from '../../common/types';

const getKey = (postfix: string) => `warebee-simulation-report-${postfix}`;

export const orderedQuantityReport = atom<OrderedQuantityReportSummaryFragment>(
  {
    key: getKey('ordered-quantity'),
    default: null,
  },
);

export const orderedQuantityReportLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('ordered-quantity-load-status'),
  default: AsyncLoadStatus.None,
});

export const orderedQuantityItems = atom<OrderedQuantityItemsFragment>({
  key: getKey('ordered-quantity-items'),
  default: null,
});

export const orderedQuantityItemsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('ordered-quantity-items-load-status'),
  default: AsyncLoadStatus.None,
});

export const orderedQuantityTableState = atom<
  DatasetTableState<keyof OrderedItemsDataRow>
>({
  key: getKey('table-state'),
  default: {
    searchValues: {},
    sortValues: {
      orderedQuantity: SortDirection.DESC,
    },
  },
});
