import * as React from 'react';
const AngleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AngleIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M116,117H12c-1.66,0-3-1.34-3-3s1.34-3,3-3h104c1.66,0,3,1.34,3,3s-1.34,3-3,3Z"
    />
    <line
      x1={11.23}
      x2={84.77}
      y1={112.77}
      y2={39.23}
      fill="none"
      strokeWidth={0}
    />
    <path
      strokeWidth={0}
      d="M11.23,115.77c-.77,0-1.54-.29-2.12-.88-1.17-1.17-1.17-3.07,0-4.24L82.65,37.11c1.17-1.17,3.07-1.17,4.24,0,1.17,1.17,1.17,3.07,0,4.24L13.35,114.89c-.59.59-1.35.88-2.12.88Z"
    />
    <path
      fill="none"
      strokeWidth={0}
      d="M52.72,71.28c10.68,10.68,17.28,25.43,17.28,41.72"
    />
    <path
      strokeWidth={0}
      d="M70,116c-1.66,0-3-1.34-3-3,0-14.96-5.82-29.02-16.4-39.6-1.17-1.17-1.17-3.07,0-4.24,1.17-1.17,3.07-1.17,4.24,0,11.71,11.71,18.16,27.28,18.16,43.84,0,1.66-1.34,3-3,3Z"
    />
  </svg>
);
export default AngleIcon;
