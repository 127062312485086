import * as React from 'react';
const Warehouse1Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Warehouse1Icon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64 29.33 6.24 68.05 6.25 76.14 64 36.99 121.76 76.14 121.76 68.03 64 29.33"
    />
    <polygon
      strokeWidth={0}
      points="9.57 76.2 9.57 106.04 41.61 106.04 41.61 76.64 86.28 76.64 86.28 106.04 118.34 106.04 118.34 76.2 63.96 39.44 9.57 76.2"
    />
    <rect width={40.48} height={2.65} x={43.71} y={78.61} strokeWidth={0} />
    <rect width={40.48} height={2.65} x={43.71} y={82.74} strokeWidth={0} />
    <rect width={40.48} height={2.65} x={43.71} y={86.86} strokeWidth={0} />
    <rect width={40.48} height={2.65} x={43.71} y={91.09} strokeWidth={0} />
    <rect width={40.48} height={2.65} x={43.71} y={95.22} strokeWidth={0} />
    <rect width={40.48} height={2.65} x={43.71} y={99.34} strokeWidth={0} />
    <rect width={40.48} height={2.65} x={43.71} y={103.47} strokeWidth={0} />
  </svg>
);
export default Warehouse1Icon;
