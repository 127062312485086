import * as React from 'react';
const WarehouseDemoIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseDemoIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="64.5 27.76 2.84 44.19 2.84 49.21 64.5 32.78 125.16 49.21 125.16 44.19 64.5 27.76"
    />
    <path
      strokeWidth={0}
      d="M57.96,85.47v2.38c0,2.4.78,3.42,2.46,3.42s2.48-1.02,2.48-3.42v-2.38c0-2.4-.8-3.42-2.48-3.42s-2.46,1.02-2.46,3.42Z"
    />
    <path
      strokeWidth={0}
      d="M4.84,50.66v49.58h73.64v-2.97h38.48v2.97h6.21v-49.58l-58.66-15.43L4.84,50.66ZM78.48,72.41h38.48v2.65h-38.48v-2.65ZM78.48,76.53h38.48v2.65h-38.48v-2.65ZM78.48,68.28h38.48v2.65h-38.48v-2.65ZM78.48,80.66h38.48v2.65h-38.48v-2.65ZM78.48,84.89h38.48v2.65h-38.48v-2.65ZM78.48,89.01h38.48v2.65h-38.48v-2.65ZM78.48,93.14h38.48v2.65h-38.48v-2.65ZM24.5,87.96c0,4.22-2.4,6.32-6.38,6.32-1.66,0-3.44-.16-4.26-.3-.38-.06-.58-.24-.58-.7v-13.22c0-.46.2-.64.58-.7.82-.14,2.6-.3,4.26-.3,3.98,0,6.38,2.1,6.38,6.32v2.58ZM36,81.57c0,.22-.18.4-.4.4h-4.06c-.94,0-1.28.32-1.28,1.36v1.82h5.18c.22,0,.4.18.4.4v1.9c0,.22-.18.4-.4.4h-5.18v2.12c0,1.04.34,1.36,1.28,1.36h4.06c.22,0,.4.18.4.4v1.86c0,.26-.12.38-.4.42-.7.1-1.72.26-3.88.26-2.58,0-4.86-.66-4.86-4.3v-6.64c0-3.64,2.3-4.28,4.88-4.28,2.16,0,3.16.16,3.86.26.28.04.4.14.4.4v1.86ZM52.04,93.82c0,.22-.18.28-.34.28h-2.64c-.18,0-.32-.18-.32-.4v-8.34c0-.14-.06-.16-.1-.16-.06,0-.1.04-.16.14l-1.8,3.3c-.14.24-.34.34-.6.34h-1.6c-.26,0-.46-.1-.6-.34l-1.8-3.3c-.06-.1-.1-.14-.16-.14-.04,0-.1.02-.1.16v8.34c0,.22-.14.4-.32.4h-2.64c-.16,0-.34-.06-.34-.28v-13.9c0-.42.3-.68.72-.68h1.88c.42,0,.7.2.88.54l3.06,5.7c.08.16.14.26.22.26s.14-.1.22-.26l3.06-5.7c.18-.34.46-.54.88-.54h1.88c.42,0,.72.28.72.7v13.88ZM66.34,85.47v2.38c0,4.64-2.62,6.42-5.92,6.42s-5.92-1.78-5.92-6.42v-2.38c0-4.64,2.62-6.42,5.92-6.42s5.92,1.78,5.92,6.42Z"
    />
    <path
      strokeWidth={0}
      d="M18.12,81.95c-.5,0-1.14.02-1.42.04v9.34c.28.02.92.04,1.42.04,1.8,0,2.96-.72,2.96-3.42v-2.58c0-2.7-1.16-3.42-2.96-3.42Z"
    />
  </svg>
);
export default WarehouseDemoIcon;
