import * as React from 'react';
const ShowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ShowIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M124.83,61c-1.17-1.17-27.54-28.85-60.83-28.85S4.35,59.82,3.17,61c-1.57,1.7-1.57,4.31,0,6,1.17,1.17,27.54,28.85,60.83,28.85s59.65-27.67,60.83-28.85c1.57-1.7,1.57-4.31,0-6ZM64,85.8c-12.01,0-21.8-9.79-21.8-21.8s9.79-21.8,21.8-21.8,21.8,9.79,21.8,21.8-9.79,21.8-21.8,21.8Z"
    />
    <circle cx={64} cy={64} r={12.66} strokeWidth={0} />
  </svg>
);
export default ShowIcon;
