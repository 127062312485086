import * as React from 'react';
const WarehouseBoxAddIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseBoxAddIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M36.29,64.27v32l27.71,16,27.71-16v-32l-27.71-16-27.71,16ZM66.21,66.99v11.03h11.59c1.22,0,2.21.99,2.21,2.21s-.99,2.21-2.21,2.21h-11.59v12.14c0,1.22-.99,2.21-2.21,2.21s-2.21-.99-2.21-2.21v-12.14h-11.59c-1.22,0-2.21-.99-2.21-2.21s.99-2.21,2.21-2.21h11.59v-11.03c0-1.22.99-2.21,2.21-2.21s2.21.99,2.21,2.21Z"
    />
    <polygon
      strokeWidth={0}
      points="64 27.22 5.2 41.26 5.2 98.16 31.96 98.16 31.96 61.77 64 43.27 96.04 61.77 96.04 98.16 122.8 98.16 122.8 41.26 64 27.22"
    />
  </svg>
);
export default WarehouseBoxAddIcon;
