import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../common/formatHelper';
import EmptyValueTag from '../simulation/tags/EmptyValueTag';
import * as Icon from './icons';
import { UnitOfMeasure } from './stats/UnitOfMeasure';

export type FormattedValueProps = {
  value: FormattedValueWithUnit;
  className?: string;
  hasIssue?: boolean;
  hasIssueHelper?: string;
};

const FormattedValue: React.FC<FormattedValueProps> = props => {
  const { t } = useTranslation('app');
  const { value, raw: unformattedValue, unit } = props.value;

  // value === 0 || isNaN(value.value)
  if (value === null || unformattedValue === 0 || isNaN(unformattedValue)) {
    return <EmptyValueTag />;
  }

  return (
    <span
      data-component="FormattedValue"
      aria-label="FormattedValue"
      className={classNames('flex items-baseline', props.className)}
      title={t(`(RAW):{{unformattedValue}}`, { unformattedValue })}
    >
      {props.hasIssue && (
        <Icon.TriangleInfo
          className={'text-compliance-nonCompliant mx-1 h-3 w-3 fill-current'}
        />
      )}
      <span>{value}</span>
      {!_.isNil(unit) && (
        <UnitOfMeasure classSize="text-xs" unitOfMeasure={unit} />
      )}
    </span>
  );
};
export default FormattedValue;
