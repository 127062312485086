export interface CalculateAllocationRequirementsInput {
  allocationRequirementSetId: string;
}

export enum AllocateJobEngine {
  LAMBDA = 'LAMBDA',
  FARGATE = 'FARGATE',
}

export interface StartAllocationInput {
  allocationRunId: string;
  engine?: AllocateJobEngine;
}
