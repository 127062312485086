import * as React from 'react';
const Mhe5BWalkiePlatformTruckElectricIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe5BWalkiePlatformTruckElectricIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M110.38,120.19h-42.86V2.03H16.07c-1.37-.16-2.34.4-2.91,1.69-.16,1.45.4,2.42,1.69,2.91.4.16.81.16,1.21,0h34.38c1.29,0,2.44.3,3.45.91,1.01.61,1.8,1.47,2.36,2.6l4.24,9.44v72.4h-1.94v-18.4c-.08-3.15-1.17-5.77-3.27-7.87-2.1-2.1-4.76-3.19-7.99-3.27h-29.66c-1.05,0-1.94.36-2.66,1.09-.73.73-1.09,1.61-1.09,2.66v55.09c.08,1.37.56,2.5,1.45,3.39s2.02,1.33,3.39,1.33h91.65c.81,0,1.51-.28,2.12-.85s.89-1.27.85-2.12c-.04-.85-.34-1.53-.91-2.06-.56-.52-1.25-.79-2.06-.79h0ZM114.5,84.59h-41.28v-16.47h-3.75v20.1h38.02c1.05,0,2.02-.2,2.91-.61l4.24-2.06c.24-.16.3-.36.18-.61s-.2-.36-.24-.36h-.06ZM43.92,53.84h-18.52c-.73,0-1.31.22-1.76.67s-.67.99-.67,1.63v4.96h23.12v-5.08c0-.56-.2-1.05-.61-1.45-.4-.4-.93-.65-1.57-.73h0Z"
    />
  </svg>
);
export default Mhe5BWalkiePlatformTruckElectricIcon;
