import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import * as Icon from './icons';

export type WarehouseNavigatorItemProps = {
  className?: string;
  children?: React.ReactNode;
  active?: boolean;
  name?: string;
  value?: string | number;
  selectable?: boolean;
  isNavigable?: boolean;
  isFlexWidth?: boolean;
  isRoute?: boolean;
};

export const WarehouseNavigatorItem = ({
  className,
  children,
  active,
  name,
  value,
  selectable,
  isNavigable,
  isFlexWidth,
  isRoute,
}: WarehouseNavigatorItemProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="WarehouseNavigatorItem"
      className={classNames('flex flex-1 items-center')}
    >
      <div className="flex flex-col flex-1">
        <div
          className={classNames(
            'flex-1 items-center uppercase text-xs text-center m-0 p-1',
            { 'bg-menu-active text-menu-active-text': active },
            { 'bg-app-panel-dark text-menu-active': !active },
            className,
          )}
        >
          <span className="text-bold select-text">{name}</span>
        </div>
        <div
          className={classNames(
            'flex-1 flex items-center text-center m-0 p-1',
            { 'bg-app-panel-dark text-menu-active': active },
            { 'bg-menu-100 text-menu-text': !active },
            className,
          )}
        >
          <span className={`flex-1 text-lg truncate text-bold select-text`}>
            {value}
          </span>
          {selectable && <Icon.ChevronDown className="w-8 h-8" />}
        </div>
      </div>
    </div>
  );
};
