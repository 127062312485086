import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import * as Icon from '../components/icons';

export type EventNotificationVideoProps = PropsWithChildren & {
  className?: string;
  title?: string;
  time?: string;
  iframeSrc?: string;
  alertSource?: string;
  alertCategory?: string;
  alertClassification?: string;
  alertConfidence?: string;
};

const EventNotificationVideo: React.FC<EventNotificationVideoProps> = props => {
  return (
    <div
      data-component="EventNotificationVideo"
      className={classNames('p-2 bg-app-panel-dark/60', props.className)}
    >
      <div
        className={classNames(
          'flex items-center p-2  mb-4',
          props.alertCategory === 'Unsafe Movement'
            ? 'text-alerts-warning'
            : props.alertCategory === 'Non Compliance'
              ? 'text-alerts-compliant'
              : 'text-menu-active-cctv',
        )}
      >
        {props.alertCategory === 'Unsafe Movement' ? (
          <Icon.CircleEyeSide className={`w-8 h-8 fill-current`} />
        ) : props.alertCategory === 'Non Compliance' ? (
          <Icon.AlertNotificationOn className={`w-8 h-8 fill-current`} />
        ) : (
          <Icon.CircleInfo className={`w-8 h-8 fill-current`} />
        )}

        <div className="ltr:ml-3 rtl:mr-3">
          <div className="text-xs opacity-75 text-white">{props.time}</div>
          <div className="text-lg">{props.title}</div>
        </div>
      </div>
      <iframe
        // src={`https://www.youtube.com/embed/XT5QuMvg5xc?controls=0?modestbranding=1&showinfo=0`}
        src={props.iframeSrc}
        width="100%"
        height="215"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
        allowFullScreen
      ></iframe>
      {/* <div className="flex items-center p-2 mt-2">
        <div className="flex-1 text-menu-active">
          <span className="text-xxs uppercase mr-2">{`Aisle:`}</span>
          <span className="text-xs">{`AM`}</span>
        </div>
        <div className="flex-1">
          <span className="text-xxs uppercase mr-2">{`CCTV ID:`}</span>
          <span className="text-xs">{`AM-1`}</span>
        </div>
      </div> */}

      {props.children}
    </div>
  );
};

export default EventNotificationVideo;
