import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type SpacerProps = {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  flexspace?: boolean;
  hasHorizontalRule?: boolean;
  fullWidth?: boolean;
  noMarginRule?: boolean;
  panelMode?: boolean;
  dashboardMode?: boolean;
};

export const Spacer = ({
  flexspace,
  hasHorizontalRule,
  noMarginRule,
  children,
  className,
  fullWidth,
  label,
  panelMode,
  dashboardMode,
}: SpacerProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <span
      data-component="Spacer"
      id={id}
      className={classNames(
        'text-menu-active text-lg flex items-center',
        {
          'flex-1': flexspace,
        },
        {
          'w-full': fullWidth,
        },

        className,
      )}
    >
      {hasHorizontalRule && (
        <hr
          className={
            (classNames(
              'flex-1',
              panelMode
                ? 'border-app-background/60'
                : dashboardMode
                  ? 'border-app-background/50'
                  : 'border-menu-active',
            ),
            noMarginRule ? 'my-4 mx-2' : 'm-4')
          }
        />
      )}
      {children}
      {label}
    </span>
  );
};
