import * as React from 'react';
const PolicyWorkloadIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PolicyWorkloadIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65Zm-6.16,12.5l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z" />
    <path d="m53.55,81.59l-1-1c-2.38,2.67-3.66,5.98-3.85,9.34h1.41c.48,0,.87.39.87.87,0,.48-.39.87-.87.87h-1.41c.19,3.36,1.47,6.67,3.85,9.34l1-1c.34-.34.89-.34,1.23,0s.34.89,0,1.24l-1,1c2.66,2.39,5.96,3.67,9.32,3.86v-1.41c0-.48.39-.87.87-.87s.87.39.87.87v1.41c3.35-.19,6.65-1.48,9.31-3.86l-1-1c-.34-.34-.34-.89,0-1.24s.89-.34,1.23,0l1,1c2.38-2.67,3.66-5.98,3.85-9.34h-1.41c-.24,0-.46-.1-.62-.26s-.26-.38-.26-.62c0-.48.39-.87.87-.87h1.41c-.19-3.36-1.47-6.67-3.85-9.34l-1,1c-.34.34-.89.34-1.23,0s-.34-.89,0-1.24l1-1c-2.66-2.39-5.96-3.67-9.31-3.86v1.41c0,.48-.39.87-.87.87-.48,0-.87-.39-.87-.87v-1.41c-3.35.19-6.65,1.48-9.31,3.86l1,1c.34.34.34.89,0,1.24s-.89.34-1.23,0Z" />
    <path d="m39.44,89.61l24.5-24.5,24.5,24.5c.31.31.81.31,1.11,0s.31-.81,0-1.11l-24.5-24.5,24.5-24.5c.31-.31.31-.81,0-1.11s-.81-.31-1.11,0l-24.5,24.5-24.5-24.5c-.31-.31-.81-.31-1.11,0s-.31.81,0,1.11l24.5,24.5-24.5,24.5c-.31.31-.31.81,0,1.11.31.31.81.31,1.11,0h0Z" />
    <path d="m36.99,84.98l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97Z" />
    <path
      d="m48.17,73.8l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97h0Z"
      opacity={0.5}
    />
    <path
      d="m25.81,73.8l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97Z"
      opacity={0.6}
    />
    <path d="m36.99,62.62l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97h0Z" />
    <path d="m78.37,73.96l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97Z" />
    <path
      d="m89.56,62.77l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97Z"
      opacity={0.5}
    />
    <path
      d="m89.56,85.14l9.97-9.97-9.97-9.97-9.97,9.97,9.97,9.97Z"
      opacity={0.5}
    />
    <path
      fill="#fff"
      d="m67.6,96.15c-.27,0-.54-.11-.74-.32l-3.68-4c-.17-.18-.26-.43-.26-.68v-9.98c0-.55.45-1,1-1s1,.45,1,1v9.59l3.42,3.71c.37.41.35,1.04-.06,1.41-.19.18-.44.26-.68.26Z"
    />
    <g>
      <circle
        cx={64.56}
        cy={20.01}
        r={3.18}
        transform="translate(-2.76 26.04) rotate(-22.34)"
      />
      <path d="m69.03,30.78s-1.88-2.88-3.03-4.71c-1.08-1.72-1.73-2.12-2.89-1.96l-1.79.25c-.94.13-1.23.52-1.76.93l-4.26,4.8c-.39.3-.61.71-.7,1.24l-1.48,4.53c0,1.13.66,1.52,1.34,1.56.55.04,1.32-.35,1.38-1.15.04-.66,1.64-3.91,1.64-3.91l2.81-2.87c-.18,2.49-.34,5.22-.38,6.14-.08,1.58.85,2.64,1.59,3.47.67.75,9,6.74,10.11,7.83.7,3.52,2.68,3.54,3.88,2.62,1.01-.77.02-4.11-.74-5.06-.75-.95-9.21-7.4-9.21-7.4l.47-5.39s1.01,1.08,1.53,1.69c.25.29,3.24,1.93,4.22,2.41,1.58.78,3.01-1.56,1.3-2.45l-4.04-2.57Z" />
      <path d="m60.2,39.02l-1.39,4.21s-5.13,2.69-5.77,3.99c-.48.98-.79,2.12.36,2.76,1.3.72,2.07-.57,2.58-1.34.47-.7,5.09-2.24,5.61-3.13.35-.6,1.91-2.75,2.09-3.6l-3.48-2.89Z" />
    </g>
  </svg>
);
export default PolicyWorkloadIcon;
