import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { createAgent } from '../../simulation/store/resourcePolicy.helper';
import { warehouseSelected } from '../../store/warehouse.state';
import {
  actualityDocumentUpdateStatus,
  actualitySelectedDocument,
} from '../store/actuality.state';
import { feedDistinctAgents, feedDistinctUoms } from '../store/feed.state';

function useUpdateFeedResourcePolicy() {
  const [callUpdateActuality] = useUpdateActualityMutation();

  const init = useRecoilCallback(({ set, reset }) => async () => {
    set(actualityDocumentUpdateStatus, AsyncLoadStatus.Loading);
  });

  const generate = useRecoilCallback(({ snapshot, set, reset }) => async () => {
    const wh = await snapshot.getPromise(warehouseSelected);
    const actuality = await snapshot.getPromise(actualitySelectedDocument);

    const uoms = await snapshot.getPromise(feedDistinctUoms);
    const allAgents = await snapshot.getPromise(feedDistinctAgents);

    const existed = new Set(
      _.map(actuality?.resourcePolicy?.agents, a => a.id),
    );

    const missingTypes = _(allAgents)
      .filter(a => !existed.has(a.agentType))
      .map(a => a.agentType)
      .uniq()
      .value();

    const newAgents = _.map(missingTypes, agentType => {
      return createAgent({
        agentId: agentType,
        titles: [],
        titlePrefix: `${agentType}`,
        uoms: _.map(uoms, u => u.uom),
        system: wh.measurementSystem,
        currencyCode: wh.currency,
      });
    });

    const newAllAgents = [
      ...(actuality.resourcePolicy?.agents ?? []),
      ...newAgents,
    ];

    await callUpdateActuality({
      variables: {
        input: {
          resourcePolicy: { agents: cloneWithoutTypename(newAllAgents) },
          actualityId: actuality.id,
        },
      },
    });

    set(actualitySelectedDocument, {
      ...actuality,
      resourcePolicy: { agents: newAllAgents },
    });

    set(actualityDocumentUpdateStatus, AsyncLoadStatus.Ok);
  });

  async function update() {
    await init();
    await generate();
  }

  return update;
}

export default useUpdateFeedResourcePolicy;
