import * as React from 'react';
const MoveTypeMoveTakeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeMoveTakeIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="54.48 21.81 54.48 59.23 91.51 59.23 66.82 34.48 82.7 34.48 113 64.86 82.64 95.23 66.78 95.23 91.5 70.49 54.48 70.49 54.48 106.19 126.86 106.19 126.86 21.81 54.48 21.81"
    />
    <rect width={44.61} height={11.25} x={3.71} y={59.16} strokeWidth={0} />
  </svg>
);
export default MoveTypeMoveTakeIcon;
