import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HelperMessage } from '../../components/HelperMessage';
import * as Icon from '../../components/icons';

export type ImportSelectPanelProps = {
  children?: React.ReactNode;
  className?: string;
  titleDataset?: string;
  linkPath?: string;
};

export const ImportSelectPanel = ({
  children,
  className,
  titleDataset,
  linkPath,
}: ImportSelectPanelProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('importer');

  return (
    <HelperMessage className={className}>
      <div className="space-y-4">
        <div>
          <span>{t`Please use the following CSV template as your reference.`}</span>
          <Link
            to={{
              pathname: linkPath,
            }}
            target="_blank"
            className={classNames(
              'text-menu-active',
              'inline-flex',
              'ltr:ml-1 rtl:mr-1',
              'cursor-pointer select-none',
              'border-b-2 border-transparent',
              'border-menu-active hover:border-current',
            )}
          >
            <span className={classNames('inline-flex items-center')}>
              {t`Download CSV template`}
              <Icon.CloudDownload
                className={classNames(
                  'ltr:ml-2 rtl:mr-2',
                  'w-4 h-4 fill-current',
                )}
              />
            </span>
          </Link>
        </div>
      </div>

      {children}
    </HelperMessage>
  );
};
