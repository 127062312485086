import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsAgent } from '../AnalyticTracker';
import {
  PRIVACY_POLICY_VERSION,
  TERMS_POLICY_VERSION,
} from '../common/constants';
import { HelperMessage } from '../components/HelperMessage';
import * as Icon from '../components/icons';
import { ActionBar } from '../components/nav/ActionBar';
import useUpdateUserSettings from '../warehouse/hooks/useUpdateUserSettings';

export type TrialHelperProps = {
  children?: React.ReactNode;
};

export const TrialHelper = ({ children }: TrialHelperProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  const configure = useUpdateUserSettings();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function completeConfiguration(whId?: string) {
    await configure({
      configured: true,
      consents: {
        privacyPolicy: {
          version: PRIVACY_POLICY_VERSION,
        },
        termsPolicy: {
          version: TERMS_POLICY_VERSION,
        },
      },
    });
    navigate(whId ? `/wh/i/${whId}` : '/wh/new');
  }

  return (
    <div data-component={`DemoButton-TrialContainer`}>
      <HelperMessage hasPadding className="!py-0">
        <div
          className={classNames('text-lg xl:text-xl')}
        >{t`Or skip Demo, and start with your warehouse data`}</div>
      </HelperMessage>
      <ActionBar
        data-component={`DemoButton-Trial`}
        className={classNames('p-4 mb-4 cursor-pointer')}
        transparent
      >
        <div
          onClick={() => {
            setIsLoading(true);
            completeConfiguration();
            try {
              analyticsAgent?.track(`Demo (Helper): Start Trial`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
          className={classNames(
            'items-end',
            'flex flex-1 flex-col',
            'w-full',
            'h-trialcard',
            'p-4 pt-2',
            'rounded',
            'bg-menu-active hover:bg-menu-active/75 text-menu-active-text',
            'shadow hover:shadow-xl',
            'overflow-hidden',
          )}
        >
          <div className={classNames('w-full', 'flex flex-1', 'justify-start')}>
            <Icon.OrderPickingPriority
              className={classNames('w-auto h-16 xl:h-20 fill-current')}
            />
          </div>
          <div className={classNames('flex items-center flex-1 w-full mt-4')}>
            <div className={classNames('flex flex-col flex-1')}>
              <div
                className={classNames('mb-1 text-base xl:text-lg uppercase')}
              >
                {t`Your Data`}
              </div>
              <div
                data-component="title"
                className={classNames(
                  'text-3xl md:text-4xl lg:text-5xl xl:text-5xl',
                )}
              >{t`Start Trial`}</div>
            </div>
            <Icon.CircleArrowRight
              className={classNames('w-12 h-12 fill-current')}
            />
          </div>
        </div>
      </ActionBar>
    </div>
  );
};
