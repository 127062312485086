import * as React from 'react';
const SortPriorityAscendingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortPriorityAscendingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M42.37,1.03H2.29s0,40.39,0,40.39h40.08s0-40.39,0-40.39ZM33.82,35.95H13.32s0-5.1,0-5.1h7.93s0-19.74,0-19.74h-.38s-5.99,7.84-5.99,7.84l-4.05-3.16,7.04-9.28h9.7s0,24.34,0,24.34h6.24s0,5.1,0,5.1Z"
    />
    <path
      strokeWidth={0}
      d="M2.29,43.42v40.39s40.08,0,40.08,0v-40.39s-40.08,0-40.08,0ZM33.23,78.59H12.06s0-5.78,0-5.78l9.28-7.72c1.63-1.41,2.79-2.61,3.48-3.63.69-1.01,1.03-2.07,1.03-3.16v-.42c0-1.21-.37-2.14-1.1-2.78s-1.72-.97-2.95-.97c-1.32,0-2.37.38-3.14,1.14s-1.34,1.76-1.71,3l-5.53-2.11c.7-2.22,1.98-4.03,3.84-5.42,1.86-1.39,4.25-2.09,7.17-2.09,2.11,0,3.93.38,5.46,1.14,1.53.76,2.7,1.81,3.5,3.14.8,1.34,1.2,2.86,1.2,4.58,0,1.55-.34,2.97-1.01,4.26-.68,1.29-1.56,2.48-2.66,3.56-1.1,1.08-2.52,2.29-4.26,3.61l-5.61,4.34h14.17s0,5.32,0,5.32Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M2.29,85.85v40.31s40,0,40,0v-40.31s-40,0-40,0ZM32.04,117.09c-.96,1.31-2.3,2.32-4.02,3.03-1.73.72-3.74,1.07-6.04,1.07-2.72,0-4.94-.51-6.65-1.54-1.71-1.02-3.11-2.39-4.21-4.1l4.67-3.62c.73,1.32,1.59,2.34,2.57,3.05.98.72,2.23,1.07,3.75,1.07,1.49,0,2.64-.35,3.47-1.05.83-.7,1.24-1.67,1.24-2.9v-.3c0-1.18-.46-2.08-1.39-2.69-.93-.62-2.22-.93-3.87-.93h-2.74s0-5.26,0-5.26h2.78c1.54,0,2.71-.29,3.49-.88.78-.59,1.18-1.36,1.18-2.32v-.29c0-1.07-.37-1.92-1.09-2.55-.73-.63-1.74-.95-3.03-.95-1.21,0-2.34.3-3.39.91-1.05.6-1.94,1.47-2.67,2.59l-4-3.7c1.23-1.6,2.66-2.82,4.27-3.66,1.61-.84,3.63-1.26,6.04-1.26,2.1,0,3.95.32,5.54.97,1.59.65,2.81,1.56,3.68,2.74.87,1.18,1.31,2.54,1.31,4.08,0,1.8-.54,3.28-1.62,4.44-1.08,1.16-2.45,1.89-4.1,2.17v.25c1.88.34,3.39,1.11,4.55,2.34,1.15,1.22,1.73,2.8,1.73,4.74,0,1.74-.48,3.26-1.43,4.57Z"
      opacity={0.65}
    />
    <path
      strokeWidth={0}
      d="M49.84,75.13v-27.09s26.38,26.36,26.38,26.36V16.93s19.19.02,19.19.02v57.47s26.38-26.31,26.38-26.31v27.09s-35.98,35.89-35.98,35.89l-35.98-35.95Z"
    />
  </svg>
);
export default SortPriorityAscendingIcon;
