import * as React from 'react';
const OptimizeGridIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="OptimizeGridIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="73.38 120.79 64.4 114.78 55.41 120.79 64.4 126.82 73.38 120.79"
    />
    <polygon
      strokeWidth={0}
      points="94.25 108.25 85.26 102.25 76.28 108.25 85.26 114.29 94.25 108.25"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="120.86 95.71 111.87 89.71 102.89 95.71 111.87 101.75 120.86 95.71"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="73.38 97.78 64.4 91.78 55.41 97.78 64.4 103.82 73.38 97.78"
    />
    <polygon
      strokeWidth={0}
      points="120.86 77.16 111.87 71.16 102.89 77.16 111.87 83.2 120.86 77.16"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="76.28 64.31 85.26 58.31 94.25 64.31 85.26 70.35 76.28 64.31"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="55.41 51.78 64.4 45.77 73.38 51.78 64.4 57.81 55.41 51.78"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="76.28 86.28 85.26 80.28 94.25 86.28 85.26 92.32 76.28 86.28"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="55.41 74.78 64.4 68.78 73.38 74.78 64.4 80.82 55.41 74.78"
      opacity={0.9}
    />
    <path
      strokeWidth={0}
      d="M63.46,121.76l11.38-18.55-11.16-5.39,21.21-11.52-21.19-11.51,10.95-5.71-11.16-18.19,21.89,12.72,26.5,12.71,1.01-.37-8.18,9.67,7.91,9.02.74.24-27.78,13.37-.09.11-.06-.03-21.98,13.43ZM75.38,103.47l-10.05,16.38,19.41-11.86-9.36-4.52ZM75.73,102.91l9.63,4.65,13.95-16.5-13.62-4.37-9.95,16.22ZM99.94,91.26l-13.32,15.75,24.96-12.01-11.64-3.74ZM65.11,97.78l10.07,4.86,9.51-15.5-19.58,10.64ZM100.41,90.72l11.07,3.55-7.17-8.17-3.9,4.62ZM86.58,86.28l13.19,4.23,4.13-4.88-3.77-4.3-13.54,4.95ZM75.53,69.36l10.15,16.54,13.98-5.11-14.45-16.48-9.68,5.05ZM65.09,74.8l19.6,10.65-9.7-15.81-9.9,5.16ZM100.75,81.1l3.55,4.05,6.55-7.75-10.1,3.7ZM86.53,64.88l13.75,15.68,10.74-3.93-24.5-11.75ZM65.31,52.7l9.88,16.1,9.4-4.9-19.28-11.2Z"
    />
    <polygon
      strokeWidth={0}
      points="54.89 121.17 63.88 115.17 72.86 121.17 63.88 127.21 54.89 121.17"
    />
    <polygon
      strokeWidth={0}
      points="34.02 108.64 43.01 102.64 51.99 108.64 43.01 114.68 34.02 108.64"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="7.42 96.1 16.4 90.1 25.38 96.1 16.4 102.14 7.42 96.1"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="54.89 98.17 63.88 92.17 72.86 98.17 63.88 104.21 54.89 98.17"
    />
    <polygon
      strokeWidth={0}
      points="7.42 77.55 16.4 71.55 25.38 77.55 16.4 83.59 7.42 77.55"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="51.99 64.7 43.01 58.7 34.02 64.7 43.01 70.74 51.99 64.7"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="72.86 52.16 63.88 46.16 54.89 52.16 63.88 58.2 72.86 52.16"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="51.99 86.67 43.01 80.67 34.02 86.67 43.01 92.71 51.99 86.67"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="72.86 75.17 63.88 69.17 54.89 75.17 63.88 81.2 72.86 75.17"
      opacity={0.9}
    />
    <path
      strokeWidth={0}
      d="M42.83,108.71l-.06.03-.09-.11-27.78-13.37.74-.24,7.91-9.02-8.18-9.67,1.01.37,26.5-12.71,21.89-12.72-11.16,18.19,10.95,5.71-21.19,11.51,21.21,11.52-11.16,5.39,11.38,18.55-21.98-13.43ZM43.53,108.38l19.41,11.86-10.05-16.38-9.36,4.52ZM42.59,87.07l-13.62,4.37,13.95,16.5,9.63-4.65-9.95-16.22ZM16.68,95.39l24.96,12.01-13.32-15.75-11.64,3.74ZM43.58,87.53l9.51,15.5,10.07-4.86-19.58-10.64ZM23.96,86.49l-7.17,8.17,11.07-3.55-3.9-4.62ZM28.15,81.71l-3.77,4.3,4.13,4.88,13.19-4.23-13.54-4.95ZM43.07,64.7l-14.45,16.48,13.98,5.11,10.15-16.54-9.68-5.05ZM53.28,70.03l-9.7,15.81,19.6-10.65-9.9-5.16ZM17.42,77.79l6.55,7.75,3.55-4.05-10.1-3.7ZM17.25,77.02l10.74,3.93,13.75-15.68-24.5,11.75ZM43.68,64.28l9.4,4.9,9.88-16.1-19.28,11.2Z"
    />
    <polygon
      strokeWidth={0}
      points="73.38 75.8 64.4 69.8 55.41 75.8 64.4 81.84 73.38 75.8"
    />
    <polygon
      strokeWidth={0}
      points="94.25 63.26 85.26 57.26 76.28 63.26 85.26 69.3 94.25 63.26"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="120.86 50.73 111.87 44.73 102.89 50.73 111.87 56.77 120.86 50.73"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="73.38 52.8 64.4 46.8 55.41 52.8 64.4 58.83 73.38 52.8"
    />
    <polygon
      strokeWidth={0}
      points="120.86 32.17 111.87 26.17 102.89 32.17 111.87 38.21 120.86 32.17"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="76.28 19.33 85.26 13.32 94.25 19.33 85.26 25.36 76.28 19.33"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="55.41 6.79 64.4 .79 73.38 6.79 64.4 12.83 55.41 6.79"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="76.28 41.3 85.26 35.29 94.25 41.3 85.26 47.33 76.28 41.3"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="55.41 29.79 64.4 23.79 73.38 29.79 64.4 35.83 55.41 29.79"
      opacity={0.9}
    />
    <path
      strokeWidth={0}
      d="M63.46,76.77l11.38-18.55-11.16-5.39,21.21-11.52-21.19-11.51,10.95-5.71-11.16-18.19,21.89,12.72,26.5,12.71,1.01-.37-8.18,9.67,7.91,9.02.74.24-27.78,13.37-.09.11-.06-.03-21.98,13.43ZM75.38,58.49l-10.05,16.38,19.41-11.86-9.36-4.52ZM75.73,57.92l9.63,4.65,13.95-16.5-13.62-4.37-9.95,16.22ZM99.94,46.28l-13.32,15.75,24.96-12.01-11.64-3.74ZM65.11,52.8l10.07,4.86,9.51-15.5-19.58,10.64ZM100.41,45.73l11.07,3.55-7.17-8.17-3.9,4.62ZM86.58,41.29l13.19,4.23,4.13-4.88-3.77-4.3-13.54,4.95ZM75.53,24.37l10.15,16.54,13.98-5.11-14.45-16.48-9.68,5.05ZM65.09,29.82l19.6,10.65-9.7-15.81-9.9,5.16ZM100.75,36.11l3.55,4.05,6.55-7.75-10.1,3.7ZM86.53,19.9l13.75,15.68,10.74-3.93-24.5-11.75ZM65.31,7.71l9.88,16.1,9.4-4.9-19.28-11.2Z"
    />
    <polygon
      strokeWidth={0}
      points="54.89 76.19 63.88 70.19 72.86 76.19 63.88 82.23 54.89 76.19"
    />
    <polygon
      strokeWidth={0}
      points="34.02 63.65 43.01 57.65 51.99 63.65 43.01 69.69 34.02 63.65"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="7.42 51.12 16.4 45.11 25.38 51.12 16.4 57.15 7.42 51.12"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="54.89 53.18 63.88 47.18 72.86 53.18 63.88 59.22 54.89 53.18"
    />
    <polygon
      strokeWidth={0}
      points="7.42 32.56 16.4 26.56 25.38 32.56 16.4 38.6 7.42 32.56"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="51.99 19.71 43.01 13.71 34.02 19.71 43.01 25.75 51.99 19.71"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="72.86 7.18 63.88 1.18 54.89 7.18 63.88 13.22 72.86 7.18"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="51.99 41.68 43.01 35.68 34.02 41.68 43.01 47.72 51.99 41.68"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="72.86 30.18 63.88 24.18 54.89 30.18 63.88 36.22 72.86 30.18"
      opacity={0.9}
    />
    <path
      strokeWidth={0}
      d="M42.83,63.73l-.06.03-.09-.11-27.78-13.37.74-.24,7.91-9.02-8.18-9.67,1.01.37,26.5-12.71,21.89-12.72-11.16,18.19,10.95,5.71-21.19,11.51,21.21,11.52-11.16,5.39,11.38,18.55-21.98-13.43ZM43.53,63.39l19.41,11.86-10.05-16.38-9.36,4.52ZM42.59,42.09l-13.62,4.37,13.95,16.5,9.63-4.65-9.95-16.22ZM16.68,50.4l24.96,12.01-13.32-15.75-11.64,3.74ZM43.58,42.55l9.51,15.5,10.07-4.86-19.58-10.64ZM23.96,41.5l-7.17,8.17,11.07-3.55-3.9-4.62ZM28.15,36.73l-3.77,4.3,4.13,4.88,13.19-4.23-13.54-4.95ZM43.07,19.72l-14.45,16.48,13.98,5.11,10.15-16.54-9.68-5.05ZM53.28,25.04l-9.7,15.81,19.6-10.65-9.9-5.16ZM17.42,32.8l6.55,7.75,3.55-4.05-10.1-3.7ZM17.25,32.03l10.74,3.93,13.75-15.68-24.5,11.75ZM43.68,19.3l9.4,4.9,9.88-16.1-19.28,11.2Z"
    />
  </svg>
);
export default OptimizeGridIcon;
