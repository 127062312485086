import * as React from 'react';
const ScreenViewDualTableIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ScreenViewDualTableIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4,4v120h120V4H4ZM66,21h6v6h-6v-6ZM66,31h6v6h-6v-6ZM66,41h6v6h-6v-6ZM66,51h6v6h-6v-6ZM66,61h6v6h-6v-6ZM66,71h6v6h-6v-6ZM66,81h6v6h-6v-6ZM66,91h6v6h-6v-6ZM66,101h6v6h-6v-6ZM62,110H18V18h44v92ZM110,107h-34v-6h34v6ZM110,97h-34v-6h34v6ZM110,87h-34v-6h34v6ZM110,77h-34v-6h34v6ZM110,67h-34v-6h34v6ZM110,57h-34v-6h34v6ZM110,47h-34v-6h34v6ZM110,37h-34v-6h34v6ZM110,27h-34v-6h34v6Z"
    />
  </svg>
);
export default ScreenViewDualTableIcon;
