import { MeasurementSystem } from '@warebee/shared/data-access-layout-manager';
import _ from 'lodash';

export type MeasureType = 'size' | 'volume' | 'weight' | 'local-date-time';

// https://docs.microsoft.com/en-us/dotnet/standard/base-types/custom-date-and-time-format-strings
export const MappingLocalDateTimeFormats = {
  'dd/MM/yyyy': {
    template: 'dd/MM/yyyy',
    example: `31/09/2022`,
  },
  'MM/dd/yyyy': {
    template: 'MM/dd/yyyy',
    example: `09/31/2022`,
  },
  'dd/MM/yyyy HH:mm': {
    template: 'dd/MM/yyyy hh:mm',
    example: `31/09/2022 23:00`,
  },
  'dd/MM/yyyy HH:mm:ss': {
    template: 'dd/MM/yyyy hh:mm:ssa',
    example: `01/09/2022 23:59:59`,
  },
  'd/M/yyyy': {
    template: 'd/M/yyyy',
    example: `31/9/2022`,
  },
  'd/M/yyyy HH:mm': {
    template: 'dd/MM/yyyy hh:mm',
    example: `31/12/2022 23:00`,
  },
  'd/M/yyyy h:mm:ssaaa': {
    template: 'd/M/yyyy h:mm:ssaaa',
    example: `31/9/2022 11:00:00AM`,
  },
  'dd.MM.yyyy': {
    template: 'dd.MM.yyyy',
    example: `31.12.2022`,
  },
  'dd.M.yyyy': {
    template: 'dd.M.yyyy',
    example: `31.9.2022`,
  },
  'dd.MM.yyyy HH:mm': {
    template: 'dd.MM.yyyy hh:mm',
    example: `31.12.2022 23:00`,
  },
  'dd.MM.yyyy HH:mm:ss': {
    template: 'dd.MM.yyyy hh:mm:ss',
    example: `31.12.2022 23:00:00`,
  },
  'dd-MM-yyyy HH:mm:ss.SSS': {
    template: 'dd-MM-yyyy HH:mm:ss.SSS',
    example: `31-09-2022 11:00:00.000`,
  },
  'dd-MM-yyyy HH:mm:ss:aa': {
    template: 'dd-MM-yyyy HH:mm:ss:aa',
    example: `31-09-2022 11:00:00PM`,
  },
  'dd-MM-yyyy hh:mm:ssa': {
    template: 'dd-MM-yyyy hh:mm:ssa',
    example: `31-09-2022 23:00:00`,
  },
  'mm/dd/yyyy h:mm:ssaaa': {
    template: 'mm/dd/yyyy h:mm:ssaaa',
    example: `09/31/2022 11:00:00AM`,
  },
  'mm/dd/yyyy hh:mm': {
    template: 'mm/dd/yyyy hh:mm',
    example: `09/31/2022 23:00`,
  },
  'mm/dd/yyyy h:mm': {
    template: 'mm/dd/yyyy h:mm',
    example: `09/31/2022 11:00`,
  },
  'mm/dd/yy h:mm:ssaaa': {
    template: 'mm/dd/yyyy h:mm:ssaaa',
    example: `09/31/22 11:00:00AM`,
  },
  'MM-dd-yyyy HH:mm:ss:aa': {
    template: 'MM-dd-yyyy HH:mm:ss:aa',
    example: `09-31-2022 11:00:00PM`,
  },
  'dd-MM-yy HH:mm': {
    template: 'dd-MM-yy HH:mm',
    example: `31-09-22 23:00`,
  },
  'yyyy-mm-dd': {
    template: 'yyyy-MM-dd',
    example: `2022-09-31`,
  },
  'yyyy-M-dd': {
    template: 'yyyy-M-dd',
    example: `2022-9-31`,
  },
  'yyyy-mm-dd HH:mm:ss': {
    template: 'yyyy-mm-dd HH:mm:ss',
    example: `2022-12-31 23:00:00`,
  },
  'yyyy/MM/dd HH:mm:ss': {
    template: 'yyyy/MM/dd HH:mm:ss',
    example: `2022/12/31 23:00:00`,
  },
  'yyyy.MM.dd HH:mm:ss': {
    template: 'yyyy.MM.dd HH:mm:ss',
    example: `2022.12.31 23:00:00`,
  },
  'yyyy/MM/dd': {
    template: 'yyyy/MM/dd',
    example: `2022/09/31`,
  },
  'yyyy.MM.dd': {
    template: 'yyyy.MM.dd',
    example: `2022.09.31`,
  },
  'yyyy-mm-dd HH:mm:ss:aa': {
    template: 'yyyy-MM-dd HH:mm:ss.SSS',
    example: `2022-12-31 23:00:00 PM`,
  },
} as const;

export type MappingDateTimeFormatLocalType =
  keyof typeof MappingLocalDateTimeFormats;

export type MappingMeasuresValuesType =
  | string
  | 'mm'
  | 'cm'
  | 'm'
  | 'in'
  | 'ft'
  | 'mm3'
  | 'cm3'
  | 'dm3'
  | 'm3'
  | 'in3'
  | 'ft3'
  | 'kg'
  | 'g'
  | 'lb'
  | 'oz';

const MappingMeasuresValuesMultipliers = new Map<
  MappingMeasuresValuesType,
  number
>([
  ['mm', 0.1],
  ['cm', 1],
  ['m', 100],
  ['in', 1],
  ['ft', 12],
  ['mm3', 0.001],
  ['cm3', 1],
  ['dm3', 1000], // added cubic decimeters
  ['m3', 1000000],
  ['in3', 1],
  ['ft3', 1728],
  ['kg', 1],
  ['g', 1 / 1000],
  ['lb', 1],
  ['oz', 1 / 16],
]);

export function getMeasureOptions(
  mappingMeasure: MeasureType,
  system: MeasurementSystem,
): MappingMeasuresValuesType[] {
  switch (mappingMeasure) {
    case 'size':
      switch (system) {
        case MeasurementSystem.METRIC:
          return LengthOptionsMetric;
        case MeasurementSystem.IMPERIAL:
          return LengthOptionsImperial;
      }
      break;

    case 'volume':
      switch (system) {
        case MeasurementSystem.METRIC:
          return VolumeOptionsMetric;
        case MeasurementSystem.IMPERIAL:
          return VolumeOptionsImperial;
      }
      break;

    case 'weight':
      switch (system) {
        case MeasurementSystem.METRIC:
          return WeightOptionsMetric;
        case MeasurementSystem.IMPERIAL:
          return WeightOptionsImperial;
      }
      break;
    case 'local-date-time':
      return _.keys(
        MappingLocalDateTimeFormats,
      ) as MappingDateTimeFormatLocalType[];
  }
  return null;
}

export function getDefaultMeasureOption(
  mappingMeasure: MeasureType,
  system: MeasurementSystem,
): MappingMeasuresValuesType {
  switch (mappingMeasure) {
    case 'size':
      switch (system) {
        case MeasurementSystem.METRIC:
          return 'cm';
        case MeasurementSystem.IMPERIAL:
          return 'in';
      }
      break;

    case 'volume':
      switch (system) {
        case MeasurementSystem.METRIC:
          return 'cm3';
        case MeasurementSystem.IMPERIAL:
          return 'in3';
      }
      break;

    case 'weight':
      switch (system) {
        case MeasurementSystem.METRIC:
          return 'kg';
        case MeasurementSystem.IMPERIAL:
          return 'lb';
      }
      break;
    case 'local-date-time':
      return 'yyyy-MM-dd HH:mm:ss.SSS';
  }
}

export function getMappingMeasureMultiplier(
  measure: MappingMeasuresValuesType,
) {
  return MappingMeasuresValuesMultipliers.get(measure) ?? 1;
}

export const LengthOptionsMetric: MappingMeasuresValuesType[] = [
  'mm',
  'cm',
  'm',
];
export const LengthOptionsImperial: MappingMeasuresValuesType[] = ['in', 'ft'];
export const VolumeOptionsMetric: MappingMeasuresValuesType[] = [
  'mm3',
  'dm3',
  'cm3',
  'm3',
];
export const VolumeOptionsImperial: MappingMeasuresValuesType[] = [
  'in3',
  'ft3',
];
export const WeightOptionsMetric: MappingMeasuresValuesType[] = ['kg', 'g'];
export const WeightOptionsImperial: MappingMeasuresValuesType[] = ['lb', 'oz'];
