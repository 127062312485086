import * as React from 'react';
const WarehouseFactoryIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseFactoryIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M43.05,113.25h30.48v2.97h8.81v-2.97h30.48v2.97h8.21v-49.58l-42.43-17.2-.08,14.74-36.36-14.74-.08,15.12L4.79,49.44l-.09,17.2v49.58h38.35v-2.97ZM73.53,111.77h-30.48v-2.65h30.48v2.65ZM73.53,107.64h-30.48v-2.65h30.48v2.65ZM73.53,103.52h-30.48v-2.65h30.48v2.65ZM73.53,99.29h-30.48v-2.65h30.48v2.65ZM73.53,95.16h-30.48v-2.65h30.48v2.65ZM73.53,91.04h-30.48v-2.65h30.48v2.65ZM112.82,111.77h-30.48v-2.65h30.48v2.65ZM112.82,107.64h-30.48v-2.65h30.48v2.65ZM112.82,103.52h-30.48v-2.65h30.48v2.65ZM112.82,99.29h-30.48v-2.65h30.48v2.65ZM112.82,95.16h-30.48v-2.65h30.48v2.65ZM112.82,91.04h-30.48v-2.65h30.48v2.65Z"
    />
    <polygon
      strokeWidth={0}
      points="35.43 21.72 27.04 21.72 24.06 54 37.41 59.26 35.43 21.72"
    />
    <polygon
      strokeWidth={0}
      points="34.63 19.73 35.43 14.76 27.04 14.76 27.74 19.73 34.63 19.73"
    />
    <rect width={123.46} height={5.23} x={1.7} y={118.36} strokeWidth={0} />
  </svg>
);
export default WarehouseFactoryIcon;
