import {
  AssignmentIssueInvalidLocationReason,
  AssignmentIssueSetFragment,
  ItemSetIssueInvalidItemReason,
  ItemSetIssueInvalidUomReason,
  ItemSetIssueSetFragment,
  LayoutIssueInvalidLocationReason,
  LayoutIssueSetFragment,
  OrderedQuantityReportItemStatus,
  OrderedQuantityReportSummaryFragment,
  OrderSetIssueSetFragment,
  OrderSetIssueUnpickableLineReason,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  getAssignmentIssueDescriptors,
  getIssuesDescriptors,
  getLayoutIssueDescriptors,
  getOrderIssueDescriptors,
  getOrderItemIssueDescriptors,
} from '../../store/simulation.issues.helper';
import { IssuePanelConfig } from '../../store/simulation.issues.types';

export function getLayoutIssuesPanelConfigs(
  layoutIssues: LayoutIssueSetFragment,
  t: TFunction<'simulation'>,
): IssuePanelConfig[] {
  const locationCount = layoutIssues.summary?.totalLocations;
  const issueMap = _.groupBy(
    layoutIssues.summary?.invalidLocations,
    l => l.reason,
  );
  const descriptors = getLayoutIssueDescriptors(t);
  return [
    {
      id: LayoutIssueInvalidLocationReason.DISABLED,
      title: descriptors[LayoutIssueInvalidLocationReason.DISABLED].title,
      value: _.sumBy(
        issueMap?.[LayoutIssueInvalidLocationReason.DISABLED],
        i => i.count,
      ),
      total: locationCount,
      selectable: true,
      threshold: 80,
    },
  ];
}

export function getAssignmentIssuesPanelConfigs(
  assignmentIssues: AssignmentIssueSetFragment,
  t: TFunction<'simulation'>,
): IssuePanelConfig[] {
  const locationCount = assignmentIssues.summary?.totalLocations;
  const issueMap = _.groupBy(
    assignmentIssues.summary?.invalidLocations,
    l => l.reason,
  );
  const descriptors = getAssignmentIssueDescriptors(t);

  return [
    {
      id: AssignmentIssueInvalidLocationReason.UNKNOWN_LOCATION,
      threshold: 50,
      selectable: true,
      tableView: true,
    },
    {
      id: AssignmentIssueInvalidLocationReason.EMPTY_LOCATION,
      selectable: true,
      threshold: 50,
    },
    {
      id: AssignmentIssueInvalidLocationReason.LOCATION_WITH_MIXED_ITEMS,
      selectable: true,
    },
    {
      id: AssignmentIssueInvalidLocationReason.DISABLED_LOCATION_WITH_ITEMS,
      selectable: true,
      threshold: 50,
    },
  ].map(config => ({
    ...config,
    title: descriptors[config.id].title,
    total: locationCount,
    value: _.sumBy(issueMap?.[config.id], i => i.count),
  }));
}

export function getOrdersIssuesPanelConfigs(
  orderIssues: OrderSetIssueSetFragment,
  t: TFunction<'simulation'>,
): IssuePanelConfig[] {
  const orderLinesCount = orderIssues.summary?.totalOrderLines;
  const orderedItemsCount = orderIssues.summary?.totalItems;
  const issuesMap = _.keyBy(
    orderIssues.summary?.unpickableLines,
    l => l.reason,
  );
  const itemIssuesMap = _.keyBy(
    orderIssues.summary?.unpickableItems,
    l => l.reason,
  );
  const linesIssuesDescriptors = getOrderIssueDescriptors(t);
  const itemsIssueDescriptor = getOrderItemIssueDescriptors(t);

  const lineIssues = [
    {
      id: OrderSetIssueUnpickableLineReason.UNASSIGNED_ITEM,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.MISSING_UOMS,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.UNKNOWN_ITEM,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.UNKNOWN_LOCATIONS,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.DISABLED_LOCATIONS,
      threshold: 50,
    },
  ].map(config => ({
    ...config,
    title: linesIssuesDescriptors[config.id].title,
    tableView: true,
    selectable: true,
    total: orderLinesCount,
    value: issuesMap?.[config.id]?.count ?? 0,
  }));

  const itemsIssues = [
    {
      id: OrderSetIssueUnpickableLineReason.UNASSIGNED_ITEM,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.MISSING_UOMS,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.UNKNOWN_ITEM,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.UNKNOWN_LOCATIONS,
      threshold: 50,
    },
    {
      id: OrderSetIssueUnpickableLineReason.DISABLED_LOCATIONS,
      threshold: 50,
    },
  ].map(config => ({
    ...config,
    title: itemsIssueDescriptor[config.id].title,
    tableView: false,
    selectable: false,
    total: orderedItemsCount,
    value: itemIssuesMap?.[config.id]?.count ?? 0,
  }));

  return [...lineIssues, ...itemsIssues];
}

export function getItemsIssuesPanelConfigs(
  itemsIssues: ItemSetIssueSetFragment,
  t: TFunction<'simulation'>,
): IssuePanelConfig[] {
  const itemsCount = itemsIssues.summary?.totalItems;
  const itemsIssuesMap = _.keyBy(
    itemsIssues.summary?.invalidItems,
    l => l.reason,
  );
  const descriptors = getIssuesDescriptors(t);
  const itemIssuesConfig = [
    {
      id: ItemSetIssueInvalidItemReason.NO_UOMS,
      title:
        descriptors['itemSet'][ItemSetIssueInvalidItemReason.NO_UOMS].title,
      value:
        itemsIssuesMap?.[ItemSetIssueInvalidItemReason.NO_UOMS]?.count ?? 0,
      total: itemsCount,
      selectable: true,
      tableView: true,
      threshold: 50,
    },
    {
      id: 'UNKNOWN_ITEM' as any,
      title: 'Unknown assigned items',
      value: itemsIssues.summary.unknownAssignedItems ?? 0,
      total: itemsIssues.summary.totalAssignedItems,
      selectable: false,
      tableView: true,
      threshold: 50,
    },
  ];

  const totalUoms = itemsIssues.summary?.totalUoms;
  const uomIssuesMap = _.keyBy(itemsIssues.summary?.invalidUoms, l => l.reason);

  const uomsIssuesConfig = [
    {
      id: ItemSetIssueInvalidUomReason.INVALID_VOLUME,
      threshold: 50,
    },
    {
      id: ItemSetIssueInvalidUomReason.INVALID_WEIGHT,
      threshold: 50,
    },
  ].map(config => ({
    ...config,
    title: descriptors['itemSet'][config.id].title,
    tableView: true,
    selectable: true,
    total: totalUoms,
    value: uomIssuesMap?.[config.id]?.count ?? 0,
  }));

  return [...itemIssuesConfig, ...uomsIssuesConfig];
}

export function getOrderedQuantityIssuesPanelConfigs(
  orderedQuantityIssues: OrderedQuantityReportSummaryFragment,
  t: TFunction<'simulation'>,
): IssuePanelConfig[] {
  // const itemCount = orderedQuantityIssues.summary?.totalItems || 0;

  const itemCount =
    orderedQuantityIssues.summary?.enoughCount +
      orderedQuantityIssues.summary?.notEnoughCount +
      orderedQuantityIssues.summary?.notOrderedCount || 0;

  return [
    {
      id: OrderedQuantityReportItemStatus.NOT_ENOUGH,
      title: t`Ordered: Missing from Stock`,
      value: orderedQuantityIssues.summary.notEnoughCount,
      total: itemCount,
      selectable: true,
      tableView: true,
      threshold: 80,
    },
    {
      id: OrderedQuantityReportItemStatus.ENOUGH,
      title: t`Ordered: In Stock`,
      value: orderedQuantityIssues.summary.enoughCount,
      total: itemCount,
      selectable: true,
      tableView: true,
      threshold: 80,
    },
    {
      id: OrderedQuantityReportItemStatus.NOT_ORDERED,
      title: t`Not Ordered (Dead Stock)`,
      value: orderedQuantityIssues.summary.notOrderedCount,
      total: itemCount,
      selectable: true,
      tableView: true,
      threshold: 80,
    },
  ];
}
