import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import Loader from './loaders/Loader';

export type LoaderIndicatorProps = {
  children?: React.ReactNode;
  className?: string;
  message?: string | React.ReactNode;
  messageBefore?: string | React.ReactNode;
  selfCenter?: boolean;
  oneLiner?: boolean;
  absolute?: boolean;
  inline?: boolean;
  noColor?: boolean;
  progress?: number;
  circleSize?: number;
  circleStroke?: number;
  backdrop?: boolean;
  transparent?: boolean;
  mapMode?: boolean;
};

// export default function LoadingIndicator({ children, message }: LoaderIndicatorProps) {
export const LoadingIndicator = ({
  children,
  className,
  message,
  messageBefore,
  selfCenter,
  oneLiner,
  absolute,
  inline,
  noColor,
  progress,
  circleSize,
  circleStroke,
  backdrop,
  transparent,
  mapMode,
}: LoaderIndicatorProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <>
      <div
        data-component="LoadingIndicator"
        id={id}
        className={classNames(
          'items-center justify-center z-1000',
          {
            'absolute top-0 h-full flex flex-1 ltr:left-0 rtl:right-0 z-1000 w-full':
              absolute,
          },
          { 'relative flex': !absolute },
          oneLiner ? 'w-full' : inline ? '' : 'w-full',
          { 'flex-1 text-center': selfCenter },
          className,
        )}
      >
        <div
          className={classNames(
            'rounded',
            'flex items-center justify-center',
            { 'text-menu-active': !noColor },
            oneLiner ? 'flex-1' : inline ? '' : 'flex-col',
            absolute && 'h-full w-full ',
            transparent
              ? ''
              : mapMode
                ? 'bg-menu/80 backdrop-filter backdrop-blur backdrop-saturate-110'
                : 'bg-menu/80 backdrop-filter backdrop-blur backdrop-saturate-110 z-1000',
            selfCenter && 'p-4',
          )}
        >
          {messageBefore &&
            (typeof messageBefore === 'string' ? (
              <div className={classNames(absolute ? 'w-full' : 'flex-1')}>
                {messageBefore}
              </div>
            ) : (
              <div className={classNames(absolute ? 'w-full' : 'flex-1')}>
                {messageBefore}
              </div>
            ))}

          <div
            className={classNames(
              'flex items-center relative',
              {
                'justify-center relative p-4 w-24 h-24 mb-2':
                  progress && !oneLiner,
              },
              // { '': oneLiner && !absolute },
            )}
          >
            <Loader
              type={
                progress && !oneLiner
                  ? 'loading-original'
                  : progress && oneLiner
                    ? 'loading-circle'
                    : 'loading-original'
              }
              size={
                circleSize
                  ? circleSize
                  : oneLiner
                    ? 30
                    : inline
                      ? 25
                      : progress
                        ? 150
                        : 40
              }
              stroke={
                circleSize && !oneLiner
                  ? circleSize
                  : circleStroke
                    ? circleStroke
                    : inline
                      ? 6
                      : progress
                        ? 6
                        : 5
              }
              // className={`
              // ${
              //   oneLiner
              //     ? 'ltr:mr-2 rtl:ml-2'
              //     : inline
              //     ? 'ltr:mr-2 rtl:ml-2'
              //     : progress
              //     ? ''
              //     : ''
              // }`}
            />

            {progress !== null && progress !== undefined && (
              <div
                data-component="progress-value"
                className={classNames(
                  'text-xl text-menu-active absolute left-0 right-0 text-center',
                )}
              >
                {progress === 0 ? <span>{`–`}</span> : <span>{progress}</span>}

                {progress > 0 && <span className={`opacity-50`}>%</span>}
              </div>
            )}
          </div>

          {message &&
            (typeof message === 'string' ? (
              <div
                className={classNames(
                  absolute ? 'w-full' : 'flex-1',
                  {
                    'mt-4': !inline && !oneLiner,
                  },
                  { 'text-start': oneLiner },
                  oneLiner
                    ? 'ltr:ml-4 rtl:mr-4 text-base xl:text-lg'
                    : inline
                      ? 'mx-2'
                      : progress
                        ? ''
                        : '',
                )}
              >
                {message}
              </div>
            ) : (
              <div
                className={classNames(absolute ? 'w-full' : 'flex-1', {
                  'mt-4': !inline,
                })}
              >
                {message}
              </div>
            ))}

          {children}
        </div>
      </div>
      {backdrop && (
        <div
          data-component="backdrop"
          className={classNames(
            'absolute top-0 flex flex-1 ltr:left-0 rtl:right-0 z-[999] backdrop-blur-[2px] h-full w-full bg-app-background/20',
          )}
        ></div>
      )}
    </>
  );
};

export default LoadingIndicator;
