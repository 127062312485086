import { ResourceUsageSummaryGroupBy } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { DayOfWeek } from '../../common/dateTimeHelper';
import {
  AgentLabourEvent,
  AgentLabourEventsSetting,
} from '../../resourcePolicy/agentData/agent.types';
import { EventGroupValues } from '../panels/OptimisationEventsComparePanel';

export const DRILL_KEY_WORK_TOTAL = '___work_total'; //i.e. analyze result
export const DRILL_KEY_WORK_CUSTOM_TOTAL = '___labour'; // sum custom events
export const DRILL_KEY_TOTAL = '___total'; // work + labour
export const DRILL_KEY_IDLE = '___idle'; // shift - total
export const DRILL_KEY_OVERTIME = '___overtime'; // total - shift

export type WorkforceForecastMode = 'byDate' | 'schedule';

export const DRILL_KEYS_LABOUR_EVENTS = [
  '___breaks',
  '___meetings',
  '___training',
  '___recharge',
  '___maintenance',
  '___misc',
] as const;

const DRILL_KEYS_LABOUR_EVENTS_SET = new Set(DRILL_KEYS_LABOUR_EVENTS);
export type WorkforceLabourDrillKey = typeof DRILL_KEYS_LABOUR_EVENTS[number];

export const getLabourDrillKey = (
  labourEvent: AgentLabourEvent,
): WorkforceLabourDrillKey => `___${labourEvent}`;

export const getLabourEventKey = (
  labourEvent: WorkforceLabourDrillKey,
): AgentLabourEvent => _.trimStart(labourEvent, '_') as AgentLabourEvent;

export const workforceSpecialDrillKeys = [
  DRILL_KEY_WORK_TOTAL,
  DRILL_KEY_TOTAL,
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_WORK_CUSTOM_TOTAL,
  ...DRILL_KEYS_LABOUR_EVENTS,
] as const;

const workforceSpecialDrillKeysSet = new Set(workforceSpecialDrillKeys);
export type WorkforceSpecialDrillKey = typeof workforceSpecialDrillKeys[number];

// TS type guards
export const isLabourDrillKey = (
  key: string,
): key is WorkforceLabourDrillKey => {
  return DRILL_KEYS_LABOUR_EVENTS_SET.has(key as any);
};

export const isWorkforceSpecialDrillKey = (
  key: string,
): key is WorkforceSpecialDrillKey => {
  return workforceSpecialDrillKeysSet.has(key as any);
};

export const workforceSimulationsTypes = ['simple', 'advanced'] as const;
export type WorkforceSimulationsType = typeof workforceSimulationsTypes[number];

export const workforceAggregationTypes = [
  'daily',
  'dailyCalendar',
  'weekly',
] as const;
export type AgentWorkforcePeriodType = typeof workforceAggregationTypes[number];

export const workforceKpiTypes = ['avg', 'sum', 'fte', 'picklists'] as const;
export type WorkforceKpiType = typeof workforceKpiTypes[number];

export const workforceDrillTypes = [
  ResourceUsageSummaryGroupBy.EVENT_TYPE,
  ResourceUsageSummaryGroupBy.ORDER_LINE_RULE,
  ResourceUsageSummaryGroupBy.PLANE,
  ResourceUsageSummaryGroupBy.PICKLIST_GROUP_KEY,
  ResourceUsageSummaryGroupBy.PICK_BY_DATE,
];
export type WorkforceDrillType = typeof workforceDrillTypes[number];

export type AgentWorkforceSimulationPeriod = {
  absolute: {
    start: number;
    end: number;
  };
};

export type AgentWorkforceSimulationSettings =
  AgentWorkforceSimulationSettingsItem[];

export type WorkforceScheduleMap = Record<
  string,
  AgentWorkforceSimulationSettingsItem
>;

export type WorkforceShiftSettings = {
  id: string;
  title: string;
  dayOfWeek?: Record<DayOfWeek, unknown>;
  startTime: string;
  agentAmount: number;
  secondsOperation: number;
  costPerSecond: number;
};

export type AgentWorkforceSimulationSettingsItem = {
  id: string;
  title: string;
  agentId: string;

  isDefault: boolean;
  period?: AgentWorkforceSimulationPeriod;
  shiftSettings: Record<string, WorkforceShiftSettings>;
  labourSettings: AgentLabourEventsSetting;

  color: string;
  sortIndex: number;
};

export type AgentWorkforceEventsTotal = {
  duration: Record<string, number>;
  cost: Record<string, number>;
  dimensions: {};
  shiftDurations: number;
  effectiveShiftDurations: number;
  effectiveShiftCount: number;
};

export type AgentWorkforceEventsAggregatedMap = Record<
  string,
  AgentWorkforceEventsAggregated
>;
export type AgentWorkforceEventsAggregated = {
  periodStart: Date;
  periodType: AgentWorkforcePeriodType;
  aggregatedShift: number;
  aggregatedShiftWithoutLabourEvents: number;
  totalShiftsDuration: number;
  totalShiftsCost: number;
  picklists: Record<string, number>;
  duration: Record<string, number>;
  durationAvg: Record<string, number>;
  cost: Record<string, number>;
  forecastIds: string[];
  dimensions: {};
};

export type AgentWorkforceDrillDownData = {
  period: string;
  totalDuration: number;
  totalOrders: number;
  totalPicklists: number;
  dimensions: {};
  duration: Record<string, number>;
  cost?: Record<string, number>;
  orders?: Record<string, number>;
  picklists?: Record<string, number>;
};

export type AgentWorkforceEventsByPeriod = AgentWorkforceDrillDownData & {
  periodStart: Date;
  periodType: AgentWorkforcePeriodType;
  agentAmount: number;
  secondsOperation: number;
  costPerSecond: number;
};

export type WorkforceDailyWorkWindows = {
  day: Date;

  totalDuration: number;
  totalCost: number;
  totalSecondsOperation: number;
  avgAgentAmount: number;
  workWindows: AgentForecastWorkWindow[];
};

export type AgentForecastWorkWindow = {
  id: string;
  from: Date;
  to: Date;
  agentAmount: number;
  secondsOperation: number;
  costPerSecond: number;
  shifts: WorkforceShiftSettings[];
  shiftWindows?: AgentForecastWorkWindow[];
};

export type AgentWorkforceWaveBase = {
  id: string;
  agentId: string;
  remainingDuration: number;
  scheduledIndex: number;
  source: {
    to: Date;
    duration: number;
  };
};

export type AgentWorkforceWave = AgentWorkforceWaveBase & {
  forecastWindow: AgentForecastWorkWindow;
  forecast: {
    from: Date;
    to: Date;
    durationTotal: number;
  };
};

export type AgentWorkforceSchedule = {
  forecastInterval: [Date, Date];
  workWindows: AgentForecastWorkWindow[];
  waves: AgentWorkforceWave[];
  unscheduledWaves: AgentWorkforceWaveBase[];
};

export function getRandomDateEvents(
  base: EventGroupValues,
  k = 1,
): EventGroupValues {
  return _.keys(base).reduce(
    (acc, key) => ({
      ...acc,

      [key]: base[key] * k * (1 + (Math.random() - 0.5) * 0.5),
    }),
    {} as EventGroupValues,
  );
}

export function scaleEvents<T extends Record<string, number>>(
  base: T,
  k = 1,
): T {
  return _.keys(base).reduce(
    (acc, key) => ({
      ...acc,
      [key]: base[key] * k,
    }),
    {} as T,
  );
}

export function sumEvents<T extends Record<string, number>>(a: T, b: T): T {
  return [..._.keys(a), ..._.keys(b)].reduce(
    (acc, key) => ({
      ...acc,
      [key]: (a[key] ?? 0) + (b[key] ?? 0),
    }),
    {} as T,
  );
}

export function zipEvents<T extends Record<string, number>>(events: T[]): T {
  return _.reduce(
    events,
    (acc, event) => {
      _.keys(event).forEach(key => {
        acc[key] = (acc[key] ?? 0) + event[key];
      });
      return acc;
    },
    {},
  ) as T;
}
