import React, { useState } from 'react';
import _ from 'lodash';
import { AppHeader } from '../../components/AppHeader';
// import * as Icon from '../components/icons';

export type AboutProps = {
  children?: React.ReactNode;
};

export const About = ({ children }: AboutProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <>
      <AppHeader />
      <div id={id} className="flex items-center w-screen p-4 About">
        <h2 className="flex flex-col p-2 w-28 h-28">
          <span className="text-yellow-400">About</span>
        </h2>
      </div>
    </>
  );
};
