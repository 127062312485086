import * as React from 'react';
const TimePredictIcon = props => (
  <svg
    aria-hidden="true"
    data-name="TimePredictIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m89.24,98.11c1.05-1.14,1.05-2.88,0-4.02-.79-.79-18.44-19.32-40.73-19.32S8.57,93.3,7.79,94.09c-1.05,1.14-1.05,2.88,0,4.02.79.79,18.44,19.32,40.73,19.32s39.94-18.53,40.73-19.32Zm-40.73,12.59c-8.04,0-14.6-6.55-14.6-14.6s6.55-14.6,14.6-14.6,14.6,6.55,14.6,14.6-6.55,14.6-14.6,14.6Z" />
    <circle cx={48.51} cy={96.1} r={8.48} />
    <path d="m114.81,66.3h0c-3.29-.45-6.28,1.79-6.72,5.08-1.34,9.56-1.35,18.71-15.51,32.87-2.7,2.7-11.74,12.27-27.35,17.17,14.04-.23,28-5.74,38.68-16.42,8.67-8.67,14.2-19.73,15.84-31.83.6-3.43-1.64-6.42-4.93-6.87Z" />
    <path d="m20.51,68.55c-.9-12.4,3.29-25.11,12.7-34.52,17.19-17.19,45.13-17.19,62.32,0l3.17,3.17,1.17,1.48h-22.71s11.65,11.68,11.65,11.68h30.97s.06-30.97.06-30.97l-11.65-11.67-.04,21.9-4.09-4.09C82.23,3.69,46.67,3.69,24.7,25.51c-12.25,12.25-17.63,28.69-16.14,44.68,3.74-1.05,7.77-1.64,11.96-1.64Z" />
    <path d="m64.15,30.02h0c-3.29,0-5.98,2.69-5.98,5.98v29.11c0,2.69,1.79,5.08,4.33,5.83l18.53,9.12c.6.15,5.99,1.12,7.47-4.03.9-3.14-1.34-5.75-4.18-7.32-2.85-1.58-14.05-7.77-14.05-7.77v-24.77c-.15-3.44-2.84-6.13-6.13-6.13Z" />
  </svg>
);
export default TimePredictIcon;
