import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DataPageParams } from '../common/types';
import { PanelHeaderProps } from '../components/designer/panels/PanelHeader';
import InboxZero from '../components/InboxZero';
import SimulationProductByRuleTableView, {
  SimulationProductByRuleTableViewProps,
} from '../simulation/tableViews/SimulationProductByRuleTableView';
import { swapPolicyRule, swapPolicySelectedIdentity } from './swapPolicy.state';
import useLoadSwapPolicyProductsByRule from './useLoadSwapPolicyProductsByRule';

export type SwapPolicyProductByRuleTableViewProps = {
  panelHeader?: PanelHeaderProps;
};

const SwapPolicyProductByRuleTableView: React.FC<
  SwapPolicyProductByRuleTableViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    swapPolicySelectedIdentity,
  );
  const rule = useRecoilValue(swapPolicyRule(selectedIdentity?.ruleId));
  const [loadCallback, cancelLoad] = useLoadSwapPolicyProductsByRule();

  function callDataLoad(page?: DataPageParams) {
    cancelLoad();
    if (_.isNil(selectedIdentity?.ruleId) || selectedIdentity?.type !== 'items')
      return;

    loadCallback({
      rule,
      group: selectedIdentity?.group,
      page,
    });
  }

  // update data when policy changed
  useEffect(() => {
    callDataLoad();
  }, [rule, selectedIdentity]);

  // cancel requests when unmount
  useEffect(() => {
    cancelLoad();
  }, []);

  if (!selectedIdentity?.ruleId)
    return (
      <InboxZero
        selfCenter
        hasIcon
        message={t`No Rule selected`}
        // message_helper="Select Orders Dataset"
      />
    );

  const viewConfig: SimulationProductByRuleTableViewProps = {
    id: rule?.id,
    title: rule?.title,
    onChange: callDataLoad,
    panelHeader: {
      title: t`Items`,
      onCloseClick: () => setSelectedIdentity(null),
      closable: true,
    },
  };

  return <SimulationProductByRuleTableView {...viewConfig} />;
};

export default SwapPolicyProductByRuleTableView;
