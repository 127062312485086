const MenuDotsVerticalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuDotsVerticalIcon"
    {...props}
  >
    <circle cx={64} cy={64} r={17} strokeWidth={0} />
    <circle cx={64} cy={20} r={17} strokeWidth={0} />
    <circle cx={64} cy={108} r={17} strokeWidth={0} />
  </svg>
);
export default MenuDotsVerticalIcon;
