import * as React from 'react';
const MenuItemShapeBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 140"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuItemShapeBottomIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M128,.33H0v118.1c0,2.29,1.56,4.29,3.79,4.85l60.21,15.05,60.21-15.05c2.23-.56,3.79-2.56,3.79-4.85V.33Z"
    />
    <path
      strokeWidth={0}
      d="M147.14-15.42c-.4-.49-.8-.99-1.17-1.49-.38-.5-.75-1.03-1.1-1.55-.35-.52-.7-1.06-1.03-1.6-.33-.54-.65-1.1-.95-1.65-.3-.56-.6-1.13-.87-1.7-.28-.57-.54-1.16-.79-1.75-.25-.59-.49-1.19-.71-1.79-.22-.6-.43-1.22-.62-1.83-.19-.62-.37-1.24-.53-1.87-.16-.63-.31-1.27-.44-1.91-.13-.64-.25-1.29-.35-1.94-.1-.65-.18-1.32-.25-1.98-.07-.66-.12-1.34-.15-2.01-.03-.67-.05-1.36-.05-2.04s.02-1.37.05-2.04c.03-.67.09-1.34.15-2.01.07-.66.15-1.32.25-1.98.1-.65.22-1.3.35-1.94.13-.64.28-1.28.44-1.91.16-.62.34-1.25.53-1.87.19-.61.4-1.23.62-1.83.22-.6.46-1.2.71-1.79.25-.59.51-1.17.79-1.75.27-.57.57-1.14.87-1.7.3-.56.62-1.11.95-1.65.33-.54.67-1.08,1.03-1.6.35-.52.72-1.04,1.1-1.55.38-.51.77-1.01,1.17-1.49.4-.49.82-.97,1.24-1.44.42-.47.86-.93,1.31-1.37l-14.5-14.51c-.67.67-1.34,1.38-1.98,2.08-.64.7-1.27,1.44-1.89,2.18-.61.74-1.21,1.5-1.78,2.27-.57.77-1.14,1.56-1.67,2.35-.54.8-1.06,1.61-1.56,2.43-.5.82-.99,1.67-1.45,2.51-.46.85-.91,1.72-1.33,2.59-.42.87-.83,1.77-1.2,2.66-.38.9-.74,1.81-1.08,2.73-.34.92-.65,1.86-.94,2.79-.29.94-.56,1.9-.81,2.85-.25.96-.47,1.94-.67,2.91-.2.98-.38,1.97-.53,2.96-.15.99-.28,2-.38,3-.1,1.01-.18,2.03-.23,3.04-.05,1.02-.08,2.06-.08,3.08s.03,2.06.08,3.08c.05,1.01.13,2.04.23,3.04.1,1,.23,2.01.38,3,.15.99.33,1.98.53,2.96.2.97.42,1.95.67,2.91.25.95.52,1.91.81,2.85.29.93.61,1.87.94,2.79.33.91.7,1.83,1.08,2.73.38.89.78,1.79,1.2,2.66.42.87.87,1.74,1.33,2.59.46.85.95,1.69,1.45,2.51.5.82,1.02,1.64,1.56,2.43.54.79,1.1,1.59,1.67,2.35.57.76,1.17,1.53,1.78,2.27.61.74,1.24,1.47,1.88,2.17.64.7,1.31,1.4,1.98,2.08l14.51-14.5c-.45-.45-.89-.91-1.31-1.37-.42-.47-.84-.95-1.24-1.43Z"
    />
  </svg>
);
export default MenuItemShapeBottomIcon;
