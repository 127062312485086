import Konva from 'konva';
import React from 'react';
import { Group, Label, Rect, Tag, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';

const bayColors = TwTheme.extend.colors.bay;

export type BayStructureFeatureProps = {
  width: number;
  height: number;
  frameWidth: number;
  shelfHeight: number;
  shelfPositions: { level: number; y: number }[];
  minLevelHeight: number;
  groundLevelWidthDelta: number;
  groundLevelHeightDelta: number;
  showFrames: boolean;
};

const BayStructureFeature: React.FC<BayStructureFeatureProps> = props => {
  const groundLevelWidthOffset = props.groundLevelWidthDelta * props.width;
  const groundLevelHeightOffset = props.groundLevelHeightDelta * props.height;
  const configFrame: Konva.RectConfig = {
    y: 0,
    width: props.frameWidth,
    height: props.height,
    draggable: false,
    fill: bayColors.DEFAULT,
    strokeWidth: 0,
    perfectDrawEnabled: false,
    visible: props.showFrames,
  };

  const configShelf: Konva.RectConfig = {
    x: props.frameWidth,
    width: props.width - props.frameWidth,
    height: -props.shelfHeight,
    draggable: false,
    fill: bayColors.shelf,
    strokeWidth: 0,
    perfectDrawEnabled: false,
  };

  const groundShelf: Konva.RectConfig = {
    x: -groundLevelWidthOffset,
    y: -groundLevelHeightOffset,
    width: props.width + 2 * groundLevelWidthOffset,
    height: groundLevelHeightOffset,
    draggable: false,
    fill: bayColors.ground,
    strokeWidth: 0,
    perfectDrawEnabled: false,
  };

  const shelfTagSize = 0.5 * props.minLevelHeight;

  return (
    <Group listening={false}>
      <Rect {...groundShelf} />
      {props.shelfPositions.map((shelf, index) => (
        <React.Fragment key={`level-${index}-${shelf.y}`}>
          <Label y={shelf.y + shelfTagSize} x={-shelfTagSize} scaleY={-1}>
            <Tag fill={bayColors.shelf} />
            <Text
              key={`level-label-${index}-${shelf.y}`}
              text={shelf.level.toFixed(0).padStart(2, '0')}
              fill="black"
              fontStyle="bold"
              align="center"
              verticalAlign="middle"
              padding={Math.round(shelfTagSize * 0.1)}
              fontSize={Math.round(shelfTagSize / 2)}
            />
          </Label>

          {shelf.y > 0 ? (
            <React.Fragment key={`level-shelf-${index}-${shelf.y}`}>
              <Text
                key={`hhf-${index}-${shelf.y}`}
                x={-shelfTagSize}
                y={shelf.y}
                text={`HFF: ${shelf.y.toFixed(0)}`}
                fill={bayColors.shelf}
                opacity={0.7}
                align="right"
                verticalAlign="middle"
                scaleY={-1}
                fontSize={Math.round(shelfTagSize / 6)}
              />
              {/* <Text
                key={`w-${index}-${shelf.y}`}
                x={-shelfTagSize}
                y={shelf.y - 6}
                text={`W: ${configShelf.width.toFixed(1)}`}
                fill={bayColors.shelf}
                opacity={0.7}
                align="right"
                verticalAlign="middle"
                scaleY={-1}
                fontSize={Math.round(shelfTagSize / 6)}
              /> */}
              <Rect
                key={`shelf-${index}-${shelf.y}`}
                {...configShelf}
                y={shelf.y}
              />
            </React.Fragment>
          ) : (
            <React.Fragment key={`bay-width-${index}-${shelf.y}`}>
              <Text
                key={`bay-width-${index}-${shelf.y}`}
                x={props.frameWidth + 10}
                y={groundShelf.y / 2 + 10}
                text={`w.: ${configShelf.width.toFixed(1)}`}
                // fill="black"
                fill={bayColors.groundText}
                opacity={0.7}
                align="right"
                verticalAlign="middle"
                scaleY={-1}
                fontSize={Math.round(shelfTagSize / 10)}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
      <Rect {...configFrame} x={0} />
      <Rect {...configFrame} x={props.width - props.frameWidth} />
    </Group>
  );
};

export default React.memo(BayStructureFeature);
