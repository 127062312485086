import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import AssignmentSimulationPanel from '../panels/AssignmentSimulationPanel';
import AssignmentStartPanel from '../panels/AssignmentStartPanel';
import AssignmentStatsPanel from '../panels/AssignmentStatsPanel';
import {
  assignmentDocument,
  assignmentDocumentLoadStatus,
  assignmentSelectedMenuItemId,
} from '../store/assignment.state';

export const AssignmentMainSidebar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(assignmentDocument);
  const documentLoadStatus = useRecoilValue(assignmentDocumentLoadStatus);
  const [stepId, setStepId] = useRecoilState(
    assignmentSelectedMenuItemId(document?.id),
  );

  if (documentLoadStatus === AsyncLoadStatus.Loading) {
    return <LoadingIndicator selfCenter message={t`Loading Assignment`} />;
  }
  if (documentLoadStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator selfCenter message={t`Cannot load Assignment`} />;
  }

  const getContent = () => {
    switch (stepId) {
      case 'assignment-menu-start':
        return <AssignmentStartPanel />;
      case 'assignment-menu-stats':
        return <AssignmentStatsPanel />;
      case 'assignment-menu-simulation':
        return <AssignmentSimulationPanel />;
    }
  };

  return (
    <Container col hasOverflowY>
      {getContent()}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(assignmentDocument);
  const [stepId, setStepId] = useRecoilState(
    assignmentSelectedMenuItemId(document?.id),
  );

  const getTitle = (): string => {
    switch (stepId) {
      case 'assignment-menu-start':
        return t`Dataset Properties`;
      case 'assignment-menu-stats':
        return t`Dataset Stats`;
      case 'assignment-menu-simulation':
        return t`Dataset in Simulations`;
    }
  };
  
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-assignment-main',
    title: getTitle(),
    size: 'sm',
  };
  return (
    <SidebarContainer {...containerProps}>
      <AssignmentMainSidebar />
    </SidebarContainer>
  );
};
