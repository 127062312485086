import {
  SortDirection,
  useExportBayWeightComplianceMutation,
  WeightComplianceBayDetailsFragment,
  WeightComplianceBayFilter,
  WeightComplianceBaySortBy,
  WeightComplianceBaySortOption,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { containsFilter } from '../../../../common/filterHelper';
import { AsyncLoadStatus } from '../../../../common/types';
import useFormatter from '../../../../common/useFormatter';
import DatasetTable, {
  ColumnConfig,
} from '../../../../components/DatasetTable';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { viewerSelectedBayIdAtom } from '../../../../layout/viewer/store/viewer.state';
import useLoadWeightComplianceBays from '../../../hooks/useLoadWeightComplianceBays';
import { getWeightComplianceStatusMap } from '../../../store/weightCompliance.helper';
import {
  weightComplianceBaysData,
  weightComplianceBaysState,
  weightComplianceDataLoadStatus,
  weightComplianceMeta,
} from '../../../store/weightCompliance.state';
import WeightComplianceStatusTag from './WeightComplianceStatusTag';

type WeightComplianceBaysTableRow = WeightComplianceBayDetailsFragment & {
  bayId: string;
  bayTitle: string;
  bayType: string;
  floor: string;
};

const sortFieldsMapping: Partial<
  Record<WeightComplianceBaysTableColumn, WeightComplianceBaySortOption>
> = {
  bayTitle: WeightComplianceBaySortOption.BAY_TITLE,
};

export type WeightComplianceBaysTableColumn =
  keyof WeightComplianceBaysTableRow;

const WeightComplianceBaysTable: React.FC = () => {
  const { t } = useTranslation('simulation');
  const complianceMeta = useRecoilValue(weightComplianceMeta);
  const loadStatus = useRecoilValue(weightComplianceDataLoadStatus);
  const baysData = useRecoilValue(weightComplianceBaysData);
  const [loadCallback, cancelLoad] = useLoadWeightComplianceBays();
  const [state, setState] = useRecoilState(weightComplianceBaysState);
  const selectBay = useSetRecoilState(viewerSelectedBayIdAtom);
  const [runExportCSV] = useExportBayWeightComplianceMutation();
  const formatter = useFormatter();

  const { searchValues, sortValues } = state;

  const statusMap = {
    '': t`All`,
    ...getWeightComplianceStatusMap(t),
  };

  function callDataLoad(
    searchValues: Partial<Record<WeightComplianceBaysTableColumn, string>>,
    sortValues: Partial<Record<WeightComplianceBaysTableColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: WeightComplianceBayFilter = {
      status: searchValues['status'] ? ([searchValues['status']] as any) : null,
      locationBayTitle: containsFilter(searchValues.bayTitle),
      locationPlaneTitle: containsFilter(searchValues.floor),
    };

    const sortBy: WeightComplianceBaySortBy[] = _.map(
      sortValues,
      (value, key, index) => {
        const sortObjectKey = sortFieldsMapping[key];
        return sortObjectKey
          ? { field: sortObjectKey, direction: value }
          : null;
      },
    ).filter(i => !!i);

    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  async function startExportCSV() {
    const { data, errors } = await runExportCSV({
      variables: {
        weightComplianceId: complianceMeta.id,
      },
    });
    //
    return {
      errors: [],
      job: data.exportBayWeightCompliance,
    };
  }

  const columnsConfig: ColumnConfig<WeightComplianceBaysTableRow>[] = [
    {
      field: 'bayId',
      title: t`BAY ID`,
      isHidden: true,
    },
    {
      field: 'status',
      title: t`Status`,
      hasFilter: true,
      render: (status, row) => (
        <WeightComplianceStatusTag
          status={row.status}
          weight={row.weight}
          maxWeight={row.maxWeight}
        />
      ),
      filterOptions: statusMap,
    },
    {
      field: 'bayTitle',
      title: t`Bay`,
      hasFilter: true,
      hasSort: true,
      isHeader: true,
    },
    {
      field: 'floor',
      title: t`Floor`,
      hasFilter: true,
    },
    {
      field: 'bayType',
      title: t`Bay Type`,
    },
    {
      field: 'maxWeight',
      title: t`Bay Weight`,
      render: (weight: number) =>
        !weight ? '-' : formatter.formatWeight(weight).fullString,
    },
    {
      field: 'weight',
      title: t`Assignment Weight`,
      render: (weight: number) =>
        !weight ? '-' : formatter.formatWeight(weight).fullString,
    },
  ];

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = baysData?.content?.length || 0;
  const totalCount = baysData?.totalCount ?? 0;

  const reduceBay = (bayData: WeightComplianceBayDetailsFragment) => {
    return {
      ...bayData,
      bayTitle: bayData.bay.title,
      bayId: bayData.bay.id,
      bayType: (bayData.bay.details as any)?.bayType,
      floor: null,
    } as WeightComplianceBaysTableRow;
  };

  const data = _.map(baysData?.content, reduceBay);

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: WeightComplianceBaysTableRow, index: number) {
    selectBay(row.bayId);
  }
  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        subtitle={t(`{{totalCount}} Filtered Items for`, {
          totalCount,
        })}
        title={t`Bays - weight compliance`}
        isSticky
        id={'weight-compliance-bay-table'}
        columnsConfig={columnsConfig}
        keyFields={['bayId']}
        data={data}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        isLoading={isLoading}
        onRowSelect={onRowSelect}
        onStartExportClick={startExportCSV}
      />
    </ContainerScroll>
  );
};

export default WeightComplianceBaysTable;
