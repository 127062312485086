import Flatten from '@flatten-js/core';
import {
  AislePortal,
  PlaneAccessSpec,
  SrcPlaneInput,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AreaConfigurationMap,
  LayoutBuilderSettings,
  LayoutEntryPoint,
} from '@warebee/shared/data-access-layout-import-converter';
import {
  findPortalsBetween,
  getConnectedComponents,
  getNavigablePolygons,
} from '@warebee/shared/data-access-layout-manager';
import { ConnectivityGraph } from '@warebee/shared/engine-model';
import _ from 'lodash';
import { selector } from 'recoil';
import { getPoints } from '../../../../layout/viewer/store/viewer.helper';
import {
  ConvertedAreaFeature,
  ConvertedLayoutFeature,
} from '../converter.serializable.model';
import {
  converterAreaConfiguration,
  converterFloorAreas,
  converterSelectedFloor,
} from './converter.area.state';
import {
  ConfiguredArea,
  aisleFeatureToVisual,
  bayFeatureToVisual,
  findEntryPoint,
} from './converter.helper';
import {
  converterLayoutBuilder,
  converterWizardStepId,
} from './converter.state';
import { ConverterAreaIntersection } from './converter.types';
import {
  TransformParams,
  getTransformationMatrix,
  transformBox,
  transformConnectivityGraph,
  transformPoint,
} from './transformation.helper';
import { stepsWithValidation } from './validation.defaults';
import {
  AreaWithDetails,
  FloorValidationResult,
  LayoutValidationResult,
} from './validation.types';

const { intersect } = Flatten.BooleanOperations;

function prepareAreaDetails({ area, config }: ConfiguredArea): AreaWithDetails {
  const transformParams: TransformParams = {
    center: Flatten.point(area.size[0] / 2, area.size[1] / 2),
    offset: Flatten.point(config.x, config.y),
    flipX: config.flipX,
    flipY: config.flipY,
    rotation: config.rotation,
  };
  const transformation = getTransformationMatrix(transformParams);
  const shape = new Flatten.Polygon(getPoints(area.outlineShape) as any);

  return {
    area,
    config,
    transformation,
    shape,
    box: transformBox(shape.box, transformation.m),
    aisles: area.aisles.map(aisleFeatureToVisual),
    bays: area.bays.map(bayFeatureToVisual),
  };
}

function findEntryPointBeforeMerge(
  areas: AreaWithDetails[],
  layoutEntryPoint: LayoutEntryPoint,
): [PlaneAccessSpec, string] | null {
  for (const a of areas) {
    const spec = findEntryPoint(a.area, layoutEntryPoint);
    if (spec) {
      return [
        {
          ...spec,
          position:
            spec.position && transformPoint(spec.position, a.transformation),
        },
        a.area.id,
      ];
    }
  }
  return null;
}

function getIntersection(
  a1: AreaWithDetails,
  a2: AreaWithDetails,
): ConverterAreaIntersection | null {
  const o1 = a1.shape.transform(a1.transformation.m);
  let o2 = a2.shape.transform(a2.transformation.m);
  if ([...o1.faces][0].orientation() !== [...o2.faces][0].orientation()) {
    console.log(`### Change orientation: ${a1.area.id} - ${a2.area.id}`);
    o2 = o2.reverse();
  }
  try {
    const overlapPoly = intersect(o1, o2);
    //const de9im = relate(a1, a2) as any as Flatten.DE9IM;
    //return de9im.I2I as any as Flatten.Polygon;
    if (overlapPoly?.faces?.size > 0 && overlapPoly.area() !== 0) {
      console.log(
        `Intersection found: ${a1.area.id} - ${a2.area.id} dH: ${overlapPoly.box.height} dW: ${overlapPoly.box.width}`,
      );
      console.log('Source shapes:', a1, a2);
      console.log('Transformed areas:', o1, o2);
      const result: ConverterAreaIntersection = {
        area1Id: a1.area.id,
        area2Id: a2.area.id,
        shape: overlapPoly,
      };
      return result;
    } else {
      return null;
    }
  } catch (ex) {
    console.log('------------- FIRST  ATTEMPTS FAILED ------------');
    console.warn(ex);
    console.log('source shapes:', a1, a2);
    console.log('Transformed areas:', o1, o2);

    try {
      const overlapPoly = intersect(o2, o1);

      if (overlapPoly?.faces?.size > 0 && overlapPoly.area() !== 0) {
        console.log(
          `Intersection found: ${a1.area.id} - ${a2.area.id} dH: ${overlapPoly.box.height} dW: ${overlapPoly.box.width}`,
        );
        console.log('Source shapes:', a1, a2);
        console.log('Transformed areas:', o1, o2);
        const result: ConverterAreaIntersection = {
          area1Id: a1.area.id,
          area2Id: a2.area.id,
          shape: overlapPoly,
        };
        return result;
      } else {
        return null;
      }
    } catch (ex) {
      console.log('------------- SECOND ATTEMPTS FAILED ------------');
      console.warn(ex);
      console.log('source shapes:', a1, a2);
      console.log('Transformed areas:', o1, o2);
      return null;
    }
  }
}

function transformAndMergeConnectivityGraphs(
  areas: AreaWithDetails[],
): [ConnectivityGraph, AislePortal[]] {
  const transformedAreas = areas.map(a => {
    return {
      ...a.area,
      aisles: a.aisles.map(aisle => aisle.transform(a.transformation)),
      bays: a.bays.map(bay => bay.transform(a.transformation)),
      ...transformConnectivityGraph(a.area, a.transformation),
    };
  });

  const aislePortals = transformedAreas.flatMap(a => a.aislePortals);
  const bayPortals = transformedAreas.flatMap(a => a.bayPortals);

  const interAreaPortals = transformedAreas.slice(0, -1).flatMap((a1, i) => {
    const p1 = getNavigablePolygons(a1);
    const p2 = transformedAreas
      .slice(i + 1)
      .flatMap(a2 => getNavigablePolygons(a2));
    return findPortalsBetween(p1, p2);
  });

  return [
    { aislePortals: aislePortals.concat(interAreaPortals), bayPortals },
    interAreaPortals,
  ];
}
export function getDuplicateAisleIds(planes: SrcPlaneInput[]): string[] {
  return _(planes)
    .flatMap(p => _.map(p.aisles, a => a.id))
    .groupBy()
    .pickBy(x => x.length > 1)
    .keys()
    .value();
}

export function validateFloor(
  floorId: string,
  areas: ConfiguredArea[],
  layoutEntryPoint?: LayoutEntryPoint,
): FloorValidationResult {
  const areasWithDetails = areas.map(a => prepareAreaDetails(a));

  const canIntersect: boolean[][] = areasWithDetails.map(() =>
    _.range(0, areasWithDetails.length).map(() => false),
  );
  const intersections: ConverterAreaIntersection[] = [];

  for (let i = 0; i < areasWithDetails.length; i++) {
    for (let j = i + 1; j < areasWithDetails.length; j++) {
      const a1 = areasWithDetails[i];
      const a2 = areasWithDetails[j];
      const c = a1.box.intersect(a2.box);
      canIntersect[i][j] = canIntersect[j][i] = c;

      // console.log('intersect %s %s %s', a1.area.id, a2.area.id, c);

      if (c) {
        const intersection = getIntersection(a1, a2);
        if (intersection) {
          intersections.push(intersection);
        }
      }
    }
  }

  const interAreaPortals = [];
  let areasNotConnected = true;
  const unreachableBayIds = [];
  let entryPoint: PlaneAccessSpec | null = null;

  if (intersections.length === 0) {
    let entryAreaId = null;
    if (layoutEntryPoint) {
      const found = findEntryPointBeforeMerge(
        areasWithDetails,
        layoutEntryPoint,
      );
      if (found) {
        entryPoint = found[0];
        entryAreaId = found[1];
      }
    }

    const connectableAreas =
      areasWithDetails.length === 1
        ? areasWithDetails
        : areasWithDetails.filter((a, i) => {
            if (canIntersect[i].some(ci => ci) || a.area.id === entryAreaId) {
              return true;
            } else {
              if (entryPoint) {
                unreachableBayIds.push(...a.area.bays.map(b => b.id));
              }
              return false;
            }
          });

    // const mergedAreasMap = transformAndMergeAreas(connectableAreas);

    // const connectivityGraph = generateConnectivityGraph(mergedAreasMap);
    // const areaIdByNavId = {};

    // connectableAreas.forEach(a => {
    //   getNavigablePolygons(a.area).forEach(np => {
    //     areaIdByNavId[np.id] = a.area.id;
    //   });
    // });

    // interAreaPortals.push(
    //   ...connectivityGraph.aislePortals.filter(
    //     ap => areaIdByNavId[ap.aisleId1] !== areaIdByNavId[ap.aisleId2],
    //   ),
    // );

    const [connectivityGraph, iap] =
      transformAndMergeConnectivityGraphs(connectableAreas);
    interAreaPortals.push(...iap);

    const allAreasMerged = areas.length === connectableAreas.length;
    if (allAreasMerged || entryPoint) {
      const ccs = getConnectedComponents(connectivityGraph);

      const potentiallyReachableBayIds = connectableAreas
        .flatMap(a => a.area.bays)
        .map(b => b.id);

      if (entryPoint) {
        const reachableComponent =
          ccs.componentsByNavigableId[entryPoint.aisleId];
        if (reachableComponent) {
          const reachableBayIds = reachableComponent.bayIds;
          unreachableBayIds.push(
            ...potentiallyReachableBayIds.filter(
              bayId => !reachableBayIds?.has(bayId),
            ),
          );
        } else {
          unreachableBayIds.push(...potentiallyReachableBayIds);
        }
      }

      if (allAreasMerged && ccs.components.length === 1) {
        areasNotConnected = false;
      }
    }
  }

  return {
    floorId,
    noStartingPoint: !entryPoint,
    areaIntersections: intersections,
    unreachableBayIds,
    interAreaPortals,
    areasNotConnected,
  };
}

function getFloorConfiguredAreas(
  floorId: string,
  areas: ConvertedAreaFeature[],
  areaConfigurations: AreaConfigurationMap,
): ConfiguredArea[] {
  return _.map(areas, area => {
    const config = areaConfigurations?.[area.id];
    if (!config || config.isDeleted || config.floor !== floorId) {
      return null;
    }
    return {
      area,
      config,
    };
  }).filter(a => a !== null);
}

export const converterSelectedFloorValidationResult =
  selector<FloorValidationResult>({
    key: 'warebee-layout-designer-selected-floor-validation-result',
    get: ({ get }) => {
      const stepId = get(converterWizardStepId);
      const floor = get(converterSelectedFloor);
      const layoutBuilder = get(converterLayoutBuilder);
      const entryPoint = layoutBuilder?.entryPoints?.[floor];
      const areas = get(converterFloorAreas);
      const areaConfigurations = get(converterAreaConfiguration);

      if (!stepsWithValidation.has(stepId) || _.isEmpty(areas)) return null;

      const convertedAreas: ConfiguredArea[] = getFloorConfiguredAreas(
        floor,
        areas,
        areaConfigurations,
      );

      console.time('validation');

      const validationResult = validateFloor(floor, convertedAreas, entryPoint);

      console.timeEnd('validation');

      return validationResult;
    },
  });

export function validateLayout(
  layout: ConvertedLayoutFeature,
  layoutBuilder: LayoutBuilderSettings,
  areasConfig: AreaConfigurationMap,
): LayoutValidationResult {
  return {
    floors: _(layoutBuilder.floors)
      .map(f =>
        validateFloor(
          f.id,
          getFloorConfiguredAreas(f.id, layout.areas, areasConfig),
          layoutBuilder.entryPoints?.[f.id],
        ),
      )
      .value(),
  };
}

export function hasCriticalValidationIssues(
  validation: LayoutValidationResult,
) {
  return _.some(
    validation?.floors,
    f =>
      f.noStartingPoint ||
      !_.isEmpty(f.unreachableBayIds) ||
      !_.isEmpty(f.areaIntersections),
  );
}
