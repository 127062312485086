import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { visibleFeaturesKeys } from '../../store/role.state';
import { featuresList } from '../../types';

const useUserPermissionFeatures = () => {
  const { t } = useTranslation('features');
  const featuresKeys = useRecoilValue(visibleFeaturesKeys);

  const featuresAllMap = featuresList(t);
  const features = _.map(featuresAllMap, feature => {
    const roleFeature = featuresKeys.find(rf => {
      if (rf.startsWith('!')) {
        return (
          rf.slice(1) === feature.id ||
          (rf.slice(1).endsWith('*') &&
            feature.id.startsWith(rf.slice(1).slice(0, -1)))
        );
      } else {
        return (
          rf === feature.id ||
          (rf.endsWith('*') && feature.id.startsWith(rf.slice(0, -1)))
        );
      }
    });

    if (roleFeature) {
      if (roleFeature.startsWith('!')) {
        feature.visible = false;
      } else {
        feature.visible = true;
      }
    } else {
      feature.visible = false; // Set feature to invisible if not included in roleFeatures
    }
    return feature;
  });
  return features;
};

export default useUserPermissionFeatures;
