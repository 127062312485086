import { AreaConfigurationMap } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { ConvertedAreaFeature } from '../converter.serializable.model';
import { getAreaLinkPoints } from './converter.area.helper';
import { DEFAULT_FLOOR_CONFIG } from './converter.defaults';
import {
  converterLayoutSelectedAisle,
  converterLayoutSelectedBay,
  converterLayoutSelectedLocationIdAtom,
} from './converter.layout.state';
import {
  converterAreaLayout,
  converterCalculatedLayout,
  converterLocationsTableState,
} from './converter.state';
import { AreaActionsMode, AreaBox, AreaLinkPoint } from './converter.types';
import { updateLinkedAreas } from './converterConnections.helper';
import { getTransformedAreaBox } from './transformation.helper';

const getKey = (postfix: string) => `warebee-layout-converter.area-${postfix}`;

export const converterSelectedFloorAtom = atom<string>({
  key: getKey('selectedFloor'),
  default: DEFAULT_FLOOR_CONFIG.id,
});

export const converterSelectedFloor = selector<string>({
  key: getKey('converter-current-floor-selector'),
  get: ({ get }) => {
    return get(converterSelectedFloorAtom);
  },
  set: ({ get, set }, value) => {
    set(converterSelectedAreaId, null);
    set(converterEditableAreaId, null);
    set(converterSelectedFloorAtom, value);
  },
});

export const converterAreaConfigurationAtom = atom<AreaConfigurationMap>({
  key: getKey('configuration-dictionary-inner'),
  default: null,
});

export const converterAreaConfiguration = selector<AreaConfigurationMap>({
  key: getKey('configuration-dictionary'),
  get: ({ get }) => get(converterAreaConfigurationAtom),
  set: ({ get, set }, value: AreaConfigurationMap) => {
    const layout = get(converterCalculatedLayout);
    const withLinked = updateLinkedAreas(value, layout?.areas);
    set(converterAreaConfigurationAtom, withLinked);
  },
});

const converterSelectedAreaIdAtom = atom<string>({
  key: getKey('selected-area-id-atom'),
  default: null,
});

export const converterSelectedAreaId = selector<string>({
  key: getKey('selected-area-id-inner'),
  get: ({ get }) => get(converterSelectedAreaIdAtom),
  set: ({ set }, value: string) => {
    set(converterLocationsTableState, current => ({
      ...current,
      searchValues: {
        ...current.searchValues,
        warehouseArea: value,
      },
    }));
    set(converterAreaLinkSelected, null);
    set(converterSelectedAreaIdAtom, value);
  },
});

export const converterDraggableAreaIdAtom = atom<string>({
  key: getKey('draggable-area-id-inner'),
  default: null,
});

export const converterSelectedArea = selector<ConvertedAreaFeature>({
  key: getKey('selected-area'),
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: ({ get }) => {
    const areaId = get(converterSelectedAreaId);
    if (!areaId) return null;
    return get(converterAreaLayout(areaId));
  },
  set: ({ set }, value: ConvertedAreaFeature) => {
    set(converterSelectedAreaId, value?.id);
  },
});

export const converterEditableAreaIdAtom = atom<string>({
  key: getKey('editable-area-inner'),
  default: null,
});

export const converterEditableAreaId = selector<string>({
  key: getKey('editable-area-id'),
  get: ({ get }) => get(converterEditableAreaIdAtom),
  set: ({ get, set }, areaId: string) => {
    const configAll = get(converterAreaConfiguration);
    // if (!_.isNil(areaId)) {
    //   const areaConfig = configAll?.[areaId];
    //   const areaLayout = get(converterAreaLayout(areaId));
    //   set(stageStateById('converter-area-view'), {
    //     autoSizeId: areaId,
    //     contentBounds: [
    //       [areaConfig.x, areaConfig.y],
    //       [
    //         areaConfig.x + areaLayout.size[0],
    //         areaConfig.y + areaLayout.size[1],
    //       ],
    //     ],
    //   });
    // } else {
    //   set(stageStateById('converter-area-view'), {
    //     autoSizeId: 'all-areas',
    //     contentBounds: [
    //       [
    //         _.min(_.map(configAll, a => a.x)),
    //         _.min(_.map(configAll, a => a.y)),
    //       ],
    //       [
    //         _.max(_.map(configAll, a => a.x + 1000)),
    //         _.max(_.map(configAll, a => a.y + 1000)),
    //       ],
    //     ],
    //   });
    // }
    set(converterLayoutSelectedAisle, null);
    set(converterLayoutSelectedBay, null);
    set(converterLayoutSelectedLocationIdAtom, null);
    set(converterEditableAreaIdAtom, areaId);
  },
});

export const converterEditableArea = selector<ConvertedAreaFeature>({
  key: getKey('editable-area'),
  get: ({ get }) => {
    const areaId = get(converterEditableAreaId);
    if (!areaId) return null;
    return get(converterAreaLayout(areaId));
  },
});

export const converterFloorAreas = selector<ConvertedAreaFeature[]>({
  key: getKey('floor-areas'),
  get: ({ get }) => {
    const floor = get(converterSelectedFloor);
    const areaConfigurations = get(converterAreaConfiguration);
    const converted = _.values(areaConfigurations)
      .filter(config => config.floor === floor && !config.isDeleted)
      .map(config => get(converterAreaLayout(config.id)))
      .filter(a => !_.isNil(a));
    return _.isEmpty(converted) ? null : converted;
  },
});

export const converterFloorAreaBoxes = selector<AreaBox[]>({
  key: getKey('area-boxes'),
  get: ({ get }) => {
    const floorAreas = get(converterFloorAreas);
    const areaConfigurations = get(converterAreaConfiguration);

    const activeAreasSet = new Set(_.map(floorAreas, 'id'));

    const links = _(areaConfigurations)
      .filter(
        cfg =>
          cfg.link &&
          activeAreasSet.has(cfg.link.masterAreaId) &&
          activeAreasSet.has(cfg.id),
      )
      .map(cfg => cfg.link)
      .value();

    const areaBoxes = _(floorAreas)
      .map(area => {
        const areaId = area.id;
        const areaConfig = areaConfigurations[area.id];
        const box = getTransformedAreaBox(area, areaConfig);
        const areaBox: AreaBox = {
          areaId,
          box,
          points: getAreaLinkPoints({
            areaId,
            box,
            areaConfig,
            links,
          }),
        };
        return areaBox;
      })
      .value();
    return areaBoxes;
  },
});

export const converterFloorAreaBoxesMap = selector<Record<string, AreaBox>>({
  key: getKey('area-boxes-map'),
  get: ({ get }) => {
    return _.keyBy(get(converterFloorAreaBoxes), 'areaId');
  },
});

export const converterAreaLinkSelected = atom<AreaLinkPoint>({
  key: getKey('area-link-selected'),
  default: null,
});

export const converterAreaActionsMode = atom<AreaActionsMode>({
  key: getKey('area-actions-mode'),
  default: 'none',
});

export const converterShowAreaLabels = atom<boolean>({
  key: getKey('show-area-labels'),
  default: true,
});
