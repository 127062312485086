import { useApolloClient } from '@apollo/client';
import {
  LoadAssignmentDocument,
  LoadAssignmentQuery,
  LoadAssignmentQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  assignmentDocument,
  assignmentDocumentId,
  assignmentDocumentLoadStatus,
  assignmentItemsDataState,
} from '../store/assignment.state';

function useLoadAssignment() {
  const client = useApolloClient();

  const cleanupState = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        set(assignmentDocument, null);
        set(assignmentDocumentId, null);
        set(assignmentDocumentLoadStatus, AsyncLoadStatus.Loading);
        reset(assignmentItemsDataState);
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (assignmentId: string) => {
        const response = await client.query<
          LoadAssignmentQuery,
          LoadAssignmentQueryVariables
        >({
          query: LoadAssignmentDocument,
          variables: {
            assignmentId,
          },
        });

        const data = response.data.assignment;
        set(assignmentDocument, data);
        set(assignmentDocumentId, assignmentId);
        set(assignmentDocumentLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (itemSetId: string) => {
    await cleanupState();
    await loadCallback(itemSetId);
  };
}

export default useLoadAssignment;
