import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import LayoutSimulationPanel from '../panels/LayoutSimulationPanel';
import LayoutStartPanel from '../panels/LayoutStartPanel';
import LayoutStatPanel from '../panels/LayoutStatPanel';
import { layoutMenuItems } from '../store/layout.default';
import {
  layoutDocument,
  layoutDocumentLoadStatus,
  layoutSelectedMenuItemId,
} from '../store/layout.state';

export const LayoutMainSidebar: React.FC = () => {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('designer');
  const document = useRecoilValue(layoutDocument);
  const documentLoadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const [stepId, setStepId] = useRecoilState(
    layoutSelectedMenuItemId(document?.id),
  );
  const menuItems = layoutMenuItems(tApp);

  if (documentLoadStatus === AsyncLoadStatus.Loading) {
    return <LoadingIndicator selfCenter message={t`Loading layout`} />;
  }
  if (documentLoadStatus === AsyncLoadStatus.Error) {
    return <ErrorIndicator selfCenter message={t`Cannot load layout`} />;
  }

  const getContent = () => {
    switch (stepId) {
      case 'layout-menu-start':
        return <LayoutStartPanel />;
      case 'layout-menu-stats':
        return <LayoutStatPanel />;
      case 'layout-menu-simulation':
        return <LayoutSimulationPanel />;
    }
  };

  return (
    <Container col hasOverflowY>
      {getContent()}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('designer');
  const document = useRecoilValue(layoutDocument);

  const [stepId, setStepId] = useRecoilState(
    layoutSelectedMenuItemId(document?.id),
  );

  const getTitle = () => {
    switch (stepId) {
      case 'layout-menu-start':
        return t`Layout Properties`;
      case 'layout-menu-stats':
        return t`Layout Stats`;
      case 'layout-menu-simulation':
        return t`in Simulations`;
    }
  };

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-layout-main',
    title: getTitle(),
    size: 'md',
  };

  return (
    <SidebarContainer {...containerProps}>
      <LayoutMainSidebar />
    </SidebarContainer>
  );
};
