import { useApolloClient } from '@apollo/client';
import {
  LoadItemSetDocument,
  LoadItemSetQuery,
  LoadItemSetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { sidebarStateByType } from '../../store/sidebar.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { getPalletOrderPolicy } from '../store/itemSet.default';
import {
  itemSetDocument,
  itemSetDocumentId,
  itemSetDocumentLoadStatus,
  itemSetItemsDataState,
  itemSetPalletSortPolicy,
} from '../store/itemSet.state';

export function useLoadItemSet() {
  const client = useApolloClient();
  const { t } = useTranslation('dataset');

  const cleanupState = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        const sidebarState = await snapshot.getPromise(
          sidebarStateByType('sidebar-item-set-editors'),
        );
        set(itemSetDocument, null);
        set(itemSetDocumentId, null);
        reset(itemSetItemsDataState);
        set(itemSetDocumentLoadStatus, AsyncLoadStatus.Loading);

        set(sidebarStateByType('sidebar-item-set-editors'), {
          isPinned: sidebarState.isPinned,
          isHidden: true,
          isCollapsed: sidebarState.isPinned ? sidebarState.isCollapsed : true,
        });
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (itemSetId: string) => {
        const system = await snapshot.getPromise(warehouseMeasurementSystem);
        const response = await client.query<
          LoadItemSetQuery,
          LoadItemSetQueryVariables
        >({
          fetchPolicy: 'no-cache',
          query: LoadItemSetDocument,
          variables: {
            itemSetId,
          },
        });

        const itemSet = response.data.itemSet;
        set(itemSetDocument, itemSet);
        set(itemSetDocumentId, itemSetId);
        set(
          itemSetPalletSortPolicy,
          getPalletOrderPolicy(itemSet.stackingPolicy, system, t),
        );
        set(itemSetDocumentLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (itemSetId: string) => {
    await cleanupState();
    await loadCallback(itemSetId);
  };
}
