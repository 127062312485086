import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import AnimatedBorderFeature from '../../../../layout/features/AnimatedBorderFeature';
import { getPoints } from '../../../../layout/viewer/store/viewer.helper';
import {
  converterAreaConfiguration,
  converterEditableArea,
} from '../store/converter.area.state';
import { getLayerConfig } from '../store/converter.helper';
import {
  converterLayoutSelectedAisle,
  converterLayoutSelectedBay,
  converterLayoutSelectedLocation,
} from '../store/converter.layout.state';

const ConverterActiveFeatureLayer: React.FC = () => {
  const selectedAisle = useRecoilValue(converterLayoutSelectedAisle);
  const selectedBay = useRecoilValue(converterLayoutSelectedBay);
  const locationLoadable = useRecoilValueLoadable(
    converterLayoutSelectedLocation,
  );

  const areaResponse = useRecoilValueLoadable(converterEditableArea);
  const areasConfigResponse = useRecoilValueLoadable(
    converterAreaConfiguration,
  );

  if (locationLoadable.state !== 'hasValue') {
    return null;
  }
  const location = locationLoadable.contents;
  const shape = location?.shape ?? selectedBay?.shape ?? selectedAisle?.shape;

  if (_.isEmpty(shape)) return null;

  if (
    _.isNil(shape) ||
    areasConfigResponse.state !== 'hasValue' ||
    areaResponse.state !== 'hasValue' ||
    !areaResponse.contents
  )
    return null;

  const area = areaResponse.contents;
  const areasConfig = areasConfigResponse.contents;
  const areaConfig = areasConfig[area?.id];

  return (
    <Layer {...getLayerConfig(areaConfig, area)}>
      <AnimatedBorderFeature points={_.flatten(getPoints(shape))} />
    </Layer>
  );
};

export default ConverterActiveFeatureLayer;
