import { SpacerTypeOption } from '@warebee/shared/data-access-layout-import-converter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getDefaultMeasure, getMeasureTitle } from '../../common/measureHelper';
import PanelContainer from '../../containers/PanelContainer';
import { getSpacerTypeOptionMap } from '../../import/layout/converter/store/converter.types';
import { getBox } from '../../layout/viewer/store/viewer.helper';
import { viewerSelectedAisle } from '../../layout/viewer/store/viewer.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { WarehouseNavigator } from '../WarehouseNavigator';
import { WarehouseNavigatorItem } from '../WarehouseNavigatorItem';
import DropdownSelector from '../actions/DropdownSelector';
import { ObjectSize } from '../designer/panels/ObjectSize';
import { ContainerScroll } from '../layout/ContainerScroll';
import TitleSection from '../layout/TitleSection';

export type AislePropertiesPanelProps = {};

const AislePropertiesPanel: React.FC<AislePropertiesPanelProps> = props => {
  const { t: tConverter } = useTranslation('designer');
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('app');

  const ms = useRecoilValue(warehouseMeasurementSystem);
  const aisle = useRecoilValue(viewerSelectedAisle);

  const spacerTypeOptions = getSpacerTypeOptionMap(tConverter);
  const aisleType = aisle.navigable
    ? SpacerTypeOption.Aisle
    : SpacerTypeOption.Block;

  const box = getBox(aisle.shape);
  const dX = box.xmax - box.xmin;
  const dY = box.ymax - box.ymin;

  const w = Math.floor(Math.min(dX, dY));
  const l = Math.floor(Math.max(dX, dY));

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(ms, 'size'),
    tMeasures,
  );
  return (
    <>
      <WarehouseNavigator>
        <WarehouseNavigatorItem
          name={aisle.navigable ? t`Aisle` : t`Block`}
          value={aisle.title}
          active
        />
      </WarehouseNavigator>
      <ContainerScroll>
        <PanelContainer
          id="panel-object-property-aisle"
          title={aisle.navigable ? t`Aisle Properties` : t`Block Properties`}
          collapsible
          hasPadding
        >
          <TitleSection inPanelView title={t`Type`} className="mb-1" />

          <DropdownSelector
            className="text-xs my-4"
            panelMode
            widthFull
            DropAlignRight
            label={t`Type`}
            value={aisleType}
            values={[...spacerTypeOptions.keys()]}
            renderValue={key => spacerTypeOptions.get(key)}
            disabled
            //icon={<Icon.DesignerBaysOrder className="w-8 h-8 fill-current" />}
          />

          <TitleSection inPanelView title={t`Size`} className="mb-1" />
          <ObjectSize
            title={t`Size and Position`}
            object={aisle.id}
            unitMeasurement={sizeMeasureValue}
            hasSize
            sizeWidth={Math.floor(w)}
            sizeLength={Math.floor(l)}
            disabled
          />
        </PanelContainer>
      </ContainerScroll>
    </>
  );
};

export default AislePropertiesPanel;
