import { AssignmentPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import { AssignmentComplianceDataRow } from '@warebee/shared/export-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import { ColumnConfig } from '../components/DatasetTable';
import { getAssignmentComplianceStatusMap } from '../simulation/store/assignmentCompliance.helper';
import { AP_FALLBACK_RULE_ID } from '../simulation/store/assignmentPolicy.default';
import AssignmentComplianceRuleTag from './AssignmentComplianceRuleTag';
import AssignmentComplianceStatusTag from './AssignmentComplianceStatusTag';
import ItemTag from '../simulation/tags/ItemTag';

export function getAssignmentComplianceTableConfig(
  policy: AssignmentPolicyFragment,
  t: TFunction<'simulation'>,
): ColumnConfig<AssignmentComplianceDataRow>[] {
  const statusMap = {
    '': t(`All`, { ns: 'simulation' }),
    ...getAssignmentComplianceStatusMap(t),
  };

  const ruleTitlesMap = _.reduce(
    policy?.rules,
    (acc, rule) => {
      return {
        ...acc,
        [rule.id]: rule.title,
      };
    },
    {
      '': t(`All`, { ns: 'simulation' }),
      [AP_FALLBACK_RULE_ID]: t(`Default rule`, { ns: 'simulation' }),
    },
  );

  function renderRules(rulesIds: string[], hasIssue: boolean) {
    return _.map(rulesIds, ruleId => {
      return (
        <AssignmentComplianceRuleTag
          key={`rule-tag-${ruleId}`}
          ruleId={ruleId}
          hasIssue={hasIssue}
        />
      );
    });
  }

  return [
    {
      field: 'status',
      title: t(`Status`, { ns: 'simulation' }),
      hasFilter: true,
      hiddenInExport: true,
      render: (status, row) => (
        <AssignmentComplianceStatusTag status={row.status} />
      ),
      filterOptions: statusMap,
    },
    {
      field: 'statusString',
      title: t(`Status`, { ns: 'simulation' }),
      hasFilter: true,
      hiddenInBrowser: true,
    },
    {
      field: 'consignee',
      title: t(`Consignee`, { ns: 'simulation' }),
      isHeader: true,
      hasSort: true,
    },
    {
      field: 'sku',
      title: t(`Item`, { ns: 'simulation' }),
      isHeader: true,
      hasSort: true,
      render: (sku: string, row) => (
        <ItemTag
          title={sku}
          filters={{ consignee: row['consignee'], sku }}
          // color={getQualitativeColor(sku, 'dimension')[0]}
        />
      ),
    },
    {
      field: 'locationId',
      title: t(`Location`, { ns: 'simulation' }),
      isHeader: true,
      hasSort: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'skuGroup',
      title: t(`Item Group`, { ns: 'simulation' }),
      hasSort: false,
    },
    {
      field: 'subGroup',
      title: t(`Sub Group`, { ns: 'simulation' }),
      hasSort: false,
    },
    {
      field: 'locationRules',
      title: t(`Location Rules`, { ns: 'simulation' }),
      hiddenInBrowser: true,
    },
    {
      field: 'locationRuleIds',
      title: t(`Location Rules`, { ns: 'simulation' }),
      hasFilter: true,
      filterOptions: ruleTitlesMap,
      hiddenInExport: true,
      render: (values: string[], row) => renderRules(values, false),
    },
    {
      field: 'itemRules',
      title: t(`Item Rules`, { ns: 'simulation' }),
      hiddenInBrowser: true,
    },
    {
      field: 'itemRuleIds',
      title: t(`Item Rules`, { ns: 'simulation' }),
      hasFilter: true,
      filterOptions: ruleTitlesMap,
      hiddenInExport: true,
      render: (values: string[], row) => renderRules(values, false),
    },
    {
      field: 'violatedRules',
      title: t(`Violated Rules`, { ns: 'simulation' }),
      hiddenInBrowser: true,
    },
    {
      field: 'violatedRuleIds',
      title: t(`Violated Rules`, { ns: 'simulation' }),
      hasFilter: true,
      filterOptions: ruleTitlesMap,
      hiddenInExport: true,
      render: (values: string[], row) => renderRules(values, true),
    },
  ];
}
