import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../../../common/utils';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import { ActionBar } from '../../../components/nav/ActionBar';
import { simulationWizardSelectedStepId } from '../../store/simulation.wizard.state';
import IssuesSummary from './IssuesSummary';

const SimulationIssuesPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const step = useRecoilValue(simulationWizardSelectedStepId);

  const displayOptions = [
    {
      label: t('All'),
      value: 'all',
      // description: 'Displays all stats and issues',
    },
    {
      label: t('Issues'),
      value: 'issues',
      // description: 'Displays only stats with issues',
    },
    {
      label: t('Stats'),
      value: 'stats',
      // description: 'Displays stats with values, but not issues',
    },
  ];
  const [displayOption, setDisplayOption] = useState('all');

  return (
    <Container
      col
      hasOverflowY
      className={classNames('bg-issue-transparent/40')} //bg-app-panel/70
    >
      <ScreenTitle
        title={t`Stats & Issues`}
        subtitle={t`Pre-Analyse Dataset`}
        isSticky
        helpNavTo={'simulation/dataset/simulation-dataset-stats'}
        icon={Icon.DataStatsPie}
      />

      <ActionBar sticky stickyTop className={cn('top-12 py-2')}>
        <ButtonSwitchMulti
          className={cn('mx-2 my-1 w-full text-xs')}
          autoSize
          selectedIndex={displayOptions.findIndex(
            option => option.value === displayOption,
          )}
          onClick={index => setDisplayOption(displayOptions[index].value)}
          options={displayOptions}
          buttonType="header"
        />
      </ActionBar>

      {(step === 'import-layout' || step === 'data-issues') && (
        <IssuesSummary dataType="layout" displayOption={displayOption} />
      )}
      {(step === 'import-assignments' || step === 'data-issues') && (
        <IssuesSummary dataType="assignment" displayOption={displayOption} />
      )}
      {(step === 'import-items' || step === 'data-issues') && (
        <IssuesSummary dataType="itemSet" displayOption={displayOption} />
      )}
      {(step === 'import-orders' || step === 'data-issues') && (
        <>
          <IssuesSummary dataType="orderSet" displayOption={displayOption} />
          <IssuesSummary
            dataType="orderedItems"
            displayOption={displayOption}
          />
        </>
      )}
    </Container>
  );
};
export default SimulationIssuesPanel;
