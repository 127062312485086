import * as React from 'react';
const SimulationAddLayoutIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationAddLayoutIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="121.45 104.97 94.26 120.67 94.26 89.27 121.45 73.57 121.45 104.97"
    />
    <polygon
      strokeWidth={0}
      points="93.62 56.67 120.74 72.33 93.62 87.99 66.49 72.33 93.62 56.67"
    />
    <polygon
      strokeWidth={0}
      points="92.9 89.29 92.9 120.61 65.78 104.95 65.78 73.63 92.9 89.29"
    />
    <polygon
      strokeWidth={0}
      points="91.45 54.34 64.26 70.04 64.26 38.64 91.45 22.94 91.45 54.34"
    />
    <polygon
      strokeWidth={0}
      points="63.62 6.04 90.74 21.7 63.62 37.36 36.49 21.7 63.62 6.04"
    />
    <polygon
      strokeWidth={0}
      points="62.9 38.66 62.9 69.98 35.78 54.32 35.78 23 62.9 38.66"
    />
    <path
      strokeWidth={0}
      d="M34.26,57.96l-27.71,16v32s27.71,16,27.71,16l27.71-16v-32s-27.71-16-27.71-16ZM34.26,74.47c1.22,0,2.21.99,2.21,2.21v11.03s11.59,0,11.59,0c1.22,0,2.21.99,2.21,2.21,0,1.22-.99,2.21-2.21,2.21h-11.59s0,12.14,0,12.14c0,1.22-.99,2.21-2.21,2.21s-2.21-.99-2.21-2.21v-12.14s-11.59,0-11.59,0c-1.22,0-2.21-.99-2.21-2.21,0-1.22.99-2.21,2.21-2.21h11.59s0-11.03,0-11.03c0-1.22.99-2.21,2.21-2.21Z"
    />
  </svg>
);
export default SimulationAddLayoutIcon;
