import classNames from 'classnames';
import React from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';

type ValidationErrorProps = {
  name: string;
  errors: FieldErrors;
};

export default function FormError({ name, errors }: ValidationErrorProps) {
  const fieldError = errors[name] as FieldError;

  if (!fieldError) {
    return null;
  }

  return <FormErrorField message={fieldError.message} />;
}

type FormErrorProps = {
  children?: React.ReactNode;
  className?: string;
  message: string;
};

export function FormErrorField({
  message,
  children,
  className,
}: FormErrorProps) {
  return (
    <div
      data-component="FormError"
      className={classNames(
        'select-text',
        'p-2 ltr:pl-4 rtl:pr-4 pr-4 mt-0',
        'text-white bg-alerts-error',
        className,
      )}
    >
      {message}
      {children}
    </div>
  );
}
