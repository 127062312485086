import * as React from 'react';
const AlignVerticalCenterIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlignVerticalCenterIcon"
    {...props}
  >
    <rect
      height={0.25}
      x={43.85}
      y={55.92}
      strokeWidth={0}
      transform="translate(-26.79 47.43) rotate(-45.01)"
    />
    <polygon
      strokeWidth={0}
      points="67.69 32.38 67.69 32.38 67.52 32.2 67.69 32.38"
    />
    <polygon
      strokeWidth={0}
      points="76.18 23.89 76.18 23.89 76 23.72 76.18 23.89"
    />
    <polygon
      strokeWidth={0}
      points="69.39 30.68 69.39 30.68 69.21 30.5 69.39 30.68"
    />
    <rect width={28} height={100} x={34.19} y={14} strokeWidth={0} />
    <rect width={63.5} height={4.44} x={5.69} y={61.78} strokeWidth={0} />
    <rect width={28.5} height={4.44} x={97.19} y={61.78} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="96.7 31.61 96.58 31.5 83.01 31.5 96.7 45.19 96.7 31.61"
    />
    <polygon
      strokeWidth={0}
      points="68.7 96.5 76.73 96.5 68.7 88.47 68.7 96.5"
    />
    <polyline
      strokeWidth={0}
      points="93.84 59.31 96.7 62.16 96.7 48.58 79.61 31.5 68.7 31.5 68.7 34.16 93.84 59.31 93.84 59.31"
    />
    <polygon
      strokeWidth={0}
      points="96.7 96.1 96.7 82.53 68.7 54.53 68.7 68.1 96.7 96.1"
    />
    <polygon
      strokeWidth={0}
      points="68.73 37.59 68.73 51.16 96.7 79.13 96.7 65.55 68.73 37.59"
    />
    <polygon
      strokeWidth={0}
      points="68.7 85.07 80.12 96.5 93.7 96.5 68.7 71.5 68.7 85.07"
    />
  </svg>
);
export default AlignVerticalCenterIcon;
