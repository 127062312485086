import classNames from 'classnames';
import React from 'react';

export type TooltipProps = {
  children?: React.ReactNode;
  label?: string;
  value?: string;
};

const Tooltip: React.FC<TooltipProps> = ({ children, label, value }) => {
  const tooltipClass = classNames('flex items-center', 'p-1 px-2');

  const labelClass = classNames(
    tooltipClass,
    'text-xs',
    'bg-menu-active',
    'text-menu-active-text',
    'uppercase',
  );

  const valueClass = classNames(
    tooltipClass,
    'text-sm font-bold',
    'bg-app-panel-dark',
    'text-menu-text',
    'text-center',
  );

  return (
    <div data-component="Tooltip" className={classNames('min-w-tooltip')}>
      {label && <label className={labelClass}>{label}</label>}
      {value && <div className={valueClass}>{value}</div>}
      {children}
    </div>
  );
};

export default Tooltip;
