import { InferResult } from 'kysely';
import _ from 'lodash';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

export type FeedAgentSummaries = InferResult<
  ReturnType<typeof getFeedAgentSummaryQuery>
>;
export type FeedAgentSummary = FeedAgentSummaries[number];

export const getFeedAgentSummaryQuery = (
  params: ActivityFeedQueryBuilderParams,
) => {
  return datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .selectFrom('af')
    .select(({ fn }) => [
      'agentId',
      fn.countAll().as('totalEvents'),
      fn.min('eventEndTime').as('minTime'),
      fn.max('eventEndTime').as('maxTime'),
    ])
    .where('locationId', 'is not', null)
    .where('agentId', 'is not', null)
    .groupBy('agentId');
};

const isDateField = (f: string): boolean => {
  const fName = _.camelCase(f);
  return fName === 'minTime' || fName === 'maxTime';
};

export function postProcessFeedAgentSummary(
  events: Record<string, any>[],
): FeedAgentSummary[] {
  return postProcessDefault<FeedAgentSummary>(events, isDateField);
}
