import { useApolloClient } from '@apollo/client';
import {
  LoadOrderedQuantityItemsDocument,
  LoadOrderedQuantityItemsQuery,
  LoadOrderedQuantityItemsQueryVariables,
  OrderedQuantityReportItemFilter,
  OrderedQuantityReportItemSortBy,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { simulationIssueSelected } from '../store/simulation.issues.state';
import { simulationCurrentId } from '../store/simulation.state';
import {
  orderedQuantityItems,
  orderedQuantityItemsLoadStatus,
} from '../store/simulationReport.state';

export type LoadOrderedItemsParams = {
  limit?: number;
  skip?: number;
  isAppend?: boolean;
  filter?: OrderedQuantityReportItemFilter;
  sortBy?: OrderedQuantityReportItemSortBy[];
};

function useLoadOrderedItems() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Ordered Items`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadOrderedItemsParams) => {
        set(orderedQuantityItemsLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadOrderedItemsParams) => {
        const simulationId = await snapshot.getPromise(simulationCurrentId);
        const current = await snapshot.getPromise(orderedQuantityItems);
        const selectedIssue = await snapshot.getPromise(
          simulationIssueSelected,
        );

        const query = client.watchQuery<
          LoadOrderedQuantityItemsQuery,
          LoadOrderedQuantityItemsQueryVariables
        >({
          query: LoadOrderedQuantityItemsDocument,
          variables: {
            simulationId,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip,
            },
            filter: params.filter,
            sortBy: params.sortBy,
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(orderedQuantityItemsLoadStatus, AsyncLoadStatus.Error);
            return;
          }

          let content =
            data?.simulation?.orderedQuantityReport?.items?.content || [];
          if (params.isAppend) {
            content = [...current.content, ...content];
          }

          set(orderedQuantityItems, {
            content,
            totalCount:
              data?.simulation?.orderedQuantityReport?.items?.totalCount,
          });

          set(orderedQuantityItemsLoadStatus, AsyncLoadStatus.Ok);
        });
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadOrderedItemsParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadOrderedItems;
