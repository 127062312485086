import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../icons';
import { StepCounterCircle } from './StepCounterCircle';

export const StepCountSize = {
  xs: 'w-6 h-6 text-minimal',
  sm: 'w-8 h-8 text-sm',
  md: 'w-8 h-8 ltr:mr-1 rtl:ml-1 text-sm xl:text-base',
  lg: 'w-10 h-10 m-2 text-lg',
  policy: 'w-10 h-6 text-sm xl:text-base',
  title: 'w-5 h-5 xl:w-7 xl:h-7 text-sm xl:text-base',
};

export type StepListItemHelperProps = {
  children?: React.ReactNode;
  className?: string;
  classNameTitle?: string;
  classNameTitleContainer?: string;
  title?: string | React.ReactNode;
  stepCountSize?: keyof typeof StepCountSize | string;
  stepCountId?: string;
  isPrimary?: boolean;
  isActive?: boolean;
  hasIssue?: boolean;
  truncate?: boolean;
};

export const StepListItemHelper = ({
  children,
  className,
  classNameTitle,
  classNameTitleContainer,
  stepCountId,
  stepCountSize,
  title,
  isPrimary,
  isActive,
  hasIssue,
  truncate,
}: StepListItemHelperProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <div
      data-component="StepListItemHelper"
      id={id}
      key={stepCountId}
      className={classNames(
        'flex flex-1 text-sm lg:text-base',
        isActive ? 'text-menu-text' : '',
        children ? 'mb-1' : 'items-start lg:items-center',
        className,
      )}
    >
      {stepCountId && (
        <StepCounterCircle
          isActive={isActive}
          hasIssue={hasIssue}
          isPrimary={isPrimary}
          stepCountId={stepCountId}
          stepCountSize={stepCountSize || 'sm'}
        />
      )}

      <div
        className={classNames('flex-1 flex flex-col', {
          'flex-col items-start': children,
          'items-start': !children,
          'ltr:ml-2 ltr:xl:ml-2 rtl:xl:mr-2 rtl:mr-2': stepCountId,
          truncate: truncate,
          classNameTitleContainer,
        })}
      >
        <div
          className={classNames(
            'text-bold w-full',
            'flex items-center',
            { truncate: truncate },
            classNameTitle,
          )}
        >
          {hasIssue && (
            <Icon.TriangleInfo className="w-5 h-5 xl:mr-1 xl:ml-1 ltr:xl:mr-2 rtl:xl:ml-2 fill-current" />
          )}
          {title}
        </div>
        {children && <span>{children}</span>}
      </div>
    </div>
  );
};
