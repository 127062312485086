import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { ContainerScroll } from '../../../components/layout/ContainerScroll';
import { StatGroup } from '../../../components/stats/StatGroup';
import { analyzeProductMetricSelected } from '../../../metrics/analyzeProduct/analyzeProductMetric.state';
import { analyzeJobDetailsSelectedRow } from '../../store/analyze.state';
import { optimisationSelectedAssignmentDiff } from '../../store/optimisation.state';
import OptimisationReassignJobsTable from './OptimisationReassignJobsTable';
import OptimizationAssignmentDiffTable from './OptimizationAssignmentDiffTable';

const viewOptionsIds = ['diff', 'moves', 'jobs'] as const;
type ViewOptionId = (typeof viewOptionsIds)[number];

const OptimisationReassignPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const resetSelectedDiff = useResetRecoilState(
    optimisationSelectedAssignmentDiff,
  );
  const resetSelectedJob = useResetRecoilState(analyzeJobDetailsSelectedRow);
  const [viewAs, setViewAs] = useState<ViewOptionId>('jobs');
  const setProductHeatmapMetric = useSetRecoilState(
    analyzeProductMetricSelected,
  );

  useEffect(() => {
    setProductHeatmapMetric('reassign-task-type');
    // return () => {
    //   setProductHeatmapMetric('visits');
    //   resetSelectedJob();
    //   resetSelectedDiff();
    // };
  }, []);

  const showOptions = _.keyBy(
    [
      {
        key: 'jobs',
        title: t`Show Jobs`,
      },
      {
        key: 'diff',
        title: t`Show Diff table`,
      },
    ],
    o => o.key,
  ) as Record<ViewOptionId, { key: ViewOptionId; title: string }>;

  function onChange(option: ViewOptionId) {
    resetSelectedDiff();
    resetSelectedJob();
    setViewAs(option);
  }

  return (
    <ContainerScroll className="flex flex-col">
      <StatGroup
        className='z-toolbar'
        title={
          viewAs === 'jobs'
            ? t`Optimisation — Moves Plan`
            : t`Optimisation — Diff Plan`
        }
        helpNavTo={'simulation/optimise/simulation-optimise-reassign'}
        titleAction={
          <DropdownSelector
            classNameLabel="text-sm"
            onClick={e => {
              e.stopPropagation();
            }}
            DropAlignRight
            buttonTransparent
            vertical
            panelMode
            value={'...'}
            values={_.keys(showOptions) as ViewOptionId[]}
            renderValue={o => showOptions[o]?.title ?? '...'}
            onChange={async (option: ViewOptionId, e) => {
              e.stopPropagation();
              onChange(option);
            }}
          />
        }
      />
      {viewAs === 'diff' && <OptimizationAssignmentDiffTable />}
      {viewAs === 'jobs' && <OptimisationReassignJobsTable />}
    </ContainerScroll>
  );
};

export default OptimisationReassignPanel;
