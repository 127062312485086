import * as React from 'react';
const Mhe14BAgvTowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe14BAgvTowIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M2,94.08c0,.97.32,1.78.97,2.42.65.65,1.45.97,2.42.97h3.63v-.73c.32-3.79,1.8-6.94,4.42-9.45,2.62-2.5,5.83-3.79,9.63-3.88h.73c3.88.32,7.04,1.8,9.51,4.42,2.46,2.62,3.77,5.83,3.94,9.63h83.8c1.29,0,2.38-.44,3.27-1.33.89-.89,1.33-1.98,1.33-3.27v-14.89H2v16.11h0ZM11.32,97.47c.24,4.76,2.22,8.21,5.93,10.35,3.71,2.14,7.71,2.16,11.99.06,1.86-1.13,3.31-2.6,4.36-4.42,1.05-1.82,1.57-3.81,1.57-5.99-.24-4.68-2.24-8.11-5.99-10.29-3.75-2.18-7.73-2.18-11.93,0-1.86,1.13-3.31,2.6-4.36,4.42s-1.57,3.77-1.57,5.87ZM79.14,58.72c0-3.07-1.03-5.61-3.09-7.63-2.06-2.02-4.58-3.07-7.57-3.15h-8.72c-2.26,0-4.44.28-6.54.85l-37.78,9.45c-2.34.65-4.4,1.76-6.18,3.33-1.78,1.57-3.11,3.53-4,5.87l-3.15,8.48h77.14v-17.2h-.12ZM53.71,46.98c2.02-.4,4.04-.61,6.05-.61h8.72c2.26,0,4.32.54,6.18,1.63,1.86,1.09,3.35,2.6,4.48,4.54v-3.88c0-2.26-.77-4.14-2.3-5.63-1.53-1.49-3.43-2.28-5.69-2.36h-8.36c-.97,0-1.82-.26-2.54-.79s-1.25-1.27-1.57-2.24l-4.6-15.02c-.4-1.05-1.09-1.94-2.06-2.66l-2.18-1.21c-.57-.32-1.13-.42-1.7-.3-.57.12-1.01.48-1.33,1.09-.32.61-.4,1.19-.24,1.76l7.14,25.67h0ZM105.17,109.58c3.07.08,5.73-.75,7.99-2.48,2.26-1.74,3.71-4.1,4.36-7.08h-23.37c.57,2.66,1.86,4.86,3.88,6.6,2.02,1.74,4.4,2.72,7.14,2.97h0ZM80.95,34.14c.73.32,1.33.16,1.82-.48l1.57-3.15,1.09.61c1.29.73,2.36,1.68,3.21,2.85.85,1.17,1.39,2.48,1.63,3.94l1.94,12.47c1.13,7.67.65,15.26-1.45,22.77l-.85,2.79h36.09l-5.45-20.59c-.89-3.31-2.34-6.3-4.36-8.96-2.02-2.66-4.52-4.84-7.51-6.54l-22.4-12.96,1.57-3.15c.32-.73.12-1.27-.61-1.63s-1.29-.22-1.7.42l-5.09,9.93c-.32.73-.16,1.29.48,1.7h0Z"
    />
  </svg>
);
export default Mhe14BAgvTowIcon;
