import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { namedColorsByKey } from '../../store/namedColors.state';

export type RuleSectionContainerProps = {
  children?: React.ReactNode;
  isSelected?: boolean;
  // isActive?: boolean;
  hasIssue?: boolean;
  transparent?: boolean;
  className?: string;
  onClick?: () => void;
  namedColorKey?: string;
  color?: string;
  hasPadding?: boolean;
};

const RuleSectionContainer: React.FC<RuleSectionContainerProps> = props => {
  const colorPair = useRecoilValue(namedColorsByKey(props.namedColorKey));
  const hasColor = !_.isEmpty(colorPair);
  const bgColor = _.head(colorPair);

  return (
    <div
      data-component="RuleSectionContainer"
      className={classNames([
        'flex flex-col flex-1',
        'w-full',
        'rounded',
        'group',
        // 'space-y-0.5 2xl:space-y-1',
        props.hasPadding && 'p-0.5 md:p-1 xl:p-2',
        {
          'bg-app-panel-dark/30 group-hover:bg-opacity-90':
            !props.isSelected && !props.transparent,
        },
        {
          'bg-menu-active text-menu-active-text group-hover:bg-menu-active group-hover:text-menu-active-text':
            props.isSelected && !bgColor && !props.transparent,
        },
        {
          'shadow-2xl border border-app-panel-dark/50': props.isSelected,
        },
        { 'bg-app-panel/50 bg-issue-transparent/30': props.hasIssue },
        'transition duration-200',
        props.className,
      ])}
      style={
        props.color
          ? { backgroundColor: props.color }
          : { backgroundColor: bgColor }
      }
      onClick={e => {
        if (props.onClick) {
          e.stopPropagation();
          props.onClick && props.onClick();
        }
      }}
    >
      {props.children}
    </div>
  );
};
export default RuleSectionContainer;
