import { useApolloClient } from '@apollo/client';
import {
  LoadRoutingInaccessibleTotalDocument,
  LoadRoutingInaccessibleTotalQuery,
  LoadRoutingInaccessibleTotalQueryVariables,
  RoutingPolicyDirectionThresholdFragment,
  RoutingPolicyFeatureRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../common/types';
import { cloneWithoutTypename } from '../common/utils';
import {
  routingPolicyCheckResultByRule,
  routingPolicyCheckResultByRuleLoadStatus,
} from '../simulation/store/routingPolicy/routingPolicy.state';
import { simulationLayoutId } from '../simulation/store/simulation.state';
import { errorAppender } from '../store/error.state';

export type CheckRoutingPolicyRuleParams = {
  ruleId: string;
  featureRules: RoutingPolicyFeatureRuleFragment[];
  threshold?: RoutingPolicyDirectionThresholdFragment;
};

function useCheckRoutingPolicyRule() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Routing policy check results`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: CheckRoutingPolicyRuleParams) => {
        set(
          routingPolicyCheckResultByRuleLoadStatus(params.ruleId),
          AsyncLoadStatus.Loading,
        );
      },
  );

  const callCheck = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: CheckRoutingPolicyRuleParams) => {
        const layoutId = await snapshot.getPromise(simulationLayoutId);

        // if (_.isNil(rule) || _.isNil(simulation)) {
        //   console.warn('Simulation and Routing policy should be provided');
        //   return;
        // }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(
            routingPolicyCheckResultByRuleLoadStatus(params.ruleId),
            AsyncLoadStatus.Error,
          );
        }

        const query = client.watchQuery<
          LoadRoutingInaccessibleTotalQuery,
          LoadRoutingInaccessibleTotalQueryVariables
        >({
          query: LoadRoutingInaccessibleTotalDocument,
          variables: {
            input: {
              layoutId,
              featureRules: _.map(params.featureRules, cloneWithoutTypename),
              threshold: cloneWithoutTypename(params.threshold),
            },
            page: null,
          },
        });

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }
            const checkResult = data?.findLayoutInaccessibleLocations;
            set(
              routingPolicyCheckResultByRule(params.ruleId),
              checkResult.totalCount,
            );
            set(
              routingPolicyCheckResultByRuleLoadStatus(params.ruleId),
              AsyncLoadStatus.Ok,
            );
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: CheckRoutingPolicyRuleParams) {
    await initLoading(params);
    await callCheck(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useCheckRoutingPolicyRule;
