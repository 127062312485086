import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  optimisationAnalyzeId,
  optimisationSelectedOrder,
} from '../store/optimisation.state';
import { simulationHeatmapLocationByLevel } from '../store/simulation.heatmap.state';
import SimulationLocationHeatmapLayerBase from './SimulationLocationHeatmapLayerBase';

export const OptimisationLocationLayer: React.FC = () => {
  const analyzeId = useRecoilValue(optimisationAnalyzeId);
  const heatmapByLocLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByLevel(analyzeId),
  );
  const selectedOrderLoadable = useRecoilValueLoadable(
    optimisationSelectedOrder,
  );

  const heatmapByLoc =
    heatmapByLocLoadable.state === 'hasValue'
      ? heatmapByLocLoadable.contents
      : null;

  const selectedOrder =
    selectedOrderLoadable?.state === 'hasValue'
      ? selectedOrderLoadable.getValue()
      : null;

  const locationSet = selectedOrder
    ? new Set(selectedOrder.visitedLocations)
    : null;

  return (
    <SimulationLocationHeatmapLayerBase
      heatmapByLoc={heatmapByLoc}
      filter={location =>
        _.isNil(locationSet) || locationSet.has(location.locationId)
      }
    />
  );
};
