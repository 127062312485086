import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
// import { LogoHeader } from './LogoHeader';

export type ActionBarProps = {
  children?: React.ReactNode;
  className?: string;
  transparent?: boolean;
  sticky?: boolean;
  stickyTop?: boolean;
  stickyBottom?: boolean;
  truncate?: boolean;
};

export const ActionBar = ({
  children,
  className,
  transparent,
  sticky,
  stickyTop,
  stickyBottom,
  truncate,
}: ActionBarProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="ActionBar"
      className={classNames(
        'flex items-center w-full',
        { truncate: truncate },
        {
          'bg-sidebar-header/50 backdrop-blur saturate-110 text-menu-text':
            sticky && !transparent,
        },
        stickyTop ? 'sticky top-0 z-menu' : '',
        stickyBottom ? 'absolute bottom-0 z-footer' : '',
        transparent ? '' : 'bg-sidebar-title/50',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const ActionBarFooter = ({
  children,
  className,
  transparent,
  sticky,
}: ActionBarProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <footer
      id={id}
      data-component="ActionBarFooter"
      className={classNames(
        'flex items-center w-full',
        {
          'sticky bottom-0': sticky,
        },
        transparent
          ? ''
          : 'bg-sidebar-footer/80 backdrop-blur saturate-110 text-menu-text',
        className,
      )}
    >
      {children}
    </footer>
  );
};
