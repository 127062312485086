import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimulationsListPanel from '../../components/SimulationsListPanel';
import { orderSetDocument } from '../store/orderSet.state';

const OrderSetSimulationPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(orderSetDocument);
  const simulations = document?.simulationsWith?.content;
  return (
    <SimulationsListPanel subtitle={t`Order Set`} simulations={simulations} />
  );
};

export default OrderSetSimulationPanel;
