import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppBillingContentProps } from './AppBillingOneTimePriceContent';

export type AppBillingSubscriptionPriceContentProps = AppBillingContentProps & {
  billingInterval: string;
};

const AppBillingSubscriptionPriceContent: React.FC<
  AppBillingSubscriptionPriceContentProps
> = props => {
  const { t } = useTranslation('app');
  const price = props.price.value;
  const currency = props.price.unit;

  return (
    <div className="flex items-center justify-center mt-8">
      <div className="flex items-baseline">
        <span className="text-4xl text-opacity-75">{currency}</span>
        <span className="text-7xl">{price}</span>
      </div>

      <div className="flex flex-col items-start self-center ltr:ml-2 rtl:mr-2">
        <div className="items-start text-base">{t`per`}</div>
        <div className="text-2xl text-opacity-75">{props.billingInterval}</div>
      </div>
    </div>
  );
};

export default AppBillingSubscriptionPriceContent;
