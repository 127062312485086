import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  applyColorOpacity,
  getQualitativeColor,
} from '../../../common/color.helper';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { getWaypointPolicyOrderedLocationsMatch } from '../../store/waypointPolicy/waypointPolicy.helper';
import {
  waypointPolicy,
  waypointPolicyBayLocations,
  waypointPolicySelectedIdentity,
} from '../../store/waypointPolicy/waypointPolicy.state';

const WaypointPolicyBayLocationLayer: React.FC = () => {
  const policy = useRecoilValue(waypointPolicy);
  const locationMapLoadable = useRecoilValueLoadable(
    waypointPolicyBayLocations,
  );
  const selectedIdentity = useRecoilValue(waypointPolicySelectedIdentity);

  const locationMap =
    locationMapLoadable.state === 'hasValue'
      ? locationMapLoadable.contents
      : null;

  const orderedRulesDesc = getWaypointPolicyOrderedLocationsMatch(policy);
  const orderedRulesMap = _.keyBy(orderedRulesDesc, d => d.index);

  const getGradientColors = (location: LayoutBayLocationFragment): string[] => {
    const locId = location.locationId;
    const loc = locationMap?.[locId];
    if (_.isNil(loc)) return null;
    const colors = _(loc?.allMatchingFilters)
      .map(ruleIndex => orderedRulesMap[ruleIndex])
      .compact()
      .map(matchDescriptor => {
        const colors = getQualitativeColor(
          matchDescriptor.ruleId,
          matchDescriptor.groupType === 'start'
            ? 'waypointStart'
            : 'waypointEnd',
        );
        const bgColor = colors[0];
        return _.isNil(selectedIdentity) ||
          selectedIdentity.ruleId === matchDescriptor.ruleId
          ? bgColor
          : applyColorOpacity(bgColor, 0.4);
      })
      .value();
    return colors;
  };

  return <BayLocationLayer getGradientColors={getGradientColors} />;
};

export default WaypointPolicyBayLocationLayer;
