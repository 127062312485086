import * as React from 'react';
const ObjectAisleRightMiddleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisleRightMiddleIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="27.88 121.71 83 89.88 83 46.36 27.88 3.54 5 3.54 5 121.71 27.88 121.71"
    />
    <polygon
      strokeWidth={0}
      points="25.95 7.68 25.95 42.68 7.95 42.68 25.95 7.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="25.95 7.68 25.95 42.68 7.95 42.68 25.95 7.68"
    />
    <polygon strokeWidth={0} points="7.5 40.5 7.5 5.5 25.5 5.5 7.5 40.5" />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="7.5 40.5 7.5 5.5 25.5 5.5 7.5 40.5"
    />
    <polygon
      strokeWidth={0}
      points="25.95 45.68 25.95 80.68 7.95 80.68 25.95 45.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="25.95 45.68 25.95 80.68 7.95 80.68 25.95 45.68"
    />
    <polygon strokeWidth={0} points="7.5 78.5 7.5 43.5 25.5 43.5 7.5 78.5" />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="7.5 78.5 7.5 43.5 25.5 43.5 7.5 78.5"
    />
    <polygon
      strokeWidth={0}
      points="25.95 82.68 25.95 117.68 7.95 117.68 25.95 82.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="25.95 82.68 25.95 117.68 7.95 117.68 25.95 82.68"
    />
    <polygon strokeWidth={0} points="7.5 115.5 7.5 80.5 25.5 80.5 7.5 115.5" />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="7.5 115.5 7.5 80.5 25.5 80.5 7.5 115.5"
    />
    <polygon
      strokeWidth={0}
      points="100.12 121.71 85 89.88 85 46.36 100.12 3.54 123 3.54 123 121.71 100.12 121.71"
    />
    <polygon
      strokeWidth={0}
      points="102.06 7.68 102.06 42.68 120.06 42.68 102.06 7.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="102.06 7.68 102.06 42.68 120.06 42.68 102.06 7.68"
    />
    <polygon
      strokeWidth={0}
      points="120.5 40.5 120.5 5.5 102.5 5.5 120.5 40.5"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="120.5 40.5 120.5 5.5 102.5 5.5 120.5 40.5"
    />
    <polygon
      strokeWidth={0}
      points="102.06 45.68 102.06 80.68 120.06 80.68 102.06 45.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="102.06 45.68 102.06 80.68 120.06 80.68 102.06 45.68"
    />
    <polygon
      strokeWidth={0}
      points="120.5 78.5 120.5 43.5 102.5 43.5 120.5 78.5"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="120.5 78.5 120.5 43.5 102.5 43.5 120.5 78.5"
    />
    <polygon
      strokeWidth={0}
      points="102.06 82.68 102.06 117.68 120.06 117.68 102.06 82.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="102.06 82.68 102.06 117.68 120.06 117.68 102.06 82.68"
    />
    <polygon
      strokeWidth={0}
      points="120.5 115.5 120.5 80.5 102.5 80.5 120.5 115.5"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="120.5 115.5 120.5 80.5 102.5 80.5 120.5 115.5"
    />
  </svg>
);
export default ObjectAisleRightMiddleIcon;
