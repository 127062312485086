import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cn } from '../../../common/utils';
import * as Icon from '../../../components/icons';
import {
  converterEditableArea,
  converterEditableAreaId,
  converterSelectedArea,
} from './store/converter.area.state';

export type ConverterLayoutTitleProps = {
  children?: React.ReactNode;
};

const ConverterLayoutTitle: React.FC<ConverterLayoutTitleProps> = ({
  children,
}) => {
  const { t } = useTranslation('designer');
  const selectedArea = useRecoilValue(converterSelectedArea);
  const editableArea = useRecoilValue(converterEditableArea);
  const setEditableAreaId = useSetRecoilState(converterEditableAreaId);

  const isEditMode = !_.isNil(editableArea);
  const isSelectMode = _.isNil(editableArea) && !_.isNil(selectedArea);
  const isDefaultMode = _.isNil(editableArea) && _.isNil(selectedArea);

  const styleIcon = cn('h-5 w-5', 'fill-current', 'ltr:mr-2 rtl:ml-2');
  const styleIconChevron = cn('mx-0.5 h-6 w-5 fill-current');

  return (
    <div
      data-component="ConverterLayoutTitle"
      className={cn('text-xs uppercase', 'px-2', 'flex items-center', 'w-full')}
    >
      <div
        data-component="breadcrumbs-locations"
        className={cn('flex flex-1 items-center')}
      >
        {/* <div className={cn('text-xs uppercase')}>{t`Layout`}</div> */}
        {/* 
        {isEditMode && (
          <div
            className={cn(
              'text-menu-active hover:text-opacity-80',
              'group',
              'cursor-pointer',
              'flex items-center',
              'truncate',
              'text-xs',
            )}
          >
          
            <Icon.ChevronRight className={styleIconChevron} />
            <span className={`uppercase`}>{editableArea.id}</span>
          </div>
        )} */}
        {/* {isSelectMode && (
          <div className=" flex items-center truncate text-xs">
            <Icon.ChevronRight className={styleIconChevron} />
            <span className="uppercase">{selectedArea.id}</span>
          </div>
        )} */}

        {children && (
          <div className="flex items-center">
            {/* <Icon.ChevronRight className={styleIconChevron} /> */}
            {children}
          </div>
        )}
      </div>

      {/* <Spacer flexspace /> */}

      <div
        data-component={`LayoutSelectMode`}
        className={cn('group truncate uppercase', 'ltr:ml-1 rtl:mr-1')}
      >
        {isDefaultMode && (
          <div
            className={cn(
              'bg-app-panel-dark/60',
              'text-menu-active',
              'border-menu-active/50 border',
              'flex items-center',
              'rounded',
              'px-2 py-0.5',
            )}
          >
            <Icon.CursorClick className={cn(styleIcon)} />
            {t`No Selection`}
          </div>
        )}
        {isSelectMode && (
          <div
            className={cn(
              'bg-app-panel-dark',
              'text-menu-active hover:text-menu-active-hover',
              'border-area-link_active border border-dashed',
              'flex items-center',
              'rounded',
              'px-2 py-0.5',
              'h-7',
            )}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
              setEditableAreaId(selectedArea.id);
            }}
          >
            <Icon.CursorSelectAreaClick
              className={cn('h-5 w-5', 'cursor-pointer', 'fill-current')}
            />
            <span className="mx-1">{selectedArea.id}</span>
            {/* {t`Area (Selected)`} */}
            {/* <Icon.ArrowRightBottom
              className={cn(
                'text-menu-active hover:text-menu-active-hover',
                'h-4 w-4',
                'cursor-pointer',
                'fill-current',
                'ltr:ml-2 rtl:mr-2',
              )}
            /> */}
          </div>
        )}

        {isEditMode && (
          <div
            className={cn(
              'group',
              'bg-menu-active',
              'text-menu-active-text',
              'border-area-link_active border border-dashed',
              'flex items-center',
              'rounded',
              'px-2 py-0.5',
              'h-6',
              'cursor-pointer',
            )}
            // className={cn(
            //   'bg-menu-active text-menu-active-text hover:bg-menu-active-hover',
            //   'flex items-center',
            //   'cursor-pointer',
            //   'rounded',
            //   'px-2 py-0.5',
            // )}
            onClick={e => {
              setEditableAreaId(null);
            }}
          >
            <Icon.ArrowLeftTop
              className={cn(styleIcon, 'group-hover:hidden')}
            />
            <Icon.CircleX
              className={cn(styleIcon, 'hidden group-hover:block')}
            />
            {t`Exit - Edit Mode:`}
            <span className="mx-1">{editableArea.id}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConverterLayoutTitle;
