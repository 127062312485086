import { CalendarDate, getDayOfWeek, isSameDay } from '@internationalized/date';
import { useCalendarCell } from '@react-aria/calendar';
import { useFocusRing } from '@react-aria/focus';
import { useLocale } from '@react-aria/i18n';
import { mergeProps } from '@react-aria/utils';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useRef } from 'react';

function isRangeCalendarState(
  state: CalendarState | RangeCalendarState,
): state is RangeCalendarState {
  return _.has(state, 'highlightedRange');
}

export type CalendarCellProps = {
  date: CalendarDate;
  state: CalendarState | RangeCalendarState;
};

export const CalendarCell: React.FC<CalendarCellProps> = ({ state, date }) => {
  const ref = useRef();
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart =
    isRangeCalendarState(state) && !_.isNil(state.highlightedRange)
      ? isSameDay(date, state.highlightedRange.start)
      : isSelected;
  const isSelectionEnd =
    isRangeCalendarState(state) && !_.isNil(state.highlightedRange)
      ? isSameDay(date, state.highlightedRange.end)
      : isSelected;

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft =
    isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd ||
      dayOfWeek === 6 ||
      date.day === date.calendar.getDaysInMonth(date));

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td
      {...cellProps}
      className={classNames(
        'py-0.5 relative text-menu-text',
        isFocusVisible ? 'z-10' : 'z-0',
      )}
    >
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={classNames(
          'w-10 h-10',
          'outline-none group',
          isRoundedLeft ? 'rounded-l-full' : '',
          isRoundedRight ? 'rounded-r-full' : '',
          isSelected
            ? isInvalid
              ? 'bg-alerts-warning text-menu-text'
              : 'bg-menu-active/40 text-menu-active-text'
            : '',
          isDisabled ? 'disabled' : 'cursor-pointer',
        )}
      >
        <div
          className={classNames(
            'w-full h-full',
            'rounded-full',
            'flex items-center justify-center',
            isDisabled && !isInvalid ? 'text-menu-text/50' : 'cursor-pointer',
            // Focus ring, visible while the cell has keyboard focus.
            isFocusVisible
              ? 'ring-2 group-focus:z-2 ring-menu-active ring-offset-2'
              : '',
            // Darker selection background for the start and end.
            isSelectionStart || isSelectionEnd
              ? isInvalid
                ? 'bg-alerts-warning text-alerts-text hover:bg-alerts-warning/75'
                : // Active state for selected end/start dates
                  'bg-menu-active text-menu-active-text hover:bg-menu-active/75 hover:text-menu-active-text cursor-pointer'
              : '',
            // Hover state for cells in the middle of the range.
            isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
              ? isInvalid
                ? 'hover:bg-alerts-warning/50'
                : 'hover:bg-menu-active/75 hover:text-menu-active-text cursor-pointer'
              : '',
            // Hover state for non-selected cells.
            !isSelected && !isDisabled
              ? 'hover:bg-menu-active/75 hover:text-menu-text cursor-pointer'
              : 'cursor-default',
          )}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
};
