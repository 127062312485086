import * as React from 'react';
const AlignHorizontalLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlignHorizontalLeftIcon"
    {...props}
  >
    <rect width={4.44} height={120} x={5.78} y={4} strokeWidth={0} />
    <rect width={100} height={28} x={16} y={47} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M19.09,101.58c-.09.09-.18.2-.25.31-.03.05-.06.11-.09.16-.03.06-.07.12-.1.19-.03.07-.04.14-.06.21-.02.06-.04.11-.05.17-.05.26-.05.53,0,.78.01.06.03.11.05.17.02.07.04.14.06.2.03.07.06.13.1.19.03.05.05.11.09.16.07.11.16.21.25.31l16,16c.78.78,2.05.78,2.83,0,.39-.39.59-.9.59-1.41s-.2-1.02-.59-1.41l-12.59-12.59h30.17c1.1,0,2-.9,2-2s-.9-2-2-2h-30.17s12.59-12.59,12.59-12.59c.39-.39.59-.9.59-1.41s-.2-1.02-.59-1.41c-.78-.78-2.05-.78-2.83,0l-16,16Z"
    />
    <polygon
      strokeWidth={0}
      points="16.07 10.99 15.96 11.11 15.96 24.69 29.65 10.99 16.07 10.99"
    />
    <polygon
      strokeWidth={0}
      points="80.96 38.99 80.96 30.96 72.93 38.99 80.96 38.99"
    />
    <polyline
      strokeWidth={0}
      points="43.77 13.85 46.62 10.99 33.04 10.99 15.96 28.08 15.96 38.99 18.62 38.99 43.77 13.85 43.77 13.85"
    />
    <polygon
      strokeWidth={0}
      points="80.56 10.99 66.98 10.99 38.99 38.99 52.56 38.99 80.56 10.99"
    />
    <polygon
      strokeWidth={0}
      points="22.05 38.96 35.62 38.96 63.59 10.99 50.01 10.99 22.05 38.96"
    />
    <polygon
      strokeWidth={0}
      points="69.53 38.99 80.96 27.57 80.96 13.99 55.96 38.99 69.53 38.99"
    />
  </svg>
);
export default AlignHorizontalLeftIcon;
