import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ResourcePolicyAgentView from '../../resourcePolicy/agent/ResourcePolicyAgentView';
import useUpdateSimulation from '../../simulation/hooks/useUpdateSimulation';
import { optimisationIsEditable } from '../store/optimisation.state';
import {
  optimisationSetupResourcePolicy,
  optimisationSetupSelectedAgent,
} from '../store/optimisationSetup.state';
import { simulationCurrent, simulationUoms } from '../store/simulation.state';

const OptimisationSetupAgentViewContainer: React.FC = () => {
  const sim = useRecoilValue(simulationCurrent);
  const policy = useRecoilValue(optimisationSetupResourcePolicy);
  const isEditable = useRecoilValue(optimisationIsEditable);
  const uoms = useRecoilValue(simulationUoms);
  const [agent, setAgent] = useRecoilState(optimisationSetupSelectedAgent);
  const updateSim = useUpdateSimulation();
  const firstMount = useRef(true);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
    } else {
      const optimizationSettings = {
        ...(sim.optimizationSettings ?? {}),
        policyOverrides: {
          ...(sim.optimizationSettings?.policyOverrides ?? {}),
          resourcePolicy: policy,
        },
      };
      updateSim({ optimizationSettings });
    }
  }, [policy]);

  return (
    <ResourcePolicyAgentView
      agent={agent}
      setAgent={setAgent}
      editMode={isEditable ? 'specs-only' : 'disabled'}
      uoms={uoms}
    />
  );
};

export default OptimisationSetupAgentViewContainer;
