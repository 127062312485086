import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../../../components/HelperMessage';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { Alert } from '../../../../components/alerts/Alerts';
import * as Icon from '../../../../components/icons';
import { InputQuestion } from '../../../../components/inputs/InputQuestionAnswer';
import { ContainerCol } from '../../../../components/layout/ContainerFlex';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import { Spacer } from '../../../../components/layout/Spacer';
import TitleSection from '../../../../components/layout/TitleSection';
import Callout from '../../../../helpContext/Callout';
import HelpContainer from '../../../../helpContext/HelpContainer';
import {
  converterLayoutBuilder,
  converterLayoutIdSaving,
} from '../store/converter.state';
import { hasCriticalValidationIssues } from '../store/validation.helper';
import { converterValidationResult } from '../store/validation.state';
import { FloorValidationResult } from '../store/validation.types';

const StepDesigner: React.FC = () => {
  const { t } = useTranslation('designer');
  const isLayoutSaving = !_.isNil(useRecoilValue(converterLayoutIdSaving));
  const layoutBuilder = useRecoilValue(converterLayoutBuilder);
  const [validation, setValidation] = useRecoilState(converterValidationResult);

  const hasIssues = hasCriticalValidationIssues(validation);
  function floorIssues(floorResult: FloorValidationResult) {
    if (
      _.isEmpty(floorResult.unreachableBayIds) &&
      _.isEmpty(floorResult.areaIntersections) &&
      !floorResult.noStartingPoint
    ) {
      return;
    }

    const floorTitle =
      layoutBuilder?.floors[floorResult.floorId]?.title ?? floorResult.floorId;

    return (
      <div className="p-4" key={`floor-issues-${floorTitle}`}>
        {/* // <PanelContainer
      //   id={`floor-issues-${floorResult.floorId}`}
      //   key={`floor-issues-${floorResult.floorId}`}
      //   title={
      //     layoutBuilder?.floors[floorResult.floorId]?.title ??
      //     floorResult.floorId
      //   }
      //   className="flex flex-col"
      //   collapsible
      //   hasPadding
      // > */}

        <TitleSection
          title={t(`Floor: {{floorTitle}}`, {
            floorTitle,
          })}
          inPanelView
        />

        <div className="space-y-2">
          {floorResult.noStartingPoint && (
            <Alert
              className="mt-4"
              hasStatusAlert
              message={t(`Floor: {{floorTitle}} — No starting point setup`, {
                floorTitle,
              })}
            >{t`Please ensure the floor has a starting point set.`}</Alert>
          )}
          {!_.isEmpty(floorResult.unreachableBayIds) && (
            <Alert
              className="mt-4"
              hasStatusAlert
              message={t(`Floor: {{floorTitle}} — Unreachable Locations`, {
                floorTitle,
              })}
            >{t`Please ensure the areas are connected.`}</Alert>
          )}
          {!_.isEmpty(floorResult.areaIntersections) && (
            <Alert
              className="mt-4"
              hasStatusAlert
              message={t(`Floor: {{floorTitle}} — Overlapping areas`, {
                floorTitle,
              })}
            >{t`Please ensure areas are not overlapping with each other.`}</Alert>
          )}
        </div>

        {/* // </PanelContainer> */}
      </div>
    );
  }

  return (
    <ContainerScroll className="flex flex-col relative">
      <ScreenTitle
        isSticky
        title={t`Finish`}
        subtitle={t`Importing Layout`}
        hasStepCounter={'5.'}
      />

      {hasIssues ? null : (
        <InputQuestion
          icon={Icon.Settings4}
          question={t`Saving your warehouse layout`}
        />
      )}

      {isLayoutSaving ? (
        <ContainerCol overflow className="relative">
          <LoadingIndicator
            message={t`Converting Layout`}
            // absolute
            selfCenter
          />
        </ContainerCol>
      ) : (
        <>
          {!hasIssues && <HelpContainer id={'designer/05-finish'} hasPadding />}

          {hasIssues && (
            <>
              <Callout panelMode type="suggestion">
                <div className="text-xl mb-2 text-menu-active flex">
                  <Icon.TriangleInfo className="w-8 h-8 ltr:mr-2 rtl:ml-2 fill-current text-menu-active" />
                  {t`This layout cannot be finalised`}
                </div>
                <div>{t`Please fix the issues below, and re-try`}</div>
              </Callout>
              {_.map(validation?.floors, floorIssues)}
            </>
          )}
          {!_.isEmpty(validation?.duplicateAisleIds) && (
            <>
              <Callout panelMode type="suggestion">
                <div className="text-xl mb-2 text-menu-active flex">
                  <Icon.TriangleInfo className="w-8 h-8 ltr:mr-2 rtl:ml-2 fill-current text-menu-active" />
                  {t`This layout cannot be finalised`}
                </div>
                <div>{t`Duplicate aisle ids was found:`}</div>
              </Callout>
              {_.join(validation?.duplicateAisleIds, ', ')}
            </>
          )}

          {!hasIssues && (
            <>
              <Spacer flexspace />
              <HelperMessage
                hasPadding
                hasBefore
                hasAction
                iconAction={Icon.ArrowRightBottom}
              >
                {t`Click on`}
                <HelperMessageAction>{t`Finish Layout Design`}</HelperMessageAction>
                {t`to create your warehouse layout.`}
              </HelperMessage>
            </>
          )}
        </>
      )}
    </ContainerScroll>
  );
};

export default StepDesigner;
