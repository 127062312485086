import * as React from 'react';
const WarehouseGoIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseGoIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="125.16 43.69 63.5 27.26 2.84 43.69 2.84 48.71 63.5 32.28 125.16 48.71 125.16 43.69"
    />
    <path
      strokeWidth={0}
      d="M63.5,35.73L2.84,52.16v48.58h122.32v-48.58l-61.66-16.43ZM56.51,74.24c-.96.84-2.42.77-3.26-.19-.83-.94-.78-2.4.15-3.22l1.96-1.74s-11.24,0-16.86,0c-.26,0-.46.22-.46.48s.2.46.46.46h8.6c1.28,0,2.31,1.03,2.31,2.31,0,1.28-1.05,2.31-2.33,2.31-2.86,0-5.73.04-8.58,0-.26,0-.46.2-.46.46s.2.46.46.46c1.94,0,3.88,0,5.82,0,1.28,0,2.33,1.03,2.33,2.31s-1.05,2.33-2.33,2.33c-2.73,0-5.48.04-8.2,0-.26,0-.46.2-.46.46s.2.46.46.46c1.64,0,3.28,0,4.92,0,1.28,0,2.31,1.03,2.31,2.31s-1.03,2.33-2.99,2.33c-4.72,0-14.15,0-14.15,0-6.02,0-12.02-6-12.02-13.41,0-7.4,5.99-13.39,13.39-13.39,0,0,9.85,0,14.78,0,1.97,0,2.33,0,2.33,1.28,0,2.24-2.04,3.32-3.32,3.32-2.1,0-4.2,0-6.29,0-.26,0-.46.2-.46.46s.2.48.46.48c6.76,0,20.29,0,20.29,0l-1.96-1.74c-.94-.83-.98-2.28-.15-3.22.84-.96,2.3-1.03,3.26-.19l8.47,7.46-8.47,7.46Z"
    />
  </svg>
);
export default WarehouseGoIcon;
