import { t } from 'i18next';
import { WizardStepConfig } from '../../common/types';
import { ProductCategory } from '../../simulation/store/abc/simulation.ABC.types';
import { FeedAggregatedEvent } from './datasetQueries/feedAggregatedEvents';
import { ActualityAnalyzedEventsSummaryRow } from './datasetQueries/feedAnalyzedEvents';
import { FeedItemWithRank } from './datasetQueries/feedItemsWithRank';

export const feedActivityDurationTypes = ['default', 'custom'] as const;
export type FeedActivityDurationType =
  (typeof feedActivityDurationTypes)[number];

export const feedActivityViewTypes = ['total', 'latest', 'route'] as const;
export type FeedActivityViewType = (typeof feedActivityViewTypes)[number];

export const feedActivityAggregateBy = ['hour', 'day'] as const;
export type FeedActivityAggregateBy = (typeof feedActivityAggregateBy)[number];

export type FeedMenuItemId =
  | 'feed-menu-activity'
  | 'feed-menu-info'
  | 'feed-menu-data-view'
  | 'feed-menu-stats'
  | 'feed-menu-simulation'
  | 'feed-menu-policies'
  | 'feed-menu-setup';

export type FeedMenuItem = WizardStepConfig<FeedMenuItemId>;

// Activity Feed Tabs
// ———————————————————————————————————
export const activityFeedTabIds = [
  'tab-feed-activity',
  'tab-feed-summary',
  'tab-feed-process',
  'tab-feed-resources',
  'tab-feed-workforce',
  'tab-feed-items',
  'tab-feed-orders',
  'tab-feed-heatmaps',
  'tab-feed-stats',
  'tab-feed-allocate',
  'tab-feed-more',
  'tab-feed-kpi',
] as const;

export type StatsKpiKeys =
  | 'pickRate'
  | 'uomRate'
  | 'uomCount'
  | 'picklists'
  | 'linesCount'
  | 'netTime';
export type StatsActivityKeys = 'energyIndicator' | 'signalStrength';

export type ActivityFeedTabId = (typeof activityFeedTabIds)[number];

export type FeedHeatmapProductVisits = {
  consignee: string;
  sku: string;
  visitsCount: number;
};

export type FeedHeatmapLocationVisits = {
  locationId: string;
  visitsCount: number;
  items: FeedHeatmapProductVisits[];
};

export type FeedHeatmapLocationsVisits = Record<
  string,
  FeedHeatmapLocationVisits
>;

export type FeedItemWithABCRank = FeedItemWithRank & {
  category: ProductCategory;
};

export const feedHeatmapMetrics = ['locationVisits', 'abc', 'agents'] as const;

export type FeedHeatmapMetric = (typeof feedHeatmapMetrics)[number];

export type FeedStats = {
  picklistCount: number;
  totalLines: number;
  totalUoms: number;
  netLinesPerformance: number;
  netUomPerformance: number;
  grossLinePerformance: number;
  grossUomPerformance: number;
  netTime: number;
  grossTime: number;
  idleTime: number;
  minEnergy?: number;
  minSignal?: number;
};

export type FeedAgentStats = FeedStats & {
  agentId: string;
};

export type FeedAgentStatsField =
  | keyof FeedAgentStats
  | keyof ActualityAnalyzedEventsSummaryRow<'agentId'>;

export type FeedAgentIntervalStat = Omit<FeedAggregatedEvent, 'eventHour'> & {
  intervalKey: string;
  intervalDate: Date;
  intervalsCount: number;
  aggregateBy: FeedActivityAggregateBy;
  jobs: string[];
};

export const groupByTimeOptions = [
  { label: t('By Day'), value: 'byDay' },
  { label: t('By Hour'), value: 'byHour' },
  { label: t('By Week'), value: 'byWeek' },
];

//  Feed Events Panel - Display Options switch
export const feedDisplayOptions = [
  { label: t('Stats'), value: 'stats' },
  { label: t('All'), value: 'all' },
  { label: t('Issues'), value: 'issues' },
];

export type FeedAgentIntervalStats = FeedAgentIntervalStat[];

export const feedGroupByAgentOptions = ['agent', 'agentType'] as const;
export type FeedGroupByAgentType = (typeof feedGroupByAgentOptions)[number];
