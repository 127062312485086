import * as React from 'react';
const MenuItemShapeForwardArrowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 60 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuItemShapeForwardArrowIcon"
    {...props}
  >
    <polyline strokeWidth={0} points="0 128 12.09 128 49 64 12.09 0 0 0" />
    <polyline
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={0}
      points="12.09 128 49 64 12.09 0"
    />
  </svg>
);
export default MenuItemShapeForwardArrowIcon;
