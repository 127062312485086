import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { HelperMessage } from '../../../components/HelperMessage';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../../store/warehouse.state';
import useUpdateUserSettings from '../../hooks/useUpdateUserSettings';
import { BetaCard } from './BetaCard';

export type BetaProps = {
  children?: React.ReactNode;
};

export const BetaSales = ({ children }: BetaProps) => {
  const { t } = useTranslation('app-beta');

  const [id] = useState<string>(_.uniqueId());
  const settings = useRecoilValue(userSettings);
  const settingsStatus = useRecoilValue(userSettingsLoadStatus);
  const updateUserSettings = useUpdateUserSettings();

  return (
    <BetaCard subtitle={t`Beta`} title={t`Sales`}>
      <HelperMessage hasPadding className="flex-1">
        {/* <Icon.ArrowRightBottom className="w-24 h-24 p-2 text-white fill-current" /> */}
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`Why?`}</h4>
        <p>
          {t`3PL Sales teams struggle to price warehousing services optimally,
          while onboarding and contract management is a big hassle both for 3PL
          and it’s customers.`}
        </p>
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`How?`}</h4>

        <p>
          {t`Pricing using data-driven optimisations. WareBee will help you to
          audit contract SLA, as well.`}
        </p>
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`What do I get?`}</h4>
        <p>{t`Growing revenue and improving profitability of 3PL providers.`}</p>
      </HelperMessage>
      <Button
        className="mb-4 mt-8 rounded-full p-1"
        label={
          settings?.featureRequests?.sales
            ? t`Unsubscribe`
            : t`Join the Waitlist`
        }
        buttonType={settings?.featureRequests?.sales ? '' : 'primary'}
        isDisabled={settingsStatus === AsyncLoadStatus.Loading}
        isLoading={settingsStatus === AsyncLoadStatus.Loading}
        full
        hasIconAfter={settings?.featureRequests?.sales}
        hasIconBefore={!settings?.featureRequests?.sales}
        buttonIcon={
          settings?.featureRequests?.sales ? (
            <Icon.CircleOk className={`h-7 w-7 fill-current`} />
          ) : (
            <Icon.CircleArrowRight className={`h-8 w-8 fill-current`} />
          )
        }
        onPress={() => {
          updateUserSettings({
            featureRequests: {
              sales: !settings?.featureRequests?.sales,
            },
          });
        }}
      />
    </BetaCard>
  );
};
