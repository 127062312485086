import * as React from 'react';
const AreasStagingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AreasStagingIcon"
    {...props}
  >
    <rect width={37.72} height={20.8} x={85.74} y={3.57} strokeWidth={0} />
    <rect
      width={37.72}
      height={20.8}
      x={85.74}
      y={28.37}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect width={37.72} height={20.8} x={85.74} y={53.17} strokeWidth={0} />
    <rect width={37.72} height={20.8} x={85.74} y={77.97} strokeWidth={0} />
    <rect
      width={37.72}
      height={20.8}
      x={85.74}
      y={102.77}
      strokeWidth={0}
      opacity={0.6}
    />
    <rect width={76.35} height={120} x={4.84} y={3.57} strokeWidth={0} />
  </svg>
);
export default AreasStagingIcon;
