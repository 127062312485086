import { AgentRole } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import InboxZero from '../../components/InboxZero';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { LinkUrl } from '../../components/actions/LinkUrl';
import { SpecItem } from '../../components/designer/panels/SpecItem';
import * as Icon from '../../components/icons';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import { InputValue } from '../../components/inputs/InputValue';
import { ContainerSectionSettings } from '../../components/layout/ContainerSectionSettings';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import * as Logo from '../../components/logos';
import {
  agentAllRoles,
  getAgentRolesTitle,
} from '../../simulation/store/resourcePolicy.helper';
import {
  AgentCategoryDefinition,
  AgentCategoryId,
  AgentMetadata,
} from '../agentData/agent.types';
import {
  agentCategoriesIdsSorted,
  agentCategoriesMap,
} from '../data/categories/categories';
import { agentManufacturersMap } from '../data/manufacturers/manufacturers';
import { agentModelsByCategory } from '../data/models/models';
import { AgentSettingBaseProps } from './AgentSettingsSection';

export type AgentSettingsSpecialSectionProps = AgentSettingBaseProps & {
  onChangeModel?: (modelId: string) => void;
};

const ManufacturerDetails = ({ manufacturer, className }) => {
  return (
    <ContainerSectionSettings
      isHero
      className={className}
      childrenStart={
        manufacturer?.logo ? (
          <img
            alt={manufacturer?.title}
            className={classNames(
              'object-contain',
              'w-full h-full',
              'max-h-64',
              'fill-current text-current',
              'p-2',
            )}
            src={`${manufacturer.logo}`}
          ></img>
        ) : (
          <div
            className={classNames(
              'bg-app-panel rounded flex-1',
              'p-4 w-20 h-20 lg:w-20 lg:h-28 xl:w-30 xl:h-38',
              'flex flex-col items-center justify-center',
            )}
          >
            <Logo.WareBeeSymbolShape
              className={classNames(
                'w-10 h-8 lg:w-20 lg:h-16 xl:w-20 xl:h-16',
                'fill-current',
                'my-2 mx-2',
              )}
            />
          </div>
        )
      }
    >
      {manufacturer?.title && (
        <h4 className={classNames('flex-1 mb-2 text-2xl')}>
          {manufacturer.title}
        </h4>
      )}

      {manufacturer?.website &&
        (Array.isArray(manufacturer.website) ? (
          manufacturer.website.map(url =>
            url ? <LinkUrl key={`url-${url}`} url={url} /> : null,
          )
        ) : (
          <LinkUrl url={manufacturer.website} />
        ))}

      {manufacturer?.description && (
        <HelperMessage className={classNames('flex-1 pb-4')}>
          {manufacturer.description}
        </HelperMessage>
      )}
    </ContainerSectionSettings>
  );
};

const ModelDetails = ({ model, className }) => {
  const { t } = useTranslation('simulation');

  return (
    <ContainerSectionSettings
      childrenStart={
        model?.image?.length > 0 ? (
          <div
            data-component="model-image"
            className={classNames(
              'flex items-center',
              'bg-app-panel-dark text-sm',
              'rounded',
            )}
          >
            {model.image.map(image =>
              image ? (
                <img
                  alt={model?.title}
                  key={image.id}
                  className={classNames(
                    'object-contain w-full h-full fill-current text-current p-2',
                  )}
                  src={image}
                ></img>
              ) : (
                <div
                  key={image.id}
                  className={classNames(
                    'p-4 max-w-40 max-h-48 flex flex-col items-center justify-center',
                  )}
                >
                  <Icon.MheForklift1
                    className={classNames('w-24 h-24 fill-current my-2 mx-2')}
                  />
                  <label
                    className={classNames('text-xs uppercase')}
                  >{t`Model Image`}</label>
                </div>
              ),
            )}
          </div>
        ) : (
          <div
            data-component="model-image-none"
            className={classNames(
              'p-4 w-40 h-48 flex flex-col items-center justify-center flex-1 bg-menu rounded',
            )}
          >
            <label
              className={classNames('text-xs uppercase')}
            >{t`No Model Image`}</label>
            <Logo.WareBeeSymbolShape
              className={classNames('w-20 h-16 fill-current my-2 mx-2')}
            />
          </div>
        )
      }
    >
      {model.description && (
        <HelperMessage className="flex-1 pb-4 text-sm">
          {model.description}
        </HelperMessage>
      )}
    </ContainerSectionSettings>
  );
};

const AgentSettingsSpecialSection: React.FC<
  AgentSettingsSpecialSectionProps
> = props => {
  const { t } = useTranslation('simulation');

  const models = agentModelsByCategory[props.agentSettings.resourceTypeId];
  const mnfrsIds = _(models)
    .map(m => agentManufacturersMap[m.manufacturerId])
    .sortBy(mnfr => mnfr?.title)
    .map(mnfr => mnfr?.id)
    .uniq()
    .value();
  const meta = (props.agentSettings?.metadata ?? {}) as AgentMetadata;
  const modelsMap = _.keyBy(models, model => model.id);
  const modelId = meta.modelId ?? _.head(_.keys(modelsMap));
  const model = modelsMap[modelId];

  const manufacturerId = model?.manufacturerId;
  const manufacturer = agentManufacturersMap[manufacturerId];

  const modelIdsFiltered = _(models)
    .filter(m => m.manufacturerId === manufacturerId)
    .map(m => m.id)
    .value();

  function changeManufacturer(mnfrId: string) {
    const newModels = models.filter(m => m.manufacturerId === mnfrId);
    const newModelId = _.head(newModels).id;
    props.onChangeModel(newModelId);
  }

  function changeCategory(categoryId: AgentCategoryId) {
    const newModels = agentModelsByCategory[categoryId];
    const newModelId =
      _.find(newModels, m => m.useAsDefault)?.id ?? _.head(newModels)?.id;
    props.onChangeModel(newModelId);
  }

  function changeModel(modelId: string) {
    props.onChangeModel(modelId);
  }

  function changeMetaProperty(value: Partial<AgentMetadata>) {
    props.onChangeProperty({ ...meta, ...value }, 'metadata');
  }

  function changeRole(roleId: AgentRole, enabled: boolean) {
    const roleIndex = _.findIndex(
      props.agentSettings.roles,
      r => r.roleId === roleId,
    );

    if (roleIndex === -1) {
      const position = props.agentSettings.roles.length;
      const defaultSettings = _.find(
        props.agentDefaults.roles,
        r => r.roleId === roleId,
      );

      props.onChangeProperty(
        { ...defaultSettings, disabled: !enabled },
        `roles.[${position}]`,
      );
    } else {
      props.onChangeProperty(!enabled, `roles.[${roleIndex}].disabled`);
    }
  }

  const styleGroupList = classNames(
    'space-y-4',
    'divide-y divide-solid divide-menu/50',
    'flex-1',
  );

  return (
    <>
      <ScreenTitle
        title={t`Properties`}
        subtitle={t`Agent`}
        isSticky
        hasStepCounter={t`A`}
      />

      <TitleSection
        key={`resource-policy-editor-setup-section-${model?.title}-${model?.id}`}
        id={`resource-policy-editor-setup-section-${model?.title}-${model?.id}`}
        title={t`Type, Roles and Settings`}
        collapsible
        // inSidebarView
        inTabView
        hasPadding
        hasScreenTitle
        titleView
        className={classNames(
          'top-20',
          'text-md uppercase',
          'border-opacity-20',
        )}
      >
        <InputGroupList className={classNames(styleGroupList)}>
          <div className={classNames('flex flex-col lg:flex-row')}>
            <ManufacturerDetails
              className={`flex-1 w-full`}
              manufacturer={manufacturer}
            />
          </div>

          <ContainerSectionSettings
            // isHero
            title={t`Title`}
            description={t`Agent title`}
          >
            <InputGroupList className={classNames(styleGroupList)}>
              <InputValue
                value={props.agentSettings.title}
                disabled={props.editMode !== 'full'}
                onChange={v => props.onChangeProperty(v, 'title')}
                isDebouncedChange={true}
              />
            </InputGroupList>
          </ContainerSectionSettings>

          <ContainerSectionSettings
            title={t`Agent Type`}
            description={t`Material Handling Equipment type. Selecting the type will pre-populate the specifications.`}
          >
            <InputGroupList className={classNames(styleGroupList)}>
              <DropdownSelector
                className="w-full text-xl"
                classNameValue="p-3 hover:text-menu-active"
                classNameLabel="text-base uppercase"
                widthFull
                disabled={props.editMode !== 'full'}
                panelMode
                // label={t`Agent Type`}
                values={agentCategoriesIdsSorted}
                value={props.agentSettings.resourceTypeId}
                renderValue={(categoryId, filter) => {
                  const cat: AgentCategoryDefinition =
                    agentCategoriesMap[categoryId];
                  const title = cat?.title ?? categoryId;
                  if (_.isEmpty(filter) || cat.title.indexOf(filter) > -1) {
                    return title;
                  }
                  const aliases = _.filter(
                    cat.title_alias ?? [],
                    alias =>
                      alias
                        ?.toLocaleLowerCase()
                        .indexOf(filter.toLowerCase()) >= 0,
                  );
                  if (!_.isEmpty(aliases)) {
                    return (
                      <>
                        <span>{title}</span>
                        {_.map(aliases, alias => (
                          <span
                            className={classNames(
                              'text-xs text-menu-text/60',
                              'ltr:ml-2 rtl:mr-2',
                            )}
                          >
                            #{alias}
                          </span>
                        ))}
                      </>
                    );
                  }

                  return title;
                }}
                onChange={v => {
                  changeCategory(v as AgentCategoryId);
                }}
                hasSearch
                filterValue={(categoryId, filter) => {
                  const cat = agentCategoriesMap[categoryId];
                  return (
                    _.isEmpty(filter) ||
                    _.some(
                      [cat.title, ...(cat.title_alias ?? [])],
                      t => t.toLowerCase().indexOf(filter.toLowerCase()) >= 0,
                    )
                  );
                }}
              />
            </InputGroupList>
          </ContainerSectionSettings>

          <ContainerSectionSettings
            // isHero
            title={t`Number of Agents`}
            description={t`How many resources of this type are available.`}
          >
            <InputGroupList className={classNames(styleGroupList)}>
              <InputValue
                value={props.agentSettings.utilisation?.agentAmount ?? 0}
                // title={t`Number of Agents of this type`}
                isNumeric={true}
                disabled={props.editMode !== 'full'}
                onChange={v => {
                  const numericValue = v === '' ? null : parseInt(v);
                  if (
                    !Number.isNaN(numericValue) &&
                    numericValue !==
                      props.agentSettings?.utilisation?.agentAmount
                  ) {
                    props.onChangeProperty(
                      numericValue,
                      'utilisation.agentAmount',
                    );
                    new Date(Date.now());
                  }
                }}
              />
            </InputGroupList>
          </ContainerSectionSettings>
          <ContainerSectionSettings
            // isHero
            title={t`Manufacturer & Model`}
            description={t`Selecting the Manufacturer and the model will allow us to select the specific spec for the MHE used.`}
          >
            <InputGroupList className={classNames(styleGroupList)}>
              {manufacturerId && (
                <DropdownSelector
                  hasSearch
                  classNameLabel="text-base uppercase"
                  className="w-full text-xl pt-4"
                  widthFull
                  panelMode
                  disabled={props.editMode !== 'full'}
                  label={t`Manufacturer`}
                  values={mnfrsIds}
                  value={manufacturerId}
                  renderValue={mId => agentManufacturersMap[mId]?.title}
                  onChange={m => changeManufacturer(m)}
                />
              )}

              {modelId && (
                <DropdownSelector
                  hasSearch
                  classNameLabel="text-base uppercase"
                  className="w-full text-xl pt-4"
                  widthFull
                  panelMode
                  disabled={props.editMode !== 'full'}
                  label={t`Model`}
                  values={modelIdsFiltered}
                  value={modelId}
                  renderValue={mId => modelsMap[mId]?.title}
                  onChange={v => changeModel(v)}
                />
              )}
            </InputGroupList>
          </ContainerSectionSettings>
          <ContainerSectionSettings
            // isHero
            title={t`Agent Roles`}
            description={t`What kind of Warehouse roles, does this Agent can do? Specifying the roles will enable different timing options.`}
          >
            {/* <div className="pt-8"> */}
            <InputGroupList className={classNames(styleGroupList)}>
              {_.map(agentAllRoles, (roleId, index) => {
                const role = _.find(
                  props.agentSettings.roles,
                  r => r.roleId === roleId,
                );
                const isSelected = !_.isNil(role) && !role?.disabled;
                return (
                  <InputCheckbox
                    className="mt-0 text-xl py-3"
                    key={`role-selector-${roleId}`}
                    isSelected={isSelected}
                    label={getAgentRolesTitle(roleId, t)}
                    isDisabled={props.editMode !== 'full'}
                    onChange={selected => {
                      changeRole(roleId, selected);
                    }}
                  >
                    {getAgentRolesTitle(roleId, t)}
                  </InputCheckbox>
                );
              })}
              {/* </div> */}
            </InputGroupList>
          </ContainerSectionSettings>
        </InputGroupList>
      </TitleSection>

      {model && (
        <TitleSection
          key={`resource-policy-editor-section-${model?.title}-${model?.id}`}
          id={`resource-policy-editor-section-${model?.title}-${model?.id}`}
          title={t`Model Details`}
          collapsible
          // inSidebarView
          inTabView
          hasPadding
          hasScreenTitle
          titleView
          className={classNames(
            'top-20',
            'text-md uppercase',
            'border-opacity-20',
          )}
        >
          <div
            className={classNames(
              'flex flex-col lg:flex-row',
              'pb-4 ltr:pr-2 rtl:pl-2 lg:pr-4',
            )}
          >
            <div
              data-component="modelGroup"
              key={`model-${modelId}`}
              className={classNames('flex flex-col flex-1 p-4')}
            >
              <ModelDetails model={model} className={``} />

              {_.isEmpty(model?.specsMetric) &&
                _.isEmpty(model?.specsImperial) && (
                  <InboxZero message={t`No Spec found`} />
                )}

              {!_.isEmpty(model?.specsMetric) && (
                <TitleSection
                  key={`resource-policy-editor-section-${model?.title}-${model?.id}-specs`}
                  id={`resource-policy-editor-section-${model?.title}-${model?.id}-specs`}
                  title={t`Model Specs`}
                  // inTabView
                  // innerPadding
                  hasScreenTitle
                  titleView
                  className={classNames(
                    'top-20',
                    'text-lg',
                    'border-opacity-50',
                  )}
                >
                  <div className={classNames('flex space-x-4 items-start p-1')}>
                    {!_.isEmpty(model?.specsMetric.dimensions) && (
                      <div
                        className={classNames(
                          'py-2 text-sm flex flex-col items-center w-full flex-1',
                        )}
                      >
                        <h5
                          className={classNames(
                            'text-xxs text-menu-active uppercase w-full flex-1',
                          )}
                        >{t`Performance`}</h5>

                        <SpecItem
                          label={t`Travelling Speed - Loaded`}
                          unitOfMeasure={`m/s`}
                          value={
                            model?.specsMetric?.performance
                              ?.horizontalTravelling?.speed?.speedUnloaded
                          }
                        />
                        <SpecItem
                          label={t`Travelling Speed - Unloaded`}
                          unitOfMeasure={`m/s`}
                          value={
                            model?.specsMetric?.performance
                              ?.horizontalTravelling?.speed?.speedUnloaded
                          }
                        />

                        <SpecItem
                          label={t`Lifting Speed - Loaded`}
                          unitOfMeasure={`m/s`}
                          value={
                            model?.specsMetric?.performance?.verticalTravelling
                              ?.lifting?.speedLoaded
                          }
                        />
                        <SpecItem
                          label={t`Lifting Speed - Unloaded`}
                          unitOfMeasure={`m/s`}
                          value={
                            model?.specsMetric?.performance?.verticalTravelling
                              ?.lifting?.speedUnloaded
                          }
                        />

                        <SpecItem
                          label={t`Lowering Speed - Loaded`}
                          unitOfMeasure={`m/s`}
                          value={
                            model?.specsMetric?.performance?.verticalTravelling
                              ?.lowering?.speedLoaded
                          }
                        />
                        <SpecItem
                          label={t`Lowering Speed - Unloaded`}
                          unitOfMeasure={`m/s`}
                          value={
                            model?.specsMetric?.performance?.verticalTravelling
                              ?.lowering?.speedUnloaded
                          }
                        />

                        <SpecItem
                          label={t`Payload capacity`}
                          unitOfMeasure={`kg`}
                          value={
                            model?.specsMetric?.performance?.load
                              ?.maxPayloadCapacity
                          }
                        />
                      </div>
                    )}
                    {!_.isEmpty(model?.specsMetric.dimensions) && (
                      <div className="py-2 text-sm flex flex-col items-center w-full flex-1">
                        <h5 className="text-xxs text-menu-text uppercase w-full flex-1">{t`Dimensions`}</h5>

                        <SpecItem
                          label={t`Width`}
                          unitOfMeasure={`mm`}
                          value={model?.specsMetric?.dimensions?.width}
                        />
                        <SpecItem
                          label={t`Height (max)`}
                          unitOfMeasure={`mm`}
                          value={model?.specsMetric?.dimensions?.heightMax}
                        />
                        <SpecItem
                          label={t`Height (min)`}
                          unitOfMeasure={`mm`}
                          value={model?.specsMetric?.dimensions?.heightMin}
                        />
                        <SpecItem
                          label={t`Height Overhead Cab`}
                          unitOfMeasure={`mm`}
                          value={
                            model?.specsMetric?.dimensions?.heightOverheadCab
                          }
                        />
                        <SpecItem
                          label={t`Length (min)`}
                          unitOfMeasure={`mm`}
                          value={model?.specsMetric?.dimensions?.length}
                        />
                        <SpecItem
                          label={t`Weight`}
                          unitOfMeasure={`kg`}
                          value={
                            model?.specsMetric?.dimensions?.weight
                              ?.serviceWeight
                          }
                        />
                        <SpecItem
                          label={t`Turning Radius`}
                          unitOfMeasure={`mm`}
                          value={
                            model?.specsMetric?.dimensions
                              ?.turningRadiusExternal
                          }
                        />
                      </div>
                    )}
                  </div>
                  {!_.isEmpty(model.sensors) && (
                    <div
                      className={classNames(
                        'py-2 text-sm border-b border-menu-500 flex items-center p-1',
                      )}
                    >
                      <label className="text-xs uppercase">{t`Sensors`}</label>
                      {model.sensors.map(sensor => (
                        <span
                          key={sensor}
                          className={classNames(
                            'ltr:ml-4 rtl:mr-4',
                            'px-2 py-1',
                            'bg-menu',
                            'rounded',
                            'text-xs',
                          )}
                        >
                          {sensor}
                        </span>
                      ))}
                    </div>
                  )}
                </TitleSection>
              )}
            </div>
          </div>
        </TitleSection>
      )}
    </>
  );
};

export default AgentSettingsSpecialSection;
