import {
  AssignmentComplianceItemFragment,
  AssignmentComplianceItemStatus,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../Tw';
import { AssignmentPolicySelectedIdentity } from './assignmentPolicy.types';

// const complianceColors = TwTheme.extend.colors.compliance;
const heatmapColors = TwTheme.extend.colors.heatmap_compliance;
const heatmapTextColors = TwTheme.extend.colors.heatmap_text;

export function getAssignmentComplianceStatusColor(
  status: AssignmentComplianceItemStatus,
): [string, string] {
  let bgColor = heatmapColors.empty;
  let txtColor = heatmapColors.emptyText;

  switch (status) {
    case AssignmentComplianceItemStatus.COMPLIANT:
      bgColor = heatmapColors.compliant;
      txtColor = heatmapColors.compliantText;
      break;
    case AssignmentComplianceItemStatus.NONCOMPLIANT:
      bgColor = heatmapColors.nonCompliant;
      txtColor = heatmapColors.nonCompliantText;
      break;
  }

  return [bgColor, txtColor];
}

export function getAssignmentComplianceStatusMap(
  t: TFunction<'simulation'>,
): Record<AssignmentComplianceItemStatus, string> {
  return {
    [AssignmentComplianceItemStatus.COMPLIANT]: t(`Compliant`, {
      ns: 'simulation',
    }),
    [AssignmentComplianceItemStatus.NONCOMPLIANT]: t(`Non-compliant`, {
      ns: 'simulation',
    }),
  };
}

export function getAssignmentComplianceLocationStatus(
  locProducts: AssignmentComplianceItemFragment[],
): AssignmentComplianceItemStatus {
  return _.some(
    locProducts,
    p => p.status === AssignmentComplianceItemStatus.NONCOMPLIANT,
  )
    ? AssignmentComplianceItemStatus.NONCOMPLIANT
    : AssignmentComplianceItemStatus.COMPLIANT;
}

export function getAssignmentComplianceLocationColor(
  locProducts: AssignmentComplianceItemFragment[],
  selectedDetails: AssignmentPolicySelectedIdentity,
): [string, string] {
  const txtColor = heatmapTextColors.light;

  const locationStatus = getAssignmentComplianceLocationStatus(locProducts);
  const fitByLocRule =
    selectedDetails?.filterType === 'locations' &&
    _.some(
      locProducts,
      p =>
        // p.status === AssignmentComplianceItemStatus.NONCOMPLIANT &&
        _.head(p.locationRuleIds) === selectedDetails?.ruleId,
    );

  const fitByItemRule =
    selectedDetails?.filterType === 'items' &&
    _.some(
      locProducts,
      p =>
        // p.status === AssignmentComplianceItemStatus.NONCOMPLIANT &&
        _.head(p.itemRuleIds) === selectedDetails?.ruleId,
    );

  if (_.isEmpty(selectedDetails) || fitByLocRule || fitByItemRule) {
    return getAssignmentComplianceStatusColor(locationStatus);
  }
  return [heatmapColors.empty, heatmapColors.emptyText];
}
