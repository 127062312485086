import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { HelperMessage } from '../HelperMessage';

export type InputGroupParamProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  description?: string | React.ReactNode;
};

export const InputGroupParam = ({
  children,
  className,
  title,
  description,
}: InputGroupParamProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <section
      id={id}
      data-component="InputGroupParam"
      className={classNames(
        'flex items-start flex-col lg:flex-row pb-4 pt-6 ltr:pr-2 rtl:pl-2 xl:pr-4',
        className,
      )}
    >
      <div
        className={classNames(
          'flex flex-col flex-1',
          'w-full md:w-1/3',
          'p-2',
          'mr-0 md:mr-10',
        )}
      >
        {title && (
          <h3 className={classNames('text-2xl mb-4 text-menu-h3')}>{title}</h3>
        )}
        <HelperMessage className={classNames('flex-1 pb-4')}>
          {description}
        </HelperMessage>
      </div>
      <div
        data-component="InputGroupParamChild"
        className={classNames('flex flex-col flex-1 ltr:pl-2 rtl:pr-2 pr-4')}
      >
        {children}
      </div>
    </section>
  );
};
