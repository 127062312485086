import * as React from 'react';
const RoutePathToolIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoutePathToolIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="3.03 5.36 33.43 80.65 42.58 44.91 78.32 35.76 3.03 5.36"
    />
    <path
      strokeWidth={0}
      d="M105.65,73.12l-2.99-2.99-4.97,4.97,8.99,8.99c.65.66,1.56,1.03,2.49,1.03s1.83-.38,2.49-1.03l8.99-8.99-4.97-4.97-2.99,2.99v-9.71c0-1.75-.68-3.39-1.92-4.63-1.23-1.24-2.88-1.92-4.63-1.92h-21.71v7.03h21.23v9.22Z"
    />
    <path
      strokeWidth={0}
      d="M105.65,108.42h-9.22l2.99-2.99-4.97-4.97-8.99,8.99c-.66.66-1.03,1.55-1.03,2.49s.38,1.83,1.03,2.49l8.99,8.99,4.97-4.97-2.99-2.99h9.71c1.75,0,3.39-.68,4.63-1.92,1.24-1.24,1.92-2.88,1.92-4.63v-21.71h-7.03v21.22Z"
    />
    <path
      strokeWidth={0}
      d="M61.14,99.2l2.99,2.99,4.97-4.97-8.99-8.99c-.66-.67-1.55-1.03-2.49-1.03s-1.82.37-2.49,1.03l-8.99,8.99,4.97,4.97,2.99-2.99v9.71c0,1.75.68,3.39,1.92,4.62,1.24,1.24,2.88,1.92,4.63,1.92h21.71v-7.03s-21.22,0-21.22,0v-9.22Z"
    />
    <circle cx={64.26} cy={69.23} r={16.27} strokeWidth={0} />
  </svg>
);
export default RoutePathToolIcon;
