import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { TableRow } from './TableRow';

export type TableRowHeadProps = PropsWithChildren & {
  className?: string;
};

export const TableRowHead = ({ children, className }: TableRowHeadProps) => {
  return (
    <TableRow className={classNames(className)} rowHead>
      {children}
    </TableRow>
  );
};
