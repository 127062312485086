import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import BayPropertiesPanel from '../../components/sharedPanels/BayPropertiesPanel';
import {
  viewerAreaAisles,
  viewerBayLocations,
  viewerBayPortalDictionary,
  viewerSelectedBayDetails,
} from '../../layout/viewer/store/viewer.state';
import SimulationBayViewerLayout from '../bayViewer/SimulationBayViewerLayout';
import WeightComplianceBayProperties from '../sidebar/compliance/WeightComplianceBayProperties';
import {
  simulationComplianceTabKey,
  simulationWizardSelectedStepId,
} from '../store/simulation.wizard.state';

const SimulationBayInfoPanel: React.FC = () => {
  const bay = useRecoilValue(viewerSelectedBayDetails);
  const bayLocations = useRecoilValue(viewerBayLocations);
  const bayPortal = useRecoilValue(viewerBayPortalDictionary);
  const aisleDict = useRecoilValue(viewerAreaAisles);
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const complianceTab = useRecoilValue(simulationComplianceTabKey);
  if (!bay) return null;

  const areaTitle = _.head(_.values(bayLocations))?.warehouseArea;
  const aisleTitle = aisleDict?.[bayPortal?.[bay.id]?.aisleId]?.title;

  const showWeightCompliance =
    stepId === 'compliance' && complianceTab === 'tab-compliance-weight';

  return (
    <BayPropertiesPanel
      bay={bay}
      bayViewer={() => <SimulationBayViewerLayout />}
      areaTitle={areaTitle}
      aisleTitle={aisleTitle}
    >
      {showWeightCompliance && <WeightComplianceBayProperties />}
    </BayPropertiesPanel>
  );
};

export default SimulationBayInfoPanel;
