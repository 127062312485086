import { AreaConfiguration } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from '../../../../components/actions/Button';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import HelpContainer from '../../../../helpContext/HelpContainer';
import {
  converterAreaConfiguration,
  converterEditableAreaId,
  converterSelectedArea,
} from '../store/converter.area.state';
import { converterLayoutBuilder } from '../store/converter.state';

const StepDeleteAreas: React.FC = () => {
  const { t } = useTranslation('designer');

  const layoutBuilder = useRecoilValue(converterLayoutBuilder);
  const [areasConfig, setAreasConfig] = useRecoilState(
    converterAreaConfiguration,
  );
  const setSelectedArea = useSetRecoilState(converterSelectedArea);
  const setEditableAreaId = useSetRecoilState(converterEditableAreaId);
  if (!layoutBuilder) {
    return <LoadingIndicator selfCenter message={t`Loading layout settings`} />;
  }

  const floorMap = _.keyBy(_.values(layoutBuilder.floors), f => f.id);
  const canDelete =
    _(areasConfig)
      .filter(v => !v.isDeleted)
      .value().length > 1;

  const drawRow = (area: AreaConfiguration) => {
    return (
      <TableRow key={`row-${area.id}`} rowLineList rowHover>
        <TableTd>{area.id}</TableTd>
        <TableTd>{floorMap[area.floor]?.title || ''}</TableTd>
        <TableTd>
          <Button
            className="rounded"
            label={area.isDeleted ? t`Restore` : t`Delete`}
            isDisabled={!area.isDeleted && !canDelete}
            buttonSize="xs"
            buttonType={area.isDeleted ? 'primary' : 'delete'}
            hasIconAfter
            buttonIcon={
              area.isDeleted ? (
                <Icon.Reload className={`w-4 h-4 fill-current`} />
              ) : (
                <Icon.CircleX className={`w-5 h-5 fill-current`} />
              )
            }
            onPress={() => {
              setSelectedArea(null);
              setEditableAreaId(null);
              setAreasConfig({
                ...areasConfig,
                [area.id]: { ...area, isDeleted: !area.isDeleted },
              });
            }}
          />
        </TableTd>
      </TableRow>
    );
  };

  return (
    <PanelBody>
      <ScreenTitle subtitle={t`Areas Setup`} title={t`Delete Areas`} />
      <InputQA>
        <InputQuestion
          icon={Icon.CircleX}
          question={t`Delete unneeded areas?`}
        />
      </InputQA>
      <InputAnswerGroup>
        <HelpContainer id={'designer/02-areas-delete'} hasPadding />
        {/* <HelperContainer
          collapsible
          id={'helper-layout-area-delete'}
          hideMessage={t`Hide helper`}
          showMessage={t`show more`}
          showIcon={Icon.CircleHelp}
          hideIcon={Icon.MenuDotsHorizontal}
          message={t`At this step, you can delete areas from the layout.`}
        >
          <HelperMessage isSuggestion hasBefore>
            {t`Sometimes you can have areas that you don't want to analyse or optimise, you can delete those areas at this step.`}
          </HelperMessage>
        </HelperContainer> */}
      </InputAnswerGroup>

      <TitleSection titleView title={t`Delete Areas`} />
      <Table isSticky isSortable isHoverable>
        <TableHead>
          <TableRow>
            <TableTh value={t`Area`} selectable={true} />
            <TableTh value={t`Floor`}></TableTh>
            <TableTh />
          </TableRow>
        </TableHead>
        <TableBody>
          {_(areasConfig)
            .values()
            .sortBy(a => a.id)
            .map(area => drawRow(area))
            .value()}
        </TableBody>
      </Table>
    </PanelBody>
  );
};

export default StepDeleteAreas;
