import * as React from 'react';
const ArrowsDoubleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowsDoubleIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M107.28,65.79l-28.76,28.76c-.58.58-.75,1.44-.44,2.19.31.75,1.05,1.24,1.86,1.24h16.62c.56,0,1.09-.23,1.47-.64l28.17-30.18c.72-.77.72-1.97,0-2.75l-28.17-30.18c-.38-.41-.91-.64-1.47-.64h-16.62c-.81,0-1.55.49-1.86,1.24-.31.75-.14,1.62.44,2.19l28.76,28.76Z"
    />
    <path
      strokeWidth={0}
      d="M21.73,65.8l28.76-28.76c.58-.58.75-1.44.44-2.19-.31-.75-1.05-1.24-1.86-1.24h-16.62c-.56,0-1.09.23-1.47.64L2.81,64.42c-.72.77-.72,1.97,0,2.75l28.17,30.18c.38.41.91.64,1.47.64h16.62c.81,0,1.55-.49,1.86-1.24.31-.75.14-1.62-.44-2.19l-28.76-28.76Z"
    />
  </svg>
);
export default ArrowsDoubleIcon;
