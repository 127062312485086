import { useApolloClient } from '@apollo/client';
import {
  LoadOrderSetIssueDataDocument,
  LoadOrderSetIssueDataQuery,
  LoadOrderSetIssueDataQueryVariables,
  OrderSetIssueUnpickableLineReason,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { pickingPolicyOrderLineByRuleLoadStatus } from '../store/pickingPolicy/pickingPolicy.state';
import {
  simulationIssueSelected,
  simulationIssuesOrderLinesData,
  simulationIssuesOrderLinesStatus,
} from '../store/simulation.issues.state';
import { simulationCurrent } from '../store/simulation.state';

export type OrderLinesByRuleParams = {
  limit?: number;
  skip?: number;
  isAppend?: boolean;
  // filter?: ;
};

function useLoadOrderLinesIssues() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Order Lines with issues.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: OrderLinesByRuleParams) => {
        set(simulationIssuesOrderLinesStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: OrderLinesByRuleParams) => {
        const simulation = await snapshot.getPromise(simulationCurrent);
        const current = await snapshot.getPromise(
          simulationIssuesOrderLinesData,
        );
        const selectedIssue = await snapshot.getPromise(
          simulationIssueSelected,
        );
        const key = {
          layoutId: simulation.layout?.id,
          assignmentId: simulation.assignment?.id,
          itemSetId: simulation.itemSet?.id,
          orderSetId: simulation.orderSet?.id,
        };

        const query = client.watchQuery<
          LoadOrderSetIssueDataQuery,
          LoadOrderSetIssueDataQueryVariables
        >({
          query: LoadOrderSetIssueDataDocument,
          variables: {
            key,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip,
            },
            reason: selectedIssue?.reason as OrderSetIssueUnpickableLineReason,
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(pickingPolicyOrderLineByRuleLoadStatus, AsyncLoadStatus.Error);
            return;
          }

          let content = data?.orderSetIssueSet?.unpickableLines?.content || [];
          if (params.isAppend) {
            content = [...current.content, ...content];
          }

          set(simulationIssuesOrderLinesData, {
            content,
            totalCount: data?.orderSetIssueSet?.unpickableLines?.totalCount,
          });

          set(simulationIssuesOrderLinesStatus, AsyncLoadStatus.Ok);
        });
        setObservable(queryObservable);
      },
  );

  async function call(params: OrderLinesByRuleParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadOrderLinesIssues;
