import {
  LayoutLevelLocationFragment,
  LayoutLocationInaccessibilityReason,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../Tw';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import RoutesLayer from '../../layout/viewer/layers/RoutesLayer';
import {
  viewerLocationMultiselectModeEnabled,
  viewerMultiselectedLocationIds,
} from '../../layout/viewer/store/viewer.state';
import {
  routingPolicyInaccessibleLevelLocation,
  routingPolicyRoutes,
} from '../store/routingPolicy/routingPolicy.state';

const locationColor = TwTheme.extend.colors.location;

const SimulationRoutingPolicyLocationLayer: React.FC = () => {
  const selectedLocations = useRecoilValue(viewerMultiselectedLocationIds);
  const inaccessibleLocationsLoadable = useRecoilValueLoadable(
    routingPolicyInaccessibleLevelLocation,
  );
  const routes = useRecoilValueLoadable(routingPolicyRoutes);

  const inaccessibleLocations =
    inaccessibleLocationsLoadable.state === 'hasValue'
      ? inaccessibleLocationsLoadable.contents
      : null;

  const unreachable = _.keyBy(
    _.flatten(_.map(routes?.contents, r => r.unreachableLocations)),
  );

  const getColor = (
    location: LayoutLevelLocationFragment,
  ): [string, string] => {
    const locId = location.locationId;
    const loc = inaccessibleLocations?.[locId];

    if (_.has(unreachable, locId)) return [locationColor.unreachable, null];
    if (_.has(selectedLocations, locId)) return [locationColor.selected, null];

    if (_.isNil(loc)) {
      return null; // do not change default color
    }
    if (_.size(loc.reason) === 2) {
      return [locationColor.unreachableStartAndEnd, null];
    }
    return loc.reason[0] ===
      LayoutLocationInaccessibilityReason.NO_ACCESS_FROM_START
      ? [locationColor.unreachableStart, null]
      : [locationColor.unreachableEnd, null];
  };

  const isNavigationModeEnabled = useRecoilValue(
    viewerLocationMultiselectModeEnabled,
  );

  return (
    <>
      <LocationLayer
        getColor={getColor}
        disableBayAutoSelections={isNavigationModeEnabled ? false : false}
      />
      <RoutesLayer />
    </>
  );
};

export default SimulationRoutingPolicyLocationLayer;
