import { AnalyzePicklistFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilState, useRecoilValue } from 'recoil';
import naturalCompare from 'string-natural-compare';
import TitleSection from '../../../components/layout/TitleSection';
import { PicklistRoute } from '../../../layout/features/features.types';
import { viewerPlanesMap } from '../../../layout/viewer/store/viewer.state';
import { AgentCategoryId } from '../../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../../resourcePolicy/agentData/agentDataHelper';
import { resourcePolicy } from '../../store/resourcePolicy.state';
import PicklistDetails from './PicklistDetails';

const getPicklistGroupCode = (p: AnalyzePicklistFragment) =>
  `${p.pickByDate}-${p.planeId}-${_.head(p.agents)}`;

export const getIterationCode = (title: string) =>
  _.takeRight(_.split(title, '/'), 2).join('.');

export type OrderPicklistsProps = {
  orderId: string;
  analyzeId: string;
  picklists: AnalyzePicklistFragment[];

  selectedPicklist: RecoilState<string>;
  setRoutePart: RecoilState<PicklistRoute>;
  setRoutePartWithZoom: RecoilState<PicklistRoute>;
  wpType?: 'before' | 'after';
};

const OrderPicklists: React.FC<OrderPicklistsProps> = props => {
  const resPolicy = useRecoilValue(resourcePolicy);
  const planesMap = useRecoilValue(viewerPlanesMap);
  const { t } = useTranslation('simulation');

  const { picklists } = props;

  const groups = _.groupBy(
    _.sortBy(picklists, pl => pl.pickByDate),
    getPicklistGroupCode,
  );

  const agentsMap = _.keyBy(resPolicy.agents, a => a.id);
  return (
    <div
      data-component="AnalyzeOrderPicklists"
      className="flex flex-col flex-1 p-0.5 w-full"
    >
      {_.map(groups, (list, groupId) => {
        const { pickByDate, planeId, agents } = _.head(list);
        const agentId = _.head(agents);
        const periodTitle = t(`Period: {{pickByDate}}`, {
          pickByDate,
        });
        const planeTitle = t(`Floor: {{planeTitle}}`, {
          planeTitle: planesMap[planeId]?.title,
        });

        const agentTitle = t(`Agent: {{agentTitle}}`, {
          agentTitle: agentsMap[agentId]?.title,
        });

        const sortedList = [...list].sort((a1, a2) =>
          naturalCompare(
            getIterationCode(a1.picklist.title),
            getIterationCode(a2.picklist.title),
          ),
        );

        const agent = agentsMap[agentId];

        return (
          <div key={groupId}>
            {pickByDate && (
              <TitleSection
                inSidebarView
                className="!bg-menu-active !text-menu-active-text"
                title={periodTitle}
              />
            )}
            {planeTitle && (
              <TitleSection
                inSidebarView
                className="!bg-menu-active !text-menu-active-text !border-app-panel-dark"
                title={planeTitle}
              />
            )}
            <TitleSection
              inSidebarView
              className="!bg-menu-active !text-menu-active-text !border-app-panel-dark"
              title={
                <>
                  {getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
                  {agentTitle}
                </>
              }
              titleAlt={`orderId ${props.orderId}}`}
              classNameInner={`space-y-1`}
            >
              {_.map(sortedList, (picklistResult: AnalyzePicklistFragment) => (
                <PicklistDetails
                  key={`picklist-container-${picklistResult?.picklist?.id}`}
                  orderId={props.orderId}
                  analyzeId={props.analyzeId}
                  picklistSummary={picklistResult}
                  selectedPicklist={props.selectedPicklist}
                  setRoutePart={props.setRoutePart}
                  setRoutePartWithZoom={props.setRoutePartWithZoom}
                  wpType={props.wpType ?? 'before'}
                />
              ))}
            </TitleSection>
          </div>
        );
      })}
    </div>
  );
};

export default OrderPicklists;
