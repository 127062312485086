import {
  CompiledQuery,
  LimitNode,
  OffsetNode,
  OperationNode,
  PostgresQueryCompiler,
  RootOperationNode,
  SelectQueryNode,
} from 'kysely';
import { isEmpty, isString, padEnd } from 'lodash';
import { isCommentedNode } from './query-comment-plugin';

function isSelectQueryNode(node: OperationNode): node is SelectQueryNode {
  return node.kind == 'SelectQueryNode';
}

export interface AthenaQueryCompilerConfig {
  logParameters?: boolean;
}

export class AthenaQueryCompiler extends PostgresQueryCompiler {
  constructor(private readonly config?: AthenaQueryCompilerConfig) {
    super();
  }

  compileQuery(node: RootOperationNode): CompiledQuery<unknown> {
    const r = super.compileQuery(node);

    const commentLines = [];

    if (isCommentedNode(node)) {
      commentLines.push(
        ...node.comment
          .split('\n')
          .map(c => '-- ' + padEnd(c, 120, String.fromCharCode(160))),
      );
    }

    if (this.config?.logParameters) {
      commentLines.push(...r.parameters.map((p, i) => `-- ${i}: "${p}"`));
    }

    if (!isEmpty(commentLines)) {
      return {
        ...r,
        sql: commentLines.join('\n') + '\n' + r.sql,
      };
    } else {
      return r;
    }
  }

  protected appendImmediateValue(value: unknown): void {
    if (isString(value)) {
      super.appendImmediateValue(value.replaceAll("'", "''"));
    } else {
      super.appendImmediateValue(value);
    }
  }

  protected getCurrentParameterPlaceholder(): string {
    return '?';
  }

  protected override visitOffset(node: OffsetNode): void {
    if (
      this.parentNode != null &&
      isSelectQueryNode(this.parentNode) &&
      this.parentNode.limit != null
    )
      return; // will be handle when visitLimit

    this.append(' OFFSET ');
    this.visitNode(node.offset);
    this.append(' ROWS ');
  }

  protected override visitLimit(node: LimitNode): void {
    // make sure LIMIT goes after OFFSET as required by Athena, copied from https://github.com/kysely-org/kysely/issues/38#issuecomment-1681747567
    if (this.parentNode != null && isSelectQueryNode(this.parentNode)) {
      if (this.parentNode.offset != null) {
        this.append(' OFFSET ');
        this.visitNode(this.parentNode.offset.offset);
        this.append(' ROWS ');
      } else this.append(' OFFSET 0 ROWS ');
    }

    this.append(' FETCH NEXT ');
    this.visitNode(node.limit);
    this.append(' ROWS ONLY ');
  }
}
