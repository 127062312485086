import * as React from 'react';
const UnhideTableIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="UnhideTableIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M66.56,91.06c-11.62,0-21.09-9.47-21.09-21.09,0-5.89,2.44-11.22,6.36-15.06l-12.47-9.73c-18.35,7.97-30.87,21.09-31.66,21.88-1.52,1.64-1.52,4.17,0,5.81,1.14,1.14,26.65,27.92,58.86,27.92,12.25,0,23.52-3.87,32.86-8.74l-14.68-11.45c-3.67,6.24-10.44,10.46-18.18,10.46Z"
      opacity={0.75}
    />
    <path
      strokeWidth={0}
      d="M54.3,69.97c0,6.77,5.49,12.25,12.25,12.25,4.94,0,9.18-2.93,11.12-7.13l-18.79-14.66c-2.79,2.25-4.58,5.68-4.58,9.54Z"
      opacity={0.75}
    />
    <rect width={23.19} height={6.5} x={94.69} y={55.86} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={94.69} y={64.99} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="101.29 83.24 117.88 83.24 117.88 96.19 101.29 83.24"
    />
    <polygon
      strokeWidth={0}
      points="94.69 78.09 94.69 74.12 117.88 74.12 117.88 80.62 97.93 80.62 94.69 78.09"
    />
    <rect width={23.19} height={6.5} x={94.69} y={37.61} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={94.69} y={46.74} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="68.75 57.85 68.75 55.86 91.94 55.86 91.94 62.37 74.53 62.37 68.75 57.85"
    />
    <polygon
      strokeWidth={0}
      points="77.9 64.99 91.94 64.99 91.94 71.49 86.23 71.49 77.9 64.99"
    />
    <polygon
      strokeWidth={0}
      points="91.94 74.12 91.94 75.95 89.6 74.12 91.94 74.12"
    />
    <rect width={23.19} height={6.5} x={68.75} y={37.61} strokeWidth={0} />
    <rect width={23.19} height={6.5} x={68.75} y={46.74} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="54.5 46.74 66 46.74 66 53.24 62.84 53.24 54.5 46.74"
    />
    <polygon
      strokeWidth={0}
      points="42.81 37.61 66 37.61 66 44.11 51.14 44.11 42.81 37.61"
    />
  </svg>
);
export default UnhideTableIcon;
