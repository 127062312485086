import * as React from 'react';
const PolicyWaypointsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaypointsIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.82,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <circle
      cx={48.37}
      cy={74.44}
      r={5.54}
      strokeWidth={0}
      transform="translate(-19.51 17.23) rotate(-16.86)"
    />
    <circle
      cx={48.96}
      cy={43.78}
      r={5.54}
      strokeWidth={0}
      transform="translate(-13.51 23.52) rotate(-23.83)"
    />
    <circle
      cx={64.58}
      cy={28.15}
      r={5.54}
      strokeWidth={0}
      transform="translate(-5.85 26.41) rotate(-22.13)"
    />
    <path
      strokeWidth={0}
      d="M29.42,55.48c-2.16,2.16-2.16,5.67,0,7.83s5.67,2.16,7.83,0,2.16-5.67,0-7.83-5.67-2.16-7.83,0Z"
    />
    <circle
      cx={94.66}
      cy={58.23}
      r={5.54}
      strokeWidth={0}
      transform="translate(-14.65 38.18) rotate(-21.2)"
    />
    <circle
      cx={79.04}
      cy={73.86}
      r={5.54}
      strokeWidth={0}
      transform="translate(-21.36 33.58) rotate(-21.2)"
    />
    <circle
      cx={79.62}
      cy={43.19}
      r={5.54}
      strokeWidth={0}
      transform="translate(-10.23 31.72) rotate(-21.2)"
    />
    <circle
      cx={64}
      cy={58.82}
      r={5.54}
      strokeWidth={0}
      transform="translate(-16.94 27.13) rotate(-21.2)"
    />
    <path
      strokeWidth={0}
      d="M44.46,70.52c-2.16,2.16-2.16,5.67,0,7.83,2.16,2.16,5.67,2.16,7.83,0,2.16-2.16,2.16-5.67,0-7.83s-5.67-2.16-7.83,0Z"
    />
    <path
      strokeWidth={0}
      d="M79.6,41.61l-15.01-15.03h0s-.02-.02-.02-.02L31.78,59.42l22.31,22.31c.54-.54,1.11-1.02,1.71-1.44l-5.83-5.83,14.04-14.07,13.44,13.46-6.21,6.24c.61.41,1.22.9,1.77,1.43l23.25-23.25-16.65-16.66ZM62.42,58.82l-14.03,14.06h0s-13.46-13.47-13.46-13.47l14.04-14.07,13.46,13.47ZM93.07,58.22l-14.01,14.08-13.48-13.49,14.03-14.06,13.46,13.47ZM78.02,43.18l-14.03,14.06-13.46-13.47,14.03-14.06,13.46,13.47Z"
      opacity={0.5}
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M54.3,83.56c-5.06,5.06-5.08,13.24-.02,18.3,5.06,5.06,13.26,5.06,18.32,0,5.06-5.06,5.03-13.24-.02-18.3-5.06-5.06-13.22-5.06-18.28,0ZM70.67,95.82l-4.86-4.86v9.71s-3.54,0-3.54,0v-9.71s-4.86,4.85-4.86,4.85v-4.99s6.63-6.61,6.63-6.61l6.63,6.63v4.99Z"
    />
  </svg>
);
export default PolicyWaypointsIcon;
