import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';
import type { StackingPriority } from '../policies';
import { GRAPHQL_STACKING_PRIORITY } from '../policies';

@InputAndObjectType()
export class PicklistLineDetails {
  @Field(() => GRAPHQL_STACKING_PRIORITY, { nullable: true })
  stackingPriority?: StackingPriority;

  @Field(() => ID, { nullable: true })
  pickingContainer?: string;

  @Field(() => ID, { nullable: true })
  sourceEventId?: string;
}
