import { SimulationRefSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../common/formatHelper';
import * as Icon from '../../components/icons';
import { Spacer } from '../../components/layout/Spacer';
import DashboardItemStatusTag from '../DashboardItemStatusTag';
import { DashboardItemStatus } from '../store/dashboard.types';
import { ItemListCard } from './ItemListCard';

export type SimulationItemCardProps = PropsWithChildren & {
  className?: string;
  sim: SimulationRefSummaryFragment;
  path?: string;
  status?: DashboardItemStatus;
  isActionable?: boolean;
  isLatest?: boolean;
  fullWidth?: boolean;
};

const SimulationItemCard: React.FC<SimulationItemCardProps> = props => {
  const { sim } = props;
  // const { t } = useTranslation('app');
  const navigate = useNavigate();

  return (
    <ItemListCard
      titleTrace={`Card (Simulation): [${props.status}] ${sim.title}`}
      className={classNames(
        'group',
        'text-app-card-title',
        'hover:bg-menu-active hover:text-menu-active-text',
        props.className,
      )}
      isActionable
      label={`SimulationItemCard`}
      id={sim.id}
      fullWidth={props.fullWidth}
      //isLoading={props.isLoading}
      onClick={() => {
        navigate(props.path);
      }}
    >
      <header className="mb-4" data-component={`SimulationItemCardHeader`}>
        <div className={classNames('flex items-center mb-2')}>
          {props.status && <DashboardItemStatusTag status={props.status} />}
          <Spacer flexspace />
          <div className={classNames('flex-1 text-xs text-end')}>
            {formatDateTime(new Date(sim.updatedAt))}
          </div>
        </div>

        <div className={classNames('flex')}>
          <div className={classNames('flex-1')}>
            <Link
              to={{ pathname: props.path }}
              className={classNames('flex flex-1')}
            >
              <h3 className={classNames('flex-1 w-full text-2xl line-clamp-1')}>
                {sim.title}
              </h3>
            </Link>
            <div className={classNames('flex')}>
              {sim.description && (
                <div className={classNames('flex')}>
                  <div className={classNames('text-xs')}>{sim.description}</div>
                </div>
              )}
            </div>
          </div>
          {props.isActionable && (
            <Icon.ArrowRight
              className={classNames('w-10 h-10', 'fill-current')}
            />
          )}
        </div>
      </header>
      <Link to={{ pathname: props.path }}>{props.children}</Link>
    </ItemListCard>
  );
};
export default SimulationItemCard;
