import {
  AppBillingProductCategory,
  AppBillingProductFragment,
  LoadAppBillingProductsDocument,
  LoadAppBillingProductsQuery,
  LoadAppBillingProductsQueryVariables,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { appBillingPublicClient } from './GraphQLClient';
import AppBillingPricingCards, {
  AppBillingPricingCardsCategoryMode,
  AppBillingPricingCardsLinkMode,
  AppBillingPricingCardsPriceMode,
  AppBillingPricingCardsViewMode,
} from './appBilling/AppBillingPricingCards';
import CountriesLocalesLanguages from './assets/data/countries-locale-language.json';
import LoadingIndicator from './components/LoadingIndicator';
import { ContainerCol } from './components/layout/ContainerFlex';
import { ContainerScroll } from './components/layout/ContainerScroll';

export type PricingProps = PropsWithChildren & {
  children?: ReactElement;
  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;
};

export const PricingPublic: React.FC<PricingProps> = ({
  children,
  modePreview,
  modeCategory,
  modePrice,
  modeLink,
}) => {
  // export default function Pricing() {
  const { t } = useTranslation('app');

  const [products, setProducts] = useState<AppBillingProductFragment[]>();
  const [category, setCategory] = useState<AppBillingProductCategory>(
    modeCategory === 'SUBSCRIPTION' || modeCategory === 'ALL'
      ? AppBillingProductCategory.SUBSCRIPTION
      : AppBillingProductCategory.BUNDLE,
  );

  const locale = window.navigator.language;
  const getCurrencyCode = (locale: string) => {
    const country = CountriesLocalesLanguages.find(c =>
      c.locales.includes(locale),
    );
    return country?.currency_default || 'USD';
  };
  const userLocaleCurrency = getCurrencyCode(locale);

  const [currency, setCurrency] = useState(userLocaleCurrency);
  const [whSize, setWhSize] = useState<WarehouseSize>(WarehouseSize.MEDIUM);

  async function loadProducts() {
    const response = await appBillingPublicClient.query<
      LoadAppBillingProductsQuery,
      LoadAppBillingProductsQueryVariables
    >({
      query: LoadAppBillingProductsDocument,
    });

    setProducts(response.data.appBillingProducts);
  }

  useEffect(() => {
    loadProducts();
  });

  if (!products?.length) {
    return (
      <ContainerCol
        overflow
        className={`items-center justify-center bg-app-panel-dark`}
      >
        <LoadingIndicator selfCenter message={t`Loading Pricing info...`} />
      </ContainerCol>
    );
  }

  return (
    <ContainerScroll
      hasNoScroll
      className="bg-app-panel-dark"
      data-component={`PricingPublic-${modeCategory}-${modePreview}`}
    >
      <AppBillingPricingCards
        modePreview={modePreview}
        modeCategory={modeCategory}
        modePrice={modePrice}
        modeLink={modeLink}
        products={products}
        currency={currency}
        onCurrencyChange={setCurrency}
        category={category}
        onCategoryChange={setCategory}
        whSize={whSize}
        onWhSizeChange={setWhSize}
        hasNoScroll
      />
      {children}
    </ContainerScroll>
  );
};

export default PricingPublic;
