import {
  Field,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';
import { OrderSetFilter } from './dataset-filters.model';
import { ReassignJobsSettings } from './reassign.settings';

@InputAndObjectType()
export class AnalyzeSettings {
  @Field({ nullable: true, defaultValue: true })
  saveEvents?: boolean;

  @Field(() => ReassignJobsSettings, { nullable: true })
  reassignJobs?: ReassignJobsSettings;

  @Field(() => OrderSetFilter, { nullable: true })
  orderSetFilter?: OrderSetFilter;
}
