import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import InboxZero from '../../components/InboxZero';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import PickingPolicyLocationFilterEditor from '../../pickingPolicy/PickingPolicyLocationFilterEditor';
import PickingPolicyAgentEditor from '../../pickingPolicy/editors/PickingPolicyAgentEditor';
import PickingPolicyContainerEditor from '../../pickingPolicy/editors/PickingPolicyContainerEditor';
import PickingPolicyGroupByEditor from '../../pickingPolicy/editors/PickingPolicyGroupEditor';
import PickingPolicyModeEditor from '../../pickingPolicy/editors/PickingPolicyModeEditor';
import PickingPolicyOrderLineFilterEditor from '../../pickingPolicy/editors/PickingPolicyOrderLineFilterEditor';
import PickingPolicyPickingGroupEditor from '../../pickingPolicy/editors/PickingPolicyPickingGroupEditor';
import PickingPolicyPriorityEditor from '../../pickingPolicy/editors/PickingPolicyPriorityEditor';
import PickingPolicySortByEditor from '../../pickingPolicy/editors/PickingPolicySortByEditor';
import PickingPolicyUomEditor from '../../pickingPolicy/editors/PickingPolicyUOMEditor';
import PickingPolicyWaveByDateEditor from '../../pickingPolicy/editors/PickingPolicyWaveByDateEditor';
import PickingPolicyWaveEditor from '../../pickingPolicy/editors/PickingPolicyWaveEditor';
import PickingPolicyWaypointEditor from '../../pickingPolicy/editors/PickingPolicyWaypointEditor';
import PickingPolicyWaypointsEditor from '../../pickingPolicy/editors/PickingPolicyWaypointsEditor';
import useLoadOrderLineFilterValues from '../../pickingPolicy/hooks/useLoadOrderLineFilterValues';
import useLoadPickingConfigurationFilterValues from '../../pickingPolicy/hooks/useLoadPickingConfigurationFilterValues';
import {
  updateAllPolicyFilterValues,
  updatePolicyFilterValues,
} from '../../policyFilters/policyFilter.helper';
import useLoadLocationFilterValues from '../../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../store/assignmentPolicy.default';
import {
  getOrderLineFilterConfigCommon,
  getPickingHowFilterConfig,
} from '../store/pickingPolicy/pickingPolicy.default';
import {
  pickingPolicyPickingRule,
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../store/pickingPolicy/pickingPolicy.state';
const PickingPolicyEditorSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const formatter = useFormatter();
  const filterIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const rule = useRecoilValue(pickingPolicyRule(filterIdentity?.ruleId));
  const pickingRule = useRecoilValue(
    pickingPolicyPickingRule({
      ruleId: filterIdentity?.ruleId,
      pickingRuleId: filterIdentity?.pickingRuleId,
    }),
  );
  const [loadOrderLineValues, cancelLoadOrderLineValues] =
    useLoadOrderLineFilterValues();
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();
  const [loadRuleConfigurationValues] =
    useLoadPickingConfigurationFilterValues();

  useEffect(() => {
    if (filterIdentity?.locationFilterId) {
      updateAllPolicyFilterValues({
        filterConfig: getLocationFilterConfigCommon(t),
        selectedFilterId: filterIdentity.locationFilterId,
        policyFilterUnion: pickingRule.locationsMatch,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }

    if (filterIdentity?.orderLineFilterId) {
      updatePolicyFilterValues({
        filterConfig: getOrderLineFilterConfigCommon(t, formatter),
        selectedFilterId: filterIdentity.orderLineFilterId,
        policyFilterUnion: rule.orderLinesMatch,
        load: loadOrderLineValues,
        cancel: cancelLoadOrderLineValues,
      });
    }
    if (filterIdentity?.selectionTypeId === 'picklistLineUOM') {
      getPickingHowFilterConfig(t).forEach(configItem => {
        loadRuleConfigurationValues({ field: configItem.type });
      });
    }
  }, [filterIdentity]);

  if (!filterIdentity || !rule) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in picking policy rule to change filters`}
      />
    );
  }

  const selectedType = filterIdentity?.selectionTypeId;

  const isSortBy = selectedType === 'orderLineSort';
  const isSelectBy =
    selectedType === 'picklistLineUOM' ||
    selectedType === 'picklistLineAgent' ||
    selectedType === 'picklistLineMode';
  const isGroupBy = selectedType === 'orderLineGroup';

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={
          isSortBy
            ? t`Sort Options for`
            : isGroupBy
              ? t`Group Options for`
              : isSelectBy
                ? t`Select Options for`
                : t`Filter Options for`
        }
        title={`${rule.title}`}
        isSticky
      />

      {selectedType === 'orderLineGroup' && (
        <PickingPolicyGroupByEditor key={filterIdentity?.ruleId} />
      )}
      {selectedType === 'orderLineWave' && (
        <PickingPolicyWaveEditor key={filterIdentity?.ruleId} />
      )}
      {selectedType === 'orderLineWaypoints' && (
        <PickingPolicyWaypointEditor key={filterIdentity?.ruleId} />
      )}

      {selectedType === 'orderLineWaveDate' && (
        <PickingPolicyWaveByDateEditor />
      )}
      {selectedType === 'orderLineSort' && <PickingPolicyPriorityEditor />}
      {selectedType === 'orderLineContainer' && (
        <PickingPolicyContainerEditor />
      )}
      {selectedType === 'picklistLineUOM' && <PickingPolicyUomEditor />}
      {selectedType === 'picklistLineAgent' && <PickingPolicyAgentEditor />}
      {selectedType === 'picklistLineMode' && <PickingPolicyModeEditor />}
      {selectedType === 'picklistLineGroup' && (
        <PickingPolicyPickingGroupEditor />
      )}
      {selectedType === 'orderLinePicklistLineSortBy' && (
        <PickingPolicySortByEditor />
      )}

      {selectedType === 'picklistLineWaypoints' && (
        <PickingPolicyWaypointsEditor />
      )}
      {!_.isNil(filterIdentity.orderLineFilterId) &&
        getOrderLineFilterConfigCommon(t, formatter).map(configItem => (
          <PickingPolicyOrderLineFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadOrderLineValues}
            cancelLoadData={cancelLoadOrderLineValues}
          />
        ))}

      {!_.isNil(filterIdentity.locationFilterId) &&
        getLocationFilterConfigCommon(t).map(configItem => (
          <PickingPolicyLocationFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-picking-editor',
    title: t`Filter Policy Params`,
    loadingMessage: t`Loading...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <PickingPolicyEditorSidebar />
    </SidebarContainer>
  );
};
