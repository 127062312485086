import { WeightComplianceStatus } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getWeightColorByStatus,
  getWeightColorByValue,
  getWeightComplianceStatusMap,
} from '../../../store/weightCompliance.helper';

type WeightComplianceStatusTagProps = {
  status: WeightComplianceStatus;
  weight?: number;
  maxWeight?: number;
};

const WeightComplianceStatusTag: React.FC<
  WeightComplianceStatusTagProps
> = props => {
  const { t } = useTranslation('simulation');
  const statusMap = getWeightComplianceStatusMap(t);
  const [background, color] =
    getWeightColorByStatus(props.status ?? WeightComplianceStatus.UNKNOWN) ??
    getWeightColorByValue(props.weight, props.maxWeight);

  return (
    <span
      aria-label={`Weight Compliance Status`}
      className={`text-center px-2 py-1 rounded text-xxs flex-1 whitespace-nowrap`}
      style={{
        background,
        color,
      }}
    >
      {statusMap[props.status]}
    </span>
  );
};

export default WeightComplianceStatusTag;
