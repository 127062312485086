import * as React from 'react';
const Grid9Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Grid9Icon"
    {...props}
  >
    <rect width={38} height={38} x={4} y={4} strokeWidth={0} />
    <rect width={38} height={38} x={45} y={4} strokeWidth={0} />
    <rect width={38} height={38} x={86} y={4} strokeWidth={0} />
    <rect width={38} height={38} x={4} y={45} strokeWidth={0} />
    <rect width={38} height={38} x={45} y={45} strokeWidth={0} />
    <rect width={38} height={38} x={86} y={45} strokeWidth={0} />
    <rect width={38} height={38} x={4} y={86} strokeWidth={0} />
    <rect width={38} height={38} x={45} y={86} strokeWidth={0} />
    <rect width={38} height={38} x={86} y={86} strokeWidth={0} />
  </svg>
);
export default Grid9Icon;
