import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import LanguageSelector from '../components/LanguageSelector';
import { Copyright } from './legal/Copyright';
import { FooterLegal } from './legal/FooterLegal';

export type FooterPublicProps = {
  children?: React.ReactNode;
  className?: string;
};

export const FooterPublic = ({ children, className }: FooterPublicProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div id={id} data-component="FooterPublic" className={`${className || ''}`}>
      {children}
      <FooterLegal
        className={classNames(
          'p-10 xl:py-5 pt-8 xl:pt-10 text-menu-active',
          'pb-5 lg:p-10 xl:p-10', //stylePadding
        )}
      />
      <footer className="flex items-center p-10 pt-4 pb-4">
        <Copyright />
        <LanguageSelector DropUp label={``} />
      </footer>
    </div>
  );
};
