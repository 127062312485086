import * as React from 'react';
const AllocationReportIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AllocationReportIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M43.75,109.33c-.21.03-.51-.04-.72-.25l-6.74-6.74-2.82,3.08c-5.05,5.51-7.89,12.7-8.13,20.14h-9.12c.04-.47.09-3.46.15-3.89v-.1c1.17-8.76,4.91-16.7,10.86-22.96l2.68-2.83-6.63-6.63c-.23-.23-.3-.59-.23-.8.03-.1.09-.19.16-.27.09-.09.2-.15.31-.16l22.5-2.69c.23-.03.5.07.67.24.06.06.2.2.15.55l-2.59,22.75c-.02.19-.08.33-.15.39-.03.02-.19.09-.36.16Z"
    />
    <polyline
      strokeWidth={0}
      points="66.26 91.03 70.64 87.17 72.8 87.95 75.21 85.67 75.21 81.63 78.17 78.84 80.63 80.4 80.63 80.4 83.05 78.12 83.05 70.44 63.71 88.42 63.71 96.39 66.13 94.11"
    />
    <path
      strokeWidth={0}
      d="M44.37,70.44v7.67s19.34,18.27,19.34,18.27v-7.97s-19.34-17.97-19.34-17.97ZM45.3,75.02l17.49,16.26v2.61s-17.49-16.53-17.49-16.53v-2.34Z"
    />
    <polygon
      strokeWidth={0}
      points="46.94 69.73 46.94 56.68 62.3 70.35 62.3 83.61 46.94 69.73"
    />
    <polygon
      strokeWidth={0}
      points="65.26 70.34 80.46 56.69 80.46 69.73 65.26 83.6 65.26 70.34"
    />
    <polygon
      strokeWidth={0}
      points="57.74 44.15 63.7 39 79.63 52.77 73.33 58.32 57.74 44.15"
    />
    <polygon
      strokeWidth={0}
      points="47.76 52.77 55.29 46.26 70.9 60.46 63.7 66.81 47.76 52.77"
    />
    <path
      strokeWidth={0}
      d="M5.24,74.03c-.21-1.48-.36-2.97-.45-4.45l25.95-1.65c.05.83.14,1.68.25,2.51l-25.75,3.59Z"
    />
    <path
      strokeWidth={0}
      d="M64.42,125.46c-.41,0-.82,0-1.23-.01l.56-25.99c1.68.03,3.37-.06,5.01-.28l3.38,25.78c-2.55.33-5.15.5-7.73.5ZM80.91,123.13l-7.22-24.98c1.61-.46,3.2-1.06,4.72-1.76l10.88,23.61c-2.71,1.25-5.53,2.3-8.39,3.12ZM97.1,115.69l-14.24-21.75c1.41-.92,2.76-1.96,4.01-3.07l17.32,19.39c-2.21,1.98-4.6,3.81-7.09,5.44ZM110.41,103.79l-20.04-16.56c.42-.5.83-1.03,1.22-1.57.6-.82,1.17-1.68,1.69-2.55l22.3,13.37c-.93,1.55-1.94,3.08-3.01,4.53-.69.95-1.42,1.88-2.16,2.78ZM119.58,88.5l-24.04-9.9c.62-1.5,1.13-3.07,1.53-4.64l25.29,6.02-.1.38c-.69,2.73-1.6,5.49-2.69,8.14ZM123.84,71.2l-25.89-2.4c.1-1.03.14-2.08.14-3.12,0-.64-.02-1.29-.06-1.93l25.96-1.49c.06,1.13.1,2.28.1,3.42,0,1.84-.09,3.7-.26,5.52ZM30.8,62.88l-25.9-2.24c.26-2.96.74-5.93,1.43-8.82l25.28,6.06c-.39,1.64-.67,3.32-.81,5ZM97.38,58.75c-.35-1.64-.82-3.26-1.41-4.83l24.34-9.14c1.05,2.79,1.89,5.69,2.51,8.6l-25.44,5.37ZM33.15,53.06l-24.1-9.75c1.12-2.77,2.45-5.46,3.97-8.02l22.37,13.25c-.85,1.44-1.6,2.96-2.24,4.52ZM93.84,49.33c-.17-.3-.34-.6-.52-.9-.69-1.16-1.45-2.28-2.27-3.33l20.59-15.87c1.43,1.86,2.77,3.83,3.98,5.85.31.53.62,1.06.92,1.6l-22.72,12.65ZM38.27,44.4l-20.14-16.45c1.88-2.31,3.96-4.49,6.17-6.49l17.44,19.28c-1.25,1.13-2.41,2.36-3.47,3.65ZM87.68,41.34c-1.05-1.01-2.18-1.96-3.36-2.82-.18-.13-.35-.26-.53-.38l14.97-21.26c.31.22.61.43.91.66,2.1,1.54,4.12,3.23,6,5.03l-17.99,18.77ZM45.74,37.65l-14.37-21.67c.77-.51,1.56-1.01,2.37-1.49,1.76-1.05,3.58-2.02,5.41-2.88l11.01,23.55c-1.04.49-2.07,1.04-3.07,1.63-.46.27-.91.56-1.35.85ZM79.46,35.56c-1.5-.75-3.07-1.39-4.68-1.91l8-24.74c2.83.92,5.62,2.05,8.28,3.38l-11.6,23.27ZM54.87,33.38l-7.37-24.93c2.8-.83,5.69-1.45,8.59-1.86h.1s-.17,0-.17,0l4,25.69c-.12.02-.24.04-.37.05-1.61.23-3.23.58-4.79,1.04ZM69.86,32.46c-1.66-.27-3.35-.43-5.03-.45l.37-26c2.97.04,5.97.31,8.9.79l-4.24,25.65Z"
    />
  </svg>
);
export default AllocationReportIcon;
