import {
  AppBillingProductCategory,
  AppBillingProductFragment,
  BillableOperationType,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbolSettings } from '../common/formatHelper';
import { HelperMessage } from '../components/HelperMessage';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import * as Icon from '../components/icons';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import { Spacer } from '../components/layout/Spacer';
import AppBillingPricingHeader from './AppBillingPricingHeader';
import AppBillingPriceCard from './cards/AppBillingPriceCard';
import { getWarehouseDescriptor } from './store/appBilling.helper';

export type AppBillingPricingCardsViewMode = 'FULL' | 'PREVIEW';
export type AppBillingPricingCardsPriceMode = 'PRICING_SHOW' | 'PRICING_HIDE';
export type AppBillingPricingCardsCategoryMode =
  | 'ALL'
  | 'SUBSCRIPTION'
  | 'BUNDLE';
export type AppBillingPricingCardsLinkMode = 'APP' | 'WEBSITE';

export function getFilteredProducts(
  products: AppBillingProductFragment[],
  currency: string,
  whSize: WarehouseSize,
  category: AppBillingProductCategory,
) {
  return _(products)
    .filter(
      p =>
        _.some(
          p.prices,
          price =>
            currency &&
            price.currency?.toLowerCase() === currency?.toLowerCase(),
        ) &&
        p.warehouseSize === whSize &&
        p.productCategory === category,
    )
    .sortBy(p => (p.operationType === BillableOperationType.ANALYZE ? 0 : 1))
    .value();
}

export function getFilteredPrices(
  products: AppBillingProductFragment[],
  currency: string,
  whSize: WarehouseSize,
  category: AppBillingProductCategory,
) {
  return _(getFilteredProducts(products, currency, whSize, category))
    .map(p => p.prices)
    .flatten()
    .filter(
      price =>
        currency && price.currency?.toLowerCase() === currency?.toLowerCase(),
    )
    .value();
}

export type AppBillingPricingCardsProps = PropsWithChildren & {
  products: AppBillingProductFragment[];
  selectedPriceId?: string;

  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;

  currency: string;
  onCurrencyChange: (currency: string) => void;

  category: AppBillingProductCategory;
  onCategoryChange: (category: AppBillingProductCategory) => void;

  whSize: WarehouseSize;
  onWhSizeChange: (whSize: WarehouseSize) => void;
  hasNoScroll?: boolean;
};

const AppBillingPricingCards: React.FC<AppBillingPricingCardsProps> = props => {
  const { products, currency, category, whSize } = props;
  const { t } = useTranslation('app');

  const filteredProducts = getFilteredProducts(
    products,
    currency,
    whSize,
    category,
  );

  const currencies = _(products)
    .map(p => p.prices)
    .flatten()
    .map(price => price.currency.toUpperCase())
    .uniq()
    .value();

  const sizes = [
    WarehouseSize.SMALL,
    WarehouseSize.MEDIUM,
    WarehouseSize.LARGE,
  ];

  // const productCategories = [
  //   AppBillingProductCategory.SUBSCRIPTION,
  //   AppBillingProductCategory.BUNDLE,
  // ];

  const isHeroMode = props.modePreview === 'PREVIEW';

  const isModePricing = props.modePrice === 'PRICING_SHOW' ? true : false;
  const isModeCategoryAll = props.modeCategory === 'ALL' ? true : false;
  const isModeLinkPublic = props.modeLink === 'WEBSITE' ? true : false;

  return (
    <>
      <AppBillingPricingHeader
        products={products}
        currency={currency}
        onCurrencyChange={props.onCurrencyChange}
        category={category}
        onCategoryChange={props.onCategoryChange}
        showCurrencySwitch={isModePricing}
        showCategorySwitch={isModeCategoryAll}
        modePreview={props.modePreview}
        modeCategory={props.modeCategory}
        modePrice={props.modePrice}
        modeLink={props.modeLink}
      />
      <ContainerScroll hasNoScroll hasOverflowY>
        {/* <Container
          overflow={props.hasNoScroll ? false : true}
          hasOverflowY={props.hasNoScroll ? true : false}
        > */}
        <div
          data-component="PricingTableContainer"
          className={classNames(
            'flex flex-col xl:flex-row',
            'px-10 xl:px-10 pt-5 xl:space-x-4 pb-20',
          )}
        >
          <div className={classNames('flex-1 mb-6 lg:mb-4')}>
            {props.children}
            <div
              className={classNames(
                'sticky top-0',
                'mt-4',
                // '-m-4',
                'p-4 rounded-t-xl',
                'backdrop-filter backdrop-blur-lg backdrop-saturate-110',
                'bg-opacity-80',
              )}
            >
              <div className={classNames('flex-1 flex')}>
                <div
                  className={classNames(
                    'flex flex-1 items-center text-3xl xl:text-4xl',
                    isHeroMode ? 'text-menu-active-text' : 'text-menu-active',
                  )}
                >
                  <div
                    className={classNames(
                      'flex items-center justify-center font-bold rounded-full relative',
                      'w-8 h-8 xl:w-10 xl:h-10',
                      'text-sm ltr:mr-2 rtl:ml-2',
                      isHeroMode
                        ? 'border-2 border-app-panel-dark'
                        : 'border border-menu-active text-menu-active',
                    )}
                  >{`1`}</div>
                  <div
                    className={classNames(
                      'ltr:ml-1 rtl:mr-1',
                      'text-xl xl:text-2xl',
                    )}
                  >{t`Warehouse Size`}</div>
                </div>
              </div>

              <HelperMessage
                isHero={isHeroMode}
                hasBefore
                iconAction={Icon.ArrowBottom}
                textSize={'xl'}
              >
                {t`Please select your Warehouse size.`}
                <br />
                {t`Warehouse size is how many locations are in the simulated layout.`}
              </HelperMessage>

              <div
                className={classNames(
                  'mt-4',
                  'p-2 rounded-xl flex flex-col items-center',
                  isHeroMode
                    ? 'border border-app-panel-dark/50 bg-app-panel-dark shadow'
                    : 'border border-app-panel-dark bg-menu-active text-menu-active-text',
                )}
              >
                <div
                  className={classNames('flex items-center flex-1 mt-2 w-full')}
                >
                  <ButtonSwitchMulti
                    autoSize
                    buttonType={isHeroMode ? 'secondary' : 'secondary'}
                    className={classNames('!text-xl !lg:text-xl flex-1')}
                    options={sizes.map(s => ({
                      label: getWarehouseDescriptor(s, t).shortTitle,
                    }))}
                    selectedIndex={_.indexOf(sizes, whSize)}
                    onClick={index => props.onWhSizeChange(sizes[index])}
                  />
                </div>
                <div
                  data-component="WarehouseSizeLimitDescriptor"
                  className={classNames(
                    'text-xl',
                    'mt-4 mb-2',
                    'py-4 px-3',
                    isHeroMode ? 'text-menu-active' : '',
                  )}
                >
                  {getWarehouseDescriptor(whSize, t).locationLimitText}
                </div>
              </div>

              <div className={classNames('flex-1 flex flex-col', 'mt-12')}>
                <div
                  className={classNames(
                    'flex flex-1 items-center',
                    'text-3xl xl:text-4xl',
                    isHeroMode ? 'text-menu-active-text' : 'text-menu-active',
                  )}
                >
                  <div
                    className={classNames(
                      'flex items-center justify-center font-bold rounded-full relative',
                      'w-8 h-8 xl:w-10 xl:h-10',
                      'text-sm ltr:mr-2 rtl:ml-2',
                      isHeroMode
                        ? 'border-2 border-app-panel-dark'
                        : 'border border-menu-active text-menu-active',
                    )}
                  >{`2`}</div>
                  <div
                    className={classNames(
                      'ltr:ml-1 rtl:mr-1',
                      'text-xl xl:text-2xl',
                    )}
                  >{t`Select Plan`}</div>
                </div>

                <HelperMessage
                  isHero={isHeroMode}
                  hasBefore
                  iconAction={Icon.ArrowRight}

                  // textSize={'xl'}
                >
                  {t`Please select your plan:`}
                  <br />
                  {t`Full subscription with Optimisation which includes the Analyse and Compliance options or just the Analyse & Compliance options.`}
                </HelperMessage>
              </div>
            </div>
          </div>
          <div
            data-component="CurrencySwitchContainer"
            className={classNames(
              'flex xl:hidden',
              'items-center',
              'ltr:ml-2 rtl:mr-2 mb-4',
            )}
          >
            <Spacer flexspace />
            <ButtonSwitchMulti
              autoSize
              buttonType="secondary"
              className={classNames('!text-lg !lg:text-xl')}
              options={currencies.map(currencyCode => ({
                label: getCurrencySymbolSettings(currencyCode).symbol,
              }))}
              selectedIndex={_.indexOf(currencies, currency)}
              onClick={index => props.onCurrencyChange(currencies[index])}
            />
          </div>
          <div
            data-component="PricingTableContainer"
            className={classNames(
              'flex items-center',
              'w-full',
              'ltr:ml-2 rtl:mr-2',
            )}
          >
            <div
              data-component="PricingTable"
              className={classNames(
                'space-y-10 sm:space-y-0',
                'md:space-x-2 xl:space-x-4',
                'md:flex',
                'mb-4',
              )}
            >
              {filteredProducts.map(product => {
                const price = product.prices.find(
                  price =>
                    currency &&
                    price.currency?.toLowerCase() === currency?.toLowerCase(),
                );
                const isSelected = props.selectedPriceId === price.id;

                return (
                  <AppBillingPriceCard
                    key={product.id + '-' + price.id}
                    modePreview={props.modePreview}
                    modeCategory={props.modeCategory}
                    modePrice={props.modePrice}
                    modeLink={props.modeLink}
                    size={props.whSize}
                    price={price}
                    product={product}
                    isSelected={isSelected}
                    featurePreset={isHeroMode ? 'short' : 'full'}
                    displayPreset={isHeroMode ? 'short' : 'full'}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* </Container> */}
      </ContainerScroll>
    </>
  );
};

export default AppBillingPricingCards;
