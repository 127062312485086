import {
  BatchJobStatus,
  SortDirection,
  WeightComplianceBayDetailsFragment,
  WeightComplianceLocationDetailsFragment,
  WeightComplianceMetaFragment,
  WeightComplianceStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  viewerSelectedBay,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../layout/viewer/store/viewer.state';
import { WeightComplianceBaysTableColumn } from '../panels/compliance/weight/WeightComplianceBaysTable';
import { WeightComplianceLocationTableColumn } from '../panels/compliance/weight/WeightComplianceLocationsTable';
import { simulationCurrent } from './simulation.state';
import {
  WeightComplianceBaysData,
  WeightComplianceDataState,
  WeightComplianceLocationsData,
} from './simulation.types';
import {
  simulationComplianceTabKey,
  simulationWizardSelectedStepId,
} from './simulation.wizard.state';
import {
  loadBayWeightHeatmap,
  loadLocationWeightHeatmap,
} from './weightCompliance.helper';

const getKey = (postfix: string) =>
  `warego-simulation-weight-compliance-${postfix}`;

export const weightComplianceMeta = atom<WeightComplianceMetaFragment>({
  key: getKey('meta'),
  default: null,
});

export const weightComplianceMetaLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('summary-load-status'),
  default: AsyncLoadStatus.Ok,
});

const weightComplianceShowHeatmapAtom = atom<boolean>({
  key: getKey('show-heatmap-atom'),
  default: true,
});

export const weightComplianceShowHeatmap = selector<boolean>({
  key: getKey('show-heatmap'),
  get: ({ get }) => get(weightComplianceShowHeatmapAtom),
  set: ({ set }, value) => {
    //value && set(viewerShowLocations, value);
    set(weightComplianceShowHeatmapAtom, value);
  },
});

// Weight compliance table view
export const weightComplianceBaysData = atom<WeightComplianceBaysData>({
  key: getKey('bays-data'),
  default: null,
});

export const weightComplianceBaysState = atom<
  WeightComplianceDataState<WeightComplianceBaysTableColumn>
>({
  key: getKey('bays-data-state'),
  default: {
    searchValues: { status: WeightComplianceStatus.NONCOMPLIANT },
    sortValues: {
      bayTitle: SortDirection.ASC,
    },
  },
});

export const weightComplianceLocationsData =
  atom<WeightComplianceLocationsData>({
    key: getKey('locations-data'),
    default: null,
  });

export const weightComplianceLocationsState = atom<
  WeightComplianceDataState<WeightComplianceLocationTableColumn>
>({
  key: getKey('locations-data-state'),
  default: {
    searchValues: {
      status: WeightComplianceStatus.NONCOMPLIANT,
    },
    sortValues: {
      locationId: SortDirection.ASC,
    },
  },
});

export const weightComplianceDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('data-load-status'),
  default: AsyncLoadStatus.None,
});

export const weightComplianceLevelLocation = selector<
  Record<string, WeightComplianceLocationDetailsFragment>
>({
  key: getKey('locations-weight-by-level'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const compliance = get(weightComplianceMeta);
    const areaId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);

    if (
      _.isNil(level) ||
      compliance?.status !== BatchJobStatus.READY ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-weight'
    )
      return null;

    const locationMap = await loadLocationWeightHeatmap({
      simulationId: sim.id,
      areaId,
      level,
    });

    return locationMap;
  },
});

export const weightComplianceBayLocation = selector<
  Record<string, WeightComplianceLocationDetailsFragment>
>({
  key: getKey('locations-weight-by-bay'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const areaId = get(viewerSelectedPlaneId);
    const bayId = get(viewerSelectedBay)?.id;
    const stepId = get(simulationWizardSelectedStepId);
    const compliance = get(weightComplianceMeta);
    const tab = get(simulationComplianceTabKey);

    if (
      _.isNil(bayId) ||
      compliance?.status !== BatchJobStatus.READY ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-weight'
    )
      return null;

    const locationMap = await loadLocationWeightHeatmap({
      simulationId: sim.id,
      areaId,
      bayId,
    });
    return locationMap;
  },
});

const weightComplianceIsVisible = selector<boolean>({
  key: getKey('is-compliance-visible'),
  get: ({ get }) => {
    const compliance = get(weightComplianceMeta);
    const areaId = get(viewerSelectedPlaneId);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);

    if (
      _.isNil(areaId) ||
      compliance?.status !== BatchJobStatus.READY ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-weight'
    )
      return null;

    return true;
  },
});

export const weightComplianceBay = selector<
  Record<string, WeightComplianceBayDetailsFragment>
>({
  key: getKey('bay-weight-map'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const areaId = get(viewerSelectedPlaneId);
    const isVisible = get(weightComplianceIsVisible);
    if (!isVisible) return null;
    const bayMap = await loadBayWeightHeatmap({
      simulationId: sim.id,
      areaId,
    });
    return bayMap;
  },
});
