import { format } from 'date-fns';
import Konva from 'konva';
import React, { useState } from 'react';
import { KonvaNodeEvents, Line, Rect, RegularPolygon, Text } from 'react-konva';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TwTheme } from '../../../Tw';
import { formatTime } from '../../common/formatHelper';
import { getDimensionColor } from '../../simulation/store/workforce.helper';
import {
  workforceHoveredWave,
  workforceSelectedDeadline,
} from '../../simulation/store/workforce.state';
import { AgentWorkforceWave } from '../../simulation/store/workforce.types';
import { ticksInPixel } from '../viewer/layers/SchedulerLayer';

const menuColors = TwTheme.extend.colors.menu;
const brandColors = TwTheme.extend.colors.brand;

const widthDay = 24 * 60; //24h*60min
const heightDay = widthDay / 3;
const height = 0.8 * heightDay;

export type WaveFeatureProps = {
  wave: AgentWorkforceWave;
};

const WaveFeature: React.FC<WaveFeatureProps> = props => {
  const setHoveredGlobal = useSetRecoilState(workforceHoveredWave);
  const [selectedDeadline, setSelectedDeadline] = useRecoilState(
    workforceSelectedDeadline,
  );
  const [hovered, setHovered] = useState(false);

  const { wave } = props;
  const id = wave.forecast.to.toString();
  const idETA = wave.source.to.toString();
  const x = wave.forecast.from.getTime() / ticksInPixel;
  const xMax = wave.forecast.to.getTime() / ticksInPixel;
  const width = xMax - x;

  const waveDeadline = wave.source.to.getTime() / ticksInPixel;
  const waveStart = wave.remainingDuration < 0;
  const waveEnd = wave.scheduledIndex === 0;

  const cornerRadiusMax = Math.min(0.085 * heightDay, width / 2);
  const cornerRadiusStart = waveStart ? cornerRadiusMax : 0;
  const cornerRadiusEnd = waveEnd ? cornerRadiusMax : 0;

  const isSelectedDeadline = wave.source.to === selectedDeadline;

  const waveConfig: Konva.RectConfig = {
    id,
    x,
    y: 0.115 * heightDay,
    width,
    height,
    fill: getDimensionColor(idETA)?.[0],
    // strokeScaleEnabled: true,
    // strokeWidth: 5,
    // perfectDrawEnabled: false,
    cornerRadius: [
      cornerRadiusStart, // LeftBottom
      cornerRadiusEnd, // RightBottom
      cornerRadiusEnd, // RightTop
      cornerRadiusStart, // LeftTop
    ],
    fillEnabled: true,
    opacity: hovered ? 0.85 : isSelectedDeadline ? 1 : 0.45,
    stroke: menuColors[800],
    // stroke: brandColors[500],
    strokeWidth: isSelectedDeadline ? 5 : 0,
    shadowEnabled: isSelectedDeadline ? true : false,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowBlur: 12,
    shadowOffset: {
      x: 10,
      y: -10,
    },
    // dash: [15, 20],
  };

  const waveSelectionStroke: Konva.RectConfig = {
    id,
    x,
    y: 0.115 * heightDay,
    width,
    height,
    cornerRadius: [
      cornerRadiusStart, // LeftBottom
      cornerRadiusEnd, // RightBottom
      cornerRadiusEnd, // RightTop
      cornerRadiusStart, // LeftTop
    ],
    fillEnabled: false,
    opacity: hovered ? 0.85 : isSelectedDeadline ? 1 : 0.45,
    perfectDrawEnabled: true,
    strokeScaleEnabled: true,
    stroke: brandColors[500],
    strokeWidth: isSelectedDeadline ? 5 : 0,
    dash: [15, 20],
  };

  const lineDeadlineConfig: Konva.LineConfig = {
    id: `line-${id}`,
    points: [waveDeadline, 0, waveDeadline, heightDay * 1.25],
    strokeWidth: 4,
    stroke: getDimensionColor(idETA)?.[0],
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0,
    lineCap: 'round',
    strokeScaleEnabled: true,
    perfectDrawEnabled: true,
  };

  const flagDeadlineConfig: Konva.RegularPolygonConfig = {
    scaleY: -1,
    x: waveDeadline - 7,
    y: height * 1.55,
    rotation: -30,
    sides: 3,
    radius: 24,
    fill: getDimensionColor(idETA)?.[0],
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0,
    // stroke: 'black',
    // strokeWidth: 4,
  };

  const titleDeadlineConfig: Konva.TextConfig = {
    x: waveDeadline + 15,
    y: height * 1.58,
    // stroke: 'yellow',
    fill: getDimensionColor(idETA)?.[0],
    text: formatTime(wave.source.to),
    fontSize: height * 0.1,
    fontFamily: 'NeoSans-Regular',
    scaleY: -1,
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0,
    // padding: 10,
    // stroke: getDimensionColor(idETA)?.[0],

    // rotation: -90,
  };

  const tickStartOfDeadlineConfig: Konva.LineConfig = {
    id: `tick-start-${id}`,
    points: waveStart ? [xMax, -170, xMax, heightDay] : [x, -170, x, heightDay],
    // points: [xMax, height * 0.15, xMax, heightDay * 1.25],
    strokeWidth: 4,
    stroke: getDimensionColor(idETA)?.[0],
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0,
    lineCap: 'round',
    strokeScaleEnabled: true,
    perfectDrawEnabled: true,
    dash: [4, 16],
  };

  const tickEndOfDeadlineConfig: Konva.LineConfig = {
    id: `tick-end-${id}`,
    points: waveStart ? [x, -170, x, heightDay] : [xMax, -170, xMax, heightDay],
    // points: [x, height * 0.15, xMax, heightDay * 1.25],
    strokeWidth: 4,
    stroke: getDimensionColor(idETA)?.[0],
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0,
    lineCap: 'round',
    strokeScaleEnabled: true,
    perfectDrawEnabled: true,
    dash: [4, 16],
  };

  const triangleDeadlineConfig: Konva.RegularPolygonConfig = {
    scaleY: -1,
    points: waveStart
      ? [xMax, -180, xMax, heightDay]
      : [xMax, -180, xMax, heightDay],
    y: -175,
    rotation: waveStart ? 30 : -30,
    // rotation: 0,
    sides: 3,
    radius: 18,
    fill: getDimensionColor(idETA)?.[0],
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0,

    // stroke: 'black',
    // strokeWidth: 4,
  };

  const textDeadlineConfig: Konva.TextConfig = {
    x: waveStart ? x - 30 : xMax - 30,
    y: -185,
    // stroke: 'yellow',
    fill: getDimensionColor(idETA)?.[0],
    text: waveStart
      ? formatTime(wave.forecast.from)
      : formatTime(wave.forecast.to),
    fontSize: height * 0.075,
    fontFamily: 'NeoSans-Regular',
    scaleY: -1,
    opacity: hovered ? 0.5 : isSelectedDeadline ? 1 : 0.25,
    align: 'center',
    // padding: 10,
    // stroke: getDimensionColor(idETA)?.[0],

    // rotation: -90,
  };

  const showTitle = width > widthDay / 24;
  const waveTitle: Konva.TextConfig = {
    x: x + 0.08 * heightDay,
    y: heightDay * 0.85,
    // width,
    // height,
    rotation: -90,
    fontSize: 0.08 * Math.abs(height),
    fontFamily: 'NeoSans-Regular',
    text: format(wave.source.to, 'dd hh:mm'), //{format(wave.forecast.to, 'MMM dd hh:mm')}
    fill: 'rgba(255,255,255,0.9)',
    // globalCompositeOperation: 'xor',
    scaleY: -1,
    align: 'center',
    //verticalAlign: 'top',
  };

  function selectDeadline() {
    setSelectedDeadline(isSelectedDeadline ? null : wave.source.to);
  }
  const eventConfig: KonvaNodeEvents = {
    onClick: selectDeadline,
    onTap: selectDeadline,
    onMouseEnter: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
      setHoveredGlobal(wave);
      setHovered(true);
    },

    onMouseLeave: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';
      setHoveredGlobal(null);
      setHovered(false);
    },
  };

  return (
    <React.Fragment key={id}>
      {/* Wave Deadline */}
      <Line {...lineDeadlineConfig} />
      <RegularPolygon {...flagDeadlineConfig} />
      <Text {...titleDeadlineConfig} />

      {/* Wave Time Guides */}
      <Line {...tickStartOfDeadlineConfig} />
      <Line {...tickEndOfDeadlineConfig} />
      <Text {...textDeadlineConfig} />
      <RegularPolygon {...triangleDeadlineConfig} />

      {/* Wave */}
      <Rect {...waveConfig} {...eventConfig} />
      <Rect {...waveSelectionStroke} {...eventConfig} />
      {showTitle && <Text {...waveTitle} />}
    </React.Fragment>
  );
};

export default WaveFeature;
