import * as React from 'react';
const CongestionMap91Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CongestionMap91Icon"
    {...props}
  >
    <g opacity={0.4}>
      <circle
        cx={18.8}
        cy={64}
        r={14.81}
        strokeWidth={0}
        transform="translate(-44.59 43.22) rotate(-55.48)"
      />
    </g>
    <g opacity={0.6}>
      <circle
        cx={41.4}
        cy={41.4}
        r={14.81}
        strokeWidth={0}
        transform="translate(-16.17 52.05) rotate(-55.48)"
      />
    </g>
    <g opacity={0.8}>
      <circle
        cx={64}
        cy={18.8}
        r={14.81}
        strokeWidth={0}
        transform="translate(12.25 60.88) rotate(-55.48)"
      />
    </g>
    <circle
      cx={41.4}
      cy={86.6}
      r={14.81}
      strokeWidth={0}
      transform="translate(-53.42 71.64) rotate(-55.48)"
    />
    <g opacity={0.7}>
      <circle
        cx={64}
        cy={64}
        r={14.81}
        strokeWidth={0}
        transform="translate(-25 80.46) rotate(-55.48)"
      />
    </g>
    <g opacity={0.9}>
      <circle
        cx={86.6}
        cy={41.4}
        r={14.81}
        strokeWidth={0}
        transform="translate(3.42 89.29) rotate(-55.48)"
      />
    </g>
    <g opacity={0.9}>
      <circle
        cx={64}
        cy={109.21}
        r={14.81}
        strokeWidth={0}
        transform="translate(-62.25 100.05) rotate(-55.48)"
      />
    </g>
    <g opacity={0.8}>
      <circle
        cx={86.6}
        cy={86.6}
        r={14.81}
        strokeWidth={0}
        transform="translate(-33.83 108.88) rotate(-55.48)"
      />
    </g>
    <g opacity={0.5}>
      <circle
        cx={109.21}
        cy={64}
        r={14.81}
        strokeWidth={0}
        transform="translate(-5.41 117.71) rotate(-55.48)"
      />
    </g>
  </svg>
);
export default CongestionMap91Icon;
