import classNames from 'classnames';
import { format, isWeekend } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import { formatInteger, formatTimespan } from '../../common/formatHelper';
import { TooltipItem } from '../../components/stats/TooltipItem';
import { StageType } from '../../layout/stage/stage.helper';
import TooltipWrapper from '../../layout/tooltip/TooltipWrapper';
import { actualityAnalyzedJobSummaryById } from '../store/actuality.state';
import {
  feedHoveredPickListId,
  feedPicklistsSummaryById,
} from '../store/feed.state';

export type TimelineTooltipProps = {
  stageId: StageType;
};

const TimelineTooltip: React.FC<TimelineTooltipProps> = props => {
  const { t } = useTranslation('feed');
  const jobId = useRecoilValue(feedHoveredPickListId);
  const picklistsAll = useRecoilValue(feedPicklistsSummaryById);
  const analyzedJobs = useRecoilValue(actualityAnalyzedJobSummaryById);

  if (_.isNil(jobId)) return null;

  const actual = picklistsAll[jobId];
  // console.log('actual', actual);
  const analyzed = analyzedJobs[jobId];
  // console.log('analyzed', actual);

  const colors = getQualitativeColor(actual.agentId, 'dimension');

  const date = actual.minEventTime;
  const weekend = _.isNil(date) ? false : isWeekend(date);

  const dayTitleShort = _.isNil(date)
    ? t`No Date`
    : format(date, 'dd, MMM, yyyy hh:mm:ss');
  const weekdayTitle = _.isNil(date) ? null : format(date, 'EE');

  return (
    <TooltipWrapper stageId={props.stageId}>
      <div className="min-w-tooltip bg-app-panel-dark/90 whitespace-nowrap text-sm backdrop-blur backdrop-filter">
        <div className="min-w-tooltip">
          <div
            className="flex items-center p-2 text-xs ltr:pl-3 rtl:pr-3"
            style={{
              backgroundColor: colors[0],
              color: colors[1],
            }}
          >
            {actual.agentId}
          </div>
          <div
            className={classNames(
              'flex items-center',
              'px-2 py-2',
              'text-start text-sm font-bold',
              'bg-app-panel-dark',
            )}
          >
            <span className={classNames('ltr:pl-1 rtl:pr-1', 'flex-1')}>
              {dayTitleShort}
            </span>
            {weekdayTitle && (
              <span
                className={classNames(
                  'ltr:ml-3 rtl:mr-3',
                  'px-2 py-0.5',
                  'rounded uppercase',
                  'border',
                  weekend ? 'border-menu/50 text-menu-active' : 'border-menu',
                )}
              >
                {format(date, 'EE')}
              </span>
            )}
          </div>
        </div>
        <div
          className={classNames(
            'px-1',
            'divide-menu/50 divide-y divide-solid divide-opacity-50',
            'border-menu/75 border-t',
          )}
        >
          <TooltipItem title={t`ID`} value={actual.jobId} />
          <TooltipItem
            title={t`Process Type`}
            value={actual.eventProcessType}
          />
          {analyzed?.cost && (
            <TooltipItem
              title={t`Cost`}
              value={formatInteger(analyzed?.cost)}
            />
          )}

          {/* <TooltipItem
            title={t`Duration`}
            value={formatTimespan(actual?.duration * 1000)}
          /> */}
          {analyzed?.duration && (
            <TooltipItem
              title={t`Duration (Sim)`}
              value={formatTimespan(analyzed?.duration * 1000)}
            />
          )}

          <TooltipItem title={t`Lines`} value={formatInteger(actual.lines)} />
          <TooltipItem title={t`UOMs`} value={formatInteger(actual.uomCount)} />
        </div>
      </div>
    </TooltipWrapper>
  );
};

export default TimelineTooltip;
