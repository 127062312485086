import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import TitleToolbarContainer from '../../containers/TitleToolbarContainer';
import {
  loadBalancingPolicyLocationByLevel,
  loadBalancingPolicyRuleSelected,
  loadBalancingPolicyShowHeatmap,
} from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import useGetZoneTitle from '../../loadBalancingPolicy/useGetZoneTitle';

const LoadBalancingPolicyToolbar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const [showHeatmap, setShowHeatmap] = useRecoilState(
    loadBalancingPolicyShowHeatmap,
  );
  const ruleSummary = useRecoilValue(loadBalancingPolicyLocationByLevel);
  const rule = useRecoilValue(loadBalancingPolicyRuleSelected);
  const getZoneTitle = useGetZoneTitle();

  if (_.isNil(ruleSummary) || _.isNil(rule)) return null;

  return (
    <ButtonToolbarGroup
      className={`absolute ltr:left-1 rtl:right-1 top-1 bottom-1 flex flex-col`}
    >
      {/* <TitleToolbarContainer
        id="panel-toolbar-analytics-heatmap-weight"
        title={t`Analytics`}
        collapsible
      >
        <div className="flex items-start">
          <ButtonToolbar isSelected={showHeatmap} onChange={setShowHeatmap}>
            <Icon.HeatMap9
              className="w-8 h-8 text-white"
              alt={t`Show Weight heatmap`}
              title={t`Show Weight heatmap`}
            />
          </ButtonToolbar>
        </div>
      </TitleToolbarContainer> */}
      {showHeatmap && (
        <TitleToolbarContainer
          id="panel-toolbar-heatmap-weight"
          title={t`Balancing zones`}
          collapsible
          classNameContainer="mt-2 overflow-x-auto"
        >
          <div className="space-y-1">
            <div className="text-xs flex flex-col p-1 bg-menu-500 rounded mx-1">
              <span>Variance:</span>
              <span>{formatInteger(ruleSummary.variance)}</span>
            </div>
            <div className="text-xs flex flex-col p-1 bg-menu-500 rounded mx-1">
              <span>Load (avg)</span>
              <span>{formatInteger(ruleSummary.meanLoad)}</span>
            </div>
            <div className="overflow-y-auto h-full space-y-1">
              {_(ruleSummary.zones)
                .sortBy(z => z.zoneLoad)
                .map(z => {
                  const color = _.head(getQualitativeColor(z.zoneId, 'policy'));
                  return (
                    <div
                      key={z.zoneId}
                      className="text-xs flex flex-col p-1 bg-menu-500 rounded mx-1"
                    >
                      <div className="flex items-center">
                        <div
                          style={{ background: color }}
                          className="flex w-3 h-3 rounded mx-1"
                        ></div>
                        <div className="text-xs ml-1 truncate max-w-[10ch]">
                          {getZoneTitle(z.zoneId, rule)}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="flex items-center flex-1">
                          <div className="w-4 h-4 text-xs mx-1 flex items-center">
                            &#9733;
                          </div>
                          <div className="text-xs">
                            {formatInteger(z.zoneLoad)}
                          </div>
                        </div>

                        <div className="flex items-center flex-1 text-menu-active-300">
                          <div className="w-4 h-4 text-xs mx-1 flex items-center">
                            &Delta;
                          </div>
                          <div className="text-xs">
                            {
                              formatter.formatShare(
                                z.zoneLoad / ruleSummary.meanLoad - 1,
                              ).fullString
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                .value()}
            </div>
          </div>
        </TitleToolbarContainer>
      )}
    </ButtonToolbarGroup>
  );
};

export default LoadBalancingPolicyToolbar;
