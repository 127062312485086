import { PicklistLinesSortDirection } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { ActionBar } from '../../components/nav/ActionBar';
import OrderedItemsTable from '../../import/layout/converter/panels/OrderedItemsTable';

const orderedListItemOptions = [
  'sort-asc',
  'sort-desc',
  'sort-default',
  'remove',
] as const;
type OrderedListItemOptions = (typeof orderedListItemOptions)[number];
type OrderedListItemSortDirection = 'ASC' | 'DESC' | 'DEFAULT';

export type OrderedListItem<T extends string> = {
  type: T;
  direction?: 'ASC' | 'DESC' | 'DEFAULT';
};

//-- ORDERED LIST  ITEM --
type OrderedListItemProps = {
  value: string;
  sortDirection?: OrderedListItemSortDirection;
  onChange: (option: OrderedListItemOptions) => void;
};

const OrderedListItem: React.FC<OrderedListItemProps> = props => {
  const { t } = useTranslation('components');

  const optionsTitles: Record<OrderedListItemOptions, string> = {
    'sort-asc': t`Sort (Ascending)`,
    'sort-desc': t`Sort (Descending)`,
    'sort-default': t`Sort (Default)`,
    remove: t`Delete`,
  };
  const hasActiveSort =
    props.sortDirection === 'ASC' || props.sortDirection === 'DESC';

  const styleIconButton = cn(
    'h-5 w-5',
    'fill-current',
    // 'bg-menu/10 lg:bg-menu/5',
    // 'hover:bg-menu-active hover:text-menu-active-text',
    // hasActiveSort ? 'text-menu-active bg-menu/20' : '',
    'p-0.5',
    'mx-0.5',
    'rounded-full',
    'cursor-pointer',
  );

  const IconCpm = () => {
    switch (props.sortDirection) {
      case 'ASC':
        return <Icon.SortAscending {...props} className={styleIconButton} />;

      case 'DESC':
        return <Icon.SortDescending {...props} className={styleIconButton} />;

      default:
        return <Icon.SortNone {...props} className={styleIconButton} />;
    }
  };

  return (
    <div className="flex flex-1 items-center">
      <div className="flex flex-1 flex-col truncate text-xs xl:text-sm">
        <div>{props.value}</div>
        <div className="text-xxs flex items-center opacity-50">
          {/* <IconCpm /> */}
          <span className="flex-1">{props.sortDirection}</span>
        </div>
      </div>
      <DropdownSelector
        DropAlignRight
        buttonTransparent
        vertical
        value={'...'}
        values={[...orderedListItemOptions]}
        renderValue={v => optionsTitles[v] ?? '...'}
        onChange={props.onChange}
      />
    </div>
  );
};

//-- ORDERED LIST --

export type OrderedListProps<T extends string> = {
  current: OrderedListItem<T>[];
  options: Record<T, string>;
  onChange: (value: OrderedListItem<T>[]) => void;
};

function OrderedList<T extends string>(props: OrderedListProps<T>) {
  const { t } = useTranslation('app');
  const { current } = props;
  const [selectedType, setSelectedType] = useState<T>();
  const usedForSort = _.keyBy(current, s => s.type);
  const availableOptions = _.filter(
    _.keys(props.options) as T[],
    type => !_.has(usedForSort, type),
  );

  useEffect(() => {
    setSelectedType(_.head(availableOptions));
  }, [current]);

  const currentMap = _.keyBy(current, c => c.type);

  function addSortOption(type: T) {
    const newSorting: OrderedListItem<T>[] = [
      ...current,
      {
        type,
        direction: PicklistLinesSortDirection.ASC,
      },
    ];
    props.onChange(newSorting);
  }

  function onSortChange(newSortedTypes: T[]) {
    props.onChange(_.map(newSortedTypes, t => usedForSort[t]));
  }

  function onItemOptionChange(item: T, option: OrderedListItemOptions) {
    switch (option) {
      case 'sort-asc':
        props.onChange(
          _.map(current, so =>
            so.type === item ? { ...so, direction: 'ASC' } : so,
          ),
        );
        break;
      case 'sort-desc':
        props.onChange(
          _.map(current, so =>
            so.type === item ? { ...so, direction: 'DESC' } : so,
          ),
        );
        break;
      case 'sort-default':
        props.onChange(
          _.map(current, so =>
            so.type === item ? { ...so, direction: 'DEFAULT' } : so,
          ),
        );
        break;
      case 'remove':
        props.onChange(_.filter(current, so => so.type !== item));
        break;
    }
  }

  return (
    <div data-component="OrderedList" className="min-h-5">
      <div className={`bg-app-panel-dark/60 flex flex-col p-1`}>
        {!_.isEmpty(availableOptions) && (
          <ActionBar className="px-1 py-2">
            <DropdownSelector
              classNameDropdown="flex-1 text-sm mr-2"
              classNameLabel="text-xs"
              classNameItem="text-sm"
              dark
              DropAlignRight
              widthFull
              value={selectedType}
              renderValue={v => props.options[v]}
              values={availableOptions}
              onChange={(v: T) => setSelectedType(v)}
            />
            <Button
              className="rounded"
              buttonType="primary"
              buttonSize="xs"
              hasIconAfter
              buttonIcon={<Icon.Plus className={`h-4 w-4 fill-current`} />}
              onPress={() => addSortOption(selectedType)}
            />
          </ActionBar>
        )}

        <div className={`relative flex h-full min-h-[3rem] flex-1 flex-col`}>
          {!_.isEmpty(current) && (
            <OrderedItemsTable
              // itemColumnTitle={t`Sort by`}
              items={_.map(current, i => i.type)}
              onChange={onSortChange}
              render={(v: T) => (
                <OrderedListItem
                  value={props.options[v]}
                  sortDirection={currentMap[v]?.direction}
                  onChange={o => onItemOptionChange(v, o)}
                />
              )}
            />
          )}

          {_.isEmpty(current) && (
            <InboxZero message={t`No custom order is set.`} />
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderedList;
