import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import ImportPipelinesTable from './ImportPipelinesTable';

export type ImportPipelinesViewProps = {
  documentId?: string;
  documentImportType: ImportJobImportType;
};

const ImportPipelinesView: React.FC<ImportPipelinesViewProps> = props => {
  const { t } = useTranslation('dataset');
  return (
    <Container
      col
      hasOverflowY
      flex1
      componentName="ImportPipelinesView"
      className={classNames('relative')}
    >
      <PanelHeader title={t`Pipelines`} />

      <ImportPipelinesTable
        importType={props.documentImportType}
        targetId={props.documentId}
      />
    </Container>
  );
};

export default ImportPipelinesView;
