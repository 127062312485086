import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { getLocationWeightColor } from '../../store/weightCompliance.helper';
import { weightComplianceBayLocation } from '../../store/weightCompliance.state';

const WeightComplianceBayLocationLayer: React.FC = () => {
  const locationLoadable = useRecoilValueLoadable(weightComplianceBayLocation);

  if (locationLoadable.state !== 'hasValue') return null;
  const weightByLocationMap = locationLoadable.getValue();

  return (
    <BayLocationLayer
      stageId="simulation-bay-view"
      getColor={loc => {
        const locWeight = weightByLocationMap?.[loc.locationId];
        return locWeight ? getLocationWeightColor(locWeight) : null;
      }}
    />
  );
};

export default WeightComplianceBayLocationLayer;
