import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { delayDuration } from '../store/feed.helper';
import { feedLatestEvents, feedSelectedAgentId } from '../store/feed.state';

const FeedPoliciesPanel: React.FC = () => {
  const { t: tApp } = useTranslation('app');
  const { t } = useTranslation('feed');
  const eventsAll = useRecoilValue(feedLatestEvents);
  const device = useRecoilValue(feedSelectedAgentId);
  const [withIssues, setWithIssues] = useState(false);
  const events = _.filter(
    eventsAll,
    e =>
      !withIssues ||
      Math.abs(e.eventStartTime.getTime() - e.eventEndTime.getTime()) >
        delayDuration,
  );
  const legendOptions = [{ label: t`All` }, { label: t`Issues` }];

  return (
    <div className={classNames('h-full')}>
      <InboxZero selfCenter message={t`No Policies Found`} />
    </div>
  );
};

export default FeedPoliciesPanel;
