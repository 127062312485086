import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import OrderSetDashboardCard from '../../orders/OrderSetDashboardCard';
import { simulationOrderSetSummary } from '../store/simulation.layout.state';

const SimulationInfoPanelOrders: React.FC = () => {
  const { t } = useTranslation('simulation');

  const summary = useRecoilValue(simulationOrderSetSummary);
  if (!summary)
    return <InboxZero selfCenter message={t`No Data Stats found`} />;

  return (
    <>
      <ScreenTitle title={t`Orders`} subtitle={t`Stats`} isSticky />
      <div className={`p-2`}>
        <OrderSetDashboardCard summary={summary} itemLimit={200} />
      </div>
    </>
  );
};

export default SimulationInfoPanelOrders;
