import {
  eachDayOfInterval,
  endOfDay,
  endOfHour,
  startOfDay,
  startOfHour,
} from 'date-fns';
import Konva from 'konva';
import _ from 'lodash';
import React, { Fragment, useEffect, useRef } from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { actualityAnalyzedJobSummaries } from '../../../feed/store/actuality.state';
import {
  feedAgentShifts,
  feedEffectiveDateRange,
  feedPicklistsSummaryByAgent,
  feedSelectedAgentId,
} from '../../../feed/store/feed.state';
import FeedJobFeature, {
  jobFeatureHeight,
} from '../../features/FeedJobFeature';
import TimelineAgentShiftFeature from '../../features/TimelineAgentShiftFeature';
import TimelineFeature from '../../features/TimelineFeature';
import TimelineRowFeature from '../../features/TimelineRowFeature';
import { StageType } from '../../stage/stage.helper';
import { stageStateById } from '../../stage/stage.state';
import { viewerAppliedCachingSettings } from '../store/viewer.state';

export const ticksInPixel = 60 * 1000; // 1 min
export const stageHeight = 24 * 60;
export const jobTopMargin = 10;

export type JobsTimelineLayerProps = {
  stageId: StageType;
};

const JobsTimelineLayer: React.FC<JobsTimelineLayerProps> = props => {
  const picklistsAllLoadable = useRecoilValueLoadable(
    feedPicklistsSummaryByAgent,
  );
  const analyzedJobsLoadable = useRecoilValueLoadable(
    actualityAnalyzedJobSummaries,
  );
  const agentId = useRecoilValue(feedSelectedAgentId);
  const intervalLoadable = useRecoilValueLoadable(feedEffectiveDateRange);
  const shiftsLoadable = useRecoilValueLoadable(feedAgentShifts);
  const setStageState = useSetRecoilState(stageStateById(props.stageId));
  const cachingSettings = useRecoilValue(viewerAppliedCachingSettings);
  const ref = useRef<Konva.Layer>(null);

  useEffect(() => {
    if (intervalLoadable.state === 'hasValue') {
      if (_.isEmpty(intervalLoadable.contents)) return;
      const [start, end] = intervalLoadable.contents;
      const xMin = startOfDay(start).getTime() / ticksInPixel;
      const xMax = endOfDay(end).getTime() / ticksInPixel;
      const days = 3; // 3 day
      const xScope = xMin + (days * 24 * 3600 * 1000) / ticksInPixel;

      const key = `${xMin}-${xMax}`;
      setStageState({
        autoSizeId: `auto-size-bay-${key}`,
        contentBounds: [
          [xMin, -stageHeight / 2],
          [Math.min(xMax, xScope), stageHeight / 2],
        ],
      });
    }
  }, [intervalLoadable]);

  useEffect(() => {
    if (ref?.current?.isCached) {
      ref?.current?.clearCache();
    }
    if (
      cachingSettings.type === 'on' ||
      (cachingSettings.type === 'auto' && cachingSettings.ratio < 1)
    ) {
      console.debug(
        'Cache JobsTimelineLayer with ratio: ',
        cachingSettings.ratio,
      );
      ref?.current?.cache({
        pixelRatio: cachingSettings.ratio,
        hitCanvasPixelRatio: cachingSettings.ratio,
      });
    }
  });

  const hasInterval = intervalLoadable.state === 'hasValue';
  const hasJobs = picklistsAllLoadable.state === 'hasValue';
  const hasAnalyzedJobs = analyzedJobsLoadable.state === 'hasValue';
  const hasShifts = shiftsLoadable.state === 'hasValue';

  const isReady = hasInterval && hasJobs && hasAnalyzedJobs && hasShifts;
  if (!isReady) return null;

  // all loaded

  const [start, end] = intervalLoadable.contents;
  const jobs = picklistsAllLoadable.getValue();
  const analyzedJobs = analyzedJobsLoadable.contents;
  const shifts = shiftsLoadable.contents;
  const analyzedJobsMap = _.keyBy(analyzedJobs, j => j.jobId);

  const totalAgents = _.size(jobs);
  if (_.isEmpty(jobs)) return null;

  return (
    <Layer>
      {eachDayOfInterval({
        start,
        end,
      }).map((day, index) => {
        return (
          <TimelineFeature
            key={`day-${day}-${index}`}
            day={day}
            index={index}
            rowCount={totalAgents}
            maxShiftCount={1}
          />
        );
      })}

      {_(jobs)
        .values()
        .sortBy(agentJobs => {
          const agentId = _.head(agentJobs)?.agentId;
          const firstShift = _(shifts)
            .filter(s => s.agentUser === agentId)
            .minBy(s => s.shiftStart);

          return firstShift.shiftStart?.getHours();
        })
        .map((agentJobs, agentIndex) => {
          const agentId = _.head(agentJobs)?.agentId;
          const agentShifts = _.filter(shifts, s => s.agentUser === agentId);

          return (
            <Fragment key={`agent-shifts-${agentId}`}>
              <TimelineRowFeature
                from={startOfDay(start)}
                to={endOfDay(end)}
                index={agentIndex}
              />

              {_.map(agentShifts, shift => {
                return (
                  <TimelineAgentShiftFeature
                    key={`job-${shift.shiftId}-${agentId}`}
                    id={`shift-${shift.shiftId}-${agentId}`}
                    from={startOfHour(shift.shiftStart)}
                    to={endOfHour(shift.shiftEnd)}
                    index={agentIndex}
                    title={`${agentId}`}
                  />
                );
              })}

              {_.map(agentJobs, (j, index) => {
                const anlJob = analyzedJobsMap[j.jobId];
                const isFirstJob = index === 0;
                const isLastJob = index === agentJobs.length - 1;
                const viewJobMode = 'user';

                return (
                  <FeedJobFeature
                    key={`job-${j.jobId}`}
                    job={j}
                    analyzedJob={anlJob}
                    offsetY={agentIndex * jobFeatureHeight}
                    // isActive={selectedJobId === j.jobId}
                    isFirst={isFirstJob}
                    isLast={isLastJob}
                    viewJobMode={viewJobMode}
                  />
                );
              })}
            </Fragment>
          );
        })
        .value()}
    </Layer>
  );
};

export default JobsTimelineLayer;
