import { useGetImportTransformedPreviewLazyQuery } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { errorAppender } from '../store/error.state';
import { createDefaultMapping } from './store/import.helper';
import {
  importMappingSettingsByType,
  importTransformedPreview,
  importTransformedPreviewStatus,
  importTypeCurrent,
} from './store/import.state';
import useApplyMappingSettings from './useApplyMappingSettings';

export type ApplyImportTransformationParams = {
  jobId: string;
  query: string;
  skipMapping?: boolean;
};

function useApplyImportTransformation() {
  const applyMappings = useApplyMappingSettings();
  const [getTransformed] = useGetImportTransformedPreviewLazyQuery();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot apply preview transformation`;

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ApplyImportTransformationParams) => {
        set(importTransformedPreviewStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: ApplyImportTransformationParams) => {
        const type = await snapshot.getPromise(importTypeCurrent);
        const mappingSettings = await snapshot.getPromise(
          importMappingSettingsByType(type),
        );
        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importTransformedPreviewStatus, AsyncLoadStatus.Error);
        }

        const hasTransformation =
          mappingSettings?.transformation?.enabled ?? false;
        if (!hasTransformation) {
          set(importTransformedPreviewStatus, AsyncLoadStatus.Ok);
          return;
        }

        try {
          const response = await getTransformed({
            variables: {
              jobId: params.jobId,
              query: params.query,
            },
          });
          const { data, error, errors } = response;
          if (!_.isEmpty(errors)) {
            const messages = _.map(errors, e => e.message);
            set(importTransformedPreview, {
              fields: [],
              data: null,
              errors: messages,
            });
            set(importTransformedPreviewStatus, AsyncLoadStatus.Error);
            return;
          }

          const resp = data.importJob?.previewQuery;

          const columnNames = _.map(resp?.columns, c => c.name);
          set(importTransformedPreview, {
            fields: columnNames,
            data: resp.result,
          });
          set(importTransformedPreviewStatus, AsyncLoadStatus.Ok);

          if (!params.skipMapping) {
            const newMapping = createDefaultMapping(
              mappingSettings,
              columnNames,
            );
            applyMappings({
              mappingSettings: newMapping,
            });
          }
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: ApplyImportTransformationParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    //
  }

  return [call, cancel] as const;
}

export default useApplyImportTransformation;
