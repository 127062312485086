import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AppHeaderData from '../../components/AppHeaderData';
import { AppHeaderProps } from '../../components/AppHeaderImporter';
import { getImportConfigurations } from '../../import/store/import.default';
import { ImportType } from '../../import/store/import.types';
import { warehouseSelected } from '../../store/warehouse.state';

export type DashboardItemsGroupContainerProps = {
  dataType?: ImportType;
  document?: any;
  title?: string;
};

const HeaderDataset: React.FC<DashboardItemsGroupContainerProps> = ({
  dataType,
  document,
  title,
}) => {
  const { t } = useTranslation('dataset');
  const warehouse = useRecoilValue(warehouseSelected);

  const importConfigurations = getImportConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.dataType === dataType,
  );
  if (!datasetConfig) return null;

  if (!warehouse) return null;

  const basePath = `/wh/i/${warehouse.id}`;

  const headerProps: AppHeaderProps = {
    warehouseId: warehouse.id,
    breadcrumbItems: [
      { title: warehouse.title, href: `${basePath}` },
      { title: t`Data Dashboard`, href: `${basePath}/data` },
      {
        title: t(`Dataset: {{ datasetType }}`, {
          datasetType: datasetConfig.title,
        }),
        href: datasetConfig.getDashboardPath(warehouse.id),
      },
    ],
  };

  // document-specific breadcrumb
  if (document) {
    // If document is passed, add document-specific breadcrumb
    headerProps.breadcrumbItems.push({
      title: document.title ?? t`Dataset`,
      href: `/wh/i/${warehouse.id}/${dataType}/${document.id ?? ''}`,
    });
  } else {
    // If no document is passed, add a different breadcrumb item
    headerProps.breadcrumbItems.push({
      title: title ?? t`Dataset`,
      //href: `/wh/i/${wh.id}/${datasetType}/general`, // Adjust the href as needed
    });
  }

  return (
    <>
      <AppHeaderData {...headerProps} />
    </>
  );
};

export default HeaderDataset;
