import {
  AnalyzeEventsQueryBuilderParams,
  getAnalyzeEventsFilteredQueryBuilder,
} from './analyzedEventsQueryBuilder';
import { getActivityFeedJobsBuilder } from './feedJobsQueryBuilders';

export function analyzedFeedEventsQueryBuilder(
  params: AnalyzeEventsQueryBuilderParams,
) {
  let builder = getActivityFeedJobsBuilder(params)
    .with(
      cte => cte('aje'),
      db => {
        return getAnalyzeEventsFilteredQueryBuilder(params, db).selectAll();
      },
    )
    .with(
      cte => cte('ajewpl'),
      db => {
        return db
          .selectFrom('aje')
          .innerJoin('afj', join => join.onRef('aje.jobId', '=', 'afj.jobId'))
          .selectAll('aje')
          .select([
            'afj.jobKey',
            'afj.agentId',
            'afj.minEventTime',
            'afj.maxEventTime',
          ]);
      },
    );
  return builder;
}
