import React, { PropsWithChildren, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

export type ContainerViewportProps = PropsWithChildren & {
  onEnterViewport?: VoidFunction;
  onLeaveViewport?: VoidFunction;
  threshold?: number;
};

const ContainerViewport: React.FC<ContainerViewportProps> = props => {
  const ref = useRef();
  const { inViewport, enterCount, leaveCount } = useInViewport(
    ref,
    {
      threshold: props.threshold ?? 0,
    },
    { disconnectOnLeave: false },
    {
      onEnterViewport: props.onEnterViewport,
      onLeaveViewport: props.onLeaveViewport,
    },
  );

  return <section ref={ref}>{props.children}</section>;
};

export default ContainerViewport;
