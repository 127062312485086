import { IsNotEmpty } from '@warego/class-validator';
import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { CreateBaseEntityInput } from './create-base-entity.input';

@InputType({ isAbstract: true })
export class CreateBaseWarehouseEntityInput extends CreateBaseEntityInput {
  @Field(() => ID)
  @IsNotEmpty()
  warehouseId: string;
}
