import * as React from 'react';
const PickingWaveAreaHistoryIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PickingWaveAreaHistoryIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m65.29,121.84v-56.55s56.55,0,56.55,0c.71,0,1.29-.58,1.29-1.29s-.58-1.29-1.29-1.29h-56.55s0-56.55,0-56.55c0-.71-.58-1.29-1.29-1.29-.71,0-1.29.58-1.29,1.29v56.55s-56.55,0-56.55,0c-.71,0-1.29.58-1.29,1.29s.58,1.29,1.29,1.29h56.55s0,56.55,0,56.55c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29h0Z" />
    <path d="m117.97,117.92v-23s-23,0-23,0v23s23,0,23,0Z" />
    <path d="m117.97,92.11v-23.02s-23,0-23,0v23s23,0,23,0h0Z" opacity={0.5} />
    <path d="m92.16,117.92v-23s-23,0-23,0v23s23,0,23,0Z" opacity={0.6} />
    <path d="m92.16,92.11v-23.02s-23,0-23,0v23s23,0,23,0h0Z" />
    <path d="m92.16,58.86v-23.02s-23,0-23,0v23.02s23,0,23,0Z" />
    <path d="m92.16,33.04V10.03s-23,0-23,0v23s23,0,23,0Z" opacity={0.5} />
    <path d="m35.89,94.91v23s23.02,0,23.02,0v-23s-23.02,0-23.02,0Z" />
    <path
      d="m35.89,69.09v23s23.02,0,23.02,0v-23s-23.02,0-23.02,0Z"
      opacity={0.6}
    />
    <g>
      <path d="m33.07,7.58c-14.04,0-25.46,11.42-25.46,25.46h-3.79l9.32,10.99,9.32-10.99h-4.82c0-8.5,6.92-15.42,15.42-15.42s15.42,6.92,15.42,15.42-6.92,15.42-15.42,15.42v10.04c14.04,0,25.46-11.42,25.46-25.46S47.1,7.58,33.07,7.58Zm6.01,48.5c-.9.21-1.8-.35-2.01-1.25l-.63-2.71c-.21-.9.35-1.8,1.25-2.01.9-.21,1.8.35,2.01,1.25l.63,2.71c.21.9-.35,1.8-1.25,2.01ZM15.86,30.66c-.1.92-.94,1.58-1.85,1.47l-2.76-.32c-.92-.11-1.58-.94-1.47-1.85.11-.92.93-1.58,1.85-1.47l2.76.32c.92.11,1.58.94,1.47,1.85Zm3.18-7.8c-.52.76-1.56.96-2.33.44l-2.29-1.57c-.76-.52-.96-1.56-.44-2.33.52-.76,1.56-.96,2.33-.44l2.29,1.57c.76.52.96,1.56.44,2.32Zm6.45-5.42c-.82.43-1.83.12-2.26-.7l-1.3-2.46c-.43-.82-.12-1.83.7-2.26.82-.43,1.83-.12,2.26.7l1.3,2.46c.43.82.12,1.83-.7,2.26Zm8.88-3.46c0,.92-.75,1.67-1.67,1.67s-1.67-.75-1.67-1.67v-2.78c0-.92.75-1.67,1.67-1.67s1.67.75,1.67,1.67v2.78Zm10.12.3l-1.3,2.46c-.43.82-1.44,1.13-2.26.7s-1.13-1.44-.7-2.26l1.3-2.46c.43-.82,1.44-1.13,2.26-.7.82.43,1.13,1.44.7,2.26Zm2.89,8.58c-.52-.76-.33-1.8.44-2.32l2.29-1.57c.76-.52,1.8-.33,2.33.44.52.76.33,1.8-.44,2.33l-2.29,1.57c-.76.52-1.8.33-2.33-.44Zm1.71,27.84c-.7.6-1.76.53-2.36-.17l-1.82-2.1c-.61-.7-.53-1.76.17-2.36.7-.61,1.75-.53,2.36.17l1.82,2.1c.61.7.53,1.76-.17,2.36Zm6.34-9.43c-.34.86-1.3,1.29-2.17.95l-2.59-1.01c-.86-.33-1.29-1.3-.95-2.17.33-.86,1.3-1.29,2.17-.95l2.59,1.01c.86.34,1.29,1.31.95,2.17Zm-.26-9.45l-2.76.32c-.92.11-1.75-.55-1.85-1.47-.11-.92.55-1.75,1.47-1.85l2.76-.32c.92-.11,1.75.55,1.85,1.47.11.92-.55,1.75-1.47,1.85Z" />
      <path d="m43.28,32.76h-8.24c-.19-.26-.41-.48-.67-.67v-8.72c0-.92-.75-1.67-1.67-1.67s-1.67.75-1.67,1.67v8.72c-.73.52-1.21,1.37-1.21,2.34,0,1.59,1.29,2.88,2.88,2.88.97,0,1.82-.48,2.34-1.21h8.24c.92,0,1.67-.75,1.67-1.67,0-.92-.75-1.67-1.67-1.67Z" />
    </g>
  </svg>
);
export default PickingWaveAreaHistoryIcon;
