import * as React from 'react';
const PolicyAllocationRequirementIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAllocationRequirementIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M76.28,59.61h20.33s-11.63-11.67-11.63-11.67h12.23l13.13,13.17c1.56,1.56,1.56,4.09,0,5.65l-12.16,12.18h-12.23l10.65-10.66h-10.26s-10.06-8.67-10.06-8.67Z"
    />
    <path
      strokeWidth={0}
      d="M50.72,59.61h-20.33s11.63-11.67,11.63-11.67h-12.23s-13.13,13.17-13.13,13.17c-1.56,1.56-1.56,4.09,0,5.65l12.16,12.18h12.23s-10.65-10.66-10.65-10.66h10.26s10.06-8.67,10.06-8.67Z"
      opacity={0.3}
    />
    <path
      strokeWidth={0}
      d="M79.38,40.86l-10.66-10.65v21.32c-.68-.29-1.76-1.25-2.67-2.14-.92-.91-2.41-.91-3.33,0s-1.99,1.86-2.67,2.14v-21.33s-11.67,11.63-11.67,11.63v-12.23s13.17-13.13,13.17-13.13c1.56-1.56,4.09-1.56,5.65,0l12.18,12.16v12.23Z"
      opacity={0.5}
    />
    <polyline
      strokeWidth={0}
      points="71.25 105.18 73.16 106.79 75.49 104.59 75.49 100.7 78.33 98.02 80.7 99.52 80.7 99.52 83.02 97.32 83.02 89.94 64.42 107.23 64.42 114.9 66.74 112.71"
    />
    <path
      strokeWidth={0}
      d="M45.81,89.94v7.38s18.6,17.58,18.6,17.58v-7.67s-18.6-17.29-18.6-17.29ZM46.7,94.35l16.83,15.64v2.51s-16.83-15.9-16.83-15.9v-2.25Z"
    />
    <polygon
      strokeWidth={0}
      points="48.28 89.26 48.28 76.7 63.06 89.85 63.06 102.61 48.28 89.26"
    />
    <polygon
      strokeWidth={0}
      points="65.91 89.85 80.53 76.71 80.53 89.26 65.91 102.6 65.91 89.85"
    />
    <polygon
      strokeWidth={0}
      points="58.67 64.65 64.41 59.69 79.74 72.94 73.67 78.28 58.67 64.65"
    />
    <polygon
      strokeWidth={0}
      points="49.08 72.94 56.32 66.68 71.34 80.34 64.41 86.44 49.08 72.94"
    />
  </svg>
);
export default PolicyAllocationRequirementIcon;
