import {
  LayoutAreaSummaryFragment,
  LayoutImportSummaryFragment,
  LayoutSummaryFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import { Card } from '../components/cards/Card';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import { StatListItem } from '../components/stats/StatListItem';

export type DashboardCardProps = {
  summary: LayoutSummaryFragment | LayoutImportSummaryFragment;
  itemLimit?: number;
  isActionable?: boolean;
};

const LayoutDashboardCard: React.FC<DashboardCardProps> = props => {
  const { t } = useTranslation('simulation');
  const { summary } = props;

  if (!summary) return null;
  const areas: Pick<
    LayoutAreaSummaryFragment,
    'areaId' | 'title' | 'locationCount'
  >[] = summary.areas;
  const { itemLimit = 10 } = props;

  return (
    <Card transparent isActionable label={`LayoutDashboardCard`}>
      <StatGroup
        helpNavTo={'simulation/dataset/simulation-dataset-stats-layout'}
      >
        <Stat
          dashboardView
          isPreview
          title={t`Aisles`}
          value={formatInteger(summary.aisleCount).toString()}
        />
        <Stat
          dashboardView
          isPreview
          title={t`Bays`}
          value={formatInteger(summary.bayCount).toString()}
        />
        <Stat
          dashboardView
          isPreview
          title={t`Locations`}
          value={formatInteger(summary.locationCount).toString()}
        >
          <StatListItem
            title={t`Enabled`}
            value={formatInteger(
              summary.locationCount - summary.disabledLocationCount,
            ).toString()}
          />
          <StatListItem
            title={t`Disabled`}
            value={formatInteger(summary.disabledLocationCount).toString()}
          />
        </Stat>
        <Stat dashboardView isPreview title={t`Locations by Area`}>
          {_.take(areas, itemLimit).map(area => (
            <StatListItem
              key={`card-item-${area.title}`}
              title={area.title}
              value={formatInteger(area.locationCount).toString()}
            />
          ))}
        </Stat>
      </StatGroup>
    </Card>
  );
};

export default LayoutDashboardCard;
