import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Icon from '../components/icons';
import { assignmentComplianceSelectedIdentity } from '../simulation/store/assignmentCompliance.state';
import { assignmentPolicyRule } from '../simulation/store/assignmentPolicy.state';
import { AssignmentPolicyFilterType } from '../simulation/store/assignmentPolicy.types';
import { namedColorsByKey } from '../store/namedColors.state';

type AssignmentComplianceStatusTagProps = {
  ruleId: string;
  hasIssue: boolean;
  isPrimary?: boolean;
  affectedFilterType?: AssignmentPolicyFilterType;
};

const AssignmentComplianceRuleTag: React.FC<
  AssignmentComplianceStatusTagProps
> = props => {
  const { t } = useTranslation('simulation');
  const rule = useRecoilValue(assignmentPolicyRule(props.ruleId));
  const setSelectedRule = useSetRecoilState(
    assignmentComplianceSelectedIdentity,
  );
  const [background, color] = useRecoilValue(namedColorsByKey(rule?.title));

  if (_.isNil(rule)) {
    console.warn(
      `Assignment policy rule with id: ${props.ruleId} was not found`,
    );
    return null;
  }

  return (
    <span
      data-component="AssignmentComplianceRuleTag"
      aria-label={`Assignment Compliance Rule`}
      className={`text-center px-2 py-1 rounded text-xxs flex whitespace-nowrap m-0.5 cursor-pointer`}
      style={{
        background: props.isPrimary ? background : `${background}1A`,
        color: props.isPrimary ? color : background,
        border: props.isPrimary ? '1px solid' : '1px solid',
      }}
      onMouseEnter={event => {
        if (!props.isPrimary) {
          const target = event.target as HTMLElement;
          target.style.background = `${background}33`;
          // target.style.border = '1px solid rgba(0, 0, 0, 1)';
        }
      }}
      onMouseLeave={event => {
        if (!props.isPrimary) {
          const target = event.target as HTMLElement;
          target.style.background = `${background}1A`;
          // target.style.border = '1px solid rgba(0, 0, 0, 0.9)';
        }
      }}
      onClick={() =>
        setSelectedRule({
          ruleId: rule.id,
          filterType: props.affectedFilterType ?? 'locations',
        })
      }
    >
      {props.hasIssue && (
        <Icon.TriangleInfo
          className={'w-4 h-4 mx-1 text-compliance-nonCompliant fill-current'}
        />
      )}
      {rule.title}
    </span>
  );
};

export default AssignmentComplianceRuleTag;
