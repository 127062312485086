import _ from 'lodash';

import { ColumnConfigBase } from './types';

export function getHeaderRow<T>(
  columnsConfig: ColumnConfigBase<T>[],
): string[] {
  return _(columnsConfig)
    .filter(col => !col.isHidden && !col.hiddenInExport)
    .map(col => col.title)
    .value();
}

export function getDataRows<T>(
  data: T[],
  columnsConfig: ColumnConfigBase<T>[],
): string[][] {
  return _.map(data, (row, index) => {
    return _(columnsConfig)
      .filter(col => !col.isHidden && !col.hiddenInExport)
      .map(column => _.get(row, column.field)?.toString() ?? '')
      .value();
  });
}
