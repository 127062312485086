import React from 'react';
import { AppFooter } from '../components/AppFooter';
import { AppHeader } from '../components/AppHeader';
import { ContainerAppView } from '../components/layout/ContainerAppView';
import SidebarHelp from '../screens/help/SidebarHelp';
import SidebarNotifications from '../screens/notifications/SidebarNotifications';
import SidebarSettings from '../screens/settings/SidebarSettings';
import SidebarGlobalMenu from '../screens/SidebarGlobalMenu';
import { WelcomeHome } from './WelcomeHome';

const Welcome: React.FC = () => {
  return (
    <>
      <AppHeader hasMenuGlobal hasHelp hasUser />
      <ContainerAppView>
        <WelcomeHome />
      </ContainerAppView>
      <AppFooter />
      <SidebarGlobalMenu />
      <SidebarHelp />
      <SidebarNotifications />
      <SidebarSettings />
    </>
  );
};

export default Welcome;
