import _ from 'lodash';
import React, { useState } from 'react';
import * as Icon from '../icons';

export type LinkUrlProps = {
  children?: React.ReactNode;
  label?: string;
  url: string;
  title?: string;
};

export const LinkUrl = ({ children, label, url, title }: LinkUrlProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <a
      data-component="LinkUrl"
      id={id}
      key={url}
      className={`mt-2 mb-4 flex items-center text-sm text-menu-active text-start group`}
      target="_blank"
      href={url}
      rel="noreferrer"
      title={title}
    >
      <Icon.ArrowRight className={`w-3 h-3 fill-current ltr:mr-1 rtl:ml-1`} />
      <span className="hover:text-menu-active-hover hover:border-b hover:border-menu-active-hover">
        {label ? label : url}
      </span>
      {children}
    </a>
  );
};
