import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import AnalyzeActuality from '../components/AnalyzeActuality';
import DataSettingSelector from '../components/DataSettingSelector';
import { actualitySelectedDocument } from '../store/actuality.state';
import { ActualityDatasetPatch } from '../store/actuality.types';

const FeedSettingsDataPanel: React.FC = () => {
  const { t } = useTranslation('feed');

  const [actuality, setActuality] = useRecoilState(actualitySelectedDocument);
  const [callUpdateActuality] = useUpdateActualityMutation();

  const isActivityFeedSelected = !!actuality.activityFeedId;

  function updateActuality(patch: ActualityDatasetPatch) {
    setActuality({
      ...actuality,
      ...patch,
    });

    callUpdateActuality({
      variables: {
        input: {
          ...patch,
          actualityId: actuality.id,
        },
      },
    });
  }

  return (
    <>
      {isActivityFeedSelected && <AnalyzeActuality />}

      <div className="space-y-4 px-2 pb-10 pt-4">
        <DataSettingSelector
          dataType="activityFeed"
          datasetId={actuality.activityFeedId}
          onChange={id =>
            updateActuality({
              activityFeedId: id,
            })
          }
          viewMode="panel"
        />
        <DataSettingSelector
          dataType="convertedLayout"
          datasetId={actuality.layoutId}
          onChange={id =>
            updateActuality({
              layoutId: id,
            })
          }
          viewMode="panel"
        />
        <DataSettingSelector
          dataType="items"
          datasetId={actuality.itemSetId}
          onChange={id =>
            updateActuality({
              itemSetId: id,
            })
          }
          viewMode="panel"
        />
        <DataSettingSelector
          dataType="assignment"
          datasetId={actuality.assignmentId}
          onChange={id =>
            updateActuality({
              assignmentId: id,
            })
          }
          viewMode="panel"
        />
      </div>
    </>
  );
};

export default FeedSettingsDataPanel;
