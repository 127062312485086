import { useApolloClient } from '@apollo/client';
import {
  LayoutImportConverterAreaFragment,
  LayoutImportConverterLocationFragment,
  LayoutImportLocation,
  LoadLayoutImportLocationsDocument,
  LoadLayoutImportLocationsQuery,
  LoadLayoutImportLocationsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import {
  ConverterSettings,
  LocationPatchHolder,
  getLocationKey,
  getLocationPatchKey,
  groupLocations,
} from '@warebee/shared/data-access-layout-import-converter';
import { instanceToPlain } from 'class-transformer';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import useSelectConverterWizardStep from '../../import/layout/converter/hooks/useSelectConverterWizardStep';
import {
  converterAreaConfiguration,
  converterEditableAreaId,
  converterSelectedArea,
  converterSelectedFloorAtom,
} from '../../import/layout/converter/store/converter.area.state';
import { getLocationDefaultConfig } from '../../import/layout/converter/store/converter.defaults';
import { applyLocationSettings } from '../../import/layout/converter/store/converter.helper';
import {
  getDefaultConverterSettings,
  mergeConverterSettings,
} from '../../import/layout/converter/store/converter.settings.helper';
import {
  converterAllAreasBuilder,
  converterBayTypesBuilder,
  converterBuilderState,
  converterCalculatedLayout,
  converterExtraLocations,
  converterLayoutBuilder,
  converterLayoutData,
  converterLayoutDataInitial,
  converterLayoutIdSaving,
  converterLayoutImportDocumentId,
  converterLocationPatches,
  converterLocationTypesBuilder,
  converterPatchedLocations,
  converterShelvesSettingsBuilder,
  converterSpacerAreaSettings,
  converterWizardStepByLayoutId,
} from '../../import/layout/converter/store/converter.state';
import { LayoutImportConverterData } from '../../import/layout/converter/store/converter.types';
import { importTriggeredBySim } from '../../store/global.state';
import { sidebarStateByType } from '../../store/sidebar.state';
import { warehouseSelected } from '../../store/warehouse.state';
import useLoadLayoutImportMeta from './useLoadLayoutImportMeta';

const locationChunk = 10000;

export function useLoadLayoutImport() {
  const selectStep = useSelectConverterWizardStep();
  const loadLayoutImportMeta = useLoadLayoutImportMeta();
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load layout import`;

  const cleanupState = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (layoutImportId: string) => {
        set(converterLayoutImportDocumentId, layoutImportId);
        set(converterLayoutDataInitial, null);
        set(converterLayoutData, null);

        set(converterLocationPatches, null);
        set(converterLayoutBuilder, null);
        set(converterAllAreasBuilder, null);
        set(converterAreaConfiguration, null);
        set(converterCalculatedLayout, null);
        set(converterLocationTypesBuilder, null);
        set(converterBayTypesBuilder, null);
        set(converterBuilderState, {
          status: AsyncLoadStatus.Loading,
          updatedAt: new Date(),
        });
        set(converterSpacerAreaSettings, null);

        set(converterSelectedArea, null);
        set(converterEditableAreaId, null);
        reset(converterLayoutIdSaving);
        reset(converterSelectedFloorAtom);

        //wizard
        const steps = await snapshot.getPromise(converterWizardStepByLayoutId);
        const sidebarStatus = await snapshot.getPromise(
          sidebarStateByType('sidebar-converter-wizard'),
        );
        selectStep(steps[layoutImportId] ?? 'getting-started');
        if (!sidebarStatus.isPinned) {
          set(sidebarStateByType('sidebar-converter-wizard'), {
            ...sidebarStatus,
            isCollapsed: false,
          });
        }
      },
  );

  const setLayoutImportIdCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (importLayoutId: string) => {
        const warehouse = await snapshot.getPromise(warehouseSelected);

        const layoutImportMeta = await loadLayoutImportMeta(importLayoutId);

        const layoutImport: LayoutImportConverterData = {
          ...layoutImportMeta,
          areas: [],
        };

        // Load settings from other Converter and apply to new one
        const importSettingsId =
          await snapshot.getPromise(importTriggeredBySim);
        let settingsToApply: ConverterSettings = null;
        if (!_.isNil(importSettingsId)) {
          const converterMetaToApply =
            await loadLayoutImportMeta(importSettingsId);
          settingsToApply = converterMetaToApply?.settings;
        }
        set(importTriggeredBySim, null);

        // LOAD LOCATIONS
        const queryCount = Math.ceil(
          layoutImport.locations.totalCount / locationChunk,
        );
        const locationChunks: LayoutImportConverterLocationFragment[][] = [];
        await Promise.all(
          _.range(queryCount).map(async i => {
            const locationResponse = await client.query<
              LoadLayoutImportLocationsQuery,
              LoadLayoutImportLocationsQueryVariables
            >({
              query: LoadLayoutImportLocationsDocument,
              variables: {
                layoutImportId: importLayoutId,
                page: {
                  skip: i * locationChunk,
                  limit: locationChunk,
                },
              },
            });
            locationChunks[i] = _.map(
              locationResponse.data.layoutImport.locations.content,
              l => ({
                ...l,
                locationKey: getLocationKey(l),
              }),
            );
          }),
        );

        const extraLocations: Record<string, LayoutImportLocation> = {
          ...layoutImport?.settings?.extraLocations,
          ...settingsToApply?.extraLocations,
        };

        const allLocation = [
          ..._.flatten(locationChunks),
          ..._.values(extraLocations),
        ];

        const locationMap = _.keyBy(allLocation, l => l.locationKey);

        const patchesToApply: Record<string, LocationPatchHolder> = {
          ...layoutImport?.settings?.locationsPatches,
          ...settingsToApply?.locationsPatches,
        };

        const patches = _.reduce(
          _.values(patchesToApply),
          (acc, p) => {
            const key = getLocationPatchKey(p);
            return {
              ...acc,
              [key]: { ...acc[key], ...p },
            };
          },
          {} as Record<string, LocationPatchHolder>,
        );

        _.forEach(_.values(patchesToApply), p => {
          const key = getLocationPatchKey(p);
          const l = locationMap[key];
          if (l) {
            _.forEach(_.entries(p.patch), ([propertyName, value]) => {
              l[propertyName] = value;
            });
          }
        });

        layoutImport.areas = groupLocations(_.values(locationMap)).map(
          c => instanceToPlain(c) as LayoutImportConverterAreaFragment,
        );

        //  CREATE SETTINGS
        const defaultSettings = getDefaultConverterSettings({
          layoutImport,
          measurementSystem: warehouse.measurementSystem,
        });

        let settings: ConverterSettings = mergeConverterSettings(
          defaultSettings,
          layoutImport?.settings,
        );

        if (!_.isNil(settingsToApply)) {
          settings = mergeConverterSettings(settings, settingsToApply);
        }

        set(converterLayoutBuilder, settings.layoutSettings);
        set(converterAllAreasBuilder, settings.areasBuilder);
        set(converterAreaConfiguration, settings.areasConfigurations);
        set(converterLocationPatches, patches);
        set(converterLocationTypesBuilder, settings.locationsTypes);
        set(converterBayTypesBuilder, settings.bayTypes);
        set(converterShelvesSettingsBuilder, settings.shelvesSettings);
        set(converterSpacerAreaSettings, settings.areaSpacers);
        set(converterExtraLocations, settings.extraLocations);
        const data = applyLocationSettings({
          data: layoutImport,
          locationPatches: {}, // settings.locationsPatches,
          locationTypeMap: settings.locationsTypes,
          locationTypeDefault: getLocationDefaultConfig(
            warehouse.measurementSystem,
          ),
          bayModelMap: settings.bayTypes,
        });

        // Set Data
        set(converterPatchedLocations, locationMap);
        set(converterLayoutDataInitial, layoutImport);
        set(converterLayoutData, data);
      },
  );

  async function load(importLayoutId: string) {
    await cleanupState(importLayoutId);
    await setLayoutImportIdCallback(importLayoutId);
  }
  async function unload(importLayoutId: string) {
    await cleanupState(importLayoutId);
  }

  return [load, unload];
}
