import * as React from 'react';
const RefreshIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RefreshIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M19,64c0,24.75,20.25,45,45,45v15s30-22.5,30-22.5l-30-22.5v15c-16.65,0-30-13.35-30-30h-15ZM34,26.5l30,22.5v-15c16.65,0,30,13.35,30,30h15c0-24.75-20.25-45-45-45V4s-30,22.5-30,22.5Z"
    />
  </svg>
);
export default RefreshIcon;
