import * as React from 'react';
const PolicyIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PolicyIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2Zm45.37,68.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z" />
    <path d="m21.63,33.31v37.9c0,7.8,6.05,18.29,13.14,22.87l29.23,18.13,29.15-18.08c7.17-4.62,13.22-15.12,13.22-22.92v-37.9l-42.37-18.82-42.37,18.82Z" />
  </svg>
);
export default PolicyIcon;
