import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { LogoHeader } from '../../components/layout/LogoHeader';
import { ActionBar } from '../../components/nav/ActionBar';
import HelpContainer from '../../helpContext/HelpContainer';
import SidebarHelpBreadcrumbs from '../../screens/help/SidebarHelpBreadcrumbs';
import { HeaderPublic } from '../HeaderPublic';

export type LegalProps = {
  children?: React.ReactNode;
};

export const Legal = ({ children }: LegalProps) => {
  const { t } = useTranslation('app-public');

  const [id] = useState<string>(_.uniqueId());

  return (
    <Container hasOverflowY col data-component="Legal">
      <HeaderPublic
        iconBefore={Icon.CircleArrowLeft}
        url={'/login'}
        message={t`Back`}
      />
      <ActionBar
        transparent
        className={classNames(
          'bg-app-panel-dark/70 backdrop-blur-lg backdrop-saturate-110 shadow-xl',
          'sticky top-10',
          'h-16',
        )}
      >
        <LogoHeader logoFull logoSize={'sm'} />
      </ActionBar>

      <Container overflow col className="px-10 xl:p-20">
        <SidebarHelpBreadcrumbs />
        <HelpContainer
          id={'legal/legal'}
          hasPadding
          fullpageMode
        ></HelpContainer>
        {children}
      </Container>
    </Container>
  );
};
