import {
  LimitSettings,
  OptimizationSettingsFragment,
  SimulationMetaFragment,
  SimulationStatus,
  TerminationSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { ResourcePolicyDefault } from './resourcePolicy.default';

export const getSimulationDefaults = (): Partial<SimulationMetaFragment> => ({
  id: 'new',
  status: SimulationStatus.DRAFT,
  resourcePolicy: ResourcePolicyDefault,
  optimizationSettings: optimisationSettingsDefault,
});

export const optimisationSettingsDefault: OptimizationSettingsFragment = {
  bayWidthConstraint: {
    disabled: false,
  },
  terminationSettings: {
    timeLimit: 3600 * 1, // 1 hour
  },
};

export const optimisationLimitSettings: LimitSettings = {
  maxMoves: 100,
};

export const optimisationTerminationSettingsDefault: TerminationSettingsFragment =
  {
    // 1 hour
    timeLimit: 3600 * 1,
    // 30%
    targetImprovement: 30,
    /** less than 1% in last 1000 steps  */
    minImprovementRate: {
      steps: 10000,
      minImprovementChange: 0.1,
    },
  };
