import { PickingPolicyLocationSelectionMode } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor, manipulateColor } from '../common/color.helper';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import { getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import { Alert } from '../components/alerts/Alerts';
import { StepListItemHelper } from '../components/helpers/StepListItemHelper';
import * as Icon from '../components/icons';
import TitleSection from '../components/layout/TitleSection';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from '../policyFilters/policyFilter.helper';
import { getOrderLineFilterConfigCommon } from '../simulation/store/pickingPolicy/pickingPolicy.default';
import {
  pickingPolicyCheckResult,
  pickingPolicyCheckResultLoadStatus,
  pickingPolicyRule,
  pickingPolicySelectedDetailsIdentityAtom,
  pickingPolicySelectedIdentity,
  pickingPolicySelectedOrderLineIntersection,
} from '../simulation/store/pickingPolicy/pickingPolicy.state';
import {
  pickingOrderLineSelectionTypes,
  pickingPicklistLineSelectionTypes,
} from '../simulation/store/pickingPolicy/pickingPolicy.types';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { collapsibleStateAtom } from '../store/collapsible.state';
import { sidebarStateByType } from '../store/sidebar.state';
import PickingPolicyPickingRule from './PickingPolicyPickingRule';
import PickingPolicyRuleStrategyFilter from './PickingPolicyRuleStrategyFilter';
import useLoadOrderLineFilterValues from './hooks/useLoadOrderLineFilterValues';

export type PickingPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;

  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
};

const PickingPolicyRule: React.FC<PickingPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const [rule, updateRule] = useRecoilState(pickingPolicyRule(props.ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    pickingPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-picking-editor'),
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const [selectedDetails, setSelectedDetails] = useRecoilState(
    pickingPolicySelectedDetailsIdentityAtom,
  );
  const checkResult = useRecoilValue(pickingPolicyCheckResult);
  const checkResultStatus = useRecoilValue(pickingPolicyCheckResultLoadStatus);
  const [filterIntersection, setFilterIntersection] = useRecoilState(
    pickingPolicySelectedOrderLineIntersection,
  );
  const [loadOrderLineValues, cancelLoadOrderLineValues] =
    useLoadOrderLineFilterValues();

  const formatter = useFormatter();

  function selectOrderLineFilterIntersection(id: string) {
    const shouldSelect = id !== selectedIdentity?.orderLineFilterId;
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        orderLineFilterId: id,
      });

      setSelectedDetails({
        ruleId: rule.id,
        showOrderLinesByRule: true,
      });
    }

    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? !shouldSelect
        : policyEditPanelState.isCollapsed,
      isHidden: !shouldSelect,
    });
  }

  function addOrderLineIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      orderLinesMatch: {
        anyOf: [...(rule.orderLinesMatch.anyOf ?? []), { id, allOf: [] }],
      },
    });
    selectOrderLineFilterIntersection(id);
  }

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  const removeField = (intersectionId: string, fieldId: string) => {
    const intersection = _.find(
      rule.orderLinesMatch.anyOf,
      fi => fi.id === intersectionId,
    );

    const newIntersection = {
      ...intersection,
      allOf: intersection.allOf.filter(filter => filter.type !== fieldId),
    };

    updateRule({
      ...rule,
      orderLinesMatch: {
        anyOf: _.map(rule.orderLinesMatch.anyOf, match =>
          match.id === intersectionId ? newIntersection : match,
        ),
      },
    });

    // refresh filters if rule is selected
    if (isActiveWhatToPick) {
      getOrderLineFilterConfigCommon().forEach(configItem => {
        const keyParts = getPolicyFilterDataKeysByEditorType(
          configItem.editorType,
        );
        keyParts.forEach(key => {
          const filterKey = getPolicyFilterKey(configItem.type, key);
          cancelLoadOrderLineValues(filterKey);
          loadOrderLineValues({
            field: configItem.type,
            filterKey,
            filterIntersection: newIntersection,
          });
        });
      });
    }
  };

  const removeIntersection = (intersectionId: string) => {
    updateRule({
      ...rule,
      orderLinesMatch: {
        anyOf: _.filter(
          rule.orderLinesMatch?.anyOf,
          fg => fg.id !== intersectionId,
        ),
      },
    });
  };

  const removePickingRule = (ruleId: string) => {
    updateRule({
      ...rule,
      pickingRules: _.filter(rule.pickingRules, rule => rule.id !== ruleId),
    });
  };

  const addPickingRule = () => {
    const pickingRuleId = nanoid();
    const locationFilterId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(rule?.pickingRules, r => r.title)),
      t`Picking Rule #`,
    );
    updateRule({
      ...rule,
      pickingRules: [
        ...rule.pickingRules,
        {
          id: pickingRuleId,
          title: ruleTitle,
          agentId: sim.resourcePolicy?.agents?.[0]?.id,
          uomTypes: [],
          locationsMatch: {
            anyOf: [
              {
                id: locationFilterId,
                allOf: [],
              },
            ],
          },
          locationSelectionMode: PickingPolicyLocationSelectionMode.ROUND_ROBIN,
        },
      ],
    });

    setSelectedIdentity({
      ruleId: rule.id,
      pickingRuleId,
      locationFilterId,
    });

    setSelectedDetails({
      ruleId: rule.id,
      pickingRuleId,
      showLocations: true,
    });

    setPolicyEditPanelState({
      ...policyEditPanelState,
      isCollapsed: !policyEditPanelState.isPinned
        ? false
        : policyEditPanelState.isCollapsed,
      isHidden: false,
    });

    setCollapsible({
      ...collapsible,
      [pickingRuleId]: { isCollapsed: false },
    });
  };

  function movePickingRule(from, to) {
    const newRules = [...rule.pickingRules];
    newRules.splice(to, 0, newRules.splice(from, 1)[0]);
    updateRule({
      ...rule,
      pickingRules: newRules,
    });
  }
  const isActiveRule = selectedIdentity?.ruleId === rule?.id;

  // [Picking Requirements]: A. Order Lines Policy
  const isActiveSomeOrderLinesFilter =
    isActiveRule && !_.isNil(selectedIdentity.orderLineFilterId);

  const isActiveWhatToPick =
    isActiveRule &&
    (isActiveSomeOrderLinesFilter ||
      _.includes(
        pickingOrderLineSelectionTypes,
        selectedIdentity.selectionTypeId,
      ));

  // [Picking Rules] (Where, Who, What,)
  const isActivePickingRule =
    isActiveRule &&
    (selectedIdentity?.locationFilterId ||
      _.includes(
        pickingPicklistLineSelectionTypes,
        selectedIdentity.selectionTypeId,
      ));

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive: isActiveRule,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
  };
  const hasIssues = false;

  const ruleCheckResult = checkResult?.policy.orderLineRules?.find(
    r => r.id === rule.id,
  );
  const isCheckLoading = checkResultStatus !== AsyncLoadStatus.Ok;

  const matchedOrderLinesCount =
    ruleCheckResult?.orderLineFilterMatch?.count ?? 0;

  const styleChevron = 'w-5 h-5 ltr:mr-2 rtl:ml-2';

  const hasIssue = matchedOrderLinesCount === 0;

  return (
    <>
      <RuleContainer
        dataComponent="PickingPolicyRule"
        {...containerParams}
        isDisabled={props.isDisabled}
        hasIssue={hasIssues}
        hasIcon
        hasColorMode={false}
        isActive={isActiveSomeOrderLinesFilter}
        hasOrder
        stickyTop={6.5}
        transparent
        // transparent={isActiveSomeOrderLinesFilter}
        color={getQualitativeColor(props.ruleId, 'policy')[0]}
        classNameInner="m-0"
      >
        {hasIssues && (
          <div className="m-1">
            <Alert
              messageHelper={t`WARNING`}
              message={t`Issues detected`}
              hasStatusAlert
            />
          </div>
        )}
        {/* ——————————————————————————————————————————————————————————————— */}
        {/* 1. What to Pick */}
        {/* ——————————————————————————————————————————————————————————————— */}
        <TitleSection
          key={`picking-policy-what-${props.ruleId}-${props.index}`}
          id={`picking-policy-what-${props.ruleId}-${props.index}`}
          isTransparent
          className={classNames(
            'px-2 pb-2 xl:pt-4',
            'text-lg xl:text-xl',
            'uppercase',
            'sticky top-20',
            'bg-sidebar-title/75 backdrop-blur-sm',
            { 'p-0 shadow-xl': isActiveSomeOrderLinesFilter },
          )}
          classNameInner={classNames(
            'p-0.5 xl:p-1',
            isActiveSomeOrderLinesFilter ? 'p-0.5 xl:p-1' : '',
          )}
          classNameAlign={`items-start`}
          classNameChevron={classNames(
            styleChevron,
            isActiveSomeOrderLinesFilter
              ? 'text-menu-text'
              : 'text-menu-active',
          )}
          colorInner={getQualitativeColor(props.ruleId, 'policy')}
          titleView
          collapsible
          inPanelView
          isPrimary
          summaryView
          hasScreenTitle
          hasAction
          classNameTitle={`flex-1`}
          // title={t`1. What to Pick?`}
          title={
            <StepListItemHelper
              stepCountId="1"
              stepCountSize="title"
              isPrimary
              className={classNames(
                isActiveSomeOrderLinesFilter
                  ? 'text-menu-text'
                  : 'text-menu-active',
              )}
              title={
                <div className="flex flex-col items-baseline">
                  <div>{t`What to Pick?`}</div>
                  <div className="text-xxs opacity-70 xl:mx-2 xl:text-xs">{t`(Order Lines)`}</div>
                </div>
              }
            />
          }
        >
          <Stat
            className={classNames(
              'p-2',
              // isActiveWhatToPick ? '!text-menu-text' : '',
            )}
            transparent
            hasNoPadding
            hasIssue={!isCheckLoading && (hasIssues || hasIssue)}
            title={t`Order Lines`}
            value={
              hasIssue ? t`None Matched` : formatInteger(matchedOrderLinesCount)
            }
            unitOfMeasure={hasIssue ? null : `OL`}
            inPanelMode
            inFilterStat
            isCheckLoading={isCheckLoading}
            onClick={() => {
              setSelectedDetails(
                isActiveSomeOrderLinesFilter
                  ? null
                  : {
                      ruleId: rule.id,
                      showOrderLinesByRule: true,
                    },
              );
            }}
          />
          <RuleSectionContainer
            // className={classNames('pt-1 2xl:pt-2', 'mt-0')}
            hasIssue={hasIssues}
            isSelected={isActiveWhatToPick}
            color={
              isActiveWhatToPick || isActiveSomeOrderLinesFilter
                ? manipulateColor(
                    getQualitativeColor(props.ruleId, 'policy')[0],
                    {
                      opacity: 0.85,
                      darkness: 0.2,
                    },
                  )
                : null
            }
          >
            <PolicyFilterGroupList
              isDisabled={props.isDisabled}
              filterSets={rule.orderLinesMatch.anyOf.map(filterGroup => ({
                id: filterGroup.id,
                allOf: [...filterGroup.allOf],
              }))}
              config={getOrderLineFilterConfigCommon(t, formatter)}
              selectedId={selectedIdentity?.orderLineFilterId}
              onAddGroup={addOrderLineIntersection}
              onDeleteField={removeField}
              onSelectGroup={selectOrderLineFilterIntersection}
              onDeleteGroup={removeIntersection}
              transparent
              // className="px-1"
              showAddFilter={false}
            />
          </RuleSectionContainer>

          <RuleSectionContainer
            className={classNames('p-1 2xl:p-2', 'mt-0')}
            hasIssue={hasIssues}
            isSelected={isActiveWhatToPick}
            color={
              isActiveWhatToPick || isActiveSomeOrderLinesFilter
                ? manipulateColor(
                    getQualitativeColor(props.ruleId, 'policy')[0],
                    {
                      opacity: 0.9,
                      lightness: 0.2,
                    },
                  )
                : null
            }
          >
            <PickingPolicyRuleStrategyFilter
              ruleId={rule.id}
              isDisabled={props.isDisabled}
            />
          </RuleSectionContainer>
        </TitleSection>

        {/* ——————————————————————————————————————————————————————————————— */}
        {/* 2. Picking Rules */}
        {/* ——————————————————————————————————————————————————————————————— */}
        <TitleSection
          key={`picking-policy-rules-${props.ruleId}-${props.index}`}
          id={`picking-policy-rules-${props.ruleId}-${props.index}`}
          collapsible
          inPanelView
          isPrimary
          summaryView
          hasScreenTitle
          isTransparent
          titleView
          // color={
          //   isActivePickingRule && getQualitativeColor(props.ruleId, 'policy')
          // }
          // colorInner={
          //   isActivePickingRule && getQualitativeColor(props.ruleId, 'policy')

          //   // manipulateColor(getQualitativeColor(props.ruleId, 'policy'), {
          //   //   opacity: 0.5,
          //   //   darkness: 5,
          //   //   saturation: 0.3,
          //   // })
          // }

          className={classNames(
            'px-2 pb-2 xl:pt-4',
            'text-lg xl:text-xl',
            'uppercase',
            'sticky top-20',
            'bg-sidebar-title/75 backdrop-blur-sm',
            { 'p-0 shadow-xl': isActivePickingRule },
          )}
          classNameInner={'bg-app-panel-dark/75 p-1 lg:p-2'}
          classNameAlign={'items-start'}
          classNameChevron={`${styleChevron} ${
            isActivePickingRule ? 'text-menu-active' : 'text-menu-active'
          }`}
          title={
            <StepListItemHelper
              stepCountId="2"
              stepCountSize="title"
              isPrimary
              className={classNames(
                isActivePickingRule ? 'text-menu-text' : 'text-menu-active',
              )}
              title={
                <div className="flex flex-col items-baseline">
                  <div>{t`Picking Rules?`}</div>
                  <div className="text-xxs opacity-70 xl:mx-2 xl:text-xs">{t`(Where, How, Who)`}</div>
                </div>
              }
            />
          }
        >
          {_.map(rule?.pickingRules, (pickingRule, index) => {
            return (
              <PickingPolicyPickingRule
                key={`picking-policy-picking-rule-${pickingRule.id}-${rule.id}`}
                ruleId={rule.id}
                pickingRuleId={pickingRule.id}
                index={index + 1}
                canDelete={!props.isDisabled}
                onDeleteClick={() => removePickingRule(pickingRule.id)}
                canMoveUp={index > 0}
                onMoveUp={() => movePickingRule(index, index - 1)}
                canMoveDown={index < rule.pickingRules.length - 1}
                onMoveDown={() => movePickingRule(index, index + 1)}
                canRename={!props.isDisabled}
                isDisabled={props.isDisabled}
              />
            );
          })}
          {_.isEmpty(rule?.pickingRules) ? (
            <InboxZero
              className="!block !p-0"
              selfCenter
              hasIcon
              message={t`No Rules found`}
            >
              {!props.isDisabled && (
                <Button
                  full
                  buttonSize="xs"
                  buttonType="primary"
                  label={t`Add Rule`}
                  className={classNames('flex-1', 'mt-2 p-1 py-1', 'rounded')}
                  onPress={addPickingRule}
                  hasIconAfter
                  buttonIcon={
                    <Icon.CirclePlus className={`h-6 w-6 fill-current`} />
                  }
                />
              )}
            </InboxZero>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!props.isDisabled && (
                <footer className="">
                  <Button
                    buttonSize="xs"
                    full
                    buttonType="secondary"
                    // label={t`Add rule` + ' ' + rule?.title}
                    label={t`Add Rule`}
                    className="mt-2 flex-1 rounded p-2 py-2"
                    onPress={addPickingRule}
                    hasIconAfter={
                      <Icon.CirclePlus className={`h-6 w-6 fill-current`} />
                    }
                  />
                </footer>
              )}
            </>
          )}
        </TitleSection>
      </RuleContainer>
    </>
  );
};
export default PickingPolicyRule;
