import { AppBillingCustomerFragment } from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  COUNTRY_CODE_DEFAULT,
  countriesCodesAll,
  countryNamesIntl,
} from '../assets/countries';
import { AsyncLoadStatus } from '../common/types';
import { FormErrorField } from '../components/FormError';
import LoadingIndicator from '../components/LoadingIndicator';
import { Button } from '../components/actions/Button';
import DropdownSelector from '../components/actions/DropdownSelector';
import { InputGroupList } from '../components/inputs/InputGroupList';
import InputText from '../components/inputs/InputText';
import { Container, ContainerForm } from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import TitleSection from '../components/layout/TitleSection';
import { ActionBar } from '../components/nav/ActionBar';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import useLoadCurrentCustomer from './hooks/useLoadCurrentCustomer';
import {
  appBillingCustomer,
  appBillingCustomerLoadStatus,
  appBillingPurchaseError,
  appBillingSubscriptionError,
} from './store/appBilling.state';
import { countyTaxIds } from './store/appBilling.tax';

export type AppBillingCustomerDetailsProps = {
  currencyCode: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onSubmit: (customer: AppBillingCustomerFragment) => void;
};

const AppBillingCustomerDetails: React.FC<
  AppBillingCustomerDetailsProps
> = props => {
  const { t } = useTranslation('app');

  const [purchaseError, setPurchaseError] = useRecoilState(
    appBillingPurchaseError,
  );
  const [subscriptionError, setSubscriptionError] = useRecoilState(
    appBillingSubscriptionError,
  );
  const stripeCustomer = useRecoilValue(appBillingCustomer);
  const [customer, setCustomer] = useState<AppBillingCustomerFragment>(null);
  const loadStatus = useRecoilValue(appBillingCustomerLoadStatus);
  const loadCustomer = useLoadCurrentCustomer();

  useEffect(() => {
    loadCustomer(props.currencyCode);
  }, [props.currencyCode]);

  useEffect(() => {
    setCustomer(stripeCustomer);
  }, [stripeCustomer]);

  const isLoading = props.isLoading || loadStatus === AsyncLoadStatus.Loading;
  const country = customer?.address?.country ?? COUNTRY_CODE_DEFAULT;
  const requiredErrorText = t`This field is required`;

  function drawCountryTax() {
    const taxes = countyTaxIds.filter(
      tax => tax.countryCode === customer?.address?.country,
    );
    if (_.isEmpty(taxes)) {
      return null;
    }
    return (
      <>
        <TitleSection
          titleView
          title={t`Tax ID(s) - Optional`}
          className={classNames('mb-1 text-xl top-10')}
        />
        <InputGroupList className={classNames('px-10 py-6 space-y-8')}>
          {taxes.map(tax => (
            <fieldset
              data-component="taxId"
              key={tax.taxTitle}
              className={classNames('flex')}
            >
              <div className={classNames('flex-1')}>
                <InputText
                  placeholder={tax.pattern}
                  label={tax.taxTitle}
                  name="tax"
                  value={
                    customer?.taxIds?.find(tid => tid.type === tax.taxType)
                      ?.value ?? ''
                  }
                  onChange={v =>
                    changeCustomer({
                      ...customer,
                      taxIds: [
                        ...(customer?.taxIds?.filter(
                          tid => tid.type !== tax.taxType,
                        ) ?? []),
                        {
                          type: tax.taxType,
                          value: v,
                        },
                      ],
                    })
                  }
                  isDisabled={props.isDisabled}
                />
              </div>
            </fieldset>
          ))}
        </InputGroupList>
      </>
    );
  }

  function changeCustomer(customer) {
    setCustomer(customer);
    setPurchaseError(null);
    setSubscriptionError(null);
  }

  const error = purchaseError ?? subscriptionError;
  const isCustomerValid =
    customer?.email &&
    customer?.name &&
    customer?.phone &&
    customer?.address?.country &&
    customer?.address?.city &&
    customer?.address?.line1 &&
    customer?.address?.postalCode;

  return (
    <ContainerForm className={classNames('flex-1 relative')}>
      {error && <FormErrorField message={error} />}

      {isLoading ? (
        <Container col overflow>
          <LoadingIndicator message={t`Please Wait`} selfCenter />
        </Container>
      ) : (
        <Container col hasOverflowY className="relative">
          <TitleSection
            titleView
            title={t`Billing Details`}
            className={classNames('mb-1 text-2xl')}
          />
          <InputGroupList className={classNames('px-10 py-6 space-y-8')}>
            <fieldset className={classNames('flex')}>
              <div className={classNames('flex-1')}>
                <InputText
                  placeholder={t`Email`}
                  label={t`Email`}
                  name="email"
                  value={customer?.email}
                  onChange={v => {
                    changeCustomer({
                      ...customer,
                      email: v,
                    });
                  }}
                  isDisabled={props.isDisabled}
                />
                {customer && _.isEmpty(customer?.email) && (
                  <FormErrorField message={requiredErrorText} />
                )}
              </div>
            </fieldset>
            <fieldset className={classNames('flex')}>
              <div className={classNames('flex-1')}>
                <InputText
                  placeholder={t`Phone`}
                  label={t`Phone`}
                  name="Phone"
                  value={customer?.phone ?? ''}
                  onChange={v =>
                    changeCustomer({
                      ...customer,
                      phone: v,
                    })
                  }
                  isDisabled={props.isDisabled}
                />
                {customer && _.isEmpty(customer?.phone) && (
                  <FormErrorField message={requiredErrorText} />
                )}
              </div>
            </fieldset>
            <fieldset className={classNames('flex')}>
              <div className={classNames('flex-1')}>
                <InputText
                  placeholder={t`Company Name`}
                  label={t`Company Name`}
                  name="Company Name"
                  value={customer?.name ?? ''}
                  onChange={v =>
                    changeCustomer({
                      ...customer,
                      name: v,
                    })
                  }
                  isDisabled={props.isDisabled}
                />
                {customer && _.isEmpty(customer?.name) && (
                  <FormErrorField message={requiredErrorText} />
                )}
              </div>
            </fieldset>
          </InputGroupList>
          <TitleSection
            titleView
            title={t`Billing Address`}
            className="mb-2 mt-8 top-10"
          />

          <InputGroupList className={classNames('px-10 py-6 space-y-8')}>
            <fieldset className={classNames('flex')}>
              <div className={classNames('flex-1')}>
                <InputText
                  placeholder={t`Address Line 1`}
                  label={t`Address Line 1`}
                  name="Address Line 1"
                  value={customer?.address?.line1}
                  onChange={v =>
                    changeCustomer({
                      ...customer,
                      address: { ...(customer?.address ?? {}), line1: v },
                    })
                  }
                  isDisabled={props.isDisabled}
                />
                {customer && _.isEmpty(customer?.address?.line1) && (
                  <FormErrorField message={requiredErrorText} />
                )}
              </div>
            </fieldset>
            <fieldset className={classNames('flex')}>
              <div className={classNames('flex-1')}>
                <InputText
                  placeholder={t`Address Line 2`}
                  label={t`Address Line 2`}
                  name="Address Line 2"
                  value={customer?.address?.line2}
                  onChange={v =>
                    changeCustomer({
                      ...customer,
                      address: { ...(customer?.address ?? {}), line2: v },
                    })
                  }
                  isDisabled={props.isDisabled}
                />
              </div>
            </fieldset>
            <fieldset className={classNames('flex sm:flex-col')}>
              <DropdownSelector
                formMode
                // light
                labelVertical
                DropAlignRight
                label={t`Country`}
                values={_.keys(countriesCodesAll)}
                renderValue={v => (_.isNil(v) ? '' : countryNamesIntl.of(v))}
                value={country}
                onChange={v =>
                  changeCustomer({
                    ...customer,
                    address: { ...(customer?.address ?? {}), country: v },
                  })
                }
                disabled={props.isDisabled}
                hasSearch
                filterValue={(v, search) =>
                  countryNamesIntl
                    .of(v)
                    ?.toLowerCase()
                    ?.indexOf(search.toLocaleLowerCase()) >= 0
                }
              />
            </fieldset>
            <fieldset className={classNames('flex sm:flex-col')}>
              <InputText
                placeholder={t`State`}
                label={t`State`}
                name="State"
                value={customer?.address?.state ?? ''}
                onChange={v =>
                  changeCustomer({
                    ...customer,
                    address: { ...(customer?.address ?? {}), state: v },
                  })
                }
                isDisabled={props.isDisabled}
              />
            </fieldset>
            <fieldset className={classNames('flex sm:flex-col')}>
              <InputText
                className="flex-1"
                placeholder={t`City`}
                label={t`City`}
                name="City"
                value={customer?.address?.city ?? ''}
                onChange={v =>
                  changeCustomer({
                    ...customer,
                    address: { ...(customer?.address ?? {}), city: v },
                  })
                }
                isDisabled={props.isDisabled}
              />
              {customer && _.isEmpty(customer?.address?.city) && (
                <FormErrorField message={requiredErrorText} />
              )}
            </fieldset>
            <fieldset className={classNames('flex sm:flex-col')}>
              <InputText
                className={classNames('flex-1')}
                placeholder={t`Postal Code`}
                label={t`Postal Code`}
                name="Postal code"
                value={customer?.address?.postalCode ?? ''}
                onChange={v =>
                  changeCustomer({
                    ...customer,
                    address: { ...(customer?.address ?? {}), postalCode: v },
                  })
                }
                isDisabled={props.isDisabled}
              />
              {customer && _.isEmpty(customer?.address?.postalCode) && (
                <FormErrorField message={requiredErrorText} />
              )}
            </fieldset>
          </InputGroupList>
          {drawCountryTax()}
        </Container>
      )}
      <ActionBar>
        <Spacer flexspace />
        <Button
          label={
            props.isLoading
              ? t`Processing...`
              : !isCustomerValid
                ? t`Please, fill all required fields`
                : t`Proceed to Payment details`
          }
          buttonType="purchase"
          onPress={() => {
            setPurchaseError(null);
            setSubscriptionError(null);
            props.onSubmit(customer);
          }}
          hasIconAfter
          buttonIcon={<IconArrowDir />}
          isDisabled={props.isDisabled || !isCustomerValid}
          isLoading={props.isLoading}
        />
      </ActionBar>
    </ContainerForm>
  );
};

export default AppBillingCustomerDetails;
