import { InferResult } from 'kysely';
import { ActivityFeedQueryBuilderParams } from './queryBuilder/feedEventsQueryBuilders';
import { getActivityFeedJobsBuilder } from './queryBuilder/feedJobsQueryBuilders';

export type FeedPicklists = InferResult<
  ReturnType<typeof getFeedPicklistQuery>
>;
export type FeedPicklist = FeedPicklists[number];

export function getFeedPicklistQuery(params: ActivityFeedQueryBuilderParams) {
  const builder = getActivityFeedJobsBuilder(params)
    .selectFrom('afj')
    .select([
      'jobKey',
      'jobId',
      'agentId',
      'agentType',
      'eventProcessType',
      'lines',
      'uomCount',
      'minEventTime',
      'minPreviousEndTime',
      'maxEventTime',
      'timeFramedEventsCount',
      'timeFramedUomCount',
    ]);
  return builder;
}
