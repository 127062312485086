import {
  BarCanvasProps,
  BarDatum,
  BarTooltipProps,
  ComputedDatum,
  ResponsiveBarCanvas,
} from '@nivo/bar';
import format from 'date-fns/format';
import _ from 'lodash';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  applyColorOpacity,
  getQualitativeColor,
} from '../../common/color.helper';
import { formatTimespan } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { resourcePolicyAgentsMap } from '../../simulation/store/resourcePolicy.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { AgentPerformanceKPI } from '../analyze.types';
import AgentPerformanceTooltip from './AgentPerformanceTooltip';
import {
  getAgentIdFromKey,
  getAgentPerformanceValueFormatted,
} from './agentPerformance.helper';

export type AgentPerformanceDatum = BarDatum & {
  date: number;
};

type ComputedAgentPerformanceDatum = ComputedDatum<AgentPerformanceDatum>;

export type AgentPerformanceChartProps = {
  data: AgentPerformanceDatum[];
  kpi: AgentPerformanceKPI;
  series: string[];
};

const AgentPerformanceChart: React.FC<AgentPerformanceChartProps> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const agents = useRecoilValue(resourcePolicyAgentsMap);
  const ms = useRecoilValue(warehouseMeasurementSystem);

  const canvasBarProps: Omit<
    BarCanvasProps<AgentPerformanceDatum>,
    'height' | 'width'
  > = {
    data: props.data,
    keys: props.series,
    indexBy: 'date',
    groupMode: 'grouped',
    colors: (datum: ComputedAgentPerformanceDatum) => {
      const agentKey = datum.id?.toString();
      const agentId = getAgentIdFromKey(agentKey);
      const color = getQualitativeColor(agentId, 'policy')[0];
      return agentId === agentKey ? color : applyColorOpacity(color, 0.6);
    },
  };

  return (
    <div className={`flex flex-1 items-center relative h-full w-full`}>
      <div
        className="w-full flex h-full flex-1"
        style={{ width: '100%', minWidth: '100%' }}
      >
        <ResponsiveBarCanvas
          {...canvasBarProps}
          enableLabel={false}
          margin={{ top: 30, right: 10, left: 80, bottom: 80 }}
          enableGridY={false}
          // gridYValues={shiftDurations}
          theme={{
            annotations: {
              link: {
                stroke: 'rgba(255, 255, 255,  0.0)',
                opacity: 0,
                strokeWidth: 0,
                outlineWidth: 0,
              },
              outline: {
                stroke: 'rgba(255, 255, 255,  0.0)',
                outlineColor: 'rgba(255, 255, 255,  0.0)',
                opacity: 0,
                strokeWidth: 0,
                outlineWidth: 0,
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 9,
                  fill: 'rgba(255, 255, 255,  0.9)',
                },
              },
            },
          }}
          valueFormat={(v: number) => {
            try {
              return _.isNumber(v) && !_.isNaN(v)
                ? formatTimespan(v * 1000)
                : '';
            } catch (ex) {
              console.error(ex);
            }
          }}
          axisBottom={{
            // tickValues: dateTicks,
            tickRotation: -90,
            format: v => format(new Date(v), 'MMM, dd (EE)'),
          }}
          axisLeft={{
            format: v => {
              return getAgentPerformanceValueFormatted(
                v,
                props.kpi,
                formatter,
                ms,
                t,
              ).fullString;
            },
          }}
          // axisRight={{
          //   tickValues: yTicks,
          //   format: v => formatDurationHoursShort(tMes, v * 1000),
          // }}
          // layers={chartLayers}
          tooltip={(datum: BarTooltipProps<AgentPerformanceDatum>) => (
            <ErrorBoundary fallbackRender={() => null}>
              <AgentPerformanceTooltip
                datum={datum}
                totalX={props.data.length}
                formatValue={v =>
                  getAgentPerformanceValueFormatted(
                    v,
                    props.kpi,
                    formatter,
                    ms,
                    t,
                  )
                }
                getSerieTitle={(v: string) =>
                  agents[getAgentIdFromKey(v)]?.title
                }
              />
            </ErrorBoundary>
          )}
        />
      </div>
    </div>
  );
};

export default AgentPerformanceChart;
