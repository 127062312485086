import {
  AllocationEnforcePickability,
  AllocationRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../../../components/layout/TitleSection';
import { PolicyCardItem } from '../../../../components/policies/PolicyCardItem';
import { getPickabilityOptions } from '../../../store/allocation/allocation.helper';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

const AllocationPolicyPickabilityModeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  function updateAllocationSettings(patch: Partial<AllocationRuleFragment>) {
    updateRule(current => ({
      ...current,
      ...patch,
    }));
  }
  const selected =
    rule?.pickabilitySettings?.enforcePickability ??
    AllocationEnforcePickability.NEVER;
  const options = getPickabilityOptions(t);

  return (
    <div data-component="AllocationPolicyPickabilityModeEditor">
      <TitleSection
        id={`policy-editor-picking-mode-section`}
        title={t`Location Pick Order`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`bg-app-panel-dark/60 flex flex-col p-1`}>
            <div
              className={`relative flex h-full min-h-[3rem] flex-1 flex-col`}
            >
              {_.map(options, (title, key: AllocationEnforcePickability) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-Mode-${key}`}
                    isActive={key === selected}
                    onClick={() =>
                      updateAllocationSettings({
                        pickabilitySettings: {
                          ...rule.pickabilitySettings,
                          enforcePickability: key,
                        },
                      })
                    }
                    // icon={
                    //   groupByType === 'ROUND_ROBIN'
                    //     ? Icon.SortPriorityRoundRobin
                    //     : groupByType === 'LOCATION_PRIORITY'
                    //     ? Icon.LocationPickingOrder
                    //     : Icon.SortPriorityAscending
                    // }
                    iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
                    title={title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default AllocationPolicyPickabilityModeEditor;
