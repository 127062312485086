import {
    GenerateAssignmentIssueSetDocument,
    GenerateAssignmentIssueSetMutation,
    GenerateAssignmentIssueSetMutationVariables,
    LoadAssignmentIssueSetDocument,
    LoadAssignmentIssueSetQuery,
    LoadAssignmentIssueSetQueryVariables
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
    simulationAssignmentIssues,
    simulationAssignmentIssuesLoadStatus
} from '../store/simulation.issues.state';

export type LoadAssignmentIssuesParams = {
  layoutId: string;
  assignmentId: string;
};

function useLoadAssignmentIssues() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Assignment Issues`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadAssignmentIssuesParams) => {
        if (_.isNil(params.assignmentId) || _.isNil(params.layoutId)) {
          return;
        }
        set(simulationAssignmentIssuesLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentIssuesParams) => {
        if (_.isNil(params.assignmentId) || _.isNil(params.layoutId)) {
          return;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationAssignmentIssuesLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const key = {
          layoutId: params.layoutId,
          assignmentId: params.assignmentId,
        };
        const query = secureClient.watchQuery<
          LoadAssignmentIssueSetQuery,
          LoadAssignmentIssueSetQueryVariables
        >({
          query: LoadAssignmentIssueSetDocument,
          variables: { key },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const issuesSet = data?.assignmentIssueSet;

            if (_.isNil(issuesSet)) {
              const runResult = await secureClient.mutate<
                GenerateAssignmentIssueSetMutation,
                GenerateAssignmentIssueSetMutationVariables
              >({
                mutation: GenerateAssignmentIssueSetDocument,
                variables: {
                  key,
                },
              });
              set(
                simulationAssignmentIssues,
                runResult?.data?.generateAssignmentIssueSet,
              );
              set(simulationAssignmentIssuesLoadStatus, AsyncLoadStatus.Ok);
            } else {
              set(simulationAssignmentIssues, issuesSet);
              set(simulationAssignmentIssuesLoadStatus, AsyncLoadStatus.Ok);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentIssuesParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAssignmentIssues;
