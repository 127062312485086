import React from 'react';
import { useRecoilValue } from 'recoil';
import { simulationRoutingTabKey } from '../../store/simulation.wizard.state';
import SimulationBayLocationLayer from './SimulationBayLocationLayer';
import WaypointPolicyBayLocationLayer from './WaypointPolicyBayLocationLayer';

const RoutingStepBayLocationLayer: React.FC = () => {
  const tab = useRecoilValue(simulationRoutingTabKey);
  switch (tab) {
    case 'tab-routing-policy':
      return <SimulationBayLocationLayer />;
    case 'tab-waypoint-policy':
      return <WaypointPolicyBayLocationLayer />;
    default:
      return null;
  }
};

export default RoutingStepBayLocationLayer;
