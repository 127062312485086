import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import * as Icon from '../../../components/icons';
import { Stat } from '../../../components/stats/Stat';
import {
  workforceAgentSchedule,
  workforceSelectedUnscheduled,
} from '../../store/workforce.state';

export type WorkforceAgentScheduleHeaderProps = {
  analyzeId: string;
};

const WorkforceAgentScheduleHeader: React.FC<
  WorkforceAgentScheduleHeaderProps
> = props => {
  const { t } = useTranslation('simulation');
  const schedule = useRecoilValue(workforceAgentSchedule(props.analyzeId));
  const [selectedUnscheduled, setSelectedUnscheduled] = useRecoilState(
    workforceSelectedUnscheduled,
  );
  const formatter = useFormatter();
  const scheduledTime = _.sumBy(
    schedule.waves,
    w => w.forecastWindow.secondsOperation * w.forecastWindow.agentAmount,
  );
  const unscheduledTime = _.sumBy(
    schedule.unscheduledWaves,
    w => w.remainingDuration,
  );

  const timeTotal = scheduledTime + unscheduledTime;
  const shareSchedule = formatter.formatShare(
    timeTotal > 0 ? scheduledTime / timeTotal : null,
  );
  const shareUnschedule = formatter.formatShare(
    timeTotal > 0 ? unscheduledTime / timeTotal : null,
  );

  if (!schedule) return null;

  return (
    <div className="grid grid-cols-2 gap-1 bg-app-panel-dark/60">
      <Stat
        // className={unscheduledTime > 0 ? 'bg-alerts-warning' : ''}
        value={
          unscheduledTime > 0
            ? formatter.formatTimeSpan(unscheduledTime).fullString
            : t`All Scheduled`
        }
        title={t`Unscheduled`}
        icon={unscheduledTime > 0 ? Icon.TriangleInfo : Icon.CircleOk}
        classIcon={unscheduledTime > 0 ? 'text-menu-active' : ''}
        hasIssue={unscheduledTime > 0}
        isActionable
        isSelected={selectedUnscheduled}
        onClick={() => setSelectedUnscheduled(!selectedUnscheduled)}
      />
      {/* {shareSchedule.value}
      {shareUnschedule.value} */}
      <Stat
        value={formatter.formatTimeSpan(scheduledTime).fullString}
        title={t`Scheduled`}
      />
    </div>
  );
};

export default WorkforceAgentScheduleHeader;
