import * as React from 'react';
const ObjectAilseIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAilseIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="4.48 67.51 4.48 89.9 20.25 67.51 4.48 67.51"
    />
    <polygon
      strokeWidth={0}
      points="4.48 5.35 4.48 27.74 20.25 5.35 4.48 5.35"
    />
    <polygon
      strokeWidth={0}
      points="21.53 94.36 21.54 72.11 5.86 94.36 21.53 94.36"
    />
    <polygon
      strokeWidth={0}
      points="4.48 36.43 4.48 58.82 20.25 36.43 4.48 36.43"
    />
    <polygon
      strokeWidth={0}
      points="21.53 32.21 21.54 9.96 5.86 32.21 21.53 32.21"
    />
    <polygon
      strokeWidth={0}
      points="21.53 63.28 21.54 41.03 5.86 63.28 21.53 63.28"
    />
    <path
      strokeWidth={0}
      d="M25.22,1.25H.91v96.04h24.31l9.73-29.44V29.66L25.22,1.25ZM22.28,33.65v.98h1.8v29.43h-1.8v.67h0v.98h1.8v29.43h-1.8v1.22H3.74v-1.22h-1.8v-29.43h1.8v-.42h0v-1.22h-1.8v-29.43h1.8v-.42h0v-1.22h-1.8V3.55h1.8v-1l18.54.02v.98h1.8v29.43h-1.8v.67h0Z"
    />
    <polygon
      strokeWidth={0}
      points="123.19 67.51 123.19 89.9 107.42 67.51 123.19 67.51"
    />
    <polygon
      strokeWidth={0}
      points="123.19 5.35 123.19 27.74 107.42 5.35 123.19 5.35"
    />
    <polygon
      strokeWidth={0}
      points="106.14 94.36 106.14 72.11 121.81 94.36 106.14 94.36"
    />
    <polygon
      strokeWidth={0}
      points="123.19 36.43 123.19 58.82 107.42 36.43 123.19 36.43"
    />
    <polygon
      strokeWidth={0}
      points="106.14 32.21 106.14 9.96 121.81 32.21 106.14 32.21"
    />
    <polygon
      strokeWidth={0}
      points="106.14 63.28 106.14 41.03 121.81 63.28 106.14 63.28"
    />
    <path
      strokeWidth={0}
      d="M92.72,29.66v38.19s9.73,29.44,9.73,29.44h24.31s0-96.04,0-96.04h-24.31s-9.73,28.41-9.73,28.41ZM105.4,33.65v-.67h-.9s-.9,0-.9,0V3.55s1.8,0,1.8,0v-.98l18.54-.02v1h1.8v29.43s-.9,0-.9,0h-.9s0,.1,0,.1v1.12h0v.42h1.8v29.43s-.9,0-.9,0h-.9s0,.1,0,.1v1.12h0v.42h1.8v29.43s-.9,0-.9,0h-.9s0,.1,0,.1v1.12h-18.54v-1.22h-.9s-.9,0-.9,0v-29.43s1.8,0,1.8,0v-.98h0v-.67h-.9s-.9,0-.9,0v-29.43s1.8,0,1.8,0v-.98h0Z"
    />
    <rect width={125.74} height={24.92} x={1} y={101.34} strokeWidth={0} />
  </svg>
);
export default ObjectAilseIcon;
