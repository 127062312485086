import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';

export type ContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  className?: string;
  message?: string;
  hideMessage?: string;
  showMessage?: string;
  hideIcon?: React.FC<HTMLAttributes<Element>>;
  showIcon?: React.FC<HTMLAttributes<Element>>;
  hasFilter?: string;
  loadingMessage?: string;
  maxHeight?: number;
  hasScroll?: boolean;
  hasPadding?: boolean;
  hidden?: boolean;
  borderTop?: boolean;
  collapsible?: boolean;
  transparent?: boolean;
};

const HelperContainer: React.FC<ContainerProps> = props => {
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const ShowIcon = props.showIcon;
  const HideIcon = props.hideIcon;

  return (
    <div
      data-component="HelperContainer"
      className={classNames(
        'group',
        { 'px-4': props.hasPadding },
        { hidden: props.hidden },
      )}
    >
      <div className="">
        {(props.collapsible && (
          <span className={`text-menu-text/60 text-start`}>
            {props.message}
          </span>
        )) || (
          <span className={`text-menu-text/60 text-start`}>
            {props.message}
          </span>
        )}
        {isClosed && (
          <Button
            label={props.showMessage}
            buttonSize={`helper`}
            buttonType={`helper`}
            hasIconBefore={ShowIcon && true}
            buttonIcon={
              <Icon.CircleHelp
                className={`w-4 h-3 fill-current ltr:mr-0.5 rtl:ml-0.5`}
              />
            }
            className={classNames(
              'mt-4 transition duration-100 ease-in-out delay-75',
              {
                'cursor-pointer': props.collapsible,
              },
              {
                closed: isClosed,
              },
              props.className,
            )}
            onPress={props.collapsible && (() => toggleOpen())}
          />
        )}
      </div>

      <Transition
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <section
          data-component="HelperContainerBody"
          className={classNames({ hidden: props.hidden }, props.className)}
        >
          {props.children}
          {!isClosed && props.collapsible && (
            <Button
              full
              label={props.hideMessage}
              buttonSize={`helper`}
              buttonType={`helper`}
              hasIconBefore={ShowIcon && true}
              buttonIcon={
                <Icon.MenuDotsHorizontal
                  className={`w-4 h-3 fill-current ltr:mr-0.5 rtl:ml-0.5`}
                />
              }
              className={classNames(
                'mt-4',
                {
                  'cursor-pointer transition duration-500 ease-in-out delay-1000 opacity-0 any-hover:group-hover:opacity-100':
                    props.collapsible,
                },
                {
                  closed: isClosed,
                },
                props.className,
              )}
              onPress={props.collapsible && (() => toggleOpen())}
            />
          )}
        </section>
      </Transition>
    </div>
  );
};

export default HelperContainer;
