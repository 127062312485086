import {
  AgentRole,
  AnalyzeResultOrderSummaryFragment,
  AssignmentSummaryFragment,
  ItemSetSummaryFragment,
  LayoutLocationSelectionDataFragment,
  LayoutSummaryFragment,
  LoadAssignmentSummaryDocument,
  LoadAssignmentSummaryQuery,
  LoadItemSetSummaryDocument,
  LoadItemSetSummaryQuery,
  LoadLayoutsSummaryDocument,
  LoadLayoutsSummaryQuery,
  LoadOrderSetsSummaryDocument,
  LoadOrderSetsSummaryQuery,
  MeasurementSystem,
  OrderSetSummaryFragment,
  PicklistEventWaypointFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { atom, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { PicklistRoute } from '../../layout/features/features.types';
import { stageStateById } from '../../layout/stage/stage.state';
import { viewerSelectedLocationIdAtom } from '../../layout/viewer/store/viewer.state';
import { sidebarStateByType } from '../../store/sidebar.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { analyzeJobDetailsSelectedRow } from './analyze.state';
import {
  optimisationPicklistRoutes,
  optimisationSelectedPicklistId,
  optimisationSelectedRoutePart,
} from './optimisation.state';
import { resourcePolicy } from './resourcePolicy.state';
import {
  routingPolicyRule,
  routingPolicySelectedIdentity,
} from './routingPolicy/routingPolicy.state';
import { createRouteForPicklist } from './simulation.helper';
import {
  simulationAnalyzeOrderDetails,
  simulationAnalyzeOrdersListData,
  simulationAnalyzePicklistEvents,
  simulationAssignmentId,
  simulationItemSetId,
  simulationLayoutId,
  simulationOrderSetId,
} from './simulation.state';
import { HeatmapFilter } from './simulation.types';

const getKey = (postfix: string) => `warebee-simulation-layout-${postfix}`;

const simulationLayoutSelectedOrderIdAtom = atom<string>({
  key: getKey('selected-order-id-atom'),
  default: null,
});

export const simulationLayoutSelectedOrderId = selector<string>({
  key: getKey('selected-order-id'),
  get: ({ get }) => get(simulationLayoutSelectedOrderIdAtom),
  set: ({ set, get }, value) => {
    const current = get(simulationLayoutSelectedOrderId);
    const routeSidebar = get(sidebarStateByType('sidebar-simulation-routes'));

    // Reset all order-related data in state
    // in analyze
    set(simulationLayoutSelectedPicklistId, null);
    set(simulationLayoutSelectedRoutePart, null);

    // and optimize
    set(optimisationPicklistRoutes, null);
    set(optimisationSelectedPicklistId, null);
    set(optimisationSelectedRoutePart, null);

    const valueToSet = current === value ? null : value;
    set(sidebarStateByType('sidebar-simulation-routes'), {
      ...routeSidebar,
      isCollapsed: routeSidebar.isPinned
        ? routeSidebar.isCollapsed
        : !valueToSet,
      isHidden: !valueToSet,
    });
    set(simulationLayoutSelectedOrderIdAtom, valueToSet);
  },
});

export const simulationLayoutSelectedPicklistId = atom<string>({
  key: getKey('selected-picklist-ids'),
  default: null,
});

export const simulationLayoutSelectedOrder =
  selector<AnalyzeResultOrderSummaryFragment>({
    key: getKey('selected-order'),
    get: ({ get }) => {
      const selected = get(simulationLayoutSelectedOrderIdAtom);
      const list = get(simulationAnalyzeOrdersListData);

      return _.find(list, order => order.id === selected);
    },
  });

export const simulationLayoutPicklistRoute = selectorFamily<
  PicklistRoute,
  string
>({
  key: getKey('picklist-routes'),
  get:
    (picklistId: string) =>
    ({ get }) => {
      const picklistWithEvents = get(
        simulationAnalyzePicklistEvents(picklistId),
      );
      if (_.isNil(picklistWithEvents)) {
        return null;
      }
      return createRouteForPicklist(picklistWithEvents);
    },
});

export const simulationLayoutSelectedRoutePart = atom<PicklistRoute>({
  key: getKey('picklist-route-part-selected'),
  default: null,
});

export const simulationLayoutSelectedRoutePartZoom = selector<PicklistRoute>({
  key: getKey('picklist-route-part-selected-zoom'),
  get: ({ get }) => get(simulationLayoutSelectedRoutePart),
  set: ({ get, set }, value: PicklistRoute) => {
    if (value) {
      const viewport =
        get(warehouseMeasurementSystem) === MeasurementSystem.METRIC
          ? 900
          : 900 * 0.4;
      const minX = _.min(value.waypoints.map(wp => wp.position.x));
      const maxX = _.max(value.waypoints.map(wp => wp.position.x));
      const minY = _.min(value.waypoints.map(wp => wp.position.y));
      const maxY = _.max(value.waypoints.map(wp => wp.position.y));

      set(stageStateById('simulation-area-view'), {
        ...get(stageStateById('simulation-area-view')),
        autoSizeId: nanoid(),
        contentBounds: [
          [minX - viewport, minY - viewport],
          [maxX + viewport, maxY + viewport],
        ],
      });
    }

    set(simulationLayoutSelectedRoutePart, value);
  },
});

export const simulationLayoutSelectedWaypointInner =
  atom<PicklistEventWaypointFragment>({
    key: getKey('selected-waypoint-inner'),
    default: null,
  });

export const simulationLayoutSelectedWaypointZoom =
  selector<PicklistEventWaypointFragment>({
    key: getKey('selected-waypoint-with-zoom'),
    get: ({ get }) => get(simulationLayoutSelectedWaypointInner),
    set: ({ get, set }, value: PicklistEventWaypointFragment) => {
      if (value) {
        const viewport =
          get(warehouseMeasurementSystem) === MeasurementSystem.METRIC
            ? 900
            : 900 * 0.4;
        set(stageStateById('simulation-area-view'), {
          ...get(stageStateById('simulation-area-view')),
          autoSizeId: value.id,
          contentBounds: [
            [value.position.x - viewport, value.position.y - viewport],
            [value.position.x + viewport, value.position.y + viewport],
          ],
        });
      }

      set(simulationLayoutSelectedWaypointInner, value);
    },
  });

export const simulationLayoutSelectedWaypoint =
  selector<PicklistEventWaypointFragment>({
    key: getKey('selected-waypoint'),
    get: ({ get }) => get(simulationLayoutSelectedWaypointInner),
    set: ({ get, set }, value: PicklistEventWaypointFragment) => {
      set(simulationLayoutSelectedWaypointInner, value);
    },
  });
export const simulationLayoutSelectedWaypointType = atom<'before' | 'after'>({
  key: getKey('selected-waypoint-type'),
  default: 'before',
});

export const simulationLayoutHoveredWaypoint =
  atom<PicklistEventWaypointFragment>({
    key: getKey('hovered-waypoint'),
    default: null,
  });

export const simulationLayoutShowCongestion = atom<boolean>({
  key: getKey('show-congestion-inner'),
  default: false,
});

export const simulationLayoutShowHeatmapLegend = atom<boolean>({
  key: getKey('show-heatmap-legend'),
  default: true,
});

export const simulationLayoutHeatmapFilters = atom<HeatmapFilter>({
  key: getKey('heatmap-filters'),
  default: {
    hiddenBuckets: {},
  },
});

export const simulationLayoutHeatmapFiltersHasAny = selector<boolean>({
  key: getKey('heatmap-filters-has any'),
  get: ({ get }) =>
    _.some(
      get(simulationLayoutHeatmapFilters)?.hiddenBuckets,
      (value, key) => value === true,
    ),
});

export const simulationAssignmentSummary = selector<AssignmentSummaryFragment>({
  key: getKey('assignment-summary'),
  get: async ({ get }) => {
    const assignmentId = get(simulationAssignmentId);
    if (!assignmentId) return null;
    const response = await secureClient.query<LoadAssignmentSummaryQuery>({
      query: LoadAssignmentSummaryDocument,
      variables: {
        assignmentId,
      },
    });

    return response.data.assignment.summary;
  },
});

export const simulationLayoutSummary = selector<LayoutSummaryFragment>({
  key: getKey('layout-summary'),
  get: async ({ get }) => {
    const layoutId = get(simulationLayoutId);
    if (!layoutId) return null;
    const response = await secureClient.query<LoadLayoutsSummaryQuery>({
      query: LoadLayoutsSummaryDocument,
      variables: {
        layoutId: layoutId,
      },
    });

    return response.data.layout.summary;
  },
});

export const simulationOrderSetSummary = selector<OrderSetSummaryFragment>({
  key: getKey('orderSet-summary'),
  get: async ({ get }) => {
    const orderSetId = get(simulationOrderSetId);
    if (!orderSetId) return null;
    const response = await secureClient.query<LoadOrderSetsSummaryQuery>({
      query: LoadOrderSetsSummaryDocument,
      variables: {
        orderSetId,
      },
    });

    return response.data.orderSet.summary;
  },
});

export const simulationItemSetSummary = selector<ItemSetSummaryFragment>({
  key: getKey('item-set-summary'),
  get: async ({ get }) => {
    const itemSetId = get(simulationItemSetId);
    if (!itemSetId) return null;
    const response = await secureClient.query<LoadItemSetSummaryQuery>({
      query: LoadItemSetSummaryDocument,
      variables: {
        itemSetId,
      },
    });

    return response.data.itemSet?.summary;
  },
});

export const simulationLayoutSelectedLocationZoom =
  selector<LayoutLocationSelectionDataFragment>({
    key: getKey('selected-location-with-zoom'),
    get: ({ get }) => null,
    set: ({ get, set }, location: LayoutLocationSelectionDataFragment) => {
      if (location) {
        const stage = get(stageStateById('simulation-area-view'));
        const stageBase = get(stageStateById('viewer-area-view'));
        const cX = location.shape?.coordinates?.[0]?.[0]?.[0];
        const cY = location.shape?.coordinates?.[0]?.[0]?.[1];
        const w = stage.size[0] / (stage.scale * 2);
        const h = stage.size[1] / (stage.scale * 2);

        set(stageStateById('simulation-area-view'), {
          ...stage,
          autoSizeId: nanoid(),
          contentBounds: [
            [cX - w, cY - h],
            [cX + w, cY + h],
          ],
        });

        set(stageStateById('viewer-area-view'), {
          ...stageBase,
          autoSizeId: nanoid(),
          contentBounds: [
            [cX - w, cY - h],
            [cX + w, cY + h],
          ],
        });
      }

      set(viewerSelectedLocationIdAtom, location.locationId);
    },
  });

/**
 *  Agent context that applied to layout
 */
export const simulationLayoutActiveAgentId = selector<string>({
  key: getKey('active-agent-id'),
  get: ({ get }) => {
    // from routing policy
    const { ruleId } = get(routingPolicySelectedIdentity) ?? {};
    if (!_.isNil(ruleId)) {
      return _.head(get(routingPolicyRule(ruleId))?.agentIds) ?? 'any';
    }

    //from selected picklist
    const picklistId = get(simulationLayoutSelectedPicklistId);
    if (!_.isNil(picklistId)) {
      const order = get(simulationAnalyzeOrderDetails);
      const agentIds = _.find(
        order?.picklists,
        p => p.picklistId === picklistId,
      )?.agents;
      return _.head(agentIds);
    }
    //from reassign
    const isMoveSelected = !_.isNil(get(analyzeJobDetailsSelectedRow));
    if (isMoveSelected) {
      const policy = get(resourcePolicy);
      const agent = _.find(policy.agents, a =>
        _.some(a.roles, r => r.roleId === AgentRole.REASSIGN && !r.disabled),
      );
      return agent?.id;
    }
  },
});
