import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getHeatmapBucketByValue } from '../../common/heatmap.helper';
import useFormatter from '../../common/useFormatter';
import InboxZero from '../../components/InboxZero';
import { Container } from '../../components/layout/ContainerFlex';
import { StatListHeader } from '../../components/stats/StatListHeader';
import { StatListItem } from '../../components/stats/StatListItem';
import { WarehouseNavigator } from '../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../components/WarehouseNavigatorItem';
import PanelContainer from '../../containers/PanelContainer';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { viewerSelectedLocationIdAtom } from '../../layout/viewer/store/viewer.state';
import { getAnalyzeProductMetricDescriptorsMap } from '../../metrics/analyzeProduct/analyzeProductMetric.default';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { AnalyzeProductDataRow } from '../../metrics/analyzeProduct/analyzeProductMetric.types';
import {
  analyzeCompareBuckets,
  analyzeCompareHeatmapRangeDescriptor,
  analyzeCompareLocationByLevel,
} from '../analyzeCompare.heatmap.state';

/**
 *  Location details sidebar for Analyze Compare
 *
 */

const AnalyzeCompareLocationSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const locationId = useRecoilValue(viewerSelectedLocationIdAtom);
  const locationMapFirst = useRecoilValue(
    analyzeCompareLocationByLevel('first'),
  );
  const locationMapSecond = useRecoilValue(
    analyzeCompareLocationByLevel('second'),
  );
  const selectedMetric = useRecoilValue(analyzeProductMetricSelected);
  const rangeDescriptor = useRecoilValue(analyzeCompareHeatmapRangeDescriptor);
  const buckets = useRecoilValue(analyzeCompareBuckets);

  const metricDescriptors = getAnalyzeProductMetricDescriptorsMap(t, formatter);

  if (_.isNil(locationId)) {
    return <InboxZero selfCenter message={t`No Location Selected`} />;
  }

  const locationItemsFirst = locationMapFirst?.[locationId];
  const locationItemsSecond = locationMapSecond?.[locationId];

  function getMetrics(locationItem: AnalyzeProductDataRow) {
    return _.map(metricDescriptors, descriptor => {
      const valueRaw = _.get(locationItem, descriptor.path);
      if (_.isNil(valueRaw)) return null;

      const valueString =
        _.isFunction(descriptor.format) && _.isNumber(valueRaw)
          ? descriptor.format(valueRaw)
          : valueRaw?.toString();
      let color: string = null;
      let background: string = null;

      if (descriptor.type === selectedMetric) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bucket = getHeatmapBucketByValue({
          data: locationItem,
          metricDescriptor: descriptor,
          rangeDescriptor,
          buckets: _.keyBy(buckets, b => b.id),
        });
        background = bucket?.color;
        color = bucket?.textColor;
      }

      return (
        <StatListItem
          key={`item-${locationItem.itemId}-${descriptor.type}`}
          title={descriptor.title}
          value={valueString}
          valueStyle={{
            color,
            background,
          }}
        />
      );
    });
  }

  function getItems(items: AnalyzeProductDataRow[]) {
    return _(items)
      .sortBy(item => -item?.sku)
      .map(row => {
        const p = row;
        return (
          <>
            <StatListItem
              key={`item-${p.itemId}`}
              title={p.sku}
              labelValueType={'itemId'}
              labelValueFilters={{
                consignee: p.consignee,
                sku: p.sku,
              }}
            />
            {getMetrics(row)}
          </>
        );
      })
      .value();
  }

  return (
    <Container col hasOverflowY>
      <WarehouseNavigator>
        {/* <WarehouseNavigatorItem name={t`Level`} value={locationId} active /> */}
        <WarehouseNavigatorItem name={t`Location`} value={locationId} active />
      </WarehouseNavigator>
      {!_.isEmpty(locationItemsFirst) && (
        <PanelContainer
          id="panel-analyze-compare-items-first"
          title={t`First Analyse`}
          collapsible
          hasPadding
        >
          <StatListHeader title={t`Item`} />
          {getItems(locationItemsFirst)}
        </PanelContainer>
      )}
      {!_.isEmpty(locationItemsSecond) && (
        <PanelContainer
          id="panel-analyze-compare-items-second"
          title={t`Second Analyse`}
          collapsible
          hasPadding
        >
          <StatListHeader title={t`Item`} />
          {getItems(locationItemsSecond)}
        </PanelContainer>
      )}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'analyze-compare-location',
    title: t`Location`,
    loadingMessage: t`Loading Analyse Compare`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps} width={96}>
      <AnalyzeCompareLocationSidebar />
    </SidebarContainer>
  );
};
