import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventViewAs } from '../../common/types';
import ButtonSwitchMulti from './ButtonSwitchMulti';

export type SwitchProps = {
  selected: EventViewAs;
  onChange: (value: EventViewAs) => void;
  availableOptions: EventViewAs[];
};

const SwitchEvents: React.FC<SwitchProps> = ({
  selected,
  onChange,
  availableOptions,
}) => {
  const { t } = useTranslation('app');

  const viewAs: { key: EventViewAs; label: string }[] = [
    {
      key: 'picking',
      label: t('Picking'),
    },
    {
      key: 'replenishment',
      label: t('Replenishment'),
    },
    {
      key: 'reassign',
      label: t('Reassign'),
    },
  ];

  const filteredViewAsT = viewAs.filter(({ key }) =>
    availableOptions.includes(key),
  );

  return (
    <ButtonSwitchMulti
      autoSize
      className="mx-1 xl:mx-3 w-full flex-1 truncate"
      classNameLabel="text-xs xl:text-sm py-1 px-2"
      buttonType="header"
      options={filteredViewAsT}
      selectedIndex={filteredViewAsT.findIndex(o => o.key === selected)}
      onClick={index => onChange(filteredViewAsT[index].key)}
    />
  );
};
export default SwitchEvents;
