import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  Filter,
  FilterBase,
  FilterIntersection,
  FilterUnion,
} from './filter.model';

export enum SimulationItemFilterType {
  // Item match filters
  CONSIGNEE = 'CONSIGNEE',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  // Item range filters
  NET_WEIGHT = 'NET_WEIGHT',
  WIDTH = 'WIDTH',
  LENGTH = 'LENGTH',
  HEIGHT = 'HEIGHT',
  VOLUME = 'VOLUME',
  // order count filters
  PERCENT_RANK = 'PERCENT_RANK',
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  TOTAL_ORDER_LINE_COUNT = 'TOTAL_ORDER_LINE_COUNT',
  TOTAL_QUANTITY_ORDERED = 'TOTAL_QUANTITY_ORDERED',
  DAILY_ORDER_LINE_COUNT_MAX = 'DAILY_ORDER_LINE_COUNT_MAX',
  DAILY_ORDER_LINE_COUNT_AVG = 'DAILY_ORDER_LINE_COUNT_AVG',
  WEEKLY_ORDER_LINE_COUNT_MAX = 'WEEKLY_ORDER_LINE_COUNT_MAX',
  WEEKLY_ORDER_LINE_COUNT_AVG = 'WEEKLY_ORDER_LINE_COUNT_AVG',
  WEEKLY_DAYS_WITH_ORDERS_MAX = 'WEEKLY_DAYS_WITH_ORDERS_MAX',
  WEEKLY_DAYS_WITH_ORDERS_AVG = 'WEEKLY_DAYS_WITH_ORDERS_AVG',
  // assignment filters
  ASSIGNMENT_COUNT = 'ASSIGNMENT_COUNT',
}

registerEnumType(SimulationItemFilterType, {
  name: 'SimulationItemFilterType',
});

@InputAndObjectType()
export class SimulationItemFilter
  extends FilterBase
  implements Filter<SimulationItemFilterType>
{
  @Field(() => SimulationItemFilterType)
  type: SimulationItemFilterType;
}

@InputAndObjectType()
export class SimulationItemFilterIntersection
  implements FilterIntersection<SimulationItemFilter>
{
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field(() => [SimulationItemFilter])
  allOf: SimulationItemFilter[];
}

@InputAndObjectType()
export class SimulationItemFilterUnion
  implements FilterUnion<SimulationItemFilter>
{
  @Field(() => [SimulationItemFilterIntersection])
  anyOf: SimulationItemFilterIntersection[];
}
