import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import OrderSetDashboardCard from '../OrderSetDashboardCard';
import { orderSetDocument } from '../store/orderSet.state';

const OrderSetStatsPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [document, setDocument] = useRecoilState(orderSetDocument);

  return (
    <ContainerScroll className={`p-2`}>
      <ScreenTitle isSticky title={t`Stats`} subtitle={t`Order Set`} />
      <OrderSetDashboardCard summary={document?.summary} itemLimit={200} />
    </ContainerScroll>
  );
};

export default OrderSetStatsPanel;
