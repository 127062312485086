import * as React from 'react';
const CongestionMap4Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CongestionMap4Icon"
    {...props}
  >
    <g opacity={0.4}>
      <circle cx={33.01} cy={33.01} r={28.72} strokeWidth={0} />
    </g>
    <g opacity={0.6}>
      <circle cx={94.99} cy={33.01} r={28.72} strokeWidth={0} />
    </g>
    <circle cx={33.01} cy={94.99} r={28.72} strokeWidth={0} />
    <g opacity={0.7}>
      <circle cx={94.99} cy={94.99} r={28.72} strokeWidth={0} />
    </g>
  </svg>
);
export default CongestionMap4Icon;
