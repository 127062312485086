import { Serie } from '@nivo/line';
import {
  OptimizationObjectiveType,
  OptimizationRunStatus,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatTimespan } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Button } from '../../../components/actions/Button';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import { ActionBar } from '../../../components/nav/ActionBar';
import { Stat } from '../../../components/stats/Stat';
import { StatGroup } from '../../../components/stats/StatGroup';
import { loadBalancingPolicyDocument } from '../../../loadBalancingPolicy/loadBalancingPolicy.state';
import useStopOptimizationRun from '../../../warehouse/hooks/useStopOptimizationRun';
import { optimisationResult } from '../../store/optimisation.state';
import { simulationLayoutSummary } from '../../store/simulation.layout.state';
import { simulationCurrent } from '../../store/simulation.state';
import { getDimensionColor } from '../../store/workforce.helper';
import LineChart from '../LineChart';
import OptimizationSettingsStats from './OptimisationSettingsInfo';
import OptimisationStatsInfo from './OptimisationStatsInfo';

const OptimisationProgressStatus: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const simulation = useRecoilValue(simulationCurrent);
  const opt = useRecoilValue(optimisationResult);
  const layoutSummary = useRecoilValue(simulationLayoutSummary);
  const stopOptimization = useStopOptimizationRun();
  const [isLoading, setIsLoading] = useState(false);
  const [showStopOptions, setShowStopOptions] = useState<boolean>(false);
  const [showAdvancedStats, setShowAdvancedStats] = useState<boolean>(false);
  const balancingPolicy = useRecoilValue(loadBalancingPolicyDocument);

  const balancingPolicyRules = _.keyBy(balancingPolicy?.rules, r => r.id);

  const optStatus = opt?.status;
  const isStopping = optStatus === OptimizationRunStatus.STOPPING;
  const inProgress =
    optStatus === OptimizationRunStatus.CREATED ||
    optStatus === OptimizationRunStatus.IN_PROGRESS ||
    isStopping;

  const canStop =
    optStatus === OptimizationRunStatus.CREATED ||
    optStatus === OptimizationRunStatus.IN_PROGRESS;

  // console.log('opt?.progress', opt?.progress.toPrecision(2));
  // const optimisationProgress = Math.floor(Math.max(opt?.progress ?? 0, 1));
  const optimisationProgress =
    opt && typeof opt.progress === 'number'
      ? opt.progress < 1
        ? parseFloat(opt.progress.toFixed(1))
        : Math.floor(opt.progress)
      : 0;

  const showStats = !_.isNil(opt);

  const progressMessage =
    !isStopping && opt?.progress > 0
      ? t`Optimising`
      : isStopping
        ? t`Finalising Optimisation`
        : t`Preparing Data`;

  const statusRaw = opt?.status;
  const titleStatus =
    statusRaw === OptimizationRunStatus.COMPLETED
      ? t(`Optimised`)
      : statusRaw === OptimizationRunStatus.CREATED
        ? t(`Preparing Data`)
        : statusRaw === OptimizationRunStatus.FAILED
          ? t(`Error`)
          : statusRaw === OptimizationRunStatus.IN_PROGRESS
            ? t(`Optimising`)
            : statusRaw === OptimizationRunStatus.STOPPING
              ? t(`Finalising Optimisation`)
              : '—';

  const lastStep = _.last(opt?.progressDetails);
  const totalImprovementRaw = lastStep?.currentTotalImprovement ?? 0;
  const remainingTimeRaw = lastStep?.remainingComputeTime ?? 0;
  const elapsedTimeRaw = lastStep?.elapsedComputeTime ?? 0;
  const currentMoveCount = lastStep?.currentMoveCount ?? 0;
  const currentStepCount = lastStep?.step ?? 0;

  const totalImprovement = formatter.formatShare(totalImprovementRaw / 100);

  const objectivesTitles: Record<OptimizationObjectiveType, string> = {
    COST: t`Predicted Cost Improvement`,
    LOAD_BALANCING: t`Est. Balancing`,
  };

  function getTitle(
    type: OptimizationObjectiveType,
    ruleId: string,
    weight: number,
  ) {
    if (_.isNil(ruleId)) {
      return objectivesTitles[type];
    }
    return t('{{objType}} - {{ ruleTitle}}', {
      objType: objectivesTitles[type],
      ruleTitle: balancingPolicyRules[ruleId]?.title,
      ruleWeight: formatter.formatShare(weight).fullString,
    });
  }

  const costStats = _.filter(
    lastStep?.details?.improvement?.objectives,
    target => target.type === OptimizationObjectiveType.COST,
  );

  const loadBalancingStats = _.filter(
    lastStep?.details?.improvement?.objectives,
    target => target.type === OptimizationObjectiveType.LOAD_BALANCING,
  );

  const chartData: Partial<Record<OptimizationObjectiveType, Serie>> = _.keyBy(
    [
      {
        id: OptimizationObjectiveType.COST,
        type: OptimizationObjectiveType.COST as string,
        title: objectivesTitles[OptimizationObjectiveType.COST],
        data: [],
      },
    ],
    v => v.id,
  ) as Partial<Record<OptimizationObjectiveType, Serie>>;

  const isChartDataLoading =
    _.isEmpty(chartData) || _.isEmpty(_.values(chartData));

  _.forEach(opt?.progressDetails, stepDetails => {
    // legacy support
    if (_.isEmpty(stepDetails.details?.improvement)) {
      (
        chartData[OptimizationObjectiveType.COST].data as {
          x: number;
          y: number;
        }[]
      ).push({
        x: stepDetails.elapsedComputeTime,
        y: stepDetails.currentTotalImprovement,
      });
      // chartData[OptimizationObjectiveType.COST].data.push({
      //   x: stepDetails.elapsedComputeTime,
      //   y: stepDetails.currentTotalImprovement,
      // });
    }
    _.forEach(stepDetails.details?.improvement?.objectives, obj => {
      const key = obj.id ?? obj.type;
      // create serie if required
      if (_.isNil(chartData[key])) {
        chartData[key] = {
          id: key,
          type: obj.type,
          title: getTitle(obj.type, obj.id, obj.objectiveWeight),
          data: [],
        };
      }

      chartData[key].data.push({
        x: stepDetails.elapsedComputeTime,
        y: obj.improvement * 100,
      });
    });
  });

  const optimiseTime = formatTimespan(
    opt?.completedAt ?? 0,
    opt?.startedAt ?? 0,
  );

  return (
    <Container col hasOverflowY>
      {/* // <ContainerScroll className="pb-20"> */}
      <ScreenTitle
        subtitle={t`Optimisation Status`}
        title={t`Optimisation`}
        helpNavTo={'simulation/optimise/simulation-optimise'}
        icon={Icon.SimulationOptimize}
      />
      {isLoading && (
        <LoadingIndicator message={t`Loading Data`} absolute selfCenter />
      )}

      {showStats && (
        <>
          <StatGroup
            title={t`Improvement Stats`}
            helpNavTo={'simulation/optimise/simulation-optimise'}
          >
            {/* <Stat
              title={t('Predicted Improvement', {})}
              value={totalImprovement.value}
              unitOfMeasure={totalImprovement.unit}
              inPanelMode
            /> */}
            {/* Render COST Stats */}

            {isLoading || !elapsedTimeRaw ? (
              <div className="flex">
                <LoadingIndicator
                  message={t`Loading stats`}
                  // absolute
                  selfCenter
                  oneLiner
                  // className="w-full"
                />
              </div>
            ) : (
              _.map(costStats, target => (
                <Stat
                  key={target.id}
                  legendColor={getDimensionColor(target.id ?? target.type)}
                  title={getTitle(
                    target.type,
                    target.id,
                    target.objectiveWeight,
                  )}
                  titleAlt={getTitle(
                    target.type,
                    target.id,
                    target.objectiveWeight,
                  )}
                  value={target.improvement * 100}
                  hasHelper
                  isPrimaryResult
                  unitOfMeasure={'%'}
                  inPanelMode
                  isFullWidth
                  isPreview
                />
              ))
            )}
          </StatGroup>

          <StatGroup
            title={t`Improvement Graph`}
            helpNavTo={'simulation/optimise/simulation-optimise'}
            titleAction={
              <DropdownSelector
                classNameLabel="text-sm ltr:mr-1 rtl:ml-1"
                onClick={e => {
                  e.stopPropagation();
                }}
                DropAlignRight
                buttonTransparent
                vertical
                panelMode
                value={'...'}
                values={[
                  showAdvancedStats
                    ? t`Hide Options...`
                    : t`Show Support Options`,
                ]}
                onChange={async (option, e) => {
                  setShowAdvancedStats(!showAdvancedStats);
                }}
              />
            }
          >
            <div
              className={classNames(
                'flex flex-1 flex-col items-center',
                'relative',
                'bg-app-panel-dark/60',
                'h-fill w-full',
              )}
            >
              {isLoading && (
                <LoadingIndicator
                  message={t`Loading Data`}
                  absolute
                  selfCenter
                />
              )}
              <label
                aria-label="Title"
                className="text-xxs w-full flex-1 uppercase xl:text-sm"
              >
                <div className="flex items-center pt-4">
                  <span className="flex-1 ltr:ml-4 rtl:mr-4">{t`Improvement by time`}</span>
                </div>
              </label>

              {inProgress && (
                <div className="flex w-full">
                  <LoadingIndicator
                    className="flex-1"
                    progress={optimisationProgress}
                    message={titleStatus}
                    selfCenter
                    transparent
                    oneLiner
                    circleSize={64}
                    circleStroke={4}
                  />
                  <DropdownSelector
                    classNameLabel="text-sm"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    DropAlignRight
                    buttonTransparent
                    vertical
                    panelMode
                    value={'...'}
                    values={[
                      showStopOptions
                        ? t`Hide Options...`
                        : t`Show Run Options`,
                    ]}
                    onChange={async (option, e) => {
                      setShowStopOptions(!showStopOptions);
                    }}
                  />
                </div>
              )}

              {showStopOptions && canStop && (
                <div
                  className={classNames(
                    'p-4',
                    'w-full',
                    'bg-issue-transparent bg-app-panel-dark/75',
                    'transform transition-all',
                  )}
                >
                  <ActionBar className="my-2">
                    <Button
                      full
                      className="truncate rounded text-xs"
                      label={t`Stop Optimisation Run`}
                      buttonType="delete"
                      buttonSize="xs"
                      isDisabled={isLoading}
                      onPress={async () => {
                        setIsLoading(true);
                        await stopOptimization(opt.id);
                        setIsLoading(false);
                      }}
                      hasIconAfter={
                        <Icon.CircleX
                          className={classNames('h-6 w-6 fill-current')}
                        />
                      }
                    />
                  </ActionBar>
                </div>
              )}

              <div
                className={classNames(
                  'relative',
                  'px-1 py-0.5 md:px-2 lg:px-3 xl:px-4 ltr:px-0.5 ltr:xl:px-1 rtl:px-0.5 rtl:xl:px-1',
                  'm-0.5',
                )}
                style={{
                  height: 320,
                  width: '100%',
                  minWidth: '100%',
                }}
              >
                {isChartDataLoading || !elapsedTimeRaw ? (
                  <LoadingIndicator
                    message={t`Loading Chart Data`}
                    absolute
                    selfCenter
                  />
                ) : (
                  <div className="h-full px-0.5 pb-4 pt-1 lg:px-2">
                    <LineChart data={_.values(chartData)} />
                  </div>
                )}
              </div>

              {inProgress && (
                <footer className="h-auto w-full px-0.5 pb-4 pt-2 lg:px-2">
                  <div
                    className={classNames(
                      'text-menu-active flex-1 px-2 text-xs xl:px-4',
                    )}
                  >
                    <span
                      className={classNames(
                        'text-xxs text-menu-text/60 uppercase ltr:mr-2 rtl:ml-2',
                      )}
                    >{`Elapsed Time`}</span>
                    <span>
                      {elapsedTimeRaw
                        ? formatTimespan(elapsedTimeRaw * 1000)
                        : t`Calculating...`}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      'text-menu-active flex-1 px-2 text-xs xl:px-4',
                    )}
                  >
                    <span
                      className={classNames(
                        'text-xxs text-menu-text/60 uppercase ltr:mr-2 rtl:ml-2',
                      )}
                    >{`Est. Remaining Time`}</span>
                    <span>
                      {remainingTimeRaw
                        ? formatTimespan(remainingTimeRaw * 1000)
                        : t`Calculating...`}
                    </span>
                  </div>
                </footer>
              )}
            </div>
          </StatGroup>
          {loadBalancingStats.length > 0 && (
            <StatGroup
              title={t`Workload Balancing`}
              helpNavTo={'simulation/optimise/simulation-optimise'}
            >
              {/* Render LOAD_BALANCING stats */}
              <div
                className={`flex ${
                  loadBalancingStats.length > 3
                    ? 'flex-col space-y-1'
                    : 'flex-col space-y-1 xl:flex-row xl:space-x-1 xl:space-y-0'
                }`}
              >
                {/* {_.map(lastStep?.details?.improvement?.objectives, target => ( */}
                {_.map(loadBalancingStats, target => (
                  <Stat
                    key={target.id}
                    legendColor={getDimensionColor(target.id ?? target.type)}
                    title={getTitle(
                      target.type,
                      target.id,
                      target.objectiveWeight,
                    )}
                    titleClassName="text-xxs"
                    titleAlt={getTitle(
                      target.type,
                      target.id,
                      target.objectiveWeight,
                    )}
                    value={target.improvement * 100}
                    hasHelper
                    isPrimaryResult
                    unitOfMeasure={'%'}
                    inPanelMode
                    isFullWidth
                    isPreview
                  />
                ))}
              </div>
            </StatGroup>
          )}

          {showAdvancedStats && (
            <>
              <OptimisationStatsInfo opt={opt} layoutSummary={layoutSummary} />
              <OptimizationSettingsStats
                optimizationSettings={simulation?.optimizationSettings}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default OptimisationProgressStatus;
