import { DatasetObjectQueryResponseFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';

export type ProductCategoryDescriptorExport = {
  tag: string;
  fromCmlPercentile?: number;
  toCmlPercentile?: number;
};

export const getCategoryString = (
  cumulativePercentile: number,
  categories: ProductCategoryDescriptorExport[],
) => {
  if (_.isNil(cumulativePercentile)) {
    return _.find(
      categories,
      cat => _.isNil(cat.fromCmlPercentile) && _.isNil(cat.toCmlPercentile),
    );
  }

  const v = Math.min(cumulativePercentile, 1);
  if (v === 1) {
    _.find(categories, cat => cat.toCmlPercentile === 1);
  }
  const targetCategory = _.values(categories).find(
    c => v >= c.fromCmlPercentile && v < c.toCmlPercentile,
  );
  return (
    targetCategory ??
    _.find(
      categories,
      cat => _.isNil(cat.fromCmlPercentile) && _.isNil(cat.toCmlPercentile),
    )
  );
};

export function postProcessDatasetQuery<T>(
  response: DatasetObjectQueryResponseFragment,
): T[] {
  const dateFields = new Set(
    _(response?.columns)
      .filter(c => c.type === 'timestamp')
      .map(c => c.name)
      .value(),
  );
  return _.map(response.result, row => {
    return _.reduce(
      _.entries(row),
      (acc, [key, value]) => {
        return {
          ...acc,
          [_.camelCase(key)]: dateFields.has(key)
            ? new Date(value as string)
            : value,
        };
      },
      {} as T,
    );
  });
}
