import {
  SkipLimitPageSpec,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { HeatmapAggregationFn } from '../../../common/heatmap.types';
import {
  AssignmentHqField,
  AssignmentHqQueryBuilderParams,
  getAssignmentHqQueryBuilder,
} from './assignmentQueryBuilder';

export type AssignmentHeatmapDataRow = {
  locationId: string;
  skuKey: string;
  consignee: string;
  sku: string;
  dimensionValue: number | string | bigint;
};
export type AssignmentHeatmapDataColumn = keyof AssignmentHeatmapDataRow;

export type AssignmentHeatmapDataSortBy = {
  direction?: SortDirection;
  field: AssignmentHeatmapDataColumn;
};

export type AssignmentHeatmapDataQueryParams =
  AssignmentHqQueryBuilderParams & {
    groupBy: AssignmentHqField;
    locationLevel?: number;
    bayId?: string;
    aggregationFn: HeatmapAggregationFn;
    page?: SkipLimitPageSpec;
    sortBy?: AssignmentHeatmapDataSortBy;
  };

export function getAssignmentHeatmapDataQuery(
  params: AssignmentHeatmapDataQueryParams,
) {
  let query = getAssignmentHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) =>
      _.compact([
        'locationId',
        'skuKey',
        'consignee',
        'sku',
        params.aggregationFn === 'max'
          ? fn.max(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'min'
          ? fn.min(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'avg'
          ? fn.avg(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'sum'
          ? fn.sum(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'count'
          ? fn.count(params.groupBy).as('dimensionValue')
          : null,
        params.aggregationFn === 'count-locations'
          ? fn.count<number>(params.groupBy).distinct().as('dimensionValue')
          : null,
      ]),
    )
    .groupBy(['locationId', 'skuKey', 'consignee', 'sku'])
    .orderBy(
      params.sortBy?.field ?? 'dimensionValue',
      params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
    )
    .$if(!_.isNil(params.page), db => db.offset(params.page.skip ?? 0))
    .$if(!_.isNil(params.page), db => db.limit(params.page.limit ?? 100))
    .$if(!_.isNil(params.locationLevel), db =>
      db.where('locationLevel', '=', params.locationLevel),
    )
    .$if(!_.isNil(params.bayId), db =>
      db.where('locationBayId', '=', params.bayId as any),
    );
  return query;
}

export function getAssignmentHeatmapTotalQuery(
  params: AssignmentHeatmapDataQueryParams,
) {
  let query = getAssignmentHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) => [fn.countAll<number>().as('totalCount')])
    .groupBy(['locationId', 'skuKey']);

  if (!_.isNil(params.locationLevel)) {
    query = query.where('locationLevel', '=', params.locationLevel);
  }
  if (!_.isNil(params.bayId)) {
    query = query.where('locationBayId', '=', params.bayId as any);
  }
  return query;
}
