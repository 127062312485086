import * as React from 'react';
const PolicyStackingDoNotStackIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyStackingDoNotStackIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M62.95,39.92c.37-.28.89-.28,1.27,0l2.63,1.93-1.53,1.54-1.73-1.27-1.44,1.06-1.54-1.54,2.35-1.72ZM62.51,107.5l-14.32-10.51v-15.82l14.32,10.51v15.81ZM63.59,89.81l-14.66-10.75,3.44-2.52-1.81-1.33-2.01-1.48,1.54-1.54,12.43,9.13v-15.81l-3.31-2.43,1.53-1.54,2.85,2.09,3.13-2.3,1.54,1.54-3.6,2.64v15.81l12.71-9.34,1.54,1.54-2.3,1.69-1.81,1.33,3.43,2.52-14.64,10.75ZM78.97,96.99l-14.31,10.51v-15.81l14.31-10.51v15.81ZM76.08,57.11l-1.54-1.54,3.69-2.71-5.09-3.74,1.54-1.54s6.13,4.55,6.18,4.62c.04.05.08.08.11.13.09.16.15.34.15.52v9.28l-2.15-2.15v-5.01l-2.89,2.12ZM67.58,51.65l17.26,17.26-3.74,3.75-17.26-17.26-17.26,17.26-3.75-3.75,17.26-17.26-17.26-17.26,3.75-3.75,17.26,17.26,17.26-17.26,3.74,3.75-17.26,17.26ZM52.91,55.79l-1.54,1.54-3.18-2.33v5.51l-2.15,2.15v-9.78c0-.19.06-.36.15-.52.03-.05.08-.08.12-.13.05-.07,6.47-4.83,6.47-4.83l1.53,1.54-5.38,3.95,3.98,2.92Z"
    />
  </svg>
);
export default PolicyStackingDoNotStackIcon;
