import * as React from 'react';
const HeatMapLayoutBackIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HeatMapLayoutBackIcon"
    {...props}
  >
    <path strokeWidth={0} d="M123.46,123.57V35.85H35.74v87.72h87.72Z" />
    <rect
      width={27.72}
      height={87.72}
      x={3.46}
      y={35.85}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={87.72}
      height={27.72}
      x={35.74}
      y={3.57}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={27.72}
      height={27.72}
      x={3.46}
      y={3.57}
      strokeWidth={0}
      opacity={0.6}
    />
  </svg>
);
export default HeatMapLayoutBackIcon;
