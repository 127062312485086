import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type CardProps = {
  children?: React.ReactNode;
  className?: string;
  transparent?: boolean;
  isActionable?: boolean;
  label?: string;
};

export const Card = ({
  children,
  className,
  transparent,
  isActionable,
  label,
}: CardProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <section
      data-component="Card"
      data-label={`card${'-' + label || ''}`}
      aria-label={`card${'-' + label || ''}`}
      id={id}
      className={classNames(
        'flex flex-col overflow-hidden',
        'min-h-72',
        {
          'isActionable transition duration-400 transform cursor-pointer motion-reduce:transform-none any-hover:hover:scale-103':
            isActionable,
        },
        {
          'p-4 bg-menu-active text-menu-active-text': !transparent,
        },
        className,
      )}
    >
      {children}
    </section>
  );
};
