import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputCheckbox from '../components/inputs/InputCheckbox';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { resourcePolicyAgentsMap } from '../simulation/store/resourcePolicy.state';
import {
  routingPolicy,
  routingPolicyRule,
  routingPolicySelectedIdentity,
} from '../simulation/store/routingPolicy/routingPolicy.state';

const RoutingPolicyAgentEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const agents = useRecoilValue(resourcePolicyAgentsMap);
  const selectedIdentity = useRecoilValue(routingPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    routingPolicyRule(selectedIdentity?.ruleId),
  );
  const policy = useRecoilValue(routingPolicy);

  if (!selectedIdentity || !rule) return null;

  const current = rule?.agentIds ?? [];
  const selectedAgents = _.keyBy(rule?.agentIds);

  // Agents already selected in other rules
  const disabledAgents = _(policy?.rules)
    .filter(r => r.id !== selectedIdentity.ruleId)
    .map(r => r.agentIds)
    .flatten()
    .keyBy();

  const onSelectValue = (value: string) => {
    updateRule({
      ...rule,
      agentIds: _.has(selectedAgents, value)
        ? _.filter(current, v => v !== value)
        : [...current, value],
    });
  };

  return (
    <div data-component="RoutingPolicyAgentEditor">
      <ScreenTitle subtitle={t`Agent(s) Routing Restriction`} />
      <TitleSection
        id={`routing-policy-editor-agents`}
        title={t`Agents`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {_.map(_.values(agents), agent => (
                <InputCheckbox
                  isHoverable
                  className="ltr:pl-4 rtl:pr-4"
                  key={`chk-${agent.id}`}
                  isSelected={_.has(selectedAgents, agent.id)}
                  isDisabled={_.has(disabledAgents, agent.id)}
                  value={agent.id}
                  onChange={() => onSelectValue(agent.id)}
                >
                  {agent.title}
                </InputCheckbox>
              ))}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default RoutingPolicyAgentEditor;
