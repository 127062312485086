import { ApolloQueryResult } from '@apollo/client';
import {
  LoadCurrentUserSettingsDocument,
  LoadCurrentUserSettingsQuery,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../store/warehouse.state';

function useLoadUserSettings() {
  const { t } = useTranslation('app');
  const errorUpdate = t`Can not update user profile`;

  const clear = useRecoilCallback(({ reset }) => async () => {
    reset(userSettingsLoadStatus);
    reset(userSettings);
  });

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(userSettingsLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadUserSettings = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        let response: ApolloQueryResult<LoadCurrentUserSettingsQuery>;
        try {
          response = await secureClient.query<LoadCurrentUserSettingsQuery>({
            query: LoadCurrentUserSettingsDocument,
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: errorUpdate,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(userSettingsLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: errorUpdate,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(userSettingsLoadStatus, AsyncLoadStatus.Error);
          return;
        }
        set(userSettings, response.data.currentUserSettings);
        set(userSettingsLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function load() {
    await initLoading();
    await loadUserSettings();
  }
  return [load, clear];
}

export default useLoadUserSettings;
