import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';

const StepAreaSetup = () => {
  const { t } = useTranslation('designer');

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Importing Layout`}
        title={t`Areas Setup`}
        hasStepCounter={'2.'}
      />
      <HelpContainer id={'designer/02-areas'} hasPadding />
    </PanelBody>
  );
};
export default StepAreaSetup;
