import * as React from 'react';
const PolicyPickingAgentsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyPickingAgentsIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M65.77,34.2c4.07,0,7.36-3.29,7.36-7.36s-3.29-7.36-7.36-7.36-7.36,3.29-7.36,7.36,3.29,7.36,7.36,7.36Z"
    />
    <path
      strokeWidth={0}
      d="M87.36,58.26c-1.44-.65-10.92-5.36-10.92-5.36,0,0-3.36-7.17-5.1-11.09-1.64-3.69-2.82-4.65-5.14-4.65h-3.59c-1.88,0-2.57.69-3.72,1.34l-9.71,8.26c-.85.49-1.39,1.22-1.72,2.26l-2.08,13.98c-.3,2.23.88,3.18,2.21,3.45,1.08.23,2.7-.32,3.03-1.88.26-1.29,2.23-12.73,2.23-12.73l6.34-4.87c-1.05,4.86-2.11,10.17-2.46,11.98-.6,3.09.93,5.43,2.15,7.27,1.11,1.67,16.79,21.95,18.68,24.41,1.9,2.46,3.62,3.68,6.25,2.21,2.21-1.24,1.84-4.05.62-6.14-1.22-2.08-17.04-23.3-17.04-23.3l2.43-10.49s1.68,2.4,2.54,3.75c.42.63.85.89,1.98,1.51,1.34.73,7.82,3.68,9.78,4.57,1.75.78,3.92,1.08,4.79-.47.88-1.54-.23-3.39-1.54-3.99Z"
    />
    <path
      strokeWidth={0}
      d="M53.01,78.94s-6.97,4.74-9.77,6.2c-2.12,1.1-4.14,2.73-2.93,5.36,1.36,2.94,2.81,2.24,4.7,1.53,1.73-.64,11.93-5.51,13.34-7.28.94-1.2,1.45-2.2,1.86-3.2.26-.67,1.27-3.42,1.92-5.21l-6.03-8.29-3.09,10.89Z"
    />
  </svg>
);
export default PolicyPickingAgentsIcon;
