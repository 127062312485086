import * as React from 'react';
const DesignerStartingPointGridIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerStartingPointGridIcon"
    {...props}
  >
    <circle cx={30.27} cy={55.53} r={10.67} strokeWidth={0} />
    <circle cx={72.82} cy={14.57} r={10.67} strokeWidth={0} />
    <circle cx={115.38} cy={14.57} r={10.67} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M30.27,3.9c-5.89,0-10.67,4.78-10.67,10.67s4.78,10.67,10.67,10.67,10.67-4.78,10.67-10.67-4.78-10.67-10.67-10.67Z"
    />
    <circle cx={115.38} cy={96.5} r={10.67} strokeWidth={0} />
    <circle cx={72.82} cy={96.5} r={10.67} strokeWidth={0} />
    <circle cx={115.38} cy={55.53} r={10.67} strokeWidth={0} />
    <circle cx={72.82} cy={55.53} r={10.67} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M30.27,44.86c-5.89,0-10.67,4.78-10.67,10.67s4.78,10.67,10.67,10.67,10.67-4.78,10.67-10.67-4.78-10.67-10.67-10.67Z"
    />
    <path
      strokeWidth={0}
      d="M117.5,53.33l.02-40.91h0v-.05l-89.39.11v60.76c1.46,0,2.89.13,4.29.36v-15.87l38.27-.05-.02,36.63-16.95.04c.27,1.39.42,2.89.46,4.35h63.32v-45.36ZM70.67,53.39l-38.26.05h0V16.76l38.27-.05-.02,36.68ZM113.21,94.31l-38.25.09.02-36.73,38.25-.05-.02,36.68ZM113.21,53.34l-38.25.05.02-36.68,38.25-.05-.02,36.68Z"
      opacity={0.5}
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M25.92,76.01c-13.77,0-24.95,11.12-24.95,24.89s11.18,24.95,24.95,24.95,24.89-11.18,24.89-24.95-11.12-24.89-24.89-24.89ZM31.52,115v-13.24s-13.23,13.23-13.23,13.23l-4.81-4.82,13.23-13.23-13.23-.02,6.8-6.8,18.04.02v18.05s-6.79,6.8-6.79,6.8Z"
    />
  </svg>
);
export default DesignerStartingPointGridIcon;
