import * as React from 'react';
const CircleWaypoint3RightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleWaypoint3RightIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4C30.92,4,4,30.91,4,64s26.92,60,60,60,60-26.91,60-60S97.08,4,64,4ZM13.04,67.55v-8.4h23.42c6.2,0,12.05-2.43,16.48-6.86,4.42-4.42,6.86-10.27,6.86-16.48v-7.36s-11.55,11.54-11.55,11.54v-11.87s15.75-15.74,15.75-15.74l15.75,15.74v11.87s-11.55-11.54-11.55-11.54v7.36c0,8.45-3.31,16.41-9.32,22.42-6.01,6.01-13.97,9.32-22.42,9.32H13.04ZM64,114.27l-15.75-15.74v-11.87s11.55,11.54,11.55,11.54v-7.36c0-6.2-2.44-12.05-6.86-16.48-1.8-1.8-3.84-3.27-6.05-4.38,3.17-.97,6.18-2.39,8.98-4.21,1.05.82,2.05,1.69,3.01,2.65,6.01,6.01,9.32,13.97,9.32,22.42v7.36s11.55-11.54,11.55-11.54v11.87s-15.75,15.74-15.75,15.74ZM99.22,79.1h-11.87s11.54-11.55,11.54-11.55h-30.57s-4.66-4.2-4.66-4.2l4.66-4.2h30.57s-11.54-11.55-11.54-11.55h11.87s15.74,15.75,15.74,15.75l-15.74,15.75Z"
    />
  </svg>
);
export default CircleWaypoint3RightIcon;
