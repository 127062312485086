import _ from 'lodash';
import React, { useState } from 'react';

export type ButtonGroupProps = {
  children?: React.ReactNode;
  className?: string;
  horizontal?: boolean;
};

export const ButtonGroup = ({
  children,
  className,
  horizontal,
}: ButtonGroupProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      className={`flex ${
        horizontal ? 'flex-col' : ''
      } items-center mt-1 w-full ${className || ''}`}
    >
      {children}
    </div>
  );
};
