import React from 'react';

import { AriaCheckboxProps } from '@react-types/checkbox';
import classNames from 'classnames';
import { useCheckbox, useId } from 'react-aria';
import { useToggleState } from 'react-stately';
import useSharedRef from '../../utils/use-shared-ref';

export type ButtonToggleProps = {
  className?: string;
  classNameLabel?: string;
  title?: string;
  label?: string;
} & AriaCheckboxProps;

const ButtonToggle = React.forwardRef<HTMLInputElement, ButtonToggleProps>(
  (
    {
      children,
      className,
      classNameLabel,
      title,
      label,
      ...props
    }: ButtonToggleProps,
    ref,
  ) => {
    const id = useId();

    const labelId = `toggle-but-label-${id}`;

    const sharedRef = useSharedRef(ref);
    const state = useToggleState(props);
    const { inputProps } = useCheckbox(
      { ...props, 'aria-labelledby': labelId },
      state,
      sharedRef,
    );

    return (
      <label
        data-component="ButtonToggle"
        data-label={`button-toggle-${title}`}
        aria-label={`button-toggle-${label ? label : title}`}
        id={labelId}
        className={classNames(
          'flex items-center',
          'cursor-pointer',
          classNameLabel,
        )}
        title={title}
      >
        <input type="checkbox" className="hidden" {...inputProps} />
        <div className={classNames(className)} aria-hidden="true">
          {children}
        </div>
      </label>
    );
  },
);

export default ButtonToggle;
