import _ from 'lodash';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import useLoadAnalyzedOrderDetails from '../hooks/useLoadAnalyzedOrderDetails';
import useLoadAnalyzedOrdersCompare from '../hooks/useLoadAnalyzedOrdersCompare';
import useLoadOptimizedOrderDetails from '../hooks/useLoadOptimizedOrderDetails';
import {
  optimisationAnalyzeOrdersListData,
  optimisationResult,
} from '../store/optimisation.state';
import { simulationLayoutSelectedOrderId } from '../store/simulation.layout.state';
import {
  simulationAnalyzeOrdersListData,
  simulationAnalyzeOrdersListState,
  simulationAnalyzeOrdersListStatus,
} from '../store/simulation.state';
import AnalyzedOrdersList from './AnalyzedOrdersList';

export type SimulationOptimiseOrderPanelProps = {
  optimisedAnalyzeId: string;
  baseAnalyzeId: string;
};

const SimulationOptimiseOrdersComparePanel: React.FC<
  SimulationOptimiseOrderPanelProps
> = props => {
  const optimisation = useRecoilValue(optimisationResult);
  const { optimisedAnalyzeId, baseAnalyzeId } = props;
  const [selectedOrderId, setSelectedOrderId] = useRecoilState(
    simulationLayoutSelectedOrderId,
  );
  const ordersOpt = useRecoilValue(optimisationAnalyzeOrdersListData);
  const ordersAnl = useRecoilValue(simulationAnalyzeOrdersListData);
  const orderListLoadStatus = useRecoilValue(simulationAnalyzeOrdersListStatus);
  const [orderListState, setOrderListState] = useRecoilState(
    simulationAnalyzeOrdersListState,
  );
  const [loadOrders, cancelLoadOrders] = useLoadAnalyzedOrdersCompare();

  const [loadOptOrderDetails, cancelLoadOptOrderDetails] =
    useLoadOptimizedOrderDetails();
  const [loadAnlOrderDetails, cancelLoadAnlOrderDetails] =
    useLoadAnalyzedOrderDetails();

  async function onOrderSelect(orderId: string) {
    setSelectedOrderId(orderId);
    cancelLoadOptOrderDetails();
    cancelLoadAnlOrderDetails();

    if (selectedOrderId !== orderId) {
      loadOptOrderDetails({
        analyzeId: optimisedAnalyzeId,
        orderId,
      });

      loadAnlOrderDetails({
        analyzeId: baseAnalyzeId,
        orderId,
      });
    }
  }

  useEffect(() => {
    if (_.isNil(baseAnalyzeId) || _.isNil(optimisedAnalyzeId)) return null;
    setSelectedOrderId(null);
    cancelLoadOrders();
    loadOrders({
      optimisedAnalyzeId,
      baseAnalyzeId,
      isAppend: false,
      sortBy: orderListState.sortBy,
      filter: orderListState.searchValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optimisedAnalyzeId,
    baseAnalyzeId,
    orderListState.sortBy,
    orderListState.searchValues,
  ]);

  const loadedCount = ordersOpt?.length ?? 0;

  function loadNext() {
    loadOrders({
      optimisedAnalyzeId,
      baseAnalyzeId,
      isAppend: true,
      skip: loadedCount,
      sortBy: orderListState.sortBy,
      filter: orderListState.searchValues,
    });
  }

  return (
    <AnalyzedOrdersList
      summary={optimisation?.analyzeResult}
      orders={ordersOpt}
      orderCompare={ordersAnl}
      state={orderListState}
      onLoadNext={loadNext}
      selectedOrderId={selectedOrderId}
      onSelect={onOrderSelect}
      selectedKPI={orderListState.sortBy}
      isLoading={orderListLoadStatus === AsyncLoadStatus.Loading}
      onStateChanged={setOrderListState}
    />
  );
};

export default SimulationOptimiseOrdersComparePanel;
