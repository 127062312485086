import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import FeedEventsPanel from '../panels/FeedEventsPanel';
import FeedPicklistPanel from '../panels/FeedPicklistPanel';
import { feedSelectedAgentId } from '../store/feed.state';

const FeedEventsSidebar: React.FC = () => {
  const { t } = useTranslation('feed');
  const device = useRecoilValue(feedSelectedAgentId);
  return (
    <Container col data-component="FeedEventsSidebar" className="relative">
      <ScreenTitle
        isSticky
        title={t`Latest activities`}
        subtitle={t`Warehouse`}
      />
      <Container col className="relative">
        <Suspense
          fallback={
            <LoadingIndicator
              absolute
              message={t`Loading Activity`}
              selfCenter
            />
          }
        >
          {_.isNil(device) ? <FeedEventsPanel /> : <FeedPicklistPanel />}
        </Suspense>
      </Container>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('feed');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-feed-info-event',
    title: t`Activity events`,
    size: 'xs',
  };

  return (
    <SidebarContainer {...containerProps}>
      <FeedEventsSidebar />
    </SidebarContainer>
  );
};
