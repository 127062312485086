import { BayFeatureFragment } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import BayLayer from '../../layout/viewer/layers/BayLayer';
import { viewerShowLocations } from '../../layout/viewer/store/viewer.state';
import { simulationComplianceTabKey } from '../store/simulation.wizard.state';
import { getBayWeightColor } from '../store/weightCompliance.helper';
import { weightComplianceBay } from '../store/weightCompliance.state';

const SimulationComplianceBayLayer: React.FC = () => {
  const showLocations = useRecoilValue(viewerShowLocations);
  const tab = useRecoilValue(simulationComplianceTabKey);
  const weightComplianceByBayLoadable =
    useRecoilValueLoadable(weightComplianceBay);

  let getColor: (bay: BayFeatureFragment) => [string, string] | null;

  if (
    weightComplianceByBayLoadable.state === 'hasValue' &&
    !showLocations &&
    tab === 'tab-compliance-weight'
  ) {
    const bayMap = weightComplianceByBayLoadable.contents;
    getColor = (bay: BayFeatureFragment) => getBayWeightColor(bayMap?.[bay.id]);
  }

  return <BayLayer getColor={getColor} />;
};

export default SimulationComplianceBayLayer;
