import { Vector } from '@warebee/frontend/data-access-api-graphql';

export type RoutingPolicySelectionIdentity = {
  ruleId: string;
  isRoutingAgentSelected?: boolean;
  isRoutingConfigurationSelected?: boolean;
};

export const directionDescriptorIds = [
  'direction-any',
  'direction-north',
  'direction-east',
  'direction-south',
  'direction-west',
  'direction-none',
] as const;

export type DirectionDescriptorId = (typeof directionDescriptorIds)[number];

export type DirectionDescriptor = {
  id: DirectionDescriptorId;
  value: Vector;
  title: string;
  description?: string;
};
