import { FetchResult } from '@apollo/client';
import {
  LoadAppBillingSubscriptionDocument,
  LoadAppBillingSubscriptionQuery,
  LoadAppBillingSubscriptionQueryVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { billingClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import {
  appBillingSubscription,
  appBillingSubscriptionError,
  appBillingSubscriptionLoadStatus,
} from '../store/appBilling.state';

function useLoadSubscription(): [
  (string) => Promise<void>,
  () => Promise<void>,
] {
  const { t } = useTranslation('app');

  const cleanupSubscription = useRecoilCallback(
    ({ snapshot, reset }) =>
      async () => {
        reset(appBillingSubscription);
        reset(appBillingSubscriptionError);
        reset(appBillingSubscriptionLoadStatus);
      },
  );

  const beforeLoadSubscription = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        set(appBillingSubscription, null);
        set(appBillingSubscriptionError, null);
        set(appBillingSubscriptionLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (SubscriptionId: string) => {
        let subscriptionResponse: FetchResult<
          LoadAppBillingSubscriptionQuery,
          Record<string, any>,
          Record<string, any>
        >;

        function handleError(message, details?) {
          console.error(message, details);
          set(appBillingSubscriptionError, message);
          set(appBillingSubscriptionLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        try {
          subscriptionResponse = await billingClient.mutate<
            LoadAppBillingSubscriptionQuery,
            LoadAppBillingSubscriptionQueryVariables
          >({
            mutation: LoadAppBillingSubscriptionDocument,
            variables: {
              id: SubscriptionId,
            },
          });

          if (subscriptionResponse.errors) {
            handleError(
              t`Can't load Subscription`,
              subscriptionResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }
        } catch (ex) {
          handleError(t`Can't load Subscription`, ex.message);
          return;
        }

        set(
          appBillingSubscription,
          subscriptionResponse.data.appBillingSubscription,
        );
        set(appBillingSubscriptionLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function loadSubscription(subscriptionId: string) {
    await beforeLoadSubscription();
    await loadCallback(subscriptionId);
  }

  return [loadSubscription, cleanupSubscription];
}

export default useLoadSubscription;
