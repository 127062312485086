import React, { PropsWithChildren } from 'react';
import { AriaDialogProps, useDialog } from 'react-aria';

export type DialogProps = PropsWithChildren & AriaDialogProps;

const Dialog: React.FC<DialogProps> = ({ children, ...props }) => {
  const ref = React.useRef(null);
  const { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  );
};
export default Dialog;
