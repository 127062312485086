import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import * as Icon from '../components/icons';

export type NotificationProps = {
  children?: React.ReactNode;
};

export const NotificationDashboard = ({ children }: NotificationProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <div id={id} className="flex w-screen p-4">
      <h2 className="flex flex-col items-center p-2 text-white rounded-full w-28 h-28 bg-menu-app">
        <span className="text-menu-active">{t`Notifications`}</span>
      </h2>
    </div>
  );
};
