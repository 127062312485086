import * as React from 'react';
const LocationsOccupiedIcon = props => (
  <svg
    aria-hidden="true"
    data-name="LocationsOccupiedIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={25.27}
      height={122.66}
      x={50.79}
      y={2.73}
      opacity={0.3}
      transform="translate(126.85 128.11) rotate(180)"
    />
    <path d="m78.03,31.63h47.8s0-28.92,0-28.92h-47.8s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m78.03,62.89h47.8s0-28.92,0-28.92h-47.8s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m78.03,94.15h47.8s0-28.92,0-28.92h-47.8s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m78.03,125.41h47.8s0-28.92,0-28.92h-47.8s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,31.63h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92ZM31.07,8.95l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,62.89h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,94.15h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,125.41h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
  </svg>
);
export default LocationsOccupiedIcon;
