import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import {
  Container,
  ContainerCol,
  ContainerRow,
} from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import useLayoutAreaWatcher from '../simulation/watcher/useLayoutAreaWatcher';
import AnalyzeCompareLayoutView from './AnalyzeCompareLayoutView';
import { getAnalyzeTitle } from './analyzeCompare.helper';
import {
  analyzeCompareAnalyzeList,
  analyzeCompareIdByType,
  analyzeCompareViewModeSelected,
} from './analyzeCompare.state';

const AnalyzeCompareLayoutStages: React.FC = () => {
  const { t } = useTranslation('simulation');
  useLayoutAreaWatcher('simulation-area-view');
  const viewMode = useRecoilValue(analyzeCompareViewModeSelected);
  const analyzeList = useRecoilValue(analyzeCompareAnalyzeList);
  const firstAnalyzeId = useRecoilValue(analyzeCompareIdByType('first'));
  const secondAnalyzeId = useRecoilValue(analyzeCompareIdByType('second'));
  const firstAnalyzeListItem = _.find(
    analyzeList,
    a => a.analyzeId === firstAnalyzeId,
  );
  const secondAnalyzeListItem = _.find(
    analyzeList,
    a => a.analyzeId === secondAnalyzeId,
  );

  const firstTitle = firstAnalyzeListItem
    ? getAnalyzeTitle(firstAnalyzeListItem, t)
    : t`First analyse`;
  const secondTitle = secondAnalyzeListItem
    ? getAnalyzeTitle(secondAnalyzeListItem, t)
    : t`Second analyse`;
  const showFirst = viewMode !== 'second';
  const showSecond = viewMode !== 'first';

  return (
    <Container className={classNames('relative')}>
      {showFirst && (
        <ContainerCol>
          <ContainerRow overflow className={'!h-auto'}>
            <PanelHeader title={firstTitle} className={'flex-1'}>
              <Spacer flexspace />
              {/* <SimulationFilters /> */}
            </PanelHeader>
          </ContainerRow>
          <ContainerRow
            overflow
            flex1
            className={classNames(
              'relative',
              'border-8 border-app-background/70',
              {
                'border-r-4': showSecond,
              },
            )}
          >
            <AnalyzeCompareLayoutView
              analyzeType="first"
              hideLayoutToolbar={showSecond}
            />
          </ContainerRow>
        </ContainerCol>
      )}
      {showSecond && (
        <ContainerCol>
          <ContainerRow overflow className={'!h-auto'}>
            <PanelHeader title={secondTitle} className={'flex-1'} />
          </ContainerRow>
          <ContainerRow
            overflow
            flex1
            className={classNames('relative border-8  border-app-panel-dark', {
              'border-l-4': showSecond,
            })}
          >
            <AnalyzeCompareLayoutView analyzeType="second" />
          </ContainerRow>
        </ContainerCol>
      )}
    </Container>
  );
};

export default AnalyzeCompareLayoutStages;
