import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Tabs, { TabItem } from '../../../components/actions/Tabs';
import RoutingPolicy from '../../../routingPolicy/RoutingPolicy';
import WaypointPolicy from '../../../waypointPolicy/WaypointPolicy';
import { RoutingTabId } from '../../store/simulation.types';
import { simulationRoutingTabKey } from '../../store/simulation.wizard.state';

const RoutingPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [selectedTab, setSelectedTab] = useRecoilState(simulationRoutingTabKey);

  const tabItems: TabItem<RoutingTabId>[] = [
    {
      id: 'tab-routing-policy',
      title: t`Routing`,
      content: <RoutingPolicy />,
    },
    {
      id: 'tab-waypoint-policy',
      title: t`Waypoints`,
      content: <WaypointPolicy />,
    },
  ];

  return (
    <Tabs
      componentName="RoutingPanel"
      items={tabItems}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      fullHeight
      innerScroll
      hasActionbar={false}
    />
  );
};

export default RoutingPanel;
