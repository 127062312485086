import {
  Field,
  GraphQLLocalTime,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import { LocationFilterUnion } from '../location-filter.model';
import { OrderLineFilterUnion } from '../order-line-filter.model';

export enum PickingPolicyLocationSelectionMode {
  LOCATION_PRIORITY = 'LOCATION_PRIORITY',
  ROUND_ROBIN = 'ROUND_ROBIN',
}

registerEnumType(PickingPolicyLocationSelectionMode, {
  name: 'PickingPolicyLocationSelectionMode',
});

export enum PickingRequirementsGroupBy {
  ORDER = 'ORDER',
  ITEM = 'ITEM',
  CUSTOMER = 'CUSTOMER',
  BATCH = 'BATCH',
}

registerEnumType(PickingRequirementsGroupBy, {
  name: 'PickingRequirementsGroupBy',
});

export enum PickingWavePickByDateMode {
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  FIXED_FRAMES = 'FIXED_FRAMES',
}

registerEnumType(PickingWavePickByDateMode, {
  name: 'PickingWavePickByDateMode',
});

@InputAndObjectType()
export class PickingWavePickByDateSettings {
  @Field(() => PickingWavePickByDateMode)
  mode: PickingWavePickByDateMode;

  @Field(() => [GraphQLLocalTime], { nullable: true })
  fixedDeadlines?: string[];
}

@InputAndObjectType()
export class PickingWaveSettings {
  @Field(() => PickingWavePickByDateSettings, { nullable: true })
  pickByDateSettings?: PickingWavePickByDateSettings;

  @Field({ nullable: true })
  useDeliveryRoute?: boolean;

  @Field({ nullable: true })
  useDock?: boolean;

  @Field({ nullable: true })
  useStagingArea?: boolean;

  @Field({ nullable: true })
  useWaveId?: boolean;
}

@InputAndObjectType()
export class PickingRequirementsBatchSettings {
  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  maxOrderCount?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  maxOrderLineCount?: number;

  // @Field({ nullable: true })
  // maxWeight?: number;

  // @Field({ nullable: true })
  // maxVolume?: number;
}

export enum PickingRequirementTerminalLocationSource {
  DELIVERY_ROUTE = 'DELIVERY_ROUTE',
  DOCK = 'DOCK',
  STAGING_AREA = 'STAGING_AREA',
  WAVE_ID = 'WAVE_ID',
}

registerEnumType(PickingRequirementTerminalLocationSource, {
  name: 'PickingRequirementTerminalLocationSource',
});

@InputAndObjectType()
export class PickingPolicyPickingRequirementsSettings {
  @Field(() => PickingRequirementsGroupBy)
  groupBy: PickingRequirementsGroupBy;

  @Field(() => PickingWaveSettings, { nullable: true })
  wave?: PickingWaveSettings;

  @Field(() => PickingRequirementsBatchSettings, {
    nullable: true,
  })
  batch?: PickingRequirementsBatchSettings;

  @Field(() => PickingRequirementTerminalLocationSource, { nullable: true })
  startLocationIdSource?: PickingRequirementTerminalLocationSource;

  @Field(() => PickingRequirementTerminalLocationSource, { nullable: true })
  endLocationIdSource?: PickingRequirementTerminalLocationSource;
}

// @InputAndObjectType()
// export class PickingRuleWaypointTerminalSettings {

//   @Field(() => [ID], { nullable: true })
//   locationIds?: string[];
// }

@InputAndObjectType()
export class PickingPolicyWaypointSettings {
  @Field(() => ID, { nullable: true })
  ruleId?: string;

  @Field(() => [ID], { nullable: true })
  startLocationIds?: string[];

  @Field(() => [ID], { nullable: true })
  endLocationIds?: string[];

  // @Field(() => PickingRuleWaypointTerminalSettings, { nullable: true })
  // startSettings?: PickingRuleWaypointTerminalSettings;

  // @Field(() => PickingRuleWaypointTerminalSettings, { nullable: true })
  // endSettings?: PickingRuleWaypointTerminalSettings;
}

@InputAndObjectType()
export class PickingPolicyPickingRule {
  @Field(() => ID)
  id: string;

  @Field(() => ID)
  agentId: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => [String], { nullable: true })
  uomTypes?: string[];

  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;

  @Field(() => PickingPolicyLocationSelectionMode, { nullable: true })
  locationSelectionMode?: PickingPolicyLocationSelectionMode;

  @Field(() => ID, { nullable: true })
  picklistGroupKey?: string;

  @Field(() => PickingPolicyWaypointSettings, { nullable: true })
  waypointSettings?: PickingPolicyWaypointSettings;
}

export enum PicklistLinesOrderOptions {
  LOCATION_ID = 'LOCATION_ID',
  LOCATION_ORDER = 'LOCATION_ORDER',
  LOCATION_LEVEL = 'LOCATION_LEVEL',
  // TODO do we need more location props?
  CONSIGNEE = 'CONSIGNEE',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  // TODO do we need more item props?
  // TODO do we need more picklist line props?
  STACKING_PRIORITY = 'STACKING_PRIORITY',
}

registerEnumType(PicklistLinesOrderOptions, {
  name: 'PicklistLinesOrderOptions',
});

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

registerEnumType(SortDirection, {
  name: 'PicklistLinesSortDirection',
  description: `Sorting direction`,
});

@InputAndObjectType()
export class PicklistLinesSortBy {
  @Field(() => PicklistLinesOrderOptions)
  type: PicklistLinesOrderOptions;

  @Field(() => SortDirection, {
    defaultValue: SortDirection.ASC,
    description: `Sorting direction`,
  })
  direction?: SortDirection;

  @Field({ nullable: true, defaultValue: false })
  nullsFirst?: boolean;
}

@InputAndObjectType()
export class PickingPolicyPicklistSettings {
  @Field({
    nullable: true,
    description:
      "sort picklist lines by order line's picking priority field when splitting into picklist",
  })
  usePickingPriority?: boolean;

  @Field(() => [PicklistLinesSortBy], { nullable: true })
  picklistLinesOrder?: PicklistLinesSortBy[];
}

@InputAndObjectType()
export class PickingPolicyOrderLineRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => OrderLineFilterUnion, { nullable: true })
  orderLinesMatch?: OrderLineFilterUnion;

  @Field(() => PickingPolicyPickingRequirementsSettings, { nullable: true })
  pickingRequirementsSettings?: PickingPolicyPickingRequirementsSettings;

  @Field(() => [PickingPolicyPickingRule], { nullable: true })
  pickingRules?: PickingPolicyPickingRule[];

  @Field(() => PickingPolicyPicklistSettings, { nullable: true })
  picklistSettings?: PickingPolicyPicklistSettings;

  @Field({
    nullable: true,
    description:
      'if true, use picking container property from order line to separate picking requirements and calculate reorder separately',
  })
  usePickingContainer?: boolean;
}

@InputAndObjectType()
export class PickingPolicy {
  @Field(() => [PickingPolicyOrderLineRule], { nullable: true })
  orderLineRules?: PickingPolicyOrderLineRule[];
}
