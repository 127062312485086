import {
  AllocationRequirementGroupBy,
  AllocationRequirementSummaryKeyFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  AllocationRequirementGroupByType,
  AllocationRequirementGroutByOptions,
} from './allocation.types';

export function getAllocationRequirementGroupByOptions(
  t: TFunction<'simulation'>,
): Record<
  AllocationRequirementGroupByType,
  AllocationRequirementGroutByOptions
> {
  const options: AllocationRequirementGroutByOptions[] = [
    {
      type: 'consignee',
      title: t('Consignee', { ns: 'simulation' }),
      groupBy: [AllocationRequirementGroupBy.CONSIGNEE],
    },
    {
      type: 'item',
      title: t('Item', { ns: 'simulation' }),
      groupBy: [
        AllocationRequirementGroupBy.CONSIGNEE,
        AllocationRequirementGroupBy.SKU,
      ],
    },
    // {
    //   type: 'location',
    //   title: t('Location Group', { ns: 'simulation' }),
    //   groupBy: [
    //     AllocationRequirementGroupBy.POSSIBLE_LOCATION_MASK,
    //     AllocationRequirementGroupBy.REQUIRED_LOCATION_MASK,
    //   ],
    // },
  ];
  return _.keyBy(options, o => o.type) as Record<
    AllocationRequirementGroupByType,
    AllocationRequirementGroutByOptions
  >;
}
const AllocationRequirementSummaryKeysMap: Record<
  AllocationRequirementGroupBy,
  keyof AllocationRequirementSummaryKeyFragment
> = {
  CONSIGNEE: 'consignee',
  SKU: 'sku',
  ITEM_ID: 'itemId',
  ORDER_LINE_RULE_ID: 'orderLineRuleId',
  POSSIBLE_LOCATION_MASK: 'possibleLocationMask',
  REQUIRED_LOCATION_MASK: 'requiredLocationMask',
};

export function getAllocationRequirementKeyTitle(
  groupBy: AllocationRequirementGroupBy[],
  key: AllocationRequirementSummaryKeyFragment,
): string {
  return _(groupBy)
    .map(group => key[AllocationRequirementSummaryKeysMap[group]])
    .join(' ');
}
