import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import type { Paths } from 'type-fest';
import { ColumnConfig } from './DatasetTable';
import * as Icon from './icons';

export function createFilterTagData<T>(
  searchValues: Record<string, string>,
  columnsConfig: ColumnConfig<T>[],
): TableFilterTagData[] {
  const fieldOrder = columnsConfig.map(c => c.field);
  return _(searchValues)
    .map((value, key) => {
      const fieldConfig: ColumnConfig<T> = columnsConfig.find(
        c => c.field === key,
      );

      if (!_.isNil(value) && value !== '' && fieldConfig) {
        return {
          field: key,
          title: fieldConfig.title,
          value: fieldConfig?.filterOptions?.[value] ?? value,
        };
      }
    })
    .filter(v => !!v)
    .sortBy(f => fieldOrder.indexOf(f.field as Paths<T>))
    .value();
}

export type TableFilterTagData = {
  field: string;
  title: string;
  value: string;
};

export type TableFilterProps = {
  children?: React.ReactNode;
  className?: string;
  filterData?: TableFilterTagData[];
  onRemoveClick?: () => void;
};

export const TableFilter = ({
  children,
  className,
  filterData,
  onRemoveClick,
}: TableFilterProps) => {
  const [id] = useState<string>(_.uniqueId());

  if (_.isEmpty(filterData)) return null;

  // concatenated  filter values
  const filterText = _.map(filterData, f => `${f.title}: ${f.value} `).join(
    ' | ',
  );

  return (
    <div
      data-component="TableFilter"
      id={id}
      className={classNames(
        'cursor-pointer rounded',
        'flex items-center',
        'px-2 py-1',
        'text-xxs md:text-xs',
        'bg-menu-active text-menu-active-text hover:bg-menu-hover group',
        className,
      )}
    >
      <div
        data-component="FilterControl"
        className={classNames('ltr:mr-1 rtl:ml-1')}
      >
        <div
          className={classNames('block group-hover:hidden hover:hidden')}
          title="hide filter"
        >
          <Icon.Filter className="h-2 w-2 fill-current" />
        </div>
        <div
          className="hidden group-hover:block"
          title="show filter"
          onClick={onRemoveClick}
        >
          <Icon.CircleX className="h-4 w-4 fill-current" />
        </div>
      </div>

      <ul
        className={classNames(
          'flex flex-1 space-x-1 divide-x divide-menu-400/50',
        )}
      >
        {_.map(filterData, filter => (
          <li
            id={filter.field + id}
            key={filter.field + id}
            className={classNames('flex flex-1 ltr:pl-1 rtl:pr-1')}
          >
            <span data-component="key" className="font-bold">
              {filter.title}
            </span>
            {`:`}
            <span
              data-component="value"
              className={classNames('ltr:ml-1 rtl:mr-1 opacity-80')}
            >
              {filter.value}
            </span>
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
};
