import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { Button } from '../../components/actions/Button';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import {
  warehouseSelected,
  warehouseUpdateLoadStatus,
} from '../../store/warehouse.state';
import {
  LiveFeedFeatureSettings,
  WarehouseExtraSettings,
} from '../../store/warehouse.types';
import useUpdateWarehouseExtraSettings from '../../warehouse/hooks/useUpdateWarehouseExtraSettings';

const SettingsFeed: React.FC = () => {
  const { t } = useTranslation('app');

  const wh = useRecoilValue(warehouseSelected);
  const status = useRecoilValue(warehouseUpdateLoadStatus);
  const extra: WarehouseExtraSettings = wh?.extraSettings;
  const feedSettings = extra?.liveFeed;
  // local state
  const [localEnable, localSetEnable] = useState(feedSettings?.enabled);
  const [localJobId, localSetJobId] = useState(feedSettings?.jobId);
  const [localLayoutId, localSetLayoutId] = useState(feedSettings?.layoutId);

  useEffect(() => {
    localSetEnable(feedSettings?.enabled);
    localSetJobId(feedSettings?.jobId);
    localSetLayoutId(feedSettings?.layoutId);
  }, [feedSettings]);

  const [updateFeedSettings] = useUpdateWarehouseExtraSettings();

  function update() {
    const newFeedSettings: LiveFeedFeatureSettings = {
      enabled: localEnable,
      jobId: localJobId,
      layoutId: localLayoutId,
    };
    updateFeedSettings({ patch: { liveFeed: newFeedSettings } });
  }

  const isLoading = status === AsyncLoadStatus.Loading;
  const hasError = status === AsyncLoadStatus.Error;

  return (
    <section
      className={classNames(
        'w-full',
        'mb-4',
        'divide-menu/50 divide-y divide-solid',
        'p-4',
      )}
    >
      <InputCheckbox
        name="notification"
        className="p-2"
        isSelected={localEnable}
        onChange={v => localSetEnable(v)}
        isDisabled={isLoading}
      >
        <div>{t`Warehouse Live Feed`}</div>
      </InputCheckbox>

      {/* <fieldset className={classNames('p-4 mb-4')}>
        <InputText
          value={localJobId}
          label={t`Events Feed Id`}
          name="jobId"
          isDisabled={!localEnable || isLoading}
          onChange={v => localSetJobId(v)}
        />
      </fieldset> */}

      <ActionBar className={classNames('flex w-full items-end', 'p-1')}>
        <Spacer flexspace />
        <Button
          full
          label={t`Enable`}
          buttonType="primary"
          buttonSize="xs"
          className={classNames('rounded')}
          isLoading={isLoading}
          isDisabled={isLoading}
          onPress={() => update()}
        />
      </ActionBar>
    </section>
  );
};

export default SettingsFeed;
