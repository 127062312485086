import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { actualityHeatmapLevelData } from '../store/actuality.heatmap.state';
import { actualityHeatmapTypeSelected } from '../store/actuality.state';
import { assignmentHeatmapLevelData } from '../store/assignment.heatmap.state';
import useActualityLocationColor from './useActualityLocationColor';

const ActivityLocationLayer: React.FC = () => {
  const heatmapType = useRecoilValue(actualityHeatmapTypeSelected);
  const eventsDataLoadable = useRecoilValueLoadable(actualityHeatmapLevelData);
  const assignmentDataLoadable = useRecoilValueLoadable(
    assignmentHeatmapLevelData,
  );

  const dataLoadable =
    heatmapType === 'events' ? eventsDataLoadable : assignmentDataLoadable;
  const [getHeatmapColor, getGradientHeatmapColors] = useActualityLocationColor(
    { dataLoadable },
  );

  return (
    <LocationLayer
      getColor={getHeatmapColor}
      getGradientColors={getGradientHeatmapColors}
    />
  );
};

export default ActivityLocationLayer;
