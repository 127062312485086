import * as React from 'react';
const WarehouseAreasAisleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseAreasAisleIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,16.94L.84,33.37v73.12h126.32V33.37l-62.66-16.43ZM46.18,74.7H4.57l23.98-27.81h25.03l-7.4,27.81ZM81.78,74.7l-7.4-27.81h25.03l23.98,27.81h-41.61Z"
    />
    <path
      strokeWidth={0}
      d="M60.45,65.76l-1.7,23.83-9.35-5.53-2.09,5.78,16.69,13.96,16.69-13.96-2.09-5.78-9.35,5.53-1.7-23.83c-.07-1.05-.94-1.86-2-1.86h-3.12c-1.05,0-1.92.81-2,1.86Z"
    />
  </svg>
);
export default WarehouseAreasAisleIcon;
