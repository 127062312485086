import * as React from 'react';
const CircleArrowTwoWayIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowTwoWayIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4C30.88,3.97,3.95,30.86,3.95,63.95s26.94,60.03,60.05,60.05c33.12.03,60.05-26.86,60.05-59.95S97.12,4.03,64,4ZM34.97,57.11l14.63,14.67h-15.06s-19.95-20-19.95-20l19.98-20h15.06s-14.65,14.66-14.65,14.66h29.29s0,10.67,0,10.67h-29.29ZM93.97,96.22h-15.06s14.65-14.66,14.65-14.66h-29.29s0-10.67,0-10.67h29.29s-14.63-14.67-14.63-14.67h15.06s19.95,20,19.95,20l-19.98,20Z"
    />
  </svg>
);
export default CircleArrowTwoWayIcon;
