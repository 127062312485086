import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { Stat } from '../components/stats/Stat';
import {
  stackingPolicyRulesStats,
  stackingPolicySelectedIdentity,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';

export type StackingPolicyItemsStatsProps = {
  ruleId: string;
  color?: string;
};

const StackingPolicyItemsStats: React.FC<
  StackingPolicyItemsStatsProps
> = props => {
  const { t } = useTranslation('dataset');
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    stackingPolicySelectedIdentity,
  );
  const ruleStatsLoadable = useRecoilValueLoadable(stackingPolicyRulesStats);
  const ruleStats =
    ruleStatsLoadable.state === 'hasValue' ? ruleStatsLoadable.contents : null;
  const itemsCount = ruleStats?.[props.ruleId];
  const isLoading = ruleStatsLoadable.state === 'loading';

  const isSelected = selectedIdentity?.ruleId === props.ruleId;

  return (
    // <PolicyStatContainer>
    <Stat
      title={t`Item(s)`}
      value={formatInteger(itemsCount)}
      inPanelMode
      transparent
      isActionable
      inFilterStat
      className={`px-1 xl:px-2 py-1`}
      isCheckLoading={isLoading}
      isSelected={isSelected}
      // isLoading={isLoading}
      onClick={() =>
        setSelectedIdentity(
          isSelected
            ? null
            : {
                ruleId: props.ruleId,
              },
        )
      }
    />
    // </PolicyStatContainer>
  );
};

export default StackingPolicyItemsStats;
