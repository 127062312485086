import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NotificationDetails } from '../store/notification.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import { Button } from './actions/Button';
import * as Icon from './icons';
import { Spacer } from './layout/Spacer';
import { ActionBar } from './nav/ActionBar';

export type NotificationWithSimulationProps = {
  notification: NotificationDetails;
  onClose: () => void;
};

const NotificationWithSimulation: React.FC<
  NotificationWithSimulationProps
> = props => {
  const { t } = useTranslation('app');
  const whID = useRecoilValue(warehouseSelectedId);
  const { notification } = props;
  const navigate = useNavigate();

  const toSimulation = `/wh/i/${whID}/simulations/${notification.simulationId}`;

  return (
    <div
      data-component="NotificationWithSimulation"
      className={`flex flex-col`}
    >
      <div
        className={`flex ltr:items-start rtl:items-end border-b border-app-panel-dark`}
      >
        <div
          className={`flex-1 text-xl flex ltr:items-start rtl:items-end ltr:pl-1 rtl:pr-1`}
        >
          <Icon.CircleOk className="w-7 h-7 pb-0.5 pt-1 ltr:mr-1 rtl:ml-1 fill-current ltr:ml-1 rtl:mr-1" />
          <div className={`flex-1 ltr:ml-1 rtl:mr-1 ltr:pl-2 rtl:pr-2 h-full`}>
            {notification.title}
          </div>
        </div>
        <button
          type="button"
          className={`ml-2 rounded-full w-7 h-7 flex items-center hover:text-menu-text text-menu-text`}
          aria-label={t`Close Error`}
          onClick={props.onClose}
        >
          <Icon.Close className={`w-4 h-4 fill-current`} />
        </button>
      </div>

      <ActionBar transparent className={`mt-0`}>
        <Button
          full
          buttonSize="xs"
          buttonType="primary"
          hasIconBefore
          buttonIcon={<Icon.ArrowLeft className={`w-6 h-6 fill-current`} />}
          onPress={() => {
            props.onClose();
            navigate(toSimulation);
          }}
        >
          <span className="ltr:ml-2 rtl:mr-2">{t`Back to Simulation`}</span>
        </Button>

        <Spacer flexspace />
        {/* <Button buttonSize="xs" buttonType="primary" onPress={props.onClose}>
          {t`Dismiss`}
        </Button> */}
      </ActionBar>
    </div>
  );
};
export default NotificationWithSimulation;
