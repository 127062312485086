import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { TableRow } from './TableRow';

export type TableRowBodyProps = PropsWithChildren & {
  className?: string;
  isSelected?: boolean;
  isHoverable?: boolean;
  onClick?: () => void;
};

export const TableRowBody = ({
  children,
  className = '',
  isSelected,
  isHoverable,
  onClick,
  ...rest
}: TableRowBodyProps) => {
  return (
    <TableRow
      rowBody
      rowLine
      className={classNames(
        'text-start',
        isSelected
          ? 'bg-menu-active/20 text-menu-active-text hover:bg-menu-active/30'
          : 'bg-app-panel-dark',
        {
          'hover:bg-menu-active/30 hover:text-menu-active-text': isHoverable,
        },
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </TableRow>
  );
};
