import * as React from 'react';
const RotateObjectIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RotateObjectIcon"
    {...props}
  >
    <circle cx={85} cy={13.2} r={2} strokeWidth={0} />
    <circle cx={85} cy={27.6} r={2} strokeWidth={0} />
    <circle cx={85} cy={20.4} r={2} strokeWidth={0} />
    <circle cx={85} cy={34.8} r={2} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M83,6c0,.54.22,1.04.58,1.42.38.36.9.58,1.42.58s1.04-.22,1.42-.58c.74-.74.74-2.1,0-2.84-.38-.36-.88-.58-1.42-.58s-1.04.22-1.42.58c-.36.38-.58.88-.58,1.42Z"
    />
    <circle cx={55.66} cy={6} r={2} strokeWidth={0} />
    <circle cx={48.34} cy={6} r={2} strokeWidth={0} />
    <circle cx={77.66} cy={6} r={2} strokeWidth={0} />
    <circle cx={63} cy={6} r={2} strokeWidth={0} />
    <circle cx={70.34} cy={6} r={2} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M41,8c.52,0,1.04-.22,1.42-.58.74-.74.74-2.1,0-2.84-.38-.36-.9-.58-1.42-.58s-1.04.22-1.4.58c-.38.38-.6.88-.6,1.42s.22,1.04.58,1.42c.38.36.9.58,1.42.58Z"
    />
    <circle cx={41} cy={27.6} r={2} strokeWidth={0} />
    <circle cx={41} cy={20.4} r={2} strokeWidth={0} />
    <circle cx={41} cy={34.8} r={2} strokeWidth={0} />
    <circle cx={41} cy={13.2} r={2} strokeWidth={0} />
    <circle cx={41} cy={96.5} r={2} strokeWidth={0} />
    <circle cx={41} cy={113.5} r={2} strokeWidth={0} />
    <circle cx={41} cy={105} r={2} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M42.42,120.58c-.38-.36-.9-.58-1.42-.58s-1.04.22-1.42.58c-.36.38-.58.9-.58,1.42s.22,1.04.58,1.42c.38.36.9.58,1.42.58s1.04-.22,1.42-.58c.74-.74.74-2.1,0-2.84Z"
    />
    <circle cx={55.66} cy={122} r={2} strokeWidth={0} />
    <circle cx={63} cy={122} r={2} strokeWidth={0} />
    <circle cx={77.66} cy={122} r={2} strokeWidth={0} />
    <circle cx={70.34} cy={122} r={2} strokeWidth={0} />
    <circle cx={48.34} cy={122} r={2} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M86.42,120.58c-.38-.36-.9-.58-1.42-.58s-1.04.22-1.42.58c-.36.38-.58.9-.58,1.42s.22,1.04.58,1.42c.38.36.9.58,1.42.58s1.04-.22,1.42-.58c.74-.76.74-2.08,0-2.84Z"
    />
    <circle cx={85} cy={96.5} r={2} strokeWidth={0} />
    <circle cx={85} cy={105} r={2} strokeWidth={0} />
    <circle cx={85} cy={113.5} r={2} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M95,112h0l3.6-4.8c.67-.88.48-2.14-.4-2.8-.88-.66-2.14-.48-2.8.4l-6,8s-.04.08-.06.12c-.04.06-.09.11-.12.18-.02.03-.01.07-.02.1-.11.25-.19.52-.19.79,0,0,0,0,0,0h0c0,.27.07.53.18.78.03.07.07.13.1.2.04.07.06.15.12.22l6,8c.66.88,1.92,1.06,2.8.4.88-.66,1.07-1.92.4-2.8l-3.6-4.8c14.76,0,26.87-11.49,27.9-26h.1v-50H7.1c1.02-12.3,11.34-22,23.9-22h0l-3.6,4.8c-.27.36-.4.78-.4,1.2,0,.61.28,1.21.8,1.6.88.66,2.14.48,2.8-.4l6-8s.03-.07.05-.1c.05-.07.1-.13.14-.21.05-.1.07-.2.1-.31.02-.05.04-.1.05-.16.05-.22.06-.44.03-.67,0-.05-.03-.1-.04-.16-.04-.18-.09-.34-.18-.5-.03-.06-.06-.12-.1-.18-.03-.04-.04-.09-.07-.13l-6-8c-.66-.88-1.92-1.06-2.8-.4-.88.66-1.06,1.91-.4,2.8l3.6,4.8c-14.76,0-26.87,11.49-27.9,26h-.1v50h115.9c-1.02,12.3-11.34,22-23.9,22Z"
    />
  </svg>
);
export default RotateObjectIcon;
