import classNames from 'classnames';
import _ from 'lodash';
import { HTMLAttributes, useState } from 'react';
import { Button } from '../actions/Button';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';

export type AlertProps = {
  children?: React.ReactNode;
  icon?: React.FC<HTMLAttributes<Element>>;
  className?: string;
  message?: string | React.ReactNode;
  messageHelper?: string;
  actionable?: boolean;
  action_title?: string;
  hasStatusAlert?: boolean;
  hasStatusError?: boolean;
  hasStatusWarning?: boolean;
  hasStatusInfo?: boolean;
  hasStatusOkay?: boolean;
  hasStatusLocked?: boolean;
  hasStatusDisabled?: boolean;
  hasStatusOverlap?: boolean;
  hasStatusHelper?: boolean;
  hasStatusConsent?: boolean;
  hasStatusLoading?: boolean;
  isAbsoluteBottom?: boolean;
  fullWidth?: boolean;
  horizontal?: boolean;
  onActionButtonClick?: () => void;
};

export const Alert = ({
  children,
  className,
  icon,
  message,
  messageHelper,
  actionable,
  action_title,
  hasStatusAlert,
  hasStatusError,
  hasStatusWarning,
  hasStatusInfo,
  hasStatusOkay,
  hasStatusDisabled,
  hasStatusLocked,
  hasStatusHelper,
  hasStatusConsent,
  hasStatusOverlap,
  hasStatusLoading,
  isAbsoluteBottom,
  fullWidth,
  horizontal,
  onActionButtonClick,
}: AlertProps) => {
  const [id] = useState<string>(_.uniqueId());
  const IconMenu = icon;

  return (
    <div
      data-component="Alert"
      data-label={`alert-${message || ''}`}
      aria-label={`alert-${message || ''}`}
      id={id}
      className={classNames(
        'z-alert flex items-center rounded-sm',
        'text-sm xl:text-base',
        'p-2',
        { 'flex-col items-start': hasStatusAlert },
        hasStatusHelper
          ? 'text-menu-active bg-menu backdrop-saturate-110 rounded bg-opacity-50 backdrop-blur-lg backdrop-filter'
          : hasStatusConsent
            ? 'backdrop-saturate-120 rounded bg-white/50 backdrop-blur-xl backdrop-filter'
            : 'text-menu-text',

        { 'w-full': fullWidth },
        { 'bg-alerts-error': hasStatusError },
        { 'bg-alerts-alert': hasStatusAlert },
        { 'bg-alerts-warning text-alerts-alertText': hasStatusWarning },
        { 'bg-alerts-alert text-alerts-alertText': hasStatusLocked },
        { 'bg-alerts-info': hasStatusInfo },
        { 'bg-alerts-okay': hasStatusOkay },
        { 'bg-alerts-disabled': hasStatusDisabled },
        { 'bg-alerts-overlap': hasStatusOverlap },
        { 'bg-app-panel-dark/60 text-menu-active': hasStatusLoading },
        {
          'absolute bottom-0 left-0 right-0 bg-opacity-70 backdrop-blur backdrop-filter':
            isAbsoluteBottom || hasStatusLoading,
        },
        className,
      )}
    >
      <div
        className={classNames(
          'flex flex-1',
          children && !hasStatusConsent
            ? 'mb-2 items-center self-start'
            : 'w-full items-center',
        )}
      >
        {hasStatusLoading && (
          <Loader
            size={24}
            stroke={5}
            type={'loading-original'}
            className={classNames('ltr:mr-2 rtl:ml-2')}
          />
        )}

        {hasStatusOkay && (
          <Icon.CircleInfo
            className={classNames(
              'mx-1 h-5 w-5 fill-current ltr:mr-2 rtl:ml-2',
            )}
          />
        )}
        {hasStatusWarning && (
          <Icon.TriangleInfo
            className={classNames(
              'mx-1 h-5 w-5 fill-current ltr:mr-2 rtl:ml-2',
            )}
          />
        )}
        {hasStatusLocked && (
          <Icon.Lock
            className={classNames(
              'mx-1 h-5 w-5 fill-current ltr:mr-2 rtl:ml-2',
            )}
          />
        )}
        {!messageHelper && hasStatusAlert && (
          <Icon.TriangleInfo
            className={classNames(
              'mx-1 h-5 w-5 fill-current ltr:mr-2 rtl:ml-2',
            )}
          />
        )}

        {hasStatusError && (
          <Icon.TriangleInfo
            className={`mx-1 fill-current ${
              children ? 'h-4 w-4' : 'h-5 w-5 ltr:mr-2 rtl:ml-2'
            }`}
          />
        )}

        {!hasStatusLoading && hasStatusInfo && (
          <Icon.CircleInfo className="mx-2 h-5 w-5 fill-current" />
        )}

        {IconMenu && (
          <IconMenu
            className={classNames(
              'h-6 w-6 fill-current ltr:mr-4 ltr:lg:ml-2 rtl:ml-4 rtl:lg:mr-2',
              horizontal ? '' : 'mt-2 lg:mt-4',
            )}
          />
        )}

        <div
          className={classNames(
            'flex flex-1 select-text flex-col',
            { 'lg:mt-0 lg:pt-0': !horizontal },
            {
              'border-menu-100/30 lg:border-menu-100/30 border-l lg:border-t-0 lg:pr-2 ltr:pl-3 lg:ltr:border-l lg:ltr:pl-4 rtl:border-r rtl:pr-3 lg:rtl:pr-4':
                IconMenu,
            },
          )}
        >
          {messageHelper && (
            <div
              className={classNames(
                'shadow',
                'px-2 py-1',
                'text-sm font-bold',
                'text-start align-middle',
                'rounded',
                'bg-menu/50',
                'my-1 mb-2 max-w-xs xl:my-3',
                'w-full',
                {
                  'flex items-center': hasStatusAlert,
                },
              )}
            >
              {hasStatusAlert && (
                <Icon.TriangleInfo
                  className={classNames(
                    'mx-1 h-4 w-4 fill-current xl:h-5 xl:w-5 ltr:mr-2 rtl:ml-2',
                  )}
                />
              )}
              {messageHelper}
            </div>
          )}
          {message && <div className="flex-1">{message}</div>}
        </div>
      </div>

      {actionable && (
        <Button
          className={classNames('', {
            'hover:bg-menu-active text-menu-active-text mx-4 min-w-12 cursor-pointer rounded border border-black px-4 text-base shadow-lg hover:shadow-xl xl:text-xl':
              hasStatusConsent,
          })}
          buttonSize="xs"
          buttonType={`
          ${hasStatusConsent && 'primary'}
         ${hasStatusOkay ? 'primary' : 'primary'}
         ${hasStatusError && 'error'}
        `}
          onPress={() => {
            onActionButtonClick && onActionButtonClick();
          }}
        >
          {action_title}
        </Button>
      )}
      {children && (
        <div className={classNames('w-full flex-1')}>{children}</div>
      )}
    </div>
  );
};
