import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import LayoutFilter from '../../components/LayoutFilter';
import { analyzeJobDetailsSelectedRow } from '../store/analyze.state';

const ReassignMoveSelectedTag: React.FC = () => {
  const { t } = useTranslation('app');
  const [selectedMove, setSelectedMove] = useRecoilState(
    analyzeJobDetailsSelectedRow,
  );

  if (_.isNil(selectedMove)) return null;

  return (
    <LayoutFilter
      className={'ltr:ml-1 rtl:mr-1'}
      filterName={t`Reassign move: `}
      onRemoveClick={() => {
        setSelectedMove(null);
      }}
      type="selection"
    >
      {selectedMove?.stepId}
    </LayoutFilter>
  );
};

export default () => (
  <Suspense>
    <ReassignMoveSelectedTag />
  </Suspense>
);
