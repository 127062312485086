import _ from 'lodash';
import React, { useState } from 'react';
import { IconArrowDir } from './IconArrowDirectional';

export type NavGoProps = {
  className?: string;
  children?: React.ReactNode;
  message?: string;
  title?: string;
};

export const NavGo = ({ title, message, className }: NavGoProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component="NavGo"
      id={id}
      className="flex items-center py-4 px-6 bg-menu-app"
    >
      <span className="flex-1 font-bold text-xl text-menu-active hover:text-menu-active-light">
        {message}
      </span>
      <IconArrowDir className="w-6 h-6 p-1 ltr:ml-6 rtl:mr-6 rounded-full fill-current bg-menu-active text-menu-active-text" />
    </div>
  );
};
