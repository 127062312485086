import { AreaLinkPointType } from '@warebee/shared/data-access-layout-import-converter';

export const iconLinkAdd = `
    M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM109.75,71.09c-2.3,2.29-5.35,3.55-8.59,3.55h-25.04s-.04,26.52-.04,26.52c0,3.24-1.27,6.28-3.56,8.57-2.3,2.29-5.34,3.55-8.58,3.55-6.69,0-12.12-5.44-12.12-12.13l.04-26.52h-25.05c-6.69,0-12.12-5.44-12.12-12.12,0-3.24,1.27-6.29,3.56-8.58,2.3-2.29,5.34-3.55,8.58-3.55h25.05s.03-23.54.03-23.54c0-3.24,1.27-6.28,3.56-8.57,2.3-2.29,5.34-3.55,8.59-3.55,6.68,0,12.12,5.44,12.11,12.12l-.03,23.55h25.05c3.24,0,6.28,1.26,8.57,3.55,2.29,2.29,3.55,5.34,3.54,8.58,0,3.23-1.27,6.28-3.56,8.57Z
    `;

export const iconLinkSource = `
    M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM109,99.56c0,5.22-4.23,9.45-9.45,9.45h-5.39l-54.13-54.13.03,40.85-21.06-21V19h55.69l21.02,21h-40.83s54.13,54.1,54.13,54.1v5.46Z
    `;

export const iconLinkTarget = `
    M90.44,63.04c-5.73,0-10.28,4.65-10.28,10.28v6.33h20.46s.1,0,.1,0v-6.33c0-5.73-4.65-10.28-10.28-10.28Z 
    M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM61.94,61.16h-7.86l-28.65-28.65.02,21.63-11.15-11.12V13.52h29.48l11.13,11.12h-21.61s28.65,28.64,28.65,28.64v7.89ZM113.68,110.98c0,2.67-2.18,4.84-4.84,4.84h-36.97c-2.67,0-4.84-2.17-4.84-4.84v-26.49c0-2.67,2.18-4.84,4.84-4.84h.4v-6.33c0-9.98,8.21-18.19,18.19-18.19,9.98,0,18.19,8.2,18.19,18.19v6.33h.1c2.67,0,4.75,2.08,4.94,4.74v26.59Z
    `;

export const iconLinkTargetBottom = `
    M91.95 21.79c5.73 0 10.28 4.65 10.28 10.28v6.33H81.67v-6.33c0-5.73 4.65-10.28 10.28-10.28Z
    M125.71 115.71v-100c0-5.52-4.48-10-10-10h-100c-5.52 0-10 4.48-10 10v100c0 5.52 4.48 10 10 10h100c5.52 0 10-4.48 10-10ZM63.64 76.44l-28.65 28.63H56.6l-11.13 11.12H15.99v-29.5l11.15-11.12-.02 21.63 28.65-28.65h7.86v7.89Zm5.08-33.3c.2-2.67 2.27-4.74 4.94-4.74h.1v-6.33c0-9.98 8.21-18.19 18.19-18.19 9.99 0 18.19 8.2 18.19 18.19v6.33h.39c2.67 0 4.84 2.17 4.84 4.84v26.49c0 2.67-2.18 4.84-4.85 4.84H73.55a4.85 4.85 0 0 1-4.84-4.84V43.14Z
  `;

export const iconLinkTargetTop = `
    M92.15 64.75c-5.73 0-10.28 4.65-10.28 10.28v6.33h20.56v-6.33c0-5.73-4.65-10.28-10.28-10.28Z
    M115.71 5.71h-100c-5.52 0-10 4.48-10 10v100c0 5.52 4.48 10 10 10h100c5.52 0 10-4.48 10-10v-100c0-5.52-4.48-10-10-10ZM63.64 62.87h-7.86L27.13 34.22l.02 21.63L16 44.73V15.22h29.48l11.13 11.12H35l28.65 28.64v7.89Zm51.74 49.82a4.85 4.85 0 0 1-4.84 4.84H73.57c-2.67 0-4.84-2.17-4.84-4.84V86.2a4.85 4.85 0 0 1 4.84-4.84h.4v-6.33c0-9.98 8.21-18.19 18.19-18.19s18.19 8.2 18.19 18.19v6.33h.1c2.67 0 4.75 2.08 4.94 4.74v26.59Z
    `;

export const iconLinkBoth = `
    M114 4H14C8.48 4 4 8.48 4 14v100c0 5.52 4.48 10 10 10h100c5.52 0 10-4.48 10-10V14c0-5.52-4.48-10-10-10ZM38.05 45.29 25.43 32.51l.02 21.63L14.3 43.02v-29.5h29.48l11.13 11.12H33.3l12.74 12.89c-3.09 2.1-5.79 4.73-7.98 7.76Zm8.11 37.83V60.86c0-1.74 1.66-3.16 3.7-3.16h.3v-4.12c0-6.51 6.27-11.85 13.91-11.85s13.91 5.35 13.91 11.85v4.12h.08c2.04 0 3.63 1.35 3.78 3.09v22.33c0 1.74-1.66 3.16-3.7 3.16H49.87c-2.04 0-3.7-1.42-3.7-3.16Zm70.43 32.72H87.11l-11.12-11.12H97.6L82.88 89.83c3.01-2.21 5.63-4.92 7.71-8.03l14.86 15.05-.02-21.63 11.15 11.12v29.5Z
    M64.08 46.88c-4.38 0-7.86 3.03-7.86 6.7v4.12h15.73v-4.12c0-3.74-3.55-6.7-7.86-6.7Z
    `;

export const iconLinkRemove = `
    M114,4H14c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10ZM99.3,99.37c-3.63,3.63-9.53,3.63-13.15,0l-22.96-23-22.18,22.18c-3.63,3.63-9.53,3.62-13.15,0-1.76-1.76-2.72-4.1-2.72-6.59,0-2.49.97-4.83,2.73-6.58l22.18-22.18-21.35-21.38c-1.75-1.76-2.72-4.1-2.72-6.59,0-2.49.97-4.83,2.73-6.59,3.62-3.63,9.52-3.62,13.15,0l21.35,21.39,22.18-22.18c1.76-1.76,4.09-2.73,6.58-2.73,2.49,0,4.82.97,6.58,2.73,1.75,1.75,2.72,4.1,2.72,6.58,0,2.49-.97,4.83-2.73,6.59l-22.17,22.18,22.96,23c1.76,1.76,2.72,4.1,2.72,6.59,0,2.49-.97,4.83-2.73,6.58Z
    `;

export type IconDef = {
  path: string;
  rotation?: number;
  flipX?: boolean;
  flipY?: boolean;
};

function getRotation(type: AreaLinkPointType): number {
  switch (type) {
    case 'bottomLeft':
      return 0;
    case 'topLeft':
      return -90;
    case 'topRight':
      return -180;
    case 'bottomRight':
      return -270;
  }
}

export function getLinkSourceIconDef(type: AreaLinkPointType): IconDef {
  return {
    path: iconLinkSource,
    rotation: getRotation(type),
  };
}

export function getLinkTargetIconDef(type: AreaLinkPointType): IconDef {
  const path =
    type === 'bottomLeft' || type === 'bottomRight'
      ? iconLinkTargetBottom
      : iconLinkTargetTop;
  const flipX = type === 'bottomLeft' || type === 'topLeft' ? false : true;

  return {
    path,
    flipX,
    flipY: true,
  };
}

export function getLinkBothIconDef(type: AreaLinkPointType): IconDef {
  return {
    path: iconLinkBoth,
    rotation: getRotation(type),
  };
}
