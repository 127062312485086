import * as React from 'react';
const DataGridIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataGridIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M14,4c-5.52,0-10,4.48-10,10v100c0,5.52,4.48,10,10,10h100c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10H14ZM16.15,32.22h95.69v9.7H16.15v-9.7ZM16.15,49.28h95.69v56.83c0,2.76-2.24,5-5,5H21.15c-2.76,0-5-2.24-5-5v-56.83ZM19.97,54.64v7.36h24.02v-7.36h-24.02ZM52.05,54.64v7.36h24.02v-7.36h-24.02ZM84.04,54.64v7.36h24.02v-7.36h-24.02ZM19.97,69.36v7.36h24.02v-7.36h-24.02ZM52.05,69.36v7.36h24.02v-7.36h-24.02ZM84.04,69.36v7.36h24.02v-7.36h-24.02ZM19.97,84.08v7.36h24.02v-7.36h-24.02ZM52.05,84.08v7.36h24.02v-7.36h-24.02ZM84.04,84.08v7.36h24.02v-7.36h-24.02ZM19.97,98.58v7.36h24.02v-7.36h-24.02ZM52.05,98.58v7.36h24.02v-7.36h-24.02ZM84.04,98.58v7.36h24.02v-7.36h-24.02Z"
    />
  </svg>
);
export default DataGridIcon;
