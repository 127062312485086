import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListItem } from '../../components/stats/StatListItem';
import PanelContainer from '../../containers/PanelContainer';
import { optimisationSelectedOrder } from '../store/optimisation.state';
import { resourcePolicy } from '../store/resourcePolicy.state';
import { simulationLayoutSelectedOrder } from '../store/simulation.layout.state';

const picklistColors = TwTheme.extend.colors.picklistLines;

const OrderSummaryPanel: React.FC = () => {
  const orderBefore = useRecoilValue(simulationLayoutSelectedOrder);
  const orderAfter = useRecoilValue(optimisationSelectedOrder);

  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  if (!resourcePolicy || !orderBefore || !orderAfter) {
    return null;
  }
  //distance
  const distanceBefore = formatter.formatDistance(orderBefore.totalDistance);
  const distanceAfter = formatter.formatDistance(orderAfter.totalDistance);
  // const distanceDelta = formatter.formatDistance(
  //   Math.abs(orderBefore.totalDistance - orderAfter.totalDistance),
  // );
  const distanceDelta = formatInteger(distanceBefore.raw - distanceAfter.raw);
  const distanceShareNumeric =
    1 - orderAfter.totalDistance / orderBefore.totalDistance;
  const distanceShare = formatter.formatShare(distanceShareNumeric);

  const durationBefore = formatter.formatTimeSpan(
    orderBefore.totalDuration,
    10,
  );
  const durationAfter = formatter.formatTimeSpan(orderAfter.totalDuration, 10);
  // const durationDelta = formatter.formatTimeSpan(
  //   Math.abs(orderBefore.totalDuration - orderAfter.totalDuration),
  // );
  const durationDelta = formatInteger(durationBefore.raw - durationAfter.raw);
  const durationShareNumeric =
    1 - orderAfter.totalDuration / orderBefore.totalDuration;
  const durationShare = formatter.formatShare(durationShareNumeric);
  // cost
  const costBefore = formatter.formatCurrency(orderBefore.totalCost);
  const costAfter = formatter.formatCurrency(orderAfter.totalCost);
  // const costDelta = formatter.formatCurrency(
  //   Math.abs(orderBefore.totalCost - orderAfter.totalCost),
  // );
  const costDelta = formatter.formatCurrency(costBefore.raw - costAfter.raw);
  const costShareNumeric = 1 - orderAfter.totalCost / orderBefore.totalCost;
  const costShare = formatter.formatShare(costShareNumeric);

  return (
    <PanelContainer
      id="panel-routes-stats"
      title={t`Order Stats`}
      collapsible
      subtitle={orderAfter.order.orderId}
    >
      <Stat
        isSelectable
        isPreview
        title={t`Order`}
        value={orderAfter.order.orderId}
        canCopy
      >
        <StatListItem
          title={t`Total Order Lines`}
          value={formatInteger(orderAfter.totalLines)}
        />
        <StatListItem
          legendType="line"
          legendColor={picklistColors.full}
          title={t`Picked Lines`}
          value={formatInteger(orderAfter.assembledLines)}
        />
        <StatListItem
          legendType="line"
          legendColor={picklistColors.partial}
          title={t`Partially Picked Lines`}
          value={formatInteger(orderAfter.assembledPartiallyLines)}
        />
        <StatListItem
          legendType="line"
          legendColor={picklistColors.ignored}
          title={t`Unpickable Lines`}
          value={formatInteger(orderAfter.ignoredLines)}
        />
      </Stat>
      <StatGroup
        title={t`Order Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/optimise/simulation-optimise-summary-orders'}
      >
        <Stat
          inPanelMode
          hasHelper
          // isPreview
          title={t`Total Cost`}
          valueDiffPercent={costShare.value}
          //valueDiff={costDelta.value}
          valueDiff={costDelta.value}
          valueBefore={costBefore}
          valueAfter={costAfter}
          isComparable
          unitOfMeasure={costAfter.unit}
          isUnitPrefix={costAfter.prefixUnit}
          valueIsBig
          isFullWidth
        />

        <Stat
          isFullWidth
          inPanelMode
          hasHelper
          // isPreview
          title={t`Picking Distance`}
          valueDiffPercent={distanceShare.value}
          //valueDiff={distanceDelta.value}
          valueDiff={distanceDelta}
          valueBefore={distanceBefore}
          valueAfter={distanceAfter}
          isComparable
          // isPrimaryResult
          valueIsBig
          unitOfMeasure={distanceBefore.unit}
        />
        <Stat
          inPanelMode
          hasHelper
          isPreview
          title={t`Picking Time`}
          valueBefore={durationBefore}
          valueAfter={durationAfter}
          valueDiffPercent={durationShare.value}
          //valueDiff={durationDelta.value}
          valueDiff={durationDelta}
          isComparable
          unitOfMeasure={durationBefore.unit}
          // isPrimaryResult
          valueIsBig
        />
      </StatGroup>
    </PanelContainer>
  );
};

export default OrderSummaryPanel;
