import { atom } from 'recoil';
import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';

const getKey = (postfix: string) => `simulation-bay-viewer-${postfix}`;

export const simulationBayViewerHoveredLocation =
  atom<LayoutBayLocationFragment>({
    key: getKey('hovered-location'),
    default: null,
  });
