import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { InputGroupList } from '../components/inputs/InputGroupList';
import { ContainerScroll } from '../components/layout/ContainerScroll';
import useLoadItemSetFilterValues from '../itemSet/hooks/useLoadItemSetFilterValues';
import { updatePolicyFilterValues } from '../policyFilters/policyFilter.helper';
import { getStackingPolicyFilterConfig } from '../simulation/store/stackingPolicy/stackingPolicy.default';
import {
  stackingPolicyRule,
  stackingPolicySelectedIdentity,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';
import StackingPolicyFilterEditor from './StackingPolicyFilterEditor';

const StackingPolicyFilterEditorPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [startLoadData, cancelLoadData] = useLoadItemSetFilterValues();
  const selectedIdentity = useRecoilValue(stackingPolicySelectedIdentity);
  const rule = useRecoilValue(stackingPolicyRule(selectedIdentity.ruleId));

  const itemsFilterConfig = getStackingPolicyFilterConfig(t);
  useEffect(() => {
    updatePolicyFilterValues({
      filterConfig: itemsFilterConfig,
      selectedFilterId: selectedIdentity.productFilterId,
      policyFilterUnion: rule.itemsMatch,
      load: startLoadData,
      cancel: cancelLoadData,
    });
  }, [selectedIdentity]);

  return (
    <ContainerScroll>
      <InputGroupList hasSpacing></InputGroupList>
      <InputGroupList>
        {_.map(itemsFilterConfig, filterConfig => {
          return (
            <StackingPolicyFilterEditor
              key={`item-set-filter-${filterConfig.type}`}
              filterConfig={filterConfig}
              startLoadData={startLoadData}
              cancelLoadData={cancelLoadData}
            />
          );
        })}
      </InputGroupList>
    </ContainerScroll>
  );
};

export default StackingPolicyFilterEditorPanel;
