import * as React from 'react';
const MenuBars3BarsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuBars3BarsIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M108.77,97.07H19.23c-2.81,0-5.09-2.28-5.09-5.09s2.28-5.09,5.09-5.09h89.54c2.81,0,5.09,2.28,5.09,5.09s-2.28,5.09-5.09,5.09ZM108.77,69.09H19.23c-2.81,0-5.09-2.28-5.09-5.09s2.28-5.09,5.09-5.09h89.54c2.81,0,5.09,2.28,5.09,5.09s-2.28,5.09-5.09,5.09ZM108.77,41.11H19.23c-2.81,0-5.09-2.28-5.09-5.09s2.28-5.09,5.09-5.09h89.54c2.81,0,5.09,2.28,5.09,5.09s-2.28,5.09-5.09,5.09Z"
    />
  </svg>
);
export default MenuBars3BarsIcon;
