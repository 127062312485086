import { LayoutFeatureType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Group } from 'react-konva';
import { useRecoilValueLoadable } from 'recoil';
import RoutingDirectionFeature from '../../layout/features/RoutingDirectionFeature';
import { getBox, getBoxWH } from '../../layout/viewer/store/viewer.helper';
import {
  viewerAreaAisles,
  viewerAreaBays,
} from '../../layout/viewer/store/viewer.state';

export type RoutingRulesFeaturesGroupProps = {
  isStaticMode: boolean;
};

const RoutingRulesFeaturesGroup: React.FC<
  RoutingRulesFeaturesGroupProps
> = props => {
  const areaAislesLoadable = useRecoilValueLoadable(viewerAreaAisles);
  const areaBaysLoadable = useRecoilValueLoadable(viewerAreaBays);

  if (
    areaAislesLoadable.state !== 'hasValue' ||
    areaBaysLoadable.state !== 'hasValue'
  )
    return null;

  const features = {
    ...areaAislesLoadable.contents,
    ...areaBaysLoadable.contents,
  };

  return (
    <Group>
      {_.values(features)
        .filter(f => f.navigable)
        .map(feature => {
          const box = getBox(feature.shape);
          const [boxWidth, boxHeight] = getBoxWH(box);
          return (
            <RoutingDirectionFeature
              key={`routing-feature-${feature.id}`}
              featureId={feature.id}
              title={feature.title}
              box={box}
              isStaticMode={props.isStaticMode}
              initialDirection={boxHeight > boxWidth ? [0, 1] : [1, 0]}
              rotateRad={
                feature.type === LayoutFeatureType.AISLE
                  ? Math.PI
                  : -Math.PI / 2
              }
            />
          );
        })}
    </Group>
  );
};

export default RoutingRulesFeaturesGroup;
