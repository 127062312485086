import {
  LayoutBayLocationFragment,
  LayoutLevelLocationFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { getHeatmapBucketByValue } from '../../../common/heatmap.helper';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { analyzeProductMetricDescriptor } from '../../../metrics/analyzeProduct/analyzeProductMetric.state';
import { AnalyzeProductDataRow } from '../../../metrics/analyzeProduct/analyzeProductMetric.types';
import {
  simulationHeatmapBuckets,
  simulationHeatmapRangeDescriptor,
} from '../../store/simulation.heatmap.state';
import { simulationLayoutHeatmapFilters } from '../../store/simulation.layout.state';

export type SimulationBayLocationLayerBaseProps = {
  heatmapByLoc: Record<string, AnalyzeProductDataRow[]>;
  filter?: (location: LayoutBayLocationFragment) => boolean;
};

const SimulationBayLocationLayerBase: React.FC<
  SimulationBayLocationLayerBaseProps
> = props => {
  const { heatmapByLoc } = props;

  const rangeDescriptorLoadable = useRecoilValueLoadable(
    simulationHeatmapRangeDescriptor,
  );
  const heatmapFilter = useRecoilValue(simulationLayoutHeatmapFilters);
  const bucketsLoadable = useRecoilValueLoadable(simulationHeatmapBuckets);
  const metricDescriptor = useRecoilValue(analyzeProductMetricDescriptor);

  const buckets =
    bucketsLoadable.state === 'hasValue' ? bucketsLoadable.contents : null;

  const rangeDescriptor =
    rangeDescriptorLoadable.state === 'hasValue'
      ? rangeDescriptorLoadable.contents
      : null;

  if (_.isNil(rangeDescriptor) || _.isNil(heatmapByLoc)) return null;

  const bucketsMap = _.keyBy(buckets, b => b.id);
  function getGradientColors(loc: LayoutBayLocationFragment): string[] {
    if (props.filter && !props.filter(loc)) {
      return null;
    }
    const colors = _(heatmapByLoc[loc.locationId])
      .map(data =>
        getHeatmapBucketByValue({
          data,
          buckets: bucketsMap,
          metricDescriptor,
          rangeDescriptor,
        }),
      )
      .compact()
      .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
      .sortBy(b => -b.index)
      .map(p => p.color)
      .uniq()
      .value();
    return _.isEmpty(colors) ? null : colors;
  }

  return (
    <BayLocationLayer
      stageId="simulation-bay-view"
      getGradientColors={getGradientColors}
    />
  );

  // const heatmapType = useRecoilValue(simulationLayoutHeatmapType);
  // const heatmapFilter = useRecoilValue(simulationLayoutHeatmapFilters);
  // const maxVisits = useRecoilValue(simulationMaxLocationVisits);
  // const maxAppliedReorders = useRecoilValue(simulationMaxAppliedReorders);
  // const maxTriggeredReorders = useRecoilValue(simulationMaxTriggeredReorders);

  // if (_.isNil(heatmapByLoc)) return null;

  // const visitsBuckets = getVisitsHeatmapBuckets(maxVisits);
  // const appliedReordersBuckets =
  //   getReorderingHeatmapBuckets(maxAppliedReorders);
  // const triggeredReordersBuckets =
  //   getReorderingHeatmapBuckets(maxTriggeredReorders);

  // const filterByCustomBucket: (
  //   location: LayoutBayLocationFragment,
  // ) => boolean = l => {
  //   const products = getActiveProducts(heatmapByLoc[l.locationId]);
  //   const hiddenDisabled =
  //     heatmapFilter?.hideDisabled === true && !l.locationStatus;

  //   const hiddenEmpty =
  //     _.isEmpty(products) && heatmapFilter?.hideEmpty === true;

  //   const hideZeroVisits =
  //     heatmapFilter?.hideZeros === true && getVisitsCount(products) === 0;

  //   return !hiddenDisabled && !hiddenEmpty && !hideZeroVisits;
  // };

  // const filters: ((location: LayoutBayLocationFragment) => boolean)[] =
  //   _.compact([props.filter, filterByCustomBucket]);

  // const getLabel: (location: LayoutBayLocationFragment) => string = loc => {
  //   const currentProductsCount = _.size(
  //     getActiveProducts(heatmapByLoc?.[loc.locationId]),
  //   );
  //   return currentProductsCount > 1
  //     ? formatInteger(currentProductsCount)
  //     : null;
  // };

  // const layerProps: BayLocationLayerProps = {
  //   getLabel,
  //   // filter: l => _.every(filters, f => f(l)),
  // };

  // switch (heatmapType) {
  //   case 'visits':
  //     layerProps.getColor = l =>
  //       getVisitsColor(heatmapByLoc[l.locationId], maxVisits, visitsBuckets);
  //     //
  //     filters.push(l =>
  //       getVisibleByVisits(
  //         heatmapByLoc[l.locationId],
  //         maxVisits,
  //         visitsBuckets,
  //         heatmapFilter.hiddenBuckets,
  //       ),
  //     );
  //     break;

  //   case 'abc':
  //     layerProps.getGradientColors = l =>
  //       getABCGradientColors(
  //         heatmapByLoc[l.locationId],
  //         heatmapFilter.hiddenBuckets,
  //       );
  //     //
  //     filters.push(l =>
  //       getVisibleByABC(
  //         heatmapByLoc[l.locationId],
  //         heatmapFilter.hiddenBuckets,
  //       ),
  //     );
  //     break;

  //   case 'applied-reorder':
  //     layerProps.getGradientColors = l =>
  //       getAppliedReorderingGradientColors(
  //         heatmapByLoc[l.locationId],
  //         maxAppliedReorders,
  //         appliedReordersBuckets,
  //         heatmapFilter.hiddenBuckets,
  //       );
  //     //
  //     filters.push(l =>
  //       getVisibleByAppliedReordering(
  //         heatmapByLoc[l.locationId],
  //         maxAppliedReorders,
  //         appliedReordersBuckets,
  //         heatmapFilter.hiddenBuckets,
  //       ),
  //     );
  //     break;

  //   case 'triggered-reorder':
  //     layerProps.getGradientColors = l =>
  //       getTriggeredReorderingGradientColors(
  //         heatmapByLoc[l.locationId],
  //         maxTriggeredReorders,
  //         triggeredReordersBuckets,
  //         heatmapFilter.hiddenBuckets,
  //       );
  //     //
  //     filters.push(l =>
  //       getVisibleByTriggeredReordering(
  //         heatmapByLoc[l.locationId],
  //         maxTriggeredReorders,
  //         triggeredReordersBuckets,
  //         heatmapFilter.hiddenBuckets,
  //       ),
  //     );
  //     break;
  // }
  // return <BayLocationLayer stageId="simulation-bay-view" {...layerProps} />;
};

export default SimulationBayLocationLayerBase;
