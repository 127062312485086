import * as React from 'react';
const SimulationVisualizeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationVisualizeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4.98,55.26l54.8,31.04c-3.52-1.86-4.61-7.13-.09-11.22,2.17-1.97,4.09-1.23,2.97-1.86l-46.41-26.01c-13.01-8.26-17.54,4.64-11.28,8.05Z"
    />
    <polygon
      strokeWidth={0}
      points="1.05 63.59 6.78 60.26 61.92 92.1 65.31 92.1 120.09 60.26 125.89 63.61 63.7 99.76 1.05 63.59"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="1.05 75.6 6.78 72.27 61.92 104.11 65.31 104.11 120.09 72.27 125.89 75.62 63.7 111.77 1.05 75.6"
      opacity={0.75}
    />
    <polygon
      strokeWidth={0}
      points="1.05 87.61 6.78 84.28 61.92 116.12 65.31 116.12 120.09 84.28 125.89 87.63 63.7 123.78 1.05 87.61"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="59.41 39.65 52.15 52.67 67.84 61.5 92.9 46.6 77.22 37.78 59.41 39.65"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M121.36,41.3c-8.87,3.59-45.1-17.05-49-23.84l-53.42,29.45,49.42,27.89c1.12.63-3-1.14-5.37.58-8.12,5.9-3.46,15.45,9.09,8.79l38.77-22.32c4.99-3.38,15.89-10.63,10.51-20.55ZM69.84,65.4l-23.87-12.23,9.25-16.59,22.69-2.39,23.85,12.22-31.93,18.98Z"
    />
  </svg>
);
export default SimulationVisualizeIcon;
