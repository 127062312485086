import {
  LayoutBayLocationFragment,
  LayoutLocationWithMatchingFiltersFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../../Tw';
import {
  getQualitativeColor,
  manipulateColor,
} from '../../../common/color.helper';
import useGetNamedColor from '../../../common/useGetNamedColor';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { loadBalancingPolicyBayLocationByRule } from '../../../loadBalancingPolicy/loadBalancingPolicy.state';
import { swapPolicyBayLocationByRule } from '../../../swapPolicy/swapPolicy.state';
import { AP_FALLBACK_RULE_ID } from '../../store/assignmentPolicy.default';
import {
  assignmentPolicyBayLocations,
  assignmentPolicyDocument,
  assignmentPolicySelectedIdentity,
} from '../../store/assignmentPolicy.state';
import { pickingPolicyBayLocationByRule } from '../../store/pickingPolicy/pickingPolicy.state';
import {
  simulationOptimisationPolicyTabKey,
  simulationWizardSelectedStepId,
} from '../../store/simulation.wizard.state';
import { STACKING_POLICY_DEFAULT_RULE_ID } from '../../store/stackingPolicy/stackingPolicy.default';
import {
  stackingPolicyBayLocations,
  stackingPolicySelectedIdentity,
} from '../../store/stackingPolicy/stackingPolicy.state';
import { StackingPolicyItem } from '../../store/stackingPolicy/stackingPolicy.types';

const locationColor = TwTheme.extend.colors.location;
const opacity = '66';

const PolicyBayLocationLayer: React.FC = () => {
  const stepId = useRecoilValue(simulationWizardSelectedStepId);
  const pickingPolicyLocationLoadable = useRecoilValueLoadable(
    pickingPolicyBayLocationByRule,
  );
  const swapPolicyLocationLoadable = useRecoilValueLoadable(
    swapPolicyBayLocationByRule,
  );
  const balancingPolicyLocationLoadable = useRecoilValueLoadable(
    loadBalancingPolicyBayLocationByRule,
  );

  const assignmentPolicyLocationLoadable = useRecoilValueLoadable(
    assignmentPolicyBayLocations,
  );

  const stackingPolicyLocationLoadable = useRecoilValueLoadable(
    stackingPolicyBayLocations,
  );
  const stackingPolicySelected = useRecoilValue(stackingPolicySelectedIdentity);
  const assignmentPolicySelected = useRecoilValue(
    assignmentPolicySelectedIdentity,
  );
  const optimisationTab = useRecoilValue(simulationOptimisationPolicyTabKey);

  const assignmentPolicy = useRecoilValue(assignmentPolicyDocument);
  const getNamedColor = useGetNamedColor();

  let getColor: (loc: LayoutBayLocationFragment) => [string, string] | null;
  let getGradientColors: (loc: LayoutBayLocationFragment) => string[];

  if (
    stepId === 'policy-picking' &&
    pickingPolicyLocationLoadable.state === 'hasValue'
  ) {
    getColor = (loc: LayoutBayLocationFragment) =>
      pickingPolicyLocationLoadable.contents?.has(loc.locationId)
        ? [locationColor.selected, null]
        : null;
  }

  if (
    stepId === 'optimise' &&
    optimisationTab === 'tab-optimisation-policy-swap' &&
    swapPolicyLocationLoadable.state === 'hasValue'
  ) {
    getColor = (loc: LayoutBayLocationFragment) =>
      swapPolicyLocationLoadable.contents?.has(loc.locationId)
        ? [locationColor.selected, null]
        : null;
  }
  if (
    stepId === 'optimise' &&
    optimisationTab === 'tab-optimisation-policy-load-balancing' &&
    balancingPolicyLocationLoadable.state === 'hasValue'
  ) {
    const balancingSummary = balancingPolicyLocationLoadable.contents;
    if (!_.isEmpty(balancingSummary?.zones)) {
      const zoneDictionary = _.reduce(
        balancingSummary?.zones,
        (zones, z) => ({
          ...zones,
          ..._.reduce(
            z.locationIds,
            (acc, l) => ({ ...acc, [l]: z.zoneId }),
            {},
          ),
        }),
        {},
      );
      getColor = (loc: LayoutBayLocationFragment) =>
        _.isNil(zoneDictionary[loc.locationId])
          ? null
          : getQualitativeColor(zoneDictionary[loc.locationId], 'policy');
    }
  }

  if (
    stepId === 'policy-storage' &&
    assignmentPolicyLocationLoadable.state === 'hasValue'
  ) {
    const locationMap: Record<
      string,
      LayoutLocationWithMatchingFiltersFragment
    > = assignmentPolicyLocationLoadable.contents;
    getGradientColors = (loc: LayoutBayLocationFragment): string[] => {
      const ruleIndexes = locationMap[loc.locationId]?.allMatchingFilters;

      if (_.isEmpty(ruleIndexes)) return null;

      const colors = _(ruleIndexes)
        .map(i => {
          const rule = assignmentPolicy?.rules[i];
          const ruleColor = getNamedColor(
            rule?.title ?? AP_FALLBACK_RULE_ID,
          )?.[0];
          const isSelected = rule?.id === assignmentPolicySelected?.ruleId;
          const applyOpacity =
            !_.isNil(assignmentPolicySelected?.ruleId) && !isSelected;
          return applyOpacity
            ? manipulateColor(ruleColor, {
                darkness: 1.5,
                saturation: 0.15,
              })
            : ruleColor;
        })
        .compact()
        .value();
      return _.isEmpty(colors) ? null : colors;
    };
  }

  if (
    stepId === 'policy-stacking' &&
    stackingPolicyLocationLoadable.state === 'hasValue'
  ) {
    const locationMap: Record<string, StackingPolicyItem[]> =
      stackingPolicyLocationLoadable.contents;

    getGradientColors = (loc: LayoutBayLocationFragment): string[] => {
      let colors = _(locationMap[loc.locationId])
        .map(item => item.color)
        .compact()
        .value();

      const isSelected = _.some(
        locationMap[loc.locationId],
        item =>
          (item.policyRule?.id ?? STACKING_POLICY_DEFAULT_RULE_ID) ===
          stackingPolicySelected?.ruleId,
      );

      const needApplyOpacity =
        !_.isNil(stackingPolicySelected?.ruleId) && !isSelected;

      if (needApplyOpacity) {
        colors = _.map(colors, c =>
          manipulateColor(c, {
            darkness: 1.5,
            saturation: 0.15,
          }),
        );
      }
      return _.isEmpty(colors) ? null : colors;
    };
  }

  return (
    <BayLocationLayer
      stageId="simulation-bay-view"
      getColor={getColor}
      getGradientColors={getGradientColors}
    />
  );
};

export default PolicyBayLocationLayer;
