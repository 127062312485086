import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  Filter,
  FilterIntersection,
  FilterUnion,
  FilterBase,
} from './filter.model';

export enum ItemFilterType {
  // Item match filters
  CONSIGNEE = 'CONSIGNEE',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  // Item range filters
  NET_WEIGHT = 'NET_WEIGHT',
  WIDTH = 'WIDTH',
  LENGTH = 'LENGTH',
  HEIGHT = 'HEIGHT',
  VOLUME = 'VOLUME',
}

registerEnumType(ItemFilterType, {
  name: 'ItemFilterType',
});

@InputAndObjectType()
export class ItemFilter extends FilterBase implements Filter<ItemFilterType> {
  @Field(() => ItemFilterType)
  type: ItemFilterType;
}

@InputAndObjectType()
export class ItemFilterIntersection implements FilterIntersection<ItemFilter> {
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field(() => [ItemFilter])
  allOf: ItemFilter[];
}

@InputAndObjectType()
export class ItemFilterUnion implements FilterUnion<ItemFilter> {
  @Field(() => [ItemFilterIntersection])
  anyOf: ItemFilterIntersection[];
}
