import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import SimulationHeader from '../simulation/SimulationHeader';
import OptimisationImplementPanel from '../simulation/panels/implement/OptimisationImplementPanel';
import {
  simulationCurrent,
  simulationLoadStatus,
} from '../simulation/store/simulation.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import { useLoadSimulation } from '../warehouse/hooks/useLoadSimulation';

export type OptimizationImplementParams = {
  simulationId: string;
};

export type OptimizationImplementProps = {
  simulationId?: string;
  hasControls?: boolean;
};

const OptimizationImplement: React.FC<OptimizationImplementProps> = props => {
  const { simulationId: simulationIdParams } =
    useParams<OptimizationImplementParams>();
  const simulationId = props.simulationId ?? simulationIdParams;
  const whId = useRecoilValue(warehouseSelectedId);
  const [loadSimulation, cleanup] = useLoadSimulation();
  const simLoadStatus = useRecoilValue(simulationLoadStatus);
  const sim = useRecoilValue(simulationCurrent);
  const { t } = useTranslation('simulation');

  useEffect(() => {
    loadSimulation(whId, simulationId);
    return () => {
      cleanup();
    };
  }, [simulationId]);

  console.log(sim);
  if (
    simLoadStatus === AsyncLoadStatus.None ||
    simLoadStatus === AsyncLoadStatus.Loading
  ) {
    return (
      <>
        <SimulationHeader />
        <ContainerMain>
          <LoadingIndicator selfCenter message={t`Loading simulation`} />
        </ContainerMain>
      </>
    );
  }

  if (_.isNil(sim?.optimizationRuns?.content?.[0]?.id)) {
    return (
      <>
        <SimulationHeader />
        <ContainerMain>
          <InboxZero selfCenter hasIcon message={t`Optimisation not found`} />;
        </ContainerMain>
      </>
    );
  }

  return (
    <>
      <SimulationHeader />
      <ContainerMain>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorIndicator
              selfCenter
              message={error.message}
              hasReloadButton
              exception={error}
            />
          )}
        >
          <Suspense
            fallback={
              <Container hasOverflowY flex1 col>
                <PanelHeader isLoading title={t`Loading Layout...`} />
                <LoadingIndicator
                  className={classNames(
                    'bg-map-area-striped',
                    'border-8 border-app-background',
                  )}
                  selfCenter
                  message={t`Loading Layout`}
                />
              </Container>
            }
          >
            <div className="flex flex-col flex-1">
              <OptimisationImplementPanel
                hasControls={props.hasControls}
                optimisationId={sim?.optimizationRuns?.content?.[0]?.id}
              />
            </div>
          </Suspense>
        </ErrorBoundary>
      </ContainerMain>
    </>
  );
};

export default OptimizationImplement;
