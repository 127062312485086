import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';
import { LocationFilterUnion } from '../location-filter.model';

@InputAndObjectType()
export class WaypointPolicyRuleTerminalPoints {
  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;
}

@InputAndObjectType()
export class WaypointPolicyRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => WaypointPolicyRuleTerminalPoints, { nullable: true })
  startPoints?: WaypointPolicyRuleTerminalPoints;

  @Field(() => WaypointPolicyRuleTerminalPoints, { nullable: true })
  endPoints?: WaypointPolicyRuleTerminalPoints;
}

@InputAndObjectType()
export class WaypointPolicy {
  @Field(() => [WaypointPolicyRule], { nullable: true })
  rules?: WaypointPolicyRule[];
}
