import * as React from 'react';
const MinusIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MinusIcon"
    {...props}
  >
    <rect width={100} height={9.52} x={14} y={59.24} strokeWidth={0} />
  </svg>
);
export default MinusIcon;
