import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { Loadable, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { getHeatmapBucketByValue } from '../../common/heatmap.helper';
import {
  actualityHeatmapBuckets,
  actualityHeatmapFilters,
  actualityHeatmapRangeDescriptor,
  actualityMetricDescriptor,
} from '../store/actuality.heatmap.state';
import { actualityHeatmapTypeSelected } from '../store/actuality.state';
import {
  assignmentHeatmapBuckets,
  assignmentHeatmapRangeDescriptor,
  assignmentMetricDescriptor,
} from '../store/assignment.heatmap.state';
import { ActualityHeatmapDataRow } from '../store/datasetQueries/actualityHeatmapData';
import { getItemKey } from '../store/feed.helper';
import { feedItemsWithRank } from '../store/feed.state';

export type FeedLocationColorOut = [
  getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null,
  getGradientColors: (loc: LayoutLevelLocationFragment) => string[],
];

export type ActualityLocationColorParams = {
  dataLoadable: Loadable<ActualityHeatmapDataRow[]>;
};

const useActualityLocationColor = (
  params: ActualityLocationColorParams,
): FeedLocationColorOut => {
  const heatmapDataLoadable = params.dataLoadable;

  const heatmapType = useRecoilValue(actualityHeatmapTypeSelected);

  const eventsMetricDescriptor = useRecoilValue(actualityMetricDescriptor);
  const asgmntMetricDescriptor = useRecoilValue(assignmentMetricDescriptor);

  const eventsRangeDescriptorLoadable = useRecoilValueLoadable(
    actualityHeatmapRangeDescriptor,
  );
  const eventsHeatmapBucketsLoadable = useRecoilValueLoadable(
    actualityHeatmapBuckets,
  );

  const assignmentRangeDescriptorLoadable = useRecoilValueLoadable(
    assignmentHeatmapRangeDescriptor,
  );
  const assignmentHeatmapBucketsLoadable = useRecoilValueLoadable(
    assignmentHeatmapBuckets,
  );

  const heatmapFilter = useRecoilValue(actualityHeatmapFilters);
  const itemsWithRankLoadable = useRecoilValueLoadable(feedItemsWithRank);

  const metricDescriptor =
    heatmapType === 'events' ? eventsMetricDescriptor : asgmntMetricDescriptor;

  const rangeDescriptorLoadable =
    heatmapType === 'events'
      ? eventsRangeDescriptorLoadable
      : assignmentRangeDescriptorLoadable;
  const heatmapBucketsLoadable =
    heatmapType === 'events'
      ? eventsHeatmapBucketsLoadable
      : assignmentHeatmapBucketsLoadable;

  let getColor: (loc: LayoutLevelLocationFragment) => [string, string] | null;
  let getLabel: (loc: LayoutLevelLocationFragment) => string;
  let getGradientColors: (loc: LayoutLevelLocationFragment) => string[];

  const hasAllHeatmapData =
    heatmapDataLoadable.state === 'hasValue' &&
    heatmapBucketsLoadable.state === 'hasValue' &&
    itemsWithRankLoadable.state === 'hasValue' &&
    rangeDescriptorLoadable.state === 'hasValue';

  const heatmapByLoc: ActualityHeatmapDataRow[] = heatmapDataLoadable.contents;
  const buckets = heatmapBucketsLoadable.contents;
  const itemsWithRank = itemsWithRankLoadable.contents;
  const rangeDescriptor = rangeDescriptorLoadable.contents;

  const bucketsMap = _.keyBy(buckets, b => b.id);
  const itemsDictionary = _.keyBy(itemsWithRank, item => getItemKey(item));

  const locations = _.groupBy(heatmapByLoc, row => row.locationId);

  if (hasAllHeatmapData) {
    getGradientColors = (loc: LayoutLevelLocationFragment): string[] => {
      const locationRows = locations[loc.locationId];
      const colors = _(locationRows)
        .map(data => {
          return getHeatmapBucketByValue({
            data: {
              [metricDescriptor.path]: data.dimensionValue,
            },
            buckets: bucketsMap,
            metricDescriptor: metricDescriptor as any,
            rangeDescriptor,
          });
        })
        .compact()
        .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
        .sortBy(b => -b.index)
        .map(p => p.color)
        .uniq()
        .value();
      return _.isEmpty(colors) ? null : colors;
    };
  }

  return [getColor, getGradientColors];
};

export default useActualityLocationColor;
