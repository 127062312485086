import React from 'react';
import { useRecoilState } from 'recoil';
import { createFilterTagData, TableFilter } from '../components/TableFilter';
import { layoutItemsDataState } from './store/layout.state';
import useLayoutDataTableConfig from './useLayoutDataTableConfig';

const LayoutFilterTag: React.FC = () => {
  const [dataState, setDataState] = useRecoilState(layoutItemsDataState);
  const columnsConfig = useLayoutDataTableConfig();
  return (
    <TableFilter
      filterData={createFilterTagData(dataState.searchValues, columnsConfig)}
      onRemoveClick={() => setDataState({ ...dataState, searchValues: {} })}
    />
  );
};

export default LayoutFilterTag;
