import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ACTUALITY_ANALYZE_STATUS_REFRESH_INTERVAL } from '../../common/constants';
import { actualityStatusUpdateTime } from '../store/actuality.state';

function useActualityStatusWatcher() {
  const [timeUpdated, setTimeUpdated] = useRecoilState(
    actualityStatusUpdateTime,
  );

  useEffect(() => {
    const timeoutId = setInterval(
      () => setTimeUpdated(new Date()),

      ACTUALITY_ANALYZE_STATUS_REFRESH_INTERVAL,
    );

    return () => {
      // console.log('Cleanup: Cancelling event time watcher');
      clearTimeout(timeoutId);
    };
  }, [timeUpdated]);

  return null;
}

export default useActualityStatusWatcher;
