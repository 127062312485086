import * as React from 'react';
const PolicyComplianceAssignmentArrowIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceAssignmentArrowIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM109.37,70.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z"
    />
    <path
      strokeWidth={0}
      d="M29.88,65.08v-5.53s15.43,0,15.43,0c4.09,0,7.94-1.6,10.85-4.52,2.91-2.91,4.52-6.77,4.52-10.85v-4.85s-7.61,7.6-7.61,7.6v-7.82s10.38-10.37,10.38-10.37l10.37,10.37v7.82s-7.61-7.6-7.61-7.6v4.85c0,5.56-2.18,10.81-6.14,14.77-3.96,3.96-9.2,6.14-14.77,6.14h-15.43ZM73.82,85.5l-10.37,10.37-10.38-10.37v-7.82s7.61,7.6,7.61,7.6v-4.85c0-4.09-1.6-7.94-4.52-10.85-1.19-1.19-2.53-2.15-3.98-2.89,2.09-.64,4.07-1.57,5.91-2.77.69.54,1.35,1.12,1.98,1.74,3.96,3.96,6.14,9.2,6.14,14.77v4.85s7.61-7.6,7.61-7.6v7.82ZM78.83,72.69l7.6-7.61h-20.14s-3.07-2.77-3.07-2.77l3.07-2.77h20.14s-7.6-7.61-7.6-7.61h7.82s10.37,10.38,10.37,10.38l-10.37,10.37h-7.82Z"
    />
  </svg>
);
export default PolicyComplianceAssignmentArrowIcon;
