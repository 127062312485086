import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import LayoutDataTable from './LayoutDataTable';
import LayoutFilterTag from './LayoutFilterTag';
import { layoutDocument } from './store/layout.state';

const LayoutDataView: React.FC = () => {
  const { t } = useTranslation('dataset');
  const layoutDoc = useRecoilValue(layoutDocument);

  return (
    <Container
      col
      flex1
      componentName="LayoutDataView"
      className={classNames(
        'border-app-panel-dark relative ltr:border-l rtl:border-r',
      )}
    >
      <PanelHeader
        title={t`Layout Data Table`}
        filterPlaceholder={<LayoutFilterTag />}
      />
      <LayoutDataTable id={layoutDoc?.id} title={layoutDoc?.title} />
    </Container>
  );
};

export default LayoutDataView;
