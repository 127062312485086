import Flatten from '@flatten-js/core';
import {
  AreaLink,
  AreaLinkPointType,
  areaLinkPointTypes,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import ObjectSize from '../../../../components/designer/panels/ObjectSize';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import TitleSection from '../../../../components/layout/TitleSection';
import PanelContainer from '../../../../containers/PanelContainer';
import { warehouseMeasurementSystem } from '../../../../store/warehouse.state';
import {
  converterAreaConfiguration,
  converterSelectedArea,
} from '../store/converter.area.state';
import {
  getReverseTransformedAreaBox,
  getTransformedAreaBox,
} from '../store/transformation.helper';
import ObjectLinkVisualisation from './ObjectLinkVisualisation';

const defaultLink: AreaLink = {
  areaId: null,
  masterAreaId: null,
  masterLinkPointType: 'bottomRight',
  linkPointType: 'bottomLeft',
  linkOffsetX: 0,
  linkOffsetY: 0,
};

const AreaSummary: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const area = useRecoilValue(converterSelectedArea);
  const [areaConfigAll, setAreaConfigAll] = useRecoilState(
    converterAreaConfiguration,
  );

  if (_.isNil(area)) {
    return null;
  }

  const areaConfig = areaConfigAll[area.id];
  const box = getTransformedAreaBox(area, areaConfig);

  const w = parseFloat(box.width.toPrecision(7));
  const l = parseFloat(box.height.toPrecision(7));

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(ms, 'size'),
    tMeasures,
  );

  function update(dx: number, dy: number) {
    const offset = new Flatten.Point(dx, dy);
    const trBox = getReverseTransformedAreaBox(box, areaConfig, offset);

    console.log(trBox);
    setAreaConfigAll({
      ...areaConfigAll,
      [area.id]: {
        ...areaConfig,
        x: trBox.xmin,
        y: trBox.ymin,
      },
    });
  }

  function updateLink(patch: Partial<AreaLink>) {
    setAreaConfigAll({
      ...areaConfigAll,
      [area.id]: {
        ...areaConfig,
        link: _.isNil(patch)
          ? null
          : {
              ...areaConfig.link,
              ...patch,
            },
      },
    });
  }

  const areasIdToLink = _(areaConfigAll)
    .filter(
      a =>
        a.id !== areaConfig.id && !a.isDeleted && a.floor === areaConfig.floor,
    )
    .map(a => a.id)
    .value();

  type LinkTypeDescription = {
    id: AreaLinkPointType;
    title: string;
    icon?: React.ReactNode;
  };

  const linkTypeOptions: LinkTypeDescription[] = [
    {
      id: 'bottomLeft',
      title: t`Bottom left`,
    },
    {
      id: 'bottomRight',
      title: t`Bottom right`,
    },
    {
      id: 'topLeft',
      title: t`Top left`,
    },
    {
      id: 'topRight',
      title: t`Top Right`,
    },
  ];

  const linkTypeOptionsMap = _.keyBy(linkTypeOptions, l => l.id);
  return (
    <>
      <WarehouseNavigator>
        <WarehouseNavigatorItem
          name={t`Area`}
          value={area.title ?? area.id}
          active
        />
      </WarehouseNavigator>
      <ContainerScroll>
        <PanelContainer
          id="panel-object-area-property"
          title={t`Area Properties`}
          collapsible
          hasPadding
        >
          <TitleSection inPanelView title={t`Size`} className="mb-1" />
          <ObjectSize
            title={t`Size and Position`}
            object={area.id}
            unitMeasurement={sizeMeasureValue}
            hasSize
            sizeWidth={w}
            sizeLength={l}
            // hasPosition
            // positionX={areaConfig.x}
            // positionY={areaConfig.y}
            disabled
          />

          <TitleSection inPanelView title={t`Position`} className="mb-1" />
          <InputGroupList hasPadding panelMode className="space-y-1">
            <InputNumber
              key={`area-position-x-${area.id}`}
              title={t`Position X`}
              value={box.xmin}
              //unitOfMeasure={sizeMeasureValue}
              onChange={v => update(v - box.xmin, 0)}
              debounceInterval={100}
            />
            <InputNumber
              key={`area-position-y-${area.id}`}
              title={t`Position Y`}
              value={box.ymin}
              //unitOfMeasure={sizeMeasureValue}
              onChange={v => update(0, v - box.ymin)}
              debounceInterval={100}
            />
          </InputGroupList>
        </PanelContainer>
        <PanelContainer
          id="panel-object-area-links"
          title={t`Area Links`}
          collapsible
          hasPadding
        >
          <InputGroupList hasPadding panelMode>
            <DropdownSelector
              panelMode
              panelModeMultiline
              widthFull
              value={areaConfig.link?.masterAreaId ?? null}
              values={[null, ...areasIdToLink]}
              renderValue={i => i ?? t`No link`}
              label={t`Link to`}
              icon={
                <Icon.DesignerAreaLocked className="h-8 w-8 fill-current" />
              }
              onChange={targetId => {
                updateLink(
                  _.isNil(targetId)
                    ? null
                    : {
                        ...defaultLink,
                        areaId: area.id,
                        masterAreaId: targetId,
                      },
                );
              }}
              hasSearch
              hasSearchLabel={t`Areas`}
            />
            {!_.isNil(areaConfig.link?.masterAreaId) && (
              <InputGroupList hasPadding panelMode className="space-y-2">
                <TitleSection inPanelView title={t`Current`} />
                <ObjectLinkVisualisation
                  currentTitle={areaConfig.link?.masterAreaId ?? t`Current`}
                  currentLinkPoint={areaConfig.link.masterLinkPointType}
                  linkedTitle={areaConfig.link?.areaId ?? t`Linked`}
                  linkedLinkPoint={areaConfig.link.linkPointType}
                  onCurrentLinkPointChange={point =>
                    updateLink({ masterLinkPointType: point })
                  }
                  onLinkedLinkPointChange={point =>
                    updateLink({ linkPointType: point })
                  }
                />

                <DropdownSelector
                  panelMode
                  widthFull
                  value={areaConfig.link?.linkPointType}
                  values={[...areaLinkPointTypes]}
                  renderValue={v => linkTypeOptionsMap[v].title}
                  label={t`Point in current area`}
                  onChange={v => updateLink({ linkPointType: v })}
                  panelModeMultiline
                />
                <TitleSection inPanelView title={t`Linked`} />
                <DropdownSelector
                  panelMode
                  widthFull
                  value={areaConfig.link?.masterLinkPointType}
                  values={[...areaLinkPointTypes]}
                  renderValue={v => linkTypeOptionsMap[v].title}
                  label={t`Point in linked area`}
                  onChange={v => updateLink({ masterLinkPointType: v })}
                  panelModeMultiline
                />
                <InputNumber
                  title={t`Offset X`}
                  value={areaConfig.link?.linkOffsetX ?? 0}
                  unitOfMeasure={sizeMeasureValue}
                  onChange={v => updateLink({ linkOffsetX: v })}
                  debounceInterval={100}
                />
                <InputNumber
                  title={t`Offset Y`}
                  value={areaConfig.link?.linkOffsetY ?? 0}
                  unitOfMeasure={sizeMeasureValue}
                  onChange={v => updateLink({ linkOffsetY: v })}
                  debounceInterval={100}
                />
              </InputGroupList>
            )}
          </InputGroupList>
        </PanelContainer>
      </ContainerScroll>
    </>
  );
};

export default AreaSummary;
