import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { Circle, Group, Line, RegularPolygon, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';
import { analyticsAgent } from '../../AnalyticTracker';
import {
  getBestContrastColor,
  manipulateColor,
} from '../../common/color.helper';
import { getBox, getPoints } from '../viewer/store/viewer.helper';

const locationColors = TwTheme.extend.colors.location;

export type LocationProps = {
  loc: LayoutLevelLocationFragment;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  color?: [string, string];
  gradientColors?: string[];
  labelColor?: [string, string];
  label?: string;
  title?: string;
  isStaticMode?: boolean;
  isCompliance?: boolean;
  opacity?: number;
};

const LocationFeature: React.FC<LocationProps> = props => {
  const { loc: location } = props;

  function onLocationClick(
    evt: Konva.KonvaEventObject<MouseEvent | TouchEvent>,
  ) {
    analyticsAgent?.track('Location: Selected (Click)', {
      id: location.locationId,
      viewer: 'main',
    });
    props.onClick && props.onClick();
    evt.cancelBubble = true;
  }

  const box = getBox(location.shape);
  const centerX = box?.xmin + (box?.xmax - box?.xmin) / 2;
  const centerY = box?.ymin + (box?.ymax - box?.ymin) / 2;

  let bgColor = props.color?.[0] ?? locationColors.DEFAULT;

  if (!location.locationStatus) {
    bgColor = manipulateColor(bgColor, {
      darkness: 0.05,
      // saturation: 0.2,
      opacity: 0.75,
    });
  }

  const titleColorLocation = getBestContrastColor(bgColor);
  // const titleColor = props.color?.[1] ?? locationColors.title;

  const baseConfig: Konva.NodeConfig = {
    draggable: false,
    strokeScaleEnabled: false,
    perfectDrawEnabled: true,
    closed: true,
    listening: !props.isStaticMode,
    id: location.locationId,
    fill: _.isNil(props.gradientColors) ? bgColor : null,
    opacity: 1,
  };

  let gradientConfig: Partial<Konva.LineConfig> = {};
  if (!_.isEmpty(props.gradientColors)) {
    const total = _.size(props.gradientColors);
    const stopColors = _(props.gradientColors)
      .compact()
      .map(c =>
        location.locationStatus
          ? c
          : manipulateColor(c, {
              darkness: 0.05,
              // saturation: 0.2,
              opacity: 0.7,
            }),
      ) //getColorDarker(c)))
      .map((c, index) => [index / total, c, (index + 1) / total, c])
      .flatten()
      .value();
    gradientConfig = {
      fillLinearGradientStartPoint: { x: box.xmin, y: box.ymin },
      fillLinearGradientEndPoint: { x: box.xmax, y: box.ymax },
      fillLinearGradientColorStops: stopColors,
    };
  }
  const config: Konva.LineConfig = {
    ...baseConfig,
    ...gradientConfig,
    points: _.flatten(getPoints(location.shape)),
    strokeEnabled: false,
    hitStrokeWidth: 0,
    shadowForStrokeEnabled: false,
  };

  const showLabels = !_.isNil(props.label) || !_.isNil(props.labelColor);
  const showLabelText = !_.isNil(props.label);
  const showTitle = !_.isNil(props.title);

  const boxWidth = box?.xmax - box?.xmin;
  const boxHeight = box?.ymax - box?.ymin;
  const captionCharLength = props.title?.length || 10;
  // const fontSize = Math.min(
  //   Math.floor(0.9 * boxHeight),
  //   Math.floor((boxWidth / captionLength) * 0.9),
  // );

  const titleFontSize =
    boxWidth < 24
      ? 2
      : boxWidth < 25
        ? 3
        : boxWidth < 31
          ? 5
          : boxWidth < 59
            ? 6
            : boxWidth < 79
              ? 8
              : boxWidth > 1000 // Huge Locations
                ? 80
                : captionCharLength > 11 // Larger than 11 chars
                  ? 10
                  : 12;

  const titleTextOffsetY =
    boxWidth < 25 ? 7 : boxWidth < 79 ? 10 : boxWidth > 1000 ? 160 : 20;
  const titleTextOffsetX =
    boxWidth < 25 ? -1 : boxWidth < 79 ? -4 : boxWidth > 1000 ? -80 : -10;

  const labelSizeRadius =
    boxWidth < 24
      ? 8
      : boxWidth < 79
        ? 12
        : boxWidth > 1000 // Huge Locations
          ? 55
          : 25;

  const labelFontSize =
    boxWidth < 24
      ? 8
      : boxWidth < 79
        ? 12
        : boxWidth > 1000 // Huge Locations
          ? 55
          : 26;

  const labelBackgroundColor = props.labelColor?.[0] ?? 'rgba(0,0,0,0.7)';
  const labelFontColor = props.labelColor?.[1] ?? 'rgba(255,255,255,1)';

  return (
    <Group>
      <Line
        key={`location-feature-${location.locationId}`}
        {...config}
        onClick={onLocationClick}
        onTap={onLocationClick}
        onMouseEnter={e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
          props.onMouseOver && props.onMouseOver();
          e.cancelBubble = true;
          e.evt.stopPropagation();
        }}
        onMouseLeave={e => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
          props.onMouseOut && props.onMouseOut();
          e.cancelBubble = true;
          e.evt.stopPropagation();
        }}
      />
      {showTitle && (
        <Text
          x={box.xmin}
          y={box.ymin}
          width={boxWidth}
          height={boxHeight}
          fontSize={titleFontSize}
          align="left"
          verticalAlign="top"
          offsetY={titleTextOffsetY}
          offsetX={titleTextOffsetX}
          fill={titleColorLocation}
          scaleY={-1}
          text={props.title}
          listening={false}
          strokeEnabled={false}
          fontFamily={'NeoSans-Regular'}
        />
      )}
      {showLabels && (
        <Group listening={false}>
          {props.isCompliance ? (
            <RegularPolygon
              x={centerX}
              y={centerY}
              fill={labelBackgroundColor}
              stroke={'#000000'}
              strokeWidth={6}
              radius={labelSizeRadius}
              sides={3}
              rotation={180}
              listening={false}
            />
          ) : (
            <Circle
              x={centerX}
              y={centerY}
              radius={labelSizeRadius}
              fill={labelBackgroundColor}
              listening={false}
            />
          )}

          {showLabelText && (
            <Text
              x={box.xmin}
              y={box.ymin}
              width={box.xmax - box.xmin}
              height={box.ymin - box.ymax}
              fontSize={labelFontSize}
              align="center"
              verticalAlign="middle"
              fill={labelFontColor}
              scaleY={-1}
              text={props.label}
              listening={false}
              strokeEnabled={false}
            />
          )}
        </Group>
      )}
    </Group>
  );
};

export default React.memo(
  LocationFeature,
  (prev, next) =>
    prev.loc?.locationId === next.loc?.locationId &&
    prev.color === next.color &&
    prev.gradientColors === next.gradientColors &&
    prev.title === next.title,
);
