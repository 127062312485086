import Flatten, { LoopOfShapes } from '@flatten-js/core';
import {
  AisleFeatureMetaFragment,
  LayoutPLaneFragment,
  LocationPortalType,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import naturalCompare from 'string-natural-compare';
import { LayoutPlane, PlaneStats } from './layout.types';

export function getPortalTypeDictionary(
  t: TFunction<'app'>,
): Record<LocationPortalType, string> {
  return {
    [LocationPortalType.DEFAULT]: t('Default', { ns: 'app' }),
    [LocationPortalType.PRIMARY]: t('Primary', { ns: 'app' }),
    [LocationPortalType.SECONDARY]: t('Secondary', { ns: 'app' }),
  };
}

export function getLayoutPlanes(
  planesRaw: LayoutPLaneFragment[],
): LayoutPlane[] {
  return _(planesRaw)
    .map(p => {
      const coords = _.flatten(
        (p.outlineShape as GeoJSON.Polygon).coordinates,
      ) as LoopOfShapes;
      const area = new Flatten.Polygon(coords).area();
      return {
        ...p,
        area: area,
      };
    })
    .sort((p1, p2) => naturalCompare(p1.title, p2.title))
    .value();
}

export function getPlanesStats(
  aisles: AisleFeatureMetaFragment[],
): Record<string, PlaneStats> {
  return _.reduce(
    aisles,
    (acc, aisle) => {
      const area = new Flatten.Polygon(
        _.flatten((aisle.shape as GeoJSON.Polygon).coordinates) as any,
      ).area();

      return {
        ...acc,
        [aisle.planeId]: {
          aisleArea: (acc?.[aisle.planeId]?.aisleArea ?? 0) + area,
        },
      };
    },
    {},
  );
}
