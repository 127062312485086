import {
  AssignmentComplianceItemStatus,
  LayoutBayLocationFragment,
  LayoutLocationWithMatchingFiltersFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { TwTheme } from '../../../../Tw';
import useGetNamedColor from '../../../common/useGetNamedColor';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import {
  getAssignmentComplianceLocationColor,
  getAssignmentComplianceLocationStatus,
} from '../../store/assignmentCompliance.helper';
import {
  assignmentComplianceLocationsAllByBay,
  assignmentComplianceLocationsByBay,
  assignmentComplianceSelectedIdentity,
  assignmentComplianceUseComplianceColors,
} from '../../store/assignmentCompliance.state';
import { AP_FALLBACK_RULE_ID } from '../../store/assignmentPolicy.default';
import { assignmentPolicyDocument } from '../../store/assignmentPolicy.state';

const locationColor = TwTheme.extend.colors.location;

const opacity = '4D';
const AssignmentComplianceBayLocationLayer: React.FC = () => {
  const locationLoadable = useRecoilValueLoadable(
    assignmentComplianceLocationsByBay,
  );
  const locationAllLoadable = useRecoilValueLoadable(
    assignmentComplianceLocationsAllByBay,
  );

  const selectedIdentity = useRecoilValue(assignmentComplianceSelectedIdentity);
  const assignmentPolicy = useRecoilValue(assignmentPolicyDocument);
  const applyComplianceColor = useRecoilValue(
    assignmentComplianceUseComplianceColors,
  );
  const getNamedColor = useGetNamedColor();

  if (
    locationLoadable.state !== 'hasValue' ||
    locationAllLoadable.state !== 'hasValue'
  )
    return null;

  const locationMap = locationLoadable.getValue();
  const locationAllMap: Record<
    string,
    LayoutLocationWithMatchingFiltersFragment
  > = locationAllLoadable.contents;

  const getGradientColors = (loc: LayoutBayLocationFragment): string[] => {
    const ruleIndexes = locationAllMap?.[loc.locationId]?.allMatchingFilters;

    if (_.isEmpty(ruleIndexes)) return null;

    const ruleTitles = _.map(
      ruleIndexes,
      i => assignmentPolicy?.rules[i]?.title,
    );
    const ruleSet = new Set(ruleTitles);

    let colors = _(ruleTitles)
      .map(title => getNamedColor(title ?? AP_FALLBACK_RULE_ID)?.[0])
      .compact()
      .value();

    const applyOpacity =
      !_.isNil(selectedIdentity?.ruleId) &&
      !ruleSet.has(selectedIdentity?.ruleId);

    if (applyOpacity) {
      colors = _.map(colors, c => `${c}${opacity}`);
    }
    return _.isEmpty(colors) ? null : colors;
  };

  return (
    <BayLocationLayer
      isCompliance
      stageId="simulation-bay-view"
      getGradientColors={getGradientColors}
      getLabel={loc =>
        applyComplianceColor
          ? null
          : getAssignmentComplianceLocationStatus(
              locationMap?.[loc.locationId],
            ) === AssignmentComplianceItemStatus.NONCOMPLIANT
          ? '!'
          : null
      }
      getLabelColor={
        applyComplianceColor
          ? null
          : loc =>
              getAssignmentComplianceLocationColor(
                locationMap?.[loc.locationId],
                selectedIdentity,
              )
      }
    />
  );
};

export default AssignmentComplianceBayLocationLayer;
