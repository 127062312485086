import { Transition } from '@headlessui/react';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import * as Icon from '../components/icons';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';

export type ContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  className?: string;
  title?: string;
  hideMessage?: string;
  showMessage?: string;
  classTitle?: string;
  hideIcon?: React.FC<HTMLAttributes<Element>>;
  showIcon?: React.FC<HTMLAttributes<Element>>;
  hasFilter?: string;
  loadingMessage?: string;
  maxHeight?: number;
  hasScroll?: boolean;
  hasPadding?: boolean;
  hidden?: boolean;
  borderTop?: boolean;
  collapsible?: boolean;
  transparent?: boolean;
};

const AccordionContainer: React.FC<ContainerProps> = props => {
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const ShowIcon = props.showIcon;
  const HideIcon = props.hideIcon;

  return (
    <div
      data-component="AccordionContainer"
      className={`group w-full
      ${props.hidden && 'hidden'}
      ${props.hasPadding ? 'p-4' : ''}
      `}
    >
      <div className="flex">
        {(props.collapsible && (
          <>
            <span
              className={`flex-1 text-start any-hover:group-hover:text-opacity-70 ${
                props.classTitle || ''
              }`}
            >
              {props.title}
            </span>
            {isClosed ? (
              <Icon.ChevronDualOpenVertical
                className={`w-5 h-4 fill-current text-menu-300 hover:text-menu-active any-hover:group-hover:text-menu-active ltr:mr-1 rtl:ml-1`}
              />
            ) : (
              <Icon.ChevronDualCloseVertical
                className={`w-5 h-4 fill-current text-menu-300 hover:text-menu-active any-hover:group-hover:text-menu-active ltr:mr-1 rtl:ml-1`}
              />
            )}
          </>
        )) || (
          <span
            className={`v flex-1 text-start any-hover:group-hover:text-opacity-70 ${
              props.classTitle || ''
            }`}
          >
            {props.title}
          </span>
        )}
      </div>
      {props.collapsible ? (
        <Transition
          show={!isClosed}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <section
            data-component="AccordionContainerBody"
            className={`${props.hidden && 'hidden'} ${props.className || ''}`}
          >
            {props.children}
          </section>
        </Transition>
      ) : (
        <section
          data-component="AccordionBody"
          className={`${props.hidden && 'hidden'} ${props.className || ''}`}
        >
          {props.children}
        </section>
      )}
    </div>
  );
};

export default AccordionContainer;
