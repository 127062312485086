import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import {
  updateAllPolicyFilterValues,
  updatePolicyFilterValues,
} from '../../../policyFilters/policyFilter.helper';
import useLoadItemFilterValues from '../../../policyFilters/useLoadItemFilterValues';
import useLoadLocationFilterValues from '../../../policyFilters/useLoadLocationFilterValues';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../../store/assignmentPolicy.default';
import AllocationPolicyConstraintsEditor from './allocationPolicyEditors/AllocationPolicyConstraintsEditor';
import AllocationPolicyLimitsEditor from './allocationPolicyEditors/AllocationPolicyLimitsEditor';
import AllocationPolicyLimitsVolumeEditor from './allocationPolicyEditors/AllocationPolicyLimitsVolumeEditor';
import AllocationPolicyLocationFilterEditor from './allocationPolicyEditors/AllocationPolicyLocationFilterEditor';
import AllocationPolicyOverflowSettingsEditor from './allocationPolicyEditors/AllocationPolicyOverflowSettingsEditor';
import AllocationPolicyPickabilityModeEditor from './allocationPolicyEditors/AllocationPolicyPickabilityModeEditor';
import AllocationPolicyPrioritiesEditor from './allocationPolicyEditors/AllocationPolicyPrioritiesEditor';
import AllocationPolicyProductFilterEditor from './allocationPolicyEditors/AllocationPolicyProductFilterEditor';
import AllocationPolicyUOMEditor from './allocationPolicyEditors/AllocationPolicyUOMEditor';

const AllocationPolicyEditorPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const rule = useRecoilValue(allocationPolicyRule(selectedIdentity?.ruleId));
  const [loadItemValues, cancelLoadItemValues] = useLoadItemFilterValues();
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  const showProductFilters = !_.isNil(selectedIdentity?.ruleId);
  const productFiltersConfig = getProductFilterConfigCommon(t, formatter);
  const locationsFilterConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (
      selectedIdentity?.filterId &&
      selectedIdentity?.selectionType === 'itemsFilter'
    ) {
      updatePolicyFilterValues({
        filterConfig: productFiltersConfig,
        selectedFilterId: selectedIdentity.filterId,
        policyFilterUnion: rule.itemsMatch,
        load: loadItemValues,
        cancel: cancelLoadItemValues,
      });
    }

    if (
      selectedIdentity?.filterId &&
      selectedIdentity?.selectionType === 'locationFilter'
    ) {
      updateAllPolicyFilterValues({
        filterConfig: locationsFilterConfig,
        selectedFilterId: selectedIdentity.filterId,
        policyFilterUnion: rule.locationsMatch,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }
  }, [selectedIdentity]);

  const selectedType = selectedIdentity?.selectionType;
  const subtitle = t`Allocation Policy`;

  if (!showProductFilters) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle subtitle={subtitle} title={rule?.title ?? ''} isSticky />

      {selectedType === 'uoms' && <AllocationPolicyUOMEditor />}
      {selectedType === 'limitsQuantities' && <AllocationPolicyLimitsEditor />}
      {selectedType === 'limitsVolume' && (
        <AllocationPolicyLimitsVolumeEditor />
      )}
      {selectedType === 'overflowSettings' && (
        <AllocationPolicyOverflowSettingsEditor />
      )}
      {selectedType === 'priorities' && <AllocationPolicyPrioritiesEditor />}
      {selectedType === 'constraints' && <AllocationPolicyConstraintsEditor />}

      {selectedType === 'pickability' && (
        <AllocationPolicyPickabilityModeEditor />
      )}

      {selectedType === 'itemsFilter' &&
        productFiltersConfig.map(configItem => (
          <AllocationPolicyProductFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadItemValues}
            cancelLoadData={cancelLoadItemValues}
          />
        ))}

      {selectedType === 'locationFilter' &&
        locationsFilterConfig.map(configItem => (
          <AllocationPolicyLocationFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
    </Container>
  );
};

export default AllocationPolicyEditorPanel;
