import { useApolloClient } from '@apollo/client';
import {
    LoadAnalyzeOrdersDocument,
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { ANALYZE_ORDERS_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { optimisationAnalyzeOrdersListData } from '../store/optimisation.state';
import {
    getOrderFilterParam,
    getOrderSortParam,
    KPI
} from '../store/simulation.helper';
import {
    simulationAnalyzeOrdersListData,
    simulationAnalyzeOrdersListState,
    simulationAnalyzeOrdersListStatus
} from '../store/simulation.state';
import { OrderSearchValue } from '../store/simulation.types';

export type LoadAnalyzedOrdersListParams = {
  optimisedAnalyzeId: string;
  baseAnalyzeId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: OrderSearchValue;
  sortBy?: KPI;
};

function useLoadAnalyzedOrdersCompare() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Orders data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(simulationAnalyzeOrdersListStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAnalyzedOrdersListParams) => {
        const currentOpt = await snapshot.getPromise(
          optimisationAnalyzeOrdersListData,
        );
        const currentAnl = await snapshot.getPromise(
          simulationAnalyzeOrdersListData,
        );

        const currentState = await snapshot.getPromise(
          simulationAnalyzeOrdersListState,
        );

        const query = client.watchQuery<
          LoadAnalyzeOrdersQuery,
          LoadAnalyzeOrdersQueryVariables
        >({
          query: LoadAnalyzeOrdersDocument,
          variables: {
            analyzeId: params.optimisedAnalyzeId,
            filter: getOrderFilterParam(params.filter),
            sortBy: getOrderSortParam(params.sortBy),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? ANALYZE_ORDERS_PAGE_SIZE,
            },
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationAnalyzeOrdersListStatus, AsyncLoadStatus.Error);
        }

        let queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }
            let itemsOpt = data.analyzeResult.orders.content;
            const orderFilter = _.isEmpty(itemsOpt)
              ? ['ORDER_ID_PLACEHOLDER']
              : itemsOpt.map(item => item.order.id);

            const query = client.watchQuery<
              LoadAnalyzeOrdersQuery,
              LoadAnalyzeOrdersQueryVariables
            >({
              query: LoadAnalyzeOrdersDocument,
              variables: {
                analyzeId: params.baseAnalyzeId,
                filter: {
                  id: orderFilter,
                },
                page: {
                  skip: 0,
                  limit: ANALYZE_ORDERS_PAGE_SIZE,
                },
              },
            });

            queryObservable = query.subscribe(
              ({ data: dataCompare, errors }) => {
                if (errors) {
                  console.error(errors);
                  handleError(null, errors.map(e => e.message).join('. '));
                  return;
                }
                let itemsAnl = dataCompare.analyzeResult.orders.content;

                if (params.isAppend) {
                  itemsOpt = [...currentOpt, ...itemsOpt];
                  itemsAnl = [...currentAnl, ...itemsAnl];
                }

                set(optimisationAnalyzeOrdersListData, itemsOpt);
                set(simulationAnalyzeOrdersListStatus, AsyncLoadStatus.Ok);
                set(simulationAnalyzeOrdersListState, {
                  ...currentState,
                  totalCount: data.analyzeResult.orders.totalCount,
                });
                set(simulationAnalyzeOrdersListData, itemsAnl);
              },
              error => {
                console.error(error);
                handleError(error.message || error, error.stack || null);
              },
            );
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAnalyzedOrdersListParams) {
    await initLoading();
    await loadCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAnalyzedOrdersCompare;
