import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, useState } from 'react';
// import * as Icon from '../icons';

export type InputQuestionProps = {
  children?: React.ReactNode;
  className?: string;
  question: string;
  label?: boolean;
  showIf?: boolean;
  value?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
};

export const InputQuestion = ({
  children,
  className,
  question,
  showIf,
  value,
  label,
  icon,
}: InputQuestionProps) => {
  const [id] = useState<string>(_.uniqueId());
  const IconMenu = icon;

  return (
    <div
      data-component="InputQuestion"
      data-label={`input-question-${question || ''}${label || ''}`}
      aria-label={`input-question-${question || ''}${label || ''}`}
      id={id}
      className={classNames(
        'flex items-start text-menu-active',
        'mt-1 mb-4',
        'px-2 xl:px-4',
        className,
      )}
    >
      {icon && (
        <IconMenu
          className={`fill-current w-8 h-8 lg:w-12 lg:h-12 xl:w-16 xl:h-16 ltr:mr-2 rtl:ml-2 xl:ltr:mr-4 xl:rel:ml-4`}
        />
      )}

      <h4 className={`flex-1 text-lg md:text-xl xl:text-2xl font-bold`}>
        {question}
      </h4>
    </div>
  );
};

export type InputAnswerProps = {
  children?: React.ReactNode;
  className?: string;
  answer: string;
  answer_id?: string;
  label?: boolean;
  defaultAnswer?: boolean;
  defaultChecked?: boolean;
  selectedAnswer?: boolean;
  value?: string;
} & HTMLAttributes<HTMLLIElement>;

export const InputAnswer = ({
  children,
  className,
  answer,
  answer_id,
  value,
  label,
  defaultAnswer,
  defaultChecked,
  selectedAnswer,
  ...elementProps
}: InputAnswerProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <li
      {...elementProps}
      data-component="InputAnswer"
      data-label={`input-answer-${label || ''}`}
      aria-label={`input-answer-${label || ''}`}
      id={id}
      className={classNames(
        'flex group items-center mt-1 px-2 py-2 rounded-sm cursor-pointer hover:bg-app-panel hover:bg-opacity-10',
        { 'bg-app-panel/10': selectedAnswer },
        className,
      )}
    >
      <span
        data-component="answer_id"
        className={classNames(
          'flex w-8 h-8 p-2 ltr:mr-2 rtl:ml-2 rounded-sm items-center',
          selectedAnswer
            ? 'bg-menu-active text-menu-active-text'
            : 'bg-app-panel text-menu-text group-hover:bg-menu-active group-hover:text-menu-active-text',
        )}
      >
        <span>{answer_id}</span>
        {defaultChecked && '*'}
      </span>
      <span
        data-component="answer"
        className={classNames(
          'group-hover:text-menu-active',
          { 'text-menu-active': selectedAnswer },
          className,
        )}
      >
        {answer}
      </span>
    </li>
  );
};

export type InputAnswerGroupProps = {
  children?: React.ReactNode;
  className?: string;
  viewList?: boolean;
};

export const InputAnswerGroup = ({
  children,
  className,
  viewList,
}: InputAnswerGroupProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <ol
      data-component="InputAnswerGroup"
      id={id}
      className={classNames(
        'ltr:pl-2 rtl:pr-2',
        { 'space-y-5': viewList },
        className,
      )}
    >
      {children}
    </ol>
  );
};

export type InputQAProps = {
  children?: React.ReactNode;
  className?: string;
  hasHr?: boolean;
};

export const InputQA = ({ children, className, hasHr }: InputQAProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <>
      <div
        data-component="InputQA"
        id={id}
        className={classNames('px-2 py-2 text-white flex-1', className)}
      >
        {children}
      </div>
      {hasHr && <hr className="opacity-10" />}
    </>
  );
};
