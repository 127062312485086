import * as React from 'react';
const AisleOrderRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AisleOrderRightIcon"
    {...props}
  >
    <rect width={13.79} height={72.7} x={80.61} y={50.09} strokeWidth={0} />
    <rect width={13.79} height={72.7} x={99.62} y={50.09} strokeWidth={0} />
    <rect width={13.79} height={72.7} x={4.55} y={50.09} strokeWidth={0} />
    <rect width={13.79} height={64.25} x={61.59} y={58.54} strokeWidth={0} />
    <rect width={13.79} height={55.8} x={23.57} y={67} strokeWidth={0} />
    <rect width={13.79} height={72.7} x={42.58} y={50.09} strokeWidth={0} />
    <rect width={4.44} height={120} x={116.68} y={4} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M107.81,26.42c.09-.09.18-.2.25-.31.03-.05.06-.11.09-.16.03-.06.07-.12.1-.19.03-.07.04-.14.06-.21.02-.06.04-.11.05-.17.05-.26.05-.53,0-.78-.01-.06-.03-.11-.05-.17-.02-.07-.04-.14-.06-.2-.03-.07-.06-.13-.1-.19-.03-.05-.05-.11-.09-.16-.07-.11-.16-.21-.25-.31l-16-16c-.78-.78-2.05-.78-2.83,0-.39.39-.59.9-.59,1.41s.2,1.02.59,1.41l12.59,12.59h-30.17c-1.1,0-2,.9-2,2s.9,2,2,2h30.17s-12.59,12.59-12.59,12.59c-.39.39-.59.9-.59,1.41,0,.51.2,1.02.59,1.41.78.78,2.05.78,2.83,0l16-16Z"
    />
  </svg>
);
export default AisleOrderRightIcon;
