import { AnalyzeResultWaypointFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Circle, Layer } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { viewerSelectedPlaneId } from '../../../layout/viewer/store/viewer.state';
import { simulationLayoutShowCongestion } from '../../store/simulation.layout.state';

const maxSize = 140;
const minSize = 100;

export type SimulationCongestionLayerBaseProps = {
  waypoints: AnalyzeResultWaypointFragment[];
};

const SimulationCongestionLayerBase: React.FC<
  SimulationCongestionLayerBaseProps
> = props => {
  const planeId = useRecoilValue(viewerSelectedPlaneId);
  const showCongestion = useRecoilValue(simulationLayoutShowCongestion);

  if (!showCongestion || !planeId || _.isEmpty(props.waypoints)) return null;

  const byArea = props.waypoints.filter(wp => wp.planeId === planeId);
  if (_.isEmpty(byArea)) return null;
  const max = _.head(byArea).hitCount;

  return (
    <Layer>
      {byArea.map((wp, i) => (
        <Circle
          key={wp.waypointId}
          {...{
            radius:
              Math.floor((1.15 * wp.hitCount * (maxSize - minSize)) / max) +
              minSize,
            fill: '#ff007330',
            x: wp.x,
            y: wp.y,
          }}
        />
      ))}
    </Layer>
  );
};

export default SimulationCongestionLayerBase;
