import {
  DatabaseIntrospector,
  Dialect,
  DialectAdapter,
  Driver,
  DummyDriver,
  Kysely,
  QueryCompiler,
} from 'kysely';
import { AthenaAdapter } from './athena-adapter';
import { AthenaIntrospector } from './athena-introspector';
import { AthenaQueryCompiler } from './athena-query-compiler';

export class DummyAthenaDialect implements Dialect {
  createAdapter(): DialectAdapter {
    return new AthenaAdapter();
  }

  createDriver(): Driver {
    return new DummyDriver();
  }

  createIntrospector(db: Kysely<any>): DatabaseIntrospector {
    return new AthenaIntrospector(db);
  }

  createQueryCompiler(): QueryCompiler {
    return new AthenaQueryCompiler();
  }
}
