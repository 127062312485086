import { InferResult } from 'kysely';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

const feedDistinctAgentsSelect = datasetQueryBuilder
  .selectFrom('___activity_feed_iceberg___')
  .select(['agentId', 'agentType', 'agentUser'])
  .groupBy(['agentId', 'agentType', 'agentUser']);

export type FeedDistinctAgents = InferResult<typeof feedDistinctAgentsSelect>;
export type FeedDistinctAgent = FeedDistinctAgents[number];

export const getFeedDistinctAgentsQuery = (datasetId: string) => {
  return feedDistinctAgentsSelect.where('datasetObjectId', '=', datasetId);
};

export function postProcessFeedDistinctAgents(
  data: Record<string, any>[],
): FeedDistinctAgents {
  return postProcessDefault<FeedDistinctAgent>(data);
}
