import { Role } from '../types';

export const SupervisorActivityMap: Role = {
  id: 'SupervisorActivityMap',
  version: '2024-06-01',
  description:
    'Warehouse Activity Supervisor - can only see the activity feed of the warehouse',
  permissions: [
    {
      actions: ['read'],
      features: ['layout'],
    },
  ],
  resources: ['/wh/i/{warehouse-id}/feed/*'],
};
