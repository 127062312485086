import React from 'react';
import { useRecoilValue } from 'recoil';
import LocationDetailedHtmlTooltip from '../../tooltip/LocationDetailedHtmlTooltip';
import TooltipWrapper from '../../tooltip/TooltipWrapper';
import { bayViewerHoveredLocation } from '../store/bayViewer.state';

export type LocationHtmlTooltipLayerProps = {};

const LocationHtmlTooltipLayer: React.FC<
  LocationHtmlTooltipLayerProps
> = props => {
  const location = useRecoilValue(bayViewerHoveredLocation);
  if (!location) return null;
  return (
    <TooltipWrapper stageId={'viewer-bay-view'}>
      <LocationDetailedHtmlTooltip location={location} />
    </TooltipWrapper>
  );
};

export default LocationHtmlTooltipLayer;
