import { AssignmentPolicyComplianceRuleMatchFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import * as Icon from '../components/icons';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import { PolicyFilterTag } from '../components/policies/PolicyFilterTag';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import { assignmentComplianceSelectedIdentity } from '../simulation/store/assignmentCompliance.state';
import {
  AP_FALLBACK_RULE_ID,
  getLocationFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import { assignmentPolicyRule } from '../simulation/store/assignmentPolicy.state';
import { simulationShowDatasetAsTable } from '../simulation/store/simulation.state';
import { AssignmentComplianceRuleBaseProps } from './AssignmentComplianceRule';

export type AssignmentComplianceFallbackRuleProps =
  AssignmentComplianceRuleBaseProps & {
    unmatchedByLocation: AssignmentPolicyComplianceRuleMatchFragment;
  };

const AssignmentComplianceFallbackRule: React.FC<
  AssignmentComplianceFallbackRuleProps
> = props => {
  const { t } = useTranslation('simulation');

  const rule = useRecoilValue(assignmentPolicyRule(AP_FALLBACK_RULE_ID));
  const [selectedDetails, setSelectedDetails] = useRecoilState(
    assignmentComplianceSelectedIdentity,
  );
  const setShowDatasetTable = useSetRecoilState(simulationShowDatasetAsTable);

  const isCheckLoading = props.isCheckLoading;
  // checkStatus === AsyncLoadStatus.None ||
  // checkStatus === AsyncLoadStatus.Loading;

  // function ItemCount({ value }: { value: number }) {
  //   if (isCheckLoading) {
  //     return <Loader size={14} />;
  //   }
  //   if (value === 0) {
  //     return (
  //       <div className="p-0.5 text-xs rounded bg-alerts-warning text-menu-text flex items-center">
  //         <Icon.TriangleInfo className="w-4 h-4 ltr:mr-2 rtl:ml-2 fill-current" />
  //         <span className="flex-1 ltr:pr-1 rtl:pl-1 uppercase">{t`None`}</span>
  //       </div>
  //     );
  //   }
  //   return <div className="text-lg">{value}</div>;
  // }

  // const itemsSummary = checkResult.matchedByItem;
  // const locationsSummary = checkResult.matchedByLocation;

  // const isCompliant =
  //   isCheckLoading ||
  //   itemsSummary.nonCompliantCount + locationsSummary.nonCompliantCount === 0;

  const isSelectedRuleDetails = selectedDetails?.ruleId === rule.id;
  const isSelectedLocationExcluded =
    isSelectedRuleDetails && selectedDetails?.filterType === 'locations';
  const isSelectedLocationEmpty = false;
  // isSelectedRuleDetails &&
  // selectedDetails?.detailsType === RuleDetailsType.Empty &&
  // selectedDetails?.filterType === 'locations';
  const isSelectedProductExcluded =
    isSelectedRuleDetails && selectedDetails?.filterType === 'items';

  const hasIssueLocations = props.locationMatch?.nonCompliantCount > 0;
  const hasIssueLocationsUnmatched =
    props.unmatchedByLocation?.nonCompliantCount > 0;
  const hasIssueItems = props.productMatch?.nonCompliantCount > 0;

  const hasIssue =
    hasIssueLocations || hasIssueItems || hasIssueLocationsUnmatched;

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: false,
    isDisabled: props.isDisabled,
    isActive: props.isActive,
  };

  return (
    <RuleContainer
      dataComponent="AssignmentComplianceFallbackRule"
      {...containerParams}
      hasIcon
      hasColorMode
    >
      <PolicyFilterTag>
        <RuleSectionContainer>
          {/* <div className="flex items-center p-2 pb-0">
            <div className="text-menu-text flex-1 text-xl">{t`Items`}</div>
          </div> */}
          <PolicyStatContainer>
            <Stat
              title={t`Items (Non-Compliant)`}
              isSectionable
              // value={formatInteger(props.productMatch?.nonCompliantCount)}
              value={
                hasIssueItems
                  ? formatInteger(props.productMatch?.nonCompliantCount)
                  : t`None`
              }
              valueTotal={
                hasIssueItems
                  ? formatInteger(props.productMatch?.totalCount)
                  : null
              }
              icon={hasIssueItems ? Icon.TriangleInfo : Icon.CircleOk}
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={isCheckLoading}
              hasHelper
              isSelected={isSelectedProductExcluded}
              onClick={() => {
                setSelectedDetails(
                  isSelectedProductExcluded
                    ? null
                    : {
                        ruleId: rule.id,
                        filterType: 'items',
                      },
                );
              }}
            />
          </PolicyStatContainer>
        </RuleSectionContainer>

        <RuleSectionContainer>
          <PolicyStatContainer>
            {!_.isEmpty(props.unmatchedByLocation) && (
              <Stat
                title={t`Unmatched Locations`}
                isSectionable
                value={
                  hasIssueLocationsUnmatched
                    ? formatInteger(
                        props.unmatchedByLocation?.nonCompliantCount,
                      )
                    : t`None`
                }
                valueTotal={
                  hasIssueLocationsUnmatched
                    ? formatInteger(props.unmatchedByLocation?.totalCount)
                    : null
                }
                icon={
                  hasIssueLocationsUnmatched ? Icon.TriangleInfo : Icon.CircleOk
                }
                inPanelMode
                isActionable
                inFilterStat
                isCheckLoading={isCheckLoading}
                isSelected={isSelectedLocationEmpty}
                // onClick={() => {
                //   setSelectedDetails(
                //     isSelectedLocationEmpty
                //       ? null
                //       : {
                //           ruleId: rule.id,
                //           filterType: AssignmentPolicyFilterType.Location,
                //           detailsType: RuleDetailsType.Empty,
                //         },
                //   );
                // }}
              />
            )}
          </PolicyStatContainer>
        </RuleSectionContainer>

        <RuleSectionContainer isSelected={isSelectedLocationExcluded}>
          {/* <div className={cn('flex items-center p-2 pb-0')}>
            <div className={cn('flex-1 text-xl')}>{t`Locations`}</div>
          </div> */}
          <PolicyStatContainer>
            <Stat
              title={t`Locations (Non-Compliant)`}
              isSectionable
              value={
                hasIssueLocations
                  ? formatInteger(props.locationMatch?.nonCompliantCount)
                  : t`None`
              }
              valueTotal={
                hasIssueLocations
                  ? formatInteger(props.locationMatch?.totalCount)
                  : null
              }
              icon={hasIssueLocations ? Icon.TriangleInfo : Icon.CircleOk}
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={isCheckLoading}
              isSelected={isSelectedLocationExcluded}
              onClick={() => {
                setShowDatasetTable(
                  isSelectedLocationExcluded ? null : 'locations-stats-by-rule',
                );
                setSelectedDetails(
                  isSelectedLocationExcluded
                    ? null
                    : {
                        ruleId: rule.id,
                        filterType: 'locations',
                      },
                );
              }}
            />
          </PolicyStatContainer>

          <PolicyFilterGroupList
            isDisabled={props.isDisabled}
            filterSets={_.map(rule.locationsMatch?.anyOf, filterGroup => ({
              id: filterGroup.id,
              allOf: [...filterGroup.allOf],
            }))}
            config={getLocationFilterConfigCommon(t)}
            selectedId={null}
            showAddFilter={false}
          />
        </RuleSectionContainer>
      </PolicyFilterTag>
    </RuleContainer>
  );
};
export default AssignmentComplianceFallbackRule;
