const LinkTargetIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkTargetIcon"
    {...props}
  >
    <path d="M36.71,116.57c0,1.37.56,2.72,1.53,3.69.98.98,2.29,1.53,3.68,1.53h74.64c1.39,0,2.7-.54,3.69-1.53.97-.97,1.52-2.31,1.52-3.68v-4.19s0-70.45,0-70.45c0-1.39-.54-2.7-1.53-3.68-.98-.98-2.29-1.53-3.69-1.53h-51.71s0,10.42,0,10.42h26.42s20.07,0,20.07,0v64.22s-64.22,0-64.22,0v-16.42h-10.43" />
    <path d="M56.4,80.86H16.64s0-64.22,0-64.22h64.22s0,15.94,0,15.94h10.42s0-21.15,0-21.15c0-2.87-2.34-5.21-5.21-5.21H11.43c-1.37,0-2.72.55-3.69,1.52-.97.97-1.53,2.31-1.53,3.68v74.64c0,1.37.56,2.71,1.53,3.68.97.97,2.31,1.53,3.68,1.53h44.97" />
    <polygon points="62.96 92.78 84.57 92.77 61.92 69.14 61.92 61.25 69.77 61.25 92.43 84.9 92.41 63.27 103.56 74.39 103.56 103.89 74.08 103.89 62.96 92.78" />
    <path d="M52.62,40.27h-.07v-4.21c0-6.64-5.46-12.09-12.09-12.09-6.64,0-12.09,5.46-12.09,12.09v4.21h-.26c-1.77,0-3.22,1.45-3.22,3.22v17.61c0,1.77,1.45,3.22,3.22,3.22h24.58c1.77,0,3.22-1.45,3.22-3.22v-17.68c-.13-1.77-1.51-3.15-3.29-3.15ZM47.3,40.27h-13.67s0-4.21,0-4.21c0-3.75,3.02-6.84,6.84-6.84,3.75,0,6.84,3.02,6.84,6.83v4.21Z" />
  </svg>
);
export default LinkTargetIcon;
