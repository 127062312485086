import { AxisTickProps } from '@nivo/axes';
import { Datum, ResponsiveLine, Serie } from '@nivo/line';
import { area, curveMonotoneX } from 'd3-shape';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPercent } from '../../common/formatHelper';
import { ProductCategoryDescriptor } from '../store/abc/simulation.ABC.types';

export type ABCSerie = Serie & {
  category?: ProductCategoryDescriptor;
};

export type SimulationABCChartProps = {
  current: ABCSerie[];
  categories: any;
  ticks: number[];
};

const baselineABCPoints = [
  { x: 0, y: 0 },
  { x: 0.05, y: 0.5 },
  { x: 0.2, y: 0.8 },
  { x: 0.5, y: 0.95 },
  { x: 1, y: 1 },
];

// const sharpABCPoints = [
//   { x: 0, y: 0 },
//   { x: 0.015, y: 0.5 },
//   { x: 0.1, y: 0.8 },
//   { x: 0.5, y: 0.98 },
//   { x: 1, y: 1 },
// ];

// const flatABCPoints = [
//   { x: 0, y: 0 },
//   { x: 0.1, y: 0.5 },
//   { x: 0.3, y: 0.8 },
//   { x: 0.65, y: 0.94 },
//   { x: 1, y: 0.98 },
// ];

const SimulationABCChart: React.FC<SimulationABCChartProps> = props => {
  const { t } = useTranslation('simulation');

  const CustomTick = (tick: AxisTickProps<string>) => {
    return (
      <g transform={`translate(${tick.x},${tick.y + 25})`}>
        <rect
          x={-30}
          y={-12}
          rx={3}
          ry={3}
          width={60}
          height={24}
          fill="rgba(79,90,97,1)"
        />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            //...theme.axis.ticks.text,
            fill: '#ffffff',
            fontSize: 14,
          }}
        >
          {tick.value}
        </text>
      </g>
    );
  };

  const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
    const areaGenerator = area<Datum>()
      .x(d => xScale(d.x))
      .y0(d => innerHeight)
      .y1(d => yScale(d.y))
      .curve(curveMonotoneX);

    return (
      <>
        {props.current.map((serie, index) => {
          return (
            <path
              key={`area-category-${serie.category.key}-${index}`}
              d={areaGenerator(serie.data)}
              fill={serie.category?.color}
              stroke="#3daff7"
              strokeWidth={0}
            />
          );
        })}
      </>
    );
  };

  return (
    <ResponsiveLine
      margin={{ top: 10, right: 15, bottom: 50, left: 60 }}
      data={[
        {
          id: 'baselineABC',
          data: baselineABCPoints,
        },
      ]}
      xScale={{ type: 'linear', min: 0, max: 1 }}
      yScale={{ type: 'linear', min: 0, max: 1 }}
      xFormat=".0%"
      yFormat=".0%"
      curve="monotoneX"
      // axisTop={null}
      // axisRight={null}
      //  gridXValues={baselinePoints.map(p => p.x)}
      gridYValues={baselineABCPoints.map(p => p.y)}
      enablePoints={true}
      pointLabelYOffset={0}
      enablePointLabel={false}
      // colors={['rgba(255,255,255, 0.6)']} // ABC Pareto Curve
      // colors={['rgba(162, 188, 204, 0.5)']} // ABC Pareto Curve
      colors={['rgba(200, 235, 255, 0.6)']} // ABC Pareto Curve
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 11,
              fill: 'rgba(255, 255, 255,  0.9)',
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fill: 'rgba(255, 255, 255,  0.9)',
            },
          },
        },

        grid: {
          line: {
            stroke: 'rgba(162,188,204, 0.85)',
            strokeWidth: 0.5,
          },
        },

        crosshair: {
          line: {
            stroke: 'rgba(0, 0, 0,  0.7)',
            strokeWidth: 3,
            // simulate line will dash stroke when index is even
            strokeDasharray: '3, 3',
          },
        },
      }}
      axisBottom={{
        tickValues: props.ticks,
        legend: t`% of Total Items`,
        legendOffset: 40,
        legendPosition: 'middle',
        format: '.0%',
        //renderTick: CustomTick,
      }}
      axisLeft={{
        tickValues: [0.5, 0.8, 0.95, 1],
        tickRotation: 0,
        legend: t`Sales Volume %`,
        legendOffset: -50,
        legendPosition: 'middle',
        format: '.0%',
        //renderTick: CustomTick,
      }}
      // //pointSize={10}
      // pointColor={{ theme: 'background' }}
      // pointBorderWidth={2}
      // pointBorderColor={{ from: 'serieColor' }}
      // //pointLabelYOffset={-12}
      // enableArea={false}
      // areaOpacity={1}
      // areaBlendMode="normal"
      enableSlices={'y'}
      isInteractive={true}
      layers={[
        'grid',
        AreaLayer,
        'markers',
        'lines',
        'slices',
        'axes',
        'points',
        'legends',
        'crosshair',
      ]}
      sliceTooltip={({ slice }) => {
        const toPercent = slice.points[0].data.y;
        if (toPercent === 0) return null;

        const cat = _.values(props.categories).find(
          c => c.toCmlPercentile === toPercent,
        );
        const msg = t(
          '{{moveShare}} of movement did by {{productShare}} of products (target {{targetProductShare}})',
          {
            moveShare: 0,
            productShare: 0,
            targetProductShare: formatPercent(cat.productShareTarget),
          },
        );
        return (
          <div className={`bg-categoryABC-${cat.key} text-menu-text text-sm`}>
            <div className="p-2">{`[${cat.tag}] ${cat.title}`}</div>
            <div className="p-2 bg-opacity-80 bg-app-panel-dark/60 backdrop-filter backdrop-blur text-white">
              {t('{{productShare}} of Total Items', {
                productShare: formatPercent(cat.productShare),
                diffShare: formatPercent(cat.productShare),
              })}
            </div>
            <div className="p-2 bg-opacity-80 bg-app-panel-dark/60 backdrop-filter backdrop-blur text-white">
              {t('{{moveShare}} of Sales', {
                moveShare: formatPercent(cat.toCmlPercentile),
              })}
            </div>

            {/* orderLinesShare: formatPercent( totalHitCount !== 0 ?
            data?.totalHitCount / totalHitCount : 0, ), */}
            {/* <div className="p-2 bg-opacity-80 bg-app-panel-dark/60 backdrop-filter backdrop-blur text-white">
              {t('{{targetProductShare}} target product share', {
                targetProductShare: formatPercent(cat.productShareTarget),
              })}
            </div> */}
          </div>
        );
      }}
    />
  );
};

export default SimulationABCChart;
