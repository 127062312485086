import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import TooltipWrapper from '../../../../layout/tooltip/TooltipWrapper';
import { ConvertedAisle, ConvertedBay } from '../converter.model';
import {
  converterLayoutHoveredFeature,
  converterLayoutHoveredLocation,
} from '../store/converter.layout.state';

const ConverterTooltipLayer: React.FC = props => {
  const { t } = useTranslation('app');
  const feature = useRecoilValue(converterLayoutHoveredFeature);
  const location = useRecoilValue(converterLayoutHoveredLocation);

  if (!(feature || location)) return null;

  const aisle = feature instanceof ConvertedAisle ? feature : null;
  const bay = feature instanceof ConvertedBay ? feature : null;

  const styleLabel = classNames(
    'flex items-center',
    'p-1 px-2',
    'text-xs',
    'bg-menu-active',
    'text-menu-active-text',
    'uppercase',
  );

  const styleValue = classNames(
    'flex',
    'p-1 px-2',
    'text-sm font-bold',
    'text-center',
    'text-menu-text bg-app-panel-dark',
    'items-center',
  );

  return (
    <TooltipWrapper stageId={'converter-area-view'}>
      {location && (
        <div className="min-w-tooltip">
          <label className={classNames(styleLabel)}>{t`Location`}</label>
          <div className={classNames(styleValue)}>{location.locationId}</div>
        </div>
      )}

      {bay && (
        <div className="min-w-tooltip">
          <label className={classNames(styleLabel)}>{t`Bay`}</label>
          <div className={classNames(styleValue)}>{bay?.title ?? ''}</div>
        </div>
      )}

      {aisle && (
        <div className="min-w-tooltip">
          <label className={classNames(styleLabel)}>
            {aisle.navigable ? t`Aisle` : t`Block`}
          </label>
          <div className={classNames(styleValue)}>{aisle?.title ?? ''}</div>
        </div>
      )}
    </TooltipWrapper>
  );
};

export default ConverterTooltipLayer;
