import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import Loader from '../loaders/Loader';
import { Button } from './Button';

export type ButtonLoadMoreProps = {
  children?: React.ReactNode;
  className?: string;
  titleNext?: string;
  titlePrev?: string;
  titleFirst?: string;
  titleLast?: string;
  isLoading?: boolean;
  totalCount?: number;
  itemsCount?: number;
  totalLabel?: string;
  onNext?: () => void;
  onPrev?: () => void;
  onFirst?: () => void;
  onLast?: () => void;
};

export const ButtonLoadMore = ({
  children,
  className,
  titleNext,
  titlePrev,
  titleFirst,
  titleLast,
  totalLabel,
  totalCount,
  itemsCount,
  isLoading,
  onNext,
  onPrev,
  onFirst,
  onLast,
}: ButtonLoadMoreProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  const defaultTitleNext = isLoading ? t`Loading ...` : t`Load More`;
  const defaultTitlePrev = t`Prev`;
  const defaultTitleFirst = t`First`;
  const defaultTitleLast = t`Last`;
  const canLoadMoreNext = totalCount > 0;
  // const canLoadMoreNext = totalCount > 0 && itemsCount < totalCount;

  titleNext ??= defaultTitleNext;
  titlePrev ??= defaultTitlePrev;
  titleFirst ??= defaultTitleFirst;
  titleLast ??= defaultTitleLast;

  return (
    <div
      data-component="ButtonLoadMore"
      data-label={`ButtonLoadMore-${titleNext || ''}-${titlePrev || ''}-${titleFirst || ''}-${titleLast || ''}`}
      aria-label={`ButtonLoadMore-${titleNext || ''}-${titlePrev || ''}-${titleFirst || ''}-${titleLast || ''}`}
      id={id}
      className={classNames(
        'flex items-center',
        'ltr:ml-2 rtl:mr-2',
        className,
      )}
    >
      <div className={classNames('flex')}>
        <div
          className={classNames(
            'flex items-center justify-center',
            'text-menu-active text-sm',
            'h-8 min-w-8',
            'mx-4',
            'px-2 py-0.5',
            canLoadMoreNext || onPrev || onFirst || onLast
              ? 'bg-app-background/80 hover:bg-app-background cursor-pointer rounded-full'
              : 'bg-app-background/75 rounded-lg',
          )}
        >
          <div className={classNames('flex items-center')}>
            {onFirst && (
              <Button
                className={classNames(
                  'bg-menu/50 hover:bg-menu',
                  'rounded-full',
                  'text-menu-active text-xxs',
                )}
                buttonSize={'xs'}
                label={titleFirst}
                onPress={onFirst}
                isDisabled={itemsCount === 1}
              />
            )}
            {onPrev && (
              <Button
                className={classNames(
                  'ltr:ml-1 rtl:ml-1',
                  'ltr:mr-2 rtl:ml-2',
                  'bg-menu/50 hover:bg-menu',
                  'rounded-full',
                  'text-menu-active text-xxs',
                )}
                buttonSize={'xs'}
                label={titlePrev}
                onPress={onPrev}
                isDisabled={itemsCount === 1}
              />
            )}
            {canLoadMoreNext ? (
              <>
                {isLoading ? (
                  <span className={classNames('flex items-center', 'relative')}>
                    <Loader
                      size={23}
                      stroke={5}
                      type={'loading-original'}
                      className="text-menu-active"
                    />
                  </span>
                ) : (
                  <span className={classNames('flex items-center', 'relative')}>
                    <span>{formatInteger(itemsCount)}</span>
                  </span>
                )}
                <span
                  className={classNames('opacity-70', 'mx-2')}
                >{t`of`}</span>
                <span data-component="totalCount">
                  {formatInteger(totalCount)}
                </span>
                {/* {onNext && ( */}
                <Button
                  className={classNames(
                    'ltr:ml-2 rtl:mr-2',
                    'bg-menu/50 hover:bg-menu',
                    'rounded-full',
                    'text-menu-active text-xxs',
                  )}
                  isLoading={isLoading}
                  isDisabled={isLoading || itemsCount === totalCount}
                  buttonSize={'xs'}
                  label={titleNext}
                  onPress={onNext}
                />
                {/* )} */}
              </>
            ) : (
              <div
                data-component="LoadMoreNone"
                className={classNames('flex items-center', 'relative')}
              >
                {totalLabel && (
                  <span
                    className={classNames(
                      'opacity-70',
                      'mx-2',
                      'text-minimal uppercase',
                    )}
                  >
                    {totalLabel}
                  </span>
                )}

                {isLoading ? (
                  <span className={classNames('flex items-center', 'relative')}>
                    <Loader
                      size={18}
                      stroke={5}
                      type={'loading-original'}
                      className="text-menu-active"
                    />
                  </span>
                ) : (
                  <span data-component="totalCount">
                    {totalCount === 0 ? (
                      <span className={classNames('opacity-50')}>{`—`}</span>
                    ) : (
                      <span>{formatInteger(totalCount)}</span>
                    )}
                  </span>
                )}
              </div>
            )}
            {/* && itemsCount < totalCount */}
            {onLast && (
              <Button
                className={classNames(
                  'ltr:ml-1 rtl:mr-1',
                  'bg-menu/50 hover:bg-menu',
                  'rounded-full',
                  'text-menu-active text-xxs',
                )}
                buttonSize={'xs'}
                label={titleLast}
                onPress={onLast}
                isDisabled={isLoading || itemsCount === totalCount}
              />
            )}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};
