import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import AssignmentDataTable from './AssignmentDataTable';
import AssignmentFilterTag from './AssignmentFilterTag';
import { assignmentDocument } from './store/assignment.state';

const AssignmentDataView: React.FC = () => {
  const { t } = useTranslation('dataset');
  const assignment = useRecoilValue(assignmentDocument);

  return (
    <Container
      col
      flex1
      componentName="AssignmentDataView"
      className={classNames(
        'border-app-panel-dark relative ltr:border-l rtl:border-r',
      )}
    >
      <PanelHeader
        title={t`Assignments Data Table`}
        filterPlaceholder={<AssignmentFilterTag />}
      />
      <AssignmentDataTable id={assignment?.id} title={assignment?.title} />
    </Container>
  );
};

export default AssignmentDataView;
