import { Container } from '../../components/layout/ContainerFlex';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import SimulationDatasetPanelOrders from '../panels/SimulationDatasetPanelOrders';
import { simulationCurrent } from '../store/simulation.state';
import ActionBarNextStep from './ActionBarNextStep';

const SimulationSidebarDatasetOrders: React.FC = () => {
  const simulation = useRecoilValue(simulationCurrent);
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <Container col hasOverflowY hasExtraPadding>
      <Suspense
        fallback={
          <LoadingIndicator selfCenter message={t`Loading Orders Dataset...`} />
        }
      >
        <SimulationDatasetPanelOrders />
      </Suspense>
      <ActionBarNextStep isDisabled={_.isNil(simulation?.orderSet?.id)} />
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-dataset-orders',
    title: t`Dataset > Orders`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationSidebarDatasetOrders />
    </SidebarContainer>
  );
};
