import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import ErrorIndicator from '../../../components/ErrorIndicator';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  optimisationAnalyzeId,
  optimisationAnalyzeOrderDetails,
  optimisationAnalyzeOrderDetailsLoadStatus,
  optimisationSelectedPicklistId,
  optimisationSelectedRoutePart,
  optimisationSelectedRoutePartZoom,
} from '../../store/optimisation.state';
import { simulationLayoutSelectedOrderId } from '../../store/simulation.layout.state';
import OrderPicklists from './OrderPicklists';

const OptimizeOrderPicklists: React.FC = () => {
  const orderId = useRecoilValue(simulationLayoutSelectedOrderId);
  const analyzeId = useRecoilValue(optimisationAnalyzeId);
  const orderDetails = useRecoilValue(optimisationAnalyzeOrderDetails);
  const orderDetailsStatus = useRecoilValue(
    optimisationAnalyzeOrderDetailsLoadStatus,
  );
  const { t } = useTranslation('simulation');

  if (
    orderDetailsStatus === AsyncLoadStatus.None ||
    orderDetailsStatus === AsyncLoadStatus.Loading
  ) {
    return (
      <LoadingIndicator
        className="p-4"
        selfCenter
        message={t`Loading Picklists`}
      />
    );
  }

  if (orderDetailsStatus === AsyncLoadStatus.Error) {
    return (
      <ErrorIndicator
        className="p-4"
        selfCenter
        message={t`Cannot load Picklists, please contact support`}
      />
    );
  }

  return (
    <OrderPicklists
      orderId={orderId}
      analyzeId={analyzeId}
      picklists={orderDetails?.picklists ?? []}
      selectedPicklist={optimisationSelectedPicklistId}
      setRoutePart={optimisationSelectedRoutePart}
      setRoutePartWithZoom={optimisationSelectedRoutePartZoom}
      wpType="after"
    />
  );
};

export default OptimizeOrderPicklists;
