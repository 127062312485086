import React from 'react';
// import * as Icon from '../../icons';
import {
  CsvImportOptions,
  ImportFormat,
  ImportJobSourceCompression,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ImporterPreviewSizeInput from '../../../import/csv/components/ImporterPreviewSizeInput';
import { importSourceSettings } from '../../../import/store/import.state';
import DropdownSelector from '../../actions/DropdownSelector';
import { InputValue } from '../../inputs/InputValue';

export type ToolbarImportParamsProps = {
  settings: CsvImportOptions;
  onChange: (value: CsvImportOptions) => void;
};

export const ToolbarImportParams: React.FC<
  ToolbarImportParamsProps
> = props => {
  const { t } = useTranslation('importer');
  const [sourceSettings, setSourceSettings] =
    useRecoilState(importSourceSettings);

  const formats: Record<ImportFormat, string> = {
    CSV: t`CSV`,
    JSON: t`JSON`,
    DATASET: t`Dataset`,
  };

  const compressions: Record<ImportJobSourceCompression, string> = {
    NO: t`NO`,
    GZIP: t`GZIP`,
  };

  return (
    <header
      data-component="ToolbarImportParams"
      className={classNames(
        'flex',
        'p-3 xl:p-4',
        'bg-sidebar-menu',
        'divide-menu/50 divide-x',
      )}
    >
      <DropdownSelector
        label={t`Data format`}
        value={sourceSettings.format}
        values={_.keys(formats) as ImportFormat[]}
        renderValue={v => formats[v]}
        onChange={v => setSourceSettings({ ...sourceSettings, format: v })}
        panelMode
        className="px-2"
        DropAlignRight
      />

      <DropdownSelector
        label={t`Compression method`}
        value={sourceSettings.compression}
        values={_.keys(compressions) as ImportJobSourceCompression[]}
        renderValue={v => compressions[v]}
        onChange={v => setSourceSettings({ ...sourceSettings, compression: v })}
        panelMode
        className="px-2"
        DropAlignRight
      />
      {sourceSettings.format === ImportFormat.CSV && (
        <>
          <InputValue
            title={t`Delimiter`}
            id_name="custom-delimiter"
            className="px-2"
            isDebouncedChange
            value={props.settings?.separator ?? ','}
            onChange={separator =>
              props.onChange({
                ...(props.settings ?? {}),
                separator,
              })
            }
          />
          <InputValue
            title={t`Separator`}
            id_name="custom-separator"
            className="px-2"
            isDebouncedChange
            value={props.settings?.quote ?? '"'}
            onChange={quote =>
              props.onChange({
                ...(props.settings ?? {}),
                quote,
              })
            }
          />
        </>
      )}

      <ImporterPreviewSizeInput />
    </header>
  );
};
