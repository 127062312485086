import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../../../common/utils';
import { ContainerMap } from '../../../../components/layout/ContainerMap';
import StageContainer from '../../../../layout/stage/StageContainer';
import ConverterBayLocationLayer from './layers/ConverterBayLocationLayer';
import ConverterLocationHtmlTooltipLayer from './layers/ConverterLocationHtmlTooltipLayer';

const ConverterBayViewerLayout: React.FC = () => {
  const { t } = useTranslation('designer');

  return (
    <ContainerMap
      headerTitle={t`Bay Layout`}
      className={cn('min-h-bayMap max-h-bayMap')}
    >
      <StageContainer type="converter-bay-view" darkBg>
        <ConverterBayLocationLayer />
      </StageContainer>
      <ConverterLocationHtmlTooltipLayer />
    </ContainerMap>
  );
};

export default ConverterBayViewerLayout;
