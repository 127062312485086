// src/roles/index.ts
import { Role } from '../types';
import { AdminWarehouse } from './adminWarehouse';
import { ManagerWarehouse } from './managerWarehouse';
import { SupervisorActivity } from './supervisorActivity';
import { SupervisorActivityMap } from './supervisorActivityMap';
import { SupervisorStock } from './supervisorStock';

// Collect all roles into a single object
export const DEFAULT_ROLES: Record<string, Role> = {
  ManagerWarehouse,
  SupervisorActivityMap,
  SupervisorActivity,
  SupervisorStock,
  AdminWarehouse,
};
