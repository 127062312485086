import { PickingWaveSettingsFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import { pickingRequirementDefault } from '../../simulation/store/pickingPolicy/pickingPolicy.default';
import {
  getIsWaveSettingSelected,
  getWaveDescriptors,
  WaveSettingId,
} from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';

const PickingPolicyWaveEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    pickingPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  const wave = rule?.pickingRequirementsSettings?.wave ?? {};
  const waveDescriptors = getWaveDescriptors(t);

  const onSelectValue = (settingId: WaveSettingId, value: boolean) => {
    let patch: Partial<PickingWaveSettingsFragment> = {};
    switch (settingId) {
      case 'areaStaging':
        patch = { useStagingArea: value };
        break;
      case 'dock':
        patch = { useDock: value };
        break;
      case 'route':
        patch = { useDeliveryRoute: value };
        break;
      case 'waveIdentity':
        patch = { useWaveId: value };
        break;
    }

    updateRule({
      ...rule,
      pickingRequirementsSettings: {
        ...(rule.pickingRequirementsSettings ?? pickingRequirementDefault),
        wave: {
          ...wave,
          ...patch,
        },
      },
    });
  };

  return (
    <TitleSection
      id={`policy-editor-wave-group-by`}
      title={t`Picking Wave by`}
      inSidebarView
      hasScreenTitle
      dataComponent="PickingPolicyWaveEditor"
      classNameInner="p-2 space-y-2"
    >
      {/* Select/ deselect Order Metadata Types that should affect on picklist scope: */}

      {_.map(waveDescriptors, (descriptor, index) => {
        const isSelected = getIsWaveSettingSelected(descriptor.id, wave);
        return (
          <PolicyCardItem
            key={`PolicyCardItem-Delivery-${descriptor.id}`}
            title={descriptor.title}
            isActive={isSelected}
            icon={descriptor.icon}
            iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
            onClick={() => onSelectValue(descriptor.id, !isSelected)}
          />
        );
      })}
    </TitleSection>
  );
};

export default PickingPolicyWaveEditor;
