import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import LayoutDashboardCard from '../LayoutDashboardCard';
import LayoutStats from '../LayoutStats';
import { layoutDocument } from '../store/layout.state';

const LayoutStatPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [document, setDocument] = useRecoilState(layoutDocument);
  // const whId = useRecoilValue(warehouseSelectedId);
  // const updateItemSet = useUpdateLayout();
  // const navigate = useNavigate();

  // const changeTitle = (title: string) => {
  //   setDocument({ ...document, title: title });
  //   updateItemSet({ layoutId: document.id, title: title });
  // };
  // const changeDescription = (description: string) => {
  //   setDocument({ ...document, description: description });
  //   updateItemSet({ layoutId: document.id, description: description });
  // };

  // const usedInSimulation = document?.simulationsWith?.totalCount > 0;
  // const simulation = document?.simulationsWith?.content;
  return (
    <PanelBody data-component="LayoutStatPanel">
      <ScreenTitle isSticky title={t`Stats`} subtitle={t`Layout`} />
      <div className={`p-2`}>
        <LayoutDashboardCard summary={document?.summary} itemLimit={100} />
        <LayoutStats />
      </div>
    </PanelBody>
  );
};

export default LayoutStatPanel;
