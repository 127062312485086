import { FetchResult } from '@apollo/client';
import {
  AppBillingCustomerFragment,
  CreateOrUpdateAppBillingCustomerDocument,
  CreateOrUpdateAppBillingCustomerMutation,
  CreateOrUpdateAppBillingCustomerMutationVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { billingClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import {
  appBillingCustomer,
  appBillingCustomerLoadStatus,
  appBillingPurchaseError,
  appBillingPurchaseLoadStatus,
} from '../store/appBilling.state';

function useUpdateCustomer() {
  const { t } = useTranslation('app');

  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    set(appBillingCustomer, null);
    set(appBillingCustomerLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (customer: AppBillingCustomerFragment) => {
        let customerResponse: FetchResult<
          CreateOrUpdateAppBillingCustomerMutation,
          Record<string, any>,
          Record<string, any>
        >;

        function handleError(message, details?) {
          console.error(message, details);
          set(appBillingPurchaseError, message);
          set(appBillingPurchaseLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        try {
          customerResponse = await billingClient.mutate<
            CreateOrUpdateAppBillingCustomerMutation,
            CreateOrUpdateAppBillingCustomerMutationVariables
          >({
            mutation: CreateOrUpdateAppBillingCustomerDocument,
            variables: {
              input: cloneWithoutTypename(customer),
            },
          });

          if (customerResponse.errors) {
            handleError(
              t`Can't update customer`,
              customerResponse.errors.map(e => e.message).join('. '),
            );
            return;
          }
        } catch (ex) {
          handleError(t`Can't create purchase`, ex.message);
          return;
        }

        const data = customerResponse.data.createOrUpdateAppBillingCustomer;
        if (data?.stripeError) {
          handleError(data?.stripeError.message, data?.stripeError);
        }
        set(appBillingCustomer, data.customer);
        set(appBillingCustomerLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (customer: AppBillingCustomerFragment) => {
    await cleanupState();
    return await loadCallback(customer);
  };
}

export default useUpdateCustomer;
