import * as React from 'react';
const Warehouse3PlIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Warehouse3PlIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M49.78,69.83c-.24,0-2.23.03-2.44.03v5.83c.17.03,2.23.03,2.44.03,2.4,0,3.67-.93,3.67-2.85v-.27c0-1.99-1.37-2.78-3.67-2.78Z"
    />
    <path
      strokeWidth={0}
      d="M64.5,24.5L.84,40.93v62.58h126.32v-62.58l-62.66-16.43ZM35.62,82.42c0,5.35-3.6,7.72-9.36,7.72-2.81,0-5.97-.55-7.58-1.17-.34-.14-.69-.31-.69-.69v-3.36c0-.38.27-.65.62-.65h.07c2.13.17,5.04.34,7.13.34s2.92-.82,2.92-2.2v-.55c0-1.27-.38-2.44-2.68-2.44h-5.69c-.38,0-.69-.31-.69-.69v-3.67c0-.38.31-.65.69-.65h5.69c2.16,0,2.68-1.34,2.68-2.54v-.55c0-1.37-.89-1.78-2.64-1.78-2.37,0-5.01.21-7.24.38h-.07c-.34,0-.65-.27-.65-.65v-3.36c0-.38.38-.55.72-.69,1.61-.62,4.43-1.23,7.24-1.23,5.8,0,9.47,1.75,9.47,6.89v.48c0,1.89-.99,4.15-3.12,5.45,1.92.99,3.19,2.74,3.19,5.04v.55ZM60.38,72.88c0,6.76-5.66,8.64-10.6,8.64-.75,0-1.51-.07-2.44-.1v7.72c0,.38-.31.69-.69.69h-5.49c-.38,0-.72-.24-.72-.62v-22.5c0-1.65.55-2.02,1.99-2.26,1.71-.27,4.8-.45,7.24-.45,5.11,0,10.7,1.78,10.7,8.61v.27ZM78.56,89.04c0,.41-.24.62-.69.69-1.23.24-3.57.41-5.39.41-4.39,0-8.82-1.03-8.82-7.68v-17.43c0-.38.34-.69.72-.69h5.59c.38,0,.69.31.69.69v17.43c0,1.34.41,1.72,1.65,1.72h5.56c.38,0,.69.31.69.69v4.18Z"
    />
  </svg>
);
export default Warehouse3PlIcon;
