import {
  ImportPipelineAfterPullAction,
  ImportPipelineConnectorSettingsFragment,
  ImportPipelineSftpPullConnectorSettingsFragment,
  SftpTestResultFragment,
  useTestImportPipelineSftpConnectorMutation,
  useUpdateImportPipelineMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../common/types';
import ErrorIndicator from '../../../components/ErrorIndicator';
import { Button } from '../../../components/actions/Button';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../../components/inputs/InputGroupList';
import InputNumber from '../../../components/inputs/InputNumber';
import InputText, { InputPassword } from '../../../components/inputs/InputText';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import TitleSection from '../../../components/layout/TitleSection';
import { ActionBar } from '../../../components/nav/ActionBar';
import { StatListItem } from '../../../components/stats/StatListItem';
import useUpdateImportPipeline from '../../hooks/useUpdateImportPipeline';
import {
  importPipelineIsActiveEditor,
  importPipelineSelected,
  importPipelineSelectedConnectorId,
} from '../../store/importPipelines.state';

type SFTPConnectorEditorProps = {
  connector: ImportPipelineConnectorSettingsFragment;
  isNew: boolean;
  onChange: () => Promise<void>;
};

const SFTPConnectorEditor: React.FC<SFTPConnectorEditorProps> = props => {
  const pipeline = useRecoilValue(importPipelineSelected);
  const [callSftpTest] = useTestImportPipelineSftpConnectorMutation();
  const [callUpdatePipeline] = useUpdateImportPipelineMutation();
  const setShowEditor = useSetRecoilState(importPipelineIsActiveEditor);
  const setSelectedConnectorId = useSetRecoilState(
    importPipelineSelectedConnectorId,
  );

  const [connector, setConnector] =
    useState<ImportPipelineConnectorSettingsFragment>(props.connector);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string[]>(null);
  const [testStatus, setTestStatus] = useState<AsyncLoadStatus>(
    AsyncLoadStatus.None,
  );
  const [testResult, setTestResult] = useState<SftpTestResultFragment>(null);
  const updatePipeline = useUpdateImportPipeline();

  useEffect(() => {
    setConnector(props.connector);
    setIsChanged(false);
  }, [props.connector]);

  const sftp = connector.sftpPull;

  function updateSftp(
    patch: Partial<ImportPipelineSftpPullConnectorSettingsFragment>,
  ) {
    setError(null);
    setConnector({
      ...connector,
      sftpPull: {
        ...connector.sftpPull,
        ...patch,
      },
    });
  }

  async function applyChanges() {
    if (!validate()) return;
    setIsLoading(true);
    const connectors = _.map(
      pipeline.settings?.integrationSettings?.connectors,
      c => (c.id === props.connector?.id ? connector : c),
    );

    if (props.isNew) {
      connectors.push(connector);
    }

    try {
      updatePipeline({
        importPipelineId: pipeline.id,
        settings: {
          ...pipeline.settings,
          integrationSettings: {
            ...(pipeline?.settings?.integrationSettings ?? {}),
            connectors,
          },
        },
      });

      props.onChange();
    } catch (ex) {
      console.error(ex);
      setError(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  function validate() {
    const errors: string[] = [];
    if (_.isEmpty(connector.sftpPull?.host)) {
      errors.push(t`Host is not set`);
    }

    setError(errors);

    return _.isEmpty(errors);
  }

  async function test() {
    try {
      setError(null);
      setTestResult(null);
      setTestStatus(AsyncLoadStatus.Loading);

      const { data, errors } = await callSftpTest({
        variables: {
          sftp: {
            host: connector.sftpPull.host,
            port: connector.sftpPull.port,
            path: connector.sftpPull.path,
            pattern: connector.sftpPull.pattern,
            username: connector.sftpPull.username,
            password: connector.sftpPull.password,
          },
        },
      });
      if (!_.isEmpty(errors)) {
        setTestStatus(AsyncLoadStatus.Error);
        setError(
          _(errors)
            .map(e => e.message)
            .value(),
        );
        setTestStatus(AsyncLoadStatus.Error);
        return;
      }
      setTestStatus(AsyncLoadStatus.Ok);
      setTestResult(data.testImportPipelineSftpConnector);
    } catch (ex) {
      console.error(ex);
      setError([ex?.message ?? ex]);
      setTestStatus(AsyncLoadStatus.Error);
    }
  }

  return (
    <>
      <ScreenTitle
        subtitle={t`Connector properties`}
        title={t`SFTP`}
        isSticky
      />

      <TitleSection inPanelView title={t`Connection setup:`} hasPadding>
        <InputGroupList hasPadding hasSpacing panelMode>
          <InputText
            label={t`Host`}
            placeholder="sftp://my-organization.com"
            value={sftp.host ?? ''}
            onChange={v => updateSftp({ host: v })}
            className="flex-1"
          />
          <InputNumber
            title={t`Port`}
            placeholder="22"
            value={sftp.port ?? 22}
            onChange={v => updateSftp({ port: v })}
            className="flex-1"
          />
        </InputGroupList>
      </TitleSection>
      <TitleSection inPanelView title={t`Authentication:`} hasPadding>
        <InputGroupList hasPadding hasSpacing panelMode>
          <InputText
            label={t`Username`}
            placeholder="sftp-user"
            value={sftp.username ?? ''}
            onChange={v => updateSftp({ username: v })}
            className="flex-1"
          />
          <InputPassword
            label={t`Password`}
            placeholder="sftp-user"
            value={sftp.password ?? ''}
            onChange={v => updateSftp({ password: v })}
            className="flex-1"
            // labelVertical
          />
        </InputGroupList>
      </TitleSection>

      <TitleSection inPanelView title={t`File setup:`} hasPadding>
        <InputGroupList hasPadding hasSpacing panelMode>
          <InputText
            label={t`File Path`}
            placeholder="/events"
            value={sftp.path ?? ''}
            onChange={v => updateSftp({ path: _.isEmpty(v) ? null : v })}
            className="flex-1"
            // inputText
            // labelVertical
          />
          <InputText
            label={t`File mask`}
            placeholder="*-warehouseName-*.gz"
            value={sftp.pattern ?? ''}
            onChange={v => updateSftp({ pattern: _.isEmpty(v) ? null : v })}
            className="flex-1"
            // inputText
            // labelVertical
          />
          <InputCheckbox
            isSelected={
              sftp.afterPull?.action === ImportPipelineAfterPullAction.DELETE
            }
            label={t`Delete file after completion`}
            onChange={v =>
              updateSftp({
                afterPull: {
                  action: ImportPipelineAfterPullAction.DELETE,
                },
              })
            }
          >{t`Delete file after completion`}</InputCheckbox>
        </InputGroupList>
      </TitleSection>

      <TitleSection inPanelView title={t`Schedule`} hasPadding>
        <InputGroupList hasPadding hasSpacing panelMode>
          <InputText
            label={t`Cron expression`}
            placeholder="*/30 * * * *"
            value={sftp.schedule?.cronExpression ?? ''}
            onChange={v =>
              updateSftp({
                schedule: {
                  cronExpression: v,
                },
              })
            }
          />
        </InputGroupList>
      </TitleSection>
      {!_.isNil(error) && (
        <>
          <TitleSection inPanelView title={t`Connection Error:`} hasPadding>
            <ErrorIndicator message={error} />
          </TitleSection>
        </>
      )}
      {!_.isNil(testResult) && (
        <TitleSection inPanelView title={t`Connection Status:`} hasPadding>
          <StatListItem
            title={t`Total:`}
            value={formatInteger(testResult.totalFiles)}
            unitOfMeasure={`files`}
          />
          <StatListItem
            title={t`Matched:`}
            value={formatInteger(testResult.matchedFiles)}
            unitOfMeasure={`files`}
          />
        </TitleSection>
      )}

      <ActionBar sticky stickyBottom>
        <Button
          className={classNames('text-xs')}
          label={t`Cancel`}
          buttonType="secondary"
          hasIconAfter
          // buttonIcon={<Icon.ArrowsDouble />}
          onPress={() => {
            setShowEditor(false);
            setSelectedConnectorId(null);
          }}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
        <Button
          label={t`Test Connection`}
          className={classNames('flex-1 text-left text-xs', {
            'bg-alerts-error': testStatus === AsyncLoadStatus.Error,
            'bg-alerts-okay': testStatus === AsyncLoadStatus.Ok,
          })}
          buttonType="basic"
          isDisabled={testStatus === AsyncLoadStatus.Loading}
          isLoading={testStatus === AsyncLoadStatus.Loading}
          onPress={test}
        />
        <Button
          className={classNames('text-xs')}
          label={props.isNew ? t`Add` : t`Update`}
          buttonType="primary"
          onPress={applyChanges}
          isDisabled={isLoading}
        />
      </ActionBar>
    </>
  );
};

export default SFTPConnectorEditor;
