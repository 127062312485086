import React from 'react';
import { useRecoilValue } from 'recoil';
import { useAssignmentMetricDescriptors } from '../../metrics/assignment/useAssignmentMetricDescriptors';
import {
  assignmentHeatmapBuckets,
  assignmentMetricHasStats,
  assignmentMetricSelected,
} from '../store/assignment.heatmap.state';
import HeatmapLegendPanel from './HeatmapLegendPanel';

const AssignmentHeatmapLegendPanel: React.FC = () => {
  const metric = useRecoilValue(assignmentMetricSelected);
  const buckets = useRecoilValue(assignmentHeatmapBuckets);
  const bucketsWithStats = useRecoilValue(assignmentMetricHasStats);
  const metricDescriptorMap = useAssignmentMetricDescriptors();
  const metricDescriptor = metricDescriptorMap[metric];

  return (
    <HeatmapLegendPanel
      metricDescriptor={metricDescriptor as any}
      buckets={buckets}
      hasStats={bucketsWithStats}
    />
  );
};

export default AssignmentHeatmapLegendPanel;
