import { AnalyzeResultLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { selector, selectorFamily } from 'recoil';
import {
  getAnalyzeProductHeatmapMetricRange,
  HeatmapBucket,
} from '../common/heatmap.helper';
import { HeatmapMetricRange } from '../common/heatmap.types';
import {
  viewerSelectedLevel,
  viewerSelectedPlaneId,
  viewerShowLocations,
} from '../layout/viewer/store/viewer.state';
import { getAnalyzeProductHeatmapBuckets } from '../metrics/analyzeProduct/analyzeProductMetric.helper';
import { analyzeProductMetricDescriptor } from '../metrics/analyzeProduct/analyzeProductMetric.state';
import { AnalyzeProductDataRow } from '../metrics/analyzeProduct/analyzeProductMetric.types';
import { loadLocationHeatmap } from '../simulation/store/simulation.helper';
import { namedColorsGroupSelected } from '../store/namedColors.state';
import { getAnalyzeCompareDataRows } from './analyzeCompare.helper';
import {
  analyzeCompareIdByType,
  analyzeCompareResultByType,
} from './analyzeCompare.state';
import { AnalyzeCompareAnalyzeType } from './analyzeCompare.types';

const getKey = (postfix: string) =>
  `warebee-analyze-product-heatmap-${postfix}`;

export const analyzeCompareHeatmapRangeDescriptor =
  selector<HeatmapMetricRange>({
    key: getKey('range-descriptor'),
    get: ({ get }) => {
      const first = get(analyzeCompareResultByType('first'));
      const second = get(analyzeCompareResultByType('second'));
      const metricDescriptor = get(analyzeProductMetricDescriptor);
      const dataFirst = get(analyzeCompareLocationByLevelArray('first')) ?? [];
      const dataSecond =
        get(analyzeCompareLocationByLevelArray('second')) ?? [];

      return getAnalyzeProductHeatmapMetricRange(
        metricDescriptor,
        [first, second],
        _.concat(dataFirst, dataSecond),
      );
    },
  });

export const analyzeCompareBuckets = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets'),
  get: ({ get }) => {
    const namedColors = get(namedColorsGroupSelected);
    const metricDescriptor = get(analyzeProductMetricDescriptor);
    const rangeDescriptor = get(analyzeCompareHeatmapRangeDescriptor);
    if (_.isNil(rangeDescriptor)) return null;
    return getAnalyzeProductHeatmapBuckets(
      rangeDescriptor,
      metricDescriptor,
      namedColors,
    );
  },
});

const analyzeCompareLocationByLevelRaw = selectorFamily<
  Record<string, AnalyzeResultLocationFragment>,
  AnalyzeCompareAnalyzeType
>({
  key: getKey('locations-level-heatmap-raw-family'),
  get:
    (analyzeType: AnalyzeCompareAnalyzeType) =>
    async ({ get }) => {
      const analyzeId = get(analyzeCompareIdByType(analyzeType));
      const areaId = get(viewerSelectedPlaneId);
      const level = get(viewerSelectedLevel);
      const showLocation = get(viewerShowLocations);

      if (!showLocation || _.isNil(level) || !analyzeId || !areaId) return null;

      const locationMap = await loadLocationHeatmap({
        analyzeId,
        areaId,
        level,
      });
      return locationMap;
    },
});

export const analyzeCompareLocationByLevelArray = selectorFamily<
  AnalyzeProductDataRow[],
  AnalyzeCompareAnalyzeType
>({
  key: getKey('locations-level-heatmap-flat-family'),
  get:
    (analyzeType: AnalyzeCompareAnalyzeType) =>
    async ({ get }) => {
      const locationMapRaw = get(analyzeCompareLocationByLevelRaw(analyzeType));
      const metricDescriptor = get(analyzeProductMetricDescriptor);
      const rows = getAnalyzeCompareDataRows(
        _.flatten(_.values(locationMapRaw)),
        metricDescriptor,
      );
      return rows;
    },
});

export const analyzeCompareLocationByLevel = selectorFamily<
  Record<string, AnalyzeProductDataRow[]>,
  AnalyzeCompareAnalyzeType
>({
  key: getKey('locations-level-heatmap-family'),
  get:
    (analyzeType: AnalyzeCompareAnalyzeType) =>
    async ({ get }) => {
      const data = get(analyzeCompareLocationByLevelArray(analyzeType));
      const locationMap = _.groupBy(data, loc => loc.locationId);
      return locationMap;
    },
});
