import * as React from 'react';
const AlertNotificationNoneIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlertNotificationNoneIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M111.26,99.22c-9.22-9.17-13.9-20.02-13.9-32.25v-17.8c.09-13.57-9.04-22.58-16.89-26.16-2.21-1.01-4.54-1.76-6.92-2.33v-1.21c0-5.46-4.44-9.9-9.9-9.9s-9.9,4.44-9.9,9.9v1.4c-2.14.56-4.24,1.24-6.23,2.16-8.67,4-17.45,13.62-17.53,26.13v17.82c0,12.64-4.47,23.5-13.27,32.25-.52.52-.72,1.29-.49,2,.22.71.82,1.23,1.55,1.36l18.79,3.24c3.54.61,6.76,1.1,9.79,1.5,3.45,6.77,10.24,11.12,17.65,11.12s14.2-4.35,17.66-11.13c3.01-.4,6.21-.89,9.74-1.49l18.79-3.24c.73-.13,1.33-.65,1.55-1.36.22-.71.03-1.48-.49-2ZM57.72,19.46c0-3.27,2.66-5.94,5.94-5.94s5.94,2.66,5.94,5.94v.38c-3.94-.56-7.97-.53-11.88.1v-.48ZM41.89,51.13h-.01c-1.09,0-1.97-.9-1.97-1.99.06-9.27,7.43-15.13,11.77-17.13,7.62-3.51,17.01-3.51,24.68,0,.99.45,1.43,1.63.98,2.62-.46.99-1.63,1.43-2.62.98-6.64-3.04-14.78-3.04-21.38,0-3.5,1.61-9.43,6.28-9.47,13.56,0,1.09-.89,1.97-1.98,1.97Z"
    />
  </svg>
);
export default AlertNotificationNoneIcon;
