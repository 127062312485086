import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  componentName?: string;
  containerMaxHeight?: number;
  containerMinHeight?: number;
  containerHeight?: string | number;
};

export const ContainerChart = ({
  children,
  className,
  componentName,
  containerHeight = 300,
  containerMaxHeight, // = 300,
  containerMinHeight, // = 320,
}: ContainerProps) => {
  const [id] = useState<string>(_.uniqueId());

  const { t } = useTranslation('simulation');

  return (
    <div
      id={id}
      data-component={`ContainerChart${
        componentName ? '-' + componentName : ''
      }`}
      className={classNames(
        'relative flex flex-1 flex-col items-center',
        className,
      )}
      style={{
        minHeight: containerHeight,
        // maxHeight: containerMaxHeight,
        height: containerHeight,
        width: '100%',
        minWidth: '100%',
      }}
    >
      {children}
    </div>
  );
};

export default ContainerChart;
