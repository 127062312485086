import { AnalyzeResultSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { Stat } from '../../components/stats/Stat';
import StatItem from '../../components/stats/StatItem';
import { getAnalyzeTotals, getCarbonValue } from '../store/analyze.helper';

export type AnalyzeStatsPanelProps = {
  analyzeResult: AnalyzeResultSummaryFragment;
  analyzeResultToCompare?: AnalyzeResultSummaryFragment;
  dashboardSimulationView?: boolean;
};

const AnalyzeStatsPanel: React.FC<AnalyzeStatsPanelProps> = props => {
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');
  if (!props.analyzeResult) return null;

  const kpi = [props.analyzeResult, props.analyzeResultToCompare].map(
    result => {
      if (_.isNil(result)) return null;
      const totals = getAnalyzeTotals(result);
      const distance = formatter.formatDistance(totals.distance);
      const duration = formatter.formatTimeSpan(totals.duration);
      const cost = formatter.formatCurrency(totals.cost);

      const assembledOrdersCount = formatInteger(result.totalAssembledOrders);
      const partiallyAssembledOrdersCount = formatInteger(
        result.totalAssembledPartiallyOrders,
      );
      const ignoredOrdersCount = formatInteger(
        result.totalOrders -
          result.totalAssembledOrders -
          result.totalAssembledPartiallyOrders,
      );

      const totalAllOrderSet = result.totalOrders;
      const totalAllOrderSetCount = formatInteger(totalAllOrderSet);

      const totalPickedOrders =
        result.totalAssembledOrders + result.totalAssembledPartiallyOrders;
      const totalPickedOrdersCount = formatInteger(totalPickedOrders);

      const carbonValue = getCarbonValue(result);
      const carbon = formatter.formatCO2(carbonValue);

      return {
        assembledOrdersCount,
        partiallyAssembledOrdersCount,
        ignoredOrdersCount,
        totalPickedOrdersCount,
        distance,
        duration,
        cost,
        carbon,
      };
    },
  );

  return (
    <Stat
      dashboardSimulationView={props.dashboardSimulationView}
      title={t`Total Picked Orders`}
      value={kpi[0]?.totalPickedOrdersCount ?? 0}
      cardView={true}
      titleAlt={`Picked: ${kpi[0]?.assembledOrdersCount ?? 0}\n
                 Partial: ${kpi[0]?.partiallyAssembledOrdersCount ?? 0}\n
                 Ignored: ${kpi[0]?.ignoredOrdersCount ?? 0}\n
                 `}
      hasHelper
      isPreview
      inPanelMode
    >
      <StatItem
        title={t`Total Cost`}
        value={kpi[0]?.cost}
        valueToCompare={kpi[1]?.cost}
      />
      <StatItem
        title={t`Total Time`}
        value={kpi[0]?.duration}
        valueToCompare={kpi[1]?.duration}
      />
      <StatItem
        title={t`Total Distance`}
        value={kpi[0]?.distance}
        valueToCompare={kpi[1]?.distance}
      />
      {kpi[0]?.carbon.raw > 0 && (
        <StatItem
          title={t`Total CO2 Footprint`}
          value={kpi[0]?.carbon}
          valueToCompare={kpi[1]?.carbon}
        />
      )}
    </Stat>
  );
};

export default AnalyzeStatsPanel;
