import * as React from 'react';
const DashboardDataIcon = props => (
  <svg
    aria-hidden="true"
    data-name="DashboardDataIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={120} height={12.68} x={4} y={4.12} opacity={0.8} />
    <path
      d="m4,20.59v58h58.5V20.59H4Zm4.36,10.98c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83H9.43c-.54,0-1.07-.42-1.07-.83v-2.5Zm49.68,41.06c0,.77-.77,1.55-1.55,1.55H9.9c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h46.59c.77,0,1.55.77,1.55,1.55v4.64Z"
      opacity={0.8}
    />
    <path d="m4,123.79h38v-43H4v43Zm3.54-39.28c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83H8.61c-.54,0-1.07-.42-1.07-.83v-2.5Zm0,8.97c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55H9.08c-.77,0-1.55-.77-1.55-1.55v-4.64Zm0,10.36c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55H9.08c-.77,0-1.55-.77-1.55-1.55v-4.64Zm0,10.36c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55H9.08c-.77,0-1.55-.77-1.55-1.55v-4.64Z" />
    <path d="m45,123.79h38v-43h-38v43Zm3.54-39.28c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83h-19.33c-.54,0-1.07-.42-1.07-.83v-2.5Zm0,8.97c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Zm0,10.36c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Zm0,10.36c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Z" />
    <path d="m86,123.79h38v-43h-38v43Zm3.54-39.28c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83v2.5c0,.42-.54.83-1.07.83h-19.33c-.54,0-1.07-.42-1.07-.83v-2.5Zm0,8.97c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Zm0,10.36c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Zm0,10.36c0-.77.77-1.55,1.55-1.55h27.83c.77,0,1.55.77,1.55,1.55v4.64c0,.77-.77,1.55-1.55,1.55h-27.83c-.77,0-1.55-.77-1.55-1.55v-4.64Z" />
    <path
      d="m124,20.59h-58.5v58h58.5V20.59Zm-33.97,8.98v2.5c0,.42-.54.83-1.07.83h-19.33c-.54,0-1.07-.42-1.07-.83v-2.5c0-.42.54-.83,1.07-.83h19.33c.54,0,1.07.42,1.07.83Zm31.2,44.19c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64Zm0-9.87c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64Zm0-10.36c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64Zm0-10.36c0,.77-.77,1.55-1.55,1.55h-49.59c-.77,0-1.55-.77-1.55-1.55v-4.64c0-.77.77-1.55,1.55-1.55h49.59c.77,0,1.55.77,1.55,1.55v4.64Z"
      opacity={0.6}
    />
  </svg>
);
export default DashboardDataIcon;
