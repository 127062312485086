import * as React from 'react';
const DesignerBaysAlignTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerBaysAlignTopIcon"
    {...props}
  >
    <rect width={120.05} height={5.42} x={4.19} y={2.03} strokeWidth={0} />
    <rect width={14} height={27.33} x={4.07} y={69.31} strokeWidth={0} />
    <rect width={14} height={27.33} x={4.07} y={39.97} strokeWidth={0} />
    <rect width={14} height={27.33} x={4.07} y={10.64} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={39.97} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={69.31} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={98.64} strokeWidth={0} />
    <rect width={14} height={27.33} x={20.28} y={10.64} strokeWidth={0} />
    <rect width={14} height={27.33} x={65.11} y={10.82} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={40.15} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={10.82} strokeWidth={0} />
    <rect width={14} height={27.33} x={65.11} y={39.97} strokeWidth={0} />
    <rect width={14} height={27.33} x={48.89} y={69.99} strokeWidth={0} />
    <rect
      width={10}
      height={115.31}
      x={36.59}
      y={10.64}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={10}
      height={115.31}
      x={81.43}
      y={10.64}
      strokeWidth={0}
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="94.42 24.4 108.03 10.82 121.65 24.43 121.65 32.48 110.89 21.72 110.89 42.17 105.18 42.16 105.18 21.72 94.42 32.45 94.42 24.4"
    />
  </svg>
);
export default DesignerBaysAlignTopIcon;
