import { Field, ObjectType } from '@warebee/shared/util-backend-only-types';
import { Expose } from 'class-transformer';
import _ from 'lodash';
import {
  AisleSide,
  LayoutImportLocation,
} from './layout-import-location.model';

export const ALL_AISLE_SIDES: AisleSide[] = [AisleSide.LEFT, AisleSide.RIGHT];

@ObjectType()
export class LayoutImportLevelSummary {
  constructor(input: Partial<LayoutImportLevelSummary>) {
    Object.assign(this, input);
  }

  @Field()
  level: number;

  @Field()
  locationCount: number;

  @Field()
  usedWidth: number;

  @Field({ defaultValue: 0 })
  height: number;
}

@ObjectType()
export class LayoutImportBay {
  constructor(input: Partial<LayoutImportBay>) {
    Object.assign(this, input);
  }

  @Field()
  bayId: string;

  @Field()
  bay: number;

  @Field(() => [LayoutImportLocation])
  locations: LayoutImportLocation[];

  @Field(() => [LayoutImportLevelSummary])
  @Expose()
  get levels() {
    return _.values(
      _.mapValues(
        _.groupBy(this.locations, l => l.locationLevel),
        locs =>
          new LayoutImportLevelSummary({
            level: locs[0].locationLevel,
            locationCount: locs.length,
            usedWidth: _.sumBy(locs, l => parseFloat(l.locationWidth as any)),
          }),
      ),
    );
  }

  @Field()
  @Expose()
  get depth(): number {
    return Math.max(...this.locations.map(l => l.locationLength));
  }

  @Field()
  @Expose()
  get locationCount(): number {
    return this.locations.length;
  }

  @Field({ nullable: true })
  @Expose()
  get bayType(): string {
    return this.locations[0]?.bayType;
  }
}

@ObjectType()
export class LayoutImportSide {
  constructor(input: Partial<LayoutImportSide>) {
    Object.assign(this, input);
  }

  @Field(() => AisleSide)
  side: AisleSide;

  @Field(() => [LayoutImportBay])
  bays: LayoutImportBay[];

  @Field()
  @Expose()
  get locationCount(): number {
    return _.sumBy(this.bays, b => b.locationCount);
  }
}

@ObjectType()
export class LayoutImportAisle {
  constructor(input: Partial<LayoutImportAisle>) {
    Object.assign(this, input);
  }

  @Field()
  aisleId: string;

  @Field()
  aisle: string;

  @Field(() => [LayoutImportSide])
  sides: LayoutImportSide[];

  @Field()
  @Expose()
  get locationCount(): number {
    return _.sumBy(this.sides, s => s.locationCount);
  }
}

@ObjectType()
export class LayoutImportArea {
  constructor(input: Partial<LayoutImportArea>) {
    Object.assign(this, input);
  }

  @Field()
  area: string;

  @Field(() => [LayoutImportAisle])
  aisles: LayoutImportAisle[];

  @Field()
  @Expose()
  get locationCount(): number {
    return _.sumBy(this.aisles, a => a.locationCount);
  }
}

export function groupLocations(
  locations: LayoutImportLocation[],
): LayoutImportArea[] {
  const areas: Record<string, LayoutImportArea> = {};
  const aisles: Record<string, LayoutImportAisle> = {};
  const bays: Record<string, LayoutImportBay> = {};

  locations.forEach(l => {
    const {
      warehouseArea: areaId,
      locationAisle: aisleId,
      baySide: sideId,
    } = l;
    const areaKey = areaId;
    const aisleKey = areaKey + '-' + aisleId;
    const bayKey = l.locationBayId;

    let bay = bays[bayKey];
    if (bay) {
      bay.locations.push(l);
      return;
    }
    bay = new LayoutImportBay({
      bayId: l.locationBayId,
      bay: l.locationBay,
      locations: [l],
    });
    bays[bayKey] = bay;

    let aisle = aisles[aisleKey];
    if (aisle) {
      const side = aisle.sides.find(s => s.side == sideId);
      if (side) {
        side.bays.push(bay);
        return;
      } else {
        aisle.sides.push(
          new LayoutImportSide({ side: sideId as AisleSide, bays: [bay] }),
        );
        return;
      }
    } else {
      aisle = new LayoutImportAisle({
        aisleId: aisleKey,
        aisle: aisleId,
        sides: [
          new LayoutImportSide({ side: sideId as AisleSide, bays: [bay] }),
        ],
      });
      aisles[aisleKey] = aisle;
    }

    const area = areas[areaKey];
    if (area) {
      area.aisles.push(aisle);
    } else {
      areas[areaKey] = new LayoutImportArea({
        area: areaId,
        aisles: [aisle],
      });
    }
  });

  return _.sortBy(_.values(areas), a => a.area);
}
