import * as React from 'react';
const Settings4Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Settings4Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M107.78,124l-8.41-8.41,5.79-5.79c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-5.79,5.79-5.61-5.61,3.48-3.48c.64-.64.53-1.79-.24-2.56-.78-.77-1.92-.88-2.56-.24l-3.48,3.48-5.61-5.61,5.79-5.79c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-5.79,5.79-5.61-5.61,3.47-3.48c.64-.64.53-1.79-.24-2.56-.78-.77-1.92-.88-2.56-.24l-3.48,3.48-5.61-5.61,5.79-5.79c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-5.79,5.79-5.61-5.61,3.48-3.48c.64-.64.53-1.79-.24-2.56-.78-.77-1.92-.88-2.56-.24l-3.48,3.48-5.61-5.61,5.79-5.79c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-5.79,5.79-5.61-5.61,3.47-3.48c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-3.48,3.48-5.61-5.61,5.79-5.79c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-5.79,5.79-5.61-5.61,3.48-3.48c.64-.64.53-1.79-.24-2.56-.78-.77-1.92-.88-2.56-.24l-3.48,3.48-5.61-5.61,5.79-5.79c.64-.64.53-1.79-.24-2.56-.77-.77-1.92-.88-2.56-.24l-5.79,5.79-8.41-8.41L20.23,4l103.77,103.78-16.22,16.22Z"
    />
    <path
      strokeWidth={0}
      d="M113.82,13.56l-4.43,18.65-13.69-13.69,18.12-4.96ZM101.17,30.23l5.1,5.1-23.21,23.21-5.1-5.1,23.21-23.21ZM53.52,77.88l5.1,5.1-31.98,31.98-5.1-5.1,31.98-31.98ZM93.08,22.14l5.1,5.1-23.21,23.21-5.1-5.1,23.21-23.21ZM44.75,69.12l5.1,5.1-31.98,31.98-5.1-5.1,31.98-31.98ZM8.83,104.49l14.07,14.21-4.37,4.37c-2.45,2.45-6.51,2.24-9.24-.5l-4.4-4.4c-2.73-2.73-2.95-6.79-.5-9.24l4.44-4.44Z"
    />
  </svg>
);
export default Settings4Icon;
