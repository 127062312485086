import _ from 'lodash';
import {
  Aisle,
  Plane,
  Bay,
  BayLocationOrder,
  ConnectivityGraph,
  Location,
} from '@warebee/shared/engine-model';
import {
  VisualPlaneMap,
  VisualBay,
  VisualLocation,
  VisualLocationPortals,
} from './visual-layout-map.model';
import {
  generateConnectivityGraph,
  getAisleIdByLocationBinding,
} from './layout-connectivity.engine';
import {
  bindBayLocations,
  generateLocationPortals,
} from './location-binding.engine';

export interface SampleLayoutGeneratorOptions {
  rowHeight?: number;
  colWidth?: number;
  uniqueIds?: boolean;
  planeId?: string;
}

interface ObjectSegment {
  id: string;
  row: number;
  colStart: number;
  colEnd: number;
  type: 'aisle' | 'bay';
  navigable: boolean;
  faceOrientation?: 'left' | 'right';
}

const SPECIAL_CHARS = ['|', ':', '[', ']', '=', '>', '<'];

function parseRow(input: string, row: number): ObjectSegment[] {
  if (input.length === 0) {
    return [];
  }

  let col = 0;
  let c = input[0];

  const result: ObjectSegment[] = [];

  function gc() {
    col++;
    if (col < input.length) {
      c = input[col];
    } else {
      c = 'EOL';
    }
  }

  function skipSpace() {
    while (c === ' ') {
      gc();
    }
  }

  function parseLabel(stopChars: string[], invalidChars: string[]): string {
    const startCol = col;

    for (;;) {
      if (c === 'EOL') {
        throw new Error(
          `row ${row}, column ${col}: expected one of ${stopChars.join(
            ', ',
          )}, got EOL`,
        );
      } else if (stopChars.includes(c)) {
        return input.substring(startCol, col).trim();
      } else if (invalidChars.includes(c)) {
        throw new Error(
          `row ${row}, column ${col}: expected one of ${stopChars.join(
            ', ',
          )}, got ${c}`,
        );
      } else {
        gc();
      }
    }
  }

  function parseAisle(colStart: number, navigable: boolean) {
    const label = parseLabel(navigable ? ['|'] : [':'], SPECIAL_CHARS);
    gc();
    result.push({
      row,
      colStart,
      colEnd: col,
      type: 'aisle',
      navigable,
      id: label || _.uniqueId(),
    });
  }

  function parseBay(
    colStart: number,
    navigable: boolean | null,
    faceOrientation: 'left' | 'right',
  ) {
    const label = parseLabel(
      faceOrientation === 'right' ? ['>'] : ['=', ']'],
      SPECIAL_CHARS,
    );
    if (faceOrientation === 'left') {
      navigable = c === '=';
    }
    gc();
    result.push({
      row,
      colStart,
      colEnd: col,
      type: 'bay',
      navigable,
      id: label || _.uniqueId(),
      faceOrientation,
    });
  }

  skipSpace();

  while (c !== 'EOL') {
    if (c == '|') {
      const colStart = col;
      gc();
      parseAisle(colStart, true);
    } else if (c == ':') {
      const colStart = col;
      gc();
      parseAisle(colStart, false);
    } else if (c == '[') {
      const colStart = col;
      gc();
      parseBay(colStart, false, 'right');
    } else if (c == '=') {
      const colStart = col;
      gc();
      parseBay(colStart, true, 'right');
    } else if (c == '<') {
      const colStart = col;
      gc();
      parseBay(colStart, null, 'left');
    } else {
      throw new Error(
        `row ${row}, column ${col}: expected one of |, :, [, =, < or whitespace, got "${c}"`,
      );
    }

    skipSpace();
  }

  return result;
}

function mergeSegments(
  id: string,
  segments: ObjectSegment[],
  rowCount: number,
  options: SampleLayoutGeneratorOptions,
): ['aisle', Aisle] | ['bay', Bay] {
  const types = _.uniq(segments.map(s => s.type));
  if (types.length > 1) {
    throw new Error(`different types for object ${id}`);
  }

  const rows = _.sortBy(segments.map(s => s.row));
  const expectedRows = _.range(rows[0], rows[rows.length - 1] + 1);

  if (!_.isEqual(rows, expectedRows)) {
    throw new Error(
      `object ${id} is disjoint - expected to occupy rows ${expectedRows.join(
        ', ',
      )}, was ${rows.join(', ')}`,
    );
  }

  const startCols = _.uniq(segments.map(s => s.colStart));
  const endCols = _.uniq(segments.map(s => s.colEnd));

  if (startCols.length > 1 || endCols.length > 1) {
    throw new Error(`object ${id} is not rectangular`);
  }

  const minY = (rowCount - rows[rows.length - 1]) * options.rowHeight;
  const sizeY = rows.length * options.rowHeight;
  const minX = startCols[0] * options.colWidth;
  const sizeX = (endCols[0] - startCols[0]) * options.colWidth;

  if (types[0] === 'aisle') {
    const navFlags = _.uniq(segments.map(s => s.navigable));
    if (navFlags.length > 1) {
      throw new Error(
        `parts of aisle ${id} have different navigability status`,
      );
    }

    return [
      'aisle',
      {
        id: options.uniqueIds ? _.uniqueId() : id,
        title: id,
        nonNavigable: !navFlags[0],
        points: [
          { x: minX, y: minY },
          { x: minX, y: minY + sizeY },
          { x: minX + sizeX, y: minY + sizeY },
          { x: minX + sizeX, y: minY },
        ],
      } as Aisle,
    ];
  } else if (types[0] === 'bay') {
    const navFlags = _.uniq(segments.map(s => s.navigable));
    if (navFlags.length > 1) {
      throw new Error(`parts of bay ${id} have different navigability status`);
    }
    const faceOrientations = _.uniq(segments.map(s => s.faceOrientation));
    if (faceOrientations.length > 1) {
      throw new Error(`parts of bay ${id} have different face orientation`);
    }
    return [
      'bay',
      {
        id: options.uniqueIds ? _.uniqueId() : id,
        title: id,
        hasPass: navFlags[0],
        depth: sizeX,
        width: sizeY,
        position:
          faceOrientations[0] === 'right'
            ? { x: minX + sizeX, y: minY }
            : { x: minX, y: minY + sizeY },
        frontEdge:
          faceOrientations[0] === 'right'
            ? { x: 0, y: sizeY }
            : { x: 0, y: -sizeY },
        locationOrder:
          faceOrientations[0] === 'right'
            ? BayLocationOrder.LTR
            : BayLocationOrder.RTL,
      } as Bay,
    ];
  }
}

const DEFAULT_OPTIONS: SampleLayoutGeneratorOptions = {
  colWidth: 10,
  rowHeight: 10,
};

/**
 * Generates sample plane layout from schematic ASCII representation.
 *
 * Supported input sequences:
 *
 *    [ B > or < B ] - bay, where > or < represents front side
 *    = B > or < B = - navigable bay
 *    | A | - navigable aisle
 *    : A : - non-navigable aisle
 *
 * Objects on different lines having the same label are merged. Objects without labels have unique labels generated.
 * Currently only rectangular objects are supported.
 * @param input
 * @param options
 * @returns
 */
export function generatePlaneLayout(
  input: string,
  options: SampleLayoutGeneratorOptions = {},
): Plane {
  const effectiveOptions = { ...DEFAULT_OPTIONS, ...options };

  const rows = input.split(/\r?\n/);

  const segments = rows.flatMap((r, i) => parseRow(r, i));

  const segmentsById = _.groupBy(segments, s => s.id);

  const aisles = [];
  const bays = [];

  Object.entries(segmentsById).forEach(([id, segs]) => {
    const [type, r] = mergeSegments(id, segs, rows.length, effectiveOptions);
    if (type === 'aisle') {
      aisles.push(r);
    } else {
      bays.push(r);
    }
  });

  return {
    aisles,
    bays,
    id: options.planeId || _.uniqueId(),
    title: options.planeId,
  };
}

type LocationGenerator = (
  b: Bay,
) => (string | (Pick<Location, 'locationId'> & Partial<Location>))[];

export function generateLocations(
  a: Pick<Plane, 'bays'> & Partial<Plane>,
  generator: LocationGenerator,
  connectivityGraph?: ConnectivityGraph,
): (VisualLocation<Location> & VisualLocationPortals)[] {
  const { bays, aisles } = a;
  const plane = VisualPlaneMap.load(a as any);
  if (!connectivityGraph && a.aisles) {
    connectivityGraph = generateConnectivityGraph(plane);
  }

  return bays.flatMap(bay => {
    const locationSpecs = generator(bay);
    const specsWithWidth = locationSpecs.filter(
      spec => _.isObject(spec) && spec.locationWidth,
    );
    const usedWidth = specsWithWidth
      .map(loc => (loc as any).locationWidth)
      .reduce((a, b) => a + b, 0);
    if (usedWidth > bay.width) {
      throw new Error(
        `requested location width ${usedWidth} exceeds bay width ${bay.width} for bay ${bay.id}`,
      );
    }
    const specsWithoutWidthCount = locationSpecs.length - specsWithWidth.length;
    const generatedWidth =
      specsWithoutWidthCount > 0
        ? (bay.width - usedWidth) / specsWithoutWidthCount
        : null;

    const bindableLocations = locationSpecs.map((spec, pos) => {
      const effectiveSpec = _.isObject(spec)
        ? spec
        : { locationId: spec, locationWidth: 0 };
      return {
        locationStatus: true,
        locationLevel: 1,
        locationDepthPosition: 1,
        ...effectiveSpec,
        locationBayId: bay.id,
        locationBayPosition: pos,
        locationWidth: effectiveSpec.locationWidth || generatedWidth,
        locationLength: effectiveSpec.locationLength || bay.depth,
      } as Location;
    });

    const vb = VisualBay.load(bay, null);
    return bindBayLocations(vb, bindableLocations, {}, {}).map(loc => {
      const portalSpecs = [];
      if (connectivityGraph) {
        const aisleId = getAisleIdByLocationBinding(connectivityGraph, loc);
        loc.aisleId = aisleId;
        portalSpecs.push({ aisleId });
      }
      return generateLocationPortals(loc, portalSpecs, plane.aislesById);
    });
  });
}
