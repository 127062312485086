import { AnalyzeResultStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { endOfDay, startOfDay } from 'date-fns';
import _ from 'lodash';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AnalyzeComponentBaseProps } from '../../../../analyze/analyze.types';
import { formatInteger } from '../../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../../common/types';
import ErrorIndicator from '../../../../components/ErrorIndicator';
import InboxZero from '../../../../components/InboxZero';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import { Container } from '../../../../components/layout/ContainerFlex';
import { Stat } from '../../../../components/stats/Stat';
import { StatGroup } from '../../../../components/stats/StatGroup';
import AgentSummary from '../../../../resourcePolicy/AgentSummary';
import { AgentCategoryId } from '../../../../resourcePolicy/agentData/agent.types';
import {
  getAgentIcon,
  getAgentTypeTitle,
} from '../../../../resourcePolicy/agentData/agentDataHelper';
import { HelperTemplate } from '../../../helper/HelperTemplate';
import useUpdateWorkforceSettings from '../../../hooks/useUpdateWorkforceSettings';
import { resourcePolicyPickingAgents } from '../../../store/resourcePolicy.state';
import {
  simulationAnalyzeDistinctDays,
  simulationAnalyzeInterval,
  simulationLoadStatus,
} from '../../../store/simulation.state';
import {
  workforceAgentForecastEnabled,
  workforceAllAgentSettingsMap,
  workforceSelectedAgentId,
  workforceSimulateInterval,
} from '../../../store/workforce.state';
import { WorkforceScheduleMap } from '../../../store/workforce.types';
import WorkforceAgentTotals from '../WorkforceAgentTotals';
import WorkforceSummaryPanel from '../summary/WorkforceSummaryPanel';
import WorkforceForecastSchedule from './WorkforceForecastSchedule';

const WorkforceForecastPanel: React.FC<AnalyzeComponentBaseProps> = props => {
  const { t } = useTranslation('simulation');
  const analyzeDays = useRecoilValue(simulationAnalyzeDistinctDays);
  const simulationStatus = useRecoilValue(simulationLoadStatus);
  const analyzeResult = props.analyzeResult;
  const analyzeInterval = useRecoilValue(simulationAnalyzeInterval);
  const [simulateInterval, setSimulateInterval] = useRecoilState(
    workforceSimulateInterval,
  );
  const agents = useRecoilValue(resourcePolicyPickingAgents);
  const [selectedAgentId, setSelectedAgentId] = useRecoilState(
    workforceSelectedAgentId,
  );
  const [isForecastSimulation, setForecastSimulation] = useRecoilState(
    workforceAgentForecastEnabled(selectedAgentId),
  );
  const updateSimSettings = useUpdateWorkforceSettings();
  const workforceSchedules = useRecoilValue(workforceAllAgentSettingsMap);

  const [previousSchedules, setPreviousSchedules] =
    useState<WorkforceScheduleMap>();

  useEffect(() => {
    if (previousSchedules === undefined) {
      setPreviousSchedules(workforceSchedules);
      return;
    }

    updateSimSettings(workforceSchedules);
  }, [workforceSchedules]);

  const hasError = analyzeResult?.status === AnalyzeResultStatus.FAILED;
  const isLoading =
    simulationStatus === AsyncLoadStatus.Loading || _.isNil(analyzeResult);

  if (hasError) {
    return (
      <>
        <HelperTemplate
          title={t`Analyse Summary`}
          subtitle={t`Analyse Error`}
          hasPadding
        >
          {t`Looks like there is an issue with analysing your warehouse, please fix the issue and try again.`}
        </HelperTemplate>
        <ErrorIndicator selfCenter message={[t`Analyze run has failed.`]} />
      </>
    );
  }

  const showWorkforce =
    analyzeResult?.status === AnalyzeResultStatus.COMPLETED && analyzeDays >= 1;

  function setInterval([from, to]: [Date, Date]) {
    setSimulateInterval([startOfDay(from), endOfDay(to)]);
  }

  if (isLoading) {
    return <LoadingIndicator message={t`Loading analyse results`} selfCenter />;
  }

  if (!showWorkforce) {
    return (
      <InboxZero
        selfCenter
        message={t`Workforce labour stats are unavailable!`}
        message_helper={t`No order dates found`}
      />
    );
  }

  const allAgentsId = 'all-agents-summary';
  const selectAgentOptions = _.keyBy(
    [
      {
        id: allAgentsId,
        title: t`Summary`,
        icon: Icon.ArrowRight,
      },
      ..._(agents)
        .map(a => ({
          id: a.id,
          title: a.title,
          icon: getAgentIcon(a.resourceTypeId as any),
        }))
        .value(),
    ],
    'id',
  );

  const SelectOption = (props: { id: string }) => {
    const options = selectAgentOptions[props.id];
    const Icon = options.icon;
    return (
      <div key={`option-${props.id}`} className="flex">
        <Icon className="w-6 h-6 fill-current mr-2" /> {options.title}
      </div>
    );
  };

  const LoadingContainer = (
    <Container col hasNoScroll className={'items-center justify-center h-full'}>
      <LoadingIndicator selfCenter message={t('Loading...')} />
    </Container>
  );

  // const simulationOptions: ButtonSwitchMultiOption[] = [
  //   {
  //     icon: (
  //       <div className="flex items-center">
  //         <span className="flex-1">{t`Current`}</span>
  //         <Icon.TimeFull className={`ltr:ml-2 rtl:mr-2 w-5 h-5 fill-current`} />
  //       </div>
  //     ),
  //     label: t`Current`,
  //   },
  //   {
  //     icon: (
  //       <div className="flex items-center">
  //         <span className="flex-1">{t`Forecast simulation`}</span>
  //         <Icon.Forecast className={`ltr:ml-2 rtl:mr-2 w-5 h-5 fill-current`} />
  //       </div>
  //     ),
  //     label: t`Forecast simulation`,
  //   },
  // ];

  const agent = selectedAgentId ? agents[selectedAgentId] : null;
  const agentTypeTitle = agent
    ? getAgentTypeTitle(agent.resourceTypeId, t)
    : '';

  return (
    <>
      {/* <HelperContainer
          hasPadding
          collapsible
          id={'helper-simulation-analyse-results'}
          hideMessage={t`Hide helper`}
          showMessage={t`Show Help`}
          showIcon={Icon.CircleHelp}
          hideIcon={Icon.ArrowRight}
          message={t`Your warehouse is now analysed.`}
        >
          <HelperMessage isSuggestion hasBefore>
            {t`Scroll down to review, the summary, ABC Analytics, Order details and Routes.`}
          </HelperMessage>
        </HelperContainer> */}
      {/* <TitleSection
        id={'simulation-time-range'}
        collapsible
        inPanelView
        title={t`Time Range`}
      >
        <InputDateRange
          range={simulateInterval}
          availableRange={analyzeInterval}
          onChange={setInterval}
          className={'ltr:mr-2 rtl:ml-2'}
        />
        <footer className="w-full bg-app-panel-dark/60 py-2 px-4">
          <Button
            className="rounded border border-menu-500"
            label={t`Reset`}
            buttonSize="xs"
            buttonType="secondary"
            onPress={() => setSimulateInterval(null)}
          />
        </footer>
      </TitleSection> */}

      {/* <ContainerScroll> */}
      <header
        className={classNames(
          'w-full flex flex-col',
          'p-2',
          'sticky top-0 z-dropdown_menu',
          'bg-app-panel/70 backdrop-filter backdrop-blur-lg backdrop-saturate-110',
        )}
      >
        <DropdownSelector
          widthFull
          isSelected
          className={classNames(
            // 'bg-app-panel-dark/70 text-menu-active',
            'rounded-full px-2 lg:w-auto mb-4 lg:mb-0',
          )}
          classNameLabel={classNames('text-sm opacity-80')}
          value={selectedAgentId ?? allAgentsId}
          values={_.keys(selectAgentOptions)}
          iconClass={classNames('text-menu-active', 'p-2 mr-2')}
          renderValue={id => <SelectOption id={id} />}
          onChange={id => setSelectedAgentId(id === allAgentsId ? null : id)}
        />
      </header>
      {!selectedAgentId && (
        <WorkforceSummaryPanel analyzeResult={analyzeResult} showSummary />
      )}

      {selectedAgentId && (
        <>
          {/* <header className="w-full bg-app-panel-dark/60 py-2 px-4">
              <ButtonSwitchMulti
                // buttonType="minimal"
                buttonType="secondary"
                autoSize
                className="w-full lg:w-auto mb-4 lg:mb-0"
                options={simulationOptions}
                selectedIndex={isForecastSimulation ? 1 : 0}
                onClick={index => setForecastSimulation(index === 1)}
              />
            </header> */}
          {isForecastSimulation && (
            <WorkforceForecastSchedule agentId={selectedAgentId} />
          )}

          {!isForecastSimulation && (
            <>
              <Suspense fallback={LoadingContainer}>
                <StatGroup
                  title={t`Agent Summary`}
                  subTitle={t`Stats`}
                  helpNavTo={'simulation/workforce/simulation-workforce'}
                >
                  <Stat
                    className={`whitespace-pre-line`}
                    title={agentTypeTitle}
                    value={formatInteger(agent?.utilisation?.agentAmount) ?? 0}
                    inFilterStat
                    hasHelper
                    icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
                  />
                  <WorkforceAgentTotals analyzeId={analyzeResult.id} />
                </StatGroup>

                <StatGroup
                  title={t`Agent Specs`}
                  subTitle={t`Stats`}
                  helpNavTo={
                    'simulation/policies/policy-resource/policy-resource'
                  }
                >
                  <AgentSummary
                    key={selectedAgentId}
                    agentSettings={agents[selectedAgentId]}
                  />
                </StatGroup>
              </Suspense>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WorkforceForecastPanel;
