import Konva from 'konva';
import React from 'react';
import { Rect } from 'react-konva';
import { TwTheme } from '../../../Tw';

const areaColors = TwTheme.extend.colors.area;

const labelTextConfig: Konva.TextConfig = {
  fontFamily: TwTheme.extend.fontFamily.active[0],
  fontSize: 28,
  fill: TwTheme.extend.colors.menu[900],
  padding: 5,
};

const labelTagConfig: Konva.TagConfig = {
  fill: areaColors.label,
  offsetY: 4,
  cornerRadius: 2,
};

export type IntersectionFeatureProps = {
  box?: { xmin; ymin; xmax; ymax };
  label?: string;
  fillColor?: string;
  strokeColor?: string;
  stroke?: number;
  listening?: boolean;
};

const BoxZoneFeature: React.FC<IntersectionFeatureProps> = props => {
  const { box } = props;
  const x = Math.min(box.xmin, box.xmax);
  const y = Math.max(box.ymin, box.ymax);
  const w = Math.abs(box.xmax - box.xmin);
  const h = Math.abs(box.ymax - box.ymin);

  const fontSize = 0.1 * Math.floor(w / (props.label?.length ?? 1));
  return (
    <>
      <Rect
        x={x}
        y={y}
        width={w}
        height={h}
        fill={props.fillColor}
        scaleY={-1}
      />
      {/*
      <Text
        x={x}
        y={y}
        width={w}
        height={h}
        fontSize={fontSize}
        align="center"
        verticalAlign="middle"
        fill={'white'}
        scaleY={-1}
        text={props.label}
        listening={false}
        strokeEnabled={false}
      /> */}
    </>
  );
};

export default BoxZoneFeature;
