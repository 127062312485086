import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import { Card } from '../../components/cards/Card';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';

export type DashboardCardProps = {
  summary?: number;
  itemLimit?: number | 10;
  isActionable?: boolean;
};

const DataDashboardCard: React.FC<DashboardCardProps> = props => {
  const { t } = useTranslation('simulation');

  return (
    <Card
      transparent
      isActionable={props.isActionable}
      label={`DataDashboardCard`}
      className="!min-h-24"
    >
      <StatGroup>
        <Stat
          dashboardView
          isPreview
          title={t`Items`}
          value={formatInteger(props.summary).toString()}
        />
      </StatGroup>
    </Card>
  );
};

export default DataDashboardCard;
