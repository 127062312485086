import * as React from 'react';
const SimulationIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SimulationIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.16 106.84 33.36 122.54 33.36 91.14 6.16 75.44 6.16 106.84"
    />
    <polygon
      strokeWidth={0}
      points="34 58.54 6.88 74.2 34 89.86 61.12 74.2 34 58.54"
    />
    <polygon
      strokeWidth={0}
      points="34.71 91.16 34.71 122.48 61.84 106.82 61.84 75.5 34.71 91.16"
    />
    <polygon
      strokeWidth={0}
      points="66.16 106.84 93.36 122.54 93.36 91.14 66.16 75.44 66.16 106.84"
    />
    <polygon
      strokeWidth={0}
      points="94 58.54 66.88 74.2 94 89.86 121.12 74.2 94 58.54"
    />
    <polygon
      strokeWidth={0}
      points="94.71 91.16 94.71 122.48 121.84 106.82 121.84 75.5 94.71 91.16"
    />
    <polygon
      strokeWidth={0}
      points="36.16 55.43 63.36 71.13 63.36 39.73 36.16 24.03 36.16 55.43"
    />
    <polygon
      strokeWidth={0}
      points="64 7.13 36.87 22.79 64 38.45 91.12 22.79 64 7.13"
    />
    <polygon
      strokeWidth={0}
      points="64.71 39.75 64.71 71.07 91.84 55.41 91.84 24.09 64.71 39.75"
    />
  </svg>
);
export default SimulationIcon;
