import {
  ImportPipelineConnectorSettingsFragment,
  ImportPipelineFragment,
  useRemoveImportPipelineMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import {
  importPipelineSelectedId,
  importPipelinesDataTableState,
} from '../../store/importPipelines.state';

type PipelineEditableProps = Pick<
  ImportPipelineFragment,
  'title' | 'description' | 'enabled'
>;
type PipelineEditorProps = {
  pipeline: ImportPipelineFragment;
  isNew: boolean;
  onChange: (cnn: ImportPipelineConnectorSettingsFragment) => Promise<void>;
};

const RemovePipeline: React.FC<PipelineEditorProps> = props => {
  const { t } = useTranslation('dataset');
  const [pipeline, setPipeline] = useState<PipelineEditableProps>(
    props.pipeline,
  );
  const [selectedId, setSelectedId] = useRecoilState(importPipelineSelectedId);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableState, setTableState] = useRecoilState(
    importPipelinesDataTableState,
  );
  const [jobId, setJobId] = useState<string>(null);

  useEffect(() => {
    setPipeline(
      props.pipeline ?? {
        enabled: true,
      },
    );
    setIsChanged(false);
  }, [props.pipeline]);

  const [callRemove] = useRemoveImportPipelineMutation();

  const [error, setError] = useState<any>(null);

  function reloadTable() {
    setTableState({
      ...tableState,
      searchValues: { ...tableState?.searchValues },
    });
  }

  async function removePipeline() {
    setIsLoading(true);
    try {
      await callRemove({ variables: { id: props.pipeline.id } });
      reloadTable();
      setSelectedId(null);
    } catch (ex) {
      console.error(ex);
      setError(true);
    }
    setSelectedId(null);
  }

  if (!pipeline) {
    return null;
  }

  return (
    <>
      {/* {!props.isNew && ( */}
      <Button
        className={classNames('flex-1 text-sm')}
        label={t`Delete Pipeline`}
        buttonType="delete"
        full
        hasIconAfter={<Icon.CircleX className={`w-5 h-5 fill-current`} />}
        onPress={removePipeline}
        isDisabled={isLoading}
        isLoading={isLoading}
      />
      {/* )} */}
    </>
  );
};

export default RemovePipeline;
