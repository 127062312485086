import {
  AssignmentIssueInvalidLocationFragment,
  AssignmentIssueInvalidLocationReason,
  LoadAssignmentIssueDataQuery,
  LoadAssignmentIssueDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import {
  ColumnConfigBase,
  ExportConverter,
  ExportJobParams,
  NULL_VALUE,
} from './types';

export type AssignmentItemIssuesExportJobParams = ExportJobParams<
  LoadAssignmentIssueDataQueryVariables,
  AssignmentItemIssuesConverterConfig
>;

export const assignmentItemIssuesExportJobParams: AssignmentItemIssuesExportJobParams =
  {
    converterId: 'ASSIGNMENT_ITEM_ISSUES',
    query: null,
    config: null,
    variables: null,
    filename: 'assignment-issues.csv',
  };

export type AssignmentItemIssuesDataRow =
  AssignmentIssueInvalidLocationFragment & {
    reasonString: string;
    sku: string;
    consignee: string;
  };

export type AssignmentItemIssuesConverterConfig = {
  columnsConfig: ColumnConfigBase<AssignmentItemIssuesDataRow>[];
  dictionaries: {
    reasons: Record<AssignmentIssueInvalidLocationReason, string>;
  };
};

const getReasons = (
  reasonsData: AssignmentIssueInvalidLocationReason[],
  reasonsMap: Record<AssignmentIssueInvalidLocationReason, string>,
) => {
  if (_.isEmpty(reasonsData)) return NULL_VALUE;
  return _(reasonsData)
    .map(r => `${reasonsMap[r]}`)
    .join(', ');
};

export const getAssignmentItemIssuesTableRows = (
  data: AssignmentIssueInvalidLocationFragment[],
  config: AssignmentItemIssuesConverterConfig,
): AssignmentItemIssuesDataRow[] => {
  function reduceAssignmentItem(
    item: AssignmentIssueInvalidLocationFragment,
  ): AssignmentItemIssuesDataRow[] {
    if (_.isEmpty(item.productIds)) {
      return [
        {
          ...item,
          reasonString: getReasons(item.reason, config.dictionaries.reasons),
          consignee: null,
          sku: null,
        },
      ];
    }
    return _.map(item.productIds, product => ({
      ...item,
      consignee: product.consignee,
      sku: product.sku,
      reasonString: getReasons(item.reason, config.dictionaries.reasons),
    }));
  }
  return _.flatMap(data, reduceAssignmentItem);
};

export const ASSIGNMENT_ITEM_ISSUES_EXPORT_CONVERTER: ExportConverter<
  LoadAssignmentIssueDataQuery,
  AssignmentItemIssuesConverterConfig
> = (input, config) => {
  const data = getAssignmentItemIssuesTableRows(
    input.assignmentIssueSet?.invalidLocations?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
