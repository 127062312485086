import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsAgent } from '../AnalyticTracker';
import {
  PRIVACY_POLICY_VERSION,
  TERMS_POLICY_VERSION,
} from '../common/constants';
import { HelperMessage } from '../components/HelperMessage';
import * as Icon from '../components/icons';
import useUpdateUserSettings from '../warehouse/hooks/useUpdateUserSettings';

export type TrialHelperProps = {
  className?: string;
  classNameAction?: string;
  classNameMessage?: string;
  children?: React.ReactNode;
};

export const TrialHelperMessage = ({
  children,
  className,
  classNameAction,
  classNameMessage,
}: TrialHelperProps) => {
  const { t } = useTranslation('app');

  const configure = useUpdateUserSettings();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function completeConfiguration(whId?: string) {
    await configure({
      configured: true,
      consents: {
        privacyPolicy: {
          version: PRIVACY_POLICY_VERSION,
        },
        termsPolicy: {
          version: TERMS_POLICY_VERSION,
        },
      },
    });
    navigate(whId ? `/wh/i/${whId}` : '/wh/new');
  }

  return (
    <HelperMessage
      data-component="TrialHelper"
      className={classNames('m-4 p-3 flex-1 ', className)}
      classNameMessage={classNameMessage}
      classNameAction={classNameAction}
    >
      <div
        className={classNames(
          'relative flex-1 text-xl',
          'text-menu-active-text',
        )}
      >
        <div className={classNames('mt-8 mb-4')}>
          {t`Begin digitising your warehouse today!\n Click [Start Trial] to explore our platform with your own data.\n No IT setup required, just instant insights.`}
        </div>
        <div
          data-component={`DemoButton-Trial`}
          className={classNames(
            'cursor-pointer',
            'flex items-center flex-1',
            'p-4 rounded bg-app-panel-dark/90 hover:bg-app-panel-dark',
            'text-menu-active',
          )}
          onClick={() => {
            setIsLoading(true);
            completeConfiguration();
            try {
              analyticsAgent?.track(`Demo (Header): Start Trial`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
        >
          <div className={classNames('flex flex-col flex-1 items-start')}>
            <div className="mb-0.5 text-xl uppercase">{t`Your Data`}</div>
            <div
              className={classNames('text-3xl lg:text-5xl xl:text-6xl')}
            >{t`Start Trial`}</div>
          </div>
          <Icon.CircleArrowRight
            className={classNames('w-10 h-10 xl:w-16 xl:h-16 fill-current')}
          />
        </div>
        <div
          className={classNames('mt-4', 'text-menu-active-text')}
        >{t`And don't worry, we will guide you every step of the way!`}</div>
      </div>
      {children}
    </HelperMessage>
  );
};
