import * as React from 'react';
const MoveTypeSwapIssueIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MoveTypeSwapIssueIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M96.54,28.1v26.55s-11.18-11.17-11.18-11.17v10.81s15.71,15.7,15.71,15.7l15.72-15.7v-10.81s-11.18,11.17-11.18,11.17v-30.62c0-2.76-2.23-5-5-5h-42.1c-1.38,0-2.5,1.11-2.5,2.49v4.07c0,1.38,1.12,2.5,2.5,2.5h38.16"
    />
    <path
      strokeWidth={0}
      d="M30.12,98.3v-26.55s11.18,11.17,11.18,11.17v-10.81s-15.71-15.7-15.71-15.7l-15.72,15.7v10.81s11.18-11.17,11.18-11.17v30.62c0,2.76,2.23,5,4.99,5h42.1c1.38,0,2.5-1.11,2.5-2.49v-4.07c0-1.38-1.12-2.5-2.5-2.5H29.99"
    />
    <path
      strokeWidth={0}
      d="M44.57,9.39c-9.89-9.83-25.88-9.83-35.65,0-9.77,9.83-9.67,25.82.22,35.65,9.89,9.83,25.88,9.83,35.65,0,9.77-9.83,9.67-25.82-.22-35.65ZM34.11,37.16l-7.24-7.2-7.5,7.54c-.75.76-1.98.76-2.74,0-.76-.76-.77-1.99-.02-2.74l7.5-7.54-7.24-7.2c-.76-.76-.77-1.99-.02-2.74.75-.76,1.98-.76,2.74,0l7.24,7.2,6.81-6.86c.75-.76,1.98-.76,2.74,0s.77,1.99.02,2.74l-6.81,6.86,7.24,7.2c.76.76.77,1.99.02,2.74-.75.76-1.98.76-2.74,0Z"
      opacity={0.5}
    />
    <path
      strokeWidth={0}
      d="M101.6,74.9c-13.9,0-25.2,11.3-25.2,25.2s11.3,25.2,25.2,25.2,25.2-11.3,25.2-25.2-11.3-25.2-25.2-25.2ZM115.65,91.7l-15.51,17.45c-.38.43-.91.65-1.45.65-.43,0-.85-.14-1.21-.42l-9.69-7.75c-.84-.67-.97-1.89-.3-2.72.67-.84,1.89-.97,2.72-.3l8.26,6.6,14.29-16.07c.71-.8,1.94-.87,2.74-.16.8.71.87,1.94.16,2.74Z"
    />
  </svg>
);
export default MoveTypeSwapIssueIcon;
