import {
  LayoutImportConverterBayFragment,
  LayoutImportConverterLocationFragment,
  MeasurementSystem,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AreaBuilderSettings,
  BayTypeSettings,
  ConverterSettings,
  LocationTypeSettings,
} from '@warebee/shared/data-access-layout-import-converter';
import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import _ from 'lodash';
import {
  getAisleWidthDefault,
  getBayDefaultConfig,
  getDefaultAreaBuilderSettings,
  getDefaultAreaConfigurationRaw,
  getDefaultLayoutBuilderSettings,
  getLocationDefaultConfig,
} from './converter.defaults';
import { LayoutImportConverterData } from './converter.types';

export function getDefaultLocationTypes(
  locations: LayoutImportConverterLocationFragment[],
  ms: MeasurementSystem,
): Record<string, LocationTypeSettings> {
  const defaultLocationTypes: Record<string, LocationTypeSettings> = _(
    locations,
  )
    .groupBy(l => l.locationRackingType)
    .map((locs, id) => {
      const maxWidth = _.maxBy(locs, l => l.locationWidth)?.locationWidth ?? 0;
      return {
        typeId: id,
        ...getLocationDefaultConfig(ms, maxWidth),
      };
    })
    .keyBy(locType => locType.typeId)
    .value();
  return defaultLocationTypes;
}

export function getDefaultBayTypes(
  bays: LayoutImportConverterBayFragment[],
  ms: MeasurementSystem,
) {
  const defaultBayTypes = _(bays)
    .groupBy(b => b.bayType)
    .reduce(
      (acc, bays) => {
        const bay = _.head(bays);
        const bayType = bay.bayType ?? DEFAULT_BAY_TYPE;
        const levelHeights = _(bays)
          .flatMap(b => b.locations)
          .groupBy(l => l.locationLevel)
          .reduce((acc, locs, level) => {
            const minHeight =
              _.minBy(locs, l => l.locationHeight)?.locationHeight ?? 0;
            return { ...acc, [level]: minHeight };
          }, {});

        return {
          ...acc,
          [bayType]: {
            ...getBayDefaultConfig(ms),
            bayType,
            levelHeights,
          },
        };
      },
      {} as Record<string, BayTypeSettings>,
    );
  return defaultBayTypes;
}

export type GetDefaultConverterSettingsParams = {
  layoutImport: LayoutImportConverterData;
  measurementSystem: MeasurementSystem;
};

/**
 * Create default converter settings
 * @returns ConverterSettings
 */
export function getDefaultConverterSettings(
  params: GetDefaultConverterSettingsParams,
): ConverterSettings {
  const { layoutImport } = params;

  // layoutSettings: LayoutBuilderSettings;
  const layoutBuilderDefault = {
    name: layoutImport.title,
    description: layoutImport.description,
    ...getDefaultLayoutBuilderSettings(),
  };

  // areasConfigurations: AreaConfigurationMap;
  const defaultAisleWidth = getAisleWidthDefault(params.measurementSystem);
  const defaultAreasConfig = _.keyBy(
    getDefaultAreaConfigurationRaw(layoutImport.areas, defaultAisleWidth),
    a => a.id,
  );

  // areasBuilder: Record<string, AreaBuilderSettings>;
  const defaultAreasBuilder = _.keyBy(
    layoutImport.areas.map(a => ({
      id: a.area,
      ...getDefaultAreaBuilderSettings(params.measurementSystem),
    })),
    a => a.id,
  ) as Record<string, AreaBuilderSettings>;

  // locationsTypes: Record<string, LocationTypeSettings>;
  const bays: LayoutImportConverterBayFragment[] = _.flattenDeep(
    layoutImport.areas.map(a => a.aisles.map(a2 => a2.sides.map(s => s.bays))),
  );
  const locs: LayoutImportConverterLocationFragment[] = _.flattenDeep(
    _.map(bays, b => b.locations),
  );
  const defaultLocationTypes = getDefaultLocationTypes(
    locs,
    params.measurementSystem,
  );

  const defaultBayTypes = getDefaultBayTypes(bays, params.measurementSystem);

  return {
    layoutSettings: layoutBuilderDefault,
    areasConfigurations: defaultAreasConfig,
    areasBuilder: defaultAreasBuilder,
    locationsPatches: {},
    locationsTypes: defaultLocationTypes,
    bayTypes: defaultBayTypes,
    shelvesSettings: {},
    areaSpacers: {},
    extraLocations: {},
  };
}

/**
 * Merge ConverterSettings into new one
 * @param settings
 * @returns ConverterSettings
 */
export function mergeConverterSettings(
  ...settings: ConverterSettings[]
): ConverterSettings {
  console.debug('[Converter] Settings to merge:', settings);
  const merged = _.merge({}, ...settings);
  console.debug('[Converter] Merged settings:', merged);
  return merged;
}
