import {
  AppBillingProductCategory,
  AppBillingProductFragment,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbolSettings } from '../common/formatHelper';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { Spacer } from '../components/layout/Spacer';
import {
  AppBillingPricingCardsCategoryMode,
  AppBillingPricingCardsLinkMode,
  AppBillingPricingCardsPriceMode,
  AppBillingPricingCardsViewMode,
} from './AppBillingPricingCards';

export type AppBillingPricingHeaderProps = {
  products: AppBillingProductFragment[];
  selectedPriceId?: string;

  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;
  // size: WarehouseSize;

  currency: string;
  onCurrencyChange: (currency: string) => void;

  category: AppBillingProductCategory;
  onCategoryChange: (category: AppBillingProductCategory) => void;

  showCategorySwitch?: boolean;
  showCurrencySwitch?: boolean;
};

const AppBillingPricingHeader: React.FC<
  AppBillingPricingHeaderProps
> = props => {
  const { t } = useTranslation('app');
  // const { products, currency, category, size } = props;
  const { products, currency, category } = props;

  // const filteredProducts = getFilteredProducts(
  //   products,
  //   currency,
  //   whSize,
  //   category,
  // );

  const currencies = _(products)
    .map(p => p.prices)
    .flatten()
    .map(price => price.currency.toUpperCase())
    .uniq()
    .value();

  const productCategories = [
    AppBillingProductCategory.SUBSCRIPTION,
    AppBillingProductCategory.BUNDLE,
  ];

  return (
    <header
      data-component="AppBillingPricingHeader"
      className={classNames('flex items-center px-10 xl:px-10 pb-0 pt-2')}
    >
      <Spacer flexspace />
      <div
        className={classNames(
          'flex flex-col lg:flex-row items-center w-full',
          'pb-4',
        )}
      >
        {props.showCategorySwitch && (
          <div
            data-component="CategorySwitch"
            className={classNames(
              'xs:flex flex-col lg:flex-row lg:justify-center items-center xs:mb-4 w-full lg:w-auto',
            )}
          >
            {/* {isModeBundleOnly && <>isModeBundleOnly</>}
            {isModeSubscriptionOnly && <>isModeSubscriptionOnly</>} */}
            <ButtonSwitchMulti
              autoSize
              buttonType="secondary"
              className={classNames(
                'text-3xl xl:text-2xl flex-1 lg:flex-none w-full lg:w-auto',
              )}
              options={productCategories.map(cat => ({
                label:
                  cat === AppBillingProductCategory.SUBSCRIPTION
                    ? t`Subscription`
                    : t`One Time`,
              }))}
              selectedIndex={_.indexOf(productCategories, category)}
              onClick={index =>
                props.onCategoryChange(productCategories[index])
              }
            />
          </div>
        )}
        {props.showCurrencySwitch && (
          <>
            <Spacer flexspace />
            <div
              data-component="CurrencySwitchContainer"
              className={classNames(
                'hidden xl:flex',
                'items-center ltr:ml-2 rtl:mr-2',
              )}
            >
              <ButtonSwitchMulti
                autoSize
                buttonType="secondary"
                classNameLabel="!text-lg !lg:text-xl"
                options={currencies.map(currencyCode => ({
                  label: getCurrencySymbolSettings(currencyCode).symbol,
                }))}
                selectedIndex={_.indexOf(currencies, currency)}
                onClick={index => props.onCurrencyChange(currencies[index])}
              />
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default AppBillingPricingHeader;
