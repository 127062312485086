import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons/';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import { warehouseSelectedId } from '../../../store/warehouse.state';
import useLoadAssignmentImplement from '../../hooks/useLoadAssignmentImplement';
import {
  optimisationAssignmentImplementFiltered,
  optimisationAssignmentImplementStatus,
} from '../../store/optimisation.state';
import { simulationCurrentId } from '../../store/simulation.state';
import PanelItemMoveAction from '../PanelItemMoveAction';

export type OptimisationImplementPanelProps = {
  optimisationId: string;
  hasControls?: boolean;
};

const OptimisationImplementPanel: React.FC<
  OptimisationImplementPanelProps
> = props => {
  // const { url } = useMatch();
  const simId = useRecoilValue(simulationCurrentId);
  const whId = useRecoilValue(warehouseSelectedId);

  const assignmentDiff = useRecoilValue(
    optimisationAssignmentImplementFiltered,
  );
  const assignmentDiffStatus = useRecoilValue(
    optimisationAssignmentImplementStatus,
  );
  const [loadDiff, cancelLoad] = useLoadAssignmentImplement();

  const { t } = useTranslation('simulation');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    cancelLoad();
    loadDiff({ optimisationId: props.optimisationId, index });
  }, [props.optimisationId, index]);

  function select(delta: number) {
    setIndex(index + delta);
  }

  if (!assignmentDiff?.content) {
    return <LoadingIndicator selfCenter message={t`Loading Results`} />;
  }

  const tableLabel = t(`{{index}} of {{total}}`, {
    index: index + 1,
    total: assignmentDiff?.totalCount,
  });

  return (
    <>
      <div className={`p-4 text-center items-center`}>{tableLabel}</div>
      <Container
        data-component="OptimisationImplementPanel"
        // className="flex flex-col h-full flex-1"
        col
        flex1
        fullHeight
        hasNoScroll
      >
        <ActionBar>
          <Button
            className="ltr:mr-1 rtl:ml-1"
            label={t`Previous`}
            buttonType={index === 0 ? `primary` : `primary`}
            isDisabled={index === 0}
            full
            hasIconBefore
            buttonIcon={<Icon.ArrowLeft className={`w-5 h-5 fill-current`} />}
            onPress={() => select(-1)}
          />
          <Button
            className="ltr:ml-1 rtl:mr-1"
            label={t`Next`}
            buttonType={
              index === assignmentDiff.totalCount - 1 ? `primary` : `primary`
            }
            isDisabled={index === assignmentDiff.totalCount - 1}
            full
            hasIconAfter
            buttonIcon={<Icon.ArrowRight className={`w-5 h-5 fill-current`} />}
            onPress={() => select(1)}
          />
        </ActionBar>
        {/* <div className={`text-menu-active text-xl p-2`}>
          {t(`{{index}} of {{total}}`, {a
            index,
            total: assignmentDiff?.totalCount,
          })}
        </div> */}

        {assignmentDiffStatus === AsyncLoadStatus.Loading ||
        assignmentDiffStatus === AsyncLoadStatus.None ||
        _.isNil(assignmentDiff.content[0]) ? (
          <LoadingIndicator selfCenter message={t`Loading Record`} />
        ) : (
          <PanelItemMoveAction item={assignmentDiff.content[0]} />
        )}
      </Container>
      {props.hasControls && (
        <ActionBar>
          <Link
            target="_blank"
            to={`/wh/i/${whId}/simulations/${simId}/implement`}
          >
            <Button
              label={t`Preview (Full Screen)`}
              // buttonType="primary"
              // isDisabled={true}
            />
          </Link>
          <Spacer flexspace />
          {/* TODO: Export report of done/not done */}
          {/* <Button label={t`Export`} isDisabled={true} /> */}
        </ActionBar>
      )}
    </>
  );
};

export default OptimisationImplementPanel;
