import { ResponsiveLine, Serie } from '@nivo/line';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDurationHoursShort } from '../../common/formatHelper';
import { getDimensionColor } from '../store/workforce.helper';

export type LineChartProps = {
  data: Serie[];
};

const LineChart: React.FC<LineChartProps> = props => {
  const { t: tMes } = useTranslation('measures');
  const { t } = useTranslation('simulation');

  // Check if LOAD_BALANCING type exists in data
  const hasLoadBalancing = props.data.some(d => d.type === 'LOAD_BALANCING');

  // Chart data to include yScaleId based on type
  const chartData = props.data.map(d => ({
    ...d,
    yScaleId: d.type === 'COST' ? 'cost' : 'balance',
  }));

  const chartSideMargin = 35;
  const chartRightMargin = hasLoadBalancing ? chartSideMargin : 10;
  // Calculate max values for each type of data

  return (
    <ResponsiveLine
      margin={{
        top: 10,
        right: 10,
        bottom: 50,
        left: chartSideMargin,
      }}
      data={chartData}
      curve="monotoneX"
      enablePoints={false}
      pointLabelYOffset={0}
      enablePointLabel={false}
      colors={d => {
        return getDimensionColor(d.id ?? d.type)[0];
      }}
      xScale={{
        type: 'linear',
        // nice: false,
        // min: 'auto', // This allows for dynamic negative values
        // max: 'auto', // Set max based on data type
      }}
      yScale={{
        type: 'linear',
        nice: true,
        min: 'auto', // This allows for dynamic negative values
        max: 'auto', // Set max based on data type
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 11,
              fill: 'rgba(255, 255, 255,  0.5)',
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fill: 'rgba(255, 255, 255,  0.9)',
            },
          },
        },
        grid: {
          line: {
            stroke: 'rgba(162,188,204, 0.85)',
            strokeWidth: 0.3,
          },
        },
        crosshair: {
          line: {
            stroke: 'rgba(0, 0, 0,  0.7)',
            strokeWidth: 3,
            // simulate line will dash stroke when index is even
            strokeDasharray: '3, 3',
          },
        },
      }}
      axisBottom={{
        ticksPosition: 'before',
        tickValues: _(props.data)
          .takeRight(1)
          .map(d => d.x)
          .value(),
        legend: t`Time`,
        legendOffset: 40,
        legendPosition: 'middle',
        format: v => formatDurationHoursShort(tMes, v * 1000),
      }}
      axisLeft={{
        tickRotation: 0,
        legend: t`Sales Volume %`,
        legendOffset: -50,
        legendPosition: 'middle',
        format: v => `${v}%`,
      }}
      // //pointSize={10}
      // pointColor={{ theme: 'background' }}
      // pointBorderWidth={2}
      // pointBorderColor={{ from: 'serieColor' }}
      // //pointLabelYOffset={-12}
      // enableArea={false}
      // areaOpacity={1}
      // areaBlendMode="normal"
      // enableSlices={'y'}
      isInteractive={true}
      layers={[
        'grid',
        'markers',
        'lines',
        'slices',
        'axes',
        'points',
        'legends',
        'crosshair',
      ]}

      // sliceTooltip={({ slice }) => {
      //   const toPercent = slice.points[0].data.y;
      //   if (toPercent === 0) return null;

      //   const cat = _.values(props.categories).find(
      //     c => c.toCmlPercentile === toPercent,
      //   );
      //   const msg = t(
      //     '{{moveShare}} of movement did by {{productShare}} of products (target {{targetProductShare}})',
      //     {
      //       moveShare: 0,
      //       productShare: 0,
      //       targetProductShare: formatPercent(cat.productShareTarget),
      //     },
      //   );
      //   return (
      //     <div className={`bg-categoryABC-${cat.key} text-menu-text text-sm`}>
      //       <div className="p-2">{`[${cat.tag}] ${cat.title}`}</div>
      //       <div className="p-2 bg-opacity-80 bg-app-panel-dark/60 backdrop-filter backdrop-blur text-white">
      //         {t('{{productShare}} of Total Items', {
      //           productShare: formatPercent(cat.productShare),
      //           diffShare: formatPercent(cat.productShare),
      //         })}
      //       </div>
      //       <div className="p-2 bg-opacity-80 bg-app-panel-dark/60 backdrop-filter backdrop-blur text-white">
      //         {t('{{moveShare}} of Sales', {
      //           moveShare: formatPercent(cat.toCmlPercentile),
      //         })}
      //       </div>

      //       {/* orderLinesShare: formatPercent( totalHitCount !== 0 ?
      //       data?.totalHitCount / totalHitCount : 0, ), */}
      //       {/* <div className="p-2 bg-opacity-80 bg-app-panel-dark/60 backdrop-filter backdrop-blur text-white">
      //         {t('{{targetProductShare}} target product share', {
      //           targetProductShare: formatPercent(cat.productShareTarget),
      //         })}
      //       </div> */}
      //     </div>
      //   );
      // }}
    />
  );
};

export default LineChart;
