export const action = {
  render: 'ActionLink',
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    type: {
      type: String,
      default: 'tip',
      matches: ['tip', 'suggestion', 'caution', 'check', 'note', 'warning'],
    },
    title: {
      type: String,
    },
    iconType: {
      type: String,
    },
  },
};
