import * as React from 'react';
const Mhe2BVnaIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe2BVnaIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M62.36,83.53V2h-9.45v3.03h.85L20.09,83.53c-2.58.24-4.74,1.25-6.48,3.03-1.74,1.78-2.6,3.96-2.6,6.54v23.62h51.36v-33.19h0ZM55.1,15.2h-2.18v3.03h2.18v10.18h-2.18v3.03h2.18v10.3h-2.18v3.03h2.18v10.18h-2.18v3.03h2.18v10.18h-2.18v2.91h2.18v12.48H21.42L54.98,5.03h0l.12,10.18h0ZM69.03,83.4v18.17h12.96v-3.27l-9.45-2.54-1.7-12.36h-1.82ZM87.8,86.92c-.24.16-1.01,1.01-2.3,2.54l-2.3,2.54,1.45,2.42h3.03v16.6h-19.87v-59.6c0-.97.32-1.78.97-2.42.65-.65,1.45-.97,2.42-.97h13.57l2.3-2.54h-23.38v71.11h15.51c1.13-1.45,2.58-2.34,4.36-2.67,1.78-.32,3.47.08,5.09,1.21.57.4,1.05.89,1.45,1.45h.24v-33.07h-2.54v3.39h0ZM84.65,115.02c-.97,0-1.88.24-2.73.73-.85.48-1.51,1.17-2,2.06H11v5.94h.24c.48.97,1.21,1.64,2.18,2,.97.36,1.94.34,2.91-.06.97-.4,1.66-1.05,2.06-1.94h61.78c.97,1.21,2.18,1.94,3.63,2.18,1.45.24,2.83-.1,4.12-1.03s2.06-2.12,2.3-3.57-.08-2.83-.97-4.12c-.57-.65-1.23-1.17-2-1.57-.77-.4-1.64-.61-2.6-.61h0ZM104.76,72.74v33.43h-2.06v-6.54h-10.78v24.11h21.44V58.21h-8.6v14.54h0ZM106.82,60.02h4.48v8.24h-4.48v-8.24h0ZM106.82,69.84h4.48v2.91h-4.48v-2.91h0ZM106.82,83.4h4.48v4.6h-4.48v-4.6h0ZM101.13,72.74v15.14h2.67v-15.14h-2.67ZM114.33,72.74v15.14h2.67v-15.14h-2.67Z"
    />
  </svg>
);
export default Mhe2BVnaIcon;
