import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import { getAnalyzeProductMetricDescriptorsMap } from '../../metrics/analyzeProduct/analyzeProductMetric.default';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { simulationHeatmapBuckets } from '../store/simulation.heatmap.state';
import { simulationLayoutHeatmapFilters } from '../store/simulation.layout.state';
import HeatmapLegendBuckets, {
  HeatmapLegendBucketsProps,
} from './HeatmapLegendBuckets';

const ReassignHeatmapToolbar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();
  const buckets = useRecoilValue(simulationHeatmapBuckets);
  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );

  const metric = useRecoilValue(analyzeProductMetricSelected);
  const descriptorsAll = getAnalyzeProductMetricDescriptorsMap(t, formatter);
  const metricDescriptor = descriptorsAll[metric];

  const bucketProps: HeatmapLegendBucketsProps = {
    title: t`Legend`,
    buckets: _.values(buckets) ?? [],
    metric: metricDescriptor,
    heatmapFilter,
    setHeatmapFilter,
  };

  return (
    <ButtonToolbarGroup
      className={classNames(
        'absolute',
        'top-1 ltr:left-1 rtl:right-1',
        //'bottom-1',
        'flex flex-col',
        'z-toolbar',
        'overflow-hidden',
      )}
    >
      <HeatmapLegendBuckets {...bucketProps} />
    </ButtonToolbarGroup>
  );
};

export default ReassignHeatmapToolbar;
