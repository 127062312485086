import Konva from 'konva';
import _ from 'lodash';
import React from 'react';
import { Line } from 'react-konva';
import { TwTheme } from '../../../Tw';

export type IntersectionFeature2Props = {
  id: string;
  points: number[][];
  fillColor?: string;
  strokeColor?: string;
  stroke?: number;
  listening?: boolean;
};

const IntersectionFeature2: React.FC<IntersectionFeature2Props> = props => {
  const lineConfig: Konva.LineConfig = {
    points: _.flatten(props.points),
    draggable: false,
    fill: props.fillColor,
    globalCompositeOperation: 'multiply',
    strokeScaleEnabled: false,
    stroke: props.strokeColor,
    strokeWidth: props.stroke || 1,
    perfectDrawEnabled: false,
    closed: true,
    listening: !!props.listening,
  };

  return <Line key={`intersection-${props.id}`} {...lineConfig} />;
};

export default IntersectionFeature2;
