import classNames from 'classnames';
import React from 'react';
import { useId } from 'react-aria';

export type HelpItemListCardsContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

export const HelpItemListCardsContainer = ({
  children,
  className = '',
}: HelpItemListCardsContainerProps) => {
  const id = useId();

  return (
    <ul
      data-component="HelpItemListCardsContainer"
      id={`help-list-card-container-${id}`}
      // className={` px-4_ ${className}`}
      className={classNames('flex flex-wrap max-w-screen', className)}
    >
      {children}
    </ul>
  );
};
