import {
  ImportJobBaseFragment,
  ImportSource,
  LoadImportJobsQuery,
  LoadImportJobsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type ImportJobsExportJobParams = ExportJobParams<
  LoadImportJobsQueryVariables,
  ImportJobsConverterConfig
>;

export const importJobsExportJobParams: ImportJobsExportJobParams = {
  converterId: 'IMPORT_JOBS',
  query: null,
  config: null,
  variables: null,
  filename: 'import-jobs.csv',
};

export type ImportJobsDataRow = ImportJobBaseFragment & {
  sourceProcessType: ImportSource;
  filename: string;
  errorCount: number;
  inputLineCount: number;
  importedLineCount: number;
  transformationEnabled?: boolean;
  transformation?: string;
};

export type ImportJobsDataColumn = keyof ImportJobsDataRow;

export type ImportJobsConverterConfig = {
  columnsConfig: ColumnConfigBase<ImportJobsDataRow>[];
};

export const getImportJobsTableRows = (
  data: ImportJobBaseFragment[],
  config: ImportJobsConverterConfig,
): ImportJobsDataRow[] => {
  const reduceImportJobs = (job: ImportJobBaseFragment): ImportJobsDataRow => {
    return {
      ...job,
      sourceProcessType: job.sourceSettings?.source,
      filename: job.sourceSettings?.filename,
      errorCount: job.summary?.errorCount,
      inputLineCount: job.summary?.inputLineCount,
      importedLineCount: job.summary?.importedLineCount,
      transformationEnabled: job.settings?.transformation?.enabled,
      transformation: job.settings?.transformation?.query,
    };
  };

  return _.map(data, reduceImportJobs);
};

export const IMPORT_JOBS_EXPORT_CONVERTER: ExportConverter<
  LoadImportJobsQuery,
  ImportJobsConverterConfig
> = (input, config) => {
  const data = getImportJobsTableRows(input.importJobs.content, config);
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
