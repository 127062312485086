import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import { formatInteger } from '../../common/formatHelper';
import { StatCenterPie } from '../../components/stats/StatCenterPie';
import StatItem from '../../components/stats/StatItem';
import PanelContainer from '../../containers/PanelContainer';
import useActivityEventProcessTypeDetails from '../../import/activityFeed/schema/useActivityEventProcessTypeDetails';
import ContainerChart from '../../simulation/panels/ContainerChart';
import SimulationPieChart, {
  PieData,
} from '../../simulation/panels/SimulationPieChart';
import { feedProcessTypeTotals } from '../store/feed.state';

const FeedProcessPie: React.FC = () => {
  const { t } = useTranslation('feed');
  const processTypeTotals = useRecoilValue(feedProcessTypeTotals);
  const [currentEventKpi, setCurrentEventKpi] = useState<
    'eventCount' | 'eventPercentage' | 'eventCost'
  >('eventCount');

  const processTypeDetails = useActivityEventProcessTypeDetails();

  const totalEventsCountRaw = _.sumBy(processTypeTotals, 'totalCount');
  const totalEventsCount = formatInteger(totalEventsCountRaw);

  const data: PieData[] = _.chain(processTypeTotals)
    .map(row => {
      const key = `${row.eventProcessType} ${row.eventType}`;
      const label =
        processTypeDetails.find(detail => detail.id === row.eventProcessType)
          ?.title || row.eventProcessType;

      return {
        id: key,
        value: row.totalCount,
        label: label,
        color: getQualitativeColor(key, 'dimension'),
        formatted: {
          value: formatInteger(row.totalCount),
          fullString: `${formatInteger(row.totalCount)}`,
          unit: t`events`,
          raw: row.totalCount,
          unitCode: 'events',
        },
      };
    })
    .sortBy(item => -item.value)
    .value();

  if (data.length === 0) {
    return null;
  }

  return (
    <PanelContainer
      id="panel-feed-global-filters-process-type"
      title={t`Process Summary`}
      // collapsible
      hasPadding
    >
      <ContainerChart containerMaxHeight={200}>
        <StatCenterPie
          statLabelMiddle={t`Total`}
          statValueMiddle={totalEventsCount}
        />
        <SimulationPieChart data={data} />
      </ContainerChart>
      {_(data)
        .map(item => (
          <StatItem
            key={`event-group-${item.id}`}
            title={item.label}
            value={item.formatted}
            legendColor={item.color[0]}
          />
        ))
        .value()}
    </PanelContainer>
  );
};

export default FeedProcessPie;
