export enum ImportJobImportType {
  LAYOUT = 'LAYOUT',
  ASSIGNMENT = 'ASSIGNMENT',
  ITEM_SET = 'ITEM_SET',
  ORDER_SET = 'ORDER_SET',
  ASSIGNMENT_POLICY = 'ASSIGNMENT_POLICY',
  ACTIVITY_FEED = 'ACTIVITY_FEED',
  // TODO distinguish between DatasetObjectType, DatasetTableType and ImportJobImportType
  CONVERTED_LAYOUT = 'CONVERTED_LAYOUT',
}

export const ALL_IMPORT_JOB_IMPORT_TYPES: ImportJobImportType[] = [
  ImportJobImportType.LAYOUT,
  ImportJobImportType.ASSIGNMENT,
  ImportJobImportType.ITEM_SET,
  ImportJobImportType.ORDER_SET,
  ImportJobImportType.ASSIGNMENT_POLICY,
  ImportJobImportType.ACTIVITY_FEED,
  ImportJobImportType.CONVERTED_LAYOUT,
];
