import {
  AllocationRequirementFragment,
  AllocationRequirementsConnectionFragment,
  AllocationRequirementSetMetaFragment,
  AllocationRequirementSummaryFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { atom } from 'recoil';
import { AsyncLoadStatus, DatasetTableState } from '../../../common/types';
import { AllocationRequirementGroupByType } from './allocation.types';

const getKey = (postfix: string) =>
  `warebee-simulation-allocation-requirement-${postfix}`;

export const allocationRequirement = atom<AllocationRequirementSetMetaFragment>(
  {
    key: getKey('allocation-requirement'),
    default: null,
  },
);

export const allocationRequirementLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('load-status'),
  default: AsyncLoadStatus.None,
});

export const allocationRequirementItems =
  atom<AllocationRequirementsConnectionFragment>({
    key: getKey('items'),
    default: null,
  });

export const allocationRequirementItemsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('items-load-status'),
  default: AsyncLoadStatus.None,
});

export const allocationRequirementTableState = atom<
  DatasetTableState<keyof AllocationRequirementFragment>
>({
  key: getKey('table-state'),
  default: {
    searchValues: {},
    sortValues: {
      requiredQuantity: SortDirection.DESC,
    },
  },
});

export const allocationRequirementShowTable = atom<boolean>({
  key: getKey('show-table'),
  default: true,
});

export const allocationRequirementSummaryGroupBy =
  atom<AllocationRequirementGroupByType>({
    key: getKey('summary-group-by'),
    default: 'consignee',
  });

export const allocationRequirementSummary = atom<
  AllocationRequirementSummaryFragment[]
>({
  key: getKey('requirement-summary'),
  default: null,
});

export const allocationRequirementSummaryLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('summary-load-status'),
  default: AsyncLoadStatus.None,
});
