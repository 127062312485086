import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { AssignmentMenuItem } from './assignment.types';

export const assignmentMenuItems: (
  t: TFunction<'app'>,
) => AssignmentMenuItem[] = t => [
  {
    id: 'assignment-menu-start',
    title: t('Assignment Data Table', { ns: 'app' }),
    titleMinimized: t('Data', { ns: 'app' }),
    disabled: false,
    icon: Icon.TableView,
    menuLevel: 1,
    sidebar: {
      'sidebar-assignment-info': { isHidden: false, isCollapsed: false },
    },
  },
  {
    id: 'assignment-menu-stats',
    title: t('Stats', { ns: 'app' }),
    titleMinimized: t('Stats', { ns: 'app' }),
    disabled: false,
    icon: Icon.DataStatsPie,
    menuLevel: 1,
    sidebar: {
      'sidebar-assignment-info': { isHidden: false, isCollapsed: false },
    },
  },
  {
    id: 'assignment-menu-simulation',
    title: t('Simulations', { ns: 'app' }),
    titleMinimized: t('Simulations', { ns: 'app' }),
    disabled: false,
    icon: Icon.SimulationsArrow,
    menuLevel: 1,
    sidebar: {
      'sidebar-assignment-info': { isHidden: false, isCollapsed: false },
    },
  },
];
