import { StringSearchFilterType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  AssignmentMetric,
  AssignmentMetricDescriptorBase,
} from './assignmentMetric.types';

export const assignmentMetricDescriptors: AssignmentMetricDescriptorBase[] = [
  // Velocity
  {
    type: 'abc',
    path: 'skuKey',
    rangeType: 'distinct',
    aggregationFn: 'min',
  },
  // Item
  {
    type: 'consignee',
    path: 'consignee',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'consigneeSku',
    path: 'skuKey',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'skuGroup',
    path: 'skuGroup',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'subGroup',
    path: 'subGroup',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'transportClass',
    path: 'transportClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'stockCategory',
    path: 'stockCategory',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'storageClass',
    path: 'storageClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'pollutionClass',
    path: 'pollutionClass',
    rangeType: 'distinct',
    aggregationFn: 'min',
    qualitativePaletteId: 'dimension',
  },
  {
    type: 'stockQty',
    path: 'stock_qty',
    rangeType: 'range-pow',
    aggregationFn: 'sum',
    sequentialPaletteId: 'elevation-sort',
  },
  {
    type: 'stockEACHQty',
    path: 'stockUomQuantity',
    rangeType: 'range-pow',
    aggregationFn: 'sum',
    sequentialPaletteId: 'elevation-sort',
    filterBy: {
      stockUom: {
        value: 'EA',
        type: StringSearchFilterType.CONTAINS,
      },
    },
  },
  {
    type: 'stockCASEQty',
    path: 'stockUomQuantity',
    rangeType: 'range-pow',
    aggregationFn: 'sum',
    sequentialPaletteId: 'elevation-sort',
    filterBy: {
      stockUom: {
        value: 'CS',
        type: StringSearchFilterType.CONTAINS,
      },
    },
  },
  {
    type: 'stockPLQty',
    path: 'stockUomQuantity',
    rangeType: 'range-pow',
    aggregationFn: 'sum',
    sequentialPaletteId: 'elevation-sort',
    filterBy: {
      stockUom: {
        value: 'PL',
        type: StringSearchFilterType.CONTAINS,
      },
    },
  },
];

export const assignmentMetricDescriptorsMap = _.keyBy(
  assignmentMetricDescriptors,
  d => d.type,
) as Record<AssignmentMetric, AssignmentMetricDescriptorBase>;
