import classNames from 'classnames';
import React from 'react';

export type HelpItemListCardProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  id?: string;
  transparent?: boolean;
  onClick?: () => void;
};

export const HelpItemListCard = (props: HelpItemListCardProps) => {
  return (
    <div
      id={props.id}
      data-component="HelpItemListCard"
      className={classNames(
        'flex-1 p-4 group',
        'mb-px- mb-0.5 ltr:mr-0.5 rtl:ml-0.5 isolate',
        'md:min-w-half sm:min-w-full lg:min-w-hlaf xl:min-w-quarter',
        { 'bg-app-panel/70 shadow': !props.transparent },
        props.className,
      )}
    >
      {props.title}
      {props.children}
    </div>
  );
};
