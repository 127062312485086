import classNames from 'classnames';
import React from 'react';

interface LegendItemProps {
  legendType?: 'Warn' | 'Info' | 'Route';
  legendSize?: 'Start' | 'Full' | 'End';
  bgColor?: string;
  textColor?: string;
  className?: string;
}

const styleEventPath = 'rounded-sm';
const styleLegendPosition = 'absolute z-0 ltr:left-5 rtl:right-5';

const LegendItem: React.FC<LegendItemProps> = ({
  className,
  bgColor,
  textColor,
  legendType = 'Route',
  legendSize = 'Full',
}) => (
  <span
    data-component={`LegendItem${legendType && legendType}`}
    aria-label="LegendItem"
    className={classNames(
      'w-2',
      styleEventPath,
      styleLegendPosition,
      legendSize === 'Full' && 'bottom-0 top-0 h-full',
      legendSize === 'Start' && 'top-1/2 mt-1 h-1/2',
      legendSize === 'End' && 'bottom-1/2 mb-2 h-1/2',
      legendType === 'Warn' && 'bg-menu-active',
      legendType === 'Info' && 'bg-menu',
      { 'bg-route-line_full': legendType != 'Warn' && legendType != 'Info' },
      className,
    )}
    style={{ background: bgColor, color: textColor }}
  ></span>
);

export default LegendItem;
