import * as React from 'react';
const WarehouseMenuOptionIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseMenuOptionIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,58c-17.65,0-32,14.35-32,32s14.35,32,32,32,32-14.35,32-32-14.35-32-32-32ZM77.71,104.86h-27.43c-1.26,0-2.29-1.02-2.29-2.29s1.02-2.29,2.29-2.29h27.43c1.26,0,2.29,1.02,2.29,2.29s-1.02,2.29-2.29,2.29ZM77.71,92.29h-27.43c-1.26,0-2.29-1.02-2.29-2.29s1.02-2.29,2.29-2.29h27.43c1.26,0,2.29,1.02,2.29,2.29s-1.02,2.29-2.29,2.29ZM77.71,79.71h-27.43c-1.26,0-2.29-1.02-2.29-2.29s1.02-2.29,2.29-2.29h27.43c1.26,0,2.29,1.02,2.29,2.29s-1.02,2.29-2.29,2.29Z"
    />
    <path
      strokeWidth={0}
      d="M64,27.22L5.2,41.26v56.91h22.47c-.65-2.76-1.01-5.64-1.01-8.6,0-20.54,16.65-37.19,37.19-37.19s37.19,16.65,37.19,37.19c0,2.96-.36,5.84-1.01,8.6h22.76v-56.91l-58.8-14.03Z"
    />
  </svg>
);
export default WarehouseMenuOptionIcon;
