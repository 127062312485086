import * as React from 'react';
const RoundParallelIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoundParallelIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M4,63.93c0,33.16,26.77,60.07,59.93,60.07,33.16,0,60.07-26.91,60.07-60.07,0-33.16-26.91-59.93-60.07-59.93-33.16,0-59.93,26.77-59.93,59.93ZM89.99,24.31l13.38,15.91c1.19,1.34.15,3.27-1.78,3.27h-6.1c0,10.56-.18,21.39-.18,31.95,0,16.29-13.24,29.62-29.53,29.63-16.29,0-29.51-13.09-29.51-29.38v-31.7s-6.11,0-6.11,0c-1.93,0-3.12-1.93-1.93-3.27l13.38-15.91c1.04-1.19,2.97-1.19,3.87,0l13.38,15.91c1.19,1.34.15,3.27-1.78,3.27h-6.1s-.19,31.33-.19,31.33c0,8.28,6.72,15,15,15,8.28,0,15-6.72,15-15h-.02s0-31.82,0-31.82h-6.1c-1.93,0-3.12-1.93-1.93-3.27l13.38-15.91c1.04-1.19,2.97-1.19,3.87,0Z"
    />
  </svg>
);
export default RoundParallelIcon;
