import {
  AllocationEnforcePickability,
  AllocationItemPriority,
  AllocationLocationPriority,
  AllocationPolicyFragment,
  AllocationPolicyInput,
  AllocationRunResultDeallocatedReason,
  AllocationRunResultRequirementGroupBy,
  AllocationRunResultUnallocatedReason,
  AllocationSettingsFragment,
  AllocationSettingsInput,
  DeallocateType,
  DeallocationPolicyFragment,
  DeallocationPolicyInput,
  ReallocateQuantitySource,
  ReallocateType,
  ReallocateUomSource,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { cloneWithoutTypename } from '../../../common/utils';
import { getSKUKey } from '../../../metrics/analyzeProduct/analyzeProductMetric.helper';
import { getPolicyMatchInput } from '../../../policyFilters/policyFilter.helper';
import {
  AllocationMetric,
  AllocationMetricDescriptor,
  AllocationSummaryGroupByDescriptor,
  AllocationSummaryGroupByType,
} from './allocation.types';

export function getAllocationSummaryGroutByOptions(
  t: TFunction<'simulation'>,
): Record<AllocationSummaryGroupByType, AllocationSummaryGroupByDescriptor> {
  const options: AllocationSummaryGroupByDescriptor[] = [
    {
      type: 'consignee',
      title: t('Consignee', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.CONSIGNEE],
      keyColumns: ['consignee'],
      getMetricValue: row => row.consignee,
      heatmapMetric: 'consignee',
    },
    {
      type: 'item',
      title: t('Item', { ns: 'simulation' }),
      groupBy: [
        AllocationRunResultRequirementGroupBy.CONSIGNEE,
        AllocationRunResultRequirementGroupBy.SKU,
      ],
      keyColumns: ['consignee', 'sku'],
      getMetricValue: row =>
        getSKUKey({ consignee: row.consignee, sku: row.sku }),
      heatmapMetric: 'consignee-sku',
    },
    {
      type: 'sku-group',
      title: t('Item Group', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.SKU_GROUP],
      keyColumns: ['skuGroup'],
      getMetricValue: row => row.skuGroup,
      heatmapMetric: 'sku-group',
    },
    {
      type: 'sub-group',
      title: t('Sub Group', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.SUBGROUP],
      keyColumns: ['subGroup'],
      getMetricValue: row => row.subGroup,
      heatmapMetric: 'sub-group',
    },
    {
      type: 'stock-category',
      title: t('Stock category', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.STOCK_CATEGORY],
      keyColumns: ['stockCategory'],
      getMetricValue: row => row.stockCategory,
      heatmapMetric: 'stock-category',
    },
    {
      type: 'transport-class',
      title: t('Transport class', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.TRANSPORT_CLASS],
      keyColumns: ['transportClass'],
      getMetricValue: row => row.transportClass,
      heatmapMetric: 'transport-class',
    },
    {
      type: 'storage-class',
      title: t('Storage class', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.STORAGE_CLASS],
      keyColumns: ['storageClass'],
      getMetricValue: row => row.storageClass,
      heatmapMetric: 'storage-class',
    },
    {
      type: 'pollution-class',
      title: t('Pollution class', { ns: 'simulation' }),
      groupBy: [AllocationRunResultRequirementGroupBy.POLLUTION_CLASS],
      keyColumns: ['pollutionClass'],
      getMetricValue: row => row.pollutionClass,
      heatmapMetric: 'pollution-class',
    },
  ];
  return _.keyBy(options, o => o.type) as Record<
    AllocationSummaryGroupByType,
    AllocationSummaryGroupByDescriptor
  >;
}

export function getAllocationMetricDescriptors(
  t: TFunction<'simulation'>,
): Record<AllocationMetric, AllocationMetricDescriptor> {
  const descriptors: AllocationMetricDescriptor[] = [
    {
      type: 'all',
      title: t`All`,
      columns: [],
    },
    {
      type: 'required',
      title: t`Ordered`,
      columns: ['requiredQty'],
      visibleTableColumns: ['requiredQty', 'requiredUOM'],
    },
    {
      type: 'assigned',
      title: t`Stock`,
      columns: ['initiallyPickableQty'],
      visibleTableColumns: [
        'initiallyPickableQty',
        'initiallyPickableUOM',
        'initiallyPickableUOMString',
        'initiallyPickableLocationCount',
      ],
    },
    {
      type: 'allocated',
      title: t`Allocated`,
      columns: ['allocatedQty'],
      visibleTableColumns: [
        'allocatedQty',
        'allocatedUOM',
        'allocatedUOMString',
        'allocatedLocationCount',
        'allocatedSharedQty',
        'allocatedSharedUOM',
        'allocatedSharedUOMString',
        'allocatedSharedLocationCount',
      ],
    },

    // {
    //   type: 'reallocated',
    //   title: t`Moved`,
    //   columns: ['toReallocateQty'],
    //   visibleTableColumns: [
    //     'toReallocateQty',
    //     'toReallocateUOM',
    //     'toReallocateUOMString',
    //   ],
    // },
    {
      type: 'deallocated',
      title: t`Moved / Removed`,
      columns: ['deallocatedQty'],
      visibleTableColumns: [
        'toReallocateQty',
        'toReallocateUOM',
        'toReallocateUOMString',
        'deallocatedQty',
        'deallocatedUOM',
        'deallocatedUOMString',
        'deallocatedReasons',
        'deallocatedReasonsString',
        'deallocatedLocationCount',
      ],
    },
    // {
    //   type: 'unpickable',
    //   title: t`Issues`,
    //   columns: ['unpickableQty', 'deallocatedReasonsString'],
    // },
    {
      type: 'unallocated',
      title: t`Remaining`,
      columns: ['unAllocatedQty'],
      visibleTableColumns: [
        'unAllocatedQty',
        'unAllocatedUOM',
        'unAllocatedUOMString',
        'unAllocatedReasons',
      ],
    },
    {
      type: 'unallocated-fully',
      title: t`Issues`,
      columns: ['unAllocatedQty'],
      emptyColumns: ['allocatedQty'],
      visibleTableColumns: [
        'allocatedQty',
        'allocatedUOM',
        'allocatedUOMString',
        'allocatedLocationCount',
        'unAllocatedQty',
        'unAllocatedUOM',
        'unAllocatedUOMString',
        'unAllocatedReasons',
        'unpickableQty',
        'deallocatedReasonsString',
      ],
    },
  ];
  return _.keyBy(descriptors, d => d.type) as Record<
    AllocationMetric,
    AllocationMetricDescriptor
  >;
}

export const ALLOCATION_POLICY_DEFAULT_RULE_ID =
  'ALLOCATION_POLICY_DEFAULT_RULE_ID';

export function getAllocationPolicyDefault(
  t: TFunction<'simulation'>,
): AllocationPolicyFragment {
  return {
    rules: [],
    defaultRule: {
      id: ALLOCATION_POLICY_DEFAULT_RULE_ID,
      title: t`Default rule`,
    },
  };
}

export function getAllocationPolicyInput(
  policy: AllocationPolicyFragment | null | undefined,
): AllocationPolicyInput {
  if (!policy) {
    return {
      rules: [],
      defaultRule: undefined,
    };
  }

  return {
    ...cloneWithoutTypename(policy),
    rules: policy.rules
      ? policy.rules.map(r => ({
          ...cloneWithoutTypename(r),
          itemsMatch: getPolicyMatchInput(r.itemsMatch),
          locationsMatch: getPolicyMatchInput(r.locationsMatch),
        }))
      : [],
    defaultRule: policy.defaultRule
      ? {
          ...cloneWithoutTypename(policy.defaultRule),
          itemsMatch: getPolicyMatchInput(policy.defaultRule.itemsMatch),
          locationsMatch: getPolicyMatchInput(
            policy.defaultRule.locationsMatch,
          ),
        }
      : undefined,
  };
}

export const DEALLOCATION_POLICY_DEFAULT_RULE_ID =
  'DEALLOCATION_POLICY_DEFAULT_RULE_ID';

export function getDeallocationPolicyDefault(
  t: TFunction<'simulation'>,
): DeallocationPolicyFragment {
  return {
    rules: [],
    defaultRule: {
      id: DEALLOCATION_POLICY_DEFAULT_RULE_ID,
      title: t`Default rule`,
    },
  };
}

export function getDeallocationPolicyInput(
  policy: DeallocationPolicyFragment,
): DeallocationPolicyInput {
  return {
    ...cloneWithoutTypename(policy),
    rules: _.map(policy.rules, r => ({
      ...cloneWithoutTypename(r),
      itemsMatch: getPolicyMatchInput(r.itemsMatch),
      locationsMatch: getPolicyMatchInput(r.locationsMatch),
    })),
    defaultRule: {
      ...cloneWithoutTypename(policy.defaultRule),
      itemsMatch: getPolicyMatchInput(policy.defaultRule.itemsMatch),
      locationsMatch: getPolicyMatchInput(policy.defaultRule.locationsMatch),
    },
  };
}

export function getAllocationSettingsInput(
  settings: AllocationSettingsFragment,
): AllocationSettingsInput {
  if (_.isNil(settings)) return null;
  return {
    ...cloneWithoutTypename(settings),
    allocationPolicy: getAllocationPolicyInput(settings.allocationPolicy),
    deallocationPolicy: getDeallocationPolicyInput(settings.deallocationPolicy),
  };
}

export function getDeallocationTypeOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: DeallocateType.NONE,
      title: t(`No de-allocation`, { ns: 'simulation' }),
      type: DeallocateType.NONE,
      icon: null,
    },
    {
      id: DeallocateType.NON_COMPLIANT,
      title: t(`Non-compliant items only`, { ns: 'simulation' }),
      type: DeallocateType.NON_COMPLIANT,
      icon: null,
    },
    {
      id: DeallocateType.ALL,
      title: t(`All items`, { ns: 'simulation' }),
      type: DeallocateType.ALL,
      icon: null,
    },
  ];
}

export function getReallocationTypeOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: ReallocateType.NONE,
      title: t(`No re-allocation`, { ns: 'simulation' }),
      type: ReallocateType.NONE,
      icon: null,
    },
    {
      id: ReallocateType.REALLOCATE,
      title: t(`Re-allocate`, { ns: 'simulation' }),
      type: ReallocateType.REALLOCATE,
      icon: null,
    },
  ];
}

export function getReallocationUOMSourceOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: ReallocateUomSource.CAPACITY_UOM,
      title: t(`Location capacity UOM`, { ns: 'simulation' }),
      type: ReallocateUomSource.CAPACITY_UOM,
      icon: null,
    },
    {
      id: ReallocateUomSource.STOCK_UOM,
      title: t(`Stock UOM`, { ns: 'simulation' }),
      type: ReallocateUomSource.STOCK_UOM,
      icon: null,
    },
    {
      id: ReallocateUomSource.FIXED_UOM,
      title: t(`Fixed UOM`, { ns: 'simulation' }),
      type: ReallocateUomSource.FIXED_UOM,
      icon: null,
    },
  ];
}

export function getReallocationUOMQuantityOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: ReallocateQuantitySource.BY_CAPACITY,
      title: t(`Replenishment qty`, { ns: 'simulation' }),
      type: ReallocateQuantitySource.BY_CAPACITY,
      icon: null,
    },
    {
      id: ReallocateQuantitySource.STOCK_UOM_QUANTITY,
      title: t(`Stock UOM qty`, { ns: 'simulation' }),
      type: ReallocateQuantitySource.STOCK_UOM_QUANTITY,
      icon: null,
    },
    {
      id: ReallocateQuantitySource.FIXED_QUANTITY,
      title: t(`Fixed qty`, { ns: 'simulation' }),
      type: ReallocateQuantitySource.FIXED_QUANTITY,
      icon: null,
    },
  ];
}

export const getItemsPriorityOptions = (t: TFunction) => ({
  [AllocationItemPriority.ORDER_LINE_COUNT]: t('ABC'),
  [AllocationItemPriority.ESTIMATED_REPLENISHMENT_COUNT]: t(
    'Replenishment count',
  ),
  [AllocationItemPriority.REMAINING_REQUIRED_VOLUME]: t('Ordered volume'),
  [AllocationItemPriority.SKU_GROUP]: t('Item group'),
  [AllocationItemPriority.SUBGROUP]: t('Sub group'),
  [AllocationItemPriority.TRANSPORT_CLASS]: t('Transport class'),
  [AllocationItemPriority.STOCK_CATEGORY]: t('Stock category'),
  [AllocationItemPriority.STORAGE_CLASS]: t('Storage class'),
  [AllocationItemPriority.POLLUTION_CLASS]: t('Pollution class'),
  [AllocationItemPriority.UOM_HEIGHT]: t('Item height'),
  [AllocationItemPriority.UOM_WIDTH]: t('Item width'),
  [AllocationItemPriority.UOM_LENGTH]: t('Item length'),
  [AllocationItemPriority.UOM_VOLUME]: t('Item volume'),

  [AllocationItemPriority.TOTAL_DAYS_WITH_ORDERS]: t('Total days with orders'),
  [AllocationItemPriority.TOTAL_WEEKS_WITH_ORDERS]: t(
    'Total weeks with orders',
  ),
  [AllocationItemPriority.DAILY_ORDER_LINE_COUNT_AVG]: t(
    'Daily order line count avg',
  ),
  [AllocationItemPriority.DAILY_ORDER_LINE_COUNT_MAX]: t(
    'Daily order line count max',
  ),
  [AllocationItemPriority.DAILY_ORDER_LINE_COUNT_CV]: t(
    'Daily order line count cv',
  ),
  [AllocationItemPriority.WEEKLY_ORDER_LINE_COUNT_AVG]: t(
    'Weekly order line count avg',
  ),
  [AllocationItemPriority.WEEKLY_ORDER_LINE_COUNT_MAX]: t(
    'Weekly order line count max',
  ),
  [AllocationItemPriority.WEEKLY_ORDER_LINE_COUNT_CV]: t(
    'Weekly order line count cv',
  ),
  [AllocationItemPriority.WEEKLY_DAYS_WITH_ORDERS_AVG]: t(
    'Weekly days with orders avg',
  ),
  [AllocationItemPriority.WEEKLY_DAYS_WITH_ORDERS_MAX]: t(
    'Weekly days with orders max',
  ),
  [AllocationItemPriority.WEEKLY_DAYS_WITH_ORDERS_CV]: t(
    'Weekly days with orders cv',
  ),
  [AllocationItemPriority.CUSTOM]: t('Custom'),
});

export const getLocationPriorityOptions = (t: TFunction) => ({
  [AllocationLocationPriority.DISTANCE_FROM_START]: t('Distance from start'),
  [AllocationLocationPriority.DISTANCE_TO_END]: t('Distance to end'),
  [AllocationLocationPriority.LOCATION_LEVEL]: t('Level'),
  [AllocationLocationPriority.LOCATION_ORDER]: t('Location order'),
  [AllocationLocationPriority.LOCATION_VOLUME]: t('Location Volume'),
  [AllocationLocationPriority.LOCATION_WIDTH]: t('Location width'),
  [AllocationLocationPriority.LOCATION_HEIGHT]: t('Location height'),
  [AllocationLocationPriority.LOCATION_LENGTH]: t('Location length'),
  [AllocationLocationPriority.BAY_WIDTH]: t('Bay Width'),
  [AllocationLocationPriority.BAY_TITLE]: t('Bay ID'),
  [AllocationLocationPriority.CUSTOM]: t('Custom'),
});

export enum AllocationPriorityDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  DEFAULT = 'DEFAULT',
}

export const getPriorityDirectionOptions = (t: TFunction) => ({
  [AllocationPriorityDirection.ASC]: t('Ascending'),
  [AllocationPriorityDirection.DESC]: t('Descending'),
  [AllocationPriorityDirection.DEFAULT]: t(''),
});

export const getPickabilityOptions = (
  t: TFunction<'simulation'>,
): Record<AllocationEnforcePickability, string> => ({
  [AllocationEnforcePickability.FIRST]: t('First'),
  [AllocationEnforcePickability.LAST]: t('Last'),
  [AllocationEnforcePickability.NEVER]: t('Never'),
});

export interface PrioritySetting {
  priority: string;
  direction?: AllocationPriorityDirection;
}

export function getPriorityValue(
  t: TFunction,
  priority: PrioritySetting[] | undefined,
  priorityOptions: Record<string, string>,
  directionOptions: Record<string, string>,
): string {
  return _.isEmpty(priority)
    ? t('Default')
    : _(priority)
        .map(p => {
          const priorityText = priorityOptions[p.priority];
          const directionText = p.direction
            ? directionOptions[p.direction]
            : '';
          return `${priorityText}${directionText ? ` (${directionText})` : ''}`;
        })
        .join(', ');
}

export function getUnallocatedReasonOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: AllocationRunResultUnallocatedReason.NO_SPACE_FOR_PICKABILITY,
      title: t(`No space for pickability`, { ns: 'simulation' }),
      type: AllocationRunResultUnallocatedReason.NO_SPACE_FOR_PICKABILITY,
    },
    {
      id: AllocationRunResultUnallocatedReason.NO_SPACE,
      title: t(`No space`, { ns: 'simulation' }),
      type: AllocationRunResultUnallocatedReason.NO_SPACE,
    },
    {
      id: AllocationRunResultUnallocatedReason.DEALLOCATE_RULE,
      title: t(`De-allocate rule`, { ns: 'simulation' }),
      type: AllocationRunResultUnallocatedReason.DEALLOCATE_RULE,
    },
    {
      id: AllocationRunResultUnallocatedReason.ROUND_LIMIT,
      title: t(`Round limit`, { ns: 'simulation' }),
      type: AllocationRunResultUnallocatedReason.ROUND_LIMIT,
    },
    {
      id: AllocationRunResultUnallocatedReason.NO_RULE,
      title: t(`No Allocation rule`, { ns: 'simulation' }),
      type: AllocationRunResultUnallocatedReason.NO_RULE,
    },
  ];
}

export function getDeallocatedReasonOptions(t: TFunction<'simulation'>) {
  return [
    {
      id: AllocationRunResultDeallocatedReason.NON_COMPLIANT,
      title: t(`Non-compliant`, { ns: 'simulation' }),
      type: AllocationRunResultDeallocatedReason.NON_COMPLIANT,
    },
    {
      id: AllocationRunResultDeallocatedReason.DEALLOCATE_RULE,
      title: t(`De-allocate rule`, { ns: 'simulation' }),
      type: AllocationRunResultDeallocatedReason.DEALLOCATE_RULE,
    },
    {
      id: AllocationRunResultDeallocatedReason.REPLACED,
      title: t(`Replaced`, { ns: 'simulation' }),
      type: AllocationRunResultDeallocatedReason.REPLACED,
    },
  ];
}
