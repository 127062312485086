import _ from 'lodash';
import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useState,
} from 'react';

import { AriaTextFieldProps } from '@react-types/textfield';
import classNames from 'classnames';
import { useTextField } from 'react-aria';
import { useTranslation } from 'react-i18next';
import * as Icon from '../../components/icons';
import useSharedRef from '../../utils/use-shared-ref';

export type InputTextProps = {
  children?: React.ReactNode;

  className?: string;
  classNameLabel?: string;
  classNameLabelTitle?: string;
  dataComponent?: string;
  autoComplete?: string;
  optional?: boolean;
  required?: boolean;
  readonly?: boolean;
  animate?: boolean;
  horizontal?: boolean;
  showPasswordPreview?: boolean;
  onChangeValidate?: ChangeEventHandler;
} & AriaTextFieldProps;

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props: InputTextProps, ref) => {
    const { t } = useTranslation('app');
    const [id] = useState<string>(_.uniqueId());
    const { label } = props;
    const sharedRef = useSharedRef<HTMLInputElement>(ref);
    // const { labelProps, inputProps } = useTextField(props, sharedRef);
    const {
      labelProps,
      inputProps,
    }: { labelProps: any; inputProps: InputHTMLAttributes<HTMLInputElement> } =
      useTextField(props, sharedRef);

    const [showPasswordPreview, setShowPasswordPreview] = useState(false);
    const inputType = props.type || 'text';

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control, react/jsx-props-no-spreading
      <label
        data-component={`Input${props.dataComponent || 'Text'}`}
        data-label={`button-toolbar-${label || ''}`}
        aria-label={`button-toolbar-${label || ''}`}
        id={id}
        {...labelProps}
        className={classNames(
          'flex',

          props.horizontal ? 'w-full flex-row items-center' : 'flex-col',
          inputProps.disabled ? 'text-menu-text/50' : 'text-menu-text',
          props.classNameLabel,
        )}
      >
        <div
          className={classNames(
            props.classNameLabelTitle && ' flex text-sm',
            props.horizontal ? 'items-center ltr:pr-2 rtl:pl-2' : 'mb-2',
          )}
        >
          {label}
          {props.optional && (
            <span
              className={classNames('opacity-50', 'ltr:ml-2 rtl:mr-2')}
            >{t`(Optional)`}</span>
          )}
          {props.isReadOnly && (
            <span
              className={classNames(
                'opacity-75',
                'ltr:ml-2 rtl:mr-2',
                'text-xxs',
              )}
            >
              <Icon.Lock
                className={classNames(
                  'h-4 w-4',
                  'fill-current ltr:mr-1 rtl:ml-1',
                )}
              />
            </span>
          )}
          {props.required && (
            <span
              className={classNames(
                'opacity-75',
                'ltr:ml-0.5 rtl:mr-0.5',
                'text-xs',
              )}
              title={t`Required`}
            >{`*`}</span>
          )}
        </div>
        <div
          className={classNames(
            'flex-1',
            props.children ? 'flex flex-row' : 'w-full flex-col',
            props.showPasswordPreview ? 'relative' : '',
          )}
        >
          {props.showPasswordPreview && (
            <>
              {/* <Spacer flexspace /> */}
              <button
                type="button"
                onClick={() => setShowPasswordPreview(!showPasswordPreview)}
                className="absolute right-1 top-4"
              >
                {showPasswordPreview ? (
                  <Icon.Hide
                    className={classNames(
                      'h-5 w-5 fill-current ltr:mr-1 rtl:ml-1',
                    )}
                  />
                ) : (
                  <Icon.Show
                    className={classNames(
                      'h-5 w-5 fill-current ltr:mr-1 rtl:ml-1',
                    )}
                  />
                )}
              </button>
            </>
          )}
          <input
            ref={sharedRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(inputProps as InputHTMLAttributes<HTMLInputElement>)}
            type={showPasswordPreview ? 'text' : inputProps.type}
            onChange={e => {
              inputProps.onChange && inputProps.onChange(e);
              props.onChangeValidate && props.onChangeValidate(e);
            }}
            className={classNames(
              'flex-1',
              props.horizontal ? 'p-1 text-base' : 'p-3 text-lg',
              'w-full',
              'placeholder-opacity-30',
              'focus:placeholder-menu-active-secondary focus:outline-none focus:ring-0',
              'active:text-menu-active active:border-opacity-100',
              'read-only:border-menu-active/20 read-only:hover:border-menu-active/10 read-only:text-menu-text/50 read-only:hover:text-menu-text/50 read-only:cursor-not-allowed',
              'disabled:border-menu/10 disabled:hover:border-menu/10 disabled:text-menu-text/75 disabled:cursor-not-allowed',
              'bg-app-input/90 active:bg-app-input focus:bg-app-input/75 text-menu-text',
              'shadow read-only:shadow-none focus:shadow-xl active:shadow-xl',
              inputProps.disabled
                ? 'text-menu-text/50 bg-app-input/60'
                : 'border-menu-active/50 placeholder-menu-text/30 hover:border-menu-active hover:text-menu-text hover:placeholder-menu-active-secondary focus:border-menu-active focus:text-menu-text hover:border-opacity-100 focus:border-opacity-100',
              {
                'border-animation animate-rotate-border-90': props.animate,
              },
              props.className,
            )}
            autoCapitalize={'none'}
            autoComplete={props.autoComplete}
            readOnly={props.isReadOnly}
          />

          {props.children}
        </div>
      </label>
    );
  },
);

export default InputText;

// InputPassword
export type InputPasswordProps = Omit<InputTextProps, 'type'>;
export const InputPassword = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props: InputPasswordProps, ref) => (
    <InputText dataComponent="Password" type="password" {...props} ref={ref} />
  ),
);

// InputEmail
export type InputEmailProps = Omit<InputTextProps, 'type'>;
export const InputEmail = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props: InputEmailProps, ref) => (
    <InputText dataComponent="Email" type="email" {...props} ref={ref} />
  ),
);
