import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../../../components/InboxZero';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../../containers/SidebarContainer';
import LayoutDashboardCard from '../../../../layout/LayoutDashboardCard';
import ConverterAisleInfoPanel from '../panels/ConverterAisleInfoPanel';
import ConverterAreaInfoPanel from '../panels/ConverterAreaInfoPanel';
import ConverterAutoSpacerInfoPanel from '../panels/ConverterAutoSpacerInfoPanel';
import ConverterBayInfoPanel from '../panels/ConverterBayInfoPanel';
import ConverterPortalPanel from '../panels/ConverterPortalPanel';
import ConverterSpacerAreaInfoPanel from '../panels/ConverterSpacerAreaInfoPanel';
import ConverterSpacerInfoPanel from '../panels/ConverterSpacerInfoPanel';
import {
  converterEditableArea,
  converterSelectedArea,
} from '../store/converter.area.state';
import {
  converterLayoutSelectedAisle,
  converterLayoutSelectedBay,
  converterLayoutShowPortals,
  converterSelectedPortal,
} from '../store/converter.layout.state';
import {
  converterLayoutDataInitial,
  converterSpacerAreaSettings,
} from '../store/converter.state';

const ConverterPropertyPanelSidebarCmp: React.FC = () => {
  const { t } = useTranslation('designer');

  const layoutImport = useRecoilValue(converterLayoutDataInitial);
  const bay = useRecoilValue(converterLayoutSelectedBay);
  const selectedArea = useRecoilValue(converterSelectedArea);
  const editableArea = useRecoilValue(converterEditableArea);
  const aisle = useRecoilValue(converterLayoutSelectedAisle);
  const portal = useRecoilValue(converterSelectedPortal);
  const showPortal = useRecoilValue(converterLayoutShowPortals);
  const spacerAreaConfigs = useRecoilValue(converterSpacerAreaSettings);

  const getContent = () => {
    const areaId = selectedArea?.id ?? editableArea?.id;
    if (areaId && spacerAreaConfigs?.[areaId]) {
      return <ConverterSpacerAreaInfoPanel />;
    }
    if (showPortal && portal) return <ConverterPortalPanel />;
    if (bay) return <ConverterBayInfoPanel />;
    if (aisle?.isAutoSpacer) return <ConverterAutoSpacerInfoPanel />;
    if (aisle?.isSpacer) return <ConverterSpacerInfoPanel />;
    if (aisle) return <ConverterAisleInfoPanel />;
    if (selectedArea && !editableArea) return <ConverterAreaInfoPanel />;

    return (
      <ContainerScroll hasOverflowY>
        <LayoutDashboardCard summary={layoutImport?.converterSummary} />
        <InboxZero
          // className="z-0 w-full"
          center={false}
          // selfCenter
          message={t`No Selection`}
          message_helper={t`Click once on an Area to edit\nDouble-Click Area to edit Aisles, Bays or Locations`}
        />
      </ContainerScroll>
    );
  };

  return <>{getContent()}</>;
};

export default () => {
  const { t } = useTranslation('designer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-converter-info-common',
    title: t`Layout panel`,
    size: 'md',
  };
  return (
    <SidebarContainer {...containerProps}>
      <ConverterPropertyPanelSidebarCmp />
    </SidebarContainer>
  );
};
