import * as React from 'react';
const WarehouseArchiveIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseArchiveIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M37.75,59.44v32.24h11.25v-11.25c0-2.07,1.68-3.75,3.75-3.75s3.75,1.68,3.75,3.75v11.25h3.75v-15c0-2.07,1.68-3.75,3.75-3.75s3.75,1.68,3.75,3.75v15h3.75v-11.25c0-2.07,1.68-3.75,3.75-3.75s3.75,1.68,3.75,3.75v11.25h11.25v-32.24l-26.25-23.12-26.25,23.12Z"
    />
    <path
      strokeWidth={0}
      d="M94,91.68h7.5v-11.25c0-2.07,1.68-3.75,3.75-3.75s3.75,1.68,3.75,3.75v11.25h7.5c2.07,0,3.75-1.68,3.75-3.75v-26.25h-26.25v30Z"
    />
    <path
      strokeWidth={0}
      d="M7.75,87.93c0,2.07,1.68,3.75,3.75,3.75h7.5v-11.25c0-2.07,1.68-3.75,3.75-3.75s3.75,1.68,3.75,3.75v11.25h7.5v-30H7.75v26.25Z"
    />
  </svg>
);
export default WarehouseArchiveIcon;
