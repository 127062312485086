import {
  AgentRoleSettings,
  UomHandlingSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  AgentEditorSettings,
  AgentFilterSectionConfig,
} from './AgentEditorSettings';

export function getReplenishmentFilterConfiguration(
  config: AgentEditorSettings,
  replenishmentRole: AgentRoleSettings,
  rolePrefix: string,
  uoms: string[],
  t: TFunction<'simulation'>,
): AgentFilterSectionConfig[] {
  function getTimePerUom(
    group: UomHandlingSettingsFragment[],
    groupPrefix: string,
    getTitle: (uom: string) => string,
  ) {
    const getUomIndex = (uom: string) => _.findIndex(group, g => g.uom === uom);

    return _(uoms)
      .filter(u => _.some(group, g => g.uom === u))
      .map(uom => ({
        path: [rolePrefix, groupPrefix, `[${getUomIndex(uom)}]`, 'timePerUom'],
        title: getTitle(uom),
        filterConfig: config.timePerUom,
      }))
      .value();
  }

  return [
    {
      title: t`Timing — Replenishment`,
      counter: t`B2`,
      filterGroups: [
        {
          title: t(`Task Pre-handling Time`, { ns: 'simulation' }),
          description: t(
            `Time spent before replenishment task starts. It can include task assignments, handling unit preparation, labels, or instruction printing. Configure it per task, quantity (UOM), or just as a fixed value..`,
            { ns: 'simulation' },
          ),
          category: t(`HANDLING`, { ns: 'simulation' }),
          filters: [
            {
              path: [rolePrefix, 'preHandling', 'timeFixed'],
              title: t(`Fixed time`, { ns: 'simulation' }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'preHandling', 'timePerOrder'],
              title: t(`Time per replenishment job`, { ns: 'simulation' }),
              filterConfig: config.timePerOrder,
            },
            {
              path: [rolePrefix, 'preHandling', 'timePerPicklistLine'],
              title: t(`Time per replenishment task`, { ns: 'simulation' }),
              filterConfig: config.timePerLine,
            },
            {
              path: [rolePrefix, 'preHandling', 'timePerUom'],
              title: t(`Time per Units replenished`, { ns: 'simulation' }),
              filterConfig: config.timePerUom,
            },
          ],
        },
        {
          title: t(`Replenishment Time (At Location)`, { ns: 'simulation' }),
          description: t(
            `Replenishment handling time. Time can be estimated by task, UOM quantity, weight, volume, or just as a constant.`,
            { ns: 'simulation' },
          ),
          category: t(`SPECIFICATION`, { ns: 'simulation' }),
          filters: [
            {
              path: [rolePrefix, 'handling', 'timeFixed'],
              title: t(`Replenishment Handling time`, {
                ns: 'simulation',
              }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'handling', 'timePerWeight'],
              title: t(`Replenishment Time - Weight`, { ns: 'simulation' }),
              filterConfig: config.timePerWeight,
            },
            {
              path: [rolePrefix, 'handling', 'timePerVolume'],
              title: t(`Replenishment Time - Volume`, { ns: 'simulation' }),
              filterConfig: config.timePerVolume,
            },
            ...getTimePerUom(
              replenishmentRole.handling.timePerUom,
              'handling.timePerUom',
              uom =>
                t(`Replenishment Time - UOM Type: {{uom}}`, {
                  ns: 'simulation',
                  uom,
                }),
            ),
          ],
        },
        {
          title: t(`Post-Handling Replenishment (At location)`, {
            ns: 'simulation',
          }),
          category: t(`HANDLING`, { ns: 'simulation' }),
          description: t(
            `Additional Time spent to organise stock on the handling unit. Can be measured per quantity or can be constant.`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: [rolePrefix, 'handlingCompletion', 'timeFixed'],
              title: t(`Replenishment Completion - Fixed time`, {
                ns: 'simulation',
              }),
              filterConfig: config.timeAbsolute,
            },

            {
              path: [rolePrefix, 'handlingCompletion', 'timePerUom'],
              title: t(`Replenishment Completion - Time per UOM qty`, {
                ns: 'simulation',
              }),
              filterConfig: config.timePerUom,
            },
          ],
        },
        {
          title: t(`Replenishment Post-Handling`, { ns: 'simulation' }),
          category: t(`HANDLING`, { ns: 'simulation' }),
          description: t(
            `Additional time spent by the employee, after stock handling at a location has been completed. Can be measured per task, UOM qty or can be constant.,`,
            { ns: 'simulation' },
          ),
          filters: [
            {
              path: [rolePrefix, 'postHandling', 'timeFixed'],
              title: t(`Post-Handling - Replenishment Fixed Time`, {
                ns: 'simulation',
              }),
              filterConfig: config.timeAbsolute,
            },
            {
              path: [rolePrefix, 'postHandling', 'timePerOrder'],
              title: t(`Post-Handling - Time per replenishment task`, {
                ns: 'simulation',
              }),
              filterConfig: config.timePerOrder,
            },
            {
              path: [rolePrefix, 'postHandling', 'timePerPicklistLine'],
              title: t(`Post-Handling - Time per replenishment job`, {
                ns: 'simulation',
              }),
              filterConfig: config.timePerLine,
            },
            {
              path: [rolePrefix, 'postHandling', 'timePerUom'],
              title: t(`Post-Handling Time per UOM qty`, { ns: 'simulation' }),
              filterConfig: config.timePerUom,
            },
          ],
        },
      ],
    },
  ];
}
