import React from 'react';
import { useTranslation } from 'react-i18next';
import { Panel } from '../../components/designer/panels/Panel';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SchemaHelper from '../SchemaHelper';
import { ImportMapPanel } from '../panels/ImportMapPanel';
import { getActivityFeedSchema } from './schema/activityFeedSchema';

const ImportActivityFeedMapPanel: React.FC = () => {
  const { t } = useTranslation('importer');
  return (
    <Panel>
      <ScreenTitle
        isSticky
        title={t`Map Fields`}
        subtitle={t`Activity Feed import`}
        hasStepCounter={'4.'}
      />
      <ImportMapPanel>
        <SchemaHelper schema={getActivityFeedSchema(t)} />
      </ImportMapPanel>
    </Panel>
  );
};

export default ImportActivityFeedMapPanel;
