import * as React from 'react';
const ArrowRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowRightIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M75.77,112.27h-36.37s35.36-35.39,35.36-35.39H4s0-25.75,0-25.75h70.76S39.4,15.73,39.4,15.73h36.37s48.23,48.27,48.23,48.27l-48.23,48.27Z"
    />
  </svg>
);
export default ArrowRightIcon;
