import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { feedMenuItems } from '../../feed/store/feed.default';
import { sidebarStateByType } from '../../store/sidebar.state';
import { actualitySelectedDocument } from '../store/actuality.state';
import { feedSelectedMenuItemId } from '../store/feed.state';
import { FeedMenuItemId } from '../store/feed.types';

type FeedMenuSidebarProps = {
  isMinimised: boolean;
  isEmbedded?: boolean;
};

const FeedMenuSidebar: React.FC<FeedMenuSidebarProps> = props => {
  const { t } = useTranslation('feed');
  const actuality = useRecoilValue(actualitySelectedDocument);
  const showFeedSetup = !actuality?.activityFeedId || !actuality?.layoutId;
  const [activeMenuId, setActiveMenuId] = useRecoilState(
    feedSelectedMenuItemId(actuality?.id),
  );

  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-feed-main'),
  );

  function changeActiveMenu(menuId: FeedMenuItemId) {
    setActiveMenuId(menuId);
    if (!sidebarState.isPinned) {
      setSidebarState({ ...sidebarState, isCollapsed: false });
    }
  }

  return (
    <Container data-component="FeedMenuSidebarContainer" col hasOverflowY>
      <ul
        data-component="FeedMenuSidebar"
        className={classNames(
          'relative',
          'flex flex-col',
          'text-start',
          'px-1',
        )}
      >
        {feedMenuItems(t).map(item => {
          return (
            <SidebarMenuItem
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeMenuId}
              treeLine={item.treeLine}
              treeLineStart={item.treeLineStart}
              treeLineEnd={item.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              stepCounter={item.stepCounter}
              disabled={item?.disabled}
              isHidden={item.id === 'feed-menu-activity' ? showFeedSetup : item.isHidden}
              onClick={() => changeActiveMenu(item.id)}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('feed');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-feed-menu',
    title: t`Menu`,
    size: 'xs',
  };

  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      widthAutoMinimised
      withMenuIcons={<FeedMenuSidebar isMinimised={true} />}
    >
      <FeedMenuSidebar isMinimised={false} />
    </SidebarContainer>
  );
};
