import * as React from 'react';
const OrderPickingPriorityIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="OrderPickingPriorityIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="48.23 114 48.23 114 51.09 112.19 51.09 106.08 28.21 120.38 28.21 126.72 31.07 124.9 31.07 122.03 36.61 118.68 38.96 120.01 41.82 118.19 41.82 114.98 45.32 112.76 48.23 114"
    />
    <path
      strokeWidth={0}
      d="M5.32,106.08v6.1s22.88,14.53,22.88,14.53v-6.34s-22.88-14.29-22.88-14.29ZM6.41,109.73l20.7,12.93v2.08s-20.7-13.14-20.7-13.14v-1.86Z"
    />
    <path
      strokeWidth={0}
      d="M71.53,2.15L18.15,30.22v43.52l9.86-5.69,25.98,15v20.04h70.05V30.22L71.53,2.15ZM51.47,65.09h-28.22v-31.28h28.22v31.28ZM85.72,73.67h-28.58v-39.86h28.58v39.86ZM118.11,95.77h-27.65v-40.53h27.65v40.53ZM118.11,50.1h-27.65v-16.29h27.65v16.29Z"
    />
    <polygon
      strokeWidth={0}
      points="35.46 40.59 35.75 40.59 35.75 55.91 29.6 55.91 29.6 59.88 45.51 59.88 45.51 55.91 40.66 55.91 40.66 37.02 33.13 37.02 27.66 44.23 30.81 46.68 35.46 40.59"
    />
    <path
      strokeWidth={0}
      d="M68.29,47.21c.55-.54,1.29-.81,2.23-.81.88,0,1.57.23,2.09.69s.78,1.12.78,1.97v.3c0,.78-.24,1.53-.73,2.24-.49.72-1.31,1.58-2.47,2.57l-6.58,5.47v4.1h15.01v-3.77h-10.05l3.98-3.08c1.24-.94,2.24-1.79,3.02-2.56.78-.77,1.41-1.61,1.88-2.53.48-.92.72-1.92.72-3.02,0-1.22-.28-2.3-.85-3.24-.57-.95-1.39-1.69-2.48-2.23-1.09-.54-2.38-.81-3.87-.81-2.07,0-3.77.49-5.08,1.48-1.31.99-2.22,2.27-2.72,3.84l3.92,1.5c.26-.88.66-1.58,1.21-2.12Z"
    />
    <path
      strokeWidth={0}
      d="M106.9,82.86c-.66.56-1.58.84-2.76.84-1.21,0-2.2-.28-2.98-.85-.78-.57-1.46-1.38-2.04-2.43l-3.72,2.88c.87,1.36,1.99,2.45,3.35,3.26,1.36.81,3.13,1.22,5.29,1.22,1.83,0,3.43-.28,4.8-.85,1.37-.57,2.44-1.37,3.2-2.41.76-1.04,1.14-2.25,1.14-3.63,0-1.54-.46-2.8-1.37-3.77-.92-.97-2.12-1.59-3.61-1.86v-.2c1.32-.22,2.4-.8,3.26-1.72.86-.93,1.29-2.1,1.29-3.53,0-1.23-.35-2.31-1.04-3.25-.69-.94-1.67-1.66-2.93-2.18-1.26-.51-2.73-.77-4.4-.77-1.92,0-3.52.33-4.8,1-1.28.67-2.42,1.64-3.4,2.91l3.18,2.95c.58-.89,1.29-1.58,2.13-2.06.84-.48,1.73-.72,2.69-.72,1.03,0,1.83.25,2.41.75.58.5.87,1.18.87,2.03v.23c0,.76-.31,1.37-.93,1.84-.63.47-1.55.7-2.78.7h-2.21v4.18h2.18c1.32,0,2.34.25,3.08.74.74.49,1.1,1.21,1.1,2.14v.24c0,.98-.33,1.75-.99,2.31Z"
    />
    <polygon
      strokeWidth={0}
      points="8.36 105.52 8.36 88.88 26.53 99.75 26.53 116.56 8.36 105.52"
    />
    <polygon
      strokeWidth={0}
      points="30.04 99.75 48.02 88.89 48.02 105.52 30.04 116.55 30.04 99.75"
    />
    <polygon
      strokeWidth={0}
      points="21.14 78.92 28.19 74.83 47.05 85.78 39.58 90.2 21.14 78.92"
    />
    <polygon
      strokeWidth={0}
      points="9.33 85.78 18.24 80.6 36.72 91.89 28.19 96.94 9.33 85.78"
    />
  </svg>
);
export default OrderPickingPriorityIcon;
