import { TFunction } from 'i18next';
import * as Icon from '../../components/icons';
import { OrderSetMenuItem } from './orderSet.types';

export const orderSetMenuItems: (
  t: TFunction<'app'>,
) => OrderSetMenuItem[] = t => [
  {
    id: 'order-set-menu-start',
    title: t(`Data`, { ns: 'app' }),
    titleMinimized: t(`Data`, { ns: 'app' }),
    disabled: false,
    icon: Icon.TableView,
    menuLevel: 1,
  },
  {
    id: 'order-set-menu-stats',
    title: t(`Stats`, { ns: 'app' }),
    titleMinimized: t(`Stats`, { ns: 'app' }),
    disabled: false,
    icon: Icon.DataStatsPie,
    menuLevel: 1,
  },
  {
    id: 'order-set-menu-simulation',
    title: t(`Simulations`, { ns: 'app' }),
    titleMinimized: t(`Simulations`, { ns: 'app' }),
    disabled: false,
    icon: Icon.SimulationsArrow,
    menuLevel: 1,
  },
];
