import * as React from 'react';
const CursorSelectMove1Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectMove1Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M50,39.56l11-6.52v20.95c0,1.66,1.34,3,3,3s3-1.34,3-3v-20.95l11,6.52L64,6.6l-14,32.96Z"
    />
    <path
      strokeWidth={0}
      d="M94.95,61h-20.95c-1.66,0-3,1.34-3,3s1.34,3,3,3h20.95l-6.52,11,32.96-14-32.96-14,6.51,11Z"
    />
    <path
      strokeWidth={0}
      d="M67,74c0-1.66-1.34-3-3-3s-3,1.34-3,3v20.95l-11-6.52,14,32.96,14-32.96-11,6.52v-20.95Z"
    />
    <path
      strokeWidth={0}
      d="M54,67c1.66,0,3-1.34,3-3s-1.34-3-3-3h-20.95l6.51-11-32.96,14,32.96,14-6.52-11h20.95Z"
    />
  </svg>
);
export default CursorSelectMove1Icon;
