import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../components/layout/TitleSection';
import { PolicyCardItem } from '../../components/policies/PolicyCardItem';
import { getPolicyGroupOptions } from '../../simulation/store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicyPickingRule,
  pickingPolicySelectedIdentity,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';

const PickingPolicyPickingGroupEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(pickingPolicySelectedIdentity);
  const [pickingRule, updatePickingRule] = useRecoilState(
    pickingPolicyPickingRule({
      ruleId: selectedIdentity?.ruleId,
      pickingRuleId: selectedIdentity?.pickingRuleId,
    }),
  );
  if (!selectedIdentity || !pickingRule) return null;

  const selected = pickingRule?.picklistGroupKey ?? null;

  const onSelectValue = (group: string) => {
    updatePickingRule({
      ...pickingRule,
      picklistGroupKey: group,
    });
  };

  const options = getPolicyGroupOptions(t);
  return (
    <div data-component="PickingPolicyPickingGroupEditor">
      <TitleSection
        id={`policy-editor-picking-mode-section`}
        title={t`Picklist line group by`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {_.map(options, (mode, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-group-${mode.id}`}
                    isActive={mode.id === selected}
                    onClick={() => onSelectValue(mode.id)}
                    //icon={getAgentIcon(agent.resourceTypeId as AgentCategoryId)}
                    // iconClassName={`w-12 h-12 ltr:mr-2 rtl:ml-2`}
                    title={mode.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default PickingPolicyPickingGroupEditor;
