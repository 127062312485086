import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';

export type TitleToolbarContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  className?: string;
  classNameContainer?: string;
  classNameContainerBodyChildren?: string;
  title: string;
  subtitle?: string;
  hasFilter?: string;
  loadingMessage?: string;
  maxHeight?: number;
  hasScroll?: boolean;
  hasPadding?: boolean;
  hidden?: boolean;
  icon?: string;
  borderTop?: boolean;
  collapsible?: boolean;
  transparent?: boolean;
};

const TitleToolbarContainer: React.FC<TitleToolbarContainerProps> = props => {
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = props.collapsible && state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  return (
    <div
      data-component={`TitleToolbarContainer-${props.title}`}
      className={classNames(
        'hover:bg-app-panel-dark z-auto w-full flex-1 hover:bg-opacity-10',
        { 'border-menu-500 border-b border-opacity-20': isClosed },
        { 'overflow-y-auto overflow-x-hidden': props.hasScroll },
        props.classNameContainer,
      )}
    >
      <Button
        full
        isTransparent
        buttonSize={`toolbar`}
        isDisabled={!props.collapsible}
        className={classNames(
          'panel-header accordion-header z-container group sticky top-0',
          props.transparent
            ? 'uppercase'
            : props.collapsible
              ? 'cursor-pointer'
              : '',
          'flex items-center',
          'p-0.5 py-1',
          'transition delay-75 duration-100 ease-in-out',
          { 'sticky top-0': props.hasScroll },
          'bg-sidebar-header/30 backdrop-blur',
          // { 'backdrop-blur bg-menu/50': props.hasScroll },

          {
            closed: isClosed,
          },
          props.className,
        )}
        onPress={props.collapsible && (() => toggleOpen())}
      >
        <h6
          className={classNames(
            'flex h-full flex-1 items-center',
            // 'mr-0',
            'text-menu-text/75 hover:text-menu-active any-hover:group-hover:text-menu-active',
            // 'backdrop-blur bg-menu-200/50',
          )}
        >
          {(props.collapsible && (
            <>
              <span
                className={classNames(
                  'text-minimal flex-1 truncate px-1 text-start uppercase',
                )}
              >
                {props.title}
              </span>
              {props.icon}
              {isClosed ? (
                <Icon.ChevronDualOpenVertical
                  className={classNames('h-3 w-3', 'fill-current', 'mx-1')}
                />
              ) : (
                <span
                  className={classNames(
                    'any-hover-none:opacity-100 any-hover:opacity-0 any-hover:group-hover:opacity-100',
                  )}
                >
                  <Icon.ChevronDualCloseVertical
                    className={classNames('h-3 w-3', 'fill-current', 'mx-1')}
                  />
                </span>
              )}
            </>
          )) || (
            <span
              className={classNames(
                'text-minimal text-menu/60 flex-1 truncate px-1 text-start uppercase',
              )}
            >
              {props.title}
            </span>
          )}

          {props.subtitle && (
            <div className={classNames('text-menu/60 flex items-center px-1')}>
              <span className={classNames('flex-1 text-xs')}>
                {props.subtitle}
              </span>
            </div>
          )}
        </h6>
      </Button>

      <Transition
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100 z-10"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <section
          data-component="TitleToolbarContainerBody"
          className={classNames(
            { hidden: props.hidden },
            { 'p-2 pb-2': props.hasPadding },
            'hover:bg-app-panel-dark relative hover:bg-opacity-10',
            props.classNameContainerBodyChildren,
          )}
        >
          {props.children}
        </section>
      </Transition>
    </div>
  );
};

export default TitleToolbarContainer;
