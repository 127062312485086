import { useTranslation } from 'react-i18next';

export function useErrorTranslator() {
  const { t } = useTranslation('errors');

  const errorMap: Record<string, string> = {
    // Cognito-specific errors
    AliasExistsException: t`This alias is already in use. Please choose a different one`,
    CodeDeliveryFailureException: t`We couldn't send the verification code. Please try again`,
    CodeMismatchException: t`The code you entered is incorrect. Please try again`,
    ExpiredCodeException: t`The code has expired. Please retry`,
    InternalErrorException: t`Something went wrong on our end. Please contact support`,
    InvalidEmailRoleAccessPolicyException: t`Account permissions are not configured correctly. Please contact support`,
    InvalidLambdaResponseException: t`Server Error. Please contact support`,
    InvalidParameterException: t`Invalid input data. Please correct it and try again`,
    InvalidPasswordException: t`The password you entered is invalid. Make sure it meets the criteria`,
    InvalidUserPoolConfigurationException: t`Something is wrong. Please contact support`,
    LimitExceededException: t`You've reached the maximum number of allowable requests within a given time period. Please wait at least 15 minutes before attempting to make another request. If the issue persists or if you believe this is an error, please contact our support team for further assistance.`,
    MFAMethodNotFoundException: t`Multi-factor authentication required. Please set it up`,
    NotAuthorizedException: t`Login Error. Check Username or Password and try again`,
    PasswordResetRequiredException: t`You need to reset your password. Please follow the instructions sent to your email`,
    ResourceNotFoundException: t`Service not found. Please contact support`,
    TooManyFailedAttemptsException: t`Too many failed attempts. Please contact support`,
    TooManyRequestsException: t`You've made too many requests. Please wait and try again.`,
    UnexpectedLambdaException: t`Unexpected error occurred. Please try again later`,
    UserLambdaValidationException: t`Verification Error. Please contact support`,
    UserNotConfirmedException: t`Your account is not yet confirmed. Please verify your email`,
    UserNotFoundException: t`Please check your credentials and try again`,
    UsernameExistsException: t`Username already exists. Please choose a different one`,

    // General AWS API errors
    AccessDeniedException: t`You don't have permission to access this service. Please contact support`,
    AccountProblemException: t`There's a problem with your account. Please contact support`,
    IncompleteSignatureException: t`Incomplete request. Please try again`,
    InvalidActionException: t`The action you are trying to perform is invalid`,
    InvalidClientTokenIdException: t`Invalid ID. Please log in again`,
    InvalidQueryParameterException: t`Invalid parameter in your query. Please correct it`,
    MalformedQueryStringException: t`The query string in your request is malformed`,
    MissingActionException: t`Your request is missing the required action`,
    MissingParameterException: t`Your request is missing a required parameter`,
    OptInRequiredException: t`You must opt-in to perform this action`,
    RequestExpiredException: t`Your request has expired. Please try again`,
    ServiceUnavailableException: t`The service is currently unavailable. Please try again later`,
    ThrottlingException: t`Request limits reached. Please try again later`,
    ValidationErrorException: t`The request is not valid. Please correct it and try again`,
    // Add other error types as needed
    EnableSoftwareTokenMFAException: t`The verification code must be at least 6 characters long`, // PASSWORD MISMATCH
  };

  const translateError = (error: any): string => {
    const errorType = error.name || error.__type;
    const i18nKey =
      errorMap[errorType] ||
      t`An unexpected error occurred. Please contact support`;
    return i18nKey;
  };

  return { translateError };
}
