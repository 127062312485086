import { Container } from '../../components/layout/ContainerFlex';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { warehouseDataDashPath } from '../../store/warehouse.state';
import { dataDashboardMenuItems } from '../store/dashboard.default';
import {
  DataDashboardMenuItemId,
  dataDashboardMenuToPath,
} from '../store/dashboard.types';

const SidebarDataDashboardMenu: React.FC<{
  isMinimized: boolean;
  selectedId: DataDashboardMenuItemId;
}> = ({ isMinimized, selectedId }) => {
  const { t } = useTranslation('app');
  const basePath = useRecoilValue(warehouseDataDashPath);
  const navigate = useNavigate();

  function onChange(menuId: DataDashboardMenuItemId) {
    navigate(basePath + (dataDashboardMenuToPath[menuId] ?? '')); //TODO: Use proper path combine
  }

  return (
    <Container data-component="SidebarDataDashboardMenu" col hasOverflowY>
      <nav className={classNames('flex flex-col flex-1', 'p-1')}>
        <ul
          data-component="SidebarDataDashboardMenu"
          className="relative text-start"
        >
          {dataDashboardMenuItems(t).map(item => {
            return (
              <SidebarMenuItem
                isMinimised={isMinimized}
                active={item.id === selectedId}
                key={item.id}
                title={item.title}
                titleMinimised={item.titleMinimized}
                icon={item.icon}
                hasMenuItems={false}
                menuLevel={`${item.menuLevel}`}
                disabled={item.disabled}
                onClick={() => onChange(item.id)}
                isHidden={item?.isHidden}
              />
            );
          })}
        </ul>
      </nav>
    </Container>
  );
};

export default ({ selectedId }: { selectedId: DataDashboardMenuItemId }) => {
  const { t } = useTranslation('app');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-menu-dashboard-data',
    title: t('Data Menu'),
    size: 'xs',
  };

  return (
    <SidebarContainer
      {...containerProps}
      // widthAutoMinimised
      noHeaderTitle
      noHeaderTitleMinimised
      withMenuIcons={
        <SidebarDataDashboardMenu isMinimized={true} selectedId={selectedId} />
      }
    >
      <SidebarDataDashboardMenu isMinimized={false} selectedId={selectedId} />
    </SidebarContainer>
  );
};
