import React from 'react';
import { useRecoilValue } from 'recoil';
import { simulationRoutingTabKey } from '../store/simulation.wizard.state';
import SimulationRoutingPolicyLocationLayer from './SimulationRoutingPolicyLocationLayer';
import SimulationWaypointPolicyLocationLayer from './SimulationWaypointPolicyLocationLayer';

const SimulationRoutingStepLocationLayer: React.FC = () => {
  const tab = useRecoilValue(simulationRoutingTabKey);
  switch (tab) {
    case 'tab-routing-policy':
      return <SimulationRoutingPolicyLocationLayer />;
    case 'tab-waypoint-policy':
      return <SimulationWaypointPolicyLocationLayer />;
    default:
      return null;
  }
};

export default SimulationRoutingStepLocationLayer;
