import * as React from 'react';
const CircleOkIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleOkIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,14c-27.57,0-50,22.43-50,50s22.43,50,50,50,50-22.43,50-50S91.57,14,64,14ZM91.87,47.33l-30.77,34.62c-.76.85-1.81,1.29-2.88,1.29-.84,0-1.69-.28-2.4-.84l-19.23-15.38c-1.66-1.33-1.93-3.75-.6-5.4,1.33-1.66,3.75-1.93,5.41-.6l16.38,13.1,28.34-31.89c1.41-1.59,3.84-1.73,5.43-.32,1.59,1.41,1.73,3.84.32,5.43Z"
    />
  </svg>
);
export default CircleOkIcon;
