import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ErrorIndicator from '../components/ErrorIndicator';
import { HelperMessage } from '../components/HelperMessage';
import { InboxZero } from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import { Spacer } from '../components/layout/Spacer';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { ItemListCard } from '../dashboard/containers/ItemListCard';
import { TrialHelperMessage } from '../pricingPlans/TrialHelperMessage';
import { HelperTemplate } from '../simulation/helper/HelperTemplate';
import { warehouseIsDemo, warehouseSelectedId } from '../store/warehouse.state';
import AppBillingPurchaseStatus from './AppBillingPurchaseStatus';
import AppBillingStepper from './AppBillingStepper';
import AppBillingSubscriptionStatus from './AppBillingSubscriptionStatus';
import AppBillingWarehouseStatus from './AppBillingWarehouseStatus';
import { appBillingWarehouseState } from './store/appBilling.state';

export type AppBillingPaymentStatusProps = {
  hasStepper?: boolean;
};

export const AppBillingPaymentStatus: React.FC<
  AppBillingPaymentStatusProps
> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const location = useLocation();
  const whState = useRecoilValue(appBillingWarehouseState);
  const hasAllowedOperation = !_.isEmpty(whState?.allowedOperations);
  const hasSubscription = !_.isEmpty(whState?.currentSubscriptions);
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const isDemo = useRecoilValue(warehouseIsDemo);

  const params = new URLSearchParams(location.search);

  const paymentId = params.get('payment_intent');
  if (_.isNil(paymentId)) {
    <ErrorIndicator message={t`Payment ID is not provided`} />;
  }

  const purchaseId = params.get('purchaseId');
  const subscriptionId = params.get('subscriptionId');

  return (
    <Container col overflow>
      {isDemo && (
        <ItemListCard
          titleTrace={`Billing [Demo]: Payment Status`}
          isHero
          isHelper
          fullWidth={isDemo}
          className="flex-none"
        >
          <ScreenTitle
            className={classNames('text-menu-text')}
            title={t`Get Started`}
          />
          <div className={classNames('p-8')}>
            <TrialHelperMessage
              classNameMessage={classNames('text-menu-text')}
            />
          </div>
        </ItemListCard>
      )}

      {!isDemo && (
        <>
          {props.hasStepper && <AppBillingStepper active={'payment-status'} />}

          <div className={classNames('px-4 py-2', 'flex-1')}>
            <div className={classNames('flex flex-col flex-1', 'h-full')}>
              <div
                className={classNames(
                  'flex-1 space-x-0 sm:space-x-0 md:space-x-2 lg:space-x-4 p-4 sm:flex-col md:flex lg:flex md:flex-row',
                )}
              >
                <ItemListCard
                  titleTrace={`Billing: Payment Status`}
                  label={`WarehousePaymentStatus`}
                >
                  <ScreenTitle
                    // subtitle={
                    //   (purchaseId && 'Purchase ID: ' + purchaseId) ||
                    //   (subscriptionId && 'Subscription ID: ' + subscriptionId)
                    // }
                    title={t`Payment Status`}
                  />

                  {hasAllowedOperation ? (
                    <HelperTemplate hasPadding>
                      <HelperMessage
                        className={classNames(
                          'mt-2 mb-8 p-3',
                          'rounded',
                          'border border-menu-active',
                        )}
                        isSuggestion
                      >
                        <div className={classNames('p-4', 'text-2xl')}>
                          <div
                            className={classNames('mt-2')}
                          >{t`Thank you for your payment!`}</div>
                          <div className="">
                            {t`You can now start simulating your warehouse.`}
                          </div>

                          <Button
                            className={classNames('mt-8 py-4', 'text-base')}
                            label={t`Get Started with a Simulation`}
                            buttonType="primary"
                            buttonSize="sm"
                            full
                            onPress={() => {
                              navigate(`/wh/i/${warehouseId}`);
                            }}
                            hasIconAfter
                            buttonIcon={<IconArrowDir />}
                          />
                        </div>
                      </HelperMessage>
                      <AppBillingWarehouseStatus />
                    </HelperTemplate>
                  ) : (
                    <>
                      <HelperTemplate
                        // title={t`Analyse Summary`}
                        // subtitle={t`Not Ready for Analysis`}
                        hasPadding
                      >
                        <HelperMessage
                          className={classNames(
                            'mt-2 mb-8 p-3 rounded bg-menu-active',
                          )}
                          isSuggestion
                          isError
                          isHero
                        >
                          <div className={classNames('p-4 text-2xl')}>
                            <div
                              className={classNames('mt-2 font-bold')}
                            >{t`We couldn't find any active plans!`}</div>

                            <div className={classNames('mt-4')}>
                              {t`You need to purchase a subscription or pay-as-go plan to be able to simulate this warehouse.`}
                            </div>

                            <Button
                              className={classNames(
                                'mt-8 py-4 text-base rounded',
                              )}
                              label={t`Go to billing`}
                              buttonType="secondary"
                              buttonSize="sm"
                              full
                              onPress={() => {
                                navigate(`/wh/i/${warehouseId}/pricing`);
                              }}
                              hasIconAfter
                              buttonIcon={
                                <Icon.ArrowRight
                                  className={classNames('w-6 h-6 fill-current')}
                                />
                              }
                            />
                          </div>
                        </HelperMessage>
                      </HelperTemplate>

                      <AppBillingWarehouseStatus />
                    </>
                  )}

                  <Spacer flexspace />
                </ItemListCard>

                <ItemListCard
                  titleTrace={`Billing: Purchase Invoice`}
                  label={`WarehouseInvoice`}
                >
                  <ScreenTitle title={t`Purchase Invoice`} />
                  {_.isEmpty(purchaseId) && _.isEmpty(subscriptionId) && (
                    <InboxZero
                      selfCenter
                      message={t`No Invoices found, please contact support.`}
                    />
                  )}

                  {!_.isEmpty(purchaseId) && (
                    <AppBillingPurchaseStatus purchaseId={purchaseId} />
                  )}

                  {!_.isEmpty(subscriptionId) && (
                    <AppBillingSubscriptionStatus
                      subscriptionId={subscriptionId}
                    />
                  )}
                </ItemListCard>
              </div>
            </div>
          </div>
        </>
      )}

      {/* </div> */}
    </Container>
  );
};

export default AppBillingPaymentStatus;
