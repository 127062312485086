import classNames from 'classnames';
import _ from 'lodash';
import { HTMLAttributes, useState } from 'react';

export type InboxZeroProps = {
  children?: React.ReactNode;
  className?: string;
  classNameInner?: string;
  message?: string;
  message_helper?: string;
  selfCenter?: boolean;
  hasIcon?: boolean;
  icon?: React.FC<HTMLAttributes<Element>>;
  center?: boolean | true;
  isOnTop?: boolean;
  hasNoPadding?: boolean;
};

// export default function LoadingIndicator({ children, message }: LoaderIndicatorProps) {
export const InboxZero = ({
  children,
  className,
  classNameInner,
  message,
  message_helper,
  selfCenter,
  hasIcon,
  icon,
  center = true,
  isOnTop,
  hasNoPadding,
}: InboxZeroProps) => {
  const [id] = useState<string>(_.uniqueId());
  const IconMenu = icon;

  return (
    <div
      data-component="InboxZero"
      id={id}
      className={classNames(
        'relative flex items-center text-center',
        hasNoPadding ? '' : 'p-3 xl:p-4',
        isOnTop ? 'z-1000' : 'z-0',
        { 'h-full flex-1': selfCenter },
        className,
      )}
    >
      <div
        data-component="InboxZeroContainer"
        className={classNames(
          'flex flex-1',
          'rounded',
          'p-2 lg:p-3 xl:p-4',
          'bg-app-panel/30',
          'backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
          center ? 'items-center' : 'items-start lg:items-center',
          children
            ? 'flex-col'
            : IconMenu
              ? 'flex-col lg:flex-row'
              : message_helper && message
                ? 'flex-col'
                : '', //lg:flex-row
          classNameInner,
        )}
      >
        {IconMenu && (
          <IconMenu
            className={classNames(
              'h-5 w-5 lg:h-6 lg:w-6',
              'fill-current',
              'text-menu-active',
              'mb-0 mr-0 lg:mb-2 lg:mt-0 xl:ltr:mr-2 xl:rtl:ml-2',
            )}
          />
        )}

        {message && (
          <div
            data-component="message"
            className={classNames(
              'w-full flex-1',
              'text-menu-text',
              'text-sm xl:text-base',
              {
                'border-app-panel-dark/20 border-t pt-4 lg:mt-0 lg:border-t-0 lg:pt-0 lg:ltr:border-l rtl:border-r':
                  IconMenu,
              },
              !center
                ? 'text-center ltr:ml-2 ltr:pl-4 ltr:lg:ml-0  lg:ltr:pl-0 rtl:mr-2 rtl:pr-4 rtl:lg:mr-0 lg:rtl:pr-0'
                : 'text-center',
            )}
          >
            {message}
          </div>
        )}

        {message_helper && (
          <div
            data-component="messageHelper"
            className={classNames(
              'w-full flex-1',
              'whitespace-pre-line',
              'text-menu-text/70',
              'text-sm xl:text-base',
            )}
          >
            {message_helper}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default InboxZero;
