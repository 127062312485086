import {
  Field,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';

export enum ReassignJobCycleMode {
  EXCHANGE = 'EXCHANGE',
  STASH = 'STASH',
}

registerEnumType(ReassignJobCycleMode, {
  name: 'ReassignJobCycleMode',
});

@InputAndObjectType({
  description: 'Controls reassign cost calculation during optimize',
})
export class ReassignJobsSettings {
  @Field({
    nullable: true,
    description: 'Do not include reassign cost into account when optimizing',
  })
  disabled?: boolean;

  @Field(() => ReassignJobCycleMode, {
    nullable: true,
    defaultValue: ReassignJobCycleMode.EXCHANGE,
  })
  cycleMode?: ReassignJobCycleMode;
}
