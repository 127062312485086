import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import RoutingPanel from '../panels/routing/RoutingPanel';
import ActionBarNextStep from './ActionBarNextStep';
import {Container} from '../../components/layout/ContainerFlex';

const RoutingPolicySidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <Container col hasOverflowY hasExtraPadding>
      <RoutingPanel />
      <ActionBarNextStep />
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-routing-policy',
    title: t`Routing Policy`,
    loadingMessage: t`Loading Routing Policy`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <RoutingPolicySidebar />
    </SidebarContainer>
  );
};
