import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { formatInteger } from '../common/formatHelper';
import { InputNumber } from '../components/inputs/InputNumber';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import { PolicyStatContainer } from '../components/policies/PolicyStatContainer';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { Stat } from '../components/stats/Stat';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import {
  locationSharingPolicyRule,
  locationSharingPolicySelectedIdentity,
  locationSharingPolicySelectedRuleId,
} from '../simulation/store/locationSharingPolicy/locationSharingPolicy.state';

export type LocationSharingPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isCheckLoading: boolean;
  hasLocationFilters: boolean;
  locationCount?: [total: number, exclusive: number, empty?: number];

  canDelete?: boolean;
  onDeleteClick?: () => void;

  canMoveUp?: boolean;
  onMoveUp?: () => void;
  canMoveDown?: boolean;
  onMoveDown?: () => void;

  canRename?: boolean;
};

const LocationSharingPolicyRule: React.FC<
  LocationSharingPolicyRuleProps
> = props => {
  const { t } = useTranslation('simulation');

  const [rule, updateRule] = useRecoilState(
    locationSharingPolicyRule(props.ruleId),
  );
  const setSelectedRuleId = useSetRecoilState(
    locationSharingPolicySelectedRuleId,
  );
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    locationSharingPolicySelectedIdentity,
  );

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  // const isCheckLoading = !checkResult;
  // checkStatus === AsyncLoadStatus.None ||
  // checkStatus === AsyncLoadStatus.Loading;

  const removeField = (groupId: string, fieldId: string) => {
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: _.map(rule.locationsMatch?.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            id: groupId,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };
  function addLocationIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: [...rule.locationsMatch.anyOf, { id, allOf: [] }],
      },
    });
  }

  const removeIntersection = (groupId: string) => {
    updateRule({
      ...rule,
      locationsMatch: {
        anyOf: _.filter(rule.locationsMatch?.anyOf, fg => fg.id !== groupId),
      },
    });
  };

  function selectLocationFilterIntersection(
    filterIntersectionId: string,
    shouldSelect: boolean,
  ) {
    if (!shouldSelect) {
      // deselect intersection only
      setSelectedIdentity(null);
    } else {
      // select intersection and rule details
      setSelectedIdentity({
        ruleId: rule.id,
        locationFilterId: filterIntersectionId,
      });

      // setSelectedDetails({
      //   ruleId: rule.id,
      //   filterType: AssignmentPolicyFilterType.Location,
      //   detailsType: RuleDetailsType.Excluded,
      // });
    }

    // if (!policyEditPanelState.isPinned) {
    //   setPolicyEditPanelState({
    //     ...policyEditPanelState,
    //     isCollapsed: !shouldSelect,
    //   });
    // }
  }

  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isDisabled: props.isDisabled,
    isActive: props.isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),

    hasArrowUp: props.canMoveUp,
    onArrowUpClick: () => props.onMoveUp && props.onMoveUp(),

    hasArrowDown: props.canMoveDown,
    onArrowDownClick: () => props.onMoveDown && props.onMoveDown(),
  };

  return (
    <RuleContainer {...containerParams} hasIcon hasOrder hasColorMode={false}>
      <section
        data-component="LocationSharingPolicyRule"
        className={`
          group relative
          flex-none flex flex-col flex-wrap items-center
          w-full p-0.5
          text-sm font-medium text-menu-text
           transition duration-500
          ${props.className || ''}`}
      >
        <RuleSectionContainer>
          <PolicyStatContainer>
            <InputNumber
              title={t`Max Items`}
              // icon={<Icon.ProjectAddTwin className="w-8 h-8 fill-current" />}
              value={rule?.maxItemsPerLocation ?? 1}
              range={[1, null]}
              onChange={v =>
                updateRule({
                  ...rule,
                  maxItemsPerLocation: v,
                })
              }
            />
          </PolicyStatContainer>
        </RuleSectionContainer>

        <RuleSectionContainer isSelected={props.isActive}>
          <PolicyStatContainer>
            <Stat
              title={t`Filtered Locations`}
              // value={`${props.locationCount[1]}`}
              value={formatInteger(props.locationCount[1])}
              inPanelMode
              isActionable
              inFilterStat
              isCheckLoading={props.isCheckLoading}
              hasTooltip={t`Global Locations: ` + props.locationCount[0]}
              isSelected={props.isActive}
              onClick={() => {
                setSelectedRuleId(props.isActive ? null : rule.id);
              }}
            />
          </PolicyStatContainer>
          {props.hasLocationFilters && (
            <PolicyFilterGroupList
              isDisabled={props.isDisabled}
              filterSets={_.map(rule.locationsMatch?.anyOf, filterGroup => ({
                id: filterGroup.id,
                allOf: [...filterGroup.allOf],
              }))}
              config={getLocationFilterConfigCommon(t)}
              selectedId={selectedIdentity?.locationFilterId}
              onAddGroup={addLocationIntersection}
              onDeleteField={removeField}
              onSelectGroup={selectLocationFilterIntersection}
              onDeleteGroup={removeIntersection}
            />
          )}
        </RuleSectionContainer>
      </section>
    </RuleContainer>
  );
};
export default LocationSharingPolicyRule;
