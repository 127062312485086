import * as React from 'react';
const CursorSelectIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectIcon"
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 93.99 93.99">
        <path
          strokeWidth={0}
          d="M.51,4.38C-.93.81.81-.93,4.38.51l87.02,35.14c3.57,1.44,3.44,3.4-.29,4.36l-33.91,8.68c-3.73.96-7.56,4.79-8.52,8.52l-8.68,33.91c-.96,3.73-2.92,3.86-4.36.29L.51,4.38Z"
        />
      </symbol>
    </defs>
    <use
      xlinkHref="#a"
      width={93.99}
      height={93.99}
      transform="translate(20 20) scale(1.06)"
    />
  </svg>
);
export default CursorSelectIcon;
