import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import UploadWarning from '../UploadWarning';

const ImportAssignmentPolicyUploadPanel: React.FC = () => {
  const { t } = useTranslation('importer');
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Uploading`}
        subtitle={t`Assignment import`}
        hasStepCounter={'4.'}
      />
      <HelperMessage hasMargin hasPadding>
        <h3 className="mt-8 mb-2 text-xl text-menu-active">{t`Uploading your data`}</h3>
        <div>
          {t`Please wait, while we validate and upload your data, once uploaded, you will be redirected to view the data and its stats.`}
        </div>
        <div>{t`You can then proceed in using the data in the simulation`}</div>
      </HelperMessage>
      <UploadWarning />
    </PanelBody>
  );
};

export default ImportAssignmentPolicyUploadPanel;
