import React from 'react';
import { useRecoilValue } from 'recoil';
import LocationDetailedHtmlTooltip from '../../../../../layout/tooltip/LocationDetailedHtmlTooltip';
import TooltipWrapper from '../../../../../layout/tooltip/TooltipWrapper';
import { converterBayViewerHoveredLocation } from '../store/converterBayViewer.state';

const ConverterLocationHtmlTooltipLayer: React.FC = props => {
  const location = useRecoilValue(converterBayViewerHoveredLocation);
  if (!location) return null;
  return (
    <TooltipWrapper stageId={'converter-bay-view'}>
      <LocationDetailedHtmlTooltip location={location} />
    </TooltipWrapper>
  );
};

export default ConverterLocationHtmlTooltipLayer;
