import * as React from 'react';
const FiletypeAnyIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FiletypeAnyIcon"
    {...props}
  >
    <rect width={97.49} height={45} x={15.25} y={64} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M16.19,4c-.52,0-.94.42-.94.94v59.06h97.49v-30h-29.06c-.52,0-.94-.42-.94-.94V4H16.19ZM86.5,4v26.25h26.25l-26.25-26.25ZM15.25,109v11c0,2.21,1.79,4,4,4h89.49c2.21,0,4-1.79,4-4v-11H15.25Z"
    />
  </svg>
);
export default FiletypeAnyIcon;
