import { IsInt, IsNotEmpty, IsOptional } from '@warego/class-validator';
import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';

@InputType()
export class AssignmentItemStockInput {}

@InputType()
export class CreateAssignmentItemInput {
  @Field()
  @IsNotEmpty()
  consignee: string;

  @Field()
  @IsNotEmpty()
  sku: string;

  @Field()
  @IsNotEmpty()
  locationId: string;

  @Field(() => ID, { nullable: true })
  stockUom?: string;

  @Field({ nullable: true })
  @IsOptional()
  @IsInt()
  stockUomQuantity?: number;
}
