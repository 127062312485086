import React from 'react';
import { useTranslation } from 'react-i18next';
import { InboxZero } from '../components/InboxZero';

const SimulationLayoutPlaceholder: React.FC = () => {
  const { t } = useTranslation('simulation');
  return (
    <InboxZero
      selfCenter
      hasIcon
      message={t`No Dataset selected!`}
      message_helper={t`Please select Layout to get started`}
    />
  );
};

export default SimulationLayoutPlaceholder;
