import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import DatasetTable from '../../../components/DatasetTable';
import { getImportConfiguration } from '../../store/import.default';
import {
  importMappingSettingsByType,
  importParsedFile,
  importValidationErrors,
  importValidationErrorsGrouped,
} from '../../store/import.state';
import {
  ImportStepContainerProps,
  MappingFieldSettings,
} from '../../store/import.types';
import useApplyMappingSettings from '../../useApplyMappingSettings';
import useMappingTableConfig from './useMappingTableConfig';

const ImporterFieldMapping: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const previewData = useRecoilValue(importParsedFile);
  const mappingSettings = useRecoilValue(
    importMappingSettingsByType(props.type),
  );
  const validationErrors = useRecoilValue(importValidationErrors);
  const validationErrorsGrouped = useRecoilValue(importValidationErrorsGrouped);
  const applyMappingChange = useApplyMappingSettings();

  const columnConfig = useMappingTableConfig({
    type: props.type,
    withErrors: !_.isEmpty(validationErrors),
  });
  const [previewRowIndex, setPreviewRowIndex] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const previewRowDisplayIndex = previewRowIndex + 1;

  function resetMappingSettings() {
    const defaultSettings = getImportConfiguration(
      props.type,
    ).mappingSettingsDefault;

    console.log('defaultSettings', defaultSettings);
    applyMappingChange({
      mappingSettings: defaultSettings,
    });
  }
  const errorsAllByfield = _.groupBy(validationErrors, e => e.property);
  const errorsUniqByfield = _.groupBy(validationErrorsGrouped, e => e.property);

  const data: MappingFieldSettings<Object>[] = _.map(
    mappingSettings.fields,
    (f, index) => {
      const row = previewData?.data?.[previewRowIndex];

      return {
        ...f,
        rowNumber: index + 1,
        example: f.columnName ? row?.[f.columnName] : null,
        totalErrorCount: _.size(errorsAllByfield[f.name]),
        uniqErrorCount: _.size(errorsUniqByfield[f.name]),
      };
    },
  );

  const totalCount = mappingSettings.fields.length;

  return (
    <>
      {/* <div className="flex ltr:ml-2 rtl:mr-2 sm:ml-0 w-full space-x-0.5 mt-1">
        <Button
          className="flex-1 text-center items-center"
          hasIcon
          buttonType="primary"
          buttonSize={'sm'}
          onPress={e => setPreviewRowIndex(0)}
          isDisabled={previewRowIndex === 0}
        >
          <Icon.ChevronTripleLeft className={`w-6 h-6 fill-current flex-1`} />
        </Button>
        <Button
          className="flex-1 text-center items-center"
          hasIcon
          buttonType="primary"
          buttonSize={'sm'}
          onPress={e => setPreviewRowIndex(previewRowIndex - 1)}
          isDisabled={previewRowIndex === 0}
        >
          <Icon.ChevronLeft className={`w-6 h-6 fill-current flex-1`} />
        </Button>
        <Button
          className="flex-1 text-center items-center"
          hasIcon
          buttonType="primary"
          buttonSize={'sm'}
          onPress={e => setPreviewRowIndex(previewRowIndex + 1)}
          isDisabled={previewRowIndex === preview.length - 1}
        >
          <Icon.ChevronRight className={`w-6 h-6 fill-current flex-1`} />
        </Button>
        <Button
          className="flex-1 text-center items-center"
          hasIcon
          buttonType="primary"
          buttonSize={'sm'}
          onPress={e => setPreviewRowIndex(preview.length - 1)}
          isDisabled={previewRowIndex === preview.length - 1}
        >
          <Icon.ChevronTripleRight className={`w-6 h-6 fill-current flex-1`} />
        </Button>
      </div> */}

      <DatasetTable
        id={'mapping-table'}
        data={data}
        keyFields={['name']}
        totalCount={totalCount}
        columnsConfig={columnConfig}
        onLoadNext={_.noop}
        onSearch={v => setSearchValues(v)}
        searchValues={searchValues}
        hideScreenTitle
      ></DatasetTable>
    </>
  );
};
export default ImporterFieldMapping;
