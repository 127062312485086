import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { HelperMessage } from '../../../../components/HelperMessage';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import { Spacer } from '../../../../components/layout/Spacer';
import TitleSection from '../../../../components/layout/TitleSection';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { converterGuidelineMode } from '../store/converter.state';
import { GuidelineMode, getGuidelineModes } from '../store/converter.types';

const StepConnectAreas = () => {
  const { t } = useTranslation('designer');
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  const [guidelineMode, setGuidelineMode] = useRecoilState(
    converterGuidelineMode,
  );

  const modes = getGuidelineModes(t);

  return (
    <PanelBody className="flex flex-col flex-1">
      <ScreenTitle
        isSticky
        title={t`Connect Areas`}
        subtitle={t`Navigation`}
        hasStepCounter={'4.'}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerAreasConnect}
          question={t`How you navigate between the areas?`}
        />
        <InputAnswerGroup>
          <HelpContainer
            id={'designer/04-navigation-connect-areas'}
            hasPadding
          />

          <HelperMessage hasBefore iconAction={Icon.ArrowRight}>
            {t`Drag the areas to connect to each other through an aisle, you will see guide indicating the areas are connected.`}
          </HelperMessage>
        </InputAnswerGroup>
      </InputQA>
      <Spacer flexspace />
      <span
        className="text-menu-text/60 hover:underline text-sm cursor-pointer p-4"
        onClick={() => {
          setShowAdvanced(!showAdvanced);
        }}
      >
        {showAdvanced
          ? t`Hide guide lines settings`
          : t`Need help with the alignment?`}
      </span>
      {showAdvanced && (
        <div className="p-4 pb-20">
          <TitleSection title={t(`Guide Lines settings`)} inPanelView />
          <DropdownSelector
            panelMode
            widthFull
            value={guidelineMode}
            values={_.keys(modes) as GuidelineMode[]}
            renderValue={i => modes[i]}
            label={t`Guide line mode`}
            icon={<Icon.CursorTransformBox className="w-8 h-8 fill-current" />}
            onChange={v => setGuidelineMode(v)}
          />
        </div>
      )}
    </PanelBody>
  );
};

export default StepConnectAreas;
