import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type SidebarProps = {
  children?: React.ReactNode;
  relative?: boolean;
  absolute?: boolean;
  alignRight?: boolean;
  alignLeft?: boolean;
  className?: string;
};

export const Sidebar = ({
  children,
  className,
  relative,
  alignRight,
  alignLeft,
  absolute,
}: SidebarProps) => {
  const [id] = useState<string>(_.uniqueId());

  // relative = true;

  return (
    <aside
      id={id}
      data-component="Sidebar"
      className={classNames(
        'flex flex-col',
        { ' relative ': relative },
        { 'absolute top-0 ltr:right-0 rtl:left:0': absolute },
        className,
      )}
    >
      {children}
    </aside>
  );
};
