import {
  FindLocationsByFilterDocument,
  FindLocationsByFilterQuery,
  FindLocationsByFilterQueryVariables,
  LayoutLocationFilter,
  OptimizationSwapRuleInput,
  SwapPolicyFragment,
  SwapPolicyRuleFragment,
  SwapSettingsInput,
  UnassignedLocationsMatch,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { HTMLAttributes } from 'react';
import { secureClient } from '../GraphQLClient';
import { cloneWithoutTypename } from '../common/utils';
import * as Icon from '../components/icons';
import { getPolicyMatchInput } from '../policyFilters/policyFilter.helper';
import { SwapPolicySelectedGroup } from './swapPolicy.type';

export function getSwapPolicyRuleInput(
  rule: SwapPolicyRuleFragment,
): OptimizationSwapRuleInput {
  const clonedRule: OptimizationSwapRuleInput = cloneWithoutTypename(rule);
  const sourceItemsMatch = getPolicyMatchInput(rule?.src?.itemsMatch);
  const sourceLocsMatch = getPolicyMatchInput(rule?.src?.locationsMatch);

  const destItemsMatch = getPolicyMatchInput(rule?.dest?.itemsMatch);
  const destLocsMatch = getPolicyMatchInput(rule?.dest?.locationsMatch);

  const r: OptimizationSwapRuleInput = {
    ...clonedRule,
    src: {
      ...clonedRule.src,
      itemsMatch: _.isEmpty(sourceItemsMatch?.anyOf) ? null : sourceItemsMatch,
      locationsMatch: _.isEmpty(sourceLocsMatch?.anyOf)
        ? null
        : sourceLocsMatch,
    },
    dest: {
      ...clonedRule.dest,
      itemsMatch: _.isEmpty(destItemsMatch?.anyOf) ? null : destItemsMatch,
      locationsMatch: _.isEmpty(destLocsMatch?.anyOf) ? null : destLocsMatch,
    },
  };
  return r;
}

export function getSwapPolicyInput(
  policy: SwapPolicyFragment,
): SwapSettingsInput {
  return {
    rules: _.map(policy?.rules, getSwapPolicyRuleInput),
  };
}

export async function loadLocationsByRule(params: {
  layoutId: string;
  rule: SwapPolicyRuleFragment;
  group: SwapPolicySelectedGroup;
  filter: LayoutLocationFilter;
}): Promise<Set<string>> {
  const ruleInput = getSwapPolicyRuleInput(params.rule);
  const includeMatching =
    params.group === 'source'
      ? ruleInput.src?.locationsMatch
      : ruleInput.dest?.locationsMatch;

  const response = await secureClient.query<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  >({
    query: FindLocationsByFilterDocument,
    variables: {
      page: {
        limit: null,
      },
      filter: params.filter,
      layoutId: params.layoutId,
      input: {
        includeMatching,
      },
    },
  });

  const ids = _.map(
    response.data?.layout?.locationsByFilter.content,
    l => l.locationId,
  );
  return new Set(ids);
}

export function getSwapModeOptions(t: TFunction<'simulation'>): {
  id: UnassignedLocationsMatch;
  title: string;
  icon?: React.FC<HTMLAttributes<Element>>;
}[] {
  return [
    {
      id: UnassignedLocationsMatch.EXCLUDE,
      title: t(`Occupied locations`, { ns: 'simulation' }),
      icon: Icon.LocationsOccupied,
    },
    {
      id: UnassignedLocationsMatch.UNASSIGNED_ONLY,
      title: t(`Empty Locations`, { ns: 'simulation' }),
      icon: Icon.LocationsEmpty,
    },
    {
      id: UnassignedLocationsMatch.INCLUDE,
      title: t(`Occupied and Empty (All)`, {
        ns: 'simulation',
      }),
      icon: Icon.LocationsOccupiedAll,
    },
  ];
}
