import * as React from 'react';
const PolicyLocationSharingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyLocationSharingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.06-.16.16-.31.28-.43L60.82,11.99c.88-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <polygon
      strokeWidth={0}
      points="47.62 42.9 32.93 51.39 47.62 59.87 62.31 51.39 47.62 42.9"
    />
    <polygon
      strokeWidth={0}
      points="30.22 73.11 44.96 81.62 44.96 64.6 30.22 56.08 30.22 73.11"
    />
    <polygon
      strokeWidth={0}
      points="50.33 81.58 65.02 73.09 65.02 56.13 50.33 64.61 50.33 81.58"
    />
    <path
      strokeWidth={0}
      d="M84.53,45.62l-15.91,9.19v18.38l15.91,9.19,15.92-9.19v-18.38l-15.91-9.19ZM91.44,67.39h-3.6v3.87c0,1.83-1.49,3.31-3.31,3.31s-3.31-1.49-3.31-3.31v-3.87h-3.6c-1.83,0-3.31-1.49-3.31-3.31s1.49-3.31,3.31-3.31h3.6v-3.32c0-1.83,1.49-3.31,3.31-3.31s3.31,1.49,3.31,3.31v3.32h3.6c1.83,0,3.32,1.49,3.32,3.31s-1.49,3.31-3.32,3.31Z"
    />
  </svg>
);
export default PolicyLocationSharingIcon;
