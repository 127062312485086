import { OptimizationRunStatus } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import LayoutAlertsBase from '../../layout/alerts/LayoutAlertsBase';
import { LoadingAlertMessageType } from '../../layout/alerts/LayoutLoadingAlert';
import { LoadingErrorAlertMessageType } from '../../layout/alerts/LayoutLoadingErrorAlert';
import {
  optimisationAnalyzeOrderDetailsLoadStatus,
  optimisationAnalyzeResult,
  optimisationStatus,
  optimisationWaypointHeatmap,
} from '../store/optimisation.state';
import { simulationHeatmapLocationByLevel } from '../store/simulation.heatmap.state';

export const OptimisationLayoutLoadingAlerts: React.FC = () => {
  const optimiseStatus = useRecoilValue(optimisationStatus);
  const analyzeResult = useRecoilValue(optimisationAnalyzeResult);
  const heatmapLoadable = useRecoilValueLoadable(
    simulationHeatmapLocationByLevel(analyzeResult?.id),
  );
  const congestionLoadable = useRecoilValueLoadable(
    optimisationWaypointHeatmap,
  );
  const orderLoadStatus = useRecoilValue(
    optimisationAnalyzeOrderDetailsLoadStatus,
  );

  const messages: LoadingAlertMessageType[] = [];
  const errors: LoadingErrorAlertMessageType[] = [];

  // #3 - optimise result
  const isOptimiseLoading =
    optimiseStatus === OptimizationRunStatus.COMPLETED && !analyzeResult;
  if (isOptimiseLoading) {
    messages.push(LoadingAlertMessageType.optimise);
  }
  // #4 - location heatmap
  if (heatmapLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.heatmap);
  }
  if (heatmapLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.heatmap);
  }

  // #5 - waypoint heatmap
  if (congestionLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.congestion);
  }

  // #6 - order route
  if (orderLoadStatus === AsyncLoadStatus.Loading) {
    messages.push(LoadingAlertMessageType.route);
  }

  return <LayoutAlertsBase messages={messages} errors={errors} />;
};
