import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ActionBarNextStep from './ActionBarNextStep';
import PickingPolicy from './PickingPolicy';

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-picking',
    title: t`Picking Policy`,
    loadingMessage: t`Loading Picking Policy`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <Container col hasOverflowY>
        <Suspense
          fallback={<LoadingIndicator selfCenter message={t`Loading...`} />}
        >
          <PickingPolicy />
        </Suspense>
      </Container>

      <ActionBarNextStep />
    </SidebarContainer>
  );
};
