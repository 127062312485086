import { useApolloClient } from '@apollo/client';
import {
    AnalyzeOrderStatus,
    LoadAnalyzeOrdersDocument,
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { ANALYZE_ORDERS_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
    getOrderFilterParam,
    getOrderSortParam,
    KPI
} from '../store/simulation.helper';
import {
    simulationAnalyzeOrdersListData,
    simulationAnalyzeOrdersListState,
    simulationAnalyzeOrdersListStatus
} from '../store/simulation.state';
import { OrderSearchValue } from '../store/simulation.types';

export type LoadAnalyzedOrdersListParams = {
  analyzeId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: OrderSearchValue;
  sortBy?: KPI;
  status: AnalyzeOrderStatus;
};

function useLoadAnalyzedOrdersList() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Orders data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(simulationAnalyzeOrdersListStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAnalyzedOrdersListParams) => {
        const current = await snapshot.getPromise(
          simulationAnalyzeOrdersListData,
        );
        const currentState = await snapshot.getPromise(
          simulationAnalyzeOrdersListState,
        );

        const query = client.watchQuery<
          LoadAnalyzeOrdersQuery,
          LoadAnalyzeOrdersQueryVariables
        >({
          query: LoadAnalyzeOrdersDocument,
          variables: {
            analyzeId: params.analyzeId,
            filter: {
              ...getOrderFilterParam(params.filter),
              status: params.status ? [params.status] : null,
            },
            sortBy: getOrderSortParam(params.sortBy),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? ANALYZE_ORDERS_PAGE_SIZE,
            },
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationAnalyzeOrdersListStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let items = data.analyzeResult.orders.content;

            if (params.isAppend) {
              items = [...current, ...items];
            }

            set(simulationAnalyzeOrdersListData, items);
            set(simulationAnalyzeOrdersListStatus, AsyncLoadStatus.Ok);
            set(simulationAnalyzeOrdersListState, {
              ...currentState,
              totalCount: data.analyzeResult.orders.totalCount,
            });
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAnalyzedOrdersListParams) {
    await initLoading();
    await loadCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAnalyzedOrdersList;
