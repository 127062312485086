import {
  AllocationSettings,
  AssignmentCapacityPolicy,
  AssignmentPolicy,
  LocationSharingPolicy,
  OptimizationSettings,
  PickingPolicy,
  ResourcePolicy,
  RoutingPolicy,
  StackingPolicy,
  WaypointPolicy,
} from '@warebee/shared/engine-model';
import { Field, ID, InputType } from '@warebee/shared/util-backend-only-types';
import { CreateBaseWarehouseEntityInput } from '../create-base-warehouse-entity.input';

@InputType()
export class CreateSimulationInput extends CreateBaseWarehouseEntityInput {
  @Field(() => ID, { nullable: true })
  layoutId?: string;

  @Field(() => ID, { nullable: true })
  itemSetId?: string;

  @Field(() => ID, { nullable: true })
  assignmentId?: string;

  @Field(() => ID, { nullable: true })
  orderSetId?: string;

  @Field(() => AssignmentPolicy, { nullable: true })
  assignmentPolicy?: AssignmentPolicy;

  @Field(() => ResourcePolicy, { nullable: true })
  resourcePolicy?: ResourcePolicy;

  @Field(() => PickingPolicy, {
    nullable: true,
    defaultValue: {},
  })
  pickingPolicy?: PickingPolicy;

  @Field(() => RoutingPolicy, { nullable: true })
  routingPolicy?: RoutingPolicy;

  @Field(() => StackingPolicy, { nullable: true })
  stackingPolicy?: StackingPolicy;

  @Field(() => WaypointPolicy, { nullable: true })
  waypointPolicy?: WaypointPolicy;

  @Field(() => AssignmentCapacityPolicy, { nullable: true })
  assignmentCapacityPolicy?: AssignmentCapacityPolicy;

  @Field(() => LocationSharingPolicy, {
    nullable: true,
    defaultValue: {
      defaultMaxItemsPerLocation: 1,
    },
  })
  locationSharingPolicy?: LocationSharingPolicy;

  @Field(() => OptimizationSettings, { nullable: true })
  optimizationSettings?: OptimizationSettings;

  @Field(() => AllocationSettings, { nullable: true })
  allocationSettings?: AllocationSettings;
}
