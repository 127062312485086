import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import * as Icon from '../../../components/icons';
import ErrorContainer from '../../../containers/ErrorContainer';
import { collapsibleState } from '../../../store/collapsible.state';
import { importUnmappedFields } from '../../store/import.state';
import { ImportStepContainerProps } from '../../store/import.types';

const ImporterMappingErrors: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const unmappedFields = useRecoilValue(importUnmappedFields(props.type));
  const errorContainerState = useRecoilValue(
    collapsibleState('error-container-importer-unmapped'),
  );
  const unmappedFieldCount = _.size(unmappedFields);
  const required = _.filter(unmappedFields, f => !f.optional);
  const unmappedRequiredCount = _.size(required);
  const optional = _.filter(unmappedFields, f => f.optional);
  return (
    <ErrorContainer
      status={unmappedRequiredCount ? 'ERROR' : 'WARNING'}
      id="error-container-importer-unmapped"
      icon={Icon.TriangleInfo}
      title={t(`We've found {{unmappedFieldCount}} unmapped field(s)`, {
        unmappedFieldCount,
        unmappedRequiredCount,
      })}
      collapsible
      collapsibleLabel={
        errorContainerState.isCollapsed ? 'Show More' : 'Show Less'
      }
      hasPadding
    >
      <ul className="divide-y divide-menu-white/50 list-decimal list-outside ltr:pl-10 rtl:pr-10">
        {_.map(required, field => (
          <li key={field.title} className="py-2">
            <span className="px-2 py-1 ltr:mr-2 rtl:ml-2 align-middle border rounded text-minimal border-menu-white/50 text-white">
              {t`Required`}
            </span>
            <span> {field.title}</span>
          </li>
        ))}

        {/* {_.map(optional, field => (
          <li key={field.title} className="py-2">
            <span className="px-2 py-1 ltr:mr-2 rtl:ml-2 align-middle border rounded text-minimal border-menu-white/50 text-white">
              {t`Optional`}
            </span>
            <span> {field.title}</span>
          </li>
        ))} */}
      </ul>
    </ErrorContainer>
  );
};

export default ImporterMappingErrors;
