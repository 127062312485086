import { AnalyzeResultStage } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { optimisationResult } from '../store/optimisation.state';
import { simulationAnalyzeResult } from '../store/simulation.state';

const locationColors = TwTheme.extend.colors.location;

const AnimatedLocationLayer: React.FC = () => {
  const analyse = useRecoilValue(simulationAnalyzeResult);
  const optimise = useRecoilValue(optimisationResult);

  if (_.isNil(analyse) && _.isNil(optimise)) return null;
  const progress = optimise?.progress ?? analyse?.progress ?? 1;

  const color: [string, string] =
    analyse?.stage === AnalyzeResultStage.ANALYZING
      ? [locationColors.analyzing, null]
      : [locationColors.optimizing, null];

  function getColor() {
    const hasColor = Math.random() < progress / 100;
    return hasColor ? color : null;
  }
  return <LocationLayer getColor={getColor} />;
};
export default AnimatedLocationLayer;
