import {
  confirmSignIn,
  fetchAuthSession,
  fetchMFAPreference,
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup,
} from 'aws-amplify/auth';
import _ from 'lodash';
import { AuthState, LoggedInUser, MfaMethod, UserToken } from './auth.types';

export function convertUserToken(token: UserToken): LoggedInUser {
  return {
    email: token.email,
    id: token.sub,
    fullName: [token.given_name, token.family_name].join(' '),
    firstName: token.given_name,
    lastName: token.family_name,
  };
}

export async function getAuthState(): Promise<AuthState> {
  try {
    const session = await fetchAuthSession();
    if (_.isNil(session?.tokens?.accessToken)) {
      return { status: 'unauthorized' };
    }
    const tokenPayload = session.tokens?.idToken?.payload as UserToken;

    return {
      status: 'authorized',
      user: convertUserToken(tokenPayload),
    };
  } catch (err) {
    return { status: 'unauthorized' };
  }
}

export async function getPreferredMFAType(): Promise<MfaMethod> {
  try {
    const mfaSettings = await fetchMFAPreference();
    return mfaSettings.preferred;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getTOTPCode(): Promise<string> {
  const output = await setUpTOTP();
  const secretCode = output.sharedSecret;
  return secretCode;
}

export async function authDisableMFA() {
  try {
    await updateMFAPreference({
      totp: 'DISABLED',
    });
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function confirmTOTPSetup(code: string): Promise<void> {
  await verifyTOTPSetup({ code });
  await updateMFAPreference({
    totp: 'PREFERRED',
  });
}

export async function confirmMFA(OTPCode: string): Promise<boolean> {
  const { isSignedIn, nextStep } = await confirmSignIn({
    challengeResponse: OTPCode,
  });
  return isSignedIn;
}

// async function getAuthStateWithRetry(retryCount: number) {
//   try {
//     const fetchResult = await fetch(
//       `${BACKEND_URL}/refresh-token?${nanoid()}`,
//       {
//         headers: { pragma: 'no-cache', 'cache-control': 'no-cache' },
//         credentials: 'include',
//       },
//     );
//     if (fetchResult.status === 504 && retryCount > 0) {
//       console.info(
//         `Refresh-token request failed. Retry left ${retryCount}. Result: `,
//         fetchResult,
//       );
//       return await getAuthStateWithRetry(retryCount - 1);
//     }
//     if (fetchResult.status === 401) {
//       return { status: 'unauthorized' };
//     }
//     if (fetchResult.ok) {
//       const response = await fetchResult.json();
//       return {
//         status: 'authorized',
//         user: response.user,
//         initialAccessToken: response.accessToken,
//       };
//     }
//   } catch (ex) {
//     console.info(
//       `Refresh-token request failed. Retry left ${retryCount}. Exception: `,
//       ex,
//     );

//     if (ex.status === 504 && retryCount > 0) {
//       return await getAuthStateWithRetry(retryCount - 1);
//     }
//   }
//   return { status: 'error' };
// }
