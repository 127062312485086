import * as React from 'react';
const CursorSelectTransformRoundIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectTransformRoundIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M56.47,60.17c-1-2.49.21-3.7,2.7-2.7l60.62,24.48c2.49,1,2.4,2.37-.2,3.04l-23.62,6.05c-2.6.67-5.27,3.34-5.93,5.93l-6.05,23.62c-.67,2.6-2.03,2.69-3.04.2l-24.48-60.62Z"
    />
    <path
      strokeWidth={0}
      d="M119,20c0-6.08-4.92-11-11-11-5.51,0-10.05,4.05-10.86,9.33H31.86c-.81-5.28-5.35-9.33-10.86-9.33-6.08,0-11,4.92-11,11,0,5.15,3.55,9.47,8.33,10.66v65.68c-4.78,1.19-8.33,5.51-8.33,10.66,0,6.08,4.92,11,11,11,5.15,0,9.47-3.55,10.66-8.33h39.11v-3.38H31.96c-.35-5.5-4.75-9.89-10.25-10.25V30.96c5.16-.33,9.35-4.22,10.14-9.25h65.29c.74,4.7,4.44,8.4,9.14,9.14v39.91h3.38V30.86c5.28-.81,9.33-5.35,9.33-10.86Z"
    />
  </svg>
);
export default CursorSelectTransformRoundIcon;
