import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cloneWithoutTypename } from '../../common/utils';
import ResourcePolicyAgentView from '../../resourcePolicy/agent/ResourcePolicyAgentView';
import {
  actualityResourcePolicy,
  actualityResourcePolicySelectedAgent,
  actualitySelectedId,
} from '../store/actuality.state';
import { feedDistinctUoms } from '../store/feed.state';

const FeedPolicyAgentViewContainer: React.FC = () => {
  const actualityId = useRecoilValue(actualitySelectedId);
  const policy = useRecoilValue(actualityResourcePolicy);
  const uoms = useRecoilValue(feedDistinctUoms);
  const [agent, setAgent] = useRecoilState(
    actualityResourcePolicySelectedAgent,
  );
  const [callUpdateActuality] = useUpdateActualityMutation();
  const firstMount = useRef(true);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
    } else {
      callUpdateActuality({
        variables: {
          input: {
            resourcePolicy: cloneWithoutTypename(policy),
            actualityId,
          },
        },
      });
    }
  }, [policy]);

  const isEditable = true;
  return (
    <ResourcePolicyAgentView
      agent={agent}
      setAgent={setAgent}
      editMode={isEditable ? 'full' : 'disabled'}
      uoms={_.map(uoms, i => i.uom)}
    />
  );
};

export default FeedPolicyAgentViewContainer;
