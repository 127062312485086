import * as React from 'react';
const DataItemsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DataItemsIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="6.14 95.58 6.14 95.58 2.12 93.02 2.11 86.79 34.32 106.91 34.32 113.48 30.3 110.92 30.3 106.88 22.49 102.17 19.19 104.04 15.16 101.48 15.16 96.95 10.24 93.84 6.14 95.58"
    />
    <path
      strokeWidth={0}
      d="M34.32,106.91v6.57s32.21-20.45,32.21-20.45v-6.24s-32.21,20.12-32.21,20.12Z"
    />
    <polygon
      strokeWidth={0}
      points="33.45 103.02 6.9 86.24 6.9 22.98 33.45 38.86 33.45 103.02"
    />
    <polygon
      strokeWidth={0}
      points="61.75 57.56 61.75 22.98 35.44 38.86 35.44 104.02 61.75 87.51 61.75 57.56"
    />
    <polygon
      strokeWidth={0}
      points="49.31 28.56 61.75 21.2 34.32 5.27 22.47 12.16 49.31 28.56"
    />
    <polygon
      strokeWidth={0}
      points="21.25 12.87 6.9 21.2 34.32 37.43 48.1 29.28 21.25 12.87"
    />
    <polygon
      strokeWidth={0}
      points="95.27 112.66 121.82 96.52 121.82 62.52 95.27 78.41 95.27 112.66"
    />
    <polygon
      strokeWidth={0}
      points="93.28 112.66 66.97 96.52 66.97 62.52 93.28 78.41 93.28 112.66"
    />
    <polygon
      strokeWidth={0}
      points="79.41 68.1 66.97 60.74 94.4 44.82 106.25 51.7 79.41 68.1"
    />
    <polygon
      strokeWidth={0}
      points="107.47 52.41 121.82 60.74 94.4 76.98 80.62 68.82 107.47 52.41"
    />
    <polygon
      strokeWidth={0}
      points="66.15 105.27 66.15 105.27 62.12 102.72 62.12 96.48 94.33 116.6 94.33 123.17 90.3 120.61 90.3 116.57 82.5 111.86 79.2 113.73 75.17 111.17 75.17 106.64 70.25 103.53 66.15 105.27"
    />
    <path
      strokeWidth={0}
      d="M94.33,116.6v6.57s32.21-20.45,32.21-20.45v-6.24s-32.21,20.12-32.21,20.12ZM125,101.87l-29.13,18.5v-2.92s29.14-18.2,29.14-18.2v2.62Z"
    />
  </svg>
);
export default DataItemsIcon;
