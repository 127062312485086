import * as React from 'react';
const ImportTransformIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ImportTransformIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M16.19,4c-.52,0-.94.42-.94.94v42.06h97.49v-13h-29.06c-.52,0-.94-.42-.94-.94V4H16.19ZM86.5,4v26.25h26.25l-26.25-26.25Z"
    />
    <path
      strokeWidth={0}
      d="M15.23,50.03v9.97c0,4.95,4.03,8.98,8.98,8.98h4.98v6.98s23.97-9.97,23.97-9.97l-23.97-9.97v6.98h-4.98c-1.65,0-2.99-1.34-2.99-2.99v-9.97"
    />
    <path
      strokeWidth={0}
      d="M43.67,80.15l-23.97,9.97,23.97,10.97v-7.98h58.93c4.95,0,8.98-4.03,8.98-8.98v-12.96c0-4.95-4.03-8.98-8.98-8.98h-3.97s0,5.99,0,5.99h3.97c1.65,0,2.99,1.34,2.99,2.99v12.96c0,1.65-1.34,2.99-2.99,2.99h-58.93v-6.98Z"
    />
    <path
      strokeWidth={0}
      d="M112.59,102.94H15.58s0,17.97,0,17.97h97s0-17.97,0-17.97Z"
    />
    <path
      strokeWidth={0}
      d="M95.64,71.38v-11.98c0-1.65-1.35-2.99-2.99-2.99h-31.97c-1.65,0-2.99,1.35-2.99,2.99v11.98c0,1.65,1.35,2.99,2.99,2.99h31.97c1.65,0,2.99-1.35,2.99-2.99Z"
    />
  </svg>
);
export default ImportTransformIcon;
