import * as React from 'react';
const DashboardHomeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DashboardHomeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,29.96L2.86,41.77v57.22h122.29v-57.22l-60.64-11.81ZM39.95,88.66h-16.67v-35.17h16.67v35.17ZM73.28,88.66h-29.17v-10h29.17v10ZM73.28,74.5h-29.17v-20.9h29.17v20.9ZM106.61,88.66h-29.17v-10h29.17v10ZM106.61,74.5h-29.17v-20.9h29.17v20.9Z"
    />
  </svg>
);
export default DashboardHomeIcon;
