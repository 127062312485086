import { StackingPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import { getStackingPolicyInput } from '../simulation/store/stackingPolicy/stackingPolicy.helper';
import { stackingPolicyDocument } from '../simulation/store/stackingPolicy/stackingPolicy.state';
import StackingPolicyFallbackRule from './StackingPolicyFallbackRule';
import StackingPolicyRulesGroup from './StackingPolicyRulesGroup';

const StackingPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(stackingPolicyDocument);
  // const canUpdate = useRecoilValue(simulationIsEditable);

  const [lastSavedPolicy, setLastSavedPolicy] =
    useState<StackingPolicyFragment>();
  const updateSim = useUpdateSimulation();
  // const formatter = useFormatter();

  useEffect(() => {
    // skip if policy not changed
    if (policy === lastSavedPolicy) return;

    // skip re-save on first render
    if (!_.isNil(lastSavedPolicy)) {
      updateSim({ stackingPolicy: getStackingPolicyInput(policy) });
    }

    // update local state
    setLastSavedPolicy(policy);
  }, [policy]);

  return (
    <Container col hasOverflowY hasExtraPadding>
      <ScreenTitle
        title={t`Stacking Policy`}
        subtitle={t`Stacking Policy`}
        isSticky
        helpNavTo={'simulation/policies/policy-stacking/policy-stacking'}
        icon={Icon.PolicyStacking}
      />
      <StackingPolicyRulesGroup groupType="top" />
      <StackingPolicyFallbackRule />
      <StackingPolicyRulesGroup groupType="bottom" />
    </Container>
  );
};

export default StackingPolicy;
