import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListItem } from '../../components/stats/StatListItem';
import { ActualityAnalyzedEventsSummaryRow } from '../store/datasetQueries/feedAnalyzedEvents';
import { feedDisabledAgentStatsFields } from '../store/feed.state';
import { getFeedAgentsStatsDesc } from '../store/feedStats.default';

export type FeedAnalyzedKPIStatsProps = {
  value: ActualityAnalyzedEventsSummaryRow<'agentId'>[];
  isSelected: boolean;
};

const FeedAnalyzedKPIStats: React.FC<FeedAnalyzedKPIStatsProps> = props => {
  const { t } = useTranslation('feed');
  const formatter = useFormatter();

  const statsDescriptors = getFeedAgentsStatsDesc(t, formatter);
  const disabledStats = useRecoilValue(feedDisabledAgentStatsFields);

  const disabledStatsSet = new Set(disabledStats);
  const statItems = _.filter(
    statsDescriptors,
    item => item.category === 'analyze' && !disabledStatsSet.has(item.id),
  );

  const byProcess = _.groupBy(props.value, stat => stat.processType);
  return (
    <>
      {_.map(byProcess, (stats, processType) => {
        return (
          <StatGroup
            key={processType}
            classNameStatGroupItems={classNames(
              'divide-y divide-solid divide-menu/75',
            )}
            title={processType}
            hasNoPadding
            classNameTitleContainer="!bg-sidebar-header/40"
            classNameTitle="!text-sm p-1 !text-menu-text"
          >
            {statItems.map(item => {
              const value = _.head(stats)?.[item.id];
              return (
                <StatListItem
                  key={item.id}
                  isSelected={props.isSelected}
                  // className="opacity-75"
                  title={item.title}
                  value={_.isNumber(value) ? item.getFormatted(value) : value}
                  unitOfMeasure={item.unitOfMeasure ?? ''}
                  inStatView
                />
              );
            })}
          </StatGroup>
        );
      })}
    </>
  );
};

export default FeedAnalyzedKPIStats;
