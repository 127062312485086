import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { getBox, getBoxWH } from '../layout/viewer/store/viewer.helper';
import { viewerAreaAisles } from '../layout/viewer/store/viewer.state';
import CCTVFeature from './CCTVFeature';
import { cctvConfig } from './cctv.defaults';
import { cctvShow } from './cctv.state';

const CCTVLayer: React.FC = props => {
  const areaAislesLoadable = useRecoilValueLoadable(viewerAreaAisles);
  const showCCTV = useRecoilValue(cctvShow);
  if (areaAislesLoadable.state !== 'hasValue' || !showCCTV) return null;

  const areaAisles = areaAislesLoadable.contents;

  if (_.isEmpty(areaAisles)) return null;

  return (
    <Layer>
      {_.values(areaAisles).map(aisle => {
        const hasCCTV = _.some(cctvConfig, c => c.aisleTitle === aisle.title);
        if (!hasCCTV) return null;
        const box = getBox(aisle.shape);
        const [boxWidth, boxHeight] = getBoxWH(box);
        return (
          <CCTVFeature
            key={`cctv-${aisle.id}`}
            x={box.xmin}
            y={box.ymin}
            maxIconWidth={Math.min(boxWidth, boxHeight)}
          />
        );
      })}
    </Layer>
  );
};

export default CCTVLayer;
