import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ItemSetEditorPanel from '../panels/ItemSetEditorPanel';
import { itemSetSelectedRule } from '../store/itemSet.state';

const ItemSetEditorSidebar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const rule = useRecoilValue(itemSetSelectedRule);

  if (!rule) {
    return (
      <InboxZero
        selfCenter
        message={t`Select Rule to edit`}
        // message_helper={t`TODO`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={t`Settings for`}
        title={`${rule?.title}`}
        isSticky
      />
      <ItemSetEditorPanel />
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('dataset');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-item-set-editors',
    title: t`Items Settings`,
    loadingMessage: t`Loading item policies`,
    size: 'sm',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <ItemSetEditorSidebar />
    </SidebarContainer>
  );
};
