import {
  AssignmentDiffItemsFilter,
  AssignmentDiffItemsSortBy,
  LoadOptimizationDiffDocument,
  LoadOptimizationDiffQuery,
  LoadOptimizationDiffQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  optimisationAssignmentDiff,
  optimisationAssignmentDiffLoadStatus,
} from '../store/optimisation.state';

export type LoadAssignmentDiffParams = {
  optimisationId: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: AssignmentDiffItemsFilter;
  sortBy?: AssignmentDiffItemsSortBy[];
};

function useLoadAssignmentDiff() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load optimised assignment`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(optimisationAssignmentDiffLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentDiffParams) => {
        const current = await snapshot.getPromise(optimisationAssignmentDiff);

        if (_.isNil(params.optimisationId)) {
          console.log(
            'Invalid parameter value: optimisationId:',
            params.optimisationId,
          );
          return null;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(optimisationAssignmentDiffLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadOptimizationDiffQuery,
          LoadOptimizationDiffQueryVariables
        >({
          query: LoadOptimizationDiffDocument,
          variables: {
            optimisationRunId: params.optimisationId,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip || 0,
            },
            filter: params.filter,
            sortBy: params.sortBy,
          },
        });

        const queryObservable = query.subscribe(
          result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const assignmentItems = data?.optimizationRun.assignmentDiff.items;
            let content = assignmentItems?.content ?? [];

            if (params.isAppend) {
              content = [...current.content, ...content];
            }

            set(optimisationAssignmentDiff, {
              totalCount: assignmentItems?.totalCount ?? 0,
              content,
            });
            set(optimisationAssignmentDiffLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentDiffParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAssignmentDiff;
