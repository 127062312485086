import * as React from 'react';
const PolicyComplianceAssignmentIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceAssignmentIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM109.37,70.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z"
    />
    <path
      strokeWidth={0}
      d="M84.62,62.2c-1.29-1.46-1.21-3.71.24-4.99l3.04-2.69s-17.4,0-26.09,0c-.4,0-.71.34-.71.74s.31.71.71.71c4.44,0,8.88,0,13.31,0,1.97,0,3.57,1.6,3.57,3.57,0,1.98-1.63,3.57-3.6,3.57-4.42,0-8.88.06-13.28,0-.4,0-.71.31-.71.71s.31.71.71.71c3,0,6,0,9,0,1.98,0,3.6,1.6,3.6,3.57s-1.63,3.6-3.6,3.6c-4.23,0-8.48.06-12.69,0-.4,0-.71.31-.71.71s.31.71.71.71h7.62c1.97,0,3.57,1.6,3.57,3.57s-1.59,3.6-4.63,3.6c-7.3,0-21.9,0-21.9,0-9.31,0-18.6-9.29-18.6-20.75,0-11.46,9.26-20.72,20.72-20.72,0,0,15.25,0,22.88,0,3.05,0,3.6,0,3.6,1.98,0,3.46-3.16,5.14-5.14,5.14-3.25,0-6.49,0-9.74,0-.4,0-.71.31-.71.71s.31.74.71.74c10.47,0,31.41,0,31.41,0l-3.04-2.69c-1.45-1.28-1.52-3.53-.24-4.99,1.31-1.48,3.57-1.6,5.05-.29l13.11,11.54-13.11,11.54c-1.48,1.3-3.74,1.19-5.05-.29Z"
    />
  </svg>
);
export default PolicyComplianceAssignmentIcon;
