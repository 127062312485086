import * as React from 'react';
const FitToBoxIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FitToBoxIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M94,36.5c0-1.38-1.12-2.5-2.5-2.5h-55c-1.38,0-2.5,1.12-2.5,2.5v55c0,1.38,1.12,2.5,2.5,2.5h55c1.38,0,2.5-1.12,2.5-2.5v-55Z"
    />
    <path
      strokeWidth={0}
      d="M24.43,27.97l-4.95-.71c-1.37-.19-2.63.75-2.83,2.12-.2,1.37.75,2.63,2.12,2.83l12.37,1.77c.12.02.24.02.35.02.32,0,.65-.06.95-.19.3-.12.58-.31.82-.54.27-.27.45-.58.57-.92.04-.1.04-.2.06-.3.06-.25.09-.49.07-.74,0-.05.02-.1.01-.16l-1.77-12.37c-.2-1.37-1.46-2.33-2.83-2.12-1.37.2-2.32,1.46-2.12,2.83l.71,4.95-9.7-9.7c-.98-.98-2.56-.98-3.53,0-.98.98-.98,2.56,0,3.53l9.7,9.7Z"
    />
    <path
      strokeWidth={0}
      d="M33.9,95.95c-.02-.1-.02-.21-.06-.3-.12-.33-.3-.65-.57-.92-.22-.22-.49-.38-.77-.51-.04-.02-.07-.04-.1-.05-.26-.1-.54-.16-.83-.16-.05,0-.1,0-.15,0-.09,0-.18,0-.27,0l-12.37,1.77c-1.37.2-2.32,1.46-2.12,2.83.2,1.37,1.45,2.32,2.83,2.12l4.95-.71-9.7,9.7c-.98.98-.98,2.56,0,3.53.49.49,1.13.73,1.77.73s1.28-.24,1.77-.73l9.7-9.7-.71,4.95c-.2,1.37.75,2.63,2.12,2.83.12.02.24.02.36.02,1.23,0,2.29-.9,2.47-2.15l1.77-12.37c0-.05-.02-.1-.01-.16.02-.25-.01-.5-.07-.74Z"
    />
    <path
      strokeWidth={0}
      d="M94.1,32.05c.02.1.02.21.06.3.12.33.3.65.57.92.24.24.52.42.82.54.3.12.63.19.95.19.12,0,.23,0,.35-.02l12.37-1.77c1.37-.2,2.32-1.46,2.12-2.83-.2-1.37-1.46-2.32-2.83-2.12l-4.95.71,9.7-9.7c.98-.98.98-2.56,0-3.53-.98-.98-2.56-.98-3.53,0l-9.7,9.7.71-4.95c.2-1.37-.75-2.63-2.12-2.83-1.37-.2-2.63.75-2.83,2.12l-1.77,12.37c0,.05.02.1.01.16-.02.25.01.5.07.74Z"
    />
    <path
      strokeWidth={0}
      d="M103.57,100.03l4.95.71c1.38.21,2.63-.75,2.83-2.12.2-1.37-.75-2.63-2.12-2.83l-12.37-1.77c-.09-.01-.18,0-.26,0-.05,0-.1,0-.16,0-.29,0-.57.06-.83.16-.04.01-.07.04-.11.05-.28.12-.54.29-.76.51-.27.27-.45.58-.57.92-.04.1-.04.2-.06.3-.06.25-.09.49-.07.74,0,.05-.02.1-.01.16l1.77,12.37c.18,1.25,1.25,2.15,2.47,2.15.12,0,.24,0,.36-.02,1.37-.2,2.32-1.46,2.12-2.83l-.71-4.95,9.7,9.7c.49.49,1.13.73,1.77.73s1.28-.24,1.77-.73c.98-.98.98-2.56,0-3.53l-9.7-9.7Z"
    />
  </svg>
);
export default FitToBoxIcon;
