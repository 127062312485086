import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import DatasetFieldsSummary from '../../components/DatasetFieldsSummary';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { datasetObjectDocument } from '../store/datasetObject.state';

const DatasetObjectStatsPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [document, setDocument] = useRecoilState(datasetObjectDocument);

  return (
    <PanelBody className="h-full">
      <Container col hasOverflowY>
        <ScreenTitle
          isSticky
          title={t`Properties`}
          subtitle={t`Dataset object`}
        />

        {document.summary?.fields && (
          <TitleSection
            id={`${document.id}-schema-field-stats`}
            title={t`Datatable stats`}
            collapsible
            inPanelView
            // hasScroll
          >
            <DatasetFieldsSummary fields={document.summary?.fields} />
          </TitleSection>
        )}
        {document.summary?.rawDataFields && (
          <TitleSection
            id={`${document.id}-raw-field-stats`}
            title={t`Raw data stats`}
            collapsible
            inPanelView
            // hasScroll
          >
            <DatasetFieldsSummary fields={document.summary?.rawDataFields} />
          </TitleSection>
        )}
      </Container>
    </PanelBody>
  );
};

export default DatasetObjectStatsPanel;
