import * as React from 'react';
const LocationPalletIcon = props => (
  <svg
    aria-hidden="true"
    data-name="LocationPalletIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m124,107.23v-4.32H4v4.32h6.17v14.23h-6.17v4.32h120v-4.32h-6.17v-14.23h6.17Zm-104.37,0h39.64v14.23H19.63v-14.23Zm49.1,14.23v-14.23h39.64v14.23h-39.64Z" />
    <path d="m13.6,8.43v92.15h100.17V8.43H13.6Zm85.57,69.52v8.34h-6.47v-8.34h-4.8l8.04-11.47,8.04,11.47h-4.8Z" />
  </svg>
);
export default LocationPalletIcon;
