import { SimulationRefSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimulationItemCard from '../dashboard/containers/SimulationItemCard';
import { DashboardItemStatusType } from '../dashboard/store/dashboard.types';
import { warehouseSelectedId } from '../store/warehouse.state';
import InboxZero from './InboxZero';
import { PanelBody } from './designer/panels/PanelBody';
import { ScreenTitle } from './layout/ScreenTitle';

export type SimulationsListPanelProps = {
  subtitle: string;
  simulations: SimulationRefSummaryFragment[];
};

const SimulationsListPanel: React.FC<SimulationsListPanelProps> = props => {
  const whId = useRecoilValue(warehouseSelectedId);
  const { t } = useTranslation('dataset');
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`in Simulations`}
        subtitle={props.subtitle}
      />

      {!_.isEmpty(props.simulations) ? (
        <div className="p-4">
          {props.simulations.map((sim, index) => (
            <SimulationItemCard
              key={sim.id}
              status={
                index === 0
                  ? {
                      type: DashboardItemStatusType.progress,
                      title: t`Latest`,
                    }
                  : null
              }
              className="mb-2"
              fullWidth
              sim={sim}
              isActionable
              path={`/wh/i/${whId}/simulations/${sim.id}`}
            />
          ))}
        </div>
      ) : (
        <InboxZero
          className={classNames('mt-2')}
          message={t`No used in a simulation`}
          message_helper={t`To use it, go to Simulations`}
        />
      )}
    </PanelBody>
  );
};

export default SimulationsListPanel;
