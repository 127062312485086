import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { optimisationWaypointHeatmap } from '../../store/optimisation.state';
import SimulationCongestionLayerBase from './SimulationCongestionLayerBase';

const OptimisationCongestionLayer: React.FC = () => {
  const waypointsLoadable = useRecoilValueLoadable(optimisationWaypointHeatmap);

  const waypoints =
    waypointsLoadable.state === 'hasValue' ? waypointsLoadable.contents : null;

  return <SimulationCongestionLayerBase waypoints={waypoints} />;
};

export default OptimisationCongestionLayer;
