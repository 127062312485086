import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { StatGroup } from '../components/stats/StatGroup';
import { loadBalancingPolicyDocument } from '../loadBalancingPolicy/loadBalancingPolicy.state';
import WorkLoadBalancingPanel from '../simulation/panels/WorkLoadBalancingPanel';
import AnalyzeResultLoadBalancingRule from './AnalyzeResultLoadBalancingRule';
import { AnalyzeComponentBaseProps } from './analyze.types';

const AnalyzeResultLoadBalancing: React.FC<
  AnalyzeComponentBaseProps
> = props => {
  const { t } = useTranslation('simulation');
  const policy = useRecoilValue(loadBalancingPolicyDocument);

  if (_.isEmpty(policy?.rules)) {
    return null;
  }

  return (
    <StatGroup
      title={t`Congestion`}
      subTitle={t`Stats`}
      helpNavTo={'simulation/optimise/simulation-optimise-summary-workload'}
    >
      
      <WorkLoadBalancingPanel />

      {_.map(policy?.rules, r => (
        <AnalyzeResultLoadBalancingRule
          key={r.id}
          analyzeId={props.analyzeResult.id}
          rule={r}
          series={props.isOptimise ? 'after' : 'before'}
        />
      ))}
    </StatGroup>
  );
};

export default AnalyzeResultLoadBalancing;
