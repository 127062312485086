import * as React from 'react';
const LinkChain2Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkChain2Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M55.18,72.95c-1.6-1.6-2.9-3.32-3.89-5.13l1.28-1.28c1.54-1.54,3.92-2.14,6.1-1.58.53.79,1.18,1.57,1.99,2.38.15.18.31.35.48.52.75.75,1.71,1.46,2.85,2.11,5.78,3.07,12.97,2,17.53-2.56l26.34-26.34c5.7-5.87,5.69-15.26,0-20.95-5.87-5.7-15.26-5.69-20.95,0l-21.25,21.11c-3.06-.89-6.27-1.29-9.45-1.21l25.3-25.3c4.19-4.25,9.77-6.6,15.73-6.62,6.03-.02,11.72,2.33,16.01,6.62,4.29,4.29,6.65,9.98,6.62,16.01-.02,5.96-2.37,11.54-6.61,15.72l-26.22,26.51c-8.67,8.66-22.44,8.9-31.35.52l-.54-.54Z"
    />
    <path
      strokeWidth={0}
      d="M14.73,113.27c-4.29-4.29-6.65-9.98-6.62-16.01.02-5.96,2.37-11.54,6.61-15.72l26.22-26.51c8.67-8.67,22.44-8.9,31.35-.52l.53.53c1.6,1.6,2.91,3.32,3.9,5.14l-1.28,1.28c-1.54,1.54-3.92,2.14-6.1,1.58-.54-.8-1.18-1.57-1.99-2.38-.15-.18-.31-.35-.48-.52-.75-.75-1.71-1.46-2.85-2.11-5.78-3.07-12.97-2.01-17.54,2.56l-26.34,26.34c-5.7,5.87-5.69,15.26,0,20.94,5.87,5.7,15.26,5.69,20.94,0l21.26-21.26c3.07.89,6.32,1.3,9.55,1.24l-25.41,25.41c-4.19,4.25-9.77,6.6-15.73,6.62-6.03.02-11.72-2.33-16.01-6.62Z"
    />
  </svg>
);
export default LinkChain2Icon;
