import * as React from 'react';
const ObjectVolumeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectVolumeIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="99.5 73.15 51.57 95.77 51.57 44.9 99.29 25.57 99.5 73.15"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="51.57 95.77 3.76 70.44 3.76 22.65 51.57 44.9 51.57 95.77"
      opacity={0.7}
    />
    <polygon
      strokeWidth={0}
      points="51.57 44.9 3.77 22.55 51.57 5.38 99.29 25.57 51.57 44.9"
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M51.57,97.46c-.83,0-1.51.68-1.51,1.51v25.07c0,.83.68,1.51,1.51,1.51s1.51-.68,1.51-1.51v-25.07c0-.83-.68-1.51-1.51-1.51Z"
    />
    <path
      strokeWidth={0}
      d="M3.77,71.74c-.83,0-1.51.68-1.51,1.51v22.69c0,.83.68,1.51,1.51,1.51s1.51-.68,1.51-1.51v-22.69c0-.83-.68-1.51-1.51-1.51Z"
    />
    <path
      strokeWidth={0}
      d="M114.92,68.97c-.25-.53-.79-.87-1.37-.87-.22,0-.44.05-.64.14l-12.33,5.8c-.41.15-.72.48-.87.89,0,0,0,0,0,0,0,.02,0,.03-.01.05-.02.07-.04.13-.05.2,0,.05-.01.11-.02.16,0,.03,0,.06,0,.09l-.12,24.13c0,.83.67,1.52,1.51,1.52h0c.83,0,1.51-.68,1.51-1.51l.12-23.17,11.55-5.43c.37-.17.64-.48.78-.86.14-.38.12-.79-.06-1.16Z"
    />
    <path
      strokeWidth={0}
      d="M102.97,25.57c.22,0,.45-.05.64-.14l12.42-5.84c.75-.36,1.08-1.26.72-2.02-.25-.53-.79-.87-1.37-.87-.22,0-.44.05-.64.14l-12.42,5.84c-.37.17-.64.48-.78.86-.14.38-.12.79.06,1.16.25.53.79.87,1.37.87Z"
    />
    <polygon
      strokeWidth={0}
      points="103.41 54.87 103.41 49.93 109.57 56.09 109.57 31.67 103.41 37.83 103.41 32.89 111.57 24.73 119.72 32.89 119.72 37.83 113.57 31.67 113.57 56.09 119.72 49.93 119.72 54.87 111.57 63.03 103.41 54.87"
    />
    <polygon
      strokeWidth={0}
      points="29.93 120.91 38.34 118.68 11.4 102.43 13.64 110.85 9.36 108.37 6.39 97.22 17.54 94.26 21.82 96.73 13.4 98.97 40.35 115.22 38.1 106.8 42.38 109.28 45.35 120.43 34.2 123.39 29.93 120.91"
    />
    <polygon
      strokeWidth={0}
      points="56.63 118.83 60.3 107.9 64.72 105.69 61.95 113.95 90.75 100.74 82.5 97.97 86.92 95.77 97.86 99.43 94.2 110.37 89.77 112.57 92.54 104.32 63.74 117.53 71.99 120.29 67.57 122.49 56.63 118.83"
    />
  </svg>
);
export default ObjectVolumeIcon;
