import {
  ImportPipelineConnectorSettingsFragment,
  ImportPipelineConnectorType,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';

export const pipelineNewKey = 'NEW_PIPELINE';
export const connectorNewSFTPKey = 'NEW_SFTP_CONNECTOR';
export const connectorNewDatasetKey = 'NEW_DATASET_CONNECTOR';

export function createDefaultSftpConnector(): ImportPipelineConnectorSettingsFragment {
  return {
    id: nanoid(6),
    enabled: true,
    type: ImportPipelineConnectorType.SFTP_PULL,
    sftpPull: {
      host: null,
      port: 4422,
      password: null,
      username: null,
    },
  };
}

export function createDefaultDatasetConnector(): ImportPipelineConnectorSettingsFragment {
  return {
    id: nanoid(6),
    enabled: true,
    type: ImportPipelineConnectorType.DATASET_CHANGED,
    datasetChanged: {
      sources: [],
    },
  };
}
