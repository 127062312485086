import * as React from 'react';
const CursorCenterIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorCenterIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M86.45,83.24h21.71c1.25,0,2.26-1.01,2.26-2.26s-1.01-2.26-2.26-2.26h-27.17c-.29,0-.59.06-.87.17-.55.23-.99.67-1.22,1.22-.11.28-.17.57-.17.87v27.17c0,1.25,1.01,2.26,2.26,2.26s2.26-1.01,2.26-2.26v-21.7l36.89,36.89c.44.44,1.02.66,1.6.66s1.16-.22,1.6-.66c.88-.88.88-2.32,0-3.2l-36.89-36.89Z"
    />
    <path
      strokeWidth={0}
      d="M41.55,44.75h-21.7c-1.25,0-2.26,1.01-2.26,2.26s1.01,2.26,2.26,2.26h27.17c.29,0,.59-.06.87-.17.55-.23.99-.67,1.22-1.22.11-.28.17-.57.17-.87v-27.17c0-1.25-1.01-2.26-2.26-2.26s-2.26,1.01-2.26,2.26v21.7L7.86,4.66c-.88-.88-2.32-.88-3.2,0-.88.88-.88,2.32,0,3.2l36.89,36.89Z"
    />
    <path
      strokeWidth={0}
      d="M47.88,78.89c-.28-.11-.57-.17-.87-.17h-27.17c-1.25,0-2.26,1.01-2.26,2.26s1.01,2.26,2.26,2.26h21.7L4.66,120.14c-.88.88-.88,2.32,0,3.2.44.44,1.02.66,1.6.66s1.16-.22,1.6-.66l36.89-36.89v21.7c0,1.25,1.01,2.26,2.26,2.26s2.26-1.01,2.26-2.26v-27.17c0-.29-.06-.59-.17-.87-.23-.55-.67-.99-1.22-1.22Z"
    />
    <path
      strokeWidth={0}
      d="M80.12,49.11c.28.11.57.17.87.17h27.17c1.25,0,2.26-1.01,2.26-2.26s-1.01-2.26-2.26-2.26h-21.7L123.34,7.86c.88-.88.88-2.32,0-3.2-.88-.88-2.32-.88-3.2,0l-36.89,36.89v-21.7c0-1.25-1.01-2.26-2.26-2.26s-2.26,1.01-2.26,2.26v27.17c0,.29.06.59.17.87.23.55.67.99,1.22,1.22Z"
    />
    <circle cx={64} cy={64} r={16.98} strokeWidth={0} />
  </svg>
);
export default CursorCenterIcon;
