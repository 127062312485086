import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import * as Icon from '../icons';

type UOMBreakdown = {
  uom: string;
  uomQuantity: number;
};

export type JobsDetailsUOMProps = {
  title: string;
  taskCount: number;
  taskType?: 'add' | 'remove';
  breakdownByUom: UOMBreakdown[];
};

const UOMDetails: React.FC<UOMBreakdown> = props => {
  return (
    <span className={`flex items-center`}>
      <span
        className={`text-end text-xs p-1 px-1 ltr:pr-2 rtl:pl-2 ltr:mr-2 rtl:ml-2 rounded bg-app-panel`}
      >
        <span className="mx-1">{formatInteger(props.uomQuantity)}</span>
        <span className="mx-1">
          {props.uom}
          {`(s)`}
        </span>
      </span>
    </span>
  );
};

const JobsDetailsUOM: React.FC<JobsDetailsUOMProps> = props => {
  const { t } = useTranslation('app');
  const type = props.taskType ?? 'add';
  const ActionIcon =
    type === 'add' ? Icon.ArrowRightBottom : Icon.ArrowRightTop;

  return (
    <div
      data-component="JobsDetailsUOM"
      className={classNames('flex items-start mb-4')}
    >
      <ActionIcon
        className={classNames(
          'h-4 w-4',
          'mt-3 ltr:mr-2 rtl:ml-2 mx-0.5',
          'fill-current',
          'text-menu-text/80',
        )}
      />
      <div className={classNames('flex flex-col flex-1')}>
        <div
          className={classNames(
            'flex items-end border-menu-400 border-solid border-b border-opacity-30 mt-2 mb-1 py-1 text-xxs lg:text-xs uppercase text-menu-text/60',
          )}
        >
          <div className="flex-1">{props.title}</div>
          <div
            className={classNames(
              'ltr:pr-2 rtl:pl-2',
              'ltr:mr-1 rtl:ml-1',
              'text-end',
            )}
          >{t`UOM`}</div>
        </div>

        <div className={`flex items-center`}>
          <div className={`flex items-center`}>
            <span
              className={`text-end text-xs p-1 px-2 rounded border border-menu-400`}
            >
              {formatInteger(props.taskCount)}
              <span className="mx-1 text-xs">{`x`}</span>
            </span>
            <span className={`flex-1`}></span>
          </div>
          <div className={`flex items-center flex-col`}>
            {_.map(props.breakdownByUom, uom => (
              <UOMDetails key={uom.uom} {...uom} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsDetailsUOM;
