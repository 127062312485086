import { sql } from 'kysely';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './queryBuilder/feedEventsQueryBuilders';

// -- HEATMAP CONGESTION QUERY --
export type FeedHeatmapCongestion = {
  locationId: string;
  congestion: number;
};

export type FeedHeatmapCongestions = FeedHeatmapCongestion[];

export function getFeedHeatmapCongestionsSelect(
  params: ActivityFeedQueryBuilderParams,
) {
  return datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => getActivityFeedFilteredQueryBuilder(params, db),
    )
    .with(
      cte => cte('locationCongestions'),
      db =>
        db.selectFrom('af as t1').select(({ fn, selectFrom }) => [
          't1.locationId',
          selectFrom('af as t2')
            .select(({ fn }) => [fn.countAll().as('congestion')])
            .whereRef('t1.locationId', '=', 't2.locationId')
            // .whereRef('t1.partition', '=', 't2.partition')
            .whereRef('t1.agentId', '<>', 't2.agentId')
            .where(
              sql<any>`abs(
                  to_unixtime(${sql.ref('t2.eventEndTime')}) 
                  - to_unixtime(${sql.ref('t1.eventEndTime')})) 
                  < 1000*60*5`,
            )
            .as('congestion'),
        ]),
    )
    .selectFrom('locationCongestions')
    .select(({ fn }) => ['locationId', fn.sum('congestion').as('congestion')])
    .groupBy('locationId');
}
