import * as React from 'react';
const ShowTableIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ShowTableIcon"
    {...props}
  >
    <rect
      width={23.19}
      height={6.5}
      x={95.18}
      y={68.53}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={23.19}
      height={6.5}
      x={95.18}
      y={59.41}
      strokeWidth={0}
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="101.78 47.66 118.37 47.66 118.37 34.71 101.78 47.66"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="95.18 52.8 95.18 56.78 118.37 56.78 118.37 50.28 98.42 50.28 95.18 52.8"
      opacity={0.5}
    />
    <rect
      width={23.19}
      height={6.5}
      x={95.18}
      y={86.79}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={23.19}
      height={6.5}
      x={95.18}
      y={77.66}
      strokeWidth={0}
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="69.24 73.05 69.24 75.04 92.43 75.04 92.43 68.53 75.02 68.53 69.24 73.05"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="78.39 65.91 92.43 65.91 92.43 59.41 86.72 59.41 78.39 65.91"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="92.43 56.78 92.43 54.95 90.08 56.78 92.43 56.78"
      opacity={0.5}
    />
    <rect
      width={23.19}
      height={6.5}
      x={69.24}
      y={86.79}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={23.19}
      height={6.5}
      x={69.24}
      y={77.66}
      strokeWidth={0}
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="54.99 84.16 66.48 84.16 66.48 77.66 63.33 77.66 54.99 84.16"
      opacity={0.5}
    />
    <polygon
      strokeWidth={0}
      points="43.3 93.29 66.48 93.29 66.48 86.79 51.63 86.79 43.3 93.29"
      opacity={0.5}
    />
    <polygon
      fillRule="evenodd"
      strokeWidth={0}
      points="120.3 26.94 110.7 26.94 22.92 96.72 32.52 96.72 120.3 26.94"
    />
    <path
      strokeWidth={0}
      d="M46.23,60.91c0-11.62,9.47-21.09,21.09-21.09,7.18,0,13.54,3.62,17.35,9.13l13.97-10.9c-9.03-4.49-19.74-7.96-31.32-7.96-32.21,0-57.73,26.78-58.86,27.92-1.52,1.64-1.52,4.17,0,5.81.76.77,12.58,13.14,30.05,21.15l13.01-10.15c-3.28-3.72-5.28-8.58-5.28-13.91Z"
    />
    <path
      strokeWidth={0}
      d="M67.33,48.66c-6.77,0-12.25,5.49-12.25,12.25,0,3.29,1.3,6.27,3.41,8.47l19.2-14.98c-2.17-3.44-5.99-5.74-10.36-5.74Z"
    />
  </svg>
);
export default ShowTableIcon;
