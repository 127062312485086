import { SpacerTypeOption } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InboxZero from '../../../../components/InboxZero';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableRowHead } from '../../../../components/table/TableRowHead';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import HelpContainer from '../../../../helpContext/HelpContainer';
import AddSpacerFreeObject from '../AddSpacerFreeObject';
import { converterSelectedArea } from '../store/converter.area.state';
import { converterLayoutSelectedAisle } from '../store/converter.layout.state';
import { converterAreaBuilder } from '../store/converter.state';

const StepSpacers: React.FC = () => {
  const { t } = useTranslation('designer');
  const setSelectedAisle = useSetRecoilState(converterLayoutSelectedAisle);
  const area = useRecoilValue(converterSelectedArea);
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );

  const spacersMap = _.keyBy(
    _.flatten(_.values(areaBuilder?.spacers)),
    s => s.id,
  );

  function drawRowSpacer(aisle) {
    return (
      <TableRow
        rowLineList
        rowHover
        key={aisle.id}
        aria-label={aisle.id}
        // onMouseLeave={() => setSelectedAisle(null)}
        // onMouseEnter={() => setSelectedAisle(aisle)}
      >
        <TableTd cellSize="sm">{aisle.title}</TableTd>
      </TableRow>
    );
  }

  return (
    <PanelBody>
      <ScreenTitle
        title={t`Columns, Walls, Obstacles`}
        subtitle={t`Aisles, Locations and Bays`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerAislesSpacers}
          question={t`Do you need additional spacing elements to align your layout?`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/03-setup-obstacles'} hasPadding />

          {/* <HelperContainer
            collapsible
            id={'helper-layout-area-spacers'}
            hideMessage={t`Hide helper`}
            showMessage={t`show more`}
            showIcon={Icon.CircleHelp}
            hideIcon={Icon.MenuDotsHorizontal}
            message={t`You can add additional elements to the warehouse map to represent physical objects like a wall, column or any obstacle that impacts navigation or was not mapped in your data.`}
          >
            <HelperMessage>
              {t`Select a bay on the map to add a new aisle or a "block" element. You can add a unique name too.`}
              <HelperMessage
                isTip
                hasBefore
              >{t`You can add new map elements to the top, bottom or both sides of the bay. Click on "+" icon on the side of the bay on the map.`}</HelperMessage>
            </HelperMessage>
          </HelperContainer> */}
        </InputAnswerGroup>
      </InputQA>

      <TitleSection titleView hasPadding title={t`Aisles`}>
        <AddSpacerFreeObject />
      </TitleSection>

      <TitleSection titleView title={t`Spacers`} />
      {area ? (
        <Table isSticky viewAsLine isHoverable>
          <TableHead>
            <TableRowHead>
              <TableTh panelMode>{t`Dividers`}</TableTh>
            </TableRowHead>
          </TableHead>

          <TableBody>
            {_(area.aisles)
              .filter(
                a =>
                  a.isSpacer &&
                  spacersMap[a.id].type === SpacerTypeOption.Block,
              )
              .map(aisle => drawRowSpacer(aisle))
              .value()}
          </TableBody>
        </Table>
      ) : (
        <InboxZero
          message={t`No Area Selected`}
          message_helper={t`Double Click - Area to edit`}
        />
      )}
    </PanelBody>
  );
};
export default StepSpacers;
