
const WareBeeLogoFullLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2463.43 667.55"
    {...props}
  >
    <path
      d="M690.14,231.15,533.9,140.94c-12.5-7.22-31.35-7.22-43.84,0l-72.57,41.89-72.57-41.89c-12.49-7.22-31.34-7.22-43.84,0l-156.24,90.2c-12.5,7.22-21.93,23.54-21.93,38V449.53c0,14.43,9.43,30.75,21.93,38l156.24,90.21a47.11,47.11,0,0,0,43.84,0l72.57-41.9,72.57,41.9a47.11,47.11,0,0,0,43.84,0L690.14,487.5c12.5-7.22,21.93-23.54,21.93-38V269.11C712.07,254.68,702.64,238.36,690.14,231.15Zm-.3,176.31L522.79,503.91c-5.56,3.21-16.07,3.21-21.62,0L487,495.7,689.84,378.57Zm0-109.11-356,205.56c-5.56,3.21-16.06,3.21-21.62,0l-167-96.45V378.57l155.94,90a47.11,47.11,0,0,0,43.84,0L689.84,269.46ZM301.08,359.5a47.11,47.11,0,0,0,43.84,0l156.25-90.21c5.55-3.21,16.06-3.21,21.62,0l14.21,8.2L333.81,394.8c-5.55,3.21-16.06,3.21-21.62,0l-167-96.45V269.46Zm305.39-67.56L533.9,250c-12.5-7.21-31.35-7.21-43.84,0L333.81,340.25c-5.55,3.21-16.06,3.21-21.62,0L298,332.05,501.17,214.73c5.55-3.2,16.06-3.2,21.62,0l108.7,62.76ZM301.08,414.05a47.11,47.11,0,0,0,43.84,0l214.3-123.73,25,14.45L333.81,449.36c-5.55,3.2-16.06,3.2-21.62,0l-167-96.45V324ZM501.17,160.18c5.55-3.21,16.06-3.21,21.62,0l155.94,90-25,14.44L533.9,195.49c-12.5-7.21-31.35-7.22-43.84,0L275.76,319.22l-25-14.45ZM228.51,291.94l-25-14.45,166.76-96.27,25,14.44Zm83.68-131.76c5.56-3.21,16.07-3.21,21.62,0L348,168.39,181.27,264.66l-25-14.44Zm21.62,398.28c-5.55,3.21-16.06,3.21-21.62,0L156,468.25c-5.56-3.2-10.81-12.3-10.81-18.72V433.12l155.94,90a47,47,0,0,0,43.84,0L689.84,324v28.9ZM679,468.25,522.79,558.46c-5.56,3.21-16.07,3.21-21.62,0L439.71,523l25-14.45,25.33,14.62a47,47,0,0,0,43.84,0l155.94-90v16.41C689.84,456,684.59,465.05,679,468.25Z"
    />
    <path d="M1078.28,208.44,1032.55,487c-2.89,16.89-12.36,21-25.55,21H989.29c-11.54,0-20.19-8.24-23.49-20.6L925.43,334.92c-.83-3.71-1.24-3.71-2.47-3.71h-.83c-1.23,0-1.65,0-2.47,3.71L878.87,487.36c-3.29,12.36-12,20.6-23.48,20.6H838.08c-13.18,0-22.66-4.12-25.54-21L766.81,208.44V207.2a6.7,6.7,0,0,1,7-7h23.9c3.29,0,6.18,2.47,7,7l40,262.45c.82,4.12.41,6.18,2.06,6.18s2.06-2.48,2.88-6.18L890,317.2c2.88-11.12,8.65-16.47,19.36-16.47h26.37c10.71,0,16.48,5.35,19.36,16.47l40.38,152.45c.82,3.7,1.23,6.18,2.88,6.18s1.24-2.06,2.06-6.18l40-262.45c.82-4.53,3.71-7,7-7h23.9a6.7,6.7,0,0,1,7,7Z" />
    <path d="M1254.2,506.31h-19a8.26,8.26,0,0,1-8.24-8.24V489c-19.78,11.54-40.38,20.19-63.45,20.19-35,0-67.16-15.25-67.16-66.75v-4.94c0-44.5,26-66.74,99.29-66.74h30.49V354.28c0-34.6-12-46.55-44.08-46.55a599.09,599.09,0,0,0-63,3.3H1117c-3.71,0-7.41-1.24-7.41-6.6V290c0-4.53,2.88-7,8.65-7.83a355.25,355.25,0,0,1,63.86-5.35c57.27,0,80.34,28.84,80.34,77.45V498.07A8.26,8.26,0,0,1,1254.2,506.31Zm-28-109.59h-30.49c-49.85,0-63,11.13-63,40.38V442c0,29.67,14,37.49,37.9,37.49,19,0,35.85-5.76,55.62-16.48Z" />
    <path d="M1402.23,308.14c-19,0-36.26,4.94-56.86,17.31V498.07a8.55,8.55,0,0,1-8.24,8.24h-20.19a8.18,8.18,0,0,1-7.82-8.24V288a7.92,7.92,0,0,1,7.82-8.24h19.37a7.92,7.92,0,0,1,7.82,8.24v8.66c20.61-14.43,39.56-19.78,58.1-19.78q8.65,0,8.65,7.41v16.9C1410.88,305.67,1408,308.14,1402.23,308.14Z" />
    <path d="M1604.43,404.55h-138v5.77c0,49,19.37,67.15,57.27,67.15,23.9,0,54.39-1.64,73.34-4.53h2.06c3.29,0,6.59,1.65,6.59,5.77v14c0,5.35-1.65,8.24-8.24,9.47a373,373,0,0,1-73.75,7c-47.38,0-93.52-22.25-93.52-98.88V373.24c0-58.92,32.55-96.41,91.46-96.41,60.15,0,90.64,37.9,90.64,96.41v22.25C1612.26,400.84,1609.38,404.55,1604.43,404.55Zm-28.84-31.31c0-46.56-17.71-64.69-54-64.69-35.43,0-55.21,20.19-55.21,64.69V377h109.18Z" />
    <path d="M1734.5,509.2c-27.2,0-51.5-1.24-67.16-3.71-9.48-1.24-13.6-4.94-13.6-15.24V215.85c0-9.47,4.54-14.42,13.19-15.24,13.59-1.65,38.73-3.3,65.51-3.3,65.5,0,120.71,17.31,120.71,79.52v6.59c0,30.49-16.06,54-44.08,64.69,28.43,9.88,45.73,35,45.73,66.74v7C1854.8,491.07,1798.77,509.2,1734.5,509.2Zm81.16-232.37c0-39.55-33-47.79-83.22-47.79-14,0-29.26.82-40.79,1.23V332.45h63.45c47.79,0,60.56-23.07,60.56-49Zm1.65,138c0-27.19-13.6-52.32-62.21-52.32h-63.45v113.3c13.59.41,28.43,1.64,42.85,1.64,51.08,0,82.81-11.12,82.81-55.62Z" />
    <path d="M2064.13,404.55h-138v5.77c0,49,19.37,67.15,57.27,67.15,23.9,0,54.39-1.64,73.34-4.53h2.06c3.29,0,6.59,1.65,6.59,5.77v14c0,5.35-1.65,8.24-8.24,9.47a373,373,0,0,1-73.75,7c-47.38,0-93.52-22.25-93.52-98.88V373.24c0-58.92,32.55-96.41,91.46-96.41,60.15,0,90.64,37.9,90.64,96.41v22.25C2072,400.84,2069.08,404.55,2064.13,404.55Zm-28.84-31.31c0-46.56-17.71-64.69-54-64.69-35.43,0-55.21,20.19-55.21,64.69V377h109.18Z" />
    <path d="M2279.65,404.55h-138v5.77c0,49,19.36,67.15,57.26,67.15,23.9,0,54.39-1.64,73.34-4.53h2.06c3.3,0,6.59,1.65,6.59,5.77v14c0,5.35-1.65,8.24-8.24,9.47a373,373,0,0,1-73.75,7c-47.38,0-93.52-22.25-93.52-98.88V373.24c0-58.92,32.55-96.41,91.46-96.41,60.16,0,90.64,37.9,90.64,96.41v22.25C2287.47,400.84,2284.59,404.55,2279.65,404.55Zm-28.84-31.31c0-46.56-17.72-64.69-54-64.69-35.43,0-55.2,20.19-55.2,64.69V377h109.18Z" />
    <path d="M2261.7,267a28.36,28.36,0,0,1-3.95.31c-5.58,0-8.25-1.46-8.25-8.14V238l-5.89-.67c-.67-.06-1.15-.42-1.15-1.09v-1.64a1.06,1.06,0,0,1,1.15-1.09h5.89v-5.95a1.24,1.24,0,0,1,1.09-1.22l3.16-.48h.18a1,1,0,0,1,.91,1v6.62h6.56a1.21,1.21,0,0,1,1.21,1.21v2.07a1.22,1.22,0,0,1-1.21,1.21h-6.56v21.18c0,3.34.85,3.83,3.28,3.83h3.4c.85,0,1.27.24,1.27.85V266C2262.79,266.52,2262.49,266.88,2261.7,267Z" />
    <path d="M2305.88,266.88H2303a1.21,1.21,0,0,1-1.21-1.21V244.18c0-5.1-2.37-6.49-5.53-6.49a21.07,21.07,0,0,0-7.1,1.63,18,18,0,0,1,.55,4.74v21.61a1.16,1.16,0,0,1-1.09,1.21h-3.22a1.1,1.1,0,0,1-1-1.21V244.06c0-5.28-1.52-6.37-4.68-6.37-2.18,0-4.85,1.09-7.4,2.36v25.62a1.16,1.16,0,0,1-1.09,1.21H2268a1.1,1.1,0,0,1-1-1.21v-31a1.16,1.16,0,0,1,1.15-1.21h3a1.17,1.17,0,0,1,1.16,1.21v1.15a17.13,17.13,0,0,1,8.86-2.85,7.4,7.4,0,0,1,6.07,2.49,24.35,24.35,0,0,1,10.08-2.49c5.46,0,9.83,2.73,9.83,11.17v21.49A1.22,1.22,0,0,1,2305.88,266.88Z" />
    <text
      transform="translate(752.39 -163.51)"
      fontSize={88.12}
      fontFamily="NeoSansPro-Regular, Neo Sans Pro"
    >
      <tspan letterSpacing="-0.01em">{'M'}</tspan>
      <tspan x={65.83} y={0} letterSpacing="-0.01em">
        {'A'}
      </tspan>
      <tspan x={120.33} y={0} letterSpacing="-0.03em">
        {'K'}
      </tspan>
      <tspan x={171.33} y={0} letterSpacing="-0.03em">
        {'I'}
      </tspan>
      <tspan x={191.98} y={0} letterSpacing="-0.02em">
        {'N'}
      </tspan>
      <tspan x={249.92} y={0} letterSpacing="0em">
        {'G '}
      </tspan>
      <tspan x={325.61} y={0} letterSpacing="-0.05em">
        {'W'}
      </tspan>
      <tspan x={393.74} y={0} letterSpacing="-0.01em">
        {'A'}
      </tspan>
      <tspan x={448.24} y={0} letterSpacing="-0.04em">
        {'R'}
      </tspan>
      <tspan x={500.15} y={0} letterSpacing="-0.03em">
        {'E'}
      </tspan>
      <tspan x={546.12} y={0} letterSpacing="-0.02em">
        {'H'}
      </tspan>
      <tspan x={603.96} y={0} letterSpacing="-0.02em">
        {'O'}
      </tspan>
      <tspan x={660.02} y={0} letterSpacing="-0.02em">
        {'U'}
      </tspan>
      <tspan x={716.21} y={0} letterSpacing="-0.03em">
        {'S'}
      </tspan>
      <tspan x={762.32} y={0} letterSpacing="-0.02em">
        {'E'}
      </tspan>
      <tspan x={808.78} y={0}>
        {'S '}
      </tspan>
      <tspan x={879} y={0} letterSpacing="-0.02em">
        {'M'}
      </tspan>
      <tspan x={944.03} y={0} letterSpacing="-0.02em">
        {'O'}
      </tspan>
      <tspan x={1000.21} y={0} letterSpacing="-0.04em">
        {'R'}
      </tspan>
      <tspan x={1052.11} y={0} letterSpacing="0em">
        {'E '}
      </tspan>
      <tspan x={1122.43} y={0} letterSpacing="-0.03em">
        {'EF'}
      </tspan>
      <tspan x={1214.37} y={0} letterSpacing="-0.03em">
        {'F'}
      </tspan>
      <tspan x={1259.85} y={0} letterSpacing="-0.03em">
        {'I'}
      </tspan>
      <tspan x={1280.67} y={0} letterSpacing="-0.02em">
        {'C'}
      </tspan>
      <tspan x={1326.6} y={0} letterSpacing="-0.03em">
        {'I'}
      </tspan>
      <tspan x={1347.12} y={0} letterSpacing="-0.03em">
        {'E'}
      </tspan>
      <tspan x={1393.09} y={0} letterSpacing="-0.02em">
        {'N'}
      </tspan>
      <tspan x={1451.44} y={0}>
        {'T'}
      </tspan>
    </text>
  </svg>
);

export default WareBeeLogoFullLogo;
