import * as React from 'react';
const PolicyWaveDockTimeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaveDockTimeIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M7.17,37.77h-2.48c.35,6.25,2.85,11.93,6.77,16.32l1.74-1.74c.6-.6,1.56-.6,2.16,0,.6.6.6,1.56,0,2.16l-1.74,1.74c4.39,3.92,10.07,6.42,16.32,6.77v-2.48c0-.84.68-1.53,1.53-1.53s1.53.68,1.53,1.53v2.48c6.25-.35,11.93-2.85,16.32-6.77l-1.74-1.74c-.6-.6-.6-1.56,0-2.16s1.56-.6,2.16,0l1.74,1.74c3.92-4.39,6.42-10.07,6.77-16.32h-2.48c-.84,0-1.53-.68-1.53-1.53s.68-1.53,1.53-1.53h2.48c-.35-6.25-2.85-11.93-6.77-16.32l-1.74,1.74c-.3.3-.69.45-1.08.45s-.78-.15-1.08-.45c-.6-.6-.6-1.56,0-2.16l1.74-1.74c-4.39-3.92-10.07-6.42-16.32-6.77v2.48c0,.84-.68,1.53-1.53,1.53s-1.53-.68-1.53-1.53v-2.48c-6.25.35-11.93,2.85-16.32,6.77l1.74,1.74c.6.6.6,1.56,0,2.16-.6.6-1.56.6-2.16,0l-1.74-1.74c-3.92,4.39-6.42,10.07-6.77,16.32h2.48c.84,0,1.53.68,1.53,1.53s-.68,1.53-1.53,1.53ZM19.25,25.28c.6-.6,1.56-.6,2.16,0l11.36,12.15,7.22-5.89c.75-.38,1.67-.07,2.05.68.38.75.07,1.67-.68,2.05l-8.2,6.38c-.22.11-.45.16-.68.16-.4,0-.79-.15-1.08-.45l-12.14-12.93c-.6-.6-.6-1.56,0-2.16Z"
    />
    <path
      strokeWidth={0}
      d="M33.72,69.37v51.6h12.08v-54.14c-3.7,1.63-7.78,2.54-12.08,2.54Z"
    />
    <path
      strokeWidth={0}
      d="M52.57,62.7h55.97c.55,0,.99.44.99.99v57.28h12.08V46.85h-58.84c-1.63,6.33-5.27,11.85-10.21,15.84Z"
    />
    <rect width={59.78} height={5.31} x={47.74} y={86.2} strokeWidth={0} />
    <rect width={59.78} height={5.3} x={47.74} y={93.48} strokeWidth={0} />
    <rect width={59.78} height={5.31} x={47.74} y={100.76} strokeWidth={0} />
    <rect width={59.78} height={5.3} x={47.74} y={78.91} strokeWidth={0} />
    <rect width={59.78} height={5.3} x={47.74} y={115.33} strokeWidth={0} />
    <rect width={59.78} height={5.31} x={47.74} y={71.63} strokeWidth={0} />
    <rect width={59.78} height={5.3} x={47.74} y={108.05} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M50.32,64.35c-.83.56-1.7,1.07-2.59,1.54v3.76h59.78v-5.3h-57.19Z"
    />
    <path
      strokeWidth={0}
      d="M63.6,30.64c0,8.97,0,9.07,0,9.18,0,1.73-.15,3.42-.43,5.06h61.61v-14.24h-61.19Z"
    />
  </svg>
);
export default PolicyWaveDockTimeIcon;
