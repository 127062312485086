import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type TitleProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  truncate?: boolean;
};

export const Title = ({ children, title, className, truncate }: TitleProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <h1
      data-component="Title"
      className={classNames(
        'text-sm md:text-base lg:text-lg xl:text-2xl',
        'text-menu-h1',
        'flex items-center',
        className,
      )}
    >
      {title && (
        <span className={classNames({ 'flex-1 truncate': truncate })}>
          {title}
        </span>
      )}
      {children}
    </h1>
  );
};
