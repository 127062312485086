import { format } from 'date-fns';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DATASET_VIEW_PAGE_SIZE } from '../../../common/constants';
import { formatDateTimeShort } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';
import DatasetTablePanelHeader from '../../../components/DatasetTablePanelHeader';
import { ContainerScroll } from '../../../components/layout/ContainerScroll';
import {
  workforceAgentSchedule,
  workforceSelectedDeadline,
  workforceSelectedUnscheduled,
} from '../../store/workforce.state';
import { AgentWorkforceWave } from '../../store/workforce.types';
import WorkforceDeadlineTag from './WorkforceDeadlineTag';

type WorkforceAgentScheduleTableRow = {
  workWindowId: string;
  deadline: Date;
  waveFrom: Date;
  waveTo: Date;
  duration: number;
  agentAmount: number;
};

type WorkforceAgentScheduleTableColumn = keyof WorkforceAgentScheduleTableRow;

export type WorkforceAgentScheduleTableProps = {
  analyzeId: string;
};

const WorkforceAgentScheduleTable: React.FC<
  WorkforceAgentScheduleTableProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const schedule = useRecoilValue(workforceAgentSchedule(props.analyzeId));
  const [selectedDeadline, setSelectedDeadline] = useRecoilState(
    workforceSelectedDeadline,
  );
  const [visibleLineCount, setVisibleLineCount] = useState(
    DATASET_VIEW_PAGE_SIZE,
  );
  const [selectedUnscheduled, setSelectedUnscheduled] = useRecoilState(
    workforceSelectedUnscheduled,
  );

  const searchValues: Partial<
    Record<WorkforceAgentScheduleTableColumn, string>
  > = {
    deadline: selectedDeadline ? formatDateTimeShort(selectedDeadline) : null,
  };

  const columnsConfig: ColumnConfig<WorkforceAgentScheduleTableRow>[] = [
    {
      field: 'deadline',
      title: t`Wave, Pick by`,
      isHeader: true,
      render: (eta, row) => (
        <WorkforceDeadlineTag
          deadline={eta as Date}
          isScheduled={!_.isNil(row.waveFrom)}
        />
      ),
    },
    {
      field: 'waveFrom',
      title: t`From`,
      // isHeader: true,
      render: (from: Date) =>
        from ? format(from, 'MM/dd  HH:mm:ss') : t`UNSCHEDULED`,
    },
    {
      field: 'waveTo',
      title: t`To`,
      // isHeader: true,
      render: (to: Date) =>
        to ? format(to, 'MM/dd  HH:mm:ss') : t`UNSCHEDULED`,
    },
    {
      field: 'duration',
      title: t`Duration`,
      // isHeader: true,
      render: (d: number) => formatter.formatTimeSpan(d).fullString,
    },
    {
      field: 'agentAmount',
      title: t`Total Agents`,
    },
  ];

  async function startExportCSV() {
    // const { data, errors } = await runExportCSV({
    //   variables: {
    //     id: agent.id,
    //   },
    // });
    //
    return {
      errors: [],
      job: [],
    };
  }
  const waves: AgentWorkforceWave[] = _.filter(
    [
      ...(selectedUnscheduled ? [] : schedule.waves),
      ...schedule.unscheduledWaves,
    ],
    w => _.isNil(selectedDeadline) || w.source.to === selectedDeadline,
  ) as AgentWorkforceWave[];

  const data = _(waves)
    .take(visibleLineCount)
    .map(w => ({
      workWindowId: w.id,
      deadline: w.source.to,
      waveFrom: w.forecast?.from,
      waveTo: w.forecast?.to,
      duration: w.forecast?.durationTotal ?? w.remainingDuration,
      agentAmount: w.forecastWindow?.agentAmount,
    }))
    .sortBy(w => w.waveFrom ?? new Date(0)) // unscheduled first
    .value();

  const totalCount = _.size(waves);

  return (
    <ContainerScroll className="flex flex-col bg-app-panel-dark/60">
      <DatasetTablePanelHeader
        title={t`Schedule`}
        columnsConfig={columnsConfig as any}
        searchValues={searchValues}
        onClearAllFilters={() => setSelectedDeadline(null)}
      />
      <DatasetTable
        id={'agent-schedule-table'}
        isSticky
        columnsConfig={columnsConfig}
        keyFields={['workWindowId']}
        data={data}
        onLoadNext={() =>
          setVisibleLineCount(visibleLineCount + DATASET_VIEW_PAGE_SIZE)
        }
        onSearch={_.noop} // setSearchValues}
        totalCount={totalCount}
        searchValues={searchValues}
        hideScreenTitle={true}
        // onStartExportClick={startExportCSV}
      />
    </ContainerScroll>
  );
};

export default WorkforceAgentScheduleTable;
