import {
  ItemFilterType,
  MeasurementSystem,
  StackingPolicyFragment,
  StackingPolicyInput,
  StackingPolicyRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { FilterFieldDataType, FilterFieldEditorType } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import * as Icon from '../../components/icons';
import {
  ItemSetMenuItem,
  PalletOrderPolicy,
  PalletOrderRule,
  PalletOrderRuleId,
  PalletSortFilterConfig,
} from './itemSet.types';

export const itemSetMenuItems: (
  t: TFunction<'app'>,
) => ItemSetMenuItem[] = t => [
  {
    id: 'item-set-menu-start',
    title: t(`Data`, { ns: 'app' }),
    titleMinimized: t(`Data`, { ns: 'app' }),
    disabled: false,
    icon: Icon.TableView,
    menuLevel: 1,
    sidebar: {
      'sidebar-item-set-main': { isCollapsed: false, openByDefault: true },
      'sidebar-item-set-editors': { isHidden: true },
    },
  },
  {
    id: 'item-set-menu-stats',
    title: t(`Stats`, { ns: 'app' }),
    titleMinimized: t(`Stats`, { ns: 'app' }),
    disabled: false,
    icon: Icon.DataStatsPie,
    menuLevel: 1,

    sidebar: {
      'sidebar-item-set-main': { isCollapsed: false, openByDefault: true },
      'sidebar-item-set-editors': { isHidden: true },
    },
  },
  {
    id: 'item-set-menu-simulation',
    title: t(`Simulations`, { ns: 'app' }),
    titleMinimized: t(`Simulations`, { ns: 'app' }),
    disabled: false,
    icon: Icon.SimulationsArrow,
    menuLevel: 1,
    sidebar: {
      'sidebar-item-set-main': { isCollapsed: false, openByDefault: true },
      'sidebar-item-set-editors': { isHidden: true },
    },
  },
  // {
  //   id: 'item-set-menu-uom',
  //   title: t('UOM Setup', {ns: 'app'}),
  //   titleMinimized: 'UOM Setup',
  //   disabled: false,
  //   icon: Icon.Pallet,
  //   menuLevel: 1,
  // },
  // {
  //   id: 'item-set-menu-pallet-policy',
  //   title: t(`Pallet Stacking Policy`, { ns: 'app' }),
  //   titleMinimized: t(`Stacking Policy`, { ns: 'app' }),
  //   disabled: false,
  //   icon: Icon.PolicyStackingDoNotStack,
  //   menuLevel: 1,
  //   sidebar: {
  //     'sidebar-item-set-main': { isCollapsed: false, openByDefault: true },
  //   },
  // },
];

export const itemSetPalletSortFilterConfig: (
  t: TFunction<'dataset'>,
) => PalletSortFilterConfig = t => [
  {
    type: ItemFilterType.CONSIGNEE,
    title: t(`Client`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SKU,
    title: t(`Item`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SKU_GROUP,
    title: t(`Item Group`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SUB_GROUP,
    title: t(`Item Sub Group`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.TRANSPORT_CLASS,
    title: t(`Transport Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.STOCK_CATEGORY,
    title: t(`Stock Category`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.STORAGE_CLASS,
    title: t(`Storage Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.POLLUTION_CLASS,
    title: t(`Pollution Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.NET_WEIGHT,
    title: t('Net Weight', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.LENGTH,
    title: t('Length', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.WIDTH,
    title: t('Width', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.HEIGHT,
    title: t('Height', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.VOLUME,
    title: t('Volume', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
];

export function getAffectedPalletOrderRuleIds(ruleId: PalletOrderRuleId) {
  switch (ruleId) {
    case 'items-pallet-order-bottom':
      return {
        include: ['items-pallet-order-bottom'],
        exclude: ['items-pallet-order-top'],
      };
    case 'items-pallet-order-top':
      return {
        include: ['items-pallet-order-top'],
        exclude: [],
      };
    case 'items-pallet-order-middle':
      return {
        include: [],
        exclude: ['items-pallet-order-top', 'items-pallet-order-bottom'],
      };
  }
}

export const PALLET_ORDER_BOTTOM_RULE_ID: PalletOrderRuleId =
  'items-pallet-order-bottom';
export const PALLET_ORDER_TOP_RULE_ID: PalletOrderRuleId =
  'items-pallet-order-top';
export const PALLET_ORDER_MIDDLE_RULE_ID: PalletOrderRuleId =
  'items-pallet-order-middle';

export function getPalletOrderBucketName(id: string, t: TFunction<'dataset'>) {
  switch (id) {
    case PALLET_ORDER_BOTTOM_RULE_ID:
      return t('Bottom Priority', { ns: 'dataset' });
    case PALLET_ORDER_TOP_RULE_ID:
      return t('Top Priority', { ns: 'dataset' });
    case PALLET_ORDER_MIDDLE_RULE_ID:
      return t('No Priority (default)', { ns: 'dataset' });
  }
  throw new Error(t(`Invalid parameter id: {{id}}`, { ns: 'dataset', id }));
}

export function getPalletOrderPolicyRuleFragment(
  rule: PalletOrderRule,
): StackingPolicyRuleFragment {
  if (!rule) return null;
  const itemsMatchAnyOf = rule.itemsMatch.anyOf.filter(
    intersection => intersection.allOf.length > 0,
  );
  return _.isEmpty(itemsMatchAnyOf)
    ? null
    : {
        id: rule.id,
        itemsMatch: {
          anyOf: cloneWithoutTypename(itemsMatchAnyOf),
        },
      };
}

export function getPalletOrderPolicyAPIInput(
  policy: PalletOrderPolicy,
): StackingPolicyInput {
  return {
    bottom: getPalletOrderPolicyRuleFragment(
      policy.rules.find(r => r.id === PALLET_ORDER_BOTTOM_RULE_ID),
    ),
    top: getPalletOrderPolicyRuleFragment(
      policy.rules.find(r => r.id === PALLET_ORDER_TOP_RULE_ID),
    ),
    totalNetWeightRange: cloneWithoutTypename(policy.totalNetWeightRange),
  };
}

export function getPalletOrderPolicy(
  data: StackingPolicyFragment,
  system: MeasurementSystem,
  t: TFunction<'dataset'>,
): PalletOrderPolicy {
  return {
    rules: [
      {
        title: t('Pallet Top Priority', { ns: 'dataset' }),
        //  ...(data?.top ?? { itemsMatch: { anyOf: [] } }),
        id: PALLET_ORDER_TOP_RULE_ID,
      },
      {
        id: PALLET_ORDER_MIDDLE_RULE_ID,
        title: t(`Dynamic (By Picked Line Weight)`, { ns: 'dataset' }),
        disableFilterSelection: true,
        itemsMatch: { anyOf: [] },
      },
      {
        title: t(`Pallet Bottom Priority`, { ns: 'dataset' }),
        //   ...(data?.bottom ?? { itemsMatch: { anyOf: [] } }),
        id: PALLET_ORDER_BOTTOM_RULE_ID,
      },
    ],
    // totalNetWeightRange: data?.totalNetWeightRange ?? {
    //   from: null,
    //   to: system === MeasurementSystem.METRIC ? 100 : 220,
    // },
    totalNetWeightRange: {
      from: null,
      to: system === MeasurementSystem.METRIC ? 100 : 220,
    },
  };
}
