import { AssignmentOccupancyLocationItemFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../common/measureHelper';
import useFormatter from '../../../common/useFormatter';
import InboxZero from '../../../components/InboxZero';
import * as Icon from '../../../components/icons';
import { StatGroup } from '../../../components/stats/StatGroup';
import { StatListHeader } from '../../../components/stats/StatListHeader';
import { StatListItem } from '../../../components/stats/StatListItem';
import PanelContainer from '../../../containers/PanelContainer';
import { warehouseMeasurementSystem } from '../../../store/warehouse.state';
import {
  getAssignmentOccupancyStatusMap,
  getOccupancyCategories,
  getOccupancyCategory,
  getOccupancyCategoryFull,
} from '../../store/occupancy/assignmentOccupancy.helper';
import { assignmentOccupancyBayLocation } from '../../store/occupancy/assignmentOccupancy.state';

export type LocationPropertiesAssignmentOccupancyProps = {
  locationId: string;
};

const LocationPropertiesAssignmentOccupancy: React.FC<
  LocationPropertiesAssignmentOccupancyProps
> = props => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const locationMap = useRecoilValue(assignmentOccupancyBayLocation);
  const ms = useRecoilValue(warehouseMeasurementSystem);

  const loc = locationMap?.[props.locationId];
  // const sectionTitle = t`Stock Occupancy`;

  const statusMap = getAssignmentOccupancyStatusMap(t);

  const EmptyPlaceholder = () => (
    <InboxZero message={t`Empty location`} message_helper={`(No Items)`} />
  );

  if (_.isNil(loc)) {
    return <EmptyPlaceholder />;
  }

  const locationVolume = loc.locationVolume;

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(ms, 'size'),
    tMeasures,
  );
  function getItems(items: AssignmentOccupancyLocationItemFragment[]) {
    return _(items)
      .sortBy(item => -item.sku)
      .map(product => {
        // const [background, color] = getAssignmentComplianceStatusColor(
        //   p.status,
        // );

        const share = formatter.formatShare(
          product.occupiedVolume / locationVolume,
        );
        const occupiedVolume = formatter.formatVolume(product.occupiedVolume);
        const count = formatInteger(product.stockUomQuantity);

        const itemCategory = getOccupancyCategoryFull(
          share?.raw,
          getOccupancyCategories(t),
        );

        // Volume - Calculated
        const volumeCalculatedRaw =
          product.stockUom?.width *
          product.stockUom?.height *
          product.stockUom?.length;

        const uomVolume = formatter.formatVolume(
          product.stockUom?.volume || volumeCalculatedRaw,
        );

        return (
          <React.Fragment key={`item-${product.itemId}`}>
            <StatListItem
              titleSelectable
              title={product.sku}
              value={share.value}
              valueRaw={share.raw}
              unitOfMeasure={share.unit}
              valueStyle={{
                background: itemCategory.color,
                color: itemCategory.textColor,
              }}
              labelValueType="itemId"
              labelValueFilters={{
                consignee: product.consignee,
                sku: product.sku,
                locationId: loc.locationId,
              }}
            />

            {!_.isEmpty(product.stockUom) && ( // Volume - Calculated
              <>
                <StatGroup className="pl-6">
                  <StatListItem
                    titleSelectable
                    title={t`Stock Qty`}
                    value={count}
                    // isUnitPrefix
                    // unitOfMeasure={`X`}
                    styleInput
                  />
                  <StatListItem
                    titleSelectable
                    title={t`Stock Vol. (Total)`}
                    value={occupiedVolume.value}
                    valueRaw={occupiedVolume.raw}
                    unitOfMeasure={occupiedVolume.unit}
                    styleInput
                  />
                </StatGroup>
                <div className={`flex items-start mb-4`}>
                  <Icon.BoxMargined
                    className={`h-5 w-5 mt-3 ltr:mr-2 rtl:ml-2 mx-0.5 fill-current text-menu-100`}
                  />
                  <div className={`flex flex-col flex-1`}>
                    <div
                      className={`flex items-end border-menu-400/30 border-b mt-2 mb-1 py-1 text-xs uppercase text-menu/60`}
                    >
                      <div className="flex-1 uppercase">{t`Dimensions`}</div>
                      <div
                        data-component="Value"
                        className="text-end text-xs p-1 px-2 rounded border border-menu-400"
                      >
                        {product.stockUom.uom}
                      </div>
                    </div>
                    <StatGroup className="">
                      <StatListItem
                        titleSelectable
                        title={t`Width`}
                        value={product.stockUom?.width}
                        unitOfMeasure={sizeMeasureValue}
                        styleInput
                      />
                      <StatListItem
                        titleSelectable
                        title={t`Height`}
                        value={product.stockUom?.height}
                        unitOfMeasure={sizeMeasureValue}
                        styleInput
                      />
                      <StatListItem
                        titleSelectable
                        title={t`Length`}
                        value={product.stockUom?.length}
                        unitOfMeasure={sizeMeasureValue}
                        styleInput
                      />
                      <StatListItem
                        titleSelectable
                        title={
                          _.isNil(product.stockUom?.volume)
                            ? t`Volume (calc.)`
                            : t`Volume`
                        }
                        value={uomVolume.value}
                        valueRaw={uomVolume.raw}
                        unitOfMeasure={uomVolume.unit}
                        styleInput
                      />
                    </StatGroup>

                    {/* <ObjectSize
                      object={p.sku}
                      unitMeasurement={sizeMeasureValue}
                      hasSize
                      sizeWidth={p.stockUom.width}
                      sizeHeight={p.stockUom.height}
                      sizeLength={p.stockUom.length}
                      sizeVolume={
                        p.stockUom.width * p.stockUom.height * p.stockUom.length
                      }
                      sizeVolumeUnit={volume.unit}
                      disabled
                    /> */}
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        );
      })
      .value();
  }

  const locationCategory = getOccupancyCategory(loc.occupancy);

  const share = formatter.formatShare(loc.occupancy);
  return (
    <PanelContainer
      id="panel-compliance-weight-items"
      title={t`Stock Occupancy`}
      collapsible
      hasPadding
    >
      {!_.isEmpty(loc) &&
        (loc.items.length > 0 ? (
          <>
            <StatListItem
              title={t`Total Occupancy`}
              value={share.value}
              unitOfMeasure={share.unit}
              valueClass={'px-2 rounded text-xxs'}
              valueStyle={{
                background: locationCategory.color,
                color: locationCategory.textColor,
              }}
            />
            <StatListHeader
              title={loc.items.length > 1 ? t`Item` : t`Item(s)`}
              value={t`Data`}
            />
            {getItems(loc.items)}
          </>
        ) : (
          <InboxZero message={t`No Items`} />
        ))}
    </PanelContainer>
  );
};

export default LocationPropertiesAssignmentOccupancy;
