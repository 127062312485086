import {
  AppBillingCustomerFragment,
  AppBillingProductCategory,
  AppBillingProductFragment,
  AppBillingPurchaseFragment,
  AppBillingSubscriptionFragment,
  AppBillingWarehouseStateFragment,
  LoadAppBillingProductsDocument,
  LoadAppBillingProductsQuery,
  LoadAppBillingProductsQueryVariables,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { billingClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getWarehouseBillingSummary } from './appBilling.helper';
import {
  Payments,
  PriceWithProduct,
  WarehouseBillingSummary,
} from './appBilling.types';

const getKey = (postfix: string) => `warebee-billing-${postfix}`;

export const appBillingProducts = selector<AppBillingProductFragment[]>({
  key: getKey('products'),
  get: async () => {
    try {
      const response = await billingClient.query<
        LoadAppBillingProductsQuery,
        LoadAppBillingProductsQueryVariables
      >({
        query: LoadAppBillingProductsDocument,
      });

      return response.data.appBillingProducts;
    } catch (ex) {
      console.log(ex);
    }
  },
});

export const appBillingPrices = selector<Record<string, PriceWithProduct>>({
  key: getKey('prices-map'),
  get: ({ get }) => {
    return _(get(appBillingProducts))
      .map(p => p.prices.map(price => ({ ...price, product: p })))
      .flatten()
      .keyBy(price => price.id)
      .value();
  },
});

// BILLING CUSTOMER
export const appBillingCustomer = atom<AppBillingCustomerFragment>({
  key: getKey('customer'),
  default: null,
});

export const appBillingCustomerLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('customer-load-status'),
  default: AsyncLoadStatus.None,
});

// ONE-TIME payments
export const appBillingPurchase = atom<AppBillingPurchaseFragment>({
  key: getKey('purchase'),
  default: null,
});

export const appBillingPurchaseError = atom<string>({
  key: getKey('purchase-error'),
  default: null,
});

export const appBillingPurchaseLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('purchase-load-status'),
  default: AsyncLoadStatus.None,
});

// SUBSCRIPTION
export const appBillingSubscription = atom<AppBillingSubscriptionFragment>({
  key: getKey('subscription'),
  default: null,
});

export const appBillingSubscriptionError = atom<string>({
  key: getKey('subscription-error'),
  default: null,
});

export const appBillingSubscriptionLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('subscription-load-status'),
  default: AsyncLoadStatus.None,
});

const appBillingWarehouseStateAtom = atom<
  Record<string, AppBillingWarehouseStateFragment>
>({
  key: getKey('warehouses-billing-state-all-atom'),
  default: null,
});

export const appBillingWarehouseStateAll = selector<
  Record<string, AppBillingWarehouseStateFragment>
>({
  key: getKey('warehouses-billing-state-all-dictionary'),
  get: ({ get }) => get(appBillingWarehouseStateAtom),
  set: ({ get, set }, value) => {
    const current = get(appBillingWarehouseStateAtom) ?? {};
    const newState = {
      ...current,
      ...value,
    };
    //    if (!_.isEqual(current, newState)) {
    set(appBillingWarehouseStateAtom, newState);
    //    }
  },
});

// WAREHOUSE BILLING STATE
export const appBillingWarehouseState =
  selector<AppBillingWarehouseStateFragment>({
    key: getKey('warehouse-billing-state'),
    get: ({ get }) =>
      get(appBillingWarehouseStateAll)?.[get(warehouseSelectedId)],
  });

export const appBillingWarehouseStateSummary =
  selector<WarehouseBillingSummary>({
    key: getKey('warehouse-billing-state-summary'),
    get: ({ get }) => getWarehouseBillingSummary(get(appBillingWarehouseState)),
  });

export const appBillingWarehouseLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('warehouse-load-status'),
  default: AsyncLoadStatus.None,
});

// payments
export const appBillingPayments = atom<Payments>({
  key: getKey('payments'),
  default: null,
});

export const appBillingPaymentsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('payments-load-status'),
  default: AsyncLoadStatus.None,
});

// Pricing view state
const appBillingPricingCurrencyByWhAtom = atom<Record<string, string>>({
  key: getKey('currency-by-wh'),
  default: {},
});

export const appBillingPricingCurrency = selector<string>({
  key: getKey('currency-family'),
  get: ({ get }) => {
    const whId = get(warehouseSelectedId);
    const all = get(appBillingPricingCurrencyByWhAtom);
    return all[whId] ?? 'USD';
  },
  set: ({ get, set }, value) => {
    const whId = get(warehouseSelectedId);
    set(appBillingPricingCurrencyByWhAtom, {
      ...get(appBillingPricingCurrencyByWhAtom),
      [whId]: value,
    });
  },
});

const appBillingPricingCategoryByWhAtom = atom<
  Record<string, AppBillingProductCategory>
>({
  key: getKey('category-by-wh'),
  default: {},
});

export const appBillingPricingCategory = selector<AppBillingProductCategory>({
  key: getKey('category-selector'),
  get: ({ get }) => {
    const whId = get(warehouseSelectedId);
    const all = get(appBillingPricingCategoryByWhAtom);
    return all[whId] ?? AppBillingProductCategory.SUBSCRIPTION;
  },
  set: ({ get, set }, value) => {
    const whId = get(warehouseSelectedId);
    set(appBillingPricingCategoryByWhAtom, {
      ...get(appBillingPricingCategoryByWhAtom),
      [whId]: value,
    });
  },
});

const appBillingPricingSizeByWhAtom = atom<Record<string, WarehouseSize>>({
  key: getKey('size-by-wh'),
  default: {},
});

export const appBillingPricingSize = selector<WarehouseSize>({
  key: getKey('size-selector'),
  get: ({ get }) => {
    const whId = get(warehouseSelectedId);
    const all = get(appBillingPricingSizeByWhAtom);
    return all[whId] ?? WarehouseSize.MEDIUM;
  },
  set: ({ get, set }, value) => {
    const whId = get(warehouseSelectedId);
    set(appBillingPricingSizeByWhAtom, {
      ...get(appBillingPricingSizeByWhAtom),
      [whId]: value,
    });
  },
});
