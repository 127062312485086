const LinkSourceIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkSourceIcon"
    {...props}
  >
    <path d="M80.86,47.14h9.98s20.51,0,20.51,0v64.22s-64.22,0-64.22,0v-15.94s-10.42,0-10.42,0v21.15c0,2.87,2.34,5.21,5.21,5.21h74.64c1.37,0,2.72-.55,3.69-1.52.97-.97,1.53-2.31,1.53-3.68V41.93c0-1.37-.56-2.71-1.53-3.68-.97-.97-2.31-1.53-3.68-1.53h-25.28s0-25.28,0-25.28c0-1.37-.56-2.72-1.53-3.69-.98-.98-2.29-1.53-3.68-1.53H11.43c-1.39,0-2.7.54-3.69,1.53-.97.97-1.52,2.31-1.52,3.68v4.19s0,70.45,0,70.45c0,1.39.54,2.7,1.53,3.68.98.98,2.29,1.53,3.69,1.53h74.64c1.37,0,2.71-.55,3.68-1.52.97-.97,1.53-2.31,1.53-3.69v-29.08s-10.42,0-10.42,0v23.87s-33.73,0-33.73,0h0s-10.42,0-10.42,0h-20.07V16.63s64.22,0,64.22,0v20.07" />
    <polygon points="64.35 35.46 42.74 35.46 71.39 64.1 71.39 71.99 63.54 71.99 34.88 43.33 34.9 64.96 23.75 53.84 23.75 24.34 53.23 24.34 64.35 35.46" />
  </svg>
);
export default LinkSourceIcon;
