import React from 'react';
import { useTranslation } from 'react-i18next';
import { InboxZero } from '../../components/InboxZero';

const AnalyzeCompareLayoutPlaceholder: React.FC = () => {
  const { t } = useTranslation('simulation');
  return (
    <InboxZero
      selfCenter
      hasIcon
      message={t`No Layout selected!`}
      message_helper={t`Please select Analyze to get started`}
    />
  );
};

export default AnalyzeCompareLayoutPlaceholder;
