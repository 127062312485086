import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import { Container } from '../../components/layout/ContainerFlex';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { sidebarStateByType } from '../../store/sidebar.state';
import useSelectItemSetMenuStep from '../hooks/useSelectItemSetMenuStep';
import { itemSetMenuItems } from '../store/itemSet.default';
import {
  itemSetDocument,
  itemSetSelectedMenuItemId,
} from '../store/itemSet.state';

type ItemSetMenuSidebarProps = {
  isMinimised: boolean;
};

const ItemSetMenuSidebar: React.FC<ItemSetMenuSidebarProps> = props => {
  const { t } = useTranslation('dataset');
  const [activeMenuId, setActiveMenuId] = useRecoilState(
    itemSetSelectedMenuItemId,
  );
  const selectStep = useSelectItemSetMenuStep();

  const document = useRecoilValue(itemSetDocument);
  const simulations = document?.simulationsWith?.content;
  const simulationCount = simulations ? simulations.length : null;

  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-item-set-main'),
  );

  useEffect(() => {
    if (!sidebarState.isPinned) {
      setSidebarState({ ...sidebarState, isCollapsed: false });
    }
  }, []);

  return (
    <Container col hasOverflowY data-component="ItemSetMenuSidebar">
      <ul className={cn('relative flex flex-col px-1 text-start')}>
        {itemSetMenuItems(t).map(item => {
          return (
            <SidebarMenuItem
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeMenuId}
              treeLine={item.treeLine}
              treeLineStart={item.treeLineStart}
              treeLineEnd={item.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              //inProgress={status?.inProgress}
              stepCounter={item.stepCounter}
              disabled={item?.disabled}
              isHidden={item?.isHidden}
              //locked={status?.isLocked}
              counterItems={
                item.id === 'item-set-menu-simulation' ? simulationCount : null
              }
              onClick={() => selectStep({ stepId: item.id })}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('dataset');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-item-set-menu',
    title: t('Menu'),
    size: 'xs',
  };

  return (
    <SidebarContainer
      key={containerProps.type}
      {...containerProps}
      widthAutoMinimised
      withMenuIcons={<ItemSetMenuSidebar isMinimised={true} />}
    >
      <ItemSetMenuSidebar isMinimised={false} />
    </SidebarContainer>
  );
};
