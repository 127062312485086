import classNames from 'classnames';
import _ from 'lodash';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../components/icons';

export const ActionLinkType = {
  tip: 'bg-blue-500 hover:bg-blue-700 text-white font-bold',
  suggestion: '',
  caution: '',
  check: '',
  note: '',
  warning: '',
  action: '',
};

export type ActionLinkProps = PropsWithChildren & {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  type?: keyof typeof ActionLinkType | string;
  iconType?: string;
};

const ActionLink: React.FC<ActionLinkProps> = props => {
  const { t } = useTranslation('help');
  let IconComponent = props.icon;

  if (!_.isNil(props.iconType)) {
    IconComponent = Icon[props.iconType];
  }

  return (
    <span data-component="ActionLink" className={classNames(props.className)}>
      {!_.isNil(IconComponent) && (
        <IconComponent className={classNames('fill-current w-3 h-3')} />
      )}
      {props.children && (
        <span
          data-component="ActionLinkChildren"
          className={classNames(
            'text-menu-active any-hover:hover:text-menu-active-active',
          )}
        >
          {props.children}
        </span>
      )}
      {!_.isNil(IconComponent) && (
        <IconComponent className={classNames('fill-current')} />
      )}
    </span>
  );
};

export default ActionLink;
