import _ from 'lodash';
import React, { useState } from 'react';

export type InputValueProps = {
  children?: React.ReactNode;
  className?: string;
  title: string;
  title_helper?: string;
  disabled?: boolean;
};

export const InputLabelGroup = ({
  children,
  className,
  title,
  title_helper,
}: InputValueProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      className={`InputLabelGroup self-end flex items-center w-full ${
        className || ''
      }`}
    >
      <span className="flex-1 title">{title}</span>
      {title_helper && (
        <span
          className={`ml-1 ltr:mr-2 rtl:ml-2 text-xs text-white opacity-50 text-opacity-90`}
        >
          {title_helper}
        </span>
      )}
      {children}
    </div>
  );
};
