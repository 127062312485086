import { InferResult } from 'kysely';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';
import { postProcessDefault } from './queryBuilder/feedQueryBuilder';

/**
 * Get list of existed UOMS in activity feed events for NOT LIMITED time range
 */

const feedDistinctUomsSelect = datasetQueryBuilder
  .selectFrom('___activity_feed_iceberg___')
  .select(['uom'])
  .distinct();

export type FeedDistinctUoms = InferResult<typeof feedDistinctUomsSelect>;
export type FeedDistinctUom = FeedDistinctUoms[number];

export const getFeedDistinctUomsQuery = (datasetId: string) => {
  return feedDistinctUomsSelect.where('datasetObjectId', '=', datasetId);
};

export function postProcessFeedDistinctUoms(
  data: Record<string, any>[],
): FeedDistinctUoms {
  return postProcessDefault<FeedDistinctUom>(data);
}
