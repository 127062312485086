import { AgentRole } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import {
  AgentSettingsWithMeta,
  ResourcePolicy,
} from '../../resourcePolicy/agentData/agent.types';
import { ResourcePolicyDefault } from './resourcePolicy.default';
import { ResourcePolicyReadyStatus } from './resourcePolicy.types';
import { simulationCurrent } from './simulation.state';

const getKey = (postfix: string) => `warebee-simulation-cost-policy-${postfix}`;

export const resourcePolicy = selector<ResourcePolicy>({
  key: getKey('document'),
  get: ({ get }) => {
    const sim = get(simulationCurrent);
    return (sim?.resourcePolicy as ResourcePolicy) ?? ResourcePolicyDefault;
  },
  set: ({ get, set }, value: ResourcePolicy) => {
    const sim = get(simulationCurrent);
    set(simulationCurrent, { ...sim, resourcePolicy: value });
  },
});

export const resourcePolicySelectedAgentId = atom<string>({
  key: getKey('selected-rule-id'),
  default: null,
});

export const resourcePolicyAgentById = selectorFamily<
  AgentSettingsWithMeta,
  string
>({
  key: getKey('agent-by-id'),
  get:
    (agentId: string) =>
    ({ get }) => {
      const policy = get(resourcePolicy);
      return _.find(policy?.agents, agent => agent.id === agentId);
    },
  set:
    (agentId: string) =>
    ({ get, set }, value: AgentSettingsWithMeta) => {
      const policy = get(resourcePolicy);
      set(resourcePolicy, {
        ...policy,
        agents: policy.agents.map(rule => (rule.id === agentId ? value : rule)),
      });
    },
});

export const resourcePolicySelectedAgent = selector<AgentSettingsWithMeta>({
  key: getKey('selected-rule'),
  get: ({ get }) => {
    const selectedId = get(resourcePolicySelectedAgentId);
    if (_.isNil(selectedId)) return null;
    return get(resourcePolicyAgentById(selectedId));
  },
  set: ({ get, set }, value: AgentSettingsWithMeta) => {
    const selectedId = get(resourcePolicySelectedAgentId);
    if (_.isNil(selectedId)) return null;
    set(resourcePolicyAgentById(selectedId), value);
  },
});

export const resourcePolicyPickingAgents = selector<
  Record<string, AgentSettingsWithMeta>
>({
  key: getKey('picking-agents-map'),
  get: ({ get }) => {
    const rp = get(resourcePolicy);
    return _(rp?.agents)
      .filter(a =>
        _.some(a.roles, r => r.roleId === AgentRole.PICKING && !r.disabled),
      )
      .keyBy(a => a.id)
      .value();
  },
});

export const resourcePolicyReassignAgents = selector<
  Record<string, AgentSettingsWithMeta>
>({
  key: getKey('reassign-agents-map'),
  get: ({ get }) => {
    const rp = get(resourcePolicy);
    return _(rp?.agents)
      .filter(a =>
        _.some(a.roles, r => r.roleId === AgentRole.REASSIGN && !r.disabled),
      )
      .keyBy(a => a.id)
      .value();
  },
});

export const resourcePolicyAgentsMap = selector<
  Record<string, AgentSettingsWithMeta>
>({
  key: getKey('agents-set'),
  get: ({ get }) => _.keyBy(get(resourcePolicy)?.agents, a => a.id),
});

export const resourcePolicyIsReadyToAnalyze =
  selector<ResourcePolicyReadyStatus>({
    key: getKey('is-ready-to-analyze'),
    get: ({ get }) => {
      const rp = get(resourcePolicy);
      const pickingAgents = get(resourcePolicyPickingAgents);
      const reassignAgents = get(resourcePolicyReassignAgents);

      const hasReassignRole = !_.isEmpty(reassignAgents);
      const hasPickingRole = !_.isEmpty(pickingAgents);
      const isAnalyzeReady = hasReassignRole && hasPickingRole;
      return {
        isAnalyzeReady,
        hasReassignRole,
        hasPickingRole,
      };
    },
  });
