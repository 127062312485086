import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import InboxZero from '../components/InboxZero';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import { updateAllPolicyFilterValues } from '../policyFilters/policyFilter.helper';
import useLoadLocationFilterValues from '../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import LoadBalancingPolicyLocationFilterEditor from './LoadBalancingPolicyLocationFilterEditor';
import LoadBalancingZoneTypeEditor from './LoadBalancingZoneTypeEditor';
import {
  loadBalancingPolicyRule,
  loadBalancingPolicySelectedIdentity,
} from './loadBalancingPolicy.state';

const LoadBalancingEditorPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const loadSelectedIdentity = useRecoilValue(
    loadBalancingPolicySelectedIdentity,
  );
  const rule = useRecoilValue(
    loadBalancingPolicyRule(loadSelectedIdentity?.ruleId),
  );
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  const showLocationFilters = loadSelectedIdentity?.type === 'locations';
  const showZoneTypeEditor = loadSelectedIdentity?.type === 'zone-type';

  const locationFiltersConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (showLocationFilters) {
      updateAllPolicyFilterValues({
        filterConfig: locationFiltersConfig,
        selectedFilterId: loadSelectedIdentity.locationFilterId,
        policyFilterUnion: null, //rule.locationsMatch,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }
  }, [loadSelectedIdentity]);

  if (!loadSelectedIdentity || !rule) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${rule.title}`}
        isSticky
      />

      {showZoneTypeEditor && <LoadBalancingZoneTypeEditor />}
      {showLocationFilters &&
        _.map(locationFiltersConfig, configItem => (
          <LoadBalancingPolicyLocationFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
    </Container>
  );
};
export default LoadBalancingEditorPanel;
