import { WaypointPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import { getIndexedTitle } from '../common/utils';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import useUpdateSimulation from '../simulation/hooks/useUpdateSimulation';
import { simulationIsEditable } from '../simulation/store/simulation.state';
import { createWaypointPolicyRule } from '../simulation/store/waypointPolicy/waypointPolicy.default';
import { getWaypointPolicyInput } from '../simulation/store/waypointPolicy/waypointPolicy.helper';
import {
  waypointPolicy,
  waypointPolicySelectedIdentity,
} from '../simulation/store/waypointPolicy/waypointPolicy.state';
import { collapsibleStateAtom } from '../store/collapsible.state';
import { sidebarStateByType } from '../store/sidebar.state';
import WaypointPolicyRule from './WaypointPolicyRule';

const WaypointPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(waypointPolicy);
  const updateSim = useUpdateSimulation();
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    waypointPolicySelectedIdentity,
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-routing-policy-editor'),
  );
  const [lastSavedPolicy, setLastSavedPolicy] =
    useState<WaypointPolicyFragment>();
  const formatter = useFormatter();

  useEffect(() => {
    // skip if policy not changed
    if (policy === lastSavedPolicy) return;

    // skip re-save on first render
    if (!_.isNil(lastSavedPolicy)) {
      updateSim({ waypointPolicy: getWaypointPolicyInput(policy) });
    }

    // update local state
    setLastSavedPolicy(policy);
  }, [policy]);

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy?.rules, r => r.title)),
      t`Waypoint Policy #`,
    );

    const newRule = createWaypointPolicyRule(ruleTitle);
    const ruleId = newRule.id;
    setPolicy({
      ...policy,
      rules: [...(policy?.rules ?? []), newRule],
    });

    setSelectedIdentity({
      ruleId,
    });

    setCollapsible({
      ...collapsible,
      [`waypoint-policy-rule-${ruleId}`]: { isCollapsed: false },
    });

    // setPolicyEditPanelState({
    //   isHidden: false,
    //   isCollapsed: !policyEditPanelState.isPinned
    //     ? false
    //     : policyEditPanelState.isCollapsed,
    // });
  };

  const isLoading = false;
  const hasError = false;

  const hasRules = !_.isEmpty(policy?.rules);
  const policyRuleCounter = policy?.rules?.length;

  // const color = getQualitativeColor(
  //   ruleId,
  //   props.groupType === 'start' ? 'waypointStart' : 'waypointEnd',
  // );

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={t`Waypoint policy`}
        subtitle={t`Define Start/End waypoints`}
        isSticky
        helpNavTo={'simulation/policies/policy-routing/policy-routing'}
        icon={Icon.PolicyWaypoints}
      />
      {!isLoading && !hasError && (
        <>
          <TitleSection
            title={
              <PolicyRuleSectionTitle
                isFeasible={true}
                counter={policyRuleCounter}
              />
            }
            inPanelView
            className="top-12 xl:top-20 z-400"
            classNameInner={classNames('flex-1', {
              'bg-app-panel-dark': canUpdate,
              'bg-issue-transparent bg-app-panel-dark': !canUpdate,
            })}
            hasScreenTitle
            hasAction={
              canUpdate ? (
                hasRules && (
                  <Button
                    label={t`Add Policy`}
                    className={classNames('ltr:ml-4 rtl:mr-4 rounded')}
                    buttonSize="xs"
                    buttonType="primary"
                    hasIconAfter={
                      <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                    }
                    onPress={() => addRule()}
                  />
                )
              ) : (
                <StatusTag
                  title={t`Policy Locked`}
                  type="locked"
                  modeStyle="stroke"
                  icon={Icon.Lock}
                />
              )
            }
          >
            <Container col flex1 overflow>
              <PolicyWell
                fullHeight
                isDisabled={!canUpdate}
                data-component="PolicyWellRuleWaypoints"
                className={classNames('flex-1 mb-2 relative')}
                classNameChildren={classNames('space-y-2 pb-10')}
              >
                {_.map(policy?.rules, (rule, index) => {
                  return (
                    <WaypointPolicyRule
                      // color={}
                      key={`policy-waypoint-rule-${rule.id}-${index}`}
                      ruleId={rule.id}
                      index={index + 1}
                      canRename={true}
                      canDelete={true}
                      isDisabled={!canUpdate}
                      onDeleteClick={() => removeRule(rule.id)}
                    />
                  );
                })}
                {!hasRules && (
                  <InboxZero selfCenter hasIcon message={t`No Rules found`}>
                    {canUpdate && (
                      <Button
                        full
                        buttonSize="xs"
                        buttonType="primary"
                        className="flex-1 mt-3 rounded"
                        label={t`Add New Policy`}
                        onPress={addRule}
                        hasIconAfter
                        buttonIcon={
                          <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                        }
                      />
                    )}
                  </InboxZero>
                )}
              </PolicyWell>
            </Container>
          </TitleSection>
        </>
      )}
    </Container>
  );
};

export default WaypointPolicy;
