import { AreaConfiguration } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import {
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import { Table } from '../../../../components/table/Table';
import { TableBody } from '../../../../components/table/TableBody';
import { TableHead } from '../../../../components/table/TableHead';
import { TableRow } from '../../../../components/table/TableRow';
import { TableRowHead } from '../../../../components/table/TableRowHead';
import { TableTd } from '../../../../components/table/TableTd';
import { TableTh } from '../../../../components/table/TableTh';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { converterAreaConfiguration } from '../store/converter.area.state';
import { converterLayoutBuilder } from '../store/converter.state';

const StepOrganizeAreas: React.FC = () => {
  const { t } = useTranslation('designer');

  const layoutBuilder = useRecoilValue(converterLayoutBuilder);
  const [areasConfig, setAreasConfig] = useRecoilState(
    converterAreaConfiguration,
  );

  if (!layoutBuilder) {
    return <LoadingIndicator selfCenter message={t`Loading layout settings`} />;
  }
  const floorMap = _.keyBy(_.values(layoutBuilder.floors), f => f.id);

  const drawRow = (area: AreaConfiguration) => {
    return (
      <TableRow key={`row-${area.id}`} rowLineList rowHover>
        <TableTd>{area.id}</TableTd>
        <TableTd>
          <DropdownSelector
            panelMode
            widthFull
            value={area.floor}
            values={_.values(layoutBuilder.floors).map(f => f.id)}
            renderValue={v => floorMap[v]?.title || ''}
            onChange={(value: string) => {
              setAreasConfig({
                ...areasConfig,
                [area.id]: { ...area, floor: value },
              });
            }}
          />
        </TableTd>
      </TableRow>
    );
  };

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Areas Setup`}
        title={t`Floor Assignment`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.Floor}
          question={t`How to change the floor of the area?`}
        />
        <HelpContainer id={'designer/02-areas-floors-assignment'} hasPadding />
      </InputQA>

      <TitleSection titleView title={t`Area Floor assignment`} />
      <Table isSticky isSortable isHoverable className="w-full">
        <TableHead>
          <TableRowHead>
            <TableTh panelMode value={t`Area`} selectable={true} />
            <TableTh panelMode value={t`Floor`}></TableTh>
          </TableRowHead>
        </TableHead>
        <TableBody>
          {_(areasConfig)
            .values()
            .filter(a => !a.isDeleted)
            .sortBy(a => a.id)
            .map(area => drawRow(area))
            .value()}
        </TableBody>
      </Table>
    </PanelBody>
  );
};

export default StepOrganizeAreas;
