import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import HelpContainer from '../../../../helpContext/HelpContainer';

const StepGettingStarted: React.FC = () => {
  const { t } = useTranslation('designer');
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Getting Started`}
        subtitle={t`Importing Layout`}
        hasStepCounter={'1.'}
      />
      <HelpContainer id={'designer/01-getting-started'} hasPadding />

      {/* <HelperMessage hasMargin hasPadding>
        <h3 className="mb-1 text-base xl:text-xl text-menu-text">
          {t`Welcome to the`}
          <span className="text-menu-text">{t`WareBee`}</span>
          {t`Interactive Warehouse Layout builder`}.
        </h3>
        <div className="text-xs xl:text-base">
          {t`We will guide you on creating a digital model of your warehouse based on the data you've just imported.`}
        </div>
        <h4 className={`text-xl text-menu-active mt-6 mb-2`}>
          {t`This Wizard has 5 steps:`}
        </h4>
        <div className="flex flex-col space-y-1">
          <StepListItemHelper stepCountId="1" title={t`Getting Started`}>
            {t`Define layout name, system of measurement and the floors in your warehouse.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="2" title={t`Areas`}>
            {t`Configure Warehouse Areas (Zones). You can rotate, move areas and even delete them.`}
          </StepListItemHelper>
          <StepListItemHelper
            stepCountId="3"
            title={t`Aisles, Locations and Bays`}
          >
            {t`Rearrange your aisles, bays and locations. You can adjust sizes, orientation, create new spaces and more.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="4" title={t`Navigation`}>
            {t`Connect areas to create a navigation path, and define the Starting point for the picking path of the layout.`}
          </StepListItemHelper>
          <StepListItemHelper stepCountId="5" title={t`Final Approval`}>
            {t`This is the last step of the wizard, confirming the layout design.`}
          </StepListItemHelper>
        </div>
        <HelperMessage isTip hasBefore>
          {t`You can skip any steps or jump between different sections.`}
        </HelperMessage>
        <HelperMessage hasBefore iconAction={Icon.ArrowRightBottom}>
          {t`Click on`}
          <HelperMessageAction>{t`Next`}</HelperMessageAction>
          {t`button bellow to get started.`}
        </HelperMessage>
      </HelperMessage> */}

      {/* <ImportLayoutHelper /> */}
    </PanelBody>
  );
};

export default StepGettingStarted;
