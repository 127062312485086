import { OptimizationRunStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import SwitchAnalyzeCompareType from '../../analyze/SwitchAnalyzeCompareType';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import DropdownSelector from '../../components/actions/DropdownSelector';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import { IconBackDirectional } from '../../components/nav/IconBackDirectional';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import LoadBalancingPolicyPanel from '../../loadBalancingPolicy/LoadBalancingPolicyPanel';
import SwapPolicyPanel from '../../swapPolicy/SwapPolicyPanel';
import { useLoadOptimizeResult } from '../../warehouse/hooks/useLoadOptimizeResult';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import AnalyzePanel from '../panels/AnalyzePanel';
import AnalyzePanelCompare from '../panels/AnalyzePanelCompare';
import OptimisationProgressStatus from '../panels/optimization/OptimisationProgressStatus';
import {
  optimisationResult,
  optimisationStatus,
  optimisationViewMode,
} from '../store/optimisation.state';
import {
  simulationAnalyzeCompareTypeSelected,
  simulationAnalyzeResult,
  simulationCurrent,
  simulationCurrentId,
} from '../store/simulation.state';
import {
  OptimisationPolicyTabId,
  OptimisationViewMode,
} from '../store/simulation.types';
import { simulationOptimiseTabKey } from '../store/simulation.wizard.state';
import ActionBarNextStep from './ActionBarNextStep';
import OptimisationPolicySetup from './optimisationPolicy/OptimisationPolicySetup';

interface OptimisationContentProps {
  analyzeTypeCompareMode: string;
  analyzeResult: any;
  optimisation: any;
}

const OptimisationContent: React.FC<OptimisationContentProps> = ({
  analyzeTypeCompareMode,
  analyzeResult,
  optimisation,
}) => {
  switch (analyzeTypeCompareMode) {
    case 'before':
      return (
        <>
          <AnalyzePanel analyzeResult={analyzeResult} />
        </>
      );

    case 'after':
      return (
        <>
          <AnalyzePanel analyzeResult={optimisation.analyzeResult} />
        </>
      );

    case 'compare':
      return (
        <>
          <AnalyzePanelCompare
            resultBefore={analyzeResult}
            resultAfter={optimisation?.analyzeResult}
          />
        </>
      );

    default:
      return <OptimisationProgressStatus />;
  }
};

interface OptimisationResultsProps {
  showOptimizePolicies: boolean;
}

const SidebarOptimisationResultsSummary: React.FC<OptimisationResultsProps> = ({
  showOptimizePolicies,
}) => {
  const { t } = useTranslation('simulation');

  const simId = useRecoilValue(simulationCurrentId);
  const simulation = useRecoilState(simulationCurrent);
  const selectStep = useSelectSimulationWizardStep();
  const analyzeTypeCompareMode = useRecoilValue(
    simulationAnalyzeCompareTypeSelected,
  );

  const optimisation = useRecoilValue(optimisationResult);
  const analyzeResult = useRecoilValue(simulationAnalyzeResult);
  // const allocationAnalyze = useRecoilValue(allocationAnalyzeResult);
  const [viewMode, setViewMode] = useRecoilState(optimisationViewMode);
  const loadOptimizeResult = useLoadOptimizeResult();
  const optStatus = useRecoilValue(optimisationStatus);
  const [selectedTab, setSelectedTab] = useRecoilState(
    simulationOptimiseTabKey,
  );

  const isOptimising =
    optStatus === OptimizationRunStatus.CREATED ||
    optStatus === OptimizationRunStatus.IN_PROGRESS ||
    optStatus === OptimizationRunStatus.STOPPING;

  const isLoading =
    !optimisation && optStatus === OptimizationRunStatus.COMPLETED;

  const hasOptimisationError = optStatus === OptimizationRunStatus.FAILED;
  // No optimisation, show  optimisation setup
  // if (!optStatus || hasOptimisationError) {
  //   return <OptimisationSetupPanel withError={hasOptimisationError} />;
  // }
  if (!optStatus || hasOptimisationError || showOptimizePolicies) {
    return (
      <>
        <OptimisationPolicySetup />
      </>
    );
  }

  // Optimisation exists but results is not loaded yet
  if (isLoading) {
    return (
      <LoadingIndicator
        message={t`Loading Optimisation Results...`}
        selfCenter
      />
    );
  }

  // Optimisation in progress
  if (isOptimising) {
    return <OptimisationProgressStatus />;
  }

  // const analyzeBefore =
  //   optimisation.optimizationSettings?.source?.type ===
  //   OptimizationRunSourceType.ALLOCATION_RUN
  //     ? allocationAnalyze
  //     : analyzeResult;

  const tabs: TabItem[] = [
    {
      id: OptimisationViewMode.Original,
      title: t`Before..`,
      content: (
        <>
          <AnalyzePanel analyzeResult={analyzeResult} />
        </>
      ),
    },
    {
      id: OptimisationViewMode.Optimised,
      title: t`After`,
      content: (
        <>
          <AnalyzePanel analyzeResult={optimisation.analyzeResult} />
        </>
      ),
    },
    {
      id: OptimisationViewMode.Compare,
      title: t`Compare`,
      content: (
        <>
          <AnalyzePanelCompare
            resultBefore={analyzeResult}
            resultAfter={optimisation?.analyzeResult}
          />
        </>
      ),
    },
    {
      id: OptimisationViewMode.Optimised,
      icon: (
        <Icon.MenuDotsVertical className={classNames('h-6 w-6 fill-current')} />
      ),
      secondary: true,
      content: (
        <>
          <OptimisationProgressStatus />
        </>
      ),
    },
  ];

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  let tabItems: TabItem<OptimisationPolicyTabId>[] = [
    {
      id: 'tab-optimisation-status',
      title: t`Optimisation Status`,
      icon: <Icon.OptimisationMl className={tabIconStyle} />,
      content: <OptimisationProgressStatus />,
    },
    {
      id: 'tab-optimisation-policy-swap',
      title: t`Swap policy`,
      icon: <Icon.PolicyOptimisation className={tabIconStyle} />,
      content: <SwapPolicyPanel />,
    },
    {
      id: 'tab-optimisation-policy-load-balancing',
      title: t`Load balancing`,
      icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
      content: <LoadBalancingPolicyPanel />,
    },
  ];

  return (
    <>
      {showOptimizePolicies ? (
        <Tabs
          componentName="OptimizePanelSetup"
          items={tabItems}
          selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
          onChange={index => setSelectedTab(tabItems[index].id)}
          classNameTitle="uppercase"
          hasScroll
          fullHeight
        />
      ) : (
        <OptimisationContent
          analyzeTypeCompareMode={analyzeTypeCompareMode}
          analyzeResult={analyzeResult}
          optimisation={optimisation}
        />
      )}

      <ActionBarNextStep title={t`Review Re-Assign Plan`} />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');
  const [showOptimizePolicies, setShowOptimizePolicies] =
    useState<boolean>(false);

  const optStatus = useRecoilValue(optimisationStatus);

  const getOptimizationTitle = status => {
    switch (status) {
      case OptimizationRunStatus.COMPLETED:
        return t`Optimisation: Results`;
      case OptimizationRunStatus.CREATED || OptimizationRunStatus.IN_PROGRESS:
        return t`Optimisation: Running`;
      case OptimizationRunStatus.STOPPING:
        return t`Optimisation: Stopping`;
      case OptimizationRunStatus.FAILED:
        return t`Optimisation: Issue`;
      case undefined:
        return t`Optimisation: Setup`;
      default:
        return t`Optimisation`;
    }
  };

  const isOptimising =
    optStatus === OptimizationRunStatus.CREATED ||
    optStatus === OptimizationRunStatus.IN_PROGRESS ||
    optStatus === OptimizationRunStatus.STOPPING;

  const isOptimisedSetup = optStatus === undefined;
  const isOptimiseHasIssue = optStatus === OptimizationRunStatus.FAILED;
  const isOptimised = optStatus === OptimizationRunStatus.COMPLETED;

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-optimisation-results',
    title: getOptimizationTitle(optStatus),
    titleAction: (
      <>
        {isOptimisedSetup ? (
          <span>{getOptimizationTitle(optStatus)}</span>
        ) : (
          <>
            {!showOptimizePolicies && (
              <>
                <DropdownSelector
                  classNameLabel={classNames('text-xxs xl:text-sm')}
                  className={classNames(
                    'ltr:mr-0.5 xl:ltr:mr-2 rtl:ml-0.5 xl:rtl:ml-2',
                    {
                      'text-menu-active': showOptimizePolicies,
                    },
                  )}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  DropAlignLeft
                  buttonTransparent
                  vertical
                  panelMode
                  value={showOptimizePolicies ? t`Hide Setup` : t`...`}
                  values={[
                    showOptimizePolicies ? t`Hide Setup` : t`Show Setup`,
                  ]}
                  onChange={async (option, e) => {
                    setShowOptimizePolicies(!showOptimizePolicies);
                  }}
                />
                {!isOptimised && <span>{getOptimizationTitle(optStatus)}</span>}
              </>
            )}
          </>
        )}
        {!showOptimizePolicies && isOptimised && <SwitchAnalyzeCompareType />}

        {showOptimizePolicies && (
          <Button
            label={t`Optimise Setup`}
            buttonSize="xs"
            full
            onPress={() => setShowOptimizePolicies(!showOptimizePolicies)}
            hasIconBefore={
              <span
                className={classNames(
                  'bg-app-panel-dark/50 group-hover:bg-menu-active group-hover:text-menu-active-text group rounded-full',
                  'h-8 w-8',
                  'flex items-center',
                )}
              >
                <IconBackDirectional
                  arrowSize="xs"
                  className={classNames('flex-1')}
                />
              </span>
            }
          />
        )}
      </>
    ),
    loadingMessage: t`Loading Optimisation Results...`,
    size: 'md',
    hasControlsPin: false,
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps} width={96}>
      <SidebarOptimisationResultsSummary
        showOptimizePolicies={showOptimizePolicies}
      />
    </SidebarContainer>
  );
};
