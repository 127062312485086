import * as React from 'react';
const CursorSelectTransformIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorSelectTransformIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M61.69,61.89c-1-2.49.21-3.7,2.7-2.7l60.62,24.48c2.49,1,2.4,2.37-.2,3.04l-23.62,6.05c-2.6.67-5.27,3.34-5.93,5.93l-6.05,23.62c-.67,2.6-2.03,2.69-3.04.2l-24.48-60.62Z"
    />
    <path
      strokeWidth={0}
      d="M104.88,48.17v-26.67h5.07V7.97h-13.53v5.07h-26.47c-.48-5.63-5.2-10.05-10.95-10.05s-10.47,4.42-10.95,10.05h-26.04v-5.07h-13.53v13.53h5.07v26.6c-5.39.71-9.55,5.31-9.55,10.89s4.16,10.18,9.55,10.89v26.03h-5.07v13.53h13.53v-5.07h26.09c.68,5.42,5.3,9.62,10.9,9.62,6.08,0,11-4.92,11-11s-4.92-11-11-11c-5.39,0-9.87,3.88-10.81,9h-26.19v-5.07h-5.07v-26.11c5.15-.91,9.07-5.4,9.07-10.82s-3.92-9.91-9.07-10.82v-26.68h5.07v-5.07h26.27c1.11,4.9,5.48,8.57,10.72,8.57s9.61-3.67,10.72-8.57h26.7v5.07h5.07v26.61c-5.36.73-9.5,5.32-9.5,10.89,0,6.08,4.92,11,11,11s11-4.92,11-11c0-5.43-3.94-9.93-9.12-10.83Z"
    />
  </svg>
);
export default CursorSelectTransformIcon;
