import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../components/HelperMessage';
import { Button } from '../../components/actions/Button';
import { DemoHelper } from '../../components/helpers/DemoHelper';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import {
  warehouseCanUpdate,
  warehouseIsDemo,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { ItemListCard } from '../containers/ItemListCard';
import { DashboardInfoCardProps } from '../store/dashboard.types';

const DashboardSimulationInfoCard: React.FC<DashboardInfoCardProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const isDemo = useRecoilValue(warehouseIsDemo);

  function StartButton() {
    if (!canUpdate) return null;
    return (
      <Button
        className={`mt-4 w-full rounded`}
        label={t`Create Simulation`}
        buttonType="primary"
        isDisabled={props.isLoading}
        full
        hasIconAfter
        buttonIcon={<Icon.CirclePlus className={`h-6 w-6 fill-current`} />}
        onPress={() => {
          navigate(`/wh/i/${warehouseId}/simulations/new`);
        }}
        isLoading={props.isLoading}
      />
    );
  }

  if (props.showButtonOnly) {
    return (
      <div className="p-2">
        <StartButton />
      </div>
    );
  }

  if (props.listMode) {
    return null;
  }

  return (
    <ItemListCard isHelper>
      <ScreenTitle
        subtitle={t`Warehouse Digital Twin`}
        title={t`Getting Started with Simulations`}
      />
      <HelperMessage hasPadding>
        <HelperMessage isLead textSize={'lead'}>
          {t`WareBee will visualise and analyse your warehouse to present performance and cost analytics of your operations.\n You will see the contribution of a single order to the picking time, costs and CO2 footprint.`}
        </HelperMessage>

        {isDemo ? (
          <HelperMessage
            hasBefore
            iconAction={Icon.ArrowRightBottom}
            textSize={'xl'}
          >
            {t`Click on`}
            <HelperMessageAction>{t`Simulation Card`}</HelperMessageAction>
            {t`(on the right) to see how WareBee analyses your warehouse.`}
          </HelperMessage>
        ) : (
          <>
            <HelperMessage isTip hasBefore>
              {t`New Simulation starts with dataset wizard, which helps you import new data or use existing layouts, orders, items, and assignments.`}
            </HelperMessage>
            <Spacer flexspace />

            <HelperMessage
              hasBefore
              iconAction={
                props.showButtonInline ? Icon.ArrowRightBottom : Icon.ArrowRight
              }
              textSize={'xl'}
            >
              {t`Click on`}
              <HelperMessageAction>{t`Create Simulation`}</HelperMessageAction>
              {`to start the new simulation wizard.`}
            </HelperMessage>
          </>
        )}
      </HelperMessage>

      {props.showButtonInline ? <StartButton /> : null}

      {isDemo && (
        <DemoHelper>
          <div className="my-8">
            <iframe
              src="https://www.youtube.com/embed/Kac9yQnKPG4?controls=0"
              width="100%"
              height="315"
              title="YouTube video player"
              // frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
              allowFullScreen
            />
          </div>
        </DemoHelper>
      )}
    </ItemListCard>
  );
};

export default DashboardSimulationInfoCard;
