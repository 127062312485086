import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import HeaderDataset from '../components/headers/HeaderDataset';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import DatasetObjectEditorSidebar from '../datasetObject/sidebar/DatasetObjectEditorSidebar';
import DatasetObjectPipelineSidebar from '../datasetObject/sidebar/DatasetObjectPipelineSidebar';
import { getImportConfigurations, getImportTypeFromJobType } from '../import/store/import.default';
import { ImportType } from '../import/store/import.types';
import DatasetAutomationDataView from './DatasetAutomationDataView';
import DatasetAutomationMenuSidebar from './sidebar/DatasetAutomationMenuSidebar';
import { datasetAutomationType } from './store/datasetAutomation.state';

export type DatasetAutomationRootParams = {
  datasetType: ImportJobImportType;
};



const DatasetAutomationRoot: React.FC<DatasetAutomationRootParams> = props => {
  const { t } = useTranslation('dataset');
  const setDatasetType = useSetRecoilState(datasetAutomationType);
  // const pipeline = useRecoilValue(importPipelineSelected);

  const { datasetType } = props;
  const dataType = getImportTypeFromJobType(datasetType);

  useEffect(() => {
    setDatasetType(datasetType);
    return () => {
      setDatasetType(null);
    };
  }, [datasetType]);

  const isLoading = false; //loadStatus === AsyncLoadStatus.Loading;
  const hasError = false; // loadStatus === AsyncLoadStatus.Error;
  const isReady = true; //loadStatus === AsyncLoadStatus.Ok;

  return (
    <>
      <HeaderDataset
        dataType={dataType}
        title={t`Integration (Jobs & Pipelines)`}
      />
      <ContainerMain className="flex" data-component="DatasetAutomationRoot">
        {hasError && <ErrorIndicator selfCenter message={t`Error`} />}

        {isLoading && <LoadingIndicator selfCenter message={t`Loading... `} />}

        {isReady && (
          <>
            <ContainerSidebar side="left">
              <DatasetAutomationMenuSidebar />
            </ContainerSidebar>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton={true}
                  exception={error}
                />
              )}
            >
              <Suspense
                fallback={
                  <Container col hasOverflowY>
                    <PanelHeader isLoading title={t`Loading...`} />
                    <LoadingIndicator
                      className={classNames(
                        'bg-map-area-striped border-app-panel-dark border-8',
                      )}
                      selfCenter
                      message={t`Loading`}
                    />
                  </Container>
                }
              >
                <DatasetAutomationDataView importType={datasetType} />
              </Suspense>
            </ErrorBoundary>
            <ContainerSidebar side="right">
              <DatasetObjectPipelineSidebar />
              <DatasetObjectEditorSidebar />
            </ContainerSidebar>
          </>
        )}
      </ContainerMain>
    </>
  );
};

export default DatasetAutomationRoot;
