import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Callout from '../helpContext/Callout';
import {
  SupportedLanguage,
  getLanguageTitle,
  supportedLanguages,
} from '../utils/language.helper';

const LanguagePrompt = () => {
  const { i18n } = useTranslation();
  const [detectedLanguage, setDetectedLanguage] =
    useState<SupportedLanguage | null>(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    // Detect the user's browser language
    const userLanguages = navigator.languages || [navigator.language];
    const userLanguage = userLanguages[0] as SupportedLanguage;

    // Check if the detected language is different from the current language and if it is supported by the application
    if (
      userLanguage &&
      i18n.language !== userLanguage &&
      supportedLanguages.includes(userLanguage)
    ) {
      setDetectedLanguage(userLanguage);
      setShowPrompt(true);
    }
  }, [i18n]);

  const handleChangeLanguage = () => {
    if (detectedLanguage) {
      i18n.changeLanguage(detectedLanguage);
    }
    setShowPrompt(false);
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const detectedLanguageTitle = detectedLanguage
    ? getLanguageTitle(detectedLanguage, i18n.t.bind(i18n))
    : '';

  console.debug(':: Detected Language :: ', detectedLanguage);
  const { t } = useTranslation('app');

  return (
    showPrompt && (
      <Callout panelMode type="suggestion">
        {t(
          'We detected your browser language is {{detectedLanguageTitle}}. Would you like to switch to it?',
          { detectedLanguageTitle },
        )}
        {/* <button onClick={handleChangeLanguage}>{t('Yes')}</button>
          <button onClick={handleClosePrompt}>{t('No')}</button> */}
      </Callout>
    )
  );
};

export default LanguagePrompt;
