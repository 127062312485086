import {
  StackingPolicy,
  StackingPolicyRule,
  StackingPolicyRuleSubcategoryType,
  StackingPriority,
} from './stacking-policy.model';

import { sortBy } from 'lodash';

export interface StackingPolicySubcategoryDetails {
  type: StackingPolicyRuleSubcategoryType;
  index: number;
  from?: number;
  to?: number;
}

export interface StackingPolicyCategoryDetails {
  index: number;
  rule: StackingPolicyRule;
}

export interface StackingPriorityDetails {
  category: StackingPolicyCategoryDetails;
  subcategory?: StackingPolicySubcategoryDetails;
}

export const FALLBACK_CATEGORY_ID = 'MIDDLE';

export function getStackingPriorityDetails(
  policy: StackingPolicy,
  priority: StackingPriority,
): StackingPriorityDetails | null {
  const effectiveTopRules = policy.topRules || (policy.top ? [policy.top] : []);
  const effectiveBottomRules =
    policy.bottomRules || (policy.bottom ? [policy.bottom] : []);
  const effectiveFallbackRule = {
    ...(policy.fallbackRule || {
      subcategories:
        policy.totalNetWeightRange?.from || policy.totalNetWeightRange?.to
          ? {
              boundaries: [
                policy.totalNetWeightRange.from,
                policy.totalNetWeightRange.to,
              ].filter(b => b),
            }
          : null,
    }),
    id: FALLBACK_CATEGORY_ID,
  };

  if (priority.length != 2) {
    return null;
  }

  const [categoryIndex, subcategoryIndex] = priority;
  let rule = null;
  if (categoryIndex < 0) {
    const topRuleIndex = effectiveTopRules.length + categoryIndex;
    if (topRuleIndex < 0 || topRuleIndex >= effectiveTopRules.length) {
      return null;
    }
    rule = effectiveTopRules[topRuleIndex];
  } else if (categoryIndex == 0) {
    rule = effectiveFallbackRule;
  } else {
    const bottomRuleIndex = categoryIndex - 1;
    if (bottomRuleIndex < 0 || bottomRuleIndex >= effectiveBottomRules.length) {
      return null;
    }
    rule = effectiveBottomRules[bottomRuleIndex];
  }

  let subcategoryDetails: StackingPolicySubcategoryDetails = null;

  const subcategories = rule.subcategories;

  if (subcategories?.boundaries) {
    const boundaries = sortBy(subcategories.boundaries);
    subcategoryDetails = {
      type:
        subcategories.type ||
        StackingPolicyRuleSubcategoryType.PICKLIST_LINE_WEIGHT,
      index: subcategoryIndex,
      from: subcategoryIndex == 0 ? null : boundaries[subcategoryIndex - 1],
      to:
        subcategoryIndex >= boundaries.length
          ? null
          : boundaries[subcategoryIndex],
    };
  } else if (subcategories?.step) {
    subcategoryDetails = {
      type:
        subcategories.type ||
        StackingPolicyRuleSubcategoryType.PICKLIST_LINE_WEIGHT,
      index: subcategoryIndex,
      from: subcategoryIndex * subcategories.step,
      to: (subcategoryIndex + 1) * subcategories.step,
    };
  }

  return {
    category: {
      index: categoryIndex,
      rule,
    },
    subcategory: subcategoryDetails,
  };
}
