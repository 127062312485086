import * as React from 'react';
const CloudDownloadCsvIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CloudDownloadCsvIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M27.37,99.36c1.03-1.03,1.6-2.42,1.61-3.87h0s0-10.2,0-10.2l-15.68,15.68h10.2c1.45,0,2.85-.58,3.87-1.61Z"
    />
    <path
      strokeWidth={0}
      d="M111.37,84.31c-1.03-1.03-2.42-1.61-3.87-1.61h-17.32v8.49s-21.03,21.02-21.03,21.02l-21.03-21.02v-8.49h-15.49v12.78c0,2.42-.97,4.74-2.68,6.45-1.71,1.71-4.03,2.67-6.45,2.68h-12.78v18.89h102.26v-35.33c0-1.45-.58-2.85-1.61-3.87Z"
    />
    <path
      strokeWidth={0}
      d="M69.15,102.61l-14.05-14.03v-10.58s10.3,10.29,10.3,10.29v-35.49s7.49,0,7.49,0v35.49s10.3-10.29,10.3-10.29v10.58s-14.04,14.03-14.04,14.03Z"
    />
    <path
      strokeWidth={0}
      d="M105.08,29.59h0c-1.88-14.15-14.02-25.1-28.68-25.1-10.97,0-18.68,6.81-22.49,11.17-3.17-1.89-6.81-2.91-10.58-2.91-10.08,0-18.5,7.26-20.31,16.82-11.89,1.82-21.03,12.12-21.03,24.51,0,13.67,11.13,24.8,24.8,24.8h21.32v-15.94s10.3,10.29,10.3,10.29v-25.61s21.47,0,21.47,0v25.61s10.3-10.29,10.3-10.29v15.94h11.02c13.67,0,24.8-11.13,24.8-24.8,0-12.35-9.08-22.63-20.92-24.5Z"
    />
  </svg>
);
export default CloudDownloadCsvIcon;
