import { FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateLayoutDocument,
  UpdateLayoutInput,
  UpdateLayoutMutation,
  UpdateLayoutMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { errorAppender } from '../../store/error.state';
import { layoutDocumentSaveStatus } from '../store/layout.state';

function useUpdateLayout() {
  const client = useApolloClient();
  const { t } = useTranslation('dataset');

  const beforeUpdate = useRecoilCallback(({ set }) => async () => {
    set(layoutDocumentSaveStatus, AsyncLoadStatus.Loading);
  });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (patch: UpdateLayoutInput) => {
        let response: FetchResult<UpdateLayoutMutation>;
        try {
          response = await client.mutate<
            UpdateLayoutMutation,
            UpdateLayoutMutationVariables
          >({
            mutation: UpdateLayoutDocument,
            variables: {
              layoutInput: {
                ...cloneWithoutTypename(patch),
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save Layout`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(layoutDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save Layout`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(layoutDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }
        set(layoutDocumentSaveStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (patch: UpdateLayoutInput) => {
    await beforeUpdate();
    await callUpdate(patch);
  };
}
export default useUpdateLayout;
