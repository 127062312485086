import {
  FindSimulationItemsByFilterQuery,
  FindSimulationItemsByFilterQueryVariables,
  SimulationItemBaseUomDataFragment,
  SimulationItemDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  getCategoryString,
  ProductCategoryDescriptorExport,
} from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type ProductsByRuleExportJobParams = ExportJobParams<
  FindSimulationItemsByFilterQueryVariables,
  ProductsByRuleConverterConfig
>;

export const productsByRuleExportJobParams: ProductsByRuleExportJobParams = {
  converterId: 'PRODUCTS_BY_RULE',
  query: null,
  config: null,
  variables: null,
  filename: 'products-by-rule.csv',
};

export type ProductsByRuleDataRow = Omit<
  SimulationItemDataFragment & SimulationItemBaseUomDataFragment,
  '__typename'
> & {
  categorySting: string;
};
export type ProductsByRuleDataColumn = keyof ProductsByRuleDataRow;

export type ProductsByRuleConverterConfig = {
  columnsConfig: ColumnConfigBase<ProductsByRuleDataRow>[];
  dictionaries: {
    categories: ProductCategoryDescriptorExport[];
  };
};

export const getProductsByRuleTableRows = (
  data: SimulationItemDataFragment[],
  config: ProductsByRuleConverterConfig,
): ProductsByRuleDataRow[] => {
  const getProductsByRuleDataRow = (row: SimulationItemDataFragment) => {
    const uom = row.baseUom;
    return {
      ...row,
      ...uom,
      categorySting: getCategoryString(
        row.cumulativePercentRank,
        config.dictionaries.categories,
      )?.tag,
    };
  };

  return _.map(data, getProductsByRuleDataRow);
};

export const PRODUCT_BY_RULE_EXPORT_CONVERTER: ExportConverter<
  FindSimulationItemsByFilterQuery,
  ProductsByRuleConverterConfig
> = (input, config) => {
  const data = getProductsByRuleTableRows(
    input.findSimulationItemsByFilter?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
