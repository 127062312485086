import { Container } from '../../components/layout/ContainerFlex';
import { importPipelineSelectedId } from '../../importJobs/store/importPipelines.state';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ImportPipelineMainPanel from '../../importJobs/panels/ImportPipelineMainPanel';
import {
  datasetObjectDocument,
  datasetObjectDocumentId,
} from '../store/datasetObject.state';

const DatasetObjectPipelineSidebar: React.FC = () => {
  const { t } = useTranslation('dataset');
  const id = useRecoilValue(datasetObjectDocumentId);
  const documentLoadable = useRecoilValueLoadable(datasetObjectDocument);

  const isLoading = documentLoadable.state === 'loading';
  const hasError = documentLoadable.state === 'hasError';
  const isReady = documentLoadable.state === 'hasValue';

  if (isLoading) {
    return <LoadingIndicator selfCenter message={t`Loading Dataset`} />;
  }
  if (hasError) {
    return <ErrorIndicator selfCenter message={t`Cannot load Dataset`} />;
  }

  return (
    <Container col>
      <ImportPipelineMainPanel />
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('dataset');
  const selectedPipelineId = useRecoilValue(importPipelineSelectedId);

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-dataset-pipeline',
    title: t`Pipeline Setup`,
    size: _.isNil(selectedPipelineId) ? 'sm' : 'lg',
  };
  return (
    <SidebarContainer {...containerProps}>
      <DatasetObjectPipelineSidebar />
    </SidebarContainer>
  );
};
