import * as React from 'react';
const HeatMapLayoutIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="HeatMapLayoutIcon"
    {...props}
  >
    <rect width={77.72} height={87.72} x={3.46} y={3.57} strokeWidth={0} />
    <rect
      width={37.72}
      height={87.72}
      x={85.74}
      y={3.57}
      strokeWidth={0}
      opacity={0.8}
    />
    <rect
      width={77.72}
      height={27.72}
      x={3.46}
      y={95.85}
      strokeWidth={0}
      opacity={0.5}
    />
    <rect
      width={37.72}
      height={27.72}
      x={85.74}
      y={95.85}
      strokeWidth={0}
      opacity={0.6}
    />
  </svg>
);
export default HeatMapLayoutIcon;
