import * as React from 'react';
const ArrowTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowTopIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4l48.27,48.23v36.37s-35.4-35.37-35.4-35.37v70.76s-25.75,0-25.75,0V53.24S15.73,88.6,15.73,88.6v-36.37S64,4,64,4Z"
    />
  </svg>
);
export default ArrowTopIcon;
