import { ApolloQueryResult } from '@apollo/client';
import {
  AppBillingProductCategory,
  AppBillingWarehouseStateFragment,
  BillableOperationType,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import {
  Action,
  GetWarehousesPermissionsDocument,
  GetWarehousesPermissionsQuery,
  GetWarehousesPermissionsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { secureClient } from '../../GraphQLClient';
import {
  AppBillingWarehouseSettings as AppBillingWarehouseDescriptor,
  WarehouseBillingInterval,
  WarehouseBillingOneTime,
  WarehouseBillingSummary,
  WarehouseBillingSummaryStatus,
} from './appBilling.types';

export function getWarehouseDescriptor(
  size: WarehouseSize,
  t: TFunction<'app'>,
): AppBillingWarehouseDescriptor {
  switch (size) {
    case WarehouseSize.SMALL:
      return {
        size: WarehouseSize.SMALL,
        title: t(`Small Warehouse`, { ns: 'app' }),
        shortTitle: t(`< 5,000`, { ns: 'app' }),
        // shortTitle: t(`Small`,{ns: 'app'}),
        description: t(`Small Warehouse`, { ns: 'app' }),
        locationLimit: 5000,
        locationLimitText: t(`Up to 5,000 Locations, per simulation run`, {
          ns: 'app',
        }),
      };
    case WarehouseSize.MEDIUM:
      return {
        size: WarehouseSize.MEDIUM,
        title: t(`Medium Warehouse`, { ns: 'app' }),
        shortTitle: t(`< 10,000`, { ns: 'app' }),
        // shortTitle: t(`Medium`,{ns: 'app'}),
        description: t(`Medium Warehouse`, { ns: 'app' }),
        locationLimit: 10000,
        locationLimitText: t(`Up to 10,000 Locations, per simulation run`, {
          ns: 'app',
        }),
      };
    case WarehouseSize.LARGE:
      return {
        size: WarehouseSize.LARGE,
        title: t(`Large Warehouse`, { ns: 'app' }),
        shortTitle: t(`10,000 >`, { ns: 'app' }),
        // shortTitle: t(`Large`,{ns: 'app'}),
        description: t(`Large Warehouse`, { ns: 'app' }),
        locationLimit: 10001,
        locationLimitText: t(`More than 10,000 Locations, per simulation run`, {
          ns: 'app',
        }),
      };
  }
}

export function getProductTitle(
  productType: BillableOperationType,
  category: AppBillingProductCategory,
  t: TFunction<'app'>,
) {
  switch (productType) {
    case BillableOperationType.ANALYZE:
      switch (category) {
        case AppBillingProductCategory.BUNDLE:
          return t(`Analyse Bundle`, { ns: 'app' });
        case AppBillingProductCategory.SUBSCRIPTION:
          return t(`Analyse Subscription`, { ns: 'app' });
      }
      break;
    case BillableOperationType.OPTIMIZE:
      switch (category) {
        case AppBillingProductCategory.BUNDLE:
          return t(`Optimise Bundle`, { ns: 'app' });
        case AppBillingProductCategory.SUBSCRIPTION:
          return t(`Optimise Subscription`, { ns: 'app' });
      }
  }
}

export function getOperationTitle(
  operation: BillableOperationType,
  t: TFunction<'app'>,
): string {
  switch (operation) {
    case BillableOperationType.ANALYZE:
      return t(`Analyse Simulation`, { ns: 'app' });
    case BillableOperationType.OPTIMIZE:
      return t(`Optimise Simulation`, { ns: 'app' });
  }
}

export function getOperationLabel(
  operation: BillableOperationType,
  t: TFunction<'app'>,
): string {
  switch (operation) {
    case BillableOperationType.ANALYZE:
      return t(`Analyse(s)`, { ns: 'app' });
    case BillableOperationType.OPTIMIZE:
      return t(`Optimise(s)`, { ns: 'app' });
  }
}

export function convertStripeAmount(value: number, currency?: string) {
  return value / 100;
}

export async function getWarehouseToken(warehouseIds: string[], handleError) {
  let response: ApolloQueryResult<GetWarehousesPermissionsQuery>;
  try {
    response = await secureClient.query<
      GetWarehousesPermissionsQuery,
      GetWarehousesPermissionsQueryVariables
    >({
      query: GetWarehousesPermissionsDocument,
    });
  } catch (ex) {
    handleError(ex.message || ex, ex.stack || null);
    return null;
  }

  if (response.errors) {
    handleError(null, response.errors.map(e => e.message).join('. '));
    return null;
  }
  const result = response.data?.warehouses?.content
    .filter(
      wh =>
        _.includes(warehouseIds, wh.id) &&
        _.includes(wh.permissions?.allowed, Action.READ),
    )
    .map(wh => wh.permissions?.token)
    .filter(token => !_.isEmpty(token));

  return result;
}

export function getWarehouseBillingSummary(
  whBillingState: AppBillingWarehouseStateFragment,
): WarehouseBillingSummary {
  let status: WarehouseBillingSummaryStatus = 'unpaid';
  const intervals: WarehouseBillingInterval[] = [];
  const oneTimes: WarehouseBillingOneTime[] = [];

  if (_.isNil(whBillingState)) {
    return { status };
  }
  const liveSubscriptions = _.filter(
    whBillingState.currentSubscriptions,
    sub => sub.status === 'ACTIVE',
  );

  const inactiveSubscriptions = _.filter(
    whBillingState.currentSubscriptions,
    sub => sub.status !== 'ACTIVE',
  );

  const hasAnalyseSub = _.some(
    liveSubscriptions,
    sub =>
      sub.subscriptionType === BillableOperationType.OPTIMIZE ||
      sub.subscriptionType === BillableOperationType.ANALYZE,
  );

  const hasOptimiseSub = _.some(
    liveSubscriptions,
    sub => sub.subscriptionType === BillableOperationType.OPTIMIZE,
  );

  //TRIAL
  if (!_.isEmpty(whBillingState.activeTrials)) {
    status = 'hasTrial';

    intervals.push(
      ...whBillingState.activeTrials.map(
        trial =>
          ({
            type: 'trial',
            to: new Date(trial.trialEnd),
            operations: trial.allowedOperations,
          } as WarehouseBillingInterval),
      ),
    );
  }

  // ONE TIME
  if (!_.isEmpty(whBillingState.balance)) {
    status = _.every(whBillingState.balance, b => b.balance === 0)
      ? 'hasExpiredOneTime'
      : 'hasOneTime';
    oneTimes.push(
      ..._.map(whBillingState.balance, oneTime => {
        const active =
          (oneTime.operationType === BillableOperationType.ANALYZE &&
            !(hasAnalyseSub || hasOptimiseSub)) ||
          (oneTime.operationType === BillableOperationType.OPTIMIZE &&
            !hasOptimiseSub);
        return {
          active,
          counter: oneTime.balance,
          operation: oneTime.operationType,
        } as WarehouseBillingOneTime;
      }),
    );
  }

  if (!_.isEmpty(inactiveSubscriptions) && status !== 'hasOneTime') {
    status = 'hasExpiredSubscription';
    const live: WarehouseBillingInterval[] = liveSubscriptions.map(sub => ({
      type: 'subscription-expired',
      to: new Date(sub.currentPeriodEnd),
      operations:
        sub.subscriptionType === BillableOperationType.OPTIMIZE
          ? [BillableOperationType.ANALYZE, BillableOperationType.OPTIMIZE]
          : [BillableOperationType.ANALYZE],
    }));
    intervals.push(...live);
  }

  if (!_.isEmpty(liveSubscriptions)) {
    status = 'hasSubscription';
    const live: WarehouseBillingInterval[] = liveSubscriptions.map(sub => ({
      type: 'subscription',
      to: new Date(sub.currentPeriodEnd),
      operations:
        sub.subscriptionType === BillableOperationType.OPTIMIZE
          ? [BillableOperationType.ANALYZE, BillableOperationType.OPTIMIZE]
          : [BillableOperationType.ANALYZE],
    }));
    intervals.push(...live);
  }

  return {
    status,
    intervals,
    oneTimes,
  };
}
