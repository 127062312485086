import * as React from 'react';
const WarehouseAreasIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseAreasIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,24.5L.84,40.93v62.58h126.32v-62.58l-62.66-16.43ZM46.12,78.51h25.03l1.16,5.33h-29.08l2.89-5.33ZM9.5,93.5l17.06-15.01h14.29l-10.55,15.01H9.5ZM38,93.5l2.97-5.47h32.25l1.19,5.47h-36.41ZM76.45,78.51h25.03l6.06,5.33h-29.07l-2.01-5.33ZM82.11,93.5l-2.07-5.47h32.24l6.22,5.47h-36.4Z"
    />
  </svg>
);
export default WarehouseAreasIcon;
