import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ActualityHeatmapLegendPanel from '../panels/ActualityHeatmapLegendPanel';
import AssignmentHeatmapLegendPanel from '../panels/AssignmentHeatmapLegendPanel';
import { actualityHeatmapTypeSelected } from '../store/actuality.state';

const ActualityHeatmapBucketsSidebar: React.FC = () => {
  const { t } = useTranslation('feed');
  const heatmapType = useRecoilValue(actualityHeatmapTypeSelected);

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-feed-heatmap',
    title: t`Warehouse Stats`,
    size: 'xs',
  };

  return (
    <SidebarContainer {...containerProps}>
      <Container
        col
        data-component="ActualityHeatmapBucketsSidebar"
        className="relative"
      >
        {/* <Container col> */}
        <Suspense
          fallback={
            <LoadingIndicator absolute message={t`Loading Data`} selfCenter />
          }
        >
          {heatmapType === 'events' && <ActualityHeatmapLegendPanel />}
          {heatmapType === 'assignment' && <AssignmentHeatmapLegendPanel />}
        </Suspense>
      </Container>
      {/* </Container> */}
    </SidebarContainer>
  );
};
export default ActualityHeatmapBucketsSidebar;
