import * as React from 'react';
const PickByContainerIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PickByContainerIcon"
    {...props}
  >
    <g>
      <polygon
        strokeWidth={0}
        points="2.74 30.59 2.74 12.85 18.1 21.72 18.1 39.45 2.74 30.59"
      />
      <polygon
        strokeWidth={0}
        points="4.32 10.1 19.64 1.26 34.95 10.1 19.64 18.94 4.32 10.1"
      />
      <polygon
        strokeWidth={0}
        points="21.22 21.73 36.54 12.89 36.54 30.57 21.22 39.41 21.22 21.73"
      />
      <g opacity={0.7}>
        <polygon
          strokeWidth={0}
          points="69.03 76.15 69.03 58.41 84.39 67.28 84.39 85.01 69.03 76.15"
        />
        <polygon
          strokeWidth={0}
          points="70.62 55.66 85.93 46.82 101.25 55.66 85.93 64.5 70.62 55.66"
        />
        <polygon
          strokeWidth={0}
          points="87.52 67.29 102.83 58.45 102.83 76.13 87.52 84.97 87.52 67.29"
        />
      </g>
      <polygon
        strokeWidth={0}
        points="2.72 74.38 2.72 56.65 18.08 65.52 18.08 83.25 2.72 74.38"
      />
      <polygon
        strokeWidth={0}
        points="4.3 53.9 19.62 45.06 34.93 53.9 19.62 62.74 4.3 53.9"
      />
      <polygon
        strokeWidth={0}
        points="21.2 65.53 36.52 56.69 36.52 74.37 21.2 83.21 21.2 65.53"
      />
      <polygon
        strokeWidth={0}
        points="2.62 118.18 2.62 100.45 17.98 109.31 17.98 127.05 2.62 118.18"
      />
      <polygon
        strokeWidth={0}
        points="4.21 97.7 19.52 88.86 34.84 97.7 19.52 106.54 4.21 97.7"
      />
      <polygon
        strokeWidth={0}
        points="21.11 109.33 36.42 100.49 36.42 118.17 21.11 127.01 21.11 109.33"
      />
    </g>
    <g>
      <polygon
        strokeWidth={0}
        points="59.5 25.77 59.48 35.18 49.17 24.92 39.16 24.92 39.16 29.66 47.04 29.66 56.03 38.6 46.57 38.61 51.43 43.44 64.34 43.44 64.36 30.61 59.5 25.77"
      />
      <polygon
        strokeWidth={0}
        points="59.5 108.63 59.48 99.22 49.17 109.48 39.16 109.48 39.16 104.74 47.04 104.74 56.03 95.8 46.57 95.8 51.43 90.96 64.34 90.97 64.36 103.79 59.5 108.63"
      />
      <polygon
        strokeWidth={0}
        points="104.68 63.57 118.22 63.57 111.52 56.91 118.38 56.93 127.51 66.06 118.45 75.15 111.59 75.13 118.23 68.47 104.68 68.47 104.68 63.57"
      />
      <polygon
        strokeWidth={0}
        points="42.13 63.57 56.67 63.61 49.97 56.91 56.83 56.93 65.96 66.06 56.9 75.15 50.04 75.13 56.68 68.47 42.13 68.42 42.13 63.57"
      />
    </g>
  </svg>
);
export default PickByContainerIcon;
