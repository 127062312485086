import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import LocationPropertiesPanel from '../../components/sharedPanels/LocationPropertiesPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { viewerSelectedBayLocation } from '../../layout/viewer/store/viewer.state';
import FeedLocationItems from '../components/FeedLocationItems';

const FeedLocationSidebar: React.FC = () => {
  const { t } = useTranslation('feed');
  const locationLoadable = useRecoilValueLoadable(viewerSelectedBayLocation);

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-layout-info-location',
    title: t`Location Info`,
    size: 'xs',
  };

  const location =
    locationLoadable.state === 'hasValue' ? locationLoadable.contents : null;
  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      {!location && (
        <LoadingIndicator selfCenter message={t`Loading location info...`} />
      )}
      {location && (
        <LocationPropertiesPanel location={location}>
          <Suspense
            fallback={
              <LoadingIndicator selfCenter message={t`Loading Item Data...`} />
            }
          >
            <FeedLocationItems locationId={location.locationId} />
          </Suspense>
        </LocationPropertiesPanel>
      )}
    </SidebarContainer>
  );
};

export default FeedLocationSidebar;
