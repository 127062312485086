import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import StatDiffIcon from './StatDiffIcon';
import { UnitOfMeasure } from './UnitOfMeasure';

export type StatItemProps = {
  title: string;
  value: FormattedValueWithUnit;
  valueToCompare?: FormattedValueWithUnit;
  legendColor?: string;
};

const ValueCell: React.FC<{
  valueWithUnit: FormattedValueWithUnit;
  className?: string;
  classNameValue?: string;
}> = ({ valueWithUnit, className, classNameValue }) => {
  const { t } = useTranslation('app');
  return (
    <div
      data-component="StatValueCell"
      className={classNames(
        'flex items-baseline ltr:justify-end rtl:justify-start',
        className,
      )}
    >
      {valueWithUnit.prefixUnit && (
        <UnitOfMeasure unitOfMeasure={valueWithUnit.unit} />
      )}
      <div
        aria-label={t`Stat Value`}
        className={classNames(
          'text-end text-xs md:text-sm font-bold',
          classNameValue,
        )}
      >
        {valueWithUnit.value}
      </div>
      {!valueWithUnit.prefixUnit && (
        <UnitOfMeasure unitOfMeasure={valueWithUnit.unit} />
      )}
    </div>
  );
};

const StatItem: React.FC<StatItemProps> = props => {
  const { title = '' } = props;

  const compareValueEqual = props.value?.raw === props.valueToCompare?.raw;
  const showCompareValue = !_.isNil(props.valueToCompare);

  return (
    <div
      data-component="StatItem"
      data-label={`stat-item-${title}`}
      className={classNames(
        'flex flex-col xl:flex-row flex-1 items-start',
        'border-menu-300 border-solid border-b border-opacity-20',
        'mt-0.5 py-1',
      )}
    >
      <div
        className={classNames(
          'flex flex-1 items-center',
          'uppercase whitespace-nowrap',
          'text-minimal sm:text-xxs md:text-xs w-full xl:w-auto',
        )}
      >
        {props.legendColor && (
          <div
            data-component="StatItemLegend"
            className={classNames(
              'ltr:mr-2 rtl:ml-2',
              'w-3 h-3 lg:w-4 lg:h-4',
              'rounded-full',
              'border-4',
            )}
            style={{
              borderColor: props.legendColor,
              background: props.legendColor,
            }}
          />
        )}
        {title}
      </div>
      <div
        className={classNames(
          'flex items-center mt-2 w-full xl:mt-0 xl:w-auto xl:mr-1',
        )}
      >
        <ValueCell
          valueWithUnit={props.value}
          className={classNames({ '': showCompareValue })}
        />
        {!compareValueEqual && showCompareValue && (
          <>
            <StatDiffIcon
              className={classNames(
                'fill-current',
                'w-3 h-3 xl:w-3 xl:h-3',
                'ltr:mr-0.5 rtl:ml-0.5',
                'opacity-75',
              )}
              before={props.value}
              after={props.valueToCompare}
            />
            <ValueCell
              valueWithUnit={props.valueToCompare}
              className={classNames('xl:min-w-12')}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StatItem;
