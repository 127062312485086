import React from 'react';
import ActiveFeatureLayer from '../layout/viewer/layers/ActiveFeatureLayer';
import HoveredFeatureLayer from '../layout/viewer/layers/HoveredFeatureLayer';
import SimulationAisleLayer from './layers/SimulationAisleLayer';
import SimulationComplianceBayLayer from './layers/SimulationComplianceBayLayer';
import SimulationLayoutContainer from './SimulationLayoutContainer';
import SimulationLayoutAlerts from './toolbars/SimulationLayoutLoadingAlerts';

export type SimulationComplianceLayoutViewProps = {
  getLocationLayer: () => React.ReactNode | undefined;
  getAbsoluteContent?: () => React.ReactNode | undefined;
};

const SimulationComplianceLayoutView: React.FC<
  SimulationComplianceLayoutViewProps
> = props => {
  return (
    <SimulationLayoutContainer
      getAbsoluteContent={() => (
        <>
          <SimulationLayoutAlerts />
          {props.getAbsoluteContent && props.getAbsoluteContent()}
        </>
      )}
    >
      <SimulationAisleLayer />
      <SimulationComplianceBayLayer />
      {props.getLocationLayer()}
      <ActiveFeatureLayer />
      <HoveredFeatureLayer />
    </SimulationLayoutContainer>
  );
};

export default SimulationComplianceLayoutView;
