import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../icons';

export type PolicyFilterEmptyItemProps = {
  isSelected?: boolean;
};

const PolicyFilterEmptyItem: React.FC<PolicyFilterEmptyItemProps> = props => {
  const { t } = useTranslation('simulation');

  return (
    <div
      data-component="PolicyFilterEmptyItem"
      className={classNames([
        'p-1 m-0.5 mt-2 rounded border bg-app-panel-dark/50 flex items-center isolate',
        'transition duration-300 delay-200',
        {
          'text-menu-active border-menu-active hover:text-menu-text hover:bg-app-panel-dark/60':
            props.isSelected,
        },
        {
          'text-menu-text border-app-panel-dark hover:text-menu-text/60 hover:bg-app-panel-dark/40':
            !props.isSelected,
        },
      ])}
    >
      <div className="flex-1 ltr:pl-2 rtl:pr-2">{t`Click to set Policy filter`}</div>
      <Icon.ArrowRight className="w-4 h-4 ltr:mr-2 rtl:ml-2 fill-current" />
    </div>
  );
};

export default PolicyFilterEmptyItem;
