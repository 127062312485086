import React from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { TwTheme } from '../../../../../Tw';
import AisleFeature from '../../../../layout/features/AisleFeature';
import {
  converterAreaConfiguration,
  converterEditableArea,
} from '../store/converter.area.state';
import { getLayerConfig } from '../store/converter.helper';
import {
  converterLayoutAltSelectedAisleId,
  converterLayoutHoveredFeature,
  converterLayoutSelectedAisle,
} from '../store/converter.layout.state';

const aisleColors = TwTheme.extend.colors.aisle;

const ConverterAisleLayer: React.FC = () => {
  const area = useRecoilValue(converterEditableArea);
  const areasConfigResponse = useRecoilValueLoadable(
    converterAreaConfiguration,
  );
  const setSelectedAisle = useSetRecoilState(converterLayoutSelectedAisle);
  const highlightedAisleId = useRecoilValue(converterLayoutAltSelectedAisleId);
  const setHovered = useSetRecoilState(converterLayoutHoveredFeature);

  if (!area || areasConfigResponse.state !== 'hasValue') return null;

  const areasConfig = areasConfigResponse.contents;
  const areaConfig = areasConfig[area?.id];

  return (
    <Layer {...getLayerConfig(areaConfig, area)}>
      {[...area.aisles].map(convertedAisle => {
        return (
          <AisleFeature
            key={`aisle-${convertedAisle.id}`}
            aisle={convertedAisle}
            color={
              convertedAisle.id === highlightedAisleId
                ? aisleColors.selected
                : null
            }
            onClick={() => {
              setSelectedAisle(convertedAisle);
            }}
            onMouseOver={() => {
              setHovered(convertedAisle);
            }}
            onMouseOut={() => {
              setHovered(null);
            }}
          />
        );
      })}
    </Layer>
  );
};

export default ConverterAisleLayer;
