import { IsNotEmpty } from '@warego/class-validator';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import FormError from '../components/FormError';
import { Button } from '../components/actions/Button';
import { Alert } from '../components/alerts/Alerts';
import InputText from '../components/inputs/InputText';
import { Spacer } from '../components/layout/Spacer';
import { IconArrowDir } from '../components/nav/IconArrowDirectional';
import { confirmMFA } from '../store/auth.helper';
import { authState } from '../store/auth.state';
import useValidatedForm from '../utils/use-validated-form';
import { useErrorTranslator } from './ErrorTranslator';

export type LoginTOTPFormProps = {};

class TOTPFormState {
  @IsNotEmpty({ message: ({ t }) => t('Code required') })
  code: string;
}

const LoginTOTPForm: React.FC<LoginTOTPFormProps> = props => {
  const { t } = useTranslation('app-public');
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { translateError } = useErrorTranslator();

  const { register, handleSubmit, formState } = useValidatedForm<TOTPFormState>(
    TOTPFormState,
    {
      defaultValues: {
        code: '',
      },
      validatorOptions: {
        extraArguments: {
          t,
        },
      },
    },
  );

  if (auth.status !== 'unauthorized') {
    return null;
  }

  const codeFieldProps = register('code');

  async function handleTOTPCode({ code }: TOTPFormState) {
    setIsLoading(true);
    try {
      const isSuccessful = await confirmMFA(code);
      if (isSuccessful) {
        navigate('/');
      }
    } catch (ex) {
      console.error(ex);
      setErrorMessage(translateError(ex));
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <form
      data-component="TOTPForm"
      onSubmit={handleSubmit(handleTOTPCode)}
      className="form-totp w-full flex-1 p-4"
    >
      {errorMessage && <Alert hasStatusAlert message={errorMessage} />}
      <section className="w-full space-y-8 p-4 pb-0">
        <fieldset>
          <InputText
            id="code"
            autoComplete="code"
            placeholder={t`123456`}
            label={t`One-time Password`}
            name="code"
            {..._.omit(codeFieldProps, 'onChange')}
            onChangeValidate={codeFieldProps.onChange}
          />
          <FormError errors={formState?.errors} name="username" />
        </fieldset>
      </section>

      <footer className="flex w-full items-center p-4">
        <Spacer flexspace />
        <Button
          label={t`Submit`}
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading || !_.isEmpty(formState?.errors)}
          buttonType="primary"
          hasIconAfter
          buttonIcon={<IconArrowDir />}
        />
      </footer>
    </form>
  );
};
export default LoginTOTPForm;
