import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { DashboardActionbar } from '../../components/DashboardActionbar';
import { HelperMessage } from '../../components/HelperMessage';
import { DemoHelper } from '../../components/helpers/DemoHelper';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ContainerMain } from '../../components/layout/ContainerMain';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ItemListCard } from '../../dashboard/containers/ItemListCard';
import DataDashboardLayout from '../../dashboard/sections/DataDashboardLayouts';
import { warehouseIsDemo } from '../../store/warehouse.state';
import WarehouseContainer from '../WarehouseContainer';

export const WarehouseDesignerDashboard: React.FC = () => {
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar />
      <ContainerMain className="flex data-container designer-mode-select">
        <ContainerCol overflow>
          <ContainerScroll className="pt-0 pb-24">
            <ItemListCard fullWidth>
              <ScreenTitle
                // subtitle={t`Digital Twin`}
                title={t`Getting started with Warehouse Layout Designer`}
              />
              <HelperMessage hasPadding>
                <p>{t`Let's start designing your Warehouse`}</p>
              </HelperMessage>
              <header
                data-component="import-helper-group"
                className={`flex items-center flex-col lg:flex-row lg:space-x-4 ${
                  isDemo && 'hidden'
                }`}
              />
              <DemoHelper />
            </ItemListCard>
            <DataDashboardLayout />
            {/* <DataDashboardLayoutImports /> */}
          </ContainerScroll>
        </ContainerCol>
      </ContainerMain>
    </WarehouseContainer>
  );
};

export default WarehouseDesignerDashboard;
