import { ActivityEventProcessType } from '@warebee/shared/import-converter';
import { useTranslation } from 'react-i18next';

export type ActivityEventProcessTypeDetails = {
  id: ActivityEventProcessType;
  title: string;
  description: string;
};

function useActivityEventProcessTypeDetails(): ActivityEventProcessTypeDetails[] {
  const { t } = useTranslation('feed');
  return [
    {
      id: ActivityEventProcessType.PICKING,
      title: t`Picking`,
      description: t`Selecting items from inventory to fulfill orders.`,
    },
    {
      id: ActivityEventProcessType.REPLENISHMENT,
      title: t`Replenishment`,
      description: t`Restocking picking locations from bulk storage.`,
    },
    {
      id: ActivityEventProcessType.REASSIGN,
      title: t`Reassign`,
      description: t`Changing the task or location of items or resources.`,
    },
    {
      id: ActivityEventProcessType.PUTAWAY,
      title: t`Putaway`,
      description: t`Storing received goods in their designated location.`,
    },
    {
      id: ActivityEventProcessType.PACKING,
      title: t`Packing`,
      description: t`Preparing items for shipment.`,
    },
    {
      id: ActivityEventProcessType.STACKING,
      title: t`Stacking`,
      description: t`Arranging items vertically for storage or transport.`,
    },
    {
      id: ActivityEventProcessType.DESTACKING,
      title: t`Destacking`,
      description: t`Removing items from a stacked arrangement.`,
    },
    {
      id: ActivityEventProcessType.RECEIVING,
      title: t`Receiving`,
      description: t`Accepting and processing incoming goods.`,
    },
    {
      id: ActivityEventProcessType.MOVE,
      title: t`Move (Internal transfer)`,
      description: t`Relocating items within the warehouse.`,
    },
    {
      id: ActivityEventProcessType.ALLOCATE,
      title: t`Allocate`,
      description: t`Assigning inventory to specific orders or purposes.`,
    },
    {
      id: ActivityEventProcessType.STAGING,
      title: t`Staging`,
      description: t`Preparing and organizing items for the next process.`,
    },
    {
      id: ActivityEventProcessType.LOADING,
      title: t`Loading`,
      description: t`Placing items onto transport vehicles.`,
    },
    {
      id: ActivityEventProcessType.UNLOADING,
      title: t`Unloading`,
      description: t`Removing items from transport vehicles.`,
    },
    {
      id: ActivityEventProcessType.SLOTTING,
      title: t`Slotting`,
      description: t`Optimizing item placement in storage locations.`,
    },
    {
      id: ActivityEventProcessType.DISPATCH,
      title: t`Dispatch`,
      description: t`Sending out items or vehicles for delivery.`,
    },
    {
      id: ActivityEventProcessType.SORTING,
      title: t`Sorting`,
      description: t`Organizing items into groups or categories.`,
    },
    {
      id: ActivityEventProcessType.HANDLING,
      title: t`Handling`,
      description: t`General manipulation of items.`,
    },
    {
      id: ActivityEventProcessType.HANDLING_SPECIAL,
      title: t`Handling Special`,
      description: t`Handling items requiring special care.`,
    },
    {
      id: ActivityEventProcessType.HANDLING_HAZMAT,
      title: t`Handling Hazmat`,
      description: t`Handling hazardous materials.`,
    },
    {
      id: ActivityEventProcessType.HANDLING_EXCEPTION,
      title: t`Handling Exception`,
      description: t`Dealing with non-standard handling situations.`,
    },
    {
      id: ActivityEventProcessType.QUALITY_CONTROL,
      title: t`Quality Control`,
      description: t`Inspecting and verifying item quality.`,
    },
    {
      id: ActivityEventProcessType.INVENTORY,
      title: t`Inventory`,
      description: t`Managing and tracking stock levels.`,
    },
    {
      id: ActivityEventProcessType.INVENTORY_COUNT,
      title: t`Inventory Count`,
      description: t`Physically counting stock to ensure accuracy.`,
    },
    {
      id: ActivityEventProcessType.VALUE_ADD,
      title: t`Value Add`,
      description: t`Performing additional services to increase product value.`,
    },
    {
      id: ActivityEventProcessType.KITTING,
      title: t`Kitting`,
      description: t`Assembling individual items into ready-to-ship kits.`,
    },
    {
      id: ActivityEventProcessType.CONSOLIDATION,
      title: t`Consolidation`,
      description: t`Combining multiple items or shipments.`,
    },
    {
      id: ActivityEventProcessType.DECONSOLIDATION,
      title: t`Deconsolidation`,
      description: t`Separating combined shipments into individual orders.`,
    },
    {
      id: ActivityEventProcessType.LABELING,
      title: t`Labeling`,
      description: t`Applying labels or tags to items.`,
    },
    {
      id: ActivityEventProcessType.REPAIR,
      title: t`Repair`,
      description: t`Fixing damaged items.`,
    },
    {
      id: ActivityEventProcessType.MARSHALLING,
      title: t`Marshalling`,
      description: t`Organizing and arranging items or vehicles.`,
    },
    {
      id: ActivityEventProcessType.RETURNS_PROCESSING,
      title: t`Returns Processing`,
      description: t`Handling and processing returned items.`,
    },
    {
      id: ActivityEventProcessType.SHIPPING,
      title: t`Shipping`,
      description: t`Sending out goods to customers.`,
    },
    {
      id: ActivityEventProcessType.TRANSPORT,
      title: t`Transport`,
      description: t`Moving goods between locations.`,
    },
    {
      id: ActivityEventProcessType.MAINTENANCE,
      title: t`Maintenance`,
      description: t`Upkeep of equipment and facilities.`,
    },
    {
      id: ActivityEventProcessType.CROSSDOCKING,
      title: t`Crossdocking`,
      description: t`Transferring items directly from inbound to outbound shipping.`,
    },
    {
      id: ActivityEventProcessType.MANAGEMENT,
      title: t`Management`,
      description: t`General management activities.`,
    },
    {
      id: ActivityEventProcessType.MANAGEMENT_YARD,
      title: t`Yard Management`,
      description: t`Managing the yard area and vehicle movements.`,
    },
    {
      id: ActivityEventProcessType.MANAGEMENT_DATA,
      title: t`Data Management (System)`,
      description: t`Handling and processing warehouse data.`,
    },
    {
      id: ActivityEventProcessType.MANAGEMENT_WMS,
      title: t`WMS Management (System)`,
      description: t`Activities related to the Warehouse Management System.`,
    },
    {
      id: ActivityEventProcessType.SYSTEM,
      title: t`System`,
      description: t`Automated system processes.`,
    },
    {
      id: ActivityEventProcessType.IDENTIFICATION,
      title: t`Identification`,
      description: t`Identifying or verifying items or locations.`,
    },
    {
      id: ActivityEventProcessType.OTHER,
      title: t`Other`,
      description: t`Activities not covered by other categories.`,
    },
    {
      id: ActivityEventProcessType.IGNORE,
      title: t`Ignore`,
      description: t`Activities to be excluded from processing or reporting.`,
    },
  ];
}

export default useActivityEventProcessTypeDetails;
