import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../../common/formatHelper';

export type ProgressBarProps = {
  children?: React.ReactNode;
  className?: string;
  progress?: FormattedValueWithUnit;
  message?: string;
  step?: string;
  action?: string;
  viewMode?: 'full' | 'compact';
};

export const ProgressBar = ({
  className,
  progress,
  message,
  step,
  action,
  viewMode,
}: ProgressBarProps) => {
  const { t } = useTranslation('app');
  const hasProgress = progress.raw > 0;

  return (
    <div
      aria-label="ProgressBar"
      className={classNames('w-full flex-1', className)}
    >
      <div
        className={classNames(
          'mb-1 flex items-center',
          viewMode == 'compact' ? 'p-1' : 'p-2',
        )}
      >
        <div className={classNames('flex flex-1 flex-col')}>
          {viewMode !== 'compact' && (
            <div
              className={classNames(
                'flex flex-1',
                'text-menu-active',
                'text-base xl:text-xl',
              )}
            >
              {step && (
                <>
                  <div className="">{step}</div>
                  <div className="mx-2">{`—`}</div>
                </>
              )}

              <div className="">{action}</div>
            </div>
          )}

          <span
            className={classNames(
              'text-menu-text flex-1',
              viewMode == 'compact' ? 'text-sm' : 'text-xl',
            )}
          >
            {message}
          </span>
        </div>
        <span
          className={classNames(
            hasProgress
              ? viewMode == 'compact'
                ? 'text-sm'
                : 'text-3xl xl:text-4xl'
              : viewMode == 'compact'
                ? 'text-sm'
                : 'text-4xl xl:text-5xl',
            'text-menu-active',
          )}
        >
          {hasProgress ? progress.fullString : t`Please Wait...`}
        </span>
      </div>
      <div
        data-component="ProgressBarBar"
        className={classNames(
          'relative w-full overflow-hidden rounded-full',
          viewMode == 'compact' ? 'h-4' : 'h-6',
          className,
        )}
      >
        <div
          data-component="ProgressBarBackground"
          className={classNames('bg-app-panel-light/50 absolute h-full w-full')}
        />
        <div
          data-component="ProgressBarProgressValue"
          className={classNames(
            'absolute',
            'h-full',
            'flex items-center',
            'text-center',
            'bg-menu-active',
            progress.value === '0' ? 'text-menu-text' : 'text-menu-active-text',
          )}
          style={{
            width: `${progress.value ?? 100}%`,
          }}
        >
          {(hasProgress && (
            <span data-component="value" className={classNames('w-full')}>
              {progress.fullString}
            </span>
          )) || (
            <span className={classNames('w-full px-2')}>{t`Preparing...`}</span>
          )}
        </div>
      </div>
    </div>
  );
};
