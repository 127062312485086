import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export type TagDataTypeProps = PropsWithChildren & {
  className?: string;
  optional?: boolean;
};

export const TagDataType: React.FC<TagDataTypeProps> = props => {
  const { t } = useTranslation('importer');

  return (
    <span
      data-component="TagDataType"
      className={classNames(
        'flex items-center justify-center',
        'px-1 py-0.5',
        'ltr:ml-2 rtl:mr-2',
        'text-minimal',
        'rounded',
        'bg-app-panel-dark/50',
        props.className,
      )}
    >
      {props.children}
    </span>
  );
};
