import { BarTooltipProps } from '@nivo/bar';
import classNames from 'classnames';
import format from 'date-fns/format';
import isWeekend from 'date-fns/isWeekend';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../../common/color.helper';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import { TooltipItem } from '../../components/stats/TooltipItem';
import { AgentPerformanceDatum } from './AgentPerformanceChart';
import { getAgentIdFromKey } from './agentPerformance.helper';

export type AgentPerformanceTooltipProps = {
  datum: BarTooltipProps<AgentPerformanceDatum>;
  totalX: number;
  // series: string[];

  formatValue: (value: number) => FormattedValueWithUnit;
  getSerieTitle: (value: string) => string;
  // getDimensionTitle: (dimensionKey: string) => string;
  // getDimensionColor: (dimensionKey: string) => [string, string];
};

const AgentPerformanceTooltip: React.FC<
  AgentPerformanceTooltipProps
> = props => {
  const { datum } = props;
  const { t } = useTranslation('simulation');

  const date = _.isNaN(datum.data.date) ? null : new Date(datum.data.date);
  const weekend = _.isNil(date) ? false : isWeekend(date);
  const showOnLeft = props.totalX > 0 && datum.index / props.totalX > 0.66;
  const agentId = getAgentIdFromKey(datum.id as string);

  const dayTitleShort = _.isNil(date)
    ? t`No Date`
    : format(date, 'dd MMMM, yyyy');
  const weekdayTitle = _.isNil(date) ? null : format(date, 'EE');
  return (
    <div
      key={datum.id}
      data-component="AgentTimelineTooltip"
      className={classNames(
        'bg-app-panel-dark/90 backdrop-filter backdrop-blur absolute text-sm whitespace-nowrap',
      )}
      style={{
        left: showOnLeft ? undefined : 16,
        right: showOnLeft ? 16 : undefined,
        top: -64,
      }}
    >
      <div className="min-w-tooltip">
        <div
          className={classNames(
            'flex items-center p-2 ltr:pl-3 rtl:pr-3 text-xs bg-menu-active text-menu-active-text uppercase',
          )}
        >
          {t`Day Analysis`}
        </div>
        <div
          className={classNames(
            'flex items-center py-2 px-2 text-sm font-bold text-start bg-app-panel-dark',
          )}
        >
          <span className={classNames('ltr:pl-1 rtl:pr-1 flex-1')}>
            {dayTitleShort}
          </span>
          {weekdayTitle && (
            <span
              className={classNames(
                'ml-3 py-0.5 px-2 rounded uppercase border',
                weekend ? 'border-menu/40 text-menu-active' : 'border-menu',
              )}
            >
              {format(date, 'EE')}
            </span>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'px-1',
          'divide-y divide-menu/50',
          'border-t border-menu',
        )}
      >
        <TooltipItem
          key={datum.id}
          isActive={true}
          title={props.getSerieTitle(agentId)}
          value={props.formatValue(datum.value).fullString}
          color={getQualitativeColor(agentId, 'policy')[0]}
          //   backgroundColor={eventGroupsColor.overtime}
          tooltipLegend
        />
      </div>
    </div>
  );
};

export default AgentPerformanceTooltip;
