import classNames from 'classnames';
import _ from 'lodash';
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useId } from 'react-aria';
import { useTranslation } from 'react-i18next';

export type InputNumberProps = {
  value: number;

  title?: string;
  unitOfMeasure?: string;
  ariaLabel?: string;

  icon?: React.ReactNode;

  className?: string;
  inputClassName?: string;

  disabled?: boolean;
  isTransparent?: boolean;
  labelVertical?: boolean;
  range?: [number | null, number | null];
  placeholder?: string;
  debounceInterval?: number;
  onChange?: (value: number) => Promise<void> | void;
};

export const InputNumber: React.FC<InputNumberProps> = props => {
  const { t } = useTranslation('app');
  const id = useId();
  const ariaLabel = useId(props.ariaLabel);
  const [value, setValue] = useState(props.value);
  const [isValid, setValid] = useState(true);
  const [validationMsg, setValidationMsg] = useState('');

  const hasLabel = !_.isEmpty(props.title);
  const label = props.title ?? '';

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = event => {
    // if (event.key === 'Enter') {
    //   onEndEdit((event.target as HTMLInputElement).value);
    // }
    if (event.key === 'Escape') {
      debouncedChange.cancel();
      setValid(true);
      setValue(props.value);
    }
  };

  function validate(value: number): boolean {
    const [min, max] = props.range ?? [];

    setValid(true);

    if (Number.isNaN(value)) {
      setValid(false);
      setValidationMsg(t`Value can not be empty`);
      return false;
    } else if (!_.isNil(min) && value < min) {
      setValid(false);
      setValidationMsg(t(`Value should be >= {{min}}`, { min }));
      return false;
    } else if (!_.isNil(max) && value > max) {
      setValid(false);
      setValidationMsg(t(`Value should be <= {{max}}`, { max }));
      return false;
    }
    return true;
  }

  const debouncedChange = useCallback(
    _.debounce(async (value: number) => {
      try {
        //console.log('Call debounced function');
        await props.onChange(value);
      } catch (ex) {
        setValid(false);
      }
    }, props.debounceInterval ?? 800),
    [id, props.onChange],
  );

  const isValueApplied = Math.abs(props.value - value) < Number.EPSILON;

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    //reset validation state
    setValid(true);

    // validate
    const numeric = parseFloat(e.target.value);
    const validationRes = validate(numeric);

    setValue(numeric);

    //call debounce nonChange only for valid values
    if (validationRes) {
      debouncedChange(numeric);
    } else {
      debouncedChange.cancel();
    }
  }

  return (
    <div
      data-component={`InputNumber`}
      className={classNames(
        `flex items-center flex-1`,
        { 'flex-col': props.labelVertical },
        props.className,
      )}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <label
        data-component={`InputNumberLabel`}
        data-label={`input-number-${label}`}
        aria-label={ariaLabel}
        htmlFor={id}
        className={classNames(
          'flex w-full mb-0 text-xs font-bold text-menu-text items-center',
          {
            'flex-col': props.labelVertical,
            'px-0.5 flex-1': props.title,
          },
        )}
      >
        {!_.isNil(props.icon) && (
          <div
            className={classNames(
              'w-10 h-10',
              'p-1',
              'm-1 ml-0 ltr:mr-2 rtl:ml-2',
              'text-menu-active-text',
              'flex items-center',
              'hover:bg-menu-active hover:text-menu-active-text bg-menu-button',
              'shadow',
              'rounded',
            )}
          >
            {props.icon}
          </div>
        )}

        {hasLabel && (
          <div
            className={classNames(props.labelVertical ? 'w-full' : 'flex-1')}
          >
            <span
              className={classNames(
                'text-xs uppercase',
                'text-menu-text',
                'ltr:mr-1 rtl:ml-1',
                {
                  'w-full': props.labelVertical,
                },
              )}
            >
              {label}
            </span>
            {!_.isNil(props.unitOfMeasure) && (
              <span
                className={classNames(
                  'ltr:ml-1 rtl:mr-1',
                  'text-xs text-menu-text/50',
                )}
              >
                <span className={classNames('opacity-50')}>(</span>
                {props.unitOfMeasure}
                <span className={classNames('opacity-50')}>)</span>
              </span>
            )}
          </div>
        )}

        <input
          id={id}
          className={classNames(
            'flex-1 w-full min-w-10',
            'p-1',
            'border-transparent',
            props.labelVertical ? 'w-full' : 'max-w-ch-10',
            props.disabled
              ? 'text-menu-text/60 bg-app-panel/60 bg-opacity-50'
              : props.isTransparent
                ? ''
                : 'bg-app-input/90 active:bg-app-input focus:bg-app-input/75 text-menu-text',
            {
              'ltr:ml-1 rtl:mr-1': hasLabel,
              'text-menu-text/60': isValid && !isValueApplied,
              'text-alerts-error border-alerts-error focus:ring-alerts-error focus:border-alerts-error':
                !isValid,
            },
            props.isTransparent
              ? 'bg-opacity-0 focus:bg-opacity-0 focus:outline-none active:outline-none focus:ring-0 active:ring-0 ring-0'
              : '',
            props.inputClassName,
          )}
          type={'number'}
          //inputMode={'numeric'}
          //pattern={'[0-9]*[.,]?[0-9]+'}
          disabled={props.disabled}
          value={Number.isNaN(value) ? '' : value}
          placeholder={props.placeholder}
          onChange={onChange}
          title={isValid ? null : validationMsg}
          // onBlur={e => {
          //   onEndEdit && onEndEdit(e.target.value);
          // }}
          onKeyUp={handleKeyPress}
        />
      </label>
    </div>
  );
};

export default InputNumber;
