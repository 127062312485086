import _ from 'lodash';
import React from 'react';

type AppInfo = {
  name: string;
  url: string;
};

const appLinksData: Record<string, AppInfo> = {
  authy: {
    name: 'Authy',
    url: 'https://authy.com/',
  },
  onePassword: {
    name: '1Password',
    url: 'https://1password.com/',
  },
  googleAuthenticatorAndroid: {
    name: 'Google Authenticator (Android)',
    url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US',
  },
  googleAuthenticatorIOS: {
    name: 'Google Authenticator (iOS)',
    url: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  },
  microsoftAuthenticator: {
    name: 'Microsoft Authenticator',
    url: 'https://www.microsoft.com/en-us/account/authenticator',
  },
};

const TOTPAppLinks: React.FC = () => {
  const count = _.size(appLinksData);

  return (
    <>
      {_.map(_.values(appLinksData), (app, index) => (
        <>
          {' '}
          <a
            key={index}
            href={app.url}
            className="hover:text-menu-active-light any-hover:hover:underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            {app.name}
          </a>
          {index < count - 1 && ','}
        </>
      ))}
    </>
  );
};

export default TOTPAppLinks;
