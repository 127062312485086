import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardActionbar } from '../../components/DashboardActionbar';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ContainerMain } from '../../components/layout/ContainerMain';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import TitleSection from '../../components/layout/TitleSection';
import WarehouseContainer from '../WarehouseContainer';
import { BetaContracts } from './cards/BetaContracts';

export const WarehouseDashboardContracts: React.FC = () => {
  const { t } = useTranslation('app-beta');

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar />
      <ContainerMain className="flex">
        <ContainerCol overflow>
          <ContainerScroll className="pt-0 pb-24">
            <TitleSection title={t`Contracts`} />
            <BetaContracts />
          </ContainerScroll>
        </ContainerCol>
      </ContainerMain>
    </WarehouseContainer>
  );
};

export default WarehouseDashboardContracts;
