import * as React from 'react';
const PolicyWaveRouteTruckTimeGateIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaveRouteTruckTimeGateIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M11.54,36.6h-2.48c.35,6.25,2.85,11.93,6.77,16.32l1.74-1.74c.6-.6,1.56-.6,2.16,0,.6.6.6,1.56,0,2.16l-1.74,1.74c4.39,3.92,10.07,6.42,16.32,6.77v-2.48c0-.84.68-1.53,1.53-1.53s1.53.68,1.53,1.53v2.48c6.25-.35,11.93-2.85,16.32-6.77l-1.74-1.74c-.6-.6-.6-1.56,0-2.16s1.56-.6,2.16,0l1.74,1.74c3.92-4.39,6.42-10.07,6.77-16.32h-2.48c-.84,0-1.53-.68-1.53-1.53s.68-1.53,1.53-1.53h2.48c-.35-6.25-2.85-11.93-6.77-16.32l-1.74,1.74c-.3.3-.69.45-1.08.45s-.78-.15-1.08-.45c-.6-.6-.6-1.56,0-2.16l1.74-1.74c-4.39-3.92-10.07-6.42-16.32-6.77v2.48c0,.84-.68,1.53-1.53,1.53s-1.53-.68-1.53-1.53v-2.48c-6.25.35-11.93,2.85-16.32,6.77l1.74,1.74c.6.6.6,1.56,0,2.16-.6.6-1.56.6-2.16,0l-1.74-1.74c-3.92,4.39-6.42,10.07-6.77,16.32h2.48c.84,0,1.53.68,1.53,1.53s-.68,1.53-1.53,1.53ZM23.62,24.12c.6-.6,1.56-.6,2.16,0l11.36,12.15,7.22-5.89c.75-.38,1.67-.07,2.05.68.38.75.07,1.67-.68,2.05l-8.2,6.38c-.22.11-.45.16-.68.16-.4,0-.79-.15-1.08-.45l-12.14-12.93c-.6-.6-.6-1.56,0-2.16Z"
    />
    <path
      strokeWidth={0}
      d="M95.22,57.92s.06.02.17.04c-.05-.02-.11-.04-.17-.04Z"
    />
    <path
      strokeWidth={0}
      d="M110.25,105.19c-4.28,0-7.76,3.48-7.76,7.76,0,.56.06,1.11.17,1.63.75,3.5,3.87,6.13,7.59,6.13s6.83-2.63,7.58-6.13c.11-.53.17-1.07.17-1.63,0-4.28-3.48-7.76-7.76-7.76ZM111.55,114.59c-.36.28-.8.45-1.29.45s-.93-.17-1.3-.45c-.48-.38-.79-.97-.79-1.63,0-1.15.94-2.09,2.09-2.09s2.09.94,2.09,2.09c0,.66-.31,1.26-.79,1.63Z"
    />
    <path
      strokeWidth={0}
      d="M125.26,109.58v-22.78l-3.37-14.91c-3.48-8.07-15.18-9.91-15.18-9.91-1.27-1.48-10-3.69-11.32-4.02.91.47.96,4.66.96,4.66v40.22s-5.4,0-5.4,0v-44.92s0-4.71-4.49-4.71h-22.75c-5.97,9.05-16.22,15.03-27.87,15.03s-21.9-5.98-27.87-15.03H3v49.64h9.75v2.06h-6.56v8.05h40.65v-8.05h-3.65v-2.06h5.88l.84,3.5v3.1s-.73.42-.73,1.34h3.1c0-.92-.73-1.34-.73-1.34v-3.1l.84-3.5h7.61c-.39,0-.71.31-.71.71v10.33c0,.39.32.71.71.71h2.26c-.1-.53-.16-1.08-.16-1.63,0-2.23.87-4.31,2.44-5.89,1.57-1.57,3.66-2.44,5.88-2.44s4.31.87,5.89,2.44c1.58,1.57,2.44,3.66,2.44,5.89,0,.55-.05,1.11-.16,1.63h23.5c-.11-.53-.16-1.08-.16-1.63,0-2.23.87-4.31,2.44-5.89,1.57-1.57,3.66-2.44,5.89-2.44s4.31.87,5.88,2.44c1.57,1.57,2.44,3.66,2.44,5.89,0,.55-.06,1.11-.16,1.63h4.9c2.35,0,1.94-5,1.94-5ZM41.14,104.9H14.38v-2.06h26.76v2.06ZM110.52,84.68c0,.39-.31.71-.71.71h-4.4c-.39,0-.71-.31-.71-.71v-9.8c0-.39.32-.71.71-.71h4.4c.39,0,.71.32.71.71v9.8ZM113.76,94.11h-1.65c-.38,0-.7-.31-.7-.7s.31-.7.7-.7h1.65c.39,0,.7.31.7.7s-.31.7-.7.7ZM123.37,90.59s.05,1.89-1.35,1.89h-2.07l-7.05-5.77s-.97-.56-.97-2.15v-8.94s-.2-1.68,1.58-1.68h7.15l2.71,11.38v5.26Z"
    />
    <path
      strokeWidth={0}
      d="M70.43,105.19c-4.28,0-7.76,3.48-7.76,7.76,0,.56.06,1.11.17,1.63.75,3.5,3.87,6.13,7.58,6.13s6.83-2.63,7.59-6.13c.12-.53.17-1.07.17-1.63,0-4.28-3.48-7.76-7.76-7.76ZM71.72,114.59c-.36.28-.81.45-1.3.45s-.94-.17-1.29-.45c-.49-.38-.79-.97-.79-1.63,0-1.15.93-2.09,2.08-2.09s2.09.94,2.09,2.09c0,.66-.31,1.26-.79,1.63Z"
    />
  </svg>
);
export default PolicyWaveRouteTruckTimeGateIcon;
