import * as React from 'react';
const SortDescendingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortDescendingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M68.55,26.85v-7.43c0-1.24-1.24-2.48-2.48-2.48h-14.58c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h14.58c1.24,0,2.48-1.24,2.48-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M68.55,51.62v-7.43c0-1.24-1.24-2.48-2.48-2.48h-24.58c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h24.58c1.24,0,2.48-1.24,2.48-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M68.55,76.39v-7.43c0-1.24-1.24-2.48-2.48-2.48H31.49c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h34.58c1.24,0,2.48-1.24,2.48-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M68.55,101.16v-7.43c0-1.24-1.24-2.48-2.48-2.48H21.49c-1.24,0-2.48,1.24-2.48,2.48v7.43c0,1.24,1.24,2.48,2.48,2.48h44.58c1.24,0,2.48-1.24,2.48-2.48Z"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M109.72,81h4.69c2.07,0,3.02,1.5,2.09,3.35l-17.34,23.69c-.91,1.83-2.43,1.85-3.35,0l-17.34-23.69c-.92-1.83.01-3.35,2.09-3.35h4.69c2.07,0,3.77-1.68,3.77-3.75V19.89c0-2.07,1.68-3.75,3.76-3.75h9.42c2.07,0,3.76,1.68,3.76,3.75v57.36c0,2.07,1.69,3.75,3.77,3.75h0Z"
    />
  </svg>
);
export default SortDescendingIcon;
