import * as React from 'react';
const WarehouseMediumIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseMediumIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="125.16 48.71 125.16 43.69 64.5 27.26 2.84 43.69 2.84 48.71 64.5 32.28 125.16 48.71"
    />
    <path
      strokeWidth={0}
      d="M64.5,35.73L2.84,52.16v48.58h22.75v-1.61h31.61v1.61h13.61v-1.61h31.61v1.61h22.75v-48.58l-60.66-16.43ZM57.2,97.65h-31.61v-2.65h31.61v2.65ZM57.2,93.52h-31.61v-2.65h31.61v2.65ZM57.2,89.29h-31.61v-2.65h31.61v2.65ZM57.2,85.17h-31.61v-2.65h31.61v2.65ZM57.2,81.04h-31.61v-2.65h31.61v2.65ZM102.41,97.65h-31.61v-2.65h31.61v2.65ZM102.41,93.52h-31.61v-2.65h31.61v2.65ZM102.41,89.29h-31.61v-2.65h31.61v2.65ZM102.41,85.17h-31.61v-2.65h31.61v2.65ZM102.41,81.04h-31.61v-2.65h31.61v2.65Z"
    />
  </svg>
);
export default WarehouseMediumIcon;
