import Flatten from '@flatten-js/core';

export class LayoutTransformation {
  constructor(
    readonly m: Flatten.Matrix,
    readonly flipAndRotateOnly: Flatten.Matrix,
    readonly flipDirections: boolean,
  ) {}
}

export function transformVector(
  v: Flatten.Vector,
  m: Flatten.Matrix,
): Flatten.Vector {
  const [x, y] = m.transform([v.x, v.y]);
  return Flatten.vector(x, y);
}

/**
 * Computes inverse of the given transformation matrix.
 * @param m
 * @returns
 */
export function inverseMatrix(m: Flatten.Matrix): Flatten.Matrix {
  // compute inverse of the given matrix using Gaussian elimination
  const v = [
    [m.a, m.c, m.tx, 1, 0, 0],
    [m.b, m.d, m.ty, 0, 1, 0],
    [0, 0, 1, 0, 0, 1],
  ];
  const n = 3;

  function swapRows(i: number, j: number) {
    const t = v[i];
    v[i] = v[j];
    v[j] = t;
  }

  function addRow(dest: number[], src: number[], r = 1) {
    for (const i in dest) {
      dest[i] += src[i] * r;
    }
  }

  function mulRow(row: number[], r = 1) {
    for (const i in row) {
      row[i] *= r;
    }
  }

  for (let i = 0; i < n; i++) {
    if (v[i][i] == 0) {
      let j = i + 1;
      for (; j < n; j++) {
        if (v[j][i] != 0) {
          swapRows(i, j);
          break;
        }
      }
      if (j == n) {
        throw new Error('not invertible matrix');
      }
    }

    if (v[i][i] != 1) {
      mulRow(v[i], 1 / v[i][i]);
    }

    for (let j = 0; j < n; j++) {
      if (i == j) continue;
      if (v[j][i] != 0) {
        addRow(v[j], v[i], -v[j][i]);
      }
    }
  }

  const [[a, c, tx, ia, ic, itx], [b, d, ty, ib, id, ity]] = v;

  return Flatten.matrix(ia, ib, ic, id, itx, ity);
}
