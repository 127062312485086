import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import * as Icon from '../icons';

export type StatDiffIconProps = {
  before: FormattedValueWithUnit;
  after: FormattedValueWithUnit;
  className?: string;
};

const StatDiffIcon: React.FC<StatDiffIconProps> = props => {
  if (_.isNil(props.before) || _.isNil(props.after)) {
    return null;
  }

  const commonClasses = `fill-current ltr:ml-1 rtl:mr-1 ltr:mr-0.5 rtl:ml-0.5`;

  if (props.before.raw > props.after.raw) {
    return (
      <Icon.ArrowRightBottom
        className={classNames(commonClasses, '', props.className)}
      />
    );
  }

  if (props.before.raw < props.after.raw) {
    return (
      <Icon.ArrowRightTop
        className={classNames(commonClasses, '', props.className)}
      />
    );
  }

  return (
    <Icon.ArrowRight
      className={classNames(commonClasses, 'opacity-0', props.className)}
    />
  );
};

export default StatDiffIcon;
