import {
  GenerateItemSetIssueSetDocument,
  GenerateItemSetIssueSetMutation,
  GenerateItemSetIssueSetMutationVariables,
  LoadItemSetIssueSetDocument,
  LoadItemSetIssueSetQuery,
  LoadItemSetIssueSetQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  simulationItemSetIssues,
  simulationItemSetIssuesLoadStatus,
} from '../store/simulation.issues.state';

export type LoadItemSetIssuesParams = {
  assignmentId: string;
  itemSetId: string;
};

function useLoadItemSetIssues() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load ItemSet Issues`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadItemSetIssuesParams) => {
        if (_.isNil(params.itemSetId) || _.isNil(params.assignmentId)) {
          return;
        }
        set(simulationItemSetIssuesLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadItemSetIssuesParams) => {
        if (_.isNil(params.itemSetId) || _.isNil(params.assignmentId)) {
          return;
        }

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationItemSetIssuesLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const key = {
          assignmentId: params.assignmentId,
          itemSetId: params.itemSetId,
        };

        const query = secureClient.watchQuery<
          LoadItemSetIssueSetQuery,
          LoadItemSetIssueSetQueryVariables
        >({
          query: LoadItemSetIssueSetDocument,
          variables: { key },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            const issuesSet = data?.itemSetIssueSet;

            if (_.isNil(issuesSet)) {
              const runResult = await secureClient.mutate<
                GenerateItemSetIssueSetMutation,
                GenerateItemSetIssueSetMutationVariables
              >({
                mutation: GenerateItemSetIssueSetDocument,
                variables: {
                  key,
                },
              });
              set(
                simulationItemSetIssues,
                runResult?.data?.generateItemSetIssueSet,
              );
              set(simulationItemSetIssuesLoadStatus, AsyncLoadStatus.Ok);
            } else {
              set(simulationItemSetIssues, issuesSet);
              set(simulationItemSetIssuesLoadStatus, AsyncLoadStatus.Ok);
            }
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadItemSetIssuesParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadItemSetIssues;
