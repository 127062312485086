import { useApolloClient } from '@apollo/client';
import {
  BatchJobStatus,
  LoadAllocationRequirementMetaDocument,
  LoadAllocationRequirementMetaQuery,
  LoadAllocationRequirementMetaQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  allocationRequirement,
  allocationRequirementLoadStatus,
} from '../store/allocation/allocationRequirement.state';

export type LoadAllocationRequirementsParams = {
  allocationRequirementId: string;
};

function useLoadAllocationRequirementsMeta() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load ordered quantity report`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(allocationRequirementLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadJob = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAllocationRequirementsParams) => {
        const query = client.watchQuery<
          LoadAllocationRequirementMetaQuery,
          LoadAllocationRequirementMetaQueryVariables
        >({
          query: LoadAllocationRequirementMetaDocument,
          variables: {
            allocationRequirementId: params.allocationRequirementId,
          },
        });

        function handleError(details?, stack?) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(allocationRequirementLoadStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          async ({ data, errors }) => {
            if (errors) {
              const stack = errors.map(e => e.message).join('. ');
              handleError(null, stack);
              return;
            }

            const job = data?.allocationRequirementSet;
            if (
              job?.status === BatchJobStatus.FAILED ||
              job?.status === BatchJobStatus.TERMINATED
            ) {
              handleError();
            }

            set(allocationRequirement, job);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAllocationRequirementsParams) {
    await initLoading();
    await loadJob(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadAllocationRequirementsMeta;
