import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { HelperMessage } from '../HelperMessage';

export type ContainerSectionSettingsProps = {
  children?: React.ReactNode;
  childrenStart?: React.ReactNode;
  childrenEnd?: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  isHero?: boolean;
};

export const ContainerSectionSettings = ({
  children,
  className,
  title,
  description,
  childrenStart,
  childrenEnd,
  isHero,
}: ContainerSectionSettingsProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <section
      id={id}
      data-component="ContainerSectionSettings"
      className={classNames('flex flex-col lg:flex-row', 'py-3', className)}
    >
      <div
        data-component="titleDescription"
        className={classNames(
          'flex flex-1 w-full max-w-full xl:max-w-1/3 p-2',
          'ltr:mr-0 md:ltr:mr-10',
          'rtl:ml-0 md:rtl:ml-10',
          { 'items-center justify-center': childrenStart },
          { 'flex-col': !childrenStart },
        )}
      >
        {childrenStart ? (
          childrenStart
        ) : (
          <div className={classNames('sticky top-40')}>
            {title && (
              <h3 className={classNames('text-menu-h3 text-2xl mb-4')}>
                {title}
              </h3>
            )}
            {description && (
              <HelperMessage className={classNames('flex-1 pb-4')}>
                {description}
              </HelperMessage>
            )}
          </div>
        )}
      </div>

      <div
        data-component="settingsGroup"
        className={classNames(
          'flex flex-col flex-1',
          {
            'p-6 bg-app-panel/30 text-app-panel-light-text rounded': !isHero,
          },
          { 'divide-y divide-menu-text/10': !isHero },
        )}
      >
        {childrenEnd ? childrenEnd : children}
      </div>
    </section>
  );
};
