import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import PolicyWarnings from '../components/policies/PolicyWarnings';
import { resourcePolicyIsReadyToAnalyze } from '../simulation/store/resourcePolicy.state';

const ResourcePolicyWarnings: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('simulation');
  const resourcePolicyStatus = useRecoilValue(resourcePolicyIsReadyToAnalyze);

  const getMessages = () => {
    const messages = [];
    if (!resourcePolicyStatus.hasPickingRole) {
      messages.push(t`Should have at least one agent with picking role`);
    }
    if (!resourcePolicyStatus.hasReassignRole) {
      messages.push(t`Should have at least one agent with reassign role`);
    }
    return messages;
  };
  const messages = getMessages();

  // return null if no warnings
  if (_.isEmpty(messages)) return null;

  return <PolicyWarnings messages={getMessages()} />;
};

export default ResourcePolicyWarnings;
