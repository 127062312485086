import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { UnitOfMeasure } from './UnitOfMeasure';
// import * as Icon from '../components/icons';

const barItems = [
  {
    title: `A+`,
    label: `A+`,
    value: 50,
    range: [0, 50],
    class: 'bg-categoryABC-S',
    classText: 'text-menu-text',
  },
  {
    title: `A`,
    label: `A`,
    value: 80,
    range: [50, 80],
    class: 'bg-categoryABC-A',
    classText: 'text-menu-text',
  },
  {
    title: `B`,
    label: `B`,
    value: 95,
    range: [80, 95],
    class: 'bg-categoryABC-B',
    classText: 'text-menu-text',
  },
  {
    title: `C`,
    label: `C`,
    value: 100,
    range: [95, 100],
    class: 'bg-categoryABC-C',
    classText: 'text-menu-text',
  },
];

export type BarPercentageProps = {
  className?: string;
  classNameSegment?: string;
  children?: React.ReactNode;
  unitOfMeasure?: string | number | React.ReactNode;
  isDisabled?: boolean;
  hasTickValue?: boolean;
  classSize?: string;
  style?: React.CSSProperties;
};

// const barItemList = (
//   <ol data-component="barItemList" className="flex">
//     {barItems.map(item => (
//       <li key={item.title} className="py-2 ltr:pl-2 rtl:pr-2">
//         {item.title}
//       </li>
//     ))}
//   </ol>
// );

export const BarPercentage = ({
  className,
  classNameSegment: classNameItem,
  children,
  unitOfMeasure,
  isDisabled,
  classSize,
  style,
  hasTickValue,
}: BarPercentageProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="BarPercentage"
      className={classNames(
        'w-full p-1',
        // unitOfMeasure ? 'mx-0.5' : '',
        children ? 'mx-0.5' : '',
        classSize ? classSize : 'text-xxs',
        // isSelected ? 'text-menu-700' : 'opacity-70',
        // { 'backdrop-grayscale': isDisabled },
        className,
      )}
    >
      <ol data-component="barItemList" className="flex w-full flex-1">
        {barItems.map(item => (
          <li
            data-component={`${item.title}`}
            title={`${item.title}`}
            key={item.title}
            style={{
              width: `${item?.range[1] - item?.range[0]}%`,
              // background: item?.color,
              // color: item?.textColor,
            }}
            className={classNames(
              'h-6 flex items-center',
              isDisabled
                ? 'bg-app-panel-dark/60 border-r border-app-panel-dark'
                : item.class,
              isDisabled ? 'text-menu-100' : item.classText,
            )}
          >
            {item.label && <span className="p-1">{item.label}</span>}
          </li>
        ))}
      </ol>

      {hasTickValue && (
        <ol
          data-component="barItemList"
          className="flex w-full flex-1 truncate"
        >
          {barItems.map(item => (
            <li
              data-component={`${item.title}`}
              title={`${item.title}`}
              key={item.title}
              style={{
                width: `${item?.range[1] - item?.range[0]}%`,
                // background: item?.color,
                // color: item?.textColor,
              }}
              className={classNames(
                'h-6 flex items-end border-l border-menu-400/50 truncate ltr:justify-end rtl:justify-start px-0.5',
                isDisabled ? 'text-menu-100' : 'text-menu-text',
              )}
            >
              <span className="truncate">{item.value}</span>
            </li>
          ))}
        </ol>
      )}

      {unitOfMeasure && <UnitOfMeasure unitOfMeasure={unitOfMeasure} />}
      {children}
    </div>
  );
};
