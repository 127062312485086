import classNames from 'classnames';
import React from 'react';
import * as Icon from '../icons';

export type TableTdProps = {
  children?: React.ReactNode;
  className?: string;
  value?: string;
  alignText?: string;
  alignChildren?: string;
  alignCell?: string;
  cellSize?: string;
  hasBorder?: boolean;
  hasStatusAlert?: boolean;
  hasStatusError?: boolean;
  hasStatusWarning?: boolean;
  hasStatusInfo?: boolean;
  hasStatusOkay?: boolean;
  isHeader?: boolean;
  isSelected?: boolean;
  rowSpan?: number;
  colSpan?: number;
  viewAsLine?: boolean;
  viewAsTable?: boolean;
  viewAsFlex?: boolean;
  noWrap?: boolean;
  truncate?: boolean;
  maxWidth?: string;
  hidden?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};

export const TableTd = ({
  children,
  className,
  value,
  alignText,
  alignChildren,
  alignCell,
  cellSize,
  hasBorder,
  hasStatusAlert,
  hasStatusError,
  hasStatusWarning,
  hasStatusInfo,
  hasStatusOkay,
  isHeader,
  isSelected,
  rowSpan,
  colSpan,
  viewAsLine,
  viewAsTable,
  viewAsFlex,
  maxWidth,
  hidden,
  noWrap,
  truncate,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: TableTdProps) => {
  hasBorder = true;
  // viewAsLine = hasBorder = false;

  noWrap = true;

  return (
    <td
      rowSpan={rowSpan}
      colSpan={colSpan}
      className={classNames(
        hasBorder ||
          (viewAsTable &&
            'border-app-panel-dark/20 border-b ltr:border-r rtl:border-l'),
        { 'border-menu-200/40 border-b': viewAsLine },
        { 'px-4 py-2': !cellSize },
        { 'px-4 py-3': cellSize === 'lg' },
        { 'px-4 py-2': cellSize === 'md' },
        { 'p-1 px-2 text-sm': cellSize === 'sm' },
        { 'p-0.5 px-1 text-xs': cellSize === 'xs' },
        alignText === 'right' ? 'text-end' : '',
        { 'text-center': alignText === 'items-center text-center' },
        { 'bg-table-header/70': isHeader },
        { 'bg-table-active/50': isSelected },
        alignCell ? alignCell : 'items-center',
        { hidden: hidden },
        className,
      )}
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      onClick={() => onClick && onClick()}
    >
      {value}
      <div
        data-component="TableTdContainer"
        className={classNames(
          'flex',
          // 'truncate',
          alignChildren === 'right' ? 'text-end' : '',
          { 'items-center': alignChildren === 'items-center text-center' },
        )}
      >
        {hasStatusOkay && (
          <Icon.TriangleOk className="mx-1 h-4 w-4 fill-current text-green-700" />
        )}
        {hasStatusWarning && (
          <Icon.TriangleInfo className="mx-1 h-4 w-4 fill-current text-yellow-800" />
        )}
        {hasStatusAlert && (
          <Icon.TriangleInfo className="mx-1 h-4 w-4 fill-current text-yellow-600" />
        )}
        {hasStatusError && (
          <Icon.TriangleInfo className="mx-1 h-4 w-4 fill-current text-red-600" />
        )}
        {hasStatusInfo && (
          <Icon.CircleInfo className="mx-1 h-4 w-4 fill-current text-white" />
        )}
        <div
          className={classNames(
            'flex-1',
            { truncate: truncate },
            { 'whitespace-pre': noWrap },
            { flex: viewAsFlex },
            maxWidth && 'w-' + maxWidth,
          )}
        >
          {children}
        </div>
      </div>
    </td>
  );
};
