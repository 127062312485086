import _ from 'lodash';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { cn } from '../../../common/utils';
import CopyToClipboardButton from '../../../components/actions/CopyToClipboardButton';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';

export type ImporterSQLPreviewProps = {
  data: Object[];
};

const jsonToSQL = (json: Object): string => {
  const keys = Object.keys(json);
  const columns = keys.map(key => `"${key}"`).join(',\n  ');
  return `
SELECT
  ${columns}
FROM
  ___input___
`;
};

const ImporterSqlPreview: React.FC<ImporterSQLPreviewProps> = props => {
  const { t } = useTranslation('importer');
  const [currentIndex, setCurrentIndex] = useState(0);

  const sqlQuery = jsonToSQL(props.data[currentIndex]);

  return (
    <Container col className="relative">
      <div className="h-full overflow-auto">
        <ActionBar sticky stickyTop>
          <CopyToClipboardButton
            className={cn('rounded-none')}
            // title={t`Copy Query`}
            hasIcon
            hasIconSmall
            value={sqlQuery}
          />
          <Spacer flexspace />
        </ActionBar>
        <Editor
          value={sqlQuery}
          disabled={true}
          onValueChange={_.noop}
          highlight={code => Prism.highlight(code, Prism.languages.sql, 'sql')}
          padding={10}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 12,
            paddingBottom: '3rem',
          }}
        />
      </div>
    </Container>
  );
};

export default ImporterSqlPreview;
