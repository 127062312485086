import { MeasurementSystem } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { WaypointLabeled } from '../../layout/features/features.types';
import RouteFeature from '../../layout/features/RouteFeature';
import { viewerSelectedPlaneId } from '../../layout/viewer/store/viewer.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import {
  simulationLayoutHoveredWaypoint,
  simulationLayoutSelectedWaypoint,
  simulationLayoutSelectedWaypointType,
} from '../store/simulation.layout.state';

export type SimulationRouteLayerBaseProps = {
  waypoints: WaypointLabeled[];
  color?: string;
  animate?: boolean;
  wpType?: 'before' | 'after';
};

const SimulationRouteLayerBase: React.FC<
  SimulationRouteLayerBaseProps
> = props => {
  const planeId = useRecoilValue(viewerSelectedPlaneId);
  const [selectedWp, setSelectedWp] = useRecoilState(
    simulationLayoutSelectedWaypoint,
  );
  const setHovered = useSetRecoilState(simulationLayoutHoveredWaypoint);
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);
  const setSelectedWpType = useSetRecoilState(
    simulationLayoutSelectedWaypointType,
  );

  if (_.isEmpty(props.waypoints) || !planeId) return null;
  const scaleFactor = measureSystem === MeasurementSystem.IMPERIAL ? 0.4 : 1;

  return (
    <Layer>
      <RouteFeature
        waypoints={props.waypoints}
        selected={selectedWp?.id}
        setSelected={wp => {
          setSelectedWpType(props.wpType ?? 'before');
          setSelectedWp(wp);
        }}
        color={props.color}
        onMouseOver={wp => setHovered(wp)}
        onMouseOut={() => setHovered(null)}
        showLabels={true}
        scale={scaleFactor}
        animate={props.animate}
      />
    </Layer>
  );
};

export default SimulationRouteLayerBase;
