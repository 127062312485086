import { AnalyzeResultToCompareFragment } from '@warebee/frontend/data-access-api-graphql';
import { atom, selectorFamily } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import {
  AnalyzeCompareAnalyzeType,
  AnalyzeCompareIdMap,
  AnalyzeCompareViewMode,
  AnalyzeResultList,
} from './analyzeCompare.types';

import _ from 'lodash';
import { loadAnalyzeResultCompareSummary } from './analyzeCompare.helper';

const getKey = (postfix: string) => `warebee-analyze-compare-${postfix}`;

export const analyzeCompareAnalyzeList = atom<AnalyzeResultList>({
  key: getKey('analyze-list'),
  default: null,
});

export const analyzeCompareAnalyzeListLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('analyze-list-load-status'),
  default: AsyncLoadStatus.None,
});

const analyzeCompareAnalyzeIdAtom = atom<AnalyzeCompareIdMap>({
  key: getKey('analyze-id-map'),
  default: {
    first: null,
    second: null,
  },
});

export const analyzeCompareIdByType = selectorFamily<
  string,
  AnalyzeCompareAnalyzeType
>({
  key: getKey('id-by-type-selector'),
  get:
    (type: AnalyzeCompareAnalyzeType) =>
    ({ get }) => {
      return get(analyzeCompareAnalyzeIdAtom)[type];
    },
  set:
    (type: AnalyzeCompareAnalyzeType) =>
    ({ get, set }, value: string) => {
      const current = get(analyzeCompareAnalyzeIdAtom);
      set(analyzeCompareAnalyzeIdAtom, {
        ...current,
        [type]: value,
      });
    },
});

export const analyzeCompareViewModeSelected = atom<AnalyzeCompareViewMode>({
  key: getKey('view-mode-selected'),
  default: 'first',
});

export const analyzeCompareResultByType = selectorFamily<
  AnalyzeResultToCompareFragment,
  AnalyzeCompareAnalyzeType
>({
  key: getKey('result-summary-by-type'),
  get:
    (type: AnalyzeCompareAnalyzeType) =>
    async ({ get }) => {
      const analyzeId = get(analyzeCompareIdByType(type));
      if (_.isNil(analyzeId)) return null;
      return await loadAnalyzeResultCompareSummary({ analyzeId });
    },
});
