import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { PanelHeader } from '../../../components/designer/panels/PanelHeader';
import StageContainer from '../../../layout/stage/StageContainer';
import ConverterLayoutTitle from './ConverterLayoutTitle';
import ConverterActiveFeatureLayer from './layers/ConverterActiveFeatureLayer';
import ConverterAisleLayer from './layers/ConverterAisleLayer';
import ConverterAreaLayer from './layers/ConverterAreaLayer';
import ConverterBayActionLayer from './layers/ConverterBayActionLayer';
import ConverterBayLayer from './layers/ConverterBayLayer';
import ConverterHoveredFeatureLayer from './layers/ConverterHoveredFeatureLayer';
import ConverterIntersectionsLayer from './layers/ConverterIntersectionsLayer';
import ConverterLocationLayer from './layers/ConverterLocationLayer';
import ConverterStatusLayer from './layers/ConverterStatusLayer';
import ConverterTooltipLayer from './layers/ConverterTooltipLayer';
import {
  converterLayoutData,
  converterLayoutIdSaving,
} from './store/converter.state';
import ConverterActiveLocationTag from './toolbars/ConverterActiveLocationTag';
import ConverterAlerts from './toolbars/ConverterAlerts';
import ConverterAreaActionToolbar from './toolbars/ConverterAreaActionToolbar';
import ConverterViewModeToolbar from './toolbars/ConverterViewModeToolbar';

const ConverterLayoutView: React.FC = () => {
  const { t } = useTranslation('importer');
  const layoutData = useRecoilValue(converterLayoutData);
  const isLayoutSaving = !_.isNil(useRecoilValue(converterLayoutIdSaving));
  if (layoutData === null) {
    return (
      <LoadingIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Loading Imported Layout`}
      />
    );
  }
  return (
    <div
      data-component="ContainerMap"
      className={classNames('relative flex h-full flex-1 flex-col')}
    >
      <PanelHeader filterPlaceholder={<ConverterActiveLocationTag />}>
        <ConverterLayoutTitle>
          <ConverterActiveLocationTag />
        </ConverterLayoutTitle>
      </PanelHeader>

      <div
        className={classNames(
          'relative',
          'flex flex-1 flex-col',
          'overflow-hidden',
          'bg-app-panel-dark/50',
          'border-app-background/70 border-8',
          'z-0',
        )}
      >
        <Suspense
          fallback={
            <LoadingIndicator
              className="bg-map-area-striped"
              selfCenter
              message={t`Converting Layout..`}
            />
          }
        >
          <StageContainer
            type="converter-area-view"
            stopListening={isLayoutSaving}
          >
            <ConverterAreaLayer />
            <ConverterAisleLayer />
            <ConverterBayLayer />
            <ConverterLocationLayer />
            <ConverterIntersectionsLayer />
            <ConverterActiveFeatureLayer />
            <ConverterHoveredFeatureLayer />
            <ConverterBayActionLayer />
            <ConverterStatusLayer />
            {/* <ConverterEntryPointLayer /> */}
          </StageContainer>
          {isLayoutSaving ? null : (
            <>
              <ConverterAreaActionToolbar />
              <ConverterViewModeToolbar />
              <ConverterAlerts />
              <ConverterTooltipLayer />
            </>
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default ConverterLayoutView;
