import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from './common/utils';
import { NavBack } from './components/nav/NavBack';

export type NoMatch404Props = {
  children?: React.ReactNode;
  title?: string | '404';
  subtitle?: string;
  errorCode?: string | 'ERROR - 404';
};

export const NoMatch404 = ({
  children,
  title,
  subtitle,
  errorCode,
}: NoMatch404Props) => {
  const { t } = useTranslation('app');

  return (
    <>
      {title && (
        <div
          data-component="404"
          className={cn(
            'bg-menu',
            'm-4',
            'w-full',
            'flex flex-1 flex-col items-center justify-center',
            'rounded-xl',
            'p-20',
          )}
        >
          <div className={cn('mb-4 w-full pb-4', 'text-xl')}>
            <NavBack isHeaderHome message={t`Back to Home`} />
          </div>
          <div
            className={cn(
              'text-menu-active',
              'border-menu-active border-b',
              'mb-4 pb-4',
              'w-full',
              'text-xl',
            )}
          >
            {errorCode || t`ERROR - 404`} {subtitle && <>- {subtitle}</>}
          </div>

          <div className={cn('text-menu-active w-full text-6xl')}>{title}</div>
        </div>
      )}

      {children}
    </>
  );
};
