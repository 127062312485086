import { ResourcePolicy } from '../../resourcePolicy/agentData/agent.types';

export const agentGroups = ['picking', 'replenishment'] as const;
export type AgentGroupType = typeof agentGroups[number];

const energyGenerationCostLCV: {
  name: string;
  energyElectricity?: number; // kWh
  energyFuel?: number; // kg
  // energyGas?: number; // kg
}[] = [
  { name: 'DEFAULT', energyElectricity: 0.85, energyFuel: 13.1 },
  { name: 'EU', energyElectricity: 0.85, energyFuel: 13.1 },
  { name: 'US', energyElectricity: 0.85, energyFuel: 13.1 },
  { name: 'CN', energyElectricity: 0.85, energyFuel: 13.1 },
  { name: 'GB', energyElectricity: 0.85, energyFuel: 13.1 },
];

//  FEF–fuel emission factor [kgCO2/kWh];
//  EEF–electrical energy emission factor [kgCO2/kWh];
//  GEF–gas energy emission factor [kgCO2/kWh];
//  FCHR–average fuel consumption per hour [kWh/h];
//  ECHR–average electrical energy consumption per hour [kWh/h];

// EEF is taken from (Eurostat, 2015).
// This value is derived considering the average mix of production sources of electricity in EU countries.
const energyProductionCountryAverageFactor = 0.388;
// The overall efficiency η of electric energy storage can be assumed equal to 0.85,
// considering, optimistically, 90% energy efficiency for the battery charging process
// and around 95 % for all the other ancillary equipment.
const energyStorageEfficiencyFactor = 0.85;
export const LCV_EU_Multiplier =
  energyProductionCountryAverageFactor / energyStorageEfficiencyFactor;

export const ResourcePolicyDefault: ResourcePolicy = { agents: [] };
