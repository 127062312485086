import {
  AssignmentPolicyFragment,
  AssignmentPolicyRuleFragment,
  CheckedAssignmentPolicyFragment,
  LayoutLocationWithMatchingFiltersFragment,
  LocationFilterIntersectionFragment,
  SimulationItemFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../layout/viewer/store/viewer.state';
import {
  AP_FALLBACK_RULE_ID,
  AssignmentPolicyDefault,
} from './assignmentPolicy.default';
import { loadLocationsByPolicy } from './assignmentPolicy.helper';
import { AssignmentPolicySelectedIdentity } from './assignmentPolicy.types';
import { simulationCurrent } from './simulation.state';
import { simulationWizardSelectedStepId } from './simulation.wizard.state';

const getKey = (postfix: string) => `warebee-simulation-ap-${postfix}`;

export const assignmentPolicyDocument = selector<AssignmentPolicyFragment>({
  key: getKey('document'),
  get: ({ get }) => {
    const sim = get(simulationCurrent);
    return sim?.assignmentPolicy || AssignmentPolicyDefault;
  },
  set: ({ get, set }, value: AssignmentPolicyFragment) => {
    const sim = get(simulationCurrent);
    set(simulationCurrent, { ...sim, assignmentPolicy: value });
  },
});

export const assignmentPolicySelectedIdentity =
  atom<AssignmentPolicySelectedIdentity>({
    key: getKey('selected-identity'),
    default: null,
  });

const assignmentPolicyFallbackRule = selector<AssignmentPolicyRuleFragment>({
  key: getKey('fallback-rule'),
  get: ({ get }) => {
    const policy = get(assignmentPolicyDocument);
    return {
      id: AP_FALLBACK_RULE_ID,
      title: 'Default Rule',
      productsMatch: null,
      locationsMatch: policy.fallbackLocationsMatch ?? { anyOf: [] },
    };
  },
});

export const assignmentPolicyRule = selectorFamily<
  AssignmentPolicyRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }): AssignmentPolicyRuleFragment => {
      if (_.isNil(ruleId)) return null;
      const policy = get(assignmentPolicyDocument);
      if (ruleId === AP_FALLBACK_RULE_ID)
        return get(assignmentPolicyFallbackRule);
      return _.find(policy.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, value: AssignmentPolicyRuleFragment) => {
      const policy = get(assignmentPolicyDocument);

      if (ruleId === AP_FALLBACK_RULE_ID) {
        set(assignmentPolicyDocument, {
          ...policy,
          fallbackLocationsMatch: value.locationsMatch,
        });
      } else {
        set(assignmentPolicyDocument, {
          ...policy,
          rules: policy.rules.map(rule => (rule.id === ruleId ? value : rule)),
        });
      }
    },
});

export const assignmentPolicyLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  AssignmentPolicySelectedIdentity
>({
  key: getKey('location-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (!identity?.locationFilterId || !identity?.ruleId) return null;

      const rule = get(assignmentPolicyRule(identity.ruleId));
      if (!rule) return null;

      const filterIntersection = _.find(
        rule.locationsMatch?.anyOf,
        fs => fs.id === identity.locationFilterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (!identity?.locationFilterId || !identity?.ruleId) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(assignmentPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(assignmentPolicyRule(identity.ruleId), {
        ...rule,
        locationsMatch: {
          ...rule.locationsMatch,
          anyOf: _.map(rule.locationsMatch.anyOf, fs =>
            fs.id === identity.locationFilterId ? value : fs,
          ),
        },
      });
    },
});

export const assignmentPolicyProductFilterIntersection = selectorFamily<
  SimulationItemFilterIntersectionFragment,
  AssignmentPolicySelectedIdentity
>({
  key: getKey('product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (!identity?.productFilterId) return null;

      const policy = get(assignmentPolicyDocument);
      const rule = policy.rules.find(rule => rule.id === identity?.ruleId);
      if (!rule) return null;

      const filterIntersection = _.find(
        rule.productsMatch?.anyOf,
        fs => fs.id === identity.productFilterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: SimulationItemFilterIntersectionFragment) => {
      if (!identity?.productFilterId || !identity?.ruleId) {
        throw new Error('Invalid  parameters. Filter identity is invalid');
      }

      const rule = get(assignmentPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(assignmentPolicyRule(identity.ruleId), {
        ...rule,
        productsMatch: {
          ...rule.productsMatch,
          anyOf: _.map(rule.productsMatch?.anyOf, fs =>
            fs.id === identity.productFilterId ? value : fs,
          ),
        },
      });
    },
});

export const assignmentPolicyCheckResult =
  atom<CheckedAssignmentPolicyFragment>({
    key: getKey('check-results'),
    default: null,
  });

export const assignmentPolicyCheckLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('check-results-load-status'),
  default: AsyncLoadStatus.None,
});

export const assignmentPolicyLocationByLevel = selector<
  Record<string, LayoutLocationWithMatchingFiltersFragment>
>({
  key: getKey('locations-per-level'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(assignmentPolicyDocument);

    const layoutId = sim?.layout?.id;
    if (_.isNil(layoutId) || _.isNil(level) || stepId !== 'policy-storage')
      return null;

    return await loadLocationsByPolicy({
      layoutId,
      policy,
      filter: {
        planeId: [planeId],
        level: [level],
      },
    });
  },
});

export const assignmentPolicyBayLocations = selector<
  Record<string, LayoutLocationWithMatchingFiltersFragment>
>({
  key: getKey('locations-per-bay-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const bay = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const policy = get(assignmentPolicyDocument);

    const layoutId = sim?.layout?.id;
    if (_.isNil(layoutId) || _.isNil(bay) || stepId !== 'policy-storage')
      return null;

    return await loadLocationsByPolicy({
      layoutId,
      policy,
      filter: {
        planeId: [planeId],
        bayId: [bay],
      },
    });
  },
});

export const assignmentPolicyShowUnassigned = atom<boolean>({
  key: getKey('show-unassigned'),
  default: false,
});
