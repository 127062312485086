import {
  BatchJobStatus,
  useGetImportTransformedPreviewLazyQuery,
  useLoadImportJobLazyQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { errorAppender } from '../store/error.state';
import { IMPORT_PREVIEW_QUERY } from './store/import.default';
import {
  importJob,
  importMappingSettingsByType,
  importProcessStatus,
  importRawPreview,
  importRawPreviewStatus,
  importSelectedFile,
  importSourceSettings,
  importTypeCurrent,
  importUploadStatus,
  importValidationErrors,
} from './store/import.state';
import useApplyImportTransformation from './useApplyImportTransformation';
import useApplyMappingSettings from './useApplyMappingSettings';

export type LoadImportJobParams = {
  id: string;
};

function useLoadImportJob() {
  const { t } = useTranslation('errors');
  const [loadJob] = useLoadImportJobLazyQuery();
  const [getTransformed] = useGetImportTransformedPreviewLazyQuery();
  const [applyTransformation] = useApplyImportTransformation();
  const applyMappings = useApplyMappingSettings();
  const errorTitle = t`Cannot load Preview.`;

  const initLoading = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: LoadImportJobParams) => {
        reset(importJob);
        set(importRawPreviewStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadImportJobParams) => {
        const type = await snapshot.getPromise(importTypeCurrent);
        const defaultMappingSettings = await snapshot.getPromise(
          importMappingSettingsByType(type),
        );
        function handleError(details?: string, stack?: string) {
          console.error(errorTitle, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importUploadStatus, AsyncLoadStatus.Error);
        }

        if (_.isNil(params.id)) {
          handleError(t`Job id is not provided`);
          return;
        }

        try {
          const { data: jobLoadResult, error: jobLoadError } = await loadJob({
            variables: {
              id: params.id,
            },
          });

          if (!_.isNil(jobLoadError)) {
            handleError(jobLoadError.message);
            return;
          }
          const job = jobLoadResult.importJob;
          const jobId = job.id;
          const mappingSettings = job.settings;
          const fileStub = new File([], job.sourceSettings?.filename ?? '');

          const { data: previewData, error: previewDataError } =
            await getTransformed({
              variables: {
                jobId,
                query: IMPORT_PREVIEW_QUERY,
              },
            });

          const columnNames = _.map(
            previewData?.importJob?.previewQuery?.columns,
            c => c.name,
          );

          set(importRawPreview, {
            data: previewData?.importJob?.previewQuery?.result,
            fields: columnNames,
            errors: _.isNil(previewDataError)
              ? null
              : [previewDataError?.message],
          });

          if (mappingSettings?.transformation?.enabled) {
            await applyTransformation({
              jobId,
              query: mappingSettings?.transformation?.query,
              skipMapping: true,
            });
          }
          const jobMappedFields = _.keyBy(
            mappingSettings.mapping?.fields,
            f => f.name,
          );

          applyMappings({
            mappingSettings: {
              ...mappingSettings,
              csvOptions: job.settings.csvOptions,
              fields: _.map(defaultMappingSettings.fields, m => ({
                ...m,
                columnName: jobMappedFields[m.name]?.columnName,
              })),
            },
          });

          set(importJob, job);
          set(importSelectedFile, fileStub);
          set(importSourceSettings, {
            format: job.sourceSettings.format,
            compression: job.sourceSettings.compression,
          });

          const status =
            job.status === BatchJobStatus.FAILED ||
            job.status === BatchJobStatus.TERMINATED
              ? AsyncLoadStatus.Error
              : AsyncLoadStatus.Ok;
          set(importValidationErrors, job.errors.content);
          set(importProcessStatus, status);
          set(importRawPreviewStatus, AsyncLoadStatus.Ok);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: LoadImportJobParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    //
  }

  return [call, cancel] as const;
}
export default useLoadImportJob;
