import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import InputText from '../components/inputs/InputText';
import { importTargetDatasetId } from './store/import.state';

const SelectTargetDocument: React.FC = () => {
  const { t } = useTranslation('importer');
  const [documentId, setDocumentId] = useRecoilState(importTargetDatasetId);
  return (
    <>
      <InputText
        horizontal
        label={t`Target document id`}
        placeholder={t`Target document id`}
        value={documentId ?? ''}
        onChange={v => setDocumentId(v)}
        animate={!_.isNil(documentId)}
      />
    </>
  );
};

export default SelectTargetDocument;
