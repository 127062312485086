import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import LoadingIndicator from '../components/LoadingIndicator';
import { Spacer } from '../components/layout/Spacer';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';

export type EventSectionProps = {
  id?: CollapsibleId;
  title?: string;
  loadingMessage?: string;
  maxHeight?: number;
  loadedCount?: number;
  totalCount?: number;
  hasScroll?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  titleContainer?: React.ReactNode;
  className?: string;
  hasSort?: boolean;
  hasFilter?: boolean;
  hasSearch?: boolean;
  hasViewControl?: boolean;
  hasSpacer?: boolean;
  hasAction?: React.ReactNode;
  inPanelView?: boolean;
  inSidebarView?: boolean;
  inListView?: boolean;
  collapsible?: boolean;
  fullWidth?: boolean;
  isPrimary?: boolean;
  isHelpTitle?: boolean;
  isTransparent?: boolean;
  hasScreenTitle?: boolean;
  innerPadding?: boolean;
  active?: boolean;
  disabled?: boolean;
  navLine?: boolean;
  navLineStart?: boolean;
  navLineEnd?: boolean;
  isMinimised?: boolean;
  hasAgent?: boolean;
  isSelected?: boolean;
};

const EventSection: React.FC<EventSectionProps> = props => {
  const { t } = useTranslation('simulation');
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const StepNavLine = () => {
    return (
      <div
        className={
          (classNames(
            'absolute z-0 ltr:border-l rtl:border-r left-4',
            props.active
              ? 'border-app-panel-dark/75 group-hover:border-menu-active'
              : props.disabled
                ? 'border-app-panel/30'
                : 'border-menu-active group-hover:border-app-panel',
          ),
          (props.navLineStart || props.navLineEnd) && 'h-full',
          props.navLineStart ? 'top-1/2 h-1/2' : 'bottom-0',
          props.navLineEnd ? 'bottom-1/2 h-1/2' : 'top-0',
          props.disabled ? 'border-opacity-20' : '',
          props.isMinimised ? 'ltr:left-1/2 rtl:right-1/2 hidden' : '')
        }
      />
    );
  };

  const ItemIndicator = () => {
    return (
      <div
        data-component="ItemIndicator"
        className={classNames(
          'flex items-center',
          props.inPanelView ? 'mx-0' : 'mx-2',
        )}
      >
        {props.totalCount !== 0 &&
          props.totalCount &&
          props.loadedCount === props.totalCount && (
            <div
              className={classNames(
                'px-0.5 ltr:ml-0.5 rtl:mr-0.5 text-menu/60',
                props.inSidebarView
                  ? 'inSidebarView text-xs'
                  : props.inPanelView
                    ? 'inPanelView text-xs'
                    : 'text-sm',
              )}
            >
              {'('}
              {props.totalCount}
              {')'}
            </div>
          )}

        {props.totalCount === 0 && (
          <div
            className={classNames(
              'px-0.5',
              'ltr:ml-0.5 rtl:mr-0.5',
              'text-menu-text/60',
              props.inSidebarView
                ? 'text-xs'
                : props.inPanelView
                  ? 'text-xs'
                  : 'text-sm',
            )}
          >
            {'('}
            {t`Nothing Found`}
            {')'}
          </div>
        )}

        {props.loadedCount < props.totalCount && (
          <div
            className={classNames(
              'px-0.5 ltr:ml-0.5 rtl:mr-0.5 text-menu/60',
              props.inSidebarView
                ? 'text-sm inSidebarView'
                : props.inPanelView
                  ? 'text-sm inPanelView'
                  : 'text-sm',
            )}
          >
            {'('}
            {props.loadedCount} {t`of`} {props.totalCount}
            {')'}
          </div>
        )}
      </div>
    );
  };

  const styleBlur =
    ' backdrop-filter backdrop-blur-lg backdrop-saturate-110 bg-opacity-60 ';

  return (
    <>
      <header
        data-component="EventSection"
        className={classNames(
          'relative hover:bg-menu-active hover:bg-opacity-5',
          'flex items-center',
          { 'flex-col': props.hasAgent },
          { 'cursor-pointer': props.collapsible },
          { 'w-full': props.fullWidth },
          { 'text-menu-active': props.isPrimary },
          { 'text-menu-text': props.inSidebarView },
          { 'ltr:-ml-8 rtl:-mr-8 mt-3': props.inListView },
          {
            'transition-all ease-in-out delay-50 duration-300 bg-menu-active/75 text-menu-active-text':
              props.isSelected,
          },
          isClosed
            ? 'isClosed border-b border-menu-400/50'
            : 'isOpen border-b border-menu-300',

          props.hasScreenTitle
            ? styleBlur + 'hasScreenTitle sticky top-20 bg-app-panel-dark/60'
            : props.inSidebarView
              ? styleBlur +
                'inSidebarView text-sm sticky top-0 bg-app-panel hover:bg-opacity-50'
              : props.inPanelView
                ? styleBlur + ' ' + isClosed
                  ? ' mb-0'
                  : ' mb-1' +
                    'inPanelView h-9 text-sm sticky top-8 bg-app-panel-dark/60'
                : props.isHelpTitle
                  ? styleBlur +
                    'isHelpTitle text-xl mt-16 sticky top-20 mb-1 py-4'
                  : styleBlur +
                    'text-xl md:text-2xl lg:text-3xl mt-10- mb-4 sticky top-0 bg-app-panel-dark/60 py-4 px-4',
          props.className,
        )}
        onClick={props.collapsible && (() => toggleOpen())}
      >
        {props.navLine && <StepNavLine />}

        {props.titleContainer}
        {(props.collapsible && (
          <>
            <span
              className={classNames(
                'truncate',
                props.collapsible ? 'collapsible' : 'ltr:pl-4 rtl:pr-4',
              )}
            >
              {props.title}
            </span>
            <Spacer flexspace />
          </>
        )) || (
          <span
            className={classNames(
              'truncate',
              props.inPanelView ? 'ltr:pl-2 rtl:pr-2' : 'ltr:pl-4 rtl:pr-4',
            )}
          >
            {props.title}
          </span>
        )}

        {props.hasSpacer && !props.inPanelView && (
          <Spacer hasHorizontalRule flexspace panelMode />
        )}

        {props.isLoading ? (
          <LoadingIndicator inline className={classNames('mx-2')} />
        ) : (
          <ItemIndicator />
        )}

        {props.hasSpacer && props.inPanelView && (
          <Spacer flexspace hasHorizontalRule noMarginRule panelMode />
        )}
      </header>

      <Transition
        data-component="EventSectionInner"
        className={classNames({
          'p-4 py-8 space-y-8 pb-10 divide-y divide-menu-200 bg-app-panel-dark/60':
            props.innerPadding,
        })}
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {props.children}
      </Transition>
    </>
  );
};

export default EventSection;
