import {
  AnalyzeEventFragment,
  AnalyzePickListLineFragment,
  HandlingPalletReorderingDetails,
} from '@warebee/frontend/data-access-api-graphql';
import { StackingPriority } from '@warebee/shared/engine-model';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import EventSection from '../../../containers/EventSection';
import EventListItemHeader from './EventListItemHeader';

export type PicklistLineDetailsProps = PropsWithChildren & {
  picklistId: string;
  picklistLine: AnalyzePickListLineFragment;
  events: AnalyzeEventFragment[];
  isSelected?: boolean;
};

export const PicklistLineDetails: React.FC<
  PicklistLineDetailsProps
> = props => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const cost = _.sumBy(props.events, e => e.cost);

  const costFormatted = formatter.formatCurrency(cost);

  const stats = _.compact([
    {
      title: t`UOM`,
      value: props.picklistLine.uom,
    },
    _.isNil(props.picklistLine.pickingPriority)
      ? null
      : {
          title: t`Priority`,
          value: props.picklistLine.pickingPriority,
        },
    {
      title: t`Qty`,
      value: formatInteger(props.picklistLine.uomQuantity),
      valueRaw: props.picklistLine.uomQuantity,
      valueThreshold: 1000,
    },
    {
      title: t`Cost`,
      titleAlt: `cost: ${cost}`,
      value: `${
        cost < 0.01
          ? `< 0.01`
          : cost < 0.1
            ? cost.toPrecision(2)
            : cost === 0
              ? `—`
              : cost > 1
                ? cost.toFixed(0)
                : cost.toPrecision(1)
      }`,
      valueRaw: cost,
      valueThreshold: 100,
    },
  ]);

  // const stackingPriority = props.picklistLine.details
  //   ?.stackingPriority as StackingPriority;

  // if (!_.isNil(stackingPriority)) {
  //   const stackingDetails = getStackingPriorityDetails(
  //     stackingPolicy,
  //     stackingPriority,
  //   );
  //   const stackingTagTitle = t`${
  //     stackingDetails.category?.rule?.title ?? 'Default Rule'
  //   }`;

  //   // stats.push({
  //   //   title: t`Rule:`,
  //   //   value: stackingTagTitle,
  //   // });
  //   stats.push({
  //     title: t`StackIndex`,
  //     value: _.join(stackingPriority, '-'),
  //   });
  // }

  const issues = [];
  const reorderingEvent = _.find(
    props.events,
    e => e?.details?.__typename === 'HandlingPalletReorderingDetails',
  );
  if (reorderingEvent) {
    const duration = formatter.formatTimeSpan(reorderingEvent.duration, 1);
    const details = reorderingEvent.details as HandlingPalletReorderingDetails;
    const reorderedWeight = _.sumBy(details.reordered, item => item.weight);
    const weight = formatter.formatWeight(reorderedWeight);
    issues.push({
      title: t`Sort penalty`,
      value: `${duration.value}${duration.unit}`,
    });

    issues.push({
      title: t`Sort weight`,
      value: `${weight.value}${weight.unit}`,
    });
  }

  return (
    <EventSection
      id={`${props.picklistId}-${props.picklistLine.picklistLine}`}
      fullWidth
      inPanelView
      collapsible
      isSelected={props.isSelected}
      titleContainer={
        <EventListItemHeader
          labelValueType={'itemId'}
          labelValueFilters={{
            consignee: props.picklistLine.consignee,
            sku: props.picklistLine.sku,
          }}
          title={props.picklistLine.sku}
          titleAriaLabel={t`Item`}
          counter={props.picklistLine.picklistLine + 1}
          stackingPriority={
            props.picklistLine.details?.stackingPriority as StackingPriority
          }
          container={props.picklistLine?.details?.pickingContainer}
          stats={stats}
          issues={issues}
        />
      }
    >
      {props.children}
    </EventSection>
  );
};
