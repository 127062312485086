import * as React from 'react';
const Mhe2BPalletJackPoweredElectricIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe2BPalletJackPoweredElectricIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M125.19,85.86H54.94v-22.41c-.16-5.98-2.22-10.94-6.18-14.9s-8.92-5.98-14.9-6.06H7.57c-1.62,0-2.95.52-4,1.57-1.05,1.05-1.57,2.38-1.57,4h0v39.37c0,1.29.44,2.38,1.33,3.27.89.89,1.98,1.33,3.27,1.33h.24c0,.89.3,1.62.91,2.18s1.33.85,2.18.85,1.57-.28,2.18-.85.91-1.29.91-2.18h86.25c0,.89.28,1.62.85,2.18.57.57,1.29.85,2.18.85s1.62-.28,2.18-.85.85-1.29.85-2.18h1.7c0,.89.28,1.62.85,2.18s1.29.85,2.18.85,1.62-.28,2.18-.85c.57-.57.85-1.29.85-2.18h5.21c.57,0,1.05-.16,1.45-.48l5.94-4.48c.32-.16.4-.44.24-.85-.16-.24-.4-.36-.73-.36h0ZM32.65,37.53c-.08-1.29-.55-2.38-1.39-3.27s-1.96-1.33-3.33-1.33h-11.87c-1.37,0-2.48.44-3.33,1.33s-1.31,1.98-1.39,3.27h0v3.03h21.32v-3.03h0Z"
    />
  </svg>
);
export default Mhe2BPalletJackPoweredElectricIcon;
