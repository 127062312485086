import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';
import { Vector } from '../../geometry.model';

@InputAndObjectType()
export class RoutingPolicyDirectionThreshold {
  @Field({ nullable: true })
  angle?: number;

  @Field({ nullable: true })
  distance?: number;
}

@InputAndObjectType()
export class RoutingPolicyFeatureRule {
  @Field(() => ID)
  featureId: string;

  @Field(() => Vector, { nullable: true })
  direction?: Vector;

  @Field(() => RoutingPolicyDirectionThreshold, { nullable: true })
  threshold?: RoutingPolicyDirectionThreshold;
}

@InputAndObjectType()
export class RoutingPolicyRule {
  @Field(() => [RoutingPolicyFeatureRule], { nullable: true })
  featureRules?: RoutingPolicyFeatureRule[];
}

@InputAndObjectType()
export class RoutingPolicyAgentRule extends RoutingPolicyRule {
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => [ID])
  agentIds: string[];
}

@InputAndObjectType()
export class RoutingPolicy {
  @Field(() => [RoutingPolicyAgentRule], { nullable: true })
  rules?: RoutingPolicyAgentRule[];

  @Field(() => RoutingPolicyRule, { nullable: true })
  defaultRule?: RoutingPolicyRule;

  @Field(() => RoutingPolicyDirectionThreshold, { nullable: true })
  threshold?: RoutingPolicyDirectionThreshold;
}
