import * as React from 'react';
const ArrowRightTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowRightTopIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M113.98,14.03l.02,72.61-27.37,27.37-.02-53.24-49.48,49.48c-2.08,2.08-5.45,2.08-7.52,0l-11.85-11.85c-2.08-2.08-2.08-5.45,0-7.52l49.48-49.48-53.24-.02,27.37-27.37,72.61.03Z"
    />
  </svg>
);
export default ArrowRightTopIcon;
