import { ItemFilterIntersectionFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import {
  HeatmapBucket,
  getHeatmapBuckets,
  getHeatmapMetricRangeFromData,
} from '../common/heatmap.helper';
import { HeatmapMetricRange } from '../common/heatmap.types';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../layout/viewer/store/viewer.state';
import {
  simulationCurrent,
  simulationEffectiveAssignmentId,
} from '../simulation/store/simulation.state';
import { simulationWizardSelectedStepId } from '../simulation/store/simulation.wizard.state';
import { namedColorsGroupSelected } from '../store/namedColors.state';
import { assignmentStatItemMetricDescriptorsMap } from './assignmentStats.default';
import { loadAssignmentStats } from './assignmentStats.helper';
import {
  AssignmentLocationItemStat,
  AssignmentStatItemMetric,
  AssignmentStatItemMetricDescriptorBase,
  AssignmentStatsEditorViewMode,
} from './assignmentStats.type';

const getKey = (postfix: string) => `warebee-assignment-stats-${postfix}`;

export const assignmentStatsItemMetric = atom<AssignmentStatItemMetric>({
  key: getKey('item-metric'),
  default: 'consignee',
});

export const assignmentStatsItemMetricDescriptor =
  selector<AssignmentStatItemMetricDescriptorBase>({
    key: getKey('metric-descriptor'),
    get: ({ get }) => {
      const metric = get(assignmentStatsItemMetric);
      return assignmentStatItemMetricDescriptorsMap[metric];
    },
  });

export const assignmentStatsItemsFilter = atom<ItemFilterIntersectionFragment>({
  key: getKey('filter'),
  default: {
    id: 'assignment-stats-filter',
    allOf: [],
  },
});

export const assignmentStatsEditorViewMode =
  atom<AssignmentStatsEditorViewMode>({
    key: getKey('editor-view-mode'),
    default: 'legend',
  });

export const assignmentStatsLocationsByLevel = selector<
  Record<string, AssignmentLocationItemStat[]>
>({
  key: getKey('locations-by-level'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const effectiveAssignmentId = get(simulationEffectiveAssignmentId);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    if (_.isNil(planeId) || _.isNil(level) || stepId !== 'data-stats') {
      return null;
    }
    const layoutId = sim?.layout?.id;
    const itemSetId = sim?.itemSet?.id;

    const itemFilter = get(assignmentStatsItemsFilter);
    return await loadAssignmentStats({
      layoutId,
      assignmentId: effectiveAssignmentId,
      itemSetId,
      filter: {
        planeId: [planeId],
        level: [level],
      },
      itemFilter,
    });
  },
});

export const assignmentStatsLocationsByBay = selector<
  Record<string, AssignmentLocationItemStat[]>
>({
  key: getKey('locations-all-by-bay'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const effectiveAssignmentId = get(simulationEffectiveAssignmentId);
    const planeId = get(viewerSelectedPlaneId);
    const stepId = get(simulationWizardSelectedStepId);
    const bay = get(viewerSelectedBayIdAtom);

    if (_.isNil(planeId) || _.isNil(bay) || stepId !== 'data-stats') {
      return null;
    }
    const layoutId = sim?.layout?.id;
    const assignmentId = sim?.assignment?.id;
    const itemSetId = sim?.itemSet?.id;
    const itemFilter = get(assignmentStatsItemsFilter);
    return await loadAssignmentStats({
      layoutId,
      assignmentId: effectiveAssignmentId,
      itemSetId,
      filter: {
        planeId: [planeId],
        bayId: [bay],
      },
      itemFilter,
    });
  },
});

export const assignmentStatsRangeDescriptor = selector<HeatmapMetricRange>({
  key: getKey('range-descriptor'),
  get: ({ get }) => {
    const data = get(assignmentStatsLocationsByLevel);
    if (_.isEmpty(data)) return null;
    const metricDescriptor = get(assignmentStatsItemMetricDescriptor);

    return getHeatmapMetricRangeFromData(
      _.flatten(_.values(data)),
      metricDescriptor,
    );
  },
});

export const assignmentStatsBuckets = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets'),
  get: ({ get }) => {
    const data = get(assignmentStatsLocationsByLevel);
    if (_.isEmpty(data)) return null;
    const namedColors = get(namedColorsGroupSelected);
    const metricDescriptor = get(assignmentStatsItemMetricDescriptor);
    const rangeDescriptor = get(assignmentStatsRangeDescriptor);
    return getHeatmapBuckets(rangeDescriptor, metricDescriptor, namedColors);
  },
});
