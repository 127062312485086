import { OptimizationRunStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import Tabs, { TabItem } from '../../../components/actions/Tabs';
import * as Icon from '../../../components/icons';
import LoadBalancingPolicyPanel from '../../../loadBalancingPolicy/LoadBalancingPolicyPanel';
import { userHasOptimise } from '../../../store/warehouse.state';
import SwapPolicyPanel from '../../../swapPolicy/SwapPolicyPanel';
import OptimisationResourcePolicyPanel from '../../panels/OptimisationResourcePolicyPanel';
import OptimisationSetupPanel from '../../panels/OptimisationSetupPanel';
import OptimisationProgressStatus from '../../panels/optimization/OptimisationProgressStatus';
import OptimizationRunActionAdvanced from '../../panels/optimization/OptimizationRunActionAdvanced';
import { optimisationStatus } from '../../store/optimisation.state';
import { OptimisationPolicyTabId } from '../../store/simulation.types';
import { simulationOptimisationPolicyTabKey } from '../../store/simulation.wizard.state';

const OptimisationPolicySetup: React.FC = () => {
  const { t } = useTranslation('simulation');
  const optStatus = useRecoilValue(optimisationStatus);
  const allowOptimise = useRecoilValue(userHasOptimise);
  const isOptimising =
    optStatus === OptimizationRunStatus.CREATED ||
    optStatus === OptimizationRunStatus.IN_PROGRESS ||
    optStatus === OptimizationRunStatus.STOPPING;

  const [selectedTab, setSelectedTab] = useRecoilState(
    simulationOptimisationPolicyTabKey,
  );

  const showRunOptimiseButton =
    !isOptimising &&
    allowOptimise &&
    selectedTab === 'tab-optimisation-settings';

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const setupTabConfig: TabItem<OptimisationPolicyTabId> = {
    id: 'tab-optimisation-settings',
    title: t`Optimisation setup`,
    icon: <Icon.OptimisationMl className={tabIconStyle} />,
    content: <OptimisationSetupPanel />,
  };

  const statusTabConfig: TabItem<OptimisationPolicyTabId> = {
    id: 'tab-optimisation-status',
    title: t`Optimisation Status`,
    icon: <Icon.OptimisationMl className={tabIconStyle} />,
    content: <OptimisationProgressStatus />,
  };

  const tabItems: TabItem<OptimisationPolicyTabId>[] = [
    isOptimising || optStatus === OptimizationRunStatus.COMPLETED
      ? statusTabConfig
      : setupTabConfig,
    {
      id: 'tab-optimisation-policy-swap',
      title: t`Swap policy`,
      icon: <Icon.PolicyOptimisation className={tabIconStyle} />,
      content: <SwapPolicyPanel />,
    },
    {
      id: 'tab-optimisation-policy-load-balancing',
      title: t`Load balancing`,
      icon: <Icon.WorkloadZoneBalance className={tabIconStyle} />,
      content: <LoadBalancingPolicyPanel />,
    },
    {
      id: 'tab-optimisation-resource-policy',
      title: t`Resource`,
      icon: <Icon.PolicyPickingAgents className={tabIconStyle} />,
      content: <OptimisationResourcePolicyPanel />,
    },
  ];

  return (
    <>
      {/* <Container hasOverflowY data-componentName="OptimisationPolicyPanel"> */}
      <Tabs
        componentName="OptimisationPolicyPanel"
        items={tabItems}
        selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
        onChange={index => setSelectedTab(tabItems[index].id)}
        hasScroll
        fullHeight
      />
      {/* </Container> */}
      {showRunOptimiseButton && <OptimizationRunActionAdvanced />}
    </>
  );
};

export default OptimisationPolicySetup;
