import * as React from 'react';
const DesignerAislesTunnelAislesIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesTunnelAislesIcon"
    {...props}
  >
    <rect
      width={34}
      height={124}
      x={47.31}
      y={46.87}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(173.18 44.56) rotate(90)"
    />
    <rect
      width={34}
      height={124}
      x={47.31}
      y={-43.13}
      strokeWidth={0}
      opacity={0.4}
      transform="translate(83.18 -45.44) rotate(90)"
    />
    <path
      strokeWidth={0}
      d="M61.1,97.56c-1.11,0-2.01-.88-2.01-1.97v-51.58s-18.73,18.38-18.73,18.38v-13.58s23.64-23.18,23.64-23.18l23.63,23.18v13.58s-18.73-18.37-18.73-18.37v51.58c0,1.08-.91,1.97-2.01,1.97h-5.79Z"
    />
    <rect width={29.43} height={25.31} x={2} y={65} strokeWidth={0} />
    <rect
      width={29.43}
      height={25.31}
      x={33.58}
      y={65}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={29.43}
      height={25.31}
      x={65.17}
      y={65}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect width={29.43} height={25.31} x={96.57} y={65} strokeWidth={0} />
    <rect width={29.43} height={25.31} x={2} y={37.68} strokeWidth={0} />
    <rect
      width={29.43}
      height={25.31}
      x={33.58}
      y={37.68}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={29.43}
      height={25.31}
      x={65.17}
      y={37.68}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect width={29.43} height={25.31} x={96.57} y={37.68} strokeWidth={0} />
  </svg>
);
export default DesignerAislesTunnelAislesIcon;
