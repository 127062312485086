import * as React from 'react';
const PolicyWaveRouteIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaveRouteIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M19.07,42.96c0,18.75,15.25,34,34,34v-13.4c-11.36,0-20.6-9.24-20.6-20.6s9.24-20.6,20.6-20.6c11.36,0,20.6,9.24,20.6,20.6h-6.44s12.45,14.68,12.45,14.68l12.45-14.68h-5.06c0-18.75-15.25-34-34-34-18.75,0-34,15.25-34,34ZM43.38,71.05l.84-3.61c.28-1.2,1.48-1.95,2.68-1.67,1.2.28,1.95,1.48,1.67,2.68l-.84,3.61c-.28,1.2-1.49,1.95-2.69,1.67-1.2-.28-1.95-1.48-1.67-2.69ZM78.01,37.31l3.69-.42c1.23-.14,2.34.74,2.48,1.96.14,1.23-.74,2.33-1.96,2.48l-3.69.42c-1.23.14-2.34-.74-2.48-1.96-.14-1.23.74-2.33,1.96-2.48ZM72.39,26.26l3.06-2.1c1.02-.7,2.41-.44,3.11.58.7,1.02.44,2.41-.58,3.11l-3.06,2.09c-1.02.7-2.41.44-3.11-.58-.7-1.02-.44-2.41.58-3.11ZM62.26,19.11l1.73-3.28c.58-1.09,1.93-1.51,3.02-.93,1.09.57,1.51,1.93.93,3.02l-1.73,3.28c-.58,1.09-1.93,1.51-3.02.93-1.09-.58-1.51-1.93-.93-3.02ZM51.33,13.8c0-1.23,1-2.23,2.23-2.23s2.23,1,2.23,2.23v3.71c0,1.23-1,2.23-2.23,2.23s-2.23-1-2.23-2.23v-3.71ZM38.76,14.89c1.09-.58,2.44-.16,3.02.93l1.73,3.28c.58,1.09.16,2.44-.93,3.02s-2.44.16-3.02-.93l-1.73-3.28c-.58-1.09-.16-2.44.93-3.02ZM30.86,29.95l-3.06-2.09c-1.02-.7-1.28-2.09-.58-3.11.7-1.02,2.08-1.28,3.11-.58l3.06,2.1c1.02.7,1.28,2.09.58,3.11-.7,1.02-2.09,1.28-3.11.58ZM31.46,63.39l2.43-2.8c.81-.93,2.22-1.03,3.15-.22.93.81,1.03,2.22.22,3.15l-2.43,2.8c-.81.93-2.22,1.03-3.15.22-.93-.81-1.03-2.22-.22-3.15ZM24.48,51.05l3.46-1.34c1.15-.45,2.45.12,2.89,1.27.45,1.15-.12,2.45-1.27,2.89l-3.46,1.35c-1.15.44-2.45-.13-2.89-1.27-.44-1.15.12-2.45,1.27-2.89ZM21.6,38.85c.14-1.22,1.25-2.1,2.48-1.96l3.68.42c1.23.14,2.11,1.25,1.96,2.48-.14,1.22-1.25,2.1-2.48,1.96l-3.69-.42c-1.23-.14-2.11-1.25-1.96-2.48Z"
    />
    <path
      strokeWidth={0}
      d="M37.2,44.82c0,1.23,1,2.23,2.23,2.23h11.01c.7.97,1.83,1.62,3.13,1.62,2.13,0,3.85-1.72,3.85-3.85,0-1.29-.64-2.43-1.62-3.13v-11.65c0-1.23-1-2.23-2.23-2.23s-2.23,1-2.23,2.23v11.64c-.34.25-.64.55-.89.89h-11.01c-1.23,0-2.23,1-2.23,2.23Z"
    />
    <path
      strokeWidth={0}
      d="M123.48,107.14v-7.32q-.43-1.38-1.95-2.38l-17.86-2.16-1.22-6.44h1.47s-.3-1.77-1.89-3.9l-1.64-.09c-1.4-2.42-4.04-6.06-11.14-9.59,0,0-16.2-6.44-27.02-7.52-2,.24-1.95,2.13-1.95,4.76v40.54l-20.49-.04c-.84-1.4-2.19-2.44-3.8-2.88h-3.33c-1.6.44-2.95,1.47-3.79,2.86h-2.88c-.84-1.39-2.18-2.43-3.79-2.86h-3.34c-1.59.43-2.93,1.46-3.77,2.83h-6.53s0,1.84,0,1.84c0,2.63.87,4.01,1.95,4.01h4.15c.98,2.27,3.24,3.87,5.87,3.87s4.89-1.59,5.87-3.87h2.06c.98,2.27,3.24,3.87,5.87,3.87s4.89-1.59,5.87-3.87h66.41c.98,2.27,3.23,3.87,5.87,3.87s4.89-1.59,5.87-3.87h2.49c.87,0,1.77-.97,2.03-1.92,0,0,2.47.27,2.47-4.61,0-5.58-1.83-5.13-1.83-5.13h0ZM21.65,118.8c-.35.16-.73.25-1.13.25s-.78-.09-1.13-.25c-.97-.44-1.66-1.4-1.66-2.53,0-1.53,1.25-2.78,2.78-2.78s2.78,1.25,2.78,2.78c0,1.13-.68,2.1-1.65,2.53h0ZM35.45,118.8c-.35.16-.73.25-1.13.25s-.78-.09-1.13-.25c-.97-.44-1.66-1.4-1.66-2.53,0-1.53,1.25-2.78,2.78-2.78s2.79,1.25,2.79,2.78c0,1.13-.69,2.1-1.66,2.53h0ZM93.35,94.77v-2.62c0-1.45-.21-2.62,1.24-2.62,0,0,3.04-.03,3.93,0,.88.03,1.86-.19,2.06.93.2,1.13.76,4.31.76,4.31h-7.99ZM112.46,119.05c-.41,0-.79-.09-1.13-.25-.97-.44-1.66-1.4-1.66-2.53,0-1.53,1.25-2.78,2.79-2.78s2.79,1.25,2.79,2.78c0,1.13-.68,2.1-1.65,2.53-.35.16-.73.25-1.14.25h0Z"
    />
    <path
      strokeWidth={0}
      d="M48.82,84.9c-16.97,0-31.37-10.99-36.48-26.24h-7.8v49.81h51.71v-24.29c-2.41.47-4.89.72-7.43.72Z"
    />
  </svg>
);
export default PolicyWaveRouteIcon;
