import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Tabs, { TabItem } from '../../../components/actions/Tabs';
import * as Icon from '../../../components/icons';
import SimulationItemSetWrap from '../../hoc/SimulationItemSetWrap';
import AllocationPolicy from '../../panels/allocate/AllocationPolicy';
import DeallocatePolicy from '../../panels/deallocate/DeallocatePolicy';
import { allocationRunSummary } from '../../store/allocation/allocation.state';
import { AllocateTabId } from '../../store/simulation.types';
import { simulationAllocatePolicyTabKey } from '../../store/simulation.wizard.state';

const AllocationPolicyPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  // const allocationStatus = useRecoilValue(optimisationStatus);
  const allocationRun = useRecoilValue(allocationRunSummary);
  const isAllocating =
    allocationRun?.status === 'CALCULATING' ||
    allocationRun?.status === 'CREATED';

  const [selectedTab, setSelectedTab] = useRecoilState(
    simulationAllocatePolicyTabKey,
  );

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const setupTabConfig: TabItem<AllocateTabId> = {
    id: 'tab-allocate-policy-allocation',
    title: t`Allocation Policy`,
    icon: <Icon.PolicyAllocationRequirement className={tabIconStyle} />,
    content: <AllocationPolicy />,
  };

  const statusTabConfig: TabItem<AllocateTabId> = {
    id: 'tab-allocate-status',
    title: t`Allocation Status`,
    icon: <Icon.PolicyAllocationRequirement className={tabIconStyle} />,
    content: (
      <>
        <LoadingIndicator selfCenter message={t`Allocating...`} />
      </>
    ),
  };

  const deallocateTabConfig: TabItem<AllocateTabId> = {
    id: 'tab-allocate-policy-deallocation',
    title: t`Re-Allocate policy`,
    icon: <Icon.PolicyWaypointStartToEnd className={tabIconStyle} />,
    content: <DeallocatePolicy />,
  };

  const tabItems: TabItem<AllocateTabId>[] = isAllocating
    ? [statusTabConfig]
    : [setupTabConfig, deallocateTabConfig];

  return (
    <SimulationItemSetWrap>
      <Tabs
        componentName="AllocationPolicyPanel"
        items={tabItems}
        selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
        onChange={index => setSelectedTab(tabItems[index].id)}
        hasScroll
        fullHeight
      />
    </SimulationItemSetWrap>
  );
};

export default AllocationPolicyPanel;
