const CircleArrowsNonConnectIcon = props => (
  <svg
    aria-hidden="true"
    data-name="CircleArrowsNonConnectIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m64,4C30.92,4,4,30.91,4,64s26.92,60,60,60,60-26.91,60-60S97.08,4,64,4ZM13.04,67.55v-8.4h23.42c6.2,0,12.05-2.43,16.48-6.86,4.42-4.42,6.86-10.27,6.86-16.48v-7.36s-11.55,11.54-11.55,11.54v-11.87s15.75-15.74,15.75-15.74l15.75,15.74v11.87s-11.55-11.54-11.55-11.54v7.36c0,8.45-3.31,16.41-9.32,22.42-6.01,6.01-13.97,9.32-22.42,9.32H13.04Zm104.31-8.91v8.4s-23.42,0-23.42,0c-6.2,0-12.05,2.44-16.48,6.86-4.42,4.42-6.86,10.27-6.86,16.48v7.36s11.55-11.54,11.55-11.54v11.87s-15.75,15.74-15.75,15.74l-15.75-15.74v-11.87s11.55,11.54,11.55,11.54v-7.36c0-8.45,3.31-16.41,9.32-22.42,6.01-6.01,13.97-9.32,22.42-9.32h23.42Z" />
  </svg>
);
export default CircleArrowsNonConnectIcon;
