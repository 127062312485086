import { format, startOfDay } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../../../common/useFormatter';
import { StatListItem } from '../../../../components/stats/StatListItem';
import { AgentWorkforceWave } from '../../../store/workforce.types';

export type WorkforceWaveTooltipProps = {
  wave: AgentWorkforceWave;
};

const WorkforceWaveTooltip: React.FC<WorkforceWaveTooltipProps> = props => {
  const { t } = useTranslation('simulation');
  const { wave } = props;
  const formatter = useFormatter();
  const isNotSameDay =
    startOfDay(wave.source.to) > startOfDay(wave.forecast.to);
  const durationTotal = formatter.formatTimeSpan(wave.forecast.durationTotal);

  return (
    <div data-component="WorkforceWaveTooltip" className="min-w-10 w-40">
      <div className="flex items-center p-1 px-2 text-xs text-menu-text uppercase bg-brand">
        <span className="uppercase text-xxs opacity-75">{t`Wave, Pick by`}</span>
        <span className="ml-2 font-bold text-end flex-1">
          {format(wave.source.to, 'dd HH:mm')}
        </span>
      </div>
      <div className="flex flex-col p-1 px-2 text-sm font-bold text-start text-white bg-app-panel-dark ">
        <StatListItem
          title={t`From`}
          titleClass="uppercase text-xxs opacity-75"
          value={format(wave.forecast.from, 'MMM dd HH:mm')}
        />
        <StatListItem
          title={t`To`}
          titleClass="uppercase text-xxs opacity-75"
          value={format(wave.forecast.to, 'MMM dd HH:mm')}
        />
        {/* <StatListItem
          title={t`Deadline:`}
          value={format(wave.source.to, 'MMM dd hh:mm')}
        /> */}

        <StatListItem
          title={t`Wave Interval`}
          titleClass="uppercase text-xxs opacity-75"
          value={
            formatter.formatTimespanAs24String(
              (wave.forecast.to.getTime() - wave.forecast.from.getTime()) /
                1000,
            ).fullString
          }
        />
        <StatListItem
          title={t`Wave (Total)`}
          titleClass="uppercase text-xxs opacity-75"
          value={formatter.formatTimeSpan(wave.source.duration).fullString}
        />
        <StatListItem
          title={t`Time (*Agents)`}
          titleClass="uppercase text-xxs opacity-75"
          value={durationTotal.value}
          unitOfMeasure={durationTotal.unit}
        />
        {isNotSameDay && (
          <StatListItem
            title={t`Same day`}
            titleClass="uppercase text-xxs opacity-75"
            value={isNotSameDay ? 'No' : 'Yes'}
          />
        )}
      </div>
    </div>
  );
};

export default WorkforceWaveTooltip;
