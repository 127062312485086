import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import PanelContainer from '../../../containers/PanelContainer';
import ImporterJSONRawPreview from './ImporterJSONRawPreview';
import ImporterJSONTablePreview from './ImporterJSONTablePreview';
import ImporterSqlPreview from './ImporterSqlPreview';

type ViewAs = 'table' | 'raw' | 'sql';

export type ImporterJSONPreviewProps = {
  id: string;
  data: Record<string, string>[];
  columns: string[];
  className?: string;
  collapsible?: boolean;
  transformable?: boolean;
};

const ImporterJSONPreview: React.FC<ImporterJSONPreviewProps> = props => {
  const { t } = useTranslation('importer');
  const headerRow = _.keys(_.head(props.data));
  const preview = props.data;
  const previewSize = _.size(preview);
  const [viewAs, setViewAs] = useState<ViewAs>('table');

  const title = t(`Data Preview (first {{previewSize}})`, {
    previewSize: previewSize,
  });

  const titleFooter = t(`End of data preview {{previewSize}} rows`, {
    previewSize: previewSize,
  });

  const viewAsOptions: { key: ViewAs; label: string }[] = [
    {
      key: 'table',
      label:
        props.id === 'source'
          ? t`Source Data (Table)`
          : t`Transformed Data (Table)`,
    },
    {
      key: 'raw',
      label:
        props.id === 'source'
          ? t`Source Data (JSON)`
          : t`Transformed Data (JSON)`,
    },
    {
      key: 'sql',
      label: props.id === 'source' ? t`SQL (Helper)` : null,
    },
  ];

  return (
    <PanelContainer
      id={`import-data-preview-${props.id}`}
      title={
        props.id === 'source' ? (
          <>
            {props.collapsible ? (
              <ButtonSwitchMulti
                autoSize
                className=""
                buttonType="switchon"
                classNameLabel="text-xxs py-0.5 px-2"
                options={viewAsOptions}
                selectedIndex={_.findIndex(
                  viewAsOptions,
                  o => o.key === viewAs,
                )}
                onClick={index => setViewAs(viewAsOptions[index].key)}
              />
            ) : (
              <>{t`Source Data (Preview)`}</>
            )}

            <Spacer flexspace />
          </>
        ) : (
          <>
            <ButtonSwitchMulti
              autoSize
              className=""
              buttonType="switchon"
              classNameLabel="text-xxs py-0.5 px-2"
              options={viewAsOptions}
              selectedIndex={_.findIndex(viewAsOptions, o => o.key === viewAs)}
              onClick={index => setViewAs(viewAsOptions[index].key)}
            />
            <Spacer flexspace />
          </>
        )
      }
      subtitle={title}
      collapsible={props.collapsible}
      isAccordion
    >
      {/* <PanelHeader title={title} className={'flex-1 !bg-menu-500 mb-px'}>
        <ButtonSwitchMulti
          autoSize
          className="mx-3"
          classNameLabel="text-xxs py-0.5 px-2"
          options={viewAsOptions}
          selectedIndex={_.findIndex(viewAsOptions, o => o.key === viewAs)}
          onClick={index => setViewAs(viewAsOptions[index].key)}
        />
      </PanelHeader> */}

      <Container hasOverflowY col>
        {viewAs === 'table' && (
          <ImporterJSONTablePreview
            id={`import-data-preview-table-${props.id}`}
            data={preview}
            columns={props.columns}
          />
        )}
        {viewAs === 'raw' && <ImporterJSONRawPreview data={preview} />}
        {viewAs === 'sql' && <ImporterSqlPreview data={preview} />}
      </Container>
    </PanelContainer>
  );
};
export default ImporterJSONPreview;
