import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../../common/useFormatter';
import * as Icon from '../../icons';
import { InputLabelGroup } from '../../inputs/InputLabelGroup';
import { InputValue } from '../../inputs/InputValue';

export type ObjectSizeProps = {
  className?: string;
  object?: string;
  children?: React.ReactNode;
  unitMeasurement?: string;
  title?: string;
  title_helper?: string;
  hasPreview?: boolean;
  shortTitle?: boolean;
  hasPosition?: boolean;
  hasPadding?: boolean;
  hasSize?: boolean;
  hasAngle?: boolean;
  showInputLabels?: boolean;
  sizeWidth?: number;
  sizeLength?: number;
  sizeHeight?: number;
  sizeVolume?: number;
  sizeVolumeValue?: string;
  sizeVolumeUnit?: string;
  sizeHeightFromFloor?: number;
  positionX?: number;
  positionY?: number;
  positionZ?: number;
  positionAngle?: number;
  disabled?: boolean;
};

export const ObjectSize = ({
  className,
  children,
  unitMeasurement,
  title,
  title_helper,
  object,
  hasPreview,
  hasPosition,
  shortTitle,
  hasSize,
  hasAngle,
  showInputLabels,
  sizeWidth,
  sizeLength,
  sizeHeight,
  sizeVolume,
  sizeVolumeUnit,
  sizeVolumeValue,
  sizeHeightFromFloor,
  positionX,
  positionY,
  positionZ,
  positionAngle,
  disabled,
}: ObjectSizeProps) => {
  const { t } = useTranslation('app');
  const formatter = useFormatter();

  // Volume - Calculated
  const volumeCalculatedRaw = sizeWidth * sizeHeight * sizeLength;
  const volumeCalculated = formatter.formatVolume(volumeCalculatedRaw);

  return (
    <>
      <div
        data-component="ObjectSize"
        className={classNames('flex flex-col my-2', className)}
      >
        {hasPreview && (
          <>
            {showInputLabels && <InputLabelGroup title={t`Info`} />}
            <div className="row-span-2 justify-self-stretch">
              <div className="flex w-16 h-16 align-middle bg-opacity-50 rounded-md bg-app-panel-dark center">
                <Icon.ObjectAisleMiddle className="w-20 h-20 p-1 fill-current text-menu-active" />
              </div>
            </div>
          </>
        )}
        {hasAngle && (
          <div className="flex-1">
            <InputValue
              icon="angle"
              id_name="angle"
              value={positionAngle}
              title={t`Angle`}
            />
          </div>
        )}
        {hasSize && (
          <div className="flex-1">
            {showInputLabels && (
              <InputLabelGroup title="Size" title_helper={unitMeasurement} />
            )}
            <div className="flex flex-col space-y-1">
              {!_.isNil(sizeWidth) && (
                <InputValue
                  title={shortTitle ? t`W.` : t`Width`}
                  className="flex-1"
                  id_name={object + '-size-w'}
                  value={sizeWidth}
                  unitOfMeasure={unitMeasurement}
                  disabled
                />
              )}
              {!_.isNil(sizeHeight) && (
                <InputValue
                  title={shortTitle ? t`H.` : t`Height`}
                  className="flex-1"
                  id_name={object + '-size-h'}
                  value={sizeHeight}
                  unitOfMeasure={unitMeasurement}
                  disabled
                />
              )}
              {!_.isNil(sizeLength) && (
                <InputValue
                  title={shortTitle ? t`D.` : t`Depth`}
                  className="flex-1"
                  id_name={object + '-size-d'}
                  value={sizeLength}
                  unitOfMeasure={unitMeasurement}
                  disabled
                />
              )}
              {!_.isNil(sizeVolumeValue) &&
                !_.isNil(volumeCalculated.value) && (
                  <InputValue
                    title={shortTitle ? t`V.` : t`Volume`}
                    className="flex-1"
                    id_name={object + '-size-vol'}
                    value={
                      sizeVolumeValue ? sizeVolumeValue : volumeCalculated.value
                    }
                    unitOfMeasure={
                      sizeVolumeUnit ? sizeVolumeUnit : volumeCalculated.unit
                    }
                    disabled
                  />
                )}
              {sizeHeightFromFloor > 0 && (
                <InputValue
                  title={shortTitle ? t`HFF.` : t`Height from Floor`}
                  className="flex-1"
                  id_name={object + '-size-hff'}
                  value={sizeHeightFromFloor}
                  unitOfMeasure={unitMeasurement}
                  disabled
                />
              )}
            </div>
          </div>
        )}
        {hasPosition && (
          <div className="flex-1 mt-1">
            {showInputLabels && (
              <InputLabelGroup
                title={t`Position`}
                title_helper={unitMeasurement}
              />
            )}

            <div className="flex flex-col space-y-1">
              {_.isNumber(positionX) && (
                <InputValue
                  title={`X.`}
                  className="flex-1"
                  id_name={object + '-position-x'}
                  value={positionX}
                  disabled={disabled}
                />
              )}
              {_.isNumber(positionY) && (
                <InputValue
                  title={`Y.`}
                  className="flex-1"
                  id_name={object + '-position-y'}
                  value={positionY}
                  disabled={disabled}
                />
              )}
              {_.isNumber(positionZ) && (
                <InputValue
                  title={`Z.`}
                  className="flex-1"
                  id_name={object + '-position-z'}
                  value={positionZ}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {children}
    </>
  );
};
export default ObjectSize;
