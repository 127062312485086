import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { HelpItemListCard } from '../../dashboard/containers/HelpItemListCard';
import { HelpItemListCardsContainer } from '../../dashboard/containers/HelpItemListCardsContainer';
import SchemaHelper from '../SchemaHelper';
import { ImportSelectPanel } from '../panels/ImportSelectPanel';
import { getAssignmentSchema } from './schema/assignmentSchema';

const ImportAssignmentStartView: React.FC = () => {
  const { t } = useTranslation('importer');
  const importTemplatePath = '/app/assets/importer/csv';
  const importTemplateVersion = 'v24.04.001';
  const importTemplateDataType = 'assignment';
  const importTemplateNameMetric = `WareBee-WAREHOUSE_NAME-${importTemplateDataType}-${importTemplateVersion}-TEMPLATE`;

  return (
    <Container
      componentName="ImportSelectFileStep"
      col
      overflow
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        'bg-app-panel-dark',
      )}
    >
      <HeaderAppMenu title={t`Assignment`} />
      <ScreenTitle
        title={t`Assignment Import`}
        subtitle={t`Map fields`}
        isSticky
        truncate
      />
      <HelperMessage hasMargin hasPadding>
        <HelpItemListCardsContainer>
          <HelpItemListCard className="flex-1" transparent>
            <HelperMessage isLead textSize={'lead'}>
              {t`We need the following data`}
            </HelperMessage>
            <HelperMessage hasBefore textSize={'hero'}>
              {t`We will only ask you for a minimal set of data to build the warehouse model. Providing all the optional fields will allow WareBee to create a more precise digital twin model.`}
            </HelperMessage>
            <ImportSelectPanel
              className="mt-8"
              titleDataset={t`Assignment`}
              linkPath={`${importTemplatePath}/${importTemplateNameMetric}.zip`}
            />
          </HelpItemListCard>
          <HelperMessage className="flex-1" hasPadding>
            <HelperMessage isLead className="mt-2 pt-4" textSize={'xl'}>
              {t`Field Guide (Schema)`}
            </HelperMessage>
            <SchemaHelper schema={getAssignmentSchema(t)} />
          </HelperMessage>
        </HelpItemListCardsContainer>
      </HelperMessage>
    </Container>
  );
};

export default ImportAssignmentStartView;
