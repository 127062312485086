import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../common/utils';
import ScreenHeader from '../components/ScreenHeader';
import * as Icon from '../components/icons';
import NavBreadcrumbs from '../components/nav/NavBreadcrumbs';
import { getSimulationStatus } from '../dashboard/store/dashboard.helper';
import { DashboardSimulationItemStatus } from '../dashboard/store/dashboard.types';
import Tag from '../helpContext/Tag';
import { appIsEmbeddedState } from '../store/global.state';
import { warehouseSelected } from '../store/warehouse.state';
import { optimisationStatus } from './store/optimisation.state';
import {
  simulationAnalyzeStatus,
  simulationCurrent,
  simulationManualLock,
} from './store/simulation.state';

const SimulationHeader: React.FC = () => {
  const { t } = useTranslation('app');
  const { t: tSim } = useTranslation('simulation');
  const wh = useRecoilValue(warehouseSelected);
  const simulation = useRecoilValue(simulationCurrent);
  const analyzeStatus = useRecoilValue(simulationAnalyzeStatus);
  const optimizeStatus = useRecoilValue(optimisationStatus);
  const [isSimulationManuallyLocked, setIsManuallyLocked] =
    useRecoilState(simulationManualLock);

  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);
  if (isAppEmbedded) {
    return null;
  }
  if (!wh) return null;

  const breadcrumbItems = [
    { title: wh.title, href: `/wh/i/${wh.id}` },
    { title: t`Simulations`, href: `/wh/i/${wh.id}` },
  ];

  let status: DashboardSimulationItemStatus = null;

  if (simulation) {
    status = getSimulationStatus(
      {
        ...simulation,
        analyzeResult: {
          ...(simulation?.analyzeResult ?? {}),
          status: analyzeStatus,
        },
        // optimizeResult: {
        //   ...(simulation?.optimizationRuns?.content ?? {}),
        //   status: optimizeStatus,
        // },
      } as any,
      tSim,
    );
    breadcrumbItems.push({
      title: simulation?.title,
      href: `/wh/i/${wh.id}/simulations/${simulation?.id}`,
    });
  }

  return (
    <ScreenHeader backPath={`/wh/i/${wh.id}`} logo subtitle={t`WareBee`}>
      <NavBreadcrumbs items={breadcrumbItems}>
        <Tag
          // classType="border border-state-analyzed uppercase text-xxs"
          className="flex"
        >
          {isSimulationManuallyLocked ? (
            <Icon.Lock className={cn('mx-1 h-3 w-3 fill-current')} />
          ) : null}
          {/* {`${simulation?.status}`} */}
        </Tag>
      </NavBreadcrumbs>
    </ScreenHeader>
  );
};
export default SimulationHeader;
