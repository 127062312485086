import { useApolloClient } from '@apollo/client';
import {
  LoadAssignmentIssueDataDocument,
  LoadAssignmentIssueDataQuery,
  LoadAssignmentIssueDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  simulationIssuesAssignmentItemsData,
  simulationIssuesAssignmentItemsDataStatus,
  simulationIssueSelected,
} from '../store/simulation.issues.state';
import { simulationCurrent } from '../store/simulation.state';

export type AssignmentItemIssuesDataParams = {
  limit?: number;
  skip?: number;
  isAppend?: boolean;
  // filter?: ;
};

function useLoadAssignmentItemIssues() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Assignment Items with issues.`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: AssignmentItemIssuesDataParams) => {
        set(simulationIssuesAssignmentItemsDataStatus, AsyncLoadStatus.Loading);
      },
  );

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: AssignmentItemIssuesDataParams) => {
        const simulation = await snapshot.getPromise(simulationCurrent);
        const current = await snapshot.getPromise(
          simulationIssuesAssignmentItemsData,
        );
        const selectedIssue = await snapshot.getPromise(
          simulationIssueSelected,
        );
        const key = {
          assignmentId: simulation.assignment?.id,
          layoutId: simulation.layout?.id,
        };

        const query = client.watchQuery<
          LoadAssignmentIssueDataQuery,
          LoadAssignmentIssueDataQueryVariables
        >({
          query: LoadAssignmentIssueDataDocument,
          variables: {
            key,
            page: {
              limit: params.limit || DATASET_VIEW_PAGE_SIZE,
              skip: params.skip,
            },
            reason: selectedIssue?.reason as any,
          },
        });

        const queryObservable = query.subscribe(({ data, errors }) => {
          if (errors) {
            console.error(errors);
            set(errorAppender, {
              id: nanoid(),
              title: errorTitle,
              details: null,
              callStack: errors.map(e => e.message).join('. '),
            });
            set(
              simulationIssuesAssignmentItemsDataStatus,
              AsyncLoadStatus.Error,
            );
            return;
          }

          let content =
            data?.assignmentIssueSet?.invalidLocations?.content || [];
          if (params.isAppend) {
            content = [...current.content, ...content];
          }

          set(simulationIssuesAssignmentItemsData, {
            content,
            totalCount: data?.assignmentIssueSet?.invalidLocations?.totalCount,
          });

          set(simulationIssuesAssignmentItemsDataStatus, AsyncLoadStatus.Ok);
        });
        setObservable(queryObservable);
      },
  );

  async function call(params: AssignmentItemIssuesDataParams) {
    await initLoading(params);
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAssignmentItemIssues;
