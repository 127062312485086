import {
  LoadOrderLineSetDocument,
  LoadOrderLineSetQuery,
  LoadOrderLineSetQueryVariables,
  RunGenerateSimulationOrderLineSetDocument,
  RunGenerateSimulationOrderLineSetMutation,
  RunGenerateSimulationOrderLineSetMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import {
  simulationOrderLineSet,
  simulationOrderLineSetLoadStatus,
} from '../../simulation/store/simulation.state';
import { errorAppender } from '../../store/error.state';

export type LoadOrderLineSetParams = {
  simulationId: string;
};

function useLoadOrderLineSet() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load size order line set status`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(simulationOrderLineSetLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadOrderLineSetParams) => {
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationOrderLineSetLoadStatus, AsyncLoadStatus.Error);
          return;
        }
        const query = secureClient.watchQuery<
          LoadOrderLineSetQuery,
          LoadOrderLineSetQueryVariables
        >({
          query: LoadOrderLineSetDocument,
          variables: {
            simulationId: params.simulationId,
          },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let orderLineSet = data?.simulation?.orderLineSet;

            if (_.isNil(orderLineSet)) {
              try {
                const response = await secureClient.mutate<
                  RunGenerateSimulationOrderLineSetMutation,
                  RunGenerateSimulationOrderLineSetMutationVariables
                >({
                  mutation: RunGenerateSimulationOrderLineSetDocument,
                  variables: {
                    simulationId: params.simulationId,
                  },
                });
                orderLineSet = response.data?.generateSimulationOrderLineSet;
              } catch (ex) {
                console.error(errorTitle, ex);
                handleError(ex.message || ex, ex.stack || null);
              }
            }

            set(simulationOrderLineSet, orderLineSet);
            set(simulationOrderLineSetLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadOrderLineSetParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadOrderLineSet;
