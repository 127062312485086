import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type PolicyStatContainerProps = {
  children?: React.ReactNode;
  className?: string;
  color?: boolean;
  noPadding?: boolean;
};

export const PolicyStatContainer = ({
  children,
  className,
  color,
  noPadding = false,
}: PolicyStatContainerProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      data-component={`PolicyStatContainer`}
      id={id}
      className={classNames(
        // 'flex-1',
        'flex items-end',
        { 'px-1 py-2': !noPadding },
        color
          ? ''
          : 'text-menu-text/60 hover:text-menu-text group-hover:text-menu-100',
        className,
      )}
    >
      <div className={classNames('flex flex-1', 'divide-menu-500/50 divide-x')}>
        {children}
      </div>
    </div>
  );
};
