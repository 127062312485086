import * as React from 'react';
const CircleArrowFullScreenIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowFullScreenIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4C30.88,3.97,3.95,30.86,3.95,63.95s26.94,60.03,60.05,60.05c33.12.03,60.05-26.86,60.05-59.95S97.12,4.03,64,4ZM46.41,90.26l20.71.03-10.65,10.65-28.25-.04v-28.27s10.64-10.65,10.64-10.65v20.73s20.72-20.71,20.72-20.71l7.54,7.55-20.71,20.71ZM99.78,57.63l-10.65,10.65v-20.73s-20.72,20.71-20.72,20.71l-7.54-7.55,20.71-20.71-20.71-.03,10.65-10.65,28.25.04v28.27Z"
    />
  </svg>
);
export default CircleArrowFullScreenIcon;
