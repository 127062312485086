import * as React from 'react';
const PolicyOptimisationIcon = props => (
  <svg
    aria-hidden="true"
    data-name="PolicyOptimisationIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon points="74.42 104.16 66.6 96.94 58.79 104.16 66.6 111.42 74.42 104.16" />
    <polygon
      points="74.42 76.49 66.6 69.27 58.79 76.49 66.6 83.75 74.42 76.49"
      opacity={0.6}
    />
    <polygon
      points="58.79 21.15 66.6 13.93 74.42 21.15 66.6 28.41 58.79 21.15"
      opacity={0.6}
    />
    <polygon
      points="58.79 48.82 66.6 41.6 74.42 48.82 66.6 56.08 58.79 48.82"
      opacity={0.9}
    />
    <polygon points="87.99 90.32 80.17 83.1 72.36 90.32 80.17 97.58 87.99 90.32" />
    <polygon
      points="72.36 34.99 80.17 27.77 87.99 34.99 80.17 42.25 72.36 34.99"
      opacity={0.9}
    />
    <polygon
      points="72.36 62.65 80.17 55.44 87.99 62.65 80.17 69.92 72.36 62.65"
      opacity={0.9}
    />
    <polygon
      points="85.93 48.82 93.74 41.6 101.56 48.82 93.74 56.08 85.93 48.82"
      opacity={0.6}
    />
    <polygon
      points="85.93 76.49 93.74 69.27 101.56 76.49 93.74 83.75 85.93 76.49"
      opacity={0.6}
    />
    <polygon
      points="99.5 62.65 107.31 55.44 115.13 62.65 107.31 69.92 99.5 62.65"
      opacity={0.9}
    />
    <path d="m66.02,105.15c-.15,0-.31-.1-.42-.28-.2-.32-.24-.81-.09-1.2l15.11-41.01-14.53-41c-.14-.39-.09-.88.1-1.19.2-.31.5-.36.74-.12l40.71,41.5c.18.18.28.49.28.83s-.11.65-.28.83l-41.29,41.5c-.1.1-.21.15-.32.15Zm2.3-81.07l13.51,38.11c.11.31.11.69,0,.99l-14.02,38.06,38.37-38.57-37.85-38.59Z" />
    <path d="m93.74,75.44c-.1,0-.21-.04-.3-.13l-12.43-11.79-14.71-13.83c-.18-.17-.3-.49-.3-.83,0-.34.11-.66.29-.84l13.86-13.83c.14-.14.31-.18.47-.11.16.07.29.24.37.47l13.28,39.46c.13.38.09.86-.11,1.17-.12.18-.27.28-.42.28Zm-25.97-26.63l13.84,13.01,10.57,10.02-11.94-35.48-12.46,12.44Z" />
    <path d="m81.31,91.32c-.1,0-.21-.04-.3-.13l-14.71-13.83c-.18-.17-.3-.49-.3-.83,0-.34.1-.66.28-.84l27.14-27.67c.23-.23.52-.19.72.1.2.29.26.76.14,1.15l-12.43,41.5c-.07.24-.2.43-.36.51-.06.03-.12.04-.18.04Zm-13.55-14.84l13.29,12.5,11.23-37.49-24.52,25Z" />
    <path d="m122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65Zm-6.16,12.5l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z" />
  </svg>
);
export default PolicyOptimisationIcon;
