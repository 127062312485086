import {
  BaseEntitySortOption,
  Maybe,
  SkipLimitPageSpec,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { WizardStepConfig } from '../../common/types';

export enum DashboardItemStatusType {
  draft,
  progress,
  error,
  complete,
  inuse,
  data,
}

export enum DashboardViewMode {
  card,
  list,
  debug,
}

export enum DashboardOrder {
  title,
  createdAsc,
  createdDesc,
  updatedAsc,
  updatedDesc,
}

export type SimulationStage =
  | 'draft'
  | 'analysing'
  | 'analyseError'
  | 'analyzed'
  | 'allocating'
  | 'allocationError'
  | 'allocated'
  | 'optimising'
  | 'optimiseError'
  | 'optimised';

export type DashboardItemStatus = {
  type: DashboardItemStatusType;
  title: string;
  progress?: number;
};

export type DashboardSimulationItemStatus = DashboardItemStatus & {
  simulationStage: SimulationStage;
};

export type DashboardGroupState = {
  viewMode: DashboardViewMode;
  order: DashboardOrder;
  page: SkipLimitPageSpec;
  loadedCount?: number;
  totalCount?: number;
  autoUpdate?: boolean;
  loadedDate?: Date;
};

export type DashboardInfoCardProps = {
  listMode?: boolean;
  showButtonInline?: boolean;
  showButtonOnly?: boolean;
  isLoading: boolean;
};

export type SortTypeBase = {
  field: BaseEntitySortOption;
  direction?: Maybe<SortDirection>;
};

export type LoadDashboardParams = {
  sort: DashboardOrder;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: string;
};

export type DataDashboardPath =
  | 'layouts'
  | 'assignments'
  | 'orders'
  | 'items'
  | 'activity'
  | 'datasets'
  | 'policies'
  | 'files';

export type DataDashboardMenuItemId =
  | 'data-dash-data-all'
  | 'data-dash-layout'
  | 'data-dash-assignment'
  | 'data-dash-order'
  | 'data-dash-items'
  | 'data-dash-dataset-activityFeed'
  | 'data-dash-dataset-object'
  | 'data-dash-policies'
  | 'data-dash-files';

export const dataDashboardMenuToPath: Record<
  DataDashboardMenuItemId,
  DataDashboardPath
> = {
  'data-dash-layout': 'layouts',
  'data-dash-assignment': 'assignments',
  'data-dash-order': 'orders',
  'data-dash-items': 'items',
  'data-dash-dataset-object': 'datasets',
  'data-dash-dataset-activityFeed': 'activity',
  'data-dash-policies': 'policies',
  'data-dash-files': 'files',
  'data-dash-data-all': null,
};
export type DataDashboardMenuItem = WizardStepConfig<DataDashboardMenuItemId>;
