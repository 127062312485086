import classNames from 'classnames';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import LoadingIndicator from '../components/LoadingIndicator';
import StageContainer from '../layout/stage/StageContainer';
import { layoutDocumentLoadStatus } from '../layout/store/layout.state';
import ViewModeToolbar from '../layout/toolbar/ViewModeToolbar';
import ActiveFeatureLayer from '../layout/viewer/layers/ActiveFeatureLayer';
import AisleLayer from '../layout/viewer/layers/AisleLayer';
import BayLayer from '../layout/viewer/layers/BayLayer';
import HoveredFeatureLayer from '../layout/viewer/layers/HoveredFeatureLayer';
import { analyzeCompareResultByType } from './analyzeCompare.state';
import { AnalyzeCompareAnalyzeType } from './analyzeCompare.types';
import AnalyzeCompareLayoutPlaceholder from './components/AnalyzeCompareLayoutPlaceholder';
import AnalyzeCompareLocationLayer from './layers/AnalyzeCompareLocationLayer';

export type AnalyzeCompareLayoutViewProps = {
  analyzeType: AnalyzeCompareAnalyzeType;
  hideLayoutToolbar?: boolean;
};

const AnalyzeCompareLayoutView: React.FC<
  AnalyzeCompareLayoutViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const analyzeResultSummary = useRecoilValue(
    analyzeCompareResultByType(props.analyzeType),
  );
  const loadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const layoutId = analyzeResultSummary?.layout?.id;

  if (!layoutId) return <AnalyzeCompareLayoutPlaceholder />;

  if (loadStatus === AsyncLoadStatus.Loading) {
    return (
      <LoadingIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Loading Layout data...`}
      />
    );
  }

  return (
    <>
      <StageContainer type="simulation-area-view">
        <AisleLayer />
        <BayLayer />
        <AnalyzeCompareLocationLayer analyzeType={props.analyzeType} />
        <ActiveFeatureLayer />
        <HoveredFeatureLayer />
      </StageContainer>
      <Suspense
        fallback={
          <LoadingIndicator
            className={classNames(
              'left-0 right-0 absolute m-auto top-[45%] max-h-28 max-w-[12rem] shadow-2xl',
            )}
            absolute
            selfCenter
            backdrop
            message={t`Loading Optimised Layout...`}
          />
        }
      >
        {!props.hideLayoutToolbar && <ViewModeToolbar />}
        {/* <HtmlTooltipLayer isOptimisation={true} />
        <OptimisationLayoutLoadingAlerts /> */}
      </Suspense>
    </>
  );
};

export default AnalyzeCompareLayoutView;
