import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APP_VER } from './common/environmentVariables';
import { AppFooter } from './components/AppFooter';
import { ButtonSidebarToggle } from './components/ButtonSidebarToggle';
import { Button } from './components/actions/Button';
import { MailTo } from './components/actions/MailTo';
import { Alert } from './components/alerts/Alerts';
import * as Icon from './components/icons';
import { ContainerApp } from './components/layout/ContainerApp';
import { ContainerCol } from './components/layout/ContainerFlex';
import { Spacer } from './components/layout/Spacer';
import * as Logo from './components/logos';
import { ActionBar } from './components/nav/ActionBar';
import Callout from './helpContext/Callout';
import SidebarHelp from './screens/help/SidebarHelp';
import SidebarNotifications from './screens/notifications/SidebarNotifications';

export type ErrorOrigin =
  | 'notConnected'
  | 'unhandledException'
  | 'networkIssue';

export type ErrorFallbackProps = {
  errorOrigin: ErrorOrigin;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  return (
    <ContainerApp className="ErrorFallback h-full bg-menu-app">
      <div className="flex items-center w-full">
        <div className="flex flex-col items-center flex-1 w-full">
          <div className="flex items-center w-full p-2">
            <Logo.WareBeeLogoFull
              className={`h-12 fill-current text-menu-active`}
            />
            <Spacer flexspace className="" />

            <ul className="flex">
              <li className="text-menu-text border-app-panel-dark/50">
                <ButtonSidebarToggle
                  title={t`Notifications`}
                  noTitle
                  icon={Icon.AlertNotificationOn}
                  sidebarID="sidebar-global-notifications"
                />
              </li>
              <li className="ltr:border-l rtl:border-r text-menu-text border-app-panel-dark/50">
                <ButtonSidebarToggle
                  title={t`Help`}
                  icon={Icon.HelpBot}
                  sidebarID="sidebar-global-help"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ContainerCol overflow className={`items-center justify-center`}>
        {props.errorOrigin === 'networkIssue' && (
          <Alert
            hasStatusError
            message={'Network issue detected'}
            className="pr-6"
          />
        )}
        {props.errorOrigin === 'notConnected' && (
          <Alert
            hasStatusError
            message={t`Apologies, \n Looks like we've been disconnected from the Server`}
            className="pr-6"
          />
        )}
        {props.errorOrigin === 'unhandledException' && (
          <div
            data-component="ErrorIndicator"
            className={`flex relative items-center justify-center z-1000
      `}
          >
            <div
              className={`px-0 py-1 mx-2 mb-4 bg-alerts-error/90 backdrop-filter backdrop-blur-lg backdrop-saturate-110 text-white max-w-80 w-full rounded`}
            >
              <ActionBar transparent className="border-b border-white/25 mb-3">
                <Button
                  hasIconBefore
                  buttonIcon={
                    <Icon.ArrowLeft className={`w-5 h-5 fill-current`} />
                  }
                  buttonType="transparent"
                  buttonSize="sm"
                  label={t`Back`}
                  onPress={() => {
                    navigate(-1);
                  }}
                />
                <Spacer flexspace />
              </ActionBar>
              <div className={classNames('flex flex-col')}>
                <div
                  className={classNames(
                    'flex-1 text-xl flex items-center',
                    'ltr:pl-1 rtl:pr-1',
                  )}
                >
                  <Icon.TriangleInfo
                    className={classNames(
                      'w-12 h-12',
                      'pb-0.5 pt-1 ltr:pl-2 ltr:pr-4 rtl:ltr:pl-4 rtl:pr-4',
                      'fill-current',
                      'ltr:ml-1 rtl:mr-1',
                      'ltr:border-r rtl:border-l border-menu/50',
                    )}
                  />
                  <div
                    className={classNames(
                      'flex-1',
                      'ltr:ml-1 rtl:mr-1 ltr:pl-2 rtl:pr-2',
                      'h-full',
                    )}
                  >{t`Error Displaying`}</div>
                </div>

                {/* {er.details ? <div className={`mb-2`}>{er.details}</div> : null} */}

                <div className={classNames('px-4 mt-4 mb-4')}>
                  {t`Apologies, Something went wrong. Please tap the back button, hit refresh, or contact us at support@warebee.com.`}
                </div>

                <Callout type="suggestion" className="border-none">
                  <MailTo
                    email="support@warebee.com"
                    subject="[WAREBEE] ERROR"
                    body={`Hello, I need help with the following error: `}
                  >
                    {t`If you continue to see this message, please contact us at support@warebee.com`}
                  </MailTo>
                </Callout>

                <ActionBar
                  transparent
                  className={classNames('border-t border-menu/25 mt-3')}
                >
                  {/* <Button
                    hasIconBefore
                    buttonIcon={
                      <Icon.ArrowLeft className={`w-6 h-6 fill-current`} />
                    }
                    buttonType="transparent"
                    buttonSize="sm"
                    label={t`Back`}
                    onPress={() => {
                      navigate(`/`);
                    }}
                  /> */}
                  <Spacer flexspace />
                  <Button
                    hasIconAfter
                    buttonIcon={
                      <Icon.Reload
                        className={classNames('w-5 h-5 fill-current')}
                      />
                    }
                    buttonType="transparent"
                    buttonSize="sm"
                    label={t`Reload`}
                    onPress={() => window.location.reload()}
                  />
                </ActionBar>
              </div>
            </div>
          </div>
        )}
      </ContainerCol>

      <SidebarHelp />
      <SidebarNotifications />
      <AppFooter className={`px-6 py-4`}>
        <div className="flex flex-1 text-xs">
          <MailTo
            email="support@warebee.com"
            subject="[WAREBEE] Support"
            body={t`Hello, I need support with the following: \n 1.`}
          >
            <div>{t`Need Support?`}</div>
            <div className={`ltr:ml-1 rtl:mr-1`}>{t`support@warebee.com`}</div>
          </MailTo>
        </div>
        <div className="flex flex-1 text-xs">
          <div>{t`v.`}</div>
          <div className={`ltr:ml-1 rtl:mr-1`}>{APP_VER}</div>
        </div>
      </AppFooter>
    </ContainerApp>
  );
};
export default ErrorFallback;
