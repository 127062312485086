import {
  RoutingPolicyFragment,
  Vector,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  DIRECTION_EAST,
  DIRECTION_NONE,
  DIRECTION_NORTH,
  DIRECTION_SOUTH,
  DIRECTION_WEST,
} from './routing.default';
import {
  DirectionDescriptor,
  DirectionDescriptorId,
} from './routingPolicy.types';

export const ROUTING_POLICY_FALLBACK_ID = 'routing-policy-fallback-rule';

export const routingPolicyDefault: RoutingPolicyFragment = {
  rules: [],
  defaultRule: {
    featureRules: [],
  },
};

export const routingDirectionOptions = [
  null,
  DIRECTION_NORTH,
  DIRECTION_EAST,
  DIRECTION_SOUTH,
  DIRECTION_WEST,
];
export function getVectorKey(v: Vector): string {
  if (_.isNil(v?.x) || _.isNil(v?.y)) return 'null';
  const precession = 100;
  return `${Math.round(v.x * precession)}-${Math.round(v.y * precession)}`;
}

export function getDirectionDescriptors(
  t: TFunction<'simulation'>,
): DirectionDescriptor[] {
  return [
    {
      id: 'direction-any',
      title: t('Any', { ns: 'simulation' }),
      value: null,
    },
    {
      id: 'direction-north',
      title: t('North', { ns: 'simulation' }),
      value: DIRECTION_NORTH,
    },

    {
      id: 'direction-east',
      title: t('East', { ns: 'simulation' }),
      value: DIRECTION_EAST,
    },
    {
      id: 'direction-south',
      title: t('South', { ns: 'simulation' }),
      value: DIRECTION_SOUTH,
    },
    {
      id: 'direction-west',
      title: t('West', { ns: 'simulation' }),
      value: DIRECTION_WEST,
    },
    {
      id: 'direction-none',
      title: t('Block', { ns: 'simulation' }),
      value: DIRECTION_NONE,
    },
  ];
}

export function getDirectionDescriptorsMapById(
  t: TFunction<'simulation'>,
): Record<DirectionDescriptorId, DirectionDescriptor> {
  return _.keyBy(getDirectionDescriptors(t), d => d.id) as Record<
    DirectionDescriptorId,
    DirectionDescriptor
  >;
}

export const getDirectionDescriptorsMapByVector = _.memoize(
  (t: TFunction<'simulation'>): Record<string, DirectionDescriptor> => {
    return _.keyBy(getDirectionDescriptors(t), d => getVectorKey(d.value));
  },
);
