import _ from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { cn } from '../common/utils';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { HeaderAppMenu } from '../components/headers/HeaderAppMenu';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { Spacer } from '../components/layout/Spacer';
import FeedDatePicker from '../feed/components/FeedDatePicker';
import FeedCommonInfoSidebar from '../feed/sidebar/FeedCommonInfoSidebar';
import LayoutActiveFeatureTag from '../layout/LayoutActiveFeatureTag';
import { useLoadLayout } from '../layout/hooks/useLoadLayout';
import { layoutDocumentLoadStatus } from '../layout/store/layout.state';
import useUserPermissionFeatures from '../permissions/roles/hooks/useUserPermissionFeatures';
import { resourcePolicySelectedAgentId } from '../simulation/store/resourcePolicy.state';
import { appIsEmbeddedState } from '../store/global.state';
import { sidebarStateByType } from '../store/sidebar.state';
import ActualityExtraDatasetView from './ActualityExtraDatasetView';
import FeedHeader from './FeedHeader';
import FeedActiveFiltersTags from './components/FeedActiveFiltersTags';
import FeedLayoutViewSwitch from './components/FeedLayoutViewSwitch';
import useActualityStatusWatcher from './hooks/useActualityStatusWatcher';
import useActualityTimeWatcher from './hooks/useActualityTimeWatcher';
import { useLoadActuality } from './hooks/useLoadActuality';
import FeedActivitySwarmRoot from './layout/FeedActivitySwarmRoot';
import FeedPolicyAgentViewContainer from './layout/FeedPolicyAgentViewContainer';
import FeedSetup from './layout/FeedSetup';
import ViewerActivityFeed from './layout/ViewerActivityFeed';
import ViewerJobsTimeline from './layout/ViewerJobsTimeline';
import ActualityHeatmapBucketsSidebar from './sidebar/ActualityHeatmapBucketsSidebar';
import FeedEventsSidebar from './sidebar/FeedEventsSidebar';
import FeedLocationSidebar from './sidebar/FeedLocationSidebar';
import FeedMainSidebar from './sidebar/FeedMainSidebar';
import FeedMenuSidebar from './sidebar/FeedMenuSidebar';
import {
  actualityMainViewType,
  actualitySelectedDocument,
  actualitySelectedDocumentStatus,
} from './store/actuality.state';
import {
  activityFeedSidebarMenuState,
  feedActivitySelectedTabId,
  feedLayoutId,
  feedSelectedAgentId,
  feedSelectedDateRange,
  feedSelectedMenuItemId,
} from './store/feed.state';

type FeedRootParams = {
  actualityId: string;
  isEmbedded: string;
};

export const FeedRoot: React.FC = () => {
  const { t } = useTranslation('feed');
  const { actualityId, isEmbedded: isEmbeddedParam } =
    useParams<FeedRootParams>();
  const actuality = useRecoilValue(actualitySelectedDocument);
  const showFeedSetup = !actuality?.activityFeedId || !actuality?.layoutId;
  const actualityLoadStatus = useRecoilValue(actualitySelectedDocumentStatus);
  const resetSelectedDateRange = useResetRecoilState(feedSelectedDateRange);

  const layoutId = useRecoilValue(feedLayoutId);
  const layoutLoadStatus = useRecoilValue(layoutDocumentLoadStatus);

  const [activeMenuId, setActiveMenuId] = useRecoilState(
    feedSelectedMenuItemId(actualityId),
  );
  const activityTabId = useRecoilValue(feedActivitySelectedTabId);
  const [selectedAgentId, setSelectedAgentId] =
    useRecoilState(feedSelectedAgentId);
  const [loadLayout, cleanupLayout] = useLoadLayout();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadActuality, unloadActuality] = useLoadActuality();

  useActualityTimeWatcher();
  useActualityStatusWatcher();
  const isEmbeddedAtom = useRecoilValue(appIsEmbeddedState);

  const hasHeader =
    _.isNil(searchParams.get('token')) &&
    _.isNil(isEmbeddedParam) &&
    !isEmbeddedAtom;
  const rpSelectedAgentId = useRecoilValue(resourcePolicySelectedAgentId);
  const setSidebarState = useSetRecoilState(
    sidebarStateByType('sidebar-feed-info-event'),
  );
  const mainViewType = useRecoilValue(actualityMainViewType);

  const [showActivityFeedSidebarMenu, setShowMenuSidebarFeed] = useRecoilState(
    activityFeedSidebarMenuState,
  );

  const visibleFeatures = useUserPermissionFeatures();
  const isSidebarFeedMenuVisible = visibleFeatures.find(
    f => f.id === 'sidebar-feed-menu',
  )?.visible;

  const isSidebarFeedMainVisible = visibleFeatures.find(
    f => f.id === 'sidebar-feed-main',
  )?.visible;

  useEffect(() => {
    if (!_.isNil(actualityId)) {
      resetSelectedDateRange();
      loadActuality(actualityId);
    }
    return () => {
      unloadActuality();
    };
  }, [actualityId]);

  useEffect(() => {
    if (!_.isNil(layoutId)) {
      loadLayout(layoutId);
    }
    return () => {
      cleanupLayout();
    };
  }, [layoutId]);

  useEffect(() => {
    setSidebarState({
      isHidden: !selectedAgentId,
      isCollapsed: !selectedAgentId,
    });
  }, [selectedAgentId]);

  const isReady = actualityLoadStatus === AsyncLoadStatus.Ok;
  // &&
  //layoutLoadStatus === AsyncLoadStatus.Ok;

  const showLocationViews =
    activeMenuId === 'feed-menu-setup' ||
    (activeMenuId === 'feed-menu-activity' && activityTabId !== 'tab-feed-kpi');

  const showKPIView =
    activeMenuId === 'feed-menu-activity' && activityTabId === 'tab-feed-kpi';

  const showEventsSidebar =
    activeMenuId === 'feed-menu-activity' &&
    activityTabId === 'tab-feed-process';

  const showHeatmapLegendSidebar =
    (activeMenuId === 'feed-menu-activity' &&
      activityTabId === 'tab-feed-heatmaps') ||
    (activeMenuId === 'feed-menu-activity' &&
      activityTabId === 'tab-feed-items');

  function getContent() {
    if (activeMenuId === 'feed-menu-setup' && !_.isNil(rpSelectedAgentId)) {
      return (
        <Container col overflow flex1 componentName={`ActualityResources`}>
          <HeaderAppMenu
            hasControls={false}
            title={t`Agent details`}
            className="!bg-sidebar-header/70"
          />
          <FeedPolicyAgentViewContainer />
        </Container>
      );
    }
    if (_.isNil(actuality.layoutId) || _.isNil(actuality.activityFeedId)) {
      return <FeedSetup />;
    }

    if (showKPIView) {
      return <FeedActivitySwarmRoot />;
    }

    return (
      <>
        <Container col overflow flex1 componentName={`ActualityViewContainer`}>
          <ViewerActivityFeed />
        </Container>

        <ActualityExtraDatasetView />
      </>
    );
  }

  if (!isReady) {
    return (
      <>
        {hasHeader && <FeedHeader />}
        <ContainerMain data-component="activity-feed-container">
          <LoadingIndicator selfCenter message={t`Loading Data...`} />
        </ContainerMain>
      </>
    );
  }
  return (
    <>
      {hasHeader && <FeedHeader />}

      <ContainerMain data-component="activity-feed-container">
        <div
          data-component="sidebar-left-container"
          className={cn('sidebar-wrapper side-left flex')}
        >
          {isSidebarFeedMenuVisible && showActivityFeedSidebarMenu && (
            <FeedMenuSidebar />
          )}
          {isSidebarFeedMainVisible && <FeedMainSidebar />}

          {!showFeedSetup && (
            <>
              {showEventsSidebar && <FeedEventsSidebar />}
              {showHeatmapLegendSidebar && <ActualityHeatmapBucketsSidebar />}
            </>
          )}
        </div>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorIndicator
              selfCenter
              message={error.message}
              hasReloadButton
              exception={error}
            />
          )}
        >
          <Suspense
            fallback={
              <Container hasOverflowY col>
                <HeaderAppMenu
                  isLoading
                  title={showKPIView ? t`Loading...` : t`Loading Feed...`}
                />
                <LoadingIndicator
                  className={cn(
                    'bg-map-area-striped',
                    'border-app-background border-8',
                  )}
                  selfCenter
                  message={
                    showKPIView ? t`Loading Data Chart` : t`Loading Feed...`
                  }
                />
              </Container>
            }
          >
            <Container
              col
              componentName="FeedActivityRoot"
              className={cn('border-app-panel-dark', 'border-x-px')}
            >
              <PanelHeader
                title={showFeedSetup ? t`Activity Feed Setup` : null}
              >
                {!showFeedSetup && (
                  <>
                    <FeedDatePicker />
                    <Spacer flexspace />
                    {!showKPIView && (
                      <>
                        <LayoutActiveFeatureTag />
                        <FeedActiveFiltersTags />
                      </>
                    )}
                    <FeedLayoutViewSwitch />
                  </>
                )}
              </PanelHeader>
              <Suspense
                fallback={
                  <LoadingIndicator
                    className={cn(
                      'bg-map-area-striped',
                      'border-app-background border-8',
                    )}
                    selfCenter
                    message={
                      showKPIView ? t`Loading Data Chart` : t`Loading Feed...`
                    }
                  />
                }
              >
                {mainViewType === 'timeline' && <ViewerJobsTimeline />}

                {getContent()}

                {/* <DatasetViewer mode="actuality"></DatasetViewer> */}
              </Suspense>
            </Container>
          </Suspense>
        </ErrorBoundary>
        {!showFeedSetup && (
          <div
            data-component="sidebar-right-container"
            className={cn(
              'sidebar-wrapper side-right',
              'flex',
              'border-app-panel-dark',
              'ltr:border-l rtl:border-r',
            )}
          >
            {showLocationViews && (
              <>
                <FeedLocationSidebar />
                <FeedCommonInfoSidebar />
              </>
            )}
          </div>
        )}
      </ContainerMain>
    </>
  );
};

export default FeedRoot;
