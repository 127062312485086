import { Field, ID, ObjectType } from '@warebee/shared/util-backend-only-types';

@ObjectType()
export class PickingRequirementDetails {
  @Field(() => ID, { nullable: true })
  startLocationId?: string;

  @Field(() => ID, { nullable: true })
  endLocationId?: string;
}
