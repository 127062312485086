import * as React from 'react';
const AlignHorizontalRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlignHorizontalRightIcon"
    {...props}
  >
    <rect width={4.44} height={120} x={111.56} y={4} strokeWidth={0} />
    <rect width={100} height={28} x={5.78} y={47} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M102.69,101.58c.09.09.18.2.25.31.03.05.06.11.09.16.03.06.07.12.1.19.03.07.04.14.06.21.02.06.04.11.05.17.05.26.05.53,0,.78-.01.06-.03.11-.05.17-.02.07-.04.14-.06.2-.03.07-.06.13-.1.19-.03.05-.05.11-.09.16-.07.11-.16.21-.25.31l-16,16c-.78.78-2.05.78-2.83,0-.39-.39-.59-.9-.59-1.41,0-.51.2-1.02.59-1.41l12.59-12.59h-30.17c-1.1,0-2-.9-2-2s.9-2,2-2h30.17s-12.59-12.59-12.59-12.59c-.39-.39-.59-.9-.59-1.41,0-.51.2-1.02.59-1.41.78-.78,2.05-.78,2.83,0l16,16Z"
    />
    <polygon
      strokeWidth={0}
      points="105.71 10.99 105.82 11.11 105.82 24.69 92.13 10.99 105.71 10.99"
    />
    <polygon
      strokeWidth={0}
      points="40.82 38.99 40.82 30.96 48.85 38.99 40.82 38.99"
    />
    <polyline
      strokeWidth={0}
      points="78.01 13.85 75.16 10.99 88.73 10.99 105.82 28.08 105.82 38.99 103.16 38.99 78.01 13.85 78.01 13.85"
    />
    <polygon
      strokeWidth={0}
      points="41.22 10.99 54.79 10.99 82.79 38.99 69.22 38.99 41.22 10.99"
    />
    <polygon
      strokeWidth={0}
      points="99.73 38.96 86.15 38.96 58.19 10.99 71.76 10.99 99.73 38.96"
    />
    <polygon
      strokeWidth={0}
      points="52.25 38.99 40.82 27.57 40.82 13.99 65.82 38.99 52.25 38.99"
    />
  </svg>
);
export default AlignHorizontalRightIcon;
