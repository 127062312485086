import Flatten from '@flatten-js/core';

interface Cloneable {
  clone(): this;
}

export type CloneableIndexableElement = Flatten.PlanarSetEntry & Cloneable;

export interface ShapeHolder<
  S extends CloneableIndexableElement = CloneableIndexableElement,
> {
  id: string;
  shape: S;
}

type IdentifiedShape = CloneableIndexableElement & {
  id: string;
};

export interface MultiShapeHolder<
  S extends CloneableIndexableElement = CloneableIndexableElement,
> {
  id: string;
  shapes: S[];
}

export type ShapeIndexInput<
  S extends CloneableIndexableElement = CloneableIndexableElement,
> = ShapeHolder<S> | MultiShapeHolder<S>;

function isMultiShapeHolder(s: ShapeIndexInput): s is MultiShapeHolder {
  return (s as any).shapes;
}

export class ShapeIndex<
  S extends CloneableIndexableElement = CloneableIndexableElement,
> {
  private readonly index: Flatten.PlanarSet = new Flatten.PlanarSet();
  private readonly shapesById: Record<string, CloneableIndexableElement[]> = {};

  constructor(shapes?: ShapeIndexInput<S>[]) {
    if (shapes) {
      this.add(shapes);
    }
  }

  private convertFound(found: Flatten.PlanarSetEntry[]): ShapeHolder<S>[] {
    return (found as IdentifiedShape[]).map(
      s =>
        ({
          id: s.id,
          shape: s,
        }) as unknown as ShapeHolder<S>,
    );
  }

  search(box: Flatten.Box): ShapeHolder<S>[] {
    return this.convertFound(this.index.search(box));
  }

  hit(pt: Flatten.Point) {
    return this.convertFound(this.index.hit(pt));
  }

  forEach(consumer: (s: ShapeHolder<S>) => void) {
    this.index.forEach((s: IdentifiedShape) => {
      consumer({
        id: s.id,
        shape: s as unknown as S,
      });
    });
  }

  add(shapes: ShapeIndexInput<S> | ShapeIndexInput<S>[]) {
    this.update(shapes);
  }

  update(shapes: ShapeIndexInput<S> | ShapeIndexInput<S>[]) {
    if (Array.isArray(shapes)) {
      shapes.forEach(s => this.updateOne(s));
    } else {
      this.updateOne(shapes);
    }
  }

  private updateOne(h: ShapeIndexInput<S>) {
    if (this.shapesById[h.id]) {
      this.shapesById[h.id].forEach(shape => this.index.delete(shape));
    }
    if (isMultiShapeHolder(h)) {
      const effectiveShapes = h.shapes.map(s =>
        Object.assign(s.clone(), { id: h.id }),
      );
      effectiveShapes.forEach(s => this.index.add(s));
      this.shapesById[h.id] = effectiveShapes;
    } else {
      const effectiveShape = Object.assign(h.shape.clone(), { id: h.id });
      this.index.add(effectiveShape);
      this.shapesById[h.id] = [effectiveShape];
    }
  }

  remove(shapes: ShapeIndexInput<S> | ShapeIndexInput<S>[]) {
    if (Array.isArray(shapes)) {
      shapes.forEach(s => this.removeOne(s));
    } else {
      this.removeOne(shapes);
    }
  }

  private removeOne(s: ShapeIndexInput<S>) {
    if (this.shapesById[s.id]) {
      this.shapesById[s.id].forEach(shape => this.index.delete(shape));
      delete this.shapesById[s.id];
    }
  }
}
