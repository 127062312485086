import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import InboxZero from '../components/InboxZero';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import {
  updateAllPolicyFilterValues,
  updatePolicyFilterValues,
} from '../policyFilters/policyFilter.helper';
import useLoadItemFilterValues from '../policyFilters/useLoadItemFilterValues';
import useLoadLocationFilterValues from '../policyFilters/useLoadLocationFilterValues';
import {
  getLocationFilterConfigCommon,
  getProductFilterConfigCommon,
} from '../simulation/store/assignmentPolicy.default';
import SwapPolicyLocationFilterEditor from './SwapPolicyLocationFilterEditor';
import SwapPolicyModeEditor from './SwapPolicyModeEditor';
import SwapPolicyProductFilterEditor from './SwapPolicyProductFilterEditor';
import { swapPolicyRule, swapPolicySelectedIdentity } from './swapPolicy.state';

const SwapPolicyEditorPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const filterIdentity = useRecoilValue(swapPolicySelectedIdentity);
  const rule = useRecoilValue(swapPolicyRule(filterIdentity?.ruleId));
  const [loadItemValues, cancelLoadItemValues] = useLoadItemFilterValues();
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  const showProductFilters = filterIdentity?.type === 'items';
  const showLocationFilters = filterIdentity?.type === 'locations';
  const showModeEditor = filterIdentity?.type === 'mode';

  const productFiltersConfig = getProductFilterConfigCommon(t, formatter);
  const locationFiltersConfig = getLocationFilterConfigCommon(t);

  useEffect(() => {
    if (showProductFilters) {
      updatePolicyFilterValues({
        filterConfig: productFiltersConfig,
        selectedFilterId: filterIdentity.filterId,
        policyFilterUnion:
          filterIdentity?.group === 'source'
            ? rule?.src?.itemsMatch
            : rule?.dest?.itemsMatch,
        load: loadItemValues,
        cancel: cancelLoadItemValues,
      });
    }
    if (showLocationFilters) {
      updateAllPolicyFilterValues({
        filterConfig: locationFiltersConfig,
        selectedFilterId: filterIdentity.filterId,
        policyFilterUnion:
          filterIdentity?.group === 'source'
            ? rule?.src?.locationsMatch
            : rule?.dest?.locationsMatch,
        load: loadLocationValues,
        cancel: cancelLoadLocationValues,
      });
    }
  }, [filterIdentity]);

  if (!filterIdentity || !rule) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${rule.title}`}
        isSticky
      />
      {showModeEditor && <SwapPolicyModeEditor />}
      {showProductFilters &&
        _.map(productFiltersConfig, configItem => (
          <SwapPolicyProductFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadItemValues}
            cancelLoadData={cancelLoadItemValues}
          />
        ))}

      {showLocationFilters &&
        _.map(locationFiltersConfig, configItem => (
          <SwapPolicyLocationFilterEditor
            key={`policy-filter-editor-${configItem.type}`}
            filterConfig={configItem}
            startLoadData={loadLocationValues}
            cancelLoadData={cancelLoadLocationValues}
          />
        ))}
    </Container>
  );
};

export default SwapPolicyEditorPanel;
