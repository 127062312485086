import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import useCheckLocationSharingPolicy from '../../assignmentPolicy/useCheckLocationSharingPolicy';
import { AsyncLoadStatus } from '../../common/types';
import { getIndexedTitle } from '../../common/utils';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { StatusTag } from '../../components/nav/StatusTag';
import { PolicyWell } from '../../components/policies/PolicyWell';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import LocationSharingPolicyRule from '../../locationSharingPolicy/LocationSharingPolicyRule';
import LocationSharingPolicyRuleDetailWatcher from '../../locationSharingPolicy/LocationSharingPolicyRuleDetailWatcher';
import { collapsibleStateAtom } from '../../store/collapsible.state';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import {
  LSP_FALLBACK_RULE_ID,
  getLocationSharingPolicyInput,
} from '../store/locationSharingPolicy/locationSharingPolicy.default';
import {
  locationSharingPolicy,
  locationSharingPolicyCheckResult,
  locationSharingPolicySelectedRuleId,
} from '../store/locationSharingPolicy/locationSharingPolicy.state';
import { optimisationIsEditable } from '../store/optimisation.state';
import ActionBarNextStep from './ActionBarNextStep';

const LocationSharingPolicySidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const [policy, setPolicy] = useRecoilState(locationSharingPolicy);
  const checkResult = useRecoilValue(locationSharingPolicyCheckResult);
  const [checkAssignmentPolicy, cancelCheckAssignmentPolicy] =
    useCheckLocationSharingPolicy();
  const selectedRuleId = useRecoilValue(locationSharingPolicySelectedRuleId);
  const updateSim = useUpdateSimulation();

  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const selectStep = useSelectSimulationWizardStep();
  const canUpdate = useRecoilValue(optimisationIsEditable);
  const [policyIsLoaded, setPolicyIsLoaded] = useState(false);

  useEffect(() => {
    if (_.isNil(policy)) return;

    if (policyIsLoaded) {
      updateSim({
        locationSharingPolicy: getLocationSharingPolicyInput(policy) as any,
      });
    } else {
      //do not call update if policy just loaded ( value changed from "null" to  "{...}")
      setPolicyIsLoaded(true);
    }

    cancelCheckAssignmentPolicy();
    checkAssignmentPolicy();
  }, [policy]);

  const removeRule = (ruleId: string) => {
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy.rules, r => r.title)),
      t`Rule #`,
    );
    setPolicy({
      ...policy,
      rules: [
        ...(policy?.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          maxItemsPerLocation: 1,
          locationsMatch: { anyOf: [] },
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  function moveRule(from, to) {
    const newRules = [...policy.rules];
    newRules.splice(to, 0, newRules.splice(from, 1)[0]);
    setPolicy({
      ...policy,
      rules: newRules,
    });
  }

  const isCheckLoading = checkResult?.status !== AsyncLoadStatus.Ok;

  return (
    <>
      <ContainerScroll className="flex flex-col">
        <ScreenTitle
          title={t`Location Sharing Policy`}
          subtitle={t`Max Items in Locations`}
          isSticky
        />
        {!canUpdate && (
          <InboxZero
            className="p-0 mx-4 mb-4 shadow-2xl"
            classNameInner="py-2"
            center={false}
            icon={Icon.Lock}
            message={t`Simulation is Analysed,\n Policy is locked`}
          />
        )}

        <Container col flex1>
          <TitleSection
            title={
              <div className="flex items-center">
                {/* {!isFeasible && (
                  <Icon.TriangleInfo
                    className={`w-4 h-4 ltr:mr-1 rtl:ml-1 fill-current text-alerts-alert`}
                  />
                )} */}
                {t`Policy Rules`}
                {policy?.rules?.length > 0 && (
                  <span className="opacity-70 mx-2 text-xxs">
                    ({policy?.rules.length})
                  </span>
                )}
              </div>
            }
            inSidebarView
            hasScreenTitle
            hasAction={
              canUpdate ? (
                <Button
                  label={t`Add Policy`}
                  className="ltr:ml-4 rtl:mr-4 rounded"
                  buttonSize="xs"
                  buttonType="primary"
                  hasIconAfter={
                    <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                  }
                  onPress={() => addRule()}
                />
              ) : (
                <StatusTag
                  title={t`Policy Locked`}
                  type="locked"
                  modeStyle="stroke"
                  icon={Icon.Lock}
                  onPress={null}
                />
              )
            }
          />
          <LocationSharingPolicyRuleDetailWatcher />
          <PolicyWell
            className={classNames(`bg-issue-transparent`, {
              'bg-app-panel-dark flex-1 pb-10 mb-2': canUpdate,
              'bg-app-panel-dark': !canUpdate,
            })}
            // className={`bg-issue-transparent ${
            //   (!canUpdate ? ' bg-app-panel-dark' : 'flex-1 bg-app-panel-dark pb-10 mb-2') ||
            //   ''
            // }`}
          >
            {_.map(policy?.rules, (rule, index) => {
              const ruleCheckResult = checkResult?.result?.rules?.find(
                r => r.id === rule.id,
              );
              const locationCount: [number, number] = [
                ruleCheckResult?.locationFilterMatch?.total,
                ruleCheckResult?.locationFilterMatch?.exclusive,
              ];

              return (
                <LocationSharingPolicyRule
                  isDisabled={!canUpdate}
                  key={`location-sharing-rule-${rule.id}`}
                  ruleId={rule.id}
                  index={index + 1}
                  canRename={true}
                  canDelete={true}
                  onDeleteClick={() => removeRule(rule.id)}
                  canMoveUp={index > 0}
                  onMoveUp={() => moveRule(index, index - 1)}
                  canMoveDown={index < policy.rules.length - 1}
                  onMoveDown={() => moveRule(index, index + 1)}
                  isActive={selectedRuleId === rule.id}
                  isCheckLoading={isCheckLoading}
                  hasLocationFilters={true}
                  locationCount={locationCount}
                />
              );
            })}

            {policy?.rules?.length === 0 && (
              <InboxZero selfCenter hasIcon message={t`No Rules found`}>
                {canUpdate && (
                  <Button
                    full
                    buttonSize="sm"
                    buttonType="primary"
                    label={t`Add Policy`}
                    className="flex-1 rounded"
                    onPress={addRule}
                    hasIconAfter
                    buttonIcon={
                      <Icon.CirclePlus className={`w-5 h-5 fill-current`} />
                    }
                  />
                )}
              </InboxZero>
            )}
          </PolicyWell>

          <TitleSection title={t`Default Policy`} inPanelView />
          <PolicyWell fullHeight isDisabled={!canUpdate}>
            <LocationSharingPolicyRule
              isDisabled={!canUpdate}
              key={LSP_FALLBACK_RULE_ID}
              ruleId={LSP_FALLBACK_RULE_ID}
              hasLocationFilters={false}
              canDelete={false}
              canMoveUp={false}
              canMoveDown={false}
              canRename={false}
              isDefault={true}
              isCheckLoading={isCheckLoading}
              locationCount={[
                checkResult?.result?.fallbackLocationFilterMatch?.total,
                checkResult?.result?.fallbackLocationFilterMatch?.exclusive,
                checkResult?.result?.allLocations?.exclusive,
              ]}
            />
          </PolicyWell>
        </Container>
      </ContainerScroll>

      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-location-sharing',
    title: t`Location Policy`,
    loadingMessage: t`Loading Location Policy`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <LocationSharingPolicySidebar />
    </SidebarContainer>
  );
};
