import {
  BatchJobStatus,
  CheckSimulationPickingPolicyResultFragment,
  LocationFilterIntersectionFragment,
  OrderLineFilterIntersectionFragment,
  PickingPolicyFragment,
  PickingPolicyOrderLineRuleFragment,
  PickingPolicyPickingRuleFragment,
  SimulationOrderLinesConnectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../../layout/viewer/store/viewer.state';
import { resourcePolicyPickingAgents } from '../resourcePolicy.state';
import {
  simulationCurrent,
  simulationOrderLineSet,
  simulationUoms,
} from '../simulation.state';
import { simulationWizardSelectedStepId } from '../simulation.wizard.state';
import { loadLocationsByRule } from './pickingPolicy.helper';
import {
  PickingDetailsSelectionIdentity,
  PickingPolicyReadyStatus,
  PickingRuleIdentity,
  PickingSelectionIdentity,
} from './pickingPolicy.types';

const getKey = (postfix: string) => `warebee-picking-policy-${postfix}`;

export const pickingPolicy = atom<PickingPolicyFragment>({
  key: getKey('document'),
  default: null,
});

export const pickingPolicyRule = selectorFamily<
  PickingPolicyOrderLineRuleFragment,
  string
>({
  key: getKey('rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      const policy = get(pickingPolicy);
      // if (ruleId === LSP_FALLBACK_RULE_ID)
      //   return get(pickingPolicyFallbackRule);
      return _.find(policy?.orderLineRules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, newRule: PickingPolicyOrderLineRuleFragment) => {
      const policy = get(pickingPolicy);
      set(pickingPolicy, {
        ...policy,
        orderLineRules: policy.orderLineRules.map(rule =>
          rule.id === ruleId ? newRule : rule,
        ),
      });
    },
});

export const pickingPolicySelectedIdentityAtom = atom<PickingSelectionIdentity>(
  {
    key: getKey('selected-identity-atom'),
    default: null,
  },
);

export const pickingPolicySelectedIdentity = selector<PickingSelectionIdentity>(
  {
    key: getKey('selected-identity'),
    get: ({ get }) => get(pickingPolicySelectedIdentityAtom),
    set: ({ get, set }, value: PickingSelectionIdentity) => {
      set(pickingPolicySelectedIdentityAtom, value);
    },
  },
);

export const pickingPolicySelectedOrderLineIntersection =
  selector<OrderLineFilterIntersectionFragment>({
    key: getKey('selected-order-line-filter-intersection'),
    get: ({ get }) => {
      const identity = get(pickingPolicySelectedIdentity);
      if (!identity?.orderLineFilterId || !identity?.ruleId) return null;

      const rule = get(pickingPolicyRule(identity.ruleId));
      if (!rule) return null;

      const filterIntersection = _.find(
        rule.orderLinesMatch.anyOf,
        fs => fs.id === identity.orderLineFilterId,
      );
      return filterIntersection;
    },
    set: ({ get, set }, value: OrderLineFilterIntersectionFragment) => {
      const identity = get(pickingPolicySelectedIdentity);
      if (!identity?.orderLineFilterId || !identity?.ruleId) {
        throw new Error('Invalid  parameters. Filter identity is invalid');
      }

      const rule = get(pickingPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(pickingPolicyRule(identity.ruleId), {
        ...rule,
        orderLinesMatch: {
          ...rule.orderLinesMatch,
          anyOf: _.map(rule.orderLinesMatch.anyOf, fs =>
            fs.id === identity.orderLineFilterId ? value : fs,
          ).filter(fs => !!fs),
        },
      });
    },
  });

export const pickingPolicyPickingRule = selectorFamily<
  PickingPolicyPickingRuleFragment,
  PickingRuleIdentity
>({
  key: getKey('picking-rule-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.ruleId) || _.isNil(identity?.pickingRuleId))
        return null;
      const rule = get(pickingPolicyRule(identity.ruleId));
      return _.find(
        rule?.pickingRules,
        pickRule => pickRule.id === identity.pickingRuleId,
      );
    },
  set:
    identity =>
    ({ get, set }, newRule: PickingPolicyPickingRuleFragment) => {
      if (_.isNil(identity?.ruleId) || _.isNil(identity?.pickingRuleId))
        return null;
      const rule = get(pickingPolicyRule(identity.ruleId));
      set(pickingPolicyRule(identity.ruleId), {
        ...rule,
        pickingRules: rule.pickingRules.map(rule =>
          rule.id === identity.pickingRuleId ? newRule : rule,
        ),
      });
    },
});

export const pickingPolicySelectedLocationIntersection =
  selector<LocationFilterIntersectionFragment>({
    key: getKey('selected-location-filter-intersection'),
    get: ({ get }) => {
      const identity = get(pickingPolicySelectedIdentity);
      if (
        _.isNil(identity?.pickingRuleId) ||
        _.isNil(identity?.locationFilterId) ||
        _.isNil(identity?.ruleId)
      )
        return null;

      const pickingRule = get(
        pickingPolicyPickingRule({
          ruleId: identity.ruleId,
          pickingRuleId: identity.pickingRuleId,
        }),
      );
      if (_.isNil(pickingRule)) return null;

      const filterIntersection = _.find(
        pickingRule.locationsMatch.anyOf,
        fs => fs.id === identity.locationFilterId,
      );
      return filterIntersection;
    },
    set: ({ get, set }, value: LocationFilterIntersectionFragment) => {
      const identity = get(pickingPolicySelectedIdentity);
      if (!identity?.locationFilterId || !identity?.ruleId) {
        throw new Error('Invalid  parameters. Filter identity is invalid');
      }

      const pickingRule = get(
        pickingPolicyPickingRule({
          ruleId: identity.ruleId,
          pickingRuleId: identity.pickingRuleId,
        }),
      );

      if (_.isNil(pickingRule)) return null;

      set(
        pickingPolicyPickingRule({
          ruleId: identity.ruleId,
          pickingRuleId: identity.pickingRuleId,
        }),
        {
          ...pickingRule,
          locationsMatch: {
            anyOf: _.map(pickingRule.locationsMatch?.anyOf, fs =>
              fs.id === identity.locationFilterId ? value : fs,
            ).filter(fs => !!fs),
          },
        },
      );
    },
  });

export const pickingPolicySelectedDetailsIdentityAtom =
  atom<PickingDetailsSelectionIdentity>({
    key: getKey('selected-details-identity-atom'),
    default: null,
  });

export const pickingPolicyOrderLineByRule =
  atom<SimulationOrderLinesConnectionFragment>({
    key: getKey('order-lines-by-rule'),
    default: null,
  });

export const pickingPolicyOrderLineByRuleLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('order-lines-by-rule-load-status'),
  default: AsyncLoadStatus.None,
});

export const pickingPolicyCheckResult =
  atom<CheckSimulationPickingPolicyResultFragment>({
    key: getKey('check-results'),
    default: null,
  });

export const pickingPolicyCheckResultLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('check-results-load-status'),
  default: AsyncLoadStatus.None,
});

export const pickingPolicyLevelLocationByRule = selector<Set<string>>({
  key: getKey('locations-by-level-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const details = get(pickingPolicySelectedDetailsIdentityAtom);

    const layoutId = sim?.layout?.id;
    if (
      _.isNil(layoutId) ||
      _.isNil(details?.pickingRuleId) ||
      !details?.showLocations ||
      _.isNil(level) ||
      stepId !== 'policy-picking'
    )
      return null;

    const pickingRule = get(
      pickingPolicyPickingRule({
        ruleId: details.ruleId,
        pickingRuleId: details.pickingRuleId,
      }),
    );

    return loadLocationsByRule({
      layoutId,
      pickingRule,
      filter: {
        planeId: [planeId],
        level: [level],
      },
    });
  },
});

export const pickingPolicyBayLocationByRule = selector<Set<string>>({
  key: getKey('locations-per-bay-by-rule'),
  get: async ({ get }) => {
    const sim = get(simulationCurrent);
    const planeId = get(viewerSelectedPlaneId);
    const bay = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const details = get(pickingPolicySelectedDetailsIdentityAtom);

    const layoutId = sim?.layout?.id;
    if (
      _.isNil(layoutId) ||
      _.isNil(details?.pickingRuleId) ||
      !details?.showLocations ||
      _.isNil(bay) ||
      stepId !== 'policy-picking'
    )
      return null;

    const pickingRule = get(
      pickingPolicyPickingRule({
        ruleId: details.ruleId,
        pickingRuleId: details.pickingRuleId,
      }),
    );

    return loadLocationsByRule({
      layoutId,
      pickingRule,
      filter: {
        planeId: [planeId],
        bayId: [bay],
      },
    });
  },
});

export const pickingPolicyOrderLineRulesTitles = selector<
  Record<string, string>
>({
  key: getKey('order-line-rules-map'),
  get: ({ get }) => {
    return _.reduce(
      get(pickingPolicy)?.orderLineRules,
      (acc, rule) => ({
        ...acc,
        [rule.id]: rule.title,
      }),
      {},
    );
  },
});

/**
 * Picking Policy is ready if:
 *  - there is at least one order line rule
 *  - every order line rule has at least one picking rule
 *  - [simulation_order_line] table is created
 */
export const pickingPolicyIsReadyToAnalyze = selector<PickingPolicyReadyStatus>(
  {
    key: getKey('is-ready-analyze'),
    get: ({ get }) => {
      const policy = get(pickingPolicy);
      const orderLineSet = get(simulationOrderLineSet);
      const hasOrderLineRules = !_.isEmpty(policy?.orderLineRules);
      const hasPickingRules = _.every(
        policy?.orderLineRules,
        orl => !_.isEmpty(orl.pickingRules),
      );

      const isOrderLineTableReady =
        orderLineSet?.status === BatchJobStatus.READY;

      const pickingAgents = get(resourcePolicyPickingAgents);
      const isPickingAgentsSelected = _.every(policy?.orderLineRules, orl =>
        _.every(orl.pickingRules, pr => _.has(pickingAgents, pr.agentId)),
      );

      const uoms = new Set(get(simulationUoms));
      const isUOMsSelected = _.every(policy?.orderLineRules, orl =>
        _.every(orl?.pickingRules, rule =>
          _.some(rule.uomTypes, uom => uoms.has(uom)),
        ),
      );

      const isAnalyzeReady =
        hasOrderLineRules &&
        hasPickingRules &&
        isOrderLineTableReady &&
        isPickingAgentsSelected &&
        isUOMsSelected;

      return {
        isAnalyzeReady,
        hasOrderLineRules,
        hasPickingRules,
        isOrderLineTableReady,
        isPickingAgentsSelected,
        isUOMsSelected,
      };
    },
  },
);
