import * as React from 'react';
const ResyncIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ResyncIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M41.06,84.45l11.05-11.05-48.1-5.51,5.51,48.11,11.1-11.1c10.87,11.53,26.28,18.73,43.39,18.73,27.21,0,50.11-18.24,57.27-43.15-10.85,10.81-25.81,17.49-42.33,17.5-14.38,0-27.55-5.1-37.88-13.53h0Z"
    />
    <path
      strokeWidth={0}
      d="M86.94,43.55l-11.05,11.05,48.1,5.51-5.51-48.11-11.1,11.1c-10.87-11.53-26.28-18.73-43.39-18.73C36.79,4.37,13.89,22.61,6.73,47.52c10.85-10.81,25.8-17.49,42.33-17.5,14.38,0,27.55,5.09,37.88,13.53h0Z"
    />
  </svg>
);
export default ResyncIcon;
