import Flatten from '@flatten-js/core';
import { Vector } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import * as Icon from '../../../components/icons';
import { DirectionDescriptorId } from './routingPolicy.types';

export const restrictionIconVector = Flatten.vector(0, 1);

export const DIRECTION_NORTH: Vector = { x: 0, y: 1 };
export const DIRECTION_EAST = { x: 1, y: 0 };
export const DIRECTION_SOUTH = { x: 0, y: -1 };
export const DIRECTION_WEST = { x: -1, y: 0 };
export const DIRECTION_NONE = { x: 0, y: 0 };

export const iconSizeBase = 128;

export const startPointSVG =
  'M64,4C30.88,3.97,3.95,30.86,3.95,63.95s26.94,60.03,60.05,60.05c33.12,.03,60.05-26.86,60.05-59.95S97.12,4.03,64,4Zm-29.03,53.11l14.63,14.67h-15.06S14.59,51.78,14.59,51.78l19.98-20h15.06s-14.65,14.66-14.65,14.66h29.29s0,10.67,0,10.67h-29.29Zm59,39.11h-15.06s14.65-14.66,14.65-14.66h-29.29s0-10.67,0-10.67h29.29s-14.63-14.67-14.63-14.67h15.06s19.95,20,19.95,20l-19.98,20Z';

// Routing Icons (default: top)
export const noRestrictionSVG =
  'M64,4C30.92,4,4,30.91,4,64s26.92,60,60,60,60-26.91,60-60S97.08,4,64,4Zm-15.75,24.13l15.75-15.74,15.75,15.74v11.87s-11.55-11.54-11.55-11.54v23.09s-8.4,0-8.4,0V28.46s-11.55,11.54-11.55,11.54v-11.87Zm-19.47,50.97l-15.74-15.75,15.74-15.75h11.87s-11.54,11.55-11.54,11.55h23.09s0,8.4,0,8.4H29.11s11.54,11.55,11.54,11.55h-11.87Zm50.97,19.47l-15.75,15.74-15.75-15.74v-11.87l11.55,11.54v-23.09s8.4,0,8.4,0v23.09s11.55-11.54,11.55-11.54v11.87Zm19.47-19.47h-11.87s11.54-11.55,11.54-11.55h-23.09v-8.4h23.09s-11.54-11.55-11.54-11.55h11.87s15.74,15.75,15.74,15.75l-15.74,15.75Z';
export const restrictionArrowSVG =
  'M15.73,75.77V39.4s35.39,35.36,35.39,35.36V4s25.75,0,25.75,0V74.76s35.4-35.37,35.4-35.37v36.37s-48.27,48.23-48.27,48.23L15.73,75.77Z';
export const restrictionBlockSvg =
  'm99.37,28.63c-19.5-19.5-51.24-19.5-70.74,0-19.5,19.5-19.5,51.24,0,70.74,19.5,19.5,51.24,19.5,70.74,0,19.5-19.5,19.5-51.24,0-70.74Zm-21.09,55.1l-14.28-14.28-14.96,14.96c-1.5,1.5-3.94,1.5-5.44,0-1.5-1.5-1.5-3.94,0-5.44l14.96-14.96-14.28-14.28c-1.5-1.5-1.5-3.94,0-5.44,1.5-1.5,3.94-1.5,5.44,0l14.28,14.28,13.6-13.6c1.5-1.5,3.94-1.5,5.44,0,1.5,1.5,1.5,3.94,0,5.44l-13.6,13.6,14.28,14.28c1.5,1.5,1.5,3.94,0,5.44-1.5,1.5-3.94,1.5-5.44,0Z';

export function getRestrictionIcon(directionType: DirectionDescriptorId) {
  if (_.isNil(directionType)) return Icon.CircleWaypoint4;
  switch (directionType) {
    case 'direction-any':
      return Icon.CircleWaypoint4;
    case 'direction-none':
      return Icon.CircleX;
    default:
      return Icon.ArrowTop;
  }
}

export function getRestrictionIconSvg(v: Vector): string {
  if (_.isNil(v)) return noRestrictionSVG;
  if (v.x === 0 && v.y === 0) return restrictionBlockSvg;

  return restrictionArrowSVG;
}

export function getRotationAngle(v1: Vector, v2 = DIRECTION_NORTH) {
  if (_.isNil(v1) || _.isNil(v2)) return 0;
  if (v1.x === 0 && v1.y === 0) return 0;

  const angleRadian = Flatten.vector(v1.x, v1.y).angleTo(
    Flatten.vector(v2.x, v2.y),
  );
  const angleDegree = (360 + (180 / Math.PI) * angleRadian) % 360;
  return angleDegree;
}
