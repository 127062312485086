import { BayLocationOrder } from '@warebee/shared/engine-model';
import {
  AisleSide,
  LayoutImportLocation,
} from './layout-import-location.model';

export const areaActionPointPositions = [
  'left',
  'top',
  'right',
  'bottom',
] as const;
export type AreaActionPointPositions =
  (typeof areaActionPointPositions)[number];

export const areaLinkPointTypes = [
  'bottomLeft',
  'topLeft',
  'topRight',
  'bottomRight',
] as const;

export type AreaLinkPointType = (typeof areaLinkPointTypes)[number];

export const areaLinkPointStatus = [
  'default',
  'linkSource',
  'linkTarget',
  'linkBoth',
] as const;
export type AreaLinkPointStatus = (typeof areaLinkPointStatus)[number];

export type AreaLink = {
  areaId: string;
  masterAreaId: string;
  linkPointType: AreaLinkPointType;
  masterLinkPointType: AreaLinkPointType;
  linkOffsetX: number;
  linkOffsetY: number;
};

export type AreaConfiguration = {
  id: string;
  x: number;
  y: number;
  rotation: number;
  flipX: boolean;
  flipY: boolean;
  floor: string;
  isDeleted?: boolean;
  link?: AreaLink;
  zIndex?: number;
};

export type AreaConfigurationMap = Record<string, AreaConfiguration>;

export enum SpacerTypeOption {
  Aisle,
  Block,
}

export enum SpacerPositionOption {
  Left,
  Right,
}

export enum BaysAlignOption {
  Bottom,
  Top,
}

export enum OrderTypeOption {
  Default,
  Reverse,
  Shifted,
  Custom,
}

export enum BaysOrderInAisleOption {
  Parallel,
  ReverseParallel,
  RoundLeft,
  RoundRight,
}
export type BayLevelHeights = Record<number, number>;

export type BayTypeSettings = {
  bayType: string;
  verticalFrameProfile: number;
  shelfHeight: number;
  maxWeight: number;
  levelHeights: BayLevelHeights;
};

export type ShelfSettings = {
  level: number;
  minHeight: number;
};

export type LocationTypeSettings = {
  typeId: string;
  gapWidth: number;
  gapDepth: number;
  gapHeight: number;
  isWeakDepth: boolean;
  minDepth?: number;
};

export type OrderSettings = {
  orderType: OrderTypeOption;
  shift: number;
  customOrder?: string[];
};

export type BayRowBuilderSettings = OrderSettings & {
  align: BaysAlignOption;
};

export type MergeAisleSettings = [string, string];

export type AisleBuilderSettings = {
  aisleId: string;
  width?: number;
  bayRowSettings: Record<AisleSide, BayRowBuilderSettings>;
  locationOrderSettings: Partial<Record<AisleSide, BayLocationOrder>>;
};

export type SpacerBuilderSettings = {
  id: string;
  title: string;
  bayId: string;
  position: SpacerPositionOption;
  type: SpacerTypeOption;
  width: number;
};

export type SpacerAreaSettings = {
  id: string;
  title: string;
  type: SpacerTypeOption;
  width: number;
  height: number;
};

export type AreaBuilderSettings = {
  id: string;
  aisleWidth: number;
  hasBottomAccessAisle: boolean;
  hasTopAccessAisle: boolean;
  baysAlign: BaysAlignOption;
  baysOrder: BaysOrderInAisleOption;
  aisleSettings?: Record<string, AisleBuilderSettings>;
  spacers?: Record<string, SpacerBuilderSettings[]>;
  mergeAisles: MergeAisleSettings[];
  aisleOrder?: OrderSettings;
  tunnelBays?: Record<string, boolean>;
  minimalTunnelWidth: number;
  useAutoSpacers?: boolean;
  maximizeAisleLength?: boolean;
  minAreaLength?: number;
};

export type LayoutFloorSettings = {
  id: string;
  title: string;
};

export type LayoutEntryPoint = {
  Id1: string;
  Id2: string;
};

export type LayoutBuilderSettings = {
  name: string;
  description: string;
  floors: Record<string, LayoutFloorSettings>;
  entryPoints?: Record<string, LayoutEntryPoint>;
};

export type LocationPatch = Partial<LayoutImportLocation>;

export type LocationPatchHolder = {
  areaId: string;
  bayId: string;
  patch: LocationPatch;
};

/**
 * Layout import conversion settings.
 */
export type ConverterSettings = {
  layoutSettings: LayoutBuilderSettings;
  areasConfigurations: AreaConfigurationMap;
  areasBuilder: Record<string, AreaBuilderSettings>;
  locationsPatches: Record<string, LocationPatchHolder>;
  locationsTypes: Record<string, LocationTypeSettings>;
  bayTypes: Record<string, BayTypeSettings>;
  shelvesSettings: Record<string, Record<number, ShelfSettings>>;
  areaSpacers: Record<string, SpacerAreaSettings>;
  extraLocations: Record<string, LayoutImportLocation>;
};
