import {
  Field,
  GraphQLJSON,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';

@InputType({ isAbstract: true })
export class UpdateBaseEntityInput {
  @Field(() => ID, { nullable: true })
  title?: string;

  @Field(() => ID, { nullable: true })
  description?: string;

  @Field(() => GraphQLJSON, { nullable: true })
  extraSettings?: any;
}
