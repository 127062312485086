import _ from 'lodash';
import { HeatmapBucketStats } from '../../common/heatmap.helper';
import { HeatmapMetricRange } from '../../common/heatmap.types';
import { ActualityMetricDescriptorBase } from '../../metrics/actuality/actualityMetric.types';
import { ActualityMetricSummaryRow } from './datasetQueries/actualityHq';

// export function getActualityMetricDataRow(
//   row: ActualityHqRow,
//   itemsWithRank: Record<string, FeedItemWithRank>,
// ): ActualityMetricDataRow {
//   const key = getItemKey(row);
//   itemsWithRank[key];
//   const rank = itemsWithRank[key]?.percentRank;
//   return {
//     ...row,
//     skuKey: '',
//     locationVisits: 0,
//     categoryString: '',
//   };
// }

export function getActualityHeatmapMetricRange(
  metricDescriptor: ActualityMetricDescriptorBase,
  summaries: ActualityMetricSummaryRow[],
): HeatmapMetricRange {
  const groups = _(summaries).groupBy(s => s.dimension);
  const dimensionStatsByLevel: Record<
    string,
    Record<number, HeatmapBucketStats>
  > = groups
    .transform((acc, values, key) => {
      acc[key] = _.keyBy(values, v => v.locationLevel);
    }, {})
    .value();

  const dimensionStats: Record<string, HeatmapBucketStats> = groups
    .transform((acc, values, key) => {
      const dimensionStats: HeatmapBucketStats = {
        totalCount: _.sumBy(values, v => v.totalCount),
        eventsCount: _.sumBy(values, v => v.totalEvents),
        itemsCount: _.sumBy(values, v => v.totalItems),
        assignmentsCount: _.sumBy(values, v => v.totalAssignments),
        locationCount: _.sumBy(values, v => v.totalLocations),
        totalExistedLocations: _.sumBy(values, v => v.totalExistedLocations),
      };
      acc[key] = dimensionStats;
    }, {})
    .value();

  let min = 0;
  let max = _(dimensionStats)
    .values()
    .map(s => s.totalCount)
    .max();

  if (metricDescriptor.rangeType === 'distinct-range') {
    const numericDimensions = _(summaries)
      .map(s => parseFloat(s.dimension))
      .filter(v => !_.isNaN(v))
      .compact()
      .uniq()
      .value();
    min = _.min(numericDimensions);
    max = _.max(numericDimensions);
  }

  return {
    rangeType: metricDescriptor.rangeType,
    keys: _.keys(dimensionStats),
    min,
    max,
    stats: dimensionStats,
    levelStats: dimensionStatsByLevel,
  };
}
