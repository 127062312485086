import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AppHeaderMenuItem from '../components/AppHeaderMenuItem';
import { Container } from '../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../containers/SidebarContainer';
import { warehouseIsDemo, warehouseSelected } from '../store/warehouse.state';

export type AppHeaderMenuProps = {
  warehouseId?: string;
  children?: React.ReactNode;
  active?: boolean;
  isDemo?: boolean;
};

const SidebarGlobalMenu: React.FC = props => {
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);

  const warehouse = useRecoilValue(warehouseSelected);
  if (!warehouse) return null;

  const basePath = `/wh/i/${warehouse.id}`;

  const menuItems = [
    {
      title: t`Data`,
      subtitle: t`Warehouse Datasets`,
      to: `${basePath}/data`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 1,
    },
    {
      title: t`Activity Feed`,
      subtitle: t`Warehouse`,
      to: `${basePath}/feed`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 2,
    },
    {
      title: t`Simulations`,
      subtitle: t`Digital Twin`,
      to: `${basePath}/simulations`,
      default: true,
      isDemo: isDemo,
      beta: false,
      disabled: false,
      order: 3,
    },
    {
      title: t`Designer`,
      subtitle: t`Warehouse Layout`,
      to: `${basePath}/designer`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 4,
    },
    {
      title: t`Billing`,
      subtitle: t`Revenue Assurance`,
      to: `${basePath}/billing`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 5,
    },
    {
      title: t`Contracts`,
      subtitle: t`Audit`,
      to: `${basePath}/contracts`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 6,
    },
    {
      title: t`Marketplace`,
      subtitle: t`Warehouse`,
      to: `${basePath}/marketplace`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 7,
    },
    {
      title: t`Sales`,
      subtitle: t`AI Assistant`,
      to: `${basePath}/sales`,
      default: false,
      beta: true,
      disabled: true,
      collapsed: 'sm',
      order: 7,
    },
    {
      title: t`Warehouse Settings`,
      subtitle: t`Settings`,
      to: `${basePath}/edit`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
    },
    {
      title: t`Account Billing`,
      subtitle: t`Settings`,
      to: `${basePath}/settings`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
    },
    {
      title: t`Plans & Pricing`,
      subtitle: t`Warehouse Settings`,
      to: `${basePath}/pricing/`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
    },
  ];

  return (
    <Container
      col
      hasOverflowY
      className={classNames('flex flex-col', 'shadow-xl')}
    >
      <div
        data-component={`AppHeaderMenu`}
        className={classNames(
          'flex items-center',
          'w-full',
          'border-menu-active border-b',
        )}
      >
        <nav
          className={classNames(
            'flex flex-1 flex-col',
            'ltr:justify-start rtl:justify-end',
            'divide-y',
          )}
        >
          {menuItems.map(item => (
            <AppHeaderMenuItem
              key={`header-item-${item.title}`}
              exactActive
              widthFull
              isInBeta={item.beta}
              isDemo={item.isDemo}
              title={item.title}
              subtitle={item.subtitle}
              to={item.to}
              hidden={item.disabled}
              active={item.default}
            />
          ))}
        </nav>
      </div>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('app');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-global-menu',
    title: t('Menu'),
    size: 'md',
  };

  return (
    <SidebarContainer {...containerProps} sideRight isGlobal>
      <SidebarGlobalMenu />
    </SidebarContainer>
  );
};
