import {
  AislePortal,
  BayPortal,
  ConnectivityGraph,
} from '@warebee/shared/engine-model';
import Flatten from '@flatten-js/core';
import { findSharedSegment, getOnlyFace } from './geometry.engine';
import { Segment } from '@warebee/shared/engine-model';
import { MultiShapeHolder, ShapeIndex } from './shape-index';
import {
  VisualAisle,
  VisualBay,
  VisualPlaneMap,
} from './visual-layout-map.model';
import { NavigablePolygon } from './navigation.common';
import { Location } from '@warebee/shared/engine-model';
import { uniq } from 'lodash';

function toRawSegment(s: Flatten.Segment): Segment {
  return {
    start: { x: s.start.x, y: s.start.y },
    end: { x: s.end.x, y: s.end.y },
  };
}

function toShapeHolder(p: NavigablePolygon): MultiShapeHolder<Flatten.Segment> {
  const face = getOnlyFace(p.shape);
  return {
    id: p.id,
    shapes: face.edges.map(e => e.shape as Flatten.Segment),
  };
}

function indexEdges(set: NavigablePolygon[]) {
  return new ShapeIndex<Flatten.Segment>(set.map(toShapeHolder));
}

function doFindPortalsBetween(
  destIndex: ShapeIndex<Flatten.Segment>,
  srcSet?: MultiShapeHolder<Flatten.Segment>[],
): AislePortal[] {
  const result: AislePortal[] = [];

  function findPortalsFromShape(id: string, shape: Flatten.Segment) {
    const found = destIndex.search(shape.box);
    found.forEach(e2 => {
      if (!srcSet && e2.id <= id) {
        return;
      }

      const sp = findSharedSegment(shape, e2.shape);
      if (sp) {
        result.push({
          aisleId1: id,
          aisleId2: e2.id,
          coords: toRawSegment(sp.segment),
        });
      }
    });
  }

  if (srcSet) {
    srcSet.forEach(h => {
      h.shapes.forEach(shape => {
        findPortalsFromShape(h.id, shape);
      });
    });
  } else {
    destIndex.forEach(e1 => {
      findPortalsFromShape(e1.id, e1.shape);
    });
  }

  return result;
}

export function findPortalsBetween(
  set1: NavigablePolygon[],
  set2: NavigablePolygon[],
): AislePortal[] {
  const destIndex = indexEdges(set1);

  if (set1 === set2) {
    return doFindPortalsBetween(destIndex);
  } else {
    return doFindPortalsBetween(destIndex, set2.map(toShapeHolder));
  }
}

export function generateAislePortals(
  aisles: VisualAisle[],
  bays: VisualBay[],
): AislePortal[] {
  const navigablePolygons = (
    aisles.filter(a => !a.nonNavigable) as NavigablePolygon[]
  ).concat(bays.filter(b => b.hasPass));

  const aisleEdgeIndex = indexEdges(navigablePolygons);
  return doFindPortalsBetween(aisleEdgeIndex);
}

export type AttachedVisualBay = VisualBay & {
  aisleId: string | null;
};

export function attachBay(
  bay: VisualBay,
  aisleId: string | null,
): AttachedVisualBay {
  const ab = bay as AttachedVisualBay;
  ab.aisleId = aisleId;
  return ab;
}

export function generateBayPortalsForAttachedBays(
  aisles: VisualAisle[],
  bays: AttachedVisualBay[],
): BayPortal[] {
  const nonNavigableAisleIds = new Set([
    ...aisles.filter(a => a.nonNavigable).map(a => a.id),
  ]);

  return bays
    .filter(b => b.aisleId && !nonNavigableAisleIds.has(b.aisleId))
    .map(b => ({
      aisleId: b.aisleId,
      bayId: b.id,
      bayProjection: {
        start: 0,
        end: b.width,
      },
      coords: {
        start: b.position,
        end: b.position.translate(b.frontEdge),
      },
    }));
}

export function generateConnectivityGraphWithAttachedBays(
  aisles: VisualAisle[],
  bays: AttachedVisualBay[],
): ConnectivityGraph {
  return {
    aislePortals: generateAislePortals(aisles, bays),
    bayPortals: generateBayPortalsForAttachedBays(aisles, bays),
  };
}

/**
 * Generate connectivity graph for the given layout plane.
 * @param m
 * @returns
 */
export function generateConnectivityGraph(
  m: VisualPlaneMap,
): ConnectivityGraph {
  const aisleEdgeIndex = indexEdges(m.navigablePolygons);
  const aislePortals = doFindPortalsBetween(aisleEdgeIndex);

  const bayPortals: BayPortal[] = [];

  m.bays.forEach(b => {
    const position = Flatten.point(b.position.x, b.position.y);
    const frontSideVector = Flatten.vector(b.frontEdge.x, b.frontEdge.y);
    const frontSideSegment = Flatten.segment(
      position,
      position.translate(frontSideVector),
    );

    const found = aisleEdgeIndex.search(frontSideSegment.box);

    found
      .filter(e => m.aislesById[e.id]) // bays can be connected to aisles only, not navigable bays
      .forEach(e => {
        const sp = findSharedSegment(e.shape, frontSideSegment);
        if (sp) {
          bayPortals.push({
            aisleId: e.id,
            bayId: b.id,
            coords: toRawSegment(sp.segment),
            bayProjection: sp.projection2,
          });
        }
      });
  });

  return {
    aislePortals,
    bayPortals,
  };
}

export class ConnectedComponent {
  constructor(
    readonly navigableIds: Set<string>,
    readonly bayIds: Set<string>,
  ) {}

  add(id: string, isBay: boolean) {
    if (isBay) {
      this.bayIds.add(id);
    } else {
      this.navigableIds.add(id);
    }
  }

  mergeWith(cc: ConnectedComponent) {
    cc.navigableIds.forEach(id => this.navigableIds.add(id));
    cc.bayIds.forEach(id => this.bayIds.add(id));
  }
}

export class ConnectedComponents {
  components: ConnectedComponent[];
  componentsByNavigableId: Record<string, ConnectedComponent>;
  componentsByBayId: Record<string, ConnectedComponent>;
}

export function getConnectedComponents(
  cg: ConnectivityGraph,
): ConnectedComponents {
  const componentsByNavigableId: Record<string, ConnectedComponent> = {};

  function processPortal(id1: string, id2: string) {
    const c1 = componentsByNavigableId[id1];
    const c2 = componentsByNavigableId[id2];

    if (c1 && c2) {
      c1.mergeWith(c2);
      c2.navigableIds.forEach(id => {
        componentsByNavigableId[id] = c1;
      });
    } else if (c1) {
      c1.add(id2, false);
      componentsByNavigableId[id2] = c1;
    } else if (c2) {
      c2.add(id1, false);
      componentsByNavigableId[id1] = c2;
    } else {
      const c = new ConnectedComponent(new Set([id1, id2]), new Set());
      componentsByNavigableId[id1] = c;
      componentsByNavigableId[id2] = c;
    }
  }

  for (const { aisleId1, aisleId2 } of cg.aislePortals) {
    processPortal(aisleId1, aisleId2);
  }

  const componentsByBayId: Record<string, ConnectedComponent> = {};

  for (const { aisleId, bayId } of cg.bayPortals) {
    const c = componentsByNavigableId[aisleId];
    if (c) {
      c.add(bayId, true);
      componentsByBayId[bayId] = c;
    } else {
      const cc = new ConnectedComponent(new Set([aisleId]), new Set([bayId]));
      componentsByBayId[bayId] = cc;
    }
  }

  return {
    componentsByBayId,
    componentsByNavigableId,
    components: uniq(
      Object.values(componentsByNavigableId).concat(
        Object.values(componentsByBayId),
      ),
    ),
  };
}

export function getAisleIdByLocationBinding(
  cg: ConnectivityGraph,
  loc: Pick<Location, 'locationBayId' | 'locationBayProjection'>,
) {
  return cg.bayPortals.find(
    bp =>
      bp.bayId == loc.locationBayId &&
      loc.locationBayProjection >= bp.bayProjection.start &&
      loc.locationBayProjection <= bp.bayProjection.end,
  )?.aisleId;
}
