import { registerEnumType } from '@warebee/shared/util-backend-only-types';

export enum ProcessingPipelineJobStatus {
  CREATED = 'CREATED',
  QUEUED = 'QUEUED',
  STARTING = 'STARTING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TERMINATED = 'TERMINATED',
  SKIPPED = 'SKIPPED',
}

registerEnumType(ProcessingPipelineJobStatus, {
  name: 'ProcessingPipelineJobStatus',
});

export interface StartProcessingPipelineJob {
  pipelineId: string;
  jobId: string;
}
