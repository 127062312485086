import * as React from 'react';
const ArrowLeftTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ArrowLeftTopIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M14.03,14.03l72.61-.03,27.37,27.37-53.24.02,49.48,49.48c2.08,2.08,2.08,5.45,0,7.52l-11.85,11.85c-2.08,2.08-5.45,2.08-7.52,0l-49.48-49.48-.02,53.24-27.37-27.37.03-72.61Z"
    />
  </svg>
);
export default ArrowLeftTopIcon;
