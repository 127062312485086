import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  LoadAllItemSetsMetaDocument,
  LoadAllItemSetsMetaQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseAllItemSetsMeta } from '../../store/warehouse.state';

export function useLoadAllItemSetsMeta() {
  const client = useApolloClient();

  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    const current = await snapshot.getPromise(warehouseAllItemSetsMeta);
    set(warehouseAllItemSetsMeta, {
      ...current,
      status: AsyncLoadStatus.Loading,
    });
  });

  const loadAllItemSetsMetaCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (warehouseId: string) => {
        if (_.isNil(warehouseId)) return;
        const current = await snapshot.getPromise(warehouseAllItemSetsMeta);
        let response: ApolloQueryResult<LoadAllItemSetsMetaQuery>;
        try {
          response = await client.query<LoadAllItemSetsMetaQuery>({
            query: LoadAllItemSetsMetaDocument,
            variables: {
              warehouseId,
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load items list',
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(warehouseAllItemSetsMeta, {
            ...current,
            status: AsyncLoadStatus.Error,
          });
          return;
        }

        if (response.error) {
          console.error(response.error);
          set(errorAppender, {
            id: nanoid(),
            title: 'Cannot load items list',
            details: response.error.message,
            callStack: response.error.stack || null,
          });
          set(warehouseAllItemSetsMeta, {
            ...current,
            status: AsyncLoadStatus.Error,
          });
          return;
        }
        const items = response.data.warehouse.itemSets.content;
        set(warehouseAllItemSetsMeta, {
          status: AsyncLoadStatus.Ok,
          items,
        });
      },
  );

  return async (warehouseId: string) => {
    await cleanupState();
    await loadAllItemSetsMetaCallback(warehouseId);
  };
}
