import {
  ItemFilterFragment,
  ItemFilterIntersectionFragment,
  ItemFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterFieldConfigBase } from '../common/types';
import { LoadItemSetFilterValuesParams } from '../itemSet/hooks/useLoadItemSetFilterValues';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from '../policyFilters/policyFilter.helper';
import PolicyFilterValueEditor, {
  PolicyFilterValueEditorProps,
} from '../policyFilters/PolicyFilterValueEditor';
import { getStackingPolicyFilterConfig } from '../simulation/store/stackingPolicy/stackingPolicy.default';
import {
  filterPresetProductFilterIntersection,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

export type FilterPresetProductFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<ItemFilterType>;
  startLoadData: (params: LoadItemSetFilterValuesParams) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const FilterPresetProductFilterEditor: React.FC<
  FilterPresetProductFilterEditorProps
> = props => {
  const { t } = useTranslation('app');
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;

  const editableFilterSetIdentity = useRecoilValue(
    filterPresetSelectedIdentity,
  );
  const [filterIntersection, setFilterIntersection] = useRecoilState(
    filterPresetProductFilterIntersection(editableFilterSetIdentity),
  );

  if (!editableFilterSetIdentity || !filterIntersection) return null;

  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const onSelectValue = (value: ItemFilterFragment) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);
    // If all values vas deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn) ||
      !_.isNil(value?.range?.from) ||
      !_.isNil(value?.range?.to)
    ) {
      others.push(value);
    }

    const newIntersection: ItemFilterIntersectionFragment = {
      id: filterIntersection.id,
      allOf: others,
    };

    setFilterIntersection(newIntersection);

    // trigger other filter to refresh
    getStackingPolicyFilterConfig(t).forEach(configItem => {
      if (configItem.type !== type) {
        const keyParts = getPolicyFilterDataKeysByEditorType(
          configItem.editorType,
        );

        keyParts.forEach(key => {
          const filterKey = getPolicyFilterKey(configItem.type, key);
          cancelLoadData(filterKey);
          startLoadData({
            field: configItem.type,
            filterKey,
            filterIntersection: newIntersection,
          });
        });
      }
    });
  };

  const editorProps: PolicyFilterValueEditorProps<ItemFilterType> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params => startLoadData({ ...params, filterIntersection }),
    cancelLoadData,
  };

  return <PolicyFilterValueEditor {...editorProps} />;
};
export default FilterPresetProductFilterEditor;
