import * as React from 'react';
const AlignVerticalBottomIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AlignVerticalBottomIcon"
    {...props}
  >
    <rect width={120} height={4.44} x={4} y={114.67} strokeWidth={0} />
    <rect width={28} height={100} x={53} y={8.89} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M26.42,105.8c-.09.09-.2.18-.31.25-.05.03-.11.06-.16.09-.06.03-.12.07-.19.1-.07.03-.14.04-.21.06-.06.02-.11.04-.17.05-.26.05-.53.05-.78,0-.06-.01-.11-.03-.17-.05-.07-.02-.14-.04-.2-.06-.07-.03-.13-.06-.19-.1-.05-.03-.11-.05-.16-.09-.11-.07-.21-.16-.31-.25l-16-16c-.78-.78-.78-2.05,0-2.83.39-.39.9-.59,1.41-.59.51,0,1.02.2,1.41.59l12.59,12.59v-30.17c0-1.1.9-2,2-2s2,.9,2,2v30.17s12.59-12.59,12.59-12.59c.39-.39.9-.59,1.41-.59.51,0,1.02.2,1.41.59.78.78.78,2.05,0,2.83l-16,16Z"
    />
    <polygon
      strokeWidth={0}
      points="117.01 108.82 116.89 108.93 103.31 108.93 117.01 95.24 117.01 108.82"
    />
    <polygon
      strokeWidth={0}
      points="89.01 43.93 97.04 43.93 89.01 51.96 89.01 43.93"
    />
    <polyline
      strokeWidth={0}
      points="114.15 81.12 117.01 78.27 117.01 91.85 99.92 108.93 89.01 108.93 89.01 106.27 114.15 81.12 114.15 81.12"
    />
    <polygon
      strokeWidth={0}
      points="117.01 44.33 117.01 57.9 89.01 85.9 89.01 72.33 117.01 44.33"
    />
    <polygon
      strokeWidth={0}
      points="89.04 102.84 89.04 89.26 117.01 61.3 117.01 74.87 89.04 102.84"
    />
    <polygon
      strokeWidth={0}
      points="89.01 55.36 100.43 43.93 114.01 43.93 89.01 68.93 89.01 55.36"
    />
  </svg>
);
export default AlignVerticalBottomIcon;
