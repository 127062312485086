import { eachDayOfInterval, endOfDay, startOfDay } from 'date-fns';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import {
  workforceAgentSchedule,
  workforceEffectiveAgentSchedules,
  workforceSelectedAgentId,
} from '../../../simulation/store/workforce.state';
import DayFeature from '../../features/DayFeature';
import ShiftFeature from '../../features/ShiftFeature';
import UnscheduledFeature from '../../features/UnscheduledFeature';
import WaveFeature from '../../features/WaveFeature';
import { StageType } from '../../stage/stage.helper';
import { stageStateById } from '../../stage/stage.state';

export const ticksInPixel = 60 * 1000; // 1 min
export const stageHeight = 24 * 60;

export type SchedulerLayerProps = {
  analyzeId: string;
  stageId: StageType;
};

const SchedulerLayer: React.FC<SchedulerLayerProps> = props => {
  const agentId = useRecoilValue(workforceSelectedAgentId);
  const scheduleLoadable = useRecoilValueLoadable(
    workforceAgentSchedule(props.analyzeId),
  );
  const agentForecasts = useRecoilValue(
    workforceEffectiveAgentSchedules(agentId),
  );
  const setStageState = useSetRecoilState(stageStateById(props.stageId));
  useEffect(() => {
    if (scheduleLoadable.state === 'hasValue') {
      const { forecastInterval } = scheduleLoadable.contents;
      const xMin = startOfDay(forecastInterval[0]).getTime() / ticksInPixel;
      const xMax = endOfDay(forecastInterval[1]).getTime() / ticksInPixel;
      const days = 3; // 3 day
      const xScope = xMin + (days * 24 * 3600 * 1000) / ticksInPixel;

      const key = `${xMin}-${xMax}`;
      setStageState({
        autoSizeId: `auto-size-bay-${key}`,
        contentBounds: [
          [xMin, -stageHeight / 2],
          [Math.min(xMax, xScope), stageHeight / 2],
        ],
      });
    }
  }, [scheduleLoadable]);

  if (scheduleLoadable.state !== 'hasValue') return null;

  const { forecastInterval, waves, workWindows, unscheduledWaves } =
    scheduleLoadable.contents;

  if (_.isEmpty(waves)) return null;

  const shiftOffsets: Record<string, number> = {};

  _.forEach(workWindows, ww => {
    let rowIndex = _(ww.shiftWindows)
      .map(wsw => shiftOffsets[wsw.id] ?? 0)
      .max();

    _(ww.shiftWindows)
      .filter(wsw => !_.has(shiftOffsets, wsw.id))
      .forEach(wsw => {
        shiftOffsets[wsw.id] = ++rowIndex;
      });
  });

  const maxIntersectedShifts = _.max(_.values(shiftOffsets));
  const drawnShifts: Record<string, number> = {};

  const sortedForecasts = _.sortBy(agentForecasts, s => s.sortIndex);
  return (
    <Layer>
      {eachDayOfInterval({
        start: forecastInterval[0],
        end: forecastInterval[1],
      }).map((day, index) => {
        const scheduleSettings = sortedForecasts.find(
          setting =>
            setting.period.absolute.start <= day.getTime() &&
            setting.period.absolute.end >= day.getTime(),
        );

        return (
          <DayFeature
            key={`day-${day}`}
            day={day}
            index={index}
            maxShiftCount={maxIntersectedShifts}
            scheduleSettings={scheduleSettings}
          />
        );
      })}

      {_.flatMap(workWindows, (ww, index) => {
        let rowIndex = _(ww.shiftWindows)
          .map(wsw => drawnShifts[wsw.id] ?? 0)
          .max();

        return _(ww.shiftWindows)
          .map(wsw => {
            if (_.has(drawnShifts, wsw.id)) return null;
            drawnShifts[wsw.id] = ++rowIndex;
            return (
              <ShiftFeature
                key={`work-window-${wsw.id}`}
                workWindow={wsw}
                shiftRowIndex={rowIndex}
              />
            );
          })
          .compact()
          .value();
      })}

      {_.map(waves, wave => (
        <WaveFeature key={`wave-${wave.id}`} wave={wave} />
      ))}

      {_.size(unscheduledWaves) > 0 && (
        <UnscheduledFeature schedulingStart={startOfDay(forecastInterval[0])} />
      )}
    </Layer>
  );
};

export default SchedulerLayer;
