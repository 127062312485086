import * as React from 'react';
const CursorAddToGroupIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorAddToGroupIcon"
    {...props}
  >
    <defs>
      <symbol viewBox="0 0 93.99 93.99">
        <path
          strokeWidth={0}
          d="M.51,4.38C-.93.81.81-.93,4.38.51l87.02,35.14c3.57,1.44,3.44,3.4-.29,4.36l-33.91,8.68c-3.73.96-7.56,4.79-8.52,8.52l-8.68,33.91c-.96,3.73-2.92,3.86-4.36.29L.51,4.38Z"
        />
      </symbol>
    </defs>
    <path
      strokeWidth={0}
      d="M86.71,68.57h-10.35v-10.35c0-2.76-2.24-5-5-5s-5,2.24-5,5v10.35h-10.35c-2.76,0-5,2.24-5,5s2.24,5,5,5h10.35v10.35c0,2.76,2.24,5,5,5s5-2.24,5-5v-10.35h10.35c2.76,0,5-2.24,5-5s-2.24-5-5-5Z"
    />
    <use
      xlinkHref="#a"
      width={93.99}
      height={93.99}
      transform="translate(10.11 12.11) scale(.75)"
    />
    <rect
      width={26}
      height={26}
      x={97}
      y={97}
      strokeWidth={0}
      rx={3.34}
      ry={3.34}
    />
    <rect
      width={26}
      height={26}
      x={67}
      y={97}
      strokeWidth={0}
      rx={3.34}
      ry={3.34}
    />
    <rect
      width={26}
      height={26}
      x={97}
      y={67}
      strokeWidth={0}
      rx={3.34}
      ry={3.34}
    />
  </svg>
);
export default CursorAddToGroupIcon;
