import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonToolbar from '../../components/actions/ButtonToolbar';
import { ButtonToolbarGroup } from '../../components/actions/ButtonToolbarGroup';
import * as Icon from '../../components/icons';
import {
  viewerLocationMultiselectModeEnabled,
  viewerShowMultiselectToolbar,
} from '../viewer/store/viewer.state';

const NavigationModeToolbar: React.FC = () => {
  const { t } = useTranslation('app');
  const isVisible = useRecoilValue(viewerShowMultiselectToolbar);
  const [navigationMode, setNavigationMode] = useRecoilState(
    viewerLocationMultiselectModeEnabled,
  );
  if (!isVisible) {
    return null;
  }
  return (
    <div
      data-component="NavigationModeToolbar"
      className={`absolute ltr:left-1 rtl:right-1 top-1`}
    >
      <ButtonToolbarGroup className={`flex flex-col`}>
        <div className="flex">
          <ButtonToolbar
            isSelected={navigationMode}
            onChange={setNavigationMode}
          >
            <Icon.RoutePathTool
              className="w-8 h-8 text-white"
              alt={t`Toggle navigation mode`}
              title={t`Toggle navigation mode`}
            />
          </ButtonToolbar>
        </div>
      </ButtonToolbarGroup>
    </div>
  );
};

export default NavigationModeToolbar;
