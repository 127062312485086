import * as React from 'react';
const FilterCancelIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FilterCancelIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M119.75,13.93H8.25c-3.22,0-5.36,3.65-3.65,6.43l46.53,62.18v36.17c0,3.22,3.43,5.36,6.22,3.86l17.15-8.58c1.5-.64,2.36-2.14,2.36-3.86v-27.59l46.53-62.18c1.72-2.79-.43-6.43-3.65-6.43ZM78.81,54.8c-.95.95-2.21,1.47-3.55,1.47s-2.6-.52-3.55-1.47l-8.68-8.68-9.17,9.17c-.95.95-2.21,1.47-3.55,1.47s-2.6-.52-3.55-1.47c-1.96-1.96-1.96-5.14,0-7.1l9.17-9.17-8.68-8.68c-1.96-1.96-1.96-5.14,0-7.1.95-.95,2.21-1.47,3.55-1.47s2.6.52,3.55,1.47l8.68,8.68,8.19-8.19c.95-.95,2.21-1.47,3.55-1.47,1.34,0,2.6.52,3.55,1.47.95.95,1.47,2.21,1.47,3.55s-.52,2.6-1.47,3.55l-8.19,8.19,8.68,8.68c.95.95,1.47,2.21,1.47,3.55s-.52,2.6-1.47,3.55Z"
    />
  </svg>
);
export default FilterCancelIcon;
