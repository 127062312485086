import * as React from 'react';
const DesignerLocationsProfileIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerLocationsProfileIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M97.45,91.23v-2.63H24.55v2.63h3.75v8.64h-3.75v2.63h72.89v-2.63h-3.75v-8.64h3.75ZM34.05,91.23h24.08v8.64h-24.08v-8.64ZM63.87,99.87v-8.64h24.08v8.64h-24.08Z"
      opacity={0.65}
    />
    <path
      strokeWidth={0}
      d="M29.62,39.38v47.73h62.37v-47.73H29.62ZM82.91,75.13v5.19h-4.03v-5.19h-2.99l5-7.14,5,7.14h-2.99Z"
      opacity={0.65}
    />
    <path
      strokeWidth={0}
      d="M123.29,107.73H20.74s0-102.55,0-102.55c0-.94-.77-1.71-1.71-1.71H4.71c-.94,0-1.71.77-1.71,1.71v9.15s11.32,0,11.32,0c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3s0,7.57,0,7.57h11.33c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v7.57h11.33c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v7.57h6.57c.94,0,1.71.77,1.71,1.71s-.76,1.71-1.71,1.71H3v29.11h0c0,.94.77,1.71,1.71,1.71h24.8s0,0,0,0h4.32v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57v-11.33c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v11.33h7.57s0-6.57,0-6.57c0-.94.77-1.71,1.71-1.71.94,0,1.71.76,1.71,1.71v6.57h7.57v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57s0-11.33,0-11.33c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v11.33h7.57v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57v-6.57c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v6.57h7.57s0-11.33,0-11.33c0-.94.77-1.71,1.71-1.71s1.71.76,1.71,1.71v11.33h9.14c.94,0,1.71-.77,1.71-1.71v-14.32c0-.94-.77-1.71-1.71-1.71Z"
      opacity={0.4}
    />
    <rect width={3.35} height={27.15} x={24.79} y={8.15} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="37.21 31.01 27.95 21.72 37.23 12.43 42.72 12.43 35.38 19.77 59.33 19.77 59.32 23.67 35.38 23.67 42.7 31.01 37.21 31.01"
    />
    <rect width={3.35} height={27.15} x={93.49} y={8.15} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="84.42 31.01 93.68 21.72 84.4 12.43 78.91 12.43 86.25 19.77 62.3 19.77 62.31 23.67 86.25 23.67 78.93 31.01 84.42 31.01"
    />
    <rect width={27.15} height={3.35} x={98.45} y={99.14} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="102.73 90.07 112.02 99.34 121.31 90.06 121.31 84.57 113.97 91.91 113.97 71.96 110.08 71.96 110.08 91.91 102.73 84.58 102.73 90.07"
    />
    <rect width={27.15} height={3.35} x={98.45} y={38.21} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="121.31 50.63 112.02 41.36 102.73 50.65 102.73 56.14 110.07 48.8 110.08 68.75 113.97 68.74 113.97 48.8 121.31 56.12 121.31 50.63"
    />
  </svg>
);
export default DesignerLocationsProfileIcon;
