import * as React from 'react';
const ObjectAisleLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisleLeftIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="100.12 121.71 5 99.88 5 26.36 100.12 3.54 123 3.54 123 121.71 100.12 121.71"
    />
    <polygon
      strokeWidth={0}
      points="102.06 7.68 102.06 42.68 120.06 42.68 102.06 7.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="102.06 7.68 102.06 42.68 120.06 42.68 102.06 7.68"
    />
    <polygon
      strokeWidth={0}
      points="120.5 40.5 120.5 5.5 102.5 5.5 120.5 40.5"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="120.5 40.5 120.5 5.5 102.5 5.5 120.5 40.5"
    />
    <polygon
      strokeWidth={0}
      points="102.06 45.68 102.06 80.68 120.06 80.68 102.06 45.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="102.06 45.68 102.06 80.68 120.06 80.68 102.06 45.68"
    />
    <polygon
      strokeWidth={0}
      points="120.5 78.5 120.5 43.5 102.5 43.5 120.5 78.5"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="120.5 78.5 120.5 43.5 102.5 43.5 120.5 78.5"
    />
    <polygon
      strokeWidth={0}
      points="102.06 82.68 102.06 117.68 120.06 117.68 102.06 82.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="102.06 82.68 102.06 117.68 120.06 117.68 102.06 82.68"
    />
    <polygon
      strokeWidth={0}
      points="120.5 115.5 120.5 80.5 102.5 80.5 120.5 115.5"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="120.5 115.5 120.5 80.5 102.5 80.5 120.5 115.5"
    />
  </svg>
);
export default ObjectAisleLeftIcon;
