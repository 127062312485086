import React, { ChangeEventHandler } from 'react';

import { AriaCheckboxProps } from '@react-types/checkbox';
import classNames from 'classnames';
import { useCheckbox, useId } from 'react-aria';
import { useToggleState } from 'react-stately';
import useSharedRef from '../../utils/use-shared-ref';

export type InputCheckboxSize = {
  xs?: 'h-4 w-4 text-lg';
  sm?: 'h-6 w-6 text-xs';
  lg?: 'h-8 w-8 text-lg';
};

export type InputCheckboxProps = {
  className?: string;
  label?: string;
  describedBy?: string;
  isDisabled?: boolean;
  isHoverable?: boolean;
  truncate?: boolean;
  size?: string;
  titleAlt?: string;
  onChangeValidate?: ChangeEventHandler;
} & AriaCheckboxProps &
  InputCheckboxSize;

const InputCheckbox = React.forwardRef<HTMLInputElement, InputCheckboxProps>(
  (props: InputCheckboxProps, ref) => {
    const id = useId();
    const labelId = `checkbox-label-${id}`;
    const { children } = props;
    const state = useToggleState(props);
    const sharedRef = useSharedRef(ref);
    const { inputProps } = useCheckbox(
      { ...props, 'aria-labelledby': labelId },
      state,
      sharedRef,
    );

    return (
      <>
        <label
          data-component={`InputCheckbox`}
          data-label={`input-checkbox-${props.label || ''}`}
          aria-label={'InputCheckbox-label-' + id}
          id={labelId}
          className={classNames(
            'group inline-flex cursor-pointer items-center break-words',
            'checked:text-menu-active w-full flex-1',
            {
              'hover:bg-menu-active/10 hover:text-menu-active-text rounded-md':
                props.isHoverable && !props.isDisabled,
            },
            { 'opacity-50': props.isDisabled },
            { truncate: props.truncate },
            props.className,
          )}
        >
          <input
            ref={sharedRef}
            id={'InputCheckbox-' + id}
            aria-describedby={'InputCheckbox-' + id}
            aria-labelledby={'InputCheckbox-' + id}
            name=""
            type="checkbox"
            className={classNames(
              'cursor-pointer',
              'h-5 w-5 rounded',
              'group-hover:border-menu-active',
              'bg-app-panel-dark text-menu-active hover:text-menu-active-text',
              'focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent',
              'focus:border-app-panel-dark/30 checked:focus:border-app-panel-dark/30 checked:focus:bg-menu-active checked:focus:text-menu-active-text',
              'checked:group-hover:border-app-panel-dark/30 checked:group-hover:bg-opacity-50',
              'checked:text-menu-active-text checked:border-menu-active checked:bg-menu-active',
              { 'mr-1': children },
              state.isSelected ? 'selected' : 'not-selected',
            )}
            {...inputProps}
            onChange={e => {
              if (props.isDisabled) return;
              e.stopPropagation();
              inputProps.onChange && inputProps.onChange(e);
              props.onChangeValidate && props.onChangeValidate(e);
            }}
          />

          {children && (
            <div
              title={props.titleAlt}
              id={'InputCheckboxValue-' + id}
              className={classNames(
                { truncate: props.truncate },
                'text-menu-text hover:text-menu-active group-hover:text-menu-active m-1 flex-1 p-1',
                'checked:text-menu-active checked:bg-brand checked:bg-current checked:fill-current',
              )}
              aria-label={'InputCheckbox-' + id}
            >
              {children}
            </div>
          )}
        </label>
      </>
    );
  },
);

export default InputCheckbox;
