import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../components/layout/TitleSection';

export type AppBillingStripeElementsWrapperProps = PropsWithChildren & {
  stripeKey: string;
  paymentIntentId: string;
};

const AppBillingStripeElementsWrapper: React.FC<
  AppBillingStripeElementsWrapperProps
> = props => {
  const stripePromise = loadStripe(props.stripeKey);
  const { t } = useTranslation('app');
  return (
    <>
      <TitleSection
        titleView
        title={t`Payment Details`}
        className="mb-1 text-2xl"
      />
      <Elements
        stripe={stripePromise}
        options={{
          appearance: {
            theme: 'night',
            labels: 'floating',
            variables: {
              colorPrimary: '#FFCE00',
              fontFamily: 'NeoSans',
              borderRadius: '0px',
              focusOutline: '#FFCE00',
              focusBoxShadow: '#FFCE00',
              spacingUnit: '3px',
              colorBackground: '#363D42',
              colorIconTabSelected: '#2B3034',
              colorLogoTabSelected: 'dark',
              // colorIcon: '#363D42',
              // colorPrimaryText: '#FFCE00',
              colorText: 'white',
              colorTextSecondary: 'white',
              colorTextPlaceholder: '#FFCE00',
              colorIconTab: 'white',
              colorLogo: 'dark',
              colorDanger: '#ff302f',
              colorWarn: '#8810e2',
            },
          } as unknown, //HACK: To suppress TS error  on   labels: 'floating',
          clientSecret: props.paymentIntentId,
        }}
      >
        {props.children}
      </Elements>
    </>
  );
};

export default AppBillingStripeElementsWrapper;
