import { useApolloClient } from '@apollo/client';
import {
  ImportJobImportType,
  ImportJobsFilter,
  LoadImportJobsDocument,
  LoadImportJobsQuery,
  LoadImportJobsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { DATASET_VIEW_PAGE_SIZE } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  importJobsData,
  importJobsDataStatus,
} from '../store/importJobs.state';

export type LoadImportJobsDataParams = {
  importType: ImportJobImportType;
  targetId?: string;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: ImportJobsFilter;
};

function useLoadImportJobs() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Import Jobs`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(importJobsDataStatus, AsyncLoadStatus.Loading);
  });

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(importJobsDataStatus);
    reset(importJobsData);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadImportJobsDataParams) => {
        const current = await snapshot.getPromise(importJobsData);
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);

        const query = client.watchQuery<
          LoadImportJobsQuery,
          LoadImportJobsQueryVariables
        >({
          query: LoadImportJobsDocument,
          variables: {
            filter: {
              warehouseId: [warehouseId],
              importType: [params.importType],
              targetId: params.targetId ? [params.targetId] : null,
            },
            //sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DATASET_VIEW_PAGE_SIZE,
            },
          },
        });

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(importJobsDataStatus, AsyncLoadStatus.Error);
        }

        const queryObservable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let jobs = data.importJobs.content;

            if (params.isAppend) {
              jobs = [...(current?.content ?? []), ...jobs];
            }

            set(importJobsData, {
              totalCount: data.importJobs.totalCount,
              content: jobs,
            });
            set(importJobsDataStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(queryObservable);
      },
  );

  async function call(params: LoadImportJobsDataParams) {
    await initLoading();
    await loadCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }
  function reset() {
    cancel();
    resetCallback();
  }

  return [call, cancel, reset] as const;
}

export default useLoadImportJobs;
