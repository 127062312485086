import { ApolloQueryResult } from '@apollo/client';
import {
  AppBillingCustomerFragment,
  LoadCurrentAppBillingCustomerDocument,
  LoadCurrentAppBillingCustomerQuery,
  LoadCurrentAppBillingCustomerQueryVariables,
} from '@warebee/frontend/app-billing-graphql-api';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { billingClient } from '../../GraphQLClient';
import { loggedInUser } from '../../store/auth.state';
import { errorAppender } from '../../store/error.state';
import { warehouseSelected } from '../../store/warehouse.state';
import {
  appBillingCustomer,
  appBillingCustomerLoadStatus,
} from '../store/appBilling.state';

function useLoadCurrentCustomer() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load customer data`;

  const cleanupState = useRecoilCallback(({ snapshot, set }) => async () => {
    set(appBillingCustomer, null);
    set(appBillingCustomerLoadStatus, AsyncLoadStatus.Loading);
  });

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (currencyCode: string) => {
        const user = await snapshot.getPromise(loggedInUser);
        const wh = await snapshot.getPromise(warehouseSelected);

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(appBillingCustomerLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        let response: ApolloQueryResult<LoadCurrentAppBillingCustomerQuery>;
        try {
          response = await billingClient.query<
            LoadCurrentAppBillingCustomerQuery,
            LoadCurrentAppBillingCustomerQueryVariables
          >({
            query: LoadCurrentAppBillingCustomerDocument,
          });
        } catch (ex) {
          handleError(ex.message || ex, ex.stack || null);
          return;
        }

        if (response.errors) {
          handleError(null, response.errors.map(e => e.message).join('. '));
          return;
        } else {
          const customer: AppBillingCustomerFragment =
            response.data.currentAppBillingCustomer?.find(
              c => c.currency === currencyCode,
            ) ?? {
              name: user.fullName,
              email: user.email,
              currency: currencyCode,
              address: {
                country: wh.country === 'UK' ? 'GB' : wh.country, //HACK: Fix old warehouses
              },
            };
          set(appBillingCustomer, customer);
          set(appBillingCustomerLoadStatus, AsyncLoadStatus.Ok);
        }
      },
  );

  return async (currencyCode: string) => {
    await cleanupState();
    await loadCallback(currencyCode);
  };
}

export default useLoadCurrentCustomer;
