import _ from 'lodash';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import BayLayoutAlertsBase from '../../layout/alerts/BayLayoutAlertsBase';
import { InformationAlertMessageType } from '../../layout/alerts/LayoutInformationAlert';
import { LoadingAlertMessageType } from '../../layout/alerts/LayoutLoadingAlert';
import { LoadingErrorAlertMessageType } from '../../layout/alerts/LayoutLoadingErrorAlert';
import { actualityHeatmapBayData } from '../store/actuality.heatmap.state';

const ActualityLayoutLoadingAlerts: React.FC = () => {
  const heatmapLoadable = useRecoilValueLoadable(actualityHeatmapBayData);

  const messages: LoadingAlertMessageType[] = [];
  const errors: LoadingErrorAlertMessageType[] = [];
  const infos: InformationAlertMessageType[] = [];

  if (heatmapLoadable.state === 'loading') {
    messages.push(LoadingAlertMessageType.heatmap);
  }
  if (heatmapLoadable.state === 'hasError') {
    errors.push(LoadingErrorAlertMessageType.heatmap);
  }

  return (
    <BayLayoutAlertsBase
      messages={_.take(messages, 1)}
      errors={errors}
      infos={infos}
    />
  );
};
export default ActualityLayoutLoadingAlerts;
