import { BillableOperationType } from '@warebee/shared/app-billing-utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import { getOperationTitle } from '../store/appBilling.helper';
import { FeatureListPreset } from './AppBillingFeaturesList';

export type AppBillingContentProps = {
  price?: FormattedValueWithUnit;
  description?: string;
  operationType?: BillableOperationType;
  featurePreset?: FeatureListPreset;
};

export type AppBillingOneTimePriceContentProps = AppBillingContentProps & {
  operationCount: number;
};

const AppBillingOneTimePriceContent: React.FC<
  AppBillingOneTimePriceContentProps
> = props => {
  const { t } = useTranslation('app');
  const price = props.price.value;
  const currency = props.price.unit;

  return (
    <div className="flex items-end justify-center mt-8">
      <div className="flex items-baseline">
        <span className="text-4xl text-opacity-75">{currency}</span>
        <span className="text-7xl">{price}</span>
      </div>

      <div className="flex flex-col items-start self-center ltr:ml-4 rtl:mr-4">
        <div className="items-start text-xl">{t`per`}</div>
        <div className="text-2xl text-opacity-75">
          <span className="ltr:mr-1 rtl:ml-1">{props.operationCount}</span>
          <span className="">
            {getOperationTitle(props.operationType, t)}
            {`s`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppBillingOneTimePriceContent;
