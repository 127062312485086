import { AssignmentPolicyRuleMatchType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../components/layout/TitleSection';
import { PolicyCardItem } from '../components/policies/PolicyCardItem';
import { getAssignmentPolicyRuleTypes } from '../simulation/store/assignmentPolicy.helper';
import {
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';

const AssignmentPolicyRuleTypeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(assignmentPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    assignmentPolicyRule(selectedIdentity?.ruleId),
  );
  if (selectedIdentity?.filterType !== 'ruleType' || !rule) return null;

  const selected =
    rule.type ?? AssignmentPolicyRuleMatchType.ITEM_MUST_BE_IN_LOCATION;

  function onChangeRuleType(ruleType: AssignmentPolicyRuleMatchType) {
    updateRule({
      ...rule,
      type: ruleType,
    });
  }

  const options = getAssignmentPolicyRuleTypes(t);
  return (
    <div data-component="AssignmentPolicyRuleTypeEditor">
      <TitleSection
        id={`assignment-policy-rule-type-editor-panel`}
        title={t`Rule type`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`flex flex-col p-1 bg-app-panel-dark/60`}>
            <div
              className={`flex flex-col relative h-full flex-1 min-h-[3rem]`}
            >
              {_.map(options, (ruleType, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-group-${ruleType.id}`}
                    isActive={ruleType.id === selected}
                    onClick={() => onChangeRuleType(ruleType.id)}
                    icon={ruleType.icon}
                    iconClassName={`w-12 h-12 ltr:mr-2 rtl:ml-2`}
                    title={ruleType.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default AssignmentPolicyRuleTypeEditor;
