import { LayoutImportBay } from '@warebee/frontend/data-access-api-graphql';
import { BayTypeSettings } from '@warebee/shared/data-access-layout-import-converter';
import { DEFAULT_BAY_TYPE } from '@warebee/shared/engine-model';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { formatInteger } from '../../../../common/formatHelper';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { Button } from '../../../../components/actions/Button';
import { PanelBody } from '../../../../components/designer/panels/PanelBody';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import {
  InputAnswerGroup,
  InputQA,
  InputQuestion,
} from '../../../../components/inputs/InputQuestionAnswer';
import { ScreenTitle } from '../../../../components/layout/ScreenTitle';
import TitleSection from '../../../../components/layout/TitleSection';
import { Stat } from '../../../../components/stats/Stat';
import HelpContainer from '../../../../helpContext/HelpContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import { getLocationDefaultConfig } from '../store/converter.defaults';
import { applyLocationSettings } from '../store/converter.helper';
import {
  converterBayTypesBuilder,
  converterLayoutData,
  converterLayoutDataInitial,
  converterLocationPatches,
  converterLocationTypesBuilder,
} from '../store/converter.state';
import StepBaySetupShelves from './StepBaySetupShelves';

type NumericBayTypeFields = Pick<
  BayTypeSettings,
  'shelfHeight' | 'verticalFrameProfile' | 'maxWeight'
>;

const StepBaySetup: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const wh = useRecoilValue(warehouseSelected);
  const layoutData = useRecoilValue(converterLayoutData);
  const locationTypes = useRecoilValue(converterLocationTypesBuilder);
  const bayTypesInitial = useRecoilValue(converterBayTypesBuilder);
  const [bayTypes, setBayTypes] = useState<Record<string, BayTypeSettings>>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBayTypes(_.cloneDeep(bayTypesInitial));
  }, [bayTypesInitial]);

  const saveChanges = useRecoilCallback(({ snapshot, set }) => async () => {
    const layoutData = await snapshot.getPromise(converterLayoutDataInitial);
    const patches = await snapshot.getPromise(converterLocationPatches);

    const data = applyLocationSettings({
      data: layoutData,
      locationPatches: patches,
      locationTypeMap: locationTypes,
      locationTypeDefault: getLocationDefaultConfig(wh.measurementSystem),
      bayModelMap: bayTypes,
    });

    // Set Data
    set(converterBayTypesBuilder, bayTypes);
    set(converterLayoutData, data);
    setLoading(false);
  });

  const changeMinLevelHeight = useCallback(
    (value: number, level: string, typeId: string) => {
      setBayTypes({
        ...bayTypes,
        [typeId]: {
          ...bayTypes[typeId],
          levelHeights: {
            ...(bayTypes[typeId].levelHeights ?? {}),
            [level]: value,
          },
        },
      });
    },
    [bayTypes],
  );

  const changeBaySetting = useCallback(
    (value: number, typeId: string, field: keyof NumericBayTypeFields) => {
      console.log(`set ${field} to value : ${value}`);
      console.log(bayTypes);
      setBayTypes({
        ...bayTypes,
        [typeId]: {
          ...bayTypes[typeId],
          [field]: value,
        },
      });
    },
    [bayTypes],
  );

  if (!layoutData) return null;

  const bays: LayoutImportBay[] = _.flattenDeep(
    layoutData.areas.map(area =>
      area.aisles.map(aisle => aisle.sides.map(s => s.bays)),
    ),
  );

  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  const weightMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'weight'),
    tMeasures,
  );
  const bayCountByType = _.countBy(bays, b => b.bayType ?? DEFAULT_BAY_TYPE);

  function drawBayTypesSettings(settings: BayTypeSettings) {
    return (
      <PanelBody key={`bay-type-${settings.bayType}`}>
        <TitleSection
          id={`bay-type-settings-${settings.bayType}`}
          title={t(`Racking Type: {{bayType}}`, {
            bayType: settings.bayType,
          })}
          inPanelView
          collapsible
          titleSummaryClosed={
            <span className="text-end text-sm p-1 px-2 rounded border border-menu-400">
              {formatInteger(bayCountByType[settings.bayType])}
            </span>
          }
          titleSummaryOpen={
            <span className="text-end text-sm p-1 px-2 rounded border border-menu-active/30 text-menu-active">
              {formatInteger(bayCountByType[settings.bayType])}
            </span>
          }
        >
          <Stat
            title={t(`Racking: {{bayType}}`, {
              bayType: settings.bayType,
            })}
            // value={formatInteger(locByRackType[settings.typeId].length)}
            value={formatInteger(bayCountByType[settings.bayType])}
            isFullWidth
            hasHelper
            // isActionable
            isPreview
            // isSelected={isSelected}
            // onClick={() => toggleSelectionRackType(settings.typeId)}
          >
            <InputGroupList hasSpacing className="mt-4">
              <InputNumber
                title={t`Column Width`}
                value={settings.verticalFrameProfile}
                unitOfMeasure={sizeMeasureValue}
                onChange={v =>
                  changeBaySetting(v, settings.bayType, 'verticalFrameProfile')
                }
                debounceInterval={100}
                range={[0, null]}
              />
              <InputNumber
                title={t`Beam Height`}
                value={settings.shelfHeight}
                unitOfMeasure={sizeMeasureValue}
                onChange={v => {
                  changeBaySetting(v, settings.bayType, 'shelfHeight');
                }}
                debounceInterval={100}
                range={[0, null]}
              />
              <InputNumber
                title={t`Max Weight`}
                value={settings.maxWeight}
                unitOfMeasure={weightMeasureValue}
                onChange={v => {
                  changeBaySetting(v, settings.bayType, 'maxWeight');
                }}
                debounceInterval={100}
                range={[0, null]}
              />
            </InputGroupList>
            <Button
              className="mt-6 mb-2"
              label={t`Apply`}
              buttonSize="sm"
              buttonType="primary"
              full
              onPress={() => {
                setLoading(true);
                setTimeout(saveChanges, 1);
              }}
              isLoading={loading}
              isDisabled={loading}
            />
          </Stat>
        </TitleSection>
      </PanelBody>
    );
  }

  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        subtitle={t`Aisles, Locations and Bays`}
        title={t`Rack Profiles`}
      />
      <InputQA>
        <InputQuestion
          icon={Icon.DesignerBaysLocationsProfile}
          question={t`Configure your rack system`}
        />
        <InputAnswerGroup>
          <HelpContainer id={'designer/03-setup-racks'} hasPadding />
        </InputAnswerGroup>
      </InputQA>
      <TitleSection className="mt-4" titleView title={t`Bay Sizes (by type)`} />
      <InputQA>{_.values(bayTypes).map(drawBayTypesSettings)}</InputQA>
      <StepBaySetupShelves />
    </PanelBody>
  );
};
export default StepBaySetup;
