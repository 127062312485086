import * as React from 'react';
const LayoutWarehouseDesignAisleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayoutWarehouseDesignAisleIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M29.18,95.63V18.45C29.18-1.89,4.1.29,4.1,18.45v77.72c4.77-10.92,20.89-10.05,25.09-.55Z"
    />
    <path
      strokeWidth={0}
      d="M35.9,22.15v84.71c0,5.94-5.39,6.03-6.72.82-1.39-5.48-6.44-9.32-12.2-9.32-7.13,0-12.89,5.76-12.89,12.75s5.76,12.89,12.89,12.89h106.92V22.15H35.9ZM105.15,43.52v60.28s-7.56,0-7.56,0v-60.28h7.56ZM62.62,43.52v60.28s-7.56,0-7.56,0v-60.28s7.56,0,7.56,0ZM73.49,81.29c-.37.38-1.03.52-1.78-.21-.27-.26-1.15-1.1-1.73-1.66v2.25c0,.62,0,23.69,0,24.44,0,1.79-1.42,3.32-3.08,3.33-3.38.03-11.51.03-14.9,0-1.75-.02-3.4-1.67-3.4-3.41,0,0,.03-25.33.03-27.39v-1.95c0-.39,0-.79,0-1.18,0-.61.35-1,.98-1.1.53-.09,1.06.31,1.18.9.04.22.05.44.05.67v29.84c0,1.15.26,1.41,1.38,1.41h14.45c.92,0,1.09-.17,1.09-1.08v-24.46s0-2.24,0-2.24c-.54.54-1.31,1.31-1.54,1.53-.25.24-.55.36-.85.34-.3-.02-.57-.16-.77-.4-.37-.44-.3-1.09.17-1.58l3.29-3.4c.24-.25.55-.38.87-.38s.67.15.93.41l3.47,3.6c.31.32.47.67.47,1.02,0,.27-.1.52-.3.72ZM75.14,103.8v-60.28h7.56v60.28s-7.56,0-7.56,0ZM86.3,44.05c.2-.24.47-.39.77-.4.3-.01.6.11.85.34.23.22,1,.99,1.54,1.53v-2.24s0-2.11,0-2.11c0-.91-.17-1.08-1.09-1.08h-16.8c-1.13,0-1.38.26-1.38,1.41v29.84c0,.23,0,.45-.05.67-.12.59-.65.99-1.18.9-.63-.1-.98-.5-.98-1.1,0-.39,0-.79,0-1.18v-1.95c0-2.07,0-26.89-.02-27.29v-.1c0-1.74,1.65-3.39,3.4-3.41,3.39-.03,13.88-.03,17.25,0,1.66.01,3.08,1.54,3.08,3.33,0,.75,0,1.47,0,2.09v2.25c.59-.56,1.47-1.4,1.73-1.66.74-.73,1.4-.58,1.78-.21.19.2.3.44.3.72,0,.35-.16.7-.47,1.02l-3.47,3.6c-.26.27-.59.41-.93.41s-.63-.14-.87-.38l-3.29-3.4c-.47-.49-.53-1.13-.17-1.58ZM116.51,103.64c-.37.38-1.03.52-1.78-.21-.27-.26-1.15-1.1-1.73-1.66v2.25c0,.62,0,1.34,0,2.09,0,1.79-1.42,3.32-3.08,3.33-3.38.03-13.87.03-17.25,0-1.75-.01-3.4-1.67-3.4-3.41v-.1c.02-.4.03-47.58.03-49.64v-1.95c0-.39,0-.79,0-1.18,0-.6.36-1,.98-1.1.53-.09,1.06.31,1.18.9.04.22.05.44.05.67v52.19c0,1.15.26,1.41,1.38,1.41h16.8c.92,0,1.09-.17,1.09-1.08v-2.11s0-2.24,0-2.24c-.54.54-1.31,1.31-1.54,1.53-.25.23-.55.36-.85.34-.3-.02-.57-.16-.77-.41-.37-.44-.3-1.09.17-1.58l3.29-3.4c.24-.25.55-.38.87-.38s.67.15.93.41l3.47,3.6c.31.32.47.67.47,1.02,0,.27-.1.52-.3.72Z"
    />
  </svg>
);
export default LayoutWarehouseDesignAisleIcon;
