import * as React from 'react';
const DesignerAreaLockCornerIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAreaLockCornerIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M108.09,86.8h-.1v-6.4c0-10.1-8.3-18.4-18.4-18.4s-18.4,8.3-18.4,18.4v6.4h-.4c-2.7,0-4.9,2.2-4.9,4.9v26.8c0,2.7,2.2,4.9,4.9,4.9h37.4c2.7,0,4.9-2.2,4.9-4.9v-26.9c-.2-2.7-2.3-4.8-5-4.8ZM92.29,106.2c-.4.3-.5.5-.5,1v5.9h0c0,.8-.4,1.6-1.1,2-1.7.9-3.4-.3-3.4-2h0v-5.9c0-.4-.1-.7-.4-.9-1.8-1.3-2.4-3.6-1.5-5.5.9-1.9,3-3,5-2.6,2.2.4,3.8,2.2,3.8,4.5-.1,1.3-.7,2.6-1.9,3.5ZM99.89,86.8h-20.7v-6.4c0-5.7,4.6-10.4,10.4-10.4s10.4,4.6,10.4,10.4v6.4h-.1Z"
    />
    <path
      strokeWidth={0}
      d="M108,26.54c-5.51,0-10.05,4.05-10.86,9.33h-31.45v-14.19h8.31s0-20,0-20h-20s0,20,0,20h8.31v14.19h-30.45c-.81-5.28-5.35-9.33-10.86-9.33-6.08,0-11,4.92-11,11,0,5.15,3.55,9.47,8.33,10.66v49.68c-4.78,1.19-8.33,5.51-8.33,10.66,0,6.08,4.92,11,11,11,5.15,0,9.47-3.55,10.66-8.33h28.11v-3.38h-27.8c-.35-5.5-4.75-9.89-10.25-10.25v-49.07c5.17-.33,9.35-4.22,10.14-9.25h65.29c.74,4.7,4.44,8.4,9.14,9.14v12.91h3.38v-12.91c5.28-.81,9.33-5.35,9.33-10.86,0-6.08-4.92-11-11-11Z"
      opacity={0.7}
    />
  </svg>
);
export default DesignerAreaLockCornerIcon;
