import { useApolloClient } from '@apollo/client';
import {
  LoadDatasetObjectDocument,
  LoadDatasetObjectQuery,
  LoadDatasetObjectQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import {
  datasetObjectDocument,
  datasetObjectDocumentId,
  datasetObjectDocumentLoadStatus,
} from '../store/datasetObject.state';

function useLoadDatasetObject(): [
  (itemSetId: string) => Promise<void>,
  () => Promise<void>,
] {
  const client = useApolloClient();

  const resetCallback = useRecoilCallback(({ set, reset }) => async () => {
    reset(datasetObjectDocumentId);
    reset(datasetObjectDocument);
    reset(datasetObjectDocumentLoadStatus);
  });

  const beforeCallback = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        set(datasetObjectDocumentId, null);
        set(datasetObjectDocument, null);
        set(datasetObjectDocumentLoadStatus, AsyncLoadStatus.Loading);
      },
  );

  const loadCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: string) => {
        const response = await client.query<
          LoadDatasetObjectQuery,
          LoadDatasetObjectQueryVariables
        >({
          query: LoadDatasetObjectDocument,
          variables: {
            id,
          },
        });

        const data = response.data.datasetObject;
        set(datasetObjectDocumentId, id);
        set(datasetObjectDocument, data);
        set(datasetObjectDocumentLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function call(itemSetId: string) {
    await beforeCallback();
    await loadCallback(itemSetId);
  }

  async function reset() {
    await resetCallback();
  }

  return [call, reset];
}

export default useLoadDatasetObject;
