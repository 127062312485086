import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import useSelectSimulationWizardStep from '../../hooks/useSelectSimulationWizardStep';
import WorkforceForecastPanel from '../../panels/workforce/forecast/WorkforceForecastPanel';
import { simulationAnalyzeResult } from '../../store/simulation.state';
import ActionBarNextStep from '../ActionBarNextStep';

const WorkforceSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();
  const analyzeResult = useRecoilValue(simulationAnalyzeResult);
  const nextStepButtonLabel = t`Next`;
  return (
    <Container col hasNoScroll>
      <ScreenTitle
        subtitle={t`Analyse`}
        title={t`Analyse Workforce`}
        helpNavTo={'simulation/workforce/simulation-workforce'}
        icon={Icon.SimulationWorkforce}
      />
      <WorkforceForecastPanel analyzeResult={analyzeResult} />
      <ActionBarNextStep title={nextStepButtonLabel} />
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-analyze-time-summary',
    title: t`Analyse Workforce`,
    loadingMessage: t`Loading Workforce data...`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <WorkforceSidebar />
    </SidebarContainer>
  );
};
