import * as React from 'react';
const DesignerLocationsSizeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerLocationsSizeIcon"
    {...props}
  >
    <rect width={3.17} height={25.16} x={100.31} y={2} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="91.73 23.19 100.5 14.58 91.72 5.97 86.52 5.97 93.46 12.78 65.52 12.78 65.52 16.38 93.47 16.38 86.54 23.19 91.73 23.19"
    />
    <rect width={3.17} height={25.16} x={24.51} y={2} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="36.26 5.97 27.5 14.58 36.28 23.19 41.47 23.19 34.53 16.39 62.48 16.38 62.47 12.78 34.53 12.78 41.46 5.97 36.26 5.97"
    />
    <rect width={3.17} height={25.16} x={100.31} y={100.84} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="91.73 122.03 100.5 113.42 91.72 104.81 86.52 104.81 93.46 111.61 65.52 111.62 65.52 115.22 93.47 115.22 86.54 122.03 91.73 122.03"
    />
    <rect width={3.17} height={25.16} x={24.51} y={100.84} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="36.26 104.81 27.5 113.42 36.28 122.03 41.47 122.03 34.53 115.22 62.48 115.22 62.47 111.62 34.53 111.62 41.46 104.81 36.26 104.81"
    />
    <rect width={25.68} height={3.11} x={100.32} y={100.5} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="104.37 92.09 113.16 100.68 121.95 92.08 121.95 86.99 115 93.79 115 66.41 111.32 66.41 111.32 93.79 104.37 87 104.37 92.09"
    />
    <rect width={25.68} height={3.11} x={100.32} y={26.24} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="121.95 37.75 113.16 29.16 104.37 37.77 104.37 42.85 111.32 36.05 111.32 63.43 115 63.43 115 36.05 121.95 42.84 121.95 37.75"
    />
    <rect width={25.68} height={3.11} x={2} y={100.5} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="6.05 92.09 14.84 100.68 23.63 92.08 23.63 86.99 16.68 93.79 16.68 66.41 13 66.41 13 93.79 6.05 87 6.05 92.09"
    />
    <rect width={25.68} height={3.11} x={2} y={26.24} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="23.63 37.75 14.84 29.16 6.05 37.77 6.05 42.85 13 36.05 13 63.43 16.68 63.43 16.68 36.05 23.63 42.84 23.63 37.75"
    />
    <path
      strokeWidth={0}
      d="M85.03,79.31h2.34s0-3.59,0-3.59h-45.47s0,3.59,0,3.59h2.34s0,5.09,0,5.09h-2.34s0,3.59,0,3.59h45.47s0-3.59,0-3.59h-2.34s0-5.09,0-5.09ZM62.84,84.4h-15.02s0-5.09,0-5.09h15.02s0,5.09,0,5.09ZM81.45,84.41h-15.02s0-5.09,0-5.09h15.02s0,5.09,0,5.09Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M83.99,40.84c0-.27-.22-.48-.48-.48h-37.74c-.27,0-.48.22-.48.48v32.51c0,.27.22.48.49.48h37.74c.27,0,.48-.22.48-.48v-32.51ZM53.29,50.08v4.05h-3.14v-4.05h-2.33l3.9-5.57,3.9,5.57h-2.33Z"
      opacity={0.6}
    />
    <path
      strokeWidth={0}
      d="M93.63,35v58h-58v-58h58M97.63,31H31.63v66h66V31h0Z"
      opacity={0.8}
    />
  </svg>
);
export default DesignerLocationsSizeIcon;
