import * as React from 'react';
const LinkDnaIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LinkDnaIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M103.55,47.53h4.62s10,32.88,10,32.88h-4.62s-10-32.88-10-32.88Z"
    />
    <path
      strokeWidth={0}
      d="M95.45,47.53h4.62s10,32.88,10,32.88h-4.62s-10-32.88-10-32.88Z"
    />
    <path
      strokeWidth={0}
      d="M87.34,47.53h4.62s10,32.88,10,32.88h-4.62s-10-32.88-10-32.88Z"
    />
    <path
      strokeWidth={0}
      d="M93.85,80.41l-10-32.88h-2.62c-.67,0-1.33.05-1.99.13l10,32.6c.69.09,1.38.14,2.07.15h2.55Z"
    />
    <path
      strokeWidth={0}
      d="M84.75,79.42c-1.67-.6-4.23-1.47-5.62-2.58l-6.43-21.55c0-.77,1.66-3.8,3.33-4.39l8.71,28.51Z"
    />
    <path
      strokeWidth={0}
      d="M112.12,47.53c1.67.6,5.7,1.19,7.09,2.29l1.65,26.23-8.74-28.52Z"
    />
    <path
      strokeWidth={0}
      d="M24.27,48.11h-4.62s-10,32.88-10,32.88h4.62s10-32.88,10-32.88Z"
    />
    <path
      strokeWidth={0}
      d="M32.37,48.11h-4.62s-10,32.88-10,32.88h4.62s10-32.88,10-32.88Z"
    />
    <path
      strokeWidth={0}
      d="M40.48,48.11h-4.62s-10,32.88-10,32.88h4.62s10-32.88,10-32.88Z"
    />
    <path
      strokeWidth={0}
      d="M33.97,80.99l10-32.88h2.62c.67,0,1.33.05,1.99.13l-10,32.6c-.69.09-1.38.14-2.07.15h-2.55Z"
    />
    <path
      strokeWidth={0}
      d="M43.07,80c1.67-.6,4.23-1.47,5.62-2.58l6.43-21.55c0-.77-1.66-3.8-3.33-4.39l-8.71,28.51Z"
    />
    <path
      strokeWidth={0}
      d="M15.7,48.11c-1.67.6-5.7,1.19-7.09,2.29l-1.65,26.23,8.74-28.52Z"
    />
    <path
      strokeWidth={0}
      d="M8.67,42.91h31.26c3.56,0,7.07.91,10.19,2.64,3.36,1.85,6.14,4.59,8.05,7.92.77-2.19,1.79-4.28,3.01-6.25-5.12-6.52-12.95-10.33-21.24-10.34H8.67c-.8,0-1.57.31-2.14.88-.57.57-.89,1.33-.89,2.14,0,1.67,1.36,3.01,3.03,3.01h0Z"
    />
    <path
      strokeWidth={0}
      d="M122.36,88.04c0-1.67-1.36-3.01-3.03-3.01h-31.26c-3.56,0-7.07-.91-10.19-2.64-3.36-1.85-6.14-4.59-8.05-7.92-.77,2.19-1.79,4.28-3.01,6.25,5.12,6.52,12.95,10.33,21.24,10.34h31.28c.8,0,1.57-.31,2.14-.88.57-.57.89-1.33.89-2.14h0Z"
    />
    <path
      strokeWidth={0}
      d="M8.67,91.05h31.26c8.29,0,16.13-3.8,21.26-10.32.52-.66,1-1.34,1.46-2.04.45-.7.93-1.54,1.35-2.34,1.96-3.8,2.99-8,3-12.28.03-2.52.49-5.02,1.37-7.38.4-1.07.89-2.11,1.46-3.1,1.92-3.34,4.7-6.1,8.07-7.98,1.46-.81,3.01-1.44,4.62-1.88,1.78-.49,3.62-.74,5.47-.74h31.35c1.67,0,3.02-1.35,3.03-3.01.02-.81-.29-1.6-.86-2.18-.57-.58-1.35-.91-2.17-.9h-31.26c-8.29,0-16.13,3.8-21.26,10.32-.52.66-1,1.34-1.46,2.04-.45.7-.95,1.62-1.35,2.34-1.96,3.8-2.99,8-3,12.28-.02,2.55-.48,5.07-1.37,7.45-.4,1.07-.89,2.11-1.46,3.1-1.92,3.34-4.7,6.1-8.07,7.98-1.46.84-3.01,1.5-4.63,1.95-1.78.49-3.62.74-5.48.74H8.67c-1.66,0-3.01,1.35-3.01,3.01s1.35,3.01,3.01,3.01v-.07Z"
    />
  </svg>
);
export default LinkDnaIcon;
