import {
  AisleSide,
  BayLocationOrder,
} from '@warebee/frontend/data-access-api-graphql';
import {
  BayRowBuilderSettings,
  OrderTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import _, { parseInt } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import { InputValue } from '../../../../components/inputs/InputValue';
import TitleSection from '../../../../components/layout/TitleSection';
import { ConvertedBayFeature } from '../converter.serializable.model';
import { getFeatureOrderIndex } from '../store/converter.helper';
import { converterLayoutAltSelectedBayId } from '../store/converter.layout.state';
import { getOrderOptionsMap } from '../store/converter.types';
import OrderedItemsTable from './OrderedItemsTable';

export type ConverterAisleSidePanelProps = {
  side: AisleSide;
  bays: string[];
  bayMap: Record<string, ConvertedBayFeature>;
  builder: BayRowBuilderSettings;
  locationOrder: BayLocationOrder;
  onChangeBayOrder: (builder: BayRowBuilderSettings) => void;
  onChangeLocationOrder: (v: BayLocationOrder) => void;
};

export const ConverterAisleSidePanel: React.FC<
  ConverterAisleSidePanelProps
> = props => {
  const { t } = useTranslation('designer');
  const setSelectedBay = useSetRecoilState(converterLayoutAltSelectedBayId);
  if (_.isEmpty(props.bays)) return null;

  const orderOptionsMap = getOrderOptionsMap(t);
  function applyPatch(patch: Partial<BayRowBuilderSettings>) {
    const { builder, onChangeBayOrder: onChange } = props;
    onChange({
      ...builder,
      ...patch,
    });
  }
  const baysLength = props.bays.length;
  const sorted = _.sortBy(props.bays, (bayId, index) =>
    getFeatureOrderIndex(bayId, index, baysLength, props.builder),
  );

  const drawOptionEditor = () => {
    if (props.builder.orderType === OrderTypeOption.Custom) {
      return (
        <OrderedItemsTable
          itemColumnTitle={t`Bay`}
          items={sorted}
          render={v => props.bayMap[v]?.title ?? v}
          onChange={bays => applyPatch({ customOrder: bays })}
          onRowMouseEnter={bayId => {
            setSelectedBay(bayId);
          }}
          onRowMouseLeave={() => {
            setSelectedBay(null);
          }}
        />
      );
    }
    if (props.builder.orderType === OrderTypeOption.Shifted) {
      return (
        <InputValue
          value={props.builder.shift || 0}
          // title={`Bay's count to shift`}
          title={t`Shift Bays by:`}
          icon={<Icon.Order className="w-8 h-8 fill-current" />}
          onChange={v => applyPatch({ shift: parseInt(v) })}
        />
      );
    }
    return null;
  };

  return (
    <TitleSection
      id={'Bay-order-' + props.side}
      title={props.side + ' ' + t`Bays`}
      inPanelView
      collapsible
    >
      <DropdownSelector
        panelMode
        widthFull
        value={props.builder.orderType}
        values={[...orderOptionsMap.keys()]}
        label={props.side + ' ' + t`Bay Order`}
        renderValue={i => orderOptionsMap.get(i)}
        onChange={(v: OrderTypeOption) => {
          applyPatch({ orderType: v });
        }}
        icon={
          <Icon.DesignerBaysOrder className="w-8 h-8 fill-current" />
        }
      />
      {drawOptionEditor()}
      <DropdownSelector
        panelMode
        widthFull
        value={props.locationOrder}
        values={['LTR', 'RTL']}
        label={t(`{{side}} Bay's location order`, { side: props.side })}
        //renderValue={i => orderOptionsMap.get(i)}
        onChange={props.onChangeLocationOrder}
        icon={<Icon.DesignerBaysLeftToRight className="w-8 h-8 fill-current" />}
      />
    </TitleSection>
  );
};

export default ConverterAisleSidePanel;
