import { registerEnumType } from '@warebee/shared/util-backend-only-types';

export enum MeasurementSystem {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

registerEnumType(MeasurementSystem, {
  name: 'MeasurementSystem',
});
