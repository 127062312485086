import {
  AssignmentComplianceItemFragment,
  AssignmentComplianceItemStatus,
  LoadAssignmentComplianceItemsQuery,
  LoadAssignmentComplianceItemsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ExportConverter, ColumnConfigBase, ExportJobParams } from './types';

export type AssignmentComplianceExportJobParams = ExportJobParams<
  LoadAssignmentComplianceItemsQueryVariables,
  AssignmentComplianceConverterConfig
>;

export const assignmentComplianceExportJobParams: AssignmentComplianceExportJobParams =
  {
    converterId: 'ASSIGNMENT_COMPLIANCE',
    query: null,
    config: null,
    variables: null,
    filename: 'assignment-compliance.csv',
  };

export type AssignmentComplianceDataRow = AssignmentComplianceItemFragment & {
  statusString: string;
  locationRules: string;
  itemRules: string;
  violatedRules: string;
  skuGroup: string;
  subGroup: string;
};

export type AssignmentComplianceConverterConfig = {
  columnsConfig: ColumnConfigBase<AssignmentComplianceDataRow>[];
  dictionaries: {
    status: Record<AssignmentComplianceItemStatus, string>;
    rules: Record<string, string>;
  };
};

export const getAssignmentComplianceTableRows = (
  data: AssignmentComplianceItemFragment[],
  config: AssignmentComplianceConverterConfig,
): AssignmentComplianceDataRow[] => {
  const statusMap = config.dictionaries.status;
  const rulesMap = config.dictionaries.rules;

  const reduceAssignmentComplianceItem = (
    assignmentItemData: AssignmentComplianceItemFragment,
  ): AssignmentComplianceDataRow => {
    return {
      ...assignmentItemData,
      statusString: statusMap[assignmentItemData.status],
      skuGroup: assignmentItemData.item?.skuGroup,
      subGroup: assignmentItemData.item?.subGroup,

      locationRules: _(assignmentItemData.locationRuleIds)
        .map(v => rulesMap[v])
        .join(', '),
      itemRules: _(assignmentItemData.itemRuleIds)
        .map(v => rulesMap[v])
        .join(', '),
      violatedRules: _(assignmentItemData.violatedRuleIds)
        .map(v => rulesMap[v])
        .join(', '),
    };
  };

  return _.map(data, reduceAssignmentComplianceItem);
};

export const ASSIGNMENT_COMPLIANCE_EXPORT_CONVERTER: ExportConverter<
  LoadAssignmentComplianceItemsQuery,
  AssignmentComplianceConverterConfig
> = (input, config) => {
  const data = getAssignmentComplianceTableRows(
    input.simulation?.assignmentCompliance?.items?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
