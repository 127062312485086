import * as React from 'react';
const ForecastIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ForecastIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2C29.76,2,2,29.76,2,64s27.76,62,62,62,62-27.76,62-62S98.24,2,64,2ZM108.56,93.72c-3.78,5.41-10.38,7.12-14.7,3.8-4.32-3.31-4.77-10.42-.99-15.83,3.78-5.41,10.38-7.12,14.7-3.8,4.32,3.31,4.77,10.42.99,15.83ZM107.99,62.28c-4.49-3-10.23-4.22-16.15-3.41-7.47,1-14.52,5.04-19.36,11.07-8.95,11.17-7.74,25.93,2.7,32.9,3.59,2.4,7.98,3.65,12.62,3.65,1.16,0,2.34-.08,3.53-.24,4.16-.56,8.2-2.06,11.79-4.33-57.24,39.13-81.59-17.6-81.59-21.11,0-18.6,28.42-44.05,60.95-44.05,34.69,0,37.3,24.69,37.3,24.69-.1,6.35-1.19,12.45-3.12,18.18.21-6.91-2.73-13.4-8.67-17.36Z"
    />
  </svg>
);
export default ForecastIcon;
