import * as React from 'react';
const CirclePinIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CirclePinIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M66.07,4c-33.12-.03-60.05,26.86-60.05,59.95s26.94,60.03,60.05,60.05c33.12.03,60.05-26.86,60.05-59.95S99.18,4.03,66.07,4ZM37.26,95.19c-.33.33-.76.5-1.2.5s-.87-.17-1.2-.5c-.66-.66-.66-1.73,0-2.39l19.73-19.73,2.39,2.39-19.73,19.73ZM95.86,44.47c-.52,0-1.01.24-1.33.65l-1.33,1.7-19.6,25.2c-.28.36-.4.82-.34,1.28l2.27,15.9-17.34-17.34-17.34-17.34,15.91,2.27c.46.07.92-.06,1.28-.34l12.39-9.64,4.35-3.38,10.17-7.91c.41-.32.65-.81.65-1.34v-1.9l6.08,6.08,5.06,5.06,1.03,1.03h-1.9Z"
    />
  </svg>
);
export default CirclePinIcon;
