import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import Tabs, { TabItem } from '../../components/actions/Tabs';
import * as Icon from '../../components/icons';
import StatsCompareSummaryPanel from '../../components/stats/StatsCompareSummaryPanel';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import OptimisationEventsComparePanel from '../../simulation/panels/OptimisationEventsComparePanel';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  analyzeCompareResultByType,
  analyzeCompareViewModeSelected,
} from '../analyzeCompare.state';
import { AnalyzeCompareViewMode } from '../analyzeCompare.types';
import useLoadAnalyzeResultList from '../hooks/useLoadAnalyzeResultList';
import AnalyzeCompareHeatmapPanel from '../panels/AnalyzeCompareHeatmapPanel';
import AnalyzeCompareSinglePanel from '../panels/AnalyzeCompareSinglePanel';

/**
 *  Main sidebar for Analyze Compare
 *
 */
const AnalyzeCompareSummarySidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const whId = useRecoilValue(warehouseSelectedId);

  const analyzeBefore = useRecoilValue(analyzeCompareResultByType('first'));
  const analyzeAfter = useRecoilValue(analyzeCompareResultByType('second'));

  const [selectedViewMode, setSelectedViewMode] = useRecoilState(
    analyzeCompareViewModeSelected,
  );
  const [loadAnalyzeList, cancelLoadAnalyzeList] = useLoadAnalyzeResultList();

  useEffect(() => {
    // Refresh available analyze on first component mount
    cancelLoadAnalyzeList();
    loadAnalyzeList({ warehouseId: whId });
    return () => {
      cancelLoadAnalyzeList();
    };
  }, [whId]);

  const tabIconStyle = classNames(
    'fill-current w-7 lg:w-8 xl:w-10 2xl:w-12 h-auto',
  );

  const tabs: TabItem<AnalyzeCompareViewMode>[] = [
    {
      id: 'first',
      title: t`Simulation 1`,
      content: <AnalyzeCompareSinglePanel analyzeType="first" />,
      icon: <Icon.CompareBefore className={tabIconStyle} />,
    },
    {
      id: 'second',
      title: t`Simulation 2`,
      content: <AnalyzeCompareSinglePanel analyzeType="second" />,
      icon: <Icon.CompareAfter className={tabIconStyle} />,
    },
    {
      id: 'compare',
      title: t`Compare`,
      content: (
        <>
          <StatsCompareSummaryPanel
            analyzeBefore={analyzeBefore}
            analyzeAfter={analyzeAfter}
          />

          <OptimisationEventsComparePanel
            title={t`By event type`}
            eventsBefore={analyzeBefore?.summary?.eventTypes}
            eventsAfter={analyzeAfter?.summary?.eventTypes}
            energyRate={
              analyzeBefore?.resourcePolicy?.agents?.[0]?.specs?.powerSource
                ?.energyConsumptionPerSecond ?? 0
            }
            showKpiSwitch
            showLegendStats
          />
        </>
      ),
      // content: <AnalyzeCompareComparePanel />,
      icon: <Icon.Compare className={tabIconStyle} />,
    },
    {
      id: 'heatmap',
      title: t`Heatmaps`,
      content: <AnalyzeCompareHeatmapPanel />,
      icon: <Icon.HeatMap9 className={tabIconStyle} />,
    },
  ];

  return (
    <Tabs
      componentName="AnalyzeCompareSummarySidebar"
      items={tabs}
      defaultIndex={_.findIndex(tabs, t => t.id === selectedViewMode)}
      onChange={index => setSelectedViewMode(tabs[index].id)}
      hasScroll
      fullHeight
      hasActionbar={false}
    />
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'analyze-compare-summary',
    title: t`Analyse compare`,
    loadingMessage: t`Loading Analyse Compare`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps} width={96}>
      <AnalyzeCompareSummarySidebar />
    </SidebarContainer>
  );
};
