import { Picklist, PicklistLine } from '@warebee/backend/backend-model';
import {
  PickingPolicy,
  ResourcePolicy,
  RoutingPolicy,
  StackingPolicy,
  WaypointPolicy,
} from '@warebee/shared/engine-model';
import {
  Field,
  GraphQLLocalDateTime,
  ID,
  InputAndObjectType,
  ObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';

export class StartAnalyzeInput {
  analyzeResultId: string;
}

export interface AnalyzeEvent {
  eventType: 'analyze.success' | 'analyze.failure';
  analyzeResultId: string;
}

export interface AnalyzeSuccess extends AnalyzeEvent {
  eventType: 'analyze.success';
  startedAt?: number;
  completedAt?: number;
}

export interface AnalyzeFailure extends AnalyzeEvent {
  eventType: 'analyze.failure';
  error?: string;
  cause?: string;
}

export class GeneratePicklistEventsInput {
  analyzeResultId: string;
  picklistIds: string[];
}

export type AnalyzablePicklistLine = Omit<
  PicklistLine,
  'picklistSetId' | 'picklistId' | 'picklist' | 'consignee' | 'sku'
>;

export type AnalyzablePicklist = Omit<
  Picklist,
  'picklistSetId' | 'picklistSet' | 'pickingMethod' | 'picklistOrders'
> & {
  jobDate: string;
  processType?: string;
  lines: AnalyzablePicklistLine[];
};

export interface AnalyzeJobSaveResultInput {
  warehouseId: string;
  documentId: string;
}

export class AnalyzePicklistInput {
  picklists: AnalyzablePicklist[];

  layoutId: string;
  itemSetId?: string;

  resourcePolicy: ResourcePolicy;
  pickingPolicy?: PickingPolicy;
  routingPolicy?: RoutingPolicy;
  stackingPolicy?: StackingPolicy;
  waypointPolicy?: WaypointPolicy;

  saveResult?: AnalyzeJobSaveResultInput;
}

@InputAndObjectType()
export class AnalyzeActualitySourceEventFilter {
  @Field(() => GraphQLLocalDateTime, { nullable: true })
  jobDateFrom?: string;

  @Field(() => GraphQLLocalDateTime, { nullable: true })
  jobDateTo?: string;
}

export enum AnalyzeActualityJobEngine {
  LAMBDA = 'LAMBDA',
  FARGATE = 'FARGATE',
}

registerEnumType(AnalyzeActualityJobEngine, {
  name: 'AnalyzeActualityJobEngine',
});

@InputAndObjectType()
export class AnalyzeActualityJobInput {
  @Field(() => AnalyzeActualitySourceEventFilter, { nullable: true })
  sourceEventFilter?: AnalyzeActualitySourceEventFilter;

  @Field({ nullable: true })
  newEventsOnly?: boolean;

  @Field(() => AnalyzeActualityJobEngine, { nullable: true })
  engine?: AnalyzeActualityJobEngine;
}

@InputAndObjectType()
export class AnalyzeActualityInput extends AnalyzeActualityJobInput {
  @Field(() => ID)
  actualityId: string;
}

@ObjectType()
export class AnalyzeActualityJobOutput {
  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  processedJobs?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  processedEvents?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  generatedEvents?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  generatedAnalyzeEvents?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  generatedIgnoredEvents?: number;
}
