import {
  Field,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { MeasurementSystem } from '@warebee/shared/data-access-layout-manager';
import { CreateBaseEntityInput } from './create-base-entity.input';

@InputType()
export class CreateWarehouseInput extends CreateBaseEntityInput {
  @Field({ nullable: true })
  companyName?: string;

  @Field({ nullable: true })
  address?: string;

  @Field(() => MeasurementSystem, { defaultValue: MeasurementSystem.METRIC })
  measurementSystem: MeasurementSystem;

  @Field(() => ID, { defaultValue: 'GB' })
  country: string;

  @Field(() => ID, { defaultValue: 'USD' })
  currency: string;
}
