import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenHeader from './ScreenHeader';
import { Spacer } from './layout/Spacer';
import NavBreadcrumbs, { NavBreadcrumbsItem } from './nav/NavBreadcrumbs';

export type AppHeaderInnerProps = {
  children?: React.ReactNode;
  warehouseId: string;
  breadcrumbItems: NavBreadcrumbsItem[];
  subtitle?: string;
};

const AppHeaderInner: React.FC<AppHeaderInnerProps> = props => {
  const { t } = useTranslation('app');

  return (
    <ScreenHeader
      backPath={`/wh/i/${props.warehouseId}`}
      subtitle={props.subtitle || t`WareBee Simulate`}
    >
      <NavBreadcrumbs items={props.breadcrumbItems} />
      <Spacer flexspace />

      {props.children}
    </ScreenHeader>
  );
};

export default React.memo(AppHeaderInner);
