import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { HelperMessage } from '../../../components/HelperMessage';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../../store/warehouse.state';
import useUpdateUserSettings from '../../hooks/useUpdateUserSettings';
import { BetaCard } from './BetaCard';

export type BetaProps = {
  children?: React.ReactNode;
};

export const BetaContracts = ({ children }: BetaProps) => {
  const { t } = useTranslation('app-beta');

  const [id] = useState<string>(_.uniqueId());
  const settings = useRecoilValue(userSettings);
  const settingsStatus = useRecoilValue(userSettingsLoadStatus);
  const updateUserSettings = useUpdateUserSettings();

  return (
    <BetaCard subtitle={t`Beta`} title={t`Contracts`}>
      <HelperMessage hasPadding className="flex-1">
        {/* <Icon.ArrowRightBottom className="w-24 h-24 p-2 text-white fill-current" /> */}
        <h4 className="mt-8 mb-2 text-xl text-menu-active">{`Why?`}</h4>
        <p>
          {t`Warehousing service providers (3PLs) under-bill 4%-5% of the revenue.`}
        </p>
        <h4 className="mt-8 mb-2 text-xl text-menu-active">{t`How?`}</h4>
        <p>
          {t`Billing Automation generates accurate invoices or will you help to
          audit existing billing systems, as well.`}
        </p>
        <h4 className="mt-8 mb-2 text-xl text-menu-active">{t`What do I get?`}</h4>
        <p>
          {t`Minimising under-billing, activity based costing and billing as a
          service.`}
        </p>
      </HelperMessage>
      <Button
        className="p-1 mt-8 mb-4 rounded-full"
        label={
          settings?.featureRequests?.contracts
            ? t`Unsubscribe`
            : t`Join the Waitlist`
        }
        buttonType={settings?.featureRequests?.contracts ? '' : 'primary'}
        isDisabled={settingsStatus === AsyncLoadStatus.Loading}
        isLoading={settingsStatus === AsyncLoadStatus.Loading}
        full
        hasIconAfter={settings?.featureRequests?.contracts}
        hasIconBefore={!settings?.featureRequests?.contracts}
        buttonIcon={
          settings?.featureRequests?.contracts ? (
            <Icon.CircleOk className={`w-7 h-7 fill-current`} />
          ) : (
            <Icon.CircleArrowRight className={`w-8 h-8 fill-current`} />
          )
        }
        onPress={() => {
          updateUserSettings({
            featureRequests: {
              contracts: !settings?.featureRequests?.contracts,
            },
          });
        }}
      />
    </BetaCard>
  );
};
