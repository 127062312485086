import { AriaCheckboxProps } from '@react-types/checkbox';
import classNames from 'classnames';
import React from 'react';
import { useCheckbox, useId } from 'react-aria';
import { useToggleState } from 'react-stately';
import { analyticsAgent } from '../../AnalyticTracker';
import useSharedRef from '../../utils/use-shared-ref';
import { ButtonSize } from '../_ComponentsThemeStyles';
import Loader from '../loaders/Loader';

export type ButtonToolbarProps = {
  className?: string;
  classNameButtonToolbar?: string;
  title?: string;
  label?: string;
  // hasTitle?: boolean;
  //transparent?: boolean;
  disabled?: boolean;
  isBoxed?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  buttonSize?: keyof typeof ButtonSize | string;
  titleTrace?: string;
} & AriaCheckboxProps;

const ButtonToolbar = React.forwardRef<HTMLInputElement, ButtonToolbarProps>(
  (
    {
      children,
      className,
      classNameButtonToolbar,
      title,
      label,
      // hasTitle,
      //transparent,
      disabled,
      isBoxed,
      isFullWidth,
      isLoading,
      buttonSize,
      titleTrace,
      ...props
    }: ButtonToolbarProps,
    ref,
  ) => {
    const id = useId();
    const labelId = `toolbar-but-label-${id}`;
    const sharedRef = useSharedRef(ref);
    const state = useToggleState(props);
    const { inputProps } = useCheckbox(
      { ...props, 'aria-labelledby': labelId },
      state,
      sharedRef,
    );

    const buttonBackground =
      props.isSelected || isLoading
        ? `${
            disabled
              ? ''
              : 'bg-menu-active hover:bg-menu-active/80 hover:text-menu-active-text shadow-md'
          } text-menu-active-text`
        : `${
            disabled
              ? 'bg-menu-button/50 text-menu-text'
              : 'bg-menu-button/75 hover:bg-menu-active hover:text-menu-active-text text-menu-active-text shadow'
          } `;

    return (
      <div
        data-component="ButtonToolbar"
        data-label={`button-toolbar-${title}`}
        aria-label={`button-toolbar-${label ? label : title}`}
        id={id}
        className={classNames(
          '',
          title
            ? 'flex items-center justify-center flex-1 w-full'
            : isBoxed
              ? 'flex items-center justify-center'
              : 'flex-1 w-full',
          classNameButtonToolbar,
        )}
      >
        <label
          id={labelId}
          className={classNames('flex cursor-pointer', {
            'w-full': isFullWidth,
          })}
          title={label}
          aria-label={label}
        >
          <input
            type="checkbox"
            className={`hidden`}
            {...inputProps}
            onClick={e => {
              inputProps.onClick && inputProps.onClick(e);
              try {
                analyticsAgent?.track(`Button Toolbar ${title ?? titleTrace}`);
              } catch (ex) {
                console.debug('analyticsAgent Error:', ex);
              }
              e.stopPropagation();
            }}
          />
          <div
            data-component="ButtonToggle"
            className={classNames(
              'btn-toggle',
              'relative p-0.5 lg:p-1 rounded',
              'flex flex-1 items-center justify-center',
              title ? 'ml-0 ltr:mr-2 rtl:ml-2 my-2' : 'm-1',
              { 'w-9 h-9 lg:w-10 lg:h-10': isBoxed },
              buttonBackground,
              buttonSize,
              className,
            )}
            aria-hidden="true"
          >
            {isLoading && (
              <Loader
                size={30}
                stroke={7}
                type={'loading-original'}
                className={classNames('absolute', 'h-auto w-9 lg:w-10 xl:w-12')}
              />
            )}

            {children}
          </div>
        </label>
        {title && (
          <div
            className={classNames('uppercase text-xxs lg:text-xs flex-1', {
              'opacity-20': isLoading,
            })}
          >
            {title}
          </div>
        )}
      </div>
    );
  },
);

export default ButtonToolbar;
