import { OptimizationObjectiveType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import StatsCompareSummaryPanel from '../../components/stats/StatsCompareSummaryPanel';
import { loadBalancingPolicyDocument } from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import { getAnalyzeTotals, getCarbonValue } from '../store/analyze.helper';
import {
  optimisationAnalyzeResult,
  optimisationResult,
} from '../store/optimisation.state';
import { simulationAnalyzeResult } from '../store/simulation.state';
import OptimisationEventsComparePanel from './OptimisationEventsComparePanel';

const SimulationOptimiseSummaryPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const optimisation = useRecoilValue(optimisationResult);
  const balancingPolicy = useRecoilValue(loadBalancingPolicyDocument);

  const analyzeBefore = useRecoilValue(simulationAnalyzeResult);
  const analyzeAfter = useRecoilValue(optimisationAnalyzeResult);

  if (!analyzeBefore || !analyzeAfter) return null;

  const orderCountAssembledBefore =
    analyzeBefore.totalAssembledOrders +
    analyzeBefore.totalAssembledPartiallyOrders;
  const orderountAssembledAfter =
    analyzeAfter.totalAssembledOrders +
    analyzeAfter.totalAssembledPartiallyOrders;
  const totalsBefore = getAnalyzeTotals(analyzeBefore);
  const totalsAfter = getAnalyzeTotals(analyzeAfter);

  // Distance
  const distanceBefore = formatter.formatDistance(totalsBefore.distance);
  const distanceAfter = formatter.formatDistance(totalsAfter.distance);

  // const distanceDelta = formatter.formatDistance(
  //   Math.abs(analyzeBefore.totalDistance - analyzeAfter.totalDistance),
  // );
  const distanceDelta = formatInteger(distanceBefore.raw - distanceAfter.raw);
  const distanceShareNumeric = 1 - distanceAfter.raw / distanceBefore.raw;
  const distanceShare = formatter.formatShare(distanceShareNumeric);

  console.log('analyzeAfter.totalDistance', analyzeAfter.totalDistance);
  console.log('analyzeBefore.totalDistance', analyzeBefore.totalDistance);
  console.log('distanceShareNumeric', distanceShareNumeric);
  console.log('distanceShare', distanceShare);

  const durationBefore = formatter.formatTimeSpan(totalsBefore.duration, 1);
  const durationAfter = formatter.formatTimeSpan(totalsAfter.duration, 1);
  const durationDelta = formatInteger(durationBefore.raw - durationAfter.raw);
  const durationShareNumeric = 1 - totalsAfter.duration / totalsBefore.duration;
  const durationShare = formatter.formatShare(durationShareNumeric);
  // cost
  const costBefore = formatter.formatCurrency(totalsBefore.cost);
  const costAfter = formatter.formatCurrency(totalsAfter.cost);
  const costDelta = formatter.formatCurrency(costBefore.raw - costAfter.raw);
  const costShareNumeric = 1 - totalsAfter.cost / totalsBefore.cost;
  const costShare = formatter.formatShare(costShareNumeric);

  const orderCountFormatted = formatInteger(orderCountAssembledBefore);

  const carbonBeforeValue = getCarbonValue(analyzeBefore);
  const carbonAfterValue = getCarbonValue(analyzeAfter);

  const carbonBefore = formatter.formatCO2(carbonBeforeValue);
  const carbonAfter = formatter.formatCO2(carbonAfterValue);

  const carbonDelta = formatter.formatCO2(carbonBefore.raw - carbonAfter.raw);
  const carbonShareNumeric = 1 - carbonAfter.raw / carbonBefore.raw;
  const carbonShare = formatter.formatShare(carbonShareNumeric);

  const detailedStats =
    optimisation?.optimizationSummary?.improvement?.objectives;

  const balancingPolicyRules = _.keyBy(balancingPolicy?.rules, r => r.id);

  const objectivesTitles: Record<OptimizationObjectiveType, string> = {
    COST: t`Predicted Cost Improvement`,
    LOAD_BALANCING: t`Est. Balancing`,
  };

  function getTitle(
    type: OptimizationObjectiveType,
    ruleId: string,
    weight: number,
  ) {
    if (_.isNil(ruleId)) {
      return objectivesTitles[type];
    }
    return t('{{objType}} - {{ ruleTitle}}', {
      objType: objectivesTitles[type],
      ruleTitle: balancingPolicyRules[ruleId]?.title,
      ruleWeight: formatter.formatShare(weight).fullString,
    });
  }

  const loadBalancingStats = _.filter(
    optimisation.optimizationSummary?.improvement?.objectives,
    target => target.type === OptimizationObjectiveType.LOAD_BALANCING,
  );

  const hasDetailedStats = !_.isEmpty(detailedStats);

  return (
    <>
      <StatGroup
        title={t`Optimisation`}
        subTitle={t`Summary`}
        helpNavTo={'simulation/optimise/simulation-optimise-results'}
      >
        <Stat
          title={t('Total Optimised Orders')}
          value={orderCountFormatted}
          hasHelper
          inPanelMode
          isFullWidth
          isPreview
        />

        {/* Render COST objectives */}
        <Stat
          title={t`Predicted Cost Improvement`}
          titleAlt={t`Predicted Cost Improvement`}
          value={costShare.value}
          unitOfMeasure={'%'}
          hasHelper
          inPanelMode
          isFullWidth
          isPreview
        />

        {loadBalancingStats.length > 0 && (
          <StatGroup title={t`Workload Balancing`}>
            <div
              className={`flex ${
                loadBalancingStats.length > 3
                  ? 'flex-col space-y-1'
                  : 'flex-col space-y-1 xl:flex-row xl:space-x-1 xl:space-y-0'
              }`}
            >
              {_.map(loadBalancingStats, target => (
                <Stat
                  key={`loadBalancingStats-${target.type}-${target.id}`}
                  classTitle="text-xxs"
                  title={getTitle(
                    target.type,
                    target.id,
                    target.objectiveWeight,
                  )}
                  titleAlt={getTitle(
                    target.type,
                    target.id,
                    target.objectiveWeight,
                  )}
                  value={target.improvement * 100}
                  hasHelper
                  isPrimaryResult
                  unitOfMeasure={'%'}
                  inPanelMode
                  isFullWidth
                  isPreview
                />
              ))}
            </div>
          </StatGroup>
        )}

        <OptimisationEventsComparePanel
          title={t`By event type`}
          eventsBefore={analyzeBefore.summary?.eventTypes}
          eventsAfter={analyzeAfter.summary?.eventTypes}
          energyRate={
            analyzeBefore?.resourcePolicy?.agents?.[0]?.specs?.powerSource
              ?.energyConsumptionPerSecond ?? 0
          }
          showKpiSwitch
          showLegendStats
        />
        {/* <Stat
          // unitOfMeasure={`%`}
          hasHelper
          isPreview
          title={t`Picking Agent Utilisation`}
          inPanelMode
          // value={agentPickerUtilizationBefore.toFixed(0)}
        >
          <div className={`ChartContainer flex mb-4`}>
            <div className={`flex flex-col flex-1 p-2`}>
              <label
                className={`pt-1 text-xs mb-4 text-menu-100`}
              >{t`Before`}</label>
              <div className="relative flex flex-1">
                <SimulationUtilityChart
                  settings={agent}
                  agentUtilizationHours={agentPickerUtilizationBeforeHours}
                />
                <div className="absolute flex items-center justify-center flex-1 w-full h-1/2 top-1">
                  <Icon.PickingAgentRunning
                    className={`fill-current w-6 text-menu-active`}
                  />
                </div>
              </div>
            </div>
            <div className="m-4 mt-0 mb-0 ltr:border-r rtl:border-l border-menu-400"></div>
            <div className={`flex flex-col flex-1 p-2`}>
              <label
                className={`pt-1 text-xs mb-4 text-menu-100`}
              >{`After (Optimised)`}</label>
              <div className="relative flex flex-1">
                <SimulationUtilityChart
                  settings={agent}
                  agentUtilizationHours={agentPickerUtilizationAfterHours}
                />
                <div className="absolute flex items-center justify-center flex-1 w-full h-1/2 top-1">
                  <Icon.PickingAgentRunning
                    className={`fill-current w-6 text-menu-active`}
                  />
                </div>
              </div>
            </div>
          </div>

          <StatListItem
            title={t`Picking Utilisation (Before)`}
            unitOfMeasure={`h`}
            value={
              agentPickerUtilizationBeforeHours.toFixed(2) +
              ` out of ` +
              agent.secondsOperation
            }
          />
          <StatListItem
            title={t`Picking Utilisation (After)`}
            unitOfMeasure={`h`}
            value={
              agentPickerUtilizationAfterHours.toFixed(2) +
              ` out of ` +
              agent.secondsOperation
            }
          />

          <HelperMessage
            isSuggestion
            className={`mt-4 border border-menu-active p-3 rounded`}
          >
            {t(
              'Same amount of work can now be done with {{agentPickerUtilizationAfterAmount }} agents instead of {{agentPickerUtilizationBeforeAmount}}',
              {
                agentPickerUtilizationBeforeAmount: agent.agentAmount,
                agentPickerUtilizationAfterAmount: (
                  (agent.agentAmount * timeTotalAfterHours) /
                  timeTotalBeforeHours
                ).toFixed(1),
              },
            )}

            // {t`Same work can now be done with {2.2} Employees instead of {3}`}
          </HelperMessage>
        </Stat> */}
      </StatGroup>
      <StatsCompareSummaryPanel
        analyzeBefore={analyzeBefore}
        analyzeAfter={analyzeAfter}
      />
    
    </>
  );
};

export default SimulationOptimiseSummaryPanel;
