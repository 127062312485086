import * as React from 'react';
const LocationsOccupiedAllIcon = props => (
  <svg
    aria-hidden="true"
    data-name="LocationsOccupiedAllIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={25.27}
      height={122.66}
      x={50.79}
      y={2.73}
      opacity={0.3}
      transform="translate(126.85 128.11) rotate(180)"
    />
    <path d="m78.03,31.63h47.8s0-28.92,0-28.92h-47.8s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m78.03,94.15h47.8s0-28.92,0-28.92h-47.8s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,31.63h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92ZM31.07,8.95l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,62.89h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <path d="m1.04,125.41h47.8s0-28.92,0-28.92H1.04s0,28.92,0,28.92Zm30.03-22.68l1.29,1.43-6.13,6.79,6.13,6.79-1.29,1.43-6.13-6.79-6.13,6.79-1.29-1.43,6.13-6.79-6.13-6.79,1.29-1.43,6.13,6.79,6.13-6.79Z" />
    <g>
      <path d="m48.84,65.23H1.04v28.92s47.8,0,47.8,0v-28.92h0ZM3.04,92.15v-24.92s43.8,0,43.8,0v24.92H3.04Z" />
      <polygon points="31.07 71.47 24.94 78.26 18.82 71.47 17.53 72.9 23.65 79.69 17.53 86.49 18.82 87.92 24.94 81.12 31.07 87.92 32.36 86.49 26.23 79.69 32.36 72.9 31.07 71.47 31.07 71.47" />
    </g>
    <g>
      <path d="m125.83,96.49h-47.8v28.92s47.8,0,47.8,0v-28.92h0Zm-45.8,26.92v-24.92s43.8,0,43.8,0v24.92h-43.8Z" />
      <polygon points="108.06 102.73 101.93 109.52 95.81 102.73 94.52 104.16 100.64 110.95 94.52 117.75 95.81 119.18 101.93 112.38 108.06 119.18 109.35 117.75 103.22 110.95 109.35 104.16 108.06 102.73 108.06 102.73" />
    </g>
    <g>
      <path d="m125.83,33.94h-47.8v28.92s47.8,0,47.8,0v-28.92h0Zm-45.8,26.92v-24.92s43.8,0,43.8,0v24.92h-43.8Z" />
      <polygon points="108.06 40.18 101.93 46.97 95.81 40.18 94.52 41.61 100.64 48.4 94.52 55.19 95.81 56.62 101.93 49.83 108.06 56.62 109.35 55.19 103.22 48.4 109.35 41.61 108.06 40.18 108.06 40.18" />
    </g>
  </svg>
);
export default LocationsOccupiedAllIcon;
