import {
  AnalyzeResultStatus,
  OptimizationRunStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import CCTVLayer from '../cctv/CCTVLayer';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { layoutDocumentLoadStatus } from '../layout/store/layout.state';
import ActiveFeatureLayer from '../layout/viewer/layers/ActiveFeatureLayer';
import HoveredFeatureLayer from '../layout/viewer/layers/HoveredFeatureLayer';
import PortalLayer from '../layout/viewer/layers/PortalLayer';
import SimulationLayoutContainer from './SimulationLayoutContainer';
import SimulationLayoutPlaceholder from './SimulationLayoutPlaceholder';
import AnimatedLocationLayer from './layers/AnimatedLocationLayer';
import AssignmentStatsLocationLayer from './layers/AssignmentStatsLocationLayer';
import HtmlTooltipLayer from './layers/HtmlTooltipLayer';
import SimulationAisleLayer from './layers/SimulationAisleLayer';
import SimulationBayLayer from './layers/SimulationBayLayer';
import SimulationLocationLayer from './layers/SimulationLocationLayer';
import SimulationNavigationLayer from './layers/SimulationNavigationLayer';
import SimulationPolicyLocationLayer from './layers/SimulationPolicyLocationLayer';
import SimulationRouteLayer, {
  SimulationRoutePartLayer,
} from './layers/SimulationRouteLayer';
import SimulationRoutingStepLocationLayer from './layers/SimulationRoutingStepLocationLayer';
import SimulationCongestionLayer from './layers/congestion/SimulationCongestionLayer';
import { allocationAnalyzeId } from './store/allocation/allocation.state';
import {
  optimisationAnalyzeId,
  optimisationStatus,
} from './store/optimisation.state';
import {
  simulationAnalyzeId,
  simulationAnalyzeStatus,
  simulationCurrentId,
  simulationLayoutId,
} from './store/simulation.state';
import { SimulationMenuWizardStepId } from './store/simulation.wizard';
import LoadBalancingPolicyToolbar from './toolbars/LoadBalancingPolicyToolbar';
import SimulationLayoutAlerts from './toolbars/SimulationLayoutLoadingAlerts';

export type SimulationLayoutViewProps = {
  stepId: SimulationMenuWizardStepId;
  hideViewModeToolbar?: boolean;
};

const LocationLayer = ({ stepId, analyzeStatus, optimizeStatus }) => {
  const analyseReady = analyzeStatus === AnalyzeResultStatus.COMPLETED;
  const analyseInProgress = analyzeStatus === AnalyzeResultStatus.ANALYZING;
  const optimiseInProgress =
    optimizeStatus === OptimizationRunStatus.IN_PROGRESS;

  switch (stepId as SimulationMenuWizardStepId) {
    case 'policy-routing':
      return <SimulationRoutingStepLocationLayer />;

    case 'policy-picking':
    case 'policy-storage':
    case 'policy-stacking':
      return <SimulationPolicyLocationLayer />;

    case 'analyse':
      if (analyseInProgress) return <AnimatedLocationLayer />;
      if (analyseReady) return <SimulationLocationLayer />;

      return <SimulationPolicyLocationLayer />;

    case 'optimise':
      return optimiseInProgress ? (
        <AnimatedLocationLayer />
      ) : (
        <SimulationLocationLayer />
      );

    case 'data-stats':
      return <AssignmentStatsLocationLayer />;
    default:
      return <SimulationLocationLayer />;
  }
};

const SimulationLayoutView: React.FC<SimulationLayoutViewProps> = props => {
  const { t } = useTranslation('simulation');
  const simId = useRecoilValue(simulationCurrentId);
  const layoutId = useRecoilValue(simulationLayoutId);
  const loadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const analyzeStatus = useRecoilValue(simulationAnalyzeStatus);
  const optimizeStatus = useRecoilValue(optimisationStatus);
  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const allocationAnlId = useRecoilValue(allocationAnalyzeId);
  const optimisationAnlId = useRecoilValue(optimisationAnalyzeId);

  if (_.isNil(simId)) return null;
  if (!_.isNil(simId) && !layoutId) {
    return <SimulationLayoutPlaceholder />;
  }

  if (loadStatus === AsyncLoadStatus.Loading /*|| isDelayed*/) {
    return (
      <LoadingIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Loading Layout...`}
      />
    );
  }
  if (loadStatus === AsyncLoadStatus.Error) {
    return (
      <ErrorIndicator
        className="bg-map-area-striped"
        selfCenter
        message={t`Cannot load layout`}
      />
    );
  }

  return (
    <SimulationLayoutContainer
      hideViewModeToolbar={props.hideViewModeToolbar}
      getAbsoluteContent={() => (
        <>
          <HtmlTooltipLayer analyzeId={analyzeId} />
          <SimulationLayoutAlerts />
          <LoadBalancingPolicyToolbar />
        </>
      )}
    >
      <SimulationAisleLayer />
      <PortalLayer />
      <SimulationCongestionLayer />
      <SimulationBayLayer />
      <LocationLayer
        stepId={props.stepId}
        analyzeStatus={analyzeStatus}
        optimizeStatus={optimizeStatus}
      />
      <ActiveFeatureLayer />
      <HoveredFeatureLayer />
      <SimulationNavigationLayer />
      <SimulationRouteLayer />
      <SimulationRoutePartLayer />
      <CCTVLayer />
    </SimulationLayoutContainer>
  );
};

export default SimulationLayoutView;
