import {
  AssignmentItemsExtendedFilter,
  AssignmentLocationItemStatFragment,
  ItemFilterIntersectionFragment,
  ItemFilterType,
  LoadAssignmentStatsDocument,
  LoadAssignmentStatsQuery,
  LoadAssignmentStatsQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { HeatmapMetricGroup } from '../common/heatmap.types';
import { FilterFieldDataType, FilterFieldEditorType } from '../common/types';
import { secureClient } from '../GraphQLClient';
import { ItemSetFieldConfig } from '../itemSet/store/itemSet.types';
import { getPolicyMatchInput } from '../policyFilters/policyFilter.helper';
import {
  AssignmentLocationItemStat,
  AssignmentStatItemMetric,
} from './assignmentStats.type';

export async function loadAssignmentStats(params: {
  layoutId: string;
  assignmentId: string;
  itemSetId: string;
  filter: AssignmentItemsExtendedFilter;
  itemFilter: ItemFilterIntersectionFragment;
}): Promise<Record<string, AssignmentLocationItemStat[]>> {
  const itemFilter = getPolicyMatchInput({ anyOf: [params.itemFilter] });
  const response = await secureClient.query<
    LoadAssignmentStatsQuery,
    LoadAssignmentStatsQueryVariables
  >({
    query: LoadAssignmentStatsDocument,
    variables: {
      assignmentId: params.assignmentId,
      itemSetId: params.itemSetId,
      layoutId: params.layoutId,
      filter: params.filter,
      itemFilter,
      matchingOnly: !_.isNil(itemFilter),
    },
  });

  const byLocationId = _.reduce(
    response.data?.applyAssignmentItemFilter?.content,
    (acc, locationData) => ({
      ...acc,
      [locationData.locationId]: [
        ...(acc[locationData.locationId] ?? []),
        getAssignmentLocationItemStat(locationData),
      ],
    }),
    {} as Record<string, AssignmentLocationItemStat[]>,
  );

  return byLocationId;
}

export function getAssignmentLocationItemStat(
  locationItem: AssignmentLocationItemStatFragment,
): AssignmentLocationItemStat {
  // capacity
  const baseUom = locationItem?.item?.baseUom;
  const baseUOMVolume =
    (baseUom?.volume ?? 0) > 0
      ? baseUom?.volume
      : (baseUom?.width ?? 0) * (baseUom?.height ?? 0) * (baseUom?.length ?? 0);

  const locationVolume =
    (locationItem?.location?.locationWidth ?? 0) *
    (locationItem?.location?.locationHeight ?? 0) *
    (locationItem?.location?.locationLength ?? 0);

  const baseUOMCapacity = baseUOMVolume
    ? Math.floor(locationVolume / baseUOMVolume)
    : 0;

  // density
  const baseUOMDensity =
    baseUOMVolume > 0 && baseUom.netWeight > 0
      ? (baseUom.netWeight * 100 * 100 * 100) / baseUOMVolume
      : null;

  // Proportionality
  const { length = 0, width = 0, height = 0 } = baseUom ?? {};
  const maxSize = Math.max(length, width, height);
  const minSize = Math.min(length, width, height);
  const baseUOMProportionality = minSize > 0 ? maxSize / minSize : 0;
  return {
    ...locationItem,
    baseUOMCapacity,
    baseUOMDensity,
    baseUOMProportionality,
  };
}

export const getAssignmentStatsItemFilterConfig: (
  t: TFunction<'dataset'>,
) => ItemSetFieldConfig[] = t => [
  {
    type: ItemFilterType.CONSIGNEE,
    title: t(`Client`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SKU,
    title: t(`Item`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SKU_GROUP,
    title: t(`Item Group`, { ns: 'dataset' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.SUB_GROUP,
    title: t(`Item Sub Group`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.TRANSPORT_CLASS,
    title: t(`Transport Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.STOCK_CATEGORY,
    title: t(`Stock Category`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.STORAGE_CLASS,
    title: t(`Storage Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.POLLUTION_CLASS,
    title: t(`Pollution Class`, { ns: 'simulation' }),
    editorType: FilterFieldEditorType.MultipleCheck,
  },
  {
    type: ItemFilterType.NET_WEIGHT,
    title: t('Net Weight', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.LENGTH,
    title: t('Length', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.WIDTH,
    title: t('Width', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.HEIGHT,
    title: t('Height', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
  {
    type: ItemFilterType.VOLUME,
    title: t('Volume', { ns: 'dataset' }),
    editorType: FilterFieldEditorType.SliderRange,
    dataType: FilterFieldDataType.NumberRange,
  },
];

export function getAssignmentStatsMetricGroups(
  t: TFunction<'simulation'>,
): HeatmapMetricGroup<AssignmentStatItemMetric>[] {
  const groups: HeatmapMetricGroup<AssignmentStatItemMetric>[] = [
    {
      id: 'item',
      title: t('Item', { ns: 'dataset' }),
      metrics: [
        'consignee',
        'skuGroup',
        'subGroup',
        'pollutionClass',
        'transportClass',
        'stockCategory',
        'storageClass',
      ],
    },
    {
      id: 'uom',
      title: t('Base UOM', { ns: 'dataset' }),
      metrics: [
        'baseUOM',
        'stockUom',
        'baseUOMWeight',
        'baseUOMWeight-pow',
        'baseUOMVolume',
        'baseUOMVolume-pow',
        'baseUOMCapacity',
        'baseUOMDensity',
        'baseUOMProportionality',
      ],
    },
    {
      id: 'location',
      title: t('Location', { ns: 'dataset' }),
      metrics: [
        'locationRackingType',
        'locationUsageType',
        'locationOrder',
        'congestionZone',
      ],
    },
  ];
  return groups;
}
