import * as React from 'react';
const PolicyComplianceFireIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyComplianceFireIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,2L8.94,26.46v44.42c0,12.02,8.3,26.18,19.31,32.96h0s35.74,22.17,35.74,22.17l35.74-22.17h0c11.01-6.77,19.31-20.94,19.31-32.96V26.46L64,2ZM109.37,70.87c0,8.92-6.55,20.24-14.67,25.48l-30.7,19.04-30.7-19.05c-8.12-5.24-14.67-16.55-14.67-25.47V31.9l45.37-20.15,45.37,20.15v38.98Z"
    />
    <path
      strokeWidth={0}
      d="M72.5,34.1c1.48,5.4,3.92,8.01,6.62,15.33,2.7,7.49,2.26,11.58,2.26,11.58,0,0,.78-4.62,2.7-7.75,4.18-6.88,9.84-8.53,9.84-8.53,0,0-2.96,6.27-2.26,14.72.52,6.18,1.39,13.32-1.83,21.33-2.53,6.36-9.84,15.06-20.11,17.42,5.92-2.61,9.84-8.71,9.4-15.33-.26-3.83-2.53-7.49-4.96-10.01-1.48,9.67-6.79,10.8-6.79,10.8,0,0,3.05-6.36,3.48-13.41.26-2.79.09-5.75-.78-8.71-2.79-10.01-7.58-12.71-7.58-12.71,0,0,1.57,5.83-4.18,15.24-4.01,6.53-10.8,11.32-10.54,21.07.17,5.22,3.22,9.66,7.49,12.19t0,0h0s0,0,0,0c-9.14-3.14-16.72-10.8-20.64-22.47-5.83-16.63,6.18-29.87,6.18-29.87,0,0-.35,3.4,1.13,7.14,1.48,3.74,4.35,6.44,4.35,6.44,0,0-2.79-7.84,4.01-20.9,7.66-14.72,24.56-17.85,24.56-17.85,0,0-4.27,7.05-2.35,14.28Z"
    />
  </svg>
);
export default PolicyComplianceFireIcon;
