import * as React from 'react';
const PolicyWaypointIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyWaypointIcon"
    {...props}
  >
    <ellipse cx={63.7} cy={85.11} strokeWidth={0} rx={8.08} ry={4.58} />
    <path
      strokeWidth={0}
      d="M82.74,43.17c0-10.44-8.46-18.91-18.91-18.91-10.44,0-18.91,8.46-18.91,18.91,0,18.91,18.91,39.56,18.91,39.56,0,0,18.91-20.65,18.91-39.56ZM63.84,50.74c-4.18,0-7.56-3.39-7.56-7.56,0-4.18,3.39-7.56,7.56-7.56,4.18,0,7.56,3.39,7.56,7.56s-3.39,7.56-7.56,7.56Z"
    />
    <path
      strokeWidth={0}
      d="M28.34,85.16c0,6.74,7.01,12.62,17.46,15.82l-17.33-17.33c-.08.5-.13,1-.13,1.51Z"
    />
    <path
      strokeWidth={0}
      d="M99.34,85.16c0-.41-.03-.82-.09-1.23l-16.91,16.91c10.19-3.23,16.99-9.05,16.99-15.68Z"
    />
    <path
      strokeWidth={0}
      d="M60.83,116.01l-8-8c-6.01-.78-11.55-2.17-16.35-4.05l18.21,18.21c5.11,5.11,13.54,5.11,18.65,0l18.43-18.43c-4.83,1.95-10.44,3.41-16.54,4.23l-8.05,8.05"
    />
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l12.54,12.54c-.01-.24-.03-.47-.03-.71,0-3.01,1.1-5.89,3.1-8.54l-9.44-9.44c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.06-.16.16-.31.28-.43L60.82,11.99c.88-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34l-9.63,9.63c1.91,2.6,2.96,5.41,2.96,8.35,0,.35-.02.7-.05,1.05l12.88-12.88c5.11-5.11,5.11-13.54,0-18.65Z"
    />
    <path
      strokeWidth={0}
      d="M45.79,100.98l7.03,7.03c3.53.46,7.22.7,11.01.7s7.74-.26,11.38-.75l7.13-7.13c-5.39,1.71-11.73,2.69-18.51,2.69s-12.75-.93-18.04-2.55Z"
    />
    <path
      strokeWidth={0}
      d="M48.67,68.54c-.85-1.66-1.66-3.36-2.41-5.11-11.34,2.47-20.34,7.24-24.82,13.18l7.03,7.03c1.05-6.69,9.02-12.36,20.2-15.11Z"
    />
    <path
      strokeWidth={0}
      d="M81.41,63.43c-.75,1.75-1.56,3.45-2.41,5.11,11.34,2.78,19.38,8.58,20.24,15.39l7.13-7.13c-4.43-6.03-13.51-10.88-24.96-13.37Z"
    />
    <path
      strokeWidth={0}
      d="M35.74,85.16c0,8.02,12.6,14.55,28.1,14.55s28.1-6.52,28.1-14.55c0-5.57-6.08-10.41-14.97-12.86-1.02,1.78-2.06,3.47-3.1,5.06,4.86,1.68,8.07,4.54,8.07,7.8,0,5.18-8.1,9.37-18.1,9.37s-18.1-4.19-18.1-9.37c0-3.25,3.2-6.12,8.07-7.8-1.03-1.59-2.08-3.28-3.1-5.06-8.9,2.44-14.97,7.29-14.97,12.86Z"
    />
    <path
      strokeWidth={0}
      d="M55.64,85.16c0,2.15,3.09,3.92,7.1,4.2-1.15-1.34-3.09-3.71-5.36-6.81-1.09.72-1.75,1.63-1.75,2.61Z"
    />
    <path
      strokeWidth={0}
      d="M72.04,85.16c0-.99-.66-1.89-1.75-2.61-2.26,3.11-4.21,5.47-5.36,6.81,4.01-.28,7.1-2.05,7.1-4.2Z"
    />
  </svg>
);
export default PolicyWaypointIcon;
