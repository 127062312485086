import {
  AppBillingProductCategory,
  AppBillingProductFragment,
  LoadAppBillingProductsDocument,
  LoadAppBillingProductsQuery,
  LoadAppBillingProductsQueryVariables,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { appBillingPublicClient } from './GraphQLClient';
import AppBillingPricingCards, {
  AppBillingPricingCardsCategoryMode,
  AppBillingPricingCardsLinkMode,
  AppBillingPricingCardsPriceMode,
  AppBillingPricingCardsViewMode,
} from './appBilling/AppBillingPricingCards';
import LoadingIndicator from './components/LoadingIndicator';
import { ContainerCol } from './components/layout/ContainerFlex';

export type PricingProps = PropsWithChildren & {
  children?: ReactElement;
  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;
};

export const Pricing: React.FC<PricingProps> = ({
  children,
  modePreview,
  modeCategory,
  modePrice,
  modeLink,
}) => {
  // export default function Pricing() {
  const { t } = useTranslation('app');

  const [products, setProducts] = useState<AppBillingProductFragment[]>();

  const [category, setCategory] = useState<AppBillingProductCategory>(
    modeCategory === 'SUBSCRIPTION' || modeCategory === 'ALL'
      ? AppBillingProductCategory.SUBSCRIPTION
      : AppBillingProductCategory.BUNDLE,
  );

  const [currency, setCurrency] = useState('USD');
  const [whSize, setWhSize] = useState<WarehouseSize>(WarehouseSize.MEDIUM);

  async function loadProducts() {
    const response = await appBillingPublicClient.query<
      LoadAppBillingProductsQuery,
      LoadAppBillingProductsQueryVariables
    >({
      query: LoadAppBillingProductsDocument,
    });

    setProducts(response.data.appBillingProducts);
  }

  useEffect(() => {
    loadProducts();
  });

  if (!products?.length) {
    return (
      <ContainerCol overflow className={`items-center justify-center`}>
        <LoadingIndicator selfCenter message={t`Loading Pricing info...`} />
      </ContainerCol>
    );
  }

  return (
    <AppBillingPricingCards
      modePreview={modePreview}
      modeCategory={modeCategory}
      modePrice={modePrice}
      modeLink={modeLink}
      products={products}
      currency={currency}
      onCurrencyChange={setCurrency}
      category={category}
      onCategoryChange={setCategory}
      whSize={whSize}
      onWhSizeChange={setWhSize}
    />
  );
};

export default Pricing;
