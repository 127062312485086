import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import DropdownSelector from '../components/actions/DropdownSelector';
import {
  simulationAvailableAssignments,
  simulationEffectiveAssignmentId,
} from './store/simulation.state';

const SimulationAssignmentSelect: React.FC = () => {
  const availableAssignment = useRecoilValue(simulationAvailableAssignments);
  const [effectiveAssignmentId, setEffectiveAssignmentId] = useRecoilState(
    simulationEffectiveAssignmentId,
  );

  return (
    <DropdownSelector
      value={effectiveAssignmentId}
      values={_.keys(availableAssignment)}
      renderValue={v => availableAssignment[v]}
      onChange={v => setEffectiveAssignmentId(v)}
      buttonTransparent
      panelMode
      className="rounded-full px-2"
      classNameValue="text-xs"
    />
  );
};

export default SimulationAssignmentSelect;
