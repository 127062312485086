import { AssignmentPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { DataPageParams } from '../common/types';
import { getLocationFilterMatchings } from '../simulation/store/assignmentPolicy.helper';
import { AssignmentPolicySelectedIdentity } from '../simulation/store/assignmentPolicy.types';
import useLoadLocationsStatsByRule from './useLoadLocationsStatsByRule';

export type LocationsStatsByRuleParams = {
  selectedDetails: AssignmentPolicySelectedIdentity;
  policy: AssignmentPolicyFragment;
  page?: DataPageParams;
};

function useLoadAssignmentPolicyLocationsStatsByRule() {
  const [loadBase, cancel] = useLoadLocationsStatsByRule();

  const load = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LocationsStatsByRuleParams) => {
        const { policy, selectedDetails } = params;
        await loadBase(getLocationFilterMatchings(policy, selectedDetails));
      },
  );

  return [load, cancel] as const;
}
export default useLoadAssignmentPolicyLocationsStatsByRule;
