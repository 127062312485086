import * as React from 'react';
const AddressIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="AddressIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M64.51,2.31C30.15,2.31,2.28,30.17,2.28,64.54s27.86,61.77,62.23,61.77,61.77-27.87,61.77-61.77S98.42,2.31,64.51,2.31ZM92.86,62.93c-.9,2.8-2.23,5.45-3.93,7.91l-21.83,31.91c-1.05,1.54-3.16,1.94-4.7.89-.38-.26-.68-.57-.92-.93l-22.02-32.2c-1.66-2.31-2.92-4.79-3.78-7.4-.88-2.69-1.34-5.52-1.34-8.42,0-8.12,3.42-15.75,8.91-21.31,5.42-5.49,12.87-8.99,21.05-8.99s15.61,3.43,21.05,8.88h0c5.44,5.45,8.86,12.95,8.86,21.08,0,2.95-.47,5.82-1.36,8.58Z"
    />
    <path
      fillRule="evenodd"
      strokeWidth={0}
      d="M64.28,38c-8.91,0-16.13,7.22-16.13,16.13s7.22,16.13,16.13,16.13,16.13-7.22,16.13-16.13-7.22-16.13-16.13-16.13Z"
    />
  </svg>
);
export default AddressIcon;
