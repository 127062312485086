import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorIndicator from '../../../components/ErrorIndicator';
import InboxZero from '../../../components/InboxZero';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Tabs, { TabItem } from '../../../components/actions/Tabs';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import Callout from '../../../helpContext/Callout';
import {
  allocationRunSummary,
  allocationRunSummaryLoadStatus,
} from '../../store/allocation/allocation.state';
import { AllocateTabId } from '../../store/simulation.types';
import { simulationAllocateTabKey } from '../../store/simulation.wizard.state';
import AllocateReassignJobsTable from './AllocateReassignJobsTable';
import AllocationSummary from './AllocationSummary';

const AllocationResultPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const allocationJob = useRecoilValue(allocationRunSummary);
  const allocationJobLoadStatus = useRecoilValue(
    allocationRunSummaryLoadStatus,
  );
  
  const [selectedTab, setSelectedTab] = useRecoilState(
    simulationAllocateTabKey,
  );

  const tabItems: TabItem<AllocateTabId>[] = [
    {
      id: 'tab-allocate-summary',
      title: t`Allocation Summary`,
      content: <AllocationSummary />,
    },
    {
      id: 'tab-allocate-jobs',
      title: t`Allocate Tasks`,
      content: <AllocateReassignJobsTable />,
    },
  ];

  if (_.isNil(allocationJob)) {
    return (
      <>
        <ScreenTitle
          subtitle={t`Allocation`}
          title={t`Allocation Summary`}
          isSticky
        />
        <InboxZero selfCenter>{t`No items were allocated`}</InboxZero>
        {/* {allocationJobLoadStatus} */}
      </>
    );
  }

  const isCalculating =
    allocationJob?.status === BatchJobStatus.CREATED ||
    allocationJob?.status === BatchJobStatus.CALCULATING;

  const hasError =
    allocationJob?.status === BatchJobStatus.FAILED ||
    allocationJob?.status === BatchJobStatus.TERMINATED;

  const isReady = allocationJob?.status === BatchJobStatus.READY;

  if (isCalculating) {
    return <LoadingIndicator message={t`Calculating...`} selfCenter />;
  }

  if (hasError) {
    return (
      <>
        <ScreenTitle
          subtitle={t`Allocation`}
          title={t`Allocation Summary`}
          isSticky
        />

        <Callout panelMode type="suggestion">
          {t`Looks like there is an issue with analysing your warehouse, please fix the issue and try again.`}

          <ErrorIndicator
            className="mt-4"
            selfCenter
            message={t`Allocate cannot be completed!`}
          />
        </Callout>
      </>
    );
  }

  return (
    <Tabs
      componentName="AllocationResultPanel"
      className="mt-0.5"
      items={tabItems}
      selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
      onChange={index => setSelectedTab(tabItems[index].id)}
      fullHeight
      hasActionbar={false}
    />
  );
};

export default AllocationResultPanel;
