import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type CounterProps = {
  children?: React.ReactNode;
  minimal?: boolean;
  right?: boolean;
  left?: boolean;
  className?: string;
};

export const Counter = ({
  children,
  className,
  minimal,
  right,
  left,
}: CounterProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <span
      data-component="Counter"
      className={classNames(
        `absolute`,
        `rounded shadow`,
        {
          '-top-[5px] -right-[5px]': right,
        },
        {
          '-top-[5px] -left-[0px]': left,
        },
        minimal ? 'text-xxs p-px' : 'text-xs py-0.5 px-1',
        `text-menu-text`,
        className,
      )}
    >
      {children}
    </span>
  );
};
