import Konva from 'konva';
import React, { useState } from 'react';
import { Circle, Group, KonvaNodeEvents, Path } from 'react-konva';
import { TwTheme } from '../../../Tw';

const iconSize = 192;
const iconSvg =
  'M64,14a50,50,0,1,0,50,50A50.06,50.06,0,0,0,64,14ZM88,67.85H67.85V89a3.85,3.85,0,1,1-7.7,0V67.85H40a3.85,3.85,0,0,1,0-7.7H60.15V40.92a3.85,3.85,0,0,1,7.7,0V60.15H88a3.85,3.85,0,0,1,0,7.7Z';

const bayColors = TwTheme.extend.colors.bay;

export type BaySideActionFeatureProps = {
  x: number;
  y: number;
  maxIconWidth: number;
  onClick: () => void;
};

const BaySideActionFeature: React.FC<BaySideActionFeatureProps> = props => {
  const [hovered, setHovered] = useState(false);
  const { x, y } = props;

  const scaleFactorInternal = props.maxIconWidth / iconSize;

  const circleConfig: Konva.CircleConfig = {
    radius: iconSize / 3,
    fill: bayColors.actionBg,
  };

  const pathConfig: Konva.PathConfig = {
    offsetX: iconSize / 3,
    offsetY: iconSize / 3,
    data: iconSvg,
    fill: hovered ? bayColors.actionHovered : bayColors.action,
    stroke: bayColors.actionBg,
  };

  const groupConfig: Konva.NodeConfig = {
    x,
    y,
    scaleX: scaleFactorInternal,
    scaleY: scaleFactorInternal,
  };

  const eventConfig: KonvaNodeEvents = {
    onTap: props.onClick,
    onClick: props.onClick,
    onMouseEnter: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
      setHovered(true);
    },

    onMouseLeave: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';
      setHovered(false);
    },
  };

  return (
    <Group {...groupConfig} {...eventConfig}>
      <Circle {...circleConfig} />
      <Path {...pathConfig} />
    </Group>
  );
};

export default React.memo(BaySideActionFeature);
