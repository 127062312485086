import { useTranslation } from 'react-i18next';
import {
  getLanguageTitle,
  SupportedLanguage,
  supportedLanguages,
} from '../utils/language.helper';
import DropdownSelector from './actions/DropdownSelector';

export type LanguageSelectorProps = {
  label?: string;
  DropUp?: boolean;
  panelMode?: boolean;
};

export default function LanguageSelector({
  DropUp,
  label,
  panelMode,
}: LanguageSelectorProps) {
  const { t, i18n } = useTranslation('app');

  console.debug(':: Language is ::', i18n.language);
  return (
    <DropdownSelector
      // panelMode={panelMode}
      classNameLabel={panelMode ? 'flex-1' : ''}
      headerMode={panelMode}
      label={label || t`Language`}
      value={(i18n.language as SupportedLanguage) ?? 'en-GB'}
      DropUp={DropUp}
      DropAlignRight
      values={[...supportedLanguages]}
      onChange={l => i18n.changeLanguage(l)}
      renderValue={l => getLanguageTitle(l, t)}
    />
  );
}
