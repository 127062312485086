import classNames from 'classnames';
import i18n from '../../i18n';
import * as Icon from '../icons';

export const ArrowSize = {
  default: 'h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6',
  xs: 'h-2 w-2 md:h-3 md:w-3 lg:h-4 lg:w-4',
  sm: 'h-3 w-3 md:h-4 md:w-4 lg:h-5 lg:w-5',
  md: 'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8',
  lg: 'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8',
  xl: 'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8',
};

export type IconProps = {
  className?: string;
  arrowSize?: keyof typeof ArrowSize | string;
};

export const IconBackDirectional = ({
  className,
  arrowSize = 'default',
}: IconProps) => {
  const sizeClass = ArrowSize[arrowSize];

  return i18n.dir(i18n.language) === 'ltr' ? (
    <Icon.ArrowLeft
      className={classNames(sizeClass, 'mx-0.5', 'fill-current', className)}

      // className={classNames(
      //   'h-5 w-5 md:h-6 md:w-6 lg:h-8 lg:w-8 mx-0.5 fill-current',
      //   className,
      // )}
    />
  ) : (
    <Icon.ArrowRight
      className={classNames(sizeClass, 'mx-0.5', 'fill-current', className)}
    />
  );
};
