import { Transition } from '@headlessui/react';
import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../components/actions/Button';
import { ButtonLoadMore } from '../components/actions/ButtonLoadMore';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import { Spacer } from '../components/layout/Spacer';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import {
  showAutoUpdateDateTimeState,
  showHeaderAddButtonState,
  showHelperCardState,
} from './store/dashboard.state';
import {
  DashboardGroupState,
  DashboardOrder,
  DashboardViewMode,
} from './store/dashboard.types';

export type DashboardItemsGroupContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  title: string;
  state: DashboardGroupState;
  className?: string;
  classNameContainerCards?: string;
  classNameContainerList?: string;
  loadingMessage?: string;
  hasScroll?: boolean;
  isLoading?: boolean;
  isSimulation?: boolean;
  showHelperCard?: boolean;
  collapsible?: boolean;
  updateDate?: Date;
  autoUpdate?: boolean;
  datasetJobType?: ImportJobImportType;
  datasetPath?: string;
  actionBar?: React.ReactNode;
  onViewModeChanged: (mode: DashboardViewMode) => void;
  onSortChanged: (mode: DashboardOrder) => void;
  onLoadMoreClick: () => void;
  onAutoUpdateChange?: (value: boolean) => void;
  viewFeatures?: string[];
  onViewFeaturesChanged?: (selectedFeature: string) => void;
};

const DashboardItemsGroupContainer: React.FC<
  DashboardItemsGroupContainerProps
> = props => {
  const { t } = useTranslation('app');
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const [showHelperCard, setShowHelperCard] =
    useRecoilState(showHelperCardState);
  const [showHeaderAddButton, setShowHeaderAddButton] = useRecoilState(
    showHeaderAddButtonState,
  );
  const [showAutoUpdateDateTime, setShowAutoUpdateDateTime] = useRecoilState(
    showAutoUpdateDateTimeState,
  );

  const isClosed = props.collapsible && state.isCollapsed;

  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);

  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const [viewOptions, setViewOptions] = useState<
    Map<DashboardViewMode | string, string>
  >(new Map());

  useEffect(() => {
    setViewOptions(
      new Map<DashboardViewMode | string, string>([
        [DashboardViewMode.card, t`View as cards`],
        [DashboardViewMode.list, t`View as list`],
        [DashboardViewMode.debug, t`View advanced stats`],
        [
          'showHelperCard',
          showHelperCard ? t`Hide Helper Card` : t`Show Helper Card`,
        ],
        [
          'showAutoUpdateDateTime',
          showAutoUpdateDateTime ? t`Hide Update Timer` : t`Show Update Timer`,
        ],
      ]),
    );
  }, [showHelperCard, showAutoUpdateDateTime]);

  const sortOptions = new Map<DashboardOrder, string>([
    [DashboardOrder.title, t`Title`],
    [DashboardOrder.createdAsc, t`Created (Oldest)`],
    [DashboardOrder.createdDesc, t`Created (Newest)`],
    [DashboardOrder.updatedAsc, t`Updated (Oldest)`],
    [DashboardOrder.updatedDesc, t`Recent`],
  ]);

  // const canLoadMore =
  //   props.state.totalCount > 0 &&
  //   props.state.loadedCount < props.state.totalCount;

  const handleViewModeChange = selectedOption => {
    if (selectedOption === 'showHelperCard') {
      setShowHelperCard(prevShowHelperCard => !prevShowHelperCard);
      setShowHeaderAddButton(true);
    } else if (selectedOption === 'showAutoUpdateDateTime') {
      setShowAutoUpdateDateTime(
        prevShowAutoUpdateDateTime => !prevShowAutoUpdateDateTime,
      );
    } else {
      props.onViewModeChanged(selectedOption);
    }
  };

  const styleIconButton = classNames(
    'h-5 w-5',
    'fill-current',
    'text-opacity-60 hover:text-menu-active',
    'bg-menu/5 hover:bg-menu-hover',
    'text-menu-text',
    'p-1 mx-0.5',
    'rounded-full',
  );

  return (
    <>
      <h2
        data-component="TitleSectionDashboard"
        data-label={`button-${props.title || ''}`}
        aria-label={`button-${props.title || ''}`}
        className={classNames(
          'flex items-center',
          'bg-app-panel/20 text-app-title',
          'text-xl md:text-2xl lg:text-3xl',
          'z-1000 sticky top-0',
          'backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
          'px-4 py-4',
          'ltr:pl-2 rtl:pr-2',
          'relative',
          isClosed
            ? 'border-app-panel-dark border-ts border-b'
            : 'border-app-panel-dark mb-4 border-b border-opacity-20 shadow-xl',
          { 'cursor-pointer': props.collapsible },
          props.className,
        )}
      >
        {props.collapsible ? (
          <button
            type="button"
            className={classNames('ltr:mr-2 rtl:ml-2')}
            aria-label={'Button-Collapsible'}
            onClick={e => {
              props.collapsible && toggleOpen();
            }}
          >
            {isClosed ? (
              <Icon.ChevronDualOpenVertical
                className={classNames(styleIconButton)}
              />
            ) : (
              <Icon.ChevronDualCloseHorizontal
                className={classNames(styleIconButton)}
              />
            )}
          </button>
        ) : null}
        <span
          className={classNames('truncate', {
            'ltr:pl-4 rtl:pr-4': !props.collapsible,
          })}
          onClick={e => {
            props.collapsible && toggleOpen();
          }}
        >
          {props.title}
        </span>
        <ButtonLoadMore
          itemsCount={props.state.loadedCount}
          totalCount={props.state.totalCount}
          onNext={props.onLoadMoreClick}
          isLoading={props.isLoading}
        />
        {props.isSimulation ? null : (
          <>
            {props.datasetPath && (
              <>
                <Spacer flexspace />

                <Button
                  label={t`Integrations`}
                  buttonType="secondary"
                  buttonSize="xs"
                  hasIconBefore={
                    <Icon.ImportMap
                      className={classNames('h-5 w-5 fill-current')}
                    />
                  }
                  className={classNames('rounded', 'ltr:mr-4 rtl:ml-4')}
                  onPress={() => {
                    navigate(
                      `/wh/i/${warehouseId}/data/${props.datasetPath}/integration`,
                    );
                  }}
                />
              </>
            )}
          </>
        )}
        {/* {props.isSimulation &&
          (showHeaderAddButton ||
            props.state.viewMode === DashboardViewMode.list) && (
            <>
              <Button
                label={t`New`}
                buttonType="primary"
                buttonSize="xs"
                hasIconAfter
                className={classNames('rounded mr-4')}
                buttonIcon={
                  <Icon.CirclePlus
                    className={classNames('w-5 h-5 fill-current')}
                  />
                }
                onPress={() => {
                  navigate(`/wh/i/${warehouseId}/simulations/new`);
                }}
              />
              <Button
                label={t`Compare`}
                buttonType="secondary"
                buttonSize="xs"
                hasIconBefore={
                  <Icon.Compare
                    className={classNames('w-5 h-5 fill-current')}
                  />
                }
                className={classNames('rounded ltr:mr-4')}
                onPress={() => {
                  navigate(`/wh/i/${warehouseId}/simulations/compare`);
                }}
              />
            </>
          )} */}
        {props.actionBar}
        {!props.datasetPath && <Spacer flexspace noMarginRule dashboardMode />}
        {_.isDate(props.updateDate) && (
          <div
            className={classNames(
              'text-xs',
              'flex flex-col',
              // 'ltr:ml-4 rtl:mr-4'
            )}
          >
            <span
              className={classNames('text-minimal uppercase', 'opacity-75')}
            >{t`updated:`}</span>
            <span>
              {new Intl.DateTimeFormat(undefined, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: undefined,
              }).format(props.updateDate)}
            </span>
          </div>
        )}
        {showAutoUpdateDateTime && _.isFunction(props.onAutoUpdateChange) && (
          <ButtonSwitchMulti
            buttonType="minimal"
            autoSize
            className={classNames('mx-2 text-xs')}
            options={[{ label: t`auto` }, { label: t`off` }]}
            selectedIndex={props.autoUpdate ? 0 : 1}
            onClick={index => props.onAutoUpdateChange(index === 0)}
          />
        )}
        <div className={classNames('text-sm', 'ltr:ml-1 rtl:mr-1')}>
          <DropdownSelector
            className={classNames('text-xs uppercase')}
            classNameItem="text-xs"
            DropAlignRight
            value={props.state.order}
            values={[...sortOptions.keys()]}
            renderValue={key => sortOptions.get(key)}
            onChange={props.onSortChanged}
            w_lg
          />
        </div>
        <div className={classNames('text-base ltr:ml-4 rtl:mr-4')}>
          <DropdownSelector
            className={classNames('ltr:ml-2 rtl:mr-2', 'text-xs')}
            w_lg
            vertical
            // headerMode
            DropAlignRight
            value={props.state.viewMode}
            values={[...viewOptions.keys()]}
            renderValue={key => viewOptions.get(key)}
            // onChange={props.onViewModeChanged}
            onChange={handleViewModeChange}
          />
        </div>
      </h2>

      <Transition
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className={'relative'}
      >
        {props.state.viewMode === DashboardViewMode.card ||
        props.state.viewMode === DashboardViewMode.debug ? (
          <div
            id={`list-card-container`}
            className={classNames(
              'max-w-screen flex flex-wrap',
              'px-4 pb-10',
              'relative',
              props.classNameContainerCards,
            )}
          >
            {props.children}
          </div>
        ) : (
          <div
            id={`list-container`}
            className={classNames(
              'flex flex-col',
              'max-w-screen',
              'px-4 pb-10 pt-2',
              'relative',
              props.classNameContainerList,
            )}
          >
            {props.children}
          </div>
        )}
      </Transition>
    </>
  );
};

export default DashboardItemsGroupContainer;
