import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../../components/icons';

export type PolicyRuleSectionTitleProps = {
  children?: React.ReactNode;
  title?: string;
  isFeasible?: boolean;
  counter?: number;
};

const PolicyRuleSectionTitle: React.FC<PolicyRuleSectionTitleProps> = props => {
  const { t } = useTranslation('simulation');

  return (
    <div data-component="PolicyRuleSectionTitle">
      <div className="flex items-center">
        {!props.isFeasible && (
          <Icon.TriangleInfo
            className={`w-4 h-4 ltr:mr-1 rtl:ml-1 fill-current text-alerts-alert`}
          />
        )}
        {props.title ?? t`Policy Rules`}

        {props.counter > 0 && (
          <span className="opacity-70 mx-2 text-xxs">({props.counter})</span>
        )}
      </div>
      {props.children}
    </div>
  );
};
export default PolicyRuleSectionTitle;
