import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AnalyzeResultSummary from '../../analyze/AnalyzeResultSummary';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import { analyzeCompareResultByType } from '../analyzeCompare.state';
import { AnalyzeCompareAnalyzeType } from '../analyzeCompare.types';
import AnalyzeCompareSelector from '../components/AnalyzeCompareSelector';

export type AnalyzeCompareSinglePanelProps = {
  analyzeType: AnalyzeCompareAnalyzeType;
};

const AnalyzeCompareSinglePanel: React.FC<
  AnalyzeCompareSinglePanelProps
> = props => {
  const { t } = useTranslation('simulation');
  const analyzeResultSummary = useRecoilValue(
    analyzeCompareResultByType(props.analyzeType),
  );
  return (
    <Container col hasOverflowY>
      <AnalyzeCompareSelector analyzeType={props.analyzeType} />
      {_.isNil(analyzeResultSummary) && (
        <Spacer flexspace className="h-full min-h-20" />
      )}
      {analyzeResultSummary && (
        <AnalyzeResultSummary analyzeResult={analyzeResultSummary} />
      )}
    </Container>
  );
};

export default AnalyzeCompareSinglePanel;
