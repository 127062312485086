import useLayoutAreaWatcher from '../../simulation/watcher/useLayoutAreaWatcher';
import useSimulationSidebarWatcher from '../../simulation/watcher/useSimulationSidebarWatcher';
import { SidebarType } from '../../store/sidebar.state';
import { StageType } from '../stage/stage.helper';

export default function LayoutWatcher({
  stageId,
  sidebarIds,
}: {
  stageId: StageType;
  sidebarIds: [SidebarType, SidebarType];
}) {
  useLayoutAreaWatcher(stageId);

  useSimulationSidebarWatcher(...sidebarIds);
  return null;
}
