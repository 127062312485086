import { addSeconds } from 'date-fns';
import { addDays } from 'date-fns/esm';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DayOfWeek,
  daysOfWeek,
  toDateFromLocaleStringTime,
  toLocaleTimeString,
} from '../../../../common/dateTimeHelper';
import { getWeekdayShort } from '../../../../common/formatHelper';
import ButtonToolbar from '../../../../components/actions/ButtonToolbar';
import InputSlider from '../../../../components/inputs/InputSlider';
import InputTime from '../../../../components/inputs/calendar/InputTime';
import RuleContainer from '../../../../components/policies/RuleContainer';
import { WorkforceShiftSettings } from '../../../store/workforce.types';

export type WorkforceShiftProps = {
  shift: WorkforceShiftSettings;
  setShift: (v: WorkforceShiftSettings) => void;
  onDelete: () => void;
};

const InputContainer = ({ children }: PropsWithChildren) => {
  return (
    <span
      data-component="InputContainer"
      className={`flex-1 px-2 md:px-3 lg:px-4 xl:px-6 my-0.5`}
    >
      {children}
    </span>
  );
};

const WorkforceShift: React.FC<WorkforceShiftProps> = props => {
  const { t } = useTranslation('simulation');
  const { shift, setShift } = props;
  const startTime = toDateFromLocaleStringTime(shift.startTime);
  const setStartTime = (v: string) => {
    let start = toDateFromLocaleStringTime(v);
    if (endTime < startTime) {
      start = addDays(endTime, -1);
    }
    const secondsOperation = (endTime.getTime() - start.getTime()) / 1000;

    setShift({
      ...shift,
      startTime: v,
      secondsOperation: secondsOperation,
    });
  };

  const setEndTime = (v: string) => {
    let endTime = toDateFromLocaleStringTime(v);
    if (endTime < startTime) {
      endTime = addDays(endTime, 1);
    }
    const secondsOperation = (endTime.getTime() - startTime.getTime()) / 1000;

    setShift({
      ...shift,
      secondsOperation: secondsOperation,
    });
  };
  const amount = shift.agentAmount;
  const setAmount = v => {
    setShift({
      ...shift,
      agentAmount: v,
    });
  };

  const cost = shift.costPerSecond * 3600;
  const setCost = v => {
    setShift({
      ...shift,
      costPerSecond: v / 3600,
    });
  };

  const setDayOfWeek = (d: DayOfWeek, isSet: boolean) => {
    const dayOfWeek = _.omit(shift.dayOfWeek, d) as Record<DayOfWeek, unknown>;
    if (isSet) {
      dayOfWeek[d] = true;
    }
    setShift({
      ...shift,
      dayOfWeek,
    });
  };

  const endTime = addSeconds(startTime, shift.secondsOperation);

  return (
    <RuleContainer
      id={`agent-simulation-shift-'${shift.id}`}
      title={shift.title}
      className="bg-shift-1/30"
      canRename
      isCollapsible
      isRemovable
      hasIcon
      hasColorMode={false}
      onDeleteClick={props.onDelete}
      onTitleChange={v =>
        setShift({
          ...shift,
          title: v,
        })
      }
    >
      <div
        data-component="WorkforceShift"
        className={`flex flex-1 flex-col p-0.5 xl:p-2 pb-10 xl:pb-10 rounded shadow-xl divide-y divide-solid divide-menu-400/50`}
        // style={{
        //   color,
        //   backgroundColor,
        // }}
      >
        <div
          data-component="WeekDays"
          className="flex flex-col flex-1 px-1 xl:px-3 mb-2 py-1"
        >
          <div className="text-xxs lg:text-xs text-menu-text my-1">{t`Work Days`}</div>
          <div className="grid grid-cols-7 gap-0.5">
            {_.map(daysOfWeek, weekday => {
              return (
                <ButtonToolbar
                  key={`weekday-btn-${weekday}`}
                  // isBoxed
                  className="text-xxs lg:text-xxs xl:text-xs !lg:p-0.5 xl:p-0.5 flex-1"
                  isSelected={_.has(shift.dayOfWeek, weekday)}
                  onChange={v => setDayOfWeek(weekday, v)}
                >
                  {getWeekdayShort(weekday)}
                </ButtonToolbar>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col flex-1 px-1 xl:px-3 mb-2 py-1">
          <div className="text-xxs lg:text-xs text-menu-text my-1">{t`Shift Range`}</div>
          <div
            data-component="FromToTimeSelect"
            className="flex flex-1 space-x-2 mt-2"
          >
            <InputTime
              label={t`From`}
              value={shift.startTime}
              onChange={setStartTime}
            />
            <InputTime
              label={t`To`}
              value={toLocaleTimeString(endTime)}
              onChange={setEndTime}
            />
          </div>
        </div>

        <InputContainer>
          <InputSlider
            classNameTitle="text-xxs lg:text-xs -ml-2 xl:-ml-1"
            title={t`Agents Amount`}
            max={200}
            min={1}
            stepSize={1}
            values={[amount]}
            onChange={v => setAmount(_.head(v))}
            handleSize="sm"
            isMinimal
            // activeMode
          />
        </InputContainer>
        <InputContainer>
          <InputSlider
            classNameTitle="text-xxs lg:text-xs -ml-2 xl:-ml-1"
            title={t`Cost per hour`}
            max={100}
            min={0.5}
            stepSize={0.5}
            values={[cost]}
            onChange={v => setCost(_.head(v))}
            handleSize="sm"
            isMinimal
            // activeMode
            unitOfMeasure={t`$/h`}
          />
        </InputContainer>
      </div>
    </RuleContainer>
  );
};

export default WorkforceShift;
