import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';

export type LocationMask = string;

@InputAndObjectType()
export class LocationMaskSpec {
  @Field(() => ID, { nullable: true })
  possibleLocationMask?: LocationMask;

  @Field(() => ID, { nullable: true })
  requiredLocationMask?: LocationMask;
}
