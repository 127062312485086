import * as React from 'react';
const SortGridIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="SortGridIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M62.29,2.03H2.29s0,60.46,0,60.46h60s0-60.46,0-60.46ZM49.49,54.3h-30.69s0-7.64,0-7.64h11.87s0-29.55,0-29.55h-.57s-8.97,11.74-8.97,11.74l-6.06-4.74,10.54-13.89h14.52s0,36.44,0,36.44h9.35s0,7.64,0,7.64Z"
    />
    <path
      strokeWidth={0}
      d="M2.29,65.51v60.46s60,0,60,0v-60.46s-60,0-60,0ZM48.61,118.16h-31.7s0-8.65,0-8.65l13.89-11.56c2.44-2.1,4.18-3.91,5.21-5.43,1.03-1.51,1.55-3.09,1.55-4.74v-.63c0-1.81-.55-3.2-1.64-4.17s-2.57-1.45-4.42-1.45c-1.98,0-3.55.57-4.7,1.7s-2.01,2.63-2.56,4.48l-8.27-3.16c1.05-3.33,2.97-6.03,5.74-8.12,2.78-2.08,6.36-3.13,10.73-3.13,3.16,0,5.88.57,8.18,1.7,2.29,1.14,4.04,2.71,5.24,4.7,1.2,2,1.8,4.28,1.8,6.85,0,2.32-.51,4.44-1.51,6.38-1.01,1.94-2.34,3.71-3.98,5.34-1.64,1.62-3.77,3.42-6.38,5.4l-8.4,6.5h21.22s0,7.96,0,7.96Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M65.71,2.03v60.46s60,0,60,0V2.03s-60,0-60,0ZM110.33,48.9c-1.43,1.96-3.44,3.47-6.03,4.55-2.59,1.08-5.61,1.61-9.06,1.61-4.08,0-7.41-.77-9.98-2.3-2.57-1.54-4.67-3.59-6.31-6.16l7.01-5.43c1.1,1.98,2.38,3.51,3.85,4.58,1.47,1.07,3.34,1.61,5.62,1.61,2.23,0,3.97-.53,5.21-1.58,1.24-1.05,1.86-2.5,1.86-4.36v-.44c0-1.77-.69-3.11-2.08-4.04-1.39-.92-3.33-1.39-5.81-1.39h-4.1s0-7.89,0-7.89h4.17c2.31,0,4.06-.44,5.24-1.33,1.18-.88,1.77-2.04,1.77-3.47v-.44c0-1.6-.55-2.87-1.64-3.82-1.1-.95-2.61-1.42-4.55-1.42-1.81,0-3.5.45-5.08,1.36-1.58.91-2.92,2.2-4.01,3.88l-6-5.56c1.85-2.4,3.99-4.23,6.41-5.49,2.42-1.26,5.44-1.89,9.06-1.89,3.16,0,5.92.48,8.31,1.45,2.38.97,4.22,2.34,5.53,4.1,1.3,1.77,1.96,3.81,1.96,6.12,0,2.69-.81,4.92-2.43,6.66-1.62,1.75-3.68,2.83-6.16,3.25v.38c2.82.51,5.09,1.67,6.82,3.5,1.73,1.83,2.59,4.2,2.59,7.1,0,2.61-.72,4.89-2.15,6.85Z"
      opacity={0.65}
    />
    <polygon
      strokeWidth={0}
      points="86.33 102.99 98.51 102.99 98.51 84.49 97.94 84.49 86.33 102.99"
      opacity={0.55}
    />
    <path
      strokeWidth={0}
      d="M65.71,65.51v60.46s60,0,60,0v-60.46s-60,0-60,0ZM113.29,110.32h-5.68v8.46s-9.09,0-9.09,0v-8.46s-19.83,0-19.83,0v-8.08s17.56-27.53,17.56-27.53h11.37s0,28.29,0,28.29h5.68s0,7.33,0,7.33Z"
      opacity={0.55}
    />
  </svg>
);
export default SortGridIcon;
