import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InboxZero from '../components/InboxZero';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import TitleSection from '../components/layout/TitleSection';
import { StatusTag } from '../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../components/policies/PolicyWell';
import {
  cloneAgent,
  createAgent,
} from '../simulation/store/resourcePolicy.helper';
import {
  resourcePolicy,
  resourcePolicyAgentById,
  resourcePolicySelectedAgentId,
} from '../simulation/store/resourcePolicy.state';
import {
  simulationIsEditable,
  simulationUoms,
} from '../simulation/store/simulation.state';
import { warehouseSelected } from '../store/warehouse.state';
import ResourcePolicyAgent from './ResourcePolicyAgent';
import ResourcePolicyWarnings from './ResourcePolicyWarnings';
import { AgentSettingsWithMeta } from './agentData/agent.types';

const ResourcePolicyPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const wh = useRecoilValue(warehouseSelected);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const [policy, setPolicy] = useRecoilState(resourcePolicy);
  const setSelectedAgentId = useSetRecoilState(resourcePolicySelectedAgentId);
  const uoms = useRecoilValue(simulationUoms);

  const existingTitles = _.map(policy.agents, r => r.title);

  const addAgent = () => {
    const agent = createAgent({
      titles: existingTitles,
      titlePrefix: t`Agent #`,
      uoms: uoms,
      system: wh.measurementSystem,
      currencyCode: wh.currency,
    });

    setPolicy({
      ...policy,
      agents: [agent, ...(policy.agents ?? [])],
    });
    setSelectedAgentId(agent.id);
  };

  const clone = (agent: AgentSettingsWithMeta) => {
    const newAgent = cloneAgent({
      agent,
      titles: existingTitles,
      titlePrefix: t('{{sourceTitle}} Copy', {
        sourceTitle: agent.title,
      }),
    });

    setPolicy({
      ...policy,
      agents: [newAgent, ...(policy.agents ?? [])],
    });
    setSelectedAgentId(newAgent.id);
  };

  const removeAgent = (agentId: string) => {
    setPolicy({
      ...policy,
      agents: _.filter(policy.agents, agent => agent.id !== agentId),
    });
  };

  const policyRuleCounter = policy?.agents?.length;
  const hasRules = policyRuleCounter > 0;

  return (
    <Container col hasOverflowY hasExtraPadding>
      <ScreenTitle
        subtitle={t`Resource Policy`}
        title={t`MHE Agents`}
        isSticky
        helpNavTo={'simulation/policies/policy-resource/policy-resource'}
        icon={Icon.PolicyPickingAgents}
      />
      <ResourcePolicyWarnings />

      <TitleSection
        title={
          <PolicyRuleSectionTitle
            title={t`Resources`}
            isFeasible={hasRules}
            counter={policyRuleCounter}
          />
        }
        inPanelView
        className="top-12 xl:top-20 z-400"
        hasScreenTitle
        hasAction={
          canUpdate ? (
            <Button
              label={t`Add Resource`}
              className={classNames('ltr:ml-4 rtl:mr-4 rounded')}
              buttonSize="xs"
              buttonType="primary"
              hasIconAfter={
                <Icon.CirclePlus
                  className={classNames('w-5 h-5 fill-current')}
                />
              }
              onPress={addAgent}
            />
          ) : (
            <StatusTag
              title={t`Policy Locked`}
              type="locked"
              modeStyle="stroke"
              icon={Icon.Lock}
            />
          )
        }
      />
      <PolicyWell fullHeight isDisabled={!canUpdate}>
        {!hasRules && (
          <InboxZero
            center
            selfCenter
            message={t`No Resources`}
            // icon={Icon.TriangleInfo}
          />
        )}

        {policy.agents.map((agent, index) => (
          <ResourcePolicyAgent
            key={`agent-${agent.id}`}
            agentId={agent.id}
            agentSelector={resourcePolicyAgentById}
            index={index + 1}
            isCheckLoading={false}
            isDisabled={false}
            canDelete={canUpdate}
            canRename={canUpdate}
            onDeleteClick={() => removeAgent(agent.id)}
            onCloneClick={() => clone(agent)}
          />
        ))}
      </PolicyWell>
    </Container>
  );
};

export default ResourcePolicyPanel;
