import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LayoutFilter from '../../components/LayoutFilter';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import { FeedLatestEvent } from '../store/datasetQueries/feedLatestEvents';
import { SWARM_PAGE_SIZE } from '../store/feed.default';
import {
  KpiSummaryModes,
  feedAgentsStats,
  feedAggregateBy,
  feedEffectiveDateRange,
  feedSelectedAgentIds,
  feedSelectedAgentsDataPage,
  showEventsKpiSummaryChart,
} from '../store/feed.state';
import { feedActivityAggregateBy } from '../store/feed.types';
import FeedActivityAgentHeatmap from './FeedActivityAgentHeatmap';
import FeedActivitySwarm, {
  SwarmColorBy,
  swarmColorBy,
} from './FeedActivitySwarm';

export type FeedSwarmItem = FeedLatestEvent & {
  color: string;
  id: string;
  date: Date;
  ms: number;
};

const FeedActivitySwarmRoot: React.FC = () => {
  const { t } = useTranslation('feed');
  const agentStatsAll = useRecoilValue(feedAgentsStats);
  const [from, to] = useRecoilValue(feedEffectiveDateRange);
  const [selectedAgents, setSelectedAgents] =
    useRecoilState(feedSelectedAgentIds);
  const [selectedPage, setSelectedPage] = useRecoilState(
    feedSelectedAgentsDataPage,
  );
  const [kpiSummaryMode, setKpiSummaryMode] = useRecoilState(
    showEventsKpiSummaryChart,
  );

  const [aggregateBy, setAggregateBy] = useRecoilState(feedAggregateBy);
  const [colorBy, setColorBy] = useState<SwarmColorBy>('agent');

  useEffect(() => {
    return () => {
      setSelectedPage(1);
    };
  }, []);

  const resourcePaginationLabel = (currentPage: number): React.ReactNode => {
    const startItem = (currentPage - 1) * SWARM_PAGE_SIZE + 1;
    const endItem = Math.min(currentPage * SWARM_PAGE_SIZE, totalAgents);

    if (currentPage === -1) {
      return (
        <div
          data-component="resourcePaginationLabel"
          className={classNames('ltr:pr-2 rtl:pl-2')}
        >
          <div className={classNames('text-base')}>ALL</div>
        </div>
      );
    }
    return (
      <div
        data-component="resourcePaginationLabel"
        className={classNames('ltr:pr-2 rtl:pl-2')}
      >
        <div
          className={classNames('text-base')}
        >{`${startItem}—${endItem}`}</div>

        <div className={classNames('opacity-75', 'text-xs')}>
          <span
            className={classNames('uppercase text-xxs ltr:mr-1 rtl:ml-1')}
          >{`of Total:`}</span>
          {`(${totalAgents})`}
        </div>
      </div>
    );
  };

  const selectedAgentsSet = new Set(selectedAgents);

  function toggleSelectAgent(agentId: string) {
    const isReset = selectedAgentsSet.has(agentId);

    setSelectedAgents(
      isReset
        ? _.filter(selectedAgents, a => a !== agentId)
        : [...selectedAgents, agentId],
    );
  }

  const showAllAgents = _.isEmpty(selectedAgents);

  const totalAgents = _.size(agentStatsAll);
  const pageCount = Math.ceil(_.size(agentStatsAll) / SWARM_PAGE_SIZE);

  const colorByOptions: ButtonSwitchMultiOption[] = [
    {
      label: t`Agent ID`,
    },
    {
      label: t`Agent Type`,
    },
    {
      label: t`Job ID`,
    },
    {
      label: t`Job Type`,
    },
  ];

  const aggregateByOptions: ButtonSwitchMultiOption[] = [
    {
      label: t`Hour`,
    },
    {
      label: t`Day`,
    },
  ];

  const kpiSummaryModeOptions: Record<KpiSummaryModes, string> = {
    [KpiSummaryModes.Summary]: t('Summary'),
    [KpiSummaryModes.All]: t('All'),
    [KpiSummaryModes.Detailed]: t('Details'),
  };

  const options: ButtonSwitchMultiOption[] = Object.entries(
    kpiSummaryModeOptions,
  ).map(([_, label]) => ({
    label,
  }));

  const getKeyByValue = (
    object: Record<KpiSummaryModes, string>,
    value: string,
  ) => {
    return Object.keys(object).find(
      key => object[key as KpiSummaryModes] === value,
    ) as KpiSummaryModes;
  };

  const showSwarm =
    (to?.getTime() ?? 0) - (from?.getTime() ?? 0) <= 3600 * 1000;

  return (
    <Container flex1 col>
      <ScreenTitle
        title={t`Latest activity`}
        subtitle={t`Live feed`}
        isSticky
        truncate
      >
        {showAllAgents && (
          <>
            {pageCount > 1 && (
              <DropdownSelector
                className={classNames('mx-2 text-xs')}
                value={selectedPage}
                values={[-1, ..._.range(pageCount).map((_, i) => i + 1)]}
                renderValue={resourcePaginationLabel}
                onChange={value => setSelectedPage(value)}
                light
              />
            )}
          </>
        )}
      </ScreenTitle>
      <ActionBar className={classNames('p-2')} sticky>
        {!showSwarm && (
          <ButtonSwitchMulti
            className="mx-2"
            autoSize
            selectedIndex={Object.values(kpiSummaryModeOptions).indexOf(
              kpiSummaryModeOptions[kpiSummaryMode],
            )}
            onClick={index => {
              const selectedLabel = options[index].label;
              const selectedMode = getKeyByValue(
                kpiSummaryModeOptions,
                selectedLabel,
              );
              setKpiSummaryMode(selectedMode);
            }}
            options={options}
            buttonType="secondary"
          />
        )}
        <Spacer flexspace />
        {showSwarm && (
          <ButtonSwitchMulti
            className="mx-2"
            autoSize
            selectedIndex={_.indexOf(swarmColorBy, colorBy)}
            onClick={index => setColorBy(swarmColorBy[index])}
            options={colorByOptions}
            buttonType="secondary"
          />
        )}
        {!showSwarm && (
          <ButtonSwitchMulti
            className="mx-2"
            autoSize
            selectedIndex={_.indexOf(feedActivityAggregateBy, aggregateBy)}
            onClick={index => setAggregateBy(feedActivityAggregateBy[index])}
            options={aggregateByOptions}
            buttonType="secondary"
          />
        )}
      </ActionBar>
      {!showAllAgents && (
        <ActionBar className={classNames('p-2')} sticky>
          {/* {t`Selected:`} */}
          {_.map(selectedAgents, a => (
            <LayoutFilter
              key={`selected-agent-${a}`}
              type="selection"
              onRemoveClick={() => toggleSelectAgent(a)}
              className={classNames('ltr:ml-1 rtl:mr-1')}
            >
              {a}
            </LayoutFilter>
          ))}
        </ActionBar>
      )}
      <Container col hasOverflowX>
        <Suspense fallback={<LoadingIndicator selfCenter />}>
          {showSwarm && <FeedActivitySwarm colorBy={colorBy} />}
          {!showSwarm && <FeedActivityAgentHeatmap />}
        </Suspense>
      </Container>
    </Container>
  );
};

export default FeedActivitySwarmRoot;
