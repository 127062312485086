import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputSlider from '../components/inputs/InputSlider';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import RuleSectionContainer from '../components/policies/RuleSectionContainer';
import { getLocationFilterConfigCommon } from '../simulation/store/assignmentPolicy.default';
import { optimisationIsEditable } from '../simulation/store/optimisation.state';
import { sidebarStateByType } from '../store/sidebar.state';
import { getZoneTypeOptions } from './loadBalancingPolicy.helper';
import {
  loadBalancingPolicyRule,
  loadBalancingPolicySelectedIdentity,
  loadBalancingShowAdvancedOptions,
} from './loadBalancingPolicy.state';
import {
  LoadBalancingPolicySelectedIdentity,
  LoadBalancingPolicySelectedType,
} from './loadBalancingPolicy.type';

export type LoadBalancingRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;
  isCheckLoading: boolean;
  productCount?: [number, number];
  locationCount?: [total: number, exclusive: number, empty?: number];

  disableProductSelection?: boolean;
  canDelete?: boolean;
  onDeleteClick?: () => void;
  canRename?: boolean;
};

const LoadBalancingRule: React.FC<LoadBalancingRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const { ruleId } = props;
  const [rule, updateRule] = useRecoilState(loadBalancingPolicyRule(ruleId));
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    loadBalancingPolicySelectedIdentity,
  );
  const [showAdvanced, setShowAdvanced] = useRecoilState(
    loadBalancingShowAdvancedOptions,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-swap-editor'),
  );
  const isDisabled = !useRecoilValue(optimisationIsEditable);
  // const checkResult = useRecoilValue(loadBalancingPolicyCheckResult);
  // const ruleCheckResult = _.find(
  //   checkResult?.result?.rules,
  //   r => r.id === ruleId,
  // );

  function handleSelect(
    type: LoadBalancingPolicySelectedType,
    locationFilterId: string,
  ) {
    const isClearing =
      type === 'zone-type'
        ? isActive && selectedIdentity.type === 'zone-type'
        : _.isNil(locationFilterId) ||
          locationFilterId === selectedIdentity?.locationFilterId;

    const newSelectedIdentity: LoadBalancingPolicySelectedIdentity = isClearing
      ? null
      : {
          ruleId,
          locationFilterId,
          type,
        };

    setSelectedIdentity(newSelectedIdentity);
    // trigger  toggle filter editor panel
    if (!isDisabled && !policyEditPanelState.isPinned) {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: isClearing,
      });
    }
  }

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  function addLocationIntersection() {
    const id = nanoid();
    updateRule({
      ...rule,
      include: {
        anyOf: [...(rule?.include?.anyOf ?? []), { id, allOf: [] }],
      },
    });
    handleSelect('locations', id);
  }

  function removeIntersection(groupId: string) {
    updateRule({
      ...rule,
      include: {
        anyOf: _.filter(rule.include?.anyOf, fg => fg.id !== groupId),
      },
    });

    if (groupId === selectedIdentity?.locationFilterId) {
      setSelectedIdentity(null);
    }
  }

  const removeField = (groupId: string, fieldId: string) => {
    updateRule({
      ...rule,
      include: {
        anyOf: _.map(rule.include.anyOf, match => {
          if (match.id !== groupId) return match;
          return {
            ...match,
            allOf: match.allOf.filter(filter => filter.type !== fieldId),
          };
        }),
      },
    });
  };

  const isActive = selectedIdentity?.ruleId === ruleId;
  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),
    actions: {
      advanced: () => setShowAdvanced(!showAdvanced),
    },
  };
  const isSelectedLocation = isActive && selectedIdentity?.type === 'locations';
  const isSelectedZoneType = isActive && selectedIdentity?.type === 'zone-type';

  // find the zone type option that matches the value
  const options = getZoneTypeOptions(t);
  const zoneTypeOption = options.find(option => option.id === rule.type);

  return (
    <RuleContainer
      {...containerParams}
      isDisabled={props.isDisabled}
      hasIcon
      hasOrder
      hasColorMode={false}
    >
      {/* <div className="space-y-6"> */}
      <PolicyFilter
        label={t`Balance By`}
        isActive={isSelectedZoneType}
        isDisabled={isDisabled}
        onClick={() => handleSelect('zone-type', null)}
      >
        <PolicyFilterItem
          value={zoneTypeOption?.title ?? `Select location`}
          icon={zoneTypeOption?.icon}
          iconClassName={classNames(
            'w-6 h-6 xl:w-8 xl:h-8',
            'opacity-50',
            'ltr:mr-2 rtl:ml-2 ltr:ml-1 rtl:mr-1',
          )}
          isActive={isSelectedZoneType}
          isDisabled={isDisabled}
          isRemovable={false}
        />
      </PolicyFilter>

      {showAdvanced && (
        <PolicyFilter key={`PolicyFilter-weight`} label={t`Weight`}>
          <InputSlider
            min={0}
            max={2}
            values={[rule.objectiveWeight ?? 0]}
            stepSize={0.01}
            onChange={v =>
              updateRule({
                ...rule,
                objectiveWeight: _.head(v),
              })
            }
            isDisabled={isDisabled}
          />
        </PolicyFilter>
      )}
      <RuleSectionContainer isSelected={isSelectedLocation}>
        <PolicyFilterGroupList
          // isDisabled={isDisabled}
          filterSets={_.map(rule?.include?.anyOf, filterGroup => ({
            id: filterGroup.id,
            allOf: [...filterGroup.allOf],
          }))}
          config={getLocationFilterConfigCommon(t)}
          selectedId={selectedIdentity?.locationFilterId}
          onAddGroup={addLocationIntersection}
          onDeleteField={removeField}
          onSelectGroup={(id, isSelected) => handleSelect('locations', id)}
          onDeleteGroup={removeIntersection}
        />
      </RuleSectionContainer>
      {/* </div> */}
    </RuleContainer>
  );
};
export default LoadBalancingRule;
