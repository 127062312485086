import _ from 'lodash';
import React from 'react';
import { HeatmapBucket } from '../../common/heatmap.helper';
import { HeatmapMetricDescriptor } from '../../common/heatmap.types';
import { Stat } from '../stats/Stat';

export type HeatmapBucketsListItemProps = {
  bucket: HeatmapBucket;
  isSelected: boolean;
  metricDescriptor: HeatmapMetricDescriptor<string, Object>;
  onClick: (
    value: boolean,
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
};

const HeatmapBucketsListItem: React.FC<HeatmapBucketsListItemProps> = props => {
  let title = props.bucket.title;
  const format = props.metricDescriptor.format;
  if (_.isFunction(format)) {
    const { from, to } = props.bucket;
    title = `${format(from)} ... ${format(to)}`;
  }

  return (
    <Stat
      key={`legend-stat-bucket-${props.bucket.id}`}
      className={'mb-0.5'}
      title={title}
      legendColor={[props.bucket.color, props.bucket.textColor]}
      isActionable
      isPreview
      isSelectable
      isSelected={props.isSelected}
      onClick={evt => props.onClick(!props.isSelected, evt)}
    />
  );
};

export default HeatmapBucketsListItem;
