import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { warehouseIsDemo, warehouseSelected } from '../store/warehouse.state';
import { WarehouseExtraSettings } from '../store/warehouse.types';
import AppHeaderMenuItem from './AppHeaderMenuItem';
import { ButtonMenuWarehouses } from './ButtonMenuWarehouses';
import { ButtonSidebarToggle } from './ButtonSidebarToggle';
import { LabelModes } from './LabelModes';
import * as Icon from './icons';

const WarehouseTitleLabelDemo = ({ warehouseId }: AppHeaderMenuProps) => {
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);

  return (
    <div
      data-component="WarehouseTitleLabelDemo"
      className={classNames('flex flex-1 flex-col', 'font-title', 'mb-0.5')}
    >
      <div className={classNames('flex items-center truncate text-xs')}>
        {isDemo ? (
          <LabelModes title={t`Warehouse Demo`} type="DEMO" />
        ) : (
          <span className="">{t`Warehouse`}</span>
        )}
      </div>
    </div>
  );
};

const WarehouseTitle = ({ warehouseId }: AppHeaderMenuProps) => {
  const warehouse = useRecoilValue(warehouseSelected);

  return (
    <div
      data-component="WarehouseTitle"
      className={classNames('text-xl xl:text-3xl', 'truncate', 'max-w-ch-30')}
    >
      {warehouse?.title}
    </div>
  );
};

export type AppHeaderMenuProps = {
  warehouseId?: string;
  children?: React.ReactNode;
  active?: boolean;
  isDemo?: boolean;
  isPurchasing?: boolean;
  canSwitchWarehouse?: boolean;
  showIfActive?: boolean;
};

export const AppHeaderMenu = ({
  warehouseId,
  isPurchasing,
  canSwitchWarehouse,
}: AppHeaderMenuProps) => {
  const { t } = useTranslation('app');
  const wh = useRecoilValue(warehouseSelected);
  const isDemo = useRecoilValue(warehouseIsDemo);
  const hasLiveFeed = (wh?.extraSettings as WarehouseExtraSettings)?.liveFeed
    ?.enabled;

  const [id] = useState<string>(_.uniqueId());

  const menuItems = [
    hasLiveFeed && {
      title: t`Activity Feed`,
      subtitle: t`Warehouse`,
      to: `/wh/i/${warehouseId}/feed/`,
      default: true,
      isDemo: isDemo,
      beta: false,
      disabled: false,
      order: 1,
      isExact: true,
    },
    {
      title: t`Simulations`,
      subtitle: t`Digital Twin`,
      to: `/wh/i/${warehouseId}/simulations`,
      default: true,
      isDemo: isDemo,
      beta: false,
      disabled: false,
      order: 2,
      isExact: true,
    },
    {
      title: t`Data`,
      subtitle: t`Warehouse`,
      to: `/wh/i/${warehouseId}/data`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 1,
      isExact: false,
    },
    {
      title: t`Designer`,
      subtitle: t`Warehouse`,
      to: `/wh/i/${warehouseId}/designer`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 3,
      isExact: false,
    },

    {
      title: t`Billing Status`,
      subtitle: t`Warehouse Settings`,
      to: `/wh/i/${warehouseId}/settings`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
    },
    {
      title: t`Billing`,
      subtitle: t`Revenue Assurance`,
      to: `/wh/i/${warehouseId}/billing`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 4,
      isExact: true,
    },
    {
      title: t`Contracts`,
      subtitle: t`Audit`,
      to: `/wh/i/${warehouseId}/contracts`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 5,
      isExact: true,
    },
    {
      title: t`Marketplace`,
      subtitle: t`Warehouse`,
      to: `/wh/i/${warehouseId}/marketplace`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 6,
      isExact: true,
    },
    {
      title: t`Sales`,
      subtitle: t`AI Assistant`,
      to: `/wh/i/${warehouseId}/sales`,
      default: false,
      beta: true,
      disabled: true,
      collapsed: 'sm',
      order: 7,
      isExact: true,
    },
    {
      title: t`Plans & Pricing`,
      subtitle: t`Warehouse Settings`,
      to: `/wh/i/${warehouseId}/pricing`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
      showIfActive: true,
      isPurchasing: true,
      widthCustom: 'max-w-10 min-w-10',
    },
    // {
    //   title: t`More...`,
    //   subtitle: t`Try`,
    //   to: `/wh/i/${warehouseId}/beta`,
    //   default: false,
    //   disabled: false,
    //   beta: true,
    //   order: 4,
    //   isExact: true,
    //   widthCustom: 'max-w-10 min-w-10',
    // },
  ].filter(Boolean);

  return (
    <div
      data-component="AppHeaderMenuWrapper"
      className={classNames(
        'flex items-center',
        'w-full',
        'border-menu-active border-b',
        'bg-app-header',
      )}
    >
      <header
        data-component="AppHeaderMenu"
        id={id}
        className={classNames(
          'flex items-center',
          'flex-1',
          'w-full',
          'p-0',
          'm-0',
        )}
      >
        <nav
          className={classNames(
            'flex flex-1 items-end ltr:justify-start rtl:justify-end',
          )}
        >
          <div className={classNames('flex flex-1')}>
            <ButtonMenuWarehouses
              collapsible={canSwitchWarehouse || !isPurchasing}
            >
              <div
                className={classNames('flex flex-1 items-center', 'px-2 py-2')}
              >
                <div className="flex-1">
                  <WarehouseTitleLabelDemo />
                  <WarehouseTitle />
                </div>
              </div>
            </ButtonMenuWarehouses>
          </div>

          <div
            data-component="AppHeaderMenu"
            className={classNames(
              'hidden flex-1 md:flex ltr:justify-end rtl:justify-start',
            )}
          >
            {isPurchasing ? (
              <>
                <AppHeaderMenuItem
                  key={`header-item-purchases-purchase`}
                  exactActive={true}
                  title={t`Purchase Plan`}
                  subtitle={t`Warehouse Setup`}
                  to={``}
                  active={true}
                />
                <AppHeaderMenuItem
                  key={`header-item-purchases-cancel`}
                  exactActive={true}
                  title={t`Cancel Purchase`}
                  // subtitle={`Warehouse Setup`}
                  to={`/wh/i/${warehouseId}`}
                  icon={
                    <Icon.CircleX
                      className={classNames(
                        'fill-current',
                        'h-7 w-7',
                        'ltr:mr-2 rtl:ml-2',
                      )}
                    />
                  }
                  active={true}
                />
              </>
            ) : (
              <>
                {menuItems.map(item => (
                  <AppHeaderMenuItem
                    // className={`${item.collapsed === 'sm' && 'hidden'}`}
                    key={`header-item-${item.title}`}
                    exactActive={item.isExact}
                    isInBeta={item.beta}
                    isDemo={item.isDemo}
                    title={item.title}
                    subtitle={item.subtitle}
                    to={item.to}
                    hidden={
                      item.disabled ||
                      item.collapsed === 'sm' ||
                      item.showIfActive
                    }
                    active={item.default}
                    widthCustom={item.widthCustom}
                  />
                ))}
              </>
            )}
          </div>
          {!isPurchasing && (
            <div
              className={classNames(
                'border-app-panel-dark/20 ltr:border-l rtl:border-r',
                'min-w-20',
              )}
            >
              <ButtonSidebarToggle
                title={t`Menu`}
                noTitle
                icon={Icon.MenuBars3Bars}
                buttonSize={'menu'}
                sidebarID="sidebar-global-menu"
              />
            </div>
          )}
        </nav>
      </header>
    </div>
  );
};
