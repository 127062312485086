import { ApolloProvider } from '@apollo/client/react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { secureClient, updateClient } from './GraphQLClient';
import {
  APP_DOMAIN_URL,
  COGNITO_USER_POOL_CLIENT_ID,
  COGNITO_USER_POOL_ID,
  COGNITO_USER_POOL_MFA_CLIENT_ID,
  COGNITO_USER_POOL_MFA_ID,
} from './common/environmentVariables';
import { getAuthState } from './store/auth.helper';
import { authState, authUserPoolType } from './store/auth.state';
export type AuthManagerProps = PropsWithChildren & {
  errorFallback?: ReactElement;
};

const AuthManager: React.FC<AuthManagerProps> = ({
  children,
  errorFallback,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token') || searchParams.get('access_token');

  const setAuth = useSetRecoilState(authState);
  const userPoolType = useRecoilValue(authUserPoolType);

  const redirectUri = `${window.location.origin}/`;
  const isLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Ensure string type for baseRedirectUrl
  const baseRedirectUrl: string = isLocalhost
    ? 'http://localhost:4200/'
    : `https://${String(APP_DOMAIN_URL)}/`;

  // Ensure string type for logoutRedirectUrl
  const logoutRedirectUrl: string = isLocalhost
    ? 'http://localhost:4200/login'
    : `https://${String(APP_DOMAIN_URL)}/login`;

  useEffect(() => {
    if (token) {
      const userData = JSON.parse(atob(token.split('.')[1]));
      console.log('userData', userData);
      const userId = userData.username;
      const userName = userData.name;
      const userEmail = userData.email;
      const userFirstName = userData.given_name;
      const userLastName = userData.family_name;

      setAuth({
        status: 'authorized',
        user: {
          id: userId,
          email: `${userEmail}`,
          firstName: `${userFirstName}`,
          lastName: `${userLastName}`,
          fullName: `Oauth ${userName}`,
        },
      });
      updateClient(token);
      navigate(location);

      window.addEventListener('message', event => {
        if (event.data.accessToken) {
          updateClient(event.data.accessToken);
        }
      });
    }
  }, []);
  Amplify.configure({
    Auth: {
      Cognito: {
        signUpVerificationMethod: 'code',
        loginWith: {
          oauth: {
            domain: `auth.${APP_DOMAIN_URL}`,
            scopes: ['email', 'profile', 'openid'],
            responseType: 'code',
            redirectSignIn: [baseRedirectUrl],
            redirectSignOut: [logoutRedirectUrl],
          },
        },
        userPoolId:
          userPoolType === 'mfa'
            ? COGNITO_USER_POOL_MFA_ID
            : COGNITO_USER_POOL_ID,
        userPoolClientId:
          userPoolType === 'mfa'
            ? COGNITO_USER_POOL_MFA_CLIENT_ID
            : COGNITO_USER_POOL_CLIENT_ID,
      },
    },
  });

  Hub.listen('auth', async data => {
    switch (data.payload.event) {
      case 'signedIn':
        const state = await getAuthState();
        return setAuth(state);
      case 'signedOut':
        return setAuth({ status: 'unauthorized' });
    }
  });

  // if (auth.status === 'error') {
  //   return errorFallback;
  // }

  return <ApolloProvider client={secureClient}>{children}</ApolloProvider>;
};

export default AuthManager;
