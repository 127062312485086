import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { formatInteger } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import { getIndexedTitle } from '../../common/utils';
import ErrorIndicator from '../../components/ErrorIndicator';
import InboxZero from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { StatusTag } from '../../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../../components/policies/PolicyWell';
import { Stat } from '../../components/stats/Stat';
import HelperContainer from '../../containers/HelperContainer';
import PickingPolicyRule from '../../pickingPolicy/PickingPolicyRule';
import PickingPolicyWarnings from '../../pickingPolicy/PickingPolicyWarnings';
import useCheckPickingPolicy from '../../pickingPolicy/hooks/useCheckPickingPolicy';
import useLoadOrderLineSet from '../../pickingPolicy/hooks/useLoadOrderLineSet';
import { collapsibleStateAtom } from '../../store/collapsible.state';
import { sidebarStateByType } from '../../store/sidebar.state';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import { pickingRequirementDefault } from '../store/pickingPolicy/pickingPolicy.default';
import { getPickingPolicyInput } from '../store/pickingPolicy/pickingPolicy.helper';
import {
  pickingPolicy,
  pickingPolicyCheckResult,
  pickingPolicyCheckResultLoadStatus,
  pickingPolicyIsReadyToAnalyze,
  pickingPolicySelectedDetailsIdentityAtom,
  pickingPolicySelectedIdentity,
} from '../store/pickingPolicy/pickingPolicy.state';
import { simulationOrderSetSummary } from '../store/simulation.layout.state';
import {
  simulationCurrent,
  simulationIsEditable,
  simulationOrderLineSet,
  simulationOrderLineSetLoadStatus,
} from '../store/simulation.state';

const PickingPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const [policy, setPolicy] = useRecoilState(pickingPolicy);
  const [callCheck, cancelCheck] = useCheckPickingPolicy();
  const updateSim = useUpdateSimulation();
  const checkResult = useRecoilValue(pickingPolicyCheckResult);
  const checkResultStatus = useRecoilValue(pickingPolicyCheckResultLoadStatus);
  const [selectedDetails, setSelectedDetails] = useRecoilState(
    pickingPolicySelectedDetailsIdentityAtom,
  );
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    pickingPolicySelectedIdentity,
  );
  const orderLineSet = useRecoilValue(simulationOrderLineSet);
  const orderLineSetLoadStatus = useRecoilValue(
    simulationOrderLineSetLoadStatus,
  );
  const [loadOrderLineSet, cancelLoadOrderLineSet] = useLoadOrderLineSet();
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const selectStep = useSelectSimulationWizardStep();
  const canUpdate = useRecoilValue(simulationIsEditable);
  const pickingPolicyStatus = useRecoilValue(pickingPolicyIsReadyToAnalyze);
  const isFeasible = Object.values(pickingPolicyStatus).every(
    val => val === true,
  );

  const orderSetSummary = useRecoilValue(simulationOrderSetSummary);
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-picking-editor'),
  );
  const orderLinesCount =
    _.sumBy(orderSetSummary?.consignees, c => c.orderLineCount) ?? 0;

  useEffect(() => {
    if (orderLineSet?.status === BatchJobStatus.READY) {
      updateSim({ pickingPolicy: getPickingPolicyInput(policy) });
      cancelCheck();
      callCheck();
    }
  }, [policy, orderLineSet?.status]);

  useEffect(() => {
    if (orderLineSetLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    //  recalculate SimulationOrderLineSet data if it not calculated ye
    if (_.isNil(orderLineSet)) {
      loadOrderLineSet({
        simulationId: sim.id,
      });
    }
    if (
      orderLineSet?.status === BatchJobStatus.CREATED ||
      orderLineSet?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadOrderLineSet({
          simulationId: sim.id,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadOrderLineSet();
    };
  }, [orderLineSet]);

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }

    if (selectedDetails?.ruleId === ruleId) {
      setSelectedDetails(null);
    }
    setPolicy({
      ...policy,
      orderLineRules: _.filter(
        policy.orderLineRules,
        rule => rule.id !== ruleId,
      ),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();
    const orderLineFilterId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy?.orderLineRules, r => r.title)),
      t`Picking Policy #`,
    );
    setPolicy({
      ...policy,
      orderLineRules: [
        ...(policy?.orderLineRules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          orderLinesMatch: {
            anyOf: [
              {
                id: orderLineFilterId,
                allOf: [],
              },
            ],
          },
          pickingRequirementsSettings: pickingRequirementDefault,
          pickingRules: [],
        },
      ],
    });

    setSelectedIdentity({
      ruleId,
      orderLineFilterId,
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });

    setSelectedDetails({
      ruleId,
      showOrderLinesByRule: true,
    });

    setPolicyEditPanelState({
      isHidden: false,
      isCollapsed: !policyEditPanelState.isPinned
        ? false
        : policyEditPanelState.isCollapsed,
    });
  };

  function moveRule(from, to) {
    const newRules = [...policy.orderLineRules];
    newRules.splice(to, 0, newRules.splice(from, 1)[0]);
    setPolicy({
      ...policy,
      orderLineRules: newRules,
    });
  }

  const hasError =
    orderLineSetLoadStatus === AsyncLoadStatus.Error ||
    orderLineSet?.status === BatchJobStatus.FAILED;

  const isLoading =
    orderLineSetLoadStatus !== AsyncLoadStatus.Ok ||
    orderLineSet?.status !== BatchJobStatus.READY;

  const policyOrderLinesTotalCountRaw = orderLinesCount;
  const policyOrderLinesTotalCount = formatInteger(orderLinesCount);
  const policyOrderLinesUnmatchedCountRaw =
    checkResult?.policy?.unmatchedOrderLines?.count;
  const policyOrderLinesUnmatchedCount = formatInteger(
    policyOrderLinesUnmatchedCountRaw ?? 0,
  );

  const isTableGenerating =
    orderLineSet?.status === BatchJobStatus.CREATED ||
    orderLineSet?.status === BatchJobStatus.CALCULATING;

  const progress = isTableGenerating
    ? Math.floor(Math.max(1, orderLineSet?.progress ?? 0))
    : null;
  const policyOrderLinesTotalMatched = policyOrderLinesUnmatchedCountRaw === 0;

  const hasRules = !_.isEmpty(policy?.orderLineRules);
  const policyRuleCounter = policy?.orderLineRules?.length;

  return (
    <>
      {/* <ContainerScroll className="flex flex-col"> */}
      <ScreenTitle
        title={t`Picking policy`}
        subtitle={t`Orders — Locations`}
        isSticky
        helpNavTo={'simulation/policies/policy-picking/policy-picking'}
        icon={Icon.PolicyAssignmentAgent}
      />

      <HelperContainer
        hidden
        hasPadding
        collapsible
        id={'helper-simulation-policy-storage'}
        showIcon={Icon.CircleHelp}
        hideIcon={Icon.ArrowRight}
      ></HelperContainer>

      {hasError && <ErrorIndicator message={t`Error`} />}

      {isLoading && !hasError && (
        <LoadingIndicator
          message={t`Building Policy Filters...`}
          progress={progress}
          selfCenter
        />
      )}
      {!isLoading && !hasError && (
        <>
          {canUpdate && <PickingPolicyWarnings />}

          <div className={classNames('z-1000 sticky top-0 flex-none')}>
            <Stat
              className={classNames(
                'backdrop-saturate-110 bg-opacity-50 backdrop-blur-lg backdrop-filter',
              )}
              title={
                !policyOrderLinesTotalMatched
                  ? t`Order Lines
                    (Unmatched of total)`
                  : t`Order Lines`
              }
              isSectionable
              valueTotal={
                !policyOrderLinesTotalMatched
                  ? policyOrderLinesTotalCount
                  : null
              }
              value={
                !policyOrderLinesTotalMatched
                  ? policyOrderLinesUnmatchedCount
                  : t(`All Matched ({{policyOrderLinesTotalCount}})`, {
                      policyOrderLinesTotalCount: policyOrderLinesTotalCount,
                    })
              }
              icon={
                !policyOrderLinesTotalMatched
                  ? Icon.TriangleInfo
                  : Icon.CircleOk
              }
              inPanelMode
              isActionable
              inFilterStat
              toggleTable
              isCheckLoading={
                !hasRules ? false : checkResultStatus !== AsyncLoadStatus.Ok
              }
              isSelected={selectedDetails?.showOrderLinesUnmatched}
              onClick={() => {
                setSelectedDetails(
                  selectedDetails?.showOrderLinesUnmatched
                    ? null
                    : {
                        showOrderLinesUnmatched: true,
                      },
                );
              }}
            />
          </div>
          <TitleSection
            title={
              <PolicyRuleSectionTitle
                isFeasible={!hasError}
                counter={policyRuleCounter}
              />
            }
            inPanelView
            className={classNames(
              'z-400 top-[5rem] md:top-[3rem] lg:top-[3.5rem] xl:top-[5.25rem]',
            )}
            hasScreenTitle
            hasAction={
              canUpdate ? (
                hasRules && (
                  <Button
                    label={t`Add Policy`}
                    className={classNames('rounded ltr:ml-4 rtl:mr-4')}
                    buttonSize="xs"
                    buttonType="primary"
                    hasIconAfter={
                      <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                    }
                    onPress={() => addRule()}
                  />
                )
              ) : (
                <StatusTag
                  title={t`Policy Locked`}
                  type="locked"
                  modeStyle="stroke"
                  icon={Icon.Lock}
                  onPress={null}
                />
              )
            }
          />

          <PolicyWell
            data-component="PolicyWellRulePicking"
            fullHeight
            isDisabled={!canUpdate}
            className={classNames('mb-2 flex-1')}
            classNameChildren={classNames('space-y-2 pb-10')}
          >
            {_.map(policy?.orderLineRules, (rule, index) => {
              return (
                <PickingPolicyRule
                  isDisabled={!canUpdate}
                  key={`PickingPolicyRule-${rule.id}-${index}`}
                  ruleId={rule.id}
                  index={index + 1}
                  canDelete={true}
                  canRename={true}
                  onDeleteClick={() => removeRule(rule.id)}
                  canMoveUp={index > 0}
                  onMoveUp={() => moveRule(index, index - 1)}
                  canMoveDown={index < policy.orderLineRules.length - 1}
                  onMoveDown={() => moveRule(index, index + 1)}
                  isActive={selectedIdentity?.ruleId === rule.id}
                  hasIssue={!isFeasible}
                />
              );
            })}

            {(policy?.orderLineRules === null ||
              policy?.orderLineRules.length === 0) && (
              <InboxZero selfCenter hasIcon message={t`No Rules found`}>
                {canUpdate && (
                  <Button
                    full
                    buttonSize="xs"
                    buttonType="primary"
                    className="mt-3 flex-1 rounded"
                    label={t`Add New Policy`}
                    onPress={addRule}
                    hasIconAfter
                    buttonIcon={
                      <Icon.CirclePlus
                        className={classNames('h-5 w-5 fill-current')}
                      />
                    }
                  />
                )}
              </InboxZero>
            )}
          </PolicyWell>
        </>
      )}
      {/* </ContainerScroll>
      <ActionBarNextStep /> */}
    </>
  );
};

export default PickingPolicy;
