import {
  AssignmentChangeItemMoveDetailsFragment,
  LoadOptimizationMovesQuery,
  LoadOptimizationMovesQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  getCategoryString,
  ProductCategoryDescriptorExport,
} from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type OptimizationMovesExportJobParams = ExportJobParams<
  LoadOptimizationMovesQueryVariables,
  OptimizationMovesConverterConfig
>;

export const optimizationMovesExportJobParams: OptimizationMovesExportJobParams =
  {
    converterId: 'OPTIMIZATION_MOVES',
    query: null,
    config: null,
    variables: null,
    filename: 'optimization-moves.csv',
  };

export type OptimizationMovesDataRow =
  AssignmentChangeItemMoveDetailsFragment & {
    stepId: string;
    category: number;
    categorySting: string;
    spacer: string;
  };

export type OptimizationMovesDataColumn = keyof OptimizationMovesDataRow;

export type OptimizationMovesConverterConfig = {
  columnsConfig: ColumnConfigBase<OptimizationMovesDataRow>[];
  dictionaries: {
    categories: ProductCategoryDescriptorExport[];
  };
};

export const getOptimizationMovesTableRows = (
  data: AssignmentChangeItemMoveDetailsFragment[],
  config: OptimizationMovesConverterConfig,
): OptimizationMovesDataRow[] => {
  const getOptimizationMovesDataRow = (
    row: AssignmentChangeItemMoveDetailsFragment,
  ) => {
    //const rank = row.productDetails?.cumulativePercentRank;
    const rank = 0;
    return {
      ...row,
      stepId: `${row.groupId}.${row.chainNumber + 1}.${row.sequenceNumber + 1}`,
      spacer: '',
      category: rank,
      categorySting: getCategoryString(rank, config.dictionaries.categories)
        ?.tag,
    } as OptimizationMovesDataRow;
  };

  return _.map(data, getOptimizationMovesDataRow);
};

export const OPTIMIZATION_MOVES_EXPORT_CONVERTER: ExportConverter<
  LoadOptimizationMovesQuery,
  OptimizationMovesConverterConfig
> = (input, config) => {
  const data = getOptimizationMovesTableRows(
    input.optimizationRun?.assignmentChangeSequence?.moves,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
