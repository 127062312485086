import {
  AnalyzeOrderStatus,
  AnalyzeResultFragment,
  AnalyzeResultOrderSummaryFragment,
  MeasurementSystem,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import handleViewport from 'react-in-viewport';
import { useRecoilValue } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import InboxZero from '../../components/InboxZero';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListHeader } from '../../components/stats/StatListHeader';
import {
  StatListItem,
  StatListItemProps,
} from '../../components/stats/StatListItem';
import {
  warehouseMeasurementSystem,
  warehouseSelected,
} from '../../store/warehouse.state';
import {
  KPI,
  getFormattedKPIValue,
  getKPITitles,
} from '../store/simulation.helper';
import {
  AnalyzeOrdersListState,
  OrderSearchFieldId,
} from '../store/simulation.types';

export function getOrderStatusOptions(
  summary: AnalyzeResultFragment,
  t: TFunction<'simulation'>,
): {
  key: AnalyzeOrderStatus;
  title?: string;
  label: string;
  total?: string;
  icon?: React.ReactNode;
}[] {
  return [
    {
      key: null,
      title: t('All'),
      label: t('All ({{total}})', {
        total: formatInteger(summary?.totalOrders ?? 0),
      }),
      total: formatInteger(summary?.totalOrders ?? 0),
      icon: t`All`,
    },
    {
      key: AnalyzeOrderStatus.ASSEMBLED,
      title: t('Picked'),
      label: t('Picked ({{total}})', {
        total: formatInteger(summary?.totalAssembledOrders ?? 0),
      }),
      total: formatInteger(summary?.totalAssembledOrders ?? 0),
      icon: t`Picked`,
    },

    {
      key: AnalyzeOrderStatus.ASSEMBLED_PARTIALLY,
      title: t('Partial'),
      label: t('Partial ({{total}})', {
        total: formatInteger(summary?.totalAssembledPartiallyOrders ?? 0),
      }),
      total: formatInteger(summary?.totalAssembledPartiallyOrders ?? 0),
      icon: t`Partial`,
    },
    {
      key: AnalyzeOrderStatus.IGNORED,
      title: t('Unpickable'),
      label: t('Unpickable ({{total}})', {
        total: formatInteger(
          (summary?.totalOrders ?? 0) -
            (summary?.totalAssembledPartiallyOrders ?? 0) -
            (summary?.totalAssembledOrders ?? 0),
        ),
      }),
      total: formatInteger(
        (summary?.totalOrders ?? 0) -
          (summary?.totalAssembledPartiallyOrders ?? 0) -
          (summary?.totalAssembledOrders ?? 0),
      ),
      icon: t`Unpickable`,
    },
  ];
}

export type AnalyzedOrdersListProps = {
  summary: AnalyzeResultFragment;
  orders: AnalyzeResultOrderSummaryFragment[];
  orderCompare?: AnalyzeResultOrderSummaryFragment[];
  selectedOrderId: string;
  onSelect: (orderId: string) => void;

  state: AnalyzeOrdersListState;
  onStateChanged: (state: AnalyzeOrdersListState) => void;

  onLoadNext: () => void;
  selectedKPI: KPI;
  isLoading: boolean;
};

const AnalyzedOrdersList: React.FC<AnalyzedOrdersListProps> = props => {
  const { t } = useTranslation('simulation');
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const wh = useRecoilValue(warehouseSelected);

  const formatter = useFormatter();
  const [localState, setLocalState] = useState<AnalyzeOrdersListState>(
    props.state,
  );

  const [showAdvanced, setShowAdvanced] = useState<boolean>(true);

  useEffect(() => {
    setLocalState(props.state);
  }, [props.state]);

  const debouncedChangeState = useCallback(
    _.debounce(state => props.onStateChanged(state), 500),
    [props.onStateChanged],
  );

  function onSearch(field: OrderSearchFieldId, value: string) {
    const newState: AnalyzeOrdersListState = {
      ...localState,
      searchValues: { ...localState.searchValues, [field]: value },
    };
    setLocalState(newState);
    debouncedChangeState(newState);
  }

  function onKPIChange(newKPI: KPI) {
    const newState: AnalyzeOrdersListState = { ...localState, sortBy: newKPI };
    setLocalState(newState);
    props.onStateChanged(newState);
  }

  function onStatusFilterChanged(status: AnalyzeOrderStatus) {
    const newState: AnalyzeOrdersListState = { ...localState, status: status };
    setLocalState(newState);
    props.onStateChanged(newState);
  }

  const KPIs: KPI[] = ['cost', 'distance', 'duration'];
  const selectedKPI = localState.sortBy;

  const compareWith = _.keyBy(
    props.orderCompare,
    orderSummary => orderSummary.order.id,
  );

  const drawOrders = () => {
    return props.orders.map(orderResults => {
      const formattedKPI = getFormattedKPIValue(
        orderResults,
        selectedKPI,
        formatter,
      );
      const orderToCompare = compareWith[orderResults.order.id];

      const totalLinesRaw = orderResults.totalLines ?? 0;
      const totalLines = formatInteger(totalLinesRaw);

      const assembledLinesRaw = orderResults.assembledLines ?? 0;
      const assembledLines = formatInteger(assembledLinesRaw);

      const partiallyLinesRaw = orderResults.assembledPartiallyLines ?? 0;
      const partiallyLines = formatInteger(partiallyLinesRaw);

      const ignoredLinesRaw = orderResults.ignoredLines ?? 0;
      const ignoredLines = formatInteger(ignoredLinesRaw);

      const dataProps: Partial<StatListItemProps> = orderToCompare
        ? {
            isComparable: true,
            valueAfter: formattedKPI,
            valueBefore: getFormattedKPIValue(
              orderToCompare,
              selectedKPI,
              formatter,
            ),
          }
        : { value: formattedKPI.raw };

      const title = `${orderResults.order.orderId}`;

      const isOrderSelected = orderResults.order.id === props.selectedOrderId;

      return (
        <li
          key={`order-li-${orderResults.order.orderId}`}
          onClick={() => {
            props.onSelect(orderResults.id);
          }}
        >
          <StatListItem
            titleSelectable
            key={`order-${orderResults.order.orderId}`}
            isActionable
            truncate
            summaryView
            titleTrace={`Order: ${title}`}
            title={
              <div className="flex items-center flex-1 ltr:pr-2 rtl:pl-2 truncate">
                <span
                  className={classNames('ltr:mr-2 rtl:ml-2  truncate', {
                    'flex-1': !showAdvanced,
                  })}
                >
                  {title}
                </span>

                {showAdvanced ? (
                  <span className="space-x-2 text-minimal xl:text-xs flex items-center">
                    {partiallyLinesRaw > 0 && (
                      <span
                        data-component="LinesCounter"
                        title={t`Partial Lines`}
                        className={classNames(
                          'border p-0.5 xl:px-1 xl:py-0.5 rounded xl:min-w-8',
                          isOrderSelected
                            ? 'border-app-panel-dark'
                            : 'border-picklistLines-partial text-picklistLines-partial',
                        )}
                      >
                        {partiallyLines}
                      </span>
                    )}
                    {ignoredLinesRaw > 0 && (
                      <span
                        data-component="LinesCounter"
                        title={t`Unpickable Lines`}
                        className={classNames(
                          'border p-0.5 xl:px-1 xl:py-0.5 rounded xl:min-w-8',
                          isOrderSelected
                            ? 'border-app-panel-dark'
                            : 'border-picklistLines-ignored text-picklistLines-ignored',
                        )}
                      >
                        {ignoredLines}
                      </span>
                    )}
                    {/* <span className="bg-route-line_full text-menu-text px-2 py-1 rounded">
                      {assembledLines}
                    </span> */}
                  </span>
                ) : (
                  (partiallyLinesRaw > 0 || ignoredLinesRaw > 0) && (
                    <span
                      className={classNames('ltr:ml-2 trl:mr-2')}
                      title={`Picked Lines: ${assembledLines}\n Unpickable Lines: ${ignoredLines}\nPartial Lines: ${partiallyLines}\n—————————————————\n Total: ${totalLines}`}
                    >
                      <Icon.TriangleInfo
                        className={classNames(
                          'h-3 w-3 fill-current opacity-50',
                        )}
                      />
                    </span>
                  )
                )}
              </div>
            }
            {...dataProps}
            unitOfMeasure={formattedKPI.unit}
            isUnitPrefix={formattedKPI.prefixUnit}
            isSelected={isOrderSelected}
          />
        </li>
      );
    });
  };

  const ViewportBlock = handleViewport(
    ({ forwardedRef }) => (
      <div ref={forwardedRef as React.RefObject<HTMLDivElement>}></div>
    ) /** options: {}, config: {} **/,
  );

  const loadedCount = props.orders?.length ?? 0;
  const totalCount = props.state?.totalCount ?? 0;
  const hasOrderFilter = !_.isEmpty(props.state.searchValues?.orderId); //

  const loadedCountFormatted = formatInteger(loadedCount);
  const totalCountFormatted = formatInteger(totalCount);

  const noOrdersFound = loadedCount === 0;

  const filterPostfix = hasOrderFilter ? t`(Filtered)` : '';
  const statCounter = t(
    `{{loadedCountFormatted}} of {{totalCountFormatted}} {{filterPostfix}}`,
    {
      kpi: getKPITitles(selectedKPI, t),
      loadedCountFormatted,
      totalCountFormatted,
      filterPostfix,
    },
  );

  const systemDistance =
    wh.measurementSystem === MeasurementSystem.IMPERIAL ? t`mi` : t`km`;

  const systemCurrency =
    wh.currency === 'USD'
      ? `$`
      : wh.currency === 'GBP'
        ? `£`
        : wh.currency === 'EUR'
          ? `€`
          : `$`;

  const statTitle = t(`By {{kpi}}`, {
    kpi: getKPITitles(selectedKPI, t),
  });

  const kpiOptions = [
    {
      label: t`Cost`,
      icon: systemCurrency,
    },
    {
      label: t`Distance`,
      icon: systemDistance,
    },
    {
      label: t`Time`,
      icon: 'h',
    },
  ];

  const orderStatusOptions = getOrderStatusOptions(props.summary, t);
  const orderStatuses = orderStatusOptions.map(os => os.key);
  const selectedOrderStatus = localState.status ?? null;

  return (
    <StatGroup
      title={t`Orders`}
      subTitle={t`Stats`}
      helpNavTo={'simulation/analyse/simulation-analyse'}
      titleAction={
        <DropdownSelector
          // className="text-xxs"
          classNameLabel="text-sm"
          // classNameItem="text-xs"
          onClick={e => {
            e.stopPropagation();
          }}
          DropAlignRight
          buttonTransparent
          vertical
          panelMode
          value={'...'}
          values={[showAdvanced ? t`Hide Options...` : t`Show More Options`]} // values={options}
          onChange={async (option, e) => {
            setShowAdvanced(!showAdvanced);
          }}
        />
      }
      // isPrimaryResult
    >
      {showAdvanced && (
        <div className={classNames('bg-app-panel-stat p-2 lg:p-4 w-full')}>
          {/* <div className="text-xs mb-2 text-menu-text uppercase">{t`Filters & Options`}</div> */}
          <ButtonSwitchMulti
            autoSize
            // buttonType="secondary"
            buttonType="minimal"
            className={classNames('w-full truncate mb-1')}
            options={orderStatusOptions}
            selectedIndex={orderStatuses.indexOf(selectedOrderStatus) ?? 0}
            onClick={index => {
              onStatusFilterChanged(orderStatuses[index]);
            }}
          />
          <Stat
            hasHelper
            isPreview
            title={`${
              orderStatuses.indexOf(selectedOrderStatus) === 0
                ? orderStatusOptions[0].title
                : orderStatuses.indexOf(selectedOrderStatus) === 1
                  ? orderStatusOptions[1].title
                  : orderStatuses.indexOf(selectedOrderStatus) === 2
                    ? orderStatusOptions[2].title
                    : orderStatuses.indexOf(selectedOrderStatus) === 3
                      ? orderStatusOptions[3].title
                      : null
            }`}
            value={`${
              orderStatuses.indexOf(selectedOrderStatus) === 0
                ? orderStatusOptions[0].total
                : orderStatuses.indexOf(selectedOrderStatus) === 1
                  ? orderStatusOptions[1].total
                  : orderStatuses.indexOf(selectedOrderStatus) === 2
                    ? orderStatusOptions[2].total
                    : orderStatuses.indexOf(selectedOrderStatus) === 3
                      ? orderStatusOptions[3].total
                      : null
            }`}
            inPanelMode
          >
            {orderStatuses.indexOf(selectedOrderStatus) === 0 ? (
              <>
                <StatListItem
                  title={`${orderStatusOptions[1].title}`}
                  value={`${orderStatusOptions[1].total}`}
                />
                <StatListItem
                  title={`${orderStatusOptions[2].title}`}
                  value={`${orderStatusOptions[2].total}`}
                />
                <StatListItem
                  title={`${orderStatusOptions[3].title}`}
                  value={`${orderStatusOptions[3].total}`}
                />
              </>
            ) : null}
          </Stat>
        </div>
      )}

      <Stat
        hasHelper
        isPreview
        title={statTitle}
        counter={statCounter}
        isFullWidth
        inPanelMode
        isLoading={props.isLoading}
        hasListDecimals
        switchUnitOfMeasure={
          <ButtonSwitchMulti
            autoSize
            options={kpiOptions}
            selectedIndex={KPIs.indexOf(selectedKPI)}
            onClick={index => {
              onKPIChange(KPIs[index]);
            }}
          />
        }
      >
        {/* <div className="mb-2 mt-4 -mx-4">
          <ButtonSwitchMulti
            autoSize
            // buttonType="secondary"
            buttonType="minimal"
            className="text-xs"
            options={orderStatusOptions}
            selectedIndex={orderStatuses.indexOf(selectedOrderStatus) ?? 0}
            onClick={index => {
              onStatusFilterChanged(orderStatuses[index]);
            }}
          />
        </div> */}
        <StatListHeader
          hasSearch
          title={t`Order Number`}
          value={getKPITitles(selectedKPI, t)}
          searchValue={localState.searchValues?.['orderId']}
          onSearch={v => onSearch('orderId', v)}
        />
        {/* {props.isLoading && (
          <LoadingIndicator absolute selfCenter message={t`Loading`} />
        )} */}
        {drawOrders()}
        {!props.isLoading && noOrdersFound && (
          <InboxZero
            selfCenter
            message={t`No orders found`}
            // className={'min-h-1/2'}
          />
        )}
      </Stat>
      <ViewportBlock
        onEnterViewport={() => {
          if (
            !props.isLoading &&
            props.state?.totalCount > props.orders.length
          ) {
            props.onLoadNext();
          }
        }}
      />
    </StatGroup>
  );
};

export default AnalyzedOrdersList;
