const ObjectBoxSideYIcon = props => (
  <svg
    aria-hidden="true"
    data-name="ObjectBoxSideYIcon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon points="120.72 42.07 120.72 34.29 111.57 25.14 102.41 34.29 102.41 42.07 108.57 35.91 108.57 53.67 102.41 47.51 102.41 55.29 111.57 64.44 120.72 55.29 120.72 47.51 114.57 53.67 114.57 35.91 120.72 42.07" />
    <polygon
      fill="none"
      points="99.5 73.15 51.57 95.77 51.57 44.9 99.29 25.57 99.5 73.15"
      opacity={0.2}
    />
    <polygon
      fill="none"
      points="51.57 95.77 3.76 70.44 3.76 22.65 51.57 44.9 51.57 95.77"
      opacity={0.3}
    />
    <polygon
      fill="none"
      points="51.57 44.9 3.77 22.55 51.57 5.38 99.29 25.57 51.57 44.9"
      opacity={0.1}
    />
    <polygon points="53.77 45.37 98.37 27.31 98.56 71.5 53.77 92.64 53.77 45.37" />
    <polygon points="4.89 68.8 4.89 24.5 49.56 45.29 49.56 92.47 4.89 68.8" />
    <polygon points="8.31 21.36 51.6 5.8 94.96 24.15 51.73 41.66 8.31 21.36" />
    <g>
      <path d="m51.57,94.26c-.83,0-1.51.68-1.51,1.51v25.07c0,.83.68,1.51,1.51,1.51s1.51-.68,1.51-1.51v-25.07c0-.83-.68-1.51-1.51-1.51Z" />
      <path d="m6.79,71.74c-.83,0-1.51.68-1.51,1.51v22.69c0,.83.68,1.51,1.51,1.51s1.51-.68,1.51-1.51v-22.69c0-.83-.68-1.51-1.51-1.51Z" />
      <path d="m113.28,66.66c-.25-.53-.79-.87-1.37-.87-.22,0-.44.05-.64.14l-12.33,5.8c-.41.15-.72.48-.87.89,0,0,0,0,0,0,0,.02,0,.03-.01.05-.02.07-.04.13-.05.2,0,.05-.01.11-.02.16,0,.03,0,.06,0,.09l-.12,24.13c0,.83.67,1.52,1.51,1.52h0c.83,0,1.51-.68,1.51-1.51l.12-23.17,11.55-5.43c.37-.17.64-.48.78-.86.14-.38.12-.79-.06-1.16Z" />
      <path d="m99.5,27.08c.22,0,.45-.05.64-.14l12.42-5.84c.75-.36,1.08-1.26.72-2.02-.25-.53-.79-.87-1.37-.87-.22,0-.44.05-.64.14l-12.42,5.84c-.37.17-.64.48-.78.86-.14.38-.12.79.06,1.16.25.53.79.87,1.37.87Z" />
    </g>
  </svg>
);
export default ObjectBoxSideYIcon;
