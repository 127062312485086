import * as React from 'react';
const PalletIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,108.11v-2.47H4v2.47h6.17v8.12h-6.17v2.47h120v-2.47h-6.17v-8.12h6.17ZM19.63,108.11h39.64v8.12H19.63v-8.12ZM68.73,116.24v-8.12h39.64v8.12h-39.64Z"
    />
    <path strokeWidth={0} d="M4,57.52v46.27h53.06v-46.27H4Z" />
    <path
      strokeWidth={0}
      d="M59.22,57.52v46.27h64.78v-46.27H59.22ZM118.52,93.41v8.12h-4.18v-8.12h-3.11l5.2-7.42,5.2,7.42h-3.11Z"
    />
    <path
      strokeWidth={0}
      d="M4,9.3v46.27h87.61V9.3H4ZM20.85,31.68c0,.65-2.23,1.18-4.99,1.18s-4.99-.53-4.99-1.18c0-.61,1.92-1.11,4.39-1.18v-6.77c-2.89-.44-5.14-4.06-5.14-8.45h6.44l-.44,2.41-.28,1.54,1.12-.9c.19-.16.39-.31.58-.46l-.9,3.54,1.97-4.48.4-.91-.96.49c-.16.08-.31.16-.47.24l.52-1.46h3.46c0,4.39-2.25,8.01-5.14,8.45v6.77c2.48.07,4.4.57,4.4,1.18ZM85.34,45.39v8.12h-4.18v-8.12h-3.11l5.2-7.42,5.2,7.42h-3.11Z"
    />
  </svg>
);
export default PalletIcon;
