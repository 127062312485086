import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import BayLocationLayer from '../../../layout/bayViewer/layers/BayLocationLayer';
import { getSizeColorByStatus } from '../../store/sizeCompliance.helper';
import { sizeComplianceBayLocation } from '../../store/sizeCompliance.state';

const SizeComplianceBayLocationLayer: React.FC = () => {
  const locationLoadable = useRecoilValueLoadable(sizeComplianceBayLocation);

  if (locationLoadable.state !== 'hasValue') return null;

  const sizeByLocationMap = locationLoadable.contents;

  return (
    <BayLocationLayer
      stageId="simulation-bay-view"
      getColor={loc => {
        const locSize = sizeByLocationMap?.[loc.locationId];
        return locSize ? getSizeColorByStatus(locSize?.status) : null;
      }}
    />
  );
};

export default SizeComplianceBayLocationLayer;
