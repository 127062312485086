import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SchemaHelper from '../SchemaHelper';
import { ImportMapPanel } from '../panels/ImportMapPanel';
import { getOrderSetSchema } from './schema/orderSetSchema';

const ImportOrderSetMapPanel: React.FC = () => {
  const { t } = useTranslation('importer');
  return (
    <PanelBody>
      <ScreenTitle
        isSticky
        title={t`Map Fields`}
        subtitle={t`Order set import`}
        hasStepCounter={'4.'}
      />
      <ImportMapPanel>
        <SchemaHelper schema={getOrderSetSchema(t)} />
      </ImportMapPanel>
    </PanelBody>
  );
};

export default ImportOrderSetMapPanel;
