import {
  LoadWarehouseFilesDocument,
  LoadWarehouseFilesQuery,
  LoadWarehouseFilesQueryVariables,
  StoredFileFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { atom, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { AsyncLoadStatus } from '../../common/types';
import {
  UploadChunkStatus,
  UploadStatus,
} from '../../import/store/import.types';
import { warehouseSelectedId } from '../../store/warehouse.state';

const getKey = (postfix: string) => `warebee-warehouse-files-${postfix}`;

export const warehouseFilesSeed = atom<string>({
  key: getKey('seed'),
  default: nanoid(),
});

export const warehouseFilesUploadStatus = atom<AsyncLoadStatus>({
  key: getKey('upload-status'),
  default: AsyncLoadStatus.None,
});

export const warehouseFilesUploadChunkStatusAtom = atom<
  Record<string, UploadChunkStatus>
>({
  key: getKey('chunk-upload-status-atom'),
  default: {},
});

export const warehouseFilesUploadChunkStatus = selectorFamily<
  UploadChunkStatus,
  string
>({
  key: getKey('chun-upload-status'),
  get:
    key =>
    ({ get }) =>
      get(warehouseFilesUploadChunkStatusAtom)?.[key],
  set:
    key =>
    ({ get, set }, value) => {
      const current = get(warehouseFilesUploadChunkStatusAtom);
      set(warehouseFilesUploadChunkStatusAtom, {
        ...current,
        [key]: value,
      });
    },
});

export const warehouseFilesUploadProgress = selector<UploadStatus>({
  key: getKey('upload-progress-summary'),
  get: ({ get }) => {
    const allChunks = get(warehouseFilesUploadChunkStatusAtom);
    return _.reduce(
      _.values(allChunks),
      (acc, chunk) => ({
        jobId: chunk.jobId,
        status: Math.max(acc.status, chunk.status),
        loaded: acc.loaded + chunk.loaded,
        total: acc.total + chunk.total,
        errors: [...acc.errors, ...(chunk.errors ?? [])],
      }),
      {
        jobId: null,
        status: AsyncLoadStatus.None,
        loaded: 0,
        total: 0,
        errors: [],
      },
    );
  },
});

export const warehouseFilesData = selector<StoredFileFragment[]>({
  key: getKey('data'),
  get: async ({ get }) => {
    const seed = get(warehouseFilesSeed); // reference for updated
    const whId = get(warehouseSelectedId);
    try {
      const response = await secureClient.query<
        LoadWarehouseFilesQuery,
        LoadWarehouseFilesQueryVariables
      >({
        query: LoadWarehouseFilesDocument,
        variables: {
          whId,
        },
      });

      if (!_.isEmpty(response.errors)) {
        console.error("Cannot load plane's bays", response.errors);
        throw new Error("Cannot load plane's bays");
      }
      return response.data.warehouse?.files;
    } catch (ex) {
      console.error('Cannot load Warehouse Files', ex);
      throw new Error('Cannot load Warehouse Files');
    }
  },
});
