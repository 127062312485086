import * as React from 'react';
const EmailIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="EmailIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M4.24,25.79c.44-2.13,1.41-4.12,2.81-5.79,1.58-1.99,3.99-3.14,6.53-3.12h99.3c2.59-.09,5.13.76,7.15,2.39,2.02,1.63,3.38,3.94,3.82,6.49v.29c.48,3.97-.52,7.97-2.81,11.24-2.53,3.81-5.8,7.07-9.61,9.61-11.12,7.47-22.21,15.23-33.24,22.98-3.06,2.38-6.36,4.42-9.85,6.1-2.81,1.34-6.09,1.29-8.86-.14-3.05-1.61-6-3.4-8.84-5.36-9.45-6.4-18.84-12.93-28.19-19.57-4.33-2.67-8.36-5.8-12.01-9.34-2.67-2.62-4.68-5.84-5.86-9.39-.62-2.07-.75-4.26-.36-6.39h0ZM113.03,111.19H14.89c-2.91-.02-5.7-1.19-7.74-3.27-2.04-2.07-3.18-4.87-3.16-7.78v-53.17c4.92,5.45,11.02,8.67,16.59,12.58,9.49,6.72,19.02,13.35,28.53,20.05,2.95,2.07,6.18,3.69,9.61,4.8,3.49,1.09,7.25,1.04,10.71-.14,3.7-1.23,7.18-3.04,10.33-5.36,12.34-8.65,24.78-17.2,37.13-25.86,2.4-1.71,4.61-3.7,7.01-5.64v7.59c0,15.06-.12,30.11,0,45.15h0c-.04,2.88-1.19,5.64-3.21,7.7-2.02,2.06-4.76,3.26-7.64,3.35h0Z"
    />
  </svg>
);
export default EmailIcon;
