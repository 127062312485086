import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { StageType } from '../../layout/stage/stage.helper';
import { stageStateById } from '../../layout/stage/stage.state';
import {
  viewerAutoSizeId,
  viewerSelectedPlane,
} from '../../layout/viewer/store/viewer.state';

function useLayoutAreaWatcher(stageId: StageType) {
  const plane = useRecoilValue(viewerSelectedPlane);
  const setStageSize = useSetRecoilState(stageStateById(stageId));
  const autoSizeId = useRecoilValue(viewerAutoSizeId);

  useEffect(() => {
    if (_.isNil(plane)) return;
    const box = plane.boundingBox;
    setStageSize({
      autoSizeId: autoSizeId ?? plane.id,
      contentBounds: [
        [box[0], box[1]],
        [box[2], box[3]],
      ],
    });
  }, [plane, setStageSize, autoSizeId]);

  return null;
}

export default useLayoutAreaWatcher;
