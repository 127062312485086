import * as React from 'react';
const CursorZoomFitBoxIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorZoomFitBoxIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M113.45,112.61l-29.13-30.3c7.43-7.45,12.04-17.73,12.04-29.06,0-22.71-18.47-41.18-41.18-41.18S14,30.54,14,53.25s18.47,41.18,41.18,41.18c9.97,0,19.11-3.56,26.24-9.48l29.21,30.37c.38.4.9.6,1.41.6s.98-.18,1.36-.55c.78-.75.81-1.99.05-2.77ZM73.21,34.7c.11-.77.82-1.31,1.59-1.2.77.11,1.31.83,1.2,1.6l-.39,2.8,5.46-5.48c.55-.55,1.44-.55,1.99,0,.55.55.55,1.44,0,2l-5.46,5.48,2.79-.4c.77-.11,1.49.43,1.6,1.2.11.77-.42,1.49-1.19,1.6l-6.97,1c-.07,0-.13.01-.2.01-.18,0-.36-.04-.53-.11s-.33-.17-.46-.31c-.15-.15-.25-.33-.32-.52-.02-.06-.02-.11-.03-.17-.03-.14-.05-.28-.04-.42,0-.03-.01-.06,0-.09l.98-6.99ZM27.52,32.42c.55-.55,1.44-.55,1.99,0l5.48,5.48-.4-2.8c-.11-.77.42-1.49,1.19-1.6.77-.12,1.49.43,1.6,1.2l1.01,6.99s-.01.06,0,.09c.01.14,0,.28-.04.42-.01.06-.01.12-.03.17-.07.19-.17.37-.32.52-.13.13-.29.24-.46.31-.17.07-.35.11-.53.11-.07,0-.13,0-.2-.01l-6.98-1c-.77-.11-1.31-.83-1.2-1.6.11-.77.82-1.3,1.59-1.2l2.79.4-5.48-5.48c-.55-.55-.55-1.44,0-2ZM37.46,72.05c-.1.7-.7,1.21-1.39,1.21-.07,0-.13,0-.2-.01-.77-.11-1.31-.83-1.2-1.6l.39-2.79-5.46,5.48c-.28.28-.64.41-1,.41s-.72-.14-1-.41c-.55-.55-.55-1.44,0-2l5.46-5.48-2.79.4c-.78.11-1.49-.43-1.6-1.2-.11-.77.42-1.49,1.19-1.6l6.97-1c.05,0,.1,0,.15,0,.03,0,.06,0,.08,0,.16,0,.32.03.47.09.02,0,.04.02.06.03.16.07.31.16.43.29.15.15.25.33.32.52.02.06.02.11.03.17.03.14.05.28.04.42,0,.03.01.06,0,.09l-.98,6.99ZM70.87,63.45h-31c-.78,0-1.41-.63-1.41-1.41l-.06-17.33c0-.78.63-1.41,1.41-1.41h31c.78,0,1.41.63,1.41,1.41l.06,17.33c0,.78-.63,1.41-1.41,1.41ZM83.16,74.33c-.27.28-.63.41-1,.41s-.72-.14-1-.41l-5.48-5.48.4,2.79c.11.77-.42,1.49-1.19,1.6-.07,0-.13.01-.2.01-.69,0-1.29-.51-1.4-1.21l-1.01-6.99s.01-.06,0-.09c-.01-.14,0-.28.04-.42.01-.06.01-.12.03-.17.07-.19.17-.37.32-.52.12-.12.27-.22.43-.29.02,0,.04-.02.06-.03.15-.06.3-.09.47-.09.03,0,.06,0,.09,0,.05,0,.1,0,.15,0l6.98,1c.77.11,1.31.83,1.2,1.6-.11.77-.82,1.32-1.59,1.2l-2.79-.4,5.48,5.48c.55.55.55,1.44,0,2Z"
    />
  </svg>
);
export default CursorZoomFitBoxIcon;
