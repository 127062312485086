import * as React from 'react';
const ObjectWidthIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectWidthIcon"
    {...props}
  >
    <rect width={6.57} height={53.21} x={1} y={37.39} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="25.34 45.79 7.18 64 25.38 82.21 36.14 82.21 21.76 67.82 56.93 67.81 56.92 60.19 21.75 60.19 36.11 45.79 25.34 45.79"
    />
    <rect width={6.57} height={53.21} x={120.43} y={37.39} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="102.66 82.21 120.82 64 102.62 45.79 91.86 45.79 106.25 60.18 71.07 60.19 71.08 67.81 106.25 67.81 91.89 82.21 102.66 82.21"
    />
  </svg>
);
export default ObjectWidthIcon;
