import _ from 'lodash';
import { AgentEventSettingsDefinition } from '../../agentData/agent.types';
import defaultsPickerVoice from './eventsDefault-default-picker-voice.json';
import defaultsPoweredElectric from './eventsDefault-default-powered-electric.json';
import defaults from './eventsDefault-default.json';
import defaultsPowerJack from './eventsDefault-pallet-jack-manual.json';

const agentEventSettings: AgentEventSettingsDefinition[] = [
  defaults,
  defaultsPoweredElectric,
  defaultsPickerVoice,
  ...defaultsPowerJack,
];

//dictionary
export const agentEventSettingsMap = _.keyBy(
  agentEventSettings,
  settings => settings.id,
);
