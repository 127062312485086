import * as React from 'react';
const Mhe4APalletTruckIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe4APalletTruckIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M102.02,78.13c.08.89.48,4.56,1.21,11.02l1.21,11.02,21.07-2.3-5.21,2.91-15.5,1.82h0l-5.93.61-2.79-24.46,5.93-.61ZM67.02,108.4c.24,5.57,2.56,9.63,6.96,12.17,4.4,2.54,9.1,2.56,14.11.06,2.18-1.29,3.9-3.01,5.15-5.15,1.25-2.14,1.88-4.5,1.88-7.08-.24-5.57-2.56-9.63-6.96-12.17-4.4-2.54-9.1-2.56-14.11-.06-2.18,1.29-3.9,3.01-5.15,5.15-1.25,2.14-1.88,4.5-1.88,7.08ZM1.51,112.03c.24,4.12,1.98,7.1,5.21,8.96,3.23,1.86,6.7,1.86,10.41,0,1.61-.97,2.89-2.24,3.81-3.81.93-1.57,1.39-3.29,1.39-5.15-.24-4.2-1.98-7.23-5.21-9.08-3.23-1.86-6.7-1.86-10.41,0-1.61.97-2.89,2.24-3.81,3.81-.93,1.57-1.39,3.33-1.39,5.27ZM24.64,108.64c-1.05-3.63-3.09-6.32-6.12-8.05s-6.32-2.2-9.87-1.39c-.73.24-1.49.52-2.3.85v-17.07c.08-1.13.44-2.08,1.09-2.85.65-.77,1.53-1.23,2.66-1.39l4-.61c2.34-.32,4.36-1.27,6.05-2.85s2.83-3.53,3.39-5.87l1.57-7.14c.32-1.37,1.09-2.5,2.3-3.39l3.03-1.94c1.13-.73,2.42-1.13,3.88-1.21h26.28v.97h16.59l1.21-.12c.57-.08,1.03-.34,1.39-.79s.5-.95.42-1.51l-.73-7.02,10.29-1.21,6.3,55.1c-1.29-2.66-3.13-4.82-5.51-6.48-2.38-1.65-5.07-2.6-8.05-2.85l1.7-3.03-2.66-23.37c-.16-.97-.48-1.82-.97-2.54l-3.39-4.36h-16.59v.97h-26.04c-.73,0-1.31.24-1.76.73-.44.48-.67,1.05-.67,1.7h0v17.44h3.51v-15.5c0-.65.18-1.15.54-1.51s.87-.54,1.51-.54h25.91c1.21,0,2.24.36,3.09,1.09s1.39,1.65,1.63,2.79l5.21,27.85c-3.96,2.1-6.68,5.13-8.17,9.08-1.49,3.96-1.43,8.03.18,12.23H24.88c.4-2.1.32-4.16-.24-6.18h0Z"
    />
  </svg>
);
export default Mhe4APalletTruckIcon;
