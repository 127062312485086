import {
  AllocationRequirementSortBy,
  AllocationRequirementSortOption,
  AllocationRequirementsFilter,
  LoadAllocationRequirementItemsDocument,
  SortDirection,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AllocationRequirementConverterConfig,
  AllocationRequirementDataColumn,
  AllocationRequirementDataRow,
  AllocationRequirementExportJobParams,
  allocationRequirementExportJobParams,
  getAllocationRequirementTableRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import DatasetTable, { ColumnConfig } from '../components/DatasetTable';
import { Container } from '../components/layout/ContainerFlex';
import { getPolicyMatchInput } from '../policyFilters/policyFilter.helper';
import {
  allocationRequirement,
  allocationRequirementItems,
  allocationRequirementItemsLoadStatus,
  allocationRequirementTableState,
} from '../simulation/store/allocation/allocationRequirement.state';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../simulation/store/allocationPolicy/allocationPolicy.state';
import { pickingPolicy } from '../simulation/store/pickingPolicy/pickingPolicy.state';
import {
  simulationCurrentId,
  simulationUoms,
} from '../simulation/store/simulation.state';
import { getAllocationRequirementTableConfig } from './getAllocationRequirementTableConfig';
import useLoadAllocationRequirementItems from './useLoadAllocationRequirementItems';

const sortFieldsMapping: Partial<
  Record<AllocationRequirementDataColumn, AllocationRequirementSortOption>
> = {
  consignee: AllocationRequirementSortOption.CONSIGNEE,
  sku: AllocationRequirementSortOption.SKU,
  orderLineRuleId: AllocationRequirementSortOption.ORDER_LINE_RULE_ID,
  requiredQuantity: AllocationRequirementSortOption.REQUIRED_QUANTITY,
};

const AllocationRequirementTable: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrentId);
  const requirement = useRecoilValue(allocationRequirement);
  const policy = useRecoilValue(pickingPolicy);
  const data = useRecoilValue(allocationRequirementItems);
  const [loadCallback, cancelLoad] = useLoadAllocationRequirementItems();
  const loadStatus = useRecoilValue(allocationRequirementItemsLoadStatus);
  const [state, setState] = useRecoilState(allocationRequirementTableState);
  const allocationSelectedIdentity = useRecoilValue(
    allocationPolicySelectedIdentity,
  );
  const allocationSelectedRule = useRecoilValue(
    allocationPolicyRule(allocationSelectedIdentity?.ruleId),
  );
  const itemsMatch = getPolicyMatchInput(allocationSelectedRule?.itemsMatch);
  const [runExportCSV] = useRunExportJobMutation();
  const datasetUOMs = useRecoilValue(simulationUoms);

  const { searchValues, sortValues } = state;

  function getFilter(): AllocationRequirementsFilter {
    return {
      consigneeContains: searchValues?.consignee,
      skuContains: searchValues?.sku,
      skuGroupContains: searchValues?.skuGroup,
      itemsMatch: itemsMatch,
    };
  }

  function getSortBy(): AllocationRequirementSortBy[] {
    return _.map(sortValues, (value, key, index) => {
      const sortObjectKey = sortFieldsMapping[key];
      return sortObjectKey ? { field: sortObjectKey, direction: value } : null;
    }).filter(i => !!i);
  }

  function callDataLoad(
    searchValues: Partial<Record<AllocationRequirementDataColumn, string>>,
    sortValues: Partial<Record<AllocationRequirementDataColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: AllocationRequirementsFilter = getFilter();
    const sortBy: AllocationRequirementSortBy[] = getSortBy();
    cancelLoad();
    loadCallback({
      simulationId,
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues, allocationSelectedRule?.itemsMatch]);

  const rulesMap = _.reduce(
    policy?.orderLineRules,
    (acc, rule) => {
      return {
        ...acc,
        [rule.id]: rule.title,
      };
    },
    {},
  );

  const columnsConfig: ColumnConfig<AllocationRequirementDataRow>[] =
    getAllocationRequirementTableConfig(datasetUOMs, t);

  const converterConfig: AllocationRequirementConverterConfig = {
    columnsConfig,
    dictionaries: {
      rules: rulesMap,
    },
  };

  async function startExportCSV() {
    const variables: AllocationRequirementExportJobParams = {
      ...allocationRequirementExportJobParams,
      query: LoadAllocationRequirementItemsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        simulationId,
        //filter: getFilter(),
        sortBy: getSortBy(),
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = data?.content?.length || 0;
  const totalCount = data?.totalCount ?? 0;

  const flattenData = getAllocationRequirementTableRows(
    data?.content,
    converterConfig,
  );

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  return (
    <Container hasOverflowY col>
      <DatasetTable
        isActionable
        subtitle={t`Filtered Items for`}
        title={t`Allocation requirement`}
        isSticky
        id={'allocation-requirement-items-table'}
        columnsConfig={columnsConfig}
        keyFields={['itemId', 'orderLineRuleId']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        hasCounter
      />
    </Container>
  );
};

export default AllocationRequirementTable;
