import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import Callout from '../../helpContext/Callout';

export type ImportMapPanelProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ImportMapPanel = ({
  children,
  className,
}: ImportMapPanelProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('importer');

  return (
    <HelperMessage hasMargin hasPadding>
      <div>{t`Please map your csv fields to ours.`}</div>
      <div>{t`Once the fields are correctly mapped, please click upload.`}</div>
      <h3 className="mt-8 mb-2 text-xl text-menu-active">{t`Field Guide (Schema)`}</h3>
      <div>{t`Below the list of data fields with data types:`}</div>
      {children}

      <Callout type="suggestion">
        <p>{t`Need Assistance?`}</p>
        {t`Are you having trouble mapping the data? Let's chat.`}
      </Callout>
    </HelperMessage>
  );
};
