import { SpacerBuilderSettings } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import { Button } from '../../../../components/actions/Button';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import InputNumber from '../../../../components/inputs/InputNumber';
import { InputValue } from '../../../../components/inputs/InputValue';
import { ContainerScroll } from '../../../../components/layout/ContainerScroll';
import { Spacer } from '../../../../components/layout/Spacer';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import PanelContainer from '../../../../containers/PanelContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import { converterEditableArea } from '../store/converter.area.state';
import { converterLayoutSelectedAisle } from '../store/converter.layout.state';
import {
  converterAreaBuilder,
  converterNewSpacerType,
} from '../store/converter.state';
import { getSpacerTypeOptionMap } from '../store/converter.types';

const ConverterSpacerInfoPanel: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');

  const area = useRecoilValue(converterEditableArea);
  const wh = useRecoilValue(warehouseSelected);
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );
  const [aisle, setSelectedAisle] = useRecoilState(
    converterLayoutSelectedAisle,
  );
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );
  const setNewSpacerType = useSetRecoilState(converterNewSpacerType);

  if (!area || !aisle) return null;

  const spacerConfig = _.flatten(_.values(areaBuilder.spacers)).find(
    s => s.id === aisle.id,
  );

  if (!spacerConfig) return null;

  const spacerTypeOptions = getSpacerTypeOptionMap(t);

  function removeSpacer() {
    setSelectedAisle(null);
    setAreaBuilder({
      ...areaBuilder,
      spacers: {
        ...(areaBuilder.spacers || {}),
        [spacerConfig.bayId]: _.filter(
          areaBuilder.spacers[spacerConfig.bayId],
          s => s.id !== spacerConfig.id,
        ),
      },
    });
  }
  function applyChange(patch: Partial<SpacerBuilderSettings>) {
    setAreaBuilder({
      ...areaBuilder,
      spacers: {
        ...(areaBuilder.spacers || {}),
        [spacerConfig.bayId]: _.map(
          areaBuilder.spacers[spacerConfig.bayId],
          s => {
            if (s.id !== spacerConfig.id) return s;
            return {
              ...s,
              ...patch,
            };
          },
        ),
      },
    });
  }

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <WarehouseNavigator>
        <WarehouseNavigatorItem active name={t`Area`} value={area.id} />
        <WarehouseNavigatorItem
          name={spacerConfig.type === 0 ? t`Aisle` : t`Block`}
          value={aisle.title}
          active
          aria-label={aisle.title}
        />
      </WarehouseNavigator>
      <ContainerScroll>
        <PanelContainer
          id="panel-aisle-properties"
          title={
            spacerConfig.type === 0 ? t`Aisle Properties` : t`Block Properties`
          }
          // collapsible
          hasPadding
        >
          <InputValue
            className="flex-1"
            inputText
            key={`aisle-title-${aisle.id}`}
            title={t`Title`}
            icon={<Icon.ObjectAisleWidth className="w-8 h-8 fill-current" />}
            value={spacerConfig.title}
            onChange={v => applyChange({ title: v })}
          />
          <InputNumber
            key={`aisle-width-${aisle.id}`}
            title={t`Width`}
            unitOfMeasure={sizeMeasureValue}
            icon={<Icon.ObjectAisleWidth className="w-8 h-8 fill-current" />}
            value={spacerConfig.width}
            onChange={v => applyChange({ width: v })}
            range={[1, null]}
          />

          <DropdownSelector
            className="text-sm text-white"
            panelMode
            widthFull
            DropAlignRight
            label={t`Type`}
            value={spacerConfig.type}
            values={[...spacerTypeOptions.keys()]}
            renderValue={key => spacerTypeOptions.get(key)}
            onChange={v => {
              applyChange({ type: v });
              setNewSpacerType(v);
            }}
            icon={<Icon.DesignerBaysOrder className="w-8 h-8 fill-current" />}
          />

          <div className="flex items-center">
            <Spacer flexspace />
            <Button
              className="mt-4"
              buttonSize="xs"
              label={t`Remove`}
              hasIconAfter
              buttonIcon={<Icon.CircleX className={`w-5 h-5 fill-current`} />}
              buttonType="delete"
              onPress={removeSpacer}
            />
          </div>
        </PanelContainer>
      </ContainerScroll>
    </div>
  );
};

export default ConverterSpacerInfoPanel;
