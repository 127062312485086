import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../Tw';

type BatteryIndicatorProps = {
  percentage: number;
  isCharging?: boolean;
  className?: string;
};

const IndicatorEnergy: React.FC<BatteryIndicatorProps> = ({
  percentage,
  isCharging = false,
  className = '',
  ...props
}) => {
  const { t } = useTranslation('feed');

  const strengthColors = TwTheme.extend.colors.signal;

  const getBatteryStatusColor = (): string => {
    if (percentage >= 100) return strengthColors[90];
    if (percentage > 80) return strengthColors[80];
    if (percentage > 70) return strengthColors[60];
    if (percentage > 60) return strengthColors[50];
    if (percentage > 50) return strengthColors[50];
    if (percentage > 40) return strengthColors[40];
    if (percentage > 30) return strengthColors[30];
    if (percentage > 20) return strengthColors[20];
    return strengthColors[10];
  };

  const getBatteryStatusColorText = (): string => {
    if (percentage >= 100) return strengthColors.light;
    if (percentage > 80) return strengthColors.light;
    if (percentage > 70) return strengthColors.light;
    if (percentage > 50) return strengthColors[50];
    if (percentage > 30) return strengthColors[30];
    return strengthColors[10];
  };

  const batteryHeight = 28;
  const batteryWidth = batteryHeight * 2;

  return (
    <div
      title={`Energy Level: ${percentage}%`}
      className={classNames('relative', className)}
    >
      <svg
        viewBox="0 0 60 30"
        preserveAspectRatio="xMidYMid meet"
        aria-hidden="true"
        data-name="EnergyIndicator"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(
          'w-full h-full fill-current stroke-current',
          className,
        )}
        {...props}
      >
        <rect
          x="1"
          y="1"
          width={batteryWidth - 2}
          height={batteryHeight}
          rx="6"
          ry="6"
          stroke={getBatteryStatusColor()}
          strokeWidth="3"
          fill="none"
        />
        <rect
          x={batteryWidth + 1}
          y="10"
          width="6"
          height="10"
          fill={getBatteryStatusColor()}
        />
        {/* <rect
          x="3"
          y="3"
          width={`${Math.max(percentage, 0) - 16}%`}
          height="24"
          rx="4"
          ry="4"
          fill={getBatteryFill()}
          strokeWidth="0"
        /> */}
        {isCharging ? (
          <text
            x="24"
            y="15"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="12"
          >
            &#9889;
          </text>
        ) : (
          <>
            <text
              x="26"
              y="17"
              dominantBaseline="middle"
              textAnchor="middle"
              fill={getBatteryStatusColorText()}
              fontSize={percentage == 100 ? '16' : '18'}
            >
              {percentage}
            </text>
          </>
        )}
      </svg>
      {/* {!isCharging && (
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs">
          {percentage}%
        </span>
      )} */}
    </div>
  );
};

export default IndicatorEnergy;
