import * as React from 'react';
const CircleArrowLeftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowLeftIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,4C30.92,4,4,30.91,4,64s26.92,60,60,60,60-26.91,60-60S97.08,4,64,4ZM104.02,72.59h-47.2s23.59,23.61,23.59,23.61h-24.26S23.98,64,23.98,64L56.15,31.8h24.26s-23.59,23.61-23.59,23.61h47.2v17.18Z"
    />
  </svg>
);
export default CircleArrowLeftIcon;
