import {
  getStackingPriorityDetails,
  StackingPriority,
} from '@warebee/shared/engine-model';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../Tw';
import useFormatter from '../common/useFormatter';
import {
  stackingPolicyDocument,
  stackingPolicyIsDefined,
} from '../simulation/store/stackingPolicy/stackingPolicy.state';

const priorityColors = TwTheme.extend.colors.priority;
const priorityTextColors = TwTheme.extend.colors.priorityText;

type StackingPolicyCategoryTagProps = {
  stackingPriority: StackingPriority;
  isPrimary?: boolean;
};

const StackingPolicyCategoryTag: React.FC<
  StackingPolicyCategoryTagProps
> = props => {
  const { t } = useTranslation('simulation');
  const policy = useRecoilValue(stackingPolicyDocument);
  const isDefined = useRecoilValue(stackingPolicyIsDefined);
  const formatter = useFormatter();
  if (!isDefined || _.isNil(props.stackingPriority)) return null;

  const priorityRuleIndex = _.head(props.stackingPriority);
  let background = priorityColors.middle;
  let color = priorityTextColors.middle;
  if (priorityRuleIndex > 0) {
    background = priorityColors.bottom;
    color = priorityTextColors.bottom;
  }
  if (priorityRuleIndex < 0) {
    background = priorityColors.top;
    color = priorityTextColors.top;
  }

  const stackingDetails = getStackingPriorityDetails(
    policy,
    props.stackingPriority,
  );
  const defaultRuleTitle = t`Default (Middle)`;
  const ruleTitle = stackingDetails?.category?.rule?.title ?? defaultRuleTitle;
  const { from, to } = _.isNil(props.stackingPriority[1])
    ? { from: null, to: null }
    : stackingDetails.subcategory ?? {};

  const hasRange = !_.isNil(from) || !_.isNil(to);
  const fromSuffix = _.isNil(from)
    ? ''
    : t(`from: {{fromWeight}}`, {
        fromWeight: formatter.formatWeight(from).fullString,
      });
  const toSuffix = _.isNil(to)
    ? ''
    : t(`to: {{toWeight}}`, {
        toWeight: formatter.formatWeight(to).fullString,
      });
  const rangeSuffix = hasRange ? `(${fromSuffix} ${toSuffix})` : '';
  const title = `${ruleTitle} ${rangeSuffix}`;
  return (
    <span
      data-component="StackingPolicyCategoryTag"
      aria-label={`Stacking Policy Category`}
      className={classNames(
        'flex',
        'text-center px-2 py-0.5',
        'rounded text-xxs whitespace-nowrap m-0.5',
        'cursor-pointer',
        'w-full',
        'bg-app-panel-dark/60',
      )}
      style={{
        background: props.isPrimary ? background : null,
        color: props.isPrimary ? color : background,
        border: props.isPrimary ? '1px solid' : '1px solid',
      }}
    >
      {title}
    </span>
  );
};

export default StackingPolicyCategoryTag;
