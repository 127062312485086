import {
  LoadOrderedQuantityItemsDocument,
  OrderedQuantityReportItemFilter,
  OrderedQuantityReportItemSortBy,
  OrderedQuantityReportItemSortOption,
  OrderedQuantityReportItemStatus,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  OrderedItemsConverterConfig,
  OrderedItemsDataRow,
  OrderedItemsExportJobParams,
  getOrderedItemsTableRows,
  orderedItemsExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../../../common/filterHelper';
import { formatInteger } from '../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../common/types';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';
import { ContainerScroll } from '../../../components/layout/ContainerScroll';
import useLoadOrderedItems from '../../hooks/useLoadOrderedItems';
import { simulationIssueSelected } from '../../store/simulation.issues.state';
import { simulationCurrent } from '../../store/simulation.state';
import { getOrderedItemsTitlesMap } from '../../store/simulationReport.helper';
import {
  orderedQuantityItems,
  orderedQuantityItemsLoadStatus,
  orderedQuantityTableState,
} from '../../store/simulationReport.state';
import ItemTag from '../../tags/ItemTag';
import OrderedItemStatusTag from '../../tags/OrderedItemStatusTag';

const orderedItemsSortMap: Partial<
  Record<keyof OrderedItemsDataRow, OrderedQuantityReportItemSortOption>
> = {
  consignee: OrderedQuantityReportItemSortOption.CONSIGNEE,
  sku: OrderedQuantityReportItemSortOption.SKU,
  assignedCapacity: OrderedQuantityReportItemSortOption.ASSIGNED_CAPACITY,
  assignedCapacityGap:
    OrderedQuantityReportItemSortOption.ASSIGNED_CAPACITY_GAP,
  orderedQuantity: OrderedQuantityReportItemSortOption.ORDERED_QUANTITY,
  stockQuantity: OrderedQuantityReportItemSortOption.STOCK_QUANTITY,
};

const OrderedItemsReportTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const orderedItemsData = useRecoilValue(orderedQuantityItems);
  const orderItemsLoadStatus = useRecoilValue(orderedQuantityItemsLoadStatus);
  const selectedIssue = useRecoilValue(simulationIssueSelected);
  const [loadCallback, cancelLoad] = useLoadOrderedItems();
  const [runExportCSV] = useRunExportJobMutation();
  const [tableState, setTableState] = useRecoilState(orderedQuantityTableState);

  const tableTitle = t`Order item's gap report`;
  const tableSubTitle = t`Ordered items`;

  const { searchValues, sortValues } = tableState;

  function callDataLoad(page = { isAppend: false, skip: 0 }) {
    if (selectedIssue?.group !== 'orderedItems') return;

    const filter: OrderedQuantityReportItemFilter = {
      consignee: containsFilter(searchValues['consignee']),
      sku: containsFilter(searchValues['sku']),
      status: searchValues['status']
        ? [searchValues['status'] as OrderedQuantityReportItemStatus]
        : null,
    };
    const sortBy: OrderedQuantityReportItemSortBy[] = _(sortValues)
      .map((value, key) => {
        if (_.isNil(orderedItemsSortMap[key])) return null;
        return {
          field: orderedItemsSortMap[key],
          direction: value,
        };
      })
      .compact()
      .value();

    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad();
  }, [selectedIssue, tableState]);

  const statusMap = getOrderedItemsTitlesMap(t);
  const EmptyCell = (
    <span className=" w-full text-right opacity-50">{`—`}</span>
  );
  const columnsConfig: ColumnConfig<OrderedItemsDataRow>[] = [
    {
      field: 'status',
      title: t`Status`,
      render: status => {
        return (
          <OrderedItemStatusTag
            status={status as OrderedQuantityReportItemStatus}
          />
        );
      },
      hiddenInExport: true,
      hasFilter: true,
      filterOptions: statusMap,
    },
    {
      field: 'statusString',
      title: t`Status`,
      hiddenInBrowser: true,
    },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
      hasSort: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      hasSort: true,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'orderedQuantity',
      title: t`Ordered qty`,
      hasSort: true,
      render: (v: OrderedItemsDataRow['orderedQuantity']) => {
        return v > 0 ? formatInteger(v) : EmptyCell;
      },
    },
    {
      field: 'stockQuantity',
      title: t`Stock qty`,
      hasSort: true,
      render: (v: OrderedItemsDataRow['stockQuantity']) => {
        return v > 0 ? formatInteger(v) : EmptyCell;
      },
    },
    {
      field: 'assignedCapacity',
      title: t`Capacity qty`,
      hasSort: true,
      render: (v: OrderedItemsDataRow['assignedCapacity']) => {
        return v > 0 ? formatInteger(v) : EmptyCell;
      },
    },
    {
      field: 'assignedCapacityGap',
      title: t`Capacity gap`,
      hasSort: true,
      render: (v: OrderedItemsDataRow['assignedCapacityGap']) => {
        return v > 0 ? formatInteger(v) : EmptyCell;
      },
    },
    {
      field: 'capacityGapBreakdown',
      title: 'Capacity gap breakdown',
    },
    {
      field: 'stockGapBreakdown',
      title: 'Stock gap breakdown',
    },
    {
      field: 'capacityGapMaxUOM',
      title: 'Capacity gap UOM',
    },
    {
      field: 'stockGapMaxUOM',
      title: 'Stock gap UOM',
    },
  ];

  function onLoadNext() {
    callDataLoad({
      isAppend: true,
      skip: itemsCount,
    });
  }

  const converterConfig: OrderedItemsConverterConfig = {
    columnsConfig,
    dictionaries: {
      statusMap,
    },
  };

  async function startExportCSV() {
    const variables: OrderedItemsExportJobParams = {
      ...orderedItemsExportJobParams,
      query: LoadOrderedQuantityItemsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        simulationId: sim.id,
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = orderItemsLoadStatus === AsyncLoadStatus.Loading;
  const itemsCount = orderedItemsData?.content?.length || 0;
  const totalCount = orderedItemsData?.totalCount ?? 0;

  const flattenData = getOrderedItemsTableRows(
    orderedItemsData?.content,
    converterConfig,
  );

  return (
    <ContainerScroll className="flex flex-col">
      <DatasetTable
        // subtitle={tableSubTitle}
        title={tableTitle}
        isSticky
        id={selectedIssue?.reason}
        columnsConfig={columnsConfig}
        keyFields={['itemId', 'consignee', 'sku']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={v =>
          setTableState({
            ...tableState,
            searchValues: v,
          })
        }
        onSort={sortValues => setTableState({ ...tableState, sortValues })}
        totalCount={totalCount}
        sortBy={sortValues}
        searchValues={searchValues}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        hasCounter
      />
    </ContainerScroll>
  );
};

export default OrderedItemsReportTable;
