import {
  AllocationAllowReplacement,
  AllocationRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import SectionOptional from '../../../../components/layout/SectionOptional';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

const AllocationPolicyOverflowSettingsEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  function updateAllocationSettings(patch: Partial<AllocationRuleFragment>) {
    updateRule(current => ({
      ...current,
      ...patch,
    }));
  }

  return (
    <TitleSection
      id={`policy-editor-settings`}
      title={t`Allocation settings`}
      inSidebarView
      hasScreenTitle
    >
      <InputGroupList className="space-y-2">
        <SectionOptional
          id={'allocation-multiplexing'}
          title={t`Item Multiplexing`}
          value={
            rule?.multiplexingSettings?.allow ===
            AllocationAllowReplacement.ALWAYS
          }
          onChange={enabled =>
            updateAllocationSettings({
              multiplexingSettings: {
                ...rule?.multiplexingSettings,
                allow: enabled ? AllocationAllowReplacement.ALWAYS : null,
              },
            })
          }
        />

        <SectionOptional
          id={'allocation-replacement'}
          title={t`Stock Replacement`}
          value={
            rule?.replacementSettings?.allow ===
            AllocationAllowReplacement.ALWAYS
          }
          onChange={enabled =>
            updateAllocationSettings({
              replacementSettings: {
                ...rule?.replacementSettings,
                allow: enabled ? AllocationAllowReplacement.ALWAYS : null,
              },
            })
          }
        />
      </InputGroupList>
    </TitleSection>
  );
};

export default AllocationPolicyOverflowSettingsEditor;
