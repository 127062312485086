import * as React from 'react';
const PickGestureIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PickGestureIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124.23,51.1l-20.01-17.62c-2.26-1.99-5.71-1.81-7.71.45-1.96,2.23-1.85,5.66.36,7.62l4.64,4.1s-31.96,0-47.95,0c-.61,0-1.08-.52-1.08-1.13,0-.61.47-1.08,1.08-1.08,4.96,0,9.91,0,14.87,0,3.01,0,7.84-2.56,7.84-7.84,0-3.02-.84-3.02-5.5-3.02-11.64,0-34.92,0-34.92,0-17.49,0-31.63,14.15-31.63,31.63,0,17.49,14.18,31.68,28.39,31.68h33.44c4.65,0,7.07-2.48,7.07-5.5,0-3.01-2.44-5.45-5.45-5.45-3.88,0-7.75,0-11.63,0-.61,0-1.08-.47-1.08-1.08,0-.61.47-1.09,1.08-1.08,6.43.09,12.93,0,19.38,0,3.01,0,5.5-2.48,5.5-5.5,0-3.01-2.48-5.45-5.5-5.45-4.58,0-9.16,0-13.74,0-.61,0-1.08-.47-1.08-1.08s.47-1.09,1.08-1.08c6.73.09,13.53,0,20.28,0,3.01,0,5.5-2.43,5.5-5.45,0-3.02-2.44-5.45-5.45-5.45-6.77,0-13.55,0-20.32,0-.61,0-1.08-.47-1.08-1.08s.47-1.13,1.08-1.13h39.83s-4.64,4.1-4.64,4.1c-2.21,1.96-2.32,5.39-.36,7.62,1.99,2.26,5.44,2.44,7.71.45l20.01-17.62Z"
    />
  </svg>
);
export default PickGestureIcon;
