import * as React from 'react';
const CursorTransformBoxIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CursorTransformBoxIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,20V4h-16v6h-36v-6h-16v6H20v-6H4v16h6v36h-6v16h6v36h-6v16h16v-6h36v6h16v-6h36v6h16v-16h-6v-36h6v-16h-6V20h6ZM114,56h-6v16h6v36h-6v6h-36v-6h-16v6H20v-6h-6v-36h6v-16h-6V20h6v-6h36v6h16v-6h36v6h6v36Z"
    />
  </svg>
);
export default CursorTransformBoxIcon;
