import {
  useCreateActualityMutation,
  useLoadActualitiesQuery,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { ContainerMain } from '../components/layout/ContainerMain';
import { warehouseSelectedId } from '../store/warehouse.state';
import FeedHeader from './FeedHeader';

const ActualityDashboard: React.FC = () => {
  const { t } = useTranslation('dataset');
  const navigate = useNavigate();
  const whId = useRecoilValue(warehouseSelectedId);
  const [createActuality] = useCreateActualityMutation();
  const [error, setError] = useState<string>();

  const {
    data: actualitiesResponse,
    loading: actualitiesLoading,
    error: actualitiesLoadingError,
  } = useLoadActualitiesQuery({
    variables: {
      warehouseId: whId,
    },
  });

  useEffect(() => {
    async function process() {
      try {
        if (actualitiesLoading || !_.isEmpty(actualitiesLoadingError)) {
          return;
        }

        const list = actualitiesResponse?.warehouse?.actualities?.content ?? [];
        if (_.isEmpty(list)) {
          //create
          const { data, errors } = await createActuality({
            variables: {
              input: {
                warehouseId: whId,
                title: t`Activity Feed`,
              },
            },
          });
          if (!_.isEmpty(errors)) {
            console.error(errors);
            setError(`Create actuality error`);
            return;
          }
          const id = data.createActuality?.id;
          navigate(`./${id}`);
          return;
        }
        const id = _.head(list)?.id;
        navigate(`./${id}`);
      } catch (ex) {
        setError(ex.message ?? ex.toString());
      }
    }

    process();
  }, [actualitiesResponse]);

  const hasError = !_.isNil(actualitiesLoadingError) || !_.isNil(error);
  return (
    <>
      <FeedHeader />

      <ContainerMain data-component="activity-feed-container">
        <div
          data-component="sidebar-left-container"
          className="flex sidebar-wrapper side-left"
        >
          {hasError && (
            <ErrorIndicator message={t`Error loading Activities`} selfCenter />
          )}
          {!hasError && (
            <LoadingIndicator
              absolute
              selfCenter
              message={t`Loading Activities...`}
            />
          )}
        </div>
      </ContainerMain>
    </>
  );
};

export default ActualityDashboard;
