import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ButtonToolbarGroupProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ButtonToolbarGroup = ({
  children,
  className,
}: ButtonToolbarGroupProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <aside
      role="toolbar"
      data-component="ButtonToolbarGroup"
      id={id}
      className={classNames(
        'rounded',
        'shadow-xl',
        'bg-sidebar-toolbar',
        'backdrop-filter backdrop-blur-lg backdrop-saturate-150',
        'flex items-center',
        'z-0',
        className,
      )}
    >
      {children}
    </aside>
  );
};
