import {
  AppBillingProductCategory,
  AppBillingProductPriceFragment,
  AppBillingSubscriptionStatus,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import LoadingIndicator from '../components/LoadingIndicator';
import { ContainerCol } from '../components/layout/ContainerFlex';
import { warehouseIsDemo, warehouseSelectedId } from '../store/warehouse.state';
import AppBillingActiveSubscription from './AppBillingActiveSubscription';
import AppBillingPricingCards, {
  getFilteredPrices,
} from './AppBillingPricingCards';
import AppBillingStepper from './AppBillingStepper';
import useLoadWarehouseBillingState from './hooks/useLoadWarehouseBillingState';
import {
  appBillingPricingCategory,
  appBillingPricingCurrency,
  appBillingPricingSize,
  appBillingProducts,
  appBillingWarehouseLoadStatus,
  appBillingWarehouseState,
} from './store/appBilling.state';

/**
 * @property previewMode - if true, stepper is hidden, card's buttons redirect to pricing and show reduced features preset
 */

export type AppBillingPricingParams = {
  priceId: string;
};

export type AppBillingPricingProps = {
  children?: React.ReactNode;
  modePreview?: boolean;
};

const AppBillingPricing: React.FC<AppBillingPricingProps> = props => {
  const whId = useRecoilValue(warehouseSelectedId);
  const { priceId } = useParams<AppBillingPricingParams>();
  const products = useRecoilValue(appBillingProducts);
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);
  const [currency, setCurrency] = useRecoilState(appBillingPricingCurrency);
  const [category, setCategory] = useRecoilState(appBillingPricingCategory);
  const [whSize, setWhSize] = useRecoilState(appBillingPricingSize);
  const [selectedPrice, setSelectedPrice] =
    useState<AppBillingProductPriceFragment>();
  const navigate = useNavigate();
  const whState = useRecoilValue(appBillingWarehouseState);
  const whStateLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const loadBillingState = useLoadWarehouseBillingState();

  // refresh warehouse state
  useEffect(() => {
    if (_.isNil(whState)) {
      loadBillingState([whId]);
    }
  }, [loadBillingState, whId, whState]);

  useEffect(() => {
    if (_.isEmpty(products) || props.modePreview) return;

    // if selected priceID is passed to props, and product with this price exists,
    // take currency from  price

    const prices = _(products)
      .map(p => p.prices)
      .flatten()
      .value();
    const price = prices.find(p => p.id === priceId);

    if (price) {
      if (price.id !== selectedPrice?.id) {
        const product = products.find(p =>
          _.some(p.prices, pr => price.id === pr.id),
        );
        setWhSize(product.warehouseSize);
        setCurrency(price.currency.toUpperCase());
        setCategory(product.productCategory);
        setSelectedPrice(price);
      }
    } else {
      const filteredPrices = getFilteredPrices(
        products,
        currency,
        whSize,
        category,
      );
      navigate(`/wh/i/${whId}/pricing/${_.head(filteredPrices).id}`, {
        replace: true,
      });
    }
  }, [priceId, products, whId, currency, whSize, category]);

  function onCurrencyChange(currencyCode: string) {
    setCurrency(currencyCode);
    if (!props.modePreview) {
      const filteredPrices = getFilteredPrices(
        products,
        currencyCode,
        whSize,
        category,
      );
      navigate(`/wh/i/${whId}/pricing/${_.head(filteredPrices)?.id ?? ''}`);
    }
  }

  function onWhSizeChange(size: WarehouseSize) {
    setWhSize(size);

    if (!props.modePreview) {
      const filteredPrices = getFilteredPrices(
        products,
        currency,
        size,
        category,
      );
      navigate(`/wh/i/${whId}/pricing/${_.head(filteredPrices)?.id ?? ''}`);
    }
  }

  function onCategoryChange(category: AppBillingProductCategory) {
    setCategory(category);
    if (!props.modePreview) {
      const filteredPrices = getFilteredPrices(
        products,
        currency,
        whSize,
        category,
      );
      navigate(`/wh/i/${whId}/pricing/${_.head(filteredPrices)?.id ?? ''}`);
    }
  }

  const isWhStatusLoading =
    whStateLoadStatus === AsyncLoadStatus.Loading ||
    whStateLoadStatus === AsyncLoadStatus.None;

  if (isWhStatusLoading) {
    return (
      <LoadingIndicator
        transparent
        className="text-menu-text"
        noColor
        message={t`Loading...`}
      />
    );
  }

  const hasLiveSubscription = _.some(
    whState?.currentSubscriptions,
    sub =>
      sub.status === AppBillingSubscriptionStatus.ACTIVE ||
      sub.status === AppBillingSubscriptionStatus.PAST_DUE,
  );
  // Do not show products list if any subscription exists
  if (hasLiveSubscription || isDemo) {
    return <AppBillingActiveSubscription whState={whState} />;
  }

  return (
    <ContainerCol overflow>
      {!props.modePreview && (
        <AppBillingStepper active={'pricing'} priceId={selectedPrice?.id} />
      )}
      <AppBillingPricingCards
        selectedPriceId={selectedPrice?.id}
        modePreview={props.modePreview ? 'PREVIEW' : 'FULL'}
        modeCategory={'SUBSCRIPTION'}
        modePrice={'PRICING_SHOW'}
        modeLink={'APP'}
        products={products}
        currency={currency}
        onCurrencyChange={onCurrencyChange}
        category={category}
        onCategoryChange={onCategoryChange}
        whSize={whSize}
        onWhSizeChange={onWhSizeChange}
      />
    </ContainerCol>
  );
};

export default AppBillingPricing;
