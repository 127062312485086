import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../../components/InboxZero';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../containers/SidebarContainer';
import { simulationAllocatePolicyTabKey } from '../../store/simulation.wizard.state';
import DeallocatePolicyEditorPanel from '../deallocate/DeallocatePolicyEditorPanel';
import AllocationPolicyEditorPanel from './AllocationPolicyEditorPanel';

const AllocationEditorSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');

  const selectedPolicyTab = useRecoilValue(simulationAllocatePolicyTabKey);

  if (selectedPolicyTab === 'tab-allocate-policy-allocation') {
    return <AllocationPolicyEditorPanel />;
  }

  if (selectedPolicyTab === 'tab-allocate-policy-deallocation') {
    return <DeallocatePolicyEditorPanel />;
  }

  return (
    <InboxZero
      selfCenter
      center
      message={t`No Policy filter group selected`}
      message_helper={t`Click on an Edit button in Policy Rule to change filters`}
    />
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-allocation-editor',
    title: t`Filter Policy Params`,
    loadingMessage: t`Loading Policy...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <AllocationEditorSidebar />
    </SidebarContainer>
  );
};
