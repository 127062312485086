import * as React from 'react';
const PolicyAssignmentAgentIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAssignmentAgentIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M96.15,74.99c-1.43,1.26-3.61,1.15-4.87-.28-1.24-1.41-1.17-3.58.23-4.81l2.93-2.59s-16.78,0-25.17,0c-.39,0-.68.33-.68.71s.3.68.68.68h12.84c1.9,0,3.45,1.54,3.44,3.44,0,1.91-1.57,3.44-3.47,3.44-4.27,0-8.56.06-12.81,0-.39,0-.68.3-.68.68s.3.68.68.68h8.68c1.91,0,3.47,1.54,3.47,3.44s-1.57,3.47-3.47,3.47c-4.08,0-8.18.06-12.24,0-.38,0-.68.3-.68.68s.3.68.68.68h7.35c1.9,0,3.44,1.54,3.44,3.44s-1.53,3.47-4.47,3.47h-21.12c-8.98,0-17.94-8.96-17.94-20.01s8.93-19.99,19.98-19.99h22.06c2.94,0,3.47,0,3.47,1.91,0,3.34-3.05,4.95-4.95,4.95h-9.39c-.38,0-.68.3-.68.68s.3.71.68.71c10.1,0,30.29,0,30.29,0l-2.93-2.59c-1.4-1.24-1.47-3.4-.23-4.81,1.26-1.43,3.44-1.54,4.87-.28l12.64,11.13-12.64,11.13Z"
    />
  </svg>
);
export default PolicyAssignmentAgentIcon;
