import _ from 'lodash';
import { HeatmapAggregationFn } from '../../../common/heatmap.types';
import {
  AssignmentHqField,
  AssignmentHqQueryBuilderParams,
  getAssignmentHqQueryBuilder,
} from './assignmentQueryBuilder';

export type AssignmentMetricSummaryRow = {
  dimension: string | number;
  locationLevel: number;
  totalCount: number;
  totalEvents: number;
  totalLocations: number;
  totalExistedLocations: number;
  totalItems: number;
  totalAssignments: number;
};

export type GetAssignmentMetricSummaryParams =
  AssignmentHqQueryBuilderParams & {
    groupBy: AssignmentHqField;
    aggregationFn: HeatmapAggregationFn;
    aggregateBy: AssignmentHqField;
  };

export function getAssignmentMetricSummary(
  params: GetAssignmentMetricSummaryParams,
) {
  return getAssignmentHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) =>
      _.compact([
        `${params.groupBy} as dimension`,
        'locationLevel',
        params.aggregationFn === 'max'
          ? fn.count<number>(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'min'
          ? fn.count<number>(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'avg'
          ? fn.count<number>(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'sum'
          ? fn.sum<number>(params.aggregateBy).as('totalCount')
          : null,
        params.aggregationFn === 'count'
          ? fn.count<number>(params.aggregateBy).as('totalCount')
          : null,
        fn.countAll<number>().as('totalEvents'),
        fn.count<number>('locationId').distinct().as('totalLocations'),
        fn
          .count<number>('layoutLocationId')
          .distinct()
          .as('totalExistedLocations'),
        fn.count<number>('skuKey').distinct().as('totalItems'),
        fn.count<number>('assignmentItem').distinct().as('totalAssignments'),
      ]),
    )
    .groupBy([params.groupBy, 'locationLevel']);
}
