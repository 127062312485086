const MenuCloseAlt2Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="MenuCloseAlt2Icon"
    {...props}
  >
    <rect width={17} height={114} x={103} y={6} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M55.85,64L95.5,12.45c.79-1.03,1.03-2.58.6-3.93-.43-1.35-1.44-2.23-2.56-2.23h-44.38c-.77,0-1.5.42-2.03,1.15L8.3,61.54c-.99,1.38-.99,3.54,0,4.92l38.83,54.1c.53.73,1.26,1.15,2.03,1.15h44.38c1.12,0,2.13-.88,2.56-2.23.43-1.35.19-2.9-.6-3.93l-39.65-51.55Z"
    />
  </svg>
);
export default MenuCloseAlt2Icon;
