import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useLoadSimulationItemSet from '../../assignmentPolicy/useLoadSimulationItemSet';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  simulationCurrent,
  simulationEffectiveItemSet,
  simulationEffectiveItemSetLoadStatus,
} from '../store/simulation.state';

const SimulationItemSetWrap: React.FC<PropsWithChildren> = props => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const effectiveItemSet = useRecoilValue(simulationEffectiveItemSet);
  const effectiveItemSetLoadStatus = useRecoilValue(
    simulationEffectiveItemSetLoadStatus,
  );
  const [loadItemSetStatus, cancelLoadItemSetStatus] =
    useLoadSimulationItemSet();

  useEffect(() => {
    if (effectiveItemSetLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    //  recalculate SimulationEffectiveItemSet data if it not calculated ye
    if (_.isNil(effectiveItemSet)) {
      loadItemSetStatus({
        simulationId: sim.id,
      });
    }
    if (
      effectiveItemSet?.status === BatchJobStatus.CREATED ||
      effectiveItemSet?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadItemSetStatus({
          simulationId: sim.id,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadItemSetStatus();
    };
  }, [effectiveItemSet]);

  const hasError =
    effectiveItemSetLoadStatus === AsyncLoadStatus.Error ||
    effectiveItemSet?.status === BatchJobStatus.FAILED ||
    effectiveItemSet?.status === BatchJobStatus.TERMINATED;
  const isLoading =
    effectiveItemSetLoadStatus !== AsyncLoadStatus.Ok ||
    effectiveItemSet?.status !== BatchJobStatus.READY;

  if (hasError) {
    return <ErrorIndicator message={t`Error building Policy Filters...`} />;
  }

  if (isLoading) {
    <LoadingIndicator message={t`Building Policy Filters...`} selfCenter />;
  }

  return <>{props.children}</>;
};

export default SimulationItemSetWrap;
