import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import LoadingIndicator from '../../components/LoadingIndicator';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import InputSlider from '../../components/inputs/InputSlider';
import { InputValue } from '../../components/inputs/InputValue';

export type PolicyRangeEditorProps = {
  title: string;
  fieldId: string;
  selected: [from: number, to: number];
  range: [from: number, to: number];
  isLoading: boolean;
  isIntegerStepSize?: boolean;
  format?: (v) => FormattedValueWithUnit;
  hasSelectNullOption?: boolean;
  nullOptionTitle?: string;
  selectedNullOption?: boolean;
  onSelectValue: (value: [from: number, to: number], isNull?: boolean) => void;
};

const PolicyRangeEditor: React.FC<PolicyRangeEditorProps> = props => {
  const { t } = useTranslation('app');

  const [isFromValid, setFromValid] = useState(true);
  const [isToValid, setToValid] = useState(true);
  const [from, setFrom] = useState<number>(null);
  const [to, setTo] = useState<number>(null);
  useEffect(() => {
    setFrom(props.selected[0]);
    setFromValid(true);
    setTo(props.selected[1]);
    setToValid(true);
  }, [props.selected]);

  const isRangeSelectionDisabled = props.selectedNullOption;

  const fromPlaceholder = t`min`;
  const toPlaceholder = t`max`;

  function validateFrom(value: string) {
    if (value === '') {
      !_.isNil(props.selected[0]) &&
        props.onSelectValue([null, props.selected[1] ?? null]);
      return;
    }
    const num = parseFloat(value);
    const isValid =
      !Number.isNaN(num) &&
      num >= props.range[0] &&
      num <= (_.isNil(props.selected[1]) ? props.range[1] : props.selected[1]);
    if (isValid) {
      from !== num && props.onSelectValue([num, props.selected[1] ?? null]);
    } else {
      setFromValid(false);
    }
  }

  function validateTo(value: string) {
    if (value === '') {
      !_.isNil(props.selected[1]) &&
        props.onSelectValue([props.selected[0] ?? null, null]);
      return;
    }
    const num = parseFloat(value);
    const isValid =
      !Number.isNaN(num) &&
      num <= props.range[1] &&
      num >= (_.isNil(props.selected[0]) ? props.range[0] : props.selected[0]);
    if (isValid) {
      from !== num && props.onSelectValue([props.selected[0] ?? null, num]);
    } else {
      setToValid(false);
    }
  }

  const sliderValues: [number, number] = [
    props.selected[0] ?? props.range[0],
    props.selected[1] ?? props.range[1],
  ];

  const stepSizeBase = (props.range[1] - props.range[0]) / 50;
  const stepSize = props.isIntegerStepSize ? 1 : stepSizeBase;
  const hasRange = props.range[0] < props.range[1];
  const minRangeValueString = !_.isNil(props.range[0])
    ? props.format
      ? props.format(props.range[0]).fullString
      : props.range[0]
    : null;

  return (
    <div
      data-component="PolicyRangeEditor"
      className={classNames('relative px-4 min-h-5')}
    >
      {props.isLoading && (
        <LoadingIndicator
          inline
          selfCenter
          absolute
          message={t`Filtering values`}
        />
      )}

      {!hasRange ? (
        <div
          className={classNames(
            'p-2 m-1 mt-4',
            'text-xs text-center',
            'rounded',
            'bg-app-panel-dark/50',
            'text-menu-text/75',
          )}
        >
          {minRangeValueString ?? t`No data available`}
        </div>
      ) : (
        <>
          <div className={classNames('flex mb-4 mt-2')}>
            <div
              data-component="min"
              className={classNames('flex-1 flex flex-col')}
            >
              <label
                className={classNames('uppercase text-xxs mb-0.5 opacity-50')}
              >{t`From`}</label>
              <InputValue
                isNumeric={true}
                hasError={!isFromValid}
                placeholder={fromPlaceholder}
                value={from ?? ''}
                onChange={v => {
                  setFromValid(true);
                  setFrom(v);
                }}
                onEndEdit={validateFrom}
                onCancelEdit={() => {
                  setFromValid(true);
                  setFrom(props.selected[0]);
                }}
                disabled={isRangeSelectionDisabled}
              />
            </div>
            <div className={classNames('flex-1 max-w-2')}></div>
            <div
              data-component="max"
              className={classNames('flex-1 flex flex-col')}
            >
              <label
                className={classNames('uppercase text-xxs mb-0.5 opacity-50')}
              >{t`To`}</label>
              <InputValue
                placeholder={toPlaceholder}
                hasError={!isToValid}
                isNumeric={true}
                value={to ?? ''}
                onChange={v => {
                  setToValid(true);
                  setTo(v);
                }}
                onEndEdit={validateTo}
                onCancelEdit={() => {
                  setToValid(true);
                  setTo(props.selected[1]);
                }}
                disabled={isRangeSelectionDisabled}
              />
            </div>
          </div>

          <InputSlider
            values={sliderValues}
            min={props.range[0]}
            max={props.range[1]}
            stepSize={stepSize}
            onChange={props.onSelectValue}
            minValueTitle={fromPlaceholder}
            maxValueTitle={toPlaceholder}
            format={props.format}
            handleSize={'xs'}
            segmentsClassNames={[
              'bg-menu-button',
              'bg-menu-active',
              'bg-menu-button',
            ]}
            hideHandlesValue
            isDisabled={isRangeSelectionDisabled}
            className={props.hasSelectNullOption ? '!mb-2 lg:!mb-2' : ''}
          />
          {props.hasSelectNullOption && (
            <InputCheckbox
              // label=
              className="border-t border-menu-400/50"
              isSelected={props.selectedNullOption}
              onChange={v => props.onSelectValue(props.selected, v)}
            >
              {props.nullOptionTitle}
            </InputCheckbox>
          )}
        </>
      )}
    </div>
  );
};

export default PolicyRangeEditor;
