import _ from 'lodash';
import Prism, { highlight } from 'prismjs';
import 'prismjs/components/prism-json';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import { ButtonLoadMore } from '../../../components/actions/ButtonLoadMore';
import { Container } from '../../../components/layout/ContainerFlex';
import { ActionBar } from '../../../components/nav/ActionBar';

export type ImporterJSONRawPreviewProps = {
  data: Object[];
};

const ImporterJSONRawPreview: React.FC<ImporterJSONRawPreviewProps> = props => {
  const { t } = useTranslation('importer');
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerPage = 1;
  const totalCount = props.data.length;

  const handleNext = () => {
    if (currentIndex < totalCount - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleFirst = () => {
    setCurrentIndex(0);
  };

  const handleLast = () => {
    setCurrentIndex(totalCount - 1);
  };

  const value = JSON.stringify(props.data[currentIndex], null, '\t');

  return (
    <Container col className="relative">
      <ActionBar stickyTop className="p-2">
        <ButtonLoadMore
          totalCount={totalCount}
          itemsCount={currentIndex + 1}
          titleNext={t('Next')}
          titlePrev={t('Prev')}
          titleFirst={t('First')}
          titleLast={t('Last')}
          onNext={handleNext}
          onPrev={handlePrev}
          onFirst={handleFirst}
          onLast={handleLast}
        />
      </ActionBar>
      <div className="h-full overflow-auto">
        <Editor
          value={value}
          disabled={true}
          onValueChange={_.noop}
          highlight={code => {
            if (Prism.languages.json) {
              return highlight(code, Prism.languages.json, 'json');
            }
            return code;
          }}
          padding={10}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 12,
            paddingBottom: '3rem',
          }}
        />
      </div>
    </Container>
  );
};

export default ImporterJSONRawPreview;
