import * as React from 'react';
const PalletStackingHeatmapIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletStackingHeatmapIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="102.29 92.7 102.29 92.7 107.48 88.07 107.48 67.11 66 103.48 66 125.06 71.18 120.43 71.18 112.12 81.24 103.61 85.49 107.98 90.67 103.36 90.67 95.18 97.01 90.54 102.29 92.7"
    />
    <polygon
      strokeWidth={0}
      points="26.71 92.7 26.71 92.7 21.53 88.07 21.53 67.11 63 103.48 63 125.06 57.82 120.43 57.82 112.12 47.76 104.61 43.51 107.98 38.33 103.36 38.33 95.18 31.99 89.54 26.71 92.7"
    />
    <polygon
      strokeWidth={0}
      points="28.03 65.67 28.03 36.02 60.96 63.68 60.96 93.76 28.03 65.67"
    />
    <polygon
      strokeWidth={0}
      points="67.32 63.67 99.92 36.04 99.92 65.67 67.32 93.74 67.32 63.67"
      opacity={0.6}
    />
    <polyline
      strokeWidth={0}
      points="80.21 14.17 63.5 .38 46.91 14.17 63.61 27.95 80.21 14.17"
    />
    <polyline
      strokeWidth={0}
      points="99.22 30.04 82.52 16.25 65.93 30.04 82.63 43.83 99.22 30.04"
      opacity={0.4}
    />
    <polyline
      strokeWidth={0}
      points="61.3 30.04 44.6 16.25 28 30.04 44.7 43.83 61.3 30.04"
      opacity={0.8}
    />
    <polyline
      strokeWidth={0}
      points="80.32 45.92 63.62 32.13 47.02 45.92 63.72 59.71 80.32 45.92"
      opacity={0.6}
    />
  </svg>
);
export default PalletStackingHeatmapIcon;
