import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { getSizeColorByStatus } from '../store/sizeCompliance.helper';
import { sizeComplianceLevelLocation } from '../store/sizeCompliance.state';

const SizeComplianceLocationLayer: React.FC = () => {
  const levelLocationLoadable = useRecoilValueLoadable(
    sizeComplianceLevelLocation,
  );

  const levelLocation =
    levelLocationLoadable.state === 'hasValue'
      ? levelLocationLoadable.contents
      : null;

  return (
    <LocationLayer
      getColor={loc => {
        const locSize = levelLocation?.[loc.locationId];
        return locSize ? getSizeColorByStatus(locSize?.status) : null;
      }}
    />
  );
};
export default SizeComplianceLocationLayer;
