import {
  AnalyzeOrderLineStatus,
  AnalyzeResultOrderDetailsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TwTheme } from '../../../Tw';
import { formatInteger, formatTimespan } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatListHeader } from '../../components/stats/StatListHeader';
import { StatListItem } from '../../components/stats/StatListItem';
import PanelContainer from '../../containers/PanelContainer';
import { resourcePolicy } from '../store/resourcePolicy.state';

export type StatCollapsibleProps = {
  children?: React.ReactNode;
  className?: string;
  classNameStyle?: string;
  id?: string;
  title?: string;
  titleAlt?: string;
  unitOfMeasure?: string;
  color?: string;
  value?: string | number;
  transparent?: boolean;
  collapsible?: boolean;
  isClosed?: boolean;
};

export const StatCollapsible: React.FC<StatCollapsibleProps> = props => {
  const { t } = useTranslation('simulation');
  // const legendTypeClasses = classNames({
  //   'w-4 h-4': legend === 'square',
  //   'w-4 h-4 rounded-full': legend === 'circle',
  //   'w-1 xl:w-2 h-4 xl:h-7 rounded-sm': legend === 'line',
  //   'w-4 h-4 rounded-full border-2': legend === 'torus',
  // });

  return (
    <TitleSection
      id={`order-lines-with-${props.title}-${props.id}`}
      title={
        <div data-component="StatCollapsible" className="flex items-center">
          <div
            data-component="LegendItem"
            aria-label="LegendItem"
            className={classNames(
              'ltr:ml-1 ltr:mr-2 rtl:ml-2 rtl:mr-1',
              'h-4 w-1 rounded-sm xl:h-7 xl:w-2',
              props.classNameStyle,
            )}
          />
          {props.title}
        </div>
      }
      titleSummaryClosed={props.value}
      classNameChevron={`w-4 h-4 ltr:mr-0.5 rtl:ml-0.5 text-menu-active`}
      inPanelView
      collapsible={props.collapsible}
      isClosed={props.isClosed}
      className={classNames(
        'text-sm ltr:mr-4 rtl:ml-4',
        'bg-app-panel-dark/80 backdrop-blur backdrop-filter',
        props.className,
      )}
      // className={`${
      //   props.className || ''
      // }`}
      classNameInner="py-3"
      isTransparent
    >
      {props.children && (
        <>
          <StatListHeader
            className="ltr:pl-4 ltr:pr-3 rtl:pl-3 rtl:pr-4"
            title={t`Item`}
            value={t`Qty`}
          />
          <ul
            className={`text-menu-200 list-outside list-decimal text-xs ltr:pl-6 rtl:pr-6`}
          >
            {props.children}
          </ul>
        </>
      )}
    </TitleSection>
  );
};

const picklistColors = TwTheme.extend.colors.picklistLines;

export type OrderSummaryPanelProps = {
  order: AnalyzeResultOrderDetailsFragment;
};

const OrderSummaryPanel: React.FC<OrderSummaryPanelProps> = props => {
  const { order } = props;
  const { t } = useTranslation('simulation');

  const formatter = useFormatter();
  if (!resourcePolicy || !order) {
    return null;
  }

  const linesAssembled = _.filter(
    order.orderLinesStatus,
    line => line.status === AnalyzeOrderLineStatus.ASSEMBLED,
  );

  const linesWithIssuesIgnored = _.filter(
    order.orderLinesStatus,
    line => line.status === AnalyzeOrderLineStatus.IGNORED,
  );

  const linesIgnoredCount = linesWithIssuesIgnored.length;

  const linesWithIssuesPartial = _.filter(
    order.orderLinesStatus,
    line => line.status === AnalyzeOrderLineStatus.ASSEMBLED_PARTIALLY,
  );

  const linesPartialCount = linesWithIssuesPartial.length;

  const linesWithIssues = _.filter(
    order.orderLinesStatus,
    line =>
      line.status === AnalyzeOrderLineStatus.ASSEMBLED_PARTIALLY ||
      line.status === AnalyzeOrderLineStatus.IGNORED,
  );

  const originalLinesDict = _.keyBy(
    order.order.details,
    line => line.orderLine,
  );
  const distance = formatter.formatDistance(order.totalDistance);
  const cost = formatter.formatCurrency(order.totalCost);
  const duration = formatTimespan(order.totalDuration * 1000);
  const durationInMinutes = order.totalDuration / 60;

  const statsByUom = _(order?.picklists)
    .map(pl => pl.picklist.lines)
    .flatten()
    .groupBy(l => l.uom)
    .map((lines, uom) => ({
      uom,
      uomCount: _.sumBy(lines, l => l.uomQuantity),
      unitCount: _.sumBy(lines, l => l.quantity),
    }))
    .value();

  const totalUnitCount = _.sumBy(statsByUom, 'unitCount');
  const pickingRatePerHour = (totalUnitCount / durationInMinutes) * 60;

  return (
    <>
      <PanelContainer
        id="panel-routes-stats"
        title={t`Order Stats`}
        collapsible
        subtitle={order.order.orderId}
      >
        <Stat
          isSelectable
          isPreview
          title={t`Order`}
          value={order.order.orderId}
        >
          {order.order.orderDate && (
            <StatListItem title={t`Order Date`} value={order.order.orderDate} />
          )}
          <StatListItem
            title={t`Consignee (Client)`}
            value={order.order.consignee}
          />
          {order.order.customer && (
            <StatListItem title={t`Customer`} value={order.order.customer} />
          )}
        </Stat>
        <Stat title={t`Stats`} hasHelper isPreview inPanelMode>
          <StatListItem
            title={t`Distance`}
            value={distance.value}
            unitOfMeasure={distance.unit}
          />
          <StatListItem
            title={t`Cost`}
            value={cost.raw}
            unitOfMeasure={cost.unit}
            isUnitPrefix={cost.prefixUnit}
          />
          <StatListItem
            title={t`Duration`}
            value={duration}
            // unitOfMeasure={duration.}
          />
        </Stat>
        <Stat title={t`Unit Stats`} hasHelper isPreview inPanelMode>
          <StatListItem
            key="total-unit-count"
            title={t`Total Units (Base)`}
            value={formatInteger(totalUnitCount)}
            unitOfMeasure={t`unit(s)`}
          />

          <StatListItem
            key="total-unit-count"
            title={t`Pick Rate (Avg.)`}
            value={formatInteger(pickingRatePerHour)}
            unitOfMeasure={t`UOM/hour`}
          />

          {_.map(statsByUom, uomStat => (
            <>
              <StatListItem
                key={uomStat.uom + uomStat.uomCount}
                title={t(`UOM: {{uomType}}`, {
                  uomType: uomStat.uom,
                })}
                value={formatInteger(uomStat.uomCount)}
              />

              <StatListItem
                className={classNames('ltr:pl-4 rtl:pr-4')}
                key={uomStat.uom + uomStat.unitCount}
                title={t`UOM (Base)`}
                // value={formatInteger(uomStat.uomCount)}
                value={formatInteger(uomStat.unitCount)}
                unitOfMeasure={`unit(s)`}
              />
            </>
          ))}
        </Stat>
      </PanelContainer>

      <PanelContainer
        className="0.5"
        id="panel-order-lines-issues"
        title={t`Order Lines Stats`}
        collapsible
        subtitle={`${formatInteger(order.assembledLines)} of ${formatInteger(
          order.totalLines,
        )} ${linesWithIssues.length ? '(' + linesWithIssues.length + ')' : ''}`}
      >
        {/* <Stat
          isPreview
          // title={t`Order`}
          // value={order.order.orderId}
          // unitOfMeasure={'distanceBest.unit'}
        > */}
        <div className="bg-app-panel-dark/60 px-2 py-4">
          <div className="px-4">
            <StatListItem
              title={t`Total Order Lines`}
              value={formatInteger(order.totalLines)}
            />
            <StatListItem
              legendType="line"
              legendColor={picklistColors.full}
              title={t`Picked Lines`}
              value={formatInteger(order.assembledLines)}
            />
            {/* <StatListItem
              legendType="line"
              legendColor={picklistColors.partial}
              title={t`Partially Picked Lines`}
              value={formatInteger(order.assembledPartiallyLines)}
            /> */}
            {/* <StatListItem
            legendType="line"
            legendColor={picklistColors.ignored}
            title={t`Ignored Lines`}
            value={formatInteger(order.ignoredLines)}
          /> */}
          </div>
          <div className="-mx-1 pr-0">
            {/* {order.assembledLines > 0 && (
              <StatCollapsible
                title={t`Picked Lines`}
                value={order.assembledLines}
                classNameStyle="bg-picklistLines-full"
                isClosed
              />
            )} */}

            {linesPartialCount > 0 && (
              <StatCollapsible
                title={t`Partial Lines`}
                value={linesPartialCount}
                classNameStyle="bg-picklistLines-partial"
                collapsible
              >
                {linesWithIssuesPartial.map(line => {
                  return (
                    <li
                      key={`order-lines-with-issues-${order.id}-${line.orderLine}-li`}
                    >
                      <StatListItem
                        key={`order-lines-with-issues-${order.id}-${line.orderLine}`}
                        legendType="line"
                        legendColor={picklistColors.partial}
                        title={originalLinesDict[line.orderLine]?.sku}
                        value={formatInteger(
                          originalLinesDict[line.orderLine]?.quantity,
                        )}
                        labelValueType={'itemId'}
                        labelValueFilters={{
                          consignee: order.order?.consignee,
                          sku: originalLinesDict[line.orderLine]?.sku,
                        }}
                      />
                    </li>
                  );
                })}
              </StatCollapsible>
            )}

            {linesIgnoredCount > 0 && (
              <StatCollapsible
                title={t`Unpickable Lines`}
                value={linesIgnoredCount}
                classNameStyle="bg-picklistLines-ignored"
                collapsible
              >
                {linesWithIssuesIgnored.map(line => {
                  return (
                    <li
                      className="ltr:pr-4 rtl:pl-4"
                      key={`order-lines-with-issues-${order.id}-${line.orderLine}-li`}
                    >
                      <StatListItem
                        key={`order-lines-with-issues-${order.id}-${line.orderLine}`}
                        legendType="line"
                        legendColor={picklistColors.ignored}
                        title={originalLinesDict[line.orderLine]?.sku}
                        value={formatInteger(
                          originalLinesDict[line.orderLine]?.quantity,
                        )}
                        labelValueType={'itemId'}
                        labelValueFilters={{
                          consignee: order.order?.consignee,
                          sku: originalLinesDict[line.orderLine]?.sku,
                        }}
                      />
                    </li>
                  );
                })}
              </StatCollapsible>
            )}
          </div>
        </div>
      </PanelContainer>
    </>
  );
};

export default OrderSummaryPanel;
