import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import AisleFeature from '../../features/AisleFeature';
import {
  viewerAreaAisles,
  viewerHoveredAisle,
  viewerSelectedAisleWithToggle,
} from '../store/viewer.state';

export type AisleLayerProps = {
  hideLabels?: boolean;
};
const AisleLayer: React.FC<AisleLayerProps> = props => {
  const areaAislesLoadable = useRecoilValueLoadable(viewerAreaAisles);
  const setSelectedToggle = useSetRecoilState(viewerSelectedAisleWithToggle);
  const setHovered = useSetRecoilState(viewerHoveredAisle);

  if (areaAislesLoadable.state !== 'hasValue') return null;

  const areaAisles = areaAislesLoadable.contents;

  if (_.isEmpty(areaAisles)) return null;

  return (
    <Layer>
      {_.values(areaAisles).map(aisle => {
        return (
          <AisleFeature
            key={`aisle-${aisle.id}`}
            aisle={aisle}
            hideLabels={props.hideLabels}
            onClick={() => setSelectedToggle(aisle)}
            onMouseOver={() => setHovered(aisle)}
            onMouseOut={() => setHovered(null)}
          />
        );
      })}
    </Layer>
  );
};

export default AisleLayer;
