import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PolicyFilter } from '../components/policies/PolicyFilter';
import PolicyFilterGroupList from '../components/policies/PolicyFilterGroupList';
import PolicyFilterItem from '../components/policies/PolicyFilterItem';
import {
  viewerPlanesMap,
  viewerSelectedLevel,
  viewerSelectedPlane,
} from '../layout/viewer/store/viewer.state';
import { getAssignmentStatsItemFilterConfig } from './assignmentStats.helper';
import { assignmentStatsItemsFilter } from './assignmentStats.state';

const AssignmentStatsFilterSummary = () => {
  const { t } = useTranslation('simulation');
  const planes = useRecoilValue(viewerPlanesMap);
  const hasFloors = planes ? Object.keys(planes).length > 1 : false;

  const [filterIntersection, setFilterIntersection] = useRecoilState(
    assignmentStatsItemsFilter,
  );

  const level = useRecoilValue(viewerSelectedLevel);
  const plane = useRecoilValue(viewerSelectedPlane);

  const removeField = (groupId: string, fieldId: string) => {
    setFilterIntersection({
      ...filterIntersection,
      allOf: _.filter(filterIntersection?.allOf, fg => fg.type !== fieldId),
    });
  };

  const itemsFilterConfig = getAssignmentStatsItemFilterConfig(t);
  const hasFilteredItems = filterIntersection?.allOf?.length > 0;

  return (
    <PolicyFilterGroupList
      filterSets={[filterIntersection as any]}
      isDisabled={!hasFilteredItems}
      config={itemsFilterConfig}
      selectedId={null}
      onDeleteField={removeField}
      hideActionMenu={true}
      //onSelectGroup={selectFilterSet}
      //onDeleteGroup={removeFilterSet}
    >
      <PolicyFilter
        key={`layout-filter`}
        isDisabled={true}
        // label={t`Layout filters`}
      >
        {hasFloors && (
          <PolicyFilterItem
            key={`layout-filter-plane`}
            isDisabled={true}
            isRemovable={false}
            name={t`Floor`}
            value={plane?.title}
          />
        )}

        <PolicyFilterItem
          key={`layout-filter-level`}
          isDisabled={true}
          isRemovable={false}
          name={t`Level`}
          value={`${level}`}
        />
      </PolicyFilter>
    </PolicyFilterGroupList>
  );
};

export default AssignmentStatsFilterSummary;
