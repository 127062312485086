import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import RuleContainer, {
  RuleContainerProps,
} from '../components/policies/RuleContainer';
import SwapPolicyGroup from './SwapPolicyGroup';
import SwapPolicyIssue from './SwapPolicyIssue';
import {
  swapPolicyCheckResult,
  swapPolicyRule,
  swapPolicySelectedIdentity,
} from './swapPolicy.state';

export type SwapPolicyRuleProps = {
  ruleId: string;
  index?: number;
  className?: string;
  isDefault?: boolean;
  isRemovable?: boolean;
  isNew?: boolean;
  hasIssue?: boolean;
  isDisabled?: boolean;
  isCheckLoading: boolean;
  productCount?: [number, number];
  locationCount?: [total: number, exclusive: number, empty?: number];

  disableProductSelection?: boolean;
  canDelete?: boolean;
  onDeleteClick?: () => void;
  canRename?: boolean;
};

const SwapPolicyRule: React.FC<SwapPolicyRuleProps> = props => {
  const { t } = useTranslation('simulation');
  const { ruleId } = props;
  const [rule, updateRule] = useRecoilState(swapPolicyRule(ruleId));
  const selectedIdentity = useRecoilValue(swapPolicySelectedIdentity);
  const checkResult = useRecoilValue(swapPolicyCheckResult);
  const ruleCheckResult = _.find(
    checkResult?.result?.rules,
    r => r.id === ruleId,
  );

  function renameRule(newTitle: string) {
    updateRule({
      ...rule,
      title: newTitle,
    });
  }

  const hasIssue = ruleCheckResult?.hasValidSwaps === false;
  const isActive = selectedIdentity?.ruleId === ruleId;
  const containerParams: RuleContainerProps = {
    id: rule.id,
    title: rule.title,
    isCollapsible: true,
    isRemovable: props.canDelete,
    isActive,
    isNew: props.isNew,
    orderCounter: props.index,
    onDeleteClick: () => props.onDeleteClick && props.onDeleteClick(),

    canRename: props.canRename,
    onTitleChange: title => renameRule(title),
  };

  return (
    <RuleContainer
      {...containerParams}
      isDisabled={props.isDisabled}
      hasIssue={hasIssue}
      hasIcon
      hasColorMode={false}
      hasOrder
    >
      {hasIssue && <SwapPolicyIssue details={ruleCheckResult?.details} />}

      <div className="space-y-6">
        <SwapPolicyGroup title={t`Source`} ruleId={ruleId} group="source" />
        <SwapPolicyGroup
          title={t`Destination`}
          ruleId={ruleId}
          group="destination"
        />
      </div>
    </RuleContainer>
  );
};
export default SwapPolicyRule;
