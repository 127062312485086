import {
  ItemSetIssueInvalidUomFragment,
  ItemSetIssueInvalidUomReason,
  LoadItemSetUomIssueDataQuery,
  LoadItemSetUomIssueDataQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import {
  ColumnConfigBase,
  ExportConverter,
  ExportJobParams,
  NULL_VALUE,
} from './types';

export type UOMIssuesExportJobParams = ExportJobParams<
  LoadItemSetUomIssueDataQueryVariables,
  UOMIssuesConverterConfig
>;

export const UOMIssuesExportJobParams: UOMIssuesExportJobParams = {
  converterId: 'UOM_ISSUES',
  query: null,
  config: null,
  variables: null,
  filename: 'items-uom-issues.csv',
};

export type UOMIssuesDataRow = ItemSetIssueInvalidUomFragment & {
  reasonString: string;
};

export type UOMIssuesConverterConfig = {
  columnsConfig: ColumnConfigBase<UOMIssuesDataRow>[];
  dictionaries: {
    reasons: Record<ItemSetIssueInvalidUomReason, string>;
  };
};

const getReasons = (
  reasonsData: ItemSetIssueInvalidUomReason[],
  reasonsMap: Record<ItemSetIssueInvalidUomReason, string>,
) => {
  if (_.isEmpty(reasonsData)) return NULL_VALUE;
  return _(reasonsData)
    .map(r => reasonsMap[r])
    .join(', ');
};

export const getUOMIssuesTableRows = (
  data: ItemSetIssueInvalidUomFragment[],
  config: UOMIssuesConverterConfig,
): UOMIssuesDataRow[] => {
  const getUOMIssuesDataRow = (row: ItemSetIssueInvalidUomFragment) => {
    return {
      ...row,
      reasonString: getReasons(row.reason, config.dictionaries.reasons),
    } as UOMIssuesDataRow;
  };

  return _.map(data, getUOMIssuesDataRow);
};

export const UOM_ISSUES_EXPORT_CONVERTER: ExportConverter<
  LoadItemSetUomIssueDataQuery,
  UOMIssuesConverterConfig
> = (input, config) => {
  const data = getUOMIssuesTableRows(
    input.itemSetIssueSet?.invalidUoms?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
