import * as React from 'react';
const WarehouseCosmeticsIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseCosmeticsIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M24.99,80.5c.74-.11,1.26-.74,1.21-1.47l-2.16-22.86c-.05-.68-.63-1.21-1.32-1.21h-.11c-.74.05-1.32.74-1.26,1.47l2.16,22.86c.11.74.74,1.26,1.47,1.21Z"
    />
    <path
      strokeWidth={0}
      d="M48.59,65.6c-3.75,0-6.78,3.03-6.78,6.78s3.03,6.78,6.78,6.78,6.78-3.03,6.78-6.78c0-3.75-3.03-6.78-6.78-6.78ZM50.03,73.8c-1.32,0-2.39-1.07-2.39-2.39s1.07-2.39,2.39-2.39,2.39,1.07,2.39,2.39c0,1.32-1.07,2.39-2.39,2.39Z"
    />
    <path
      strokeWidth={0}
      d="M64.5,13.87L.84,30.3v62.58h126.32V30.3l-62.66-16.43ZM40.99,55.38c0-1.63,1.26-2.95,2.9-2.95h5.06c1.58,0,2.9,1.32,2.9,2.95v3.95c0,.53-.42.95-.95.95h-9.01c-.53,0-.95-.42-.9-.95v-3.95ZM14.45,42.11c0-1.63,1.26-2.95,2.9-2.95h5.11c1.58,0,2.9,1.32,2.9,2.95v3.95c0,.53-.42.95-.95.95h-9.06c-.53,0-.95-.42-.89-.95v-3.95ZM10.71,52.38c.16-1.47,1.37-2.58,2.84-2.58h12.59c1.47,0,2.69,1.11,2.84,2.58l2.84,30.54c.16,1.69-1.16,3.11-2.84,3.11H10.66c-1.63,0-2.95-1.47-2.79-3.11l2.84-30.54ZM57.9,83.08c0,1.63-1.26,2.95-2.9,2.95h-17.17c-1.63,0-2.95-1.32-2.95-2.95v-17.06c0-1.63,1.32-2.95,2.95-2.95h17.17c1.58,0,2.9,1.32,2.9,2.95v17.06Z"
    />
  </svg>
);
export default WarehouseCosmeticsIcon;
