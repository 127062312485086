import * as React from 'react';
const LayerHorizontalIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayerHorizontalIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="1.5 66.95 11.35 61.35 70.59 95.01 116.65 69.4 126.5 75 70.59 106.24 1.5 66.95"
    />
    <polygon
      strokeWidth={0}
      points="68.93 55.13 54.79 47.1 40.66 55.13 54.79 63.2 68.93 55.13"
    />
    <polygon
      strokeWidth={0}
      points="100.51 55.13 86.38 47.1 72.24 55.13 86.38 63.2 100.51 55.13"
      opacity={0.6}
    />
    <polygon
      strokeWidth={0}
      points="84.72 64.12 70.59 56.09 56.45 64.12 70.59 72.2 84.72 64.12"
      opacity={0.7}
    />
    <polygon
      strokeWidth={0}
      points="84.72 46.13 70.59 38.1 56.45 46.13 70.59 54.21 84.72 46.13"
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="116.65 64.12 102.51 56.09 88.37 64.12 102.51 72.2 116.65 64.12"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="52.8 64.12 38.66 56.09 24.52 64.12 38.66 72.2 52.8 64.12"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="52.97 46.13 38.83 38.1 24.69 46.13 38.83 54.21 52.97 46.13"
    />
    <polygon
      strokeWidth={0}
      points="68.76 37.14 54.62 29.11 40.49 37.14 54.62 45.21 68.76 37.14"
      opacity={0.4}
    />
    <polygon
      strokeWidth={0}
      points="36.84 55.13 22.7 47.1 8.56 55.13 22.7 63.2 36.84 55.13"
      opacity={0.8}
    />
    <polygon
      strokeWidth={0}
      points="68.93 73.17 54.79 65.14 40.66 73.17 54.79 81.25 68.93 73.17"
    />
    <polygon
      strokeWidth={0}
      points="100.51 73.17 86.38 65.14 72.24 73.17 86.38 81.25 100.51 73.17"
      opacity={0.9}
    />
    <polygon
      strokeWidth={0}
      points="84.72 82.17 70.59 74.14 56.45 82.17 70.59 90.25 84.72 82.17"
      opacity={0.5}
    />
  </svg>
);
export default LayerHorizontalIcon;
