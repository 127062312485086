import * as React from 'react';
const PalletStackingResortIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PalletStackingResortIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M62.01,111.25v-23.84s-38.98,0-38.98,0v23.84s38.98,0,38.98,0Z"
    />
    <path
      strokeWidth={0}
      d="M20.26,111.25v-23.84s-16.26,0-16.26,0v23.84s16.26,0,16.26,0Z"
    />
    <path
      strokeWidth={0}
      d="M42.75,64.61v19.81s12.26,0,12.26,0v-19.81s-12.26,0-12.26,0Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M11.59,64.61v19.81s27.63,0,27.63,0v-19.81s-27.63,0-27.63,0Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M50.54,45.26h-23.84s0,16.26,0,16.26h23.84s0-16.26,0-16.26Z"
    />
    <path
      strokeWidth={0}
      d="M23.24,61.25V25.42s-16.26,0-16.26,0v35.84s16.26,0,16.26,0Z"
    />
    <path
      strokeWidth={0}
      d="M59.01,115.95v-2.2H4v2.2h2.83v7.24h-2.83v2.2h55.01v-2.2h-2.83v-7.24h2.83ZM11.17,115.95h18.17v7.24H11.17v-7.24ZM33.68,123.19v-7.24h18.17v7.24h-18.17Z"
    />
    <path
      strokeWidth={0}
      d="M123.51,111.25v-23.84s-36.98,0-36.98,0v23.84s36.98,0,36.98,0Z"
    />
    <path
      strokeWidth={0}
      d="M83.76,111.25v-23.84s-15.26,0-15.26,0v23.84s15.26,0,15.26,0Z"
    />
    <path
      strokeWidth={0}
      d="M96.54,64.61v19.81s26.98,0,26.98,0v-19.81s-26.98,0-26.98,0Z"
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M68.67,64.61v19.81s25.26,0,25.26,0v-19.81s-25.26,0-25.26,0Z"
      opacity={0.8}
    />
    <path
      strokeWidth={0}
      d="M123.51,115.95v-2.2h-55.01v2.2h2.83v7.24h-2.83v2.2h55.01v-2.2h-2.83v-7.24h2.83ZM75.67,115.95h18.17v7.24h-18.17v-7.24ZM98.17,123.19v-7.24h18.17v7.24h-18.17Z"
    />
    <path
      strokeWidth={0}
      d="M96.11,58.72l-12.16-12.15v-9.16s8.91,8.91,8.91,8.91v-5.68c0-4.79-1.88-9.3-5.29-12.72-3.41-3.41-7.93-5.29-12.72-5.29h-38.08s0-6.48,0-6.48h38.08c6.52,0,12.66,2.55,17.3,7.19,4.64,4.64,7.19,10.78,7.19,17.3v5.68s8.91-8.91,8.91-8.91v9.16s-12.15,12.15-12.15,12.15Z"
    />
  </svg>
);
export default PalletStackingResortIcon;
