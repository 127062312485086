import * as React from 'react';
const CircleArrowBottomTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowBottomTopIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M3.95,63.95c0,33.08,26.94,60.03,60.05,60.05,33.12.03,60.05-26.86,60.05-59.95,0-33.08-26.94-60.03-60.05-60.05C30.88,3.97,3.95,30.86,3.95,63.95ZM69.15,63.28v29.29s14.67-14.63,14.67-14.63v15.06s-20,19.95-20,19.95l-20-19.98v-15.06s14.66,14.65,14.66,14.65v-29.29s10.67,0,10.67,0ZM58.48,63.26v-28.56s-14.67,14.63-14.67,14.63v-15.06s20-19.95,20-19.95l20,19.98v15.06s-14.66-14.65-14.66-14.65v28.56s-10.67,0-10.67,0Z"
    />
  </svg>
);
export default CircleArrowBottomTopIcon;
