import {
  LocationFilterFragment,
  LocationFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { LocationFilterType } from '@warebee/shared/engine-model';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { FilterFieldConfigBase } from '../../../../common/types';
import PolicyFilterValueEditor, {
  PolicyFilterValueEditorProps,
} from '../../../../policyFilters/PolicyFilterValueEditor';
import { LoadLocationFilterValuesParams } from '../../../../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../../../store/assignmentPolicy.default';
import { assignmentOccupancyLocationFilter } from '../../../store/occupancy/assignmentOccupancy.state';

export type AssignmentOccupancyFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<LocationFilterType>;
  startLoadData: (params: LoadLocationFilterValuesParams) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const AssignmentOccupancyFilterEditor: React.FC<
  AssignmentOccupancyFilterEditorProps
> = props => {
  const { t } = useTranslation('dataset');
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;
  const [locationMatch, setLocationMatch] = useRecoilState(
    assignmentOccupancyLocationFilter,
  );

  const filterIntersection = _.head(locationMatch.anyOf);
  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const filterConfigs = getLocationFilterConfigCommon(t);

  const onSelectValue = (value: LocationFilterFragment) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);
    // If all values vas deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn || value.stringRange || value.range) ||
      value.isNot
    ) {
      others.push(value as LocationFilterFragment);
    }

    const newIntersection: LocationFilterIntersectionFragment = {
      id: filterIntersection.id,
      allOf: others,
    };

    setLocationMatch({ anyOf: [newIntersection] });

    startLoadData({
      triggeredField: type,
      filterConfigs: filterConfigs,
      affect: 'others',
      filterIntersection: newIntersection,
    });
  };

  const editorProps: PolicyFilterValueEditorProps<LocationFilterType> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params =>
      startLoadData({
        ...params,
        triggeredField: type,
        filterConfigs,
        affect: 'self',
        filterIntersection,
      }),
    cancelLoadData,
  };

  return <PolicyFilterValueEditor {...editorProps} />;
};
export default AssignmentOccupancyFilterEditor;
