import * as React from 'react';
const CalendarTimeIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CalendarTimeIcon"
    {...props}
  >
    <rect width={7.28} height={8.56} x={26.06} y={2} strokeWidth={0} />
    <rect width={7.27} height={8.56} x={58.38} y={2} strokeWidth={0} />
    <rect width={7.28} height={8.56} x={90.71} y={2} strokeWidth={0} />
    <polygon
      strokeWidth={0}
      points="120.04 10.64 97.73 10.64 97.73 23.81 90.5 23.81 90.5 10.64 65.63 10.64 65.63 23.81 58.41 23.81 58.41 10.64 33.54 10.64 33.54 23.81 26.32 23.81 26.32 10.64 5.18 10.64 5.18 33.16 120.04 33.16 120.04 10.64"
    />
    <path
      strokeWidth={0}
      d="M97.56,66.13c8.12,0,15.59,2.83,21.44,7.58l.14-.12c.33-.29.52-.7.52-1.12v-32.2H4.79s0,72.02,0,72.02c0,2.81,2.29,5.08,5.08,5.08h56.71c.35,0,.68-.12.93-.33l.14-.12c-2.75-4.92-4.3-10.57-4.3-16.58,0-18.86,15.35-34.22,34.22-34.22ZM98.93,45.91c0-.81.66-1.45,1.45-1.45h11.4c.79,0,1.45.64,1.45,1.45v11.38c0,.81-.66,1.45-1.45,1.45h-11.4c-.79,0-1.45-.64-1.45-1.45v-11.38ZM77.01,45.91c0-.81.64-1.45,1.45-1.45h11.38c.81,0,1.45.64,1.45,1.45v11.38c0,.81-.64,1.45-1.45,1.45h-11.38c-.81,0-1.45-.64-1.45-1.45v-11.38ZM55.08,45.91c0-.81.64-1.45,1.45-1.45h11.38c.81,0,1.45.64,1.45,1.45v11.38c0,.81-.64,1.45-1.45,1.45h-11.38c-.81,0-1.45-.64-1.45-1.45v-11.38ZM33.15,45.91c0-.81.64-1.45,1.45-1.45h11.38c.81,0,1.45.64,1.45,1.45v11.38c0,.81-.64,1.45-1.45,1.45h-11.38c-.81,0-1.45-.64-1.45-1.45v-11.38ZM25.51,97.01c0,.81-.66,1.45-1.45,1.45h-11.4c-.79,0-1.45-.64-1.45-1.45v-11.38c0-.81.66-1.45,1.45-1.45h11.4c.79,0,1.45.64,1.45,1.45v11.38ZM25.51,77.16c0,.79-.66,1.45-1.45,1.45h-11.4c-.79,0-1.45-.66-1.45-1.45v-11.4c0-.79.66-1.45,1.45-1.45h11.4c.79,0,1.45.66,1.45,1.45v11.4ZM25.51,57.29c0,.81-.66,1.45-1.45,1.45h-11.4c-.79,0-1.45-.64-1.45-1.45v-11.38c0-.81.66-1.45,1.45-1.45h11.4c.79,0,1.45.64,1.45,1.45v11.38ZM47.44,97.01c0,.81-.64,1.45-1.45,1.45h-11.38c-.81,0-1.45-.64-1.45-1.45v-11.38c0-.81.64-1.45,1.45-1.45h11.38c.81,0,1.45.64,1.45,1.45v11.38ZM47.44,77.16c0,.79-.64,1.45-1.45,1.45h-11.38c-.81,0-1.45-.66-1.45-1.45v-11.4c0-.79.64-1.45,1.45-1.45h11.38c.81,0,1.45.66,1.45,1.45v11.4ZM55.08,77.16v-11.4c0-.79.64-1.45,1.45-1.45h11.38c.81,0,1.45.66,1.45,1.45v11.4c0,.79-.64,1.45-1.46,1.45h-11.38c-.81,0-1.45-.66-1.45-1.45Z"
    />
    <path
      strokeWidth={0}
      d="M97.56,125c-13.59,0-24.65-11.06-24.65-24.65s11.06-24.65,24.65-24.65,24.65,11.06,24.65,24.65-11.06,24.65-24.65,24.65ZM97.56,115.16c-1.26,0-2.29,1.03-2.29,2.29v4.34c0,1.26,1.03,2.29,2.29,2.29s2.29-1.03,2.29-2.29v-4.34c0-.6-.24-1.19-.67-1.62-.43-.43-1.01-.67-1.62-.67ZM98.04,105.03c.31,0,.6-.06.87-.17l9.95-7.3c.47-.19.9-.65,1.13-1.24.27-.69.24-1.51-.09-2.25-.47-1.06-1.45-1.75-2.49-1.75-.3,0-.6.06-.88.18l-8.34,5.74-8.78-10.23c-.84-1.03-1.53-1.67-2.67-1.67h0c-.4,0-.79.08-1.16.23-.73.31-1.3.89-1.6,1.62-.3.74-.29,1.55.01,2.28l.06.15,11.08,12.9c.73.94,1.74,1.51,2.75,1.51h-.04c.07,0,.14,0,.2,0ZM111.02,99.06c-1.26,0-2.29,1.03-2.29,2.29,0,.61.24,1.19.67,1.62.42.42,1.01.67,1.62.67h6.98c1.26,0,2.29-1.03,2.29-2.29s-1.03-2.29-2.29-2.29h-6.98ZM77.11,99.06c-1.26,0-2.29,1.03-2.29,2.29s1.03,2.29,2.29,2.29h6.98c1.26,0,2.29-1.03,2.29-2.29s-1.03-2.29-2.29-2.29h-6.98ZM97.56,78.61c-1.26,0-2.29,1.03-2.29,2.29v4.34c0,.61.24,1.19.67,1.62.42.42,1.01.67,1.62.67s1.19-.24,1.62-.67c.43-.43.67-1.01.67-1.62v-4.34c0-1.26-1.03-2.29-2.29-2.29Z"
    />
  </svg>
);
export default CalendarTimeIcon;
