import * as React from 'react';
const RoutingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="RoutingIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="63.04 82.89 17.71 97.87 17.71 124 110.29 124 110.29 97.87 63.04 82.89"
    />
    <path
      strokeWidth={0}
      d="M47.78,19.39h32.45c1.35,0,2.46-1.1,2.46-2.45v-4.27c0-1.37-1.11-2.45-2.46-2.45h-10.01c0-3.44-2.77-6.21-6.21-6.21s-6.21,2.77-6.21,6.21h-10.02c-1.35,0-2.45,1.08-2.45,2.45v4.27c0,1.35,1.1,2.45,2.45,2.45ZM64,7.76c1.35,0,2.46,1.08,2.46,2.45s-1.1,2.45-2.46,2.45-2.45-1.1-2.45-2.45,1.11-2.45,2.45-2.45Z"
    />
    <path
      strokeWidth={0}
      d="M92.75,14.81h-7.71v2.12c0,2.66-2.17,4.83-4.81,4.83h-32.45c-2.66,0-4.83-2.17-4.83-4.83v-2.12h-7.71c-2.71,0-4.92,2.19-4.92,4.9v70.78l32.97-10.82,34.37,10.76V19.71c0-2.71-2.19-4.9-4.92-4.9ZM35.96,30.04h43.99s-3.65-3.65-3.65-3.65h6.58l5.98,5.98-5.97,5.98h-6.58l3.65-3.65h-39.15v24.17h37.82v4.84h-42.66V30.04ZM89.06,76.08h-53.11v-4.84h48.27v-21.17h-29.15l3.65,3.65h-6.58l-5.97-5.98,5.98-5.98h6.58l-3.65,3.65h33.99v30.66Z"
    />
  </svg>
);
export default RoutingIcon;
