import _ from 'lodash';
import { atom, selector } from 'recoil';
import {
  HeatmapBucket,
  HeatmapBucketSortDirection,
  HeatmapBucketStatField,
} from '../../common/heatmap.helper';
import {
  HeatmapMetricDescriptor,
  HeatmapMetricRange,
} from '../../common/heatmap.types';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import {
  viewerSelectedBay,
  viewerSelectedLevel,
} from '../../layout/viewer/store/viewer.state';
import { assignmentMetricDescriptorsMap } from '../../metrics/assignment/assignmentMetric.default';
import { getAssignmentHeatmapBuckets } from '../../metrics/assignment/assignmentMetric.helper';
import {
  AssignmentMetric,
  AssignmentMetricDataRow,
  AssignmentMetricDescriptorBase,
} from '../../metrics/assignment/assignmentMetric.types';
import { getProductCategory } from '../../simulation/store/abc/simulation.ABC.helper';
import { HeatmapFilter } from '../../simulation/store/simulation.types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  actualityExtraSettings,
  actualitySelectedDocument,
} from './actuality.state';
import { DatasetExtraFields } from './actuality.types';
import { getAssignmentHeatmapMetricRange } from './assignment.heatmap.helper';
import {
  AssignmentHeatmapDataQueryParams,
  AssignmentHeatmapDataRow,
  getAssignmentHeatmapDataQuery,
} from './datasetQueries/assignmentHeatmapData';
import {
  AssignmentMetricSummaryRow,
  getAssignmentMetricSummary,
} from './datasetQueries/assignmentHq';
import { AssignmentHqField } from './datasetQueries/assignmentQueryBuilder';
import { executeDatasetQuery } from './feed.helper';
import { feedItemsWithRankMap } from './feed.state';

const getKey = (postfix: string) => `warebee-assignment-heatmap-${postfix}`;

export const assignmentExtraDatasetFields = selector<DatasetExtraFields[]>({
  key: getKey('extra-dataset-fields'),
  get: ({ get }) => get(actualityExtraSettings).assignmentExtraFields ?? [],
});

export const assignmentExtraMetricDescriptors = selector<
  HeatmapMetricDescriptor<string, AssignmentMetricDataRow>[]
>({
  key: getKey('extra-extra-metric-descriptors'),
  get: ({ get }) =>
    get(actualityExtraSettings).assignmentMetricDescriptors ?? [],
});

export const assignmentHeatmapBuilderParams =
  selector<AssignmentHeatmapDataQueryParams>({
    key: getKey('heatmap-query-builder-params'),
    get: ({ get }) => {
      const actuality = get(actualitySelectedDocument);
      const metric = get(assignmentMetricDescriptor);
      const assignmentExtraFields = get(assignmentExtraDatasetFields);
      return {
        itemSetId: actuality.itemSetId,
        layoutId: actuality.layoutId,
        assignmentId: actuality.assignmentId ?? 'dummy',
        groupBy: metric.path as any, // todo : correct type
        aggregationFn: metric.aggregationFn ?? 'min',
        filterBy: metric.filterBy,
        assignmentExtraFields: assignmentExtraFields,
      };
    },
  });

export const assignmentMetricSelected = atom<AssignmentMetric>({
  key: getKey('metric-selected'),
  default: 'abc',
});

export const assignmentMetricDescriptor =
  selector<AssignmentMetricDescriptorBase>({
    key: getKey('metric-descriptor'),
    get: ({ get }) => {
      const metric = get(assignmentMetricSelected);
      const extraDescriptors = _.keyBy(
        get(assignmentExtraMetricDescriptors) ?? [],
        'type',
      );
      return (
        assignmentMetricDescriptorsMap[metric] ??
        (extraDescriptors[metric] as AssignmentMetricDescriptorBase)
      );
    },
  });

export const assignmentMetricHasStats = selector<boolean>({
  key: getKey('metric-has-stats'),
  get: ({ get }) => {
    const metricDescriptor = get(assignmentMetricDescriptor);
    return (
      metricDescriptor.rangeType === 'distinct' &&
      metricDescriptor.type !== 'abc'
    );
  },
});

export const assignmentHeatmapFilters = atom<HeatmapFilter>({
  key: getKey('heatmap-filters'),
  default: {
    hiddenBuckets: {},
  },
});

export const assignmentHeatmapSummary = selector<AssignmentMetricSummaryRow[]>({
  key: getKey('summary'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(assignmentMetricDescriptor);

    if (_.isNil(actuality.itemSetId) || _.isNil(actuality.layoutId)) {
      return null;
    }

    const queryParamsBase = get(assignmentHeatmapBuilderParams);

    const compiledQuery = getAssignmentMetricSummary({
      ...queryParamsBase,
      aggregateBy: (metric.aggregateBy ?? metric.path) as any,
      groupBy: metric.path as any, // todo : correct type
      aggregationFn: metric.aggregationFn ?? 'min',
      filterBy: metric.filterBy,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Assignment Heatmap Summary',
    });

    return result as AssignmentMetricSummaryRow[];
  },
});

export const assignmentHeatmapRangeDescriptor = selector<HeatmapMetricRange>({
  key: getKey('heatmap-range-descriptor'),
  get: ({ get }) => {
    const metricDescriptor = get(assignmentMetricDescriptor);
    const heatmapSummary = get(assignmentHeatmapSummary);
    return getAssignmentHeatmapMetricRange(metricDescriptor, heatmapSummary);
  },
});

const assignmentHeatmapBucketsSortByAtom = persistAtom<HeatmapBucketStatField>({
  key: getKey('heatmap-buckets-sort-by-atom'),
  default: null,
});

export const assignmentHeatmapBucketsSortBy = selector<HeatmapBucketStatField>({
  key: getKey('heatmap-buckets-sort-by'),
  get: ({ get }) => {
    const hasStats = get(assignmentMetricHasStats);
    if (!hasStats) {
      return null;
    }
    return get(assignmentHeatmapBucketsSortByAtom);
  },

  set: ({ set }, value) => set(assignmentHeatmapBucketsSortByAtom, value),
});

export const assignmentHeatmapBucketsSortDirection =
  persistAtom<HeatmapBucketSortDirection>({
    key: getKey('heatmap-buckets-sort-direction'),
    default: 'desc',
  });

const assignmentHeatmapBucketsInner = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets-inner'),
  get: ({ get }) => {
    // const namedColors = get(namedColorsGroupSelected);
    const metricDescriptor = get(assignmentMetricDescriptor);
    const rangeDescriptor = get(assignmentHeatmapRangeDescriptor);
    if (_.isNil(rangeDescriptor)) return null;

    return getAssignmentHeatmapBuckets(rangeDescriptor, metricDescriptor);
  },
});

export const assignmentHeatmapBuckets = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets'),
  get: ({ get }) => {
    const buckets = get(assignmentHeatmapBucketsInner);
    const sortBy = get(assignmentHeatmapBucketsSortBy);
    const sortDirection = get(assignmentHeatmapBucketsSortDirection);

    return _.orderBy(
      buckets,
      b => {
        if (sortBy && b.stats) {
          return b.stats?.[sortBy];
        }
        return b.index;
      },
      sortDirection,
    );
  },
});

export const assignmentHeatmapLevelData = selector<AssignmentHeatmapDataRow[]>({
  key: getKey('actuality-heatmap-by-level'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(assignmentMetricDescriptor);
    const level = get(viewerSelectedLevel);

    if (_.isNil(actuality.itemSetId) || _.isNil(actuality.layoutId)) {
      return null;
    }

    const queryParamsBase = get(assignmentHeatmapBuilderParams);

    const compiledQuery = getAssignmentHeatmapDataQuery({
      ...queryParamsBase,
      locationLevel: level,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap By Level',
    });

    if (metric.type === 'abc') {
      const itemsWithRank = get(feedItemsWithRankMap);
      _.forEach(result, row => {
        row.dimensionValue = itemsWithRank[row.skuKey]
          ? getProductCategory(itemsWithRank[row.skuKey].cmlPercentRank)?.key
          : 'D';
      });
    }

    return result as any as AssignmentHeatmapDataRow[];
  },
});

export const assignmentHeatmapBayData = selector<AssignmentHeatmapDataRow[]>({
  key: getKey('actuality-heatmap-by-bay'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(assignmentMetricDescriptor);
    const bay = get(viewerSelectedBay);

    if (
      _.isNil(actuality.itemSetId) ||
      _.isNil(actuality.layoutId) ||
      _.isNil(bay)
    ) {
      return null;
    }

    const queryParamsBase = get(assignmentHeatmapBuilderParams);

    const compiledQuery = getAssignmentHeatmapDataQuery({
      ...queryParamsBase,
      bayId: bay.id,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap By Bay',
    });

    if (metric.type === 'abc') {
      const itemsWithRank = get(feedItemsWithRankMap);
      _.forEach(result, row => {
        row.dimensionValue = itemsWithRank[row.skuKey]
          ? getProductCategory(itemsWithRank[row.skuKey].cmlPercentRank)?.key
          : null;
      });
    }

    return result as any as AssignmentHeatmapDataRow[];
  },
});

export const assignmentHeatmapLocationByLevel = selector<
  Record<number, number>
>({
  key: getKey('location-by-level'),
  get: ({ get }) => {
    const buckets = get(assignmentHeatmapBuckets);
    const bucketsFilter = get(assignmentHeatmapFilters);
    const affectedBuckets = _.filter(
      buckets,
      b => !bucketsFilter.hiddenBuckets[b.id],
    );

    return _(affectedBuckets)
      .map(b => _.entries(b.levelStats))
      .flatten()
      .reduce((acc, [level, levelStat]) => {
        return {
          ...acc,
          [level]: levelStat.totalExistedLocations + (acc[level] ?? 0),
        };
      }, {});
  },
});

export const assignmentHeatmapTableData = atom<AssignmentHeatmapDataRow[]>({
  key: getKey('heatmap-table-data'),
  default: [],
});

export const assignmentHeatmapTableTotalCount = atom<number>({
  key: getKey('heatmap-table-total-count'),
  default: 0,
});

export const assignmentHeatmapTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('heatmap-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const assignmentHeatmapTableDataState = atom<
  DataTableState<AssignmentHqField>
>({
  key: getKey('heatmap-table-data-state'),
  default: {
    sortValues: {
      // dimensionValue: SortDirection.DESC,
    },
    searchValues: {},
  },
});
