import {
  MeasurementSystem,
  WaypointType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { Layer } from 'react-konva';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { routingPolicyRoutes } from '../../../simulation/store/routingPolicy/routingPolicy.state';
import { warehouseMeasurementSystem } from '../../../store/warehouse.state';
import RouteFeature from '../../features/RouteFeature';

const RoutesLayer: React.FC = () => {
  const routesSim = useRecoilValueLoadable(routingPolicyRoutes);
  const measureSystem = useRecoilValue(warehouseMeasurementSystem);

  if (routesSim.state !== 'hasValue') return null;
  const routes = [];
  if (routesSim.state === 'hasValue') {
    routes.push(...(routesSim.contents ?? []));
  }

  const scaleFactor = measureSystem === MeasurementSystem.IMPERIAL ? 0.4 : 1;
  let index = 1;
  return (
    <Layer>
      {_.map(routes, r => {
        return (
          <RouteFeature
            key={`route-with-distance-${r.distance}`}
            waypoints={_.map(r.waypoints, (w, i) => ({
              ...w,
              label: w.type === WaypointType.LOCATION ? `${index++}` : null,
            }))}
            animate
            showLabels
            scale={scaleFactor}
          />
        );
      })}
    </Layer>
  );
};

export default RoutesLayer;
