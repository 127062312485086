import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputCheckbox from '../../../../components/inputs/InputCheckbox';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';
import { simulationUoms } from '../../../store/simulation.state';

const AllocationPolicyUOMEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const uoms = useRecoilValue(simulationUoms);
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);

  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  const selected = rule?.uomTypes ?? [];
  const selectedSet = new Set(rule?.uomTypes);

  const onSelectValue = (value: string) => {
    updateRule({
      ...rule,
      uomTypes: selectedSet.has(value)
        ? _.filter(selected, v => v !== value)
        : [...selected, value],
    });
  };

  return (
    <div data-component="AllocationPolicyUOMEditor">
      <TitleSection
        id={`policy-editor-uoms`}
        // collapsible
        title={t`Allocation UOM`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`bg-app-panel-dark/60 flex flex-col p-1`}>
            <div
              className={`relative flex h-full min-h-[3rem] flex-1 flex-col`}
            >
              {_.map(uoms, (uom, index) => {
                return (
                  <InputCheckbox
                    isHoverable
                    className="ltr:pl-4 rtl:pr-4"
                    key={`chk-${uom}`}
                    isSelected={selectedSet.has(uom)}
                    value={uom}
                    onChange={() => onSelectValue(uom)}
                  >
                    {uom}
                  </InputCheckbox>
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default AllocationPolicyUOMEditor;
