import * as React from 'react';
const MheForklift2Icon = props => (
  <svg
    aria-hidden="true"
    data-name="MheForklift2Icon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m76.28,51.41c-.6-.47-1.5-.35-1.98.25l-6.27,8.08c-.48.6-.38,1.47.25,1.95.48.42,1.35.47,1.95-.22l2.25-2.88,8.35,7.12v-3.68l-6.62-5.67,2.33-2.97c.48-.6.35-1.5-.25-1.98Z" />
    <path d="m83.32,42.46l-15.73-15.68c-1.2-1.2-2.85-1.9-4.55-1.9h-23.83c-3.55,0-6.42,2.88-6.42,6.42v24.22h6.42v-24.22h23.85l20.25,20.22v29.23H4v11.3c0,3.55,2.88,6.42,6.42,6.42h1.75c0-7.58,6.15-13.72,13.72-13.72s13.73,6.15,13.73,13.72h22.7c0-7.58,6.15-13.72,13.73-13.72s13.4,5.85,13.7,13.15c2.25-1.02,3.8-3.25,3.8-5.85V18.29h-10.23v24.18Z" />
    <path d="m102.42,98.29c-2.23,0-4-1.8-4-4v-31.4h-2.38v29.17c0,2.02-.67,3.9-1.85,5.4,1.27,3.3,4.48,5.65,8.23,5.65h21.58v-4.83h-21.58Z" />
    <path d="m63.25,64.31c-1.4-3.75-5-6.28-9.03-6.28H15.45c-2.95,0-5.52,2.03-6.23,4.88l-4.5,15.35h64.73l-6.2-13.95Z" />
    <path d="m25.9,87.26c-6.2,0-11.22,5.02-11.22,11.22s5.02,11.22,11.22,11.22,11.23-5.02,11.23-11.22-5.03-11.22-11.23-11.22Zm0,16.02c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.83,4.8-4.83,4.83,2.18,4.83,4.83-2.17,4.8-4.83,4.8Z" />
    <path d="m76.05,87.26c-6.2,0-11.23,5.02-11.23,11.22s5.02,11.22,11.23,11.22,11.23-5.02,11.23-11.22-5.02-11.22-11.23-11.22Zm0,16.02c-2.65,0-4.83-2.15-4.83-4.8s2.17-4.83,4.83-4.83,4.83,2.18,4.83,4.83-2.18,4.8-4.83,4.8Z" />
    <path d="m42.03,43.01h-.3v12.52h10.08l-3.75-8.3c-.92-2.55-3.33-4.22-6.02-4.22Z" />
  </svg>
);
export default MheForklift2Icon;
