import * as React from 'react';
const PickingAgentCarryingIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PickingAgentCarryingIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M116.74,101.88c-.4-1.36-.62-1.78-1.5-1.92h-.64l-13.8,6.52c-1.92-2.9-5.15-4.87-8.84-5.05-.2-.01-.4-.03-.59-.03-.23,0-.45.01-.68.03-1.67-2.08-2.56-4.66-2.83-5.42-3.85-11.43-7.83-22.83-11.75-34.23-.74-2.11-1.63-3.76-4.22-4.32-1.12-.24-2.25-.41-2.99-.28-.61-2.43-2.77-3.37-6.01-3.52-7.88-.4-14.25-3.24-17.77-10.27,1.43-4.7,2.24-11.89-1.15-15.48-3.17-3.34-7.17-2.53-10.85-.72-3.07,1.53-5.26,4.13-6.45,7.34-3.41,9.24-5.14,18.65-4.39,28.68.44,5.84,4.25,10.53,4.25,16.32-.03,11.69-3.86,21.78-12.07,30.24-4.16,4.3-4.37,8.75-.98,11.75,3.48,3.08,7.56,2.36,11.76-2.11,4.71-5,8.43-10.66,11.67-17.87.68,5.22-1.67,8.8-2.31,12.68-.65,3.99.25,7.09,4.3,8.42,4.12,1.37,7.42.16,9.04-4.06,5.02-13.02,6.96-26.53.16-39.14-4.06-7.51-5.56-14.75-5.02-22.87,4.03,1.67,7.49,4.75,11.93,5.35,4.77.62,9.85,2.59,13.95-1.81,0,0,.01,0,.01.01l.14-.17c4.42,12.81,8.72,25.64,13.32,38.38.95,2.66,1.37,5.56-.35,7.94-.01.01-.01.03-.01.03-1.26,1.83-2.01,4.03-2.01,6.41,0,6.24,5.07,11.31,11.31,11.31s11.31-5.07,11.31-11.31c0-.06-.01-.13-.01-.2.52-3.23,2.48-4.13,5.15-4.91,1.68-.5,3.35-.99,5.02-1.54,2.07-.68,4.67-1.46,3.91-4.16ZM91.12,117.53c-2.42,0-4.37-1.95-4.37-4.37s1.95-4.37,4.37-4.37,4.37,1.97,4.37,4.37-1.95,4.37-4.37,4.37Z"
    />
    <path
      strokeWidth={0}
      d="M47.71,24.86c5.66.04,10.39-4.79,10.37-10.6-.02-5.61-4.51-10.18-10.09-10.26-5.87-.09-10.66,4.46-10.75,10.19-.08,5.53,4.92,10.63,10.48,10.67Z"
    />
    <path
      strokeWidth={0}
      d="M99.87,104.05c.67.74,1.72.99,2.63.58l7.78-3.47c2.58-1.15,4.65-2.18,4.6-2.29-.05-.11-1.03-2.31-2.18-4.88l-5.54-12.43c-.51-1.15-1.86-1.67-3.01-1.15l-15.42,6.88c-1.15.51-1.67,1.86-1.15,3.01l3.92,8.79c.31.7.96,1.21,1.72,1.33,2.63.41,4.95,1.73,6.65,3.62Z"
    />
    <path
      strokeWidth={0}
      d="M101.95,80.75l-11.79,5.24c-1.58.7-3.43,0-4.14-1.59l-5.24-11.79c-.7-1.58,0-3.43,1.59-4.14l11.79-5.24c1.58-.7,3.43,0,4.14,1.59l5.24,11.79c.7,1.58,0,3.43-1.59,4.14Z"
    />
  </svg>
);
export default PickingAgentCarryingIcon;
