import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { getHeatmapBucketByValue } from '../../common/heatmap.helper';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import { analyzeProductMetricDescriptor } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { simulationLayoutHeatmapFilters } from '../../simulation/store/simulation.layout.state';
import {
  analyzeCompareBuckets,
  analyzeCompareHeatmapRangeDescriptor,
  analyzeCompareLocationByLevel,
} from '../analyzeCompare.heatmap.state';
import { AnalyzeCompareAnalyzeType } from '../analyzeCompare.types';

export type AnalyzeCompareLocationLayerProps = {
  analyzeType: AnalyzeCompareAnalyzeType;
};

const AnalyzeCompareLocationLayer: React.FC<
  AnalyzeCompareLocationLayerProps
> = props => {
  const levelLocationsLoadable = useRecoilValueLoadable(
    analyzeCompareLocationByLevel(props.analyzeType),
  );
  const rangeDescriptorLoadable = useRecoilValueLoadable(
    analyzeCompareHeatmapRangeDescriptor,
  );
  const heatmapFilter = useRecoilValue(simulationLayoutHeatmapFilters);
  const bucketsLoadable = useRecoilValueLoadable(analyzeCompareBuckets);
  const metricDescriptor = useRecoilValue(analyzeProductMetricDescriptor);
  const locations =
    levelLocationsLoadable.state === 'hasValue'
      ? levelLocationsLoadable.contents
      : null;

  const buckets =
    bucketsLoadable.state === 'hasValue' ? bucketsLoadable.contents : null;

  const rangeDescriptor =
    rangeDescriptorLoadable.state === 'hasValue'
      ? rangeDescriptorLoadable.contents
      : null;

  if (_.isNil(rangeDescriptor) || _.isNil(locations)) return null;

  const bucketsMap = _.keyBy(buckets, b => b.id);
  function getGradientColors(loc: LayoutLevelLocationFragment): string[] {
    const colors = _(locations[loc.locationId])
      .map(data =>
        getHeatmapBucketByValue({
          data,
          buckets: bucketsMap,
          metricDescriptor,
          rangeDescriptor,
        }),
      )
      .compact()
      .filter(b => b && heatmapFilter.hiddenBuckets[b.id] !== true)
      .sortBy(b => -b.index)
      .map(p => p.color)
      .uniq()
      .value();
    return _.isEmpty(colors) ? null : colors;
  }

  return (
    <LocationLayer getGradientColors={getGradientColors} getOpacity={() => 1} />
  );
};

export default AnalyzeCompareLocationLayer;
