import { LayoutLevelLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  applyColorOpacity,
  getQualitativeColor,
} from '../../common/color.helper';
import LocationLayer from '../../layout/viewer/layers/LocationLayer';
import {
  getWaypointPolicyLocationMatchKey,
  getWaypointPolicyOrderedLocationsMatch,
} from '../store/waypointPolicy/waypointPolicy.helper';
import {
  waypointPolicy,
  waypointPolicyLocationByLevel,
  waypointPolicySelectedIdentity,
} from '../store/waypointPolicy/waypointPolicy.state';

const SimulationWaypointPolicyLocationLayer: React.FC = () => {
  const policy = useRecoilValue(waypointPolicy);
  const locationMapLoadable = useRecoilValueLoadable(
    waypointPolicyLocationByLevel,
  );
  const selectedIdentity = useRecoilValue(waypointPolicySelectedIdentity);

  const locationMap =
    locationMapLoadable.state === 'hasValue'
      ? locationMapLoadable.contents
      : null;

  if (_.isNil(locationMap)) return null;

  const orderedRulesDesc = getWaypointPolicyOrderedLocationsMatch(policy);
  const orderedRulesMap = _.keyBy(orderedRulesDesc, d => d.index);

  const getGradientColors = (
    location: LayoutLevelLocationFragment,
  ): string[] => {
    const locId = location.locationId;
    const loc = locationMap?.[locId];
    if (_.isNil(loc)) return null;
    const colors = _(loc?.allMatchingFilters)
      .map(ruleIndex => orderedRulesMap[ruleIndex])
      .compact()
      .map(matchDescriptor => {
        const colors = getQualitativeColor(
          matchDescriptor.ruleId,
          matchDescriptor.groupType === 'start'
            ? 'waypointStart'
            : 'waypointEnd',
        );
        const bgColor = colors[0];
        return _.isNil(selectedIdentity) ||
          selectedIdentity.ruleId === matchDescriptor.ruleId
          ? bgColor
          : applyColorOpacity(bgColor, 0.4);
      })
      .value();
    return colors;
  };

  return (
    <LocationLayer getGradientColors={getGradientColors} getOpacity={() => 1} />
  );
};

export default SimulationWaypointPolicyLocationLayer;
