import {
  LoadStackingPolicyItemsDocument,
  LoadStackingPolicyItemsQuery,
  LoadStackingPolicyItemsQueryVariables,
  LoadStackingPolicyStatsDocument,
  LoadStackingPolicyStatsQuery,
  LoadStackingPolicyStatsQueryVariables,
  StackingPolicyFragment,
  StackingPolicyInput,
  StackingPolicyRuleFragment,
  StackingPolicyRuleInput,
  StackingPolicyRuleSubcategoryType,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { TwTheme } from '../../../../Tw';
import { secureClient } from '../../../GraphQLClient';
import { getSequentialColor } from '../../../common/color.helper';
import { cloneWithoutTypename } from '../../../common/utils';
import { getPolicyMatchInput } from '../../../policyFilters/policyFilter.helper';
import { STACKING_POLICY_DEFAULT_RULE_ID } from './stackingPolicy.default';
import { StackingPolicyItem } from './stackingPolicy.types';

const priorityColors = TwTheme.extend.colors.priority;
const priorityTextColors = TwTheme.extend.colors.priorityText;

function getStackingPolicyRuleInput(
  rule: StackingPolicyRuleFragment,
): StackingPolicyRuleInput {
  return {
    ...cloneWithoutTypename(rule),
    itemsMatch: getPolicyMatchInput(rule.itemsMatch),
  };
}

export function getStackingPolicyInput(
  policy: StackingPolicyFragment,
): StackingPolicyInput {
  return {
    ...cloneWithoutTypename(policy),
    topRules: _.map(policy?.topRules, getStackingPolicyRuleInput),
    bottomRules: _.map(policy?.bottomRules, getStackingPolicyRuleInput),
  };
}

type descriptorsMap = Record<
  StackingPolicyRuleSubcategoryType,
  { id: StackingPolicyRuleSubcategoryType; title: string }
>;

export function getStackingPolicySubcategoryTypeDescriptors(
  t: TFunction<'simulation'>,
): descriptorsMap {
  const descriptors = [
    {
      id: StackingPolicyRuleSubcategoryType.PICKLIST_LINE_WEIGHT,
      title: t('Picklist line weight', { ns: 'simulation' }),
    },
    {
      id: StackingPolicyRuleSubcategoryType.BASE_UOM_WEIGHT,
      title: t('Base UOM weight', { ns: 'simulation' }),
    },
    {
      id: StackingPolicyRuleSubcategoryType.PICKED_UOM_WEIGHT,
      title: t('Picked UOM weight', { ns: 'simulation' }),
    },
  ];
  return _.keyBy(descriptors, 'id') as descriptorsMap;
}

export function getIndexedStackingPolicyRulesTitles(
  policy: StackingPolicyFragment,
  t: TFunction<'simulation'>,
) {
  const indexedMap = {
    0: t(`Default (Middle)`, { ns: 'simulation' }),
  };

  _.forEachRight(
    policy.bottomRules,
    (r, index) => (indexedMap[index + 1] = r.title),
  );
  _.forEach(policy.topRules, (r, index) => (indexedMap[-index - 1] = r.title));

  return indexedMap;
}

export function getRuleColor(
  globalIndex: number,
  topCount: number,
  bottomCount: number,
): [string, string] {
  if (_.isNil(globalIndex))
    return [priorityColors.middle, priorityTextColors.middle];
  if (globalIndex < topCount) {
    return getSequentialColor((globalIndex * 100) / topCount, 'stacking-top');
  }
  return getSequentialColor(
    ((globalIndex - topCount) * 100) / bottomCount,
    'stacking-bottom',
  );
}

export type LoadStackingPolicyItemsParams = {
  layoutId: string;
  assignmentId: string;
  itemSetId: string;
  policy: StackingPolicyFragment;
  planeId: string;
  level?: number;
  bayId?: string;
};
export async function loadStackingPolicyItems(
  params: LoadStackingPolicyItemsParams,
) {
  if (
    !params.layoutId ||
    !params.assignmentId ||
    !params.itemSetId ||
    !params?.planeId
  )
    return null;

  const rules = [
    ...(params.policy.topRules ?? []),
    ...(params.policy.bottomRules ?? []),
  ];
  const topRulesCount = _.size(params.policy?.topRules);
  const bottomRulesCount = _.size(params.policy?.bottomRules);
  const filters = _.map(rules, r => getPolicyMatchInput(r.itemsMatch));

  const response = await secureClient.query<
    LoadStackingPolicyItemsQuery,
    LoadStackingPolicyItemsQueryVariables
  >({
    query: LoadStackingPolicyItemsDocument,
    variables: {
      input: {
        layoutId: params.layoutId,
        assignmentId: params.assignmentId,
        itemSetId: params.itemSetId,
        filters,
      },
      filter: {
        planeId: [params.planeId],
        bayId: _.isNil(params.bayId) ? null : [params.bayId],
        level: _.isNil(params.level) ? null : [params.level],
      },
    },
  });

  const locationMap = _.reduce(
    response.data?.applyAssignmentItemFilter?.content,
    (acc, data) => {
      const [color, textColor] = getRuleColor(
        data.matchingFilter,
        topRulesCount,
        bottomRulesCount,
      );
      return {
        ...acc,
        [data.locationId]: [
          ...(acc[data.locationId] ?? []),
          {
            ...data,
            color,
            policyRule: _.isNil(data.matchingFilter)
              ? null
              : rules[data.matchingFilter],
          },
        ],
      };
    },
    {} as Record<string, StackingPolicyItem[]>,
  );
  return locationMap;
}

export type LoadStackingPolicyStatsParams = {
  layoutId: string;
  assignmentId: string;
  itemSetId: string;
  policy: StackingPolicyFragment;
};
export async function loadStackingPolicyStats(
  params: LoadStackingPolicyStatsParams,
): Promise<Record<string, number>> {
  if (!params.layoutId || !params.assignmentId || !params.itemSetId)
    return null;

  const rules = [
    ...(params.policy.topRules ?? []),
    ...(params.policy.bottomRules ?? []),
  ];
  const filters = _.map(rules, r => getPolicyMatchInput(r.itemsMatch));

  const response = await secureClient.query<
    LoadStackingPolicyStatsQuery,
    LoadStackingPolicyStatsQueryVariables
  >({
    query: LoadStackingPolicyStatsDocument,
    variables: {
      input: {
        layoutId: params.layoutId,
        assignmentId: params.assignmentId,
        itemSetId: params.itemSetId,
        filters,
      },
    },
  });

  const result = response.data?.countAssignmentItemsByMatchingFilter;
  const ruleMatch = result?.matchesCount;
  const ruleMap = _.reduce(
    rules,
    (acc, rule, index) => ({
      ...acc,
      [rule.id]: ruleMatch[index],
    }),
    {} as Record<string, number>,
  );

  ruleMap[STACKING_POLICY_DEFAULT_RULE_ID] = result.unmatchedCount;
  return ruleMap;
}
