import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import ItemSetDataTable from './ItemSetDataTable';
import ItemSetFilterTag from './ItemSetFilterTag';
import { itemSetDocument } from './store/itemSet.state';
import classNames from 'classnames';

const ItemSetDataView: React.FC = () => {
  const { t } = useTranslation('dataset');
  const itemSet = useRecoilValue(itemSetDocument);

  return (
    <Container
      col
      flex1
      componentName="ItemSetDataView"
      className={classNames(
        'border-app-panel-dark relative ltr:border-l rtl:border-r',
      )}
    >
      <PanelHeader
        title={t`Items Data Table`}
        filterPlaceholder={<ItemSetFilterTag />}
      />
      <ItemSetDataTable id={itemSet?.id} title={itemSet?.title} />
    </Container>
  );
};

export default ItemSetDataView;
