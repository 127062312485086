import {
  LayoutImportConverterAreaFragment,
  LayoutImportLocation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AreaBuilderSettings,
  AreaConfiguration,
  LocationPatch,
  applyLocationPatch,
  getLocationPatchKey,
  groupLocations,
} from '@warebee/shared/data-access-layout-import-converter';
import { instanceToPlain } from 'class-transformer';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { warehouseMeasurementSystem } from '../../../../store/warehouse.state';
import {
  converterAreaConfiguration,
  converterSelectedFloor,
} from '../store/converter.area.state';
import {
  getDefaultAreaBuilderSettings,
  getLocationDefaultConfig,
} from '../store/converter.defaults';
import { applyLocationSettings } from '../store/converter.helper';
import { getDefaultLocationTypes } from '../store/converter.settings.helper';
import {
  converterAllAreasBuilder,
  converterBayTypesBuilder,
  converterLayoutData,
  converterLocationPatches,
  converterLocationTypesBuilder,
  converterPatchedLocations,
} from '../store/converter.state';

export type ApplyLocationPatchParams = {
  patch: LocationPatch;
};

function useApplyLocationPatch() {
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const [locations, setLocations] = useRecoilState(converterPatchedLocations);
  const [patches, setPatches] = useRecoilState(converterLocationPatches);

  const [locationTypesBuilder, setLocationTypesBuilder] = useRecoilState(
    converterLocationTypesBuilder,
  );
  const [bayTypeBuilder, setBayTypeBuilder] = useRecoilState(
    converterBayTypesBuilder,
  );
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAllAreasBuilder,
  );
  const setLayoutData = useSetRecoilState(converterLayoutData);
  const planeId = useRecoilValue(converterSelectedFloor);
  const [areaConfiguration, setAreaConfiguration] = useRecoilState(
    converterAreaConfiguration,
  );

  function applyPatch(params: ApplyLocationPatchParams) {
    const { locationKey } = params.patch;

    const patchBefore = _.find(
      _.values(patches),
      p =>
        p.patch.locationKey === locationKey ||
        getLocationPatchKey(p) === locationKey,
    );

    const patch: Partial<LayoutImportLocation> = {
      ...patchBefore?.patch,
      ...params.patch,
    };
    const locationBefore = locations[locationKey];
    const patchedLocations = applyLocationPatch(
      locations[locationKey],
      params.patch,
    );
    const newLocationsAll = { ...locations, [locationKey]: patchedLocations };

    const areasModified = [
      locations[locationKey].warehouseArea,
      patchedLocations.warehouseArea,
    ];
    const areasModifiedSet = new Set(areasModified);

    const recalculateLocations = _.filter(newLocationsAll, l =>
      areasModifiedSet.has(l.warehouseArea),
    );

    const areas = groupLocations(recalculateLocations).map(
      c => instanceToPlain(c) as LayoutImportConverterAreaFragment,
    );

    const newAreaId = params.patch.warehouseArea;
    const resultAreaIds = _.map(areas, a => a.area);
    const resultAreaIdsSet = new Set(resultAreaIds);
    const areasToDelete = _.filter(
      areasModified,
      areaId => !resultAreaIdsSet.has(areaId),
    );
    const areasToDeleteSet = new Set(areasToDelete);

    // CREATE NEW AREAS CONFIGS IF REQUIRED
    if (!_.isNil(newAreaId)) {
      const newAreaBuilder: AreaBuilderSettings = {
        ...(areaBuilder[locationBefore.warehouseArea] ??
          getDefaultAreaBuilderSettings(ms)),
        id: newAreaId,
      };
      const targetPosition = { x: 0, y: 0 };
      const newAreaConfiguration: AreaConfiguration = {
        ...(areaConfiguration[locationBefore.warehouseArea] ?? {
          x: targetPosition.x,
          y: targetPosition.y,
          rotation: 0,
          floor: planeId,
          flipX: false,
          flipY: false,
        }),
        id: newAreaId,
      };

      setAreaConfiguration(current => ({
        [newAreaId]: newAreaConfiguration,
        ..._.omitBy(current, b => areasToDeleteSet.has(b.id)),
      }));

      setAreaBuilder(current => ({
        [newAreaId]: newAreaBuilder,
        ..._.omitBy(current, b => areasToDeleteSet.has(b.id)),
      }));
    }

    const defaultLocationTypes = getDefaultLocationTypes(
      [patchedLocations],
      ms,
    );
    let newLocationsTypes = {
      ...defaultLocationTypes,
      ...locationTypesBuilder,
    };

    // CREATE NEW LOCATION TYPE BUILDER IF REQUIRED
    if (
      !_.isNil(params.patch.locationRackingType) &&
      !_.has(locationTypesBuilder, params.patch.locationRackingType)
    ) {
      setLocationTypesBuilder(newLocationsTypes);
    }

    //save

    const data = applyLocationSettings({
      data: { areas } as any,
      locationPatches: {}, // settings.locationsPatches,
      locationTypeMap: newLocationsTypes,
      locationTypeDefault: getLocationDefaultConfig(ms),
      bayModelMap: bayTypeBuilder,
    });

    setLayoutData(current => ({
      ...current,
      areas: [
        ..._.filter(current.areas, a => !areasModifiedSet.has(a.area)),
        ...data.areas,
      ],
    }));

    setPatches(current => ({
      ...current,
      [patch.locationKey]: {
        areaId: patchedLocations.warehouseArea,
        bayId: patchedLocations.locationBayId,
        patch,
      },
    }));

    setLocations(newLocationsAll);
  }

  // const bays: LayoutImportConverterBayFragment[] = _.flattenDeep(
  //   area.aisles.map(a2 => a2.sides.map(s => s.bays)),
  // );
  // const defaultBayTypes = getDefaultBayTypes(bays, ms);

  // set(converterBayTypesBuilder, current => ({
  //   ...defaultBayTypes,
  //   ...current,
  // }));

  return [applyPatch];
}

export default useApplyLocationPatch;
