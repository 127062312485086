import * as React from 'react';
const CircleArrowTopIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleArrowTopIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,64c0-33.08-26.91-60-60-60-33.09,0-60,26.92-60,60,0,33.08,26.91,60,60,60,33.09,0,60-26.92,60-60ZM55.41,104.02v-47.2s-23.61,23.59-23.61,23.59v-24.26s32.2-32.17,32.2-32.17l32.2,32.17v24.26s-23.61-23.59-23.61-23.59v47.2s-17.18,0-17.18,0Z"
    />
  </svg>
);
export default CircleArrowTopIcon;
