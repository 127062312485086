import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Stat } from '../../components/stats/Stat';
import ContainerChart from '../../simulation/panels/ContainerChart';
import { analyzeAgentPerformance } from '../../simulation/store/analyze.state';
import { resourcePolicyAgentsMap } from '../../simulation/store/resourcePolicy.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { AgentPerformanceKPI } from '../analyze.types';
import AgentPerformanceChart, {
  AgentPerformanceDatum,
} from './AgentPerformanceChart';
import AgentPerformanceTable from './AgentPerformanceTable';
import {
  getAgentPerformanceKpiValue,
  getAggregatedAgentPerformanceData,
} from './agentPerformance.helper';

export type AnalyzePerformanceProps = {
  analyzeId: string;
};

const AnalyzePerformance: React.FC<AnalyzePerformanceProps> = props => {
  const { t } = useTranslation('simulation');
  const agents = useRecoilValue(resourcePolicyAgentsMap);
  const performanceDataLoadable = useRecoilValueLoadable(
    analyzeAgentPerformance(props.analyzeId),
  );
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const [kpi, setKpi] = useState<AgentPerformanceKPI>('linesPerDistance');

  const agentsWithValues = new Set<string>();

  const isLoading = performanceDataLoadable.state === 'loading';
  const hasError = performanceDataLoadable.state === 'hasError';
  const isReady = performanceDataLoadable.state === 'hasValue';
  const aggregated =
    performanceDataLoadable.state === 'hasValue'
      ? getAggregatedAgentPerformanceData(performanceDataLoadable.contents)
      : null;

  const chartData: Record<string, AgentPerformanceDatum> = _.reduce(
    aggregated,
    (acc, agentData) => {
      agentsWithValues.add(agentData.agent);
      return {
        ...acc,
        [agentData.date]: {
          ...(acc[agentData.date] ?? {}),
          date: agentData.date,
          [agentData.agent]: getAgentPerformanceKpiValue(
            agentData,
            kpi,
            agents[agentData.agent],
            ms,
          ),
        },
      };
    },
    {},
  );

  const kpiOptions: { key: AgentPerformanceKPI; label: string }[] = [
    {
      key: 'picklistLines',
      label: t`Lines`,
    },
    {
      key: 'distance',
      label: t`Distance`,
    },
    {
      key: 'linesPerDistance',
      label: t`Performance`,
    },
    {
      key: 'linesPerHour',
      label: t`Lines Per Hour`,
    },
  ];

  return (
    <>
      <Stat
        hasHelper
        isPreview
        title={t`Agent performance`}
        isFullWidth
        inPanelMode
        // isLoading={isLoading}
        switchUnitOfMeasure={
          <ButtonSwitchMulti
            buttonType="header"
            autoSize
            options={kpiOptions}
            selectedIndex={_.findIndex(kpiOptions, o => o.key === kpi)}
            onClick={index => {
              setKpi(kpiOptions[index].key);
            }}
          />
        }
      >
        <ContainerChart containerHeight={440}>
          {isLoading && <LoadingIndicator selfCenter />}
          {isReady && (
            <AgentPerformanceChart
              data={_.values(chartData)}
              kpi={kpi}
              series={[...agentsWithValues]}
            />
          )}
        </ContainerChart>
      </Stat>
      <AgentPerformanceTable
        data={_.values(aggregated)}
        isLoading={isLoading}
      />
    </>
  );
};

export default AnalyzePerformance;
