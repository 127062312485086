import * as React from 'react';
const WarehouseBoxesIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseBoxesIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="47.73 118.44 60.94 118.44 60.94 118.44 60.94 113.77 47.73 113.77 47.73 118.44"
    />
    <rect width={13.23} height={4.67} x={67.52} y={113.77} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M63.04,65.01L4.32,84.42v39.85h119.93v-39.85l-61.2-19.41ZM77.08,82.62h3.47v2.89h-3.47v-2.89ZM72.13,82.62h3.74v4.1h5.88v-4.1h3.74v14.2h-13.36v-14.2ZM80.54,98.15v2.89h-3.47v-2.89h3.47ZM72.13,98.03h3.74v4.22h5.88v-4.22h3.74v13.01h-13.36v-13.01ZM62.5,82.62h3.47v2.89h-3.47v-2.89ZM57.55,82.62h3.74v4.1h5.9v-4.1h3.73v14.2h-13.36v-14.2ZM65.97,98.15v2.89h-3.47v-2.89h3.47ZM57.55,98.03h3.74v4.22h5.9v-4.22h3.73v13.01h-13.36v-13.01ZM47.93,82.62h3.47v2.89h-3.47v-2.89ZM42.99,82.62h3.72v4.1h5.9v-4.1h3.73v14.2h-13.35v-14.2ZM51.4,98.15v2.89h-3.47v-2.89h3.47ZM42.99,98.03h3.72v4.22h5.9v-4.22h3.73v13.01h-13.35v-13.01ZM87.32,118.74c0,.67-.54,1.21-1.21,1.21h-43.76c-.67,0-1.21-.54-1.21-1.21v-5.28c0-.67.54-1.21,1.21-1.21h43.76c.67,0,1.21.54,1.21,1.21v5.28Z"
    />
  </svg>
);
export default WarehouseBoxesIcon;
