import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import DropdownSelector from '../../components/actions/DropdownSelector';
import {
  policyFilterData,
  policyFilterSearchByFilterKey,
} from '../../simulation/store/policyFilter.state';

export type PolicyEditorDropDownProps = {
  title: string;
  type: string;
  filterKey: string;
  selected: string;
  onSelectValue: (value: string, isSelected: boolean) => void;
  onSearch: (searchValue: string) => void;
};

const PolicyEditorDropDown: React.FC<PolicyEditorDropDownProps> = props => {
  const { t } = useTranslation('app');

  const { filterKey } = props;
  const filterData = useRecoilValue(policyFilterData(filterKey));
  const search = useRecoilValue(policyFilterSearchByFilterKey(filterKey));

  const filterValues = _.map(filterData?.content, v => v.title) || [];
  const isLoading = filterData?.status === AsyncLoadStatus.Loading;
  const loadedCount = filterData?.content?.length || 0;
  const totalCount = filterData?.totalCount || 0;

  console.log('Search', search);

  return (
    <fieldset
      data-component="PolicyEditorDropDown"
      className={`flex-1 flex flex-col`}
    >
      <label className={`uppercase text-xxs mb-0.5 text-opacity-50`}>
        {props.title}
      </label>
      <DropdownSelector
        className="border-menu-50 bg-app-panel-dark/60"
        values={filterValues}
        value={props.selected ?? ''}
        onChange={v => props.onSelectValue(v, true)}
        hasAsyncSearch
        asyncSearchValue={search}
        onAsyncSearch={props.onSearch}
        isLoading={isLoading}
      />

      {/* {!isLoading && totalCount === 0 && (
        <div className="p-2 m-1 text-xs text-center bg-opacity-50 rounded bg-app-panel-dark text-menu-200">
          {t`No items found`}
        </div>
      )} */}
    </fieldset>
  );
};

export default PolicyEditorDropDown;
