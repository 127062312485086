import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import useFormatter from '../../common/useFormatter';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { getAnalyzeProductMetricDescriptorsMap } from '../../metrics/analyzeProduct/analyzeProductMetric.default';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';

const AnalyzeCompareHeatmapPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [metric, setMetric] = useRecoilState(analyzeProductMetricSelected);
  const formatter = useFormatter();
  const metricDescriptors = getAnalyzeProductMetricDescriptorsMap(t, formatter);

  const fallbackGroupName = 'Others';
  const metricGroups = _.groupBy(
    metricDescriptors,
    metricDescriptor => metricDescriptor.titleCategory || fallbackGroupName,
  );

  // Define the order of the groups
  const groupOrder = ['velocity', 'item', 'Group3', fallbackGroupName]; // replace with your group names

  // Sort the groups
  const orderedMetricGroups = _.sortBy(Object.keys(metricGroups), group =>
    _.indexOf(groupOrder, group),
  );

  return (
    <Container col hasOverflowY>
      <ScreenTitle
        title={t`Heatmaps`}
        subtitle={t`Analyse`}
        isSticky
        helpNavTo={'simulation/analyse/simulation-analyse'}
        icon={Icon.HeatMap9}
      />
      <StatGroup classNameStatGroupItems={'!space-y-0.5'}>
        {orderedMetricGroups.map(category => (
          <TitleSection
            key={`dataset-metric-group-${category}`}
            id={`dataset-metric-group-${category}`}
            title={category}
            inPanelView
            collapsible
            classNameInner="space-y-px"
          >
            {metricGroups[category].map(d => (
              <Stat
                key={d.title}
                isActionable
                isSelectable
                isSelected={metric === d.type}
                title={d.title}
                onClick={() => setMetric(d.type)}
              />
            ))}
          </TitleSection>
        ))}
      </StatGroup>
    </Container>
  );
};
export default AnalyzeCompareHeatmapPanel;
