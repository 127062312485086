import * as React from 'react';
const CircleMenuIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CircleMenuIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64,14c-27.57,0-50,22.43-50,50s22.43,50,50,50,50-22.43,50-50S91.57,14,64,14ZM85.43,87.21h-42.86c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h42.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57ZM85.43,67.57h-42.86c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h42.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57ZM85.43,47.93h-42.86c-1.97,0-3.57-1.6-3.57-3.57s1.6-3.57,3.57-3.57h42.86c1.97,0,3.57,1.6,3.57,3.57s-1.6,3.57-3.57,3.57Z"
    />
  </svg>
);
export default CircleMenuIcon;
