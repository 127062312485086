import { Container } from '../../components/layout/ContainerFlex';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SidebarMenuItem } from '../../components/nav/SidebarMenuItem';
import SidebarContainer from '../../containers/SidebarContainer';
import { datasetAutomationMenuItems } from '../store/datasetAutomation.default';
import {
  datasetAutomationSelectedMenuItemId,
  datasetAutomationType,
} from '../store/datasetAutomation.state';
import { DatasetAutomationMenuItemId } from '../store/datasetAutomation.types';

type DatasetAutomationMenuSidebarProps = {
  isMinimised: boolean;
};

const DatasetAutomationMenuSidebar: React.FC<
  DatasetAutomationMenuSidebarProps
> = props => {
  const { t } = useTranslation('app');
  const datasetType = useRecoilValue(datasetAutomationType);
  const [activeMenuId, setActiveMenuId] = useRecoilState(
    datasetAutomationSelectedMenuItemId(datasetType),
  );

  function changeActiveMenu(menuId: DatasetAutomationMenuItemId) {
    setActiveMenuId(menuId);
  }

  return (
    <Container col hasOverflowY>
      <ul
        data-component="DatasetAutomationMenuSidebar"
        className={classNames(
          'relative',
          'flex flex-col',
          'text-start',
          'px-1',
        )}
      >
        {datasetAutomationMenuItems(t).map(item => {
          return (
            <SidebarMenuItem
              isMinimised={props.isMinimised}
              key={item.id}
              menuLevel={item.menuLevel.toString()}
              title={item.title}
              titleMinimised={item.titleMinimized}
              icon={props.isMinimised && item.icon}
              active={item.id === activeMenuId}
              treeLine={item?.treeLine}
              treeLineStart={item?.treeLineStart}
              treeLineEnd={item?.treeLineEnd}
              hasMenuItems={item.hasMenuItems}
              //inProgress={status?.inProgress}
              stepCounter={item?.stepCounter}
              disabled={item?.disabled}
              isHidden={item?.isHidden}
              //locked={status?.isLocked}
              onClick={() => changeActiveMenu(item.id)}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('app');
  return (
    <SidebarContainer
      key={'sidebar-dataset-automation-menu'}
      type={'sidebar-dataset-automation-menu'}
      title={t`Menu`}
      size={'xs'}
      widthAutoMinimised
      withMenuIcons={<DatasetAutomationMenuSidebar isMinimised={true} />}
    >
      <DatasetAutomationMenuSidebar isMinimised={false} />
    </SidebarContainer>
  );
};
