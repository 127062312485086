import * as React from 'react';
const Mhe6GForkliftRoughTerrainIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe6GForkliftRoughTerrainIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M59.4,87.17c.4,7.43,3.53,12.82,9.38,16.17,5.85,3.35,12.09,3.37,18.71.06,2.91-1.78,5.19-4.08,6.84-6.9,1.65-2.83,2.48-5.93,2.48-9.32-.4-7.43-3.51-12.84-9.32-16.23s-12.03-3.39-18.65,0c-2.99,1.7-5.29,3.98-6.9,6.84-1.61,2.87-2.46,5.99-2.54,9.38ZM13.38,80.87c-1.61,0-3.19.28-4.72.85-1.53.57-2.91,1.37-4.12,2.42l1.57-18.41c.16-1.29.67-2.36,1.51-3.21s1.92-1.31,3.21-1.39l28.58-2.3,1.45-17.68c.24-2.66,1.29-4.86,3.15-6.6,1.86-1.74,4.12-2.6,6.78-2.6h28.94l-5.09,3.15h0l6.66,31.36c-5.81-.73-10.98.57-15.5,3.88-4.52,3.31-7.31,7.87-8.36,13.68-.4,2.5-.32,5.05.24,7.63h-21.19c-.89,0-1.49-.36-1.82-1.09l-1.21-2.3c-.4-.73-1.01-1.09-1.82-1.09h-6.66c-1.29-2.02-2.95-3.57-4.96-4.66s-4.24-1.63-6.66-1.63h0ZM2,94.43c.24,4.52,2.14,7.81,5.69,9.87,3.55,2.06,7.35,2.08,11.38.06,1.78-1.05,3.17-2.44,4.18-4.18,1.01-1.74,1.51-3.65,1.51-5.75-.24-4.52-2.14-7.81-5.69-9.87-3.55-2.06-7.35-2.04-11.38.06-1.78,1.05-3.17,2.44-4.18,4.18-1.01,1.74-1.51,3.61-1.51,5.63ZM91.49,29.04h2.3v-6.9h7.14v72.66h25.07l-7.14,3.51h-23.01c2.42-4.04,3.45-8.32,3.09-12.84s-2.08-8.6-5.15-12.23v-40.69h-2.3v-3.51h0Z"
    />
  </svg>
);
export default Mhe6GForkliftRoughTerrainIcon;
