import { ValidationError, ValidatorOptions } from '@warego/class-validator';
import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import classValidatorResolver, {
  useFieldErrors,
} from './class-validator-resolver';

type UseValidatedFormOptions<
  TFieldValues extends FieldValues,
  TContext extends object,
> = {
  validatorOptions?: ValidatorOptions;
} & Omit<UseFormProps<TFieldValues, TContext>, 'resolver'>;

type UseValidatedFormMethods<TFieldValues extends FieldValues = FieldValues> = {
  setFieldErrors: (errors: ValidationError[]) => void;
} & UseFormReturn<TFieldValues>;

/**
 * Extension of react-hook-form's useForm() with class-validator based validator.
 * @param Type form type with validation decorators
 * @param param1 options
 */
export default function useValidatedForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  Type: new () => TFieldValues,
  {
    validatorOptions,
    ...useFormOptions
  }: UseValidatedFormOptions<TFieldValues, TContext> = {},
): UseValidatedFormMethods<TFieldValues> {
  const form = useForm({
    resolver: classValidatorResolver(Type, validatorOptions),
    ...useFormOptions,
  });

  return {
    ...form,
    setFieldErrors: useFieldErrors(form.setError),
  };
}
