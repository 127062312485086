import * as React from 'react';
const Location2Icon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Location2Icon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M124,107.23v-4.32H4v4.32h6.17v14.23h-6.17v4.32h120v-4.32h-6.17v-14.23h6.17ZM19.63,107.23h39.64v14.23H19.63v-14.23ZM68.73,121.46v-14.23h39.64v14.23h-39.64Z"
    />
    <path
      strokeWidth={0}
      d="M13.6,8.43v92.15h100.17V8.43H13.6ZM99.17,77.95v8.34h-6.47v-8.34h-4.8l8.04-11.47,8.04,11.47h-4.8Z"
    />
  </svg>
);
export default Location2Icon;
