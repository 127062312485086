import * as React from 'react';
const PolicyAssignmentAgentLineIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="PolicyAssignmentAgentLineIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M122.17,54.67L73.33,5.83c-2.56-2.56-5.94-3.83-9.33-3.83h0c-3.39,0-6.77,1.28-9.33,3.83L5.83,54.67c-5.11,5.11-5.11,13.54,0,18.65l48.84,48.84c5.11,5.11,13.54,5.11,18.65,0l48.84-48.84c5.11-5.11,5.11-13.54,0-18.65ZM116.01,67.17l-48.84,48.84c-1.75,1.75-4.59,1.75-6.34,0L11.99,67.17c-1.62-1.62-1.67-4.15-.28-5.91h0s0,0,0,0c.07-.16.16-.31.28-.43L60.83,11.99c.87-.87,2.02-1.31,3.17-1.31h0s0,0,0,0c1.15,0,2.3.44,3.17,1.31l48.84,48.84c1.75,1.75,1.75,4.59,0,6.34Z"
    />
    <path
      strokeWidth={0}
      d="M113.99,62.66L65.34,14.01c-.43-.43-.88-.64-1.34-.64h0c-.46,0-.91.21-1.34.64L14.01,62.66c-.86.86-.86,1.82,0,2.68l48.65,48.65c.86.86,1.82.85,2.68,0l48.65-48.65c.85-.85.85-1.82,0-2.68ZM89.84,68.93c-1.34,1.18-3.38,1.07-4.57-.27-1.16-1.32-1.1-3.35.21-4.51l2.75-2.43s-15.74,0-23.61,0c-.36,0-.64.31-.64.67s.28.64.64.64h12.04c1.79,0,3.23,1.44,3.23,3.23,0,1.79-1.47,3.23-3.26,3.23-4,0-8.03.05-12.02,0-.36,0-.64.28-.64.64s.28.64.64.64h8.15c1.79,0,3.26,1.45,3.26,3.23s-1.47,3.26-3.26,3.26c-3.82,0-7.67.05-11.48,0-.36,0-.64.28-.64.64s.28.64.64.64h6.89c1.79,0,3.23,1.45,3.23,3.23s-1.44,3.26-4.19,3.26h-19.82c-8.42,0-16.82-8.41-16.82-18.77s8.38-18.75,18.75-18.75h20.7c2.76,0,3.26,0,3.26,1.79,0,3.13-2.86,4.65-4.65,4.65h-8.81c-.36,0-.64.28-.64.64s.28.67.64.67c9.47,0,28.41,0,28.41,0l-2.75-2.43c-1.31-1.16-1.38-3.19-.21-4.51,1.18-1.34,3.23-1.45,4.57-.27l11.86,10.44-11.86,10.44Z"
      opacity={0.8}
    />
  </svg>
);
export default PolicyAssignmentAgentLineIcon;
