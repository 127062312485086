import { AllocationRuleFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import SectionOptional from '../../../../components/layout/SectionOptional';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

const AllocationPolicyConstraintsEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  if (!selectedIdentity || !rule) return null;

  function updateAllocationSettings(patch: Partial<AllocationRuleFragment>) {
    updateRule(current => ({
      ...current,
      ...patch,
    }));
  }

  const ignoreAssignmentPolicyConstraint =
    _.isNil(rule?.constraintSettings?.assignmentPolicyConstraint?.disabled) ||
    rule?.constraintSettings?.assignmentPolicyConstraint?.disabled === true;

  const ignoreLocationSizeConstraint =
    _.isNil(rule?.constraintSettings?.locationSizeConstraint?.disabled) ||
    rule?.constraintSettings?.locationSizeConstraint?.disabled === true;

  const ignoreBayWidthConstraint =
    _.isNil(rule?.constraintSettings?.bayWidthConstraint?.disabled) ||
    rule?.constraintSettings?.bayWidthConstraint?.disabled === true;

  return (
    <>
      <TitleSection
        id={`policy-editor-constraints`}
        title={t`Constraints`}
        inSidebarView
        hasScreenTitle
      >
        <InputGroupList className="space-y-2">
          {/* <h4 className="text-menu-active py-2 text-base">{t`Allocation Settings`}</h4> */}
          <SectionOptional
            id={'allocation-assignment-policy-constraints'}
            title={t`Storage policy Constraints`}
            value={!ignoreAssignmentPolicyConstraint}
            onChange={enabled =>
              updateAllocationSettings({
                constraintSettings: {
                  ...rule?.constraintSettings,
                  assignmentPolicyConstraint: {
                    disabled: !enabled,
                  },
                },
              })
            }
          />
          <SectionOptional
            id={'allocation-location-size-constraints'}
            title={t`Location Size Constraints`}
            value={!ignoreLocationSizeConstraint}
            onChange={enabled =>
              updateAllocationSettings({
                constraintSettings: {
                  ...rule?.constraintSettings,
                  locationSizeConstraint: {
                    disabled: !enabled,
                  },
                },
              })
            }
          />
          <SectionOptional
            id={'allocation-bay-width-constraints'}
            title={t`Bay Width Constraints`}
            value={!ignoreBayWidthConstraint}
            onChange={enabled =>
              updateAllocationSettings({
                constraintSettings: {
                  ...rule?.constraintSettings,
                  bayWidthConstraint: {
                    disabled: !enabled,
                  },
                },
              })
            }
          />
        </InputGroupList>
      </TitleSection>
    </>
  );
};

export default AllocationPolicyConstraintsEditor;
