import { Container } from '../../components/layout/ContainerFlex';
import React, { PropsWithChildren, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getDefaultMeasure, getMeasureTitle } from '../../common/measureHelper';
import useFormatter from '../../common/useFormatter';
import PanelContainer from '../../containers/PanelContainer';
import { BayFeatureDetailedFragment } from '../../import/layout/converter/converter.serializable.model';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import LoadingIndicator from '../LoadingIndicator';
import { WarehouseNavigator } from '../WarehouseNavigator';
import { WarehouseNavigatorItem } from '../WarehouseNavigatorItem';
import { StatGroup } from '../stats/StatGroup';
import { StatListItem } from '../stats/StatListItem';

export type BayPropertiesPanelProps = PropsWithChildren & {
  bay: BayFeatureDetailedFragment;
  bayViewer: React.FC;
  areaTitle?: string;
  aisleTitle?: string;
};

const BayPropertiesPanel: React.FC<BayPropertiesPanelProps> = props => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('app');

  const ms = useRecoilValue(warehouseMeasurementSystem);

  const { bay } = props;
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(ms, 'size'),
    tMeasures,
  );
  const BayViewer = props.bayViewer;

  const formatter = useFormatter();

  // Volume - Calculated
  const volumeCalculatedRaw =
    bay.details.width * bay.details.height * bay.details.depth;
  const volumeCalculated = formatter.formatVolume(volumeCalculatedRaw);

  return (
    <>
      <WarehouseNavigator>
        {props.areaTitle && (
          <WarehouseNavigatorItem
            name={t`Area`}
            value={props.areaTitle}
            active
          />
        )}
        {props.aisleTitle && (
          <WarehouseNavigatorItem
            name={t`Aisle`}
            value={props.aisleTitle}
            active
          />
        )}
        <WarehouseNavigatorItem name={t`Bay`} value={bay.title} active />
      </WarehouseNavigator>

      <Container col flex1 hasOverflowY>
        <Suspense
          fallback={
            <LoadingIndicator selfCenter message={t`Loading bay locations`} />
          }
        >
          <BayViewer />
        </Suspense>
        <PanelContainer
          id="panel-object-property-bay"
          title={t`Bay Properties`}
          className="flex flex-col"
          collapsible
          hasPadding
        >
          {/* <TitleSection inPanelView title={t`Bay Type`} className="mb-1" /> */}
          <StatListItem
            title={t`Bay Type`}
            value={bay.details.bayType ?? '—'}
          />

          <StatGroup className="">
            <StatListItem
              titleSelectable
              title={t`Width`}
              value={bay.details.width}
              unitOfMeasure={sizeMeasureValue}
              styleInput
            />
            <StatListItem
              titleSelectable
              title={t`Height`}
              value={bay.details.height}
              unitOfMeasure={sizeMeasureValue}
              styleInput
            />
            <StatListItem
              titleSelectable
              title={t`Length`}
              value={bay.details.depth}
              unitOfMeasure={sizeMeasureValue}
              styleInput
            />
            <StatListItem
              titleSelectable
              title={t`Volume`}
              value={volumeCalculated.value}
              valueRaw={volumeCalculated.raw}
              unitOfMeasure={volumeCalculated.unit}
              styleInput
            />
          </StatGroup>
          {/* <ObjectSize
            object={bay.id}
            unitMeasurement={sizeMeasureValue}
            hasSize
            sizeWidth={bay.details.width}
            sizeHeight={bay.details.height}
            sizeLength={bay.details.depth}
            sizeVolume={
              bay.details.width * bay.details.height * bay.details.depth
            }
            disabled
          /> */}
        </PanelContainer>
        {props.children}
      </Container>
    </>
  );
};

export default BayPropertiesPanel;
