import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import AllocationResultPanel from '../panels/allocate/AllocationResultPanel';

export default () => {
  const { t } = useTranslation('simulation');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-analyze-allocate',
    title: t('Allocate'),
    loadingMessage: t('Loading Allocation plan'),
    size: 'xl',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps} width={140}>
      <AllocationResultPanel />
    </SidebarContainer>
  );
};
