import * as React from 'react';
const MheForklift1Icon = props => (
  <svg
    aria-hidden="true"
    data-name="MheForklift1Icon"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m13.14,96.11c0,5.29,4.29,9.58,9.58,9.58s9.58-4.29,9.58-9.58-4.29-9.58-9.58-9.58-9.58,4.29-9.58,9.58Zm3.7,0c0-3.25,2.63-5.88,5.88-5.88s5.88,2.63,5.88,5.88c0,3.25-2.63,5.88-5.88,5.88s-5.88-2.63-5.88-5.88Z" />
    <path d="m4,84.8l1.82,11.12,6.17,2.78s-1.76-13.71,10.61-13.71c12.36,0,11.5,12.46,11.5,12.46h29.9s-1.41-17.44,13.13-17.44c1.46,0,4.28.5,6.04.84v1.75c-1.67-.86-3.56-1.35-5.56-1.35-6.75,0-12.22,5.47-12.22,12.22,0,6.75,5.47,12.22,12.22,12.22,5.15,0,9.54-3.18,11.34-7.69.15.08.3.12.45.12,0,3.83,3.74,3.16,3.74,3.16l30.86-1.44v-.86s-29.81,0-29.81,0v-37.38s-.96,0-.96,0l-1.63,1.63h-2.2s.57-40.93.57-40.93h-6.81s0,57.5,0,57.5c-1.35-.41-3.45-1.02-5.75-1.52-1.25-7.09-.96-7.57-3.45-8.05,0,0-7.57-26.26-13.23-30.1-5.65-3.83-28.85-1.44-28.85-1.44,0,0-1.34,1.63-1.73,5.75-.38,4.12-1.53,18.88-1.53,20.32,0,1.44.96,4.5.96,4.5H10.81s-4.41,5.27-4.41,5.27l-2.4,10.25Zm81.11,8.67c0,4.14-3.36,7.5-7.5,7.5s-7.5-3.36-7.5-7.5c0-4.14,3.36-7.5,7.5-7.5s7.5,3.36,7.5,7.5Zm-18.55-36.18l-1.57-.45-1.44-3.9.83-1.02,2.17,5.37Zm-32.94,9.58l1.82-23.96h21.37c1.92,0,3.74-.57,7.28,8.34-.96,1.25-1.25,1.5-1.25,1.5l1.73,4.7,2.27.67,1.76,4.76-2.43-.16s-1.57-2.78-3.19-2.94c-.7-.29.32-.83,1.12-1.37.8-.54.38-1.41-1.15-.29-1.53,1.12-3.77,2.59-4.95,3.42-1.18.83-.67,1.56,1.05.54,1.72-1.02,2.08,0,2.08,0,0,0,.96,1.82,1.5,3.65.54,1.82,1.37,3.93,2.46,4.09-.26.61.64,1.47,4.76,5.02l-5.75,5.85-7,.57s2.68-7.76-5.56-9.39c-.09-.57,3.07-.57,3.07-.57,0,0,.96-3.05-2.3-3.05s-5.37.85-5.37.85c0,0-.96-4.89-6.61-5.94,1.25-3.26.67-7.09-4.89-7.09-.62.62-.29,2.01,0,3.55.29,1.53,1.15,9.78,3.64,11.6-.81.81-3.99.19-3.99.19l-1.47-4.5Z" />
    <path d="m84.29,93.47c0-3.69-2.99-6.69-6.68-6.69s-6.68,2.99-6.68,6.69c0,3.69,2.99,6.68,6.68,6.68s6.69-2.99,6.68-6.68Zm-10.07,0c0,.27-.22.49-.49.49s-.49-.22-.49-.49c0-.27.22-.49.49-.49s.49.22.49.49Zm1.69-2.93c-.23.14-.53.06-.67-.18-.14-.23-.06-.53.18-.67.23-.14.53-.06.67.18.13.23.06.53-.18.67Zm.18,6.54c-.14.23-.43.31-.67.18-.23-.14-.31-.43-.18-.67.14-.23.43-.31.67-.18.23.14.31.43.18.67Zm2.57-3.6c0,.58-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06c0-.58.47-1.06,1.06-1.06s1.06.48,1.06,1.06Zm1.3-3.11c-.14.23-.43.31-.67.18-.23-.14-.31-.43-.18-.67.14-.23.43-.31.67-.18.23.13.31.43.18.67Zm-.18,6.89c-.23.14-.53.05-.67-.18-.14-.24-.06-.53.18-.67.24-.14.53-.06.67.18.14.23.06.53-.18.67Zm2.18-3.78c0,.27-.22.49-.49.49s-.49-.22-.49-.49c0-.27.22-.49.49-.49s.49.22.49.49Z" />
    <path d="m17.29,96.11c0,3,2.43,5.44,5.44,5.44s5.44-2.43,5.44-5.44c0-3-2.43-5.44-5.44-5.44s-5.44,2.43-5.44,5.44Zm8.09,0c0-.21.17-.38.38-.38s.38.17.38.38c0,.21-.17.38-.38.38s-.38-.17-.38-.38Zm-.8-2.44c-.11.18-.34.25-.52.14-.18-.11-.25-.34-.14-.52.11-.18.34-.25.52-.14.18.1.25.34.14.52Zm-.52,4.74c.18-.11.42-.04.52.14.11.18.04.42-.14.52-.18.11-.42.04-.52-.14-.11-.18-.04-.42.14-.52Zm-2.16-2.3c0-.46.37-.83.83-.83s.83.37.83.83-.37.83-.83.83-.83-.37-.83-.83Zm-1.02,2.44c.11-.18.34-.25.52-.14.18.11.25.34.14.52-.11.18-.34.25-.52.14-.18-.11-.25-.34-.14-.52Zm.14-5.4c.18-.11.42-.04.52.14.11.18.04.42-.14.52-.18.11-.42.04-.52-.14-.11-.18-.04-.42.14-.52Zm-1.71,2.97c0-.21.17-.38.38-.38s.38.17.38.38c0,.21-.17.38-.38.38s-.38-.17-.38-.38Z" />
  </svg>
);
export default MheForklift1Icon;
