import BitSet from 'bitset';
import { LocationMask, LocationMaskSpec } from './location-mask.model';

export function locationMaskMatches(
  locationMask: LocationMask,
  requiredMask: LocationMaskSpec,
) {
  const locationMaskBitSet = BitSet.fromBinaryString(locationMask);
  const possibleLocationMaskBitSet = BitSet.fromBinaryString(
    requiredMask.possibleLocationMask || '0',
  );
  const requiredLocationMaskBitSet = BitSet.fromBinaryString(
    requiredMask.requiredLocationMask || '0',
  );

  return (
    !locationMaskBitSet.and(possibleLocationMaskBitSet).isEmpty() &&
    locationMaskBitSet
      .and(requiredLocationMaskBitSet)
      .equals(requiredLocationMaskBitSet)
  );
}
