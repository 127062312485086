import _ from 'lodash';
import React, { useState } from 'react';
// import * as Icon from '../components/icons';

export type AppFooterProps = {
  children?: React.ReactNode;
  className?: string;
};

export const AppFooter = ({ children, className }: AppFooterProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <footer
      id={id}
      className={`flex w-full bg-app-panel-dark/60 ${className || ''}`}
    >
      {children}
    </footer>
  );
};
