import * as React from 'react';
const ObjectAisleRightIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisleRightIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="29.88 121.71 125 99.88 125 26.36 29.88 3.54 7 3.54 7 121.71 29.88 121.71"
    />
    <polygon
      strokeWidth={0}
      points="27.95 7.68 27.95 42.68 9.95 42.68 27.95 7.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="27.95 7.68 27.95 42.68 9.95 42.68 27.95 7.68"
    />
    <polygon strokeWidth={0} points="9.5 40.5 9.5 5.5 27.5 5.5 9.5 40.5" />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="9.5 40.5 9.5 5.5 27.5 5.5 9.5 40.5"
    />
    <polygon
      strokeWidth={0}
      points="27.95 45.68 27.95 80.68 9.95 80.68 27.95 45.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="27.95 45.68 27.95 80.68 9.95 80.68 27.95 45.68"
    />
    <polygon strokeWidth={0} points="9.5 78.5 9.5 43.5 27.5 43.5 9.5 78.5" />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="9.5 78.5 9.5 43.5 27.5 43.5 9.5 78.5"
    />
    <polygon
      strokeWidth={0}
      points="27.95 82.68 27.95 117.68 9.95 117.68 27.95 82.68"
    />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="27.95 82.68 27.95 117.68 9.95 117.68 27.95 82.68"
    />
    <polygon strokeWidth={0} points="9.5 115.5 9.5 80.5 27.5 80.5 9.5 115.5" />
    <polygon
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.39}
      points="9.5 115.5 9.5 80.5 27.5 80.5 9.5 115.5"
    />
  </svg>
);
export default ObjectAisleRightIcon;
