import * as React from 'react';
const WayOutIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WayOutIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M97.75,46.12l15.87-14.28c.45-.4.72-.99.72-1.6s-.26-1.19-.71-1.59l-15.87-14.29h-34.01v-3.53c0-.98-.79-1.76-1.76-1.76s-1.76.79-1.76,1.76v3.53h-31.06c-3.31,0-5.99,2.69-5.99,5.99v19.77c0,3.31,2.69,6,5.99,6h31.06v3.53h-29.29l-15.88,14.29c-.45.41-.71.99-.71,1.59s.26,1.19.71,1.59l15.87,14.29h29.29v31.76c0,.98.79,1.76,1.76,1.76s1.76-.79,1.76-1.76v-31.76h36.36c3.31,0,5.99-2.69,5.99-5.99v-19.78c0-3.31-2.69-5.99-5.99-5.99h-36.36v-3.53h34.01Z"
    />
  </svg>
);
export default WayOutIcon;
