import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getDefaultMeasure, getMeasureTitle } from '../../common/measureHelper';
import { StatGroup } from '../../components/stats/StatGroup';
import { StatListItem } from '../../components/stats/StatListItem';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';

export type LocationDetailedHtmlTooltipProps = {
  location: LayoutBayLocationFragment;
};
const LocationDetailedHtmlTooltip: React.FC<
  LocationDetailedHtmlTooltipProps
> = props => {
  const { t } = useTranslation('app');

  const { t: tMeasures } = useTranslation('measures');
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(ms, 'size'),
    tMeasures,
  );
  const { location } = props;

  return (
    <div
      data-component="LocationDetailedHtmlTooltip"
      className={classNames(
        'flex flex-col overflow-hidden w-tooltip',
        'bg-app-panel-dark/90 backdrop-filter backdrop-blur',
      )}
    >
      <div
        className={classNames(
          'flex items-center',
          'px-1 py-2',
          'text-sm font-bold text-start',
          location.locationStatus
            ? 'bg-menu-active text-menu-active-text'
            : 'bg-menu-button text-menu-text',
        )}
      >
        <div
          className={classNames(
            'flex-1',
            'ltr:pl-1 rtl:pr-1 text-base uppercase',
          )}
        >
          {location.locationId}
        </div>
        {!location.locationStatus && (
          <div
            className={classNames(
              'rounded',
              'p-0.5 px-1',
              'ltr:ml-1 rtl:mr-1',
              'bg-app-panel-dark',
              'text-menu-text',
              'text-xxs',
            )}
          >{t`Disabled`}</div>
        )}
      </div>
      <div
        className={classNames(
          'flex items-center',
          'w-full',
          'py-1 px-1 ltr:pr-2 rtl:ltr:pl-2 rtl:pr-2',
          'text-start',
          'border-b border-menu/20',
          'test-start text-menu-text/75',
        )}
      >
        <span
          className={classNames('uppercase text-xxs ltr:mr-0.5 rtl:ml-0.5')}
        >{t`Level`}</span>
        <span
          className={classNames(
            'flex-1 text-xs truncate text-end overflow-ellipsis',
          )}
        >
          {location.locationLevel}
        </span>
      </div>
      <div
        className={classNames(
          'flex items-center',
          'w-full',
          'py-1 px-1 ltr:pr-2 rtl:ltr:pl-2 rtl:pr-2',
          'text-start',
          'border-b border-menu/20',
          'test-start text-menu-text/75',
        )}
      >
        <span
          className={classNames('uppercase text-xxs ltr:mr-0.5 rtl:ml-0.5')}
        >{t`Type`}</span>
        <span
          className={classNames(
            'flex-1 text-xs truncate text-end overflow-ellipsis',
          )}
        >
          {location.locationRackingType}
        </span>
      </div>
      {/* <div className="flex items-center w-full px-1 text-start border-b border-opacity-20 border-menu test-start text-menu-text/60 ">
        <span className="flex-1 uppercase text-xxs">{t`Status`}</span>
        <span className="flex-1 text-xs">{location.locationStatus}</span>
      </div> */}
      <StatGroup
        className={classNames(
          'font-bold text-start test-start p-0.5 px-0 pb-1',
        )}
      >
        <StatListItem
          titleSelectable
          title={t`W.`}
          value={location.locationWidth}
          unitOfMeasure={sizeMeasureValue}
          styleInput
        />
        <StatListItem
          titleSelectable
          title={t`H.`}
          value={location.locationHeight}
          unitOfMeasure={sizeMeasureValue}
          styleInput
        />
        <StatListItem
          titleSelectable
          title={t`L.`}
          value={location.locationLength}
          unitOfMeasure={sizeMeasureValue}
          styleInput
        />
        <StatListItem
          titleSelectable
          title={t`HFF.`}
          value={location.locationHeightFromFloor}
          unitOfMeasure={sizeMeasureValue}
          styleInput
        />
        {/* <StatListItem
            titleSelectable
            title={t`Volume`}
            value={volumeCalculated.value}
            valueRaw={volumeCalculated.raw}
            unitOfMeasure={volumeCalculated.unit}
            styleInput
          /> */}
      </StatGroup>

      {/* <ObjectSize
          className="my-0"
          key={location.locationId}
          // title={location.locationId}
          object={location.locationId}
          // unitMeasurement={measurementUnit.toLowerCase()}
          hasSize
          shortTitle
          hasPadding={false}
          sizeWidth={location.locationWidth}
          sizeHeight={location.locationHeight}
          sizeLength={location.locationLength}
          hasPosition={true}
          sizeHeightFromFloor={location.locationHeightFromFloor}
          disabled
          unitMeasurement={sizeMeasureValue}
        /> */}
    </div>
  );
};

export default React.memo(LocationDetailedHtmlTooltip);
