import * as React from 'react';
const CongestionMap4IsometricIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="CongestionMap4IsometricIcon"
    {...props}
  >
    <g isolation="isolate">
      <path
        strokeWidth={0}
        d="M81.05,32.49c-9.51-5.49-25.01-5.49-34.45,0-9.44,5.49-9.39,14.47.11,19.96,9.51,5.49,25.01,5.49,34.45,0,9.44-5.49,9.39-14.47-.11-19.96"
      />
      <path
        strokeWidth={0}
        d="M118.35,54.02c-9.51-5.49-25.01-5.49-34.45,0-9.44,5.49-9.39,14.47.11,19.96,9.51,5.49,25.01,5.49,34.45,0,9.44-5.49,9.39-14.47-.11-19.96"
        opacity={0.6}
      />
      <path
        strokeWidth={0}
        d="M44,54.02c-9.51-5.49-25.01-5.49-34.45,0-9.44,5.49-9.4,14.47.11,19.96,9.51,5.49,25.02,5.48,34.46,0,9.44-5.49,9.39-14.47-.11-19.96"
        opacity={0.8}
      />
      <path
        strokeWidth={0}
        d="M81.3,75.56c-9.51-5.49-25.01-5.49-34.45,0-9.44,5.49-9.4,14.47.11,19.96,9.51,5.49,25.02,5.48,34.46,0s9.39-14.47-.11-19.96"
        opacity={0.5}
      />
    </g>
  </svg>
);
export default CongestionMap4IsometricIcon;
