import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  AnalyzeResultStatus,
  LoadSimulationDocument,
  LoadSimulationQuery,
  LoadSimulationQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import useLoadWarehouseBillingState from '../../appBilling/hooks/useLoadWarehouseBillingState';
import { AsyncLoadStatus } from '../../common/types';
import { useLoadLayout } from '../../layout/hooks/useLoadLayout';
import {
  loadBalancingPolicyDocument,
  loadBalancingPolicySelectedIdentity,
} from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import { AgentSettingsWithMeta } from '../../resourcePolicy/agentData/agent.types';
import { updateAgentUoms } from '../../resourcePolicy/agentData/agentDataHelper';
import useSelectSimulationWizardStep from '../../simulation/hooks/useSelectSimulationWizardStep';
import {
  getAllocationPolicyDefault,
  getDeallocationPolicyDefault,
} from '../../simulation/store/allocation/allocation.helper';
import {
  allocationAnalyzeResultAtom,
  allocationRunSummary,
  allocationRunSummaryLoadStatus,
} from '../../simulation/store/allocation/allocation.state';
import {
  allocationRequirement,
  allocationRequirementItems,
  allocationRequirementItemsLoadStatus,
  allocationRequirementLoadStatus,
  allocationRequirementSummary,
  allocationRequirementSummaryLoadStatus,
} from '../../simulation/store/allocation/allocationRequirement.state';
import {
  analyzeJobDetails,
  analyzeJobDetailsLoadStatus,
  analyzeJobDetailsSelectedRow,
  analyzeJobDetailsTableState,
} from '../../simulation/store/analyze.state';
import {
  assignmentComplianceDataTableState,
  assignmentComplianceSelectedIdentity,
} from '../../simulation/store/assignmentCompliance.state';
import { getDefaultLocationSharingPolicy } from '../../simulation/store/locationSharingPolicy/locationSharingPolicy.default';
import { locationSharingPolicy } from '../../simulation/store/locationSharingPolicy/locationSharingPolicy.state';
import {
  assignmentOccupancyCategoryFilter,
  assignmentOccupancyMeta,
  assignmentOccupancyMetaLoadStatus,
} from '../../simulation/store/occupancy/assignmentOccupancy.state';
import {
  optimisationAssignmentDiff,
  optimisationAssignmentImplement,
  optimisationResult,
  optimisationResultStatus,
  optimisationSelectedAssignmentDiff,
} from '../../simulation/store/optimisation.state';
import {
  pickingPolicy,
  pickingPolicyCheckResult,
  pickingPolicyCheckResultLoadStatus,
  pickingPolicySelectedDetailsIdentityAtom,
  pickingPolicySelectedIdentityAtom,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { resourcePolicySelectedAgentId } from '../../simulation/store/resourcePolicy.state';
import { routingPolicyDefault } from '../../simulation/store/routingPolicy/routingPolicy.default';
import {
  routingPolicy,
  routingPolicySelectedIdentity,
} from '../../simulation/store/routingPolicy/routingPolicy.state';
import { getSimulationDefaults } from '../../simulation/store/simulation.default';
import {
  simulationAssignmentIssues,
  simulationAssignmentIssuesLoadStatus,
  simulationItemSetIssues,
  simulationItemSetIssuesLoadStatus,
  simulationLayoutIssues,
  simulationLayoutIssuesLoadStatus,
  simulationOrderSetIssues,
  simulationOrderSetIssuesLoadStatus,
} from '../../simulation/store/simulation.issues.state';
import {
  simulationLayoutHeatmapFilters,
  simulationLayoutSelectedOrderId,
} from '../../simulation/store/simulation.layout.state';
import {
  simulationAnalyzeOrderDetails,
  simulationAnalyzeOrderDetailsLoadStatus,
  simulationAnalyzeOrdersListState,
  simulationAnalyzeResultAtom,
  simulationAnalyzeTypeSelected,
  simulationCurrent,
  simulationEffectiveAssignmentIdAtom,
  simulationEffectiveItemSet,
  simulationEffectiveItemSetLoadStatus,
  simulationLoadStatus,
  simulationOrderLineSet,
  simulationOrderLineSetLoadStatus,
  simulationShowComplianceTable,
  simulationShowDatasetAsTable,
  simulationUoms,
} from '../../simulation/store/simulation.state';
import { SimulationExtraSettings } from '../../simulation/store/simulation.types';
import { simulationWizardSelectedStepId } from '../../simulation/store/simulation.wizard.state';
import {
  orderedQuantityReport,
  orderedQuantityReportLoadStatus,
} from '../../simulation/store/simulationReport.state';
import {
  sizeComplianceMeta,
  sizeComplianceMetaLoadStatus,
} from '../../simulation/store/sizeCompliance.state';
import {
  stackingPolicyDocument,
  stackingPolicySelectedIdentity,
} from '../../simulation/store/stackingPolicy/stackingPolicy.state';
import { waypointPolicyDefault } from '../../simulation/store/waypointPolicy/waypointPolicy.default';
import {
  waypointPolicy,
  waypointPolicySelectedIdentity,
} from '../../simulation/store/waypointPolicy/waypointPolicy.state';
import {
  weightComplianceMeta,
  weightComplianceMetaLoadStatus,
} from '../../simulation/store/weightCompliance.state';
import {
  workforceAllAgentSettingsMap,
  workforceSelectedAgentId,
} from '../../simulation/store/workforce.state';
import { errorAppender } from '../../store/error.state';
import { sidebarStateByType } from '../../store/sidebar.state';
import {
  swapPolicyDocument,
  swapPolicySelectedIdentity,
} from '../../swapPolicy/swapPolicy.state';
import { useLoadAnalyzeResult } from './useLoadAnalyzeResult';
import { useLoadOptimizeResult } from './useLoadOptimizeResult';

export function useLoadSimulation() {
  const { t: tSim } = useTranslation('simulation');
  const { t } = useTranslation('errors');
  const [loadLayout, cleanupLayout] = useLoadLayout();
  const loadAnalyzeResult = useLoadAnalyzeResult();
  const loadOptimizeResult = useLoadOptimizeResult();
  const selectStep = useSelectSimulationWizardStep();
  const client = useApolloClient();
  const updateBillingState = useLoadWarehouseBillingState();
  const errorTitle = t`Could not load simulation`;

  const cleanup = useRecoilCallback(({ set, reset }) => async () => {
    set(simulationLoadStatus, AsyncLoadStatus.Loading);
    set(simulationCurrent, null);
    set(simulationAnalyzeResultAtom, null);
    reset(simulationUoms);
    reset(simulationEffectiveAssignmentIdAtom);

    reset(simulationLayoutIssues);
    reset(simulationAssignmentIssues);
    reset(simulationItemSetIssues);
    reset(simulationOrderSetIssues);
    reset(simulationLayoutIssuesLoadStatus);
    reset(simulationAssignmentIssuesLoadStatus);
    reset(simulationItemSetIssuesLoadStatus);
    reset(simulationOrderSetIssuesLoadStatus);
    reset(simulationShowDatasetAsTable);

    reset(orderedQuantityReport);
    reset(orderedQuantityReportLoadStatus);

    // allocation requirements
    reset(allocationRequirement);
    reset(allocationRequirementLoadStatus);
    reset(allocationRequirementItems);
    reset(allocationRequirementItemsLoadStatus);
    reset(allocationRequirementSummary);
    reset(allocationRequirementSummaryLoadStatus);
    reset(simulationAnalyzeTypeSelected);
    reset(allocationRunSummary);
    reset(allocationRunSummaryLoadStatus);
    reset(allocationAnalyzeResultAtom);

    set(optimisationResult, null);
    reset(resourcePolicySelectedAgentId);
    reset(pickingPolicy);

    //layout filters
    reset(simulationLayoutHeatmapFilters);
    reset(assignmentOccupancyCategoryFilter);

    //orders and order details
    reset(simulationLayoutSelectedOrderId);
    reset(simulationAnalyzeOrdersListState);
    reset(simulationAnalyzeOrderDetails);
    reset(simulationAnalyzeOrderDetailsLoadStatus);

    //compliance
    reset(sizeComplianceMeta);
    reset(sizeComplianceMetaLoadStatus);
    reset(weightComplianceMeta);
    reset(weightComplianceMetaLoadStatus);
    reset(assignmentOccupancyMeta);
    reset(assignmentOccupancyMetaLoadStatus);
    reset(simulationShowComplianceTable);

    // assignment compliance
    reset(assignmentComplianceDataTableState);
    set(assignmentComplianceSelectedIdentity, null);

    // Routing policy
    reset(routingPolicy);
    reset(routingPolicySelectedIdentity);

    //Waypoint Policy
    reset(waypointPolicy);
    reset(waypointPolicySelectedIdentity);

    // Storage Policy / Assignment Policy
    reset(simulationEffectiveItemSetLoadStatus);
    reset(simulationEffectiveItemSet);

    //stacking policy
    reset(stackingPolicyDocument);
    reset(stackingPolicySelectedIdentity);

    // picking policy
    reset(pickingPolicy);
    reset(pickingPolicySelectedIdentityAtom);
    reset(pickingPolicySelectedDetailsIdentityAtom);
    reset(simulationOrderLineSetLoadStatus);
    reset(simulationOrderLineSet);
    reset(pickingPolicyCheckResult);
    reset(pickingPolicyCheckResultLoadStatus);

    // Swap Policy
    reset(swapPolicyDocument);
    reset(swapPolicySelectedIdentity);
    reset(loadBalancingPolicyDocument);
    reset(loadBalancingPolicySelectedIdentity);

    reset(optimisationResultStatus);
    // optimisation assignment diff
    set(optimisationAssignmentDiff, null);
    set(optimisationSelectedAssignmentDiff, null);
    set(optimisationAssignmentImplement, null);
    //set(simulationLayoutAreaId, null);
    reset(analyzeJobDetails);
    reset(analyzeJobDetailsLoadStatus);
    reset(analyzeJobDetailsTableState);
    reset(analyzeJobDetailsSelectedRow);

    //workforce
    reset(workforceAllAgentSettingsMap);
    reset(workforceSelectedAgentId);

    await cleanupLayout();
  });

  const selectStepCallback = useRecoilCallback(
    ({ snapshot }) =>
      async (simulationId: string) => {
        const current = await snapshot.getPromise(
          simulationWizardSelectedStepId,
        );
        const isNew = simulationId === 'new';
        selectStep({ stepId: isNew ? 'getting-started' : current });
      },
  );

  const loadSimulationCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (simulationId: string) => {
        let simulation = getSimulationDefaults();
        const isNew = simulationId === 'new';

        let simulationResponse: ApolloQueryResult<LoadSimulationQuery>;

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        try {
          if (!isNew) {
            simulationResponse = await client.query<
              LoadSimulationQuery,
              LoadSimulationQueryVariables
            >({
              fetchPolicy: 'no-cache',
              query: LoadSimulationDocument,
              variables: {
                simulationId: simulationId,
              },
            });
            simulation = simulationResponse.data.simulation;
            // simulation.analyzeResult.status = AnalyzeResultStatus.FAILED;
          } else {
            set(sidebarStateByType('sidebar-simulation-menu'), {
              isCollapsed: false,
              isHidden: false,
              isPinned: false,
            });
          }
        } catch (ex) {
          handleError(ex.message || ex, ex.stack || null);
          return;
        }

        if (simulationResponse?.errors) {
          handleError(
            null,
            simulationResponse?.errors.map(e => e.message).join('. '),
          );
          return;
        }

        const extraSettings =
          simulation.extraSettings as SimulationExtraSettings;

        set(sidebarStateByType('sidebar-simulation-menu'), {
          isCollapsed: !isNew,
          isHidden: false,
          isPinned: false,
        });
        const uoms = _.map(simulation?.itemSet?.uomTypes, ut => ut.uom) ?? [];
        set(simulationUoms, uoms);
        simulation.resourcePolicy = {
          ...simulation.resourcePolicy,
          agents: _.map(simulation.resourcePolicy?.agents, a =>
            updateAgentUoms(a as AgentSettingsWithMeta, uoms),
          ),
        };

        simulation.allocationSettings = {
          ...simulation.allocationSettings,
          allocationPolicy:
            simulation.allocationSettings?.allocationPolicy ??
            getAllocationPolicyDefault(tSim),
          deallocationPolicy:
            simulation.allocationSettings?.deallocationPolicy ??
            getDeallocationPolicyDefault(tSim),
        };
        set(routingPolicy, simulation?.routingPolicy ?? routingPolicyDefault);
        set(
          waypointPolicy,
          simulation?.waypointPolicy ?? waypointPolicyDefault,
        );
        set(pickingPolicy, simulation?.pickingPolicy);
        set(
          locationSharingPolicy,
          simulation?.locationSharingPolicy ??
            getDefaultLocationSharingPolicy(),
        );
        set(
          stackingPolicyDocument,
          simulation?.stackingPolicy ?? {
            topRules: [],
            bottomRules: [],
            fallbackRule: {
              subcategories: null,
            },
          },
        );
        set(simulationOrderLineSet, simulation.orderLineSet);
        set(simulationOrderLineSetLoadStatus, AsyncLoadStatus.Ok);
        set(simulationEffectiveItemSet, simulation.effectiveItemSet);
        set(simulationEffectiveItemSetLoadStatus, AsyncLoadStatus.Ok);
        const optimisationRun = simulation?.optimizationRuns?.[0];
        set(optimisationResultStatus, optimisationRun);
        set(optimisationResult, optimisationRun);

        // compliance summaries
        set(sizeComplianceMeta, simulation.sizeCompliance);
        set(weightComplianceMeta, simulation.weightCompliance);

        //workforce
        set(
          workforceAllAgentSettingsMap,
          extraSettings?.workforceSettings?.schedules,
        );

        //swap policy
        set(swapPolicyDocument, simulation.optimizationSettings?.swapSettings);
        set(
          loadBalancingPolicyDocument,
          simulation.optimizationSettings?.loadBalancing,
        );

        // allocation
        set(allocationRequirement, simulation.allocationRequirementSet);

        set(orderedQuantityReport, simulation.orderedQuantityReport);

        set(allocationRunSummary, simulation.latestAllocationRun);

        set(simulationLoadStatus, AsyncLoadStatus.Ok);

        set(simulationCurrent, simulation);

        return simulation;
      },
  );

  async function load(whId: string, simulationId: string) {
    await cleanup();

    updateBillingState([whId]);
    const sim = await loadSimulationCallback(simulationId);
    await selectStepCallback(simulationId);

    if (sim.layout?.id) {
      await loadLayout(sim.layout.id);
    }

    if (sim?.analyzeResult?.status === AnalyzeResultStatus.COMPLETED) {
      await loadAnalyzeResult({
        analyzeId: sim?.analyzeResult.id,
        analyzeType: 'initial',
      });
    }

    if (
      sim?.latestAllocationRun?.analyzeResult?.status ===
      AnalyzeResultStatus.COMPLETED
    ) {
      await loadAnalyzeResult({
        analyzeId: sim?.latestAllocationRun?.analyzeResult?.id,
        analyzeType: 'allocate',
      });
    }

    const optimizeResult = _.last(sim?.optimizationRuns?.content);
    if (optimizeResult) {
      await loadOptimizeResult(optimizeResult.id);
    }
  }

  return [load, /*cancel,*/ cleanup] as const;
}
