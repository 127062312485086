import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../common/useFormatter';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { Spacer } from '../components/layout/Spacer';
import { ActionBar } from '../components/nav/ActionBar';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import { StatListItem } from '../components/stats/StatListItem';
import { viewerPlanesMap, viewerPlaneStats } from './viewer/store/viewer.state';

const LayoutStats: React.FC = () => {
  const { t } = useTranslation('app');
  const formatter = useFormatter();
  const planes = useRecoilValue(viewerPlanesMap);
  const planesStats = useRecoilValue(viewerPlaneStats);

  return (
    <StatGroup
      data-component="LayoutStats"
      title={t`By Area`}
      subTitle={t`Stats`}
      //helpNavTo={''}
    >
      {_.map(planes, p => {
        const planeArea = formatter.formatArea(p.area);
        const aislesArea = formatter.formatArea(planesStats[p.id]?.aisleArea);
        const storageArea = formatter.formatArea(
          p.area - planesStats[p.id]?.aisleArea,
        );
        const aislesShare = formatter.formatShare(
          planesStats[p.id]?.aisleArea / p.area,
        );
        const storageShare = formatter.formatShare(
          1 - planesStats[p.id]?.aisleArea / p.area,
        );

        const legendOptions = [{ label: t`m²` }, { label: `%` }];
        const [showPercentage, setShowPercentage] = useState<boolean>(false);

        return (
          <Stat
            key={`plane-area-stats-${p.id}`}
            title={t(`Floor: {{title}}`, { title: p.title })}
            // value={p.title}
            hasHelper
            isPreview
            inPanelMode
          >
            <ActionBar className="mb-2" transparent>
              <Spacer flexspace />
              <ButtonSwitchMulti
                autoSize
                buttonType="minimal"
                selectedIndex={showPercentage ? 1 : 0}
                onClick={index => setShowPercentage(index === 1)}
                options={legendOptions}
              />
            </ActionBar>

            <StatListItem
              title={t`Total Size`}
              value={planeArea.value}
              unitOfMeasure={planeArea.unit}
            />
            {showPercentage ? (
              <StatListItem
                title={t`Storage/Aisles`}
                valueAfter={storageShare}
                valueBefore={aislesShare}
                unitOfMeasure={storageShare.unit}
                isComparable
                separator={`/`}
              />
            ) : (
              <StatListItem
                title={t`Storage/Aisles`}
                valueAfter={storageArea}
                valueBefore={aislesArea}
                unitOfMeasure={storageArea.unit}
                isComparable
                separator={`/`}
              />
            )}

            {/* <StatListItem
              title={t`Aisles Area`}
              value={aislesArea.value}
              unitOfMeasure={aislesArea.unit}
            /> */}

            {/* <StatListItem
              title={t`Storage %`}
              value={storageShare.value}
              unitOfMeasure={storageShare.unit}
            />
            <StatListItem
              title={t`Aisles %`}
              value={aislesShare.value}
              unitOfMeasure={aislesShare.unit}
            /> */}
          </Stat>
        );
      })}
    </StatGroup>
  );
};

export default LayoutStats;
