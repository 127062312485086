import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import AssignmentDashboardCard from '../AssignmentDashboardCard';
import { assignmentDocument } from '../store/assignment.state';

const AssignmentStatsPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const document = useRecoilValue(assignmentDocument);

  return (
    <PanelBody>
      <ScreenTitle isSticky title={t`Stats`} subtitle={t`Assignment`} />

      <div className={`p-2`}>
        <AssignmentDashboardCard
          summary={document?.summary}
          itemLimit={50}
          isActionable={false}
        />
      </div>
    </PanelBody>
  );
};

export default AssignmentStatsPanel;
