import {
  LoadOrderSetDataQuery,
  LoadOrderSetDataQueryVariables,
  OrderHeaderDataFragment,
  OrderLineDataFragment,
  OrderSetItemFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  ProductCategoryDescriptorExport,
  getCategoryString,
} from './export-converter-utils';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type OrderSetExportJobParams = ExportJobParams<
  LoadOrderSetDataQueryVariables,
  OrderSetConverterConfig
>;

export const orderSetExportJobParams: OrderSetExportJobParams = {
  converterId: 'ORDER_SET',
  query: null,
  config: null,
  variables: null,
  filename: 'WareBee--Orders.csv',
};

export type OrderSetRow = Omit<OrderLineDataFragment, '__typename'> &
  Omit<OrderHeaderDataFragment, '__typename'> &
  Omit<OrderSetItemFragment, '__typename'> & {
    categorySting: string;
  };

export type OrderSetConverterConfig = {
  columnsConfig: ColumnConfigBase<OrderSetRow>[];
  dictionaries: {
    categories: ProductCategoryDescriptorExport[];
  };
};

export const getOrderSetTableRows = (
  data: OrderLineDataFragment[],
  config: OrderSetConverterConfig,
): OrderSetRow[] => {
  return _.map(data, row => ({
    ...row,
    ...row.orderHeader,
    ...row.itemDetails,
    categorySting: getCategoryString(
      row.itemDetails.cumulativePercentRank,
      config.dictionaries.categories,
    )?.tag,
  }));
};

export const ORDER_SET_EXPORT_CONVERTER: ExportConverter<
  LoadOrderSetDataQuery,
  OrderSetConverterConfig
> = (input, config) => {
  const data = getOrderSetTableRows(
    input?.orderSet?.orderLines?.content,
    config,
  );

  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
