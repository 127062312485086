import { AssignmentPolicyFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleSection from '../../../components/layout/TitleSection';
import { PolicyCardItem } from '../../../components/policies/PolicyCardItem';
import { getAssignmentComplianceModeOptions } from '../../store/assignmentPolicy.helper';
import {
  assignmentPolicyDocument,
  assignmentPolicySelectedIdentity,
} from '../../store/assignmentPolicy.state';

const AssignmentPolicyComplianceModeEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(assignmentPolicySelectedIdentity);
  const [policy, setPolicy] = useRecoilState(assignmentPolicyDocument);
  if (
    selectedIdentity?.filterType !== 'items-compliance-mode' &&
    selectedIdentity?.filterType !== 'locations-compliance-mode'
  )
    return null;

  const selected =
    selectedIdentity?.filterType === 'items-compliance-mode'
      ? policy.fallbackItemsAllowAnyLocations
      : policy.fallbackLocationsAllowAnyItems;

  const onSelectValue = (value: boolean) => {
    const patch: Partial<AssignmentPolicyFragment> =
      selectedIdentity?.filterType === 'items-compliance-mode'
        ? { fallbackItemsAllowAnyLocations: value }
        : { fallbackLocationsAllowAnyItems: value };

    setPolicy({
      ...policy,
      ...patch,
    });
  };

  const options = getAssignmentComplianceModeOptions(t);

  // console.log('options', options);

  return (
    <div data-component="AssignmentPolicyComplianceModeEditor">
      <TitleSection
        id={`policy-editor-picking-mode-section`}
        title={t`Default Compliance Mode`}
        inSidebarView
        hasScreenTitle
      >
        <div className="min-h-5">
          <div className={`bg-app-panel-dark/60 flex flex-col p-1`}>
            <div
              className={`relative flex h-full min-h-[3rem] flex-1 flex-col`}
            >
              {_.map(options, (mode, index) => {
                return (
                  <PolicyCardItem
                    key={`PolicyCardItem-Mode-${mode.id}`}
                    isActive={mode.value === selected}
                    onClick={() => onSelectValue(mode.value)}
                    icon={mode.icon}
                    iconClassName={`w-6 h-6 lg:w-9 lg:h-9 xl:w-12 xl:h-12 ltr:mr-2 rtl:ml-2`}
                    title={mode.title}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </TitleSection>
    </div>
  );
};

export default AssignmentPolicyComplianceModeEditor;
