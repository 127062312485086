import classNames from 'classnames';
import _ from 'lodash';
import React, { MouseEventHandler, useState } from 'react';

export type PolicyFilterProps = {
  children?: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
  label?: string;
  hasIssue?: boolean;
  isActive?: boolean;
  isEmpty?: boolean;
  isDisabled?: boolean;
  transparent?: boolean;
  color?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const PolicyFilter = ({
  children,
  className,
  label,
  hasIssue,
  isActive,
  isDisabled,
  isEmpty,
  action,
  transparent,
  onClick,
}: PolicyFilterProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <div
      id={id}
      data-component="PolicyFilter"
      data-label={`PolicyFilter-${label}`}
      key={`PolicyFilter-${label}`}
      className={classNames(
        'group/PolicyFilter',
        'rounded',
        'z-0',
        'p-0.5 2xl:p-1',
        'm-0 2xl:m-1',
        'duration-400 transition',
        'shadow-xl',
        { hidden: isDisabled && isEmpty },
        // { 'bg-alerts-alert/75': hasIssue },
        // {
        //   'bg-app-panel/10 text-menu-text/90 border-0': isDisabled && !isActive,
        // },
        {
          'bg-app-panel/20 border-0': isDisabled && !isActive && !transparent,
        },

        { 'text-menu-active-text/75 border-none': isDisabled && isActive },
        {
          'bg-menu-active text-menu-active-text border-app-panel-dark cursor-pointer border-2':
            isActive && !isDisabled && !hasIssue && !transparent,
        },
        {
          'bg-app-panel-dark/80 text-menu-text cursor-default backdrop-blur backdrop-filter':
            !isActive && !isDisabled && !hasIssue,
        },
        'space-y-0.5 2xl:space-y-1',
        className,
      )}
      onClick={e => !isDisabled && onClick && onClick(e)}
    >
      <div className={classNames('flex items-center')}>
        {label && (
          <label
            className={classNames(
              'flex flex-1 items-center',
              'text-minimal lg:text-xxs text-start uppercase',
              'p-1',
              'opacity-75',
            )}
          >
            {label}
          </label>
        )}
        {!isDisabled && (
          <div className={classNames('flex items-center', 'text-end')}>
            {action}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
