import React from 'react';
import { useRecoilState } from 'recoil';
import { createFilterTagData, TableFilter } from '../components/TableFilter';
import { itemSetItemsDataState } from './store/itemSet.state';
import useItemSetDataTableConfig from './useItemSetDataTableConfig';

const ItemSetFilterTag: React.FC = () => {
  const [dataState, setDataState] = useRecoilState(itemSetItemsDataState);
  const columnsConfig = useItemSetDataTableConfig();
  return (
    <TableFilter
      filterData={createFilterTagData(dataState.searchValues, columnsConfig)}
      onRemoveClick={() => setDataState({ ...dataState, searchValues: {} })}
    />
  );
};

export default ItemSetFilterTag;
