import { DeallocationPolicyInput } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn, getIndexedTitle } from '../../../common/utils';
import InboxZero from '../../../components/InboxZero';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import TitleSection from '../../../components/layout/TitleSection';
import { StatusTag } from '../../../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../../../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../../../components/policies/PolicyWell';
import { collapsibleStateAtom } from '../../../store/collapsible.state';
import { sidebarStateByType } from '../../../store/sidebar.state';
import { warehouseSelectedId } from '../../../store/warehouse.state';
import useUpdateSimulation from '../../hooks/useUpdateSimulation';
import {
  DEALLOCATION_POLICY_DEFAULT_RULE_ID,
  getDeallocationPolicyInput,
} from '../../store/allocation/allocation.helper';
import {
  deallocationPolicy,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import {
  simulationCurrent,
  simulationIsEditable,
} from '../../store/simulation.state';
import DeallocatePolicyRule from './DeallocatePolicyRule';

const DeallocatePolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const whId = useRecoilValue(warehouseSelectedId);
  const [policy, setPolicy] = useRecoilState(deallocationPolicy);
  const [savedPolicy, setSavedPolicy] = useState<DeallocationPolicyInput>(null);
  const updateSim = useUpdateSimulation();

  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    deallocationPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-allocation-editor'),
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(simulationIsEditable);

  // // trigger policy saving and policy check request when AP changed
  useEffect(() => {
    const policyToSave = getDeallocationPolicyInput(policy);

    const isPolicyChanged = !_.isEqual(policyToSave, savedPolicy);
    const isInitial = savedPolicy === null;
    setSavedPolicy(policyToSave);
    if (!isPolicyChanged) return;

    if (!isInitial) {
      updateSim({
        allocationSettings: {
          ...sim.allocationSettings,
          deallocationPolicy: policyToSave,
        },
      });
    }
  }, [policy]);

  useEffect(() => {
    return () => {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: true,
        isHidden: true,
      });
    };
  }, []);

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy.rules, r => r.title)),
      t`Re-Allocate Rule #`,
    );
    setPolicy({
      ...policy,
      rules: [
        ...(policy.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          itemsMatch: { anyOf: [] },
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  const hasRules = !_.isEmpty(policy?.rules);
  const rulesCount = _.size(policy?.rules);

  function getPolicyHeaderContent() {
    if (canUpdate && hasRules) {
      return (
        <Button
          label={t`Add Policy`}
          className={classNames('rounded ltr:ml-4 rtl:mr-4')}
          buttonSize="xs"
          buttonType="primary"
          hasIconAfter={<Icon.CirclePlus className={`h-5 w-5 fill-current`} />}
          onPress={() => addRule()}
        />
      );
    }

    if (!canUpdate) {
      return (
        <StatusTag
          title={t`Policy Locked`}
          type="locked"
          modeStyle="stroke"
          icon={Icon.Lock}
          onPress={null}
        />
      );
    }

    return null;
  }

  return (
    <Container col hasOverflowY>
      {/* <ScreenTitle
        title={t`Deallocate policy`}
        subtitle={t`Items`}
        isSticky
        icon={Icon.PolicyAssignment}
      /> */}
      <TitleSection
        title={
          <PolicyRuleSectionTitle isFeasible={true} counter={rulesCount} />
        }
        className={classNames('z-400 top-12 xl:top-20')}
        inPanelView
        hasScreenTitle
        hasAction={getPolicyHeaderContent()}
      />

      {/* <DeallocatePolicyRuleDetailWatcher /> */}
      <Container col flex1 hasOverflowY>
        <PolicyWell fullHeight isDisabled={!canUpdate} isCentred={!hasRules}>
          {!hasRules && (
            <InboxZero selfCenter hasIcon message={t`No Rules found`}>
              {canUpdate && (
                <div
                  className={classNames(
                    'mt-4 flex flex-col items-center space-y-2 xl:flex-row xl:space-y-0',
                  )}
                >
                  <Button
                    full
                    buttonSize="xs"
                    buttonType="primary"
                    label={t`Add Policy`}
                    className="w-full flex-1 rounded"
                    onPress={addRule}
                    hasIconAfter={
                      <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                    }
                  />
                </div>
              )}
            </InboxZero>
          )}

          {_.map(policy?.rules, (rule, index) => {
            return (
              <DeallocatePolicyRule
                isDisabled={!canUpdate}
                key={`deallocation-rule-${rule.id}`}
                ruleId={rule.id}
                index={index + 1}
                isRemovable={canUpdate}
                canDelete={canUpdate}
                canRename={canUpdate}
                onDeleteClick={() => removeRule(rule.id)}
              />
            );
          })}
        </PolicyWell>

        <TitleSection title={t`Default Policy`} inPanelView />
        <PolicyWell
          data-component="PolicyWellRuleDeallocateDefault"
          fullHeight
          isDisabled={!canUpdate}
          className={cn('mb-2 flex-1')}
          classNameChildren={cn('space-y-2 pb-10')}
        >
          <DeallocatePolicyRule
            isDisabled={!canUpdate}
            key={`deallocation-rule-default`}
            ruleId={DEALLOCATION_POLICY_DEFAULT_RULE_ID}
            // index={index + 1}
            isRemovable={false}
            canDelete={false}
            canRename={false}
            // onDeleteClick={() => removeRule(rule.id)}
          />{' '}
        </PolicyWell>
      </Container>
    </Container>
  );
};

export default DeallocatePolicy;
