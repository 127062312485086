import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import { formatInteger } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import InboxZero from '../../components/InboxZero';
import {
  loadBalancingPolicyLocationByLevel,
  loadBalancingPolicyRuleSelected,
  loadBalancingPolicyShowHeatmap,
} from '../../loadBalancingPolicy/loadBalancingPolicy.state';
import useGetZoneTitle from '../../loadBalancingPolicy/useGetZoneTitle';

const WorkLoadBalancingPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();
  const [showHeatmap, setShowHeatmap] = useRecoilState(
    loadBalancingPolicyShowHeatmap,
  );
  const ruleSummary = useRecoilValue(loadBalancingPolicyLocationByLevel);
  const rule = useRecoilValue(loadBalancingPolicyRuleSelected);
  const getZoneTitle = useGetZoneTitle();

  if (_.isNil(ruleSummary) || _.isNil(rule))
    return <InboxZero message={t`No Data`}></InboxZero>;

  return (
    <div className="space-y-1">
      <div className="text-xs flex flex-col p-1 bg-menu-500 rounded mx-1">
        <span>{t`Variance:`}</span>
        <span>{formatInteger(ruleSummary.variance)}</span>
      </div>
      <div className="text-xs flex flex-col p-1 bg-menu-500 rounded mx-1">
        <span>{t`Load (avg)`}</span>
        <span>{formatInteger(ruleSummary.meanLoad)}</span>
      </div>
      <div className="overflow-y-auto h-full space-y-1">
        {_(ruleSummary.zones)
          .sortBy(z => z.zoneLoad)
          .map(z => {
            const color = _.head(getQualitativeColor(z.zoneId, 'policy'));
            return (
              <div
                key={z.zoneId}
                className="text-xs flex flex-col p-1 bg-menu-500 rounded mx-1"
              >
                <div className="flex items-center">
                  <div
                    style={{ background: color }}
                    className="flex w-3 h-3 rounded mx-1"
                  ></div>
                  <div className="text-xs ml-1 truncate max-w-[10ch]">
                    {getZoneTitle(z.zoneId, rule)}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex items-center flex-1">
                    <div className="w-4 h-4 text-xs mx-1 flex items-center">
                      &#9733;
                    </div>
                    <div className="text-xs">{formatInteger(z.zoneLoad)}</div>
                  </div>

                  <div className="flex items-center flex-1 text-menu-active-300">
                    <div className="w-4 h-4 text-xs mx-1 flex items-center">
                      &Delta;
                    </div>
                    <div className="text-xs">
                      {
                        formatter.formatShare(
                          z.zoneLoad / ruleSummary.meanLoad - 1,
                        ).fullString
                      }
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          .value()}
      </div>
    </div>
  );
};

export default WorkLoadBalancingPanel;
