import * as React from 'react';
const FloorIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="FloorIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="91.22 13.03 91.22 39.43 64.42 39.43 64.42 65.83 37.42 65.83 37.42 92.23 10.62 92.23 10.62 125.03 23.62 125.03 23.62 105.03 50.42 105.03 50.42 78.63 77.42 78.63 77.42 52.23 104.22 52.23 104.22 25.83 124.62 25.83 124.62 13.03 91.22 13.03"
    />
    <polygon
      strokeWidth={0}
      points="13.82 63.03 37.42 39.83 37.42 52.03 50.42 52.03 50.42 18.03 15.82 18.03 15.82 30.83 28.22 30.83 4.62 54.03 13.82 63.03"
    />
  </svg>
);
export default FloorIcon;
