import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import ResourcePolicyPanel from '../../resourcePolicy/ResourcePolicyPanel';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import ActionBarNextStep from './ActionBarNextStep';

const SidebarResourcePolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectStep = useSelectSimulationWizardStep();

  return (
    <>
      <ResourcePolicyPanel />
      <ActionBarNextStep />
    </>
  );
};

export default () => {
  const { t } = useTranslation('simulation');
  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-resource',
    title: t`Resource Policy`,
    loadingMessage: t`Loading Resource Policy...`,
    size: 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SidebarResourcePolicy />
    </SidebarContainer>
  );
};
