import {
  ItemFilterIntersectionFragment,
  ItemSetDataFragment,
  ItemSetFragment,
  StackingTotalsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { ItemSetDataTableColumn } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import {
  ItemSetDataStatus,
  ItemSetFilterData,
  ItemSetMenuItemId,
  PalletOrderPolicy,
  PalletOrderRule,
  PalletOrderRuleId,
  PalletOrderRuleItemData,
} from './itemSet.types';

const getKey = (postfix: string) => `warebee-item-set-${postfix}`;

export const itemSetDocumentId = atom<string>({
  key: getKey('document-id'),
  default: null,
});

export const itemSetDocument = atom<ItemSetFragment>({
  key: getKey('document'),
  default: null,
});
export const itemSetDocumentLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('document-load-state'),
  default: AsyncLoadStatus.None,
});

export const itemSetDocumentSaveStatus = atom<AsyncLoadStatus>({
  key: getKey('document-save-state'),
  default: AsyncLoadStatus.None,
});

export const itemSetItemsData = atom<ItemSetDataFragment>({
  key: getKey('items-data'),
  default: null,
});

export const itemSetItemsDataStatus = atom<AsyncLoadStatus>({
  key: getKey('items-data-status'),
  default: AsyncLoadStatus.None,
});
export const itemSetItemsDataState = atom<
  DataTableState<ItemSetDataTableColumn>
>({
  key: getKey('items-data-state'),
  default: {
    searchValues: {},
    sortValues: {},
  },
});

export const itemSetSelectedMenuItemId = persistAtom<ItemSetMenuItemId>({
  key: getKey('selected-menu-item-id'),
  default: 'item-set-menu-start',
});

export const itemSetPalletSortPolicyData = atom<PalletOrderRuleItemData[]>({
  key: getKey('pallet-sort-policy-data'),
  default: null,
});

export const itemSetPalletSortPolicyDataStatus = atom<AsyncLoadStatus>({
  key: getKey('pallet-sort-policy-data-status'),
  default: AsyncLoadStatus.None,
});

export const itemSetPalletSortPolicyDataState = atom<ItemSetDataStatus>({
  key: getKey('pallet-sort-policy-data-state'),
  default: { totalCount: 0 },
});

export const itemSetSelectedRuleId = atom<PalletOrderRuleId>({
  key: getKey('rule-selected-id'),
  default: null,
});
export const itemSetSelectedRule = selector<PalletOrderRule>({
  key: getKey('rule-selected'),
  get: ({ get }) => {
    const id = get(itemSetSelectedRuleId);
    return get(itemSetRuleById(id));
  },
});

export const itemSetSelectedFilterSetId = atom<string>({
  key: getKey('selected-filter-set-id'),
  default: null,
});

export const itemSetSelectedFilterSet =
  selector<ItemFilterIntersectionFragment>({
    key: getKey('selected-filter-set'),
    get: ({ get }) => {
      const filterSetId = get(itemSetSelectedFilterSetId);
      const policy = get(itemSetPalletSortPolicy);
      return _(policy.rules)
        .map(rule => rule.itemsMatch)
        .flatten()
        .map(match => match.anyOf)
        .flatten()
        .find(filterSet => filterSet.id === filterSetId);
    },

    set: ({ get, set }, value: ItemFilterIntersectionFragment) => {
      const ruleId = get(itemSetSelectedRuleId);
      const rule = get(itemSetRuleById(ruleId));
      if (!rule) {
        console.error('Error while updating item filter');
      }
      set(itemSetRuleById(ruleId), {
        ...rule,
        itemsMatch: {
          anyOf: rule.itemsMatch.anyOf.map(filterSet =>
            filterSet.id === value.id ? value : filterSet,
          ),
        },
      });
    },
  });

export const itemSetPalletSortPolicy = atom<PalletOrderPolicy>({
  key: getKey('pallet-sort-policy'),
  default: null,
});

export const itemSetRuleById = selectorFamily<PalletOrderRule, string>({
  key: getKey('rule-by-id'),
  get:
    (ruleId: string) =>
    ({ get }) => {
      return _.find(get(itemSetPalletSortPolicy).rules, r => r.id === ruleId);
    },
  set:
    (ruleId: string) =>
    ({ get, set }, value: PalletOrderRule) => {
      const policy = get(itemSetPalletSortPolicy);
      set(itemSetPalletSortPolicy, {
        ...policy,
        rules: policy.rules.map(rule => (rule.id === ruleId ? value : rule)),
      });
    },
});
export const itemSetSelectedRuleResultsId = atom<PalletOrderRuleId>({
  key: getKey('selected-rule-results-id'),
  default: null,
});

export const itemSetPalletPolicyCheckResults = atom<StackingTotalsFragment>({
  key: getKey('policy-check-results'),
  default: null,
});

export const itemSetPalletPolicyRuleCheckResults = selectorFamily<
  number,
  PalletOrderRuleId
>({
  key: getKey('policy-check-results-by-rule'),
  get:
    (ruleId: PalletOrderRuleId) =>
    ({ get }) => {
      const checkResult = get(itemSetPalletPolicyCheckResults);
      if (!checkResult) return null;
      switch (ruleId) {
        case 'items-pallet-order-bottom':
          return checkResult?.totalBottomCount;
        case 'items-pallet-order-top':
          return checkResult?.totalTopCount;
        case 'items-pallet-order-middle':
          return (
            checkResult?.totalCount -
            checkResult?.totalBottomCount -
            checkResult?.totalTopCount
          );
      }
    },
});
export const itemSetPolicyCheckStatus = atom<AsyncLoadStatus>({
  key: getKey('policy-check-results-status'),
  default: AsyncLoadStatus.None,
});

export const itemSetFilterValues = atom<Record<string, ItemSetFilterData>>({
  key: getKey('filter-values-all'),
  default: null,
});

export const itemSetFilterValuesByField = selectorFamily<
  ItemSetFilterData,
  string
>({
  key: getKey('filter-values-by-field'),
  get:
    (field: string) =>
    ({ get }) =>
      get(itemSetFilterValues)?.[field],
  set:
    (field: string) =>
    ({ get, set }, value) => {
      const current = get(itemSetFilterValues);
      set(itemSetFilterValues, {
        ...current,
        [field]: value,
      });
    },
});

export const itemSetEditorsSearchValues = atom<Record<string, string>>({
  key: getKey('editors-search-values'),
  default: {},
});

export const itemSetViewSearchValues = atom<Record<string, string>>({
  key: getKey('view-search-values'),
  default: {},
});
