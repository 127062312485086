import * as React from 'react';
const LayoutWarehouseCsvIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="LayoutWarehouseCsvIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M29.18,95.63V18.45C29.18-1.89,4.1.29,4.1,18.45v77.72c4.77-10.92,20.89-10.05,25.09-.55Z"
    />
    <path
      strokeWidth={0}
      d="M35.9,22.15v84.71c0,5.94-5.39,6.03-6.72.82-1.39-5.48-6.44-9.32-12.2-9.32-7.13,0-12.89,5.76-12.89,12.75s5.76,12.89,12.89,12.89h106.92V22.15H35.9ZM61.12,90.86c0,.43-.12.65-.65.59-1.27-.12-2.57-.28-4.34-.28-1.67,0-3.19.74-3.19,4.68v3.19c0,3.91,1.49,4.68,3.19,4.68s2.98-.12,4.34-.28c.56-.06.65.22.65.59v3.97c0,.4-.19.53-.68.68-.81.28-2.39.56-4.34.56-5.71,0-9.55-3.1-9.55-10.2v-3.19c0-7.1,3.84-10.2,9.55-10.2,1.95,0,3.54.28,4.34.56.5.15.68.25.68.68v3.97ZM71.14,109.25c-2.54,0-4.62-.34-6.26-.81-.31-.09-.65-.28-.65-.62v-3.81c0-.43.19-.65.65-.62,1.33.12,4.65.46,6.29.46,1.52,0,1.95-.59,1.95-1.58,0-.62-.31-.96-1.46-1.64l-4.46-2.64c-2.91-1.71-3.69-3.81-3.69-6.2,0-3.38,1.86-6.17,8.12-6.17,2.45,0,5.77.4,7.04.78.28.09.56.25.56.59v3.88c0,.34-.12.62-.62.59-1.27-.09-4.81-.4-7.04-.4-1.15,0-1.55.4-1.55,1.05,0,.56.28.87,1.61,1.58l4.19,2.23c3.01,1.61,4.09,3.66,4.09,6.23,0,3.47-2.39,7.1-8.77,7.1ZM102.17,86.55l-4.16,18.98c-.68,3.07-2.98,3.72-5.95,3.72s-5.27-.65-5.95-3.72l-4.15-18.98c-.06-.34.03-.62.5-.62h5.36c.43,0,.59.28.65.59l3.25,17.15c.06.28.09.43.34.43s.31-.16.37-.43l3.25-17.15c.06-.31.28-.59.62-.59h5.4c.46,0,.53.28.46.62Z"
    />
  </svg>
);
export default LayoutWarehouseCsvIcon;
