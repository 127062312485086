import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
// import { LogoHeader } from './LogoHeader';

export type InputGroupListProps = {
  children?: React.ReactNode;
  className?: string;
  hasPadding?: boolean;
  hasSpacing?: boolean;
  panelMode?: boolean;
};

export const InputGroupList = ({
  children,
  className,
  hasPadding,
  hasSpacing,
  panelMode,
}: InputGroupListProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <section
      id={id}
      data-component="InputGroupList"
      className={classNames(
        'w-full',
        hasSpacing ? 'space-y-6' : '',
        hasPadding ? (panelMode ? 'px-1 py-2' : 'px-3 py-2') : '',
        className,
      )}
    >
      {children}
    </section>
  );
};
