import * as React from 'react';
const DesignerAislesOrderIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="DesignerAislesOrderIcon"
    {...props}
  >
    <rect width={17.73} height={67.35} x={73.12} y={41.09} strokeWidth={0} />
    <rect
      width={14.57}
      height={121.67}
      x={56.28}
      y={56.67}
      strokeWidth={0}
      transform="translate(181.08 53.95) rotate(90)"
    />
    <rect width={17.73} height={67.35} x={19.91} y={41.09} strokeWidth={0} />
    <rect
      width={13.75}
      height={15.99}
      x={93.02}
      y={92.46}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={93.02}
      y={75.3}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={93.02}
      y={58.15}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={108.94}
      y={92.46}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={108.94}
      y={75.3}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={108.94}
      y={58.15}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={40.88}
      y={92.46}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={40.88}
      y={75.3}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={40.88}
      y={58.15}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={2.73}
      y={92.46}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={2.73}
      y={75.3}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={2.73}
      y={58.15}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={56.8}
      y={92.46}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={56.8}
      y={75.3}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={56.8}
      y={58.15}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={93.02}
      y={41.09}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={108.94}
      y={41.09}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={40.88}
      y={41.09}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={2.73}
      y={41.09}
      strokeWidth={0}
      opacity={0.4}
    />
    <rect
      width={13.75}
      height={15.99}
      x={56.8}
      y={41.09}
      strokeWidth={0}
      opacity={0.4}
    />
    <path
      strokeWidth={0}
      d="M70.55,6.25v29.27s25.18,0,25.18,0V6.25s-25.18,0-25.18,0ZM91.04,31.73h-15.35s0-4.19,0-4.19l6.73-5.6c1.18-1.02,2.02-1.9,2.52-2.63.5-.73.75-1.5.75-2.29v-.31c0-.88-.27-1.55-.8-2.02s-1.24-.7-2.14-.7c-.96,0-1.72.27-2.28.83s-.97,1.27-1.24,2.17l-4-1.53c.51-1.61,1.44-2.92,2.78-3.93,1.35-1.01,3.08-1.51,5.2-1.51,1.53,0,2.85.27,3.96.83,1.11.55,1.96,1.31,2.54,2.28.58.97.87,2.07.87,3.32,0,1.12-.24,2.15-.73,3.09-.49.94-1.13,1.8-1.93,2.58-.8.78-1.82,1.66-3.09,2.61l-4.07,3.15h10.27s0,3.85,0,3.85Z"
      opacity={0.7}
    />
    <path
      strokeWidth={0}
      d="M41.36,6.25h-25.18s0,29.27,0,29.27h25.18s0-29.27,0-29.27ZM37.11,31.55h-14.86s0-3.7,0-3.7h5.75v-14.31h-.27s-4.34,5.69-4.34,5.69l-2.94-2.29,5.11-6.73h7.03s0,17.64,0,17.64h4.53s0,3.7,0,3.7Z"
    />
    <path
      strokeWidth={0}
      d="M56.68,30.69h-7.38s7.19-7.19,7.19-7.19h-9.37s0-5.23,0-5.23h9.37s-7.17-7.19-7.17-7.19h7.38s9.78,9.81,9.78,9.81l-9.8,9.81Z"
    />
    <path
      strokeWidth={0}
      d="M108.83,30.69h-7.38s7.19-7.19,7.19-7.19h-9.37s0-5.23,0-5.23h9.37s-7.17-7.19-7.17-7.19h7.38s9.78,9.81,9.78,9.81l-9.8,9.81Z"
      opacity={0.6}
    />
  </svg>
);
export default DesignerAislesOrderIcon;
