import {
  OrderSettings,
  OrderTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import { InputValue } from '../../../../components/inputs/InputValue';
import { ConvertedAreaFeature } from '../converter.serializable.model';
import {
  getBottomAccessAisleId,
  getFeatureOrderIndex,
  getTopAccessAisleId,
} from '../store/converter.helper';
import { converterLayoutAltSelectedAisleId } from '../store/converter.layout.state';
import { converterAreaBuilder } from '../store/converter.state';
import { getOrderOptionsMap } from '../store/converter.types';
import OrderedItemsTable from './OrderedItemsTable';

type ConverterAisleOrderPanelProps = {
  area: ConvertedAreaFeature;
};

const ConverterAisleOrderPanel: React.FC<
  ConverterAisleOrderPanelProps
> = props => {
  const { t } = useTranslation('designer');
  const setSelectedAisle = useSetRecoilState(converterLayoutAltSelectedAisleId);
  const { area } = props;
  const [areaBuilder, setAreaBuilder] = useRecoilState(
    converterAreaBuilder(area?.id),
  );

  const orderOptions = getOrderOptionsMap(t);
  const aisleOrderSettings: OrderSettings = areaBuilder.aisleOrder || {
    orderType: OrderTypeOption.Default,
    shift: 0,
  };

  function applyChanges(patch: Partial<OrderSettings>) {
    setAreaBuilder({
      ...areaBuilder,
      aisleOrder: {
        ...aisleOrderSettings,
        ...patch,
      },
    });
  }

  const aislesMap = _.keyBy(area.aisles, a => a.title);

  const aisleIds = area.aisles
    .filter(
      a =>
        !a.isSpacer &&
        !a.isAutoSpacer &&
        a.id !== getBottomAccessAisleId(area.id) &&
        a.id !== getTopAccessAisleId(area.id),
    )
    .map(a => a.title);
  const aisleLength = aisleIds.length;

  let sortIndex = 0;
  const sorted = _.sortBy(aisleIds, aisleId =>
    getFeatureOrderIndex(aisleId, sortIndex++, aisleLength, aisleOrderSettings),
  );

  const drawOptionEditor = () => {
    if (aisleOrderSettings.orderType === OrderTypeOption.Custom) {
      return (
        <OrderedItemsTable
          itemColumnTitle={t`Aisle`}
          items={sorted}
          onChange={aisles => applyChanges({ customOrder: aisles })}
          onRowMouseEnter={areaTitle => {
            setSelectedAisle(aislesMap[areaTitle]?.id);
          }}
          onRowMouseLeave={areaId => {
            setSelectedAisle(null);
          }}
        />
      );
    }
    if (aisleOrderSettings.orderType === OrderTypeOption.Shifted) {
      return (
        <InputValue
          value={aisleOrderSettings.shift || 0}
          // title={`Bay's count to shift`}
          title={t`Shift Aisle by:`}
          icon={<Icon.Order className="w-8 h-8 fill-current" />}
          onChange={v => applyChanges({ shift: parseInt(v) })}
        />
      );
    }
    return null;
  };
  return (
    <>
      <DropdownSelector
        panelMode
        widthFull
        value={aisleOrderSettings.orderType}
        values={[...orderOptions.keys()]}
        renderValue={i => orderOptions.get(i)}
        label={t`Aisle Order`}
        onChange={(v: OrderTypeOption) => {
          applyChanges({ orderType: v });
        }}
        icon={<Icon.DesignerAislesOrder className="w-8 h-8 fill-current" />}
      />
      {drawOptionEditor()}
    </>
  );
};

export default ConverterAisleOrderPanel;
