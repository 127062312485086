import { FetchResult, useApolloClient } from '@apollo/client';
import {
  UpdateAssignmentDocument,
  UpdateAssignmentInput,
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import { errorAppender } from '../../store/error.state';
import { assignmentDocumentSaveStatus } from '../store/assignment.state';

function useUpdateAssignment() {
  const client = useApolloClient();
  const { t } = useTranslation('dataset');

  const beforeUpdate = useRecoilCallback(({ set }) => async () => {
    set(assignmentDocumentSaveStatus, AsyncLoadStatus.Loading);
  });

  const callUpdate = useRecoilCallback(
    ({ snapshot, set }) =>
      async (patch: UpdateAssignmentInput) => {
        let response: FetchResult<UpdateAssignmentMutation>;
        try {
          response = await client.mutate<
            UpdateAssignmentMutation,
            UpdateAssignmentMutationVariables
          >({
            mutation: UpdateAssignmentDocument,
            variables: {
              assignmentInput: {
                ...cloneWithoutTypename(patch),
              },
            },
          });
        } catch (ex) {
          console.error(ex);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save Assignment`,
            details: ex.message || ex,
            callStack: ex.stack || null,
          });
          set(assignmentDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }

        if (response.errors) {
          console.error(response.errors);
          set(errorAppender, {
            id: nanoid(),
            title: t`Can not save Assignment`,
            details: null,
            callStack: response.errors.map(e => e.message).join('. '),
          });
          set(assignmentDocumentSaveStatus, AsyncLoadStatus.Error);
          return;
        }
        set(assignmentDocumentSaveStatus, AsyncLoadStatus.Ok);
      },
  );

  return async (patch: UpdateAssignmentInput) => {
    await beforeUpdate();
    await callUpdate(patch);
  };
}
export default useUpdateAssignment;
