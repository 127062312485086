import {
  ItemQuantityDetailsFragment,
  LoadOrderedQuantityItemsQuery,
  LoadOrderedQuantityItemsQueryVariables,
  OrderedQuantityItemFragment,
  OrderedQuantityReportItemStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import {
  ExportConverter,
  ColumnConfigBase,
  ExportJobParams,
  NULL_VALUE,
} from './types';

export type OrderedItemsExportJobParams = ExportJobParams<
  LoadOrderedQuantityItemsQueryVariables,
  OrderedItemsConverterConfig
>;

export const orderedItemsExportJobParams: OrderedItemsExportJobParams = {
  converterId: 'ORDERED_QUANTITY_REPORT',
  query: null,
  config: null,
  variables: null,
  filename: 'order-quantity-report.csv',
};

type OrderedQuantityItemFields = Pick<
  OrderedQuantityItemFragment,
  | 'itemId'
  | 'sku'
  | 'consignee'
  | 'status'
  | 'orderedQuantity'
  | 'stockQuantity'
  | 'assignedCapacity'
  | 'assignedCapacityGap'
>;

export type OrderedItemsDataRow = OrderedQuantityItemFields & {
  statusString: string;
  capacityGapBreakdown: string;
  capacityGapMaxUOM: string;
  stockGapBreakdown: string;
  stockGapMaxUOM: string;
};

export type OrderedItemsConverterConfig = {
  columnsConfig: ColumnConfigBase<OrderedItemsDataRow>[];
  dictionaries: {
    statusMap: Record<OrderedQuantityReportItemStatus, string>;
  };
};

const getStatus = (
  status: OrderedQuantityReportItemStatus,
  statusMap: Record<OrderedQuantityReportItemStatus, string>,
) => {
  if (_.isEmpty(status)) return NULL_VALUE;
  return statusMap[status];
};

function getBreakdown(details: ItemQuantityDetailsFragment): string {
  return (
    _(details?.breakdownByUom)
      .map(uom => `${uom.uom}: ${uom.uomQuantity}`)
      .join(', ') ?? NULL_VALUE
  );
}
function getMaxUomQty(details: ItemQuantityDetailsFragment): string {
  const maxUOM = _.head(details?.quantityPerUom);
  return maxUOM ? `${maxUOM.uom}: ${maxUOM.uomQuantity}` : NULL_VALUE;
}

export const getOrderedItemsTableRows = (
  data: OrderedQuantityItemFragment[],
  config: OrderedItemsConverterConfig,
): OrderedItemsDataRow[] => {
  const getOrderedItemsDataRow = (row: OrderedQuantityItemFragment) => {
    return {
      ...row,
      statusString: getStatus(row.status, config.dictionaries.statusMap),
      capacityGapBreakdown: getBreakdown(
        row.details.assignedCapacityGapDetails,
      ),
      capacityGapMaxUOM: getMaxUomQty(row.details.assignedCapacityGapDetails),
      stockGapBreakdown: getBreakdown(row.details.stockQuantityGapDetails),
      stockGapMaxUOM: getMaxUomQty(row.details.stockQuantityGapDetails),
    };
  };

  return _.map(data, getOrderedItemsDataRow);
};

export const ORDERED_QUANTITY_REPORT_EXPORT_CONVERTER: ExportConverter<
  LoadOrderedQuantityItemsQuery,
  OrderedItemsConverterConfig
> = (input, config) => {
  const data = getOrderedItemsTableRows(
    input.simulation?.orderedQuantityReport?.items?.content,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
