import classNames from 'classnames';
import _ from 'lodash';
import { PropsWithChildren } from 'react';
import { analyticsAgent } from '../AnalyticTracker';
import { cn } from '../common/utils';
import * as Icon from './icons';

export type LayoutFilterProps = PropsWithChildren & {
  className?: string;
  filterName?: string;
  type?: 'filter' | 'selection';
  onRemoveClick?: () => void;
};

const LayoutFilter: React.FC<LayoutFilterProps> = props => {
  const { type = 'filter' } = props;
  const TypeIcon = type === 'filter' ? Icon.Filter : Icon.CursorClick;

  const typeClass =
    type === 'filter'
      ? 'bg-menu-active text-menu-active-text hover:bg-menu-hover hover:text-menu-active-text'
      : 'bg-app-header/60 border border-dashed border-menu-active text-menu-active';

  const typeIconClass =
    type === 'filter' ? 'h-3 w-3 fill-current' : 'h-4 w-4 fill-current';

  function remove() {
    if (!_.isFunction(props.onRemoveClick)) return;
    props.onRemoveClick();
    try {
      analyticsAgent?.track(`LayoutFilter: Deselect ${type}`);
    } catch (ex) {
      console.debug('analyticsAgent Error:', ex);
    }
  }

  const tailwind_hack = (
    <div
      className={cn(
        'group/filter',
        'group-hover/filter:hidden',
        'group-hover/filter:flex',
        'group/selection',
        'group-hover/selection:hidden',
        'group-hover/selection:flex',
      )}
    />
  );

  const group = type === 'filter' ? `filter` : `selection`;

  return (
    <div
      data-component={`LayoutFilter`}
      className={classNames(
        `group/${group}`,
        typeClass,

        'cursor-pointer',
        'rounded',
        'flex items-center',
        'mx-1 px-2 py-1',
        'text-xxs md:text-xs',
        'truncate whitespace-nowrap',
        props.className,
      )}
      onClick={remove}
    >
      <div
        data-component="FilterControl"
        className={cn('ltr:mr-1 rtl:ml-1', 'flex')}
      >
        <div
          className={cn(`flex group-hover/${group}:hidden`)}
          title={`Hide filter`}
        >
          <TypeIcon className={cn(typeIconClass)} />
        </div>
        <div
          className={cn(`hidden group-hover/${group}:flex`)}
          title={`Show filter`}
          onClick={remove}
        >
          <Icon.CircleX className={cn(typeIconClass)} />
        </div>
      </div>

      <div
        className={cn(
          'flex flex-1',
          'opacity-70',
          'ltr:mr-0.5 rtl:ml-0.5',
          'truncate',
        )}
      >
        {props.filterName}
      </div>

      <span className={cn('truncate')}>{props.children}</span>
    </div>
  );
};

export default LayoutFilter;
