import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import { StatusType } from '../components/_ComponentsThemeStyles';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';

export type ErrorContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  className?: string;
  classNameInner?: string;
  title: string;
  subtitle?: string;
  hasFilter?: string;
  loadingMessage?: string;
  maxHeight?: number;
  hasScroll?: boolean;
  hasPadding?: boolean;
  hidden?: boolean;
  icon?: React.FC<HTMLAttributes<Element>>;
  borderTop?: boolean;
  collapsible?: boolean;
  collapsibleLabel?: string;
  transparent?: boolean;
  hasZindex?: boolean;
  status?: keyof typeof StatusType;
};

const ErrorContainer: FC<ErrorContainerProps> = ({
  hasZindex = true,
  ...props
}) => {
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };
  const IconMenu = props.icon;

  return (
    <div
      data-component="ErrorContainer"
      className={classNames(
        hasZindex && 'z-alert',
        'warning-stripes-animation',
        props.status
          ? StatusType[props.status]
          : 'border border-menu-50 border-opacity-50 rounded',
      )}
    >
      <Button
        full
        isTransparent
        buttonSize={`header`}
        className={classNames(
          'sticky top-0 accordion-header z-container group',
          props.transparent
            ? 'uppercase'
            : props.collapsible
              ? 'cursor-pointer'
              : '',
          'flex items-center',
          'p-0.5 transition duration-100 ease-in-out delay-75',
          { closed: isClosed },
          props.className,
        )}
        onPress={props.collapsible && (() => toggleOpen())}
      >
        {props.borderTop && (
          <hr className={classNames('mt-1 mb-2', 'border-app-panel-dark')} />
        )}
        <div
          className={classNames(
            'flex items-center flex-1',
            'h-full',
            'ltr:pl-2 rtl:pr-2',
          )}
        >
          {IconMenu && (
            <IconMenu
              className={classNames(
                'fill-current',
                'w-4 h-4',
                'ltr:mr-2 rtl:ml-2',
              )}
            />
          )}

          {(props.collapsible && (
            <>
              <span className={`flex-1 text-start`}>{props.title}</span>
              <span
                className={classNames(
                  'sm:bg-app-panel-dark/20 lg:bg-app-panel-dark/10 hover:bg-app-panel-dark/50',
                  'p-2',
                  'rounded-full',
                  'flex items-center',
                  'text-menu-text hover:text-menu-active any-hover:group-hover:text-menu-active',
                )}
              >
                {isClosed ? (
                  <>
                    {props.collapsibleLabel && (
                      <span
                        className={classNames(
                          'text-xxs lg:text-xs',
                          'ltr:mr-1 rtl:ml-1',
                        )}
                      >
                        {props.collapsibleLabel}
                      </span>
                    )}
                    <Icon.ChevronDualOpenVertical
                      className={classNames('w-4 h-4 fill-current')}
                    />
                  </>
                ) : (
                  <>
                    {props.collapsibleLabel && (
                      <span
                        className={classNames(
                          'text-xxs lg:text-xs ltr:mr-1 rtl:ml-1',
                        )}
                      >
                        {props.collapsibleLabel}
                      </span>
                    )}
                    <Icon.ChevronDualCloseVertical
                      className={classNames('w-4 h-4 fill-current')}
                    />
                  </>
                )}
              </span>
            </>
          )) || (
            <span
              className={classNames(
                'flex-1',
                'ltr:pl-2 rtl:pr-2',
                'text-sm text-start',
                'truncate test-start',
                'group-hover:opacity-70',
              )}
            >
              {props.title}
            </span>
          )}
        </div>
      </Button>

      <Transition
        className={`bg-blend-darken`}
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <section
          data-component="ErrorContainerBody"
          className={classNames(
            'flex-none select-text',
            {
              'py-0.5 px-4': props.hasPadding,
            },
            {
              hidden: props.hidden,
            },
            props.className,
          )}
        >
          {props.children}
        </section>
      </Transition>
    </div>
  );
};

export default ErrorContainer;
