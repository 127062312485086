export const button = {
  render: 'Button',
  description: 'Display the enclosed content in a callout box',
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    // type: {
    //   type: String,
    //   default: 'note',
    //   matches: ['caution', 'check', 'note', 'warning'],
    //   errorLevel: 'critical',
    //   description:
    //     'Controls the color and icon of the callout. Can be: "caution", "check", "note", "warning"',
    // },
    label: {
      type: String,
      description: 'The title displayed at the top of the callout',
    },
    buttonType: {
      type: String,
      default: 'primary',
      description: '',
    },
    buttonSize: {
      type: String,
      default: 'xs',
      description: '',
    },
  },
};
