import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SwitchAnalyzeType from '../../analyze/SwitchAnalyzeType';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import { allocationRunSummary } from '../store/allocation/allocation.state';
import { itemsContentViewAs } from '../store/analyze.state';
import { simulationAnalyzeTypeSelected } from '../store/simulation.state';
import { simulationAnalyseTabKey } from '../store/simulation.wizard.state';
import { SimulationAnalyzeSidebarCmp } from './SimulationAnalyzeSidebarCmp';

export default () => {
  const { t } = useTranslation('simulation');
  const allocationRun = useRecoilValue(allocationRunSummary);
  const analyzeType = useRecoilValue(simulationAnalyzeTypeSelected);
  const selectedTab = useRecoilValue(simulationAnalyseTabKey);
  const itemsViewAs = useRecoilValue(itemsContentViewAs);

  const hasAllocationRun =
    allocationRun?.status === 'READY' && !_.isNil(allocationRun?.analyzeResult);

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-simulation-analyze',
    title: t`Analyse Results`,
    titleAction: hasAllocationRun ? (
      <>
        <SwitchAnalyzeType />
      </>
    ) : null,
    loadingMessage: t`Loading Analysed Results...`,
    size:
      analyzeType === 'allocate' &&
      selectedTab === 'tab-analyze-items' &&
      itemsViewAs === 'allocate'
        ? 'half'
        : 'md',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <SimulationAnalyzeSidebarCmp />
    </SidebarContainer>
  );
};
