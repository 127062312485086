import {
  ItemFilterType,
  OrderLineFilterFragment,
  OrderLineFilterIntersectionFragment,
  OrderLineFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterFieldConfigBase } from '../../common/types';
import {
  getPolicyFilterDataKeysByEditorType,
  getPolicyFilterKey,
} from '../../policyFilters/policyFilter.helper';
import PolicyFilterValueEditor, {
  PolicyFilterValueEditorProps,
} from '../../policyFilters/PolicyFilterValueEditor';
import { getOrderLineFilterConfigCommon } from '../../simulation/store/pickingPolicy/pickingPolicy.default';
import {
  pickingPolicySelectedIdentity,
  pickingPolicySelectedOrderLineIntersection,
} from '../../simulation/store/pickingPolicy/pickingPolicy.state';
import { LoadOrderLineFilterValuesParams } from '../hooks/useLoadOrderLineFilterValues';

export type PickingPolicyOrderLineFilterEditorProps = {
  filterConfig: FilterFieldConfigBase<OrderLineFilterType>;
  startLoadData: (params: LoadOrderLineFilterValuesParams) => Promise<void>;
  cancelLoadData: (fieldId: string) => void;
};

const PickingPolicyOrderLineFilterEditor: React.FC<
  PickingPolicyOrderLineFilterEditorProps
> = props => {
  const { type } = props.filterConfig;
  const { startLoadData, cancelLoadData } = props;
  const editableFilterSetIdentity = useRecoilValue(
    pickingPolicySelectedIdentity,
  );
  const [filterIntersection, setFilterIntersection] = useRecoilState(
    pickingPolicySelectedOrderLineIntersection,
  );

  if (!editableFilterSetIdentity || !filterIntersection) return null;

  const fieldValue = _.find(filterIntersection?.allOf, f => f.type === type);

  const onSelectValue = (value: OrderLineFilterFragment) => {
    const others = filterIntersection.allOf.filter(fi => fi.type !== type);
    // If all values was deselected in filter, we shouldn't add this filter to set
    if (
      !_.isEmpty(value.valueIn) ||
      !_.isNil(value?.range?.from) ||
      !_.isNil(value?.range?.to)
    ) {
      others.push(value);
    }

    const newIntersection: OrderLineFilterIntersectionFragment = {
      id: filterIntersection.id,
      allOf: others,
    };

    setFilterIntersection(newIntersection);

    // trigger other filter to refresh
    const itemSetFields = new Set(_.keys(ItemFilterType));
    const isItemSetField = itemSetFields.has(value.type);

    getOrderLineFilterConfigCommon()
      .filter(
        configItem => isItemSetField === itemSetFields.has(configItem.type),
      )
      .forEach(configItem => {
        if (configItem.type !== type) {
          const keyParts = getPolicyFilterDataKeysByEditorType(
            configItem.editorType,
          );
          keyParts.forEach(key => {
            const filterKey = getPolicyFilterKey(configItem.type, key);
            cancelLoadData(configItem.type);
            startLoadData({
              field: configItem.type,
              filterKey,
              filterIntersection: newIntersection,
            });
          });
        }
      });
  };

  const editorProps: PolicyFilterValueEditorProps<OrderLineFilterType> = {
    config: props.filterConfig,
    value: fieldValue,
    onValueChange: onSelectValue,
    startLoadData: params => startLoadData({ ...params, filterIntersection }),
    cancelLoadData,
  };

  return <PolicyFilterValueEditor {...editorProps} />;
};
export default PickingPolicyOrderLineFilterEditor;
