import { Role } from '../types';

export const SupervisorStock: Role = {
  id: 'SupervisorStock',
  version: '2024-06-01',
  description:
    'Warehouse Stock Supervisor - can only see the stock related activity feed of the warehouse',
  permissions: [
    {
      actions: ['read'],
      features: ['tab-feed-items', 'tab-feed-heatmaps', 'layout', 'table'],
    },
  ],
  resources: ['/wh/i/{warehouse-id}/feed/*'],
};
