import * as React from 'react';
const Mhe6ForkliftIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Mhe6ForkliftIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M6.46,108.87c.23,4.45,2.11,7.71,5.62,9.78,3.52,2.07,7.27,2.05,11.25-.06,1.8-1.02,3.2-2.38,4.22-4.1s1.52-3.59,1.52-5.62c-.23-4.53-2.13-7.81-5.68-9.84s-7.32-2.03-11.31,0c-1.8,1.09-3.18,2.5-4.16,4.22-.98,1.72-1.46,3.59-1.46,5.62ZM65.99,107.23c.23,5.16,2.38,8.89,6.45,11.19s8.4,2.32,13.01.06c2.03-1.25,3.61-2.85,4.75-4.8s1.7-4.1,1.7-6.45c-.23-5.16-2.38-8.91-6.45-11.25-4.06-2.34-8.4-2.34-13.01,0-2.03,1.17-3.61,2.75-4.75,4.75s-1.7,4.16-1.7,6.5ZM30.72,74.65c1.48,0,2.91.25,4.28.76,1.37.51,2.56,1.27,3.57,2.29l9.49,8.67c2.66,2.27,5.66,3.4,9.02,3.4l23.32.23c.78-.08,1.21-.55,1.29-1.41,0-.16-.04-.27-.12-.35-2.03-4.84-4.96-10.31-8.79-16.41-3.83-6.09-8.63-11.72-14.41-16.87-2.19-1.8-4.65-3.14-7.38-4.04-2.73-.9-5.55-1.23-8.44-1l-15,1.29c-1.17.08-2.25.47-3.22,1.17s-1.74,1.64-2.29,2.81c-2.42,5.78-4.37,11.76-5.86,17.93-.16.7.12,1.17.82,1.41l13.71.12h0ZM56.85,93.05c-3.91,0-7.38-1.33-10.43-3.98l-9.49-8.55c-1.87-1.72-4.1-2.58-6.68-2.58H11.74c-.94,0-1.78.25-2.52.76-.74.51-1.27,1.19-1.58,2.05-2.5,7.42-3.71,15-3.63,22.73,0,.39.18.61.53.64s.57-.06.64-.29c1.56-3.59,4.04-6.07,7.44-7.44s6.89-1.35,10.49.06c.78.31,1.52.7,2.23,1.17,1.56.86,2.77,2.07,3.63,3.63,1.09,1.8,1.76,3.71,1.99,5.74s.08,4.02-.47,5.98h34.45c-.94-2.34-1.29-4.77-1.05-7.27s1.02-4.84,2.34-7.03c1.02-1.8,2.42-3.16,4.22-4.1.78-.55,1.6-.94,2.46-1.17l-16.05-.35h0ZM98.57,107.11c-.31-2.5-1.6-12.38-3.87-29.65l-3.75-29.65-11.95,1.52.59,5.04c.08.39.27.7.59.94.31.23.66.35,1.05.35l1.64-.23,5.04,38.91c.08.39.27.74.59,1.05,2.58,2.11,4.3,4.67,5.16,7.68s.74,6.07-.35,9.2l24.37-3.16,6.33-3.75-23.67,3.05c-.39.16-.76.12-1.11-.12s-.57-.55-.64-.94v-.23h0Z"
    />
  </svg>
);
export default Mhe6ForkliftIcon;
