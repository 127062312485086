import { ConverterSettings } from '@warebee/shared/data-access-layout-import-converter';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  formatDateTime,
  formatTimeAgoShort,
} from '../../../../common/formatHelper';
import { AsyncLoadStatus } from '../../../../common/types';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import useSaveConverterBuilder from '../hooks/useSaveConverterBuilder';
import { converterAreaConfiguration } from '../store/converter.area.state';
import {
  converterAllAreasBuilder,
  converterBayTypesBuilder,
  converterBuilderState,
  converterExtraLocations,
  converterLayoutBuilder,
  converterLayoutData,
  converterLocationPatches,
  converterLocationTypesBuilder,
  converterShelvesSettingsBuilder,
  converterSpacerAreaSettings,
} from '../store/converter.state';

export const ConverterSaveBuilderPanel: React.FC = () => {
  const layoutData = useRecoilValue(converterLayoutData);
  const layoutSettings = useRecoilValue(converterLayoutBuilder);
  const areasConfigurations = useRecoilValue(converterAreaConfiguration);
  const areasBuilder = useRecoilValue(converterAllAreasBuilder);
  const locationsPatches = useRecoilValue(converterLocationPatches);
  const locationTypesBuilder = useRecoilValue(converterLocationTypesBuilder);
  const bayTypes = useRecoilValue(converterBayTypesBuilder);
  const shelvesSettings = useRecoilValue(converterShelvesSettingsBuilder);
  const areaSpacers = useRecoilValue(converterSpacerAreaSettings);
  const extraLocations = useRecoilValue(converterExtraLocations);
  const save = useSaveConverterBuilder();
  const builderState = useRecoilValue(converterBuilderState);
  const { t } = useTranslation('designer');

  useEffect(() => {
    if (layoutData?.id) {
      const objectToSave: ConverterSettings = {
        layoutSettings,
        areasConfigurations,
        areasBuilder,
        locationsPatches,
        locationsTypes: locationTypesBuilder,
        bayTypes,
        shelvesSettings,
        areaSpacers,
        extraLocations,
      };
      save(layoutData?.id, objectToSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areasBuilder,
    areasConfigurations,
    layoutData?.id,
    layoutSettings,
    locationTypesBuilder,
    locationsPatches,
    bayTypes,
    shelvesSettings,
    extraLocations,
  ]);

  if (!builderState) {
    return null;
  }

  return builderState.status === AsyncLoadStatus.Loading ? (
    <LoadingIndicator
      className="text-xxs md:text-xs"
      message={t`Updating`}
      inline
      transparent
    />
  ) : (
    <>
      <span
        className={'text-menu-100 hidden text-xs lg:block'}
      >{t`Updated:`}</span>
      <span
        title={formatDateTime(builderState.updatedAt)}
        className={'text-xxs md:text-xs ltr:ml-2 rtl:mr-2'}
      >
        {formatTimeAgoShort(builderState.updatedAt)}
      </span>
    </>
  );
};

export default ConverterSaveBuilderPanel;
