import * as React from 'react';
const ObjectAisleIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="ObjectAisleIcon"
    {...props}
  >
    <polygon
      strokeWidth={0}
      points="88.33 124.08 39.61 124.64 57.52 79.78 70.63 79.78 88.33 124.08"
    />
    <polygon
      strokeWidth={0}
      points="8.11 87.44 8.11 115.63 27.96 87.44 8.11 87.44"
    />
    <polygon
      strokeWidth={0}
      points="8.11 9.19 8.11 37.37 27.96 9.19 8.11 9.19"
    />
    <polygon
      strokeWidth={0}
      points="29.58 121.24 29.58 93.23 9.85 121.24 29.58 121.24"
    />
    <polygon
      strokeWidth={0}
      points="8.11 48.31 8.11 76.5 27.96 48.31 8.11 48.31"
    />
    <polygon
      strokeWidth={0}
      points="29.58 42.99 29.58 14.98 9.85 42.99 29.58 42.99"
    />
    <polygon
      strokeWidth={0}
      points="29.58 82.12 29.58 54.1 9.85 82.12 29.58 82.12"
    />
    <path
      strokeWidth={0}
      d="M34.22,4.02H3.61s0,120.9,0,120.9h30.61s18.96-45.94,18.96-45.94v-20.05S34.22,4.02,34.22,4.02ZM30.52,44.8v1.23s2.27,0,2.27,0v37.06s-1.14,0-1.14,0h-1.14s0,.28,0,.28v.56s0,0,0,0v1.23s2.27,0,2.27,0v37.06s-1.14,0-1.14,0h-1.14s0,.28,0,.28v1.26H7.18v-1.13s0-.15,0-.15v-.13s0-.13,0-.13h-1.14s-1.14,0-1.14,0v-37.06s2.27,0,2.27,0v-.53s0,0,0,0v-1.13s0-.15,0-.15v-.13s0-.13,0-.13h-1.14s-1.14,0-1.14,0v-37.06s2.27,0,2.27,0v-.53s0,0,0,0v-1.13s0-.15,0-.15v-.13s0-.13,0-.13h-1.14s-1.14,0-1.14,0V6.91s2.27,0,2.27,0v-1.26s23.34.03,23.34.03v1.23s2.27,0,2.27,0v37.06s-1.14,0-1.14,0h-1.14s0,.28,0,.28v.56s0,0,0,0Z"
    />
    <polygon
      strokeWidth={0}
      points="119.32 87.44 119.32 115.63 99.46 87.44 119.32 87.44"
    />
    <polygon
      strokeWidth={0}
      points="119.32 9.19 119.32 37.37 99.46 9.19 119.32 9.19"
    />
    <polygon
      strokeWidth={0}
      points="97.85 121.24 97.84 93.23 117.57 121.24 97.85 121.24"
    />
    <polygon
      strokeWidth={0}
      points="119.32 48.31 119.32 76.5 99.46 48.31 119.32 48.31"
    />
    <polygon
      strokeWidth={0}
      points="97.85 42.99 97.84 14.98 117.57 42.99 97.85 42.99"
    />
    <polygon
      strokeWidth={0}
      points="97.85 82.12 97.84 54.1 117.57 82.12 97.85 82.12"
    />
    <path
      strokeWidth={0}
      d="M74.24,58.93v20.05s18.96,45.94,18.96,45.94h30.61s0-120.9,0-120.9h-30.61s-18.96,54.91-18.96,54.91ZM96.91,44.8v-.56s0-.28,0-.28h-1.14s-1.14,0-1.14,0V6.91s2.27,0,2.27,0v-1.23s23.34-.03,23.34-.03v1.26h2.27s0,37.06,0,37.06h-1.14s-1.14,0-1.14,0v.13s0,.13,0,.13v.15s0,1.13,0,1.13h0v.53h2.27s0,37.06,0,37.06h-1.14s-1.14,0-1.14,0v.13s0,.13,0,.13v.15s0,1.13,0,1.13h0v.53h2.27s0,37.06,0,37.06h-1.14s-1.14,0-1.14,0v.13s0,.13,0,.13v.15s0,1.13,0,1.13h-23.34s0-1.26,0-1.26v-.28h-1.14s-1.14,0-1.14,0v-37.06s2.27,0,2.27,0v-1.23s0,0,0,0v-.56s0-.28,0-.28h-1.14s-1.14,0-1.14,0v-37.06s2.27,0,2.27,0v-1.23s0,0,0,0Z"
    />
  </svg>
);
export default ObjectAisleIcon;
