import * as React from 'react';
const TableViewIcon = props => (
  <svg
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="TableViewIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M111.58,4H14.59c-5.79,0-10.59,4.8-10.59,10.59v98.82c0,5.79,4.8,10.59,10.59,10.59h98.82c5.79,0,10.59-4.8,10.59-10.59V14.59c0-5.93-6.64-10.59-12.42-10.59ZM116.94,113.41c0,1.98-1.55,3.53-3.53,3.53H14.59c-1.98,0-3.53-1.55-3.53-3.53V14.59c0-1.98,1.55-3.53,3.53-3.53h97.13c2.4,0,5.22,2.12,5.22,3.53v98.82Z"
    />
    <rect width={40.23} height={10.24} x={37.88} y={38.88} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M106.35,18.12h-24.71v17.24h28.24v-13.71c0-1.98-1.55-3.53-3.53-3.53Z"
    />
    <rect width={40.23} height={17.24} x={37.88} y={18.12} strokeWidth={0} />
    <rect width={16.24} height={10.24} x={18.12} y={38.88} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M18.12,106.35c0,1.98,1.55,3.53,3.53,3.53h12.71v-17.43h-16.24v13.9Z"
    />
    <rect width={40.23} height={17.43} x={37.88} y={92.45} strokeWidth={0} />
    <rect width={28.24} height={10.24} x={81.65} y={38.88} strokeWidth={0} />
    <rect width={40.23} height={10.24} x={37.88} y={52.52} strokeWidth={0} />
    <rect width={16.24} height={10.24} x={18.12} y={52.52} strokeWidth={0} />
    <rect width={28.24} height={10.24} x={81.65} y={52.52} strokeWidth={0} />
    <rect width={40.23} height={10.24} x={37.88} y={66.17} strokeWidth={0} />
    <rect width={16.24} height={10.24} x={18.12} y={66.17} strokeWidth={0} />
    <rect width={28.24} height={10.24} x={81.65} y={66.17} strokeWidth={0} />
    <rect width={40.23} height={10.24} x={37.88} y={79.51} strokeWidth={0} />
    <rect width={16.24} height={10.24} x={18.12} y={79.51} strokeWidth={0} />
    <rect width={28.24} height={10.24} x={81.65} y={79.51} strokeWidth={0} />
    <path
      strokeWidth={0}
      d="M18.12,21.65v13.71h16.24v-17.24h-12.71c-1.98,0-3.53,1.55-3.53,3.53Z"
    />
    <path
      strokeWidth={0}
      d="M81.65,109.88h24.71c1.98,0,3.53-1.55,3.53-3.53v-13.9h-28.24v17.43Z"
    />
  </svg>
);
export default TableViewIcon;
