import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type ContainerSidebarProps = {
  children?: React.ReactNode;
  className?: string;
  side?: string | 'left' | 'right';
};

export const ContainerSidebar = ({
  children,
  className,
  side,
}: ContainerSidebarProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <aside
      id={id}
      data-component={`ContainerSidebar side-${side}`}
      className={classNames('flex', className)}
    >
      {children}
    </aside>
  );
};
