import * as React from 'react';
const WarehouseApparelIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    data-name="WarehouseApparelIcon"
    {...props}
  >
    <path
      strokeWidth={0}
      d="M64.5,24.5L.84,40.93v62.58h126.32v-62.58l-62.66-16.43ZM44.22,71.45v22.38s-3.31,2.05-13.24,2.05-13.24-2.05-13.24-2.05v-22.31l-1.66,1.39-8.08-6.42c.79-2.18,2.38-5.36,3.57-7.35,3.31-5.5,6.82-6.22,12.12-6.29.79,1.85,3.11,3.64,7.28,3.64s6.42-1.79,7.28-3.64c5.23.07,8.81.79,12.12,6.29h0c1.19,1.99,2.78,5.16,3.58,7.28l-8.08,6.42-1.66-1.39Z"
    />
    <g>
      <image
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAApCAYAAABDV7v1AAAACXBIWXMAAAsSAAALEgHS3X78AAAAHUlEQVRYhe3BAQ0AAADCoPdPbQ8HFAAAAAAAABwbGxEAAYMiiy4AAAAASUVORK5CYII="
        width={42}
        height={41}
        transform="translate(42.14 43)"
      />
    </g>
  </svg>
);
export default WarehouseApparelIcon;
