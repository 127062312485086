import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

export type MailtoProps = {
  children?: React.ReactNode;
  className?: string;
  email: string;
  subject?: string;
  body?: string;
};
// const Mailto = ({ email, subject = '', body = '', children }) => {
export const MailTo = ({
  children,
  className,
  email,
  subject,
  body,
}: MailtoProps) => {
  const [id] = useState<string>(_.uniqueId());
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return (
    <a
      data-component="MailTo"
      className={
        classNames(className)
        // 'px-2 py-1',
        // 'opacity-90 text-menu-active',
        // 'hover:text-menu-active-hover border rounded border-menu-50/20 hover:border-b hover:border-b-brand',
      }
      id={id + email}
      href={`mailto:${email}${params}`}
    >
      {children}
    </a>
  );
};
