import React, { useState } from 'react';
import _ from 'lodash';
import CookieConsent from '../CookieConsent';

export type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  consent?: boolean;
  dataComponent?: string;
};

export const ContainerPublic = ({
  children,
  className,
  consent,
  dataComponent,
}: ContainerProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <main
      id={id}
      data-component={`ContainerPublic${
        dataComponent ? '-' + dataComponent : ''
      }`}
      className={`flex flex-1 w-screen h-full ${className || ''}`}
    >
      {children}
      {consent && <CookieConsent />}
    </main>
  );
};
